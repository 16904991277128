import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import cx from 'classnames';

import { Button, ApiResponseLoader } from '@clef/client-library';

import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useGetProjectModelListQuery } from '@/serverStore/projectModels';

import { ModelPerformanceSidebarEmptyState } from '../ModelPerformanceSidebarEmptyState';
import { useDataBrowserStyles } from '../../styles';
import useStyles from './styles';
import ModelInfoCard from './ModelInfoCard';

const MODELS_COUNT_PER_TIME = 5;
const INITIAL_MODELS_COUNT = 2;

const ModelList: React.FC = () => {
  const dataBrowserStyles = useDataBrowserStyles();
  const styles = useStyles();

  const { labelType } = useGetSelectedProjectQuery().data ?? {};

  const {
    data: projectModelsData,
    isLoading: projectModelsLoading,
    error: projectModelsError,
  } = useGetProjectModelListQuery();

  const [modelCardCount, setModelCardCount] = useState(INITIAL_MODELS_COUNT);

  return (
    <Box
      className={cx({
        [styles.modelListWrapper]: true,
        [dataBrowserStyles.customizedScrollbar]: true,
      })}
    >
      {!projectModelsLoading && !projectModelsError && !projectModelsData?.length && (
        <Box mt={4}>
          <ModelPerformanceSidebarEmptyState labelType={labelType} />
        </Box>
      )}

      <ApiResponseLoader
        response={projectModelsData}
        loading={projectModelsLoading}
        error={projectModelsError ?? undefined}
        defaultHeight={40}
      >
        {projectModelsLoaded => {
          const savedModels = projectModelsLoaded.filter(model => model.modelName);

          return (
            <Box p={4}>
              {savedModels.slice(0, modelCardCount).map((model, index) => (
                <ModelInfoCard key={model.id} model={model} index={index} />
              ))}
              {modelCardCount < savedModels.length && (
                <Button
                  id="toggle-show-all-models"
                  variant="outlined"
                  color="default"
                  className={styles.toggleShowAllModelsBtn}
                  onClick={() => {
                    setModelCardCount(modelCardCount => modelCardCount + MODELS_COUNT_PER_TIME);
                  }}
                >
                  {t(`Load {{remaining}} more`, {
                    remaining: Math.min(MODELS_COUNT_PER_TIME, savedModels.length - modelCardCount),
                  })}
                </Button>
              )}
              {modelCardCount >= savedModels.length && savedModels.length > INITIAL_MODELS_COUNT && (
                <Button
                  id="toggle-show-less"
                  variant="outlined"
                  color="default"
                  className={styles.toggleShowAllModelsBtn}
                  onClick={() => {
                    setModelCardCount(INITIAL_MODELS_COUNT);
                  }}
                >
                  {t('Show less')}
                </Button>
              )}
            </Box>
          );
        }}
      </ApiResponseLoader>
    </Box>
  );
};
export default ModelList;

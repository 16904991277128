import { BaseAPI } from './base_api';
import { ApiResponse, ProjectId, RegisteredModel } from '@clef/shared/types';

type EndpointInfo = {
  id: string;
  name: string;
  isCloud: boolean;
};

export type BundleInfo = {
  id: string;
  threshold: number;
  endpoints: EndpointInfo[];
  isFav: boolean;
  isDeleted: boolean;
};

export type ModelListResponse = {
  modelList: RegisteredModelWithBundles[];
};

export type RegisteredModelWithBundles = RegisteredModel & { bundles: BundleInfo[] };

export type RegisteredModelWithThreshold = RegisteredModel & {
  threshold: number;
};

export type RegisteredModelWithBundlesThresholdAndEndpoints = RegisteredModelWithBundles & {
  threshold: number;
} & {
  endpoints: EndpointInfo[];
};

export type RegisteredModelWithThresholdAndEndpoints = RegisteredModelWithThreshold & {
  endpoints: EndpointInfo[];
};

class ClefModelAPI extends BaseAPI {
  async postCreateFastTrainingJob(params: object): Promise<ApiResponse<{ runId: string }>> {
    return this.postJSON(`train`, params);
  }

  async postCreateVPTrainingJob(
    projectId: number,
    currentMediaId: number,
  ): Promise<ApiResponse<{ data: { jobId: string } }>> {
    return this.postJSON(`train_vp_model`, { projectId, currentMediaId });
  }

  async getModelList(params: { projectId: ProjectId }): Promise<ModelListResponse> {
    return this.get(`list`, params, true);
  }
}

export default new ClefModelAPI('model');

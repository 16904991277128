import { OrgId, UsageSummary } from '@clef/shared/types';
import { useQuery } from '@tanstack/react-query';
import usage_api, {
  GetUsageParams,
  ModelCountsItem,
  UsageImagesItem,
  UsageItem,
} from '@/api/usage_api';
import { ApiErrorType } from '@/api/base_api';
import { dateToISOString, tomorrowISOString } from '@/utils/date_utils';
import { useTypedSelector } from '@/hooks/useTypedSelector';

export const usageQueryKey = {
  all: (orgId: number) => [orgId, 'usage'] as const,
  record: (orgId: OrgId, params: GetUsageParams | undefined, disableDateConversion?: boolean) =>
    [
      ...usageQueryKey.all(orgId),
      'record',
      params?.fromTimestamp ?? {},
      params?.toTimestamp ?? {},
      params?.interval ?? {},
      params?.groupByProject ?? {},
      params?.groupBySource ?? {},
      params?.groupByImages ?? {},
      params?.pageSize ?? {},
      disableDateConversion,
    ] as const,
  mediaCountsByOrg: (orgId: OrgId) => [...usageQueryKey.all(orgId), 'mediaCounts'] as const,
  mediaCounts: (orgId: OrgId, params: GetUsageParams) =>
    [
      ...usageQueryKey.mediaCountsByOrg(orgId),
      params.fromTimestamp,
      params.toTimestamp,
      params.interval,
      params.groupByProject,
      params.groupBySource,
      params.groupByImages,
      params.pageSize,
    ] as const,
  modelCounts: (orgId: OrgId, params: GetUsageParams) =>
    [
      ...usageQueryKey.all(orgId),
      'modelCounts',
      params.fromTimestamp,
      params.toTimestamp,
      params.interval,
      params.groupByProject,
      params.groupBySource,
      params.groupByImages,
      params.pageSize,
    ] as const,
  summary: (orgId: OrgId, includeLabeledMediaCount?: boolean) =>
    [...usageQueryKey.all(orgId), 'summary', includeLabeledMediaCount] as const,
};

const convertDateFormats = (params: GetUsageParams) => {
  return {
    ...params,
    fromTimestamp: dateToISOString(params.fromTimestamp),
    toTimestamp: tomorrowISOString(params.toTimestamp),
  } as GetUsageParams;
};

export const useGetUsage = (
  params: (GetUsageParams & { disableDateConversion?: boolean }) | undefined,
) => {
  const orgId = useTypedSelector(state => state.login.user)?.orgId ?? 0;
  const { fromTimestamp, toTimestamp, disableDateConversion } = params ?? {};
  return useQuery<UsageItem[], ApiErrorType>({
    queryKey: usageQueryKey.record(orgId, params, disableDateConversion),
    queryFn: async () => {
      if (!params) return [];
      const { disableDateConversion, ...restParams } = params;
      const response = await usage_api.getUsage(
        disableDateConversion ? restParams : convertDateFormats(restParams),
      );
      return response;
    },
    enabled: !!orgId && !!fromTimestamp && !!toTimestamp,
  });
};

export const useGetUsageImages = (params: GetUsageParams) => {
  const orgId = useTypedSelector(state => state.login.user)?.orgId ?? 0;
  const { fromTimestamp, toTimestamp } = params;
  return useQuery<UsageImagesItem[], ApiErrorType>({
    queryKey: usageQueryKey.mediaCounts(orgId, params),
    queryFn: async () => {
      const response = await usage_api.getMediaCounts(convertDateFormats(params));
      return response;
    },
    enabled: !!orgId && !!fromTimestamp && !!toTimestamp,
  });
};

export const useGetModelCounts = (params: GetUsageParams) => {
  const orgId = useTypedSelector(state => state.login.user)?.orgId ?? 0;
  const { fromTimestamp, toTimestamp } = params;
  return useQuery<ModelCountsItem[], ApiErrorType>({
    queryKey: usageQueryKey.modelCounts(orgId, params),
    queryFn: async () => {
      const response = await usage_api.getModelCounts(convertDateFormats(params));
      return response;
    },
    enabled: !!orgId && !!fromTimestamp && !!toTimestamp,
  });
};

export const useGetUsageSummary = (includeLabeledMediaCount?: boolean) => {
  const orgId = useTypedSelector(state => state.login.user)?.orgId ?? 0;
  return useQuery<UsageSummary, ApiErrorType>({
    queryKey: usageQueryKey.summary(orgId, includeLabeledMediaCount),
    queryFn: async () => {
      const response = await usage_api.getUsageSummary({ includeLabeledMediaCount });
      return response;
    },
    enabled: !!orgId,
  });
};

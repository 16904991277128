import React from 'react';

export type PenIconProps = {};

const PenIcon: React.FC<PenIconProps> = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0001 11.6664L16.3334 6.9997M2.91675 25.083L6.86518 24.6443C7.34758 24.5907 7.58879 24.5639 7.81423 24.4909C8.01425 24.4262 8.2046 24.3347 8.38012 24.219C8.57794 24.0885 8.74955 23.9169 9.09276 23.5737L24.5001 8.16637C25.7888 6.87771 25.7888 4.78837 24.5001 3.4997C23.2114 2.21104 21.1221 2.21104 19.8334 3.4997L4.4261 18.907C4.08289 19.2502 3.91128 19.4218 3.78082 19.6197C3.66509 19.7952 3.5736 19.9855 3.50885 20.1856C3.43586 20.411 3.40906 20.6522 3.35546 21.1346L2.91675 25.083Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PenIcon;

import React from 'react';
import { useHistory } from 'react-router-dom';

import { SignupType } from '@clef/shared/types';

import { SelfServiceCompleteSignup } from './SelfServiceCompleteSignup';
import { InvitedCompleteSignup } from './InvitedCompleteSignup';

export const CompleteSignup: React.FC = () => {
  const history = useHistory();
  const type = new URLSearchParams(history?.location?.search).get('type');

  // TODO: add case for unknown type
  return type === SignupType.Register ? <SelfServiceCompleteSignup /> : <InvitedCompleteSignup />;
};

import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

import { Typography } from '@clef/client-library';

const useStyles = makeStyles(theme => ({
  step: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.greyModern[600]}`,
    color: theme.palette.greyModern[900],
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1,
  },
}));

interface StepTitleProps {
  step: number;
  title: string;
}

const StepTitle: React.FC<StepTitleProps> = ({ step, title }) => {
  const styles = useStyles();

  return (
    <Box display="flex" alignItems="center" style={{ gap: 12 }} mt={4}>
      <Box className={styles.step}>{step}</Box>
      <Typography variant="h2_semibold">{t(title)}</Typography>
    </Box>
  );
};

export default StepTitle;

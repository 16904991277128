import React, { useEffect } from 'react';
import cx from 'classnames';
import { useDeviceList } from '../../../hooks/api/useEdgeApi';
import { Paper, Grid, Typography } from '@material-ui/core';
import { Button } from '@clef/client-library';
import {
  ApiResponseLoader,
  Dropdown,
  SelectOptions,
  useStateSyncSearchParams,
  SimpleBadge,
} from '@clef/client-library';
import { useDeviceMonitorStyles } from './deviceMonitorStyles';
import { useDeviceMonitorState } from './deviceMonitorState';
import ModelFilter from './filters/ModelFilter';
import UploadTimeFilter from './filters/UploadTimeFilter';
import DefectFilter from './filters/DefectFilter';
import ConfidenceFilter from './filters/ConfidenceFilter';
import HumanJudgementFilter from './filters/HumanJudgementFilter';
import MoreFiltersDropdown from './filters/MoreFiltersDropdown';
import ImageIdFilter from './filters/ImageIdFilter';
import LocationIdFilter from './filters/LocationIdFilter';
import InspectionStationIdFilter from './filters/InspectionStationIdFilter';
import { DEFAULT_DEVICE_NAME } from '../../../pages/deployment/constants';
import { normalizeDefaultDeviceName } from './utils';

export interface DeviceMonitorTopPanelProps {
  edgeProjectId: number;
  hideDeviceSwitcher?: boolean;
}

const deviceSelectionDefaultOption = t('- Select Device -');

export const DeviceMonitorTopPanel: React.FC<DeviceMonitorTopPanelProps> = ({
  edgeProjectId,
  hideDeviceSwitcher = false,
}) => {
  const styles = useDeviceMonitorStyles();
  const [deviceList, deviceListLoading, deviceListError] = useDeviceList({
    projectId: edgeProjectId!,
  });
  const {
    state: {
      deviceId,
      filterImageIdEnabled,
      filterLocationIdEnabled,
      filterInspectionStationIdEnabled,
    },
    dispatch,
  } = useDeviceMonitorState();

  const extraFilterCount = [
    filterImageIdEnabled,
    filterLocationIdEnabled,
    filterInspectionStationIdEnabled,
  ].filter(enabled => enabled).length;

  const [localDeviceId, setLocalDeviceId] = useStateSyncSearchParams('device', '');
  // sync local deviceId to state deviceId
  useEffect(() => {
    dispatch(draft => {
      draft.deviceId = localDeviceId;
    });
  }, [dispatch, localDeviceId]);
  return (
    <Paper className={styles.topPanelContainer}>
      <ApiResponseLoader
        response={deviceList}
        loading={deviceListLoading}
        error={deviceListError}
        defaultHeight={72}
      >
        {response => {
          return (
            <>
              {/* Select device */}
              {!hideDeviceSwitcher && (
                <Grid container direction="row" alignItems="center" className={styles.topSection}>
                  <div className={styles.sectionComponent}>
                    <Typography variant="body1">{t('Choose device to monitor')}</Typography>
                  </div>
                  <div className={styles.sectionComponent}>
                    <SelectOptions
                      size="medium"
                      options={[
                        deviceSelectionDefaultOption,
                        ...response.map(device =>
                          normalizeDefaultDeviceName(device.id, String(edgeProjectId)),
                        ),
                      ]}
                      value={normalizeDefaultDeviceName(
                        deviceId || deviceSelectionDefaultOption,
                        String(edgeProjectId),
                      )}
                      onChange={newValue =>
                        setLocalDeviceId(
                          newValue === deviceSelectionDefaultOption
                            ? ''
                            : ((newValue === DEFAULT_DEVICE_NAME
                                ? String(edgeProjectId)
                                : newValue) as string),
                        )
                      }
                    />
                  </div>
                </Grid>
              )}

              {/* Filters */}
              {!!deviceId && (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={cx(
                    styles.topSection,
                    !hideDeviceSwitcher && styles.topSectionFilterSection,
                  )}
                >
                  <div className={styles.sectionComponent}>
                    <ModelFilter />
                  </div>
                  <div className={styles.sectionComponent}>
                    <UploadTimeFilter />
                  </div>
                  <div className={styles.sectionComponent}>
                    <DefectFilter />
                  </div>
                  <div className={styles.sectionComponent}>
                    <ConfidenceFilter />
                  </div>
                  <div className={styles.sectionComponent}>
                    <HumanJudgementFilter />
                  </div>
                  <Dropdown
                    extraGutter={{ vertical: 12 }}
                    dropdown={
                      <MoreFiltersDropdown
                        filters={[
                          {
                            node: <ImageIdFilter />,
                            title: t('Image ID'),
                            enabled: filterImageIdEnabled,
                            enable: () => {
                              dispatch(draft => {
                                draft.filterImageIdEnabled = true;
                              });
                            },
                            disable: () => {
                              dispatch(draft => {
                                draft.filterImageId = '';
                                draft.filterImageIdEnabled = false;
                              });
                            },
                          },
                          {
                            node: <LocationIdFilter />,
                            title: t('Location ID'),
                            enabled: filterLocationIdEnabled,
                            enable: () => {
                              dispatch(draft => {
                                draft.filterLocationIdEnabled = true;
                              });
                            },
                            disable: () => {
                              dispatch(draft => {
                                draft.filterLocationId = '';
                                draft.filterLocationIdEnabled = false;
                              });
                            },
                          },
                          {
                            node: <InspectionStationIdFilter />,
                            title: t('Inspection Station ID'),
                            enabled: filterInspectionStationIdEnabled,
                            enable: () => {
                              dispatch(draft => {
                                draft.filterInspectionStationIdEnabled = true;
                              });
                            },
                            disable: () => {
                              dispatch(draft => {
                                draft.filterInspectionStationId = '';
                                draft.filterInspectionStationIdEnabled = false;
                              });
                            },
                          },
                        ]}
                      />
                    }
                  >
                    <Button
                      color="primary"
                      size="small"
                      className={styles.textButton}
                      id="data-browser-more-filters"
                    >
                      <span className={styles.moreFilterText}>{t('More filters')}</span>
                      {extraFilterCount > 0 && (
                        <SimpleBadge color="primary" content={extraFilterCount} />
                      )}
                    </Button>
                  </Dropdown>
                </Grid>
              )}
            </>
          );
        }}
      </ApiResponseLoader>
    </Paper>
  );
};

export default DeviceMonitorTopPanel;

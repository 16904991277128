import React, { useCallback } from 'react';
import { DefectId } from '@clef/shared/types';
import { Typography } from '@material-ui/core';
import { useDefectSelector } from '@/store/defectState/actions';
import {
  AccordionWrapper,
  NoiseThresholdSelector,
} from '@/pages/DataBrowser/InstantLearningModels/InstantLearningModelsSidebar';
import { useDataBrowserState } from '@/pages/DataBrowser/dataBrowserState';

interface NoiseFilterSectionProps {}

export const NoiseFilterSection: React.FC<NoiseFilterSectionProps> = () => {
  const allDefects = useDefectSelector();
  const { state, dispatch } = useDataBrowserState();

  const setAreaThresholdByDefectId = useCallback(
    (defectId: DefectId, newAreaThreshold: number) => {
      dispatch(draft => {
        if (!draft.TEMP_defectIdToSegmentationAreaThreshold) {
          draft.TEMP_defectIdToSegmentationAreaThreshold = {};
        }

        draft.TEMP_defectIdToSegmentationAreaThreshold[defectId] = newAreaThreshold;
      });
    },
    [dispatch],
  );

  return (
    <AccordionWrapper
      title={t('Noise Filtering')}
      tooltipTitle={t('Suppress predicted regions below a certain area')}
    >
      {allDefects.length ? (
        allDefects.map(defect => (
          <NoiseThresholdSelector
            defect={defect}
            key={defect.id}
            areaThreshold={state.TEMP_defectIdToSegmentationAreaThreshold?.[defect.id] ?? 0}
            setAreaThreshold={newAreaThreshold => {
              setAreaThresholdByDefectId(defect.id, newAreaThreshold);
            }}
          />
        ))
      ) : (
        <Typography>{t('You can set thresholds after creating classes.')}</Typography>
      )}
    </AccordionWrapper>
  );
};

import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import CLEF_PATH from '../../../constants/path';
import { transformLegacyPath } from '../utils';
import { PageLayout } from '../PageLayout';

import { PageTitle } from '../../../constants/page';
import LabelingManagement from '../../../pages/LabelingManagement';
import LabelingTask from '../../../pages/LabelingTask';
import LabelingReview from '../../../pages/LabelingReview';
import { PageName } from '@clef/shared/constants';

export interface labelingRoutesProps {}

const labelingRoutes = (currentPath: string) => [
  <Route
    exact
    key={CLEF_PATH.label.main}
    path={transformLegacyPath(currentPath, CLEF_PATH.label.main)}
    render={() => (
      <PageLayout title={PageTitle.Labeling} pageName={PageName.LabelingPage}>
        <LabelingManagement />
      </PageLayout>
    )}
  />,
  // New labeling tool
  <Route
    exact
    key={CLEF_PATH.data.task}
    path={transformLegacyPath(currentPath, `${CLEF_PATH.data.task}/:taskId/`)}
    render={() => (
      <PageLayout hideSidebar hideHeader pageName={PageName.LabelingTask} fullScreenContent>
        <LabelingTask />
      </PageLayout>
    )}
  />,
  // Legacy labeling tool route,
  <Route
    exact
    key={CLEF_PATH.label.task}
    path={transformLegacyPath(currentPath, `${CLEF_PATH.label.task}/:taskId/`)}
    render={() => (
      <PageLayout hideSidebar hideHeader pageName={PageName.LabelingTool} fullScreenContent>
        <LabelingTask />
      </PageLayout>
    )}
  />,
  <Route
    exact
    key={CLEF_PATH.label.review}
    path={transformLegacyPath(currentPath, `${CLEF_PATH.label.review}/:taskId/`)}
    render={() => (
      <PageLayout hideSidebar hideHeader pageName={PageName.LabelingReview} fullScreenContent>
        <LabelingReview />
      </PageLayout>
    )}
  />,
  <Route
    exact
    key={CLEF_PATH.label.labelingQuorum}
    path={transformLegacyPath(currentPath, `${CLEF_PATH.label.labelingQuorum}/:taskId/`)}
    render={({ match }) => <Redirect to={`${CLEF_PATH.label.review}/${match.params.taskId}/`} />}
  />,
];

export default labelingRoutes;

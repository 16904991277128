import { useCallback } from 'react';
import { getDefectColor } from '../../utils/defect_utils';
import useGetDefectById from './useGetDefectById';

const useGetDefectColorById = () => {
  const getDefectById = useGetDefectById();

  return useCallback(
    (defectId: number) => {
      const foundDefect = getDefectById(defectId);
      return getDefectColor(foundDefect);
    },
    [getDefectById],
  );
};

export default useGetDefectColorById;

import { MediaCreateSpec, MetadataFormattedValue } from '@clef/shared/types';
import { FileWithPath } from 'react-dropzone';

export enum UploadStatus {
  NotStarted = 'NotStarted',
  Pending = 'Pending',
  Uploading = 'Uploading',
  Success = 'Success',
  Failure = 'Failure',
}

export enum UploadFailureReason {
  Duplicated = 'Duplicated',
  InvalidCharInFileName = 'InvalidCharacterInFileName',
  Other = 'Other',
}

export enum UploadStage {
  NotStarted = 'NotStarted',
  UploadInProgress = 'UploadInProgress',
  UploadCancelled = 'UploadCancelled',
  UploadFulfilled = 'UploadFulfilled',
  UploadFulfilledWithFailure = 'UploadFulfilledWithFailure',
}

export type UploadFile = {
  key: string;
  file: FileWithPath & { mediaId?: number | null };
  status: UploadStatus;
  failureReason?: UploadFailureReason | (string & {});
  progress: number;
  classifiedFolder?: string; // unique to classification classified upload
  initialLabel?: MediaCreateSpec['initialLabel'];
};

export enum UploadMediaType {
  SegmentationLabeledMedia = 'SegmentationLabeledMedia',
  SegmentationUnlabeledMedia = 'SegmentationUnlabeledMedia',
  Null = 'Null',
}

export type ClassifiedFolder = {
  folderName: string;
};

export type Split = string | null;

export type DefectMap = {
  key: string;
  file: FileWithPath;
  map: Record<string, string>;
} | null;

export type SegmentationMask = {
  key: string;
  file: FileWithPath;
  base64String: string;
};

export type UploadState = {
  uploadStage: UploadStage;
  uploadData: UploadFile[];
  classifiedFolders: ClassifiedFolder[]; // unique to classification classified upload
  uploadMediaType?: UploadMediaType;
  metadata?: MetadataFormattedValue;
  split?: Split;
  tags?: string[];
  defectMap?: DefectMap; // unique to segmentation labeled data upload
  segmentationMasks?: SegmentationMask[]; // unique to segmentation labeled data upload
};

import { MediaAllSplitName } from '@/constants/stats_card';
import { useDefectSelector } from '@/store/defectState/actions';
import { getDefectColor, statsCardDataKeyCompareFn } from '@/utils';
import { DistributionType, greyScale } from '@clef/client-library';
import { MediaStatsAPIResponse } from '@clef/shared/types';
import { useMemo } from 'react';
import seedColor from 'seed-color';

export const useFormattedSplitMediasForChart = (
  mediaSplitDistribution: MediaStatsAPIResponse[] | undefined,
) => {
  const splitStatsFormatted = useMemo(() => {
    if (!mediaSplitDistribution) return [];
    const formattedMediaSplitDistribution = mediaSplitDistribution
      .slice()
      .map(({ split, count }) => ({
        name: split || t('unassigned'),
        value: count,
        // color: splitColors[index % splitColors.length],
      }))
      .filter(({ value }) => value > 0);
    const allImagesCount = formattedMediaSplitDistribution.reduce((prev, cur) => {
      return prev + cur.value;
    }, 0);
    formattedMediaSplitDistribution.push({ name: MediaAllSplitName, value: allImagesCount });
    return formattedMediaSplitDistribution.sort((a, b) =>
      statsCardDataKeyCompareFn(a.name, b.name),
    );
  }, [mediaSplitDistribution]);
  // https://stackoverflow.com/questions/53420055/error-while-sorting-array-of-objects-cannot-assign-to-read-only-property-2-of/53420326

  return { splitStatsFormatted };
};
export const useFormattedSplitDefectsForChart = (
  mediaDefectDistribution: MediaStatsAPIResponse[] | undefined,
  enableCalcAll: boolean = false,
) => {
  const allDefects = useDefectSelector();
  const defectIdToName = useMemo(() => {
    return allDefects.reduce((acc, defect) => {
      acc[defect.id] = defect.name;
      return acc;
    }, {} as Record<number, string>);
  }, [allDefects]);

  const totalDefectsGroupBySplitDefect = useMemo(() => {
    if (!mediaDefectDistribution) {
      return undefined;
    }
    return mediaDefectDistribution.reduce((acc, cur) => {
      const { count: imageCount, defect_distribution, split } = cur;
      const splitName = split || t('Unassigned');
      Object.entries(defect_distribution || {}).forEach(([defectId, defectCount]) => {
        const defectName = (defectId && defectIdToName[Number(defectId)]) || t('No Classes');

        acc[splitName!] = acc[splitName!] || {};
        acc[splitName!][defectName] = acc[splitName!][defectName] || 0;
        acc[splitName!][defectName] += defectCount * imageCount;
        if (enableCalcAll) {
          acc[MediaAllSplitName] = acc[MediaAllSplitName] || {};
          acc[MediaAllSplitName][defectName] = acc[MediaAllSplitName][defectName] || 0;
          acc[MediaAllSplitName][defectName] += defectCount * imageCount;
        }
      });
      return acc;
    }, {} as { [splitName: string]: { [defectName: string]: number } });
  }, [defectIdToName, mediaDefectDistribution]);

  const totalDefectsFormatted = useMemo(() => {
    if (!totalDefectsGroupBySplitDefect) {
      return [];
    }
    const totalDefects = Object.entries(totalDefectsGroupBySplitDefect)
      // sort by name (train, dev, test, unassigned)
      .sort(([split1], [split2]) => statsCardDataKeyCompareFn(split1, split2))
      .map(([split, defectCountByDefectName]) => {
        const distributions: DistributionType[] = Object.entries(defectCountByDefectName)
          .map(
            ([defectName, defectCount]) =>
              ({
                distributor: defectName,
                value: defectCount,
              } as DistributionType),
          )
          .sort((a, b) => {
            // Always put 'No defect' distribution at the end
            if (a.distributor === t('No Classes')) return 1;
            if (b.distributor === t('No Classes')) return -1;
            return a.distributor > b.distributor ? 1 : -1;
          });
        const count = distributions.reduce((prev, cur) => prev + cur.value, 0);
        return {
          name: split || t('unassigned'),
          distributions,
          count,
        };
      });
    totalDefects.sort((a, b) => statsCardDataKeyCompareFn(a.name, b.name));
    return totalDefects;
  }, [totalDefectsGroupBySplitDefect]);

  const defectColorMap: { [key: string]: string } = allDefects
    .slice()
    // https://stackoverflow.com/questions/53420055/error-while-sorting-array-of-objects-cannot-assign-to-read-only-property-2-of/53420326
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .reduce(
      (acc, defect) => ({ ...acc, [defect.name]: getDefectColor(defect) }),
      Object.keys(defectIdToName ?? {}).reduce((acc, defectId) => {
        return {
          ...acc,
          [defectIdToName![Number(defectId)]]: seedColor(defectId).toHex(),
        };
      }, {}),
    );
  // Add t('No defects)' label to the end
  defectColorMap[t('No Classes')] = greyScale[200]!;
  return { totalDefectsGroupBySplitDefect, totalDefectsFormatted, defectColorMap };
};

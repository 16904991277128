import { VirtualGrid } from '@clef/client-library';
import { AnnotationInstance as AnnotationInstanceType } from '@clef/shared/types';
import React from 'react';
import { useDataBrowserState } from '../dataBrowserState';
import AnnotationInstance from './AnnotationInstance';

type InstanceGridProps = {
  instances?: AnnotationInstanceType[];
  disableVirtualGrid?: boolean;
  onInstanceClick?: (instance: AnnotationInstanceType) => void;
};

const InstanceGrid: React.FC<InstanceGridProps> = props => {
  const { instances, disableVirtualGrid, onInstanceClick } = props;
  const instancesWithId = (instances ?? []).map(instance => ({
    ...instance,
    id: `gt:${instance.groundTruthAnnotation?.id},pred:${instance.predictionAnnotation?.id}`,
  }));
  const { state } = useDataBrowserState();
  const { itemPerRowOffset } = state;

  return (
    <VirtualGrid
      disabled={disableVirtualGrid}
      imageRatio={0.75}
      componentList={instancesWithId}
      itemPerRowOffset={itemPerRowOffset}
    >
      {instance => (
        <AnnotationInstance
          key={instance.id}
          instance={instance}
          onClick={() => onInstanceClick?.(instance)}
        />
      )}
    </VirtualGrid>
  );
};

export default InstanceGrid;

import React from 'react';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { Typography, Grid } from '@material-ui/core';
import DropFileZone from '../components/DropFileZone';
import { useAppDispatch } from '../../../../store';
import { addFile } from '../../../../store/uploadState';
import UnclassifiedInstruction1 from '../../../../images/classification_media_upload/unclassified_instruction_1.png';
import UnclassifiedInstruction2 from '../../../../images/classification_media_upload/unclassified_instruction_2.png';
import { useUploadStyles } from '../styles';
import { ReachLimitException } from '../../../../store/uploadState/utils';
import { useDialog } from '../../../Layout/components/useDialog';
import { ExceedType } from '../../../Layout/components/UsageExceedDialog';

export interface UnclassifiedUploadDropFileZoneProps {
  fileCapacity?: number | null;
  fileLimit?: number | null;
  isNewCreditSystem?: boolean;
  disabled?: boolean;
}

const UnclassifiedUploadDropFileZone: React.FC<UnclassifiedUploadDropFileZoneProps> = ({
  fileCapacity,
  fileLimit,
  isNewCreditSystem,
  disabled,
}) => {
  const styles = useUploadStyles();
  const uploadData = useTypedSelector(state => state.uploadState.uploadData);

  const dispatch = useAppDispatch();
  const { showUsageExceedDialog } = useDialog();
  return (
    <DropFileZone
      onDrop={async files => {
        try {
          await dispatch(
            addFile({
              files,
              capacity: fileCapacity,
              limit: fileLimit,
              throwOnReachLimit: isNewCreditSystem,
            }),
          ).unwrap();
        } catch (e) {
          if (e instanceof ReachLimitException) {
            showUsageExceedDialog(ExceedType.Upload, e.fileCount, fileCapacity!);
          }
        }
      }}
      disabled={disabled}
      dataTestId="unclassified-image-upload-input"
    >
      <Typography variant="body1" component="div" className={styles.dropzoneText}>
        {t('Drag and drop ')}
        <strong>{t('folders/images ')}</strong>
        {t('here, or click to select files')}
      </Typography>
      {!uploadData.length && (
        <Grid container direction="row">
          <Grid container item xs={6} direction="column" alignItems="center">
            <img src={UnclassifiedInstruction1} className={styles.instructionImg} />
            <Typography className={styles.dropzoneText}>
              {t('We accept JPEG, PNG, BMP and MPO image format')}
            </Typography>
          </Grid>
          <Grid container item xs={6} direction="column" alignItems="center">
            <img src={UnclassifiedInstruction2} className={styles.instructionImg} />
            <Typography className={styles.dropzoneText}>
              {t('Folder information will be automatically tagged as metadata to each media')}
            </Typography>
          </Grid>
        </Grid>
      )}
    </DropFileZone>
  );
};

export default UnclassifiedUploadDropFileZone;

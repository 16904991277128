import { Box, Chip, CircularProgress, Grid, MenuItem, MenuList, Popover } from '@material-ui/core';
import React, { CSSProperties, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';
import Check from '@material-ui/icons/Check';
import ExpandMore from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { greyScale } from '../../themes/colors';

export type ClassifiedClass = { id: number; name: string; color: string };

export const useClassChipStyles = makeStyles(({ spacing, boxShadow, palette }) => ({
  classificationChip: {
    backgroundColor: 'rgba(30, 42, 55, 0.9)',
    boxShadow: boxShadow.default,
    color: '#ffffff',
    borderStyle: 'solid',
    borderWidth: 2,
    padding: spacing(0, 3),
    transition: 'border-width 0.2s',
    width: '100%',
    '& > svg': {
      width: 20,
      height: 20,
      marginRight: spacing(2),
    },
  },
  classificationChipLabel: {
    padding: spacing(0),
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
  },
  prediction: {
    '&:hover': {
      borderWidth: 3,
    },
  },
  loadingIcon: {
    margin: spacing(0, 1.5, 0, 0),
  },
  changeClassPopover: {
    backgroundColor: palette.grey[700],
    '& .MuiListItem-button:hover': {
      backgroundColor: palette.grey[600],
    },
  },
  classItemIcon: {
    width: 36,
  },
  expandIcon: {
    margin: spacing(0, -1, 0, 0.5),
  },
  selectItem: {
    fontSize: 12,
    paddingLeft: 15,
  },
  selectTitle: {
    color: '#fff',
    fontSize: 14,
    cursor: 'no-drop',
    fontWeight: 700,
    paddingLeft: 15,
    paddingBottom: spacing(1),
  },
  className: {
    flex: 1,
  },
  chipTextBox: {
    display: 'block',
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export type ClassChipProps = {
  classifiedClass?: ClassifiedClass | string;
  onClassChange?: (selectedClass: ClassifiedClass) => void;
  allClassifiedClasses?: ClassifiedClass[];
  enableColor?: boolean;
  dotted?: boolean;
  loading?: boolean;
  openCreateDialog?: () => void;
  statusIndicator?: {
    el: React.ReactNode;
    type: 'question' | 'success' | 'error';
  } | null;
  confidenceScore?: string | number;
  showDisabledGroundTruth?: boolean;
  setOpenTooltip?: (open: boolean) => void;
  style?: React.CSSProperties;
  gtClassifiedClass?: ClassifiedClass;
  hideMoreIcon?: boolean;
  showMoreIconWithInTask?: boolean;
  allowCreateClass?: boolean;
  hideClassInfo?: boolean;
  onClickClassInfo?: (defectId: number) => void;
};

const ClassChip: React.FC<ClassChipProps> = ({
  classifiedClass,
  onClassChange,
  allClassifiedClasses,
  enableColor,
  dotted,
  loading,
  openCreateDialog,
  statusIndicator,
  confidenceScore,
  showDisabledGroundTruth,
  setOpenTooltip,
  style: customStyle = {},
  gtClassifiedClass,
  hideMoreIcon = false,
  showMoreIconWithInTask = false,
  allowCreateClass = true,
  hideClassInfo = true,
  onClickClassInfo,
}) => {
  const styles = useClassChipStyles();
  const enableSetClass = !!onClassChange;

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'change-class-popup',
  });
  const chipStyles: CSSProperties = {};
  const hasClass = typeof classifiedClass === 'object' && !!classifiedClass;
  if (hasClass) {
    chipStyles.borderColor = enableColor ? classifiedClass.color : '#fff';
    chipStyles.color = enableColor ? classifiedClass.color : '#fff';
  } else {
    chipStyles.borderColor = 'transparent';
    chipStyles.color = '#CDD0D3';
    chipStyles.backgroundColor = `rgba(55, 65, 77, ${showMoreIconWithInTask ? 0.5 : 0.8})`;
  }
  if (dotted) {
    chipStyles.borderStyle = 'dotted';
  }
  const className =
    (typeof classifiedClass === 'string' ? classifiedClass : classifiedClass?.name) ?? '';
  const classId = gtClassifiedClass
    ? gtClassifiedClass?.id
    : typeof classifiedClass === 'string'
    ? undefined
    : classifiedClass?.id;
  const [hoveringMenuItemKey, setHoveringMenuItemKey] = useState<string | undefined>(undefined);

  return enableSetClass ? (
    <>
      <Chip
        data-testid="classification-label"
        classes={{
          label: styles.classificationChipLabel,
          root: styles.classificationChip,
        }}
        style={{ ...chipStyles, ...customStyle, cursor: 'pointer' }}
        icon={loading ? <CircularProgress size={20} className={styles.loadingIcon} /> : undefined}
        label={
          <Grid
            container
            alignItems="center"
            onMouseOver={() => {
              setOpenTooltip?.(true);
            }}
            onMouseOut={() => {
              setOpenTooltip?.(false);
            }}
            onClick={() => {
              setOpenTooltip?.(false);
            }}
          >
            {statusIndicator?.el && statusIndicator.el}
            <span style={{ marginLeft: 8, marginRight: 8 }} className={styles.chipTextBox}>
              {className}
            </span>
            {confidenceScore ? (
              <span>{confidenceScore}</span>
            ) : !hideMoreIcon ? (
              <ExpandMore className={styles.expandIcon} fontSize="small" />
            ) : null}
          </Grid>
        }
        {...bindTrigger(popupState)}
        // `clickable` only controls the UI but not the onClick event, see https://mui.com/api/chip/.
        // set to false because we don't want the focus styles on the Chip
        clickable={false}
        disabled={loading}
      />
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        classes={{ paper: styles.changeClassPopover }}
        style={{ top: '-12px' }}
        disableEnforceFocus
      >
        <MenuList>
          {showDisabledGroundTruth && (
            <Box
              className={styles.selectTitle}
              onMouseOver={() => {
                setOpenTooltip?.(false);
              }}
            >
              {t('Ground Truth:')}
            </Box>
          )}
          {allClassifiedClasses!.map(oneClassifiedClass => (
            <MenuItem
              key={oneClassifiedClass.name}
              onClick={() => {
                onClassChange?.(oneClassifiedClass);
                popupState.setOpen(false);
                setOpenTooltip?.(false);
              }}
              onMouseOver={() => {
                setOpenTooltip?.(false);
              }}
              onMouseEnter={() => {
                setHoveringMenuItemKey(oneClassifiedClass.name);
              }}
              onMouseLeave={() => {
                setHoveringMenuItemKey(undefined);
              }}
              style={{ color: enableColor ? oneClassifiedClass.color : '#ffffff' }}
              disabled={loading}
              className={styles.selectItem}
            >
              <Grid container justifyContent="flex-start">
                <Grid item className={styles.className}>
                  {oneClassifiedClass.name}
                </Grid>
                {hasClass ? (
                  <Grid item className={styles.classItemIcon} container justifyContent="center">
                    {oneClassifiedClass.id === classId && statusIndicator?.type !== 'question' ? (
                      <Check fontSize="small" />
                    ) : (
                      ''
                    )}
                  </Grid>
                ) : (
                  <Box pl={4} />
                )}
                {!hideClassInfo && (
                  <InfoOutlined
                    fontSize="small"
                    style={{
                      color: greyScale[500],
                      visibility:
                        hoveringMenuItemKey === oneClassifiedClass.name ? 'visible' : 'hidden',
                    }}
                    onClick={e => {
                      onClickClassInfo?.(oneClassifiedClass.id);
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  />
                )}
              </Grid>
            </MenuItem>
          ))}
          {allowCreateClass && (
            <MenuItem
              style={{ color: '#fff' }}
              onClick={() => {
                popupState.setOpen(false);
                openCreateDialog && openCreateDialog();
              }}
              className={styles.selectItem}
            >
              {t('Create New Class')}
            </MenuItem>
          )}
        </MenuList>
      </Popover>
    </>
  ) : (
    <Chip
      data-testid="classification-label"
      classes={{
        label: styles.classificationChipLabel,
        root: classNames(styles.classificationChip, {
          [styles.prediction]: !!statusIndicator,
        }),
      }}
      style={{
        ...chipStyles,
        ...customStyle,
      }}
      label={
        <Box
          onMouseOver={() => {
            setOpenTooltip?.(true);
          }}
          onMouseOut={() => {
            setOpenTooltip?.(false);
          }}
          display="flex"
          alignItems="center"
        >
          {statusIndicator && statusIndicator.el}
          <div style={{ marginLeft: 8, marginRight: 8 }} className={styles.chipTextBox}>
            {className}
          </div>
          {confidenceScore && confidenceScore}
          {showMoreIconWithInTask && <ExpandMore className={styles.expandIcon} fontSize="small" />}
        </Box>
      }
    />
  );
};

export default ClassChip;

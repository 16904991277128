import React, { useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import { useHistory } from 'react-router';
import CLEF_PATH from '@/constants/path';
import subscription_api from '@/api/subscription_api';
import { useSnackbar } from 'notistack';
import { useAppDispatch } from '@/store';
import { fetchOrgUserProfile } from '@/store/newLoginState/actions';
import Brightness1OutlinedIcon from '@material-ui/icons/Brightness1Outlined';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import classNames from 'classnames';
import { getPlanName } from '../plan/utils';
import { useCurrentSubscription } from '@/hooks/useSubscriptions';
import { startCase } from 'lodash';

const useStyles = makeStyles(theme => ({
  cancelPlanPage: {
    maxWidth: 1000,
    margin: 'auto',
  },
  title: {
    color: theme.palette.grey[800],
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '32px',
    textAlign: 'center',
  },
  subTitle: {
    color: theme.palette.grey[600],
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
  },
  checkboxItem: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
    padding: theme.spacing(1, 2, 1, 5),
    '& label': {
      width: '100%',
      marginRight: 0,
      marginLeft: 0,
    },
    '& span': {
      order: 2,
    },
    '& .MuiFormControlLabel-label': {
      order: 1,
      flex: 1,
    },
  },
  checkedItem: {
    backgroundColor: theme.palette.blue[25],
    borderWidth: 2,
    borderColor: theme.palette.blue[600],
    padding: '3px 8px 3px 20px',
  },
  reasonInput: {
    display: 'block',
    outline: 'none',
    flex: 1,
    height: 32,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '5px',
    marginLeft: theme.spacing(2),
  },
  checkboxIcon: {
    width: 20,
    height: 20,
  },
  button: {
    '&.MuiButton-containedPrimary': {
      backgroundColor: theme.palette.blue[600],
    },
  },
  reasonTextField: {
    width: '100%',
  },
  dialogActions: {
    paddingRight: 0,
  },
}));

export type CancelPlanPageProps = {};

const reasons: { label: string; input?: boolean }[] = [
  {
    label: t('Too expensive'),
  },
  {
    label: t('Model performance did not meet my expectations'),
  },
  {
    label: t('Project canceled'),
  },
  {
    label: t('Other'),
    input: true,
  },
];

const OtherReasonIndex = reasons.length - 1;

const CancelPlanPage: React.FC<CancelPlanPageProps> = () => {
  const styles = useStyles();
  const history = useHistory();
  const [checkedDescriptions, setCheckedDescriptions] = useState([] as number[]);
  const { enqueueSnackbar } = useSnackbar();
  const [canceling, setCanceling] = useState(false);
  const dispatch = useAppDispatch();
  const [selectedReason, setSelectedReason] = useState(0);
  const [openReasonDialog, setOpenReasonDialog] = useState(false);

  const subscription = useCurrentSubscription();
  const cancelDescriptions = useMemo(
    () => [
      t('You can still enjoy the {{plan}} plan benefits until the end of current billing cycle', {
        plan: startCase(getPlanName(subscription)),
      }),
      t('Starting next cycle, your organization will be on the Free plan'),
      t('You can reactivate your plan anytime'),
    ],
    [subscription],
  );
  const allDescriptionsChecked = checkedDescriptions.length === cancelDescriptions.length;

  const [improvement, setImprovement] = useState<string>();
  const [otherReason, setOtherReason] = useState<string>();

  // TODO: collect cancel reasons.
  return (
    <Box className={styles.cancelPlanPage}>
      <Box className={styles.title} marginBottom={8}>
        {t('Are you sure you want to cancel your plan?')}
      </Box>
      <Box marginBottom={8}>
        <Box className={styles.subTitle} marginBottom={4}>
          {t('Here’s what will happen after cancellation (check all to continue)')}
        </Box>
        <Box>
          {cancelDescriptions.map((text, index) => (
            <Box
              key={index}
              className={classNames(styles.checkboxItem, {
                [styles.checkedItem]: checkedDescriptions.includes(index),
              })}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedDescriptions.includes(index)}
                    color="primary"
                    icon={<Brightness1OutlinedIcon className={styles.checkboxIcon} />}
                    checkedIcon={<CheckCircleRoundedIcon className={styles.checkboxIcon} />}
                    onChange={() => {
                      if (checkedDescriptions.includes(index)) {
                        setCheckedDescriptions(prev => prev.filter(i => i !== index));
                      } else {
                        setCheckedDescriptions(prev => [...prev, index]);
                      }
                    }}
                  />
                }
                label={text}
                data-testid="cancel-description"
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box marginBottom={9}>
        <Box className={styles.subTitle} marginBottom={4}>
          {t('Reason for cancellation')}
        </Box>
        <Box>
          <RadioGroup
            onChange={(_, value) => {
              setSelectedReason(Number(value));
            }}
            value={`${selectedReason}`}
          >
            {reasons.map((r, index) => (
              <Box
                key={index}
                className={classNames(styles.checkboxItem, {
                  [styles.checkedItem]: selectedReason === index,
                })}
              >
                <FormControlLabel
                  value={index}
                  control={
                    <Radio size="small" color="primary" checked={selectedReason === index} />
                  }
                  label={
                    r.input ? (
                      <Box display="flex" alignItems="center">
                        <Typography>{r.label}</Typography>
                        <input
                          className={styles.reasonInput}
                          onFocus={() => setSelectedReason(OtherReasonIndex)}
                          onChange={e => setOtherReason(e.target.value)}
                        />
                      </Box>
                    ) : (
                      r.label
                    )
                  }
                />
              </Box>
            ))}
          </RadioGroup>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" marginBottom={6}>
        <Box marginBottom={2}>
          <Button
            id="do-not-cancel-plan"
            color="primary"
            variant="contained"
            onClick={() => {
              history.push(CLEF_PATH.organizationSettings);
            }}
            className={styles.button}
          >
            {t('Back to Plan and Billing')}
          </Button>
        </Box>
        <Box>
          <Button
            id="continue-to-cancel-plan"
            color="primary"
            disabled={!allDescriptionsChecked || canceling}
            startIcon={canceling && <CircularProgress size={20} />}
            onClick={async () => {
              setOpenReasonDialog(true);
            }}
          >
            {t('Continue to cancel')}
          </Button>
        </Box>
      </Box>
      <Dialog
        open={openReasonDialog}
        onClose={() => {
          setOpenReasonDialog(false);
        }}
      >
        <DialogTitle>{t("We're sorry to see you go!")}</DialogTitle>
        <DialogContent>
          <Typography>
            {t("We'd appreciate it if you would take a moment to let us know how we can improve.")}
          </Typography>
          <Box marginTop={2}>
            <TextareaAutosize
              className={styles.reasonTextField}
              data-testid="reason-text-field"
              minRows={8}
              onChange={e => setImprovement(e.target.value)}
            ></TextareaAutosize>
          </Box>
          <DialogActions className={styles.dialogActions}>
            <Button
              id="submit-feedback"
              startIcon={canceling && <CircularProgress size={20} />}
              disabled={canceling}
              onClick={async () => {
                setCanceling(true);
                try {
                  const cancelReason =
                    selectedReason === OtherReasonIndex
                      ? otherReason
                      : reasons[selectedReason]?.label;
                  await subscription_api.cancelPlan(cancelReason, improvement);
                  dispatch(fetchOrgUserProfile());
                  history.push(CLEF_PATH.organizationSettings);
                } catch (e) {
                  enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
                }
                setCanceling(false);
              }}
            >
              {t('Submit feedback')}
            </Button>
            <Button
              id="continue-plan"
              disabled={canceling}
              color="primary"
              variant="contained"
              className={styles.button}
              onClick={async () => {
                setOpenReasonDialog(false);
                history.push(CLEF_PATH.organizationSettings);
              }}
            >
              {t('I didn’t mean to cancel')}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CancelPlanPage;

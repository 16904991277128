import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import Check from '@material-ui/icons/Check';
import { SubscriptionName } from '@clef/shared/types';
import { useCurrentSubscription } from '@/hooks/useSubscriptions';

const useStyles = makeStyles(theme => ({
  planCard: {
    flex: 1,
    outline: '4px solid transparent',
    borderRadius: 20,
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer',
    transition: 'outline 0.2s',
    boxShadow: '0px 2px 4px 0px rgba(24, 39, 75, 0.12), 0px 4px 4px 0px rgba(24, 39, 75, 0.08)',
    '& $planNameWrapper': {
      backgroundColor: theme.palette.blue[500],
      color: 'white',
    },
    '& + $planCard': {
      marginLeft: theme.spacing(8),
    },
    '&.starter': {
      '& $planNameWrapper': {
        backgroundColor: '#A3C2FF',
        color: theme.palette.grey[900],
      },
      '&$planCardActive': {
        outline: `4px solid #4f86f6`,
      },
    },
    '&.enterprise': {
      '& $planNameWrapper': {
        backgroundColor: theme.palette.purple[500],
      },
      '&$planCardActive': {
        outline: `4px solid ${theme.palette.purple[600]}`,
      },
    },
  },
  features: {
    fontSize: 14,
    lineHeight: '20px',
  },
  planCardActive: {
    outline: '4px solid ' + theme.palette.blue[600],
  },
  basePlanText: {
    fontWeight: 700,
    lineHeight: '20px',
  },
  viewFullFeatureLink: {
    fontWeight: 700,
  },
  planNameWrapper: {
    padding: theme.spacing(6),
    borderRadius: 10,
  },
  planName: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '32px',
  },
  currentPlanText: {
    fontSize: 14,
    lineHeight: '20px',
    marginLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.blue[100],
    color: theme.palette.blue[700],
    padding: theme.spacing(0.5, 1),
    borderRadius: '5px',
    fontWeight: 700,
    '&.starter': {
      color: '#7289b8',
      backgroundColor: '#dfe7f6',
    },
    '&.enterprise': {
      backgroundColor: theme.palette.purple[100],
      color: theme.palette.purple[700],
    },
  },
  planSubtitle: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
}));

export type PlanInfo = {
  planType: SubscriptionName;
  planName: string;
  basedOnPlan?: string;
  features: string[];
  planSubtitle: string;
};

export type PlanCardProps = {
  planInfo: PlanInfo;
  active?: boolean;
  onClick?: () => void;
};

const PlanCard: React.FC<PlanCardProps> = props => {
  const { active, planInfo, onClick } = props;
  const styles = useStyles();
  const subscription = useCurrentSubscription();
  if (!subscription) {
    return null;
  }

  return (
    <Box
      className={classNames(styles.planCard, active && styles.planCardActive, planInfo.planType)}
      onClick={onClick}
      data-testid={`plan-item-${planInfo.planName}-${active ? 'active' : 'default'}`}
    >
      <Box className={styles.planNameWrapper}>
        <Box display="flex" alignItems="center">
          <Typography variant="h3" className={styles.planName}>
            {planInfo.planName}
          </Typography>
          {planInfo.planType === subscription.meta?.plan && (
            <Box className={classNames(styles.currentPlanText, planInfo.planType)}>
              {t('Current Plan')}
            </Box>
          )}
        </Box>
        <Box marginTop={2} className={styles.planSubtitle}>
          {planInfo.planSubtitle}
        </Box>
      </Box>
      <Box padding={6} paddingTop={4}>
        {planInfo.basedOnPlan && (
          <Box marginBottom={1.5} className={styles.basePlanText}>
            {t('Everything in {{basePlan}}, Plus', { basePlan: planInfo.basedOnPlan })}
          </Box>
        )}
        <Box className={styles.features}>
          {planInfo.features.map((feature, index) => (
            <Box key={index} marginBottom={1.5} display="flex" flexWrap="nowrap">
              <Check fontSize="small" />
              <Box marginLeft={1.5}>{feature}</Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PlanCard;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Divider, makeStyles, Tooltip, Typography } from '@material-ui/core';
import {
  DisplayMode,
  ImagesGroupInLabelingView,
} from '@/components/GroupedImages/components/ImagesGroupInLabelingView';
import { useVPLatestModel } from '@/serverStore/projectModels';
import { LabelStatus } from '@clef/shared/types';
import { useDataBrowserState } from '../DataBrowser/dataBrowserState';
import {
  calcItemPerRow,
  useLocalStorage,
  useWindowEventListener,
  ToggleButton,
} from '@clef/client-library';
import {
  useImageLabelingContext,
  useMediaList,
  useSaveAnnotations,
} from '@/components/Labeling/imageLabelingContext';
import { useInstantLearningState } from './state';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@clef/client-library';
import openInFull from '@/images/instant_learning/open_in_full.svg';
import closeFullScreen from '@/images/instant_learning/close_full_screen.svg';
import { calcOptimalRatio } from '@/pages/DataBrowser/MediaGrid/MediaGrid';

const CONTAINER_HORIZONTAL_MARGIN = 30;
const CONTAINER_VERTICAL_MARGIN = 14;
const DIVIDER_MARGIN = 25;
const DIVIDER_LINE = 1;

const getItemCountPerRow = () => Math.max(calcItemPerRow() - 2, 3);

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: `${CONTAINER_VERTICAL_MARGIN}px ${CONTAINER_HORIZONTAL_MARGIN}px`,
    background: theme.palette.common.white,
    width: '100%',
  },
  expandMoreButton: {
    zIndex: 1000,
    position: 'absolute',
    left: '50%',
    bottom: -34,
    transform: 'translateX(-50%)',
    background: theme.palette.common.white,
    width: 'auto',
    borderRadius: '0px 0px 20px 20px',
  },
  expandMoreButtonText: {
    marginLeft: theme.spacing(2),
  },
  popper: {
    pointerEvents: 'auto',
  },
  arrow: {
    left: '20px !important',
    color: theme.palette.blue[700],
  },
  tooltip: {
    backgroundColor: theme.palette.blue[700],
  },
  gotIt: {
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 700,
  },
  closeButton: {
    position: 'relative',
    paddingTop: '4px',
    right: '4px',
  },
  openInFull: {
    marginLeft: '16px',
    marginRight: '16px',
  },
  openInFullImg: {
    width: '14px',
  },
}));

export type BrowseImagesTopBarProps = {
  showGroundTruthLabels?: boolean;
  showPredictionLabeled?: boolean;
  showPredictionUnlabeled?: boolean;
  setShowGroundTruthLabels?(show: boolean): void;
  setShowPredictionLabels?(show: boolean, imageGroup: LabelStatus): void;
  closeBrowseImagesTopBar(): void;
};

export const BrowseImagesTopBar: React.FC<BrowseImagesTopBarProps> = ({
  showGroundTruthLabels,
  showPredictionLabeled,
  showPredictionUnlabeled,
  setShowGroundTruthLabels,
  setShowPredictionLabels,
  closeBrowseImagesTopBar,
}) => {
  const styles = useStyles();
  const { latestModel } = useVPLatestModel();
  const { id: latestModelId } = latestModel ?? {};
  const { dispatch } = useDataBrowserState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [itemPerRow, setItemPerRow] = useState(getItemCountPerRow());
  const [displayMode, setDisplayMode] = useState<DisplayMode>('partial');

  useWindowEventListener('resize', () => {
    setWindowWidth(window.innerWidth);
    setItemPerRow(getItemCountPerRow());
  });

  const mediaList = useMediaList();
  const { state, dispatch: dispatchImageLabelingState } = useImageLabelingContext();
  const saveAnnotations = useSaveAnnotations();

  const onImageClick = useCallback(
    (mediaId: number) => {
      const mediaIndex = mediaList.findIndex(m => m.id === mediaId);
      if (mediaIndex !== -1 && state.mediaIndex !== mediaIndex) {
        dispatchImageLabelingState(draft => {
          draft.mediaIndex = mediaIndex;
        });
        saveAnnotations();
      }
    },
    [dispatchImageLabelingState, mediaList, saveAnnotations],
  );

  const {
    state: { topbarImages },
  } = useInstantLearningState();

  const [showTooltip, setShowTooltip] = useLocalStorage(
    'show_instant_labeling_browser_images_tooltip',
  );
  const [openTooltip, setOpenTooltip] = useState(false);

  useEffect(() => {
    if (showTooltip) return;
    if (topbarImages.labeledGroupImages.length > 0) {
      setTimeout(() => {
        setShowTooltip(true);
        setOpenTooltip(true);
      }, 100);
    }
  }, [setShowTooltip, showTooltip, topbarImages.labeledGroupImages.length]);

  const expandMoreButton = useMemo(
    () => (
      <IconButton
        id="expand-more-images"
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setDisplayMode(value => (value === 'partial' ? 'full' : 'partial'))}
        className={styles.openInFull}
      >
        <img
          src={displayMode === 'partial' ? openInFull : closeFullScreen}
          className={styles.openInFullImg}
        />
      </IconButton>
    ),
    [],
  );

  const imageRatio = useMemo(() => {
    return calcOptimalRatio([
      ...topbarImages.labeledGroupImages,
      ...topbarImages.unlabeledGroupImages,
    ]);
  }, [topbarImages.labeledGroupImages, topbarImages.unlabeledGroupImages]);

  return (
    <Box className={styles.container}>
      <Box display="flex" width="100%">
        <Tooltip
          open={openTooltip}
          arrow
          placement="top-start"
          classes={{
            popper: styles.popper,
            arrow: styles.arrow,
            tooltip: styles.tooltip,
          }}
          title={
            <Box display="flex" alignItems="flex-end" flexDirection="column">
              <Typography>
                {t(
                  'You can browse and view predictions for more images from here, and pick the next one to iterate on.',
                )}
              </Typography>
              <Box
                onClick={() => {
                  setShowTooltip(true);
                  setOpenTooltip(false);
                }}
                className={styles.gotIt}
                marginTop={3}
              >
                {t('Got it')}
              </Box>
            </Box>
          }
        >
          <Box
            display="flex"
            style={{
              display: topbarImages.labeledGroupImages.length > 0 ? 'flex' : 'none',
            }}
          >
            <ImagesGroupInLabelingView
              group="labeled"
              displayMode={displayMode}
              // Labeled media section will take 1/3 window width
              itemPerRow={itemPerRow}
              width={
                (windowWidth -
                  CONTAINER_HORIZONTAL_MARGIN * 2 -
                  DIVIDER_MARGIN * 2 -
                  DIVIDER_LINE) /
                3
              }
              onImageClick={onImageClick}
              showGroundTruthLabels={showGroundTruthLabels}
              showPredictionLabels={!!latestModelId && showPredictionLabeled}
              toggleLabelButton={
                <>
                  {setShowGroundTruthLabels && (
                    <ToggleButton
                      id="toggle-ground-truth-labels"
                      isOn={showGroundTruthLabels}
                      onToggle={() => setShowGroundTruthLabels(!showGroundTruthLabels)}
                    >
                      {t('Labels')}
                    </ToggleButton>
                  )}

                  {latestModelId && setShowPredictionLabels && (
                    <ToggleButton
                      id="toggle-predictions-labels"
                      isOn={showPredictionLabeled}
                      onToggle={() => {
                        setShowPredictionLabels(!showPredictionLabeled, LabelStatus.Labeled);
                      }}
                    >
                      {t('Predictions')}
                    </ToggleButton>
                  )}
                </>
              }
              onPaginationChange={newPageIndex =>
                dispatch(draft => {
                  draft.pageIndex = newPageIndex - 1;
                })
              }
              imageRatio={imageRatio}
            />

            <Box marginX={`${DIVIDER_MARGIN}px`}>
              <Divider orientation="vertical" />
            </Box>
          </Box>
        </Tooltip>

        <ImagesGroupInLabelingView
          group="unlabeled"
          displayMode={displayMode}
          // Labeled media section will take 2/3 window width
          itemPerRow={itemPerRow * 2}
          width={
            ((windowWidth - CONTAINER_HORIZONTAL_MARGIN * 2 - DIVIDER_MARGIN * 2 - DIVIDER_LINE) *
              2) /
            3
          }
          onImageClick={onImageClick}
          showPredictionLabels={!!latestModelId && showPredictionUnlabeled}
          toggleLabelButton={
            setShowPredictionLabels && (
              <ToggleButton
                id="toggle-prediction-labels"
                isOn={showPredictionUnlabeled}
                onToggle={() =>
                  setShowPredictionLabels?.(!showPredictionUnlabeled, LabelStatus.Unlabeled)
                }
              >
                {t('Predictions')}
              </ToggleButton>
            )
          }
          expandMoreButton={expandMoreButton}
          onPaginationChange={newPageIndex =>
            dispatch(draft => {
              draft.secondaryPageIndex = newPageIndex - 1;
            })
          }
          imageRatio={imageRatio}
        />
      </Box>
      <Box className={styles.closeButton}>
        <IconButton
          id="close-browse-images-button"
          size="small"
          aria-label="close"
          color="inherit"
          onClick={closeBrowseImagesTopBar}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default BrowseImagesTopBar;

import { throttle, ThrottleSettings } from 'lodash';
import { useCallback, useRef } from 'react';

const useThrottle = <Params extends any[], Return>(
  cb: (...params: Params) => Return,
  delay: number,
  options?: ThrottleSettings,
) => {
  // use mutable ref to make useCallback/throttle not depend on `cb` dep
  const cbRef = useRef(cb);
  cbRef.current = cb;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    throttle((...args: Params) => cbRef.current(...args), delay, options),
    [delay],
  );
};

export default useThrottle;

import React, { useState, useCallback, useMemo } from 'react';
import { Typography, Menu, MenuItem, ListItemText, Tooltip } from '@material-ui/core';
import { Button } from '@clef/client-library';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { useNewUploadStyles } from '../styles';
import { useAppDispatch } from '../../../../store';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { switchMediaUploadType } from '../../../../store/uploadState';
import { UploadMediaType } from '../../../../store/uploadState/types';
import Row from '../../../Utils/Row';
import MediaUploadProgress from '../components/MediaUploadProgress';
import { useIsShowUploadLimitDialog } from '../../../../pages/DataBrowser/utils';
import UploadLimitDialog from '../../../../pages/DataBrowser/UploadLimitDialog';
import { addSkipDialogWithId, SkipDialogKey } from '../../../../utils/train';
import { useUploadMediaMutation } from '@/serverStore/upload';

export interface SegmentationUploadHeaderProps {
  mediaCount?: number;
  mediaLimit?: number | null;
  isNewCreditSystem?: boolean;
}

/**
 * The header component of uploading media for segmentation task, including page title,
 * upload media button, and dropdown button for selecting labeled/unlabeled data uploading
 */
const SegmentationUploadHeader: React.FC<SegmentationUploadHeaderProps> = ({
  mediaCount,
  mediaLimit,
  isNewCreditSystem,
}) => {
  const styles = useNewUploadStyles();
  const dispatch = useAppDispatch();

  const { uploadData, uploadMediaType, segmentationMasks, defectMap } = useTypedSelector(
    state => state.uploadState,
  );

  const { data: selectedProject } = useGetSelectedProjectQuery();

  const [dropdownButtonAnchorEl, setDropdownButtonAnchorEl] = useState<HTMLElement | null>(null);
  const isShowUploadLimitDialog = useIsShowUploadLimitDialog();
  const [openUploadLimitDialog, setOpenUploadLimitDialog] = useState(false);

  const onDropdownButtonClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setDropdownButtonAnchorEl(event.currentTarget);
  }, []);

  const onDropdownButtonClose = useCallback(() => {
    setDropdownButtonAnchorEl(null);
  }, []);

  const uploadMedia = useUploadMediaMutation();

  const hasRequiredUploadedFiles = useMemo(() => {
    // Labeled data uploading requires at least 1 image, 1 mask, and 1 defect map
    if (uploadMediaType === UploadMediaType.SegmentationLabeledMedia) {
      return !!(
        uploadData.length &&
        segmentationMasks &&
        segmentationMasks.length &&
        defectMap &&
        Object.keys(defectMap).length
      );
    }
    // Unlabeled data uploading requires at least 1 image
    else {
      return !!uploadData.length;
    }
  }, [defectMap, segmentationMasks, uploadData.length, uploadMediaType]);

  const hasUploadedFile = useMemo(() => {
    // Labeled data uploading requires at least 1 image, 1 mask, or 1 defect map
    if (uploadMediaType === UploadMediaType.SegmentationLabeledMedia) {
      return !!(
        uploadData.length ||
        (segmentationMasks && segmentationMasks.length) ||
        (defectMap && Object.keys(defectMap).length)
      );
    }
    // Unlabeled data uploading requires at least 1 image
    else {
      return !!uploadData.length;
    }
  }, [defectMap, segmentationMasks, uploadData.length, uploadMediaType]);

  const disabledUploadButtonReasons = useMemo(() => {
    const reasons: string[] = [];
    if (!uploadData.length) {
      reasons.push(t('Missing image files'));
    }

    if (
      uploadMediaType === UploadMediaType.SegmentationLabeledMedia &&
      (!segmentationMasks || !segmentationMasks.length)
    ) {
      reasons.push(t('Missing segmentation mask files'));
    }

    if (
      uploadMediaType === UploadMediaType.SegmentationLabeledMedia &&
      (!defectMap || !Object.keys(defectMap).length)
    ) {
      reasons.push(t('Missing or empty defect map'));
    }

    return (
      <>
        {reasons.map((reason, index) => {
          return <div key={index}>{reason}</div>;
        })}
      </>
    );
  }, [defectMap, segmentationMasks, uploadData.length, uploadMediaType]);

  return (
    <>
      <Row
        className={styles.headerRow}
        center={
          <Typography variant="h1" align="center" className={styles.headerText}>
            {t('Upload Images')}
          </Typography>
        }
        right={
          <Tooltip
            title={disabledUploadButtonReasons}
            disableFocusListener={hasRequiredUploadedFiles}
            disableHoverListener={hasRequiredUploadedFiles}
            disableTouchListener={hasRequiredUploadedFiles}
          >
            {/* A quick workaround to enable tooltip for a disabled button
                      Reference: https://stackoverflow.com/a/66713470 */}
            <span>
              <Button
                color="primary"
                variant="contained"
                data-testid="data_upload_files"
                id="upload-dialog-upload-button"
                disabled={!hasRequiredUploadedFiles}
                onClick={() => {
                  if (isShowUploadLimitDialog) {
                    setOpenUploadLimitDialog(true);
                    selectedProject &&
                      addSkipDialogWithId(SkipDialogKey.Upload, selectedProject.orgId);
                  } else {
                    uploadMedia.mutate();
                  }
                }}
              >
                {t('Upload {{count}} Image(s)', {
                  count: uploadData.length,
                })}
              </Button>
            </span>
          </Tooltip>
        }
      />

      <MediaUploadProgress
        mediaCount={mediaCount}
        mediaLimit={mediaLimit}
        isNewCreditSystem={isNewCreditSystem}
        className={styles.mediaCountProgressBar}
      />

      {!hasUploadedFile && (
        <Typography variant="h1" align="center" className={styles.headerText}>
          <Button
            id="segmentation_media_upload_type_menu"
            aria-controls="segmentation_media_upload_type_menu"
            aria-haspopup="true"
            variant="outlined"
            color="primary"
            onClick={onDropdownButtonClick}
            endIcon={<KeyboardArrowDownIcon />}
            data-testid="media_type_selection_dropdown_btn"
            className={styles.segmentationMediaTypeSelectionButton}
          >
            {uploadMediaType === UploadMediaType.SegmentationLabeledMedia
              ? t('Upload labeled images')
              : t('Upload unlabeled images')}
          </Button>

          <Menu
            elevation={0}
            // Required for placing the menu item below the dropdown button
            // https://github.com/mui/material-ui/issues/7961
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            id="segmentation_media_upload_type_menu"
            keepMounted
            anchorEl={dropdownButtonAnchorEl}
            open={!!dropdownButtonAnchorEl}
            onClose={onDropdownButtonClose}
            disablePortal
          >
            <MenuItem
              className={styles.segmentationMediaTypeSelectionDropdownItem}
              onClick={() => {
                dispatch(switchMediaUploadType(UploadMediaType.SegmentationUnlabeledMedia));
                onDropdownButtonClose();
              }}
              selected={uploadMediaType === UploadMediaType.SegmentationUnlabeledMedia}
            >
              <ListItemText primary={t('Upload unlabeled images')} />
            </MenuItem>

            <MenuItem
              className={styles.segmentationMediaTypeSelectionDropdownItem}
              onClick={() => {
                dispatch(switchMediaUploadType(UploadMediaType.SegmentationLabeledMedia));
                onDropdownButtonClose();
              }}
              selected={uploadMediaType === UploadMediaType.SegmentationLabeledMedia}
            >
              <ListItemText primary={t('Upload labeled images')} />
            </MenuItem>
          </Menu>
        </Typography>
      )}
      <UploadLimitDialog
        style={{ zIndex: 300002 }}
        open={openUploadLimitDialog}
        onGotIt={() => {
          setOpenUploadLimitDialog(false);
          uploadMedia.mutate();
        }}
      />
    </>
  );
};

export default SegmentationUploadHeader;

import { OptionsObject } from 'notistack';
export const NOT_IMPLEMENTED_SNACKBAR_STYLE: OptionsObject = {
  variant: 'warning',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

export const ERROR_SNACKBAR_STYLE: OptionsObject = {
  variant: 'error',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

export const SUCCESS_SNACKBAR_STYLE: OptionsObject = {
  variant: 'success',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

import { useCallback } from 'react';
import { useDataBrowserState } from '@/pages/DataBrowser/dataBrowserState';
import { useDefectSelector } from '@/store/defectState/actions';
import { Defect, DefectId } from '@clef/shared/types';
import ExperimentReportApi from '@/api/experiment_report_api';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';

const getDefectMapFromDefects = (defects: Defect[]) =>
  defects.reduce(
    (lookup, defect) => {
      if (defect.indexId == undefined) {
        return lookup;
      }

      lookup[defect.indexId] = { defectId: defect.id, name: defect.name };
      return lookup;
    },
    { 0: { name: 'ok' } } as Record<number, { name: string; defectId?: number }>,
  );

const getNoiseFilter = (
  allDefects: Defect[],
  TEMP_defectIdToSegmentationAreaThreshold: Record<DefectId, number>,
) =>
  allDefects.reduce((noiseFilter, defect) => {
    const threshold = TEMP_defectIdToSegmentationAreaThreshold[defect.id];
    if (threshold) {
      noiseFilter[defect.id] = isFinite(threshold) ? threshold || 0 : -1;
    } else {
      noiseFilter[defect.id] = 0;
    }

    return noiseFilter;
  }, {} as Record<number, number>);

export const useSavePostProcessingConfig = () => {
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const { state } = useDataBrowserState();
  const allDefects = useDefectSelector();

  const { TEMP_defectIdToSegmentationAreaThreshold, TEMP_imageLevelClassificationRuleCollection } =
    state;

  return useCallback(
    (defects: Defect[] = allDefects) => {
      if (
        !projectId ||
        !defects ||
        !TEMP_defectIdToSegmentationAreaThreshold ||
        !TEMP_imageLevelClassificationRuleCollection
      ) {
        return null;
      }

      const postProcessingConfig = {
        defectMap: getDefectMapFromDefects(defects),
        noiseFilter: getNoiseFilter(defects, TEMP_defectIdToSegmentationAreaThreshold),
        classificationRules: TEMP_imageLevelClassificationRuleCollection,
      };

      return ExperimentReportApi.savePostProcessing(projectId, postProcessingConfig);
    },
    [
      TEMP_defectIdToSegmentationAreaThreshold,
      TEMP_imageLevelClassificationRuleCollection,
      allDefects,
      projectId,
    ],
  );
};

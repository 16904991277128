import React from 'react';
import cx from 'classnames';
import { Typography, Fade } from '@material-ui/core';
import { DefaultFormProps } from '../../../types/client';

interface FormStatusProps extends DefaultFormProps {
  classes?: {
    errorMessage?: string;
    successMessage?: string;
  };
}

const FormStatus: React.FC<FormStatusProps> = ({ classes = {}, error, message, success }) => {
  return (
    <>
      {error && (
        <Fade in={error}>
          <Typography
            color="secondary"
            className={cx(classes.errorMessage, 'cy-form-status-error')}
          >
            {message || t('Something is wrong with your input :(')}
          </Typography>
        </Fade>
      )}
      {success && (
        <Fade in={success}>
          <Typography className={classes.successMessage}>{message}</Typography>
        </Fade>
      )}
    </>
  );
};

export default FormStatus;

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  divider: {
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
  },
  dialogPaper: {
    borderRadius: 20,
    maxHeight: 'calc(100vh - 64px)',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      height: 777,
    },
  },
  predictContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  predictWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: '40px',
    flex: 1,
  },
  predictCloseButton: {
    position: 'absolute',
    top: 13,
    right: 13,
  },
  predictTitleWrapper: {
    padding: 0,
    marginBottom: 20,
  },
  predictTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '28px',
  },
  predictContent: {
    padding: 0,
    flex: 1,
    height: 0,
  },
  startScreenRoot: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  startScreenImagesText: {
    color: theme.palette.grey[600],
    fontWeight: 500,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    marginBottom: 16,
    textAlign: 'center',
  },
  containerGrid: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
    width: '100%',
  },
  predictGrid: {
    height: 470,
    columnGap: 40,
    flexWrap: 'nowrap',
  },
  instantLearningFeaturedGridContainer: {
    height: 530,
  },
  instantLearningFeaturedGridContainerWithBanner: {
    height: 470,
  },
  instantLearningPredictGrid: {
    minHeight: '600px',
    height: 'calc(100% - 10px)',
    flexWrap: 'nowrap',
    columnGap: 40,
  },
  instantLearningPredictGridWithBanner: {
    minHeight: '560px',
    height: 'calc(100% - 78px - 10px)',
    flexWrap: 'nowrap',
    columnGap: 40,
  },
  featuredGrid: {
    display: 'flex',
  },
  predictSidebarSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  horizontalList: {
    marginTop: 16,
    columnGap: 12,
  },
  horizontalListActions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  horizontalListIconWrapper: {
    width: 40,
    height: 40,
    background: '#EFF1F5',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  imageList: {
    flexWrap: 'nowrap',
  },
  predictImageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    cursor: 'pointer',
    padding: 7,
    transformOrigin: 'center',
    '&:hover': {
      transform: 'scale(1.05)',
      background: '#F3F6F9',
      boxShadow:
        '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
      borderRadius: 10,
    },
  },
  predictImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  predictImageListItem: {
    borderRadius: 10,
    height: 100,
    width: 151,
  },
  imageListItemRoot: {
    maxHeight: 88,
    height: '100%',
    maxWidth: 104,
    width: '100%',
    marginRight: 6,
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  mediaViewerRoot: {
    background: '#F3F6F9',
    borderRadius: 8,
  },
  predictBanner: {
    left: '106px',
    top: '842px',
    background: '#F3F6F9',
    borderRadius: 20,
    marginTop: 20,
  },
  cameraIcon: {
    color: 'rgb(0, 34, 147)',
  },
  uploadButton: {
    backgroundColor: 'rgb(224, 234, 255)',
    color: 'rgb(0, 34, 147)',
    height: 40,
    '& + $uploadButton': {
      marginLeft: theme.spacing(3),
    },
  },
  dragAndDrop: {
    width: 400,
    height: 346,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
  fullHeight: {
    height: '100%',
  },
  infoIcon: {
    color: theme.palette.blue[200],
    width: 20,
    height: 20,
    marginLeft: theme.spacing(1),
  },
}));

export default useStyles;

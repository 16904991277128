import React from 'react';

export type LightBulbIconProps = {};

const LightBulbIcon: React.FC<LightBulbIconProps> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_5386_17756"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5386_17756)">
        <path
          d="M12 22C11.5667 22 11.175 21.896 10.825 21.688C10.475 21.4793 10.2 21.2 10 20.85C9.45 20.85 8.97933 20.6543 8.588 20.263C8.196 19.871 8 19.4 8 18.85V15.3C7.01667 14.65 6.22933 13.7917 5.638 12.725C5.046 11.6583 4.75 10.5 4.75 9.25C4.75 7.23333 5.454 5.52067 6.862 4.112C8.27067 2.704 9.98333 2 12 2C14.0167 2 15.729 2.704 17.137 4.112C18.5457 5.52067 19.25 7.23333 19.25 9.25C19.25 10.5333 18.9543 11.7 18.363 12.75C17.771 13.8 16.9833 14.65 16 15.3V18.85C16 19.4 15.8043 19.871 15.413 20.263C15.021 20.6543 14.55 20.85 14 20.85C13.8 21.2 13.525 21.4793 13.175 21.688C12.825 21.896 12.4333 22 12 22ZM10 18.85H14V17.95H10V18.85ZM10 16.95H14V16H10V16.95ZM9.8 14H11.25V11.3L9.05 9.1L10.1 8.05L12 9.95L13.9 8.05L14.95 9.1L12.75 11.3V14H14.2C15.1 13.5667 15.8333 12.929 16.4 12.087C16.9667 11.2457 17.25 10.3 17.25 9.25C17.25 7.78333 16.7417 6.54167 15.725 5.525C14.7083 4.50833 13.4667 4 12 4C10.5333 4 9.29167 4.50833 8.275 5.525C7.25833 6.54167 6.75 7.78333 6.75 9.25C6.75 10.3 7.03333 11.2457 7.6 12.087C8.16667 12.929 8.9 13.5667 9.8 14Z"
          fill="#697586"
        />
      </g>
    </svg>
  );
};

export default LightBulbIcon;

import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { Typography, Grid, Box, Link } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import { useSnackbar } from 'notistack';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import cx from 'classnames';

import { useStyles } from './newStyles';
import AuthAPI from '../../api/auth_api';
import { LandingLensLogo } from '../../components/Auth/Common';
import { EmailTextField } from '../../components/Auth/TextField';
import { PrimaryButton } from '../../components/Auth/Button';
import { CLEF_PATH } from '../../constants/path';
import AuthPageLayout from '../../components/Layout/AuthPageLayout';

interface FormValues {
  email: string;
}

export const ForgetPassword: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const { control, errors, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = useCallback(
    async ({ email }: FormValues) => {
      setIsSendingEmail(true);

      try {
        await AuthAPI.newForgotPasswordCode(email);
        enqueueSnackbar('Check your inbox for an email with reset instructions.', {
          variant: 'success',
        });

        history.push(`${CLEF_PATH.login.forgetPasswordEmailDelivered}`);
      } catch (e) {
        enqueueSnackbar(e?.message, { variant: 'error', autoHideDuration: 12000 });
      } finally {
        setIsSendingEmail(false);
      }
    },
    [enqueueSnackbar, history],
  );

  return (
    <AuthPageLayout metaTitle={t('Forget Password')}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={styles.container}
      >
        <LandingLensLogo />

        <IconButton
          id="go-back"
          onClick={() => {
            history.goBack();
          }}
          className={styles.backButton}
        >
          <ArrowBackIcon />
        </IconButton>

        <Typography variant="h1" className={styles.helperText}>
          {t('Enter Your Work Email')}
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <EmailTextField
            error={errors?.email}
            control={control}
            className={styles.marginBottom8}
          />

          <PrimaryButton
            id="send-password-reset-link"
            text={t('Reset Password')}
            className={styles.marginBottom6}
            disabled={!!errors.email}
            isPending={isSendingEmail}
            useNewUi
          />

          <Link
            className={cx(styles.link, styles.newLink)}
            onClick={() => history.push(CLEF_PATH.login.main)}
          >
            {t('Return to Sign In')}
          </Link>
        </Box>
      </Grid>
    </AuthPageLayout>
  );
};

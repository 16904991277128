import {
  ApiResponseLoader,
  Button,
  BottomDrawer,
  useStateSyncSearchParams,
  IconButton,
} from '@clef/client-library';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import {
  DataBrowserStateContext,
  getSelectedMediaCount,
  getStateFromStorage,
  saveStateToStorage,
  appliedFilterMappingToFormattedFilterMapping,
  getColumnFilterMapWithModelId,
} from './dataBrowserState';
import MediaSelectionActions from './MediaSelectionActions';
import { useImmer } from 'use-immer';
import { SidebarWidthV2, useDataBrowserStyles } from './styles';
import { Box, LinearProgress, Tooltip, Typography } from '@material-ui/core';
import TrainModelButtonGroup from './TrainModelButtonGroup';
import { useHasPermission } from '../../hooks/useProjectRolePermissions';
import {
  AnnotationInstance,
  LabelType,
  Media,
  MediaId,
  MediaStatusType,
  UserPermission,
} from '@clef/shared/types';
import { useDialog } from '../../components/Layout/components/useDialog';
import { useFastNEasyEnabled } from '../../hooks/useFeatureGate';
import MediaGrid from './MediaGrid/MediaGrid';
import { omit } from 'lodash';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import { isModelTrainingSuccessful } from '../../store/projectModelInfoState/utils';
import Toolbar from './Toolbar';
import {
  useAnnotationInstances,
  useDatasetMedias,
  useTotalMediaCount,
  useTotalMediaOrInstanceCount,
} from './utils';
import cx from 'classnames';
import InstanceGrid from './InstanceGrid';
import ManageSplitDialog from '../../components/StatsCard/ManageSplitDialog';
import MoreButton from './MoreButton';
import SnapshotActionButton from './SnapshotActionButton';
import FREEmptyPage from './FREEmptyPage/FREEmptyPage';
import { useSnackbar } from 'notistack';
import DataBrowserUploadDropZone from './DataBrowserUploadDropZone';
import { useWorkflowAssistantState } from '../../components/WorkflowAssistant/state';
import { StepName } from '../../types/client';
import Pagination from '@material-ui/lab/Pagination';
import BarChartIcon from '@material-ui/icons/BarChart';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { DataSummaryDialog } from './DataSummaryDialog';
import classNames from 'classnames';
import { PredictContextProvider } from '@/components/Predict/predictContext';
import CLEF_PATH from '../../constants/path';
import ModelPerformance from './ModelPerformance';
import { useUpdateProjectMutation } from '@/serverStore/projects';
import { useAtom, useSetAtom } from 'jotai';
import { initLabelTogglesAtom } from '@/uiStates/mediaDetails/labelToggles';
import {
  datasetSearchParamsAtom,
  annotationInstanceAtom,
} from '@/uiStates/mediaDetails/pageUIStates';
import { createSnapshotDialogModeAtom } from '@/uiStates/datasetSnapshot/pageUIStates';
import { useModelStatusQuery } from '@/serverStore/projectModels';
import { CreateSnapshotDialog } from './CreateSnapshotDialog';
import SnowflowIcon from '@/images/data-browser/snowflake-transparent.png';
import { SnowflakeSyncDataDialog } from '@/components/Dialogs/SnowflakeSyncDataDialog';
import LabelControlBar from './LabelControlBar';
import ModelList from './ModelPerformance/ModelList';
import SnowflakeSyncIndicator from '@/components/Layout/components/Header/SnowflakeSyncIndicator';

const FREDataBrowser: React.FC<{}> = () => {
  const styles = useDataBrowserStyles();
  const history = useHistory();

  const { id: projectId, datasetId, labelType } = useGetSelectedProjectQuery().data ?? {};
  const [state, dispatch] = useImmer(omit(getStateFromStorage(projectId), 'labelDisplay'));
  const [dataInsightsDialogOpen, setDataInsightsDialogOpen] = useState(false);

  const { appliedFilters, rightSidebar, viewMode, sortField } = state;
  const totalMediaOrInstanceCount = useTotalMediaOrInstanceCount(state);
  const [filterSearchParam, setFilterSearchParam] = useStateSyncSearchParams('filters', '');
  // initialPageIndexSearchParam is used for restoring page index when returning from media_details page
  const [initialPageIndexSearchParam, setInitialPageIndexSearchParam] = useStateSyncSearchParams(
    'initialPageIndex',
    '',
  );
  const [isManageSplitDialogOpen, setManageSplitDialogOpen] = useState(false);
  const {
    state: { goButtonClicked, step },
    dispatch: workflowAssitantDispatch,
  } = useWorkflowAssistantState();
  const { enqueueSnackbar } = useSnackbar();
  const [openSnowflowSyncDialog, setOpenSnowflowSyncDialog] = useState(false);

  const initLabelToggles = useSetAtom(initLabelTogglesAtom);
  const setDatasetSearchParams = useSetAtom(datasetSearchParamsAtom);
  const setAnnotationInstance = useSetAtom(annotationInstanceAtom);

  const [createSnapshotDialogMode] = useAtom(createSnapshotDialogModeAtom);

  useEffect(() => {
    try {
      const filterDecoded = JSON.parse(decodeURIComponent(filterSearchParam));
      dispatch(draft => {
        draft.appliedFilters = filterDecoded;
      });
    } catch (e) {
      setFilterSearchParam(encodeURIComponent(JSON.stringify({})));
    }
    // only do this on mount, afterwards appliedFilters can be only changed from user
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      const pageIndexDecoded = JSON.parse(decodeURIComponent(initialPageIndexSearchParam));
      if (pageIndexDecoded) {
        dispatch(draft => {
          draft.pageIndex = pageIndexDecoded;
        });
      }
    } catch (e) {
      setInitialPageIndexSearchParam('');
    }
    // only do this on mount, afterwards page index can be only changed from user
  }, []);

  const { data: totalMediaCount } = useTotalMediaCount();
  // Store total media count
  useEffect(() => {
    if (totalMediaCount) {
      dispatch(draft => {
        draft.totalMediaCount = totalMediaCount;
      });
    }
  }, [dispatch, totalMediaCount]);

  const selectedMediaCount = totalMediaOrInstanceCount ? getSelectedMediaCount(state) : 0;

  // On appliedFilters change, do 2 things
  // 1. Reset selected media when filters change
  // 2. Save filters to FilterSearchParam
  useEffect(() => {
    dispatch(draft => {
      draft.selectingMediaMode = 'select';
      draft.selectedOrUnselectedMedia = [];
    });
    setFilterSearchParam(encodeURIComponent(JSON.stringify(appliedFilters)));
  }, [dispatch, appliedFilters, setFilterSearchParam]);

  // save to storage
  useEffect(() => {
    projectId && saveStateToStorage(projectId, state);
  }, [projectId, state]);

  // Reset pageIndex to 0 when filter or sortField or mode changed
  useEffect(() => {
    // remove initialPageIndex in url after use
    if (initialPageIndexSearchParam) {
      setInitialPageIndexSearchParam('');
    } else {
      dispatch(draft => {
        draft.pageIndex = 0;
      });
    }
  }, [appliedFilters, dispatch, sortField, viewMode]);

  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  const { data: modelStatusRes } = useModelStatusQuery(projectId, currentModelId);
  const { status: modelStatus, metricsReady } = modelStatusRes ?? {};
  const fastEasyModelSuccessful = isModelTrainingSuccessful(modelStatus, metricsReady);
  // In any case, if there is no successful model, we need to fall back to show ground truth in data browser
  useEffect(() => {
    dispatch(draft => {
      if (viewMode === 'instance') {
        draft.showGroundTruth = true;
        draft.showPredictions = false;
      } else {
        if (labelType === LabelType.Classification) {
          if (fastEasyModelSuccessful) {
            draft.showPredictions = true;
            draft.showGroundTruth = false;
          } else {
            draft.showGroundTruth = true;
            draft.showPredictions = false;
          }
        } else {
          draft.showGroundTruth = true;
          draft.showPredictions = !!fastEasyModelSuccessful;
        }
      }
    });
  }, [dispatch, modelStatus, labelType, fastEasyModelSuccessful, viewMode]);

  const {
    data: datasetMedias,
    isLoading: datasetMediasLoading,
    error: datasetMediasError,
  } = useDatasetMedias(state);
  const {
    data: annotationInstances,
    isLoading: annotationInstancesLoading,
    error: annotationInstancesError,
  } = useAnnotationInstances(state);

  const [mediasOrInstances, mediasOrInstancesLoading, mediasOrInstancesError] = useMemo(() => {
    return viewMode === 'image'
      ? [datasetMedias, datasetMediasLoading, datasetMediasError]
      : [annotationInstances, annotationInstancesLoading, annotationInstancesError];
  }, [
    viewMode,
    datasetMedias,
    datasetMediasLoading,
    datasetMediasError,
    annotationInstances,
    annotationInstancesLoading,
    annotationInstancesError,
  ]);

  const canUploadData = useHasPermission(UserPermission.UploadData);
  const canTrainModel = useHasPermission(UserPermission.TrainModel);

  const { openUpload } = useDialog();
  const fastNEasySupported = useFastNEasyEnabled();

  // below are assembling the parameters related to media details page
  const [columnFilterMap, metadataFilterMap] = useMemo(() => {
    const [col, metadata] = appliedFilterMappingToFormattedFilterMapping(appliedFilters);
    const colWithModelId = getColumnFilterMapWithModelId(col, currentModelId);
    return [colWithModelId, metadata];
  }, [appliedFilters, currentModelId]);

  const listParamsForDetailsPage = useMemo(() => {
    return {
      sortOptions: {
        ...sortField,
        offset: state.pageIndex * state.paginationLimit,
        limit: state.paginationLimit,
      },
      columnFilterMap,
      metadataFilterMap: Object.fromEntries(
        Object.entries(metadataFilterMap).filter(([, value]) => {
          return Object.values(value).some(subValue => {
            return Array.isArray(subValue) ? subValue.length > 0 : !!subValue;
          });
        }),
      ),
    };
  }, [sortField, state.pageIndex, state.paginationLimit, columnFilterMap, metadataFilterMap]);

  // If go button in workflow assistant is clicked, and the current step is label, then open the media details dialog,
  // and mark go button unclicked
  useEffect(() => {
    if (goButtonClicked && step?.stepName === StepName.Label && datasetMedias) {
      const firstUnlabeledMedia = datasetMedias?.find(
        media => media.mediaStatus === MediaStatusType.Raw,
      );

      if (firstUnlabeledMedia) {
        initLabelToggles({ showHeatmap: state.showHeatmap });
        setDatasetSearchParams(listParamsForDetailsPage);
        history.push(
          `${CLEF_PATH.data.mediaDetails}/${firstUnlabeledMedia.id}${
            currentModelId ? `?modelId=${currentModelId}` : ''
          }`,
        );

        workflowAssitantDispatch(state => {
          state.goButtonClicked = false;
        });
      } else {
        enqueueSnackbar('All images were labeled. Please upload more images to continue labeling', {
          variant: 'warning',
        });
      }
    }
  }, [
    datasetMedias,
    enqueueSnackbar,
    goButtonClicked,
    step?.stepName,
    workflowAssitantDispatch,
    history,
    listParamsForDetailsPage,
    initLabelToggles,
    state.showHeatmap,
    setDatasetSearchParams,
    currentModelId,
  ]);

  const updateProject = useUpdateProjectMutation();
  const [openTootip, setOpenToolTip] = useState(false);

  const moreButtonCommonAttrs = {
    onMouseEnter: () => {
      setOpenToolTip(true);
    },
    onMouseLeave: () => {
      setOpenToolTip(false);
    },
    onClick: (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      setOpenToolTip(false);
    },
  };

  if (!projectId) {
    return null;
  }

  return (
    <DataBrowserStateContext.Provider value={{ state, dispatch }}>
      <PredictContextProvider
        projectId={projectId}
        datasetId={datasetId}
        labelType={labelType}
        modelId={currentModelId}
      >
        {!datasetMediasLoading &&
        // If there is error, leave the following normal logic to handle
        !datasetMediasError &&
        !datasetMedias?.length &&
        !Object.entries(appliedFilters).length &&
        viewMode === 'image' ? (
          <Box paddingRight={rightSidebar === 'model_performance' ? SidebarWidthV2 + 'px' : 0}>
            <Box
              className={classNames(
                styles.topBar,
                styles.freTopBar,
                rightSidebar === 'model_performance' && 'sideBarV2Opened',
              )}
            >
              <div className={styles.topActionButton}>
                <Tooltip title={t('More actions')} open={openTootip} placement="top" arrow>
                  <Box {...moreButtonCommonAttrs}>
                    <MoreButton onlyClassesAndMetadata />
                  </Box>
                </Tooltip>
                {process.env.IS_SNOWFLAKE === 'true' && (
                  <>
                    <Button
                      id="snowflake-stage-button"
                      data-testid="data-browser-snowflake-stage-button"
                      variant="outlined"
                      startIcon={<img src={SnowflowIcon} width={20} height={20} />}
                      onClick={() => setOpenSnowflowSyncDialog(true)}
                    >
                      <Typography>{t('Sync Snowflake Data')}</Typography>
                    </Button>
                    <SnowflakeSyncIndicator />
                  </>
                )}
              </div>
            </Box>

            <FREEmptyPage
              changeLabelType={async newLabelType => {
                if (newLabelType === labelType) {
                  return;
                }
                updateProject.mutate(
                  {
                    id: projectId,
                    labelType: newLabelType,
                  },
                  {
                    onError: e =>
                      enqueueSnackbar((e as Error).message, {
                        variant: 'error',
                        autoHideDuration: 12000,
                      }),
                  },
                );
              }}
              changingLabelType={updateProject.isLoading}
              withRightPanel={rightSidebar === 'model_performance'}
            />
            <ModelPerformance />
            {process.env.IS_SNOWFLAKE === 'true' && openSnowflowSyncDialog && (
              <SnowflakeSyncDataDialog
                showClassificationToggle={labelType === LabelType.Classification}
                open={openSnowflowSyncDialog}
                onClose={() => setOpenSnowflowSyncDialog(false)}
              />
            )}
          </Box>
        ) : (
          <>
            <DataBrowserUploadDropZone
              disabled={state.isFirstRunLiveExperienceModalOpen}
              className={cx({
                [styles.dataBrowserContentWrapper]: true,
                [styles.customizedScrollbar]: true,
                [styles.wrapperWithMR]: rightSidebar === 'model_performance',
              })}
            >
              <Box mr={rightSidebar === 'model_performance' ? 5 : 0}>
                {/*  Bottom Drawer - Media Selection Actions */}
                <BottomDrawer
                  show={!!selectedMediaCount}
                  className={cx(
                    styles.scrollStickyContainer,
                    styles.newTopDrawer,
                    rightSidebar && styles.shrinkedBottomDrawerWidthV2,
                  )}
                >
                  <MediaSelectionActions
                    style={{
                      width: `calc(80vw - 400px - ${rightSidebar ? SidebarWidthV2 : 0}px)`,
                    }}
                  />
                </BottomDrawer>

                <Box
                  position="absolute"
                  top={28}
                  left={110}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant="body1"
                    component="div"
                    className={styles.newMediaCountText}
                    data-testid={'databrowser-total-medias'}
                  >
                    {viewMode === 'image'
                      ? t('{{mediaCount}} images', { mediaCount: totalMediaOrInstanceCount })
                      : t('{{count}} instances', { count: totalMediaOrInstanceCount })}
                  </Typography>

                  <IconButton
                    id="data-summary-button"
                    test-dataid="data-summary-button"
                    size="small"
                    tooltip={t('Displays a summary of your labels and label distributions.')}
                    onClick={() => {
                      setDataInsightsDialogOpen(true);
                    }}
                  >
                    <BarChartIcon />
                  </IconButton>
                </Box>

                {/* Upload & Train */}
                <Box
                  justifyContent="space-between"
                  alignItems="center"
                  className={cx(
                    styles.topBar,
                    styles.freTopBar,
                    rightSidebar === 'model_performance' && 'sideBarV2Opened',
                  )}
                >
                  <div className={styles.topActionButton}>
                    <Tooltip open={openTootip} title={t('More actions')} placement="top" arrow>
                      <Box {...moreButtonCommonAttrs}>
                        <MoreButton />
                      </Box>
                    </Tooltip>
                    <SnapshotActionButton />
                    {canUploadData && (
                      <IconButton
                        id="upload-image-button"
                        onClick={() => openUpload()}
                        data-testid="data-browser-upload-btn"
                        className={styles.uploadIconButton}
                        tooltip={t('Select and upload images from a directory.')}
                      >
                        <CloudUploadOutlinedIcon className={styles.uploadIcon} />
                      </IconButton>
                    )}
                    {process.env.IS_SNOWFLAKE === 'true' && (
                      <>
                        <Button
                          id="snowflake-stage-button"
                          data-testid="data-browser-snowflake-stage-button"
                          variant="outlined"
                          startIcon={<img src={SnowflowIcon} width={20} height={20} />}
                          onClick={() => setOpenSnowflowSyncDialog(true)}
                        >
                          <Typography>{t('Sync Snowflake Data')}</Typography>
                        </Button>
                        <SnowflakeSyncIndicator />
                      </>
                    )}
                    {fastNEasySupported && canTrainModel && <TrainModelButtonGroup />}
                  </div>
                  {process.env.IS_SNOWFLAKE === 'true' && openSnowflowSyncDialog && (
                    <SnowflakeSyncDataDialog
                      showClassificationToggle={labelType === LabelType.Classification}
                      open={openSnowflowSyncDialog}
                      onClose={() => setOpenSnowflowSyncDialog(false)}
                    />
                  )}
                </Box>
                {/*
                tool bar:
                Image/instance view, filter, sort, data insights, model performance
                Ground truth / prediction, select all, image size etc.
              */}
                <Toolbar />
                {labelType !== LabelType.AnomalyDetection ? <LabelControlBar /> : <Box mb={4} />}

                {/* media grid */}
                <ApiResponseLoader
                  response={mediasOrInstances}
                  loading={mediasOrInstancesLoading}
                  error={mediasOrInstancesError}
                  defaultHeight={500}
                >
                  {mediasOrInstances => {
                    return mediasOrInstances.length ? (
                      <Box position="relative">
                        {/* this progress is for loading with stale and refresh */}
                        {mediasOrInstancesLoading && (
                          <LinearProgress className={styles.loadingImagesProgress} />
                        )}
                        {viewMode === 'image' ? (
                          <MediaGrid
                            medias={mediasOrInstances as Media[]}
                            onInfoClick={(mediaId: MediaId) => {
                              initLabelToggles({ showHeatmap: state.showHeatmap });
                              setDatasetSearchParams(listParamsForDetailsPage);
                              history.push(
                                `${CLEF_PATH.data.mediaDetails}/${mediaId}${
                                  currentModelId ? `?modelId=${currentModelId}` : ''
                                }`,
                              );
                            }}
                            showClassChip
                          />
                        ) : (
                          <InstanceGrid
                            instances={mediasOrInstances as AnnotationInstance[]}
                            onInstanceClick={(instance: AnnotationInstance) => {
                              initLabelToggles({ showHeatmap: state.showHeatmap });
                              setDatasetSearchParams(listParamsForDetailsPage);
                              setAnnotationInstance(instance);
                              history.push(
                                `${CLEF_PATH.data.mediaDetails}/${instance.mediaId}${
                                  currentModelId ? `?modelId=${currentModelId}` : ''
                                }`,
                              );
                            }}
                          />
                        )}
                      </Box>
                    ) : (
                      <Typography
                        variant="subtitle1"
                        component="div"
                        gutterBottom
                        className={styles.hintTextNormal}
                        data-test-id="no-medias-found-data-browser"
                      >
                        {Object.entries(appliedFilters).length ? (
                          <>
                            {/* No media, non match filter condition */}
                            {viewMode === 'image' ? t('No image found') : t('No instance found')}
                            <Button
                              color="secondary"
                              size="small"
                              id="data-browser-empty-upload-button"
                              className={styles.textButton}
                              onClick={() =>
                                dispatch(draft => {
                                  draft.appliedFilters = {};
                                })
                              }
                            >
                              {t('Clear filters')}
                            </Button>
                          </>
                        ) : (
                          <>
                            {/* No media, none uploaded */}
                            {t('No instance found')}
                            {canUploadData && (
                              <Button
                                id="empty-state-upload-image-button"
                                color="primary"
                                size="small"
                                className={styles.textButton}
                                onClick={() => openUpload()}
                              >
                                {t('Upload Media')}
                              </Button>
                            )}
                          </>
                        )}
                      </Typography>
                    );
                  }}
                </ApiResponseLoader>

                <Pagination
                  data-testid="databrowser-bottom-pagination"
                  count={Math.ceil((totalMediaOrInstanceCount ?? 0) / state.paginationLimit)}
                  color="primary"
                  size="small"
                  page={state.pageIndex + 1}
                  onChange={(_, newPageIndex) =>
                    dispatch(draft => {
                      draft.pageIndex = newPageIndex - 1;
                    })
                  }
                  className={styles.pagination}
                />
                <Box height={16} />
              </Box>

              {/* Auto split dialog */}
              {/* {isAutoSplitDialogOpen && <AutoSplitDialog onClose={() => setAutoSplitDialogOpen(false)} />} */}
              {isManageSplitDialogOpen && (
                <ManageSplitDialog
                  onClose={() => {
                    setManageSplitDialogOpen(false);
                  }}
                />
              )}

              {createSnapshotDialogMode !== null && <CreateSnapshotDialog />}

              <DataSummaryDialog
                open={dataInsightsDialogOpen}
                onClose={() => {
                  setDataInsightsDialogOpen(false);
                }}
              />
            </DataBrowserUploadDropZone>
            {rightSidebar === 'model_performance' && <ModelList />}
          </>
        )}
      </PredictContextProvider>
    </DataBrowserStateContext.Provider>
  );
};

export default FREDataBrowser;

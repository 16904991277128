import { ProjectId, LabelType, ModelArchSchemaItem } from '@clef/shared/types';
import { useQuery } from '@tanstack/react-query';
import ExperimentReportApi from '@/api/experiment_report_api';
import { ApiErrorType } from '@/api/base_api';
import { useGetSelectedProjectQuery } from '../projects';

const trainQueryKey = {
  all: ['train'] as const,
  modelArchSchemas: (projectId: ProjectId, labelType: LabelType) =>
    [...trainQueryKey.all, 'modelArchSchemas', projectId, labelType] as const,
};

export const useGetModelArchSchemas = () => {
  const { id: projectId, labelType } = useGetSelectedProjectQuery().data ?? {};
  return useQuery<ModelArchSchemaItem[], ApiErrorType>({
    queryKey: trainQueryKey.modelArchSchemas(projectId!, labelType!),
    queryFn: async () => {
      const response = await ExperimentReportApi.getModelArchSchemas(projectId!, labelType!);
      return response;
    },
    enabled:
      !!projectId &&
      !!labelType &&
      labelType !== LabelType.AnomalyDetection &&
      labelType !== LabelType.SegmentationInstantLearning,
  });
};

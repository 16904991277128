import { Typography } from '@clef/client-library';
import { Box } from '@material-ui/core';
import React from 'react';
import emptyBox from '@/images/empty_box.svg';
import cx from 'classnames';
import { useStyles } from './styles';

const ActiveProjectsEmptyState = () => {
  const styles = useStyles();
  return (
    <Box display="flex" flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <Box marginTop={2}>
        <img src={emptyBox} className={styles.emptyBoxIcon} />
      </Box>
      <Box marginTop={2}>
        <Typography className={styles.greyModern600} variant="h3_semibold">
          {t('You don’t have any active projects yet')}
        </Typography>
      </Box>
      <Box marginTop={1}>
        <Typography
          className={cx(styles.emptyStateModelPageTextBody, styles.greyModern600)}
          variant="body_regular"
        >
          {t('After adding active projects, you can view them all here.')}
        </Typography>
      </Box>
    </Box>
  );
};

export default ActiveProjectsEmptyState;

import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import { Button } from '@clef/client-library';
// @ts-ignore
import logo from '../../images/landing.png';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  logotype: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logotypeText: {
    color: '#fff',
    marginLeft: theme.spacing(2),
  },
  logotypeIcon: {
    width: 36,
    height: 36,
    marginRight: theme.spacing(2),
  },
  paperRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(10),
    maxWidth: 404,
  },
  textRow: {
    marginBottom: theme.spacing(10),
    textAlign: 'center',
  },
  errorCode: {
    fontWeight: 500,
  },
}));

const ErrorPage: React.FC<{ message?: string }> = ({ message }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <div className={classes.logotype}>
        <img className={classes.logotypeIcon} src={logo} alt="logo" />
        <Typography variant="h1" className={classes.logotypeText}>
          {t('Landing AI')}
        </Typography>
      </div>
      <Paper classes={{ root: classes.paperRoot }}>
        <Typography
          variant="h1"
          color="primary"
          className={classnames(classes.textRow, classes.errorCode)}
        >
          {t('Something is wrong...')}
        </Typography>
        <Typography variant="h3" color="primary" className={classes.textRow}>
          {message ?? t("Oops. Looks like the page you're looking for no longer exists")}
        </Typography>
        <Button
          id="error-page-back-to-home"
          variant="contained"
          color="primary"
          component={Link}
          to="/"
        >
          {t('Back to Home')}
        </Button>
      </Paper>
    </Grid>
  );
};

export default ErrorPage;

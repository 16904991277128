import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Box, Tooltip, Popover, List, ListItem, ListItemText } from '@material-ui/core';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useAtom, useSetAtom } from 'jotai';
import { throttle } from 'lodash';

import { SelectMediaOption, MediaStatusType, DatasetGroupOptions } from '@clef/shared/types';
import { ApiResponseLoader, Typography, greyModernScale, ToggleButton } from '@clef/client-library';

import { defaultSelectOptions } from '@/constants/data_browser';
import { supportedAutoSplit } from '@/components/AutoSplitDialog/utils';
import {
  autoSplitCoreAlgorithm,
  DefectDistribution,
  splitValueToNoDefectAssignment,
} from '@clef/shared/utils/auto_split_core_algorithm';
import {
  noDefectKey,
  sliderValueToSplitPercentage,
  defaultSliderValue,
} from '@/components/AutoSplitDialog/Step2SplitDistribution';
import { useDefectSelector } from '@/store/defectState/actions';
import {
  useGetDatasetStatsQuery,
  useGetDatasetFilterOptionsQuery,
  useDatasetMediaCountQuery,
} from '@/serverStore/dataset';
import {
  selectedDatasetVersionAtom,
  defectDistributionWithAssignmentAtom,
  enableAdjustSplitAtom,
} from '@/uiStates/customTraining/pageUIStates';

import SplitSlider from './SplitSlider';

import useStyles from '../styles';

export interface SplitAdjustmentProps {}

enum SplitAdjustmentScale {
  ALL = 'Adjust all classes together',
  CLASS = 'Adjust classes separately',
}

const SplitAdjustment: React.FC<SplitAdjustmentProps> = () => {
  const styles = useStyles();
  const [selectedDatasetVersion] = useAtom(selectedDatasetVersionAtom);
  const [enableAdjustSplit, setEnableAdjustSplit] = useAtom(enableAdjustSplitAtom);
  const setDefectDistributionWithAssignment = useSetAtom(defectDistributionWithAssignmentAtom);

  const { data: allFilters } = useGetDatasetFilterOptionsQuery(
    selectedDatasetVersion?.version ?? undefined,
  );

  const splitFilterOption = allFilters
    ? allFilters.find(value => value.filterName === 'Split' && value.filterType === 'column') ||
      allFilters.find(value => value.filterName === 'split')
    : undefined;
  const splitFilter = splitFilterOption?.value ? Object.keys(splitFilterOption.value) : [];
  const supportedAutoSplitId: number[] =
    splitFilterOption?.filterType === 'column'
      ? supportedAutoSplit.map(s => splitFilterOption.value![s] as number)
      : [];

  const unassignedLabeledMediaSelectMediaOption: SelectMediaOption | undefined = splitFilterOption
    ? {
        ...defaultSelectOptions,
        fieldFilterMap:
          splitFilterOption.filterType === 'field' && splitFilter?.length
            ? {
                [splitFilterOption.fieldId!]: { NOT_CONTAIN_ANY: splitFilter },
              }
            : {},
        columnFilterMap: {
          datasetContent: {
            mediaStatus: { CONTAINS_ANY: [MediaStatusType.Approved] },
            ...(splitFilterOption.filterType === 'column' &&
              supportedAutoSplitId.length && {
                splitSet: { NOT_CONTAIN_ANY: supportedAutoSplitId },
              }),
          },
        },
      }
    : undefined;

  const { data: labeledUnassignedMediaCount, isLoading: isLabeledUnassignedMediaCountLoading } =
    useDatasetMediaCountQuery({
      version: selectedDatasetVersion?.version,
      selectOptions: unassignedLabeledMediaSelectMediaOption,
    });

  const { data: mediaGroupByDefectDistribution } = useGetDatasetStatsQuery({
    selectOptions: unassignedLabeledMediaSelectMediaOption,
    groupOptions: [DatasetGroupOptions.DEFECT_DISTRIBUTION],
  });

  const allDefects = useDefectSelector();

  const { data: mediaGroupByDefectType } = useGetDatasetStatsQuery({
    selectOptions: unassignedLabeledMediaSelectMediaOption,
    groupOptions: [DatasetGroupOptions.DEFECT_TYPE],
  });

  // filter out defects that does not have count
  const defectList =
    allDefects && mediaGroupByDefectType
      ? allDefects.filter(({ id }) =>
          mediaGroupByDefectType.find(defectType => defectType.defect_id === id),
        )
      : undefined;

  const [adjustSplitForAll, setAdjustSplitForAll] = useState(true);
  const [splitValues, setSplitValues] = useState<[number, number]>(defaultSliderValue);
  const [splitSliderValuePerDefect, setSplitSliderValuePerDefect] = useState<
    { [defect: string]: [number, number] } | undefined
  >(undefined);

  const idleCallbackId = useRef(-1);

  const coreAlgorithmAndSetDefectDistribution = useCallback(
    (newDefectSplitValue: { [defect: string]: [number, number] }) => {
      if (defectList && mediaGroupByDefectDistribution && newDefectSplitValue) {
        if (idleCallbackId.current > 0) {
          window.cancelIdleCallback(idleCallbackId.current);
        }
        // Use requestIdleCallback here so core algorithm triggers async so ui can update first.
        idleCallbackId.current = window.requestIdleCallback(() => {
          const defectIdList: number[] = defectList.map(defect => defect.id);
          const defectDistributions: DefectDistribution[] = (
            mediaGroupByDefectDistribution as DefectDistribution[]
          ).filter(stats => stats.count && stats.defect_distribution);
          const numberOfMediaWithNoDefects =
            mediaGroupByDefectDistribution.find(stats => !stats.defect_distribution)?.count || 0;

          const [targetSplitPerDefect, targetSplitNoDefect] = Object.entries(
            newDefectSplitValue,
          ).reduce(
            (acc, [defectName, value]) => {
              const [targetSplitPerDefect, targetSplitNoDefect] = acc;
              if (defectName === noDefectKey) {
                return [targetSplitPerDefect, sliderValueToSplitPercentage(value)];
              } else {
                const defectId = defectList.find(_ => _.name === defectName)!.id;
                return [
                  {
                    ...targetSplitPerDefect,
                    [defectId]: sliderValueToSplitPercentage(value),
                  },
                  targetSplitNoDefect,
                ];
              }
            },
            [
              {} as { [defectId: number]: [number, number, number] },
              undefined as [number, number, number] | undefined,
            ],
          );
          const distributionWithDefectResult = autoSplitCoreAlgorithm(
            defectIdList,
            defectDistributions,
            targetSplitPerDefect,
          );
          const distributionWithNoDefectResult = targetSplitNoDefect
            ? splitValueToNoDefectAssignment(numberOfMediaWithNoDefects, targetSplitNoDefect)
            : undefined;
          setDefectDistributionWithAssignment(
            distributionWithNoDefectResult
              ? [
                  ...distributionWithDefectResult,
                  {
                    count: numberOfMediaWithNoDefects,
                    defect_distribution: null,
                    assignment: distributionWithNoDefectResult,
                  },
                ]
              : distributionWithDefectResult,
          );
        });
      }
    },
    [defectList, mediaGroupByDefectDistribution, setDefectDistributionWithAssignment],
  );

  // when defects response returns for the first time
  useEffect(() => {
    if (defectList && mediaGroupByDefectDistribution && !splitSliderValuePerDefect) {
      const newSplitValue = defectList.reduce(
        (acc, defect) => ({
          ...acc,
          [defect.name]: defaultSliderValue,
        }),
        {} as { [key: string]: [number, number] },
      );
      const hasNoDefectMedia = !!mediaGroupByDefectDistribution.find(
        stats => !stats.defect_distribution,
      );
      if (hasNoDefectMedia) {
        newSplitValue[noDefectKey] = defaultSliderValue;
      }
      setSplitSliderValuePerDefect(newSplitValue);
      coreAlgorithmAndSetDefectDistribution(newSplitValue);
    }
  }, [
    defectList,
    coreAlgorithmAndSetDefectDistribution,
    mediaGroupByDefectDistribution,
    splitSliderValuePerDefect,
    setDefectDistributionWithAssignment,
  ]);

  const throttledcoreAlgorithmAndSetDefectDistribution = throttle(newSplitValue => {
    coreAlgorithmAndSetDefectDistribution(newSplitValue);
  }, 500);

  const handleSliderChange = useCallback(
    (newValue: [number, number], defectName?: string) => {
      let newSplitValue: { [defectName: string]: [number, number] };
      if (defectName) {
        newSplitValue = { ...splitSliderValuePerDefect, [defectName]: newValue };
      } else {
        setSplitValues(newValue);
        newSplitValue = splitSliderValuePerDefect
          ? Object.keys(splitSliderValuePerDefect).reduce(
              (acc, key) => ({ ...acc, [key]: newValue }),
              {},
            )
          : {};
      }
      setSplitSliderValuePerDefect(newSplitValue);
      throttledcoreAlgorithmAndSetDefectDistribution(newSplitValue);
    },
    [splitSliderValuePerDefect, throttledcoreAlgorithmAndSetDefectDistribution],
  );

  const handleClickAdjustForAll = () => {
    if (adjustSplitForAll) {
      return;
    }

    setAdjustSplitForAll(true);
    const newSplitValue = splitSliderValuePerDefect
      ? Object.keys(splitSliderValuePerDefect).reduce(
          (acc, key) => ({ ...acc, [key]: splitValues }),
          {},
        )
      : {};
    setSplitSliderValuePerDefect(newSplitValue);
    coreAlgorithmAndSetDefectDistribution(newSplitValue);
  };

  return (
    <ApiResponseLoader
      response={labeledUnassignedMediaCount}
      loading={isLabeledUnassignedMediaCountLoading}
    >
      {res =>
        res > 0 && (
          <Box mb={6}>
            <Tooltip
              title="LandingLens will automatically split unassigned labeled images into the Train, Dev, and Test sets. Furthermore, the platform will distribute each class evenly across these sets."
              placement="top-start"
              arrow
            >
              <Box mt={-5} mb={2} ml={-4} width="fit-content">
                <ToggleButton
                  id="custom-train-toggle-adjust-split"
                  isOn={enableAdjustSplit}
                  onToggle={() => setEnableAdjustSplit(prev => !prev)}
                >
                  {t(`Assign split to ${res} images`)}
                </ToggleButton>
              </Box>
            </Tooltip>
            {enableAdjustSplit && (
              <Box width="100%" padding={4} bgcolor={greyModernScale[50]} borderRadius={10}>
                <Box display="flex" justifyContent="space-between" alignContent="center">
                  <Typography variant="body_bold">
                    {t('Set your target split distribution')}
                  </Typography>
                  <PopupState variant="popover" popupId="adjust-scale-popup-popover">
                    {popupState => (
                      <div>
                        <Box
                          display="flex"
                          alignItems="center"
                          style={{ gap: 2, cursor: 'pointer' }}
                          {...bindTrigger(popupState)}
                        >
                          <Typography>
                            {adjustSplitForAll
                              ? t(SplitAdjustmentScale.ALL)
                              : t(SplitAdjustmentScale.CLASS)}
                          </Typography>
                          <ExpandMoreIcon htmlColor="#697586" />
                        </Box>
                        <Popover
                          {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <List>
                            <ListItem
                              button
                              id="adjust-split-for-all"
                              onClick={() => {
                                handleClickAdjustForAll();
                                popupState.close();
                              }}
                            >
                              <ListItemText primary={t(SplitAdjustmentScale.ALL)} />
                            </ListItem>
                            <ListItem
                              button
                              id="adjust-split-for-class"
                              onClick={() => {
                                setAdjustSplitForAll(false);
                                popupState.close();
                              }}
                            >
                              <ListItemText primary={t(SplitAdjustmentScale.CLASS)} />
                            </ListItem>
                          </List>
                        </Popover>
                      </div>
                    )}
                  </PopupState>
                </Box>
                <Box display="flex" justifyContent="space-between" mt={4.5} mb={1}>
                  <Typography>{t('All distribution is on instance level')}</Typography>
                  <Typography>{'Train / dev / test (%)'}</Typography>
                </Box>
                {adjustSplitForAll ? (
                  <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <SplitSlider
                      key={'adjust-split-for-all'}
                      className={styles.slider}
                      values={splitValues}
                      onChange={value => handleSliderChange(value)}
                    />
                  </Box>
                ) : (
                  <>
                    {splitSliderValuePerDefect && (
                      <Box display="flex" flexDirection="column" width="100%">
                        {Object.entries(splitSliderValuePerDefect).map(
                          ([defectName, value], idx) => (
                            <Box
                              key={defectName + idx}
                              display="flex"
                              alignItems="center"
                              style={{ gap: 8 }}
                            >
                              <Typography width={80}>{defectName}</Typography>
                              <SplitSlider
                                className={styles.slider}
                                values={value}
                                onChange={value => handleSliderChange(value, defectName)}
                              />
                            </Box>
                          ),
                        )}
                      </Box>
                    )}
                  </>
                )}
              </Box>
            )}
          </Box>
        )
      }
    </ApiResponseLoader>
  );
};

export default SplitAdjustment;

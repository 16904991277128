import React, { useState } from 'react';
import cx from 'classnames';
import {
  useFetchDefectBookExampleApi,
  updateDefectBookExampleApi,
} from '../../../hooks/api/useDefectBookApi';
import { MediaInteractiveCanvas } from '@clef/client-library';
import { Grid, Fab } from '@material-ui/core';
import { useDefectBookEnhancedStyles } from '../defectBookEnhancedStyles';
import { useMediaByIdApi } from '../../../hooks/api/useMediaApi';
import ReactMarkdown from 'react-markdown';
import EditRounded from '@material-ui/icons/EditRounded';
import DeleteRounded from '@material-ui/icons/DeleteRounded';
import ArrowUpwardRounded from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRounded from '@material-ui/icons/ArrowDownwardRounded';
import MediaEditModal from '../components/MediaEditModal';
import { defectExampleAnnotationToCanvasAnnotation } from '../components/utils';
import { getThumbnail } from '@clef/client-library';

export interface TerminologyExampleProps {
  termId: number;
  onDelete: () => void;
  onMoveTop?: () => void;
  onMoveBottom?: () => void;
}

const TerminologyExample: React.FC<TerminologyExampleProps> = ({
  termId,
  onDelete,
  onMoveTop,
  onMoveBottom,
}) => {
  const [termExamples, , , mutateTermExample] = useFetchDefectBookExampleApi([termId]);
  const { mediaId, note, annotations } = termExamples?.[0] ?? {};
  const [media] = useMediaByIdApi(mediaId);
  const [editDialogOpen, SetEditDialogOpen] = useState(false);
  const styles = useDefectBookEnhancedStyles();

  if (!mediaId || !annotations) return null;

  return (
    <Grid
      container
      className={styles.terminologyExample}
      justifyContent="space-between"
      alignItems="flex-start"
      wrap="nowrap"
      data-testid="terminology-example"
    >
      <Grid item xs={5} className={cx(styles.terminologyMedia)}>
        <MediaInteractiveCanvas
          imageSrc={getThumbnail(media, 'large')}
          properties={media?.properties}
          annotations={defectExampleAnnotationToCanvasAnnotation(annotations)}
          builtInZoom
          enableHideLabels
        />
      </Grid>
      <Grid item className={styles.defectExampleText} xs={7}>
        <ReactMarkdown className={styles.markdownText}>{note ?? t('')}</ReactMarkdown>
      </Grid>
      <Grid
        container
        wrap="wrap"
        justifyContent="flex-end"
        alignItems="flex-end"
        className={styles.defectExampleAction}
      >
        {!!onMoveTop && (
          <Fab
            aria-label="move-up-example"
            size="small"
            className={styles.defectExampleActionFab}
            onClick={onMoveTop}
          >
            <ArrowUpwardRounded />
          </Fab>
        )}
        {!!onMoveBottom && (
          <Fab
            aria-label="move-down-example"
            size="small"
            className={styles.defectExampleActionFab}
            onClick={onMoveBottom}
          >
            <ArrowDownwardRounded />
          </Fab>
        )}
        <Fab
          color="primary"
          aria-label="edit-example"
          size="small"
          className={styles.defectExampleActionFab}
          onClick={() => SetEditDialogOpen(true)}
        >
          <EditRounded />
        </Fab>
        <Fab
          color="secondary"
          aria-label="delete-example"
          size="small"
          className={styles.defectExampleActionFab}
          onClick={onDelete}
        >
          <DeleteRounded />
        </Fab>
      </Grid>
      {editDialogOpen && (
        <MediaEditModal
          media={media}
          annotations={annotations!}
          note={note!}
          onClose={() => SetEditDialogOpen(false)}
          onSave={updateData => {
            const newData = {
              ...termExamples![0],
              ...updateData,
            };
            mutateTermExample(() => [newData]);
            updateDefectBookExampleApi(termId, newData);
          }}
        />
      )}
    </Grid>
  );
};

export default TerminologyExample;

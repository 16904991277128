import { useAllModels, useModels } from '@/hooks/useModels';
import {
  useCreateEndpointMutation,
  useDeployModelToEndpointMutation,
  useGetEndpointListQuery,
} from '@/serverStore/endpoints';
import cx from 'classnames';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { getDateNumber } from '@/utils';
import { getModelName, isFastNEasyModel } from '@/utils/models';
import {
  ApiResponseLoader,
  Button,
  IconButton,
  SwitchButtonGroup,
  Typography,
} from '@clef/client-library';
import {
  Avatar,
  Box,
  ButtonGroup,
  Dialog,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import CloudOutlinedIcon from '@material-ui/icons/CloudOutlined';
import MonitorOutlinedIcon from '@material-ui/icons/DesktopMacOutlined';
import { formatDistance } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
// TODO: @Louie replace it with DriveFileMoveIcon once we upgrade material-ui to v5
import DownloadLandingEdgeConfirmDialog from '@/pages/deployment/components/DownloadLandingEdgeConfirmDialog';
import { EndpointIcon } from '@/pages/deployment/components/EndpointIcon';
import { useSaveModelMutation } from '@/serverStore/projectModels';
import AddIcon from '@material-ui/icons/PlaylistAdd';
import { isNil } from 'lodash';
import { useSnackbar } from 'notistack';
import { ClientFeatures } from '@clef/shared/features';
import {
  useFeatureGateEnabled,
  useIsFeatureEnabledAndMayHideForSnowflake,
} from '@/hooks/useFeatureGate';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useIsLargeImageModel } from '@/hooks/useIsLargeImageModel';
import { useActiveProjectsEnabled } from '@/hooks/useSubscriptions';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6),
    gap: theme.spacing(5),
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  },
  edgeSectionContainer: {
    width: 400,
  },
  edgeSectionPadding: {
    paddingLeft: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  endpointPadding: {
    padding: `0 ${theme.spacing(6)}px`,
  },
  switchButton: {
    width: '50%',
  },
  switchButtonText: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
  },
  switchButtonIcon: {
    fontSize: '16px !important',
    marginRight: theme.spacing(-1),
  },
  endpointItems: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: -10,
    maxHeight: '300px',
    overflow: 'auto',
  },
  stretchBox: {
    marginLeft: theme.spacing(-6),
    marginRight: theme.spacing(-6),
  },
  clickableWithIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    width: '100%',
  },
  endpointItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  radioGroup: {
    gap: theme.spacing(3),
  },
  endpointModelInfo: {
    display: 'flex',
    gap: 10,
  },
  endpointItemName: {
    color: theme.palette.grey[900],
    lineHeight: '20px',
    fontWeight: 700,
  },
  endpointModelName: {
    lineHeight: '20px',
    color: theme.palette.grey[800],
  },
  endpointModelDeployDate: {
    lineHeight: '20px',
    color: theme.palette.grey[500],
  },
  addIconContainer: {
    background: theme.palette.blue[50],
  },
  addIcon: {
    color: theme.palette.blue[600],
  },
  edgeDownloadLink: {
    cursor: 'pointer',
  },
  edgeDescriptionBulletPoint: {
    display: 'list-item',
    marginLeft: theme.spacing(10),
  },
  edgeDescriptionEmphasis: {
    fontWeight: 700,
  },
  deployButton: {
    alignSelf: 'flex-end',
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(6),
  },
  endpointNameInput: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(7),
  },
  bundleIdTextContainer: {
    width: 300,
    backgroundColor: `${theme.palette.greyModern[100]} !important`,
  },
  bundleIdText: {
    color: theme.palette.greyModern[500],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  copyBtnContainer: {
    width: 60,
  },
  textGrey600: {
    color: theme.palette.grey[600],
  },
  downloadBtn: {
    width: 360,
  },
  downloadBtnDisabled: {
    width: 360,
    color: `${theme.palette.greyModern[400]} !important`,
    backgroundColor: `${theme.palette.greyModern[100]} !important`,
  },
  downloadBtnTextDisabled: {
    color: theme.palette.greyModern[400],
  },
  downloadAppIcon: {
    fontSize: 20,
    marginRight: theme.spacing(2),
  },
}));

const EndpointItemV2: React.FC<{
  endpointName: string;
  modelName: string;
  lastDeployedTime: string;
}> = ({ endpointName, modelName, lastDeployedTime }) => {
  const styles = useStyles();
  const dateNow = useMemo(() => new Date(), []);

  return (
    <Box className={styles.endpointItem}>
      <Typography className={styles.endpointItemName}>{endpointName}</Typography>

      <Box className={styles.endpointModelInfo}>
        <Typography className={styles.endpointModelName} maxWidth={200}>
          {modelName}
        </Typography>
        <Typography className={styles.endpointModelDeployDate}>
          {isNaN(getDateNumber(lastDeployedTime))
            ? t('Unknown date')
            : t('Deployed {{dateDistance}} ago', {
                dateDistance: formatDistance(getDateNumber(lastDeployedTime), dateNow),
              })}
        </Typography>
      </Box>
    </Box>
  );
};

const NEW_NAME_ENDPOINT_ID_VALUE = '';

type CloudDeploymentSectionProps = {
  isLargeImageModel: boolean;
  currentModelIsFastNEasyModel: boolean;
  modelInfo: {
    id: string;
    threshold?: number;
  };
  onDeployButtonClicked: () => void;
};
const CloudDeploymentSection: React.FC<CloudDeploymentSectionProps> = ({
  isLargeImageModel,
  currentModelIsFastNEasyModel,
  modelInfo,
  onDeployButtonClicked,
}) => {
  const { data: project } = useGetSelectedProjectQuery();
  const [endpointName, setEndpointName] = useState('');
  const [hasError, setHasError] = useState<boolean>(false);
  const [selectedEndpointId, setSelectedEndpointId] = useState<string | null>(null);
  const { id: projectId } = project ?? {};
  const {
    data: endpointsData,
    isLoading: endpointsLoading,
    error: endpointsError,
  } = useGetEndpointListQuery(projectId ?? -1);
  const styles = useStyles();
  const { findModels } = useAllModels();
  const saveModel = useSaveModelMutation();
  const { mutateAsync: deployModelToEndpoint, isLoading: isDeployModelToEndpointLoading } =
    useDeployModelToEndpointMutation();
  const { mutateAsync: createEndpoint, isLoading: isCreatingEndpoint } =
    useCreateEndpointMutation();

  const isDeploying = saveModel.isLoading || isDeployModelToEndpointLoading || isCreatingEndpoint;
  const isNewEndpointSelected = (selectedEndpointId: string | null) => {
    return selectedEndpointId === NEW_NAME_ENDPOINT_ID_VALUE;
  };
  const onDeployButtonClick = async (endpointId = selectedEndpointId) => {
    if (isNil(endpointId) || !modelInfo?.id) {
      onDeployButtonClicked();
      return;
    }
    if (isNewEndpointSelected(endpointId)) {
      if (!endpointName.trim()) {
        setHasError(true);
        return;
      }
      await createEndpoint(endpointName, modelInfo);
    } else {
      await deployModelToEndpoint(endpointId, modelInfo.id, modelInfo?.threshold);
    }
    onDeployButtonClicked();
  };

  const showNewNameInputComponent = isNewEndpointSelected(selectedEndpointId);
  const sortedEndpointsData = useMemo(() => {
    return endpointsData?.sort((a, b) => {
      return getDateNumber(b.endpoint.creationTime) - getDateNumber(a.endpoint.creationTime);
    });
  }, [endpointsData]);

  useEffect(() => {
    if (sortedEndpointsData && !endpointsLoading && sortedEndpointsData.length === 0) {
      setSelectedEndpointId(NEW_NAME_ENDPOINT_ID_VALUE);
    }
  }, [sortedEndpointsData, endpointsLoading]);

  return (
    <Box className={styles.sectionContainer}>
      <Typography className={styles.endpointPadding}>
        {t('Choose an endpoint to host your deployment.')}
      </Typography>
      <ApiResponseLoader
        response={sortedEndpointsData}
        loading={endpointsLoading}
        error={endpointsError ?? undefined}
        defaultHeight={100}
      >
        {endpoints => (
          <>
            <Box className={cx(styles.endpointItems, styles.endpointPadding)}>
              <RadioGroup
                className={styles.radioGroup}
                value={selectedEndpointId}
                onChange={event => {
                  setSelectedEndpointId(event.target.value);
                }}
              >
                {endpoints
                  .filter(endpoint => {
                    if (currentModelIsFastNEasyModel) {
                      // Fast-N-Easy model can be deployed only on dynamic device/endpoint
                      return endpoint.endpoint.type === 'dynamic';
                    } else {
                      // Classic model can be deployed on both dynamic and static device/endpoint
                      return (
                        endpoint.endpoint.type === 'dynamic' || endpoint.endpoint.type === 'static'
                      );
                    }
                  })
                  .map(endpoint => {
                    return (
                      <FormControlLabel
                        key={endpoint.endpoint.id}
                        value={endpoint.endpoint.id}
                        control={<Radio color="primary" size="small" />}
                        label={
                          <Box className={styles.clickableWithIcon}>
                            <EndpointIcon
                              isCloudEndpoint
                              endpointId={endpoint.endpoint.id}
                              size={40}
                            />
                            <EndpointItemV2
                              endpointName={endpoint.endpoint.name}
                              modelName={
                                endpoint.model === null
                                  ? t('No model deployed')
                                  : getModelName(findModels(endpoint.model?.jobId))
                              }
                              lastDeployedTime={endpoint.endpoint.lastDeployedTime}
                            />
                          </Box>
                        }
                      />
                    );
                  })}
                <FormControlLabel
                  value={NEW_NAME_ENDPOINT_ID_VALUE}
                  control={<Radio color="primary" size="small" />}
                  label={
                    <Box>
                      <Box className={styles.clickableWithIcon}>
                        <Avatar className={styles.addIconContainer} sizes="40px" variant="rounded">
                          <AddIcon className={styles.addIcon} />
                        </Avatar>
                        <Typography className={styles.endpointItemName}>
                          {t('New Endpoint')}
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
              </RadioGroup>
              {showNewNameInputComponent && (
                <TextField
                  required
                  variant="outlined"
                  label=""
                  placeholder={t('Endpoint name')}
                  value={endpointName}
                  error={hasError}
                  onChange={e => {
                    hasError && setHasError(false);
                    setEndpointName(e.target.value);
                  }}
                  size="small"
                  className={styles.endpointNameInput}
                  focused
                />
              )}
            </Box>
            {isLargeImageModel ? (
              <Tooltip
                arrow
                placement="top"
                title={
                  <span>
                    <Typography variant="body2">
                      {t('This model is incompatible with cloud inference.')}
                    </Typography>
                    <Typography variant="body2">
                      {t('Use LandingEdge to run predictions.')}
                    </Typography>
                  </span>
                }
              >
                <span className={styles.deployButton}>
                  <Button
                    id="deploy-model-dialog-deploy-button"
                    variant="contained"
                    color="primary"
                    onClick={() => onDeployButtonClick()}
                    isPending={isDeploying}
                    disabled={isLargeImageModel || isNil(selectedEndpointId)}
                  >
                    {t('Deploy')}
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                id="deploy-model-dialog-deploy-button"
                variant="contained"
                color="primary"
                onClick={() => onDeployButtonClick()}
                className={styles.deployButton}
                tooltipOnButton={isLargeImageModel}
                tooltip={t(
                  isLargeImageModel
                    ? 'This model is incompatible with cloud inference.\nUse LandingEdge to run predictions.'
                    : '',
                )}
                isPending={isDeploying}
                disabled={isLargeImageModel || isNil(selectedEndpointId)}
              >
                {t('Deploy')}
              </Button>
            )}
          </>
        )}
      </ApiResponseLoader>
    </Box>
  );
};

type EdgeDeploymentSectionProps = {
  bundleId: string;
  onDownloadModelClicked: () => void;
};
const EdgeDeploymentSection = ({
  bundleId,
  onDownloadModelClicked,
}: EdgeDeploymentSectionProps) => {
  const [downloadLandingEdgeDialogOpen, setDownloadLandingEdgeDialogOpen] =
    useState<boolean>(false);
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const shouldAllowDownloadingBundle = useFeatureGateEnabled(
    ClientFeatures.ShouldAllowDownloadingBundle,
  );
  const activeProjectEnabled = useActiveProjectsEnabled();

  return (
    <Box
      className={cx(
        styles.sectionContainer,
        styles.edgeSectionContainer,
        styles.edgeSectionPadding,
      )}
    >
      <Box width={400} display={'flex'} flexDirection={'column'}>
        <Box>
          <Typography className={styles.textGrey600}>
            {t('We offer Docker and LandingEdge for Self-Hosted deployment. {{link}}', {
              link: (
                <Link
                  color="primary"
                  href="https://support.landing.ai/docs/deployment-options"
                  target="_blank"
                >
                  {t('Compare Deployment Options')}
                </Link>
              ),
            })}
          </Typography>
        </Box>
        <Box marginTop={6}>
          <Typography className={styles.textGrey600}>
            {t('{{highlightText}} (For Docker)', {
              highlightText: <strong>{t('Model ID')}</strong>,
            })}
          </Typography>
          <Box marginTop={2}>
            <ButtonGroup disableElevation variant="outlined">
              <Button className={cx(styles.bundleIdTextContainer)} disabled id={'bundle-id'}>
                {
                  <Typography variant={'body_regular'} className={styles.bundleIdText}>
                    {bundleId}
                  </Typography>
                }
              </Button>
              <Button
                className={cx(styles.copyBtnContainer, styles.textGrey600)}
                id={'copy-bundle-id'}
                onClick={() => {
                  navigator.clipboard.writeText(bundleId);
                  enqueueSnackbar('The Model Id has been copied to your clipboard.', {
                    variant: 'success',
                  });
                }}
              >
                {t('Copy')}
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
        <Box marginTop={6}>
          <Typography className={styles.textGrey600}>
            {t('{{highlightText}} (For Docker & LandingEdge)', {
              highlightText: <strong>{t('Download')}</strong>,
            })}
          </Typography>
          <Box marginTop={2}>
            {activeProjectEnabled || shouldAllowDownloadingBundle ? (
              <Button
                id={'download-bundle-' + bundleId}
                variant="outlined"
                onClick={() => {
                  onDownloadModelClicked();
                }}
                className={styles.downloadBtn}
              >
                <GetAppIcon className={styles.downloadAppIcon} />
                <Typography variant="body_bold">{t('Download Model')}</Typography>
              </Button>
            ) : (
              <>
                <Button
                  id={'download-disabled-bundle-' + bundleId}
                  disabled
                  variant="contained"
                  className={styles.downloadBtnDisabled}
                >
                  <GetAppIcon className={styles.downloadAppIcon} />
                  <Typography className={styles.downloadBtnTextDisabled} variant="body_bold">
                    {t('Download Model')}
                  </Typography>
                </Button>
                <Box marginTop={2}>
                  <Typography className={styles.textGrey600}>
                    {t('Contact your Landing AI representative about this option')}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <DownloadLandingEdgeConfirmDialog
        open={downloadLandingEdgeDialogOpen}
        onClose={() => setDownloadLandingEdgeDialogOpen(false)}
      />
    </Box>
  );
};

enum ViewMode {
  Cloud = 'cloud',
  Edge = 'edge',
}

export interface DeployModelDialogProps {
  open: boolean;
  onClose: () => void;
  modelInfo?: {
    id: string;
    threshold: number;
    bundleId: string;
  };
  onDownloadModelClicked: () => void;
}

const DeployModelDialogV3: React.FC<DeployModelDialogProps> = ({
  open,
  onClose,
  modelInfo,
  onDownloadModelClicked,
}) => {
  const styles = useStyles();
  const { findModels } = useModels();
  const isCloudDeploymentEnabled = useIsFeatureEnabledAndMayHideForSnowflake().cloudDeployment;
  const [viewMode, setViewMode] = useState<ViewMode>(
    isCloudDeploymentEnabled ? ViewMode.Cloud : ViewMode.Edge,
  );
  const currentModel = useMemo(() => findModels(modelInfo?.id), [findModels, modelInfo?.id]);
  const [isLargeImageModel] = useIsLargeImageModel(currentModel?.id);

  const currentModelIsFastNEasyModel = isFastNEasyModel(currentModel);

  if (!currentModel || !modelInfo) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" scroll="body">
      <Box className={styles.body}>
        <Grid container>
          <Typography variant="h2" className={styles.title}>
            {t('Choose Deployment Option')}
          </Typography>
          <IconButton
            id="close-deploy-model-dialog-v2"
            className={styles.closeButton}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Grid>
        {isCloudDeploymentEnabled && (
          <SwitchButtonGroup>
            <Button
              id="deploy-modal-view-cloud-deployment-button"
              fullWidth
              className={cx(
                styles.switchButton,
                viewMode === ViewMode.Cloud ? 'active' : undefined,
              )}
              onClick={() => setViewMode(ViewMode.Cloud)}
              aria-pressed={viewMode === ViewMode.Cloud}
              startIcon={<CloudOutlinedIcon className={styles.switchButtonIcon} />}
            >
              <Typography className={styles.switchButtonText}>{t('Cloud Deployment')}</Typography>
            </Button>
            <Button
              id="deploy-modal-view-edge-deployment-button"
              fullWidth
              className={cx(styles.switchButton, viewMode === ViewMode.Edge ? 'active' : undefined)}
              onClick={() => setViewMode(ViewMode.Edge)}
              aria-pressed={viewMode === ViewMode.Edge}
              startIcon={<MonitorOutlinedIcon className={styles.switchButtonIcon} />}
            >
              <Typography className={styles.switchButtonText}>
                {t('Self-Hosted Deployments')}
              </Typography>
            </Button>
          </SwitchButtonGroup>
        )}
      </Box>
      {viewMode === ViewMode.Cloud && (
        <CloudDeploymentSection
          onDeployButtonClicked={onClose}
          isLargeImageModel={isLargeImageModel}
          currentModelIsFastNEasyModel={currentModelIsFastNEasyModel}
          modelInfo={{ id: currentModel.id, threshold: modelInfo.threshold }}
        />
      )}
      {viewMode === ViewMode.Edge && (
        <EdgeDeploymentSection
          bundleId={modelInfo.bundleId}
          onDownloadModelClicked={onDownloadModelClicked}
        />
      )}
    </Dialog>
  );
};

export default DeployModelDialogV3;

import React, { useMemo } from 'react';
import { Box, Dialog, DialogContent, makeStyles, Typography } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import LabelBarChart from '@/components/StatsCharts/LabelBarChart';
import LabelsPerDefectSplitDistributionChart from '@/components/StatsCharts/LabelsPerDefectSplitDistributionChart';
import MediaLabelingStatusStackedBarChart from '@/components/StatsCharts/MediaLabelingStatusStackedBarChart';
import MediaSplitStackedBarChart from '@/components/StatsCharts/MediaSplitStackedBarChart';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import { SelectMediaOption, LabelType } from '@clef/shared/types';
import CloseIcon from '@material-ui/icons/Close';
import {
  useDataBrowserState,
  appliedFilterMappingToFormattedFilterMapping,
  getColumnFilterMapWithModelId,
} from './dataBrowserState';
import classNames from 'classnames';

const useStyle = makeStyles(theme => ({
  labelingChartPaper: {
    maxWidth: 900,
  },
  dialogContent: {
    position: 'relative',
    width: 900,
    backgroundColor: theme.palette.grey[100],
  },
  chartSection: {
    padding: theme.spacing(6),
    backgroundColor: theme.palette.common.white,
    marginTop: theme.spacing(6),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  chartTitle: {
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.grey[800],
    marginTop: 0,
  },
  rightSidebarCloseButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  chartSectionLeft: {
    flex: '0 0 276px',
    boxSizing: 'content-box',
  },
  chartSectionRight: {
    flex: 1,
  },
}));

export type DataSummaryDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const DataSummaryDialog: React.FC<DataSummaryDialogProps> = ({ open, onClose }) => {
  const { labelType } = useGetSelectedProjectQuery().data ?? {};
  const styles = useStyle();
  const { state } = useDataBrowserState();
  const { appliedFilters } = state;
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  const [columnFilterMap, metadataFilterMap] = useMemo(() => {
    const [col, metadata] = appliedFilterMappingToFormattedFilterMapping(appliedFilters);
    const colWithModelId = getColumnFilterMapWithModelId(col, currentModelId);
    return [colWithModelId, metadata];
  }, [appliedFilters, currentModelId]);

  const selectedMediaOption = useMemo(
    () =>
      ({
        fieldFilterMap: metadataFilterMap,
        columnFilterMap,
        selectedMedia: [],
        unselectedMedia: [],
        isUnselectMode: true,
      } as SelectMediaOption),
    [columnFilterMap, metadataFilterMap],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: styles.labelingChartPaper,
      }}
    >
      <DialogContent className={styles.dialogContent}>
        <Box padding={1}>
          <IconButton
            id="close-data-summary-dialog-button"
            className={styles.rightSidebarCloseButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h3">{t('Data Summary')}</Typography>

          <Box display="flex">
            {labelType !== LabelType.AnomalyDetection && labelType !== LabelType.Classification && (
              <Box
                data-testid="media-okng-doughnut-chart"
                className={classNames(styles.chartSection, styles.chartSectionLeft)}
                marginRight={6}
              >
                <h4 className={styles.chartTitle}>{t('Labeling Status')}</h4>
                <MediaLabelingStatusStackedBarChart selectMediaOptions={selectedMediaOption} />
              </Box>
            )}
            <Box
              data-testid="label-bar-chart"
              className={classNames(styles.chartSection, styles.chartSectionRight)}
            >
              <h4 className={styles.chartTitle}>{t('Labels')}</h4>
              <LabelBarChart selectMediaOptions={selectedMediaOption} />
            </Box>
          </Box>
          <Box display="flex">
            <Box
              data-testid="media-split-doughnut-chart"
              className={classNames(styles.chartSection, styles.chartSectionLeft)}
              marginRight={6}
            >
              <h4 className={styles.chartTitle}>{t('Media Split Distribution')}</h4>
              <MediaSplitStackedBarChart selectMediaOptions={selectedMediaOption} />
            </Box>
            <Box
              data-testid="defect-per-split-distribution-chart"
              className={classNames(styles.chartSection, styles.chartSectionRight)}
            >
              <h4 className={styles.chartTitle}>{t('Label Split Distribution')}</h4>
              <LabelsPerDefectSplitDistributionChart
                selectMediaOptions={selectedMediaOption}
                hideLabel
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

import React from 'react';
import { useAtom } from 'jotai';
import { Box, makeStyles } from '@material-ui/core';
import { TabManager } from '@clef/client-library';
import { Typography } from '@clef/client-library';
import { EvaluationSetItem } from '@/api/evaluation_set_api';
import { RegisteredModelWithThreshold } from '@/api/model_api';
import ModelComparisonConfusionMatrix from './ModelComparisonConfusionMatrix';
import ModelImageList from '../ModelImageList/ModelImageList';
import { TabsType } from '@clef/client-library/src/components/TabManager';
import { modelListFilterOptionsAtom } from '../atoms';

const useStyles = makeStyles(theme => ({
  dialogSection: {
    display: 'flex',
    padding: theme.spacing(5, 0, 5, 0),
  },
}));

interface IProps {
  evaluationSet: EvaluationSetItem;
  baseline: RegisteredModelWithThreshold;
  candidate: RegisteredModelWithThreshold;
}

export const ModelComparisonDetailAnalysis: React.FC<IProps> = ({
  evaluationSet,
  baseline,
  candidate,
}) => {
  const styles = useStyles();
  const [, setFilterOptions] = useAtom(modelListFilterOptionsAtom);
  return (
    <Box flexDirection="column" className={styles.dialogSection}>
      <Typography variant="h4_semibold">{t('Detailed Analysis')}</Typography>
      <Box position="relative" marginTop={5}>
        <TabManager
          type={TabsType.Card}
          tabs={[
            {
              key: 'errorType',
              title: t('Confusion matrices'),
              component: (
                <ModelComparisonConfusionMatrix
                  evaluationSet={evaluationSet}
                  baseline={baseline}
                  baselineThreshold={baseline.threshold}
                  candidate={candidate}
                  candidateThreshold={candidate.threshold}
                />
              ),
            },
            {
              key: 'allImages',
              title: t('All images'),
              onClick: () => setFilterOptions(undefined),
              component: (
                <ModelImageList
                  model={baseline}
                  candidate={candidate}
                  threshold={baseline.threshold}
                  candidateThreshold={candidate.threshold}
                  evaluationSet={evaluationSet}
                />
              ),
            },
          ]}
        />
      </Box>
    </Box>
  );
};

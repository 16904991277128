import { MetadataState, LegacyReduxStates } from '../../store/types';
import MaterialTable, { Column, MTableBodyRow } from '@material-table/core';
import * as React from 'react';
import { LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import ChipsContainer from './ChipsContainer';
import { useEffect, useState } from 'react';
import { fetchMetadataByProjectId } from '../../store/metadata_store';
import { MetadataField } from '@clef/shared/types';
import MetadataDialog from './MetadataDialog';
import { tableIcons } from '../Utils/MatTable';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
interface MetadataTableProps {
  fieldList: MetadataState['fieldList'];
  fetchMetadataByProjectId: MetadataState['fetchMetadataByProjectId'];
}

const MetadataTable: React.FunctionComponent<MetadataTableProps> = ({
  fieldList,
  fetchMetadataByProjectId,
}) => {
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const [selectedField, setSelectedField] = useState<MetadataField | undefined>(undefined);
  const [openDialog, setOpenDialog] = useState(false);
  function onCloseDialog() {
    setSelectedField(undefined);
    setOpenDialog(false);
  }
  const onRowClick = (
    _event: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData: MetadataField | undefined,
  ) => {
    if (rowData) {
      setSelectedField(rowData);
      setOpenDialog(true);
    }
  };
  const columns: Column<any>[] = [
    {
      title: t('ID'),
      field: 'id',
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: t('Name'),
      field: 'name',
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: t('Type'),
      field: 'type',
      lookup: { boolean: t('boolean'), number: t('number'), text: t('text') },
      cellStyle: {
        textAlign: 'left',
      },
      filterOnItemSelect: true,
    },
    {
      title: t('Value'),
      field: 'valueFlexible',
      filtering: true,
      searchable: false,
      lookup: { true: 'Tags', false: 'Pre-Defined Choice' },
      render: (data: { valueFlexible: boolean }) =>
        data.valueFlexible ? t('Tags') : t('Pre-Defined Choice'),
      cellStyle: {
        textAlign: 'left',
      },
      filterOnItemSelect: true,
    },
    {
      title: t('Allow Multiple'),
      field: 'allowMultiple',
      filtering: true,
      searchable: false,
      type: 'boolean',
      render: (data: { allowMultiple: boolean }) => String(data.allowMultiple),
      cellStyle: {
        textAlign: 'left',
      },
    },
    {
      title: t('Acceptable Inputs'),
      field: 'predefinedChoices',
      searchable: false,
      render: (data: { valueFlexible: boolean; predefinedChoices?: string[] }) =>
        data.valueFlexible ? null : (
          <ChipsContainer
            values={!data.valueFlexible && data.predefinedChoices ? data.predefinedChoices : []}
          />
        ),
      cellStyle: {
        textAlign: 'left',
      },
    },
  ];

  useEffect(() => {
    if (selectedProject?.id) {
      fetchMetadataByProjectId(selectedProject.id);
    }
  }, [selectedProject]);

  return selectedProject ? (
    <div>
      <MaterialTable
        title=""
        icons={tableIcons}
        columns={columns}
        data={fieldList}
        onRowClick={onRowClick}
        components={{
          Row: props => <MTableBodyRow {...props} data-testid={'metadata-table-row'} />,
        }}
        options={{
          filtering: true,
          pageSize: 5,
          headerStyle: {
            textAlign: 'left',
          },
          filterCellStyle: {
            textAlign: 'left',
          },
        }}
      />
      {selectedField ? (
        <MetadataDialog fieldInfo={selectedField} open={openDialog} closeDialog={onCloseDialog} />
      ) : null}
    </div>
  ) : (
    <LinearProgress />
  );
};

const mapStateToProps = (state: LegacyReduxStates) => ({
  fieldList: state.metadata.fieldList,
});
const acitons = {
  fetchMetadataByProjectId,
};
export default connect(mapStateToProps, acitons)(MetadataTable);

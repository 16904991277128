import React from 'react';
import { Box, Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@clef/client-library';

type UseStylesProps = {
  values: [number, number];
};

const useStyles = (props: UseStylesProps) =>
  makeStyles(theme => {
    const { values } = props;

    const createGradient = (values: [number, number]) => {
      const [value1, value2] = values.map(value => (value / 100) * 100);
      return `linear-gradient(
      to right,
      ${theme.palette.teal[300]} 0%, ${theme.palette.teal[300]} ${value1}%,
      ${theme.palette.yellow[300]} ${value1}%, ${theme.palette.yellow[300]} ${value2}%,
      ${theme.palette.purple[500]} ${value2}%)`;
    };

    return {
      thumb: {
        height: 16,
        width: 16,
        backgroundColor: '#fff',
        border: '1px solid #4D5761',
      },
      track: {
        display: 'none',
      },
      rail: {
        height: 6,
        borderRadius: 4,
        opacity: 1,
        background: createGradient(values),
      },
      desc: {
        flexShrink: 0,
        transform: 'translateY(2px)',
      },
    };
  });

export interface SplitSliderProps {
  values: [number, number];
  onChange: (value: [number, number]) => void;
  className?: string;
}

const SplitSlider: React.FC<SplitSliderProps> = ({ values, onChange, className }) => {
  const styles = useStyles({ values })();
  return (
    <Box
      className={className}
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      style={{ gap: 12 }}
    >
      <Slider
        classes={{ thumb: styles.thumb, track: styles.track, rail: styles.rail }}
        valueLabelDisplay="off"
        value={values}
        onChange={(event, newValue) => onChange(newValue as [number, number])}
      />
      <Typography variant="body_small" className={styles.desc}>
        {`${values[0]} / ${values[1] - values[0]} / ${100 - values[1]}`}
      </Typography>
    </Box>
  );
};

export default SplitSlider;

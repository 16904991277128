import { LineChart, AVAILABLE_COLORS } from '@clef/client-library';
import { makeStyles } from '@material-ui/core/styles';
import { JobInfo } from '@clef/shared/types';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Box from '@material-ui/core/Box';

import { toTitle } from '../../utils';
import { roundToMaxDecimals } from '@clef/shared/utils';

const useStyles = makeStyles(theme => ({
  legend: {
    borderRadius: 2,
    marginRight: theme.spacing(2),
    width: 12,
    height: 12,
  },
}));

interface Props {
  metrics: Required<JobInfo>['metrics'];
  name: string;
  startTime?: number;
  customConfigs?: CustomizedChartConfigs;
}

// Nicer job duration labels: e.g. 2h, 3h4m, 2m30s, etc.
function formatDuration(millliseconds: number) {
  const h = Math.trunc(millliseconds / 60 / 60 / 1000);
  const m = Math.trunc((millliseconds - h * 60 * 60 * 1000) / 60 / 1000);
  const s = Math.trunc((millliseconds - h * 60 * 60 * 1000 - m * 60 * 1000) / 1000);
  return (h ? `${h}h` : '') + (m ? `${m}m` : '') + (s && !h ? `${s}s` : '');
}

type CustomizedChartConfigs = {
  axisTitleY?: string;
  axisTitleX?: string;
};
const MetricsChart = ({ name, metrics, startTime = 0, customConfigs }: Props) => {
  const classes = useStyles();
  const { axisTitleY, axisTitleX } = customConfigs ?? {};
  return (
    <Box display="flex" alignItems="center">
      <LineChart
        labelFormatterX={formatDuration}
        labelFormatterY={value => roundToMaxDecimals(value, 2)}
        axisTitleY={axisTitleY ?? toTitle(name)}
        axisTitleX={axisTitleX ?? 'Time'}
        getX={({ timestamp }) => timestamp - startTime}
        data={metrics}
      />
      <Box pr={4} ml={4}>
        {metrics.map(({ name }, i) => (
          <Box key={name} display="flex" alignItems="baseline" my={1}>
            <span
              className={classes.legend}
              style={{ backgroundColor: AVAILABLE_COLORS[AVAILABLE_COLORS.length % (i + 1)] }}
            />
            <Typography variant="body2">
              {name.endsWith('_val') ? t('Validation') : t('Train')}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MetricsChart;

import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import {
  refreshTaskInfo,
  useTaskApi,
  useTaskLabelingMediaToReview,
} from '../../hooks/api/useTaskApi';
import LabelingReviewHeader from './components/LabelingReviewHeaderPanel';
import LabelingReviewMediaInfoPanel from './components/LabelingReviewMediaInfoPanel';
import LabelingReviewMediaListPanel from './components/LabelingReviewMediaListPanel';
import { useLabelingReviewStyles } from './labelingReviewStyles';
import { Grid } from '@material-ui/core';
import LabelingReviewMainPanel from './components/LabelingReviewMainPanel';
import { ApiResponseLoader } from '@clef/client-library';
import { LabelingReviewContext, defaultState } from './labelingReviewState';
import { useImmer } from 'use-immer';
import { LabelingType, ReviewType, TaskStatus } from '@clef/shared/types';
import CLEF_PATH from '../../constants/path';
import useGoBack from '../../hooks/useGoBack';
import { useSnackbar } from 'notistack';
import OldLabelingReviewMediaListPanel from './components/OldLabelingReviewMediaListPanel';
import { HintSnackbarContextProvider } from '../../components/Labeling/HintSnackbar';
import {
  defaultState as defaultLabelingState,
  LabelingContext,
  ToolMode,
} from '../../components/Labeling/labelingState';
import ImageEnhancerContext from '../../components/ImageEnhancer/ImageEnhancerContext';

const getLabelingType = (labelingTypes: LabelingType[]) => {
  if (labelingTypes.includes(LabelingType.DefectBoundingBox)) {
    return LabelingType.DefectBoundingBox;
  }
  if (labelingTypes.includes(LabelingType.DefectSegmentation)) {
    return LabelingType.DefectSegmentation;
  }
  if (labelingTypes.includes(LabelingType.DefectClassification)) {
    return LabelingType.DefectClassification;
  }
  return undefined;
};

const LabelingReviewPage: React.FC = () => {
  const styles = useLabelingReviewStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { taskId: taskIdStr } = useParams<{
    taskId?: string;
  }>();

  const search = new URLSearchParams(location.search);
  const type = (search.get('type') as ReviewType) || undefined;

  const taskId = Number(taskIdStr);

  const [taskInfo, taskInfoLoading, taskInfoError] = useTaskApi(taskId);
  const numberOfLabelerPerMedia = taskInfo?.extra?.numberOfLabelerPerMedia ?? 1;
  const showAgreement = numberOfLabelerPerMedia > 1;

  const [
    taskLabelingMediaToReview,
    taskLabelingMediaToReviewLoading,
    taskLabelingMediaToReviewError,
  ] = useTaskLabelingMediaToReview(taskId ? { taskId, type } : undefined);

  const [state, dispatch] = useImmer(defaultState);
  const { currentMediaId, reviewMediaList } = state;
  const [labelingState, dispatchLabelingState] = useImmer(defaultLabelingState);

  // put isViewResultMode in state
  useEffect(() => {
    if (taskInfo?.status) {
      dispatch(draft => {
        draft.isViewResultMode = type
          ? type === 'reviewed'
          : taskInfo.status === TaskStatus.Reviewed;
      });
    }
  }, [dispatch, taskInfo, type]);

  const goBack = useGoBack(CLEF_PATH.label.main);

  // initialize reviewMediaList in state
  useEffect(() => {
    if (!taskLabelingMediaToReview) {
      return;
    }
    // if we have taskLabelingMediaToReview but no length, it means we have nothing to review
    if (taskLabelingMediaToReview && !taskLabelingMediaToReview.length) {
      refreshTaskInfo({ keys: 'refresh-all' }); // refresh task because task might have finished review
      goBack();
      enqueueSnackbar(t('No media found to review'), {
        variant: 'warning',
        autoHideDuration: 12000,
      });
      return;
    }
    // if we have taskLabelingMediaToReview but not reviewMediaList, initialize reviewMediaList and currentMediaId
    if (taskLabelingMediaToReview && !reviewMediaList.length)
      dispatch(draft => {
        draft.currentMediaId = taskLabelingMediaToReview[0].id;
        draft.reviewMediaList = taskLabelingMediaToReview;
      });
  }, [
    dispatch,
    taskLabelingMediaToReview,
    reviewMediaList.length,
    history,
    goBack,
    enqueueSnackbar,
  ]);

  // TODO: remove labelingType and use project.labelType instead
  useEffect(() => {
    if (taskInfo && currentMediaId) {
      dispatchLabelingState(draft => {
        if (taskInfo.labelingType.includes(LabelingType.DefectBoundingBox)) {
          draft.labelingType = LabelingType.DefectBoundingBox;
          // there is only one mode for bounding box labeling task, it is always selected
          draft.toolMode = ToolMode.Box;
        } else if (taskInfo.labelingType.includes(LabelingType.DefectSegmentation)) {
          draft.labelingType = LabelingType.DefectSegmentation;
        } else if (taskInfo.labelingType.includes(LabelingType.DefectClassification)) {
          draft.labelingType = LabelingType.DefectClassification;
        }
      });
    }
  }, [currentMediaId, dispatchLabelingState, taskInfo]);

  useEffect(() => {
    dispatchLabelingState(draft => {
      draft.toolOptions.erasing = false;
      draft.toolOptions.zoomScale = 'fit';
    });
  }, [dispatchLabelingState, currentMediaId]);

  return (
    <HintSnackbarContextProvider>
      <ImageEnhancerContext>
        <ApiResponseLoader
          loading={taskInfoLoading || taskLabelingMediaToReviewLoading}
          error={taskInfoError || taskLabelingMediaToReviewError}
          response={taskInfo && reviewMediaList.length && currentMediaId ? taskInfo : undefined}
          defaultWidth="100vw"
          defaultHeight="100vh"
        >
          {taskInfo => {
            const labelingType = getLabelingType(taskInfo.labelingType);
            return (
              <LabelingContext.Provider
                value={{ state: labelingState, dispatch: dispatchLabelingState }}
              >
                <LabelingReviewContext.Provider
                  value={{
                    state,
                    dispatch,
                  }}
                >
                  <LabelingReviewHeader taskInfo={taskInfo} />
                  <Grid className={styles.mainContent} container direction="row">
                    {state.isViewResultMode ? (
                      <LabelingReviewMediaListPanel
                        showAgreement={showAgreement}
                        taskInfo={taskInfo}
                      />
                    ) : (
                      <OldLabelingReviewMediaListPanel showAgreement={showAgreement} />
                    )}
                    {labelingType !== LabelingType.DefectClassification && (
                      <LabelingReviewMediaInfoPanel
                        showAgreement={showAgreement}
                        labelingType={labelingType}
                      />
                    )}
                    <LabelingReviewMainPanel labelingType={labelingType} />
                  </Grid>
                </LabelingReviewContext.Provider>
              </LabelingContext.Provider>
            );
          }}
        </ApiResponseLoader>
      </ImageEnhancerContext>
    </HintSnackbarContextProvider>
  );
};

export default LabelingReviewPage;

import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import LoadingOverlay from 'react-loading-overlay';
import { Media, MediaData } from '@clef/shared/types';
import { VirtualGrid } from '@clef/client-library';
import { MediaViewer } from '@clef/client-library';
import MediaContainer from '../MediaContainer';
import { useJobStyles } from '../../jobStyles';
import { GRID_SIZE } from '../../../../constants/model_train';
import { PreviewDimensions } from '../../JobNewPage';

export interface MediaGridProps {
  mediasData: MediaData[];
  previewDimensions?: PreviewDimensions | null;
  paginatedMedias: Media[];
  loadingAllMedia: boolean;
  showLabels?: boolean;
  disableVirtualGrid?: boolean;
  page: number;
  setPage: (page: number) => void;
  showPagination?: boolean;
  fromVersionedDataset?: number;
}

const calcOptimalRatio = (medias: Media[], previewDimensions: PreviewDimensions) => {
  if (previewDimensions?.width) {
    return previewDimensions.height / previewDimensions.width;
  } else {
    const allRatios = medias
      .filter(media => media.properties?.height && media.properties?.width)
      .map(media => media.properties!.height / media.properties!.width)
      .sort((a, b) => a - b);
    return allRatios.length ? allRatios[Math.floor(allRatios.length / 2)] : 16 / 9;
  }
};

const MediaGrid: React.FC<MediaGridProps> = ({
  mediasData,
  previewDimensions,
  paginatedMedias,
  loadingAllMedia,
  showLabels = false,
  disableVirtualGrid,
  page,
  setPage,
  showPagination = true,
  fromVersionedDataset,
}) => {
  const styles = useJobStyles();

  return (
    <LoadingOverlay active={loadingAllMedia} className={styles.previewMediaOverlay} spinner text="">
      {showPagination && (
        <Pagination
          className={styles.pagination}
          data-testid={'augmentation-top-pagination'}
          count={Math.ceil(mediasData.length / GRID_SIZE)}
          color="primary"
          size="small"
          page={page + 1}
          onChange={(_, newPageIndex) => {
            setPage(newPageIndex - 1);
          }}
        />
      )}
      <VirtualGrid
        disabled={disableVirtualGrid}
        imageRatio={calcOptimalRatio(paginatedMedias!, previewDimensions!)}
        componentList={paginatedMedias}
      >
        {media =>
          showLabels ? (
            <MediaContainer
              media={media}
              showLabels={showLabels}
              fromVersionedDataset={fromVersionedDataset}
              propertiesOverride={previewDimensions}
            />
          ) : (
            <MediaViewer imgSrc={media?.url} properties={previewDimensions ?? media?.properties} />
          )
        }
      </VirtualGrid>
    </LoadingOverlay>
  );
};

export default MediaGrid;

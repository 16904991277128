// this tools component is for media details page

import React, { useState, useCallback, useEffect } from 'react';
import cx from 'classnames';
import { Card, Tooltip, Slider } from '@material-ui/core';
import { useAtom } from 'jotai';
import { isEmpty } from 'lodash';
import { Button, useKeyPress, landingBlue } from '@clef/client-library';
import {
  IconProps,
  BrushDrawIcon,
  BrushEraserIcon,
  PolygonDrawIcon,
  PolygonEraserIcon,
} from '@/images/labeling_tools_icons/ToolIcons';
import { currentToolStateAtom } from '@/uiStates/mediaDetails/pageUIStates';
import { ToolMode, useLabelingState } from './labelingState';
import { useLabelingStyles } from './labelingStyles';

const modeToEraseOptionIcons: {
  [mode in ToolMode]?: { PlusIcon: React.FC<IconProps>; MinusIcon: React.FC<IconProps> };
} = {
  [ToolMode.Brush]: {
    PlusIcon: BrushDrawIcon,
    MinusIcon: BrushEraserIcon,
  },
  [ToolMode.Polygon]: {
    PlusIcon: PolygonDrawIcon,
    MinusIcon: PolygonEraserIcon,
  },
};

export const MIN_STROKE_WIDTH = 1;
export const MAX_STROKE_WIDTH = 50;

const calcLineWidthCapped = (value: number | string) => {
  return Math.min(Math.max(Number(value), MIN_STROKE_WIDTH), MAX_STROKE_WIDTH);
};

const SegmentationLabelToolModes: React.FC<{}> = () => {
  const styles = useLabelingStyles();
  const {
    state: {
      toolMode,
      toolOptions: { strokeWidth, erasing, eraserWidth },
    },
    dispatch,
  } = useLabelingState();

  const functionalWidth = erasing ? eraserWidth : strokeWidth;
  const [localLineWidth, setLocalLineWidth] = useState<string | number>(functionalWidth);
  // set the toolOptions values as the last saved values
  const [currentToolState, setCurrentToolState] = useAtom(currentToolStateAtom);
  useEffect(() => {
    if (!isEmpty(currentToolState)) {
      dispatch(draft => {
        draft.toolOptions.strokeWidth = currentToolState.strokeWidth ?? 8;
        draft.toolOptions.erasing = currentToolState.erasing ?? false;
        draft.toolOptions.eraserWidth = currentToolState.eraserWidth ?? 8;
      });
      setLocalLineWidth(
        currentToolState.erasing
          ? currentToolState.eraserWidth ?? 8
          : currentToolState.strokeWidth ?? 8,
      );
    }
  }, []);

  const setStrokeWidth = useCallback(
    (newValue: string | number) => {
      const newValueCapped = Math.min(
        Math.max(Number(newValue), MIN_STROKE_WIDTH),
        MAX_STROKE_WIDTH,
      );
      dispatch(draft => {
        if (erasing) {
          draft.toolOptions.eraserWidth = newValueCapped;
          setCurrentToolState(prev => ({ ...prev, eraserWidth: newValueCapped }));
        } else {
          draft.toolOptions.strokeWidth = newValueCapped;
          setCurrentToolState(prev => ({ ...prev, strokeWidth: newValueCapped }));
        }
      });
    },
    [dispatch, erasing, setCurrentToolState],
  );

  const OptionButtons = toolMode ? modeToEraseOptionIcons[toolMode] : undefined;
  const enableStrokeWidth = toolMode
    ? [ToolMode.Brush, ToolMode.Polyline].includes(toolMode)
    : false;

  useKeyPress(
    '[',
    () => {
      if (enableStrokeWidth) {
        const cappedValue = calcLineWidthCapped(functionalWidth - 1);
        setStrokeWidth(cappedValue);
        setLocalLineWidth(cappedValue);
      }
    },
    { id: 'task-line-width-decr' },
  );

  useKeyPress(
    ']',
    () => {
      if (enableStrokeWidth) {
        const cappedValue = calcLineWidthCapped(functionalWidth + 1);
        setStrokeWidth(cappedValue);
        setLocalLineWidth(cappedValue);
      }
    },
    { id: 'task-line-width-incr' },
  );

  const toggleEraseModeHandler = useCallback(() => {
    if (OptionButtons) {
      dispatch(draft => {
        draft.toolOptions.erasing = !erasing;
        setCurrentToolState(prev => ({ ...prev, erasing: !erasing }));
      });
    }
  }, [OptionButtons, dispatch, erasing, setCurrentToolState]);
  useKeyPress('e', toggleEraseModeHandler, { id: 'task-toggle-eraser' });

  if (!OptionButtons && !enableStrokeWidth) {
    return null;
  }

  return (
    <Card className={cx(styles.optionCard)}>
      {OptionButtons && (
        <Tooltip
          arrow
          placement="top"
          title={t('toggle draw / erase {{hotKey}}', {
            hotKey: <span className={styles.codeBlock}>e</span>,
          })}
        >
          <div className={styles.flexRow}>
            <Button
              className={styles.modeOptionButton}
              aria-label="tool-option-button-draw"
              variant={erasing ? undefined : 'contained'}
              onClick={() => {
                dispatch(draft => {
                  draft.toolOptions.erasing = false;
                  setCurrentToolState(prev => ({ ...prev, erasing: false }));
                });
              }}
              id="task-eraser-off"
            >
              <OptionButtons.PlusIcon color={erasing ? undefined : landingBlue.main} />
            </Button>
            <Button
              className={styles.modeOptionButton}
              aria-label="tool-option-button-erase"
              variant={erasing ? 'contained' : undefined}
              onClick={() => {
                dispatch(draft => {
                  draft.toolOptions.erasing = true;
                  setCurrentToolState(prev => ({ ...prev, erasing: true }));
                });
              }}
              id="task-eraser-on"
            >
              <OptionButtons.MinusIcon color={erasing ? landingBlue.main : undefined} />
            </Button>
          </div>
        </Tooltip>
      )}
      {enableStrokeWidth && (
        <>
          <Tooltip
            arrow
            placement="top"
            title={t('Set line width {{hotKey1}} / {{hotKey2}}, ', {
              hotKey1: <span className={styles.codeBlock}>[</span>,
              hotKey2: <span className={styles.codeBlock}>]</span>,
            })}
          >
            <Slider
              className={styles.lineWidthSlider}
              value={Number(localLineWidth)}
              step={1}
              min={MIN_STROKE_WIDTH}
              max={MAX_STROKE_WIDTH}
              onChange={(_, newValue) => {
                setLocalLineWidth(Number(newValue));
                setStrokeWidth(calcLineWidthCapped(newValue as number));
              }}
              onChangeCommitted={() => {
                setLocalLineWidth(functionalWidth);
              }}
              aria-labelledby="line-width-slider"
            />
          </Tooltip>
        </>
      )}
    </Card>
  );
};

export default SegmentationLabelToolModes;

import CLEF_PATH from '@/constants/path';
import { getPlanName } from '@/pages/plan/utils';
import { ApiResponseLoader, Button, Typography } from '@clef/client-library';
import { Box, Grid, makeStyles, Tooltip } from '@material-ui/core';
import { startCase } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router';
import subscription_api from '../../../api/subscription_api';
import { useSubscriptionPlanSettings } from '../../../hooks/api/useSubscriptionApi';
import {
  useCurrentSubscription,
  useIsNonStripeUser,
  useIsPricingV2User,
} from '../../../hooks/useSubscriptions';
import AntSwitch from '@clef/client-library/src/components/AntSwitch';
import CreditUsageBlock from './components/CreditUsageBlock';
import ActiveProjectBlock from './components/ActiveProjectBlock';
import PeriodEndDateBlock from './components/PeriodEndDateBlock';

export type PlanSectionProps = {};

const useStyles = makeStyles(theme => ({
  planSectionContainer: {
    marginTop: theme.spacing(1),
  },
  planSectionHeader: {
    width: '100%',
    color: theme.palette.grey[800],
    '& > span': {
      display: 'flex',
    },
  },
  planName: {
    color: theme.palette.blue[500],
  },
  planSectionCard: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '13px',
    width: '100% !important',
    margin: '0 !important',
    '&.MuiGrid-spacing-xs-10 > .MuiGrid-item': {
      padding: theme.spacing(5, 7.5),
    },
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.grey[800],
    borderBottom: `1px dotted ${theme.palette.grey[900]}`,
  },
  value: {
    fontSize: 20,
    color: theme.palette.grey[800],
    fontWeight: 500,
    marginRight: 6,
    fontFamily: 'Commissioner',
  },
  progressValue: {
    color: theme.palette.grey[800],
    fontSize: 14,
    paddingRight: theme.spacing(1),
  },
  addButton: {
    cursor: 'pointer',
  },
  creditLimitMonthInfoButton: {
    height: 20,
    width: 20,
    color: theme.palette.blue[200],
  },
  tooltipBox: {
    width: 238,
    padding: theme.spacing(2),
  },
  tooltipTitle: {
    fontSize: 14,
    fontWeight: 700,
  },
  tooltipContent: {
    marginTop: theme.spacing(1),
  },
  cancelTip: {
    fontSize: 14,
    fontFamily: 'Commissioner',
    lineHeight: '20px',
    color: theme.palette.grey[600],
    fontWeight: 400,
  },
}));

const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
  },
}));

const PlanSection: React.FC<PlanSectionProps> = () => {
  const styles = useStyles();

  const history = useHistory();

  const subscription = useCurrentSubscription();
  const planName = getPlanName(subscription);
  const [orgPlanSetting, orgPlanSettingLoading, orgPlanSettingError, mutate] =
    useSubscriptionPlanSettings('anystring');

  const tooltipClasses = useStylesBootstrap();
  const isNonStripeUser = useIsNonStripeUser();
  const isStripeUser = !isNonStripeUser;
  const isPricingV2User = useIsPricingV2User();

  return (
    <Box className={styles.planSectionContainer}>
      <Box className={styles.planSectionHeader} display="flex" alignItems="center">
        <Typography variant="h2">
          {t('You are on the {{planName}} Plan', {
            planName: <span className={styles.planName}> {startCase(planName)} </span>,
          })}
        </Typography>
        {subscription?.status === 'to_be_canceled' && (
          <Box className={styles.cancelTip} marginLeft={4}>
            {isPricingV2User
              ? t('(Plan canceled, your organization will be on the Free Plan starting next cycle)')
              : t(
                  '(Plan canceled, your organization will be on the Freemium Plan starting next cycle)',
                )}
          </Box>
        )}
        <div style={{ flex: 1 }}></div>
        {isStripeUser && (
          <Button
            id="go-to-change-plan"
            variant="tonal"
            role="button"
            onClick={() => {
              if (isPricingV2User) {
                history.push(CLEF_PATH.planV2);
              } else {
                history.push(CLEF_PATH.plan);
              }
            }}
          >
            {t('Change Plan')}
          </Button>
        )}
      </Box>
      <Box marginTop={4}>
        <Grid container className={styles.planSectionCard} spacing={10}>
          <CreditUsageBlock />
          <ActiveProjectBlock />
          <PeriodEndDateBlock />
          <Grid item xs={12} sm={6} md={3}>
            <Box>
              <Tooltip
                arrow
                classes={tooltipClasses}
                title={
                  <Box className={styles.tooltipBox}>
                    <Typography variant="body1" className={styles.tooltipTitle}>
                      {t('Predict at upload')}
                    </Typography>
                    <Typography variant="body1" className={styles.tooltipContent}>
                      {t(
                        'When enabled, LandingLens will run inference on images uploaded after a model has been trained. This impacts credit usage.',
                      )}
                    </Typography>
                  </Box>
                }
              >
                <span className={styles.cardTitle}>{t('Plan settings')}</span>
              </Tooltip>
            </Box>
            <ApiResponseLoader
              response={orgPlanSetting}
              loading={orgPlanSettingLoading}
              error={orgPlanSettingError}
            >
              {response => {
                return (
                  <Box>
                    <Box display="flex" alignItems="center" marginTop={2}>
                      <AntSwitch
                        color="primary"
                        checked={response.predictUponUploading}
                        onChange={async () => {
                          const newData = {
                            ...response,
                            predictUponUploading: !response.predictUponUploading,
                          };
                          subscription_api.updatePlanSettings(newData);
                          // optimistic update
                          mutate(() => newData);
                        }}
                      />
                      <Typography className={styles.progressValue}>
                        {t('Predict at upload')}
                      </Typography>
                    </Box>
                  </Box>
                );
              }}
            </ApiResponseLoader>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PlanSection;

import React, { useCallback, useMemo } from 'react';
import { Box, makeStyles, Mark, Slider, Typography, ValueLabelProps } from '@material-ui/core';
import { usePlanPageStateContext } from './state';
import { formatPrice, useCreditOptions } from './utils';
import { useCurrentSubscription } from '../../hooks/useSubscriptions';
import classNames from 'classnames';
import { SubscriptionName } from '@clef/shared/types';

const useStyles = makeStyles(theme => ({
  valueLabelComponent: {
    position: 'relative',
    zIndex: 2,
  },
  valueLabelCredits: {
    position: 'absolute',
    top: '100%',
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.blue[500],
    color: 'white',
    borderRadius: 4,
    marginTop: theme.spacing(3),
    zIndex: 2,
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: 0,
      transform: 'translate(-50%, -50%) rotate(45deg)',
      border: '3px solid transparent',
      borderLeftColor: theme.palette.blue[500],
      borderTopColor: theme.palette.blue[500],
    },
  },
  valueLabelUnitPrice: {
    position: 'absolute',
    bottom: '100%',
    marginBottom: theme.spacing(2),
    color: theme.palette.grey[600],
    whiteSpace: 'nowrap',
  },
  sliderMarkLabel: {
    marginTop: theme.spacing(6),
    zIndex: 2,
    transform: 'none',
  },
  sliderBox: {
    paddingRight: 20,
    '& .MuiSlider-rail': {
      height: '20px',
      backgroundColor: theme.palette.blue[50],
      borderRadius: 10,
      opacity: '1 !important',
      width: 'calc(100% + 20px)',
    },
    '& .MuiSlider-track': {
      height: '20px',
      backgroundColor: theme.palette.blue[500],
      borderRadius: '10px 0 0 10px',
    },
    '& .MuiSlider-thumb': {
      width: 24,
      height: 24,
      marginTop: -2,
      backgroundColor: theme.palette.blue[200],
    },
    '& .MuiSlider-thumb::after': {
      top: 6,
      bottom: 6,
      left: 6,
      right: 6,
      width: 12,
      height: 12,
      backgroundColor: theme.palette.blue[600],
    },
    '&:hover $scalePoint': {
      visibility: 'visible',
      opacity: 1,
    },
  },
  currentCreditLimitText: {
    transform: 'translateX(-50%)',
    fontSize: 14,
    fontStyle: 'italic',
    color: theme.palette.grey[900],
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    top: -65,
  },
  mark: {
    display: 'none',
  },
  currentCreditPoint: {
    width: 16,
    height: 16,
    borderRadius: 16,
    backgroundColor: theme.palette.blue[800],
    position: 'absolute',
    top: -35,
    left: -1,
  },
  scalePoint: {
    visibility: 'hidden',
    opacity: 0,
    transition: '200ms',
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: theme.palette.common.white,
    outline: `1px solid ${theme.palette.blue[300]}`,
    marginTop: 3,
    left: 0,
    '&.first': {
      marginLeft: 5,
    },
  },
}));

const ValueLabel: React.ElementType<ValueLabelProps> = ({ value, children }) => {
  const styles = useStyles();
  const options = useCreditOptions();
  if (!options.length)
    return (
      <Box className={styles.valueLabelComponent}>{React.cloneElement(children, undefined)}</Box>
    );
  const { credits } = options[value];

  return (
    <Box className={styles.valueLabelComponent}>
      {React.cloneElement(
        children,
        undefined,
        <Box className={styles.valueLabelCredits} data-testid="credict-value">
          {credits}
        </Box>,
      )}
    </Box>
  );
};

export type CreditOptionSelectorProps = {};

const CreditOptionSelector: React.FC<CreditOptionSelectorProps> = () => {
  const styles = useStyles();
  const { state, dispatch } = usePlanPageStateContext();
  const { creditOptionIndex, plan } = state;
  const currentSubscription = useCurrentSubscription();
  const selectCreditOption = useCallback(
    (optionIndex: number) => {
      if (isNaN(optionIndex)) return;
      dispatch(draft => {
        draft.creditOptionIndex = optionIndex;
      });
    },
    [dispatch],
  );

  const options = useCreditOptions(
    plan ?? (currentSubscription?.meta.plan as SubscriptionName | undefined),
  );

  const sliderMarks: Mark[] = useMemo(() => {
    return options.map((option, index) => {
      const planTier = Number(currentSubscription?.meta?.planTier) - 1;
      const isCurrent = planTier === index && plan === currentSubscription?.meta?.plan;
      const currentValue = index === 0 || index === options.length - 1 ? option.credits : '';
      return {
        value: index,
        label: (
          <Box position="relative">
            <Box
              className={classNames(
                styles.currentCreditPoint,
                styles.scalePoint,
                index === 0 && 'first',
              )}
              data-testid={`credict-options-${index}`}
              onClick={e => {
                e.stopPropagation();
                e.nativeEvent.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                e.preventDefault();
                selectCreditOption(index);
              }}
            />
            {isCurrent && (
              <Box className={classNames(styles.currentCreditPoint, 'currentCreditPoint')} />
            )}
            {currentValue && <Typography variant="body1">{formatPrice(currentValue)}</Typography>}
          </Box>
        ),
      };
    });
  }, [
    currentSubscription?.meta?.plan,
    currentSubscription?.meta?.planTier,
    options,
    plan,
    selectCreditOption,
    styles.currentCreditLimitText,
    styles.currentCreditPoint,
    styles.scalePoint,
  ]);

  if (!options.length) return null;

  return (
    <>
      <Box paddingBottom={2} className={styles.sliderBox} data-testid="credict-slider-box">
        <Slider
          marks={sliderMarks}
          min={0}
          max={options.length - 1}
          step={1}
          value={creditOptionIndex}
          valueLabelDisplay="on"
          ValueLabelComponent={ValueLabel}
          onChange={(_e, value) => selectCreditOption(value as number)}
          classes={{ markLabel: styles.sliderMarkLabel, mark: styles.mark }}
        />
      </Box>
    </>
  );
};

export default CreditOptionSelector;

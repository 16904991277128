import React, { useMemo } from 'react';
import { Box, CircularProgress, Tooltip, makeStyles } from '@material-ui/core';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { Typography } from '@clef/client-library';
import { isNumber } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { LabelType } from '@clef/shared/types';
import {
  useGetConfusionMatrixQuery,
  useGetModelMediaListInfiniteQuery,
} from '@/serverStore/modelAnalysis';
import { EvaluationSetItem } from '@/api/evaluation_set_api';

const useStyles = makeStyles(theme => ({
  progressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  progressBg: {
    // color: '#FEDF89',
    color: theme.palette.blue[200],
  },
  progressFg: {
    // color: '#F79009',
    color: theme.palette.blue[500],
  },
}));

export type ModelPerformanceProps = {
  performance?: number | null;
  performanceLabel?: string;
  showImagesAndInstancesNumber?: boolean;
  modelId?: string;
  threshold?: number;
  evaluationSet?: EvaluationSetItem | null;
  isComparison?: boolean;
};

// Format a large number to readable form. Espectially useful for segmentation pixels
// 1~999 -> original number
// 1000~999,999 -> 1.0k to 999.9k
// 1,000,000~?  -> 1.0M to ????.?M
const humanReadableNumber = (value: number) => {
  if (value < 1e3) {
    return value;
  }
  if (value < 1e6) {
    return (value / 1000).toFixed(1) + 'K';
  }
  return (value / 1e6).toFixed(1) + 'M';
};

const ModelPerformance: React.FC<ModelPerformanceProps> = props => {
  const styles = useStyles();
  const {
    performance,
    performanceLabel,
    showImagesAndInstancesNumber = true,
    modelId,
    threshold,
    evaluationSet,
    isComparison = false,
  } = props;

  const { data: project } = useGetSelectedProjectQuery();
  const { labelType } = project ?? {};

  const { data: mediaListRes } = useGetModelMediaListInfiniteQuery(
    modelId,
    threshold,
    evaluationSet,
  );
  const totalMedias = mediaListRes?.pages[0]?.total;
  const { data: confusionMatrixData } = useGetConfusionMatrixQuery(
    modelId,
    evaluationSet?.id,
    threshold,
  );
  const { correct, misClassification, falseNegative, falsePositive } =
    confusionMatrixData?.splitConfusionMatrices ?? {};
  const totalInstances =
    (correct?.count ?? 0) +
    (misClassification?.count ?? 0) +
    (falseNegative?.count ?? 0) +
    (falsePositive?.count ?? 0);

  const imagesAndInstancesNumberComponent = useMemo(() => {
    if (totalMedias === undefined || totalInstances === undefined) {
      return <Skeleton variant="text" width={100} />;
    }
    if (labelType === LabelType.BoundingBox) {
      return t('({{totalMedias}} images, {{totalInstances}} instances labeled)', {
        totalMedias,
        totalInstances,
      });
    }
    if (labelType === LabelType.Segmentation) {
      return t('({{totalMedias}} images, {{totalInstances}} pixels labeled)', {
        totalMedias,
        totalInstances: humanReadableNumber(totalInstances),
      });
    }
    return t('');
  }, [labelType, totalInstances, totalMedias]);

  return (
    <Box display="flex" alignItems="center">
      <Box position="relative" width={48} height={48}>
        <CircularProgress
          size={48}
          variant="determinate"
          thickness={6}
          value={100}
          classes={{ root: styles.progressRoot, colorPrimary: styles.progressBg }}
        />
        <CircularProgress
          size={48}
          variant="determinate"
          thickness={6}
          value={(performance ?? 0) * 100}
          classes={{ root: styles.progressRoot, colorPrimary: styles.progressFg }}
        />
      </Box>
      <Box marginLeft={4}>
        {isComparison ? (
          <>
            <Typography variant="h2">
              {isNumber(performance) ? (
                performance === -1 ? (
                  t('N/A')
                ) : (
                  t('{{performance}}%', { performance: (performance * 100).toFixed(1) })
                )
              ) : (
                <Skeleton variant="text" />
              )}
            </Typography>
            {performanceLabel && <Typography variant="body_medium">{performanceLabel}</Typography>}
          </>
        ) : (
          <>
            <Box display="flex" alignItems="flex-end" marginBottom={1}>
              <Typography variant="h2">
                {isNumber(performance) ? (
                  performance === -1 ? (
                    t('N/A')
                  ) : (
                    t('{{performance}}%', { performance: (performance * 100).toFixed(1) })
                  )
                ) : (
                  <Skeleton variant="text" />
                )}
              </Typography>
              <Box display="flex" alignItems="center" marginLeft={2}>
                <Typography variant="body_bold">
                  {labelType === LabelType.Segmentation ? t('IOU') : t('F1')}
                </Typography>
                <Box marginLeft={1} />
                <Tooltip
                  placement="top"
                  arrow
                  interactive
                  title={
                    labelType === LabelType.Segmentation
                      ? t(
                          'Intersection over Union (IOU) is used to measure the accuracy of the model by measuring the overlap between the predicted and actual masks in an image. A higher IOU indicates better agreement between the ground truth and predicted mask. For IoU we ignore the implicit background and micro-averaging. {{learnMore}}',
                          {
                            learnMore: (
                              <Typography
                                style={{
                                  display: 'inline',
                                  cursor: 'pointer',
                                  marginLeft: 4,
                                  textDecoration: 'underline',
                                  fontSize: '12px',
                                }}
                                onClick={() => {
                                  window.open(
                                    'https://support.landing.ai/landinglens/docs/compare-models#segmentation-intersection-over-union-iou',
                                    '_blank',
                                  );
                                }}
                              >
                                {t('Learn more.')}
                              </Typography>
                            ),
                          },
                        )
                      : t(
                          'The F1 score combines precision and recall into a single score, creating a unified measure that assesses the model’s effectiveness in minimizing false positives and false negatives. A higher F1 score indicates the model is balancing the two factors well. We calculate F1 using micro-averaging.',
                        )
                  }
                >
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              </Box>
            </Box>
            {showImagesAndInstancesNumber && <Box>{imagesAndInstancesNumberComponent}</Box>}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ModelPerformance;

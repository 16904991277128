import DatasetAPI from '../../api/dataset_api';
import { SelectMediaOption, DatasetGroupOptions, MediaStatusType } from '@clef/shared/types';
import { useDefectSelector } from '../../store/defectState/actions';
import {
  datasetQueryKeys,
  useDatasetMediaCountQuery,
  useGetDatasetStatsQuery,
} from '@/serverStore/dataset';
import { queryClient } from '@/serverStore';
import { useDataBrowserState } from '@/pages/DataBrowser/dataBrowserState';
import { useTotalMediaCount } from '@/pages/DataBrowser/utils';
import { useCallback } from 'react';

// Combined useDatasetStatsApi with useDefectSelector
export const useDatasetDefectTotalNumbers = (
  selectOptions?: SelectMediaOption,
  isEnabled = true,
) => {
  const { data: distributionStats } = useGetDatasetStatsQuery(
    {
      selectOptions: selectOptions,
      groupOptions: [DatasetGroupOptions.DEFECT_DISTRIBUTION],
    },
    isEnabled,
  );
  const defects = useDefectSelector();

  if (!distributionStats || !defects) return undefined;

  return defects.reduce(
    (acc, defect) => ({
      ...acc,
      [defect.name]: distributionStats.reduce((acc, stats) => {
        return acc + (stats?.defect_distribution?.[defect.id] ?? 0) * stats.count;
      }, 0),
    }),
    {},
  );
};

export const useLabeledMediaCount = (
  params: {
    projectId: number;
    datasetId: number;
  },
  ignoreOKMedia: boolean = false,
) => {
  const allParams: {
    projectId: number;
    datasetId: number;
    selectOptions?: SelectMediaOption;
    labeledOnly?: boolean;
  } = {
    ...params,
    selectOptions: {
      selectedMedia: [],
      unselectedMedia: [],
      isUnselectMode: true,
      fieldFilterMap: {},
      columnFilterMap: {
        datasetContent: { mediaStatus: { CONTAINS_ANY: [MediaStatusType.Approved] } },
      },
    },
  };

  if (ignoreOKMedia) {
    allParams.labeledOnly = true;
  }

  const { data: count } = useDatasetMediaCountQuery(allParams);
  return count;
};

export const refreshLabeledMediaCount = (params: { projectId: number; datasetId: number }) => {
  queryClient.invalidateQueries(
    datasetQueryKeys.mediaCount(params.projectId, params.datasetId, {
      selectOptions: {
        selectedMedia: [],
        unselectedMedia: [],
        isUnselectMode: true,
        fieldFilterMap: {},
        columnFilterMap: {
          datasetContent: { mediaStatus: { CONTAINS_ANY: [MediaStatusType.Approved] } },
        },
      },
    }),
  );
};

export const removeMediaFromDataset = async (
  projectId: number,
  datasetId: number,
  selectOptions: SelectMediaOption,
) => {
  const response = await DatasetAPI.deleteMedias(datasetId, projectId, selectOptions);
  projectId && queryClient.removeQueries(datasetQueryKeys.medias(projectId));
  projectId && queryClient.removeQueries(datasetQueryKeys.mediaCount(projectId, datasetId));
  projectId && queryClient.invalidateQueries(datasetQueryKeys.allStats(projectId));
  projectId && queryClient.invalidateQueries(datasetQueryKeys.modelMetrics(projectId));
  if (selectOptions.isUnselectMode) {
    queryClient.removeQueries(datasetQueryKeys.mediaDetails(datasetId));
  } else {
    selectOptions.selectedMedia.forEach(mediaId => {
      queryClient.removeQueries(
        datasetQueryKeys.mediaDetails(datasetId, {
          mediaId,
        }),
      );
    });
  }
  return response;
};

export const useRemoveMediaFromDataset = () => {
  const { state, dispatch } = useDataBrowserState();
  const { data: totalMediaCount = 0 } = useTotalMediaCount(state);
  return useCallback(
    async (projectId: number, datasetId: number, selectOptions: SelectMediaOption) => {
      const res = await removeMediaFromDataset(projectId, datasetId, selectOptions);
      // After removing images, if the pageIndex is out of remaining pages, then we change to the last of remaing pages
      const remainingMedias = totalMediaCount - res.deletedMediasCount;
      const remainingPages = Math.ceil(remainingMedias / (state.paginationLimit || 50));
      if (state.pageIndex >= remainingPages - 1 && remainingPages > 0) {
        dispatch(draft => {
          draft.pageIndex = remainingPages - 1;
        });
      }
      return res;
    },
    [dispatch, state.pageIndex, state.paginationLimit, totalMediaCount],
  );
};

import React, { useMemo } from 'react';
import { MediaDetails, EdgeMediaDetails } from '@clef/shared/types';
import { Grid, Typography } from '@material-ui/core';
import { startCase } from 'lodash';

interface MediaDetailsItemProps {
  name: string | JSX.Element;
  value: string | JSX.Element;
}

function isString(node: string | JSX.Element): node is string {
  return typeof node == 'string';
}

const MediaDetailsItem: React.FC<MediaDetailsItemProps> = ({ name, value }) => {
  let nameComponent = name,
    valueComponent = value;
  if (isString(name)) {
    nameComponent = <Typography variant="body2">{startCase(name)}</Typography>;
  }
  if (isString(value)) {
    valueComponent = <Typography variant="body2">{value}</Typography>;
  }
  return (
    <Grid item container style={{ paddingTop: '10px' }}>
      <Grid item xs={4}>
        {nameComponent}
      </Grid>
      <Grid item xs={8} data-testid={`media-details-${name}`.toLowerCase().replace(' ', '-')}>
        {valueComponent}
      </Grid>
    </Grid>
  );
};

export interface MediaInfoPanelProps {
  media: MediaDetails | EdgeMediaDetails;
}

/**
 * Info Panel of Media Details page
 * Layout: Table with key and values
 */
const MediaInfoPanel: React.FC<MediaInfoPanelProps> = ({ media }) => {
  const edgeImageMetaData = (media as EdgeMediaDetails).image_metadata;

  const data = useMemo(
    () => [
      { key: 'id', name: t('Media ID'), value: `${media?.id!}` },
      {
        key: 'imageId',
        name: t('Image ID'),
        value: edgeImageMetaData?.image_id || 'N/A',
      },
      {
        key: 'inspectionStationId',
        name: t('Inspection Station ID'),
        value: edgeImageMetaData?.inspection_station_id || 'N/A',
      },
      {
        key: 'locationId',
        name: t('Location ID'),
        value: edgeImageMetaData?.location_id || 'N/A',
      },
      {
        key: 'captureTime',
        name: t('Capture Time'),
        value: edgeImageMetaData?.capture_time
          ? new Date(
              Date.parse(edgeImageMetaData?.capture_time.replace('T', ' ') + ' +0000'),
            ).toUTCString() // The server returns the date in UTC already
          : 'N/A',
      },
    ],
    [media, edgeImageMetaData],
  );
  return (
    <Grid container>
      {data.map(({ key, ...param }) => (
        <MediaDetailsItem key={key} {...param} />
      ))}
    </Grid>
  );
};

export default MediaInfoPanel;

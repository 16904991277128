import React, { useCallback } from 'react';
import { useSetAtom } from 'jotai';

import ImageEnhancer from '@/components/ImageEnhancer/ImageEnhancer';
import { useImageEnhancerState, initialState } from '@/components/ImageEnhancer/state';

import {
  imageEnhancerStatesAtom,
  resetImageEnhancerStatesAtom,
} from '@/uiStates/mediaDetails/imageEnhancerStates';

export interface ImageEnhanceWrapperProps {
  hasPaperContainer?: boolean;
  hideApplyToAll?: boolean;
  iconColor?: string;
  customerIcon?: React.ReactNode;
}

// this is mainly for sync state from ImageEnhancerContext to Jotai
const ImageEnhanceWrapper: React.FunctionComponent<ImageEnhanceWrapperProps> = ({
  hasPaperContainer = false,
  hideApplyToAll = false,
  iconColor,
  customerIcon,
}) => {
  const setImageEnhancerStates = useSetAtom(imageEnhancerStatesAtom);
  const resetImageEnhancerStates = useSetAtom(resetImageEnhancerStatesAtom);

  const { state, dispatch } = useImageEnhancerState();

  const setBrightness = useCallback(
    (brightness: number) => {
      dispatch(draft => {
        draft.brightness = brightness;
      });
      setImageEnhancerStates(prev => ({ ...prev, brightness }));
    },
    [dispatch, setImageEnhancerStates],
  );
  const setContrast = useCallback(
    (contrast: number) => {
      dispatch(draft => {
        draft.contrast = contrast;
      });
      setImageEnhancerStates(prev => ({ ...prev, contrast }));
    },
    [dispatch, setImageEnhancerStates],
  );
  const toggleHistogram = useCallback(() => {
    dispatch(draft => {
      draft.histogramEnabled = !draft.histogramEnabled;
      draft.autoContrastEnabled = false;
    });
    setImageEnhancerStates(prev => ({
      ...prev,
      histogramEnabled: !prev.histogramEnabled,
      autoContrastEnabled: false,
    }));
  }, [dispatch, setImageEnhancerStates]);
  const toggleAutoContrast = useCallback(() => {
    dispatch(draft => {
      draft.autoContrastEnabled = !draft.autoContrastEnabled;
      draft.histogramEnabled = false;
    });
    setImageEnhancerStates(prev => ({
      ...prev,
      autoContrastEnabled: !prev.autoContrastEnabled,
      histogramEnabled: false,
    }));
  }, [dispatch, setImageEnhancerStates]);

  const resetEnhancers = useCallback(() => {
    dispatch(draft => {
      draft.autoContrastEnabled = initialState.autoContrastEnabled;
      draft.brightness = initialState.brightness;
      draft.contrast = initialState.contrast;
      draft.histogramEnabled = initialState.histogramEnabled;
    });
    resetImageEnhancerStates(true);
  }, [dispatch, resetImageEnhancerStates]);

  return (
    <ImageEnhancer
      brightness={state.brightness}
      contrast={state.contrast}
      histogramEnabled={state.histogramEnabled}
      autoContrastEnabled={state.autoContrastEnabled}
      setContrast={setContrast}
      setBrightness={setBrightness}
      toggleHistogram={toggleHistogram}
      toggleAutoContrast={toggleAutoContrast}
      resetEnhancers={resetEnhancers}
      hasPaperContainer={hasPaperContainer}
      hideApplyToAll={hideApplyToAll}
      iconColor={iconColor}
      customerIcon={customerIcon}
    />
  );
};

export default ImageEnhanceWrapper;

import { BROWSER_WARNING, VERSION_WARNING } from '../..';
import Bowser from 'bowser';

declare const window: any;

const CHROME_VERSION = 90;
export const { browser } = Bowser.parse(navigator.userAgent);

export const isChromeBrowser = () => {
  return browser.name === 'Chrome';
};

const checkVersion = (dataString: string) => {
  const versionSearchString: string = 'Chrome';
  const index = dataString.indexOf(versionSearchString);
  if (index === -1) return 0;
  return parseFloat(dataString.substring(index + versionSearchString.length + 1));
};

export const searchBrowserAndVersion = () => {
  if (isChromeBrowser()) {
    if (
      (checkVersion(window.navigator.userAgent) || checkVersion(window.navigator.appVersion)) <
      CHROME_VERSION
    ) {
      return VERSION_WARNING;
    }
  } else {
    return BROWSER_WARNING;
  }
  return '';
};

import { Typography } from '@clef/client-library';
import { Box, Link, makeStyles } from '@material-ui/core';
import React from 'react';

const BANNER_HEIGHT = '200px';

const useStyles = makeStyles(theme => ({
  outerContainer: {
    position: 'relative',
    width: '100vw',
    left: '50%',
    right: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.indigoBlue[25],
    height: BANNER_HEIGHT,
  },
  innerContainer: {
    width: '100%',
    // position: 'relative',
    transform: 'translateY(-100%)',
    height: BANNER_HEIGHT,
    marginBottom: -BANNER_HEIGHT,
  },
  linkText: {
    fontSize: 14,
    color: theme.palette.greyModern[800],
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginRight: theme.spacing(4),
    cursor: 'pointer',
  },
}));

type PricingBannerContent = {
  heading: string;
  body: string;
  links: {
    text: string;
    url?: string;
    onClick?: () => void;
  }[];
};
const PricingBannerCommon = (props: {
  children: React.ReactElement;
  content: PricingBannerContent;
}) => {
  const styles = useStyles();
  const { children, content } = props;
  const { heading, body, links } = content;
  return (
    <Box marginBottom={10} marginTop={-5} height={BANNER_HEIGHT}>
      <Box
        className={styles.outerContainer}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      ></Box>
      <Box
        className={styles.innerContainer}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          width="50%"
          height="100%"
          overflow="auto"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Typography variant="h2_semibold">{heading}</Typography>
          <Box paddingBottom={2} />
          <Typography variant="body_regular">{body}</Typography>
          <Box paddingBottom={2} />
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'flex-start'}
            justifyContent={'center'}
          >
            {links.map((link, index) => {
              if (link.onClick) {
                return (
                  <Link
                    key={index}
                    className={styles.linkText}
                    color="primary"
                    onClick={link.onClick}
                  >
                    {link.text}
                  </Link>
                );
              } else {
                return (
                  <Link
                    key={index}
                    className={styles.linkText}
                    color="primary"
                    href={link.url}
                    target="_blank"
                  >
                    {link.text}
                  </Link>
                );
              }
            })}
          </Box>
        </Box>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};

export default PricingBannerCommon;

import React from 'react';
import { Typography, Grid, makeStyles, Box } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { PrimaryButton } from '../../../components/Auth/Button';
import { BaseTextField } from '../../../components/Auth/TextField';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import { useCreateEndpointMutation } from '@/serverStore/endpoints';
import { useUpdateBundleIsDeletedMutation } from '@/serverStore/modelAnalysis/mutations';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: 'flex',
    margin: theme.spacing(2, 4, 0),
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(0, 4, 7),
  },
  descriptionText: {
    marginBottom: theme.spacing(4),
  },
  endpointNameInput: {
    width: 400,
    marginBottom: theme.spacing(5),
  },
  createEndpointButton: {
    width: 100,
    height: 34,
  },
}));

interface FormValues {
  endpointName: string;
}

export interface CreateEndpointDialogProps {
  open: boolean;
  onClose(): void;
  isClassicModel?: boolean;
  modelInfo?: {
    id: string;
    threshold?: number;
  };
}

export const CreateEndpointDialog: React.FC<CreateEndpointDialogProps> = ({
  open,
  onClose,
  modelInfo,
  isClassicModel,
}) => {
  const styles = useStyles();
  const { control, errors, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      endpointName: 'Untitled endpoint',
    },
  });
  const { mutateAsync: createEndpoint, isLoading: isCreating } = useCreateEndpointMutation();
  const { mutateAsync: updateBundleIsDeleted, isLoading: isUpdateBundleIsDeletedLoading } =
    useUpdateBundleIsDeletedMutation('', modelInfo?.threshold);

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      disablePortal
      title={t('Name Your Endpoint')}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(async ({ endpointName }) => {
          if (modelInfo && modelInfo.threshold !== undefined && !isClassicModel) {
            await updateBundleIsDeleted({
              modelId: modelInfo.id,
              threshold: modelInfo.threshold,
              isDeleted: false,
            });
          }
          await createEndpoint(endpointName, modelInfo);
          onClose();
        })}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Typography className={styles.descriptionText}>
          {t('Name your endpoint to start deploying your models.')}
        </Typography>

        <BaseTextField
          data-testid="endpoint-name-input"
          rules={{
            required: t('This is required.'),
          }}
          placeholder={t('Endpoint name')}
          name="endpointName"
          error={errors?.endpointName}
          control={control}
          className={styles.endpointNameInput}
          enableAutoFocus
        />

        <Grid container justifyContent="flex-end">
          <PrimaryButton
            id="create-endpoint-confirm-button"
            text={t('Create')}
            disabled={!!errors.endpointName}
            isPending={isCreating || isUpdateBundleIsDeletedLoading}
            className={styles.createEndpointButton}
          />
        </Grid>
      </Box>
    </BaseDialog>
  );
};

export default CreateEndpointDialog;

import { BoxAnnotation, getThumbnail, MediaViewer } from '@clef/client-library';
import {
  Annotation,
  AnnotationInstance as AnnotationInstanceType,
  MediaId,
} from '@clef/shared/types';
import { makeStyles } from '@material-ui/core';
import { has as hasProperty, truncate } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDatasetMediaDetailsQuery } from '@/serverStore/dataset';
import useGetDefectById from '../../../hooks/defect/useGetDefectById';
import useGetDefectColorById from '../../../hooks/defect/useGetDefectColorById';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useDefectSelectorWithArchived } from '../../../store/defectState/actions';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import { getBoxAnnotations } from '../../../utils';
import { useDataBrowserState } from '../dataBrowserState';

const useStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
  },
  hoverClickContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    zIndex: 105,
    '&:hover': {
      borderColor: theme.palette.grey[400],
    },
    transition: 'all 0.135s cubic-bezier(0.0,0.0,0.2,1)',
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 8,
    overflow: 'hidden',
  },
}));

export type AnnotationInstanceProps = {
  instance: AnnotationInstanceType;
  onClick?: (mediaId: MediaId) => void;
};

/**
 * Only object detection has instance view, so we can assume that only box annotation instances are supported
 */
const AnnotationInstance: React.FC<AnnotationInstanceProps> = props => {
  const { instance, onClick } = props;
  const styles = useStyles();
  const { datasetId } = useGetSelectedProjectQuery().data ?? {};
  const { mediaId, groundTruthAnnotation, predictionAnnotation } = instance;
  const { id: currentModelId, confidence } = useCurrentProjectModelInfoQuery();
  const { data: mediaDetails } = useDatasetMediaDetailsQuery({
    datasetId,
    mediaId,
    modelId: currentModelId,
  });
  const { state } = useDataBrowserState();
  const { showGroundTruth, showPredictions } = state;
  const getDefectColorById = useGetDefectColorById();
  const getDefectById = useGetDefectById();
  const counter = useRef(1);
  const convertToBoxAnnotation = useCallback(
    (
      annotation:
        | AnnotationInstanceType['groundTruthAnnotation']
        | AnnotationInstanceType['predictionAnnotation'],
    ) => {
      const {
        defectId,
        rangeBox: { xmin, xmax, ymin, ymax },
      } = annotation!;
      return {
        key: annotation?.id ?? ++counter.current,
        color: getDefectColorById(defectId),
        xMin: xmin,
        xMax: xmax,
        yMin: ymin,
        yMax: ymax,
        confidence: hasProperty(annotation, 'confidence')
          ? (annotation as AnnotationInstanceType['predictionAnnotation'])!.confidence
          : 0,
      };
    },
    [getDefectColorById],
  );
  // focused annotations
  const focusedBoxAnnotations = useMemo(() => {
    const result = [] as BoxAnnotation[];
    if (groundTruthAnnotation && showGroundTruth) {
      result.push({
        ...convertToBoxAnnotation(groundTruthAnnotation),
        description: getDefectById(groundTruthAnnotation.defectId)?.name,
      });
    }
    if (predictionAnnotation && showPredictions) {
      result.push({
        ...convertToBoxAnnotation(predictionAnnotation),
        description:
          truncate(getDefectById(predictionAnnotation.defectId).name, { length: 20 }) +
          ' ' +
          predictionAnnotation.confidence.toFixed(2),
        isPrediction: true,
      });
    }
    return result;
  }, [
    convertToBoxAnnotation,
    getDefectById,
    groundTruthAnnotation,
    predictionAnnotation,
    showGroundTruth,
    showPredictions,
  ]);
  const focusRangeBox = useMemo(() => {
    const xmin = Math.min(...focusedBoxAnnotations.map(annotation => annotation.xMin));
    const ymin = Math.min(...focusedBoxAnnotations.map(annotation => annotation.yMin));
    const xmax = Math.max(...focusedBoxAnnotations.map(annotation => annotation.xMax));
    const ymax = Math.max(...focusedBoxAnnotations.map(annotation => annotation.yMax));
    return { xmin, xmax, ymin, ymax };
  }, [focusedBoxAnnotations]);

  // all annotations
  const defects = useDefectSelectorWithArchived();
  const confidenceThreshold = confidence ?? 0;
  const annotations = (mediaDetails?.label?.annotations as Annotation[]) ?? [];
  const predictionAnnotations = (mediaDetails?.predictionLabel?.annotations as Annotation[]) ?? [];

  const boxGroundTruthAnnotations = getBoxAnnotations(annotations, defects!).map(ann => ({
    ...ann,
    description: ann.key === groundTruthAnnotation?.id ? ann.description : undefined,
  }));
  const boxPredictionAnnotations = getBoxAnnotations(
    predictionAnnotations,
    defects!,
    confidenceThreshold,
  ).map(ann => ({
    ...ann,
    description:
      ann.key === predictionAnnotation?.id && ann.confidence !== undefined
        ? truncate(ann.description, { length: 20 }) + ' ' + ann.confidence.toFixed(2)
        : undefined,
  }));
  const allBoxAnnotations = useMemo(() => {
    return [
      ...(showGroundTruth ? boxGroundTruthAnnotations : []),
      ...(showPredictions ? boxPredictionAnnotations : []),
    ];
  }, [boxGroundTruthAnnotations, boxPredictionAnnotations, showGroundTruth, showPredictions]);

  const [isHover, setIsHover] = useState(false);

  // hover to show all, otherwise only show focused
  const boxAnnotations = useMemo(() => {
    return isHover ? allBoxAnnotations : focusedBoxAnnotations;
  }, [allBoxAnnotations, focusedBoxAnnotations, isHover]);

  return (
    <>
      {/* hover indicator */}
      <div
        data-testid="annotation-instance-hover-container"
        className={styles.hoverClickContainer}
        onClick={() => onClick?.(mediaId)}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      ></div>
      <MediaViewer
        imgSrc={getThumbnail(mediaDetails, 'medium')}
        fallbackImgSrc={mediaDetails?.url}
        boxAnnotations={boxAnnotations}
        properties={mediaDetails?.properties}
        focusBox={focusRangeBox}
        classes={{ root: styles.root }}
      />
    </>
  );
};

export default AnnotationInstance;

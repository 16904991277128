import React from 'react';
import { Box, Divider, Typography, makeStyles } from '@material-ui/core';
import ModelCountSvg from '@/images/project/model_count.svg';
import FilterIcon from '@material-ui/icons/Filter';
import cx from 'classnames';
import { isNil } from 'lodash';
import { DatasetVersionId } from '@clef/shared/types';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    width: '100%',
    borderRadius: theme.spacing(2),
    borderStyle: 'solid',
    borderWidth: 1,
    padding: theme.spacing(4),
    margin: theme.spacing(3, 0, 0, 0),
  },
  unselectedCardContainer: {
    backgroundColor: theme.palette.greyModern[50],
    borderColor: theme.palette.greyModern[300],
    '&:hover': {
      borderWidth: 2,
      backgroundColor: theme.palette.greyModern[100],
    },
  },
  selectedCardContainer: {
    width: '100%',
    backgroundColor: theme.palette.purple[50],
    borderColor: theme.palette.purple[300],
    borderRadius: theme.spacing(2),
    borderStyle: 'solid',
    borderWidth: 2,
    padding: theme.spacing(4),
    margin: theme.spacing(3, 0, 0, 0),
  },
  nameText: {
    fontWeight: 'bold',
  },
  descriptionText: {
    fontColor: theme.palette.greyModern[500],
  },
  creatorText: {
    fontColor: theme.palette.greyModern[500],
  },
  paddingVertical1: {
    padding: theme.spacing(1, 0, 1, 0),
  },
  divider: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  paddingLeft1: {
    paddingLeft: theme.spacing(1),
  },
  marginLeft0dot5: {
    marginLeft: theme.spacing(0.5),
  },
  marginLeft1: {
    marginLeft: theme.spacing(1),
  },
  filterIcon: {
    fontSize: theme.spacing(4.5),
  },
  modelCountSvg: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

export type DataSnapshotCardProps = {
  id: DatasetVersionId;
  name: string;
  description: string;
  creator?: string;
  mediaCount?: number;
  modelsCount?: number;
  isSelected: boolean;
  handleClick: (id: DatasetVersionId) => void;
};

const DataSnapshotCard: React.FC<DataSnapshotCardProps> = props => {
  const styles = useStyles();
  const { id, name, description, creator, mediaCount, modelsCount, isSelected, handleClick } =
    props;
  return (
    <Box
      onClick={() => handleClick(id)}
      display="flex"
      flexDirection="column"
      className={cx(
        styles.cardContainer,
        isSelected ? styles.selectedCardContainer : styles.unselectedCardContainer,
      )}
    >
      <Typography className={cx(styles.nameText, styles.paddingVertical1)}>{name}</Typography>
      <Typography className={cx(styles.descriptionText, styles.paddingVertical1)}>
        {description}
      </Typography>
      <Divider className={styles.divider} />
      <Box
        className={styles.paddingVertical1}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography className={styles.creatorText}>
          {!isNil(creator) ? t('by {{creator}}', { creator }) : t('Auto')}
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          {!isNil(mediaCount) && (
            <Box display="flex" alignItems="center">
              <FilterIcon className={styles.filterIcon} />
              <Typography className={styles.marginLeft1}>{mediaCount}</Typography>
            </Box>
          )}
          {!isNil(modelsCount) && (
            <Box display="flex" alignItems="center" className={styles.paddingLeft1}>
              <img className={cx(styles.modelCountSvg, styles.paddingLeft1)} src={ModelCountSvg} />
              <Typography className={styles.marginLeft0dot5}>{modelsCount}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DataSnapshotCard;

import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  searchIcon: {
    color: theme.palette.grey[500],
    marginRight: theme.spacing(1),
  },
}));

interface BasicFilterSearchBarProps {
  filterKey: string | null;
  handleFilterKeyChange: (value: string | null) => void;
  options: string[];
  placeholderText: string;
  showSearchIcon?: boolean;
}

const BasicFilterSearchBar = (props: BasicFilterSearchBarProps) => {
  const { options, filterKey, handleFilterKeyChange, showSearchIcon, placeholderText } = props;
  const styles = useStyles();
  return (
    <Autocomplete
      id="add-evaluation-set-data-snapshot-filter"
      options={options}
      value={filterKey}
      freeSolo={true}
      open={false}
      renderInput={params => (
        <TextField
          {...params}
          size="small"
          placeholder={placeholderText}
          margin="dense"
          variant="outlined"
          InputProps={
            showSearchIcon
              ? {
                  startAdornment: (
                    <>
                      <SearchIcon className={styles.searchIcon} />
                    </>
                  ),
                }
              : undefined
          }
        />
      )}
      onInputChange={(_event, value, _reason) => {
        handleFilterKeyChange(value);
      }}
    />
  );
};

export default BasicFilterSearchBar;

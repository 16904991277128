import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { omit } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useImmer } from 'use-immer';
import GroupedImages from '../../components/GroupedImages/GroupedImages';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import {
  DataBrowserStateContext,
  getStateFromStorage,
  saveStateToStorage,
  getSelectedMediaCount,
  useDataBrowserState,
} from './dataBrowserState';
import { useDataBrowserStyles, SidebarWidth } from './styles';
import MoreButton from './MoreButton';
import { BottomDrawer, Button, IconButton, usePrevious } from '@clef/client-library';
import { useHistory } from 'react-router';
import CLEF_PATH from '../../constants/path';
import { useVPLatestModel } from '@/serverStore/projectModels';
import DataBrowserEmptyStateSvg from '../../images/empty-state/data-browser.svg';
import { MIN_LABELED_MEDIA_FOR_FAST_N_EASY_TRAIN } from '../../constants/model_train';
import { useDialog } from '../../components/Layout/components/useDialog';
import { LabelStatus, ModelStatus, UserPermission } from '@clef/shared/types';
import { useHasPermission } from '../../hooks/useProjectRolePermissions';
import { useTotalMediaCount, useTotalMediaOrInstanceCount } from '../DataBrowser/utils';
import cx from 'classnames';
import MediaSelectionActions from './MediaSelectionActions';
import classNames from 'classnames';
import DataBrowserUploadDropZone from './DataBrowserUploadDropZone';
import { useSnackbar } from 'notistack';
import FREEmptyPage from './FREEmptyPage/FREEmptyPage';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import Toolbar from './Toolbar';
import { PredictContextProvider } from '../../components/Predict/predictContext';
import { useLabelingDrawer } from '@/components/Labeling/LabelingDrawer';
import { useUpdateProjectMutation } from '@/serverStore/projects';
import useHandleTrainingComplete from '../InstantLearningLabeling/useHandleTrainingComplete';
import { useModelStatusQuery } from '@/serverStore/projectModels';
import { useDatasetMediasQuery } from '@/serverStore/dataset';

const InstantLearningDataBrowserInternal: React.FC<{}> = () => {
  const { id: projectId, labelType } = useGetSelectedProjectQuery().data ?? {};
  const { state, dispatch } = useDataBrowserState();

  useEffect(() => {
    projectId && saveStateToStorage(projectId, state);
  }, [projectId, state]);
  const styles = useDataBrowserStyles();
  const history = useHistory();
  const updateProject = useUpdateProjectMutation();
  const { appliedFilters, rightSidebar, sortField } = state;
  const totalMediaOrInstanceCount = useTotalMediaOrInstanceCount(state);
  const {
    data: datasetMedias,
    isLoading: datasetMediasLoading,
    error: datasetMediasError,
  } = useDatasetMediasQuery({});

  const onTogglePredictionLabels = useCallback(
    (show: boolean, imageGroup: LabelStatus) => {
      dispatch(draft => {
        if (imageGroup === LabelStatus.Unlabeled) {
          draft.showPredictionUnlabeled = show;
        } else if (imageGroup === LabelStatus.Labeled) {
          draft.showPredictionLabeled = show;
        }
      });
    },
    [dispatch],
  );

  const { latestModel } = useVPLatestModel();
  const { id: latestModelId } = latestModel ?? {};
  const { openLoadSampleData, openUpload } = useDialog();
  const canUploadData = useHasPermission(UserPermission.UploadData);
  const { data: totalMediaCount } = useTotalMediaCount();

  const { data: modelStatusRes } = useModelStatusQuery(projectId, latestModelId);
  // TODO: @tian.lan migrate VP polling with jotai + react query
  // Need to handle training complete here for this use case:
  // User goes to image details, draws some labels, runs a train, then goes back to data browser
  // before training completed.
  const modelStatus = modelStatusRes?.status;
  const prevModelStatus = usePrevious(modelStatus);
  const handleTrainingComplete = useHandleTrainingComplete();
  useEffect(() => {
    if (
      prevModelStatus &&
      modelStatus &&
      prevModelStatus !== modelStatus &&
      [ModelStatus.ALLBatches, ModelStatus.Succeed].includes(modelStatus)
    ) {
      handleTrainingComplete();
    }
  }, [handleTrainingComplete, modelStatus, prevModelStatus]);

  // Store total media count
  useEffect(() => {
    if (totalMediaCount) {
      dispatch(draft => {
        draft.totalMediaCount = totalMediaCount;
      });
    }
  }, [dispatch, totalMediaCount]);
  const selectedMediaCount = totalMediaOrInstanceCount ? getSelectedMediaCount(state) : 0;
  const { enqueueSnackbar } = useSnackbar();

  const { closeDrawer } = useLabelingDrawer();
  useEffect(() => {
    closeDrawer();
  }, []);

  // Reset pageIndex to 0 when filter or sortField or mode changed
  useEffect(() => {
    dispatch(draft => {
      draft.pageIndex = 0;
      draft.secondaryPageIndex = 0;
    });
  }, [appliedFilters, dispatch, sortField]);

  if (
    !datasetMediasLoading &&
    // If there is error, leave the following normal logic to handle
    !datasetMediasError &&
    !datasetMedias?.length
  ) {
    return (
      <FREEmptyPage
        changeLabelType={async newLabelType => {
          if (newLabelType === labelType || !projectId) {
            return;
          }
          updateProject.mutate(
            {
              id: projectId,
              labelType: newLabelType,
            },
            {
              onError: e =>
                enqueueSnackbar((e as Error).message, {
                  variant: 'error',
                  autoHideDuration: 12000,
                }),
            },
          );
        }}
        changingLabelType={updateProject.isLoading}
      />
    );
  }
  return (
    <DataBrowserUploadDropZone disabled={state.isFirstRunLiveExperienceModalOpen}>
      <Box paddingRight={rightSidebar ? SidebarWidth + 'px' : undefined}>
        <Box
          className={classNames(styles.topBar, styles.freTopBar)}
          flex={1}
          paddingRight={rightSidebar ? SidebarWidth + 'px' : undefined}
        >
          <Box className={styles.topActionButton}>
            <MoreButton onlyClasses />
            <IconButton
              id="instant-learning-upload"
              onClick={() => openUpload()}
              data-testid="data-browser-upload-btn"
              className={styles.uploadIconButton}
              tooltip={t('Select and upload images from a directory.')}
            >
              <CloudUploadOutlinedIcon className={styles.uploadIcon} />
            </IconButton>
          </Box>
        </Box>
        <Toolbar />
        <BottomDrawer
          show={!!selectedMediaCount}
          className={cx(
            styles.scrollStickyContainer,
            styles.newTopDrawer,
            rightSidebar && styles.shrinkedBottomDrawerWidth,
          )}
        >
          <MediaSelectionActions
            onlyRemove
            style={{
              width: `calc(80vw - 400px - ${rightSidebar ? SidebarWidth : 0}px)`,
            }}
          />
        </BottomDrawer>
        <Box marginTop={4} />
        {datasetMediasLoading ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size="26px" data-testid="api-response-loader" />
          </Box>
        ) : !datasetMedias?.length ? (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={styles.emptyStateContainer}
            direction="column"
          >
            <img src={DataBrowserEmptyStateSvg} className={styles.emptyStateImage} />
            <Typography variant="h3" className={styles.emptyStateText}>
              {t('Start with Data')}
            </Typography>
            <Typography variant="body1" className={styles.emptyStateText}>
              {t('Upload and label at least {{count}} images to train your first model.', {
                count: <strong>{MIN_LABELED_MEDIA_FOR_FAST_N_EASY_TRAIN}</strong>,
              })}
            </Typography>
            <Grid container alignItems="center" justifyContent="center">
              <Button
                id="empty-state-import-sample-data-button"
                variant="outlined"
                onClick={openLoadSampleData}
                // @ts-ignore target does exist on MuiButton
                target="_blank"
                color="primary"
                className={styles.emptyStateDownloadSampleDataButton}
              >
                {t('Import Sample Data')}
              </Button>
              {canUploadData && (
                <Button
                  id="empty-state-upload-button"
                  variant="contained"
                  color="primary"
                  onClick={() => openUpload()}
                >
                  {t('Upload')}
                </Button>
              )}
            </Grid>
          </Grid>
        ) : (
          <GroupedImages
            onImageClick={mediaId => {
              history.push(`${CLEF_PATH.data.instantLearningLabel}?mediaId=${mediaId}`);
            }}
            showGroundTruthLabels={state.showGroundTruth}
            showPredictionLabels={state.showPredictions}
            showPredictionLabeled={state.showPredictionLabeled}
            showPredictionUnlabeled={state.showPredictionUnlabeled}
            setShowGroundTruthLabels={show =>
              dispatch(draft => {
                draft.showGroundTruth = show;
              })
            }
            setShowPredictionLabels={latestModelId ? onTogglePredictionLabels : undefined}
          />
        )}
        {/* media grid */}
      </Box>
    </DataBrowserUploadDropZone>
  );
};

const InstantLearningDataBrowser: React.FC<{}> = () => {
  const { id: projectId, datasetId, labelType } = useGetSelectedProjectQuery().data ?? {};
  const [state, dispatch] = useImmer(omit(getStateFromStorage(projectId), 'labelDisplay'));
  const { latestModel } = useVPLatestModel();
  const { id: latestModelId } = latestModel ?? {};

  if (!projectId) {
    return null;
  }

  return (
    <DataBrowserStateContext.Provider value={{ state, dispatch }}>
      <PredictContextProvider
        projectId={projectId}
        datasetId={datasetId}
        labelType={labelType}
        modelId={latestModelId}
      >
        <InstantLearningDataBrowserInternal />
      </PredictContextProvider>
    </DataBrowserStateContext.Provider>
  );
};

export default InstantLearningDataBrowser;

import React, { useMemo } from 'react';
import { Box, Modal, Paper } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { Media, Pipeline, TransformType } from '@clef/shared/types';
import { PreviewDimensions } from '../../../types/client';
import { useJobStyles } from '../../model_iteration/jobStyles';
import MediaGrid from '../../model_iteration/components/MediaGrid/MediaGrid';
import { refreshTransformImages, useTransformImages } from '../../../hooks/api/useTransformsApi';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

export interface PreviewModalProps {
  open: boolean;
  onClose: () => void;
  activeStep: string;
  loadingAllMedia: boolean;
  applyPipelineSections: (section: Pipeline['sections'], sectionType: TransformType) => void;
  pipelineSections?: Pipeline['sections'];
  previewDimensions?: PreviewDimensions | null;
  paginatedMedias: Media[];
}

const PreviewModal: React.FC<PreviewModalProps> = ({
  activeStep,
  open,
  onClose,
  loadingAllMedia,
  pipelineSections,
  previewDimensions,
  paginatedMedias,
}) => {
  const styles = useJobStyles();
  const projectId = useTypedSelector(state => state.project.selectedProjectId);
  const transformImagesParams =
    pipelineSections && paginatedMedias.length
      ? {
          sources: paginatedMedias.map(t => t.path || ''),
          sections: pipelineSections,
          projectId,
        }
      : undefined;
  const [transformedUrls, transforming] = useTransformImages(transformImagesParams);
  const medias = useMemo(() => {
    return paginatedMedias.map((media, index) => ({
      ...media,
      url: !transforming ? transformedUrls?.[index] : undefined,
    }));
  }, [paginatedMedias, transformedUrls, transforming]);

  return (
    <Modal open={open} onClose={onClose} className={styles.modal} disableEnforceFocus>
      <Paper elevation={0} className={styles.paperContainer}>
        <h2>{t(`Preview ${activeStep?.toLowerCase()} effect`)}</h2>
        {activeStep === 'Augmentation' && (
          <Box pb={4}>
            <Button
              id="re-apply-effect"
              onClick={() =>
                transformImagesParams &&
                refreshTransformImages({ keys: transformImagesParams, swr: false })
              }
              disabled={loadingAllMedia || transforming}
              variant="contained"
              color="primary"
            >
              {t('Re-Apply')}
            </Button>
          </Box>
        )}
        <MediaGrid
          mediasData={[]}
          previewDimensions={previewDimensions}
          loadingAllMedia={loadingAllMedia || transforming}
          paginatedMedias={medias}
          page={0}
          setPage={() => null}
          showPagination={false}
        />
      </Paper>
    </Modal>
  );
};

export default PreviewModal;

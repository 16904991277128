import { Draft } from 'immer';
import { createContext, useContext } from 'react';
import { TaskState } from '../../store/types';

export const defaultState: TaskState = {
  tasks: [],
  tasksStats: {},
};

/**
 * Context
 */
export const TaskContext = createContext<{
  state: TaskState;
  dispatch: (f: (state: Draft<TaskState>) => void | TaskState) => void;
}>({
  state: defaultState,
  dispatch: () => {},
});

export const useTaskState = () => useContext(TaskContext);

import React, { useCallback, useMemo, useState } from 'react';
import { ClassChip, ClassifiedClass, defectColors } from '@clef/client-library';
import {
  Media,
  EdgeMedia,
  MediaDetailsWithPrediction,
  MediaStatusType,
  AnnotationType,
  AnnotationWithoutId,
  MediaLevelLabel,
  LabelSource,
  LabelType,
} from '@clef/shared/types';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useDefectSelectorWithArchived } from '../../../store/defectState/actions';
import { getClassifiedClass, getDefectColor, produceSetClassMediaDetails } from '../../../utils';
import { useSnackbar } from 'notistack';
import { makeStyles, Tooltip } from '@material-ui/core';
import CLEF_PATH from '../../../constants/path';
import { Link } from 'react-router-dom';
import LabelAPI from '../../../api/label_api';
import CreateDefectDialog from '../../DefectBook/DefectList/CreateDefectDialog';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import { useQueryClient } from '@tanstack/react-query';
import { datasetQueryKeys } from '@/serverStore/dataset';

export const useClassChipStyles = makeStyles(() => ({
  toDefect: {
    color: '#659bf3',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
}));

export type MediaClassifiedClassProps = {
  media: Media | EdgeMedia;
  mediaDetails?: MediaDetailsWithPrediction;
  enableSetClass?: boolean;
  enableColor?: boolean;
  isPrediction?: boolean;
};

const MediaClassifiedClass: React.FC<MediaClassifiedClassProps> = ({
  media,
  mediaDetails,
  enableSetClass,
  enableColor,
  isPrediction = false,
}) => {
  const { id } = media;

  const styles = useClassChipStyles();
  const { id: currentUserId } = useTypedSelector(state => state.login.user!);
  const { id: projectId, datasetId, labelType } = useGetSelectedProjectQuery().data ?? {};
  const defects = useDefectSelectorWithArchived() ?? [];
  const annotations =
    (isPrediction
      ? mediaDetails?.predictionLabel?.annotations
      : mediaDetails?.label?.annotations) || [];
  const [loading, setLoading] = useState(false);
  const allClassifiedClasses = defects.map(defect => ({
    id: defect.id,
    name: defect.name,
    color: getDefectColor(defect),
  })) as ClassifiedClass[];

  const classifiedClass = getClassifiedClass(annotations, defects!);

  const { enqueueSnackbar } = useSnackbar();

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  const queryClient = useQueryClient();
  const handleSetClass = useCallback(
    async (newClassifiedClass: ClassifiedClass) => {
      if (
        newClassifiedClass.id === classifiedClass?.id ||
        !mediaDetails ||
        !datasetId ||
        !projectId
      ) {
        return;
      }
      setLoading(true);
      try {
        const mediaId = id;
        const defectId = newClassifiedClass.id;
        // upload pascal voc
        const serverAnnotation = {
          defectId,
          annotationType: AnnotationType.classification,
          dataSchemaVersion: 3,
        } as AnnotationWithoutId;
        const newMediaDetail = produceSetClassMediaDetails(
          mediaDetails ?? ({} as MediaDetailsWithPrediction),
          {
            currentUserId,
            defectId,
          },
        );
        queryClient.setQueryData(
          datasetQueryKeys.mediaDetails(datasetId, {
            mediaId,
            modelId: currentModelId,
          }),
          newMediaDetail,
        );

        await LabelAPI.upsertLabels({
          projectId,
          mediaId: mediaDetails!.id,
          annotations: [serverAnnotation],
          mediaLevelLabel: MediaLevelLabel.NG,
          source: LabelSource.DirectLabeling,
          modelId: currentModelId,
        });

        queryClient.invalidateQueries(datasetQueryKeys.allStats(projectId));
        queryClient.invalidateQueries(
          datasetQueryKeys.mediaDetails(datasetId, {
            mediaId,
            modelId: currentModelId,
          }),
        );
        enqueueSnackbar(
          t(`Successfully set class to {{className}}`, { className: newClassifiedClass.name }),
          { variant: 'success' },
        );
      } catch (e) {
        enqueueSnackbar((e as Error).message, { variant: 'error' });
      }
      setLoading(false);
    },
    [
      classifiedClass?.id,
      mediaDetails,
      datasetId,
      id,
      projectId,
      currentUserId,
      currentModelId,
      enqueueSnackbar,
      queryClient,
    ],
  );

  const mediaStatus = mediaDetails?.mediaStatus;
  const isInTask = mediaStatus === MediaStatusType.InTask;
  const classifiedClassOrText = useMemo(() => {
    if (isPrediction) {
      return classifiedClass ?? t('Not predicted');
    }
    if (isInTask) {
      return t('In Task');
    } else if (mediaStatus === MediaStatusType.Raw) {
      return t('- Select -');
    }
    return classifiedClass;
  }, [isPrediction, isInTask, mediaStatus, classifiedClass]);

  const toolTip = useMemo(() => {
    if (isPrediction && !classifiedClass) {
      return t('Not in last model run. Please train a new model to see prediction on it');
    }
    if (!isPrediction && isInTask) {
      return t('Media is in a labeling task');
    }
    if (!isPrediction && allClassifiedClasses.length === 0) {
      return t('No defect was found. Please create one in the {{link}}.', {
        link: (
          <Link className={styles.toDefect} to={CLEF_PATH.data.defectBookEnhanced}>
            {t('defect book')}
          </Link>
        ),
      });
    }
    return '';
  }, [allClassifiedClasses.length, classifiedClass, isInTask, isPrediction, styles.toDefect]);

  return (
    <Tooltip arrow interactive title={toolTip}>
      <div data-testid="set-class-trigger">
        <ClassChip
          classifiedClass={classifiedClassOrText}
          loading={loading}
          allClassifiedClasses={allClassifiedClasses}
          onClassChange={enableSetClass && !isInTask && !isPrediction ? handleSetClass : undefined}
          enableColor={enableColor}
          dotted={isPrediction}
          openCreateDialog={() => {
            setOpenCreateDialog(true);
          }}
          allowCreateClass={labelType != LabelType.AnomalyDetection}
        />
        {openCreateDialog && (
          <CreateDefectDialog
            onClose={() => setOpenCreateDialog(false)}
            nextDefectIndex={(defects?.length ?? 0) % defectColors.length}
            alertText={t(
              // eslint-disable-next-line max-len
              'You can delete inactive classes before they are used to annotate your dataset. To delete an active defect class, either delete all label instances or delete the labeled images.',
            )}
            onCreateSuccess={defect => {
              if (enableSetClass && !isInTask && !isPrediction && defect.color) {
                handleSetClass({
                  id: defect.id,
                  name: defect.name,
                  color: defect.color,
                });
              }
              setOpenCreateDialog(false);
            }}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default MediaClassifiedClass;

import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Route, Switch, Redirect } from 'react-router-dom';
import FRELandingSVG from '@/images/logo-stacked-white.svg';
import LoginTab from './components/LoginTab';
import ForgotPasswordTab from './components/ForgotPasswordTab';
import { StyledComponentProps } from '@material-ui/styles/withStyles';
import styles from './styles';
import { LoginTabProps, SignupTabProps, ForgotPasswordTabProps } from './types';
import SingleSignOnPage from './components/SingleSignOnPage';

interface ILoginProps extends Required<StyledComponentProps> {
  match: object;
  location: object;
  history: object;
  activeTabId: number;
  handleTabChange: (...args: any[]) => any;
}

type LoginProps = ILoginProps & (LoginTabProps | SignupTabProps | ForgotPasswordTabProps);
const Login: React.FC<LoginProps> = ({ classes, activeTabId, handleTabChange, ...props }) => (
  <Grid container className={classes.container}>
    <div className={classes.logotypeContainer}>
      <img src={FRELandingSVG} alt="logo" className={classes.logotypeImage} />
      <Typography color="primary" className={classes.copyright}>
        {`© 2017-${new Date().getFullYear()} Landing AI. ` + t('All rights reserved.')}
      </Typography>
    </div>
    <div className={classes.formContainer}>
      <div className={classes.form}>
        <Switch>
          <Route
            exact
            path="/legacy_login"
            render={() => <Redirect to={{ ...props.location, pathname: '/legacy_login/0' }} />}
          />
          <Route
            path="/legacy_login/0"
            // @ts-ignore
            render={() => <LoginTab {...props} />}
          />
          <Route
            path="/legacy_login/sso"
            render={() => (
              // @ts-ignore
              <SingleSignOnPage {...props} />
            )}
          />
          <Route
            path="/legacy_login/forgot"
            render={() => (
              // @ts-ignore
              <ForgotPasswordTab {...props} />
            )}
          />
        </Switch>
      </div>
    </div>
  </Grid>
);
// @ts-ignore
export default withStyles(styles, { withTheme: true })(Login);

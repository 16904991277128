import { useState } from 'react';
import LRUCache from 'lru-cache';
import { useDebouncedEffect } from '../../hooks/useDebouncedEffect';
import { runLengthDecode } from '@clef/shared/utils';
import { createPixelatedCanvas } from '../../utils/canvas';

export const PaddingRatio = 0.25;

let textWidthCanvas = null as OffscreenCanvas | null;
const textWidthCache = {} as Record<string, number>;
const getTextWidth = (text: string) => {
  if (textWidthCache[text]) {
    return textWidthCache[text];
  }

  if (!textWidthCanvas) {
    textWidthCanvas = new OffscreenCanvas(1024, 30);
  }

  const ctx = textWidthCanvas.getContext('2d');
  if (!ctx) return 0;
  ctx.font = `bold 12px Commissioner, sans-serif`;
  const { width } = ctx.measureText(text);
  textWidthCache[text] = width;
  return width;
};

type TextPositionCalculatorProps = {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
  imageWidth: number;
  imageHeight: number;
  scale: number;
  text: string;
};
export const buildTextPositionCalculators = (
  props: TextPositionCalculatorProps,
  tooltipGap: number = 0,
) => {
  const { xMin, xMax, yMin, yMax, imageWidth, imageHeight, scale, text } = props;
  const boxWidth = xMax - xMin;
  const boxHeight = yMax - yMin;
  const fontSize = 12 / scale;
  const backgroundHeight = fontSize * 1.5;
  const backgroundWidth = getTextWidth(text) / scale + 2 * fontSize;

  const padding = Math.max(boxWidth, boxHeight) * PaddingRatio;

  const offset = 2 / scale;

  const top = () =>
    // there are enough space on the top
    yMin > backgroundHeight + tooltipGap && {
      // if text clipped by right side, need to right-align
      x: xMin + backgroundWidth > imageWidth ? boxWidth - backgroundWidth : 0,
      y: -backgroundHeight - tooltipGap - offset,
    };
  const topRight = () =>
    // there are enough space on the top
    yMin > backgroundHeight && {
      // if text clipped by left side, need to left-align
      x: xMin - backgroundWidth < 0 ? 0 : boxWidth - backgroundWidth,
      y: -backgroundHeight - offset,
    };
  const bottom = () =>
    // there are enough space on the bottom
    yMax < imageHeight - backgroundHeight - tooltipGap && {
      // if text clipped by right side, need to right-align
      x: xMin + backgroundWidth > imageWidth ? boxWidth - backgroundWidth : 0,
      y: boxHeight + offset + tooltipGap,
    };
  const bottomRight = () =>
    // there are enough space on the bottom
    yMax < imageHeight - backgroundHeight && {
      // if text clipped by left side, need to left-align
      x: xMax - backgroundWidth < 0 ? 0 : boxWidth - backgroundWidth,
      y: boxHeight + offset,
    };
  const left = () =>
    // there are enough space on the left
    xMin > backgroundWidth &&
    backgroundWidth < padding && {
      x: -backgroundWidth - offset,
      y: 0,
    };
  const right = () =>
    // there are enough space on the right
    imageWidth - xMax > backgroundWidth &&
    backgroundWidth < padding && {
      x: boxWidth + offset,
      y: 0,
    };
  const inner = () => ({
    x: offset,
    y: offset,
  });
  const innerRight = () => ({
    x: boxWidth - backgroundWidth + offset,
    y: offset,
  });
  return { top, topRight, bottom, bottomRight, left, right, inner, innerRight };
};

export type PositionPriority = keyof ReturnType<typeof buildTextPositionCalculators>;
export const DefaultPositionPriorities: PositionPriority[] = [
  'top',
  'left',
  'right',
  'bottom',
  'inner', // must be the last one
];
export const ReversedPositionPriorities: PositionPriority[] = [
  'bottomRight',
  'right',
  'left',
  'topRight',
  'innerRight', // must be the last one
];
export const DefaultTooltipPositionPriorities: PositionPriority[] = ['top', 'bottom'];

export const calcAnnotationTextPositions = (
  props: TextPositionCalculatorProps,
  priorities: PositionPriority[] = DefaultPositionPriorities,
) => {
  const { scale, text } = props;
  const fontSize = 12 / scale;
  const backgroundHeight = fontSize * 1.5;
  const backgroundWidth = getTextWidth(text) / scale + 2 * fontSize;

  const rectProps = {
    x: 0,
    y: 0,
    width: backgroundWidth,
    height: backgroundHeight,
  };
  const calculators = buildTextPositionCalculators(props);
  for (const priority of priorities) {
    const position = calculators[priority]();
    if (position) {
      rectProps.x = position.x;
      rectProps.y = position.y;
      break;
    }
  }

  const textProps = {
    textAnchor: 'middle' as const,
    alignmentBaseline: 'middle' as const,
    x: (rectProps.x as number) + backgroundWidth * 0.5,
    y: (rectProps.y as number) + backgroundHeight * 0.5,
  };

  return [textProps, rectProps] as [typeof textProps, typeof rectProps];
};

export const calcAnnotationTooltipPositions = (
  props: TextPositionCalculatorProps & { arrowSize: { width: number; height: number } },
  priorities: PositionPriority[] = DefaultPositionPriorities,
) => {
  const { xMin, xMax, yMin, scale, text, arrowSize } = props;
  const fontSize = 12 / scale;
  const backgroundHeight = fontSize * 2;
  const backgroundWidth = getTextWidth(text) / scale + 2 * fontSize;
  const boxWidth = xMax - xMin;

  const rectProps = {
    x: 0,
    y: 0,
    width: backgroundWidth,
    height: backgroundHeight,
    arrowX: 0,
    arrowY: 0,
    arrowRotate: 0,
  };
  const calculators = buildTextPositionCalculators(props, fontSize); // fontSize is suitable for the gap between tooltip and annotation
  for (const priority of priorities) {
    const position = calculators[priority]();
    if (position) {
      rectProps.x = xMin + position.x;
      rectProps.y = yMin + position.y;
      if (priority === 'top') {
        rectProps.arrowX = Math.min(xMin + boxWidth / 2, xMin + backgroundWidth - arrowSize.width);
        rectProps.arrowY = yMin + position.y + backgroundHeight - 1;
      } else if (priority === 'bottom') {
        rectProps.arrowX = Math.min(xMin + boxWidth / 2, xMin + backgroundWidth - arrowSize.width);
        rectProps.arrowY = yMin + position.y;
        rectProps.arrowRotate = 180;
      }
      break;
    }
  }

  return rectProps as typeof rectProps;
};

const bitmapDataUrlCache = new LRUCache<string, string>({
  max: 200,
  maxAge: 1000 * 60 * 15, // 15 min
});

/**
 * When used for preview we resize the canvas to 512 x 512;
 * For the others we keep the original size.
 */
export const useCompressedBitMapDataUrl = (
  compressedBitMap: string | OffscreenCanvas,
  color: string,
  width: number,
  height: number,
  compress?: boolean,
  opacity: number = 0.5,
) => {
  const [dataUrl, setDataUrl] = useState<string | undefined>();
  useDebouncedEffect(() => {
    if (typeof compressedBitMap === 'string') {
      // Directly return cached data url if hit cache
      const cacheKey = JSON.stringify({
        compressedBitMap,
        color,
        width,
        height,
        compress,
        opacity,
      });
      if (bitmapDataUrlCache.has(cacheKey)) {
        setDataUrl(bitmapDataUrlCache.get(cacheKey));
        return;
      }
      const bitMap = runLengthDecode(compressedBitMap);
      const offscreen = createPixelatedCanvas(
        { width, height },
        ({ i }) => bitMap[i] === '1',
        color,
        opacity,
        compress ? { baseWidth: 512, baseHeight: 512 } : { baseWidth: width, baseHeight: height },
      );
      offscreen.convertToBlob().then(value => {
        const imgSrc = URL.createObjectURL(value);
        bitmapDataUrlCache.set(cacheKey, imgSrc);
        setDataUrl(imgSrc);
      });
    } else {
      compressedBitMap.convertToBlob().then(value => setDataUrl(URL.createObjectURL(value)));
    }
  }, [compressedBitMap, color, width, height, compress, opacity]);
  return dataUrl;
};

import { withRouter, RouteComponentProps } from 'react-router-dom';
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { withStyles, WithStyles } from '@material-ui/styles';
import styles from '../../components/Metadata/styles';
import MetadataTable from '../../components/Metadata/MetadataTable';
import MetadataDialog from '../../components/Metadata/MetadataDialog';

interface MetadataPageProps extends WithStyles<typeof styles>, RouteComponentProps {}

const MetadataPage: React.FunctionComponent<MetadataPageProps> = ({ classes }) => {
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  return (
    <Grid container>
      <Grid item xs={12} className={classes.addNewFieldButtonStyle}>
        <Button
          variant="contained"
          color="primary"
          id="create-metadata"
          className="cy-create-metadata"
          onClick={() => {
            setAddDialogOpen(true);
          }}
        >
          {t('Add A New Field')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <MetadataTable />
      </Grid>
      {addDialogOpen ? (
        <MetadataDialog
          open={addDialogOpen}
          closeDialog={() => {
            setAddDialogOpen(false);
          }}
        />
      ) : null}
    </Grid>
  );
};

export default withStyles(styles)(withRouter(MetadataPage));

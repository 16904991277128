import { dateToISOString } from '@/utils/date_utils';
import { ApiResponseLoader } from '@clef/client-library';
import { Box, Typography } from '@material-ui/core';
import { addDays } from 'date-fns';
import React, { useMemo } from 'react';
import { useGetProjectsQuery } from '@/serverStore/projects';
import ChartEmptyState from '../components/ChartEmptyState';
import { useStyles } from './styles';
import { UsageParams, useUsageStyles } from './UsageSection';
import { useGetUsage } from '@/serverStore/usage';

export type TopProjectsUsageSectionProps = {
  params: UsageParams;
};

const TopProjectsUsageSection: React.FC<TopProjectsUsageSectionProps> = ({ params }) => {
  const usageStyles = useUsageStyles();
  const styles = useStyles();
  const { startTime, endTime } = params;
  const { data: projects } = useGetProjectsQuery();
  const {
    data: usageData,
    isLoading: loading,
    error,
  } = useGetUsage({
    fromTimestamp: startTime,
    toTimestamp: dateToISOString(addDays(new Date(endTime), 1)),
    interval: 'all',
    groupByProject: true,
  });

  const topProjectData = useMemo(() => {
    usageData?.sort((a, b) => b.usage - a.usage);
    return (usageData || []).map(item => {
      const project = projects?.find(project => project.id === item.projectId);
      return {
        label:
          project?.name ??
          item.projectName ??
          (!project?.id && !item.projectId ? 'OCR' : '(private project)'),
        value: item.usage,
      };
    });
  }, [projects, usageData]);

  const maxUsage = useMemo(() => {
    return Math.max(...topProjectData.map(data => data.value));
  }, [topProjectData]);

  return (
    <Box marginTop={9}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={usageStyles.title} variant="h2">
          {t('Top Projects: by credits')}
        </Typography>
      </Box>
      <Box marginTop={4}>
        <ApiResponseLoader loading={loading} response={usageData} error={error}>
          {() =>
            topProjectData.length ? (
              <Box maxWidth={800}>
                {topProjectData.map((d, index) => {
                  const width = !d.value ? '10px' : `calc((100% - 120px) * ${d.value / maxUsage})`;
                  return (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      className={styles.topValueItem}
                    >
                      <Box className={styles.label}>{t(d.label)}</Box>
                      <Box className={styles.usageBox} display="flex" alignItems="center">
                        <Box
                          className={styles.topProjectsValue}
                          style={{ width: width, flex: `0 0 ${width}` }}
                        ></Box>
                        <Typography className={styles.label}>
                          {t('{{value}} Credits', {
                            value: d.value,
                          })}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <ChartEmptyState
                chartType="horizontal_bar"
                title={t('You have not spent any credits on your projects yet')}
                subtitle={t(
                  'Once models are trained or predictions are made, you can see which projects used most credits here',
                )}
              />
            )
          }
        </ApiResponseLoader>
      </Box>
    </Box>
  );
};

export default TopProjectsUsageSection;

import React, { memo, MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { Text, Rect, Transformer, Image, Group, Line, Shape } from 'react-konva';
import Konva from 'konva';
import {
  LineAnnotation,
  BoxAnnotation,
  TextAnnotation,
  BitMapAnnotation,
  RangeBox,
  PureCanvasAnnotation,
  Position,
  Box,
} from '@clef/shared/types';
import { DEFECT_FALLBACK_COLOR, TOOLTIP_BG_DEFAULT_COLOR } from '../../../constants/common';
import { useState } from 'react';
import { runLengthDecode, cap, isDark } from '@clef/shared/utils';
import { createPixelatedCanvas } from '../../../utils/canvas';
import { useCallback } from 'react';
import { CanvasMode } from '../types';
import { isChromeBrowser, usePrevious } from '../../..';
import { isEqual, truncate } from 'lodash';
import { linePointsToPixelatedCanvasAsync } from '../linePixelation';
import { linePointsToPixelatedCanvas } from '../utils';
import {
  calcAnnotationTextPositions,
  calcAnnotationTooltipPositions,
  DefaultPositionPriorities,
  DefaultTooltipPositionPriorities,
  ReversedPositionPriorities,
} from '../../MediaViewer/util';
import { DefectCreatorRef } from './DefectCreator';
import { getContours } from './utils';

const STROKE_WIDTH_BASE = 2;
const ARROW_SIZE = { width: 10, height: 5 };

export const pixelatePosition = (pos: Position, layerPosition: Position, scale: number) => {
  const relativePosition = {
    x: pos.x - layerPosition.x,
    y: pos.y - layerPosition.y,
  };
  const pixelatedRelativePosition = {
    x: Math.round(relativePosition.x / scale) * scale,
    y: Math.round(relativePosition.y / scale) * scale,
  };
  return {
    x: pixelatedRelativePosition.x + layerPosition.x,
    y: pixelatedRelativePosition.y + layerPosition.y,
  };
};

/**
 * return the top-left position of the innerBox to ensure that the innerBox is inside the outerBox
 * Assumption: innerBox is smaller than outerBox
 */
export const capPosition = (innerBox: Box | Position, outerBox: Box) => {
  return {
    x: cap(innerBox.x, {
      min: outerBox.x,
      max: outerBox.x + outerBox.width - ((innerBox as Box).width ?? 0),
    }),
    y: cap(innerBox.y, {
      min: outerBox.y,
      max: outerBox.y + outerBox.height - ((innerBox as Box).height ?? 0),
    }),
  };
};

const boxToEdges = (box: Box) => ({
  left: box.x,
  top: box.y,
  right: box.x + box.width,
  bottom: box.y + box.height,
});

export const clipBox = (innerBox: Box, outerBox: Box, scale: number): Box => {
  const innerBoxEdges = boxToEdges(innerBox);
  const outerBoxEdges = boxToEdges(outerBox);

  innerBoxEdges.left = Math.max(innerBoxEdges.left, outerBoxEdges.left);
  innerBoxEdges.right = Math.min(innerBoxEdges.right, outerBoxEdges.right);
  innerBoxEdges.top = Math.max(innerBoxEdges.top, outerBoxEdges.top);
  innerBoxEdges.bottom = Math.min(innerBoxEdges.bottom, outerBoxEdges.bottom);

  const newInnerBox = {
    x: innerBoxEdges.left,
    y: innerBoxEdges.top,
    width: innerBoxEdges.right - innerBoxEdges.left,
    height: innerBoxEdges.bottom - innerBoxEdges.top,
  };

  // [LAN-5015] handle resizing out of the outer box.
  // force width and height of inner box to have at least one unit of scale and always be inside outer box.
  const oneUnit = 1 * scale;
  // width and height should be about n times of scale, considering floating point precisions
  // we use 0.9 * scale as a threshold
  const lessThanOneUnit = 0.9 * scale;
  if (newInnerBox.width < lessThanOneUnit || newInnerBox.x >= outerBoxEdges.right) {
    newInnerBox.width = oneUnit;
    newInnerBox.x = outerBoxEdges.right - oneUnit;
  }
  if (newInnerBox.height < lessThanOneUnit || newInnerBox.y >= outerBoxEdges.bottom) {
    newInnerBox.height = oneUnit;
    newInnerBox.y = outerBoxEdges.bottom - oneUnit;
  }

  return newInnerBox;
};

/**
 * Verify if the innerBox is inside the outerBox.
 * Assumption: width and height for both boxes are >= 0
 */
export const isInsideBox = (innerBox: Box, outerBox: Box) => {
  return !(
    innerBox.x < outerBox.x ||
    innerBox.y < outerBox.y ||
    innerBox.x + innerBox.width > outerBox.x + outerBox.width ||
    innerBox.y + innerBox.height > outerBox.y + outerBox.height
  );
};

/**
 * Konva creates negative width / height boxes if we draw a box leftward / upward.
 * This function flips the box to change the dimensions to positive numbers for better calculation.
 */
export const flipBoxToPositiveDimensions = (box: Box): Box => {
  let { x, y, width, height } = box;
  if (width < 0) {
    x += width;
    width = -width;
  }
  if (height < 0) {
    y += height;
    height = -height;
  }
  return { x, y, width, height };
};

export const calcBottomRightCornerPosition = (rect: Konva.Rect | null) => {
  if (rect) {
    const stage = rect.getStage()!;
    const container = stage.container();
    const clientRect = container.getBoundingClientRect();
    const position = rect.absolutePosition();
    const scale = rect.getAbsoluteScale();

    return {
      x: (clientRect?.left || 0) + position.x + rect.width() * scale.x,
      y: (clientRect?.top || 0) + position.y + rect.height() * scale.y,
    };
  }

  return {
    x: 0,
    y: 0,
  };
};

/**
 * Box annotation
 * ===============
 * bounding boxes
 */
type BoxAnnotationProps = {
  scale: number;
  annotationId: string;
  annotation: BoxAnnotation;
  editable?: boolean;
  selected?: boolean;
  onDragEnd?: (evt: Konva.KonvaEventObject<DragEvent>) => void;
  onChange?: (newAnnotation: BoxAnnotation, annotationid: string) => void | Promise<void>;
  onSelect?: (annotationId: string) => void;
  onMouseDown?: (evt: Konva.KonvaEventObject<Event>) => void;
  onHoverChange?: (isHover: boolean) => void;
  mode?: CanvasMode;
  hovered?: boolean;
  showTextOnHover?: boolean;
  defectCreatorRef?: MutableRefObject<DefectCreatorRef | null>;
};

// memo to shallow compare props to avoid unecessary render
export const BoxAnnotationComponent: React.FC<BoxAnnotationProps> = memo(
  ({
    annotationId,
    annotation,
    editable = false,
    selected = false,
    scale,
    onChange,
    onSelect,
    onHoverChange,
    mode,
    hovered,
    showTextOnHover,
    defectCreatorRef,
  }) => {
    const rectRef = useRef<Konva.Rect | null>(null);
    const trRef = useRef<Konva.Transformer>(null);
    // to be compatible with already-created boxes, we need to flip here as well
    const box = flipBoxToPositiveDimensions(annotation);

    const isMouseDown = useRef(false);
    const [borderWidth, setBorderWidth] = useState(STROKE_WIDTH_BASE);

    const handleResizable = useCallback(
      (resizable: boolean) => {
        if (trRef.current && rectRef.current) {
          trRef.current.nodes([rectRef.current]);
          trRef.current.getLayer()?.batchDraw();
          if (mode === CanvasMode.View && resizable) {
            trRef.current.resizeEnabled(false);
            trRef.current.borderEnabled(false);
          } else {
            trRef.current.resizeEnabled(resizable);
            trRef.current.borderEnabled(resizable);
          }
        }
      },
      [mode],
    );

    useEffect(() => {
      if (hovered && mode === CanvasMode.View) {
        setBorderWidth(STROKE_WIDTH_BASE * 2);
      } else {
        setBorderWidth(STROKE_WIDTH_BASE);
      }
    }, [hovered, mode]);

    // We are NOT using states to control resizeEnabled because states changes will trigger re-render of the shapes.
    // During dragging and resizing, the shapes are expected to be controlled inside Konva, and
    // re-rendering shapes in this period will cause unexpected behaviours.
    const setResizable = useCallback(
      (resizable: boolean) => {
        if (editable || mode === CanvasMode.View) {
          handleResizable(resizable);
        }
      },
      [editable, handleResizable, mode],
    );

    useEffect(() => setResizable(!!selected), [selected, setResizable]);

    const text = useMemo(() => truncate(annotation.tag || '', { length: 25 }), [annotation.tag]);

    const [tagAndBackgroundProps, setTagAndBackgroundProps] = useState<
      ReturnType<typeof calcAnnotationTextPositions> | undefined
    >(undefined);
    const [tagProps, backgroundProps] = tagAndBackgroundProps ?? [];

    const highContrastColor = isDark(annotation.color) ? '#ffffff' : '#000000';

    const bottomRightCornerPosition = calcBottomRightCornerPosition(rectRef.current);

    useEffect(() => {
      defectCreatorRef?.current?.setPosition({
        x: bottomRightCornerPosition.x,
        y: bottomRightCornerPosition.y,
      });
    }, [defectCreatorRef, bottomRightCornerPosition.x, bottomRightCornerPosition.y]);

    useEffect(() => {
      const defectCreator = defectCreatorRef?.current;

      return () => {
        defectCreator?.setMode(null);
      };
    }, [defectCreatorRef]);

    return (
      <Group
        onMouseOver={e => {
          e.cancelBubble = true;
          if (mode !== CanvasMode.View) {
            setResizable(true);
            onHoverChange?.(true);
          }
        }}
        onMouseOut={e => {
          e.cancelBubble = true;
          // when resizing / dragging, it is possible that the mouse moves out of the rect edges.
          // in that case we treat it as NOT moving out.
          if (!isMouseDown.current) {
            setResizable(!!selected);
            onHoverChange?.(false);
          }
        }}
        onMouseDown={e => {
          isMouseDown.current = true;
          if (editable) {
            e.cancelBubble = true;
            onSelect?.(annotationId);
          }
        }}
        onMouseUp={() => (isMouseDown.current = false)}
      >
        {hovered && showTextOnHover && !!text && tagProps && backgroundProps && (
          <>
            <Rect
              x={annotation.x + backgroundProps.x}
              y={annotation.y + backgroundProps.y}
              width={backgroundProps.width}
              height={backgroundProps.height}
              fill={annotation.color || DEFECT_FALLBACK_COLOR}
              strokeWidth={1}
              stroke={highContrastColor}
              cornerRadius={4 / scale}
              dash={annotation.dashed ? [4, 2] : undefined}
              strokeScaleEnabled={false}
            />
            <Text
              x={annotation.x + backgroundProps.x}
              y={annotation.y + backgroundProps.y}
              width={backgroundProps.width}
              height={backgroundProps.height}
              text={text}
              fill={highContrastColor}
              fontSize={12 / scale}
              fontFamily="Commissioner, sans-serif"
              fontStyle="bold"
              align="center"
              verticalAlign="middle"
              // avoid large letter spacing when scaled
              letterSpacing={0.5 / scale}
            />
          </>
        )}
        <Rect
          ref={ref => {
            const layer = ref?.getLayer();
            if (!layer) {
              return;
            }

            rectRef.current = ref;

            const width = layer.clipWidth();
            const height = layer.clipHeight();
            const res = calcAnnotationTextPositions(
              {
                xMin: annotation.x,
                xMax: annotation.x + annotation.width,
                yMin: annotation.y,
                yMax: annotation.y + annotation.height,
                imageWidth: width,
                imageHeight: height,
                scale,
                text,
              },
              annotation.dashed ? ReversedPositionPriorities : DefaultPositionPriorities,
            );
            if (!isEqual(res, tagAndBackgroundProps)) {
              setTagAndBackgroundProps(res);
            }
          }}
          x={box.x}
          y={box.y}
          height={box.height}
          width={box.width}
          draggable={editable}
          fillEnabled={false}
          stroke={annotation.color || DEFECT_FALLBACK_COLOR}
          strokeScaleEnabled={false}
          strokeWidth={borderWidth}
          // pad 2x stroke width on both side of the stroke: 1 + 2 + 2 = 5
          hitStrokeWidth={STROKE_WIDTH_BASE * 5}
          dash={annotation.dashed ? [4, 4] : undefined}
          opacity={annotation.opacity ?? 1}
          dragBoundFunc={pos => {
            const rect = rectRef.current!;
            const layer = rect.getLayer()!;
            const layerBox = {
              ...layer.getAbsolutePosition(),
              width: layer.clipWidth() * layer.getAbsoluteScale().x,
              height: layer.clipHeight() * layer.getAbsoluteScale().y,
            };
            const pixelatedPosition = pixelatePosition(pos, layerBox, scale);
            const newBox = {
              ...pixelatedPosition,
              width: rect.width() * rect.getAbsoluteScale().x,
              height: rect.height() * rect.getAbsoluteScale().y,
            };
            return capPosition(newBox, layerBox);
          }}
          onDragEnd={e => {
            const node = e.target;
            const position = { x: Math.round(node.x()), y: Math.round(node.y()) };
            onChange?.({ ...annotation, ...box, ...position }, annotationId);
          }}
          onTransformEnd={() => {
            // Reference: https://konvajs.org/docs/react/Transformer.html
            const node = rectRef.current!;
            const scaleX = node.scaleX();
            const scaleY = node.scaleY();
            node.scaleX(1);
            node.scaleY(1);

            const rotation = node.rotation();
            node.rotate(-rotation);

            const rect = {
              x: Math.round(node.x()),
              y: Math.round(node.y()),
              width: Math.round(node.width() * scaleX),
              height: Math.round(node.height() * scaleY),
            };

            if (rotation === 180 || rotation === -180) {
              rect.width = -rect.width;
              rect.height = -rect.height;
            }

            const flippedRect = flipBoxToPositiveDimensions(rect);
            onChange?.({ ...annotation, ...flippedRect }, annotationId);
          }}
        />
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          ignoreStroke
          keepRatio={false}
          enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
          boundBoxFunc={(_, newBox) => {
            const layer = rectRef.current!.getLayer()!;
            const layerBox = {
              ...layer.getAbsolutePosition(),
              width: layer.clipWidth() * layer.getAbsoluteScale().x,
              height: layer.clipHeight() * layer.getAbsoluteScale().y,
            };
            const newPixelatedBox = {
              ...pixelatePosition(newBox, layerBox, scale),
              width: Math.max(1, Math.round(newBox.width / scale)) * scale,
              height: Math.max(1, Math.round(newBox.height / scale)) * scale,
            };
            return { ...newBox, ...clipBox(newPixelatedBox, layerBox, scale) };
          }}
        />
      </Group>
    );
  },
);

BoxAnnotationComponent.displayName = 'BoxAnnotationComponent';

// memo to shallow compare props to avoid unecessary render
export const BoxSuggestionAnnotationComponent: React.FC<BoxAnnotationProps> = memo(
  ({ annotationId, annotation, scale, onSelect, hovered }) => {
    const rectRef = useRef<Konva.Rect | null>(null);
    // to be compatible with already-created boxes, we need to flip here as well
    const box = flipBoxToPositiveDimensions(annotation);
    const text = 'Click to accept and edit';
    const [hovering, setHovering] = useState(false);
    const [borderWidth, setBorderWidth] = useState(STROKE_WIDTH_BASE);

    useEffect(() => {
      if (hovered || hovering) {
        setBorderWidth(STROKE_WIDTH_BASE * 2);
      } else {
        setBorderWidth(STROKE_WIDTH_BASE);
      }
    }, [hovered, hovering]);

    const tooltipProps = useMemo(() => {
      const layer = rectRef.current?.getLayer();
      if (!layer) {
        return undefined;
      }
      const width = layer.clipWidth();
      const height = layer.clipHeight();
      return calcAnnotationTooltipPositions(
        {
          xMin: annotation.x,
          xMax: annotation.x + annotation.width,
          yMin: annotation.y,
          yMax: annotation.y + annotation.height,
          imageWidth: width,
          imageHeight: height,
          scale,
          text,
          arrowSize: ARROW_SIZE,
        },
        DefaultTooltipPositionPriorities,
      );
    }, [rectRef.current, annotation, scale, text]);

    const [dashOffset, setDashOffset] = useState(0);
    const timeoutIdRef = useRef<number | null>(null);

    useEffect(() => {
      const updateDashOffset = () => {
        setDashOffset(offset => (offset + 1) % 100);

        timeoutIdRef.current = setTimeout(() => {
          requestAnimationFrame(updateDashOffset);
        }, 100) as unknown as number;
      };

      requestAnimationFrame(updateDashOffset);

      return () => {
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
        }
      };
    }, []);

    return (
      <Group
        onMouseOver={e => {
          e.cancelBubble = true;
          setHovering(true);
          const stage = e.target.getStage();
          if (stage) {
            stage.container().style.cursor = 'pointer';
          }
        }}
        onMouseOut={e => {
          e.cancelBubble = true;
          setHovering(false);
          const stage = e.target.getStage();
          if (stage) {
            stage.container().style.cursor = 'crosshair';
          }
        }}
        onMouseDown={e => {
          e.cancelBubble = true;
          onSelect?.(annotationId);
        }}
        onMouseUp={() => {}}
      >
        {hovering && tooltipProps && (
          <>
            <Rect
              x={tooltipProps.x}
              y={tooltipProps.y}
              width={tooltipProps.width}
              height={tooltipProps.height}
              fill={TOOLTIP_BG_DEFAULT_COLOR}
              cornerRadius={4 / scale}
              strokeScaleEnabled={false}
            />
            <Shape
              sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(0, 0);
                context.lineTo(10 / scale, 0);
                context.lineTo(5 / scale, 5 / scale);
                context.closePath();
                context.fillStrokeShape(shape);
              }}
              fill={TOOLTIP_BG_DEFAULT_COLOR}
              x={tooltipProps.arrowX}
              y={tooltipProps.arrowY}
              rotation={tooltipProps.arrowRotate}
            />
            <Text
              x={tooltipProps.x}
              y={tooltipProps.y}
              width={tooltipProps.width}
              height={tooltipProps.height}
              text={text}
              fill={'white'}
              fontSize={12 / scale}
              fontFamily="Commissioner, sans-serif"
              align="center"
              verticalAlign="middle"
              // avoid large letter spacing when scaled
              letterSpacing={0.5 / scale}
            />
          </>
        )}
        <Rect
          ref={ref => {
            rectRef.current = ref;
          }}
          x={box.x}
          y={box.y}
          height={box.height}
          width={box.width}
          draggable={false}
          fill={'transparent'}
          stroke={annotation.color || DEFECT_FALLBACK_COLOR}
          strokeScaleEnabled={false}
          strokeWidth={borderWidth}
          // pad 2x stroke width on both side of the stroke: 1 + 2 + 2 = 5
          hitStrokeWidth={STROKE_WIDTH_BASE * 5}
          dash={[6, 6]}
          dashOffset={dashOffset}
          opacity={annotation.opacity ?? 1}
        />
      </Group>
    );
  },
);

type PureLineAnnotationProps = {
  annotation: LineAnnotation;
  isPolygon?: boolean;
  opacity?: number;
  strokeOnly?: boolean;
  xmin?: number;
  ymin?: number;
  dash?: number[];
};

export const PureLineAnnotationComponent: React.FC<LineAnnotationProps> = ({
  annotation,
  isPolygon,
  opacity: _opacity,
  strokeOnly = false,
  xmin = 0,
  ymin = 0,
  dash,
}) => {
  const { strokeWidth = 1, points, color, opacity = 1 } = annotation;
  const adjustedStrokeWidth = strokeWidth < 2 ? strokeWidth : 2 * strokeWidth - 1;
  const lineWidth = isPolygon ? (strokeOnly ? adjustedStrokeWidth : 0) : adjustedStrokeWidth;
  const finalOpacity = color === 'transparent' ? 0 : _opacity || opacity;
  const lineCap = lineWidth <= 5 ? 'square' : 'round';
  const lineJoin = lineWidth <= 5 ? 'bevel' : 'round';
  // for line (i.e. brush & polyline), the points are center positions of each pixel, need to add offset;
  // for polygon, the points are corners of each pixel, no need to add offset.
  const positionOffset = isPolygon && !strokeOnly ? 0 : -0.5;
  return (
    <>
      <Line
        offsetX={-xmin + positionOffset}
        offsetY={-ymin + positionOffset}
        globalCompositeOperation="destination-out"
        strokeWidth={lineWidth}
        points={points}
        stroke="white"
        opacity={1}
        lineJoin={lineJoin}
        lineCap={lineCap}
        closed={isPolygon}
        fill={isPolygon && !strokeOnly ? 'white' : undefined}
        strokeScaleEnabled={!strokeOnly}
      />
      {!!finalOpacity && (
        <Line
          offsetX={-xmin + positionOffset}
          offsetY={-ymin + positionOffset}
          globalCompositeOperation="lighter"
          strokeWidth={lineWidth}
          points={points}
          stroke={color}
          opacity={finalOpacity}
          lineJoin={lineJoin}
          lineCap={lineCap}
          closed={isPolygon}
          fill={isPolygon && !strokeOnly ? color : undefined}
          strokeScaleEnabled={!strokeOnly}
          dash={dash}
        />
      )}
    </>
  );
};

/**
 * Canvas annotation
 * ===============
 * Rendering canvas to Image implementation
 * IMPORTANT: This is the very "basic" component that ALL current and future segmentation representations
 * (brush / polygon / polyline)
 * should inherit and build upon, the representations just need to focus on how to create it's pixelated canvas
 * See <LineAnnotationComponent /> and <BitMapAnnotationComponent /> below
 */
export const PureCanvasAnnotationComponent: React.FC<PureCanvasAnnotation> = React.memo(
  ({ x, y, canvas, opacity = 1, height, width, enableContour }) => {
    // if the shape has holes, the result can be multiple contours,
    // so the result data structure is a list of lines, e.g.
    // [
    //   [x1, y1, x2, y2, ...],
    //   [x6, y6, x7, y7, ...],
    // ]
    const contours = useMemo(() => {
      return canvas && enableContour ? getContours(canvas) : [];
    }, [canvas, enableContour]);

    return (
      <>
        {/* A "destination-out" layer to overwrite previous pixels */}
        <Image
          globalCompositeOperation="destination-out"
          imageSmoothingEnabled={false}
          shadowEnabled={false}
          x={x}
          y={y}
          height={height}
          width={width}
          image={canvas as unknown as HTMLCanvasElement}
        />
        {!!opacity && (
          <Image
            globalCompositeOperation="lighter"
            imageSmoothingEnabled={false}
            shadowEnabled={false}
            x={x}
            y={y}
            height={height}
            width={width}
            opacity={opacity}
            image={canvas as unknown as HTMLCanvasElement}
          />
        )}

        {contours.map((contour, index) => (
          <PureLineAnnotationComponent
            key={index}
            annotation={{
              color: '#FFFFFF',
              points: contour,
              strokeWidth: 2,
              opacity: 1,
            }}
            opacity={1}
            isPolygon
            strokeOnly
            xmin={x}
            ymin={y}
          />
        ))}
      </>
    );
  },
);

type LineAnnotationProps = PureLineAnnotationProps & {
  /**
   * if set to true, pixelation will be done synchronously. only set to true for small labels.
   */
  sync?: boolean;
  /**
   * enable for more precise labeling; disable for better performance
   */
  enablePixelation?: boolean;
  /**
   * enable drawing contour
   */
  enableContour?: boolean;
};

/**
 * Line annotation
 * ===============
 * Render LineAnnotations to canvas, to make sure the render is pixelated, we manually converted
 * Konva.Line to Konva.Image.
 * CAUTIOUS: this could be expensive calculation
 */
export const LineAnnotationComponent: React.FC<LineAnnotationProps> = ({
  annotation,
  isPolygon,
  opacity: _opacity,
  sync,
  enablePixelation,
  enableContour = false,
}) => {
  const { strokeWidth = 1, points, color, opacity = 1 } = annotation;
  const [rangeBox, setRangeBox] = useState<RangeBox>();
  const [canvasBitMap, setCanvasBitMap] = useState<OffscreenCanvas>();

  const prevAnnotation = usePrevious(annotation);
  useEffect(() => {
    if (!enablePixelation) {
      return;
    }
    if (isEqual(annotation, prevAnnotation)) {
      return;
    }
    if (!points.length) {
      setCanvasBitMap(undefined);
      return;
    }
    // It's not easy to polyfill OffscreenCanvas in webworker, so we only support webworker for Chrome.
    // Offscreen Browser Compatibility: https://developer.mozilla.org/en-US/docs/Web/API/OffscreenCanvas#browser_compatibility
    if (sync || !isChromeBrowser()) {
      const [offscreen, calcRangeBox] = linePointsToPixelatedCanvas(
        points,
        strokeWidth,
        color,
        isPolygon,
      );
      setCanvasBitMap(offscreen);
      setRangeBox(calcRangeBox);
    } else {
      linePointsToPixelatedCanvasAsync(points, strokeWidth ?? 1, color, isPolygon).then(
        ([offscreen, calcRangeBox]) => {
          setCanvasBitMap(offscreen);
          setRangeBox(calcRangeBox);
        },
      );
    }
  }, [annotation, color, enablePixelation, isPolygon, points, prevAnnotation, strokeWidth, sync]);

  const finalOpacity = color === 'transparent' ? 0 : _opacity || opacity;
  /**
   * Pure victor line, cases we will use this component:
   * 1. enablePixelation is false, means we never calculate canvasBitMap, we show pure line
   * 2. enablePixelation is on, however canvasBitMap (or contours) has not complete calculation, we use this for preview
   */
  if (!rangeBox || !canvasBitMap) {
    return (
      <>
        {enableContour && color !== 'transparent' && (
          <PureLineAnnotationComponent
            annotation={{
              ...annotation,
              strokeWidth: annotation.strokeWidth + 1,
              color: '#fff',
              opacity: 1,
            }}
          />
        )}
        <PureLineAnnotationComponent
          annotation={annotation}
          isPolygon={isPolygon}
          opacity={opacity}
        />
      </>
    );
  }

  const { xmin, xmax, ymin, ymax } = rangeBox;
  const width = xmax - xmin + 1;
  const height = ymax - ymin + 1;

  return (
    <PureCanvasAnnotationComponent
      x={xmin}
      y={ymin}
      canvas={canvasBitMap}
      opacity={finalOpacity}
      width={width}
      height={height}
      enableContour={canvasBitMap && enableContour && color !== 'transparent'}
    />
  );
};

/**
 * BitMap annotation
 * ===============
 * Converting bitmap on canvas to be rendered with Konva.Image.
 * CAUTIOUS: this could be expensive calculation
 */
export const BitMapAnnotationComponent: React.FC<BitMapAnnotation> = React.memo(
  ({ rangeBox: { xmin, xmax, ymax, ymin }, bitMap, opacity = 1, color, enableContour }) => {
    const [canvasBitMap, setCanvasBitMap] = useState<OffscreenCanvas>();

    const width = xmax - xmin + 1;
    const height = ymax - ymin + 1;

    useEffect(() => {
      const bitMapDecoded = runLengthDecode(bitMap);
      const offscreen = createPixelatedCanvas(
        { width, height },
        ({ i }) => bitMapDecoded[i] === '1',
        color,
      );
      setCanvasBitMap(offscreen);
    }, [bitMap, color, height, width]);

    if (!canvasBitMap) {
      return null;
    }

    return (
      <PureCanvasAnnotationComponent
        x={xmin}
        y={ymin}
        canvas={canvasBitMap}
        width={width}
        height={height}
        opacity={color === 'transparent' ? 0 : opacity}
        enableContour={canvasBitMap && enableContour}
      />
    );
  },
);

/**
 * Text annotation
 * ===============
 * render text fields onto the canvas
 */
type TextAnnotationProps = {
  annotationId: string;
  annotation: TextAnnotation;
  fontSize: number;
  selected?: boolean;
  draggable?: boolean;
  onSelect?: (annotationId: string) => void;
  onDragEnd?: (evt: Konva.KonvaEventObject<DragEvent>) => void;
  onMouseDown?: (evt: Konva.KonvaEventObject<Event>) => void;
  onChange?: (newAnnotation: TextAnnotation, annotationid: string) => void;
};

export const TextAnnotationComponent: React.FC<TextAnnotationProps> = ({
  annotationId,
  annotation,
  fontSize,
  draggable = false,
  onChange,
  onSelect,
  selected,
}) => {
  const shapeRef = useRef<Konva.Text>(null);
  const trRef = useRef<Konva.Transformer>(null);
  const setResizable = useCallback((resizable: boolean) => {
    if (trRef.current && shapeRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.resizeEnabled(resizable);
      trRef.current.borderEnabled(resizable);
      trRef.current.getLayer()?.batchDraw();
    }
  }, []);

  useEffect(() => setResizable(!!selected), [selected, setResizable]);

  const handleDoubleClick = () => {
    const node = shapeRef.current!;
    const stage = node.getStage()!;
    node.hide();
    trRef?.current?.hide();

    const textPosition = node.absolutePosition();
    const container = stage.container();

    const rect = container?.getBoundingClientRect();
    const textEditorPosition = {
      x: (rect?.left || 0) + textPosition.x,
      y: (rect?.top || 0) + textPosition.y,
      width: (node.text().length + 1) * node.fontSize() * node.getAbsoluteScale().x,
      height: node.height() * node.getAbsoluteScale().x,
    };

    //create textarea for inline editing, and style it
    const textarea = document.createElement('textarea');
    document.body.appendChild(textarea);

    const handleOutsideClick = (e: any) => {
      if (e.target !== textarea) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        finishEditing(true);
      }
    };

    const setupTextArea = () => {
      textarea.value = node.text();
      textarea.style.border = '0px none transparent';
      textarea.style.padding = '0px';
      textarea.style.margin = '0px';
      textarea.style.overflow = 'hidden';
      textarea.style.background = 'none';
      textarea.style.outline = 'none';
      textarea.style.resize = 'none';
      textarea.style.zIndex = '1400';
      textarea.style.position = 'fixed';
      textarea.style.top = `${textEditorPosition.y}px`;
      textarea.style.left = `${textEditorPosition.x}px`;
      textarea.style.width = `${textEditorPosition.width}px`;
      textarea.style.height = `${textEditorPosition.height}px`;
      textarea.style.fontSize = node.fontSize() * node.getAbsoluteScale().x + 'px';
      textarea.style.lineHeight = String(node.lineHeight());
      textarea.style.fontFamily = node.fontFamily();
      textarea.style.textAlign = node.align();
      textarea.style.color = node.fill();
      textarea.focus();
    };

    setupTextArea();

    setTimeout(() => {
      //wait for next tick before attaching handler
      window.addEventListener('click', handleOutsideClick);
    });

    const finishEditing = (save: boolean) => {
      if (save) {
        node.text(textarea.value);
        shapeRef?.current?.text(textarea.value);
      }
      window.removeEventListener('click', handleOutsideClick);
      node?.show();
      trRef?.current?.show();
      trRef?.current?.forceUpdate();
      const newText = { text: textarea.value };
      onChange?.({ ...annotation, ...newText }, annotationId);
      textarea?.parentNode?.removeChild(textarea);
    };

    textarea.addEventListener('keydown', function (e) {
      //pressing enter or tab key will finish text editing and save the changes
      if (e.keyCode === 13 || e.keyCode === 9) {
        finishEditing(true);
      }
      //escape key will finish text editing without saving the changes
      if (e.keyCode === 27) {
        finishEditing(false);
      }
      textarea.style.width =
        (textarea.value.length + 1) * node.fontSize() * node.getAbsoluteScale().x + 'px';
    });
  };

  return (
    <>
      <Text
        ref={shapeRef}
        x={annotation.x}
        y={annotation.y}
        text={annotation.text}
        fontSize={fontSize}
        strokeScaleEnabled={false}
        fill={annotation.color}
        draggable={draggable}
        onClick={e => {
          e.cancelBubble = true;
          onSelect?.(annotationId);
        }}
        onDblClick={
          draggable
            ? e => {
                e.cancelBubble = true;
                handleDoubleClick();
              }
            : undefined
        }
        onMouseDown={e => {
          e.cancelBubble = true;
        }}
        onDragEnd={e => {
          const node = e.target;
          const position = { x: Math.round(node.x()), y: Math.round(node.y()) };
          onChange?.({ ...annotation, ...position }, annotationId);
        }}
      />
      {selected && (
        <Transformer ref={trRef} rotateEnabled={false} resizeEnabled enabledAnchors={[]} />
      )}
    </>
  );
};

import React from 'react';
import cx from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(_ => ({
  div: {
    display: 'inline-flex',
    fontWeight: 500,
    border: 'none',
    borderRadius: 4,
    padding: 0,
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'center',
  },
  span: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: 5,
    paddingRight: 5,
    textOverflow: 'ellipsis',
    fontSize: 13,
    paddingTop: 1,
  },
  greenColoring: {
    background: 'rgba(52, 199, 89, 0.1)',
    color: '#34C759',
  },
  blueColoring: {
    background: '#F4F8FE',
    color: '#0C64CD',
  },
}));

export interface ChipProps {
  text: string;
  color: 'green' | 'blue';
  style?: React.CSSProperties;
  className?: string;
}

export const Chip: React.FC<ChipProps> = ({ text, color, style, className }) => {
  const styles = useStyles();

  return (
    <div
      className={cx(
        styles.div,
        color === 'green' ? styles.greenColoring : styles.blueColoring,
        className,
      )}
      style={style}
    >
      <span className={styles.span}>{text}</span>
    </div>
  );
};

import React from 'react';
import { useActiveProjectsEnabled } from '@/hooks/useSubscriptions';
import PricingBannerActiveProjects from './PricingBannerActiveProjects';
import ActiveProjectsContent from './ActiveProjectsContent';

const ActiveProjectsInternal = () => {
  return (
    <>
      <PricingBannerActiveProjects />
      <ActiveProjectsContent />
    </>
  );
};

const ActiveProjects = () => {
  const activeProjectsEnabled = useActiveProjectsEnabled();

  if (activeProjectsEnabled) {
    return <ActiveProjectsInternal />;
  } else {
    return null;
  }
};

export default ActiveProjects;

import React, { useState, useEffect } from 'react';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import { useGetTrainHealthQuery } from '@/serverStore/snowflake';
import { RegisteredModelId } from '@clef/shared/types';

const useStyles = makeStyles(theme => ({
  timeDistanceChip: {
    backgroundColor: theme.palette.greyModern[100],
    color: theme.palette.greyModern[600],
    padding: theme.spacing(0, 1),
    fontWeight: 700,
    fontSize: 14,
    margin: theme.spacing(0, 2),
    borderRadius: 4,
    height: 19,
    lineHeight: '19px',
  },
}));

export type ModelTrainingInProgressProps = {
  modelId: string | undefined;
  showLearningGraphBlock?: boolean;
};

const getProvisionGpuTimerTooltip = (count: number | undefined) => {
  if (!count) {
    return t('It can take up to 15 min to spin up GPU');
  } else {
    return t('It can take up to 15 min to spin up GPU, total {{count}} jobs in queue', { count });
  }
};

const ProvisionGpuTimer = ({
  modelId,
  startTime,
}: {
  modelId?: RegisteredModelId;
  startTime: string;
}) => {
  const [timeDistance, setTimeDistance] = useState('');
  const styles = useStyles();
  const initialCurrentDate = new Date();
  const startDate = new Date(startTime);
  const initialDistanceInSeconds = Math.floor(
    (initialCurrentDate.getTime() - startDate.getTime()) / 1000,
  );
  const { data: trainHealthData } = useGetTrainHealthQuery(modelId);
  const { queueSize } = trainHealthData ?? {};

  useEffect(() => {
    const updateDistance = () => {
      const now = new Date();
      const updatedAt = new Date(startTime);
      const distanceInSeconds = Math.floor((now.getTime() - updatedAt.getTime()) / 1000);
      let formattedDistance;
      if (distanceInSeconds < 60) {
        formattedDistance = `${distanceInSeconds}s`;
        setTimeDistance(formattedDistance);
      } else if (distanceInSeconds < 3600) {
        formattedDistance = `${Math.floor(distanceInSeconds / 60)}min ${distanceInSeconds % 60}s`;
        setTimeDistance(formattedDistance);
      } else if (distanceInSeconds < 86400) {
        formattedDistance = `${Math.floor(distanceInSeconds / 3600)}h ${Math.floor(
          (distanceInSeconds % 3600) / 60,
        )}min`;
        setTimeDistance(formattedDistance);
      }
    };
    updateDistance();
    const intervalId = setInterval(updateDistance, 1000);

    return () => clearInterval(intervalId);
  }, [startTime]);

  if (initialDistanceInSeconds >= 86400) {
    return null;
  }

  return (
    <Tooltip title={getProvisionGpuTimerTooltip(queueSize)} arrow={true} placement="top">
      <Box className={styles.timeDistanceChip} paddingLeft={4}>
        {timeDistance}
      </Box>
    </Tooltip>
  );
};

export default ProvisionGpuTimer;

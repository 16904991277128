import CLEF_PATH from '@/constants/path';
import { Box, Grid, Link, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import {
  useActiveProjectsEnabled,
  useIsNonStripeUser,
  useCheckActiveProjectReachLimit,
} from '../../../../hooks/useSubscriptions';
import BorderLinearProgress from './BorderLinearProgress';
import CommonBlockHeader from './CommonBlockHeader';
import useStyles from './styles';
import { Typography } from '@clef/client-library';
import cx from 'classnames';

const useLocalStyle = makeStyles(theme => ({
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  gm600: {
    color: theme.palette.greyModern[600],
  },
}));

const UsageNumber = ({ usage }: { usage: number | undefined }) => {
  const styles = useStyles();
  const history = useHistory();
  const localStyles = useLocalStyle();
  return (
    <Box marginTop={1} display="flex" flexDirection="row" alignItems="center">
      <Typography className={styles.value}>{usage?.toLocaleString()}</Typography>
      <Typography className={localStyles.gm600} variant="body_regular">
        {t('projects')}
      </Typography>
      <Link
        className={cx(styles.clickableLink, localStyles.marginLeft)}
        onClick={() => {
          history.push(CLEF_PATH.organizationSettings + '?tab=activeProject');
        }}
      >
        {t('Manage')}
      </Link>
    </Box>
  );
};

const UsageProgressBar = ({
  usage,
  limit,
}: {
  usage: number | undefined;
  limit: number | undefined;
}) => {
  const styles = useStyles();
  const usagePercent = Math.min(100, ((usage ?? 0) / (limit || 1)) * 100);
  if (limit === -1) {
    return null;
  }
  return (
    <Box display="flex" alignItems="center" marginTop={1}>
      <BorderLinearProgress
        variant="determinate"
        value={usagePercent}
        color={usagePercent === 100 ? 'secondary' : 'primary'}
      />
      <Typography className={styles.progressValue}>
        {usage}/{limit}
      </Typography>
    </Box>
  );
};

const title = t('Active projects');
const tooltipCommercial = t(
  'You can download models from active projects for offline, commercial use. Once a project is activated, you can download an unlimited number of models from it.',
);
const tooltipNonCommercial = t(
  'You can download models from active projects for offline, noncommercial use. Once a project is activated, you can download an unlimited number of models from it.',
);

const ActiveProjectBlock = () => {
  const isActiveProjectEnabled = useActiveProjectsEnabled();
  const { limit, usage } = useCheckActiveProjectReachLimit();
  const canCommercialUse = useIsNonStripeUser();
  if (!isActiveProjectEnabled) {
    return null;
  }

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box>
        {
          <CommonBlockHeader
            title={title}
            tooltip={canCommercialUse ? tooltipCommercial : tooltipNonCommercial}
          />
        }
        {<UsageNumber usage={usage} />}
        {<UsageProgressBar usage={usage} limit={limit} />}
      </Box>
    </Grid>
  );
};

export default ActiveProjectBlock;

import React, { useCallback, useState, useMemo, useRef } from 'react';
import cx from 'classnames';
import { ClickAwayListener, makeStyles } from '@material-ui/core';
import useWindowEventListener from '../../hooks/useWindowEventListener';
import debounce from 'lodash/debounce';
import { isTestEnv } from '../../utils/env';

const useStyles = makeStyles(theme => ({
  dropdownRoot: {
    position: 'relative',
  },
  dropdownSelector: {
    cursor: 'pointer',
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  dropdownContent: {
    position: 'fixed',
    overflow: 'auto',
    borderRadius: 8,
    zIndex: theme.zIndex.appBar + 101,
    backgroundColor: theme.palette.background.default,
    boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px',
  },
  dropdownContentAnimation: {
    maxHeight: '550px',
    overflow: 'auto',
    opacity: 0,
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
  disabled: {
    pointerEvents: 'none',
  },
}));

type FuncOrValue<T, K> = ((params: K) => T) | T;

const executeOrValue = <T, K>(funcOrValue: FuncOrValue<T, K>, params: K): T => {
  if (typeof funcOrValue === 'function') {
    return (funcOrValue as (params: K) => T)(params);
  } else {
    return funcOrValue;
  }
};

const screenMargin = 8;

export const horizontalPositionConsiderEdge = (
  dropdownNodeRects: DOMRect,
  selectorNodeRects: DOMRect,
  prefer: 'left' | 'right',
  gutter: number,
): React.CSSProperties => {
  // if the selectorNodeRects.left does not have space for gutter + screenMargin + dropdownNodeRects.width
  const reachedScreenLeft =
    selectorNodeRects.left < gutter + dropdownNodeRects.width + screenMargin;
  // if the selectorNodeRects.right + dropdownNodeRects.width + screenMargin + gutter > window.innerWidth
  const reachedScreenRight =
    selectorNodeRects.right + dropdownNodeRects.width + screenMargin + gutter > window.innerWidth;
  const positionRight: React.CSSProperties = {
    left: selectorNodeRects.right + gutter,
  };
  const positionLeft: React.CSSProperties = {
    left: selectorNodeRects.left - gutter - dropdownNodeRects.width,
  };
  const shouldPositionLeft =
    // want to place left, and have enough left space or there is no space on the right anyways
    (prefer === 'left' && (!reachedScreenLeft || reachedScreenRight)) ||
    // want to place right, but have no space on right, but there is space on the left
    (prefer === 'right' && !reachedScreenLeft && reachedScreenRight);

  return shouldPositionLeft ? positionLeft : positionRight;
};

export interface DropdownProps {
  // The children(selector) component that and opens the dropdown when clicked on.
  // Children can be either React.ReactNode or function (open: Boolean) => React.ReactNode.
  // latter cases is provided if selector need to render differently when dropdown is opened.
  children: FuncOrValue<React.ReactNode, boolean>;
  // onClose handler when dropdown is closed
  onClose?: () => void;
  // The dropdown's position placement relative to the selector.
  // Practically this can easily all 12 placement directions, but we are just adding base on needs
  // so feel free to contribute more placements!
  placement?: 'bottom' | 'right' | 'left';
  // In addition to the placement, if you want to manually move the dropdown in any directions
  extraGutter?: {
    vertical?: number;
    horizontal?: number;
  };
  // Extra classes to internal components
  classes?: FuncOrValue<
    {
      root?: string;
      selector?: string;
      content?: string;
    },
    boolean
  >;
  // The dropdown component to be rendered when selector (children) is clicked.
  // Dropdown can be either React.ReactNode or function (togglerOpen: (open: boolean) => void>) => React.ReactNode.
  // latter cases is provided if dropdown need to internally trigger open/close the dropdown.
  dropdown?: FuncOrValue<React.ReactNode, (open: boolean) => void>;
  // Disable the dropdown and click event handler
  disabled?: boolean;
  footerComponent?: FuncOrValue<React.ReactNode, (open: boolean) => void>;
}
export type DropdownRef = {
  onClose: () => void;
};
/**
 * The basic component to render a temporary dropdown surfaces, when clicked on given selector component (children)
 */
const Dropdown: React.FC<DropdownProps> = ({
  children,
  dropdown,
  onClose,
  placement = 'bottom',
  extraGutter = {
    vertical: 0,
    horizontal: 0,
  },
  classes,
  disabled = false,
  footerComponent = null,
}) => {
  const styles = useStyles();
  const { horizontal: horizontalGutter = 0, vertical: verticalGutter = 0 } = extraGutter;
  const [open, setOpen] = useState(false);
  const [dropdownProps, setDropdownProps] = useState<React.CSSProperties>({ left: 0, top: 0 });
  const selectorRef = useRef<HTMLDivElement | null>(null);

  const toggleContent = useCallback(
    (nextState: boolean) => {
      if (!nextState) {
        onClose?.();
        setDropdownProps(prev => ({
          ...prev,
          opacity: 0,
        }));
      }
      setOpen(nextState);
    },
    [onClose],
  );

  useWindowEventListener('scroll', e => {
    // this will break cypress tests
    if (open && !isTestEnv()) {
      e.preventDefault();
      e.stopImmediatePropagation();
      setOpen(false);
    }
  });

  const calculateDropdownPosition = useCallback(
    (selectorNode: HTMLDivElement, dropdownNode: HTMLDivElement) => {
      if (selectorRef.current) {
        const selectorNodeRects = selectorNode.getBoundingClientRect();
        const dropdownNodeRects = dropdownNode.getBoundingClientRect();

        const hasEnoughBottomSpacing =
          selectorNodeRects.bottom + dropdownNodeRects.height + screenMargin + verticalGutter >=
          window.innerHeight;

        if (placement === 'bottom') {
          const top = selectorNodeRects.top + selectorNodeRects.height + verticalGutter;
          setDropdownProps({
            opacity: 1,
            top: hasEnoughBottomSpacing ? undefined : top,
            bottom: hasEnoughBottomSpacing ? screenMargin : undefined,
            left: selectorNodeRects.left + horizontalGutter,
          });
        } else {
          //placement = 'left' or 'right'
          const top = selectorNodeRects.top + verticalGutter;
          setDropdownProps({
            opacity: 1,
            top: hasEnoughBottomSpacing ? undefined : top,
            bottom: hasEnoughBottomSpacing ? screenMargin : undefined,
            ...horizontalPositionConsiderEdge(
              dropdownNodeRects,
              selectorNodeRects,
              placement,
              horizontalGutter,
            ),
          });
        }
      }
    },
    [horizontalGutter, verticalGutter, placement],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        toggleContent(false);
      }
    },
    [toggleContent],
  );

  const closeDropdown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        toggleContent(false);
      }
    },
    [toggleContent],
  );

  useWindowEventListener('keydown', closeDropdown);

  const dropdownRef = useCallback(
    (dRef: HTMLDivElement | null) => {
      if (dRef) {
        const config = { attributes: false, childList: true, subtree: true };
        const mutationFunc = debounce(() => {
          if (selectorRef.current) {
            calculateDropdownPosition(selectorRef.current, dRef);
          }
        }, 200);
        const observer = new MutationObserver(mutationFunc);
        observer.observe(dRef, config);
        mutationFunc();
      }
    },
    [calculateDropdownPosition],
  );

  const classesExecuted = useMemo(() => executeOrValue(classes, open), [classes, open]);

  return (
    <ClickAwayListener onClickAway={() => !disabled && open && toggleContent(false)}>
      <div className={cx(styles.dropdownRoot, classesExecuted?.root, disabled && styles.disabled)}>
        <div
          ref={selectorRef}
          onClick={() => !disabled && toggleContent(!open)}
          className={cx(styles.dropdownSelector, classesExecuted?.selector)}
        >
          {executeOrValue(children, open)}
        </div>
        {open && (
          <div
            className={cx(
              styles.dropdownContent,
              !isTestEnv() && styles.dropdownContentAnimation,
              classesExecuted?.content,
            )}
            style={dropdownProps}
            ref={dropdownRef}
            onKeyDown={handleKeyDown}
          >
            {executeOrValue(dropdown, toggleContent)}
            {footerComponent && footerComponent}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default Dropdown;

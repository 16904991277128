import { ThemeOptions } from '@material-ui/core/styles/createTheme';
import {
  pageBackground,
  greyScale,
  blueScale,
  indigoScale,
  indigoBlueScale,
  blueLightScale,
  newGreyScale,
  greyModernScale,
  greyBlueScale,
  greenScale,
  tealScale,
  orangeScale,
  yellowScale,
  redScale,
  pinkScale,
  landingBlue,
  attentionRed,
  warningYellow,
  failureRed,
  infoBlue,
  successGreen,
  purpleScale,
} from './colors';

export const landingTheme: ThemeOptions = {
  typography: {
    // Header X-Large - Commissioner SemiBold 24/32
    h1: {
      fontSize: 24,
      fontFamily: 'Commissioner, sans-serif',
      fontWeight: 600,
    },
    // Header Large - Commissioner Regular 20/28
    h2: {
      fontSize: 20,
      fontFamily: 'Commissioner, sans-serif',
      fontWeight: 600,
    },
    // Header Medium - Commissioner Semibold 16/22
    h3: {
      fontSize: 18,
      fontFamily: 'Commissioner, sans-serif',
      fontWeight: 600,
    },
    // Header Small - Commissioner Regular 14/20
    h4: {
      fontSize: 16,
      fontFamily: 'Commissioner, sans-serif',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 12,
      fontFamily: 'Commissioner, sans-serif',
      fontWeight: 500,
      color: greyScale[500],
    },
    body1: {
      fontSize: 14,
      fontFamily: 'Commissioner, sans-serif',
    },
    body2: {
      fontSize: 12,
      fontFamily: 'Commissioner, sans-serif',
    },
    caption: {
      fontSize: 12,
      fontWeight: 300,
      fontFamily: 'Commissioner, sans-serif',
    },
  },
  boxShadow: {
    default: '0px 8px 24px rgba(0, 53, 133, 0.1)',
    strong: '0px 8px 24px rgba(0, 53, 133, 0.3)',
  },
  spacing: 4,
  palette: {
    primary: landingBlue,
    secondary: attentionRed,
    warning: warningYellow,
    error: failureRed,
    info: infoBlue,
    success: successGreen,
    text: {
      primary: greyScale[800],
      secondary: greyScale[500],
      hint: greyScale[300],
    },
    background: {
      default: '#fff',
      lightBlue: pageBackground,
    },
    grey: greyScale,
    blue: blueScale,
    indigo: indigoScale,
    indigoBlue: indigoBlueScale,
    blueLight: blueLightScale,
    newGrey: newGreyScale,
    greyModern: greyModernScale,
    greyBlue: greyBlueScale,
    green: greenScale,
    teal: tealScale,
    orange: orangeScale,
    yellow: yellowScale,
    red: redScale,
    pink: pinkScale,
    purple: purpleScale,
  },
  overrides: {
    MuiFab: {
      root: {
        backgroundColor: greyScale[100],
      },
      secondary: {
        color: '#fff',
      },
    },
    // @ts-ignore
    MuiAlert: {
      root: {
        fontSize: 14,
        padding: '8px 16px',
        borderRadius: 8,
        boxShadow: 'none !important',
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: 14,
        fontFamily: 'Commissioner, sans-serif',
      },
    },
    MuiDialogContent: {
      root: {
        fontSize: 14,
        fontFamily: 'Commissioner, sans-serif',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '16px 24px',
      },
    },
    MuiChip: {
      root: {
        backgroundColor: greyScale[100],
        fontFamily: 'Commissioner, sans-serif',
        borderRadius: 12,
        fontSize: 14,
      },
      outlined: {
        borderColor: greyScale[200],
      },
      colorSecondary: {
        color: '#FFFFFF',
      },
      sizeSmall: {
        height: 21,
        fontSize: 12,
        borderRadius: 8,
      },
      label: {
        paddingRight: 20,
        paddingLeft: 20,
      },
      labelSmall: {
        paddingRight: 16,
        paddingLeft: 16,
      },
      icon: {
        marginLeft: 14,
        marginRight: -10,
      },
      iconSmall: {
        marginLeft: 12,
        marginRight: -8,
      },
      deleteIcon: {
        marginLeft: -12,
        marginRight: 12,
      },
      deleteIconSmall: {
        marginLeft: -8,
        marginRight: 10,
      },
      deleteIconColorSecondary: {
        color: 'rgba(255, 255, 255, 0.7)',
      },
    },
    MuiButtonGroup: {
      root: {
        borderRadius: 6,
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'Commissioner, sans-serif',
        fontWeight: 600,
        fontSize: 14,
        textTransform: 'none',
        height: 34,
        borderRadius: 10,
        padding: '10px 20px',
        whiteSpace: 'nowrap',
      },
      contained: {
        padding: '10px 20px',
        backgroundColor: '#fff',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      containedSizeSmall: {
        padding: '10px 20px',
      },
      containedSecondary: {
        color: '#fff',
      },
      outlined: {
        padding: '10px 20px',
      },
      outlinedSizeSmall: {
        padding: '10px 20px',
      },
      text: {
        padding: '10px 20px',
      },
      sizeSmall: {
        fontSize: 12,
        height: 32,
        padding: '10px 20px',
      },
      label: {
        justifyContent: 'center',
        alignItems: 'center',
        lineHeight: 'normal',
      },
    },
    MuiTab: {
      root: {
        fontSize: 14,
        fontFamily: 'Commissioner, sans-serif',
        textTransform: 'none',
      },
    },
    MuiTableCell: {
      head: { fontWeight: 500, fontSize: 14, fontFamily: 'Commissioner, sans-serif' },
      body: { fontSize: 14, fontFamily: 'Commissioner, sans-serif' },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'Commissioner, sans-serif',
        borderRadius: 8,
        boxShadow: '0px 8px 24px rgba(0, 53, 133, 0.1) !important',
        padding: '8px 12px',
        '& code': {
          backgroundColor: greyScale[500],
          height: '18px',
          lineHeight: '18px',
          fontWeight: 'bold',
          minWidth: '18px',
          textAlign: 'center',
          borderRadius: 4,
          display: 'inline-block',
          padding: '0 4px',
          boxSizing: 'border-box',
        },
      },
    },
    MuiBadge: {
      colorSecondary: {
        color: '#fff',
      },
    },
    MuiPaper: {
      root: {
        // overwrite variations
        boxShadow: '0px 8px 24px rgba(0, 53, 133, 0.1) !important',
        borderRadius: 8,
        border: 'none',
      },
      rounded: {
        borderRadius: 8,
      },
      outlined: {
        border: 'none',
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: 'none!important',
        borderRadius: 0,
        margin: 0,
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        boxSizing: 'content-box',
        padding: 0,
        height: 28,
        minHeight: 28,
        '&$expanded': {
          height: 28,
          minHeight: 28,
        },
      },
    },
    MuiStepConnector: {
      lineVertical: {
        minHeight: 12,
      },
    },
  },
  props: {
    MuiDialog: {
      disableEnforceFocus: true,
    },
    MuiPopover: {
      disableEnforceFocus: true,
    },
  },
  zIndex: {
    snackbar: 310000,
  },
};

export default landingTheme;

import { SortFieldInfo } from '@clef/shared/types';

import { createAtom } from '../utils';

// for memory list scroll offset after refresh page
export const historyListOffsetAtom = createAtom<number | undefined>('historyListOffset', undefined);
export const hideAutoGeneratedSnapshotAtom = createAtom<boolean>('hideAutoGeneratedSnapshot', true);

export const showGroundTruthAtom = createAtom<boolean>('showGroundTruth', true);

export const UploadTimeDescSortField: SortFieldInfo = {
  label: 'Upload Time',
  sortType: 'media',
  sortValue: 'uploadTime',
  sortOrder: 'desc',
  orderLabel: '(Newest to Oldest)',
};
export const sortFieldAtom = createAtom<SortFieldInfo>('sortField', UploadTimeDescSortField);

export const itemPerRowOffsetAtom = createAtom<number>('itemPerRowOffset', 0);
export const paginationLimitAtom = createAtom<number>('paginationLimit', 50);

export enum CreateSnapshotDialogMode {
  FULL = 'FULL',
  SUBSET = 'SUBSET',
}
export const createSnapshotDialogModeAtom = createAtom<CreateSnapshotDialogMode | null>(
  'createSnapshotDialogMode',
  null,
);

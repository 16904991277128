import React from 'react';
import { alpha, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { blueGrey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  pulsing: {
    borderRadius: 10,
    boxShadow: `0 0 0 var(--pulseColor5)`,
    animation: `$pulsing 1500ms ${theme.transitions.easing.easeOut} infinite`,
  },
  '@keyframes pulsing': {
    '0%': {
      boxShadow: `0 0 0 0 var(--pulseColor5)`,
    },
    '70%': {
      boxShadow: `0 0 0 var(--pulseSize) var(--pulseColor0)`,
    },
    '100%': {
      boxShadow: `0 0 0 0 var(--pulseColor0)`,
    },
  },
  fillContainer: {
    width: '100%',
    height: '100%',
  },
}));

export type PulseWrapperProps = {
  enabled?: boolean;
  className?: string;
  pulseSize?: number;
  fillContainer?: boolean;
  pulseColor?: string;
};

export const PulseWrapper: React.FC<PulseWrapperProps> = props => {
  const { enabled, className, pulseSize = 8, children, fillContainer, pulseColor } = props;
  const styles = useStyles();

  return (
    <div
      style={{
        // @ts-ignore
        '--pulseSize': `${pulseSize}px`,
        '--pulseColor0': alpha(pulseColor ?? blueGrey[600], 0.0),
        '--pulseColor5': alpha(pulseColor ?? blueGrey[600], 0.5),
      }}
      className={cx(fillContainer && styles.fillContainer, enabled && styles.pulsing, className)}
    >
      {children}
    </div>
  );
};

export default PulseWrapper;

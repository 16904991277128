import { BaseAPI } from './base_api';
import { ApiResponse, ProjectId, SelectMediaOption, Tag } from '@clef/shared/types';

class TagAPIClass extends BaseAPI {
  async createNewTag(params: { projectId: ProjectId; name: string }): Promise<ApiResponse<Tag>> {
    return this.postJSON('new', {
      ...params,
      color: '#E3E8EF',
    });
  }

  async updateTag(params: { tagId: number; name?: string; color?: string }) {
    return this.postJSON('update', params);
  }

  async archiveTag(tagId: number) {
    return this.postJSON('update', {
      tagId,
      isArchived: true,
    });
  }

  async attchTagIdsToMedias(params: {
    projectId: ProjectId;
    tagIds: number[];
    selectOption: SelectMediaOption;
    accumulation?: boolean;
  }) {
    return this.postJSON('attach_to_medias', params);
  }

  async attachTagStringsToMedias(params: {
    projectId: ProjectId;
    tags: string[];
    selectOption: SelectMediaOption;
    accumulation?: boolean;
  }): Promise<ApiResponse<{ tags: Tag[] }>> {
    return this.postJSON('media', params);
  }

  async tagsByProjectId(projectId: ProjectId): Promise<ApiResponse<Tag[]>> {
    return this.get('tags', {
      projectId,
    });
  }
}

export const TagAPI = new TagAPIClass('tag');

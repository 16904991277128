import React, { useMemo } from 'react';
import cx from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Box, Theme, makeStyles } from '@material-ui/core';
import { isProjectOwner } from '@clef/shared/utils';
import { Project, UserId, User } from '@clef/shared/types';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import ProjectCard from '@/components/ProjectCard/NewProjectCard';

const useStyles = makeStyles<Theme>(theme => ({
  hoverClickContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    zIndex: 105,

    '&:hover': {
      background: 'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 60px)',
      borderColor: theme.palette.grey[400],
      '& $infoIcon': {
        opacity: 1,
      },
      '& $checkIcon': {
        opacity: 1,
      },
    },
    transition: 'all 0.135s cubic-bezier(0.0,0.0,0.2,1)',
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 16,
    overflow: 'hidden',
  },
  disableSelectionOnItem: {
    cursor: 'default',

    '&:hover': {
      background: 'none',
      borderColor: 'transparent',
    },
  },
  hoverIconContainerSelected: {
    borderColor: theme.palette.primary.main,
    '& $checkIcon': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
  },
  checkIcon: {
    opacity: 0,
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
    color: 'rgba(255,255,255, 0.7)',
  },
  infoIcon: {
    opacity: 0,
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
    color: 'rgba(255,255,255, 0.7)',
    '&:hover': {
      cursor: 'pointer',
      color: 'rgba(255,255,255, 1)',
    },
  },
}));

type ProjectCardWithSelectionProps = {
  onSelected?: (selected: boolean) => void;
  selected?: boolean;
  disableSelection?: boolean;
  project: Project;
};

const ProjectCardWithSelection = (props: ProjectCardWithSelectionProps) => {
  const styles = useStyles();
  const currentUser = useTypedSelector(state => state.login.user!);
  const users = useTypedSelector(state => state.user.users);
  const { project, disableSelection, selected, onSelected } = props;
  const projectOwner = project.usersRoles?.find(user => isProjectOwner(user.role));
  const userById = useMemo(() => {
    return users.reduce((accum: { [key: UserId]: User }, val) => {
      accum[val.id] = val;
      return accum;
    }, {});
  }, [users]);

  return (
    <Box padding={4}>
      <Box position="relative">
        <ProjectCard
          key={project.id}
          project={project}
          ownerUser={userById[projectOwner?.userId ?? '']}
          isCurrentUserOwner={currentUser.id === projectOwner?.userId}
          style={{
            width: '100%',
            height: '100%',
            aspectRatio: '3 / 2',
          }}
        />
        <div
          className={cx(
            styles.hoverClickContainer,
            disableSelection && styles.disableSelectionOnItem,
            selected && styles.hoverIconContainerSelected,
          )}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onSelected?.(!selected);
          }}
        >
          {!disableSelection && onSelected && (
            <CheckCircleIcon
              data-testid="select-media-grid-item"
              className={cx(styles.checkIcon)}
              onClick={event => {
                event.stopPropagation();
                event.nativeEvent.stopImmediatePropagation();
                onSelected?.(!selected);
              }}
            />
          )}
        </div>
      </Box>
    </Box>
  );
};

export default ProjectCardWithSelection;

import React from 'react';
import { MediaStatusType } from '@clef/shared/types';
import { rawGrey, pendingLabelingGrey, approveGreen, greyScale } from '@clef/client-library';
import { Tooltip } from '@material-ui/core';
import AccountCircleRounded from '@material-ui/icons/AccountCircleRounded';
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded';

const RawIcon = React.memo(() => (
  <Tooltip placement="top" title={t('Raw')} arrow>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="9.5" stroke="white" strokeWidth="2" strokeDasharray="4,4" />
    </svg>
  </Tooltip>
));

const InTaskIcon = React.memo(() => (
  <Tooltip placement="top" title={t('Assigned')} arrow>
    <AccountCircleRounded htmlColor={greyScale[300]} />
  </Tooltip>
));

const ApprovedIcon = React.memo(() => (
  <Tooltip placement="top" title={t('Approved')} arrow>
    <CheckCircleRounded htmlColor={approveGreen} />
  </Tooltip>
));

export type MediaStatusDisplayProps = {
  text: string;
  bgColor: string;
  icon: JSX.Element;
};

export const MediaStatusProps = (status: MediaStatusType): MediaStatusDisplayProps =>
  ({
    [MediaStatusType.Raw]: {
      text: t('Raw'),
      bgColor: rawGrey,
      icon: <RawIcon />,
    },
    [MediaStatusType.Approved]: {
      text: t('Approved'),
      bgColor: approveGreen,
      icon: <ApprovedIcon />,
    },
    [MediaStatusType.InTask]: {
      text: t('In Task'),
      bgColor: pendingLabelingGrey,
      icon: <InTaskIcon />,
    },
  }[status]);

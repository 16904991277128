import { makeStyles } from '@material-ui/core';

export const useSnapshotStyles = makeStyles(theme => ({
  createDialog: {
    width: 633,
    maxWidth: 633,
    padding: theme.spacing(6),
  },
  dialogSection: {
    padding: theme.spacing(0),
  },
  dialogContent: {
    padding: theme.spacing(4, 0, 6, 0),
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  dialogTitleText: {
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.greyModern[900],
  },
  noticeBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 44,
    background: theme.palette.greyModern[50],
    borderRadius: 6,
    marginTop: theme.spacing(4),
    color: theme.palette.greyModern[500],
    fontWeight: 500,
  },
  mainInfoFont: {
    color: theme.palette.grey[800],
    whiteSpace: 'pre-wrap',
    fontWeight: 500,
  },
  subInfoFont: {
    color: theme.palette.greyModern[900],
    whiteSpace: 'pre-wrap',
    fontWeight: 400,
  },
  dialogActions: {
    width: '100%',
    justifyContent: 'space-between',
  },
  opsButton: {
    borderColor: theme.palette.grey[300],
    color: theme.palette.grey[600],
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  createDialogBtn: {
    width: 286,
  },
  revertDialog: {
    width: 532,
    padding: theme.spacing(6, 8),
  },
  waringBox: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
  },
  warningIcon: {
    marginRight: theme.spacing(2),
  },
  revertDialogBtn: {
    width: 230,
  },
}));

export default useSnapshotStyles;

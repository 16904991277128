import React from 'react';
import { Box, Dialog, Paper, makeStyles } from '@material-ui/core';
import { Button, IconButton, Typography } from '@clef/client-library';
import { ApiKey } from '@clef/shared/types';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import Close from '@material-ui/icons/Close';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    borderRadius: 20,
  },
  icon: {
    color: theme.palette.green[500],
    width: 50,
    height: 50,
  },
  apiKey: {
    backgroundColor: theme.palette.greyModern[100],
    borderRadius: 6,
    padding: theme.spacing(0, 3),
    fontSize: 14,
    // width: 380,
    flex: 1,
    height: 40,
    lineHeight: '40px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  copyButton: {
    backgroundColor: theme.palette.greyModern[100],
    borderRadius: 10,
    width: 40,
    height: 40,
  },
}));

export type UnmaskedKeyDialogProps = {
  apiKey: ApiKey;
  purpose?: 'view' | 'create';
  onClose: () => void;
};

const UnmaskedKeyDialog: React.FC<UnmaskedKeyDialogProps> = props => {
  const { apiKey, onClose, purpose = 'create' } = props;
  const styles = useStyles();
  const copyToClipboard = useCopyToClipboard({ text: apiKey.key });
  return (
    <Dialog open onClose={onClose}>
      <Paper className={styles.dialogRoot}>
        <Box paddingX={6} paddingBottom={6} paddingTop={7} position="relative" maxWidth={400}>
          <Box position="absolute" right={4} top={4}>
            <IconButton id="close-dialog-button" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          {purpose === 'create' ? (
            <>
              <Box display="flex" justifyContent="center">
                <CheckCircle className={styles.icon} />
              </Box>
              <Box marginTop={6} textAlign="center">
                <Typography variant="h2_semibold">
                  {t('“{{keyName}}” Has Been Successfully Created', {
                    keyName: (
                      <Typography
                        maxWidth={200}
                        variant="h2_semibold"
                        style={{ display: 'inline-block', verticalAlign: 'bottom' }}
                      >
                        {apiKey.name}
                      </Typography>
                    ),
                  })}
                </Typography>
              </Box>
            </>
          ) : (
            <Box marginTop={6} textAlign="center">
              <Typography variant="h2_semibold" width="100%">
                {apiKey.name}
              </Typography>
            </Box>
          )}
          <Box marginTop={3}>
            <Typography variant="h4_semibold">{t('API Key')}</Typography>
          </Box>
          <Box marginTop={2} display="flex" flexWrap="nowrap">
            <Box className={styles.apiKey}>{apiKey.key}</Box>
            <Box marginLeft={2}>
              <IconButton
                id="copy-api-key"
                className={styles.copyButton}
                tooltip={t('Copy API Key')}
                onClick={copyToClipboard}
              >
                <FileCopyOutlined />
              </IconButton>
            </Box>
          </Box>
          <Box marginTop={5}>
            <Button
              id="close-api-key-created-dialog"
              color="primary"
              variant="contained"
              onClick={onClose}
              fullWidth
            >
              {t('Done')}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default UnmaskedKeyDialog;

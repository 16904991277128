import { IconButton, useKeyPress } from '@clef/client-library';
import { ButtonBaseProps, Grid, MenuItem, MenuList, Paper, Tooltip } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import React, { useEffect, useRef, useState } from 'react';
import { useLabelingDrawer } from '../../../components/Labeling/LabelingDrawer';
import { useLabelingState } from '../../../components/Labeling/labelingState';
import { DefectItem } from '../../../components/Labeling/ToolDefectCard';
import { useDefectSelector } from '../../../store/defectState/actions';
import { LabelingTaskState } from '../labelingTaskState';
import { useLabelingTaskStyles } from '../labelingTaskStyles';

export type DefectSelectorProps = {
  onClassificationChanged?: (defectId: number) => void;
  currentMediaId: number;
  annotationData: LabelingTaskState['annotationData'];
};

const ClassificationDefectSelector: React.FC<DefectSelectorProps> = ({
  onClassificationChanged,
  currentMediaId,
  annotationData,
}) => {
  const styles = useLabelingTaskStyles();
  const allDefects = useDefectSelector();
  const [menuOpen, setMenuOpen] = useState(true);
  const { dispatch: dispatchLabelingState } = useLabelingState();
  // assume that there is only one annotation for classification media
  const classificationAnnotation = annotationData?.[currentMediaId]?.[0];
  const currentMediaDefect = allDefects?.find(
    defect => classificationAnnotation?.defectId === defect.id,
  );
  const anchorEl = useRef<null | HTMLButtonElement>(null);
  const { toggleDefectBookDrawer } = useLabelingDrawer();

  useEffect(() => {
    dispatchLabelingState(draft => {
      draft.anyMenuOpened = menuOpen;
    });
  }, [dispatchLabelingState, menuOpen]);
  useKeyPress('c', () => {
    setMenuOpen(!menuOpen);
  });
  useKeyPress('esc', e => {
    e.preventDefault();
    setMenuOpen(false);
  });

  const [lastSelectedDefectId, setLastSelectedDefectId] = useState<number | undefined>(undefined);
  const hasAutoFocusDefect = !!lastSelectedDefectId || !!currentMediaDefect;

  const focusItemAction: ButtonBaseProps['action'] = useRef(null);
  useEffect(() => {
    if (menuOpen) {
      focusItemAction.current?.focusVisible();
    }
  }, [currentMediaId, allDefects, menuOpen]);

  useKeyPress(
    'v',
    () => {
      if (currentMediaDefect?.id) {
        toggleDefectBookDrawer(currentMediaDefect.id);
      }
    },
    { id: 'task-open-defect-definition' },
  );

  return (
    <div className={styles.defectSelector}>
      <Paper ref={anchorEl}>
        <Tooltip
          title={t('Toggle popup {{c}}', {
            c: <span className={styles.codeBlock}>c</span>,
          })}
        >
          <MenuItem onClick={() => setMenuOpen(true)} className={styles.defectSelectorButton}>
            {currentMediaDefect ? (
              <DefectItem defect={currentMediaDefect} />
            ) : (
              <strong>{t('Select a class')}</strong>
            )}
          </MenuItem>
        </Tooltip>
      </Paper>
      {menuOpen && (
        <Paper className={styles.defectSelectorMenu}>
          <MenuList
            data-testid="select-defect-menu"
            className={styles.defectMenuContainer}
            autoFocusItem
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={styles.defectSelectorHeader}
            >
              <Grid item>
                <strong>{t('Select a class:')}</strong>
              </Grid>
              <Grid item>
                <IconButton size="small" aria-label="close" onClick={() => setMenuOpen(false)}>
                  <KeyboardArrowDown fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
            {(allDefects ?? []).map((defect, index) => {
              const autoFocus =
                defect.id === currentMediaDefect?.id ||
                (!currentMediaDefect && defect.id === lastSelectedDefectId) ||
                (!hasAutoFocusDefect && index == 0);
              return (
                <MenuItem
                  key={defect.id}
                  onClick={() => {
                    onClassificationChanged?.(defect.id);
                    setLastSelectedDefectId(defect.id);
                  }}
                  autoFocus={autoFocus}
                  selected={defect.id === currentMediaDefect?.id}
                  aria-selected={defect.id === currentMediaDefect?.id}
                  action={autoFocus ? focusItemAction : undefined}
                >
                  <DefectItem defect={defect} />
                </MenuItem>
              );
            })}
          </MenuList>
        </Paper>
      )}
    </div>
  );
};

export default ClassificationDefectSelector;

import { EdgeRecord, JobInfoParsed } from '@clef/shared/types';
import { ButtonProps } from '@material-ui/core';

export enum DeviceType {
  EdgeDevice = 'EdgeDevice',
  App = 'App',
  CloudInference = 'CloudInference',
}

export enum SectionOptions {
  dynamic = 'dynamic',
  static = 'static',
  edge = 'edge',
}

export type SelectedSectionType = keyof typeof SectionOptions;

export type ModelBundle = JobInfoParsed & {
  bundleId: string;
  runType?: string;
  threshold?: number;
  bundleGeneratedTime?: string;
};

export const getDeviceTypes = (device: EdgeRecord | undefined) => {
  const deviceIds = {
    [DeviceType.EdgeDevice]: [] as string[],
    [DeviceType.App]: [] as string[],
    [DeviceType.CloudInference]: [] as string[],
  };

  if (device?.type === DeviceType.EdgeDevice) {
    deviceIds[DeviceType.EdgeDevice].push(device.id);
  }
  if (device?.type === DeviceType.App) {
    deviceIds[DeviceType.App].push(device.id);
  }
  if (device?.type === DeviceType.CloudInference) {
    deviceIds[DeviceType.CloudInference].push(device.id);
  }
  const {
    [DeviceType.EdgeDevice]: selectedDeviceIds,
    [DeviceType.App]: selectedAppIds,
    [DeviceType.CloudInference]: selectedCloudIds,
  } = deviceIds;
  return { selectedDeviceIds, selectedAppIds, selectedCloudIds };
};

export const timeFromNow = (time: string | null) => {
  // Setup return object
  const tfn = { unitOfTime: 'sec', time: 0 };
  if (!time) {
    return tfn;
  }

  // Get timestamps
  const unixTime = new Date(time).getTime();
  if (!unixTime) return tfn;
  const now = new Date().getTime();

  // Calculate difference
  const difference = Math.abs(unixTime / 1000 - now / 1000);
  const getPlural = (t: number) => (t > 1 ? 's' : '');

  // Calculate time unit
  if (difference / (60 * 60 * 24 * 365) > 1) {
    // Years
    const year = Math.floor(difference / (60 * 60 * 24 * 365));
    tfn.unitOfTime = `year${getPlural(year)}`;
    tfn.time = year;
  } else if (difference / (60 * 60 * 24 * 45) > 1) {
    // Months
    const month = Math.floor(difference / (60 * 60 * 24 * 45));
    tfn.unitOfTime = `month${getPlural(month)}`;
    tfn.time = month;
  } else if (difference / (60 * 60 * 24) > 1) {
    // Days
    const day = Math.floor(difference / (60 * 60 * 24));
    tfn.unitOfTime = `day${getPlural(day)}`;
    tfn.time = day;
  } else if (difference / (60 * 60) > 1) {
    // Hours
    const hour = Math.floor(difference / (60 * 60));
    tfn.unitOfTime = `hour${getPlural(hour)}`;
    tfn.time = hour;
  } else if (difference / 60 > 1) {
    // Minutes
    const min = Math.floor(difference / 60);
    tfn.unitOfTime = `min${getPlural(min)}`;
    tfn.time = min;
  } else {
    // Seconds
    const sec = Math.floor(difference);
    tfn.unitOfTime = `sec${getPlural(sec)}`;
    tfn.time = sec;
  }

  // Return time from now data
  return tfn;
};

export const confirmationOptions = (content: string) => ({
  title: t('Confirm Deployment'),
  content: t(content),
  confirmText: t('Deploy'),
  color: 'primary' as ButtonProps['color'],
});

export const convertToLowerCaseWithoutSpace = (str: string) =>
  str.toLowerCase().replace(/\s/g, '-');

const MODEL_ARCH_NAMES: string[] = ['RetinaNet', 'UNet', 'ResNet'];
export const checkIsStaticModel = (model: JobInfoParsed) =>
  model?.modelArch && MODEL_ARCH_NAMES.includes(model.modelArch);

export const getSelectedSections = (model?: JobInfoParsed) => {
  const deviceActivationMap = {
    [SectionOptions.dynamic]: [SectionOptions.dynamic],
    [SectionOptions.static]: [SectionOptions.dynamic, SectionOptions.static],
    [SectionOptions.edge]: [],
  };

  const sectionName =
    model?.modelArch &&
    (checkIsStaticModel(model) ? SectionOptions.static : SectionOptions.dynamic);

  const selectedSection = sectionName
    ? (deviceActivationMap[sectionName] as SelectedSectionType[])
    : [];
  return selectedSection;
};

export const getMobileInferenceEndpoint = (endpointId?: string) => {
  const baseUrl = window.location.origin;
  return `${baseUrl}/predict/${endpointId}`;
};

import React, { useRef, useState } from 'react';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';

import { pendoEntity } from '@/utils/pendo';
import { useTypedSelector } from '@/hooks/useTypedSelector';

const useStyles = makeStyles({
  dialogPaper: {
    width: 992,
    maxWidth: 992,
  },
  dialogContentRoot: {
    padding: 0,

    '&:first-child': {
      paddingTop: 0,
    },
  },
  video: {
    width: '100%',
    display: 'block',
    objectFit: 'fill',
  },
});

export interface ChooseTypeGuideProps {
  onClose(): void;
}

export const ChooseTypeGuide: React.FC<ChooseTypeGuideProps> = ({ onClose }) => {
  const styles = useStyles();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [startPlaying, setStartPlaying] = useState(false);

  const currentUser = useTypedSelector(state => state.login.user);

  const handleClickDialog = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handlePlayVideo = (event: React.SyntheticEvent<HTMLVideoElement>) => {
    if (event.currentTarget.currentTime < 1 && !startPlaying) {
      setStartPlaying(true);
      pendoEntity?.track('project_type_guide_video_start_play', {
        userName: currentUser ? `${currentUser.name} ${currentUser.lastName}` : undefined,
        email: currentUser ? currentUser.email : undefined,
        orgName: currentUser ? currentUser.company : undefined,
        orgId: currentUser ? currentUser.orgId : undefined,
      });
    }
  };

  const handleClose = () => {
    if (videoRef.current) {
      const playedSeconds = videoRef.current.currentTime;
      pendoEntity?.track('project_type_guide_video_play_duration', {
        userName: currentUser ? `${currentUser.name} ${currentUser.lastName}` : undefined,
        email: currentUser ? currentUser.email : undefined,
        duration: Math.round(playedSeconds),
      });

      onClose?.();
    }
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      onClick={handleClickDialog}
      classes={{ paper: styles.dialogPaper }}
    >
      <DialogContent classes={{ root: styles.dialogContentRoot }}>
        <video
          ref={videoRef}
          className={styles.video}
          src="https://landing-lens-support.s3.us-east-2.amazonaws.com/video/understand-the-different-project-types.mp4"
          poster="https://landing-lens-support.s3.us-east-2.amazonaws.com/video/understand-the-different-project-types-poster.png"
          controls
          onPlay={handlePlayVideo}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ChooseTypeGuide;

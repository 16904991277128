import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  modelPerformanceSideBar: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(20, 4, 4, 4),
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(8),
  },
  modelPerformanceSideBarTopPadding: {
    paddingTop: 24,
  },
  switchModelButton: {
    height: 40,
    borderRadius: 6,
    overflow: 'hidden',
    width: 'calc(100% + 24px)',
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.common.white}`,
    margin: theme.spacing(0, -3),
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
  },
  switchModelMenu: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
  modelName: {
    fontWeight: 500,
    marginRight: theme.spacing(2),
  },
  modelBadge: {
    color: '#9BA0A6',
    background: '#EEEEEE',
    padding: '1px 4px',
    borderRadius: 4,
    display: 'inline-flex',
    marginRight: theme.spacing(2),
    fontWeight: 700,
  },
  grow: {
    flex: 1,
  },
  modelsMenuSectionHeader: {
    padding: '6px 16px',
    color: '#9BA0A6',
    fontWeight: 700,
  },
  modelItem: {
    width: 350,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  modelActionButtonGroup: {
    marginTop: 20,
  },
  saveModelButton: {
    marginRight: 8,
  },
}));

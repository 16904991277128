import {
  MediaOKNGName,
  MediaSplitName,
  DeploymentModelName,
  MediaAllSplitName,
} from '../constants/stats_card';

/* snake_case -> Snake Case */
export function getReadableText(input: string) {
  return input
    .split('_')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

/** convert the first character to uppercase and the rest to lowercase
 * hello -> Hello
 * HELLO -> Hello
 */
export function toTitle(input: string): string {
  return input.replace(
    /(\w)(\w*)/g,
    (_, firstChar, rest) => firstChar.toUpperCase() + rest.toLowerCase(),
  );
}

const DEFAULT_ORDER_MAPPING: Record<string, number> = {
  // Media OK/NG
  [MediaOKNGName.OK]: 1,
  [MediaOKNGName.NG]: 2,
  [MediaOKNGName.Unlabled]: 255,

  // Media Split
  [MediaAllSplitName]: 0.1,
  [MediaSplitName.Train]: 3,
  [MediaSplitName.Dev]: 4,
  [MediaSplitName.Test]: 5,
  [MediaSplitName.Unassigned]: 256,

  // Deployment Models
  [DeploymentModelName.AvailableModels]: 6,
  [DeploymentModelName.ActiveModels]: 7,
};

/**
 * Compare function to determine the order of the data keys for stats card. By default, names
 * are in the order of "OK", "NG", "train", "dev", "test", frist character of custom name,
 * "Unlabled", and "unassigned".
 *
 * @param {string} keyA Data key A
 * @param {string} keyB Data key B
 * @param {Record<string, number>} [orderMapping=DEFAULT_ORDER_MAPPING] An optional 1-based
 * order mapping with a default value "DEFAULT_ORDER_MAPPING"
 * @returns number
 */
export function statsCardDataKeyCompareFn(
  splitA: string,
  splitB: string,
  orderMapping: Record<string, number> = DEFAULT_ORDER_MAPPING,
): number {
  const weightA = orderMapping[splitA] || splitA.charCodeAt(0);
  const weightB = orderMapping[splitB] || splitB.charCodeAt(0);
  return weightA - weightB;
}

export const isLandingLensSupportEmail = (email: string) => {
  if (email === 'landinglens_support@landing.ai') {
    return true;
  }

  // Matching pattern like "landinglens_support+any@landing.ai"
  return /landinglens_support\+.*@landing\.ai$/.test(email);
};

/**
 * @description: for generating random id
 * @param {number} length
 * @return string
 */
export const generateRandomId = (length: number = 8): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

import React, { useState } from 'react';
import { useDefectSelectorWithArchived } from '../../../store/defectState/actions';
import {
  ApiResponseLoader,
  Button,
  SelectOptions,
  useStateSyncSearchParams,
} from '@clef/client-library';
import { Typography, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useDefectBookEnhancedStyles } from '../defectBookEnhancedStyles';
import DefectCardView from './DefectCardView';
import DefectDetailView from './DefectDetailView';
import { SELECTED_DEFECT_SEARCH_PARAM } from './utils';
import CreateDefectDialog from './CreateDefectDialog';
import { defectColors } from '@clef/client-library';
import classNames from 'classnames';
import { useHistory } from 'react-router';

interface DefectListProps {
  className?: string;
  style?: React.CSSProperties;
}

const DefectSortOptions = {
  UpdateTimeLatestFirst: t('Last Update Time - Latest First'),
  UpdateTimeEarliestFirst: t('Last Updated Time - Earliest First'),
  CreationTimeNewestFirst: t('Creation Time - Newest First'),
  CreationTimeOldestFirst: t('Creation Time - Oldest First'),
};

const DefectList: React.FC<DefectListProps> = ({ className, style }) => {
  const defects = useDefectSelectorWithArchived();
  const styles = useDefectBookEnhancedStyles();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const [sortOption, setSortOption] = useState<string>(DefectSortOptions.CreationTimeOldestFirst);
  const [showArchivedDefects, setShowArchivedDefects] = useState<boolean>(false);
  const [selectedDefect, setSelectedDefect] = useStateSyncSearchParams(
    SELECTED_DEFECT_SEARCH_PARAM,
    '',
  );

  const history = useHistory();

  const downloadUrl = `${history.location.pathname}/print`;

  return (
    <section className={classNames(styles.defectListContainer, className)} style={style}>
      <Grid container justifyContent="flex-start" wrap="nowrap" alignItems="center">
        <div className={styles.flexGrow} />
        <Button
          id="download-defect-pdf"
          color="primary"
          // @ts-ignore
          target="_blank"
          size="small"
          href={downloadUrl}
          endIcon={<OpenInNewIcon />}
          className={styles.marginRight2}
        >
          {t('Download PDF')}
        </Button>
        <FormControlLabel
          control={
            <Checkbox
              checked={showArchivedDefects}
              onChange={() => {
                setShowArchivedDefects(!showArchivedDefects);
              }}
              data-testid="show-archived-defects"
              color="primary"
            />
          }
          label={t('Show archived classes')}
        />
        <Typography variant="body1" component="span" className={styles.marginRight2}>
          {t('Sort by:')}
        </Typography>
        <SelectOptions
          size="medium"
          value={sortOption}
          onChange={value => {
            setSortOption(value as string);
          }}
          options={Object.values(DefectSortOptions)}
        />
      </Grid>
      <div className={styles.defectListContentContainer}>
        <ApiResponseLoader response={defects} loading={false} defaultHeight={300}>
          {defects => {
            if (!defects.length) {
              return (
                <Typography variant="body1" className={styles.noDefectText}>
                  {t('You have not created any classes yet.')}
                </Typography>
              );
            }
            const defectsSorted = defects.slice().sort((defectA, defectB) => {
              if (sortOption === DefectSortOptions.CreationTimeOldestFirst) {
                return defectA.id - defectB.id;
              }
              if (sortOption === DefectSortOptions.CreationTimeNewestFirst) {
                return defectB.id - defectA.id;
              }
              // if defect is never updated, use creation time instead
              const lastUpdateA =
                new Date(defectA.updatedAt!) > new Date(defectA.createdAt!)
                  ? defectA.updatedAt!
                  : defectA.createdAt!;
              const lastUpdateB =
                new Date(defectB.updatedAt!) > new Date(defectB.createdAt!)
                  ? defectB.updatedAt!
                  : defectB.createdAt!;
              if (sortOption === DefectSortOptions.UpdateTimeEarliestFirst) {
                return +new Date(lastUpdateA) - +new Date(lastUpdateB);
              }
              if (sortOption === DefectSortOptions.UpdateTimeLatestFirst) {
                return +new Date(lastUpdateB) - +new Date(lastUpdateA);
              }
              return 0;
            });
            return selectedDefect ? (
              <DefectDetailView
                defects={defectsSorted}
                showArchived={showArchivedDefects}
                onSelectDefect={defectIdStr => setSelectedDefect(defectIdStr)}
                selectedDefectId={Number(selectedDefect)}
              />
            ) : (
              <DefectCardView
                defects={defectsSorted}
                showArchived={showArchivedDefects}
                onSelectDefect={defectIdStr => setSelectedDefect(defectIdStr)}
              />
            );
          }}
        </ApiResponseLoader>
      </div>
      {openCreateDialog && (
        <CreateDefectDialog
          onClose={() => setOpenCreateDialog(false)}
          nextDefectIndex={(defects?.length ?? 0) % defectColors.length}
        />
      )}
    </section>
  );
};

export default DefectList;

import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { Button } from '@clef/client-library';
import IdeaImage from '@/images/idea.png';

const useStyles = makeStyles(theme => ({
  tipContainer: {
    position: 'relative',
    top: -40,
  },
  ideaImage: {
    height: 48,
    width: 48,
  },
  tipHeaderText: {
    fontSize: theme.spacing(4),
    fontWeight: 'bold',
    textAlign: 'center',
  },
  tipBodyText: {
    color: theme.palette.greyModern[500],
    padding: theme.spacing(3, 0, 3, 0),
  },
  addEvaluationSetButton: {
    color: theme.palette.blue[800],
    background: theme.palette.blue[100],
    '&:hover': {
      background: theme.palette.blue[200],
    },
  },
  learnMoreButton: {
    color: theme.palette.blue[800],
    marginLeft: theme.spacing(2),
  },
}));

export type AddEvaluationSetTipProps = {
  handleAddClick: () => void;
  handleGotItClick: () => void;
};

const AddEvaluationSetTip: React.FC<AddEvaluationSetTipProps> = props => {
  const styles = useStyles();
  const { handleAddClick, handleGotItClick } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      className={styles.tipContainer}
    >
      <Box>
        <img className={styles.ideaImage} src={IdeaImage} />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography className={styles.tipHeaderText}>
          {t('Define Your Own Evaluation Set')}
        </Typography>
        <Typography className={styles.tipBodyText}>
          {t(
            'Create dataset snapshots, add them as evaluation sets, and analyze models using these snapshots!',
          )}
        </Typography>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
          <Button
            id="add-evaluation-set-button"
            onClick={handleAddClick}
            variant="contained"
            color="primary"
            className={styles.addEvaluationSetButton}
          >
            {t('Add Evaluation Set')}
          </Button>
          <Button
            id="evaluation-set-learn-more-button"
            className={styles.learnMoreButton}
            onClick={handleGotItClick}
          >
            {t('Got it')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddEvaluationSetTip;

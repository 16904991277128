import React from 'react';
import { Box, makeStyles, Link } from '@material-ui/core';
import { TabManager, Typography } from '@clef/client-library';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { lightfair } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import LaunchIcon from '@material-ui/icons/Launch';

import { Button } from '@clef/client-library';
import { PREDICT_URL } from '../../../constants';
import { Endpoint } from '@clef/shared/types';
import { useApiKey } from '@/hooks/api/useUserApi';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { useIsFeatureEnabledAndMayHideForSnowflake } from '@/hooks/useFeatureGate';

enum APICommandType {
  Python = 'python',
  Javascript = 'javascript',
  Curl = 'curl',
}

const getApiCommand = (type: APICommandType, endpoint?: Endpoint, version: 'v1' | 'v2' = 'v1') => {
  const endpointInfo = endpoint === undefined ? 'YOUR_ENDPOINT_ID' : endpoint.id;
  const hostname = `${
    endpoint === undefined || endpoint.type === 'dynamic'
      ? PREDICT_URL
      : `https://${endpoint.hostname}`
  }/inference/v1/predict?endpoint_id=${endpointInfo}`;

  if (type === APICommandType.Python) {
    return process.env.IS_SNOWFLAKE === 'true'
      ? `from PIL import Image
from landingai.predict import SnowflakeNativeAppPredictor
endpoint_id = "${endpointInfo}"
url = "https://${window.location.hostname}"
# Load your image
image = Image.open("image.png")
# Run inference
predictor = SnowflakeNativeAppPredictor(
  endpoint_id=endpoint_id, 
  native_app_url=url,
  snowflake_account=<snowflake account identifier>, 
  snowflake_user=<snowflake user>, 
  snowflake_password=<snowflake password>, 
  # or, in case of private key auth, use the following instead of \`snowflake_password\`:
  # snowflake_private_key="-----BEGIN PRIVATE KEY-----\\nMIIEvg...",
)
predictions = predictor.predict(image)`
      : `from PIL import Image
from landingai.predict import Predictor
# Enter your API Key
endpoint_id = "${endpointInfo}"
api_key = "FILL_YOUR_API_KEY"
# Load your image
image = Image.open("image.png")
# Run inference
predictor = Predictor(endpoint_id, api_key=api_key)
predictions = predictor.predict(image)`;
  } else if (type === APICommandType.Javascript) {
    return `import React from 'react';
import { useState } from "react";
import { InferenceContext, InferenceResult, PhotoCollector } from "landingai-react";

const apiInfo = {
  endpoint: "${hostname}",
  key: "<FILL_YOUR_API_KEY>",
};

export default function App() {
  const [image, setImage] = useState();

  return (
    <InferenceContext.Provider value={apiInfo}>
      <PhotoCollector setImage={setImage} />
      <InferenceResult image={image} />
    </InferenceContext.Provider>
  );
}`;
  }

  if (version === 'v1') {
    return `curl --location --request POST '${hostname}' \\
     --header 'Content-Type: multipart/form-data' \\
     --header 'apikey: YOUR_APIKEY' \\
     --header 'apisecret: YOUR_APISECRET' \\
     --form 'file=@"YOUR_IMAGE"'`;
  } else {
    return `curl --location --request POST '${hostname}' \\
     --header 'Content-Type: multipart/form-data' \\
     --header 'apikey: YOUR_APIKEY' \\
     --form 'file=@"YOUR_IMAGE"'`;
  }
};

const useStyles = makeStyles(theme => ({
  viewApiKeyAndSecretButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  viewApiKeyAndSecretButtonIcon: {
    marginLeft: theme.spacing(1),
    fontSize: 16,
  },
  viewApiKeyAndSecretButtonLabel: {
    fontSize: 12,
    textDecorationLine: 'underline',
  },
  copyApiCommandButton: {
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: 40,
    right: 10,
  },
  copyLegacyKeyMessage: {
    color: theme.palette.greyModern[600],
  },
  extraButton: {
    color: theme.palette.primary.main,
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  tabLabel: {
    fontSize: 14,
  },
}));

export type ApiCommandProps = {
  endpoint?: Endpoint;
  shouldWrapCodeLines?: boolean;
  onViewApiKeyAndSecretButtonClick?: () => void | Promise<void>;
};

export const ApiCommandComponent: React.FC<ApiCommandProps & { type: APICommandType }> = ({
  type,
  endpoint,
  shouldWrapCodeLines,
  onViewApiKeyAndSecretButtonClick,
}) => {
  const styles = useStyles();

  const [apiKey] = useApiKey({});
  const showApiKey = useIsFeatureEnabledAndMayHideForSnowflake().apiCode;

  const v1Command = getApiCommand(type, endpoint, 'v1');
  const v2Command = getApiCommand(type, endpoint, 'v2');

  const copyToClipboard = useCopyToClipboard({
    text: v2Command,
    successMessage: t('API command copied to clipboard.'),
  });

  const copyV1CommandToClipboard = useCopyToClipboard({
    text: v1Command,
    successMessage: t('API command copied to clipboard.'),
  });

  const getRefererLink = () => {
    if (type === APICommandType.Python) {
      return {
        sdk:
          process.env.IS_SNOWFLAKE === 'true'
            ? 'https://landing-ai.github.io/landingai-python/inferences/snowflake-native-app/'
            : 'https://landing-ai.github.io/landingai-python/',
      };
    } else if (type === APICommandType.Javascript) {
      return {
        sdk: 'https://landing-ai.github.io/landingai-js/',
        example: 'https://codesandbox.io/s/eloquent-tesla-yzsbsk?file=/src/App.js',
      };
    } else {
      return undefined;
    }
  };

  return (
    <Box position="relative">
      <Box overflow="scroll">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          position="relative"
          width="100%"
          minWidth={600}
        >
          <Box position="relative" data-testid="fre-deploy-page-api-command-box" width="100%">
            <SyntaxHighlighter
              wrapLongLines={shouldWrapCodeLines}
              lineNumberStyle={{ display: 'none' }}
              language="bash"
              style={lightfair}
              customStyle={{
                margin: 0,
                background: 'rgba(188, 198, 212, 0.3)',
                width: '100%',
              }}
            >
              {v2Command}
            </SyntaxHighlighter>
          </Box>

          <Box display="flex" alignItems="center" width="100%" marginTop={2}>
            {showApiKey && onViewApiKeyAndSecretButtonClick && (
              <Link
                id="fre-deploy-page-view-api-key-and-secret-button"
                className={styles.viewApiKeyAndSecretButton}
                onClick={onViewApiKeyAndSecretButtonClick}
              >
                <Typography className={styles.viewApiKeyAndSecretButtonLabel}>
                  {t('View API Key & Secret')}
                </Typography>
                <LaunchIcon className={styles.viewApiKeyAndSecretButtonIcon} />
              </Link>
            )}
            {!!apiKey?.apiKeyHash && type === APICommandType.Curl && (
              <Box className={styles.copyLegacyKeyMessage} marginLeft="auto">
                {t(
                  'If you have a legacy API Key and Secret, {{clickHere}} to copy the cURL command.',
                  {
                    clickHere: (
                      <span
                        role="button"
                        onClick={copyV1CommandToClipboard}
                        className={styles.extraButton}
                      >
                        {t('click here')}
                      </span>
                    ),
                  },
                )}
              </Box>
            )}
            {type === APICommandType.Python && (
              <Box className={styles.copyLegacyKeyMessage} marginLeft="auto">
                {t('Please refer to {{sdkLink}}', {
                  sdkLink: (
                    <span
                      role="button"
                      onClick={() => window.open(getRefererLink()?.sdk)}
                      className={styles.extraButton}
                    >
                      {t('Python SDK documentation')}
                    </span>
                  ),
                })}
              </Box>
            )}
            {type === APICommandType.Javascript && (
              <Box className={styles.copyLegacyKeyMessage} marginLeft="auto">
                {t('Please refer to {{sdkLink}} and {{exampleCode}}', {
                  sdkLink: (
                    <span
                      role="button"
                      onClick={() => window.open(getRefererLink()?.sdk)}
                      className={styles.extraButton}
                    >
                      {t('Javascript documentation')}
                    </span>
                  ),
                  exampleCode: (
                    <span
                      role="button"
                      onClick={() => window.open(getRefererLink()?.example)}
                      className={styles.extraButton}
                    >
                      {t('example code')}
                    </span>
                  ),
                })}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Button
        id="fre-deploy-page-copy-api-command-button"
        className={styles.copyApiCommandButton}
        onClick={copyToClipboard}
      >
        {t('Copy')}
      </Button>
    </Box>
  );
};

export const ApiCommand: React.FC<ApiCommandProps> = props => {
  const styles = useStyles();
  return (
    <Box width="100%">
      <TabManager
        classes={{ tabLabel: styles.tabLabel }}
        tabs={[
          {
            key: APICommandType.Python,
            title: t('Python SDK'),
            component: <ApiCommandComponent {...props} type={APICommandType.Python} />,
          },
          ...(process.env.IS_SNOWFLAKE === 'true'
            ? []
            : [
                {
                  key: APICommandType.Javascript,
                  title: t('Javascript SDK'),
                  component: <ApiCommandComponent {...props} type={APICommandType.Javascript} />,
                },
                {
                  key: APICommandType.Curl,
                  title: t('cURL Command'),
                  component: <ApiCommandComponent {...props} type={APICommandType.Curl} />,
                },
              ]),
        ]}
      />
    </Box>
  );
};

export default ApiCommand;

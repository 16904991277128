import React from 'react';
import { TabManager } from '@clef/client-library';
import ProjectSettingWithUserRole from '../ProjectSettingWithUserRole/ProjectSettingWithUserRole';
import ProjectSettingAdministrative from '../ProjectSettingsAdmin/ProjectSettingAdministrative';

interface ProjectSettingsProps {}

const ProjectSettingsAcessAndAdmin: React.FC<ProjectSettingsProps> = () => {
  return (
    <TabManager
      tabs={[
        {
          key: 'permission',
          title: t('Permission'),
          component: <ProjectSettingWithUserRole />,
        },
        {
          key: 'administrative',
          title: t('Administrative'),
          component: <ProjectSettingAdministrative />,
        },
      ]}
    />
  );
};

export default ProjectSettingsAcessAndAdmin;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useGetSelectedProjectQuery } from '../projects';
import EvaluationSetAPI from '@/api/evaluation_set_api';
import { DatasetVersionId } from '@clef/shared/types';
import { evaluationSetQueryKeys } from './queries';

export const useAddEvaluationSetMutation = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { datasetId, id: projectId } = useGetSelectedProjectQuery().data ?? {};
  return useMutation({
    mutationFn: async (params: {
      datasetVersionId: DatasetVersionId;
      splitId: number | null;
      hidden?: boolean;
      tagId?: number;
    }) => {
      if (!datasetId) return;
      if (!projectId) return;
      const { datasetVersionId, splitId, hidden, tagId } = params;
      const response = await EvaluationSetAPI.addEvaluationSet({
        projectId,
        datasetVersionId,
        splitId,
        hidden,
        tagId,
      });
      return response.data;
    },
    onSuccess: () => {
      enqueueSnackbar(t('Successfully add an evaluation set'), {
        variant: 'success',
      });
      projectId && queryClient.invalidateQueries(evaluationSetQueryKeys.list(projectId));
    },
    onError: (error: Error) => {
      enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 12000 });
    },
  });
};

export const useHideEvaluationSetMutation = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  return useMutation({
    mutationFn: async (params: { evaluationSetId: number }) => {
      const { evaluationSetId } = params;
      await EvaluationSetAPI.hideEvaluationSet({
        evaluationSetId,
      });
    },
    onSuccess: () => {
      enqueueSnackbar(t('Successfully archived an evaluation set'), {
        variant: 'success',
      });
      projectId && queryClient.invalidateQueries(evaluationSetQueryKeys.list(projectId));
    },
    onError: (error: Error) => {
      enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 12000 });
    },
  });
};

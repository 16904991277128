import { ApiResponseLoader, Button, Dropdown, usePrevious } from '@clef/client-library';
import { MenuItem, MenuList } from '@material-ui/core';
import Sort from '@material-ui/icons/Sort';
import React, { useEffect, useMemo } from 'react';
import { useDatasetSortableFieldInfoQuery } from '@/serverStore/dataset';
import {
  ConfidenceScoreAscSortField,
  ConfidenceScoreDescSortField,
  InstanceLabelTimeAscSortField,
  InstanceLabelTimeDescSortField,
  UploadTimeDescSortField,
  useDataBrowserState,
} from '../dataBrowserState';
import { useDataBrowserStyles } from '../styles';

const SortDropdown: React.FC = () => {
  const dataBrowserStyles = useDataBrowserStyles();
  const {
    state: { sortField, viewMode, showGroundTruth, showPredictions },
    dispatch,
  } = useDataBrowserState();
  const {
    data: sortableFields,
    isLoading: sortableFieldsLoading,
    error: sortableFieldsError,
  } = useDatasetSortableFieldInfoQuery();

  const sanitizedSortableFields = useMemo(() => {
    if (!sortableFields) {
      return undefined;
    }
    const newSortableFields = [...sortableFields].filter(field => {
      if (viewMode === 'instance') {
        // remove upload time for instance view
        if (field.label.includes('Upload Time')) return false;
        // remove media label time for instance view
        if (field.label.includes('Last Labeled Time')) return false;
      }
      return true;
    });
    if (viewMode === 'instance' && showPredictions) {
      // Add confidence score sorting for instance view (when prediction shown)
      // assumption: the last sort option is Random, we insert new options before Random option
      newSortableFields.splice(-1, 0, ConfidenceScoreAscSortField);
      newSortableFields.splice(-1, 0, ConfidenceScoreDescSortField);
    }
    if (viewMode === 'instance' && !showPredictions && showGroundTruth) {
      // Add instance label time sorting
      // assumption: the last sort option is Random, we insert new options before Random option
      newSortableFields.splice(-1, 0, InstanceLabelTimeDescSortField);
      newSortableFields.splice(-1, 0, InstanceLabelTimeAscSortField);
    }
    return newSortableFields;
  }, [showGroundTruth, showPredictions, sortableFields, viewMode]);

  // change default sorting when switching between image / instance view and toggling prediction labels
  const prevViewMode = usePrevious(viewMode);
  useEffect(() => {
    dispatch(draft => {
      // 1. Image view => default sort by upload time desc
      if (prevViewMode !== viewMode && viewMode === 'image') {
        draft.sortField = UploadTimeDescSortField;
      } else if (viewMode === 'instance') {
        // 2. Instance view, show prediction => default sort by score asc
        // 3. Instance view, hide prediction => default sort by label time desc
        draft.sortField = showPredictions
          ? ConfidenceScoreAscSortField
          : InstanceLabelTimeDescSortField;
      }
    });
  }, [dispatch, prevViewMode, showPredictions, viewMode]);

  return (
    <Dropdown
      extraGutter={{ vertical: 12 }}
      dropdown={() => (
        <ApiResponseLoader
          response={sanitizedSortableFields}
          loading={sortableFieldsLoading}
          error={sortableFieldsError}
          defaultHeight={'60px'}
          defaultWidth={'60px'}
        >
          {responseLoaded => (
            <MenuList aria-label="sortBy" data-testid="sort-by-select">
              {responseLoaded.map((field, index) => {
                const opt = field.orderLabel ? `${field.label}: ${field.orderLabel}` : field.label;
                return (
                  <MenuItem
                    data-testid={`dropdown-option-${opt}`.toLowerCase().replace(' ', '-')}
                    key={opt}
                    selected={
                      sortField.label === field.label && sortField.orderLabel === field.orderLabel
                    }
                    onClick={() => {
                      dispatch(draft => {
                        const selectedSortField = responseLoaded[index];
                        draft.sortField = { ...responseLoaded[index] };
                        if (selectedSortField.sortType === 'random') {
                          draft.sortField.sortValue = String(Math.random());
                        }
                      });
                    }}
                  >
                    {opt}
                  </MenuItem>
                );
              })}
            </MenuList>
          )}
        </ApiResponseLoader>
      )}
    >
      <Button
        id="open-sort-option-button"
        color="primary"
        variant="outlined"
        className={dataBrowserStyles.toolbarButton}
        startIcon={<Sort />}
      >
        {t('Sort')}
      </Button>
    </Dropdown>
  );
};

export default SortDropdown;

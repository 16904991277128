import { SegmentationAnnotation, BoxAnnotation, ClassifiedClass } from '@clef/client-library';
import {
  EdgeLabel,
  BoundingBoxAnnotationData,
  SegmentationAnnotationData,
  AnnotationType,
  Defect,
  EdgeMediaDetails,
  MediaStatusType,
  LabelReviewStatus,
  Label,
  MediaDetailsWithPrediction,
  AnnotationBase,
  Annotation,
  PNGAnnotationData,
  MediaId,
  AnnotationInstance,
  RangeBox,
} from '@clef/shared/types';
import { truncate } from 'lodash';
import { roundToMaxDecimals } from '@clef/shared/utils';
import { getDefectColor } from './defect_utils';
import produce from 'immer';
import {
  BitMapLabelingAnnotation,
  BoxLabelingAnnotation,
} from '../components/Labeling/labelingState';
import { FilterOptions } from '@/api/model_analysis_api';

export const getSegmentAnnotations = (
  annotations: Annotation[],
  defects: Defect[],
): SegmentationAnnotation[] => {
  return annotations
    .filter(annotation => annotation.annotationType === AnnotationType.segmentation)
    .map(annotation => {
      const { defectId, rangeBox, id, segmentationBitmapEncoded } = annotation;
      if (!rangeBox) {
        throw new Error('no rangeBox for segmentation annotation');
      }
      if (!segmentationBitmapEncoded) {
        throw new Error('no bitmap for segmentation annotation');
      }
      return {
        key: id,
        description: defects ? defects.find(_ => _.id === defectId)?.name : undefined,
        color: getDefectColor(defects?.find(_ => _.id === defectId)),
        compressedBitMap: segmentationBitmapEncoded,
        xMin: rangeBox.xmin,
        yMin: rangeBox.ymin,
        xMax: rangeBox.xmax,
        yMax: rangeBox.ymax,
      };
    });
};

export const getBoxAnnotations = (
  annotations: Annotation[],
  defects: Defect[],
  confidenceThreshold?: number,
): BoxAnnotation[] => {
  const isPrediction = typeof confidenceThreshold === 'number';
  return annotations
    .filter(annotation => annotation.annotationType === AnnotationType.bndbox)
    .filter(annotation => !isPrediction || (annotation.confidence ?? 0) >= confidenceThreshold)
    .map(annotation => {
      const { defectId, rangeBox, id, confidence } = annotation;
      if (!rangeBox) {
        throw new Error('no rangeBox for bounding box annotation');
      }
      return {
        key: id,
        description: defects ? defects.find(_ => _.id === defectId)?.name : undefined,
        color: getDefectColor(defects?.find(_ => _.id === defectId)),
        xMin: rangeBox.xmin,
        yMin: rangeBox.ymin,
        xMax: rangeBox.xmax,
        yMax: rangeBox.ymax,
        isPrediction,
        confidence,
      };
    });
};

export const getFilteredAnnotationPairs = (
  mediaId: MediaId,
  confidenceThreshold?: number,
  annotationInstances?: AnnotationInstance[],
  defects?: Defect[],
  filterOptions?: FilterOptions,
  showFilteredOnly?: boolean,
): BoxAnnotation[] | undefined => {
  if (!filterOptions || !defects || !annotationInstances || !confidenceThreshold) {
    return undefined;
  }

  const annotations: BoxAnnotation[] = [];
  annotationInstances.forEach(instance => {
    if (instance.mediaId !== mediaId) return;
    const { groundTruthAnnotation, predictionAnnotation } = instance;
    const gtHitFilter = (groundTruthAnnotation?.defectId ?? 0) === filterOptions.gtClassId;
    const predictionHitNoLabelFilter =
      filterOptions.predClassId === 0 &&
      (!predictionAnnotation || predictionAnnotation.confidence < confidenceThreshold);
    const predictionHitNonZeroFilter =
      filterOptions.predClassId !== 0 &&
      !!predictionAnnotation &&
      predictionAnnotation.defectId === filterOptions.predClassId &&
      predictionAnnotation.confidence >= confidenceThreshold;
    const hitFilter = gtHitFilter && (predictionHitNoLabelFilter || predictionHitNonZeroFilter);
    [groundTruthAnnotation, predictionAnnotation].forEach(
      (
        annotation: {
          id: number;
          defectId: number;
          rangeBox: RangeBox;
          confidence?: number;
        } | null,
      ) => {
        if (
          !annotation ||
          (typeof annotation.confidence === 'number' && annotation.confidence < confidenceThreshold)
        )
          return;
        const isPrediction = typeof annotation.confidence === 'number';
        const name = defects.find(_ => _.id === annotation.defectId)?.name;
        const description = hitFilter
          ? isPrediction && name
            ? `${truncate(name, { length: 15 })} ${annotation.confidence?.toFixed(2)}`
            : name
          : undefined;
        if (showFilteredOnly && !description) {
          return;
        }
        annotations.push({
          key: annotation.id,
          description,
          color: getDefectColor(defects.find(_ => _.id === annotation.defectId)),
          xMin: annotation.rangeBox.xmin,
          yMin: annotation.rangeBox.ymin,
          xMax: annotation.rangeBox.xmax,
          yMax: annotation.rangeBox.ymax,
          isPrediction: typeof annotation.confidence === 'number',
          confidence: annotation.confidence,
        });
      },
    );
  });
  return annotations.sort((a, b) => {
    // draw annotation with text later so it won't be covered by other bounding boxes
    if (a.description) return 1;
    else if (b.description) return -1;
    else return 0;
  });
};

export const getFilteredComparisonAnnotationPairs = (
  mediaId: MediaId,
  baselineAllInstances?: AnnotationInstance[],
  baselineConfidenceThreshold?: number,
  candidateAllInstances?: AnnotationInstance[],
  candidateConfidenceThreshold?: number,
  defects?: Defect[],
  filterOptions?: FilterOptions,
): [BoxAnnotation[] | undefined, BoxAnnotation[] | undefined] => {
  if (!filterOptions) {
    return [undefined, undefined];
  }
  if (
    !defects ||
    !baselineConfidenceThreshold ||
    !baselineAllInstances ||
    !candidateConfidenceThreshold ||
    !candidateAllInstances
  ) {
    return [[], []];
  }

  const baselineAnnotationInstances = baselineAllInstances.filter(
    instance => instance.mediaId === mediaId,
  );
  const candidateAnnotationInstances = candidateAllInstances.filter(
    instance => instance.mediaId === mediaId,
  );

  const pusher = (
    instances: AnnotationInstance[],
    boxAnnotations: BoxAnnotation[],
    isFindingMissingGt = false,
  ) => {
    instances.forEach(({ groundTruthAnnotation, predictionAnnotation }) => {
      const annoation = isFindingMissingGt ? groundTruthAnnotation : predictionAnnotation;
      const defect = defects.find(_ => _.id === annoation?.defectId);
      if (!defect || !annoation) {
        return;
      }
      const description = isFindingMissingGt
        ? t('Missed')
        : `${truncate(defect.name, { length: 15 })} ${predictionAnnotation?.confidence?.toFixed(
            2,
          )}`;
      boxAnnotations.push({
        key: annoation.id,
        defectId: defect?.id,
        description,
        isPrediction: true,
        color: isFindingMissingGt ? '#ffffff' : getDefectColor(defect),
        xMin: annoation.rangeBox.xmin,
        yMin: annoation.rangeBox.ymin,
        xMax: annoation.rangeBox.xmax,
        yMax: annoation.rangeBox.ymax,
        confidence: predictionAnnotation?.confidence,
      });
    });
  };

  const baselineAnnotations: BoxAnnotation[] = [];
  const candidateAnnotations: BoxAnnotation[] = [];

  if (filterOptions.gtClassId === 0 && filterOptions.predClassId !== 0) {
    // False positive, gt defect id -> 0
    const gtNoLabelPairs = baselineAnnotationInstances.filter(
      a =>
        !a.groundTruthAnnotation?.defectId &&
        a.predictionAnnotation?.defectId === filterOptions.predClassId &&
        a.predictionAnnotation.confidence >= baselineConfidenceThreshold,
    );
    const candidateNoLabelPairs = candidateAnnotationInstances.filter(
      a =>
        !a.groundTruthAnnotation?.defectId &&
        a.predictionAnnotation?.defectId === filterOptions.predClassId &&
        a.predictionAnnotation.confidence >= candidateConfidenceThreshold,
    );
    pusher(gtNoLabelPairs, baselineAnnotations);
    pusher(candidateNoLabelPairs, candidateAnnotations);
  } else if (filterOptions.gtClassId !== 0 && filterOptions.predClassId === 0) {
    // False Negative, pred defect id -> 0 || confidence < threshold
    const baselineNoPredictionPairs = baselineAnnotationInstances.filter(
      a =>
        (!a.predictionAnnotation?.defectId ||
          a.predictionAnnotation.confidence < baselineConfidenceThreshold) &&
        a.groundTruthAnnotation?.defectId === filterOptions.gtClassId,
    );
    const candidateNoPredictionPairs = candidateAnnotationInstances.filter(
      a =>
        (!a.predictionAnnotation?.defectId ||
          a.predictionAnnotation.confidence < candidateConfidenceThreshold) &&
        a.groundTruthAnnotation?.defectId === filterOptions.gtClassId,
    );
    pusher(baselineNoPredictionPairs, baselineAnnotations, true);
    pusher(candidateNoPredictionPairs, candidateAnnotations, true);
  } else if (filterOptions.gtClassId !== 0 && filterOptions.predClassId !== 0) {
    // Mis-Classification or correct mapping, gt defect id equal or not euqal to predication defect id and prediction confidence >= threshold
    const baselineMatchFilterPairs = baselineAnnotationInstances.filter(
      a =>
        a.groundTruthAnnotation?.defectId === filterOptions.gtClassId &&
        a.predictionAnnotation?.defectId === filterOptions.predClassId &&
        a.predictionAnnotation.confidence >= baselineConfidenceThreshold,
    );
    const candidateMatchFilterPairs = candidateAnnotationInstances.filter(
      a =>
        a.groundTruthAnnotation?.defectId === filterOptions.gtClassId &&
        a.predictionAnnotation?.defectId === filterOptions.predClassId &&
        a.predictionAnnotation.confidence >= candidateConfidenceThreshold,
    );
    // TODO: @Louie optimize this from 4 pass to 2 pass
    pusher(baselineMatchFilterPairs, baselineAnnotations);
    pusher(candidateMatchFilterPairs, candidateAnnotations);
  }

  return [baselineAnnotations, candidateAnnotations];
};

export const getClassifiedClass = (
  annotations: AnnotationBase[] | (BitMapLabelingAnnotation | BoxLabelingAnnotation)[],
  defects: Defect[],
): ClassifiedClass | undefined => {
  if (annotations.length && annotations[0].annotationType === AnnotationType.classification) {
    const defectClassId = annotations[0].defectId;
    const foundDefect = defects?.find(defect => defect.id === defectClassId)!;

    return foundDefect
      ? {
          id: foundDefect.id,
          name: foundDefect.name,
          color: getDefectColor(foundDefect),
        }
      : undefined;
  }
  return undefined;
};

const mapAnnotation = (
  label: EdgeLabel,
  type: AnnotationType,
  defects: Defect[] | undefined,
  showDefectName = true,
  showConfidenceScore = true,
) => {
  const {
    defect_id,
    annotation: { annotationData },
    confidence,
  } = label;
  const findDefect = defects?.find(defect => defect.id === defect_id);
  const captions: string[] = [];

  if (showDefectName) {
    captions.push(findDefect?.name ?? 'unknown_defect');
  }

  if (showConfidenceScore) {
    captions.push(roundToMaxDecimals(confidence, 2).toString());
  }

  const { xmin, ymin, xmax, ymax } =
    type === AnnotationType.bndbox
      ? (annotationData as BoundingBoxAnnotationData)
      : (annotationData as SegmentationAnnotationData).rangeBox;
  return {
    key: label.id,
    description: captions.join(' | '),
    color: getDefectColor(findDefect),
    xMin: xmin,
    yMin: ymin,
    xMax: xmax,
    yMax: ymax,
    compressedBitMap: (annotationData as SegmentationAnnotationData).bitMap || '',
  };
};

export const getEdgeBoxAnnotations = (
  edgeMediaDetails: EdgeMediaDetails | undefined,
  annotationType: AnnotationType,
  defects: Defect[] | undefined,
  showDefectName = true,
  showConfidenceScore = true,
) => {
  return edgeMediaDetails?.labels
    ?.filter(label => label.annotation_type === annotationType)
    .map(label =>
      mapAnnotation(
        label as EdgeLabel,
        annotationType,
        defects,
        showDefectName,
        showConfidenceScore,
      ),
    );
};

export const getJudgementAnnotation = (value: string) => {
  const judgement = value?.toLowerCase();
  if (judgement === 'ok' || judgement === 'accepted') {
    return 0;
  }
  if (judgement === 'ng' || judgement === 'rejected') {
    return 1;
  }
  return -1;
};

export const getAnnotationSrcData = (edgeMediaDetails: EdgeMediaDetails | undefined) => {
  const label: EdgeLabel | undefined = (edgeMediaDetails?.labels || []).find(label => {
    const annotationData = label?.annotation?.annotationData;
    return !!(annotationData && 'data' in annotationData);
  });

  return label && 'data' in label?.annotation?.annotationData
    ? (label.annotation.annotationData as PNGAnnotationData).data
    : undefined;
};

export const produceSetClassMediaDetails = (
  mediaDetails: MediaDetailsWithPrediction,
  { currentUserId, defectId }: { currentUserId: string; defectId: number },
) => {
  return produce(mediaDetails!, draft => {
    draft.mediaStatus = MediaStatusType.Approved;
    if (!draft.label) {
      draft.label = {
        id: Date.now(),
      } as Label;
    }
    draft.label.reviewStatus = LabelReviewStatus.Accepted;
    draft.label.labelerName = currentUserId;
    draft.label.annotations = [
      {
        dataSchemaVersion: 3,
        defectId: defectId,
        annotationType: AnnotationType.classification,
      },
    ];
  });
};

import { BaseAPI } from './base_api';
import ImageNotFound from '../images/broken_image.svg';
import {
  Media,
  MediaId,
  DefectId,
  ApiResponse,
  SelectMediaOption,
  MediaCreateSpec,
  UploadParameters,
} from '@clef/shared/types';

const MediaAPIurlPrefix = 'medias';

class MediaAPI extends BaseAPI {
  async getMediaById(id: MediaId): Promise<ApiResponse<Media>> {
    const response = await this.get('list', { mediaIds: String(id) });
    if (response.code === 404) {
      response.data.url = ImageNotFound;
      return response;
    }
    return { ...response, data: response.data[0] };
  }

  async getMediasByIds(ids: MediaId[]): Promise<ApiResponse<Media[]>> {
    return this.get('list', { mediaIds: ids.join() });
  }

  async createMedia(media: MediaCreateSpec): Promise<ApiResponse<Media>> {
    return this.postJSON('new', media);
  }

  async requestUploadSignature(data: object): Promise<ApiResponse<UploadParameters>> {
    return this.postJSON('sign', data);
  }

  async setClass(params: {
    datasetId: number;
    projectId: number;
    selectMediaOption: SelectMediaOption | MediaId[];
    defectId: DefectId;
  }): Promise<ApiResponse<{ mediaCount: number }>> {
    return this.postJSON('set_class', {
      ...params,
      selectMediaOption: Array.isArray(params.selectMediaOption)
        ? ({
            selectedMedia: params.selectMediaOption,
            unselectedMedia: [],
            isUnselectMode: false,
            fieldFilterMap: {},
            columnFilterMap: {},
          } as SelectMediaOption)
        : params.selectMediaOption,
    });
  }
}

export default new MediaAPI(MediaAPIurlPrefix);

import React, { useCallback } from 'react';
import { Dropdown, FilterPill } from '@clef/client-library';
import { useDeviceMonitorStyles } from '../deviceMonitorStyles';
import { Typography, Checkbox } from '@material-ui/core';
import { useDeviceMonitorState } from '../deviceMonitorState';
import { useDefectSelector } from '../../../../store/defectState/actions';

export interface DefectFilterProps {}

const DefectFilter: React.FC<DefectFilterProps> = () => {
  const styles = useDeviceMonitorStyles();
  const {
    state: { filterDefects, filterClass },
    dispatch,
  } = useDeviceMonitorState();
  const defects = useDefectSelector();
  const onFilterOptionChange = useCallback(
    (optionValue: number, classLabel: string) => () => {
      const newSelectedFilterOption = filterDefects.includes(optionValue)
        ? filterDefects.filter(option => option !== optionValue)
        : [...filterDefects, optionValue];
      dispatch(draft => {
        draft.filterDefects = newSelectedFilterOption;
      });

      const newSelectedClassFilterOption = filterClass.includes(classLabel)
        ? filterClass.filter(option => option !== classLabel)
        : [...filterClass, classLabel];
      dispatch(draft => {
        draft.filterClass = newSelectedClassFilterOption;
      });
    },
    [dispatch, filterDefects, filterClass],
  );

  return (
    <Dropdown
      extraGutter={{ vertical: 12 }}
      dropdown={
        <div className={styles.dropdownPanelList}>
          {defects?.map(defect => (
            <div
              key={defect.id}
              onClick={onFilterOptionChange(defect.id, defect.name)}
              className={styles.optionItem}
              data-testid="filter-dropdown-option"
            >
              <Checkbox
                color="primary"
                size="small"
                checked={filterDefects.includes(defect.id)}
                onChange={onFilterOptionChange(defect.id, defect.name)}
              />
              <Typography variant="body1" component="span">
                {defect.name}
              </Typography>
            </div>
          ))}
        </div>
      }
    >
      {open => (
        <FilterPill filterName={t('Class')} badgeContent={filterDefects.length} open={open} />
      )}
    </Dropdown>
  );
};

export default DefectFilter;

import { BaseAPI } from './base_api';
import { DefectBookOverview, DefectBookOverviewInfo } from '@clef/shared/types';

class DefectBookOverviewApi extends BaseAPI {
  async getData(projectId: number): Promise<DefectBookOverview> {
    return this.get('', /* params = */ { projectId }, /* dataOnly = */ true);
  }

  async updateData(projectId: number, defectBookInfo: DefectBookOverviewInfo): Promise<{}> {
    return this.postJSON('update', /* params = */ { projectId, defectBookInfo });
  }
}

export default new DefectBookOverviewApi('defect_book_overview');

import React from 'react';
import { SelectMediaOption, DatasetGroupOptions } from '@clef/shared/types';
import {
  ChartContainer,
  ApiResponseLoader,
  BarChart,
  BarChartHorizontal,
} from '@clef/client-library';
import { useGetDatasetStatsQuery } from '@/serverStore/dataset';
import { useDefectSelector } from '../../store/defectState/actions';
import { getDefectColor } from '../../utils';

export interface DefectBarChartProps {
  title?: string;
  selectMediaOptions: SelectMediaOption;
  barDirection?: 'vertical' | 'horizontal';
}

const LabelBarChart: React.FC<DefectBarChartProps> = ({
  title,
  selectMediaOptions,
  barDirection = 'vertical',
}) => {
  const defects = useDefectSelector();

  const {
    data: distrbutionStats,
    isLoading: distributionStatsLoading,
    error: distributionStatsError,
  } = useGetDatasetStatsQuery({
    selectOptions: selectMediaOptions,
    groupOptions: [DatasetGroupOptions.DEFECT_DISTRIBUTION],
  });

  const ChartComponent = (
    <ApiResponseLoader
      response={distrbutionStats}
      loading={distributionStatsLoading}
      error={distributionStatsError}
      defaultHeight={300}
    >
      {distrbutionStatsLoaded => {
        const defectStats: { [key: string]: number } = defects.reduce(
          (acc, defect) => ({
            ...acc,
            [defect.name]: distrbutionStatsLoaded.reduce((acc, stats) => {
              return acc + (stats?.defect_distribution?.[defect.id] ?? 0) * stats.count;
            }, 0),
          }),
          {},
        );
        const defectColor: { [key: string]: string } = defects.reduce(
          (acc, defect) => ({
            ...acc,
            [defect.name]: getDefectColor(defect),
          }),
          {},
        );
        const defectStatsFormatted = Object.entries(defectStats).map(([name, value]) => ({
          name,
          value,
          color: defectColor[name],
        }));

        return barDirection === 'vertical' ? (
          <BarChart chartData={defectStatsFormatted} />
        ) : (
          <BarChartHorizontal chartData={defectStatsFormatted} />
        );
      }}
    </ApiResponseLoader>
  );

  return title ? <ChartContainer title={title}>{ChartComponent}</ChartContainer> : ChartComponent;
};

export default LabelBarChart;

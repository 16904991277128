import { LinearProgress, withStyles } from '@material-ui/core';

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 8,
    borderRadius: 4,
    flex: 1,
    marginRight: theme.spacing(2),
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[200],
  },
  bar: {
    borderRadius: 4,
  },
  barColorPrimary: {
    backgroundColor: theme.palette.blue[600],
  },
  barColorSecondary: {
    backgroundColor: theme.palette.red[500],
  },
}))(LinearProgress);

export default BorderLinearProgress;

import React from 'react';
import { Box } from '@material-ui/core';
import UsageSection from './UsageSection';
import { last } from 'lodash';
import { addDays, format } from 'date-fns';
import { addMonths } from 'date-fns/esm';
import { useGetUsageSummary } from '@/serverStore/usage';

const Usage: React.FC = () => {
  const { data: usageSummary } = useGetUsageSummary();
  if (!usageSummary) {
    return null;
  }

  let { cycleStart, cycleEnd } = last(usageSummary.billingCycles) ?? {};
  if (!cycleStart) {
    cycleStart = format(addMonths(new Date(), -1), 'yyyy-MM-dd');
  } else {
    cycleStart = format(new Date(cycleStart), 'yyyy-MM-dd');
  }
  if (!cycleEnd) {
    cycleEnd = format(new Date(), 'yyyy-MM-dd');
  } else {
    cycleEnd = format(addDays(new Date(cycleEnd), -1), 'yyyy-MM-dd');
  }
  return (
    <Box>
      <UsageSection initCycleStart={cycleStart} initCycleEnd={cycleEnd} />
    </Box>
  );
};

export default Usage;

import {
  AnnotationType,
  BoundingBoxAnnotationData,
  ProjectId,
  SegmentationAnnotationData,
  PNGAnnotationData,
} from './basic';

import { ModelInfo } from './maglev';

export type CLApp = {
  creation_time: string;
  id: string;
  model_id?: string;
  name?: string;
  owner: string;
  ping_interval: string;
  project_id: number;
  updated_time?: string;
  status: string;
};

export type OrgDevice = {
  id: string;
  creation_time: string;
  name: string | null;
  owner: string;
  ping_interval: string;
  updated_time?: string;
  status: string;
  hostname: string;
  models: Array<{
    model_id: ModelInfo['id'];
    project_id: ProjectId;
    deployed_model_id: ModelInfo['id'];
    device_id: string;
  }>;
};

export type EdgeRecord = {
  id: string;
  uid: string;
  name: string;
  type: 'EdgeDevice' | 'App' | 'CloudInference';
  creationTime: string;
  updatedTime?: string;
  status: string;
  owner?: string;
  projectId: number;
  orgId?: number;
  modelId?: string;
  deployedModelId?: string;
  hostname?: string;
  pingInterval?: string;
  autoGeneratedId?: number;
};

export type SCAppRecord = {
  id?: string;
  app?: string;
  model?: string;
  description?: string;
  link?: string;
  bundleId?: string;
};

export type EdgeDevice = {
  device_id: string;
  project_id: number;
  env: string;
  creation_time: string;
  status?: string;
};

export type EdgeLabel = {
  id: number;
  org_id?: number;
  media_id: number;
  class_label: string;
  model_id: string;
  defect_id: number;
  confidence: number;
  annotation_type: AnnotationType;
  annotation: {
    annotationData: BoundingBoxAnnotationData | SegmentationAnnotationData | PNGAnnotationData;
  };
};

export type EdgeMedia = {
  id: number;
  org_id?: number;
  name?: string;
  width: number;
  height: number;
  device_id: string;
  path: string;
  project_id: number;
  upload_time: string;
  dm_media_id?: number;
  model_id?: string;
};

export type EdgeMediaFilterOption = {
  fieldId: string;
  resource: 'media' | 'label' | 'human_judgement' | 'meta_data';
  value: string[] | number[] | string;
  operator: 'BETWEEN' | 'CONTAINS_ANY' | 'LIKE';
};

export type EdgeMediaDetails = EdgeMedia & {
  url: string;
  labels: EdgeLabel[];
  status?: EdgeMediaStatusType;
  image_metadata?: EdgeImageMetaData;
  human_judgement?: HumanJudgementData;
};

export type HumanJudgementData = {
  inspection_time: string;
  inspector_id: string;
  judgement: string;
  mode: 'shadow' | 'production';
  comments: string;
};

export type EdgeImageMetaData = {
  capture_time: string;
  id: number;
  image_id: string;
  inspection_station_id: string;
  location_id: string;
};

export type EdgeMediaSortOption = {
  sortType: string;
  sortValue: string;
};

export enum EdgeMediaStatusType {
  Raw = 'raw',
  InSync = 'in_sync',
}

import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { ApiKeyStatus } from '@clef/shared/types';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  statusBadge: {
    padding: theme.spacing(0.25, 1),
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 700,
    backgroundColor: theme.palette.greyModern[200],
    color: theme.palette.greyModern[500],
    display: 'inline-block',
  },
  active: {
    backgroundColor: `rgba(52, 199, 89, 0.1);`,
    color: `#34C759`,
  },
}));

const statusTextMap: Record<ApiKeyStatus, string> = {
  [ApiKeyStatus.ACTIVE]: t('Active'),
  [ApiKeyStatus.REVOKED]: t('Revoked'),
};

export type StatusBadgeProps = {
  status?: ApiKeyStatus;
};

const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  const styles = useStyles();
  if (!status) {
    return null;
  }
  return (
    <Box
      className={classNames(styles.statusBadge, status === ApiKeyStatus.ACTIVE && styles.active)}
    >
      {statusTextMap[status] ?? ''}
    </Box>
  );
};

export default StatusBadge;

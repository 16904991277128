/**
 * This file is for testing only.
 * See zero_auth_first_label_experience.test.ts for usage.
 */
export class MockedEventSource {
  listeners = new Map<string, Set<EventListenerOrEventListenerObject>>();
  static instance: MockedEventSource | null = null;
  constructor() {
    if (!MockedEventSource.instance) {
      MockedEventSource.instance = this;
    }
    return MockedEventSource.instance;
  }
  addEventListener(
    type: string,
    callback: EventListenerOrEventListenerObject | null,
    _options?: boolean | AddEventListenerOptions | undefined,
  ): void {
    if (!callback) {
      return;
    }
    if (!this.listeners.has(type)) {
      this.listeners.set(type, new Set());
    }
    this.listeners.get(type)!.add(callback);
  }
  removeEventListener(
    type: string,
    callback: EventListenerOrEventListenerObject | null,
    _options?: boolean | EventListenerOptions | undefined,
  ): void {
    if (!callback) {
      return;
    }
    if (this.listeners.has(type)) {
      this.listeners.get(type)!.delete(callback);
    }
  }
  close() {}
  static sendMessage(action: string, payload?: any) {
    const { instance } = MockedEventSource;
    const type = 'message';
    if (instance && instance.listeners.has(type)) {
      instance.listeners.get(type)!.forEach(callback => {
        const data = JSON.stringify({ content: { action, payload } });
        const event = new MessageEvent(type, { data });
        // @ts-ignore
        callback(event);
      });
    }
  }
}

import React from 'react';
import { TextField } from '@material-ui/core';
import { useDeviceMonitorState } from '../deviceMonitorState';

export interface MediaIdFilterProps {}

const ImageIdFilter: React.FC<MediaIdFilterProps> = () => {
  const {
    state: { filterImageId },
    dispatch,
  } = useDeviceMonitorState();
  return (
    <TextField
      value={filterImageId}
      onChange={event => {
        const value = event.target.value.trim();
        dispatch(draft => {
          draft.filterImageId = value;
        });
      }}
    />
  );
};

export default ImageIdFilter;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Typography, Grid, Box, Link } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import { useSnackbar } from 'notistack';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import cx from 'classnames';

import { useStyles } from './newStyles';
import AuthAPI from '../../api/auth_api';
import { LandingLensLogo } from '../../components/Auth/Common';
import { EmailTextField } from '../../components/Auth/TextField';
import { PrimaryButton } from '../../components/Auth/Button';
import { CLEF_PATH } from '../../constants/path';
import { AuthPageLayout } from '../../components/Layout/AuthPageLayout';

interface FormValues {
  email: string;
}

export const SsoLogin: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const searchParams = useMemo(
    () => new URLSearchParams(history?.location?.search),
    [history?.location?.search],
  );
  const email = useMemo(() => searchParams.get('email'), [searchParams]);
  const { control, errors, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      email: email || '',
    },
  });

  const onSubmit = useCallback(
    async ({ email }: FormValues) => {
      setIsLoggingIn(true);

      try {
        const redirectUrl = await AuthAPI.samlLogin(email);
        window.open(redirectUrl, '_self', 'noopener,noreferrer');
      } catch (e) {
        enqueueSnackbar(e?.message, { variant: 'error', autoHideDuration: 12000 });
        setIsLoggingIn(false);
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    // If there is email in query params, auto sso login
    if (email) {
      onSubmit({ email });
    }
  }, [onSubmit, email]);

  return (
    <AuthPageLayout metaTitle={t('SSO Login')}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={styles.container}
      >
        <LandingLensLogo />

        <IconButton
          id="go-back"
          onClick={() => {
            history.goBack();
          }}
          className={styles.backButton}
        >
          <ArrowBackIcon />
        </IconButton>

        <Typography variant="h1" className={styles.helperText}>
          {t('Enter Your Work Email')}
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <EmailTextField
            error={errors?.email}
            control={control}
            className={styles.marginBottom8}
            standalongLabel={false}
          />

          <PrimaryButton
            id="sso-login"
            text={t('Continue with Enterprise SSO')}
            className={styles.marginBottom6}
            disabled={!!errors.email}
            isPending={isLoggingIn}
            useNewUi
          />

          <Link
            className={cx(styles.link, styles.newLink)}
            onClick={() => history.push(CLEF_PATH.login.main)}
          >
            {t('Return to Sign In')}
          </Link>
        </Box>
      </Grid>
    </AuthPageLayout>
  );
};

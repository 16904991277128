import { makeStyles } from '@material-ui/core';

export const useDataBrowserStyles = makeStyles(theme => ({
  textButton: {
    marginLeft: theme.spacing(2),
  },
  scrollStickyContainer: {
    height: 64,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  hintTextNormal: {
    color: theme.palette.grey[500],
  },
  hintTextError: {
    color: theme.palette.error.main,
  },
  hintTextContainer: {
    height: 500,
  },
  topFloatingButton: {
    position: 'absolute',

    marginBottom: theme.spacing(3),
    // To be displayed at the right corner
    right: 32,
    top: 92,
  },
  topBar: {
    marginBottom: theme.spacing(7),
    marginTop: -theme.spacing(7),
  },
  topActionButton: {
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(3),
    },
  },
  customizeTrainDropdownButton: {
    width: 8,
  },
  paginationTop: {
    marginBottom: theme.spacing(5),
  },
  paginationTopInDialog: {
    margin: theme.spacing(5),
  },
  paginationBottomUl: {
    marginTop: theme.spacing(5),
    justifyContent: 'flex-end',
  },
  mediaCountText: {
    whiteSpace: 'nowrap',
  },
  statsCardRoot: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(7),
    paddingLeft: theme.spacing(4),
    boxSizing: 'content-box',
    marginLeft: -theme.spacing(4),
    overflowX: 'auto',
    '& section + section': {
      marginLeft: theme.spacing(5),
    },
  },
  viewOptions: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  tooltipIcon: {
    color: theme.palette.grey[400],
    marginLeft: theme.spacing(2),
  },
  heatmapInfoIcon: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(2),
  },
  advancedTrainingLoading: {
    marginRight: theme.spacing(2),
  },
  trainGroupPopover: {
    marginTop: theme.spacing(2),
  },
  trainGroupMenuList: {
    border: `1px solid ${theme.palette.greyModern[300]}`,
    borderRadius: theme.spacing(2),
    color: theme.palette.blue[900],
    padding: 0,
  },
  trainMenuItem: {
    padding: theme.spacing(3, 4),

    '&:first-child': {
      borderBottom: `1px solid ${theme.palette.greyModern[300]}`,
    },
  },
  trainMenuItemTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    color: theme.palette.blue[900],

    '& p': {
      color: theme.palette.blue[900],
    },
  },
}));

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { IconButton } from '../../../..';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { TextField } from '@material-ui/core';
import cx from 'classnames';

import { isDark } from '@clef/shared/utils';
import { hexToRgba } from '@clef/shared/utils';
import PulseWrapper from '../../PulseWrapper';

const SPACING = 4;
const BORDER_WIDTH = 2;

const getBoundedValue = (num: number, min: number, max: number) => {
  return Math.max(min, Math.min(max, num));
};

/**
 * null: initial mode, no creator elements.
 * label-without-defect: label without defect. when finish label, go to create-defect step.
 * create-defect: open an input to enter class name.
 */
export type Mode = 'label-without-defect' | 'create-defect' | null;

type Position = {
  x: number | undefined;
  y: number | undefined;
};

const SIZE_CONFIG: Record<
  Exclude<Mode, null>,
  Partial<{
    width: number | string;
    height: number | string;
    minHeight?: number | string;
  }>
> = {
  'create-defect': {
    width: 168,
    height: 32,
  },
  'label-without-defect': {
    width: 'auto',
    height: 'auto',
    minHeight: 32,
  },
};

const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    borderRadius: '13px 13px 13px 0',
    zIndex: 1000,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 3),
    width: '100%',
    height: '100%',
  },
  disablePointer: {
    pointerEvents: 'none',
  },
  clickAwayListener: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
  },
}));

export type DefectCreatorProps = {
  color: string;
  defectName?: string;
  onCreateDefectConfirm?: (defectName: string, defectColor: string) => void | Promise<void>;
  onEscapePressed?: () => void | Promise<void>;
  onClickAway?: () => void | Promise<void>;
  creatorTips?: string;
};

export type DefectCreatorRef = {
  mode: Mode;
  text: string;
  setText: (text: string) => void;
  setPosition: (position: Position) => void;
  setPositionLimit: (props: { xmin: number; ymin: number; xmax: number; ymax: number }) => void;
  setMode: (mode: Mode) => void;
  setShow: (show: boolean) => void;
  setIsLoading: (loading: boolean) => void;
};

export const DefectCreator = forwardRef<DefectCreatorRef, DefectCreatorProps>((props, ref) => {
  const {
    onCreateDefectConfirm,
    onEscapePressed,
    onClickAway,
    creatorTips = t('Draw a box around the object you want to label'),
    color,
    defectName,
  } = props;

  const styles = useStyles();
  const [mode, setMode] = useState<Mode>(null);
  const [show, setShow] = useState(true);
  const [position, setPosition] = useState<Position>({
    x: undefined,
    y: undefined,
  });
  const [positionLimit, setPositionLimit] = useState({
    xmin: 0,
    xmax: 0,
    ymin: 0,
    ymax: 0,
  });
  const [text, setText] = useState(defectName ?? '');
  const [isLoading, setIsLoading] = useState(false);

  const iconButtonRef = useRef<HTMLButtonElement | null>(null);
  const inputBoxContainerRef = useRef<HTMLDivElement | null>(null);
  const [, forceUpdate] = useState(false);

  useEffect(() => {
    if (color) {
      // When switching color, continue to label without defect
      setMode('label-without-defect');
    } else {
      setMode(null);
    }
  }, [color]);

  useEffect(() => {
    defectName && setText(defectName);
  }, [defectName]);

  const loadRef = useCallback((ref: HTMLDivElement) => {
    if (ref) {
      inputBoxContainerRef.current = ref;
      forceUpdate(value => !value);
    }
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      mode,
      text,
      setText,
      setPosition,
      setPositionLimit,
      setMode,
      setShow,
      setIsLoading,
    }),
    [mode, text],
  );

  const textColor = useMemo(() => (isDark(color) ? 'white' : 'black'), [color]);

  const internalOnCreateDefectConfirm = useCallback(async () => {
    setIsLoading(true);
    await onCreateDefectConfirm?.(text, color);
    setIsLoading(false);
  }, [color, onCreateDefectConfirm, text]);

  // useKeyPress does not work for this case
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        iconButtonRef.current?.click();
      } else if (e.key === 'Escape') {
        onEscapePressed?.();
      }
    };

    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [onEscapePressed]);

  const selfHeight = inputBoxContainerRef.current?.clientHeight ?? 32;
  const offset = useMemo(() => {
    return {
      // Put the component to the right of cursor position with distance "SPACING" on x-axis
      x: SPACING,
      // Put the component to the top of the cursor position with no distance on y-axis
      // Note, we need to compensate for the borders of the component
      y: -selfHeight - BORDER_WIDTH * 2,
    };
  }, [selfHeight]);

  // set ref to null on unmount
  useEffect(
    () => () => {
      inputBoxContainerRef.current = null;
    },
    [ref],
  );

  if (mode === null || !show || position.x === undefined || position.y === undefined) {
    return null;
  }

  return (
    <div
      ref={loadRef}
      className={cx(styles.container, mode === 'label-without-defect' && styles.disablePointer)}
      style={{
        ...SIZE_CONFIG[mode],
        left: getBoundedValue(position.x, positionLimit.xmin, positionLimit.xmax) + offset.x,
        top: getBoundedValue(position.y, positionLimit.ymin, positionLimit.ymax) + offset.y,
        background: hexToRgba(color, 0.8),
        border: `${BORDER_WIDTH}px solid ${color}`,
      }}
    >
      <PulseWrapper pulseSize={12} pulseColor={color} enabled={mode === 'create-defect'}>
        <div className={styles.wrapper}>
          {mode === 'create-defect' && (
            <>
              {onClickAway && <div className={styles.clickAwayListener} onClick={onClickAway} />}

              <TextField
                value={text}
                placeholder="Name Your Class"
                onChange={e => {
                  setText(e.target.value);
                }}
                inputProps={{
                  style: {
                    color: textColor,
                  },
                  'data-testid': 'create-classes-input-bbox',
                }}
                InputProps={{ disableUnderline: true }}
                autoFocus
                onFocus={e => {
                  // auto select all on focus
                  e.target.select();
                }}
              />

              <IconButton
                ref={iconButtonRef}
                size="small"
                onClick={internalOnCreateDefectConfirm}
                disabled={!text}
                id="create-classes-bbox-confirm-btn"
              >
                {isLoading ? (
                  <CircularProgress size={20} style={{ color: textColor }} />
                ) : (
                  <KeyboardReturnIcon
                    fontSize="small"
                    style={{ color: textColor, opacity: text ? 1 : 0.3 }}
                  />
                )}
              </IconButton>
            </>
          )}

          {mode === 'label-without-defect' && (
            <Typography
              style={{
                color: textColor,
              }}
            >
              {creatorTips}
            </Typography>
          )}
        </div>
      </PulseWrapper>
    </div>
  );
});

export default DefectCreator;

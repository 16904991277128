import React from 'react';
import { Box } from '@material-ui/core';
import { ApiResponseLoader } from '@clef/client-library';
import { useNewUploadStyles } from '../Dialogs/UploadFullscreen/styles';
import { useAppDispatch } from '@/store';
import { useGetProjectTagsQuery } from '@/serverStore/tags';
import { addTags } from '@/store/uploadState';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { TagAutocomplete } from '../TagAutocomplete';

export const UploadAddTagButton: React.FC<{
  disabled?: boolean;
  handleDisableEscapeKeyDown?: (disableEscapeKeyDown: boolean) => void;
}> = ({ disabled, handleDisableEscapeKeyDown }) => {
  const styles = useNewUploadStyles();
  const { data, isFetching, error } = useGetProjectTagsQuery();

  const dispatch = useAppDispatch();
  const { tags = [] } = useTypedSelector(state => state.uploadState);

  return (
    <Box className={styles.additionalInfoChips}>
      <Box className={styles.additionalInfoChipsTitle}>{t('Tag: ')}</Box>
      <ApiResponseLoader response={data} loading={isFetching} error={error}>
        {response => (
          <TagAutocomplete
            tags={tags}
            tagOptions={response.filter(tag => !tag.isArchived).map(tag => tag.name)}
            disabled={disabled}
            onChangeTags={tags => dispatch(addTags(tags))}
            handleDisableEscapeKeyDown={handleDisableEscapeKeyDown}
          />
        )}
      </ApiResponseLoader>
    </Box>
  );
};

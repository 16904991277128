import React, { useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import BottomLeft from './icons/runningIcons/BottomLeft';
import BottomRight from './icons/runningIcons/BottomRight';
import TopLeft from './icons/runningIcons/TopLeft';
import TopRight from './icons/runningIcons/TopRight';
import CenterCube from './icons/runningIcons/CenterCube';

const useStyles = makeStyles(() => ({
  runningMask: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.25)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    color: 'white',
    fontSize: 24,
    fontWeight: 500,
    visibility: 'hidden',
    opacity: 0,
    transition: '0.2s',
    '&.show': {
      visibility: 'visible',
      opacity: 1,
      backdropFilter: 'blur(3px)',
    },
    '&.blur': {
      backdropFilter: 'blur(30px)',
      animation: '$maskBlur 6s 0.2s infinite ease',
    },
    '& svg': {
      display: 'block',
    },
  },
  // running icon styles
  runningIcon: {
    width: 55,
    height: 55,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topLeft: {
    position: 'absolute',
    left: 0,
    top: 0,
    animation: '$topleft ease 2s infinite',
  },
  topRight: {
    position: 'absolute',
    right: 0,
    top: 0,
    animation: '$topright ease 2s infinite',
  },
  bottomLeft: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    animation: '$bottomleft ease 2s infinite',
  },
  bottomRight: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    animation: '$bottomright ease 2s infinite',
  },
  '@keyframes maskBlur': {
    '0%, 100%': {
      backdropFilter: 'blur(10px)',
    },
    '50%': {
      backdropFilter: 'blur(30px)',
    },
  },
  '@keyframes topleft': {
    '50%': {
      top: -5,
      left: -5,
    },
  },
  '@keyframes topright': {
    '50%': {
      top: -5,
      right: -5,
    },
  },
  '@keyframes bottomleft': {
    '50%': {
      bottom: -5,
      left: -5,
    },
  },
  '@keyframes bottomright': {
    '50%': {
      bottom: -5,
      right: -5,
    },
  },
  // end of running icon styles
  // prompt text styles
  text: {
    color: 'white',
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Commissioner',
    opacity: 0,
    transition: '0.5s',
    '&.show': {
      opacity: 1,
    },
  },
}));

export const defaultTexts = [
  t('Taking inputs…'),
  t('Processing…'),
  t('Building your precise Model…'),
  t('This might take a few seconds…'),
];

export const loopTexts = defaultTexts.slice(-2);

const useTextState = (enable?: boolean, texts: string[] = defaultTexts) => {
  const [textIndex, setTextIndex] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!enable || texts.length === 1) {
      setShow(true);
      setTextIndex(0);
      return;
    }

    /**
     * The sequence is like this:
     * - show text1
     * - hide text1
     * - show text2
     * - hide text2
     * - ...
     *
     * And finally loop between the last two texts
     */
    const nextTask = () => {
      if (show) {
        setShow(false);
      } else {
        setShow(true);
        setTextIndex(prev => (prev === texts.length - 1 ? prev - 1 : prev + 1));
      }
    };

    const timerId = setTimeout(nextTask, show ? 3000 : 300);

    return () => {
      clearTimeout(timerId);
    };
  }, [textIndex, show, enable, texts.length]);

  const text = texts[textIndex];
  return [text, show];
};

export type RunningMaskProps = {
  show?: boolean;
  blur?: boolean;
  texts?: string[];
  icon?: React.ReactNode;
};

const RunningMask: React.FC<RunningMaskProps> = props => {
  const { show = false, blur = false, texts, icon } = props;
  const styles = useStyles();
  const [text, showText] = useTextState(show, texts);
  return (
    <Box className={classNames(styles.runningMask, show && 'show', blur && 'blur')}>
      <Box className={styles.runningIcon}>
        {icon ?? (
          <>
            <div className={styles.topLeft}>
              <TopLeft />
            </div>
            <div className={styles.topRight}>
              <TopRight />
            </div>
            <div className={styles.bottomLeft}>
              <BottomLeft />
            </div>
            <div className={styles.bottomRight}>
              <BottomRight />
            </div>
            <CenterCube />
          </>
        )}
      </Box>
      <Box marginTop={7} className={classNames(styles.text, showText && 'show')}>
        {text}
      </Box>
    </Box>
  );
};

export default RunningMask;

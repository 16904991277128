import React from 'react';
import { DialogContent, DialogActions, makeStyles } from '@material-ui/core';
import { Button } from '@clef/client-library';

export interface StepContainerProps {
  primaryButton?: {
    text: string;
    action: () => void;
    disabled?: boolean;
  };
  secondaryButton?: {
    text: string;
    action: () => void;
    disabled?: boolean;
  };
}

const useStyles = makeStyles(theme => ({
  dialogActionButtons: {
    padding: theme.spacing(0, 6),
  },
}));

const StepContainer: React.FC<StepContainerProps> = ({
  children,
  primaryButton,
  secondaryButton,
}) => {
  const styles = useStyles();
  return (
    <>
      <DialogContent>{children}</DialogContent>
      <DialogActions className={styles.dialogActionButtons}>
        {secondaryButton && (
          <Button
            id="step-container-secondary-button"
            disabled={secondaryButton.disabled}
            onClick={() => secondaryButton.action()}
          >
            {secondaryButton.text}
          </Button>
        )}
        {primaryButton && (
          <Button
            id="step-container-primary-button"
            color="primary"
            variant="contained"
            disabled={primaryButton.disabled}
            onClick={() => primaryButton.action()}
          >
            {primaryButton.text}
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default StepContainer;

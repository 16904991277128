import { useCallback } from 'react';
import { useParams } from 'react-router';
import { useAtom } from 'jotai';
import { cloneDeep, omit } from 'lodash';

import {
  MediaLevelLabel,
  MediaDetailsWithPrediction,
  Defect,
  AnnotationInstance,
  LabelSource,
} from '@clef/shared/types';

import { useGetSelectedProjectQuery } from '@/serverStore/projects';

import { useUpsertLabels } from '@/serverStore/label';
import {
  getAnnotationTypeByLabelType,
  convertToServerAnnotation,
} from '@/components/Labeling/utils';
import {
  BoxLabelingAnnotation,
  BitMapLabelingAnnotation,
  ClassificationLabelingAnnotation,
  PureCanvasLabelingAnnotation,
  ToolMode,
} from '@/components/Labeling/labelingState';

import { createAtom, createWritableAtom } from '../utils';

export enum RightDrawerType {
  // Labels = 'Labels',
  Information = 'Information',
  HotKeys = 'HotKeys',
}

export enum LabelUpdateState {
  Unchanged = 'Unchanged',
  Updating = 'Updating',
  Updated = 'Updated',
}

export type DatasetSearchParamsType = {
  sortOptions: any;
  columnFilterMap: any;
  metadataFilterMap: any;
};

export const defaultDatasetSearchParams: DatasetSearchParamsType = {
  sortOptions: {
    label: 'Upload Time',
    sortType: 'media',
    sortValue: 'uploadTime',
    sortOrder: 'desc',
    orderLabel: '(Newest to Oldest)',
    offset: 0,
    limit: 50,
  },
  columnFilterMap: {},
  metadataFilterMap: {},
};

export type MediaStates = {
  annotations:
    | BoxLabelingAnnotation[]
    | BitMapLabelingAnnotation[]
    | ClassificationLabelingAnnotation[]
    | PureCanvasLabelingAnnotation[];
  predictionAnnotations?:
    | BoxLabelingAnnotation[]
    | BitMapLabelingAnnotation[]
    | ClassificationLabelingAnnotation[]
    | PureCanvasLabelingAnnotation[];
  mediaLevelLabel?: MediaLevelLabel | null;
  predictionMediaLevelLabel?: MediaLevelLabel | null;
  mediaDetails?: MediaDetailsWithPrediction;
};

export const defaultMediaStates: MediaStates = {
  annotations: [],
};

export const rightDrawerTypeAtom = createAtom<RightDrawerType | null>(
  'rightDrawerType',
  RightDrawerType.Information,
);

export const datasetSearchParamsAtom = createAtom<DatasetSearchParamsType>(
  'datasetSearchParams',
  defaultDatasetSearchParams,
);

export const isLabelModeAtom = createAtom('isLabelMode', false);

// to remember current toolmode for labeling (labeling context would reset when switch image)
// null: initial state, undefined: toolMode in span mode
export const currentToolModeAtom = createAtom<ToolMode | null | undefined>('currentToolMode', null);

export type CurrentToolState = {
  strokeWidth?: number;
  erasing?: boolean;
  eraserWidth?: number;
};

// to remember current tool state
export const currentToolStateAtom = createAtom<CurrentToolState>('currentToolState', {});

// to remember selected defect
export const selectedDefectAtom = createAtom<Defect | undefined>('selectedDefect', undefined);

export const annotationInstanceAtom = createAtom<AnnotationInstance | null>(
  'annotationInstance',
  null,
);

export const initialMediaStatesAtom = createAtom<MediaStates>(
  'initialMediaStates',
  defaultMediaStates,
);

export const mediaStatesMapAtom = createAtom<{ [mediaId: number]: MediaStates }>(
  'mediaStatesMap',
  {},
);

export const labelUpdateStateAtom = createAtom<LabelUpdateState>(
  'labelUpdateStateAtom',
  LabelUpdateState.Unchanged,
);

export const toggleOnModelAssistLabelingAtom = createAtom<boolean>(
  'toggleOnModelAssistLabeling',
  false,
);

export const useCurrentMediaStates = () => {
  const { mediaId } = useParams<{ mediaId: string }>();
  const currentMediaId = parseInt(mediaId);
  const [mediaStatesMap] = useAtom(mediaStatesMapAtom);
  const [initialMediaStates] = useAtom(initialMediaStatesAtom);
  return !!currentMediaId && mediaStatesMap[currentMediaId]
    ? {
        ...initialMediaStates,
        annotations: mediaStatesMap[currentMediaId].annotations,
        mediaLevelLabel: mediaStatesMap[currentMediaId].mediaLevelLabel,
      }
    : initialMediaStates;
};

export const resetMediaDetailsAtomsAtom = createWritableAtom(
  'resetMediaDetailsAtoms',
  null,
  (get, set) => {
    set(rightDrawerTypeAtom, RightDrawerType.Information);
    set(datasetSearchParamsAtom, defaultDatasetSearchParams);
    set(isLabelModeAtom, false);
    set(annotationInstanceAtom, null);
    set(initialMediaStatesAtom, defaultMediaStates);
    set(mediaStatesMapAtom, {});
    set(labelUpdateStateAtom, LabelUpdateState.Unchanged);
    set(currentToolModeAtom, null);
    set(currentToolStateAtom, {});
    set(selectedDefectAtom, undefined);
    set(toggleOnModelAssistLabelingAtom, false);
  },
);

export const useSaveAnnotations = () => {
  const { id: projectId, datasetId, labelType } = useGetSelectedProjectQuery().data ?? {};
  const [mediaStatesMap, setMediaStatesMap] = useAtom(mediaStatesMapAtom);
  const [toggleOnModelAssistLabeling] = useAtom(toggleOnModelAssistLabelingAtom);
  const upsesrtLabels = useUpsertLabels();

  const annotationType = getAnnotationTypeByLabelType(labelType);

  const saveAnnotationsForMedia = useCallback(
    async (mediaStates: MediaStates): Promise<number | undefined> => {
      if (!datasetId || !projectId) {
        return undefined;
      }
      const { annotations, mediaDetails, mediaLevelLabel } = mediaStates;

      const serverAnnotations = annotations.map(ann =>
        convertToServerAnnotation(ann, annotationType!),
      );

      await upsesrtLabels.mutateAsync({
        projectId,
        annotations: serverAnnotations,
        mediaId: mediaDetails!.id,
        mediaLevelLabel: mediaLevelLabel || undefined,
        source: toggleOnModelAssistLabeling ? LabelSource.LabelAssist : LabelSource.DirectLabeling,
        mediaName: mediaDetails!.name,
      });
      return mediaDetails!.id;
    },
    [annotationType, datasetId, projectId, toggleOnModelAssistLabeling, upsesrtLabels],
  );

  return useCallback(async () => {
    if (!datasetId || !projectId) {
      return;
    }

    const saveQueue = { ...mediaStatesMap };

    let newMediaStatesById: { [mediaId: number]: MediaStates } = cloneDeep(saveQueue);

    newMediaStatesById = Object.entries(newMediaStatesById).reduce((lookup, [mediaId, states]) => {
      // Filter out annotations without defect id in case user does not complete the whole quick create defect flow
      const newAnnotations = states.annotations.filter(annotation => !!annotation.defectId);
      lookup[parseInt(mediaId)] = {
        ...states,
        annotations: newAnnotations,
      };
      return lookup;
    }, {} as { [mediaId: number]: MediaStates });
    Object.values(newMediaStatesById).forEach(mediaStates => {
      saveQueue[mediaStates.mediaDetails!.id] = mediaStates;
    });

    const succeedUpdates = await Promise.all(
      Object.values(saveQueue).map(mediaStates => saveAnnotationsForMedia(mediaStates)),
    );
    succeedUpdates.forEach(mediaId => {
      if (mediaId) {
        const newMediaStatesMap = omit(mediaStatesMap, mediaId);
        setMediaStatesMap(newMediaStatesMap);
      }
    });
  }, [datasetId, mediaStatesMap, projectId, saveAnnotationsForMedia, setMediaStatesMap]);
};

import { ApiErrorType } from '@/api/base_api';
import { ProjectId } from '@clef/shared/types';
import { useQuery } from '@tanstack/react-query';
import { useGetSelectedProjectQuery } from '../projects';
import LabelAPI, { SuggestLabel } from '@/api/label_api';
import { useQueryClient } from '@tanstack/react-query';
import { datasetQueryKeys } from '@/serverStore/dataset';

export const labelQueryKeys = {
  all: ['Label'] as const,
  modelAssistSuggestions: (projectId: ProjectId, mediaId?: number, modelId?: string) =>
    [projectId, mediaId, modelId, 'suggestions'] as const,
};

export const useFetchModelAssistSuggestionsQuery = (
  mediaId?: number,
  modelId?: string,
  enabled = true,
) => {
  const { id: projectId = 0, datasetId } = useGetSelectedProjectQuery().data ?? {};
  const queryClient = useQueryClient();

  return useQuery<SuggestLabel[], ApiErrorType>({
    queryKey: labelQueryKeys.modelAssistSuggestions(projectId, mediaId, modelId),
    queryFn: async () =>
      LabelAPI.fetchModelAssistSuggestions({
        projectId,
        mediaId,
        modelId,
      }),
    onSuccess: () => {
      datasetId &&
        queryClient.invalidateQueries(
          datasetQueryKeys.mediaDetails(datasetId, {
            mediaId: mediaId,
            modelId: modelId,
          }),
        );
    },
    enabled: enabled && !!projectId && !!mediaId && !!modelId,
  });
};

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MetadataView from './Metadata/MetadataView';
import { useMetadataApi } from '../../../hooks/api/useMetadataApi';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import AddMetadata from './Metadata/AddMetadata';
import { ApiResponseLoader } from '@clef/client-library';
import { MetadataFormattedValue } from '@clef/shared/types/store';
import { MetadataObjectValue } from '@clef/shared/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  soloSaveChips: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  addMetadataBtn: {
    marginTop: theme.spacing(2),
  },
}));

interface MediaMetadataPanelProps {
  mediaId: number;
  showAddMetadataButton?: boolean;
  mediaMetadata: MetadataFormattedValue;
}

const MediaMetadataPanel: React.FC<MediaMetadataPanelProps> = ({
  mediaId,
  showAddMetadataButton = true,
  mediaMetadata,
}) => {
  const classes = useStyles();

  const { data: selectedProject } = useGetSelectedProjectQuery();
  const [metadataMap, metadataMapLoading, metadataMapError] = useMetadataApi(
    selectedProject ? selectedProject.id : undefined,
  );

  return (
    <>
      <ApiResponseLoader
        response={mediaMetadata && metadataMap ? { mediaMetadata, metadataMap } : undefined}
        loading={metadataMapLoading}
        error={metadataMapError}
        defaultHeight={100}
      >
        {({ metadataMap: metadataMapLoaded, mediaMetadata: mediaMetadataLoaded }) => {
          const appliedMetadataIds = Object.keys(metadataMapLoaded!).filter(
            metadataId => mediaMetadataLoaded[metadataId] !== undefined,
          );

          return (
            <>
              {appliedMetadataIds.map(metadataId => {
                const metadata = metadataMapLoaded![metadataId];
                const metadataObjectValue = metadataMapLoaded[metadataId]
                  ? mediaMetadataLoaded[metadataId]
                  : [];
                return (
                  <div
                    key={metadataId}
                    className={classes.root}
                    data-testid="metadata-options-list"
                  >
                    <MetadataView
                      metadata={metadata}
                      value={metadataObjectValue! as MetadataObjectValue}
                      mediaId={mediaId}
                    />
                  </div>
                );
              })}
              {showAddMetadataButton && (
                <div className={classes.addMetadataBtn}>
                  <AddMetadata
                    mediaId={mediaId}
                    appliedMetadataIds={appliedMetadataIds.map(_ => Number(_))}
                    mediaMetadata={mediaMetadata}
                  />
                </div>
              )}
            </>
          );
        }}
      </ApiResponseLoader>
    </>
  );
};

export default MediaMetadataPanel;

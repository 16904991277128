import { useCurrentSubscription } from '@/hooks/useSubscriptions';
import { SubscriptionName } from '@clef/shared/types';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { Updater, useImmer } from 'use-immer';

export type PlanPageState = {
  plan: SubscriptionName | null;
  creditOptionIndex: number;
  paymentInterval: 'monthly' | 'annually';
};

export const initialState: PlanPageState = {
  plan: null,
  creditOptionIndex: 0,
  paymentInterval: 'annually',
};

export const PlanPageContext = createContext<{
  state: PlanPageState;
  dispatch: Updater<PlanPageState>;
}>({ state: initialState, dispatch: () => {} });

export const usePlanPageStateContext = () => useContext(PlanPageContext);

export interface PlanPageStateContextValue {
  state: PlanPageState;
  dispatch: Updater<PlanPageState>;
}

export const usePlanPageState = (): PlanPageStateContextValue => {
  const [state, dispatch] = useImmer<PlanPageState>(initialState);
  const subscription = useCurrentSubscription();

  useEffect(() => {
    if (subscription?.meta && !state.plan) {
      const { meta, stripeRecurringInterval } = subscription;
      const { plan, planTier } = meta;
      dispatch(draft => {
        if (plan === SubscriptionName.Freemium) {
          draft.plan = SubscriptionName.Starter;
          draft.creditOptionIndex = 0;
          draft.paymentInterval = 'annually';
        } else {
          draft.plan = subscription.meta!.plan as SubscriptionName;
          draft.creditOptionIndex = Number(planTier) - 1;
          draft.paymentInterval = stripeRecurringInterval === 'month' ? 'monthly' : 'annually';
        }
      });
    }
  }, [subscription, state, dispatch]);

  return useMemo<PlanPageStateContextValue>(() => ({ state, dispatch }), [state]);
};

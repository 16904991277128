/**
 * The parsed json should be number keys and string values
 */
export function isSegDefectMapValid(defectMap: Record<number, string>) {
  try {
    return Object.entries(defectMap).every(([key, value]) => {
      return key.match(/^\d+$/) && typeof value === 'string';
    });
  } catch (e) {
    return false;
  }
}

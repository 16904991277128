import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { GridTypeMap } from '@material-ui/core/Grid';

const useStyles = makeStyles(_ => ({
  leftGridItem: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  centerGridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  righttGridItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export type GridProps = GridTypeMap['props'];

export interface RowProps {
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
  leftGridCompoonentProps?: GridProps;
  centerGridCompoonentProps?: GridProps;
  rightGridCompoonentProps?: GridProps;
  className?: string;
}

const Row: React.FC<RowProps> = ({
  left,
  center,
  right,
  leftGridCompoonentProps,
  centerGridCompoonentProps,
  rightGridCompoonentProps,
  className,
}) => {
  const styles = useStyles();

  return (
    <Grid container className={className}>
      <Grid item xs={4} className={styles.leftGridItem} {...leftGridCompoonentProps}>
        {left}
      </Grid>
      <Grid item xs={4} className={styles.centerGridItem} {...centerGridCompoonentProps}>
        {center}
      </Grid>
      <Grid item xs={4} className={styles.righttGridItem} {...rightGridCompoonentProps}>
        {right}
      </Grid>
    </Grid>
  );
};

export default Row;

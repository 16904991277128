import { BoxAnnotation } from '@clef/client-library';
import {
  BoundingBoxPrediction,
  ClassificationPrediction,
  Defect,
  InferenceExecutorResult,
  SegmentationPrediction,
} from '@clef/shared/types';
import { getDefectColor } from '../../utils';

export type PredictionLabel = BoundingBoxPrediction['label'];

export const isClassificationPrediction = (
  predictionResult: InferenceExecutorResult | undefined,
): predictionResult is ClassificationPrediction =>
  typeof (predictionResult as ClassificationPrediction)?.label?.defectId === 'number';

export const isSegmentationPrediction = (
  predictionResult: InferenceExecutorResult | undefined,
): predictionResult is SegmentationPrediction =>
  typeof (predictionResult as SegmentationPrediction)?.segS3Path === 'string';

export const isBoundingBoxPrediction = (
  predictionResult: InferenceExecutorResult | undefined,
): predictionResult is BoundingBoxPrediction =>
  Array.isArray((predictionResult as BoundingBoxPrediction)?.label) &&
  !isSegmentationPrediction(predictionResult);

export const getBoxAnnotations = (
  annotations: BoundingBoxPrediction['label'],
  defects: Defect[],
  confidenceThreshold?: number | null,
): BoxAnnotation[] => {
  const isPrediction = typeof confidenceThreshold === 'number';
  return annotations
    .filter(annotation => !isPrediction || (annotation.score ?? 0) >= confidenceThreshold)
    .map(annotation => {
      const { defectId, coordinates, score } = annotation;
      if (!coordinates) {
        throw new Error('no coordinates for bounding box annotation');
      }
      return {
        key: `${defectId}-${Object.values(coordinates).join('')}-${score}`,
        description: defects ? defects.find(_ => _.id === defectId)?.name : undefined,
        color: getDefectColor(defects?.find(_ => _.id === defectId)),
        xMin: coordinates.xmin,
        yMin: coordinates.ymin,
        xMax: coordinates.xmax,
        yMax: coordinates.ymax,
        isPrediction,
        confidence: score,
        defectId,
      };
    });
};

import React, { useContext, createContext, useEffect, useMemo } from 'react';
import { Draft } from 'immer';
import { useImmer } from 'use-immer';
import { useHistory } from 'react-router';

import { LabelType, MediaStatusType } from '@clef/shared/types';

import { useDatasetMediaCountQuery } from '@/serverStore/dataset';
import { useGetProjectModelListQuery } from '@/serverStore/projectModels';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { StepName } from '../../types/client';
import { isModelTrainingSuccessful } from '../../store/projectModelInfoState/utils';
import { isOnMediaDetailsDialog } from '../../utils/url_utils';
import { getDateNumber } from '../../utils/time_utils';
import { isJestEnv } from '@clef/client-library/src/utils/env';
import { useGetModelArchSchemas } from '@/serverStore/train';

type STEP = {
  name: StepName;
  requiredPoints: number;
};

export const DEFAULT_STEPS: STEP[] = [
  {
    name: StepName.Upload,
    requiredPoints: 1,
  },
  {
    name: StepName.Label,
    requiredPoints: 10,
  },
  {
    name: StepName.Train,
    requiredPoints: 1,
  },
  {
    name: StepName.Predict,
    requiredPoints: 1,
  },
  {
    name: StepName.Complete,
    requiredPoints: 0,
  },
];

export type WorkflowAssistantState = {
  step: {
    stepName: StepName;
    stepStarted: boolean;
    stepPointsCompleted: number;
  } | null;
  loading: boolean;
  hovering: boolean;
  autoHovering: boolean;
  goButtonClicked: boolean;
  labelingRequiredPoints: number;
};

export const defaultState: WorkflowAssistantState = {
  step: null,
  loading: false,
  hovering: false,
  autoHovering: false,
  goButtonClicked: false,
  labelingRequiredPoints: 10,
};

export const WorkflowAssistantContext = createContext<{
  state: WorkflowAssistantState;
  dispatch: (f: (state: Draft<WorkflowAssistantState>) => void | WorkflowAssistantState) => void;
} | null>(null);

export const useWorkflowAssistantState = () => {
  const context = useContext(WorkflowAssistantContext);

  if (!context) {
    throw Error('No workflow assistant context registered');
  }

  return context;
};

export const useSteps = (): STEP[] => {
  const {
    state: { labelingRequiredPoints },
  } = useWorkflowAssistantState();
  return useMemo(() => {
    return DEFAULT_STEPS.map(step => {
      return {
        ...step,
        requiredPoints: step.name === StepName.Label ? labelingRequiredPoints : step.requiredPoints,
      };
    });
  }, [labelingRequiredPoints]);
};

export const WorkflowAssistantContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [state, dispatch] = useImmer(defaultState);
  const { labelType } = useGetSelectedProjectQuery().data ?? {};

  const firstRunExperienceWorkflowAssistantEnabled =
    // The workflow assistant is enabled for those three types
    (labelType === LabelType.BoundingBox ||
      labelType === LabelType.Segmentation ||
      labelType === LabelType.Classification) &&
    // After cleaning up the workflow assistant feature gate (https://feature.app.landing.ai/projects/default/features/first-run-exp-workflow-assistant),
    // the following API calls will be fetched, meaning that all tests should mock the APIs properly. One potential
    // Jest test failure can be:
    //   (node:718) UnhandledPromiseRejectionWarning: TypeError: Network request failed
    // For the sake of time, disable workflow assistant for Jest testing environments.
    !isJestEnv;

  const { data: mediaCount, isLoading: mediaCountLoading } = useDatasetMediaCountQuery(
    {
      selectOptions: {
        fieldFilterMap: {},
        columnFilterMap: {},
        selectedMedia: [],
        unselectedMedia: [],
        isUnselectMode: true,
      },
    },
    firstRunExperienceWorkflowAssistantEnabled,
  );
  const { data: labeledMediaCount, isLoading: labeledMediaCountLoading } =
    useDatasetMediaCountQuery(
      {
        selectOptions: {
          selectedMedia: [],
          unselectedMedia: [],
          isUnselectMode: true,
          fieldFilterMap: {},
          columnFilterMap: {
            datasetContent: { mediaStatus: { CONTAINS_ANY: [MediaStatusType.Approved] } },
          },
        },
      },
      firstRunExperienceWorkflowAssistantEnabled,
    );
  const { data: models, isLoading: modelsLoading } = useGetProjectModelListQuery(
    firstRunExperienceWorkflowAssistantEnabled,
  );

  const { isLoading: getModelArchSchemasLoading } = useGetModelArchSchemas();

  const loading =
    mediaCountLoading ||
    mediaCount === undefined ||
    labeledMediaCountLoading ||
    labeledMediaCount === undefined ||
    modelsLoading ||
    models === undefined ||
    getModelArchSchemasLoading;

  useEffect(() => {
    dispatch(state => {
      state.loading = loading;
    });
  }, [dispatch, loading]);

  useEffect(() => {
    if (!loading) {
      dispatch(state => {
        // Valid models should match the models rendered in model switcher of model performance panel
        const savedModels = models.filter(model => model.modelName);
        const latestModel = models.sort(
          (a, b) => getDateNumber(b.createdAt) - getDateNumber(a.createdAt),
        )[0];
        const validModels = [...savedModels];
        if (latestModel && !latestModel.modelName) {
          validModels.push(latestModel);
        }

        // If at least one model has inference, then the whole flow is finished
        if (validModels && validModels.find(model => !!model.predictionCount)) {
          state.step = {
            stepName: StepName.Complete,
            stepStarted: true,
            stepPointsCompleted: 0,
          };
        }

        // If at least one model is trained successfully, then the next step is predict
        else if (
          validModels &&
          validModels.find(model => isModelTrainingSuccessful(model.status, model.metricsReady))
        ) {
          state.step = {
            stepName: StepName.Predict,
            stepStarted: false,
            stepPointsCompleted: 0,
          };
        }

        // If there are at least ten / two labeled media (depends on project's type), then the next step is train
        else if (labeledMediaCount && labeledMediaCount >= state.labelingRequiredPoints) {
          state.step = {
            stepName: StepName.Train,
            stepStarted: false,
            stepPointsCompleted: 0,
          };
        }

        // If there is at least one media, then the next step is label
        // Please note, we might not check the rule that at least one media should be labeled with defect here, and
        // let training button group UI indicate it for customers
        else if (mediaCount && mediaCount > 0) {
          state.step = {
            stepName: StepName.Label,
            // If labeledMediaCount > 0, then assign true to "stepStarted"; otherwise, check if it is on media details
            // dialog. If yes, assign true; otherwise, assign false
            stepStarted:
              !!(labeledMediaCount ?? 0) || isOnMediaDetailsDialog(history.location.search),
            stepPointsCompleted: labeledMediaCount ?? 0,
          };
        }

        // Otherwise, the next step is upload
        else {
          state.step = {
            stepName: StepName.Upload,
            stepStarted: false,
            stepPointsCompleted: 0,
          };
        }
      });
    }
  }, [
    dispatch,
    history.location.search,
    loading,
    labeledMediaCount,
    labeledMediaCountLoading,
    mediaCount,
    mediaCountLoading,
    models,
    modelsLoading,
    state.labelingRequiredPoints,
  ]);

  return (
    <WorkflowAssistantContext.Provider value={{ state, dispatch }}>
      {children}
    </WorkflowAssistantContext.Provider>
  );
};

import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { LabelType, MediaDetailsWithPrediction, MediaStatusType } from '@clef/shared/types';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    color: palette.grey[100],
    background: 'rgba(0, 0, 0, 0.4)',
    borderRadius: spacing(1),
    padding: '2px 4px',
    fontWeight: 500,
  },
}));

export const MediaLevelLabelItem: React.FC<{
  label: string;
}> = ({ label }) => {
  const styles = useStyles();
  return label ? <div className={styles.container}>{label}</div> : null;
};

export type MediaLevelLabelGroupProps = {
  mediaDetails?: MediaDetailsWithPrediction;
  showGroundTruth?: boolean;
  showPredictions?: boolean;
};

/**
 * Showing bottom-right status indicator for media
 *
 * *Object detection project*
 * - Image view
 *   - GT
 *     - Unlabeled => show `Unlabeled`
 *     - In task => show `In Task`
 *     - (Labeled with GT label | Labeled with nothing to label) => no UI
 *   - Prediction
 *     - Not predicted => show `Unpredicted`
 *     - (Predicted with label | Predicted with nothing to label) => no UI
 *   - GT & Prediction
 *     - Unlabeled & Not predicted => show `Unlabeled` & `Unpredicted`
 *     - Unlabeled & (Predicted with label | Predicted with nothing to label) => show `Unlabeled`
 *     - In task & Not predicted => show `In Task` & `Unpredicted`
 *     - In task & (Predicted with label | Predicted with nothing to label) => show `In Task`
 *     - (Labeled with GT label | Labeled with nothing to label) & Not predicted => show `Unpredicted`
 *     - Other scenarios => no UI
 *  - Instance view (covered by component "MediaLevelLabelItem")
 *     - Has prediction label => show confidence score
 *     - No prediction label => no UI
 */
const MediaLevelLabelGroup: React.FC<MediaLevelLabelGroupProps> = ({
  mediaDetails,
  showGroundTruth,
  showPredictions,
}) => {
  const mediaStatus = mediaDetails?.mediaStatus;
  const { labelType } = useGetSelectedProjectQuery().data ?? {};

  const gtLabel = useMemo(() => {
    if (mediaStatus === MediaStatusType.Raw) {
      return 'Unlabeled';
    } else if (mediaStatus === MediaStatusType.InTask) {
      return 'In Task';
    }

    return '';
  }, [mediaStatus]);

  const predLabel = useMemo(() => {
    if (!mediaDetails?.predictionLabel) {
      return 'Unpredicted';
    }
    if (labelType === LabelType.Classification && mediaStatus === MediaStatusType.InTask) {
      return t('In Task');
    }

    return '';
  }, [labelType, mediaDetails?.predictionLabel, mediaStatus]);

  return (
    <Grid container spacing={1}>
      {showGroundTruth && gtLabel && (
        <Grid item>
          <MediaLevelLabelItem label={gtLabel} />
        </Grid>
      )}
      {showPredictions && predLabel && (
        <Grid item>
          <MediaLevelLabelItem label={predLabel} />
        </Grid>
      )}
    </Grid>
  );
};

export default MediaLevelLabelGroup;

import { ApiResponseLoader, LineChart } from '@clef/client-library';
import { Line, LineChartDataPoint } from '@clef/shared/types';
import { Box, Typography } from '@material-ui/core';
import { addDays, format } from 'date-fns';
import React, { useMemo } from 'react';

import { UsageParams, useUsageStyles } from './UsageSection';
import ChartEmptyState from '../components/ChartEmptyState';
import { dateDiffInDays } from '../../../utils';
import { range } from 'lodash';
import { useGetModelCounts } from '@/serverStore/usage';

type ModelsUsageSectionProps = {
  params: UsageParams;
};

const ModelsUsageSection: React.FC<ModelsUsageSectionProps> = ({ params }) => {
  const usageStyles = useUsageStyles();
  const { startTime, endTime, interval } = params;
  const {
    data: usageData,
    isLoading: loading,
    error,
  } = useGetModelCounts({
    fromTimestamp: startTime,
    toTimestamp: endTime,
    interval,
  });
  const days = useMemo(() => {
    if (!startTime || !endTime) {
      return 30;
    }
    return dateDiffInDays(new Date(endTime), new Date(startTime));
  }, [endTime, startTime]);

  const lineCharData: Line<LineChartDataPoint>[] = useMemo(() => {
    if (!usageData || !usageData.length) {
      return [];
    }
    const usageMap = usageData.reduce((acc, item) => {
      return {
        ...acc,
        [format(new Date(item.startTime), 'MMM dd')]: item.modelCount,
      };
    }, {} as Record<string, number>);
    const startDate = new Date(startTime);
    const data = range(0, days).map(dayIndex => {
      const date = addDays(startDate, dayIndex);
      const dateString = format(date, 'MMM dd');
      return { x: date.getTime(), y: usageMap[dateString] ?? 0 };
    });
    return [
      {
        name: '',
        values: data,
        color: '#0A64FC',
      },
    ];
  }, [days, startTime, usageData]);

  return (
    <Box marginTop={8}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={usageStyles.title} variant="h2">
          {t('Models')}
        </Typography>
      </Box>
      <ApiResponseLoader response={usageData} loading={loading} error={error}>
        {() => {
          return (
            <Box className={usageStyles.chartContainer}>
              {lineCharData.length > 0 ? (
                <LineChart
                  labelFormatterX={v => {
                    return format(new Date(v), 'MMM dd');
                  }}
                  labelFormatterY={value => (value === 0 ? '' : value)}
                  data={lineCharData}
                  getY={d => d.y || 0}
                  getX={d => d.x || Date.now()}
                  aspectRatio={4.5}
                  minY={0}
                />
              ) : (
                <ChartEmptyState
                  chartType="line"
                  title={t('No models trained or deployed during this time period')}
                  subtitle={t('Tip: Try changing the time range')}
                />
              )}
            </Box>
          );
        }}
      </ApiResponseLoader>
    </Box>
  );
};

export default ModelsUsageSection;

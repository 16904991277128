import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';

export const HubspotContactForm: React.FC<{}> = () => {
  const [formLoading, setFormLoading] = useState(true);
  const tryRenderForm = useCallback(() => {
    // @ts-ignore
    if (typeof hbspt !== 'undefined') {
      // @ts-ignore
      hbspt.forms.create({
        region: 'na1',
        portalId: '5754110',
        formId: '7b402688-7017-4134-a8d1-109d31112897',
        target: '#hubspotForm',
      });
      setFormLoading(false);
      return true;
    } else {
      return false;
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (!tryRenderForm()) {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);

      script.addEventListener('load', () => {
        // @ts-ignore
        tryRenderForm();
      });
    }
  }, [tryRenderForm]);

  return (
    <div>
      {formLoading && (
        <Grid container justifyContent="center" alignItems="center" direction="column">
          <CircularProgress size="26px" />
        </Grid>
      )}
      <div id="hubspotForm"></div>
    </div>
  );
};

import { Key } from '../types';
import {
  NOT_ALLOWED_SPECIAL_CHARACTERS,
  NOT_ALLOWED_SPECIAL_CHARACTERS_IN_EXPORT_DATASET_NAME,
} from '../constants';

export const mapToObject = <K, V>(aMap: Map<K, V>): Record<string, V> => {
  const obj: Record<string, V> = {};
  aMap.forEach((v, k) => {
    obj[String(k)] = v;
  });
  return obj;
};

export const nuthrows = <T>(value: T | null | undefined): T => {
  if (value === null || value === undefined) {
    throw new Error('value is null or undefined');
  }
  return value as T;
};

export const flatten = (value: any) => {
  if (value === null || value === undefined || Array.isArray(value) || typeof value !== 'object') {
    return {};
  }
  const flattenedParams: Record<string, string> = {};
  const traverse = (value: any, path: string) => {
    if (value === null || value === undefined) {
      flattenedParams[path] = '' + value;
      return;
    }
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        traverse(item, path + '.[' + index + ']');
      });
      return;
    }
    if (typeof value === 'object') {
      for (const [key, v] of Object.entries(value)) {
        traverse(v, path + '.' + key);
      }
      return;
    }
    flattenedParams[path] = value;
  };
  traverse(value, '');
  return flattenedParams;
};

const TRACE_ID_RADIX = 36;
export function getTraceId(): string {
  return Math.random().toString(TRACE_ID_RADIX).substring(2, 15); // remove the leading '0.'
}

export const CYPRESS_COOKIE_NAME = 'is_cypress_test';

/**
 * Invert map of key,value map given that key,value are one to one
 * ex:
 * map:     { a: 1, b: 2 }
 * output:  { 1: a, 2: b }
 */
export const reverseMap = <K extends Key, V extends Key>(map: {
  [K: string]: V;
}): { [V: string]: K } => {
  const init = {} as { [V: string]: K };
  return Object.entries(map).reduce((obj, [k, v]) => {
    // @ts-ignore
    obj[v] = k;
    return obj;
  }, init);
};

/**
 * Allow searching a key in a dictionary without case sensitivity
 */
export const caseInsensitiveLookUp = (obj: Record<string, any>, key: string) => {
  for (const k of Object.keys(obj)) {
    if (k.toLowerCase() == key.toLowerCase()) {
      return obj[k];
    }
  }
  return undefined;
};

/**
 * Cap value within a range
 */
export const cap = (value: number, { min, max }: { min: number; max: number }) => {
  if (min > max) {
    throw Error(`should satisfy min <= max, but got min: ${min} max: ${max}`);
  }
  return Math.max(min, Math.min(value, max));
};

export function containsSpecialChars(str: string) {
  return NOT_ALLOWED_SPECIAL_CHARACTERS.test(str);
}

export function containsSpecialCharsInExportedDatasetName(str: string) {
  return NOT_ALLOWED_SPECIAL_CHARACTERS_IN_EXPORT_DATASET_NAME.test(str);
}

export function replaceSpecialChars(
  str: string,
  newChar: string,
  pattern: RegExp = NOT_ALLOWED_SPECIAL_CHARACTERS,
) {
  const regex = new RegExp(pattern, 'g');
  return str.replace(regex, newChar);
}

export function escapeXml(content: string) {
  if (!content) {
    return '';
  }
  return content.replace(/[<>&'"#]/g, (c: string): string => {
    switch (c) {
      case '<':
        return '&lt;';
      case '>':
        return '&gt;';
      case '&':
        return '&amp;';
      case "'":
        return '&apos;';
      case '"':
        return '&quot;';
      default:
        return '';
    }
  });
}

export const getDateNumber = (date: number | Date | string | undefined | null) => {
  if (!date) {
    return NaN;
  }

  if (typeof date === 'number') {
    return date;
  }

  if (typeof date === 'string') {
    const dateNum = Date.parse(date);

    if (isNaN(dateNum)) {
      return NaN;
    }

    return dateNum;
  }

  return date.getTime();
};

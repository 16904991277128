export const PageTitle = {
  DefectBook: t('Defects'),
  NewDefectBook: t('Classes'),
  DefectConsensus: t('Defect consensus'),
  DataBrowser: t('Build'),
  Tags: t('Tags'),
  Metadata: t('Metadata'),
  Labeling: t('Labeling'),
  ExportedDataset: t('Exported dataset'),
  Models: t('Models'),
  TrainingJobs: t('Training jobs'),
  JobDetails: t('Job details'),
  ExperimentReport: t('Experiment report'),
  ErrorAnalysis: t('Error analysis'),
  ModelComparison: t('Model Comparison'),
  ModelAnalysisFailedJobs: t('Failed Jobs in last 7 days'),
  MyTasks: t('My tasks'),
  NewMyTasks: t('Tasks'),
  Deployment: t('Deployment'),
  Settings: t('Settings'),
  Deploy: t('Deploy'),
  ModelComparisonReports: t('Model Comparison Reports'),
  CustomTraining: t('Custom Training'),
};

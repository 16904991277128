import { LabelType, Label, LabelingType } from '@clef/shared/types';

export const getLabelingType = (projectLabelType?: LabelType | null, label?: Label | null) => {
  let labelingType:
    | LabelingType.DefectBoundingBox
    | LabelingType.DefectSegmentation
    | LabelingType.DefectClassification
    | undefined;

  // detect project level labeling type
  if (projectLabelType === LabelType.BoundingBox) {
    labelingType = LabelingType.DefectBoundingBox;
  } else if (projectLabelType === LabelType.Segmentation) {
    labelingType = LabelingType.DefectSegmentation;
  } else if (projectLabelType === LabelType.Classification) {
    labelingType = LabelingType.DefectClassification;
  } else if (projectLabelType === LabelType.AnomalyDetection) {
    labelingType = LabelingType.DefectClassification;
  }

  // to be compatible with old projects that don't have labelType
  if (!labelingType) {
    if (label?.bndboxNumber) {
      labelingType = LabelingType.DefectBoundingBox;
    } else if (label?.segNumber) {
      labelingType = LabelingType.DefectSegmentation;
    }
  }

  // if still no labelingType, force to bounding box
  return labelingType || LabelingType.DefectBoundingBox;
};

import { BaseAPI } from './base_api';
import { DefectBookExample, DefectBookExampleData } from '@clef/shared/types';

class DefectBookExampleApi extends BaseAPI {
  async getExamples(exampleIds: number[]): Promise<DefectBookExample[]> {
    return this.get('', /* params = */ { ids: exampleIds }, /* dataOnly = */ true);
  }

  async createExample(params: DefectBookExampleData): Promise<DefectBookExample> {
    return (await this.postJSON('create', /* params = */ params)).data;
  }

  async updateExample(exampleId: number, params: DefectBookExampleData): Promise<{}> {
    return (await this.postJSON('update', /* params = */ { ...params, exampleId })).data;
  }
}

export default new DefectBookExampleApi('defect_book_example');

import React, { useCallback, useEffect } from 'react';
import { Typography, Grid, Link, Box } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import cx from 'classnames';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useStyles } from './styles';
import { LandingLensLogo } from '../../components/Auth/Common';
import { AuthPageLayout } from '../../components/Layout/AuthPageLayout';
import EmailDeliveredPng from '../../images/email_delivered.png';
import { useTimer } from '../../hooks/useTimer';
import AuthAPI from '../../api/auth_api';
import { getSearchParamsValue, parseUTMParams } from '../../utils/url_utils';

export const SelfServiceSignupEmailDelivered: React.FC = () => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  //Decode the email from the url: abc123%40gmail.com -> abc123@gmail.com
  const email = decodeURIComponent(getSearchParamsValue(history?.location?.search, 'email'));

  const {
    time,
    start: timerStart,
    clear: timerClear,
    restart: timerRestart,
  } = useTimer({
    initialValue: 60,
    increment: -1,
    valueLimit: 0,
  });

  useEffect(() => {
    timerStart();
    return () => {
      timerClear();
    };
  }, [timerStart, timerClear]);

  const resendEmail = useCallback(async () => {
    if (!time) {
      try {
        timerRestart();
        const utmParams = parseUTMParams(location.search);
        await AuthAPI.register(email, utmParams);
      } catch (e) {
        enqueueSnackbar(e?.message, { variant: 'error', autoHideDuration: 12000 });
      }
    }
  }, [email, enqueueSnackbar, time, timerRestart]);

  return (
    <AuthPageLayout metaTitle={t('Email Delivered')}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minWidth="100vw"
        minHeight="100vh"
        position="relative"
      >
        <LandingLensLogo />

        <IconButton
          id="go-back"
          onClick={() => {
            history.goBack();
          }}
          className={styles.backButton}
        >
          <ArrowBackIcon />
        </IconButton>

        <Typography
          variant="h1"
          className={cx(styles.invitationDeliveredText, styles.marginBottom3)}
        >
          {t('Check your inbox for your invitation to LandingLens.')}
        </Typography>

        <Grid
          container
          direction="row"
          className={cx(styles.autoWidth, styles.marginBottom8)}
          alignItems="center"
        >
          <Typography className={cx(styles.marginRight1)}>
            {t('Don’t see the email? Check your spam folder or')}
          </Typography>

          <Link className={cx(styles.link, time && styles.disabledLink)} onClick={resendEmail}>
            {t('resend the email')} {time ? `(${time})` : null}
          </Link>
        </Grid>
        <img src={EmailDeliveredPng} className={styles.emailDeliveredPng} />
      </Box>
    </AuthPageLayout>
  );
};

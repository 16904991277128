import React, { useCallback } from 'react';
import { Box, Typography, Grid, Divider } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cx from 'classnames';

import { FilterOptionType, FilterOptionValueType } from '@clef/shared/types';
import { SimpleBadge } from '@clef/client-library';

import FilterDropdownPredefinedChoices from './FilterDropdownPredefinedChoices';
import FilterDropdownNumberOperation from './FilterDropdownNumberOperation';
import FilterDropdownRawText from './FilterDropdownRawText';

import useStyles from './styles';

export type MediaFilterDropDownProps = {
  filters: (FilterOptionType | 'divider')[];
  filterMappingKey: string | number;
};

export const MediaFilterDropDown: React.FC<MediaFilterDropDownProps> = ({
  filters,
  filterMappingKey,
}) => {
  const styles = useStyles();

  const filterSelectorComponent = useCallback(
    (open: boolean, filterName: string, badgeContent: number | 'check') => {
      return (
        <Grid
          className={cx(styles.optionItem, open && styles.optionItemOpen)}
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          container
          wrap="nowrap"
        >
          <Typography variant="body1" className={styles.selectorText}>
            {filterName}
          </Typography>
          {badgeContent === 'check' && <CheckCircleIcon fontSize="small" color="primary" />}
          {badgeContent !== 'check' && badgeContent > 0 && (
            <SimpleBadge color="primary" content={badgeContent} />
          )}
          {badgeContent !== 'check' && badgeContent === 0 && <ChevronRightIcon fontSize="small" />}
        </Grid>
      );
    },
    [styles.optionItem, styles.optionItemOpen, styles.selectorText],
  );

  const filterComponent = useCallback(
    (filter: FilterOptionType | 'divider', index: number) => {
      if (filter === 'divider') {
        return <Divider key={'divider' + index} className={styles.divider} />;
      }
      return (
        <React.Fragment key={filter.filterName}>
          {/* Predefined choice filter */}
          {(filter.valueType === FilterOptionValueType.PREDEFINED_CHOICES ||
            filter.valueType === FilterOptionValueType.SINGLE_SELECT) && (
            <FilterDropdownPredefinedChoices
              filterOption={filter}
              placement="right"
              extraGutter={{ horizontal: 4, vertical: -8 }}
              customizedSelector={(selectedFilters, open) =>
                filterSelectorComponent(open, filter.filterName, selectedFilters.length)
              }
              filterMappingKey={filterMappingKey}
            />
          )}
          {/* Number operation filter */}
          {filter.valueType === FilterOptionValueType.NUMBER && (
            <FilterDropdownNumberOperation
              filterOption={filter}
              placement="right"
              extraGutter={{ horizontal: 4, vertical: -8 }}
              customizedSelector={(selected, open) =>
                filterSelectorComponent(open, filter.filterName, selected ? 'check' : 0)
              }
              filterMappingKey={filterMappingKey}
            />
          )}
          {/* Raw text filter */}
          {filter.valueType === FilterOptionValueType.RAW_TEXT && (
            <FilterDropdownRawText
              filterOption={filter}
              placement="right"
              extraGutter={{ horizontal: 4, vertical: -8 }}
              customizedSelector={(selected, open) =>
                filterSelectorComponent(open, filter.filterName, selected ? 'check' : 0)
              }
              filterMappingKey={filterMappingKey}
            />
          )}
        </React.Fragment>
      );
    },
    [filterMappingKey, filterSelectorComponent, styles.divider],
  );

  const [columnFilters, metadataFilers] = filters.reduce(
    (acc, filterOption) => {
      acc[filterOption === 'divider' || filterOption.filterType === 'column' ? 0 : 1].push(
        filterOption,
      );
      return acc;
    },
    [[], []] as [(FilterOptionType | 'divider')[], (FilterOptionType | 'divider')[]],
  );

  return (
    <Box className={styles.dropdownPanel}>
      {columnFilters.map((filter, index) =>
        filterComponent(
          filter === 'divider' ? filter : { ...filter, filterName: t(filter.filterName) },
          index,
        ),
      )}
      {!!metadataFilers.length && <Divider className={styles.divider} />}
      {metadataFilers.map((filter, index) => filterComponent(filter, index))}
    </Box>
  );
};

export default MediaFilterDropDown;

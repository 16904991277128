import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  RadioGroup,
  TextField,
  Typography,
  Radio,
  LinearProgress,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import Dialog from '@material-ui/core/Dialog';
import React, { useState, useCallback } from 'react';
import UserSelector from '../UserSelector/UserSelector';
import {
  SelectMediaOption,
  TaskPurpose,
  User,
  MediaStatusType,
  LabelType,
  LabelingType,
} from '@clef/shared/types';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useSnackbar } from 'notistack';
import { TaskCreationOptions } from '../TaskCreationDialog/types';
import { DatasetGroupOptions } from '@clef/shared/types';
import { SelectOptions, ApiResponseLoader } from '@clef/client-library';
import { Alert } from '@material-ui/lab';
import { createTaskApi, refreshUseMyTask } from '../../hooks/api/useTaskApi';
import CheckIcon from '@material-ui/icons/Check';
import { MAX_LABELING_QUORUM_SIZE } from '@clef/shared/constants';
import { ERROR_SNACKBAR_STYLE, SUCCESS_SNACKBAR_STYLE } from '../../constants/snackbar';
import { useLabelingTaskDialogStyles } from './styles';
import { ProjectLabelTypeDisplayProps } from '../../utils/project';
import { MediaStatusProps } from '../../utils/media_status_props';
import { datasetQueryKeys, useGetDatasetStatsQuery } from '@/serverStore/dataset';
import { useQueryClient } from '@tanstack/react-query';
import { layoutQueryKeys } from '@/serverStore/layout';

export interface props {
  selectedMediaOptions: SelectMediaOption;
  onTaskCreationSuccess: () => void;
  onClose: () => void;
}

const projectLabelTypeToTaskLabelType = {
  [LabelType.BoundingBox]: [LabelingType.DefectBoundingBox],
  [LabelType.Classification]: [LabelingType.DefectClassification],
  [LabelType.AnomalyDetection]: [LabelingType.DefectClassification],
  [LabelType.Segmentation]: [LabelingType.DefectSegmentation],
  [LabelType.SegmentationInstantLearning]: [LabelingType.DefectSegmentation],
};

const LabelingTaskCreationDialog: React.FC<props> = ({
  onTaskCreationSuccess,
  selectedMediaOptions,
  onClose,
}) => {
  const styles = useLabelingTaskDialogStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { id: projectId, datasetId, labelType } = useGetSelectedProjectQuery().data ?? {};

  const isLegacyProjectWithoutLabelType = !labelType;
  const queryClient = useQueryClient();

  const [taskName, setTaskName] = useState<string>('');
  const [transferLabel, setTransferLabel] = useState(false);
  const [isCreatingTask, setIsCreatingTask] = useState(false);
  const [selectedLabelers, setSelectedLabelers] = useState<User[]>([]);
  const [numberOfLabelerPerMedia, setNumberOfLabelersPerMedia] = useState(1);
  const supportAgreementLabeling =
    !labelType || [LabelType.BoundingBox, LabelType.Segmentation].includes(labelType);

  // 2021.09.01 For backwards compatible, in case there are no labelType from project
  const [stateLabelType, setStateLabelingType] = useState<LabelType>(
    labelType ?? LabelType.BoundingBox,
  );
  const {
    data: stats,
    isLoading: statsLoading,
    error: statsError,
  } = useGetDatasetStatsQuery({
    selectOptions: selectedMediaOptions,
    groupOptions: [DatasetGroupOptions.MEDIA_STATUS],
  });
  const onTaskNameChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTaskName(e.target?.value || '');
  }, []);

  const onCreateTaskClick = useCallback(async (): Promise<void> => {
    setIsCreatingTask(true);
    try {
      if (!datasetId || !projectId) {
        throw new Error('no datasetId');
      }
      const taskCreationOptions: TaskCreationOptions = {
        projectId,
        userIds: selectedLabelers.map(user => user.id),
        taskName,
        purpose:
          numberOfLabelerPerMedia === 1 ? TaskPurpose.BatchLabeling : TaskPurpose.MultiLabeling,
        datasetId,
        doesTransferLabels: transferLabel,
        labelingType: projectLabelTypeToTaskLabelType[stateLabelType],
        selectedMediaOptions,
        extra: {
          numberOfLabelerPerMedia,
        },
      };
      const response = await createTaskApi(taskCreationOptions);
      datasetId && queryClient.invalidateQueries(datasetQueryKeys.mediaDetails(datasetId));
      projectId && queryClient.invalidateQueries(datasetQueryKeys.allWithFilters(projectId));
      const { task } = response.data;
      const { id: taskId } = task;
      enqueueSnackbar(
        t('Task {{temp0}} has been created', {
          temp0: taskId,
        }),
        SUCCESS_SNACKBAR_STYLE,
      );
      onTaskCreationSuccess();

      // Refresh layout to show task in sidebar
      projectId && queryClient.invalidateQueries(layoutQueryKeys.list(projectId));
      refreshUseMyTask({ keys: 'refresh-all' });
      onClose();
    } catch (err) {
      enqueueSnackbar(
        (err?.body || err)?.message, // this could be an api error or frontend error
        ERROR_SNACKBAR_STYLE,
      );
    } finally {
      setIsCreatingTask(false);
    }
  }, [
    datasetId,
    projectId,
    selectedLabelers,
    taskName,
    numberOfLabelerPerMedia,
    transferLabel,
    stateLabelType,
    selectedMediaOptions,
    enqueueSnackbar,
    onTaskCreationSuccess,
    onClose,
  ]);

  const enoughLabelersForMultiLabelerTask = numberOfLabelerPerMedia <= selectedLabelers.length;
  return (
    <Dialog
      aria-labelledby="labeling-task-creation-dialog-title"
      data-testid="labeling-task-dialog"
      fullWidth
      maxWidth={'md'}
      onClose={onClose}
      open
    >
      <ApiResponseLoader
        response={stats}
        loading={statsLoading}
        error={statsError}
        defaultHeight={600}
      >
        {statsLoaded => {
          const mediaStats = statsLoaded.reduce(
            (obj, v) => {
              obj[MediaStatusProps(v.media_status!).text] = v.count;
              return obj;
            },
            {
              [MediaStatusProps(MediaStatusType.Raw).text]: 0,
              [MediaStatusProps(MediaStatusType.Approved).text]: 0,
              [MediaStatusProps(MediaStatusType.InTask).text]: 0,
            } as { [key: string]: number },
          );

          const availableMediaCounts =
            mediaStats[MediaStatusProps(MediaStatusType.Raw).text] +
            mediaStats[MediaStatusProps(MediaStatusType.Approved).text];
          const pendingMediasCount = mediaStats[MediaStatusProps(MediaStatusType.InTask).text];
          // selectedMedia.length is 0 when using "Select all" option, so assume no deleted medias
          const deletedMediasCount =
            selectedMediaOptions.selectedMedia.length > 0
              ? selectedMediaOptions.selectedMedia.length -
                availableMediaCounts -
                pendingMediasCount
              : 0;
          const notAvailableCount = deletedMediasCount + pendingMediasCount;

          return (
            <>
              {isCreatingTask && (
                <div className={styles.postRequestMask}>
                  <LinearProgress />
                </div>
              )}
              <div className={styles.dialogContainer} data-testid="task-creation-dialog">
                <DialogTitle id="labeling-task-creation-dialog-title">
                  {t('Create new {{type}} labeling task', {
                    type: labelType
                      ? ProjectLabelTypeDisplayProps[labelType].title.toLowerCase()
                      : '',
                  })}
                </DialogTitle>
                <DialogContent>
                  {/* no medias available */}
                  {!availableMediaCounts && (
                    <Alert className={styles.alerts} severity="error">
                      {t(
                        "All media are in task or deleted. Can't create tasks for these media, please finish/cancel labeling tasks and review labels first.",
                      )}
                    </Alert>
                  )}
                  {/* have pending or deleted media */}
                  {!!availableMediaCounts && !!notAvailableCount && (
                    <Alert className={styles.alerts} severity="warning">
                      {t(
                        '{{totalCount}} image(s) selected, however your task will only have {{availableMediaCounts}} image(s). In Task images and deleted images will not be included in the task.',
                        {
                          totalCount: <strong>{availableMediaCounts + notAvailableCount}</strong>,
                          availableMediaCounts: <strong>{availableMediaCounts}</strong>,
                        },
                      )}
                    </Alert>
                  )}
                  {/* all available media  */}
                  {!!availableMediaCounts && !notAvailableCount && (
                    <Alert className={styles.alerts} severity="success">
                      {t('Your task will have {{availableMediaCounts}} media.', {
                        availableMediaCounts: <strong>{availableMediaCounts}</strong>,
                      })}
                    </Alert>
                  )}
                  {availableMediaCounts > 0 && (
                    <>
                      <Typography variant="subtitle1" className={styles.typography}>
                        {t('Task name')}
                      </Typography>
                      <TextField
                        variant="outlined"
                        fullWidth
                        autoFocus
                        onChange={onTaskNameChange}
                        value={taskName}
                        inputProps={{
                          className: styles.input,
                          maxLength: 50, // max length for task name
                          'data-testid': 'labeling-task-name',
                        }}
                        classes={{
                          root: styles.inputRoot,
                        }}
                        size="small"
                      />
                      {isLegacyProjectWithoutLabelType && (
                        <>
                          <Typography variant="subtitle1" className={styles.typography}>
                            {t('Labeling task type')}
                          </Typography>
                          <SelectOptions
                            size="medium"
                            value={ProjectLabelTypeDisplayProps[stateLabelType].title}
                            options={[
                              ProjectLabelTypeDisplayProps[LabelType.BoundingBox].title,
                              ProjectLabelTypeDisplayProps[LabelType.Segmentation].title,
                            ]}
                            onChange={newValue => {
                              const newType = Object.entries(ProjectLabelTypeDisplayProps).find(
                                ([_, value]) => value.title === newValue,
                              )![0];
                              setStateLabelingType(newType as LabelType);
                            }}
                          />
                        </>
                      )}
                      {supportAgreementLabeling && (
                        <>
                          <Typography variant="subtitle1" className={styles.typography}>
                            {t('Labeling agreement')}
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {t('Calculating labeling agreement among multiple labelers helps:')}
                          </Typography>
                          <Typography variant="body2" gutterBottom className={styles.textWithIcon}>
                            <CheckIcon fontSize="small" className={styles.checkIcon} />
                            {t('Prioritize media in review')}
                          </Typography>
                          <Typography variant="body2" gutterBottom className={styles.textWithIcon}>
                            <CheckIcon fontSize="small" className={styles.checkIcon} />
                            {t('Identify defect ambiguity')}
                          </Typography>
                          <Typography variant="body2" gutterBottom className={styles.textWithIcon}>
                            <CheckIcon fontSize="small" className={styles.checkIcon} />
                            {t('Auto-reject media with low agreement score')}
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            {t(
                              'Please select the number of distinctive labels (labeled by different labelers) required:',
                            )}
                          </Typography>
                          <SelectOptions
                            size="medium"
                            value={numberOfLabelerPerMedia}
                            options={Array.from(
                              { length: MAX_LABELING_QUORUM_SIZE },
                              (_, i) => i + 1,
                            )}
                            onChange={newValue => setNumberOfLabelersPerMedia(newValue as number)}
                          />
                        </>
                      )}
                      <Typography variant="subtitle1" className={styles.typography}>
                        {t('Carry over existing labels?')}
                      </Typography>
                      <RadioGroup
                        value={transferLabel ? 'yes' : 'no'}
                        onChange={event => {
                          setTransferLabel(event.target.value === 'yes');
                        }}
                        row
                      >
                        <FormControlLabel
                          value={'no'}
                          control={<Radio color="primary" size="small" />}
                          label={<Typography variant="body2">{t('No')}</Typography>}
                          data-testid="no-keep-labels-in-task"
                        />
                        <FormControlLabel
                          value={'yes'}
                          control={<Radio color="primary" size="small" />}
                          label={<Typography variant="body2">{t('Yes')}</Typography>}
                          data-testid="keep-labels-in-task"
                        />
                      </RadioGroup>
                      <>
                        <Typography variant="subtitle1" className={styles.typography}>
                          {t('Assign labelers')}
                          {!enoughLabelersForMultiLabelerTask && (
                            <span className={styles.errorMessage}>
                              {t(
                                'Number of labelers has to be greater than number of labelers per media',
                              )}
                            </span>
                          )}
                        </Typography>
                        <UserSelector setUsers={setSelectedLabelers} />
                      </>
                    </>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    id="close-labeling-task-creation-dialog"
                    onClick={onClose}
                    color="primary"
                    data-testid="cancel-create-task-btn"
                  >
                    {t('Cancel')}
                  </Button>
                  {availableMediaCounts > 0 && (
                    <Button
                      id="create-task-dialog-button"
                      disabled={
                        !taskName || !selectedLabelers.length || !enoughLabelersForMultiLabelerTask
                      }
                      onClick={onCreateTaskClick}
                      color="primary"
                      variant="contained"
                      data-testid="accept-create-task-btn"
                    >
                      {t('Create Task')}
                    </Button>
                  )}
                </DialogActions>
              </div>
            </>
          );
        }}
      </ApiResponseLoader>
    </Dialog>
  );
};

export default LabelingTaskCreationDialog;

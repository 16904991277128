import React, { useState } from 'react';
import {
  Typography,
  Grid,
  makeStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
  LinearProgress,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import { useMount } from '@clef/client-library';

import { NO_SPLIT_DISPLAY_NAME } from '@clef/shared/constants';
import { statsCardDataKeyCompareFn } from '../../utils/str_utils';
import { useGetProjectSplitQuery } from '@/serverStore/projects';

const useStyles = makeStyles(theme => ({
  optionItem: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    paddingRight: theme.spacing(5),
    '&:hover': {
      backgroundColor: 'rgba(9, 30, 66, 0.08)',
    },
    // overwrites
    marginLeft: 0,
    marginRight: 0,
  },
  textButton: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  dropdownPanel: {
    minWidth: 150,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  dropdownContainer: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 8,
  },
  postRequestMask: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
    background: 'rgba(255,255,255,0.7)',
  },
}));

export interface SetSplitDropdownProps {
  toggleDropdown: (open: boolean) => void;
  enableLinearProgress?: boolean;
  setSplit?: (projectSplitId: number, selectedOption: string) => any;
  onSetSplitSuccess?: () => void;
}

const INITIAL_SELECTED_OPTION = '';

const SetSplitDropdown: React.FC<SetSplitDropdownProps> = ({
  toggleDropdown,
  enableLinearProgress,
  setSplit,
  onSetSplitSuccess,
}) => {
  const styles = useStyles();
  const isMount = useMount();

  const [selectedOption, setSelectedOption] = useState<string>(INITIAL_SELECTED_OPTION);
  const [applyingSplitSet, setApplyingSplitSet] = useState(false);

  const { data: projectSplits = [] } = useGetProjectSplitQuery();
  const projectSplitNames = projectSplits.map(p => p.splitSetName);
  const projectSplitNameToId = projectSplits.reduce((obj: Record<string, number>, projectSplit) => {
    obj[projectSplit.splitSetName] = projectSplit.id;
    return obj;
  }, {});
  // no split will use 0 as the value
  projectSplitNameToId[NO_SPLIT_DISPLAY_NAME] = 0;
  return (
    <div className={styles.dropdownContainer} data-testid="set-split-dropdown">
      {enableLinearProgress && applyingSplitSet && (
        <div className={styles.postRequestMask}>
          <LinearProgress />
        </div>
      )}
      <div className={styles.dropdownPanel}>
        {
          // !allowMultiple - radio group
          <RadioGroup
            value={selectedOption || null}
            onChange={(_, newValue: string) => setSelectedOption(newValue)}
          >
            {projectSplitNames
              .sort((a, b) => statsCardDataKeyCompareFn(a, b))
              .map(option => (
                <FormControlLabel
                  className={styles.optionItem}
                  key={option}
                  value={option}
                  control={<Radio size="small" color="primary" />}
                  label={<Typography variant="body2">{option}</Typography>}
                  data-testid={`split-option-${option}`}
                />
              ))}
            <FormControlLabel
              className={styles.optionItem}
              key={NO_SPLIT_DISPLAY_NAME}
              value={NO_SPLIT_DISPLAY_NAME}
              control={<Radio size="small" color="primary" />}
              label={<Typography variant="body2">{NO_SPLIT_DISPLAY_NAME}</Typography>}
              data-testid={`split-option-unassigned`}
            />
          </RadioGroup>
        }
        {/* Apply / Clear button */}
        <Grid container justifyContent="flex-end">
          <Button
            id="apply-split-btn"
            color="primary"
            size="small"
            className={styles.textButton}
            disabled={!selectedOption}
            onClick={async () => {
              if (enableLinearProgress) {
                setApplyingSplitSet(true);
              }
              setSplit && (await setSplit(projectSplitNameToId[selectedOption], selectedOption));
              if (enableLinearProgress) {
                isMount() && setApplyingSplitSet(false);
              }
              onSetSplitSuccess?.();
              toggleDropdown(false);
            }}
          >
            {t('Apply')}
          </Button>
          <Button
            color="secondary"
            size="small"
            className={styles.textButton}
            disabled={!selectedOption}
            onClick={() => setSelectedOption(INITIAL_SELECTED_OPTION)}
            id="clear-split-btn"
          >
            {t('Clear')}
          </Button>
        </Grid>
      </div>
    </div>
  );
};

export default SetSplitDropdown;

import React, { useEffect, useState } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import { useRenameProjectMutation } from '@/serverStore/projects';
import Check from '@material-ui/icons/Check';

interface EditProjectNameTextFieldProps {
  name: string;
  projectId: number;
  setIsEditingName: (isEditingName: boolean) => void;
}

export const EditProjectNameTextField: React.FC<EditProjectNameTextFieldProps> = props => {
  const { name, projectId, setIsEditingName } = props;
  const renameProject = useRenameProjectMutation();
  const [projectName, setProjectName] = useState(name ?? '');

  useEffect(() => {
    name && setProjectName(name);
  }, [name]);

  return (
    <>
      <TextField
        inputProps={{
          'aria-label': 'project-setting-edit-name-field',
        }}
        value={projectName}
        onClick={e => e.stopPropagation()}
        onChange={e => setProjectName(e.target.value)}
        disabled={renameProject.isLoading}
        onKeyUp={async e => {
          if (e.key === 'Enter') {
            await renameProject.mutateAsync({
              projectId: projectId,
              originalProjectName: name,
              editingProjectName: projectName,
            });
            setIsEditingName(false);
          }
        }}
        onBlur={async e => {
          if (e.relatedTarget && e.relatedTarget.id === 'more-icon-button') {
            // When click 'rename' from Project Card, icon button will automatically fire focus event once, making textField trigger blur event
            return;
          }
          await renameProject.mutateAsync({
            projectId: projectId,
            originalProjectName: name,
            editingProjectName: projectName,
          });
          setIsEditingName(false);
        }}
        autoFocus
      />
      {renameProject.isLoading ? (
        <IconButton id="edit-project-name-loading" disabled>
          <CircularProgress size={16} />
        </IconButton>
      ) : (
        <IconButton
          id="edit-project-name-done"
          onClick={async e => {
            e.stopPropagation();
            await renameProject.mutateAsync({
              projectId: projectId,
              originalProjectName: name,
              editingProjectName: projectName,
            });
            setIsEditingName(false);
          }}
          color="primary"
        >
          <Check fontSize="small" />
        </IconButton>
      )}
    </>
  );
};

export default EditProjectNameTextField;

import React from 'react';
import { Media } from '@clef/shared/types';
import { Skeleton } from '@material-ui/lab';
import { getThumbnail, MediaViewer } from '@clef/client-library';
import { useDatasetMediaDetailsQuery } from '@/serverStore/dataset';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useVPLatestModel } from '@/serverStore/projectModels';
import { Box, makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const IMAGE_WIDTH = 160;
const IMAGE_HEIGHT = 140;

const useStyles = makeStyles(theme => ({
  mediaViewerWrapper: {
    transition: 'border 0.2ms',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.95,
    },
    width: '100%',
    padding: theme.spacing(2, 6),
  },
  mediaViewerSelected: {
    backgroundColor: 'rgba(12, 100, 205, 0.1)',
    '& $mediaViewierBorder': {
      borderColor: theme.palette.primary.main,
    },
  },
  mediaViewierBorder: {
    transition: 'border 0.2ms',
    border: '2px solid transparent',
    borderRadius: 6,
  },
}));

export type MediaViewerWrapperProps = {
  media?: Media;
  selected?: boolean;
  onClick?(): void;
};

const MediaViewerWrapper: React.FC<MediaViewerWrapperProps> = ({ media, selected, onClick }) => {
  const styles = useStyles();
  const { datasetId } = useGetSelectedProjectQuery().data ?? {};
  const { latestModel } = useVPLatestModel();
  const { id: latestModelId } = latestModel ?? {};

  const { data: mediaDetails } = useDatasetMediaDetailsQuery({
    datasetId,
    mediaId: media?.id,
    modelId: latestModelId,
  });

  if (!mediaDetails) {
    return <Skeleton variant="rect" width={IMAGE_WIDTH} height={IMAGE_HEIGHT} />;
  }
  return (
    <Box className={classNames(styles.mediaViewerWrapper, selected && styles.mediaViewerSelected)}>
      <Box
        width={IMAGE_WIDTH}
        height={IMAGE_HEIGHT}
        marginY={2}
        onClick={onClick}
        className={styles.mediaViewierBorder}
      >
        <MediaViewer
          imgSrc={getThumbnail(mediaDetails, 'medium')}
          fallbackImgSrc={mediaDetails?.url}
          properties={mediaDetails.properties ?? { width: IMAGE_WIDTH, height: IMAGE_HEIGHT }}
        />
      </Box>
    </Box>
  );
};

export default MediaViewerWrapper;

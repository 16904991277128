import {
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import React from 'react';
import { useGetProjectSplitQuery } from '@/serverStore/projects';

export interface ManageSplitDialogProps {
  onClose: () => void;
}

const ManageSplitDialog: React.FC<ManageSplitDialogProps> = ({ onClose }) => {
  const { data: projectSplits } = useGetProjectSplitQuery();

  return (
    <Dialog fullWidth maxWidth="md" open onClose={onClose}>
      <DialogContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Split</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projectSplits?.map(projectSplit => (
                <TableRow key={projectSplit.id}>
                  <TableCell>{projectSplit.splitSetName}</TableCell>
                  <TableCell>{projectSplit.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button id="close-split-dialog" variant="text" color="primary" onClick={onClose}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageSplitDialog;

import * as t from '@clef/shared/types';

import fetchHookFactory from './fetchHookFactory';
import MaglevApi from '../../api/maglev_api';
import { ModelClass } from '../../constants/model';

export const [useMaglevModelClassConfigApi] = fetchHookFactory(
  (params: { filteredArchClass: ModelClass | null }) =>
    MaglevApi.getModelArchConfigs().then(modelArchs =>
      modelArchs
        // Hide classification models when the project has not defined type
        .filter(({ archClass }) =>
          params.filteredArchClass
            ? params.filteredArchClass === archClass
            : // no-type projects only allow object-detection or segmentartion labels
              /object-detection/i.test(archClass) || /segmentation/i.test(archClass),
        ),
    ),
  'stale-while-revalidate',
);

export const [useModelsPage] = fetchHookFactory(
  (params: Parameters<typeof MaglevApi.getTrainedModelsPage>[0]) =>
    MaglevApi.getTrainedModelsPage(params),
  'stale-while-revalidate',
);

export const [useMaglevGetJobLogsApi, refreshJobLogs] = fetchHookFactory(
  (params: { projectId: number; jobId: string }) => {
    return MaglevApi.getJobLogs(params.projectId, params.jobId);
  },
  'stale-while-revalidate',
);

export const createTrainingJobApi = async (formFields: t.GenericFormType) => {
  await MaglevApi.postCreateTrainingJob(formFields);
};

export const createEvalJobApi = async (formFields: t.GenericFormType) => {
  await MaglevApi.postCreateEvalJob(formFields);
};

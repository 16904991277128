import React from 'react';
import { SelectMediaOption, DatasetGroupOptions } from '@clef/shared/types';
import { ChartContainer, ApiResponseLoader, DistributionChart } from '@clef/client-library';
import { useGetDatasetStatsQuery } from '@/serverStore/dataset';
import { useFormattedSplitDefectsForChart } from './useFormattedSplitDefectsForChart';

export interface LabelsPerDefectSplitDistributionChartProps {
  title?: string;
  selectMediaOptions: SelectMediaOption;
  hideLabel?: boolean;
  compact?: boolean;
}

const LabelsPerDefectSplitDistributionChart: React.FC<
  LabelsPerDefectSplitDistributionChartProps
> = ({ title, selectMediaOptions, hideLabel }) => {
  /**
   * Example response:
   * [{count: 4, defect_distribution: {1948291: 2}, split: "dev"}]
   * This means there are 4 media that have two defects of defect ID 1948291 in split dev
   */
  const {
    data: mediaDefectDistribution,
    isLoading: mediaDefectDistributionLoading,
    error: mediaDefectDistributionError,
  } = useGetDatasetStatsQuery({
    selectOptions: selectMediaOptions,
    groupOptions: [DatasetGroupOptions.DEFECT_DISTRIBUTION, DatasetGroupOptions.SPLIT],
    // version,
  });

  const { totalDefectsFormatted, defectColorMap } =
    useFormattedSplitDefectsForChart(mediaDefectDistribution);

  const ChartComponent = (
    <ApiResponseLoader
      response={mediaDefectDistribution}
      loading={mediaDefectDistributionLoading}
      error={mediaDefectDistributionError}
      defaultHeight={300}
    >
      {() => {
        return (
          <div>
            <DistributionChart
              distributionData={totalDefectsFormatted}
              distributorColorMap={defectColorMap}
              hideLabel={hideLabel}
              size="small"
              bandWidth={20}
            />
          </div>
        );
      }}
    </ApiResponseLoader>
  );

  return title ? <ChartContainer title={title}>{ChartComponent}</ChartContainer> : ChartComponent;
};

export default LabelsPerDefectSplitDistributionChart;

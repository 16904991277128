import React, { useMemo, useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  TextField,
  makeStyles,
  Popover,
  DialogActions,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { ColorResult } from 'react-color';
import { IconButton, Button } from '@clef/client-library';
import ColorPicker from '@/pages/DefectBook/components/ColorPicker';
import ColorToggleButton from '@/pages/DefectBook/components/ColorToggleButton';
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import { COLOR_PALETTE } from '@/constants/colors';

const useStyles = makeStyles(theme => ({
  createClassesDialog: {
    maxWidth: '395px',
    position: 'relative',
  },
  postRequestMask: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  editModeTitle: {
    ...theme.typography.h3,
    padding: theme.spacing(2, 3),
  },
  editModeInputRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
    },
    marginBottom: theme.spacing(1),
  },
  dialogTitleBox: {
    position: 'relative',
  },
  close: {
    position: 'absolute',
    right: '10px',
    top: '13px',
    cursor: 'pointer',
  },
  dialogTitle: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#000',
  },
  inputTip: {
    color: theme.palette.grey[400],
  },
  addColor: {
    transform: 'translateY(-2px)',
  },
  button: {
    color: theme.palette.blue[500],
  },
  saveButton: {
    backgroundColor: theme.palette.blue[500],
    borderRadius: '13px',
  },
  colorButtonItem: {
    width: '24px',
    height: '24px',
    padding: '0 !important',
  },
  colorButtonItemWrapper: {
    margin: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px 0`,
    padding: 0,
    '&:nth-child(11n)': {
      marginRight: 0,
    },
  },
  disabledClassName: {
    margin: 0,
    padding: 0,
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  newColorPalette: {
    width: '24px',
    height: '24px',
  },
  disabledColorPalette: {
    width: '20px',
    height: '20px',
  },
  selector: {
    width: '16px',
    height: '16px',
  },
  propertyNameText: {
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  title: string;
  selectedName: string;
  setSelectedName: (name: string) => void;
  propertyName: string;
  selectedColor: string;
  setSelectedColor: (color: string) => void;
  defaultColors: string[];
  chosenColors: string[];
  isCreating: boolean;
  disableSaveButton?: boolean;
  showTextHint?: boolean;
  hideColorSelector?: boolean;
  skipExistingColorCheck?: boolean;
  onClose: () => void;
  onDiscardButtonClick?: () => void | Promise<void>;
  onSaveButtonClick: () => void | Promise<void>;
}

export const DialogWithColorSelector: React.FC<Props> = ({
  title,
  propertyName,
  selectedName,
  setSelectedName,
  selectedColor,
  setSelectedColor,
  defaultColors,
  chosenColors,
  isCreating,
  hideColorSelector,
  disableSaveButton,
  skipExistingColorCheck,
  onClose,
  onDiscardButtonClick,
  onSaveButtonClick,
  showTextHint = true,
}) => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [customColors, setCustomColors] = useState<string[]>([]);
  const [newColor, setNewColor] = useState(COLOR_PALETTE[0].toUpperCase());
  const { enqueueSnackbar } = useSnackbar();

  const handleAddNewColor = () => {
    setAnchorEl(null);
    if (
      [...customColors, selectedColor, ...defaultColors]
        .map(color => color.toUpperCase())
        .includes(newColor)
    ) {
      enqueueSnackbar(t('Please do not set duplicate colors'), {
        variant: 'warning',
      });
      return;
    }
    setSelectedColor(newColor);
    setCustomColors([...customColors, newColor]);
  };

  const isExist = (color: string) => {
    return chosenColors.includes(color);
  };

  const isDisabledCreate = useMemo(() => {
    const isRepeat = isExist(selectedColor);
    return !selectedName || (!skipExistingColorCheck && isRepeat);
  }, [isExist, selectedColor, selectedName, skipExistingColorCheck]);

  const showColorPicker = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (chosenColors.length > 0) {
      const colors = chosenColors.filter(color => !defaultColors.includes(color));
      setCustomColors(Array.from(new Set(colors)));
    }
  }, [chosenColors]);

  const commonColorButtonClassNames = {
    className: styles.colorButtonItem,
    colorPaletteClassName: styles.newColorPalette,
    disabledColorPaletteClassName: styles.disabledColorPalette,
    selectorColorClassName: styles.selector,
    disabledClassName: styles.disabledClassName,
  };

  return (
    <>
      <Dialog
        classes={{
          paper: styles.createClassesDialog,
        }}
        open
        fullWidth
        maxWidth="sm"
        onClose={onClose}
      >
        {isCreating && (
          <div className={styles.postRequestMask}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle className={styles.dialogTitleBox}>
          <Typography className={styles.dialogTitle} variant="h2">
            {title}
          </Typography>
          <CloseOutlined
            className={styles.close}
            onClick={() => {
              setAnchorEl(null);
              onClose();
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box>
              <Typography variant="body2" className={styles.propertyNameText}>
                {t('{{propertyName}} Name', {
                  propertyName,
                })}
              </Typography>

              <TextField
                variant="outlined"
                fullWidth
                inputProps={{
                  'aria-label': 'create-defect-name-field',
                  className: styles.editModeTitle,
                }}
                classes={{
                  root: styles.editModeInputRoot,
                }}
                value={selectedName}
                onChange={e => {
                  e.persist();
                  const value = e?.target?.value || '';
                  if (value.length <= 30) {
                    setSelectedName(value);
                  } else {
                    setSelectedName(value.substring(0, 30));
                  }
                }}
              />

              {showTextHint && (
                <Typography variant="body2" className={styles.inputTip}>
                  {t('You cannot edit {{propertyName}} name afterwards', {
                    propertyName,
                  })}
                </Typography>
              )}
            </Box>

            {!hideColorSelector && (
              <Box marginTop={4} marginBottom={4}>
                <Typography variant="body2">
                  {t('{{propertyName}} Color', {
                    propertyName,
                  })}
                </Typography>

                <Box marginTop={2} display="flex" flexWrap="wrap">
                  {defaultColors.map(color => {
                    const disabled = isExist(color);
                    return (
                      <Box
                        key={color}
                        data-testid={`color-toggle-button-item-${color}`}
                        aria-disabled={disabled}
                        className={styles.colorButtonItemWrapper}
                      >
                        <ColorToggleButton
                          disabled={disabled}
                          key={color}
                          color={color}
                          selectedColor={selectedColor}
                          onClick={() => setSelectedColor(color)}
                          {...commonColorButtonClassNames}
                        />
                      </Box>
                    );
                  })}
                  {!!customColors.length && (
                    <>
                      {customColors.map(color => {
                        const disabled = isExist(color);
                        return (
                          <Box
                            key={color}
                            data-testid={`color-toggle-button-item-${color}`}
                            aria-disabled={disabled}
                            className={styles.colorButtonItemWrapper}
                          >
                            <ColorToggleButton
                              disabled={disabled}
                              key={color}
                              color={color}
                              selectedColor={selectedColor}
                              onClick={() => setSelectedColor(color)}
                              {...commonColorButtonClassNames}
                            />
                          </Box>
                        );
                      })}
                    </>
                  )}
                  <Box alignSelf="center">
                    <IconButton
                      size="small"
                      className={styles.addColor}
                      data-testid="create-defects-custom-color"
                      onClick={handleClick}
                      aria-owns={showColorPicker ? 'colorpicker-click-popover' : undefined}
                      aria-haspopup="true"
                    >
                      <AddCircleOutlineOutlined />
                    </IconButton>
                    <Popover
                      id="colorpicker-click-popover"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={showColorPicker}
                      anchorEl={anchorEl}
                      disableEnforceFocus
                    >
                      <ColorPicker
                        color={newColor}
                        onChange={(color: ColorResult) => {
                          setNewColor(color.hex.toUpperCase());
                        }}
                        onClose={handleAddNewColor}
                      />
                    </Popover>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            id="discard-save-class-button"
            color="primary"
            variant="text"
            className={styles.button}
            onClick={async () => {
              await onDiscardButtonClick?.();
              setAnchorEl(null);
              onClose();
            }}
          >
            {t('Discard')}
          </Button>
          <Button
            id="save-class-button"
            color="primary"
            variant="contained"
            className={styles.saveButton}
            onClick={onSaveButtonClick}
            disabled={isDisabledCreate || disableSaveButton}
          >
            {isCreating && (
              <CircularProgress color="inherit" size={14} style={{ marginRight: 4 }} />
            )}
            {t('Save {{propertyName}}', {
              propertyName,
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

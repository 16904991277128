import ExampleProjectAPI from '../../api/example_project_api';
import fetchHookFactory from './fetchHookFactory';
import { ProjectId } from '@clef/shared/types';

export const [useExampleProjectsList] = fetchHookFactory(
  async () => ExampleProjectAPI.getExampleProjectsList(),
  'cache',
);

export const [useExampleProjectDetails] = fetchHookFactory(async (exampleProjectId: ProjectId) => {
  const res = await ExampleProjectAPI.getExampleProjectDetails(exampleProjectId);
  return res.data;
}, 'always-refresh');

export const [useExampleProjectTrainingData] = fetchHookFactory(
  async (exampleProjectId: ProjectId) => {
    const res = await ExampleProjectAPI.getExampleProjectTrainingData(exampleProjectId);
    return res.data;
  },
  'cache',
);

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  projectsToolbarContainer: {
    display: 'flex',
    position: 'absolute',
    top: -60,
    right: 0,
  },
  projectToolbarAutocomplete: {
    marginRight: theme.spacing(5),
  },
  searchIcon: {
    color: theme.palette.grey[500],
    marginRight: theme.spacing(1),
  },
  projectToolbarQueryTagRoot: {
    backgroundColor: 'rgba(239, 247, 255, 1)',
  },
  projectToolbarQueryTagSizeSmall: {
    fontWeight: 'bold',
  },
  projectToolbarPopupBox: {
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),
  },
  projectToolbarPopupGroup: {
    '&:not(:last-child)': {
      paddingBottom: theme.spacing(3),
    },
  },
  projectToolbarPopupGroupItemsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  projectToolbarPopupGroupHeader: {
    fontWeight: 'normal',
    paddingBottom: theme.spacing(2),
    fontSize: 12,
    color: theme.palette.greyModern[500],
  },
  projectToolbarAutocompleteOption: {
    backgroundColor: 'rgba(239, 247, 255, 1)',
    fontSize: 12,
    borderRadius: 8,
    margin: theme.spacing(0.5, 1),
    height: 21,
    fontWeight: 'bold',
    '&[data-focus="true"]': {
      backgroundColor: 'rgba(239, 247, 255, 1)',
    },
  },
  projectToolbarAutocompleteInput: {
    paddingRight: theme.spacing(8),
  },

  projectToolbarSearchTextField: {
    borderRadius: 6,
    minWidth: 540,
    height: 36,
    background: theme.palette.greyModern[25],
  },
  projectGridSearchInput: {
    padding: '9px 0 9px 14px',
  },
  projectToolbarEndAdornment: {
    right: 14,
  },
  projectGridSortingDropDownButton: {
    whiteSpace: 'nowrap',
    minWidth: 220,
    height: 36,
    color: theme.palette.grey[600],
    borderColor: theme.palette.grey[400],
    background: theme.palette.greyModern[25],
    '&:hover': {
      background: 'rgba(0,0,0,0.04)',
    },
  },
  projectsEmptyStateContainer: {
    padding: theme.spacing(6),
    height: 100,
  },
  projectsEmptyStateText: {
    fontWeight: 500,
  },
  emptyStateContainer: {
    height: '70vh',
  },
  emptyStateText: {
    marginBottom: theme.spacing(5),
  },
  workflowTutorialBox: {
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(7),
    border: `1px solid ${theme.palette.greyModern[300]}`,
  },
  workflowTutorialBoxContainer: {
    background: theme.palette.indigoBlue[25],
    borderRadius: 20,
    padding: 68,
    position: 'relative',
  },
  workflowTutorialBoxStartButton: {
    borderRadius: 20,
    background: theme.palette.blue[100],
    width: 180,
    height: 64,
    color: theme.palette.blue[900],
    fontSize: 18,
  },
  workflowTutorialBoxCloseButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  emptyStateTitle: {
    fontWeight: 500,
    width: 700,
    textAlign: 'center',
    marginBottom: theme.spacing(5),
  },
  emptyStateDescription: {
    fontSize: 16,
    fontWeight: 400,
    width: 700,
    textAlign: 'center',
  },
}));

export default useStyles;

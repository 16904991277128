import React, { useMemo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { useTypographyStyles } from '@clef/client-library/src/themes/typography';
import classNames from 'classnames';
import { PulseWrapper } from '@clef/client-library';
import {
  useHasTrained,
  useLabeledClassIds,
  useStartTrain,
  useZeroAuthInstantLearningState,
} from './state';

const useStyles = makeStyles(theme => ({
  trainButtonWrapper: {
    position: 'absolute',
    left: '50%',
    bottom: 0,
    transform: 'translate(-50%, 50%)',
    zIndex: 2,
  },
  trainButton: {
    height: 180,
    width: 180,
    borderRadius: '50%',
    alignItems: 'flex-start',
    paddingTop: 42,
    color: 'white',
    transition: '0.3s!important',
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: theme.palette.greyModern[300],
      color: theme.palette.greyModern[400],
    },
  },
  pulseWrapper: {
    borderRadius: '50%',
  },
}));

export type TrainButtonProps = {
  disabled?: boolean;
};

const TrainButton: React.FC<TrainButtonProps> = ({ disabled = false }) => {
  const styles = useStyles();
  const typographyStyles = useTypographyStyles();
  const newLabeledClassIds = useLabeledClassIds('new_labels_only');
  const allLabeledClassIds = useLabeledClassIds('all');
  const hasTrained = useHasTrained();
  const { state } = useZeroAuthInstantLearningState();
  const { training, countDown } = state;

  const canTrain = useMemo(() => {
    if (training || countDown > 0) {
      return false;
    }
    // need to label two classes to train
    if (!hasTrained) {
      return newLabeledClassIds.size >= 2;
    }
    // if trained before, need at least one new label, and at least 2 labels through all images
    return newLabeledClassIds.size > 0 && allLabeledClassIds.size >= 2;
  }, [allLabeledClassIds.size, hasTrained, newLabeledClassIds.size, training, countDown]);

  const startTrain = useStartTrain();

  const disableTrainButton = disabled || !canTrain;

  return (
    <Box className={styles.trainButtonWrapper}>
      <PulseWrapper enabled={!disableTrainButton} className={styles.pulseWrapper} pulseSize={35}>
        <Button
          id="zero-auth-train-button"
          className={classNames(typographyStyles.h1_semibold, styles.trainButton)}
          color="primary"
          variant="contained"
          disabled={disableTrainButton}
          onClick={startTrain}
        >
          {training ? t('Running') : t('Run')}
          {countDown > 0 && t(' ({{countDown}})', { countDown })}
        </Button>
      </PulseWrapper>
    </Box>
  );
};

export default TrainButton;

import { Grid, makeStyles } from '@material-ui/core';
import { Button } from '@clef/client-library';
import React, { FunctionComponent } from 'react';
import CLPAPI from '../../../api/clp_api';
import { isCypressEnv } from '@clef/client-library/src/utils/env';
import { Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  text: {
    marginBottom: theme.spacing(6),
  },
  confirmButton: {
    marginLeft: theme.spacing(3),
  },
}));

export const downloadDevice = async (key: string) => {
  const { url } = await CLPAPI.downloadApp(key);
  if (!isCypressEnv()) {
    window.open(url);
  }
};

interface DownloadLandingEdgeContentProps {
  onDownload: () => void;
}

const DownloadLandingEdgeContent: FunctionComponent<DownloadLandingEdgeContentProps> = ({
  onDownload,
}) => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const handleDownload = async (deviceType: string) => {
    try {
      await downloadDevice(deviceType);
      onDownload();
    } catch (e) {
      enqueueSnackbar(t('Could not download at this moment, {{error}}', { error: e.message }), {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Typography className={styles.text}>{t('Select your operating system..')}</Typography>
      <Grid container justifyContent="flex-end">
        <Button
          id="download-landingedge-confirm-dialog-windows-button"
          color="primary"
          variant="contained"
          className={styles.confirmButton}
          onClick={() => handleDownload('greenfield_win10_amd64')}
        >
          {t('Download for Windows')}
        </Button>
        <Button
          id="download-landingedge-confirm-dialog-linux-button"
          color="primary"
          variant="contained"
          className={styles.confirmButton}
          onClick={() => handleDownload('linux_amd64')}
        >
          {t('Download for Linux')}
        </Button>
      </Grid>
    </>
  );
};

export default DownloadLandingEdgeContent;

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Grow, Tooltip, LinearProgress, Chip, Box } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import CloseRounded from '@material-ui/icons/CloseRounded';
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded';
import CancelRounded from '@material-ui/icons/CancelRounded';
import PhotoLibrary from '@material-ui/icons/PhotoLibrary';
import { useUploadStyles } from '../styles';
import { deleteFile } from '../../../../store/uploadState';
import { UploadFailureReason, UploadFile, UploadStatus } from '../../../../store/uploadState/types';
import { useAppDispatch } from '../../../../store';

export interface PreviewMediaProps {
  uploadFile: UploadFile;
  onFileDelete?: (file: UploadFile) => any;
}

const PreviewMedia: React.FC<PreviewMediaProps> = ({ uploadFile, onFileDelete }) => {
  const [imgSrc, setImgSrc] = useState<string | null>();
  const dispatch = useAppDispatch();
  const styles = useUploadStyles();
  const [deleting, setDeleting] = useState(false);

  const onDelete = useCallback(() => {
    setDeleting(true);
    // wait for animation to finish
    setTimeout(() => {
      if (onFileDelete) {
        onFileDelete(uploadFile);
      } else {
        dispatch(deleteFile(uploadFile.key));
      }
    }, 360);
  }, [onFileDelete, uploadFile, dispatch]);

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = function (e) {
      setImgSrc(e?.target?.result?.toString());
    };
    // you have to declare the file loading
    reader.readAsDataURL(uploadFile.file);
  }, [uploadFile]);

  const { file, status, progress, failureReason, initialLabel } = uploadFile;

  const failMessage = useMemo(() => {
    if (status !== UploadStatus.Failure) return '';
    if (failureReason === UploadFailureReason.Duplicated) {
      return t('This media already exists in the project.');
    }
    if (failureReason === UploadFailureReason.InvalidCharInFileName) {
      return t('Media name contains invalid ({}^%`[]<>~()#|&\'").');
    }
    return failureReason ?? t('Upload failed. Please retry.');
  }, [failureReason, status]);

  if (imgSrc) {
    return (
      <Grow in={!deleting}>
        <Tooltip
          title={
            failMessage ? (
              <>
                {t('{{name}} failed to upload: ', {
                  name: file.name,
                })}
                {failMessage === "We only allow 0 for 'ok'." ? (
                  <div>
                    <div>
                      {t(
                        'In your Defect Map, the value of 0 must be “ok”. “0” is used to identify the background; in other words, areas that do not have Classes applied to them. The value “ok” will not be created as a Class.',
                      )}
                    </div>
                    <div>{t('Update your Defect Map, and re-upload your images.')}</div>
                  </div>
                ) : (
                  failMessage
                )}
              </>
            ) : (
              file.name
            )
          }
          arrow
          placement="top"
          // disable portal to make the tooltip not covered by high z-index upload dialog.
          PopperProps={{
            disablePortal: true,
            // use "window" as the boundary to calculate placement. Ref: https://stackoverflow.com/a/64220678/8830074
            popperOptions: {
              modifiers: {
                preventOverflow: {
                  boundariesElement: 'window',
                },
              },
            },
          }}
        >
          <div className={styles.previewMedia} data-testid="preview-media">
            <div className={styles.previewMediaToolTip}>
              <img src={imgSrc} />
              {status === UploadStatus.Success && (
                <CheckCircleRounded
                  fontSize="small"
                  className={styles.previewMediaSuccess}
                  aria-label="success-media-upload"
                />
              )}
              <div
                className={styles.postRequestMask}
                style={{
                  opacity:
                    status === UploadStatus.Uploading || status === UploadStatus.Pending ? 1 : 0,
                }}
              >
                {status === UploadStatus.Uploading && (
                  <LinearProgress value={progress} variant="determinate" />
                )}
              </div>
              {status === UploadStatus.NotStarted && (
                <IconButton
                  size="small"
                  className={styles.previewMediaClose}
                  onClick={onDelete}
                  id="preview-media-close"
                  aria-label="preview-media-close"
                >
                  <CloseRounded style={{ fontSize: '1rem' }} />
                </IconButton>
              )}
              {Boolean(initialLabel?.objectDetection) && (
                <Box title={t('Pascal Voc')} data-testid="preview-media-name-chip">
                  <Chip size="small" label={t('Pascal Voc')} className={styles.tagLeftCorner} />
                </Box>
              )}
            </div>
            {status === UploadStatus.Failure &&
              (failureReason === UploadFailureReason.Duplicated ? (
                <PhotoLibrary fontSize="small" className={styles.previewMediaFailure} />
              ) : failureReason === UploadFailureReason.InvalidCharInFileName ? (
                <CancelRounded fontSize="small" className={styles.previewMediaFailure} />
              ) : (
                <CancelRounded fontSize="small" className={styles.previewMediaFailure} />
              ))}
          </div>
        </Tooltip>
      </Grow>
    );
  }

  return null;
};

export default PreviewMedia;

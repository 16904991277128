import { ERROR_SNACKBAR_STYLE, SUCCESS_SNACKBAR_STYLE } from '@/constants/metadata_constants';
import { useSnackbar } from 'notistack';
import React from 'react';

interface Props {
  text: string;
  successMessage?: string;
  failedMessage?: string;
}

export const useCopyToClipboard = (props: Props) => {
  const { text, successMessage, failedMessage } = props;
  const { enqueueSnackbar } = useSnackbar();

  const handleSuccess = React.useCallback(
    () => enqueueSnackbar(successMessage ?? t('Copied to clipboard'), SUCCESS_SNACKBAR_STYLE),
    [enqueueSnackbar, successMessage],
  );

  const handleError = React.useCallback(
    () => enqueueSnackbar(failedMessage ?? t('Copy to clipboard failed'), ERROR_SNACKBAR_STYLE),
    [enqueueSnackbar, failedMessage],
  );

  // Checkout https://stackoverflow.com/a/30810322 to see why this seems to be overly complicated
  // TL;DR: Security concerns and browser support of newer APIs
  const fallbackCopyTextToClipboard = React.useCallback(
    (text: string) => {
      var textArea = document.createElement('textarea');
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        handleSuccess();
      } catch (err) {
        handleError();
      }
      document.body.removeChild(textArea);
    },
    [handleSuccess, handleError],
  );

  const copyTextToClipboard = React.useCallback(
    () =>
      navigator.clipboard
        ? navigator.clipboard.writeText(text).then(handleSuccess, handleError)
        : fallbackCopyTextToClipboard(text),
    [fallbackCopyTextToClipboard, handleSuccess, handleError, text],
  );

  return copyTextToClipboard;
};

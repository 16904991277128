import React, { useState } from 'react';
import { TextField, ClickAwayListener, makeStyles } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';

import { Typography } from '@clef/client-library';

import { useSaveModelMutation } from '@/serverStore/projectModels';

const useStyles = makeStyles(theme => ({
  modelNameContainer: {
    marginBottom: theme.spacing(5),
  },
  modelNameText: {
    marginRight: theme.spacing(2),
  },
}));

interface ModelNameEditFieldProps {
  initialModelName: string;
  modelId: string;
}

const ModelNameEditField = (props: ModelNameEditFieldProps) => {
  const { initialModelName, modelId } = props;
  const [modelName, setModelName] = useState(initialModelName);
  const [isEditing, setIsEditing] = useState(false);
  const [modelNameError, setModelNameError] = useState('');

  const styles = useStyles();

  const saveModel = useSaveModelMutation();

  const onSaveModelClick = () => {
    if (!modelName) {
      setModelNameError('This is required.');
    } else {
      setIsEditing(false);
    }
    saveModel.mutate(
      {
        id: modelId,
        modelName,
      },
      {
        onError: () => setModelName(initialModelName),
      },
    );
  };

  return (
    <>
      {isEditing ? (
        <>
          <ClickAwayListener
            onClickAway={() => {
              if (!modelName) {
                setModelNameError('This is required.');
              } else {
                setIsEditing(false);
              }
            }}
          >
            <TextField
              variant="outlined"
              value={modelName}
              size="small"
              className={styles.modelNameText}
              onChange={e => {
                setModelName(e.target.value as string);
              }}
              error={!!modelNameError}
              helperText={modelNameError}
              autoFocus
            />
          </ClickAwayListener>

          <IconButton id="model-details-save-model-name" size="small" onClick={onSaveModelClick}>
            <CheckIcon />
          </IconButton>
        </>
      ) : (
        <>
          <Typography variant="h1" className={styles.modelNameText} maxWidth={600}>
            {modelName || t('Untitled model')}
          </Typography>

          <IconButton
            id="model-details-edit-model-name"
            size="small"
            onClick={() => {
              setIsEditing(true);
            }}
          >
            <EditIcon />
          </IconButton>
        </>
      )}
    </>
  );
};

export default ModelNameEditField;

import React from 'react';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { useLabelingTaskState } from '../labelingTaskState';
import { useLabelingTaskStyles } from '../labelingTaskStyles';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import RelativeDateTime from '../../../components/RelativeDateTime/RelativeDateTime';

export interface AutoSaveInfoProps {}

const AutoSaveInfo: React.FC<AutoSaveInfoProps> = () => {
  const {
    state: { autoSaveStatus, lastSavedTime },
  } = useLabelingTaskState();
  const styles = useLabelingTaskStyles();
  return (
    <Grid container alignItems="center" justifyContent="flex-end" item>
      {autoSaveStatus === 'saving' && (
        <>
          <CircularProgress size={16} />
          <Typography className={styles.autoSaveCaption} variant="caption">
            {t('saving progress...')}
          </Typography>
        </>
      )}
      {autoSaveStatus === 'saved' && lastSavedTime && (
        <>
          <CheckCircleRoundedIcon className={styles.checkIcon} />
          <Typography className={styles.autoSaveCaption} variant="caption">
            <RelativeDateTime prefixText={t('progress saved - ')}>
              {lastSavedTime.toString()}
            </RelativeDateTime>
          </Typography>
        </>
      )}
      {autoSaveStatus === 'save-error' && (
        <>
          <ErrorRoundedIcon className={styles.errorIcon} />
          <Typography className={styles.autoSaveCaption} variant="caption">
            {t('auto save failed.')}
          </Typography>
        </>
      )}
    </Grid>
  );
};

export default AutoSaveInfo;

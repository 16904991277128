import { makeStyles } from '@material-ui/core';

export const useLabelingDrawerStyles = makeStyles(theme => ({
  drawer: {
    width: '520px',
    zIndex: theme.zIndex.modal + 1,
    borderRadius: 0,
  },
  instantLearningDrawer: {
    top: '100px',
    width: '520px',
    zIndex: theme.zIndex.modal + 1,
    borderRadius: 0,
  },
  drawerContent: {
    padding: theme.spacing(8, 7, 7, 7),
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: theme.spacing(1),
    color: theme.palette.grey[800],
  },
  closeIcon: {
    fontSize: '22px',
  },
  actionIconButton: {
    width: '32px',
    height: '32px',
    borderRadius: '10px',
  },
  actionIconButtonMarginRight: {
    marginRight: theme.spacing(2),
  },
  defectName: {
    fontSize: '20px',
    fontWeight: 500,
  },
  defectColor: {
    width: 32,
    height: 32,
    borderRadius: 6,
    marginRight: theme.spacing(4),
  },
  markdownText: {
    ...theme.typography.body1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  bottomLinkContainer: {
    ...theme.typography.body1,
    textAlign: 'right',
    marginTop: theme.spacing(5),
  },
  drawerTitle: {
    marginBottom: theme.spacing(7),
  },
  terminologyExampleContainer: {
    borderRadius: 8,
    borderColor: theme.palette.grey[300],
    borderWidth: 1,
    borderStyle: 'solid',
    overflow: 'hidden',
  },
  terminologyExample: {
    padding: theme.spacing(0, 3),
  },
  terminologyMarkdown: {
    ...theme.typography.body1,
  },
  iconImg: {
    width: 12,
    height: 12,
    marginTop: theme.spacing(1.5),
  },
  keyJoin: {
    margin: theme.spacing(0, 1),
  },
  code: {
    backgroundColor: theme.palette.grey[100],
    height: '24px',
    lineHeight: '24px',
    fontWeight: 'bold',
    minWidth: '24px',
    textAlign: 'center',
    borderRadius: 4,
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    boxSizing: 'border-box',
  },
  codeIcon: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  hotKeySection: {
    margin: theme.spacing(3, 0, 2, 0),
    fontSize: theme.typography.body1.fontSize,
  },
  hotKeySectionTitle: {
    marginBottom: theme.spacing(2),
  },
  hotKeyItem: {
    padding: theme.spacing(1.5, 0),
  },
  divider: {
    margin: theme.spacing(3, 0, 5, 0),
  },
  defectUpdateDateBox: {
    backgroundColor: '#F8FAFC',
    borderRadius: '5px',
    marginTop: '16px',
    padding: theme.spacing(1),
  },
  defectUpdateDateDescription: {
    lineHeight: '20px',
  },
  actionIconImg: {
    height: '16px',
    width: '16px',
  },
}));

import { Box, Tooltip, makeStyles } from '@material-ui/core';
import useStyles from './styles';
import React from 'react';
import { Typography } from '@clef/client-library';

const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
  },
}));

const CommonBlockHeader = ({ title, tooltip }: { title: string; tooltip: string }) => {
  const styles = useStyles();
  const tooltipClasses = useStylesBootstrap();
  return (
    <Tooltip
      arrow
      classes={tooltipClasses}
      title={
        <Box className={styles.tooltipBox}>
          <Typography variant="body1" className={styles.tooltipTitle}>
            {title}
          </Typography>
          <Typography variant="body1" className={styles.tooltipContent}>
            {tooltip}
          </Typography>
        </Box>
      }
    >
      <span className={styles.cardTitle}>{title}</span>
    </Tooltip>
  );
};

export default CommonBlockHeader;

import React from 'react';
import { Box, BoxProps } from '@material-ui/core';

const DEFAULT_ANIMATIONT_DURATION = 0.5;

export interface AnimatedBoxProps extends BoxProps {
  mounted?: boolean;
  open?: boolean;
  animationDuration?: number;
}

export const AnimatedBox: React.FC<AnimatedBoxProps> = ({
  mounted,
  open,
  animationDuration = DEFAULT_ANIMATIONT_DURATION,
  children,
  ...rest
}) => {
  if (!mounted) {
    return null;
  }

  return (
    <Box
      display={mounted ? 'flex' : 'none'}
      justifyContent="center"
      alignItems="center"
      style={{
        opacity: open ? 1 : 0,
        width: mounted ? 'auto' : 0,
        transition: `opacity ${animationDuration}s linear`,
        ...rest.style,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default AnimatedBox;

import { Draft } from 'immer';
import { useContext, createContext } from 'react';

export type ImageEnhancerState = {
  brightness: number;
  contrast: number;
  histogramEnabled: boolean;
  autoContrastEnabled: boolean;
};

export const initialState: ImageEnhancerState = {
  brightness: 0,
  contrast: 0,
  histogramEnabled: false,
  autoContrastEnabled: false,
};

/**
 * Context
 */
export const ImageEnhancerStateContext = createContext<{
  state: ImageEnhancerState;
  dispatch: (f: (state: Draft<ImageEnhancerState>) => void | ImageEnhancerState) => void;
}>({
  state: initialState,
  dispatch: () => {},
});

export const useImageEnhancerState = () => useContext(ImageEnhancerStateContext);

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AppBar, Typography, Grid, Badge, Tooltip, CircularProgress } from '@material-ui/core';

import { useLabelingTaskStyles } from './labelingTaskStyles';
import { ApiResponse, Task } from '@clef/shared/types';
import {
  postTaskCompleteBatchApi,
  refreshTaskInfo,
  refreshUserTaskMediaLabelBatch,
  refreshUserTaskStats,
  refreshUseMyTask,
} from '../../hooks/api/useTaskApi';
import {
  useGoToPrevMedia,
  useGoToNextMedia,
  useLabelingTaskState,
  useUpsertMediaLabelToServer,
} from './labelingTaskState';
import { useKeyPress, usePrevious, useWindowEventListener } from '@clef/client-library';
import { usePageLayoutState } from '../../components/Layout/PageLayout/state';
import AutoSaveInfo from './components/AutoSaveInfo';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import KeyboardOutlined from '@material-ui/icons/KeyboardOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton, Button } from '@clef/client-library';
import { useLabelingDrawer } from '../../components/Labeling/LabelingDrawer';
import { useSnackbar } from 'notistack';
import CLEF_PATH from '../../constants/path';
import {
  useLabelingState,
  useUnsavedLabelCheckDecorator,
} from '../../components/Labeling/labelingState';
import { getLabelingTaskHotKeySections } from '../../components/Labeling/drawer/HotKeysDrawer';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useQueryClient } from '@tanstack/react-query';
import { datasetQueryKeys } from '@/serverStore/dataset';
import { layoutQueryKeys } from '@/serverStore/layout';

export interface LabelingTaskHeaderProps {
  taskInfo: Task;
}

const LabelingTaskHeader: React.FC<LabelingTaskHeaderProps> = ({ taskInfo }) => {
  const styles = useLabelingTaskStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmittingResult, setIsSubmittingResult] = useState(false);
  const { dispatch: dispatchPageLayout } = usePageLayoutState();
  const {
    dispatch,
    state: { annotationData, currentMediaId, autoSaveStatus, taskMediaList },
  } = useLabelingTaskState();
  const {
    state: { labelingType },
  } = useLabelingState();
  const selectedProject = useGetSelectedProjectQuery().data;

  const labeledMedia = Object.entries(annotationData)
    .filter(([_, annotations]) => !!annotations)
    .map(([mediaId]) => Number(mediaId));

  useEffect(() => {
    dispatchPageLayout(draft => {
      draft.metaTitle = `Labeling Task - ${taskInfo.taskName}`;
    });
  }, [dispatchPageLayout, taskInfo.taskName]);

  // shortcuts
  const handleGoToNextMedia = useGoToNextMedia();
  const handleGoToPrevMedia = useGoToPrevMedia();
  const goToNextMedia = useUnsavedLabelCheckDecorator(handleGoToNextMedia);
  const goToPrevMedia = useUnsavedLabelCheckDecorator(handleGoToPrevMedia);

  useKeyPress('up,left', goToPrevMedia, { id: 'task-prev-media' });
  useKeyPress('down,right', goToNextMedia, { id: 'task-next-media' });

  const { toggleLabelingInstructionsDrawer, toggleHotKeysDrawer } = useLabelingDrawer({
    hotKeySections: getLabelingTaskHotKeySections(labelingType),
  });

  useKeyPress('i', toggleLabelingInstructionsDrawer, {
    id: 'task-toggle-labeling-instruction',
  });
  useKeyPress('/', toggleHotKeysDrawer, {
    id: 'task-toggle-hotkey',
  });

  const goBack = useCallback(() => {
    history.push(CLEF_PATH.myTasks);
  }, [history]);

  const handleTaskCompleteResponse = useCallback(
    (response: ApiResponse<{ nothingLeftToFetch: boolean }>) => {
      if (response.data.nothingLeftToFetch) {
        refreshTaskInfo({ keys: 'refresh-all' });
        goBack();
      } else {
        refreshUserTaskMediaLabelBatch({ keys: 'refresh-all' });
        refreshUserTaskStats({ keys: 'refresh-all' });
        dispatch(draft => {
          draft.currentMediaId = 0;
        });
      }
    },
    [dispatch, goBack],
  );

  // save unsaved annotations in queue when switch media
  const upsertMediaLabel = useUpsertMediaLabelToServer();
  const prevMediaId = usePrevious(currentMediaId);
  useEffect(() => {
    if (prevMediaId && prevMediaId !== currentMediaId) {
      upsertMediaLabel(prevMediaId);
    }
  }, [prevMediaId, currentMediaId, upsertMediaLabel]);

  // auto save before leave page
  // Note: goBack() also triggers this event
  useWindowEventListener('beforeunload', e => {
    if (labeledMedia.length) {
      upsertMediaLabel('all');
      e.preventDefault();
      e.returnValue = 'Are you sure you want to leave? Some changes may be lost.';
    }
  });

  const handleGoBack = useCallback(async () => {
    await upsertMediaLabel('all');
    goBack();
  }, [goBack, upsertMediaLabel]);
  const onGoBack = useUnsavedLabelCheckDecorator(handleGoBack);
  const queryClient = useQueryClient();

  const handleSubmit = useCallback(async () => {
    setIsSubmittingResult(true);
    try {
      // ensure annotations in the last media is saved
      await upsertMediaLabel('all');
      const response = await postTaskCompleteBatchApi(taskInfo.id, labeledMedia);
      selectedProject?.datasetId &&
        queryClient.invalidateQueries(datasetQueryKeys.mediaDetails(selectedProject?.datasetId));
      selectedProject?.id &&
        queryClient.invalidateQueries(datasetQueryKeys.allWithFilters(selectedProject.id));
      handleTaskCompleteResponse(response);
      selectedProject?.id &&
        queryClient.invalidateQueries(layoutQueryKeys.list(selectedProject.id));
      refreshUseMyTask({ keys: 'refresh-all' });
    } catch (e) {
      enqueueSnackbar(t(`Failed to save annotations: ${e.message}`), {
        variant: 'error',
        autoHideDuration: 8000,
      });
    } finally {
      setIsSubmittingResult(false);
    }
  }, [enqueueSnackbar, handleTaskCompleteResponse, labeledMedia, taskInfo.id, upsertMediaLabel]);
  const onSubmit = useUnsavedLabelCheckDecorator(handleSubmit);

  return (
    <>
      {isSubmittingResult && <div className={styles.postRequestMask}></div>}
      <AppBar elevation={0} className={styles.appBar} color="default" position="fixed">
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          wrap="nowrap"
          alignItems="center"
          data-testid="labeling-task-header"
        >
          <Grid
            item
            container
            justifyContent="flex-start"
            direction="row"
            wrap="nowrap"
            alignItems="center"
          >
            <IconButton disabled={autoSaveStatus === 'saving'} onClick={onGoBack} id="task-go-back">
              {autoSaveStatus === 'saving' ? <CircularProgress size="24px" /> : <ArrowBackIcon />}
            </IconButton>
            <Typography variant="h4" style={{ marginLeft: 8 }}>
              {taskInfo.taskName}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            direction="row"
            wrap="nowrap"
            alignItems="center"
          >
            <Typography variant="h4" className={styles.appBarTaskProgress}>
              {t('Labeled:')}
            </Typography>
            <Typography variant="h2" title="labeled media count">
              {labeledMedia.length}
            </Typography>
            <Typography variant="h4" className={styles.appBarTaskProgress}>
              {t('Not labeled:')}
            </Typography>
            <Typography variant="h2" title="unlabeled media count">
              {taskMediaList.length - labeledMedia.length}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            direction="row"
            wrap="nowrap"
            alignItems="center"
          >
            <AutoSaveInfo />
            <Tooltip
              title={t('Hot keys {{hotkey}}', {
                hotkey: <span className={styles.codeBlock}>/</span>,
              })}
            >
              <IconButton
                role="button"
                onClick={toggleHotKeysDrawer}
                aria-label="toggle hot keys"
                id="task-toggle-hotkey"
              >
                <KeyboardOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t('Labeling instructions {{hotkey}}', {
                hotkey: <span className={styles.codeBlock}>i</span>,
              })}
            >
              <IconButton
                role="button"
                onClick={toggleLabelingInstructionsDrawer}
                aria-label="toggle labeling instructions"
                id="task-toggle-labeling-instruction"
              >
                <DescriptionOutlined />
              </IconButton>
            </Tooltip>
            <Badge color="secondary" badgeContent={labeledMedia.length}>
              <Button
                color="primary"
                variant="contained"
                disabled={!labeledMedia.length}
                className={styles.actionButton}
                id="task-submit"
                onClick={onSubmit}
              >
                {t('Submit')}
              </Button>
            </Badge>
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
};

export default LabelingTaskHeader;

import { Draft } from 'immer';
import { useContext, createContext, useMemo, useCallback } from 'react';
import {
  Defect,
  SegmentationAnnotationData,
  LabelingType,
  BoundingBoxAnnotationData,
  ClassificationAnnotationData,
  AnnotationType,
} from '@clef/shared/types';
import { ZoomScale } from '@clef/client-library';
import { useDefectSelectorWithArchived } from '../../store/defectState/actions';
import { getDefectColor } from '../../utils';

export enum ToolMode {
  Brush = 'brush',
  Polygon = 'polygon',
  Polyline = 'polyline',
  Box = 'box',
  Quick = 'quick',
}

export enum TutorialStep {
  Polygon = 'Polygon',
  Zoom = 'Zoom',
}

export type AnnotationWithId = {
  defectId: number;
  id: string;
  selected?: boolean;
  hovered?: boolean;
  color?: string;
};

export type BitMapLabelingAnnotation = {
  data: SegmentationAnnotationData;
  annotationType?: AnnotationType;
} & AnnotationWithId;

export type BoxLabelingAnnotation = {
  data: BoundingBoxAnnotationData;
  confidence?: number;
  annotationType?: AnnotationType;
} & AnnotationWithId;

export type ClassificationLabelingAnnotation = {
  data: ClassificationAnnotationData;
} & AnnotationWithId;

export type PureCanvasLabelingAnnotation = {
  data: OffscreenCanvas;
} & AnnotationWithId;

export type LabelingState = {
  // labeling type
  labelingType:
    | LabelingType.DefectBoundingBox
    | LabelingType.DefectSegmentation
    | LabelingType.DefectClassification;
  // tool mode - brush / polygon / polyline / auto segmentation
  toolMode?: ToolMode;
  // tool options used with the mode
  toolOptions: {
    // stroke width for brush / polygon
    strokeWidth: number;
    // if the tool is erasing vs drawing
    erasing: boolean;
    // stroke width for erasing
    eraserWidth: number;
    // zoom scale
    zoomScale: ZoomScale;
    // zoom scale when auto fit
    fitZoomScale: number;
  };
  // the selected defect
  selectedDefect?: Defect;
  // any menu opened
  anyMenuOpened?: boolean;
  // tutorial anchors
  tutorialAnchorElementsMap: { [step in TutorialStep]?: HTMLElement };
  // hide labels
  hideLabels: boolean;
  // is drawing in progress;
  isDrawing: boolean;
  // show heatmap
  showHeatmap: boolean;
  // is creating defect
  isCreatingDefect: boolean;
  // user has unsaved label (currently, only SAM mask can be unsaved)
  haveUnsavedLabel: boolean;
  // show the dialog to confirm the unsaved label (currently, only SAM mask can be unsaved)
  showUnsavedLabelConfirmationDialog: boolean;
};

export const defaultState: LabelingState = {
  labelingType: LabelingType.DefectSegmentation,
  toolOptions: {
    strokeWidth: 8,
    eraserWidth: 8,
    erasing: false,
    zoomScale: 'fit',
    fitZoomScale: 0,
  },
  tutorialAnchorElementsMap: {},
  hideLabels: false,
  isDrawing: false,
  showHeatmap: false,
  isCreatingDefect: false,
  haveUnsavedLabel: false,
  showUnsavedLabelConfirmationDialog: false,
};

/**
 * Context
 */
export const LabelingContext = createContext<{
  state: LabelingState;
  dispatch: (f: (state: Draft<LabelingState>) => void | LabelingState) => void;
}>({
  state: defaultState,
  dispatch: () => {},
});

export const useLabelingState = () => useContext(LabelingContext);

export const useColorToDefectIdMap = () => {
  const allDefects = useDefectSelectorWithArchived();
  const colorToDefectIdMap = useMemo(() => {
    return (allDefects ?? []).reduce(
      (acc, defect) => ({
        ...acc,
        [getDefectColor(defect)]: defect.id,
      }),
      {} as { [color: string]: number },
    );
  }, [allDefects]);
  return colorToDefectIdMap;
};

export const useColorToDefectMap = () => {
  const allDefects = useDefectSelectorWithArchived();
  const colorToDefectIdMap = useMemo(() => {
    return (allDefects ?? []).reduce(
      (acc, defect) => ({
        ...acc,
        [getDefectColor(defect)]: defect,
      }),
      {} as { [color: string]: Defect },
    );
  }, [allDefects]);
  return colorToDefectIdMap;
};

export const useUnsavedLabelCheckDecorator = <ReturnValue>(callback: () => ReturnValue) => {
  const {
    state: { haveUnsavedLabel },
    dispatch,
  } = useLabelingState();

  return useCallback(() => {
    if (haveUnsavedLabel) {
      dispatch(draft => {
        draft.showUnsavedLabelConfirmationDialog = true;
      });

      return;
    }

    return callback();
  }, [callback, dispatch, haveUnsavedLabel]);
};

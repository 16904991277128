import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  defectColor: {
    width: 16,
    height: 16,
    borderRadius: 6,
    marginRight: theme.spacing(3),
  },
}));

export type DefectColorProps = {
  color: string;
};

const DefectColor: React.FC<DefectColorProps> = ({ color }) => {
  const styles = useStyles();
  return <div className={styles.defectColor} style={{ backgroundColor: color }} />;
};

export default DefectColor;

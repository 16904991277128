import { Button, Dropdown, SimpleBadge, usePrevious } from '@clef/client-library';
import { FilterOptionName, FilterOptionType, LabelType } from '@clef/shared/types';
import { makeStyles, Tooltip } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import React, { useEffect, useMemo, useRef } from 'react';
import { useGetDatasetFilterOptionsQuery } from '@/serverStore/dataset';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useDataBrowserState } from '../dataBrowserState';
import { useDataBrowserStyles } from '../styles';
import FiltersDropdown from './FiltersDropdown';
import FilterSvg from '../../../images/data-browser/filter.svg';

const useStyles = makeStyles(theme => ({
  clearFiltersIcon: {
    color: theme.palette.grey[500],
  },
}));

const MediaFilter: React.FC = () => {
  const styles = useStyles();
  const dataBrowserStyles = useDataBrowserStyles();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { data: allFilters } = useGetDatasetFilterOptionsQuery();
  const {
    state: { appliedFilters, viewMode },
    dispatch,
  } = useDataBrowserState();
  const labelType = selectedProject?.labelType;
  const SegmentationInstantLearningFilters: string[] = [
    FilterOptionName.MediaName,
    FilterOptionName.MediaId,
  ];

  const sanitizedFilters = useMemo(
    () =>
      (allFilters ?? []).filter(filter => {
        // TODO properly remove OK/NG filter
        if (filter.filterName.includes('OK/NG')) return false;
        // TODO properly remove media status filter
        if (viewMode === 'instance' && filter.filterName === 'Media status') return false;
        if (labelType === LabelType.SegmentationInstantLearning)
          return SegmentationInstantLearningFilters.includes(filter.filterName);
        return true;
      }),
    [allFilters, viewMode],
  );

  const prevAppliedMediaStatusFilter = useRef(appliedFilters[FilterOptionName.MediaStatus]);
  const prevViewMode = usePrevious(viewMode);
  if (viewMode === 'image' && prevViewMode !== 'instance') {
    prevAppliedMediaStatusFilter.current = appliedFilters[FilterOptionName.MediaStatus];
  }
  useEffect(() => {
    dispatch(draft => {
      // clear OK/NG filters if applied
      delete draft.appliedFilters[FilterOptionName.GroundTruthOkNg];
      delete draft.appliedFilters[FilterOptionName.PredictionOkNg];
      // handle Media status filter
      if (viewMode === 'instance') {
        // clear Media status filter when not available
        delete draft.appliedFilters[FilterOptionName.MediaStatus];
      } else {
        // if Media status filter previously applied in image view, add it back
        if (prevAppliedMediaStatusFilter.current) {
          draft.appliedFilters[FilterOptionName.MediaStatus] = prevAppliedMediaStatusFilter.current;
        }
      }
    });
  }, [dispatch, viewMode]);

  if (!sanitizedFilters.length) {
    return null;
  }

  const [defaultFilters, moreFilters] = sanitizedFilters.reduce(
    (acc, filterOption) => {
      acc[filterOption.displayDefault ? 0 : 1].push(filterOption);
      return acc;
    },
    [[], []] as [FilterOptionType[], FilterOptionType[]],
  );
  const appliedFilterCount = Object.keys(appliedFilters).filter(key => {
    const value = appliedFilters[key]?.v;
    return Array.isArray(value) ? value.length : value;
  }).length;

  const FiltersDropdownFilters: (FilterOptionType | 'divider')[] =
    defaultFilters.length === 0 ? [...moreFilters] : [...defaultFilters, 'divider', ...moreFilters];

  return (
    <Dropdown
      extraGutter={{ vertical: 12 }}
      dropdown={<FiltersDropdown filters={FiltersDropdownFilters} />}
    >
      <Button
        id="open-media-filter-button"
        color="primary"
        variant="outlined"
        className={dataBrowserStyles.toolbarButton}
        startIcon={
          appliedFilterCount ? (
            <SimpleBadge color="primary" content={appliedFilterCount} />
          ) : (
            <img src={FilterSvg} />
          )
        }
        endIcon={
          !!appliedFilterCount && (
            <Tooltip title={t('Clear filters')} arrow placement="top">
              <Cancel
                className={styles.clearFiltersIcon}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  dispatch(draft => {
                    draft.appliedFilters = {};
                  });
                }}
              />
            </Tooltip>
          )
        }
      >
        {t('Filter')}
      </Button>
    </Dropdown>
  );
};

export default MediaFilter;

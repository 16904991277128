import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import CLEF_PATH from '@/constants/path';
import IntroVideo from './IntroVideo';
import { useHistory } from 'react-router';
import ExamplesPng from '@/images/home/new_examples.png';
import { useIsFeatureEnabledAndMayHideForSnowflake } from '@/hooks/useFeatureGate';

const useStyles = makeStyles(theme => ({
  quickDemoCardContainer: {
    display: 'flex',
    borderRadius: 10,
    width: '66.7%',
    marginBottom: theme.spacing(10),
    maxWidth: 800,
  },
  quickDemoCardVideo: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: 10,
    position: 'relative',
    minWidth: 550,
    maxHeight: 295,
    boxShadow: '0px 40px 80px -20px rgba(0, 66, 218, 0.08)',
  },
  quickDemoCardDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4),
    marginRight: theme.spacing(7),
    minWidth: 220,
  },
  quickDemoCardDescriptionTitle: {
    marginBottom: theme.spacing(5),
    lineHeight: '24px',
  },
  examplesCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 370,
    maxWidth: 400,
    border: `1px solid ${theme.palette.greyModern[300]}`,
    borderRadius: 10,
    marginBottom: theme.spacing(10),
    overflow: 'hidden',
  },
  examplesCardImg: {
    transition: 'scale 0.25s',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      scale: 1.1,
    },
  },
  examplesCardText: {
    fontSize: 16,
    fontWeight: 500,
  },
}));

export const IntroSection: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const showExamplesPage = useIsFeatureEnabledAndMayHideForSnowflake().examplesPage;
  const isSnowflake = process.env.IS_SNOWFLAKE === 'true';
  const videoUrl = isSnowflake
    ? 'https://landing-lens-support.s3.us-east-2.amazonaws.com/video/snowflake_native_app_2mins.mp4'
    : 'https://landing-lens-support.s3.us-east-2.amazonaws.com/video/FRE+video_1920.mp4';
  return (
    <Box display="flex" width="100%" maxWidth={1200} flexWrap="wrap" justifyContent="center">
      <Box className={styles.quickDemoCardContainer}>
        <IntroVideo src={videoUrl} className={styles.quickDemoCardVideo} />

        <Box className={styles.quickDemoCardDescriptionContainer}>
          {isSnowflake ? (
            <Typography>
              {t(
                'Quick intro on using the LandingLens Native App for Snowflake & leveraging AI for your visual data.',
              )}
            </Typography>
          ) : (
            <Typography>
              {t('Get a quick intro to using LandingLens and leveraging AI for your visual data.')}
            </Typography>
          )}
        </Box>
      </Box>

      {showExamplesPage && (
        <Box
          className={styles.examplesCardContainer}
          onClick={() => history.push(CLEF_PATH.examples)}
        >
          <img src={ExamplesPng} className={styles.examplesCardImg} />

          <Box display="flex" alignItems="center" flex={1} minHeight={40}>
            <Typography className={styles.examplesCardText}>
              {t('Play with a few of our models')}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Typography, Grid, Box, Link } from '@material-ui/core';
import cx from 'classnames';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { formatEmail } from '@clef/shared/utils';

import { useStyles } from './styles';
import AuthAPI from '../../api/auth_api';
import AccountAPI from '../../api/account_api';
import {
  LandingLensLogo,
  ContinueWithEnterpriseSsoButton,
  ContinueWithGoogleButton,
  OrSeparator,
} from '../../components/Auth/Common';
import { BaseTextField, PasswordTextField } from '../../components/Auth/TextField';
import { TermsAndConditionsCheckbox } from '../../components/Auth/Checkbox';
import { PrimaryButton } from '../../components/Auth/Button';
import {
  loginAccountSuccess,
  loginAccountFailure,
  loginOrgUserSuccess,
  loginOrgUserFailure,
} from '../../store/newLoginState/actions';
import { CLEF_PATH, SsoLoginOpener } from '../../constants/path';
import { AuthPageLayout } from '../../components/Layout/AuthPageLayout';
import { isFDA } from '../../constants';

interface FormValues {
  firstName: string;
  lastName: string;
  password: string;
  reviewedTerms: boolean;
}

export const InvitedCompleteSignup: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isSigningUp, setIsSigningUp] = useState(false);
  const searchParams = useMemo(
    () => new URLSearchParams(history?.location?.search),
    [history?.location?.search],
  );
  const orgName = useMemo(
    () => searchParams.get('orgName') ?? t('Unknown organization'),
    [searchParams],
  );
  const { control, errors, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
      reviewedTerms: false,
    },
  });

  useEffect(() => {
    const activationCode = searchParams.get('activationCode') ?? '';
    const email = searchParams.get('email') ?? '';
    const token = searchParams.get('token') ?? '';

    // If there is no activation code, i.e. user already has an account, then redirect to login page
    if (!activationCode) {
      enqueueSnackbar(
        t(
          'Our record shows you already have an account. Please sign in with {{email}} and join the organization to accept the invitation',
          {
            email: formatEmail(email) ?? t('unknown email'),
          },
        ),
        {
          variant: 'warning',
          autoHideDuration: 12000,
        },
      );

      history.push(`${CLEF_PATH.login.main}?token=${token}`);
    }
  }, [enqueueSnackbar, history, searchParams]);

  const onSubmit = useCallback(
    async ({ firstName, lastName, password }: FormValues) => {
      const activationCode = searchParams.get('activationCode') ?? '';
      const email = searchParams.get('email') ?? '';
      const token = searchParams.get('token') ?? '';

      setIsSigningUp(true);

      // Step 1 - sign up a new account
      try {
        await AuthAPI.newSignup({
          email,
          name: firstName,
          lastname: lastName,
          password,
          activationCode,
        });

        enqueueSnackbar('Account has been created!', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(e?.message, { variant: 'error', autoHideDuration: 12000 });

        dispatch(loginAccountFailure(e));
        setIsSigningUp(false);

        // If failed to sign up the account, stop here and stay at the current page
        return;
      }

      // Step 2 - join the org
      try {
        await AccountAPI.joinOrg(token);

        // After dispatching this action, auth router will auto redirect to app page
        dispatch(loginOrgUserSuccess(null));

        // Clear the query params
        history.replace(history.location.pathname);
      } catch (e) {
        enqueueSnackbar(e?.message, { variant: 'error', autoHideDuration: 12000 });

        // Defer to dispatch login account success action to avoid router redirection before join org API finishes
        dispatch(loginOrgUserFailure(e));

        // After dispatching this action, auth router will auto redirect to join org page
        dispatch(loginAccountSuccess(null));
        setIsSigningUp(false);
      }
    },
    [dispatch, enqueueSnackbar, history, searchParams],
  );

  return (
    <AuthPageLayout metaTitle={t('Complete Signup')} showExamples>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        minHeight="100vh"
        position="relative"
      >
        <LandingLensLogo />

        <>
          <Typography variant="h1" className={styles.helperText}>
            {t('Join {{orgName}}', { orgName })}
          </Typography>

          {!isFDA() && <ContinueWithGoogleButton className={styles.marginBottom3} />}

          <ContinueWithEnterpriseSsoButton
            className={styles.marginBottom8}
            opener={SsoLoginOpener.Signup}
          />

          <OrSeparator />

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Grid container className={styles.marginBottom8}>
              <BaseTextField
                label={t('First Name')}
                rules={{
                  required: t('This is required.'),
                }}
                name="firstName"
                error={errors?.firstName}
                control={control}
                className={cx(styles.smallInputBox, styles.marginRight8)}
                enableAutoComplete={false}
                standalongLabel
              />

              <BaseTextField
                label={t('Last Name')}
                rules={{
                  required: t('This is required.'),
                }}
                name="lastName"
                error={errors?.lastName}
                control={control}
                className={styles.smallInputBox}
                enableAutoComplete={false}
                standalongLabel
              />
            </Grid>

            <PasswordTextField
              error={errors?.password}
              control={control}
              watch={watch}
              className={cx(styles.normalInputBox, styles.marginBottom8)}
              showHint
              enablePatternCheck
              enableAutoComplete={false}
            />

            <TermsAndConditionsCheckbox
              error={errors?.reviewedTerms}
              control={control}
              className={styles.marginBottom6}
            />

            <PrimaryButton
              id="complete-invited-sign-up-with-email"
              text={t('Create LandingLens Account')}
              className={styles.marginBottom6}
              disabled={
                !!errors.firstName ||
                !!errors.lastName ||
                !!errors.password ||
                !!errors.reviewedTerms
              }
              isPending={isSigningUp}
              useNewUi
            />
          </Box>

          <Grid container direction="row" className={styles.autoWidth} alignItems="center">
            <Typography className={cx(styles.alreadyHaveAnAccountText, styles.marginRight1)}>
              {t('Already have an account?')}
            </Typography>

            <Link
              className={cx(styles.link, styles.newLink)}
              onClick={() => history.push(`${CLEF_PATH.login.main}`)}
            >
              {t('Sign In')}
            </Link>
          </Grid>
        </>
      </Box>
    </AuthPageLayout>
  );
};

import React, { useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import cx from 'classnames';
import { makeStyles, Box, Theme } from '@material-ui/core';
import { Typography } from '@clef/client-library';
import { AnnotationInstance, Media } from '@clef/shared/types';
import MediaContainer from '@/pages/DataBrowser/MediaContainer';
import { useGetProjectDefectsQuery } from '@/serverStore/projects';
import { getFilteredAnnotationPairs, getFilteredComparisonAnnotationPairs } from '@/utils';
import { modelListFilterOptionsAtom } from '../atoms';

const useStyles = makeStyles(theme => ({
  mediaView: {
    position: 'relative',
    borderRadius: 8,
    border: `2px solid transparent`,
    '&::after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: 106,
      cursor: 'pointer',
    },
  },
  mediaViewHoverable: {
    '&:hover': {
      border: `2px solid ${theme.palette.greyModern[400]}`,
      padding: theme.spacing(1),
    },
  },
  highlightTitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 700,
    color: theme.palette.grey[900],
  },
}));

interface IProps {
  modelId?: string;
  version?: number;
  threshold?: number;
  hoverable?: boolean;
  showTitle?: boolean;
  showFilteredOnly?: boolean;
  onImageClick?: (mediaId: number) => void;
  media: Media & { count?: number };
  allInstances?: AnnotationInstance[];
  rowWidth: number;
}

export const ObjectDetectionImageDiffView: React.FC<IProps> = ({
  media,
  modelId,
  version,
  threshold,
  rowWidth,
  onImageClick,
  hoverable = true,
  showTitle = false,
  showFilteredOnly = true,
  allInstances,
}) => {
  const styles = useStyles();

  const [filterOptions] = useAtom(modelListFilterOptionsAtom);
  const datasetDefects = useGetProjectDefectsQuery(version).data;
  const columns = 2;
  const columnWidth = 100 / columns;
  const [onHover, setOnHover] = useState<boolean>(false);

  const boxAnnotations = useMemo(() => {
    return getFilteredAnnotationPairs(
      media.id,
      threshold,
      allInstances,
      datasetDefects,
      filterOptions,
      !onHover && showFilteredOnly, // showFilteredOnly
    );
  }, [allInstances, media.id, threshold, datasetDefects, onHover, filterOptions, showFilteredOnly]);

  return (
    <Box width="100%">
      {showTitle && (
        <Box display="flex" width="100%">
          <Box width="50%">
            <Typography className={styles.title}>{t('Ground truth')}</Typography>
          </Box>
          <Box width="50%">
            <Typography className={styles.title}>{t('Prediction')}</Typography>
          </Box>
        </Box>
      )}
      {media.count && filterOptions && showFilteredOnly && (
        <Typography className={styles.highlightTitle}>
          {t('{{count}} {{instance}} highlighted', {
            count: media.count,
            instance: media.count === 1 ? 'instance' : 'instances',
          })}
        </Typography>
      )}
      <Box
        display="flex"
        width="100%"
        height={rowWidth / columns}
        marginBottom={1.5}
        className={cx(styles.mediaView, {
          [styles.mediaViewHoverable]: hoverable,
        })}
        onClick={() => onImageClick?.(media.id)}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <Box width={columnWidth + '%'}>
          <MediaContainer
            media={media}
            modelId={modelId}
            versionId={version}
            showGroundTruth
            hideDefectName={!filterOptions || !showFilteredOnly}
            filterOptions={filterOptions}
            showClassChip={false}
            threshold={threshold}
            boxAnnotations={boxAnnotations?.filter(annotation => !annotation.isPrediction)}
            onInfoClick={() => onImageClick?.(media.id)}
          />
        </Box>
        <Box width={columnWidth + '%'}>
          <MediaContainer
            media={media}
            modelId={modelId}
            versionId={version}
            showPredictions
            hideDefectName={!filterOptions || !showFilteredOnly}
            filterOptions={filterOptions}
            showClassChip={false}
            threshold={threshold}
            boxAnnotations={boxAnnotations?.filter(annotation => annotation.isPrediction)}
            onInfoClick={() => onImageClick?.(media.id)}
          />
        </Box>
      </Box>
    </Box>
  );
};

const useDiffViewStyles = makeStyles<Theme, { differences?: number }>(theme => ({
  mediaViewerRoot: {
    border: props =>
      `3px solid ${
        props.differences && props.differences > 0
          ? theme.palette.red[500]
          : theme.palette.green[500]
      } !important`,
    borderRadius: '6px',
  },
  differenceText: {
    color: 'white',
    padding: '2px 4px',
    borderRadius: '3px 3px 0 0',
    fontWeight: 700,
    lineHeight: '16px',
    display: 'inline-block',
    marginRight: theme.spacing(4),
    backgroundColor: props =>
      props.differences && props.differences > 0
        ? theme.palette.red[500]
        : theme.palette.green[500],
  },
  errorInstancesCount: {
    background: 'rgba(0, 0, 0, 0.50)',
    padding: '0 2px',
    position: 'absolute',
    right: 20,
    bottom: 20,
    color: 'white',
  },
  diffViewContainer: {
    position: 'relative',
    transition: 'outline 0.3s',
    borderRadius: 8,
    border: `2px solid transparent`,
    '&:hover': {
      border: `2px solid ${theme.palette.greyModern[400]}`,
      padding: theme.spacing(1),
    },
    '&::after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: 106,
      cursor: 'pointer',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    padding: theme.spacing(1, 4),
  },
  title: {
    fontWeight: 700,
    color: theme.palette.grey[800],
  },
  secondaryTitle: {
    fontWeight: 400,
    color: theme.palette.grey[800],
  },
}));

interface DiffViewProps {
  media: Media;
  baselineAllInstances?: AnnotationInstance[];
  baselineModelId?: string;
  baselineThreshold?: number;
  rowWidth: number;
  candidateAllInstances?: AnnotationInstance[];
  candidateModelId?: string;
  candidateThreshold?: number;
  version?: number;
  differences?: number;
  onImageClick?: () => void;
  showFilteredOnly?: boolean;
  isDetailDialog?: boolean;
}

export const ComparisonObjectDetectionImageDiffView: React.FC<DiffViewProps> = ({
  media,
  baselineModelId,
  version,
  baselineThreshold,
  rowWidth,
  onImageClick,
  candidateModelId,
  candidateThreshold,
  baselineAllInstances,
  candidateAllInstances,
  showFilteredOnly = true,
  differences,
  isDetailDialog = false,
}) => {
  const styles = useDiffViewStyles({ differences });

  const [filterOptions] = useAtom(modelListFilterOptionsAtom);
  const datasetDefects = useGetProjectDefectsQuery(version).data;
  const columns = !showFilteredOnly || !filterOptions ? 3 : 2;
  const columnWidth = 100 / columns;
  const isCorrectMapping = filterOptions && filterOptions?.gtClassId === filterOptions?.predClassId;

  const [baselineBoxAnnotations, candidateBoxAnnotations] = useMemo(() => {
    return getFilteredComparisonAnnotationPairs(
      media.id,
      baselineAllInstances,
      baselineThreshold,
      candidateAllInstances,
      candidateThreshold,
      datasetDefects,
      filterOptions,
    );
  }, [
    media.id,
    baselineThreshold,
    baselineAllInstances,
    candidateThreshold,
    candidateAllInstances,
    datasetDefects,
    filterOptions,
  ]);

  const getCountTooltipText = (count: number) => {
    if (count === 1) {
      return isCorrectMapping ? t('1 correct') : t('1 error');
    } else {
      return isCorrectMapping
        ? t('{{count}} corrects', {
            count,
          })
        : t('{{count}} errors', {
            count,
          });
    }
  };

  return (
    <Box textAlign="right" width="100%">
      {!!differences && !isDetailDialog && (
        <Typography variant="body2" className={styles.differenceText}>
          {differences > 0
            ? t('{{diff}} more {{errors}}', {
                diff: differences,
                errors: differences === 1 ? t('error') : t('errors'),
              })
            : t('{{diff}} fewer {{errors}}', {
                diff: -differences,
                errors: -differences === 1 ? t('error') : t('errors'),
              })}
        </Typography>
      )}
      <Box
        display="flex"
        key={media.id}
        className={isDetailDialog ? undefined : styles.diffViewContainer}
        height={isDetailDialog ? '100%' : rowWidth / columns}
        onClick={() => onImageClick?.()}
      >
        {(!filterOptions || !showFilteredOnly) && (
          <Box width={columnWidth + '%'}>
            {isDetailDialog && (
              <Box className={styles.titleContainer}>
                <Typography className={styles.title}>{'Ground Truth'}</Typography>
              </Box>
            )}
            <MediaContainer
              media={media}
              modelId={baselineModelId}
              versionId={version}
              showGroundTruth
              showClassChip={false}
              threshold={baselineThreshold}
            />
          </Box>
        )}
        <Box width={columnWidth + '%'} position="relative">
          {isDetailDialog && (
            <Box className={styles.titleContainer}>
              <Typography className={styles.title}>{'Baseline model'}</Typography>
              {baselineBoxAnnotations && showFilteredOnly && (
                <Typography className={styles.secondaryTitle}>
                  {getCountTooltipText(baselineBoxAnnotations.length)}
                </Typography>
              )}
            </Box>
          )}
          <MediaContainer
            media={media}
            modelId={baselineModelId}
            versionId={version}
            showPredictions
            hideDefectName={!filterOptions || !showFilteredOnly}
            filterOptions={showFilteredOnly ? filterOptions : undefined}
            showClassChip={false}
            threshold={baselineThreshold}
            boxAnnotations={showFilteredOnly ? baselineBoxAnnotations : undefined}
          />
          {!isDetailDialog && baselineBoxAnnotations && (
            <Typography className={styles.errorInstancesCount}>
              {getCountTooltipText(baselineBoxAnnotations.length)}
            </Typography>
          )}
        </Box>
        <Box width={columnWidth + '%'} position="relative">
          {isDetailDialog && (
            <Box className={styles.titleContainer}>
              <Typography className={styles.title}>{'Candidate model'}</Typography>
              {candidateBoxAnnotations && showFilteredOnly && (
                <Typography className={styles.secondaryTitle}>
                  {getCountTooltipText(candidateBoxAnnotations.length)}
                </Typography>
              )}
            </Box>
          )}
          <MediaContainer
            media={media}
            modelId={candidateModelId}
            versionId={version}
            showPredictions
            hideDefectName={!filterOptions || !showFilteredOnly}
            filterOptions={showFilteredOnly ? filterOptions : undefined}
            showClassChip={false}
            threshold={candidateThreshold}
            boxAnnotations={showFilteredOnly ? candidateBoxAnnotations : undefined}
            classes={
              differences
                ? {
                    mediaViewerRoot: styles.mediaViewerRoot,
                  }
                : undefined
            }
          />
          {!isDetailDialog && candidateBoxAnnotations && (
            <Typography className={styles.errorInstancesCount}>
              {getCountTooltipText(candidateBoxAnnotations.length)}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

enum OSOption {
  Mac = 'Mac',
  Windows = 'Windows',
  Linux = 'Linux',
  X86_64_ARM64 = 'X86_64_ARM64',
  JetsonJetpack5x = 'JetsonJetpack5x',
  JetsonJetpack4x = 'JetsonJetpack4x',
}

enum ArchitectureOption {
  X86_64_ARM64 = 'X86-64 / ARM64',
  JetsonJetpack5x = 'Jetson w/ Jetpack 5.x',
  JetsonJetpack4x = 'Jetson w/ Jetpack 4.x',
}

const getCurrentOS = () => {
  if (navigator.userAgent.indexOf('Mac OS X') !== -1) {
    return OSOption.Mac;
  } else if (navigator.userAgent.indexOf('Windows') !== -1) {
    return OSOption.Windows;
  }
  return OSOption.Linux;
};

export { ArchitectureOption, getCurrentOS };

export default OSOption;

import { ApiResponseLoader } from '@clef/client-library';
import {
  Dialog,
  Grid,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import { Pagination } from '@material-ui/lab';
import React, { useEffect, useMemo } from 'react';
import { useImmer } from 'use-immer';
import { useDatasetMediaCountQuery, useDatasetMediasQuery } from '@/serverStore/dataset';
import {
  appliedFilterMappingToFormattedFilterMapping,
  DataBrowserStateContext,
  defaultState,
  getColumnFilterMapWithModelId,
} from './dataBrowserState';
import MediaGrid from './MediaGrid/MediaGrid';
import { useDataBrowserStyles } from './legacyStyles';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';

export interface MediaSelectionDialogProps {
  onSelectMedia: (mediaIds: number[]) => void;
  onClose: () => void;
}
/**
 * this is a mini data browser dialog used when you want to select media from data browser
 * use case such as add to defect book
 */
const MediaSelectionDialog: React.FC<MediaSelectionDialogProps> = ({ onSelectMedia, onClose }) => {
  const styles = useDataBrowserStyles();
  const [state, dispatch] = useImmer({
    ...defaultState,
    paginationLimit: 20,
  });
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();

  const { appliedFilters, sortField, paginationLimit, pageIndex, selectedOrUnselectedMedia } =
    state;
  // Reformat appliedFilters to columnFilterMap and metadataFilterMap
  const [columnFilterMap, metadataFilterMap] = useMemo(() => {
    const [col, metadata] = appliedFilterMappingToFormattedFilterMapping(appliedFilters);
    const colWithModelId = getColumnFilterMapWithModelId(col, currentModelId);
    return [colWithModelId, metadata];
  }, [appliedFilters, currentModelId]);

  // Get media list after filtering and options
  const {
    data: datasetMedias,
    isLoading: datasetMediasLoading,
    error: datasetMediasError,
  } = useDatasetMediasQuery({
    sortOptions: {
      ...sortField,
      offset: pageIndex * paginationLimit,
      limit: paginationLimit,
    },
    columnFilterMap,
    metadataFilterMap,
    includeMediaStatus: true, // task creation needs here
  });

  const {
    data: totalMediaCount,
    isLoading: totalMediaCountLoading,
    error: totalMediaCountError,
  } = useDatasetMediaCountQuery({
    selectOptions: {
      fieldFilterMap: metadataFilterMap,
      columnFilterMap: columnFilterMap,
      // Not important
      selectedMedia: [],
      unselectedMedia: [],
      isUnselectMode: true,
    },
  });

  // Reset pageIndex to 0 when filter or sortField changed
  useEffect(() => {
    dispatch(draft => {
      draft.pageIndex = 0;
    });
  }, [appliedFilters, dispatch, sortField]);

  // On appliedFilters change, do 2 things
  // 1. Reset selected media when filters change
  // 2. Save base64 filters to FilterSearchParam
  useEffect(() => {
    dispatch(draft => {
      draft.selectingMediaMode = 'select';
      draft.selectedOrUnselectedMedia = [];
    });
  }, [dispatch, appliedFilters]);

  // Store total media count
  useEffect(() => {
    if (totalMediaCount) {
      dispatch(draft => {
        draft.totalMediaCount = totalMediaCount;
      });
    }
  }, [dispatch, totalMediaCount]);

  return (
    <Dialog open fullWidth maxWidth={'lg'} onClose={onClose}>
      <DataBrowserStateContext.Provider value={{ state, dispatch }}>
        <DialogTitle>{t('Select media from dataset')}</DialogTitle>
        <DialogContent>
          {/* Media count and pagination */}
          <ApiResponseLoader
            response={totalMediaCount}
            loading={totalMediaCountLoading}
            error={totalMediaCountError}
            defaultHeight={0}
          >
            {responseLoaded => (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                className={styles.paginationTopInDialog}
                wrap="nowrap"
              >
                <Typography
                  variant="body1"
                  component="div"
                  className={styles.mediaCountText}
                  data-testid={'databrowser-total-medias'}
                >
                  {t('Total {{count}} media', { count: <strong>{responseLoaded}</strong> })}
                </Typography>
                <Pagination
                  data-testid={'databrowser-top-pagination'}
                  count={Math.ceil(responseLoaded / paginationLimit)}
                  color="primary"
                  size="small"
                  page={pageIndex + 1}
                  onChange={(_, newPageIndex) =>
                    dispatch(draft => {
                      draft.pageIndex = newPageIndex - 1;
                    })
                  }
                />
              </Grid>
            )}
          </ApiResponseLoader>
          {/* Media Grid */}
          <ApiResponseLoader
            response={datasetMedias}
            loading={datasetMediasLoading}
            error={datasetMediasError}
            defaultHeight={500}
          >
            {datasetMediasLoaded => {
              if (datasetMediasLoaded.length) {
                return (
                  <>
                    <MediaGrid
                      medias={datasetMediasLoaded}
                      disableVirtualGrid
                      showClassChip={false}
                    />
                  </>
                );
              } else {
                return (
                  <>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      gutterBottom
                      className={styles.hintTextNormal}
                      data-test-id="no-medias-found-data-browser"
                    >
                      {Object.entries(appliedFilters).length ? (
                        <>
                          {/* No media, non match filter condition */}
                          {t('No media found')}
                          <Button
                            id="clear-media-filter"
                            color="secondary"
                            size="small"
                            className={styles.textButton}
                            onClick={() =>
                              dispatch(draft => {
                                draft.appliedFilters = {};
                              })
                            }
                          >
                            {t('Clear filters')}
                          </Button>
                        </>
                      ) : (
                        <>
                          {/* No media, none uploaded */}
                          {t('No media in this project')}
                        </>
                      )}
                    </Typography>
                  </>
                );
              }
            }}
          </ApiResponseLoader>
          {/* Bottom pagination */}
          <ApiResponseLoader
            response={totalMediaCount}
            loading={totalMediaCountLoading}
            error={totalMediaCountError}
            defaultHeight={0}
          >
            {responseLoaded => {
              if (!responseLoaded) return null;
              return (
                <Pagination
                  data-testid={'databrowser-bottom-pagination'}
                  classes={{
                    ul: styles.paginationBottomUl,
                  }}
                  count={Math.ceil(responseLoaded / paginationLimit)}
                  color="primary"
                  size="small"
                  page={pageIndex + 1}
                  onChange={(_, newPageIndex) =>
                    dispatch(draft => {
                      draft.pageIndex = newPageIndex - 1;
                    })
                  }
                />
              );
            }}
          </ApiResponseLoader>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              onSelectMedia(selectedOrUnselectedMedia);
              onClose();
            }}
            id="confirm-selected-media"
          >
            {t('Select {{count}} media', { count: selectedOrUnselectedMedia.length })}
          </Button>
          <Button
            color="primary"
            onClick={() => {
              onClose();
            }}
            id="cancel-selected-media"
          >
            {t('Cancel')}
          </Button>
        </DialogActions>
      </DataBrowserStateContext.Provider>
    </Dialog>
  );
};

export default MediaSelectionDialog;

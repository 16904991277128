import React, { useMemo, useState } from 'react';
import { LabelReviewStatus, MediaLevelLabel } from '@clef/shared/types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, ButtonGroup } from '@material-ui/core';
import { Button } from '@clef/client-library';
import cx from 'classnames';
import { useKeyPress } from '@clef/client-library';
import { arrayToObject } from '../../../utils';
import { useFetchUsersForProject } from '../../../hooks/api/useProjectApi';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';

const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: 16,
  },
  buttonGroup: {
    marginBottom: theme.spacing(5),
  },
  accept: {
    color: '#fff',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
  reject: {
    color: '#fff',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  comment: {
    marginRight: theme.spacing(2),
    '& > div': {
      borderRadius: 12,
    },
  },
  normalText: {},
  submitReviewButton: { marginTop: '5px' },
}));

export interface MediaReviewPanelProps {
  acceptButtonLabel?: string;
  rejectButtonLabel?: string;
  handleReviewSubmit?: (status: MediaLevelLabel, notes: string, reviewer: string) => void;
}

const MediaReviewPanel: React.FC<MediaReviewPanelProps> = ({
  acceptButtonLabel,
  rejectButtonLabel,
  handleReviewSubmit,
}) => {
  const { id: currentUserId } = useTypedSelector(state => state.login.user!);
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};

  const classes = useStyles();
  const [status, setStatus] = useState<LabelReviewStatus | undefined>();
  const [notes, setNotes] = useState<string>('');
  const [users, usersLoading] = useFetchUsersForProject(projectId);

  const userMap = useMemo(() => {
    if (usersLoading || !users) return {};
    return arrayToObject(users.activeUsers, 'id');
  }, [users, usersLoading]);

  const onStatusChanged = (status: LabelReviewStatus) => {
    setStatus(status);
  };
  useKeyPress('a', () => onStatusChanged(LabelReviewStatus.Accepted));
  useKeyPress('r', () => onStatusChanged(LabelReviewStatus.Rejected));

  const isAccepted = status == LabelReviewStatus.Accepted;
  const isRejected = status == LabelReviewStatus.Rejected;
  const reviewerEmail = userMap[currentUserId]?.email ?? null;

  return (
    <Grid data-testid="media-review-panel">
      <ButtonGroup
        disableElevation
        fullWidth
        classes={{
          grouped: classes.button,
          root: classes.buttonGroup,
        }}
      >
        <Button
          id="media-review-accept"
          variant={isAccepted ? 'contained' : 'outlined'}
          fullWidth
          className={cx(classes.normalText, {
            [classes.accept]: isAccepted,
          })}
          onClick={() => onStatusChanged(LabelReviewStatus.Accepted)}
        >
          {acceptButtonLabel ?? t('Accept')}
        </Button>
        <Button
          id="media-review-reject"
          variant={isRejected ? 'contained' : 'outlined'}
          fullWidth
          className={cx(classes.normalText, {
            [classes.reject]: isRejected,
          })}
          onClick={() => onStatusChanged(LabelReviewStatus.Rejected)}
        >
          {rejectButtonLabel ?? t('Reject')}
        </Button>
      </ButtonGroup>
      <TextField
        data-testid="media-review-comment"
        className={classes.comment}
        type={'text'}
        multiline
        size="small"
        fullWidth
        onChange={e => setNotes(e.target.value)}
        rows={3}
        rowsMax={12}
        helperText={reviewerEmail ? `Reviewed by: ${reviewerEmail}` : ''}
        value={notes}
        variant={'outlined'}
      />
      <Button
        id="submit-review"
        className={classes.submitReviewButton}
        variant="contained"
        color="primary"
        disabled={!status}
        onClick={() =>
          handleReviewSubmit?.(
            status === LabelReviewStatus.Accepted ? MediaLevelLabel.OK : MediaLevelLabel.NG,
            notes,
            reviewerEmail as string,
          )
        }
      >
        {t('Submit')}
      </Button>
    </Grid>
  );
};

export default MediaReviewPanel;

import { SubscriptionName, TemporaryProcessedSubscriptionName } from '../types';

export enum SubscriptionMetaQuotaType {
  USAGE = 'quotaUsage',
  USER = 'quotaUser',
  ACTIVE_PROJECT = 'quotaActiveProject',
}

const DefaultSubscriptionNameToQuotaTypeMapForCredit = {
  [TemporaryProcessedSubscriptionName.Free]: [SubscriptionMetaQuotaType.USAGE],
  [TemporaryProcessedSubscriptionName.Visionary]: [SubscriptionMetaQuotaType.USAGE],
  [TemporaryProcessedSubscriptionName.PreSales]: [SubscriptionMetaQuotaType.USAGE],
  [TemporaryProcessedSubscriptionName.Enterprise]: [],
  [TemporaryProcessedSubscriptionName.TrialingV1]: [SubscriptionMetaQuotaType.USAGE],
  [TemporaryProcessedSubscriptionName.FreemiumV1]: [SubscriptionMetaQuotaType.USAGE],
  [TemporaryProcessedSubscriptionName.StarterV1]: [SubscriptionMetaQuotaType.USAGE],
  [TemporaryProcessedSubscriptionName.VisionaryV1]: [SubscriptionMetaQuotaType.USAGE],
};

const DefaultSubscriptionNameToQuotaTypeMapForUser = {
  [TemporaryProcessedSubscriptionName.Free]: [SubscriptionMetaQuotaType.USER],
  [TemporaryProcessedSubscriptionName.Visionary]: [SubscriptionMetaQuotaType.USER],
  [TemporaryProcessedSubscriptionName.PreSales]: [SubscriptionMetaQuotaType.USER],
  [TemporaryProcessedSubscriptionName.Enterprise]: [SubscriptionMetaQuotaType.USER],
  [TemporaryProcessedSubscriptionName.TrialingV1]: [],
  [TemporaryProcessedSubscriptionName.FreemiumV1]: [],
  [TemporaryProcessedSubscriptionName.StarterV1]: [],
  [TemporaryProcessedSubscriptionName.VisionaryV1]: [],
};
const DefaultSubscriptionNameToQuotaTypeMapForActiveProjects = {
  [TemporaryProcessedSubscriptionName.Free]: [SubscriptionMetaQuotaType.ACTIVE_PROJECT],
  [TemporaryProcessedSubscriptionName.Visionary]: [SubscriptionMetaQuotaType.ACTIVE_PROJECT],
  [TemporaryProcessedSubscriptionName.PreSales]: [SubscriptionMetaQuotaType.ACTIVE_PROJECT],
  [TemporaryProcessedSubscriptionName.Enterprise]: [SubscriptionMetaQuotaType.ACTIVE_PROJECT],
  [TemporaryProcessedSubscriptionName.TrialingV1]: [],
  [TemporaryProcessedSubscriptionName.FreemiumV1]: [],
  [TemporaryProcessedSubscriptionName.StarterV1]: [],
  [TemporaryProcessedSubscriptionName.VisionaryV1]: [],
};

export const API_PATH_MODEL_TRAIN_AS_DEFAULT_CREDIT_USAGE = '/api/model/train';

export const API_PATH_INVITE_USER_AS_DEFAULT_USER_USAGE = '/api/account/invite_user';

export const API_PATH_ADD_ACTIVE_PROJECT_AS_DEFAULT_ACTIVE_PROJECT_USAGE =
  '/api/project/active_project/add';

const ApiPathToSubscriptionMetaQuotaTypeMap: Record<
  string,
  Record<TemporaryProcessedSubscriptionName, SubscriptionMetaQuotaType[]>
> = {
  [API_PATH_MODEL_TRAIN_AS_DEFAULT_CREDIT_USAGE]: {
    ...DefaultSubscriptionNameToQuotaTypeMapForCredit,
  },
  '/v1/projects/\\d+/train': { ...DefaultSubscriptionNameToQuotaTypeMapForCredit },
  '/train/create_training_run': { ...DefaultSubscriptionNameToQuotaTypeMapForCredit },
  '/train/v2/create_fast_training_run': { ...DefaultSubscriptionNameToQuotaTypeMapForCredit },
  '/inference/v2/predict': { ...DefaultSubscriptionNameToQuotaTypeMapForCredit },
  '/inference/v1/predict': { ...DefaultSubscriptionNameToQuotaTypeMapForCredit },
  '/ocr/v1/detect-text': { ...DefaultSubscriptionNameToQuotaTypeMapForCredit },
  [API_PATH_INVITE_USER_AS_DEFAULT_USER_USAGE]: { ...DefaultSubscriptionNameToQuotaTypeMapForUser },
  '/api/account/activate': {
    ...DefaultSubscriptionNameToQuotaTypeMapForUser,
  },
  [API_PATH_ADD_ACTIVE_PROJECT_AS_DEFAULT_ACTIVE_PROJECT_USAGE]: {
    ...DefaultSubscriptionNameToQuotaTypeMapForActiveProjects,
  },
};

/**
 * Checks if the path exists in the URL's pathname.
 *
 * @param {string} urlWithoutQueryParams - The URL to check.
 * @param {string} path - The path to look for in the URL's pathname.
 * @returns {boolean} - Returns true if the path is found in the URL's pathname.
 */
const urlPathMatch = (urlWithoutQueryParams: string, path: string) => {
  const regex1 = new RegExp(`.*${path}$`);
  const regex2 = new RegExp(`.*${path}/.*`);

  return regex1.test(urlWithoutQueryParams) || regex2.test(urlWithoutQueryParams);
};

export const convertSubscriptionNameToTemporaryProcessedSubscriptionName = (
  planName: SubscriptionName,
  hasPlanTier: boolean,
): TemporaryProcessedSubscriptionName => {
  switch (planName) {
    case SubscriptionName.Free:
      return TemporaryProcessedSubscriptionName.Free;
    case SubscriptionName.Trialing:
      return TemporaryProcessedSubscriptionName.TrialingV1;
    case SubscriptionName.Enterprise:
      return TemporaryProcessedSubscriptionName.Enterprise;
    case SubscriptionName.Freemium:
      return TemporaryProcessedSubscriptionName.FreemiumV1;
    case SubscriptionName.Starter:
      return TemporaryProcessedSubscriptionName.StarterV1;
    case SubscriptionName.PreSales:
      return TemporaryProcessedSubscriptionName.PreSales;
    case SubscriptionName.Visionary:
      return hasPlanTier
        ? TemporaryProcessedSubscriptionName.VisionaryV1
        : TemporaryProcessedSubscriptionName.Visionary;
  }
};

const hasSubscriptionExpired = (
  expiryDate: Date | string | undefined,
  currentTime?: string | Date,
): boolean => {
  if (expiryDate === undefined) {
    return false;
  }
  const currentDate = currentTime ? new Date(currentTime) : new Date();
  const expiryDateObject = new Date(expiryDate);
  return currentDate > expiryDateObject;
};

export const checkSubscriptionHasExpired = (
  planName: SubscriptionName | undefined,
  expiryDate: Date | string | undefined,
  currentTime?: string | Date,
): boolean => {
  return (
    planName !== SubscriptionName.Enterprise && hasSubscriptionExpired(expiryDate, currentTime)
  );
};

export const checkSubscriptionHasExpiredWithUrl = (
  url: string,
  planName: SubscriptionName | undefined,
  expiryDate: Date | string | undefined,
  currentTime?: Date,
): boolean => {
  for (const path of Object.keys(ApiPathToSubscriptionMetaQuotaTypeMap)) {
    if (urlPathMatch(url, path)) {
      return checkSubscriptionHasExpired(planName, expiryDate, currentTime);
    }
  }
  return false;
};

export const getQuotaTypesForSubscription = (
  url: string,
  planName: TemporaryProcessedSubscriptionName,
): SubscriptionMetaQuotaType[] => {
  for (const [path, quotaTypes] of Object.entries(ApiPathToSubscriptionMetaQuotaTypeMap)) {
    if (urlPathMatch(url, path)) {
      return quotaTypes[planName];
    }
  }
  return [];
};

/**
 * This function checks if the usage has exceeded the limit.
 * It takes in three parameters: limit, usage, and an optional increment.
 * It returns a boolean indicating whether the limit has been exceeded.
 *
 * @param {number} limit - The limit for the usage.
 * @param {number} usage - The current usage.
 * @param {number} increment - The amount by which the usage is to be increased. Default is 0.
 * @returns {boolean} - Returns true if the usage (plus the increment) exceeds the limit, otherwise false.
 *
 * If the limit is -1, the function assumes there is no limit and returns false.
 *
 * If the increment is 0, the function simply checks if the current usage is greater than or equal to the limit.
 *
 * If the increment is not 0 (for frontend only), the function adds the increment to the current usage and checks if the result exceeds the limit.
 */
export const checkHasExceedLimit = (
  limit: number,
  usage: number,
  increment: number = 0,
): boolean => {
  if (limit === -1) {
    return false;
  }
  if (increment === 0) {
    return usage >= limit;
  }
  return usage + increment > limit;
};

export const isContractUser = (subscriptionName: SubscriptionName) => {
  return (
    subscriptionName === SubscriptionName.Enterprise ||
    subscriptionName === SubscriptionName.PreSales
  );
};

import { EventLogger } from '@clef/client-library';
import { isJestEnv } from '@clef/client-library/src/utils/env';
import { Project, ProjectId } from '@clef/shared/types';
import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit';
import { ReduxState } from '..';
import { fetchEnabledFeaturesForProject } from '../feature_toggle_store';

export type ProjectState = {
  selectedProjectId?: ProjectId;
};

const initialState: ProjectState = {};

export const resetSelectedProject = createAction<void>('projectState/resetSelectedProject');
export const updateProject = createAction<{ projectId: ProjectId } & Partial<Project>>(
  'projectState/updateProject',
);

export const setSelectedProjectId = createAsyncThunk<
  number | undefined,
  number | undefined,
  { state: ReduxState }
>('projectState/setSelectedProjectId', async (selectedProjectId, thunkAPI) => {
  const { dispatch } = thunkAPI;
  if (selectedProjectId !== undefined) {
    EventLogger.setDefaultAttributes({
      projectId: selectedProjectId,
    });
    // TODO: Temp disable update project feature gating for jest for now
    if (!isJestEnv) {
      dispatch(fetchEnabledFeaturesForProject(selectedProjectId));
    }
  }
  return selectedProjectId;
});

export default createReducer(initialState, builder => {
  /**
   * @ACTION: resetSelectedProject.
   */
  builder.addCase(resetSelectedProject, state => {
    state.selectedProjectId = undefined;
  });

  /**
   * @ACTION: setSelectedProjectId.
   */
  builder.addCase(setSelectedProjectId.fulfilled, (state, action) => {
    state.selectedProjectId = action.payload;
  });
});

import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { usePublicEndpointByIdApi } from '@/hooks/api/usePublicEndpointApi';
import MobileInferenceCardDisabled from './MobileInferenceCardDisabled';
import MobileInferenceCardEnabled from './MobileInferenceCardEnabled';

type MobileInferenceCardProps = {
  endpointId?: string;
  inferenceCost: number;
  onQrCodeGenerated?: () => void;
};

const MobileInferenceCard: React.FC<MobileInferenceCardProps> = props => {
  const { endpointId, inferenceCost, onQrCodeGenerated } = props;
  const [endpointData, loading] = usePublicEndpointByIdApi(endpointId);

  if (loading || !endpointData) {
    return <CircularProgress color="primary" size={24} />;
  }
  return endpointData.enable ? (
    <MobileInferenceCardEnabled endpointId={endpointId} inferenceCost={inferenceCost} />
  ) : (
    <MobileInferenceCardDisabled endpointId={endpointId} onQrCodeGenerated={onQrCodeGenerated} />
  );
};

export default MobileInferenceCard;

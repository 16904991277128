import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  grow: {
    flex: 1,
  },
  sectionTitle: {
    marginBottom: theme.spacing(7),
  },
  introSectionContainer: {
    backgroundColor: theme.palette.greyModern[100],
    marginBottom: theme.spacing(7),
    padding: theme.spacing(24, 9, 5),
  },
  projectsSectionContainer: {
    padding: theme.spacing(0, 8),
    margin: '0 auto',
    maxWidth: 1604,
  },
  emptyStateContainer: {
    background: '#F5F8FF',
    borderRadius: 20,
    padding: 68,
  },
  emptyStateText: {
    color: '#5D6B98',
    marginBottom: theme.spacing(5),
  },
  emptyStateStartButton: {
    background: theme.palette.blue[100],
    width: 160,
    height: 64,
    color: theme.palette.blue[900],
  },
  quickDemoVideoDialogActionBar: {
    padding: theme.spacing(2),
  },
  quickDemoVideoDialogPlayer: {
    minWidth: 600,
    width: '80vw',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0, 14, 14),
  },
  workflowTutorialBox: {
    border: `1px solid ${theme.palette.greyModern[300]}`,
  },
  demoFeatureItemDotIcon: {
    fontSize: 9,
    color: theme.palette.greyModern[600],
  },
}));

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  splitSetup: {
    width: 'fit-content',
    margin: theme.spacing(5, 0, 0, 10),
  },
  modelsConfiguration: {
    margin: theme.spacing(5, 10),
  },
  datasetSelectorButton: {
    width: 374,
    marginBottom: theme.spacing(6),

    '& .MuiButton-label': {
      justifyContent: 'space-between',
    },
  },
  datasetSelectorMenu: {
    width: 374,
  },
  pageControlButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    gap: 12,
  },
  viewModeSwitch: {
    height: theme.spacing(6),
    '& button': {
      height: theme.spacing(6),
      fontSize: '12px',
      borderRadius: '5px !important',
      padding: theme.spacing(0, 3),
    },
    '&::after': {
      borderRadius: '5px',
    },
  },
  slider: {
    flex: 1,
  },
  finalSplitDesc: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
}));

export default useStyles;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { inviteUser } from '../../store/user_store';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import { useSnackbar } from 'notistack';
import { User, UserPermission } from '@clef/shared/types';
import { AnyUser } from '../ProjectSettingWithUserRoleTable/types';
import ProjectSettingWithUserRoleTable, {
  ProjectSettingWithUserRoleTableRef,
  UserPermissions,
} from '../ProjectSettingWithUserRoleTable/ProjectSettingWithUserRoleTable';
import AddMembersToPlatformDialog from '../AddMembersToPlatformDialog/AddMembersToPlatformDialog';
import { makeStyles } from '@material-ui/core';
import SearchBox from '../Text/SearchBox';
import { useExternalSearchText } from '../../hooks/MaterialTable';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useAddProjectMembersMutation, useGetUserProjectQuery } from '@/serverStore/projects';

const addUsersToProjectDialogStyles = makeStyles(() => ({
  dialogActions: {
    paddingLeft: '24px',
    paddingRight: '24px',
    marginTop: '8px',
    marginBottom: '8px',
  },
  inviteMembersBtn: {
    alignSelf: 'left',
  },
  searchBar: {
    marginBottom: '8px',
    minHeight: '60px',
  },
}));

type AddUsersToProjectDialogProps = {
  open: boolean;
  onCancel: () => void;
  projectId: number;
};

export const AddUsersToProjectDialog: React.FC<AddUsersToProjectDialogProps> = ({
  open,
  onCancel,
  projectId,
}) => {
  const platformUsers = useTypedSelector(state => state.user.users);
  const { data: selectedProject, isLoading: isQuerySelectedProjectLoading } =
    useGetUserProjectQuery(projectId ?? 0);
  const [openInvite, setOpenInvite] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<AnyUser[]>([]);
  const [usersNotAdded, setUsersNotAdded] = useState<User[]>([]);
  const [userIdsInProject, setUserIdsInProject] = useState<AnyUser['id'][]>([]);
  const [isAdding, setIsAdding] = useState(false);
  const styles = addUsersToProjectDialogStyles();
  const [userPermissions, setUserPermissions] = useState<UserPermissions>({} as UserPermissions);

  const { enqueueSnackbar } = useSnackbar();
  const projectSettingWithUserRoleRef = useRef<ProjectSettingWithUserRoleTableRef>(null);

  const tableRef = useRef<any>();
  const { setSearchText } = useExternalSearchText(tableRef);

  const addProjectMembers = useAddProjectMembersMutation();

  const _addMembers = async (): Promise<void> => {
    if (!selectedProject) return;
    setIsAdding(true);

    try {
      await addProjectMembers.mutateAsync({
        projectId: selectedProject.id,
        projectName: selectedProject.name,
        numberOfUsersToAdd: selectedUsers.length,
        userPermissions: selectedUsers.map((user: AnyUser) => {
          return {
            id: user.id,
            permissions: Object.entries(userPermissions[user.id] ?? ({} as UserPermissions))
              .filter(([, value]) => {
                return value;
              })
              .map(([key]) => key) as UserPermission[],
          };
        }),
      });
      setIsAdding(false);
      onCancel();
    } catch (e) {
      enqueueSnackbar(t('An error occured while adding users to the project.'), {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    const userIds = selectedProject?.users?.map(user => user.id) ?? [];
    setUserIdsInProject(userIds);
  }, [selectedProject]);

  useEffect(() => {
    setUsersNotAdded(platformUsers?.filter(user => !userIdsInProject.includes(user.id)) || []);
  }, [platformUsers, userIdsInProject]);

  const onPermissionChange = useCallback(userPermissions => {
    setUserPermissions(userPermissions);
  }, []);

  if (!selectedProject && !isQuerySelectedProjectLoading) {
    return <></>;
  }
  return (
    <>
      <Dialog
        className="cy-pr-add-members-dialog"
        data-testid="add-members-dialog"
        open={open && !openInvite}
        onClose={onCancel}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle>{t('Add Members to the Project')}</DialogTitle>
        {isQuerySelectedProjectLoading ? (
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <CircularProgress size={24} />
          </Box>
        ) : (
          <>
            <DialogContent className={styles.searchBar}>
              <SearchBox
                type="text"
                placeholder={t('Search')}
                onInput={(e: React.FormEvent): void => {
                  setSearchText((e.target as HTMLInputElement).value);
                }}
                style={{ width: '100%', height: '44px' }}
              />
            </DialogContent>
            <DialogContent>
              <ProjectSettingWithUserRoleTable
                usersPartialUserProject={usersNotAdded}
                showSelect
                allEditableRole
                showRoleErrors
                isInvite={true}
                onSelectionChange={(selectedUsers: AnyUser[]): void => {
                  setSelectedUsers(selectedUsers);
                }}
                onPermissionChange={onPermissionChange}
                tableRef={tableRef}
                ref={projectSettingWithUserRoleRef}
              />
            </DialogContent>
          </>
        )}
        <DialogActions className={styles.dialogActions}>
          <div style={{ flex: '1 0 0' }} />
          <Button
            id="cancel-button"
            onClick={() => {
              onCancel();
              setSelectedUsers([]);
              projectSettingWithUserRoleRef.current?.clear();
            }}
            color="primary"
          >
            {t('Cancel')}
          </Button>
          <Button
            id="add-members-project-dialog-button"
            className="cy-add-members-project-dialog-btn"
            color="primary"
            variant="contained"
            disabled={isAdding}
            onClick={_addMembers}
            data-testid="invite-users-to-project-done"
          >
            {isAdding
              ? t('Adding members...')
              : t('Add {{count}} Members', { count: selectedUsers.length })}
          </Button>
        </DialogActions>
      </Dialog>
      <AddMembersToPlatformDialog
        open={open && openInvite}
        projectId={selectedProject?.id}
        onClose={(): void => setOpenInvite(false)}
        inviteUser={(email, userRole, projectId?, projectRole?) => {
          inviteUser(email, userRole, projectId, projectRole);
          onCancel();
        }}
      />
    </>
  );
};

export default AddUsersToProjectDialog;

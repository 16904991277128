import { MediaStatusType } from '@clef/shared/types';

export enum MediaOKNGName {
  OK = 'OK',
  NG = 'NG',
  Unlabled = 'Unlabled',
}
export const MediaAllSplitName = 'all';

export enum MediaSplitName {
  Train = 'train',
  Dev = 'dev',
  Test = 'test',
  Unassigned = 'unassigned',
}

export enum DeploymentModelName {
  AvailableModels = 'Models available to deploy',
  ActiveModels = 'Active models',
}

export const SplitMapping: Record<MediaSplitName, string> = {
  [MediaSplitName.Train]: t('train'),
  [MediaSplitName.Dev]: t('dev'),
  [MediaSplitName.Test]: t('test'),
  [MediaSplitName.Unassigned]: t('unassigned'),
};

export const MediaStatusNameMapping: Record<MediaStatusType, string> = {
  [MediaStatusType.Approved]: t('labeled'),
  [MediaStatusType.Raw]: t('unlabeled'),
  [MediaStatusType.InTask]: t('in task'),
};

export const statsColorMap = {
  [t('labeled')]: '#3F86FE',
  [t('unlabeled')]: '#CBE4FE',
  [t('in task')]: '#B9C0D4',
};

export const splitColorMap = {
  [t('train')]: '#76C893',
  [t('dev')]: '#99D98C',
  [t('test')]: '#BBE5B5',
  [t('unassigned')]: '#B9C0D4',
};

import React from 'react';
import { Annotation, Dimensions, Media } from '@clef/shared/types';
import { useDatasetMediaDetailsQuery } from '@/serverStore/dataset';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { MediaViewer } from '@clef/client-library';
import { useDefectSelector } from '../../../../store/defectState/actions';
import { getBoxAnnotations, getSegmentAnnotations } from '../../../../utils';

interface MediaContainerProps {
  media: Media;
  showLabels?: boolean;
  fromVersionedDataset?: number;
  propertiesOverride?: Dimensions | null;
}

const MediaContainer: React.FC<MediaContainerProps> = ({
  media,
  showLabels = true,
  fromVersionedDataset,
  propertiesOverride,
}) => {
  const { id, properties } = media;
  const { datasetId } = useGetSelectedProjectQuery().data ?? {};
  const defects = useDefectSelector();
  const { data: mediaDetails } = useDatasetMediaDetailsQuery({
    datasetId,
    mediaId: id,
    versionId: fromVersionedDataset,
  });
  const annotations = (mediaDetails?.label?.annotations as Annotation[]) ?? [];
  const boxAnnotations = showLabels ? getBoxAnnotations(annotations, defects!) : undefined;

  const segmentationAnnotations = showLabels
    ? getSegmentAnnotations(annotations, defects!)
    : undefined;
  return (
    <MediaViewer
      imgSrc={media?.url}
      properties={propertiesOverride ?? properties}
      boxAnnotations={boxAnnotations}
      segmentationAnnotations={segmentationAnnotations}
    />
  );
};

export default MediaContainer;

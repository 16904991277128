import React from 'react';
import { UploadFailureReason, UploadFile, UploadStatus } from './types';
import PictorAPI from '../../api/pictor_api';
import { getPredictionFromCloudInference } from '../../utils/upload_utils';
import {
  InferenceExecutorResult,
  MetadataFormattedValue,
  RegisteredModelId,
  UploadType,
} from '@clef/shared/types';
import { FileWithPath } from 'react-dropzone';
import snackbar from '../../utils/snackbar_singleton';
import { queryClient } from '@/serverStore';
import { tagsQueryKey } from '@/serverStore/tags';
import { StatusCodes } from 'http-status-codes';

// Snowflake has performance issue with uploading multiple files at once
const maxParallelUpload = 5;

export const unassignedClassName = t('Unassigned (will be uploaded in raw status)');

const maxAttemptsUpload = 3;

export const uploadMedia = async (
  projectId: number,
  datasetId: number,
  uploadFile: UploadFile,
  updateStatusProgress: (
    status: UploadStatus,
    progress: number,
    failureReason?: UploadFailureReason | (string & {}),
  ) => void,
  mediaMd5Set: Set<string>,
  tags?: string[],
  metadata?: MetadataFormattedValue,
  split?: string | null,
  modelId?: RegisteredModelId,
): Promise<any> => {
  const { file, initialLabel } = uploadFile;
  let result;
  let response;
  let error;
  let attempts = 0;
  updateStatusProgress(UploadStatus.Uploading, 10);

  while (attempts < maxAttemptsUpload) {
    attempts += 1;
    try {
      // Upload image to Pictor.
      response = await PictorAPI.upload(
        file as File,
        projectId,
        datasetId,
        split,
        tags,
        metadata,
        initialLabel,
        UploadType.Dataset,
        undefined,
        modelId,
      );
      if (response.code === StatusCodes.CONFLICT) {
        throw { status: StatusCodes.CONFLICT };
      }
      error = null;
      break;
    } catch (e) {
      error = e;
      response = null;
      // No need to retry duplicated media error
      if (error.status === StatusCodes.CONFLICT) {
        if (attempts > 1) {
          // Not first time duplicated error will treat as success
          error = null;
        }
        break;
      }
      // Retry other errors until max attempts
    }
  }
  // If no error, or not first time duplicated media error, treat as success
  if (!error) {
    updateStatusProgress(UploadStatus.Success, 100);
    projectId && queryClient.invalidateQueries(tagsQueryKey.listByProjectId(projectId));
    result = response ? { ...response.data, mediaId: response.data.id } : undefined;
  } else {
    const failureReason =
      error.status === StatusCodes.CONFLICT
        ? UploadFailureReason.Duplicated
        : error.status === StatusCodes.BAD_REQUEST && error.body.code === 10
        ? UploadFailureReason.InvalidCharInFileName
        : error.body.message;
    updateStatusProgress(UploadStatus.Failure, 0, failureReason);
  }
  // regardless if the upload failed or not, always return promise resolve to continue next upload
  return Promise.resolve(result);
};

export const uploadMediaAndGetPrediction = async (
  projectId: number,
  datasetId: number,
  file: File,
  selectedModelId: string,
  s3url?: string,
): Promise<{
  result: InferenceExecutorResult;
  s3url: string;
}> => {
  if (s3url) {
    // get the prediction results
    const prediction = await getPredictionFromCloudInference(
      file.name,
      s3url,
      projectId,
      selectedModelId,
    );
    return { result: prediction, s3url };
  }

  const response = await PictorAPI.upload(
    file as File,
    projectId,
    datasetId,
    undefined,
    undefined,
    undefined,
    undefined,
    UploadType.Prediction,
    true,
    selectedModelId,
  );
  const s3Path = response.data.path;
  // get the prediction results
  const prediction = await getPredictionFromCloudInference(
    file.name,
    s3Path,
    projectId,
    selectedModelId,
  );
  return { result: prediction, s3url: s3Path };
};

export const uploadMediaWithParallelization = async (
  projectId: number,
  datasetId: number,
  uploadFileList: UploadFile[],
  onSingleMediaStatusUpdate: (
    uploadFile: UploadFile,
  ) => (
    status: UploadStatus,
    progress: number,
    failureReason?: UploadFailureReason | (string & {}),
  ) => void,
  tags?: string[],
  metadata?: MetadataFormattedValue,
  split?: string | null,
  modelId?: RegisteredModelId,
) => {
  const mediaMd5Set = new Set<string>();
  return Promise.all(
    Array.from(Array(maxParallelUpload).keys()).map(initialIndex => {
      const partitionedMediaFileList = uploadFileList.filter(
        (_, index) => index % maxParallelUpload === initialIndex,
      );
      return partitionedMediaFileList.reduce(async (accPromise, uploadFile) => {
        // wait for last upload promise
        await accPromise;
        return uploadMedia(
          projectId,
          datasetId,
          uploadFile,
          onSingleMediaStatusUpdate(uploadFile),
          mediaMd5Set,
          tags,
          metadata,
          split,
          modelId,
        );
      }, Promise.resolve());
    }),
  );
};

export const getUploadStats = (uploadFiles: UploadFile[]) => {
  uploadFiles = uploadFiles || [];
  const failedFiles = uploadFiles.filter(file => file.status !== UploadStatus.Success);
  return {
    total: uploadFiles.length,
    successCount: uploadFiles.filter(file => file.status === UploadStatus.Success).length,
    failedCount: failedFiles.length,
    retryableCount: failedFiles.filter(
      file =>
        file.status === UploadStatus.Failure &&
        file.failureReason !== UploadFailureReason.Duplicated,
    ).length,
    duplicatedCount: failedFiles.filter(
      file =>
        file.status === UploadStatus.Failure &&
        file.failureReason === UploadFailureReason.Duplicated,
    ).length,
  };
};

export const generateUploadingMessage = (uploadData: UploadFile[]) => {
  const { total, successCount, duplicatedCount } = getUploadStats(uploadData);
  return (
    <span>
      {t('Uploading: {{count}} of {{total}} image(s).', {
        count: <strong>{successCount}</strong>,
        total: <strong>{total}</strong>,
      })}
      {!!duplicatedCount &&
        t(
          ' {{count}} image(s) could not be uploaded: because they already exist in your project.',
          {
            count: <strong>{duplicatedCount}</strong>,
          },
        )}
    </span>
  );
};

export const generateUploadedMessage = ({
  successCount = 0,
  retryableCount = 0,
  duplicatedCount = 0,
}) => (
  <div>
    {t('{{count}} image(s) uploaded successfully.', {
      count: <strong>{successCount}</strong>,
    })}
    {!!retryableCount &&
      t(' {{count}} image(s) failed to upload: Hover over the image(s) for details.', {
        count: <strong>{retryableCount}</strong>,
      })}
    {!!duplicatedCount &&
      t(' {{count}} image(s) could not be uploaded: because they already exist in your project.', {
        count: <strong>{duplicatedCount}</strong>,
      })}
  </div>
);

export const DEFAULT_FILE_KEY = '';

export const getFileKey = (file: FileWithPath) => {
  // First retrieve the file name without path/folder
  // Note, we should not worry about this in the actual UI flow but Cypress tests, which will make
  // file.name contain file path
  const strs = file.name.split('/');
  const fileFullname = strs[strs.length - 1];
  // Then scrub the file extension
  return fileFullname.split('.').slice(0, -1).join('.') || DEFAULT_FILE_KEY;
};

export function fileListUniqueByKey<T>(
  fileList: T[],
  duplicateFileWarningMsg: string,
  getFileKey = (item: T) => (item as unknown as { key: string }).key || DEFAULT_FILE_KEY,
  chronologicalOrder: boolean = false,
): T[] {
  let uniqueFiles = [];
  if (chronologicalOrder) {
    const lookup: Record<string, T> = {};
    for (const file of fileList) {
      const key = getFileKey(file);
      if (lookup[key]) {
        delete lookup[key];
      }
      lookup[key] = file;
    }
    uniqueFiles = Object.values(lookup);
  } else {
    uniqueFiles = [...new Map(fileList.map(item => [getFileKey(item), item])).values()];
  }

  if (uniqueFiles.length !== fileList.length) {
    snackbar.warning(duplicateFileWarningMsg);
  }

  return uniqueFiles;
}

export class ReachLimitException extends Error {
  fileCount: number;
  constructor(message: string, fileCount: number) {
    super(message);
    this.fileCount = fileCount;
  }
}

export function truncateList<T>(
  fileList: T[],
  capacity: number | null | undefined,
  filesTruncatedWarningMsg: string,
  throwOnReachLimit = false,
): T[] {
  if (capacity === null || capacity === undefined || fileList.length <= capacity) {
    return fileList;
  }

  if (throwOnReachLimit) {
    throw new ReachLimitException(filesTruncatedWarningMsg, fileList.length);
  } else {
    snackbar.warning(filesTruncatedWarningMsg);
  }

  return fileList.slice(0, capacity);
}

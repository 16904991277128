import React from 'react';

export interface IconProps {
  color?: string;
  width?: number | string;
  height?: number | string;
}

export const UpdatingIcon: React.FC<IconProps> = ({ color = '#4B5565' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1849_4030" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1849_4030)">
        <path
          d="M3.33337 16.6673V15.0007H5.62504L5.29171 14.709C4.56949 14.0701 4.06254 13.3409 3.77087 12.5215C3.47921 11.702 3.33337 10.8757 3.33337 10.0423C3.33337 8.50065 3.79518 7.12912 4.71879 5.92773C5.6424 4.72635 6.84726 3.93121 8.33337 3.54232V5.29232C7.33337 5.65343 6.52782 6.26801 5.91671 7.13607C5.3056 8.00412 5.00004 8.97287 5.00004 10.0423C5.00004 10.6673 5.1181 11.275 5.35421 11.8652C5.59032 12.4555 5.95837 13.0007 6.45837 13.5007L6.66671 13.709V11.6673H8.33337V16.6673H3.33337ZM11.6667 16.459V14.709C12.6667 14.3479 13.4723 13.7333 14.0834 12.8652C14.6945 11.9972 15 11.0284 15 9.95898C15 9.33398 14.882 8.72635 14.6459 8.13607C14.4098 7.54579 14.0417 7.00065 13.5417 6.50065L13.3334 6.29232V8.33398H11.6667V3.33398H16.6667V5.00065H14.375L14.7084 5.29232C15.3889 5.97287 15.8855 6.71246 16.198 7.51107C16.5105 8.30968 16.6667 9.12565 16.6667 9.95898C16.6667 11.5007 16.2049 12.8722 15.2813 14.0736C14.3577 15.275 13.1528 16.0701 11.6667 16.459Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const OKIcon: React.FC<IconProps> = ({ color = '#039855' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66663 9.99935C1.66663 5.39935 5.39996 1.66602 9.99996 1.66602C14.6 1.66602 18.3333 5.39935 18.3333 9.99935C18.3333 14.5993 14.6 18.3327 9.99996 18.3327C5.39996 18.3327 1.66663 14.5993 1.66663 9.99935ZM4.16663 9.99935L8.33329 14.166L15.8333 6.66602L14.6583 5.48268L8.33329 11.8077L5.34163 8.82435L4.16663 9.99935Z"
        fill={color}
      />
    </svg>
  );
};

import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import Konva from 'konva';
import { LineAnnotation } from '@clef/shared/types';
import {
  LineAnnotationComponent,
  PureCanvasAnnotationComponent,
  PureLineAnnotationComponent,
} from '../components/Shapes';
import { isEqual } from 'lodash';
import { AccurateLabelingZoomScaleThreshold, calcAccurateOffset, distance } from '../utils';
import { createPixelatedCanvas } from '../../../utils/canvas';
import { AnnotationCreatorBuilder } from '../types';
import { greyScale } from '../../../themes/colors';
import { useThrottle } from '../../..';
import { Circle } from 'react-konva';

type LineAnnotationDrawHook = (options: {
  disabled?: boolean;
  color?: string;
  strokeWidth: number;
  opacity: number;
  onFinishAnnotation: (line: LineAnnotation) => void;
  onMissingColor?: () => void;
  onIsWorkingInProgressChanged?: (wip: boolean) => void;
  onDisabledMouseDown?: () => void;
  zoomScale: number;
  enableContour?: boolean;
}) => AnnotationCreatorBuilder;

export const useLineAnnotationCreator: LineAnnotationDrawHook = ({
  disabled,
  color,
  strokeWidth,
  opacity,
  onFinishAnnotation,
  onMissingColor,
  onDisabledMouseDown,
  zoomScale,
  onIsWorkingInProgressChanged,
  enableContour = false,
}) => {
  const [cursorBitMap, setCursorBitMap] = useState<OffscreenCanvas>();
  const [points, setPoints] = useState<number[]>([]);
  const wipLineAnnotation = useMemo(
    () => ({
      color: color!,
      points,
      opacity,
      strokeWidth,
    }),
    [color, opacity, points, strokeWidth],
  );
  const isMouseDown = useRef(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  const onMouseMove = useThrottle((e: Konva.KonvaEventObject<MouseEvent>) => {
    const { x, y } = calcAccurateOffset(e, 'pixel-center');
    if (isEqual(mousePos, { x, y })) {
      return;
    }
    setMousePos({ x, y });
    if (!isMouseDown.current) {
      return;
    }
    setPoints(prev => {
      // do not store consecutive duplicate points
      if (isEqual([x, y], prev.slice(-2))) {
        return prev;
      }
      return prev.concat(x - 0.5, y - 0.5);
    });
  }, 32);

  const onMouseDown = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (disabled) {
        onDisabledMouseDown?.();
        return;
      }

      if (color) {
        isMouseDown.current = true;
        const { x, y } = calcAccurateOffset(e, 'pixel-center');
        // duplicate first point
        setPoints([x - 0.5, y - 0.5, x - 0.5, y - 0.5]);
        onIsWorkingInProgressChanged?.(true);
      } else {
        onMissingColor?.();
      }
    },
    [color, disabled, onDisabledMouseDown, onIsWorkingInProgressChanged, onMissingColor],
  );

  const onDiscardWip = useCallback(() => {
    setPoints([]);
  }, []);

  const onMouseUp = useCallback(() => {
    if (!color) {
      return;
    }
    isMouseDown.current = false;
    onFinishAnnotation(wipLineAnnotation);
    onDiscardWip();
    onIsWorkingInProgressChanged?.(false);
  }, [color, onDiscardWip, onFinishAnnotation, onIsWorkingInProgressChanged, wipLineAnnotation]);

  const adjustedStrokeWidth = strokeWidth < 2 ? strokeWidth : 2 * strokeWidth - 1;

  useEffect(() => {
    if (color) {
      const radius = adjustedStrokeWidth * 0.5;
      const offscreen = createPixelatedCanvas(
        { width: adjustedStrokeWidth, height: adjustedStrokeWidth },
        ({ x, y }) =>
          adjustedStrokeWidth <= 5
            ? true
            : distance(x + 0.5, y + 0.5, radius, radius) < radius + 0.5,
        color === 'transparent' ? (greyScale[100] as string) : color,
      );
      setCursorBitMap(offscreen);
    }
  }, [color, adjustedStrokeWidth]);

  const opacityHex = Math.round(255 * opacity).toString(16);

  return {
    creatorPreviewComponent: (
      <>
        {mousePos &&
          color &&
          wipLineAnnotation &&
          // if one pixel of label is displayed as >10 pixels on screen,
          // we can display pixelated line preview for accurate labeling.
          // otherwise, display non-pixelated line preview for better performance.
          (zoomScale > AccurateLabelingZoomScaleThreshold ? (
            <LineAnnotationComponent annotation={wipLineAnnotation} sync />
          ) : (
            <>
              {/* Draw a +1 stroke width white shape,
                so it looks like the contour after drawing the original shape on it */}
              {enableContour && (
                <PureLineAnnotationComponent
                  annotation={{
                    ...wipLineAnnotation,
                    color: '#ffffff',
                    strokeWidth: wipLineAnnotation.strokeWidth + 1,
                    opacity: 1,
                  }}
                />
              )}
              <PureLineAnnotationComponent annotation={wipLineAnnotation} />
            </>
          ))}
        {mousePos && color && cursorBitMap && (
          <>
            {enableContour ? (
              <Circle
                x={mousePos.x}
                y={mousePos.y}
                radius={wipLineAnnotation.strokeWidth}
                stroke="#ffffff"
                fill={/^#[0-9a-f]{6}$/i.test(color) ? color + opacityHex : color}
                strokeWidth={2.5}
                strokeScaleEnabled={false}
              />
            ) : (
              <PureCanvasAnnotationComponent
                x={mousePos.x - adjustedStrokeWidth * 0.5}
                y={mousePos.y - adjustedStrokeWidth * 0.5}
                canvas={cursorBitMap}
                opacity={opacity}
                width={cursorBitMap.width}
                height={cursorBitMap.height}
              />
            )}
          </>
        )}
      </>
    ),
    creatorInteractiveProps: {
      onMouseUp,
      onMouseDown,
      onMouseMove,
    },
    creatorCustomHandlers: {
      onModeChanged: onDiscardWip,
      onEscPressed: onDiscardWip,
    },
  };
};

import React from 'react';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import { HubspotContactForm } from '@/components/Dialogs/UpgradeDialog';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    width: 600,
    marginBottom: theme.spacing(5),
  },
}));

type ContactUsDialogProps = {
  open: boolean;
  onClose: () => void;
};
const ContactUsDialog = (props: ContactUsDialogProps) => {
  const styles = useStyles();
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent className={styles.dialogContent}>
        <HubspotContactForm />
      </DialogContent>
    </Dialog>
  );
};

export default ContactUsDialog;

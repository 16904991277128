import {
  Button,
  SwitchButtonGroup,
  useKeyPress,
  useStateSyncSearchParams,
} from '@clef/client-library';
import { Box, Grid } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { useDataBrowserState, DataBrowserState } from '../dataBrowserState';
import MediaFilter from '../MediaFilter';
import AppliedFilters from './AppliedFilters';
import MediaSelection from './MediaSelection';
import SortDropdown from './SortDropdown';
import ModelPerformance from '../ModelPerformance';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { LabelType } from '@clef/shared/types';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import {
  isModelTrainingSuccessful,
  isDeploymentReady,
  isModelTrainingInProgress,
} from '../../../store/projectModelInfoState/utils';
import { useWorkflowAssistantState } from '../../../components/WorkflowAssistant/state';
import { StepName } from '../../../types/client';
import InstantLearningModels from '../InstantLearningModels';
import { useModelStatusQuery } from '@/serverStore/projectModels';

const Toolbar: React.FC = () => {
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  const { id: projectId, labelType } = useGetSelectedProjectQuery().data ?? {};
  const { data: modelStatusRes } = useModelStatusQuery(projectId, currentModelId);
  const { status: modelStatus, metricsReady } = modelStatusRes ?? {};
  const modelTrainingSuccessful = isModelTrainingSuccessful(modelStatus, metricsReady);
  const isTraining = isModelTrainingInProgress(modelStatus, metricsReady);
  const {
    state: { viewMode, rightSidebar },
    dispatch,
  } = useDataBrowserState();
  const {
    state: { goButtonClicked, step },
    dispatch: workflowAssitantDispatch,
  } = useWorkflowAssistantState();

  useKeyPress(
    'i',
    useCallback(() => {
      if (labelType === LabelType.BoundingBox) {
        dispatch(draft => {
          draft.viewMode = draft.viewMode === 'image' ? 'instance' : 'image';
        });
      }
    }, [dispatch, labelType]),
  );
  const [rightSidebarParams, setRightSidebarParams] = useStateSyncSearchParams('rightSidebar', '');

  useEffect(() => {
    if (rightSidebarParams) {
      dispatch(draft => {
        draft.rightSidebar = rightSidebarParams as DataBrowserState['rightSidebar'];
      });
    }
  }, [dispatch, rightSidebarParams]);

  useEffect(() => {
    setRightSidebarParams(rightSidebar || '');
  }, [rightSidebar, setRightSidebarParams]);

  // If go button in workflow assistant is clicked, and the current step is predict, then open the model performance
  // panel, and mark go button unclicked
  useEffect(() => {
    if (
      (goButtonClicked && step?.stepName === StepName.Predict) ||
      modelTrainingSuccessful ||
      isTraining
    ) {
      dispatch(state => {
        state.rightSidebar = 'model_performance';
      });

      workflowAssitantDispatch(state => {
        state.goButtonClicked = false;
      });
    }
    // instant project show model_performance when deploymentReady
    if (labelType === LabelType.SegmentationInstantLearning && isDeploymentReady(modelStatus)) {
      dispatch(state => {
        state.rightSidebar = 'model_performance';
      });
    }
  }, [
    dispatch,
    goButtonClicked,
    isTraining,
    labelType,
    metricsReady,
    modelStatus,
    modelTrainingSuccessful,
    step?.stepName,
    workflowAssitantDispatch,
  ]);

  return (
    <>
      <Grid container spacing={4}>
        {/* only object detection has instance view */}
        {labelType === LabelType.BoundingBox && (
          <Grid item>
            <SwitchButtonGroup>
              <Button
                id="image-view-toggle-button"
                className={viewMode === 'image' ? 'active' : undefined}
                tooltip={t('View all labels in an image at a time. Press {{key}} to toggle.', {
                  key: <code>i</code>,
                })}
                tooltipOnButton
                onClick={() =>
                  dispatch(draft => {
                    draft.viewMode = 'image';
                    // pair view is removed in new version of instance view, so decouple with instance view
                    draft.showGroundTruth = true;
                    draft.showPredictions = true;
                  })
                }
                aria-pressed={viewMode === 'image'}
              >
                {t('Images')}
              </Button>
              <Button
                id="instance-view-toggle-button"
                className={viewMode === 'instance' ? 'active' : undefined}
                tooltip={
                  currentModelId
                    ? t('View only one label in an image at a time. Press {{key}} to toggle.', {
                        key: <code>i</code>,
                      })
                    : t('Instance view is available after training a model.')
                }
                tooltipOnButton
                onClick={() => {
                  if (!currentModelId) return;
                  dispatch(draft => {
                    draft.viewMode = 'instance';
                    // pair view is removed in new version of instance view, so set showGroundTruth to true as default
                    draft.showGroundTruth = true;
                    draft.showPredictions = false;
                  });
                }}
                aria-pressed={viewMode === 'instance'}
              >
                {t('Instances')}
              </Button>
            </SwitchButtonGroup>
          </Grid>
        )}
        <Grid item>
          <MediaFilter />
        </Grid>
        <Grid item>
          <SortDropdown />
        </Grid>

        <Box flex={1} />

        <Grid item>
          <Grid container wrap="nowrap" spacing={4}>
            <Grid item>
              {labelType === LabelType.SegmentationInstantLearning ? (
                <InstantLearningModels />
              ) : (
                <ModelPerformance />
              )}
            </Grid>
          </Grid>

          {/* Media selections, pagination */}
          <MediaSelection />
        </Grid>
      </Grid>

      <AppliedFilters />
    </>
  );
};

export default Toolbar;

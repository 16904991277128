import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useLivePredictions } from './hooks/useLivePredictions';
import { usePredictContext } from './predictContext';

export const usePredictWithModel = ({
  selectedProjectId,
  selectedDatasetId,
  selectedModelId,
}: {
  selectedProjectId?: number;
  selectedDatasetId?: number;
  selectedModelId?: string;
}) => {
  const {
    livePredictions: livePredictionsWithContext,
    setLivePredictions: setLivePredictionsWithContext,
    clearLivePredictions: clearLivePredictionsWithContext,
    getImageFilePredictions: getImageFilePredictionsWithContext,
    refreshImageFilePredictions: refreshImageFilePredictionsWithContext,
  } = usePredictContext();
  const { id: defaultProjectId, datasetId: defaultDatasetId } =
    useGetSelectedProjectQuery().data ?? {};
  const projectId = selectedProjectId ?? defaultProjectId;
  const datasetId = selectedDatasetId ?? defaultDatasetId;
  const {
    livePredictions: livePredictionsWithSelectedModel,
    setLivePredictions: setLivePredictionsWithSelectedModel,
    clearLivePredictions: clearLivePredictionsWithSelectedModel,
    getImageFilePredictions: getImageFilePredictionsWithSelectedModel,
    refreshImageFilePredictions: refreshImageFilePredictionsWithSelectedModel,
  } = useLivePredictions(projectId, datasetId, selectedModelId);
  return {
    livePredictions: selectedModelId
      ? livePredictionsWithSelectedModel
      : livePredictionsWithContext,
    setLivePredictions: selectedModelId
      ? setLivePredictionsWithSelectedModel
      : setLivePredictionsWithContext,
    clearLivePredictions: selectedModelId
      ? clearLivePredictionsWithSelectedModel
      : clearLivePredictionsWithContext,
    getImageFilePredictions: selectedModelId
      ? getImageFilePredictionsWithSelectedModel
      : getImageFilePredictionsWithContext,
    refreshImageFilePredictions: selectedModelId
      ? refreshImageFilePredictionsWithSelectedModel
      : refreshImageFilePredictionsWithContext,
  };
};

import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useIsPricingV2User } from '@/hooks/useSubscriptions';

const useStyles = makeStyles(theme => ({
  faqRoot: {},
  accordionRoot: {
    boxShadow:
      '0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08)!important',
    borderRadius: '16px!important',
    margin: `${theme.spacing(0, 0, 4, 0)}!important`,
    '&:before': {
      display: 'none',
    },
    border: '1px solid ' + theme.palette.divider,
    width: 600,
  },
  accordionSummaryRoot: {
    padding: `${theme.spacing(6, 8)}!important`,
    borderRadius: '16px!important',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[25] + '!important',
  },
  accordionDetailsRoot: {
    padding: `${theme.spacing(0, 8, 6, 8)}!important`,
    '& ul': {
      padding: '0.9em',
    },
    '& ul li': {
      listStyle: 'disc',
    },
  },
}));

export type FrequentlyAskedQuestionsProps = {
  // TODO: Will remove this flag after migration
  pricingV2?: boolean;
};

const FrequentlyAskedQuestions: React.FC<FrequentlyAskedQuestionsProps> = ({ pricingV2 }) => {
  const styles = useStyles();
  const isPricingV2 = useIsPricingV2User();

  const faqList = [
    {
      title: t('What are credits?'),
      content: (
        <Box>
          {t(
            'Credits are a form of currency on the LandingLens platform and can be used for training a model or running inferences (for example, through the Predict functionality).',
          )}
          {isPricingV2 && <p></p>}
          {isPricingV2 &&
            t(
              'In the free plan, there is a 1000 maximum credit usage each month. In the Visionary plan, there are 5000 credits included in the monthly subscription; any additional credit usage will be charged at $0.01/credit. There is a maximum 500,000 credit usage per month on the Visionary plan.',
            )}
        </Box>
      ),
    },
    {
      title: t('What can I spend my credits on?'),
      content: (
        <Box>
          <Typography>
            {t(
              'Credits can be used for training models or making predictions. A credit is equal to 1 image trained or 1 image inferenced (used for prediction).',
            )}
            <Box>{t('For example, with a 1000 credit package you can:')}</Box>
            <Box marginLeft={4}>
              <ul>
                <li>
                  <Typography>
                    {t(
                      'Train a project with 200 images. This will cost 200 credits and leave you with a remaining balance of 800 credits.',
                    )}
                  </Typography>
                  <li>
                    <Typography>
                      {t(
                        'Test 10 images using the Predict function by dragging and dropping images into the Predict window. This will cost 10 credits and the remaining balance will be 790.',
                      )}
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      {t(
                        'Deploy the model to a cloud endpoint and begin sending images to the API endpoint. Each image sent to the endpoint will cost 1 credit.',
                      )}
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      {t(
                        'For Custom Training, changes in the settings will impact the cost of each image trained and inferences.',
                      )}
                    </Typography>
                  </li>
                </li>
              </ul>
            </Box>
          </Typography>
        </Box>
      ),
    },
    {
      title: t('What if I go over my allotted credits?'),
      content: (
        <Box>
          {isPricingV2
            ? t(
                'In the free plan, you will not be able to run a training or inference once you reach the 1000 credit limit. In the Visionary plan, you will be charged in arrears for any credits used over the monthly 5000 credits included in your $50/month subscription. Credits used beyond the 5000 credits will be charged at $0.01/credit.',
              )
            : t(
                'By default, your organization will be upgraded to the next credit package within the tier. You can downgrade to a lower credit package whenever you would like to.',
              )}
        </Box>
      ),
    },
    {
      title: t('Do credits rollover at the end of the billing cycle?'),
      content: (
        <Box>
          {isPricingV2
            ? t('Credits do not rollover each month for the free plan or the Visionary plan.')
            : t(
                'Credits do not rollover for Starter or Visionary tiers and will reset each month.',
              )}
        </Box>
      ),
    },
    {
      title: t('What’s the difference between commercial and noncommercial use?'),
      content: (
        <Box>
          {t(
            '"Noncommercial" means not primarily intended for or directed towards commercial advantage or monetary compensation.',
          )}
        </Box>
      ),
    },
  ];

  const filteredFaqList = pricingV2 ? faqList : faqList.slice(0, -1);
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h1">{t('Frequently Asked Questions')}</Typography>
      <Box marginTop={6}>
        {filteredFaqList.map(({ title, content }, index) => (
          <Accordion key={index} className={styles.accordionRoot}>
            <AccordionSummary expandIcon={<ExpandMore />} className={styles.accordionSummaryRoot}>
              {title}
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDetailsRoot}>{content}</AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default FrequentlyAskedQuestions;

import React, { useState } from 'react';
import PricingBannerCommon from './PricingBannerCommon';
import { Box } from '@material-ui/core';
import { Button, Typography } from '@clef/client-library';
import { useCheckUserReachLimit } from '@/hooks/useSubscriptions';
import { InviteMembersDialog } from './InviteMembersDialog';
import ContactUsDialog from './ContactUsDialog';
import UsageProgressBar from './UsageProgressBar';

const PricingBannerMembers = () => {
  const { limit, usage, hasReachLimit: shouldOpenContactUsDialog } = useCheckUserReachLimit();
  const isUserUnlimited = limit === -1;
  const [inviteUsersDialogOpen, setInviteUsersDialogOpen] = useState<boolean>(false);
  const [contactUsDialogOpen, setContactUsDialogOpen] = useState<boolean>(false);
  return (
    <>
      <InviteMembersDialog
        open={inviteUsersDialogOpen}
        onClose={() => setInviteUsersDialogOpen(false)}
        onReachUserLimit={() => {
          setInviteUsersDialogOpen(false);
          setContactUsDialogOpen(true);
        }}
      />
      <ContactUsDialog open={contactUsDialogOpen} onClose={() => setContactUsDialogOpen(false)} />
      <PricingBannerCommon
        content={{
          heading: t('Invite people to collaborate on projects'),
          body: t(
            'LandingLens encourages team collaboration. You can invite people to the organization and assign roles accordingly. Roles can be changed later, and organization ownership can be transferred by the owner on this page.',
          ),
          links: [
            {
              text: t('Learn more about roles and permissions'),
              url: 'https://support.landing.ai/docs/members?highlight=roles',
            },
          ],
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-end'}
          justifyContent={'center'}
        >
          <Box>
            <Typography>
              {t('You can invite {{userLimitText}} members', {
                userLimitText: isUserUnlimited ? t('unlimited') : t('up to {{limit}}', { limit }),
              })}
            </Typography>
          </Box>
          <UsageProgressBar usage={usage} limit={limit} />
          <Box paddingBottom={5} />
          {shouldOpenContactUsDialog ? (
            <Button
              id="org-settings-contact-us-button"
              color="primary"
              variant="contained"
              onClick={() => setContactUsDialogOpen(true)}
            >
              {t('Contact us for more seats')}
            </Button>
          ) : (
            <Button
              id="org-settings-invite-members-button"
              color="primary"
              variant="contained"
              onClick={() => setInviteUsersDialogOpen(true)}
            >
              {t('Invite Members')}
            </Button>
          )}
        </Box>
      </PricingBannerCommon>
    </>
  );
};

export default PricingBannerMembers;

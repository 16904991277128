import React from 'react';
import { Box, Dialog, makeStyles } from '@material-ui/core';
import GroupedImages from '../../components/GroupedImages/GroupedImages';
import { MediaId } from '@clef/shared/types';
import ViewImagesButtonGroup from './ViewImagesButtonGroup';

const useStyles = makeStyles(() => ({
  paperRoot: {
    width: '100vw',
    maxWidth: '100vw',
    height: '100vh',
    maxHeight: '100vh',
    margin: 0,
    borderRadius: 0,
  },
}));

export type BrowseImagesFullscreenDialogProps = {
  open: boolean;
  onClose?: (mediaId?: MediaId) => void;
};

const BrowseImagesFullscreenDialog: React.FC<BrowseImagesFullscreenDialogProps> = props => {
  const { open, onClose } = props;
  const styles = useStyles();
  return (
    <Dialog open={open} onClose={() => onClose?.()} classes={{ paper: styles.paperRoot }}>
      <Box paddingX={17} paddingY={10}>
        <ViewImagesButtonGroup onClick={mode => mode === 'sidebar' && onClose?.()} />
        <Box marginBottom={4} />
        <GroupedImages onImageClick={mediaId => onClose?.(mediaId)} />
      </Box>
    </Dialog>
  );
};

export default BrowseImagesFullscreenDialog;

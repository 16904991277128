import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Typography, Grid, Link, Box } from '@material-ui/core';
import cx from 'classnames';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { useStyles } from './newStyles';
import AuthAPI from '../../api/auth_api';
import {
  LandingLensLogo,
  ContinueWithEnterpriseSsoButton,
  ContinueWithGoogleButton,
  OrSeparator,
} from '../../components/Auth/Common';
import { EmailTextField, PasswordTextField } from '../../components/Auth/TextField';
import { PrimaryButton } from '../../components/Auth/Button';
import { loginAccountSuccess, loginAccountFailure } from '../../store/newLoginState/actions';
import { CLEF_PATH, SsoLoginOpener, JoinOrgOpener } from '../../constants/path';
import { AuthPageLayout } from '../../components/Layout/AuthPageLayout';
import { isFDA } from '../../constants';

interface FormValues {
  email: string;
  password: string;
}

export const Login: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isLogining, setIsLogining] = useState(false);
  const { control, errors, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = useCallback(
    async ({ email, password }: FormValues) => {
      setIsLogining(true);

      try {
        const { data } = await AuthAPI.newLogin(email, password);

        // After dispatching this action, auth router will auto redirect to join org page
        dispatch(loginAccountSuccess(data));

        history.push(`${CLEF_PATH.login.joinOrg}?opener=${JoinOrgOpener.Login}`);
      } catch (e) {
        if ((e?.message as string).startsWith('Incorrect username or password.')) {
          enqueueSnackbar(
            <div>
              {t(
                'Incorrect username or password. Please check your login information and try again.',
              )}{' '}
              {t('Or')}{' '}
              <Link
                className={styles.resetPasswordLink}
                onClick={() => history.push(CLEF_PATH.login.forgetPassword)}
              >
                {t('reset your password.')}
              </Link>
            </div>,
            { variant: 'error', autoHideDuration: 12000 },
          );
        } else {
          enqueueSnackbar(e?.message, { variant: 'error', autoHideDuration: 12000 });
        }
        dispatch(loginAccountFailure(e));
        setIsLogining(false);
      }
    },
    [dispatch, enqueueSnackbar, history, styles.resetPasswordLink],
  );

  return (
    <AuthPageLayout metaTitle={t('Login')} showExamples>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        minHeight="100vh"
        position="relative"
      >
        <LandingLensLogo />

        <Typography variant="h1" className={styles.helperText}>
          {t('Sign In')}
        </Typography>

        {!isFDA() && <ContinueWithGoogleButton className={styles.marginBottom3} />}

        <ContinueWithEnterpriseSsoButton
          className={styles.marginBottom8}
          opener={SsoLoginOpener.Login}
        />

        <OrSeparator />

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box className={styles.marginBottom6}>
            <EmailTextField error={errors?.email} control={control} />
          </Box>

          <Box className={styles.marginBottom8}>
            <PasswordTextField error={errors?.password} control={control} showForgetYourPassword />
          </Box>

          <Box className={styles.marginBottom6}>
            <PrimaryButton
              id="sign-in-with-email-and-password"
              text={t('Sign In')}
              disabled={!!errors.email || !!errors.password}
              isPending={isLogining}
              useNewUi
            />
          </Box>
        </Box>

        <Grid
          container
          direction="row"
          className={cx(styles.autoWidth, styles.marginBottom6)}
          alignItems="center"
        >
          <Typography className={cx(styles.alreadyHaveAnAccountText, styles.marginRight1)}>
            {t('Don’t have an account?')}
          </Typography>

          <Link
            className={cx(styles.link, styles.newLink)}
            onClick={() => history.push(CLEF_PATH.signup.main + location.search)}
          >
            {t('Sign Up')}
          </Link>
        </Grid>

        <Link
          className={cx(styles.link, styles.newLink)}
          onClick={() => history.push('legacy_login' + location.search)}
        >
          {t('Switch to Legacy Sign In')}
        </Link>
      </Box>
    </AuthPageLayout>
  );
};

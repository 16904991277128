import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Divider,
  Grid,
  IconProps,
  makeStyles,
  Paper,
  Popover,
  Tooltip,
} from '@material-ui/core';
import {
  AnnotationChangeType,
  Button,
  isMacOS,
  MediaInteractiveCanvas,
  useKeyPress,
} from '@clef/client-library';
import { MutableRefObject } from '@testing-library/react/node_modules/@types/react';
import { ToolMode, useLabelingState } from '../../../components/Labeling/labelingState';
import { bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import classNames from 'classnames';
import HandIcon from '../icons/HandIcon';
import PenIcon from '../icons/PenIcon';
import EraserIcon from '../icons/EraserIcon';
import LineWidthSelector from './LineWidthSelector';
import UndoIcon from '../icons/UndoIcon';
import RedoIcon from '../icons/RedoIcon';

const useStyles = makeStyles(theme => ({
  labelingTools: {
    padding: theme.spacing(0, 2.5),
    borderRadius: 16,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    color: theme.palette.grey[600],
    height: 48,

    '& button': {
      height: 32,
      minWidth: 32,
      padding: 0,
      margin: theme.spacing(0, 0),
      borderRadius: 10,
      color: theme.palette.grey[600],

      '& svg': {
        width: 16,
        height: 16,
      },
    },

    '& + &': {
      marginLeft: theme.spacing(3),
    },
  },
  verticalLine: {
    height: 24,
    margin: theme.spacing(0, 2.5),
  },
  lineWidthInput: {
    '&:hover $notchedOutline': {
      display: 'block',
    },
  },
  notchedOutline: {
    display: 'none',
  },
  numberInput: {
    width: 42,
    fontWeight: 500,
  },
  toolOptions: {
    padding: theme.spacing(0, 2.5),
    borderRadius: 16,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',

    '& button': {
      height: 40,
      minWidth: 40,
      padding: theme.spacing(0, 2.5),
      margin: theme.spacing(2.5, 0),
      borderRadius: 0,
    },
  },
  selectedOption: {
    color: theme.palette.primary.main,
  },
  arrowUpIcon: {
    color: theme.palette.grey[400],
  },
  active: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
  },
  clean: {
    '& svg': {
      height: '18px !important',
    },
  },
  labelingToolsBox: {
    width: 'auto',
  },
}));

type LabelingToolOptions = {
  tool: ToolMode;
  Icon: React.FC<IconProps | {}>;
  hotkey: string;
  name: string;
  subTools?: {
    showSizeTool?: boolean;
  };
};

const AllToolOptions: LabelingToolOptions[] = [
  {
    tool: ToolMode.Brush,
    Icon: PenIcon,
    hotkey: 'b',
    name: 'Brush',
    subTools: {
      showSizeTool: true,
    },
  },
];

type LabelingToolsProps = {
  mediaCanvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
  hasAnnotation: boolean;
  deleteOption?: AnnotationChangeType.Delete | AnnotationChangeType.DeleteGroundTruth;
};

const CleanIcon = () => (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3333 9.16671H10.5V2.50004C10.5 1.58337 9.75 0.833374 8.83333 0.833374H7.16667C6.25 0.833374 5.5 1.58337 5.5 2.50004V9.16671H4.66667C2.36667 9.16671 0.5 11.0334 0.5 13.3334V19.1667H15.5V13.3334C15.5 11.0334 13.6333 9.16671 11.3333 9.16671ZM7.16667 2.50004H8.83333V9.16671H7.16667V2.50004ZM13.8333 17.5H12.1667V15C12.1667 14.5417 11.7917 14.1667 11.3333 14.1667C10.875 14.1667 10.5 14.5417 10.5 15V17.5H8.83333V15C8.83333 14.5417 8.45833 14.1667 8 14.1667C7.54167 14.1667 7.16667 14.5417 7.16667 15V17.5H5.5V15C5.5 14.5417 5.125 14.1667 4.66667 14.1667C4.20833 14.1667 3.83333 14.5417 3.83333 15V17.5H2.16667V13.3334C2.16667 11.9584 3.29167 10.8334 4.66667 10.8334H11.3333C12.7083 10.8334 13.8333 11.9584 13.8333 13.3334V17.5Z"
      fill="#F04438"
    />
  </svg>
);

const LabelingTools: React.FC<LabelingToolsProps> = props => {
  const { mediaCanvasRef, hasAnnotation, deleteOption = AnnotationChangeType.Delete } = props;
  const styles = useStyles();

  const undo = useCallback(() => {
    mediaCanvasRef.current?.undo();
  }, [mediaCanvasRef]);

  const redo = useCallback(() => {
    mediaCanvasRef.current?.redo();
  }, [mediaCanvasRef]);

  const { state, dispatch } = useLabelingState();
  const {
    toolMode,
    toolOptions: { erasing },
  } = state;
  const setPanMode = useCallback(() => {
    dispatch(draft => {
      draft.toolMode = undefined;
    });
  }, [dispatch]);
  useKeyPress('v', setPanMode);
  useKeyPress('b', () =>
    dispatch(draft => {
      draft.toolMode = ToolMode.Brush;
      draft.toolOptions.erasing = false;
    }),
  );

  const clearAllLabels = useCallback(() => {
    mediaCanvasRef.current?.setAnnotations([], deleteOption);
  }, [deleteOption, mediaCanvasRef]);

  const selectedToolOption = useMemo(() => {
    return AllToolOptions.find(o => o.tool === toolMode) ?? AllToolOptions[0];
  }, [toolMode]);
  const { Icon: SelectedToolIcon, tool, hotkey, name, subTools } = selectedToolOption;
  const { showSizeTool } = subTools ?? {};

  useKeyPress('e', () => {
    dispatch(draft => {
      draft.toolOptions.erasing = !draft.toolOptions.erasing;
      draft.toolMode = tool;
    });
  });

  const popupState = usePopupState({
    popupId: 'tool-selector-popup',
    variant: 'popover',
  });

  return (
    <Grid container className={styles.labelingToolsBox} alignItems="center" wrap="nowrap">
      <Box className={styles.labelingTools}>
        <Tooltip placement="top" arrow title={t('Pan {{key}}', { key: <code>v</code> })}>
          <Button
            id="pan"
            onClick={setPanMode}
            className={classNames({
              [styles.active]: !toolMode,
            })}
          >
            <HandIcon />
          </Button>
        </Tooltip>

        <Divider orientation="vertical" className={styles.verticalLine} />

        {/* tool selector */}
        <Button
          id="select-tool"
          tooltip={t('{{name}} {{key}}', { name, key: <code>{hotkey}</code> })}
          className={classNames({
            [styles.active]: toolMode === tool && !erasing,
          })}
          onClick={() => {
            dispatch(draft => {
              draft.toolMode = tool;
              draft.toolOptions.erasing = false;
            });
          }}
        >
          <SelectedToolIcon />
        </Button>
        <Button
          id={`segmentation-tool-${tool}-erase`}
          tooltip={t('Eraser {{key}}', {
            key: <code>e</code>,
          })}
          className={classNames({
            [styles.active]: erasing,
          })}
          onClick={() => {
            dispatch(draft => {
              draft.toolOptions.erasing = true;
              draft.toolMode = tool;
            });
          }}
        >
          <EraserIcon />
        </Button>

        {showSizeTool && <LineWidthSelector />}

        <Popover
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          {...bindPopover(popupState)}
        >
          <Paper className={styles.toolOptions}>
            {AllToolOptions.map(({ tool, name, hotkey, Icon }) => (
              <Button
                id={tool}
                key={tool}
                tooltip={t('{{name}} {{key}}', { name, key: <code>{hotkey}</code> })}
                onClick={() => {
                  dispatch(draft => {
                    draft.toolMode = tool;
                  });
                  popupState.close();
                }}
                className={classNames(tool === toolMode && styles.selectedOption)}
              >
                <Icon />
              </Button>
            ))}
          </Paper>
        </Popover>
        <Divider orientation="vertical" className={styles.verticalLine} />
        <Button
          id="undo"
          onClick={undo}
          tooltip={t('Undo {{key}}', {
            key: (
              <>
                <code>{isMacOS() ? 'Command' : 'Ctrl'}</code> + <code>Z</code>
              </>
            ),
          })}
        >
          <UndoIcon />
        </Button>
        <Button
          id="redo"
          onClick={redo}
          tooltip={t('Redo {{key}}', {
            key: (
              <>
                <code>Shift</code> + <code>{isMacOS() ? 'Command' : 'Ctrl'}</code> + <code>Z</code>
              </>
            ),
          })}
        >
          <RedoIcon />
        </Button>
        <Divider orientation="vertical" className={styles.verticalLine} />
        {/* remove all labels within current image */}
        <Button
          id="remove-all-label-button"
          onClick={clearAllLabels}
          tooltip={t('Clear All Labels')}
          color={!hasAnnotation ? 'primary' : 'secondary'}
          disabled={!hasAnnotation}
          className={styles.clean}
        >
          <CleanIcon />
        </Button>
      </Box>
    </Grid>
  );
};

export default LabelingTools;

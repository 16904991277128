import { Box, Divider, Paper, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import TestClock from './TestClock';
import UsageReporter from './UsageReporter';

export type InternalSettingsProps = {};

const InternalSettings: React.FC<InternalSettingsProps> = () => {
  return (
    <Box>
      <Paper>
        <Box padding={4}>
          <Box marginBottom={4}>
            <Typography variant="h3">{t('Test tools')}</Typography>
          </Box>
          <Alert color="info">
            {t('This is for testing only. Use this feature to test subscriptions.')}
          </Alert>

          <TestClock />

          <Divider />

          <UsageReporter />
        </Box>
      </Paper>
    </Box>
  );
};

export default InternalSettings;

import React, { useState } from 'react';
import PricingBannerCommon from '../components/PricingBannerCommon';
import { Box } from '@material-ui/core';
import { Button, Typography } from '@clef/client-library';
import ContactUsDialog from '../components/ContactUsDialog';
import { useCheckActiveProjectReachLimit, useIsNonStripeUser } from '@/hooks/useSubscriptions';
import UsageProgressBar from '../components/UsageProgressBar';
import AddActiveProjectDialog from './AddActiveProjectDialog';
import { makeStyles } from '@material-ui/core/styles';

const TOOLTIP_FOR_NON_COMMERCIAL_USE = t(
  'LandingLens empowers you to download models for offline, noncommercial use. Once a project is activated, you can download an unlimited number of models from the project.',
);

const TOOLTIP_FOR_COMMERCIAL_USE = t(
  'LandingLens empowers you to download models from your projects for offline use. Once a project is eligible, you can download an unlimited number of models from it for commercial use.',
);

const useLocalStyles = makeStyles(theme => ({
  contactUsBtn: {
    padding: theme.spacing(0, 1),
  },
}));

const PricingBannerActiveProjects = () => {
  const {
    limit,
    usage,
    hasReachLimit: shouldOpenContactUsDialog,
  } = useCheckActiveProjectReachLimit();
  const localStyles = useLocalStyles();
  const [addActiveProjectOpen, setAddActiveProjectOpen] = useState<boolean>(false);
  const [contactUsDialogOpen, setContactUsDialogOpen] = useState<boolean>(false);
  const isNonStripeUser = useIsNonStripeUser();
  return (
    <>
      <AddActiveProjectDialog
        open={addActiveProjectOpen}
        onClose={() => {
          setAddActiveProjectOpen(false);
        }}
      />
      <ContactUsDialog open={contactUsDialogOpen} onClose={() => setContactUsDialogOpen(false)} />
      <PricingBannerCommon
        content={{
          heading: t('Unlock the Power of Offline Models'),
          body: isNonStripeUser ? TOOLTIP_FOR_COMMERCIAL_USE : TOOLTIP_FOR_NON_COMMERCIAL_USE,
          links: isNonStripeUser
            ? [{ text: t('Learn more'), url: 'https://landing.ai/terms-of-service' }]
            : [
                {
                  text: t('Contact us for commercial inquiries'),
                  onClick: () => setContactUsDialogOpen(true),
                },
                { text: t('Learn more'), url: 'https://landing.ai/terms-of-service' },
              ],
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-end'}
          justifyContent={'center'}
        >
          <Box>
            <Typography>{t('Active Projects')}</Typography>
          </Box>
          <UsageProgressBar usage={usage} limit={limit ?? 0} />
          <Box paddingBottom={5} />
          {shouldOpenContactUsDialog ? (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Typography variant="body_regular">{t('Want to activate more projects?')}</Typography>
              <Button
                className={localStyles.contactUsBtn}
                id="org-settings-contact-us-button"
                color="primary"
                variant="text"
                onClick={() => setContactUsDialogOpen(true)}
              >
                {t('Contact us')}
              </Button>
            </Box>
          ) : (
            <Button
              id="org-settings-add-active-project-button"
              color="primary"
              variant="contained"
              onClick={() => setAddActiveProjectOpen(true)}
            >
              {t('Activate a Project')}
            </Button>
          )}
        </Box>
      </PricingBannerCommon>
    </>
  );
};

export default PricingBannerActiveProjects;

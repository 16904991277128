import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  Link,
  makeStyles,
} from '@material-ui/core';
import { DateStringISO, SubscriptionName, TermsAndConditionsType } from '@clef/shared/types';
import { format } from 'date-fns';
import { Button, Typography } from '@clef/client-library';
import { useCurrentSubscription } from '@/hooks/useSubscriptions';
import { getPlanName } from '@/pages/plan/utils';
import CreditCard from '@material-ui/icons/CreditCard';
import AttachMoney from '@material-ui/icons/AttachMoney';
import LockOpen from '@material-ui/icons/LockOpen';
import Markdown from 'markdown-to-jsx';
import OpenInNew from '@material-ui/icons/OpenInNew';
import classNames from 'classnames';
import subscription_api from '@/api/subscription_api';
import { useSnackbar } from 'notistack';
import { refreshSubscriptionPlanSettings } from '@/hooks/api/useSubscriptionApi';

const useStyles = makeStyles(theme => ({
  dialogContentRoot: {
    width: 600,
    padding: '0 !important',
    '& li': {
      margin: theme.spacing(2, 0),
    },
  },
  subTitle: {
    color: theme.palette.greyModern[500],
  },
  slide: {
    display: 'flex',
    width: '100%',
    height: 220,
    alignItems: 'center',
    backgroundColor: theme.palette.greyModern[50],
    padding: theme.spacing(8),
    marginTop: theme.spacing(6),
  },
  slideDot: {
    padding: theme.spacing(1),
    cursor: 'pointer',
    '&::after': {
      content: '""',
      display: 'block',
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: theme.palette.greyBlue[300],
    },
  },
  slideDotActive: {
    '&::after': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

type Slide = {
  icon: JSX.Element;
  title: string;
  bullets: string[];
};

const freeSlides: Slide[] = [
  {
    icon: <CreditCard fontSize="large" />,
    title: t('Payment updates'),
    bullets: [t('Your remaining balance has been refunded.'), t('The new Free Plan is... free!')],
  },
  {
    icon: <LockOpen fontSize="large" />,
    title: t('Unlock the power of edge inference'),
    bullets: [
      t('Get **1000 free credits** every month'),
      t('Create **unlimited** projects'),
      t('Download models for **offline, noncommercial use**'),
      t('Access LandingEdge and our Docker application for free'),
    ],
  },
];
const visionarySlides: Slide[] = [
  {
    icon: <CreditCard fontSize="large" />,
    title: t('Payment updates'),
    bullets: [
      t('Your account is **refunded** to your payment balance.'),
      t(
        'You will be charged **$50** on **the 1st of each month**, plus any additional credit usage beyond 5000 credits from the previous month.',
      ),
      t('The refunded balance will be applied before charging your original payment method.'),
      t('You can cancel or change your plan any time.'),
    ],
  },
  {
    icon: <AttachMoney fontSize="large" />,
    title: t('On-demand credit usage'),
    bullets: [
      t('**5000 credits included** each month (credits do not accumulate month to month)'),
      t(
        '**Additional credit usage** (beyond the 5000 credits) will be billed in arrears at a rate of **$0.01/credit**',
      ),
    ],
  },
  {
    icon: <LockOpen fontSize="large" />,
    title: t('Unlock the power of edge inference'),
    bullets: [
      t('Download models for **offline, noncommercial** use'),
      t('Access LandingEdge and our Docker application for free'),
    ],
  },
];

const slidesMap = {
  [SubscriptionName.Free]: freeSlides,
  [SubscriptionName.Visionary]: visionarySlides,
};

export type PricingV2MigrationConfirmationDialogProps = {
  migratedAt: DateStringISO;
};

const PricingV2MigrationConfirmationDialog: React.FC<
  PricingV2MigrationConfirmationDialogProps
> = props => {
  const { migratedAt } = props;
  const formattedDate = format(new Date(migratedAt), 'dd MMM yyyy');
  const styles = useStyles();
  const subscription = useCurrentSubscription();
  const slides = slidesMap[subscription?.meta.plan as keyof typeof slidesMap] ?? [];
  const planName = getPlanName(subscription);

  const [step, setStep] = useState(0);
  const [hasRead, setHasRead] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const slide = slides[step];

  const { enqueueSnackbar } = useSnackbar();

  if (!subscription || !slides || !slide) {
    return null;
  }

  return (
    <Dialog open>
      <DialogContent className={styles.dialogContentRoot}>
        {/* Title */}
        <Box textAlign="center" paddingTop={8} paddingX={12}>
          <Typography variant="h2_semibold">
            {t('You are now on the new {{plan}} Plan', { plan: planName })}
          </Typography>
        </Box>
        {/* Subtitle */}
        <Box textAlign="center" paddingTop={2} paddingX={12}>
          <Typography variant="body_regular" className={styles.subTitle}>
            {subscription.meta.plan === SubscriptionName.Visionary
              ? t(
                  'As of {{date}}, we transitioned your organization to the new Visionary Plan with {{bold}}!',
                  {
                    date: formattedDate,
                    bold: <strong>{t('lower monthly fees and more features')}</strong>,
                  },
                )
              : t(
                  'As of {{date}}, we transitioned your organization to the new Free Plan with {{bold}}!',
                  {
                    date: formattedDate,
                    bold: <strong>{t('no monthly fees and more features')}</strong>,
                  },
                )}
          </Typography>
        </Box>

        {/* Slide */}
        <Box className={styles.slide}>
          <Box width={150} flexShrink={0} flexGrow={0}>
            <Box textAlign="center">{slide.icon}</Box>
            <Box textAlign="center" marginTop="8">
              {slide.title}
            </Box>
          </Box>
          <Box marginLeft={8} flex={1}>
            <ul>
              {slide.bullets.map(bullet => (
                <li key={bullet}>
                  <Markdown>{bullet}</Markdown>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
        {/* Slide dots */}
        <Box display="flex" justifyContent="center" marginTop={3}>
          {slides.map((_, i) => (
            <Box
              className={classNames(styles.slideDot, i === step && styles.slideDotActive)}
              key={`slide-dot-${i}`}
              role="button"
              onClick={() => setStep(i)}
            ></Box>
          ))}
        </Box>

        {/* Terms of service */}
        <Box paddingX={12} marginTop={1}>
          <FormControlLabel
            control={
              <Checkbox color="primary" checked={hasRead} onChange={() => setHasRead(!hasRead)} />
            }
            label={
              <Typography variant="body_medium">
                {t('I have read and agree to the LandingLens {{termsOfService}}', {
                  termsOfService: (
                    <Link
                      href="https://landing.ai/terms-of-service"
                      target="_blank"
                      color="inherit"
                      underline="always"
                    >
                      {t('Terms of Service')}
                    </Link>
                  ),
                })}
              </Typography>
            }
          />
        </Box>

        {/* Dialog buttons */}
        <Box paddingX={12} paddingTop={3} paddingBottom={8} display="flex">
          <Box flex={1}>
            <Button
              id="learn-more-button"
              endIcon={<OpenInNew />}
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() =>
                window.open('https://support.landing.ai/docs/plan-changes-2024', '_blank')
              }
            >
              {t('Learn More')}
            </Button>
          </Box>
          <Box flex={1} marginLeft={4}>
            <Button
              id="confirm-button"
              variant="contained"
              color="primary"
              disabled={!hasRead || confirming}
              startIcon={confirming && <CircularProgress size={16} color="inherit" />}
              fullWidth
              onClick={async () => {
                setConfirming(true);
                try {
                  await subscription_api.confirmTermsAndConditions(
                    TermsAndConditionsType.PricingV2,
                  );
                  refreshSubscriptionPlanSettings({
                    keys: 'refresh-all',
                    swr: prev => ({ ...prev, pricingV2ConfirmedAt: new Date().toISOString() }),
                  });
                } catch (e) {
                  enqueueSnackbar(e.message, { variant: 'error' });
                }
                setConfirming(false);
              }}
            >
              {t('Got it')}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PricingV2MigrationConfirmationDialog;

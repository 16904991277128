import React from 'react';
import cx from 'classnames';
import { Box } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { useDefectBookEnhancedStyles } from '../defectBookEnhancedStyles';

interface ColorToggleButtonProps {
  color: string;
  selectedColor: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>, color: string) => void;
  disabled?: boolean;
  className?: string;
  disabledClassName?: string;
  colorPaletteClassName?: string;
  disabledColorPaletteClassName?: string;
  selectorColorClassName?: string;
}

const ColorToggleButton = ({
  color,
  selectedColor,
  onClick,
  disabled = false,
  className,
  disabledClassName,
  colorPaletteClassName,
  selectorColorClassName,
  disabledColorPaletteClassName,
}: ColorToggleButtonProps) => {
  const styles = useDefectBookEnhancedStyles();

  if (disabled) {
    return (
      <Box className={cx(styles.disabledBox, disabledClassName)}>
        <div
          className={cx(styles.colorPalette, disabledColorPaletteClassName, styles.disabled)}
          style={{
            backgroundColor: color,
          }}
        ></div>
        <div className={styles.disabledLine} />
      </Box>
    );
  }

  return (
    <ToggleButton
      key={color}
      value={color}
      color="primary"
      classes={{
        root: cx(styles.toggleButton, styles.colorToggleButton, className),
        selected: styles.selected,
      }}
      onClick={event => onClick(event, selectedColor === color ? '' : color)}
    >
      <Box className={cx(selectedColor === color && styles.colorPaletteBorder)}>
        <div
          className={cx(
            styles.colorPalette,
            colorPaletteClassName,
            selectedColor === color && selectorColorClassName,
          )}
          style={{
            backgroundColor: color,
          }}
        ></div>
      </Box>
    </ToggleButton>
  );
};

export default ColorToggleButton;

export const getStepSize = (title: string, type: string | undefined): number => {
  if (title.match(/blur/gi)) {
    return 2;
  }

  if (type === 'integer') {
    return 1;
  }

  return 0.1;
};

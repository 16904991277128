import { EndpointListItem, ProjectId } from '@clef/shared/types';
import { ApiErrorType } from '@/api/base_api';
import { useQuery } from '@tanstack/react-query';
import ClpAPI from '@/api/clp_api';
import ClibAPI from '@/api/clib_api';
import CLIAAPI from '@/api/clia_api';
import { ModelBundle } from '@/pages/deployment/utils';
import { useGetJobDetailQueries } from '../jobs';
import { useGetSelectedProjectQuery } from '../projects';

export const endpointQueryKeys = {
  all: ['endpoints'] as const,
  list: (projectId: ProjectId) => [projectId, ...endpointQueryKeys.all, 'list'] as const,
  bundleList: (projectId: ProjectId) =>
    [projectId, ...endpointQueryKeys.all, 'bundlesList'] as const,
  jobDetailList: (orgId: number, projectId: ProjectId) =>
    [orgId, projectId, ...endpointQueryKeys.all, 'jobDetailList'] as const,
  edgeMediaDetails: (projectId: ProjectId, mediaId: number) => [
    projectId,
    ...endpointQueryKeys.all,
    'edgeMediaDetails',
    mediaId,
  ],
};

export const useGetEndpointListQuery = (projectId: ProjectId) => {
  return useQuery<EndpointListItem[], ApiErrorType>({
    queryKey: endpointQueryKeys.list(projectId),
    queryFn: async () => ClpAPI.getEndpoints(projectId),
  });
};

const useGetBundleListQuery = (projectId: ProjectId) => {
  return useQuery({
    queryKey: endpointQueryKeys.bundleList(projectId),
    queryFn: async () => ClibAPI.listBundles(projectId),
  });
};

export const useGetJobDetailListQuery = (projectId: ProjectId, orgId: number) => {
  const { data: bundleList = [], isLoading: bundleListLoading } = useGetBundleListQuery(projectId);
  // for old bundle records, job_id is empty and bundle_id presents the job ID.
  const jobIds = bundleList.map(bundle => bundle.job_id || bundle.bundle_id);
  const results = useGetJobDetailQueries(orgId, projectId, jobIds);
  const jobList: ModelBundle[] = [];
  let isLoading = bundleListLoading;
  let error: ApiErrorType | undefined;
  results.forEach((result, index) => {
    if (result.isLoading) {
      isLoading = true;
    } else if (result.error) {
      error = result.error as ApiErrorType;
      // eslint-disable-next-line no-console
      console.error(`Error getting model bundle details: ${result.error}`);
    } else if (result.data) {
      // adapt feilds
      const bundle = {
        ...result.data,
        bundleGeneratedTime: bundleList[index].created_at ?? undefined,
        jobName: bundleList[index].model_version || result.data.jobName,
        bundleId: bundleList[index].bundle_id,
        threshold: bundleList[index].threshold,
      } as ModelBundle;
      jobList.push(bundle);
    }
  });
  return {
    data: jobList,
    isLoading,
    error,
  };
};

export const useEdgeMediaDetailsQuery = (mediaId: number) => {
  const { id: projectId = 0 } = useGetSelectedProjectQuery().data ?? {};
  return useQuery({
    queryKey: endpointQueryKeys.edgeMediaDetails(projectId, mediaId),
    queryFn: async () => CLIAAPI.getMediaDetails(projectId, mediaId),
    enabled: !!projectId,
  });
};

import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Popover, List, ListItem, ListItemText } from '@material-ui/core';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { useAtom } from 'jotai';

import { IconButton } from '@clef/client-library';
import CLEF_PATH from '@/constants/path';

import { useDatasetExportedWithVersionsQuery } from '@/serverStore/dataset';
import {
  hideAutoGeneratedSnapshotAtom,
  historyListOffsetAtom,
} from '@/uiStates/datasetSnapshot/pageUIStates';
import { LabelType, RegisteredModel } from '@clef/shared/types';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useSnackbar } from 'notistack';
import DatasetAPI from '@/api/dataset_api';

export type MoreButtonProps = {
  model: RegisteredModel;
  showDownloadCsv?: boolean;
};

const MoreButton: React.FC<MoreButtonProps> = ({
  model,
  showDownloadCsv = false,
}: MoreButtonProps) => {
  const history = useHistory();
  const { datasetVersionId } = model;
  const { data: datasetExported } = useDatasetExportedWithVersionsQuery({
    withCount: true,
    includeNotCompleted: true,
    includeFastEasy: true,
  });
  const versionedDatasetContentId = datasetExported?.datasetVersions?.find(
    e => e.id === datasetVersionId,
  )?.version;

  const curSnapshot = useMemo(
    () =>
      datasetExported?.datasetVersions?.find(item => item.version === versionedDatasetContentId),
    [datasetExported?.datasetVersions, versionedDatasetContentId],
  );
  const [hideAutoGeneratedSnapshot, setHideAutoGeneratedSnapshot] = useAtom(
    hideAutoGeneratedSnapshotAtom,
  );
  const [, setHistoryListOffset] = useAtom(historyListOffsetAtom);

  const { id: projectId, labelType } = useGetSelectedProjectQuery().data ?? {};

  const { enqueueSnackbar } = useSnackbar();

  const [downloadCsvLoading, setDownloadCsvLoading] = useState(false);

  const handleViewModelsPage = () => {
    history.push(`${CLEF_PATH.modelsV2.list}?modelId=${model.id}`);
  };

  const handleDownloadCsv = useCallback(async () => {
    if (!projectId) return;
    try {
      setDownloadCsvLoading(true);
      const res = await DatasetAPI.getModelPerformanceCsv(projectId, model.id);
      window.open(res, '_top');
    } catch (e) {
      enqueueSnackbar('Failed to download CSV. Please try again later.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } finally {
      setDownloadCsvLoading(false);
    }
  }, [enqueueSnackbar, projectId, model.id]);

  return (
    <PopupState variant="popover" popupId="model-card-details-dialog-more-btn-popup-popover">
      {popupState => (
        <>
          <IconButton
            id="model-card-details-dialog-more-btn"
            size="small"
            {...bindTrigger({ ...popupState })}
            onClick={e => {
              e.stopPropagation();
              bindTrigger({ ...popupState }).onClick(e);
            }}
          >
            <MoreHoriz />
          </IconButton>
          <Popover
            onClick={e => {
              e.stopPropagation();
              bindPopover({ ...popupState }).onClose();
            }}
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <List dense>
              {showDownloadCsv && labelType !== LabelType.Segmentation && (
                <ListItem
                  button
                  id="download-csv"
                  disabled={downloadCsvLoading}
                  onClick={handleDownloadCsv}
                >
                  <ListItemText
                    primary={downloadCsvLoading ? t('Downloading') : t('Download CSV')}
                  />
                </ListItem>
              )}
              <ListItem button id="to-models-page" onClick={handleViewModelsPage}>
                <ListItemText primary={t('View on Models Page')} />
              </ListItem>
              {curSnapshot && (
                <ListItem
                  button
                  id="to-snapshot-page"
                  onClick={() => {
                    if (curSnapshot.fastEasyExport && hideAutoGeneratedSnapshot) {
                      setHideAutoGeneratedSnapshot(false);
                      setHistoryListOffset(undefined);
                    }
                    history.push(`${CLEF_PATH.data.datasetSnapshot}/${versionedDatasetContentId}`);
                  }}
                >
                  <ListItemText primary={t('Go to Snapshot Page')} />
                </ListItem>
              )}
            </List>
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default MoreButton;

import React from 'react';
import { Grid } from '@material-ui/core';
import { GridTypeMap } from '@material-ui/core/Grid';

type GridProps = GridTypeMap['props'];

interface ColumnProps extends GridProps {
  className?: string;
  parentGridProps?: GridProps;
  childrenGridProps?: GridProps;
}

export const Column: React.FC<ColumnProps> = ({
  className,
  parentGridProps,
  childrenGridProps,
  children,
}) => {
  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      justifyContent="center"
      wrap="nowrap"
      className={className}
      {...parentGridProps}
    >
      {React.Children.map(children, (component, index) => (
        <Grid item key={index} {...childrenGridProps}>
          {component}
        </Grid>
      ))}
    </Grid>
  );
};

export default Column;

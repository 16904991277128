import { ApiErrorType } from '@/api/base_api';
import maglev_api from '@/api/maglev_api';
import { JobInfoParsed, ModelStatus, ProjectId } from '@clef/shared/types';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useGetSelectedProjectQuery } from '../projects';

export const jobQueryKeys = {
  all: ['jobs'] as const,
  jobDetail: (orgId: number, projectId: ProjectId, jobId: string) =>
    [orgId, projectId, ...jobQueryKeys.all, jobId] as const,
};

const useGetJobDetailQuery = (
  orgId: number | undefined,
  projectId: ProjectId | undefined,
  jobId?: string,
  modelStatus?: ModelStatus | null,
) => {
  return useQuery<JobInfoParsed | undefined, ApiErrorType, JobInfoParsed | undefined>({
    queryKey: jobQueryKeys.jobDetail(orgId!, projectId!, jobId!),
    queryFn: async () =>
      orgId && projectId && jobId ? maglev_api.getJobDetails(projectId, jobId, orgId) : undefined,
    refetchInterval: () => {
      // Only polls during training / evaluating.
      if (modelStatus === ModelStatus.Training || modelStatus === ModelStatus.Evaluating) {
        return 3000;
      }
      return false;
    },
    enabled: Boolean(orgId && projectId && jobId),
  });
};

export const useJobDetailForCurrentProject = (jobId?: string, modelStatus?: ModelStatus | null) => {
  const { orgId, id: projectId } = useGetSelectedProjectQuery().data ?? {};
  return useGetJobDetailQuery(orgId!, projectId!, jobId, modelStatus);
};

export const useGetJobDetailQueries = (
  orgId: number | undefined,
  projectId: ProjectId | undefined,
  jobIds: string[],
) => {
  return useQueries({
    queries: jobIds.map(jobId => ({
      queryKey: jobQueryKeys.jobDetail(orgId!, projectId!, jobId),
      queryFn: async () =>
        orgId && projectId && jobId ? maglev_api.getJobDetails(projectId, jobId, orgId) : undefined,
      enabled: Boolean(orgId && projectId && jobId),
    })),
  });
};

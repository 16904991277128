import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import cx from 'classnames';

import { Button } from '@clef/client-library';

const useStyles = makeStyles(theme => ({
  button: {
    width: 36,
    height: 24,
    padding: 0,
    minWidth: 36,
    background: theme.palette.blue[500],
    '&:hover': {
      backgroundColor: theme.palette.blue[300],
    },
  },
  text: {
    fontWeight: 700,
    color: 'white',
  },
  icon: {
    fontSize: 10,
    color: 'white',
  },
}));

export type GoButtonProps = {
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void | Promise<void>;
};

export const GoButton: React.FC<GoButtonProps> = ({ style, className, onClick }) => {
  const styles = useStyles();

  return (
    <Button
      id="workflow-assistant-go-button"
      data-testid="workflow-assistant-go-button"
      className={cx(styles.button, className)}
      style={style}
      onClick={onClick}
    >
      <Typography variant="body2" className={styles.text}>
        {t('Go')}
      </Typography>

      <ArrowForwardIos className={styles.icon} />
    </Button>
  );
};

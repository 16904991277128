import { ImageLevelClassificationRuleCollection, DefectId, Defect } from '@clef/shared/types';
import { roundToMaxDecimals } from '@clef/shared/utils';

// From https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
const nFormatter = (num: number, digits: number): string => {
  if (num === Number.POSITIVE_INFINITY) {
    return '∞';
  }
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(item => num >= item.value);

  return item
    ? roundToMaxDecimals(num / item.value, digits)
        .toFixed(digits)
        .replace(rx, '$1') + item.symbol
    : '0';
};

export const sliderValueLabelFormat = (value: number): string => {
  return nFormatter(value, 0);
};

export const NUM_SLIDER_STEPS = 1000;
export const MAX_THRESHOLD_VALUE = 256 ** 3 - 1;
export const sliderValueToThresholdValue = (sliderValue: number): number => {
  if (sliderValue === 0) {
    return 0;
  } else if (sliderValue === NUM_SLIDER_STEPS) {
    return Number.POSITIVE_INFINITY;
  }
  return Math.floor(
    10 ** ((Math.log10(MAX_THRESHOLD_VALUE) * sliderValue) / (NUM_SLIDER_STEPS - 1)),
  );
};

export const thresholdValueToSliderValue = (thresholdValue: number): number => {
  if (thresholdValue === 0) {
    return 0;
  } else if (thresholdValue === Number.POSITIVE_INFINITY) {
    return NUM_SLIDER_STEPS;
  }
  return (Math.log10(thresholdValue) * (NUM_SLIDER_STEPS - 1)) / Math.log10(MAX_THRESHOLD_VALUE);
};

export const filterComponentsByAreaAndAddDefectId = (
  blobStatsResult: any,
  defects: Defect[],
  defectIdToSegmentationAreaThreshold: Record<DefectId, number> = {},
): any => {
  const blobStats = blobStatsResult.blob_stats;
  const blobStatsWithDefectId = blobStats.map((blobStat: any) => ({
    ...blobStat,
    defect_id: defects.find(defect => defect.indexId === blobStat.label_index)?.id,
  }));
  const filteredBlobStatsWithDefectId = blobStatsWithDefectId.filter(
    (blobStat: any) =>
      !(blobStat.defect_id in defectIdToSegmentationAreaThreshold) ||
      blobStat.area >= defectIdToSegmentationAreaThreshold[blobStat.defect_id],
  );
  return { ...blobStatsResult, blob_stats: filteredBlobStatsWithDefectId };
};

const isSubset = (setA: Set<any>, setB: Set<any>): boolean => {
  if (setA.size > setB.size) {
    return false;
  }
  return Array.from(setA).every(x => setB.has(x));
};

export const applyImageLevelClassificationRuleCollection = (
  blobStatsResult: any,
  rules: ImageLevelClassificationRuleCollection,
): string => {
  const blobStats = blobStatsResult.blob_stats;
  const defectIdsPresent = new Set(blobStats.map((blobStat: any) => blobStat.defect_id));
  for (const rule of rules.rules) {
    if (rule.operator === 'all') {
      if (isSubset(new Set(rule.defectIds), defectIdsPresent)) {
        return rule.classification;
      }
    } else {
      // TODO: Somehow alert if there was an unrecognized rule type.
      // Right now, it just ignores them...
      continue;
    }
  }
  return rules.defaultClassification;
};

export const generateModelNameByDate = (): string => `Model ${new Date().toLocaleString()}`;

export const generateImageLevelClassificationText = (
  imageLevelClassificationRuleCollection: ImageLevelClassificationRuleCollection | undefined,
) =>
  `${
    imageLevelClassificationRuleCollection?.rules.length
      ? imageLevelClassificationRuleCollection.rules.length
      : 'No'
  } rules applied`;

export const generateNoiseFilterText = (
  allDefects: Defect[],
  defectIdToSegmentationAreaThreshold: Record<DefectId, number> | undefined,
) =>
  allDefects.reduce(
    (noiseFilterText, defect) =>
      defectIdToSegmentationAreaThreshold === undefined
        ? ''
        : `${noiseFilterText ? `${noiseFilterText}, ` : ''}${defect.name} > ${
            defectIdToSegmentationAreaThreshold[defect.id] ?? 0
          }`,
    '',
  );

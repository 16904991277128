import React from 'react';
import { DatasetVersion, DatasetVersionId, RegisteredModel } from '@clef/shared/types';
import { Box, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { useDatasetExportedWithVersionsQuery } from '@/serverStore/dataset';
import ModelTrainingInProgressPanel from '@/pages/model_iteration/componentsV2/ModelDetailsPanel/ModelTrainingInProgressPanel';
import { Button, IconButton, Typography } from '@clef/client-library';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';
import { useHistory } from 'react-router';
import CLEF_PATH from '@/constants/path';

interface ModelDetailsDialogTrainingInProgressProps {
  model: RegisteredModel;
  open: boolean;
  onClose: () => void;
}

const ModelDetailsDialogTrainingInProgress = (props: ModelDetailsDialogTrainingInProgressProps) => {
  const { model, open, onClose } = props;
  const styles = useStyles();
  const history = useHistory();

  const { data: snapshots } = useDatasetExportedWithVersionsQuery({
    withCount: true,
    includeNotCompleted: true,
    includeFastEasy: true,
  });

  const datasetVersions = snapshots && snapshots.datasetVersions ? snapshots.datasetVersions : [];
  const datasetVersionsMap = datasetVersions.reduce((acc, cur: DatasetVersion) => {
    return { ...acc, [cur.id]: cur };
  }, {} as Record<DatasetVersionId, DatasetVersion>);

  const handleViewModelsPage = () => {
    onClose();
    history.push(`${CLEF_PATH.modelsV2.list}?modelId=${model.id}`);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogTitle className={styles.modelDetailsTrainingInProgressDialogTitle}>
        <Box
          display="flex"
          flexDirection={'row'}
          justifyContent="space-between"
          alignItems={'center'}
        >
          <Box display="flex" flexDirection={'row'} alignItems={'center'}>
            <Box paddingRight={4}>
              <Typography variant={'h2_semibold'}>{model.modelName}</Typography>
            </Box>
            <Box className={styles.modelDetailsTrainingInProgressChip}>
              <Typography
                variant="body_bold"
                className={styles.modelDetailsTrainingInProgressChipText}
              >
                {t('Training in progress')}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection={'row'} alignItems={'center'}>
            <Box paddingRight={4}>
              <Button id="view-on-models-page" variant={'outlined'} onClick={handleViewModelsPage}>
                <Typography variant="body_semibold">{t('View on Models Page')}</Typography>
              </Button>
            </Box>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className={styles.modelDetailsTrainingInProgressDialogContent}>
        <ModelTrainingInProgressPanel
          model={model}
          datasetVersion={datasetVersionsMap[model.datasetVersionId!]}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ModelDetailsDialogTrainingInProgress;

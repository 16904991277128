import React from 'react';
import { Controller, Control, FieldError } from 'react-hook-form';
import { TextField, MenuItem, Grid } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import { UserRole } from '@clef/shared/types';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

import { toTitle } from '../../../utils';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(5),
  },
  emailInputBoxWithDomain: {
    width: 200,
    marginRight: theme.spacing(1),
  },
  emailInputBoxWithoutDomain: {
    width: 200,
    marginRight: theme.spacing(5),
  },
  roleInputBox: {
    width: 200,
    marginRight: theme.spacing(4),
  },
  emailDomainText: {
    marginRight: theme.spacing(5),
    paddingTop: theme.spacing(2),
  },
  deleteIconButton: {
    padding: theme.spacing(1.5),
  },
}));

const userRolesForEnterprise = [
  {
    value: UserRole.Admin,
    label: t(toTitle(UserRole.Admin)),
  },
  {
    value: UserRole.Collaborator,
    label: t(toTitle(UserRole.Collaborator)),
  },
];

export interface InviteMembersRowProps {
  index: number;
  control: Control;
  onDelete: () => void;
  deletable?: boolean;
  error?: {
    email: FieldError;
    userRole: FieldError;
  };
  allowFullEmailAddress?: boolean;
}

export const InviteMembersRow: React.FC<InviteMembersRowProps> = ({
  index,
  control,
  onDelete,
  deletable = true,
  error,
}) => {
  const styles = useStyles();

  return (
    <Grid container className={styles.container} alignItems="flex-start">
      <Controller
        as={
          <TextField
            variant="outlined"
            label={t('Organization Email')}
            placeholder={t('Enter Organization Email')}
            className={styles.emailInputBoxWithoutDomain}
            size="small"
            error={!!error?.email}
            helperText={error?.email ? error?.email.message : null}
          />
        }
        rules={{
          required: t('This is required.'),
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address.',
          },
        }}
        defaultValue={''}
        name={`invites[${index}].email`}
        control={control}
      />

      <Controller
        as={
          <TextField
            variant="outlined"
            label={t('Organization Role')}
            placeholder={t('Select Organization Role')}
            className={styles.roleInputBox}
            size="small"
            select
            error={!!error?.userRole}
            helperText={error?.userRole ? error?.userRole.message : null}
            InputLabelProps={{ htmlFor: 'org-role-selection' }}
          >
            {userRolesForEnterprise.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        }
        rules={{ required: t('This is required.') }}
        defaultValue={''}
        name={`invites[${index}].userRole`}
        control={control}
        id="org-role-selection"
      />

      {deletable && (
        <IconButton
          id="delete-inviting-member-button"
          onClick={onDelete}
          className={styles.deleteIconButton}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Grid>
  );
};

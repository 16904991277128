import { createAtom, createWritableAtom } from '../utils';
import { ImageEnhancerState, initialState } from '@/components/ImageEnhancer/state';

export const imageEnhancerStatesAtom = createAtom<ImageEnhancerState>(
  'imageEnhancerStates',
  initialState,
);

export const resetImageEnhancerStatesAtom = createWritableAtom(
  'resetImageEnhancerStates',
  null,
  (get, set) => {
    set(imageEnhancerStatesAtom, initialState);
  },
);

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { IconButton } from '@clef/client-library';
import CloseIcon from '@material-ui/icons/Close';
import { greyScale, BROWSER_WARNING, VERSION_WARNING } from '@clef/client-library';
import { BrowserWarning, VersionWarning } from './browserWarnings';
import { isCypressEnv } from '@clef/client-library/src/utils/env';
import { CacheStorage, CacheStorageKey } from '@clef/client-library';

const useStyles = makeStyles(({ spacing }) => ({
  snackBarPosition: {
    top: '40px',
    right: '40px',
    opacity: '0.8',
    width: '680px',
  },
  snackBarRoot: {
    backgroundColor: greyScale[800],
  },
  action: {
    position: 'absolute',
    top: '10px',
    right: '17px',
  },
  message: {
    fontSize: '14px',
    padding: spacing(6, 3),
  },
}));
interface BrowserPropsT {
  message: string;
}

const componentMap: { [key: string]: JSX.Element } = {
  [BROWSER_WARNING]: <BrowserWarning />,
  [VERSION_WARNING]: <VersionWarning />,
};

export const BrowserWarningCache = new CacheStorage<string>({
  key: CacheStorageKey.BrowserWarning,
  persist: true,
  defaultValue: 'false',
});

const BrowserDetectionSnackbar: React.FC<BrowserPropsT> = ({ message }: BrowserPropsT) => {
  const classes = useStyles();
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  useEffect(() => {
    if (message && !isCypressEnv()) {
      setOpenSnackBar(true);
    }
  }, [message]);

  const handleClose = () => {
    setOpenSnackBar(false);
  };

  if (!message || isCypressEnv()) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={openSnackBar}
      key={'browser_compatibility_check'}
      classes={{
        anchorOriginTopRight: classes.snackBarPosition,
      }}
    >
      <SnackbarContent
        message={componentMap[message]}
        action={
          <IconButton
            id="close-browser-detection-snack-bar"
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        classes={{
          message: classes.message,
          action: classes.action,
          root: classes.snackBarRoot,
        }}
      />
    </Snackbar>
  );
};

export default BrowserDetectionSnackbar;

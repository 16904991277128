import React from 'react';
import { Grid, Dialog, DialogTitle, DialogContent, makeStyles } from '@material-ui/core';
import { Typography, IconButton } from '@clef/client-library';
import { TagTable } from './TagTable';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(theme => ({
  dialogTitleText: {
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.greyModern[900],
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(3),
  },
  tagTableContainer: {
    paddingBottom: theme.spacing(4),
  },
}));

export const TagsPage: React.FC<Props> = ({ open, onClose }) => {
  const styles = useStyles();

  return (
    <Dialog data-testid="manage-tags-dialog" fullWidth open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <Typography variant="h2" className={styles.dialogTitleText}>
          {t('Manage Tags')}
        </Typography>
        <IconButton
          id="close-manage-tags-dialog-button"
          onClick={onClose}
          className={styles.closeIcon}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} className={styles.tagTableContainer}>
            <TagTable />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

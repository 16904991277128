import { makeStyles } from '@material-ui/core';
import { MediaDetailsWithPrediction } from '@clef/shared/types';
import React from 'react';

export const useStyles = makeStyles(() => ({
  scoreBox: {
    background: 'rgba(55, 65, 77, 0.8)',
    color: 'white',
    borderRadius: '4px',
    maxWidth: '40px',
    paddingLeft: '3px',
    paddingRight: '3px',
  },
}));

export type MediaPredictionPerformanceScoreProps = {
  mediaDetails?: MediaDetailsWithPrediction;
};

/**
 * Show prediction performance score for
 *
 */
const MediaPredictionPerformanceScore: React.FC<MediaPredictionPerformanceScoreProps> = ({
  mediaDetails,
}) => {
  const styles = useStyles();
  const mediaLevelScore = mediaDetails?.predictionLabel?.mediaLevelScore;
  return mediaLevelScore !== null && mediaLevelScore !== undefined ? (
    <div data-testid="media-performance-score" className={styles.scoreBox}>
      {Number(mediaLevelScore.toFixed(2))}
    </div>
  ) : (
    <></>
  );
};

export default MediaPredictionPerformanceScore;

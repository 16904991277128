import { atom, Atom, PrimitiveAtom, Getter, Setter } from 'jotai';

type Read<Value> = (get: Getter) => Value | Promise<Value>;
type Write<Update> = (get: Getter, set: Setter, update: Update) => void | Promise<void>;

export function createAtom<Value>(debugLabel: string, initialValue: Value): PrimitiveAtom<Value> {
  const newAtom = atom<Value>(initialValue);
  newAtom.debugLabel = debugLabel;
  return newAtom;
}

export function createReadOnlyAtom<Value>(
  debugLabel: string,
  read: Read<Value>,
): Atom<Value | Promise<Value>> {
  const newAtom = atom(read);
  newAtom.debugLabel = debugLabel;
  return newAtom;
}

export function createWritableAtom<Value, Update>(
  debugLabel: string,
  read: Read<Value> | null,
  write: Write<Update>,
) {
  const newAtom = atom(read, write);
  newAtom.debugLabel = debugLabel;
  return newAtom;
}

// export function createWritableAtom<Value, Update>(
//   debugLabel: string,
//   read: Read<Value>,
//   write: Write<Update>,
// ): WritableAtom<Value | Promise<Value>, [update: Update], void | Promise<void>> {
//   const newAtom = atom(read, write);
//   newAtom.debugLabel = debugLabel;
//   return newAtom;
// }

// type AtomOrWritableAtom<Value, Update> =
//   | Atom<Value>
//   | Atom<Promise<Value>>
//   | WritableAtom<Value, Update extends undefined ? never[] : Update[], void>;

// type WithInitialValue<Value> = {
//   init: Value;
// };

// export function atomWithDebugLabel<Value, Update = undefined>(debugLabel: string) {
//   return function (
//     arg1: Value | ((get: Getter) => Value | Promise<Value>),
//     arg2?: (get: Getter, set: Setter, update: Update) => void | Promise<void>,
//   ): AtomOrWritableAtom<Value, Update> {
//     let newAtom: AtomOrWritableAtom<Value, Update>;

//     if (arg2 !== undefined) {
//       newAtom = atom(arg1 as any, arg2) as WritableAtom<Value, Update[], void>;
//     } else if (typeof arg1 === 'function') {
//       try {
//         // Attempt to resolve the function as synchronous
//         newAtom = atom((arg1 as (get: Getter) => Value)(undefined as any));
//       } catch (error) {
//         // If the function is actually asynchronous, this will catch it
//         newAtom = atom((arg1 as (get: Getter) => Promise<Value>)(undefined as any));
//       }
//     } else {
//       newAtom = atom(arg1 as Value) as PrimitiveAtom<Value> & WithInitialValue<Value>;
//     }

//     newAtom.debugLabel = debugLabel;

//     return newAtom;
//   };
// }

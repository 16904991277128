import React from 'react';
import { Grid, DialogContent, Dialog, makeStyles, Box } from '@material-ui/core';
import { IconButton, Typography } from '@clef/client-library';
import CloseIcon from '@material-ui/icons/Close';
import cx from 'classnames';
import { useModels } from '@/hooks/useModels';
import { ModelDetailsDialogContextProvider } from '@/pages/DataBrowser/ModelPerformance/ModelDetailsDialogV2/modelDetailsDialogState';
import DataSnapshot from './DataSnapshot';

const useStyles = makeStyles(theme => ({
  container: {
    width: 900,
    height: 800,
    overflow: 'hidden',
  },
  grow: {
    flex: 1,
    paddingLeft: theme.spacing(8),
    paddingBottom: theme.spacing(6),
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    overflow: 'hidden',
  },
  detailItem: {
    display: 'flex',
    width: '100%',
  },
  detailItemFixed: {
    height: '100%',
    position: 'relative',
  },
  modelNameContainer: {
    marginTop: theme.spacing(6),
  },
  modelNameText: {
    marginRight: theme.spacing(2),
  },
  detailTabContent: {
    padding: theme.spacing(0, 8, 7, 8),
    backgroundColor: theme.palette.greyBlue[50],
    flexGrow: 1,
    overflowY: 'auto',
  },
  detailTabContentFixed: {
    overflowY: 'hidden',
  },
}));

export interface ImageDetailsDialogProps {
  open: boolean;
  onClose(): void;
  modelId: string;
  datasetVersion?: number;
}

export const ImageDetailsDialog: React.FC<ImageDetailsDialogProps> = ({
  open,
  onClose,
  modelId,
  datasetVersion,
}) => {
  const styles = useStyles();
  const { findModels } = useModels();
  const currentModel = findModels(modelId);

  return (
    <ModelDetailsDialogContextProvider>
      <Dialog open={open} onClose={onClose} maxWidth="md" classes={{ paper: styles.container }}>
        <Grid container>
          <div className={styles.grow}>
            <Grid container alignItems="center" className={styles.modelNameContainer}>
              {
                <Typography variant="h1" className={styles.modelNameText} maxWidth={600}>
                  {currentModel?.modelName || t('Untitled model')}
                </Typography>
              }
            </Grid>
          </div>

          <Box display="flex" alignItems="center" marginRight={5}>
            <IconButton id="model-details-dialog-close-btn" size="medium" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>

        <DialogContent className={styles.contentContainer}>
          <Grid container className={cx(styles.detailTabContent, styles.detailTabContentFixed)}>
            <Box className={cx(styles.detailItem, styles.detailItemFixed)}>
              <DataSnapshot version={datasetVersion} modelId={modelId} />
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    </ModelDetailsDialogContextProvider>
  );
};

export default ImageDetailsDialog;

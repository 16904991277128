import { useCallback, useMemo } from 'react';
import { useDatasetMediaCountQuery } from '@/serverStore/dataset';
import { useTypedSelector } from './useTypedSelector';
import {
  SubscriptionMeta,
  SubscriptionName,
  TemporaryProcessedSubscriptionName,
  UserStatus,
} from '@clef/shared/types';
import { useLocalStorage } from '@clef/client-library';
import { useGetUsageSummary } from '@/serverStore/usage';
import {
  API_PATH_ADD_ACTIVE_PROJECT_AS_DEFAULT_ACTIVE_PROJECT_USAGE,
  API_PATH_INVITE_USER_AS_DEFAULT_USER_USAGE,
  API_PATH_MODEL_TRAIN_AS_DEFAULT_CREDIT_USAGE,
  SubscriptionMetaQuotaType,
  checkHasExceedLimit,
  checkSubscriptionHasExpired,
  convertSubscriptionNameToTemporaryProcessedSubscriptionName,
  getQuotaTypesForSubscription,
} from '@clef/shared/utils/subscription';
import { useSnackbar } from 'notistack';
import { useGetActiveProjectsQuery } from '@/serverStore/projects';

export const useSubscriptions = () => {
  const user = useTypedSelector(state => state.login.user);
  return user?.subscriptions;
};

export const useCurrentSubscription = () => {
  const subscriptions = useSubscriptions();
  if (!subscriptions || subscriptions.length === 0) {
    return undefined;
  }
  return subscriptions[0];
};

/**
 * @deprecated Will be removed once we migrate all users to new plans
 */
export const useActiveProjectsEnabled = () => {
  const subscription = useCurrentSubscription();
  const isOldSubscriptionPlan = subscription?.meta?.quotaActiveProject === undefined;
  return !isOldSubscriptionPlan;
};

export const usePreviousSubscription = () => {
  const subscriptions = useSubscriptions();
  if (!subscriptions || subscriptions.length < 2) {
    return undefined;
  }
  return subscriptions[1];
};

/**
 * @deprecated this hook should be carefully used, try check usage v.s. api limit instead of checking stripeProductName
 */
export const useIsEnterpriseUser = () => {
  const subscription = useCurrentSubscription();
  return useMemo(
    () => subscription?.stripeProductName === SubscriptionName.Enterprise,
    [subscription?.stripeProductName],
  );
};

export const useIsEnterprisePresalesUser = () => {
  const subscription = useCurrentSubscription();
  return useMemo(
    () => subscription?.meta.plan === SubscriptionName.PreSales,
    [subscription?.meta.plan],
  );
};

/**
 * non-stripe users means enterprise and pre-sales, which are not managed by Stripe.
 */
export const useIsNonStripeUser = () => {
  const isEnterpriseUser = useIsEnterpriseUser();
  const isEnterprisePresalesUser = useIsEnterprisePresalesUser();

  return isEnterpriseUser || isEnterprisePresalesUser;
};

/**
 * @deprecated Will be removed once we migrate all users to new plans
 */
export const useIsPricingV2User = () => {
  const subscription = useCurrentSubscription();
  return useMemo(() => {
    // Pricing v2 only have free and visionary plans, and there's no tier
    const meta = subscription?.meta ?? ({} as SubscriptionMeta);
    return (
      [SubscriptionName.Free, SubscriptionName.Visionary, SubscriptionName.PreSales].includes(
        meta.plan as any,
      ) && !('planTier' in meta)
    );
  }, [subscription]);
};

export const useIsPricingV2VisionaryUser = () => {
  const isPricingV2User = useIsPricingV2User();
  const subscription = useCurrentSubscription();
  return useMemo(() => {
    const meta = subscription?.meta ?? ({} as SubscriptionMeta);
    return isPricingV2User && meta.plan === SubscriptionName.Visionary;
  }, [isPricingV2User, subscription?.meta.plan]);
};

export const useIsFreeUser = () => {
  const subscription = useCurrentSubscription();
  return useMemo(() => {
    const meta = subscription?.meta ?? ({} as SubscriptionMeta);
    return meta.plan === SubscriptionName.Free;
  }, [subscription?.meta.plan]);
};

export const useIsFreemiumUserTrialingExpired = () => {
  const subscription = useCurrentSubscription();
  return useMemo(
    () =>
      subscription?.stripeProductName === 'LandingLens-Freemium-Package' &&
      subscription.status !== 'trialing',
    [subscription?.stripeProductName],
  );
};

export const useIsStarterOrVisionaryUser = () => {
  const subscription = useCurrentSubscription();
  return useMemo(
    () =>
      subscription?.stripeProductName.includes('LandingLens-Starter-Package') ||
      subscription?.stripeProductName.includes('LandingLens-Visionary-Package'),
    [subscription?.stripeProductName],
  );
};

/**
 * If free trial expires, backend will create a new subscription record, thus
 * there should be a freemium subscription and a free trial subscription:
 * [
 *   { stripeProductName: 'freemium', status: 'active' },   // freemium
 *   { stripeProductName: 'freemium', status: 'trialing', stripeCurrentPeriodEnd: '<date>' }, // expired free trial
 * ]
 */
export const useShowFreeTrialExpiredDialog = () => {
  const subscription = useCurrentSubscription();
  const prevSubscription = usePreviousSubscription();
  const { orgId } = useTypedSelector(state => state.login.user) ?? {};
  return useMemo(() => {
    if (!subscription || !prevSubscription || orgId === undefined) {
      return false;
    }
    // show the expired dialog only when changing from free trial -> freemium
    const wasFreeTrial =
      prevSubscription?.meta?.plan === 'freemium' && prevSubscription.status === 'trialing';
    const isFreemium = subscription?.meta?.plan === 'freemium' && subscription.status === 'active';
    if (wasFreeTrial && isFreemium) {
      const skipUpgradeOrgIds = JSON.parse(localStorage.getItem('skipUpgradeOrgIds') ?? '[]');
      const hasSkippedBefore = skipUpgradeOrgIds.includes(orgId);
      return !hasSkippedBefore;
    }
    return false;
  }, [subscription, prevSubscription, orgId]);
};

export const useShowFreeTrialWelcomeDialog = () => {
  const subscription = useCurrentSubscription();
  const { orgId } = useTypedSelector(state => state.login.user) ?? {};
  const [skipWelcomeOrgIds] = useLocalStorage<number[]>('skipWelcomeOrgIds');
  return useMemo(() => {
    if (!subscription || orgId === undefined) {
      return false;
    }

    const isFreeTrial =
      subscription?.meta?.plan === 'freemium' && subscription.status === 'trialing';
    if (isFreeTrial) {
      // If no welcome org ids were set for a free trial org, show the welcome dialog
      if (!skipWelcomeOrgIds) {
        return true;
      }

      const hasSkippedBefore = skipWelcomeOrgIds.includes(orgId);
      return !hasSkippedBefore;
    }
    return false;
  }, [orgId, skipWelcomeOrgIds, subscription]);
};

export const useQuotaAndUsageInfo = () => {
  const { quotaUsage, quotaUser, quotaActiveProject } = useCurrentSubscription()?.meta ?? {};

  const {
    data: imageUsage,
    isLoading: imageUsageLoading,
    error: imageUsageError,
  } = useDatasetMediaCountQuery({
    selectOptions: {
      fieldFilterMap: {},
      columnFilterMap: {},
      selectedMedia: [],
      unselectedMedia: [],
      isUnselectMode: true,
    },
  });

  const subscription = useCurrentSubscription();
  const currentPeriodEnd = subscription?.stripeCurrentPeriodEnd;
  const { data: usageSummary, isLoading: isSummaryLoading } = useGetUsageSummary();
  const usage = usageSummary?.usage;

  return useMemo(() => {
    return {
      imageUsage,
      imageUsageLoading,
      imageUsageError,
      quotaUsage,
      quotaUser,
      quotaActiveProject,
      usage,
      currentPeriodEnd,
      isSummaryLoading,
    };
  }, [
    imageUsage,
    imageUsageLoading,
    imageUsageError,
    quotaUsage,
    quotaUser,
    quotaActiveProject,
    usage,
    currentPeriodEnd,
    isSummaryLoading,
  ]);
};

export const useCheckCreditReachLimit = () => {
  const { quotaUsage: limit, usage } = useQuotaAndUsageInfo();
  const subscription = useCurrentSubscription();
  const currentTime = useTypedSelector(state => state.login.user?.currentTime);
  const hasPlanExpired = checkSubscriptionHasExpired(
    subscription?.meta?.plan as SubscriptionName,
    subscription?.stripeCurrentPeriodEnd,
    currentTime,
  );
  const { enqueueSnackbar } = useSnackbar();

  const processedPlanName = convertSubscriptionNameToTemporaryProcessedSubscriptionName(
    subscription?.meta?.plan as SubscriptionName,
    'planTier' in (subscription?.meta ?? {}),
  );
  const quotaTypes =
    getQuotaTypesForSubscription(API_PATH_MODEL_TRAIN_AS_DEFAULT_CREDIT_USAGE, processedPlanName) ??
    [];

  const checkReachLimitApplyingCost = useCallback(
    (cost: number) => {
      if (hasPlanExpired) {
        enqueueSnackbar(t('Your subscription plan has expired, please renew your plan.'), {
          variant: 'warning',
          preventDuplicate: true,
        });
        return true;
      }
      if (!quotaTypes.includes(SubscriptionMetaQuotaType.USAGE)) {
        return false;
      }
      return checkHasExceedLimit(limit ?? -1, usage ?? 0, cost);
    },
    [limit, usage],
  );
  const hasReachLimit = useMemo(() => {
    return checkReachLimitApplyingCost(0);
  }, [checkReachLimitApplyingCost]);

  return {
    limit,
    usage,
    hasReachLimit,
    hasPlanExpired,
    checkReachLimitApplyingCost,
  };
};

export const useShouldDisplayPricingV2 = () => {
  const subscription = useCurrentSubscription();
  const processedPlanName = convertSubscriptionNameToTemporaryProcessedSubscriptionName(
    subscription?.meta?.plan as SubscriptionName,
    'planTier' in (subscription?.meta ?? {}),
  );

  const shouldDisplayPricingV2 = [
    TemporaryProcessedSubscriptionName.Enterprise,
    TemporaryProcessedSubscriptionName.Free,
    TemporaryProcessedSubscriptionName.PreSales,
    TemporaryProcessedSubscriptionName.Visionary,
  ].includes(processedPlanName);
  return { shouldDisplayPricingV2 };
};

export const useCheckUserReachLimit = () => {
  const users = useTypedSelector(state => state.user.users);
  const activeUsers = useMemo(
    () => users.filter(user => user.status === UserStatus.Active),
    [users],
  );
  const pendingUsers = useTypedSelector(state => state.user.pendingUsers);
  const allUsers = useMemo(() => [...activeUsers, ...pendingUsers], [activeUsers, pendingUsers]);
  const usage = allUsers.length;
  const { quotaUser } = useQuotaAndUsageInfo();
  const subscription = useCurrentSubscription();
  const { enqueueSnackbar } = useSnackbar();

  const currentTime = useTypedSelector(state => state.login.user?.currentTime);
  const hasPlanExpired = checkSubscriptionHasExpired(
    subscription?.meta?.plan as any,
    subscription?.stripeCurrentPeriodEnd,
    currentTime,
  );
  const processedPlanName = convertSubscriptionNameToTemporaryProcessedSubscriptionName(
    subscription?.meta?.plan as SubscriptionName,
    'planTier' in (subscription?.meta ?? {}),
  );

  // For old subscription plans, subscription meta does not have quotaUser, return -1 to indicate no limit.
  const quotaTypes =
    getQuotaTypesForSubscription(API_PATH_INVITE_USER_AS_DEFAULT_USER_USAGE, processedPlanName) ??
    [];
  const limit = quotaUser === undefined ? -1 : quotaUser;
  const checkReachLimitApplyingCost = useCallback(
    (cost: number) => {
      if (hasPlanExpired) {
        enqueueSnackbar(t('Your subscription plan has expired, please renew your plan.'), {
          variant: 'warning',
          preventDuplicate: true,
        });
        return true;
      }
      if (!quotaTypes.includes(SubscriptionMetaQuotaType.USER)) {
        return false;
      }
      return checkHasExceedLimit(limit ?? -1, usage ?? 0, cost);
    },
    [limit, usage],
  );
  const hasReachLimit = useMemo(() => {
    return checkReachLimitApplyingCost(0);
  }, [checkReachLimitApplyingCost]);

  return {
    limit,
    usage,
    hasReachLimit,
    hasPlanExpired,
    checkReachLimitApplyingCost,
  };
};

export const useCheckActiveProjectReachLimit = () => {
  const { quotaActiveProject: limit } = useQuotaAndUsageInfo();
  const { data: activeProjects } = useGetActiveProjectsQuery();
  const usage = activeProjects?.length ?? 0;
  const subscription = useCurrentSubscription();
  const currentTime = useTypedSelector(state => state.login.user?.currentTime);
  const hasPlanExpired = checkSubscriptionHasExpired(
    subscription?.meta?.plan as SubscriptionName,
    subscription?.stripeCurrentPeriodEnd,
    currentTime,
  );
  const { enqueueSnackbar } = useSnackbar();

  const processedPlanName = convertSubscriptionNameToTemporaryProcessedSubscriptionName(
    subscription?.meta?.plan as SubscriptionName,
    'planTier' in (subscription?.meta ?? {}),
  );
  const quotaTypes =
    getQuotaTypesForSubscription(
      API_PATH_ADD_ACTIVE_PROJECT_AS_DEFAULT_ACTIVE_PROJECT_USAGE,
      processedPlanName,
    ) ?? [];
  const checkReachLimitApplyingCost = useCallback(
    (cost: number) => {
      if (hasPlanExpired) {
        enqueueSnackbar(t('Your subscription plan has expired, please renew your plan.'), {
          variant: 'warning',
          preventDuplicate: true,
        });
        return true;
      }
      if (!quotaTypes.includes(SubscriptionMetaQuotaType.ACTIVE_PROJECT)) {
        return false;
      }
      return checkHasExceedLimit(limit ?? -1, usage ?? 0, cost);
    },
    [limit, usage],
  );
  const hasReachLimit = useMemo(() => {
    return checkReachLimitApplyingCost(0);
  }, [checkReachLimitApplyingCost]);

  return {
    limit,
    usage,
    hasReachLimit,
    hasPlanExpired,
    checkReachLimitApplyingCost,
  };
};

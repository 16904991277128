import React, { useEffect, useMemo } from 'react';
import {
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import { UPLOAD_DIALOG_Z_INDEX } from './UploadFullscreen/constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useImmer } from 'use-immer';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    // css allow this but ts does not. cast as number to workaround
    zIndex: `${UPLOAD_DIALOG_Z_INDEX + 1}!important` as unknown as number,
  },
  FormGroup: {
    marginTop: theme.spacing(4),
  },
}));

interface ConfirmationDialogProps {
  open: boolean;
  onClose(): void;
  onConfirm(): void;
  title?: string;
  confirmText: string;
  confirmAllowedCheckbox?: string[];
  content?: string;
  color: ButtonProps['color'];
  disableCancel?: boolean;
  switchButtonPosition?: boolean;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
  confirmText = 'Yes',
  confirmAllowedCheckbox = undefined,
  title = 'Are you sure you want to leave',
  content,
  color,
  disableCancel = false,
  switchButtonPosition = false,
}) => {
  const styles = useStyles();

  const [confirmAllowedCheckboxStatus, dispatch] = useImmer<Record<string, boolean>>({});
  useEffect(() => {
    dispatch(draft => {
      Object.keys(draft).forEach(key => {
        delete draft[key];
      });
    });
  }, [open]);

  useEffect(() => {
    if (confirmAllowedCheckbox) {
      dispatch(draft => {
        confirmAllowedCheckbox.forEach((item, index) => {
          if (!(index in draft)) {
            draft[index] = false;
          }
        });
      });
    }
  }, [confirmAllowedCheckbox]);

  const confirmAllowed = useMemo(() => {
    return Object.keys(confirmAllowedCheckboxStatus).every(key => {
      return confirmAllowedCheckboxStatus[key] === true;
    });
  }, [confirmAllowedCheckboxStatus]);

  const dialogActionsContent = useMemo(() => {
    const element: JSX.Element[] = [];
    element[0] = (
      <Button
        key="confirm-button"
        id="confirm-button"
        onClick={onConfirm}
        variant="contained"
        color={color}
        disabled={!confirmAllowed}
      >
        {confirmText}
      </Button>
    );
    if (!disableCancel) {
      element[1] = (
        <Button id="cancel-button" key="cancel-button" onClick={onClose} color={color}>
          {t('Cancel')}
        </Button>
      );
    }
    return switchButtonPosition ? element.reverse() : element;
  }, [switchButtonPosition, onConfirm, onClose, color, confirmAllowed, confirmText, disableCancel]);

  return (
    <Dialog
      open={open}
      onClose={disableCancel ? undefined : onClose}
      data-testid="confirmation-dialog"
      classes={{ root: styles.dialogRoot }}
      disableEscapeKeyDown={disableCancel}
    >
      <DialogTitle data-testid="confim-dialog-title" disableTypography>
        <Typography variant="h3">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        {content}
        {confirmAllowedCheckbox && (
          <FormGroup className={styles.FormGroup}>
            {confirmAllowedCheckbox.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={confirmAllowedCheckboxStatus[index]}
                    onChange={() =>
                      dispatch(draft => {
                        draft[index] = !draft[index];
                      })
                    }
                    name="gilad"
                  />
                }
                label={item}
              />
            ))}
          </FormGroup>
        )}
      </DialogContent>
      <DialogActions>{dialogActionsContent}</DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

import React, { useEffect } from 'react';
import { useImmer } from 'use-immer';

import { PageName } from '@clef/shared/constants';
import { LoggingEvent, EventLogger } from '@clef/client-library';
import { DialogContextProvider } from '../components/useDialog';
import { LabelingDrawerContextProvider } from '../../Labeling/LabelingDrawer';
import FREPageLayoutRoot from './PageLayoutRoot';
import { initialState, PageLayoutContext, PageLayoutState, usePageLayoutState } from './state';
import { WorkflowAssistantContextProvider } from '../../WorkflowAssistant/state';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';

export const PageLayoutRoot: React.FC<{}> = ({ children }) => {
  const [state, dispatch] = useImmer<PageLayoutState>(initialState);
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};

  return (
    <PageLayoutContext.Provider value={{ state, dispatch }}>
      <LabelingDrawerContextProvider>
        {/* Assign key with project id to trigger lifecycle of the workflow assistant context when project id
        changes */}
        <WorkflowAssistantContextProvider key={projectId}>
          <DialogContextProvider>
            <FREPageLayoutRoot>{children}</FREPageLayoutRoot>
          </DialogContextProvider>
        </WorkflowAssistantContextProvider>
      </LabelingDrawerContextProvider>
    </PageLayoutContext.Provider>
  );
};

export const PageLayout: React.FC<{
  title?: string;
  metaTitle?: string;
  breadcrumb?: { label: string; to: string };
  hideHeader?: boolean;
  hideSidebar?: boolean;
  fullScreenContent?: boolean;
  fixedHeightContent?: boolean;
  pageName: PageName;
  showWhiteBackground?: boolean;
  showGreyBackground?: boolean;
  showPurpleBackground?: boolean;
  showProjectBanner?: boolean;
  noPadding?: boolean;
}> = ({
  children,
  title,
  metaTitle,
  hideHeader,
  hideSidebar,
  breadcrumb,
  pageName,
  fullScreenContent,
  fixedHeightContent,
  showWhiteBackground,
  showGreyBackground,
  showPurpleBackground,
  showProjectBanner,
  noPadding,
}) => {
  const { dispatch } = usePageLayoutState();

  useEffect(() => {
    dispatch(draft => {
      draft.showHeader = !hideHeader;
      draft.showSidebar = !hideSidebar;
      draft.pageTitle = title;
      draft.metaTitle = metaTitle ?? title;
      draft.pageTitleBreadcrumb = breadcrumb;
      draft.fullScreenContent = fullScreenContent;
      draft.fixedHeightContent = fixedHeightContent;
      draft.showWhiteBackground = showWhiteBackground || false;
      draft.showGreyBackground = showGreyBackground || false;
      draft.showPurpleBackground = showPurpleBackground || false;
      draft.showProjectBanner = showProjectBanner || false;
      draft.noPadding = noPadding || false;
    });
  }, [
    breadcrumb,
    dispatch,
    fullScreenContent,
    fixedHeightContent,
    hideHeader,
    hideSidebar,
    metaTitle,
    title,
    showWhiteBackground,
    showProjectBanner,
    noPadding,
    showGreyBackground,
    showPurpleBackground,
  ]);

  useEffect(() => {
    EventLogger.log(LoggingEvent.ImpressionEvent, {
      impressionName: pageName,
      impressionType: 'page',
    });
  }, [pageName]);

  return <>{children}</>;
};

import React, { useCallback, useMemo, useState } from 'react';
import { Typography, Grid, Link, CircularProgress, Chip } from '@material-ui/core';
import VpnKey from '@material-ui/icons/VpnKey';
import cx from 'classnames';
import { capitalize } from 'lodash';

import { PendingUserWithOrg, SubscriptionName, UserWithOrg } from '@clef/shared/types';
import { useMount } from '@clef/client-library';

import { useStyles } from '../newStyles';
import { getPlanName } from '@/pages/plan/utils';

export interface OrgsSectionProps {
  users: (UserWithOrg | PendingUserWithOrg)[];
  onItemClick?: (params: {
    orgId: number;
    userId: any;
    token: string | undefined;
    isSsoOrg: boolean;
    email: string;
  }) => void | Promise<void>;
}

interface OrgSubscriptionTypeChipProps {
  orgSubscriptionType: string;
}

const OrgSubscriptionTypeChip: React.FC<OrgSubscriptionTypeChipProps> = (
  props: OrgSubscriptionTypeChipProps,
) => {
  const { orgSubscriptionType } = props;
  const styles = useStyles();

  if (orgSubscriptionType === '') {
    return null;
  }

  let styleClassName = orgSubscriptionType;
  if (styleClassName === t(SubscriptionName.Enterprise)) {
    styleClassName = SubscriptionName.Enterprise;
  }

  return (
    <Chip
      size="small"
      className={styleClassName}
      classes={{ root: styles.subscriptionTypeChip, sizeSmall: styles.subscriptionTypeChipText }}
      label={capitalize(orgSubscriptionType)}
    />
  );
};

export const OrgsSection: React.FC<OrgsSectionProps> = ({ users, onItemClick }) => {
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | number | null>(null);
  const isMounted = useMount();

  const usersCountLookup = useMemo(
    () =>
      users.reduce((lookup, user) => {
        if (lookup[user.orgId]) {
          lookup[user.orgId]++;
        } else {
          lookup[user.orgId] = 1;
        }
        return lookup;
      }, {} as Record<string, number>),
    [users],
  );

  const onOrgCardClick = useCallback(
    async (item: UserWithOrg | PendingUserWithOrg) => {
      if (!isLoading) {
        setIsLoading(true);
        setSelectedItemId(item.id);
        await onItemClick?.({
          orgId: item.organization.id,
          userId: item.id,
          token: (item as PendingUserWithOrg)?.token,
          isSsoOrg: !!item.organization.ssoConfig,
          email: item.email,
        });
        if (isMounted()) {
          setSelectedItemId(null);
          setIsLoading(false);
        }
      }
    },
    [isLoading, isMounted, onItemClick],
  );

  if (!users.length) {
    return null;
  }

  return (
    <Grid container direction="column" className={styles.orgsSectionContainer}>
      {users
        .sort((a, b) => b.orgId - a.orgId)
        .map((item, index) => {
          return (
            <div
              className={cx(
                styles.orgCard,
                selectedItemId !== item.id && isLoading && styles.orgCardDisabled,
                item.internal && styles.orgCardTempUser,
              )}
              key={index}
              onClick={() => onOrgCardClick(item)}
              onMouseEnter={() => {
                if (!isLoading) {
                  setSelectedItemId(item.id);
                }
              }}
              onMouseLeave={() => {
                if (!isLoading) {
                  setSelectedItemId(null);
                }
              }}
            >
              <Grid
                container
                alignItems="center"
                className={cx(
                  styles.orgsSectionItemContainer,
                  selectedItemId === item.id && styles.selectedOrgsSectionItemContainer,
                )}
              >
                <Grid container direction="column" className={styles.orgsSectionItemTitle}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography className={styles.orgsSectionItemOrgName}>
                      {item.organization.name}
                    </Typography>
                    {(item as UserWithOrg)?.organization?.latestSubscription ? (
                      <OrgSubscriptionTypeChip
                        orgSubscriptionType={getPlanName(
                          (item as UserWithOrg)!.organization!.latestSubscription,
                        )}
                      />
                    ) : null}
                  </Grid>
                  {
                    <Typography variant="body2" className={styles.orgsSectionItemOrgId}>
                      {`ID: ${item.organization.id}`}
                    </Typography>
                  }

                  {usersCountLookup[item.orgId] !== 1 && (
                    <Typography variant="body2" className={styles.orgsSectionItemEmail}>
                      {item.email}
                    </Typography>
                  )}

                  {item.organization.ssoConfig && (
                    <Grid container className={styles.autoWidth} alignItems="center">
                      <VpnKey className={styles.orgCardSsoOrgKeyIcon} />
                      <Typography variant="body2" className={styles.orgsSectionItemSso}>
                        {t('requires Enterprise SSO')}
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                <div className={styles.flexGrow} />

                {selectedItemId === item.id && (
                  <Link
                    className={cx(
                      styles.orgItemActionLink,
                      isLoading && styles.orgItemActionLinkLoading,
                    )}
                  >
                    {isLoading && <CircularProgress size="20px" color="primary" />}
                  </Link>
                )}
              </Grid>
            </div>
          );
        })}
    </Grid>
  );
};

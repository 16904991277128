import React from 'react';
import ReachActiveProjectLimitDialog from './ReachActiveProjectLimitDialog';
import DownloadModelDialog from './DownloadModelDialog';
import ActivateAndDownloadModelDialog from './ActivateAndDownloadModelDialog';
import { ProjectId } from '@clef/shared/types';

export type SharedDialogProps = {
  open: boolean;
  onClose: () => void;
  projectId: ProjectId;
};

type ActiveProjectDialogProps = SharedDialogProps & {
  canCommercialUse: boolean;
  isSelectedProjectActive: boolean;
  hasReachLimit: boolean;
  downloadModelBundle: () => void;
};

const ActiveProjectDialog = (props: ActiveProjectDialogProps) => {
  const {
    open,
    onClose,
    projectId,
    canCommercialUse,
    isSelectedProjectActive,
    hasReachLimit,
    downloadModelBundle,
  } = props;

  if (isSelectedProjectActive) {
    return (
      <DownloadModelDialog
        projectId={projectId}
        open={open}
        onClose={onClose}
        showNonCommercialUseBlock={!canCommercialUse}
        downloadModelBundle={downloadModelBundle}
      />
    );
  } else {
    if (hasReachLimit) {
      return <ReachActiveProjectLimitDialog projectId={projectId} open={open} onClose={onClose} />;
    } else {
      return (
        <ActivateAndDownloadModelDialog
          projectId={projectId}
          open={open}
          onClose={onClose}
          showNonCommercialUseBlock={!canCommercialUse}
          downloadModelBundle={downloadModelBundle}
        />
      );
    }
  }
};

export default ActiveProjectDialog;

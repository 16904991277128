import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  flex: {
    flex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 20,
    boxShadow: 'none !important',
    cursor: 'pointer',
    position: 'relative',
    '&:hover $projectTypeIcon': {
      transform: 'rotate(-20deg)',
    },
    '&:hover $image': {
      transform: 'scale(1.1)',
    },
  },
  imageContainer: {
    overflow: 'hidden',
    marginBottom: -theme.spacing(5),
    width: '100%',
    height: 'calc(100% - 70px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    alignSelf: 'center',
    objectFit: 'cover',
    transition: 'transform 0.2s',
    background: 'linear-gradient(180deg, rgba(10, 100, 252, 0.05) 0%, rgba(10, 100, 252, 0) 100%)',
  },
  textColor: {
    color: theme.palette.grey[600],
  },
  details: {
    width: '100%',
    padding: theme.spacing(4, 5),
    borderRadius: 16,
    position: 'absolute',
    bottom: 0,
    background: theme.palette.greyModern[100],
  },
  privateProjectIcon: {
    color: '#7D89AF',
    fontSize: 18,
  },
  createProjectCardContainer: {
    background: theme.palette.blue[50],
    border: `2px dashed ${theme.palette.primary.main}`,
    '&:hover': {
      borderWidth: 3,
    },
    position: 'relative',
  },
  createProjectCardMoreOptions: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(8),
  },
  createProjectCardAddIcon: {
    fontSize: 40,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  createProjectCardText: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  projectTypeIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.2s',
    '& img': {
      width: 32,
      height: 32,
    },
  },
  detailsName: {
    maxWidth: 'calc(100% - 40px)',
    display: 'flex',
    gap: theme.spacing(3),
    alignItems: 'center',
  },
  projectLimitCard: {
    background: theme.palette.grey[50],
    border: `1px dashed ${theme.palette.grey[600]}`,
  },
  projectLimitCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(3),
  },
  lockIcon: {
    fontSize: '48px',
  },
  lockDescription: {
    color: theme.palette.grey[800],
  },
  upgradeButton: {
    background: theme.palette.blue[100],
    color: theme.palette.blue[900],
  },
  statsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  statLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

export default useStyles;

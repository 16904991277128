import React from 'react';

export type DeployIconProps = {};

const DeployIcon: React.FC<DeployIconProps> = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 96.000000 96.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M696 869 c-65 -16 -157 -66 -219 -119 -64 -55 -64 -55 -136 -39 -40
 8 -55 7 -79 -5 -37 -19 -176 -167 -165 -176 4 -4 44 -22 88 -40 69 -29 93 -46
 172 -124 82 -80 96 -99 129 -175 20 -48 39 -89 43 -94 4 -4 49 36 100 88 81
 82 92 98 87 120 -4 14 -9 44 -12 68 -6 40 -3 46 41 97 25 30 65 93 88 139 38
 76 41 90 42 162 0 49 -5 83 -12 90 -16 16 -116 21 -167 8z m101 -124 c-12
 -115 -131 -277 -261 -354 l-57 -34 -60 59 c-32 32 -59 62 -59 65 0 4 23 41 50
 83 88 131 241 233 354 235 l39 1 -6 -55z m-448 -120 c6 -7 -49 -95 -60 -95 -5
 0 -24 7 -41 16 l-30 16 38 39 c32 32 44 39 64 34 14 -4 27 -8 29 -10z m286
 -302 c5 -22 0 -33 -33 -66 l-40 -40 -16 31 c-22 43 -21 47 27 76 53 32 53 32
 62 -1z"
        />
        <path
          d="M563 668 c-55 -27 -50 -114 8 -138 89 -37 151 99 63 139 -30 13 -42
 13 -71 -1z"
        />
        <path
          d="M195 351 c-27 -12 -51 -40 -69 -81 -13 -33 -48 -182 -43 -187 6 -6
 179 38 207 52 36 19 70 69 70 105 -1 78 -93 140 -165 111z m79 -93 c16 -25 -2
 -49 -49 -65 -21 -7 -40 -12 -42 -10 -5 5 18 70 30 85 14 17 47 11 61 -10z"
        />
      </g>
    </svg>
  );
};

export default DeployIcon;

import React, { useEffect, useRef } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import LabelingWrapper from './LabelingWrapper';
import TopToolBar from './TopToolBar';
import { InstantLearningProjectContextProvider, useInstantLearningState } from './state';
import { useImmer } from 'use-immer';
import BrowseImagesSideBar from './BrowseImagesSideBar';
import BrowseImagesFullscreenDialog from './BrowseImagesFullscreenDialog';
import {
  AnnotationChangeType,
  CanvasAnnotation,
  MediaInteractiveCanvas,
  useStateSyncSearchParams,
  AnnotationSourceType,
} from '@clef/client-library';
import { HintSnackbarContextProvider } from '../../components/Labeling/HintSnackbar';
import {
  defaultState as defaultLabelingState,
  LabelingContext,
  LabelingState,
  PureCanvasLabelingAnnotation,
  ToolMode,
  useLabelingState,
} from '../../components/Labeling/labelingState';
import { useDefectSelector } from '../../store/defectState/actions';
import {
  ImageLabelingContextProvider,
  MediaStates,
  useImageLabelingContext,
  useMediaList,
} from '../../components/Labeling/imageLabelingContext';
import { datasetQueryKeys, useDatasetMediaDetailsQuery } from '@/serverStore/dataset';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useVPLatestModel } from '@/serverStore/projectModels';
import {
  useDatasetMedias,
  useSegmentationPredictionLabelingAnnotations,
} from '../DataBrowser/utils';
import { Annotation, LabelingType } from '@clef/shared/types';
import {
  offscreenCanvasToCanvasAnnotation,
  serverAnnotationsToLabelingAnnotations,
} from '../../components/Labeling/utils';
import LabelingStepGuide from './LabelingStepGuide';
import { useInitializeClasses } from '@/hooks/defect/useInitializeClasses';
import { DataBrowserStateContext, getStateFromStorage } from '../DataBrowser/dataBrowserState';
import { omit } from 'lodash';
import { useSavePostProcessingConfig } from '@/components/Predict/hooks/useSavePostProcessingConfig';
import { PredictContextProvider } from '@/components/Predict/predictContext';
import { useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import { ClientFeatures } from '@clef/shared/features';
import { useQueryClient } from '@tanstack/react-query';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  labelingAndPreview: {
    flex: 1,
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },
  browseImagesSideBar: {
    flex: '0 0 210px',
    position: 'relative',
  },
  labeling: {
    flex: '2 2 50%',
  },
  preview: {
    flex: '2 2 50%',
  },
  previewGuide: {
    flex: '1 1 480px',
    minWidth: 480,
    backgroundColor: theme.palette.greyModern[100],
  },
  verticalDivider: {
    width: 4,
  },
}));

type InstantLearningLabelingProps = {};

const InstantLearningLabelingInternal: React.FC<InstantLearningLabelingProps> = () => {
  const styles = useStyles();
  const { state, dispatch } = useInstantLearningState();
  const { browseImagesMode, labelAndTrainMode, trainingState } = state;
  const isTraining = trainingState === 'training-in-progress';
  const [mediaIdStr, setMediaIdStr] = useStateSyncSearchParams('mediaId', '');
  const initialMediaId = Number(mediaIdStr);
  const queryClient = useQueryClient();

  const { state: labelingState, dispatch: dispatchLabelingState } = useLabelingState();

  const groundTruthCanvasRef = useRef<MediaInteractiveCanvas | null>(null);
  const predictionCanvasRef = useRef<MediaInteractiveCanvas | null>(null);
  const instantLearningPostprocessingEnabled = useFeatureGateEnabled(
    ClientFeatures.InstantLearningPostprocessing,
  );

  // Save post-processing config
  const savePostProcessingConfig = useSavePostProcessingConfig();

  // Initialize defects
  const [initializingClasses] = useInitializeClasses(
    2,
    // After the classes are initialized, we save the post-processing config
    defects => {
      if (instantLearningPostprocessingEnabled) {
        savePostProcessingConfig?.(defects);
      }
    },
  );

  // initialize media list
  const {
    state: { mediaIndex },
    dispatch: dispatchImageLabelingState,
  } = useImageLabelingContext();
  const { data: mediaListRes } = useDatasetMedias();
  useEffect(() => {
    dispatchImageLabelingState(draft => {
      if (mediaListRes) {
        draft.mediaList = mediaListRes;
        draft.mediaIndex = initialMediaId
          ? mediaListRes.findIndex(m => m.id === initialMediaId)
          : 0;
        draft.deletedMediaIds = [];
      }
    });
  }, [dispatchImageLabelingState, initialMediaId, mediaListRes]);

  // Initialize media details
  const { datasetId } = useGetSelectedProjectQuery().data ?? {};
  const { latestModel } = useVPLatestModel();
  const { id: latestModelId, confidence: threshold } = latestModel ?? {};

  const hasTrainedModel = !!latestModelId;
  const mediaList = useMediaList();
  const media = mediaList[mediaIndex];
  const mediaId = media?.id;
  const { data: mediaDetails } = useDatasetMediaDetailsQuery({
    datasetId,
    mediaId,
    modelId: latestModelId,
  });
  // prediction annotations
  const segPredictionAnnotations = useSegmentationPredictionLabelingAnnotations(
    mediaDetails?.predictionLabel?.segImgPath || '',
    threshold ?? undefined,
    0.8, // opacity
  );
  const allDefects = useDefectSelector();
  // initialize media details and annotations
  useEffect(() => {
    if (!mediaDetails) {
      return;
    }
    const newAnnotations =
      serverAnnotationsToLabelingAnnotations(mediaDetails.label?.annotations as Annotation[]) ?? [];

    dispatchImageLabelingState(draft => {
      const hasInitialized = !!draft.mediaStatesById[mediaDetails.id];
      const mediaStates = draft.mediaStatesById[mediaDetails.id] ?? ({} as MediaStates);
      if (mediaDetails?.predictionLabel?.segImgPath && segPredictionAnnotations) {
        mediaStates.predictionAnnotations = segPredictionAnnotations;

        // Directly reset the ground truth canvas with the latest prediction annotation
        groundTruthCanvasRef.current?.setAnnotations(prevAnnotations => {
          // Filter out the existing prediction annotations
          const groundTruthCanvasAnnotations = prevAnnotations
            .filter(item => item.group !== AnnotationSourceType.Prediction)
            .map(ann => ({ ...ann, created: false }));

          // Convert the new prediction annotations to canvas annotations, and directly reset the ground truth canvas
          const predictionCanvasAnnotations = segPredictionAnnotations.map(
            ann =>
              ({
                ...offscreenCanvasToCanvasAnnotation(
                  ann as PureCanvasLabelingAnnotation,
                  /* opacity */ 0.8,
                ),
                defectId: ann.defectId,
                group: AnnotationSourceType.Prediction,
              } as CanvasAnnotation),
          );

          return [...groundTruthCanvasAnnotations, ...predictionCanvasAnnotations];
        }, AnnotationChangeType.Reset);
      }

      // in case media details is refreshed during drawing, e.g. after set metadata,
      // we do not overwrite annotations etc.
      if (!hasInitialized) {
        mediaStates.isChanged = false;
        mediaStates.annotations = newAnnotations;
        mediaStates.mediaDetails = mediaDetails;
      }
      draft.mediaStatesById[mediaDetails.id] = mediaStates;
    });
  }, [dispatchImageLabelingState, mediaDetails, segPredictionAnnotations]);

  //refresh mediaCanvas if defect is deleted,Unfiltered predict annotations
  useEffect(() => {
    groundTruthCanvasRef.current?.setAnnotations(
      prev =>
        prev.filter(annotation =>
          annotation.group === AnnotationSourceType.Prediction
            ? true
            : allDefects.find(
                defect =>
                  defect.id === annotation.defectId || defect.color === annotation.data.color,
              ),
        ),
      AnnotationChangeType.Edit,
    );
  }, [allDefects]);

  useEffect(() => {
    if (mediaId) {
      setMediaIdStr(String(mediaId));
    }
  }, [mediaId, setMediaIdStr]);

  useEffect(() => {
    // when mode changes, canvas size changes.
    // need to do make canvas working correctly with container size
    groundTruthCanvasRef.current?.resizeScale();
    predictionCanvasRef.current?.resizeScale();
  }, [browseImagesMode, labelAndTrainMode]);

  // initialize default selected class
  const once = useRef(false);
  useEffect(() => {
    if (allDefects?.length && !labelingState.selectedDefect && mediaDetails && !once.current) {
      dispatchLabelingState(draft => {
        draft.selectedDefect = allDefects[0];
        draft.toolMode = draft.toolMode ?? ToolMode.Brush;
        const { width = 1500, height = 1000 } = mediaDetails.properties ?? {};
        const initStrokeWidth = Math.max(1, Math.round(Math.min(width, height) * 0.015));
        draft.toolOptions.strokeWidth = initStrokeWidth;
        draft.toolOptions.eraserWidth = initStrokeWidth;
      });
      once.current = true;
    }
  }, [allDefects, labelingState, dispatchLabelingState, mediaDetails]);

  const showPredictionPanel = hasTrainedModel || isTraining;

  const prevTrainingStateRef = useRef<typeof trainingState>(trainingState);
  useEffect(() => {
    // When training completed from training to first-batch-complete
    if (
      datasetId &&
      prevTrainingStateRef.current === 'training-in-progress' &&
      trainingState === 'first-batch-complete'
    ) {
      queryClient.invalidateQueries(
        datasetQueryKeys.mediaDetails(datasetId, {
          mediaId,
          modelId: latestModelId,
        }),
      );
    }
    // When training completed from first-batch-complete to full batch ready
    if (prevTrainingStateRef.current === 'first-batch-complete' && trainingState === null) {
      dispatch(draft => {
        draft.labelingWrapper.showGroundTruthLabels = false;
        draft.browseImagesMode = 'topbar';
      });
    }
    prevTrainingStateRef.current = trainingState;
  }, [datasetId, dispatch, dispatchLabelingState, mediaId, latestModelId, trainingState]);

  return (
    <Box className={styles.root} data-testid="instant-learning-labeling-page">
      <TopToolBar
        groundTruthCanvasRef={groundTruthCanvasRef}
        predictionCanvasRef={predictionCanvasRef}
      />
      <Box className={styles.labelingAndPreview}>
        {/* To be removed. Keep it for now in case we want to bring the entry point back */}
        {browseImagesMode === 'sidebar' && (
          <Box className={styles.browseImagesSideBar}>
            <BrowseImagesSideBar />
          </Box>
        )}
        {/* To be removed. Keep it for now in case we want to bring the entry point back */}
        {browseImagesMode === 'fullscreen' && (
          <BrowseImagesFullscreenDialog
            open
            onClose={mediaId => {
              dispatch(draft => {
                draft.browseImagesMode = 'sidebar';
              });
              const mediaIndex = mediaId && mediaList.findIndex(m => m.id === mediaId);
              if (mediaIndex && mediaIndex !== -1) {
                dispatchImageLabelingState(draft => {
                  draft.mediaIndex = mediaIndex;
                });
              }
            }}
          />
        )}
        <Box className={styles.labeling} zIndex={1}>
          <LabelingWrapper
            groundTruthCanvasRef={groundTruthCanvasRef}
            predictionCanvasRef={predictionCanvasRef}
            loading={initializingClasses}
            isTraining={isTraining}
          />
        </Box>

        {!showPredictionPanel && (
          <Box flex="1 1 480px" minWidth={480} overflow="auto" zIndex={0}>
            <LabelingStepGuide />
          </Box>
        )}

        {/* Hide for now */}
        {/* <Box display={labelAndTrainMode === 'split' ? 'flex' : 'none'}>
          <Divider variant="fullWidth" orientation="vertical" className={styles.verticalDivider} />
          <Box className={showPredictionPanel ? styles.preview : styles.previewGuide}>
            {showPredictionPanel ? (
              <PredictionWrapper
                isTraining={isTraining}
                isLoadingPredictions={!!(registeredModelId && mediaDetailsLoading)}
                groundTruthCanvasRef={groundTruthCanvasRef}
                predictionCanvasRef={predictionCanvasRef}
              />
            ) : (
              <LabelingStepGuide />
            )}
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

const InstantLearningLabeling: React.FC<InstantLearningLabelingProps> = props => {
  const { id: projectId, labelType, datasetId } = useGetSelectedProjectQuery().data ?? {};
  const [dataBrowserState, dispatchDataBrowserState] = useImmer(
    omit(getStateFromStorage(projectId), 'labelDisplay'),
  );
  const { latestModel } = useVPLatestModel();
  const { id: latestModelId } = latestModel ?? {};

  const [labelingState, dispatchLabelingState] = useImmer<LabelingState>({
    ...defaultLabelingState,
    labelingType: LabelingType.DefectSegmentation,
  });

  if (!projectId) return null;

  return (
    <DataBrowserStateContext.Provider
      value={{ state: dataBrowserState, dispatch: dispatchDataBrowserState }}
    >
      <PredictContextProvider
        projectId={projectId}
        datasetId={datasetId}
        labelType={labelType}
        modelId={latestModelId}
      >
        <LabelingContext.Provider value={{ state: labelingState, dispatch: dispatchLabelingState }}>
          <InstantLearningProjectContextProvider>
            <ImageLabelingContextProvider>
              <HintSnackbarContextProvider>
                <InstantLearningLabelingInternal {...props} />
              </HintSnackbarContextProvider>
            </ImageLabelingContextProvider>
          </InstantLearningProjectContextProvider>
        </LabelingContext.Provider>
      </PredictContextProvider>
    </DataBrowserStateContext.Provider>
  );
};

export default InstantLearningLabeling;

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import React, { useCallback, useEffect, useState } from 'react';
import ConfirmationDialog from '../Utils/ConfirmationDialog';
import NewApiKeySecretDialog from './NewApiKeySecretDialog';
import UserAPI from '../../api/user_api';
import { useTypedSelector } from '../../hooks/useTypedSelector';

const DevTools: React.FC = () => {
  const currentUser = useTypedSelector(state => state.login.user);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openNewKeySecretDialog, setOpenNewKeySecretDialog] = useState(false);
  const [generatedApiKey, setGeneratedApiKey] = useState<string | null>(null);
  const [generatedApiSecret, setGeneratedApiSecret] = useState<string | null>(null);
  const [existingApiKey, setExistingApiKey] = useState<string | null>(null);

  const getApiKey = useCallback(() => {
    UserAPI.getApiKey().then(key => {
      if (key && key.apiKeyHash) {
        setExistingApiKey(key.apiKeyHash);
      }
    });
  }, []);

  useEffect(() => {
    getApiKey();
  }, [getApiKey]);

  const generateAPIKey = useCallback(async () => {
    if (currentUser) {
      const res = await UserAPI.createApiKeyAndSecret();
      setGeneratedApiKey(res.key);
      setGeneratedApiSecret(res.secret);
      setExistingApiKey(res.key);
    }
  }, [currentUser]);

  const cancelAction = useCallback(() => {
    setOpenConfirmDialog(false);
  }, []);

  const confirmAction = useCallback(async () => {
    await generateAPIKey();
    setOpenNewKeySecretDialog(true);
    setOpenConfirmDialog(false);
  }, [generateAPIKey]);

  const closeKeyAndSecretDialog = useCallback(() => {
    setOpenNewKeySecretDialog(false);
  }, []);

  const handleGenerationButtonClick = useCallback(async () => {
    if (!existingApiKey) {
      await generateAPIKey();
      setOpenNewKeySecretDialog(true);
    } else {
      setOpenConfirmDialog(true);
    }
  }, [existingApiKey, generateAPIKey]);

  if (currentUser) {
    return (
      <div style={{ margin: 10 }}>
        <Typography variant="h3" gutterBottom>
          {t('API Keys')}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {t(
            'Landing AI Platform uses API keys to authenticate requests. You can view and manage the API keys through this utility.',
          )}
        </Typography>
        <Paper>
          <Table>
            <colgroup>
              <col style={{ width: '50%' }} />
              <col style={{ width: '50%' }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell align="left">{t('API Key')}</TableCell>
                <TableCell align="left">{t('API Secret')}</TableCell>
              </TableRow>
            </TableHead>
            {existingApiKey ? (
              <TableBody>
                <TableCell>{existingApiKey}</TableCell>
                <TableCell>
                  {t('*********************************************************')}
                </TableCell>
              </TableBody>
            ) : null}
          </Table>
        </Paper>
        <br />
        <Grid container justifyContent={'center'}>
          <Grid>
            <Button
              id="generate-api-key-button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleGenerationButtonClick()}
            >
              {t('Generate API Key')}
            </Button>
          </Grid>
        </Grid>
        <br />
        <Typography variant="subtitle1" gutterBottom>
          {t(
            'Landing AI Platform API keys carry many privileges. Please keep them secure. Do not share the API keys in publicly accessible areas such as GitHub, client-side scripts, etc. All API requests must be made over HTTPS. Calls made over HTTP will fail. Unauthorized API requests will also fail.',
          )}
        </Typography>
        <ConfirmationDialog
          open={openConfirmDialog}
          onClose={cancelAction}
          onClickConfirm={confirmAction}
          onClickCancel={cancelAction}
          text={
            'A valid API key and secret already exists. Generating another API key will override existing key and shared secret. Do you still want to continue?'
          }
        />
        {generatedApiKey && generatedApiSecret ? (
          <NewApiKeySecretDialog
            open={openNewKeySecretDialog}
            onClickOk={closeKeyAndSecretDialog}
            apiKey={generatedApiKey}
            secret={generatedApiSecret}
          />
        ) : null}
      </div>
    );
  }
  return null;
};

export default DevTools;

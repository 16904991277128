import React, { useCallback, useMemo, useState } from 'react';
import { Box, Grid, Menu } from '@material-ui/core';
import { Button, IconButton } from '@clef/client-library';
import { useValidProjectModels } from '@/serverStore/projectModels';
import { rootElement } from '@/utils/dom_utils';
import { ApiResponseLoader, Typography } from '@clef/client-library';
import { RegisteredModel } from '@clef/shared/types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useStyles } from './styles';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ModelMenuItem from './ModelMenuItem';
import cx from 'classnames';
import { useModelStatusQuery } from '@/serverStore/projectModels';

type ModelSwitcherProps = {
  idPrefix?: string;
  modelId: string | undefined;
  classes?: {
    switchModelMenu?: string;
  };
  onModelSwitch: (newModelId: string) => void | Promise<void>;
  onModelMoreVertIconClick?: () => void | Promise<void>;
};

export const ModelSwitcher: React.FC<ModelSwitcherProps> = ({
  idPrefix = '',
  modelId,
  classes,
  onModelSwitch,
  onModelMoreVertIconClick,
}) => {
  const styles = useStyles();
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const [switchModelMenuAnchorEl, setSwitchModelMenuAnchorEl] = useState<HTMLElement | null>(null);

  const { data: modelStatus } = useModelStatusQuery(projectId, modelId);
  const {
    loading: projectModelsLoading,
    error: projectModelsError,
    models: projectModelsData,
    savedModels,
  } = useValidProjectModels();

  const onDropdownButtonClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setSwitchModelMenuAnchorEl(event.currentTarget);
  }, []);

  const onDropdownButtonClose = useCallback(() => {
    setSwitchModelMenuAnchorEl(null);
  }, []);

  const renderMenuItems = useCallback(
    (savedModels: RegisteredModel[]) => {
      const menuItems = [];
      if (savedModels?.length) {
        // Saved models section title
        menuItems.push(
          <Typography className={styles.modelsMenuSectionHeader}>{t('Saved')}</Typography>,
        );

        const savedModelsItems = savedModels?.map(model => {
          return (
            <ModelMenuItem
              key={model.id}
              model={model}
              className={styles.modelItem}
              onMenuItemClick={() => {
                onModelSwitch(model.id);
                onDropdownButtonClose();
              }}
            />
          );
        });

        menuItems.push(...savedModelsItems);
      }

      return menuItems;
    },
    [onDropdownButtonClose, onModelSwitch, styles.modelItem, styles.modelsMenuSectionHeader],
  );

  const currentModel = useMemo(() => {
    if (!modelId || !projectModelsData) {
      return null;
    }

    return projectModelsData.find(model => model.id === modelId) ?? null;
  }, [modelId, projectModelsData]);

  return (
    <ApiResponseLoader
      response={projectModelsData}
      loading={projectModelsLoading}
      error={projectModelsError ?? undefined}
      defaultHeight={40}
      defaultWidth={400}
    >
      {() => {
        return (
          <Box>
            <Button
              id={`${idPrefix}-switch-model-button`}
              data-testid={`${idPrefix}-switch-model-button`}
              aria-controls={`${idPrefix}-switch-model-menu`}
              aria-haspopup="true"
              variant="outlined"
              onClick={onDropdownButtonClick}
              className={styles.switchModelButton}
            >
              {currentModel ? (
                <Grid container alignItems="center">
                  {/* Use fallback model name if not saved */}
                  <Typography className={styles.modelName} maxWidth="calc(100% - 50px)">
                    {currentModel.modelName}
                  </Typography>

                  {/* More vert icon button */}
                  {/* eslint-disable-next-line max-len */}
                  {!!onModelMoreVertIconClick && modelStatus?.modelId === modelId && (
                    <IconButton
                      id="model-switcher-show-more-button"
                      component="div"
                      size="small"
                      onClick={onModelMoreVertIconClick}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  )}

                  <Box flex={1} />

                  <KeyboardArrowDownIcon />
                </Grid>
              ) : (
                t('Unknown model')
              )}
            </Button>

            <Menu
              elevation={0}
              // Required for placing the menu item below the dropdown button
              // https://github.com/mui/material-ui/issues/7961
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              id={`${idPrefix}-switch-model-menu`}
              keepMounted
              anchorEl={switchModelMenuAnchorEl}
              open={!!switchModelMenuAnchorEl}
              onClose={onDropdownButtonClose}
              container={rootElement}
              disablePortal
              classes={{
                paper: cx(styles.switchModelMenu, classes?.switchModelMenu),
              }}
            >
              {renderMenuItems(savedModels!)}
            </Menu>
          </Box>
        );
      }}
    </ApiResponseLoader>
  );
};

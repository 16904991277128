import {
  useFeatureGateEnabled,
  useIsFeatureEnabledAndMayHideForSnowflake,
  useModelAnalysisEnabled,
} from '@/hooks/useFeatureGate';
import { useGetEndpointListQuery } from '@/serverStore/endpoints';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import {
  ApiResponseLoader,
  Button,
  Typography,
  useStateSyncSearchParams,
} from '@clef/client-library';
import { ClientFeatures } from '@clef/shared/features';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';

import { ApiErrorType } from '@/api/base_api';
import ContainerIconSVG from '@/images/deploy/container.svg';
import { DeployedModel, Endpoint, EndpointData } from '@clef/shared/types';
import { Icon } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import DeveloperBoard from '@material-ui/icons/DeveloperBoard';
import DownloadIcon from '@material-ui/icons/GetApp';
import ListAltIcon from '@material-ui/icons/ListAlt';
import cx from 'classnames';
import { EndpointIcon } from './EndpointIcon';
import { useSubscriptionPlanSettings } from '@/hooks/api/useSubscriptionApi';

const CloudIcon = () => (
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2C14.62 2 16.88 3.86 17.39 6.43L17.69 7.93L19.22 8.04C20.78 8.14 22 9.45 22 11C22 12.65 20.65 14 19 14H6C3.79 14 2 12.21 2 10C2 7.95 3.53 6.24 5.56 6.03L6.63 5.92L7.13 4.97C8.08 3.14 9.94 2 12 2ZM12 0C9.11 0 6.6 1.64 5.35 4.04C2.34 4.36 0 6.91 0 10C0 13.31 2.69 16 6 16H19C21.76 16 24 13.76 24 11C24 8.36 21.95 6.22 19.35 6.04C18.67 2.59 15.64 0 12 0Z"
      fill="#697586"
    />
  </svg>
);

const MonitorIcon = () => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 0H2C0.9 0 0 0.9 0 2V13C0 14.1 0.9 15 2 15H5L4 16V18H16V16L15 15H18C19.1 15 20 14.1 20 13V2C20 0.9 19.1 0 18 0ZM18 13H2V2H18V13Z"
      fill="#697586"
    />
  </svg>
);

const ContainerDeployIcon = () => {
  return (
    <Icon fontSize="inherit" style={{ verticalAlign: 'center' }}>
      <img src={ContainerIconSVG} alt="Container" />
    </Icon>
  );
};

const useStyles = makeStyles(theme => ({
  activeItem: {
    background: theme.palette.greyBlue[100],
    color: `${theme.palette.greyModern[800]} !important`,
  },
  accordin: {
    padding: theme.spacing(0, 2.5),
  },
  accordinArrowIcon: {
    fontSize: '0.9rem',
    transition: 'rotate 0.2s ease-out',
    marginRight: theme.spacing(2),
  },
  accordinText: {
    marginLeft: theme.spacing(2),
    fontWeight: 500,
    color: theme.palette.greyModern[500],
  },
  sectionSubButton: {
    padding: theme.spacing(2.5, 8),
    alignSelf: 'flex-start',
    width: '100%',
  },
  subButtonText: {
    color: theme.palette.greyModern[500],
    fontWeight: 700,
  },
  sectionSubButtonText: {
    justifyContent: 'flex-start',
  },
  apiCodeContainer: {
    cursor: 'pointer',
    padding: theme.spacing(0, 2.5),
    height: 34,
    borderRadius: 6,
    color: theme.palette.greyModern[500],
  },
  apiCodeText: {
    marginLeft: theme.spacing(2),
    fontWeight: 500,
  },
  endpointButtonLabel: {
    justifyContent: 'flex-start',
  },
  staticDeploymentIcon: {
    color: theme.palette.greyModern[500],
  },
}));

export enum SidebarSections {
  CloudDeployment = 'cloud-deployment',
  StaticDeployment = 'static-deployment',
  EdgeDeployment = 'edge=deployment',
  DownloadList = 'download-list',
}

export const AccordionItem: React.FC<{ title: string; icon: React.ReactNode }> = ({
  title,
  icon,
  children,
}) => {
  const styles = useStyles();
  const [expanded, setExpanded] = useState(true);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      className={styles.accordin}
    >
      <AccordionSummary>
        <Box display="flex" alignItems="center" justifyContent="center">
          <ArrowForwardIosSharpIcon
            className={styles.accordinArrowIcon}
            style={{ rotate: expanded ? '90deg' : '0deg' }}
          />
          {icon}
          <Typography className={styles.accordinText}>{title}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export const AccordionContent: React.FC<{
  selectedEndpointId: string;
  activeSection: SidebarSections;
  sidebarSection: SidebarSections;
  onEndpointSelect: (section: SidebarSections, endpointData: EndpointData) => void;
  endpoints: {
    data:
      | {
          endpoint: Endpoint;
          model: DeployedModel | null;
        }[]
      | undefined;
    loading: boolean;
    error: ApiErrorType | undefined;
  };
}> = ({
  activeSection,
  sidebarSection,
  selectedEndpointId,
  onEndpointSelect,
  children,
  endpoints,
}) => {
  const styles = useStyles();

  return (
    <Grid container direction="column" wrap="nowrap">
      <ApiResponseLoader
        response={endpoints.data}
        loading={endpoints.loading}
        error={endpoints.error}
      >
        {endpoints =>
          endpoints.map(endpoint => {
            return (
              <Button
                key={endpoint.endpoint.name}
                id={`endpoint-${endpoint.endpoint.id}-button`}
                onClick={() => onEndpointSelect(sidebarSection, endpoint)}
                className={cx(
                  activeSection === sidebarSection &&
                    selectedEndpointId === endpoint.endpoint.id &&
                    styles.activeItem,
                  styles.sectionSubButton,
                )}
                startIcon={
                  <EndpointIcon
                    endpointId={endpoint.endpoint.id}
                    size={24}
                    isCloudEndpoint={sidebarSection !== SidebarSections.EdgeDeployment}
                  />
                }
                classes={{
                  label: styles.endpointButtonLabel,
                }}
              >
                <Typography className={styles.subButtonText} maxWidth={150}>
                  {endpoint.endpoint.name}
                </Typography>
              </Button>
            );
          })
        }
      </ApiResponseLoader>

      {children}
    </Grid>
  );
};

export type EndpointsSidebarProps = {
  activeSection: SidebarSections;
  onEndpointCreate: () => void;
  onSidebarItemClick: (section: SidebarSections, data?: EndpointData) => void;
  onDownloadLandingEdgeButtonClick?: () => void | Promise<void>;
  onDeployModelRunnerButtonClick: () => void | Promise<void>;
};

export const EndpointsSidebar: React.FC<EndpointsSidebarProps> = ({
  activeSection,
  onEndpointCreate,
  onSidebarItemClick,
  onDownloadLandingEdgeButtonClick,
  onDeployModelRunnerButtonClick,
}) => {
  const styles = useStyles();
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const enableModelAnalysis = useModelAnalysisEnabled();
  const {
    data: endpointsData,
    isLoading: endpointsLoading,
    error: endpointsError,
  } = useGetEndpointListQuery(projectId ?? 0);

  const { cloudDeployment: isCloudDeploymentEnabled } = useIsFeatureEnabledAndMayHideForSnowflake();
  const isModelRunnerReady = useFeatureGateEnabled(ClientFeatures.ModelRunnerReady);
  const [selectedEndpointId] = useStateSyncSearchParams('selected_endpoint_id', '');
  const [orgPlanSetting] = useSubscriptionPlanSettings('anystring');

  return (
    <Box minWidth={240} minHeight="100%" marginRight={10}>
      {isCloudDeploymentEnabled && (
        <AccordionItem title={t('Cloud Deployment')} icon={<CloudIcon />}>
          <AccordionContent
            sidebarSection={SidebarSections.CloudDeployment}
            activeSection={activeSection}
            selectedEndpointId={selectedEndpointId}
            onEndpointSelect={onSidebarItemClick}
            endpoints={{
              data: endpointsData?.filter(endpoint => endpoint.endpoint.type === 'dynamic'),
              loading: endpointsLoading,
              error: endpointsError ?? undefined,
            }}
          >
            <Button
              id="create-endpoint-button"
              className={cx(styles.sectionSubButton, styles.subButtonText)}
              startIcon={<Add />}
              onClick={onEndpointCreate}
              classes={{ label: styles.sectionSubButtonText }}
            >
              {t('Create Endpoint')}
            </Button>
          </AccordionContent>
        </AccordionItem>
      )}

      {!!endpointsData?.filter(endpoint => endpoint.endpoint.type === 'static').length && (
        <AccordionItem
          title={t('Static Deployment')}
          icon={<DeveloperBoard className={styles.staticDeploymentIcon} />}
        >
          <AccordionContent
            sidebarSection={SidebarSections.StaticDeployment}
            activeSection={activeSection}
            selectedEndpointId={selectedEndpointId}
            onEndpointSelect={onSidebarItemClick}
            endpoints={{
              data: endpointsData?.filter(endpoint => endpoint.endpoint.type === 'static'),
              loading: endpointsLoading,
              error: endpointsError ?? undefined,
            }}
          />
        </AccordionItem>
      )}

      {!orgPlanSetting?.hideLeAndDocker && (
        <AccordionItem title={t('Self Hosted Deployment')} icon={<MonitorIcon />}>
          <AccordionContent
            sidebarSection={SidebarSections.EdgeDeployment}
            activeSection={activeSection}
            selectedEndpointId={selectedEndpointId}
            onEndpointSelect={onSidebarItemClick}
            endpoints={{
              data: endpointsData?.filter(endpoint => endpoint.endpoint.type === 'edge'),
              loading: endpointsLoading,
              error: endpointsError ?? undefined,
            }}
          >
            <Button
              id="download-landing-edge-button"
              className={cx(styles.sectionSubButton, styles.subButtonText)}
              startIcon={<DownloadIcon />}
              onClick={onDownloadLandingEdgeButtonClick}
              classes={{ label: styles.sectionSubButtonText }}
            >
              {t('LandingEdge')}
            </Button>

            {isModelRunnerReady && (
              <Button
                id="download-model-runner-button"
                className={cx(styles.sectionSubButton, styles.subButtonText)}
                startIcon={<ContainerDeployIcon />}
                onClick={onDeployModelRunnerButtonClick}
                classes={{ label: styles.sectionSubButtonText }}
              >
                {t('Container Deploy')}
              </Button>
            )}
          </AccordionContent>
        </AccordionItem>
      )}

      {!enableModelAnalysis && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          height={28}
          onClick={() => onSidebarItemClick(SidebarSections.DownloadList)}
          className={cx(
            activeSection === SidebarSections.DownloadList && styles.activeItem,
            styles.apiCodeContainer,
          )}
        >
          <ListAltIcon />
          <Typography className={styles.apiCodeText}>{t('Deployable Models')}</Typography>
        </Box>
      )}
    </Box>
  );
};

import React, { useCallback, useRef } from 'react';
import cx from 'classnames';

import { useLabelingTaskStyles } from './labelingTaskStyles';
import {
  useLabelingTaskState,
  useGoToNextMedia,
  useSaveAnnotationsToState,
} from './labelingTaskState';

import {
  MediaInteractiveCanvas,
  useKeyPress,
  MediaInteractiveCanvasProps,
  AnnotationChangeType,
  CanvasAnnotationType,
} from '@clef/client-library';
import { useDefectSelector } from '../../store/defectState/actions';
import { LabelingType } from '@clef/shared/types';
import { useHintSnackbar, HintEvents } from '../../components/Labeling/HintSnackbar';
import LabelingTaskFooter from './components/LabelingTaskFooter';
import {
  BitMapLabelingAnnotation,
  BoxLabelingAnnotation,
  useColorToDefectIdMap,
  useLabelingState,
} from '../../components/Labeling/labelingState';
import MediaCanvasWrapper from '../../components/Labeling/MediaCanvasWrapper';
import LabelingTaskMediaInfo from './LabelingTaskMediaInfo';
import {
  canvasAnnotationToBoxAnnotation,
  layerToBitMapAnnotationsAsync,
} from '../../components/Labeling/utils';

export interface LabelingTaskMainProps {}

const LabelingTaskMain: React.FC<LabelingTaskMainProps> = () => {
  const styles = useLabelingTaskStyles();
  const {
    state: { currentMediaId, taskMediaList, annotationData },
    dispatch,
  } = useLabelingTaskState();
  const {
    state: { labelingType },
  } = useLabelingState();
  const once = useRef(false);
  const mediaInteractiveCanvasRef = useRef<MediaInteractiveCanvas | null>(null);

  const currentMedia = taskMediaList.find(media => media.id === currentMediaId);
  const allDefects = useDefectSelector();
  const triggerHint = useHintSnackbar();
  const goToNextMedia = useGoToNextMedia();
  const annotationsForThisMedia = annotationData[currentMediaId]?.filter(
    ann => !!allDefects.find(d => d.id === ann.defectId),
  );

  const colorToDefectIdMap = useColorToDefectIdMap();

  const saveAnnotationsToState = useSaveAnnotationsToState();

  const onAnnotationChanged = useCallback<
    NonNullable<MediaInteractiveCanvasProps['onAnnotationChanged']>
  >(
    (newAnnotations, changeType, layer) => {
      const hasAnnotation = newAnnotations.length > 0;
      if (!once.current && hasAnnotation) {
        once.current = true;
        triggerHint(HintEvents.LabelInteractions);
      }

      // for simplicity and test sake, avoid creating canvas calculation when we know we are cleaning up
      if (!hasAnnotation) {
        saveAnnotationsToState([]);
      } else if (labelingType === LabelingType.DefectSegmentation) {
        layerToBitMapAnnotationsAsync(layer, currentMedia?.properties!, allDefects).then(
          saveAnnotationsToState,
        );
      } else if (labelingType === LabelingType.DefectBoundingBox) {
        const newBoxAnnotations: BoxLabelingAnnotation[] = newAnnotations
          .filter(ann => ann.type === CanvasAnnotationType.Box)
          .map(ann => canvasAnnotationToBoxAnnotation(ann, colorToDefectIdMap));
        if (changeType === AnnotationChangeType.Select) {
          // selecting annotations needs states update, but do not need to be saved to server
          dispatch(draft => {
            draft.annotationData[currentMediaId] = newBoxAnnotations;
          });
          if (newAnnotations.some(ann => ann.selected)) {
            triggerHint(HintEvents.SelectInstructions);
          } else {
            triggerHint();
          }
        } else {
          // saveAnnotations(newBoxAnnotations);
          saveAnnotationsToState(newBoxAnnotations);
        }
      }
    },
    [
      labelingType,
      triggerHint,
      saveAnnotationsToState,
      currentMedia,
      allDefects,
      colorToDefectIdMap,
      dispatch,
      currentMediaId,
    ],
  );

  useKeyPress(
    'n',
    () => {
      if (
        [LabelingType.DefectBoundingBox, LabelingType.DefectSegmentation].includes(labelingType)
      ) {
        onAnnotationChanged([], AnnotationChangeType.DeleteAll, null);
        goToNextMedia();
      }
    },
    { id: 'task-nothing-to-label' },
  );

  if (!currentMedia || !allDefects) {
    return null;
  }

  return (
    <>
      {labelingType !== LabelingType.DefectClassification && (
        <LabelingTaskMediaInfo mediaCanvasRef={mediaInteractiveCanvasRef} />
      )}
      <section className={cx(styles.panelContainer, styles.mainPanelContainer)}>
        <div className={styles.canvasContainer}>
          <MediaCanvasWrapper
            isLabelMode
            mediaCanvasRef={mediaInteractiveCanvasRef}
            mediaDetails={currentMedia}
            annotations={
              (annotationsForThisMedia ?? []) as
                | BoxLabelingAnnotation[]
                | BitMapLabelingAnnotation[]
            }
            onAnnotationChanged={onAnnotationChanged}
            isLabelingTask
            hidePredictionLabels
          />
        </div>
        <LabelingTaskFooter mediaCanvasRef={mediaInteractiveCanvasRef} />
      </section>
    </>
  );
};

export default LabelingTaskMain;

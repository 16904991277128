import { makeStyles } from '@material-ui/core';

export const useDefectBookEnhancedStyles = makeStyles(theme => ({
  // defect list
  defectListContainer: {
    paddingTop: theme.spacing(5),
  },
  defectListContentContainer: {
    marginTop: theme.spacing(7),
  },
  // defect card view
  defectCard: {
    width: 'auto',
    // margin: theme.spacing(0, 6, 6, 0),
    height: 160,
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    borderTopWidth: 8,
    borderTopStyle: 'solid',
  },
  approvedMediaCount: {
    textAlign: 'right',
    color: theme.palette.grey[500],
  },
  // defect detail view
  defectDetailNav: {
    width: 200,
    minWidth: 200,
    marginRight: theme.spacing(6),
  },
  defectDetailNavFixed: {
    position: 'fixed',
    top: 85,
  },
  defectDetailNavPaper: {
    padding: theme.spacing(2, 0),
    marginBottom: theme.spacing(6),
    maxHeight: 540,
    overflow: 'auto',
  },
  defectDetailNavRow: {
    padding: theme.spacing(2.5, 5),
    cursor: 'pointer',
    '&[data-selected="true"]': {
      backgroundColor: `${theme.palette.primary.main}1A`,
    },
    '&[data-selected="true"] $defectDetailNavRowText': {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  },
  defectDetailNavRowText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 128,
  },
  // defect detail view context
  editModeMask: {
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: '#fff',
    opacity: 0.7,
    width: '100vw',
    height: '100vh',
    zIndex: theme.zIndex.modal - 2,
    '& + $defectDetailContent': {
      zIndex: theme.zIndex.modal - 1,
      boxShadow: `${theme.boxShadow.strong} !important`,
      maxHeight: 'calc(100vh - 120px)',
    },
  },
  defectDetailContent: {
    flexGrow: 1,
    position: 'relative',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    '& .MuiPaper-elevation1': {
      width: '70%',
    },
  },
  linearProgress: {
    position: 'sticky',
    top: 0,
    zIndex: 103,
  },
  defectDetailHeader: {
    padding: theme.spacing(8, 8, 0),
    position: 'sticky',
    backgroundColor: '#fff',
    top: 0,
    zIndex: 101,
  },
  postRequestMask: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
    background: 'rgba(255,255,255,0.7)',
  },
  defectDetailContentInner: {
    padding: theme.spacing(8, 8),
    position: 'relative',
  },
  defectDetailContentDescription: {
    paddingRight: theme.spacing(6),
  },
  editModeTitle: {
    ...theme.typography.h3,
    padding: theme.spacing(2, 3),
  },
  editModeInputRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
    },
    marginBottom: theme.spacing(2),
  },
  editorModeTextArea: {
    ...theme.typography.body1,
    padding: theme.spacing(5),
    minHeight: 120,
    flexBasis: 0,
    flexGrow: 1,
    borderColor: theme.palette.grey[300],
    borderRadius: 8,
    '&:hover': {
      borderColor: theme.palette.grey[800],
    },
  },
  editModeActionable: {
    cursor: 'pointer',
    boxShadow: theme.boxShadow.default,
    transition: theme.transitions.create('box-shadow', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
      boxShadow: theme.boxShadow.strong,
    },
  },
  //  defect media stats
  defectMediaStatsRoot: {
    overflow: 'auto',
  },
  defectMediaStats: {
    backgroundColor: theme.palette.grey[100],
    width: 400,
    borderRadius: 8,
    padding: theme.spacing(4),
  },
  // defect example
  defectExampleContainer: {
    position: 'relative',
    borderRadius: 8,
    borderColor: theme.palette.grey[300],
    borderWidth: 1,
    borderStyle: 'solid',
    overflow: 'hidden',
    '&:hover $defectExampleAction': {
      opacity: 1,
    },
  },
  defectExampleAction: {
    position: 'absolute',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    top: 20,
    right: 20,
    width: 'auto',
    opacity: 0,
  },
  defectExampleActionFab: {
    margin: theme.spacing(0, 2),
  },
  defectExampleText: {
    padding: theme.spacing(0, 3),
    height: '100%',
    overflow: 'auto',
  },
  // Labeling instructions styles
  labelingInstructionSection: {
    padding: theme.spacing(5, 0),
  },
  labelingInstructionTitle: {
    marginBottom: theme.spacing(7),
  },
  terminologyContainer: {
    padding: theme.spacing(7),
    marginBottom: theme.spacing(4),
  },
  terminologyExample: {
    height: 320,
    position: 'relative',
    '&:hover $defectExampleAction': {
      opacity: 1,
    },
  },
  terminologyMedia: {
    borderRadius: 8,
    overflow: 'hidden',
    height: '100%',
    marginRight: theme.spacing(6),
  },
  // defect book print
  defectBookPrintContainer: {
    width: '100vw',
  },
  printLoader: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: '100%',
    left: 0,
    zIndex: 100,
    background: 'rgba(255,255,255,0.7)',
  },
  defectBookPrintContainerInner: {
    margin: theme.spacing(6, 'auto'),
    maxWidth: 1600,
  },
  defectBookPrint: {
    margin: theme.spacing(6),
    '& button': {
      display: 'none',
    },
  },
  // edit media modal
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperContainer: {
    width: '80vw',
    height: '80vh',
    overflow: 'hidden',
  },
  paperInner: {
    height: '90%',
    width: '100%',
  },
  colorPalette: {
    width: 20,
    height: 20,
    borderRadius: 15,
    transition: 'all 0.2s',
  },
  actionButton: {
    marginLeft: 10,
  },
  canvasContainer: {
    height: '90%',
    position: 'relative',
    padding: theme.spacing(2, 3),
  },
  toolContainerGrow: {
    flexGrow: 1,
    flexBasis: 0,
  },
  toolContainerBottom: {
    paddingTop: 20,
  },
  toolContainer: {
    padding: theme.spacing(1, 4, 2.5, 4),
    overflowY: 'auto',
    height: '100%',
  },
  toggleButton: {
    fontFamily: 'Commissioner, sans-serif',
    fontWeight: 700,
    fontSize: 14,
    textTransform: 'none',
    height: 34,
    padding: theme.spacing(6, 3),
    border: 'none !important',
  },
  colorToggleButton: {
    padding: `${theme.spacing(6, 1.5)} !important`,
  },
  shortcutSquare: {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 4,
    fontWeight: 700,
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 20,
    height: 20,
    padding: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  codeBlock: {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 4,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: theme.spacing(1),
    fontWeight: 700,
  },
  shortcutParent: {
    position: 'relative',
    paddingRight: 24,
    paddingBottom: 2,
  },
  backspaceIcon: {
    width: '100%',
    height: '100%',
  },
  // rest
  flexGrow: { flexGrow: 1 },
  width100: { width: '100%' },
  marginRight2: { marginRight: theme.spacing(2) },
  marginBottom4: { marginBottom: theme.spacing(4) },
  marginBottom6: { marginBottom: theme.spacing(6) },
  noDefectText: { color: theme.palette.grey[400] },
  defectColorSmall: {
    width: 20,
    height: 20,
    borderRadius: 6,
    marginRight: theme.spacing(3),
  },
  defectColorMedium: {
    width: 32,
    height: 32,
    borderRadius: 10,
    marginRight: theme.spacing(5),
  },
  colorPicker: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(7),
  },
  hidden: {
    display: 'none',
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  markdownText: {
    ...theme.typography.body1,
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  createDefectInformation: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    boxShadow: 'none !important',
  },
  colorPaletteBorder: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 15,
    padding: theme.spacing(0.5, 0.5),
  },
  selected: {
    '&&': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  colorTogglesContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  popover: {
    position: 'absolute',
    zIndex: 2,
    left: 'auto',
  },
  addColorButton: {
    margin: theme.spacing(1),
    alignSelf: 'center',
    padding: theme.spacing(0),
  },
  disabledBox: {
    padding: 1,
    border: '1px solid #666',
    position: 'relative',
    borderRadius: '100%',
    margin: '0 6px',
  },
  disabled: {
    cursor: 'no-drop',
  },
  disabledLine: {
    position: 'absolute',
    top: 0,
    left: 11.5,
    width: 1,
    height: 23,
    backgroundColor: '#999',
    transform: 'rotate(24.5deg)',
    transformOrigin: 'center',
  },
  newColorPicker: {
    padding: '20px 15px 10px',
  },
  newCreateClassesButton: {
    borderRadius: 6,
  },
  newBackButton: {
    marginLeft: -theme.spacing(5),
    marginBottom: theme.spacing(4),
  },
  newTopButtonGroup: {
    top: 174,
    right: 32,
    position: 'absolute',
    marginBottom: theme.spacing(7),
    marginTop: -theme.spacing(7),
  },
  defectNameBox: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, Box, Grid } from '@material-ui/core';
import cx from 'classnames';
import { ApiResponseLoader } from '@clef/client-library';
import ExampleMediaContainer from '@/pages/examples/ExampleMediaContainer';
import { Defect, ProjectId } from '@clef/shared/types';
import PredictDialog from '@/pages/examples/PredictDialog';
import { useExampleProjectDetails } from '@/hooks/api/useExampleProjectApi';
import { useDatasetMediaDetailsQuery } from '@/serverStore/dataset';
import { PredictContextProvider } from '@/components/Predict/predictContext';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    cursor: 'pointer',
    '& > *': {
      pointerEvents: 'none',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 28,
    paddingRight: 28,
  },
}));

interface ExampleProjectCardProps {
  projectId: ProjectId;
  style?: React.CSSProperties;
  className?: string;
  title: string;
  description: string;
  coverMediaId?: number | null;
  datasetId?: number;
  onClick?: (event: React.MouseEvent) => void;
  defects?: Defect[];
}

const ExampleProjectCard: React.FC<ExampleProjectCardProps> = ({
  projectId,
  style,
  className,
  title,
  description,
  datasetId,
  coverMediaId,
  defects = [],
}) => {
  const [isInferenceModalOpen, setIsInferenceModalOpen] = useState(false);
  const styles = useStyles();

  const {
    data: mediaDetailsData,
    isLoading: mediaDetailsDataLoading,
    error: mediaDetailsDataError,
  } = useDatasetMediaDetailsQuery({
    datasetId: datasetId,
    mediaId: coverMediaId,
  });

  const [exampleProjectDetails, exampleProjectDetailsLoading, exampleProjectDetailsError] =
    useExampleProjectDetails(isInferenceModalOpen ? projectId : undefined);

  const handleOnOpen = async () => {
    setIsInferenceModalOpen(true);
  };

  const handleOnClose = () => {
    setIsInferenceModalOpen(false);
  };

  return (
    <Card
      className={cx(styles.container, className)}
      style={style}
      onClick={handleOnOpen}
      data-testid="example-project-card"
    >
      {!exampleProjectDetailsLoading && !exampleProjectDetailsError && (
        <Grid container>
          <Grid item className={styles.image} xs={5} md={5} lg={5}>
            <ApiResponseLoader
              response={mediaDetailsData}
              loading={mediaDetailsDataLoading}
              error={mediaDetailsDataError}
            >
              {mediaDetails => <ExampleMediaContainer media={mediaDetails} defects={defects} />}
            </ApiResponseLoader>
          </Grid>
          <Grid item xs={7} md={7} lg={7} className={styles.content}>
            <Box marginBottom={3}>
              <Typography variant="h3">{title}</Typography>
            </Box>
            <Typography>{description}</Typography>
          </Grid>
        </Grid>
      )}

      {isInferenceModalOpen && projectId && (
        <ApiResponseLoader
          response={exampleProjectDetails}
          loading={exampleProjectDetailsLoading}
          error={exampleProjectDetailsError}
        >
          {selectedProject => (
            <PredictContextProvider
              projectId={projectId}
              datasetId={datasetId}
              labelType={selectedProject.labelType}
              modelId={selectedProject.registeredModel.id}
            >
              <PredictDialog
                onClose={handleOnClose}
                projectId={projectId}
                selectedProject={selectedProject}
              />
            </PredictContextProvider>
          )}
        </ApiResponseLoader>
      )}
    </Card>
  );
};

export default ExampleProjectCard;

import React, { MutableRefObject, useState } from 'react';
import { Typography, Grid, useTheme, Accordion, AccordionSummary } from '@material-ui/core';
import DeleteRounded from '@material-ui/icons/DeleteRounded';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
  MediaInteractiveCanvas,
  MediaViewerSegmentationAnnotation,
  IconButton,
  SegmentationAnnotation,
} from '@clef/client-library';
import { getDefectColor } from '../../../utils';
import { Defect, Dimensions } from '@clef/shared/types';
import { PureCanvasLabelingAnnotation } from '../../Labeling/labelingState';
import DefectColor from '../../../pages/LabelingTask/components/DefectColor';
import useStyles from './styles';

type TransformScalerProps = {
  onMouseOver?: () => void;
  onMouseOut?: () => void;
};

const TransformScaler: React.FC<Dimensions & TransformScalerProps> = ({
  width,
  height,
  children,
  onMouseOut,
  onMouseOver,
}) => {
  const styles = useStyles();
  const [containerNode, setContainerNode] = useState<HTMLDivElement | null>(null);

  const stageScale = containerNode
    ? Math.min(containerNode.clientWidth / width, containerNode.clientHeight / height)
    : 1;
  const stageStyle: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%) scale(${stageScale})`,
    width,
    height,
  };
  return (
    <div
      onMouseOver={() => {
        onMouseOver && onMouseOver();
      }}
      onMouseOut={() => {
        onMouseOut && onMouseOut();
      }}
      ref={node => {
        if (node) {
          setContainerNode(node);
        }
      }}
      className={styles.previewSegContainer}
      data-testid="preview-segmentation-container"
      style={{ paddingTop: `${100}%` }}
    >
      <div style={stageStyle}>{children}</div>
    </div>
  );
};

interface BitMapAnnotationTreeItemProps {
  mediaCanvasRef?: MutableRefObject<MediaInteractiveCanvas | null>;
  annotations?: (SegmentationAnnotation & PureCanvasLabelingAnnotation)[];
  isLabelMode?: boolean;
  isPrediction?: boolean;
  defaultExpanded?: boolean;
  allDefects: Defect[];
}

export const BitMapLabelPreviewList: React.FC<BitMapAnnotationTreeItemProps> = ({
  annotations,
  isLabelMode,
  defaultExpanded,
  allDefects,
}) => {
  const styles = useStyles();
  const theme = useTheme();

  if (!annotations || !annotations.length) {
    return (
      <Typography variant="body2" component="div" className={styles.hintTextNormal}>
        {t('No labels')}
      </Typography>
    );
  }

  return (
    <>
      {annotations.map(ann => {
        const {
          defectId,
          xMin = 0,
          yMin = 0,
          xMax = ann?.data?.width,
          yMax = ann?.data?.height,
        } = ann;
        const annotationData = ann?.data ? ann?.data : ann?.compressedBitMap;

        const foundDefect = allDefects?.find(defect => defect.id === defectId);

        if (!foundDefect) {
          return null;
        }

        return (
          <Accordion
            square
            key={foundDefect.id}
            className={styles.previewContainer}
            role="listitem"
            title={`${foundDefect.name} preview`}
            defaultExpanded={defaultExpanded}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className={!defaultExpanded ? styles.showExpandIconOnHover : undefined}
            >
              <Grid container alignItems="center" className={styles.previewContainerTop}>
                <DefectColor color={getDefectColor(foundDefect)} />
                <Typography variant="body2" component="div">
                  {foundDefect.name}
                </Typography>
                <div className={styles.flexGrow} />
                {isLabelMode && (
                  <IconButton
                    id="task-delete-defect"
                    size="small"
                    className={styles.deleteAction}
                    color="secondary"
                    aria-label="delete label"
                  >
                    <DeleteRounded fontSize="small" htmlColor={theme.palette.secondary.main} />
                  </IconButton>
                )}
              </Grid>
            </AccordionSummary>
            <TransformScaler width={ann.data.width} height={ann.data.height}>
              <MediaViewerSegmentationAnnotation
                color={getDefectColor(foundDefect)}
                xMin={xMin}
                xMax={xMax}
                yMin={yMin}
                yMax={yMax}
                compressedBitMap={annotationData}
                compress
              />
            </TransformScaler>
          </Accordion>
        );
      })}
    </>
  );
};

import React from 'react';
import { TextField } from '@material-ui/core';
import { useDeviceMonitorState } from '../deviceMonitorState';

export interface InspectionStationIdFilterProps {}

const InspectionStationIdFilter: React.FC<InspectionStationIdFilterProps> = () => {
  const {
    state: { filterInspectionStationId },
    dispatch,
  } = useDeviceMonitorState();
  return (
    <TextField
      value={filterInspectionStationId}
      onChange={event => {
        const value = event.target.value.trim();
        dispatch(draft => {
          draft.filterInspectionStationId = value;
        });
      }}
    />
  );
};

export default InspectionStationIdFilter;

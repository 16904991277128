import React from 'react';
import { Annotation, BoundingBoxAnnotationData, Label, LabelingType } from '@clef/shared/types';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { Typography, Chip, Grid } from '@material-ui/core';
import { Button } from '@clef/client-library';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowRight from '@material-ui/icons/ArrowRight';
import { useDefectSelectorWithArchived } from '../../../store/defectState/actions';
import { useLabelingReviewStyles } from '../labelingReviewStyles';
import { TreeItem, TreeView } from '@material-ui/lab';
import { getDefectColor } from '../../../utils';
import cx from 'classnames';
import { truncate } from 'lodash';

export interface LabelInstanceProps {
  label: Label;
  isSelected: boolean;
  onSelectLabel?: () => void;
  isVisible: boolean;
  enableSelect: boolean;
  onToggleVisibility?: (
    visibility: boolean,
    visibleDefect?: number,
    visibleAnnotation?: string,
  ) => void;
  labelingType?: LabelingType;
  showSelectBox?: boolean;
  onSelect?: (id: string) => void;
  userName?: string;
}

const LabelInstance: React.FC<LabelInstanceProps> = ({
  label,
  enableSelect,
  isSelected,
  onSelectLabel = () => {},
  isVisible,
  onToggleVisibility = () => {},
  labelingType,
  showSelectBox = true,
  onSelect,
  userName,
}) => {
  const allUsers = useTypedSelector(state => state.user.users);
  const styles = useLabelingReviewStyles();
  const annotations = (label.annotations as Annotation[]) || [];
  const allDefects = useDefectSelectorWithArchived();
  const labelerName = allUsers?.find(_ => _.id === label.labelerName)?.name || '';
  const labelerDisplayName = truncate(labelerName, { length: 12 });
  if (!allDefects) {
    return null;
  }
  return (
    <div
      className={cx(styles.labelInstance, !isVisible && 'labelHidden')}
      data-testid="label-instance"
      aria-hidden={!isVisible}
      onMouseEnter={() => onToggleVisibility(true)}
      onMouseLeave={() => onToggleVisibility(false)}
    >
      <TreeView
        defaultCollapseIcon={<ArrowDropDown />}
        defaultExpandIcon={<ArrowRight />}
        defaultExpanded={[labelerName, ...allDefects.map(defect => 'defect-' + defect.id)]}
        disableSelection
      >
        {/* tree level 1 - labeler */}
        <TreeItem
          nodeId={labelerName}
          label={
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              className={styles.labelsTreeItemSpacing}
            >
              <Typography variant="body2" title={labelerName}>
                <strong>{`${userName || labelerDisplayName} (${annotations.length})`}</strong>
              </Typography>
              <div className={styles.flexGrow} />
              {isSelected && showSelectBox && (
                <Chip
                  size="small"
                  variant="outlined"
                  color="primary"
                  component="span"
                  label={t('Selected')}
                  clickable={false}
                />
              )}
              {!isSelected && isVisible && enableSelect && showSelectBox && (
                <Button
                  id="select-label-button"
                  color="primary"
                  size="small"
                  className={styles.labelSelectBtn}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSelectLabel();
                  }}
                >
                  {t('Select')}
                </Button>
              )}
            </Grid>
          }
        >
          {allDefects?.map(defect => {
            const annotationsForThisDefect = annotations.filter(ann => ann.defectId === defect.id);
            if (annotationsForThisDefect.length === 0) {
              return null;
            }
            return (
              // tree level 2 - defect
              <TreeItem
                key={defect.id}
                nodeId={'defect-' + defect.id}
                data-defect-id={defect.id}
                data-testid="label-preview-defect"
                onMouseEnter={() => onToggleVisibility(true, defect.id)}
                onMouseLeave={() => onToggleVisibility(true, undefined)}
                label={
                  <Grid container alignItems="center" className={styles.labelsTreeItemSpacing}>
                    <div
                      className={styles.defectIndicator}
                      style={{ backgroundColor: getDefectColor(defect) }}
                    />
                    <Typography variant="body2">
                      {labelingType === LabelingType.DefectBoundingBox
                        ? `${defect.name} (${annotationsForThisDefect.length})`
                        : defect.name}
                    </Typography>
                  </Grid>
                }
              >
                {labelingType === LabelingType.DefectBoundingBox &&
                  annotationsForThisDefect.map((annotation, index) => {
                    const boxAnnotation = annotation.rangeBox as BoundingBoxAnnotationData;
                    const { id } = annotation;
                    return (
                      // tree level 3 - annotation
                      <TreeItem
                        key={id}
                        nodeId={'annotation-' + id}
                        data-annotation-id={id}
                        data-testid="label-preview-annotation"
                        onMouseEnter={() => onToggleVisibility(true, defect.id, String(id))}
                        onMouseLeave={() => onToggleVisibility(true, defect.id, undefined)}
                        onClick={() => {
                          if (onSelect) {
                            onSelect(String(id));
                          }
                        }}
                        label={
                          <Typography
                            variant="body2"
                            className={cx(styles.annotationDetail, styles.labelsTreeItemSpacing)}
                          >
                            {t('{{index}} - x {{x}}, y {{y}}, {{width}} x {{height}}', {
                              index: index + 1,
                              x: Math.round(boxAnnotation.xmin),
                              y: Math.round(boxAnnotation.ymin),
                              width: Math.round(boxAnnotation.xmax - boxAnnotation.xmin),
                              height: Math.round(boxAnnotation.ymax - boxAnnotation.ymin),
                            })}
                          </Typography>
                        }
                      />
                    );
                  })}
              </TreeItem>
            );
          })}
        </TreeItem>
      </TreeView>
    </div>
  );
};

export default LabelInstance;

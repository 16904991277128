import React, { forwardRef, useCallback, useEffect } from 'react';
import cx from 'classnames';
import { Box, Card, Tooltip, Typography, withStyles } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import { greyScale, useLocalStorage } from '@clef/client-library';
import { IconQuickLabeling, IconBrush, IconPolygon, IconPolyline, IconProps } from './ToolIcon';
import { Button, useKeyPress } from '@clef/client-library';
import { HintEvents, useHintSnackbar } from './HintSnackbar';
import {
  ToolMode,
  TutorialStep,
  useLabelingState,
  useUnsavedLabelCheckDecorator,
} from './labelingState';
import { useLabelingStyles } from './labelingStyles';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import SmartLabelingGif from '@/images/sam/smart_labeling.gif';
import Close from '@material-ui/icons/Close';

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#4D5761',
    fontSize: 14,
    fontWeight: 400,
    maxWidth: 600,
    padding: theme.spacing(3),
    pointerEvents: 'all',
    marginTop: theme.spacing(5),
    boxShadow: '0px 1px 2px rgba(48, 55, 79, 0.16), 0px 2px 4px rgba(48, 55, 79, 0.08)',
    '& .MuiTooltip-arrow': {
      color: 'white',
    },
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

export interface ModeButtonProps {
  mode: ToolMode;
  ModeIcon: React.FC<IconProps>;
  modeName: string;
  hotKey: string;
  isLabelingTask?: boolean;
}
export interface ToolModeCardProps {
  warning?: boolean;
  isLabelingTask?: boolean;
}

const ModeButton = forwardRef<HTMLButtonElement, ModeButtonProps>(
  ({ mode, ModeIcon, modeName, hotKey, isLabelingTask }, fRef) => {
    const styles = useLabelingStyles();
    const {
      state: { toolMode },
      dispatch,
    } = useLabelingState();

    const enabled = mode === toolMode;

    const toggleMode = useCallback(() => {
      const newToolMode = enabled ? undefined : mode;

      dispatch(draft => {
        draft.toolMode = newToolMode;
        draft.toolOptions.erasing = false;
      });
    }, [dispatch, enabled, mode]);
    const onHotKeyPress = useUnsavedLabelCheckDecorator(toggleMode);

    // keyboard shortcut
    useKeyPress(hotKey, !isLabelingTask && hotKey === 'p' ? undefined : onHotKeyPress, {
      id: `task-select-mode-${modeName}`,
    });

    return (
      <Tooltip
        arrow
        placement="top"
        title={
          <>
            {modeName} <span className={styles.codeBlock}>{hotKey}</span>
          </>
        }
      >
        <Button
          ref={fRef}
          id={`task-select-mode-${modeName.replace(' ', '-')}`}
          className={styles.labelModeButton}
          color="primary"
          aria-label={`tool-mode-button-${modeName}`}
          variant={enabled ? 'contained' : undefined}
          onClick={onHotKeyPress}
        >
          <ModeIcon color={enabled ? 'white' : greyScale[500]!} />
        </Button>
      </Tooltip>
    );
  },
);

const SegmentationToolModeCard: React.FC<ToolModeCardProps> = ({ warning, isLabelingTask }) => {
  const styles = useLabelingStyles();
  const {
    state: { toolMode },
    dispatch,
  } = useLabelingState();
  const triggerHint = useHintSnackbar();
  useEffect(() => {
    if (toolMode === ToolMode.Polygon) {
      triggerHint(HintEvents.PolygonInstructions);
    } else if (toolMode === ToolMode.Polyline) {
      triggerHint(HintEvents.PolylineInstructions);
    } else {
      triggerHint();
    }
    return () => {
      triggerHint();
    };
  }, [toolMode, triggerHint]);
  const quickLabelingExpEnabled = useFeatureGateEnabled(ClientFeatures.QuickLabelingExp);
  const [skipFirstTimeQuickLabelingTooltip, setSkipFirstTimeQuickLabelingTooltip] = useLocalStorage(
    'skipFirstTimeQuickLabelingTooltip',
  );

  return (
    <Card className={cx(styles.toolCard, styles.marginRight5, warning && styles.warningBorder)}>
      {quickLabelingExpEnabled && (
        <StyledTooltip
          arrow
          title={
            <Box width={350} paddingX={2} paddingY={1}>
              <IconButton
                id="sam-smart-labeling-tooltip-close-button"
                size="small"
                onClick={() => setSkipFirstTimeQuickLabelingTooltip(true)}
                className={styles.smartLabelingGuideCloseButton}
              >
                <Close />
              </IconButton>

              <Typography variant="h3">{t('Label faster with Smart Labeling')}</Typography>

              <Typography className={styles.smartLabelingGuideDescription}>
                {t(
                  'Just click the object you want to detect, then press Enter/Return on your keyboard to save the label.',
                )}
              </Typography>

              <img src={SmartLabelingGif} className={styles.smartLabelingGuideGif} />
            </Box>
          }
          open={!skipFirstTimeQuickLabelingTooltip}
          onClose={() => setSkipFirstTimeQuickLabelingTooltip(true)}
          placement="bottom-start"
        >
          <ModeButton
            mode={ToolMode.Quick}
            ModeIcon={IconQuickLabeling}
            modeName="Smart Labeling"
            hotKey="q"
          />
        </StyledTooltip>
      )}
      <ModeButton
        mode={ToolMode.Polygon}
        ModeIcon={IconPolygon}
        modeName="Polygon"
        hotKey="w"
        ref={buttonRef => {
          if (buttonRef) {
            dispatch(draft => {
              // having type issues putting HTMLElement into state, so cast to any
              draft.tutorialAnchorElementsMap[TutorialStep.Polygon] = buttonRef as any;
            });
          }
        }}
        isLabelingTask={isLabelingTask}
      />
      <ModeButton mode={ToolMode.Brush} ModeIcon={IconBrush} modeName="Brush" hotKey="b" />
      <ModeButton mode={ToolMode.Polyline} ModeIcon={IconPolyline} modeName="Polyline" hotKey="l" />
    </Card>
  );
};

export default SegmentationToolModeCard;

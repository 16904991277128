import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Control, Controller, FieldError, ValidationOptions } from 'react-hook-form';
import { Typography, Grid, TextField, Link, Box } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import cx from 'classnames';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import DeleteIcon from '@material-ui/icons/Delete';

import { useStyles } from './styles';
import { PasswordGuideItem } from './Common';
import { CLEF_PATH } from '../../constants/path';
import { passwordRegex } from '../../constants/regex';

interface BaseTextFieldProps {
  error?: FieldError | undefined;
  control?: Control<any>;
  label?: string;
  placeholder?: string;
  rules?: ValidationOptions;
  name: string;
  type?: string;
  className?: string;
  standalongLabel?: React.ReactNode;
  enableAutoComplete?: boolean;
  enableAutoFocus?: boolean;
  deletable?: boolean;
  onDelete?: () => void;
  classes?: {
    root?: string;
    textField?: string;
  };
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const BaseTextField: React.FC<BaseTextFieldProps> = ({
  error,
  control,
  label,
  placeholder,
  rules,
  name,
  type,
  className,
  standalongLabel,
  enableAutoComplete = true,
  enableAutoFocus = false,
  deletable = false,
  onDelete,
  classes,
  onChange,
}) => {
  const styles = useStyles();

  return (
    <Box display="flex" flexDirection="column" className={classes?.textField}>
      {!!standalongLabel &&
        (standalongLabel === true ? (
          <Typography className={styles.standalongLabel}>{label}</Typography>
        ) : (
          standalongLabel
        ))}

      <Box display="flex" alignItems="center" className={classes?.root}>
        <Controller
          as={
            <TextField
              variant="outlined"
              {...(!standalongLabel && {
                label,
              })}
              placeholder={placeholder}
              size="small"
              error={!!error}
              helperText={error ? error.message : null}
              inputProps={{
                // Enable testing library to find this element by role and name
                role: 'textbox',
                'aria-label': name,
                // Reference: https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password
                autoComplete: enableAutoComplete
                  ? 'on'
                  : name === 'password'
                  ? 'new-password'
                  : 'off',
                maxLength: 100,
                onChange,
              }}
              type={type}
              onFocus={event => {
                event.target.select();
              }}
              autoFocus={enableAutoFocus}
            />
          }
          rules={rules}
          defaultValue={''}
          name={name}
          control={control}
          className={cx(styles.textFieldController, className)}
        />

        {deletable && (
          <IconButton
            id="delete-user-to-invite"
            onClick={onDelete}
            className={styles.deleteIconButton}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export const EmailTextField: React.FC<
  {
    control: Control<{ email: string }>;
  } & Pick<BaseTextFieldProps, 'error' | 'className' | 'standalongLabel'>
> = ({ error, control, className, standalongLabel = true }) => {
  const styles = useStyles();

  return (
    <BaseTextField
      label={t('Email')}
      placeholder={t('yourname@email.com')}
      rules={{
        required: t('This is required.'),
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: 'Invalid email address.',
        },
      }}
      name="email"
      error={error}
      control={control}
      classes={{ root: cx(styles.normalInputBox, className) }}
      standalongLabel={standalongLabel}
    />
  );
};

export const VerificationCodeTextField: React.FC<
  {
    control: Control<{ password: string }>;
  } & Pick<BaseTextFieldProps, 'error' | 'className'>
> = ({ error, control, className }) => {
  const styles = useStyles();

  return (
    <BaseTextField
      label={t('Verification Code')}
      rules={{
        required: t('This is required.'),
      }}
      name="verificationCode"
      error={error}
      control={control}
      classes={{ root: cx(styles.normalInputBox, className) }}
      standalongLabel
    />
  );
};

const passwordValidators: {
  hint: string;
  validate: (password: string) => boolean;
}[] = [
  {
    hint: t('Minimum 8 characters'),
    validate: (password: string) => password.length >= 8,
  },
  {
    hint: t('At least one lower case'),
    validate: password => /[a-z]/.test(password),
  },
  {
    hint: t('At least one special character'),
    validate: password => /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password),
  },
  {
    hint: t('At least one upper case'),
    validate: password => /[A-Z]/.test(password),
  },
  {
    hint: t('At least one number'),
    validate: password => /\d/.test(password),
  },
];

export const PasswordTextField: React.FC<
  {
    control: Control<{ password: string }>;
    showHint?: boolean;
    showForgetYourPassword?: boolean;
    enablePatternCheck?: boolean;
    watch?: (field: string) => string;
  } & Pick<BaseTextFieldProps, 'error' | 'className' | 'enableAutoComplete'>
> = ({
  error,
  control,
  className,
  showHint = false,
  showForgetYourPassword = false,
  enablePatternCheck = false,
  enableAutoComplete = true,
  watch,
}) => {
  const styles = useStyles();
  const history = useHistory();
  const [hidePassword, setHidePassword] = useState(true);
  const password = watch?.('password') ?? '';

  return (
    <Grid container className={styles.passwordInputContainer}>
      {!enableAutoComplete && (
        // When hovering on the password input box, Chrome will always provide both username and password suggestions.
        // Username suggestion will always go to the previous input element in the order even if auto complete is off.
        // For this case, we provide an empty input element to counteract this Chrome behavior
        <input className={styles.emptyInputPlaceholder} />
      )}

      <BaseTextField
        label={t('Password')}
        rules={{
          required: t('This is required.'),
          ...(enablePatternCheck && {
            pattern: {
              value: new RegExp(passwordRegex.exp),
              message: t('Invalid password. Please refer to the password requirements.'),
            },
          }),
        }}
        name="password"
        type={hidePassword ? 'password' : 'text'}
        error={error}
        control={control}
        classes={{ root: cx(styles.normalInputBox, className) }}
        standalongLabel={
          <Grid container alignItems="center" className={styles.standalongLabel}>
            <Typography className={styles.marginRight1}>{t('Password')}</Typography>

            {showForgetYourPassword && (
              <Link
                className={styles.forgetYourPasswordText}
                onClick={() => history.push(CLEF_PATH.login.forgetPassword)}
                role="link"
              >
                {t('(Forgot password?)')}
              </Link>
            )}

            <div className={styles.grow} />

            <div
              className={styles.visibilityIconContainer}
              onClick={() => setHidePassword(!hidePassword)}
            >
              {hidePassword ? (
                <VisibilityIcon className={styles.visibilityIcon} />
              ) : (
                <VisibilityOffIcon className={styles.visibilityIcon} />
              )}
            </div>
          </Grid>
        }
        enableAutoComplete={enableAutoComplete}
      />

      {showHint && (
        <Grid item className={styles.passwordGuideContainer}>
          {passwordValidators.map(({ hint, validate }, index) => (
            <PasswordGuideItem key={index} active={validate(password)} text={hint} />
          ))}
        </Grid>
      )}
    </Grid>
  );
};

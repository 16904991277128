import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Typography } from '@clef/client-library';

const useStyles = makeStyles(theme => ({
  detailItem: {
    minWidth: 60,
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  itemValue: {
    lineHeight: '20px',
    fontSize: 14,
    fontWeight: 700,
  },
  itemName: {
    color: theme.palette.grey[500],
  },
}));

export type SnapshotDetailItemProps = {
  item: { name: string; value: number };
  index: number;
};

export const SnapshotDetailItem: React.FC<SnapshotDetailItemProps> = props => {
  const styles = useStyles();
  const { item, index } = props;
  return (
    <Box key={index} className={styles.detailItem}>
      <Box className={styles.itemValue}>{item.value}</Box>
      <Typography variant="body_small_medium" className={styles.itemName}>
        {item.name}
      </Typography>
    </Box>
  );
};

export default SnapshotDetailItem;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSetAtom } from 'jotai';

import { customTrainingPath } from '@/constants/model_train';
import { resetCustomTrainingAtomsAtom } from '@/uiStates/customTraining/pageUIStates';

import SplitSetup from './SplitSetup';
import ModelsConfiguration from './ModelsConfiguration';

const CustomTraining: React.FC = () => {
  const history = useHistory();
  const [previousUrl, setPreviousUrl] = useState(history.location.pathname);

  const resetCustomTrainingAtoms = useSetAtom(resetCustomTrainingAtomsAtom);

  // to prevent unexpected way of exiting the page, which would cause the beforeUnload method to fail,
  // set a listener here to reset state
  useEffect(() => {
    const unlisten = history.listen(location => {
      if (
        previousUrl.includes(customTrainingPath) &&
        !location.pathname.includes(customTrainingPath)
      ) {
        resetCustomTrainingAtoms(true);
      }
      setPreviousUrl(history.location.pathname);
    });

    return () => {
      unlisten();
    };
  }, [history, previousUrl, resetCustomTrainingAtoms]);

  return (
    <>
      <SplitSetup />
      <ModelsConfiguration />
    </>
  );
};

export default CustomTraining;

import React, { useState } from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import phoneCamera from '@/images/deploy/phone-camera.png';
import { Button } from '@clef/client-library';
import { useTypographyStyles } from '@clef/client-library/src/themes/typography';
import { useSnackbar } from 'notistack';
import { refreshPublicEndpointByIdApi } from '@/hooks/api/usePublicEndpointApi';
import public_endpoint_api from '@/api/public_endpoint_api';

const useStyles = makeStyles(() => ({
  icon: {
    width: 120,
    height: 120,
  },
}));

export type MobileInferenceCardDisabledProps = {
  endpointId?: string;
  onQrCodeGenerated?: () => void;
};

const MobileInferenceCardDisabled: React.FC<MobileInferenceCardDisabledProps> = props => {
  const { endpointId, onQrCodeGenerated } = props;
  const styles = useStyles();
  const typographyStyles = useTypographyStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  if (!endpointId) {
    return null;
  }

  return (
    <>
      <img src={phoneCamera} alt="phone-camera" className={styles.icon} />
      <Box className={typographyStyles.h3_semibold} marginTop={3}>
        {t('Mobile Inference')}
      </Box>
      <Box className={typographyStyles.body_regular} marginY={7} textAlign="center">
        {t(
          'Generate a QR code and share it with others so they can run inference from their phones!',
        )}
      </Box>
      <Button
        id="generate-public-qr-code"
        color="primary"
        variant="contained"
        disabled={loading}
        startIcon={loading ? <CircularProgress size={20} /> : undefined}
        onClick={async () => {
          if (loading) {
            return;
          }
          setLoading(true);
          try {
            await public_endpoint_api.changePublicEndpointStateById(endpointId, /* enable */ true);
            await refreshPublicEndpointByIdApi({ keys: 'refresh-all' });
            onQrCodeGenerated?.();
          } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
          }
          setLoading(false);
        }}
      >
        {t('Get QR Code')}
      </Button>
    </>
  );
};

export default MobileInferenceCardDisabled;

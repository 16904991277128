import { Typography } from '@clef/client-library';
import { PendingUser, User } from '@clef/shared/types';
import { isUser } from '@clef/shared/utils';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

import { Column as UserColumn } from '@/components/Layout/components/Column';
import { UserAvatar } from '@/components/UserAvatar/UserAvatarNew';

export type DataItem = User | PendingUser;

const useUserProfileStyles = makeStyles(theme => {
  return {
    userInfoGrid: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    avatar: {
      minWidth: 30,
    },
    userInfoColumn: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(5),
    },
    userNameText: {
      fontWeight: 'bold',
    },
    userEmailText: {
      color: theme.palette.greyModern[500],
    },
  };
});

export const getUserName = (item: DataItem) => {
  return isUser(item) ? `${item.name} ${item.lastName}` : t('Pending user');
};

const UserProfile = (props: { item: DataItem }) => {
  const { item } = props;
  const userName = getUserName(item);
  const styles = useUserProfileStyles();
  const renderUserAvatar = (item: DataItem) => {
    const userName = getUserName(item);
    return (
      <UserAvatar
        name={userName}
        disableTooltip
        classes={{
          avatar: styles.avatar,
        }}
      />
    );
  };
  return (
    <Grid item className={styles.userInfoGrid}>
      {renderUserAvatar(item)}
      <UserColumn className={styles.userInfoColumn}>
        <Typography variant="body2" className={styles.userNameText}>
          {userName}
        </Typography>
        <Typography variant="body2" className={styles.userEmailText}>
          {item.email}
        </Typography>
      </UserColumn>
    </Grid>
  );
};

export default UserProfile;

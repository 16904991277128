import React from 'react';
import Paper from '@material-ui/core/Paper';

import Popover from '@material-ui/core/Popover';
import { Button } from '@clef/client-library';
import TuneIcon from '@material-ui/icons/Tune';
import { makeStyles } from '@material-ui/core/styles';
import { greyScale } from '@clef/client-library';
import SettingsPanel from './SettingsPanel';

export interface ImageEnhancerProps {
  brightness: number;
  contrast: number;
  histogramEnabled: boolean;
  autoContrastEnabled: boolean;
  setBrightness(value: number): void;
  setContrast(value: number): void;
  toggleHistogram?(): void;
  toggleAutoContrast?(): void;
  resetEnhancers(): void;
  hideApplyToAll?: boolean;
  hasPaperContainer?: boolean;
  iconColor?: string;
  customerIcon?: React.ReactNode;
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  actionBar: {
    padding: spacing(2),
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  toggleIcon: {
    boxShadow: 'none',
    padding: 0,
    width: 40,
    minWidth: 40,
    height: 40,
    minHeight: 40,
    borderRadius: spacing(1),
  },
  configMenu: {
    padding: spacing(5),
    width: 318,
    color: palette.grey[600],
  },
}));

const ImageEnhancer: React.FunctionComponent<ImageEnhancerProps> = ({
  setBrightness,
  setContrast,
  toggleHistogram,
  toggleAutoContrast,
  brightness,
  contrast,
  histogramEnabled,
  autoContrastEnabled,
  resetEnhancers,
  hideApplyToAll = false,
  hasPaperContainer = false,
  iconColor = greyScale[500],
  customerIcon,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  // @ts-ignore
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <div data-testid={'config-icon'} onClick={handleClick}>
        {customerIcon ||
          (!hasPaperContainer ? (
            <Button
              id="enhancer-button"
              className={classes.toggleIcon}
              color={open ? 'primary' : 'default'}
              variant={open ? 'contained' : 'text'}
            >
              <TuneIcon fontSize={'small'} htmlColor={open ? greyScale[100] : greyScale[500]} />
            </Button>
          ) : (
            <Paper className={classes.actionBar}>
              <Button
                id="enhancer-button"
                className={classes.toggleIcon}
                color={open ? 'primary' : 'default'}
                variant={open ? 'contained' : 'text'}
              >
                <TuneIcon fontSize={'small'} htmlColor={open ? greyScale[100] : iconColor} />
              </Button>
            </Paper>
          ))}
      </div>
      <Popover
        aria-labelledby="image-enhancer-config"
        id={id}
        classes={{ paper: classes.configMenu }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableEnforceFocus
      >
        <SettingsPanel
          brightness={brightness}
          contrast={contrast}
          histogramEnabled={histogramEnabled}
          autoContrastEnabled={autoContrastEnabled}
          toggleHistogram={toggleHistogram}
          toggleAutoContrast={toggleAutoContrast}
          handleClose={handleClose}
          setBrightness={setBrightness}
          setContrast={setContrast}
          resetEnhancers={resetEnhancers}
          hideApplyToAll={hideApplyToAll}
        />
      </Popover>
    </>
  );
};

export default ImageEnhancer;

import { createContext, useContext } from 'react';
import { Draft } from 'immer';
import { FormattedColumnFilterMap, FormattedFieldFilterMap } from '@clef/shared/types/store';
import {
  FilterOptionNameToColumnTableFilterName,
  FilterOptionName,
  FilterOperations,
  SortFieldInfo,
  PaginationOptions,
  SelectMediaOption,
  AnnotationSortType,
  MetadataTypeExtra,
  DefectId,
  ImageLevelClassificationRuleCollection,
} from '@clef/shared/types';
import { CacheStorage, CacheStorageKey } from '@clef/client-library';
import { omit } from 'lodash';

// We want to keep these as shot as possible because they are stringified to the url
export type AppliedFilterType = {
  // value
  v: string | string[] | number | number[];
  // filterType
  t: 'column' | 'field';
  // operation
  o: FilterOperations;
  // fieldId
  fi?: number;
};

export type AppliedFilterMapping = {
  [filterName: string]: AppliedFilterType;
};

export type DataBrowserState = Readonly<{
  appliedFilters: AppliedFilterMapping;
  sortField: SortFieldInfo;
  paginationLimit: PaginationOptions['limit'];
  pageIndex: PaginationOptions['offset'];
  // (Instant learning project only) Store the page index for the unlabeled media
  secondaryPageIndex: PaginationOptions['offset'];
  selectingMediaMode: 'select' | 'unselect';
  selectedOrUnselectedMedia: number[];
  /**
   * @deprecated use showGroundTruth and showPredictions instead
   */
  labelDisplay?: 'ground_truth' | 'predictions';
  showGroundTruth: boolean;
  showPredictions: boolean;
  showPredictionLabeled: boolean;
  showPredictionUnlabeled: boolean;
  showHeatmap: boolean;
  // from api
  totalMediaCount: number;
  // right side bar, for data insights and model performance
  rightSidebar?: 'data_insights' | 'model_performance';
  viewMode: 'image' | 'instance';
  itemPerRowOffset: number;
  isFirstRunLiveExperienceModalOpen: boolean;
  // The following states are instant learning only
  TEMP_defectIdToSegmentationAreaThreshold: Record<DefectId, number>; // TODO: This needs to be saved in DB per project
  TEMP_showImageLevelClassification: boolean; // Whether to show classification postprocessing output on MediaContainer chip
  TEMP_imageLevelClassificationRuleCollection: ImageLevelClassificationRuleCollection; // TODO: This needs to be saved in DB per project
  TEMP_postProcessingLoading: boolean; // Whether it is loading post-processing config from API
}>;

export const stateSessionCacheStorage = new CacheStorage<{
  [projectId: number]: Pick<DataBrowserState, 'appliedFilters'>;
}>({
  key: CacheStorageKey.DataBrowserSession,
});

export const statePersistentCacheStorage = new CacheStorage<{
  [projectId: number]: Pick<DataBrowserState, 'paginationLimit' & 'sortField'>;
}>({
  key: CacheStorageKey.DataBrowserPersistent,
  persist: true,
});

export const ConfidenceScoreAscSortField: SortFieldInfo = {
  label: t('Confidence score'),
  orderLabel: t('(Low to High)'),
  sortType: AnnotationSortType.confidence,
  sortValue: AnnotationSortType.confidence,
  sortOrder: 'asc',
};

export const ConfidenceScoreDescSortField: SortFieldInfo = {
  label: t('Confidence score'),
  orderLabel: t('(High to Low)'),
  sortType: AnnotationSortType.confidence,
  sortValue: AnnotationSortType.confidence,
  sortOrder: 'desc',
};

export const UploadTimeDescSortField: SortFieldInfo = {
  label: 'Upload Time',
  sortType: 'media',
  sortValue: 'uploadTime',
  sortOrder: 'desc',
  orderLabel: '(Newest to Oldest)',
};

export const InstanceLabelTimeDescSortField: SortFieldInfo = {
  label: 'Label Time',
  sortType: 'labelTime',
  sortValue: '',
  sortOrder: 'desc',
  orderLabel: '(Newest to Oldest)',
};

export const InstanceLabelTimeAscSortField: SortFieldInfo = {
  label: 'Label Time',
  sortType: 'labelTime',
  sortValue: '',
  sortOrder: 'asc',
  orderLabel: '(Oldest to Newest)',
};

export const defaultState: DataBrowserState = {
  appliedFilters: {},
  sortField: UploadTimeDescSortField,
  paginationLimit: 50,
  pageIndex: 0,
  secondaryPageIndex: 0,
  selectingMediaMode: 'select',
  selectedOrUnselectedMedia: [],
  labelDisplay: 'ground_truth',
  showGroundTruth: true,
  showPredictions: false,
  showPredictionLabeled: true,
  showPredictionUnlabeled: true,
  showHeatmap: false,
  totalMediaCount: 0,
  viewMode: 'image',
  itemPerRowOffset: 0,
  isFirstRunLiveExperienceModalOpen: false,
  TEMP_defectIdToSegmentationAreaThreshold: {},
  TEMP_showImageLevelClassification: false,
  TEMP_imageLevelClassificationRuleCollection: {
    rules: [],
    defaultClassification: 'ok',
  },
  TEMP_postProcessingLoading: true,
};

export const getStateFromStorage = (projectId?: number) => ({
  ...defaultState,
  ...(projectId ? stateSessionCacheStorage.get()?.[projectId] ?? {} : {}),
  ...omit(
    projectId ? statePersistentCacheStorage.get()?.[projectId] ?? {} : {},
    'TEMP_defectIdToSegmentationAreaThreshold',
    'TEMP_imageLevelClassificationRuleCollection',
  ),
});

export const saveStateToStorage = (projectId: number, state: Draft<DataBrowserState>) => {
  const {
    appliedFilters,
    sortField,
    paginationLimit,
    labelDisplay,
    showGroundTruth,
    showPredictions,
    showHeatmap,
    viewMode,
    TEMP_showImageLevelClassification,
  } = state;
  stateSessionCacheStorage.set({
    ...stateSessionCacheStorage.get(),
    [projectId]: { appliedFilters },
  });
  statePersistentCacheStorage.set({
    ...statePersistentCacheStorage.get(),
    [projectId]: {
      paginationLimit,
      sortField,
      labelDisplay,
      showGroundTruth,
      showPredictions,
      showHeatmap,
      viewMode,
      TEMP_showImageLevelClassification,
    },
  });
};

/**
 * Selectors
 */
export const isMediaSelected = (state: Draft<DataBrowserState>, mediaId: number): boolean => {
  const { selectingMediaMode, selectedOrUnselectedMedia } = state;
  return selectingMediaMode === 'select'
    ? selectedOrUnselectedMedia.indexOf(mediaId) >= 0
    : selectedOrUnselectedMedia.indexOf(mediaId) < 0;
};

export const appliedFilterMappingToFormattedFilterMapping = (
  appliedFilters: AppliedFilterMapping,
): [FormattedColumnFilterMap, FormattedFieldFilterMap] => {
  return Object.entries(appliedFilters).reduce(
    (acc, appliedFilter) => {
      const [filterName, filterProps] = appliedFilter;
      const isValue = Array.isArray(filterProps.v) ? filterProps.v.length : filterProps.v;
      if (isValue) {
        if (filterProps.t === 'column') {
          // Column filter
          if (isValue) {
            const { table, col } =
              FilterOptionNameToColumnTableFilterName[filterName as FilterOptionName];
            acc[0] = {
              ...acc[0],
              [table]: {
                ...(acc[0]?.[table] ?? {}),
                [col]: { [filterProps.o]: filterProps.v },
              },
            };
          }
        } else {
          // Field filter
          acc[1] = {
            ...acc[1],
            [filterProps.fi!]: {
              [filterProps.o]: filterProps.v,
            },
          };
        }
      }
      return acc;
    },
    [{} as FormattedColumnFilterMap, {} as FormattedFieldFilterMap],
  );
};
export const getColumnFilterMapWithModelId = (
  columnFilterMap: FormattedColumnFilterMap,
  modelId?: string,
  instanceMode: boolean = false,
) => {
  return modelId && (instanceMode || columnFilterMap.hasOwnProperty(MetadataTypeExtra.Prediction))
    ? {
        ...columnFilterMap,
        [MetadataTypeExtra.Prediction]: {
          ...columnFilterMap[MetadataTypeExtra.Prediction],
          modelId: { '=': modelId },
        },
      }
    : columnFilterMap;
};

export const formatStateToSelectMediaOption = (
  state: Draft<DataBrowserState>,
  modelId?: string,
): SelectMediaOption => {
  const { selectingMediaMode, selectedOrUnselectedMedia, appliedFilters } = state;
  const [col, metadataFilterMap] = appliedFilterMappingToFormattedFilterMapping(appliedFilters);
  const columnFilterMap = getColumnFilterMapWithModelId(col, modelId);
  const unselectMode = selectingMediaMode === 'unselect';
  return {
    selectedMedia: unselectMode ? [] : selectedOrUnselectedMedia,
    unselectedMedia: unselectMode ? selectedOrUnselectedMedia : [],
    isUnselectMode: unselectMode,
    columnFilterMap,
    fieldFilterMap: metadataFilterMap,
  };
};

export const getSelectedMediaCount = (state: Draft<DataBrowserState>): number => {
  const { selectingMediaMode, selectedOrUnselectedMedia, totalMediaCount } = state;
  return selectingMediaMode === 'select'
    ? selectedOrUnselectedMedia.length
    : totalMediaCount - selectedOrUnselectedMedia.length;
};

/**
 * Actions
 */
export const DraftActionSelectingMedia =
  (mediaIds: number[], selected: boolean) => (draft: Draft<DataBrowserState>) => {
    const { selectingMediaMode, selectedOrUnselectedMedia } = draft;
    if (
      (selectingMediaMode === 'select' && selected) ||
      (selectingMediaMode === 'unselect' && !selected)
    ) {
      // selected + isSelectingMedia === 'select' -> Adding media to selectedMedia
      // !selected + isSelectingMedia === 'unselect' -> Adding media to unselectedMedia
      // use a set to remove duplicate from array
      draft.selectedOrUnselectedMedia = [...new Set([...selectedOrUnselectedMedia, ...mediaIds])];
    } else {
      // !selected + isSelectingMedia === 'select' -> Removing media from selectedMedia
      // selected + isSelectingMedia === 'unselect' -> Removing media from unselectedMedia
      draft.selectedOrUnselectedMedia = selectedOrUnselectedMedia.filter(
        id => !mediaIds.includes(id),
      );
    }
  };

/**
 * Context
 */
export const DataBrowserStateContext = createContext<{
  state: DataBrowserState;
  dispatch: (f: (state: Draft<DataBrowserState>) => void | DataBrowserState) => void;
}>({
  state: defaultState,
  dispatch: () => {},
});

export const useDataBrowserState = () => useContext(DataBrowserStateContext);

import { createStyles, Theme, StyleRules } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    container: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    logotypeContainer: {
      backgroundColor: theme.palette.primary.main,
      width: '50%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        width: '40%',
      },
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    logotypeImage: {
      width: 600,
    },
    formContainer: {
      width: '50%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
    },
    form: {
      width: 400,
    },
    tab: {
      fontWeight: 400,
      fontSize: 18,
    },
    greeting: {
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: theme.spacing(4),
      fontSize: '1.2em',
    },
    subGreetingProduct: {
      fontWeight: 100,
      fontSize: '1.2em',
      textAlign: 'center',
      margin: theme.spacing(3),
      lineHeight: 1.4,
    },
    googleButtonCreating: {
      marginTop: 0,
    },
    googleIcon: {
      width: 30,
      marginRight: theme.spacing(2),
    },
    creatingButtonContainer: {
      marginTop: theme.spacing(2.5),
      height: 46,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    createAccountButton: {
      height: 46,
    },
    formDividerContainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
    },
    formDividerWord: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    formDivider: {
      flexGrow: 1,
      height: 1,
      backgroundColor: theme.palette.text.hint + '40',
    },
    errorMessage: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
    successMessage: {
      color: theme.palette.success.main,
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
    textFieldUnderline: {
      '&:before': {
        borderBottomColor: theme.palette.primary.light,
      },
      '&:after': {
        borderBottomColor: theme.palette.primary.main,
      },
      '&:hover:before': {
        borderBottomColor: `${theme.palette.primary.light} !important`,
      },
    },
    textField: {
      borderBottomColor: theme.palette.background.lightBlue,
    },
    formButtons: {
      width: '100%',
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    forgetButton: {
      fontWeight: 400,
    },
    loginLoader: {
      marginLeft: theme.spacing(4),
    },
    copyright: {
      color: '#fff',
      marginTop: theme.spacing(4),
      whiteSpace: 'nowrap',
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        bottom: theme.spacing(2),
      },
    },
    centerGrid: {
      display: 'flex',
      justifyContent: 'center',
    },
    forgetPasswordButtonText: {
      paddingTop: 0,
      fontSize: 14,
      color: '#0C64CD',
      marginBottom: 10,
      fontWeight: 700,
      paddingRight: 20,
      '&:hover': { cursor: 'pointer' },
    },
    buttonText: {
      paddingTop: 0,
      fontSize: 14,
      color: '#0C64CD',
      marginLeft: 10,
      marginTop: 15,
      marginBottom: 10,
      fontWeight: 700,
      paddingRight: 20,
      '&:hover': { cursor: 'pointer' },
    },
    ssoPageTitle: {
      display: 'flex',
      justifyContent: 'center',
      color: 'black',
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 30,
    },
  });

export default styles;

import { BaseAPI } from './base_api';
import { ZeroAuthProject, ZeroAuthTrainParams } from '@clef/shared/types';

class ZeroAuthAPI extends BaseAPI {
  // This API will set cookie for later calls
  async login(): Promise<string> {
    return this.get('login', undefined, true);
  }
  async getProjects(): Promise<ZeroAuthProject[]> {
    return this.get('projects', undefined, true);
  }
  async getProject(zeroAuthProjectId: number): Promise<ZeroAuthProject> {
    return this.get('project', { zeroAuthProjectId }, true);
  }
  // Use SSE event `zero_auth/refresh` to get notified for training success
  async train(params: ZeroAuthTrainParams): Promise<any> {
    return this.postJSON('train', params);
  }
  async reset(zeroAuthProjectId: number): Promise<any> {
    return this.postJSON('reset', { zeroAuthProjectId });
  }
}

export default new ZeroAuthAPI('zero_auth', '');

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Image, Stage, Layer, Rect, Transformer } from 'react-konva';
import Konva from 'konva';
import { TransformId, TransformParamValue } from '@clef/shared/types';
import { getMultiplesInput } from '../../../utils/job_train_utils';
import { useJobStyles } from '../jobStyles';
import LoadingOverlay from 'react-loading-overlay';
import { Stage as StageType } from 'konva/types/Stage';
import { KonvaEventObject } from 'konva/types/Node';
import {
  clipBox,
  pixelatePosition,
} from '@clef/client-library/src/components/MediaInteractiveCanvas/components/Shapes';
import useImage from 'use-image';

type CarouselProps = {
  isProcessing: boolean;
  imageSrc: string | undefined;
  width: number;
  height: number;
  showCropTool?: boolean;
  transformValues?: { id: TransformId; params: TransformParamValue[] };
  updateTransformParam(id: TransformId, param: TransformParamValue): void;
  transformMultiples?: number;
  parentWidth?: number;
};

const cropLayerDefault = {
  x: 0,
  y: 0,
  width: 50,
  height: 50,
  fill: 'transparent',
};

const CarouselImageViewer = ({
  isProcessing,
  imageSrc,
  width,
  height,
  transformValues,
  updateTransformParam,
  showCropTool,
  transformMultiples,
  parentWidth,
}: CarouselProps) => {
  const styles = useJobStyles();
  const [stageScale, setStageScale] = useState<number>(0);
  const [stageX, setStageX] = useState<number>(0);
  const [stageY, setStageY] = useState<number>(0);
  const [imageWidth, setImageWidth] = useState<number>(width);
  const [imageHeight, setImageHeight] = useState<number>(height);

  const cropLayerRef = useRef<Konva.Shape>(null);
  const trRef = useRef<Konva.ShapeConfig>(null);

  const stageSize = 400;
  const stageHeight = 400;
  const stageWidth = parentWidth ?? stageSize;

  const calculateStageAttrFromProps = React.useCallback(
    (width: number, height: number) => {
      let stageX = 0,
        stageY = 0,
        scale = 1;
      if (imageSrc !== undefined) {
        const widthRatio = width === 0 ? 0 : stageWidth / width;
        const heightRatio = height === 0 ? 0 : stageHeight / height;
        const isHeightRatioHigher = widthRatio < heightRatio;
        if (isHeightRatioHigher) {
          scale = widthRatio;
          stageY = Math.round(stageHeight - scale * height);
        } else {
          scale = heightRatio;
          stageX = Math.round(stageWidth - scale * width);
        }
      }
      return { stageX, scale, stageY };
    },
    [imageSrc, stageWidth, stageHeight],
  );

  const showCropLayer = React.useCallback(() => {
    if (trRef?.current) {
      trRef.current.nodes([cropLayerRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, []);

  useEffect(() => {
    if (!transformValues) {
      return;
    }
    const { id, params } = transformValues;
    const { x, y, width, height } = cropLayerDefault;
    if (showCropTool && params.find(p => p.name == 'x_min')?.value === null) {
      updateTransformParam(id, { name: 'x_min', value: x });
      updateTransformParam(id, { name: 'y_min', value: y });
      updateTransformParam(id, {
        name: 'y_max',
        value: height + y,
      });
      updateTransformParam(id, {
        name: 'x_max',
        value: width + x,
      });
    }
  }, [transformValues, showCropTool, updateTransformParam]);

  const [image] = useImage(imageSrc || '');
  const stageRef = useRef<StageType | null>(null);
  useEffect(() => {
    if (image) {
      const { width, height } = image;
      if (stageRef.current) {
        const { scale, stageX, stageY } = calculateStageAttrFromProps(width, height);
        setStageScale(scale);
        setStageX(stageX);
        setStageY(stageY);
        setImageWidth(width);
        setImageHeight(height);
        showCropLayer();
      }
    }
  }, [image, width, height, calculateStageAttrFromProps, showCropLayer]);

  const findByKeyAndValue = (params: TransformParamValue[], key: string) =>
    params.find(p => p.name === key)?.value || 0;

  const setTransformParams = () => {
    const node = cropLayerRef.current;
    if (node && transformValues) {
      const scaleX = node?.scaleX();
      const scaleY = node?.scaleY();
      const { id } = transformValues;
      node.scaleX(1);
      node.scaleY(1);

      const x = Math.round(node.x() < 0 ? 0 : node.x());
      const y = Math.round(node.y() < 0 ? 0 : node.y());
      const width = Math.round(Math.max(cropLayerDefault.width, node.width()) * scaleX);
      const height = Math.round(Math.max(cropLayerDefault.height, node.height()) * scaleY);

      updateTransformParam(id, { name: 'x_min', value: x });
      updateTransformParam(id, { name: 'y_min', value: y });
      updateTransformParam(id, {
        name: 'x_max',
        value: getMultiplesInput(width + x, transformMultiples, x),
      });
      updateTransformParam(id, {
        name: 'y_max',
        value: getMultiplesInput(height + y, transformMultiples, y),
      });
    }
  };
  const { params = [] } = transformValues ?? {};

  const onCropDragMove = useCallback(
    (e: KonvaEventObject<DragEvent>) => {
      const node = cropLayerRef.current;
      if (node) {
        const scaleX = node?.scaleX();
        const scaleY = node?.scaleY();
        node.scaleX(1);
        node.scaleY(1);

        const x = Math.round(node.x() < 0 ? 0 : node.x());
        const y = Math.round(node.y() < 0 ? 0 : node.y());
        const width = Math.round(Math.max(cropLayerDefault.width, node.width()) * scaleX);
        const height = Math.round(Math.max(cropLayerDefault.height, node.height()) * scaleY);
        const imageBoxHeight = image?.height || imageHeight;
        const imageBoxWidth = image?.width || imageWidth;
        e.target.setAttrs({
          ...e.target.attrs,
          x: Math.min(x, imageBoxWidth - width),
          y: Math.min(y, imageBoxHeight - height),
          width,
          height,
        });
      }
    },
    [image?.height, image?.width, imageHeight, imageWidth],
  );

  return (
    <LoadingOverlay
      active={isProcessing || !image}
      className={styles.previewMediaOverlay}
      spinner
      text=""
    >
      {image && (
        <Stage
          className={styles.carouselImageContainer}
          width={stageWidth - stageX}
          height={stageHeight - stageY}
          scaleX={stageScale}
          scaleY={stageScale}
          x={0}
          y={0}
          // @ts-ignore
          ref={node => (stageRef.current = node)}
        >
          <Layer name="Image Layer">
            <Image x={0} y={0} image={image} width={imageWidth} height={imageHeight} />
          </Layer>
          {!!showCropTool && (
            <Layer>
              <Rect
                // @ts-ignore
                ref={cropLayerRef}
                x={Math.round(findByKeyAndValue(params, 'x_min')) || cropLayerDefault.x}
                y={Math.round(findByKeyAndValue(params, 'y_min')) || cropLayerDefault.y}
                width={Math.round(
                  findByKeyAndValue(params, 'x_max') - findByKeyAndValue(params, 'x_min'),
                )}
                height={Math.round(
                  findByKeyAndValue(params, 'y_max') - findByKeyAndValue(params, 'y_min'),
                )}
                draggable
                onDragEnd={setTransformParams}
                onTransformEnd={setTransformParams}
                onDragMove={onCropDragMove}
              />
              <Transformer
                // @ts-ignore
                ref={trRef}
                rotateEnabled={false}
                keepRatio={false}
                boundBoxFunc={(oldBox, newBox) => {
                  if (newBox.width < 5 || newBox.height < 5) return oldBox;
                  const layer = cropLayerRef.current!.getLayer()!;
                  const layerBox = {
                    ...layer.getAbsolutePosition(),
                    width: image.width * layer.getAbsoluteScale().x,
                    height: image.height * layer.getAbsoluteScale().y,
                  };
                  const { scale } = calculateStageAttrFromProps(image.width, image.height);
                  const newPixelatedBox = {
                    ...pixelatePosition(newBox, layerBox, scale),
                    width: Math.max(1, Math.round(newBox.width / scale)) * scale,
                    height: Math.max(1, Math.round(newBox.height / scale)) * scale,
                  };
                  return { ...newBox, ...clipBox(newPixelatedBox, layerBox, scale) };
                }}
              />
            </Layer>
          )}
        </Stage>
      )}
    </LoadingOverlay>
  );
};

export default CarouselImageViewer;

import React from 'react';

export type DataBrowserIconProps = {};

const DataBrowserIcon: React.FC<DataBrowserIconProps> = () => {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor" stroke="none">
        <path d="M19.6887 17.6688L14.3887 12.3688H13.3987L10.8587 14.9088V15.8988L16.1587 21.1988C16.5487 21.5888 17.1787 21.5888 17.5687 21.1988L19.6887 19.0788C20.0787 18.6988 20.0787 18.0588 19.6887 17.6688ZM16.8587 19.0888L12.6187 14.8488L13.3287 14.1388L17.5687 18.3788L16.8587 19.0888Z" />
        <path d="M15.3587 9.68878L16.7687 8.27878L18.8887 10.3988C20.0587 9.22878 20.0587 7.32878 18.8887 6.15878L15.3487 2.61878L13.9387 4.02878V1.20878L13.2387 0.498779L9.69874 4.03878L10.4087 4.74878H13.2387L11.8287 6.15878L12.8887 7.21878L9.99874 10.1088L5.86874 5.97878V4.55878L2.84874 1.53878L0.0187378 4.36878L3.04874 7.39878H4.45874L8.58874 11.5288L7.73874 12.3788H5.61874L0.318738 17.6788C-0.0712622 18.0688 -0.0712622 18.6988 0.318738 19.0888L2.43874 21.2088C2.82874 21.5988 3.45874 21.5988 3.84874 21.2088L9.14874 15.9088V13.7888L14.2987 8.63878L15.3587 9.68878ZM7.37874 14.8388L3.13874 19.0788L2.42874 18.3688L6.66874 14.1288L7.37874 14.8388Z" />
      </g>
    </svg>
  );
};

export default DataBrowserIcon;

import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { Button } from '@clef/client-library';
import DefectList from './DefectList';
import CreateDefectDialog from './DefectList/CreateDefectDialog';
import { defectColors } from '@clef/client-library';
import { useDefectSelectorWithArchived } from '../../store/defectState/actions';
import { useDefectBookEnhancedStyles } from './defectBookEnhancedStyles';
import { LabelType } from '@clef/shared/types';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';

export interface DefectBookPageProps {}

const DefectBookPage: React.FC<DefectBookPageProps> = () => {
  const styles = useDefectBookEnhancedStyles();
  const defects = useDefectSelectorWithArchived();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const { labelType } = useGetSelectedProjectQuery().data ?? {};
  return (
    <div>
      {labelType !== LabelType.AnomalyDetection && (
        <Box
          justifyContent="space-between"
          alignItems="center"
          className={styles.newTopButtonGroup}
        >
          <Button
            id="create-class"
            color="primary"
            variant="contained"
            onClick={() => {
              setOpenCreateDialog(true);
            }}
            className={styles.newCreateClassesButton}
          >
            {t('Create Class')}
          </Button>
        </Box>
      )}

      <DefectList style={{ paddingTop: 0 }} />

      {openCreateDialog && (
        <CreateDefectDialog
          onClose={() => setOpenCreateDialog(false)}
          nextDefectIndex={(defects?.length ?? 0) % defectColors.length}
        />
      )}
    </div>
  );
};

export default DefectBookPage;

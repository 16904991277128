import { CircularProgress, Tooltip } from '@material-ui/core';
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded';
import Error from '@material-ui/icons/Error';
import PublishRounded from '@material-ui/icons/PublishRounded';
import React from 'react';
import {
  generateUploadedMessage,
  generateUploadingMessage,
  getUploadStats,
} from '../../../../store/uploadState/utils';
import { useDialog } from '../useDialog';
import useHeaderStyles from './styles';
import cx from 'classnames';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { UploadStage } from '../../../../store/uploadState/types';

interface MediaUploadIndicatorProps {}

const MediaUploadIndicator: React.FC<MediaUploadIndicatorProps> = () => {
  const { openUpload } = useDialog();
  const classes = useHeaderStyles();
  const { uploadData, uploadStage } = useTypedSelector(state => state.uploadState);

  if (uploadStage === UploadStage.NotStarted) {
    return null;
  }

  let uploadIcon;
  if (uploadStage === UploadStage.UploadInProgress) {
    uploadIcon = (
      <div aria-label="upload in progress">
        <CircularProgress
          variant="determinate"
          value={100}
          size="small"
          className={cx(classes.uploadBase, 'wip')}
        />
        <CircularProgress size="small" className={cx(classes.uploadInProgress)} />
        <PublishRounded fontSize="small" className={cx(classes.uploadProgressIcon, 'animated')} />
      </div>
    );
  } else if (uploadStage === UploadStage.UploadFulfilled) {
    uploadIcon = (
      <div aria-label="upload succeeded">
        <CircularProgress
          variant="determinate"
          value={100}
          size="small"
          className={cx(classes.uploadBase)}
        />
        <PublishRounded fontSize="small" className={classes.uploadProgressIcon} />
        <CheckCircleRounded fontSize="small" className={classes.mediaUploadSuccessBadge} />
      </div>
    );
  } else if (uploadStage === UploadStage.UploadFulfilledWithFailure) {
    uploadIcon = (
      <div aria-label="upload failed">
        <CircularProgress
          variant="determinate"
          value={100}
          size="small"
          className={cx(classes.uploadBase)}
        />
        <PublishRounded fontSize="small" className={classes.uploadProgressIcon} />
        <Error fontSize="small" className={classes.mediaUploadFailureBadge} />
      </div>
    );
  }

  const uploadStats = getUploadStats(uploadData);
  const tooltipText =
    uploadStage === UploadStage.UploadInProgress
      ? generateUploadingMessage(uploadData)
      : generateUploadedMessage(uploadStats);

  return (
    <Tooltip placement="bottom" title={tooltipText} PopperProps={{ disablePortal: true }}>
      <div
        className={classes.indicatorContainer}
        onClick={() => openUpload()}
        data-testid="header-upload-progress"
      >
        {uploadIcon}
      </div>
    </Tooltip>
  );
};
export default MediaUploadIndicator;

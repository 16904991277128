import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  header: {},
  projects: {
    display: 'flex',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  projectDropdown: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  currentProjectButton: {
    backgroundColor: theme.palette.blue[50],
    color: theme.palette.blue[600],
  },
  createProjectButton: {
    cursor: 'default',
    backgroundColor: theme.palette.greyModern[300],
    '&:hover': {
      backgroundColor: theme.palette.greyModern[300],
    },
    '&:active': {
      backgroundColor: theme.palette.greyModern[300],
    },
  },
  verticalLine: {
    borderLeft: `1px solid ${theme.palette.greyModern[300]}`,
    height: 16,
    margin: theme.spacing(0, 2),
  },
  betaBadge: {
    backgroundColor: theme.palette.green[400],
    borderRadius: 5,
    padding: theme.spacing(0.5, 1.5),
    color: 'white',
    marginLeft: theme.spacing(2),
  },
}));

export default useStyles;

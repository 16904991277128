import { makeStyles } from '@material-ui/core';

export const useAutoSplitStyles = makeStyles(theme => ({
  // shared
  dialogScrollContainer: {
    maxHeight: 'calc(100% - 32px)',
  },
  headerText: {
    marginBottom: theme.spacing(2),
  },
  infoSection: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    '&:first-of-type': {
      paddingTop: theme.spacing(0),
    },
  },
  checkBoxContainer: {
    marginTop: theme.spacing(2),
    marginLeft: -11,
  },
  alerts: {
    borderRadius: 8,
  },
  italic: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: 500,
  },
  // index.tsx
  dialogContainer: {
    padding: theme.spacing(10),
  },
  topAlert: {
    borderRadius: 8,
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
  },
  postRequestMask: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
    background: 'rgba(255,255,255,0.7)',
  },
  // step 1
  extraMarginBottom: {
    marginBottom: theme.spacing(5),
  },
  checkBoxCaption: {
    fontStyle: 'italic',
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
  // step 2
  sliderGridContainer: {
    display: 'grid',
    gridTemplateColumns: '120px auto 150px',
    alignItems: 'center',
  },
  sliderGridSplitTitle: {
    gridRowStart: 1,
    gridColumnStart: 3,
    justifySelf: 'end',
    fontStyle: 'italic',
  },
  sliderTitle: {
    gridRowStart: 1,
    gridColumnStart: 1,
    gridColumnEnd: 'span 2',
  },
  tooltip: {
    background: 'rgba(60,60,60,0.9)',
    color: '#fff',
  },
  helpIcon: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(2),
  },
  // distribution preview graphs
  infoHeaderMargin: {
    marginBottom: theme.spacing(2),
    '& > *': {
      marginRight: theme.spacing(3),
    },
  },
  graphContainer: {
    display: 'grid',
    gridTemplateColumns: '[col1-start] 1fr 32px [col2-start] 2fr',
    gridTemplateRows: '[row1-start] auto 32px [row2-start] auto [row-end]',
  },
  splitGraph: {
    gridRowStart: 'row1-start',
    gridRowEnd: 'row-end',
    gridColumnStart: 'col1-start',
    '& > div': {
      height: '100%',
    },
  },
  defectSplitGraph: {
    gridRowStart: 'row1-start',
    gridColumnStart: 'col2-start',
    '& > div': {
      height: '100%',
    },
  },
  splitDefectGraph: {
    gridRowStart: 'row2-start',
    gridColumnStart: 'col2-start',
    '& > div': {
      height: '100%',
    },
  },
}));

import React from 'react';
import { Provider } from 'jotai';
import { Box, Dialog, Divider, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { IconButton, Typography } from '@clef/client-library';

import { ModelComparisonSelectionInfo } from '../utils';
import { ModelComparisonDetailAnalysis } from './ModelComparisonDetailAnalysis';
import { ModelComparisonSummary } from './ModelComparisonSummary';

export type ModelComparisonDialogProps = {
  open: boolean;
  onClose: () => void;
  comparisonSelectionInfo: ModelComparisonSelectionInfo;
};

const useStyles = makeStyles(theme => ({
  dialogPager: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  dialogSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(6, 6, 6, 15),
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  content: {
    overflow: 'auto',
    padding: theme.spacing(0, 15),
  },
  reportNameInput: {
    width: 800,
    marginRight: theme.spacing(2),
  },
  inputError: {
    position: 'relative',
    top: theme.spacing(3),
  },
  reportNameText: {
    fontSize: 24,
    marginRight: theme.spacing(2),
  },
}));

const ModelComparisonDialog: React.FC<ModelComparisonDialogProps> = props => {
  const styles = useStyles();
  const { open = false, onClose, comparisonSelectionInfo } = props;
  const { baseline, candidate, evaluationSet } = comparisonSelectionInfo;

  if (!baseline || !candidate || !evaluationSet) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'xl'}>
      <Box className={styles.dialogPager}>
        <Box flexDirection="row" className={styles.dialogSection}>
          <Typography variant="h1_semibold">{t('Compare Models')}</Typography>
          <IconButton aria-label="close" className={styles.closeButton} onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={styles.content}>
          <ModelComparisonSummary
            evaluationSet={evaluationSet}
            baseline={baseline}
            candidate={candidate}
          />
          <Divider />
          <Provider>
            <ModelComparisonDetailAnalysis
              evaluationSet={evaluationSet}
              baseline={baseline}
              candidate={candidate}
            />
          </Provider>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ModelComparisonDialog;

import { makeStyles } from '@material-ui/core';
import { UPLOAD_DIALOG_Z_INDEX } from './constants';

export const useUploadStyles = makeStyles(theme => ({
  headerContainer: {
    padding: theme.spacing(10, 0),
    width: '100%',
  },
  headerText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(8),
  },
  contentContainer: {
    width: '100%',
    padding: theme.spacing(10, 10),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: 1600,
    margin: theme.spacing(0, 'auto'),
  },
  bar: {
    borderRadius: 2.5,
    height: 5,
  },
  barPosition: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(5),
  },
  uploadTypeCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(10, 10),
    width: 600,
    height: 400,
    margin: theme.spacing(5),
    cursor: 'pointer',
    transition: 'all 0.2s ease-in',
    '&:hover': {
      boxShadow: `${theme.boxShadow.strong} !important`,
    },
    '& > h3': {
      marginBottom: theme.spacing(3),
    },
    '& > img': {
      marginTop: theme.spacing(5),
      width: '100%',
    },
  },
  uploadContainer: {
    padding: theme.spacing(5, 0),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  dropzoneBaseStyle: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(10),
    alignItems: 'center',
    backgroundColor: '#FAFAFB',
    padding: theme.spacing(10),
    borderWidth: 2,
    borderRadius: 8,
    borderColor: theme.palette.grey[400],
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'all .1s ease-in-out',
    cursor: 'pointer',
    position: 'relative',
  },
  dropzoneText: {
    color: theme.palette.grey[500],
  },
  activeStyle: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  dropzoneHint: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    color: theme.palette.common.black,
  },
  dropzoneContentFade: {
    opacity: 0.4,
  },
  classifiedPreviewSection: {
    width: '100%',
    margin: theme.spacing(3, 0),
    '& $rowClose': {
      opacity: 0,
    },
    '&:hover $rowClose': {
      opacity: 1,
    },
  },
  newDefectChip: {
    backgroundColor: `${theme.palette.primary.main}1A`,
    color: theme.palette.primary.main,
    margin: theme.spacing(0, 3),
  },
  previewImgRow: {
    marginTop: theme.spacing(2),
    lineHeight: 0,
    width: '100%',
  },
  previewMedia: {
    display: 'inline-block',
    position: 'relative',
    height: 112,
    margin: theme.spacing(1),
    overflow: 'hidden',
  },
  previewMediaToolTip: {
    height: '100%',
    '& > img': {
      borderRadius: 8,
      height: '100%',
      pointerEvents: 'none',
    },
    '& $previewMediaClose': {
      opacity: 0,
    },
    '&:hover $previewMediaClose': {
      opacity: 1,
    },
  },
  previewMediaClose: {
    zIndex: 101,
    backgroundColor: theme.palette.grey[100],
    position: 'absolute',
    top: 10,
    right: 10,
    width: 20,
    height: 20,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  previewMediaSuccess: {
    color: theme.palette.success.main,
    top: 10,
    left: 10,
    position: 'absolute',
  },
  previewMediaFailure: {
    color: theme.palette.error.main,
    top: 10,
    left: 10,
    position: 'absolute',
    zIndex: 101,
  },
  instructionImg: {
    width: 200,
    margin: theme.spacing(5, 0),
    pointerEvents: 'none',
  },
  rowClose: {
    marginLeft: theme.spacing(3),
  },
  uploadProgress: {
    height: 6,
    borderRadius: 3,
    boxShadow: theme.boxShadow.default,
    margin: theme.spacing(2, 0),
  },
  uploadSuccessIcon: {
    color: theme.palette.success.main,
    width: 32,
    height: 32,
    marginRight: theme.spacing(3),
  },
  // nit
  flexGrow: {
    flexGrow: 1,
  },
  helpIcon: {
    color: theme.palette.grey[400],
    margin: theme.spacing(0, 2),
  },
  divider: {
    paddingBottom: theme.spacing(2),
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey[300],
  },
  italic: {
    fontStyle: 'italic',
  },
  postRequestMask: {
    transition: 'all 0.2s ease-in',
    borderRadius: 8,
    overflow: 'hidden',
    top: 0,
    left: 0,
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
    background: 'rgba(255,255,255,0.8)',
  },
  tagLeftCorner: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    maxWidth: 'calc(112px - 16px)',
    '& span': {
      lineHeight: '19px',
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  metadataButtonDropdown: {
    display: 'inline-block',
    width: 'inherit',
  },
  uploadButtonWrapper: {
    width: '100%',
  },
  selectedAttachMetadata: {
    transform: `translateX(${theme.spacing(2)}px)`,
    overflowX: 'auto',
  },
  metadataItem: {
    '& + &': {
      marginLeft: theme.spacing(2),
    },
  },
  dropZoneDisabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  mediaCountProgressBar: {
    marginBottom: theme.spacing(5),
  },
  setMetadataBtn: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  webcamWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '40px',
    marginBottom: '40px',
  },
  webcamFileSwitchButtonWrapper: {
    marginBottom: '48px',
    display: 'flex',
    alignItems: 'center',
  },
  instantLearningZone: {
    border: `2px dashed ${theme.palette.grey[300]}`,
    backgroundColor: '#fff',
  },
  acceptLabel: {
    color: theme.palette.grey[500],
    fontSize: '14px',
  },
  labelsIcon: {
    marginLeft: '30px',
  },
  whiteBg: {
    backgroundColor: theme.palette.common.white,
  },
}));

export const useNewUploadStyles = makeStyles(theme => ({
  headerContainer: {
    width: '100%',
  },
  headerRow: {
    marginBottom: theme.spacing(5),
  },
  headerText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionHeaderText: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  contentContainer: {
    width: '100%',
    padding: theme.spacing(0, 10),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: 1600,
    margin: theme.spacing(0, 'auto'),
  },
  bar: {
    borderRadius: 2.5,
    height: 5,
  },
  barPosition: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(5),
  },
  uploadTypeCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(10, 10),
    width: 600,
    height: 400,
    margin: theme.spacing(5),
    cursor: 'pointer',
    transition: 'all 0.2s ease-in',
    '&:hover': {
      boxShadow: `${theme.boxShadow.strong} !important`,
    },
    '& > h3': {
      marginBottom: theme.spacing(3),
    },
    '& > img': {
      marginTop: theme.spacing(5),
      width: '100%',
    },
  },
  uploadContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  mediaCountText: {
    marginLeft: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  dropzoneWrapper: {
    width: '90%',
  },
  dropzoneBaseStyle: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    alignItems: 'center',
    backgroundColor: 'white',
    padding: theme.spacing(5),
    borderWidth: 2,
    borderRadius: 8,
    borderColor: theme.palette.grey[300],
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'all .1s ease-in-out',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      borderColor: theme.palette.primary.light,
      '& $dropzoneBox': {
        color: theme.palette.info.main,
        backgroundColor: '#2196f333', //rgba(33, 150, 243, 0.2)
      },
      '& $dropZoneHelpIcon': {
        color: theme.palette.info.main,
      },
    },
  },
  dropzoneBox: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[400],
    alignSelf: 'flex-start',
    padding: '2px 4px',
    fontSize: 14,
    borderRadius: 4,
    margin: -10,
  },
  dropzoneText: {
    color: theme.palette.grey[500],
  },
  chooseFileButton: {
    marginTop: theme.spacing(5),
  },
  chooseFileText: {
    color: theme.palette.primary.main,
  },
  activeStyle: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  dropzoneHint: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    color: theme.palette.common.black,
  },
  dropzoneContentFade: {
    opacity: 0.4,
  },
  classifiedPreviewSection: {
    width: '100%',
    margin: theme.spacing(3, 0),
    '& $rowClose': {
      opacity: 0,
    },
    '&:hover $rowClose': {
      opacity: 1,
    },
  },
  newDefectChip: {
    backgroundColor: `${theme.palette.primary.main}1A`,
    color: theme.palette.primary.main,
    margin: theme.spacing(0, 3),
  },
  previewImgRow: {
    marginTop: theme.spacing(2),
    lineHeight: 0,
    width: '100%',
  },
  previewMedia: {
    display: 'inline-block',
    position: 'relative',
    height: 112,
    margin: theme.spacing(1),
    overflow: 'hidden',
  },
  previewMediaToolTip: {
    height: '100%',
    '& > img': {
      borderRadius: 8,
      height: '100%',
      pointerEvents: 'none',
    },
    '& $previewMediaClose': {
      opacity: 0,
    },
    '&:hover $previewMediaClose': {
      opacity: 1,
    },
  },
  previewMediaClose: {
    zIndex: 101,
    backgroundColor: theme.palette.grey[100],
    position: 'absolute',
    top: 10,
    right: 10,
    width: 20,
    height: 20,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  previewMediaSuccess: {
    color: theme.palette.success.main,
    top: 10,
    left: 10,
    position: 'absolute',
  },
  previewMediaFailure: {
    color: theme.palette.error.main,
    top: 10,
    left: 10,
    position: 'absolute',
    zIndex: 101,
  },
  instructionImg: {
    width: 130,
    pointerEvents: 'none',
  },
  rowClose: {
    marginLeft: theme.spacing(3),
  },
  uploadProgress: {
    height: 6,
    borderRadius: 3,
    boxShadow: theme.boxShadow.default,
    margin: theme.spacing(2, 0),
  },
  uploadSuccessIcon: {
    color: theme.palette.success.main,
    width: 32,
    height: 32,
    marginRight: theme.spacing(3),
  },
  // nit
  flexGrow: {
    flexGrow: 1,
  },
  helpIcon: {
    color: theme.palette.grey[400],
    margin: theme.spacing(0, 2),
  },
  divider: {
    paddingBottom: theme.spacing(2),
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey[300],
  },
  italic: {
    fontStyle: 'italic',
  },
  postRequestMask: {
    transition: 'all 0.2s ease-in',
    borderRadius: 8,
    overflow: 'hidden',
    top: 0,
    left: 0,
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
    background: 'rgba(255,255,255,0.8)',
  },
  tagLeftCorner: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    maxWidth: 'calc(112px - 16px)',
    '& span': {
      lineHeight: '19px',
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  metadataButtonDropdown: {
    display: 'inline-block',
    width: 'inherit',
  },
  metadataButtonDropdownRoot: {
    marginRight: theme.spacing(2),
  },
  metadataButtonDropdownSector: {
    display: 'inline-block',
    width: 'inherit',
  },
  uploadButtonWrapper: {
    width: '100%',
  },
  selectedAttachMetadata: {
    transform: `translateX(${theme.spacing(2)}px)`,
    overflowX: 'auto',
  },
  metadataTitle: {
    marginRight: theme.spacing(2),
  },
  metadataItem: {
    marginRight: theme.spacing(2),
  },
  segmentationMediaTypeSelectionButton: {
    color: theme.palette.grey[800],
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  segmentationMediaTypeSelectionDropdownItem: {
    color: theme.palette.grey[800],
    '&:focus': {
      backgroundColor: theme.palette.primary.light,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
  segmentationDropZoneChipContainer: {
    marginBottom: theme.spacing(5),
  },
  segmentationDropZoneChip: {
    backgroundColor: theme.palette.common.white,
  },
  segmentationDropZoneChipIcon: {
    width: 20,
    alignSelf: 'center',
    marginRight: theme.spacing(2),
  },
  segmentationUnlabeledMediaPreviewCheckboxContainer: {
    alignSelf: 'flex-start',
    alignItems: 'center',
    display: 'flex',
  },
  segmentationUnlabeledMediaPreviewCheckboxText: {
    fontSize: 14,
  },
  segmentationMediaTypeTabText: {
    color: theme.palette.primary.main,
  },
  segmentationMediaTypeTabBox: {
    background: '#F4F8FE',
    color: theme.palette.primary.main,
    padding: 1,
  },
  segmentationMediaTypeTab: {
    minWidth: '80px',
    maxWidth: '160px',
  },
  segmentationMediaTypeTabsContainer: {
    borderBottom: 1,
    borderColor: 'divider',
    alignSelf: 'flex-start',
  },
  dropZoneHelpIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[400],
  },
  dropZoneDisabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  mediaCountProgressBar: {
    marginBottom: theme.spacing(5),
  },
  dropZoneHelpContentPopover: {
    zIndex: `${UPLOAD_DIALOG_Z_INDEX + 1} !important` as unknown as number,
  },
  additionalInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  },
  additionalInfoChips: {
    display: 'flex',
    alignItems: 'center',
  },
  additionalInfoChipsTitle: {
    width: '64px',
    marginRight: theme.spacing(4),
  },
  addTagButton: {
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[200],
    borderRadius: 5,
    padding: '4px 6px',
    height: theme.spacing(6),
  },
  addTagIcon: {
    fontSize: theme.spacing(4),
  },
  chipsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  setMetadataBtn: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

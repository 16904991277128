import { CacheStorage, CacheStorageKey } from '@clef/client-library';
import React from 'react';
import cnLang from './lang/cn';
import jpLang from './lang/jp';

export const LanguageCache = new CacheStorage<string>({
  key: CacheStorageKey.Language,
  persist: true,
  defaultValue: 'en',
});

type LanguageOption = {
  id: string;
  name: string;
  bundle: { [key: string]: string };
};

export const supportedLanguages: LanguageOption[] = [
  { id: 'en', name: 'English', bundle: {} },
  { id: 'cn', name: '简体中文', bundle: cnLang },
  { id: 'jp', name: '日本語', bundle: jpLang },
];

const variableRegex = /\{\{([^{}]+)\}\}/g;

export const translate: typeof window.t = (sentence, variables) => {
  const languageBundle = supportedLanguages.find(sl => sl.id === LanguageCache.get())?.bundle;
  const translation = languageBundle?.[sentence] ?? sentence;
  if (!variables) return translation;

  const foundVars = translation.match(variableRegex)?.map(fv => fv.slice(2, fv.length - 2));
  const transSplit = translation.split(/{{|}}/);
  const whiteSpaceStyle: React.CSSProperties = { whiteSpace: 'pre-wrap' };
  const transSplitMapToVariable = transSplit.map((split, index) => {
    if (!split) {
      return null;
    }
    if (foundVars?.includes(split)) {
      return (
        <span style={whiteSpaceStyle} key={split + index}>
          {variables?.[split] ?? ''}
        </span>
      );
    }
    return (
      <span style={whiteSpaceStyle} key={split + index}>
        {split}
      </span>
    );
  });
  return (<span style={whiteSpaceStyle}>{transSplitMapToVariable}</span>) as unknown as string;
};

window.t = translate;

/**
 * Below are a list of ad-hoc string that needs to be picked up to translate
 * Most likely they are phrases from backend and used as variables from frontend code
 */
// enum TaskStatus
t('Labeled');
t('Unassigned');
t('Completed');
t('Under Review');
t('Reviewed');
t('Canceled');
// enum UserRole
t('Super');
t('Admin');
t('Collaborator');
// enum UserStatus
t('Confirming');
t('Inactive');
// enum ProjectRoles
t('Supervisor');
t('Inspector');
// enum FilterOptionName
t('Media status');
t('Media name');
t('Defect type');
t('Reviewer');
t('Labeler');
t('Number of bounding box labels');
t('Number of defect types');
t('Media id');
t('split');

import React, { useCallback } from 'react';
import {
  Typography,
  Grid,
  makeStyles,
  DialogContent,
  Dialog,
  DialogTitle,
  Box,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import { FormContextValues, useForm } from 'react-hook-form';

import { PrimaryButton } from '../../../components/Auth/Button';
import { BaseTextField } from '../../../components/Auth/TextField';
import { useSaveModelMutation } from '@/serverStore/projectModels';
import { Alert } from '@material-ui/lab';
import { RegisteredModelId } from '@clef/shared/types';

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    display: 'flex',
    margin: theme.spacing(2, 4, 0),
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(0, 4, 7),
  },
  modelNameInput: {
    width: '100%',
    marginBottom: theme.spacing(5),
  },
  saveModelButton: {
    width: 'unset',
    height: 34,
  },
  hintTextContainer: {
    width: '100%',
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(4),
  },
  descriptionContainer: {
    width: '400px',
    color: theme.palette.grey[900],
    marginBottom: theme.spacing(3),
  },
  buttonGroup: {
    gap: theme.spacing(3),
  },
  textFieldRoot: {
    width: '100%',
  },
}));

interface FormValues {
  modelName: string;
}

export interface SaveModelDialogProps {
  open: boolean;
  onClose(): void;
  hintText?: string;
  onSaveModelSucceed?: () => void | Promise<void>;
  modelId: RegisteredModelId | undefined;
}

export const SaveModelDialogBody: React.FC<{
  form: FormContextValues<FormValues>;
  onSubmit?: (formValue: FormValues) => Promise<void>;
  hintText?: string;
}> = ({ form, onSubmit, hintText, children }) => {
  const styles = useStyles();

  return (
    <>
      {!hintText && (
        <Box className={styles.descriptionContainer}>
          <Typography>
            {t(
              'Give your model a unique name to help you track it later. Don’t worry, you will be able to rename it later.',
            )}
          </Typography>
        </Box>
      )}
      {hintText && (
        <Alert severity="info" className={styles.hintTextContainer}>
          <Typography>{hintText}</Typography>
        </Alert>
      )}

      <Box
        width="100%"
        component="form"
        onSubmit={onSubmit && form.handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <BaseTextField
          rules={{
            required: t('This is required.'),
          }}
          placeholder={t('Untitled model')}
          name="modelName"
          error={form.errors?.modelName}
          control={form.control}
          classes={{ root: styles.textFieldRoot, textField: styles.textFieldRoot }}
          className={styles.modelNameInput}
          enableAutoFocus
        />

        {children}
      </Box>
    </>
  );
};

export const SaveModelDialog: React.FC<SaveModelDialogProps> = ({
  open,
  onClose,
  hintText,
  onSaveModelSucceed,
  modelId,
}) => {
  const styles = useStyles();

  const form = useForm<FormValues>({
    defaultValues: {
      modelName: 'Untitled model',
    },
  });
  const saveModel = useSaveModelMutation();

  const onSubmit = useCallback(
    async ({ modelName }: FormValues) => {
      if (!modelId) {
        return;
      }
      await saveModel.mutateAsync(
        {
          id: modelId,
          modelName,
        },
        {
          onSuccess: () => {
            onSaveModelSucceed?.();
            onClose();
          },
        },
      );
    },
    [saveModel.mutateAsync, onClose, onSaveModelSucceed, modelId],
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" disablePortal>
      <DialogTitle className={styles.dialogTitle}>
        <Typography variant="h3">{t('Save Model')}</Typography>
      </DialogTitle>
      <DialogContent className={styles.contentContainer}>
        <SaveModelDialogBody form={form} onSubmit={onSubmit} hintText={hintText}>
          <Grid container justifyContent="flex-end" className={styles.buttonGroup}>
            <Button
              id="save-model-skip-naming-button"
              variant="outlined"
              color="primary"
              onClick={() => onSubmit({ modelName: t('Untitled model') })}
              isPending={saveModel.isLoading}
              className={styles.saveModelButton}
            >
              {t('Skip Naming')}
            </Button>
            <PrimaryButton
              id="save-model-confirm-button"
              text={t('Save')}
              disabled={!!form.errors.modelName}
              isPending={saveModel.isLoading}
              className={styles.saveModelButton}
            />
          </Grid>
        </SaveModelDialogBody>
      </DialogContent>
    </Dialog>
  );
};

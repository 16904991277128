import { UploadFile } from '@/store/uploadState/types';
import { Box } from '@material-ui/core';
import React from 'react';

export const OverSizedImagesDialogContent: React.FC<{ overSizedImages: UploadFile[] }> = props => {
  const { overSizedImages } = props;
  return (
    <Box maxWidth="50vw" maxHeight="50vh" overflow="auto">
      {overSizedImages.map((uploadFile, index) => (
        <Box key={index}>{uploadFile.file.path}</Box>
      ))}
    </Box>
  );
};

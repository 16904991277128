import {
  ApiResponse,
  DateStringISO,
  OrgSetting,
  SubscriptionPlansData,
  TermsAndConditionsType,
  UpgradePlanNextAction,
} from '@clef/shared/types';
import { BaseAPI } from './base_api';

const SubscriptionPrefix = 'subscription';

export interface BillingDetails {
  dueDate: DateStringISO;
  nextPayment: {
    base: { amount: number; currency: string };
    overage?: { amount: number; currency: string }; // For visionary only
  };
  cardLast4: string;
  defaultPaymentType?: string;
}

export type InvoiceStatus = 'deleted' | 'draft' | 'open' | 'paid' | 'uncollectible' | 'void';

export interface InvoiceHistoryItem {
  url: string;
  periodStart: DateStringISO;
  periodEnd: DateStringISO;
  payments: {
    base: { amount: number; currency: string };
    overage?: { amount: number; currency: string };
  };
  plan: string;
  status: InvoiceStatus;
}
export type InvoiceHistoryData = InvoiceHistoryItem[];

export type UpgradePlanResponse =
  | { nextAction: UpgradePlanNextAction.Pay; clientSecret: string }
  | { nextAction: UpgradePlanNextAction.Success };

class SubscriptionAPI extends BaseAPI {
  getPlans(): Promise<ApiResponse<SubscriptionPlansData>> {
    return this.get(`plans`);
  }
  changePlan(stripePriceId: string): Promise<ApiResponse<string>> {
    return this.postJSON('change', { stripePriceId });
  }
  cancelPlan(cancelReason?: string, improvements?: string) {
    return this.postJSON('cancel', { cancelReason, improvements });
  }
  setTestClock(currentTime: string) {
    return this.postJSON('test_clock', { currentTime: currentTime.replace(/\d{3}Z$/, '000Z') });
  }
  getBillingDetails(): Promise<BillingDetails> {
    return this.get('billing_details', {}, true);
  }
  getInvoiceHistory(): Promise<InvoiceHistoryData> {
    return this.get('invoice_history', {}, true);
  }
  getPlanSettings(): Promise<OrgSetting> {
    return this.get('plan_settings', {}, true);
  }
  updatePlanSettings(newSettings: OrgSetting) {
    return this.postJSON('plan_settings', newSettings);
  }
  getBillingPortalUrl(paymentMethodUpdate?: boolean): Promise<string> {
    return this.get(
      'billing_portal_url',
      { paymentMethodUpdate: paymentMethodUpdate ? 'true' : undefined },
      true,
    );
  }
  upgradePlan() {
    return this.postJSON<UpgradePlanResponse>('upgrade', {});
  }
  confirmTermsAndConditions(termsAndConditionsType: TermsAndConditionsType) {
    return this.postJSON('confirm_terms_and_conditions', { termsAndConditionsType });
  }
}

export default new SubscriptionAPI(SubscriptionPrefix);

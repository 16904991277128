import React from 'react';
import { Box, Tooltip, makeStyles } from '@material-ui/core';
import { HyperParams, RegisteredModel } from '@clef/shared/types';
import { TransformUISchema } from '@/types/client';
import { Typography } from '@clef/client-library';
import { ModelAnalysisTableCellProps } from './common';
import { StyledTableCell } from './StyledTableCell';
import { useJobDetailForCurrentProject } from '@/serverStore/jobs';
import Skeleton from '@material-ui/lab/Skeleton';
import { useModelStatusQuery } from '@/serverStore/projectModels';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { isModelTrainingInProgress } from '@/store/projectModelInfoState/utils';
import { getModelArchModelSizeDisplayName } from '@/utils/job_train_utils';
import { ModelArch } from '@clef/shared/types/model_arch';
import { TRANSFORMS_UI_SCHEMA } from '@/constants/model_train';
import { cloneDeep } from 'lodash';

const CUSTOMIZED_REGIME = 'slow-accurate';
const CUSTOMIZED_TRAIN = 'Customized configuration';
const DEFAULT_TRAIN = 'Default configuration';

const MODEL_NAME_WIDTH = 160;

const useStyles = makeStyles(theme => ({
  configurationTableTitle: {
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    width: 120,
  },
  configurationTableContent: {
    color: theme.palette.common.white,
    padding: theme.spacing(1),
  },
  configurationTooltip: {
    maxWidth: 400,
  },
  clickableCellRoot: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(4),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.greyModern[50],
    },
  },
  modelName: {
    maxWidth: MODEL_NAME_WIDTH,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.greyModern[900],
  },
  modelConfigType: {
    color: theme.palette.greyModern[600],
    fontWeight: 'bold',
  },
  inProgressChip: {
    width: 84,
    minWidth: 84,
    textAlign: 'center',
    backgroundColor: '#F4F8FE',
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 1),
    fontWeight: 700,
    fontSize: 14,
    margin: theme.spacing(0, 2, 0, 3),
    borderRadius: 4,
    height: 19,
    lineHeight: '19px',
  },
}));

export type ModelNameAndConfigurationTableCellProps = {
  model: RegisteredModel;
  datasetVersionName: string;
  onCellClick?: () => void;
} & ModelAnalysisTableCellProps;

const ConfigurationTooltip = (props: {
  datasetVersionName: string;
  modelArch: ModelArch;
  hyperParams?: HyperParams;
  augmentationSchema: TransformUISchema;
  archName?: string;
}) => {
  const { hyperParams, datasetVersionName, modelArch, augmentationSchema, archName } = props;
  const styles = useStyles();
  const rows = [{ title: t('Trained from'), content: datasetVersionName }];
  const epochs = hyperParams?.model.learningParams.epochs;
  const modelSize = hyperParams?.model.backboneParams.name;
  const transformMap: Record<string, any> =
    (hyperParams?.preprocessingConfig &&
      hyperParams?.preprocessingConfig.reduce((acc: Record<string, any>, cur: any) => {
        return { ...acc, ...cur };
      }, {} as Record<string, any>)) ??
    {};
  const resize = transformMap['Resize'];
  const rescaleWithPadding = transformMap['RescaleWithPadding'];

  const augmentationConfig = hyperParams?.augmentationConfig;
  epochs !== undefined && rows.push({ title: t('Epoch'), content: `${epochs}` });
  modelSize &&
    rows.push({
      title: t('Model size'),
      content: t(`{{modelArch}}`, {
        modelArch: archName ?? getModelArchModelSizeDisplayName(modelArch, modelSize),
      }),
    });
  resize &&
    rows.push({
      title: t('Transform'),
      content: t(`Resize {{height}} x {{width}}`, { height: resize.height, width: resize.width }),
    });
  rescaleWithPadding &&
    rows.push({
      title: t('Transform'),
      content: t(`Rescale {{height}} x {{width}}`, {
        height: rescaleWithPadding.height,
        width: rescaleWithPadding.width,
      }),
    });
  augmentationConfig &&
    augmentationConfig.length > 0 &&
    rows.push({
      title: t('Augmentation'),
      content:
        augmentationConfig.length > 1
          ? t(`{{firstKey}} and {{moreCount}} more`, {
              firstKey: augmentationSchema[Object.keys(augmentationConfig[0])[0]].label,
              moreCount: augmentationConfig.length - 1,
            })
          : t(`{{firstKey}}`, {
              firstKey: augmentationSchema[Object.keys(augmentationConfig[0])[0]].label,
            }),
    });

  return (
    <Box display="table">
      {rows.map(row => (
        <Box display="table-row" key={row.title}>
          <Box display="table-cell">
            <Typography variant="body_medium" className={styles.configurationTableTitle}>
              {row.title}
            </Typography>
          </Box>
          <Box display="table-cell">
            <Typography
              display="inline"
              variant="body_regular"
              className={styles.configurationTableContent}
            >
              {row.content}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const ModelNameAndConfigurationTableCell: React.FC<
  ModelNameAndConfigurationTableCellProps
> = props => {
  const styles = useStyles();
  const { model, datasetVersionName, onCellClick, ...otherProps } = props;
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const { data: jobDetails, isLoading } = useJobDetailForCurrentProject(model?.id);
  const { data: modelStatusRes } = useModelStatusQuery(projectId, model?.id);
  const { status: modelStatus, metricsReady } = modelStatusRes ?? {};
  const isTrainingInProgress = isModelTrainingInProgress(modelStatus, metricsReady);

  // Since TRANSFORMS_UI_SCHEMA is a global object, here we only display the label, no modify
  // If need to change its content, please use useState or jotai
  const transformsUiSchema = cloneDeep(TRANSFORMS_UI_SCHEMA);

  const cellContent = (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      className={styles.clickableCellRoot}
      onClick={onCellClick}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        height="100%"
        minWidth={MODEL_NAME_WIDTH}
      >
        <Tooltip placement="top" arrow={true} title={model.modelName || 'Untitled model'}>
          <Box>
            <Typography className={styles.modelName} variant="body_regular">
              {model.modelName}
            </Typography>
          </Box>
        </Tooltip>
        {isLoading ? (
          <Skeleton variant="text" width={40} />
        ) : (
          jobDetails && (
            <Tooltip
              placement="right"
              classes={{ tooltip: styles.configurationTooltip }}
              title={
                <ConfigurationTooltip
                  modelArch={jobDetails.modelArch as ModelArch}
                  hyperParams={jobDetails.hyperParams}
                  datasetVersionName={datasetVersionName}
                  augmentationSchema={transformsUiSchema[2]}
                  archName={jobDetails.archName}
                />
              }
              arrow={true}
            >
              <Box>
                <Typography variant="body_small" className={styles.modelConfigType}>
                  {jobDetails.hyperParams?.model.regime === CUSTOMIZED_REGIME
                    ? CUSTOMIZED_TRAIN
                    : DEFAULT_TRAIN}
                </Typography>
              </Box>
            </Tooltip>
          )
        )}
      </Box>
      {isTrainingInProgress && <div className={styles.inProgressChip}>{t('In Progress')}</div>}
    </Box>
  );

  return (
    <StyledTableCell key={`cell-${model.id}`} {...otherProps}>
      {cellContent}
    </StyledTableCell>
  );
};

export default ModelNameAndConfigurationTableCell;

import { IconButton } from '@clef/client-library';
import React, { useMemo } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { MetaTitle } from './components/Title';
import Examples from '../Auth/Examples';

export const useStyles = makeStyles(theme => ({
  backButton: {
    color: theme.palette.primary.main,
    position: 'absolute',
    left: theme.spacing(8),
    top: theme.spacing(8),
    zIndex: 1,
  },
}));

export interface AuthPageLayoutProps {
  metaTitle?: string;
  showExamples?: boolean;
  onBack?: () => void | Promise<void>;
}

export const AuthPageLayout: React.FC<AuthPageLayoutProps> = ({
  metaTitle,
  showExamples = false,
  onBack,
  children,
}) => {
  const styles = useStyles();

  const content = useMemo(() => {
    return (
      <>
        {metaTitle && <MetaTitle title={metaTitle} />}

        {onBack && (
          <IconButton id="go-back" className={styles.backButton} onClick={onBack}>
            <ArrowBackIcon />
          </IconButton>
        )}

        {children}
      </>
    );
  }, [children, metaTitle, onBack, styles.backButton]);

  return (
    <Box minWidth="100vw" minHeight="100vh">
      {showExamples ? <Examples>{content}</Examples> : content}
    </Box>
  );
};

export default AuthPageLayout;

import React, { useState } from 'react';
import { Grid, Collapse, Typography } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import { useUploadStyles } from '../styles';
import ExpandLessRounded from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRounded from '@material-ui/icons/ExpandMoreRounded';
import PreviewMedia from './PreviewMedia';
import { unassignedClassName } from '../../../../store/uploadState';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { MAX_PREVIEW_IMAGES } from '../constants';
import MaxPreviewImagesIcon from './MaxPreviewImagesIcon';

const PreviewMediaSection: React.FC<{ name: string }> = ({ name }) => {
  const [sectionOpen, setSectionOpen] = useState(true);
  const uploadData = useTypedSelector(state => state.uploadState.uploadData);
  const classifiedFileInThisSection = uploadData.filter(
    uploadFile => uploadFile.classifiedFolder === name,
  );
  const styles = useUploadStyles();
  return (
    <section className={styles.classifiedPreviewSection} data-testid="upload-preview">
      <Grid container alignItems="center" onClick={() => setSectionOpen(prev => !prev)}>
        <IconButton id="toggle-preview-media-section" size="small">
          {sectionOpen ? <ExpandLessRounded /> : <ExpandMoreRounded />}
        </IconButton>
        <Typography variant="body1">
          {name === unassignedClassName ? (
            <span className={styles.italic}>{name}</span>
          ) : (
            <strong>{name}</strong>
          )}
          <MaxPreviewImagesIcon imageCount={classifiedFileInThisSection.length} />
        </Typography>
        <div className={styles.flexGrow} />
        <Typography variant="body1">
          {t('{{length}} image(s)', {
            length: <strong>{classifiedFileInThisSection.length}</strong>,
          })}
        </Typography>
      </Grid>
      <Collapse in={sectionOpen}>
        <div className={styles.previewImgRow}>
          {classifiedFileInThisSection.slice(0, MAX_PREVIEW_IMAGES).map(uploadFile => (
            <PreviewMedia uploadFile={uploadFile} key={uploadFile.key} />
          ))}
        </div>
      </Collapse>
    </section>
  );
};

export default PreviewMediaSection;

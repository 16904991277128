import React, { useState, useCallback } from 'react';

export const useMenu = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [menuRef, setMenuRef] = useState<HTMLElement | null>(null);

  const onMenuOpen = useCallback((e: React.MouseEvent<HTMLElement>): void => {
    setMenuRef(e.currentTarget);
    setOpen(true);
  }, []);

  const onMenuClose = useCallback(() => {
    setOpen(false);
    setMenuRef(null);
  }, []);

  return {
    open,
    setOpen,
    menuRef,
    onMenuOpen,
    onMenuClose,
  };
};

import React from 'react';
import cx from 'classnames';
import { useLabelingTaskStyles } from './labelingTaskStyles';
import { Typography } from '@material-ui/core';
import { useLabelingTaskState } from './labelingTaskState';
import TaskMediaPreview from './components/TaskMediaPreview';

export interface LabelingTaskMediaListProps {}

const LabelingTaskMediaList: React.FC<LabelingTaskMediaListProps> = () => {
  const styles = useLabelingTaskStyles();
  const {
    state: { taskMediaList },
  } = useLabelingTaskState();

  return (
    <section className={cx(styles.panelContainer, styles.leftPanelContainer)}>
      <div className={cx(styles.panelSection, styles.panelSectionSticky)}>
        <Typography variant="subtitle1">{t('Media in this batch')}</Typography>
      </div>
      <div className={styles.panelSection}>
        {taskMediaList.map(taskMedia => {
          return <TaskMediaPreview taskMedia={taskMedia} key={taskMedia.id} />;
        })}
      </div>
    </section>
  );
};

export default LabelingTaskMediaList;

import React, { useCallback, useState } from 'react';
import { CircularProgress, Dialog, DialogContent, makeStyles } from '@material-ui/core';
import { SubscriptionName, UpgradePlanNextAction } from '@clef/shared/types';
import { Button } from '@clef/client-library';
import classNames from 'classnames';
import { HubspotContactForm } from '@/components/Dialogs/UpgradeDialog';
import subscription_api from '@/api/subscription_api';
import CLEF_PATH from '@/constants/path';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { useStripePromise } from '@/utils/stripe_utils';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';

const useStyles = makeStyles(theme => ({
  planButton: {
    height: 40,
    '&.free': {
      backgroundColor: theme.palette.yellow[300],
    },
    '&.visionary': {
      backgroundColor: theme.palette.blue[600],
      color: 'white',
    },
    '&.enterprise': {
      backgroundColor: theme.palette.purple[600],
      color: 'white',
    },
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: 'white',
      color: theme.palette.greyModern[800],
      border: `1px solid ${theme.palette.greyModern[300]}`,
    },
  },
  contactUsDialogContent: {
    width: 600,
  },
  paymentDialogPaper: {
    maxWidth: 'unset!important',
    width: 1000,
    padding: theme.spacing(9, 0),
    backgroundColor: '#f6f9fe',
  },
}));

export type PlanButtonProps = {
  plan: SubscriptionName;
  isCurrent?: boolean;
  toBeCanceled?: boolean;
};

const PlanButton: React.FC<PlanButtonProps> = props => {
  const { plan, isCurrent = false, toBeCanceled = false } = props;
  const styles = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  // Stripe related
  const stripePromise = useStripePromise();
  const [clientSecret, setClientSecret] = useState('');
  const [loadingClientSecret, setLoadingClientSecret] = useState(false);
  const stripeOptions = { clientSecret };

  // Contact us related
  const [contactUsDialog, setContactUsDialog] = useState(false);
  const onContactUs = useCallback(() => {
    setContactUsDialog(true);
  }, []);

  let button = null;
  if (plan === SubscriptionName.Free) {
    button = (
      <Button
        id="select-free-plan"
        variant="contained"
        fullWidth
        className={classNames(styles.planButton, plan)}
        disabled={isCurrent}
        onClick={() => {
          history.push(CLEF_PATH.cancelPlan);
        }}
      >
        {isCurrent ? t('Current plan') : t('Downgrade')}
      </Button>
    );
  }
  if (plan === SubscriptionName.Visionary) {
    let buttonText = t('Upgrade');
    if (isCurrent) {
      buttonText = toBeCanceled ? t('Resume') : t('Current plan');
    }
    button = (
      <Button
        id="select-visionary-plan"
        variant="contained"
        fullWidth
        className={classNames(styles.planButton, plan)}
        disabled={(isCurrent && !toBeCanceled) || loadingClientSecret}
        startIcon={loadingClientSecret && <CircularProgress size={16} color="inherit" />}
        onClick={async () => {
          setLoadingClientSecret(true);
          try {
            const { data } = await subscription_api.upgradePlan();
            if (data.nextAction === UpgradePlanNextAction.Pay) {
              setClientSecret(data.clientSecret);
            } else if (data.nextAction === UpgradePlanNextAction.Success) {
              history.push(CLEF_PATH.organizationSettings);
            }
          } catch (e) {
            enqueueSnackbar(e.message);
          }
          setLoadingClientSecret(false);
        }}
      >
        {buttonText}
      </Button>
    );
  }
  if (plan === SubscriptionName.Enterprise) {
    button = (
      <Button
        id="select-enterprise-plan"
        variant="contained"
        fullWidth
        className={classNames(styles.planButton, plan)}
        onClick={onContactUs}
      >
        {t('Contact Us')}
      </Button>
    );
  }
  return (
    <>
      {/* plan button */}
      {button}

      {/* Stripe payment dialog */}
      <Dialog
        open={!!clientSecret}
        onClose={() => setClientSecret('')}
        classes={{ paper: styles.paymentDialogPaper }}
      >
        <EmbeddedCheckoutProvider stripe={stripePromise} options={stripeOptions}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </Dialog>

      {/* Contact us dialog */}
      <Dialog
        open={contactUsDialog}
        onClose={() => {
          setContactUsDialog(false);
        }}
      >
        <DialogContent className={styles.contactUsDialogContent}>
          <HubspotContactForm />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PlanButton;

import React, { useState } from 'react';
import { CircularProgress, MenuItem, MenuList, Popover, makeStyles } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import MoreVert from '@material-ui/icons/MoreVert';
import { ApiKey, ApiKeyStatus } from '@clef/shared/types';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useSnackbar } from 'notistack';
import { accountApiWithAuth } from '@/api/account_api';
import EditKeyNameDialog from './EditKeyNameDialog';
import { refreshApiKeyListApi } from '@/hooks/api/useOrganizationApi';
import { useDialog } from '../Layout/components/useDialog';

const useStyles = makeStyles(theme => ({
  iconButton: {
    margin: theme.spacing(-3, 0),
  },
}));

export type MoreOptionsProps = {
  apiKey: ApiKey;
};

const MoreOptions: React.FC<MoreOptionsProps> = ({ apiKey }) => {
  const styles = useStyles();
  const popupState = usePopupState({
    popupId: 'api-key-more-options-menu',
    variant: 'popover',
  });
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [showEditNameDialog, setShowEditNameDialog] = useState(false);
  const { showConfirmationDialog } = useDialog();
  return (
    <>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <IconButton
          id="api-key-more-options"
          className={styles.iconButton}
          {...bindTrigger(popupState)}
        >
          <MoreVert />
        </IconButton>
      )}
      <Popover {...bindPopover(popupState)}>
        <MenuList>
          <MenuItem
            onClick={() => {
              setShowEditNameDialog(true);
              popupState.close();
            }}
          >
            {t('Edit Key Name')}
          </MenuItem>
          <MenuItem
            onClick={async () => {
              setLoading(true);
              popupState.close();
              try {
                await accountApiWithAuth.updateApiKey({
                  ...apiKey,
                  status:
                    apiKey.status === ApiKeyStatus.ACTIVE
                      ? ApiKeyStatus.REVOKED
                      : ApiKeyStatus.ACTIVE,
                });
                refreshApiKeyListApi({ keys: 'refresh-all', swr: true });
                enqueueSnackbar(
                  apiKey.status === ApiKeyStatus.ACTIVE
                    ? t('Successfully revoked')
                    : t('Successfully activated'),
                  { variant: 'success' },
                );
              } catch (e) {
                enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
              }
              setLoading(false);
            }}
          >
            {apiKey.status === ApiKeyStatus.ACTIVE ? t('Revoke') : t('Activate')}
          </MenuItem>
          {apiKey.status === ApiKeyStatus.REVOKED && (
            <MenuItem
              onClick={() => {
                showConfirmationDialog({
                  title: t('Are you sure you want to delete your API credentials?'),
                  content: t(
                    'Deleting your API key will interrupt any connected services. This action is irreversible. Are you sure you want to continue?',
                  ),
                  confirmText: t('Delete'),
                  color: 'secondary',
                  onConfirm: async () => {
                    setLoading(true);
                    popupState.close();
                    try {
                      await accountApiWithAuth.deleteApiKey(apiKey.id);
                      refreshApiKeyListApi({ keys: 'refresh-all', swr: true });
                      enqueueSnackbar(t('Successfully deleted'), { variant: 'success' });
                    } catch (e) {
                      enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
                    }
                    setLoading(false);
                  },
                });
              }}
            >
              {t('Delete')}
            </MenuItem>
          )}
        </MenuList>
      </Popover>
      {showEditNameDialog && (
        <EditKeyNameDialog apiKey={apiKey} onClose={() => setShowEditNameDialog(false)} />
      )}
    </>
  );
};

export default MoreOptions;

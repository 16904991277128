import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Popover, List, ListItem, ListItemText, makeStyles, Tooltip } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

import {
  DatasetVersion,
  MediaStatusType,
  DatasetGroupOptions,
  LabelType,
} from '@clef/shared/types';
import { IconButton } from '@clef/client-library';
import { isProjectOwner } from '@clef/shared/utils';
import CLEF_PATH from '@/constants/path';

import { defaultSelectOptions } from '@/constants/data_browser';
import {
  MIN_LABELED_MEDIA_FOR_FAST_N_EASY_TRAIN,
  MIN_CLASSIFICATION_DEFECTS_FOR_FAST_N_EASY_TRAIN,
} from '@/constants/model_train';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import {
  useGetSelectedProjectQuery,
  useGetProjectVersionedDefectsQuery,
} from '@/serverStore/projects';
import { useDatasetMediaCountQuery, useGetDatasetStatsQuery } from '@/serverStore/dataset';
import { useGetModelArchSchemas } from '@/serverStore/train';
import CloneProjectDialog from '@/components/Dialogs/SnapshotRelatedDialog/CloneProjectDialog';

import RestoreDialog from './RestoreDialog';

const useStyles = makeStyles(theme => ({
  mainUse: {
    padding: '10px 0 10px 8px',
    color: '#fff',
    backgroundColor: theme.palette.purple[600],
    display: 'flex',
    height: 40,
    width: 80,
    fontSize: 14,
    fontWeight: 700,
    alignItems: 'center',
    borderRadius: 10,
    '&:hover': {
      backgroundColor: theme.palette.purple[700],
    },
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  red: {
    color: theme.palette.error.main,
  },
}));

interface SnapshotMainUseProps {
  snapshot: DatasetVersion;
}

const SnapshotMainUse: React.FC<SnapshotMainUseProps> = ({ snapshot }) => {
  const history = useHistory();
  const styles = useStyles();
  const { version } = snapshot;

  const { labelType, users: activeUsers } = useGetSelectedProjectQuery().data ?? {};

  const { data: versionedDefects } = useGetProjectVersionedDefectsQuery(version);

  const { data: modelArchSchemas } = useGetModelArchSchemas();

  const { data: labeledMediaCount, isLoading: labeledMediaCountLoading } =
    useDatasetMediaCountQuery({
      version,
      selectOptions: {
        fieldFilterMap: {},
        columnFilterMap: {
          datasetContent: { mediaStatus: { CONTAINS_ANY: [MediaStatusType.Approved] } },
        },
        selectedMedia: [],
        unselectedMedia: [],
        isUnselectMode: true,
      },
    });

  const { data: mediaGroupBySplit } = useGetDatasetStatsQuery({
    selectOptions: defaultSelectOptions,
    groupOptions: [DatasetGroupOptions.SPLIT],
    version: version,
  });

  const enoughMediasForTrain = useMemo(() => {
    if (!modelArchSchemas || !labeledMediaCount || !mediaGroupBySplit) return false;
    if (labeledMediaCount < modelArchSchemas[0]?.datasetLimits.minLabeledMedia) return false;
    return true;
  }, [modelArchSchemas, labeledMediaCount, mediaGroupBySplit]);

  const isEnoughMediasInTrainSplit = useMemo(() => {
    if (!mediaGroupBySplit) return false;
    const trainSplit = mediaGroupBySplit.find(split => split.split === 'train');

    if (!trainSplit || trainSplit?.count < 2) {
      return false;
    }
    return true;
  }, [mediaGroupBySplit]);

  const currentUser = useTypedSelector(state => state.login.user);
  const projectOwner = activeUsers?.find(user => isProjectOwner(user.userProject.role));

  const [openRevertDialog, setOpenRevertDialog] = useState(false);
  const [openCloneProjectDialog, setOpenCloneProjectDialog] = useState(false);

  const invalidClassificationTrain =
    labelType === LabelType.Classification
      ? !!versionedDefects &&
        versionedDefects.length < MIN_CLASSIFICATION_DEFECTS_FOR_FAST_N_EASY_TRAIN
      : false;

  return (
    <>
      <PopupState variant="popover" popupId="snapshot-page-more-action-popup-popover">
        {popupState => (
          <>
            <IconButton
              id="snapshot-page-more-action"
              className={styles.mainUse}
              {...bindTrigger(popupState)}
            >
              {t('Use')}
              <KeyboardArrowDownIcon className={styles.icon} />
            </IconButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <List>
                <Tooltip
                  arrow
                  placement="top"
                  disableHoverListener={enoughMediasForTrain && !invalidClassificationTrain}
                  title={
                    ((!enoughMediasForTrain || !isEnoughMediasInTrainSplit) &&
                      t(`Need at least {{minCount}} labeled assigned images to train a model.`, {
                        minCount:
                          modelArchSchemas?.[0].datasetLimits.minLabeledMedia ??
                          MIN_LABELED_MEDIA_FOR_FAST_N_EASY_TRAIN,
                      })) ||
                    (invalidClassificationTrain &&
                      t('Classification projects should contain at least two classes created')) ||
                    ''
                  }
                >
                  {/* wrap with a span so that the tooltip will not hide when button disabled */}
                  <span>
                    <ListItem
                      button
                      id="snapshot-train-btn"
                      onClick={() => {
                        history.push(CLEF_PATH.data.customTraining + '?version=' + version);
                        popupState.close();
                      }}
                      disabled={
                        labeledMediaCountLoading ||
                        !enoughMediasForTrain ||
                        !isEnoughMediasInTrainSplit ||
                        invalidClassificationTrain
                      }
                    >
                      <ListItemText primary={t('Train with custom options')} />
                    </ListItem>
                  </span>
                </Tooltip>

                <ListItem
                  button
                  id="snapshot-clone-btn"
                  onClick={() => {
                    setOpenCloneProjectDialog(true);
                    popupState.close();
                  }}
                >
                  <ListItemText primary={t('Create new project')} />
                </ListItem>

                {currentUser?.id === projectOwner?.id && (
                  <ListItem
                    button
                    id="snapshot-revert-btn"
                    onClick={() => {
                      setOpenRevertDialog(true);
                      popupState.close();
                    }}
                  >
                    <ListItemText primary={t('Revert to this snapshot')} className={styles.red} />
                  </ListItem>
                )}
              </List>
            </Popover>
          </>
        )}
      </PopupState>

      {openRevertDialog && (
        <RestoreDialog snapshot={snapshot} onClose={() => setOpenRevertDialog(false)} />
      )}
      {openCloneProjectDialog && (
        <CloneProjectDialog version={version} onClose={() => setOpenCloneProjectDialog(false)} />
      )}
    </>
  );
};

export default SnapshotMainUse;

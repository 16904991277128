import { SubscriptionName } from '@clef/shared/types';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HubspotContactForm } from '../../../components/Dialogs/UpgradeDialog';
import { defaultSelectOptions } from '@/constants/data_browser';
import CLEF_PATH from '../../../constants/path';
import { useCurrentSubscription, useQuotaAndUsageInfo } from '../../../hooks/useSubscriptions';
import blueMegaphoneSvg from '../../../images/megaphone_blue.svg';
import { PlanPageContext, usePlanPageState } from '../../plan/state';
import { useDatasetMediaCountQuery } from '@/serverStore/dataset';

const REACH_LIMIT_DIALOG_Z_INDEX = 300002;
const CONTACT_US_DIALOG_Z_INDEX = REACH_LIMIT_DIALOG_Z_INDEX + 1;

interface ReachLimitModelDialogProps extends DialogProps {
  projectId: number;
  datasetId: number;
  onClose?: (open: boolean) => void;
  onPredicting?: boolean;
  onPredictingMediaCount?: number;
}

const useStyles = makeStyles(theme => ({
  dialogContent: {
    width: 563,
    height: 'auto',
    overflow: 'initial',
    margin: `0 ${theme.spacing(1)}`,
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    color: theme.palette.grey[900],
    textAlign: 'center',
  },
  tierUpgradeTitle: {
    marginTop: theme.spacing(6),
  },
  quoteUpgradeTitle: {
    marginTop: theme.spacing(4),
  },
  actions: {
    width: '100%',
  },
  actionItem: {
    flex: 1,
  },
  footer: {
    height: 38,
    backgroundColor: theme.palette.blue[25],
  },
  notNow: {
    borderColor: theme.palette.blue[600],
  },
  contactUs: {
    backgroundColor: theme.palette.blue[600],
  },
  planTo: {
    color: theme.palette.blue[600],
    fontWeight: 700,
  },
  label: {
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'center',
  },
  emphasis: {
    display: 'inline',
    fontWeight: 700,
  },
  sectionSubTitle: {
    fontSize: 12,
    color: theme.palette.grey[500],
  },
  creditsValue: {
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.grey[800],
    verticalAlign: 'middle',
  },
  priceSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(6),
  },
}));

export const ReachLimitModelDialogV1: React.FC<ReachLimitModelDialogProps> = props => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const contextValue = usePlanPageState();
  const { dispatch } = contextValue;
  const currentSubscription = useCurrentSubscription();
  const history = useHistory();

  const [contactUsDialog, setContactUsDialog] = useState(false);
  const { quotaUsage } = useQuotaAndUsageInfo();
  const { data: datasetMediaCount } = useDatasetMediaCountQuery({
    projectId: props.projectId,
    datasetId: props.datasetId,
    selectOptions: defaultSelectOptions,
  });
  const imageUsage = props.onPredicting ? props.onPredictingMediaCount : datasetMediaCount;

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    dispatch(draft => {
      draft.plan = currentSubscription?.meta.plan as SubscriptionName;
    });
  }, [currentSubscription?.meta.plan, dispatch]);

  const canUpgrade = [SubscriptionName.Freemium, SubscriptionName.Starter].includes(
    currentSubscription?.meta.plan as SubscriptionName,
  );

  return (
    <Dialog style={{ zIndex: REACH_LIMIT_DIALOG_Z_INDEX }} {...{ ...props, open }}>
      <PlanPageContext.Provider value={contextValue}>
        <>
          <DialogContent className={styles.dialogContent}>
            <Box flexDirection="column" justifyContent="center" alignItems="center" display="flex">
              <img src={blueMegaphoneSvg} alt="" />
              <Typography
                className={classNames(styles.title, styles.tierUpgradeTitle)}
                variant="h4"
              >
                {t("Uh-oh! You don't have enough credits")}
              </Typography>
            </Box>
            <Box marginTop={6}>
              {
                <Typography>
                  {t(
                    `${
                      props.onPredicting
                        ? 'Making inferences on these images'
                        : 'Training this model'
                    } will cost {{credits}} credits which exceeds your {{creditLimit}} credit limit.`,
                    {
                      credits: (
                        <Typography className={styles.emphasis}>{imageUsage || 0}</Typography>
                      ),
                      creditLimit: (
                        <Typography className={styles.emphasis}>{quotaUsage || 0}</Typography>
                      ),
                    },
                  )}
                </Typography>
              }
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              className={styles.actions}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                id="not-now"
                className={classNames(styles.actionItem, styles.notNow)}
                variant="outlined"
                color="primary"
                onClick={() => {
                  props.onClose?.(false);
                  setOpen(false);
                }}
              >
                {t('Not now')}
              </Button>
              <Box marginX={1}></Box>
              <Button
                id={canUpgrade ? 'increase-credit-limit' : 'contact-us-for-more-credits'}
                className={classNames(styles.actionItem, styles.contactUs)}
                variant="contained"
                color="primary"
                onClick={async () => {
                  if (canUpgrade) {
                    history.push(CLEF_PATH.plan);
                  } else {
                    setContactUsDialog(true);
                  }
                }}
              >
                {canUpgrade ? t('Upgrade Plan') : t('Contact us for more credits')}
              </Button>
            </Box>
          </DialogActions>
          <Dialog
            style={{ zIndex: CONTACT_US_DIALOG_Z_INDEX }}
            open={contactUsDialog}
            onClose={() => {
              setContactUsDialog(false);
            }}
          >
            <DialogContent className={styles.dialogContent}>
              <HubspotContactForm />
            </DialogContent>
          </Dialog>
        </>
      </PlanPageContext.Provider>
    </Dialog>
  );
};

import React from 'react';
import { MenuItem, MenuList } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import { supportedLanguages, LanguageCache } from '@/i18n';
import TranslateRounded from '@material-ui/icons/TranslateRounded';
import cx from 'classnames';
import useStyles from './styles';
import { Popper } from '@clef/client-library';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';

export type LanguageSwitchProps = {};

const LanguageSwitch: React.FC<LanguageSwitchProps> = () => {
  const oldStyles = useStyles();

  const enableLanguageSwitch = useFeatureGateEnabled(ClientFeatures.EnableLanguageSwitch);
  if (!enableLanguageSwitch) {
    return null;
  }

  return (
    <Popper
      dropdown={() => (
        <MenuList autoFocusItem>
          {supportedLanguages.map(lang => {
            return (
              <MenuItem
                key={lang.id}
                onClick={() => {
                  LanguageCache.set(lang.id);
                  window.location.reload();
                }}
                selected={lang.id === LanguageCache.get()}
                className={cx(oldStyles.headerMenuItem, oldStyles.link)}
                classes={{
                  selected: oldStyles.linkSelected,
                }}
              >
                {lang.name}
              </MenuItem>
            );
          })}
        </MenuList>
      )}
    >
      <IconButton
        id="select-language"
        className={cx(oldStyles.headerRightButton)}
        aria-label="select-language"
      >
        <TranslateRounded classes={{ root: oldStyles.headerIcon }} fontSize="small" />
      </IconButton>
    </Popper>
  );
};

export default LanguageSwitch;

export enum SkipDialogKey {
  Upload = 'skipUploadDialog',
  DownloadBundle = 'skipDownloadBundleDialog',
}

export const includesSkipDialogById = (key: SkipDialogKey, id: number) => {
  const params = window.localStorage.getItem(key) || '[]';
  return (JSON.parse(params) as number[]).includes(id);
};

export const addSkipDialogWithId = (key: SkipDialogKey, id: number) => {
  const params = window.localStorage.getItem(key) || '[]';
  const data = JSON.parse(params) as number[];
  data.push(id);
  window.localStorage.setItem(key, JSON.stringify(data));
};

import { SSE_WINDOW_EVENT_KEY } from '@/constants';
import { SSECustomEventPayload } from '@/types/event';
import { EventLogger } from '@clef/client-library';
import { useEffect, useMemo, useRef, useState } from 'react';

export class SSEError extends Error {
  errorEvent?: Event;
  constructor(errorEvent?: Event) {
    super();
    this.errorEvent = errorEvent;
    this.name = 'SSEError';
  }
}

const RETRY_DELAY_MS = 1000;
const MAX_RETRY_DELAY_MS = 30 * 1000;

// Get delay time with exponential back off policy
// E.g. if initial delay is 1s, max delay is 30s, and our exponentialFactor is 2,
// the returned delay time is as below:
//
// attempt | delay
// --------|---------
//    0    | 1s
//    1    | 2s
//    2    | 4s
//    3    | 8s
//    4    | 16s
//    5    | 30s (reach max delay)
//    6    | 30s (reach max delay)
const getDelayMs = (attamptCount: number) => {
  const exponentialFactor = 2;
  const factor = exponentialFactor ** attamptCount;
  return Math.min(MAX_RETRY_DELAY_MS, RETRY_DELAY_MS * factor);
};

/**
 * call this only once in the top level.
 * call useSSEEventListener to listen to event.
 */
export const useSSESubscription = (enable: boolean = true, userId?: string) => {
  // The value is only for force triggering useEffect
  const [forceUpdateFlag, forceUpdate] = useState(false);
  const retryTimesRef = useRef(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (enable && 'EventSource' in window) {
      const handleMessage = (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        const { payload, action } = data.content;
        const sseEvent = new CustomEvent<SSECustomEventPayload>(SSE_WINDOW_EVENT_KEY, {
          detail: { payload, action },
        });
        window.dispatchEvent(sseEvent);
      };

      let retryTimerId: ReturnType<typeof setTimeout> | undefined;
      const handleError = (errorEvent: Event) => {
        const error = new SSEError(errorEvent);
        EventLogger.error(error, { errorEvent }, ['SSEError']);

        // Force create a new subscription
        if (retryTimesRef.current === 0) {
          forceUpdate(prev => !prev);
        } else {
          retryTimerId && clearTimeout(retryTimerId);
          retryTimerId = setTimeout(() => {
            forceUpdate(prev => !prev);
          }, getDelayMs(retryTimesRef.current));
        }
        retryTimesRef.current++;
      };

      const handleOpen = () => {
        setLoading(false);
      };

      // NOTE
      // If EventSource does not work with your Webpack dev server, we shall connect to
      // dev's event service directly.
      // https://github.com/chimurai/http-proxy-middleware/issues/678
      setLoading(true);
      const url = userId ? `/vela/v1/subscribe?user_id=${userId}` : `/vela/v1/subscribe`;
      const source = new EventSource(url, { withCredentials: true });

      const closeSession = () => {
        source.close();
      };
      window.addEventListener('beforeunload', closeSession);
      window.addEventListener('reload', closeSession);
      source.addEventListener('message', handleMessage, false);
      source.addEventListener('error', handleError);
      source.addEventListener('open', handleOpen);

      return () => {
        window.removeEventListener('beforeunload', closeSession);
        window.removeEventListener('reload', closeSession);
        source.removeEventListener('message', handleMessage);
        source.removeEventListener('error', handleError);
        source.removeEventListener('open', handleOpen);
        source.close();
        if (retryTimerId) {
          clearTimeout(retryTimerId);
        }
      };
    }
    if (!enable) {
      retryTimesRef.current = 0;
    }
    return;
  }, [forceUpdateFlag, enable, userId]);

  return useMemo(() => ({ loading }), [loading]);
};

/**
 * Please make sure useSSESubscription is called at the top level component.
 */
export const useSSEEventListener = (
  eventListener: (event: CustomEvent<SSECustomEventPayload>) => void,
) => {
  const eventListenerRef = useRef(eventListener);
  eventListenerRef.current = eventListener;
  useEffect(() => {
    const handler = ((event: CustomEvent<SSECustomEventPayload>) =>
      eventListenerRef.current(event)) as EventListener;

    window.addEventListener(SSE_WINDOW_EVENT_KEY, handler);
    return () => {
      window.removeEventListener(SSE_WINDOW_EVENT_KEY, handler);
    };
  }, []);
};

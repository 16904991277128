import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { useLabelingReviewStyles } from '../labelingReviewStyles';
import {
  ClassChip,
  ClassifiedClass,
  MediaInteractiveCanvas,
  useKeyPress,
} from '@clef/client-library';
import { Typography, Grid, Tooltip, Box, Dialog, DialogContent } from '@material-ui/core';
import { Button } from '@clef/client-library';
import {
  LabelReviewStatus,
  LabelingType,
  ReviewType,
  LabelSource,
  Label,
  Annotation,
} from '@clef/shared/types';
import { useLabelingReviewState, useGoToNextMedia } from '../labelingReviewState';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import AddToDefectSecBtn from '../../../components/DefectBook/AddToDefectSecBtn';
import MetadataMenu from '../../LabelingTask/components/MetadataMenu';
import ReviewNotes, { ReviewNotesActions } from './ReviewNotes';
import LabelingReviewEdit from './LabelingReviewEdit';
import EditIcon from '@material-ui/icons/Edit';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import MediaCanvasWrapper from '../../../components/Labeling/MediaCanvasWrapper';
import { useDefectSelector } from '../../../store/defectState/actions';
import { serverAnnotationsToLabelingAnnotations } from '../../../components/Labeling/utils';
import {
  BitMapLabelingAnnotation,
  BoxLabelingAnnotation,
  useLabelingState,
} from '../../../components/Labeling/labelingState';
import { getClassifiedClass } from '../../../utils';

export interface LabelingReviewMainPanelProps {
  labelingType?: LabelingType;
}

const noop = () => true;

const LabelingReviewMainPanel: React.FC<LabelingReviewMainPanelProps> = ({ labelingType }) => {
  const styles = useLabelingReviewStyles();
  const {
    state: {
      currentMediaId,
      reviewResult,
      isViewResultMode,
      reviewMediaList,
      visibleLabel,
      visibleAnnotation,
      filteredDefect,
      visibleDefect,
    },
    dispatch,
  } = useLabelingReviewState();
  const {
    state: { toolMode },
  } = useLabelingState();
  const search = new URLSearchParams(location.search);
  const type = (search.get('type') as ReviewType) || undefined;
  const currentMedia = reviewMediaList.find(media => media.id === currentMediaId);

  const [openEdit, setOpenEdit] = useState(false);

  const mediaDetails = reviewMediaList.find(media => media.id === currentMediaId)!;

  const goToNextMedia = useGoToNextMedia();

  const reviewStatus = reviewResult[currentMediaId]?.reviewStatus ?? LabelReviewStatus.NotReviewed;
  const reviewNotes = reviewResult[currentMediaId]?.note ?? '';
  const isReviewEdited = reviewResult[currentMediaId]?.editedLabel;

  const isAccepted = reviewStatus === LabelReviewStatus.Accepted;
  const isRejected = reviewStatus === LabelReviewStatus.Rejected;
  // const isPending = reviewStatus === LabelReviewStatus.NotReviewed;

  const acceptButtonDisabled = isViewResultMode && isRejected;
  const rejectButtonDisabled = (isViewResultMode && isAccepted) || isReviewEdited;

  const reviewNotesEditable = !isViewResultMode && !rejectButtonDisabled;

  // const hintText =
  //   (isViewResultMode && isAccepted && viewResultAcceptedHelperText) ||
  //   (isViewResultMode && isRejected && viewResultRejectedHelperText) ||
  //   (!isViewResultMode && isAccepted && inReviewAcceptedReviewHelperText) ||
  //   (!isViewResultMode && isRejected && inReviewRejectedHelperText) ||
  //   (!isViewResultMode && isPending && inReviewPendingReviewHelperText) ||
  //   '';

  const reviewNotesActions = useRef<ReviewNotesActions>();
  const { bestLabelId } = mediaDetails;
  const { selectedLabel } = reviewResult[currentMediaId] ?? {};
  const finalSelectedLabelId = selectedLabel ?? bestLabelId;
  const newLabelId = useRef(Date.now());
  const user = useTypedSelector(state => state.login.user!);
  const mediaInteractiveCanvasRef = useRef<MediaInteractiveCanvas | null>(null);

  const openReviewNotesEditor = useCallback(() => {
    if (reviewNotesEditable) {
      reviewNotesActions.current?.openPopover(true);
    }
  }, [reviewNotesEditable]);

  const acceptReview = useCallback(() => {
    if (!isViewResultMode) {
      dispatch(draft => {
        draft.reviewResult[currentMediaId].reviewStatus = LabelReviewStatus.Accepted;
      });
      goToNextMedia();
    }
  }, [currentMediaId, dispatch, goToNextMedia, isViewResultMode]);
  const rejectReview = useCallback(() => {
    if (!isViewResultMode) {
      dispatch(draft => {
        draft.reviewResult[currentMediaId].reviewStatus = LabelReviewStatus.Rejected;
        draft.reviewResult[currentMediaId].selectedLabel = undefined;
        draft.visibleLabel = undefined;
        draft.visibleDefect = undefined;
        draft.visibleAnnotation = undefined;
      });
      goToNextMedia();
    }
  }, [currentMediaId, dispatch, goToNextMedia, isViewResultMode]);

  useKeyPress('a', () => {
    if (openEdit) {
      return;
    }
    acceptReview();
  });
  useKeyPress('r', e => {
    if (isViewResultMode) {
      return;
    }
    if (openEdit) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    openReviewNotesEditor();
  });

  const AcceptButton = (
    <Button
      variant={isAccepted ? 'contained' : 'outlined'}
      fullWidth
      aria-selected={isAccepted}
      className={cx(isAccepted ? styles.reviewAccept : styles.reviewNotAccept, styles.btn)}
      onClick={acceptReview}
      disabled={acceptButtonDisabled}
      id="labeling-review-accept-btn"
      startIcon={<CheckIcon />}
    >
      {t('Accept')}
    </Button>
  );
  const RejectButton = (
    <Button
      id="labeling-review-reject-btn"
      variant={isRejected ? 'contained' : 'outlined'}
      fullWidth
      aria-selected={isRejected}
      className={cx(isRejected ? styles.reviewReject : styles.reviewNotReject, styles.btn)}
      onClick={openReviewNotesEditor}
      startIcon={<CloseIcon />}
      disabled={rejectButtonDisabled}
    >
      {t('Reject')}
    </Button>
  );

  const selectedMediaLabel = useMemo(() => {
    return mediaDetails.labels.find(label => label.id === finalSelectedLabelId)! || {};
  }, [finalSelectedLabelId, mediaDetails.labels]);

  const onEdit = useCallback(async () => {
    newLabelId.current += 1;
    setOpenEdit(true);
    dispatch(draft => {
      draft.editingLabel = {
        ...selectedMediaLabel,
        id: newLabelId.current,
        source: LabelSource.LabelingReviewEdit,
        labelerName: user.id,
      };
    });
  }, [dispatch, selectedMediaLabel, user.id]);

  const EditButton = useMemo(() => {
    if (type === 'review_ready') {
      const labelCount = currentMedia?.labels.length ?? 0;
      const requireSelectedLabel = isRejected && !selectedLabel && labelCount > 1;
      return (
        <Tooltip title={requireSelectedLabel ? t("Select a labeler's label to edit") : ''} arrow>
          <span className={styles.editBtn}>
            <Button
              id="labeling-review-edit-button"
              variant="outlined"
              color="primary"
              fullWidth
              onClick={onEdit}
              startIcon={<EditIcon />}
              disabled={requireSelectedLabel}
            >
              {t('Edit')}
            </Button>
          </span>
        </Tooltip>
      );
    }
    return null;
  }, [currentMedia, isRejected, onEdit, selectedLabel, styles.editBtn, type]);

  const onCloseEditDialog = useCallback(() => {
    dispatch(draft => {
      draft.editingLabel = undefined;
    });
    setOpenEdit(false);
  }, [dispatch]);
  const defects = useDefectSelector();
  const labelFilter = useCallback(
    (label: Label) => !visibleLabel || visibleLabel === label.id,
    [visibleLabel],
  );

  const annotationFilter = useCallback(
    annotation => {
      return (
        !filteredDefect.includes(annotation.defectId) &&
        (!visibleDefect || annotation.defectId === visibleDefect) &&
        (!visibleAnnotation || String(annotation.id) === visibleAnnotation)
      );
    },
    [filteredDefect, visibleAnnotation, visibleDefect],
  );

  const annotations = !defects
    ? []
    : mediaDetails?.labels
        .filter(labelFilter ?? noop)
        .map(label => {
          const annotations = (label.annotations as Annotation[]).filter(annotation =>
            (annotationFilter ?? noop)(annotation),
          );

          return serverAnnotationsToLabelingAnnotations(annotations) || [];
        })
        .flat();

  const classifiedClass = getClassifiedClass(annotations, defects ?? []);

  const isClassifiedClassComponent = !!classifiedClass && React.isValidElement(classifiedClass);
  const rightCornerComponent =
    (isClassifiedClassComponent && (classifiedClass as ReactElement)) ||
    (!!classifiedClass && <ClassChip classifiedClass={classifiedClass as ClassifiedClass} />) ||
    null;

  return (
    <section
      className={cx(styles.panelContainer, styles.centerPanelContainer)}
      data-testid="labeling-review-main-panel"
    >
      <Grid
        container
        className={styles.centerPanelTop}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Typography variant="h4">{mediaDetails?.name}</Typography>
        <div className={styles.flexGrow} />
      </Grid>

      <div className={styles.centerPanelMedia}>
        {!openEdit && (
          <MediaCanvasWrapper
            isLabelMode={false}
            mediaCanvasRef={mediaInteractiveCanvasRef}
            mediaDetails={currentMedia}
            annotations={
              (annotations || []) as BoxLabelingAnnotation[] | BitMapLabelingAnnotation[]
            }
            isLabelingReview
          />
        )}
        <Box className={styles.rightCorner}>{rightCornerComponent}</Box>
      </div>

      <Grid container direction="row" className={styles.centerPanelReview} spacing={4}>
        <Grid item xs={6} container alignItems="flex-start">
          <AddToDefectSecBtn
            mediaId={currentMediaId}
            buttonProps={{ id: 'add-to-defect-section', variant: 'text', size: 'medium' }}
          />
          <Box px={5} py={2.5}>
            <MetadataMenu mediaId={currentMediaId} />
          </Box>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end" alignItems="center" wrap="nowrap">
          <ReviewNotes
            key={currentMediaId}
            reviewNotes={reviewNotes}
            actions={reviewNotesActions}
            onFinishEditing={
              reviewNotesEditable
                ? newReviewNotes => {
                    dispatch(draft => {
                      draft.reviewResult[currentMediaId].note = newReviewNotes;
                    });
                    rejectReview();
                  }
                : undefined
            }
          />
          <Box display="flex">
            {acceptButtonDisabled ? (
              AcceptButton
            ) : (
              <Tooltip
                arrow
                disableHoverListener={isViewResultMode}
                title={
                  <>
                    {t('accept and go to next media')}
                    <span className={styles.codeBlock}>{t('a')}</span>
                  </>
                }
              >
                {AcceptButton}
              </Tooltip>
            )}
            {rejectButtonDisabled ? (
              RejectButton
            ) : (
              <Tooltip
                arrow
                disableHoverListener={isRejected || isViewResultMode}
                title={
                  <>
                    {t('reject and go to next media')}
                    <span className={styles.codeBlock}>{t('r')}</span>
                  </>
                }
              >
                {RejectButton}
              </Tooltip>
            )}
            {EditButton}
          </Box>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={openEdit}
        disableEscapeKeyDown={
          // If in a tool mode, block escape listener on dialog
          !!toolMode
        }
      >
        <DialogContent className={styles.editContent}>
          {openEdit && (
            <LabelingReviewEdit
              onCancel={onCloseEditDialog}
              labelingType={labelingType}
              selectedLabelId={newLabelId.current}
              onSaveAccept={(newLabel: Label) => {
                setOpenEdit(false);
                dispatch(draft => {
                  const currentMedia = draft.reviewMediaList.find(
                    media => media.id === currentMediaId,
                  );
                  if (currentMedia) {
                    const reviewersLabels = currentMedia.labels.filter(
                      label => !label.source || label.source === LabelSource.Labeling,
                    );
                    currentMedia.labels = [newLabel, ...reviewersLabels];
                  }

                  draft.reviewResult[currentMediaId] = {
                    ...draft.reviewResult[currentMediaId],
                    selectedLabel: newLabel.id,
                    reviewStatus: LabelReviewStatus.Accepted,
                    editedLabel: true,
                  };
                  draft.visibleLabel = newLabel.id;
                });
              }}
            ></LabelingReviewEdit>
          )}
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default LabelingReviewMainPanel;

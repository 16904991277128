import React, { useState } from 'react';
import { Box, TextField, Tooltip, makeStyles } from '@material-ui/core';
import { trim } from 'lodash';

import { Typography, greyModernScale } from '@clef/client-library';

const useStyles = makeStyles(theme => ({
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    color: theme.palette.greyModern[600],
    lineHeight: 1.2,
    wordWrap: 'break-word',
    maxWidth: 280,
  },
}));

export interface NotesEditorProps {
  value: string | undefined;
  onNotesChange: (notes?: string) => void;
  isEditing?: boolean;
}

const NotesEditor: React.FC<NotesEditorProps> = ({ value, onNotesChange, isEditing = false }) => {
  const styles = useStyles();
  const [isEditingNotes, setIsEditingNotes] = useState(isEditing);
  const [notes, setNotes] = useState(value);

  const handleNotesChange = (newValue: string) => {
    if (trim(newValue) === '') {
      setNotes(undefined);
      onNotesChange(undefined);
    } else if (newValue !== value) {
      onNotesChange(newValue);
    }
    setIsEditingNotes(false);
  };

  return (
    <Box>
      {isEditingNotes ? (
        <TextField
          inputProps={{
            'aria-label': 'custom-training-edit-notes-field',
          }}
          multiline
          maxRows={2}
          value={notes}
          onClick={e => e.stopPropagation()}
          onChange={e => setNotes(e.target.value)}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              handleNotesChange(notes!);
            }
          }}
          onBlur={() => handleNotesChange(notes!)}
          autoFocus
        />
      ) : value ? (
        <Tooltip title={value} placement="bottom" arrow={true}>
          <Box>
            <Typography
              variant="body1"
              className={styles.text}
              onClick={() => setIsEditingNotes(true)}
            >
              {value}
            </Typography>
          </Box>
        </Tooltip>
      ) : (
        <Typography
          variant="body1"
          style={{ color: greyModernScale[400] }}
          onClick={() => setIsEditingNotes(true)}
        >
          {t('Add notes here...')}
        </Typography>
      )}
    </Box>
  );
};

export default NotesEditor;

import React, { useMemo, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { Menu, MenuItem, ListItemText, makeStyles, Box } from '@material-ui/core';
import { Button } from '@clef/client-library';
import seedColor from 'seed-color';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { DatasetGroupOptions, SelectMediaOption } from '@clef/shared/types';
import {
  ApiResponseLoader,
  BarChartHorizontal,
  DistributionChart,
  DistributionType,
  greyScale,
  Typography,
} from '@clef/client-library';

import { useGetProjectVersionedDefectsQuery } from '@/serverStore/projects';
import { useGetDatasetStatsQuery } from '@/serverStore/dataset';
import { getDefectColor } from '@/utils';
import { statsCardDataKeyCompareFn } from '@/utils/str_utils';
import { rootElement } from '@/utils/dom_utils';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  distributionTypeDropDownButton: {
    whiteSpace: 'nowrap',
    minWidth: 136,
    height: 32,
    color: theme.palette.grey[600],
    borderColor: theme.palette.grey[400],
    background: theme.palette.greyModern[25],
    '&:hover': {
      background: 'rgba(0,0,0,0.04)',
    },
    marginLeft: theme.spacing(2),
  },
  byImageChartWrapper: {
    width: '100%',
    padding: theme.spacing(7, 5, 1, 5),
    backgroundColor: theme.palette.greyModern[50],
    borderRadius: 6,
    '& > div:first-child': {
      width: '70%',
    },
  },
  subTitleText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#000000',
  },
  bySplitChartWrapper: {
    position: 'relative',
    // padding: theme.spacing(1, 3, 5, 3),
    padding: '1px 20px 8px 20px',
    backgroundColor: theme.palette.greyModern[50],
    borderRadius: 6,
    '& > div:first-child': {
      width: '70%',
    },
  },
}));

export enum DistributionTypeOption {
  AllImages = 'All images',
  EachSplit = 'Each split',
}
const distributionTypeOptions = Object.values(DistributionTypeOption);

export type SnapshotDistributionChartProps = {
  selectMediaOptions: SelectMediaOption;
};

export const SnapshotDistributionChart: React.FC<SnapshotDistributionChartProps> = props => {
  const { selectMediaOptions } = props;
  const styles = useStyles();
  const { version } = useParams<{ version: string }>();

  const { data: defects } = useGetProjectVersionedDefectsQuery(parseInt(version));

  const defectColor: { [key: string]: string } = useMemo(() => {
    if (!defects) return {};
    return defects.reduce(
      (acc, defect) => ({
        ...acc,
        [defect.name]: getDefectColor(defect),
      }),
      {},
    );
  }, [defects]);
  const defectIdToName = useMemo(() => {
    return defects?.reduce((acc, defect) => {
      acc[defect.id] = defect.name;
      return acc;
    }, {} as Record<number, string>);
  }, [defects]);

  const {
    data: defecTdistrbutionStats,
    isLoading: distributionStatsLoading,
    error: distributionStatsError,
  } = useGetDatasetStatsQuery({
    selectOptions: selectMediaOptions,
    groupOptions: [DatasetGroupOptions.DEFECT_DISTRIBUTION],
    version: parseInt(version, 10),
  });

  const defectStats: { [key: string]: number } = useMemo(() => {
    if (!defecTdistrbutionStats || !defects) return {};
    return defects.reduce(
      (acc, defect) => ({
        ...acc,
        [defect.name]: defecTdistrbutionStats.reduce((acc, stats) => {
          return acc + (stats?.defect_distribution?.[defect.id] ?? 0) * stats.count;
        }, 0),
      }),
      {},
    );
  }, [defecTdistrbutionStats, defects]);

  const defectStatsFormatted = useMemo(
    () =>
      Object.entries(defectStats).map(([name, value]) => ({
        name,
        value,
        color: defectColor[name],
      })),
    [defectColor, defectStats],
  );

  const defectColorMap: { [key: string]: string } = useMemo(() => {
    if (!defects || !defectIdToName) return {};
    return (
      defects
        .slice()
        // https://stackoverflow.com/questions/53420055/error-while-sorting-array-of-objects-cannot-assign-to-read-only-property-2-of/53420326
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .reduce(
          (acc, defect) => ({ ...acc, [defect.name]: getDefectColor(defect) }),
          Object.keys(defectIdToName ?? {}).reduce((acc, defectId) => {
            return {
              ...acc,
              [defectIdToName[Number(defectId)]]: seedColor(defectId).toHex(),
            };
          }, {}),
        )
    );
  }, [defectIdToName, defects]);
  // Add t('No defects)' label to the end
  defectColorMap[t('No Classes')] = greyScale[200]!;

  /**
   * Example response:
   * [{count: 4, defect_distribution: {1948291: 2}, split: "dev"}]
   * This means there are 4 media that have two defects of defect ID 1948291 in split dev
   */
  const {
    data: mediaDefectDistribution,
    isLoading: mediaDefectDistributionLoading,
    error: mediaDefectDistributionError,
  } = useGetDatasetStatsQuery({
    selectOptions: selectMediaOptions,
    groupOptions: [DatasetGroupOptions.DEFECT_DISTRIBUTION, DatasetGroupOptions.SPLIT],
    version: parseInt(version, 10),
  });
  // Calculate the number of defects group by split and defect
  const totalDefectsGroupBySplitDefect = useMemo(() => {
    if (!mediaDefectDistribution || !defectIdToName) {
      return undefined;
    }
    return mediaDefectDistribution.reduce((acc, cur) => {
      const { count: imageCount, defect_distribution, split } = cur;
      const splitName = split || t('Unassigned');
      Object.entries(defect_distribution || {}).forEach(([defectId, defectCount]) => {
        const defectName = (defectId && defectIdToName[Number(defectId)]) || t('No Classes');

        acc[splitName!] = acc[splitName!] || {};
        acc[splitName!][defectName] = acc[splitName!][defectName] || 0;
        acc[splitName!][defectName] += defectCount * imageCount;
      });
      return acc;
    }, {} as { [splitName: string]: { [defectName: string]: number } });
  }, [defectIdToName, mediaDefectDistribution]);

  const totalDefectsFormatted = useMemo(() => {
    if (!totalDefectsGroupBySplitDefect) {
      return [];
    }
    return (
      Object.entries(totalDefectsGroupBySplitDefect)
        // sort by name (train, dev, test, unassigned)
        .sort(([split1], [split2]) => statsCardDataKeyCompareFn(split1, split2))
        .map(([split, defectCountByDefectName]) => {
          const distributions = Object.entries(defectCountByDefectName)
            .map(
              ([defectName, defectCount]) =>
                ({
                  distributor: defectName,
                  value: defectCount,
                } as DistributionType),
            )
            .sort((a, b) => {
              // Always put 'No defect' distribution at the end
              if (a.distributor === t('No Classes')) return 1;
              if (b.distributor === t('No Classes')) return -1;
              return a.distributor > b.distributor ? 1 : -1;
            });
          return {
            name: split || t('unassigned'),
            distributions,
          };
        })
    );
  }, [totalDefectsGroupBySplitDefect]);

  const [typeOption, setTypeOption] = useState<DistributionTypeOption>(
    DistributionTypeOption.AllImages,
  );

  const [dropdownButtonAnchorEl, setDropdownButtonAnchorEl] = useState<HTMLElement | null>(null);

  const onDropdownButtonClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setDropdownButtonAnchorEl(event.currentTarget);
  }, []);

  const onDropdownButtonClose = useCallback(() => {
    setDropdownButtonAnchorEl(null);
  }, []);

  return (
    <>
      <Box className={styles.toolbar}>
        <Typography className={styles.subTitleText}>{t('Show Class Distribution on ')}</Typography>
        <Button
          id={'distribution_type_button'}
          aria-controls="distribution_type_menu"
          aria-haspopup="true"
          variant="outlined"
          onClick={onDropdownButtonClick}
          endIcon={<KeyboardArrowDownIcon />}
          className={styles.distributionTypeDropDownButton}
        >
          {t(typeOption)}
        </Button>
        <Menu
          id={'distribution_type_menu'}
          elevation={0}
          // Required for placing the menu item below the dropdown button
          // https://github.com/mui/material-ui/issues/7961
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          anchorEl={dropdownButtonAnchorEl}
          open={!!dropdownButtonAnchorEl}
          onClose={onDropdownButtonClose}
          container={rootElement}
          keepMounted
        >
          {distributionTypeOptions.map(option => (
            <MenuItem
              key={option}
              onClick={() => {
                setTypeOption(option);
                onDropdownButtonClose();
              }}
            >
              <ListItemText primary={t(option)} />
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <ApiResponseLoader
        response={
          typeOption === DistributionTypeOption.AllImages
            ? defecTdistrbutionStats
            : mediaDefectDistribution
        }
        loading={
          typeOption === DistributionTypeOption.AllImages
            ? distributionStatsLoading
            : mediaDefectDistributionLoading
        }
        error={
          typeOption === DistributionTypeOption.AllImages
            ? distributionStatsError
            : mediaDefectDistributionError
        }
        defaultHeight={160}
      >
        {() => {
          return (
            <>
              {typeOption === DistributionTypeOption.AllImages && (
                <Box className={styles.byImageChartWrapper}>
                  <BarChartHorizontal
                    chartData={defectStatsFormatted}
                    oneline
                    bandWidth={16}
                    borderRadius={5}
                  />
                </Box>
              )}
              {typeOption === DistributionTypeOption.EachSplit && (
                <Box className={styles.bySplitChartWrapper}>
                  <DistributionChart
                    distributionData={totalDefectsFormatted}
                    distributorColorMap={defectColorMap}
                    hideLabel
                    bandWidth={16}
                    borderRadius={5}
                  />
                </Box>
              )}
            </>
          );
        }}
      </ApiResponseLoader>
    </>
  );
};

export default SnapshotDistributionChart;

import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { Box, makeStyles } from '@material-ui/core';
import { Media } from '@clef/shared/types';
import MediaContainer from '@/pages/DataBrowser/MediaContainer';
import { Typography } from '@clef/client-library';
import { modelListFilterOptionsAtom } from '../atoms';
import { useSegmentationInfoWithFilterOptions } from '@/pages/DataBrowser/utils';
import { DefectColorChip } from '@/pages/DataBrowser/ModelPerformance/ConfusionMatrix';

const useStyles = makeStyles(theme => ({
  highlightTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  mediaView: {
    position: 'relative',
    transition: 'outline 0.3s',
    borderRadius: 8,
    border: `2px solid transparent`,
    '&:hover': {
      border: `2px solid ${theme.palette.greyModern[400]}`,
      padding: theme.spacing(1),
    },
    '&::after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: 106,
      cursor: 'pointer',
    },
  },
}));

export const ModelImageListSegmentationDiffView: React.FC<{
  media: Media & { count?: number };
  rowWidth: number;
  modelId?: string;
  version?: number;
  threshold?: number;
  onImageClick: () => void;
}> = ({ media, rowWidth, modelId, version, threshold, onImageClick }) => {
  const styles = useStyles();
  const columns = 2;
  const columnWidth = 100 / columns;
  const [filterOptions] = useAtom(modelListFilterOptionsAtom);
  const [onMediaHovered, setOnMediaHovered] = useState<boolean>(false);

  const filteredInfo = useSegmentationInfoWithFilterOptions(
    media.id,
    modelId,
    threshold,
    version,
    filterOptions,
  );

  return (
    <div>
      {media.count && filterOptions && (
        <Box className={styles.highlightTitle}>
          <Typography>
            {t('{{count}} pixels', {
              count: media.count,
            })}
          </Typography>
          <DefectColorChip defectId={filterOptions.gtClassId} />
          <Typography>{t('to')}</Typography>
          <DefectColorChip isPrediction defectId={filterOptions.predClassId} />
          <Typography>{t('filtered')}</Typography>
        </Box>
      )}
      <Box
        display="flex"
        height={rowWidth / columns}
        marginBottom={1.5}
        className={styles.mediaView}
        onMouseOver={() => setOnMediaHovered(true)}
        onMouseOut={() => setOnMediaHovered(false)}
        onClick={onImageClick}
      >
        <Box width={columnWidth + '%'}>
          <MediaContainer
            media={media}
            showGroundTruth
            maskHitFilterUrl={onMediaHovered ? undefined : filteredInfo?.gt.dataUrl}
            filterOptions={onMediaHovered ? undefined : filterOptions}
            modelId={modelId}
            versionId={version}
            threshold={threshold}
            disableSelection
            noSpacing
          />
        </Box>
        <Box width={columnWidth + '%'} marginLeft={1.5}>
          <MediaContainer
            media={media}
            showPredictions
            segmentationOpacity={0.5}
            maskHitFilterUrl={onMediaHovered ? undefined : filteredInfo?.prediction.dataUrl}
            modelId={modelId}
            versionId={version}
            threshold={threshold}
            filterOptions={onMediaHovered ? undefined : filterOptions}
            disableSelection
            noSpacing
          />
        </Box>
      </Box>
    </div>
  );
};

import { makeStyles } from '@material-ui/core';

export const projectSettingWithUserRoleStyles = makeStyles(() => ({
  accessBox: {
    marginTop: 10,
  },
  accessTitle: {
    marginBottom: 8,
    position: 'relative',
  },
  accessPopover: {
    transform: 'translateY(10px)',
  },
  selectAccess: {
    borderRadius: 8,
    padding: '6px 0',
    zIndex: 2,
  },
  selectItem: {
    padding: '4px 6px',
    cursor: 'pointer',
    '&.active': {
      background: '#F3F3F4',
    },
  },
  checkAccess: {
    margin: '0 4px',
  },
  notCheck: {
    width: 32,
  },
  accessIcon: {
    marginRight: 8,
    display: 'inline-flex',
    verticalAlign: 'middle',
    // color: '#dadada',
  },
  membersTitle: {
    marginTop: 20,
    marginBottom: 30,
  },
  InviteBtn: {
    marginTop: 20,
  },
  accessWrapper: {
    marginBottom: 20,
  },
  accessLabel: {
    fontSize: 14,
  },
  editBtn: {
    marginLeft: 20,
  },
  projectOwnerTitle: {
    fontWeight: 500,
    fontSize: 14,
  },
}));

import React from 'react';
import { Dropdown, FilterPill } from '@clef/client-library';
import { useDeviceMonitorStyles } from '../deviceMonitorStyles';
import { useDeviceMonitorState } from '../deviceMonitorState';
import { Grid, Slider } from '@material-ui/core';
import { Button } from '@clef/client-library';

export interface ConfidenceFilterProps {}

const ConfidenceFilter: React.FC<ConfidenceFilterProps> = () => {
  const styles = useDeviceMonitorStyles();
  const {
    state: { filterConfidence, filterConfidenceEnabled },
    dispatch,
  } = useDeviceMonitorState();
  return (
    <Dropdown
      extraGutter={{ vertical: 12 }}
      dropdown={
        <div className={styles.dropdownPanel}>
          <Slider
            // Array is frozen in strict mode,
            value={filterConfidence.slice()}
            valueLabelDisplay="on"
            step={0.01}
            min={0}
            max={1}
            onChange={(_, newValue) =>
              dispatch(draft => {
                draft.filterConfidence = newValue as number[];
              })
            }
            className={styles.confidenceSlide}
            classes={{
              valueLabel: styles.sliderValueLabel,
            }}
          />
          <Grid container justifyContent="flex-end">
            <Button
              id="apply-confidence-filter"
              color="primary"
              size="small"
              className={styles.textButton}
              onClick={() =>
                dispatch(draft => {
                  draft.filterConfidenceEnabled = true;
                })
              }
            >
              {t('Apply')}
            </Button>
            <Button
              id="clear-confidence-filter"
              color="secondary"
              size="small"
              className={styles.textButton}
              onClick={() =>
                dispatch(draft => {
                  draft.filterConfidenceEnabled = false;
                })
              }
              disabled={!filterConfidenceEnabled}
            >
              {t('Clear')}
            </Button>
          </Grid>
        </div>
      }
    >
      {open => (
        <FilterPill
          filterName={t('Confidence Score')}
          badgeContent={filterConfidenceEnabled ? 'check' : 0}
          open={open}
        />
      )}
    </Dropdown>
  );
};

export default ConfidenceFilter;

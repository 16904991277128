import React from 'react';
import { makeStyles, Paper, Typography, Tooltip, Grid } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles(theme => ({
  paperContainer: {
    borderRadius: 8,
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  chartTitle: {
    fontWeight: 500,
  },
  helpIcon: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(2),
  },
}));

export interface ChartContainerProps {
  title: React.ReactNode;
  caption?: React.ReactNode;
  tooltip?: React.ReactNode;
  ['data-testid']?: string;
}

const ChartContainer: React.FC<ChartContainerProps> = ({
  title,
  children,
  caption,
  tooltip,
  'data-testid': dataTestId,
}) => {
  const styles = useStyles();
  return (
    <Paper variant="outlined" className={styles.paperContainer} data-testid={dataTestId}>
      {tooltip ? (
        <Grid container alignItems="center" wrap="nowrap" justifyContent="center">
          <Typography variant="h4" className={styles.chartTitle} component="div">
            {title}
          </Typography>
          <Tooltip title={tooltip} arrow>
            <HelpOutlineIcon fontSize="small" className={styles.helpIcon} />
          </Tooltip>
        </Grid>
      ) : (
        <Typography variant="h4" className={styles.chartTitle} gutterBottom component="div">
          {title}
        </Typography>
      )}
      {!!caption && (
        <Typography variant="body2" component="div">
          {caption}
        </Typography>
      )}
      {children}
    </Paper>
  );
};

export default ChartContainer;

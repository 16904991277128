export enum CacheStorageKey {
  DataBrowserSession = 'data_browser_session',
  DataBrowserPersistent = 'data_browser_preference',
  UploadedMediaList = 'uploaded_media_list_2',
  UseNewDataBrowser = 'use_new_data_browser',
  // migrated over so has to be camelCase
  ProjectId = 'projectId',
  Language = 'language',
  BrowserWarning = 'browser_warning',
  LabelingToolHint = 'labeling_tool_hint',
  ShowSegmentationLabelingTutorial = 'show_segmentation_labeling_tutorial',
  ShowWorkflowTutorialBox = 'show_workflow_tutorial_box',
  ShowWorkflowTutorialPulsingStep = 'show_workflow_tutorial_pulsing_step',
}

export default class CacheStorage<VT> {
  key: CacheStorageKey;
  persist: boolean;
  value?: VT;

  constructor({
    key,
    defaultValue,
    persist = false,
  }: {
    key: CacheStorageKey;
    persist?: boolean;
    defaultValue?: VT;
  }) {
    this.key = key;
    this.persist = persist;
    if (persist) {
      // If type is localStorage, prioritize construct from localStorage
      const localStorageValue = localStorage.getItem(key);
      const localStorageValueParsed: VT | undefined = localStorageValue
        ? JSON.parse(localStorageValue)
        : undefined;
      this.value = localStorageValueParsed ?? defaultValue;
    } else {
      this.value = defaultValue;
    }
  }

  get() {
    return this.value;
  }

  set(newValue: VT) {
    this.value = newValue;
    if (this.persist) {
      try {
        localStorage.setItem(this.key, JSON.stringify(this.value));
      } catch (e) {
        // setItem could throws a "QuotaExceededError" DOMException exception if the new value couldn't be set.
        // e.g., the user has disabled storage for the site, or if the quota has been exceeded.
        // There isn't much we can do in these cases ignore it.
      }
    }
  }

  delete() {
    this.value = undefined;
    if (this.persist) {
      localStorage.removeItem(this.key);
    }
  }
}

import React, { useCallback } from 'react';
import { makeStyles, Typography, Slider } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles(theme => ({
  sliderGridDefectName: {
    gridColumnStart: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  sliderGridSlider: {
    gridColumnStart: 2,
    position: 'relative',
    marginTop: theme.spacing(1.5),
  },
  sliderGridSliderTrack: {
    display: 'none',
  },
  sliderGridSliderTrackThumb: {
    zIndex: 90,
  },
  sliderGridSliderTrackMarkActive: {
    backgroundColor: 'currentColor',
  },
  sliderGridSplit: {
    gridColumnStart: 3,
    justifySelf: 'end',
  },
  floatingArrows: {
    position: 'absolute',
    top: 2,
    cursor: 'pointer',
    transition: 'transform 0.15s ease',
    '&:hover': {
      transform: 'scale(1.6)',
    },
  },
}));

const sliderValueToSplitPercentage = (s: [number, number]): [number, number, number] => {
  return [s[0], s[1] - s[0], 100 - s[1]];
};

export interface DoubleSliderProps {
  name: React.ReactNode;
  value: [number, number];
  onChange: (newValue: [number, number]) => void;
  gridRowStart: number;
  color: string;
  enableLeftSlider?: boolean;
}

const DoubleSlider: React.FC<DoubleSliderProps> = ({
  name,
  value,
  onChange,
  gridRowStart,
  color,
  enableLeftSlider = true,
}) => {
  const styles = useStyles();
  const sliderValue = enableLeftSlider ? value : value[1];

  const getOnChangeNewValues = useCallback(
    (value: [number, number] | number): [number, number] =>
      enableLeftSlider ? (value as [number, number]) : [0, value as number],
    [enableLeftSlider],
  );

  const percentageValue = sliderValueToSplitPercentage(value);
  return (
    <>
      <Typography
        variant="body2"
        component="div"
        className={styles.sliderGridDefectName}
        style={{ gridRowStart }}
      >
        <strong>{name}</strong>
      </Typography>
      <div className={styles.sliderGridSlider} style={{ gridRowStart }}>
        <Slider
          value={sliderValue}
          onChange={(_, newValue) =>
            onChange(getOnChangeNewValues(newValue as [number, number] | number))
          }
          classes={{
            track: styles.sliderGridSliderTrack,
            markActive: styles.sliderGridSliderTrackMarkActive,
            thumb: styles.sliderGridSliderTrackThumb,
          }}
          aria-labelledby="range-slider"
          style={{ color }}
        />
        {enableLeftSlider && (
          <>
            <KeyboardArrowLeftIcon
              className={styles.floatingArrows}
              style={{ color, left: `calc(${value[0]}% - 36px)` }}
              onClick={() => onChange([value[0] - 1, value[1]])}
              data-testid={'slider-1-go-left'}
            />
            <KeyboardArrowRightIcon
              className={styles.floatingArrows}
              style={{ color, left: `calc(${value[0]}% + 12px)` }}
              onClick={() => onChange([value[0] + 1, value[1]])}
              data-testid={'slider-1-go-right'}
            />
          </>
        )}
        <KeyboardArrowLeftIcon
          className={styles.floatingArrows}
          style={{ color, left: `calc(${value[1]}% - 36px)` }}
          onClick={() => onChange([value[0], value[1] - 1])}
          data-testid={'slider-2-go-left'}
        />
        <KeyboardArrowRightIcon
          className={styles.floatingArrows}
          style={{ color, left: `calc(${value[1]}% + 12px)` }}
          onClick={() => onChange([value[0], value[1] + 1])}
          data-testid={'slider-2-go-right'}
        />
      </div>
      <Typography
        variant="body2"
        component="div"
        className={styles.sliderGridSplit}
        style={{
          gridRowStart,
        }}
      >
        <strong>{percentageValue[0]}</strong>
        {t('/')}
        <strong>{percentageValue[1]}</strong>
        {t('/')} <strong>{percentageValue[2]}</strong>
      </Typography>
    </>
  );
};

export default DoubleSlider;

import { useSetTimeout } from '@/hooks/useTimeout';
import { Link } from '@material-ui/core';
import { SnackbarKey, useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  useCurrentMediaStates,
  useHasUnsavedChanges,
  useMediaList,
  useSaveAnnotations,
} from '../../components/Labeling/imageLabelingContext';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useInstantLearningState } from './state';
import { useMount } from '@clef/client-library';
import { useStartLearningTrainMutation } from '@/serverStore/train';

export const useStartInstantLearningTrain = () => {
  const { state } = useInstantLearningState();
  const { trainingState } = state;
  const isTraining = trainingState === 'training-in-progress';
  const projectId = useTypedSelector(state => state.project.selectedProjectId);
  const hasUnsavedChanges = useHasUnsavedChanges();
  const saveAnnotations = useSaveAnnotations();
  const startInstantLearningTrain = useStartLearningTrainMutation();

  const mediaList = useMediaList();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { timerIdRef, setTimeout } = useSetTimeout();
  const waitingTooLongSnackBarKey = useRef<SnackbarKey>();

  useEffect(() => {
    if (!isTraining) {
      timerIdRef.current && clearTimeout(timerIdRef.current);
      waitingTooLongSnackBarKey.current && closeSnackbar(waitingTooLongSnackBarKey.current);
    }
  }, [closeSnackbar, isTraining, timerIdRef]);

  const isMounted = useMount();

  return useCallback(
    async (mediaId: number) => {
      if (!projectId || !mediaList || mediaList.length === 0 || mediaId === -1) {
        return;
      }
      if (hasUnsavedChanges) {
        // we don't want it yet
        await saveAnnotations({ skipRefreshMediaDetails: true });
      }

      await startInstantLearningTrain.mutateAsync({ projectId, mediaId });

      if (isMounted()) {
        setTimeout(() => {
          waitingTooLongSnackBarKey.current = enqueueSnackbar(
            t('Still loading? You can {{reload}} to try again.', {
              reload: (
                <Link
                  color="inherit"
                  id="reload-page"
                  onClick={() => window.location.reload()}
                  underline="always"
                  style={{ cursor: 'pointer' }}
                >
                  {t('reload the page')}
                </Link>
              ),
            }),
            { variant: 'info', persist: true },
          );
        }, 30_000);
      }
    },
    [
      projectId,
      mediaList,
      hasUnsavedChanges,
      startInstantLearningTrain,
      isMounted,
      saveAnnotations,
      setTimeout,
      enqueueSnackbar,
    ],
  );
};

export const useLabeledClassIds = () => {
  const states = useCurrentMediaStates();
  return useMemo(() => {
    return new Set<number>(states.annotations.map(ann => ann.defectId));
  }, [states]);
};

export const useHasLabeledGroundTruthAnnotations = (defectId: number | undefined) => {
  const states = useCurrentMediaStates();

  return useMemo(
    () =>
      defectId !== undefined ? !!states.annotations.find(ann => ann.defectId === defectId) : false,
    [defectId, states.annotations],
  );
};

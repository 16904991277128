import React, { useState } from 'react';
import { SvgIconProps } from '@material-ui/core';
import MemberSettingSelector from './MemberSettingSelector';
import { User, PendingUser, UserStatus } from '@clef/shared/types';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import MatTable from '../../../components/Utils/MatTable';
import { useJumpToRowById } from '../../../hooks/MaterialTable';
import { toTitle } from '../../../utils/str_utils';

interface Props {
  active: User[];
  pending: PendingUser[];
  jumpToEmail?: string | null;
  withPending?: boolean;
  tableRef: any;
}
export type Member = User & PendingUser;

const MemberTable: React.FunctionComponent<Props> = ({
  active,
  pending,
  tableRef,
  jumpToEmail = null,
  withPending = false,
}: Props) => {
  const [showPending, setShowPending] = useState(withPending);

  let members: Member[] = active as Member[],
    showPendingIcon: React.ReactElement<SvgIconProps> = <AssignmentLateIcon />;

  if (showPending) {
    members = [...active, ...pending] as Member[];
    showPendingIcon = <AssignmentLateIcon color={'primary'} />;
  }
  const { rowStyle } = useJumpToRowById(tableRef, jumpToEmail, [members]);
  const columns = [
    { title: 'Id', field: 'email', hidden: true },
    {
      title: t('Name'),
      field: 'name',
      render: (member: Member): string => member.name || '',
    },
    { title: t('Email'), field: 'email' },
    {
      title: t('Role'),
      field: 'userRole',
      render: (member: Member) => t(toTitle(member.userRole)),
    },
    {
      title: t('Status'),
      field: 'status',
      render: (member: Member) => t(toTitle(member.status ?? UserStatus.Pending)),
    },
    {
      title: '',
      render: (member: Member): React.ReactNode | string =>
        member.status === UserStatus.Inactive || member.status === UserStatus.Active ? (
          <MemberSettingSelector member={member} />
        ) : (
          ''
        ),
    },
  ];

  const actions = [
    {
      icon: (): React.ReactElement<SvgIconProps> => showPendingIcon,
      tooltip: t('Show Pending Users'),
      isFreeAction: true,
      onClick: (): void => setShowPending(!showPending),
    },
  ];
  return (
    <MatTable
      tableRef={tableRef}
      data={members}
      columns={columns}
      actions={actions}
      options={{
        pageSize: 10,
        rowStyle: rowData => {
          return {
            ...rowStyle(rowData),
            color: rowData.status === UserStatus.Inactive ? '#afabab' : 'inherit',
          };
        },
        sorting: true,
        toolbar: false,
      }}
    />
  );
};

export default MemberTable;

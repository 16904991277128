import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  more: {
    padding: '10px 0',
    color: '#666',
    border: '1px solid #CDD0D3',
    display: 'flex',
    width: 40,
    height: 40,
    alignItems: 'center',
    borderRadius: 10,
    marginLeft: theme.spacing(2),
    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  red: {
    color: theme.palette.error.main,
  },
  dialog: {
    width: 516,
    minHeight: 196,
    padding: theme.spacing(6),
  },
  dialogSection: {
    padding: theme.spacing(0),
  },
  dialogContent: {
    position: 'relative',
  },
  contentSummary: {
    position: 'relative',
    bottom: 0,
    left: 0,
    width: '100%',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'end',
  },
  dialogTitleText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.greyModern[900],
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  dialogDesc: {
    marginTop: theme.spacing(2),
    fontSize: 14,
  },
  dialogActions: {
    width: '100%',
    justifyContent: 'end',
  },
  deleteButton: {
    '&:hover': {
      background: '#DB4e35',
    },
  },
  waringBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  warningIcon: {
    marginRight: theme.spacing(2),
  },
  noticeBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 44,
    background: theme.palette.greyModern[50],
    borderRadius: 6,
    marginTop: theme.spacing(4),
    color: theme.palette.greyModern[500],
    fontWeight: 500,
  },
  mainInfoFont: {
    color: theme.palette.grey[800],
    whiteSpace: 'pre-wrap',
    fontWeight: 500,
  },
  subInfoFont: {
    color: theme.palette.greyModern[900],
    whiteSpace: 'pre-wrap',
    fontWeight: 400,
  },
  saveSnapshotCheck: {
    margin: theme.spacing(4, 0, 4, -2),
  },
}));

export default useStyles;

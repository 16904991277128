import React from 'react';

export type CenterCubeProps = {};

const CenterCube: React.FC<CenterCubeProps> = () => {
  return (
    <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25 46.3125L12.5 39.0625C11.7083 38.6042 11.0942 37.99 10.6575 37.22C10.2192 36.4483 10 35.625 10 34.75V20.25C10 19.375 10.2192 18.5517 10.6575 17.78C11.0942 17.01 11.7083 16.3958 12.5 15.9375L25 8.6875C25.7917 8.22917 26.625 8 27.5 8C28.375 8 29.2083 8.22917 30 8.6875L42.5 15.9375C43.2917 16.3958 43.9067 17.01 44.345 17.78C44.7817 18.5517 45 19.375 45 20.25V34.75C45 35.625 44.7817 36.4483 44.345 37.22C43.9067 37.99 43.2917 38.6042 42.5 39.0625L30 46.3125C29.2083 46.7708 28.375 47 27.5 47C26.625 47 25.7917 46.7708 25 46.3125ZM17.5 18.8125L15 20.25V23.1875L25 28.9375V40.4375L27.5 41.875L30 40.4375V28.9375L40 23.1875V20.25L37.5 18.8125L27.5 24.5625L17.5 18.8125Z"
        fill="white"
      />
    </svg>
  );
};

export default CenterCube;

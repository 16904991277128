import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { useLabelingReviewStyles } from '../labelingReviewStyles';
import { Typography, Grid, Slider, Tooltip } from '@material-ui/core';
import SimpleMediaPreview from './SimpleMediaPreview';
import { useLabelingReviewState, useGoToNextMedia, useGoToPrevMedia } from '../labelingReviewState';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { LabelReviewStatus } from '@clef/shared/types';
import { useKeyPress } from '@clef/client-library';
import { checkRenderAgreementThreshold } from '../utils';

export interface LabelingReviewMediaListPanelProps {
  showAgreement: boolean;
}

const OldLabelingReviewMediaListPanel: React.FC<LabelingReviewMediaListPanelProps> = ({
  showAgreement,
}) => {
  const styles = useLabelingReviewStyles();
  const {
    state: { agreementThreshold, agreementScoreSortOrder, reviewMediaList, isViewResultMode },
    dispatch,
  } = useLabelingReviewState();

  const orderIndicator: React.ReactNode = useMemo(
    () =>
      (agreementScoreSortOrder === 'asc' && <ArrowUpwardIcon fontSize="small" />) || (
        <ArrowDownwardIcon fontSize="small" />
      ),
    [agreementScoreSortOrder],
  );

  const changeOrder = useCallback(() => {
    if (!showAgreement) {
      return;
    }
    dispatch(draft => {
      const newOrder = agreementScoreSortOrder === 'asc' ? 'desc' : 'asc';
      draft.agreementScoreSortOrder = newOrder;
      draft.reviewMediaList = reviewMediaList.slice().sort((mediaA, mediaB) => {
        const mediaAScore = mediaA.overallAgreementScore ?? 0;
        const mediaBScore = mediaB.overallAgreementScore ?? 0;
        return newOrder === 'asc' ? mediaAScore - mediaBScore : mediaBScore - mediaAScore;
      });
    });
  }, [agreementScoreSortOrder, dispatch, reviewMediaList, showAgreement]);

  const changeAgreementThreshold = useCallback(
    (newValueRaw: number) => {
      if (!showAgreement) {
        return;
      }
      const newValue = Number(newValueRaw.toFixed(2));
      dispatch(draft => {
        // change all the review results between old and new agreementThreshold to pending/reject
        reviewMediaList.forEach(mediaDetail => {
          const { overallAgreementScore = 0, id: mediaId } = mediaDetail;
          // if media has selected label, respect that over threshold initiative
          if (draft.reviewResult[mediaId].selectedLabel) {
            return;
          }
          if (
            // new value is bigger
            newValue > overallAgreementScore &&
            // agreementScore is between old and new value
            overallAgreementScore < newValue &&
            overallAgreementScore >= agreementThreshold
          ) {
            draft.reviewResult[mediaId].reviewStatus = LabelReviewStatus.Rejected;
          }

          if (
            // new value is smaller
            newValue < agreementThreshold &&
            // agreementScore is between old and new value
            overallAgreementScore >= newValue &&
            overallAgreementScore < agreementThreshold
          ) {
            draft.reviewResult[mediaId].reviewStatus = LabelReviewStatus.NotReviewed;
          }
        });
        draft.agreementThreshold = newValue;
      });
    },
    [agreementThreshold, dispatch, reviewMediaList, showAgreement],
  );

  const goToNextMedia = useGoToNextMedia();
  const goToPrevMedia = useGoToPrevMedia();
  useKeyPress('up', () => goToPrevMedia());
  useKeyPress('down', () => goToNextMedia());
  useKeyPress('s', changeOrder);
  useKeyPress('left', () => changeAgreementThreshold(agreementThreshold - 0.01));
  useKeyPress('right', () => changeAgreementThreshold(agreementThreshold + 0.01));
  return (
    <section
      className={cx(
        styles.panelContainer,
        styles.mediaListPanelContainer,
        showAgreement && 'showAgreement',
      )}
    >
      {showAgreement && !isViewResultMode && (
        <div
          className={cx(
            styles.panelSection,
            styles.panelSectionImportant,
            styles.panelSectionSticky,
          )}
        >
          <Typography variant="h4" className={styles.panelSectionH4}>
            {t('Agreement Threshold')}
          </Typography>
          <Grid container direction="row" wrap="nowrap" alignItems="center">
            <Slider
              value={agreementThreshold}
              step={0.01}
              min={0}
              max={1}
              onChange={(_, newValue) => {
                changeAgreementThreshold(newValue as number);
              }}
            />
            <Typography variant="body1" className={styles.agreementThresholdText}>
              {agreementThreshold.toFixed(2)}
            </Typography>
          </Grid>
        </div>
      )}
      <div className={styles.panelSection}>
        <Typography variant="subtitle1">{t('Media List')}</Typography>
        {showAgreement && (
          <Grid
            direction="row"
            alignContent="center"
            container
            wrap="nowrap"
            spacing={2}
            className={styles.mediaListHeader}
          >
            <Grid item xs={5} container direction="row" alignItems="center">
              <Typography variant="body2">{`Media (${reviewMediaList.length})`}</Typography>
            </Grid>
            <Tooltip
              arrow
              placement="top"
              title={
                <>
                  {t('sort media by agreement score')}
                  <span className={styles.codeBlock}>{t('s')}</span>
                </>
              }
            >
              <Grid
                item
                xs={5}
                container
                direction="row"
                alignItems="center"
                onClick={changeOrder}
                className={styles.agreementLabel}
                data-testid="agreement-sort-label"
              >
                <Typography variant="body2" className={styles.agreementLabelText}>
                  {t('Agreement')}
                </Typography>
                {orderIndicator}
              </Grid>
            </Tooltip>
            <Grid item xs={2} container direction="row" alignItems="center">
              {/* <Typography variant="body2">{t('Review')}</Typography> */}
            </Grid>
          </Grid>
        )}
        {reviewMediaList.map((mediaDetail, index) => {
          const { renderDividerAfter, renderDividerBefore } = isViewResultMode
            ? { renderDividerBefore: false, renderDividerAfter: false }
            : checkRenderAgreementThreshold(
                reviewMediaList,
                mediaDetail,
                index,
                agreementScoreSortOrder,
                agreementThreshold,
              );

          return (
            <React.Fragment key={mediaDetail.id}>
              {showAgreement && renderDividerBefore && (
                <Typography variant="caption" component="div" className={styles.thresholdDivider}>
                  {t('Agreement Threshold')}
                </Typography>
              )}
              <SimpleMediaPreview mediaDetail={mediaDetail} showAgreement={showAgreement} />
              {showAgreement && renderDividerAfter && (
                <Typography variant="caption" component="div" className={styles.thresholdDivider}>
                  {t('Agreement Threshold')}
                </Typography>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </section>
  );
};

export default OldLabelingReviewMediaListPanel;

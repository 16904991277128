export enum LoggingEvent {
  // Enum name need to be capitalized
  LabelingToolClicksPerMedia = 'labelingToolClicksPerMedia',
  ApiCallEvent = '@apiCallEvent',
  PerformanceEntryEvent = '@performanceEntryEvent',
  MediaLoadFail = 'mediaLoadFail',
  FallbackMediaLoadFail = 'fallbackMediaLoadFail',
  MediaUploadTimeEvent = 'mediaUploadTime',
  LabelUploadTimeEvent = 'labelUploadTime',
  LabelingTimeEvent = '@labelingTime',
  NewLabelingTaskMediaLevelEvent = '@newLabelingTask',
  // basic action logging that keep track of things like button press and hotkey usage
  ActionEvent = '@action',
  // impression logging when user see a page or component
  ImpressionEvent = '@impression',
}

export type LoggingEventAttributes = {
  [LoggingEvent.ActionEvent]: { actionEventName: string; actionType: 'button' | 'hotkey' };
  [LoggingEvent.ImpressionEvent]: { impressionName: string; impressionType: 'page' | 'component' };
  [LoggingEvent.LabelingTimeEvent]: {
    mediaId: number;
    taskId: number;
    labelingTime: number;
    imageNotHaveLabel?: boolean;
  };
  [LoggingEvent.NewLabelingTaskMediaLevelEvent]: {
    mediaId: number;
    taskId: number;
    labelingTime: number;
    polygonNumber: number;
    erasePolygonNumber: number;
    brushNumber: number;
    eraseBrushNumber: number;
    polylineNumber: number;
    boxNumber: number;
    defectAnnotationsDeleteNumber: number;
    clearAllNumber: number;
    undoNumber: number;
    redoNumber: number;
    nothingToLabel: boolean;
  };
  [LoggingEvent.LabelingToolClicksPerMedia]: {
    mediaId: number;
    taskId: number;
    clickNumber: number;
  };
  [LoggingEvent.MediaLoadFail]: { src: string; mediaId: number };
  [LoggingEvent.MediaUploadTimeEvent]: {
    mediaId?: number;
    fetchS3FormTime?: number;
    s3UploadTime?: number;
    serverCreateMediaTime?: number;
    error?: boolean;
    size?: number;
  };
  [LoggingEvent.LabelUploadTimeEvent]: {
    labelIds?: number[];
    fetchS3FormTime?: number;
    s3UploadTime?: number;
    serverCreateMediaTime?: number;
    error?: boolean;
    size?: number;
  };
  [LoggingEvent.PerformanceEntryEvent]: {
    url: string;
    apiResponseTime: number;
    apiNetworkTime: number;
  };
  [LoggingEvent.ApiCallEvent]: {
    method: string;
    url: string;
    pathName: string;
    body?: string;
    code?: number;
    message?: string;
    apiResponseTime?: number;
    error?: boolean;
    traceId?: string;
  };
  [LoggingEvent.FallbackMediaLoadFail]: { src: string; mediaId: number };
};

export type LabelingTaskCountMetrics = Omit<
  LoggingEventAttributes[LoggingEvent.NewLabelingTaskMediaLevelEvent],
  'mediaId' | 'taskId' | 'labelingTime'
>;

type DefaultAttributes = {
  orgId: number;
  userId: string;
  email: string;
  projectId?: number;
};

type SendMetricFunc = (eventName: string, attributes: object) => void;
type SendErrorFunc = (
  errorOrMessage: Error | string,
  attributes: object,
  sentryFingerPrints: string[],
) => void;

class EventLogger {
  defaultAttributes: DefaultAttributes;
  sendMetric?: SendMetricFunc;
  sendError?: SendErrorFunc;
  constructor() {
    this.defaultAttributes = { orgId: -1, userId: 'no user', email: 'no email' };
  }

  setDefaultAttributes(newDefaultAttributes: Partial<DefaultAttributes>) {
    this.defaultAttributes = { ...this.defaultAttributes, ...newDefaultAttributes };
  }

  setMethods(sendMetric: SendMetricFunc, sendError: SendErrorFunc) {
    this.sendMetric = sendMetric;
    this.sendError = sendError;
  }

  log<T extends LoggingEvent>(eventName: T, attributes: LoggingEventAttributes[T]) {
    this.sendMetric?.(eventName, { ...this.defaultAttributes, ...attributes });
  }

  error(
    errorOrMessage: Error | string,
    attributes: object = {},
    sentryFingerPrints: string[] = [],
  ) {
    this.sendError?.(errorOrMessage, attributes, sentryFingerPrints);
  }
}
export default new EventLogger();

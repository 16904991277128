import React from 'react';
import { useDatasetDefectTotalNumbers } from '@/hooks/api/useDatasetApi';
import { SelectMediaOption } from '@clef/shared/types';
import { Link } from 'react-router-dom';
import StatsCardBase from './StatsCardBase';
import StatsCardTitle from './StatsCardTitle';
import CLEF_PATH from '../../constants/path';

export interface StatsCardDefectTotalCountProps {
  selectMediaOptions?: SelectMediaOption;
  loadingWithStatsCard?: boolean;
  title?: string;
  titleOption?: any;
  allowEmpty?: boolean;
}

const StatsCardDefectTotalCount: React.FC<StatsCardDefectTotalCountProps> = ({
  selectMediaOptions,
  loadingWithStatsCard = true,
  title = 'Classes',
  allowEmpty = true,
  titleOption,
}) => {
  const defectTotalNumberStats = useDatasetDefectTotalNumbers(selectMediaOptions);

  if (!defectTotalNumberStats)
    return loadingWithStatsCard ? <StatsCardBase loading data={{}} /> : null;

  if (!Object.entries(defectTotalNumberStats).length) return null;

  if (!allowEmpty && !Object.entries(defectTotalNumberStats).length) {
    return loadingWithStatsCard ? (
      <StatsCardBase
        errorMessage={
          <>
            {t('No')}
            <Link to={CLEF_PATH.data.defectBookEnhanced}>{t('defect')}</Link>
            {t('created')}
          </>
        }
        data={{}}
      />
    ) : null;
  }

  return (
    <section data-testid="stats-card-defect-count">
      <StatsCardTitle title={t(title)} Option={titleOption} />
      <StatsCardBase data={defectTotalNumberStats} isFullScreen />
    </section>
  );
};

export default StatsCardDefectTotalCount;

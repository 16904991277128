import { UsageItemWithInterval } from '@/api/usage_api';
import { dateDiffInDays } from '@/utils';
import { BarChart, useStateSyncSearchParams } from '@clef/client-library';
import { Bar } from '@clef/shared/types';
import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { addDays, format } from 'date-fns';
import { last, range } from 'lodash';
import React, { useMemo } from 'react';
import { usePlanStyles } from '.';
import ChartEmptyState from '../components/ChartEmptyState';
import { useGetUsage, useGetUsageSummary } from '@/serverStore/usage';

export type CreditsUsageSectionProps = {};

const useStyles = makeStyles(theme => ({
  creditsUsageSection: {},
  creditsTitle: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.grey[600],
  },
  line: {
    width: 1,
    height: 16,
    backgroundColor: theme.palette.grey[300],
    margin: theme.spacing(0, 3),
  },
  tooltips: {
    marginLeft: 'auto',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.grey[500],
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  chartRoot: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(8),
  },
  bar: {
    width: '50%',
    margin: 'auto',
    borderRadius: 0,
  },
  emptyRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 1,
  },
  chartIconBg: {
    backgroundColor: theme.palette.blue[50],
    borderRadius: '100%',
    width: 80,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      verticalAlign: 'middle',
    },
  },
  emptyTitle: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    fontFamily: 'Commissioner',
    color: theme.palette.grey[700],
  },
  emptySubtitle: {
    fontSize: 14,
    lineHeight: '20px',
    color: theme.palette.grey[600],
  },
}));

const CreditsUsageSection: React.FC<CreditsUsageSectionProps> = () => {
  const styles = useStyles();
  const planStyles = usePlanStyles();
  const { data: usageSummary } = useGetUsageSummary();
  const { cycleStart, cycleEnd } = last(usageSummary?.billingCycles) ?? {};

  const days = useMemo(() => {
    if (!cycleStart || !cycleEnd) {
      return 30;
    }
    return dateDiffInDays(new Date(cycleEnd), new Date(cycleStart));
  }, [cycleEnd, cycleStart]);

  const { data: usage, isLoading: usageLoading } = useGetUsage(
    cycleStart && cycleEnd
      ? {
          fromTimestamp: format(new Date(cycleStart), "yyyy-MM-dd'T'hh:mm:ssXXX"),
          toTimestamp: format(new Date(cycleEnd), "yyyy-MM-dd'T'hh:mm:ssXXX"),
          interval: 'day',
          disableDateConversion: true,
        }
      : undefined,
  );
  const barChartData: Bar[] = useMemo(() => {
    if (!usage || !cycleStart) {
      return [];
    }
    const usageMap = (usage as UsageItemWithInterval[]).reduce((acc, item) => {
      const dateStr = format(new Date(item.startTime), 'MMM dd');
      return {
        ...acc,
        [dateStr]: (acc[dateStr] ?? 0) + item.usage,
      };
    }, {} as Record<string, number>);
    const startDate = new Date(cycleStart);
    return range(0, days).map(dayIndex => {
      const date = addDays(startDate, dayIndex);
      const dateString = format(date, 'MMM dd');
      return { name: dateString, value: usageMap[dateString] ?? 0, color: '#0A64FC' };
    });
  }, [cycleStart, days, usage]);

  const [, setTab] = useStateSyncSearchParams('tab', '');

  const currentCycleTitle = useMemo(() => {
    if (!cycleStart || !cycleEnd) {
      return null;
    }
    const cycleStartDate = new Date(cycleStart);
    const cycleEndDate = new Date(cycleEnd);
    /**
     * For pricing v2, cycle end will be the 1st day of the next month.    (E.g. yyyy-03-05 ~ yyyy-04-01)
     * In frontend, we display the end date as the last day of this month. (E.g. yyyy-03-05 ~ yyyy-03-31)
     *
     * There's an edge case if customer registered during yyyy-mm-01 00:00:00~12:00:00 UTC,
     * the start and end day will be in the same day (yyyy-mm-01).
     * In this case we display as yyyy-mm-01 ~ yyyy-mm-01
     */
    const finalCycleEndDate = Math.max(
      cycleStartDate.getTime(),
      addDays(cycleEndDate, -1).getTime(),
    );
    return t('Current Usage Cycle: {{start}} - {{end}}', {
      start: format(cycleStartDate, 'MMM dd'),
      end: format(finalCycleEndDate, 'MMM dd'),
    });
  }, [cycleEnd, cycleStart]);

  return (
    <Box className={styles.creditsUsageSection} marginTop={4}>
      <Box display="flex" className={styles.creditsTitle} alignItems="center">
        <Box>{t('Credit Used')}</Box>
        <Box className={styles.line}></Box>
        <Box id="current-cycle-title">{currentCycleTitle}</Box>
        <Box id="go-to-usage-page" className={styles.tooltips} onClick={() => setTab('usage')}>
          {t('Learn more about how your team is using LandingLens')}
        </Box>
      </Box>
      <Box position="relative">
        {usageLoading ? (
          <Skeleton variant="rect" height={358} />
        ) : (
          <BarChart
            chartData={barChartData}
            noDataClassName={planStyles.noData}
            showEmptyValues
            classes={{ root: styles.chartRoot, bar: styles.bar }}
            formatValue={({ value }) => (value === 0 ? '' : value)}
            formatLabel={({ name }, index) =>
              index % 3 === 0 || index === barChartData.length - 1 ? name : t(' ')
            }
            tooltipContent={({ value, name }) =>
              t('{{date}}, {{count}} credits used', { date: name, count: value })
            }
          />
        )}
        {usage && usage.length === 0 && (
          <ChartEmptyState
            chartType="vertical_bar"
            title={t('You have not spent any credits yet')}
            subtitle={t(
              'Once you have trained a model or run inference, you can track daily credit usage here.',
            )}
            position="absolute"
          />
        )}
      </Box>
    </Box>
  );
};

export default CreditsUsageSection;

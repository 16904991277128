import React, { CSSProperties, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { BitMapLabelingAnnotation } from '@/components/Labeling/labelingState';
import { Dimensions } from '@clef/shared/types';
import { useClassesById } from './state';
import { getDefectColor } from '@/utils';
import { useCompressedBitMapDataUrl } from '@clef/client-library/src/components/MediaViewer/util';

const useStyles = makeStyles(() => ({
  annotation: {
    position: 'absolute',
  },
}));

export type SegmentationAnnotationProps = {
  annotation: BitMapLabelingAnnotation;
  imageDimensions: Partial<Dimensions>;
};

const SegmentationAnnotation: React.FC<SegmentationAnnotationProps> = props => {
  const styles = useStyles();
  const { annotation, imageDimensions } = props;
  const { width: imageWidth = 133, height: imageHeight = 133 } = imageDimensions;
  const { defectId } = annotation;
  const { rangeBox, bitMap } = annotation.data;
  const { xmin, xmax, ymin, ymax } = rangeBox;
  const width = xmax - xmin + 1;
  const height = ymax - ymin + 1;

  const classesById = useClassesById();
  const theClass = classesById[defectId];
  const color = getDefectColor(theClass);

  const imgSrc = useCompressedBitMapDataUrl(
    bitMap,
    color!,
    xmax - xmin + 1,
    ymax - ymin + 1,
    false,
    0.9,
  );

  const posStyles = useMemo(() => {
    const toPercentage = (value: number, base: number) => (value / base) * 100 + '%';
    return {
      left: toPercentage(xmin, imageWidth),
      top: toPercentage(ymin, imageHeight),
      width: toPercentage(width, imageWidth),
      height: toPercentage(height, imageHeight),
    } as CSSProperties;
  }, [height, imageHeight, imageWidth, width, xmin, ymin]);

  return imgSrc ? <img className={styles.annotation} style={posStyles} src={imgSrc} /> : null;
};

export default SegmentationAnnotation;

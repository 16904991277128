import React, { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Divider, makeStyles } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import { Button, defectColors, MediaInteractiveCanvas } from '@clef/client-library';
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import { ColorResult } from 'react-color';
import ColorToggleButton from '../../DefectBook/components/ColorToggleButton';
import { COLOR_PALETTE } from '../../../constants/colors';
import { useDefectSelector } from '../../../store/defectState/actions';
import ColorPicker from '../../DefectBook/components/ColorPicker';
import { useUpdateDefectMutation } from '@/serverStore/projects';
import { Defect } from '@clef/shared/types';
import { getDefectColor } from '../../../utils';
import { useSnackbar } from 'notistack';
import { useLabelingState } from '../../../components/Labeling/labelingState';

const useStyles = makeStyles(theme => ({
  newColorPicker: {
    padding: '20px 15px 10px',
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  popover: {
    position: 'absolute',
    zIndex: 2,
    left: 'auto',
    bottom: '0',
  },
  addColorButton: {
    margin: theme.spacing(1),
    alignSelf: 'center',
    padding: theme.spacing(0),
  },
}));

export type ColorPickerProps = {
  selectedDefect?: Defect;
  onClose?(): void;
  mediaCanvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
};

const ClassColorPicker: React.FC<ColorPickerProps> = props => {
  const { selectedDefect, onClose, mediaCanvasRef } = props;
  const styles = useStyles();
  const [customColors, setCustomColors] = useState<string[]>([]);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [newColor, setNewColor] = useState<string>(COLOR_PALETTE[0]);
  const [selectedColor, setSelectedColor] = useState<string>(getDefectColor(selectedDefect));
  const updateDefectApi = useUpdateDefectMutation();

  useEffect(() => {
    setSelectedColor(getDefectColor(selectedDefect));
  }, [selectedDefect]);

  const defects = useDefectSelector();

  const chosenColors = useMemo(() => {
    return (defects || []).map(item => {
      const color = (item.color || '').toUpperCase();
      return color;
    });
  }, [defects]);

  const isExist = useCallback(
    (color: string) => {
      return chosenColors?.includes(color.toLocaleUpperCase());
    },
    [chosenColors],
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleAddNewColor = useCallback(() => {
    setShowColorPicker(false);
    if ([...customColors, selectedColor, ...defectColors].includes(newColor)) {
      enqueueSnackbar(t('Please do not set duplicate colors'), {
        variant: 'warning',
      });
      return;
    }
    setSelectedColor(newColor);
    setCustomColors([...customColors, newColor]);
  }, [selectedColor, customColors, enqueueSnackbar, newColor]);

  const { dispatch: dispatchLabelingState } = useLabelingState();
  const [saving, setSaving] = useState(false);
  const onSaveColor = useCallback(async () => {
    setSaving(true);
    try {
      if (selectedDefect && selectedColor) {
        await updateDefectApi.mutateAsync({ ...selectedDefect, color: selectedColor } as Defect);
        mediaCanvasRef.current?.changeColor(getDefectColor(selectedDefect), selectedColor);
        dispatchLabelingState(draft => {
          if (draft.selectedDefect) {
            draft.selectedDefect.color = selectedColor;
          }
        });
        onClose?.();
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
    }
    setSaving(false);
  }, [
    dispatchLabelingState,
    enqueueSnackbar,
    mediaCanvasRef,
    onClose,
    selectedColor,
    selectedDefect,
  ]);

  return (
    <Box className={styles.newColorPicker} display="flex" flexWrap="wrap" alignItems="center">
      {defectColors.map(color => {
        const disabled = isExist(color);
        return (
          <Box
            key={color}
            data-testid={`color-toggle-button-item-${color}`}
            aria-disabled={disabled}
            marginBottom={1}
          >
            <ColorToggleButton
              disabled={disabled}
              key={color}
              color={color}
              selectedColor={selectedColor}
              onClick={() => {
                setSelectedColor(color);
              }}
            />
          </Box>
        );
      })}
      {!!customColors.length && (
        <>
          <Divider
            flexItem
            orientation="vertical"
            style={{ marginBottom: 16 }}
            className={styles.divider}
          />
          {customColors.map(color => {
            const disabled = isExist(color);
            return (
              <Box
                key={color}
                data-testid={`color-toggle-button-item-${color}`}
                aria-disabled={disabled}
                marginBottom={1}
              >
                <ColorToggleButton
                  disabled={disabled}
                  key={color}
                  color={color}
                  selectedColor={color}
                  onClick={() => {
                    setSelectedColor(color);
                  }}
                />
              </Box>
            );
          })}
        </>
      )}
      <Box alignSelf="center" style={{ marginBottom: 6 }}>
        <IconButton
          size="small"
          onClick={() => setShowColorPicker(!showColorPicker)}
          className={styles.addColorButton}
          id="detail-defect-custom-color"
        >
          <AddCircleOutlineOutlined />
        </IconButton>
        {showColorPicker && (
          <Box className={styles.popover}>
            <ColorPicker
              color={newColor}
              onChange={(color: ColorResult) => setNewColor(color.hex)}
              onClose={handleAddNewColor}
            />
          </Box>
        )}
      </Box>
      <div style={{ flex: 1 }}></div>
      <Button
        id="save-class-color"
        color="primary"
        variant="contained"
        startIcon={saving && <CircularProgress size={20} />}
        disabled={saving || !selectedColor}
        onClick={onSaveColor}
      >
        {t('Save')}
      </Button>
    </Box>
  );
};

export default ClassColorPicker;

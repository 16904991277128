import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, makeStyles, FormControl, FormHelperText } from '@material-ui/core';
import { trim } from 'lodash';

import { Typography, greyModernScale } from '@clef/client-library';

const useStyles = makeStyles(theme => ({
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    color: theme.palette.greyModern[900],
  },
}));

export interface NameEditorProps {
  value: string;
  onNameChange: (name: string) => void;
  isEditing?: boolean;
  nameError?: string;
}

const NameEditor: React.FC<NameEditorProps> = ({
  value,
  onNameChange,
  isEditing = false,
  nameError = undefined,
}) => {
  const styles = useStyles();
  const [isEditingName, setIsEditingName] = useState(isEditing);
  const [name, setName] = useState(value);
  const [error, setError] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleNameChange = (newValue: string) => {
    const trimmedValue = trim(newValue);
    if (trimmedValue === '') {
      setError('Name is required');
      setName('');
      onNameChange('');
      inputRef.current && inputRef.current.focus();
    } else {
      setError('');
      onNameChange(trimmedValue);
      setIsEditingName(false);
    }
  };

  useEffect(() => {
    // focus and select text after the component mounts
    const input = inputRef.current;
    if (input) {
      input.focus();
      input.select();
    }
  }, []);

  return (
    <FormControl error={!!nameError}>
      <Box mb={2}>
        {isEditingName ? (
          <TextField
            inputProps={{
              'aria-label': 'custom-training-edit-name-field',
            }}
            multiline
            maxRows={2}
            value={name}
            onClick={e => e.stopPropagation()}
            onChange={e => setName(e.target.value)}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                handleNameChange(name);
              }
            }}
            onBlur={() => handleNameChange(name)}
            autoFocus
            error={!!error}
            helperText={error}
            inputRef={inputRef}
          />
        ) : value ? (
          <Typography
            variant="h4_semibold"
            className={styles.text}
            onClick={() => setIsEditingName(true)}
          >
            {value}
          </Typography>
        ) : (
          <Typography
            variant="h4_semibold"
            style={{ color: greyModernScale[700] }}
            onClick={() => setIsEditingName(true)}
          >
            {t(`Please enter model's name...`)}
          </Typography>
        )}
        {!!nameError && <FormHelperText>{nameError}</FormHelperText>}
      </Box>
    </FormControl>
  );
};

export default NameEditor;

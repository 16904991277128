import React from 'react';

export type BottomRightProps = {};

const BottomRight: React.FC<BottomRightProps> = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 7.5V12.5H5C7.08333 12.5 8.85417 11.7708 10.3125 10.3125C11.7708 8.85417 12.5 7.08333 12.5 5V0H7.5V5C7.5 5.70833 7.26 6.30167 6.78 6.78C6.30167 7.26 5.70833 7.5 5 7.5H0Z"
        fill="white"
      />
    </svg>
  );
};

export default BottomRight;

import { Draft } from 'immer';
import { useContext, createContext, useCallback } from 'react';
import { MediaDetails, Defect, AnnotationWithoutId, MediaId } from '@clef/shared/types';
import LabelApi from '../../api/label_api';
import { useParams } from 'react-router';
import {
  BitMapLabelingAnnotation,
  BoxLabelingAnnotation,
  ClassificationLabelingAnnotation,
  useLabelingState,
} from '../../components/Labeling/labelingState';
import {
  convertToServerAnnotation,
  getAnnotationTypeByLabelType,
} from '../../components/Labeling/utils';
import { useDefectSelector } from '@/store/defectState/actions';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';

export type LabelingTaskState = {
  // current media shown
  currentMediaId: number;
  // list of media to label from server
  taskMediaList: MediaDetails[];
  // the selected defect
  selectedDefect?: Defect;
  // annotations in bitMap styles, used for preview & save to server
  annotationData: {
    [mediaId: number]:
      | (BitMapLabelingAnnotation | BoxLabelingAnnotation | ClassificationLabelingAnnotation)[]
      | undefined;
  };
  // the last timestamp when auto save happened
  lastSavedTime?: Date;
  // if last auto save is successful / failure / in progress
  autoSaveStatus?: 'saving' | 'saved' | 'save-error';
};

export const defaultState: LabelingTaskState = {
  currentMediaId: 0,
  taskMediaList: [],
  annotationData: {},
};
/**
 * Context
 */
export const LabelingTaskContext = createContext<{
  state: LabelingTaskState;
  dispatch: (f: (state: Draft<LabelingTaskState>) => void | LabelingTaskState) => void;
}>({
  state: defaultState,
  dispatch: () => {},
});

export const useLabelingTaskState = () => useContext(LabelingTaskContext);

export const useGoToNextMedia = () => {
  const {
    state: { currentMediaId, taskMediaList },
    dispatch,
  } = useLabelingTaskState();
  const {
    state: { anyMenuOpened },
  } = useLabelingState();
  return useCallback(
    ({ force } = { force: false }) => {
      if (currentMediaId && taskMediaList.length && (force || !anyMenuOpened)) {
        const currentIndex = taskMediaList.findIndex(media => media.id === currentMediaId);
        if (currentIndex !== taskMediaList.length - 1) {
          dispatch(draft => {
            draft.currentMediaId = taskMediaList[currentIndex + 1].id;
          });
        }
      }
    },
    [currentMediaId, taskMediaList, anyMenuOpened, dispatch],
  );
};

export const useGoToPrevMedia = () => {
  const {
    state: { currentMediaId, taskMediaList },
    dispatch,
  } = useLabelingTaskState();
  const {
    state: { anyMenuOpened },
  } = useLabelingState();
  return useCallback(() => {
    if (currentMediaId && taskMediaList.length && !anyMenuOpened) {
      const currentIndex = taskMediaList.findIndex(media => media.id === currentMediaId);
      if (currentIndex) {
        dispatch(draft => {
          draft.currentMediaId = taskMediaList[currentIndex - 1].id;
        });
      }
    }
  }, [currentMediaId, taskMediaList, anyMenuOpened, dispatch]);
};

/**
 * This function saves annotation to local state
 */
export const useSaveAnnotationsToState = () => {
  const {
    state: { currentMediaId },
    dispatch,
  } = useLabelingTaskState();
  const allDefects = useDefectSelector();

  return useCallback(
    (
      annotations: (
        | BitMapLabelingAnnotation
        | BoxLabelingAnnotation
        | ClassificationLabelingAnnotation
      )[],
    ) => {
      dispatch(draft => {
        draft.annotationData[currentMediaId] = annotations.filter(
          ann => !!allDefects.find(d => d.id === ann.defectId),
        );
      });
    },
    [allDefects, currentMediaId, dispatch],
  );
};

/**
 * This function saves media label to server
 */
export const useUpsertMediaLabelToServer = () => {
  const { labelType } = useGetSelectedProjectQuery().data ?? {};
  const {
    state: { annotationData, taskMediaList },
    dispatch,
  } = useLabelingTaskState();
  const allDefects = useDefectSelector();

  const { taskId: taskIdStr } = useParams<{
    taskId?: string;
  }>();

  const taskId = Number(taskIdStr);
  return useCallback(
    async (mediaIdOrAll: number | 'all') => {
      // If mediaIdOrAll is not number, it means we need to save all media
      const mediaIds =
        mediaIdOrAll === 'all'
          ? Object.keys(annotationData).map(idStr => Number(idStr))
          : [mediaIdOrAll];

      const toSaveData = mediaIds.reduce((acc, mediaId) => {
        const foundTaskMedia = taskMediaList.find(media => media.id === mediaId)!;
        const mediaAnnotation = annotationData[mediaId]?.filter(
          ann => !!allDefects.find(d => d.id === ann.defectId),
        );
        if (mediaAnnotation) {
          acc[mediaId] = {
            id: foundTaskMedia.label!.id,
            annotationList: mediaAnnotation.map(annotation =>
              convertToServerAnnotation(annotation, getAnnotationTypeByLabelType(labelType)!),
            ),
          };
        }
        return acc;
      }, {} as Record<MediaId, { id: number; annotationList: AnnotationWithoutId[] }>);

      // If there are nothing to save, skip
      if (!Object.entries(toSaveData).length) {
        return;
      }

      dispatch(draft => {
        draft.autoSaveStatus = 'saving';
      });
      try {
        const mediaIdToLabels = Object.entries(toSaveData).reduce(
          (acc, [mediaId, label]) => ({ ...acc, [mediaId]: [label] }),
          {},
        );
        const updatedLabelsRes = await LabelApi.setBatchAnnotationsForMedias(
          taskId,
          mediaIdToLabels,
        );
        const updatedLabels = updatedLabelsRes.data;
        const updatedTaskMediaList = taskMediaList.map(media => {
          if (updatedLabels && updatedLabels[media.id]) {
            return { ...media, label: updatedLabels[media.id][0] };
          }
          return media;
        });
        dispatch(draft => {
          draft.autoSaveStatus = 'saved';
          draft.lastSavedTime = new Date();
          draft.taskMediaList = updatedTaskMediaList;
        });
      } catch (error) {
        dispatch(draft => {
          draft.autoSaveStatus = 'save-error';
        });
        throw error;
      }
    },
    [allDefects, annotationData, dispatch, labelType, taskId, taskMediaList],
  );
};

import React, { createContext, useContext } from 'react';
import { useImmer, Updater } from 'use-immer';

export type ModelDetailsDialogState = {
  showHeatmap: boolean;
  showGroundTruth: boolean;
  showPredictions: boolean;
  itemPerRowOffset: number;
  openRevertDialog: boolean;
  openCloneProjectDialog: boolean;
  paginationLimit: number;
};

export const defaultState: ModelDetailsDialogState = {
  showHeatmap: false,
  showGroundTruth: true,
  showPredictions: true,
  itemPerRowOffset: 0,
  openRevertDialog: false,
  openCloneProjectDialog: false,
  paginationLimit: 50,
};

export const ModelDetailsDialogContext = createContext<{
  state: ModelDetailsDialogState;
  dispatch: Updater<ModelDetailsDialogState>;
}>({
  state: defaultState,
  dispatch: () => {},
});
export const useModelDetailsDialogState = () => useContext(ModelDetailsDialogContext);

export const ModelDetailsDialogContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useImmer(defaultState);
  return (
    <ModelDetailsDialogContext.Provider value={{ state, dispatch }}>
      {children}
    </ModelDetailsDialogContext.Provider>
  );
};

import { ProjectId } from '@clef/shared/types';
import { useQuery } from '@tanstack/react-query';
import { useGetSelectedProjectQuery } from '../projects';
import { ApiError } from '@/utils/error';
import model_comparison_report_api, {
  ModelComparisonReportsResponse,
} from '@/api/model_comparison_report_api';

export const modelComparisonReqortQueryKeys = {
  all: ['modelComparisonReports'] as const,
  modelComparisonReports: (projectId: ProjectId) => [
    projectId,
    ...modelComparisonReqortQueryKeys.all,
  ],
};

export const useModelComparisonReportsQuery = () => {
  const { id: projectId = 0 } = useGetSelectedProjectQuery().data ?? {};
  return useQuery<ModelComparisonReportsResponse, ApiError>({
    queryKey: modelComparisonReqortQueryKeys.modelComparisonReports(projectId),
    queryFn: async () => {
      const response = model_comparison_report_api.getModelComparisonReports(projectId);
      return response;
    },
    enabled: !!projectId,
  });
};

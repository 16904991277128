import React from 'react';
import { Typography, Box, Tooltip } from '@material-ui/core';
import { Button, Dropdown, SelectOptions, IconButton } from '@clef/client-library';
import { Metadata, MetadataType, MetadataValueType } from '@clef/shared/types';
import { useNewUploadStyles } from '../styles';
import SetMetadataDropdown from '../../../SetMetadata/SetMetadataDropdown';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useGetProjectSplitQuery, useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useMetadataApi } from '../../../../hooks/api/useMetadataApi';
import { useAppDispatch } from '../../../../store';
import { addMetadata, addSplit } from '../../../../store/uploadState';
import { UploadAddTagButton } from '@/components/UploadAddTagButton';
import { NO_SPLIT_DISPLAY_NAME } from '@clef/shared/constants';
import { statsCardDataKeyCompareFn } from '@/utils';
import { TagChip } from '@/components/AddTagDropdown/TagChip';
import AddIcon from '@material-ui/icons/Add';
import CLEF_PATH from '@/constants/path';
import { useHistory } from 'react-router';

const reformatMetaDataValue = (
  metadata: Metadata,
  value: string[] | number[],
): MetadataValueType => {
  let valueReformatted: MetadataValueType;
  if (metadata.type === MetadataType.Boolean) {
    valueReformatted = value?.[0] === t('true');
  } else if (!metadata.allowMultiple) {
    valueReformatted = metadata.type === MetadataType.Number ? Number(value?.[0]) : value?.[0];
  } else {
    valueReformatted =
      metadata.type === MetadataType.Number ? (value as string[]).map(val => Number(val)) : value;
  }
  return valueReformatted;
};

export interface AdditionalInfoProps {
  disabled?: boolean;
  handleDisableEscapeKeyDown?: (disableEscapeKeyDown: boolean) => void;
  onClose?: () => void;
}

const AdditionalInfo: React.FC<AdditionalInfoProps> = ({
  disabled,
  handleDisableEscapeKeyDown,
  onClose,
}) => {
  const styles = useNewUploadStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { metadata, split, uploadData } = useTypedSelector(state => state.uploadState);
  const [allMetadata] = useMetadataApi(selectedProject?.id);
  const { data: projectSplits = [] } = useGetProjectSplitQuery();
  const projectSplitNames = projectSplits.map(p => p.splitSetName);
  const projectSplitNameToId = projectSplits.reduce((obj: Record<string, number>, projectSplit) => {
    obj[projectSplit.splitSetName] = projectSplit.id;
    return obj;
  }, {});
  // no split will use 0 as the value
  projectSplitNameToId[NO_SPLIT_DISPLAY_NAME] = 0;

  const shouldDisableSplit =
    uploadData.length === 0 ||
    uploadData.some(
      data =>
        !data.initialLabel?.segMask &&
        !data.initialLabel?.objectDetection &&
        !data.initialLabel?.unlabeledAsNothingToLabel &&
        !data.initialLabel?.classification &&
        !data.classifiedFolder,
    );

  const splitDropdown = (
    <SelectOptions
      size="medium"
      disabled={shouldDisableSplit}
      options={projectSplitNames
        .sort((a, b) => statsCardDataKeyCompareFn(a, b))
        .concat(NO_SPLIT_DISPLAY_NAME)}
      value={split ?? NO_SPLIT_DISPLAY_NAME}
      onChange={value => {
        dispatch(addSplit(value as string));
      }}
    />
  );

  return (
    <>
      {/* Header */}
      <Typography variant="h3" component="div" className={styles.sectionHeaderText} gutterBottom>
        <strong>{t('Additional information')}</strong>
      </Typography>

      {/* Chips */}
      <Box className={styles.additionalInfoContainer}>
        <Box className={styles.additionalInfoChips}>
          <Box className={styles.additionalInfoChipsTitle} display="flex" alignItems="center">
            <Typography>{t('Split: ')}</Typography>
          </Box>
          {shouldDisableSplit ? (
            <Tooltip
              placement="top"
              arrow
              PopperProps={{
                disablePortal: true,
              }}
              title={<div>{t('You can only assign split on labeled images')}</div>}
            >
              <span>{splitDropdown}</span>
            </Tooltip>
          ) : (
            splitDropdown
          )}
        </Box>
        <UploadAddTagButton
          disabled={disabled}
          handleDisableEscapeKeyDown={handleDisableEscapeKeyDown}
        />
        {allMetadata && (
          <Box className={styles.additionalInfoChips}>
            <Box className={styles.additionalInfoChipsTitle}>{t('Metadata: ')}</Box>
            {!!Object.keys(allMetadata).length && (
              <Box className={styles.chipsContainer}>
                {metadata &&
                  Object.entries(metadata).map(([metadataId, metadataValue]) => (
                    <TagChip
                      key={metadataId}
                      name={`${allMetadata![metadataId]!.name} : ${
                        typeof metadataValue === 'boolean'
                          ? t(String(metadataValue))
                          : metadataValue
                      }`}
                      onDelete={() => {
                        const clonedMetadataValuesMap = { ...metadata };
                        delete clonedMetadataValuesMap[metadataId];
                        dispatch(addMetadata(clonedMetadataValuesMap));
                      }}
                    />
                  ))}
                <Dropdown
                  classes={{ selector: styles.metadataButtonDropdownSector }}
                  extraGutter={{ vertical: 8 }}
                  placement="right"
                  dropdown={
                    <SetMetadataDropdown
                      setMetadataAction={newMetadata => async value => {
                        // @ts-ignore: for metadata that does not allow multiple, extract the first value
                        const newValue = reformatMetaDataValue(newMetadata, value);
                        dispatch(
                          addMetadata({
                            ...metadata,
                            [newMetadata.id]: newValue,
                          }),
                        );
                      }}
                    />
                  }
                  disabled={disabled}
                  onClose={() => {
                    handleDisableEscapeKeyDown?.(false);
                  }}
                >
                  {!metadata || Object.keys(metadata).length === 0 ? (
                    <Button
                      variant="contained"
                      size="small"
                      id="upload-dialog-add-metadata-button"
                      disabled={disabled}
                      className={styles.addTagButton}
                      onClick={() => {
                        handleDisableEscapeKeyDown?.(true);
                      }}
                    >
                      {t('Add metadata')}
                    </Button>
                  ) : (
                    <IconButton
                      size="small"
                      disabled={disabled}
                      className={styles.addTagButton}
                      id="upload-dialog-add-metadata-icon-button"
                      onClick={() => handleDisableEscapeKeyDown?.(true)}
                    >
                      <AddIcon className={styles.addTagIcon} />
                    </IconButton>
                  )}
                </Dropdown>
              </Box>
            )}
            {!Object.keys(allMetadata).length && (
              <Box className={styles.chipsContainer}>
                <Tooltip
                  arrow
                  interactive
                  placement="top"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  title={
                    <div>
                      {t('No metadata is set up, to create new one, {{link}}.', {
                        link: (
                          <a
                            className={styles.setMetadataBtn}
                            onClick={e => {
                              e.preventDefault();
                              onClose?.();
                              history.push(CLEF_PATH.data.metadata);
                            }}
                          >
                            {t('click here')}
                          </a>
                        ),
                      })}
                    </div>
                  }
                >
                  <span>
                    <Button
                      variant="contained"
                      size="small"
                      id="attach-metadata-to-images-button"
                      disabled
                      className={styles.addTagButton}
                    >
                      {t('Add metadata')}
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default AdditionalInfo;

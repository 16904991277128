import { useCallback, useEffect, useMemo, useState } from 'react';

import { getRandomHex } from '@clef/shared/utils';
import { defectColors as defaultDefectColors } from '@clef/client-library';

import { useDefectSelectorWithArchived } from '../store/defectState/actions';

const MAXIMUM_FIND_RANDOM_HEX_RETRY = 5;

export type useAvailableDefectColorProps = {
  defectColors?: string[];
};

export const useAvailableDefectColor = ({
  defectColors = defaultDefectColors,
}: useAvailableDefectColorProps = {}) => {
  const defects = useDefectSelectorWithArchived();
  const [availableColor, setAvailableColor] = useState('');

  const chosenColors = useMemo(() => {
    return (defects || []).map(item => {
      const color = (item.color || '').toUpperCase();
      return color;
    });
  }, [defects]);

  const isExist = useCallback(
    (color: string) => {
      return chosenColors.includes(color);
    },
    [chosenColors],
  );

  const refreshAvailableColor = useCallback(() => {
    let availableColor = defectColors.find(color => !isExist(color));

    if (availableColor === undefined) {
      availableColor = getRandomHex();
      let times = 0;
      while (isExist(availableColor) && times < MAXIMUM_FIND_RANDOM_HEX_RETRY) {
        availableColor = getRandomHex();
        times += 1;
      }
    }

    setAvailableColor(availableColor);
  }, [defectColors, isExist]);

  useEffect(() => {
    refreshAvailableColor();
  }, [refreshAvailableColor]);

  return {
    availableColor,
    refreshAvailableColor,
  };
};

export default useAvailableDefectColor;

import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import { Task, TaskId, UserId, UserTaskStatus } from '@clef/shared/types';
import UserSelector from '../UserSelector/UserSelector';
import { useUserInfo } from '../../hooks/api/useUserApi';

const useStyles = makeStyles(() => ({
  dialogTitle: {
    fontWeight: 'bold',
  },
  dialogSection: {
    padding: 10,
  },
}));

interface TaskAssignmentDialogProps {
  task: Task;
  mediaCount: number;
  handleCancel: () => void;
  handleAssign: (taskId: TaskId, labelers: UserId[]) => void;
  open: boolean;
}

const TaskAssignmentDialog: React.FC<TaskAssignmentDialogProps> = ({
  task,
  mediaCount,
  open,
  handleCancel,
  handleAssign,
}) => {
  const classes = useStyles();
  const initialUserIds = task.assignees
    .filter(user => user.status !== UserTaskStatus.Canceled)
    .map(user => user.userId);
  const initialUsers = useUserInfo(initialUserIds);

  const [labelers, setLabelers] = useState<UserId[]>([]);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      aria-labelledby="assign-task-dialog"
      onClose={() => handleCancel()}
      data-testid="add-task-dialog"
    >
      <DialogTitle className={classes.dialogTitle} id="add-task-dialog">
        {t('Labeling Task Assignment')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('Total Number of Media to Be Labeled:')}
          {mediaCount}
        </DialogContentText>
        <div className={classes.dialogSection}>
          <UserSelector
            setUsers={users => setLabelers(users.map(user => user.id))}
            initialUsers={initialUsers}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          id="cancel-assign-labeler"
          onClick={() => handleCancel()}
          color="primary"
          data-testid="cancel-assign-labeler"
        >
          {t('Cancel')}
        </Button>
        <Button onClick={() => handleAssign(task.id, labelers)} color="primary" id="assign-labeler">
          {t('Assign')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaskAssignmentDialog;

import { BaseAPI } from './base_api';
import {
  ApiResponse,
  ExampleProject,
  LabelType,
  Media,
  MediaDetailsWithPrediction,
} from '@clef/shared/types';

const ExampleProjectAPIUrlPrefix = '';

export interface ExampleProjectDetails {
  defectMap: {
    [key: string]: {
      name: string;
      defectId?: number;
    };
  };
  datasetId: number;
  labelType: LabelType | null;
  description: string;
  title: string;
  registeredModel: {
    id: string;
    confidence: number;
  };
  trialData: (Media & MediaDetailsWithPrediction)[];
}
export type ExampleProjectTrainedData = (Media & MediaDetailsWithPrediction)[];

class ExampleProjectAPI extends BaseAPI {
  async getExampleProjectsList(): Promise<ExampleProject[]> {
    return this.get('example_projects', {}, true);
  }

  async getExampleProjectDetails(
    exampleProjectId: number,
  ): Promise<ApiResponse<ExampleProjectDetails>> {
    return this.get('example_project', { exampleProjectId });
  }

  async getExampleProjectTrainingData(
    exampleProjectId: number,
  ): Promise<ApiResponse<ExampleProjectTrainedData>> {
    return this.get('example_project/training_data', { exampleProjectId });
  }
}

export default new ExampleProjectAPI(ExampleProjectAPIUrlPrefix);

import { EnvironmentEnum, getEnv } from '@/constants';
import { LATEST_RELEASE_VERSION } from '@clef/shared/constants';
import { useEffect, useRef } from 'react';
import CLEF_PATH from '../constants/path';
import { useAppDispatch } from '../store';
import { signOut } from '../store/newLoginState/actions';
import { LoginState } from '../store/types';
import { useTypedSelector } from './useTypedSelector';
import ProfileApi from '../api/profile';

const env = getEnv();

export const useOnVisibilityChange = () => {
  const me = useTypedSelector(state => state.login.user) as LoginState['user'];
  const meRef = useRef(me);
  // me is undefined initially
  meRef.current = me;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const onVisibilityChange = async () => {
      if (document.visibilityState === 'visible' && meRef.current) {
        try {
          const res = await ProfileApi.getProfile();
          if (res && meRef.current) {
            const oldMe = meRef.current;
            const newMe = res.data;
            // if me have changed (switched account in another tab)
            // refresh page to home
            if (oldMe.id !== newMe.id) {
              window.location.href = CLEF_PATH.home;
            }
            // if appVersion is not same as frontend bundle and it's not local env, refresh page
            if (newMe.appVersion !== LATEST_RELEASE_VERSION && env !== EnvironmentEnum.Local) {
              location.reload();
            }
          }
        } catch (e) {
          dispatch(signOut());
        }
      }
    };
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [dispatch]);
};

import {
  SelectMediaOption,
  DefectDistributionToAssignSplitMapping,
  DatasetVersionId,
  Dataset,
} from '@clef/shared/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import DatasetAPI from '@/api/dataset_api';
import { refreshMetadataApi, refreshMediaMetadata } from '@/hooks/api/useMetadataApi';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import { projectQueryKeys, useGetSelectedProjectQuery } from '@/serverStore/projects';
import { datasetQueryKeys } from './queries';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { layoutQueryKeys } from '../layout';

export const useCreateDatesetSnapshotMutation = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  return useMutation({
    mutationFn: async (params: {
      name: string;
      details?: string;
      onlyLabeled?: boolean;
      selectMediaOptions?: SelectMediaOption;
    }) => {
      if (!projectId) return;
      const res = await DatasetAPI.createDatasetSnapshot(
        projectId,
        params.name,
        params.details,
        params.onlyLabeled,
        params.selectMediaOptions,
      );
      return res;
    },
    onSuccess: () => {
      projectId && queryClient.invalidateQueries(datasetQueryKeys.exportedWithVersions(projectId));
    },
    onError: (error: Error) => {
      enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 12000 });
    },
  });
};

export const useAutoSplitMutation = () => {
  const projectId = useTypedSelector(state => state.project.selectedProjectId);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: {
      selectOptions: SelectMediaOption;
      splitByDefectDistribution: DefectDistributionToAssignSplitMapping;
    }) => {
      if (!projectId) return;
      await DatasetAPI.autoSplit(projectId, params.selectOptions, params.splitByDefectDistribution);
    },
    onSuccess: () => {
      projectId && queryClient.invalidateQueries(datasetQueryKeys.allWithFilters(projectId));
      projectId && queryClient.removeQueries(datasetQueryKeys.allStats(projectId));
      refreshMediaMetadata({ keys: 'refresh-all', swr: true });
    },
  });
};

export const useUpdateMediaSplitMutation = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  const projectId = useTypedSelector(state => state.project.selectedProjectId);
  return useMutation({
    mutationFn: async (params: {
      datasetId: number;
      splitSet: number;
      selectMediaOptions: SelectMediaOption;
    }) => {
      if (!projectId) return;
      await DatasetAPI.updateMediaSplit(
        params.datasetId,
        projectId,
        params.splitSet,
        params.selectMediaOptions,
      );
      return params;
    },
    onSuccess: params => {
      if (params?.selectMediaOptions.isUnselectMode) {
        queryClient.invalidateQueries(datasetQueryKeys.mediaDetails(params.datasetId));
      } else {
        params?.selectMediaOptions.selectedMedia.forEach(mediaId => {
          queryClient.invalidateQueries(
            datasetQueryKeys.mediaDetails(params.datasetId, {
              mediaId,
              modelId: currentModelId,
            }),
          );
        });
      }
      projectId && queryClient.removeQueries(datasetQueryKeys.allStats(projectId));
      projectId && queryClient.invalidateQueries(datasetQueryKeys.allWithFilters(projectId));
    },
    onError: (e: Error) => {
      enqueueSnackbar((e as Error).message, { variant: 'warning' });
    },
  });
};

export const useRevertDatasetVersionMutation = () => {
  const { id: projectId, orgId, datasetId } = useGetSelectedProjectQuery().data ?? {};
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (version: number) => {
      if (!projectId || !version) return;
      await DatasetAPI.revertDatasetVersion(projectId, version);
    },
    onSuccess: () => {
      if (!projectId || !orgId || !datasetId) return;
      queryClient.invalidateQueries(datasetQueryKeys.all(projectId));
      queryClient.removeQueries(datasetQueryKeys.mediaCount(projectId!, datasetId));
      queryClient.removeQueries(datasetQueryKeys.medias(projectId!));

      queryClient.invalidateQueries(projectQueryKeys.defects(projectId));
      queryClient.invalidateQueries(datasetQueryKeys.mediaDetails(datasetId));

      refreshMetadataApi({ keys: 'refresh-all', swr: true });
      refreshMediaMetadata({ keys: 'refresh-all', swr: true });
      queryClient.invalidateQueries(layoutQueryKeys.list(projectId));

      enqueueSnackbar('Project reverted successfully.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to revert project. Please try again later.', {
        variant: 'error',
        autoHideDuration: 8000,
      });
    },
  });
};

export const useSoftDeleteSnapshotMutation = () => {
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (datasetVersionId: DatasetVersionId) => {
      if (!projectId || !datasetVersionId) return;
      await DatasetAPI.softDeleteSnapshot(projectId, datasetVersionId);
    },
    onSuccess: () => {
      if (!projectId) return;
      queryClient.invalidateQueries(datasetQueryKeys.exportedWithVersions(projectId));
      enqueueSnackbar('Snapshot deleted successfully.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: e => {
      enqueueSnackbar(`Failed to delete snapshot. ${(e as Error).message}`, {
        variant: 'error',
        autoHideDuration: 8000,
      });
    },
  });
};

export const useEditSnapshotInfoMutation = () => {
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: {
      datasetVersionId: DatasetVersionId;
      name?: string;
      details?: string;
    }) => {
      await DatasetAPI.editSnapshot(params.datasetVersionId, params.name, params.details);
      return params;
    },
    onSuccess: params => {
      if (!projectId) return;
      queryClient.setQueriesData(
        datasetQueryKeys.exportedWithVersions(projectId),
        (previous: Dataset[] | undefined) => {
          if (previous?.[0]) {
            let versionList = previous[0].datasetVersions;
            versionList = versionList?.map(v => {
              if (v.id === params.datasetVersionId) {
                return {
                  ...v,
                  name: params.name ?? v.name,
                  details: params.details ?? v.details,
                };
              }
              return v;
            });
            return [{ ...previous[0], datasetVersions: versionList }];
          }
          return previous;
        },
      );
      enqueueSnackbar('Snapshot edit successfully.', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to edit snapshot. Please try again later.', {
        variant: 'error',
        autoHideDuration: 8000,
      });
    },
  });
};

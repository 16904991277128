import CLEF_PATH from '../../../../constants/path';
import { PageName } from '@clef/shared/constants';

import FREDataBrowserIcon from './icons/DataBrowserIcon';
import FREDeployIcon from './icons/DeployIcon';
import FRETasksIcon from './icons/TasksIcon';
import FREModelV2Icon from './icons/ModelV2Icon';

export const SIDE_BAR_MODELS_V2_ID = 'side-bar-models-v2';
export type SidebarLink = {
  key: PageName;
  label: string;
  link: string;
  icon?: string | React.FC;
  notificationCount?: number;
  children?: SidebarLink[];
  id?: string;
};

export const freStructure: SidebarLink[] = [
  {
    id: 'side-bar-data',
    key: PageName.DataBrowserNew,
    label: t('Build'),
    link: CLEF_PATH.data.dataBrowser,
    icon: FREDataBrowserIcon,
  },
  {
    id: SIDE_BAR_MODELS_V2_ID,
    key: PageName.ModelV2ListPage,
    label: t('Models'),
    link: CLEF_PATH.modelsV2.list,
    icon: FREModelV2Icon,
  },
  {
    id: 'side-bar-deploy',
    key: PageName.DeploymentPage,
    label: t('Deploy'),
    link: CLEF_PATH.deployment.overview,
    icon: FREDeployIcon,
  },
  {
    id: 'side-bar-tasks',
    key: PageName.MyTasks,
    label: t('Tasks'),
    link: CLEF_PATH.myTasks,
    icon: FRETasksIcon,
  },
];

export const createFRESidebarStructure = (
  enabledPageList: PageName[],
  notificationMapping: { [pageName in PageName]?: number },
): SidebarLink[] => {
  return freStructure.reduce((acc, sidebarLink) => {
    const { key } = sidebarLink;
    if (!enabledPageList.includes(key)) {
      return acc;
    }
    return acc.concat({
      ...sidebarLink,
      notificationCount: notificationMapping[key],
    });
  }, [] as SidebarLink[]);
};

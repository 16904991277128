import React from 'react';
import { MediaDetails, EdgeMediaDetails, MediaStatusType } from '@clef/shared/types';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { startCase } from 'lodash';

interface MediaDetailsItemProps {
  name: string | JSX.Element;
  value: string | JSX.Element;
}

function isString(node: string | JSX.Element): node is string {
  return typeof node == 'string';
}

const useStyles = makeStyles(() => ({
  wordBreak: { wordBreak: 'break-all' },
}));

const MediaDetailsItem: React.FC<MediaDetailsItemProps> = ({ name, value }) => {
  const styles = useStyles();

  const { wordBreak } = styles;

  let nameComponent = name,
    valueComponent = value;
  if (isString(name)) {
    nameComponent = <Typography variant="body2">{startCase(name)}</Typography>;
  }
  if (isString(value)) {
    valueComponent = <Typography variant="body2">{value}</Typography>;
  }

  return (
    <Grid item container style={{ paddingTop: '10px' }}>
      <Grid item xs={4}>
        {nameComponent}
      </Grid>
      <Grid
        item
        xs={8}
        data-testid={`media-details-${name}`.toLowerCase().replace(' ', '-')}
        className={wordBreak}
      >
        {valueComponent}
      </Grid>
    </Grid>
  );
};

export interface MediaHumanJudgementPanelProps {
  media: MediaDetails | EdgeMediaDetails;
  mediaStatus?: MediaStatusType;
}

const MediaHumanJudgementPanel: React.FC<MediaHumanJudgementPanelProps> = ({ media }) => {
  const edgeImageJudgementData = (media as EdgeMediaDetails).human_judgement;

  const data = [
    {
      key: 'inspectorId',
      name: t('Inspector ID'),
      value: edgeImageJudgementData?.inspector_id || 'N/A',
    },
    {
      key: 'judgement',
      name: t('Judgement'),
      value: edgeImageJudgementData?.judgement || 'N/A',
    },
    {
      key: 'inspectionTimeStamp',
      name: t('Inspection Time'),
      value: edgeImageJudgementData?.inspection_time
        ? new Date(edgeImageJudgementData?.inspection_time).toUTCString()
        : 'N/A',
    },
    {
      key: 'mode',
      name: t('Mode'),
      value: edgeImageJudgementData?.mode || 'N/A',
    },
    {
      key: 'comments',
      name: t('Comment'),
      value: edgeImageJudgementData?.comments || 'N/A',
    },
  ];

  return (
    <Grid container>
      {data.map(({ key, ...param }) => (
        <MediaDetailsItem key={key} {...param} />
      ))}
    </Grid>
  );
};

export default MediaHumanJudgementPanel;

import { makeStyles } from '@material-ui/core';

export const useLabelingReviewStyles = makeStyles(theme => ({
  // page
  mainContent: {
    width: '100vw',
    height: 'calc(100vh - 88px)',
    overflow: 'hidden',
    marginTop: 88,
    display: 'flex',
    alignItems: 'stretch',
    flexWrap: 'nowrap',
  },
  codeBlock: {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 4,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: theme.spacing(1),
    fontWeight: 700,
  },
  codeBlockLight: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 4,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: theme.spacing(0.5),
    fontWeight: 700,
  },
  // header
  appBar: {
    width: '100vw',
    backgroundColor: '#fff',
    padding: theme.spacing(5),
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: theme.boxShadow.default,
  },
  appBarInner: {
    padding: theme.spacing(5),
  },
  appBarTaskProgress: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(3),
    whiteSpace: 'nowrap',
  },
  appBarTaskAssignedProgress: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  appBarTaskAssignedProgressIcon: {
    marginRight: theme.spacing(3),
  },
  actionButton: {
    marginLeft: theme.spacing(2),
  },
  // panel shared
  panelContainer: {
    height: '100%',
    overflow: 'auto',
  },
  panelSection: {
    padding: theme.spacing(5),
    position: 'relative',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
  },
  panelSectionH4: {
    marginBottom: theme.spacing(3),
  },
  panelSectionImportant: {
    paddingTop: theme.spacing(10),
  },
  panelSectionSticky: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: theme.zIndex.appBar - 10,
  },
  // media info panel
  mediaInfoPanelContainer: {
    width: '280px',
    backgroundColor: 'white',
  },
  leftPanelSubtitle: {
    marginBottom: theme.spacing(2),
  },
  postRequestMask: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: theme.zIndex.drawer + 10,
    background: 'rgba(255,255,255,0.7)',
  },
  subPanelContainer: {
    marginBottom: theme.spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  subPanelDivider: {
    marginBottom: theme.spacing(1),
  },
  instanceRow: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    boxSizing: 'content-box',
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      '& $instanceRowIconHidden': {
        visibility: 'visible',
      },
    },
  },
  instanceRowClickable: {
    cursor: 'pointer',
  },
  instanceRowText: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  instanceRowSelected: {
    backgroundColor: theme.palette.grey[200],
  },
  instanceRowIconHidden: {
    visibility: 'hidden',
  },
  instanceRowIconShown: {
    visibility: 'visible',
  },
  defectIndicator: {
    width: 12,
    height: 12,
    borderRadius: 4,
    overflow: 'hidden',
    marginRight: theme.spacing(2),
  },
  annInstance: {
    marginLeft: theme.spacing(6),
  },
  labelInstance: {
    borderRadius: 8,
    padding: theme.spacing(2),
    margin: theme.spacing(0, -2),
    position: 'relative',
    '& $labelSelectBtn': {
      visibility: 'hidden',
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      '& $labelSelectBtn': {
        visibility: 'visible',
      },
    },
    '&.labelHidden': {
      opacity: 0.3,
    },
  },
  hiddenMask: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 100,
    background: 'rgba(255,255,255,0.7)',
  },
  labelSelectBtn: {
    height: 21,
    whiteSpace: 'nowrap',
  },
  // metadata
  metadataContainer: {
    paddingTop: theme.spacing(1),
  },
  // right panel
  mediaListPanelContainer: {
    position: 'relative',
    boxShadow: theme.boxShadow.default,
    width: 280,
    backgroundColor: 'white',
  },
  mediaListHeader: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  agreementSlider: {
    width: 120,
  },
  agreementThresholdText: {
    borderWidth: 1,
    borderStyle: 'solid',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(4),
    padding: theme.spacing(2),
  },
  agreementLabel: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  agreementLabelText: {
    marginRight: theme.spacing(1),
  },
  thresholdDivider: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey[600],
    '&:before': {
      content: '""',
      flex: 1,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[400],
      marginRight: theme.spacing(2),
    },
    '&:after': {
      content: '""',
      flex: 1,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[400],
      marginLeft: theme.spacing(2),
    },
  },
  // center panel
  centerPanelContainer: {
    backgroundColor: theme.palette.background.lightBlue,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flex: 1,
    padding: theme.spacing(3),
  },
  centerPanelTop: {
    width: '100%',
    paddingBottom: theme.spacing(3),
  },
  centerPanelMedia: {
    flex: 1,
    minHeight: 300,
    width: '100%',
    position: 'relative',
  },
  centerPanelReview: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
  },
  reviewButtonGroup: {
    marginLeft: theme.spacing(7),
    flex: 1,
  },
  reviewButton: {
    borderRadius: 16,
    backgroundColor: '#fff',
    flex: 1,
  },
  reviewNotAccept: {
    color: '#34C759',
    borderColor: theme.palette.grey[300],
    backgroundColor: '#fff',
  },
  reviewAccept: {
    color: '#fff',
    backgroundColor: '#34C759',
    '&:hover': {
      backgroundColor: '#34C759',
    },
  },
  reviewNotReject: {
    color: '#FF6A60',
    borderColor: theme.palette.grey[300],
    backgroundColor: '#fff',
  },
  reviewReject: {
    color: '#fff',
    backgroundColor: '#FF6A60',
    '&:hover': {
      backgroundColor: '#FF6A60',
    },
  },
  btn: {
    marginLeft: '14px',
  },
  reviewComment: {
    marginRight: theme.spacing(2),
    '& > div': {
      backgroundColor: '#fff',
      borderRadius: 12,
    },
  },
  toggleShowLabelContainer: {
    marginLeft: -11,
  },
  // simple media preview
  mediaPreview: {
    cursor: 'pointer',
    height: 72,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1.5, 2),
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  mediaPreviewSelected: {
    backgroundColor: theme.palette.grey[200],
  },
  mediaPreviewImg: {
    height: '100%',
    width: 60,
    borderRadius: 8,
    objectFit: 'contain',
    display: 'block',
    backgroundColor: '#e1e4e9',
  },
  mediaReject: {
    color: theme.palette.error.main,
  },
  mediaApproved: {
    color: theme.palette.success.main,
  },
  enhancer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  flexGrow: {
    flexGrow: 1,
  },
  annotationDetail: {
    color: theme.palette.grey[500],
  },
  labelsTreeItemSpacing: {
    // padding: theme.spacing(1, 0),
    lineHeight: '25px',
    height: 25,
  },
  reviewNotesPopover: {
    minWidth: 480,
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1.5),
  },
  reviewNotesBadge: {
    backgroundColor: theme.palette.primary.light,
  },
  reviewNotesText: {
    whiteSpace: 'pre',
  },
  rejectTip: {
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    background: '#E9F5FE',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
  },
  rejectTipText: {
    marginLeft: theme.spacing(1),
    fontSize: '12px',
  },
  notBorder: {
    borderBottom: 'none',
  },
  reassignBtn: {
    marginTop: theme.spacing(1),
  },
  progress: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
    background: 'rgba(255,255,255,0.7)',
  },
  editContent: {
    padding: '0 !important',
  },
  editBtn: {
    marginLeft: '14px',
    '& button': {
      backgroundColor: '#fff',
      borderColor: theme.palette.grey[300],
    },
  },
  tooltipIcon: {
    color: theme.palette.grey[400],
  },
  selectClass: {
    position: 'absolute',
    zIndex: 10,
    marginRight: theme.spacing(4),
    fontSize: '12px',
    right: '300px',
    bottom: '20px',
    '& .defect-selector': {
      height: '34px',
      fontSize: '14px',
      color: '#333',
    },
  },
  rightCorner: {
    position: 'absolute',
    right: '10px',
    bottom: '10px',
  },
}));

import React from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { MAX_PREVIEW_IMAGES } from '../constants';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles(theme => ({
  maxPreviewImageIcon: {
    marginLeft: theme.spacing(1),
    // color: theme.palette.grey[600],
    verticalAlign: '-0.25em',
  },
}));

export type MaxPreviewImagesIconProps = {
  imageCount: number;
  color?: string;
};

const MaxPreviewImagesIcon: React.FC<MaxPreviewImagesIconProps> = props => {
  const { imageCount, color } = props;
  const styles = useStyles();
  if (imageCount <= MAX_PREVIEW_IMAGES) {
    return null;
  }
  return (
    <Tooltip
      title={t('We only display {{max}} image previews', { max: MAX_PREVIEW_IMAGES })}
      arrow
      placement="right"
    >
      <ErrorOutline className={styles.maxPreviewImageIcon} fontSize="small" htmlColor={color} />
    </Tooltip>
  );
};

export default MaxPreviewImagesIcon;

// TODO: we should customize our typography variants after migrating to MUI5.
// https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants

import { makeStyles } from '@material-ui/core';
import { greyModernScale } from './colors';
import { CSSProperties } from '@material-ui/styles';

export type EnhancedTypographyVariant =
  | 'h1_semibold'
  | 'h2_semibold'
  | 'h3_semibold'
  | 'h4_semibold'
  | 'body_regular'
  | 'body_semibold'
  | 'body_medium'
  | 'body_small'
  | 'body_bold'
  | 'body_small_semibold'
  | 'body_small_medium'
  | 'body_small_bold';

const typographyStyles: Record<EnhancedTypographyVariant, CSSProperties> = {
  h1_semibold: {
    fontFamily: 'Commissioner',
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px',
    color: greyModernScale[900],
  },
  h2_semibold: {
    fontFamily: 'Commissioner',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '28px',
    color: greyModernScale[900],
  },
  h3_semibold: {
    fontFamily: 'Commissioner',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '24px',
    color: greyModernScale[900],
  },
  h4_semibold: {
    fontFamily: 'Commissioner',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '20px',
    color: greyModernScale[900],
  },
  body_regular: {
    fontFamily: 'Commissioner',
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
  },
  body_semibold: {
    fontFamily: 'Commissioner',
    fontSize: 14,
    lineHeight: '20px',
    color: greyModernScale[600],
    fontWeight: 600,
  },
  body_medium: {
    fontFamily: 'Commissioner',
    fontSize: 14,
    lineHeight: '20px',
    color: greyModernScale[600],
    fontWeight: 500,
  },
  body_bold: {
    fontFamily: 'Commissioner',
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
    fontWeight: 700,
  },
  body_small: {
    fontFamily: 'Commissioner',
    fontSize: 12,
    lineHeight: '16px',
    color: greyModernScale[600],
  },
  body_small_semibold: {
    fontFamily: 'Commissioner',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 600,
  },
  body_small_medium: {
    fontFamily: 'Commissioner',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 500,
  },
  body_small_bold: {
    fontFamily: 'Commissioner',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 700,
  },
};

// This is temporary version for defining typography styles.
export const useTypographyStyles = makeStyles(typographyStyles as any);

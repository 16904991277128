import { createAtom, createWritableAtom } from '../utils';

export const showGroundTruthAtom = createAtom('showGroundTruth', true);

export const showPredictionAtom = createAtom('showPrediction', false);

export const showHeatmapAtom = createAtom('showHeatmap', false);

interface LabelTogglesParams {
  showGroundTruth?: boolean;
  showPredictions?: boolean;
  showHeatmap?: boolean;
}

export const initLabelTogglesAtom = createWritableAtom(
  'initLabelToggles',
  null,
  (get, set, props: LabelTogglesParams) => {
    const { showGroundTruth, showPredictions, showHeatmap } = props;

    if (!showGroundTruth && !showPredictions) {
      set(showGroundTruthAtom, true);
      set(showPredictionAtom, true);
    } else {
      set(showGroundTruthAtom, showGroundTruth || false);
      set(showPredictionAtom, showPredictions || false);
    }
    set(showHeatmapAtom, showHeatmap || false);
  },
);

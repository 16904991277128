import { Button } from '@clef/client-library';
import { Box, makeStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React, { useState } from 'react';
import LightBulbIcon from '../icons/LightBulbIcon';

import labelingStepGuide1 from '@/images/instant_learning/labeling_step_guide_1.gif';
import labelingStepGuide2 from '@/images/instant_learning/labeling_step_guide_2.gif';
import { useTypographyStyles } from '@clef/client-library/src/themes/typography';
import EmojiObjectsOutlined from '@material-ui/icons/EmojiObjectsOutlined';

const useStyles = makeStyles(theme => ({
  tipsButton: {
    position: 'absolute',
    right: 16,
    bottom: 16,
    borderRadius: 5,
    backgroundColor: 'white',
    padding: theme.spacing(2),
    zIndex: 1,
    color: theme.palette.greyModern[500],
    boxShadow: theme.boxShadow.default,
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  tipsRoot: {
    position: 'absolute',
    right: 16,
    bottom: 60,
    borderRadius: 10,
    backgroundColor: 'white',
    padding: theme.spacing(5),
    zIndex: 2,
    color: theme.palette.greyModern[500],
    boxShadow: theme.boxShadow.default,
    width: 320,
    '& img': {
      width: '100%',
      backgroundColor: theme.palette.greyModern[50],
      borderRadius: 10,
    },
  },
  tipsContent: {
    fontSize: 14,
    color: theme.palette.greyModern[600],
  },
}));

const allTips = [
  {
    title: t('Label your first Class'),
    content: t('You only need to label {{bold}}.', {
      bold: <strong>{t('a small area')}</strong>,
    }),
    image: labelingStepGuide1,
  },
  {
    title: t('Label another Class'),
    content: t('The Model needs {{bold}} to make meaningful predictions.', {
      bold: <strong>{t('at least two Classes')}</strong>,
    }),
    tips: t('You can label the background as one Class.'),
  },
];

type LabelingTipsProps = {
  guideImages?: [string, string];
  currentStep: number;
};

const LabelingTips: React.FC<LabelingTipsProps> = props => {
  const { currentStep, guideImages = [labelingStepGuide1, labelingStepGuide2] } = props;
  const guideImage = guideImages[currentStep];
  const styles = useStyles();
  const [showTips, setShowTips] = useState(true);
  const currentTips = allTips[currentStep];

  const typographyStyles = useTypographyStyles();

  if (!guideImage || !currentTips) {
    return null;
  }

  const { title, content, tips } = currentTips;

  return (
    <>
      <Button
        id="view-labeling-tips"
        className={styles.tipsButton}
        onClick={() => setShowTips(prev => !prev)}
      >
        {showTips ? <Close /> : <LightBulbIcon />}
        {showTips ? t('Close') : t('Stuck? View tips.')}
      </Button>
      {showTips && (
        <Box className={styles.tipsRoot}>
          <Box className={typographyStyles.h4_semibold}>{title}</Box>
          <Box className={styles.tipsContent} marginTop={2}>
            {content}
          </Box>
          {tips && (
            <Box
              display="flex"
              alignItems="center"
              className={typographyStyles.body_small}
              marginTop={2}
            >
              <EmojiObjectsOutlined fontSize="small" />
              <Box marginLeft={1}>{tips}</Box>
            </Box>
          )}
          <Box marginTop={2} />
          <img key={guideImage} src={guideImage} />
        </Box>
      )}
    </>
  );
};

export default LabelingTips;

export const MODEL_PERFORMANCE_DETAIL_DRAWER_WIDTH = '72vw';
export const MODEL_PERFORMANCE_DETAIL_DRAWER_EXPANDED_WIDTH = 'calc(100vw - 80px)'; // 80px is the left tabs width

/** left sticky: model name */
export const MIN_WIDTH_MODEL_NAME_COLUMN = 280;
export const STICKY_POSITION_MODEL_NAME_COLUMN = 0;

/** right sticky: operations */
export const FIXED_WIDTH_OPERATIONS_COLUMN = 116;
export const STICKY_POSITION_OPERATIONS_COLUMN = 0;

/** right sticky: deployment */
export const FIXED_WIDTH_ENDPOINTS_COLUMN = 144;
export const STICKY_POSITION_ENDPOINTS_COLUMN =
  FIXED_WIDTH_OPERATIONS_COLUMN + STICKY_POSITION_OPERATIONS_COLUMN;

/** right sticky: threshold (hide for cls) */
export const FIXED_WIDTH_THRESHOLD_COLUMN = 112;

export const getStickyPositionThresholdColumn = (showDeploymentColumn: boolean) => {
  return showDeploymentColumn
    ? STICKY_POSITION_ENDPOINTS_COLUMN + FIXED_WIDTH_ENDPOINTS_COLUMN
    : STICKY_POSITION_ENDPOINTS_COLUMN;
};

/** right sticky: add eval set
 * - add eval set btn
 * OR
 * - add eval set empty state (first time and no eval sets)
 **/
export const FIXED_WIDTH_ADD_EVALUATION_SET_BUTTON_COLUMN = 172;
export const FIXED_WIDTH_ADD_EVALUATION_SET_EMPTY_STATE_COLUMN = 300;

import React, { forwardRef } from 'react';
import {
  Button as MuiButton,
  ButtonProps,
  Tooltip,
  TooltipProps,
  withStyles,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';
import EventLogger, { LoggingEvent } from '../../logging/EventLogger';
import cx from 'classnames';

export const StyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#4D5761',
    fontSize: 14,
    fontWeight: 400,
    maxWidth: 600,
    padding: theme.spacing(3),
    pointerEvents: 'all',
    border: `1px solid ${theme.palette.blue[500]}`,
    boxShadow: '0px 1px 2px rgba(48, 55, 79, 0.16), 0px 2px 4px rgba(48, 55, 79, 0.08)',
    '& .MuiTooltip-arrow': {
      color: 'white',
    },
  },
  arrow: {
    color: theme.palette.common.white,
    '&:before': {
      border: `1px solid ${theme.palette.blue[500]}`,
    },
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  spinner: {
    marginLeft: theme.spacing(-3),
    marginTop: theme.spacing(-3),
    position: 'absolute',
    left: '50%',
    top: '50%',
    color: theme.palette.grey[400],
  },
  tonalButton: {
    backgroundColor: theme.palette.blue[100],
    color: theme.palette.blue[900],
    '&:hover': {
      backgroundColor: theme.palette.blue[50],
    },
  },
}));

type ButtonVariant = ButtonProps['variant'] | 'tonal';

// @ts-ignore
interface Props extends React.ComponentProps<typeof MuiButton> {
  isPending?: boolean;
  id: string;
  children: React.ReactNode;
  component?: React.ReactNode;
  to?: string;
  spinnerClassName?: string;
  tooltip?: TooltipProps['title'];
  tooltipProps?: Partial<TooltipProps>;
  tooltipOnButton?: boolean;
  target?: '_blank' | (string & {});
  // Only used when rendered as children of SwitchButtonGroup
  active?: boolean;
  variant?: ButtonVariant;
  isCustomTooltipStyle?: boolean;
  download?: boolean | string;
  onKeyPress?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
}

const AllMuiVariants = new Set(['text', 'outlined', 'contained']);

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      // custom props
      isPending,
      spinnerClassName,
      active,
      // tooltip props
      tooltip,
      tooltipProps,
      tooltipOnButton = false,
      // button props
      id,
      children,
      disabled,
      onClick,
      className,
      variant,
      isCustomTooltipStyle = false,
      onKeyPress,
      ...otherProps
    },
    fRef,
  ) => {
    const styles = useStyles();
    const muiVariant =
      variant && AllMuiVariants.has(variant) ? (variant as ButtonProps['variant']) : undefined;
    const button = (
      <MuiButton
        id={id}
        data-testid={id}
        {...otherProps}
        ref={fRef}
        className={cx(className, id, active && 'active', {
          [styles.tonalButton]: (variant as ButtonVariant) === 'tonal',
        })}
        variant={muiVariant}
        disabled={isPending || disabled}
        onClick={e => {
          onClick?.(e);
          if (id) {
            EventLogger.log(LoggingEvent.ActionEvent, {
              actionType: 'button',
              actionEventName: id,
            });
          }
        }}
        onKeyPress={e => onKeyPress?.(e)}
      >
        {isPending ? (
          <CircularProgress size={24} className={cx(styles.spinner, spinnerClassName)} />
        ) : (
          children
        )}
      </MuiButton>
    );
    return tooltip ? (
      isCustomTooltipStyle ? (
        <StyledTooltip arrow title={tooltip} placement="top" {...tooltipProps}>
          {tooltipOnButton ? button : <div>{button}</div>}
        </StyledTooltip>
      ) : (
        <Tooltip arrow title={tooltip} placement="top" {...tooltipProps}>
          {tooltipOnButton ? button : <div>{button}</div>}
        </Tooltip>
      )
    ) : (
      button
    );
  },
);

export default Button;

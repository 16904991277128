import { useQuery } from '@tanstack/react-query';
import StreamyardAPI, { StreamyardObject } from '@/api/streamyard_api';
import { ApiErrorType } from '@/api/base_api';

export const streamyardQueryKeys = {
  all: ['streamyard'] as const,
  eventId: () => [...streamyardQueryKeys.all, 'eventId'] as const,
};

export const useGetStreamyardIdFromSecretManagerQuery = () => {
  return useQuery<StreamyardObject, ApiErrorType>({
    queryKey: streamyardQueryKeys.eventId(),
    queryFn: async () => {
      const res = await StreamyardAPI.getStreamyardIdFromSecretsManager();
      return res.data;
    },
  });
};

import { MediaInteractiveCanvas } from '@clef/client-library';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useFetchDefectBookExampleApi } from '../../../hooks/api/useDefectBookApi';
import { useMediaByIdApi } from '../../../hooks/api/useMediaApi';
import { defectExampleAnnotationToCanvasAnnotation } from '../../../pages/DefectBook/components/utils';
import { useLabelingDrawerStyles } from '../labelingDrawerStyles';
import { getThumbnail } from '@clef/client-library';

export const DrawerTerminologyExample = ({ termId }: { termId: number }) => {
  const [termExamples] = useFetchDefectBookExampleApi([termId]);
  const { mediaId, note, annotations } = termExamples?.[0] ?? {};
  const [media] = useMediaByIdApi(mediaId);
  const styles = useLabelingDrawerStyles();

  if (!mediaId || !annotations) return null;

  return (
    <div className={styles.terminologyExampleContainer}>
      <MediaInteractiveCanvas
        imageSrc={getThumbnail(media, 'medium')}
        properties={media?.properties}
        annotations={defectExampleAnnotationToCanvasAnnotation(annotations)}
        aspectRadio={16 / 9}
        builtInZoom
        enableHideLabels
      />
      <div className={styles.terminologyExample}>
        <ReactMarkdown className={styles.terminologyMarkdown}>{note ?? t('')}</ReactMarkdown>
      </div>
    </div>
  );
};

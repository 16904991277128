import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { ApiResponse } from '@clef/shared/types/api';
import { User, Account, UserWithAppVersion } from '@clef/shared/types';

import AuthAPI, { UserConfirmResetData } from '../../api/auth_api';
import ProfileApi from '../../api/profile';
import { LoginThunks } from '../types';

export enum LoginStateActionType {
  // Old actions
  startLogin = 'loginState/startLogin',
  loginSuccess = 'loginState/loginSuccess',
  loginFailure = 'loginState/loginFailure',
  setAuthenticated = 'loginState/setAuthenticated',
  resetError = 'loginState/resetError',
  signupSuccess = 'loginState/signupSuccess',
  requestCodeSuccess = 'loginState/requestCodeSuccess',
  passwordResetSuccess = 'loginState/passwordResetSuccess',

  // New actions
  loginAccountSuccess = 'loginState/loginAccountSuccess',
  loginAccountFailure = 'loginState/loginAccountFailure',
  loginOrgUserSuccess = 'loginState/loginOrgUserSuccess',
  loginOrgUserFailure = 'loginState/loginOrgUserFailure',
  refreshAuthenticatedStates = 'loginState/refreshAuthenticatedStates',
  fetchOrgUserProfile = 'loginState/fetchOrgUserProfile',
  signOut = 'loginState/signOut',
}

// Old actions
export const startLogin = createAction<void>(LoginStateActionType.startLogin);
export const loginSuccess = createAction<ApiResponse<User>>(LoginStateActionType.loginSuccess);
export const loginFailure = createAction<any>(LoginStateActionType.loginFailure);
export const setAuthenticated = createAction<boolean>(LoginStateActionType.setAuthenticated);
export const resetError = createAction<void>(LoginStateActionType.resetError);
export const signupSuccess = createAction<any>(LoginStateActionType.signupSuccess);
export const requestCodeSuccess = createAction<void>(LoginStateActionType.requestCodeSuccess);
export const passwordResetSuccess = createAction<void>(LoginStateActionType.passwordResetSuccess);

// New actions

// Account-level user related actions
export const loginAccountSuccess = createAction<Account | null>(
  LoginStateActionType.loginAccountSuccess,
);
export const loginAccountFailure = createAction<any>(LoginStateActionType.loginAccountFailure);

// Org-level user related actions
export const loginOrgUserSuccess = createAction<User | null>(
  LoginStateActionType.loginOrgUserSuccess,
);
export const loginOrgUserFailure = createAction<any>(LoginStateActionType.loginOrgUserFailure);

export const refreshAuthenticatedStates = createAction<void>(
  LoginStateActionType.refreshAuthenticatedStates,
);

// To be removed when legacy auth flow is fully deprecated
export const loginUser = (username: string, password: string) => (dispatch: any) => {
  dispatch(startLogin());
  AuthAPI.login(username, password)
    .then(res => {
      dispatch(loginSuccess(res));
    })
    .catch(err => {
      dispatch(loginFailure(err));
    });
};

// To be removed when legacy auth flow is fully deprecated
export const requestVerificationCode = (username: string) => (dispatch: any) => {
  AuthAPI.forgotPasswordCode(username)
    .then(() => {
      dispatch(requestCodeSuccess());
    })
    .catch(err => {
      dispatch(loginFailure(err));
    });
};

// To be removed when legacy auth flow is fully deprecated
export const confirmResetPassword = (data: UserConfirmResetData) => (dispatch: any) => {
  AuthAPI.confirmPassword(data)
    .then(() => {
      dispatch(passwordResetSuccess());
    })
    .catch(err => {
      dispatch(loginFailure(err));
    });
};

// To be removed when legacy auth flow is fully deprecated
export const signUpUser: LoginThunks['signUpUser'] = data => dispatch => {
  dispatch(startLogin());
  if (data) {
    AuthAPI.signup(data)
      .then(message => {
        dispatch(signupSuccess(message));
        dispatch(loginUser(data.email, data.password));
      })
      .catch(err => {
        dispatch(loginFailure(err));
      });
  }
};

export const fetchOrgUserProfile = createAsyncThunk<UserWithAppVersion, void>(
  LoginStateActionType.fetchOrgUserProfile,
  async () => {
    const { data: user } = await ProfileApi.getProfile();
    return user;
  },
);

export const signOut = createAsyncThunk<void, void>(LoginStateActionType.signOut, async () => {
  await AuthAPI.logout();

  localStorage.removeItem('clef_account_is_login');
  localStorage.removeItem('clef_is_login');
  if (window.UNSAFE_cacheRegistry) {
    window.UNSAFE_cacheRegistry.forEach(cache => {
      cache.reset();
    });
  }
});

import {
  Grid,
  ListItemAvatar,
  ListItemText,
  Menu,
  ListItem,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { User, UserStatus } from '@clef/shared/types';
import { Autocomplete } from '@material-ui/lab';
import cx from 'classnames';
import { UserAvatar } from '../UserAvatar/UserAvatarNew';
import AddIcon from '@material-ui/icons/Add';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useFetchUsersForProject } from '../../hooks/api/useProjectApi';

export interface props {
  setUsers?: (users: User[]) => void;
  initialUsers?: User[];
}

const useStyles = makeStyles(theme => ({
  selected: {
    backgroundColor: 'rgba(9, 30, 66, 0.04)',
  },
  autoComplete: {
    width: 300,
  },
  avatar: {
    width: 40,
    height: 40,
  },
  autoSelectOption: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: 0,
  },
  autoPaper: {
    borderWidth: 0,
    boxShadow: 'none',
  },
  inputRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
    },
  },
  input: {
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: '0.75rem',
  },
}));

const UserSelector: React.FC<props> = ({ setUsers, initialUsers }) => {
  const styles = useStyles();
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const [users] = useFetchUsersForProject(projectId);
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState<User[]>(initialUsers ?? []);
  const addUserBtnClick = useCallback(event => {
    setUserMenuAnchor(event.target);
    event.stopPropagation();
  }, []);
  const handleUserMenuClose = useCallback(event => {
    setUserMenuAnchor(null);
    event.stopPropagation();
  }, []);
  const activeUsers = users
    ? (users.activeUsers ?? users).filter(user => user.status && user.status === UserStatus.Active)
    : [];

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          {users ? (
            <IconButton onClick={addUserBtnClick} color="primary" id="user-selector-add-user-btn">
              <AddIcon />
            </IconButton>
          ) : (
            <CircularProgress size="24px" />
          )}
        </Grid>
        {selectedUsers.map(user => {
          return (
            <Grid item key={`${user.id}-avatar`}>
              <UserAvatar name={user.name} classes={{ avatar: styles.avatar }} />
            </Grid>
          );
        })}
      </Grid>
      <Menu
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="user-menu"
        anchorEl={userMenuAnchor}
        open={Boolean(userMenuAnchor)}
        onClose={handleUserMenuClose}
      >
        <Autocomplete
          options={activeUsers || []}
          defaultValue={initialUsers}
          getOptionLabel={user => `${user.name} ${user.email}`}
          getOptionSelected={(option, user) => option.id === user.id}
          multiple
          open
          onChange={(_, newValue: User[]) => {
            setSelectedUsers(newValue);
            setUsers?.(newValue);
          }}
          disableListWrap
          classes={{
            option: styles.autoSelectOption,
            paper: styles.autoPaper,
          }}
          renderTags={() => <></>}
          disableCloseOnSelect
          renderOption={(user: User, { selected }): JSX.Element => (
            <ListItem
              key={user.id}
              dense
              className={cx(selected && styles.selected)}
              component="div"
            >
              <ListItemAvatar>
                <UserAvatar name={user.name} disableTooltip />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="body2" component="div">
                    <strong>{user.name}</strong>
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="body2"
                    component="div"
                    data-testid={`select-labeler-${user.email}`}
                  >
                    {user.email}
                  </Typography>
                }
              />
            </ListItem>
          )}
          className={styles.autoComplete}
          value={selectedUsers}
          renderInput={params => (
            <TextField
              {...params}
              data-testid="add-labeler-autocomplete"
              autoFocus
              variant="outlined"
              fullWidth
              inputProps={{
                className: styles.input,
                ...params.inputProps,
              }}
              classes={{
                root: styles.inputRoot,
              }}
              size="small"
            />
          )}
        />
      </Menu>
    </div>
  );
};

export default UserSelector;

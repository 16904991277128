import {
  Box,
  Checkbox,
  makeStyles,
  Typography,
  MenuItem,
  Select,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import React, { useCallback, useState } from 'react';
import { useDataBrowserState } from '../dataBrowserState';
import Add from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Delete';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import {
  ImageLevelClassificationRule,
  ImageLevelClassificationRuleParams,
  DefectId,
  Defect,
} from '@clef/shared/types';
import { useDefectSelector } from '../../../store/defectState/actions';
import { getDefectColor } from '../../../utils';
import { IconButton } from '@clef/client-library';

const useStyles = makeStyles(theme => ({
  classificationToggleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  addRuleButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: 8,
  },
  ruleEditorButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: 12,
    },
    marginTop: 8,
  },
  ruleEditorTypeSelectorWrapper: {
    display: 'flex',
  },
  ruleEditorTypeSelector: {
    flexGrow: 1,
  },
  ruleEditorCondition: {
    marginBottom: 4,
  },
  ruleEditorOutput: {
    marginBottom: 4,
  },
  ruleEditorOutputTextFieldWrapper: {
    display: 'flex',
  },
  ruleEditorOutputTextField: {
    flexGrow: 1,
  },
  classColorSquare: {
    width: 16,
    height: 16,
    borderRadius: 4,
    marginRight: theme.spacing(2),
    flexShrink: 0,
  },
  classNameText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
  },
  classSelector: {
    display: 'flex',
    alignItems: 'center',
  },
  validationError: {
    backgroundColor: '#fdeeed',
    color: '#5f2120',
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    borderRadius: 4,
    marginTop: 8,
  },
  validationErrorIcon: {
    color: '#d84644',
    marginRight: 6,
  },
  ruleClassColor: {
    width: 12,
    height: 12,
    borderRadius: 4,
    marginRight: theme.spacing(1),
    flexShrink: 0,
  },
  ruleChip: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100],
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 4,
    paddingLeft: 4,
    borderRadius: 10,
  },
  classificationRuleCondition: {
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: 4,
    },
    flexWrap: 'wrap',
  },
  classificationRuleChipWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: 4,
    },
    flexWrap: 'wrap',
  },
  classificationRuleList: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: 8,
    },
    marginTop: 8,
  },
  classificationRuleWrapper: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 4,
    borderColor: theme.palette.grey[200],
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    '&:hover': {
      borderColor: '#0c64cd',
      cursor: 'pointer',
    },
    display: 'flex',
    alignItems: 'center',
  },
  classificationRuleContentControls: {
    flexShrink: 0,
    display: 'flex',
  },
  classificationRuleContentOrderingControls: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  classificationRuleOutput: {
    '& > *': {
      marginLeft: 10,
    },
  },
  classificationRuleContentWrapper: {
    flexGrow: 1,
  },
  classificationCatchAllWrapper: {
    padding: 4,
    borderColor: theme.palette.grey[200],
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 8,
    '&:hover': {
      borderColor: '#0c64cd',
      cursor: 'pointer',
    },
  },
}));

const DEFAULT_PARAM_VALUES = {
  all: [],
};

export type ParamsSelectorProps = {
  params: ImageLevelClassificationRuleParams;
  setParams: (newParams: ImageLevelClassificationRuleParams) => void;
};

const ContainsAllClassesParamsSelector: React.FC<ParamsSelectorProps> = ({ params, setParams }) => {
  const styles = useStyles();
  const allDefects = useDefectSelector();
  const setClassEnabled = (defectId: DefectId, enabled: boolean) => {
    if (params.includes(defectId) && !enabled) {
      setParams(params.filter((value: DefectId) => value !== defectId));
    } else if (!params.includes(defectId) && enabled) {
      setParams([...params, defectId]);
    }
  };
  return (
    <Box>
      {allDefects.map(defect => (
        <Box key={defect.id} className={styles.classSelector}>
          <Checkbox
            color="primary"
            checked={params.includes(defect.id)}
            onChange={event => setClassEnabled(defect.id, event.target.checked)}
          />
          <Box
            className={styles.classColorSquare}
            style={{ backgroundColor: getDefectColor(defect) }}
          ></Box>
          <Box className={styles.classNameText}>{defect.name}</Box>
        </Box>
      ))}
    </Box>
  );
};

export type ValidationErrorProps = {
  text: string;
};

const ValidationError: React.FC<ValidationErrorProps> = ({ text }) => {
  const styles = useStyles();
  return (
    <Box className={styles.validationError}>
      <ErrorOutline fontSize="small" className={styles.validationErrorIcon} />
      <Typography>{text}</Typography>
    </Box>
  );
};

export type ImageLevelClassificationRuleEditorProps = {
  setIsEditingRule: (newIsEditingRule: boolean) => void;
  rule?: ImageLevelClassificationRule;
  isAddingRule: boolean;
  setIsAddingRule: (newIsAddingRule: boolean) => void;
  pushRule: (newRule: ImageLevelClassificationRule) => void;
  setCurrentEditedRuleIndex: (index: number | null) => void;
  setRule?: (newRule: ImageLevelClassificationRule) => void;
};

const ImageLevelClassificationRuleEditor: React.FC<ImageLevelClassificationRuleEditorProps> = ({
  setIsEditingRule,
  rule,
  isAddingRule,
  setIsAddingRule,
  pushRule,
  setCurrentEditedRuleIndex,
  setRule,
}) => {
  const styles = useStyles();
  const [newRuleType, setNewRuleType] = useState<string>(rule?.operator ?? 'all');
  const [newRuleParams, setNewRuleParams] = useState(
    rule?.defectIds ?? DEFAULT_PARAM_VALUES['all'],
  );
  const [newRuleOutput, setNewRuleOutput] = useState(rule?.classification ?? '');
  const [paramsValidationError, setParamsValidationError] = useState<string | null>(null);
  const [outputValidationError, setOutputValidationError] = useState<string | null>(null);
  let paramsSelector = null;
  if (newRuleType === 'all') {
    paramsSelector = (
      <ContainsAllClassesParamsSelector params={newRuleParams} setParams={setNewRuleParams} />
    );
  }

  return (
    <>
      <Box className={styles.ruleEditorCondition}>
        <Typography variant="subtitle1">Condition</Typography>
        <FormHelperText>Select when this rule applies</FormHelperText>
        <Box className={styles.ruleEditorTypeSelectorWrapper}>
          <Select
            variant="outlined"
            label="Type"
            className={styles.ruleEditorTypeSelector}
            value={newRuleType}
            onChange={event => {
              setNewRuleType(event.target.value as string);
              // Set appropriate default values for rule params
              if (event.target.value === 'all') {
                setNewRuleParams(DEFAULT_PARAM_VALUES['all']);
              }
            }}
          >
            <MenuItem value={'all'}>If the following classes are found</MenuItem>
          </Select>
        </Box>
        {paramsSelector}
        {paramsValidationError && <ValidationError text={paramsValidationError} />}
      </Box>
      <Box className={styles.ruleEditorOutput}>
        <Typography variant="subtitle1">Output</Typography>
        <FormHelperText>Specify the final classification output</FormHelperText>
        <Box className={styles.ruleEditorOutputTextFieldWrapper}>
          <TextField
            variant="outlined"
            className={styles.ruleEditorOutputTextField}
            onChange={event => {
              setNewRuleOutput(event.target.value);
            }}
            error={!!outputValidationError}
            value={newRuleOutput}
          />
        </Box>
        {outputValidationError && <ValidationError text={outputValidationError} />}
      </Box>
      <Box className={styles.ruleEditorButtonWrapper}>
        <Button
          id="cancel-save-rule"
          variant="outlined"
          color="primary"
          onClick={() => {
            setIsEditingRule(false);
            setIsAddingRule(false);
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          id="save-rule"
          variant="contained"
          color="primary"
          onClick={() => {
            // Validate the form input
            if (newRuleType === 'all') {
              if (!newRuleParams.length) {
                setParamsValidationError(t('Please select at least one class.'));
                return;
              } else {
                setParamsValidationError(null);
              }
            }
            if (!newRuleOutput.length) {
              setOutputValidationError(t('Output cannot be empty!'));
              return;
            } else {
              setOutputValidationError(null);
            }
            if (isAddingRule) {
              pushRule({
                operator: newRuleType,
                defectIds: newRuleParams,
                classification: newRuleOutput,
              });
            } else if (setRule) {
              setRule({
                operator: newRuleType,
                defectIds: newRuleParams,
                classification: newRuleOutput,
              });
            }
            setIsEditingRule(false);
            setIsAddingRule(false);
            setCurrentEditedRuleIndex(null);
          }}
        >
          {t('Done')}
        </Button>
      </Box>
    </>
  );
};

export type ImageLevelClassificationCatchAllEditorProps = {
  setIsEditingCatchAll: (newIsEditingCatchAll: boolean) => void;
  catchAll: string;
  setCatchAll: (newCatchAll: string) => void;
};

const ImageLevelClassificationCatchAllEditor: React.FC<
  ImageLevelClassificationCatchAllEditorProps
> = ({ setIsEditingCatchAll, catchAll, setCatchAll }) => {
  const styles = useStyles();
  const [newCatchAll, setNewCatchAll] = useState<string>(catchAll);
  const [catchAllValidationError, setCatchAllValidationError] = useState<string | null>(null);

  return (
    <>
      <Box className={styles.ruleEditorOutput}>
        <Typography variant="subtitle1">Catch-All</Typography>
        <FormHelperText>Specify an output in case no rules apply.</FormHelperText>
        <Box className={styles.ruleEditorOutputTextFieldWrapper}>
          <TextField
            variant="outlined"
            className={styles.ruleEditorOutputTextField}
            onChange={event => {
              setNewCatchAll(event.target.value);
            }}
            error={!!catchAllValidationError}
            value={newCatchAll}
          />
        </Box>
        {catchAllValidationError && <ValidationError text={catchAllValidationError} />}
      </Box>
      <Box className={styles.ruleEditorButtonWrapper}>
        <Button
          id="cancel-image-level-classification-settings"
          variant="outlined"
          color="primary"
          onClick={() => {
            setIsEditingCatchAll(false);
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          id="save-image-level-classification-settings"
          variant="contained"
          color="primary"
          onClick={() => {
            // Validate the form input
            if (!newCatchAll.length) {
              setCatchAllValidationError(t('Catch-all cannot be empty!'));
              return;
            } else {
              setCatchAllValidationError(null);
            }
            setCatchAll(newCatchAll);
            setIsEditingCatchAll(false);
          }}
        >
          {t('Done')}
        </Button>
      </Box>
    </>
  );
};

export type RuleClassChipProps = {
  defect?: Defect;
};

const RuleClassChip: React.FC<RuleClassChipProps> = ({ defect }) => {
  const styles = useStyles();
  return (
    <Box className={styles.ruleChip}>
      <Box
        className={styles.ruleClassColor}
        style={{ backgroundColor: getDefectColor(defect) }}
      ></Box>
      <Box className={styles.classNameText}>{defect?.name}</Box>
    </Box>
  );
};

export type ImageLevelClassificationRuleEntryProps = {
  rule: ImageLevelClassificationRule;
  defects: Defect[];
  deleteRule: () => void;
  onClick: () => void;
  nudgeRuleUp: () => void;
  nudgeRuleDown: () => void;
  isFirst: boolean;
  isLast: boolean;
};

const ImageLevelClassificationRuleEntry: React.FC<ImageLevelClassificationRuleEntryProps> = ({
  rule,
  defects,
  deleteRule,
  onClick,
  nudgeRuleUp,
  nudgeRuleDown,
  isFirst,
  isLast,
}) => {
  const [controlsVisible, setControlsVisible] = useState(false);
  const styles = useStyles();
  let condition = null;
  if (rule.operator === 'all') {
    const classChips = rule.defectIds.map((defectId: number, index: number) => (
      <RuleClassChip key={index} defect={defects.find(defect => defect.id === defectId)} />
    ));
    const contents = [];
    for (let i = 0; i < classChips.length; i++) {
      contents.push(classChips[i]);
      if (i !== classChips.length - 1) {
        contents.push(<Typography key={i.toString() + '_AND'}>&</Typography>);
      }
    }
    condition = (
      <Box className={styles.classificationRuleCondition}>
        <Typography>{'If'}</Typography>
        <Box className={styles.classificationRuleChipWrapper}>{contents}</Box>
        <Typography>{classChips.length === 1 ? 'is found' : 'are found'}</Typography>
      </Box>
    );
  } else {
    condition = 'Unknown rule type!';
  }
  const controlsStyle: React.CSSProperties = {};
  if (!controlsVisible) {
    controlsStyle.visibility = 'hidden';
  }
  // TODO: Instead of using two arrow buttons to rearrange rules, implement drag-and-drop reordering.
  return (
    <Box
      className={styles.classificationRuleWrapper}
      onMouseEnter={() => setControlsVisible(true)}
      onMouseLeave={() => setControlsVisible(false)}
      onClick={onClick}
    >
      <Box className={styles.classificationRuleContentWrapper}>
        {condition}
        <Box className={styles.classificationRuleOutput}>
          <Typography>
            → classify as <b>{rule.classification}</b>
          </Typography>
        </Box>
      </Box>
      <Box className={styles.classificationRuleContentControls} style={controlsStyle}>
        <Box className={styles.classificationRuleContentOrderingControls}>
          <IconButton
            size="small"
            onClick={e => {
              e.stopPropagation();
              nudgeRuleUp();
            }}
            style={{ visibility: isFirst || !controlsVisible ? 'hidden' : 'visible' }}
          >
            <ArrowUpward fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={e => {
              e.stopPropagation();
              nudgeRuleDown();
            }}
            style={{ visibility: isLast || !controlsVisible ? 'hidden' : 'visible' }}
          >
            <ArrowDownward fontSize="small" />
          </IconButton>
        </Box>
        <IconButton
          size="small"
          onClick={e => {
            e.stopPropagation();
            deleteRule();
          }}
        >
          <Delete fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export type ImageLevelClassificationCatchAllEntryProps = {
  catchAll: string;
  setIsEditingCatchAll: (newIsEditingCatchAll: boolean) => void;
};

const ImageLevelClassificationCatchAllEntry: React.FC<
  ImageLevelClassificationCatchAllEntryProps
> = ({ catchAll, setIsEditingCatchAll }) => {
  const styles = useStyles();
  return (
    <Box
      className={styles.classificationCatchAllWrapper}
      onClick={() => setIsEditingCatchAll(true)}
    >
      <Box className={styles.classificationRuleCondition}>
        <Typography>{t('If no rules apply')}</Typography>
      </Box>
      <Box className={styles.classificationRuleOutput}>
        <Typography>
          → classify as <b>{catchAll}</b>
        </Typography>
      </Box>
    </Box>
  );
};

export type ImageLevelClassificationSettingsProps = {};

const ImageLevelClassificationSettings: React.FC<ImageLevelClassificationSettingsProps> = () => {
  const styles = useStyles();
  const allDefects = useDefectSelector();
  const { state, dispatch } = useDataBrowserState();

  const setShowImageLevelClassification = useCallback(
    (show: boolean) => {
      dispatch(draft => {
        draft.TEMP_showImageLevelClassification = show;
      });
    },
    [dispatch],
  );

  const pushRule = useCallback(
    (rule: ImageLevelClassificationRule) => {
      dispatch(draft => {
        draft.TEMP_imageLevelClassificationRuleCollection.rules.push(rule);
      });
    },
    [dispatch],
  );

  const setRuleAtIndex = useCallback(
    (index: number, rule: ImageLevelClassificationRule) => {
      dispatch(draft => {
        draft.TEMP_imageLevelClassificationRuleCollection.rules[index] = rule;
      });
    },
    [dispatch],
  );

  const deleteRuleAtIndex = useCallback(
    (index: number) => {
      dispatch(draft => {
        draft.TEMP_imageLevelClassificationRuleCollection.rules.splice(index, 1);
      });
    },
    [dispatch],
  );

  const setCatchAll = useCallback(
    (catchAll: string) => {
      dispatch(draft => {
        draft.TEMP_imageLevelClassificationRuleCollection.defaultClassification = catchAll;
      });
    },
    [dispatch],
  );

  const swapRulesAtIndices = useCallback(
    (index1: number, index2: number) => {
      dispatch(draft => {
        const temp = draft.TEMP_imageLevelClassificationRuleCollection.rules[index1];
        draft.TEMP_imageLevelClassificationRuleCollection.rules[index1] =
          draft.TEMP_imageLevelClassificationRuleCollection.rules[index2];
        draft.TEMP_imageLevelClassificationRuleCollection.rules[index2] = temp;
      });
    },
    [dispatch],
  );

  const [isEditingRule, setIsEditingRule] = useState(false);
  const [isAddingRule, setIsAddingRule] = useState(false);
  const [currentEditedRuleIndex, setCurrentEditedRuleIndex] = useState<number | null>(null);
  const [isEditingCatchAll, setIsEditingCatchAll] = useState(false);

  const ruleEntries = state.TEMP_imageLevelClassificationRuleCollection.rules.map((rule, index) => (
    <ImageLevelClassificationRuleEntry
      rule={rule}
      key={index}
      defects={allDefects}
      deleteRule={() => deleteRuleAtIndex(index)}
      onClick={() => {
        setIsEditingRule(true);
        setIsAddingRule(false);
        setCurrentEditedRuleIndex(index);
      }}
      nudgeRuleUp={() => swapRulesAtIndices(index - 1, index)}
      nudgeRuleDown={() => swapRulesAtIndices(index, index + 1)}
      isFirst={index === 0}
      isLast={index === state.TEMP_imageLevelClassificationRuleCollection.rules.length - 1}
    />
  ));

  return isEditingCatchAll ? (
    <ImageLevelClassificationCatchAllEditor
      setIsEditingCatchAll={setIsEditingCatchAll}
      catchAll={state.TEMP_imageLevelClassificationRuleCollection.defaultClassification}
      setCatchAll={setCatchAll}
    />
  ) : isEditingRule ? (
    <ImageLevelClassificationRuleEditor
      setIsEditingRule={setIsEditingRule}
      isAddingRule={isAddingRule}
      setIsAddingRule={setIsAddingRule}
      pushRule={pushRule}
      setCurrentEditedRuleIndex={setCurrentEditedRuleIndex}
      rule={
        currentEditedRuleIndex === null
          ? undefined
          : state.TEMP_imageLevelClassificationRuleCollection.rules[currentEditedRuleIndex]
      }
      setRule={
        currentEditedRuleIndex === null
          ? undefined
          : newRule => setRuleAtIndex(currentEditedRuleIndex, newRule)
      }
    />
  ) : (
    <>
      <Typography>{t('Rules are checked from top to bottom.')}</Typography>
      <Box className={styles.classificationRuleList}>{ruleEntries}</Box>
      <Box className={styles.addRuleButtonWrapper}>
        <Button
          id="add-rule"
          variant="outlined"
          color="primary"
          startIcon={<Add />}
          onClick={() => {
            setIsEditingRule(true);
            setIsAddingRule(true);
            setCurrentEditedRuleIndex(null);
          }}
        >
          {t('Add another rule')}
        </Button>
      </Box>
      <ImageLevelClassificationCatchAllEntry
        catchAll={state.TEMP_imageLevelClassificationRuleCollection.defaultClassification}
        setIsEditingCatchAll={setIsEditingCatchAll}
      />
      <Box className={styles.classificationToggleWrapper}>
        <Checkbox
          color="primary"
          checked={state.TEMP_showImageLevelClassification}
          onChange={event => setShowImageLevelClassification(event.target.checked)}
        />
        <Typography>Show classification output</Typography>
      </Box>
    </>
  );
};

export default ImageLevelClassificationSettings;

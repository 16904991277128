import { TRANSFORM_TEXTS } from '@/constants/model_train';
import { Transform } from '@clef/shared/types';
import { Tooltip } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { getStepSize } from './SliderUtils';

const useStyles = makeStyles(({ spacing }) => ({
  slider: {
    width: 140,
  },
  configItem: {
    marginBottom: spacing(2),
    display: 'flex',
    gap: `${spacing(3)}px`,
    alignItems: 'center',
  },

  input: {
    padding: spacing(1),
    textAlign: 'center',
    height: 24,
    width: 45,
  },
  sliderName: {
    textTransform: 'capitalize',
    minWidth: 120,
  },
}));

export const RangeSlider = ({
  title,
  transform,
  lowerValue,
  upperValue,
  minKey,
  maxKey,
  updateTransformParam,
}: {
  minKey: string;
  maxKey: string;
  title: string;
  transform: Transform;
  lowerValue: number;
  upperValue: number;
  updateTransformParam(value: number[]): void;
}) => {
  const styles = useStyles();
  const [lowerParamInput, setLowerParamInput] = React.useState<number | string>(lowerValue);
  const [upperParamInput, setUpperParamInput] = React.useState<number | string>(upperValue);

  React.useEffect(() => {
    setLowerParamInput(lowerValue);
    setUpperParamInput(upperValue);
  }, [lowerValue, upperValue]);

  return (
    <div>
      <div className={styles.configItem}>
        <Tooltip title={TRANSFORM_TEXTS[title]?.tooltip ?? ''} arrow placement="top">
          <div className={styles.sliderName}>{title.replace(/_/g, ' ')}</div>
        </Tooltip>
        <OutlinedInput
          data-testid={'slider-lower-param'}
          classes={{ input: styles.input }}
          value={lowerParamInput}
          type="number"
          inputProps={{
            min: lowerValue,
            role: 'textbox',
          }}
          onChange={e => {
            setLowerParamInput(Math.max(lowerValue, Number(e.target.value)));
          }}
          onBlur={e => {
            setLowerParamInput(Number(e.target.value));
            updateTransformParam([Number(e.target.value), upperValue]);
          }}
          margin="dense"
        />
        <Slider
          className={styles.slider}
          max={Number(transform?.paramsDescription[maxKey].jsonSchema.maximum)}
          min={Number(transform?.paramsDescription[minKey].jsonSchema.minimum)}
          defaultValue={[lowerValue, upperValue]}
          value={[lowerParamInput as number, upperParamInput as number]}
          step={getStepSize(title, String(transform?.paramsDescription[minKey].jsonSchema.type))}
          onChange={(_, newValues) => {
            const values = newValues as number[];
            setLowerParamInput(values[0]);
            setUpperParamInput(values[1]);
          }}
          onChangeCommitted={(_, newValues) => {
            updateTransformParam(newValues as number[]);
          }}
        />
        <OutlinedInput
          data-testid={'slider-upper-param'}
          classes={{ input: styles.input }}
          value={upperParamInput}
          type="number"
          inputProps={{
            role: 'textbox',
          }}
          onChange={e => {
            setUpperParamInput(Number(e.target.value));
          }}
          onBlur={e => {
            setUpperParamInput(Number(e.target.value));
            updateTransformParam([lowerValue, Number(e.target.value)]);
          }}
          margin="dense"
        />
      </div>
    </div>
  );
};

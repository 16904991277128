import React, { useCallback, useMemo, useState } from 'react';
import { Box, LinearProgress, makeStyles } from '@material-ui/core';
import { useApiKeyListApi } from '@/hooks/api/useOrganizationApi';
import { IconButton, Typography, greyModernScale } from '@clef/client-library';
import emptyBox from '@/images/empty_box.svg';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { ApiKey, ApiKeyStatus, User } from '@clef/shared/types';
import { Column } from '@material-table/core';
import { startCase } from 'lodash';
import { formatDistance } from 'date-fns';
import MoreOptions from './MoreOptions';
import MatTable from '../Utils/MatTable';
import UnmaskedKeyDialog from './UnmaskedKeyDialog';
import { accountApiWithAuth } from '@/api/account_api';
import { useSnackbar } from 'notistack';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import StatusBadge from './StatusBadge';
import { dateDiffInDays } from '@/utils';

const useStyles = makeStyles(theme => ({
  emptyBoxIcon: {
    width: 120,
    height: 120,
  },
  emptyList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.greyModern[50],
    borderRadius: 20,
    minHeight: 260,
  },
  tableContainer: {
    '& .MuiPaper-root': {
      outline: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: 6,
      overflow: 'hidden',
      boxShadow: 'none !important',
    },
  },
  iconButton: {
    margin: theme.spacing(-3, 0),
  },
}));

export const tableStyleOptions: Record<string, React.CSSProperties> = {
  headerStyle: {
    backgroundColor: greyModernScale[50],
    height: 61,
  },
  rowStyle: {
    fontSize: 13,
    height: 61,
  },
};

export type KeyListProps = {
  ownerFilter?: 'anyone' | 'owner';
  keywordFilter?: string;
};

const KeyList: React.FC<KeyListProps> = props => {
  const { ownerFilter = 'anyone', keywordFilter = '' } = props;
  const styles = useStyles();
  const [apiKeyList, loading] = useApiKeyListApi({});
  const owner = useTypedSelector(state => state.login.user)!;
  const users = useTypedSelector(state => state.user.users);
  const usersById = useMemo(() => {
    return users.reduce((acc, user) => ({ ...acc, [user.id]: user }), {} as Record<string, User>);
  }, [users]);

  const getUserName = useCallback(
    (userId: string) => {
      const user = usersById[userId];
      if (!user) {
        return '';
      }
      const names = [user.name, user.lastName];
      if (userId === owner?.id) {
        names.push(t('(You)'));
      }
      return names.filter(Boolean).join(' ');
    },
    [usersById, owner],
  );

  const filteredList = useMemo(() => {
    let res = (apiKeyList ?? []).map(item => ({ ...item, creator: getUserName(item.userId) }));
    if (ownerFilter === 'owner') {
      res = res.filter(item => item.userId === owner.id);
    }
    if (keywordFilter) {
      const keywordLower = keywordFilter.toLocaleLowerCase();
      res = res.filter(item => Object.values(item).join('|').toLowerCase().includes(keywordLower));
    }
    return res;
  }, [apiKeyList, getUserName, keywordFilter, owner.id, ownerFilter]);

  const [displayedKey, setDisplayedKey] = useState<ApiKey>();

  const [loadingKey, setLoadingKey] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const columns: Column<ApiKey>[] = useMemo(() => {
    return [
      { title: t('Id'), field: 'id', hidden: true },
      {
        title: t('Name'),
        render: item => (
          <Typography variant="body1" width={300}>
            {item.name}
          </Typography>
        ),
        customSort: (a, b) => (b.name ?? '').localeCompare(a.name ?? ''),
      },
      {
        title: t('Key'),
        field: 'key',
        render: item =>
          item.userId === owner.id ? (
            <Box display="flex" alignItems="center">
              {item.key}
              <IconButton
                id="show-unmask-key"
                className={styles.iconButton}
                disabled={loadingKey}
                onClick={async () => {
                  setLoadingKey(true);
                  try {
                    const res = await accountApiWithAuth.getKeyById(item.id);
                    setDisplayedKey(res);
                  } catch (e) {
                    enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
                  }
                  setLoadingKey(false);
                }}
              >
                <VisibilityOutlined />
              </IconButton>
            </Box>
          ) : (
            item.key
          ),
      },
      {
        title: t('Creator'),
        render: item => getUserName(item.userId),
        customSort: (a, b) => {
          const nameA = getUserName(a.userId);
          const nameB = getUserName(b.userId);
          return nameB.localeCompare(nameA);
        },
      },
      {
        title: t('Created'),
        render: item =>
          t('{{distance}} ago', {
            distance: startCase(formatDistance(Date.now(), new Date(item.createdAt ?? 0))),
          }),
        customSort: (a, b) => {
          const dateA = new Date(a.createdAt ?? 0).getTime();
          const dateB = new Date(b.createdAt ?? 0).getTime();
          return dateA - dateB;
        },
        defaultSort: 'desc',
      },
      {
        title: t('Status'),
        render: item => item.status && <StatusBadge status={item.status as ApiKeyStatus} />,
        customSort: (a, b) => (b.status ?? '').localeCompare(a.status ?? ''),
      },
      {
        title: t('Last Used'),
        render: item => {
          if (item.lastUsedAt) {
            const diffDays = Math.round(dateDiffInDays(new Date(), new Date(item.lastUsedAt)));
            if (diffDays === 0) {
              return t('Today');
            } else if (diffDays === 1) {
              return t('1 day ago');
            } else {
              return t('{{diffDays}} days ago', { diffDays });
            }
          }
          return '-';
        },
        customSort: (a, b) => {
          const dateA = new Date(a.lastUsedAt ?? 0).getTime();
          const dateB = new Date(b.lastUsedAt ?? 0).getTime();
          return dateA - dateB;
        },
      },
      {
        title: '',
        align: 'right',
        sorting: false,
        width: 81,
        render: item => {
          return item.userId === owner.id ? <MoreOptions apiKey={item} /> : null;
        },
      },
    ];
  }, [enqueueSnackbar, getUserName, loadingKey, owner.id, styles.iconButton]);

  return (
    <Box>
      {apiKeyList && apiKeyList.length > 0 ? (
        <Box marginY={5}>
          <Box marginTop={5} position="relative" className={styles.tableContainer}>
            {(loading || loadingKey) && (
              <LinearProgress style={{ width: '100%', position: 'absolute', bottom: '100%' }} />
            )}
            <MatTable<ApiKey>
              title=""
              data={filteredList}
              columns={columns}
              options={{
                ...tableStyleOptions,
                search: false,
                toolbar: false,
                padding: 'dense',
              }}
            />
          </Box>
        </Box>
      ) : (
        <Box marginY={5} className={styles.emptyList}>
          <Box marginTop={5}>
            <img src={emptyBox} className={styles.emptyBoxIcon} />
          </Box>
          <Box marginTop={5}>
            <Typography variant="h4_semibold">{t(`You don't have any API keys yet`)}</Typography>
          </Box>
          <Box marginTop={3}>
            <Typography variant="body_regular">
              {t('Generate API keys to see them here')}
            </Typography>
          </Box>
        </Box>
      )}

      {displayedKey && (
        <UnmaskedKeyDialog
          apiKey={displayedKey}
          onClose={() => setDisplayedKey(undefined)}
          purpose="view"
        />
      )}
    </Box>
  );
};

export default KeyList;

import React from 'react';
import cx from 'classnames';
import { useLabelingReviewStyles } from '../labelingReviewStyles';
import { Typography } from '@material-ui/core';
import { useLabelingReviewState } from '../labelingReviewState';
import { LabelReviewStatus, LabelingType, LabelSource, Label } from '@clef/shared/types';
import LabelInstance from './LabelInstance';

export interface LabelingReviewMediaInfoPanelProps {
  labelingType?: LabelingType;
  showAgreement?: boolean;
}

const LabelingReviewMediaLabels: React.FC<
  LabelingReviewMediaInfoPanelProps & {
    labels: Label[];
    title: string;
    enableSelect: boolean;
  }
> = ({ labelingType, labels, title, enableSelect }) => {
  const styles = useLabelingReviewStyles();
  const {
    state: { currentMediaId, reviewMediaList, visibleLabel, reviewResult, isViewResultMode },
    dispatch,
  } = useLabelingReviewState();
  const { selectedLabel, reviewStatus } = reviewResult[currentMediaId] ?? {};
  const isRejected = reviewStatus === LabelReviewStatus.Rejected;
  const mediaDetails = reviewMediaList.find(media => media.id === currentMediaId)!;

  const { bestLabelId } = mediaDetails;
  const finalSelectedLabelId = selectedLabel ?? bestLabelId;

  return (
    <div className={styles.panelSection} data-testid="panel-label">
      <Typography variant="subtitle1" className={styles.leftPanelSubtitle}>
        {title}
      </Typography>
      {labels.map(label => {
        const isSelected = !isRejected && finalSelectedLabelId === label.id;
        const isVisible = !visibleLabel || visibleLabel === label.id;
        return (
          <LabelInstance
            label={label}
            key={label.id}
            enableSelect={!isViewResultMode && enableSelect}
            isVisible={isVisible}
            labelingType={labelingType}
            onToggleVisibility={(visibility, visibleDefect, visibleAnnotation) =>
              dispatch(draft => {
                if (visibility) {
                  draft.visibleLabel = label.id;
                  draft.visibleDefect = visibleDefect;
                  draft.visibleAnnotation = visibleAnnotation;
                } else {
                  draft.visibleLabel = isRejected ? undefined : finalSelectedLabelId;
                  draft.visibleDefect = undefined;
                  draft.visibleAnnotation = undefined;
                }
              })
            }
            isSelected={isSelected && enableSelect}
            onSelectLabel={() => {
              dispatch(draft => {
                draft.reviewResult[currentMediaId].selectedLabel = label.id;
                draft.reviewResult[currentMediaId].reviewStatus = LabelReviewStatus.NotReviewed;
              });
            }}
          />
        );
      })}
    </div>
  );
};

const LabelingReviewMediaInfoPanel: React.FC<LabelingReviewMediaInfoPanelProps> = ({
  showAgreement = false,
  labelingType,
}) => {
  const styles = useLabelingReviewStyles();
  const {
    state: { currentMediaId, reviewMediaList },
  } = useLabelingReviewState();
  const mediaDetails = reviewMediaList.find(media => media.id === currentMediaId)!;
  const overallAgreementScore = mediaDetails?.overallAgreementScore ?? 0;
  const { labels } = mediaDetails;

  const labelerLabels = labels.filter(
    label => !label.source || label.source === LabelSource.Labeling,
  );
  const reviewerLabels = labels.filter(label => label.source === LabelSource.LabelingReviewEdit);
  const enableSelect = labels.length > 1;

  return (
    <section
      className={cx(styles.panelContainer, styles.mediaInfoPanelContainer)}
      data-testid="labeling-review-media-info-panel"
    >
      {showAgreement && (
        <div className={cx(styles.panelSection, styles.panelSectionImportant)}>
          <Typography variant="h4" className={styles.panelSectionH4}>
            {t('Media Agreement')}
          </Typography>
          <Typography variant="h2">{overallAgreementScore?.toFixed(2)}</Typography>
        </div>
      )}
      {/* Labels */}
      {reviewerLabels.length > 0 && (
        <LabelingReviewMediaLabels
          labels={reviewerLabels}
          title={t('Reviewer Edits')}
          labelingType={labelingType}
          enableSelect={enableSelect}
        />
      )}
      <LabelingReviewMediaLabels
        labels={labelerLabels}
        title={t("Labelers' Labels")}
        labelingType={labelingType}
        enableSelect={enableSelect}
      />
    </section>
  );
};

export default LabelingReviewMediaInfoPanel;

import React, { useCallback } from 'react';
import { Dropdown, FilterPill } from '@clef/client-library';
import { useDeviceMonitorStyles } from '../deviceMonitorStyles';
import { Typography, Checkbox } from '@material-ui/core';
import { useDeviceMonitorState } from '../deviceMonitorState';
import { MediaLevelLabel } from '@clef/shared/types';

export interface HumanJudgementFilterProps {}

const HumanJudgementFilter: React.FC<HumanJudgementFilterProps> = () => {
  const styles = useDeviceMonitorStyles();
  const {
    state: { filterHumanJudgement },
    dispatch,
  } = useDeviceMonitorState();

  const onFilterOptionChange = useCallback(
    (optionValue: string) => () => {
      const newSelectedFilterOption = filterHumanJudgement.includes(optionValue)
        ? filterHumanJudgement.filter(option => option !== optionValue)
        : [...filterHumanJudgement, optionValue];
      dispatch(draft => {
        draft.filterHumanJudgement = newSelectedFilterOption;
      });
    },
    [dispatch, filterHumanJudgement],
  );

  return (
    <Dropdown
      extraGutter={{ vertical: 12 }}
      dropdown={
        <div className={styles.dropdownPanelList}>
          {[MediaLevelLabel.OK, MediaLevelLabel.NG]?.map((label, i) => (
            <div
              key={`media-level-label-${i}`}
              onClick={onFilterOptionChange(label)}
              className={styles.optionItem}
              data-testid="filter-dropdown-option"
            >
              <Checkbox
                color="primary"
                size="small"
                checked={filterHumanJudgement.includes(label)}
                onChange={onFilterOptionChange(label)}
              />
              <Typography variant="body1" component="span">
                {label === MediaLevelLabel.OK && t('Correct')}
                {label === MediaLevelLabel.NG && t('Incorrect')}
              </Typography>
            </div>
          ))}
        </div>
      }
    >
      {open => (
        <FilterPill
          filterName={t('Human Judgment')}
          badgeContent={filterHumanJudgement.length}
          open={open}
        />
      )}
    </Dropdown>
  );
};

export default HumanJudgementFilter;

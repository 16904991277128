import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Grid, makeStyles, Menu, MenuItem, Popover } from '@material-ui/core';
import { Button, defectColors, MediaInteractiveCanvas, useKeyPress } from '@clef/client-library';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useLabelingState } from '../../../components/Labeling/labelingState';
import { useDefectSelector } from '../../../store/defectState/actions';
import CreateDefectDialog from '../../DefectBook/DefectList/CreateDefectDialog';
import { useInstantLearningState } from '../state';
import ClassColorPicker from './ClassColorPicker';
import { DefectItem } from '../../../components/Labeling/ToolDefectCard';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(theme => ({
  classSelector: {
    width: 150,
  },
  selectClassButton: {
    minWidth: 200,
  },
  codeBlock: {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 4,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: theme.spacing(1),
    fontWeight: 700,
  },
  classChip: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    marginTop: theme.spacing(1),
    borderRadius: 8,
    border: `2px solid transparent`,
    fontWeight: 400,
    fontStyle: 'italic',
    padding: theme.spacing(1, 3, 1, 2),
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    '&.MuiButton-root:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
    },
  },
  classChipLabel: {
    justifyContent: 'flex-start',
  },
  classChipText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  classChipSelected: {
    borderColor: theme.palette.primary.main,
    fontWeight: 700,
  },
  addClassLabel: {
    fontStyle: 'normal',
    justifyContent: 'flex-start',
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(1),
    fontWeight: 700,
  },
  classColor: {
    width: 24,
    height: 24,
    borderRadius: 4,
    marginRight: theme.spacing(2),
    flexShrink: 0,
  },
  classSelectorPaper: {
    overflowY: 'visible',
    overflowX: 'visible',
    width: 512,
  },
  toolCard: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    borderWidth: 2,
    position: 'relative',
    borderStyle: 'solid',
    borderColor: 'transparent',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    padding: theme.spacing(1),
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'unset',
    pointerEvents: 'auto',
    marginRight: theme.spacing(2),
    boxShadow: '0px 8px 24px rgba(0, 53, 133, 0.1)',
  },
  control: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.grey[200],
    textAlign: 'center',
    lineHeight: '20px',
    borderRadius: 2,
    marginLeft: theme.spacing(4),
  },
  defectItem: {
    width: '150px',
    '& p': {
      width: 'auto',
    },
  },
  openClassesMenu: {
    top: '64px',
  },
}));

export type SelectClassButtonProps = {
  mediaCanvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
  onMenuButtonClick?: () => void | Promise<void>;
};

const ClassSelector: React.FC<SelectClassButtonProps> = props => {
  const { mediaCanvasRef, onMenuButtonClick } = props;
  const styles = useStyles();
  const {
    state: { selectedDefect },
    dispatch,
  } = useLabelingState();
  const {
    state: { browseImagesMode },
  } = useInstantLearningState();
  const allDefects = useDefectSelector();
  const popupState = usePopupState({
    popupId: 'select-class-menu',
    variant: 'popover',
  });
  const selectClassButtonRef = useRef<HTMLButtonElement | null>(null);
  useKeyPress('c', () => {
    popupState.setOpen(!popupState.isOpen, selectClassButtonRef.current as HTMLElement);
  });
  const defects = useDefectSelector();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  const currentDefectIndex = useMemo(
    () => allDefects.findIndex(d => d.id === selectedDefect?.id),
    [allDefects, selectedDefect?.id],
  );

  useEffect(() => {
    if (selectedDefect && !allDefects?.find(defect => defect.id === selectedDefect?.id)) {
      dispatch(draft => {
        draft.selectedDefect = undefined;
      });
    }
  }, [selectedDefect, allDefects]);

  useKeyPress('up', () => {
    if (!browseImagesMode && currentDefectIndex > 0) {
      dispatch(draft => {
        draft.selectedDefect = allDefects[currentDefectIndex - 1];
      });
    }
  });
  useKeyPress('down', () => {
    if (
      !browseImagesMode &&
      currentDefectIndex >= 0 &&
      currentDefectIndex < allDefects.length - 1
    ) {
      dispatch(draft => {
        draft.selectedDefect = allDefects[currentDefectIndex + 1];
      });
    }
  });

  const [classColorPickerAnchor, setClassColorPickerAnchor] = useState<HTMLDivElement>();
  const [openClassesMenu, setOpenClassesMenu] = useState(false);
  const anchorEl = useRef<null | HTMLDivElement>(null);

  useKeyPress('c', () => setOpenClassesMenu(!openClassesMenu), { id: 'instant-select-defect' });

  const [hoveringMenuItemKey, setHoveringMenuItemKey] = useState<number | undefined>(undefined);

  return (
    <Box className={styles.classSelector}>
      <Grid
        aria-controls="select-defect-menu"
        ref={anchorEl}
        onClick={() => {
          onMenuButtonClick?.();
          setOpenClassesMenu(!openClassesMenu);
        }}
        container
        wrap="nowrap"
        alignItems="center"
        role="button"
        aria-label="select-defect"
      >
        <Box display="flex" className={styles.toolCard}>
          {selectedDefect ? (
            <DefectItem defect={selectedDefect} className={styles.defectItem} hideInfo />
          ) : (
            <Button
              id="add-class"
              classes={{ root: styles.classChip, label: styles.addClassLabel }}
              onClick={() => setOpenCreateDialog(true)}
            >
              {t('+ Add Class')}
            </Button>
          )}
          <ArrowDropDown />
          <Box className={styles.control}>{t('c')}</Box>
        </Box>
      </Grid>
      <Menu
        open={openClassesMenu}
        anchorEl={anchorEl.current}
        onClose={() => setOpenClassesMenu(false)}
        style={{ top: '56px' }}
        variant="menu"
      >
        {(allDefects ?? []).map(defect => (
          <MenuItem
            onMouseEnter={() => {
              setHoveringMenuItemKey(defect.id);
            }}
            onMouseLeave={() => {
              setHoveringMenuItemKey(undefined);
            }}
            key={defect.id}
            onClick={() => {
              dispatch(draft => {
                draft.selectedDefect = defect;
              });
              setOpenClassesMenu(false);
            }}
            selected={defect.id === selectedDefect?.id}
          >
            <DefectItem hideInfo={hoveringMenuItemKey !== defect.id} defect={defect} />
          </MenuItem>
        ))}
        <MenuItem>
          <Button
            id="add-class"
            classes={{ root: styles.classChip, label: styles.addClassLabel }}
            onClick={() => setOpenCreateDialog(true)}
          >
            {t('+ Add Class')}
          </Button>
        </MenuItem>
      </Menu>
      {openCreateDialog && (
        <CreateDefectDialog
          onClose={() => setOpenCreateDialog(false)}
          nextDefectIndex={(defects?.length ?? 0) % defectColors.length}
          alertText={t(
            // eslint-disable-next-line max-len
            'You can delete inactive classes before they are used to annotate your dataset. To delete an active defect class, either delete all label instances or delete the labeled images.',
          )}
          onCreateSuccess={defect => {
            dispatch(draft => {
              draft.selectedDefect = defect;
            });
            setOpenCreateDialog(false);
            popupState.close();
          }}
        />
      )}
      <Popover
        open={!!classColorPickerAnchor}
        anchorEl={classColorPickerAnchor}
        classes={{ paper: styles.classSelectorPaper }}
        onClose={() => setClassColorPickerAnchor(undefined)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ClassColorPicker
          selectedDefect={selectedDefect}
          onClose={() => setClassColorPickerAnchor(undefined)}
          mediaCanvasRef={mediaCanvasRef}
        />
      </Popover>
    </Box>
  );
};

export default ClassSelector;

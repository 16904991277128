import React from 'react';
import cx from 'classnames';
import { Annotation, Defect, Media, MediaDetailsWithPrediction } from '@clef/shared/types';
import { MediaViewer } from '@clef/client-library';
import { makeStyles } from '@material-ui/core';
import { getBoxAnnotations, getSegmentAnnotations } from '../../../utils';
import { getThumbnail } from '@clef/client-library/';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
}));

interface ExampleMediaContainerProps {
  media: Media & MediaDetailsWithPrediction;
  defects: Defect[];
  thumbnailSize?: 'medium' | 'large';
  enableGrayscaleImage?: boolean;
}

const ExampleMediaContainer: React.FC<ExampleMediaContainerProps> = ({
  media,
  defects,
  thumbnailSize = 'medium',
  enableGrayscaleImage = false,
}) => {
  const styles = useStyles();
  const { properties } = media;

  const annotations = (media?.label?.annotations as Annotation[]) ?? [];
  const boxAnnotations = getBoxAnnotations(annotations, defects!);
  const segmentationAnnotations = getSegmentAnnotations(annotations, defects!);
  const imageSrc = getThumbnail(media, thumbnailSize);

  return (
    <div className={cx(styles.container)}>
      <MediaViewer
        imgSrc={imageSrc ? imageSrc : undefined}
        fallbackImgSrc={media?.url}
        properties={properties}
        boxAnnotations={boxAnnotations}
        segmentationAnnotations={segmentationAnnotations}
        enableGrayscaleImage={enableGrayscaleImage}
        imageViewMode="cover"
      />
    </div>
  );
};

export default ExampleMediaContainer;

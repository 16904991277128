import React, { useCallback, useMemo, useState } from 'react';
import { LabelType, RegisteredModel, UserPermission } from '@clef/shared/types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  MenuItem,
  MenuList,
  Tooltip,
} from '@material-ui/core';
import { Button, Dropdown, IconButton, Typography } from '@clef/client-library';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';
import { useHistory } from 'react-router';
import CLEF_PATH from '@/constants/path';
import LoadingProgress from '@/pages/model_iteration/componentsV2/LoadingProgress';
import {
  useModelPerformanceConfusionMatricesData,
  useModelPerformanceWithImageCountData,
} from './useModelPerformanceSummaryData';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { DownloadIcon } from '@/images/media_details/ToolIcons';
import ConfusionMatrixAccordion from './ConfusionMatrixAccordion';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { MediaAllSplitName, MediaSplitName } from '@/constants/stats_card';
import useGetDefectById from '@/hooks/defect/useGetDefectById';
import InfoIcon from '@material-ui/icons/Info';
import TryModelDialog from './TryModelDialog';
import { useSnackbar } from 'notistack';
import DatasetAPI from '@/api/dataset_api';
import MoreButton from './MoreButton';
import { useHasPermission } from '@/hooks/useProjectRolePermissions';
import { useIsFeatureEnabledAndMayHideForSnowflake } from '@/hooks/useFeatureGate';
import { useIsLargeImageModel } from '@/hooks/useIsLargeImageModel';

interface ModelDetailsDialogPerformanceSummaryProps {
  model: RegisteredModel;
  open: boolean;
  onClose: () => void;
}

interface CommonDropdownProps {
  options: { id: string; name: string }[];
  selectedOptionId: string;
  setSelectedOptionId: (option: string) => void;
}

const CommonDropdown = (props: CommonDropdownProps) => {
  const { options, selectedOptionId, setSelectedOptionId } = props;
  const selectedOptionName = options.find(option => option.id === selectedOptionId)?.name;
  return (
    <Dropdown
      dropdown={toggleDropdown => (
        <MenuList>
          {options.map(option => (
            <MenuItem
              key={selectedOptionId}
              selected={option.id === selectedOptionId}
              onClick={() => {
                setSelectedOptionId(option.id);
                toggleDropdown(false);
              }}
            >
              <Typography variant="body_medium">{t(option.name)}</Typography>
            </MenuItem>
          ))}
        </MenuList>
      )}
    >
      <Box display="flex" alignItems="center" paddingLeft={4}>
        <Typography variant="body_medium">{selectedOptionName}</Typography>
        <Box paddingTop={1} paddingLeft={1}>
          <ExpandMore fontSize="small" />
        </Box>
      </Box>
    </Dropdown>
  );
};

const getMetricsOptions = (labelType?: LabelType | null) => [
  { id: 'f1', name: labelType === LabelType.Segmentation ? t('IOU') : t('F1') },
  { id: 'precision', name: t('Precision') },
  { id: 'recall', name: t('Recall') },
];

const setOptions = [
  { id: MediaAllSplitName, name: t('All sets') },
  { id: MediaSplitName.Train, name: t('Train set') },
  { id: MediaSplitName.Dev, name: t('Dev set') },
  { id: MediaSplitName.Test, name: t('Test set') },
];

const ModelDetailsDialogPerformanceSummary = (props: ModelDetailsDialogPerformanceSummaryProps) => {
  const { model, open, onClose } = props;
  const styles = useStyles();
  const history = useHistory();
  const getDefectById = useGetDefectById();
  const { id: projectId, labelType } = useGetSelectedProjectQuery().data ?? {};
  const { performanceArrayWithImageCount } = useModelPerformanceWithImageCountData(model);
  const {
    allConfusionMatricesArray,
    trainConfusionMatricesArray,
    devConfusionMatricesArray,
    testConfusionMatricesArray,
    isLoading: isModelPerformanceConfusionMatricesLoading,
  } = useModelPerformanceConfusionMatricesData(model);
  const [selectedPerformanceMetrics, setSelectedPerformanceMetrics] = useState<
    'f1' | 'precision' | 'recall'
  >('f1');
  const [selectedSet, setSelectedSet] = useState(MediaAllSplitName);
  const [selectedDefect, setSelectedDefect] = useState<string>('');
  const [tryModelDialogOpen, setTryModelDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [downloadCsvLoading, setDownloadCsvLoading] = useState(false);

  const handleDownloadCsv = useCallback(
    async event => {
      event.stopPropagation();
      if (!projectId) return;
      try {
        setDownloadCsvLoading(true);
        const res = await DatasetAPI.getModelPerformanceCsv(projectId, model.id);
        window.open(res, '_top');
      } catch (e) {
        enqueueSnackbar('Failed to download CSV. Please try again later.', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      } finally {
        setDownloadCsvLoading(false);
      }
    },
    [enqueueSnackbar, projectId, model.id],
  );

  const defectOptions = useMemo(() => {
    const defectSet = new Set<number>();
    const defectOptions: { id: string; name: string }[] = [{ id: '', name: 'All classes' }];
    allConfusionMatricesArray.forEach(confusionMatrix => {
      confusionMatrix?.data?.forEach(cmItem => {
        cmItem.gtClassId && defectSet.add(cmItem.gtClassId);
        cmItem.predClassId && defectSet.add(cmItem.predClassId);
      });
    });
    defectSet.forEach(value => {
      defectOptions.push({ id: value.toString(), name: getDefectById(value)?.name });
    });
    return defectOptions;
  }, [allConfusionMatricesArray]);

  const getSelectedConfusionMatrices = useCallback(
    (selectedSet: string) => {
      switch (selectedSet) {
        case MediaSplitName.Train:
          return trainConfusionMatricesArray;
        case MediaSplitName.Dev:
          return devConfusionMatricesArray;
        case MediaSplitName.Test:
          return testConfusionMatricesArray;
        default:
          return allConfusionMatricesArray;
      }
    },
    [
      allConfusionMatricesArray,
      trainConfusionMatricesArray,
      devConfusionMatricesArray,
      testConfusionMatricesArray,
    ],
  );
  const selectedConfusionMatricesArray = useMemo(
    () => getSelectedConfusionMatrices(selectedSet),
    [selectedSet, getSelectedConfusionMatrices],
  );
  const filteredSelectedConfusionMatricesArray = useMemo(() => {
    return selectedConfusionMatricesArray.map(confusionMatrix => {
      if (selectedDefect) {
        const filteredData = confusionMatrix.data?.filter(
          item =>
            item.gtClassId === Number(selectedDefect) ||
            item.predClassId === Number(selectedDefect),
        );
        return { ...confusionMatrix, data: filteredData };
      }
      return confusionMatrix;
    });
  }, [selectedDefect, selectedConfusionMatricesArray]);

  const canDeployModel = useHasPermission(UserPermission.DeployModel);
  const isPredictButtonEnabled = useIsFeatureEnabledAndMayHideForSnowflake().predictButton;

  const [isLargeImageModel, loading] = useIsLargeImageModel(model.id);

  const handleViewModelsPage = () => {
    onClose();
    history.push(`${CLEF_PATH.modelsV2.list}?modelId=${model.id}`);
  };

  const handleTryModel = () => {
    setTryModelDialogOpen(open);
  };

  const modelHasNoConfusionMatrix =
    allConfusionMatricesArray.reduce((acc, cur) => {
      return acc + cur.count;
    }, 0) === 0;

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogTitle className={styles.modelDetailsTrainingInProgressDialogTitle}>
        <Box
          display="flex"
          flexDirection={'row'}
          justifyContent="space-between"
          alignItems={'center'}
        >
          <Box display="flex" flexDirection={'row'} alignItems={'center'}>
            <Box paddingRight={4}>
              <Typography variant={'h2_semibold'}>{model.modelName}</Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection={'row'} alignItems={'center'}>
            {isPredictButtonEnabled && canDeployModel && (
              <Box paddingRight={4}>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    isLargeImageModel ? (
                      <span>
                        <Typography variant="body2">
                          {t('This model is incompatible with cloud inference.')}
                        </Typography>
                        <Typography variant="body2">
                          {t('Use LandingEdge to run predictions.')}
                        </Typography>
                      </span>
                    ) : (
                      ''
                    )
                  }
                >
                  <span>
                    <Button
                      id="view-on-models-page"
                      color="primary"
                      variant={'contained'}
                      onClick={handleTryModel}
                      disabled={isLargeImageModel || loading}
                    >
                      <Typography
                        className={styles.tryModelBtnModelDetailsPerformance}
                        variant="body_semibold"
                      >
                        {t('Try Model')}
                      </Typography>
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            )}
            {labelType !== LabelType.Segmentation && (
              <Box paddingRight={4}>
                <Button
                  id="view-on-models-page"
                  startIcon={<DownloadIcon />}
                  variant={'outlined'}
                  onClick={handleDownloadCsv}
                  disabled={!model.id || !projectId || downloadCsvLoading}
                >
                  <Typography variant="body_semibold">
                    {downloadCsvLoading ? t('Downloading') : t('Download CSV')}
                  </Typography>
                </Button>
              </Box>
            )}
            <Box paddingX={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <MoreButton model={model} />
            </Box>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className={styles.modelDetailsPerformanceDialogContent}>
        {isModelPerformanceConfusionMatricesLoading ? (
          <Box
            minHeight={400}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <LoadingProgress size={32} />
          </Box>
        ) : modelHasNoConfusionMatrix ? (
          <Box display="flex" flexDirection={'column'} height={80} className={styles.fontSize16}>
            {t(
              'This model has not been evaluated before, please go to {{modelsPage}} and click {{evaluate}} button in {{trainDevTest}} column for metrics results.',
              {
                modelsPage: (
                  <Link
                    className={styles.linkText}
                    onClick={() => {
                      history.push(`${CLEF_PATH.modelsV2.list}?modelId=${model.id}`);
                    }}
                    color="primary"
                  >
                    {t('Models Page')}
                  </Link>
                ),
                evaluate: <strong>{t('Evaluate')}</strong>,
                trainDevTest: <strong>{t('Train/Dev/Test')}</strong>,
              },
            )}
          </Box>
        ) : (
          <Box display="flex" flexDirection={'column'} height="100%">
            <Box
              display="flex"
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems="center"
              width="100%"
              className={styles.viewFullReportContainer}
              marginBottom={4}
            >
              <Typography variant="body_regular">
                {t(
                  'To adjust the confidence threshold, view visual predictions, or add your own evaluation set, please access the full report on the Models page.',
                )}
              </Typography>
              <Box
                display="flex"
                flexDirection={'row'}
                alignItems="center"
                justifyContent={'flex-end'}
                width={600}
              >
                <Button
                  id={'view-full-report'}
                  variant="text"
                  onClick={handleViewModelsPage}
                  className={styles.viewFullReportBtn}
                >
                  {t('View Full Report')}
                </Button>
              </Box>
            </Box>
            {/* performance */}
            <Box display="flex" flexDirection={'column'} marginBottom={1} width="100%">
              <Box
                display="flex"
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography variant="h4_semibold">{t('Performance')}</Typography>
                <CommonDropdown
                  options={getMetricsOptions(labelType)}
                  selectedOptionId={selectedPerformanceMetrics}
                  setSelectedOptionId={setSelectedPerformanceMetrics as (option: string) => void}
                />
              </Box>
              <Box
                display="flex"
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Box display="flex" flexDirection={'row'} paddingTop={3} paddingBottom={3}>
                  {performanceArrayWithImageCount.map(item => {
                    return (
                      <Box
                        key={item.setName}
                        display="flex"
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        width={160}
                      >
                        <Box className={styles.modelDetailsDialogPerformanceNumber}>
                          {item[selectedPerformanceMetrics]}
                        </Box>
                        <Box className={styles.modelDetailsDialogPerformanceSetText}>{`${
                          item.setName
                        } (${item.imageCount === undefined ? '--' : item.imageCount})`}</Box>
                      </Box>
                    );
                  })}
                  {labelType !== LabelType.Classification && (
                    <Box
                      display="flex"
                      flexDirection={'row'}
                      alignItems={'center'}
                      width={200}
                      paddingLeft={10}
                    >
                      <Box paddingRight={1}>
                        <Typography className={styles.gm600} variant="body_regular">
                          {t('Threshold: ')}
                        </Typography>
                      </Box>
                      <Typography className={styles.gm600} variant="body_bold">
                        {model.confidence}
                      </Typography>
                      <Tooltip
                        placement="top"
                        title={t(
                          'Confidence threshold with the best F1 score for all labeled data when training the model.',
                        )}
                        arrow
                      >
                        <InfoIcon className={styles.modelDetailsDialogInfoIcon} />
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            {/* confusion matrix */}
            <Box display="flex" flexDirection={'column'} width="100%">
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent="space-between"
                alignItems={'center'}
                width="100%"
                marginBottom={3}
              >
                <Typography variant="h4_semibold">{t('Confusion matrix')}</Typography>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent="flex-end"
                  alignItems={'center'}
                >
                  <CommonDropdown
                    options={setOptions}
                    selectedOptionId={selectedSet}
                    setSelectedOptionId={setSelectedSet}
                  />
                  <CommonDropdown
                    options={defectOptions}
                    selectedOptionId={selectedDefect}
                    setSelectedOptionId={setSelectedDefect}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection={'row'} marginBottom={3}>
                <Box className={styles.firstCol}>
                  <Typography variant="body_bold">{t('Ground Truth')}</Typography>
                </Box>
                <Box className={styles.midCol}>
                  <Typography variant="body_bold">{t('Prediction')}</Typography>
                </Box>
                <Box className={styles.lastCol}>
                  <Typography variant="body_bold">{t('Count')}</Typography>
                </Box>
              </Box>
              {filteredSelectedConfusionMatricesArray.map(confusionMatrix => {
                return (
                  <ConfusionMatrixAccordion
                    key={confusionMatrix.name}
                    name={confusionMatrix.name}
                    data={confusionMatrix.data}
                    isCorrect={confusionMatrix.isCorrect}
                  />
                );
              })}
            </Box>
          </Box>
        )}
      </DialogContent>
      {tryModelDialogOpen && (
        <TryModelDialog
          model={model}
          onClose={() => {
            setTryModelDialogOpen(false);
          }}
        />
      )}
    </Dialog>
  );
};

export default ModelDetailsDialogPerformanceSummary;

import { Button, useKeyPress } from '@clef/client-library';
import { Box, Drawer, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { useDataBrowserState } from '../dataBrowserState';
import { SidebarWidth, useDataBrowserStyles } from '../styles';
import InstantLearningModelsSidebar from './InstantLearningModelsSidebar';
import { useDefectSelector } from '../../../store/defectState/actions';
import experiment_report_api from '@/api/experiment_report_api';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import cx from 'classnames';
import { FirstRunLiveExperienceDialog } from '@/pages/DataBrowser/FirstRunLiveExperienceDialog';
import { LabelType } from '@clef/shared/types';
import { useTypographyStyles } from '@clef/client-library/src/themes/typography';
import useZeroAuthInstantLearningStyles from '@/pages/ZeroAuthInstantLearning/styles';

const useStyles = makeStyles(theme => ({
  rightSidebar: {
    position: 'absolute',
    width: SidebarWidth,
  },
  verticalLine: {
    borderLeft: `1px solid ${theme.palette.greyModern[300]}`,
    height: 24,
    margin: theme.spacing(0, 3),
  },
}));

type InstantLearningModelsProps = {};

const useRefreshProcessingState = () => {
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const allDefects = useDefectSelector();
  const { dispatch } = useDataBrowserState();
  const refresh = useCallback(async () => {
    if (allDefects.length !== 0 && projectId) {
      dispatch(draft => {
        draft.TEMP_postProcessingLoading = true;
      });
      const res = await experiment_report_api.loadPostProcessing(projectId);

      dispatch(draft => {
        if (res.data) {
          draft.TEMP_defectIdToSegmentationAreaThreshold = Object.entries(
            res.data.noiseFilter,
          ).reduce((lookup, item) => {
            const [defectId, filterValue] = item;
            lookup[parseInt(defectId)] = filterValue === -1 ? Infinity : filterValue;

            return lookup;
          }, {} as Record<number, number>);
          draft.TEMP_imageLevelClassificationRuleCollection = res.data.classificationRules;
        }
        draft.TEMP_postProcessingLoading = false;
      });
    }
  }, [allDefects.length, dispatch, projectId]);

  return refresh;
};

const InstantLearningModels: React.FC<InstantLearningModelsProps> = () => {
  const zeroAuthInstantLearningStyles = useZeroAuthInstantLearningStyles();
  const typographyStyles = useTypographyStyles();
  const styles = useStyles();
  const dataBrowserStyles = useDataBrowserStyles();
  const {
    state: { rightSidebar, isFirstRunLiveExperienceModalOpen },
    dispatch,
  } = useDataBrowserState();
  const selected = rightSidebar === 'model_performance';

  const refreshPostProcessState = useRefreshProcessingState();

  useEffect(() => {
    refreshPostProcessState();
  }, [refreshPostProcessState]);

  useKeyPress(
    'm',
    useCallback(() => {
      dispatch(draft => {
        draft.rightSidebar = selected ? undefined : 'model_performance';
      });
    }, [dispatch, selected]),
  );

  return (
    <>
      <Box
        display="flex"
        height={34}
        alignItems="center"
        className={dataBrowserStyles.visualPromptingAndModelsButton}
      >
        <Box className={typographyStyles.h4_semibold}>{t('Visual Prompting')}</Box>
        <Box className={cx(typographyStyles.body_bold, zeroAuthInstantLearningStyles.betaBadge)}>
          {t('Beta')}
        </Box>
        <div className={styles.verticalLine}></div>
        <Button
          id="open-instant-learning-models-sidebar-button"
          color="primary"
          variant="outlined"
          className={cx(
            selected ? dataBrowserStyles.toolbarButtonSelected : dataBrowserStyles.toolbarButton,
            dataBrowserStyles.instantLearningModelsButton,
          )}
          onClick={() => {
            dispatch(draft => {
              draft.rightSidebar = selected ? undefined : 'model_performance';
            });
          }}
        >
          {t('Models')}
        </Button>
      </Box>
      <Drawer
        open={rightSidebar === 'model_performance'}
        variant="persistent"
        classes={{ paper: styles.rightSidebar }}
        anchor="right"
      >
        <InstantLearningModelsSidebar />
      </Drawer>
      {isFirstRunLiveExperienceModalOpen && (
        <FirstRunLiveExperienceDialog labelType={LabelType.SegmentationInstantLearning} />
      )}
    </>
  );
};

export default InstantLearningModels;

import React, { useCallback, useState } from 'react';
import { alpha, Box, Grid, makeStyles, Tooltip, Typography, withStyles } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import Close from '@material-ui/icons/Close';
import cx from 'classnames';

import { CacheStorage, CacheStorageKey, Button } from '@clef/client-library';

import UploadSvg from '../../../images/workflow_tutorial/upload.svg';
import UploadHoveredSvg from '../../../images/workflow_tutorial/upload_hovered.svg';
import LabelSvg from '../../../images/workflow_tutorial/label.svg';
import LabelHoveredSvg from '../../../images/workflow_tutorial/label_hovered.svg';
import TrainSvg from '../../../images/workflow_tutorial/train.svg';
import TrainHoveredSvg from '../../../images/workflow_tutorial/train_hovered.svg';
import PredictSvg from '../../../images/workflow_tutorial/predict.svg';
import PredictHoveredSvg from '../../../images/workflow_tutorial/predict_hovered.svg';
import UploadGif from '@/images/workflow_tutorial/upload.gif';
import LabelGif from '@/images/workflow_tutorial/label.gif';
import TrainGif from '@/images/workflow_tutorial/train.gif';
import PredictGif from '@/images/workflow_tutorial/predict.gif';
import { useCreateProjectMutation } from '@/serverStore/projects';
import { useIsFeatureEnabledAndMayHideForSnowflake } from '@/hooks/useFeatureGate';

const WORKFLOW_BUTTON_SIZE = 80;
const SVG_SIZE = 45;

const useStyles = makeStyles(theme => ({
  workflowButton: {
    background: theme.palette.greyBlue[100],
    width: WORKFLOW_BUTTON_SIZE,
    height: WORKFLOW_BUTTON_SIZE,
    scale: 1,
    transition: 'scale 0.25s',
    marginBottom: theme.spacing(3),
    '&:hover': {
      background: theme.palette.blue[500],
      scale: 1.1,
    },
    '& .svg': {
      width: SVG_SIZE,
      display: 'flex',
    },
    '&:hover .svg': {
      display: 'none',
    },
    '& .svgHovered': {
      width: SVG_SIZE,
      display: 'none',
    },
    '&:hover .svgHovered': {
      display: 'flex',
    },
  },
  workflowButtonHintText: {
    fontWeight: 500,
    fontSize: 14,
  },
  workflowButtonStepName: {
    fontWeight: 500,
    fontSize: 20,
    color: theme.palette.blue[500],
  },
  workflowButtonGif: {
    width: '100%',
  },
  uploadToLabelSvg: {
    marginTop: 2,
  },
  labelToTrainSvg: {
    marginTop: -70,
  },
  trainToPredictSvg: {
    marginTop: 2,
  },
  predictToUploadSvg: { position: 'absolute', top: -18, left: WORKFLOW_BUTTON_SIZE },
  predictToLabelSvg: { position: 'absolute', top: -12, left: WORKFLOW_BUTTON_SIZE * 2 + 100 },
  workflowTutorialBoxContainer: {
    borderRadius: 20,
    position: 'relative',
    paddingTop: theme.spacing(8),
  },
  workflowTutorialBoxStartButton: {
    borderRadius: 10,
    background: theme.palette.blue[500],
    width: 240,
    height: 64,
    color: theme.palette.common.white,
    fontSize: 18,
    '&:hover': {
      color: theme.palette.blue[500],
    },
  },
  workflowTutorialBoxCloseButton: {
    position: 'absolute',
    top: 18,
    right: 18,
  },
  marginLeft25: {
    marginLeft: theme.spacing(25),
  },
  pulsing: {
    borderRadius: '50%',
    boxShadow: `0 0 0 ${alpha(theme.palette.blue[600], 0.5)}`,
    animation: `$pulsing 1500ms ${theme.transitions.easing.easeOut} infinite`,
  },
  '@keyframes pulsing': {
    '0%': {
      boxShadow: `0 0 0 0 ${alpha(theme.palette.blue[600], 0.5)}`,
    },
    '70%': {
      boxShadow: `0 0 0 8px ${alpha(theme.palette.blue[600], 0.0)}`,
    },
    '100%': {
      boxShadow: `0 0 0 0 ${alpha(theme.palette.blue[600], 0.0)}`,
    },
  },
}));

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#4D5761',
    fontSize: 14,
    fontWeight: 400,
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.blue[500]}`,
    boxShadow: '0px 1px 2px rgba(48, 55, 79, 0.16), 0px 2px 4px rgba(48, 55, 79, 0.08)',
  },
  arrow: {
    color: theme.palette.common.white,
    '&:before': {
      border: `1px solid ${theme.palette.blue[500]}`,
    },
  },
}))(Tooltip);

const WorkflowButton: React.FC<{
  svgSrc: string;
  hoveredSvgSrc: string;
  gifSrc: string;
  hintText: string;
  stepName: string;
  className?: string;
  pulsing?: boolean;
  onHover?: () => void | Promise<void>;
}> = ({ svgSrc, hoveredSvgSrc, gifSrc, hintText, stepName, className, pulsing, onHover }) => {
  const styles = useStyles();

  return (
    <>
      <CustomTooltip
        title={
          <Box
            width={240}
            height={240}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            paddingY={2}
          >
            <img src={gifSrc} className={styles.workflowButtonGif} />
            <Box flex={1} />
            <Typography className={styles.workflowButtonHintText}>{hintText}</Typography>
          </Box>
        }
        placement="top"
        arrow
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={className}
          height={200}
          onMouseEnter={onHover}
        >
          <IconButton
            id="workflow-step-button"
            className={cx(styles.workflowButton, pulsing && styles.pulsing)}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={SVG_SIZE}
              height={SVG_SIZE}
            >
              <img src={svgSrc} className={'svg'} />
              <img src={hoveredSvgSrc} className={'svgHovered'} />
            </Box>
          </IconButton>

          <Typography className={styles.workflowButtonStepName}> {stepName} </Typography>
        </Box>
      </CustomTooltip>
    </>
  );
};

export const ShowWorkflowTutorialBox = new CacheStorage<boolean>({
  key: CacheStorageKey.ShowWorkflowTutorialBox,
  persist: true,
  defaultValue: true,
});

export const ShowWorkflowTutorialPulsingStep = new CacheStorage<boolean>({
  key: CacheStorageKey.ShowWorkflowTutorialPulsingStep,
  persist: true,
  defaultValue: true,
});

export interface WorkflowTutorialBoxProps {
  style?: React.CSSProperties;
  className?: string;
  enableClose?: boolean;
  showStartButton?: boolean;
  startButtonPlacement?: 'bottom' | 'right';
}

export const WorkflowTutorialBox: React.FC<WorkflowTutorialBoxProps> = ({
  style,
  className,
  enableClose,
  showStartButton = true,
  startButtonPlacement = 'bottom',
}) => {
  const styles = useStyles();
  const [pulsing, setPulsing] = useState(ShowWorkflowTutorialPulsingStep.get() ?? true);
  const [open, setOpen] = useState((enableClose && ShowWorkflowTutorialBox.get()) ?? true);
  const createProject = useCreateProjectMutation();
  const [isCreating, setIsCreating] = useState(false);

  const handleCreateProject = useCallback(async () => {
    setIsCreating(true);
    await createProject();
    setIsCreating(false);
  }, [createProject]);

  const onButtonHover = useCallback(() => {
    setPulsing(false);
    ShowWorkflowTutorialPulsingStep.set(false);
  }, []);

  const showPredictionButton = useIsFeatureEnabledAndMayHideForSnowflake().predictButton;

  if (!open) {
    return null;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={cx(styles.workflowTutorialBoxContainer, className)}
      style={style}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        marginBottom={showStartButton && startButtonPlacement === 'bottom' ? 15 : 0}
      >
        <WorkflowButton
          stepName={t('Upload')}
          svgSrc={UploadSvg}
          hoveredSvgSrc={UploadHoveredSvg}
          gifSrc={UploadGif}
          hintText={t('1. Upload images to your project')}
          pulsing={pulsing}
          onHover={onButtonHover}
        />
        <WorkflowButton
          stepName={t('Label')}
          svgSrc={LabelSvg}
          hoveredSvgSrc={LabelHoveredSvg}
          gifSrc={LabelGif}
          className={styles.marginLeft25}
          onHover={onButtonHover}
          hintText={t('2. Label your images')}
        />
        <WorkflowButton
          stepName={t('Train')}
          svgSrc={TrainSvg}
          hoveredSvgSrc={TrainHoveredSvg}
          gifSrc={TrainGif}
          className={styles.marginLeft25}
          onHover={onButtonHover}
          hintText={t('3. Train your model')}
        />
        {showPredictionButton && (
          <WorkflowButton
            stepName={t('Predict')}
            svgSrc={PredictSvg}
            hoveredSvgSrc={PredictHoveredSvg}
            gifSrc={PredictGif}
            hintText={t('4. Use model to predict')}
            className={styles.marginLeft25}
            onHover={onButtonHover}
          />
        )}

        {showStartButton && startButtonPlacement === 'right' && (
          <Button
            id="home-page-start"
            className={cx(styles.workflowTutorialBoxStartButton, styles.marginLeft25)}
            onClick={handleCreateProject}
            isPending={isCreating}
          >
            {t('Start First Project')}
          </Button>
        )}
      </Box>

      {showStartButton && startButtonPlacement === 'bottom' && (
        <Button
          id="home-page-start"
          className={styles.workflowTutorialBoxStartButton}
          onClick={handleCreateProject}
          isPending={isCreating}
        >
          {t('Start First Project')}
        </Button>
      )}

      {enableClose && (
        <IconButton
          size="small"
          onClick={() => {
            setOpen(false);
            ShowWorkflowTutorialBox.set(false);
          }}
          className={styles.workflowTutorialBoxCloseButton}
        >
          <Close />
        </IconButton>
      )}
    </Grid>
  );
};

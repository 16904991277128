import React, { useMemo, useState } from 'react';
import { Box, Checkbox, Dialog, OutlinedInput, Paper, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Button, IconButton, Typography } from '@clef/client-library';
import { UPLOAD_DIALOG_Z_INDEX } from '@/components/Dialogs/UploadFullscreen/constants';
import { isProjectOwner } from '@clef/shared/utils';
import { ProjectId } from '@clef/shared/types';
import {
  useAddActiveProjectMutation,
  useGetActiveProjectsQuery,
  useGetProjectsQuery,
} from '@/serverStore/projects';

import { useIsNonStripeUser } from '@/hooks/useSubscriptions';
import ProjectImageGrid from './ProjectImageGrid';

const DIALOG_WIDTH = 1200;

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: (UPLOAD_DIALOG_Z_INDEX + 1 + '!important') as unknown as number,
  },
  paper: {
    width: DIALOG_WIDTH,
  },
  greyModern800: {
    color: theme.palette.greyModern[800],
  },
  projectThumbnailsImageGrid: {
    minHeight: 400,
    maxHeight: 600,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  activateProjectButton: {
    width: 100,
  },
  topHeader: {
    backgroundColor: theme.palette.common.white,
    position: 'sticky',
    top: 0,
    zIndex: 100,
  },
  bottomTab: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.palette.common.white,
    height: 80,
    borderColor: theme.palette.greyModern[300],
    borderTopWidth: 1,
    borderWidth: 0,
    borderStyle: 'solid',
    boxShadow: `0 -5px 5px -5px ${theme.palette.greyModern[300]}`,
  },
  searchIcon: {
    color: theme.palette.grey[500],
  },
  projectGridSearchInput: {
    padding: '9px 0 9px 14px',
  },
  projectGridSearchContainer: {
    width: '100%',
    borderRadius: 6,
    marginRight: 20,
    height: 34,
    backgroundColor: theme.palette.common.white,
  },
  fixedSizeGrid: {
    scrollbarWidth: 'none',
  },
}));

export type AddActiveProjectDialogProps = {
  open: boolean;
  onClose: () => void;
};

type SearchBarProps = {
  searchText: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const SearchBar = (props: SearchBarProps) => {
  const styles = useStyles();
  const { searchText, onChange } = props;
  return (
    <OutlinedInput
      onClick={e => e.stopPropagation()}
      value={searchText}
      onChange={onChange}
      placeholder={t('Search for a project')}
      inputProps={{
        className: styles.projectGridSearchInput,
      }}
      className={styles.projectGridSearchContainer}
    />
  );
};

const AddActiveProjectDialog: React.FC<AddActiveProjectDialogProps> = props => {
  const { open, onClose } = props;
  const styles = useStyles();

  const addActiveProject = useAddActiveProjectMutation();
  const [isAddActiveProjectLoading, setIsAddActiveProjectLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [projectIdToActivate, setProjectIdToActivate] = useState<ProjectId | null>(null);
  const [nonCommercialUsePolicyAgreed, setNonCommercialUsePolicyAgreed] = useState(false);

  const isNonStripeUser = useIsNonStripeUser();

  const {
    data: projectListData,
    isLoading: projectListDataLoading,
    error: projectListDataError,
  } = useGetProjectsQuery();
  const { data: activeProjectData } = useGetActiveProjectsQuery();
  const activeProjectDataIds = activeProjectData?.map(activeProject => activeProject.id);
  const inactiveProjects = projectListData?.filter(project => {
    return !activeProjectDataIds?.includes(project.id);
  });
  const filteredProjectListData = useMemo(
    () =>
      searchText
        ? inactiveProjects?.filter(project => {
            const projectOwner = project.usersRoles?.find(user => isProjectOwner(user.role));
            return (
              project.name.toLowerCase().includes(searchText.toLowerCase()) ||
              projectOwner?.userName?.toLowerCase().includes(searchText.toLowerCase())
            );
          })
        : [...(inactiveProjects ?? [])],
    [inactiveProjects, searchText],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNonCommercialUsePolicyAgreed(event.target.checked);
  };

  const onActivateClicked = async () => {
    if (projectIdToActivate !== null) {
      setIsAddActiveProjectLoading(true);
      await addActiveProject.mutateAsync({ projectId: projectIdToActivate });
      setIsAddActiveProjectLoading(false);
      setProjectIdToActivate(null);
      setNonCommercialUsePolicyAgreed(false);
      onClose();
    }
  };

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      onClose={onClose}
      classes={{ root: styles.root, paper: styles.paper }}
    >
      <Paper>
        <Box className={styles.topHeader}>
          <Box display="flex" flexDirection={'column'}>
            <Box padding={5} display="flex" flexDirection={'row'} justifyContent={'space-between'}>
              <Box>
                <Typography variant="h2_semibold">{t('Choose a project to activate')}</Typography>
              </Box>
              <IconButton
                id="close-browser-detection-snack-bar"
                size="small"
                aria-label="close"
                color="inherit"
                onClick={onClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Box paddingLeft={5} paddingRight={5} marginBottom={3}>
            <SearchBar
              searchText={searchText}
              onChange={({ target: { value } }) => {
                setSearchText(value);
              }}
            />
          </Box>
        </Box>
        <Box className={styles.projectThumbnailsImageGrid}>
          <ProjectImageGrid
            selectedProjectId={projectIdToActivate}
            onSelectedProjectIdChange={value => {
              setProjectIdToActivate(value);
            }}
            projectListData={filteredProjectListData}
            projectListDataLoading={projectListDataLoading}
            projectListDataError={projectListDataError}
          />
        </Box>
        <Box
          display="flex"
          padding={5}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          className={styles.bottomTab}
        >
          <Typography variant="body_regular" className={styles.greyModern800}>
            {t('You can download models as many times as needed from activated projects.')}
          </Typography>
          <Box
            display="flex"
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            {!isNonStripeUser && (
              <Box
                display="flex"
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'flex-end'}
                paddingRight={2}
              >
                <Checkbox
                  color="primary"
                  checked={nonCommercialUsePolicyAgreed}
                  onChange={handleChange}
                />
                <Typography variant="body_regular" className={styles.greyModern800}>
                  {t('I confirm the models will not be used for commercial use.')}
                </Typography>
              </Box>
            )}
            <Button
              id="add-activate-project-button"
              color="primary"
              variant="contained"
              disabled={
                projectIdToActivate === null ||
                isAddActiveProjectLoading ||
                (!isNonStripeUser && !nonCommercialUsePolicyAgreed)
              }
              onClick={onActivateClicked}
              className={styles.activateProjectButton}
            >
              {isAddActiveProjectLoading ? t('Adding...') : t('Activate')}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default AddActiveProjectDialog;

import { makeStyles } from '@material-ui/core';

export const useDeviceMonitorStyles = makeStyles(theme => ({
  // top panel
  topPanelContainer: {
    padding: theme.spacing(0, 6),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(7),
  },
  topSection: {
    padding: theme.spacing(5, 0),
  },
  topSectionFilterSection: {
    borderTop: `1px solid ${theme.palette.action.disabled}`,
  },
  sectionComponent: {
    margin: theme.spacing(0, 2),
  },
  // filters
  optionItem: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    paddingRight: theme.spacing(5),
    '&:hover': {
      backgroundColor: 'rgba(9, 30, 66, 0.08)',
    },
  },
  recipientsDialogContextItem: {
    marginBottom: 0,
    marginTop: 20,
  },
  textButton: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  dropdownPanelList: {
    minWidth: 150,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dropdownPanel: {
    minWidth: 150,
    padding: theme.spacing(5),
  },
  confidenceSlide: {
    marginTop: theme.spacing(5),
    width: 300,
  },
  sliderValueLabel: {
    top: -22,
    '& *': {
      fontSize: 12,
      fontFamily: 'Commissioner, sans-serif',
      fontWeight: 500,
      background: 'transparent',
      color: theme.palette.grey[800],
    },
  },
  // edge media select action
  scrollStickyContainer: {
    height: 64,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  actionButtons: {
    marginLeft: theme.spacing(5),

    fontWeight: 500,
  },
  // edge media grid
  mediaPaginationGrid: {
    margin: theme.spacing(4, 0),
  },
  // edge media container
  hoverClickContainer: {
    opacity: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    zIndex: 105,
    '&:hover': {
      opacity: 1,
      background: 'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 60px)',
    },
    borderColor: theme.palette.grey[400],
    transition: 'all 0.135s cubic-bezier(0.0,0.0,0.2,1)',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 8,
    overflow: 'hidden',
  },
  hoverIconContainerSelected: {
    opacity: 1,
    borderColor: theme.palette.primary.main,
    '& $checkIcon': {
      color: theme.palette.primary.main,
    },
  },
  checkIcon: {
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
    color: 'rgba(255,255,255, 0.7)',
    '&:hover': {
      cursor: 'pointer',
      color: 'rgba(255,255,255, 1)',
    },
  },
  displayOptionsContainer: {
    gap: theme.spacing(1),
    justifyContent: 'space-between',
  },
  displayOptionsItem: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  // edge media display options
  controlLabel: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(8),
    '&:first-of-type': {
      marginLeft: theme.spacing(0),
    },
  },
  checkboxCaption: {
    fontStyle: 'italic',
    cursor: 'pointer',
  },
  slide: { width: 120 },
  // pagination options
  textButtonRaw: {
    cursor: 'pointer',
    margin: theme.spacing(0, 2),
  },
  selectorNoBorder: {
    border: 'none',
  },
  paginationText: {
    margin: theme.spacing(0, 2),
  },
  groupButton: {
    borderRadius: 16,
  },
  chipStyle: {
    color: '#fff',
  },
  tagRightCorner: {
    position: 'absolute',
    zIndex: 104,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  tagLeftCorner: {
    position: 'absolute',
    zIndex: 104,
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
  moreFilterText: {
    marginRight: theme.spacing(2),
    fontSize: 14,
  },
  newTopDrawer: {
    left: '80px',
    width: 'calc(100vw - 124px)',
  },
}));

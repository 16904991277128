import React, { useState, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import CompareArrows from '@material-ui/icons/CompareArrows';
import { Button, Typography } from '@clef/client-library';
import { ModelComparisonSelectionInfo } from '../utils';

const useStyles = makeStyles(theme => ({
  modelComparisonSelection: {
    position: 'fixed',
    maxWidth: '340px',
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.greyModern[300]}`,
    boxShadow: `0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.12)`,
    borderRadius: 10,
    padding: theme.spacing(4, 5),
    zIndex: theme.zIndex.modal - 1,
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(5),
    color: theme.palette.greyModern[900],
    '& p': {
      color: theme.palette.greyModern[900],
    },
  },
  comparisonDesc: {
    marginBottom: theme.spacing(5),
    color: theme.palette.greyModern[600],
  },
  compareModelSwitch: {
    padding: theme.spacing(2.5),
    minWidth: 'auto!important',
    width: 34,
    '& svg': {
      transform: 'rotate(90deg)',
    },
  },
  title: {
    color: theme.palette.greyModern[400],
    fontWeight: 500,
    marginBottom: theme.spacing(1.5),
  },
  selectSection: {
    color: theme.palette.greyModern[700],
    whiteSpace: 'nowrap',
  },
  opsButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(3),
    marginTop: theme.spacing(5),
  },
}));

export type ModelComparisonSelectionProps = {
  comparisonSelectionInfo: ModelComparisonSelectionInfo;
  onCancel?: () => void;
  onModelsSwitch?: () => void;
  onCreateReport?: () => void;
};

const ModelComparisonSelection: React.FC<ModelComparisonSelectionProps> = props => {
  const { comparisonSelectionInfo, onCancel, onModelsSwitch, onCreateReport } = props;
  const { baseline, candidate, evaluationSet } = comparisonSelectionInfo;
  const styles = useStyles();

  const [evaluationSetPosition, setEvaluationSetPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  // get the evaluation set position to render selection dialog
  useEffect(() => {
    if (comparisonSelectionInfo.evaluationSet) {
      const div = document?.getElementById(
        `eval-${comparisonSelectionInfo.evaluationSet.id}-header`,
      );
      if (div) {
        const rect = div.getBoundingClientRect();
        setEvaluationSetPosition({ top: rect.top + rect.height, left: rect.left + rect.width });
      }
    }
  }, [comparisonSelectionInfo.evaluationSet]);

  const placeHolderComponent = (
    <Typography variant="body_regular">{t('Select in the table')}</Typography>
  );
  return (
    <>
      {evaluationSetPosition ? (
        <Box
          className={styles.modelComparisonSelection}
          style={{ top: evaluationSetPosition.top, left: evaluationSetPosition.left }}
          id="model-comparison-selection"
        >
          <Box className={styles.dialogTitle}>
            <Typography variant="h4_semibold">{t('Compare two models')}</Typography>
            <CloseOutlined fontSize="small" onClick={onCancel} />
          </Box>

          <Typography variant="body_regular" className={styles.comparisonDesc}>
            {t(
              'Evaluate and compare the model performance of two models using the same evaluation set. Start by selecting a Baseline model, then a Candidate.',
            )}
          </Typography>

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" flexDirection="column">
              <Box id="model-comparison-baseline" mb={4}>
                <Typography variant="body_small" className={styles.title}>
                  {t('Baseline model')}
                </Typography>
                <Box display="flex" alignItems="center" className={styles.selectSection}>
                  {baseline ? (
                    <>
                      <Typography maxWidth={230}>{baseline.modelName}</Typography>
                      <Typography>{`(${baseline.threshold})`}</Typography>
                    </>
                  ) : (
                    placeHolderComponent
                  )}
                </Box>
              </Box>

              <Box id="model-comparison-candidate">
                <Typography variant="body_small" className={styles.title}>
                  {t('Candidate model')}
                </Typography>
                <Box display="flex" alignItems="center" className={styles.selectSection}>
                  {candidate ? (
                    <>
                      <Typography maxWidth={230}>{candidate.modelName}</Typography>
                      <Typography>{`(${candidate.threshold})`}</Typography>
                    </>
                  ) : (
                    placeHolderComponent
                  )}
                </Box>
              </Box>
            </Box>
            <Button
              id="model-comparison-switch"
              variant="outlined"
              className={styles.compareModelSwitch}
              onClick={onModelsSwitch}
            >
              <CompareArrows fontSize="small" />
            </Button>
          </Box>

          <Box className={styles.opsButtons}>
            <Button id="model-comparison-cancel" variant="text" onClick={onCancel}>
              {t('Cancel')}
            </Button>
            <Button
              id="model-comparison-create-report"
              variant="contained"
              color="primary"
              disabled={!baseline || !candidate || !evaluationSet}
              onClick={onCreateReport}
            >
              {t('Compare')}
            </Button>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default ModelComparisonSelection;

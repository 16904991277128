import React from 'react';

export type TasksIconProps = {};

const TasksIcon: React.FC<TasksIconProps> = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 96.000000 96.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path d="M180 800 l0 -40 320 0 320 0 0 40 0 40 -320 0 -320 0 0 -40z" />
        <path d="M180 640 l0 -40 320 0 320 0 0 40 0 40 -320 0 -320 0 0 -40z" />
        <path d="M180 480 l0 -40 320 0 320 0 0 40 0 40 -320 0 -320 0 0 -40z" />
        <path d="M180 320 l0 -40 140 0 140 0 0 40 0 40 -140 0 -140 0 0 -40z" />
        <path
          d="M700 295 l-64 -65 -28 27 -28 27 -28 -27 -27 -27 55 -55 55 -55 93
 93 92 93 -28 27 -28 27 -64 -65z"
        />
        <path d="M180 160 l0 -40 140 0 140 0 0 40 0 40 -140 0 -140 0 0 -40z" />
      </g>
    </svg>
  );
};

export default TasksIcon;

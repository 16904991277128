import React from 'react';

export type TopLeftProps = {};

const TopLeft: React.FC<TopLeftProps> = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 7.5V12.5H5V7.5C5 6.79167 5.24 6.1975 5.72 5.7175C6.19833 5.23917 6.79167 5 7.5 5H12.5V0H7.5C5.41667 0 3.64583 0.729167 2.1875 2.1875C0.729167 3.64583 0 5.41667 0 7.5Z"
        fill="white"
      />
    </svg>
  );
};

export default TopLeft;

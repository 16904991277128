/**
 * To see the color schema, see https://www.figma.com/file/zphcfSd1iIreTpQOTn5Wgu/Design-System
 */

import { ColorPartial, SimplePaletteColorOptions } from '@material-ui/core/styles/createPalette';

/**
 * Primary colors
 */
export const landingBlue: SimplePaletteColorOptions = {
  main: '#027EEA',
  light: '#6B8ED8',
  dark: '#004CAE',
};
export const primaryYellow: SimplePaletteColorOptions = {
  main: '#FFDD79',
  light: '#FFEEA7',
  dark: '#F6D05F',
};
/**
 * Secondary colors
 */
export const attentionRed: SimplePaletteColorOptions = {
  main: '#FF7B7B',
  light: '#FFADAA',
  dark: '#C84A4F',
};
export const failureRed: SimplePaletteColorOptions = {
  main: '#FF6A60',
  light: '#FF9C8E',
  dark: '#C63736',
};
export const warningYellow: SimplePaletteColorOptions = {
  main: '#FF9800',
  light: '#FFB74D',
  dark: '#F57C00',
};
export const infoBlue: SimplePaletteColorOptions = {
  main: '#2196F3',
  light: '#64B5F6',
  dark: '#1976D2',
};
export const successGreen: SimplePaletteColorOptions = {
  main: '#33ACB3',
  light: '#6EDEE5',
  dark: '#007C83',
};

export const pageBackground = '#F4F8FE';

export const rawGrey = '#CED1D5';
export const pendingLabelingGrey = '#F3F2EF';
export const pendingReviewYellow = primaryYellow.main;
export const approveGreen = successGreen.main;
export const rejectRed = failureRed.main;

export const greyScale: ColorPartial = {
  25: '#FCFCFD',
  100: '#F3F3F4',
  200: '#E6E7E9',
  300: '#CDD0D3',
  400: '#9BA0A6',
  500: '#69717A',
  600: '#37414D',
  700: '#1E2A37',
  800: '#051221',
};

export const splitColors = [
  '#BBE5B5',
  '#99D98C',
  '#76C893',
  '#34A0A4',
  '#168AAD',
  '#1E6091',
  '#184E77',
];

export const defectColors = [
  '#811776',
  '#FFFF00',
  '#00FFFF',
  '#FF00FF',
  '#0000FF',
  '#FF8F20',
  '#AD2E24',
  '#470400',
  '#CFF000',
  '#A7D141',
  '#41D1B3',
  '#70E9FF',
  '#00ACED',
  '#BFD8BD',
  '#FF70E9',
  '#FF5C9A',
  '#BE408F',
  '#8670FF',
  '#333275',
];

export const tagColors = [
  '#811776',
  '#FFFF00',
  '#00FFFF',
  '#FF00FF',
  '#0000FF',
  '#FF8F20',
  '#AD2E24',
  '#470400',
  '#CFF000',
  '#A7D141',
  '#41D1B3',
  '#70E9FF',
  '#00ACED',
  '#BFD8BD',
  '#FF70E9',
  '#FF5C9A',
  '#BE408F',
  '#8670FF',
  '#333275',
];

export const blueScale: ColorPartial = {
  25: '#F5FAFF',
  50: '#EBF7FF',
  100: '#DEF3FF',
  200: '#BEE4FF',
  300: '#81CAFF',
  400: '#5AB8FF',
  500: '#2193FD',
  600: '#027EEA',
  700: '#0167DC',
  800: '#004CAE',
  900: '#003592',
};

export const indigoScale: ColorPartial = {
  25: '#F5F8FF',
  50: '#EEF4FF',
  100: '#E0EAFF',
  200: '#C6D7FE',
  300: '#A4BCFD',
  400: '#8098F9',
  500: '#6172F3',
  600: '#444CE7',
  700: '#3538CD',
  800: '#2D31A6',
  900: '#2D3282',
};

export const indigoBlueScale: ColorPartial = {
  25: '#F0F6FF',
  50: '#E9F1FF',
  100: '#D5E4FC',
  200: '#B5CFF8',
  300: '#8CAEEB',
  400: '#6B8ED8',
  500: '#3F64BF',
  600: '#2E4CA4',
  700: '#1F3889',
  800: '#14266E',
  900: '#0C195B',
};

export const blueLightScale: ColorPartial = {
  25: '#F5FBFF',
  50: '#F0F9FF',
  100: '#E0F2FE',
  200: '#B9E6FE',
  300: '#7CD4FD',
  400: '#36BFFA',
  500: '#0BA5EC',
  600: '#0086C9',
  700: '#026AA2',
  800: '#065986',
  900: '#0B4A6F',
};

export const newGreyScale: ColorPartial = {
  25: '#FFFCF5',
  50: '#FFFAEB',
  100: '#FEEFC6',
  200: '#FEDF89',
  300: '#FEC84B',
  400: '#FDB022',
  500: '#F79009',
  600: '#DC6803',
  700: '#B54708',
  800: '#93370D',
  900: '#792E0D',
};

export const greyModernScale: ColorPartial = {
  25: '#FCFCFD',
  50: '#F8FAFC',
  100: '#F3F6F9',
  200: '#E3E8EF',
  300: '#CDD5DF',
  400: '#9AA4B2',
  500: '#697586',
  600: '#4B5565',
  700: '#364152',
  800: '#202939',
  900: '#121926',
};

export const greyBlueScale: ColorPartial = {
  25: '#FCFCFD',
  50: '#F9F9FB',
  100: '#EFF1F5',
  200: '#DCDFEA',
  300: '#B9C0D4',
  400: '#7D89AF',
  500: '#5D6B98',
  600: '#4A5578',
  700: '#404968',
  800: '#30374F',
  900: '#111322',
};

export const greenScale: ColorPartial = {
  25: '#F6FEF9',
  50: '#ECFDF3',
  100: '#D1FADF',
  200: '#A6F4C5',
  300: '#6CE9A6',
  400: '#32D583',
  500: '#12B76A',
  600: '#039855',
  700: '#027948',
  800: '#05603A',
  900: '#054F31',
};

export const tealScale: ColorPartial = {
  25: '#F6FEFC',
  50: '#F0FDF9',
  100: '#CCFBEF',
  200: '#99F6E0',
  300: '#5FE9D0',
  400: '#2ED3B7',
  500: '#15B79E',
  600: '#0E9384',
  700: '#107569',
  800: '#125D56',
  900: '#134E48',
};

export const orangeScale: ColorPartial = {
  25: '#FFFCF5',
  50: '#FFFAEB',
  100: '#FEEFC6',
  200: '#FEDF89',
  300: '#FEC84B',
  400: '#FDB022',
  500: '#F79009',
  600: '#DC6803',
  700: '#B54708',
  800: '#93370D',
  900: '#792E0D',
};

export const yellowScale: ColorPartial = {
  25: '#FEFDF0',
  50: '#FEFBE8',
  100: '#FEF7C3',
  200: '#FEEE95',
  300: '#FDE172',
  400: '#FAC415',
  500: '#EAAA08',
  600: '#CA8504',
  700: '#A15C07',
  800: '#85490E',
  900: '#713B12',
};

export const redScale: ColorPartial = {
  25: '#FFFAFA',
  50: '#FEF3F2',
  100: '#FEE4E2',
  200: '#FECDC9',
  300: '#FDA19B',
  400: '#F97066',
  500: '#F04438',
  600: '#D92D20',
  700: '#B32318',
  800: '#912018',
  900: '#7A271A',
};

export const pinkScale: ColorPartial = {
  25: '#FFF5F6',
  50: '#FFF1F3',
  100: '#FFE4E8',
  200: '#FECCD6',
  300: '#FEA3B4',
  400: '#FD6F8E',
  500: '#F63D68',
  600: '#E31B53',
  700: '#C01048',
  800: '#A11043',
  900: '#89123E',
};

export const purpleScale: ColorPartial = {
  25: '#FAFAFF',
  50: '#F4F3FF',
  100: '#EBE9FE',
  200: '#D9D6FE',
  300: '#BDB4FE',
  400: '#9B8AFB',
  500: '#7A5AF8',
  600: '#6938EF',
  700: '#5925DC',
  800: '#4A1FB8',
  900: '#3E1C96',
};

// Reference: https://github.com/iamhosseindhv/notistack/issues/30#issuecomment-542863653
import React from 'react';
import { useSnackbar, VariantType, WithSnackbarProps, SnackbarMessage } from 'notistack';

let useSnackbarRef: WithSnackbarProps;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export default {
  success(msg: SnackbarMessage) {
    this.toast(msg, 'success');
  },
  warning(msg: SnackbarMessage) {
    this.toast(msg, 'warning');
  },
  info(msg: SnackbarMessage) {
    this.toast(msg, 'info');
  },
  error(msg: SnackbarMessage) {
    this.toast(msg, 'error');
  },
  toast(msg: SnackbarMessage, variant: VariantType = 'default') {
    useSnackbarRef.enqueueSnackbar(msg, { variant });
  },
};

import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { Typography, Box } from '@material-ui/core';
import cx from 'classnames';
import { useSnackbar } from 'notistack';

import { useStyles } from './newStyles';
import AuthAPI from '../../api/auth_api';
import { LandingLensLogo } from '../../components/Auth/Common';
import { PrimaryButton } from '../../components/Auth/Button';
import { PasswordTextField } from '../../components/Auth/TextField';
import { CLEF_PATH } from '../../constants/path';
import AuthPageLayout from '../../components/Layout/AuthPageLayout';

interface FormValues {
  password: string;
  verificationCode: string;
}

export const ConfirmResetPassword: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [isConfirming, setIsConfirming] = useState(false);
  const { control, errors, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      password: '',
    },
  });

  const onSubmit = useCallback(
    async ({ password }: FormValues) => {
      setIsConfirming(true);
      const searchParams = new URLSearchParams(history?.location?.search);
      const email = searchParams.get('email') ?? '';
      const verificationCode = searchParams.get('token') ?? '';

      try {
        await AuthAPI.newConfirmPassword({
          username: email,
          newPassword: password,
          verificationCode,
        });

        enqueueSnackbar('Password has been reset!', { variant: 'success' });
        history.push(CLEF_PATH.login.main);
      } catch (e) {
        enqueueSnackbar(e?.message, { variant: 'error', autoHideDuration: 12000 });
        setIsConfirming(false);
      }
    },
    [enqueueSnackbar, history],
  );

  return (
    <AuthPageLayout metaTitle="Reset Password">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minWidth="100vw"
        minHeight="100vh"
        position="relative"
      >
        <LandingLensLogo />

        <Typography variant="h1" className={styles.helperText}>
          {t('Enter Your New Password')}
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <PasswordTextField
            error={errors?.password}
            control={control}
            watch={watch}
            className={styles.marginBottom12}
            showHint
            enablePatternCheck
          />

          <PrimaryButton
            id="confirm-reset-password"
            text={t('Confirm Reset Password')}
            className={cx(styles.submitButton, styles.marginBottom6)}
            disabled={!!errors.verificationCode || !!errors.password}
            isPending={isConfirming}
            useNewUi
          />
        </Box>
      </Box>
    </AuthPageLayout>
  );
};

/* eslint-disable no-redeclare */
import { isJestEnv } from '../utils/env';
import { isEqual } from 'lodash';
import { useState, useEffect, useRef, ImgHTMLAttributes, useMemo } from 'react';
import { usePrevious } from './usePrevious';
import path from 'path';

/**
 * return an Image instance after it was loaded.
 */
export default function useLoadImage(
  imgSrc: string | undefined,
  crossOrigin?: ImgHTMLAttributes<HTMLImageElement>['crossOrigin'],
): HTMLImageElement | null;

/**
 * return list of objects that has the same length as input list.
 * the coresponding object will be either null if not loaded yet or an Image instance if loaded.
 *
 * Note: currently, any change in the list will generate new images for all urls in the list.
 * TODO: optimize this hook for below cases:
 * 1. part of the list changes (added / updated / removed), those unchanged urls should return previous image objects.
 * 2. urls re-ordered, should return re-ordered existing images instead of generating new ones.
 * Now it is fine because we don't have such cases yet, and the images have http caches.
 */
export default function useLoadImage(
  imgSrcList: string[],
  crossOrigin?: ImgHTMLAttributes<HTMLImageElement>['crossOrigin'],
): (HTMLImageElement | null)[];

/**
 * return null(s) before loaded or Image instance(s) after loaded
 */
export default function useLoadImage(
  imgSrc: string | undefined | string[],
  crossOrigin?: ImgHTMLAttributes<HTMLImageElement>['crossOrigin'],
) {
  const imgSrcList = useMemo(() => (Array.isArray(imgSrc) ? imgSrc : [imgSrc]), [imgSrc]);
  const prevImgSrcList = usePrevious(imgSrcList);

  const [imageList, setImageList] = useState<(HTMLImageElement | null)[]>(
    imgSrcList.map(() => null),
  );
  const isMount = useRef(true);

  const imgSrcListRef = useRef<(string | undefined)[]>(imgSrcList);
  imgSrcListRef.current = imgSrcList;

  useEffect(() => {
    // only proceed when the list content changes
    if (isEqual(imgSrcList, prevImgSrcList)) {
      return;
    }

    imgSrcList.forEach((imgSrc, index) => {
      if (!imgSrc) {
        setImageList(prev =>
          prev.map((prevImage, prevIndex) => (prevIndex === index ? null : prevImage)),
        );
        return;
      }

      const loadImage = new Image();

      if (crossOrigin) {
        loadImage.crossOrigin = crossOrigin;
      }

      loadImage.onload = () => {
        // to resolve race conditions, only set image when the loaded image src is the same as the latest imgSrc
        if (
          isMount.current &&
          // imgSrcList might to relative path, but loadImage.src will be full path
          (isJestEnv ||
            path.normalize(loadImage.src).includes(path.normalize(imgSrcListRef.current[index]!)))
        ) {
          setImageList(prev =>
            prev.map((prevImage, prevIndex) => (prevIndex === index ? loadImage : prevImage)),
          );
        }
      };

      // on error setImage to null to differentiate from undefined
      loadImage.onerror = () => {
        // to resolve race conditions, only set to null when the error image src is the same as the latest imgSrc
        if (
          isMount.current &&
          // imgSrcList might to relative path, but loadImage.src will be full path
          (isJestEnv ||
            path.normalize(loadImage.src).includes(path.normalize(imgSrcListRef.current[index]!)))
        ) {
          setImageList(prev =>
            prev.map((prevImage, prevIndex) => (prevIndex === index ? null : prevImage)),
          );
        }
      };

      loadImage.src = isJestEnv ? 'LOAD_SUCCESS_SRC' : imgSrc;
    });
  }, [crossOrigin, imgSrcList, prevImgSrcList]);

  useEffect(
    () => () => {
      isMount.current = false;
    },
    [],
  );

  return Array.isArray(imgSrc) ? imageList : imageList[0];
}

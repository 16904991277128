import React, { useState, useCallback } from 'react';
import { Typography, Grid, makeStyles, TextField, LinearProgress } from '@material-ui/core';
import { Button, IconButton } from '@clef/client-library';
import { Dropdown, DropdownProps, useMount } from '@clef/client-library';
import { MetadataObjectValue } from '@clef/shared/types';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  operationValueContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  textButton: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  dropdownPanel: {
    width: 150,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  dropdownContainer: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 8,
  },
  postRequestMask: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
    background: 'rgba(255,255,255,0.7)',
  },
  noneOption: {
    fontStyle: 'italic',
  },
  inputRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
    },
    marginBottom: theme.spacing(2),
  },
  input: {
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: '0.75rem',
  },
}));

export interface SetMetadataRawTextProps {
  children: (dropdownOpen: boolean) => React.ReactNode;
  applyMetadata: (value: string[] | number[]) => Promise<void>;
  isNumber?: boolean;
  allowMultiple?: boolean;
  defaultValue?: MetadataObjectValue;
  // To be passed to Dropdown
  placement?: DropdownProps['placement'];
  extraGutter?: DropdownProps['extraGutter'];
}

const SetMetadataRawText: React.FC<SetMetadataRawTextProps> = ({
  children,
  placement,
  extraGutter,
  applyMetadata,
  allowMultiple,
  isNumber,
  defaultValue,
}) => {
  const styles = useStyles();
  const isMount = useMount();
  const initialValue = isNumber ? [0] : [''];
  const defaultValueFormatted = (Array.isArray(defaultValue) ? defaultValue : [defaultValue]) as (
    | string
    | number
  )[];
  const [valueList, setValueList] = useState<(string | number)[]>(
    defaultValue ? defaultValueFormatted : initialValue,
  );
  const setValueAtIndex = useCallback((index: number, text: string) => {
    setValueList(prev => {
      const a = [...prev.slice(0, index), text, ...prev.slice(index + 1)];
      return a;
    });
  }, []);
  const [applyingMetadata, setApplyingMetadata] = useState(false);
  const invalidValueList = !!valueList.filter(value => value === '').length;

  return (
    <Dropdown
      placement={placement}
      extraGutter={extraGutter}
      dropdown={(toggleDropdown: (open: boolean) => void) => (
        <div className={styles.dropdownContainer}>
          {applyingMetadata && (
            <div className={styles.postRequestMask}>
              <LinearProgress />
            </div>
          )}
          <div className={styles.dropdownPanel}>
            <div className={styles.operationValueContainer}>
              <Typography variant="body2" gutterBottom component="div">
                {t('set metadata value')}
              </Typography>
              {valueList.map((textValue, index) => (
                <TextField
                  variant="outlined"
                  key={index}
                  onKeyPress={ev => {
                    if (ev.key === 'Enter') {
                      ev.preventDefault();
                      setValueList(prev => [...prev, ...initialValue]);
                    }
                  }}
                  fullWidth
                  autoFocus
                  type={isNumber ? 'number' : 'text'}
                  onChange={e => setValueAtIndex(index, e?.target?.value ?? '')}
                  value={textValue}
                  inputProps={{
                    className: styles.input,
                    'data-testid': `set-matadata-raw-text-input-${index}`,
                  }}
                  classes={{
                    root: styles.inputRoot,
                  }}
                  size="small"
                />
              ))}
            </div>
            {/* Apply / Clear button */}
            <Grid container justifyContent="flex-end">
              {allowMultiple && (
                <IconButton
                  color="primary"
                  size="small"
                  className={styles.textButton}
                  id="set-matadata-raw-text-add-btn"
                  onClick={() => setValueList(prev => [...prev, ...initialValue])}
                >
                  <AddIcon />
                </IconButton>
              )}
              <Button
                id="apply-metadata-btn"
                color="primary"
                size="small"
                className={styles.textButton}
                disabled={invalidValueList}
                onClick={async () => {
                  setApplyingMetadata(true);
                  try {
                    await applyMetadata(valueList as string[] | number[]);
                    toggleDropdown(false);
                  } finally {
                    isMount() && setApplyingMetadata(false);
                  }
                }}
              >
                {t('Apply')}
              </Button>
              <Button
                id="clear-metadata-btn"
                color="secondary"
                size="small"
                className={styles.textButton}
                onClick={() => setValueList(initialValue)}
              >
                {t('Clear')}
              </Button>
            </Grid>
          </div>
        </div>
      )}
    >
      {open => children(open)}
    </Dropdown>
  );
};

export default SetMetadataRawText;

import { Button, calcItemPerRow, IconButton } from '@clef/client-library';
import { Box, Popover, Tooltip } from '@material-ui/core';
import { uniq } from 'lodash';
import React, { useRef, useState } from 'react';
import { getSelectedMediaCount, useDataBrowserState } from '../dataBrowserState';
import { useDataBrowserStyles } from '../styles';
import { useDatasetMedias, useTotalMediaOrInstanceCount } from '../utils';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { usePopupState, bindPopover, bindTrigger } from 'material-ui-popup-state/hooks';
import classNames from 'classnames';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { LabelType } from '@clef/shared/types';
import ZoomInIcon from '@/images/data-browser/dataset/ZoomInIcon';
import ZoomOutIcon from '@/images/data-browser/dataset/ZoomOutIcon';

export type MediaSelectionProps = {};

const MediaSelection: React.FC<MediaSelectionProps> = () => {
  const styles = useDataBrowserStyles();
  const { state, dispatch } = useDataBrowserState();
  const { viewMode, itemPerRowOffset } = state;
  const totalMediaOrInstanceCount = useTotalMediaOrInstanceCount();
  const selectedMediaCount = totalMediaOrInstanceCount ? getSelectedMediaCount(state) : 0;
  const { data: datasetMedias } = useDatasetMedias();
  const [currentSelectType, setCurrentSelectType] = useState<'all' | 'page' | 'select'>('select');
  const selectTypePopupState = usePopupState({ variant: 'popover', popupId: 'select-type-popup' });
  const selectBtn = useRef<HTMLDivElement>(null);
  const { labelType } = useGetSelectedProjectQuery().data ?? {};

  if (totalMediaOrInstanceCount === undefined) {
    return null;
  }

  const selectAllBtn = (
    <Button
      id="select-all-open-dropdown-button"
      color="primary"
      variant="text"
      className={styles.textButton}
      data-testid={'databrowser-select-all-btn'}
    >
      {t('Select all')}
    </Button>
  );

  const selectPageBtn = (
    <Button
      id="select-page-open-dropdown-button"
      color="primary"
      variant="text"
      className={styles.textButton}
    >
      {t('Select page')}
    </Button>
  );

  return (
    <Box display="flex" alignItems="center">
      {viewMode === 'image' &&
        !!datasetMedias &&
        labelType !== LabelType.SegmentationInstantLearning && (
          <>
            <div ref={selectBtn} style={{ position: 'relative' }}>
              <Box
                className={styles.selectType}
                alignItems="center"
                display="flex"
                {...bindTrigger(selectTypePopupState)}
              >
                {currentSelectType === 'page' ? (
                  selectPageBtn
                ) : currentSelectType === 'all' ? (
                  selectAllBtn
                ) : (
                  <Button id="select-option-dropdown-button" color="primary" variant="text">
                    {t('Select')}
                    <KeyboardArrowDownIcon />
                  </Button>
                )}
              </Box>
              <Popover
                {...bindPopover(selectTypePopupState)}
                id="select-type-popup"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                disableScrollLock
                disableEnforceFocus
                disablePortal
                className={styles.mediaSelectionPopover}
                anchorEl={() => selectBtn.current!}
                container={() => selectBtn.current!}
              >
                <Box marginTop={1}>
                  <Button
                    id="select-all-media-button"
                    color="primary"
                    variant="text"
                    className={classNames(styles.textButton, styles.selectMedia)}
                    disabled={selectedMediaCount === totalMediaOrInstanceCount}
                    onClick={() => {
                      setCurrentSelectType('all');
                      dispatch(draft => {
                        draft.selectingMediaMode = 'unselect';
                        draft.selectedOrUnselectedMedia = [];
                        draft.totalMediaCount = totalMediaOrInstanceCount;
                      });
                      selectTypePopupState.close();
                    }}
                  >
                    {t('Select all')}
                  </Button>
                </Box>
                <Box marginBottom={1}>
                  <Button
                    id="select-page-media-button"
                    color="primary"
                    disabled={selectedMediaCount === totalMediaOrInstanceCount}
                    variant="text"
                    className={classNames(styles.textButton, styles.selectMedia)}
                    onClick={() => {
                      setCurrentSelectType('page');
                      dispatch(draft => {
                        if (state.selectingMediaMode === 'unselect') {
                          draft.selectedOrUnselectedMedia = state.selectedOrUnselectedMedia.filter(
                            mediaId => !datasetMedias.find(media => media.id === mediaId),
                          );
                        } else {
                          draft.selectedOrUnselectedMedia = uniq([
                            ...draft.selectedOrUnselectedMedia,
                            ...datasetMedias.map(media => media.id),
                          ]);
                        }
                      });
                      selectTypePopupState.close();
                    }}
                  >
                    {t('Select page')}
                  </Button>
                </Box>
              </Popover>
            </div>

            {!!selectedMediaCount && (
              <Button
                id="unselect-all-media-button"
                color="secondary"
                onClick={() => {
                  // switch excluding to selecting, reset exclude list
                  setCurrentSelectType('select');
                  dispatch(draft => {
                    draft.selectingMediaMode = 'select';
                    draft.selectedOrUnselectedMedia = [];
                  });
                }}
                data-testid={'databrowser-unselect-all-btn'}
              >
                {t('Deselect all')}
              </Button>
            )}
          </>
        )}
      <Box display="flex" sx={{ marginX: 2 }}>
        <Tooltip title={t('Enlarge image size')} arrow placement="top">
          <Box
            className={styles.zoomIconBox}
            display="flex"
            alignItems="center"
            justifyContent={'center'}
          >
            <IconButton
              id="data-browser-increase-media-size"
              size="small"
              disabled={calcItemPerRow(undefined, itemPerRowOffset) < 2}
              onClick={() => {
                dispatch(draft => {
                  draft.itemPerRowOffset--;
                });
              }}
            >
              <ZoomInIcon />
            </IconButton>
          </Box>
        </Tooltip>
        <Box marginRight={2}></Box>
        <Tooltip title={t('Reduce image size')} arrow placement="top">
          <Box
            className={styles.zoomIconBox}
            display="flex"
            alignItems="center"
            justifyContent={'center'}
          >
            <IconButton
              id="data-browser-decrease-media-size"
              size="small"
              disabled={itemPerRowOffset > 4}
              onClick={() => {
                dispatch(draft => {
                  draft.itemPerRowOffset++;
                });
              }}
            >
              <ZoomOutIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default MediaSelection;

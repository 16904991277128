import { BaseAPI } from './base_api';
import {
  ApiResponse,
  ProjectId,
  Task,
  TaskId,
  UserId,
  TaskStatus,
  TaskPurpose,
  MediaId,
  TaskResult,
  LabelingTaskRow,
  TaskAssignee,
  TaskMediaStats,
  MediaDetailsForLabelingReview,
  LabelingTaskReviewResult,
  MediaDetails,
  UserTaskMediaStats,
  ReviewType,
} from '@clef/shared/types';
import { TaskCreationOptions } from '../components/TaskCreationDialog/types';
import { TaskManagementRow } from '../store/types';

const TasksAPIUrlPrefix = 'tasks';

class TaskAPI extends BaseAPI {
  async getResult(taskId: TaskId): Promise<ApiResponse<TaskResult>> {
    return this.get('result', { taskId });
  }

  async getTask(taskId: TaskId): Promise<ApiResponse<Task>> {
    return this.get('id', {
      taskId,
    });
  }

  async getTaskStatus(taskId: TaskId): Promise<ApiResponse<{ status: TaskStatus }>> {
    return this.get(`status`, {
      taskId,
    });
  }

  async fetchLDBTasks(projectId: ProjectId): Promise<ApiResponse<Task[]>> {
    return this.get('list', { projectId, purposes: TaskPurpose.LDB });
  }

  async fetchTasks(projectId: ProjectId) {
    return this.get<ApiResponse<TaskManagementRow[]>>('list', {
      projectId,
      purposes: [TaskPurpose.Training, TaskPurpose.BatchLabeling, TaskPurpose.MultiLabeling].join(
        ',',
      ),
    });
  }

  async fetchTasksMediaStats(
    projectId: ProjectId,
    purposes: TaskPurpose[],
    taskIds?: TaskId[],
  ): Promise<ApiResponse<Record<TaskId, TaskMediaStats>>> {
    return this.get('media_stats', { projectId, purposes, taskIds });
  }

  async createTask(
    task: Partial<TaskCreationOptions>,
  ): Promise<ApiResponse<{ task: Task; userTasks: TaskAssignee[] }>> {
    return this.postJSON('create', task);
  }

  async getMediasByTaskId(taskId: TaskId): Promise<ApiResponse<{ ids: MediaId[] }>> {
    return this.get('medias', {
      taskId,
    });
  }

  async getLabelingMediaToReview(
    taskId: number,
    sortOrder: string = 'asc',
    type?: ReviewType,
  ): Promise<ApiResponse<MediaDetailsForLabelingReview[]>> {
    return this.get('labeling_media_to_review_details', { taskId, sortOrder, type });
  }

  async fetchMyLabelingTask(
    taskType: TaskPurpose,
    projectId: ProjectId,
  ): Promise<ApiResponse<LabelingTaskRow[]>> {
    return this.get('to_label', { taskType, projectId });
  }

  async postTaskCompleteBatch(
    id: TaskId,
    mediaIds: number[],
  ): Promise<ApiResponse<{ nothingLeftToFetch: boolean }>> {
    return this.postJSON('complete_batch', {
      taskId: id,
      mediaIds: mediaIds,
    });
  }

  async cancel(taskId: TaskId) {
    return this.postJSON(`cancel`, {
      taskId,
    });
  }

  async addAssignees(taskId: TaskId, userIds: UserId[]) {
    return this.postJSON('add_assignees', { taskId, userIds });
  }

  async submitLabelingReviewResult(
    datasetId: number,
    taskId: TaskId,
    reviewerId: string,
    reviewResult: LabelingTaskReviewResult,
    reassignMediaIds?: MediaId[],
  ) {
    return this.postJSON('labeling_task_submit_review', {
      datasetId,
      taskId,
      reviewerId,
      reviewResult,
      ...(reassignMediaIds ? { reassignMediaIds } : {}),
    });
  }

  async fetchUserTaskMediaLabelBatch(taskId: number): Promise<ApiResponse<MediaDetails[]>> {
    return this.get('user_task_media_label_batch', {
      taskId,
    });
  }

  async fetchUserTaskStats(taskId: number): Promise<ApiResponse<UserTaskMediaStats>> {
    return this.get('user_tasks_stats', {
      taskId,
    });
  }

  async reassignMedia(datasetId: number, taskId: TaskId, mediaIds: MediaId[]) {
    return this.postJSON('reassign_media', {
      datasetId,
      taskId,
      mediaIds,
    });
  }
}

export default new TaskAPI(TasksAPIUrlPrefix);

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useAttachTagStringsToMediasMutation, useGetProjectTagsQuery } from '@/serverStore/tags';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { TagAutocomplete } from '@/components/TagAutocomplete';
import { ApiResponseLoader, useDebounce } from '@clef/client-library';
import { Tag } from '@clef/shared/types';

interface IProps {
  mediaId: number;
  tagIds: number[];
}

const MediaTagPanelItem: React.FC<IProps & { tagOptions: Tag[] }> = ({
  mediaId,
  tagIds,
  tagOptions,
}) => {
  const selectedProjectId = useTypedSelector(state => state.project.selectedProjectId);
  const attachTags = useAttachTagStringsToMediasMutation();

  const selectOption = useMemo(
    () => ({
      selectedMedia: [mediaId],
      unselectedMedia: [],
      isUnselectMode: false,
      fieldFilterMap: {},
      columnFilterMap: {},
    }),
    [mediaId],
  );

  const onChangeTags = useCallback(
    (tags: string[]) => {
      selectedProjectId &&
        attachTags.mutate({
          projectId: selectedProjectId,
          tags,
          selectOption,
        });
    },
    [attachTags.mutate, selectedProjectId, selectOption],
  );

  const debouncedChangeTags = useDebounce(onChangeTags, 500);

  const tags = useMemo(() => {
    return tagIds
      .map(id => tagOptions.find(tag => tag.id === id)?.name)
      .filter(tag => !!tag) as string[];
  }, [tagIds, tagOptions]);

  const [value, setValue] = useState<string[]>([]);

  useEffect(() => {
    setValue(tags);
  }, [mediaId]);

  return (
    <TagAutocomplete
      tags={value}
      tagOptions={tagOptions.map(tag => tag.name)}
      onChangeTags={tags => {
        setValue(tags);
        debouncedChangeTags(tags);
      }}
    />
  );
};

export const MediaTagPanel: React.FC<IProps> = props => {
  const { data, isLoading, isRefetching, error } = useGetProjectTagsQuery();
  return (
    <ApiResponseLoader
      response={isRefetching ? undefined : data}
      loading={isLoading || isRefetching}
      error={error}
    >
      {response => (
        <MediaTagPanelItem {...props} tagOptions={response.filter(tag => !tag.isArchived)} />
      )}
    </ApiResponseLoader>
  );
};

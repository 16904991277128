import { useCallback, useEffect, useState } from 'react';
import hotkeys, { KeyHandler } from 'hotkeys-js';
import EventLogger, { LoggingEvent } from '../logging/EventLogger';

export type KeyPressOptions = {
  keyup?: boolean;
  keydown?: boolean;
  id?: string;
};

/**
 * This is the hook implementation of hotkeys-js, we self implemented this hook instead of using react-hotkeys-hook
 * https://www.npmjs.com/package/hotkeys-js
 * @param keys the keys to which callback gets triggered, refer to https://www.npmjs.com/package/hotkeys-js#supported-keys
 * @param callback the callback when given keys are pressed
 * @param options additional options such as keyup/keydown
 */
const useKeyPress = (keys: string, callback?: KeyHandler, options?: KeyPressOptions) => {
  useEffect(() => {
    const callbackFunc = callback
      ? options?.id
        ? // if id is provided, log LoggingEvent.ActionEvent
          (...args: Parameters<KeyHandler>) => {
            EventLogger.log(LoggingEvent.ActionEvent, {
              actionEventName: options.id!,
              actionType: 'hotkey',
            });
            callback(...args);
          }
        : callback
      : undefined;
    if (callbackFunc) {
      hotkeys(
        keys,
        {
          keyup: options?.keyup ?? false, // default false
          keydown: options?.keydown ?? true, // default true
        },
        callbackFunc,
      );
    }

    return () => {
      if (callbackFunc) {
        hotkeys.unbind(keys, callbackFunc);
      }
    };
    // `options` is expected to be absent in the dependency list to avoid unecessary unbind when used like
    // `useKeyPress('i', handleKepPress, { keyup: true })`, which will generate new options object each time
    // it is called. We consider the options rarely change so it should be Okay to be ignored here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, keys]);
};

export default useKeyPress;

/**
 * Return if one of the key are pressed and held
 * @param key the key to listen to
 * @returns a boolean to state if the key is pressed
 */
export const useKeyPressHold = (key: string) => {
  const [keyPressed, setKeyPressed] = useState(false);

  const handleKeyPress: KeyHandler = useCallback(
    event => {
      if (event.key !== key) {
        return;
      }
      if (event.type === 'keydown') {
        setKeyPressed(true);
      } else if (event.type === 'keyup') {
        setKeyPressed(false);
      }
    },
    [key],
  );

  // listen to '*' to support special keys like `Shift`
  useKeyPress('*', handleKeyPress, { keyup: true, keydown: true });

  return keyPressed;
};

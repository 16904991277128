import React, { useMemo, useState } from 'react';
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useDefectSelector, useDefectSelectorWithArchived } from '../../store/defectState/actions';
import { useEffect } from 'react';
import CLEF_PATH from '../../constants/path';
import { useHistory } from 'react-router';
import { Defect, LabelType } from '@clef/shared/types';
import { defectColors } from '@clef/client-library';
import useAvailableDefectColor from '../../hooks/useAvailableDefectColor';
import { useSavePostProcessingConfig } from '../Predict/hooks/useSavePostProcessingConfig';
import { useCreateDefectMutation, useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import { ClientFeatures } from '@clef/shared/features';
import { DialogWithColorSelector } from './DialogWithColorSelector';

interface CreateClassesDialogProps {
  onClose: () => void;
  onCreateSuccess?: (defect: Defect) => void;
  onClassCreateOverwrite?: (defectName: string, defectColor: string) => void | Promise<void>;
  onClassCreateDiscard?: () => void | Promise<void>;
  title?: string;
  initialDefectName?: string;
  initialDefectColor?: string;
  hideColorSelector?: boolean;
  skipExistingColorCheck?: boolean;
}

export const CreateClassesDialog: React.FC<CreateClassesDialogProps> = ({
  onClose,
  onCreateSuccess,
  onClassCreateOverwrite,
  onClassCreateDiscard,
  title = t('You Have Unsaved Class'),
  initialDefectName = '',
  initialDefectColor,
  hideColorSelector = false,
  skipExistingColorCheck = false,
}) => {
  const [selectedName, setSelectedName] = useState(initialDefectName);
  const { availableColor } = useAvailableDefectColor();
  const [selectedColor, setSelectedColor] = useState(initialDefectColor ?? availableColor);
  const { enqueueSnackbar } = useSnackbar();
  const [isCreatingClass, setIsCreatingClass] = useState(false);
  const history = useHistory();
  const defects = useDefectSelectorWithArchived();
  const { data: project } = useGetSelectedProjectQuery();
  const { labelType } = project ?? {};
  const instantLearningPostprocessingEnabled = useFeatureGateEnabled(
    ClientFeatures.InstantLearningPostprocessing,
  );
  const createDefectApi = useCreateDefectMutation();

  useEffect(() => {
    setSelectedColor(initialDefectColor ?? availableColor);
  }, [initialDefectColor, availableColor]);

  useEffect(() => {
    setSelectedName(initialDefectName);
  }, [initialDefectName]);

  const chosenColors = useMemo(() => {
    return (defects || []).map(item => {
      const color = (item.color || '').toUpperCase();
      return color;
    });
  }, [defects]);

  const allDefects = useDefectSelector();
  const savePostProcessingConfig = useSavePostProcessingConfig();

  const onCreateClass = useCallback(async () => {
    setIsCreatingClass(true);
    try {
      const newClass = await createDefectApi.mutateAsync({
        name: selectedName.trim(),
        color: selectedColor,
      });

      if (
        labelType === LabelType.SegmentationInstantLearning &&
        instantLearningPostprocessingEnabled
      ) {
        await savePostProcessingConfig([...allDefects, newClass]);
      }

      onClose();
      enqueueSnackbar(
        t('Successfully created the class: {{temp0}}', {
          temp0: selectedName,
        }),
        {
          variant: 'success',
        },
      );
      if (!onCreateSuccess) {
        history.push(`${CLEF_PATH.data.defectBookEnhanced}?tab=defects&defect=${newClass.id}`);
      } else {
        onCreateSuccess?.(newClass);
      }
    } catch (err) {
      const e = err as Record<string, any>;
      enqueueSnackbar(
        (e?.body || e)?.message, // this could be an api error or frontend error
        { variant: 'error' },
      );
      setIsCreatingClass(false);
    }
  }, [
    selectedName,
    selectedColor,
    labelType,
    instantLearningPostprocessingEnabled,
    onClose,
    enqueueSnackbar,
    onCreateSuccess,
    savePostProcessingConfig,
    allDefects,
    history,
  ]);

  return (
    <DialogWithColorSelector
      title={title}
      propertyName="Class"
      selectedName={selectedName}
      setSelectedName={setSelectedName}
      selectedColor={selectedColor}
      setSelectedColor={setSelectedColor}
      defaultColors={defectColors}
      chosenColors={chosenColors}
      isCreating={isCreatingClass}
      hideColorSelector={hideColorSelector}
      skipExistingColorCheck={skipExistingColorCheck}
      onClose={onClose}
      onDiscardButtonClick={onClassCreateDiscard}
      onSaveButtonClick={
        onClassCreateOverwrite
          ? async () => {
              setIsCreatingClass(true);
              await onClassCreateOverwrite(selectedName.trim(), selectedColor);
              setIsCreatingClass(false);
              onClose();
            }
          : onCreateClass
      }
    />
  );
};

export default CreateClassesDialog;

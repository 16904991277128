import React from 'react';
import { Grid, LinearProgress, Typography, makeStyles, Link } from '@material-ui/core';
import { useDialog } from '../../../Layout/components/useDialog';
import { useHistory } from 'react-router';
import CLEF_PATH from '../../../../constants/path';
import { UpgradePurpose } from '@clef/shared/types';

const useStyles = makeStyles(theme => ({
  linearProgress: {
    borderRadius: 2,
    marginBottom: theme.spacing(2),
    width: 300,
  },
  text: {
    color: theme.palette.grey[500],
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export interface MediaUploadProgressProps {
  mediaCount?: number;
  mediaLimit?: number | null;
  isNewCreditSystem?: boolean;
  className?: string;
  skipUpgradeLink?: boolean;
}

export const MediaUploadProgress: React.FC<MediaUploadProgressProps> = ({
  mediaCount,
  mediaLimit,
  isNewCreditSystem,
  className,
  skipUpgradeLink = false,
}) => {
  const styles = useStyles();
  const { closeUpload } = useDialog();
  const history = useHistory();

  // If media limit is null (i.e. no limitation on media), then return empty UI
  if (mediaCount === undefined || mediaLimit === undefined || mediaLimit === null) {
    return null;
  }

  const hasNoRemainingMediaUpload = mediaCount >= mediaLimit;

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      className={className}
    >
      {hasNoRemainingMediaUpload ? (
        <LinearProgress
          variant="determinate"
          value={100}
          color="secondary"
          className={styles.linearProgress}
        />
      ) : (
        <LinearProgress
          variant="determinate"
          value={(mediaCount / mediaLimit) * 100}
          className={styles.linearProgress}
        />
      )}

      <Typography variant="body2" className={styles.text}>
        {t('{{mediaCount}} of {{mediaLimit}} image(s) have been uploaded.', {
          mediaCount,
          mediaLimit,
        })}{' '}
        {!skipUpgradeLink && (
          <Link
            className={styles.link}
            onClick={() => {
              if (isNewCreditSystem) {
                history.push(CLEF_PATH.plan + '?purpose=' + UpgradePurpose.MoreImages);
                closeUpload();
              }
            }}
          >
            {t('Upgrade today!')}
          </Link>
        )}
      </Typography>
    </Grid>
  );
};

export default MediaUploadProgress;

import { Box, Menu, MenuItem } from '@material-ui/core';
import { Button } from '@clef/client-library';
import MoreVert from '@material-ui/icons/MoreVert';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';

export interface ActionProps {
  children: React.ReactNode;
  onClick: () => void;
  changeInlineUiOnClick: boolean;
  onInlineChangedUiCancel?: () => void;
  onInlineChangedUiConfirm?: () => Promise<any>;
}

export interface ActionsProps {
  actions: Array<ActionProps>;
}

const Actions: React.FC<ActionsProps> = props => {
  const { actions } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [changeInlineUi, setChangeInlineUi] = useState(false);
  const [activeActionIndex, setActiveActionIndex] = useState<number | null>(null);

  const onMenuClose = useCallback(() => {
    setAnchorEl(null);
    setChangeInlineUi(false);
  }, []);

  const onInlineChangedUiCancel = useCallback(() => {
    onMenuClose();
    if (activeActionIndex !== null) {
      const action = actions[activeActionIndex];
      if (action?.onInlineChangedUiCancel) {
        action.onInlineChangedUiCancel();
      }
    }
  }, [onMenuClose, activeActionIndex, actions]);

  const onInlineChangedUiConfirm = useCallback(async () => {
    if (activeActionIndex !== null) {
      try {
        const action = actions[activeActionIndex];
        if (action?.onInlineChangedUiConfirm) {
          await action.onInlineChangedUiConfirm();
        }
        onMenuClose();
      } catch (e) {
        enqueueSnackbar((e as Error).message, {
          variant: 'error',
        });
      }
    }
  }, [enqueueSnackbar, onMenuClose, activeActionIndex, actions]);

  return changeInlineUi ? (
    <>
      <Box display="flex">
        <Button id="upsert-members-cancel" color="secondary" onClick={onInlineChangedUiCancel}>
          {t('Cancel')}
        </Button>
        <Button color="primary" onClick={onInlineChangedUiConfirm} id="upsert-members-done">
          {t('Done')}
        </Button>
      </Box>
    </>
  ) : (
    <>
      <Button
        aria-controls="action-menu"
        aria-haspopup="true"
        onClick={event => setAnchorEl(event.currentTarget)}
        className="cy-member-settings-selector"
        id="table-action-btn"
      >
        <MoreVert />
      </Button>
      <Menu
        id="action-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onMenuClose}
        className="cy-member-conf-menu"
      >
        {actions.map(({ children, onClick, changeInlineUiOnClick }, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setChangeInlineUi(changeInlineUiOnClick);
              setActiveActionIndex(index);
              onClick();
            }}
            className="cy-edit-member-li"
            data-testid="start-manage-permission"
          >
            {children}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Actions;

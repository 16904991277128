import React from 'react';
import { DialogContent, Typography, Card, Grid } from '@material-ui/core';
import { useUploadStyles } from '../styles';
import { ClassificationUploadType } from './types';
import classification_upload_unclassified from '../../../../images/classification_media_upload/classification_upload_unclassified.png';
import classification_upload_classified from '../../../../images/classification_media_upload/classification_upload_classified.png';
import MediaUploadProgress from '../components/MediaUploadProgress';

const ClassificationUploadOptions = [
  {
    type: ClassificationUploadType.UnclassifiedUpload,
    title: t('Unclassified Images'),
    caption: t(
      'uploaded images will be raw status, you can classify them using the platform labeling tool',
    ),
    imgSrc: classification_upload_unclassified,
  },
  {
    type: ClassificationUploadType.ClassifiedUpload,
    title: t('Classified Images'),
    caption: t('uploaded images will be classified based on your folder structure'),
    imgSrc: classification_upload_classified,
  },
];

const PickUploadType: React.FC<{
  onSelectUploadType: (choice: ClassificationUploadType) => void;
  mediaCount?: number;
  mediaLimit?: number | null;
  isNewCreditSystem?: boolean;
}> = ({ onSelectUploadType, mediaCount, mediaLimit, isNewCreditSystem }) => {
  const styles = useUploadStyles();
  return (
    <>
      <div className={styles.headerContainer}>
        <Typography variant="h1" align="center" className={styles.headerText}>
          {t('Select how you want upload images')}
        </Typography>
        {/* Upload limit bar*/}
        <MediaUploadProgress
          mediaCount={mediaCount}
          mediaLimit={mediaLimit}
          isNewCreditSystem={isNewCreditSystem}
        />
      </div>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          {ClassificationUploadOptions.map(option => (
            <Card
              className={styles.uploadTypeCard}
              key={option.type}
              onClick={() => onSelectUploadType(option.type)}
            >
              <Typography variant="h3">{option.title}</Typography>
              <Typography variant="caption">{option.caption}</Typography>
              <img src={option.imgSrc} />
            </Card>
          ))}
        </Grid>
      </DialogContent>
    </>
  );
};

export default PickUploadType;

import { makeStyles } from '@material-ui/core';

export const useMediaInteractiveCanvasStyles = makeStyles(theme => ({
  mediaCanvasContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    backgroundColor: theme.palette.grey[300],
    '& > :first-child': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '&:hover $zoomAction': {
      opacity: 1,
    },
    '&:hover $hideLabelsButton': {
      opacity: 1,
    },
  },
  zoomAction: {
    position: 'absolute',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    top: 20,
    right: 20,
    width: 'auto!important',
    height: 'auto',
    opacity: 0,
    '&.topLeft': {
      right: 'unset',
      left: 20,
    },
  },
  hideLabelsButton: {
    background: '#fff',
    boxShadow: theme.boxShadow.default,
    position: 'absolute',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    bottom: 20,
    right: 20,
    opacity: 0,
    '&:hover': {
      background: theme.palette.grey[100],
    },
    zIndex: 1,
  },
  codeBlock: {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 4,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: theme.spacing(1),
    fontWeight: 700,
  },
  buttonGroup: {
    background: '#fff',
    borderRadius: '16px!important',
  },
  zoomOptionsPopover: {
    zIndex: theme.zIndex.modal + 1,
  },
  mousePosition: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: theme.spacing(1, 2),
    position: 'absolute',
    left: 0,
    bottom: 0,
    borderTopRightRadius: 8,
  },
  configItem: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    gap: `${theme.spacing(3)}px`,
    alignItems: 'center',
  },
  configLabel: {
    minWidth: 70,
  },
  imageSizeButton: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
    width: '100%',
    padding: 10,
  },
  popoverPaper: {
    width: '100%',
    height: '100%',
    maxHeight: 'unset',
    maxWidth: 'unset',
  },
  advanceLabel: {
    marginBottom: theme.spacing(4),
  },
  imageSizeOption: {
    minWidth: 200,
  },
  imageSizeOptionLabel: {
    minWidth: 90,
  },
  configMenu: {
    padding: theme.spacing(5),
    width: 318,
    color: theme.palette.grey[600],
  },
}));

import { Draft } from 'immer';
import { useContext, createContext } from 'react';
import { DefectDistributionWithAssignment } from '@clef/shared/utils/auto_split_core_algorithm';

export type AutoSplitState = {
  includeAlreadySplitMedia: boolean;
  currentStep: 0 | 1 | 2;
  isMakingPostRequest: boolean;
  defectDistributionWithAssignment?: DefectDistributionWithAssignment[];
};

export const defaultState: AutoSplitState = {
  includeAlreadySplitMedia: false,
  currentStep: 0,
  isMakingPostRequest: false,
};

/**
 * Context
 */
export const AutoSplitStateContext = createContext<{
  state: AutoSplitState;
  dispatch: (f: (state: Draft<AutoSplitState>) => void | AutoSplitState) => void;
}>({
  state: defaultState,
  dispatch: () => {},
});

export const useAutoSplitState = () => useContext(AutoSplitStateContext);

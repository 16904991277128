import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.grey[600],
    fontWeight: 500,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    fontSize: 14,
    marginBottom: theme.spacing(1),
  },
  thresholdWrapper: {
    display: 'flex',
    columnGap: '12px',
    marginBottom: '12px',
  },
  thresholdInputRoot: {
    width: 44,
    height: 36,
  },
  thresholdInput: {
    textAlign: 'center',
    padding: 0,
    fontFamily: theme.typography.body1.fontFamily,
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  saveButton: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    minWidth: 40,
  },
  sliderRoot: {
    margin: 'auto 0',
    flex: 1,
  },
  sliderRail: {
    color: '#E6E7E9',
    borderRadius: 4,
    height: 4,
  },
  sliderTrack: {
    color: '#4B5565',
    borderRadius: 4,
    height: 4,
  },
  sliderThumb: {
    width: 16,
    height: 16,
    background: '#FFFFFF',
    border: '1.5px solid #4B5565',
    borderRadius: 12,
    marginTop: -7,
    '&:hover': {
      boxShadow: '0px 0px 0px 8px rgb(75, 85, 101, .16)',
    },
    '&.MuiSlider-active': {
      boxShadow: '0px 0px 0px 14px rgb(75, 85, 101, .16)',
    },
  },
  sliderActive: {
    boxShadow: '0px 0px 0px 14px rgb(75, 85, 101, .16)',
  },
  tooltip: {
    maxWidth: 312,
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: 20,
    width: 'auto',
  },
  tooltipTitle: {
    fontWeight: 700,
    lineHeight: '20px',
    marginBottom: 17,
  },
  disabled: {
    color: '#9BA0A6',
    cursor: 'default',
    pointerEvents: 'none',
    '& .MuiSlider-rail': {
      color: '#9BA0A6',
    },
    '& .MuiSlider-track': {
      color: 'transparent',
    },
    '& .MuiSlider-thumb': {
      width: 16,
      height: 16,
      borderRadius: 12,
      marginTop: -7,
      border: 'unset',
      color: '#9BA0A6',
      backgroundColor: '#E6E7E9',
    },
  },
}));

export default useStyles;

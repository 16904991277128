import { MetadataType, EdgeMediaDetails } from '@clef/shared/types';
import { CL_METADATA_NAMES, CL_METADATA_NAMES_TO_FIELD_MAPPING } from './constants';
import MetadataAPI from '../../../api/metadata_api';
import { createMetadataApi } from '../../../hooks/api/useMetadataApi';
import { DEFAULT_DEVICE_NAME } from '../../../pages/deployment/constants';

/**
 * Checks or creates CL Metadata Fields for the project
 * @param projectId LL Project Id
 */
export const getCLMetadataFields = async (projectId: number) => {
  const projectMetadata = await MetadataAPI.getMetadataByProjectId(projectId);
  const metadatas = Object.values(projectMetadata);
  const clMetadataFieldIds = await Promise.all(
    CL_METADATA_NAMES.map(async name => {
      const metadataId = metadatas.find(metadata => metadata.name == name)?.id;
      return (
        metadataId ??
        (await createMetadataApi(projectId, name, MetadataType.Text, false, true)).data.id
      );
    }),
  );
  return clMetadataFieldIds;
};

/**
 * Get Metadata Object using given mediaDetails and clMetadataFieldIds
 * @param clMetadataFieldIds CL MetadataField Ids
 * @param mediaDetails EdgeMediaDetails
 */
export const getMetadataObject = (clMetadataFieldIds: number[], mediaDetails: EdgeMediaDetails) => {
  return CL_METADATA_NAMES.reduce((acc, name, idx) => {
    const mapping = CL_METADATA_NAMES_TO_FIELD_MAPPING[name];
    const mediaDetailsKey = mapping.key;
    return {
      ...acc,
      [clMetadataFieldIds[idx]]: mediaDetails[mediaDetailsKey] || mapping.default,
    };
  }, {});
};

/**
 *
 * @param url
 * @param name
 * @param defaultType
 */
export async function getFileFromUrl(
  url: string,
  name: string,
  defaultType = 'image/png',
): Promise<File> {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: defaultType, // # response.headers.get('content-type') || defaultType
  });
}

/**
 *
 * @param deviceId
 * @param projectId
 */
export const normalizeDefaultDeviceName = (deviceId: string, projectId: string) =>
  deviceId === projectId ? DEFAULT_DEVICE_NAME : deviceId;

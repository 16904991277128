import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { addFile } from '../../store/uploadState';
import { useAppDispatch } from '../../store';
import { useSnackbar } from 'notistack';
import { UploadStage } from '../../store/uploadState/types';
import { ReachLimitException } from '../../store/uploadState/utils';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useDialog } from '../../components/Layout/components/useDialog';
import { ExceedType } from '../../components/Layout/components/UsageExceedDialog';
import DataBrowserEmptyStateSvg from '../../images/data-browser/data-browser-upload.svg';
import UploadLimitDialog from './UploadLimitDialog';
import { addSkipDialogWithId, SkipDialogKey } from '../../utils/train';
import { useIsShowUploadLimitDialog } from './utils';
import {
  useImageSizeLimit,
  useShowOverSizedImagesSnackbar,
} from '@/components/Dialogs/UploadFullscreen/imageSizeUtils';
import { LabelType } from '@clef/shared/types';
import {
  addSegmentationImageFile,
  OverSizeLimitException,
} from '@/store/uploadState/segmentationActions';
import { useUploadMediaMutation } from '@/serverStore/upload';

const useStyles = makeStyles(theme => ({
  overlay: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  dropzone: {
    border: 'none',
    background: 'none',
    cursor: 'unset',
  },
}));

export type DataBrowserUploadDropZoneProps = {
  changingLabelType?: boolean;
  children?: ReactNode | ((isDragActive: boolean) => ReactNode);
};

type UseUploadAnimateStyles = {
  circleWidth?: number;
};

const useUploadAnimateStyles = makeStyles<Theme, UseUploadAnimateStyles>(theme => {
  return {
    emptyStateText: {
      color: theme.palette.grey[900],
      fontSize: 20,
      lineHeight: '28px',
      marginBottom: theme.spacing(7),
      marginTop: theme.spacing(3),
    },

    '@keyframes circle1Animation': {
      '0%': { opacity: 0, transform: 'scale(0.6666666)' },
      '50%': {
        transform: 'scale(1)',
        opacity: 1,
      },
      '100%': { opacity: 0.5, transform: 'scale(0.6666666)' },
    },
    circleBox: {
      width: props => props.circleWidth || 360,
      height: props => props.circleWidth || 360,
      position: 'relative',
    },
    circle1: {
      width: props => props.circleWidth || 360,
      height: props => props.circleWidth || 360,
      borderRadius: '100%',
      backgroundColor: theme.palette.blue[25],
      transformOrigin: '180px 180px',
      animation: '$circle1Animation 2s ease infinite',
    },
    circle2: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: props => (props.circleWidth || 360) * 0.6666,
      height: props => (props.circleWidth || 360) * 0.6666,
      borderRadius: '100%',
      transform: `translate(-50%, -50%)`,
      backgroundColor: theme.palette.blue[50],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      zIndex: 10,
    },
  };
});

export const UploadAnimate: React.FC<{ label?: React.ReactNode; circleWidth?: number }> = ({
  label,
  circleWidth = 360,
}) => {
  const styles = useUploadAnimateStyles({
    circleWidth,
  });
  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <div className={styles.circleBox}>
        <div className={styles.circle1}></div>
        <div className={styles.circle2}>
          <img src={DataBrowserEmptyStateSvg} />
          <Typography variant="h3" className={styles.emptyStateText}>
            {label}
          </Typography>
        </div>
      </div>
    </Box>
  );
};

const DataBrowserUploadDropZone: React.FC<
  DataBrowserUploadDropZoneProps & {
    className?: string;
    mediaCapacity?: number | null;
    mediaLimit?: number | null;
    isNewCreditSystem?: boolean;
    disabled?: boolean;
  }
> = props => {
  const {
    children,
    mediaCapacity: fileCapacity,
    mediaLimit: fileLimit,
    disabled,
    changingLabelType,
    isNewCreditSystem,
    className,
  } = props;
  const styles = useStyles();

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const uploadStage = useTypedSelector(state => state.uploadState.uploadStage);
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { orgId, labelType } = selectedProject ?? {};

  const { showUsageExceedDialog } = useDialog();
  const [openUploadLimitDialog, setOpenUploadLimitDialog] = useState(false);
  const isShowDialog = useIsShowUploadLimitDialog();
  const pendingUploadFiles = useRef<File[]>([]);

  const sizeLimit = useImageSizeLimit();
  const showOverSizedImagesSnackbar = useShowOverSizedImagesSnackbar();
  const uploadMedia = useUploadMediaMutation();

  const onDropUploadCallback = useCallback(
    async (files: File[]) => {
      if (uploadStage === UploadStage.UploadInProgress) {
        enqueueSnackbar('Please wait for upload to finish before uploading more images', {
          variant: 'warning',
        });
        return;
      }
      try {
        if (labelType === LabelType.Segmentation) {
          await dispatch(
            addSegmentationImageFile({
              files,
              capacity: fileCapacity,
              limit: fileLimit,
              throwOnReachLimit: isNewCreditSystem,
              sizeLimit,
            }),
          ).unwrap();
        } else {
          await dispatch(
            addFile({
              files,
              capacity: fileCapacity,
              limit: fileLimit,
              throwOnReachLimit: isNewCreditSystem,
            }),
          ).unwrap();
        }
      } catch (e) {
        if (e instanceof ReachLimitException) {
          showUsageExceedDialog(ExceedType.Upload, e.fileCount, fileCapacity!);
          return;
        } else if (e instanceof OverSizeLimitException) {
          showOverSizedImagesSnackbar(e);
          return;
        }
      }
      uploadMedia.mutate();
    },
    [
      uploadStage,
      enqueueSnackbar,
      labelType,
      dispatch,
      fileCapacity,
      fileLimit,
      isNewCreditSystem,
      sizeLimit,
      showUsageExceedDialog,
      showOverSizedImagesSnackbar,
      selectedProject,
    ],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['image/jpeg', 'image/bmp', 'image/png', '.mpo'],
    noKeyboard: true,
    onDrop: async files => {
      if (isShowDialog && orgId) {
        pendingUploadFiles.current = files;
        addSkipDialogWithId(SkipDialogKey.Upload, orgId);
        setOpenUploadLimitDialog(true);
        return;
      }
      onDropUploadCallback(files);
    },
    multiple: true,
    disabled: changingLabelType || disabled,
    noClick: true,
  });

  return (
    <Box
      {...getRootProps()}
      className={className}
      flex={1}
      data-testid="first-run-experience-drop-zone"
      id="first-run-experience-drop-zone"
    >
      {isDragActive && (
        <Box className={styles.overlay}>
          {isDragActive &&
            (uploadStage !== UploadStage.UploadInProgress ? (
              <UploadAnimate label={t('Drop images here')} />
            ) : (
              <Typography variant="h3" color="error">
                {t('Please wait for upload to finish')}
              </Typography>
            ))}
        </Box>
      )}
      <input
        {...getInputProps()}
        //@ts-ignore
        directory="true"
        webkitdirectory="true"
        multiple
        type="file"
        disabled={changingLabelType || disabled}
        data-testid="data-browser-drop-upload-input"
      />
      {typeof children === 'function' ? children(isDragActive) : children}
      {openUploadLimitDialog && (
        <UploadLimitDialog
          open={openUploadLimitDialog}
          onGotIt={() => {
            setOpenUploadLimitDialog(false);
            if (pendingUploadFiles.current.length) {
              onDropUploadCallback(pendingUploadFiles.current);
              pendingUploadFiles.current = [];
            }
          }}
        />
      )}
    </Box>
  );
};

export default DataBrowserUploadDropZone;

import React from 'react';
import MembersV2 from './MembersV2';
import Members from './Members';
import { useShouldDisplayPricingV2 } from '@/hooks/useSubscriptions';

const MembersSection = () => {
  /**
   * this switch could be removed after all users migrate to new pricing plan
   */
  const { shouldDisplayPricingV2 } = useShouldDisplayPricingV2();
  if (shouldDisplayPricingV2) {
    return <MembersV2 />;
  } else {
    return <Members />;
  }
};

export default MembersSection;

import React, { useState } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { IconButton, Typography } from '@clef/client-library';
import Edit from '@material-ui/icons/Edit';
import { Skeleton } from '@material-ui/lab';
import { isProjectOwner } from '@clef/shared/utils';
import { WorkflowAssistant } from '../../WorkflowAssistant';
import { useIsFeatureEnabledAndMayHideForSnowflake } from '../../../hooks/useFeatureGate';
import EditProjectNameTextField from '@/components/Text/EditProjectNameTextField';

const useStyles = makeStyles(() => ({
  banner: {
    height: 60,
    flexWrap: 'nowrap',
  },
  projectName: {
    fontSize: 14,
    fontWeight: 500,
  },
  workflowAssistant: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginRight: '15%',
  },
}));

export type BannerProps = {};

const Banner: React.FC<BannerProps> = () => {
  const styles = useStyles();
  const { id: projectId, users, name } = useGetSelectedProjectQuery().data ?? {};
  const user = useTypedSelector(state => state.login.user!);
  const currentUserWithRole = users?.find(userRole => userRole.userProject.userId === user.id);
  const isOwner = isProjectOwner(currentUserWithRole?.userProject.role);
  const workflowAssistantEnabled = useIsFeatureEnabledAndMayHideForSnowflake().workflowAssistant;

  const [isEditingName, setIsEditingName] = useState(false);

  return (
    <Grid container className={styles.banner} alignItems="center">
      {projectId !== undefined && name ? (
        <Box width={300} display="flex" alignItems="center">
          {isEditingName ? (
            <EditProjectNameTextField
              name={name}
              projectId={projectId}
              setIsEditingName={setIsEditingName}
            />
          ) : (
            <>
              <Box display="flex" alignItems="center" onDoubleClick={() => setIsEditingName(true)}>
                <Typography className={styles.projectName} maxWidth={250}>
                  {name}
                </Typography>
              </Box>
              {isOwner && (
                <IconButton id="edit-project-name" onClick={() => setIsEditingName(true)}>
                  <Edit fontSize="small" />
                </IconButton>
              )}
            </>
          )}
        </Box>
      ) : (
        <Skeleton variant="text" />
      )}

      {workflowAssistantEnabled && <WorkflowAssistant className={styles.workflowAssistant} />}
    </Grid>
  );
};

export default Banner;

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  divider: {
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
  },
  sidebarContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  instantLearningSidebarSectionContent: {
    maxHeight: 'calc((100% - 64px)/3*2)',
    overflow: 'hidden auto',
  },
  deployComponent: {
    height: '64px',
  },
  instantLearningSidebarSection: {
    background: '#F3F6F9',
    borderRadius: 10,
    padding: '12px 24px',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflowY: 'auto',
  },
  sidebarSection: {
    background: '#F3F6F9',
    borderRadius: 10,
    padding: '12px 24px',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflowY: 'auto',
  },
  tabsRoot: {
    minHeight: 20,
    marginBottom: 16,
  },
  tabsFlexContainer: {
    columnGap: 24,
  },
  tabsIndicator: {
    display: 'none',
  },
  tab: {
    padding: 0,
    minWidth: 'auto',
    width: 'auto',
    minHeight: 20,
    fontFamily: theme.typography.body1.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#6C737F',
  },
  tabSelected: {
    color: '#051221',
    fontWeight: 700,
  },
  labelPreviewContainer: {
    backgroundColor: 'transparent',
  },
  resetButton: {
    color: theme.palette.blue[600],
    marginRight: '8px',
  },
  jsonTabContent: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    overflow: 'hidden auto',
  },
}));

export default useStyles;

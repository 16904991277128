import { useJobDetailForCurrentProject } from '@/serverStore/jobs';

export const useIsLargeImageModel = (modelId?: string) => {
  const { data: modelDetailsData, isLoading: loading } = useJobDetailForCurrentProject(modelId);

  const newLoading = !modelId || !modelDetailsData || loading;
  const isLargeImageModel = !!modelDetailsData?.isLargeImageModel;

  return [isLargeImageModel, newLoading];
};

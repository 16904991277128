import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    minWidth: '100vw',
    minHeight: '100vh',
    padding: theme.spacing(5, 2),
    position: 'relative',
  },
  title: {
    width: 'auto',
    marginBottom: theme.spacing(7),
  },
  landingLensText: {
    marginLeft: theme.spacing(3),
    color: theme.palette.primary.main,
  },
  helperText: {
    marginBottom: theme.spacing(8),
  },
  marginRight1: {
    marginRight: theme.spacing(1),
  },
  marginRight3: {
    marginRight: theme.spacing(3),
  },
  marginRight8: {
    marginRight: theme.spacing(8),
  },
  marginBottom2: {
    marginBottom: theme.spacing(2),
  },
  marginBottom3: {
    marginBottom: theme.spacing(3),
  },
  marginBottom6: {
    marginBottom: theme.spacing(6),
  },
  marginBottom8: {
    marginBottom: theme.spacing(8),
  },
  marginBottom12: {
    marginBottom: theme.spacing(12),
  },
  marginBottom20: {
    marginBottom: theme.spacing(20),
  },
  featureItemDotIcon: {
    fontSize: 8,
    color: theme.palette.grey[300],
  },
  featureItemContent: {
    width: '95%',
  },
  alreadyHaveAnAccountText: {
    color: theme.palette.grey[500],
  },
  termsText1: {
    display: 'inline',
  },
  termsText2: {
    display: 'inline',
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  normalButton: {
    width: 400,
    height: 40,
    display: 'flex',
    alignItems: 'center',
  },
  submitButton: {
    width: 400,
    height: 40,
  },
  normalInputBox: {
    width: 400,
    height: 40,
  },
  smallInputBox: {
    width: 184,
    height: 40,
  },
  orSeparatorDivider: {
    width: 160,
  },
  orSeparatorText: {
    padding: theme.spacing(0, 8),
    fontWeight: 700,
  },
  autoWidth: {
    width: 'auto',
  },
  loadingSpinner: {
    color: theme.palette.grey[400],
  },
  link: {
    fontWeight: 700,
    fontSize: 14,
    cursor: 'pointer',
  },
  newLink: {
    color: theme.palette.blue[500],
  },
  reviewedTermsCheckboxErrorState: {
    color: theme.palette.secondary.main,
  },
  joinOrgAction: {
    fontWeight: 700,
  },
  orgCard: {
    cursor: 'pointer',
  },
  orgCardTempUser: {
    cursor: 'pointer',
    color: theme.palette.blue[600],
  },
  orgCardDisabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  orgCardSsoOrgKeyIcon: {
    fontSize: 16,
    marginRight: theme.spacing(1),
  },
  orgItemActionLink: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  orgItemActionLinkLoading: {
    cursor: 'wait',
  },
  orgsSectionContainer: {
    width: 'auto',
    flexWrap: 'nowrap',
  },
  orgsSectionTitle: {
    alignSelf: 'flex-start',
  },
  orgsSectionContent: {
    marginBottom: theme.spacing(8),
  },
  orgsSectionItemContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '11px 21px',
    width: 400,
    borderRadius: 6,
    marginTop: 12,
    minHeight: 80,
  },
  subscriptionSpacing: {
    width: theme.spacing(1),
  },
  selectedOrgsSectionItemContainer: {
    border: '2px solid #0C64CD',
    padding: '10px 20px',
  },
  orgsSectionItemTitle: {
    width: '100%',
    justifySelf: 'flex-start',
  },
  orgsSectionItemOrgName: {
    fontWeight: 700,
  },
  orgsSectionItemOrgId: {
    fontWeight: 400,
    color: theme.palette.grey[500],
  },
  orgsSectionItemEmail: {
    fontWeight: 400,
  },
  orgsSectionItemSso: {
    fontWeight: 400,
  },
  flexGrow: {
    flex: 1,
  },
  resetPasswordLink: {
    color: 'white',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  backButton: {
    position: 'absolute',
    top: 96,
    left: 60,
    border: `1px solid ${theme.palette.greyModern[300]}`,
    width: 40,
    height: 40,
  },
  joinOrgTitle: {
    marginTop: theme.spacing(15),
  },
  subscriptionTypeChip: {
    '&.starter': {
      backgroundColor: '#A3C2FF !important',
      color: theme.palette.grey[900],
    },
    '&.visionary': {
      backgroundColor: `${theme.palette.blue[500]} !important`,
      color: 'white',
    },
    '&.enterprise': {
      backgroundColor: `${theme.palette.purple[500]} !important`,
      color: 'white',
    },
  },
  subscriptionTypeChipText: {
    fontWeight: 'bold',
  },
}));

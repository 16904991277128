import React from 'react';
import { Route } from 'react-router-dom';

import CLEF_PATH from '../../../constants/path';
import { transformLegacyPath } from '../utils';
import { PageLayout } from '../PageLayout';

import { PageTitle } from '../../../constants/page';
import { PageName } from '@clef/shared/constants';
import ModelComparisonReportsPage from '@/pages/model_iteration/ModelComparisonReportsPage';
import ModelsPageV2 from '@/pages/model_iteration/ModelsPageV2';
import FailedModelJobsPage from '@/pages/model_iteration/FailedModelJobsPage';

const modelIterationV2Routes = (currentPath: string) => [
  // Models routes
  <Route
    exact
    key={CLEF_PATH.modelsV2.list}
    path={transformLegacyPath(currentPath, CLEF_PATH.modelsV2.list)}
    render={() => (
      <PageLayout title={PageTitle.Models} pageName={PageName.ModelListPage} showProjectBanner>
        <ModelsPageV2 />
      </PageLayout>
    )}
  />,
  <Route
    exact
    key={CLEF_PATH.modelsV2.comparisonReports}
    path={transformLegacyPath(currentPath, `${CLEF_PATH.modelsV2.comparisonReports}`)}
    render={() => (
      <PageLayout
        title={PageTitle.ModelComparisonReports}
        breadcrumb={{ label: t('Models'), to: CLEF_PATH.modelsV2.list }}
        pageName={PageName.ModelComparisonReportsPage}
        showProjectBanner
      >
        <ModelComparisonReportsPage />
      </PageLayout>
    )}
  />,
  <Route
    exact
    key={CLEF_PATH.modelsV2.failedJobs}
    path={transformLegacyPath(currentPath, `${CLEF_PATH.modelsV2.failedJobs}`)}
    render={() => (
      <PageLayout
        title={PageTitle.ModelAnalysisFailedJobs}
        breadcrumb={{ label: t('Models'), to: CLEF_PATH.modelsV2.list }}
        pageName={PageName.ModelAnalysisFailedJobs}
        showProjectBanner
      >
        <FailedModelJobsPage />
      </PageLayout>
    )}
  />,
];

export default modelIterationV2Routes;

import React, { useCallback, useState } from 'react';
import { UploadFullscreenProps } from '..';
import { Typography, Grid } from '@material-ui/core';
import FullscreenDialog from '../../FullScreenDialog';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { UploadStage } from '../../../../store/uploadState/types';
import { useUploadStyles } from '../styles';
import AnomalyDetectionDropFileZone from './AnomalyDetectionDropzone';
import { AnomalyDefectionClassName } from '../../../../utils';
import UploadWrapper from '../components/UploadWrapper';
import { useDialog } from '../../../Layout/components/useDialog';
import { getUploadStats } from '../../../../store/uploadState/utils';
import { resetUploadState } from '../../../../store/uploadState';
import { useDispatch } from 'react-redux';

const AnomalyDetectionUpload: React.FC<UploadFullscreenProps> = ({ open, onClose }) => {
  const styles = useUploadStyles();
  const { uploadData, uploadStage } = useTypedSelector(state => state.uploadState);
  const { retryableCount } = getUploadStats(uploadData);
  const dispatch = useDispatch();
  const [disableEscapeKeyDown, setDisableEscapeKeyDown] = useState(false);

  const { showConfirmationDialog } = useDialog();
  const onDialogClose = useCallback(() => {
    let confirmMessage: string | undefined;
    if (uploadStage === UploadStage.NotStarted && uploadData.length) {
      confirmMessage = t('There are still images not uploaded');
    } else if (uploadStage === UploadStage.UploadFulfilledWithFailure && retryableCount > 0) {
      confirmMessage = t('There are images failed to upload');
    }
    if (confirmMessage) {
      showConfirmationDialog({
        title: t('Are you sure you want to leave'),
        content: confirmMessage,
        onConfirm: () => {
          onClose();
          dispatch(resetUploadState());
        },
        confirmText: t('Leave'),
        color: 'primary',
      });
    } else {
      onClose();
      dispatch(resetUploadState());
    }
  }, [dispatch, onClose, retryableCount, showConfirmationDialog, uploadData.length, uploadStage]);

  return (
    <FullscreenDialog
      open={open}
      onClose={uploadStage !== UploadStage.UploadInProgress ? onDialogClose : undefined}
      onMinimize={uploadStage === UploadStage.UploadInProgress ? onClose : undefined}
      coverPendoGuide
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {open && (
        <div className={styles.contentContainer}>
          <UploadWrapper
            handleDisableEscapeKeyDown={value => {
              setDisableEscapeKeyDown(value);
            }}
            onClose={onClose}
          >
            <Typography variant="h1" align="center" className={styles.headerText}>
              {t('Upload Images')}
            </Typography>
            <Typography align="center" className={styles.headerText}>
              {t(
                'In anomaly detection project, you can upload two types of images - {{normal}} or {{abnormal}}. Model will learn from normal images and be tuned & tested on abnormal images. We accept JPEG, PNG, BMP and MPO image format.',
                {
                  normal: <strong>{t('Normal')}</strong>,
                  abnormal: <strong>{t('Abnormal')}</strong>,
                },
              )}
            </Typography>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <AnomalyDetectionDropFileZone type={AnomalyDefectionClassName.Normal} />
              </Grid>
              <Grid item xs={6}>
                <AnomalyDetectionDropFileZone type={AnomalyDefectionClassName.Abnormal} />
              </Grid>
            </Grid>
          </UploadWrapper>
        </div>
      )}
    </FullscreenDialog>
  );
};

export default AnomalyDetectionUpload;

import React from 'react';
import { Grid, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import { Button } from '../..';

interface ApiErrorType {
  message: string;
  status?: number;
  statusText?: string;
  body?: { message: string; code: number };
}

export interface ApiResponseLoaderProps<ResponseType> {
  /**
   * Response from api hooks
   */
  response?: ResponseType;
  /**
   * Loading state from api hooks
   */
  loading: boolean;
  /**
   * Error from api hooks
   */
  error?: ApiErrorType | null;
  /**
   * Default height when loading or error
   */
  defaultHeight?: React.CSSProperties['height'];
  /**
   * Default width when loading or error
   */
  defaultWidth?: React.CSSProperties['width'];
  /**
   * Children is functional with param being response non-null asserted
   */
  children: (response: ResponseType) => React.ReactNode;
  /**
   * Optional handler. If provided, a "Retry" button will be rendered below the error message.
   */
  onRetry?(): void;
}

const useStyles = makeStyles(theme => ({
  errorMessage: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  loadingContainer: {
    overflow: 'hidden',
  },
  retryBtn: {
    marginTop: theme.spacing(2),
  },
}));

/**
 * This component is designed to be used together with API hooks from fetchHookFactory.ts.
 * You can use with single api hook or multiple combined.
 * @example
 * const [response, responseLoading, responseError] = useResponseApi(params);
 * return
 *   (<ApiResponseLoader response={response} loading={responseLoading} error={responseError}>
 *      (loadedResponse) => {...}
 *   </ApiResponseLoader>)
 */
const ApiResponseLoader = <ResponseType extends {}>(
  props: ApiResponseLoaderProps<ResponseType>,
): JSX.Element => {
  const {
    response,
    loading,
    error,
    defaultHeight = '100%',
    defaultWidth = '100%',
    children,
    onRetry,
  } = props;
  const styles = useStyles();

  if (typeof response !== 'undefined') {
    return <>{children(response)}</>;
  }

  if (loading || error) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ height: defaultHeight, width: defaultWidth }}
        className={styles.loadingContainer}
      >
        {/* loading state */}
        {loading && <CircularProgress size="26px" data-testid="api-response-loader" />}
        {/* error message */}
        {error && (
          <>
            <Typography variant="h4" component="div" gutterBottom className={styles.errorMessage}>
              {error.message}
              <div>Please contact the administrator</div>
            </Typography>
            {onRetry && (
              <Button
                id="api-error-retry"
                className={styles.retryBtn}
                onClick={onRetry}
                variant="contained"
                color="default"
                size="small"
              >
                {t('Retry')}
              </Button>
            )}
          </>
        )}
      </Grid>
    );
  }

  return <></>;
};

export default ApiResponseLoader;

import React, { useCallback, useState } from 'react';
import { Box, Dialog, makeStyles, Paper } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import calendarIcon from '@/images/calendar.svg';
import listIcon1 from '@/images/org/team.svg';
import listIcon2 from '@/images/org/upload.svg';
import listIcon3 from '@/images/org/label.svg';
import listIcon4 from '@/images/org/model.svg';
import { useHistory } from 'react-router';
import CLEF_PATH from '../../../constants/path';
import { useCurrentSubscription } from '@/hooks/useSubscriptions';
import { useGetUsageSummary } from '@/serverStore/usage';

const useStyles = makeStyles(theme => ({
  dialog: {
    fontSize: 14,
  },
  dialogPaper: {
    width: 520,
  },
  title: {
    marginBottom: theme.spacing(3),
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: 500,
  },
  subTitle: {
    fontSize: 14,
    margin: theme.spacing(10, 0, 3),
  },
  numberFormat: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: 1.25,
    verticalAlign: 'middle',
  },
  secondaryText: {
    color: theme.palette.grey[500],
  },
  upgradeButton: {
    width: 370,
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.blue[600],
  },
  cancelButton: {
    width: 370,
  },
}));

export type FreeTrialExpiredDialogProps = {};

const FreeTrialExpiredDialog: React.FC<FreeTrialExpiredDialogProps> = () => {
  const [open, setOpen] = useState(true);
  const styles = useStyles();
  const users = useTypedSelector(state => state.user.users).length;

  const subscription = useCurrentSubscription();
  const { data: usageSummary } = useGetUsageSummary(subscription ? true : undefined);

  const history = useHistory();

  const orgId = useTypedSelector(state => state.login.user)?.orgId!;
  const skipUpgradeOrgIds: number[] = JSON.parse(localStorage.getItem('skipUpgradeOrgIds') ?? '[]');
  const dontShowAgain = useCallback(() => {
    localStorage.setItem('skipUpgradeOrgIds', JSON.stringify(skipUpgradeOrgIds.concat(orgId)));
  }, [orgId, skipUpgradeOrgIds]);

  if (!usageSummary) {
    return null;
  }

  const { uploadedImages, labeledImages, trainedModels } = usageSummary;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className={styles.dialog}
      classes={{ paper: styles.dialogPaper }}
    >
      <Paper>
        <Box paddingY={8} paddingX={30} display="flex" flexDirection="column" alignItems="center">
          <Box marginBottom={5}>
            <img src={calendarIcon} />
          </Box>
          <Box className={styles.title}>{t('Your Free Trial Has Ended')}</Box>
          <Box marginBottom={7} whiteSpace="nowrap">
            {t("Here's what your organization accomplished in just 30 days!")}
          </Box>
          <Box minWidth={350}>
            <Box display="flex" alignItems="center" marginBottom={8}>
              <Box marginRight={8}>
                <img src={listIcon1} />
              </Box>
              <Box>
                <Box display="flex" alignItems="center">
                  {users > 1
                    ? t('{{users}} users have joined the team', {
                        users: <span className={styles.numberFormat}>{users}</span>,
                      })
                    : t('{{users}} user has joined the team', {
                        users: <span className={styles.numberFormat}>{users}</span>,
                      })}
                </Box>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" marginBottom={8}>
              <Box marginRight={8}>
                <img src={listIcon2} />
              </Box>
              <Box>
                <Box display="flex" alignItems="center">
                  {uploadedImages > 1
                    ? t('{{images}} images have been uploaded', {
                        images: <span className={styles.numberFormat}>{uploadedImages}</span>,
                      })
                    : t('{{images}} image has been uploaded', {
                        images: <span className={styles.numberFormat}>{uploadedImages}</span>,
                      })}
                </Box>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" marginBottom={8}>
              <Box marginRight={8}>
                <img src={listIcon3} />
              </Box>
              <Box>
                <Box display="flex" alignItems="center">
                  {labeledImages ?? 0 > 1
                    ? t('{{images}} images have been labeled', {
                        images: <span className={styles.numberFormat}>{labeledImages}</span>,
                      })
                    : t('{{images}} image has been labeled', {
                        images: <span className={styles.numberFormat}>{labeledImages}</span>,
                      })}
                </Box>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" marginBottom={8}>
              <Box marginRight={8}>
                <img src={listIcon4} />
              </Box>
              <Box>
                <Box display="flex" alignItems="center">
                  {trainedModels > 1
                    ? t('{{models}} models have been trained', {
                        models: <span className={styles.numberFormat}>{trainedModels}</span>,
                      })
                    : t('{{models}} model has been trained', {
                        models: <span className={styles.numberFormat}>{trainedModels}</span>,
                      })}
                </Box>
              </Box>
            </Box>
          </Box>
          <Button
            id="upgrade"
            color="primary"
            variant="contained"
            className={styles.upgradeButton}
            onClick={() => {
              history.push(CLEF_PATH.plan);
              setOpen(false);
              dontShowAgain();
            }}
          >
            {t('Upgrade')}
          </Button>
          <Button
            id="close-dialog"
            color="primary"
            onClick={() => {
              setOpen(false);
              dontShowAgain();
            }}
            className={styles.cancelButton}
          >
            {t('Not Now')}
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default FreeTrialExpiredDialog;

import React, { useCallback, useState } from 'react';
import { Box, CircularProgress, Dialog, Paper, TextField } from '@material-ui/core';
import { Button, Typography } from '@clef/client-library';
import { accountApiWithAuth } from '@/api/account_api';
import { ApiKey } from '@clef/shared/types';
import { useSnackbar } from 'notistack';
import { refreshApiKeyListApi } from '@/hooks/api/useOrganizationApi';

export type EditKeyNameDialogProps = {
  onClose?: () => void;
  apiKey: Pick<ApiKey, 'id' | 'name'>;
};

const EditKeyNameDialog: React.FC<EditKeyNameDialogProps> = ({ onClose, apiKey }) => {
  const [newKeyName, setNewKeyName] = useState(apiKey.name ?? '');
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const saveName = useCallback(async () => {
    if (!newKeyName || loading) {
      return;
    }
    setLoading(true);
    try {
      await accountApiWithAuth.updateApiKey({ ...apiKey, name: newKeyName });
      refreshApiKeyListApi({ keys: 'refresh-all', swr: true });
      onClose?.();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
    }
    setLoading(false);
  }, [apiKey, enqueueSnackbar, loading, newKeyName, onClose]);

  return (
    <Dialog open>
      <Paper>
        <Box padding={6}>
          <Typography variant="h3_semibold">{t('Edit Key Name')}</Typography>
          <Box marginTop={5} minWidth={300}>
            <TextField
              variant="outlined"
              fullWidth
              value={newKeyName}
              onChange={e => setNewKeyName(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && saveName()}
            />
          </Box>
          <Box marginTop={5} display="flex" justifyContent="flex-end">
            <Button
              id="save-key-name"
              color="primary"
              variant="contained"
              disabled={loading || !newKeyName}
              startIcon={loading ? <CircularProgress size={16} /> : undefined}
              onClick={saveName}
            >
              {t('Save')}
            </Button>
            <Box paddingLeft={3} />
            <Button
              id="cancel-edit-key-name"
              onClick={onClose}
              disabled={loading}
              variant="outlined"
            >
              {t('Cancel')}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default EditKeyNameDialog;

import { BaseAPI } from './base_api';
import { UserId, UserRole, ProjectId, ProjectRole } from '@clef/shared/types';

class UserAPI extends BaseAPI {
  async fetchUsers(userIds?: UserId[], projectId?: ProjectId) {
    // return all users by default.
    const filters: Record<string, any> = {};
    if (userIds) {
      filters['userIds'] = userIds.join(',');
    }
    if (projectId) {
      filters['projectId'] = projectId;
    }
    return this.get('get_users', filters, true);
  }

  async inviteUser(
    email: string,
    userRole: UserRole,
    projectId?: ProjectId,
    projectRole?: ProjectRole,
  ) {
    const res = await this.postJSON('invite_user', {
      email,
      userRole,
      projectId,
      projectRole,
    });
    return res.data;
  }

  async activateUser(userId: UserId) {
    const res = await this.postJSON('activate', {
      userId,
    });
    return res.data;
  }

  async deactivateUser(userId: UserId) {
    const res = await this.postJSON('deactivate', {
      userId,
    });
    return res.data;
  }

  async updateUserRole(userId: UserId, userRole: UserRole) {
    const res = await this.postJSON('update_user_role', {
      userId,
      userRole,
    });
    return res.data;
  }

  async transferOrganizationOwnership(userId: UserId) {
    const res = await this.postJSON('transfer_user_org_ownership', {
      userId,
    });
    return res.data;
  }

  async createApiKeyAndSecret() {
    const response = await this.get('create_api_key');
    return response.data;
  }

  async getApiKey() {
    const response = await this.get('get_api_key');
    return response.data;
  }

  async removePendingUser(email: string) {
    await this.postJSON('remove_pending_user', {
      email: email,
    });
  }
}

export default new UserAPI('account');

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import Draggable from 'react-draggable';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { showLiveWebinarAtom } from '@/uiStates/liveWebinar';
import { useAtom, useSetAtom } from 'jotai';
import cx from 'classnames';
import { useStreamyardId } from '@/hooks/useStreamyardId';

type LiveWebinarStyleProps = {
  width: number;
  height: number;
};
const useStyles = (props: LiveWebinarStyleProps) =>
  makeStyles(theme => {
    const IFRAME_BORDER_RADIUS = theme.spacing(2);

    return {
      liveWebinarContainer: {
        zIndex: 9999999,
        position: 'fixed',
        right: theme.spacing(8),
        bottom: theme.spacing(8),
        boxShadow: `${theme.boxShadow.strong} !important`,
        borderRadius: IFRAME_BORDER_RADIUS,
      },
      iframeWrapper: {
        width: props.width,
        height: props.height,
        background: 'transparent',
      },
      draggingHandleContainer: {
        width: '100%',
        height: theme.spacing(8),
        borderTopLeftRadius: IFRAME_BORDER_RADIUS,
        borderTopRightRadius: IFRAME_BORDER_RADIUS,
      },
      draggingHandle: {
        width: '100%',
        height: '100%',
        background: theme.palette.greyModern[200],
        cursor: 'grab',
        '&:active': {
          cursor: 'grabbing',
          background: theme.palette.greyModern[300],
        },
        borderTopLeftRadius: IFRAME_BORDER_RADIUS,
        borderTopRightRadius: IFRAME_BORDER_RADIUS,
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
      },
      iframe: {
        width: '100%',
        border: 0,
        overflow: 'hidden',
        background: theme.palette.common.white,
        borderBottomLeftRadius: IFRAME_BORDER_RADIUS,
        borderBottomRightRadius: IFRAME_BORDER_RADIUS,
      },
      dragging: { pointerEvents: 'none' },
      closeIcon: {
        color: theme.palette.greyModern[400],
      },
      resizableHandle: {
        zIndex: 99999999,
        borderStyle: 'solid',
        borderWidth: theme.spacing(2.5),
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderBottomColor: theme.palette.greyModern[200],
        borderRightColor: theme.palette.greyModern[200],
        borderBottomRightRadius: IFRAME_BORDER_RADIUS,
        '&:hover': {
          cursor: 'nwse-resize',
          borderBottomColor: theme.palette.greyModern[400],
          borderRightColor: theme.palette.greyModern[400],
        },
        '&:active': {
          cursor: 'nwse-resize',
          borderBottomColor: theme.palette.greyModern[500],
          borderRightColor: theme.palette.greyModern[500],
        },
        width: 0,
        height: 0,
        position: 'absolute',
        bottom: 0,
        right: 0,
      },
    };
  });

const LiveWebinar = () => {
  const DEFAULT_IFRAME_WIDTH = 640;
  const DEFAULT_IFRAME_HEIGHT = (DEFAULT_IFRAME_WIDTH / 16) * 9;

  const [showLiveWebinar] = useAtom(showLiveWebinarAtom);
  const setShowLiveWebinar = useSetAtom(showLiveWebinarAtom);
  const [height, setHeight] = useState(DEFAULT_IFRAME_HEIGHT);
  const [width, setWidth] = useState(DEFAULT_IFRAME_WIDTH);
  const classes = useStyles({ width, height })();
  const [isDragging, setIsDragging] = useState(false);
  const eventIdEmbeded = useStreamyardId();

  const handleClose = () => setShowLiveWebinar(false);

  const onResize = (_event: React.SyntheticEvent, { size }: ResizeCallbackData) => {
    setWidth(size.width);
    setHeight(size.height);
  };

  if (!showLiveWebinar) return null;

  if (!eventIdEmbeded) return null;

  return (
    <Draggable onStart={() => setIsDragging(true)} onStop={() => setIsDragging(false)}>
      <Resizable
        handle={<div className={classes.resizableHandle} />}
        height={height}
        width={width}
        onResize={onResize}
      >
        <Box className={classes.liveWebinarContainer} display="flex" flexDirection="column">
          <Box
            className={classes.draggingHandleContainer}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Box className={classes.draggingHandle}></Box>
            <IconButton className={classes.closeButton} onClick={handleClose} size="small">
              <CloseOutlinedIcon className={classes.closeIcon} fontSize="small" />
            </IconButton>
          </Box>
          <Box className={classes.iframeWrapper}>
            <iframe
              width="100%"
              height="100%"
              src={`https://streamyard.com/watch/${eventIdEmbeded}?embed=true`}
              allow="autoplay; fullscreen"
              className={cx(classes.iframe, isDragging ? classes.dragging : null)}
            ></iframe>
          </Box>
        </Box>
      </Resizable>
    </Draggable>
  );
};

export default LiveWebinar;

import React from 'react';
import { TaskPurpose, ProjectRole } from '@clef/shared/types';
import { useHistory } from 'react-router';
import { Grid, Typography } from '@material-ui/core';
import { TabManager, Button } from '@clef/client-library';
import LabelingTasks from '../../components/LabelingTasks/LabelingTasks';
import LabelingManagement from '../../pages/LabelingManagement';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { ApiResponseLoader } from '@clef/client-library';
import { getTaskCount } from '../../utils/task_utils';
import { useProjectRolePermissions } from '../../hooks/useProjectRolePermissions';
import { useAllTasks } from '../../hooks/api/useTaskApi';
import TaskPageEmptyStateSvg from '../../images/empty-state/task-page.svg';
import CLEF_PATH from '../../constants/path';
import { useStyles } from './styles';
import { useGetLayoutListQuery } from '@/serverStore/layout';

const MyTasksPage: React.FC<{}> = () => {
  const styles = useStyles();
  const history = useHistory();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { projectRole } = useProjectRolePermissions();
  const isProjectLabeler = projectRole === ProjectRole.Labeler;
  const {
    data: layoutInfoData,
    isLoading: layoutInfoLoading,
    error: layoutInfoError,
  } = useGetLayoutListQuery();
  const [allTasksData, allTasksLoading, allTasksError] = useAllTasks(selectedProject?.id);

  if (!selectedProject) {
    return null;
  }

  return (
    <ApiResponseLoader
      response={
        layoutInfoData && allTasksData
          ? {
              layoutInfoData,
              allTasksData,
            }
          : undefined
      }
      loading={layoutInfoLoading || allTasksLoading}
      error={layoutInfoError || allTasksError}
      defaultHeight="50vh"
    >
      {({ layoutInfoData: layoutInfoLoaded, allTasksData: allTasksLoaded }) => {
        const { tasksCount } = layoutInfoLoaded!;
        const tasksCountPerPurpose = tasksCount[selectedProject.id] ?? {};

        if (!allTasksLoaded.data.length) {
          return (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              className={styles.emptyStateContainer}
              direction="column"
            >
              <img src={TaskPageEmptyStateSvg} className={styles.emptyStateImage} />
              <Typography variant="h3" className={styles.emptyStateText}>
                {t('Review Labeling Tasks')}
              </Typography>
              <Typography variant="body1" className={styles.emptyStateText}>
                {t('Create labeling tasks on the Data page, then review those tasks here.')}
              </Typography>
              <Grid container alignItems="center" justifyContent="center">
                <Button
                  id="empty-state-task-page-back-to-data-button"
                  variant="contained"
                  color="primary"
                  className={styles.emptyStateDownloadSampleDataButton}
                  onClick={() => {
                    history.push(CLEF_PATH.data.dataBrowser);
                  }}
                >
                  {t('Back to Build')}
                </Button>
              </Grid>
            </Grid>
          );
        }

        return (
          <TabManager
            tabs={[
              ...(!isProjectLabeler
                ? [
                    {
                      key: 'reviewing',
                      title: t('Reviewing'),
                      component: <LabelingManagement />,
                    },
                  ]
                : []),
              {
                key: 'labeling',
                title: t('Labeling'),
                component: <LabelingTasks taskType={TaskPurpose.Training} />,
                badgeNumber: getTaskCount(tasksCountPerPurpose),
              },
            ]}
            defaultTab={isProjectLabeler ? 'labeling' : 'reviewing'}
          />
        );
      }}
    </ApiResponseLoader>
  );
};

export default MyTasksPage;

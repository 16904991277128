import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  mediaCountText: {
    whiteSpace: 'nowrap',
    '& strong': {
      fontSize: '1rem',
      marginRight: theme.spacing(1),
    },
  },
  textButton: {
    marginLeft: theme.spacing(2),
  },
  defectColor: {
    width: 16,
    height: 16,
    borderRadius: 4,
    marginRight: theme.spacing(3),
  },
  snapshotBtnWrapper: {
    position: 'relative',
  },
  moreSubButton: {
    width: '180px',
    margin: '0 10px',
    height: 39,
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
      fontWeight: 400,
    },
  },
  setMetadataBtn: {
    color: theme.palette.primary.main,
  },
  container: {
    display: 'flex',
    padding: theme.spacing(0, 5),
    transition: 'all 0.15s',
    height: 64,
    minWidth: 500,
    borderRadius: 8,
    background: theme.palette.background.default,
    boxSizing: 'border-box',
    boxShadow: '0px 8px 24px rgba(12, 100, 205, 0.1)',
  },
  setMetadataContainer: {
    width: '100%',
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.26)',
    cursor: 'not-allowed',
  },
  optionsBtnBox: {
    position: 'relative',
    overflow: 'visible !important',
  },
  mediaOptionsPopover: {
    position: 'absolute !important' as any,
    zIndex: `${theme.zIndex.drawer - 1} !important` as any,
    '& .MuiPopover-paper': {
      top: 'initial !important',
      bottom: '40px !important',
      left: '50% !important',
      width: '200px',
      maxWidth: '200px',
      maxHeight: 'fit-content',
      marginLeft: '-100px',
      zIndex: theme.zIndex.drawer - 1,
      overflow: 'unset',
    },
  },
}));

export default useStyles;

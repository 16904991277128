import MetadataAPI from '../../api/metadata_api';
import MetadataObjectAPI from '../../api/metadata_object_api';
import fetchHookFactory from './fetchHookFactory';
import {
  DatasetContent,
  MetadataFormattedValue,
  MetadataType,
  Project,
  ProjectWithUsers,
  SelectMediaOption,
} from '@clef/shared/types';
import { queryClient } from '@/serverStore';
import { datasetQueryKeys } from '@/serverStore/dataset';

export const [useMediaMetadataApi, refreshMediaMetadata] = fetchHookFactory(
  (params: { projectId: number; mediaId: number }) => {
    return MetadataObjectAPI.getMetadataOfObject(params.projectId, params.mediaId, 'media');
  },
  'cache',
);

export const [useMetadataApi, refreshMetadataApi] = fetchHookFactory(async (projectId: number) => {
  return MetadataAPI.getMetadataByProjectId(projectId);
}, 'cache');

export const attachMetadataToMediasApi = async (
  project: Project,
  selectedOptions: SelectMediaOption,
  metadataValues: MetadataFormattedValue,
  modelId: string,
) => {
  const { data: attachedMedias }: { data: DatasetContent[] } =
    await MetadataObjectAPI.attachMetadataToMedias(project, selectedOptions, metadataValues);
  await refreshMediaMetadata({ keys: 'refresh-all', swr: true });
  await queryClient.invalidateQueries(datasetQueryKeys.allWithFilters(project.id));
  const attachedMediaIds = attachedMedias.map(media => media.mediaId);
  for (const mediaId of attachedMediaIds) {
    const params = {
      datasetId: project.datasetId,
      mediaId: mediaId,
      modelId,
    };
    await queryClient.invalidateQueries(
      datasetQueryKeys.mediaDetails(params.datasetId ?? 0, params),
    );
  }
};

export const createMetadataApi = async (
  projectId: number,
  name: string,
  type: MetadataType,
  allowMultiple: boolean,
  valueFlexible: boolean,
  predefinedChoices?: string[] | null,
) => {
  const response = await MetadataAPI.addField({
    projectId,
    name,
    type,
    allowMultiple,
    valueFlexible,
    predefinedChoices,
  });
  await refreshMetadataApi({ keys: 'refresh-all', swr: true });
  return response;
};

export const attachMetadataToSingleMedia = async (
  mediaId: number,
  metadataValues: MetadataFormattedValue,
  type: 'append' | 'overwrite' = 'append',
  project: ProjectWithUsers,
  modelId?: string,
) => {
  let finalMetadataValues: MetadataFormattedValue;
  if (type === 'append') {
    const prevValues = await MetadataObjectAPI.getMetadataOfObject(project.id, mediaId, 'media');
    finalMetadataValues = { ...prevValues, ...metadataValues };
  } else {
    finalMetadataValues = metadataValues;
  }
  await MetadataObjectAPI.attachMetadataToMedia(project.id, mediaId, finalMetadataValues);
  await refreshMediaMetadata({ keys: { projectId: project.id, mediaId } });
  const params = {
    datasetId: project.datasetId,
    mediaId: mediaId,
    modelId: modelId,
  };
  await queryClient.refetchQueries(datasetQueryKeys.mediaDetails(params.datasetId ?? 0, params));

  project && (await queryClient.invalidateQueries(datasetQueryKeys.allWithFilters(project.id)));
};

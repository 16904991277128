import React, { useMemo, useState } from 'react';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import ClassificationSvg from '@/images/project/classification.svg';
import ObjectDetectionSvg from '@/images/project/object_detection.svg';
import SegmentationSvg from '@/images/project/segmentation.svg';
import SegmentationInstantLearningSvg from '@/images/project/segmentation_instant_learning.svg';
import VideoCamSvg from '@/images/project/videocam.svg';
import { LabelType } from '@clef/shared/types';
import { Typography } from '@clef/client-library';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import classNames from 'classnames';
import { useIsFeatureEnabledAndMayHideForSnowflake } from '../../../hooks/useFeatureGate';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Brightness1OutlinedIcon from '@material-ui/icons/Brightness1Outlined';
import { pendoEntity } from '@/utils/pendo';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import ChooseTypeGuide from './ChooseTypeGuide';

const useStyles = makeStyles(theme => ({
  cardIcon: {
    width: 40,
    height: 40,
    verticalAlign: 'middle',
  },
  labelTypeCard: {
    outline: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 12,
    backgroundColor: 'white',
    cursor: 'pointer',
    transition: '0.2s',
    color: theme.palette.grey[800],
    padding: '16px 0',
    '& + $labelTypeCard': {
      marginLeft: theme.spacing(4),
    },
    '&:hover': {
      outline: `2px solid ${theme.palette.grey[300]}`,
      backgroundColor: '#F5FAFF',
    },
    fontSize: 14,
    flex: 1,
  },
  selected: {
    outline: `2px solid ${theme.palette.blue[500]}`,
    backgroundColor: '#F5FAFF',
    '&:hover': {
      outline: `2px solid ${theme.palette.blue[500]}`,
    },
  },
  check: {
    flexShrink: 0,
  },
  unCheck: {
    color: theme.palette.grey[300],
  },
  beta: {
    backgroundColor: theme.palette.green[400],
    color: theme.palette.common.white,
    fontSize: 12,
    padding: '2px 6px',
    borderRadius: 5,
    fontWeight: 700,
  },
  cardTitle: {
    color: theme.palette.greyModern[900],
  },
  cardContent: {
    color: theme.palette.greyModern[800],
  },
  howToChoose: {
    color: theme.palette.blue[600],
    cursor: 'pointer',
  },
}));

const LabelTypeCard: React.FC<{
  labelType: LabelType;
  icon: string;
  content: string;
  tooltip?: string;
  selected?: boolean;
  title: string;
  onClick?: () => Promise<void>;
}> = props => {
  const styles = useStyles();
  const { icon, content, tooltip = '', selected, onClick, title, labelType } = props;

  const isShowBeta = useMemo(() => {
    return labelType === LabelType.SegmentationInstantLearning;
  }, [labelType]);

  return (
    <Tooltip title={tooltip} arrow placement="top">
      <Box
        display="flex"
        alignItems="center"
        className={classNames(styles.labelTypeCard, selected && styles.selected)}
        onClick={onClick}
        data-testid={`label-type-card-${labelType}`}
        aria-selected={selected}
        role="button"
      >
        <Box flexShrink={0} padding={4}>
          <img className={styles.cardIcon} src={icon} />
        </Box>
        <Box>
          <Box display="flex" alignItems="center">
            {title && (
              <Typography variant="body_bold" className={styles.cardTitle}>
                {title}
              </Typography>
            )}
            {isShowBeta && (
              <Box className={styles.beta} marginLeft={1}>
                {t('Beta')}
              </Box>
            )}
          </Box>
          <Typography variant="body1" className={styles.cardContent}>
            {content}
          </Typography>
        </Box>
        <Box
          className={classNames(styles.check, {
            [styles.unCheck]: !selected,
          })}
          paddingX={4}
        >
          {selected ? <CheckCircleRoundedIcon color="primary" /> : <Brightness1OutlinedIcon />}
        </Box>
      </Box>
    </Tooltip>
  );
};

const labelTypes = [
  {
    key: LabelType.BoundingBox,
    icon: ObjectDetectionSvg,
    title: t('Object Detection'),
    contentMd: t('Label with bounding boxes. Use to identify one or more objects in an image.'),
    tooltip: t(
      'Commonly used for tracking, counting, understanding locations of objects, or identifying objects and object types.',
    ),
  },
  {
    key: LabelType.Segmentation,
    icon: SegmentationSvg,
    title: t('Segmentation'),
    contentMd: t(
      'Label with precision tools. Use when pixel-level precision is required. Output from the model is a mask of the pixels.',
    ),
    tooltip: t('Commonly used for irregular shapes.'),
  },
  {
    key: LabelType.Classification,
    icon: ClassificationSvg,
    title: t('Classification'),
    contentMd: t(
      'Each image itself is a label. Use to classify the contents of an image as a whole to distinguish.',
    ),
    tooltip: t(
      'Commonly used to identify Good vs Not Good or Defective vs Good. Imaging is typically more consistent and localized as the whole image is used as input for training the model.',
    ),
  },
];

const instantLearningOption = {
  key: LabelType.SegmentationInstantLearning,
  icon: SegmentationInstantLearningSvg,
  title: t('Visual Prompting'),
  contentMd: t(
    'Label a few small areas with a brush for the model to almost immediately detect the whole object in all images.',
  ),
  tooltip: t('Commonly used for irregular shapes with many textures.'),
};

export type LabelTypeSelectorProps = {
  changeLabelType?: (newLabelType: LabelType) => Promise<void>;
};

const LabelTypeSelector: React.FC<LabelTypeSelectorProps> = ({ changeLabelType }) => {
  const styles = useStyles();
  const { labelType } = useGetSelectedProjectQuery().data ?? {};
  const isInstantLearningEnabled = useIsFeatureEnabledAndMayHideForSnowflake().visualPrompting;
  const currentUser = useTypedSelector(state => state.login.user);

  const finalLabelTypes = [...labelTypes];
  if (isInstantLearningEnabled) {
    finalLabelTypes.push(instantLearningOption);
  }

  const [openChooseTypeGuide, setOpenChooseTypeGuide] = useState(false);
  const handleChooseGuide = (e: React.MouseEvent) => {
    e.stopPropagation();
    pendoEntity?.track('project_type_guide_open', {
      userName: currentUser ? `${currentUser.name} ${currentUser.lastName}` : undefined,
      email: currentUser ? currentUser.email : undefined,
      orgName: currentUser ? currentUser.company : undefined,
      orgId: currentUser ? currentUser.orgId : undefined,
    });
    setOpenChooseTypeGuide(true);
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="body_bold">{t('Project types')}</Typography>
        <Box
          display="flex"
          alignItems="center"
          ml={2}
          style={{ gap: 2 }}
          onClick={handleChooseGuide}
        >
          <Typography variant="body_medium" className={styles.howToChoose}>
            {t('How to choose')}
          </Typography>
          <img src={VideoCamSvg} />
        </Box>
      </Box>
      <Box
        display="flex"
        mt={4}
        flexWrap="nowrap"
        data-testid="label-type-selector"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        {finalLabelTypes.map(({ key, icon, contentMd, title, tooltip }) => (
          <LabelTypeCard
            key={key}
            labelType={key}
            icon={icon}
            content={contentMd}
            tooltip={tooltip}
            title={title}
            selected={labelType === key}
            onClick={async () => changeLabelType?.(key)}
          />
        ))}
      </Box>

      {openChooseTypeGuide && <ChooseTypeGuide onClose={() => setOpenChooseTypeGuide(false)} />}
    </>
  );
};

export default LabelTypeSelector;

import React, { useState, useCallback } from 'react';
import Konva from 'konva';
import { Line } from 'react-konva';
import { LineAnnotation } from '@clef/shared/types';
import { LineAnnotationComponent } from '../components/Shapes';
import throttle from 'lodash/throttle';
import { calcAccurateOffset } from '../utils';
import { AnnotationCreatorBuilder } from '../types';
import { infoBlue } from '../../../themes/colors';

type PolylineAnnotationDrawHook = (options: {
  color?: string;
  strokeWidth: number;
  opacity: number;
  onFinishAnnotation: (line: LineAnnotation) => void;
  onMissingColor?: () => void;
  onIsWorkingInProgressChanged?: (wip: boolean) => void;
}) => AnnotationCreatorBuilder;

export const usePolylineAnnotationCreator: PolylineAnnotationDrawHook = ({
  color,
  strokeWidth,
  opacity,
  onFinishAnnotation,
  onMissingColor,
  onIsWorkingInProgressChanged,
}) => {
  const [wipLineAnnotation, setWipLineAnnotation] = useState<LineAnnotation>();
  const [previewPoints, setPreviewPoints] = useState<[number, number]>();

  const onMouseMove = useCallback(
    throttle((e: Konva.KonvaEventObject<MouseEvent>) => {
      if (wipLineAnnotation) {
        const cPos = calcAccurateOffset(e, 'pixel-center');
        setPreviewPoints([cPos.x, cPos.y]);
      }
    }, 48),
    [wipLineAnnotation],
  );

  const onDiscard = useCallback(() => {
    setWipLineAnnotation(undefined);
    setPreviewPoints(undefined);
    onIsWorkingInProgressChanged?.(false);
  }, [onIsWorkingInProgressChanged]);

  const onFinish = useCallback(() => {
    if (wipLineAnnotation) {
      onFinishAnnotation(wipLineAnnotation);
      onDiscard();
    }
  }, [onDiscard, onFinishAnnotation, wipLineAnnotation]);

  const onRevert = useCallback(() => {
    if (wipLineAnnotation) {
      // remove the last 2 points
      const newPoints = wipLineAnnotation.points.slice(0, -2);
      // the last 2 points is duplicated
      if (newPoints.length > 2) {
        setWipLineAnnotation({ ...wipLineAnnotation, points: newPoints });
      } else {
        setWipLineAnnotation(undefined);
        setPreviewPoints(undefined);

        onIsWorkingInProgressChanged?.(false);
      }
    }
  }, [onIsWorkingInProgressChanged, wipLineAnnotation]);

  const onMouseDown = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (e.evt.button === 2) {
        // right click
        onRevert();
        return;
      }
      if (!color) {
        onMissingColor?.();
        return;
      }
      const cPos = calcAccurateOffset(e);
      if (wipLineAnnotation) {
        // already have existing points
        setWipLineAnnotation({
          ...wipLineAnnotation,
          points: [...wipLineAnnotation.points, cPos.x, cPos.y],
        });
      } else {
        // just started with first point
        setWipLineAnnotation({
          color: color,
          version: 1,
          strokeWidth,
          opacity,
          // duplicate first point to show the initial dot
          points: [cPos.x, cPos.y, cPos.x, cPos.y],
        });
      }

      onIsWorkingInProgressChanged?.(true);
    },
    [
      color,
      onIsWorkingInProgressChanged,
      onMissingColor,
      onRevert,
      opacity,
      strokeWidth,
      wipLineAnnotation,
    ],
  );

  const previewColor = infoBlue.main;
  return {
    creatorPreviewComponent:
      wipLineAnnotation && wipLineAnnotation.points.length ? (
        <LineAnnotationComponent annotation={wipLineAnnotation} />
      ) : null,
    creatorHelperComponent:
      wipLineAnnotation && wipLineAnnotation.points.length ? (
        <>
          <Line
            offset={{ x: -0.5, y: -0.5 }}
            points={wipLineAnnotation.points}
            stroke={previewColor}
            strokeWidth={2}
            strokeScaleEnabled={false}
          />
          <Line
            dash={[8, 4]}
            points={[
              // the last 2 points
              ...wipLineAnnotation.points.slice(-2).map(v => Math.floor(v) + 0.5),
              ...(previewPoints ?? []),
            ]}
            stroke={previewColor}
            strokeWidth={1}
            strokeScaleEnabled={false}
          />
        </>
      ) : null,
    creatorInteractiveProps: {
      onMouseDown,
      onMouseMove,
    },
    creatorCustomHandlers: {
      onEscPressed: onDiscard,
      onEnterPressed: onFinish,
      onOutsideClick: onFinish,
      onModeChanged: onFinish,
      isWorkInProgress: () => !!wipLineAnnotation && wipLineAnnotation?.points.length > 0,
      onUndo: onRevert,
    },
  };
};

import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Usage example 1:
 * ```
 * const { countDown, startCountDown } = useCountDown();
 * const handleEvent = () => {
 *   startCountDown(60);
 * }
 * return <div>{countDown}</div>
 *```
 *
 * Usage example 2:
 * ```
 * const { state, dispatch } = useSomeContext();
 * const { countDown } = state;
 * const { startCountDown } = useCountDown();
 * useCountDown(newCount => {
 *   dispatch(draft => {
 *     draft.countDown = newCount;
 *   })
 * });
 * const handleEvent = () => {
 *   startCountDown(60);
 * }
 * return <div>{countDown}</div>
 * ```
 */
export const useCountDown = (onCountDown?: (count: number, durationMs?: number) => void) => {
  const [countDown, setCountDown] = useState(0);

  const onCountDownRef = useRef(onCountDown);
  onCountDownRef.current = onCountDown;
  useEffect(() => {
    onCountDownRef.current?.(countDown);
  }, [countDown]);

  const countDownTimerId = useRef<ReturnType<typeof setInterval>>();

  const startCountDown = useCallback((initCount: number, durationMs = 1000) => {
    setCountDown(initCount);
    countDownTimerId.current = setInterval(() => {
      setCountDown(prev => {
        if (prev === 0 && countDownTimerId.current) {
          clearInterval(countDownTimerId.current);
          countDownTimerId.current = undefined;
          return prev;
        }
        const newCount = Math.max(0, prev - 1);
        return newCount;
      });
    }, durationMs);
  }, []);

  // clear timer on destruct
  useEffect(() => {
    return () => {
      if (countDownTimerId.current) {
        clearInterval(countDownTimerId.current);
        countDownTimerId.current = undefined;
      }
    };
  }, []);

  return { countDown, startCountDown };
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, LinearProgress, Typography } from '@material-ui/core';

import { PulsingBadge, PulsingBar } from './components/PulsingBadge';
import { GoButton } from './components/GoButton';
import { useStyles } from './styles';
import { useSnackbar } from 'notistack';
import { useWorkflowAssistantState, useSteps } from './state';
import CLEF_PATH from '../../constants/path';
import {
  isOnDataBrowserPage,
  isOnMediaDetailsDialog,
  isModelPerformancePanelOpened,
} from '../../utils/url_utils';
import { NextStepBox } from './components/NextStepBox';
import AnimatedBox from './components/AnimatedBox';
import CheckSvg from '@/images/workflow_assistant/check.svg';
import { CongratsDialog } from './components/CongratsDialog';
import { StepName } from '@/types/client';
import CustomTooltip from './components/CustomTooltip';
import { useStateSyncSearchParams } from '@clef/client-library';

const TOTAL_WIDTH = 700;
const TOTAL_HEIGHT = 60;
const LINEAR_PROGRESS_WIDTH = 100;
const AUTO_HOVERING_DURATION = 6000;

interface WorkflowAssistantInternalProps {
  step: { stepName: StepName; stepStarted: boolean; stepPointsCompleted: number } | null;
  onGoButtonClick?: () => void | Promise<void>;
  onHoverChange?: (hovering: boolean) => void;
  onCompleteStep?: (stepName: string) => void;
  loading?: boolean;
  goButtonDisabled?: boolean;
  conciseVersion: boolean;
}

const WorkflowAssistantInternal: React.FC<WorkflowAssistantInternalProps> = ({
  step,
  onGoButtonClick,
  onHoverChange,
  onCompleteStep,
  loading,
  goButtonDisabled = false,
  conciseVersion,
}) => {
  const styles = useStyles();
  const steps = useSteps();

  const [hover, setHover] = useState(false);
  // in concise version, the go button is only shown when hovering on the point
  const [pointHover, setPointHover] = useState(false);
  const [previousStepIndex, setPreviousStepIndex] = useState<number | null>(null);
  const initialStepIndexRef = useRef<number | null>(null);

  const currentStepIndex = useMemo(
    () =>
      steps.findIndex(({ name }) => {
        return step?.stepName === name;
      }),
    [step?.stepName, steps],
  );

  useEffect(() => {
    if (
      !loading &&
      currentStepIndex !== -1 &&
      (previousStepIndex === null || currentStepIndex > previousStepIndex)
    ) {
      // Since the current step index will be increasing, the completion UI for a step will be rendered only once
      // unless refreshing the page
      if (previousStepIndex !== null) {
        const finishedStep = steps[previousStepIndex];

        onCompleteStep?.(finishedStep.name);
      }

      if (initialStepIndexRef.current === null) {
        initialStepIndexRef.current = currentStepIndex;
      }

      setPreviousStepIndex(currentStepIndex);
    }
  }, [currentStepIndex, loading, onCompleteStep, previousStepIndex, steps]);

  const [showCongratsTooltip, setShowCongratsTooltip] = useState(false);

  const internalOnHoverChange = useCallback(
    (hovering: boolean) => {
      setHover(hovering);
      onHoverChange?.(hovering);
    },
    [onHoverChange],
  );

  const internalOnPointHoverChange = useCallback(
    (hovering: boolean) => {
      setPointHover(hovering);
    },
    [setPointHover],
  );

  const [animationStep, setAnimationStep] = useState(0);
  const intervalRef = useRef<any>();

  useEffect(() => {
    // If current step is complete, and the inital step is not, show the congrats UI animation
    if (currentStepIndex === 4 && initialStepIndexRef.current !== 4) {
      let animationStep = 0;
      intervalRef.current = setInterval(() => {
        animationStep += 1;

        if (animationStep > 14) {
          clearInterval(intervalRef.current);
          return;
        }

        if (animationStep === 5) {
          setShowCongratsTooltip(true);
        }

        setAnimationStep(animationStep);
      }, 1000);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [currentStepIndex]);

  // If congrats UI finishes, the project already went through the workflow assistant, or previous step index or step
  // is empty, render nothing
  if (
    animationStep >= 16 ||
    initialStepIndexRef.current === 4 ||
    previousStepIndex === null ||
    step === null
  ) {
    return null;
  }

  return (
    <>
      <Box
        data-testid="workflow-assistant"
        display="flex"
        alignItems="flex-start"
        justifyContent={animationStep <= 1 ? 'flex-start' : 'center'}
        width={conciseVersion ? 'auto' : TOTAL_WIDTH}
        height={TOTAL_HEIGHT}
        paddingY={2}
        onMouseEnter={() => internalOnHoverChange(true)}
        onMouseLeave={() => internalOnHoverChange(false)}
      >
        <NextStepBox
          mounted={9 <= animationStep && animationStep <= 15}
          open={10 <= animationStep && animationStep <= 14}
          onClose={() => {
            setAnimationStep(16);
            clearInterval(intervalRef.current);
            setShowCongratsTooltip(false);
          }}
        />

        <AnimatedBox
          mounted={2 <= animationStep && animationStep <= 8}
          open={3 <= animationStep && animationStep <= 7}
          flexShrink={0}
          alignSelf="center"
          alignItems="center"
          justifyContent="center"
        >
          <img src={CheckSvg} className={styles.checkSvg} />

          <Typography className={styles.stepName}>{t('Built the first model')}</Typography>
        </AnimatedBox>

        <AnimatedBox mounted={animationStep <= 1} open={animationStep === 0} height={46}>
          <>
            {!conciseVersion && (
              <Box display="flex" alignSelf="center" marginRight={4} flexShrink={0}>
                <Typography className={styles.stepName}>{t('Build the first model')}</Typography>
              </Box>
            )}

            {steps
              .filter(step => step.requiredPoints)
              .map(({ name, requiredPoints }, stepIndex) => {
                const showStepLevelBadge = !step.stepStarted;
                const isCurrentStep: boolean = stepIndex === currentStepIndex;

                return (
                  <React.Fragment key={stepIndex}>
                    {isCurrentStep && showStepLevelBadge && (
                      <Box
                        marginRight={1}
                        marginTop={5}
                        alignSelf="center"
                        onMouseEnter={() => internalOnPointHoverChange(true)}
                        onMouseLeave={() => internalOnPointHoverChange(false)}
                      >
                        <CustomTooltip
                          step={step}
                          open={
                            (animationStep === 0 || animationStep >= 16) &&
                            ((!conciseVersion && hover) || (conciseVersion && pointHover))
                          }
                        >
                          <Box>
                            {conciseVersion && (
                              <>
                                {/* if concise version, show when hovering on the point */}
                                {/* if currentStep is Label, don't show go btn because it's already in details page */}
                                {!goButtonDisabled && pointHover && currentStepIndex !== 1 ? (
                                  <Box className={styles.goBtnWrapper}>
                                    <GoButton
                                      className={styles.fixedWidthGoBtn}
                                      data-testid="workflow-assistant-go-button"
                                      onClick={() => {
                                        onGoButtonClick?.();
                                        internalOnHoverChange(false);
                                      }}
                                    />
                                    <Box style={{ width: 20, height: 21 }} />
                                  </Box>
                                ) : (
                                  <PulsingBadge />
                                )}
                              </>
                            )}
                            {!conciseVersion && (
                              <>
                                {!goButtonDisabled && hover ? (
                                  <GoButton
                                    data-testid="workflow-assistant-go-button"
                                    onClick={() => {
                                      onGoButtonClick?.();
                                      internalOnHoverChange(false);
                                    }}
                                  />
                                ) : (
                                  <PulsingBadge />
                                )}
                              </>
                            )}
                          </Box>
                        </CustomTooltip>
                      </Box>
                    )}

                    {(!conciseVersion ||
                      (conciseVersion &&
                        (stepIndex === currentStepIndex ||
                          stepIndex === currentStepIndex - 1))) && (
                      <Box key={name} display="flex" flexDirection="column" marginRight={1.5}>
                        <Typography className={styles.stepName}>{t(name)}</Typography>

                        <Box display="flex" height={16} alignItems="center">
                          {isCurrentStep ? (
                            new Array(requiredPoints).fill(null).map((_, index) =>
                              index === step.stepPointsCompleted && !showStepLevelBadge ? (
                                <Box marginRight={0.5}>
                                  <CustomTooltip
                                    step={step}
                                    open={
                                      (animationStep === 0 || animationStep >= 16) &&
                                      ((!conciseVersion && hover) || (conciseVersion && pointHover))
                                    }
                                  >
                                    <Box
                                      onMouseEnter={() => internalOnPointHoverChange(true)}
                                      onMouseLeave={() => internalOnPointHoverChange(false)}
                                    >
                                      {!goButtonDisabled && hover && !conciseVersion ? (
                                        // if concise, don't show go button here because it's already in details page
                                        <GoButton
                                          data-testid="workflow-assistant-go-button"
                                          onClick={() => {
                                            onGoButtonClick?.();
                                            internalOnHoverChange(false);
                                          }}
                                        />
                                      ) : (
                                        <PulsingBar
                                          barWidth={LINEAR_PROGRESS_WIDTH / requiredPoints}
                                        />
                                      )}
                                    </Box>
                                  </CustomTooltip>
                                </Box>
                              ) : (
                                <LinearProgress
                                  key={`${name}-${index}`}
                                  data-testid={
                                    index < step.stepPointsCompleted
                                      ? 'workflow-assistant-minor-step-completed'
                                      : 'workflow-assistant-minor-step-not-completed'
                                  }
                                  variant="determinate"
                                  color={index < step.stepPointsCompleted ? 'primary' : 'secondary'}
                                  value={100}
                                  style={{ width: LINEAR_PROGRESS_WIDTH / requiredPoints }}
                                  className={styles.linearProgress}
                                />
                              ),
                            )
                          ) : (
                            <LinearProgress
                              key={name}
                              data-testid={
                                stepIndex < currentStepIndex
                                  ? 'workflow-assistant-step-completed'
                                  : 'workflow-assistant-step-not-completed'
                              }
                              variant="determinate"
                              color={stepIndex < currentStepIndex ? 'primary' : 'secondary'}
                              value={100}
                              style={{ width: LINEAR_PROGRESS_WIDTH }}
                              className={styles.linearProgress}
                            />
                          )}
                        </Box>
                      </Box>
                    )}
                  </React.Fragment>
                );
              })}
          </>
        </AnimatedBox>
      </Box>

      <CongratsDialog open={showCongratsTooltip} onClose={() => setShowCongratsTooltip(false)} />
    </>
  );
};

interface WorkflowAssistantProps {
  style?: React.CSSProperties;
  className?: string;
  conciseVersion?: boolean;
}

export const WorkflowAssistant: React.FC<WorkflowAssistantProps> = ({
  style,
  className,
  conciseVersion = false,
}) => {
  const history = useHistory();
  const {
    state: { step, loading, labelingRequiredPoints },
    dispatch,
  } = useWorkflowAssistantState();
  const { enqueueSnackbar } = useSnackbar();
  const [, setDataBrowserRightSidebar] = useStateSyncSearchParams('rightSidebar', '');

  const enableAutoHovering = useCallback(() => {
    dispatch(state => {
      state.autoHovering = true;
    });

    setTimeout(() => {
      dispatch(state => {
        state.autoHovering = false;
      });
    }, AUTO_HOVERING_DURATION);
  }, [dispatch]);

  const goButtonDisabled = useMemo(() => {
    const path = history.location.pathname + history.location.search;

    if (
      step?.stepName === StepName.Upload &&
      isOnDataBrowserPage(path) &&
      !isOnMediaDetailsDialog(path)
    ) {
      return true;
    } else if (step?.stepName === StepName.Label && isOnMediaDetailsDialog(path)) {
      return true;
    } else if (
      step?.stepName === StepName.Train &&
      isOnDataBrowserPage(path) &&
      !isOnMediaDetailsDialog(path)
    ) {
      return true;
    } else if (
      step?.stepName === StepName.Predict &&
      isOnDataBrowserPage(path) &&
      isModelPerformancePanelOpened(path) &&
      !isOnMediaDetailsDialog(path)
    ) {
      return true;
    }

    return false;
  }, [history.location.pathname, history.location.search, step?.stepName]);

  const onGoButtonClick = useCallback(() => {
    if (step?.stepName === StepName.Upload) {
      if (!isOnDataBrowserPage()) {
        history.push(CLEF_PATH.data.dataBrowser);
      }
    } else if (step?.stepName === StepName.Label) {
      if (!isOnDataBrowserPage()) {
        history.push(CLEF_PATH.data.dataBrowser);
      }

      dispatch(state => {
        state.goButtonClicked = true;
      });
    } else if (step?.stepName === StepName.Train) {
      if (!isOnDataBrowserPage()) {
        history.push(CLEF_PATH.data.dataBrowser);
      }

      dispatch(state => {
        state.goButtonClicked = true;
      });
    } else if (step?.stepName === StepName.Predict) {
      if (!isOnDataBrowserPage()) {
        history.push(CLEF_PATH.data.dataBrowser);
      }
      setDataBrowserRightSidebar('model_performance');

      dispatch(state => {
        state.goButtonClicked = true;
      });
    }

    enableAutoHovering();
  }, [dispatch, enableAutoHovering, history, setDataBrowserRightSidebar, step?.stepName]);

  return (
    <div className={className} style={style}>
      <WorkflowAssistantInternal
        step={step}
        loading={loading}
        onHoverChange={hovering =>
          dispatch(state => {
            state.hovering = hovering;
          })
        }
        onGoButtonClick={onGoButtonClick}
        goButtonDisabled={goButtonDisabled}
        onCompleteStep={stepName => {
          if (stepName === StepName.Label) {
            enqueueSnackbar(
              `Well done! You've labeled the first ${labelingRequiredPoints} images of this project.`,
              {
                variant: 'success',
              },
            );
          }
        }}
        conciseVersion={conciseVersion}
      />
    </div>
  );
};

export default WorkflowAssistant;

import public_endpoint_api from '@/api/public_endpoint_api';
import { refreshPublicEndpointApi } from '@/hooks/api/usePublicEndpointApi';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';

export const useChangeEndpointState = () => {
  const projectId = useTypedSelector(state => state.project.selectedProjectId);
  const [changing, setChanging] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const changeEndpointState = useCallback(
    async (enable: boolean) => {
      if (changing || !projectId) {
        return;
      }
      setChanging(true);
      try {
        await public_endpoint_api.changePublicEndpointState(projectId, 'image_upload', enable);
        refreshPublicEndpointApi({ keys: 'refresh-all', swr: true });
      } catch (e) {
        enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
      }
      setChanging(false);
    },
    [enqueueSnackbar, changing, projectId],
  );
  return { changeEndpointState, changing };
};

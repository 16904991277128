// @ts-nocheck

import { EventLogger } from '@clef/client-library';
import { ReactDomOperationError } from './utils/error';

/**
 * window.OffscreenCanvas
 * https://developer.mozilla.org/en-US/docs/Web/API/OffscreenCanvas
 */
if (!window.OffscreenCanvas) {
  // @ts-ignore
  window.OffscreenCanvas = class OffscreenCanvas {
    canvas: any;
    constructor(width: number, height: number) {
      this.canvas = document.createElement('canvas');
      this.canvas.width = width;
      this.canvas.height = height;

      this.canvas.convertToBlob = () => {
        return new Promise(resolve => {
          this.canvas.toBlob(resolve);
        });
      };

      return this.canvas;
    }
  };
}
/**
 * window.requestIdleCallback
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback
 */
window.requestIdleCallback =
  window.requestIdleCallback ||
  function (cb) {
    var start = Date.now();
    return setTimeout(function () {
      cb({
        didTimeout: false,
        timeRemaining: function () {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1);
  };
window.cancelIdleCallback =
  window.cancelIdleCallback ||
  function (id) {
    clearTimeout(id);
  };

// Google translate will mutate DOM structure, causing React to throw error:
// `Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.`
// Ideally it should be fixed by either google translate or React.
// Before that, we workaround with solution https://github.com/facebook/react/issues/11538#issuecomment-417504600
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      EventLogger.error(
        new ReactDomOperationError(
          `Cannot remove a child from a different parent. See https://github.com/facebook/react/issues/11538#issuecomment-417504600`,
        ),
      );
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      EventLogger.error(
        new ReactDomOperationError(
          `Cannot insert before a reference node from a different parent. See https://github.com/facebook/react/issues/11538#issuecomment-417504600`,
        ),
      );
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  };
}

if (!Object.fromEntries) {
  Object.fromEntries = function <T = any>(
    entries: Iterable<readonly [PropertyKey, T]>,
  ): { [k: string]: T } {
    if (!entries || !entries[Symbol.iterator]) {
      throw new Error('Object.fromEntries() requires a single iterable argument');
    }

    const obj: { [k: string]: T } = {};

    for (const [key, value] of entries) {
      obj[String(key)] = value;
    }

    return obj;
  };
}

import React from 'react';

const CHROME_DOWNLOAD_LINK = 'https://www.google.com/chrome/browser/desktop/index.html';

const BrowserTexts = {
  BrowserWarning: {
    beforeLink: t('You are using a non-Chrome browser. Please consider downloading '),
    link: t('Chrome'),
    afterLink: t('for the best LandingLens experience.'),
  },
  VersionWarning: {
    beforeLink: t('You are using an older version of Chrome. Please update to the '),
    link: t('latest version'),
    afterLink: t('for the best experience.'),
  },
};

export const BrowserWarning: React.FC = () => {
  return (
    <div>
      {BrowserTexts.BrowserWarning.beforeLink}
      <a href={CHROME_DOWNLOAD_LINK} target="_blank" style={{ textDecoration: 'underLine' }}>
        <strong>{BrowserTexts.BrowserWarning.link}</strong>
      </a>{' '}
      {BrowserTexts.BrowserWarning.afterLink}
    </div>
  );
};

export const VersionWarning: React.FC = () => {
  return (
    <div>
      {BrowserTexts.VersionWarning.beforeLink}
      <a href={CHROME_DOWNLOAD_LINK} target="_blank" style={{ textDecoration: 'underLine' }}>
        <strong>{BrowserTexts.VersionWarning.link}</strong>
      </a>
      {BrowserTexts.VersionWarning.afterLink}
    </div>
  );
};

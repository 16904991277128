import React from 'react';
import {
  Box,
  Link,
  // Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import classNames from 'classnames';

import { DatasetVersion, ModelStatus } from '@clef/shared/types';
import { Typography, ApiResponseLoader, pageBackground } from '@clef/client-library';

import { useDatasetSnapshotModelsQuery } from '@/serverStore/dataset';
import { getReadableTime } from '@/utils';
import CLEF_PATH from '@/constants/path';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  emptyStateContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: theme.spacing(5),
    alignItems: 'center',
    marginTop: theme.spacing(15),
  },
  tableContainer: {
    borderRadius: '6px',
  },
  tableHeader: {
    background: theme.palette.grey[25],
    '& .MuiTableCell-root': {
      padding: '16px 16px',
      borderBottom: 'none',
      lineHeight: '20px',
    },
  },
  tableRow: {
    '& .MuiTableCell-root': {
      padding: '10px 16px',
      borderBottom: 'none',
    },
  },
  statusChip: {
    display: 'inline-block',
    margin: theme.spacing(0, 4),
    padding: theme.spacing(0, 2),
    fontWeight: 700,
    fontSize: 14,
    borderRadius: 4,
    height: 24,
    lineHeight: '24px',
  },
  inProgress: {
    backgroundColor: pageBackground,
    color: theme.palette.primary.main,
  },
  successful: {
    backgroundColor: theme.palette.green[50],
    color: theme.palette.green[600],
  },
  modelNameLink: {
    cursor: 'pointer',
  },
}));

export type SnapshotModelsTableProps = {
  snapshot: DatasetVersion;
};

export const SnapshotModelsTable: React.FC<SnapshotModelsTableProps> = ({ snapshot }) => {
  const styles = useStyles();
  const history = useHistory();

  // get snapshot models data
  const {
    data: snapshotModels,
    isLoading: snapshotModelsLoading,
    error: snapshotModelsError,
  } = useDatasetSnapshotModelsQuery({
    datasetVersionId: snapshot.id,
  });

  if (snapshotModels?.length === 0) {
    return (
      <Box className={styles.emptyStateContainer}>
        <Typography>{t('No models trained with this snapshot')}</Typography>
      </Box>
    );
  }

  return (
    <ApiResponseLoader
      response={snapshotModels}
      loading={snapshotModelsLoading}
      error={snapshotModelsError}
    >
      {snapshotModels => {
        return (
          <Box>
            <TableContainer className={styles.tableContainer}>
              <Table aria-label="download-list-table">
                <TableHead className={styles.tableHeader}>
                  <TableRow>
                    <TableCell align="left">{t('Model')}</TableCell>
                    <TableCell align="left">{t('Trained at')}</TableCell>
                    {/* <TableCell align="left">{t('Trained by')}</TableCell> */}
                    <TableCell align="left">{''}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {snapshotModels.map(model => (
                    <TableRow key={model.id} className={styles.tableRow} hover>
                      <TableCell align="left">
                        <Link
                          color={'primary'}
                          className={styles.modelNameLink}
                          onClick={() =>
                            history.push(`${CLEF_PATH.modelsV2.list}?modelId=${model.id}`)
                          }
                          role="link"
                        >
                          {model.modelName}
                        </Link>
                      </TableCell>
                      <TableCell align="left">{getReadableTime(model.createdAt)}</TableCell>
                      {/* <TableCell align="left">{model.createdBy}</TableCell> */}
                      <TableCell align="left">
                        {(model.status === ModelStatus.Created ||
                          model.status === ModelStatus.Starting ||
                          model.status === ModelStatus.Training ||
                          model.status === ModelStatus.Evaluating ||
                          model.status === ModelStatus.Publishing) && (
                          <Typography className={classNames(styles.statusChip, styles.inProgress)}>
                            {t('In Progress')}
                          </Typography>
                        )}
                        {model.status === ModelStatus.Succeed && (
                          <Typography className={classNames(styles.statusChip, styles.successful)}>
                            {t('Successful')}
                          </Typography>
                        )}
                        {/* {model.status === ModelStatus.Succeed && (
                          <Button variant="text">{t('View details')}</Button>
                        )} */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );
      }}
    </ApiResponseLoader>
  );
};

export default SnapshotModelsTable;

import { DateStringISO, OrgId, ProjectId, UsageSummary } from '@clef/shared/types';
import { BaseAPI } from './base_api';

const UsagePrefix = 'usage';

export interface GetUsageParams {
  fromTimestamp: string;
  toTimestamp: string;
  interval?: 'day' | 'week' | 'month' | 'year' | 'all';
  groupByProject?: boolean;
  groupBySource?: boolean;
  groupByImages?: boolean;
  pageSize?: number;
}

export interface GetUsageSummaryParams {
  includeLabeledMediaCount?: boolean;
}

export type UsageItemBase = {
  stripeSubscriptionId: string;
  orgId: number;
  projectId?: number;
  projectName?: string;
  source?: string;
  usage: number;
};

// When request with interval: 'year' | 'month' | 'week' | 'day',
// response items are grouped into a time range.
export type UsageItemWithInterval = UsageItemBase & {
  startTime: DateStringISO;
  endTime: DateStringISO;
};

// When request with interval: undefined, items are grouped by ts
// When request with interval: 'all', items are not grouped.
// In both case, there is only one timestamp.
export type UsageItemWithTimestamp = UsageItemBase & {
  timestamp: DateStringISO;
};

export type UsageItem = UsageItemWithInterval | UsageItemWithTimestamp;

export type UsageImagesItem = {
  orgId: OrgId;
  projectId: ProjectId;
  mediaCount: number;
  projectName: string;
};

export type ModelCountsItem = {
  orgId: OrgId;
  startTime: string;
  endTime: string;
  modelCount: number;
};

class UsageAPI extends BaseAPI {
  getUsage(params: GetUsageParams): Promise<UsageItem[]> {
    return this.get(`record`, params, true);
  }
  getUsageSummary(params: GetUsageSummaryParams): Promise<UsageSummary> {
    return this.get('summary', params, true);
  }
  getMediaCounts(params: GetUsageParams): Promise<UsageImagesItem[]> {
    return this.get(`media_counts`, params, true);
  }
  getModelCounts(params: GetUsageParams): Promise<ModelCountsItem[]> {
    return this.get(`model_counts`, params, true);
  }
}

export default new UsageAPI(UsagePrefix);

import React from 'react';

export type EraserIconProps = {};

const EraserIcon: React.FC<EraserIconProps> = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.9996 15.1665L12.8329 6.9998M24.4996 24.4998H9.33289M12.7597 23.4063L22.8731 13.2929C24.2591 11.9069 24.9522 11.2138 25.2118 10.4147C25.4402 9.71174 25.4402 8.95452 25.2118 8.25157C24.9522 7.45242 24.2591 6.75939 22.8731 5.37333L22.626 5.12626C21.24 3.7402 20.5469 3.04717 19.7478 2.78751C19.0448 2.55911 18.2876 2.55911 17.5847 2.78751C16.7855 3.04717 16.0925 3.7402 14.7064 5.12626L5.12602 14.7067C3.73996 16.0927 3.04693 16.7858 2.78727 17.5849C2.55887 18.2879 2.55887 19.0451 2.78727 19.748C3.04693 20.5472 3.73996 21.2402 5.12601 22.6263L5.90608 23.4063C6.30964 23.8099 6.51142 24.0117 6.74689 24.156C6.95566 24.2839 7.18326 24.3782 7.42134 24.4353C7.68988 24.4998 7.97524 24.4998 8.54595 24.4998H10.1198C10.6905 24.4998 10.9759 24.4998 11.2444 24.4353C11.4825 24.3782 11.7101 24.2839 11.9189 24.156C12.1544 24.0117 12.3561 23.8099 12.7597 23.4063Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EraserIcon;

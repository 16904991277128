import React from 'react';
import cx from 'classnames';
import { Typography, makeStyles, Grid } from '@material-ui/core';
import { Button, Dropdown } from '@clef/client-library';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  optionItem: {
    cursor: 'pointer',
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'rgba(9, 30, 66, 0.08)',
    },
  },
  optionItemOpen: {
    backgroundColor: 'rgba(9, 30, 66, 0.08)',
  },
  dropdownPanel: {
    minWidth: 150,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectorText: {
    marginRight: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  operationValueContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  textButton: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  dropdownContainer: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 8,
  },
  postRequestMask: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
    background: 'rgba(255,255,255,0.7)',
  },
  noneOption: {
    fontStyle: 'italic',
  },
  inputRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
    },
    marginBottom: theme.spacing(2),
  },
  input: {
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: '0.75rem',
  },
}));

export interface MoreFiltersDropdownProps {
  filters: {
    node: React.ReactNode;
    title: string;
    enabled: boolean;
    enable: () => void;
    disable: () => void;
  }[];
}

const MoreFiltersDropdown: React.FC<MoreFiltersDropdownProps> = ({
  filters,
}: MoreFiltersDropdownProps) => {
  const styles = useStyles();

  return (
    <div className={styles.dropdownPanel} data-testid="more-filters-dropdown-panel">
      {filters.map(({ node, title, enabled, enable, disable }, index) => (
        <Dropdown
          key={`extra-filter-${index}`}
          placement="right"
          extraGutter={{ horizontal: 4, vertical: -8 }}
          dropdown={
            <div className={styles.dropdownContainer}>
              <div className={styles.dropdownPanel}>
                <div className={styles.operationValueContainer}>
                  <Typography variant="body2" gutterBottom component="div">
                    {t('Set value')}
                  </Typography>
                  {node}
                </div>
                <Grid container justifyContent="flex-end">
                  <Button
                    id="apply-metadata-btn"
                    data-testid="apply-metadata-btn"
                    color="primary"
                    size="small"
                    className={styles.textButton}
                    onClick={enable}
                  >
                    {t('Apply')}
                  </Button>
                  <Button
                    id="clear-metadata-btn"
                    data-testid="clear-metadata-btn"
                    color="secondary"
                    size="small"
                    className={styles.textButton}
                    onClick={disable}
                  >
                    {t('Clear')}
                  </Button>
                </Grid>
              </div>
            </div>
          }
        >
          <Grid
            className={cx(styles.optionItem)}
            direction="row"
            justifyContent="space-between"
            alignContent="center"
            container
            wrap="nowrap"
          >
            <Typography variant="body2" className={styles.selectorText}>
              {title}
            </Typography>
            {enabled ? (
              <CheckCircleIcon fontSize="small" color="primary" />
            ) : (
              <ChevronRightIcon fontSize="small" />
            )}
          </Grid>
        </Dropdown>
      ))}
    </div>
  );
};

export default MoreFiltersDropdown;

import React, { useCallback, useState } from 'react';
import { Box, Link, makeStyles } from '@material-ui/core';
import ImageList from './ImageList';
import { useTypographyStyles } from '@clef/client-library/src/themes/typography';
import Refresh from '@material-ui/icons/Refresh';
import CLEF_PATH from '@/constants/path';
import { useSnackbar } from 'notistack';
import zero_auth_api from '@/api/zero_auth_api';
import { useCurrentProject, useHasTrained, useZeroAuthInstantLearningState } from './state';
import { refreshZeroAuthProjectApi } from '@/hooks/api/useZeroAuthApi';
import classNames from 'classnames';
import { ToolMode, useLabelingState } from '../../components/Labeling/labelingState';

const useStyles = makeStyles(theme => ({
  previewBar: {
    boxShadow: '0px 6px 12px 0 rgba(24, 39, 75, 0.12), 0px 8px 24px 0 rgba(24, 39, 75, 0.08)',
  },
  divider: {
    borderLeft: `4px solid ${theme.palette.greyModern[50]}`,
    margin: theme.spacing(0, 5),
    flexShrink: 0,
  },
  resetProjectButton: {
    width: 240,
    height: 133,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.greyModern[200]}`,
    borderRadius: 10,
    cursor: 'pointer',
    flexShrink: 0,
    '&.loading': {
      opacity: 0.7,
      pointerEvents: 'none',
      cursor: 'not-allowed',
    },
  },
  registrationCard: {
    width: 240,
    height: 133,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(0, 5),
    flexShrink: 0,
  },
  resetProjectIcon: {
    fontSize: 32,
    color: theme.palette.greyModern[600],
  },
}));

export type PreviewBarProps = {};

const PreviewBar: React.FC<PreviewBarProps> = () => {
  const styles = useStyles();
  const typographyStyles = useTypographyStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useZeroAuthInstantLearningState();
  const { currentProjectId, training } = state;
  const [loading, setLoading] = useState(false);
  const hasTrained = useHasTrained();
  const { dispatch: dispatchLabelingState } = useLabelingState();
  const project = useCurrentProject();

  const onResetProject = useCallback(async () => {
    setLoading(true);
    if (training) {
      dispatchLabelingState(draft => {
        draft.toolMode = ToolMode.Brush;
        draft.toolOptions.erasing = false;
        draft.toolOptions.strokeWidth = project!.brushSize;
        draft.toolOptions.eraserWidth = project!.brushSize;
      });
    }
    try {
      await zero_auth_api.reset(currentProjectId!);
      enqueueSnackbar(t('Project reset succeeded!'), { variant: 'success' });
      refreshZeroAuthProjectApi({ keys: 'refresh-all' });
      dispatch(draft => {
        draft.mediaIndex = 0;
        draft.mediaStatesByIndex = {};
      });
    } catch (e) {
      // do nothing here
      enqueueSnackbar(t(e.message), { variant: 'error', autoHideDuration: 12000 });
    }
    setLoading(false);
  }, [currentProjectId, dispatch, dispatchLabelingState, enqueueSnackbar, project, training]);

  if (!hasTrained) {
    return null;
  }

  return (
    <Box paddingX={5} paddingTop={1} paddingBottom={2.5} className={styles.previewBar}>
      <Box className={typographyStyles.body_bold} marginBottom={2.5}>
        {t('Review Predictions on more images')}
      </Box>
      <Box display="flex" flexWrap="nowrap" alignItems="stretch" overflow={'auto'}>
        <ImageList />
        <Box className={styles.divider} />
        <Box
          className={classNames(styles.resetProjectButton, loading && 'loading')}
          id="reset-project-button"
          role="button"
          onClick={onResetProject}
        >
          <Refresh className={styles.resetProjectIcon} />
          <Box className={typographyStyles.h4_semibold}>{t('Reset Project')}</Box>
        </Box>
        <Box className={styles.registrationCard}>
          <Box className={typographyStyles.body_medium}>{t('Or')}</Box>
          <Box className={typographyStyles.body_medium}>
            {t('{{signUp}} and start creating your own projects for free!', {
              signUp: <Link href={CLEF_PATH.signup.main}>{t('Sign up')}</Link>,
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PreviewBar;

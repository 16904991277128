import React from 'react';

export type RedoIconProps = {};

const RedoIcon: React.FC<RedoIconProps> = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.3334 8.16667H11.6667C7.80076 8.16667 4.66675 11.3007 4.66675 15.1667C4.66675 19.0327 7.80076 22.1667 11.6667 22.1667H23.3334M23.3334 8.16667L18.6667 3.5M23.3334 8.16667L18.6667 12.8333"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RedoIcon;

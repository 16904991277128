import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import { Button } from '@clef/client-library';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getModelName } from '@/utils/models';
import { useAllModels } from '@/hooks/useModels';
import { ModelInfoBanner } from './ModelInfoBanner';
import { LabelType } from '@clef/shared/types';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    marginBottom: theme.spacing(7),
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  backButton: {
    position: 'absolute',
    top: 12,
    left: 12,
  },
  top20Margin: {
    marginTop: 20,
  },
  actionButton: {
    width: 'calc(50% - 10px)',
  },
  deployButton: {
    width: '100%',
  },
}));

interface Props {
  initialSelectedEndpointId?: string | null;
  onClose: () => void;
  modelInfo?: {
    id: string | undefined;
    threshold: number | undefined;
  } | null;
  isLargeImageModel?: boolean;
  onModelDeploy: () => Promise<void>;
  isDeploying: boolean;
  labelType?: LabelType | null;
  setSelectedEndpointId: (selectedEndpointId: string | null) => void;
}

export const EndpointConfirmDialog: React.FC<Props> = props => {
  const {
    initialSelectedEndpointId,
    onClose,
    modelInfo,
    isLargeImageModel,
    onModelDeploy,
    isDeploying,
    labelType,
    setSelectedEndpointId,
  } = props;
  const styles = useStyles();
  const { findModels } = useAllModels();

  return (
    <>
      <Grid container>
        {/* If no initial selected endpoint id, then we will show the first step to let user choose endpoint */}
        {/* and we need to show this back button */}
        {!initialSelectedEndpointId && (
          <IconButton
            id="close-endpoint-confirm-dialog"
            className={styles.backButton}
            onClick={() => setSelectedEndpointId(null)}
          >
            <ArrowBackIcon />
          </IconButton>
        )}

        <Box paddingTop={10} width="100%" />

        <Typography variant="h2" className={styles.title}>
          {t('Are you sure you want to replace the current model with the following settings?')}
        </Typography>
      </Grid>

      <ModelInfoBanner
        inLargeModal
        labelType={labelType}
        modelName={getModelName(findModels(modelInfo?.id))}
        threshold={modelInfo?.threshold}
      />

      <Grid container className={styles.top20Margin} justifyContent="space-between">
        <Button
          id="deploy-model-dialog-go-back-button"
          variant="outlined"
          color="primary"
          className={styles.actionButton}
          onClick={() => {
            if (initialSelectedEndpointId) {
              onClose();
            } else {
              setSelectedEndpointId(null);
            }
          }}
        >
          {t('No, Go Back')}
        </Button>
        <Box className={styles.actionButton}>
          <Button
            id="deploy-model-dialog-deploy-button"
            variant="contained"
            color="primary"
            tooltip={t(
              isLargeImageModel
                ? 'This model is incompatible with cloud inference.\nUse LandingEdge to run predictions.'
                : '',
            )}
            tooltipOnButton={isLargeImageModel}
            disabled={isLargeImageModel}
            className={styles.deployButton}
            onClick={() => onModelDeploy()}
            isPending={isDeploying}
          >
            {t('Yes, Deploy')}
          </Button>
        </Box>
      </Grid>
    </>
  );
};

import React from 'react';
import { Predict } from '../../../components/Predict';
import { useDataBrowserState } from '../dataBrowserState';
import {
  useGetSelectedProjectQuery,
  useGetProjectVersionedDefectsQuery,
} from '@/serverStore/projects';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import useStyles from './styles';
import { DeployComponent } from './DeployComponent';
import { LabelType } from '@clef/shared/types';
import { thresholdForPredictAtom } from '@/uiStates/projectModels/pageUIStates';
import { useAtom } from 'jotai';

interface FirstRunLiveExperienceDialogProps {
  labelType?: LabelType | null | undefined;
}

export const FirstRunLiveExperienceDialog: React.FC<FirstRunLiveExperienceDialogProps> = () => {
  const {
    name,
    id: projectId,
    datasetId,
    labelType,
  } = useGetSelectedProjectQuery().data ?? {
    name: '',
    id: undefined,
    datasetId: undefined,
  };
  const { id: currentModelId, versionedDatasetContentId } = useCurrentProjectModelInfoQuery();
  const allDefects = useGetProjectVersionedDefectsQuery(versionedDatasetContentId).data!;

  const { dispatch } = useDataBrowserState();

  const [thresholdForPredict, setThresholdForPredict] = useAtom(thresholdForPredictAtom);

  const styles = useStyles();

  const closeModal = () => {
    dispatch(draft => {
      draft.isFirstRunLiveExperienceModalOpen = false;
    });
  };

  if (!projectId || !datasetId) return null;

  const modelInfo = { id: currentModelId, threshold: thresholdForPredict };

  return (
    <Predict
      selectedProject={{
        projectId,
        datasetId,
        name,
        labelType,
      }}
      modelInfo={modelInfo}
      updateThreshold={newThreshold => setThresholdForPredict(newThreshold)}
      defectMap={allDefects}
      title="Try this model"
      onCloseModal={closeModal}
      classes={{
        bannerContainer: styles.modalBanner,
      }}
      bannerComponent={null}
      deployComponent={<DeployComponent modelInfo={modelInfo} labelType={labelType} />}
    />
  );
};

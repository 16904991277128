import React, { useMemo } from 'react';
import { Box, Dialog, makeStyles, Paper } from '@material-ui/core';
import megaphoneIcon from '@/images/megaphone.svg';
import { useCurrentSubscription } from '../../../hooks/useSubscriptions';
import { getPlanName } from '../../../pages/plan/utils';
import { Button } from '@clef/client-library';
import { useHistory } from 'react-router';
import CLEF_PATH from '../../../constants/path';
import { UPLOAD_DIALOG_Z_INDEX } from '../../Dialogs/UploadFullscreen/constants';
import { LabelType, UpgradePurpose } from '@clef/shared/types';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { InstantLearningMediaLimit } from '@/constants';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: (UPLOAD_DIALOG_Z_INDEX + 1 + '!important') as unknown as number,
  },
  paper: {
    width: 520,
  },
  title: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '28px',
    textAlign: 'center',
  },
  content: {
    fontSize: 14,
    lineHeight: '20px',
  },
  icon: {
    width: 48,
    height: 48,
  },
}));

export enum ExceedType {
  Upload = 'Upload',
  Train = 'Train',
  Predict = 'Predict',
  Credits = 'Credits',
}

export type UsageExceedDialogProps = {
  exceedType?: ExceedType;
  remaining: number;
  cost: number;
  onConfirm?: () => void;
  onClose?: () => void;
};

const UsageExceedDialog: React.FC<UsageExceedDialogProps> = props => {
  const { exceedType, remaining, cost, onConfirm, onClose } = props;
  const subscription = useCurrentSubscription();
  const styles = useStyles();
  const { labelType } = useGetSelectedProjectQuery()?.data ?? {};
  const isSegmentationInstantLearning = labelType === LabelType.SegmentationInstantLearning;
  const { title, content } = useMemo(() => {
    if (exceedType === ExceedType.Upload) {
      return {
        title: t('Uh-oh! You hit image limit'),
        content: isSegmentationInstantLearning
          ? t(
              'We accept up to {{InstantLearningMediaLimit}} images in Visual Prompting (beta). We will be able to support more in future release, thanks for your understanding.',
              { InstantLearningMediaLimit },
            )
          : t(
              "You're trying to upload {{cost}} images, but you only have {{remaining}} image uploads left for this project. Upgrade now to continue or remove some images from your upload.",
              { cost, remaining, plan: getPlanName(subscription) },
            ),
      };
    } else if (exceedType === ExceedType.Train) {
      return {
        title: t("Uh-oh! You don't have enough credits"),
        content: t(
          'Training this model will cost {{cost}} credits, but your team only have {{remaining}} credits left. Upgrade your plan now to train your model.',
          { cost, remaining },
        ),
      };
    }
    return { title: '', content: '' };
  }, [cost, exceedType, isSegmentationInstantLearning, remaining, subscription]);
  const history = useHistory();
  return (
    <Dialog
      open={!!exceedType && !!title && !!content}
      onClose={onClose}
      classes={{ root: styles.root, paper: styles.paper }}
    >
      <Paper>
        <Box padding={6}>
          <Box display="flex" justifyContent="center" marginBottom={4}>
            <img src={megaphoneIcon} className={styles.icon} />
          </Box>
          <Box className={styles.title} marginBottom={4}>
            {title}
          </Box>
          <Box className={styles.content} marginBottom={6}>
            {content}
          </Box>
          <Box display="flex">
            <Button
              id="cancel-upgrade"
              color="primary"
              variant={isSegmentationInstantLearning ? 'contained' : 'outlined'}
              onClick={onClose}
              style={{ flex: 1 }}
            >
              {isSegmentationInstantLearning ? t('Got it, cancel upload') : t('Cancel')}
            </Button>
            {!isSegmentationInstantLearning && (
              <>
                <Box paddingLeft={2} />
                <Button
                  id="upgrade-plan"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    if (exceedType === ExceedType.Upload) {
                      history.push(CLEF_PATH.plan + '?purpose=' + UpgradePurpose.MoreImages);
                    } else {
                      history.push(CLEF_PATH.plan);
                    }
                    onConfirm?.();
                  }}
                  style={{ flex: 1 }}
                >
                  {t('Upgrade Plan')}
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default UsageExceedDialog;

import { DialogProps } from '@material-ui/core';
import React from 'react';

import BaseDialog from '@/components/Dialogs/BaseDialog';
import DownloadLandingEdgeContent from './DownloadLandingEdgeContent';

export interface DownloadLandingEdgeConfirmDialogProps extends DialogProps {
  open: boolean;
  onClose: () => void | Promise<void>;
}

export const DownloadLandingEdgeConfirmDialog: React.FC<DownloadLandingEdgeConfirmDialogProps> = ({
  open,
  onClose,
}) => {
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={t('Download LandingEdge')}
      containerProps={{ minWidth: 600 }}
    >
      <DownloadLandingEdgeContent onDownload={onClose} />
    </BaseDialog>
  );
};

export default DownloadLandingEdgeConfirmDialog;

import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@/images/data-browser/dataset/WarningIcon';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';

import { DatasetVersion, MediaStatusType } from '@clef/shared/types';

import { Button, IconButton, Typography } from '@clef/client-library';

import {
  useCreateDatesetSnapshotMutation,
  useRevertDatasetVersionMutation,
  useDatasetMediaCountQuery,
} from '@/serverStore/dataset';

import useStyles from './styles';

export interface RestoreDialogProps {
  snapshot: DatasetVersion;
  onClose: () => void;
}

export const RestoreDialog: React.FC<RestoreDialogProps> = ({ snapshot, onClose }) => {
  const styles = useStyles();
  const { version, count } = snapshot;

  const { data: labeledMediaCount } = useDatasetMediaCountQuery(
    {
      version: version,
      selectOptions: {
        selectedMedia: [],
        unselectedMedia: [],
        isUnselectMode: true,
        fieldFilterMap: {},
        columnFilterMap: {
          datasetContent: { mediaStatus: { CONTAINS_ANY: [MediaStatusType.Approved] } },
        },
      },
    },
    !!version,
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const createDatasetSnapshot = useCreateDatesetSnapshotMutation();
  const revertDatasetVersion = useRevertDatasetVersionMutation();

  const [saveSnapshotFirst, setSaveSnapshotFirst] = useState(false);

  const restoreSnapshot = () => {
    if (saveSnapshotFirst) {
      createDatasetSnapshot.mutate(
        {
          name: 'Snapshot-' + format(new Date(), 'MM-dd-yyyy HH:mm'),
          details: 'auto saved before reverting',
          onlyLabeled: false,
          selectMediaOptions: {
            fieldFilterMap: {},
            columnFilterMap: {},
            selectedMedia: [],
            unselectedMedia: [],
            isUnselectMode: true,
          },
        },
        {
          onSuccess: () => {
            const snackbarKey = enqueueSnackbar(t('Successfully taken snapshot, restoring...'), {
              variant: 'success',
              autoHideDuration: 3000,
            });
            revertDatasetVersion.mutate(version, {
              onSuccess: () => {
                closeSnackbar(snackbarKey);
                onClose();
              },
            });
          },
        },
      );
    } else {
      revertDatasetVersion.mutate(version, {
        onSuccess: onClose,
      });
    }
  };

  return (
    <Dialog open color="primary" PaperProps={{ className: styles.dialog }}>
      <DialogTitle className={styles.dialogSection}>
        <Box className={styles.dialogTitleText}>{t('Revert current project to this Snapshot')}</Box>
        <IconButton id="restor-dialog-close-btn" onClick={onClose} className={styles.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classNames(styles.dialogSection, styles.dialogContent)}>
        <Typography className={styles.noticeBox}>
          {t(`Your project will have {{mediaCount}} {{labeledMediaCount}} after reverting.`, {
            mediaCount: (
              <Typography display="inline" variant="body1" className={styles.mainInfoFont}>
                {` ${count} Images`}
              </Typography>
            ),
            labeledMediaCount:
              labeledMediaCount !== undefined ? (
                <Typography display="inline" variant="body1" className={styles.subInfoFont}>
                  {`(${labeledMediaCount} Labeled)`}
                </Typography>
              ) : null,
          })}
        </Typography>
        <Box className={styles.waringBox}>
          <IconButton id="warning-icon" size="small" className={styles.warningIcon}>
            <WarningIcon />
          </IconButton>
          {/* TODO: copy should change if current master dataset is already snapshotted */}
          <Typography variant="body1" className={styles.subInfoFont}>
            {t(
              'If reverted, you will lose progress on all data (uploaded images, labels, and labeling tasks).',
            )}
          </Typography>
        </Box>
        {/* TODO: don't show the option if current master dataset is already snapshotted */}
        <FormControlLabel
          className={styles.saveSnapshotCheck}
          control={
            <Checkbox
              checked={saveSnapshotFirst}
              color="primary"
              onClick={() => setSaveSnapshotFirst(prev => !prev)}
            />
          }
          label={<Box>{t('Save current project data state as a snapshot before reverting')}</Box>}
        />
      </DialogContent>
      <DialogActions className={classNames(styles.dialogSection, styles.dialogActions)}>
        <Button
          id="restore-btn"
          color="default"
          onClick={onClose}
          disabled={revertDatasetVersion.isLoading}
        >
          {t('Cancel')}
        </Button>
        <Button
          id="save-and-restore-btn"
          color="primary"
          variant="contained"
          onClick={() => restoreSnapshot()}
          disabled={createDatasetSnapshot.isLoading || revertDatasetVersion.isLoading}
        >
          {t('Yes, revert')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestoreDialog;

import {
  cvtColor,
  findContours,
  matFromImageData,
  Mat,
  MatVector,
  threshold,
  COLOR_RGBA2GRAY,
  THRESH_BINARY,
  RETR_CCOMP,
  CHAIN_APPROX_SIMPLE,
} from '@techstark/opencv-js';
import EventLogger from '../../../logging/EventLogger';

export const getContours = (canvasBitMap: OffscreenCanvas) => {
  try {
    // 1. get image data and convert to cv.Mat
    const context = canvasBitMap.getContext('2d');
    const imageData = context!.getImageData(0, 0, canvasBitMap.width, canvasBitMap.height);
    const imageMat = matFromImageData(imageData);

    // 2. change to gray scale and merge colors to black and white, where
    // rgb(0,0,0) to black, and the others to white.
    cvtColor(imageMat, imageMat, COLOR_RGBA2GRAY, 0);
    threshold(imageMat, imageMat, 1, 255, THRESH_BINARY);

    // 3. find contours based on the black and white image data.
    const contours = new MatVector();
    const hierarchy = new Mat();
    findContours(imageMat, contours, hierarchy, RETR_CCOMP, CHAIN_APPROX_SIMPLE);

    // 4. convert the result into an array of lines.
    const totalContours = contours.size() as unknown as number;
    const contourList: number[][] = [];
    for (let i = 0; i < totalContours; ++i) {
      const contourMat = contours.get(i);
      const contour = Array.from(contourMat.data32S);
      contourList.push(contour);
    }

    return contourList;
  } catch (error: any) {
    // Most likely, the error can be a number throw by OpenCV when creating a Mat instance from the canvas
    // When that happens, check if the JS heap is full
    EventLogger.error(error instanceof Error ? error : new Error(error), {
      ImageSizeInfo: {
        width: canvasBitMap.width,
        height: canvasBitMap.height,
      },
      ErrorInfo: error,
    });

    // If there is error, do not rethrow the exception as contour should not block the user flow
    return [];
  }
};

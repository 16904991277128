import React from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
import { IconButton } from '@clef/client-library';
import Close from '@material-ui/icons/Close';

type SnackbarCloseButtonProps = {
  snackbarKey?: SnackbarKey;
};

// Add a close button to the snackbar
// https://github.com/iamhosseindhv/notistack/issues/156
const SnackbarCloseButton: React.FC<SnackbarCloseButtonProps> = props => {
  const { snackbarKey } = props;
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton id="close-snack-bar" onClick={() => closeSnackbar(snackbarKey)} size="small">
      <Close style={{ color: 'white' }} />
    </IconButton>
  );
};

export default SnackbarCloseButton;

import { makeStyles } from '@material-ui/core';

const useSnackbarStyles = makeStyles(() => ({
  root: {
    borderRadius: 8,
    overflow: 'hidden',
    '& > div': {
      fontSize: 14,
      fontFamily: 'Commissioner, sans-serif',
      fontWeight: 500,
      flexWrap: 'nowrap',
    },
  },
}));

export default useSnackbarStyles;

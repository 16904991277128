import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  DialogContent,
  Grid,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import {
  generateUploadedMessage,
  generateUploadingMessage,
  getUploadStats,
} from '../../../../store/uploadState/utils';
import { useNewUploadStyles } from '../styles';
import { useAppDispatch } from '../../../../store';
import { useSnackbar } from 'notistack';
import { Alert } from '@material-ui/lab';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { UploadFile, UploadMediaType, UploadStage } from '../../../../store/uploadState/types';
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded';
import {
  addMetadata,
  deleteSegmentationImageFile,
  resetUploadState,
  setFileWithNothingToLabel,
} from '../../../../store/uploadState';
import PreviewMedia from './PreviewMedia';
import { useDialog } from '../../../Layout/components/useDialog';
import { pairMediaLabelFiles } from '../../../../utils';
import { MAX_PREVIEW_IMAGES } from '../constants';
import MaxPreviewImagesIcon from './MaxPreviewImagesIcon';
import { useUploadMediaMutation } from '@/serverStore/upload';

export interface UploadWrapperProps {
  onClose?: () => void;
}

const UploadWrapper: React.FC<UploadWrapperProps> = ({ children }) => {
  const styles = useNewUploadStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { classifiedFolders, uploadData, uploadStage, uploadMediaType, metadata } =
    useTypedSelector(state => state.uploadState);
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { enqueueSnackbar } = useSnackbar();
  const { successCount, retryableCount, duplicatedCount } = getUploadStats(uploadData);

  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [activeMediaPreviewTab, setActiveMediaPreviewTab] = useState<number>(0);
  const [nothingToLabel, setNothingToLabel] = useState(false);
  const uploadMedia = useUploadMediaMutation();

  const onMediaPreviewTabChange = useCallback((_: any, newValue: number) => {
    setActiveMediaPreviewTab(newValue);
  }, []);

  const separateSegmentationImages = useCallback(() => {
    const labeledFiles: UploadFile[] = [];
    const unlabeledFiles: UploadFile[] = [];

    uploadData.forEach(file => {
      if (file.initialLabel?.segMask) {
        labeledFiles.push(file);
      } else {
        unlabeledFiles.push(file);
      }
    });

    return {
      labeledFiles,
      unlabeledFiles,
    };
  }, [uploadData]);

  const images = useMemo(() => separateSegmentationImages(), [separateSegmentationImages]);

  useEffect(() => {
    if (uploadMediaType === UploadMediaType.SegmentationLabeledMedia) {
      // If there is only unlabeled media, auto show unlabeled media preview tab
      if (images.unlabeledFiles.length && !images.labeledFiles.length) {
        setActiveMediaPreviewTab(1);
      }

      // If there is only labeled media, auto show labeled media preview tab
      if (images.labeledFiles.length && !images.unlabeledFiles.length) {
        setActiveMediaPreviewTab(0);
      }
    }
  }, [images, uploadMediaType]);

  const UploadDropzoneHeader = useMemo(
    () => <div className={styles.headerContainer}>{children}</div>,
    [children, styles.headerContainer],
  );

  const UploadInProgressHeader = useMemo(
    () => (
      <div className={styles.headerContainer}>
        <Typography variant="h1" align="center" className={styles.headerText}>
          {t('Uploading images...')}
        </Typography>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="body1">{generateUploadingMessage(uploadData)}</Typography>
          {/* <Button color="secondary">{t('Cancel Upload')}</Button> */}
        </Grid>
        <LinearProgress
          value={(successCount / uploadData.length) * 100}
          variant="determinate"
          className={styles.uploadProgress}
        />
      </div>
    ),
    [styles, uploadData, successCount],
  );

  const { showConfirmationDialog } = useDialog();

  const UploadMoreMediaButton = useMemo(
    () => (
      <Button
        variant="text"
        color="primary"
        id="upload-more-images-button"
        onClick={() => {
          if (retryableCount > 0) {
            showConfirmationDialog({
              title: t('Ignore failed images?'),
              content: t('There are images not uploaded successfully'),
              onConfirm: () => {
                dispatch(resetUploadState());
              },
              confirmText: t('Yes, ignore'),
              color: 'primary',
            });
          } else {
            dispatch(resetUploadState());
            if (metadata) {
              dispatch(addMetadata(metadata));
            }
          }
        }}
      >
        {t('Upload More Images')}
      </Button>
    ),
    [retryableCount, showConfirmationDialog, dispatch, metadata],
  );

  const RetryUploadButton = useMemo(
    () => (
      <Button color="primary" id="re-upload-image" onClick={() => uploadMedia.mutate()}>
        {t('Retry upload {{count}} images', { count: retryableCount })}
      </Button>
    ),
    [dispatch, enqueueSnackbar, retryableCount, selectedProject],
  );

  const UploadSuccessHeader = useMemo(
    () => (
      <div className={styles.headerContainer}>
        <Typography variant="h1" align="center" className={styles.headerText}>
          <CheckCircleRounded className={styles.uploadSuccessIcon} />
          {t('Success!')}
          {UploadMoreMediaButton}
        </Typography>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            {t('Uploaded {{count}} / {{total}} image(s)', {
              count: <strong>{successCount}</strong>,
              total: <strong>{uploadData.length}</strong>,
            })}
          </Typography>
        </Grid>
        <LinearProgress value={100} variant="determinate" className={styles.uploadProgress} />
      </div>
    ),
    [styles, uploadData.length, successCount, UploadMoreMediaButton],
  );

  const UploadFailureHeader = useMemo(
    () => (
      <div className={styles.headerContainer}>
        <Alert
          data-testid="upload-fullscreen-dialog-alert"
          severity="warning"
          action={
            <>
              {UploadMoreMediaButton}
              {!!retryableCount && RetryUploadButton}
            </>
          }
        >
          {generateUploadedMessage({ successCount, retryableCount, duplicatedCount })}
        </Alert>
      </div>
    ),
    [
      styles.headerContainer,
      UploadMoreMediaButton,
      retryableCount,
      RetryUploadButton,
      successCount,
      duplicatedCount,
    ],
  );

  const findDuplicateMediaName = useCallback(async () => {
    const mediaLabelPairs = await pairMediaLabelFiles(uploadData.map(item => item.file) as File[]);
    const hasDuplicateMediaName = Object.values(mediaLabelPairs).some(
      value => value.mediaFiles!.length > 1,
    );
    setIsDuplicate(hasDuplicateMediaName);
  }, [uploadData]);

  useEffect(() => {
    findDuplicateMediaName();
  }, [findDuplicateMediaName]);

  const RepeatMediaContent = useMemo(
    () => (
      <div className={styles.headerContainer}>
        <Grid container direction="row" alignItems="flex-start">
          <Alert severity="warning">
            <Typography>
              {t(
                'You are uploading images with duplicate names. \
                If this image name contains a label, \
                it will be added to all of the image with the same name',
              )}
            </Typography>
          </Alert>
        </Grid>
      </div>
    ),
    [styles.headerContainer],
  );

  const ClassifiedMediaPreviewHeader = useMemo(
    () => (
      <DialogContent className={styles.uploadContainer}>
        {/* Preview header */}
        {uploadStage === UploadStage.NotStarted && (
          <Grid container alignItems="center">
            <Typography
              variant="h3"
              component="div"
              className={styles.sectionHeaderText}
              gutterBottom
            >
              <strong>{t('Upload Preview')}</strong>
              <span>
                {t('{{count}} image(s)', {
                  count: uploadData.length,
                })}
              </span>
            </Typography>
            <div className={styles.flexGrow} />
          </Grid>
        )}
      </DialogContent>
    ),
    [styles, uploadStage, uploadData.length],
  );

  const UnclassifiedMediaPreviewHeader = useMemo(
    () => (
      <div className={styles.uploadContainer}>
        {/* Preview header */}
        {uploadStage === UploadStage.NotStarted && (
          <Grid container alignItems="center">
            <Typography
              variant="h3"
              component="div"
              className={styles.sectionHeaderText}
              gutterBottom
            >
              <strong>{t('Upload Preview')}</strong>
              <span className={styles.mediaCountText}>
                {t('({{count}} image(s))', {
                  count: uploadData.length,
                })}
              </span>
              {uploadMediaType === UploadMediaType.SegmentationUnlabeledMedia && (
                <MaxPreviewImagesIcon imageCount={images.unlabeledFiles.length} />
              )}
            </Typography>
          </Grid>
        )}
      </div>
    ),
    [images.unlabeledFiles, styles, uploadData, uploadMediaType, uploadStage],
  );

  const MediaPreviewHeader = uploadData.length
    ? classifiedFolders.length
      ? ClassifiedMediaPreviewHeader
      : UnclassifiedMediaPreviewHeader
    : null;

  const SegmentationLabeledMediaPreview = useMemo(
    () => (
      <div className={styles.previewImgRow}>
        {images.labeledFiles.slice(0, MAX_PREVIEW_IMAGES).map(uploadFile => (
          <PreviewMedia
            uploadFile={uploadFile}
            key={uploadFile.key}
            onFileDelete={file => {
              dispatch(deleteSegmentationImageFile([file.file]));
            }}
          />
        ))}
      </div>
    ),

    [dispatch, images.labeledFiles, styles.previewImgRow],
  );

  const SegmentationUnlabeledMediaPreview = useMemo(
    () => (
      <>
        {uploadStage === UploadStage.NotStarted && (
          <div className={styles.segmentationUnlabeledMediaPreviewCheckboxContainer}>
            <Checkbox
              checked={nothingToLabel}
              onChange={() => {
                dispatch(setFileWithNothingToLabel(!nothingToLabel));
                setNothingToLabel(!nothingToLabel);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
              color="primary"
              data-testid="nothing-to-label-checkbox"
            />
            <span className={styles.segmentationUnlabeledMediaPreviewCheckboxText}>
              {t('Upload {{count}} unlabeled image(s) as “Nothing to Label”', {
                count: images.unlabeledFiles.length,
              })}
            </span>
          </div>
        )}
        <div className={styles.previewImgRow}>
          {images.unlabeledFiles.slice(0, MAX_PREVIEW_IMAGES).map(uploadFile => (
            <PreviewMedia
              uploadFile={uploadFile}
              key={uploadFile.key}
              onFileDelete={file => {
                dispatch(deleteSegmentationImageFile([file.file]));
              }}
            />
          ))}
        </div>
      </>
    ),
    [
      dispatch,
      images.unlabeledFiles,
      nothingToLabel,
      styles.previewImgRow,
      styles.segmentationUnlabeledMediaPreviewCheckboxContainer,
      styles.segmentationUnlabeledMediaPreviewCheckboxText,
      uploadStage,
    ],
  );

  const SegmentationMediaTypeTabs = useMemo(
    () => (
      <>
        <Box className={styles.segmentationMediaTypeTabsContainer}>
          <Tabs
            value={activeMediaPreviewTab}
            onChange={onMediaPreviewTabChange}
            aria-label="basic tabs example"
            indicatorColor="primary"
          >
            <Tab
              label={
                <Box whiteSpace="nowrap">
                  <span className={styles.segmentationMediaTypeTabText}>
                    <strong>{t('Labeled ')}</strong>
                  </span>
                  <Box component="span" className={styles.segmentationMediaTypeTabBox}>
                    {images.labeledFiles.length}
                  </Box>
                  <MaxPreviewImagesIcon
                    imageCount={images.labeledFiles.length}
                    color={theme.palette.primary.main}
                  />
                </Box>
              }
              className={styles.segmentationMediaTypeTab}
            />
            <Tab
              label={
                <Box whiteSpace="nowrap">
                  <span className={styles.segmentationMediaTypeTabText}>
                    <strong>{t('Unlabeled ')}</strong>
                  </span>
                  <Box component="span" className={styles.segmentationMediaTypeTabBox}>
                    {images.unlabeledFiles.length}
                  </Box>
                  <MaxPreviewImagesIcon
                    imageCount={images.unlabeledFiles.length}
                    color={theme.palette.primary.main}
                  />
                </Box>
              }
              className={styles.segmentationMediaTypeTab}
            />
          </Tabs>
        </Box>
        {activeMediaPreviewTab === 0 && SegmentationLabeledMediaPreview}
        {activeMediaPreviewTab === 1 && SegmentationUnlabeledMediaPreview}
      </>
    ),
    [
      SegmentationLabeledMediaPreview,
      SegmentationUnlabeledMediaPreview,
      activeMediaPreviewTab,
      images.labeledFiles.length,
      images.unlabeledFiles.length,
      onMediaPreviewTabChange,
      styles.segmentationMediaTypeTab,
      styles.segmentationMediaTypeTabBox,
      styles.segmentationMediaTypeTabText,
      styles.segmentationMediaTypeTabsContainer,
      theme.palette.primary.main,
    ],
  );

  const MediaPreviewContent = MediaPreviewHeader
    ? (uploadMediaType === UploadMediaType.SegmentationLabeledMedia && SegmentationMediaTypeTabs) ||
      (uploadMediaType === UploadMediaType.SegmentationUnlabeledMedia &&
        SegmentationUnlabeledMediaPreview)
    : null;

  const HeaderComponent =
    (uploadStage === UploadStage.NotStarted && UploadDropzoneHeader) ||
    (uploadStage === UploadStage.UploadInProgress && UploadInProgressHeader) ||
    (uploadStage === UploadStage.UploadFulfilled && UploadSuccessHeader) ||
    (uploadStage === UploadStage.UploadFulfilledWithFailure && UploadFailureHeader) ||
    null;

  return (
    <>
      {isDuplicate && RepeatMediaContent}
      {HeaderComponent}
      {MediaPreviewHeader}
      {MediaPreviewContent}
    </>
  );
};

export default UploadWrapper;

import React from 'react';

const DriveFolderUploadIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 13H11V8.8L12.6 10.4L14 9L10 5L6 9L7.4 10.4L9 8.8V13ZM2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V2C0 1.45 0.196 0.979333 0.588 0.588C0.979333 0.196 1.45 0 2 0H8L10 2H18C18.55 2 19.021 2.196 19.413 2.588C19.8043 2.97933 20 3.45 20 4V14C20 14.55 19.8043 15.021 19.413 15.413C19.021 15.8043 18.55 16 18 16H2ZM2 2V14H18V4H9.175L7.175 2H2Z"
      fill="#30374F"
    />
  </svg>
);

export default DriveFolderUploadIcon;

import React, { useMemo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import CLEF_PATH from '../../../../constants/path';
import { Subscription } from '@clef/shared/types';
import classNames from 'classnames';
import { useTypedSelector } from '@/hooks/useTypedSelector';

const useStyles = makeStyles(theme => ({
  planBadge: {
    borderRadius: 4,
    color: theme.palette.indigoBlue[600],
    backgroundColor: theme.palette.indigoBlue[100],
    padding: theme.spacing(1, 3),
    cursor: 'pointer',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '20px',
  },
  planBadgeWarning: {
    color: theme.palette.yellow[600],
    backgroundColor: theme.palette.yellow[100],
  },
}));

export type PlanBadgeProps = {
  subscription?: Subscription;
};

const PlanBadge: React.FC<PlanBadgeProps> = ({ subscription }) => {
  const styles = useStyles();
  const history = useHistory();
  const { currentTime } = useTypedSelector(state => state.login.user) ?? {};
  const now = currentTime ? new Date(currentTime).getTime() : Date.now();
  const [badgeText, warning] = useMemo(() => {
    const { status, meta, stripeCurrentPeriodEnd } = subscription ?? {};
    if (!status || meta?.plan !== 'freemium') {
      return [null, false];
    }
    if (status === 'trialing' && stripeCurrentPeriodEnd) {
      const days = Math.round(
        (new Date(stripeCurrentPeriodEnd).getTime() - now) / 24 / 60 / 60 / 1000,
      );
      const warning = days <= 3;
      if (days > 1) {
        return [t('Your free trial ends in {{days}} days', { days }), warning];
      } else if (days === 1) {
        return [t('Your free trial ends in 1 day'), warning];
      } else if (days === 0) {
        return [t('Your free trial ends today'), warning];
      } else {
        return [t('Your free trial has expired'), warning];
      }
    } else {
      return [t('Upgrade'), /* warning = */ false];
    }
  }, [now, subscription]);

  if (!badgeText) {
    return null;
  }

  return (
    <Box
      data-testid="plan-badge"
      className={classNames(styles.planBadge, warning && styles.planBadgeWarning)}
      onClick={() => {
        history.push(CLEF_PATH.plan);
      }}
      whiteSpace="nowrap"
    >
      {badgeText}
    </Box>
  );
};

export default PlanBadge;

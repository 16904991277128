import { TableCell } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';
import { withModelAnalysisTableCellProps } from './common';

export const AddEvaluationSetEmptyStateTableCell = withModelAnalysisTableCellProps(
  withStyles(theme =>
    createStyles({
      head: {
        backgroundColor: theme.palette.blue[50],
        borderStyle: 'solid',
        padding: theme.spacing(4),
        borderColor: theme.palette.greyModern[300],
        borderTopWidth: 0.5,
        borderBottomWidth: 0,
        borderLeftWidth: 0.5,
        borderRightWidth: 0.5,
      },
      body: {
        backgroundColor: theme.palette.blue[50],
        fontSize: 14,
        borderStyle: 'solid',
        padding: theme.spacing(4),
        borderColor: theme.palette.greyModern[300],
        borderTopWidth: 0,
        borderBottomWidth: 0.5,
        borderLeftWidth: 0.5,
        borderRightWidth: 0.5,
      },
    }),
  )(TableCell),
);

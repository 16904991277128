import React from 'react';

const ZoomOutIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.29171 2.79248H5.03371C3.77321 2.79248 2.75146 3.81423 2.75146 5.07473V7.33273C2.75146 7.84473 3.16696 8.26023 3.67871 8.26023C4.19071 8.26023 4.60621 7.84473 4.60621 7.33273V5.07473C4.60621 4.83873 4.79771 4.64723 5.03371 4.64723H7.29171C7.80346 4.64723 8.21921 4.23173 8.21921 3.71998C8.21921 3.20798 7.80346 2.79248 7.29171 2.79248Z"
      fill="#30374F"
    />
    <path
      d="M17.2514 7.33273V5.07473C17.2514 3.81423 16.2297 2.79248 14.9692 2.79248H12.7109C12.1992 2.79248 11.7837 3.20798 11.7837 3.71998C11.7837 4.23173 12.1992 4.64723 12.7109 4.64723H14.9692C15.2052 4.64723 15.3964 4.83873 15.3964 5.07473V7.33273C15.3964 7.84473 15.8122 8.26023 16.3239 8.26023C16.8357 8.26023 17.2514 7.84473 17.2514 7.33273Z"
      fill="#30374F"
    />
    <path
      d="M12.7109 17.2925H14.9692C16.2297 17.2925 17.2514 16.2707 17.2514 15.0102V12.7522C17.2514 12.2402 16.8357 11.8247 16.3239 11.8247C15.8122 11.8247 15.3964 12.2402 15.3964 12.7522V15.0102C15.3964 15.2462 15.2052 15.4377 14.9692 15.4377H12.7109C12.1992 15.4377 11.7837 15.8532 11.7837 16.365C11.7837 16.877 12.1992 17.2925 12.7109 17.2925Z"
      fill="#30374F"
    />
    <path
      d="M2.75146 12.7522V15.0102C2.75146 16.2707 3.77321 17.2925 5.03371 17.2925H7.29171C7.80346 17.2925 8.21921 16.877 8.21921 16.365C8.21921 15.8532 7.80346 15.4377 7.29171 15.4377H5.03371C4.79771 15.4377 4.60621 15.2462 4.60621 15.0102V12.7522C4.60621 12.2402 4.19071 11.8247 3.67871 11.8247C3.16696 11.8247 2.75146 12.2402 2.75146 12.7522Z"
      fill="#30374F"
    />
    <path
      d="M12.7515 9.04248H7.25146C6.69946 9.04248 6.25146 9.49048 6.25146 10.0425C6.25146 10.5945 6.69946 11.0425 7.25146 11.0425H12.7515C13.3032 11.0425 13.7515 10.5945 13.7515 10.0425C13.7515 9.49048 13.3032 9.04248 12.7515 9.04248Z"
      fill="#30374F"
    />
  </svg>
);

export default ZoomOutIcon;

import { createStyles, StyleRules } from '@material-ui/core/styles';

export const styles = (): StyleRules =>
  createStyles({
    dialogContent: {
      minHeight: '40vh',
      maxHeight: '40vh',
    },
    dialogActions: {
      paddingLeft: '24px',
      paddingRight: '24px',
      marginTop: '8px',
      marginBottom: '8px',
    },
    hr: {
      borderWidth: '1px',
      opacity: 0.5,
    },
    appendableField: {
      marginBottom: '1rem',
    },
    errorText: {
      margin: 0,
      color: 'red',
      marginLeft: '1rem',
    },
  });

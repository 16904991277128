import store, { ReduxState } from '../store';
import { FeatureToggleState } from '../store/types';

import { ClientFeatures } from '@clef/shared/features';
import { extractIdFromUrl } from './url_utils';

/**
 * Whether to show upcoming features.
 */
export function isInFeatureMode(): boolean {
  // temp hack which should be removed (pingyang)
  if (window.location.host.startsWith('dev')) {
    return true;
  }
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  return urlParams.get('f') === 'beta';
}

/**
 * @deprecated This function is deprecated to `useFeatureGateEnabled` instead
 */
export function isFeatureEnabledForUser(featureName: ClientFeatures): boolean {
  /**
   * TODO: getting the current state from here like this is handy but controversial and makes this
   * function return wrong values in unit tests as those use a different store.
   * Prefer using hooks from `src/hooks/useFeatureGate`.
   */
  const state = store?.getState();
  const enableFeaturesForUser: ClientFeatures[] = state?.enabledFeatures?.forUser! || [];
  return enableFeaturesForUser.includes(featureName);
}

/**
 * @deprecated This function is deprecated to `useFeatureGateEnabled` instead
 */
export function isFeatureEnabledForProject(featureName: ClientFeatures): boolean {
  /**
   * TODO: getting the current state from here like this is handy but controversial and makes this
   * function return wrong values in unit tests as those use a different store.
   * Prefer using hooks from `src/hooks/useFeatureGate`.
   */
  const state = store?.getState();
  const projectId = state?.project.selectedProjectId;
  if (!projectId) {
    return false;
  }
  const enableFeaturesForProject: ClientFeatures[] =
    (state?.enabledFeatures as FeatureToggleState).forProject?.[projectId] ?? [];
  return enableFeaturesForProject.includes(featureName);
}

export function _internal_isFeatureEnabledInState(
  featureName: ClientFeatures,
  state: ReduxState,
): boolean {
  // user level enabled
  const userLevelEnabled = state.enabledFeatures.forUser?.includes(featureName);
  if (userLevelEnabled) return true;
  // project level enabled
  const projectId = state.project.selectedProjectId;
  const projectLevelEnabled = projectId
    ? (state.enabledFeatures.forProject?.[projectId] ?? []).includes(featureName)
    : false;
  if (projectLevelEnabled) return true;
  // org level enabled
  let { orgId } = extractIdFromUrl();
  // this is used for frontend unit test
  if (!orgId) {
    orgId = state.login.user?.orgId;
  }
  const orgLevelEnabled = orgId
    ? (state.enabledFeatures.forOrg?.[orgId] ?? []).includes(featureName)
    : false;
  return orgLevelEnabled;
}

import React from 'react';
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { getDefectColor } from '../../../utils';
import { Defect } from '@clef/shared/types';
import { isEmpty } from 'lodash';
import DefectColor from '../../../pages/LabelingTask/components/DefectColor';
import cx from 'classnames';
import { useLabelingStyles } from '../../Labeling/labelingStyles';
import { BoxAnnotation } from '@clef/client-library';

interface BoxLabelPreviewListProps {
  annotations?: BoxAnnotation[];
  classes?: { labelPreviewContainer?: string };
  allDefects: Defect[];
  defaultExpanded?: boolean;
}

export const BoundingBoxLabelPreviewList: React.FC<BoxLabelPreviewListProps> = ({
  annotations,
  defaultExpanded,
  classes,
  allDefects,
}) => {
  const styles = useLabelingStyles();

  if (isEmpty(allDefects) || isEmpty(annotations)) {
    return (
      <Typography variant="body2" component="div" className={styles.hintTextNormal}>
        {t('No labels')}
      </Typography>
    );
  }

  return (
    <>
      {allDefects!.map((defect, idx) => {
        const annotationsForThisDefect =
          annotations?.filter(ann => ann?.defectId && ann.defectId === defect.id) || [];
        return annotationsForThisDefect.length ? (
          <Accordion
            square
            key={`${defect.id}-${idx}`}
            className={cx(styles.labelPreviewContainer, classes?.labelPreviewContainer)}
            defaultExpanded={defaultExpanded}
            data-testid={`${defect.name}-label-preview`}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className={!defaultExpanded ? styles.showExpandIconOnHover : undefined}
              data-testid="label-preview-defect-name"
            >
              <Grid container alignItems="center">
                <DefectColor color={getDefectColor(defect)} />
                <Box
                  title={defect.name}
                  className={styles.defectNameBox}
                >{`${defect.name} (${annotationsForThisDefect.length})`}</Box>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className={styles.labelPreviewItem}>
              <Box width="100%">
                {annotationsForThisDefect.map((ann, index) => (
                  <div
                    key={`${ann.key}-${index}`}
                    aria-selected={true}
                    className={cx(styles.labelPreviewItemText, 'selected')}
                    onMouseOver={e => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                    }}
                    onMouseOut={e => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                    }}
                  >
                    {t('{{index}} - x {{x}}, y {{y}}, {{width}} x {{height}}{{confidence}}', {
                      index: index + 1,
                      x: Math.round(ann.xMin),
                      y: Math.round(ann.yMin),
                      width: Math.round(ann.xMax - ann.xMin),
                      height: Math.round(ann.yMax - ann.yMin),
                      confidence: ann.confidence ? `, (${ann.confidence.toFixed(2)})` : '',
                    })}
                  </div>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ) : null;
      })}
    </>
  );
};

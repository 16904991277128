import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import { Typography, Paper, Divider, Grid, TextareaAutosize } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { useDefectBookEnhancedStyles } from '../defectBookEnhancedStyles';
import ReactMarkdown from 'react-markdown';
import {
  useFetchDefectBookOverviewApi,
  updateDefectBookOverviewApi,
} from '../../../hooks/api/useDefectBookApi';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { ApiResponseLoader } from '@clef/client-library';
import TerminologyExample from './TerminologyExample';
import { reorder } from '../../../utils';
import { DefectBookOverviewInfo } from '@clef/shared/types';
import CreateNewExampleButtonGroup from '../components/CreateNewExampleButtonGroup';
import { useDialog } from '../../../components/Layout/components/useDialog';

interface LabelingInstructionsProps {}

const LabelingInstructions: React.FC<LabelingInstructionsProps> = () => {
  const styles = useDefectBookEnhancedStyles();
  const { showConfirmationDialog } = useDialog();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const [contextText, setContextText] = useState('');
  const [
    defectBookOverview,
    defectBookOverviewLoading,
    defectBookOverviewError,
    mutateDefectBookOverview,
  ] = useFetchDefectBookOverviewApi(selectedProject?.id);

  const updateDefectBookOverview = useCallback(
    (newData: DefectBookOverviewInfo) => {
      mutateDefectBookOverview(() => newData);
      selectedProject && updateDefectBookOverviewApi(selectedProject.id, newData);
    },
    [mutateDefectBookOverview, selectedProject],
  );
  const [editContext, setEditContext] = useState(false);

  return (
    <ApiResponseLoader
      response={defectBookOverview}
      loading={defectBookOverviewLoading}
      error={defectBookOverviewError}
      defaultHeight={300}
    >
      {response => {
        const { termExamples, projectBackground } = response;
        return (
          <>
            <section className={styles.labelingInstructionSection}>
              <Grid container className={styles.labelingInstructionTitle} alignItems="center">
                <Typography variant="h2" className={styles.marginRight2}>
                  {t('Context')}
                </Typography>
                <Button
                  id={`${editContext ? 'save' : 'edit'}-labeling-instruction`}
                  color="primary"
                  onClick={() => {
                    if (editContext) {
                      // save
                      updateDefectBookOverview({
                        ...response,
                        projectBackground: contextText,
                      });
                    } else {
                      // edit
                      setContextText(projectBackground!);
                    }
                    setEditContext(prev => !prev);
                  }}
                >
                  {editContext ? t('Save') : t('Edit')}
                </Button>
              </Grid>
              {editContext ? (
                <TextareaAutosize
                  value={contextText}
                  onChange={e => {
                    e.persist();
                    setContextText(e?.target?.value);
                  }}
                  className={cx(styles.editorModeTextArea, styles.width100)}
                />
              ) : (
                <ReactMarkdown className={styles.markdownText}>{projectBackground!}</ReactMarkdown>
              )}
            </section>
            <section className={styles.labelingInstructionSection}>
              <Typography variant="h2" className={styles.labelingInstructionTitle}>
                {t('Labeling instructions')}
              </Typography>
              <Paper className={styles.terminologyContainer}>
                {termExamples.map((termId, index) => {
                  return (
                    <React.Fragment key={termId}>
                      <TerminologyExample
                        termId={termId}
                        onMoveBottom={
                          index !== termExamples.length - 1
                            ? () => {
                                const newExamples = reorder(termExamples, index, index + 1);
                                updateDefectBookOverview({
                                  ...response,
                                  termExamples: newExamples,
                                });
                              }
                            : undefined
                        }
                        onMoveTop={
                          index !== 0
                            ? () => {
                                const newExamples = reorder(termExamples, index, index - 1);
                                updateDefectBookOverview({
                                  ...response,
                                  termExamples: newExamples,
                                });
                              }
                            : undefined
                        }
                        onDelete={() =>
                          showConfirmationDialog({
                            color: 'secondary',
                            content: t('Are you sure you want to delete this example'),
                            confirmText: t('Delete example'),
                            onConfirm: () =>
                              updateDefectBookOverview({
                                ...response,
                                termExamples: termExamples.filter(id => id !== termId),
                              }),
                          })
                        }
                      />
                      {index !== termExamples.length - 1 && <Divider className={styles.divider} />}
                    </React.Fragment>
                  );
                })}
              </Paper>
              <CreateNewExampleButtonGroup
                onCreateNewExample={newExamples => {
                  updateDefectBookOverview({
                    ...response,
                    termExamples: [...termExamples, ...newExamples.map(example => example.id)],
                  });
                }}
              />
            </section>
          </>
        );
      }}
    </ApiResponseLoader>
  );
};

export default LabelingInstructions;

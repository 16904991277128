import React from 'react';
import {
  Defect,
  DatasetGroupOptions,
  MediaStatusType,
  FilterOptionName,
  MetadataTypeExtra,
} from '@clef/shared/types';
import { Grid, Typography } from '@material-ui/core';
import { useGetDatasetStatsQuery } from '@/serverStore/dataset';
import { useDefectBookEnhancedStyles } from '../defectBookEnhancedStyles';
import { Link } from 'react-router-dom';
import CLEF_PATH from '../../../constants/path';
import { AppliedFilterMapping } from '../../DataBrowser/dataBrowserState';
import { MediaStatusProps } from '../../../utils/media_status_props';

export interface DefectMediaStatsProps {
  defect: Defect;
}

const displayMediaStatus = [MediaStatusType.InTask, MediaStatusType.Approved];

export const mediaStatusFilterOption = (
  status: MediaStatusType,
  defectId: number,
): AppliedFilterMapping => ({
  [FilterOptionName.GroundTruthLabels]: {
    v: [defectId],
    t: 'column',
    o: 'CONTAINS_ANY',
  },
  [FilterOptionName.MediaStatus]: {
    v: [status],
    t: 'column',
    o: 'CONTAINS_ANY',
  },
});

const DefectMediaStats: React.FC<DefectMediaStatsProps> = ({ defect }) => {
  const styles = useDefectBookEnhancedStyles();
  const { data: mediaStatusStats } = useGetDatasetStatsQuery({
    selectOptions: {
      columnFilterMap: {
        [MetadataTypeExtra.Label]: { defect: { CONTAINS_ANY: [defect.id] } },
      },
      fieldFilterMap: {},
      selectedMedia: [],
      unselectedMedia: [],
      isUnselectMode: true,
    },
    groupOptions: [DatasetGroupOptions.MEDIA_STATUS],
  });
  return (
    <div className={styles.defectMediaStatsRoot}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={styles.defectMediaStats}
      >
        {displayMediaStatus.map(status => {
          const appliedFilter = mediaStatusFilterOption(status, defect.id);
          const filterSearchParam = new URLSearchParams({
            filters: encodeURIComponent(JSON.stringify(appliedFilter)),
          });
          return (
            <React.Fragment key={status}>
              <Typography variant="h4">{MediaStatusProps(status).text}</Typography>
              <Link to={`${CLEF_PATH.data.dataBrowser}?${filterSearchParam.toString()}`}>
                <Typography variant="h4" color="primary">
                  <strong>
                    {mediaStatusStats
                      ? mediaStatusStats.find(stat => stat.media_status === status)?.count ?? 0
                      : t('--')}
                  </strong>
                </Typography>
              </Link>
            </React.Fragment>
          );
        })}
      </Grid>
    </div>
  );
};

export default DefectMediaStats;

import React from 'react';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { LabelType } from '@clef/shared/types';
import InstantLearningDataBrowser from './instantLearningIndex';
import FREDataBrowser from './FREIndex';

/**
 * Temporary root component for the data browser.
 * Will be removed when we delete the feature gate.
 */
const DataBrowserRoot: React.FC<{}> = () => {
  const { labelType } = useGetSelectedProjectQuery().data ?? {};

  if (labelType === LabelType.SegmentationInstantLearning) {
    return <InstantLearningDataBrowser />;
  }

  return <FREDataBrowser />;
};

export default DataBrowserRoot;

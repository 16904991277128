import React from 'react';
import { Link, makeStyles, Typography } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import CloseIcon from '@material-ui/icons/Close';

import { AnimatedBox } from './AnimatedBox';

const useStyles = makeStyles(theme => ({
  container: {
    border: `1px solid ${theme.palette.greyModern[300]}`,
    borderRadius: 5,
    padding: theme.spacing(2, 5),
  },
  title: {
    fontWeight: 700,
    marginRight: theme.spacing(1),
  },
  marginRight3: {
    marginRight: theme.spacing(3),
  },
  deployLink: {
    fontWeight: 500,
    cursor: 'pointer',
  },
}));

export type NextStepBoxProps = {
  mounted?: boolean;
  open?: boolean;
  onClose?: () => void;
};

export const NextStepBox: React.FC<NextStepBoxProps> = ({ mounted, open, onClose }) => {
  const styles = useStyles();

  return (
    <AnimatedBox
      mounted={mounted}
      open={open}
      flexShrink={0}
      alignSelf="center"
      className={styles.container}
    >
      <Typography className={styles.title}>{t("What's next?")}</Typography>

      <Typography className={styles.marginRight3}>
        {t('Keep iterating to build better models, and {{deploy}} when you are ready.', {
          deploy: (
            <Link
              onClick={() => {
                window.open(
                  'https://support.landing.ai/docs/deployment-options',
                  '_blank',
                  'noopener,noreferrer',
                );
              }}
              className={styles.deployLink}
            >
              {t('deploy')}
            </Link>
          ),
        })}
      </Typography>

      <IconButton id="close-button" size="small" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </AnimatedBox>
  );
};

import React from 'react';

const InfoIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0003 1.6665C5.40033 1.6665 1.66699 5.39984 1.66699 9.99984C1.66699 14.5998 5.40033 18.3332 10.0003 18.3332C14.6003 18.3332 18.3337 14.5998 18.3337 9.99984C18.3337 5.39984 14.6003 1.6665 10.0003 1.6665ZM10.8337 14.1665H9.16699V9.1665H10.8337V14.1665ZM10.8337 7.49984H9.16699V5.83317H10.8337V7.49984Z"
      fill="#98C6FE"
    />
  </svg>
);

export default InfoIcon;

import { useEffect, useRef, useCallback } from 'react';

/**
 * A simple hook to tell if your component is still rendered
 */
const useMount = () => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return useCallback(() => mounted.current, []);
};

export default useMount;

import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useChipStyles = makeStyles(theme => ({
  chip: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.grey[200],
    fontSize: '14px',
    lineHeight: '16px',
    height: '24px',
    borderRadius: '5px',
    paddingLeft: theme.spacing(2),
  },
  label: {
    paddingLeft: 0,
    paddingRight: theme.spacing(2),
  },
  deleteIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.grey[600],
    marginLeft: 0,
    marginRight: theme.spacing(2),
  },
}));

export const TagChip: React.FC<{ name: string; onDelete?: () => void }> = ({ name, onDelete }) => {
  const styles = useChipStyles();
  return (
    <Chip
      label={name}
      classes={{
        label: styles.label,
      }}
      className={styles.chip}
      deleteIcon={<CloseIcon className={styles.deleteIcon} />}
      onDelete={onDelete ? () => onDelete() : undefined}
    />
  );
};

import { replaceSpecialChars } from '@clef/shared/utils';
import { BaseAPI } from './base_api';
import {
  Media,
  ApiResponse,
  ProjectId,
  DatasetId,
  MetadataFormattedValue,
  MediaCreateSpec,
  UploadType,
  RegisteredModelId,
} from '@clef/shared/types';
import { NO_SPLIT_DISPLAY_NAME } from '@clef/shared/constants';

const PICTOR_PREFIX = 'pictor';
const PICTOR_API_PREFIX = 'v1';

export enum StageType {
  External = 'External Named',
  Internal = 'Internal Named',
}

export enum SnowflakeSyncTaskStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  RUNNING = 'RUNNING',
}

export type SnowflakeSyncTaskMonitoringResponse = {
  taskId: string;
  projectId: ProjectId;
  taskSpec: {
    stage: string;
    prefix: string;
  };
  status: SnowflakeSyncTaskStatus;
  tsStarted?: string | null;
  tsEnded?: string | null;
  tsUpdated?: string | null;
  message?: string | null; // failure message
}[];

export type SnowflakeFolderDirectoryResponse = {
  id: string;
  name: string;
  children?: SnowflakeFolderDirectoryResponse[] | null;
  num_medias?: number;
};

export type GetSnowflakeDatabaseResponse = {
  database: string;
}[];

export type GetSnowflakeSchemaResponse = {
  database: string;
  schema: string;
}[];

export type GetSnowflakeStageResponse = {
  database: string;
  schema: string;
  stage: string;
  stage_id: string;
  stage_type: StageType | null;
}[];

export const isExternalStage = (stageType: StageType | null): boolean => {
  return stageType === StageType.External;
};

class PictorAPI extends BaseAPI {
  async upload(
    file: File,
    projectId: ProjectId,
    datasetId: DatasetId,
    submittedSplit?: string | null,
    tags?: string[],
    metadata?: MetadataFormattedValue,
    initialLabel?: MediaCreateSpec['initialLabel'],
    uploadType?: UploadType,
    skipMediaRegister?: boolean,
    modelId?: RegisteredModelId,
  ): Promise<ApiResponse<Media>> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('project_id', projectId.toString());
    formData.append('name', replaceSpecialChars(file.name, '_'));

    if (uploadType === UploadType.Dataset) {
      formData.append('dataset_id', datasetId!.toString());
    }

    const split = submittedSplit === NO_SPLIT_DISPLAY_NAME ? null : submittedSplit;
    if (split) {
      formData.append('split', split);
    }

    if (metadata) {
      formData.append('metadata', JSON.stringify(metadata));
    }

    if (tags) {
      formData.append('tags', JSON.stringify(tags));
    }

    if (initialLabel) {
      formData.append('initial_label', JSON.stringify(initialLabel));
    }
    if (skipMediaRegister) {
      formData.append('skip_media_register', skipMediaRegister.toString());
    }
    if (modelId) {
      formData.append('model_id', modelId);
    }

    return this.post('upload', formData);
  }

  async syncSnowflakeData(params: {
    stage: string;
    prefix: string;
    project_id: number;
    dataset_id: number;
  }) {
    return this.postJSON('snowflake/sync', params);
  }

  async getSnowflakeDatabase(): Promise<{ database: string }[]> {
    return this.get('snowflake/database', undefined, true);
  }

  async getSnowflakeSchema(params: {
    database: string;
  }): Promise<{ database: string; schema: string }[]> {
    return this.get('snowflake/schema', params, true);
  }

  async getSnowflakeStage(params: {
    database: string;
    schema: string;
  }): Promise<GetSnowflakeStageResponse> {
    return this.get('snowflake/stage', params, true);
  }

  async getSnowflakeFolderPathList(params: {
    stage: string;
  }): Promise<SnowflakeFolderDirectoryResponse> {
    return this.get('snowflake/list_folder', params, true);
  }

  async getSnowflakeSyncTaskList(
    projectId: ProjectId | undefined,
  ): Promise<SnowflakeSyncTaskMonitoringResponse> {
    return this.get(`snowflake/sync/monitor?project_id=${projectId}`, undefined, true);
  }
}

// Pictor endpoint example
// app.landing.ai/pictor/v1/upload
export default new PictorAPI(PICTOR_API_PREFIX, PICTOR_PREFIX);

import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, makeStyles, Paper, Typography } from '@material-ui/core';
import LabelingTools from '../InstantLearningLabeling/LabelingTools';
import {
  AnnotationChangeType,
  AnnotationSourceType,
  CanvasAnnotation,
  CanvasMode,
  hexToRgb,
  InstantLearningFitMediaPaddingTop,
  MediaInteractiveCanvas,
  MediaInteractiveCanvasProps,
  useThrottle,
  ToggleButton,
} from '@clef/client-library';
import {
  useAllClasses,
  useClassesById,
  useCurrentImageState,
  useCurrentProject,
  useHasTrained,
  useLabeledClassIds,
  useUpdateAnnotations,
  useZeroAuthInstantLearningState,
} from './state';
import {
  convertToCanvasAnnotations,
  getCanvasMode,
  isAboutSameColor,
  offscreenCanvasToCanvasAnnotation,
} from '@/components/Labeling/utils';
import { LabelingType } from '@clef/shared/types';
import {
  BitMapLabelingAnnotation,
  PureCanvasLabelingAnnotation,
  useLabelingState,
} from '@/components/Labeling/labelingState';
import { getDefectColor } from '@/utils';
import ClassSelector from './ClassSelector';
import TrainButton from './TrainButton';
import { Layer } from 'konva/types/Layer';
import LabelingTipBox from './LabelingTipBox';
import ZoomTools from '../InstantLearningLabeling/ZoomTools';
import {
  useSegmentationMaskLabelingAnnotations,
  useSegmentationPredictionAnnotations,
} from '../DataBrowser/utils';
import { isEmpty } from 'lodash';
import { useZeroAuthProjectApi } from '@/hooks/api/useZeroAuthApi';
import RunningMask, { loopTexts } from '../InstantLearningLabeling/RunningMask';
import LabelingTips from '../InstantLearningLabeling/LabelingTips';

const useStyles = makeStyles(theme => ({
  labelingToolsWrapper: {
    height: 48,
    backgroundColor: theme.palette.greyModern[200],
    paddingLeft: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  hoveredClassChip: {
    position: 'absolute',
    borderRadius: 8,
    padding: theme.spacing(2),
    width: 150,
    zIndex: 999,
    top: 24,
    right: 24,
  },
  labelingCanvasContainer: {
    position: 'relative',
    height: '100%',
    flex: '2 2 50%',
  },
  canvasWrapper: {
    background: theme.palette.greyModern[100],
  },
  zoomTools: {
    border: `1px solid ${theme.palette.greyModern[300]}`,
    borderRadius: 10,
    marginRight: theme.spacing(6),
  },
}));

export type ZeroAuthLabelingWrapperProps = {
  canvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
};

const ZeroAuthLabelingWrapper: React.FC<ZeroAuthLabelingWrapperProps> = props => {
  const { canvasRef } = props;
  const styles = useStyles();

  const { state, dispatch } = useZeroAuthInstantLearningState();
  const { currentProjectId, mediaIndex, labelingWrapper, training, mousePosInfo } = state;
  const { showGroundTruthLabels, showPredictionLabels } = labelingWrapper;

  const {
    state: {
      selectedDefect,
      toolMode,
      toolOptions: { erasing, strokeWidth, eraserWidth },
    },
    dispatch: dispatchLabelingState,
  } = useLabelingState();

  const classesById = useClassesById();
  const getClassColorById = useCallback(
    (defectId: number) => {
      return getDefectColor(classesById[defectId]);
    },
    [classesById],
  );
  const getClassNameById = useCallback((defectId: number) => {
    return String(defectId);
  }, []);

  const allClasses = useAllClasses();
  const {
    annotations,
    predictionAnnotations,
    mediaDetails,
    properties,
    gtSegPath,
    gtInitialized,
    predSegPath,
  } = useCurrentImageState() ?? {};
  const gtAnnotations = useSegmentationMaskLabelingAnnotations(gtSegPath ?? '', allClasses, 1);
  const gtCanvasAnnotations = useMemo(() => {
    return convertToCanvasAnnotations(
      LabelingType.DefectSegmentation,
      (annotations ?? gtAnnotations ?? []) as BitMapLabelingAnnotation[],
      getClassColorById,
      AnnotationSourceType.GroundTruth,
      getClassNameById,
    );
  }, [gtAnnotations, annotations, getClassColorById, getClassNameById]);

  useEffect(() => {
    if (gtSegPath && gtAnnotations && !gtInitialized) {
      dispatch(draft => {
        draft.mediaStatesByIndex[draft.mediaIndex].annotations = gtAnnotations;
        draft.mediaStatesByIndex[draft.mediaIndex].gtInitialized = true;
      });
      canvasRef.current?.setAnnotations(prev => {
        const prevPredAnnotations = prev.filter(
          ann => ann.group === AnnotationSourceType.Prediction,
        );
        return [...gtCanvasAnnotations, ...prevPredAnnotations];
      }, AnnotationChangeType.Reset);
    }
  }, [
    canvasRef,
    dispatch,
    getClassColorById,
    getClassNameById,
    gtAnnotations,
    gtCanvasAnnotations,
    gtInitialized,
    gtSegPath,
  ]);

  const predAnnotations = useSegmentationPredictionAnnotations(
    predSegPath ?? '',
    allClasses,
    /* threshold */ 0.5,
    /* opacity */ 0.5,
  );
  const predCanvasAnnotations = useMemo(() => {
    return (predictionAnnotations ?? []).map(
      ann =>
        ({
          ...offscreenCanvasToCanvasAnnotation(
            ann as PureCanvasLabelingAnnotation,
            /* opacity */ 0.8,
          ),
          defectId: ann.defectId,
          group: AnnotationSourceType.Prediction,
        } as CanvasAnnotation),
    );
  }, [predictionAnnotations]);
  useEffect(() => {
    if (predSegPath && predAnnotations) {
      dispatch(draft => {
        draft.mediaStatesByIndex[draft.mediaIndex].predictionAnnotations = predAnnotations;
      });

      canvasRef.current?.setAnnotations(prev => {
        const prevGtAnnotations = prev.filter(ann => ann.group !== AnnotationSourceType.Prediction);
        return [
          // this is triggered when prediction is completed, created is now reset
          ...prevGtAnnotations.map(ann => ({ ...ann, created: false })),
          ...predCanvasAnnotations,
        ];
      }, AnnotationChangeType.Reset);
    }
  }, [canvasRef, dispatch, predAnnotations, predCanvasAnnotations, predSegPath]);

  // Merge GT and prediction annotations and convert to canvas annotations
  const canvasAnnotations = useMemo(() => {
    return [...gtCanvasAnnotations, ...predCanvasAnnotations];
  }, [gtCanvasAnnotations, predCanvasAnnotations]);

  const [, isProjectLoading] = useZeroAuthProjectApi(currentProjectId);
  const isLoadingPredictions =
    isProjectLoading ||
    (!!predSegPath && (isEmpty(predictionAnnotations) || isEmpty(predAnnotations)));

  const canvasShapeProps = useMemo(
    () => ({
      color: erasing ? 'transparent' : selectedDefect ? getDefectColor(selectedDefect) : undefined,
      segmentationOpacity: 0.5,
      lineStrokeWidth: erasing ? eraserWidth : strokeWidth,
    }),
    [eraserWidth, erasing, selectedDefect, strokeWidth],
  );

  const [hasCreatedLabel, setHasCreatedLabel] = useState(false);
  const updateAnnotations = useUpdateAnnotations();
  const onAnnotationChanged = useCallback(
    (annotations: CanvasAnnotation[], changeType: AnnotationChangeType, layer: Layer | null) => {
      setHasCreatedLabel(!!annotations.find(anno => anno.created));
      updateAnnotations(annotations, changeType, layer);
    },
    [updateAnnotations],
  );
  const hasTrained = useHasTrained();

  const containerRef = useRef<HTMLDivElement>(null);
  const tipBoxRef = useRef<HTMLDivElement>(null);

  const labeledClassIds = useLabeledClassIds('all');

  const handleMouseMoveInner: MediaInteractiveCanvasProps['onMouseMove'] = (e, info) => {
    const { predictColor } = info ?? {};
    dispatch(draft => {
      if (e) {
        const containerWidth = containerRef.current?.clientWidth ?? Infinity;
        const tipBoxRefWidth = tipBoxRef.current?.clientWidth ?? Infinity;
        const tipBoxRefHeight = tipBoxRef.current?.clientHeight ?? Infinity;
        draft.mousePos = {
          x: Math.min(e.evt.offsetX, containerWidth - tipBoxRefWidth - 8),
          y: Math.max(e.evt.offsetY, tipBoxRefHeight + 8),
        };
        if (labeledClassIds.size > 1) {
          // Only show mousePosInfo after you have labeled first 2 labels
          draft.mousePosInfo = {
            predictClass: Object.values(classesById).find(classEntry =>
              isAboutSameColor(hexToRgb(classEntry.color), predictColor),
            ),
          };
        }
      } else {
        draft.mousePos = undefined;
      }
    });
  };
  const handleMouseMove: MediaInteractiveCanvasProps['onMouseMove'] = useThrottle(
    handleMouseMoveInner,
    48,
  );

  const currentLabeledClassIds = useLabeledClassIds('current_image');
  const showTips = useMemo(() => {
    if (hasTrained) {
      return false;
    }
    return currentLabeledClassIds.size < 2;
  }, [currentLabeledClassIds.size, hasTrained]);

  const project = useCurrentProject();

  return (
    <Box
      flex="1 1 100%"
      overflow="hidden"
      display="flex"
      flexDirection="column"
      alignItems="stretch"
    >
      <Box className={styles.labelingToolsWrapper} display="flex">
        <LabelingTools
          mediaCanvasRef={canvasRef}
          hasAnnotation={true}
          deleteOption={AnnotationChangeType.DeleteGroundTruth}
        />
        <div style={{ flex: 1 }}></div>
        {hasTrained && (
          <Box display="flex" marginLeft="auto">
            <ToggleButton
              id="toggle-ground-truth-labels"
              isOn={showGroundTruthLabels}
              onToggle={() => {
                dispatch(draft => {
                  draft.labelingWrapper.showGroundTruthLabels =
                    !draft.labelingWrapper.showGroundTruthLabels;
                });
              }}
            >
              {t('Labels')}
            </ToggleButton>
            <ToggleButton
              id="toggle-predictions-labels"
              isOn={showPredictionLabels}
              onToggle={() =>
                dispatch(draft => {
                  draft.labelingWrapper.showPredictionLabels =
                    !draft.labelingWrapper.showPredictionLabels;
                })
              }
            >
              {t('Predictions')}
            </ToggleButton>
          </Box>
        )}
        <Box className={styles.zoomTools}>
          <ZoomTools
            setZoomScale={zoomScale => {
              canvasRef.current?.setZoomScale(zoomScale, undefined, /* skip callback */ true);
              dispatchLabelingState(draft => {
                draft.toolOptions.zoomScale = zoomScale;
              });
            }}
          />
        </Box>
      </Box>
      {mediaDetails && (
        <Box position="relative" flex="1 1 100%" overflow="hidden">
          <ClassSelector />
          {mousePosInfo?.predictClass && (
            <Paper className={styles.hoveredClassChip}>
              <Box alignItems="center" display="flex">
                <Box
                  width={20}
                  height={20}
                  bgcolor={getDefectColor(mousePosInfo.predictClass)}
                  marginRight={2}
                  borderRadius={8}
                  flexShrink={0}
                />
                <Typography variant="body1">{mousePosInfo.predictClass.name}</Typography>
              </Box>
            </Paper>
          )}
          <Box width="100%" height="100%" display="flex" flexWrap="nowrap" alignItems="stretch">
            <div className={styles.labelingCanvasContainer} ref={containerRef}>
              <RunningMask
                show={training || isLoadingPredictions}
                blur={!!training}
                texts={isLoadingPredictions ? loopTexts : undefined}
              />
              <MediaInteractiveCanvas
                ref={canvasRef}
                key={`proj_${currentProjectId}-img_${mediaIndex}`}
                className={styles.canvasWrapper}
                imageSrc={mediaDetails.url}
                properties={properties}
                annotations={canvasAnnotations}
                mode={!toolMode ? CanvasMode.Pan : getCanvasMode(toolMode)}
                enablePinchScrollZoom
                freeDrag
                shapeProps={canvasShapeProps}
                enableCrosshair
                enableFitPadding
                enableContour
                enablePixelation
                onAnnotationChanged={onAnnotationChanged}
                onMouseMove={handleMouseMove}
                showGroundTruthLabels={showGroundTruthLabels ? true : 'created-only'}
                hidePredictions={!showPredictionLabels}
                onZoomScaleChange={zoomScale => {
                  dispatchLabelingState(draft => {
                    draft.toolOptions.zoomScale = zoomScale;
                  });
                }}
                onFitZoomScaleChange={fitZoomScale => {
                  dispatchLabelingState(draft => {
                    draft.toolOptions.fitZoomScale = fitZoomScale;
                  });
                }}
                fitMediaPaddingTop={InstantLearningFitMediaPaddingTop}
              />
              <TrainButton disabled={isLoadingPredictions} />
              <LabelingTipBox
                tipBoxRef={tipBoxRef}
                hasCreatedLabel={hasCreatedLabel}
                hasPredictionLabel={!!predictionAnnotations?.length}
              />
            </div>
          </Box>
          {showTips && currentProjectId && (
            <LabelingTips
              currentStep={currentLabeledClassIds.size}
              guideImages={project?.labelingGuideImages}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ZeroAuthLabelingWrapper;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import { withStyles, WithStyles } from '@material-ui/styles';
import styles from './styles';
import { LegacyReduxStates, MetadataState } from '../../store/types';
import { METADATA_TYPE } from '../../constants/metadata_constants';
import { MetadataField, MetadataType } from '@clef/shared/types';
import { noop } from '../../utils/index';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import ChipsContainer from './ChipsContainer';
import {
  useAddMetadataFieldMutation,
  useUpdateMetadataFieldMutation,
} from '@/serverStore/metadata';
import { useTypedSelector } from '@/hooks/useTypedSelector';

interface MetadataDialogProps extends WithStyles<typeof styles> {
  open: boolean;
  closeDialog: () => void;
  fieldInfo?: MetadataField;
  fieldList: MetadataState['fieldList'];
}

const MetadataDialog: React.FunctionComponent<MetadataDialogProps> = ({
  classes,
  open,
  closeDialog,
  fieldInfo,
  fieldList,
}) => {
  const [name, setName] = useState(fieldInfo ? fieldInfo.name : null);
  const [type, setType] = useState(fieldInfo ? fieldInfo.type : MetadataType.Text);
  const [allowMultiple, setAllowMultiple] = useState(fieldInfo ? fieldInfo.allowMultiple : false);
  const [valueFlexible, setValueFlexible] = useState(fieldInfo ? fieldInfo.valueFlexible : true);
  const [predefinedChoices, setPredefinedChoices] = useState<string[] | null>(
    fieldInfo ? (fieldInfo.predefinedChoices as string[]) : null,
  );
  const [disabled, setDisabled] = useState(fieldInfo ? false : true);
  const updateMetadataField = useUpdateMetadataFieldMutation();
  const addNewField = useAddMetadataFieldMutation();
  const selectedProjectId = useTypedSelector(state => state.project.selectedProjectId);
  /**
   * For edit, to disable some part which cannot be modified
   */
  let typeDisabled, valueDisabled;
  let allowMultipleDisabled = false;
  if (fieldInfo) {
    // Edit field
    allowMultipleDisabled = true;
    typeDisabled = true;
    if (fieldInfo.valueFlexible) {
      // cannot change from random tags to predefined_values
      valueDisabled = true;
    }
  }
  useEffect(() => {
    const names = new Set(fieldList.map(metadata => metadata.name));
    ValidatorForm.addValidationRule('isNameRegexValid', name => {
      if (!name || name.length === 0 || name.length > 256) return false;
      const reg = /([^A-Za-z0-9-@#$/_ ])+/g;
      return !name.match(reg);
    });
    ValidatorForm.addValidationRule('isNameUnique', name => {
      if (fieldInfo && name === fieldInfo.name) {
        return true;
      }
      return !names.has(name);
    });
  }, [fieldInfo, fieldList]);

  function validatorListener(result: boolean) {
    setDisabled(!result);
  }

  function onDeleteFunction(value: string, index: number) {
    if (predefinedChoices && predefinedChoices[index] === value) {
      const filteredValues = Array.from(predefinedChoices);
      filteredValues.splice(index, 1);
      setPredefinedChoices(filteredValues);
    }
  }

  function onAddFunction(value: string) {
    const newPredefinedChoices = predefinedChoices ? Array.from(predefinedChoices) : [];
    newPredefinedChoices.push(value);
    setPredefinedChoices(newPredefinedChoices);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeDialog}
        maxWidth="lg"
        fullWidth={true}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t('Add/Edit Metadata Field')}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={4}>
              <p>{t('Name')}</p>
            </Grid>
            <Grid item xs={8}>
              <ValidatorForm onSubmit={noop}>
                <TextValidator
                  name="Name"
                  placeholder={t('Name')}
                  margin="normal"
                  variant="outlined"
                  label={t('Name')}
                  onChange={(
                    event: React.ChangeEvent<{
                      value: unknown;
                    }>,
                  ) => {
                    // @ts-ignore
                    setName(event.target.value);
                  }}
                  value={name || ''}
                  fullWidth
                  autoFocus
                  InputProps={{
                    'data-testid': 'metadata-dialog-name',
                    classes: {
                      underline: classes ? classes.textFieldUnderline : undefined,
                      input: classes ? classes.textField : undefined,
                    },
                  }}
                  validators={['isNameUnique', 'isNameRegexValid']}
                  errorMessages={[
                    t('Name is not valid! Name should be unique in this project'),
                    t(
                      'Name is not valid! its length should be 0-256 and only contains A-Z, a-z, 0-9, -@#$/_',
                    ),
                  ]}
                  type="text"
                  validatorListener={validatorListener}
                />
              </ValidatorForm>
            </Grid>
            <Grid item xs={4}>
              <p>{t('Type')}</p>
            </Grid>
            <Grid item xs={8}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  className="cy-metadata-type-selector"
                  native
                  value={type}
                  disabled={typeDisabled}
                  onChange={(
                    event: React.ChangeEvent<{
                      value: unknown;
                    }>,
                  ) => {
                    // @ts-ignore
                    setType(event.target.value);
                  }}
                >
                  <option value={METADATA_TYPE.TEXT}>{t('Text')}</option>
                  <option value={METADATA_TYPE.NUMBER}>{t('Number')}</option>
                  <option value={METADATA_TYPE.BOOLEAN}>{t('Boolean')}</option>
                </Select>
              </FormControl>
            </Grid>
            {type === METADATA_TYPE.BOOLEAN ? null : (
              <Grid container>
                <Grid item xs={4}>
                  <p>{t('Value')}</p>
                </Grid>
                <Grid item xs={8}>
                  <FormControl
                    variant="outlined"
                    className={classes ? classes.formControl : undefined}
                  >
                    <Select
                      className="cy-metadata-value-selector"
                      native
                      value={valueFlexible ? 'true' : 'false'}
                      disabled={valueDisabled}
                      onChange={(
                        event: React.ChangeEvent<{
                          value: unknown;
                        }>,
                      ) => {
                        if (event.target.value === 'true') {
                          setValueFlexible(true);
                        } else {
                          setValueFlexible(false);
                        }
                      }}
                    >
                      <option value={'false'}>{t('Pre-Defined Choice')}</option>
                      <option value={'true'}>{t('Tags')}</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <p>{t('Allow Multiple Selection')}</p>
                </Grid>
                <Grid item xs={8}>
                  <FormControl
                    variant="outlined"
                    className={classes ? classes.formControl : undefined}
                  >
                    <RadioGroup
                      value={allowMultiple ? 'Yes' : 'No'}
                      onChange={(
                        event: React.ChangeEvent<{
                          value: unknown;
                        }>,
                      ) => {
                        if (event.target.value === 'Yes') {
                          setAllowMultiple(true);
                        } else {
                          setAllowMultiple(false);
                        }
                      }}
                    >
                      <Grid container>
                        <Grid>
                          <FormControlLabel
                            disabled={allowMultipleDisabled}
                            value="Yes"
                            control={
                              <Radio color="primary" className="cy-allow-multiple-radio-yes" />
                            }
                            labelPlacement="start"
                            label={'Yes'}
                          />
                        </Grid>
                        <Grid>
                          <FormControlLabel
                            disabled={allowMultipleDisabled}
                            value="No"
                            control={
                              <Radio color="primary" className="cy-allow-multiple-radio-no" />
                            }
                            labelPlacement="start"
                            label={'No'}
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {valueFlexible ? null : (
                  <Grid container>
                    <Grid item xs={4}>
                      <p>{t('Acceptable Inputs')}</p>
                    </Grid>
                    <Grid item xs={8}>
                      <ChipsContainer
                        maxHeight={300}
                        maxWidth={500}
                        values={!valueFlexible && predefinedChoices ? predefinedChoices : []}
                        disabledValues={
                          fieldInfo ? (fieldInfo.predefinedChoices as string[]) : undefined
                        }
                        readOnly={false}
                        type={type}
                        onAddFunction={onAddFunction}
                        onDeleteFunction={onDeleteFunction}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            id="close-metadata-dialog"
            onClick={() => {
              closeDialog();
            }}
            color="primary"
          >
            {t('Cancel')}
          </Button>
          <Button
            id="save-metadata"
            disabled={disabled}
            className="cy-metadata-save"
            data-testid={'metadata-save-btn'}
            onClick={() => {
              if (!selectedProjectId) return;
              if (fieldInfo && name && type) {
                updateMetadataField.mutate(
                  {
                    id: fieldInfo.id,
                    name,
                    predefinedChoices,
                    valueFlexible,
                    allowMultiple,
                    projectId: selectedProjectId,
                  },
                  {
                    onSuccess: () => closeDialog(),
                  },
                );
              } else if (name && type) {
                addNewField.mutate(
                  {
                    name,
                    type,
                    allowMultiple,
                    valueFlexible,
                    predefinedChoices,
                    projectId: selectedProjectId,
                  },
                  {
                    onSuccess: () => closeDialog(),
                  },
                );
              }
            }}
            color="primary"
          >
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: LegacyReduxStates) => ({
  fieldList: state.metadata.fieldList,
});

export default connect(mapStateToProps)(withStyles(styles)(MetadataDialog));

import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { useTypographyStyles } from '@clef/client-library/src/themes/typography';
import LandingLogo from '@/images/logo_withoutText_color.svg';
import { Button, IconButton, QrCode } from '@clef/client-library';
import { usePublicEndpointApi } from '@/hooks/api/usePublicEndpointApi';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { formatDistanceStrict } from 'date-fns';
import Cached from '@material-ui/icons/Cached';
import classNames from 'classnames';
import { useChangeEndpointState } from './utils';

const useStyles = makeStyles(theme => ({
  divider: {
    borderTop: `1px solid ${theme.palette.greyModern[300]}`,
    width: '100%',
  },
  expiredMask: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(2px)',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  refreshButton: {
    backgroundColor: 'white!important',
    border: `1px solid ${theme.palette.greyModern[200]}`,
    transitionProperty: 'background-color, transform',
    '&:hover': {
      backgroundColor: theme.palette.greyModern[50] + '!important',
      transform: 'rotate(-180deg)',
    },
  },
  expiredText: {
    color: theme.palette.error.main,
  },
}));

export type MobileUploadDialogContentEnabledProps = {};

const MobileUploadDialogContentEnabled: React.FC<MobileUploadDialogContentEnabledProps> = () => {
  const styles = useStyles();
  const typographyStyles = useTypographyStyles();
  const projectId = useTypedSelector(state => state.project.selectedProjectId);

  const [endpoint] = usePublicEndpointApi(
    projectId ? { projectId, type: 'image_upload' } : undefined,
  );
  const now = new Date();
  const nowMs = now.getTime();
  const expireAt = new Date(endpoint?.expireAt ?? 0);
  const expired = nowMs >= expireAt.getTime();
  const expireIn = formatDistanceStrict(now, expireAt);
  const [, rerender] = useState(false);

  // re-calculate the expiration every second so that user can know expiration status in real time
  useEffect(() => {
    if (!endpoint?.expireAt || expired) {
      return;
    }
    const timerId = setTimeout(() => rerender(prev => !prev), 1000);
    return () => clearTimeout(timerId);
    // Intentionally add nowMs to trigger the next timeout
  }, [endpoint?.expireAt, expired, nowMs]);

  const { changeEndpointState, changing } = useChangeEndpointState();

  if (!endpoint) {
    return null;
  }

  return (
    <>
      <Box textAlign="center" className={typographyStyles.h2_semibold} marginTop={3}>
        {t('Scan and upload from your phone!')}
      </Box>
      <Box marginTop={4} position="relative">
        <QrCode
          value={`${window.location.origin}/public/image_upload/${endpoint.endpointId}`}
          size={160}
          logo={LandingLogo}
        />
        {expired && (
          <>
            <Box className={styles.expiredMask}>
              <IconButton
                id="refresh-image-upload-qrcode"
                className={styles.refreshButton}
                tooltip={t('Refresh QR Code')}
                // @ts-ignore
                tooltipProps={{ PopperProps: { disablePortal: true } }}
                disabled={changing}
                onClick={() => changeEndpointState(true)}
              >
                {changing ? <CircularProgress size={35} /> : <Cached fontSize="large" />}
              </IconButton>
            </Box>
          </>
        )}
      </Box>
      {endpoint.expireAt && (
        <>
          <Box
            marginTop={4}
            textAlign="center"
            className={classNames(typographyStyles.body_regular, expired && styles.expiredText)}
          >
            {expired
              ? t('The QR code has expired')
              : t(`The QR code will expire in {{time}}`, { time: expireIn })}
          </Box>
        </>
      )}
      <Box marginTop={4}>
        <Button
          id="turn-off-image-upload-endpoint"
          color="primary"
          disabled={changing}
          startIcon={changing ? <CircularProgress size={20} /> : undefined}
          onClick={() => changeEndpointState(expired ? true : false)}
        >
          {expired ? t('Get New QR Code') : t('Turn It Off')}
        </Button>
      </Box>
    </>
  );
};

export default MobileUploadDialogContentEnabled;

import { OptionsObject } from 'notistack';

export const METADATA_TYPE = {
  TEXT: 'text',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
};

export const ERROR_SNACKBAR_STYLE: OptionsObject = {
  variant: 'error',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

export const WARNIGN_SNACKBAR_STYLE: OptionsObject = {
  variant: 'warning',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

export const INFO_SNACKBAR_STYLE: OptionsObject = {
  variant: 'info',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

export const SUCCESS_SNACKBAR_STYLE: OptionsObject = {
  variant: 'success',
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

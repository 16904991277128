import { MutableRefObject, useEffect } from 'react';

export type ClickOutsideHandler = (e: MouseEvent | TouchEvent) => any;
export type ClickOutsideEvents = {
  onClickOutside?: ClickOutsideHandler;
};

/**
 * Listen to click event outside a component.
 * For better performance, please wrap the handler in useCallback before pass in.
 * @param ref ref to the component
 * @param handler handler when clicked outside the component
 */
export const useClickOutside = (
  ref: MutableRefObject<HTMLElement | null>,
  handler: ClickOutsideHandler,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core';
import { NEW_DRAWER_WIDTH } from '@clef/shared/constants';

const BOTTOM_DISTANCE = 40;

const useStyles = makeStyles(() => ({
  bottomDrawer: {
    width: `calc(100vw - ${NEW_DRAWER_WIDTH}px)`,
    position: 'fixed',
    left: NEW_DRAWER_WIDTH,
    zIndex: 300,
    transition: 'all 0.3s ease',
  },
}));

export interface BottomDrawerProps {
  className?: string;
  show: boolean;
}

const BottomDrawer: React.FC<BottomDrawerProps> = ({ children, className, show }) => {
  const styles = useStyles();

  const [currentShow, setCurrentShow] = useState(false);
  const [stickyStyles, setStickStyles] = useState<React.CSSProperties>({
    bottom: 0,
    opacity: 0,
  });

  useEffect(() => {
    // If current we don't show, but next prop says we should
    if (!currentShow && show) {
      // first set sticky position to be topped and hidden
      setStickStyles({
        bottom: 0,
        opacity: 0,
      });
      setCurrentShow(true);
      // After down rAFs (so browser has painted), set correct position, set currentSticky=true
      // this will trigger animation
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setStickStyles({
            bottom: BOTTOM_DISTANCE,
            opacity: 1,
          });
        });
      });
    }

    // If current we show sticky header, but next prop says we should not
    if (currentShow && !show) {
      // first set sticky position to be topped and hidden, this will trigger animation
      setStickStyles({
        bottom: 0,
        opacity: 0,
      });
      // After animation finished, set currentSticky=false, this will remove sticky header from DOM
      setTimeout(() => {
        setCurrentShow(false);
      }, 500);
    }
  }, [currentShow, show]);

  if (!currentShow) return null;

  return (
    <div style={stickyStyles} className={cx(styles.bottomDrawer, className)}>
      {children}
    </div>
  );
};

export default BottomDrawer;

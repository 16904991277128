import React from 'react';
import { SelectMediaOption, DatasetGroupOptions } from '@clef/shared/types';
import { ChartContainer, ApiResponseLoader, PieChart } from '@clef/client-library';
import { useGetDatasetStatsQuery } from '@/serverStore/dataset';
import { splitColors } from '@clef/client-library';
import { statsCardDataKeyCompareFn } from '../../utils/str_utils';

export interface MediaSplitDoughnutChartProps {
  title?: string;
  selectMediaOptions: SelectMediaOption;
}

const MediaSplitDoughnutChart: React.FC<MediaSplitDoughnutChartProps> = ({
  title,
  selectMediaOptions,
}) => {
  const {
    data: mediaGroupBySplit,
    isLoading: mediaGroupBySplitLoading,
    error: mediaGroupBySplitError,
  } = useGetDatasetStatsQuery({
    selectOptions: selectMediaOptions,
    groupOptions: [DatasetGroupOptions.SPLIT],
  });

  const ChartComponent = (
    <ApiResponseLoader
      response={mediaGroupBySplit}
      loading={mediaGroupBySplitLoading}
      error={mediaGroupBySplitError}
      defaultHeight={300}
    >
      {mediaGroupBySplitLoaded => {
        const splitStatsFormatted = mediaGroupBySplitLoaded
          // https://stackoverflow.com/questions/53420055/error-while-sorting-array-of-objects-cannot-assign-to-read-only-property-2-of/53420326
          .slice()
          .map(({ split, count }, index) => ({
            name: split || t('unassigned'),
            value: count,
            color: splitColors[index % splitColors.length],
          }))
          .filter(({ value }) => value > 0)
          .sort((a, b) => statsCardDataKeyCompareFn(a.name, b.name));

        return <PieChart chartData={splitStatsFormatted} variant="doughnut" />;
      }}
    </ApiResponseLoader>
  );

  return title ? <ChartContainer title={title}>{ChartComponent}</ChartContainer> : ChartComponent;
};

export default MediaSplitDoughnutChart;

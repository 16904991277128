import React from 'react';
import {
  Badge,
  createStyles,
  makeStyles,
  MenuItem,
  MenuList,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Button, useLocalStorage } from '@clef/client-library';
import { Dropdown } from '@clef/client-library';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import CLEF_PATH from '@/constants/path';
import { useHistory } from 'react-router';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useGetProjectModelListQuery } from '@/serverStore/projectModels';
import { isModelTrainingFailed } from '@/store/projectModelInfoState/utils';
import { ProjectId } from '@clef/shared/types';

const useStyles = makeStyles(theme => ({
  moreButton: {
    padding: theme.spacing(2.5),
    minWidth: 'auto!important',
    width: 36,
  },
  badgeTooltipPlaceholder: {
    color: theme.palette.secondary.main,
  },
}));

export type ModelPerformanceMoreOptionsProps = {};

const StyledBadge = withStyles((_theme: Theme) =>
  createStyles({
    badge: {
      transform: 'scale(0.6) translate(70%, -70%)',
    },
  }),
)(Badge);

const ModelPerformanceMoreOptions: React.FC<ModelPerformanceMoreOptionsProps> = () => {
  const styles = useStyles();
  const history = useHistory();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const [failedJobLastSeenDateMap, setFailedJobLastSeenDateMap] =
    useLocalStorage<Record<ProjectId, string>>('failedJobLastSeenDate');
  const userLastSeenFailedJobDate =
    selectedProject && failedJobLastSeenDateMap
      ? failedJobLastSeenDateMap[selectedProject?.id]
      : null;
  const { data: modelListData } = useGetProjectModelListQuery();
  const getLastWeekDate = () => {
    const today = new Date();
    return new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() - 7);
  };
  const failedModels = modelListData
    ?.filter(model => isModelTrainingFailed(model.status))
    .filter(model => {
      if (!model.createdAt) return false;
      const modelCreatedDate = new Date(model.createdAt);
      return modelCreatedDate > getLastWeekDate();
    });
  const lastFailedJob = failedModels?.length
    ? failedModels?.sort((a, b) => {
        return a.createdAt! < b.createdAt! ? 1 : -1;
      })[0]
    : null;
  const isLastFailedJobNotSeen = (lastFailedJobDate: string, userLastSeenDate: string): boolean => {
    return new Date(lastFailedJobDate) >= new Date(userLastSeenDate);
  };
  const userHasFailedJobNotSeen =
    lastFailedJob &&
    (!userLastSeenFailedJobDate ||
      isLastFailedJobNotSeen(lastFailedJob.createdAt!, userLastSeenFailedJobDate)); // whether the last failed job has been seen
  return (
    <StyledBadge
      color="secondary"
      invisible={!userHasFailedJobNotSeen}
      overlap="circular"
      badgeContent={
        <Tooltip arrow placement="top" title="View new failed jobs in 7 days">
          <Typography className={styles.badgeTooltipPlaceholder}>{t('0')}</Typography>
        </Tooltip>
      }
    >
      <Dropdown
        placement="left"
        dropdown={
          <MenuList>
            {/* temporary hide the entrance */}
            {/* <MenuItem onClick={() => history.push(CLEF_PATH.modelsV2.comparisonReports)}>
              {t('Model Comparison Reports')}
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                selectedProject &&
                  setFailedJobLastSeenDateMap({
                    ...failedJobLastSeenDateMap,
                    [selectedProject.id]: new Date().toString(),
                  });
                history.push(CLEF_PATH.modelsV2.failedJobs);
              }}
            >
              {t('Failed Jobs')}
            </MenuItem>
          </MenuList>
        }
      >
        <Button
          id="model-performance-more-options-button"
          variant="outlined"
          className={styles.moreButton}
        >
          <MoreHoriz />
        </Button>
      </Dropdown>
    </StyledBadge>
  );
};

export default ModelPerformanceMoreOptions;

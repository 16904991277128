import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@clef/client-library';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVert from '@material-ui/icons/MoreVert';
import Delete from '@material-ui/icons/Delete';
import RestoreFromTrash from '@material-ui/icons/RestoreFromTrash';
import { useSnackbar } from 'notistack';
import { Member } from './MemberTable';
import { LegacyReduxStates, LoginState } from '../../../store/types';
import { changeUserActivation } from '../../../hooks/api/useUserApi';
import { UserId, UserStatus } from '@clef/shared/types';
import { fetchUsers } from '../../../store/user_store';

interface ReduxStateProps {
  currentUser: LoginState['user'];
}
interface ReduxStateActions {
  fetchUsers: (...args: any[]) => any;
}
interface OwnProps {
  member: Member;
}

type Props = OwnProps & ReduxStateProps & ReduxStateActions;

const MemberSettingSelector: React.FC<Props> = ({ member, currentUser, fetchUsers }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { enqueueSnackbar } = useSnackbar();

  // @ts-ignore
  function handleClick(event): void {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(): void {
    setAnchorEl(null);
  }

  async function handleDeactivateUser(userId: UserId): Promise<void> {
    changeUserActivation(userId, UserStatus.Inactive)
      .then(() => {
        enqueueSnackbar(t('Successfully deactivated user'), {
          variant: 'success',
        });
        fetchUsers();
      })
      .catch(() => {
        enqueueSnackbar(t('Failed deactivate user'), {
          variant: 'error',
        });
      })
      .finally(() => {
        handleClose();
      });
  }
  async function handleReactivateUser(userId: UserId): Promise<void> {
    changeUserActivation(userId, UserStatus.Active)
      .then(() => {
        enqueueSnackbar(t('Successfully reactivated user'), {
          variant: 'success',
        });
        fetchUsers();
      })
      .catch(e => {
        enqueueSnackbar(
          e.body?.errorCode === 'QuotaExceeded'
            ? t('Failed reactivating user. Number of active members reached limit.')
            : t('Failed reactivating user.'),
          { variant: 'error', autoHideDuration: 12000 },
        );
      })
      .finally(() => {
        handleClose();
      });
  }

  const canDeactivate =
    currentUser &&
    member.status &&
    member.status === UserStatus.Active &&
    member.id !== currentUser.id;

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        id="member-settings-selector"
        className="cy-member-settings-selector"
      >
        <MoreVert />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="cy-member-conf-menu"
      >
        <MenuItem
          onClick={() => {
            if (canDeactivate) {
              handleDeactivateUser(member.id);
            } else {
              handleReactivateUser(member.id);
            }
          }}
          className="cy-delete-member-li"
        >
          {canDeactivate ? (
            <>
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              {t('Deactivate')}
            </>
          ) : (
            <>
              <ListItemIcon>
                <RestoreFromTrash />
              </ListItemIcon>
              {t('Activate')}
            </>
          )}
        </MenuItem>
      </Menu>
    </>
  );
};

const mapStateToProps = (state: LegacyReduxStates): ReduxStateProps => ({
  currentUser: state.login.user,
});
const actions = { fetchUsers };
export default connect(mapStateToProps, actions)(MemberSettingSelector);

import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardIcon from '@material-ui/icons/KeyboardRounded';
import HotKeysDialog from './HotKeysDialog';

export type HotKeyValue =
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'CLICK'
  | 'ARROW_UP'
  | 'ARROW_DOWN'
  | 'COMMAND'
  | 'CTRL'
  | (string & {});

export interface HotKey {
  keys: HotKeyValue[];
  description: string;
}

export interface HotKeySection {
  title: string;
  hotKeyList: HotKey[];
}

const useStyles = makeStyles(theme => ({
  keymap: {
    marginRight: theme.spacing(1),
  },
}));

export const useHotKeyDialog = (
  hotkeys: HotKey[],
): {
  icon: JSX.Element;
  dialog: boolean | JSX.Element;
  toggle: () => void;
} => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDialog = (): void => {
    setIsOpen(!isOpen);
  };

  const icon = (
    <Tooltip title={t('Hot Keys')}>
      <IconButton
        className={classes.keymap}
        size="medium"
        aria-label="Hot Keys"
        onClick={toggleDialog}
        id="media-viewer-hot-keys"
      >
        <KeyboardIcon />
      </IconButton>
    </Tooltip>
  );

  const dialog = isOpen && (
    <HotKeysDialog
      hotKeys={hotkeys}
      dialogOpen={isOpen}
      handleDialogClose={() => {
        setIsOpen(false);
      }}
    />
  );

  return {
    icon,
    dialog,
    toggle: toggleDialog,
  };
};

import { makeStyles } from '@material-ui/core';

export const projectSettingAdministrativeStyles = makeStyles(() => ({
  projectNameTitle: {
    marginTop: 20,
    fontSize: 16,
  },
  projectName: {
    marginTop: 10,
    fontSize: '14px',
  },
  editBtn: {
    marginTop: 10,
    marginLeft: 20,
    fontSize: 14,
  },
  editInput: {
    marginTop: 10,
    marginLeft: 20,
  },
  moreActionsTitle: {
    marginTop: 25,
  },
  deleteProject: {
    marginTop: 10,
    marginBottom: 20,
  },
  deleteBtn: {
    fontSize: '14px',
    marginTop: 5,
    marginBottom: 20,
    verticalAlign: 'middle',
    horizontalAlign: 'center',
    '&:hover': {
      background: '#DB4e35',
    },
  },
}));

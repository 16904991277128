import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box } from '@material-ui/core';
import { AggregatedConfusionMatrix } from '@clef/shared/types';
import useStyles from './styles';
import useGetDefectById from '@/hooks/defect/useGetDefectById';
import { DefectColorChip } from '../ConfusionMatrix';
import { Typography } from '@clef/client-library';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '8px !important',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles(theme => ({
  root: {
    borderRadius: '8px !important',
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottomWidth: 0,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.grey[300],
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      borderBottomWidth: 1,
      minHeight: 56,
      borderBottomLeftRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
    },
  },
  expandIcon: {
    order: -1,
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(_theme => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails);

interface ConfusionMatrixAccordionProps {
  name: string;
  isCorrect: boolean;
  data: AggregatedConfusionMatrix[] | undefined;
}

const ConfusionMatrixAccordion = (props: ConfusionMatrixAccordionProps) => {
  const styles = useStyles();
  const { name, data } = props;
  const [expanded, setExpanded] = React.useState<boolean>(true);
  const getDefectById = useGetDefectById();

  const formattedData = data
    ?.filter(item => item.count !== 0)
    .map(item => {
      const gtDefectId = item.gtClassId;
      const predDefectId = item.predClassId;
      return {
        gtDefectId: item.gtClassId,
        predDefectId: item.predClassId,
        count: item.count,
        gtCaption: gtDefectId ? getDefectById(gtDefectId)?.name : 'No label',
        predictionCaption: predDefectId ? getDefectById(predDefectId)?.name : 'No label',
      };
    });

  const handleChange = (_event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded);
  };

  const totalCount = formattedData?.reduce((acc, cur) => {
    return acc + cur.count;
  }, 0);

  if (!formattedData || formattedData.length === 0) {
    return null;
  }

  return (
    <Box paddingBottom={4} width="100%">
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Box
            display="flex"
            width="100%"
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Box paddingLeft={1}>
              <Typography variant="body_bold">{name}</Typography>
            </Box>
            <Box className={styles.lastCol}>
              <Typography variant="h4_semibold" className={styles.gm600}>
                {totalCount}
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box width="100%" display="flex" flexDirection="column">
            {formattedData?.map(item => {
              return (
                <Box
                  key={`${item.gtDefectId}-${item.predDefectId}`}
                  className={styles.confusionMatrixAccordionRow}
                  display="flex"
                  flexDirection={'row'}
                >
                  <Box className={styles.firstCol}>
                    <DefectColorChip
                      width={250}
                      defectId={item.gtDefectId}
                      size={12}
                      maxWidth={150}
                    />
                  </Box>
                  <Box className={styles.midCol}>
                    <DefectColorChip
                      width={250}
                      defectId={item.predDefectId}
                      size={12}
                      maxWidth={150}
                    />
                  </Box>
                  <Box className={styles.lastCol}>
                    <Typography variant="body_medium">{item.count}</Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
export default ConfusionMatrixAccordion;

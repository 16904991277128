import { Grid, Typography, makeStyles, Box, Tooltip, withStyles } from '@material-ui/core';
import React, { useState, useEffect, useMemo } from 'react';
import { Bar } from '@clef/shared/types';
import classNames from 'classnames';

const useStyles = makeStyles(({ spacing, palette }) => ({
  chartContainer: {
    marginTop: spacing(15),
    marginBottom: spacing(10),
  },
  nameText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 100,
    transform: 'rotate(45deg)',
    marginTop: spacing(5),
  },
  valueText: {
    whiteSpace: 'nowrap',
    textAlign: 'center',
    left: 0,
    right: 0,
    top: -24,
    position: 'absolute',
  },
  barContainer: {
    flex: '1 1 0',
    maxWidth: 100,
    height: '100%',
  },
  barContainerWithTooltip: {
    position: 'relative',
    '& $labelText': {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    '& $valueText': {
      left: '50%',
      right: 'auto',
      transform: 'translateX(-50%)',
    },
    '&:hover $barRoot::before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: 0,
      height: '100%',
      borderLeft: `1px solid ${palette.grey[200]}`,
    },
  },
  barRoot: {
    position: 'relative',
    height: '100%',
    minHeight: 220,
  },
  bar: {
    borderRadius: 8,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
  labelText: {
    paddingTop: spacing(3),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    height: 34,
  },
}));
export interface BarChartProps {
  chartData: Bar[];
  noDataClassName?: string;
  showEmptyValues?: boolean;
  classes?: {
    root?: string;
    bar?: string;
  };
  formatValue?: (bar: Bar, index: number) => string | number;
  formatLabel?: (bar: Bar, index: number) => string;
  tooltipContent?: (bar: Bar, index: number) => string;
}

const calculateSpacing = (barCount: number): 1 | 2 | 3 | 4 => {
  if (barCount >= 0 && barCount <= 4) {
    return 4;
  }
  if (barCount > 4 && barCount <= 8) {
    return 3;
  }
  if (barCount > 8 && barCount <= 12) {
    return 2;
  }
  return 1;
};

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[600],
    boxShadow: '0px 1px 2px 1px #30374F14, 0px 1px 2px 0px #30374F29!important',
  },
}))(Tooltip);

const BarChart: React.FC<BarChartProps> = props => {
  const {
    chartData,
    noDataClassName,
    showEmptyValues,
    classes,
    formatValue,
    formatLabel,
    tooltipContent,
  } = props;
  const styles = useStyles();
  const [startAnimation, setStartAnimation] = useState(false);
  useEffect(() => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setStartAnimation(true);
      });
    });
  });

  const maxValue = useMemo(
    () => chartData.reduce((max, bar) => Math.max(bar.value, max), 0),
    [chartData],
  );

  const noValue = !showEmptyValues && chartData.every(item => item.value === 0);

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={calculateSpacing(chartData.length)}
        className={classNames(styles.chartContainer, classes?.root)}
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
      >
        {noValue ? (
          <Box className={noDataClassName}>{t('No data')}</Box>
        ) : (
          chartData.map((barItem, index) => {
            const { value, name, color } = barItem;
            if (!showEmptyValues && value === 0) return null;
            const heightPercent = `${(100 * value) / maxValue}%`;
            const heightStyle = showEmptyValues ? `max(1px, ${heightPercent})` : heightPercent;
            return (
              <LightTooltip
                key={name + '_' + index}
                title={tooltipContent ? tooltipContent(barItem, index) : ''}
                placement="top"
              >
                <Grid
                  item
                  className={classNames(
                    styles.barContainer,
                    !!tooltipContent && styles.barContainerWithTooltip,
                  )}
                >
                  <div className={styles.barRoot}>
                    <div
                      style={{
                        backgroundColor: color,
                        height: startAnimation ? heightStyle : 0,
                      }}
                      className={classNames(styles.bar, classes?.bar)}
                    >
                      <Typography
                        variant="body2"
                        component="div"
                        gutterBottom
                        className={styles.valueText}
                      >
                        <strong>{formatValue ? formatValue(barItem, index) : value}</strong>
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <Typography
                      variant="subtitle2"
                      component="div"
                      align="center"
                      color="textSecondary"
                      className={styles.labelText}
                    >
                      {(formatLabel ? formatLabel(barItem, index) : name) ?? ' '}
                    </Typography>
                  </div>
                </Grid>
              </LightTooltip>
            );
          })
        )}
      </Grid>
      {/* Chart labels */}
    </>
  );
};

export default BarChart;

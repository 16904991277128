import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circle: {
    margin: 5,
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: theme.palette.blue[600],
    boxShadow: `0 0 0 ${alpha(theme.palette.blue[600], 0.5)}`,
    animation: `$pulsing 1500ms ${theme.transitions.easing.easeOut} infinite`,
  },
  bar: {
    margin: 5,
    width: 10,
    height: 4,
    borderRadius: 2,
    backgroundColor: theme.palette.blue[600],
    boxShadow: `0 0 0 ${alpha(theme.palette.blue[600], 0.5)}`,
    animation: `$pulsing 1500ms ${theme.transitions.easing.easeOut} infinite`,
  },
  '@keyframes pulsing': {
    '0%': {
      boxShadow: `0 0 0 0 ${alpha(theme.palette.blue[600], 0.5)}`,
    },
    '70%': {
      boxShadow: `0 0 0 8px ${alpha(theme.palette.blue[600], 0.0)}`,
    },
    '100%': {
      boxShadow: `0 0 0 0 ${alpha(theme.palette.blue[600], 0.0)}`,
    },
  },
}));

export interface PulsingBadgeProps {
  style?: React.CSSProperties;
  className?: string;
}

export const PulsingBadge: React.FC<PulsingBadgeProps> = ({ style, className }) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.container, className)} style={style}>
      <div className={classes.circle} />
    </div>
  );
};

export interface PulsingBarProps {
  style?: React.CSSProperties;
  className?: string;
  barWidth?: number;
}

export const PulsingBar: React.FC<PulsingBarProps> = ({ style, className, barWidth = 10 }) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.container, className)} style={style}>
      <div className={classes.bar} style={{ width: barWidth }} />
    </div>
  );
};

export enum EnvironmentEnum {
  Local = 'local',
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
  RegulatedDevelopment = 'regulated-development',
  RegulatedStaging = 'regulated-staging',
  RegulatedProduction = 'regulated-production',
  MDTStaging = 'mdt-staging',
  MDTProduction = 'mdt-production',
  Onprem = 'onprem',
}

export const LIMIT_CONCURRENCY_SIZE = 5;
const serverClefEnv = process.env.SERVER_ENV || 'local';

// TODO
// Remove all mapping after dev is stable.
const EnvToApigatewayMapping: Record<EnvironmentEnum, string> = {
  [EnvironmentEnum.Staging]: '',
  [EnvironmentEnum.Production]: '',
  [EnvironmentEnum.MDTStaging]: '',
  [EnvironmentEnum.MDTProduction]: '',
  [EnvironmentEnum.Development]: '',
  [EnvironmentEnum.RegulatedDevelopment]: '',
  [EnvironmentEnum.RegulatedStaging]: '',
  [EnvironmentEnum.RegulatedProduction]: '',
  // by default localhost without process.env.SERVER_ENV uses staging
  [EnvironmentEnum.Local]: '',
  // API gateway port, for onprem we assume single url to host all api endpoints
  [EnvironmentEnum.Onprem]: '',
};

const hostname = typeof window !== 'undefined' ? window.location.hostname : '';

const EnvToPredictDomainMapping: Record<EnvironmentEnum, string> = {
  [EnvironmentEnum.Development]: hostname.endsWith('landing.ai')
    ? 'https://predict.app.dev.landing.ai'
    : typeof window !== 'undefined'
    ? window.location.origin
    : '',
  [EnvironmentEnum.Staging]: hostname.endsWith('landing.ai')
    ? 'https://predict.app.staging.landing.ai'
    : typeof window !== 'undefined'
    ? window.location.origin
    : '',
  [EnvironmentEnum.Production]: hostname.endsWith('landing.ai')
    ? 'https://predict.app.landing.ai'
    : typeof window !== 'undefined'
    ? window.location.origin
    : '',
  [EnvironmentEnum.RegulatedDevelopment]: 'https://predict.regulated.dev.landing.ai',
  [EnvironmentEnum.RegulatedStaging]: 'https://predict.regulated.staging.landing.ai',
  [EnvironmentEnum.RegulatedProduction]: 'https://predict.regulated.landing.ai',
  [EnvironmentEnum.MDTStaging]: '',
  [EnvironmentEnum.MDTProduction]: '',
  // by default localhost without process.env.SERVER_ENV uses staging
  [EnvironmentEnum.Local]:
    serverClefEnv === 'staging'
      ? 'https://predict.app.staging.landing.ai'
      : serverClefEnv === 'dev'
      ? 'https://predict.app.dev.landing.ai'
      : '',
  // API gateway port, for onprem we assume single url to host all api endpoints
  [EnvironmentEnum.Onprem]: '',
};

export const getEnv = (): EnvironmentEnum => {
  switch (hostname) {
    case 'regulated.landingai.io': // TODO deprecate it.
    case 'regulated.landing.ai':
      return EnvironmentEnum.RegulatedProduction;
    case 'platform.landingai.io':
    case 'app.landing.ai':
      return EnvironmentEnum.Production;
    case 'regulated.staging.landingai.io': // TODO deprecated it.
    case 'regulated.staging.landing.ai':
      return EnvironmentEnum.RegulatedStaging;
    case 'staging.platform.landingai.io':
    case 'app.staging.landing.ai':
      return EnvironmentEnum.Staging;
    case 'laimdtstaging.com':
      return EnvironmentEnum.MDTStaging;
    case 'laimdtprod.com':
      return EnvironmentEnum.MDTProduction;
    case 'regulated.dev.landingai.io': // TODO deprecate it.
    case 'regulated.dev.landing.ai':
      return EnvironmentEnum.RegulatedDevelopment;
    case 'dev.platform.landingai.io':
    case 'app.dev.landing.ai':
      return EnvironmentEnum.Development;
    case 'app.local.landing.ai':
    case 'local.app.landing.ai':
    case 'localhost':
      return EnvironmentEnum.Local;
    default:
      return EnvironmentEnum.Onprem;
  }
};

export const isFDA = (): boolean => {
  const env = getEnv();
  return (
    env === EnvironmentEnum.RegulatedDevelopment ||
    env === EnvironmentEnum.RegulatedStaging ||
    env === EnvironmentEnum.RegulatedProduction
  );
};

export const API_GATEWAY_URL = EnvToApigatewayMapping[getEnv()];

export const PREDICT_URL = EnvToPredictDomainMapping[getEnv()];

export const VALID_DATA_AUGMENTATION_TRANSFORMATIONS = [
  'Blur',
  'Downscale',
  'HueSaturationValue',
  'GaussianBlur',
  'MotionBlur',
  'RandomBrightness',
  'RandomContrast',
  'Crop',
  'HorizontalFlip',
  'RandomCrop',
  'RandomRotate90',
  'Resize',
  'VerticalFlip',
];

export enum ERROR_LOGGER_ERROR_LEVEL {
  NORMAL = 'normal',
  SERIOUS = 'serious',
}

export const SAMPLE_PROJECT_NAME_PREFIX = 'Sample_Project';

// these folders can be found in the landinglens-sample-data-{{env}} bucket
export const SAMPLE_DATASET_TO_S3_FOLDER = {
  cereal: 'cereal-sample-data',
  face: 'face-focus',
  drive: 'scenic-drive',
  fire: 'wild-fire-detection',
  buddy: 'buddy',
  cell: 'cell',
  crack: 'crack',
  satellite: 'satellite-sydney-bay',
  segull: 'seagulls',
};

export const InstantLearningMediaLimit = 20;

export const SSE_WINDOW_EVENT_KEY = 'serversentevent';

import React from 'react';
import { useParams } from 'react-router';
import { Box, Tooltip, MenuList, MenuItem } from '@material-ui/core';
import Sort from '@material-ui/icons/Sort';
import { useAtom } from 'jotai';

import {
  IconButton,
  calcItemPerRow,
  Button,
  Dropdown,
  ApiResponseLoader,
} from '@clef/client-library';

import { useDatasetSortableFieldInfoQuery } from '@/serverStore/dataset';
import { itemPerRowOffsetAtom, sortFieldAtom } from '@/uiStates/datasetSnapshot/pageUIStates';
import ZoomInIcon from '@/images/data-browser/dataset/ZoomInIcon';
import ZoomOutIcon from '@/images/data-browser/dataset/ZoomOutIcon';
import { MediaFilter } from '@/components/MediaFilter';

import useStyles from './styles';

export type SnapshotMediaControllerProps = {};

export const SnapshotMediaController: React.FC<SnapshotMediaControllerProps> = () => {
  const styles = useStyles();
  const { version } = useParams<{ version: string }>();

  const [itemPerRowOffset, setItemPerRowOffset] = useAtom(itemPerRowOffsetAtom);
  const [sortField, setSortField] = useAtom(sortFieldAtom);

  const {
    data: sortableFields,
    isLoading: sortableFieldsLoading,
    error: sortableFieldsError,
  } = useDatasetSortableFieldInfoQuery();

  return (
    <Box display="flex">
      {/* filter tool */}
      <MediaFilter filterMappingKey={parseInt(version)} />

      {/* sort tool */}
      <Dropdown
        extraGutter={{ vertical: 12 }}
        dropdown={() => (
          <ApiResponseLoader
            response={sortableFields}
            loading={sortableFieldsLoading}
            error={sortableFieldsError}
            defaultHeight={'60px'}
            defaultWidth={'60px'}
          >
            {responseLoaded => (
              <MenuList aria-label="sortBy" data-testid="sort-by-select">
                {responseLoaded.map((field, index) => {
                  const opt = field.orderLabel
                    ? `${field.label}: ${field.orderLabel}`
                    : field.label;
                  return (
                    <MenuItem
                      data-testid={`dropdown-option-${opt}`.toLowerCase().replace(' ', '-')}
                      key={opt}
                      selected={
                        sortField.label === field.label && sortField.orderLabel === field.orderLabel
                      }
                      onClick={() => {
                        const newSortField = { ...responseLoaded[index] };
                        if (newSortField.sortType === 'random') {
                          newSortField.sortValue = String(Math.random());
                        }
                        setSortField(newSortField);
                      }}
                    >
                      {opt}
                    </MenuItem>
                  );
                })}
              </MenuList>
            )}
          </ApiResponseLoader>
        )}
      >
        <Button
          id="open-sort-option-button"
          color="primary"
          variant="outlined"
          className={styles.toolbarButton}
          startIcon={<Sort className={styles.sortStartIcon} />}
        >
          {t('Sort')}
        </Button>
      </Dropdown>

      {/* zoom tool */}
      <Tooltip title={t('Enlarge image size')} arrow placement="top">
        <Box
          className={styles.zoomIconBox}
          display="flex"
          alignItems="center"
          justifyContent={'center'}
        >
          <IconButton
            id="model-details-increase-media-size"
            size="small"
            disabled={calcItemPerRow(undefined, itemPerRowOffset) < 2}
            onClick={() => {
              setItemPerRowOffset(prev => prev - 1);
            }}
          >
            <ZoomInIcon />
          </IconButton>
        </Box>
      </Tooltip>
      <Box marginRight={2}></Box>
      <Tooltip title={t('Reduce image size')} arrow placement="top">
        <Box
          className={styles.zoomIconBox}
          display="flex"
          alignItems="center"
          justifyContent={'center'}
        >
          <IconButton
            id="model-details-decrease-media-size"
            size="small"
            disabled={calcItemPerRow(undefined, itemPerRowOffset) > 7 || itemPerRowOffset > 4}
            onClick={() => {
              setItemPerRowOffset(prev => prev + 1);
            }}
          >
            <ZoomOutIcon />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default SnapshotMediaController;

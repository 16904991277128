import { ProjectRole, UserPermission } from '@clef/shared/types';

export const ROLE_SELECTOR_OPTIONS: Record<string, ProjectRole> = {
  Owner: ProjectRole.Owner,
  Standard: ProjectRole.Standard,
  Labeler: ProjectRole.Labeler,
};

export const PERMISSION_SELECTOR_OPTIONS: Record<string, UserPermission> = {
  UploadData: UserPermission.UploadData,
  TrainModel: UserPermission.TrainModel,
  DelployModel: UserPermission.DeployModel,
  DirectLabel: UserPermission.DirectLabel,
};

export const PermissionMap: Record<UserPermission, string> = {
  [UserPermission.DeployModel]: t('Deploy model'),
  [UserPermission.TrainModel]: t('Train model'),
  [UserPermission.UploadData]: t('Upload data'),
  [UserPermission.DirectLabel]: t('Direct label'),
};

export const AllPermissions = [
  t('Upload data'),
  t('Train model'),
  t('Deploy model'),
  t('Direct label'),
];

export const ProjectRoleMap: Record<ProjectRole, string> = {
  [ProjectRole.Owner]: t('Owner'),
  [ProjectRole.Standard]: t('Standard'),
  [ProjectRole.Labeler]: t('Labeler'),
};

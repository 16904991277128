import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { Button, isMacOS } from '@clef/client-library';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    border: `6px solid ${theme.palette.blue[600]}`,
  },
  hintContainer: {
    display: 'flex',
    margin: '0 auto',
    width: 400,
    height: 28,
    pointerEvents: 'auto',
  },
  leftHint: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.greyModern[50],
    borderRadius: '0px 0px 0px 20px',
  },
  rightHint: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.blue[600],
    borderRadius: '0px 0px 20px 0px',
    gap: 4,
  },
  rightHintCheckIcon: {
    fontSize: 14,
    fill: theme.palette.common.white,
  },
  rightHintButton: {
    color: theme.palette.common.white,
    height: 28,
    padding: 0,
  },
}));

export type SAMLabelingConfirmationHintProps = {
  onSaveButtonClick?: () => void | Promise<void>;
};

const SAMLabelingConfirmationHint: React.FC<SAMLabelingConfirmationHintProps> = ({
  onSaveButtonClick,
}) => {
  const styles = useStyles();
  return (
    <Box className={styles.container}>
      <Box className={styles.hintContainer}>
        <Box className={styles.leftHint}>
          <Typography>{t("You're using Smart Labeling")}</Typography>
        </Box>

        <Box className={styles.rightHint}>
          <CheckIcon className={styles.rightHintCheckIcon} />
          <Button
            id="save-sam-mask-button"
            className={styles.rightHintButton}
            onClick={onSaveButtonClick}
          >
            {t('Save labels ({{button}})', {
              button: isMacOS() ? 'Return' : 'Enter',
            })}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SAMLabelingConfirmationHint;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  mediaList: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  loadingImagesProgress: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 8,
    zIndex: 1,
  },
  mediaGridWrapper: {
    position: 'relative',
    flexGrow: 1,
    width: 'auto',
    margin: theme.spacing(3, -3, 0, -1),
    overflowY: 'auto',
  },
  hintTextNormal: {
    color: theme.palette.grey[500],
  },
  virtualGrid: {
    overflow: 'visible',
  },
  toolbarButton: {
    minWidth: 66,
    height: 32,
    marginRight: theme.spacing(2),
    padding: theme.spacing(2, 3),
    borderColor: theme.palette.grey[300],
    color: theme.palette.grey[600],
    fontSize: 12,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  sortStartIcon: {
    fontSize: '16px !important',
    marginRight: theme.spacing(-1),
  },
  zoomIconBox: {
    width: 32,
    height: 32,
    background: theme.palette.greyBlue[100],
    borderRadius: 10,
  },
  appliedFiltersCls: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;

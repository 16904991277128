import React, { useCallback, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Line, DataPoint } from '@clef/shared/types';
import { getMaxCeil } from './helpers';

export const AVAILABLE_COLORS = ['#5B61A7', '#FFB61D', '#00B69B', '#004070', '#FD6771'];

const useStyles = makeStyles(({ typography, palette }) => ({
  container: {
    display: 'block',
    width: '100%',
  },
  axisTitleX: {
    textAnchor: 'middle',
    fontFamily: typography.body1.fontFamily,
    fill: palette.grey[800],
  },
  axisTitleY: {
    textAnchor: 'start',
    fontFamily: typography.body1.fontFamily,
    fill: palette.grey[800],
  },
  gridline: {
    strokeWidth: 1,
    stroke: palette.grey[300],
    strokeDasharray: '8 4',
  },
  gridLabel: {
    fontFamily: typography.body1.fontFamily,
    fill: palette.grey[600],
  },
  axis: {
    stroke: palette.grey[600],
  },
  dataline: {
    strokeWidth: 2,
    fill: 'transparent',
  },
  datapoint: {
    stroke: 'transparent',
    r: 3,
  },
  datapointPulsing: {
    stroke: 'transparent',
    r: 8,
  },
}));

export interface Props<InputDataPoint> {
  /**
   * A dictionary of lines to render in the same chart.
   *
   * By default it expects the datapoints to be of type `{ x: number, y: number }` but you can use
   * any other type if you provide a suitable pair `getX` and `getY`.
   */
  data: Line<InputDataPoint>[];
  /**
   * Allow to apply custom formatting to x-axis grid labels.
   */
  labelFormatterX?(v: number, index: number): React.ReactNode;
  /**
   * Allow to apply custom formatting to y-axis grid labels.
   */
  labelFormatterY?(v: number, index: number): React.ReactNode;
  /**
   * Wether to render grid labels on the x-axis or not.
   */
  showGridLabelsX?: boolean;
  /**
   * Wether to render grid labels on the y-axis or not.
   */
  showGridLabelsY?: boolean;
  /**
   * Wether to render vertical gridlines or not.
   */
  showGridLinesX?: boolean;
  /**
   * Wether to render horizontal gridlines or not.
   */
  showGridLinesY?: boolean;
  /**
   * Wether to render a circle on each data point or not.
   */
  showDataPoints?: boolean;
  /**
   * Wether to show pulsing animation on the last data point at the moment.
   */
  lastPointPulsing?: boolean;
  /**
   * Wether to render the lines or not.
   *
   * Setting `showLines: false` and `showDataPoints: true` allows creating a scatter plot.
   */
  showLines?: boolean;
  /**
   * The amount of vertical divisions the chart grid has. This controls the amount of vertical
   * gridlines and x-axis grid labels.
   */
  gridSizeX?: number;
  /**
   * The amount of horizontal divisions the chart grid has. This controls the amount of horizontal
   * gridlines and y-axis grid labels.
   */
  gridSizeY?: number;
  /**
   * An optional title to render next to the x-axis.
   */
  axisTitleX?: React.ReactNode;
  /**
   * An optional title to render next to the y-axis.
   */
  axisTitleY?: React.ReactNode;
  /**
   * The chart aspect ratio.
   *
   * The chart is a block element that fills the whole width of its container, so use this prop to
   * define an aspect ratio in which your specific chart looks good.
   */
  aspectRatio?: number;
  /**
   * The scale factor for axis titles and grid labels.
   *
   * This allows to proportionally increase or decrease the font size.
   */
  textScale?: number;
  /**
   * A getter function for the `x` datapoint coordinate.
   */
  getX?(p: InputDataPoint): DataPoint['x'];
  /**
   * A getter function for the `y` datapoint coordinate.
   */
  getY?(p: InputDataPoint): DataPoint['y'];
  /**
   * Value to use as x-axis minimum. If not provided it will be calculated from input data.
   */
  minX?: number;
  /**
   * Value to use as x-axis maximum. If not provided it will be calculated from input data.
   */
  maxX?: number;
  /**
   * Value to use as y-axis minimum. If not provided it will be calculated from input data.
   */
  minY?: number;
  /**
   * Value to use as y-axis maximum. If not provided it will be calculated from input data.
   */
  maxY?: number;
  /**
   * Optional render function to include custom SVG elements inside the chart.
   */
  children?(args: {
    getRelativeCoordX(x: number): number;
    getRelativeCoordY(y: number): number;
    maxGridLineY?: number;
    getXTickIndex: (e: React.MouseEvent<SVGElement, MouseEvent>) => number;
  }): React.ReactNode;
  /**
   * Id of the element
   */
  id?: string;
  /**
   * Test id of the element
   */
  'data-testid'?: string;
}

const defaultLabelFormatter = (value: number, _index: number) => value.toString();

function LineChart<InputDataPoint>({
  children,
  labelFormatterX = defaultLabelFormatter,
  labelFormatterY = defaultLabelFormatter,
  showGridLabelsX = true,
  showGridLabelsY = true,
  showGridLinesX = false,
  showGridLinesY = true,
  showDataPoints = true,
  lastPointPulsing = false,
  showLines = true,
  gridSizeX = 6,
  gridSizeY = 4,
  axisTitleX = '',
  axisTitleY = '',
  aspectRatio = 2.5,
  getX = (p: any) => p.x,
  getY = (p: any) => p.y,
  data,
  minX: customMinX,
  maxX: customMaxX,
  maxY: customMaxY,
  id,
  'data-testid': dataTestId,
}: Props<InputDataPoint>) {
  const classes = useStyles();
  const [width, setWidth] = useState(160);
  const height = width / aspectRatio;

  const lines = data.map(metric => ({
    ...metric,
    values: metric.values
      // Normalize datapoint format
      .map(inputDataPoint => ({ x: getX(inputDataPoint), y: getY(inputDataPoint) }))
      // Sorted x-axis or we would get a weird line otherwise (SVG path commands are sequential)
      .sort((a, b) => a.x - b.x),
  }));

  const minX = customMinX ?? Math.min(...lines.map(({ values }) => values[0].x));

  // x-axis values are sorted, so we can expect the last item on each line to be the max
  const maxX = customMaxX ?? Math.max(...lines.map(({ values }) => values[values.length - 1].x));

  // for max-y we need to traverse all values across all lines
  const maxY =
    customMaxY ??
    getMaxCeil(Math.max(...lines.map(({ values }) => Math.max(...values.map(({ y }) => y)))));

  const yGridLineLabelWidth = 50;
  const yTitleHeight = 35;
  const xTitleHeight = axisTitleX ? 30 : 0;
  const xGridLineLabelHeight = 20;
  const svgRef = useRef<SVGSVGElement | null>(null);
  const zeroBottom = height - xTitleHeight - xGridLineLabelHeight;

  const getRelativeCoordX = React.useCallback(
    (absoluteValue: number) => {
      return (
        ((absoluteValue - minX) * (width - yGridLineLabelWidth - 4)) / (maxX - minX || 1) +
        yGridLineLabelWidth
      );
    },
    [width, maxX, minX],
  );

  const getRelativeCoordY = React.useCallback(
    (absoluteValue: number) => {
      const value = Math.min(maxY, absoluteValue);
      const relativeValue = maxY ? (value * (zeroBottom - yTitleHeight)) / maxY : 0;
      // Adjust because SVG y-axis coordinates are upside-down
      return zeroBottom - relativeValue;
    },
    [maxY, zeroBottom],
  );

  const getPathCommands = React.useCallback(
    (datapoints: DataPoint[]) => {
      return datapoints
        .map(({ x, y }, i) => {
          // Start the line by moving to the first coordinates pair
          const pathCommand = i === 0 ? 'M' : 'L';
          return `${pathCommand}${getRelativeCoordX(x)} ${getRelativeCoordY(y)}`;
        })
        .join(' ');
    },
    [getRelativeCoordX, getRelativeCoordY],
  );

  // We get the appropriate grid values by dividing the current maximums in equal parts
  const gridValuesX = [...new Array(gridSizeX + 1)].map(
    (_, i) => minX + ((maxX - minX) / gridSizeX) * i,
  );
  const gridValuesY = [...new Array(gridSizeY + 1)].map((_, i) => (maxY / gridSizeY) * i);

  const gridLabelsPaddingX = 4;
  const gridLabelsPaddingY = 8;

  const getXTickIndex = useCallback(
    (event: React.MouseEvent<SVGElement, MouseEvent>): number => {
      const svg = svgRef.current!.getBoundingClientRect();
      const len = lines[0].values.length;
      const detalX = (width - yGridLineLabelWidth) / len;
      const index = Math.max(
        0,
        Math.min(Math.round((event.clientX - svg.left - yGridLineLabelWidth) / detalX), len),
      );

      return index;
    },
    [lines, width],
  );

  const refCallback = useCallback((ref: HTMLDivElement | null) => {
    setWidth(ref?.clientWidth || 160);
  }, []);

  return (
    <div ref={refCallback} style={{ flex: 1 }} id={id} data-testid={dataTestId}>
      <svg
        ref={svgRef}
        aria-label={`${axisTitleY} vs. ${axisTitleX} chart`}
        className={classes.container}
        width="100%"
        height={height}
        role="figure"
      >
        {axisTitleY && (
          <text className={classes.axisTitleY} x="20" y={yTitleHeight - 20}>
            {axisTitleY}
          </text>
        )}
        {axisTitleX && (
          <text
            dominantBaseline="hanging"
            className={classes.axisTitleX}
            x={width / 2}
            y={height - xGridLineLabelHeight}
          >
            {axisTitleX}
          </text>
        )}

        {(showGridLabelsY || showGridLinesY) && (
          <>
            {gridValuesY.map((v, i) => (
              <g key={`gridY-${v}-${i}`}>
                {showGridLinesY && (
                  <line
                    vectorEffect="non-scaling-stroke"
                    className={classes.gridline}
                    x1={yGridLineLabelWidth}
                    x2={width}
                    y1={getRelativeCoordY(v)}
                    y2={getRelativeCoordY(v)}
                  />
                )}
                {showGridLabelsY && (
                  <text
                    dominantBaseline="middle"
                    textAnchor="end"
                    className={classes.gridLabel}
                    x={yGridLineLabelWidth - gridLabelsPaddingX}
                    y={getRelativeCoordY(v)}
                  >
                    {labelFormatterY(v, i)}
                  </text>
                )}
              </g>
            ))}
          </>
        )}
        {(showGridLabelsX || showGridLinesX) && (
          <>
            {gridValuesX.map((v, i) => (
              <g key={`gridX-${v}`}>
                {showGridLinesX && (
                  <line
                    vectorEffect="non-scaling-stroke"
                    className={classes.gridline}
                    x1={getRelativeCoordX(v)}
                    x2={getRelativeCoordX(v)}
                    y1="0"
                    y2={height}
                  />
                )}
                {showGridLabelsX && (
                  <text
                    dominantBaseline="hanging"
                    textAnchor={i === gridValuesX.length - 1 ? 'end' : 'middle'}
                    className={classes.gridLabel}
                    x={getRelativeCoordX(v)}
                    y={zeroBottom + gridLabelsPaddingY}
                  >
                    {labelFormatterX(v, i)}
                  </text>
                )}
              </g>
            ))}
          </>
        )}

        {lines.map((line, i) => {
          const color = line.color || AVAILABLE_COLORS[AVAILABLE_COLORS.length % (i + 1)];
          return (
            <g key={line.name}>
              {showLines && (
                <path
                  data-testid={`line-${line.name}`}
                  vectorEffect="non-scaling-stroke"
                  className={classes.dataline}
                  stroke={color}
                  d={getPathCommands(line.values)}
                />
              )}
              {/* <path> does not render when there is only one point, we show a circle instead */}
              {(showDataPoints || line.values.length === 1) &&
                line.values.map(({ x, y }) => {
                  return (
                    <circle
                      data-testid={`datapoint-${x}-${y}`}
                      className={classes.datapoint}
                      fill={color}
                      key={`datapoint-${x}-${y}`}
                      cx={getRelativeCoordX(x)}
                      cy={getRelativeCoordY(y)}
                    />
                  );
                })}
              {lastPointPulsing && (
                <>
                  <circle
                    className={classes.datapoint}
                    fill={color}
                    key={`datapoint-${line.values[line.values.length - 1].x}-${
                      line.values[line.values.length - 1].y
                    }`}
                    cx={getRelativeCoordX(line.values[line.values.length - 1].x)}
                    cy={getRelativeCoordY(line.values[line.values.length - 1].y)}
                  />
                  <circle
                    className={classes.datapointPulsing}
                    fill={color}
                    key={`datapoint-pulsing-${line.values[line.values.length - 1].x}-${
                      line.values[line.values.length - 1].y
                    }`}
                    cx={getRelativeCoordX(line.values[line.values.length - 1].x)}
                    cy={getRelativeCoordY(line.values[line.values.length - 1].y)}
                  >
                    <animate
                      attributeName="opacity"
                      from="1"
                      to="0"
                      dur="1.5s"
                      begin="0s"
                      repeatCount="indefinite"
                    />
                    <animate
                      attributeName="r"
                      from="0"
                      to="8"
                      dur="1.5s"
                      begin="0s"
                      repeatCount="indefinite"
                    />
                  </circle>
                </>
              )}
            </g>
          );
        })}
        {children &&
          children({
            getRelativeCoordX,
            getRelativeCoordY,
            maxGridLineY: Math.max(...gridValuesY),
            getXTickIndex,
          })}
      </svg>
    </div>
  );
}

export default LineChart;

import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Grid,
  Paper,
  Typography,
  Divider,
  TextareaAutosize,
  LinearProgress,
  useTheme,
  Tooltip,
  Box,
} from '@material-ui/core';
import { Button, IconButton } from '@clef/client-library';
import DefectMediaStats from './DefectMediaStats';
import {
  useFetchDefectBookExampleApi,
  updateDefectBookExampleApi,
} from '../../../hooks/api/useDefectBookApi';
import {
  ApiResponseLoader,
  useWindowEventListener,
  useKeyPress,
  Popper,
} from '@clef/client-library';
import RelativeDateTime from '../../../components/RelativeDateTime/RelativeDateTime';
import { Defect, WipDefectData, LabelType } from '@clef/shared/types';
import { useDefectBookEnhancedStyles } from '../defectBookEnhancedStyles';
import {
  defectToDisplayData,
  EMPTY_DESCRIPTION_TEXT,
  getDefectColor,
  reorder,
} from '../../../utils';
import { useImmer } from 'use-immer';
import { useDefectSelectorWithArchived } from '../../../store/defectState/actions';
import {
  useDeleteDefectMutation,
  useGetSelectedProjectQuery,
  useUpdateDefectMutation,
} from '@/serverStore/projects';
import { useSnackbar } from 'notistack';
import DefectExample from '../components/DefectExample';
import { ColorResult } from 'react-color';
import { defectColors } from '@clef/client-library';
import CreateNewExampleButtonGroup from '../components/CreateNewExampleButtonGroup';
import { useHistory } from 'react-router';
import CLEF_PATH from '../../../constants/path';
import { useDialog } from '../../../components/Layout/components/useDialog';
import ColorToggleButton from '../components/ColorToggleButton';
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import ColorPicker from '../components/ColorPicker';
import { COLOR_PALETTE } from '../../../constants/colors';

export interface DefectDetailViewContentProps {
  defect: Defect;
  onContentLoaded?: () => void;
}

const wipDefectDataToDefect = (defect: Defect, wipDefectData: WipDefectData): Defect => ({
  ...defect,
  descriptionText: wipDefectData.description,
  name: wipDefectData.name,
  color: wipDefectData.color,
  defect_book_example_ids: wipDefectData.examples?.map(example => example.id),
});

const updateWipDefectExamplesColor = (defect: Defect, wipDefect: WipDefectData, color: string) => {
  return wipDefect?.examples?.map(example => {
    const textAnnotations = example.annotations.textAnnotations.map(a =>
      a.color === wipDefect.color ? { ...a, color } : a,
    );
    const boxAnnotations = example.annotations.boxAnnotations.map(a =>
      a.color === wipDefect.color ? { ...a, color } : a,
    );
    const lineAnnotations = example.annotations.lineAnnotations.map(a =>
      a.color === wipDefect.color ? { ...a, color } : a,
    );
    const annotations = { textAnnotations, boxAnnotations, lineAnnotations };
    return { ...example, annotations };
  });
};

const DefectDetailViewContent: React.FC<DefectDetailViewContentProps> = ({
  defect,
  onContentLoaded,
}) => {
  const styles = useDefectBookEnhancedStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { showConfirmationDialog } = useDialog();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const deleteDefectApi = useDeleteDefectMutation();
  const allowDefectCreation = selectedProject?.labelType !== LabelType.AnomalyDetection;
  const projectAllowsDeleteDefects =
    selectedProject?.labelType !== LabelType.SegmentationInstantLearning;
  const [editMode, setEditMode] = useState(false);
  const [updatingData, setUpdatingData] = useState(false);
  const [mediaLoadedCount, setMediaLoadedCount] = useState(0);
  const once = useRef(false);
  const theme = useTheme();
  const [customColors, setCustomColors] = useState<string[]>([]);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [newColor, setNewColor] = useState<string>(COLOR_PALETTE[0]);
  const defects = useDefectSelectorWithArchived();
  const updateDefectApi = useUpdateDefectMutation();

  const [defectExamples, defectExamplesLoading, defectExamplesError] = useFetchDefectBookExampleApi(
    defect.defect_book_example_ids?.length ? defect.defect_book_example_ids : undefined,
  );

  const [wipDefect, setWipDefect] = useImmer<WipDefectData>(
    defectToDisplayData(defect, defectExamples),
  );

  useWindowEventListener('beforeunload', (event: WindowEventMap['beforeunload']) => {
    if (editMode && wipDefect.drafted) {
      event.returnValue = 'All your changes will be discarded, are you sure you want to leave?';
    }
  });

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (editMode) {
      document.body.style.overflow = 'hidden';
    } else {
      // when material ui Dialog is closed, it restores body.style.overflow to 'hidden' async after transition,
      // because at the time of confirm dialog popup the value was 'hidden'.
      // we need to override the value to '' after transition finished.
      const timeoutDuration = theme.transitions.duration.leavingScreen;
      setTimeout(() => (document.body.style.overflow = ''), timeoutDuration + 100);
    }
  }, [editMode, theme.transitions.duration.leavingScreen]);

  // initialize wipDefect.examples with defectExamples from server.
  useEffect(() => {
    if (!wipDefect.examples && defectExamples) {
      setWipDefect(() => defectToDisplayData(defect, defectExamples));
    }
  }, [defectExamples, wipDefect.examples, setWipDefect, defect]);

  // call contentLoaded after all media are loaded
  useEffect(() => {
    if (
      !once.current &&
      (!defect.defect_book_example_ids ||
        defect.defect_book_example_ids.length === mediaLoadedCount)
    ) {
      once.current = true;
      onContentLoaded?.();
    }
  }, [defect.defect_book_example_ids, mediaLoadedCount, onContentLoaded]);

  const onExitEditMode = useCallback(() => {
    // whenever existing exit mode, reset wip data to current defect data
    if (wipDefect.drafted) {
      showConfirmationDialog({
        onConfirm: () => {
          setWipDefect(() => defectToDisplayData(defect, defectExamples));
          setEditMode(false);
        },
        title: t('You changes will be discarded'),
        content: t('Are you sure you want to discard all your changes to the defect?'),
        confirmText: t('Discard'),
        color: 'primary',
      });
    } else {
      setWipDefect(() => defectToDisplayData(defect, defectExamples));
      setEditMode(false);
    }
  }, [defect, defectExamples, setWipDefect, showConfirmationDialog, wipDefect.drafted]);

  useKeyPress('esc', onExitEditMode);

  const onSaveDefect = useCallback(async () => {
    setUpdatingData(true);
    const newDefectData = wipDefectDataToDefect(defect, wipDefect);
    const updatedExamples = updateWipDefectExamplesColor(defect, wipDefect, wipDefect.color);
    setWipDefect(draft => {
      draft.examples = updatedExamples;
      draft.drafted = true;
    });
    try {
      await Promise.all([
        ...(wipDefect.examples ?? []).map(example =>
          updateDefectBookExampleApi(example.id, {
            mediaId: example.mediaId,
            annotations: example.annotations,
            note: example.note,
          }),
        ),
        updateDefectApi.mutateAsync(newDefectData),
      ]);
      enqueueSnackbar(
        t('successfully update defect: {{temp0}}', {
          temp0: newDefectData.name,
        }),
        { variant: 'success' },
      );
      setEditMode(false);
    } catch (err) {
      enqueueSnackbar(
        (err?.body || err)?.message, // this could be an api error or frontend error
        { variant: 'error' },
      );
    } finally {
      setUpdatingData(false);
    }
  }, [defect, enqueueSnackbar, setWipDefect, wipDefect]);
  const lastUpdate =
    new Date(defect.updatedAt!) > new Date(defect.createdAt!)
      ? defect.updatedAt!
      : defect.createdAt!;

  const chosenColors = useMemo(() => {
    return (defects || []).map(item => {
      const color = (item.color || '').toUpperCase();
      return color;
    });
  }, [defects]);

  useEffect(() => {
    if (chosenColors.length > 0) {
      const colors = chosenColors.filter(color => !defectColors.includes(color));
      setCustomColors(Array.from(new Set(colors)));
    }
  }, [chosenColors]);

  const isExist = useCallback(
    (color: string) => {
      return chosenColors?.includes(color.toLocaleUpperCase());
    },
    [chosenColors],
  );

  const onColorChange = useCallback(
    (color: string) => {
      setUpdatingData(true);
      const updatedExamples = updateWipDefectExamplesColor(defect, wipDefect, color);
      setWipDefect(draft => {
        draft.color = color;
        draft.examples = updatedExamples;
        draft.drafted = true;
      });
      setUpdatingData(false);
    },
    [defect, setWipDefect, wipDefect],
  );

  const handleAddNewColor = useCallback(() => {
    setShowColorPicker(false);
    if ([...customColors, wipDefect.color, ...defectColors].includes(newColor)) {
      enqueueSnackbar(t('Please do not set duplicate colors'), {
        variant: 'warning',
      });
      return;
    }
    onColorChange(newColor);
    setCustomColors([...customColors, newColor]);
  }, [customColors, enqueueSnackbar, newColor, onColorChange, wipDefect.color]);

  return (
    <>
      {editMode && <div className={styles.editModeMask} onClick={onExitEditMode} />}
      <Paper
        className={styles.defectDetailContent}
        data-testid="defect-detail-content"
        style={{
          // 294px is basic margin to the top if screen
          transform: `translateY(${editMode ? 0 - 294 + 60 + window.scrollY : 0}px)`,
          overflowY: editMode && !updatingData && !deleteDefectApi.isLoading ? 'auto' : 'hidden',
        }}
      >
        {(updatingData || deleteDefectApi.isLoading) && (
          <>
            <div className={styles.linearProgress}>
              <LinearProgress />
            </div>
          </>
        )}
        <div className={styles.defectDetailHeader}>
          {/* Defect name and CTA */}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            className={styles.marginBottom4}
          >
            <Grid item container justifyContent="flex-start" alignItems="center">
              {editMode ? (
                <Popper
                  dropdown={() => (
                    <Box
                      className={styles.newColorPicker}
                      display="flex"
                      flexWrap="wrap"
                      alignItems="center"
                    >
                      {defectColors.map(color => {
                        const disabled = isExist(color);
                        return (
                          <Box
                            key={color}
                            data-testid={`color-toggle-button-item-${color}`}
                            aria-disabled={disabled}
                            marginBottom={1}
                          >
                            <ColorToggleButton
                              disabled={disabled}
                              key={color}
                              color={color}
                              selectedColor={wipDefect.color}
                              onClick={() => {
                                onColorChange(color);
                              }}
                            />
                          </Box>
                        );
                      })}
                      {!!customColors.length && (
                        <>
                          <Divider
                            flexItem
                            orientation="vertical"
                            style={{ marginBottom: 16 }}
                            className={styles.divider}
                          />
                          {customColors.map(color => {
                            const disabled = isExist(color);
                            return (
                              <Box
                                key={color}
                                data-testid={`color-toggle-button-item-${color}`}
                                aria-disabled={disabled}
                                marginBottom={1}
                              >
                                <ColorToggleButton
                                  disabled={disabled}
                                  key={color}
                                  color={color}
                                  selectedColor={wipDefect.color}
                                  onClick={() => {
                                    onColorChange(color);
                                  }}
                                />
                              </Box>
                            );
                          })}
                        </>
                      )}
                      <Box alignSelf="center" style={{ marginBottom: 6 }}>
                        <IconButton
                          size="small"
                          onClick={() => setShowColorPicker(!showColorPicker)}
                          className={styles.addColorButton}
                          id="detail-defect-custom-color"
                        >
                          <AddCircleOutlineOutlined />
                        </IconButton>
                        {showColorPicker && (
                          <Box className={styles.popover}>
                            <ColorPicker
                              color={newColor}
                              onChange={(color: ColorResult) => setNewColor(color.hex)}
                              onClose={handleAddNewColor}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                >
                  <div
                    className={styles.defectColorMedium}
                    data-testid="defect-color-picker"
                    style={{ backgroundColor: wipDefect.color }}
                  />
                </Popper>
              ) : (
                <div
                  className={styles.defectColorMedium}
                  style={{ backgroundColor: getDefectColor(defect) }}
                />
              )}
              {editMode ? (
                <Tooltip
                  arrow
                  title={t(
                    'If you want to rename an unused class, you must delete it and create a new class.',
                  )}
                >
                  <Typography variant="h3">{defect.name}</Typography>
                </Tooltip>
              ) : (
                <Typography variant="h3">{defect.name}</Typography>
              )}
            </Grid>
            <Grid item container justifyContent="flex-end" alignItems="center">
              {editMode ? (
                <>
                  <Button
                    id="save-class"
                    color="primary"
                    disabled={!wipDefect.drafted}
                    variant="contained"
                    onClick={onSaveDefect}
                  >
                    {t('Save')}
                  </Button>
                  <Button id="cancel-save-class" color="primary" onClick={onExitEditMode}>
                    {t('Cancel')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    id="edit-class"
                    color="primary"
                    onClick={() => setEditMode(true)}
                    disabled={deleteDefectApi.isLoading}
                  >
                    {t('Edit')}
                  </Button>
                  {allowDefectCreation && projectAllowsDeleteDefects && (
                    <div>
                      <Button
                        id="delete-class"
                        color="secondary"
                        disabled={deleteDefectApi.isLoading}
                        onClick={() =>
                          showConfirmationDialog({
                            title: t('Delete Class'),
                            content: t(
                              'This action cannot be undone. Please acknowledge the results of deleting a Class to continue.',
                            ),
                            confirmText: t('Delete Class'),
                            confirmAllowedCheckbox: [
                              t('All Labels with this Class will be deleted'),
                              t(
                                'All Predictions with this Class will be available until you train the Model again',
                              ),
                            ],
                            color: 'primary',
                            switchButtonPosition: true,
                            onConfirm: () => {
                              deleteDefectApi.mutate(defect, {
                                onSuccess: () =>
                                  isMounted.current &&
                                  history.replace(CLEF_PATH.data.defectBookEnhanced),
                              });
                            },
                          })
                        }
                      >
                        {t('Delete')}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          {/* Last update time and divider */}
          <RelativeDateTime prefixText={t('Last updated: ')}>{lastUpdate}</RelativeDateTime>
          <Divider />
        </div>
        <div className={styles.defectDetailContentInner}>
          {/* Defect description and stats */}
          <Grid
            container
            justifyContent="space-between"
            wrap="nowrap"
            alignItems="flex-start"
            className={styles.marginBottom6}
          >
            <Grid item lg={7} xs={8} container className={styles.defectDetailContentDescription}>
              {editMode ? (
                <TextareaAutosize
                  data-testid="defect-description-input"
                  value={wipDefect.description}
                  onChange={e => {
                    e.persist();
                    setWipDefect(draft => {
                      draft.description = e?.target?.value ?? '';
                      draft.drafted = true;
                    });
                  }}
                  className={styles.editorModeTextArea}
                />
              ) : (
                <ReactMarkdown className={styles.markdownText}>
                  {defect.descriptionText?.trim() || EMPTY_DESCRIPTION_TEXT}
                </ReactMarkdown>
              )}
            </Grid>
            <Grid item lg={5} xs={4} container justifyContent="flex-end">
              <DefectMediaStats defect={defect} />
            </Grid>
          </Grid>
          {/* Defect examples */}
          <ApiResponseLoader
            response={wipDefect.examples}
            loading={defectExamplesLoading}
            error={defectExamplesError}
            defaultHeight={300}
          >
            {defectExamples => (
              <Grid
                container
                wrap="wrap"
                alignItems="flex-start"
                spacing={3}
                className={styles.marginBottom6}
              >
                {defectExamples.map((defectExample, index) => (
                  <Grid item xs={6} key={defectExample.id}>
                    <DefectExample
                      defectColor={defect.color as string}
                      defectName={defect.name}
                      defectExampleNumber={index + 1}
                      defectExample={defectExample}
                      editMode={editMode}
                      onImageLoad={() => {
                        setMediaLoadedCount(prev => prev + 1);
                      }}
                      onUpdate={newData => {
                        setWipDefect(draft => {
                          draft.examples![index] = newData;
                          draft.drafted = true;
                        });
                      }}
                      onDelete={() =>
                        setWipDefect(draft => {
                          draft.examples = [
                            ...defectExamples.slice(0, index),
                            ...defectExamples.slice(index + 1),
                          ];
                          draft.drafted = true;
                        })
                      }
                      onMoveLeft={
                        index !== 0
                          ? () =>
                              setWipDefect(draft => {
                                draft.examples = reorder(defectExamples, index, index - 1);
                                draft.drafted = true;
                              })
                          : undefined
                      }
                      onMoveRight={
                        index !== defectExamples.length - 1
                          ? () =>
                              setWipDefect(draft => {
                                draft.examples = reorder(defectExamples, index, index + 1);
                                draft.drafted = true;
                              })
                          : undefined
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </ApiResponseLoader>
          {/* Edit mode: Add media from local or data browser */}
          {editMode && (
            <CreateNewExampleButtonGroup
              onCreateNewExample={newExamples => {
                setWipDefect(draft => {
                  draft.examples = [...(draft.examples ?? []), ...newExamples];
                  draft.drafted = true;
                });
              }}
            />
          )}
        </div>
      </Paper>
    </>
  );
};

export default DefectDetailViewContent;

import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import DataBrowserEmptyStateSvg from '../../images/data-browser/data-browser-upload.svg';

const useStyles = makeStyles(theme => ({
  container: {
    width: 400,
    height: 346,
    position: 'relative',
    right: 0,
    top: 0,
  },
  bigCircle: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 296,
    height: 296,
    backgroundColor: '#EFF7FF',
    borderRadius: 296,
    zIndex: 1,
  },
  smallCircle: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: 296,
    height: 296,
    backgroundColor: '#F5FAFF',
    borderRadius: 296,
  },
  uploadIcon: {
    marginLeft: 40,
    marginTop: 50,
  },
  title: {
    fontSize: 26,
    color: theme.palette.grey[900],
    fontWeight: 700,
    marginTop: `-${theme.spacing(1)}px`,
  },
  uploadLabel: {
    color: theme.palette.grey[600],
    fontSize: '14px',
    marginTop: 198,
    justifyContent: 'center',
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
  },
  children: {
    position: 'relative',
    zIndex: 2,
    marginTop: theme.spacing(5),
  },
}));

const DragAndDropArea: React.FC<
  React.HTMLAttributes<any> & {
    title?: string;
    label?: React.ReactNode;
    position?: React.ComponentProps<typeof Box>['position'];
  }
> = props => {
  const styles = useStyles();

  return (
    <Box
      className={styles.container}
      {...props}
      style={{
        position: props.position || 'relative',
      }}
    >
      <div className={styles.bigCircle}>
        <img className={styles.uploadIcon} src={DataBrowserEmptyStateSvg} alt="" />
        {props.title && <Typography className={styles.title}>{props.title}</Typography>}
      </div>
      <div className={styles.smallCircle}></div>
      {props.label && <Typography className={styles.uploadLabel}>{props.label}</Typography>}
      {props.children && (
        <Box display="flex" justifyContent="center" className={styles.children}>
          {props.children}
        </Box>
      )}
    </Box>
  );
};

export default DragAndDropArea;

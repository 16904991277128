import React, { MutableRefObject, useCallback, useMemo, useRef, useState } from 'react';
import {
  Typography,
  Grid,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  MenuItem,
  Menu,
  Tooltip,
} from '@material-ui/core';
import DeleteRounded from '@material-ui/icons/DeleteRounded';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { useDefectSelector, useDefectSelectorWithArchived } from '../../store/defectState/actions';
import {
  AnnotationChangeType,
  MediaInteractiveCanvas,
  MediaViewerSegmentationAnnotation,
  IconButton,
  useKeyPress,
  AnnotationSourceType,
} from '@clef/client-library';
import { getClassifiedClass, getDefectColor } from '../../utils';
import {
  AnnotationType,
  AnnotationWithoutId,
  BoundingBoxAnnotationData,
  Dimensions,
  LabelingType,
  LabelSource,
  LabelType,
  MediaLevelLabel,
  MediaDetails,
  MediaDetailsWithPrediction,
  MediaStatusType,
} from '@clef/shared/types';
import { isEmpty, uniqBy } from 'lodash';
import {
  BitMapLabelingAnnotation,
  BoxLabelingAnnotation,
  ClassificationLabelingAnnotation,
  PureCanvasLabelingAnnotation,
  useLabelingState,
} from './labelingState';
import { bitMapAnnotationToCanvasAnnotation } from './utils';
import DefectColor from '../../pages/LabelingTask/components/DefectColor';
import cx from 'classnames';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useLabelingStyles } from './labelingStyles';
import { useHistory } from 'react-router';
import CLEF_PATH from '../../constants/path';
import useGetDefectColorById from '../../hooks/defect/useGetDefectColorById';
import { useSnackbar } from 'notistack';
import LabelAPI from '../../api/label_api';
import { useCurrentMediaStates } from './imageLabelingContext';
import { SuccessIcon, ErrorIcon } from '@/images/data-browser/icons';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';

type TransformScalerProps = {
  onMouseOver?: () => void;
  onMouseOut?: () => void;
};

const TransformScaler: React.FC<Dimensions & TransformScalerProps> = ({
  width,
  height,
  children,
  onMouseOut,
  onMouseOver,
}) => {
  const styles = useLabelingStyles();
  const [containerNode, setContainerNode] = useState<HTMLDivElement | null>(null);

  const stageScale = containerNode
    ? Math.min(containerNode.clientWidth / width, containerNode.clientHeight / height)
    : 1;
  const stageStyle: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%) scale(${stageScale})`,
    width,
    height,
  };
  return (
    <div
      onMouseOver={() => {
        onMouseOver && onMouseOver();
      }}
      onMouseOut={() => {
        onMouseOut && onMouseOut();
      }}
      ref={node => {
        if (node) {
          setContainerNode(node);
        }
      }}
      className={styles.previewSegContainer}
      data-testid="preview-segmentation-container"
      style={{ paddingTop: `${100 / (width / height)}%` }}
    >
      <div style={stageStyle}>{children}</div>
    </div>
  );
};

export interface LabelPreviewListProps {
  mediaCanvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
  labelingType?: LabelingType;
  annotations?: (
    | BitMapLabelingAnnotation
    | BoxLabelingAnnotation
    | ClassificationLabelingAnnotation
  )[];
  mediaDimensions?: Dimensions;
  isLabelMode?: boolean;
  isPrediction?: boolean;
  annotationSourceType?: AnnotationSourceType;
  defaultExpanded?: boolean;
  onAnnotationsChange?: (annotations: ClassificationLabelingAnnotation[]) => void;
  mediaDetails?: MediaDetails | MediaDetailsWithPrediction;
  isGroundTruth?: boolean;
  className?: string;
  mediaLevelLabel?: MediaLevelLabel;
}

interface BitMapAnnotationTreeItemProps extends LabelPreviewListProps {
  annotations?: BitMapLabelingAnnotation[] | PureCanvasLabelingAnnotation[];
}

export const BitMapLabelPreviewList: React.FC<BitMapAnnotationTreeItemProps> = ({
  mediaCanvasRef,
  annotations,
  mediaDimensions,
  isLabelMode,
  defaultExpanded,
  isPrediction,
  annotationSourceType,
  mediaLevelLabel,
}) => {
  const styles = useLabelingStyles();
  const theme = useTheme();
  const allDefectsWithArchived = useDefectSelectorWithArchived();
  const allDefectsWithoutArchived = useDefectSelector();
  const allDefects = isPrediction ? allDefectsWithArchived : allDefectsWithoutArchived;
  const mediaWidth = mediaDimensions?.width ?? 0;
  const mediaHeight = mediaDimensions?.height ?? 0;

  const getDefectColorById = useGetDefectColorById();

  if (!annotations || !annotations.length) {
    return mediaLevelLabel === MediaLevelLabel.OK ? (
      <Typography variant="body1" component="span">
        {isPrediction ? t('Predicted as No Class') : t('Marked as No Class')}
      </Typography>
    ) : (
      <Typography variant="body1" component="span">
        {isPrediction
          ? annotations
            ? t('Nothing predicted')
            : t('Unpredicted')
          : t('No labels yet')}
      </Typography>
    );
  }

  return (
    <>
      {annotations.map(ann => {
        const { defectId, data } = ann;
        const rangeBox =
          'rangeBox' in data
            ? data.rangeBox
            : {
                xmin: 0,
                ymin: 0,
                xmax: (ann as PureCanvasLabelingAnnotation).data.width,
                ymax: (ann as PureCanvasLabelingAnnotation).data.height,
              };
        const annotationData = 'bitMap' in data ? data.bitMap : data;

        const foundDefect = allDefects?.find(defect => defect.id === defectId);

        if (!foundDefect) {
          return null;
        }

        return (
          <Accordion
            square
            key={foundDefect.id}
            className={styles.previewContainer}
            role="listitem"
            title={`${foundDefect.name} preview`}
            defaultExpanded={defaultExpanded}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className={!defaultExpanded ? styles.showExpandIconOnHover : undefined}
            >
              <Grid
                container
                alignItems="center"
                className={styles.previewContainerTop}
                onMouseOver={() => {
                  mediaCanvasRef.current?.setHoveredAnnotation({
                    color: getDefectColorById(ann.defectId),
                    group: annotationSourceType,
                  });
                }}
                onMouseOut={() => mediaCanvasRef.current?.setHoveredAnnotation()}
              >
                <DefectColor color={getDefectColor(foundDefect)} />
                <Typography variant="body2" component="div">
                  {foundDefect.name}
                </Typography>
                <div className={styles.flexGrow} />
                {isLabelMode && (
                  <IconButton
                    id="task-delete-defect"
                    size="small"
                    className={styles.deleteAction}
                    color="secondary"
                    aria-label="delete label"
                    onClick={() => {
                      mediaCanvasRef.current?.setAnnotations(
                        // We will replace existing defect instance with erased instance
                        // this way we can ensure orders are correct and overwrites still work
                        // e.g. if a pixel is red, overwrites with purple, delete purple => result should be empty
                        annotations.map(bitMapAnn =>
                          bitMapAnnotationToCanvasAnnotation(
                            bitMapAnn as BitMapLabelingAnnotation,
                            defectId => {
                              if (defectId === foundDefect.id) {
                                return 'transparent';
                              } else {
                                const getDefect = allDefects!.find(
                                  defect => defect.id === defectId,
                                )!;
                                return getDefectColor(getDefect);
                              }
                            },
                            AnnotationSourceType.GroundTruth,
                          ),
                        ),
                        AnnotationChangeType.DeleteColor,
                      );
                    }}
                  >
                    <DeleteRounded fontSize="small" htmlColor={theme.palette.secondary.main} />
                  </IconButton>
                )}
              </Grid>
            </AccordionSummary>
            <TransformScaler
              width={mediaWidth}
              height={mediaHeight}
              onMouseOver={() => {
                mediaCanvasRef.current?.setHoveredAnnotation({
                  color: getDefectColorById(ann.defectId),
                  group: annotationSourceType,
                });
              }}
              onMouseOut={() => mediaCanvasRef.current?.setHoveredAnnotation()}
            >
              <MediaViewerSegmentationAnnotation
                color={getDefectColor(foundDefect)}
                xMin={rangeBox.xmin}
                xMax={rangeBox.xmax}
                yMin={rangeBox.ymin}
                yMax={rangeBox.ymax}
                compressedBitMap={annotationData}
                compress
              />
            </TransformScaler>
          </Accordion>
        );
      })}
    </>
  );
};

interface BoxLabelPreviewListProps extends LabelPreviewListProps {
  annotations?: BoxLabelingAnnotation[];
  classes?: { labelPreviewContainer?: string };
}

export const BoundingBoxLabelPreviewList: React.FC<BoxLabelPreviewListProps> = ({
  mediaCanvasRef,
  annotations,
  defaultExpanded,
  isPrediction,
  mediaLevelLabel,
  annotationSourceType,
  classes,
}) => {
  const styles = useLabelingStyles();
  const allDefectsWithArchived = useDefectSelectorWithArchived();
  const allDefectsWithoutArchived = useDefectSelector();
  const allDefects = isPrediction ? allDefectsWithArchived : allDefectsWithoutArchived;
  const [showAllClasses, setShowAllLabels] = useState(false);

  if (isEmpty(allDefects) || isEmpty(annotations)) {
    return mediaLevelLabel === MediaLevelLabel.OK ? (
      <Typography variant="body1" component="span">
        {isPrediction ? t('Predicted as No Class') : t('Marked as No Class')}
      </Typography>
    ) : (
      <Typography variant="body1" component="span">
        {isPrediction
          ? annotations
            ? t('Nothing predicted')
            : t('Unpredicted')
          : t('No labels yet')}
      </Typography>
    );
  }
  // currently 0 or 1 selected IDs will be returned as we don't have multi-select yet
  const selectedAnnotationIds = annotations!.filter(ann => ann.selected).map(ann => ann.id);

  const classesWithAnnotations = allDefects
    .map(defect => {
      const annotationsForThisDefect = annotations!.filter(ann => ann.defectId === defect.id);
      return annotationsForThisDefect.length ? defect : null;
    })
    .filter(Boolean) as typeof allDefects;

  const displayClasses = showAllClasses
    ? classesWithAnnotations
    : classesWithAnnotations.slice(0, 2);

  return (
    <>
      {displayClasses.map(defect => {
        const annotationsForThisDefect = annotations!.filter(ann => ann.defectId === defect.id);
        return annotationsForThisDefect.length ? (
          <Accordion
            square
            key={defect.id}
            className={cx(styles.labelPreviewContainer, classes?.labelPreviewContainer)}
            defaultExpanded={defaultExpanded}
            data-testid={`${defect.name}-label-preview`}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              className={!defaultExpanded ? styles.showExpandIconOnHover : undefined}
              data-testid="label-preview-defect-name"
              onMouseOver={() => {
                mediaCanvasRef.current?.setHoveredAnnotation({
                  annotationId: annotationsForThisDefect.map(ann => ann.id),
                  group: annotationSourceType,
                });
              }}
              onMouseOut={() => {
                mediaCanvasRef.current?.setHoveredAnnotation();
              }}
            >
              <Grid container alignItems="center" justifyContent="space-between">
                <Box className={styles.defectInfo}>
                  <DefectColor color={getDefectColor(defect)} />
                  <Tooltip title={`${defect.name}`} placement="top" arrow>
                    <Box className={cx(styles.defectNameBox, styles.defectNameBoxMaxWidthOverride)}>
                      {`${defect.name}`}
                    </Box>
                  </Tooltip>
                </Box>
                <Box>{annotationsForThisDefect.length}</Box>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className={styles.labelPreviewItem}>
              <Box width="100%">
                {uniqBy(annotationsForThisDefect, ann => ann.id).map((ann, index) => (
                  <div
                    key={ann.id}
                    aria-selected={ann.selected}
                    className={cx(
                      styles.labelPreviewItemText,
                      selectedAnnotationIds.includes(ann.id) && 'selected',
                    )}
                    onClick={() => {
                      if (selectedAnnotationIds.includes(ann.id)) {
                        mediaCanvasRef.current?.setSelectedAnnotation('');
                      } else {
                        mediaCanvasRef.current?.setSelectedAnnotation(ann.id);
                      }
                    }}
                    onMouseOver={e => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      mediaCanvasRef.current?.setHoveredAnnotation({
                        annotationId: ann.id,
                        group: annotationSourceType,
                      });
                    }}
                    onMouseOut={e => {
                      e.stopPropagation();
                      e.nativeEvent.stopImmediatePropagation();
                      mediaCanvasRef.current?.setHoveredAnnotation();
                    }}
                  >
                    {t('{{index}} - x {{x}}, y {{y}}, {{width}} x {{height}}', {
                      index: index + 1,
                      x: Math.round(ann.data.xmin),
                      y: Math.round(ann.data.ymin),
                      width: Math.round(ann.data.xmax - ann.data.xmin),
                      height: Math.round(ann.data.ymax - ann.data.ymin),
                    })}
                    <span className={styles.labelPreviewItemConfidence}>
                      {ann.confidence ? `${ann.confidence.toFixed(2)}` : ''}
                    </span>
                  </div>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ) : null;
      })}
      {classesWithAnnotations.length > 2 && (
        <>
          {showAllClasses ? (
            <Box
              className={styles.moreClasses}
              onClick={() => {
                setShowAllLabels(false);
              }}
            >
              {t('Show less')}
            </Box>
          ) : (
            <Box
              className={styles.moreClasses}
              onClick={() => {
                setShowAllLabels(true);
              }}
            >
              {t(`Show {{extraCount}} more class{{plural}}`, {
                extraCount: classesWithAnnotations.length - 2,
                plural: classesWithAnnotations.length - 2 > 1 ? 'es' : '',
              })}
            </Box>
          )}
        </>
      )}
    </>
  );
};

type ClassificationLabelPreviewListProps = Omit<LabelPreviewListProps, 'mediaCanvasRef'> & {
  enableColor: boolean;
};

export const ClassificationLabelPreviewList: React.FC<ClassificationLabelPreviewListProps> = ({
  annotations = [],
  onAnnotationsChange,
  enableColor,
  isPrediction,
  isGroundTruth,
}) => {
  const styles = useLabelingStyles();
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  const allDefectsWithArchived = useDefectSelectorWithArchived();
  const allDefectsWithoutArchived = useDefectSelector();
  const allDefects = isPrediction ? allDefectsWithArchived : allDefectsWithoutArchived;
  // assume there is only one annotation
  const annotation = annotations[0];
  const defect = allDefects.find(defect => defect.id === annotation?.defectId);
  const defectColor = defect ? getDefectColor(defect) : 'white';
  const history = useHistory();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'classification-label-preview',
  });

  const { enqueueSnackbar } = useSnackbar();
  const { data: project } = useGetSelectedProjectQuery();
  const { id: projectId, labelType } = project ?? {};
  const { mediaDetails } = useCurrentMediaStates();

  const classifiedClass = useMemo(() => {
    return getClassifiedClass(mediaDetails?.predictionLabel?.annotations || [], allDefects!);
  }, [allDefects, mediaDetails?.predictionLabel?.annotations]);

  const gtClassifiedClass = useMemo(() => {
    return getClassifiedClass(mediaDetails?.label?.annotations || [], allDefects!);
  }, [allDefects, mediaDetails?.label?.annotations]);

  const statusIndicator = useMemo((): {
    el: React.ReactNode;
    type: 'question' | 'success' | 'error';
  } | null => {
    if (labelType !== LabelType.Classification) return null;
    if (!isPrediction) return null;
    if (!classifiedClass?.id) return null;

    if (!gtClassifiedClass?.id) {
      return {
        el: <div className={styles.question}>?</div>,
        type: 'question',
      };
    }
    if (classifiedClass?.id === gtClassifiedClass?.id) {
      return {
        el: <SuccessIcon />,
        type: 'success',
      };
    }
    return {
      el: <ErrorIcon />,
      type: 'error',
    };
  }, [classifiedClass?.id, gtClassifiedClass?.id, isPrediction, labelType, styles.question]);

  const handleChangeClass = useCallback(
    async (defectId: number) => {
      const defect = allDefects.find(defect => defect.id === defectId);
      onAnnotationsChange?.([
        {
          id: Date.now().toString(32),
          defectId,
          data: {},
        },
      ]);
      const serverAnnotation = {
        defectId,
        annotationType: AnnotationType.classification,
        dataSchemaVersion: 3,
      } as AnnotationWithoutId;
      popupState.close();
      if (labelType === LabelType.Classification && projectId) {
        await LabelAPI.upsertLabels({
          projectId,
          mediaId: mediaDetails!.id,
          annotations: [serverAnnotation],
          mediaLevelLabel: MediaLevelLabel.NG,
          source: LabelSource.DirectLabeling,
          modelId: currentModelId,
        });
        enqueueSnackbar(t(`Successfully set class to {{className}}`, { className: defect?.name }), {
          variant: 'success',
        });
      }
    },
    [
      allDefects,
      enqueueSnackbar,
      labelType,
      mediaDetails,
      onAnnotationsChange,
      popupState,
      projectId,
      currentModelId,
    ],
  );
  const anchorRef = useRef<HTMLDivElement>(null);
  useKeyPress('d', () => {
    popupState.setOpen(!popupState.isOpen, anchorRef.current || undefined);
  });

  const gotoDefect = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      history.push(CLEF_PATH.data.defectBookEnhanced);
    },
    [history],
  );

  const mediaStatus = mediaDetails?.mediaStatus;
  const isInTask = mediaStatus === MediaStatusType.InTask;

  const score = useMemo(() => {
    if (labelType === LabelType.Classification && isPrediction) {
      return mediaDetails?.predictionLabel?.mediaLevelScore?.toFixed(2) || '';
    }
    return '';
  }, [isPrediction, labelType, mediaDetails?.predictionLabel?.mediaLevelScore]);

  return (
    <div>
      {/* TODO: merge these with ClassChip when design is ready for data browser */}
      {onAnnotationsChange ? (
        <>
          {allDefects.length > 0 && (
            <Menu {...bindPopover(popupState)} classes={{ paper: styles.changeClassPopover }}>
              {allDefects.map(defect => (
                <MenuItem
                  key={defect.id}
                  onClick={() => handleChangeClass(defect.id)}
                  style={enableColor ? { color: getDefectColor(defect) } : undefined}
                >
                  {defect.name}
                </MenuItem>
              ))}
            </Menu>
          )}
          <Tooltip
            arrow
            interactive
            title={
              !allDefects.length
                ? t('No defect was found. Please create one in the {{link}}.', {
                    link: (
                      <a className={styles.toDefect} onClick={gotoDefect}>
                        {t('defect book')}
                      </a>
                    ),
                  })
                : isInTask && isGroundTruth
                ? t('Image is in a labeling task.')
                : ''
            }
          >
            <Box>
              <div
                className={styles.classificationPreviewBadge}
                data-testid="set-class"
                style={enableColor ? { borderColor: defectColor } : undefined}
                {...bindTrigger(popupState)}
                ref={anchorRef}
              >
                <Typography variant="body2" component="div" className={styles.classificationName}>
                  {defect?.name ?? t('Select Class')}
                </Typography>
                <Box ml={1} />
                <ExpandMore fontSize="small" />
              </div>
            </Box>
          </Tooltip>
        </>
      ) : (
        <>
          {isPrediction ? (
            <Box
              display="inline-block"
              className={cx(
                styles.classificationPreviewBadge,
                defect?.name ? 'prediction' : 'no-prediction',
              )}
            >
              {isPrediction && statusIndicator && statusIndicator.el}
              <Typography
                variant="body2"
                component="div"
                className={styles.classificationName}
                style={{
                  marginLeft: isPrediction && statusIndicator ? 8 : 0,
                  marginRight: score !== '' ? 8 : 0,
                }}
              >
                {defect?.name ?? t('Not predicted')}
              </Typography>
              <Typography>{score}</Typography>
            </Box>
          ) : isInTask ? (
            <Box
              display="inline-block"
              className={cx(
                styles.classificationPreviewBadge,
                defect?.name ? 'prediction' : 'no-prediction',
              )}
              onClick={e => {
                if (isGroundTruth) {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
            >
              <Typography variant="body2" component="div" className={styles.classificationName}>
                {t('In Task')}
              </Typography>
            </Box>
          ) : (
            <Box
              display="inline-block"
              className={cx(styles.classificationPreviewBadge, 'view-only')}
            >
              <Typography variant="body2" component="div" className={styles.classificationName}>
                {defect?.name ?? t('No label')}
              </Typography>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

const LabelPreviewList: React.FC<LabelPreviewListProps> = ({
  mediaCanvasRef,
  labelingType: labelingTypeProps,
  annotations,
  mediaDimensions,
  isLabelMode,
  isPrediction,
  annotationSourceType = AnnotationSourceType.GroundTruth,
  defaultExpanded,
  onAnnotationsChange,
  isGroundTruth,
  mediaLevelLabel,
}) => {
  const {
    state: { labelingType: labelingTypeState },
  } = useLabelingState();
  const { labelType: projectLabelType } = useGetSelectedProjectQuery().data ?? {};
  const labelingType = labelingTypeProps ?? labelingTypeState;

  return (
    <>
      {labelingType === LabelingType.DefectSegmentation && (
        <BitMapLabelPreviewList
          mediaCanvasRef={mediaCanvasRef}
          annotations={
            annotations?.filter(
              ann => 'bitMap' in ann.data || ann.data instanceof OffscreenCanvas,
            ) as BitMapLabelingAnnotation[]
          }
          mediaDimensions={mediaDimensions}
          isLabelMode={isLabelMode}
          defaultExpanded={defaultExpanded}
          isPrediction={isPrediction}
          annotationSourceType={annotationSourceType}
          mediaLevelLabel={mediaLevelLabel}
        />
      )}
      {labelingType === LabelingType.DefectBoundingBox && (
        <BoundingBoxLabelPreviewList
          mediaCanvasRef={mediaCanvasRef}
          annotations={
            annotations?.filter(
              ann => typeof (ann.data as BoundingBoxAnnotationData).xmin !== 'undefined',
            ) as BoxLabelingAnnotation[]
          }
          defaultExpanded={defaultExpanded}
          isPrediction={isPrediction}
          annotationSourceType={annotationSourceType}
          mediaLevelLabel={mediaLevelLabel}
        />
      )}
      {labelingType === LabelingType.DefectClassification && (
        <ClassificationLabelPreviewList
          annotations={annotations}
          onAnnotationsChange={onAnnotationsChange}
          enableColor={projectLabelType === LabelType.AnomalyDetection}
          isPrediction={isPrediction}
          isGroundTruth={isGroundTruth}
        />
      )}
    </>
  );
};

export default LabelPreviewList;

import React, { useCallback } from 'react';
import { Grid, Typography, Checkbox, Switch } from '@material-ui/core';
import { SelectOptions } from '@clef/client-library';
import { LabelType } from '@clef/shared/types';
import { useDeviceMonitorStyles } from './deviceMonitorStyles';
import { useDeviceMonitorState, DeviceMonitorState, sortFields } from './deviceMonitorState';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';

export interface EdgeMediaDisplayOptionsProps {}

const EdgeMediaDisplayOptions: React.FC<EdgeMediaDisplayOptionsProps> = () => {
  const styles = useDeviceMonitorStyles();
  const { labelType } = useGetSelectedProjectQuery().data ?? {};
  const { state, dispatch } = useDeviceMonitorState();
  const {
    displayOptions: {
      showLabels,
      showDefectName,
      showConfidenceScore,
      showImageClassification,
      showHumanJudgement,
      showHumanJudgementCheckbox,
    },
    sortOptions,
  } = state;

  const onToggleDisplayInfo = useCallback(
    (fieldName: keyof DeviceMonitorState['displayOptions']) => () => {
      dispatch(draft => {
        draft.displayOptions[fieldName] = !state.displayOptions[fieldName];
      });
    },
    [dispatch, state],
  );

  return (
    <Grid
      className={styles.displayOptionsContainer}
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid className={styles.displayOptionsItem}>
        {/* Media per page control */}
        <Typography variant="body1" component="span" className={styles.controlLabel}>
          {t('Labels:')}
        </Typography>
        <Switch
          color="primary"
          checked={showLabels}
          onChange={() => {
            dispatch(draft => {
              draft.displayOptions.showLabels = !showLabels;
            });
          }}
          inputProps={{
            // @ts-ignore this works
            'data-testid': 'device-monitor-show-label-toggle',
          }}
        />
        {showLabels && (
          <>
            <Typography variant="body1" component="span" className={styles.controlLabel}>
              {t('Label caption:')}
            </Typography>
            {/* Media status checkbox */}
            <Checkbox
              color="primary"
              size="small"
              checked={showDefectName}
              onChange={onToggleDisplayInfo('showDefectName')}
            />
            <Typography
              variant="body1"
              component="span"
              className={styles.checkboxCaption}
              onClick={onToggleDisplayInfo('showDefectName')}
            >
              {t('Class Name')}
            </Typography>
            {/* Label checkbox */}
            <Checkbox
              color="primary"
              size="small"
              checked={showConfidenceScore}
              onChange={onToggleDisplayInfo('showConfidenceScore')}
            />
            <Typography
              variant="body1"
              component="span"
              className={styles.checkboxCaption}
              onClick={onToggleDisplayInfo('showConfidenceScore')}
            >
              {t('Confidence score')}
            </Typography>
            {/* human judgement */}
            {showHumanJudgementCheckbox && (
              <>
                <Checkbox
                  color="primary"
                  size="small"
                  checked={showHumanJudgement}
                  onChange={onToggleDisplayInfo('showHumanJudgement')}
                />
                <Typography
                  variant="body1"
                  component="span"
                  className={styles.checkboxCaption}
                  onClick={onToggleDisplayInfo('showHumanJudgement')}
                >
                  {t('Human Judgment')}
                </Typography>
              </>
            )}
          </>
        )}
        {labelType === LabelType.BoundingBox && (
          <>
            <Typography
              variant="body1"
              component="span"
              className={styles.controlLabel}
              onClick={onToggleDisplayInfo('showImageClassification')}
            >
              {t('OK/NG:')}
            </Typography>
            <Switch
              color="primary"
              checked={showImageClassification}
              onChange={onToggleDisplayInfo('showImageClassification')}
            />
          </>
        )}
      </Grid>
      <Grid className={styles.displayOptionsItem}>
        {/* Sorting fields */}
        <Typography variant="body1" component="span" className={styles.controlLabel}>
          {t('Sort options:')}
        </Typography>
        <SelectOptions
          size="medium"
          value={sortOptions.label}
          onChange={(_, index) => {
            dispatch(draft => {
              draft.sortOptions = sortFields[index];
            });
          }}
          options={sortFields.map(field => field.label)}
        />
      </Grid>
    </Grid>
  );
};

export default EdgeMediaDisplayOptions;

import { User, PendingUser } from '../types';

export const isUser = (obj: any): obj is User => {
  return (
    typeof obj?.id === 'string' &&
    typeof obj?.orgId === 'number' &&
    typeof obj?.name === 'string' &&
    typeof obj?.lastName === 'string' &&
    typeof obj?.username === 'string' &&
    typeof obj?.email === 'string' &&
    typeof obj?.userRole === 'string'
  );
};

export const isPendingUser = (obj: any): obj is PendingUser => {
  return (
    typeof obj?.id === 'number' &&
    typeof obj?.email === 'string' &&
    typeof obj?.orgId === 'number' &&
    typeof obj?.invitorId === 'string'
  );
};

import React from 'react';
import {
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import { useApiKey } from '@/hooks/api/useUserApi';
import { Typography } from '@clef/client-library';

const useStyles = makeStyles(theme => ({
  table: {
    outline: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 6,
    overflow: 'hidden',
  },
  tableHead: {
    backgroundColor: theme.palette.greyModern[50],
  },
}));

export type LegacyKeyAndSecretProps = {};

const LegacyKeyAndSecret: React.FC<LegacyKeyAndSecretProps> = () => {
  const styles = useStyles();
  const [apiKey] = useApiKey({});
  if (!apiKey?.apiKeyHash) {
    return null;
  }
  return (
    <Box marginTop={5}>
      <Box>
        <Typography variant="h4_semibold">{t('Legacy API Key & Secret')}</Typography>
      </Box>
      <Box marginTop={3}>
        {t(
          'If you generated API credentials before June 21, 2023, the API Key and Secret will display here. Both the API Key and Secret are needed. {{learnMore}}',
          {
            learnMore: (
              <Link
                color="primary"
                href="https://support.landing.ai/landinglens/docs/api-key-and-api-secret"
                target="_blank"
              >
                {t('Learn more.')}
              </Link>
            ),
          },
        )}
      </Box>
      <Box marginTop={5}>
        <Table className={styles.table}>
          <colgroup>
            <col style={{ width: '50%' }} />
            <col style={{ width: '50%' }} />
          </colgroup>
          <TableHead className={styles.tableHead}>
            <TableRow>
              <TableCell align="left">{t('Key')}</TableCell>
              <TableCell align="left">{t('Secret')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{apiKey?.apiKeyHash}</TableCell>
              <TableCell>
                {t('*********************************************************')}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default LegacyKeyAndSecret;

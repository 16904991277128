import React, { useCallback } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
  List,
  ListItem,
  LinearProgress,
  Box,
} from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import { LabelType } from '@clef/shared/types';
import CloseIcon from '@material-ui/icons/Close';
import CLEF_PATH from '../../constants/path';
import { useHistory } from 'react-router';
import { useGetSelectedProjectQuery, useImportSampleDataMutation } from '@/serverStore/projects';
import { SAMPLE_DATASET_TO_S3_FOLDER } from '../../constants';
import unlabeled_fire from '../../images/sample-data/fire.jpeg';
import labeled_cereal from '../../images/sample-data/cereal_labeled.png';
import labeled_face from '../../images/sample-data/face_labeled.png';
import unlabeled_drive from '../../images/sample-data/drive.jpeg';
import unlabeled_buddy from '../../images/sample-data/buddy.jpg';
import unlabeled_gull from '../../images/sample-data/gull.jpg';
import unlabeled_cell from '../../images/sample-data/cell.png';
import unlabeled_crack from '../../images/sample-data/crack.png';
import unlabeled_satellite from '../../images/sample-data/satellite.jpg';

const useStyles = makeStyles(theme => ({
  sampleDataBtn: {
    position: 'absolute',
    alignSelf: 'center',
    visibility: 'hidden',
    margin: theme.spacing(0, 0, 0, '30%'),
  },
  sampleDataContainerDescription: {
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.grey[700],
  },
  sampleDataContainerTitle: {
    fontSize: '14px',
    lineHeight: '20px',
    minHeight: '20px',
    margin: theme.spacing(0, 0, 2.5, 0),
    color: theme.palette.grey[900],
  },
  sampleDataContainerContent: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    display: 'inline-block',
    padding: 0,
  },
  list: {
    width: '100%',
  },
  listItem: {
    padding: theme.spacing(2.5, 8),
    transition: 'background 0.2s',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.greyModern[100],
    },
    '&:active': {
      background: theme.palette.greyModern[200],
    },
  },
  greyedOutText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  sampleDataContainer: {
    display: 'flex',
    padding: 0,
  },
  sampleDataContainerInfo: {
    display: 'flex',
    padding: 0,
  },
  dialogTitle: {
    position: 'absolute',
    margin: theme.spacing(4, 2, 4, 2),
    fontSize: 20,
  },
  descriptionText: {
    display: 'flex',
    alignItems: 'left',
    margin: theme.spacing(9, 0, 0, 8),
    color: theme.palette.greyModern[600],
  },
  dialog: {
    width: 505,
    maxHeight: 515,
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    margin: theme.spacing(2, 0, 5, 0),
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5,
    color: theme.palette.grey[500],
  },
  postRequestMask: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
    background: 'rgba(255,255,255,0.7)',
  },
  image: {
    width: 116,
    height: 116,
    borderRadius: 5,
  },
}));

const sampleDataList: {
  title: string;
  description: string;
  importLabels: boolean;
  s3Folder: string;
  thumbnail: any;
  shouldShow: (labelType?: LabelType | null) => boolean;
  imageCount?: string;
}[] = [
  {
    title: t('Wildfire detection'),
    description: t('13 unlabeled images of wildfire.'),
    importLabels: false,
    s3Folder: SAMPLE_DATASET_TO_S3_FOLDER.fire,
    thumbnail: unlabeled_fire,
    shouldShow: (labelType?: LabelType | null) =>
      labelType !== LabelType.SegmentationInstantLearning,
  },
  {
    title: t('Inspect Food for Foreign Objects (Partially Labeled)'),
    description: t('20 images of cereal with screws. 17 of the images will be labeled for you.'),
    importLabels: true,
    s3Folder: SAMPLE_DATASET_TO_S3_FOLDER.cereal,
    thumbnail: labeled_cereal,
    shouldShow: (labelType?: LabelType | null) => labelType === LabelType.BoundingBox,
  },
  {
    title: t('Face Focus (Labeled)'),
    description: t(
      'Image set used to develop a facial focus detection application, whether the faces are directly facing the camera or not.',
    ),
    importLabels: true,
    s3Folder: SAMPLE_DATASET_TO_S3_FOLDER.face,
    thumbnail: labeled_face,
    shouldShow: (labelType?: LabelType | null) => labelType === LabelType.BoundingBox,
  },
  {
    title: t('Buddy'),
    description: t('Our furry friend Buddy'),
    imageCount: t('(8 images)'),
    importLabels: false,
    s3Folder: SAMPLE_DATASET_TO_S3_FOLDER.buddy,
    thumbnail: unlabeled_buddy,
    shouldShow: (labelType?: LabelType | null) =>
      labelType === LabelType.SegmentationInstantLearning,
  },
  {
    title: t('Seagull'),
    description: t('Seagulls at the beach'),
    imageCount: t('(6 images)'),
    importLabels: false,
    s3Folder: SAMPLE_DATASET_TO_S3_FOLDER.segull,
    thumbnail: unlabeled_gull,
    shouldShow: (labelType?: LabelType | null) =>
      labelType === LabelType.SegmentationInstantLearning,
  },
  {
    title: t('Cell'),
    description: t('Cells in Petri dishes'),
    imageCount: t('(4 images)'),
    importLabels: false,
    s3Folder: SAMPLE_DATASET_TO_S3_FOLDER.cell,
    thumbnail: unlabeled_cell,
    shouldShow: (labelType?: LabelType | null) =>
      labelType === LabelType.SegmentationInstantLearning,
  },
  {
    title: t('Cement'),
    description: t('Cracked cement'),
    imageCount: t('(20 images)'),
    importLabels: false,
    s3Folder: SAMPLE_DATASET_TO_S3_FOLDER.crack,
    thumbnail: unlabeled_crack,
    shouldShow: (labelType?: LabelType | null) =>
      labelType === LabelType.SegmentationInstantLearning,
  },
  {
    title: t('Satellite'),
    description: t('Sydney Harbour satellite'),
    imageCount: t('(6 images)'),
    importLabels: false,
    s3Folder: SAMPLE_DATASET_TO_S3_FOLDER.satellite,
    thumbnail: unlabeled_satellite,
    shouldShow: (labelType?: LabelType | null) =>
      labelType === LabelType.SegmentationInstantLearning,
  },
  {
    title: t('Scenic drive'),
    description: t('Scenic landscape of skies, hills, seas, and roads'),
    imageCount: t('(10 images)'),
    importLabels: false,
    s3Folder: SAMPLE_DATASET_TO_S3_FOLDER.drive,
    thumbnail: unlabeled_drive,
    shouldShow: (labelType?: LabelType | null) =>
      labelType === LabelType.SegmentationInstantLearning,
  },
];

interface SampleDataContainerProps {
  imgSrc: any;
  title: string;
  description: string;
  imageCount?: string;
}

const SampleDataContainer: React.FC<SampleDataContainerProps> = ({
  imgSrc,
  title,
  description,
  imageCount,
}) => {
  const styles = useStyles();
  return (
    <Box className={styles.sampleDataContainer}>
      <Box display="flex" className={styles.sampleDataContainerInfo}>
        <Box display="flex">
          <img src={imgSrc} alt="image not found" className={styles.image} />
        </Box>
        <Box marginLeft={4} className={styles.sampleDataContainerContent}>
          <Typography variant="h3" className={styles.sampleDataContainerTitle}>
            {title}
          </Typography>
          <Typography variant="body1" className={styles.sampleDataContainerDescription}>
            {description}
            {imageCount && <span className={styles.greyedOutText}>{imageCount}</span>}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

interface SampleDataProps {
  open: boolean;
  onClose(): void;
}

const ImportSampleDataDialog: React.FC<SampleDataProps> = ({ open, onClose }) => {
  const styles = useStyles();
  const history = useHistory();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const importSampleData = useImportSampleDataMutation();

  const closeDialog = useCallback(() => {
    onClose();
  }, [onClose]);

  const onImportSampleData = useCallback(
    async (importLabels: boolean, s3folder: string) => {
      await importSampleData.mutateAsync(
        {
          s3folder,
          importLabels,
        },
        {
          onSuccess: () => {
            closeDialog();
            history.push(CLEF_PATH.root);
          },
        },
      );
    },
    [history, closeDialog, importSampleData.mutateAsync],
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-testid="import-sample-data"
      classes={{
        paper: styles.dialog,
      }}
    >
      {importSampleData.isLoading && (
        <div className={styles.postRequestMask}>
          <LinearProgress />
        </div>
      )}
      <DialogTitle disableTypography>
        {onClose ? (
          <IconButton
            id="close-sample-data-dialog"
            aria-label="close"
            onClick={onClose}
            className={styles.closeButton}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        <Typography variant="h3" className={styles.dialogTitle}>
          {t('Load Sample Data')}
        </Typography>
      </DialogTitle>
      <Typography variant="body1" className={styles.descriptionText}>
        {t('Choose a sample dataset to load.')}
      </Typography>
      <DialogContent className={styles.dialogContainer}>
        <List disablePadding className={styles.list}>
          {sampleDataList
            .filter(item => item.shouldShow(selectedProject?.labelType))
            .map(item => (
              <React.Fragment key={item.title}>
                <ListItem
                  className={styles.listItem}
                  onClick={() => {
                    onImportSampleData(item.importLabels, item.s3Folder);
                  }}
                >
                  <SampleDataContainer
                    imgSrc={item.thumbnail}
                    title={item.title}
                    description={item.description}
                    imageCount={item.imageCount}
                  />
                </ListItem>
              </React.Fragment>
            ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default ImportSampleDataDialog;

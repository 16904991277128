import React from 'react';
import { Dropdown, FilterPill } from '@clef/client-library';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Grid } from '@material-ui/core';
import { Button } from '@clef/client-library';
import DateFnsUtils from '@date-io/date-fns';
import { useDeviceMonitorStyles } from '../deviceMonitorStyles';
import { useDeviceMonitorState } from '../deviceMonitorState';
import { formatDateToShort } from '../../../../../src/utils';

export interface UploadTimeFilterProps {}

const UploadTimeFilter: React.FC<UploadTimeFilterProps> = () => {
  const styles = useDeviceMonitorStyles();
  const {
    state: { filterUploadTime, filterUploadTimeEnabled },
    dispatch,
  } = useDeviceMonitorState();
  return (
    <Dropdown
      extraGutter={{ vertical: 12 }}
      dropdown={
        <div className={styles.dropdownPanel}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container direction="row" wrap="nowrap">
              <div className={styles.sectionComponent}>
                <DateTimePicker
                  data-testid="starttime-picker"
                  margin="normal"
                  label={t('Start date')}
                  format="MM/dd/yyyy p"
                  maxDate={filterUploadTime[1]}
                  value={filterUploadTime[0]}
                  disableFuture
                  autoOk
                  onChange={date => {
                    // date might be invalid if user is half-way updating it
                    if (date?.getTime()) {
                      dispatch(draft => {
                        draft.filterUploadTime[0] = date.getTime();
                      });
                    }
                  }}
                />
              </div>
              <div className={styles.sectionComponent}>
                <DateTimePicker
                  data-testid="endtime-picker"
                  margin="normal"
                  label={t('End date')}
                  format="MM/dd/yyyy p"
                  minDate={filterUploadTime[0]}
                  value={filterUploadTime[1]}
                  disableFuture
                  autoOk
                  onChange={date => {
                    // date might be invalid if user is half-way updating it
                    if (date?.getTime()) {
                      dispatch(draft => {
                        draft.filterUploadTime[1] = date.getTime();
                      });
                    }
                  }}
                />
              </div>
            </Grid>
          </MuiPickersUtilsProvider>
          <Grid container justifyContent="flex-end">
            <Button
              id="apply-upload-time-filter"
              color="primary"
              size="small"
              className={styles.textButton}
              onClick={() =>
                dispatch(draft => {
                  draft.filterUploadTimeEnabled = true;
                })
              }
            >
              {t('Apply')}
            </Button>
            <Button
              id="clear-upload-time-filter"
              color="secondary"
              size="small"
              className={styles.textButton}
              onClick={() =>
                dispatch(draft => {
                  draft.filterUploadTimeEnabled = false;
                })
              }
              disabled={!filterUploadTimeEnabled}
            >
              {t('Clear')}
            </Button>
          </Grid>
        </div>
      }
    >
      {open => (
        <FilterPill
          filterName={t(
            `${formatDateToShort(filterUploadTime[0])} - ${formatDateToShort(filterUploadTime[1])}`,
          )}
          badgeContent={filterUploadTimeEnabled ? 'check' : 0}
          open={open}
          prefixTestId="upload-time"
        />
      )}
    </Dropdown>
  );
};

export default UploadTimeFilter;

import { ProjectRole, UserPermission } from '@clef/shared/types';
import { UserPartialUserProject, AnyUser } from './types';
import { AllPermissions, PermissionMap } from './constants';
import { isOrgCollaborator, isProjectOwner } from '@clef/shared/utils';

export const getProjectRole = (user?: AnyUser) => {
  const userProject = (user as UserPartialUserProject)?.userProject;
  if (userProject?.role && Object.values(ProjectRole).includes(userProject.role)) {
    return userProject?.role;
  }
  const isOwner = (user as UserPartialUserProject)?.userProject?.isOwner;
  let role = ProjectRole.Standard;
  if (isOwner) {
    role = ProjectRole.Owner;
  } else if (isOrgCollaborator(user?.userRole)) {
    role = ProjectRole.Labeler;
  }
  return role;
};

export const getDisplayPermissions = (user: AnyUser) => {
  const { permissions } = (user as UserPartialUserProject).userProject || {};
  const role = getProjectRole(user);
  const hasAllPermissions = Object.values(UserPermission).every(permission =>
    permissions?.includes(permission),
  );
  if (role === ProjectRole.Labeler) {
    return t('Can only label');
  } else if (role === ProjectRole.Owner) {
    return t('All permissions');
  } else if (hasAllPermissions) {
    return AllPermissions.join('; ');
  } else if (!permissions || permissions?.length === 0) {
    return t('No permission');
  } else {
    return permissions?.map(item => PermissionMap[item as UserPermission]).join('; ');
  }
};

export const compareOwners = (user1: AnyUser, user2: AnyUser) => {
  const isOwner1 = isProjectOwner(user1.userProject?.role);
  const isOwner2 = isProjectOwner(user2.userProject?.role);
  return Number(isOwner1) - Number(isOwner2);
};

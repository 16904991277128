import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  labelControlBar: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    margin: theme.spacing(2, 2, 2, 0),
    backgroundColor: theme.palette.greyModern[50],
    borderRadius: '10px',
  },
  labelText: {
    color: theme.palette.greyModern[800],
  },
  labelTextDisabeld: {
    color: theme.palette.greyModern[400],
  },
  caption: {
    color: theme.palette.greyModern[500],
  },
  classificationToggleWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  arrowIcon: {
    color: theme.palette.greyModern[600],
  },
  loadingIcon: {
    color: theme.palette.greyModern[600],
    marginLeft: theme.spacing(2),
  },
  popoverPaper: {
    width: '300px',
    borderRadius: '10px',
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.greyModern[300]}`,
  },
  modelSettingBlock: {
    width: '100%',
    color: theme.palette.greyModern[800],
  },
  modelSelectorButton: {
    width: '100%',
    padding: theme.spacing(1, 2),
    border: `1px solid ${theme.palette.greyModern[300]}`,
    borderRadius: '6px',

    '& .MuiButton-label': {
      justifyContent: 'space-between',
    },
  },
  dropdownRoot: {
    margin: theme.spacing(2, 0, 3, 0),
  },
  dropdownContent: {
    width: '266px',
  },
  modelMenu: {
    width: '100%',
    padding: theme.spacing(1, 0, 1, 1),
  },
  sliderRoot: {
    margin: 'auto 0',
    flex: 1,
  },
  sliderRail: {
    color: theme.palette.greyModern[200],
    borderRadius: 4,
    height: 4,
  },
  sliderTrack: {
    color: theme.palette.greyModern[600],
    borderRadius: 4,
    height: 4,
  },
  sliderThumb: {
    width: 16,
    height: 16,
    background: '#FFFFFF',
    border: `1px solid ${theme.palette.greyModern[300]}`,
    borderRadius: 12,
    marginTop: -7,
    '&:hover': {
      boxShadow: '0px 0px 0px 8px rgb(75, 85, 101, .16)',
    },
    '&.MuiSlider-active': {
      boxShadow: '0px 0px 0px 14px rgb(75, 85, 101, .16)',
    },
  },
  sliderActive: {
    boxShadow: '0px 0px 0px 14px rgb(75, 85, 101, .16)',
  },
  sliderMark: {
    borderRadius: 2,
    height: 4,
    width: 4,
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.common.white} !important`,
    border: `1px solid ${theme.palette.greyModern[600]} !important`,
    opacity: `1 !important`,
    transform: 'translateY(-1px)',
  },
  sliderMarkLabel: {
    top: 20,
  },
  markLabelTooltipLayer: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: 'transparent',
    transform: 'translate(25%, -100%)',
  },
  desc: {
    color: theme.palette.greyModern[600],
  },
  modelsPageText: {
    display: 'inline',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  tipPopper: {
    pointerEvents: 'auto',
  },
  tipArrow: {
    color: theme.palette.blue[700],
  },
  tooltip: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.blue[700],
  },
  gotIt: {
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 700,
  },
}));

export default useStyles;

import * as t from '@clef/shared/types';
import { ProjectId } from '@clef/shared/types';

import ExperimentReportApi from '../../api/experiment_report_api';
import fetchHookFactory from './fetchHookFactory';

export type SingleReportFilters = {
  groundTruth?: string;
  prediction?: string;
  confusionType?: t.ConfusionMatrixKey;
};

export const [useSAMOnnxModel] = fetchHookFactory(
  async () => ExperimentReportApi.getSAMOnnxModel(),
  'stale-while-revalidate',
);

export const [useImageEmbedding] = fetchHookFactory(
  async (params: { projectId: ProjectId; mediaPath: string }) =>
    ExperimentReportApi.getImageEmbedding(params.projectId, params.mediaPath),
  'stale-while-revalidate',
);

export const [useModelInferenceCost] = fetchHookFactory(
  async (params: { projectId: ProjectId; jobId: string }) =>
    ExperimentReportApi.getModelInferenceCost(params),
  'cache',
);

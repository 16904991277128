import { useIsLargeImageModel } from '@/hooks/useIsLargeImageModel';
import { getDateNumber } from '@/utils';
import { Typography } from '@clef/client-library';
import { RegisteredModel } from '@clef/shared/types';
import { CircularProgress, MenuItem, Tooltip } from '@material-ui/core';
import { formatDistance } from 'date-fns';
import React, { useMemo } from 'react';

type ModelMenuItemProps = {
  model: RegisteredModel;
  onMenuItemClick: () => void;
  className?: string;
};

const ModelMenuItem: React.FC<ModelMenuItemProps> = ({ model, onMenuItemClick, className }) => {
  const dateNow = useMemo(() => new Date(), []);

  const [isLargeImageModel, loading] = useIsLargeImageModel(model.id);

  const menuItem = useMemo(
    () => (
      <MenuItem
        key={model.id}
        value={model.id}
        onClick={() => {
          onMenuItemClick();
        }}
        disabled={isLargeImageModel || loading}
        className={className}
      >
        <Typography maxWidth="100%">{model.modelName || t('Untitled model')}</Typography>

        <Typography variant="caption">
          {isNaN(getDateNumber(model.createdAt))
            ? t('Unknown date')
            : t('Trained {{dateDistance}} ago', {
                dateDistance: formatDistance(getDateNumber(model.createdAt), dateNow),
              })}
        </Typography>
        {loading && <CircularProgress size={20} />}
      </MenuItem>
    ),
    [
      className,
      dateNow,
      isLargeImageModel,
      loading,
      model.createdAt,
      model.id,
      model.modelName,
      onMenuItemClick,
    ],
  );

  if (isLargeImageModel) {
    return (
      <Tooltip
        arrow
        placement="top"
        title={
          <span>
            <Typography variant="body2">
              {t('This model is incompatible with cloud inference.')}
            </Typography>
            <Typography variant="body2">{t('Use LandingEdge to run predictions.')}</Typography>
          </span>
        }
      >
        <span>{menuItem}</span>
      </Tooltip>
    );
  }

  return menuItem;
};

export default ModelMenuItem;

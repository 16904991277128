import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  stepName: {
    fontWeight: 600,
  },
  linearProgress: {
    marginRight: 2,
    borderRadius: 2,
    color: theme.palette.blue[500],
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.blue[500],
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.blue[500],
    },
    '& .MuiLinearProgress-colorSecondary': {
      backgroundColor: theme.palette.greyModern[300],
    },
    '& .MuiLinearProgress-barColorSecondary': {
      backgroundColor: theme.palette.greyModern[300],
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  congratsTooltipImg: {
    width: 80,
  },
  congratsTooltipTitle: {
    margin: theme.spacing(5, 0),
  },
  closeButton: {
    position: 'absolute',
    top: 13,
    right: 13,
  },
  marginRight1: {
    marginRight: theme.spacing(1),
  },
  deployLink: {
    fontWeight: 500,
    cursor: 'pointer',
    marginRight: theme.spacing(2),
  },
  checkSvg: {
    marginRight: theme.spacing(3),
  },
  congratsTooltip: {
    border: 'none !important',
    padding: 0,
  },
  congratsTooltipArrowBorder: {
    '&:before': {
      border: 'none !important',
    },
  },
  goBtnWrapper: {
    position: 'relative',
  },
  fixedWidthGoBtn: {
    position: 'absolute',
    top: -4,
    left: -8,
    zIndex: 1,
  },
}));

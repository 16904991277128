import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  calcZoomScaleRange,
  getDisplayZoomValue,
  StepZoomRatio,
  useKeyPress,
  ZoomScale,
} from '@clef/client-library';
import {
  Card,
  MenuList,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import KeyboardArrowDownRounded from '@material-ui/icons/KeyboardArrowDownRounded';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import ZoomInRounded from '@material-ui/icons/ZoomInRounded';
import ZoomOutRounded from '@material-ui/icons/ZoomOutRounded';
import { useCallback } from 'react';
import { HintEvents, useHintSnackbar } from './HintSnackbar';
import ImageEnhancerContainer from '../ImageEnhancer/containers/ImageEnhancerContainer';
import { TutorialStep, useLabelingState } from './labelingState';
import { useLabelingStyles } from './labelingStyles';
import { LabelingType } from '@clef/shared/types';

export interface ToolImageEnhanceProps {
  setZoomScale: (newZoomScale: ZoomScale) => void;
  isLabelingReview?: boolean;
}

const ToolImageEnhance: React.FC<ToolImageEnhanceProps> = ({
  setZoomScale,
  isLabelingReview = false,
}) => {
  const styles = useLabelingStyles();
  const {
    state: {
      hideLabels,
      labelingType,
      toolOptions: { zoomScale, fitZoomScale },
    },
    dispatch,
  } = useLabelingState();

  const triggerHint = useHintSnackbar();

  const { minZoomScale, maxZoomScale } = calcZoomScaleRange(fitZoomScale);
  const zoomScaleNumber = zoomScale === 'fit' ? fitZoomScale : zoomScale;

  const zoomIn = useCallback(() => {
    if (zoomScaleNumber >= maxZoomScale) {
      triggerHint(HintEvents.NoMoreZoomIn);
    } else {
      setZoomScale(Math.min(zoomScaleNumber * StepZoomRatio, maxZoomScale));
      triggerHint();
    }
  }, [maxZoomScale, setZoomScale, triggerHint, zoomScaleNumber]);

  const zoomOut = useCallback(() => {
    if (zoomScaleNumber <= minZoomScale) {
      triggerHint(HintEvents.NoMoreZoomOut);
    } else {
      setZoomScale(Math.max(zoomScaleNumber / StepZoomRatio, minZoomScale));
      triggerHint();
    }
  }, [minZoomScale, setZoomScale, triggerHint, zoomScaleNumber]);

  useKeyPress('=', zoomIn, { id: 'task-zoom-in' });
  useKeyPress('-', zoomOut, { id: 'task-zoom-out' });
  useKeyPress('0', () => setZoomScale('fit'), { id: 'task-zoom-fit' });
  useKeyPress('1', () => setZoomScale(1), { id: 'task-zoom-100' });

  const [openZoomOptions, setOpenZoomOptions] = useState(false);
  const toggleZoomOptions = useCallback(
    (value: boolean) => {
      setOpenZoomOptions(value);
      dispatch(draft => {
        draft.anyMenuOpened = value;
      });
    },
    [dispatch],
  );
  const zoomOptionAnchorRef = useRef<HTMLButtonElement>();
  const theme = useTheme();

  const autoFitText = t('{{fitZoomScale}} (Fit)', {
    fitZoomScale: getDisplayZoomValue(fitZoomScale),
  });
  const displayZoomValue = zoomScale === 'fit' ? autoFitText : getDisplayZoomValue(zoomScaleNumber);

  const disableZoomIn = zoomScaleNumber >= maxZoomScale;
  const disableZoomOut = zoomScaleNumber <= minZoomScale;

  const [hideLabelsButtonHovered, setHideLabelsButtonHovered] = useState(false);

  useEffect(() => {
    dispatch(draft => {
      draft.hideLabels = hideLabelsButtonHovered;
    });
  }, [dispatch, hideLabelsButtonHovered]);

  return (
    <Card className={styles.toolCard}>
      {/* Zoom level picker */}
      <Tooltip
        arrow
        placement="top"
        title={t('zoom to fit {{hotkey1}} | zoom to 100% {{hotkey2}}', {
          hotkey1: <span className={styles.codeBlock}>0</span>,
          hotkey2: <span className={styles.codeBlock}>1</span>,
        })}
      >
        <Button
          ref={ref => {
            if (ref) {
              zoomOptionAnchorRef.current = ref;
              dispatch(draft => {
                // having type issues putting HTMLElement into state, so cast to any
                draft.tutorialAnchorElementsMap[TutorialStep.Zoom] = ref as any;
              });
            }
          }}
          id="task-zoom-value-button"
          endIcon={<KeyboardArrowDownRounded fontSize="small" />}
          onClick={() => {
            toggleZoomOptions(true);
          }}
          aria-controls={openZoomOptions ? 'split-button-menu' : undefined}
          aria-expanded={openZoomOptions ? 'true' : undefined}
        >
          {displayZoomValue}
        </Button>
      </Tooltip>
      <Popper
        open={openZoomOptions}
        anchorEl={zoomOptionAnchorRef.current}
        role={undefined}
        transition
        className={styles.zoomOptions}
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener
                onClickAway={() => {
                  toggleZoomOptions(false);
                }}
              >
                <MenuList autoFocusItem>
                  <MenuItem
                    key="AutoFit"
                    onClick={() => {
                      setZoomScale('fit');
                      toggleZoomOptions(false);
                    }}
                  >
                    {autoFitText}
                  </MenuItem>
                  <MenuItem
                    key="OriginalSize"
                    onClick={() => {
                      setZoomScale(1);
                      toggleZoomOptions(false);
                    }}
                  >
                    100%
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {/* Zoom in */}
      <Tooltip
        arrow
        placement="top"
        title={
          disableZoomIn ? (
            ''
          ) : (
            <>
              {t('zoom in {{hotKey}}', {
                hotKey: <span className={styles.codeBlock}>=</span>,
              })}
            </>
          )
        }
      >
        <Button
          id="task-zoom-in"
          onClick={zoomIn}
          className={styles.labelModeButton}
          color="primary"
          aria-label="zoom-in"
          disabled={disableZoomIn}
        >
          <ZoomInRounded htmlColor={theme.palette.grey[500]} />
        </Button>
      </Tooltip>
      {/* Zoom out */}
      <Tooltip
        arrow
        placement="top"
        title={
          disableZoomOut ? (
            ''
          ) : (
            <>
              {t('zoom out {{hotKey}}', {
                hotKey: <span className={styles.codeBlock}>-</span>,
              })}
            </>
          )
        }
      >
        <Button
          onClick={zoomOut}
          color="primary"
          id="task-zoom-out"
          className={styles.labelModeButton}
          disabled={disableZoomOut}
        >
          <ZoomOutRounded htmlColor={theme.palette.grey[500]} />
        </Button>
      </Tooltip>
      {/* Hide labels */}
      {(labelingType === LabelingType.DefectBoundingBox ||
        labelingType === LabelingType.DefectSegmentation) &&
        !isLabelingReview && (
          <Tooltip
            arrow
            placement="top"
            title={t('Hold {{hotKey}} to hide', {
              hotKey: <span className={styles.codeBlock}>h</span>,
            })}
          >
            <Button
              className={styles.labelModeButton}
              size="medium"
              id="task-toggle-hide-label-button"
              disableTouchRipple
              onMouseEnter={() => setHideLabelsButtonHovered(true)}
              onMouseLeave={() => setHideLabelsButtonHovered(false)}
            >
              {hideLabels ? (
                <VisibilityOffOutlined htmlColor={theme.palette.grey[500]} />
              ) : (
                <VisibilityOutlined htmlColor={theme.palette.grey[500]} />
              )}
            </Button>
          </Tooltip>
        )}
      {/* Image enhancement */}
      <ImageEnhancerContainer />
    </Card>
  );
};

export default ToolImageEnhance;

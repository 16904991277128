import { BaseAPI } from './base_api';
import {
  ApiResponse,
  JobId,
  OrgId,
  ProjectId,
  XEvent,
  ModelDownloadXEventProperty,
} from '@clef/shared/types';
import { API_GATEWAY_URL } from '../constants';

export const prefix = 'clib/v1';
/**
 * CLIB - CL Inference-bundle Builder
 * Responsibilities
 * - Take model from MI and bundle for deployment
 * - List all available bundles ready for deployment
 * - get inference bundle signed url (for CLP)
 */
class CLIBAPI extends BaseAPI {
  async prepareBundleV0(
    projectId: ProjectId,
    jobId: string,
    scoreThreshold?: number,
  ): Promise<{ bundle_id: string }> {
    const params = {} as Record<string, any>;
    params.scoreThreshold = scoreThreshold;
    return this.get(`prepare-bundle/${projectId}/${jobId}`, params, true);
  }

  async prepareBundleV1(params: {
    org_id: OrgId;
    project_id: ProjectId;
    job_id: JobId;
    bundle_path: string;
    score_threshold: number;
    model_name: string;
  }): Promise<ApiResponse<{ bundle_id: string }>> {
    return this.postJSON('prepare-bundle/', params);
  }

  async downloadBundle(
    bundleId: string,
  ): Promise<{ bundle_zip_url: string; project_id: ProjectId }> {
    const x_properties: ModelDownloadXEventProperty = {
      model_id: bundleId,
      source: 'LL',
    };
    const x_event: XEvent<ModelDownloadXEventProperty> = {
      event: 'model_download',
      action: 'GET',
      properties: x_properties,
    };
    return this.get(`inference-bundle/${bundleId}`, undefined, true, {
      headers: { 'x-event': JSON.stringify(x_event) } as Record<string, any>,
    });
  }

  /**
   * Note: For old bundle records, job_id is empty and bundle_id is job ID.
   */
  async listBundles(projectId: ProjectId): Promise<
    [
      {
        bundle_id: string;
        model_version: string;
        threshold?: number | null;
        job_id?: string | null;
        created_at?: string | null;
      },
    ]
  > {
    return this.get(`inference-bundles/${projectId}`, undefined, true);
  }
}

class CLIBAPIV0 extends BaseAPI {
  async putBundle(params: {
    projectId: ProjectId;
    modelId: string;
    threshold: number;
  }): Promise<{ bundleId: string }> {
    return this.putJSON('bundle', params) as unknown as Promise<{
      bundleId: string;
    }>;
  }
}

export const clibAPIV0 = new CLIBAPIV0('clib', API_GATEWAY_URL);

export default new CLIBAPI(prefix, API_GATEWAY_URL);

import React, { useCallback } from 'react';
import ImageEnhancer from '../ImageEnhancer';
import { useImageEnhancerState, initialState } from '../state';

export interface ImageEnhancerContainerProps {
  hasPaperContainer?: boolean;
  hideApplyToAll?: boolean;
  iconColor?: string;
}

const ImageEnhancerContainer: React.FunctionComponent<ImageEnhancerContainerProps> = ({
  hasPaperContainer = false,
  hideApplyToAll = false,
  iconColor,
}) => {
  const { state, dispatch } = useImageEnhancerState();

  const setBrightness = useCallback(
    (brightness: number) => {
      dispatch(draft => {
        draft.brightness = brightness;
      });
    },
    [dispatch],
  );
  const setContrast = useCallback(
    (contrast: number) => {
      dispatch(draft => {
        draft.contrast = contrast;
      });
    },
    [dispatch],
  );
  const toggleHistogram = useCallback(() => {
    dispatch(draft => {
      draft.histogramEnabled = !draft.histogramEnabled;
      draft.autoContrastEnabled = false;
    });
  }, [dispatch]);
  const toggleAutoContrast = useCallback(() => {
    dispatch(draft => {
      draft.autoContrastEnabled = !draft.autoContrastEnabled;
      draft.histogramEnabled = false;
    });
  }, [dispatch]);

  const resetEnhancers = useCallback(() => {
    dispatch(draft => {
      draft.autoContrastEnabled = initialState.autoContrastEnabled;
      draft.brightness = initialState.brightness;
      draft.contrast = initialState.contrast;
      draft.histogramEnabled = initialState.histogramEnabled;
    });
  }, [dispatch]);

  return (
    <ImageEnhancer
      brightness={state.brightness}
      contrast={state.contrast}
      histogramEnabled={state.histogramEnabled}
      autoContrastEnabled={state.autoContrastEnabled}
      setContrast={setContrast}
      setBrightness={setBrightness}
      toggleHistogram={toggleHistogram}
      toggleAutoContrast={toggleAutoContrast}
      resetEnhancers={resetEnhancers}
      hasPaperContainer={hasPaperContainer}
      hideApplyToAll={hideApplyToAll}
      iconColor={iconColor}
    />
  );
};

export default ImageEnhancerContainer;

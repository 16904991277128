import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const CUSTOM_EVENT_KEY = 'searchparamchange';

type CustomEventPayload = {
  searchParam: string;
  newValue: string;
};

/**
 * Build on top of basic string setState, this hook synchronize the state value to a provided search param
 * @param searchParam the search param to synchronize
 * @param fallbackInitValue the default value for setState is the current searchParam value, if it doesn't exist,
 * use this fallback value
 * @param disableSearchParam if this is true, this will be a regular setState
 */
const useStateSyncSearchParams = (
  searchParam: string,
  fallbackInitValue: string,
  disableSearchParam?: boolean,
): [string, React.Dispatch<React.SetStateAction<string>>] => {
  const history = useHistory();

  // get current searchParams
  const currentSearchParams = new URLSearchParams(history?.location?.search);
  // current value of given param
  const searchParamValue = currentSearchParams.get(searchParam);
  // state
  const [value, setValue] = useState<string>(
    disableSearchParam ? fallbackInitValue : searchParamValue ?? fallbackInitValue,
  );
  // synchronize state value to url search param
  useEffect(() => {
    const currentSearchParams = new URLSearchParams(history?.location?.search);
    const oldValue = currentSearchParams.get(searchParam) ?? '';
    if (!disableSearchParam && oldValue !== value) {
      // update currentSearchParams with new value
      if (value) {
        currentSearchParams.set(searchParam, value);
      } else {
        currentSearchParams.delete(searchParam);
      }
      const query = currentSearchParams.toString();
      history?.replace(history.location.pathname + (query ? '?' + query : ''));
      window.dispatchEvent(
        new CustomEvent<CustomEventPayload>(CUSTOM_EVENT_KEY, {
          detail: {
            searchParam,
            newValue: value,
          },
        }),
      );
    }
  }, [disableSearchParam, history, searchParam, value]);

  // when search param is updated (e.g. in the other places) we also update the state here
  useEffect(() => {
    const handler = ((e: CustomEvent<CustomEventPayload>) => {
      // Use the value from the event details instead of the centralized url to avoid stale value pollution and the
      // following unnecessary events dispatched
      if (!disableSearchParam && searchParam === e.detail.searchParam) {
        setValue(e.detail.newValue);
      }

      // Reference: https://github.com/microsoft/TypeScript/issues/28357#issuecomment-436484705
    }) as EventListener;

    window.addEventListener(CUSTOM_EVENT_KEY, handler);

    return () => {
      window.removeEventListener(CUSTOM_EVENT_KEY, handler);
    };
  }, [disableSearchParam, searchParam, setValue]);

  // return value and setValue
  return [value, setValue];
};

export default useStateSyncSearchParams;

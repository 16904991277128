import { useCurrentSubscription } from '@/hooks/useSubscriptions';
import { Typography } from '@clef/client-library';
import { SubscriptionName, SubscriptionStatus } from '@clef/shared/types';
import { Box, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import { planAttributes, plans } from './constants';
import PlanButton from './PlanButton';

const useStyles = makeStyles(theme => ({
  planRow: {
    display: 'flex',
    border: `1px solid ${theme.palette.greyModern[100]}`,
    borderBottom: 'none',
    '&:first-of-type': {
      borderColor: 'transparent',
      '& $planColumn': {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        borderTop: `1px solid ${theme.palette.greyModern[300]}`,
      },
    },
    '&:last-of-type': {
      borderBottom: `1px solid ${theme.palette.greyModern[100]}`,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      '& $planColumn': {
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        borderBottom: `1px solid ${theme.palette.greyModern[300]}`,
      },
    },
    '&:nth-child(2)': {
      borderTopLeftRadius: 20,
    },
    '& svg': {
      verticalAlign: 'middle',
    },
  },
  planAttributeNameColumn: {
    flex: '1 1 350px',
    padding: theme.spacing(3, 6),
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
  planColumn: {
    flex: '1 1 280px',
    minWidth: 240,
    marginLeft: theme.spacing(7),
    whiteSpace: 'nowrap',
    fontSize: 14,
    borderLeft: `1px solid ${theme.palette.greyModern[300]}`,
    borderRight: `1px solid ${theme.palette.greyModern[300]}`,
    '&.current': {
      backgroundColor: theme.palette.greyModern[25],
    },
    padding: theme.spacing(3, 6),
  },
  planTitleCard: {
    borderRadius: 20,
  },
  planTitleText: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  priceText: {
    fontWeight: 500,
    fontSize: 32,
    lineHeight: '40px',
  },
  currentPlanBadge: {
    borderRadius: 5,
    backgroundColor: 'white',
    padding: theme.spacing(1, 2),
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
    marginLeft: theme.spacing(2),
  },
}));

export type PlanFeaturesTableProps = {};

const PlanFeaturesTable: React.FC<PlanFeaturesTableProps> = () => {
  const styles = useStyles();
  const subscription = useCurrentSubscription();
  const currentPlan = subscription?.meta.plan as SubscriptionName | undefined;
  const toBeCanceled = subscription?.status === SubscriptionStatus.ToBeCanceled;

  return (
    <>
      <Box id="plan-features-table">
        <Box id="plan-row" className={styles.planRow}>
          <Box className={styles.planAttributeNameColumn}>&nbsp;</Box>
          {plans.map(plan => (
            <Box
              className={classNames(
                styles.planColumn,
                plan.key,
                plan.key === currentPlan && 'current',
              )}
              key={plan.key}
              data-testid={`plan-${plan.key}`}
            >
              <Box
                className={classNames(styles.planTitleCard, plan.key)}
                paddingBottom={6}
                paddingTop={3}
              >
                <Box className={styles.planTitleText}>{plan.name}</Box>
                <Box marginTop={2}>{plan.description}</Box>
              </Box>
              <Box display="flex" marginTop={1}>
                <Box className={styles.priceText}>{plan.price}</Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  justifyContent="center"
                  marginLeft={3}
                >
                  {plan.priceDescriptions.map((description, descriptionIndex) => (
                    <Typography
                      key={`plan-${plan.key}-description-${descriptionIndex}`}
                      variant="body_small"
                    >
                      {description}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Box marginTop={4}>
                <PlanButton
                  plan={plan.key}
                  isCurrent={plan.key === currentPlan}
                  toBeCanceled={toBeCanceled}
                />
              </Box>
            </Box>
          ))}
        </Box>
        {planAttributes.map(attribute => (
          <Box
            key={attribute.key}
            id={`plan-attribute-${attribute.key}`}
            className={styles.planRow}
          >
            <Box className={styles.planAttributeNameColumn}>
              <Typography variant="body_bold" maxWidth={250}>
                {attribute.name}
              </Typography>
            </Box>
            {plans.map(plan => (
              <Box
                key={`plan-${plan.key}-attribute-${attribute.key}`}
                className={classNames(
                  styles.planColumn,
                  plan.key,
                  plan.key === currentPlan && 'current',
                )}
              >
                {plan[attribute.key]}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
      <Box marginTop={4}>
        <Typography variant="body_regular">
          {t(
            '*The maximum number of credits that can be used in the Visionary plan is 500K per calendar month. Credit usage restarts at the start of each billing cycle, which is the first of each month.',
          )}
        </Typography>
      </Box>
    </>
  );
};

export default PlanFeaturesTable;

import { Theme, createStyles } from '@material-ui/core';
import { StyleRules, alpha } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    chipsContainerStyle: {
      textAlign: 'left',
      marginLeft: 0,
      marginRight: 0,
      overflow: 'scroll',
      display: 'contents',
    },
    spaceAll: {
      '& > *': {
        marginRight: theme.spacing(1),
      },
    },
    paddedRoot: {
      width: '100%',
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    chipsContainerValueSytle: {
      margin: theme.spacing(1),
      flex: '0 0 auto',
    },
    mIcon: {
      paddingLeft: '4px',
      marginTop: '10px',
    },
    leftIcon: {
      marginRight: '30px',
    },
    extFieldUnderline: {
      '&:before': {
        borderBottomColor: theme.palette.primary.light,
      },
      '&:after': {
        borderBottomColor: theme.palette.primary.main,
      },
      '&:hover:before': {
        borderBottomColor: `${theme.palette.primary.light} !important`,
      },
    },
    textField: {
      borderBottomColor: theme.palette.background.lightBlue,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    choicesListStyle: {
      border: '1px solid #d9dddd',
    },
    choicesItemStyleEven: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    choicesItemStyleOdd: {
      backgroundColor: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    addNewFieldButtonStyle: {
      textAlign: 'right',
      marginBottom: '20px',
      marginRight: '20px',
    },
    /**
     * Attach metadata
     */
    attachAddMetadataButton: {
      width: '180px',
      textAlign: 'left',
      paddingBottom: 8,
      margin: '10px',
      '& svg': {
        width: 16,
        height: 16,
      },
    },
    /**
     *  search Bar styles
     */
    searchBarRoot: {
      width: '100%',
      fontSize: 13,
      display: 'flex',
    },
    searchBarAddButton: {
      width: '100px',
      textAlign: 'left',
      paddingBottom: 8,
      margin: '10px',
      '& svg': {
        width: 16,
        height: 16,
      },
    },
    searchBarSearchButton: {
      minWidth: '100px',
      textAlign: 'right',
      paddingBottom: 8,
      margin: '10px',
    },
    searchBarPopper: {
      border: '1px solid rgba(27,31,35,.15)',
      boxShadow: '0 3px 12px rgba(27,31,35,.15)',
      borderRadius: 3,
      width: 300,
      zIndex: theme.zIndex.modal,
      fontSize: 13,
    },

    searchBarHeader: {
      borderBottom: '1px solid #e1e4e8',
      padding: '8px 10px',
      fontWeight: 500,
    },

    searchBarPaper: {
      boxShadow: 'none',
      margin: 0,
      fontSize: 13,
    },

    searchBarOption: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
    },

    searchBarPopperDisablePortal: {
      position: 'relative',
    },

    InputBase: {
      padding: '0px 5px 0px 5px',
      width: '100%',
      borderBottom: '1px solid #dfe2e5',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontSize: 14,
        '&:focus': {
          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    },

    searchBarInputBase: {
      padding: 10,
      width: '100%',
      borderBottom: '1px solid #dfe2e5',
      '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: '1px solid #ced4da',
        fontSize: 14,
        '&:focus': {
          boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
          borderColor: theme.palette.primary.main,
        },
      },
    },

    /**
     *  Filter Unit Styles
     */
    filterUnitPaper: {
      minHeight: '50px',
      textAlign: 'left',
      backgroundColor: '#fff',
      paddingTop: '7px',
      position: 'relative',
      boxShadow:
        '0px 2px 20px -1px rgba(0,0,0,0.2), 0px 1px 20px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    deleteButton: {
      padding: '1px',
      position: 'absolute',
      top: '0px',
      right: '0px',
    },
    filterUnitFieldName: {
      margin: '5px 10px 0px 10px',
      fontSize: '1rem',
    },
    filterUnitSelect: {
      marginRight: '10px',
    },
    filterUnitPredefinedChoiceSelectButton: {
      fontSize: 13,
      width: '100%',
      fontWeight: 500,
      padding: '4px 2px 0px 0px',
      minWidth: '10px',
      '& svg': {
        width: 24,
        height: 24,
      },
    },
  });

export default styles;

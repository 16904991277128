import React, { useCallback } from 'react';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useMediaMetadataApi, useMetadataApi } from '../../hooks/api/useMetadataApi';
import { ApiResponseLoader, Dropdown } from '@clef/client-library';
import { Typography, Chip, Divider, makeStyles } from '@material-ui/core';
import { MetadataObjectValue, Metadata, MetadataValueType, MetadataType } from '@clef/shared/types';
import SetMetadataDropdown from '../../components/SetMetadata/SetMetadataDropdown';
import { attachMetadataToSingleMedia } from '../../hooks/api/useMetadataApi';
import { useSnackbar } from 'notistack';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';

export interface MetadataPanelProps {
  mediaId: number;
}

const useStyles = makeStyles(theme => ({
  metadataChip: {
    color: theme.palette.grey[500],
    borderColor: theme.palette.grey[500],
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  subPanelContainer: {
    marginBottom: theme.spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  subPanelDivider: {
    marginBottom: theme.spacing(1),
  },
}));

const ValueView: React.FC<{ value: MetadataObjectValue }> = ({ value }) => {
  const styles = useStyles();
  if (value === undefined) return <></>;

  return (
    <>
      {Array.isArray(value) ? (
        value.map((v: any) => <ValueView key={v} value={v} />)
      ) : (
        <Chip
          size="small"
          variant="outlined"
          component="span"
          label={
            <Typography variant="body2" data-testid={`media-details-metadata-value-${value}`}>
              {`${value}`}
            </Typography>
          }
          className={styles.metadataChip}
          clickable={false}
        />
      )}
    </>
  );
};

const MetadataPanel: React.FC<MetadataPanelProps> = ({ mediaId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  const [mediaMetadata, mediaMetadataLoading, mediaMetadataError] = useMediaMetadataApi(
    selectedProject
      ? {
          projectId: selectedProject.id,
          mediaId,
        }
      : undefined,
  );
  const [metadataMap, metadataMapLoading, metadataMapError] = useMetadataApi(
    selectedProject ? selectedProject.id : undefined,
  );

  const handleSetMetadata = useCallback(
    (metadata: Metadata) => async (value: string[] | number[]) => {
      let valueReformatted: MetadataValueType;
      if (metadata.type === MetadataType.Boolean) {
        valueReformatted = value?.[0] === t('true');
      } else if (!metadata.allowMultiple) {
        valueReformatted = metadata.type === MetadataType.Number ? Number(value?.[0]) : value?.[0];
      } else {
        valueReformatted =
          metadata.type === MetadataType.Number
            ? (value as string[]).map(val => Number(val))
            : value;
      }
      try {
        if (!selectedProject?.id) {
          return;
        }
        await attachMetadataToSingleMedia(
          mediaId,
          { [metadata.id]: valueReformatted },
          undefined,
          selectedProject,
          currentModelId,
        );
        enqueueSnackbar(t('Metadata {{temp0}} added', { temp0: metadata.name }), {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, mediaId, currentModelId, selectedProject],
  );

  return (
    <>
      <ApiResponseLoader
        response={mediaMetadata && metadataMap ? { mediaMetadata, metadataMap } : undefined}
        loading={mediaMetadataLoading || metadataMapLoading}
        error={mediaMetadataError || metadataMapError}
        defaultHeight={100}
      >
        {({ metadataMap: metadataMapLoaded, mediaMetadata: mediaMetadataLoaded }) => {
          const appliedMetadataIds = Object.keys(metadataMapLoaded!).filter(
            metadataId => mediaMetadataLoaded[metadataId] !== undefined,
          );

          return (
            <>
              {appliedMetadataIds.map(metadataId => {
                const metadata = metadataMapLoaded![metadataId];
                const metadataObjectValue = mediaMetadataLoaded[metadataId];
                return (
                  <div key={metadataId} className={styles.subPanelContainer}>
                    <Typography variant="caption">{metadata.name}</Typography>
                    <Divider className={styles.subPanelDivider} />
                    <ValueView value={metadataObjectValue} />
                  </div>
                );
              })}
            </>
          );
        }}
      </ApiResponseLoader>
      <Dropdown
        extraGutter={{ vertical: 8 }}
        dropdown={toggleDropdown => (
          <SetMetadataDropdown
            setMetadataAction={(metadata: Metadata) => async (value: string[] | number[]) => {
              await handleSetMetadata(metadata)(value);
              toggleDropdown(false);
            }}
          />
        )}
      >
        <Typography variant="body2" component="div" color="primary">
          <strong>{t('Add metadata')}</strong>
        </Typography>
      </Dropdown>
    </>
  );
};

export default MetadataPanel;

import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import cx from 'classnames';

import { useStyles } from './styles';

export const TermsAndConditionsCheckbox: React.FC<{
  error: FieldError | undefined;
  control: Control<{
    reviewedTerms: boolean;
  }>;
  className?: string;
}> = ({ error, control, className }) => {
  const styles = useStyles();

  return (
    <Controller
      as={
        <div>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                className={cx(error && styles.reviewedTermsCheckboxErrorState)}
              />
            }
            label={
              <>
                <Typography className={styles.termsText1}>
                  {t('I have reviewed the LandingLens')}
                </Typography>{' '}
                <Typography
                  className={cx(styles.termsText2, styles.newTermsText2)}
                  onClick={() =>
                    window.open(
                      'https://landing.ai/terms-of-service/',
                      '_blank',
                      'noopener,noreferrer',
                    )
                  }
                >
                  {t('Terms of Service')}
                </Typography>
              </>
            }
          />
          <Typography variant="body2" color="secondary" className={styles.reviewedTermsError}>
            {error?.message}
          </Typography>
        </div>
      }
      rules={{
        required: t('This is required.'),
      }}
      defaultValue={''}
      name="reviewedTerms"
      control={control}
      className={cx(styles.normalInputBox, className)}
    />
  );
};

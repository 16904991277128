import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  createDialog: {
    width: 418,
    minHeight: 370,
    padding: theme.spacing(6),
  },
  dialogSection: {
    padding: theme.spacing(0),
  },
  dialogContent: {
    position: 'relative',
    overflowX: 'hidden',
  },
  contentSummary: {
    position: 'relative',
    bottom: 0,
    left: 0,
    width: '100%',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'end',
  },
  dialogTitleText: {
    color: theme.palette.greyModern[900],
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  dialogActions: {
    width: '100%',
    justifyContent: 'end',
  },
  opsButton: {
    borderColor: theme.palette.grey[300],
    color: 'white',
  },
  normalFont: {
    fontSize: 14,
    fontWeight: 400,
    color: '#4D5761',
  },
  reminderBox: {
    marginTop: theme.spacing(4),
    color: '#4D5761',
  },
  emphasizeFont: {
    display: 'inline',
    fontWeight: 500,
  },
  fieldTitle: {
    margin: theme.spacing(4, 0, 2, 0),
  },
  dialogInput: {
    height: 38,
    borderRadius: 6,
  },
  descInputWrapper: {
    alignItems: 'flex-start',
    minHeight: 76,
    borderRadius: 6,
  },
  descInput: {
    lineHeight: '20px',
  },
  accordionRoot: {
    marginTop: `${theme.spacing(3)}px!important`,
  },
  accordionDetailsRoot: {
    flexDirection: 'column',
    padding: 0,
  },
  settingDetails: {
    position: 'relative',
    marginTop: theme.spacing(3),
    padding: theme.spacing(6, 3, 3, 3),
    color: theme.palette.greyModern[800],
    backgroundColor: theme.palette.greyModern[50],
    borderRadius: 6,
  },
  detailTitle: {
    position: 'absolute',
    top: theme.spacing(4),
    fontSize: 14,
    fontWeight: 700,
  },
  emptyChart: {
    height: 6,
    width: '100%',
    borderRadius: 2,
    backgroundColor: theme.palette.greyBlue[300],
    margin: theme.spacing(6, 0, 3, 0),
  },
  detailDescWrapper: {
    marginTop: theme.spacing(2),
    fontWeight: 500,

    '& button': {
      position: 'relative',
      left: theme.spacing(-4),
      color: theme.palette.greyModern[800],
    },
  },
  detailSmallTips: {
    position: 'relative',
    fontSize: 12,
    top: theme.spacing(-1.5),
    left: theme.spacing(10),
  },
}));

import React, { useState } from 'react';
import { Box, LinearProgress, makeStyles } from '@material-ui/core';
import { greyModernScale, IconButton, Typography } from '@clef/client-library';
import {
  useDeleteModelComparisonReportMutation,
  useModelComparisonReportsQuery,
} from '@/serverStore/modelComparisonReports';
import MatTable from '@/components/Utils/MatTable';
import { Column } from '@material-table/core';
import { ModelComparisonReportResponseItem } from '@clef/shared/types';
import { getEvaluationSetName, ModelComparisonSelectionInfo } from './componentsV2/utils';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import StarBorder from '@material-ui/icons/StarBorder';
import ModelComparisonDialog from './componentsV2/ModelComparison/ModelComparisonDialog';
import { useDialog } from '@/components/Layout/components/useDialog';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    '& .MuiPaper-root': {
      outline: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 8,
      overflow: 'hidden',
      boxShadow: 'none !important',
    },
  },
}));

export const tableStyleOptions: Record<string, React.CSSProperties> = {
  headerStyle: {
    backgroundColor: greyModernScale[50],
    height: 61,
  },
  rowStyle: {
    fontSize: 13,
    height: 61,
  },
};

export type ModelComparisonReportsPageProps = {};

// This is the comparison reports page for fast & easy models
const ModelComparisonReportsPage: React.FC<ModelComparisonReportsPageProps> = () => {
  const styles = useStyles();
  const {
    data: comparisonResponse,
    isInitialLoading,
    isRefetching,
  } = useModelComparisonReportsQuery();
  const deleteComparisonReportMutation = useDeleteModelComparisonReportMutation();
  const { showConfirmationDialog } = useDialog();
  const loading = isInitialLoading || isRefetching;
  const columns: Column<ModelComparisonReportResponseItem>[] = [
    {
      id: 'report-name',
      title: t('Report Name'),
      render: item => (
        <Typography variant="body_regular" maxWidth={300}>
          {item.name ?? t('Untitled Report')}
        </Typography>
      ),
    },
    {
      id: 'baseline-run',
      title: t('Baseline'),
      render: item => (
        <Typography variant="body_regular" maxWidth={300}>
          {item.baseModel.modelName ?? t('Untitled Model')}
          {item.baseThreshold ? ', ' + item.baseThreshold : ''}
        </Typography>
      ),
    },
    {
      id: 'candidate-run',
      title: t('Candidate'),
      render: item => (
        <Typography variant="body_regular" maxWidth={300}>
          {item.candidateModel.modelName ?? t('Untitled Model')}
          {item.candidateThreshold ? ', ' + item.candidateThreshold : ''}
        </Typography>
      ),
    },
    {
      id: 'evaluation-set',
      title: t('Evaluation Set'),
      render: item => (
        <Typography variant="body_regular" maxWidth={300}>
          {getEvaluationSetName(item.evaluationSet)}
        </Typography>
      ),
    },
    {
      id: 'operations',
      title: t(''),
      width: 110,
      render: item => (
        <Box display="flex" alignItems="center">
          <IconButton
            id="toggle-comparison-report"
            size="small"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              // TODO: call mutate query
            }}
          >
            <StarBorder />
          </IconButton>
          <IconButton
            id="remove-comparison-report"
            size="small"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              showConfirmationDialog({
                title: t('Delete Model Comparison Report'),
                content: t('Are you sure you want to delete this model?'),
                confirmText: t('Delete'),
                color: 'secondary',
                onConfirm: () => {
                  deleteComparisonReportMutation.mutate(item.id);
                },
              });
            }}
          >
            <DeleteOutlined />
          </IconButton>
        </Box>
      ),
    },
  ];
  const [comparisonSelectionInfo, setComparisonSelectionInfo] =
    useState<ModelComparisonSelectionInfo>();

  return (
    <Box>
      <Box marginTop={5} position="relative" className={styles.tableContainer}>
        {loading && (
          <LinearProgress style={{ width: '100%', position: 'absolute', bottom: '100%' }} />
        )}
        <MatTable<ModelComparisonReportResponseItem>
          title=""
          data={comparisonResponse ?? []}
          columns={columns}
          options={{
            ...tableStyleOptions,
            search: false,
            toolbar: false,
            padding: 'dense',
            pageSize: 10,
          }}
          onRowClick={(_, item) => {
            item?.baseModel &&
              item.candidateModel &&
              item.evaluationSet &&
              setComparisonSelectionInfo({
                baseline: { ...item.baseModel, threshold: item.baseThreshold },
                candidate: { ...item.candidateModel, threshold: item.candidateThreshold },
                evaluationSet: item.evaluationSet,
              });
          }}
        />
      </Box>
      {comparisonSelectionInfo && (
        <ModelComparisonDialog
          open={!!comparisonSelectionInfo}
          onClose={() => setComparisonSelectionInfo(undefined)}
          comparisonSelectionInfo={comparisonSelectionInfo}
        />
      )}
    </Box>
  );
};

export default ModelComparisonReportsPage;

import { FilterOptionName } from '@clef/shared/types';
import { Grid, makeStyles, Tooltip, Box } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import { startCase } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useGetDatasetFilterOptionsQuery } from '@/serverStore/dataset';
import { AppliedFilterType, useDataBrowserState } from '../dataBrowserState';
import { NoneOption } from '../MediaFilter/FilterDropdownPredefinedChoices';

const useStyles = makeStyles(theme => ({
  filterChip: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    backgroundColor: theme.palette.grey[200],
    borderRadius: 6,
    padding: theme.spacing(1, 1.5),
  },
  removeFilterIcon: {
    fontSize: 14,
    color: theme.palette.grey[400],
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.grey[500],
    },
  },
  filterName: {
    maxWidth: 120,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  filterValue: {
    maxWidth: 120,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  filterOperation: {
    margin: theme.spacing(0, 0.5),
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));

const FilterOperationDisplayNames: Record<string, string> = {
  CONTAINS_ANY: '=',
  // NOT_CONTAIN_ANY means Unassigned so we will just display FilterOption = Unassigned
  NOT_CONTAIN_ANY: '=',
  BETWEEN: t('between'),
  IS: t('is'),
  LIKE: t('like'),
};

export type AppliedFiltersProps = {};

const AppliedFilters: React.FC<AppliedFiltersProps> = () => {
  const styles = useStyles();
  const { state, dispatch } = useDataBrowserState();
  const { appliedFilters } = state;
  const { data: allFilters } = useGetDatasetFilterOptionsQuery();

  const filterOptionValuesToNames = useMemo(() => {
    return allFilters?.reduce((acc, { filterName, value }) => {
      acc[filterName] = acc[filterName] || {};
      Object.entries(value ?? {}).forEach(([optionName, optionValue]) => {
        acc[filterName][String(optionValue)] = optionName;
      });
      return acc;
    }, {} as { [filterName: string]: { [filterValue: string]: string } });
  }, [allFilters]);

  const formatFilterValue = useCallback(
    (filterName: string, filter: AppliedFilterType) => {
      const { v: value, o: operation } = filter;
      const toStartCaseIfNeeded = (value: string | number) => {
        if (
          (filterName === FilterOptionName.MediaStatus || filterName === FilterOptionName.Split) &&
          typeof value === 'string'
        ) {
          return startCase(value);
        }
        return value;
      };
      // wrap quotes around the value for LIKE operation
      if (operation === 'LIKE') {
        return `"${toStartCaseIfNeeded(value as string)}"`;
      }
      // NOT_CONTAIN_ANY means Unassigned so we will just display FilterOption = Unassigned
      else if (operation === 'NOT_CONTAIN_ANY') {
        return NoneOption;
      } else if (Array.isArray(value)) {
        return value
          .map(v => t(filterOptionValuesToNames?.[filterName]?.[String(v)] ?? String(v)))
          .map(toStartCaseIfNeeded)
          .join(', ');
      }

      return value;
    },
    [filterOptionValuesToNames],
  );

  const formattedFilters = useMemo(
    () =>
      Object.entries(appliedFilters)
        .map(([filterName, filter]) => ({
          name: t(filterName),
          value: formatFilterValue(filterName, filter),
          operation: filter.o,
        }))
        .filter(u => u.value),
    [appliedFilters, formatFilterValue],
  );

  const removeFilter = useCallback(
    (filterName: string) => {
      dispatch(draft => {
        delete draft.appliedFilters[filterName];
      });
    },
    [dispatch],
  );

  if (!formattedFilters.length) {
    return null;
  }

  return (
    <>
      <Box marginTop={4} />
      <Grid container spacing={1} data-testid="applied-filters">
        {formattedFilters.map(({ name, value, operation }) => (
          <Grid item key={name}>
            <Tooltip
              placement="top"
              arrow
              title={`${name} ${FilterOperationDisplayNames[operation] ?? operation} ${value}`}
            >
              <div className={styles.filterChip}>
                <div className={styles.filterName}>{name}</div>
                <div className={styles.filterOperation}>
                  {FilterOperationDisplayNames[operation] ?? operation}
                </div>
                <div className={styles.filterValue}>{value}</div>
                <Cancel className={styles.removeFilterIcon} onClick={() => removeFilter(name)} />
              </div>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default AppliedFilters;

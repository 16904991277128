import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core';
import { Button } from '@clef/client-library';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';

import Header from '../components/Header/FREHeader';
import FRESideBar from '../components/Sidebar/FRESideBar';

import { PageTitle, MetaTitle } from '../components/Title';
import Banner from './Banner';
import { usePageLayoutState } from './state';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    minHeight: '100vh',
    overflow: 'hidden',
    backgroundColor: theme.palette.indigoBlue[25],
  },
  bannerWrapper: {
    marginTop: theme.spacing(-6),
  },
  contentWithHeader: {
    flexGrow: 1,
    width: '100%',
    minHeight: '100vh',
    // For defect book printing
    '@media print': {
      padding: theme.spacing(2),
    },
    position: 'relative',
    padding: theme.spacing(18, 8, 8, 8),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  contentWithSidebar: {
    flex: 1,
    padding: theme.spacing(18, 0, 0, 0),
    '& $innerContent': {
      backgroundColor: 'white',
      borderRadius: '8px 0 0 8px',
      boxShadow: '0px 0px 24px rgba(10, 100, 252, 0.05)',
    },
  },
  contentWithAnnouncementBanner: {
    paddingTop: theme.spacing(28),
  },
  noPadding: {
    padding: 0,
  },
  contentMaxWidthCenter: {
    maxWidth: 1920,
    width: '100%',
    margin: theme.spacing(0, 'auto'),
  },
  innerContent: {
    padding: theme.spacing(7, 8),
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  fixedHeightContent: {
    height: 'calc(100vh - 108px)',
    position: 'relative',
  },
  newBackButton: {
    alignSelf: 'flex-start',
    marginLeft: -theme.spacing(5),
    marginBottom: theme.spacing(4),
  },
  whiteBackground: {
    backgroundColor: theme.palette.common.white,
  },
  greyBackground: {
    backgroundColor: theme.palette.greyModern[100],
  },
  purpleBackground: {
    backgroundColor: theme.palette.purple[50],
  },
}));

/**
 * Currently used by "first run experience" feature.
 */
const PageLayoutRoot: React.FC<{}> = ({ children }) => {
  const styles = useStyles();
  const { state } = usePageLayoutState();
  const {
    showHeader,
    showSidebar,
    pageTitle,
    pageTitleBreadcrumb,
    metaTitle,
    fullScreenContent,
    fixedHeightContent,
    showProjectBanner,
    showWhiteBackground,
    showGreyBackground,
    showPurpleBackground,
    noPadding,
    showBetaAnnouncement,
  } = state;

  return (
    <main
      className={cx(
        styles.root,
        showWhiteBackground && styles.whiteBackground,
        showGreyBackground && styles.greyBackground,
        showPurpleBackground && styles.purpleBackground,
      )}
    >
      {showHeader && <Header />}
      {showSidebar && <FRESideBar />}

      <div
        className={cx(
          styles.content,
          showHeader && styles.contentWithHeader,
          showSidebar && styles.contentWithSidebar,
          showHeader && showBetaAnnouncement && styles.contentWithAnnouncementBanner,
          noPadding && styles.noPadding,
        )}
      >
        {showProjectBanner && (
          <div className={styles.bannerWrapper}>
            <Banner />
          </div>
        )}
        <div
          className={cx(
            showHeader && showSidebar && styles.innerContent,
            !fullScreenContent && styles.contentMaxWidthCenter,
            fixedHeightContent && styles.fixedHeightContent,
          )}
        >
          {pageTitleBreadcrumb && (
            <Button
              id="page-title-breadcrumb"
              color="primary"
              variant="text"
              startIcon={<ArrowBackIcon color="primary" />}
              className={styles.newBackButton}
              component={Link}
              to={pageTitleBreadcrumb?.to}
            >
              {pageTitleBreadcrumb.label}
            </Button>
          )}
          {/* Page title */}
          {pageTitle && <PageTitle title={pageTitle} />}
          {/* Meta title */}
          {metaTitle && <MetaTitle title={metaTitle} />}
          {children}
        </div>
      </div>
    </main>
  );
};

export default PageLayoutRoot;

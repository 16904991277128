import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { Typography, Grid, Box } from '@material-ui/core';
import cx from 'classnames';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

import { useStyles } from './styles';
import AuthAPI from '../../api/auth_api';
import { LandingLensLogo } from '../../components/Auth/Common';
import { BaseTextField, PasswordTextField } from '../../components/Auth/TextField';
import { TermsAndConditionsCheckbox } from '../../components/Auth/Checkbox';
import { PrimaryButton } from '../../components/Auth/Button';
import { loginAccountFailure, loginAccountSuccess } from '../../store/newLoginState/actions';
import { AuthPageLayout } from '../../components/Layout/AuthPageLayout';

interface FormValues {
  firstName: string;
  lastName: string;
  password: string;
  reviewedTerms: boolean;
}

export const SelfServiceCompleteSignup: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isSigningUp, setIsSigningUp] = useState(false);
  const { control, errors, handleSubmit, watch } = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
      reviewedTerms: false,
    },
  });

  const onSubmit = useCallback(
    async ({ firstName, lastName, password }: FormValues) => {
      const searchParams = new URLSearchParams(history?.location?.search);
      const email = searchParams.get('email') ?? '';
      const activationCode = searchParams.get('activationCode') ?? '';
      // Clear the query params, but keep UTM params if exists
      searchParams.delete('email');
      searchParams.delete('activationCode');

      setIsSigningUp(true);

      try {
        await AuthAPI.newSignup({
          email,
          name: firstName,
          lastname: lastName,
          password,
          activationCode,
        });
        enqueueSnackbar('Account has been created!', { variant: 'success' });

        // After dispatching this action, auth router will auto redirect to join org page
        dispatch(loginAccountSuccess(null));

        const searchString = searchParams.toString();
        history.replace(history.location.pathname + searchString ? `?${searchString}` : '');
      } catch (e) {
        enqueueSnackbar(e?.message, { variant: 'error', autoHideDuration: 12000 });
        dispatch(loginAccountFailure(e));
        setIsSigningUp(false);
      }
    },
    [dispatch, enqueueSnackbar, history],
  );

  return (
    <AuthPageLayout metaTitle="Complete Signup">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minWidth="100vw"
        minHeight="100vh"
        position="relative"
      >
        <LandingLensLogo />

        <Typography variant="h1" className={styles.helperText}>
          {t('Complete Your Account Setup')}
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Grid container className={styles.marginBottom8}>
            <BaseTextField
              label={t('First Name')}
              rules={{
                required: t('This is required.'),
              }}
              name="firstName"
              error={errors?.firstName}
              control={control}
              className={cx(styles.smallInputBox, styles.marginRight8)}
              enableAutoComplete={false}
              standalongLabel
            />

            <BaseTextField
              label={t('Last Name')}
              rules={{
                required: t('This is required.'),
              }}
              name="lastName"
              error={errors?.lastName}
              control={control}
              className={styles.smallInputBox}
              enableAutoComplete={false}
              standalongLabel
            />
          </Grid>

          <PasswordTextField
            error={errors?.password}
            control={control}
            watch={watch}
            className={cx(styles.normalInputBox, styles.marginBottom8)}
            showHint
            enablePatternCheck
            enableAutoComplete={false}
          />

          <TermsAndConditionsCheckbox
            error={errors?.reviewedTerms}
            control={control}
            className={styles.marginBottom6}
          />

          <PrimaryButton
            id="complete-sign-up-with-email"
            text={t('Create LandingLens Account')}
            disabled={
              !!errors.firstName || !!errors.lastName || !!errors.password || !!errors.reviewedTerms
            }
            isPending={isSigningUp}
            useNewUi
          />
        </Box>
      </Box>
    </AuthPageLayout>
  );
};

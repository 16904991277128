import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import { uniq } from 'lodash';
import { ApiResponseLoader, Button } from '@clef/client-library';
import { useAttachTagStringsToMediasMutation, useGetProjectTagsQuery } from '@/serverStore/tags';
import {
  makeStyles,
  Box,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  TextField,
  FormLabel,
} from '@material-ui/core';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { SelectMediaOption } from '@clef/shared/types';
import { useDataBrowserState } from '@/pages/DataBrowser/dataBrowserState';

const useStyles = makeStyles(theme => ({
  dropdownPanel: {
    minWidth: 250,
    paddingTop: theme.spacing(4),
  },
  formControlRoot: {
    width: '100%',
  },
  searchBox: {
    padding: `0 ${theme.spacing(4)}px`,
  },
  checkboxGroup: {
    marginTop: '6px',
    marginBottom: theme.spacing(3),
    flexWrap: 'nowrap',
    height: '224px',
    overflow: 'auto',
  },
  optionItem: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(4),
    margin: 0,
    '&:hover': {
      backgroundColor: 'rgba(9, 30, 66, 0.08)',
    },
  },
  optionText: {
    marginRight: theme.spacing(3),
  },
  buttonGroup: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: `${theme.spacing(1)}px 0`,
  },
  createText: {
    color: theme.palette.grey[700],
    fontWeight: 500,
    lineHeight: '20px',
    paddingLeft: theme.spacing(5),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  spinner: {
    margin: `${theme.spacing(2)}px auto`,
  },
}));

interface IProps {
  closeDropdown: () => void;
  selectedMediaOptions: SelectMediaOption;
}

export const AddTagDropdown: React.FC<IProps> = ({ closeDropdown, selectedMediaOptions }) => {
  const styles = useStyles();
  const { data: tags, isFetching, error } = useGetProjectTagsQuery();
  const [newTags, setNewTags] = useState<string[]>([]);
  const [checkedTags, setCheckedTags] = useState<Record<string, boolean>>({});
  const selectedProjectId = useTypedSelector(state => state.project.selectedProjectId);

  const [searchText, setSearchText] = useState<string>('');
  const attachTags = useAttachTagStringsToMediasMutation();
  const { dispatch } = useDataBrowserState();

  const onAttachTags = () => {
    if (!selectedProjectId) return;
    const selectedTags = Object.keys(checkedTags).filter(tagName => !!checkedTags[tagName]);
    attachTags.mutate(
      {
        projectId: selectedProjectId,
        tags: selectedTags,
        selectOption: selectedMediaOptions,
        accumulation: true,
      },
      {
        onSuccess: () => {
          closeDropdown();
          dispatch(draft => {
            draft.selectingMediaMode = 'select';
            draft.selectedOrUnselectedMedia = [];
          });
        },
      },
    );
  };

  const filteredTags = useMemo(() => {
    if (!tags) return;
    const loadedTags = searchText
      ? tags.filter(
          tag =>
            tag.name.toLowerCase().startsWith(searchText.toLowerCase()) || checkedTags[tag.name],
        )
      : tags;
    return uniq([...loadedTags.map(tag => tag.name), ...newTags]);
  }, [searchText, tags, checkedTags, newTags]);

  const onToggleCheckbox = (name: string, status: boolean) => {
    setCheckedTags({
      ...checkedTags,
      [name]: status,
    });
  };

  const onAddNewTag = () => {
    setNewTags([...newTags, searchText]);
    onToggleCheckbox(searchText, true);
    setSearchText('');
  };

  const isAttachingTags = attachTags.isLoading;

  return (
    <ApiResponseLoader
      response={filteredTags}
      loading={isFetching}
      error={error}
      defaultHeight={250}
      defaultWidth={250}
    >
      {response => (
        <Box className={styles.dropdownPanel}>
          <FormControl className={styles.formControlRoot} disabled={isAttachingTags}>
            <TextField
              autoFocus
              className={styles.searchBox}
              size="small"
              variant="outlined"
              value={searchText}
              placeholder={t('Find or create tags')}
              onKeyPress={e => {
                if (e.key !== 'Enter') {
                  return;
                }
                if (
                  searchText &&
                  !response.some(tagName => tagName.toLowerCase() === searchText.toLowerCase())
                ) {
                  onAddNewTag();
                }
              }}
              onChange={e => setSearchText(e.target.value)}
            />
            <FormGroup className={styles.checkboxGroup}>
              {response
                .sort((tagA, tagB) => tagA.toLowerCase().localeCompare(tagB.toLowerCase()))
                .map(tagName => (
                  <FormControlLabel
                    key={tagName}
                    className={styles.optionItem}
                    control={
                      <Checkbox
                        color="primary"
                        name={tagName}
                        checked={!!checkedTags[tagName]}
                        onChange={e => onToggleCheckbox(tagName, e.target.checked)}
                      />
                    }
                    label={tagName}
                  />
                ))}
              {searchText &&
                !response.some(tagName => tagName.toLowerCase() === searchText.toLowerCase()) && (
                  <FormLabel
                    className={cx(styles.optionItem, styles.createText)}
                    onClick={() => onAddNewTag()}
                  >
                    {t('create: {{newTagName}}', {
                      newTagName: searchText,
                    })}
                  </FormLabel>
                )}
            </FormGroup>
          </FormControl>
          <Box className={styles.buttonGroup}>
            <Button
              size="small"
              onClick={() => closeDropdown()}
              disabled={isAttachingTags}
              id="cancel-tags-btn"
            >
              {t('Cancel')}
            </Button>
            <Button
              id="apply-add-tags-btn"
              color="primary"
              size="small"
              disabled={
                isAttachingTags ||
                Object.values(checkedTags).filter(isChecked => isChecked).length === 0
              }
              onClick={() => {
                onAttachTags();
              }}
            >
              {t('Apply')}
            </Button>
          </Box>
        </Box>
      )}
    </ApiResponseLoader>
  );
};

import React, { useMemo } from 'react';
import { Tooltip, TooltipProps, withStyles } from '@material-ui/core';

import { StepName } from '@/types/client';
import { useSteps } from '../state';

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#4D5761',
    fontSize: 14,
    fontWeight: 400,
    maxWidth: 600,
    padding: theme.spacing(3),
    pointerEvents: 'all',
    border: `1px solid ${theme.palette.blue[500]}`,
    boxShadow: '0px 1px 2px rgba(48, 55, 79, 0.16), 0px 2px 4px rgba(48, 55, 79, 0.08)',
    '& .MuiTooltip-arrow': {
      color: 'white',
    },
  },
  arrow: {
    color: theme.palette.common.white,
    '&:before': {
      border: `1px solid ${theme.palette.blue[500]}`,
    },
  },
}))(Tooltip);

export interface CustomTooltipProps extends Omit<TooltipProps, 'title'> {
  step: { stepName: StepName; stepStarted: boolean; stepPointsCompleted: number } | null;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ step, children, ...rest }) => {
  const steps = useSteps();
  const currentStepIndex = useMemo(
    () =>
      steps.findIndex(({ name }) => {
        return step?.stepName === name;
      }),
    [step?.stepName, steps],
  );

  const tooltipTitle = useMemo(() => {
    if (
      currentStepIndex !== -1 &&
      step?.stepName !== undefined &&
      step?.stepPointsCompleted !== undefined
    ) {
      if (step.stepName === StepName.Upload) {
        return t('Upload at least 1 image to start labeling!');
      } else if (step.stepName === StepName.Label) {
        return steps[currentStepIndex].requiredPoints - step.stepPointsCompleted === 1
          ? t('Label 1 more image to train your first model!')
          : t('Label {{count}} more images to train your first model!', {
              count: steps[currentStepIndex].requiredPoints - step.stepPointsCompleted,
            });
      } else if (step.stepName === StepName.Train) {
        return t('Your first model is ready to train now!');
      } else if (step.stepName === StepName.Predict) {
        return t("Try out your model's performance by showing it new images!");
      }
    }

    return '';
  }, [currentStepIndex, step?.stepName, step?.stepPointsCompleted, steps]);

  return (
    <StyledTooltip title={tooltipTitle} arrow placement="bottom" {...rest}>
      {children}
    </StyledTooltip>
  );
};

export default CustomTooltip;

import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { dataUrlToFile } from '../../../utils';
import { GetPredictions } from '../hooks/useLivePredictions';
import useStyles from './styles';
import WebCam from '@/components/WebCam';

const imageFormat = 'image/jpeg';
const imageName = () => new Date().toISOString() + '_WebcamCaptured';

interface WebcamCaptureProps {
  getPredictions: GetPredictions;
  onCloseCamera: () => void;
  disabled?: boolean;
}

const WebcamCapture: React.FC<WebcamCaptureProps> = ({
  getPredictions,
  onCloseCamera,
  disabled = false,
}) => {
  const styles = useStyles();
  const [disableShutter, setDisableShutter] = useState(true);

  useEffect(() => {
    setTimeout(() => setDisableShutter(false), 2000);
  }, []);

  const onCapture = useCallback(
    async (imageInBase64: string) => {
      onCloseCamera();

      if (imageInBase64) {
        const file = await dataUrlToFile(imageInBase64, imageName(), imageFormat);
        getPredictions([file]);
      }
    },
    [getPredictions, onCloseCamera],
  );

  return (
    <Box className={styles.webcamWrapper}>
      <WebCam
        width="100%"
        height="100%"
        captureDisabled={disabled || disableShutter}
        className={styles.webcam}
        onCapture={onCapture}
        onClose={onCloseCamera}
      />
    </Box>
  );
};

export default WebcamCapture;

import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { ERROR_SNACKBAR_STYLE } from '../../constants/metadata_constants';
import AuthAPI from '../../api/auth_api';
import { setAuthenticated } from '../../store/newLoginState/actions';

interface Props {
  routeProps: RouteComponentProps;
  encryptedLoginInfo: string;
}
const LdapLoginRoute: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const [path, setPath] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    AuthAPI.ldapLogin(props.encryptedLoginInfo)
      .then(response => {
        localStorage.setItem('clef_account_is_login', '1');
        localStorage.setItem('clef_is_login', '1');
        dispatch(setAuthenticated(true));
        setPath(response.data.path || '/');
      })
      .catch(err => {
        enqueueSnackbar((err?.body || err)?.message, ERROR_SNACKBAR_STYLE);
        // jump to login page
        setPath('/login/0');
      });
  }, []);
  return (
    <LoadingOverlay active={path != null} spinner text="Please wait...">
      {path && <Redirect to={path} />}
    </LoadingOverlay>
  );
};

export default LdapLoginRoute;

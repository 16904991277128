import { makeStyles } from '@material-ui/core';
import { NEW_DRAWER_WIDTH } from '@clef/shared/constants';

// used in Instant Learning Page Sidebar
export const SidebarWidth = 320;

export const ScrollbarWidth = 18;

export const SidebarWidthV2 = 332;

export const useDataBrowserStyles = makeStyles(theme => ({
  textButton: {
    whiteSpace: 'nowrap',
    minWidth: 'auto!important',
  },
  selectMedia: {
    color: theme.palette.grey[800],
    '& .MuiButton-label': {
      fontWeight: 'normal',
    },
  },
  scrollStickyContainer: {
    height: 64,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  hintTextNormal: {
    color: theme.palette.grey[500],
  },
  hintTextError: {
    color: theme.palette.error.main,
  },
  hintTextContainer: {
    height: 500,
  },
  topFloatingButton: {
    position: 'absolute',

    marginBottom: theme.spacing(3),
    // To be displayed at the right corner
    right: 32,
    top: 92,
  },
  topBar: {
    marginBottom: theme.spacing(7),
    marginTop: -theme.spacing(7),
    position: 'absolute',
    top: 96,
    right: 32,
    '&.sideBarV2Opened': {
      marginRight: SidebarWidthV2,
    },
  },
  freTopBar: {
    top: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  topActionButton: {
    marginTop: theme.spacing(6.5),
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(3),
    },
  },
  customizeTrainDropdownButton: {
    width: 8,
  },
  paginationTopInDialog: {
    margin: theme.spacing(5),
  },
  paginationBottomUl: {
    marginTop: theme.spacing(5),
    justifyContent: 'flex-end',
  },
  mediaCountText: {
    whiteSpace: 'nowrap',
  },
  newMediaCountText: {
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(3),
  },
  statsCardRoot: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(7),
    paddingLeft: theme.spacing(4),
    boxSizing: 'content-box',
    marginLeft: -theme.spacing(4),
    overflowX: 'auto',
    '& section + section': {
      marginLeft: theme.spacing(5),
    },
  },
  viewOptions: {
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  tooltipIcon: {
    color: theme.palette.grey[400],
    marginLeft: theme.spacing(2),
  },
  heatmapInfoIcon: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(2),
  },
  point: {
    width: '30px',
    height: '30px',
    color: theme.palette.primary.main,
  },
  newTopDrawer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 64,
  },
  slide: {
    width: 80,
    margin: theme.spacing(0, 3),
  },
  toolbarButton: {
    borderColor: theme.palette.grey[300],
    color: theme.palette.grey[600],
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  toolbarButtonSelected: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(12, 100, 205, 0.1)',
  },
  modelsButton: {
    position: 'absolute',
    top: -60,
    right: 0,
    marginTop: 13,
    marginRight: 32,
    border: '1px solid #A4BCFD',
    color: '#2D3282',
    borderRadius: 10,
  },
  instantLearningModelsButton: {
    border: '1px solid #A4BCFD',
    color: '#2D3282',
    borderRadius: 10,
  },
  visualPromptingAndModelsButton: {
    position: 'absolute',
    top: -60,
    right: 0,
    marginTop: 13,
    marginRight: 32,
  },
  rightSidebarV2: {
    position: 'absolute',
    width: SidebarWidthV2,
    borderRadius: 0,
    borderLeft: '2px solid #C6D7FE',
    boxShadow: 'none !important',
  },
  rightSidebarCloseButton: {
    position: 'absolute',
    right: 0,
    top: 18,
  },
  more: {
    padding: '10px 0',
    color: '#666',
    border: '1px solid #CDD0D3',
    display: 'flex',
    width: '33px',
    height: '33px',
    alignItems: 'center',
    borderRadius: 5,
  },
  threeDotsPopoverListItem: {
    '&:hover': {
      background: 'rgba(0,0,0,0.04)',
      cursor: 'pointer',
    },
  },
  selectType: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 700,
    cursor: 'pointer',
    '& > button': {
      marginLeft: 0,
      paddingRight: '0px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  loadingImagesProgress: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1,
  },
  betaLabel: {
    background: '#41D1B3',
    padding: '2px 10px',
    borderRadius: 2,
    color: theme.palette.common.white,
    fontWeight: 500,
  },
  emptyStateContainer: {
    height: '70vh',
    position: 'relative',
    '& > *': {
      zIndex: 1,
    },
  },
  emptyStateImage: {
    marginBottom: theme.spacing(5),
  },
  emptyStateText: {
    marginBottom: theme.spacing(5),
  },
  emptyStateDownloadSampleDataButton: {
    marginRight: theme.spacing(5),
  },
  shrinkedBottomDrawerWidth: {
    width: `calc(100vw - ${NEW_DRAWER_WIDTH}px - ${SidebarWidth}px)`,
  },
  shrinkedBottomDrawerWidthV2: {
    width: `calc(100vw - ${NEW_DRAWER_WIDTH}px - ${SidebarWidthV2}px)`,
  },
  mediaSelectionPopover: {
    position: 'absolute !important' as any,
    zIndex: `${theme.zIndex.drawer - 1} !important` as any,
    '& .MuiPopover-paper': {
      top: '40px !important',
      left: '50% !important',
      width: '120px',
      maxWidth: '120px',
      maxHeight: '120px',
      marginLeft: '-60px',
      zIndex: theme.zIndex.drawer - 1,
    },
  },
  zoomIconBox: {
    width: 32,
    height: 32,
    background: theme.palette.greyBlue[100],
    borderRadius: 10,
  },
  uploadIconButton: {
    border: '1px solid #CDD0D3',
    width: 33,
    height: 33,
    borderRadius: 5,
  },
  uploadIcon: {
    fontSize: 20,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
  },
  closeIcon: {
    cursor: 'pointer',
  },
  uploadMoreImages: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  uploadLabel: {
    color: theme.palette.grey[500],
    fontSize: '12px',
  },
  uploadMore: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 32,
  },
  modelPerformanceSideBar: {
    padding: theme.spacing(8, 4, 4, 4),
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  modelListBtn: {
    position: 'absolute',
    top: -60,
    right: 0,
    marginTop: 13,
    marginRight: 16,
    height: 32,
    borderRadius: 10,
    border: `1px solid ${theme.palette.indigo[300]}`,
    color: theme.palette.indigo[900],
  },
  modelListBtnActive: {
    backgroundColor: '#fff',
  },
  dataBrowserContentWrapper: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  customizedScrollbar: {
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      boxShadow: '0 0 1px rgba(255, 255, 255, 0.2)',
    },
    '& scrollbar-color': 'rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0)', // Firefox
    '& scrollbar-width': 'thin', // Firefox
  },
  wrapperWithMR: {
    marginRight: 304,
  },
}));

import { useDialog } from '@/components/Layout/components/useDialog';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { OverSizeLimitException } from '@/store/uploadState/segmentationActions';
import { LabelType } from '@clef/shared/types';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useCallback } from 'react';
import { FileWithPath } from 'react-dropzone';
import { OverSizedImagesDialogContent } from './OverSizedImagesDialogContent';

export const getImageSizeAsync = (file: FileWithPath): Promise<number> => {
  return new Promise(resolve => {
    const image = new Image();
    image.onload = () => {
      const size = image.width * image.height;
      URL.revokeObjectURL(image.src);
      resolve(size);
    };
    image.src = URL.createObjectURL(file);
  });
};

export const useShowOverSizedImagesSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { showConfirmationDialog } = useDialog();
  return useCallback(
    (e: OverSizeLimitException) => {
      const imagesCount = (
        <span
          id="view-all-over-sized-images"
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => {
            showConfirmationDialog({
              title: t('Over Sized Images'),
              content: (
                <OverSizedImagesDialogContent overSizedImages={e.overSizedImages} />
              ) as unknown as string,
              confirmText: t('Close'),
              color: 'primary',
              disableCancel: true,
              onConfirm: () => {},
            });
          }}
        >
          images
        </span>
      );
      const message =
        'Segmentation projects support images smaller than 36MP. At least one of your {{images}} is larger and cannot be uploaded.';
      enqueueSnackbar(
        t(message, {
          images: imagesCount,
        }),
        { variant: 'error', persist: true },
      );
    },
    [enqueueSnackbar, showConfirmationDialog],
  );
};

const SEGMENTATION_UPLOAD_SIZE_LIMIT = 36 * 1000 * 1000;

export const useImageSizeLimit = () => {
  const { labelType } = useGetSelectedProjectQuery().data ?? {};
  if (labelType === LabelType.Segmentation) {
    return SEGMENTATION_UPLOAD_SIZE_LIMIT;
  }
  return;
};

import { LabelType, LabelingType } from '@clef/shared/types';
import { ProjectLabelTypeDisplayProps } from '../../utils/project';

export const ROW_COUNT_OPTIONS = [10, 15, 20, 25, 30, 35, 40, 45, 50];
export const ROW_DEFAULT_COUNT = 10;
export const READABLE_LABELING_TYPES_MAPPING = new Map([
  [LabelingType.MediaLevelClassification, t('OK/NG')],
  [LabelingType.DefectClassification, ProjectLabelTypeDisplayProps[LabelType.Classification].title],
  [LabelingType.DefectSegmentation, ProjectLabelTypeDisplayProps[LabelType.Segmentation].title],
  [LabelingType.DefectBoundingBox, ProjectLabelTypeDisplayProps[LabelType.BoundingBox].title],
]);

import React, { useCallback, useState, useMemo } from 'react';
import { Typography, Grid, CircularProgress, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Project, UserId, User } from '@clef/shared/types';
import { ApiResponseLoader, VirtualGrid } from '@clef/client-library';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useSetSelectedProjectId } from '@/serverStore/projects';
import { SortOption, sortProjects, filterProjects, QueryTag } from '../../utils/project_utils';
import useStyles from './styles';
import CLEF_PATH from '../../constants/path';
import { ProjectCard } from '../../components/ProjectCard/NewProjectCard';
import { ProjectsToolbar } from './components/ProjectsToolbar';
import { WorkflowTutorialBox } from './components/WorkflowTutorialBox';
import { CreateProjectCard } from '../../components/ProjectCard/CreateProjectCard';
import { isProjectOwner } from '@clef/shared/utils';
import { useGetProjectsQuery } from '@/serverStore/projects';

const PROJECT_CARD_MARGIN = 28;

const Projects: React.FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useTypedSelector(state => state.login.user!);
  const {
    data: projectListData,
    isLoading: projectListLoading,
    error: projectListError,
  } = useGetProjectsQuery();
  const setSelectedProjectId = useSetSelectedProjectId();
  const [searchText, setSearchText] = useState<string>('');
  const [queryTags, setQueryTags] = useState<QueryTag[]>([]);
  const [sortOption, setSortOption] = useState<SortOption>(SortOption.LastOpened);

  const users = useTypedSelector(state => state.user.users);

  const userById = useMemo(() => {
    return users.reduce((accum: { [key: UserId]: User }, val) => {
      accum[val.id] = val;
      return accum;
    }, {});
  }, [users]);

  const onProjectCardClick = useCallback(
    async (projectId: number) => {
      await setSelectedProjectId(projectId);
      history.push(`${CLEF_PATH.root}/${currentUser.orgId}/pr/${projectId}`);
    },
    [dispatch, history],
  );

  if (projectListLoading) {
    return (
      <div className={styles.container}>
        <Box display="flex" alignItems="center" justifyContent="center" height="70vh">
          <CircularProgress size="26px" data-testid="api-response-loader" />
        </Box>
      </div>
    );
  }

  if (!projectListError && !projectListLoading && projectListData && projectListData.length === 0) {
    return (
      <div className={styles.container}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="70vh"
        >
          <Typography variant="h2" className={styles.emptyStateTitle}>
            {t('You don’t have any projects yet')}
          </Typography>
          <Typography className={styles.emptyStateDescription}>
            {t(
              'Train your first computer vision model by following the 4 steps illustrated below. Hover over each step to see more details. We will also guide you along the way as you get started.',
            )}
          </Typography>

          <WorkflowTutorialBox />
        </Box>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <ProjectsToolbar
        idPrefix="fre_"
        searchText={searchText}
        queryTags={queryTags}
        setQueryTags={setQueryTags}
        setSearchText={setSearchText}
        sortOption={sortOption}
        setSortOption={setSortOption}
        className={styles.projectsToolbarContainer}
      />

      <WorkflowTutorialBox
        className={styles.workflowTutorialBox}
        enableClose
        showStartButton={false}
      />

      <ApiResponseLoader
        response={projectListData}
        loading={!!projectListLoading}
        error={projectListError ?? undefined}
        defaultHeight="30vh"
      >
        {projectListLoaded => {
          let newProjects = filterProjects(projectListLoaded, searchText, queryTags);
          newProjects = sortProjects(newProjects, sortOption, currentUser.id);
          const searchBarHasContent = searchText || queryTags.length !== 0;
          return !searchBarHasContent || newProjects?.length ? (
            <VirtualGrid
              itemPerRowCap={4}
              imageRatio={2 / 3}
              componentList={
                searchBarHasContent ? newProjects : [{ id: 'create-project-card' }, ...newProjects]
              }
              style={{ marginRight: -PROJECT_CARD_MARGIN }}
            >
              {component => {
                if (component.id === 'create-project-card') {
                  return (
                    <CreateProjectCard
                      style={{
                        width: `calc(100% - ${PROJECT_CARD_MARGIN}px)`,
                        height: `calc(100% - ${PROJECT_CARD_MARGIN}px)`,
                        aspectRatio: '1.55',
                      }}
                    />
                  );
                }
                const project = component as Project;
                const projectOwner = project.usersRoles?.find(user => isProjectOwner(user.role));
                return (
                  <ProjectCard
                    key={project.id}
                    project={project}
                    ownerUser={userById[projectOwner?.userId ?? '']}
                    isCurrentUserOwner={currentUser.id === projectOwner?.userId}
                    style={{
                      width: `calc(100% - ${PROJECT_CARD_MARGIN}px)`,
                      height: `calc(100% - ${PROJECT_CARD_MARGIN}px)`,
                      aspectRatio: '3 / 2',
                    }}
                    onClick={onProjectCardClick}
                  />
                );
              }}
            </VirtualGrid>
          ) : (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={styles.projectsEmptyStateContainer}
            >
              <Typography variant="body1" className={styles.projectsEmptyStateText}>
                {t('No projects to display')}
              </Typography>
            </Grid>
          );
        }}
      </ApiResponseLoader>
    </div>
  );
};

export default Projects;

import React from 'react';

export interface IconProps {
  color?: string;
  width?: number | string;
  height?: number | string;
}

export const PreviewIcon: React.FC<IconProps> = ({ color = '#30374F' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8333 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.7583 2.5 15.8333 2.5ZM15.8333 15.8333H4.16667V5.83333H15.8333V15.8333ZM10 8.75C11.5333 8.75 12.9 9.55 13.6167 10.8333C12.9 12.1167 11.5333 12.9167 10 12.9167C8.46667 12.9167 7.1 12.1167 6.38333 10.8333C7.1 9.55 8.46667 8.75 10 8.75ZM10 7.5C7.725 7.5 5.78333 8.88333 5 10.8333C5.78333 12.7833 7.725 14.1667 10 14.1667C12.275 14.1667 14.2167 12.7833 15 10.8333C14.2167 8.88333 12.275 7.5 10 7.5ZM10 12.0833C9.30833 12.0833 8.75 11.525 8.75 10.8333C8.75 10.1417 9.30833 9.58333 10 9.58333C10.6917 9.58333 11.25 10.1417 11.25 10.8333C11.25 11.525 10.6917 12.0833 10 12.0833Z"
        fill={color}
      />
    </svg>
  );
};

export const CostIcon: React.FC<IconProps> = ({ color = '#4D5761' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2088_3901)">
        <path
          d="M14.9995 7.55835V5.00023C14.9995 3.77778 14.1425 2.67601 12.5862 1.89794C11.2136 1.21166 9.4072 0.833679 7.4998 0.833679C5.59218 0.833679 3.78574 1.21166 2.41317 1.89794C0.857023 2.67601 0 3.77778 0 5.00023V8.33342V11.6665V14.9997C0 16.2222 0.857023 17.3239 2.41322 18.102C3.78579 18.7883 5.59223 19.1662 7.49985 19.1662C8.95304 19.1662 10.3384 18.9494 11.5352 18.5383C12.3261 18.9397 13.2202 19.1662 14.1662 19.1662C17.383 19.1662 20.0001 16.5492 20.0001 13.3324C20 10.3986 17.823 7.964 14.9995 7.55835ZM1.66682 11.0017C1.89412 11.1553 2.14281 11.3005 2.41322 11.4357C3.78579 12.122 5.59229 12.5 7.49985 12.5C7.80066 12.5 8.09969 12.49 8.39594 12.4713C8.35421 12.7524 8.33226 13.0399 8.33226 13.3324C8.33226 13.6046 8.35143 13.8725 8.38771 14.1348C8.09485 14.1557 7.79827 14.1663 7.49985 14.1663C4.16133 14.1663 1.66682 12.8466 1.66682 11.6666V11.0017ZM8.93598 10.75C8.46805 10.8051 7.98679 10.8331 7.4998 10.8331C4.16132 10.8332 1.66682 9.51342 1.66682 8.33342V7.66858C1.89412 7.82221 2.14281 7.96739 2.41322 8.10251C3.78579 8.78885 5.59229 9.16678 7.49985 9.16678C8.52617 9.16678 9.52243 9.05643 10.4385 8.84841C9.81423 9.36824 9.30013 10.0156 8.93598 10.75ZM7.4998 2.5005C10.8382 2.5005 13.3327 3.82023 13.3327 5.00023C13.3327 6.18023 10.8382 7.50001 7.4998 7.50001C4.16132 7.50001 1.66682 6.18023 1.66682 5.00023C1.66682 3.82023 4.16132 2.5005 7.4998 2.5005ZM1.66682 14.9998V14.335C1.89412 14.4886 2.14281 14.6337 2.41322 14.7689C3.78579 15.4552 5.59223 15.8332 7.49985 15.8332C7.9599 15.8332 8.41672 15.8106 8.86515 15.7668C9.11939 16.3183 9.45703 16.8236 9.86207 17.2664C9.11939 17.4185 8.31526 17.4995 7.4998 17.4995C4.16132 17.4995 1.66682 16.1797 1.66682 14.9998ZM14.1661 17.4995C11.8684 17.4995 9.99908 15.6301 9.99908 13.3324C9.99908 11.0412 11.858 9.17639 14.1468 9.16589C14.1533 9.16605 14.1596 9.16689 14.1661 9.16689C14.1727 9.16689 14.1789 9.16605 14.1855 9.16589C16.4743 9.17639 18.3332 11.0412 18.3332 13.3324C18.3332 15.6301 16.4638 17.4995 14.1661 17.4995Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2088_3901">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CopyIcon: React.FC<IconProps> = ({ color = '#30374F', width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 1H4.5C3.4 1 2.5 1.9 2.5 3V17H4.5V3H16.5V1ZM19.5 5H8.5C7.4 5 6.5 5.9 6.5 7V21C6.5 22.1 7.4 23 8.5 23H19.5C20.6 23 21.5 22.1 21.5 21V7C21.5 5.9 20.6 5 19.5 5ZM19.5 21H8.5V7H19.5V21Z"
        fill={color}
      />
    </svg>
  );
};

export const FlashIcon: React.FC<IconProps> = ({ color = '#002293' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.14233 3.06283V10.5628C6.14233 11.0212 6.51733 11.3962 6.97567 11.3962H8.64233V17.3545C8.64233 17.7795 9.20067 17.9295 9.41733 17.5628L13.7423 10.1462C14.0673 9.58783 13.6673 8.89616 13.0257 8.89616H11.1423L13.2173 3.35449C13.4257 2.81283 13.0257 2.22949 12.4423 2.22949H6.97567C6.51733 2.22949 6.14233 2.60449 6.14233 3.06283Z"
        fill={color}
      />
    </svg>
  );
};

export const TuneIcon: React.FC<IconProps> = ({ color = '#002293' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 14.1667V15.8333H7.5V14.1667H2.5ZM2.5 4.16667V5.83333H10.8333V4.16667H2.5ZM10.8333 17.5V15.8333H17.5V14.1667H10.8333V12.5H9.16667V17.5H10.8333ZM5.83333 7.5V9.16667H2.5V10.8333H5.83333V12.5H7.5V7.5H5.83333ZM17.5 10.8333V9.16667H9.16667V10.8333H17.5ZM12.5 7.5H14.1667V5.83333H17.5V4.16667H14.1667V2.5H12.5V7.5Z"
        fill={color}
      />
    </svg>
  );
};

import { ApiResponseLoader, Button, useLocalStorage } from '@clef/client-library';
import { Box, InputBase, MenuItem, Select, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import CreateKey from './CreateKey';
import KeyList from './KeyList';
import LegacyKeyAndSecret from './LegacyKeyAndSecret';
import { useApiKeyListApi } from '@/hooks/api/useOrganizationApi';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Search from '@material-ui/icons/Search';
import { ApiKey } from '@clef/shared/types';
import UnmaskedKeyDialog from './UnmaskedKeyDialog';
import { useApiKey } from '@/hooks/api/useUserApi';
import ApiKeyV2IntroductionDialog from './ApiKeyV2IntroductionDialog';

const useStyles = makeStyles(theme => ({
  goToAllKeysButton: {
    cursor: 'pointer',
    color: theme.palette.greyModern[500],
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
  },
  ownerFilter: {
    paddingTop: theme.spacing(2) + 'px!important',
    paddingBottom: theme.spacing(2) + 'px!important',
    borderRadius: 6,
  },
  keywordInput: {
    border: `1px solid ${theme.palette.greyModern[300]}`,
    padding: theme.spacing(0.25, 3),
    display: 'flex',
    alignItems: 'center',
    borderRadius: 6,
  },
  searchIcon: {
    color: theme.palette.grey[500],
  },
}));

type ApiKeyV2Props = {};

const ApiKeyV2: React.FC<ApiKeyV2Props> = () => {
  const [createKeyEditing, setCreateKeyEditing] = useState(false);
  const [apiKeys, apiKeysLoading, apiKeysError] = useApiKeyListApi({});
  const styles = useStyles();
  const [ownerFilter, setOwnerFilter] = useState<'anyone' | 'owner'>('anyone');
  const [keyword, setKeyword] = useState('');
  const [createdApiKey, setCreatedApiKey] = useState<ApiKey>();

  const [skipApiKeyV2Introduction, setSkipApiKeyV2Introduction] = useLocalStorage<string>(
    'skipApiKeyV2Introduction',
  );
  const [legacyApiKey] = useApiKey({});
  const showApiKeyV2Intro = !skipApiKeyV2Introduction && !!legacyApiKey?.apiKeyHash;

  return (
    <ApiResponseLoader response={apiKeys} loading={apiKeysLoading} error={apiKeysError}>
      {() => {
        return (
          <Box>
            {createKeyEditing ? (
              <Box>
                <Box
                  id="go-to-all-keys"
                  className={styles.goToAllKeysButton}
                  onClick={() => setCreateKeyEditing(false)}
                >
                  <KeyboardArrowLeft />
                  {t('All Keys')}
                </Box>
                <CreateKey
                  onKeyCreated={apiKey => {
                    setCreateKeyEditing(false);
                    setCreatedApiKey(apiKey);
                  }}
                />
              </Box>
            ) : (
              <Box>
                <Box display="flex" alignItems="center">
                  <Button
                    id="create-new-api-key"
                    color="primary"
                    variant="contained"
                    onClick={() => setCreateKeyEditing(true)}
                  >
                    {t('Create New Key')}
                  </Button>
                  <div style={{ flex: 1 }} />
                  <Select
                    variant="outlined"
                    value={ownerFilter}
                    onChange={e => setOwnerFilter(e.target.value as any)}
                    classes={{ select: styles.ownerFilter }}
                  >
                    <MenuItem value="anyone">{t('Created by anyone')}</MenuItem>
                    <MenuItem value="owner">{t('Created by you')}</MenuItem>
                  </Select>
                  <Box marginLeft={3} className={styles.keywordInput} width={250}>
                    <InputBase
                      value={keyword}
                      placeholder={t('Search')}
                      onChange={e => setKeyword(e.target.value)}
                      fullWidth
                    />
                    <Search className={styles.searchIcon} />
                  </Box>
                </Box>
                <KeyList ownerFilter={ownerFilter} keywordFilter={keyword} />
                <LegacyKeyAndSecret />
              </Box>
            )}
            {createdApiKey && (
              <UnmaskedKeyDialog
                apiKey={createdApiKey}
                onClose={() => setCreatedApiKey(undefined)}
              />
            )}
            {showApiKeyV2Intro && (
              <ApiKeyV2IntroductionDialog
                onClose={generateKey => {
                  setSkipApiKeyV2Introduction('1');
                  if (generateKey) {
                    setCreateKeyEditing(true);
                  }
                }}
              />
            )}
          </Box>
        );
      }}
    </ApiResponseLoader>
  );
};

export default ApiKeyV2;

import { Button } from '@clef/client-library';
import LinkedCameraOutlined from '@material-ui/icons/LinkedCameraOutlined';
import React from 'react';
import { useStyles } from './styles';

type WebcamFileSwitchButtonProps = {
  useWebcam: boolean;
  onClick?: (e: React.MouseEvent) => void;
  defaultStyles?: boolean;
};

const WebcamFileSwitchButton: React.FC<WebcamFileSwitchButtonProps> = ({
  useWebcam,
  onClick,
  defaultStyles = false,
}) => {
  const styles = useStyles();
  const buttonText = useWebcam ? t('Turn off Webcam') : t('Turn on Webcam');
  if (defaultStyles) {
    return <div>{t('Use WebCam')}</div>;
  }
  return (
    <div className={styles.webcamFileSwitchButton}>
      <Button
        id="webcam-file-switch-button"
        onClick={onClick}
        variant="outlined"
        startIcon={<LinkedCameraOutlined fontSize="small" />}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default WebcamFileSwitchButton;

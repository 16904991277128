import { Dimensions } from '@clef/shared/types';
import { hexToRgb } from '@clef/shared/utils';
import { RGB } from 'konva/types/types';

/**
 * Resize inputCanvas from it's height width to a provided dimension
 * @param inputCanvas - given canvas to resize
 * @param targetDimension - target width and height to resize
 * @returns resized OffscreenCanvas
 */
export const resizeCanvas = (
  inputCanvas: HTMLCanvasElement | OffscreenCanvas,
  targetDimension?: Dimensions,
): OffscreenCanvas => {
  if (
    !targetDimension ||
    (inputCanvas.width === targetDimension.width && inputCanvas.height === targetDimension.height)
  ) {
    return inputCanvas as OffscreenCanvas;
  }
  const resizedCanvas = new OffscreenCanvas(targetDimension.width, targetDimension.height);
  const resizedContext = resizedCanvas.getContext('2d', {
    desynchronized: true,
  })!;
  resizedContext.imageSmoothingEnabled = false;
  resizedContext.scale(
    targetDimension.width / inputCanvas.width,
    targetDimension.height / inputCanvas.height,
  );
  resizedContext.drawImage(inputCanvas, 0, 0);
  return resizedCanvas;
};

/**
 * create a pixelated canvas with provided dimensions and pixelBelongFunction
 * to iterate through all the pixels
 * @param canvasDimensions - width/height of the offscreen canvas to create
 * @param pixelBelongFunction - iterate over all pixels in canvas (x, y, i) and return where it should be painted
 * @param color - (string) the color of painted pixels
 * @param color - (function) for each pixel on canvas (x, y, i), return the rgb color of the pixel.
 * @param opacity - (optional) the opacity of pixels
 * @param compressOptions - (optional) to have better performance, if canvasDimensions is larger than
 *                          {baseWidth, baseHeight}, draw on a smaller canvas and resize it to target canvas size
 * @returns
 */
export const createPixelatedCanvas = (
  canvasDimensions: Dimensions,
  pixelBelongFunction: (index: { x: number; y: number; i: number }) => boolean,
  color: string | ((index: { x: number; y: number; i: number }) => RGB),
  opacity: number = 1,
  compressOptions?: { baseWidth: number; baseHeight: number },
): OffscreenCanvas => {
  const { width, height } = canvasDimensions;
  const { baseWidth, baseHeight } = compressOptions ?? { baseWidth: width, baseHeight: height };
  const scaleX = width < baseWidth ? 1 : width / baseWidth;
  const scaleY = height < baseHeight ? 1 : height / baseHeight;
  const scaledWidth = Math.floor(width / scaleX);
  const scaledHeight = Math.floor(height / scaleY);

  const rgb = typeof color === 'string' ? hexToRgb(color) : { r: 0, g: 0, b: 0 };
  const offscreen = new OffscreenCanvas(scaledWidth, scaledHeight);
  const context = offscreen.getContext('2d', {
    desynchronized: true,
  })!;
  context.imageSmoothingEnabled = false;
  const imageData = context.createImageData(scaledWidth, scaledHeight);
  for (let y = 0; y < scaledHeight; y++) {
    for (let x = 0; x < scaledWidth; x++) {
      const index = {
        x: x * scaleX,
        y: y * scaleY,
        i: Math.floor(y * scaleY) * width + Math.floor(x * scaleX),
      };
      if (pixelBelongFunction(index)) {
        const imageDataIndex = 4 * (y * scaledWidth + x);
        const { r, g, b } = typeof color === 'function' ? color(index) : rgb;
        imageData.data[imageDataIndex + 0] = r; // R value
        imageData.data[imageDataIndex + 1] = g; // G value
        imageData.data[imageDataIndex + 2] = b; // B value
        imageData.data[imageDataIndex + 3] = 255 * opacity; // A value
      }
    }
  }

  context.scale(scaleX, scaleY);
  context.putImageData(imageData, 0, 0);
  return offscreen;
};

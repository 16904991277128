import React from 'react';
import { useImmer } from 'use-immer';
import { ImageEnhancerStateContext, initialState } from './state';

export interface ImageEnhancerContextProps {}

const ImageEnhancerContext: React.FC<ImageEnhancerContextProps> = ({ children }) => {
  const [state, dispatch] = useImmer(initialState);

  return (
    <ImageEnhancerStateContext.Provider value={{ state, dispatch }}>
      {children}
    </ImageEnhancerStateContext.Provider>
  );
};

export default ImageEnhancerContext;

import { Button, Typography } from '@clef/client-library';
import { Typography as MuiTypography } from '@material-ui/core';
import { Box, Paper } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@clef/client-library';
import ContactUsDialog from '@/pages/OrgnizationSettings/components/ContactUsDialog';
import { useCheckActiveProjectReachLimit } from '@/hooks/useSubscriptions';
import UsageProgressBar from '@/pages/OrgnizationSettings/components/UsageProgressBar';
import NonCommercialUseOnlyBlock from './NonCommercialUseOnlyBlock';
import { useStyles } from './style';
import { SharedDialogProps } from '.';
import { useAddActiveProjectMutation } from '@/serverStore/projects';

type ActivateAndDownloadModelDialogProps = SharedDialogProps & {
  showNonCommercialUseBlock: boolean;
  downloadModelBundle: () => void;
};

const ActivateAndDownloadModelDialog = (props: ActivateAndDownloadModelDialogProps) => {
  const { open, onClose, projectId, showNonCommercialUseBlock, downloadModelBundle } = props;
  const styles = useStyles();
  const [contactUsDialogOpen, setContactUsDialogOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const { limit, usage } = useCheckActiveProjectReachLimit();
  const addActiveProjectMutation = useAddActiveProjectMutation();
  const isLastSlot = limit && limit - usage === 1;
  const bodyContent = isLastSlot
    ? t(
        'This is the {{lastProjectText}} you can activate. You can download an unlimited number of models from active projects.',
        {
          lastProjectText: (
            <MuiTypography component="span" className={styles.boldText}>
              {t('last project')}
            </MuiTypography>
          ),
        },
      )
    : limit === -1
    ? t(
        'After you activate this project, you can download an unlimited number of models from active projects.',
      )
    : t(
        'After you activate this project, you can activate {{numberOfActiveProjectsLeft}} more project. You can download an unlimited number of models from active projects.',
        { numberOfActiveProjectsLeft: (limit ?? 0) - usage - 1 },
      );
  return (
    <>
      <ContactUsDialog
        open={contactUsDialogOpen}
        onClose={() => {
          setContactUsDialogOpen(false);
        }}
      />
      <Dialog open={open} onClose={onClose} classes={{ paper: styles.paper }}>
        <Paper>
          <Box display="flex" flexDirection={'column'}>
            <Box padding={5} display="flex" flexDirection={'row'} justifyContent={'space-between'}>
              <Box>
                <Typography variant="h2_semibold">{t('Activate Project to Download')}</Typography>
              </Box>
              <IconButton
                id="close-browser-detection-snack-bar"
                size="small"
                aria-label="close"
                color="inherit"
                onClick={onClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box paddingX={5}>
              <Typography variant="body_regular">{bodyContent}</Typography>
            </Box>
            <Box paddingX={5} paddingTop={2} paddingBottom={4} width="60%">
              <UsageProgressBar usage={usage} limit={limit ?? 0} />
            </Box>
            {showNonCommercialUseBlock && (
              <Box paddingX={5}>
                <NonCommercialUseOnlyBlock
                  checked={checked}
                  onCheckedChange={newValue => {
                    setChecked(newValue);
                  }}
                />
              </Box>
            )}
            <Box padding={5} width="100%">
              <Button
                className={styles.button}
                id={'activate-and-download-btn'}
                color="primary"
                variant="contained"
                disabled={showNonCommercialUseBlock ? !checked : false}
                onClick={() => {
                  onClose();
                  addActiveProjectMutation.mutate(
                    {
                      projectId,
                    },
                    {
                      onSuccess: () => {
                        downloadModelBundle();
                      },
                    },
                  );
                }}
              >
                {t('Activate and download')}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Dialog>
    </>
  );
};

export default ActivateAndDownloadModelDialog;

import { ModelInfo, RegisteredModel } from '@clef/shared/types';

export const isUnsavedModel = (model: Partial<RegisteredModel> | Partial<ModelInfo> | null) => {
  return !model?.modelName;
};

export const getModelName = (model: RegisteredModel | ModelInfo | null | undefined) => {
  if (!model) {
    return t('Unknown model');
  }

  return model.modelName ?? t('Untitled model');
};

export const isClassicModel = (model: RegisteredModel | ModelInfo | null | undefined) => {
  if (!model) {
    return false;
  }

  return model.hasOwnProperty('trainJobId');
};

export const isFastNEasyModel = (model: RegisteredModel | ModelInfo | null | undefined) => {
  if (!model) {
    return false;
  }

  return !isClassicModel(model);
};

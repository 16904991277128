import React, { useCallback, useRef } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import ReactWebcam from 'react-webcam';
import Camera from '@material-ui/icons/Camera';
import NoPhotographyIcon from '../Predict/Icons/NoPhotographyIcon';
import cx from 'classnames';

const IMAGE_WIDTH = 1920;
const IMAGE_HEIGHT = 1080;
const DEFAULT_COMPONENT_WIDTH = 800;
const IMAGE_FORMAT = 'image/jpeg';

const useStyles = makeStyles(theme => ({
  webcam: {
    width: 'fit-content',
  },
  webcamShutter: {
    color: theme.palette.primary.main,
    transition: 'transform .25s, opacity .25s',
    cursor: 'pointer',
    '&:hover': {
      transform: 'rotate(90deg)',
    },
    width: 'fit-content',
  },
  buttonsGroup: {
    marginTop: 20,
  },
  closeCamera: {
    color: theme.palette.primary.main,
    transition: 'transform .25s, opacity .25s',
    cursor: 'pointer',
    width: 'fit-content',
  },
}));

type WebCamProps = {
  width?: number | string;
  height?: number | string;
  className?: string;
  captureDisabled?: boolean;
  onCapture: (imageInBase64: string) => void;
  onClose?: () => void;
};

export const WebCam: React.FC<WebCamProps> = ({
  width,
  height,
  className,
  captureDisabled,
  onCapture: onCaptureProps,
  onClose,
}) => {
  const styles = useStyles();
  const webcamRef = useRef<ReactWebcam | null>(null);

  const onCapture = useCallback(async () => {
    const imageInBase64 = webcamRef.current?.getScreenshot({
      width: IMAGE_WIDTH,
      height: Math.floor(
        // Use the real ratio of the inner video element first
        ((webcamRef.current?.video?.clientHeight ?? IMAGE_HEIGHT) * IMAGE_WIDTH) /
          (webcamRef.current?.video?.clientWidth ?? IMAGE_WIDTH),
      ),
    });

    if (imageInBase64) {
      onCaptureProps(imageInBase64);
    }
  }, [onCaptureProps]);

  return (
    <>
      <div data-testid="webcam-capture" className={cx(styles.webcam, className)}>
        <ReactWebcam
          audio={false}
          imageSmoothing
          screenshotQuality={1}
          mirrored
          ref={ref => {
            webcamRef.current = ref;
          }}
          screenshotFormat={IMAGE_FORMAT}
          width={width ?? DEFAULT_COMPONENT_WIDTH}
          height={height}
          forceScreenshotSourceSize
          // For some reasons, if we don't set the video constants with the expected size, the video element will have
          // the ratio of 4:3. Although the captured image maintains the ratio and the expected size, the image quality
          // is not preserved.
          videoConstraints={{
            width: IMAGE_WIDTH,
            height: IMAGE_HEIGHT,
          }}
        />
      </div>

      <Box className={styles.buttonsGroup}>
        <IconButton
          id="webcam-capture-button"
          data-testid="webcam-capture-button"
          disabled={captureDisabled}
          className={styles.webcamShutter}
          onClick={onCapture}
        >
          <Camera fontSize="large" />
        </IconButton>

        {onClose && (
          <IconButton
            id="webcam-close-button"
            data-testid="webcam-close-button"
            className={styles.closeCamera}
            disabled={captureDisabled}
            onClick={event => {
              event.stopPropagation();
              onClose();
            }}
          >
            <NoPhotographyIcon fontSize="large" />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default WebCam;

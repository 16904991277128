import React, { useCallback, useEffect, useState } from 'react';
import { addFile } from '../../../../store/uploadState';
import { dataUrlToFile } from '../../../../utils';
import { useAppDispatch } from '../../../../store';
import { useUploadStyles } from '../styles';
import { ReachLimitException } from '../../../../store/uploadState/utils';
import { useDialog } from '../../../Layout/components/useDialog';
import { ExceedType } from '../../../Layout/components/UsageExceedDialog';
import { WebCam } from '@/components/WebCam';

const IMAGE_FORMAT = 'image/jpeg';
const getImageName = () => new Date().toISOString().replace('.', '_') + '_WebcamCaptured.jpeg';

export interface WebcamCaptureProps {
  fileCapacity?: number | null;
  fileLimit?: number | null;
  disabled?: boolean;
  isNewCreditSystem?: boolean;
}

const WebcamCapture: React.FC<WebcamCaptureProps> = props => {
  const { fileCapacity, fileLimit, disabled, isNewCreditSystem } = props;
  const styles = useUploadStyles();
  const dispatch = useAppDispatch();
  const [disableShutter, setDisableShutter] = useState(true);
  const { showUsageExceedDialog } = useDialog();

  useEffect(() => {
    setTimeout(() => setDisableShutter(false), 2000);
  }, []);

  const onCapture = useCallback(
    async (imageInBase64: string) => {
      const imageFile = await dataUrlToFile(imageInBase64, getImageName(), IMAGE_FORMAT);
      try {
        await dispatch(
          addFile({
            files: [Object.assign(imageFile, { path: imageFile.name })],
            capacity: fileCapacity,
            limit: fileLimit,
            throwOnReachLimit: isNewCreditSystem,
          }),
        ).unwrap();
      } catch (e) {
        if (e instanceof ReachLimitException) {
          showUsageExceedDialog(ExceedType.Upload, e.fileCount, fileCapacity!);
        }
      }
    },
    [dispatch, fileCapacity, fileLimit, isNewCreditSystem, showUsageExceedDialog],
  );

  return (
    <div className={styles.webcamWrapper} data-testid="webcam-wrapper">
      <WebCam onCapture={onCapture} captureDisabled={disabled || disableShutter} />
    </div>
  );
};

export default WebcamCapture;

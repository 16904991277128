import React from 'react';
import { CircularProgress, withStyles, TextField, Grid } from '@material-ui/core';
import { Button } from '@clef/client-library';
import styles from '../styles';
import { withRouter } from 'react-router';
import { LoginTabProps } from '../types';
import FormStatus from './FormStatus';
import { EnvironmentEnum, getEnv } from '../../../constants';

const LoginTab: React.SFC<LoginTabProps> = ({ classes, ...props }) => {
  const handleLoginKeyPress = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter') {
      props.handleLoginButtonClick();
      e.preventDefault();
    }
  };
  const env = getEnv();
  const isOnprem = env === EnvironmentEnum.Onprem;
  return (
    <>
      <FormStatus
        classes={classes}
        isLoading={props.isLoading}
        error={props.error}
        success={props.success}
        message={props.message}
      />
      <TextField
        name="login"
        type="email"
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField,
          },
        }}
        fullWidth
        margin="normal"
        placeholder={t('Email Address or Username')}
        label={t('Email Address or Username')}
        value={props.loginValue}
        onChange={(e: React.ChangeEvent): void => props.handleInput(e)}
        onKeyDown={handleLoginKeyPress}
      />
      <TextField
        name="password"
        type="password"
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField,
          },
        }}
        fullWidth
        margin="normal"
        placeholder={t('Password')}
        label={t('Password')}
        value={props.passwordValue}
        onChange={(e: React.ChangeEvent): void => props.handleInput(e)}
        onKeyDown={handleLoginKeyPress}
      />
      <div className={classes.formButtons}>
        {props.isLoading ? (
          <CircularProgress size={26} className={classes.loginLoader} />
        ) : (
          <Grid container>
            {!isOnprem && (
              <Grid item xs={12}>
                <Grid
                  className={classes.forgetPasswordButtonText}
                  aria-label="Forgot password"
                  onClick={props.handleForgotButtonClick}
                >
                  {t('Forgot Password?')}
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} className={classes.centerGrid}>
              <Button
                id="login-button"
                aria-label="Login"
                variant="contained"
                color="primary"
                disabled={props.loginValue.length === 0 || props.passwordValue.length === 0}
                onClick={props.handleLoginButtonClick}
              >
                {t('Log In')}
              </Button>
            </Grid>
            {!isOnprem && (
              <Grid item xs={12} className={classes.centerGrid}>
                <Grid
                  className={classes.buttonText}
                  onClick={() => {
                    props.history.push('/legacy_login/sso' + props.location.search);
                  }}
                >
                  {t('Log in with SSO')}
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} className={classes.centerGrid}>
              <Grid
                className={classes.buttonText}
                onClick={() => {
                  props.history.push('/login' + location.search);
                }}
              >
                {t('Switch to New Sign In')}
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default withRouter(withStyles(styles, { withTheme: true })(LoginTab));

import React, { ReactNode, useMemo } from 'react';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { Typography, Grid, Chip, Popover, Box } from '@material-ui/core';
import DropFileZone from '../../components/DropFileZone';
import { useNewUploadStyles } from '../../styles';
import { UploadFileBlock } from '../../../../../hooks/useUploadFileBlockQueue';
import { FileWithPath } from 'react-dropzone';
import HelpOutlined from '@material-ui/icons/HelpOutlined';
import { bindPopover, usePopupState } from 'material-ui-popup-state/hooks';

export interface BaseDropFileZoneProps {
  blocks: UploadFileBlock[];
  title: string;
  acceptFileTypesText: string;
  imageSrc: string;
  guideMessage: {
    mini: React.ReactNode;
    full: React.ReactNode;
  };
  onDrop: (files: FileWithPath[]) => any;
  onDelete: (files: FileWithPath[]) => any;
  getChipLabel: (block: UploadFileBlock) => React.ReactNode;
  acceptedFiles: string[];
  acceptMultipleDrops: boolean;
  dropZoneDataTestId: string;
  hasAcceptedFiles: boolean;
  helpContent?: ReactNode;
  disabled?: boolean;
}

export const BaseDropFileZone: React.FC<BaseDropFileZoneProps> = ({
  blocks,
  title,
  acceptFileTypesText,
  imageSrc,
  guideMessage,
  onDrop,
  onDelete,
  getChipLabel,
  acceptedFiles,
  acceptMultipleDrops,
  dropZoneDataTestId,
  hasAcceptedFiles,
  helpContent,
  disabled,
}) => {
  const styles = useNewUploadStyles();
  const { uploadData, segmentationMasks, defectMap } = useTypedSelector(state => state.uploadState);

  const minimizedContent = useMemo(
    () => (
      <>
        <Typography variant="h4" component="div" className={styles.dropzoneText}>
          <strong>{title}</strong>
        </Typography>
        <Typography variant="body1" component="div" className={styles.dropzoneText}>
          {acceptFileTypesText}
        </Typography>
        <div className={styles.dropzoneText}>{guideMessage.mini}</div>
      </>
    ),
    [styles.dropzoneText, title, acceptFileTypesText, guideMessage],
  );

  const fullContent = useMemo(
    () => (
      <>
        <Typography variant="h4" component="div" className={styles.dropzoneText}>
          <strong>{title}</strong>
        </Typography>
        <Typography variant="body1" component="div" className={styles.dropzoneText}>
          {acceptFileTypesText}
        </Typography>
        <Grid container item direction="column" alignItems="center">
          <img src={imageSrc} className={styles.instructionImg} />
          {guideMessage.full}
        </Grid>
      </>
    ),
    [
      acceptFileTypesText,
      guideMessage.full,
      imageSrc,
      styles.dropzoneText,
      styles.instructionImg,
      title,
    ],
  );

  const helpPopupState = usePopupState({ variant: 'popover', popupId: 'help-popup' });

  const helpIconAndContent = useMemo(() => {
    return (
      helpContent && (
        <>
          <div
            onMouseEnter={e => helpPopupState.setOpen(true, e)}
            className={styles.dropZoneHelpIcon}
          >
            <HelpOutlined />
          </div>
          <Popover
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            {...bindPopover(helpPopupState)}
            disablePortal={false}
            className={styles.dropZoneHelpContentPopover}
          >
            <Box px={4} maxWidth={400}>
              {helpContent}
            </Box>
          </Popover>
        </>
      )
    );
  }, [helpContent, helpPopupState, styles.dropZoneHelpContentPopover, styles.dropZoneHelpIcon]);

  return (
    <>
      <DropFileZone
        onDrop={onDrop}
        onClick={e => {
          // this is the only way to prevent calling dropzone's internal onClick
          // https://github.com/react-dropzone/react-dropzone#dropzone-props-getters
          if (helpPopupState.isOpen) {
            e.stopPropagation();
          }
        }}
        accept={acceptedFiles}
        multiple={acceptMultipleDrops}
        dataTestId={dropZoneDataTestId}
        useNewStyle={true}
        hasAcceptedFiles={hasAcceptedFiles}
        disabled={disabled}
      >
        {uploadData.length ||
        (segmentationMasks && segmentationMasks.length) ||
        (defectMap && Object.keys(defectMap).length)
          ? minimizedContent
          : fullContent}
        {helpIconAndContent}
      </DropFileZone>

      <div className={styles.segmentationDropZoneChipContainer}>
        {blocks.map((block, index) => (
          <Chip
            key={index}
            className={styles.segmentationDropZoneChip}
            label={getChipLabel(block)}
            onDelete={() => {
              const { files } = blocks[index];
              onDelete(files);
            }}
            data-testid="dropzone-chip"
          />
        ))}
      </div>
    </>
  );
};

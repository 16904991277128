import { BaseAPI } from './base_api';
import { ApiResponse, InferenceExecutorResult } from '@clef/shared/types';

class InferenceAPI extends BaseAPI {
  async getFromCloudInference(
    projectId: number,
    name: string,
    path: string,
    selectedModelId: string,
  ): Promise<ApiResponse<InferenceExecutorResult>> {
    return this.get('get_from_cloud_inference', { projectId, name, path, selectedModelId });
  }
}

export default new InferenceAPI('inference');

import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';

const DEFAULT_REFRESH_RATE = 1000 * 30; // 30 seconds seems like a reasonable default

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.grey[500],
    whiteSpace: 'nowrap',
    cursor: 'default',
  },
}));

interface Props {
  refreshEveryMs?: number;
  children: string;
  prefixText?: string;
}

const RelativeDateTime = ({
  children,
  refreshEveryMs = DEFAULT_REFRESH_RATE,
  prefixText,
}: Props) => {
  const styles = useStyles();
  const [, forceUpdate] = React.useState(false);

  // As the rendered output represets the distance to the current time, we need to refresh the value
  // every once in a while at minimum, even if nothing else had changed.
  React.useEffect(() => {
    const interval = window.setInterval(() => forceUpdate(v => !v), refreshEveryMs);
    return () => {
      if (interval) window.clearInterval(interval);
    };
  }, [refreshEveryMs]);
  const dateTime = new Date(children);

  return (
    <Tooltip placement="top" title={dateTime.toLocaleString()} arrow>
      <Typography variant="body2" className={styles.text} component="span">
        {`${prefixText ?? ''}${formatDistanceToNow(dateTime, { addSuffix: true })}`}
      </Typography>
    </Tooltip>
  );
};

export default RelativeDateTime;

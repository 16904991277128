import React, { useCallback } from 'react';
import { Dropdown, FilterPill, ApiResponseLoader } from '@clef/client-library';
import { useDeviceMonitorStyles } from '../deviceMonitorStyles';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { Typography, Checkbox } from '@material-ui/core';
import { useDeviceMonitorState } from '../deviceMonitorState';
import { useGetJobDetailListQuery } from '@/serverStore/endpoints';

export interface ModelFilterProps {}

const ModelFilter: React.FC<ModelFilterProps> = () => {
  const styles = useDeviceMonitorStyles();
  const {
    state: { filterModel },
    dispatch,
  } = useDeviceMonitorState();
  const { data: project } = useGetSelectedProjectQuery();
  const {
    data: modelList,
    isLoading: modelListLoading,
    error: modelListError,
  } = useGetJobDetailListQuery(project?.id ?? 0, project?.orgId ?? 0);

  const onFilterOptionChange = useCallback(
    (optionValue: string) => () => {
      const newSelectedFilterOption = filterModel.includes(optionValue)
        ? filterModel.filter(option => option !== optionValue)
        : [...filterModel, optionValue];
      dispatch(draft => {
        draft.filterModel = newSelectedFilterOption;
      });
    },
    [dispatch, filterModel],
  );

  return (
    <Dropdown
      extraGutter={{ vertical: 12 }}
      dropdown={
        <ApiResponseLoader
          response={modelList}
          loading={modelListLoading}
          error={modelListError}
          defaultHeight={150}
          defaultWidth={150}
        >
          {modelList => {
            return (
              <div className={styles.dropdownPanelList}>
                {modelList.map(modelJob => (
                  <div
                    key={modelJob.bundleId}
                    onClick={onFilterOptionChange(modelJob.bundleId)}
                    className={styles.optionItem}
                    data-testid="filter-dropdown-option"
                  >
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={filterModel.includes(modelJob.bundleId)}
                      onChange={onFilterOptionChange(modelJob.bundleId)}
                    />
                    <Typography variant="body1" component="span">
                      {modelJob.jobName}
                    </Typography>
                  </div>
                ))}
              </div>
            );
          }}
        </ApiResponseLoader>
      }
    >
      {open => <FilterPill filterName={t('Model')} badgeContent={filterModel.length} open={open} />}
    </Dropdown>
  );
};

export default ModelFilter;

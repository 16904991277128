import React, { useCallback } from 'react';
import cx from 'classnames';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useMediaMetadataApi, useMetadataApi } from '../../hooks/api/useMetadataApi';
import { ApiResponseLoader } from '@clef/client-library';
import { Metadata, MetadataType } from '@clef/shared/types';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SetMetadataPredefinedChoices from './SetMetadataPredefinedChoices';
import SetMetadataRawText from './SetMetadataRawText';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  optionItem: {
    cursor: 'pointer',
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'rgba(9, 30, 66, 0.08)',
    },
  },
  optionItemOpen: {
    backgroundColor: 'rgba(9, 30, 66, 0.08)',
  },
  dropdownPanel: {
    minWidth: 150,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectorText: {
    marginRight: theme.spacing(3),
  },
}));

export interface SetMetadataDropdownProps {
  setMetadataAction: (metadata: Metadata) => (value: string[] | number[]) => Promise<void>;
  metadataFilter?: (metadataList: Metadata[]) => Metadata[];
  appliedMetadataIds?: number[];
  mediaMetadata?: ReturnType<typeof useMediaMetadataApi>[0];
}

const SetMetadataDropdown: React.FC<SetMetadataDropdownProps> = ({
  setMetadataAction,
  metadataFilter,
  appliedMetadataIds,
  mediaMetadata,
}) => {
  const styles = useStyles();
  const { data: project } = useGetSelectedProjectQuery();
  const [metadataMapping, metadataMappingLoading, metadataMappingError] = useMetadataApi(
    project?.id,
  );

  const getDefaultValue = useCallback(
    (medatatype: MetadataType, metadataId: number | undefined) => {
      if (!mediaMetadata || !metadataId) return [];
      if (medatatype === MetadataType.Boolean) {
        return mediaMetadata[`${metadataId}`].toString();
      }
      return mediaMetadata[`${metadataId}`];
    },
    [mediaMetadata],
  );

  const metadataSelector = useCallback(
    (metadata: Metadata) => (open: boolean) =>
      (
        <Grid
          className={cx(styles.optionItem, open && styles.optionItemOpen)}
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          container
          wrap="nowrap"
          key={metadata.id}
          data-testid="metadata-selector-box"
        >
          <Typography variant="body2" className={styles.selectorText}>
            {metadata.name}
          </Typography>
          <ChevronRightIcon fontSize="small" />
        </Grid>
      ),
    [styles.optionItem, styles.optionItemOpen, styles.selectorText],
  );

  return (
    <ApiResponseLoader
      response={metadataMapping}
      loading={metadataMappingLoading}
      error={metadataMappingError}
      defaultHeight={150}
      defaultWidth={150}
    >
      {(loadedResponse: { [key: string]: Metadata }) => {
        const metadataList = Object.values(loadedResponse);
        const finalMetadataList = metadataFilter ? metadataFilter(metadataList) : metadataList;
        return (
          <div
            className={classNames(styles.dropdownPanel, {
              hasMetadata: !!finalMetadataList.length,
            })}
            data-testid="set-metadata-dropdown"
          >
            {finalMetadataList.map(metadata => {
              if (metadata.predefinedChoices || metadata.type === MetadataType.Boolean) {
                const givenChoices: string[] =
                  metadata.type === MetadataType.Boolean
                    ? [t('true'), t('false')]
                    : metadata.predefinedChoices || [];
                const defaultValue = getDefaultValue(
                  metadata.type,
                  appliedMetadataIds?.includes(metadata.id) ? metadata.id : undefined,
                );

                return (
                  <SetMetadataPredefinedChoices
                    key={metadata.id}
                    predefinedChoices={givenChoices}
                    allowMultiple={metadata.allowMultiple}
                    placement="right"
                    extraGutter={{ horizontal: 4, vertical: -8 }}
                    applyMetadata={setMetadataAction(metadata)}
                    defaultValue={defaultValue}
                  >
                    {metadataSelector(metadata)}
                  </SetMetadataPredefinedChoices>
                );
              } else {
                const defaultValue =
                  metadata.type === MetadataType.Number &&
                  (!appliedMetadataIds || appliedMetadataIds.length === 0)
                    ? [0]
                    : [''];
                return (
                  <SetMetadataRawText
                    key={metadata.id}
                    allowMultiple={metadata.allowMultiple}
                    placement="right"
                    isNumber={metadata.type === MetadataType.Number}
                    extraGutter={{ horizontal: 4, vertical: -8 }}
                    applyMetadata={setMetadataAction(metadata)}
                    defaultValue={defaultValue}
                  >
                    {metadataSelector(metadata)}
                  </SetMetadataRawText>
                );
              }
            })}
          </div>
        );
      }}
    </ApiResponseLoader>
  );
};

export default SetMetadataDropdown;

import React, { useState, useMemo } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { Button, IconButton, Typography } from '@clef/client-library';
import {
  useDataBrowserState,
  getSelectedMediaCount,
  formatStateToSelectMediaOption,
} from '../dataBrowserState';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import DatasetAPI from '@/api/dataset_api';
import { useAtom } from 'jotai';
import { thresholdForPredictAtom } from '@/uiStates/projectModels/pageUIStates';

const useStyles = makeStyles(theme => ({
  downloadCSVDialog: {
    width: 418,
  },
  dialogTitleText: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.greyModern[900],
  },
  checkItem: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  checkIcon: {
    fontSize: 18,
    fill: theme.palette.green[500],
    marginRight: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  button: {},
  dialogContainer: {
    padding: theme.spacing(5),
    position: 'relative',
    overflow: 'hidden',
  },
  dialogActions: {
    marginTop: theme.spacing(5),
  },
  postRequestMask: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
    background: 'rgba(255,255,255,0.7)',
  },
  labelText: {
    color: theme.palette.greyModern[800],
  },
  caption: {
    color: theme.palette.greyModern[500],
  },
}));

export interface DownloadCSVDialogProps {
  onClose?: () => void;
}

const DownloadCSVDialog: React.FC<DownloadCSVDialogProps> = ({ onClose }) => {
  const styles = useStyles();
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const { state, dispatch } = useDataBrowserState();
  const { id: currentModelId, modelName: currentModelName } = useCurrentProjectModelInfoQuery();

  const { enqueueSnackbar } = useSnackbar();

  const [isDownloading, setIsDownloading] = useState(false);
  const [thresholdForPredict] = useAtom(thresholdForPredictAtom);

  const selectMediaOptionsDataBrowser = useMemo(
    () => formatStateToSelectMediaOption(state, currentModelId),
    [state, currentModelId],
  );

  const selectedMediaCount = useMemo(() => getSelectedMediaCount(state), [state]);

  const handleDownload = async () => {
    if (!projectId) return;
    try {
      setIsDownloading(true);
      const res = await DatasetAPI.getDatasetMediasCsv(
        projectId,
        currentModelId,
        thresholdForPredict,
        selectMediaOptionsDataBrowser,
      );
      window.open(res, '_top');
      dispatch(draft => {
        draft.selectingMediaMode = 'select';
        draft.selectedOrUnselectedMedia = [];
      });
    } catch (error) {
      enqueueSnackbar('Failed to download CSV', { variant: 'error' });
    } finally {
      setIsDownloading(false);
      onClose?.();
    }
  };

  return (
    <Dialog
      open
      color="primary"
      onClose={onClose}
      PaperProps={{ className: styles.downloadCSVDialog }}
    >
      <DialogTitle>
        <Typography className={styles.dialogTitleText}>{t('Download CSV')}</Typography>
        <IconButton
          id="close-clone-project-dialog-btn"
          onClick={onClose}
          className={styles.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {t('The CSV for the selected {{count}} {{imageCopy}} contains this information:', {
            count: selectedMediaCount,
            imageCopy: selectedMediaCount > 1 ? 'images' : 'image',
          })}
        </Typography>
        <Box marginTop={3} display="flex" flexDirection="column" alignItems="start">
          <Box className={styles.checkItem}>
            <CheckIcon className={styles.checkIcon} />
            <Typography variant="body_medium" className={styles.labelText}>
              {t('Ground truth')}
            </Typography>
          </Box>
          {currentModelId && thresholdForPredict && (
            <Box className={styles.checkItem}>
              <CheckIcon className={styles.checkIcon} />
              <Typography variant="body_medium" className={styles.labelText}>
                {t('Prediction: {{modelName}}', { modelName: currentModelName })}
              </Typography>
              <Typography variant="body_semibold" className={styles.caption}>
                {`(${thresholdForPredict})`}
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button id="cancel-download-csv-databrowser" onClick={onClose} className={styles.button}>
          {t('Cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          id="download-csv-databrowser"
          onClick={handleDownload}
          className={styles.button}
          disabled={isDownloading}
        >
          {isDownloading && (
            <CircularProgress color="inherit" size={16} style={{ marginRight: 10 }} />
          )}
          {isDownloading ? t('Downloading') : t('Download')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadCSVDialog;

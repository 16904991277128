import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import MouseIcon from '@material-ui/icons/Mouse';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@clef/client-library';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@clef/client-library';
import { Theme } from '@material-ui/core';
import { HotKey } from './useHotKeyDialog';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  hotKeysDialogTitle: {
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  hotKeyIcon: {
    position: 'relative',
    top: theme.spacing(2),
  },
  plusIcon: {
    left: theme.spacing(1),
    right: theme.spacing(1),
    paddingTop: theme.spacing(3),
  },
  keyButton: {
    textTransform: 'lowercase',
    fontFamily: 'Commissioner, sans-serif',
  },
  descriptionText: {
    textAlign: 'center',
  },
}));
interface Props {
  hotKeys: HotKey[];
  dialogOpen: boolean;
  handleDialogClose: () => void;
}
const HotKeysDialog: React.FC<Props> = props => {
  const { hotKeys, handleDialogClose, dialogOpen } = props;
  const classes = useStyles();
  const hotKeysListItems = hotKeys.map((hotKey: HotKey): React.ReactNode => {
    return (
      <>
        <Divider />
        <ListItem>
          <Grid xs={6} item container alignItems="center" justifyContent="center">
            <ListItemText primary={hotKey.description} className={classes.descriptionText} />
          </Grid>
          <Grid xs={6} item container alignItems="center" justifyContent="center">
            {hotKey.keys.map((key: string, index: number): React.ReactNode => {
              let join = null;
              if (index > 0) {
                join = <AddIcon className={classes.plusIcon} />;
              }
              let keyComponent = null;
              switch (key) {
                case t('CLICK'):
                  keyComponent = <MouseIcon className={classes.hotKeyIcon} />;
                  break;
                case t('ArrowLeft'):
                  keyComponent = (
                    <Button id="left-icon" variant="outlined">
                      <ArrowLeftIcon fontSize="small" />
                    </Button>
                  );
                  break;
                case t('ArrowRight'):
                  keyComponent = (
                    <Button id="right-icon" variant="outlined">
                      <ArrowRightIcon fontSize="small" />
                    </Button>
                  );
                  break;
                case t('ArrowUp'):
                  keyComponent = (
                    <Button id="up-icon" variant="outlined">
                      <ArrowDropUpIcon fontSize="small" />
                    </Button>
                  );
                  break;
                case t('ArrowDown'):
                  keyComponent = (
                    <Button id="down-icon" variant="outlined">
                      <ArrowDropDownIcon fontSize="small" />
                    </Button>
                  );
                  break;
                default:
                  keyComponent = (
                    <Button id={`${key}-button`} variant="outlined" className={classes.keyButton}>
                      {key}
                    </Button>
                  );
                  break;
              }
              return (
                <>
                  {join}
                  {keyComponent}
                </>
              );
            })}
          </Grid>
        </ListItem>
      </>
    );
  });
  return (
    <Dialog
      onClose={handleDialogClose}
      aria-labelledby="keymap-dialog-title"
      open={dialogOpen}
      fullWidth
      maxWidth={'md'}
    >
      <DialogTitle
        id="keymap-dialog-title"
        disableTypography
        className={classes.hotKeysDialogTitle}
      >
        <Typography variant="h4">{t('Hot Keys')}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <List>{hotKeysListItems}</List>
    </Dialog>
  );
};
export default HotKeysDialog;

import React, { useCallback, useState } from 'react';
import { Box, CircularProgress, TextField, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@clef/client-library';
import { useSnackbar } from 'notistack';
import { accountApiWithAuth } from '@/api/account_api';
import { refreshApiKeyListApi } from '@/hooks/api/useOrganizationApi';
import { ApiKey } from '@clef/shared/types';

const useStyles = makeStyles(theme => ({
  createKeyRoot: {
    maxWidth: 600,
    '& ul': {
      margin: 0,
      paddingLeft: '1.5em',
    },
  },
  goToAllKeysButton: {
    cursor: 'pointer',
    color: theme.palette.greyModern[500],
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
  },
}));

export type CreateKeyProps = {
  onKeyCreated?: (apiKey?: ApiKey) => void;
};

const CreateKey: React.FC<CreateKeyProps> = ({ onKeyCreated }) => {
  const styles = useStyles();
  const [keyName, setKeyName] = useState('');
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const createKey = useCallback(async () => {
    if (loading || !keyName) {
      return;
    }
    setLoading(true);
    try {
      const res = await accountApiWithAuth.createApiKey(keyName);
      onKeyCreated?.(res.data);
      refreshApiKeyListApi({ keys: 'refresh-all' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
    }
    setLoading(false);
  }, [enqueueSnackbar, keyName, loading, onKeyCreated]);

  return (
    <Box className={styles.createKeyRoot}>
      <Box marginTop={2}>
        <Typography variant="h2_semibold">{t('Generate API Key')}</Typography>
      </Box>
      <Box marginTop={5} fontSize={14}>
        <Typography variant="body_regular">
          {t('Here is everything you need to know before creating a new key:')}
        </Typography>
        <ul>
          <li>{t('You can create multiple keys for different purposes.')}</li>
          <li>
            {t(
              'Create a descriptive name for your API Key to help you remember what it will be used for.',
            )}
          </li>
          <li>{t('You can retrieve and manage keys you created.')}</li>
        </ul>
      </Box>
      <Box marginTop={5}>
        <Typography variant="body_bold">{t('Key Name')}</Typography>
        <Box marginTop={2}>
          <TextField
            placeholder="key name"
            variant="outlined"
            value={keyName}
            onChange={e => setKeyName(e.target.value)}
            onKeyPress={e => e.key === 'Enter' && createKey()}
            fullWidth
            inputProps={{ maxLength: 64 }}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" marginTop={5}>
        <Button
          id="generate-api-key"
          color="primary"
          variant="contained"
          disabled={!keyName || loading}
          startIcon={loading ? <CircularProgress size={16} /> : undefined}
          onClick={createKey}
        >
          {t('Generate API Key')}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateKey;

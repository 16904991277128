export default {
  LandingLens: 'LandingLens',
  'Landing AI Visual Inspection Platform': 'Landing AI视觉平台',
  'All rights reserved.': '版权所有',
  'Forgot Password?': '忘记密码？',
  'Log In': '登录',
  'Log in with SSO': '使用 SSO 登录',
  'Something is wrong with your input :(': '您的输入有问题:(',
  'Maximize productivity, ensure quality, and optimize business results with our AI-powered Visual Inspection product.':
    '使用我们的人工智能视觉检测产品最大限度地提高生产力、确保质量并优化业务成果',
  'Create your account': '创建您的帐户',
  'Enter details to Reset Password': '输入详细信息以重置密码',
  'SSO Sign In': '第三方登录',
  Continue: '继续',
  'Log in with password': '使用密码登录',
  'Maintenance in progress, please come back later.': '正在维护中，请稍后回来。',
  'Landing AI': 'Landing AI',
  'Something is wrong...': '有什么不对...',
  "Oops. Looks like the page you're looking for no longer exists":
    '哎呀。看起来您要查找的页面已不存在',
  'Back to Home': '返回首页',
  'ML workflow': '机器学习流程',
  Data: '数据',
  Models: '模型',
  Deployment: '部署',
  Defects: '缺陷',
  'Defect consensus': '缺陷共识',
  'Data overview': '数据概览',
  Settings: '设置',
  Metadata: '元数据',
  Labeling: '标记',
  'Exported dataset': '导出的数据集',
  'Data augmentation': '数据增强',
  'Training jobs': '训练任务',
  'Job details': '训练任务详情',
  'Experiment report': '实验报告',
  'Error analysis': '误差分析',
  'Model Comparison': '模型比较',
  'My tasks': '我的任务',
  'Model & Device': '模型和设备',
  'Device Media': '设备媒体',
  Alerts: '警报',
  Projects: '项目',
  Devices: '设备',
  Members: '成员',
  'API key': 'API 密钥',
  Documentation: '文档',
  API: 'API 接口',
  'Sign out': '登出',
  'No available project found': '找不到项目',
  'Manage projects': '管理项目',
  'New Project': '新项目',
  'Create New Project': '创建新项目',
  'Invite only': '默认不公开',
  Cancel: '取消',
  Create: '创建',
  'ML workflow overview': '机器学习流程',
  'Create your defect book': '创建您的缺陷手册',
  'Upload defects, add defect descriptions, upload sample media, and annotate your media to help define your defects.':
    '上传缺陷、添加缺陷描述、上传样本媒体并注释您的媒体以帮助定义您的缺陷。',
  'Who should create the defect book?': '谁应该创建缺陷手册？',
  'It is best for Machine Learning Engineers (MLE) to create, update and maintain the defect book while collaborating with Subject Matter Experts (SME). SMEs understand defect definitions, whereas MLEs know how to label the defects, and how labeling can affect model performance.':
    '机器学习工程师 (MLE) 最好在与主题专家 (SME) 合作的同时创建、更新和维护缺陷手册。 SME 了解缺陷定义，而 MLE 知道如何标记缺陷，以及标记如何影响模型性能。',
  'Defects created': '生成的缺陷',
  'Last updated on': '最后更新于',
  'No defect created yet...': '尚未创建缺陷...',
  'Go to defect book': '转到缺陷手册',
  'Not started': '没有开始',
  'Coming soon...': '即将推出...',
  'Started, keep iterating': '请继续迭代',
  'Upload, browse & manage your media': '上传、浏览和管理您的媒体',
  'Upload your existing data to your project and continuously upload new data as it becomes available. View and explore your data in the':
    '将您现有的数据上传到您的项目，并在新数据可用时不断上传。查看和探索您的数据',
  'data browser': '数据浏览器',
  'Manage your data through utilizing': '为了更好的管理您的数据，可以使用',
  metadata: '元数据',
  'for advanced tracking, searching and sorting. You can add metadata to your data when uploading, or assign metadata to already uploaded data.':
    '用于去做高级跟踪、搜索和排序。您可以在上传时向数据添加元数据，或将元数据分配给已上传的数据。',
  'Media uploaded': '已上传媒体',
  'Last uploaded on': '最后上传时间',
  'No media uploaded yet...': '还没有上传媒体...',
  '+ Upload media': '+ 上传媒体',
  'Go to data browser': '进入数据浏览器',
  'Remaining upload limit is': '剩余上传限制为',
  'Select metadata to attach to uploaded media': '选择要添加到上传媒体的元数据',
  'Select metadata': '选择元数据',
  'Folder Upload': '文件夹上传',
  'Direct Upload': '直接上传',
  'CSV Upload': 'CSV上传',
  'Allowed image type:': '允许的图像类型：',
  'JPEG, PNG and BMP': 'JPEG、PNG 和 BMP',
  Close: '关闭',
  Upload: '上传',
  'Data CSV': '数据CSV',
  Requirements: '要求',
  'Notify the Platform team the': '通知平台团队',
  'name of the bucket': 'bucket名称',
  'where the media will be added from if the bucket has never been verified':
    '如果从未验证过存储桶，将从何处添加媒体',
  'CSV file must contains S3 paths to the media with prefix':
    'CSV 文件必须包含带有前缀的媒体的 S3 路径',
  's3://': 's3://',
  'Number of media is limited to a': '媒体数量限制为',
  'MAXIMUM of 1000': '最多 1000',
  'entries. For larger dataset, use the ingestion API from': '条目。对于较大的数据集，请使用来自',
  'Platform API': '平台API',
  'Cancel Data Upload': '取消数据上传',
  'Are you sure you want to cancel the upload of': '您确定要取消上传吗',
  'file(s)?': '文件？',
  Yes: '是的',
  'Drag and drop some files here, or click to select files.':
    '将一些文件拖放到此处，或单击以选择文件。',
  'Allowed image type: JPEG, PNG and BMP': '允许的图像类型：JPEG、PNG 和 BMP',
  'Drop media here': '将媒体放在这里',
  'Might take some time to analyze...': '可能需要一些时间来分析...',
  'Analyzing...': '分析中...',
  'Please ensure that the files you want to upload are organized following way:':
    '请确保您要上传的文件按以下方式组织：',
  '1st folder = Parent folder / 2nd tier sub-folder = Defect names':
    '第一文件夹 = 父文件夹 / 第二层子文件夹 = 缺陷名称',
  Defect: '缺陷',
  'Existing Media': '现有媒体',
  'New Media': '新媒体',
  'New Defect': '新缺陷',
  'Creating Defects and Categories...': '创建缺陷和类别...',
  ' ': ' ',
  'Failed to create categories and defects': '未能创建类别和缺陷',
  Retry: '重试',
  true: '真的',
  false: '错误的',
  Apply: '应用',
  Clear: '清除',
  'set metadata value': '设置元数据值',
  'Clarify and evaluate your defect definitions': '澄清和评估您的缺陷定义',
  'Evaluating defect consensus and addressing ambiguity in your defect book can help avoid data being labeled incorrectly and time lost to relabeling. You can measure defect consensus whenever you think there is ambiguity in your data.':
    '评估缺陷共识并解决缺陷手册中的歧义可以帮助避免数据被错误标记和重新标记时间浪费。只要您认为数据中存在歧义，就可以衡量缺陷共识。',
  'How to select data for agreement-based labeling task?': '如何为基于共识的标注任务选择数据？',
  'Navigate to your data browser and select data: select 10 - 20 media that to your knowledge are ambiguous and difficult to label. Create a Labeling Task, assign > 1 distinctive labels per image to 2+ labelers.':
    '导航到您的数据浏览器并选择数据：选择 10 - 20 个据您所知不明确且难以标记的媒体。创建一个标记任务，为每个图像分配 > 1 个不同的标记给 2 个以上的标记。',
  'Who should participate?': '谁应该参加？',
  'To clarify ambiguity among SMEs, you can assign 2-3 SMEs to complete an agreement-based labeling task.':
    '为了澄清SME之间的歧义，您可以分配 2-3 个SME来完成基于共识的标记任务。',
  'To identify ambiguity for labelers, you can assign 2-3 labelers to complete an agreement-based labeling task.':
    '要识别标记者的歧义，您可以分配 2-3 个标记者来完成基于共识的标记任务。',
  'Defect consensus last measured': '上次测量的缺陷共识',
  'Agreement score': '同意分数',
  '%': '%',
  'Defect consensus not measured yet...': '缺陷共识尚未测量...',
  'Label your data': '标记您的数据',
  'Once you have reached acceptable defect consensus, the next step is to label your data.':
    '一旦您达成可接受的缺陷共识，下一步就是标记您的数据。',
  '1. Go to': '1. 前往',
  '2. Select media you want labelled': '2. 选择要标记的媒体',
  '3. Create labeling task and assign labelers': '3. 创建标注任务并分配标注者',
  '4. Once labels are submitted, you can approve or reject them':
    '4. 标记提交后，您可以批准或拒绝它们',
  'Current media status:': '媒体现状：',
  'View all labeling task': '查看所有标记任务',
  Raw: '原始',
  Assigned: '已分配',
  'In Review': '审核中',
  Approved: '合格',
  Rejected: '不合格',
  'Export dataset with training/dev/test split': '使用训练/开发/测试分组导出数据集',
  'When enough media are labeled and approved, you will want to export a version of your data with training, dev, and test splits appropriately applied to the dataset to ensure accuracy of labels.':
    '当足够多的媒体被标记并获得批准后，您将希望导出一个数据版本，其中训练、开发和测试分组适当地应用于数据集，以确保标记的准确性。',
  '• Use Metadata "Split" to help you split your approved media into train/dev/test':
    '• 使用“分组”元数据帮助您将合格的媒体分组为训练/开发/测试',
  '• Check the data split chart to make sure it looks good before you export dataset':
    '• 在导出数据集之前检查数据分组图表以确保它看起来不错',
  'View all datasets': '查看所有数据集',
  'Train and evaluate your model': '训练和评估您的模型',
  "Once you export an accurate dataset you're confident in, it's time to train a model and evaluate its performance.":
    '导出准确标记的数据集后，就可以训练模型并评估其性能了。',
  'What is Data Augmentation?': '什么是数据增强？',
  'Data augmentation is a set of techniques used to increase the amout of data by adding slightly modified copies of existing data to the training set. This can improve model performance in some situations but can lead to issues if not handled carefully.':
    '数据增强是一组技术，用于通过将现有数据的稍微修改的副本添加到训练集来增加数据量。在某些情况下，这可以提高模型性能，但如果处理不当，可能会导致问题。',
  'Which model class and architecture should I choose?': '我应该选择哪个模型类和架构？',
  "We offer two model options, RetinaNet for object detection and UNet for segmentation. You should choose whichever model aligns with the label type you've used in your data browser.":
    '我们提供两种模型选项，用于对象检测的 RetinaNet 和用于分组的 UNet。您应该选择与您在数据浏览器中使用的标记类型一致的模型。',
  'Succeeded training jobs': '成功的训练任务',
  'Succeeded evaluation jobs': '成功的评估任务',
  'Best model': '最佳模型',
  'No model marked as best yet': '尚无模型标记为最佳',
  'There was an error while fetching your trained models.': '获取经过训练的模型时出错。',
  'No models trained yet.': '还没有训练模型。',
  'Go to Models': '跳转到模型',
  'Go to task': '跳转到标记任务',
  'Task closed': '已关闭',
  'No tasks assigned': '没有任务',
  Id: 'ID',
  'Task Name': '任务名称',
  'Labeling Type': '标记类型',
  'Assigned Time': '创建时间',
  '# Assignees': '# 标记人数',
  '# Distinctive label required': '# 每个媒体需要标记人数',
  Action: '可选操作',
  'labeled by me / total in task': '由我标记 / 总图片数',
  '# Images': '＃ 图片',
  "Progress of all inspectors' work combined": '所有标记人进度综合',
  'Overall Task Progress': '总任务进度',
  'Visibility:': '谁可见：',
  Access: '访问权限',
  'Project name': '项目名称',
  'Only invited members can view': '只有受邀成员才能查看',
  'Only invited members can access the project': '只有受邀成员才能访问项目',
  'Anyone in the organization can access the project': '所有人都可以访问项目',
  'Anyone can view': '任何人都可以查看',
  'Add members': '添加成员',
  'Project role': '项目角色',
  'Project permission': '项目权限',
  'Search users': '用户搜索',
  'All role': '全部角色',
  'All permissions': '全部权限',
  'Are you sure you want to switch this project from private to public?':
    '确定将此项目从私有切换为公开吗？',
  'Are you sure you want to switch this project from public to private?':
    '确定将此项目从公开切换为私有吗？',
  'After it’s done, all memebers in your organzation will be able to have full access of the project.':
    '完成后，组织中的所有成员都将能够完全访问该项目。',
  'After it’s done, this project will only be available to you and project members. You can invite more members later.':
    '完成后，此项目将仅对您和项目成员可见。 您可以稍后邀请更多成员。',
  'Successfully updated access permissions': '已成功更新访问权限',
  'Switch project mode': '切换项目模式',
  'Yes, Switch to Public': '是，切换为公开',
  'Yes, Switch to Private': '是， 切换为私有',
  'Project owner:': '项目所有者',
  'Deploy model': '部署模型',
  'Train model': '训练模型',
  'Upload data': '上传数据',
  Name: '名称',
  Email: '电子邮件',
  Role: '角色',
  Mandatory: '强制的',
  Status: '状态',
  'Add Members to {{projectName}}': '将成员添加到{{projectName}}',
  'Add {{count}} Members to Project': '将 {{count}} 个成员添加到项目',
  Search: '搜索',
  'Invite members by email': '通过电子邮件邀请成员',
  'Adding members...': '添加成员...',
  'Add Members to The Platform': '添加成员到平台',
  '＋ Add another member': '＋ 添加其他成员',
  'Inviting...': '邀请中...',
  Invite: '邀请',
  'Add A New Field': '添加新字段',
  ID: 'ID',
  Type: '类型',
  boolean: '布尔值',
  number: '数字',
  text: '文本',
  Value: '数值',
  Tags: '元数据',
  'Pre-Defined Choice': '预定义选项',
  'Allow Multiple': '允许多个',
  'Acceptable Inputs': '可接受的输入',
  'Add/Edit Metadata Field': '添加/编辑元数据字段',
  Text: '文本',
  Number: '数字',
  Boolean: '布尔值',
  'Allow Multiple Selection': '允许多选',
  Save: '保存',
  'No Default Dataset': '无默认数据集',
  'Dataset not found': '未找到数据集',
  'Copy S3 path': '复制 S3 路径',
  Download: '下载',
  'Export Failed!': '导出失败！',
  'Export in progress...': '导出中...',
  Description: '描述',
  'Created On': '创建于',
  '#Media': '＃媒体',
  'Defect Distribution': '缺陷分布',
  'Defect Map': '缺陷对应表',
  'not tagged': '未被分组',
  'No defects': '无缺陷',
  'Upload Images': '上传媒体',
  'Auto Split': '智能分组',
  'Total {{mediaCount}} media': '总共 {{mediaCount}} 个媒体',
  'Select all': '全选',
  'Select page': '选择页面',
  'Unselect all': '取消选择',
  'No media found': '未找到媒体',
  'Clear filters': '清除筛选',
  'No media in this project': '此项目中没有媒体',
  'More filters': '更多筛选',
  operation: '运算',
  value: '数值',
  'Show Labels': '显示标记',
  Media: '媒体',
  'has been deleted': '已被删除',
  Edit: '编辑',
  Delete: '删除',
  '+ Add metadata': '+ 添加元数据',
  'Show All': '显示所有',
  'Media id': '媒体 ID',
  'Media Name': '媒体名称',
  Dimensions: '尺寸',
  'Upload Time': '上传时间',
  'Attach Metadata to {{count}} Media': '给{{count}}个文件添加元数据',
  'Media Status': '媒体状态',
  'Bounding Box': '边界框',
  'Remove from dataset': '从数据集中删除',
  No: '没有',
  'Media has no labels or is pending labeling!': '媒体没有标记或正在被标记！',
  'Proceed to next media after review': '审核后进入自动跳转下一个媒体',
  Accept: '接受',
  Reject: '拒绝',
  CLICK: '点击',
  ArrowLeft: '左箭头',
  ArrowRight: '向右箭头',
  ArrowUp: '向上箭头',
  ArrowDown: '向下箭头',
  'Hot Keys': '热键',
  'Media size:': '媒体尺寸：',
  'Media per page:': '每页媒体：',
  'Show:': '显示：',
  'Media status': '媒体状态',
  Labels: '标记',
  'Media name': '媒体名称',
  'Sort options:': '排序选项：',
  '{{mediaCount}} media selected': '选中 {{mediaCount}} 个媒体',
  'Create labeling task': '创建标记任务',
  'Create task': '创建任务',
  'Export dataset': '导出数据集',
  'Set metadata': '设置元数据',
  'Are you sure you want to remove {{selectedMediaCount}} media from dataset?':
    '您确定要从数据集中删除 {{selectedMediaCount}} 个媒体么？',
  'The selected {{selectedMediaCount}} media will be removed from existing labeling tasks (media already labeled will not be affected) and data browser for all future actions.':
    '已选 {{selectedMediaCount}} 个媒体将从现有的标记任务中删除（已标记的媒体将不受影响）和数据浏览器中。',
  'Remove {{selectedMediaCount}} media': '删除 {{selectedMediaCount}} 个媒体',
  media: '媒体',
  'Create new labeling task': '创建新的标记任务',
  "All media are pending labeling, pending review or deleted. Can't create tasks for these media, please finish/cancel labeling tasks and review labels first.":
    '所有媒体都处于待标记、待审核或删除状态。无法为这些媒体创建任务，请先完成/取消标记任务并查看标记。',
  '{{totalCount}} media selected, however your task will only have {{availableMediaCounts}} media. Pending labeling, pending review and deleted media will not be included in the task.':
    '选择了 {{totalCount}} 个媒体，但是您的任务将只有 {{availableMediaCounts}} 个媒体。待标记、待审核和已删除的媒体将不包括在任务中。',
  'Your task will have {{availableMediaCounts}} media.':
    '您的任务将有 {{availableMediaCounts}} 个媒体。',
  'Task name': '任务名称',
  'Labeling task type': '标注任务类型',
  'Labeling agreement': '标记共识',
  'Calculating labeling agreement among multiple labelers helps:':
    '计算多个标记员之间的标记有助于：',
  'Prioritize media in review': '优先审查歧义媒体',
  'Identify defect ambiguity': '识别缺陷歧义',
  'Auto-reject media with low agreement score': '自动拒绝共识分数低的媒体',
  'Please select the number of distinctive labels (labeled by different labelers) required:':
    '请选择所需的独特标记数量（由不同的标记人员标记）：',
  'If there are existing labels, do you want to carry them?': '如果有现有的标记，您要携带它们吗？',
  'Yes, keep existing labels': '是，保留现有标记',
  'No, erase existing labels': '不，删除现有标记',
  'Assign labelers': '选择标记人员',
  'Number of labelers has to be greater than number of labelers per media':
    '标记人员数量必须大于每个图片需要的标记人员',
  'Start Labeling': '开始标记',
  'Create new consensus task': '创建新的共识任务',
  'Randomize the media order in task': '随机媒体顺序',
  'Please select at least 2 labelers': '请至少选择 2 个标记人员',
  'Data Preview': '预览您的数据',
  'You have selected {{notValidMediaStatusF}} media. You can only export Approved and In Review media.':
    '您选择了 {{notValidMediaStatusF}} 个media， 您只能导出合格或者待审核的媒体',
  'You have selected {{inReviewCount}} in review media, We do not recommend you export media that has not been approved.':
    '您选择了 {{inReviewCount}} 个待审核媒体，我们不建议您导出未经批准的媒体。',
  '{{notTaggedMediaCount}} image(s) are not assigned to a split.':
    '有 {{notTaggedMediaCount}} 个媒体没有分到任何“分组”元数据',
  Export: '导出',
  'Export {{exportCount}} media': '导出 {{exportCount}} 个媒体',
  'Select media': '选择媒体',
  'Set split distribution': '设置分组分配方案',
  Done: '完成',
  'Auto split only supports assigning images to predefined {{predefinedSet}} sets. If you want to use your customized {{customizedSet}} set for training, please assign media manually.':
    '自动分组仅支持将媒体分配给预定义的 {{predefinedSet}} 集。如果您想使用自定义的 {{customizedSet}} 分组元数据，请手动分配媒体。',
  'train / dev / test': '训练/开发/测试',
  'Image selection': '媒体选择',
  'By default, all {{approvedText}} with {{noSplitAssignedText}} images will be selected for auto splitting.':
    '默认情况下，将选择所有 {{noSplitAssignedText}} 的 {{approvedText}} 媒体进行自动分组。',
  approved: '合格',
  'no split assigned': '未分配分组',
  'Include pending review media': '包括待审媒体',
  'Include assigned train/dev/test media to reassigned': '包括已分配的训练/开发/测试媒体以重新分配',
  'Total number of images to split': '要分组的媒体总数',
  'No images available to split.': '没有可分组的媒体。',
  '-': '-',
  'Total number of defects': '缺陷总数',
  "There are 0 defect in all selected media, you won't be able to set split distribution on them.":
    '所有选定的媒体中都没有缺陷，您将无法对它们做分组分配。',
  defect: '缺陷',
  created: '被创建',
  'No defect media': '无缺陷媒体',
  'Set your target defect split distribution': '设置目标缺陷的分组分布',
  'Adjust value for all defect types together': '统一调度',
  'Constrained by how defects are distributed across media, the final split per defect percentage might be deviated from your target.':
    '受缺陷在媒体中的具体分布限制，每个缺陷的最终分组比例可能与您的目标有偏差。',
  'train / dev / test (%)': '训练 / 开发 / 测试 (%)',
  'Result Preview': '结果预览',
  'Selected media': '选择的媒体',
  'Selected media + Existing media with split': '选择的媒体 + 已分组的媒体',
  '/': '/',
  'number of media in each split': '每个分组中的媒体数量',
  'Based on how your target defect split above, the final result of how media will be in each split':
    '根据上面的目标缺陷分组分布，每个分组中的媒体结果',
  'defect / split distribution': '缺陷 / 分组分布',
  'This should directly resemble your target defect split above, if the average deviation is very large, reasons could be:':
    '结果应该近似您上面的目标缺陷分组，如果平均偏差非常大，原因可能是：',
  '1. There are too few media and defects.': '1. 媒体和缺陷太少。',
  '2. You have set extremely different split for each defect.': '2. 您的媒体中的缺陷分布很极端',
  'split / defect distribution': '分组 /缺陷分布',
  'This chart shows the number of defects for each split, usually you want the distribution to be similar across each split to train a successful model':
    '此图表显示每个分组的缺陷数，通常您希望每个分组的分布相似来训练成功的模型',
  '* Also include {{count}} media with no defects': '* 还包括{{count}}个无缺陷的媒体',
  'average deviation from target: {{value}}%': '与目标的平均偏差：{{value}}%',
  'media to': '个媒体到',
  'You have successfully assigned': '您已成功分配',
  'distributions for all media with train/dev/test split': '训练/开发/测试分组的所有媒体的分布',
  '[Approved media] Defect count': '[合格媒体] 缺陷计数',
  '[Approved media] Data split': '[合格媒体] 数据分组',
  '[Approved media] Defect per split distribution': '[合格媒体] 每个分组分布的缺陷',
  'Labeling instructions': '标记说明',
  'Last update time - latest first': '上次更新时间 - 最新优先',
  'Last updated time - earliest first': '上次更新时间 - 最老优先',
  'Creation time - newest first': '创建时间 - 最新优先',
  'Creation time - oldest first': '创建时间 - 最老优先',
  'Create defect': '创建新缺陷',
  'Download PDF': '下载PDF',
  'Sort by:': '排序方式：',
  'You have not created any defects yet.': '您尚未创建任何缺陷。',
  'Last updated: ': '最近更新时间：',
  '{{count}} approved media': '{{count}}个合格媒体',
  'Back to card view': '返回卡片视图',
  '*No description about this defect yet.*': '*尚无关于此缺陷的描述。*',
  'You changes will be discarded': '您的更改将被丢弃',
  'Are you sure you want to discard all your changes to the defect?':
    '您确定要放弃对缺陷所做的所有更改吗？',
  Discard: '丢弃',
  '--': '--',
  '*Please add context about this project*': '*请添加有关此项目的上下文*',
  '': '',
  undo: '撤销',
  'ctrl/cmd + z': 'ctrl/cmd + z',
  redo: '重做',
  'ctrl/cmd + y': 'ctrl/cmd + y',
  'Clear all': '清除所有',
  'toggle tools': '切换工具',
  t: 't',
  Tools: '工具',
  'Stroke width': '画笔宽度',
  Opacity: '透明度',
  'toggle color': '切换颜色',
  c: 'c',
  Color: '颜色',
  Notes: '笔记',
  'Add images from local': '从本地添加媒体',
  'Add images from data browser': '从数据浏览器添加媒体',
  'Select media from dataset': '从数据集中选择媒体',
  Total: '总共',
  Select: '选择',
  'Create New Class': '创建缺陷',
  'Class Color': '缺陷颜色',
  'Please do not set duplicate colors': '请不要设置重复的颜色',
  'Class Name': '缺陷名称',
  Context: '背景',
  'Are you sure you want to delete this example': '您确定要删除此示例吗',
  'Delete example': '删除示例',
  'Create time': '创建时间',
  Progress: '进展',
  Assignees: '标记人员',
  'Overall agreement score': '共识分数',
  Actions: '可选操作',
  'In Progress': '进行中',
  'All completed': '全部完成',
  'Labeling Task Assignment': '标记任务分配',
  'Total Number of Media to Be Labeled:': '要标记的媒体总数：',
  'ASSIGN LABELERS': '选择标记人员',
  Assign: '选择',
  Senior: '高级的',
  Junior: '初级',
  '...': '...',
  Fill: '充满',
  Brightness: '亮度',
  Contrast: '对比度',
  'Advanced Enhancement': '进阶强化',
  'Historgram equalization': '直方图均衡',
  'Apply To All Media': '适用于所有媒体',
  'Select Metadata': '选择元数据',
  'Apply Metadata Filters to Objects': '将元数据筛选应用于对象',
  True: '真的',
  False: '错误的',
  AND: '和',
  Back: '后退',
  images: '图片',
  'labeled by me/by others/total in task': '由我/其他人/任务中的总标记',
  Inspectors: '标记员',
  "Task progress of all inspectors' work combined": '所有标记人员进度总和',
  'Defect Book': '缺陷手册',
  Submit: '提交',
  'We recommend you submit your current work': '我们建议您提交您当前的任务',
  Dismiss: '解雇',
  'You have not submitted all your reviewed media. Do you want to submit and continue?':
    '您尚未提交所有已审核的媒体。您要提交并继续吗？',
  'Go Back': '后退',
  'How to use auto-segmentation': '如何使用智能分割',
  'You will provide positive input and negative input:': '您将提供正输入和负输入：',
  'Left-click is positive input to auto-generate segmentation label':
    '左键单击是智能分割标记的正输入',
  'Right-click is nagative input to auto-erase labeled area': '右键单击是智能分割标记的负输入',
  'You can click multiple times to get what you want, each click might take':
    '您可以多次单击以获取所需内容，每次单击都可能需要',
  '2-3 seconds to process.': '2-3 秒处理。',
  'Got It': '明白了',
  'How to use': '如何使用',
  Segmentation: '分割',
  'Finish Segmentation': '完成分割',
  'Defects ({{defectCount}})': '缺陷 ({{defectCount}})',
  'Instance Browser ({{instanceCount}})': '实例浏览器 ({{instanceCount}})',
  'We assign media to inspectors in batches. Each batch contains 20 media. Once a batch is submitted, another one will be assigned.':
    '我们将媒体分批分配给标记员。每批包含 20 个媒体。提交一批后，将分配另一批。',
  'Media Metrics': '媒体指标',
  'Media List ({{mediaListLength}})': '媒体列表 ({{mediaListLength}})',
  'Select All': '全选',
  'Clear All': '清除所有',
  'Clear Labels': '清除标记',
  NG: 'NG',
  OK: 'OK',
  'Media has nothing to label': '媒体没有什么可标记的',
  'Skip this media': '跳过此媒体',
  'Save Drawing Segmentation': '保存绘图分割',
  'You are drawing a segmentation, do you want to save it?': '您正在绘制分割，要保存吗？',
  'stroke width': '笔画宽度',
  'Total in task:': '任务中总数：',
  'Labeling by me:': '由我标记：',
  'Pending assignment:': '待分配：',
  'Media in this batch': '这批媒体',
  Labeled: '有标记',
  'Not labeled': '未标注',
  Label: '标记',
  'Zoom options': '缩放选项',
  'stroke width:': '笔画宽度：',
  '[': '[',
  ']': ']',
  'Select Class': '选择缺陷',
  '- Select Class -': '- 选择缺陷 -',
  'In Review:': '审批中：',
  'Accepted:': '被接受：',
  'Rejected:': '被拒绝：',
  'Label Book': '缺陷手册',
  'Media Agreement': '媒体共识',
  'Media Metadata': '媒体元数据',
  'Add metadata': '添加元数据',
  'Agreement Threshold': '共识阈值',
  'Media List': '媒体列表',
  'sort media by agreement score': '按共识分数对媒体进行排序',
  s: 's',
  Agreement: '共识',
  Review: '审查',
  'Media agreement is {{higher}} than the agreement threshold. We have picked the best label, you can choose to {{accept}} or {{reject}} this label':
    '对于{{higher}}共识阈值的媒体。我们挑选了最好的标记，您可以选择{{accept}}或{{reject}}这个标记',
  higher: '高于',
  accept: '接受',
  reject: '拒绝',
  "Media agreement is {{lower}} than the agreement threshold. This media is {{rejected}}, you can view all labeler's labels":
    '对于{{lower}}共识阈值的媒体。此媒体已{{rejected}}，您可以查看所有标记人员的标记',
  lower: '低于',
  rejected: '被拒绝',
  'This media has been {{accepted}}. You can toggle to view selected label or all labels.':
    '此媒体已{{accepted}}。您可以切换以查看选定标记或所有标记。',
  accepted: '被接受',
  'This media has been {{rejected}}. You can view all labels.':
    '该媒体已{{rejected}}。您可以查看所有标记。',
  'Leave review notes about this media': '留下有关此媒体的评论',
  'view labels from all labelers': '查看所有标记人员的标记',
  v: 'v',
  'View all labels': '查看所有标记',
  'accept and go to next media': '接受并转到下一个媒体',
  a: 'a',
  'reject and go to next media': '拒绝并转到下一个媒体',
  r: 'r',
  'No defect defined. Please fill in defect book first.': '没有定义缺陷。请先填写缺陷手册。',
  'Add media to:': '将媒体添加到：',
  'Adding...': '添加中...',
  'Add media': '添加媒体',
  'Add image to Label Book': '将媒体添加到缺陷手册',
  'The report could not be generated': '无法生成报告',
  'No error message': '没有错误信息',
  'Go to models page': '进入模型页面',
  Overview: '概述',
  'Image analysis': '图像分析',
  'Ground truth = {{groundTruth}}, and Prediction = {{predictions}} ':
    'Ground truth = {{groundTruth}}，预测 = {{predictions}}',
  'No label': '无标记',
  'Ground truth': 'Ground truth',
  Prediction: '预测',
  Compare: '比较',
  'Media details': '媒体详情',
  Information: '信息',
  'Media ID': '媒体 ID',
  'Unknown width': '未知宽度',
  x: 'x',
  'Unknown height': '未知高度',
  'Unknown time': '未知时间',
  medias: '媒体',
  'Clear selection': '清空选项',
  Count: '数量',
  'Model evaluated on {{count}} media': '该模型在 {{count}} 张影像上进行了评估',
  'media.': '媒体。',
  'On {{mediaTotal}} media, ground truth has {{groundTruthTotal}} bounding box(es), model predicts {{predictionCountTotal}} bounding box(es).':
    '在 {{mediaTotal}} 媒体上，ground truth 有 {{groundTruthTotal}} 个边界框，模型预测 {{predictionCountTotal}} 个边界框。',
  'On {{mediaTotal}} media, ground truth has {{groundTruthTotal}} defect(s), model predicts {{predictionCountTotal}} defect(s).':
    '在 {{mediaTotal}} 媒体上，ground truth 有 {{groundTruthTotal}} 个缺陷，模型预测 {{predictionCountTotal}} 个缺陷。',
  Correct: '正确的',
  Incorrect: '不正确',
  'True Positive': '真阳性',
  'True Negative': '真阴性',
  'False Positive': '假阳性',
  'False Negative': '假阴性',
  Mismatched: '不匹配',
  'Incorrect Classifications': '错误分类',
  'Correct Classifications': '正确分类',
  'Ideas to improve your model': '改进模型的想法',
  'How is your label quality?': '您们的标记质量如何？',
  'Does your split represent your defects well?': '您的分组能很好地代表您的缺陷吗？',
  'Do you have similar defects?': '您有类似的缺陷吗？',
  'How did we calculate FPs & FNs?': '我们如何计算 FP 和 FN？',
  'What can you look for when doing error analysis?': '在进行错误分析时，您可以发现什么？',
  Model: '模型',
  'Loading...': '正在加载...',
  'Job ID': '任务编号',
  'Unknown Job ID': '未知任务 ID',
  'Evaluated on': '评估日期',
  '(Dataset, split)': '（数据集，分组）',
  'Unknown dataset': '未知数据集',
  '# media': '#媒体',
  'Copy to clipboard': '复制到剪贴板',
  'Ground truth = {{groundTruth}}, and Baseline prediction = {{baselinePrediction}}, and Candidate prediction = {{candidatePrediction}} ':
    'Ground truth = {{groundTruth}}，基线预测 = {{baselinePrediction}}，候选预测 = {{candidatePrediction}}',
  Baseline: '基线',
  Candidate: '候选人',
  'Count baseline': '计数基线',
  'Count candidate': '计数候选人',
  'Count difference': '计数差异',
  '+': '+',
  'Baseline and candidate test sets share': '基线和候选测试集共享',
  'Baseline and candidate test sets share {{mediaTotal}} media, On those {{mediaTotal}} media, ground truth has {{groundTruthCountTotal}} box(es), baseline model predicts {{baselineCountTotal}} box(es), and candidate model predicts {{candidateCountTotal}} box(es)':
    '基线和候选测试集共享 {{mediaTotal}} 个媒体，在那些 {{mediaTotal}} 个媒体上，ground truth 有 {{groundTruthCountTotal}} 个框，基线模型预测 {{baselineCountTotal}} 个框，和候选模型预测 {{candidateCountTotal}} 个框',
  'Baseline and candidate test sets share {{mediaTotal}} media, On those {{mediaTotal}} media, ground truth has {{groundTruthCountTotal}} defect(s), baseline model predicts {{baselineCountTotal}} defect(s), and candidate model predicts {{candidateCountTotal}} defect(s)':
    '基线和候选测试集共享 {{mediaTotal}} 个媒体，在那些 {{mediaTotal}} 个媒体上，ground truth 有 {{groundTruthCountTotal}} 个缺陷，基线模型预测 {{baselineCountTotal}} 个缺陷，并且候选模型预测 {{candidateCountTotal}} 个缺陷',
  'This project has no exported datasets yet': '该项目还没有导出的数据集',
  'You need at least one exported dataset to run a job.':
    '您至少需要一个导出的数据集才能运行任务。',
  'Unexpected validation errors:': '未知的验证错误：',
  'Creating job': '创造任务中',
  'Create job': '创造任务',
  'Train new model': '训练新模型',
  'Trained on': '训练在',
  '(Dataset, split)': '（数据集，分组）',
  'Evaluated on': '评估日期',
  Previous: '以前的',
  Next: '下一个',
  'View job details': '查看训练任务详情',
  'Delete job': '删除任务',
  'Nothing to show': '没什么可显示的',
  'You can try clearing some filters to reveal hidden models.':
    '您可以尝试清除一些过滤器以显示隐藏的模型。',
  'No trained models yet': '还没有经过训练的模型',
  'Once you have a suitable exported dataset you can train your first model.':
    '一旦有了合适的导出数据集，就可以训练第一个模型。',
  'Model details': '模型详情',
  'Only show favorite models': '只显示最喜欢的模型',
  'Only show favorite evaluation jobs': '只显示最喜欢的评估任务',
  'Model info': '模型信息',
  'Confidence threshold': '置信阈值',
  Precision: '准确率',
  'N/A': '不适用',
  Recall: '召回率',
  'This job has no': '这份任务没有',
  'Precision/Recall': '准确率/召回率',
  'data.': '数据。',
  'This will create a new evaluation job. Enter more details about your job.':
    '这将创建一个新的评估任务。输入有关您的任务的更多详细信息。',
  'Invalid hyperparameters': '无效的超参数',
  'Something is wrong with this job metadata. A new evaluation job cannot be created.':
    '此任务元数据有问题。无法创建新的评估任务。',
  'Evaluate & Analyze': '评估和分析',
  'Creating evaluation job': '创建评估任务中',
  'Create evaluation job': '创建评估任务',
  'Adjust confidence threshold': '调整置信度阈值',
  'Warning:': '警告：',
  'Baseline and Candidate models were evaluated on different datasets or split metadata.':
    '基线和候选模型在不同的数据集或分组元数据上进行评估。',
  'View details': '查看详情',
  'Mark as best': '标记为最佳',
  Evaluate: '评估',
  'Training Info': '训练信息',
  Job: '任务',
  'Job ': '任务 ',
  'Evaluation Info': '评价信息',
  'Dataset, split': '数据集，分组',
  mAP: 'mAP',
  mIOU: 'mIOU',
  'Confidence Threshold': '置信阈值',
  CREATED: '创建了',
  RUNNABLE: '可运行',
  STARTING: '开始',
  RUNNING: '运行中',
  'Job is still in progress': '任务仍在进行中',
  'Error Analysis': '误差分析',
  'Add to comparison': '添加到比较',
  'Add to deployment': '添加到部署',
  'No favorite evaluation jobs for this model': '此模型没有最喜欢的评估任务',
  'No evaluation jobs for this model': '此模型没有评估任务',
  'Refresh job state': '刷新任务状态',
  'Job Info': '任务信息',
  'Created at': '创建于',
  Creator: '创造者',
  'Unknown user': '未知用户',
  'Data Info': '数据信息',
  Trained: '训练在',
  Evaluated: '评估在',
  on: '上',
  ',': ',',
  'Model Info': '模型信息',
  'Model Details': '模型详情',
  'Class and architecture': '类和架构',
  'Data Augmentation Details': '数据增强细节',
  'No data augmentation applied': '未应用数据增强',
  Metric: '指标',
  'No metrics available': '没有可用的指标',
  Logs: '日志',
  Refresh: '刷新',
  'Transforms Details': '转换具体信息',
  Validation: '验证',
  Train: '训练',
  'Create new': '创建新的',
  'Upload yaml file': '上传yaml文件',
  'Saved data augmentation pipelines': '保存的数据增强流程',
  View: '查看',
  Rename: '改名',
  Images: '图片',
  Original: '原来的',
  'Effect training': '效果训练',
  'Effect validation': '效果验证',
  'Create Pipeline': '创建流程',
  'Transform name': '转换名称',
  Training: '训练',
  '{{sectionName}} transforms:': '{{sectionName}} 转换：',
  None: '空的',
  'Unsupported transform': '不支持的转换',
  'Your browser does not support the HTML5 Video element.': '您的浏览器不支持 HTML5 Video 元素。',
  Device: '设备',
  Alarm: '警报',
  'Lodging Front': '住宿前台',
  'Chaff Elevator': '箔条电梯',
  'Chaff Hopper': '箔条料斗',
  Yield: '屈服',
  'Select Unit on the table to view the video': '选择桌子上的单位观看视频',
  Date: '日期',
  Cam: '凸轮',
  'Devices Registered': '已注册设备',
  Online: '在线的',
  Offline: '离线',
  Manage: '管理',
  'Models available to deploy': '可部署的模型',
  'Active models': '在线模型',
  'View & Deploy': '查看和部署',
  'No data to display': '没有数据显示',
  'Avg defective rate': '平均不良率',
  'Defect Rate Trend': '缺陷率趋势',
  '# Defects detected': '# 检测到的缺陷',
  'Defects Per Type': '每种类型的缺陷',
  'Avg confidence': '平均置信度',
  'Defects Per Confidence': '缺陷置信度',
  '# Media Predicted': '# 媒体预测',
  'Media Predicted Per Day': '每天预测的媒体',
  'Deploy ({{appCount}})': '部署 ({{appCount}})',
  'Or, you can create a downloadable app, download it on your local machine  and run locally.':
    '或者，您可以创建一个可下载的应用程序，将其下载到本地计算机上并在本地运行。',
  Monitor: '监视器',
  'models page': '模型页面',
  Deploy: '部署',
  'Download for Docker': '下载 Docker',
  'Apps Suite for Windows 10': 'Windows 10 的应用套件',
  'Download for Windows 10': '下载适用于 Windows 10',
  'No devices or apps registered. You can download our inference app to your local machine and register to run with the models.':
    '没有注册设备或应用程序。您可以将我们的推理应用程序下载到您的本地计算机并注册以使用模型运行。',
  'Creation Time': '创建时间',
  Owner: '所有者',
  Unknown: '未知',
  ConnectionLost: '连接丢失',
  Loading: '加载中',
  'No Model': '无模型',
  '..': '..',
  'Monitor device media': '监控设备媒体',
  'Deploy Now': '立即部署',
  'Preparing media to be uploaded to data browser': '准备要上传到数据浏览器的媒体',
  'All selected media already uploaded to data browser': '所有选定的媒体都已上传到数据浏览器',
  'Add to data browser': '添加到数据浏览器',
  'Choose device to monitor': '选择要监控的设备',
  'Upload time (Newest to Oldest)': '上传时间（最新到最旧）',
  'Upload time (Oldest to Newest)': '上传时间（从最旧到最新）',
  'Labels:': '标记：',
  'Label caption:': '标记标题：',
  'Confidence score': '置信度',
  'Models Availaible to deploy': '可部署的模型',
  'Device Registered to Project': '注册到项目的设备',
  'Or, you can create a downloadable app, download it on your local machine and run locally.':
    '或者，您可以创建一个可下载的应用程序，将其下载到本地计算机上并在本地运行。',
  'View Model Details': '查看模型详细信息',
  'Device deployed to': '设备部署到',
  'No devices': '没有设备',
  'Model Running': '模型运行',
  'Running Time': '运行时间',
  'My profile': '我的账号',
  'API keys': 'API 密钥',
  'API documentation': 'API 文档',
  'API Keys': 'API 密钥',
  'Landing AI Platform uses API keys to authenticate requests. You can view and manage the API keys through this utility.':
    'Landing AI Platform 使用 API 密钥对请求进行身份验证。您可以通过此实用程序查看和管理 API 密钥。',
  'API Key': 'API Key',
  'API Secret': 'API Secret',
  '*********************************************************':
    '*************************************************************',
  'Generate API Key': '生成 API 密钥',
  'Landing AI Platform API keys carry many privileges. Please keep them secure. Do not share the API keys in publicly accessible areas such as GitHub, client-side scripts, etc. All API requests must be made over HTTPS. Calls made over HTTP will fail. Unauthorized API requests will also fail.':
    'Landing AI Platform API 密钥具有许多特权。请确保它们安全。请勿在可公开访问的区域（例如 GitHub、客户端脚本等）共享 API 密钥。所有 API 请求必须通过 HTTPS 进行。通过 HTTP 进行的调用将失败。未经授权的 API 请求也会失败。',
  'Key:': '钥匙：',
  'Secret:': '秘密：',
  'Member seats in your plan: {{count}}': '组织成员名额上限：{{count}}',
  'Active members: {{count}}': '有效成员：{{count}}',
  'Available seats: {{count}}': '剩余成员名额：{{count}}',
  Title: '标题',
  'Show Pending Users': '显示待定用户',
  'Add To Project': '添加到项目',
  Deactivate: '停用',
  'Edit Member': '编辑成员',
  'Projects participation': '项目参与',
  '＋ ADD TO ANOTHER PROJECT': '＋ 添加到另一个项目',
  'This project does not have any device.': '该项目没有任何设备。',
  "Your organization doesn't have devices yet.": '您的组织还没有设备。',
  'Unknown model': '未知模型',
  'Unknown project': '未知项目',
  Created: '已创建',
  Project: '项目',
  'Unknown owner': '未知所有者',
  'Confirm Deletion': '确认删除',
  'Are you sure you want delete this device?': '您确定要删除此设备吗？',
  NAME: '名称',
  VISIBILITY: '权限',
  'Can view by anyone': '任何人都可以查看',
  MEMBERS: '成员',
  Message: '信息',
  'Clone project': '克隆项目',
  'Make visible by anyone': '任何人都可以看到',
  'Make invite only': '仅邀请可见',
  'Sample Projects In Progress': '正在进行的示例项目',
  'Clone from': '克隆自',
  'Include in the cloned project:': '包括在克隆的项目中：',
  Clone: '克隆',
  'Import Sample Project': '导入示例项目',
  'Change Password': '更改密码',
  'Create First Project': '创建第一个项目',
  CREATE: '创造',
  'You do not have any projects yet. Please create one.': '您还没有任何项目。请创建一个。',
  Labelled: '已标记',
  Unassigned: '未分配',
  Completed: '完成',
  'Under Review': '正在审核',
  Reviewed: '完成审核',
  Canceled: '被取消',
  Super: '超级管理员',
  Admin: '管理员',
  Collaborator: '一般',
  Confirming: '确认',
  Active: '正常',
  Pending: '确认中',
  Inactive: '注销',
  Supervisor: '项目管理者',
  Inspector: '标记员',
  'Email Address or Username': '邮箱或者用户名',
  Password: '密码',
  'Email Address': '邮箱',
  'First Name': '名字',
  'Last Name': '姓',
  'Verification code': '邀请码',
  'Company email address': '公司邮箱',
  'Save Metadata': '保存元数据',
  'New Password': '新密码',
  'Confirm New Password': '确认新密码',
  'Defect type': '缺陷类型',
  Reviewer: '审核人',
  Labeler: '标记人',
  'Number of bounding box labels': '边界框元数据的数量',
  'Number of defect types': '缺陷类型数',
  'Enter a new project name': '输入新的项目名称',
  'CSV Has Header': 'CSV 有标题',
  'Company Email': '公司邮箱',
  'Select Organization Role': '选择组织权限',
  'Select Project Role': '选择项目权限',
  'Enter Text': '输入文字',
  'Eval Job': '评估任务',
  'Pipeline name': '流程名称',
  'Start date': '开始日期',
  'End date': '结束日期',
  'Confidence Score': '置信度',
  'Select Project': '选择项目',
  'Select Role': '选择权限',
  Username: '用户名',
  Company: '公司',
  'Current Password': '当前密码',
  'Select {{count}} media': '选择 {{count}} 个媒体',
  split: '分组',
  'Send Verification code': '发送验证码',
  'Reset Password': '重置密码',
  'SSO Log In': '单点登录',
  'Saml Login Failed: {{temp0}}': 'Saml登录失败：{{temp0}}',
  'Invalid CSV': '无效CSV文件',
  'Number of media exceed 5000 entries.': '媒体数量超过5000。',
  'Contains invalid S3 paths: {{temp0}}': '包含无效S3路径：{{temp0}}',
  'Click upload button above to start upload {{count}} media':
    '点击上方上传按钮开始上传{{count}}媒体',
  "Drag 'n' drop folder/images here, or click to select files":
    '将文件夹/图像拖放至此处，或单击选择文件',
  'Please select a role for every selected user': '请为每位已选用户选择角色',
  'Successfully added {{temp0}} member(s) to {{temp2}}': '成功添加{{temp0}}位成员至{{temp2}}',
  'An error occured while adding users to the project.': '添加用户至项目时出错',
  'Invitations have been sent!': '成功邀请所有成员',
  'Name is not valid! Name should be unique in this project':
    '名称无效！名称在本项目内须具备唯一性',
  'Name is not valid! its length should be 0-256 and only contains A-Z, a-z, 0-9, -@#$/_':
    '名称无效！名称长度须为0-256字节，且仅能包含字母大小写、数字及特殊符号-@#$/_',
  'Save Succeed': '已成功保存',
  'Path copied to clipboard': '路径已复制到剪贴板',
  'Metadata updated': '元数据已更新',
  'Metadata removed': '元数据已移除',
  'Metadata {{temp0}} added': '元数据{{temp0}}已添加',
  'Media deleted successfully': '媒体已成功删除',
  'Media not deleted': '媒体未删除',
  '{{temp0}}:{{temp1}} successfully set for {{temp2}} media':
    '{{temp0}}:{{temp1}}已成功为{{temp2}}媒体设置',
  '{{temp0}} media removed from dataset': '{{temp0}}媒体已成功从数据集中移除',
  'Task {{temp0}} has been created. Please go to Task Management to manage it.':
    '任务{{temp0}}已创建。请前往任务管理对其进行管理。',
  'Task {{temp0}} has been created': '任务{{temp0}}已创建',
  'Dataset exporting, go to Export dataset page to see the result':
    '数据集导出，前往导出数据集页面查看结果',
  'Split Distribution': '媒体分组分布',
  'Media defect distribution within splits': '分组内媒体缺陷分布',
  'Successfully assigned media to train/dev/test.': '成功将媒体分配至训练/验证/测试集',
  'successfully update defect: {{temp0}}': '成功更新缺陷：{{temp0}}',
  'successfully created class: {{temp0}}': '成功创建缺陷：{{temp0}}',
  'View result': '查看结果',
  'Setted as target': '被设置为目标',
  'Set as target': '设置为目标',
  'Copied Annotations': '已复制标注',
  'Pasted Annotations': '已粘贴标注',
  'No more media available.': '无更多可用媒体。',
  'Auto Contrast': '自动对比',
  'Histogram Equalization': '直方图均衡化',
  'Attached Successfully': '已成功添加附件',
  'Could not fetch task statistics': '无法获取任务统计信息',
  'Submit Succeeded!': '提交成功！',
  'Submit Error': '提交出错',
  'Failed to Sync the label before submit! Please try again later or contact Landing AI platform team':
    '未能在提交前同步标记！请稍后再试或联系Landing AI平台团队',
  'Review submitted successfully!': '审阅提交成功！',
  'Error submitting review.': '审阅提交出错。',
  'Incomplete task. {{temp0}} labels without review.': '未完成任务。{{temp0}}标记未审阅。',
  Brush: '自由画笔',
  Polyline: '多段线',
  'Draw Bounding Box': '画边界框',
  'Select Instance': '选择实例',
  "Finish drawing segmentation. Hotkey: 'Enter'": '完成图形分割。热键：“回车”',
  'Saved Successfully!': '成功保存！',
  'Comment Saved': '注释已保存',
  'Cleared all labels and annotations': '清除所有标记和标注',
  'Segmentation Brush': '笔刷',
  'Segmentation Eraser': '橡皮擦',
  'Move & Resize': '移动&调整大小',
  Annotations: '标注',
  'No annotations.': '无标注。',
  'No Class to Label': '无可标记',
  'next media': '下一个媒体',
  'previous media': '上一个媒体',
  'toggle view all labels (on media with best picked label)':
    '切换查看（在带有最佳标记的媒体上的）所有标记',
  'decrease agreement threshold': '降低共识阈值',
  'increase agreement threshold': '增加共识阈值',
  'accept media + next media': '接受媒体+下一个媒体',
  'reject media + next media': '拒绝媒体+下一个媒体',
  'toggle labels of the first labeler': '切换第一个标记员的标记',
  'toggle labels of the second labeler': '切换第二个标记员的标记',
  'toggle labels of the third labeler': '切换第三个标记员的标记',
  'Success submitted review for {{temp0}} media.': '成功提交对{{temp0}}媒体的审阅',
  'Cannot accept media below agreement threshold': '无法通过低于共识阈值的媒体',
  'Below agreement threshold media are already rejected': '已拒绝低于共识阈值的媒体',
  'Successfully add to label book': '成功添加至缺陷手册',
  'Remove from selection': '从选择中移除',
  'Select for relabeling': '选中以重新标记',
  'Copied to clipboard': '复制到剪贴板',
  'Copy to clipboard failed': '未能复制到剪贴板',
  'Evaluate model': '评估模型',
  'Could not get split values for this dataset': '无法获取此数据集的分组值',
  '{{temp0}} job successfully created': '{{temp0}}任务已成功创建',
  'Job Id': '任务Id',
  'Could not mark as best': '无法标记为最佳',
  'Could not change favorite state': '无法更改收藏夹状态',
  'Added to Deployment': '添加至部署',
  'Failed to add {{temp0}} to Deployment': '未能添加{{temp0}}至部署',
  'Evaluation job successfully created': '评估任务已成功创建',
  'Adjust threshold': '调整阈值',
  '{{fileName}} uploaded successfully.': '{{fileName}}已成功上传',
  'Yaml file upload failed': 'Yaml文件上传失败',
  '{{name}} updated successfully': '{{name}}已成功上传',
  'Error: {{temp0}}': '错误：{{temp0}}',
  'At least one transformation must be added': '须至少添加一个转换',
  'An image must be selected': '须选择一个图像',
  'Pipeline successfully created': '管道已成功创建',
  'Model {{temp0}} deployment to {{temp1}} {{temp2}} started':
    '模型{{temp0}}部署至{{temp1}} {{temp2}}已启动',
  'Deployment to devices failed {{temp0}}': '部署至设备失败{{temp0}}',
  'Edge Devices cannot be deleted at the moment': '此时无法删除边缘设备',
  'Deployed model {{modelId}}': '已部署模型{{modelId}}',
  'App {{temp0}} deleted': '应用{{temp0}}已删除',
  '- Select Device -': '-选择设备-',
  'Successfully deactivate user': '成功注销用户',
  'Failed deactivate user': '未能注销用户',
  'FEATURE IS NOT IMPLEMENTED': '功能未实现',
  'No Changes Made to Member Information': '未更改成员信息',
  'Sucessfully Updated Member Information': '成功更新成员信息',
  'Device {{temp0}} deleted': '设备{{temp0}}已删除',
  'Clone Sample Project Failed: {{temp0}}': '克隆样本项目失败：{{temp0}}',
  'One good way to start improving quality is to assign labeling tasks to multiple inspectors and see whether their labels overlap. You can evaluate team alignment on defects with the reported “Agreement Score” metric on each image, which quantifies overlap so you can sort by score and understand where defect ambiguity exists.\n\nRemember that your model will not perform better than your labelers.':
    '将标记任务分配至多个标记员并查看他们所作标记的重复度是提高质量的一种有效方法。您可通过各图像上报告的“一致性评分”来评估团队所标记缺陷的一致性，一致性评分能够量化重复，因此您可按照评分分类并了解存在缺陷歧义的地方。\n\n请记住，您的模型不会比你的标记员表现更好。',
  'Remember that the selection of the best model in a training job happens by looking at the valid/dev split metrics. This is why it is also important to have a valid/dev split that represents all of your defect types. A good way to start is to use the auto-split feature to ensure classes are properly split.\n\nLook out for corner or underrepresented cases of your defect types and add them manually to both training and validation sets.':
    '请记住，在训练任务中需要通过查看有效/验证分组值来选择最佳模型。因此，有一个能代表您所有缺陷类型的有效/验证分组至关重要。而要想做到这一点，使用自动分组功能来确保所有类型都正确分组是个不错的方法。\n\n查找出边角或代表性不足的缺陷类型案例并将其手动添加至训练和验证集中。',
  'If you find defect types that are too similar to one another maybe consider merging them.':
    '如果您发现了彼此过于相似的缺陷类型，可以考虑将其合并。',
  'Sometimes there are bounding boxes that look like a correct prediction but are labeled as FN. This happens when the IoU (intersection over union) of the two bounding boxes is too low. If this happens too often the root cause might indicate inconsistent labeling styles.\n\nIf a ground truth bounding box is defect type A and the corresponding predicted bounding box is defect type B it results in a FP for defect type B and one FN for defect type A.\n\nSimilarly segmentation is calculated by looking at the IoU between the ground truth and predicted classes (instead of boxes).':
    '有时看似是正确预测的边界框会被标记为假负例（FN）。当两个边界框的交并比太低时就会发生这种情况。如这种情况过于频繁，则根本原因可能是标签类型不一致。如基准真相边界框为缺陷类型A且相应预测边界框为缺陷类型B，则结果为缺陷类型B为假正例（FP），缺陷类型A为假负例（FN）。类似分组则是通过查看基准真相和预测类型（而非框）之间的交并比来计算的。',
  'Look for TP with low confidence scores or FN in your image analysis report. If you see many of these model errors for a particular defect type that could mean lack of this defect in your training split data.\n\nLook for FP for a particular defect type and evaluate what the predominant miscategorized defect type is. This usually means that you have mislabeled data, your defect definitions are ambiguous or your defects are too similar to distinguish from the images. If you find mislabeled ground truths in image analysis, select the check mark in the upper left corner of the image and click “Create labeling task”, you can also select multiple images and add them to the same labeling task.':
    '在您的图像分析报告中查找置信度分数低的真正例（TP）或假负例（FN）。如您发现针对某一特定缺陷类型存在多个模型错误，则说明您的训练分组数据中缺乏这类缺陷。查找某一特定缺陷类型的假正例（FP）并评估主要被错误分类的缺陷类型。这通常意味着您有错误标记的数据，您的缺陷定义不明确或您的缺陷过于相似而无法从图像上区分。\n\n如果您在图像分析中发现错误标记的基准真相，请选择图像左上角的复选标记，然后点击“创建标记任务”，这样您就能选择多个图像并将其添加至同一标记任务中。',
  'Media marked {{NG}} as it has labels': '因为有标记，所以被标为{{NG}}',
  'Media marked {{OK}} as it has nothing to label': '因为没有任何标记，所以被标为{{OK}}',
  'Media is unlabeled': '媒体未标记',
  'No defect was found. Please create one in the {{link}}.':
    '没有发现缺陷。 请在{{link}}中创建一个。',
  'defect book': '缺陷手册',
} as { [key: string]: string };

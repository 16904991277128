import React from 'react';
import { LabelType } from '@clef/shared/types';
import { Box, Slider, TextField, Typography } from '@material-ui/core';
import { range } from 'lodash';
import useStyles from './styles';
import { CONFIDENCE_THRESHOLD_OPTIONS } from '@clef/shared/constants';

// The constant represents the threshold percentage used to determine if the difference between the item
// (an element of the thresholds array) and the value variable is small enough to consider them equal.
// For example, if thresholdsStep is 0.01 and value is 0.01, an element is considered equal to value
// if it is within 0.0055 (55% of 0.01) from it.
// This means that elements with values between 0.0045 and 0.0155 (0.01 - 0.0055 and 0.01 + 0.0055, respectively)
// would be considered equal to value.
const THRESHOLD_FOR_ACCURACY_EVALUATION = 0.55;

type ConfidenceThresholdSliderProps = {
  labelType: LabelType | null | undefined;
  registeredModelId: string;
  threshold: number;
  updateThreshold: (newThreshold: number) => void;
  disabled?: boolean;
};

const ConfidenceThresholdSlider: React.FC<ConfidenceThresholdSliderProps> = ({
  threshold,
  updateThreshold,
  disabled = false,
}) => {
  const styles = useStyles();

  const { thresholdsMin, thresholdsMax, thresholdsStep } = CONFIDENCE_THRESHOLD_OPTIONS;

  const thresholds = range(thresholdsMin, thresholdsMax, thresholdsStep).map(
    x => Math.round(x * 1000) / 1000,
  );

  if (threshold === undefined) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" marginBottom={0.5}>
      <Typography variant="body1" className={styles.title}>
        {t('Confidence Threshold')}
      </Typography>
      <Box className={styles.thresholdWrapper}>
        <Slider
          disabled={disabled}
          min={thresholdsMin}
          max={thresholdsMax}
          step={thresholdsStep}
          value={threshold}
          onChange={(_, newThreshold) => {
            updateThreshold(newThreshold as number);
          }}
          classes={{
            root: styles.sliderRoot,
            rail: styles.sliderRail,
            track: styles.sliderTrack,
            thumb: styles.sliderThumb,
            active: styles.sliderActive,
            disabled: styles.disabled,
          }}
        />
        <TextField
          disabled={disabled}
          variant="outlined"
          type="number"
          value={threshold}
          InputProps={{
            classes: {
              root: styles.thresholdInputRoot,
              input: styles.thresholdInput,
            },
          }}
          inputProps={{
            className: styles.thresholdInput,
            step: thresholdsStep,
            min: thresholdsMin,
            max: thresholdsMax,
            'data-testid': 'threshold-input',
          }}
          onChange={e => {
            let value = Number(e.target.value || 0);
            if (value > thresholdsMax) {
              value = thresholdsMax;
            } else if (value < thresholdsMin) {
              value = thresholdsMin;
            } else if (!thresholds.includes(value)) {
              value =
                thresholds.find(
                  item =>
                    Math.abs(item - value) <= thresholdsStep * THRESHOLD_FOR_ACCURACY_EVALUATION,
                ) || 0;
            }
            updateThreshold(value || 0);
          }}
        />
      </Box>
      <div style={{ flex: 1 }} />
    </Box>
  );
};

export default ConfidenceThresholdSlider;

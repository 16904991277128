import React from 'react';
import { AnnotationType, EdgeMediaDetails } from '@clef/shared/types';
import { Chip } from '@material-ui/core';
import cx from 'classnames';
import { useDeviceMonitorStyles } from './deviceMonitorStyles';
import { useDeviceMonitorState } from './deviceMonitorState';

interface ConfidenceChipDisplayProps {
  mediaDetails: EdgeMediaDetails | undefined;
  showConfidenceScore: boolean;
  showDefectName: boolean;
}

function ConfidenceChipDisplay({
  mediaDetails,
  showConfidenceScore,
  showDefectName,
}: ConfidenceChipDisplayProps) {
  const styles = useDeviceMonitorStyles();
  const {
    state: { filterClass },
  } = useDeviceMonitorState();

  const classificationMediaDetails = mediaDetails?.labels.find(label =>
    filterClass.length
      ? label.annotation_type === AnnotationType.classification &&
        filterClass.includes(label.class_label)
      : label.annotation_type === AnnotationType.classification,
  );

  const confidence = classificationMediaDetails?.confidence;
  const classLabel = classificationMediaDetails?.class_label;

  const confidenceChipDisplay =
    confidence != null &&
    classificationMediaDetails?.annotation_type === AnnotationType.classification
      ? 'flex'
      : 'none';

  return (
    <Chip
      size="small"
      label={`${showDefectName ? classLabel : ''}${
        showDefectName && showConfidenceScore ? ': ' : ''
      }${showConfidenceScore ? `${confidence?.toLocaleString('en', { style: 'percent' })}` : ''}`}
      className={cx(styles.chipStyle, styles.tagLeftCorner)}
      style={{ backgroundColor: '#1dad9b', display: confidenceChipDisplay }}
    />
  );
}

export default ConfidenceChipDisplay;

import React, { useMemo } from 'react';
import DistributionPreviewGraphs from './DistributionPreviewGraphs';
import { useAutoSplitStyles } from './styles';
import StepContainer from './StepContainer';
import { useAutoSplitState } from './state';
import { Alert } from '@material-ui/lab';
import { distributionAssignmentToSplitStats } from './utils';
import { Divider, Grid, Typography } from '@material-ui/core';

export interface Step3SplitDoneProps {
  onClose: () => void;
}

const Step3SplitDone: React.FC<Step3SplitDoneProps> = ({ onClose }) => {
  const styles = useAutoSplitStyles();
  const {
    state: { defectDistributionWithAssignment },
  } = useAutoSplitState();

  const splitStats = useMemo(
    () =>
      defectDistributionWithAssignment
        ? distributionAssignmentToSplitStats(defectDistributionWithAssignment)
        : undefined,
    [defectDistributionWithAssignment],
  );

  return (
    <StepContainer
      secondaryButton={{
        text: 'Close',
        action: onClose,
      }}
    >
      <section className={styles.infoSection}>
        <Alert className={styles.alerts} severity="success">
          {t('You have successfully assigned {{splitStats}}', {
            splitStats: splitStats?.map(({ name, value }, index) => (
              <React.Fragment key={name}>
                {index > 0 && t(' / ')}
                {t('{{mediaCount}} media to {{split}}', {
                  mediaCount: value,
                  split: name,
                })}
              </React.Fragment>
            )),
          })}
        </Alert>
      </section>
      <Divider />
      {/* Preview graphs */}
      <section className={styles.infoSection}>
        <Grid container alignItems="flex-start" className={styles.infoHeaderMargin}>
          <Typography variant="subtitle1" component="div" className={styles.headerText}>
            {t('distributions for all media with train/dev/test split')}
          </Typography>
        </Grid>
        <DistributionPreviewGraphs
          distributionWithAssignment={defectDistributionWithAssignment}
          previewMode="existing"
        />
      </section>
    </StepContainer>
  );
};

export default Step3SplitDone;

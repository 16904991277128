import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  title: {
    width: 'auto',
    marginBottom: theme.spacing(7),
  },
  newTitle: {
    position: 'absolute',
    top: 48,
    left: 60,
  },
  marginRight1: {
    marginRight: theme.spacing(1),
  },
  marginRight3: {
    marginRight: theme.spacing(3),
  },
  marginBottom2: {
    marginBottom: theme.spacing(2),
  },
  marginBottom8: {
    marginBottom: theme.spacing(8),
  },
  inactivePasswordGuideItemDotIcon: {
    fontSize: 12,
    color: theme.palette.grey[300],
  },
  activePasswordGuideItemDotIcon: {
    fontSize: 12,
    color: '#34C759',
  },
  PasswordGuideItemContent: {
    width: '95%',
  },
  normalButton: {
    width: 400,
    height: 40,
    display: 'flex',
    alignItems: 'center',
  },
  orSeparatorDivider: {
    width: 160,
  },
  orSeparatorText: {
    padding: theme.spacing(0, 8),
    fontWeight: 700,
  },
  autoWidth: {
    width: 'auto',
  },
  normalInputBox: {
    width: 400,
    height: 40,
  },
  passwordInputContainer: {
    width: 'auto',
    position: 'relative',
  },
  passwordGuideContainer: {
    width: 240,
    position: 'absolute',
    right: -260,
    top: 25,
  },
  primaryButton: {
    width: 400,
    height: 40,
  },
  newPrimaryButton: {
    background: theme.palette.blue[500],
    borderRadius: 13,
  },
  loadingSpinner: {
    color: theme.palette.grey[400],
  },
  termsText1: {
    display: 'inline',
  },
  termsText2: {
    display: 'inline',
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  newTermsText2: {
    color: theme.palette.blue[500],
  },
  reviewedTermsCheckboxErrorState: {
    color: theme.palette.secondary.main,
  },
  reviewedTermsError: {
    fontWeight: 300,
    lineHeight: 1.66,
    marginTop: -8,
  },
  standalongLabel: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  forgetYourPasswordText: {
    color: theme.palette.grey[500],
    cursor: 'pointer',
  },
  circleContainer: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  circle: {
    background: theme.palette.primary.main,
    position: 'absolute',
  },
  fadeIn: {
    animation: `$fadeIn 2000ms ${theme.transitions.easing.easeInOut}`,
    opacity: 1,
  },
  fadeOut: {
    animation: `$fadeOut 1000ms ${theme.transitions.easing.easeInOut}`,
    opacity: 0,
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(200%)',
      scale: 0.5,
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
      scale: 1,
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  grow: {
    flex: 1,
  },
  visibilityIconContainer: {
    display: 'flex',
  },
  visibilityIcon: {
    fontSize: 23,
    color: theme.palette.grey[500],
  },
  emptyInputPlaceholder: {
    height: 0,
    width: 0,
    padding: 0,
    margin: 0,
    border: 'none',
  },
  textFieldController: {
    flex: 1,
  },
  deleteIconButton: {
    padding: theme.spacing(1.5),
    width: 36,
    height: 36,
    marginLeft: theme.spacing(1),
  },
}));

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { TagAPI } from '@/api/tag_api';
import { uniq, uniqBy } from 'lodash';
import { datasetQueryKeys } from '../dataset';
import { useGetSelectedProjectQuery } from '../projects';
import { MediaDetailsWithPrediction, Tag } from '@clef/shared/types';
import { tagsQueryKey } from './queries';
import { useTypedSelector } from '@/hooks/useTypedSelector';

export const useCreateNewTagMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const selectedProjectId = useTypedSelector(state => state.project.selectedProjectId);
  return useMutation({
    mutationFn: async (params: Parameters<typeof TagAPI.createNewTag>[0]) => {
      const response = await TagAPI.createNewTag(params);
      return response.data;
    },
    onSuccess: tag => {
      selectedProjectId &&
        queryClient.setQueriesData<Tag[]>(
          tagsQueryKey.listByProjectId(selectedProjectId),
          (prev = []) => [...prev, tag],
        );
      selectedProjectId &&
        queryClient.invalidateQueries(datasetQueryKeys.filterOptions(selectedProjectId));
      selectedProjectId &&
        queryClient.invalidateQueries(datasetQueryKeys.allWithFilters(selectedProjectId));
      enqueueSnackbar(`Created ${tag.name} tag successfully `, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: e => {
      enqueueSnackbar((e as Error).message, {
        variant: 'error',
      });
    },
  });
};

export const useUpdateTagMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const selectedProjectId = useTypedSelector(state => state.project.selectedProjectId);
  return useMutation({
    mutationFn: async (params: Parameters<typeof TagAPI.updateTag>[0]) => {
      await TagAPI.updateTag(params);
      return params;
    },
    onSuccess: ({ tagId, name, color }) => {
      selectedProjectId &&
        queryClient.setQueriesData<Tag[]>(
          tagsQueryKey.listByProjectId(selectedProjectId),
          (prev = []) =>
            prev.map(prevTag => {
              if (prevTag.id === tagId) {
                return {
                  ...prevTag,
                  name: name ?? prevTag.name,
                  color: color ?? prevTag.color,
                };
              } else {
                return prevTag;
              }
            }),
        );
      selectedProjectId &&
        queryClient.invalidateQueries(datasetQueryKeys.filterOptions(selectedProjectId));
    },
    onError: e => {
      enqueueSnackbar((e as Error).message, {
        variant: 'error',
      });
    },
  });
};

export const useArchiveTagMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const selectedProjectId = useTypedSelector(state => state.project.selectedProjectId);
  return useMutation({
    mutationFn: async (tagId: number) => {
      await TagAPI.archiveTag(tagId);
      return tagId;
    },
    onSuccess: tagId => {
      selectedProjectId &&
        queryClient.setQueriesData<Tag[]>(
          tagsQueryKey.listByProjectId(selectedProjectId),
          (prev = []) => prev.filter(prevTag => prevTag.id !== tagId),
        );
      selectedProjectId &&
        queryClient.invalidateQueries(datasetQueryKeys.filterOptions(selectedProjectId));
      enqueueSnackbar('The selected tag has been archived', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
  });
};

export const useAttachTagStringsToMediasMutation = () => {
  const queryClient = useQueryClient();
  const { datasetId, id: selectedProjectId } = useGetSelectedProjectQuery().data ?? {};
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: async (params: Parameters<typeof TagAPI.attachTagStringsToMedias>[0]) => {
      const response = await TagAPI.attachTagStringsToMedias(params);
      return { tags: response.data.tags, params };
    },
    onSuccess: ({ tags, params }) => {
      if (!datasetId || selectedProjectId !== params.projectId) {
        return;
      }
      const tagIds = tags.map(tag => tag.id);
      if (params.selectOption.isUnselectMode) {
        queryClient.setQueriesData(
          datasetQueryKeys.mediaDetails(datasetId),
          (prev?: MediaDetailsWithPrediction) =>
            prev
              ? {
                  ...prev,
                  tagIds: params.accumulation ? uniq([...(prev.tagIds ?? []), ...tagIds]) : tagIds,
                }
              : prev,
        );
      } else {
        params.selectOption.selectedMedia.forEach(mediaId => {
          queryClient.setQueriesData(
            datasetQueryKeys.mediaDetails(datasetId, { mediaId }),
            (prev?: MediaDetailsWithPrediction) =>
              prev
                ? {
                    ...prev,
                    tagIds: params.accumulation
                      ? uniq([...(prev.tagIds ?? []), ...tagIds])
                      : tagIds,
                  }
                : prev,
          );
        });
      }
      const list =
        queryClient.getQueryData<Tag[]>(tagsQueryKey.listByProjectId(params.projectId)) ?? [];
      const hasNewTag = tags.some(tag => !list.some(_tag => _tag.id === tag.id));
      if (hasNewTag) {
        // it means user has newly created tag, so we need to refresh the filter and queries with filters
        selectedProjectId &&
          queryClient.invalidateQueries(datasetQueryKeys.filterOptions(selectedProjectId));
      }
      queryClient.invalidateQueries(datasetQueryKeys.allWithFilters(params.projectId));
      queryClient.setQueriesData(
        tagsQueryKey.listByProjectId(params.projectId),
        (prev: Tag[] = []) => {
          return uniqBy([...prev, ...tags], 'id');
        },
      );
      if (params.accumulation) {
        enqueueSnackbar('Successfully added Tags to the selected images', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    },
  });
};

import React, { useEffect, useMemo } from 'react';
import { FormControlLabel, Radio, Tooltip, Box } from '@material-ui/core';

import { Typography, ToggleButton } from '@clef/client-library';
import { FilterOptionName } from '@clef/shared/types';

import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';

import { useDataBrowserState } from '../dataBrowserState';
import ModelSettings from './ModelSettings';
import useStyles from './styles';

const LabelTogglesClassification: React.FC<{}> = () => {
  const styles = useStyles();

  const {
    state: { showGroundTruth, showPredictions, showHeatmap, appliedFilters },
    dispatch,
  } = useDataBrowserState();
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();

  const hasAppliedGroundTruthFilter = !!appliedFilters[FilterOptionName.GroundTruthLabels];
  const hasAppliedPredictionFilter = !!appliedFilters[FilterOptionName.PredictionLabels];

  const isFilterGtAndPred = useMemo(() => {
    const keys = Object.keys(appliedFilters);
    return keys.includes('Ground truth labels') && keys.includes('Prediction labels');
  }, [appliedFilters]);

  useEffect(() => {
    if (isFilterGtAndPred) {
      dispatch(draft => {
        draft.showGroundTruth = false;
        draft.showPredictions = true;
      });
    }
  }, [dispatch, isFilterGtAndPred]);

  return (
    <Box ml={4} display="flex" justifyContent="space-between" alignItems="center" width="100%">
      <Box className={styles.classificationToggleWrapper}>
        <Tooltip
          placement="top"
          arrow
          title={
            hasAppliedPredictionFilter
              ? t('To view Ground truth labels, please clear Prediction labels filter')
              : ''
          }
        >
          <FormControlLabel
            control={
              <Radio
                name="label-display"
                checked={showGroundTruth && !showPredictions}
                color="primary"
                onClick={() =>
                  dispatch(draft => {
                    draft.showGroundTruth = true;
                    draft.showPredictions = false;
                  })
                }
              />
            }
            label={
              <Typography variant="body_medium" className={styles.labelText}>
                {t('Ground truth')}
              </Typography>
            }
          />
        </Tooltip>
        <Tooltip
          placement="top"
          arrow
          title={
            hasAppliedGroundTruthFilter
              ? t('To view Prediction labels, please clear Ground truth labels filter')
              : ''
          }
        >
          <FormControlLabel
            control={
              <Radio
                name="label-display"
                checked={showPredictions && !showGroundTruth}
                color="primary"
                onClick={() =>
                  dispatch(draft => {
                    draft.showPredictions = true;
                    draft.showGroundTruth = false;
                  })
                }
              />
            }
            label={
              <Box display="flex" alignItems="center">
                <Typography variant="body_medium" className={styles.labelText}>
                  {t('Prediction')}
                </Typography>
                {showPredictions && !showGroundTruth && (
                  <>
                    <Box mr={1}>{': '}</Box>
                    <ModelSettings />
                  </>
                )}
              </Box>
            }
          />
        </Tooltip>
      </Box>
      {!!currentModelId && (
        <ToggleButton
          id="toggle-heatmap-cls"
          isOn={showHeatmap}
          onToggle={() => {
            dispatch(draft => {
              draft.showHeatmap = !draft.showHeatmap;
            });
          }}
        >
          <Typography variant="body_medium" className={styles.labelText}>
            {t('Heatmap')}
          </Typography>
        </ToggleButton>
      )}
    </Box>
  );
};

export default LabelTogglesClassification;

import {
  TransformPipelineId,
  PipelineMetadata,
  PipelineSection,
  ProjectId,
  Transform,
  Pipeline,
  Media,
  InstantLearningPostprocessParams,
  InstantLearningPostprocessResult,
} from '@clef/shared/types';
import { API_GATEWAY_URL } from '../constants';
import { BaseAPI } from './base_api';

export const transformsAPIUrlPrefix = 'trs/v1';

class TransformsAPI extends BaseAPI {
  async getPipelines(projectId: ProjectId): Promise<PipelineMetadata[]> {
    return this.get('pipelines', { projectId }, true).then((data: PipelineMetadata[]) =>
      // Sort here for now until backend implements sorting and pagination
      data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)),
    );
  }

  async getPipeline(params: {
    pipelineId: TransformPipelineId;
    projectId: ProjectId;
  }): Promise<Pipeline> {
    return this.get(`pipelines/${params.pipelineId}`, { projectId: params.projectId }, true);
  }

  async getPipelineYaml(params: {
    pipelineId: TransformPipelineId;
    projectId: ProjectId;
  }): Promise<string> {
    return this.get(
      `pipelines/${params.pipelineId}/convert`,
      { projectId: params.projectId },
      true,
    );
  }

  async createPipeline(
    projectId: ProjectId,
    name: PipelineMetadata['name'],
    sections: Pipeline['sections'],
  ): Promise<any> {
    return this.postJSON('pipelines', { name, projectId, sections });
  }

  async createPipelineFromFile(projectId: ProjectId, file: File): Promise<any> {
    const yamlContent = await file.text();
    return this.postJSON('pipelines', { name: file.name, yamlContent, projectId });
  }

  async updatePipelineName(
    id: TransformPipelineId,
    name: PipelineMetadata['name'],
    projectId: ProjectId,
  ): Promise<null> {
    const queryParams = new URLSearchParams({ name, projectId: projectId.toString() });
    return this.put(`pipelines/${id}?${queryParams}`, null);
  }

  async getTransforms(): Promise<Transform[]> {
    return this.get('transforms', undefined, true);
  }

  async executePipeline(
    sources: string[],
    sections: Pipeline['sections'],
    projectId?: ProjectId,
  ): Promise<Record<PipelineSection, Required<Media>['url'][]>> {
    return this.postJSON('execute', { sources, sections, projectId }).then(({ data }) => data);
  }

  async postprocessInstantLearning(
    params: InstantLearningPostprocessParams,
  ): Promise<InstantLearningPostprocessResult[]> {
    return this.get(
      `segmentation/postprocess`,
      { ...params, algorithms: JSON.stringify(params.algorithms) },
      true,
    );
  }
}

export default new TransformsAPI(transformsAPIUrlPrefix, API_GATEWAY_URL);

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  button: {
    width: '100%',
  },
  paper: {
    width: 420,
  },
  linkText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  boldText: {
    fontWeight: 'bold',
  },
}));

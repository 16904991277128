import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import cx from 'classnames';
import { useAtom } from 'jotai';

import { Button, Dropdown, DropdownProps, SelectOptions } from '@clef/client-library';
import { FilterOptionType, FilterOperations } from '@clef/shared/types';

import { appliedFiltersAtom } from '@/uiStates/mediaFilter';

const useStyles = makeStyles(theme => ({
  textButton: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  dropdownPanel: {
    minWidth: 150,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  operationValueContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  inputRoot: {
    width: 120,
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
    },
  },
  input: {
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: '0.75rem',
  },
  extraInputMargin: {
    marginBottom: theme.spacing(1),
  },
}));

export type FilterDropdownNumberOperationProps = {
  customizedSelector: (applied: boolean, open: boolean) => React.ReactNode;
  filterOption: FilterOptionType;
  // To be passed to Dropdown
  placement?: DropdownProps['placement'];
  extraGutter?: DropdownProps['extraGutter'];
  filterMappingKey: string | number;
};

export const FilterDropdownNumberOperation: React.FC<FilterDropdownNumberOperationProps> = ({
  customizedSelector,
  filterOption,
  placement,
  extraGutter,
  filterMappingKey,
}) => {
  const styles = useStyles();

  const { filterName, filterType, fieldId, operations } = filterOption;

  const [appliedFilters, setAppliedFilters] = useAtom(appliedFiltersAtom);
  const curAppliedFilters = useMemo(
    () => appliedFilters[filterMappingKey] ?? {},
    [appliedFilters, filterMappingKey],
  );

  const [numberOperation, setNumberOperation] = useState<FilterOperations>(
    curAppliedFilters[filterName]?.o || '=',
  );

  const [numberValue, setNumberValue] = useState<number | (number | '')[] | ''>(
    curAppliedFilters[filterName] ? (curAppliedFilters[filterName].v as number | number[]) : '',
  );

  const applyNumberOperationFilter = useCallback(() => {
    const newCurAppliedFilters = { ...curAppliedFilters };
    newCurAppliedFilters[filterName] = {
      v: numberValue as number | number[],
      o: numberOperation,
      t: filterType,
      fi: fieldId,
    };
    setAppliedFilters(prev => ({
      ...prev,
      [filterMappingKey]: newCurAppliedFilters,
    }));
  }, [
    curAppliedFilters,
    filterName,
    numberValue,
    numberOperation,
    filterType,
    fieldId,
    setAppliedFilters,
    filterMappingKey,
  ]);

  const onValueChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target?.value;
    setNumberValue(value ? Number(value) : '');
  }, []);

  const onBetweenValueChange = useCallback((index: number, value?: string) => {
    setNumberValue(prev => {
      const newValue = (prev as (number | '')[]).slice();
      newValue.splice(index, 1, value ? Number(value) : '');
      return newValue;
    });
  }, []);

  useEffect(() => {
    if (Array.isArray(numberValue) && numberOperation !== 'BETWEEN') {
      setNumberValue('');
    }
    if (!Array.isArray(numberValue) && numberOperation === 'BETWEEN') {
      setNumberValue(['', '']);
    }
  }, [numberOperation, numberValue]);

  const hasOnlyEqualOperation =
    operations.length === 1 && (operations[0] === '=' || operations[0] === 'IS');

  return (
    <Dropdown
      placement={placement}
      extraGutter={extraGutter}
      dropdown={
        <div className={styles.dropdownPanel}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            wrap="nowrap"
            className={styles.operationValueContainer}
            spacing={4}
          >
            {!hasOnlyEqualOperation && (
              <Grid item container direction="column" justifyContent="center">
                <Typography variant="body2" gutterBottom>
                  {t('operation')}
                </Typography>
                <SelectOptions
                  value={numberOperation}
                  onChange={newValue => setNumberOperation(newValue as FilterOperations)}
                  options={operations}
                />
              </Grid>
            )}
            <Grid item container direction="column" justifyContent="center">
              {!hasOnlyEqualOperation && (
                <Typography variant="body2" gutterBottom>
                  {t('value')}
                </Typography>
              )}
              {/* If operation !== BETWEEN, single field  */}
              {/* If operation === BETWEEN, multiple fields  */}
              {!Array.isArray(numberValue) ? (
                <TextField
                  variant="outlined"
                  onChange={onValueChange}
                  autoFocus
                  onKeyPress={ev => {
                    if (ev.key === 'Enter') {
                      ev.preventDefault();
                      applyNumberOperationFilter();
                    }
                  }}
                  type="number"
                  value={numberValue}
                  inputProps={{
                    className: styles.input,
                    'data-testid': 'filter-number-input-0',
                  }}
                  classes={{
                    root: styles.inputRoot,
                  }}
                  size="small"
                  data-testid="number-filter-value-field"
                />
              ) : (
                numberValue.map((numberValueSingle, index) => (
                  <TextField
                    variant="outlined"
                    key={index}
                    onChange={e => onBetweenValueChange(index, e.target?.value)}
                    type="number"
                    value={numberValueSingle}
                    inputProps={{
                      className: styles.input,
                      'data-testid': `filter-number-input-${index}`,
                    }}
                    classes={{
                      root: cx(styles.inputRoot, styles.extraInputMargin),
                    }}
                    size="small"
                  />
                ))
              )}
            </Grid>
          </Grid>
          {/* Clear all options */}
          <Grid container justifyContent="flex-end">
            <Button
              color="primary"
              size="small"
              className={styles.textButton}
              onClick={applyNumberOperationFilter}
              disabled={
                Array.isArray(numberValue)
                  ? numberValue.some(value => typeof value !== 'number')
                  : typeof numberValue !== 'number'
              }
              id="number-filter-apply-btn"
            >
              {t('Apply')}
            </Button>
            <Button
              color="secondary"
              size="small"
              className={styles.textButton}
              onClick={() => {
                const newCurAppliedFilters = { ...curAppliedFilters };
                delete newCurAppliedFilters[filterName];
                setAppliedFilters(prev => ({
                  ...prev,
                  [filterMappingKey]: newCurAppliedFilters,
                }));
              }}
              disabled={!appliedFilters[filterMappingKey]?.[filterName]}
              id="number-filter-clear-btn"
            >
              {t('Clear')}
            </Button>
          </Grid>
        </div>
      }
    >
      {open => customizedSelector(!!appliedFilters[filterMappingKey]?.[filterName], open)}
    </Dropdown>
  );
};

export default FilterDropdownNumberOperation;

import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { format } from 'date-fns';
import useStyles from './styles';
import {
  useIsEnterprisePresalesUser,
  useIsEnterpriseUser,
  useIsPricingV2VisionaryUser,
  useQuotaAndUsageInfo,
} from '@/hooks/useSubscriptions';
import CommonBlockHeader from './CommonBlockHeader';

type PeriodEndDateBlockCommonProps = {
  title: string;
  tooltip: string;
};

const PeriodEndDateBlockCommon = (props: PeriodEndDateBlockCommonProps) => {
  const styles = useStyles();
  const { title, tooltip } = props;
  const { currentPeriodEnd } = useQuotaAndUsageInfo();

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box>
        <CommonBlockHeader title={title} tooltip={tooltip} />
        <Box marginTop={1}>
          <Typography className={styles.value}>
            {t('{{end}}', {
              end: format(
                currentPeriodEnd ? new Date(currentPeriodEnd) : Date.now(),
                'MMMM dd yyyy',
              ),
            })}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

const ContractRenewalDateBlock = () => {
  return (
    <PeriodEndDateBlockCommon
      title={t('Contract renewal date')}
      tooltip={t('Your contract end date.')}
    />
  );
};

const TrialEndsDateBlock = () => {
  return (
    <PeriodEndDateBlockCommon title={t('Trial ends date')} tooltip={t('Your trial end date.')} />
  );
};

const CycleEndDateBlock = () => {
  return (
    <PeriodEndDateBlockCommon
      title={t('Cycle ends date')}
      tooltip={t('Your current cycle end date.')}
    />
  );
};

const PeriodEndDateBlock = () => {
  const isEnterpriseUser = useIsEnterpriseUser();
  const isEnterprisePresalesUser = useIsEnterprisePresalesUser();
  const isPricingV2VisionaryUser = useIsPricingV2VisionaryUser();

  if (isEnterpriseUser) {
    return <ContractRenewalDateBlock />;
  } else if (isEnterprisePresalesUser) {
    return <TrialEndsDateBlock />;
  } else if (isPricingV2VisionaryUser) {
    return <CycleEndDateBlock />;
  } else {
    return null;
  }
};

export default PeriodEndDateBlock;

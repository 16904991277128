import React from 'react';
import { useAtom } from 'jotai';
import { modelListFilterOptionsAtom } from '../atoms';
import { Box, makeStyles } from '@material-ui/core';
import { DefectColorChip } from '@/pages/DataBrowser/ModelPerformance/ConfusionMatrix';
import { Typography } from '@clef/client-library';

const useStyles = makeStyles(theme => ({
  chip: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  filterChip: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.blue[50],
    padding: theme.spacing(2, 3),
    gap: theme.spacing(1),
    color: theme.palette.grey[800],
    borderRadius: '5px',
  },
  filterChipText: {
    fontWeight: 700,
    color: theme.palette.greyModern[800],
  },
}));

export const FilterOptionsChip: React.FC<{}> = () => {
  const styles = useStyles();
  const [filterOptions] = useAtom(modelListFilterOptionsAtom);

  if (!filterOptions) {
    return null;
  }

  return (
    <Box className={styles.chip}>
      <Box className={styles.filterChip}>
        <Typography className={styles.filterChipText}>{t('Ground truth = ')}</Typography>
        <DefectColorChip defectId={filterOptions.gtClassId} maxWidth={70} />
      </Box>
      <Box className={styles.filterChip}>
        <Typography className={styles.filterChipText}>{t('Prediction = ')}</Typography>
        <DefectColorChip defectId={filterOptions.predClassId} isPrediction maxWidth={70} />
      </Box>
    </Box>
  );
};

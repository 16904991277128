import React, { useState, useCallback, useRef } from 'react';
import Konva from 'konva';
import { BoxAnnotation, Dimensions, Position } from '@clef/shared/types';
import {
  BoxAnnotationComponent,
  capPosition,
  flipBoxToPositiveDimensions,
} from '../components/Shapes';
import throttle from 'lodash/throttle';
import { calcAccurateOffset } from '../utils';
import { AnnotationCreatorBuilder } from '../types';
import Crosshair from '../components/Crosshair';

type BoxAnnotationDrawHook = (options: {
  scale: number;
  color?: string;
  disabled?: boolean;
  mousePos?: Position;
  imageDimensions?: Dimensions;
  onMissingColor?: () => void;
  onFinishAnnotation: (box: BoxAnnotation) => void;
  onIsWorkingInProgressChanged?: (wip: boolean) => void;
  onDisabledMouseDown?: () => void;
  onMouseDown?: (e: Konva.KonvaEventObject<MouseEvent>) => void;
  onMouseUp?: (e: Konva.KonvaEventObject<MouseEvent>) => void;
}) => AnnotationCreatorBuilder;

export const useBoxAnnotationCreator: BoxAnnotationDrawHook = ({
  scale,
  color,
  disabled = false,
  mousePos,
  imageDimensions = { width: 0, height: 0 },
  onMissingColor,
  onFinishAnnotation,
  onIsWorkingInProgressChanged,
  onDisabledMouseDown,
  onMouseDown,
  onMouseUp,
}) => {
  const isDrawing = useRef(false);
  const [wipBoxAnnotation, setWipBoxAnnotation] = useState<BoxAnnotation>();

  const onMouseMove = useCallback(
    throttle((e: Konva.KonvaEventObject<MouseEvent>) => {
      if (isDrawing.current) {
        const pos = calcAccurateOffset(e);
        const cPos = capPosition(pos, { x: 0, y: 0, ...imageDimensions });
        setWipBoxAnnotation(prev =>
          prev
            ? {
                ...prev,
                width: cPos.x - prev.x,
                height: cPos.y - prev.y,
              }
            : undefined,
        );
      }
    }, 48),
    [imageDimensions],
  );

  const internalOnMouseDown = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      onMouseDown?.(e);

      if (disabled) {
        onDisabledMouseDown?.();
        return;
      }

      if (!color) {
        onMissingColor?.();
        return;
      }
      const cPos = calcAccurateOffset(e);
      const isOutsideImage =
        imageDimensions &&
        (cPos.x < 0 ||
          cPos.y < 0 ||
          cPos.x > imageDimensions.width ||
          cPos.y > imageDimensions.height);
      if (isOutsideImage) {
        return;
      }
      isDrawing.current = true;
      onIsWorkingInProgressChanged?.(true);
      setWipBoxAnnotation({
        x: cPos.x,
        y: cPos.y,
        width: 0,
        height: 0,
        color,
      });
    },
    [
      color,
      disabled,
      imageDimensions,
      onDisabledMouseDown,
      onIsWorkingInProgressChanged,
      onMissingColor,
      onMouseDown,
    ],
  );

  const internalOnMouseUp = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      onMouseUp?.(e);

      if (disabled) {
        return;
      }

      if (!color || !wipBoxAnnotation) {
        return;
      }
      isDrawing.current = false;
      onIsWorkingInProgressChanged?.(false);
      const pos = calcAccurateOffset(e);
      const cPos = capPosition(pos, { x: 0, y: 0, ...imageDimensions });
      const width = cPos.x - wipBoxAnnotation.x;
      const height = cPos.y - wipBoxAnnotation.y;
      const isValidBox = width !== 0 && height !== 0;

      const { x, y } = wipBoxAnnotation;
      const box = flipBoxToPositiveDimensions({ x, y, width, height });
      isValidBox && onFinishAnnotation({ ...wipBoxAnnotation, ...box });
      setWipBoxAnnotation(undefined);
    },
    [
      color,
      disabled,
      imageDimensions,
      onFinishAnnotation,
      onIsWorkingInProgressChanged,
      onMouseUp,
      wipBoxAnnotation,
    ],
  );

  const onDiscard = useCallback(() => {
    isDrawing.current = false;
    onIsWorkingInProgressChanged?.(false);
    setWipBoxAnnotation(undefined);
  }, [onIsWorkingInProgressChanged]);

  return {
    creatorPreviewComponent: wipBoxAnnotation ? (
      <BoxAnnotationComponent
        key="box-annotation-wip"
        annotationId="wip-box-annotation"
        annotation={wipBoxAnnotation}
        editable={false}
        scale={scale}
      />
    ) : null,
    creatorHelperComponent: <Crosshair mousePos={mousePos} scale={scale} />,
    creatorInteractiveProps: {
      onMouseUp: internalOnMouseUp,
      onMouseDown: internalOnMouseDown,
      onMouseMove,
    },
    creatorCustomHandlers: {
      onEscPressed: onDiscard,
    },
  };
};

import React from 'react';
import { QRCodeSVG } from 'qrcode.react';

const defaultSize = 150;

export type QrCodeProps = {
  value: string;
  size?: number;
  className?: string;
  logo?: string;
};

const QrCode: React.FC<QrCodeProps> = props => {
  const { value, size = defaultSize, logo } = props;
  const logoSize = size * 0.2;
  return (
    <QRCodeSVG
      value={value}
      size={size}
      level="M"
      imageSettings={
        logo
          ? {
              src: logo,
              x: (size - logoSize) * 0.5,
              y: (size - logoSize) * 0.5,
              width: logoSize,
              height: logoSize,
              excavate: true,
            }
          : undefined
      }
    />
  );
};

export default QrCode;

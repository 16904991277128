import { RegisteredModelWithBundles } from '@/api/model_api';
import React, { useEffect, useState, useMemo } from 'react';

const INITIAL_PAGE_INDEX = 1;
const ROWS_PER_PAGE = 8;

export const usePaginatedModels = (savedModels: RegisteredModelWithBundles[]) => {
  const [page, setPage] = useState(INITIAL_PAGE_INDEX);
  const totalPages = Math.ceil(savedModels.length / ROWS_PER_PAGE);
  const handlePaginationChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const paginatedModels = useMemo(
    () =>
      savedModels.slice(
        ROWS_PER_PAGE * (page - 1),
        Math.min(ROWS_PER_PAGE * page, savedModels.length),
      ),
    [savedModels, page],
  );

  useEffect(() => {
    if (page > totalPages) {
      setPage(INITIAL_PAGE_INDEX);
    }
  }, [page, totalPages]);
  return { page, setPage, totalPages, paginatedModels, handlePaginationChange };
};

import React, { useState } from 'react';
import { Dialog, makeStyles, LinearProgress, Slide, useTheme, Tooltip } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import CloseRounded from '@material-ui/icons/CloseRounded';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
import MinimizeIcon from '@material-ui/icons/RemoveRounded';
import { UPLOAD_DIALOG_Z_INDEX } from './UploadFullscreen/constants';
import classNames from 'classnames';

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore - this is from official doc
  // https://v4-8-3.material-ui.com/components/dialogs/#transitions
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface FullscreenDialogProps {
  open: boolean;
  onClose?: () => void;
  onBack?: () => void;
  onMinimize?: () => void;
  loading?: boolean;
  coverPendoGuide?: boolean;
  disableEscapeKeyDown?: boolean;
}

const useStyles = makeStyles(theme => ({
  coverPendoGuide: {
    // make it higher than pendo guide.
    // ts does not allow this but it is valid
    zIndex: `${UPLOAD_DIALOG_Z_INDEX}!important` as unknown as number,
  },
  topBar: {
    width: '100%',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'fixed',
  },
  topBarPlaceholder: {
    width: '100%',
    padding: theme.spacing(1),
    display: 'flex',
    minHeight: 56,
    maxHeight: 56,
  },
  flexGrow: {
    flexGrow: 1,
  },
  postRequestMask: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
    background: 'rgba(255,255,255,0.7)',
  },
}));

const FullscreenDialog: React.FC<FullscreenDialogProps> = ({
  open,
  onClose,
  children,
  onBack,
  onMinimize,
  loading,
  coverPendoGuide,
  disableEscapeKeyDown = false,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const [minimizeOpen, setMinimizeOpen] = useState(true);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-testid="create-project-dialog"
      fullScreen
      // @ts-ignore - this is from official doc
      // https://v4-8-3.material-ui.com/components/dialogs/#transitions
      TransitionComponent={Transition}
      classes={{ root: classNames(coverPendoGuide && styles.coverPendoGuide) }}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <div className={styles.topBarPlaceholder} />
      <div className={styles.topBar}>
        {!!onBack && (
          <IconButton id="close-create-project-dialog" aria-label="back" onClick={onBack}>
            <ArrowBackRounded htmlColor={theme.palette.grey[500]} />
          </IconButton>
        )}
        <div className={styles.flexGrow} />
        {!!onMinimize && (
          <Tooltip
            open={minimizeOpen}
            onClose={() => setMinimizeOpen(false)}
            onOpen={() => setMinimizeOpen(true)}
            arrow
            placement="bottom"
            title={t(
              'You can minimize this window. Images will continue to upload in the background.',
            )}
          >
            <IconButton aria-label="minimize" onClick={onMinimize}>
              <MinimizeIcon htmlColor={theme.palette.grey[500]} />
            </IconButton>
          </Tooltip>
        )}
        {!!onClose && (
          <IconButton aria-label="close" onClick={onClose}>
            <CloseRounded
              data-testid="close-upload-media-dialog-btn"
              htmlColor={theme.palette.grey[500]}
            />
          </IconButton>
        )}
      </div>
      {loading && (
        <div className={styles.postRequestMask}>
          <LinearProgress />
        </div>
      )}
      {children}
    </Dialog>
  );
};

export default FullscreenDialog;

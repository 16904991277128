import React from 'react';
import { Box, MenuItem, MenuList } from '@material-ui/core';
import { useTypographyStyles } from '@clef/client-library/src/themes/typography';
import classNames from 'classnames';
import { useZeroAuthProjectsApi } from '@/hooks/api/useZeroAuthApi';
import { useCurrentProject, useZeroAuthInstantLearningState } from './state';
import CLEF_PATH from '@/constants/path';
import { Button, Dropdown } from '@clef/client-library';
import logoLandingLens from '@/images/logo_LandingLens_color.svg';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import useStyles from './styles';

export type ZeroAuthHeaderProps = {};

const ZeroAuthHeader: React.FC<ZeroAuthHeaderProps> = () => {
  const styles = useStyles();
  const typographyStyles = useTypographyStyles();
  const [projects] = useZeroAuthProjectsApi({});
  const { state, dispatch } = useZeroAuthInstantLearningState();
  const { currentProjectId } = state;
  const currentProject = useCurrentProject();
  return (
    <Box>
      {/* Banner */}
      <Box display="flex" height={56} alignItems="center" paddingX={5}>
        <Box display="flex" alignItems="center">
          <img src={logoLandingLens} height={28} data-testid="landing-lens-logo" />
        </Box>
        <div className={styles.verticalLine}></div>
        <Box className={typographyStyles.h4_semibold}>{t('Visual Prompting')}</Box>
        <Box className={classNames(typographyStyles.body_bold, styles.betaBadge)}>{t('Beta')}</Box>
        <div style={{ flex: 1 }}></div>
        {/* Expanded projects, displayed on large screen */}
        <Box className={styles.projects} data-testid="projects">
          {projects?.map(project => (
            <Button
              id={`project-${project.id}`}
              key={project.id}
              className={classNames(project.id === currentProjectId && styles.currentProjectButton)}
              role="button"
              variant={project.id === currentProjectId ? 'tonal' : 'text'}
              color={project.id === currentProjectId ? 'primary' : 'default'}
              onClick={() => {
                dispatch(draft => {
                  draft.currentProjectId = project.id;
                  draft.mediaIndex = 0;
                  // will be initialized again in index.tsx
                  draft.mediaStatesByIndex = {};
                });
              }}
            >
              {project.name}
            </Button>
          ))}
        </Box>
        {/* projects dropdown, displayed on small screen */}
        <Box className={styles.projectDropdown}>
          <Dropdown
            dropdown={toggleDropdown => (
              <MenuList>
                {projects?.map(project => (
                  <MenuItem
                    key={project.id}
                    selected={project.id === currentProjectId}
                    onClick={() => {
                      toggleDropdown(false);
                      dispatch(draft => {
                        draft.currentProjectId = project.id;
                        draft.mediaIndex = 0;
                        // will be initialized again in index.tsx
                        draft.mediaStatesByIndex = {};
                      });
                    }}
                  >
                    {project.name}
                  </MenuItem>
                ))}
              </MenuList>
            )}
          >
            <Button id="project-dropdown" variant="outlined" endIcon={<ArrowDropDown />}>
              {currentProject?.name}
            </Button>
          </Dropdown>
        </Box>
        <Box marginLeft={2}>
          <Button
            id="start-for-free"
            color="primary"
            variant="contained"
            onClick={() => {
              window.location.href = CLEF_PATH.signup.main;
            }}
          >
            {t('Start for Free')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ZeroAuthHeader;

import { BaseAPI } from './base_api';
import { ApiResponse, EvaluationSet, ProjectId } from '@clef/shared/types';

export type EvaluationSetItem = EvaluationSet & {
  split?: {
    id: number;
    splitSetName: string;
  };
} & {
  datasetVersion: {
    datasetId: number;
    version: number;
    name: string;
    dataset: {
      name: string;
    };
  };
};

class EvaluationSetAPI extends BaseAPI {
  async getProjectEvaluationSetModelInfo(
    projectId: ProjectId,
  ): Promise<ApiResponse<EvaluationSetItem[]>> {
    return this.get('list', { projectId });
  }

  async addEvaluationSet(params: {
    projectId: number;
    datasetVersionId: number;
    splitId: number | null;
    tagId?: number;
    hidden?: boolean;
  }): Promise<ApiResponse<EvaluationSet>> {
    return this.postJSON('add', params);
  }

  async hideEvaluationSet(params: {
    evaluationSetId: number;
  }): Promise<ApiResponse<EvaluationSet>> {
    return this.postJSON('hide', params);
  }
}

export default new EvaluationSetAPI('evaluation_set');

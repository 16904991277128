import React from 'react';
import cx from 'classnames';
import { ClickAwayListener, Grow, Paper, Popper as MuiPopper, makeStyles } from '@material-ui/core';

type FuncOrValue<T, K> = ((params: K) => T) | T;

const useStyles = makeStyles(() => ({
  anchor: {
    display: 'flex',
    cursor: 'pointer',
  },
}));

type PopperProps = {
  dropdown: FuncOrValue<React.ReactNode, () => void>;
  classes?: {
    selector?: string;
  };
};

const executeOrValue = <T, K>(funcOrValue: FuncOrValue<T, K>, params: K): T => {
  if (typeof funcOrValue === 'function') {
    return (funcOrValue as (params: K) => T)(params);
  } else {
    return funcOrValue;
  }
};

const Popper: React.FC<PopperProps> = ({ children, dropdown, classes }) => {
  const styles = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = (): void => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>): void => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleKeyDown(event: React.KeyboardEvent): void {
    if (event.key === 'Escape') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <>
      <div
        ref={anchorRef}
        onClick={handleToggle}
        aria-controls={open ? 'popper-content' : undefined}
        aria-haspopup
        className={cx(styles.anchor, classes?.selector)}
      >
        {children}
      </div>
      <MuiPopper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }): React.ReactNode => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper id="popper-content" onKeyDown={handleKeyDown}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>{executeOrValue(dropdown, handleToggle)}</div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </MuiPopper>
    </>
  );
};

export default Popper;

import React from 'react';
import { Route } from 'react-router-dom';

import CLEF_PATH from '../../../constants/path';
import { transformLegacyPath } from '../utils';
import { PageLayout } from '../PageLayout';

import MetadataPage from '../../../pages/metadata/MetadataPage';
import { PageTitle } from '../../../constants/page';
import DataBrowser from '../../../pages/DataBrowser/root';
import MediaDetails from '../../../pages/MediaDetails';
import DatasetSnapshot from '../../../pages/DatasetSnapshot';
import CustomTraining from '../../../pages/CustomTraining';
import { PageName } from '@clef/shared/constants';
import DefectBookEnhanced from '../../../pages/DefectBook';
import DefectBookPrint from '../../../pages/DefectBook/print';
import InstantLearningLabeling from '../../../pages/InstantLearningLabeling';

const backToDataBreadcrumb = {
  label: t('Back to Build'),
  to: CLEF_PATH.data.dataBrowser,
};

const dataRoutes = (currentPath: string) => [
  <Route
    exact
    key={CLEF_PATH.data.dataBrowser}
    path={transformLegacyPath(currentPath, CLEF_PATH.data.dataBrowser)}
    render={() => (
      <PageLayout
        title={PageTitle.DataBrowser}
        pageName={PageName.DataBrowserNew}
        fullScreenContent
        showProjectBanner
        fixedHeightContent
      >
        <DataBrowser />
      </PageLayout>
    )}
  />,
  // media details page
  <Route
    exact
    key={CLEF_PATH.data.mediaDetails}
    path={transformLegacyPath(currentPath, CLEF_PATH.data.mediaDetails + '/:mediaId')}
    render={() => (
      <PageLayout pageName={PageName.MediaDetails} fullScreenContent hideSidebar hideHeader>
        <MediaDetails />
      </PageLayout>
    )}
  />,
  // defect pages
  <Route
    exact
    key={CLEF_PATH.data.defectBookEnhanced}
    path={transformLegacyPath(currentPath, CLEF_PATH.data.defectBookEnhanced)}
    render={() => (
      <PageLayout
        title={PageTitle.NewDefectBook}
        pageName={PageName.DefectBook}
        breadcrumb={backToDataBreadcrumb}
        showProjectBanner
      >
        <DefectBookEnhanced />
      </PageLayout>
    )}
  />,
  <Route
    exact
    key={CLEF_PATH.data.defectBookPrint}
    path={transformLegacyPath(currentPath, CLEF_PATH.data.defectBookPrint)}
    render={() => (
      <PageLayout
        pageName={PageName.DefectBookPrint}
        hideHeader
        hideSidebar
        fullScreenContent
        showProjectBanner
      >
        <DefectBookPrint />
      </PageLayout>
    )}
  />,
  // metadata
  <Route
    exact
    key={CLEF_PATH.data.metadata}
    path={transformLegacyPath(currentPath, CLEF_PATH.data.metadata)}
    render={() => (
      <PageLayout
        title={PageTitle.Metadata}
        pageName={PageName.MetadataPage}
        breadcrumb={backToDataBreadcrumb}
        showProjectBanner
      >
        <MetadataPage />
      </PageLayout>
    )}
  />,
  <Route
    exact
    key={CLEF_PATH.data.instantLearningLabel}
    path={[
      transformLegacyPath(currentPath, CLEF_PATH.data.instantLearningLabel),
      transformLegacyPath(currentPath, CLEF_PATH.data.instantLearningLabel + '/:mediaId'),
    ]}
    render={() => (
      <PageLayout
        pageName={PageName.InstantLearningImageDetails}
        fullScreenContent
        hideSidebar
        hideHeader
      >
        <InstantLearningLabeling />
      </PageLayout>
    )}
  />,
  // dataset snapshot pages
  <Route
    exact
    key={CLEF_PATH.data.datasetSnapshot}
    path={transformLegacyPath(currentPath, CLEF_PATH.data.datasetSnapshot + '/:version')}
    render={() => (
      <PageLayout
        pageName={PageName.DatasetSnapshot}
        breadcrumb={backToDataBreadcrumb}
        showProjectBanner
        showPurpleBackground
      >
        <DatasetSnapshot />
      </PageLayout>
    )}
  />,
  <Route
    exact
    key={CLEF_PATH.data.customTraining}
    path={transformLegacyPath(currentPath, CLEF_PATH.data.customTraining)}
    render={() => (
      <PageLayout
        title={PageTitle.CustomTraining}
        pageName={PageName.CustomTraining}
        showProjectBanner
      >
        <CustomTraining />
      </PageLayout>
    )}
  />,
];

export default dataRoutes;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { useLabelingReviewStyles } from '../labelingReviewStyles';
import {
  Typography,
  Grid,
  Slider,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import SimpleMediaPreview from './SimpleMediaPreview';
import {
  useLabelingReviewState,
  useGoToNextMedia,
  useGoToPrevMedia,
  AgreementScoreSortOrder,
  useUpdateFilters,
} from '../labelingReviewState';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {
  LabelReviewStatus,
  MediaDetailsForLabelingReview,
  MediaId,
  Task,
} from '@clef/shared/types';
import { useKeyPress } from '@clef/client-library';
import { checkRenderAgreementThreshold } from '../utils';
import {
  postReassignMedia,
  refreshTaskLabelingLabelingMediaToReview,
} from '../../../hooks/api/useTaskApi';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { datasetQueryKeys } from '@/serverStore/dataset';

export interface LabelingReviewMediaListPanelProps {
  showAgreement: boolean;
  taskInfo: Task;
}

type CheckedMedia = {
  [key: MediaId]: boolean;
};

const TipIcon: React.FC = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.00008 0.666626C4.40008 0.666626 0.666748 4.39996 0.666748 8.99996C0.666748 13.6 4.40008 17.3333 9.00008 17.3333C13.6001 17.3333 17.3334 13.6 17.3334 8.99996C17.3334 4.39996 13.6001 0.666626 9.00008 0.666626ZM9.83342 13.1666H8.16675V8.16663H9.83342V13.1666ZM9.83342 6.49996H8.16675V4.83329H9.83342V6.49996Z"
      fill="#0C64CD"
    />
  </svg>
);

const LabelingReviewMediaListPanel: React.FC<LabelingReviewMediaListPanelProps> = ({
  showAgreement,
  taskInfo,
}) => {
  const styles = useLabelingReviewStyles();
  const {
    state: {
      agreementThreshold,
      rejectAgreementScoreSortOrder,
      acceptAgreementScoreSortOrder,
      inReivewAgreementScoreSortOrder,
      reviewMediaList,
      isViewResultMode,
      reviewResult,
      currentMediaId,
    },
    dispatch,
  } = useLabelingReviewState();
  const [checkedMedias, setCheckedMedias] = useState<CheckedMedia>({});
  const [openReassign, setOpenReassign] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [reassignning, setReassignning] = useState(false);

  const orderIndicatorWithReject: React.ReactNode = useMemo(
    () =>
      (rejectAgreementScoreSortOrder === 'asc' && <ArrowUpwardIcon fontSize="small" />) || (
        <ArrowDownwardIcon fontSize="small" />
      ),
    [rejectAgreementScoreSortOrder],
  );

  const orderIndicatorWithAccept: React.ReactNode = useMemo(
    () =>
      (acceptAgreementScoreSortOrder === 'asc' && <ArrowUpwardIcon fontSize="small" />) || (
        <ArrowDownwardIcon fontSize="small" />
      ),
    [acceptAgreementScoreSortOrder],
  );

  const orderIndicatorWithInReview: React.ReactNode = useMemo(
    () =>
      (inReivewAgreementScoreSortOrder === 'asc' && <ArrowUpwardIcon fontSize="small" />) || (
        <ArrowDownwardIcon fontSize="small" />
      ),
    [inReivewAgreementScoreSortOrder],
  );

  const changeOrder = useCallback(
    (status?: LabelReviewStatus) => {
      if (!showAgreement) {
        return;
      }
      dispatch(draft => {
        let newOrder: AgreementScoreSortOrder = 'asc';
        switch (status) {
          case LabelReviewStatus.Rejected:
            newOrder = rejectAgreementScoreSortOrder === 'asc' ? 'desc' : 'asc';
            break;
          case LabelReviewStatus.Accepted:
            newOrder = acceptAgreementScoreSortOrder === 'asc' ? 'desc' : 'asc';
            break;
          case LabelReviewStatus.NotReviewed:
            newOrder = inReivewAgreementScoreSortOrder === 'asc' ? 'desc' : 'asc';
            break;
          default:
            newOrder = acceptAgreementScoreSortOrder === 'asc' ? 'desc' : 'asc';
            break;
        }
        if (status === LabelReviewStatus.Rejected) {
          draft.rejectAgreementScoreSortOrder = newOrder;
        } else if (status === LabelReviewStatus.Accepted) {
          draft.acceptAgreementScoreSortOrder = newOrder;
        } else if (status === LabelReviewStatus.NotReviewed) {
          draft.inReivewAgreementScoreSortOrder = newOrder;
        } else {
          draft.rejectAgreementScoreSortOrder = newOrder;
          draft.acceptAgreementScoreSortOrder = newOrder;
          draft.inReivewAgreementScoreSortOrder = newOrder;
        }
      });
    },
    [
      acceptAgreementScoreSortOrder,
      dispatch,
      inReivewAgreementScoreSortOrder,
      rejectAgreementScoreSortOrder,
      showAgreement,
    ],
  );

  const changeAgreementThreshold = useCallback(
    (newValueRaw: number) => {
      if (!showAgreement) {
        return;
      }
      const newValue = Number(newValueRaw.toFixed(2));
      dispatch(draft => {
        // change all the review results between old and new agreementThreshold to pending/reject
        reviewMediaList.forEach(mediaDetail => {
          const { overallAgreementScore = 0, id: mediaId } = mediaDetail;
          // if media has selected label, respect that over threshold initiative
          if (draft.reviewResult[mediaId].selectedLabel) {
            return;
          }
          if (
            // new value is bigger
            newValue > overallAgreementScore &&
            // agreementScore is between old and new value
            overallAgreementScore < newValue &&
            overallAgreementScore >= agreementThreshold
          ) {
            draft.reviewResult[mediaId].reviewStatus = LabelReviewStatus.Rejected;
          }

          if (
            // new value is smaller
            newValue < agreementThreshold &&
            // agreementScore is between old and new value
            overallAgreementScore >= newValue &&
            overallAgreementScore < agreementThreshold
          ) {
            draft.reviewResult[mediaId].reviewStatus = LabelReviewStatus.NotReviewed;
          }
        });
        draft.agreementThreshold = newValue;
      });
    },
    [agreementThreshold, dispatch, reviewMediaList, showAgreement],
  );

  const getMediaList = useCallback(
    (
      status: LabelReviewStatus | ((status: LabelReviewStatus) => boolean),
      sort: AgreementScoreSortOrder,
    ) => {
      const list = reviewMediaList.filter(media => {
        const reviewStatus = reviewResult[media.id]?.reviewStatus ?? LabelReviewStatus.NotReviewed;
        return typeof status === 'function' ? status(reviewStatus) : reviewStatus === status;
      });
      if (showAgreement) {
        return list.slice().sort((mediaA, mediaB) => {
          const mediaAScore = mediaA.overallAgreementScore ?? 0;
          const mediaBScore = mediaB.overallAgreementScore ?? 0;
          return sort === 'asc' ? mediaAScore - mediaBScore : mediaBScore - mediaAScore;
        });
      }
      return list;
    },
    [reviewMediaList, reviewResult, showAgreement],
  );

  const rejectMediaList = useMemo(() => {
    return getMediaList(LabelReviewStatus.Rejected, rejectAgreementScoreSortOrder);
  }, [getMediaList, rejectAgreementScoreSortOrder]);

  const acceptedMediaList = useMemo(() => {
    return getMediaList(LabelReviewStatus.Accepted, acceptAgreementScoreSortOrder);
  }, [acceptAgreementScoreSortOrder, getMediaList]);

  const inReivewMediaList = useMemo(() => {
    return getMediaList((status: LabelReviewStatus) => {
      return status !== LabelReviewStatus.Rejected && status !== LabelReviewStatus.Accepted;
    }, inReivewAgreementScoreSortOrder);
  }, [inReivewAgreementScoreSortOrder, getMediaList]);

  const goToNextMedia = useGoToNextMedia();
  const goToPrevMedia = useGoToPrevMedia();
  useKeyPress('up', () => {
    goToPrevMedia([...rejectMediaList, ...acceptedMediaList, ...inReivewMediaList]);
  });
  useKeyPress('down', () => {
    goToNextMedia([...rejectMediaList, ...acceptedMediaList, ...inReivewMediaList]);
  });
  useKeyPress('s', () => changeOrder());
  useKeyPress('left', () => changeAgreementThreshold(agreementThreshold - 0.01));
  useKeyPress('right', () => changeAgreementThreshold(agreementThreshold + 0.01));

  const updateFilters = useUpdateFilters();
  useEffect(() => {
    if (currentMediaId) {
      updateFilters(currentMediaId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMediaId]);

  const onSelectAll = useCallback(
    (checked: boolean) => {
      setSelectAll(checked);
      setCheckedMedias(Object.fromEntries(rejectMediaList.map(media => [media.id, checked])));
    },
    [rejectMediaList],
  );

  const renderMediaListTop = useCallback(
    (status: LabelReviewStatus) => {
      const isReject = status === LabelReviewStatus.Rejected;
      const isAccept = status === LabelReviewStatus.Accepted;
      const isNotReview = status === LabelReviewStatus.NotReviewed;
      if (isReject && !rejectMediaList.length) return null;
      if (isAccept && !acceptedMediaList.length) return null;
      if (isNotReview && !inReivewMediaList.length) return null;

      const statusText: { [key: string]: string } = {
        [LabelReviewStatus.Rejected]: t('Rejected media ({{num}})', {
          num: rejectMediaList.length,
        }),
        [LabelReviewStatus.Accepted]: t('Accepted media ({{num}})', {
          num: acceptedMediaList.length,
        }),
        [LabelReviewStatus.NotReviewed]: t('Review media ({{num}})', {
          num: inReivewMediaList.length,
        }),
      };

      return (
        <Box>
          {isReject && rejectMediaList.length > 0 && isViewResultMode && (
            <Box className={styles.rejectTip} display="flex">
              <TipIcon />
              <Typography variant="body2" className={styles.rejectTipText}>
                {t('Reassign is available with rejected media.')}
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">{statusText[status as string]}</Typography>
            {isReject && isViewResultMode ? (
              <FormControlLabel
                style={{ marginRight: 0, marginLeft: 0 }}
                control={
                  <Checkbox
                    checked={selectAll}
                    color="primary"
                    onChange={e => onSelectAll(!!e.target.checked)}
                  />
                }
                label={t('Select all')}
                data-testid="select-all-reject-media"
              />
            ) : showAgreement ? (
              <Tooltip
                arrow
                placement="top"
                title={
                  <>
                    {t('sort media by agreement score')}
                    <span className={styles.codeBlock}>{t('s')}</span>
                  </>
                }
              >
                <Box
                  display="flex"
                  onClick={() => changeOrder(status)}
                  className={styles.agreementLabel}
                  data-testid={`agreement-sort-label-${status}`}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body2" className={styles.agreementLabelText}>
                    {t('Agreement')}
                  </Typography>
                  {isReject
                    ? orderIndicatorWithReject
                    : isAccept
                    ? orderIndicatorWithAccept
                    : orderIndicatorWithInReview}
                </Box>
              </Tooltip>
            ) : null}
          </Box>
        </Box>
      );
    },
    [
      acceptedMediaList.length,
      changeOrder,
      inReivewMediaList.length,
      isViewResultMode,
      onSelectAll,
      orderIndicatorWithAccept,
      orderIndicatorWithInReview,
      orderIndicatorWithReject,
      rejectMediaList.length,
      selectAll,
      showAgreement,
      styles.agreementLabel,
      styles.agreementLabelText,
      styles.codeBlock,
      styles.rejectTip,
      styles.rejectTipText,
    ],
  );

  const checkedIds = useMemo(() => {
    return Object.entries(checkedMedias)
      .filter(([, checked]) => checked)
      .map(([mediaId]) => Number(mediaId));
  }, [checkedMedias]);

  useEffect(() => {
    setSelectAll(checkedIds.length === rejectMediaList.length);
  }, [checkedIds.length, rejectMediaList.length]);

  const queryClient = useQueryClient();

  const onReassign = useCallback(async () => {
    if (!selectedProject?.datasetId) return;
    setReassignning(true);
    try {
      await postReassignMedia(selectedProject.datasetId, taskInfo!.id, checkedIds);
      selectedProject.datasetId &&
        queryClient.invalidateQueries(datasetQueryKeys.mediaDetails(selectedProject.datasetId));
      selectedProject.id &&
        queryClient.invalidateQueries(datasetQueryKeys.allWithFilters(selectedProject.id));

      enqueueSnackbar(t('Reassign success'), { variant: 'success' });

      refreshTaskLabelingLabelingMediaToReview({ keys: 'refresh-all' });
      dispatch(draft => {
        draft.currentMediaId = 0;
        draft.reviewResult = {};
        draft.reviewMediaList = [];
      });
    } catch (err) {
      setReassignning(false);
      setOpenReassign(false);
      enqueueSnackbar(((err as any)?.body || err)?.message, { variant: 'error' });
    }
  }, [checkedIds, dispatch, enqueueSnackbar, selectedProject?.datasetId, taskInfo]);

  const renderReassignDialog = useMemo(() => {
    return (
      <Dialog open={openReassign} data-testid="reassign-media-confirm-dialog">
        {reassignning && (
          <div className={styles.progress}>
            <LinearProgress />
          </div>
        )}
        <DialogTitle>{t('Media reassign')}</DialogTitle>
        <DialogContent>
          {t('Are you sure you want to reassign rejected media? This action can not be undone.')}
        </DialogContent>
        <DialogActions>
          <Button
            id="cancel-reassign-media-btn-with-submit"
            onClick={() => {
              setOpenReassign(false);
            }}
            color="primary"
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={onReassign}
            color="primary"
            variant="contained"
            id="reassign-media-btn-with-submit"
          >
            {t('Reassign')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }, [onReassign, openReassign, reassignning, styles.progress]);

  const renderMediaList = useCallback(
    (mediaList: MediaDetailsForLabelingReview[], status: LabelReviewStatus) => {
      const isReject = status === LabelReviewStatus.Rejected;
      const isAccept = status === LabelReviewStatus.Accepted;
      return (
        <Box data-testid={`media-list-with-${status}`}>
          {mediaList.map((mediaDetail, index) => {
            const { renderDividerAfter } = isViewResultMode
              ? { renderDividerAfter: false }
              : checkRenderAgreementThreshold(
                  mediaList,
                  mediaDetail,
                  index,
                  isReject
                    ? rejectAgreementScoreSortOrder
                    : isAccept
                    ? acceptAgreementScoreSortOrder
                    : inReivewAgreementScoreSortOrder,
                  agreementThreshold,
                );

            return (
              <React.Fragment key={mediaDetail.id}>
                <SimpleMediaPreview
                  mediaDetail={mediaDetail}
                  showAgreement={showAgreement}
                  checked={!!checkedMedias[mediaDetail.id]}
                  onChange={checked => {
                    setCheckedMedias(prev => {
                      return {
                        ...prev,
                        [mediaDetail.id]: !!checked,
                      };
                    });
                  }}
                />
                {showAgreement && renderDividerAfter && (
                  <Typography variant="caption" component="div" className={styles.thresholdDivider}>
                    {t('Agreement Threshold')}
                  </Typography>
                )}
              </React.Fragment>
            );
          })}
          {status === LabelReviewStatus.Rejected && checkedIds.length > 0 && isViewResultMode && (
            <Button
              className={styles.reassignBtn}
              color="primary"
              variant="outlined"
              onClick={() => setOpenReassign(true)}
              id="reassign-media-btn"
            >
              {t('Reassign')}
            </Button>
          )}
          {mediaList.length > 0 && <Divider style={{ margin: '18px 0' }} />}
        </Box>
      );
    },
    [
      acceptAgreementScoreSortOrder,
      agreementThreshold,
      checkedIds.length,
      checkedMedias,
      inReivewAgreementScoreSortOrder,
      isViewResultMode,
      rejectAgreementScoreSortOrder,
      showAgreement,
      styles.reassignBtn,
      styles.thresholdDivider,
    ],
  );

  return (
    <section className={cx(styles.panelContainer, styles.mediaListPanelContainer)}>
      {showAgreement && !isViewResultMode && (
        <div
          className={cx(
            styles.panelSection,
            styles.panelSectionImportant,
            styles.panelSectionSticky,
          )}
        >
          <Typography variant="h4" className={styles.panelSectionH4}>
            {t('Agreement Threshold')}
          </Typography>
          <Grid container direction="row" wrap="nowrap" alignItems="center">
            <Slider
              value={agreementThreshold}
              step={0.01}
              min={0}
              max={1}
              onChange={(_, newValue) => {
                changeAgreementThreshold(newValue as number);
              }}
            />
            <Typography variant="body1" className={styles.agreementThresholdText}>
              {agreementThreshold.toFixed(2)}
            </Typography>
          </Grid>
        </div>
      )}
      <div className={cx(styles.panelSection, styles.notBorder)}>
        <Typography variant="subtitle1">{t('Media List')}</Typography>
        {renderMediaListTop(LabelReviewStatus.Rejected)}
        {renderMediaList(rejectMediaList, LabelReviewStatus.Rejected)}
        {renderMediaListTop(LabelReviewStatus.Accepted)}
        {renderMediaList(acceptedMediaList, LabelReviewStatus.Accepted)}
        {renderMediaListTop(LabelReviewStatus.NotReviewed)}
        {renderMediaList(inReivewMediaList, LabelReviewStatus.NotReviewed)}
      </div>
      {renderReassignDialog}
    </section>
  );
};

export default LabelingReviewMediaListPanel;

import React from 'react';
import cx from 'classnames';
import { Typography, makeStyles } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CheckCircle from '@material-ui/icons/CheckCircle';
import SimpleBadge from '../SimpleBadge';

const selectorHeight = 32;

const useStyles = makeStyles(theme => ({
  filterPill: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #B0B0B0',
    borderRadius: selectorHeight / 2,
    height: selectorHeight,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(3),
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.common.black,
    },
  },
  filterPillActive: {
    borderColor: theme.palette.common.black,
  },
  filterPillText: {
    paddingRight: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
}));

export interface FilterPillProps {
  filterName: string;
  badgeContent: number | 'check';
  open?: boolean;
  prefixTestId?: string;
}

const FilterPill: React.FC<FilterPillProps> = ({
  filterName,
  badgeContent,
  open,
  prefixTestId,
}) => {
  const styles = useStyles();

  return (
    <div
      className={cx(styles.filterPill, open && styles.filterPillActive)}
      data-testid={`${prefixTestId || filterName}-dropdown-filter`
        .toLowerCase()
        .replace(/[\s/]+/g, '-')}
    >
      <Typography variant="body1" className={styles.filterPillText}>
        {filterName}
      </Typography>
      {badgeContent === 'check' && <CheckCircle fontSize="small" color="primary" />}
      {badgeContent !== 'check' && badgeContent > 0 && (
        <SimpleBadge color="primary" content={badgeContent} />
      )}
      {badgeContent !== 'check' && badgeContent === 0 && <ExpandMore fontSize="small" />}
    </div>
  );
};

export default FilterPill;

/* eslint-disable no-redeclare */
import UserAPI from '../../api/user_api';
import fetchHookFactory from './fetchHookFactory';
import { User, ProjectId, UserId, ProjectRole, UserRole, UserStatus } from '@clef/shared/types';
import { useTypedSelector } from '../useTypedSelector';
import { isArray } from 'lodash';

export function useUserInfo(userId: string | undefined): User | undefined;
export function useUserInfo(userIds: string[]): User[];
export function useUserInfo(userIds: string | string[] | undefined): User | User[] | undefined {
  const orgUsers = useTypedSelector(state => state.user.users);
  if (!userIds) {
    return undefined;
  }
  if (isArray(userIds)) {
    return orgUsers.filter(user => userIds.includes(user.id))!;
  } else {
    return orgUsers.find(user => user.id === userIds);
  }
}

export const changeUserActivation = async (
  userId: UserId,
  userStatus: UserStatus.Active | UserStatus.Inactive,
) => {
  if (userStatus === UserStatus.Active) {
    await UserAPI.activateUser(userId);
  } else {
    await UserAPI.deactivateUser(userId);
  }
};

export const transferOrganizationOwnership = async (userId: UserId) => {
  await UserAPI.transferOrganizationOwnership(userId);
};

export const updateUserRole = async (userId: UserId, userRole: UserRole) => {
  await UserAPI.updateUserRole(userId, userRole);
};

export const removePendingUser = async (userEmail: string) => {
  await UserAPI.removePendingUser(userEmail);
};

export const inviteUserApi = async (
  email: string,
  userRole: UserRole,
  projectId?: ProjectId,
  projectRole?: ProjectRole,
) => {
  const res = await UserAPI.inviteUser(email, userRole, projectId, projectRole);
  return res;
};

export const [useApiKey, refreshApiKey] = fetchHookFactory(() => {
  return UserAPI.getApiKey() as Promise<{ apiKeyHash: string; apiSecretHash: string }>;
}, 'stale-while-revalidate');

import React, { useCallback, useRef, useState } from 'react';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import { Button } from '@clef/client-library';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProjectAPI from '../../api/project_api';
import LockOutlined from '@material-ui/icons/LockOutlined';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import { ProjectId } from '@clef/shared/types';
import { AnyUser } from '../ProjectSettingWithUserRoleTable/types';
import AddUsersToProjectDialog from '../AddUsersToProjectDialog/AddUsersToProjectDialog';
import { removeUserFromProject, transferOwnership } from '../../hooks/api/useProjectApi';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { projectSettingWithUserRoleStyles } from './projectSettingWithUserRoleStyles';
import classNames from 'classnames';
import ProjectSettingWithUserRoleTable from '../ProjectSettingWithUserRoleTable/ProjectSettingWithUserRoleTable';
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Popover,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { UserAvatar } from '../UserAvatar/UserAvatarNew';
import { projectSettingWithUserRoleTableStyles } from '../ProjectSettingWithUserRoleTable/projectSettingWithUserRoleTableStyles';
import { isProjectOwner } from '@clef/shared/utils';
import {
  useGetProjectsQuery,
  useGetSelectedProjectQuery,
  useSetSelectedProjectId,
} from '@/serverStore/projects';

type ProjectSettingWithUserRoleProps = {};

enum AccessMode {
  Private,
  Public,
}

const ProjectSettingWithUserRole: React.FC<ProjectSettingWithUserRoleProps> = () => {
  const styles = projectSettingWithUserRoleStyles();
  const tableStyles = projectSettingWithUserRoleTableStyles();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const [openAddMembersDialog, setOpenAddMembersDialog] = useState<boolean>(false);
  const activeUsers = selectedProject?.users ?? [];
  const [editAccess, setEditAccess] = useState(false);

  const accessBoxRef = useRef<HTMLDivElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const user = useTypedSelector(state => state.login.user!);
  const { data: projects } = useGetProjectsQuery();
  const setSelectedProjectId = useSetSelectedProjectId();

  const [accessModeVisible, setAccessModeVisible] = useState(false);
  const [accessMode, setAccessMode] = useState<AccessMode>(AccessMode.Public);
  const [isEditAccess, setIsEditAccess] = useState(false);
  const [currentRemovingUser, setCurrentRemovingUser] = useState<AnyUser | null>(null);
  const [userRemovalDialogVisible, setUserRemovalDialogVisible] = useState(false);
  const [isRemovingUser, setIsRemovingUser] = useState(false);
  const [currentTransferringUser, setCurrentTransferringUser] = useState<AnyUser | null>(null);
  const [transferOwnershipDialogVisible, setTransferOwnershipDialogVisible] = useState(false);
  const [isTransferringOwnership, setIsTransferringOwnership] = useState(false);

  const onAccessChange = useCallback((newAccessMode: AccessMode) => {
    setEditAccess(false);
    setAccessMode(newAccessMode);
    setAccessModeVisible(true);
  }, []);

  const onUpdateAccessStart = useCallback(() => {
    setEditAccess(true);
  }, []);

  const onUpdateAccessCancel = useCallback(() => {
    setAccessModeVisible(false);
  }, []);

  const onUpdateAccessConfirm = useCallback(async () => {
    if (isEditAccess || !selectedProject?.id) return;
    if (projects) {
      setIsEditAccess(true);
      try {
        await ProjectAPI.changeVisibility(selectedProject.id, accessMode === AccessMode.Private);
        await setSelectedProjectId(
          selectedProject?.id,
          true, // shouldInvalidProjectQuery
        );
        enqueueSnackbar(t('Successfully updated access permissions'), {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar((e as Error).message, {
          variant: 'error',
        });
      }
      setIsEditAccess(false);
    }
    setAccessModeVisible(false);
  }, [accessMode, dispatch, isEditAccess, projects, selectedProject?.id, enqueueSnackbar]);

  const onUserRemovalStart = useCallback((user: AnyUser) => {
    setCurrentRemovingUser(user);
    setUserRemovalDialogVisible(true);
  }, []);

  const onUserRemovalCancel = useCallback(() => {
    setCurrentRemovingUser(null);
    setUserRemovalDialogVisible(false);
  }, []);

  const onUserRemovalConfirm = useCallback(
    async (user: AnyUser) => {
      if (!selectedProject?.id) {
        return;
      }
      setIsRemovingUser(true);

      await removeUserFromProject(user.userProject?.projectId as ProjectId, user.id);

      setSelectedProjectId(
        selectedProject?.id,
        true, // shouldInvalidProjectQuery
      );

      setCurrentRemovingUser(null);
      setUserRemovalDialogVisible(false);
      setIsRemovingUser(false);
    },
    [dispatch, selectedProject?.id],
  );

  const onTransferOwnershipStart = useCallback((user: AnyUser) => {
    setCurrentTransferringUser(user);
    setTransferOwnershipDialogVisible(true);
  }, []);

  const onTransferOwnershipCancel = useCallback(() => {
    setCurrentTransferringUser(null);
    setTransferOwnershipDialogVisible(false);
  }, []);

  const onTransferOwnershipConfirm = useCallback(
    async (user: AnyUser) => {
      if (!selectedProject?.id) {
        return;
      }
      setIsTransferringOwnership(true);

      await transferOwnership(user.userProject?.projectId as ProjectId, user.id);

      setSelectedProjectId(
        selectedProject?.id,
        true, // shouldInvalidProjectQuery
      );

      setCurrentTransferringUser(null);
      setTransferOwnershipDialogVisible(false);
      setIsTransferringOwnership(false);
    },
    [dispatch, selectedProject?.id],
  );

  if (!selectedProject) return <CircularProgress />;

  const currentUserWithRole = activeUsers?.find(
    userRole => userRole.userProject.userId === user.id,
  );

  const isOwner = isProjectOwner(currentUserWithRole?.userProject.role);

  const privateProjectAccess = (
    <>
      <LockOutlined className={styles.accessIcon} />
      <Typography display="inline" className={styles.accessLabel}>
        {t('Only invited members can access the project')}
      </Typography>
    </>
  );

  const publicProjectAccess = (
    <>
      <LockOpenOutlined className={styles.accessIcon} />
      <Typography className={styles.accessLabel}>
        {t('Anyone in the organization can access the project')}
      </Typography>
    </>
  );

  const accessText = (
    <>
      <div ref={accessBoxRef} className={styles.accessTitle}>
        <Popover
          open={editAccess}
          anchorEl={accessBoxRef.current}
          onClose={() => {
            setEditAccess(false);
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          className={styles.accessPopover}
          disableEnforceFocus
        >
          <Card className={styles.selectAccess}>
            <Box
              className={classNames(styles.selectItem, {
                active: selectedProject.inviteOnly,
              })}
              display="flex"
              onClick={() => onAccessChange(AccessMode.Private)}
              data-testid="switch-access-with-private"
            >
              {selectedProject.inviteOnly ? (
                <CheckOutlined className={styles.checkAccess} />
              ) : (
                <Box className={styles.notCheck} />
              )}
              {privateProjectAccess}
            </Box>
            <Box
              className={classNames(styles.selectItem, {
                active: !selectedProject.inviteOnly,
              })}
              display="flex"
              onClick={() => onAccessChange(AccessMode.Public)}
              data-testid="switch-access-with-public"
            >
              {!selectedProject.inviteOnly ? (
                <CheckOutlined className={styles.checkAccess} />
              ) : (
                <Box className={styles.notCheck} />
              )}
              {publicProjectAccess}
            </Box>
          </Card>
        </Popover>
      </div>
      <Box display="flex" flexDirection="row" alignItems="center">
        {selectedProject.inviteOnly ? privateProjectAccess : publicProjectAccess}
        {isOwner && (
          <Button
            color="primary"
            className={styles.editBtn}
            onClick={onUpdateAccessStart}
            id="project-setting-switch-access-edit"
          >
            {t('Edit')}
          </Button>
        )}
      </Box>
    </>
  );

  const projectOwner = activeUsers?.find(user => isProjectOwner(user.userProject.role));
  const projectOwnerName = [projectOwner?.name, projectOwner?.lastName].filter(Boolean).join(' ');

  return (
    <>
      <AddUsersToProjectDialog
        open={openAddMembersDialog}
        onCancel={() => {
          setOpenAddMembersDialog(false);
        }}
        projectId={selectedProject.id}
      />
      <Box className={styles.accessWrapper}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          className={styles.accessBox}
        >
          {accessText}
        </Box>
        <Box>
          {isOwner && selectedProject.inviteOnly && (
            <Button
              onClick={() => {
                setOpenAddMembersDialog(true);
              }}
              variant="contained"
              color="primary"
              className={cx(styles.InviteBtn, 'cy-ps-add-members-btn')}
              id="project-setting-invite-btn"
            >
              {t('Invite')}
            </Button>
          )}
        </Box>
      </Box>

      {selectedProject.inviteOnly ? (
        <ProjectSettingWithUserRoleTable
          usersPartialUserProject={activeUsers || []}
          pageSize={10}
          isInvite={false}
          showMoreActions={true}
          onUserRemovalStart={onUserRemovalStart}
          onTransferOwnershipStart={onTransferOwnershipStart}
        />
      ) : (
        <Box display="flex" alignItems="center">
          <Typography variant="h2" component="span" className={styles.projectOwnerTitle}>
            {t('Project owner:')}
          </Typography>
          <Box display="flex" alignItems="center" pl={3}>
            <UserAvatar name={projectOwner?.name ?? ''} />
            <Box className={tableStyles.nameBox}>
              <Typography aria-label="owner-name" className={tableStyles.name}>
                {projectOwnerName}
              </Typography>
              <Typography aria-label="owner-email" className={tableStyles.email}>
                {projectOwner?.email}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      <Dialog open={accessModeVisible}>
        {isEditAccess && <LinearProgress />}
        <DialogTitle>{t('Switch project mode')}</DialogTitle>
        <DialogContent>
          <Box>
            {accessMode === AccessMode.Public
              ? t('Are you sure you want to switch this project from private to public?')
              : t('Are you sure you want to switch this project from public to private?')}
          </Box>
          <Box>
            {accessMode === AccessMode.Public
              ? t(
                  'After it’s done, all memebers in your organzation will be able to have full access of the project.',
                )
              : t(
                  'After it’s done, this project will only be available to you and project members. You can invite more members later.',
                )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button id="switch-access-cancel" color="primary" onClick={onUpdateAccessCancel}>
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onUpdateAccessConfirm}
            id="switch-access-done"
          >
            {t(
              accessMode === AccessMode.Private
                ? t('Yes, Switch to Private')
                : t('Yes, Switch to Public'),
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={userRemovalDialogVisible}>
        <DialogTitle>{t('Remove user')}</DialogTitle>
        <DialogContent>
          <Box>
            {t('Are you sure you want to remove user {{userEmail}} from project {{projectName}}?', {
              userEmail: currentRemovingUser?.email,
              projectName: selectedProject.name,
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button id="switch-access-cancel" color="primary" onClick={onUserRemovalCancel}>
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isRemovingUser}
            onClick={() => currentRemovingUser && onUserRemovalConfirm(currentRemovingUser)}
            id="switch-access-done"
          >
            {isRemovingUser ? t('Removing...') : t('Yes, Remove')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={transferOwnershipDialogVisible}>
        <DialogTitle>{t('Transfer ownership')}</DialogTitle>
        <DialogContent>
          <Box>
            {t('Are you sure you want to transfer your ownership to {{userEmail}}?', {
              userEmail: currentTransferringUser?.email,
            })}{' '}
            {t(
              "After it's done, you will become an editor of the project. You can't invite people or edit other's project permissions anymore.",
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button id="switch-access-cancel" color="primary" onClick={onTransferOwnershipCancel}>
            {t('Cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isTransferringOwnership}
            onClick={() =>
              currentTransferringUser && onTransferOwnershipConfirm(currentTransferringUser)
            }
            id="switch-access-done"
          >
            {isTransferringOwnership ? t('Transferring...') : t('Yes, Transfer Ownership')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ProjectSettingWithUserRole;

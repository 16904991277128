import { withModelAnalysisTableCellProps } from './common';
import { TableCell } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/styles';

export const EvaluationSetTableCell = withModelAnalysisTableCellProps(
  withStyles(theme =>
    createStyles({
      head: {
        backgroundColor: theme.palette.greyModern[50],
        borderStyle: 'solid',
        borderWidth: 0.5,
        padding: theme.spacing(4),
        borderColor: theme.palette.greyModern[300],
      },
      body: {
        backgroundColor: theme.palette.common.white,
        fontSize: 14,
        borderStyle: 'solid',
        borderWidth: 0.5,
        padding: theme.spacing(4),
        borderColor: theme.palette.greyModern[300],
      },
    }),
  )(TableCell),
);

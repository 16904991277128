import React from 'react';
import { ApiResponseLoader } from '@clef/client-library';
import { Project, ProjectId } from '@clef/shared/types';
import ProjectCardWithSelection from './ProjectCardWithSelection';
import { ApiErrorType } from '@/api/base_api';

import { FixedSizeGrid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

type ProjectImageGridProps = {
  selectedProjectId: ProjectId | null;
  onSelectedProjectIdChange: (projectId: ProjectId | null) => void;
  projectListData: Project[] | undefined;
  projectListDataError: ApiErrorType | null;
  projectListDataLoading: boolean;
};

const ProjectImageGrid = (props: ProjectImageGridProps) => {
  const {
    projectListData,
    projectListDataError,
    projectListDataLoading,
    selectedProjectId,
    onSelectedProjectIdChange,
  } = props;

  const getRenderCellWithColumnCount = (columnCount: number) => {
    return ({
      columnIndex,
      rowIndex,
      data,
      style,
    }: {
      columnIndex: number;
      rowIndex: number;
      style: any;
      data: any;
    }) => {
      if (rowIndex * columnCount + columnIndex >= data.length) {
        return <div style={style}></div>;
      }
      const project = data[rowIndex * columnCount + columnIndex];
      return (
        <div style={style}>
          <ProjectCardWithSelection
            key={project.id}
            selected={selectedProjectId === project.id}
            onSelected={selected => {
              if (selected) {
                onSelectedProjectIdChange(project.id);
              } else {
                onSelectedProjectIdChange(null);
              }
            }}
            project={project}
          />
        </div>
      );
    };
  };

  const getColumnCount = (width: number) => {
    if (width > 1100) {
      return 3;
    } else if (width > 700) {
      return 2;
    }
    return 1;
  };

  return (
    <ApiResponseLoader
      response={projectListData}
      loading={projectListDataLoading}
      error={projectListDataError ?? undefined}
      defaultHeight="30vh"
    >
      {projects => {
        return (
          <div style={{ display: 'flex', flexGrow: 1 }}>
            <AutoSizer>
              {({ height, width }: { height: number; width: number }) => {
                const columnCount = getColumnCount(width);
                const columnWidth = width / columnCount;
                const rowHeight = columnWidth / 1.5;

                return (
                  <FixedSizeGrid
                    style={{ overflowX: 'hidden', overflowY: 'auto', scrollbarWidth: 'thin' }}
                    columnCount={columnCount}
                    columnWidth={columnWidth}
                    rowHeight={rowHeight}
                    width={width}
                    height={height}
                    rowCount={Math.ceil(projects.length / columnCount)}
                    itemData={[...projects]}
                  >
                    {getRenderCellWithColumnCount(columnCount)}
                  </FixedSizeGrid>
                );
              }}
            </AutoSizer>
          </div>
        );
      }}
    </ApiResponseLoader>
  );
};

export default ProjectImageGrid;

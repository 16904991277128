import { MetadataState } from './types';
import actionReducer from './reducer_utils';
import { MetadataField, ProjectId, SearchableColumn, FilterType } from '@clef/shared/types';
import MetadataAPI from '../api/metadata_api';

const UPDATE_FIELD_LIST = 'update_field_list';
const UPDATE_FIELD = 'update_field';
const ADD_NEW_FIELD = 'add_new_field';
const SET_SEARCHABLE_COLUMN_LIST = 'set_searchable_column_list';

const actions: Record<string, (state: MetadataState, ...args: any[]) => any> = {};
const initialState: MetadataState = {
  fieldList: [],
  fetchMetadataByProjectId: () => {},
  searchableColumnList: [],
};

actions[UPDATE_FIELD_LIST] = (state, { payload }) => {
  return {
    ...state,
    fieldList: payload,
  };
};

function updateFieldList(fieldList: MetadataField[]) {
  return {
    type: UPDATE_FIELD_LIST,
    payload: fieldList,
  };
}

actions[UPDATE_FIELD] = (state, { payload }) => {
  const found = state.fieldList.findIndex(field => field.id === payload.id);
  const newFieldList = Array.from(state.fieldList);
  newFieldList.splice(found, 1, payload);
  return {
    ...state,
    fieldList: newFieldList,
  };
};

export function updateField(field: MetadataField) {
  return {
    type: UPDATE_FIELD,
    payload: field,
  };
}

actions[ADD_NEW_FIELD] = (state, { payload }) => {
  if (payload) {
    const newFieldList = Array.from(state.fieldList);
    newFieldList.push(payload);
    return {
      ...state,
      fieldList: newFieldList,
    };
  }
  return state;
};

export function addField(field: MetadataField) {
  return {
    type: ADD_NEW_FIELD,
    payload: field,
  };
}

actions[SET_SEARCHABLE_COLUMN_LIST] = (state, { payload }) => {
  return {
    ...state,
    searchableColumnList: payload,
  };
};

function setSearchableColumnList(searchableColumnList: SearchableColumn[]) {
  return {
    type: SET_SEARCHABLE_COLUMN_LIST,
    payload: searchableColumnList,
  };
}

export const fetchMetadataByProjectId: MetadataState['fetchMetadataByProjectId'] = (
  projectId: ProjectId,
) => {
  return async (dispatch: any) => {
    const fieldList = await MetadataAPI.getMetadataByProjectId(projectId);
    dispatch(updateFieldList(Object.values(fieldList)));
    const searchableColumnList: SearchableColumn[] = [];
    const searchableColumnInfo = await MetadataAPI.getSearchableColumnInfo(projectId);
    for (const [objectTypeName, columns] of Object.entries(searchableColumnInfo)) {
      for (const [columnName, column] of Object.entries(columns)) {
        const { type, predefinedChoicesType, allowMultiple, name, isTimestamp } = column;
        const value: SearchableColumn = {
          type: type,
          predefinedChoices: predefinedChoicesType,
          allowMultiple: allowMultiple,
          name: name,
          valueFlexible: predefinedChoicesType && predefinedChoicesType.length ? false : true,
          objectTypeName: objectTypeName,
          columnName: columnName,
          filterType: FilterType.ColumnFilter,
          isTimestamp: isTimestamp,
        };
        searchableColumnList.push(value);
      }
    }
    dispatch(setSearchableColumnList(searchableColumnList));
  };
};

export default actionReducer(actions, initialState);

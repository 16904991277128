import React from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import phoneCamera from '@/images/deploy/phone-camera.png';
import { Button } from '@clef/client-library';
import { useTypographyStyles } from '@clef/client-library/src/themes/typography';
import { useChangeEndpointState } from './utils';

const useStyles = makeStyles(() => ({
  icon: {
    width: 120,
    height: 120,
  },
}));

export type MobileUploadDialogContentDisabledProps = {};

const MobileUploadDialogContentDisabled: React.FC<MobileUploadDialogContentDisabledProps> = () => {
  const styles = useStyles();
  const typographyStyles = useTypographyStyles();

  const { changeEndpointState, changing } = useChangeEndpointState();

  return (
    <>
      <img src={phoneCamera} alt="phone-camera" className={styles.icon} />
      <Box className={typographyStyles.h3_semibold} marginTop={3}>
        {t('Mobile Upload')}
      </Box>
      <Box className={typographyStyles.body_regular} marginY={7} textAlign="center">
        {t('Generate a QR code and upload images from your phone!')}
      </Box>
      <Button
        id="generate-image-upload-qr-code"
        color="primary"
        variant="contained"
        disabled={changing}
        startIcon={changing ? <CircularProgress size={20} /> : undefined}
        onClick={() => changeEndpointState(true)}
      >
        {t('Get QR Code')}
      </Button>
    </>
  );
};

export default MobileUploadDialogContentDisabled;

import React, { useMemo, useCallback } from 'react';
import { Media, EdgeMedia, AnnotationType, MediaLevelLabel } from '@clef/shared/types';
import { AnyFunction } from '@clef/shared/types/utils';
import { MediaViewer } from '@clef/client-library';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
/* -- hooks -- */
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useDefectSelectorWithArchived } from '../../../store/defectState/actions';
/** -- sub components */
import MediaInfoPanel from './MediaInfoPanel';
import MediaHumanJudgementPanel from './MediaHumanJudgementPanel';
import MediaActionsSection from './MediaActionsSection';
import MediaReviewPanel from './MediaReviewPanel';
import MediaDetailsSection from './MediaDetailsSection';
import { getEdgeBoxAnnotations } from '../../../utils';
import EnhancedImageViewer from '../../../components/ImageEnhancer/EnhancedImageViewer';
import MediaClassifiedClass from '../MediaContainer/MediaClassifiedClass';
import { throttle } from 'lodash';
import CLIAAPI from '../../../api/clia_api';
import ConfidenceChipDisplay from '../../continuous_learning/DeviceMonitorDashboard/ConfidenceChipDisplay';
import { useQueryClient } from '@tanstack/react-query';
import { endpointQueryKeys, useEdgeMediaDetailsQuery } from '@/serverStore/endpoints';

export interface MediaDetailsContentProps {
  media: Media | EdgeMedia;
  showLabels?: boolean;
  showDefectName?: boolean;
  showConfidenceScore?: boolean;
  autoNext?: boolean;
  toggleAutoNext?: AnyFunction;
  onNext?: AnyFunction;
  deletedMedias: number[];
  onDeleteMedia: (medias: number) => void;
  showConfidenceChip?: boolean;
}

const useStyles = makeStyles(({ spacing }) => ({
  mediaView: {
    width: '100%',
    height: '50%',
    position: 'relative',
  },
  mediaActions: {
    width: '100%',
    height: '5%',
  },
  mediaInfo: {
    width: '100%',
    height: '40%',
  },
  mediaInfoChildren: {
    paddingLeft: spacing(5),
    paddingRight: spacing(5),
  },
}));

const MediaDetailsContent: React.FC<MediaDetailsContentProps> = ({
  media,
  showLabels = true,
  showDefectName = true,
  showConfidenceScore = true,
  deletedMedias,
  showConfidenceChip = false,
}) => {
  const classes = useStyles();
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const defects = useDefectSelectorWithArchived();

  const { data: edgeMediaDetails, isLoading: isLoadingEdgeMedia } = useEdgeMediaDetailsQuery(
    media.id,
  );

  const label = edgeMediaDetails?.labels;
  const queryClient = useQueryClient();

  const handleReviewSubmit = useCallback(
    throttle(async (judgement: MediaLevelLabel, comment: string, inspectorId: string) => {
      if (!projectId) return;
      const JUDGEMENT_MODE = 'shadow';

      // save the judgement
      await CLIAAPI.saveJudgement({
        mediaId: media.id,
        inspectorId,
        judgement,
        mode: JUDGEMENT_MODE,
        comments: comment,
      });

      // fetch the updated media details
      queryClient.invalidateQueries(endpointQueryKeys.edgeMediaDetails(projectId, media.id));
    }, 2000),
    [media, projectId],
  );

  const [boxAnnotations, segmentationAnnotations] = useMemo(() => {
    if (!showLabels || !label?.[0]?.annotation) return [[], [], undefined];

    const bboxes = getEdgeBoxAnnotations(
      edgeMediaDetails,
      AnnotationType.bndbox,
      defects,
      showDefectName,
      showConfidenceScore,
    );
    const segmentationAnnotations = getEdgeBoxAnnotations(
      edgeMediaDetails,
      AnnotationType.segmentation,
      defects,
      showDefectName,
      showConfidenceScore,
    );
    const classifiedClass = undefined;
    return [bboxes, segmentationAnnotations, classifiedClass];
  }, [showLabels, label, defects, edgeMediaDetails, showDefectName, showConfidenceScore]);

  const mediaDetailsLoaded = !isLoadingEdgeMedia && edgeMediaDetails;
  const isMediaDeleted = deletedMedias.includes(media.id);
  return (
    <Grid
      container
      style={{
        // Required: or else collapses the grid
        height: window.innerHeight * 0.7,
      }}
    >
      <Grid container item className={classes.mediaView}>
        <EnhancedImageViewer sourceUrl={edgeMediaDetails?.url}>
          {enhancedInfo => (
            <>
              {showConfidenceChip && (
                <ConfidenceChipDisplay
                  mediaDetails={edgeMediaDetails}
                  showConfidenceScore={showConfidenceScore}
                  showDefectName={showDefectName}
                />
              )}

              <MediaViewer
                key={edgeMediaDetails?.id}
                imgSrc={enhancedInfo?.sourceUrl}
                classifiedClass={
                  <MediaClassifiedClass
                    media={media}
                    mediaDetails={undefined}
                    enableSetClass={false}
                  />
                }
                boxAnnotations={boxAnnotations}
                segmentationAnnotations={segmentationAnnotations}
                properties={{
                  width: (media as EdgeMedia).width,
                  height: (media as EdgeMedia).height,
                }}
                enableZoom
              />
            </>
          )}
        </EnhancedImageViewer>
      </Grid>
      {/**--- Media Actions (delete/download etc...) */}
      {!isMediaDeleted ? (
        <Grid container item className={classes.mediaActions} justifyContent="flex-end">
          {mediaDetailsLoaded && edgeMediaDetails && (
            <MediaActionsSection mediaId={media.id} mediaUrl={edgeMediaDetails.url} />
          )}
        </Grid>
      ) : (
        // Deleted medias as marked with an alert until dialog is closed
        <Grid className={classes.mediaActions}>
          <Alert severity="error">
            {t('Media')}
            {media.id}
            {t('has been deleted')}
          </Alert>
        </Grid>
      )}
      <Grid container item className={classes.mediaInfo} direction="row">
        <Grid item xs={4} className={classes.mediaInfoChildren}>
          {/* -- Media Info Section-- */}
          <MediaDetailsSection title={t('Information')}>
            {edgeMediaDetails ? (
              <MediaInfoPanel media={edgeMediaDetails!} />
            ) : (
              <Typography variant="body1">{t('Loading...')}</Typography>
            )}
          </MediaDetailsSection>
        </Grid>
        <Grid item xs={4} className={classes.mediaInfoChildren}>
          {/* Metadata Info Section-- */}
          {edgeMediaDetails && (
            <MediaDetailsSection title={t('Human Judgment')}>
              <MediaHumanJudgementPanel media={edgeMediaDetails} />
            </MediaDetailsSection>
          )}
        </Grid>
        <Grid item xs={4} className={classes.mediaInfoChildren}>
          {/* Inference media review section */}
          <MediaReviewPanel
            acceptButtonLabel={t('Correct')}
            rejectButtonLabel={t('Incorrect')}
            handleReviewSubmit={handleReviewSubmit}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MediaDetailsContent;

import React, { useMemo } from 'react';
import { Typography, Box } from '@material-ui/core';
import { getClassifiedClass } from '../../../utils';
import { LabelType, Defect, AnnotationBase } from '@clef/shared/types';
import cx from 'classnames';
import { useLabelingStyles } from '../../Labeling/labelingStyles';
import { SuccessIcon, ErrorIcon } from '@/images/data-browser/icons';

interface ClassificationLabelPreviewListProps {
  annotations?: AnnotationBase[];
  allDefects: Defect[];
  labelType: LabelType | null | undefined;
}

export const ClassificationLabelPreviewList: React.FC<ClassificationLabelPreviewListProps> = ({
  annotations = [],
  allDefects = [],
  labelType,
}) => {
  const styles = useLabelingStyles();
  // assume there is only one annotation
  const annotation = annotations[0];
  const defect = allDefects.find(defect => defect.id === annotation?.defectId);

  const classifiedClass = useMemo(() => {
    return getClassifiedClass(annotations || [], allDefects!);
  }, [allDefects, annotations]);

  const gtClassifiedClass = useMemo(() => {
    return getClassifiedClass(annotations || [], allDefects!);
  }, [allDefects, annotations]);

  const statusIndicator = useMemo((): {
    el: React.ReactNode;
    type: 'question' | 'success' | 'error';
  } | null => {
    if (labelType !== LabelType.Classification) return null;
    if (!classifiedClass?.id) return null;

    if (!gtClassifiedClass?.id) {
      return {
        el: <div className={styles.question}>?</div>,
        type: 'question',
      };
    }
    if (classifiedClass?.id === gtClassifiedClass?.id) {
      return {
        el: <SuccessIcon />,
        type: 'success',
      };
    }
    return {
      el: <ErrorIcon />,
      type: 'error',
    };
  }, [classifiedClass?.id, gtClassifiedClass?.id, labelType, styles.question]);

  const score = useMemo(() => {
    if (labelType === LabelType.Classification) {
      return annotation?.confidence?.toFixed(2) || '';
    }
    return '';
  }, [labelType, annotation?.confidence]);

  return (
    <Box display="flex">
      {/* TODO: merge these with ClassChip when design is ready for data browser */}
      <Box
        display="flex"
        className={cx(
          styles.classificationPreviewBadge,
          defect?.name ? 'prediction' : 'no-prediction',
        )}
      >
        <Typography
          variant="body2"
          component="div"
          className={styles.classificationName}
          style={{
            marginLeft: statusIndicator ? 8 : 0,
            marginRight: score !== '' ? 8 : 0,
          }}
        >
          {defect?.name ?? t('Not predicted')}
        </Typography>
        <Typography>{score}</Typography>
      </Box>
    </Box>
  );
};

import { ApiResponseLoader } from '@clef/client-library';
import { Box, Grid, Typography } from '@material-ui/core';
import { Button } from '@clef/client-library';
import Launch from '@material-ui/icons/Launch';
import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDefectSelector } from '../../../store/defectState/actions';
import { useFetchDefectBookExampleApi } from '../../../hooks/api/useDefectBookApi';
import {
  defectToDisplayData,
  EMPTY_DESCRIPTION_TEXT,
  getDateNumber,
  getDefectColor,
} from '../../../utils';
import DefectExample from '../../../pages/DefectBook/components/DefectExample';
import { useLabelingDrawerStyles } from '../labelingDrawerStyles';
import CLEF_PATH from '../../../constants/path';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { LoginState } from '../../../store/types';
import { formatDistance } from 'date-fns';
import TypographyWithTooltip from '@clef/client-library/src/components/TypographyWithTooltip';

type DefectBookDrawerProps = {
  defectId: number;
};
export const DefectBookDrawer = ({ defectId }: DefectBookDrawerProps) => {
  const allDefects = useDefectSelector();
  const defect = useMemo(
    () => allDefects?.find(defect => defect.id === defectId),
    [allDefects, defectId],
  );
  const [defectExamples, defectExamplesLoading, defectExamplesError] = useFetchDefectBookExampleApi(
    defect?.defect_book_example_ids?.length ? defect?.defect_book_example_ids : undefined,
  );
  const displayDefect = defect ? defectToDisplayData(defect, defectExamples) : undefined;
  const defectBookParams = new URLSearchParams({
    tab: 'defects',
    defect: String(defect?.id),
  });
  const styles = useLabelingDrawerStyles();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const user = useTypedSelector(state => state.login.user)!;
  const defectBookUrl = useMemo(() => {
    const url = `${CLEF_PATH.data.defectBookEnhanced}?${defectBookParams.toString()}`;
    return url.replace(
      'app',
      `app/${(user as LoginState['user'])?.orgId}/pr/${selectedProject?.id}`,
    );
  }, [defectBookParams, selectedProject?.id, user]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" width="calc(100% - 48px)">
          <div className={styles.defectColor} style={{ backgroundColor: getDefectColor(defect) }} />
          <Box width="calc(100% - 48px)">
            <TypographyWithTooltip
              title={defect?.name}
              typographyClassName={styles.defectName}
            ></TypographyWithTooltip>
          </Box>
        </Box>
      </Box>
      <Box className={styles.defectUpdateDateBox}>
        <Typography variant="h4" className={styles.defectUpdateDateDescription}>
          {defect && !isNaN(getDateNumber(defect.updatedAt ?? defect.createdAt))
            ? t('Last update: {{dateDistance}} ago', {
                dateDistance: formatDistance(
                  getDateNumber(defect.updatedAt ?? defect.createdAt),
                  new Date(),
                ),
              })
            : t('Unknown date')}
        </Typography>
      </Box>
      <ReactMarkdown className={styles.markdownText}>
        {defect?.descriptionText?.trim() || EMPTY_DESCRIPTION_TEXT}
      </ReactMarkdown>
      <ApiResponseLoader
        response={displayDefect?.examples}
        loading={defectExamplesLoading}
        error={defectExamplesError}
        defaultHeight={300}
      >
        {defectExamples => (
          <>
            <Grid container spacing={3}>
              {defectExamples?.filter(Boolean).map(defectExample => (
                <Grid item key={defectExample.id} xs={12}>
                  <DefectExample editMode={false} defectExample={defectExample} />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </ApiResponseLoader>
      <div className={styles.bottomLinkContainer}>
        <Button
          id="view-full-class-definition"
          color="primary"
          variant="text"
          role="link"
          startIcon={<Launch fontSize="small" />}
          target="_blank"
          href={defectBookUrl}
        >
          <strong>{t('View Full Class Definition')}</strong>
        </Button>
      </div>
    </>
  );
};

import { useTypedSelector } from './useTypedSelector';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { ProjectRole, UserPermission } from '@clef/shared/types';

/**
 * get project role and permissions for the current user
 */
export const useProjectRolePermissions = () => {
  const { data: project } = useGetSelectedProjectQuery();
  const user = useTypedSelector(state => state.login.user);

  const { userProject } = project?.users?.find(({ id }) => user?.id === id) ?? {};
  const { permissions, role } = userProject ?? {};

  const projectRole = role || ProjectRole.Standard;

  return { inviteOnly: project?.inviteOnly, projectRole, permissions: permissions ?? [] };
};

/**
 * check if current user has a specified project-level permission
 * @param permission UserPermission
 */
export const useHasPermission = (permission: UserPermission) => {
  const { inviteOnly, projectRole, permissions } = useProjectRolePermissions();
  if (!inviteOnly || projectRole === ProjectRole.Owner) {
    return true;
  }
  if (projectRole === ProjectRole.Labeler) {
    return false;
  }
  return permissions.includes(permission);
};

import React from 'react';

const ZoomInIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.29025 2.75H5.03225C3.77175 2.75 2.75 3.77175 2.75 5.03225V7.29025C2.75 7.80225 3.1655 8.21775 3.6775 8.21775C4.18925 8.21775 4.60475 7.80225 4.60475 7.29025V5.03225C4.60475 4.79625 4.79625 4.60475 5.03225 4.60475H7.29025C7.80225 4.60475 8.21775 4.18925 8.21775 3.6775C8.21775 3.1655 7.80225 2.75 7.29025 2.75V2.75Z"
      fill="#30374F"
    />
    <path
      d="M17.25 7.29025V5.03225C17.25 3.77175 16.2282 2.75 14.9677 2.75H12.7097C12.1977 2.75 11.7822 3.1655 11.7822 3.6775C11.7822 4.18925 12.1977 4.60475 12.7097 4.60475H14.9677C15.2037 4.60475 15.3952 4.79625 15.3952 5.03225V7.29025C15.3952 7.80225 15.8107 8.21775 16.3225 8.21775C16.8345 8.21775 17.25 7.80225 17.25 7.29025Z"
      fill="#30374F"
    />
    <path
      d="M12.7097 17.25H14.9677C16.2282 17.25 17.25 16.2282 17.25 14.9677V12.7097C17.25 12.1977 16.8345 11.7822 16.3225 11.7822C15.8107 11.7822 15.3952 12.1977 15.3952 12.7097V14.9677C15.3952 15.2037 15.2037 15.3952 14.9677 15.3952H12.7097C12.1977 15.3952 11.7822 15.8107 11.7822 16.3225C11.7822 16.8345 12.1977 17.25 12.7097 17.25V17.25Z"
      fill="#30374F"
    />
    <path
      d="M2.75 12.7097V14.9677C2.75 16.2282 3.77175 17.25 5.03225 17.25H7.29025C7.80225 17.25 8.21775 16.8345 8.21775 16.3225C8.21775 15.8107 7.80225 15.3952 7.29025 15.3952H5.03225C4.79625 15.3952 4.60475 15.2037 4.60475 14.9677V12.7097C4.60475 12.1977 4.18925 11.7822 3.6775 11.7822C3.1655 11.7822 2.75 12.1977 2.75 12.7097V12.7097Z"
      fill="#30374F"
    />
    <path
      d="M9 9H7.25C6.698 9 6.25 9.448 6.25 10C6.25 10.552 6.698 11 7.25 11H9V12.75C9 13.302 9.448 13.75 10 13.75C10.552 13.75 11 13.302 11 12.75V11H12.75C13.302 11 13.75 10.552 13.75 10C13.75 9.448 13.302 9 12.75 9H11V7.25C11 6.698 10.552 6.25 10 6.25C9.448 6.25 9 6.698 9 7.25V9Z"
      fill="#30374F"
    />
  </svg>
);

export default ZoomInIcon;

// this tools component is for media details page

import React, { forwardRef, useCallback, useEffect } from 'react';
import { Box, Tooltip, Typography, makeStyles, withStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import classNames from 'classnames';

import { Button, IconButton, useLocalStorage, useKeyPress } from '@clef/client-library';

import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import {
  IconProps,
  SmartLabelingIcon,
  BrushIcon,
  PolygonIcon,
  PolylineIcon,
} from '@/images/labeling_tools_icons/ToolIcons';

import {
  ToolMode,
  TutorialStep,
  useLabelingState,
  useUnsavedLabelCheckDecorator,
} from './labelingState';
import { useLabelingStyles } from './labelingStyles';
import SegmentationLabelToolModes from './SegmentationLabelToolModes';
import SmartLabelingGif from '@/images/sam/smart_labeling.gif';
import { HintEvents, useHintSnackbar } from './HintSnackbar';
import { useAtom } from 'jotai';
import { currentToolStateAtom } from '@/uiStates/mediaDetails/pageUIStates';

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#4D5761',
    fontSize: 14,
    fontWeight: 400,
    maxWidth: 600,
    padding: theme.spacing(3),
    pointerEvents: 'all',
    marginTop: theme.spacing(5),
    boxShadow: '0px 1px 2px rgba(48, 55, 79, 0.16), 0px 2px 4px rgba(48, 55, 79, 0.08)',
    '& .MuiTooltip-arrow': {
      color: 'white',
    },
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

export interface ModeButtonProps {
  mode: ToolMode;
  ModeIcon: React.FC<IconProps>;
  modeName: string;
  hotKey: string;
  onClick?: () => void;
}

const useModeButtonStyles = makeStyles(theme => ({
  btn: {
    margin: `${theme.spacing(0, 1)} !important`,
  },
  active: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
  },
}));

const ModeButton = forwardRef<HTMLButtonElement, ModeButtonProps>(
  ({ mode, ModeIcon, modeName, hotKey, onClick }, fRef) => {
    const styles = useLabelingStyles();
    const modeButtonStyles = useModeButtonStyles();
    const {
      state: { toolMode },
      dispatch,
    } = useLabelingState();
    const [, setCurrentToolState] = useAtom(currentToolStateAtom);

    const triggerHint = useHintSnackbar();
    useEffect(() => {
      if (toolMode === ToolMode.Polygon) {
        triggerHint(HintEvents.PolygonInstructions);
      } else if (toolMode === ToolMode.Polyline) {
        triggerHint(HintEvents.PolylineInstructions);
      } else {
        triggerHint();
      }
      return () => {
        triggerHint();
      };
    }, [toolMode, triggerHint]);

    const enabled = mode === toolMode;

    const toggleMode = useCallback(() => {
      onClick?.();
      // const newToolMode = enabled ? undefined : mode;

      if (enabled) return;
      const newToolMode = mode;

      dispatch(draft => {
        draft.toolMode = newToolMode;
        draft.toolOptions.erasing = false;
        setCurrentToolState(prev => ({ ...prev, erasing: false }));
      });
    }, [dispatch, enabled, mode, onClick]);
    const onHotKeyPress = useUnsavedLabelCheckDecorator(toggleMode);

    // keyboard shortcut
    useKeyPress(hotKey, onHotKeyPress, {
      id: `task-select-mode-${modeName}`,
    });

    return (
      <Tooltip
        arrow
        placement="top"
        title={
          <>
            {modeName} <span className={styles.codeBlock}>{hotKey}</span>
          </>
        }
      >
        <Button
          ref={fRef}
          id={`task-select-mode-${modeName.replace(' ', '-')}`}
          className={classNames(modeButtonStyles.btn, enabled && modeButtonStyles.active)}
          color="primary"
          aria-label={`tool-mode-button-${modeName}`}
          onClick={onHotKeyPress}
        >
          <ModeIcon color="#30374F" />
        </Button>
      </Tooltip>
    );
  },
);

export interface SegmentationLabelToolsProps {
  onClick?: () => void;
}

const SegmentationLabelTools: React.FC<SegmentationLabelToolsProps> = ({ onClick }) => {
  const styles = useLabelingStyles();

  const {
    state: {
      toolMode,
      toolOptions: { erasing },
    },
    dispatch,
  } = useLabelingState();

  const quickLabelingExpEnabled = useFeatureGateEnabled(ClientFeatures.QuickLabelingExp);

  const [skipFirstTimeQuickLabelingTooltip, setSkipFirstTimeQuickLabelingTooltip] = useLocalStorage(
    'skipFirstTimeQuickLabelingTooltip',
  );

  return (
    <>
      {quickLabelingExpEnabled && (
        <StyledTooltip
          arrow
          title={
            <Box width={350} paddingX={2} paddingY={1}>
              <IconButton
                id="sam-smart-labeling-tooltip-close-button"
                size="small"
                onClick={() => setSkipFirstTimeQuickLabelingTooltip(true)}
                className={styles.smartLabelingGuideCloseButton}
              >
                <Close />
              </IconButton>

              <Typography variant="h3">{t('Label faster with Smart Labeling')}</Typography>

              <Typography className={styles.smartLabelingGuideDescription}>
                {t(
                  'Just click the object you want to detect, then press Enter/Return on your keyboard to save the label.',
                )}
              </Typography>

              <img src={SmartLabelingGif} className={styles.smartLabelingGuideGif} />
            </Box>
          }
          open={!skipFirstTimeQuickLabelingTooltip}
          onClose={() => setSkipFirstTimeQuickLabelingTooltip(true)}
          placement="bottom-start"
        >
          <ModeButton
            mode={ToolMode.Quick}
            // ModeIcon={IconQuickLabeling}
            ModeIcon={SmartLabelingIcon}
            modeName="Smart Labeling"
            hotKey="q"
            onClick={onClick}
          />
        </StyledTooltip>
      )}
      <ModeButton
        mode={ToolMode.Brush}
        ModeIcon={BrushIcon}
        modeName="Brush"
        hotKey="b"
        onClick={onClick}
      />
      {toolMode === ToolMode.Brush && (
        <SegmentationLabelToolModes
          key={erasing ? `${ToolMode.Brush}-erasing` : `${ToolMode.Brush}-drawing`}
        />
      )}

      <ModeButton
        mode={ToolMode.Polygon}
        ModeIcon={PolygonIcon}
        modeName="Polygon"
        hotKey="w"
        onClick={onClick}
        ref={buttonRef => {
          if (buttonRef) {
            dispatch(draft => {
              // having type issues putting HTMLElement into state, so cast to any
              draft.tutorialAnchorElementsMap[TutorialStep.Polygon] = buttonRef as any;
            });
          }
        }}
      />
      {toolMode === ToolMode.Polygon && (
        <SegmentationLabelToolModes
          key={erasing ? `${ToolMode.Polygon}-erasing` : `${ToolMode.Polygon}-drawing`}
        />
      )}

      <ModeButton
        mode={ToolMode.Polyline}
        ModeIcon={PolylineIcon}
        modeName="Polyline"
        hotKey="l"
        onClick={onClick}
      />
      {toolMode === ToolMode.Polyline && (
        <SegmentationLabelToolModes
          key={erasing ? `${ToolMode.Polyline}-erasing` : `${ToolMode.Polyline}-drawing`}
        />
      )}
    </>
  );
};

export default SegmentationLabelTools;

import React from 'react';
import { Chip } from '@material-ui/core';
import { useClassChipStyles } from '@clef/client-library';

const ReadOnlyMediaClassifiedClass: React.FC<{ name: string; dotted: boolean }> = ({
  name,
  dotted,
}) => {
  const styles = useClassChipStyles();
  const chipStyles: React.CSSProperties = {};
  if (dotted) {
    chipStyles.borderStyle = 'dashed';
  }
  return (
    <Chip
      classes={{
        label: styles.classificationChipLabel,
        root: styles.classificationChip,
      }}
      style={{
        ...chipStyles,
      }}
      label={name}
    />
  );
};

export default ReadOnlyMediaClassifiedClass;

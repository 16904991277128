import { Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

interface StatsCardTitleProps {
  title: string;
  Option?: React.FC;
}

const useStyles = makeStyles(() => ({
  titleContainer: {
    height: 32,
  },
}));

const StatsCardTitle: React.FC<StatsCardTitleProps> = ({ title, Option }) => {
  const styles = useStyles();
  return (
    <Grid container direction="row" alignItems="center" className={styles.titleContainer}>
      <Typography variant="subtitle1">{title}</Typography>
      {Option && <Option />}
    </Grid>
  );
};

export default StatsCardTitle;

import { useCallback, useEffect, useRef, useState } from 'react';

const DEFAULT_TIME_INCREMENT = 1;
const DEFAULT_TIME_UPDATE_FREQUENCY = 1000;
const DEFAULT_INITIAL_VALUE = 0;
const DEFAULT_VALUE_LIMIT = null;

export interface UseTimerProps {
  increment?: number;
  frequency?: number;
  initialValue?: number;
  valueLimit?: number | null;
}

export const useTimer = ({
  increment = DEFAULT_TIME_INCREMENT,
  frequency = DEFAULT_TIME_UPDATE_FREQUENCY,
  initialValue = DEFAULT_INITIAL_VALUE,
  valueLimit = DEFAULT_VALUE_LIMIT,
}: UseTimerProps) => {
  const timeRef = useRef(initialValue);
  const [time, setTime] = useState(initialValue);
  const [enable, setEnable] = useState(false);
  const incrementRef = useRef(increment);
  const frequencyRef = useRef(frequency);
  const initialValueRef = useRef(initialValue);
  const valueLimitRef = useRef(valueLimit);

  useEffect(() => {
    incrementRef.current = increment;
  }, [increment]);
  useEffect(() => {
    frequencyRef.current = frequency;
  }, [frequency]);
  useEffect(() => {
    initialValueRef.current = initialValue;
  }, [initialValue]);
  useEffect(() => {
    valueLimitRef.current = valueLimit;
  }, [valueLimit]);

  useEffect(() => {
    if (enable) {
      const intervalId = setInterval(() => {
        timeRef.current = timeRef.current + incrementRef.current;
        setTime(timeRef.current);

        if (
          typeof valueLimitRef.current === 'number' &&
          ((incrementRef.current > 0 && timeRef.current >= valueLimitRef.current) ||
            (incrementRef.current < 0 && timeRef.current <= valueLimitRef.current))
        ) {
          clearInterval(intervalId);
        }
      }, frequencyRef.current);

      return () => {
        clearInterval(intervalId);
      };
    }

    return;
  }, [enable]);

  const start = useCallback(() => {
    setEnable(true);
    timeRef.current = initialValueRef.current;
  }, []);

  const clear = useCallback(() => {
    setEnable(false);
    timeRef.current = initialValueRef.current;
    setTime(initialValueRef.current);
  }, []);

  const restart = useCallback(() => {
    clear();
    setTimeout(start);
  }, [clear, start]);

  return {
    time,
    start,
    clear,
    restart,
  };
};

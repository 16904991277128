import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  featuredItem: {
    flex: 1,
  },
  mediaViewerFeaturedRoot: {
    background: '#F3F6F9',
    borderRadius: 10,
  },
  loadingWithChildren: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&> *': {
      visibility: 'visible',
    },
  },
  loadingImg: {
    width: 100,
    height: 100,
    objectFit: 'contain',
    margin: 'auto',
  },
  featuredItemError: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F3F6F9',
    borderRadius: 10,
  },
  errorMessage: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
}));

export default useStyles;

import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Box, Tooltip, MenuList, MenuItem } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useAtom } from 'jotai';

import {
  DatasetGroupOptions,
  SelectMediaOption,
  MediaStatusType,
  LabelType,
} from '@clef/shared/types';
import {
  Button,
  DistributionChart,
  ApiResponseLoader,
  Typography,
  SwitchButtonGroup,
  Dropdown,
} from '@clef/client-library';

import CLEF_PATH from '@/constants/path';
import { defaultSelectOptions } from '@/constants/data_browser';
import {
  customTrainSplitChartMap,
  MIN_LABELED_MEDIA_FOR_FAST_N_EASY_TRAIN,
  MIN_CLASSIFICATION_DEFECTS_FOR_FAST_N_EASY_TRAIN,
} from '@/constants/model_train';
import { useDefectSelector } from '@/store/defectState/actions';
import { DefectDistributionWithAssignment } from '@clef/shared/utils/auto_split_core_algorithm';
import {
  useGetSelectedProjectQuery,
  useGetProjectVersionedDefectsQuery,
} from '@/serverStore/projects';
import {
  useGetDatasetStatsQuery,
  useGetDatasetFilterOptionsQuery,
  useDatasetMediaCountQuery,
  useDatasetExportedWithVersionsQuery,
} from '@/serverStore/dataset';
import { useGetModelArchSchemas } from '@/serverStore/train';
import {
  currentStepAtom,
  CustomTrainingStepMode,
  selectedDatasetVersionAtom,
  useResetAndBackToBuild,
  defectDistributionWithAssignmentAtom,
  enableAdjustSplitAtom,
  datasetTrainingIssuesAtom,
  DatasetTrainingIssueType,
} from '@/uiStates/customTraining/pageUIStates';
import {
  supportedAutoSplit,
  distributionAssignmentToSplitStats,
  distributionAssignmentAggregateByDefect,
  distributionAssignmentAggregateBySplit,
  aggregateMappingTypeToDistributionList,
} from '@/components/AutoSplitDialog/utils';
import { getDefectColor, getDateNumber } from '@/utils';

import StepTitle from '../StepTitle';
import useStyles from '../styles';
import SplitAdjustment from './SplitAdjustment';

const SplitSetup: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const snapshotVersion = searchParams.get('version');

  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
  const [selectedDatasetVersion, setSelectedDatasetVersion] = useAtom(selectedDatasetVersionAtom);
  const [enableAdjustSplit, setEnableAdjustSplit] = useAtom(enableAdjustSplitAtom);
  const [datasetTrainingIssues, setDatasetTrainingIssues] = useAtom(datasetTrainingIssuesAtom);
  const [defectDistributionWithAssignment, setDefectDistributionWithAssignment] = useAtom(
    defectDistributionWithAssignmentAtom,
  );

  const resetAndBackToBuild = useResetAndBackToBuild();

  const [viewMode, setViewMode] = useState<'class' | 'split'>('class');

  const { labelType } = useGetSelectedProjectQuery().data ?? {};

  const { data: allFilters } = useGetDatasetFilterOptionsQuery(
    selectedDatasetVersion?.version ?? undefined,
  );

  const { data: datasetExported } = useDatasetExportedWithVersionsQuery({
    withCount: true,
    includeNotCompleted: true,
    includeFastEasy: true,
  });

  useEffect(() => {
    if (snapshotVersion && datasetExported?.datasetVersions) {
      const targetVersion = datasetExported?.datasetVersions?.find(
        v => v.version === parseInt(snapshotVersion),
      );
      if (targetVersion) {
        setSelectedDatasetVersion(targetVersion);
        setCurrentStep(CustomTrainingStepMode.SplitSetup);
      }
    }
  }, [
    datasetExported?.datasetVersions,
    setCurrentStep,
    setSelectedDatasetVersion,
    snapshotVersion,
  ]);

  const splitFilterOption = allFilters
    ? allFilters.find(value => value.filterName === 'Split' && value.filterType === 'column') ||
      allFilters.find(value => value.filterName === 'split')
    : undefined;
  const supportedAutoSplitId: number[] =
    splitFilterOption?.filterType === 'column'
      ? supportedAutoSplit.map(s => splitFilterOption.value![s] as number)
      : [];

  const allDefects = useDefectSelector();

  const { data: versionedDefects } = useGetProjectVersionedDefectsQuery(
    selectedDatasetVersion?.version,
  );

  const currentDefects = useMemo(
    () => (selectedDatasetVersion ? versionedDefects : allDefects),
    [allDefects, selectedDatasetVersion, versionedDefects],
  );

  const labeledMediaSelectMediaOption: SelectMediaOption | undefined = {
    ...defaultSelectOptions,
    columnFilterMap: {
      datasetContent: {
        mediaStatus: { CONTAINS_ANY: [MediaStatusType.Approved] },
      },
    },
  };

  const { data: labeledMediaCount, isLoading: isLabeledMediaCountLoading } =
    useDatasetMediaCountQuery({
      version: selectedDatasetVersion?.version,
      selectOptions: labeledMediaSelectMediaOption,
    });

  const assignedLabeledMediaSelectMediaOption: SelectMediaOption | undefined = splitFilterOption
    ? {
        ...defaultSelectOptions,
        columnFilterMap: {
          datasetContent: {
            mediaStatus: { CONTAINS_ANY: [MediaStatusType.Approved] },
            ...(splitFilterOption.filterType === 'column' &&
              supportedAutoSplitId.length && {
                splitSet: { CONTAINS_ANY: supportedAutoSplitId },
              }),
          },
        },
      }
    : undefined;

  const { data: labeledAssignedMediaCount, isLoading: isLabeledAssignedMediaCountLoading } =
    useDatasetMediaCountQuery({
      version: selectedDatasetVersion?.version,
      selectOptions: assignedLabeledMediaSelectMediaOption,
    });

  const unassignedLabeledMediaSelectMediaOption: SelectMediaOption | undefined = splitFilterOption
    ? {
        ...defaultSelectOptions,
        columnFilterMap: {
          datasetContent: {
            mediaStatus: { CONTAINS_ANY: [MediaStatusType.Approved] },
            ...(splitFilterOption.filterType === 'column' &&
              supportedAutoSplitId.length && {
                splitSet: { NOT_CONTAIN_ANY: supportedAutoSplitId },
              }),
          },
        },
      }
    : undefined;

  const { data: labeledUnassignedMediaCount, isLoading: isLabeledUnassignedMediaCountLoading } =
    useDatasetMediaCountQuery({
      version: selectedDatasetVersion?.version,
      selectOptions: unassignedLabeledMediaSelectMediaOption,
    });

  const { data: mediaGroupBySplitDefectDistribution, isLoading: datasetStatsLoading } =
    useGetDatasetStatsQuery(
      {
        selectOptions: {
          fieldFilterMap: {},
          columnFilterMap: {
            datasetContent: {
              mediaStatus: { CONTAINS_ANY: [MediaStatusType.Approved] },
            },
          },
          selectedMedia: [],
          unselectedMedia: [],
          isUnselectMode: true,
        },
        groupOptions: [DatasetGroupOptions.DEFECT_DISTRIBUTION, DatasetGroupOptions.SPLIT],
        version: selectedDatasetVersion?.version ?? undefined,
      },
      !!splitFilterOption,
    );

  const existDistributionWithAssignment = useMemo(
    () =>
      mediaGroupBySplitDefectDistribution?.reduce((acc, { defect_distribution, split, count }) => {
        return [
          ...acc,
          {
            count,
            defect_distribution: defect_distribution!,
            assignment: [
              split === 'train' ? count : 0,
              split === 'dev' ? count : 0,
              split === 'test' ? count : 0,
            ] as [number, number, number],
          },
        ];
      }, [] as DefectDistributionWithAssignment[]),
    [mediaGroupBySplitDefectDistribution],
  );

  const distributionWithAssignmentFinal = useMemo(() => {
    if (enableAdjustSplit && defectDistributionWithAssignment && existDistributionWithAssignment) {
      return [...defectDistributionWithAssignment, ...existDistributionWithAssignment];
    }
    return existDistributionWithAssignment;
  }, [defectDistributionWithAssignment, enableAdjustSplit, existDistributionWithAssignment]);

  // for split graph
  const splitStats = useMemo(
    () =>
      distributionWithAssignmentFinal
        ? distributionAssignmentToSplitStats(distributionWithAssignmentFinal)
        : undefined,
    [distributionWithAssignmentFinal],
  );

  // for defect - split graph
  const distributionAggregatedByDefectMapping = useMemo(
    () =>
      currentDefects && distributionWithAssignmentFinal
        ? distributionAssignmentAggregateByDefect(distributionWithAssignmentFinal, currentDefects)
        : undefined,
    [currentDefects, distributionWithAssignmentFinal],
  );

  const distributionAggregatedByDefect = useMemo(
    () =>
      distributionAggregatedByDefectMapping
        ? aggregateMappingTypeToDistributionList(distributionAggregatedByDefectMapping)
        : undefined,
    [distributionAggregatedByDefectMapping],
  );

  // for split - defect graph
  const distributionAggregatedBySplitMapping = useMemo(
    () =>
      currentDefects && distributionWithAssignmentFinal
        ? distributionAssignmentAggregateBySplit(distributionWithAssignmentFinal, currentDefects)
        : undefined,
    [currentDefects, distributionWithAssignmentFinal],
  );

  // for split - defect graph
  const distributionAggregatedBySplit = useMemo(
    () =>
      distributionAggregatedBySplitMapping
        ? aggregateMappingTypeToDistributionList(distributionAggregatedBySplitMapping)
        : undefined,
    [distributionAggregatedBySplitMapping],
  );

  const defectColorMap: { [key: string]: string } | undefined = useMemo(
    () =>
      currentDefects?.reduce(
        (acc, defect) => ({ ...acc, [defect.name]: getDefectColor(defect) }),
        {},
      ),
    [currentDefects],
  );

  const { data: modelArchSchemas } = useGetModelArchSchemas();

  const { data: mediaGroupBySplit } = useGetDatasetStatsQuery({
    selectOptions: defaultSelectOptions,
    groupOptions: [DatasetGroupOptions.SPLIT],
    version: selectedDatasetVersion?.version,
  });

  const enoughMediasForTrain = useMemo(() => {
    if (!modelArchSchemas || !labeledMediaCount || !mediaGroupBySplit) return false;
    if (labeledMediaCount < modelArchSchemas[0]?.datasetLimits.minLabeledMedia) return false;
    return true;
  }, [modelArchSchemas, labeledMediaCount, mediaGroupBySplit]);

  const enoughMediasInTrainSplit = useMemo(() => {
    if (!splitStats) return false;
    const trainSplit = splitStats.find(split => split.name === 'train');

    if (!trainSplit || trainSplit?.value < 2) {
      return false;
    }
    return true;
  }, [splitStats]);

  const invalidClassificationTrain =
    labelType === LabelType.Classification
      ? !!currentDefects && currentDefects.length < MIN_CLASSIFICATION_DEFECTS_FOR_FAST_N_EASY_TRAIN
      : false;

  useEffect(() => {
    const updateTrainingIssues = (condition: boolean, issueType: DatasetTrainingIssueType) => {
      if (condition && !datasetTrainingIssues.includes(issueType)) {
        setDatasetTrainingIssues(prevIssues => [...prevIssues, issueType]);
      } else if (!condition && datasetTrainingIssues.includes(issueType)) {
        setDatasetTrainingIssues(prevIssues => prevIssues.filter(issue => issue !== issueType));
      }
    };

    updateTrainingIssues(!enoughMediasForTrain, DatasetTrainingIssueType.NotEnoughLabeledMedias);
    updateTrainingIssues(
      !enoughMediasInTrainSplit,
      DatasetTrainingIssueType.NotEnoughMediasInTrainSplit,
    );
    updateTrainingIssues(
      invalidClassificationTrain,
      DatasetTrainingIssueType.InvalidClassificationTrain,
    );
  }, [
    datasetTrainingIssues,
    enoughMediasForTrain,
    enoughMediasInTrainSplit,
    invalidClassificationTrain,
    setDatasetTrainingIssues,
  ]);

  return (
    <>
      <StepTitle step={1} title="Set up your data" />

      <Box className={styles.splitSetup}>
        <Dropdown
          extraGutter={{ vertical: 12 }}
          dropdown={(toggleDropdown: (open: boolean) => void) => (
            <MenuList aria-label="dataset-selector-menu" className={styles.datasetSelectorMenu}>
              <MenuItem
                selected={selectedDatasetVersion === null}
                onClick={() => {
                  if (selectedDatasetVersion !== null) {
                    setSelectedDatasetVersion(null);
                    setEnableAdjustSplit(true);
                    if (snapshotVersion) {
                      history.push(CLEF_PATH.data.customTraining);
                    }
                  }
                  toggleDropdown(false);
                }}
              >
                {t('Current version')}
              </MenuItem>
              {datasetExported?.datasetVersions
                ?.sort((a, b) => getDateNumber(b.creationTime) - getDateNumber(a.creationTime))
                .map(version => (
                  <MenuItem
                    key={version.id}
                    selected={version === selectedDatasetVersion}
                    onClick={() => {
                      if (version !== selectedDatasetVersion) {
                        setSelectedDatasetVersion(version);
                        setDefectDistributionWithAssignment([]);
                        if (snapshotVersion) {
                          history.push(CLEF_PATH.data.customTraining);
                        }
                      }
                      toggleDropdown(false);
                    }}
                  >
                    <Typography maxWidth={340}>{version.name}</Typography>
                  </MenuItem>
                ))}
            </MenuList>
          )}
        >
          <Button
            id={'dataset-selector-button'}
            variant="outlined"
            endIcon={<KeyboardArrowDownIcon />}
            className={styles.datasetSelectorButton}
          >
            <Typography maxWidth={300}>
              {selectedDatasetVersion ? selectedDatasetVersion.name : t('Current version')}
            </Typography>
          </Button>
        </Dropdown>

        <ApiResponseLoader
          response={{ labeledAssignedMediaCount, labeledUnassignedMediaCount }}
          loading={isLabeledAssignedMediaCountLoading || isLabeledUnassignedMediaCountLoading}
        >
          {({ labeledAssignedMediaCount, labeledUnassignedMediaCount }) => (
            <>
              {!isLabeledMediaCountLoading && (
                <Box mb={6}>
                  <Typography>
                    {t(`Your dataset contains {{labeledMediaCount}} labeled images, `, {
                      labeledMediaCount,
                    })}
                    {t(
                      labeledUnassignedMediaCount !== undefined && labeledUnassignedMediaCount > 0
                        ? `out of which {{labeledUnassignedMediaCount}} have not {{status}} been assigned to a split.`
                        : 'all of them have split assigned.',
                      {
                        labeledUnassignedMediaCount,
                        status: !selectedDatasetVersion ? t('yet') : '',
                      },
                    )}
                  </Typography>
                </Box>
              )}

              {!selectedDatasetVersion && <SplitAdjustment />}

              <Box width={700} mb={6}>
                <Typography variant="body_bold">
                  {t(
                    `${
                      labeledUnassignedMediaCount !== undefined && labeledUnassignedMediaCount > 0
                        ? 'Preview'
                        : 'View'
                    } your split (${
                      enableAdjustSplit
                        ? (labeledAssignedMediaCount ?? 0) + (labeledUnassignedMediaCount ?? 0)
                        : labeledAssignedMediaCount
                    } images)`,
                  )}
                </Typography>
                <Box display="flex" justifyContent="space-between" mt={3}>
                  {splitStats ? (
                    <Box className={styles.finalSplitDesc}>
                      <Typography>
                        {t('Images in Train')}:
                        {splitStats.find(s => s.name === 'train')?.value ?? 0}
                      </Typography>
                      <Typography>
                        {t('Images in Dev')}: {splitStats.find(s => s.name === 'dev')?.value ?? 0}
                      </Typography>
                      <Typography>
                        {t('Images in Test')}: {splitStats.find(s => s.name === 'test')?.value ?? 0}
                      </Typography>
                    </Box>
                  ) : (
                    <Box />
                  )}
                  <SwitchButtonGroup className={styles.viewModeSwitch}>
                    <Button
                      id="chart-by-class"
                      className={viewMode === 'class' ? 'active' : undefined}
                      onClick={() => setViewMode('class')}
                    >
                      {t('By Class')}
                    </Button>
                    <Button
                      id="chart-by-split"
                      className={viewMode === 'split' ? 'active' : undefined}
                      onClick={() => setViewMode('split')}
                    >
                      {t('By Split')}
                    </Button>
                  </SwitchButtonGroup>
                </Box>
                <ApiResponseLoader
                  response={
                    viewMode === 'class'
                      ? distributionAggregatedByDefect
                      : distributionAggregatedBySplit
                  }
                  loading={datasetStatsLoading}
                >
                  {res => (
                    <DistributionChart
                      distributionData={res}
                      distributorColorMap={
                        viewMode === 'class' ? customTrainSplitChartMap : defectColorMap!
                      }
                      hideLabel
                      size="small"
                      bandWidth={6}
                      labelWidth={70}
                      borderRadius={4}
                    />
                  )}
                </ApiResponseLoader>
              </Box>
              {currentStep === CustomTrainingStepMode.SplitSetup && (
                <Box className={styles.pageControlButtons}>
                  <Button
                    id="cancel-customize-training-at-step2"
                    variant="text"
                    color="primary"
                    onClick={resetAndBackToBuild}
                  >
                    {t('Cancel')}
                  </Button>
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      (datasetTrainingIssues.includes(
                        DatasetTrainingIssueType.NotEnoughLabeledMedias,
                      ) &&
                        t(`Need at least {{minCount}} labeled assigned images to train a model.`, {
                          minCount:
                            modelArchSchemas?.[0]?.datasetLimits.minLabeledMedia ??
                            MIN_LABELED_MEDIA_FOR_FAST_N_EASY_TRAIN,
                        })) ||
                      (datasetTrainingIssues.includes(
                        DatasetTrainingIssueType.NotEnoughMediasInTrainSplit,
                      ) &&
                        t(`At least 2 images are required in the train split.`)) ||
                      (datasetTrainingIssues.includes(
                        DatasetTrainingIssueType.InvalidClassificationTrain,
                      ) &&
                        t(
                          'Classification projects should contain at least two classes created.',
                        )) ||
                      ''
                    }
                  >
                    <span>
                      <Button
                        id="customize-training-go-next-at-split-setup"
                        variant="contained"
                        color="primary"
                        disabled={
                          !enoughMediasForTrain ||
                          !enoughMediasInTrainSplit ||
                          invalidClassificationTrain
                        }
                        onClick={() => setCurrentStep(CustomTrainingStepMode.ModelsConfiguration)}
                      >
                        {t('Next')}
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              )}
            </>
          )}
        </ApiResponseLoader>
      </Box>
    </>
  );
};

export default SplitSetup;

import React, { useCallback, useMemo } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { Button } from '@clef/client-library';

import { useAppDispatch } from '../../../../../store';
import {
  addSegmentationDefectMapFile,
  deleteSegmentationDefectMapFile,
} from '../../../../../store/uploadState';
import DefectMapUpload from '../../../../../images/segmentation_media_upload/defect_map_upload.png';
import JsonIcon from '../../../../../images/segmentation_media_upload/json.png';
import useUploadFileBlockQueue, {
  UploadFileBlock,
} from '../../../../../hooks/useUploadFileBlockQueue';
import { useNewUploadStyles } from '../../styles';
import { BaseDropFileZone } from './BaseDropFileZone';
import Markdown from 'markdown-to-jsx';
import { useSnackbar } from 'notistack';

const HelpContent = `The Defect Map is a JSON file that defines the Class names for your Project. The Defect Map must follow this format:

\`\`\`
{
  "0": "ok",
  "1": "Class Name",
  "2": "Class Name"
}
\`\`\`

The value for “0” must be “ok”. “0” is used to identify the background; in other words, areas that do not have Classes applied to them. The value “ok” will not be created as a Class.
`;

export interface SegmentationUploadDefectMapDropFileZoneProps {
  disabled?: boolean;
}

export const SegmentationUploadDefectMapDropFileZone: React.FC<
  SegmentationUploadDefectMapDropFileZoneProps
> = ({ disabled }) => {
  const styles = useNewUploadStyles();
  const dispatch = useAppDispatch();
  const { defectMapBlocks } = useUploadFileBlockQueue();
  const { enqueueSnackbar } = useSnackbar();

  const getChipLabel = useCallback(
    (block: UploadFileBlock) => {
      return (
        <Grid container alignItems="center" justifyContent="center">
          <img src={JsonIcon} className={styles.segmentationDropZoneChipIcon} />
          <span>{block.files[0].name}</span>
        </Grid>
      );
    },
    [styles.segmentationDropZoneChipIcon],
  );

  const helpContent = useMemo(() => <Markdown>{HelpContent}</Markdown>, []);

  return (
    <BaseDropFileZone
      blocks={defectMapBlocks}
      title={t('Defect map')}
      acceptFileTypesText={t('JSON')}
      imageSrc={DefectMapUpload}
      guideMessage={{
        mini: (
          <Typography className={styles.dropzoneText}>
            {t('Drag and drop file, or ')}
            <strong className={styles.chooseFileText}>{t('Choose File')}</strong>
          </Typography>
        ),
        full: (
          <>
            <Typography className={styles.dropzoneText}>{t('Drag and drop file, or')}</Typography>
            <Typography>
              <Button
                id="choose-defect-map-file"
                variant="contained"
                color="primary"
                className={styles.chooseFileButton}
                disabled={disabled}
              >
                {t('Choose File')}
              </Button>
            </Typography>
          </>
        ),
      }}
      onDrop={async files => {
        try {
          // @ts-ignore unwrap works
          await dispatch(addSegmentationDefectMapFile(files[0])).unwrap();
        } catch (e) {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      }}
      onDelete={() => {
        dispatch(deleteSegmentationDefectMapFile());
      }}
      getChipLabel={getChipLabel}
      acceptedFiles={['application/json']}
      acceptMultipleDrops={false}
      dropZoneDataTestId={'seg-defect-map-upload-input'}
      hasAcceptedFiles={!!defectMapBlocks.length}
      helpContent={helpContent}
      disabled={disabled}
    />
  );
};

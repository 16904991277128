import React, { useState, CSSProperties } from 'react';
import cx from 'classnames';
import { Card, Grid, Typography, Tooltip, Box, makeStyles, Theme } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { LabelType, Project, User } from '@clef/shared/types';
import {
  getThumbnail,
  greenScale,
  greyModernScale,
  orangeScale,
  purpleScale,
} from '@clef/client-library';
import { Img, blueScale, pinkScale } from '@clef/client-library';

import { useDatasetMediaDetailsQuery } from '@/serverStore/dataset';
import useNewStyles from './newStyles';
import GroupsSvg from '../../images/groups.svg';
import ClassificationSvg from '../../images/project/classification.svg';
import ObjectDetectionSvg from '../../images/project/object_detection.svg';
import SegmentationSvg from '../../images/project/segmentation.svg';
import ModelCountSvg from '../../images/project/model_count.svg';
import AnomalyDetectionSvg from '../../images/project/anomaly_detection.svg';
import SegmentationInstantLearningSvg from '../../images/project/segmentation_instant_learning.svg';
import ProjectFallbackImagePng from '../../images/project/project_fallback_image.png';
import FilterIcon from '@material-ui/icons/Filter';
import classNames from 'classnames';
import { useGetProjectStatsByOrgIdQuery } from '@/serverStore/projects';
import MoreIconMenu, { MoreIconMenuItemKeys } from './MoreIconMenu';
import EditProjectNameTextField from '../Text/EditProjectNameTextField';
import DeleteProjectDialog from '../Dialogs/DeleteProjectDialog';
import { useSnackbar } from 'notistack';
import AddUsersToProjectDialog from '../AddUsersToProjectDialog/AddUsersToProjectDialog';

const DEFAULT_PROJECT_CARD_BACKGROUND_COLOR = greyModernScale[100];

const PROJECT_CARD_BACKGROUND_COLOR_LOOKUP = {
  [LabelType.AnomalyDetection]: pinkScale[25],
  [LabelType.BoundingBox]: blueScale[100],
  [LabelType.Classification]: orangeScale[100],
  [LabelType.Segmentation]: purpleScale[100],
  [LabelType.SegmentationInstantLearning]: greenScale[50],
};

const PROJECT_CARD_TEXT_COLOR_LOOKUP = {
  [LabelType.AnomalyDetection]: pinkScale[900],
  [LabelType.BoundingBox]: blueScale[900],
  [LabelType.Classification]: orangeScale[900],
  [LabelType.Segmentation]: purpleScale[900],
  [LabelType.SegmentationInstantLearning]: greenScale[900],
};

export const PROJECT_TYPE_ICON_LOOKUP = {
  [LabelType.AnomalyDetection]: (
    <Tooltip title={t('Anomaly Detection')} arrow placement="top">
      <img src={AnomalyDetectionSvg} />
    </Tooltip>
  ),
  [LabelType.BoundingBox]: (
    <Tooltip title={t('Object Detection')} arrow placement="top">
      <img src={ObjectDetectionSvg} />
    </Tooltip>
  ),
  [LabelType.Classification]: (
    <Tooltip title={t('Classification')} arrow placement="top">
      <img src={ClassificationSvg} />
    </Tooltip>
  ),
  [LabelType.Segmentation]: (
    <Tooltip title={t('Segmentation')} arrow placement="top">
      <img src={SegmentationSvg} />
    </Tooltip>
  ),
  [LabelType.SegmentationInstantLearning]: (
    <Tooltip title={t('Visual Prompting')} arrow placement="top">
      <img src={SegmentationInstantLearningSvg} />
    </Tooltip>
  ),
};

export interface ProjectCardProps {
  project: Project;
  ownerUser?: User;
  isCurrentUserOwner: boolean;
  style?: CSSProperties;
  onClick?: (projectId: number) => void | Promise<void>;
  hideMoreActionButton?: boolean;
}

const useLocalStyles = makeStyles<Theme, { labelType?: LabelType | null }, string>(() => ({
  container: {
    '& .details': {
      transition: 'all 0.2s ease-in',
    },
    '&:hover .details': {
      backgroundColor: props =>
        PROJECT_CARD_BACKGROUND_COLOR_LOOKUP[props.labelType!] ??
        DEFAULT_PROJECT_CARD_BACKGROUND_COLOR,
      color: props => PROJECT_CARD_TEXT_COLOR_LOOKUP[props.labelType!],
    },
  },
}));

export const ProjectCard: React.FC<ProjectCardProps> = ({
  project,
  style,
  onClick,
  ownerUser,
  isCurrentUserOwner,
  hideMoreActionButton = false,
}) => {
  const styles = useNewStyles();
  const localStyles = useLocalStyles({ labelType: project.labelType });
  const { enqueueSnackbar } = useSnackbar();
  const { data: statsById } = useGetProjectStatsByOrgIdQuery();
  const { data: mediaDetails, error: mediaDetailsError } = useDatasetMediaDetailsQuery({
    datasetId: project.datasetId,
    mediaId: project.coverMediaId,
  });

  const [moreIconAnchorEl, setMoreIconAnchorEl] = useState<HTMLElement | null>(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isInviteTeammebersDialogOpen, setIsInviteTeammembersDialogOpen] = useState(false);
  const [isDeleteProjectDialogOpen, setIsDeleteProjectDialogOpen] = useState(false);

  const handleDeleteProjectDialogClose = () => setIsDeleteProjectDialogOpen(false);

  const handleMoreIconMenuClick = (key: MoreIconMenuItemKeys) => {
    switch (key) {
      case MoreIconMenuItemKeys.rename:
        setIsEditingName(true);
        break;
      case MoreIconMenuItemKeys.invite:
        setIsInviteTeammembersDialogOpen(true);
        break;
      case MoreIconMenuItemKeys.delete:
        setIsDeleteProjectDialogOpen(true);
        break;
    }
  };

  return (
    <>
      {isInviteTeammebersDialogOpen ? (
        <AddUsersToProjectDialog
          projectId={project.id}
          open={isInviteTeammebersDialogOpen}
          onCancel={() => setIsInviteTeammembersDialogOpen(false)}
        />
      ) : null}
      {isCurrentUserOwner && (
        <DeleteProjectDialog
          project={project}
          open={isDeleteProjectDialogOpen}
          handleClose={handleDeleteProjectDialogClose}
        />
      )}
      <Card
        id="project_card"
        data-testid="project_card"
        onClick={() => onClick?.(project.id)}
        className={classNames(styles.container, localStyles.container)}
        style={style}
      >
        <Grid container wrap="nowrap" className={styles.imageContainer}>
          {project?.datasetId && project?.coverMediaId && !mediaDetailsError ? (
            <Img
              src={getThumbnail(mediaDetails, 'large')}
              fallbackSrc={mediaDetails?.url}
              className={styles.image}
              crossOrigin="use-credentials"
            />
          ) : (
            <img src={ProjectFallbackImagePng} className={styles.image} />
          )}
        </Grid>

        <Box className={cx(styles.details, 'details')}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box className={styles.detailsName}>
              <Box className={styles.projectTypeIcon}>
                {project.labelType && PROJECT_TYPE_ICON_LOOKUP[project.labelType]}
              </Box>
              {isEditingName && isCurrentUserOwner ? (
                <EditProjectNameTextField
                  name={project.name}
                  projectId={project.id}
                  setIsEditingName={setIsEditingName}
                />
              ) : (
                <Typography variant="h3">{project.name}</Typography>
              )}
            </Box>
            {isCurrentUserOwner && !hideMoreActionButton ? (
              <>
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    setMoreIconAnchorEl(e.currentTarget);
                  }}
                  size="small"
                  id="more-icon-button"
                >
                  <MoreHorizIcon />
                </IconButton>
                <MoreIconMenu
                  anchorEl={moreIconAnchorEl}
                  hideInvite={!project.inviteOnly}
                  onClose={() => setMoreIconAnchorEl(null)}
                  onBlur={() => setMoreIconAnchorEl(null)}
                  onItemClick={(e, key) => {
                    e.stopPropagation();
                    setMoreIconAnchorEl(null);
                    if (!isCurrentUserOwner) {
                      enqueueSnackbar(t('Only project owner can perform this operation'), {
                        variant: 'info',
                      });
                      return;
                    }
                    handleMoreIconMenuClick(key);
                  }}
                />
              </>
            ) : null}
          </Box>

          <Box marginTop={3} display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Box display="flex" alignItems="center" marginRight={2}>
                {project.inviteOnly ? (
                  <Tooltip
                    title={t(
                      'This is a private project that can only be seen by you and invited users.',
                    )}
                    arrow
                  >
                    <LockOutlinedIcon className={styles.privateProjectIcon} />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={t(
                      'This is a public project that can be seen by any user within your organization.',
                    )}
                    arrow
                  >
                    <img src={GroupsSvg} />
                  </Tooltip>
                )}
              </Box>
              {ownerUser && (
                <Tooltip
                  title={
                    <>
                      {ownerUser.name} {ownerUser.lastName}
                      <br />
                      {ownerUser.email}
                    </>
                  }
                  arrow
                >
                  <Typography variant="body1" className={styles.textColor}>
                    {t('Created by {{ownerName}}', {
                      ownerName: isCurrentUserOwner ? t('You') : ownerUser.name,
                    })}
                  </Typography>
                </Tooltip>
              )}
            </Box>
            {statsById && project?.datasetId && (
              <Box className={styles.statsContainer}>
                <Tooltip
                  title={t(
                    `${statsById.mediaCountByDatasetId[project.datasetId] ?? 0} images, ${
                      statsById.labeledMediaCountByDatasetId[project.datasetId] ?? 0
                    } labeled`,
                  )}
                  arrow
                >
                  <Box className={styles.statLabel}>
                    <FilterIcon fontSize="small" />
                    <Typography variant="body1" className={styles.textColor}>
                      {statsById.mediaCountByDatasetId[project.datasetId] ?? 0}
                    </Typography>
                  </Box>
                </Tooltip>
                <Tooltip
                  title={t(
                    `${statsById.modelCountByProjectId[project.id] ?? 0} ${
                      (statsById.modelCountByProjectId[project.id] ?? 0) > 1 ? 'models' : 'model'
                    }`,
                  )}
                  arrow
                >
                  <Box display="flex" alignItems="center">
                    <img src={ModelCountSvg} width={20} height={20} />
                    <Typography variant="body1" className={styles.textColor}>
                      {statsById.modelCountByProjectId[project.id] ?? 0}
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default ProjectCard;

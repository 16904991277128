/** only used for parameter validation modules **/
import { ObjectRule, IntegerRule, NumberRule, EnumRule, ArrayRule } from './parameter';

export enum ModelArch {
  ResNet = 'ResNet',
  UNet = 'UNet',
  RetinaNet = 'RetinaNet',
  AFNet = 'AFNet',
  InstantNet = 'InstantNet',
  SegmentationNet = 'SegmentationNet',
  ClassificationNet = 'ClassificationNet',
}

export interface LargeImageLimits {
  thresholdArea: number;
  minArea: number;
  maxArea: number;
}

type LearningParamsRule = ObjectRule & {
  rule?: {
    epochs?: IntegerRule;
  };
  default?: {
    epochs?: number;
  };
};

type BackboneParamsRule = ObjectRule & {
  rule?: {
    name?: EnumRule<string>;
  };
  default?: {
    name?: string;
  };
};

type NmsParamsRule = ObjectRule & {
  rule?: {
    iou_threshold?: NumberRule;
  };
  default?: {
    iou_threshold?: number;
  };
};

type DatasetParamsRule = ObjectRule & {
  rule?: {
    mediaLabeled?: IntegerRule;
  };
  default?: {
    mediaLabeled?: number;
  };
};
export interface ModelArchRules {
  archClass: EnumRule<string>;
  regime: EnumRule<string>;
  learningParams: LearningParamsRule;
  backboneParams: BackboneParamsRule;
  nmsParams: NmsParamsRule;
  datasetParams: DatasetParamsRule;
  preprocessingParams: ObjectRule;
  preprocessingDefaultTransforms: ArrayRule;
  augmentationParams: ObjectRule;
  augmentationDefaultTransforms: ArrayRule;
}

export type ModelArchValues = Partial<Record<keyof ModelArchRules, any>>;

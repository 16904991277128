import { BaseAPI } from './base_api';
import {
  OrgDevice,
  ProjectId,
  DeviceId,
  ReportDays,
  ReportKind,
  EdgeDevice,
  EdgeRecord,
  CLApp,
  CloudParams,
  EndpointType,
  EndpointListItem,
  RegisteredModelId,
  BundleStatusItem,
} from '@clef/shared/types';
import { API_GATEWAY_URL } from '../constants';

export const prefix = 'clp';

/**
 * CLP - CL Provisioning service
 * Responsiblities:
 * - List Devices & Applications for an Edge Project
 * - List Devices for an organization
 * - Get device status
 * - Deploy to devices & apps
 * - Providing packaged CL App for inference
 * - Heartbeat & model deployment (CL App endpoint)
 * - [Temporary] Proxies requests to edge api until its deprecated and merged.
 */
class CLPAPI extends BaseAPI {
  async listEdgeDevices(edgeProjectId: ProjectId): Promise<EdgeDevice[]> {
    // TODO: resolve edge timeout fix
    const result = await this.get(
      `edge/list_devices?project_id=${edgeProjectId}`,
      undefined,
      true,
    ).catch((e: any) => {
      // eslint-disable-next-line no-console
      console.error(e);
      return Promise.resolve([]);
    });
    return result;
  }

  async listDevices(edgeProjectId: ProjectId): Promise<EdgeRecord[]> {
    return this.get(`list_devices?project_id=${edgeProjectId}`, undefined, true);
  }

  async listOrgDevices(params: {
    projectId?: ProjectId;
    sortOrder: 'asc' | 'desc';
    sortBy: 'id' | 'creationTime' | 'owner' | 'status' | 'updatedTime';
    offset: number;
    limit: number;
  }): Promise<{ totalItems: number; apps: OrgDevice[] }> {
    return this.get('v2/apps', params, true);
  }

  async listApps(edgeProjectId: ProjectId): Promise<CLApp[]> {
    return this.get(`v1/apps/all?project_id=${edgeProjectId}`, undefined, true);
  }

  async postDeployNow(edgeProjectId: ProjectId) {
    return this.get(`edge/release_pipeline?project_id=${edgeProjectId}`, undefined, true);
  }

  async getDevicesStatus(edgeProjectId: ProjectId) {
    return this.get(`edge/devices_status?project_id=${edgeProjectId}`, undefined, true);
  }

  async deleteApp(appId: string, edgeProjectId?: ProjectId) {
    return this.delete(`v1/apps?id=${appId}${edgeProjectId ? `&project_id=${edgeProjectId}` : ''}`);
  }

  async getDeviceReport(
    deviceId: DeviceId,
    edgeProjectId: ProjectId,
    days: ReportDays,
    reportKind: ReportKind,
  ) {
    return this.get(
      `v1/report?device_id=${deviceId}&project_id=${edgeProjectId}&days=${days}&kind=${reportKind}'`,
      undefined,
      true,
    );
  }

  async deployToDevices(
    edgeProjectId: number,
    deviceIds: string[],
    appIds: string[],
    cloudDeviceIds: string[],
    modelId: string,
    modelPath: string,
    modelDesc: string,
  ) {
    const response = await Promise.all([
      ...(deviceIds?.length
        ? [
            this.post(
              `edge/deploy_to_devices?project_id=${edgeProjectId}&app_ids=${deviceIds
                .map(encodeURIComponent)
                .join(',')}&model_path=${modelPath}&model_desc=${modelDesc}`,
            ),
          ]
        : []),
      ...(cloudDeviceIds?.length
        ? [
            this.post(
              `deploy_apps?project_id=${edgeProjectId}&app_ids=${cloudDeviceIds
                .map(encodeURIComponent)
                .join(',')}&model_id=${modelId}`,
            ),
          ]
        : []),
      ...(appIds?.length
        ? [
            this.post(
              `deploy_apps?project_id=${edgeProjectId}&app_ids=${appIds
                .map(encodeURIComponent)
                .join(',')}&model_id=${modelId}`,
            ),
          ]
        : []),
    ]);
    return response;
  }
  async downloadApp(target: string) {
    return this.get(`download_app?target=${target}`, undefined, true);
  }

  async createDevice(params: { id: DeviceId; cloud?: CloudParams }) {
    const res = await this.postJSON('v2/apps', params);
    return res;
  }

  async getSCAppList(projectId: ProjectId) {
    return this.get(`list_sc_apps`, { project_id: projectId }, true);
  }

  async getCLAppList(): Promise<string[]> {
    return this.get(`list_cl_apps`, undefined, true);
  }

  async getEndpoints(projectId: ProjectId): Promise<EndpointListItem[]> {
    return this.get(`endpoint/list?projectId=${projectId}`);
  }

  async createEndpoint(params: {
    projectId: ProjectId;
    endpointName: string;
    endpointType: EndpointType;
  }) {
    return this.putJSON(`endpoint/create`, params) as unknown as Promise<{
      endpointId: string;
      endpointName: string;
      orgId: number;
      projectId: number;
    }>;
  }

  async deployModelToEndpoint(params: {
    projectId: ProjectId;
    endpointId: string;
    modelId: string;
    threshold: number | undefined;
  }) {
    return this.postJSON(`endpoint/deploy`, params);
  }

  async getBundleStatus(params: {
    projectId: ProjectId;
    modelId: RegisteredModelId;
    threshold: number;
  }): Promise<BundleStatusItem> {
    const { projectId, modelId, threshold } = params;
    return this.get(`bundle/${projectId}/${modelId}/${threshold}/status`);
  }
}
export default new CLPAPI(prefix, API_GATEWAY_URL);

export function hexToRgb(hex: string | null | undefined) {
  const fallback = { r: 0, g: 0, b: 0 };
  if (!hex) return fallback;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : fallback;
}

export function isDark(rgb: string | null | undefined | { r: number; g: number; b: number }) {
  if (typeof rgb === 'string' || rgb === null || rgb === undefined) {
    rgb = hexToRgb(rgb);
  }
  const { r, g, b } = rgb;
  const brightness = r * 0.299 + g * 0.587 + b * 0.114;
  return brightness < 150;
}

export const hexToRgba = (hex: string | null | undefined, alpha: number = 1) => {
  const { r, g, b } = hexToRgb(hex);

  return `rgba(${r},${g},${b},${alpha})`;
};

export const getRandomHex = () =>
  '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');

import React, { useState } from 'react';
import { Box, Grid, Paper, Tab, Tabs, makeStyles } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { IconButton, Typography } from '@clef/client-library';
import Close from '@material-ui/icons/Close';
import { EvaluationSetItem } from '@/api/evaluation_set_api';
import { DatasetVersion, RegisteredModel } from '@clef/shared/types';
import PerformanceReportPanel from './PerformanceReportPanel';
import TrainingInformationPanel from './TrainingInformationPanel';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useModelStatusQuery } from '@/serverStore/projectModels';
import {
  isModelTrainingFailed,
  isModelTrainingInProgress,
} from '@/store/projectModelInfoState/utils';
import ModelTrainingInProgressPanel from './ModelTrainingInProgressPanel';
import ModelNameEditField from '@/pages/model_iteration/componentsV2/ModelDetailsPanel/ModelNameEditField';
import { useModels } from '@/hooks/useModels';
import { BundleInfo } from '@/api/model_api';
import closeFullScreen from '@/images/instant_learning/close_full_screen.svg';
import openInFull from '@/images/instant_learning/open_in_full.svg';

export type ModelDetailsPanelProps = {
  model: RegisteredModel & { bundles?: BundleInfo[] };
  datasetVersion: DatasetVersion;
  threshold: number;
  evaluationSet?: EvaluationSetItem;
  trainDevTestColumnEvaluationSets?: EvaluationSetItem[];
  otherEvaluationSets?: EvaluationSetItem[];
  onChangeEvaluationSet?: (e: EvaluationSetItem) => void;
  onClose: () => void;
  allowExpand?: boolean;
  isExpanded?: boolean;
  onExpandToggle?: () => void;
};

const useStyles = makeStyles(theme => ({
  pagerContainer: {
    minHeight: '100%',
  },
  tabsRoot: {
    minHeight: 28,
  },
  tabRoot: {
    fontFamily: theme.typography.body1.fontFamily,
    alignItems: 'flex-start',
    fontWeight: 'bold',
    minHeight: 28,
    minWidth: 72,
    padding: 0,
    margin: theme.spacing(0, 6, 0, 0),
  },
  modelDetailContainer: {
    width: '100%',
    wordWrap: 'break-word',
  },
  drawerOps: {
    position: 'absolute',
    top: theme.spacing(5),
    right: theme.spacing(8),
    display: 'flex',
    gap: theme.spacing(2),
  },
  openInFullImgButton: {
    padding: theme.spacing(2.5),
  },
  openInFullImg: {
    width: '12px',
    marginRight: theme.spacing(2),
  },
}));

const ModelDetailsPanel: React.FC<ModelDetailsPanelProps> = props => {
  const {
    model,
    datasetVersion,
    evaluationSet,
    threshold: initialThreshold,
    trainDevTestColumnEvaluationSets,
    otherEvaluationSets,
    onChangeEvaluationSet,
    onClose,
    allowExpand = false,
    isExpanded = false,
    onExpandToggle,
  } = props;
  const { findModels } = useModels();
  const registeredModel = findModels(model.id);

  const styles = useStyles();
  const [activeTabValue, setActiveTabValue] = useState(evaluationSet ? 1 : 0);
  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTabValue(newValue);
  };

  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const { data: modelStatusRes } = useModelStatusQuery(projectId, model?.id);
  const { status: modelStatus, metricsReady } = modelStatusRes ?? {};

  const getPanelContent = () => {
    const isTrainingInProgress = isModelTrainingInProgress(modelStatus, metricsReady);
    if (isTrainingInProgress) {
      return <ModelTrainingInProgressPanel model={model} datasetVersion={datasetVersion} />;
    } else if (isModelTrainingFailed(model.status)) {
      return <TrainingInformationPanel model={model} datasetVersion={datasetVersion} />;
    } else {
      return (
        <>
          <Tabs
            classes={{ root: styles.tabsRoot }}
            indicatorColor="primary"
            textColor="primary"
            value={activeTabValue}
            onChange={handleTabChange}
          >
            <Tab classes={{ root: styles.tabRoot }} label={t('Training Information')} />
            <Tab classes={{ root: styles.tabRoot }} label={t('Performance Report')} />
          </Tabs>
          {activeTabValue === 0 && (
            <TrainingInformationPanel model={model} datasetVersion={datasetVersion} />
          )}
          {activeTabValue === 1 && (
            <Box paddingTop={6}>
              <PerformanceReportPanel
                model={model}
                threshold={initialThreshold}
                evaluationSet={evaluationSet}
                trainDevTestColumnEvaluationSets={trainDevTestColumnEvaluationSets}
                otherEvaluationSets={otherEvaluationSets}
                onChangeEvaluationSet={onChangeEvaluationSet}
              />
            </Box>
          )}
        </>
      );
    }
  };

  return (
    <Paper className={styles.pagerContainer}>
      <Box paddingX={8} paddingY={7} position="relative" id="model-details-panel">
        <Box className={styles.drawerOps}>
          {allowExpand && (
            <Button
              id="expand-model-details-panel"
              variant="outlined"
              color="default"
              size="small"
              className={styles.openInFullImgButton}
              onClick={() => onExpandToggle?.()}
            >
              <img
                src={isExpanded ? closeFullScreen : openInFull}
                className={styles.openInFullImg}
              />
              {isExpanded ? t('Collapse') : t('Expand')}
            </Button>
          )}
          <IconButton id="close-model-details-panel" onClick={onClose} size="small">
            <Close />
          </IconButton>
        </Box>
        <Box marginBottom={5}>
          <Grid container alignItems="center">
            <ModelNameEditField
              initialModelName={model.modelName ?? 'Untitled model'}
              modelId={model.id}
            />
            {registeredModel?.details && (
              <Box marginTop={5} className={styles.modelDetailContainer}>
                <Typography variant="body_regular">{registeredModel?.details}</Typography>
              </Box>
            )}
          </Grid>
        </Box>
        {getPanelContent()}
      </Box>
    </Paper>
  );
};

export default ModelDetailsPanel;

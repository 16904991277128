import TaskAPI from '../../api/task_api';
import fetchHookFactory from './fetchHookFactory';
import { TaskCreationOptions } from 'src/components/TaskCreationDialog/types';
import {
  TaskId,
  LabelingTaskReviewResult,
  TaskPurpose,
  MediaId,
  ReviewType,
} from '@clef/shared/types';

export const [useTaskDefectConsensusApi] = fetchHookFactory(
  async (projectId: number) => (await TaskAPI.fetchLDBTasks(projectId!)).data,
  'stale-while-revalidate',
);

export const [useTaskApi, refreshTaskInfo] = fetchHookFactory(
  async (taskId: number) => (await TaskAPI.getTask(taskId)).data,
  'cache',
);

export const [useMyTask, refreshUseMyTask] = fetchHookFactory(
  async (params: { taskType: TaskPurpose; projectId: number }) =>
    (await TaskAPI.fetchMyLabelingTask(params.taskType, params.projectId)).data,
  'stale-while-revalidate',
);

// fetch media list (size of batch) to user to label
export const [useUserTaskMediaLabelBatch, refreshUserTaskMediaLabelBatch] = fetchHookFactory(
  async (taskId: number) => (await TaskAPI.fetchUserTaskMediaLabelBatch(taskId)).data,
  'always-refresh',
);

// fetch task stats when user is in labeling task
export const [useUserTaskStats, refreshUserTaskStats] = fetchHookFactory(
  async (taskId: number) => (await TaskAPI.fetchUserTaskStats(taskId)).data,
  'always-refresh',
);

// fetch media for consensus review
export const [useTaskLabelingMediaToReview, refreshTaskLabelingLabelingMediaToReview] =
  fetchHookFactory(
    async ({
      taskId,
      sortOrder,
      type,
    }: {
      taskId: number;
      sortOrder?: string;
      type?: ReviewType;
    }) => (await TaskAPI.getLabelingMediaToReview(taskId, sortOrder, type)).data,
    'always-refresh',
  );

export const createTaskApi = async (taskOptions: Partial<TaskCreationOptions>) => {
  const response = await TaskAPI.createTask(taskOptions);
  return response;
};

export const cancelTaskApi = async (taskId: TaskId) => {
  const response = await TaskAPI.cancel(taskId);
  refreshUseMyTask({ keys: 'refresh-all' });
  return response;
};

export const postTaskCompleteBatchApi = async (taskId: TaskId, mediaIds: number[]) => {
  const response = await TaskAPI.postTaskCompleteBatch(taskId, mediaIds);
  return response;
};

export const postSubmitLabelingReviewResult = async (
  datasetId: number,
  taskId: TaskId,
  reviewerId: string,
  reviewResult: LabelingTaskReviewResult,
  reassignMediaIds?: MediaId[],
): Promise<{
  failedReassignedMediaId: number[];
  haveMediaToReview: boolean;
  taskCompleted: boolean;
}> => {
  const res = await TaskAPI.submitLabelingReviewResult(
    datasetId,
    taskId,
    reviewerId,
    reviewResult,
    reassignMediaIds,
  );
  return res.data;
};

export const postReassignMedia = async (datasetId: number, taskId: TaskId, mediaIds: MediaId[]) => {
  const res = await TaskAPI.reassignMedia(datasetId, taskId, mediaIds);
  return res.data.taskCompleted;
};

export const [useTasksMediaStats, refreshTaskMediaStats] = fetchHookFactory(
  (params: { projectId: number; purposes: TaskPurpose[]; taskIds?: TaskId[] }) => {
    const { projectId, purposes, taskIds } = params;
    return TaskAPI.fetchTasksMediaStats(projectId, purposes, taskIds);
  },
  'always-refresh',
);

export const [useAllTasks, refreshAllTasks] = fetchHookFactory((projectId: number) => {
  return TaskAPI.fetchTasks(projectId);
}, 'always-refresh');

import React, { useRef } from 'react';
import cx from 'classnames';

import { Button, useKeyPress } from '@clef/client-library';

import { useStyles } from './styles';

export interface PrimaryButtonProps {
  id: string;
  text: string;
  className?: string;
  disabled?: boolean;
  isPending?: boolean;
  onClick?: () => void | Promise<void>;
  enableKeyPressListener?: boolean;
  useNewUi?: boolean;
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  id,
  text,
  className,
  disabled,
  isPending,
  onClick,
  enableKeyPressListener = true,
  useNewUi = false,
}) => {
  const styles = useStyles();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useKeyPress(
    'enter',
    enableKeyPressListener
      ? () => {
          if (buttonRef.current) {
            buttonRef.current.click();
          }
        }
      : undefined,
  );

  return (
    <Button
      id={id}
      ref={buttonRef}
      variant="contained"
      color="primary"
      type={onClick ? undefined : 'submit'}
      className={cx(styles.primaryButton, useNewUi && styles.newPrimaryButton, className)}
      spinnerClassName={styles.loadingSpinner}
      disabled={disabled}
      isPending={isPending}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

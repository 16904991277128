import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Grid, CircularProgress, Link } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import { useSnackbar } from 'notistack';
import cx from 'classnames';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { ApiResponseLoader } from '@clef/client-library';

import { useStyles } from './newStyles';
import AuthAPI from '../../api/auth_api';
import AccountAPI from '../../api/account_api';
import { LandingLensLogo, OrSeparator } from '../../components/Auth/Common';
import { PrimaryButton } from '../../components/Auth/Button';
import { useOrgs } from '../../hooks/api/useAccountApi';
import { OrgsSection } from './components/OrgsSection';
import {
  loginOrgUserSuccess,
  loginOrgUserFailure,
  signOut,
  refreshAuthenticatedStates,
} from '../../store/newLoginState/actions';
import { CLEF_PATH, JoinOrgOpener } from '../../constants/path';
import { AuthPageLayout } from '../../components/Layout/AuthPageLayout';
import { useAppDispatch } from '../../store';
import { isLandingLensSupportEmail } from '../../utils';
import { resetSelectedProject } from '../../store/projectState';
import { refreshBillingDetails, refreshInvoiceHistory } from '@/hooks/api/useSubscriptionApi';

export const JoinOrg: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const searchParams = useMemo(
    () => new URLSearchParams(history?.location?.search),
    [history?.location?.search],
  );
  const token = useMemo(() => searchParams.get('token'), [searchParams]);
  const opener = useMemo(
    () => (searchParams.get('opener') as JoinOrgOpener) ?? null,
    [searchParams],
  );
  const [isAutoSigning, setIsAutoSigning] = useState(false);
  const [orgsData, orgsLoading, orgsError] = useOrgs(token ? undefined : {});

  const onSsoOrgUserSignin = useCallback(
    async (email: string) => {
      dispatch(resetSelectedProject());
      try {
        const redirectUrl = await AuthAPI.samlLogin(email);
        window.open(redirectUrl, '_self', 'noopener,noreferrer');
      } catch (e) {
        enqueueSnackbar(e?.message, { variant: 'error', autoHideDuration: 12000 });
      }
    },
    [dispatch, enqueueSnackbar],
  );

  const onOrgUserSignin = useCallback(
    async (orgId: number, userId: string) => {
      dispatch(resetSelectedProject());
      try {
        const { data } = await AccountAPI.loginOrgUser(orgId, userId);

        // After dispatching this action, auth router will auto redirect to app page
        dispatch(loginOrgUserSuccess(data));

        refreshBillingDetails({ keys: 'refresh-all' });
        refreshInvoiceHistory({ keys: 'refresh-all' });

        // Clear the query params
        history.replace(history.location.pathname);
      } catch (e) {
        enqueueSnackbar(
          t(`Sign in to the organization failed with ${e?.message ?? t('unknown error')}`),
          { variant: 'error', autoHideDuration: 12000 },
        );
        dispatch(loginOrgUserFailure(e));
      }
    },
    [dispatch, enqueueSnackbar, history],
  );

  const onOrgUserJoin = useCallback(
    async (token: string | undefined) => {
      dispatch(resetSelectedProject());
      if (token) {
        try {
          await AccountAPI.joinOrg(token);

          // After dispatching this action, auth router will auto redirect to app page
          dispatch(loginOrgUserSuccess(null));

          refreshBillingDetails({ keys: 'refresh-all' });
          refreshInvoiceHistory({ keys: 'refresh-all' });

          // Clear the query params
          history.replace(history.location.pathname);
        } catch (e) {
          enqueueSnackbar(e?.message, { variant: 'error', autoHideDuration: 12000 });
          dispatch(loginOrgUserFailure(e));

          // Clear the query params
          history.replace(history.location.pathname);
        }
      }
    },
    [dispatch, enqueueSnackbar, history],
  );

  useEffect(() => {
    // If there is token in query params, auto join the org
    if (token) {
      onOrgUserJoin(token);
    }
  }, [onOrgUserJoin, token]);

  useEffect(() => {
    // If there is no org, directly go to create org page
    if (
      orgsData?.activeUsers &&
      orgsData?.pendingUsers &&
      orgsData.activeUsers.length + orgsData.pendingUsers.length === 0
    ) {
      history.push(CLEF_PATH.login.createOrg + location.search);
    }
  }, [history, orgsData?.activeUsers, orgsData?.pendingUsers]);

  useEffect(() => {
    const disableAutoSignin = new URLSearchParams(history?.location?.search).get(
      'disableAutoSignin',
    );

    // If auto redirect is turned on and there is only one org to sign in, auto sign in to the org
    if (
      !disableAutoSignin &&
      orgsData?.activeUsers &&
      orgsData?.pendingUsers &&
      orgsData.activeUsers.length === 1 &&
      orgsData.pendingUsers.length === 0
    ) {
      setIsAutoSigning(true);

      if (
        orgsData.activeUsers[0].organization.ssoConfig &&
        !isLandingLensSupportEmail(orgsData.activeUsers[0].email)
      ) {
        onSsoOrgUserSignin(orgsData.activeUsers[0].email).finally(() => {
          setIsAutoSigning(false);
        });
      } else {
        onOrgUserSignin(
          orgsData.activeUsers[0].organization.id,
          orgsData.activeUsers[0].id,
        ).finally(() => {
          setIsAutoSigning(false);
        });
      }
    }
  }, [
    history?.location?.search,
    onOrgUserSignin,
    onSsoOrgUserSignin,
    orgsData?.activeUsers,
    orgsData?.pendingUsers,
  ]);

  const onBack = useCallback(async () => {
    if (opener === JoinOrgOpener.Login) {
      await dispatch(signOut());

      history.push(CLEF_PATH.login.main + location.search);
    } else {
      localStorage.setItem('clef_is_login', '1');
      dispatch(refreshAuthenticatedStates());
      history.goBack();
    }
  }, [dispatch, history, opener]);

  return (
    <AuthPageLayout metaTitle={t('Join Organization')}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={styles.container}
      >
        <LandingLensLogo />

        {opener !== null && (
          <IconButton id="go-back" onClick={onBack} className={styles.backButton}>
            <ArrowBackIcon />
          </IconButton>
        )}

        <Typography variant="h1" className={cx(styles.helperText, styles.joinOrgTitle)}>
          {t('Select Your Organization')}
        </Typography>

        {token || isAutoSigning ? (
          <CircularProgress size="26px" className={styles.marginBottom8} />
        ) : (
          <ApiResponseLoader
            response={orgsData}
            loading={orgsLoading}
            error={orgsError}
            defaultHeight="200px"
          >
            {orgsLoaded => {
              return (
                <div className={styles.orgsSectionContent}>
                  <OrgsSection
                    users={orgsLoaded.activeUsers}
                    onItemClick={async ({ orgId, userId, isSsoOrg, email }) => {
                      if (isSsoOrg && !isLandingLensSupportEmail(email)) {
                        // For enterprise SSO, the same API will handle both login and joining
                        await onSsoOrgUserSignin(email);
                      } else {
                        await onOrgUserSignin(orgId, userId);
                      }
                    }}
                  />
                  <OrgsSection
                    users={orgsLoaded.pendingUsers}
                    onItemClick={async ({ token, isSsoOrg, email }) => {
                      if (isSsoOrg && !isLandingLensSupportEmail(email)) {
                        // For enterprise SSO, the same API will handle both login and joining
                        await onSsoOrgUserSignin(email);
                      } else {
                        await onOrgUserJoin(token);
                      }
                    }}
                  />
                </div>
              );
            }}
          </ApiResponseLoader>
        )}

        {(orgsData?.activeUsers.length ?? 0) + (orgsData?.pendingUsers.length ?? 0) > 0 && (
          <OrSeparator />
        )}

        <PrimaryButton
          id="create-new-organization"
          text={t('Create Organization')}
          className={styles.marginBottom6}
          onClick={() => {
            dispatch(resetSelectedProject());
            history.push(CLEF_PATH.login.createOrg);
          }}
          useNewUi
        />

        <Grid
          container
          direction="row"
          className={cx(styles.autoWidth, styles.marginBottom8)}
          alignItems="center"
        >
          <Typography className={cx(styles.alreadyHaveAnAccountText, styles.marginRight1)}>
            {t("Don't see your organization?")}
          </Typography>

          <Link
            className={cx(styles.link, styles.newLink)}
            onClick={async () => {
              await dispatch(signOut());

              // Clear the query params
              history.replace(history.location.pathname);
            }}
          >
            {t('Try signing in with a different account')}
          </Link>
        </Grid>
      </Grid>
    </AuthPageLayout>
  );
};

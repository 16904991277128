import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, useFieldArray, UseFieldArrayProps } from 'react-hook-form';
import { Typography, Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { Button } from '@clef/client-library';
import { UserRole, User } from '@clef/shared/types';

import { CLEF_PATH } from '../../constants/path';
import AuthPageLayout from '../../components/Layout/AuthPageLayout';
import LoveLetterSvg from '../../images/auth/love-letter.svg';
import { inviteUser } from '../..//store/user_store';
import { LandingLensLogo } from '../../components/Auth/Common';
import { BaseTextField } from '../../components/Auth/TextField';
import { PrimaryButton } from '../../components/Auth/Button';
import AccountAPI from '../../api/account_api';
import { loginOrgUserSuccess, loginOrgUserFailure } from '../../store/newLoginState/actions';

const CONTENT_WIDTH = 320;

export const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(10),
  },
  image: {
    marginBottom: theme.spacing(7),
  },
  questionTitle: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(5),
  },
  normalInputBox: {
    width: CONTENT_WIDTH,
    marginBottom: theme.spacing(8),
  },
  submitButton: {
    width: CONTENT_WIDTH,
    height: 40,
    marginBottom: theme.spacing(3),
  },
  skipButton: {
    width: CONTENT_WIDTH,
    height: 40,
  },
}));

const FREE_PLAN_MAX_MEMBERS = 3;

interface FormValues {
  invites: { email: string }[];
}

export const InviteMember: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const searchParams = useMemo(
    () => new URLSearchParams(history?.location?.search),
    [history?.location?.search],
  );

  const [isInviting, setIsInviting] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const { control, errors, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      invites: [{ email: '' }],
    },
  });
  const { fields, append, remove } = useFieldArray<UseFieldArrayProps>({
    control,
    name: 'invites',
  });

  const _inviteUser = useCallback(
    async (email: string, userRole: string): Promise<boolean> => {
      try {
        await dispatch(inviteUser(email, userRole as UserRole));
        return true;
      } catch (e) {
        enqueueSnackbar(e.message, {
          variant: 'error',
          autoHideDuration: 12000,
        });
        return false;
      }
    },
    [dispatch, enqueueSnackbar],
  );

  const onInvite = useCallback(
    async ({ invites }: FormValues) => {
      setIsInviting(true);
      let localUser: User | null = user ? { ...user } : null;

      // Step 1 - sign in to the org if not authenticated
      if (!user) {
        try {
          const orgId = parseInt(searchParams.get('orgId') ?? '');
          const userId = searchParams.get('userId') ?? '';

          const { data } = await AccountAPI.loginOrgUser(orgId, userId);

          setUser(data);
          localUser = data;
        } catch (e) {
          enqueueSnackbar(
            t(`Sign in to the organization failed with ${e?.message ?? t('unknown error')}`),
            { variant: 'error', autoHideDuration: 12000 },
          );
          dispatch(loginOrgUserFailure(e));
          setIsInviting(false);

          // If failed, go to join org page
          history.push(CLEF_PATH.login.joinOrg);
          return;
        }
      }

      // Step 2 - invite members
      let allInvited = true;
      const successfulInviteIndices: number[] = [];
      await Promise.all(
        invites.map(async (invite: any, index) => {
          const success = await _inviteUser(invite.email, UserRole.Admin);
          if (!success) {
            allInvited = false;
          } else {
            successfulInviteIndices.push(index);
          }
        }),
      );

      setIsInviting(false);

      // If all invites are successful, reset form state and save the user profile to redux, which will auto
      // redirect to the landing/home page
      if (allInvited) {
        enqueueSnackbar(t('Invitations have been sent!'), {
          variant: 'success',
        });
        reset();
        dispatch(loginOrgUserSuccess(localUser));
      }
      // Otherwise, remove the successful invites from UI and stay at the current page
      else {
        successfulInviteIndices.forEach(inviteIndex => remove(inviteIndex));
      }
    },
    [_inviteUser, dispatch, enqueueSnackbar, history, remove, reset, searchParams, user],
  );

  const onSkip = useCallback(async () => {
    try {
      const orgId = parseInt(searchParams.get('orgId') ?? '');
      const userId = searchParams.get('userId') ?? '';

      const { data } = await AccountAPI.loginOrgUser(orgId, userId);
      dispatch(loginOrgUserSuccess(data));
    } catch (e) {
      enqueueSnackbar(
        t(`Sign in to the organization failed with ${e?.message ?? t('unknown error')}`),
        { variant: 'error', autoHideDuration: 12000 },
      );
      dispatch(loginOrgUserFailure(e));

      // If failed, go to join org page
      history.push(CLEF_PATH.login.joinOrg);
    }
  }, [dispatch, enqueueSnackbar, history, searchParams]);

  return (
    <AuthPageLayout metaTitle="Invite Member">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minWidth="100vw"
        minHeight="100vh"
        position="relative"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={CONTENT_WIDTH}
        >
          <LandingLensLogo />

          <Box width={500} display="flex" justifyContent="center">
            <Typography variant="h1" className={styles.title}>
              {t('Invite Your Teammates')}
            </Typography>
          </Box>

          <img src={LoveLetterSvg} className={styles.image} />

          <Typography className={styles.questionTitle}>
            {t('Woohoo! Invite your teammates to the org.')}
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit(onInvite)}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {fields.map((field, index) => {
              return (
                <BaseTextField
                  key={field.id}
                  label={t('Enter Email')}
                  rules={{
                    required: t('This is required.'),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address.',
                    },
                  }}
                  name={`invites[${index}].email`}
                  error={errors.invites?.[index]?.email}
                  control={control}
                  classes={{ root: styles.normalInputBox }}
                  deletable={fields.length > 1}
                  onDelete={() => remove(index)}
                />
              );
            })}

            <Box marginBottom={13} display="flex" width="100%" alignItems="center">
              <Button
                id="add-more-member"
                color="primary"
                onClick={() => append({ name: 'append' })}
                variant="text"
                disabled={fields.length >= FREE_PLAN_MAX_MEMBERS - 1}
              >
                {t('Add')}
              </Button>
              <Box marginLeft={2}>
                {t('{{remainingSeats}} seat left. {{moreSeats}}', {
                  remainingSeats: Math.max(0, FREE_PLAN_MAX_MEMBERS - 1 - fields.length),
                  moreSeats:
                    fields.length >= FREE_PLAN_MAX_MEMBERS - 1
                      ? t('Contact us for additional seats.')
                      : '',
                })}
              </Box>
            </Box>

            <PrimaryButton
              id="invite-members-confirm"
              text={t('Invite')}
              disabled={!!errors.invites?.find(invite => !!invite?.email)}
              isPending={isInviting}
              className={styles.submitButton}
              useNewUi
            />

            <Button
              id="invite-member-skip"
              className={styles.skipButton}
              color="primary"
              onClick={onSkip}
            >
              {t('Skip')}
            </Button>
          </Box>
        </Box>
      </Box>
    </AuthPageLayout>
  );
};

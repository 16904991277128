import { useState } from 'react';
import pLimit from 'p-limit';

import { Media, MediaType, TextFile, UploadType } from '@clef/shared/types';
import { pairMediaLabelFiles } from '../utils/upload_utils';
import { LIMIT_CONCURRENCY_SIZE } from '../constants';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import PictorAPI from '../api/pictor_api';

const useLocalMediaUpload = (
  onFileUploaded: (file: File, fileIdx: number) => void,
  uploadType: UploadType,
  onUploadFailed?: (fileIdx: number) => void,
) => {
  const [uploadedCount, setUploadedCount] = useState<number>(0);
  const { id: projectId, datasetId } = useGetSelectedProjectQuery().data ?? {};

  const uploadFiles = async (
    filesToUpload: File[],
    metadata: any,
    labelMediaStatus?: { mediaWithLabels: boolean; mediaWithNoLabels: boolean },
  ): Promise<Media[]> => {
    const limit = pLimit(LIMIT_CONCURRENCY_SIZE);
    let count = uploadedCount;
    const mediaLabelPairs = await pairMediaLabelFiles(filesToUpload);

    const medias = await Promise.all(
      Object.keys(mediaLabelPairs).map((key, idx) =>
        limit(async () => {
          try {
            mediaLabelPairs[key].approved =
              (mediaLabelPairs[key].mediaFiles!.length > 0 && mediaLabelPairs[key].labelFile) ||
              mediaLabelPairs[key].labelFile
                ? labelMediaStatus?.mediaWithLabels
                : labelMediaStatus?.mediaWithNoLabels;
            // upload medias function
            const uploadedMedias = async () => {
              if (!datasetId || !projectId) return;
              const uploadMedias = await Promise.all(
                mediaLabelPairs[key].mediaFiles!.map(async file => {
                  const uploadedMedia = await PictorAPI.upload(
                    file,
                    projectId,
                    datasetId,
                    undefined,
                    undefined,
                    metadata,
                    undefined,
                    uploadType,
                  );
                  return { ...uploadedMedia.data, mediaType: MediaType.Image } as Media;
                }),
              );
              return uploadMedias;
            };

            const uploadSucceed = (uploadFile: File, idx: number) => {
              onFileUploaded(uploadFile, idx);
              count = count + 1;
              setUploadedCount(count);
              return uploadFile;
            };

            const uploadedFiles: (Media | TextFile)[] = [];
            // upload medias
            if (mediaLabelPairs[key].mediaFiles!.length > 0) {
              const mediaFiles = await uploadedMedias();
              if (!mediaFiles) {
                onUploadFailed && onUploadFailed(idx);
                return undefined;
              }
              uploadedFiles.push(...mediaFiles);
              mediaLabelPairs[key].mediaFiles?.forEach(file => {
                uploadSucceed(file, idx);
              });
            }
            return uploadedFiles.filter(file => file.mediaType === MediaType.Image);
          } catch (e) {
            onUploadFailed && onUploadFailed(idx);
            return undefined;
          }
        }),
      ),
    );

    // @ts-ignore: https://github.com/microsoft/TypeScript/issues/20812
    return medias.flat().filter(Boolean);
  };
  const resetUploadedCount = () => {
    setUploadedCount(0);
  };

  return { uploadFiles, uploadedCount, resetUploadedCount };
};

export default useLocalMediaUpload;

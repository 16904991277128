import { dateToISOString } from '@/utils/date_utils';
import { ApiResponseLoader } from '@clef/client-library';
import { Box, Typography } from '@material-ui/core';
import { addDays } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import ChartEmptyState from '../components/ChartEmptyState';
import { useStyles } from './styles';
import { UsageParams, useUsageStyles } from './UsageSection';
import { useGetUsageImages } from '@/serverStore/usage';

export type TopProjectsUsageByImagesProps = {
  params: UsageParams;
};

const TopProjectsUsageByImages: React.FC<TopProjectsUsageByImagesProps> = ({ params }) => {
  const usageStyles = useUsageStyles();
  const styles = useStyles();
  const { startTime, endTime } = params;
  const {
    data: usageData,
    isLoading: loading,
    error,
  } = useGetUsageImages({
    fromTimestamp: startTime,
    toTimestamp: dateToISOString(addDays(new Date(endTime), 1)),
    pageSize: 10,
  });
  useEffect(() => {
    usageData?.sort((a, b) => b.mediaCount - a.mediaCount);
  }, [usageData]);

  const maxUsage = useMemo(() => {
    return Math.max(...(usageData || []).map(data => data.mediaCount));
  }, [usageData]);

  return (
    <Box marginTop={9}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={usageStyles.title} variant="h2">
          {t('Top Projects: by images')}
        </Typography>
      </Box>
      <Box marginTop={4}>
        <ApiResponseLoader loading={loading} response={usageData} error={error}>
          {() =>
            usageData?.length ? (
              <Box maxWidth={800}>
                {usageData.map((d, index) => {
                  const width = !d.mediaCount
                    ? '10px'
                    : `calc((100% - 120px) * ${d.mediaCount / maxUsage})`;
                  return (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      className={styles.topValueItem}
                    >
                      <Box className={styles.label}>
                        {d.projectName ? t(d.projectName) : `(${t('private project')})`}
                      </Box>
                      <Box className={styles.usageBox} display="flex" alignItems="center">
                        <Box
                          className={styles.topProjectsValue}
                          style={{ width: width, flex: `0 0 ${width}` }}
                        ></Box>
                        <Typography className={styles.label}>
                          {t('{{value}} Images', {
                            value: d.mediaCount,
                          })}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <ChartEmptyState
                chartType="horizontal_bar"
                title={t('You have not uploaded any images yet')}
                subtitle={t(
                  'Once images are uploaded, you can see projects with the most images here',
                )}
              />
            )
          }
        </ApiResponseLoader>
      </Box>
    </Box>
  );
};

export default TopProjectsUsageByImages;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { setImageFilters } from '../../utils';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { useImageEnhancerState } from './state';

const useStyles = makeStyles(() => ({
  progressBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1,
  },
}));

interface ImageComponentType {
  sourceUrl: string | undefined;
}
export interface EnhancedImageViewerProps {
  sourceUrl: string | undefined;
  imageWidth?: number | undefined;
  imageHeight?: number | undefined;
  children(object: ImageComponentType): React.ReactNode;
}

const EnhancedImageViewer: React.FunctionComponent<EnhancedImageViewerProps> = ({
  sourceUrl,
  imageWidth,
  imageHeight,
  children,
}) => {
  const styles = useStyles();

  const { state } = useImageEnhancerState();
  const { brightness, contrast, histogramEnabled, autoContrastEnabled } = state;
  const [image, setImage] = useState(sourceUrl);
  const [processing, setProcessing] = useState(false);
  useEffect(() => {
    setImage(sourceUrl);
  }, [sourceUrl]);

  const applyFilters = useCallback(async () => {
    if (sourceUrl) {
      setProcessing(true);
      try {
        const newSource = await setImageFilters(
          sourceUrl,
          brightness,
          contrast,
          histogramEnabled,
          autoContrastEnabled,
          imageWidth,
          imageHeight,
        );
        setProcessing(false);
        return newSource;
      } catch {
        setProcessing(false);
        return sourceUrl;
      }
    }
    return null;
  }, [
    autoContrastEnabled,
    brightness,
    contrast,
    histogramEnabled,
    imageHeight,
    imageWidth,
    sourceUrl,
  ]);

  useEffect(() => {
    let mediaChanged = false;
    applyFilters().then(src => {
      if (!mediaChanged && src) {
        setImage(src);
      }
    });
    return () => {
      mediaChanged = true;
    };
  }, [applyFilters]);

  // trigger children re-render when image state change, but not when sourceUrl props change
  const chilrenMemo = useMemo(() => children({ sourceUrl: image }), [children, image]);

  return (
    <>
      {processing && <LinearProgress className={styles.progressBar} />}
      {chilrenMemo}
    </>
  );
};

export default EnhancedImageViewer;

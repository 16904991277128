import { ButtonGroup, ButtonGroupProps, ButtonProps, makeStyles } from '@material-ui/core';
import { Button } from '../../..';
import React from 'react';
import cx from 'classnames';
import classNames from 'classnames';

const useStyles = makeStyles(({ palette }) => ({
  buttonGroup: {
    position: 'relative',

    '&::after': {
      position: 'absolute',
      display: 'block',
      content: '""',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      border: `1px solid ${palette.indigoBlue[200]}`,
      borderRadius: 10,
    },
    '& .MuiButton-root.active': {
      backgroundColor: palette.indigoBlue[100],
      borderColor: palette.indigoBlue[200],
      color: palette.indigoBlue[900],
      position: 'relative',
      borderRadius: 10,
      zIndex: 2,
    },
    '& .MuiButton-root:not(.active)': {
      borderColor: 'transparent',
      color: palette.grey[500],
      position: 'relative',
      zIndex: 2,
    },
  },
  button: {
    borderColor: 'transparent',
    color: palette.grey[500],
    borderRadius: 10,
    '&.active': {
      backgroundColor: palette.indigoBlue[100],
      borderColor: palette.indigoBlue[200],
      color: palette.indigoBlue[900],
    },
  },
}));

export const SwitchButtonGroup: React.FC<ButtonGroupProps> = React.forwardRef(
  ({ children, ...otherProps }, ref) => {
    const styles = useStyles();
    return (
      <ButtonGroup
        ref={ref}
        variant="outlined"
        color="primary"
        {...otherProps}
        className={classNames(styles.buttonGroup, otherProps.className)}
      >
        {children}
      </ButtonGroup>
    );
  },
);

export const SwitchButton: React.FC<ButtonProps & { active?: boolean }> = ({
  children,
  active,
  ...otherProps
}) => {
  const styles = useStyles();
  return (
    <Button
      id="switch-button-group"
      variant="outlined"
      color="primary"
      aria-pressed={!!active}
      {...otherProps}
      className={cx(styles.button, active && 'active')}
    >
      {children}
    </Button>
  );
};

export default SwitchButtonGroup;

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Typography,
  Checkbox,
  Grid,
  makeStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
  LinearProgress,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import { Dropdown, DropdownProps, useMount } from '@clef/client-library';
import { MetadataObjectValue } from '@clef/shared/types';
import uniq from 'lodash/uniq';

const useStyles = makeStyles(theme => ({
  optionItem: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    paddingRight: theme.spacing(5),
    '&:hover': {
      backgroundColor: 'rgba(9, 30, 66, 0.08)',
    },
    // overwrites
    marginLeft: 0,
    marginRight: 0,
  },
  textButton: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  dropdownPanel: {
    minWidth: 150,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  dropdownContainer: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 8,
  },
  postRequestMask: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
    background: 'rgba(255,255,255,0.7)',
  },
  noneOption: {
    fontStyle: 'italic',
  },
}));

export interface SetMetadataPredefinedChoicesProps {
  children: (dropdownOpen: boolean) => React.ReactNode;
  applyMetadata: (value: string[]) => Promise<void>;
  predefinedChoices: string[];
  allowMultiple?: boolean;
  defaultValue?: MetadataObjectValue;
  // To be passed to Dropdown
  placement?: DropdownProps['placement'];
  extraGutter?: DropdownProps['extraGutter'];
}

const SetMetadataPredefinedChoices: React.FC<SetMetadataPredefinedChoicesProps> = ({
  children,
  placement,
  extraGutter,
  predefinedChoices,
  applyMetadata,
  allowMultiple,
  defaultValue,
}) => {
  const styles = useStyles();

  const isMount = useMount();

  const defaultValueFormatted = useMemo(() => {
    if (defaultValue === undefined) {
      return [];
    } else if (Array.isArray(defaultValue)) {
      return defaultValue.map(val => `${val}`);
    } else {
      return [`${defaultValue}`];
    }
  }, [defaultValue]);

  const [selectedOptions, setSelectedOptions] = useState<string[]>(defaultValueFormatted);

  useEffect(() => {
    if (defaultValueFormatted.length) {
      setSelectedOptions(defaultValueFormatted);
    }
  }, [defaultValueFormatted]);

  const allowMultipleOptionClicked = useCallback(
    (option: string) => () => {
      if (selectedOptions.includes(option)) {
        setSelectedOptions(prev => prev.filter(_ => _ !== option));
      } else {
        setSelectedOptions(prev => uniq([...prev, option]));
      }
    },
    [selectedOptions],
  );
  const [applyingMetadata, setApplyingMetadata] = useState(false);
  return (
    <Dropdown
      placement={placement}
      extraGutter={extraGutter}
      dropdown={(toggleDropdown: (open: boolean) => void) => (
        <div className={styles.dropdownContainer}>
          {applyingMetadata && (
            <div className={styles.postRequestMask}>
              <LinearProgress />
            </div>
          )}
          <div className={styles.dropdownPanel}>
            {allowMultiple ? (
              // allowMultiple - checkbox
              predefinedChoices.map(option => (
                <div
                  key={option}
                  onClick={allowMultipleOptionClicked(option)}
                  className={styles.optionItem}
                  data-testid={`metadata-option-${option}`}
                >
                  <Checkbox
                    color="primary"
                    size="small"
                    checked={selectedOptions.includes(option)}
                    onChange={allowMultipleOptionClicked(option)}
                  />
                  <Typography variant="body2" component="span">
                    {option}
                  </Typography>
                </div>
              ))
            ) : (
              // !allowMultiple - radio group
              <RadioGroup
                value={selectedOptions?.[0] || null}
                onChange={(_, newValue: string) => setSelectedOptions([newValue])}
              >
                {predefinedChoices.map(option => (
                  <FormControlLabel
                    className={styles.optionItem}
                    key={option}
                    value={option}
                    checked={selectedOptions.includes(option)}
                    control={<Radio size="small" color="primary" />}
                    label={<Typography variant="body2">{option}</Typography>}
                    data-testid={`metadata-option-${option}`}
                  />
                ))}
              </RadioGroup>
            )}
            {/* Apply / Clear button */}
            <Grid container justifyContent="flex-end">
              <Button
                id="apply-metadata-btn"
                color="primary"
                size="small"
                className={styles.textButton}
                disabled={!selectedOptions.length}
                onClick={async () => {
                  setApplyingMetadata(true);
                  try {
                    await applyMetadata(selectedOptions.filter(item => item !== ''));
                    toggleDropdown(false);
                  } finally {
                    isMount() && setApplyingMetadata(false);
                  }
                }}
              >
                {t('Apply')}
              </Button>
              <Button
                color="secondary"
                size="small"
                className={styles.textButton}
                disabled={!selectedOptions.length}
                onClick={() => setSelectedOptions([])}
                id="clear-metadata-btn"
              >
                {t('Clear')}
              </Button>
            </Grid>
          </div>
        </div>
      )}
    >
      {open => children(open)}
    </Dropdown>
  );
};

export default SetMetadataPredefinedChoices;

import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import bg from '@/images/auth/gradient_bg.jpg';
import innerBg from '@/images/auth/white_bg.png';
import bullet1 from '@/images/auth/bullet1.png';
import bullet2 from '@/images/auth/bullet2.png';
import bullet3 from '@/images/auth/bullet3.png';
import logoV2 from '@/images/auth/LandingAI-logo-primary-color-RGB.svg';
import examples from '@/images/auth/examples.png';
import { Typography } from '@clef/client-library';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  sidebarContainer: {
    position: 'relative',
    flexBasis: '35%',
    minWidth: 600,
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    padding: theme.spacing(15, 15, 0, 15),
    overflow: 'hidden',
    minHeight: 820,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  sidebarInnerContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    backgroundImage: `url(${innerBg})`,
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    minHeight: '920px',
    border: `2px solid white`,
    borderBottom: 'none',
    borderRadius: `20px 20px 0 0`,
    padding: theme.spacing(12, 14, 0, 14),
    position: 'relative',
  },
  title: {
    fontSize: 24,
    fontFamily: 'Commissioner',
    fontWeight: 500,
    marginBottom: theme.spacing(8),
  },
  blueTitle: {
    color: theme.palette.primary.main,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(6),
    height: 48,
    fontSize: 16,
  },
  contentContainer: {
    flexBasis: '65%',
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
    },
  },
}));

const list = [
  { icon: bullet1, title: t('Create a computer vision project in minutes with Visual Prompting.') },
  { icon: bullet2, title: t('Get accurate results fast with Data-Centric AI.') },
  {
    icon: bullet3,
    title: t('Easily integrate into existing environments with cloud and edge device deployments.'),
  },
];

export type ExamplesProps = {};

const Examples: React.FC<ExamplesProps> = ({ children }) => {
  const styles = useStyles();
  return (
    <Box data-testid="static-examples" className={styles.container}>
      <Box className={styles.sidebarContainer}>
        <Box className={styles.sidebarInnerContainer}>
          <Typography className={styles.title}>
            {t('Computer Vision {{blueText}}', {
              blueText: <span className={styles.blueTitle}>{t('Made Easy')}</span>,
            })}
          </Typography>
          <Box>
            {list.map((item, index) => (
              <Box key={index} className={styles.listItem}>
                <Box width={48} height={48} marginRight={3} flexShrink={0}>
                  <img src={item.icon} width="100%" />
                </Box>
                <Box>
                  <Typography variant="body_regular">{item.title}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Box position="absolute" width="93.75%">
            <img src={examples} width="100%" />
          </Box>
          <Box position="absolute" bottom={36} left={40}>
            <img src={logoV2} width={182} />
          </Box>
        </Box>
      </Box>
      <Box className={styles.contentContainer}>{children}</Box>
    </Box>
  );
};

export default Examples;

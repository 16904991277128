import { createContext, useContext } from 'react';
import { Draft } from 'immer';
import { SortFieldInfo, EdgeMediaFilterOption } from '@clef/shared/types';

export const sortFields: SortFieldInfo[] = [
  { sortType: 'uploadTime', sortValue: 'DESC', label: t('Upload time (Newest to Oldest)') },
  { sortType: 'uploadTime', sortValue: 'ASC', label: t('Upload time (Oldest to Newest)') },
];

const modelFilterTemplate = (value: string[]): EdgeMediaFilterOption => ({
  fieldId: 'modelId',
  resource: 'media',
  value,
  operator: 'CONTAINS_ANY',
});
const defectFilterTemplate = (value: number[]): EdgeMediaFilterOption => ({
  fieldId: 'defectId',
  resource: 'label',
  value,
  operator: 'CONTAINS_ANY',
});
const classFilterTemplate = (value: string[]): EdgeMediaFilterOption => ({
  fieldId: 'classLabel',
  resource: 'label',
  value,
  operator: 'CONTAINS_ANY',
});
const uploadTimeFilterTemplate = (startDate: number, endDate: number): EdgeMediaFilterOption => ({
  fieldId: 'uploadTime',
  resource: 'media',
  value: [new Date(startDate).toISOString(), new Date(endDate).toISOString()],
  operator: 'BETWEEN',
});
const confidenceFilterTemplate = (value: number[]): EdgeMediaFilterOption => ({
  fieldId: 'confidence',
  resource: 'label',
  value,
  operator: 'BETWEEN',
});
const humanJudgementFilterTemplate = (value: string[]): EdgeMediaFilterOption => ({
  fieldId: 'judgement',
  resource: 'human_judgement',
  value,
  operator: 'CONTAINS_ANY',
});
const imageIdFilterTemplate = (value: string): EdgeMediaFilterOption => ({
  fieldId: 'imageId',
  resource: 'meta_data',
  value,
  operator: 'LIKE',
});
const locationIdFilterTemplate = (value: string): EdgeMediaFilterOption => ({
  fieldId: 'locationId',
  resource: 'meta_data',
  value,
  operator: 'LIKE',
});
const inspectionStationIdFilterTemplate = (value: string): EdgeMediaFilterOption => ({
  fieldId: 'inspectionStationId',
  resource: 'meta_data',
  value,
  operator: 'LIKE',
});

export type DeviceMonitorState = Readonly<{
  selectedMedia: number[];
  deviceId: string;
  filterModel: string[];
  filterClass: string[];
  filterUploadTime: number[];
  filterUploadTimeEnabled: boolean;
  filterDefects: number[];
  filterConfidence: number[];
  filterHumanJudgement: string[];
  filterConfidenceEnabled: boolean;
  filterImageId: string;
  filterImageIdEnabled: boolean;
  filterLocationId: string;
  filterLocationIdEnabled: boolean;
  filterInspectionStationId: string;
  filterInspectionStationIdEnabled: boolean;
  displayOptions: {
    showLabels: boolean;
    showDefectName: boolean;
    showConfidenceScore: boolean;
    showImageClassification: boolean;
    showHumanJudgement: boolean;
    showHumanJudgementCheckbox: boolean;
    showImageId: boolean;
  };
  sortOptions: SortFieldInfo;
  pageIndex: number;
  paginationLimit: number;
  isClassificationMedium: boolean;
}>;

const getDateOneMonthAgo = (date: string) => {
  const newDate = new Date(date);
  const month = newDate.getMonth();
  newDate.setMonth(newDate.getMonth() - 1);

  // If still in same month, set date to last day of previous month.
  // With 31 July you get 31 June, which will be converted to 1 July.
  if (newDate.getMonth() === month) {
    newDate.setDate(0);
  }
  return newDate;
};

const dateNow = new Date(Date.now());
const today = dateNow.getTime();
const dayOneMonthAgo = getDateOneMonthAgo(dateNow.toISOString()).getTime();

export const defaultState: DeviceMonitorState = {
  selectedMedia: [],
  filterModel: [],
  filterClass: [],
  filterUploadTime: [dayOneMonthAgo, today],
  filterUploadTimeEnabled: false,
  filterDefects: [],
  deviceId: '',
  filterConfidence: [0, 1],
  filterHumanJudgement: [],
  filterConfidenceEnabled: false,
  filterImageId: '',
  filterImageIdEnabled: false,
  filterLocationId: '',
  filterLocationIdEnabled: false,
  filterInspectionStationId: '',
  filterInspectionStationIdEnabled: false,
  displayOptions: {
    showLabels: true,
    showDefectName: true,
    showConfidenceScore: true,
    showImageClassification: false,
    showHumanJudgement: false,
    showHumanJudgementCheckbox: false,
    showImageId: true,
  },
  sortOptions: sortFields[0],
  pageIndex: 0,
  paginationLimit: 50,
  isClassificationMedium: false,
};

export const consolidateFilterOptionFromState = (
  state: DeviceMonitorState,
): EdgeMediaFilterOption[] => {
  const {
    filterConfidence,
    filterConfidenceEnabled,
    filterDefects,
    filterModel,
    filterClass,
    filterHumanJudgement,
    filterUploadTime,
    filterUploadTimeEnabled,
    filterImageId,
    filterImageIdEnabled,
    filterLocationId,
    filterLocationIdEnabled,
    filterInspectionStationId,
    filterInspectionStationIdEnabled,
    isClassificationMedium,
  } = state;
  const filterOptionsRaw: EdgeMediaFilterOption[] = [];
  if (filterModel.length) {
    filterOptionsRaw.push(modelFilterTemplate(filterModel));
  }
  if (filterDefects.length && !isClassificationMedium) {
    filterOptionsRaw.push(defectFilterTemplate(filterDefects));
  }
  if (filterClass.length && isClassificationMedium) {
    filterOptionsRaw.push(classFilterTemplate(filterClass));
  }
  if (filterUploadTimeEnabled) {
    filterOptionsRaw.push(uploadTimeFilterTemplate(filterUploadTime[0], filterUploadTime[1]));
  }
  if (filterConfidenceEnabled) {
    filterOptionsRaw.push(confidenceFilterTemplate(filterConfidence));
  }
  if (filterImageIdEnabled) {
    filterOptionsRaw.push(imageIdFilterTemplate(filterImageId));
  }
  if (filterLocationIdEnabled) {
    filterOptionsRaw.push(locationIdFilterTemplate(filterLocationId));
  }
  if (filterInspectionStationIdEnabled) {
    filterOptionsRaw.push(inspectionStationIdFilterTemplate(filterInspectionStationId));
  }
  if (filterHumanJudgement.length) {
    filterOptionsRaw.push(humanJudgementFilterTemplate(filterHumanJudgement));
  }
  return filterOptionsRaw;
};

/**
 * Context
 */
export const DeviceMonitorStateContext = createContext<{
  state: DeviceMonitorState;
  dispatch: (f: (state: Draft<DeviceMonitorState>) => void | DeviceMonitorState) => void;
}>({
  state: defaultState,
  dispatch: () => {},
});

export const useDeviceMonitorState = () => useContext(DeviceMonitorStateContext);

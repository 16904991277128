import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  loadingImagesProgress: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1,
  },
  trainingDataContainer: {
    overflow: 'auto',
    display: 'flex',
    flexFlow: 'column wrap',
    height: '100%',
    flexGrow: 1,
  },
  trainingImageList: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      maxWidth: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  gridListTile: {
    [theme.breakpoints.up('lg')]: {
      flexBasis: '25%',
    },
    [theme.breakpoints.down('md')]: {
      flexBasis: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
    },
  },
}));

export default useStyles;

import React from 'react';
import cx from 'classnames';
import { makeStyles, Grid } from '@material-ui/core';

import { Typography } from '@clef/client-library';

// @ts-ignore
import FRELandingSVG from '@/images/logo_withoutText_color.svg';
import logoLandingLens from '@/images/logo_LandingLens_color.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    minHeight: '100vh',
    overflowX: 'hidden',
  },
  loading: {
    animation: `$fadeInOut 3s infinite`,
  },
  logotype: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(3),
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.2,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

export const AppLoadingSplash: React.FC<{}> = () => {
  const styles = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={cx(styles.root, styles.loading)}
    >
      <img src={logoLandingLens} height={32} />
    </Grid>
  );
};

export const AppMaintenanceSplash: React.FC<{}> = () => {
  const styles = useStyles();
  return (
    <Grid container justifyContent="center" alignItems="center" className={cx(styles.root)}>
      <img src={FRELandingSVG} width={32} height={32} />
      <Typography variant="h3" className={styles.logotype}>
        {t('Maintenance in progress, please come back later.')}
      </Typography>
    </Grid>
  );
};

import { makeStyles } from '@material-ui/core';

export const rowHeight = 132;

export const rowPadding = 16;

const imageHeight = rowHeight - rowPadding;

export const minListHeight = 480;

export const useStyles = makeStyles(theme => ({
  emptyBoxIcon: {
    width: 120,
    height: 120,
  },
  emptyStateModelPageTextBody: {
    width: 400,
    textAlign: 'center',
  },
  greyModern600: {
    color: theme.palette.greyModern[600],
  },

  activeProjectRowContainer: {
    padding: `${rowPadding}px 0 0 0`,
  },
  activeProjectRow: {
    borderRadius: theme.spacing(5),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.greyModern[50],
    },
  },
  projectCardImageContainer: {
    overflow: 'hidden',
    width: 'auto',
    flexShrink: 0,
    paddingRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  projectCardImage: {
    height: imageHeight,
    aspectRatio: '3/2',
    alignSelf: 'center',
    objectFit: 'cover',
    borderRadius: theme.spacing(5),
    borderColor: theme.palette.greyModern[300],
    borderStyle: 'solid',
    borderWidth: 1,
    background: 'linear-gradient(180deg, rgba(10, 100, 252, 0.05) 0%, rgba(10, 100, 252, 0) 100%)',
  },
}));

import { useState, useEffect } from 'react';
import { HIGHLIGHT_DURATION } from './constant';

type RowId = string | number;

interface Output {
  rowStyle: (rowData: { id: RowId }) => React.CSSProperties;
}

/**
 * Use this hook to jump to a specific id in the table and highlight it.
 * @example
 * const tableRef = React.useRef();
 * const id = getRowId();  // dummy method
 * const { rowStyle } = useJumpToRowById(tableRef, id);
 * return <MaterialTable tableRef={tableRef} options={ rowStyle }/>
 *
 * @param tableRef - the ref that is passed to MaterialTable
 * @param id - id from the table to jump into
 * @param deps - any dependency change that the hook should be listening to
 * @return object.rowStyle - rowStyle to be inserted into MaterialTable props
 */
export const useJumpToRowById = (
  tableRef: any,
  id: RowId | null,
  deps: ReadonlyArray<any> = [],
): Output => {
  const [rowId, setRowId] = useState<RowId | null>(null);

  useEffect(() => {
    if (tableRef && id) {
      const { dataManager, onChangePage, onSearchChange } = tableRef.current;
      const { sortedData, pageSize } = dataManager;
      const rowIndex = sortedData.findIndex((row: any) => row.id === id);
      if (rowIndex !== -1) {
        const page = Math.floor(rowIndex / pageSize);
        onSearchChange('');
        onChangePage(null, page);
        setRowId(id);
        setTimeout(() => setRowId(null), HIGHLIGHT_DURATION);
      }
    }
  }, [tableRef, id, ...deps]);

  const rowStyle = (rowData: { id: RowId }): React.CSSProperties => {
    let styles = {};
    if (rowData.id === rowId) {
      styles = {
        backgroundColor: '#c9e2f6',
      };
    }
    return styles;
  };
  return { rowStyle };
};

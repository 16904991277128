import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

const LoadingProgress = (props: { size?: number; alignItems?: string }) => {
  const { size, alignItems = 'center' } = props;
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems={alignItems}
    >
      <CircularProgress size={size} />
    </Box>
  );
};

export default LoadingProgress;

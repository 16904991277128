import React, { useState } from 'react';
import cx from 'classnames';
import { DefectBookExample } from '@clef/shared/types';
import { useDefectBookEnhancedStyles } from '../defectBookEnhancedStyles';
import { useMediaByIdApi } from '../../../hooks/api/useMediaApi';
import { MediaInteractiveCanvas } from '@clef/client-library';
import ReactMarkdown from 'react-markdown';
import { Grid, Fab } from '@material-ui/core';
import EditRounded from '@material-ui/icons/EditRounded';
import DeleteRounded from '@material-ui/icons/DeleteRounded';
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRounded from '@material-ui/icons/ArrowForwardRounded';
import MediaEditModal from './MediaEditModal';
import { defectExampleAnnotationToCanvasAnnotation } from './utils';
import { getThumbnail } from '@clef/client-library';

export interface DefectExampleProps {
  defectName?: string;
  defectColor?: string;
  defectExampleNumber?: number;
  defectExample: DefectBookExample;
  onDelete?: () => void;
  onUpdate?: (data: DefectBookExample) => void;
  onMoveLeft?: () => void;
  onMoveRight?: () => void;
  editMode: boolean;
  onImageLoad?: () => void;
}

const DefectExample: React.FC<DefectExampleProps> = ({
  defectExample,
  editMode,
  onDelete,
  onMoveLeft,
  onMoveRight,
  onUpdate,
  onImageLoad,
  defectExampleNumber,
  defectName,
  defectColor,
}) => {
  const { mediaId, note, annotations } = defectExample;
  const [editDialogOpen, SetEditDialogOpen] = useState(false);
  const [media] = useMediaByIdApi(mediaId);
  const styles = useDefectBookEnhancedStyles();

  return (
    <div
      className={cx(styles.defectExampleContainer, editMode && styles.editModeActionable)}
      data-testid="defect-example"
    >
      <MediaInteractiveCanvas
        key={editMode ? 'edit' : 'view'}
        imageSrc={getThumbnail(media, 'medium')}
        properties={media?.properties}
        annotations={defectExampleAnnotationToCanvasAnnotation(annotations)}
        builtInZoom={!editMode}
        enableHideLabels
        aspectRadio={16 / 9}
        onImageLoad={onImageLoad}
      />
      <div className={styles.defectExampleText}>
        <ReactMarkdown className={styles.markdownText}>{note ?? t('')}</ReactMarkdown>
      </div>
      <Grid container wrap="wrap" justifyContent="flex-end" className={styles.defectExampleAction}>
        {editMode && (
          // Edit mode: Move left / Move right / Edit / Delete
          <>
            {!!onMoveLeft && (
              <Fab
                aria-label="move-left-example"
                size="small"
                className={styles.defectExampleActionFab}
                onClick={onMoveLeft}
              >
                <ArrowBackRounded />
              </Fab>
            )}
            {!!onMoveRight && (
              <Fab
                aria-label="move-right-example"
                size="small"
                className={styles.defectExampleActionFab}
                onClick={onMoveRight}
              >
                <ArrowForwardRounded />
              </Fab>
            )}
            <Fab
              color="primary"
              aria-label="edit-example"
              size="small"
              className={styles.defectExampleActionFab}
              onClick={() => SetEditDialogOpen(true)}
            >
              <EditRounded />
            </Fab>
            <Fab
              color="secondary"
              aria-label="delete-example"
              size="small"
              className={styles.defectExampleActionFab}
              onClick={onDelete}
            >
              <DeleteRounded />
            </Fab>
          </>
        )}
      </Grid>
      {editDialogOpen && (
        <MediaEditModal
          title={t('Media example {{defectExampleNumber}} for defect "{{defectName}}"', {
            defectExampleNumber,
            defectName,
          })}
          media={media}
          annotations={annotations}
          note={note}
          onClose={() => SetEditDialogOpen(false)}
          onSave={newData => onUpdate?.({ ...defectExample, ...newData })}
          defectColor={defectColor}
        />
      )}
    </div>
  );
};

export default DefectExample;

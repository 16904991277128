import { ModelInfo, RegisteredModel } from '@clef/shared/types';
import { useCallback } from 'react';
import { useModelsPage } from './api/useMaglevApi';
import { useGetProjectModelListQuery } from '@/serverStore/projectModels';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';

export const useModels = () => {
  const {
    data: modelsData,
    isLoading: modelsLoading,
    error: modelsError,
  } = useGetProjectModelListQuery();

  const findModels = useCallback(
    (modelId: string | undefined) => {
      if (modelId === undefined) {
        return null;
      }

      return modelsData?.find(model => model.id === modelId) ?? null;
    },
    [modelsData],
  );

  return {
    models: modelsData,
    loading: modelsLoading,
    error: modelsError,
    findModels,
  };
};

export const useClassicModels = ({ pageLimit = 20 }: { pageLimit?: number }) => {
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const [modelsData, modelsLoading, modelsError] = useModelsPage(
    projectId
      ? {
          projectId,
          limit: pageLimit,
        }
      : undefined,
  );

  const findModels = useCallback(
    (modelId: string | undefined) => {
      if (modelId === undefined) {
        return null;
      }

      return modelsData?.documents?.find(model => model.trainJobId === modelId) ?? null;
    },
    [modelsData?.documents],
  );

  return {
    models: modelsData?.documents,
    loading: modelsLoading,
    error: modelsError,
    findModels,
  };
};

export const useAllModels = (
  { classicModelPageLimit = 20 }: { classicModelPageLimit?: number } = {
    classicModelPageLimit: 20,
  },
) => {
  const {
    models: modelsData,
    loading: modelsLoading,
    error: modelsError,
    findModels: findFastNEasyModels,
  } = useModels();

  const {
    models: classicModelsData,
    loading: classicModelsLoading,
    error: classicModelsError,
    findModels: findClassicModels,
  } = useClassicModels({ pageLimit: classicModelPageLimit });

  const findModels = useCallback(
    (modelId: string | undefined) => {
      let model: RegisteredModel | ModelInfo | null = findFastNEasyModels(modelId);

      if (model === null) {
        model = findClassicModels(modelId);
      }

      return model;
    },
    [findClassicModels, findFastNEasyModels],
  );

  return {
    models: modelsData,
    classicModels: classicModelsData,
    loading: modelsLoading || classicModelsLoading,
    error: modelsError || classicModelsError,
    findModels,
    findFastNEasyModels,
    findClassicModels,
  };
};

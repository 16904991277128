import { FilterOptions, ModelMetricsResponse } from '@/api/model_analysis_api';
import { createAtom } from '@/uiStates/utils';
import { BatchModelMetricParam, ModelMetricParam } from '@clef/shared/types';

export const modelListFilterOptionsAtom = createAtom<FilterOptions | undefined>(
  'modelListFilterOptions',
  undefined,
);

export const batchModelMetricsParamAtom = createAtom<BatchModelMetricParam>(
  'batchModelMetricsParam',
  [],
);

export const modelMetricsMapAtom = createAtom<
  Record<string, ModelMetricsResponse & ModelMetricParam>
>('modelMetricsMap', {});

import React, { useState, ChangeEvent, useCallback } from 'react';
import { MediaId } from '@clef/shared/types';
import { Button } from '@clef/client-library';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { useDefectSelector } from '../../store/defectState/actions';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { Typography, MenuItem, DialogTitle } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useGetSelectedProjectQuery, useUpdateDefectMutation } from '@/serverStore/projects';
import {
  useFetchDefectBookOverviewApi,
  updateDefectBookOverviewApi,
  createDefectBookExampleApi,
} from '../../hooks/api/useDefectBookApi';
import { useKeyPress } from '@clef/client-library';

const useStyles = makeStyles(() => ({
  menu: {
    width: '10rem',
    height: '5rem',
    marginRight: '2rem',
  },
}));
interface Props {
  mediaId: MediaId;
  buttonProps?: Omit<React.ComponentProps<typeof Button>, 'children'>;
}

const AddToDefectSecBtn: React.FC<Props> = ({ mediaId, buttonProps = {} }) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const defects = useDefectSelector();
  const [selectedDefectId, setSelectedDefectId] = useState<number | null>(null);
  const [isAdding, setIsAdding] = useState(false);
  const [defectOverview] = useFetchDefectBookOverviewApi(selectedProject?.id);
  const { enqueueSnackbar } = useSnackbar();
  const updateDefectApi = useUpdateDefectMutation();
  const handleDefectChange = useCallback(
    (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      setSelectedDefectId(Number(e.target.value));
    },
    [setSelectedDefectId],
  );

  const getDialogContent = useCallback(() => {
    if (!defects || !defects.length) {
      return <Typography>{t('No defect defined. Please fill in defect book first.')}</Typography>;
    }
    return (
      <>
        <FormControl className={classes.menu}>
          <InputLabel>{t('Defect')}</InputLabel>
          <Select
            value={selectedDefectId}
            onChange={handleDefectChange}
            className="cy-add-media-to-defect-defect-select"
          >
            {[...defects].map((defect, idx) => (
              <MenuItem key={idx} value={defect.id} className="cy-add-media-to-defect-defect-item">
                {defect.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  }, [defects, classes.menu, selectedDefectId, handleDefectChange]);
  const onAddMediaClick = async () => {
    try {
      if (!selectedDefectId || !selectedProject) {
        return;
      }
      setIsAdding(true);
      if (selectedDefectId === -1) {
        const newExample = await createDefectBookExampleApi({
          mediaId,
          annotations: { boxAnnotations: [], lineAnnotations: [], textAnnotations: [] },
          note: '*Please describe this example*',
        });
        await updateDefectBookOverviewApi(selectedProject.id, {
          ...defectOverview!,
          termExamples: [...defectOverview!.termExamples, newExample.id],
        });
      } else {
        const findDefect = defects?.find(defect => defect.id === selectedDefectId)!;
        const newExample = await createDefectBookExampleApi({
          mediaId,
          annotations: { boxAnnotations: [], lineAnnotations: [], textAnnotations: [] },
          note: '*Please describe this example*',
        });
        await updateDefectApi.mutateAsync({
          ...findDefect,
          defect_book_example_ids: [...(findDefect.defect_book_example_ids ?? []), newExample.id],
        });
      }
      setIsAdding(false);
      setShowDialog(false);
      enqueueSnackbar(t('Successfully add to label book'), {
        variant: 'success',
      });
    } catch (e) {
      setIsAdding(false);
      enqueueSnackbar('Failed to add to defect book with server error: ' + e.message, {
        variant: 'error',
      });
    }
  };

  useKeyPress('d', () => setShowDialog(true));
  useKeyPress('esc', () => setShowDialog(false));

  return (
    <>
      {showDialog && (
        <Dialog open={true} fullWidth={true} maxWidth="xs">
          <DialogTitle>{t('Add media to:')}</DialogTitle>
          <DialogContent>{getDialogContent()}</DialogContent>
          <DialogActions>
            <Button
              id="close-add-to-defect-section-dialog-button"
              onClick={() => {
                setShowDialog(false);
              }}
              color="primary"
            >
              {t('Close')}
            </Button>
            <Button
              id="add-to-defect-section-dialog-button"
              color="primary"
              variant="contained"
              disabled={!selectedDefectId}
              onClick={onAddMediaClick}
              className="cy-add-media-to-defect-btn"
            >
              {isAdding ? t('Adding...') : t('Add media')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setShowDialog(true);
        }}
        size="small"
        className="cy-add-media-to-db-btn"
        {...buttonProps}
        id="add-to-label-book-button"
      >
        {t('Add image to Label Book')}
      </Button>
    </>
  );
};

export default AddToDefectSecBtn;

import { makeStyles } from '@material-ui/core';
import { greyScale } from '@clef/client-library';

export const useJobStyles = makeStyles(({ spacing, palette }) => ({
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    width: '100vw',
    backgroundColor: palette.background.lightBlue,
  },
  header: {
    width: '100%',
    backgroundColor: '#fff',
  },
  stepper: {
    backgroundColor: 'transparent',
    boxShadow: 'none !important',
  },
  innerContent: {
    maxWidth: 1000,
    padding: spacing(4, 8),
  },
  previewContainer: {
    padding: spacing(4, 8),
  },
  stepperNavigation: {
    padding: spacing(2),
    paddingTop: spacing(9),
    textAlign: 'right',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperContainer: {
    width: '80vw',
    height: '80vh',
    overflow: 'auto',
    padding: spacing(10),
  },
  carouselImageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  previewMediaOverlay: {
    minHeight: 300,
  },
  pagination: {
    margin: spacing(3, 0),
  },
  buttonProgress: {
    marginLeft: spacing(3),
    color: greyScale[100],
  },
  secondaryButtonProgress: {
    marginLeft: spacing(3),
  },
}));

import { Defect } from '@clef/shared/types';
import { useDefectSelectorWithArchived } from '../../store/defectState/actions';
import { useCallback, useMemo } from 'react';

const useGetDefectById = () => {
  const allDefects = useDefectSelectorWithArchived();

  const defectIdToDefectMap = useMemo(() => {
    return (allDefects ?? []).reduce((acc, defect) => {
      acc[defect.id] = defect;
      return acc;
    }, {} as Record<number, Defect>);
  }, [allDefects]);

  return useCallback((defectId: number) => defectIdToDefectMap[defectId], [defectIdToDefectMap]);
};

export default useGetDefectById;

import { ProjectRole, UserRole } from '../types';

export const isProjectOwner = (role: ProjectRole | undefined | null) => {
  return role === ProjectRole.Owner;
};

export const isProjectStandardUser = (role: ProjectRole | undefined | null) => {
  return role === ProjectRole.Standard;
};

export const isProjectLabeler = (role: ProjectRole | undefined | null) => {
  return role === ProjectRole.Labeler;
};

export const isOrgOwner = (role: UserRole | undefined | null) => {
  return role === UserRole.Owner;
};

export const isOrgAdmin = (role: UserRole | undefined | null) => {
  return role === UserRole.Admin;
};

export const isOrgCollaborator = (role: UserRole | undefined | null) => {
  return role === UserRole.Collaborator;
};

/**
 * To determine whether operator could manage user (there should only be at most 3 org owners at the same time)
 * 1. Org owner can manage other org owners, admins or collaborators
 * 2. Admins can manage other collaborators
 * 3. Collaborators cannot manage any user role
 * @param {UserRole} operator
 * @param {UserRole} user
 * @returns {boolean} true if has permission to operate
 */
export const hasPermissionOver = (operator: UserRole, user: UserRole) => {
  return isOrgOwner(operator) || (isOrgAdmin(operator) && isOrgCollaborator(user));
};

export const getUserRolePermissionDescription = (userRole: UserRole): string => {
  if (isOrgOwner(userRole)) {
    return 'Organization owners have all platform feature accesses and permissions to manage everyone in the organization.';
  } else if (isOrgAdmin(userRole)) {
    return 'Admin has access to all the platform features and can manage collaborators';
  } else if (isOrgCollaborator(userRole)) {
    return 'Collaborators can label projects';
  } else {
    throw Error('unsupported userRole');
  }
};

export const canUpdateUserRole = (
  operator: UserRole,
  currentUserRole: UserRole,
  expectedUserRole: UserRole,
): boolean => {
  if (currentUserRole === expectedUserRole) {
    throw Error('user role has not changed, no need to update');
  }
  if (isOrgOwner(operator)) {
    return true;
  }
  if (isOrgAdmin(operator)) {
    return isOrgCollaborator(currentUserRole) && isOrgAdmin(expectedUserRole);
  }
  return false;
};

export const getDefaultProjectRoleFromOrgRole = (orgRole: UserRole | undefined | null) => {
  return orgRole === UserRole.Owner || orgRole === UserRole.Admin
    ? ProjectRole.Standard
    : ProjectRole.Labeler;
};

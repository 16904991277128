import { ApiResponse } from '@clef/shared/types';
import { BaseAPI } from './base_api';

const StreamyardAPIUrlPrefix = 'streamyard';

export type StreamyardObject = {
  streamyardId: string;
};

class StreamyardAPI extends BaseAPI {
  async getStreamyardIdFromSecretsManager(): Promise<ApiResponse<StreamyardObject>> {
    const res = await this.get('get_streamyard_id');
    return res;
  }
}

export default new StreamyardAPI(StreamyardAPIUrlPrefix);

import { IconButton } from '@clef/client-library';
import { Box, CircularProgress, Dialog, Paper, makeStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React from 'react';
import MobileUploadDialogContentEnabled from './MobileUploadDialogContentEnabled';
import { usePublicEndpointApi } from '@/hooks/api/usePublicEndpointApi';
import MobileUploadDialogContentDisabled from './MobileUploadDialogContentDisabled';
import { UPLOAD_DIALOG_Z_INDEX } from '@/components/Dialogs/UploadFullscreen/constants';
import { useTypedSelector } from '@/hooks/useTypedSelector';

const useStyles = makeStyles(() => ({
  dialogRoot: {
    zIndex: (UPLOAD_DIALOG_Z_INDEX + 1 + '!important') as any,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

type MobileUploadDialogProps = {
  onClose?: () => void;
};

const MobileUploadDialog: React.FC<MobileUploadDialogProps> = props => {
  const { onClose } = props;
  const projectId = useTypedSelector(state => state.project.selectedProjectId);
  const styles = useStyles();
  const [endpoint, loading] = usePublicEndpointApi(
    projectId ? { projectId, type: 'image_upload' } : undefined,
  );
  return (
    <Dialog open onClose={onClose} classes={{ root: styles.dialogRoot }}>
      <Paper>
        <Box
          padding={7}
          position="relative"
          display="flex"
          flexDirection="column"
          alignItems="center"
          width={480}
        >
          <IconButton id="close-dialog" className={styles.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
          {loading && (
            <Box
              width={120}
              height={120}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress size={24} />
            </Box>
          )}
          {!loading &&
            (endpoint?.enable ? (
              <MobileUploadDialogContentEnabled />
            ) : (
              <MobileUploadDialogContentDisabled />
            ))}
        </Box>
      </Paper>
    </Dialog>
  );
};

export default MobileUploadDialog;

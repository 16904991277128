import React, { useCallback } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { useAtom } from 'jotai';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import { useGetModelListQuery } from '@/serverStore/modelAnalysis';
import { cloneDeep } from 'lodash';

import { Button } from '@clef/client-library';

import { CopyIcon } from '@/images/custom_training/icons';
import {
  modelsConfigListAtom,
  autoFocusNameModelAtom,
  isInProgressAtom,
  duplicateNameIdsAtom,
} from '@/uiStates/customTraining/pageUIStates';
import { generateRandomId } from '@/utils';

import NameEditor from './NameEditor';
import NotesEditor from './NotesEditor';
import HyperParameter from './HyperParameters';
import ConfigCells from './ConfigCells';

const useStyles = makeStyles(theme => ({
  tableContainer: {},
  tableHeader: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(5, 7, 5, 3),
      borderBottom: `solid 1px ${theme.palette.greyModern[300]}`,
      lineHeight: '20px',
    },
  },
  parametersSectionTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  tableRow: {
    position: 'relative',
    background: theme.palette.grey[25],

    '&:hover': {
      '& $columnOpsBtn': {
        display: 'flex',
      },
    },

    '& .MuiTableCell-root': {
      padding: theme.spacing(6, 3),
      borderBottom: `solid 1px ${theme.palette.greyModern[300]}`,
    },
  },
  modelBasicInfo: {
    backgroundColor: '#FFFFFF',
  },
  configCell: {
    verticalAlign: 'top',
  },
  columnOpsBtn: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(6),
    display: 'none',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: theme.spacing(2),
  },
}));

const ModelsConfiguraionTable: React.FC = () => {
  const styles = useStyles();

  const { data: modelList = [] } = useGetModelListQuery();
  const modelNames = modelList.map(model => model.modelName);

  const [modelsConfigList, setModelsConfigList] = useAtom(modelsConfigListAtom);
  const [autoFocusNameModel, setAutoFocusNameModel] = useAtom(autoFocusNameModelAtom);
  const [isInProgress] = useAtom(isInProgressAtom);

  const [duplicateNameIds, setDuplicateNameIds] = useAtom(duplicateNameIdsAtom);
  const [duplicateType, setDuplicateType] = React.useState<string | undefined>(undefined);

  const findDuplicateNames = useCallback(() => {
    // check if there are duplicate names with existing model name
    const existingNameIds: string[] = modelsConfigList.reduce((acc, { tempId, name }) => {
      if (modelNames.includes(name) && tempId !== null) {
        acc.push(tempId);
      }
      return acc;
    }, [] as string[]);

    if (existingNameIds.length > 0) {
      setDuplicateNameIds(existingNameIds);
      setDuplicateType('duplicated with existing model name.');
      return;
    }

    // check if there are duplicate names in table
    const nameToIds = modelsConfigList.reduce((acc, { tempId, name }) => {
      if (!acc.has(name)) {
        acc.set(name, []);
      }
      if (tempId !== null) {
        acc.get(name)?.push(tempId);
      }
      return acc;
    }, new Map<string, string[]>());

    const duplicateIds = Array.from(nameToIds).reduce((acc, [, ids]) => {
      if (ids.length > 1) {
        return acc.concat(ids);
      }
      return acc;
    }, [] as string[]);
    setDuplicateNameIds(duplicateIds);
    if (duplicateIds.length > 0) {
      setDuplicateType('name is duplicated.');
    } else {
      setDuplicateType(undefined);
    }
  }, [modelNames, modelsConfigList, setDuplicateNameIds]);

  const handleModelNameChange = (index: number, name: string) => {
    setModelsConfigList(prev => {
      const newModelsConfigList = [...prev];
      newModelsConfigList[index].name = name;
      return newModelsConfigList;
    });
    findDuplicateNames();
  };
  const handleModelNotesChange = (index: number, notes?: string) => {
    setModelsConfigList(prev => {
      const newModelsConfigList = [...prev];
      newModelsConfigList[index].description = notes;
      return newModelsConfigList;
    });
  };

  const duplicateModelConfig = useCallback(
    rowIndex => {
      const newModelTempId = generateRandomId();
      setAutoFocusNameModel(newModelTempId);

      setModelsConfigList(prev => {
        const newModelsConfigList = [...prev];
        const newModelConfig = cloneDeep(newModelsConfigList[rowIndex]);
        newModelConfig.tempId = newModelTempId;
        newModelConfig.name = `${newModelConfig.name}_copy`;
        newModelsConfigList.splice(rowIndex + 1, 0, newModelConfig);
        return newModelsConfigList;
      });
      findDuplicateNames();
    },
    [findDuplicateNames, setAutoFocusNameModel, setModelsConfigList],
  );

  const deleteModelConfig = useCallback(
    rowIndex => {
      setModelsConfigList(prev => {
        const newModelsConfigList = [...prev];
        newModelsConfigList.splice(rowIndex, 1);
        return newModelsConfigList;
      });
    },
    [setModelsConfigList],
  );

  return (
    <TableContainer className={styles.tableContainer}>
      <Table aria-label="download-list-table">
        <TableHead className={styles.tableHeader}>
          <TableRow>
            <TableCell align="left" style={{ width: '14%' }}>
              {t('')}
            </TableCell>
            <TableCell align="left" style={{ width: '19%' }}>
              <Box className={styles.parametersSectionTitle}>
                {t('Hyperparameters')}
                <Tooltip
                  title={t(
                    'Control the speed and quality of the learning process that occurs during model training.',
                  )}
                  arrow
                  placement="top"
                >
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell align="left" style={{ width: '24%' }}>
              <Box className={styles.parametersSectionTitle}>
                {t('Transforms')}
                <Tooltip
                  title={t(
                    'Use Transforms to resize or crop images in your dataset. Remember to resize before you crop to make sure all data stays the same size to prevent accidentally cropping outside the image boundaries of smaller images.',
                  )}
                  arrow
                  placement="top"
                >
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell align="left" style={{ width: '30%' }}>
              <Box className={styles.parametersSectionTitle}>
                {t('Augmentations')}
                <Tooltip
                  title={t('Add pre-processing modifications to all images in your dataset.')}
                  arrow
                  placement="top"
                >
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell align="left" style={{ width: '13%' }}>
              {t('')}
            </TableCell>
            <TableCell align="left" style={{ width: '0%', padding: 0 }}>
              {t('')}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {modelsConfigList.map((model, index) => (
            <TableRow key={model.tempId} className={styles.tableRow} hover>
              <TableCell className={styles.modelBasicInfo}>
                <NameEditor
                  key={model.tempId}
                  value={model.name}
                  onNameChange={name => handleModelNameChange(index, name)}
                  isEditing={model.tempId === autoFocusNameModel}
                  nameError={duplicateNameIds.includes(model.tempId!) ? duplicateType : undefined}
                />
                <NotesEditor
                  value={model.description}
                  onNotesChange={notes => handleModelNotesChange(index, notes)}
                />
              </TableCell>
              <HyperParameter rowIndex={index} />
              <ConfigCells rowIndex={index} />
              <TableCell>
                <Box className={styles.columnOpsBtn}>
                  {modelsConfigList.length < 10 && (
                    <Button
                      id="custom-train-model-copy-button"
                      startIcon={<CopyIcon />}
                      variant="contained"
                      disabled={isInProgress}
                      onClick={() => duplicateModelConfig(index)}
                    >
                      {t('Duplicate')}
                    </Button>
                  )}

                  {modelsConfigList.length > 1 && (
                    <Button
                      id="custom-train-model-delete-button"
                      startIcon={<DeleteOutlined />}
                      variant="contained"
                      disabled={isInProgress}
                      onClick={() => deleteModelConfig(index)}
                    >
                      {t('Delete')}
                    </Button>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ModelsConfiguraionTable;

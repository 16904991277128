import { createContext, useContext } from 'react';
import { Updater } from 'use-immer';

export enum PerformanceType {
  MediaLevel = 'MediaLevel',
  // can be bounding box level for Object Detection, pixel level for Segmentation, or
  // classification level for Classification.
  AnnotationLevel = 'AnnotationLevel',
}

export type ProjectModelDetailsState = {
  localThreshold?: number;
  performanceType: PerformanceType;
  enableLogScale: boolean;
};

export const initialState: ProjectModelDetailsState = {
  performanceType: PerformanceType.MediaLevel,
  enableLogScale: false,
};

export const ProjectModelDetailsContext = createContext<{
  state: ProjectModelDetailsState;
  dispatch: Updater<ProjectModelDetailsState>;
}>({
  state: initialState,
  dispatch: () => {},
});

export const useProjectModelDetailsState = () => useContext(ProjectModelDetailsContext);

import { useCallback, useEffect, useState } from 'react';

const WINDOW_EVENT_KEY = 'localstorage';

const getLocalStorageValue = <Value>(key: string, ignoredValues?: Value[]) => {
  const value = localStorage.getItem(key);
  if (value === null || value === '') {
    return null;
  }

  const parsedValue = JSON.parse(value);
  // If the parsed value should be ignored, then return null
  if (ignoredValues?.find(ignoredValue => Object.is(ignoredValue, parsedValue))) {
    return null;
  }

  return parsedValue;
};

const setLocalStorageValue = <Value>(key: string, value: Value | null) => {
  if (value === null) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const useLocalStorage = <Value = any>(
  key: string,
  initialValue?: Value | null,
  ignoredValues?: Value[],
): [Value | null, (value: Value | null) => void] => {
  const [value, setValue] = useState<Value | null>(
    initialValue === undefined ? getLocalStorageValue(key, ignoredValues) : initialValue,
  );

  const updateValue = useCallback(
    (value: Value | null) => {
      setLocalStorageValue(key, value);
      setValue(value);
      window.dispatchEvent(new CustomEvent(WINDOW_EVENT_KEY));
    },
    [key],
  );

  useEffect(() => {
    const handler = () => setValue(getLocalStorageValue(key, ignoredValues));
    window.addEventListener(WINDOW_EVENT_KEY, handler);

    return () => {
      window.removeEventListener(WINDOW_EVENT_KEY, handler);
    };
  }, [key, ignoredValues]);

  useEffect(() => {
    if (initialValue !== undefined) {
      updateValue(initialValue);
    }
  }, [initialValue, updateValue]);

  return [value, updateValue];
};

import { PageName } from '@clef/shared/constants';
import { LabelType, UserPermission } from '@clef/shared/types';
import { useMemo } from 'react';
import { useHasPermission } from '../../../../hooks/useProjectRolePermissions';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useGetLayoutListQuery } from '@/serverStore/layout';

export const useSidebarInfo = () => {
  const { data: selectedProject } = useGetSelectedProjectQuery();

  const {
    data: layoutInfo,
    isLoading: layoutInfoLoading,
    error: layoutInfoError,
  } = useGetLayoutListQuery();
  const canDeployModel = useHasPermission(UserPermission.DeployModel);
  return useMemo(() => {
    if (!selectedProject) {
      return [undefined, false, undefined] as const;
    }
    if (!layoutInfo) {
      return [layoutInfo, layoutInfoLoading, layoutInfoError] as const;
    }
    const { allowedSections } = layoutInfo;

    let sections = [...allowedSections];

    if (!canDeployModel) {
      sections = sections.filter(page => page !== PageName.DeploymentPage);
    }
    if (selectedProject.labelType === LabelType.SegmentationInstantLearning) {
      sections = sections.filter(
        page => page !== PageName.ModelListPage && page !== PageName.MyTasks,
      );
    }
    if (selectedProject.labelType === LabelType.AnomalyDetection) {
      sections = sections.filter(page => page !== PageName.MyTasks);
    }

    return [
      { ...layoutInfo, allowedSections: sections },
      layoutInfoLoading,
      layoutInfoError,
    ] as const;
  }, [canDeployModel, layoutInfo, layoutInfoError, layoutInfoLoading, selectedProject]);
};

import { useHistory } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { format } from 'date-fns';

import { DatasetVersion, ClientAdvancedTrainingState } from '@clef/shared/types';
import CLEF_PATH from '@/constants/path';
import { TransformUISchema } from '@/types/client';
import { DefectDistributionWithAssignment } from '@clef/shared/utils/auto_split_core_algorithm';

import { createAtom, createWritableAtom } from '../utils';

export enum CustomTrainingStepMode {
  SplitSetup = 'SplitSetup',
  ModelsConfiguration = 'ModelsConfiguration',
}

export const currentStepAtom = createAtom<CustomTrainingStepMode>(
  'currentStep',
  CustomTrainingStepMode.SplitSetup,
);

export const selectedDatasetVersionAtom = createAtom<DatasetVersion | null>(
  'selectedDatasetVersion',
  null,
);

export interface ModelConfig {
  tempId: string | null;
  name: string;
  description?: string;
  config: ClientAdvancedTrainingState;
  transformUiSchema: TransformUISchema[];
  cost?: Record<string, number | undefined>;
}

export const defaultTrainingConfig = {
  // By default, train:dev:test = 80:20:0
  splitRadio: [80, 20, 0],
  transforms: [],
  augmentations: [],
  defaultParameters: {},
  trainingParams: undefined,
  limits: undefined,
} as ClientAdvancedTrainingState;

export const defaultModelConfig: ModelConfig = {
  tempId: null,
  name: `Model-${format(new Date(), 'MM-dd-yyyy')}`,
  description: undefined,
  config: defaultTrainingConfig,
  transformUiSchema: [],
};

export const enableAdjustSplitAtom = createAtom<boolean>('enableAdjustSplit', true);

export const defectDistributionWithAssignmentAtom = createAtom<DefectDistributionWithAssignment[]>(
  'defectDistributionWithAssignment',
  [],
);

export const modelsConfigListAtom = createAtom<ModelConfig[]>('modelsConfigList', []);

export const autoFocusNameModelAtom = createAtom<string | null>('autoFocusNameModel', null);

export const isInProgressAtom = createAtom<boolean>('isInProgress', false);

export enum DatasetTrainingIssueType {
  NotEnoughLabeledMedias = 'notEnoughLabeledMedias',
  NotEnoughMediasInTrainSplit = 'notEnoughMediasInTrainSplit',
  InvalidClassificationTrain = 'invalidClassificationTrain',
}

export const datasetTrainingIssuesAtom = createAtom<DatasetTrainingIssueType[]>(
  'datasetTrainingIssues',
  [],
);

export const configErrorsAtom = createAtom<boolean>('configErrors', false);

export const duplicateNameIdsAtom = createAtom<string[]>('duplicateNameIds', []);

export const showLargeImagesWarningAtom = createAtom<boolean>('showLargeImagesWarning', false);

export const resetCustomTrainingAtomsAtom = createWritableAtom(
  'resetCustomTrainingAtoms',
  null,
  (get, set) => {
    set(currentStepAtom, CustomTrainingStepMode.SplitSetup);
    set(selectedDatasetVersionAtom, null);
    set(enableAdjustSplitAtom, true);
    set(defectDistributionWithAssignmentAtom, []);
    set(modelsConfigListAtom, []);
    set(isInProgressAtom, false);
    set(datasetTrainingIssuesAtom, []);
    set(configErrorsAtom, false);
    set(duplicateNameIdsAtom, []);
    set(autoFocusNameModelAtom, null);
    set(showLargeImagesWarningAtom, false);
  },
);

export const useResetAndBackToBuild = () => {
  const history = useHistory();
  const resetCustomTraining = useSetAtom(resetCustomTrainingAtomsAtom);

  return () => {
    resetCustomTraining(true);
    history.push(`${CLEF_PATH.data.dataBrowser}`);
  };
};

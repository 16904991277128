import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles, Grid, Typography, Tooltip } from '@material-ui/core';
import { toPercentSingleDecimal } from './helpers';

const useStyles = makeStyles(theme => ({
  chartContainer: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  svgContainer: {
    background: '#fff',
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    maxHeight: 240,
    maxWidth: 240,
  },
  centerText: {
    fontSize: '0.4rem',
    letterSpacing: '0.03333em',
    fontWeight: 500,
    transition: '1s ease',
  },
  circle: {
    fill: 'transparent',
    transition: '1s ease-in-out',
    transform: 'rotate(-90deg)',
    transformOrigin: '50% 50%',
  },
  chartLabel: {
    marginBottom: theme.spacing(3),
    width: 240,
  },
  colorBlock: {
    height: theme.spacing(5),
    width: theme.spacing(10),
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(2),
  },
  chartLabelName: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: 100,
  },
  chartLabelStats: {
    whiteSpace: 'nowrap',
  },
}));

export interface PieChartProps {
  variant?: 'pie' | 'doughnut';
  chartData: { value: number; name: string; color: string; tooltip?: React.ReactFragment }[];
  doughnutCenterText?: (totalValue: number) => string;
}

const chartRadius = 50;

const PieChart: React.FC<PieChartProps> = ({ variant = 'pie', chartData, doughnutCenterText }) => {
  const styles = useStyles();
  const [startAnimation, setStartAnimation] = useState(false);
  useEffect(() => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setStartAnimation(true);
      });
    });
  });

  const totalValue = useMemo(
    () => chartData.reduce((acc, column) => acc + column.value, 0),
    [chartData],
  );
  const circumference = 2 * Math.PI * chartRadius;

  return (
    <>
      {/* Pie chart or Doughnut chart */}
      <div className={styles.chartContainer}>
        <svg viewBox={`0 0 ${2 * chartRadius} ${2 * chartRadius}`} className={styles.svgContainer}>
          {variant === 'doughnut' && doughnutCenterText && (
            <text
              x="50%"
              y="50%"
              dominantBaseline="middle"
              textAnchor="middle"
              style={{
                opacity: startAnimation ? 1 : 0,
              }}
              className={styles.centerText}
            >
              {doughnutCenterText(totalValue)}
            </text>
          )}
          {chartData.map((column, index) => {
            const percentOfC = startAnimation ? (column.value / totalValue) * circumference : 0;
            const previousAccValue = startAnimation
              ? chartData.slice(0, index).reduce((acc, column) => acc + column.value, 0)
              : 0;
            const offsetOfC = (-previousAccValue / totalValue) * circumference;
            return (
              <circle
                key={column.name}
                r={chartRadius}
                cx={chartRadius}
                cy={chartRadius}
                className={styles.circle}
                stroke={column.color}
                strokeWidth={variant === 'pie' ? chartRadius * 2 : chartRadius * 0.8}
                strokeDasharray={`${percentOfC} ${circumference}`}
                strokeDashoffset={offsetOfC}
              />
            );
          })}
        </svg>
      </div>
      {/* Chart labels */}
      {chartData.map(column => {
        return (
          <Grid
            container
            direction="row"
            alignItems="center"
            wrap="nowrap"
            justifyContent="space-between"
            className={styles.chartLabel}
            key={column.name}
            data-testid={`pie-chart-${column.name}-label`.replace(/\s/g, '')}
          >
            <Grid container item direction="row" alignItems="center">
              <Tooltip title={column.tooltip || ''} placement="right" arrow>
                <div
                  className={styles.colorBlock}
                  style={{
                    backgroundColor: column.color,
                  }}
                ></div>
              </Tooltip>
              <Typography variant="body2" component="div" className={styles.chartLabelName}>
                <strong>{column.name}</strong>
              </Typography>
            </Grid>
            <Typography variant="body2" className={styles.chartLabelStats} component="div">
              {column.value} ({toPercentSingleDecimal(column.value / totalValue)}%)
            </Typography>
          </Grid>
        );
      })}
    </>
  );
};

export default PieChart;

import React from 'react';
import { makeStyles, TableCellProps, Theme } from '@material-ui/core';
import classNames from 'classnames';

export type ModelAnalysisTableCellProps = TableCellProps & {
  highlight?: boolean;
  disabled?: boolean;
  selected?: boolean;
  stickyLeft?: boolean;
  stickyRight?: boolean;
  fixedWidth?: number | string;
  stickyPosition?: number | string;
  zIndex?: number;
};

export const useTableCellStyles = makeStyles<
  Theme,
  { fixedWidth: number | string; stickyPosition: number | string; zIndex: number },
  string
>(theme => ({
  highlightCell: {
    border: `1px solid ${theme.palette.blue[300]} !important`,
    boxShadow: `inset 0px 0px 8px 0.5px ${theme.palette.blue[100]}, 0px 0px 8px 0.5px ${theme.palette.blue[100]}`,
  },
  disabledCell: {
    '& div': {
      cursor: 'not-allowed',
      color: `${theme.palette.greyModern[400]}`,
    },
  },
  selectedCell: {
    backgroundColor: `${theme.palette.blue[50]} !important`,
  },
  leftStickyCell: {
    position: 'sticky',
    width: props => props.fixedWidth,
    maxWidth: props => props.fixedWidth,
    minWidth: props => props.fixedWidth,
    left: props => props.stickyPosition,
    zIndex: 2,
  },
  rightStickyCell: {
    position: 'sticky',
    width: props => props.fixedWidth,
    minWidth: props => props.fixedWidth,
    maxWidth: props => props.fixedWidth,
    right: props => props.stickyPosition,
    zIndex: 2,
  },
  defaultCell: {
    width: props => props.fixedWidth,
    minWidth: props => props.fixedWidth,
    maxWidth: props => props.fixedWidth,
  },
  zIndexCell: {
    zIndex: props => props.zIndex,
  },
}));

const DEFAULT_CELL_WIDTH = 116;
const DEFAULT_STICKY_POSITION = 0;

export const withModelAnalysisTableCellProps = (
  WrappedComponent: React.ComponentType<ModelAnalysisTableCellProps>,
) => {
  const HocComponent: React.FC<ModelAnalysisTableCellProps> = props => {
    const {
      highlight,
      disabled,
      selected,
      className,
      stickyLeft,
      stickyRight,
      fixedWidth = DEFAULT_CELL_WIDTH,
      stickyPosition = DEFAULT_STICKY_POSITION,
      zIndex = 0,
      ...otherProps
    } = props;
    const styles = useTableCellStyles({ fixedWidth, stickyPosition, zIndex });
    return (
      <WrappedComponent
        className={classNames(
          className,
          styles.defaultCell,
          highlight && styles.highlightCell,
          disabled && styles.disabledCell,
          selected && styles.selectedCell,
          stickyLeft && styles.leftStickyCell,
          stickyRight && styles.rightStickyCell,
          zIndex && styles.zIndexCell,
        )}
        {...otherProps}
      />
    );
  };
  return HocComponent;
};

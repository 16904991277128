import { isLegacyOrganizationLevelPath, isLegacyPath } from '@/constants/path';
import { UTMParams } from '@clef/shared/types';

export const extractIdFromUrl = (): {
  projectId?: number;
  orgId?: number;
} => {
  // project page path: /app/1/pr/618940/data/databrowser
  // org page path: /app/1/pr/618940/projects
  const urlRegex = /\/app\/(\d+)\/(?:pr\/(\d+)\/)?/;
  const match = urlRegex.exec(location.pathname);

  return match ? { orgId: Number(match[1]), projectId: Number(match[2]) } : {};
};

export const isOnProjectPage = (url: string = window.location.pathname) => {
  const pattern = /\/app\/\d+\/pr\/\d+/;
  return !!url.match(pattern);
};

export const getSearchParamsValue = (url: string, key: string): string => {
  if (!url) {
    return '';
  }

  const subUrl = url.split('?');

  if (subUrl.length === 2) {
    const path = subUrl[1];
    const params = path.split('&').filter(param => !!param);

    for (const param of params) {
      const [paramKey, paramValue] = param.split('=');
      if (paramKey === key) {
        return paramValue ?? '';
      }
    }
  }

  return '';
};

export const isOnMediaDetailsDialog = (url: string = window.location.href) => {
  return !!getSearchParamsValue(url, 'openMediaDetail');
};

export const isOnDataBrowserPage = (url: string = window.location.pathname) => {
  const pattern = /^\/app\/\d+\/pr\/\d+\/data\/databrowser/;
  return pattern.test(url);
};

export const isProjectLevelPage = (url: string = window.location.pathname) => {
  if (isLegacyPath(url)) {
    return !isLegacyOrganizationLevelPath(url);
  }
  const pattern = /^\/app\/\d+\/pr\/\d+\//;
  return pattern.test(url);
};

export const isModelPerformancePanelOpened = (url: string = window.location.href) => {
  return getSearchParamsValue(url, 'rightSidebar') === 'model_performance';
};

export const parseUTMParams = (search: string = window.location.search): UTMParams | undefined => {
  const params = new URLSearchParams(search);
  const utm_campaign = params.get('utm_campaign');
  const utm_source = params.get('utm_source');
  const utm_content = params.get('utm_content');
  const utm_term = params.get('utm_term');
  const utm_medium = params.get('utm_medium');
  const utmParams = { utm_campaign, utm_source, utm_content, utm_term, utm_medium };
  return Object.values(utmParams).every(el => el === null) ? undefined : utmParams;
};

export const pickUTMParams = (search: string = window.location.search): URLSearchParams => {
  const params = new URLSearchParams(search);
  const utmKeys = ['utm_campaign', 'utm_source', 'utm_content', 'utm_term', 'utm_medium'];
  const newParams = new URLSearchParams();
  utmKeys.forEach(key => {
    if (params.has(key)) {
      newParams.set(key, params.get(key)!);
    }
  });
  return newParams;
};

import React, { useState } from 'react';
import { Tooltip, makeStyles, Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import cx from 'classnames';
import { useSnackbar } from 'notistack';
import { isNumber } from 'lodash';

import { IconButton, Button } from '@clef/client-library';
import { BundleStatus } from '@clef/shared/types';

import DeployModelDialogV3 from '@/components/Dialogs/DeployModelDialog/DeployModelDialogV3';
import { EndpointIcon } from '@/pages/deployment/components/EndpointIcon';
import {
  isModelTrainingInProgress,
  isModelTrainingSuccessful,
} from '@/store/projectModelInfoState/utils';
import { RegisteredModelWithThresholdAndEndpoints } from '@/api/model_api';
import {
  isBundleInDeployableState,
  isBundleInEndState,
  useGetBundleStatusQuery,
} from '@/serverStore/modelAnalysis';
import { usePutBundleMutation } from '@/serverStore/endpoints';
import { useGetActiveProjectsQuery, useGetSelectedProjectQuery } from '@/serverStore/projects';
import { THRESHOLD_UNDEFINED } from '../ModelPerformanceTable';
import { FIXED_WIDTH_ENDPOINTS_COLUMN, STICKY_POSITION_ENDPOINTS_COLUMN } from '../constants';
import { StyledTableCell } from './StyledTableCell';
import ActiveProjectDialog from '../ActiveProjectDialog';
import {
  useActiveProjectsEnabled,
  useIsNonStripeUser,
  useCheckActiveProjectReachLimit,
} from '@/hooks/useSubscriptions';
import CLIBAPI from '@/api/clib_api';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';

const useStyles = makeStyles(theme => ({
  operationIconBtn: {
    height: 28,
    width: 28,
  },
  operationIconSvg: {
    height: 18,
    width: 18,
    color: theme.palette.greyModern[400],
  },
  operationIcon: {
    fontSize: 24,
    color: theme.palette.greyModern[400],
  },
  deployButton: {
    border: `1px dashed ${theme.palette.greyModern[400]}`,
    color: theme.palette.blue[600],
  },
  deployButtonSmall: {
    minWidth: 'auto !important',
    width: 28,
    height: 28,
    padding: theme.spacing(2.5),
    borderRadius: '5px',
  },
  endpointIconBtn: {
    height: 28,
    width: 28,
  },
}));

type DeployTableCellProps = {
  model: RegisteredModelWithThresholdAndEndpoints;
  threshold: number;
  bundleId: string | null;
  onEndpointIconClick: (endpointId: string) => void;
};

const DeployTableCell = (props: DeployTableCellProps) => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { model, threshold, bundleId, onEndpointIconClick } = props;

  const { data: bundleStatus, isLoading: isBundleStatusLoading } = useGetBundleStatusQuery(
    model.id,
    threshold,
  );
  const [deployDialogOpen, setDeployDialogOpen] = useState(false);
  const { data: project } = useGetSelectedProjectQuery();
  const { id: projectId } = project ?? {};
  const putBundle = usePutBundleMutation(model.modelName ?? '');

  const [activeProjectDialogOpen, setActiveProjectDialogOpen] = React.useState(false);
  const { hasReachLimit } = useCheckActiveProjectReachLimit();
  const activeProjectEnabled = useActiveProjectsEnabled();
  const isNonStripeUser = useIsNonStripeUser();

  const { data: activeProjects } = useGetActiveProjectsQuery();
  const isSelectedProjectActive = !!activeProjects?.find(
    project => project.id === (projectId ?? 0),
  );

  const shouldAllowDownloadingBundle = useFeatureGateEnabled(
    ClientFeatures.ShouldAllowDownloadingBundle,
  );

  const canDirectlyDownloadModelBundle =
    !hasReachLimit && isNonStripeUser && isSelectedProjectActive;

  const downloadModelBundle = async (bundleId: string) => {
    if (!projectId) return;
    const { bundle_zip_url } = await CLIBAPI.downloadBundle(bundleId);
    window.open(bundle_zip_url);
  };

  const handleDownloadModelClick = (bundleId: string) => {
    if (activeProjectEnabled) {
      // if not reach limit, enterprise account can directly download model from active projects
      if (canDirectlyDownloadModelBundle) {
        downloadModelBundle(bundleId);
      } else {
        setActiveProjectDialogOpen(true);
      }
    } else if (shouldAllowDownloadingBundle) {
      downloadModelBundle(bundleId);
    }
  };

  const onCreateBundle = async () => {
    if (!projectId) return;
    if (!model.modelName || !isNumber(threshold)) {
      return;
    }
    if (!isModelTrainingSuccessful(model.status, model.metricsReady)) {
      enqueueSnackbar(t('The model has not yet finish training, Please try again later.'), {
        variant: 'warning',
        autoHideDuration: 12000,
      });
      return;
    }
    enqueueSnackbar(
      t(`A version of "${model.modelName}, confidence threshold ${threshold}" is generating`),
      { variant: 'info' },
    );
    await putBundle.mutateAsync({
      projectId,
      modelId: model.id,
      threshold: threshold,
    });
  };

  const DeployButton = (props: {
    isBundleStatusLoading: boolean;
    isModelTrainingInProgress: boolean;
    status: BundleStatus | undefined;
    isDeployable: boolean;
  }) => {
    const { isBundleStatusLoading, status, isDeployable, isModelTrainingInProgress } = props;
    if (isModelTrainingInProgress) {
      return (
        <Tooltip arrow={true} placement="top" title={t('Model training in progress...')}>
          <Box>{'--'}</Box>
        </Tooltip>
      );
    }
    if (!isDeployable) {
      return (
        <IconButton
          tooltip={t(
            'This model does not have confidence threshold, could not be deployed at this moment',
          )}
          disabled
          className={styles.operationIconBtn}
        >
          <ErrorOutlineIcon className={styles.operationIcon} />
        </IconButton>
      );
    }
    // bundle status: not found
    if (!isBundleInDeployableState(status)) {
      return (
        <IconButton
          tooltip={t('Save this bundle for deployment')}
          className={styles.operationIconBtn}
          onClick={onCreateBundle}
        >
          <RefreshIcon className={styles.operationIcon} />
        </IconButton>
      );
    }
    // bundle status: created or deployed
    return (
      <>
        <Tooltip
          arrow
          title={
            (isBundleStatusLoading && t('Loading bundle status...')) ||
            (!isBundleInEndState(status) &&
              t('Preparing the model for deployment, please wait...')) ||
            ''
          }
        >
          <div>
            <Button
              id="deploy-button"
              variant="text"
              className={cx(
                styles.deployButton,
                model.endpoints.length > 0 && styles.deployButtonSmall,
              )}
              onClick={() => {
                setDeployDialogOpen(true);
              }}
              disabled={isBundleStatusLoading || !isBundleInEndState(status)}
            >
              <AddIcon fontSize="small" />
              {model.endpoints.length === 0 ? t('Deploy') : ''}
            </Button>
          </div>
        </Tooltip>
        {model.endpoints.map(endpoint => (
          <Box key={endpoint.id} ml={2}>
            <IconButton
              className={styles.endpointIconBtn}
              tooltip={`${endpoint.name}`}
              onClick={() => onEndpointIconClick(endpoint.id)}
            >
              <EndpointIcon size={28} isCloudEndpoint={endpoint.isCloud} endpointId={endpoint.id} />
            </IconButton>
          </Box>
        ))}
      </>
    );
  };

  return (
    <StyledTableCell
      disabled={false}
      stickyRight={true}
      fixedWidth={FIXED_WIDTH_ENDPOINTS_COLUMN}
      stickyPosition={STICKY_POSITION_ENDPOINTS_COLUMN}
    >
      <Box display="flex" flexDirection="row" alignItems="center" ml={4}>
        <DeployButton
          isModelTrainingInProgress={isModelTrainingInProgress(model.status, model.metricsReady)}
          isBundleStatusLoading={isBundleStatusLoading}
          status={bundleStatus?.status}
          isDeployable={threshold !== THRESHOLD_UNDEFINED}
        />
        {bundleId && (
          <DeployModelDialogV3
            open={deployDialogOpen}
            onClose={() => {
              setDeployDialogOpen(false);
            }}
            modelInfo={{ id: model.id, threshold, bundleId: bundleId }}
            onDownloadModelClicked={() => {
              handleDownloadModelClick(bundleId);
            }}
          />
        )}
      </Box>
      {activeProjectEnabled && (
        <ActiveProjectDialog
          open={activeProjectDialogOpen}
          onClose={() => {
            setActiveProjectDialogOpen(false);
          }}
          projectId={projectId ?? 0}
          canCommercialUse={isNonStripeUser}
          isSelectedProjectActive={isSelectedProjectActive}
          hasReachLimit={hasReachLimit}
          downloadModelBundle={() => {
            bundleId && downloadModelBundle(bundleId);
          }}
        />
      )}
    </StyledTableCell>
  );
};

export default DeployTableCell;

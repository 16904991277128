import React, { useCallback, useEffect, useState } from 'react';
import { Skeleton } from '@material-ui/lab';

import EventLogger from '../../logging/EventLogger';
import { Box } from '@material-ui/core';

export interface ImgProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  fallbackSrc?: string;
  errorLogging?: boolean;
}

export class ImageLoadingError extends Error {
  constructor(...args: Parameters<ErrorConstructor>) {
    super(...args);
    this.name = 'ImageLoadingError';
  }
}

export const Img: React.FC<ImgProps> = ({
  src,
  fallbackSrc,
  errorLogging = true,
  onError,
  className,
  style,
  ...rest
}) => {
  const [imageSrc, setImageSrc] = useState(src ?? fallbackSrc);

  useEffect(() => {
    if (src) {
      setImageSrc(src);
    }
  }, [src]);

  const internalOnError = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
      if (errorLogging) {
        const imageElem = (event.target ?? {}) as HTMLImageElement;
        const expireString = new URL(imageElem?.src)?.searchParams?.get('Expires') ?? '0';

        EventLogger.error(
          // Sending error will provide stack trace for Sentry
          // Reference: https://docs.sentry.io/platforms/javascript/usage/#capturing-errors
          new ImageLoadingError(`Failed to load image ${imageElem?.outerHTML}`),
          {
            'Image Details': {
              Source: imageElem?.src,
              CORS: imageElem?.crossOrigin,
              Now: String(new Date()),
              Expire: String(new Date(parseInt(expireString) * 1000)),
            },
          },
          ['FailedToLoadImage'],
        );
      }

      if (fallbackSrc && imageSrc !== fallbackSrc) {
        setImageSrc(fallbackSrc);
      }
    },
    [errorLogging, fallbackSrc, imageSrc],
  );

  if (!src) {
    return (
      <Box bgcolor="rgba(0, 0, 0, 0.08)" className={className} style={style}>
        <Skeleton height="100%" width="100%" variant="rect" />
      </Box>
    );
  }

  return (
    <img
      {...rest}
      src={imageSrc}
      onError={e => {
        internalOnError(e);
        onError?.(e);
      }}
      className={className}
      style={style}
    />
  );
};

export default Img;

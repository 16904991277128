import React, { useCallback, useState } from 'react';
import { Box, Dialog, makeStyles, Paper } from '@material-ui/core';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import fireworksSvg from '@/images/fireworks.svg';
import starSvg from '@/images/star.svg';
import { Button, useLocalStorage } from '@clef/client-library';
import { useGetUsageSummary } from '@/serverStore/usage';

const useStyles = makeStyles(theme => ({
  dialog: {
    fontSize: 14,
  },
  dialogPaper: {
    width: 490,
  },
  headerBackground: {
    height: 320,
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: 261,
      height: 261,
    },
  },
  title: {
    fontFamily: 'Commissioner',
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  subtitle: {
    fontFamily: 'Commissioner',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
}));

export type FreeTrialWelcomeDialogProps = {};

const FreeTrialWelcomeDialog: React.FC<FreeTrialWelcomeDialogProps> = () => {
  const [open, setOpen] = useState(true);
  const styles = useStyles();
  const { data: usageSummary } = useGetUsageSummary();
  const orgId = useTypedSelector(state => state.login.user)?.orgId!;
  const [skipWelcomeOrgIds, setSkipWelcomeOrgIds] = useLocalStorage<number[]>('skipWelcomeOrgIds');
  const dontShowAgain = useCallback(() => {
    setSkipWelcomeOrgIds((skipWelcomeOrgIds ?? []).concat(orgId));
  }, [orgId, setSkipWelcomeOrgIds, skipWelcomeOrgIds]);

  if (!usageSummary) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        dontShowAgain();
      }}
      className={styles.dialog}
      classes={{ paper: styles.dialogPaper }}
    >
      <Paper>
        <Box className={styles.headerBackground}>
          <img src={fireworksSvg} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" padding={7}>
          <Box className={styles.title} marginBottom={5}>
            {t('Welcome to your free trial!')}
          </Box>
          <Box className={styles.subtitle} marginBottom={6}>
            {t('Explore LandingLens with 1000 credits on us')}
          </Box>
          <Box display="flex" alignItems="center" marginBottom={6}>
            <Box marginRight={5}>
              <img src={starSvg} />
            </Box>
            <Box maxWidth={300} textAlign="left">
              {t(
                'Credits can be used for training models or making predictions. A credit is equal to 1 image trained or 1 image inferenced (used for prediction).',
              )}
            </Box>
          </Box>
          <Button
            id="start-exploration"
            onClick={() => {
              setOpen(false);
              dontShowAgain();
            }}
            color="primary"
            variant="contained"
            fullWidth
          >
            {t('Start Exploration Now')}
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default FreeTrialWelcomeDialog;

import React, { useMemo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useTypographyStyles } from '@clef/client-library/src/themes/typography';
import Step from './Step';
import labelingStepGuide1 from '@/images/instant_learning/labeling_step_guide_1.gif';
import labelingStepGuide2 from '@/images/instant_learning/labeling_step_guide_2.gif';
import { useLabeledClassIds } from '../utils';
import { useDefectSelector } from '@/store/defectState/actions';

const useStyles = makeStyles(() => ({
  labelingStepGuide: {},
}));

type LabelingStepGuideProps = {
  labeledClasses?: number;
  disableCreateClass?: boolean;
};

const LabelingStepGuide: React.FC<LabelingStepGuideProps> = props => {
  const { labeledClasses, disableCreateClass = false } = props;
  const styles = useStyles();
  const typographyStyles = useTypographyStyles();
  const labeledClassIdsSet = useLabeledClassIds();
  const validClasses = useDefectSelector(false).length;

  const currentStep = Math.min(2, labeledClasses ?? labeledClassIdsSet.size, validClasses);
  const stepsData = useMemo(
    () => [
      {
        title: t('Label your first Class'),
        doneTitle: t('You labeled your first Class'),
        content: t('You only need to label {{bold}}.', {
          bold: <strong>{t('a small area')}</strong>,
        }),
        image: labelingStepGuide1,
      },
      {
        title: t('Label another Class'),
        doneTitle: t('You labeled another Class'),
        content: t('The Model needs {{bold}} to make meaningful predictions.', {
          bold: <strong>{t('at least two Classes')}</strong>,
        }),
        tips: t('You can label the background as one Class.'),
        image: labelingStepGuide2,
      },
      {
        title: t('Run your first Model'),
        content: (
          <>
            <Box>
              {t('A few labeled areas are enough to run your Model! {{bold}}.', {
                bold: <strong>{t('Hit the "Run" button now')}</strong>,
              })}
            </Box>
            {!disableCreateClass && (
              <Box marginTop={2.5}>
                {t(
                  'Have more than 2 classes you want to detect? Use the drop-down on the top left and {{bold}}.',
                  {
                    bold: <strong>{t('choose "Add Class"')}</strong>,
                  },
                )}
              </Box>
            )}
          </>
        ),
      },
    ],
    [disableCreateClass],
  );

  return (
    <Box className={styles.labelingStepGuide}>
      <Box className={typographyStyles.h2_semibold} paddingLeft={25} marginY={7}>
        {t('Get ready to run your Model')}
      </Box>
      <Box paddingX={5}>
        {stepsData.map((stepData, index) => (
          <Step key={index} stepIndex={index} currentStep={currentStep} {...stepData} />
        ))}
      </Box>
    </Box>
  );
};

export default LabelingStepGuide;

import { useMemo } from 'react';
import { FileWithPath } from 'react-dropzone';
import { dirname } from 'path';

import { useTypedSelector } from './useTypedSelector';

export interface UploadFileBlock {
  folderName: string;
  files: FileWithPath[];
}

const EMPTY_FOLDER_NAME = '';

const getFolder = (file: FileWithPath) => {
  if (file?.path) {
    const folderName = dirname(file.path);
    return folderName === '.' ? EMPTY_FOLDER_NAME : folderName.slice(1).split('/')[0]; // Only consider the top-level folder
  }
  return EMPTY_FOLDER_NAME;
};

/**
 * Separate file objects into different groups, where each group shares the same folder.
 *
 * E.g.
 *    We have the following input params:
 *
 *    files = [
 *      { name: 'A', folder: '' },
 *      { name: 'B', folder: 'Folder 1' },
 *      { name: 'C', folder: '' },
 *      { name: 'D', folder: '' },
 *      { name: 'E', folder: 'Folder 2' },
 *      { name: 'F', folder: '' },
 *    ];
 *
 *    getFolder = (file) => file.folder;
 *
 *    The expected output should be:
 *
 *    output = [
 *      [{ name: 'B', folder: 'Folder 1' }],
 *      [{ name: 'E', folder: 'Folder 2' }],
 *      [
 *        { name: 'A', folder: '' },
 *        { name: 'C', folder: '' },
 *        { name: 'D', folder: '' },
 *        { name: 'F', folder: '' },
 *      ],
 *    ];
 *
 *    Please note, the groups which has a folder will be placed in the original order, while the group which
 *    does not have a folder will be placed based the position of the last non-folder file object.
 *
 * Assumption: all file objects which has a different folder name should be adjacent in the input list, e.g.
 * input files like the following is not allowed:
 *     files = [
 *      { name: 'A', folder: 'Folder 1' },
 *      { name: 'B', folder: 'Folder 2' },
 *      { name: 'C', folder: 'Folder 1' },
 *    ];
 *
 * @param files array of file objects
 * @param getFolder function to retrieve the folder name of a given file object
 * @returns array of sub-array of file objects, where each sub-array of file objects shares the same folder
 */
function separateFiles<T>(files: T[], getFolder: (file: T) => string): T[][] {
  const blocks: T[][] = [];
  let lastNonFolderBlockIndex: number | null = null;
  let currentBlock: T[] | null = null;
  let currentBlockFolder: string | null = null;

  for (const file of files) {
    const folder = getFolder(file);

    // If first time, initialize with the first file
    if (currentBlock === null) {
      currentBlockFolder = folder;
      currentBlock = [file];
    }
    // If not first time and the current file's folder does not equal to the current block's folder, ...
    else if (folder !== currentBlockFolder) {
      // If the current block is a non-folder block, mark the location of the block and add it to blocks
      if (currentBlockFolder === EMPTY_FOLDER_NAME) {
        lastNonFolderBlockIndex = blocks.length;

        blocks.push([...currentBlock]);
        currentBlock = [file];
        currentBlockFolder = folder;
      }
      // If the current block is a folder block, ...
      else {
        // If the current block is a folder block and the current file has no folder, remove the previous
        // non-folder block (if exists) and combine with the current file for the current block
        let lastNonFolderBlock: T[] = [];
        if (folder === EMPTY_FOLDER_NAME && lastNonFolderBlockIndex !== null) {
          lastNonFolderBlock = blocks.splice(lastNonFolderBlockIndex, 1)[0];
        }

        blocks.push([...currentBlock]);
        currentBlock = [...lastNonFolderBlock, file];
        currentBlockFolder = folder;
      }
    }
    // If not first time and the current file's folder equals to the current block's folder, directly add to blocks
    else {
      currentBlock.push(file);
    }
  }
  if (currentBlock) {
    blocks.push(currentBlock);
  }

  return blocks;
}

const useUploadFileBlockQueue = () => {
  const { uploadData, segmentationMasks, defectMap } = useTypedSelector(state => state.uploadState);

  const imageBlocks = useMemo((): UploadFileBlock[] => {
    const files = uploadData.map(item => item.file);
    return separateFiles(files, getFolder).map(files => ({
      folderName: getFolder(files[0]),
      files: files,
    }));
  }, [uploadData]);

  const maskBlocks = useMemo((): UploadFileBlock[] => {
    if (!segmentationMasks) {
      return [];
    }

    const files = segmentationMasks.map(item => item.file);
    return separateFiles(files, getFolder).map(files => ({
      folderName: getFolder(files[0]),
      files: files,
    }));
  }, [segmentationMasks]);

  const defectMapBlocks = useMemo((): UploadFileBlock[] => {
    if (!defectMap) {
      return [];
    }
    return [
      {
        folderName: getFolder(defectMap.file),
        files: [defectMap.file],
      },
    ];
  }, [defectMap]);

  return {
    imageBlocks,
    maskBlocks,
    defectMapBlocks,
  };
};

export default useUploadFileBlockQueue;

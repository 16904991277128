import React, { useRef, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import PlayArrow from '@material-ui/icons/PlayArrow';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  container: {
    cursor: 'pointer',
  },
  video: {
    width: '100%',
    display: 'block',
    objectFit: 'fill',
  },
  playButton: {
    position: 'absolute',
    width: 50,
    height: 50,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    padding: 0,
    boxShadow:
      '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
    background: 'rgba(0, 0, 0, 0.1)',
  },
  playButtonLabel: {
    height: '100%',
  },
  playButtonIcon: {
    border: `3px solid ${theme.palette.common.white}`,
    borderRadius: '50%',
    width: '100%',
    height: '100%',
  },
}));

export type IntroVideoProps = {
  className?: string;
  src?: string;
};

export const IntroVideo: React.FC<IntroVideoProps> = ({ className, src }) => {
  const styles = useStyles();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const hasPlayed = useRef(false);
  const isSnowflake = process.env.IS_SNOWFLAKE === 'true';
  const posterUrl = isSnowflake
    ? 'https://landing-lens-support.s3.us-east-2.amazonaws.com/img/snowflake_native_app_poster.png'
    : 'https://landing-lens-support.s3.us-east-2.amazonaws.com/video/FRE+video_1920_poster.jpg';

  return (
    <Box
      position="relative"
      className={cx(styles.container, className)}
      onClick={() => {
        if (!hasPlayed.current) {
          videoRef.current?.play();
        }
      }}
    >
      <video
        ref={videoRef}
        poster={posterUrl}
        preload="auto"
        controls={hasPlayed.current}
        src={src}
        className={styles.video}
        onPlay={() => {
          hasPlayed.current = true;
          setIsPlaying(true);
        }}
        onPause={() => setIsPlaying(false)}
      />

      {!isPlaying && (
        <IconButton
          id="intro-video-play-button"
          data-testid="intro-video-play-button"
          className={styles.playButton}
          onClick={() => videoRef.current?.play()}
          classes={{ label: styles.playButtonLabel }}
        >
          <PlayArrow className={styles.playButtonIcon} />
        </IconButton>
      )}
    </Box>
  );
};

export default IntroVideo;

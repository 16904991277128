import React, { useState, useMemo } from 'react';
import { Box, makeStyles, TextField } from '@material-ui/core';
import { TagChip } from '@/components/AddTagDropdown/TagChip';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Typography, IconButton, Button } from '@clef/client-library';
import AddIcon from '@material-ui/icons/Add';
import { uniq } from 'lodash';

const useStyles = makeStyles(theme => ({
  add: {
    color: theme.palette.grey[400],
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: '6px',
  },
  root: {
    minWidth: 250,
  },
  popper: {
    zIndex: 300002,
  },
  createOption: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    fontSize: '14px',
    cursor: 'pointer',
    color: theme.palette.grey[800],
  },
  searchBox: {
    gap: theme.spacing(1),
    borderRadius: '10px',
  },
  unfocusedContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  addTagButton: {
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[200],
    borderRadius: 5,
    padding: '4px 6px',
    height: theme.spacing(6),
  },
  addTagIcon: {
    fontSize: theme.spacing(4),
  },
}));

const filter = createFilterOptions<string>();

export const TagAutocomplete: React.FC<{
  tags: string[];
  tagOptions: string[];
  disabled?: boolean;
  onChangeTags: (tags: string[]) => void;
  handleDisableEscapeKeyDown?: (disableEscapeKeyDown: boolean) => void;
}> = React.memo(({ tags, tagOptions, onChangeTags, disabled, handleDisableEscapeKeyDown }) => {
  const styles = useStyles();
  const [newTagName, setNewTagName] = useState<string>('');
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const sortedOptions = uniq([...tagOptions, ...tags]).sort((tagA, tagB) =>
    tagA.toLowerCase().localeCompare(tagB.toLowerCase()),
  );

  const tagOptionsSet = useMemo(() => {
    return new Set<string>(sortedOptions.map(option => option.toLowerCase()));
  }, [sortedOptions]);

  const onDeleteTag = (tag: string) => {
    const newValue = tags.filter(str => str !== tag);
    onChangeTags(newValue);
  };

  if (!isFocused) {
    return tags.length === 0 ? (
      <Button
        variant="contained"
        size="small"
        id="media-details-dialog-add-tag-button"
        className={styles.addTagButton}
        onClick={() => {
          setIsFocused(true);
          handleDisableEscapeKeyDown?.(true);
        }}
      >
        {t('Add Tag')}
      </Button>
    ) : (
      <Box className={styles.unfocusedContainer}>
        {tags.map(tag => (
          <TagChip key={tag} name={tag} onDelete={() => onDeleteTag(tag)} />
        ))}
        <IconButton
          size="small"
          className={styles.addTagButton}
          id="media-details-dialog-add-tag-icon-button"
          onClick={() => {
            setIsFocused(true);
            handleDisableEscapeKeyDown?.(true);
          }}
        >
          <AddIcon className={styles.addTagIcon} />
        </IconButton>
      </Box>
    );
  }

  return (
    <Autocomplete
      multiple
      selectOnFocus
      autoHighlight={!!newTagName}
      disableCloseOnSelect
      disableClearable
      disabled={disabled}
      classes={{
        root: styles.root,
        popper: styles.popper,
        inputRoot: styles.searchBox,
      }}
      onBlur={() => {
        setIsFocused(false);
        setNewTagName('');
        handleDisableEscapeKeyDown?.(false);
      }}
      value={tags}
      inputValue={newTagName}
      options={sortedOptions}
      handleHomeEndKeys
      popupIcon={null}
      onChange={(_, newValue) => {
        onChangeTags(newValue);
        setNewTagName('');
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (newTagName !== '' && !tagOptionsSet.has(newTagName.toLowerCase())) {
          filtered.push(newTagName);
        }

        return filtered.filter(tag => !tags.includes(tag));
      }}
      renderOption={tag => {
        if (tagOptionsSet.has(tag.toLowerCase())) {
          return <TagChip key={tag} name={tag} />;
        } else {
          return (
            <Box key={tag} className={styles.createOption}>
              <Typography>
                {t('Create tag: {{newTagName}}', {
                  newTagName: <TagChip key={tag} name={newTagName} />,
                })}
              </Typography>
            </Box>
          );
        }
      }}
      renderTags={tags =>
        tags.map(tag => <TagChip key={tag} name={tag} onDelete={() => onDeleteTag(tag)} />)
      }
      renderInput={params => {
        return (
          <TextField
            {...params}
            size="small"
            variant="outlined"
            autoFocus
            value={newTagName}
            onChange={e => setNewTagName(e.target.value)}
            placeholder={tags.length === 0 ? t('Add tags') : ''}
          />
        );
      }}
    />
  );
});

import React, { MutableRefObject } from 'react';
import { Drawer, Box } from '@material-ui/core';
import { useAtomValue } from 'jotai';

import { MediaInteractiveCanvas } from '@clef/client-library';

import { useLabelingState } from '@/components/Labeling/labelingState';
import { getMediaDetailsHotKeySections } from '@/components/Labeling/drawer/HotKeysDrawer';
import { HotKeysDrawer } from '@/components/Labeling/drawer/HotKeysDrawer';
import {
  rightDrawerTypeAtom,
  useCurrentMediaStates,
  RightDrawerType,
} from '@/uiStates/mediaDetails/pageUIStates';

import InformationTab from './InformationTab';
import useStyles from './styles';

interface DetailsDrawerProps {
  mediaCanvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
}

const DetailsDrawer: React.FC<DetailsDrawerProps> = ({ mediaCanvasRef }) => {
  const styles = useStyles();

  const {
    state: { labelingType },
  } = useLabelingState();

  const rightDrawerType = useAtomValue(rightDrawerTypeAtom);
  const currentMediaStates = useCurrentMediaStates();
  const { mediaDetails } = currentMediaStates;

  const currentMediaId = mediaDetails?.id;

  if (!currentMediaId) {
    return null;
  }

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      className={styles.detailsDrawer}
      classes={{
        paper: styles.drawerPaper,
      }}
      open={!!rightDrawerType}
    >
      {/* {rightDrawerType === RightDrawerType.Labels && (
        <LabelListWrapper mediaCanvasRef={mediaCanvasRef} />
      )} */}
      {/* information */}
      {Boolean(currentMediaId) && rightDrawerType === RightDrawerType.Information && (
        <InformationTab mediaCanvasRef={mediaCanvasRef} />
      )}
      {rightDrawerType === RightDrawerType.HotKeys && (
        <Box className={styles.hotkeysDrawerWrapper}>
          <HotKeysDrawer hotKeySections={getMediaDetailsHotKeySections(labelingType)} />
        </Box>
      )}
    </Drawer>
  );
};

export default DetailsDrawer;

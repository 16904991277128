import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ApiResponseLoader, Button, SwitchButtonGroup } from '@clef/client-library';
import { PlanPageContext, usePlanPageState, usePlanPageStateContext } from './state';
import PlanSelector from './PlanSelector';
import CreditOptionSelector from './CreditOptionSelector';
import { formatPrice, useCreditOptions } from './utils';
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions';
import { SubscriptionName, UpgradePurpose } from '@clef/shared/types';
import { useSubscriptionPlans } from '../../hooks/api/useSubscriptionApi';
import { HubspotContactForm } from '../../components/Dialogs/UpgradeDialog';
import { useHistory } from 'react-router';
import CLEF_PATH from '@/constants/path';
import { useCurrentSubscription, useQuotaAndUsageInfo } from '@/hooks/useSubscriptions';
import { useSnackbar } from 'notistack';
import subscription_api from '@/api/subscription_api';
import ArrowBack from '@material-ui/icons/ArrowBack';
import useGoBack from '../../hooks/useGoBack';

const useStyles = makeStyles(theme => ({
  planPage: {
    fontSize: 14,
    minWidth: 900,
    padding: theme.spacing(20, 0),
  },
  pageTitle: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '32px',
    textAlign: 'center',
    marginBottom: theme.spacing(8),
  },
  sectionTitle: {
    color: theme.palette.grey[800],
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
  },
  sectionSubTitle: {
    color: theme.palette.grey[500],
  },
  autoUpgradeLine: {
    cursor: 'pointer',
  },
  autoUpgradeOn: {
    color: theme.palette.green[500],
  },
  goToPaymentButton: {
    backgroundColor: theme.palette.blue[100],
    padding: theme.spacing(5, 10),
    borderRadius: 10,
    height: 'auto!important',
    marginBottom: theme.spacing(2),
    color: theme.palette.blue[900],
    fontSize: 18,
    fontWeight: 700,
  },
  underlineDotted: {
    textDecoration: 'underline dotted',
  },
  creditsValue: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.grey[800],
    verticalAlign: 'middle',
  },
  dialogContent: {
    width: 600,
  },
  backButton: {
    position: 'absolute',
    left: 60,
  },
  cancelButton: {
    position: 'absolute',
    right: 60,
  },
}));

const PurposeToTitle: Record<UpgradePurpose, string> = {
  [UpgradePurpose.MoreProjects]: t('Upgrade to Create More Projects'),
  [UpgradePurpose.MoreClasses]: t('Upgrade to Create More Classes'),
  [UpgradePurpose.MoreImages]: t('Upgrade to Create More Images'),
  [UpgradePurpose.MoreSavedModels]: t('Upgrade for More Model Versions'),
};

export type PlanPageProps = {};

const PlanPage: React.FC<PlanPageProps> = () => {
  const styles = useStyles();
  const { pageType, purpose } = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const pageType = urlParams.get('pageType') as 'enroll' | 'upgrade' | null;
    const purpose = urlParams.get('purpose') as UpgradePurpose | null;
    return { pageType, purpose };
  }, []);

  const { state, dispatch } = usePlanPageStateContext();
  const { paymentInterval, creditOptionIndex } = state;
  const creditOptions = useCreditOptions();
  const selectedCreditOption = useMemo(
    () => creditOptions[creditOptionIndex] || {},
    [creditOptionIndex, creditOptions],
  );
  const [planData, planLoading, planError] = useSubscriptionPlans({});
  const { usage } = useQuotaAndUsageInfo();
  const [contactUsDialog, setContactUsDialog] = useState(false);
  const onContactUs = useCallback(() => {
    setContactUsDialog(true);
  }, []);
  const history = useHistory();

  const subscription = useCurrentSubscription();
  const { enqueueSnackbar } = useSnackbar();
  const [changingPlan, setChangingPlan] = useState(false);
  // Most of the case we manually ensure that it saves 17%,
  // but in some cases it is hard to exactly match 17%.
  // E.g. For 55/mo, if we set 45/mo it saves 18%, while 46/mo it saves 16%.
  // Thus, just hard code the 17 here.
  const savedMoneyPercent = 17;

  const totalPrice = useMemo(() => {
    if (state.paymentInterval === 'monthly') {
      return selectedCreditOption.monthPrice;
    } else {
      return selectedCreditOption.yearPrice;
    }
  }, [selectedCreditOption.monthPrice, selectedCreditOption.yearPrice, state.paymentInterval]);

  const proceedButtonText = useMemo(() => {
    if (
      subscription?.meta.plan !== SubscriptionName.Freemium &&
      !!usage &&
      usage > selectedCreditOption.credits
    ) {
      return t('Credit Usage Exceeds This Plan');
    } else if (creditOptions.length === 0) {
      return t('Contact Us');
    } else if (
      subscription?.stripePriceId === selectedCreditOption.stripePriceId &&
      subscription?.status !== 'to_be_canceled'
    ) {
      return t('Keep Current Plan');
    } else {
      return t('Proceed to Payment');
    }
  }, [
    creditOptions.length,
    selectedCreditOption.credits,
    selectedCreditOption.stripePriceId,
    subscription,
    usage,
  ]);

  const goBack = useGoBack(CLEF_PATH.organizationSettings);

  if (!state.plan) {
    return null;
  }

  return (
    <ApiResponseLoader
      loading={planLoading}
      error={planError}
      response={planData}
      defaultHeight={400}
    >
      {() => (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          position="relative"
          className={styles.planPage}
        >
          <Box className={styles.backButton}>
            <Button
              id="back-to-plan-and-usage"
              onClick={() => {
                goBack();
              }}
              startIcon={<ArrowBack />}
              color="primary"
            >
              {t('Back')}
            </Button>
          </Box>
          {![SubscriptionName.Freemium, SubscriptionName.Enterprise].includes(
            subscription?.meta?.plan as SubscriptionName,
          ) &&
            subscription?.status === 'active' && (
              <Box className={styles.cancelButton}>
                <Button
                  id="cancel-plan"
                  color="primary"
                  onClick={() => {
                    history.push(CLEF_PATH.cancelPlan);
                  }}
                  fullWidth
                >
                  {t('Cancel Plan')}
                </Button>
              </Box>
            )}
          <Box className={styles.pageTitle}>
            {PurposeToTitle[purpose!] ??
              (pageType === 'upgrade' ? t('Upgrade to Upload More Images') : t('Choose Your Plan'))}
          </Box>
          <SwitchButtonGroup>
            <Button
              id="pay-annually"
              active={paymentInterval === 'annually'}
              onClick={() => {
                dispatch(draft => {
                  draft.paymentInterval = 'annually';
                });
              }}
            >
              {t('Annually (Save {{savedMoneyPercent}}%)', { savedMoneyPercent })}
            </Button>
            <Button
              id="pay-monthly"
              active={paymentInterval === 'monthly'}
              onClick={() => {
                dispatch(draft => {
                  draft.paymentInterval = 'monthly';
                });
              }}
            >
              {t('Monthly')}
            </Button>
          </SwitchButtonGroup>
          <Box minWidth={850}>
            <Box marginTop={2} marginBottom={8}>
              <Box className={styles.sectionTitle} marginBottom={4}>
                {t('Choose a Plan')}
              </Box>
              <PlanSelector />
            </Box>
            {state.plan !== SubscriptionName.Enterprise && (
              <Box marginTop={2} marginBottom={4}>
                <Box display="flex" marginBottom={8} alignItems="center">
                  {creditOptions.length > 0 && (
                    <Typography className={styles.sectionTitle} variant="h4">
                      {t('How many {{credits}} do you want to reserve every month?', {
                        credits: <span className={styles.underlineDotted}>{t('credits')}</span>,
                      })}
                    </Typography>
                  )}
                  <div style={{ flex: 1 }}></div>
                  <Box className={styles.sectionSubTitle}>
                    {t('{{credits}} credits', {
                      credits: (
                        <span className={styles.creditsValue}>
                          {formatPrice(selectedCreditOption.credits)}
                        </span>
                      ),
                    })}
                  </Box>
                  <Box className={styles.sectionSubTitle} marginX={3}>
                    {t('|')}
                  </Box>
                  <Box className={styles.sectionSubTitle} data-testid="plan-item-unit-price">
                    {t('{{unitPrice}} / mo', {
                      unitPrice: (
                        <span className={styles.creditsValue}>
                          {t('${{count}}', {
                            count: selectedCreditOption.unitPrice || 0,
                          })}
                        </span>
                      ),
                    })}
                  </Box>
                </Box>
                <CreditOptionSelector />
              </Box>
            )}
            <Box display="flex" flexDirection="column" alignItems="center" marginBottom={8}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Button
                  id="go-to-upgrade-payment"
                  color="primary"
                  variant="contained"
                  className={styles.goToPaymentButton}
                  disabled={
                    changingPlan ||
                    (subscription?.meta.plan !== SubscriptionName.Freemium &&
                      !!usage &&
                      usage > selectedCreditOption.credits)
                  }
                  startIcon={changingPlan && <CircularProgress size={20} />}
                  onClick={async () => {
                    if (!creditOptions.length) {
                      onContactUs();
                    } else if (
                      subscription?.stripePriceId === selectedCreditOption.stripePriceId &&
                      subscription.status === 'active'
                    ) {
                      history.push(CLEF_PATH.organizationSettings);
                    } else {
                      setChangingPlan(true);
                      try {
                        const { data: url } = await subscription_api.changePlan(
                          selectedCreditOption.stripePriceId,
                        );
                        window.location.href = url;
                      } catch (e) {
                        enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
                      }
                      setChangingPlan(false);
                    }
                  }}
                >
                  {proceedButtonText}
                </Button>
                {state.plan !== SubscriptionName.Enterprise && (
                  <Box>
                    {t('${{total}}, billed {{interval}}', {
                      total: totalPrice,
                      interval: state.paymentInterval,
                    })}
                  </Box>
                )}
              </Box>
            </Box>
            <Box marginBottom={10}>
              <FrequentlyAskedQuestions />
            </Box>
          </Box>
          <Dialog
            open={contactUsDialog}
            onClose={() => {
              setContactUsDialog(false);
            }}
          >
            <DialogContent className={styles.dialogContent}>
              <HubspotContactForm />
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </ApiResponseLoader>
  );
};

const PlanPageWithContext: React.FC = () => {
  const contextValue = usePlanPageState();
  return (
    <PlanPageContext.Provider value={contextValue}>
      <PlanPage />
    </PlanPageContext.Provider>
  );
};

export default PlanPageWithContext;

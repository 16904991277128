import React, { useState, useRef, useCallback } from 'react';
import cx from 'classnames';
import {
  Modal,
  Paper,
  Grid,
  Typography,
  Slider,
  TextareaAutosize,
  Tooltip,
  Box,
  Divider,
} from '@material-ui/core';
import { Button, IconButton } from '@clef/client-library';
import { ToggleButton } from '@material-ui/lab';
import { flatten, uniq } from 'lodash';
import { ColorResult } from 'react-color';
import ColorPicker from './ColorPicker';
import ColorToggleButton from './ColorToggleButton';
import StyledToggleButtonGroup from './StyledToggleButtonGroup';
import { SpacingVertical } from '../../../components/Spacing';
import {
  useKeyPress,
  MediaInteractiveCanvas,
  CanvasMode,
  CanvasAnnotation,
  CanvasAnnotationType,
  AnnotationChangeType,
} from '@clef/client-library';
import AnnotationBoundingBox from '@material-ui/icons/CheckBoxOutlineBlankRounded';
import AnnotationSegmentation from '@material-ui/icons/GestureRounded';
import AnnotationText from '@material-ui/icons/TextFieldsRounded';
import Close from '@material-ui/icons/Close';
import Undo from '@material-ui/icons/Undo';
import Redo from '@material-ui/icons/Redo';
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import {
  DefectBookExampleData,
  DefectBookExampleAnnotation,
  LineAnnotation,
  BoxAnnotation,
  TextAnnotation,
  Media,
} from '@clef/shared/types';
import { defectExampleAnnotationToCanvasAnnotation } from './utils';
import { useDefectBookEnhancedStyles } from '../defectBookEnhancedStyles';
import { getThumbnail } from '@clef/client-library';

type Props = {
  defectColor?: string;
  title?: string;
  onClose: () => void;
  onSave: (example: Partial<DefectBookExampleData>) => void;
  media: Media | undefined;
  note: string | null;
  annotations: DefectBookExampleAnnotation;
};

type AnnotationType = LineAnnotation | BoxAnnotation | TextAnnotation;

const COLOR_PALETTE = ['#ff0000', '#00ff00', '#00ffff', '#ff00ff', '#ffff00'];
const DEFAULT_FONT_SIZE = 100;
enum LabelMode {
  Box = 'Box',
  Line = 'Line',
  Text = 'Text',
}

const defaultFontSize = 100;

const LabelModeIcon: { [mode in LabelMode]: React.ReactNode } = {
  [LabelMode.Box]: <AnnotationBoundingBox />,
  [LabelMode.Line]: <AnnotationSegmentation />,
  [LabelMode.Text]: <AnnotationText />,
};

const MediaEditModal: React.FC<Props> = ({
  defectColor,
  annotations,
  media,
  note,
  onClose,
  onSave,
  title,
}) => {
  const styles = useDefectBookEnhancedStyles();

  const annotationColors: string[] = uniq(
    flatten(
      Object.values(annotations).map(annotationValue =>
        annotationValue.map((annotationValue: AnnotationType) => annotationValue.color),
      ),
    ),
  ).filter(annotationColor => annotationColor !== defectColor);

  const textRef = useRef();
  const needConfirmExit = useRef(false);
  const clearedAll = useRef(false);
  const initialAnnotations = defectExampleAnnotationToCanvasAnnotation(annotations);
  const [canvasAnnotations, setCanvasAnnotations] =
    useState<CanvasAnnotation[]>(initialAnnotations);
  const [linePreviewStrokeWidth, setLinePreviewStrokeWidth] = useState<number>(2);
  const [linePreviewOpacity, setLinePreviewOpacity] = useState<number>(100);

  const [fontSize, setFontSize] = useState(DEFAULT_FONT_SIZE);
  const [labelMode, setLabelMode] = useState<LabelMode>(LabelMode.Box);
  const [colorPalette, setColorPalette] = useState<string>(defectColor || COLOR_PALETTE[0]);
  const [noteText, setNoteText] = useState<string>(note ? note : '');
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [newColor, setNewColor] = useState<string>(COLOR_PALETTE[0]);
  const [customColors, setCustomColors] = useState<string[]>(annotationColors);

  const mediaInteractiveCanvasRef = useRef<MediaInteractiveCanvas>(null);

  const changeLabelMode = useCallback((newMode: LabelMode) => {
    setLabelMode(newMode);
    if (newMode === LabelMode.Text) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          // @ts-ignore
          textRef.current?.focus();
        });
      });
    } else {
      mediaInteractiveCanvasRef.current?.setSelectedAnnotation('');
    }
  }, []);

  const updateSelectedAnnotation = useCallback(
    (field: string, value) => {
      const index = canvasAnnotations.findIndex(a => a.selected);
      const selectedAnnotation = canvasAnnotations.find(a => a.selected);

      if (selectedAnnotation) {
        needConfirmExit.current = true;
        const annotation = { ...selectedAnnotation };
        const newData = { ...annotation.data, [field]: value };
        const newAnnotations = [
          ...canvasAnnotations.slice(0, index),
          {
            ...annotation,
            data: newData,
          },
          ...canvasAnnotations.slice(index + 1),
        ];
        mediaInteractiveCanvasRef.current?.setAnnotations(
          newAnnotations,
          AnnotationChangeType.Edit,
        );
      }
    },
    [canvasAnnotations],
  );

  const changeColorPalette = useCallback(
    (_: React.MouseEvent<HTMLElement>, newColor: string) => {
      setColorPalette(newColor);
      newColor && updateSelectedAnnotation('color', newColor);
    },
    [updateSelectedAnnotation],
  );

  useKeyPress('t', () => {
    const allModes = Object.values(LabelMode);
    const currIndex = allModes.findIndex(value => value === labelMode);
    if (currIndex >= 0) {
      const newIndex = (currIndex + 1) % allModes.length;
      changeLabelMode(allModes[newIndex]);
    }
  });
  useKeyPress('c', () =>
    setColorPalette(prev => {
      const currIndex = COLOR_PALETTE.findIndex(value => value === prev);
      if (currIndex >= 0) {
        // toggle to the next color recursively
        const newIndex = (currIndex + 1) % COLOR_PALETTE.length;
        return COLOR_PALETTE[newIndex];
      }
      return prev;
    }),
  );

  const confirmExit = useCallback(() => {
    if (!needConfirmExit.current) {
      onClose();
    } else if (
      window.confirm('Annotation updates will not be saved, are you sure you want to leave?')
    ) {
      onClose();
    }
  }, [onClose]);

  const handleAddNewColor = useCallback(() => {
    setShowColorPicker(false);
    setColorPalette(newColor);
    if ([...COLOR_PALETTE, ...customColors, defectColor].includes(newColor)) {
      return;
    }
    setCustomColors([...customColors, newColor]);
  }, [customColors, defectColor, newColor]);
  const handleImageLoaded = useCallback((image: HTMLImageElement) => {
    setFontSize((image.width + image.height) / 50);
  }, []);

  return (
    <Modal open onClose={confirmExit} className={styles.modal} disableEnforceFocus>
      <>
        <Paper
          elevation={0}
          className={cx(styles.paperContainer, 'cy-defect-book-annotation-modal')}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Box pl={3} pt={2}>
              <Typography variant="h4">{title || t('')}</Typography>
            </Box>
            <Box pr={2} pt={2}>
              <IconButton size="small" onClick={confirmExit}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Grid container direction="row" justifyContent="center" className={styles.paperInner}>
            <Grid
              item
              container
              xs={8}
              id="annotation-stage-parent"
              className={styles.canvasContainer}
              justifyContent="center"
              alignItems="center"
            >
              <>
                <MediaInteractiveCanvas
                  ref={mediaInteractiveCanvasRef}
                  imageSrc={getThumbnail(media, 'large')}
                  properties={media?.properties}
                  mode={
                    (labelMode === LabelMode.Box && CanvasMode.IBox) ||
                    (labelMode === LabelMode.Line && CanvasMode.ILine) ||
                    (labelMode === LabelMode.Text && CanvasMode.IText) ||
                    CanvasMode.View
                  }
                  shapeProps={{
                    color: colorPalette,
                    segmentationOpacity: linePreviewOpacity,
                    lineStrokeWidth: linePreviewStrokeWidth,
                    text: t('-text-'),
                    fontSize,
                  }}
                  onImageLoad={handleImageLoaded}
                  annotations={canvasAnnotations}
                  onAnnotationChanged={newAnnotations => {
                    needConfirmExit.current = true;
                    setCanvasAnnotations(newAnnotations);
                  }}
                  builtInZoom
                  enableHideLabels
                  enablePinchScrollZoom
                />
                <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
                  <IconButton onClick={() => mediaInteractiveCanvasRef.current?.undo()}>
                    <Undo />
                  </IconButton>
                  <IconButton onClick={() => mediaInteractiveCanvasRef.current?.redo()}>
                    <Redo />
                  </IconButton>
                  <Button
                    id="clear-all-labels"
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      clearedAll.current = true;
                      needConfirmExit.current = true;
                      setCanvasAnnotations([]);
                      mediaInteractiveCanvasRef.current?.setAnnotations(
                        [],
                        AnnotationChangeType.DeleteAll,
                      );
                    }}
                    disabled={!canvasAnnotations.length}
                  >
                    {t('Clear all')}
                  </Button>
                </Box>
              </>
            </Grid>
            <Grid
              item
              xs={4}
              container
              direction="column"
              wrap="nowrap"
              className={styles.toolContainer}
            >
              <Grid item container direction="column" wrap="nowrap">
                <SpacingVertical />
                <Grid item>
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      <>
                        {t('toggle tools')}
                        <span className={styles.codeBlock}>{t('t')}</span>
                      </>
                    }
                  >
                    <Typography variant="subtitle1" gutterBottom component="span">
                      {t('Tools')}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Box width="fit-content">
                  <Paper elevation={0}>
                    <StyledToggleButtonGroup
                      value={labelMode}
                      exclusive
                      onChange={(_, value) => changeLabelMode(value)}
                      size="medium"
                    >
                      {Object.values(LabelMode).map(value => {
                        return (
                          <ToggleButton
                            key={value}
                            value={value}
                            color="primary"
                            classes={{ root: styles.toggleButton }}
                          >
                            {LabelModeIcon[value]}
                          </ToggleButton>
                        );
                      })}
                    </StyledToggleButtonGroup>
                  </Paper>
                </Box>
                <SpacingVertical />
                {labelMode === LabelMode.Line && (
                  <>
                    <div>
                      <Typography variant="caption">{t('Stroke width')}</Typography>
                      <Slider
                        valueLabelDisplay="auto"
                        step={1}
                        min={1}
                        max={32}
                        value={linePreviewStrokeWidth}
                        onChange={(_e, value) => {
                          setLinePreviewStrokeWidth(Number(value));
                        }}
                      />
                      <Typography variant="caption">{t('Opacity')}</Typography>
                      <Slider
                        valueLabelDisplay="auto"
                        step={1}
                        min={0}
                        max={100}
                        value={linePreviewOpacity * 100}
                        onChange={(_e, value) => {
                          setLinePreviewOpacity(Number(value) / 100);
                        }}
                      />
                    </div>
                    <SpacingVertical />
                  </>
                )}
                {labelMode === LabelMode.Text && (
                  <>
                    <Typography variant="caption">{t('Font Size')}</Typography>
                    <Slider
                      valueLabelDisplay="auto"
                      step={1}
                      min={1}
                      max={defaultFontSize * 4}
                      value={fontSize}
                      onChange={(_e, value) => {
                        setFontSize(Number(value));
                        updateSelectedAnnotation('fontSize', Number(value));
                      }}
                    />
                    <SpacingVertical />
                  </>
                )}
                <Grid item>
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      <>
                        {t('toggle color')}
                        <span className={styles.codeBlock}>{t('c')}</span>
                      </>
                    }
                  >
                    <Typography variant="subtitle1" gutterBottom component="span">
                      {t('Color')}
                    </Typography>
                  </Tooltip>
                </Grid>
                <Box width="fit-content">
                  <Paper elevation={0} className={styles.colorTogglesContainer}>
                    {defectColor && (
                      <>
                        <ColorToggleButton
                          color={defectColor}
                          selectedColor={colorPalette}
                          onClick={event => changeColorPalette(event, defectColor)}
                        />
                        <Divider flexItem orientation="vertical" className={styles.divider} />
                      </>
                    )}
                    {COLOR_PALETTE.filter(
                      color => color !== defectColor && !annotationColors.includes(color),
                    ).map(color => (
                      <ColorToggleButton
                        key={color}
                        color={color}
                        selectedColor={colorPalette}
                        onClick={event => changeColorPalette(event, color)}
                      />
                    ))}
                    {!!customColors.length && (
                      <>
                        <Divider flexItem orientation="vertical" className={styles.divider} />
                        {customColors.map(color => (
                          <ColorToggleButton
                            key={color}
                            color={color}
                            selectedColor={colorPalette}
                            onClick={event => changeColorPalette(event, color)}
                          />
                        ))}
                      </>
                    )}
                    <Box alignSelf="center">
                      <IconButton
                        size="small"
                        onClick={() => setShowColorPicker(!showColorPicker)}
                        className={styles.addColorButton}
                      >
                        <AddCircleOutlineOutlined />
                      </IconButton>
                      {showColorPicker && (
                        <Box className={styles.popover}>
                          <ColorPicker
                            color={newColor}
                            onChange={(color: ColorResult) => setNewColor(color.hex)}
                            onClose={handleAddNewColor}
                          />
                        </Box>
                      )}
                    </Box>
                  </Paper>
                </Box>
                <SpacingVertical />
                <Typography variant="subtitle1" gutterBottom>
                  {t('Media notes')}
                </Typography>
                <TextareaAutosize
                  value={noteText ?? ''}
                  defaultValue="*- Input text here -*"
                  rowsMin={4}
                  onChange={e => {
                    e.persist();
                    needConfirmExit.current = true;
                    setNoteText(e?.target?.value);
                  }}
                  className={cx(styles.editorModeTextArea, styles.width100)}
                />
              </Grid>
              <Grid item className={styles.toolContainerGrow}></Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-end"
                className={styles.toolContainerBottom}
              >
                <Button
                  id="defect-book-annotation-modal-cancel-btn"
                  color="primary"
                  onClick={confirmExit}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  id="defect-book-annotation-modal-save-btn"
                  variant="contained"
                  color="primary"
                  className={cx(styles.actionButton, 'cy-defect-book-annotation-modal-save-btn')}
                  onClick={() => {
                    onSave({
                      note: noteText,
                      annotations: {
                        lineAnnotations: canvasAnnotations
                          .filter(ca => ca.type === CanvasAnnotationType.Line)
                          .map(ca => ca.data) as LineAnnotation[],
                        boxAnnotations: canvasAnnotations
                          .filter(ca => ca.type === CanvasAnnotationType.Box)
                          .map(ca => ca.data) as BoxAnnotation[],
                        textAnnotations: canvasAnnotations
                          .filter(ca => ca.type === CanvasAnnotationType.Text)
                          .map(ca => ca.data) as TextAnnotation[],
                      },
                    });
                    onClose();
                  }}
                >
                  {t('Save')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </>
    </Modal>
  );
};

export default MediaEditModal;

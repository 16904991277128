import { HttpMethod, BaseAPI } from './base_api';
import {
  User,
  ApiResponse,
  UserSignUpData,
  Account,
  UTMParams,
  UserWithAppVersion,
} from '@clef/shared/types';

export type UserConfirmResetData = {
  username: User['username'];
  verificationCode: string;
  newPassword: string;
};

export type SelfServiceSignupData = {
  email: string;
  password: string;
  name: string;
  lastname: string;
  activationCode: string;
};

class AuthAPI extends BaseAPI {
  formatQueryParams(params: any) {
    return Object.keys(params)
      .filter(k => !!params[k])
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      .join('&');
  }

  joinUrls(endpoint: string, params?: any) {
    const formattedParams = params ? '?' + this.formatQueryParams(params) : '';
    return `${endpoint}${formattedParams}`;
  }

  ldapLogin(aesStr: string): Promise<ApiResponse<User & { path: string }>> {
    const form = new URLSearchParams();
    form.append('ldaplogin', aesStr);
    return this.fetch(HttpMethod.Post, this.joinUrls('login'), form, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });
  }

  login(username: string, password: string, newFlow: boolean = false): Promise<ApiResponse<User>> {
    const form = new URLSearchParams();
    form.append('username', username);
    form.append('password', password);
    if (newFlow) {
      form.append('newFlow', newFlow.toString());
    }
    return this.fetch(HttpMethod.Post, this.joinUrls('login'), form, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });
  }

  newLogin(username: string, password: string): Promise<ApiResponse<Account>> {
    return this.postJSON('login', {
      username,
      password,
      newFlow: true,
    });
  }

  samlLogin(email: string): Promise<string> {
    return this.get('saml_login', { email }, true);
  }

  signup({ email, password, name, lastname, token }: UserSignUpData) {
    const form = new URLSearchParams();
    form.append('email', email);
    form.append('password', password);
    form.append('name', name);
    form.append('lastname', lastname);
    form.append('token', token);
    return this.fetch(HttpMethod.Post, this.joinUrls('signup'), form, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });
  }

  register(email: string, utmParams?: UTMParams) {
    return this.postJSON('register', { email, ...utmParams });
  }

  newSignup({ email, password, name, lastname, activationCode }: SelfServiceSignupData) {
    return this.postJSON('signup', {
      email,
      password,
      name,
      lastname,
      activationCode,
      newFlow: true,
    });
  }

  /**
   * @deprecated Please use ProfileAPI.fetchProfile instead
   */
  fetchProfile(): Promise<ApiResponse<UserWithAppVersion>> {
    return this.fetch(HttpMethod.Get, this.joinUrls('fetch_profile'));
  }

  forgotPasswordCode(username: User['username']) {
    const form = new FormData();
    form.append('username', username);
    return this.fetch(HttpMethod.Post, this.joinUrls('forgot_password'), form);
  }

  newForgotPasswordCode(username: User['username']) {
    return this.postJSON('forgot_password', {
      username,
      newFlow: true,
    });
  }

  confirmPassword({ username, verificationCode, newPassword }: UserConfirmResetData) {
    const form = new FormData();
    form.append('username', username);
    form.append('verificationCode', verificationCode);
    form.append('newPassword', newPassword);
    return this.fetch(HttpMethod.Post, this.joinUrls('confirm_reset_password'), form);
  }

  newConfirmPassword({ username, verificationCode, newPassword }: UserConfirmResetData) {
    return this.postJSON('confirm_reset_password', {
      username,
      verificationCode,
      newPassword,
      newFlow: true,
    });
  }

  logout() {
    return this.fetch(HttpMethod.Post, this.joinUrls('logout'), null, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    });
  }
}

export default new AuthAPI('', 'auth');

import React from 'react';

import { UserId } from '@clef/shared/types';

import { UserAvatar as UserAvatarNew, UserAvatarProps } from './UserAvatarNew';
import { useUserInfo } from '../../hooks/api/useUserApi';

type Props = {
  defaultValue?: string;
  userId: UserId;
} & Omit<UserAvatarProps, 'name'>;

export const UserAvatar = ({ userId, defaultValue = 'Unknown user', ...otherProps }: Props) => {
  const user = useUserInfo(userId);

  return user ? <UserAvatarNew {...otherProps} name={user.name} /> : <>{defaultValue}</>;
};

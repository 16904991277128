import { useAllModels, useModels } from '@/hooks/useModels';
import {
  useDeployModelToEndpointMutation,
  useGetEndpointListQuery,
  usePutBundleMutation,
} from '@/serverStore/endpoints';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { getDateNumber } from '@/utils';
import { getModelName, isFastNEasyModel, isUnsavedModel } from '@/utils/models';
import {
  ApiResponseLoader,
  Button,
  IconButton,
  SwitchButtonGroup,
  Typography,
} from '@clef/client-library';
import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import CloudOutlinedIcon from '@material-ui/icons/CloudOutlined';
import MonitorOutlinedIcon from '@material-ui/icons/DesktopMacOutlined';
import cx from 'classnames';
import { formatDistance } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ClientFeatures,
  useFeatureGateEnabled,
  useIsFeatureEnabledAndMayHideForSnowflake,
} from '@/hooks/useFeatureGate';
import { useIsLargeImageModel } from '@/hooks/useIsLargeImageModel';
// TODO: @Louie replace it with DriveFileMoveIcon once we upgrade material-ui to v5
import DriveMoveFileIcon from '@/images/deploy/file_drive_move.svg';
import { SaveModelDialogBody } from '@/pages/DataBrowser/ModelPerformance/SaveModelDialog';
import CreateEndpointDialog from '@/pages/deployment/components/CreateEndpointDialog';
import DownloadLandingEdgeConfirmDialog from '@/pages/deployment/components/DownloadLandingEdgeConfirmDialog';
import { EndpointIcon } from '@/pages/deployment/components/EndpointIcon';
import { useSaveModelMutation } from '@/serverStore/projectModels';
import { BundleStatus, LabelType } from '@clef/shared/types';
import AddIcon from '@material-ui/icons/PlaylistAdd';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { ModelInfoBanner } from './ModelInfoBanner';
import { useUpdateBundleIsDeletedMutation } from '@/serverStore/modelAnalysis/mutations';
import { useGetBundleStatusQuery } from '@/serverStore/modelAnalysis';
import CLEF_PATH from '@/constants/path';
import { useSnackbar } from 'notistack';
import { isModelTrainingSuccessful } from '@/store/projectModelInfoState/utils';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    marginBottom: theme.spacing(7),
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6),
    gap: theme.spacing(5),
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  },
  edgeSectionPadding: {
    paddingLeft: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  endpointPadding: {
    padding: `0 ${theme.spacing(6)}px`,
  },
  switchButton: {
    width: '50%',
  },
  switchButtonText: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
  },
  switchButtonIcon: {
    fontSize: '16px !important',
    marginRight: theme.spacing(-1),
  },
  endpointItems: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: -10,
    maxHeight: '300px',
    overflow: 'auto',
  },
  stretchBox: {
    marginLeft: theme.spacing(-6),
    marginRight: theme.spacing(-6),
  },
  clickableWithIcon: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    width: '100%',
  },
  endpointItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  radioGroup: {
    gap: theme.spacing(2),
  },
  endpintModelInfo: {
    display: 'flex',
    gap: 10,
  },
  endpointItemName: {
    color: theme.palette.grey[900],
    lineHeight: '20px',
    fontWeight: 700,
  },
  endpointModelName: {
    lineHeight: '20px',
    color: theme.palette.grey[800],
  },
  endpointModelDeployDate: {
    lineHeight: '20px',
    color: theme.palette.grey[500],
  },
  stackedButton: {
    textAlign: 'left',
    height: 'unset',
    minHeight: 52,
    padding: '8px 24px',
    borderRadius: 0,
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  generateDownloadButton: {
    textAlign: 'left',
    padding: '8px 24px',
    borderRadius: 0,
    boxSizing: 'content-box',
    marginTop: theme.spacing(3),
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  spinner: {
    marginTop: theme.spacing(6),
    marginLeft: 'calc(50% - 26px)',
    marginBottom: theme.spacing(3),
  },
  addIconContainer: {
    background: theme.palette.blue[50],
  },
  folderIconContainer: {
    background: theme.palette.indigoBlue[100],
  },
  addIcon: {
    color: theme.palette.blue[600],
  },
  edgeDownloadLink: {
    cursor: 'pointer',
  },
  edgeDescription: {
    color: theme.palette.grey[600],
  },
  edgeDescriptionBulletPoint: {
    display: 'list-item',
    marginLeft: theme.spacing(10),
  },
  edgeDescriptionEmphasis: {
    fontWeight: 700,
  },
  top20Margin: {
    marginTop: theme.spacing(5),
  },
  saveModelTitle: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(6),
  },
  divider: {
    margin: `0 ${theme.spacing(6)}px`,
    background: theme.palette.grey[300],
  },
  deployButton: {
    alignSelf: 'flex-end',
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(6),
  },
}));

const EndpointItemV2: React.FC<{
  endpointName: string;
  modelName: string;
  lastDeployedTime: string;
}> = ({ endpointName, modelName, lastDeployedTime }) => {
  const styles = useStyles();
  const dateNow = useMemo(() => new Date(), []);

  return (
    <Box className={styles.endpointItem}>
      <Typography className={styles.endpointItemName}>{endpointName}</Typography>

      <Box className={styles.endpintModelInfo}>
        <Typography className={styles.endpointModelName} maxWidth={200}>
          {modelName}
        </Typography>
        <Typography className={styles.endpointModelDeployDate}>
          {isNaN(getDateNumber(lastDeployedTime))
            ? t('Unknown date')
            : t('Deployed {{dateDistance}} ago', {
                dateDistance: formatDistance(getDateNumber(lastDeployedTime), dateNow),
              })}
        </Typography>
      </Box>
    </Box>
  );
};

const CloudDeploymentSection: React.FC<{
  isDeploying: boolean;
  isLargeImageModel: boolean;
  selectedEndpointId?: string;
  onDeployButtonClick: () => void;
  currentModelIsFastNEasyModel: boolean;
  setSelectedEndpointId: (id: string) => void;
}> = ({
  isDeploying,
  isLargeImageModel,
  selectedEndpointId,
  currentModelIsFastNEasyModel,
  setSelectedEndpointId,
  onDeployButtonClick,
}) => {
  const { data: project } = useGetSelectedProjectQuery();
  const { id: projectId } = project ?? {};
  const {
    data: endpointsData,
    isLoading: endpointsLoading,
    error: endpointsError,
  } = useGetEndpointListQuery(projectId ?? -1);

  const styles = useStyles();
  const { findModels } = useAllModels();

  const sortedEndpointsData = useMemo(() => {
    return endpointsData?.sort((a, b) => {
      return getDateNumber(b.endpoint.creationTime) - getDateNumber(a.endpoint.creationTime);
    });
  }, [endpointsData]);

  useEffect(() => {
    if (sortedEndpointsData && !endpointsLoading && sortedEndpointsData.length === 0) {
      setSelectedEndpointId('');
    }
  }, [sortedEndpointsData, endpointsLoading]);

  return (
    <Box className={styles.sectionContainer}>
      <Typography className={styles.endpointPadding}>
        {t('Choose an endpoint to host your deployment.')}
      </Typography>
      <ApiResponseLoader
        response={sortedEndpointsData}
        loading={endpointsLoading}
        error={endpointsError ?? undefined}
        defaultHeight={100}
      >
        {endpoints => (
          <>
            <Box className={cx(styles.endpointItems, styles.endpointPadding)}>
              <RadioGroup
                className={styles.radioGroup}
                value={selectedEndpointId}
                onChange={event => setSelectedEndpointId(event.target.value)}
              >
                {endpoints
                  .filter(endpoint => {
                    if (currentModelIsFastNEasyModel) {
                      // Fast-N-Easy model can be deployed only on dynamic device/endpoint
                      return endpoint.endpoint.type === 'dynamic';
                    } else {
                      // Classic model can be deployed on both dynamic and static device/endpoint
                      return (
                        endpoint.endpoint.type === 'dynamic' || endpoint.endpoint.type === 'static'
                      );
                    }
                  })
                  .map(endpoint => {
                    return (
                      <FormControlLabel
                        key={endpoint.endpoint.id}
                        value={endpoint.endpoint.id}
                        control={<Radio color="primary" size="small" />}
                        label={
                          <Box className={styles.clickableWithIcon}>
                            <EndpointIcon
                              isCloudEndpoint
                              endpointId={endpoint.endpoint.id}
                              size={40}
                            />
                            <EndpointItemV2
                              endpointName={endpoint.endpoint.name}
                              modelName={
                                endpoint.model === null
                                  ? t('No model deployed')
                                  : getModelName(findModels(endpoint.model?.jobId))
                              }
                              lastDeployedTime={endpoint.endpoint.lastDeployedTime}
                            />
                          </Box>
                        }
                      />
                    );
                  })}
                <FormControlLabel
                  value=""
                  control={<Radio color="primary" size="small" />}
                  label={
                    <Box className={styles.clickableWithIcon}>
                      <Avatar className={styles.addIconContainer} sizes="40px" variant="rounded">
                        <AddIcon className={styles.addIcon} />
                      </Avatar>
                      <Typography className={styles.endpointItemName}>
                        {t('New Endpoint')}
                      </Typography>
                    </Box>
                  }
                />
              </RadioGroup>
            </Box>
            {isLargeImageModel ? (
              <Tooltip
                arrow
                placement="top"
                title={
                  <span>
                    <Typography variant="body2">
                      {t('This model is incompatible with cloud inference.')}
                    </Typography>
                    <Typography variant="body2">
                      {t('Use LandingEdge to run predictions.')}
                    </Typography>
                  </span>
                }
              >
                <span className={styles.deployButton}>
                  <Button
                    id="deploy-model-dialog-deploy-button"
                    variant="contained"
                    color="primary"
                    onClick={() => onDeployButtonClick()}
                    isPending={isDeploying}
                    disabled={isLargeImageModel || selectedEndpointId === undefined}
                  >
                    {t('Deploy')}
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                id="deploy-model-dialog-deploy-button"
                variant="contained"
                color="primary"
                onClick={() => onDeployButtonClick()}
                className={styles.deployButton}
                isPending={isDeploying}
                disabled={isLargeImageModel || selectedEndpointId === undefined}
              >
                {t('Deploy')}
              </Button>
            )}
          </>
        )}
      </ApiResponseLoader>
    </Box>
  );
};

const EdgeDeploymentSection: React.FC<{
  isLoading: boolean;
  bundleStatus: BundleStatus | undefined;
  onGenerateBundle: () => Promise<void>;
  onVisitModelsPage: () => Promise<void>;
  isBundleGenerating: boolean;
  isLargeModal: boolean;
  displayGenerateDeployableModelButton: boolean;
}> = ({
  isLoading,
  bundleStatus,
  isLargeModal,
  isBundleGenerating,
  onGenerateBundle,
  onVisitModelsPage,
  displayGenerateDeployableModelButton,
}) => {
  const [downloadLandingEdgeDialogOpen, setDownloadLandingEdgeDialogOpen] =
    useState<boolean>(false);
  const styles = useStyles();
  const hasBundleBeenDeployed = bundleStatus && bundleStatus !== 'not_found';

  return (
    <Box className={cx(styles.sectionContainer, styles.edgeSectionPadding)}>
      <Box width={isLargeModal ? 'auto' : 400}>
        <Typography className={styles.edgeDescription}>
          {t(
            'Deploy models on self-hosted devices in a variety of ways. First, generate a deployable model.',
          )}
        </Typography>
        {displayGenerateDeployableModelButton ? (
          !isBundleGenerating ? (
            <>
              <Button
                id="deploy-model-dialog-create-endpoint-and-deploy-button"
                variant="text"
                fullWidth
                disabled={isBundleGenerating}
                isPending={isLoading}
                className={cx(styles.stretchBox, styles.generateDownloadButton)}
                onClick={hasBundleBeenDeployed ? onVisitModelsPage : onGenerateBundle}
              >
                <Box className={styles.clickableWithIcon}>
                  <img src={DriveMoveFileIcon} width={40} height={40} />
                  <Typography className={styles.endpointItemName}>
                    {hasBundleBeenDeployed
                      ? t('Visit Models page to Download')
                      : t('Generate Deployable Model')}
                  </Typography>
                </Box>
              </Button>
            </>
          ) : (
            <CircularProgress className={styles.spinner} size="24px" />
          )
        ) : null}
      </Box>
      <DownloadLandingEdgeConfirmDialog
        open={downloadLandingEdgeDialogOpen}
        onClose={() => setDownloadLandingEdgeDialogOpen(false)}
      />
    </Box>
  );
};

enum ViewMode {
  Cloud = 'cloud',
  Edge = 'edge',
}

export interface DeployModelDialogProps {
  open: boolean;
  isLegacy?: boolean;
  onClose: () => void;
  modelInfo:
    | {
        id: string | undefined;
        threshold: number | undefined;
      }
    | undefined
    | null;
  selectedEndpointId?: string | null;
  isLargeImageModel?: boolean;
  displayGenerateDeployableModelButton?: boolean;
}

export const DeployModelDialogV2: React.FC<DeployModelDialogProps> = ({
  open,
  onClose,
  modelInfo,
  selectedEndpointId: initialSelectedEndpointId,
  displayGenerateDeployableModelButton = true,
}) => {
  const styles = useStyles();
  const { data: project } = useGetSelectedProjectQuery();
  const { id: projectId, labelType } = project ?? {};
  const { findModels } = useModels();
  const { data: bundleStatusData, isLoading: isBundleStatusLoading } = useGetBundleStatusQuery(
    modelInfo?.id ?? '',
    modelInfo?.threshold ?? 0,
  );
  const { status: bundleStatus } = bundleStatusData ?? {};

  const isCloudDeploymentEnabled = useIsFeatureEnabledAndMayHideForSnowflake().cloudDeployment;
  const [viewMode, setViewMode] = useState<ViewMode>(
    isCloudDeploymentEnabled ? ViewMode.Cloud : ViewMode.Edge,
  );
  const currentModel = useMemo(() => findModels(modelInfo?.id), [findModels, modelInfo?.id]);
  const isModelUnsaved = isUnsavedModel(currentModel);
  const [isLargeImageModel] = useIsLargeImageModel(currentModel?.id);
  const { mutateAsync: deployModelToEndpoint, isLoading } = useDeployModelToEndpointMutation();

  const { mutateAsync: updateBundleIsDeleted, isLoading: isUpdateBundleIsDeletedLoading } =
    useUpdateBundleIsDeletedMutation(
      currentModel?.modelName ?? 'Untitled model',
      modelInfo?.threshold,
    );
  const [selectedEndpointId, setSelectedEndpointId] = useState<string | undefined>(
    initialSelectedEndpointId ?? undefined,
  );
  const [createEndpointDialogOpen, setCreateEndpointDialogOpen] = useState(false);

  const saveModel = useSaveModelMutation();
  const isDeploying = saveModel.isLoading || isLoading || isUpdateBundleIsDeletedLoading;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    initialSelectedEndpointId && setSelectedEndpointId(initialSelectedEndpointId);
  }, [initialSelectedEndpointId]);

  const currentModelIsFastNEasyModel = isFastNEasyModel(currentModel);

  const instantLearningPostprocessingEnabled = useFeatureGateEnabled(
    ClientFeatures.InstantLearningPostprocessing,
  );

  const form = useForm<{
    modelName: string;
  }>({
    defaultValues: {
      modelName: 'Untitled model',
    },
  });
  const modelName = form.watch('modelName');

  const putBundle = usePutBundleMutation(currentModel?.modelName || modelName);
  const history = useHistory();

  if (!currentModel) {
    return null;
  }

  const onSaveModel = async () => {
    if (!isModelUnsaved) {
      return false;
    }
    if (!modelName) {
      form.setError('modelName', { type: 'required', message: t('This is required.') });
      return true;
    }
    await saveModel.mutateAsync({
      id: currentModel.id,
      modelName,
    });
    return false;
  };

  const onDeployButtonClick = async (endpointId = selectedEndpointId) => {
    const isModelNameEmpty = await onSaveModel();
    if (isModelNameEmpty) {
      return;
    }
    if (endpointId === '') {
      setCreateEndpointDialogOpen(true);
      return;
    }
    if (!endpointId || !modelInfo?.id) {
      return;
    }
    if (modelInfo?.threshold !== undefined) {
      await updateBundleIsDeleted({
        modelId: modelInfo.id,
        threshold: modelInfo?.threshold,
        isDeleted: false,
      });
    }
    await deployModelToEndpoint(endpointId, modelInfo.id, modelInfo?.threshold);
  };

  const onGenerateBundle = async () => {
    if (!projectId) return;
    const isModelNameEmpty = await onSaveModel();
    if (isModelNameEmpty || !modelInfo?.threshold || !modelInfo?.id) {
      return;
    }
    if (!isModelTrainingSuccessful(currentModel.status, currentModel.metricsReady)) {
      enqueueSnackbar(t('The model has not yet finish training, Please try again later.'), {
        variant: 'warning',
        autoHideDuration: 12000,
      });
      return;
    }
    if (bundleStatus === 'not_found') {
      await putBundle.mutateAsync({
        projectId,
        modelId: currentModel.id,
        threshold: modelInfo?.threshold,
      });
    }
    enqueueSnackbar(
      t(
        'The deployable model was generated successfully. Please navigate to the Models page to view the result. Note that it may take a few seconds for the model to appear.',
      ),
      { variant: 'success', autoHideDuration: 12000 },
    );
  };

  const onVisitModelsPage = async () => {
    if (!projectId) return;
    const isModelNameEmpty = await onSaveModel();
    if (isModelNameEmpty || !modelInfo?.threshold || !modelInfo?.id) {
      return;
    }
    history.push(`${CLEF_PATH.modelsV2.list}?highlight=${modelInfo.id}`);
  };

  const isLargeModal =
    labelType === LabelType.SegmentationInstantLearning && instantLearningPostprocessingEnabled;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" scroll="body">
      {isModelUnsaved && (
        <>
          <DialogTitle className={styles.saveModelTitle}>
            <Typography variant="h2">{t('Save Your Model First')}</Typography>
          </DialogTitle>
          <DialogContent>
            <SaveModelDialogBody form={form} />
          </DialogContent>
          <Divider className={styles.divider} />
        </>
      )}
      <Box className={styles.body}>
        <Grid container>
          <Typography variant="h2" className={styles.title}>
            {t('Choose Deployment Option')}
          </Typography>

          <IconButton
            id="close-deploy-model-dialog-v2"
            className={styles.closeButton}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Grid>
        <ModelInfoBanner
          labelType={labelType}
          modelName={getModelName(findModels(modelInfo?.id))}
          threshold={modelInfo?.threshold}
        />
        {isCloudDeploymentEnabled && (
          <SwitchButtonGroup>
            {
              <Button
                id="deploy-modal-view-cloud-deployment-button"
                fullWidth
                className={cx(
                  styles.switchButton,
                  viewMode === ViewMode.Cloud ? 'active' : undefined,
                )}
                onClick={() => setViewMode(ViewMode.Cloud)}
                aria-pressed={viewMode === ViewMode.Cloud}
                startIcon={<CloudOutlinedIcon className={styles.switchButtonIcon} />}
              >
                <Typography className={styles.switchButtonText}>{t('Cloud Deployment')}</Typography>
              </Button>
            }
            <Button
              id="deploy-modal-view-edge-deployment-button"
              fullWidth
              className={cx(styles.switchButton, viewMode === ViewMode.Edge ? 'active' : undefined)}
              onClick={() => setViewMode(ViewMode.Edge)}
              aria-pressed={viewMode === ViewMode.Edge}
              startIcon={<MonitorOutlinedIcon className={styles.switchButtonIcon} />}
            >
              <Typography className={styles.switchButtonText}>
                {t('Self-Hosted Deployments')}
              </Typography>
            </Button>
          </SwitchButtonGroup>
        )}
      </Box>
      {viewMode === ViewMode.Cloud && (
        <CloudDeploymentSection
          isDeploying={isDeploying}
          isLargeImageModel={isLargeImageModel}
          selectedEndpointId={selectedEndpointId}
          currentModelIsFastNEasyModel={currentModelIsFastNEasyModel}
          setSelectedEndpointId={setSelectedEndpointId}
          onDeployButtonClick={onDeployButtonClick}
        />
      )}
      {viewMode === ViewMode.Edge && (
        <EdgeDeploymentSection
          isLoading={isBundleStatusLoading || putBundle.isLoading}
          bundleStatus={bundleStatus}
          isLargeModal={isLargeModal}
          isBundleGenerating={putBundle.isLoading}
          onVisitModelsPage={onVisitModelsPage}
          onGenerateBundle={onGenerateBundle}
          displayGenerateDeployableModelButton={displayGenerateDeployableModelButton}
        />
      )}
      <CreateEndpointDialog
        open={createEndpointDialogOpen}
        onClose={() => setCreateEndpointDialogOpen(false)}
        modelInfo={{
          id: currentModel.id,
          threshold: modelInfo?.threshold,
        }}
      />
    </Dialog>
  );
};

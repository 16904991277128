import { makeStyles } from '@material-ui/core';

export const useLabelingStyles = makeStyles(theme => ({
  mainContent: {
    flex: 1,
  },
  mediaCanvasWrapper: {
    position: 'relative',
    height: '100%',
    '& .show-on-hover': {
      opacity: 0,
      transition: 'opacity 0.3s',
    },
    '&:hover .show-on-hover': {
      opacity: 1,
    },
  },
  toolCards: {
    position: 'absolute',
    left: theme.spacing(5),
    top: theme.spacing(5),
    zIndex: 1,
    display: 'flex',
    maxWidth: `calc(100% - 300px)`,
    flexWrap: 'wrap',
    '& > div': {
      marginBottom: theme.spacing(5),
    },
    pointerEvents: 'none',
    gap: theme.spacing(3),
  },
  toolCardsWithSamEnabled: {
    top: theme.spacing(10),
  },
  // main section
  toolCard: {
    borderWidth: 2,
    position: 'relative',
    borderStyle: 'solid',
    borderColor: 'transparent',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    padding: theme.spacing(1),
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'unset',
    pointerEvents: 'auto',
  },
  toolImageEnhance: {
    position: 'absolute',
    right: 20,
    top: 20,
    zIndex: 1,
  },
  toolImageEnhanceWithSamEnabled: {
    top: theme.spacing(10),
  },
  warningBorder: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,
    transition: 'none',
  },
  defectSelector: {
    width: 206,
    height: 40,
  },
  labelModeButton: {
    width: 40,
    minWidth: 40,
    padding: 0,
    height: 40,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  lineWidthInput: {
    '&:hover $notchedOutline': {
      display: 'block',
    },
  },
  notchedOutline: {
    display: 'none',
  },
  numberInput: {
    width: 42,
    fontWeight: 500,
  },
  selectDefectCTA: {
    width: '100%',
    cursor: 'pointer',
    padding: theme.spacing(0, 2, 0, 3),
  },
  defectItem: {
    width: 162,
    margin: theme.spacing(1, 0),
  },
  defectName: {
    maxWidth: 100,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  defectColor: {
    width: 20,
    height: 20,
    borderRadius: 6,
    marginRight: theme.spacing(3),
  },
  defectMenuContainer: {
    zIndex: theme.zIndex.modal + 10,
    // transform: 'translateY(48px)',
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: `${theme.palette.primary.main}1A`,
    },
    '& .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: `${theme.palette.primary.main}2B`,
    },
    '& .MuiListItem-root:hover': {
      backgroundColor: `${theme.palette.grey[500]}1A`,
    },
    '& .MuiListItem-root.Mui-selected.Mui-focusVisible': {
      backgroundColor: `${theme.palette.primary.main}1A`,
    },
    '& .MuiListItem-root.Mui-focusVisible': {
      backgroundColor: `${theme.palette.grey[500]}1A`,
    },
  },
  zoomOptions: {
    zIndex: theme.zIndex.modal + 1,
  },
  // snackbar
  snackBarPosition: {
    top: theme.spacing(10),
    right: theme.spacing(10),
    opacity: '0.8',
    width: '680px',
  },
  snackBarRoot: {
    backgroundColor: theme.palette.grey[800],
  },
  action: {
    marginBottom: theme.spacing(5),
  },
  message: {
    fontSize: '14px',
    padding: theme.spacing(4, 0, 1, 2),
  },
  neverShowMessage: {
    display: 'block',
    color: theme.palette.primary.light,
    padding: 0,
    margin: theme.spacing(0, 0, 0, 'auto'),
  },
  hintTextNormal: {
    backgroundColor: '#4B5565',
    color: '#F3F3F4',
    borderRadius: '3.53846px',
    padding: theme.spacing(1, 1.5),
  },
  hintToNext: {
    display: 'flex',
    alignItems: 'center',
    height: 40,

    '& p': {
      color: `${theme.palette.indigoBlue[600]} !important`,
    },
  },
  verticalLine: {
    height: `${theme.spacing(4)}px !important`,
    margin: `${theme.spacing(0, 2.5)} !important`,
  },
  // universal
  marginBottom2: {
    marginBottom: theme.spacing(2),
  },
  marginBottom5: {
    marginBottom: theme.spacing(5),
  },
  marginRight5: {
    marginRight: theme.spacing(5),
  },
  codeBlock: {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 4,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: theme.spacing(1),
    fontWeight: 700,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexGrow: {
    flexGrow: 1,
  },
  disableMask: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
    background: 'rgba(255,255,255,0.7)',
  },
  mediaLevelLabelActions: {
    position: 'absolute',
    left: 'auto',
    top: 'auto',
    right: theme.spacing(5),
    bottom: theme.spacing(5),
    zIndex: 1,
    backgroundColor: 'white',
    borderRadius: 17,
  },
  mediaLevelLabelCard: {
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'auto',
    whiteSpace: 'nowrap',
    gap: theme.spacing(3),
  },
  markAsNoClassWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    padding: theme.spacing(0, 5),
    backgroundColor: 'white',
    border: '2px solid white',
    borderRadius: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
    },
    '& p': {
      fontSize: 14,
      fontWeight: 500,
      color: '#4D5761',
      marginRight: theme.spacing(2),
    },
  },
  wrapperChecked: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  markAsNoClassText: {
    fontSize: 14,
    fontWeight: 500,
    color: '#4D5761',
  },
  markAsNoClassIcon: {
    color: theme.palette.primary.main,
  },
  noClassWrapperPanMode: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    padding: theme.spacing(0, 5),
    backgroundColor: 'white',
    borderRadius: theme.spacing(2),
    gap: theme.spacing(2),
  },
  predictedAsNoClassBorder: {
    border: '2px dashed #4D5761',
  },
  // TODO: new
  previewContainer: {
    marginBottom: theme.spacing(3),
    position: 'relative',
    '& $deleteAction': {
      opacity: 0,
    },
    '&:hover $deleteAction': {
      opacity: 1,
    },
  },
  previewContainerTop: {
    marginBottom: theme.spacing(1),
  },
  deleteAction: {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
  previewSegContainer: {
    width: '100%',
    backgroundColor: theme.palette.grey[200],
    borderRadius: 8,
    overflow: 'hidden',
    position: 'relative',
  },
  labelPreviewContainer: {
    margin: '2px 0',
    fontSize: 14,
  },
  labelPreviewItem: {
    padding: 0,
  },
  labelPreviewItemText: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(7),
    color: theme.palette.grey[500],
    cursor: 'default',
    lineHeight: '32px',
    transition: 'background 0.4s',
    '& .selected, &:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  labelPreviewItemConfidence: {
    fontWeight: 700,
  },
  showExpandIconOnHover: {
    '& .MuiAccordionSummary-expandIcon': {
      opacity: 0,
      transition: 'opacity 200ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '&:hover': {
      '& .MuiAccordionSummary-expandIcon': {
        opacity: 1,
      },
      '& $defectNumber': {
        opacity: 0,
      },
    },
  },
  classificationPreviewBadge: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    backgroundColor: 'rgba(30, 42, 55, 0.8)',
    border: '2px solid white',
    color: 'white',
    borderRadius: 20,
    padding: theme.spacing(1, 1, 1, 3),
    cursor: 'pointer',
    '&.view-only, &.prediction, &.no-prediction': {
      padding: theme.spacing(1, 3),
      cursor: 'unset',
    },
    '&.prediction': {
      borderStyle: 'dotted',
    },
    '&.no-prediction': {
      backgroundColor: 'rgba(55, 65, 77, 0.8)',
    },
  },
  classificationName: {
    lineHeight: '20px',
  },
  changeClassPopover: {
    color: 'white',
    backgroundColor: theme.palette.grey[700],
    '& .MuiListItem-button:hover': {
      backgroundColor: theme.palette.grey[600],
    },
    '& .MuiListItem-root.Mui-selected.Mui-focusVisible': {
      backgroundColor: theme.palette.grey[600],
    },
    '& .MuiListItem-root.Mui-focusVisible': {
      backgroundColor: theme.palette.grey[600],
    },
  },
  toDefect: {
    color: '#659bf3',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 'bold',
  },
  defectInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  defectNameBox: {
    maxWidth: 190,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  defectNameBoxMaxWidthOverride: {
    maxWidth: 120,
  },
  defectNumber: {
    transform: 'translateX(28px)',
  },
  createDefect: {
    fontSize: '14px',
    color: theme.palette.grey[800],
  },
  add: {
    color: theme.palette.grey[400],
    width: 20,
    height: 20,
    borderRadius: '6px',
    marginRight: theme.spacing(3),
  },
  chilpWrapper: {
    position: 'absolute',
    left: '10px',
    bottom: '30px',
  },
  classificationChip: {
    borderRadius: 20,
  },
  question: {
    width: '18px',
    height: '18px',
    borderRadius: '18px',
    border: '1px solid #fff',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  hitClassLimitMenuItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    borderTop: '1px solid ' + theme.palette.grey[100],
  },
  hitClassLimitText: {
    color: theme.palette.grey[500],
    fontSize: 12,
    lineHeight: '16px',
  },
  hitClassLimitButton: {
    color: theme.palette.blue[500],
    fontSize: 12,
    lineHeight: '16px',
    padding: theme.spacing(2),
    fontWeight: 700,
  },
  smartLabelingGuideGif: {
    width: '100%',
    objectFit: 'contain',
  },
  smartLabelingGuideDescription: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  smartLabelingGuideCloseButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  optionCard: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacing(8),
    marginLeft: theme.spacing(1),
  },
  modeOptionButton: {
    width: 32,
    minWidth: 32,
    padding: 0,
    height: 32,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  lineWidthSlider: {
    margin: theme.spacing(0, 2),
    width: '128px !important',
  },
  moreClasses: {
    marginTop: theme.spacing(2),
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.greyModern[600],
    cursor: 'pointer',
  },
}));

import {
  DataBrowserState,
  DraftActionSelectingMedia,
  isMediaSelected,
  useDataBrowserState,
} from '@/pages/DataBrowser/dataBrowserState';
import MediaContainer from '@/pages/DataBrowser/MediaContainer';
import { useDatasetMedias, useTotalMediaCount } from '@/pages/DataBrowser/utils';
import {
  ApiResponseLoader,
  Button,
  calcItemPerRow,
  useKeyPress,
  useWindowEventListener,
  VirtualRow,
  ToggleButton,
} from '@clef/client-library';
import { NEW_DRAWER_WIDTH } from '@clef/shared/constants';
import { FilterOptionName, LabelStatus, Media, MediaId, MediaStatusType } from '@clef/shared/types';
import { Box, LinearProgress, makeStyles } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { Pagination } from '@material-ui/lab';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import CLEF_PATH from '../../constants/path';
import MediaGrid, { calcOptimalRatio } from '../../pages/DataBrowser/MediaGrid/MediaGrid';
import { useVPLatestModel } from '@/serverStore/projectModels';
import { SidebarWidth, ScrollbarWidth } from '../../pages/DataBrowser/styles';
import { groupItems } from '@/utils';

const useStyles = makeStyles(theme => ({
  loadingImagesProgress: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1,
  },
  hintTextNormal: {
    color: theme.palette.grey[500],
  },
  groupTitle: {
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: 500,
    margin: theme.spacing(0, 0, 0, -5),
  },
  toggleButtonIcon: {
    '& > *:first-child': {
      fontSize: 30,
    },
  },
  imageOverlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 106,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  media: { padding: '0px 0px 2px 2px', width: '100%' },
}));

export type GroupedImagesProps = {
  showGroundTruthLabels?: boolean;
  showPredictionLabels?: boolean;
  showPredictionLabeled?: boolean;
  showPredictionUnlabeled?: boolean;
  setShowGroundTruthLabels?(show: boolean): void;
  setShowPredictionLabels?(show: boolean, imageGroup: LabelStatus): void;
  onImageClick?(mediaId: MediaId): void;
};

export type ImageGroupProps = GroupedImagesProps & {
  group: Omit<LabelStatus, LabelStatus.Approved>;
  toggleLabelButton?: JSX.Element;
  enableGrayscaleImage?: boolean;
  renderStartLabelingOverlay?: boolean;
  onPaginationChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
};

export const ImagesGroup: React.FC<ImageGroupProps> = props => {
  const {
    group,
    toggleLabelButton,
    showGroundTruthLabels,
    showPredictionLabels,
    onImageClick,
    enableGrayscaleImage = false,
    renderStartLabelingOverlay,
    onPaginationChange,
  } = props;
  const styles = useStyles();
  const history = useHistory();

  const { state: originalState, dispatch } = useDataBrowserState();
  const state: DataBrowserState = useMemo(() => {
    return {
      ...originalState,
      pageIndex:
        group === LabelStatus.Labeled ? originalState.pageIndex : originalState.secondaryPageIndex,
      appliedFilters: {
        ...originalState.appliedFilters,
        [FilterOptionName.MediaStatus]: {
          o: 'CONTAINS_ANY',
          t: 'column',
          v: group === LabelStatus.Labeled ? [MediaStatusType.Approved] : [MediaStatusType.Raw],
        },
      },
    };
  }, [group, originalState]);

  const { data: totalMediaCount } = useTotalMediaCount(state);
  const {
    data: datasetMedias,
    isLoading: datasetMediasLoading,
    error: datasetMediasError,
  } = useDatasetMedias(state);
  const { labelDisplay, showGroundTruth, showPredictions, itemPerRowOffset } = state;

  const [expanded, setExpanded] = useState(true);
  const [shiftKey, setShiftKey] = useState(false);
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number>(-1);
  const [displayMode, setDisplayMode] = useState<'partial' | 'full'>('partial');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [itemPerRow, setItemPerRow] = useState(calcItemPerRow(undefined, itemPerRowOffset));

  useEffect(() => {
    setItemPerRow(calcItemPerRow(undefined, itemPerRowOffset));
  }, [itemPerRowOffset]);

  useWindowEventListener('resize', () => {
    setItemPerRow(calcItemPerRow(undefined, itemPerRowOffset));
    setWindowWidth(window.innerWidth);
  });

  useKeyPress(
    '*',
    event => {
      switch (event.key) {
        case 'Shift':
          if (event.type === 'keydown') {
            setShiftKey(true);
          } else if (event.type === 'keyup') {
            setShiftKey(false);
          }
          break;
      }
    },
    { keyup: true, keydown: true },
  );

  const onMediaSelected = useCallback(
    (mediaId: number, index: number) => (selected: boolean) => {
      if (!datasetMedias) {
        return;
      }

      if (!selected) {
        dispatch(DraftActionSelectingMedia([mediaId], false));
        return;
      }

      setLastSelectedIndex(index);
      // if with shift key, find all media in between index to be selected
      if (shiftKey && lastSelectedIndex >= 0) {
        dispatch(
          DraftActionSelectingMedia(
            datasetMedias
              .slice(Math.min(lastSelectedIndex, index), Math.max(lastSelectedIndex + 1, index + 1))
              .map(_ => _.id),
            true,
          ),
        );
      } else {
        dispatch(DraftActionSelectingMedia([mediaId], true));
      }
    },
    [shiftKey, lastSelectedIndex, dispatch, datasetMedias],
  );

  const hasSelectedMedia = useMemo(() => {
    return !!datasetMedias?.some(item => isMediaSelected(state, item.id));
  }, [datasetMedias, state]);

  const renderMediaOverlay = useCallback(
    (_media, index) => {
      if (group !== LabelStatus.Unlabeled || !renderStartLabelingOverlay || index !== 0) {
        return null;
      }
      return (
        <Box className={styles.imageOverlay}>
          <Button
            id="first-image-overlay-start-labeling"
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(CLEF_PATH.data.instantLearningLabel);
            }}
          >
            {t('Start Labeling')}
          </Button>
        </Box>
      );
    },
    [group, history, renderStartLabelingOverlay, styles.imageOverlay],
  );

  const itemCountPerColumnInPartialMode = useMemo(
    () => (group === LabelStatus.Labeled ? 1 : 2),
    [group],
  );
  const optimalRatio = useMemo(() => calcOptimalRatio(datasetMedias ?? []), [datasetMedias]);

  return (
    <ApiResponseLoader
      response={datasetMedias}
      loading={datasetMediasLoading}
      error={datasetMediasError}
      defaultHeight={200}
    >
      {datasetMedias => {
        return datasetMedias.length ? (
          <Box position="relative">
            <Box display="flex" alignItems="center" marginBottom={4.5} marginTop={6}>
              <Button
                id={`toggle-expand-${group}-images`}
                startIcon={expanded ? <ArrowDropDown /> : <ArrowDropUp />}
                className={styles.groupTitle}
                onClick={() => setExpanded(prev => !prev)}
              >
                {t('{{groupName}} ({{count}})', {
                  groupName: group === LabelStatus.Labeled ? t('Labeled') : t('Unlabeled'),
                  count: totalMediaCount,
                })}
              </Button>

              <Box flex={1} />

              {toggleLabelButton}

              <Button
                id="expand-more-rows-button"
                color="primary"
                onClick={() => {
                  setDisplayMode(value => (value === 'partial' ? 'full' : 'partial'));
                }}
              >
                {displayMode === 'partial' ? t('Expand More Rows') : t('Collapse')}
              </Button>
            </Box>

            <Box position="relative">
              {datasetMediasLoading && <LinearProgress className={styles.loadingImagesProgress} />}

              {expanded && displayMode === 'partial' && (
                <>
                  <VirtualRow
                    dataList={groupItems(
                      datasetMedias,
                      itemCountPerColumnInPartialMode,
                      itemPerRow,
                    ).map(medias => ({
                      // All media ids are unique, so taking the first media id should be fine
                      id: medias[0].id,
                      medias,
                    }))}
                    itemCountPerRow={itemPerRow}
                    // "32" refers to the padding in data browser
                    containerWidth={
                      windowWidth -
                      (state.rightSidebar ? SidebarWidth + ScrollbarWidth : 0) -
                      NEW_DRAWER_WIDTH -
                      32 * 2
                    }
                    itemRatio={optimalRatio * itemCountPerColumnInPartialMode}
                  >
                    {({ medias }) => (
                      <Box height="100%">
                        {medias.map((media, index) => (
                          <Box
                            height={`${100 / itemCountPerColumnInPartialMode}%`}
                            key={media.id}
                            className={styles.media}
                          >
                            <MediaContainer
                              media={media}
                              selected={isMediaSelected(state, media.id)}
                              onSelected={onMediaSelected(media.id, index)}
                              onInfoClick={() => onImageClick?.(media.id)}
                              labelDisplay={labelDisplay}
                              showGroundTruth={showGroundTruthLabels ?? showGroundTruth}
                              showPredictions={showPredictionLabels ?? showPredictions}
                              hasSelectedMedia={hasSelectedMedia}
                              thumbnailSize={itemPerRow > 1 ? 'medium' : 'large'}
                              showClassChip={false}
                              segmentationOpacity={0.6}
                              enableGrayscaleImage={enableGrayscaleImage}
                            >
                              {renderMediaOverlay(media, index)}
                            </MediaContainer>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </VirtualRow>
                </>
              )}

              {expanded && displayMode === 'full' && (
                <MediaGrid
                  medias={datasetMedias as Media[]}
                  onInfoClick={onImageClick}
                  disableVirtualGrid
                  disableSelection={false}
                  showGroundTruth={showGroundTruthLabels}
                  showPredictions={showPredictionLabels}
                  segmentationOpacity={0.6}
                  enableGrayscaleImage={enableGrayscaleImage}
                  renderMediaOverlay={renderMediaOverlay}
                  imageRatio={optimalRatio}
                />
              )}
            </Box>

            {onPaginationChange &&
              Math.ceil((totalMediaCount ?? 0) / state.paginationLimit) > 1 && (
                <Pagination
                  data-testid="databrowser-bottom-pagination"
                  count={Math.ceil((totalMediaCount ?? 0) / state.paginationLimit)}
                  color="primary"
                  size="small"
                  page={state.pageIndex + 1}
                  onChange={onPaginationChange}
                  className={styles.pagination}
                />
              )}
          </Box>
        ) : null;
      }}
    </ApiResponseLoader>
  );
};

export const GroupedImages: React.FC<GroupedImagesProps> = props => {
  const {
    showGroundTruthLabels,
    showPredictionLabeled,
    showPredictionUnlabeled,
    onImageClick,
    setShowGroundTruthLabels,
    setShowPredictionLabels,
  } = props;
  const { latestModel } = useVPLatestModel();
  const { id: latestModelId } = latestModel ?? {};
  const { dispatch } = useDataBrowserState();

  return (
    <>
      <ImagesGroup
        group={LabelStatus.Labeled}
        onImageClick={onImageClick}
        showGroundTruthLabels={showGroundTruthLabels}
        showPredictionLabels={!!latestModelId && showPredictionLabeled}
        toggleLabelButton={
          <>
            {setShowGroundTruthLabels && (
              <ToggleButton
                id="toggle-ground-truth-labels"
                isOn={showGroundTruthLabels}
                onToggle={() => setShowGroundTruthLabels(!showGroundTruthLabels)}
              >
                {t('Labels')}
              </ToggleButton>
            )}

            {latestModelId && setShowPredictionLabels && (
              <ToggleButton
                id="toggle-predictions-labels"
                isOn={showPredictionLabeled}
                onToggle={() => {
                  setShowPredictionLabels(!showPredictionLabeled, LabelStatus.Labeled);
                }}
              >
                {t('Predictions')}
              </ToggleButton>
            )}
          </>
        }
        onPaginationChange={(_, newPageIndex) =>
          dispatch(draft => {
            draft.pageIndex = newPageIndex - 1;
          })
        }
      />

      <ImagesGroup
        group={LabelStatus.Unlabeled}
        onImageClick={onImageClick}
        showGroundTruthLabels={false}
        showPredictionLabels={showPredictionUnlabeled}
        // Disable start labeling overlay for now
        renderStartLabelingOverlay={false}
        // TODO: add this back if we need grayscale image
        // enableGrayscaleImage={showPredictionLabels}
        toggleLabelButton={
          setShowPredictionLabels && (
            <ToggleButton
              id="toggle-prediction-labels"
              isOn={showPredictionUnlabeled}
              onToggle={() =>
                setShowPredictionLabels?.(!showPredictionUnlabeled, LabelStatus.Unlabeled)
              }
            >
              {t('Predictions')}
            </ToggleButton>
          )
        }
        onPaginationChange={(_, newPageIndex) =>
          dispatch(draft => {
            draft.secondaryPageIndex = newPageIndex - 1;
          })
        }
      />
    </>
  );
};

export default GroupedImages;

import React from 'react';
import { makeStyles, MenuItem, MenuList, Paper } from '@material-ui/core';
import { useAllClasses } from './state';
import { DefectItem } from '@/components/Labeling/ToolDefectCard';
import { useLabelingState } from '@/components/Labeling/labelingState';

const useStyles = makeStyles(theme => ({
  classSelector: {
    position: 'absolute',
    left: 24,
    top: 24,
    zIndex: 2,
  },
  classItem: {
    padding: theme.spacing(0.5, 4),
  },
  defectItemRoot: {
    width: 'auto',
  },
  defectName: {
    width: 'auto',
    paddingRight: theme.spacing(2),
  },
}));

export type ClassSelectorProps = {};

const ClassSelector: React.FC<ClassSelectorProps> = () => {
  const styles = useStyles();

  const allClasses = useAllClasses();
  const { state, dispatch } = useLabelingState();
  const { selectedDefect } = state;
  return (
    <Paper className={styles.classSelector}>
      <MenuList data-testid="class-selector">
        {allClasses.map(oneClass => (
          <MenuItem
            key={oneClass.id}
            className={styles.classItem}
            selected={oneClass.id === selectedDefect?.id}
            onClick={() => {
              dispatch(draft => {
                draft.selectedDefect = oneClass;
              });
            }}
          >
            <DefectItem
              defect={oneClass}
              hideInfo
              classes={{ root: styles.defectItemRoot, defectName: styles.defectName }}
            />
          </MenuItem>
        ))}
      </MenuList>
    </Paper>
  );
};

export default ClassSelector;

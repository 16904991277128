import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import { Button, Typography } from '@clef/client-library';

const useStyles = makeStyles(theme => ({
  dialog: {
    minWidth: 520,
  },
  saveButton: {
    marginLeft: theme.spacing(2),
  },
}));

export type SAMConfirmationDialogProps = {
  open: boolean;
  inClassCreationMode?: boolean;
  onClose: () => void;
  onSave: () => void | Promise<void>;
};

export const SAMConfirmationDialog: React.FC<SAMConfirmationDialogProps> = ({
  open,
  inClassCreationMode,
  onClose,
  onSave,
}) => {
  const styles = useStyles();

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      title={inClassCreationMode ? t('Unsaved Class') : t('Unsaved Labels')}
      className={styles.dialog}
    >
      <Typography>
        {inClassCreationMode
          ? t('You have unsaved class. Please create the class.')
          : t('You have unsaved labels. Do you want to save your changes?')}
      </Typography>

      <Box display="flex" justifyContent="flex-end" marginTop={6}>
        {inClassCreationMode ? (
          <Button
            id="sam-confirmation-acknowledge-button"
            variant="contained"
            color="primary"
            onClick={onClose}
          >
            {t('Got it')}
          </Button>
        ) : (
          <>
            <Button id="sam-confirmation-cancel-button" color="primary" onClick={onClose}>
              {t('Cancel')}
            </Button>

            <Button
              id="sam-confirmation-confirm-button"
              variant="contained"
              color="primary"
              onClick={onSave}
              className={styles.saveButton}
            >
              {t('Save')}
            </Button>
          </>
        )}
      </Box>
    </BaseDialog>
  );
};

export default SAMConfirmationDialog;

import React from 'react';

const WarningIcon = () => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.47012 17.5036H17.5301C19.0701 17.5036 20.0301 15.8336 19.2601 14.5036L11.7301 1.49359C10.9601 0.163594 9.04012 0.163594 8.27012 1.49359L0.740121 14.5036C-0.0298787 15.8336 0.930121 17.5036 2.47012 17.5036ZM10.0001 10.5036C9.45012 10.5036 9.00012 10.0536 9.00012 9.50359V7.50359C9.00012 6.95359 9.45012 6.50359 10.0001 6.50359C10.5501 6.50359 11.0001 6.95359 11.0001 7.50359V9.50359C11.0001 10.0536 10.5501 10.5036 10.0001 10.5036ZM11.0001 14.5036H9.00012V12.5036H11.0001V14.5036Z"
      fill="#F79009"
    />
  </svg>
);

export default WarningIcon;

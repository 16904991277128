import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import ClefModelApi from '@/api/model_api';
import { projectModelQueryKeys } from '@/serverStore/projectModels';
import { layoutQueryKeys } from '../layout';
import { modelAnalysisQueryKeys } from '../modelAnalysis';
import model_api from '@/api/model_api';
import { projectQueryKeys, useUpdateProjectMutation } from '@/serverStore/projects';

export const useCreateFastTrainingJobMutation = () => {
  const selectedProjectId = useTypedSelector(state => state.project.selectedProjectId) ?? 0;
  const queryClient = useQueryClient();

  // const addNewParallelTrainingModel = useSetAtom(addNewParallelTrainingModelPopupAtom);

  return useMutation({
    mutationFn: async (params: object) => {
      const { data } = await ClefModelApi.postCreateFastTrainingJob(params);
      const { runId: newModelId } = data;
      return newModelId;
    },
    onSuccess: newModelId => {
      queryClient.invalidateQueries(projectModelQueryKeys.list(selectedProjectId));
      queryClient.invalidateQueries(
        projectModelQueryKeys.modelStatus(selectedProjectId, newModelId),
      );
      queryClient.invalidateQueries(layoutQueryKeys.list(selectedProjectId));
      queryClient.invalidateQueries(modelAnalysisQueryKeys.modelList(selectedProjectId));

      // Related to training in progress popup
      // if (parallelTrainingFoundationsEnabled) {
      //   addNewParallelTrainingModel({ projectId: selectedProjectId, addedModelId: newModelId });
      // }
    },
  });
};

export const useStartLearningTrainMutation = () => {
  const projectId = useTypedSelector(state => state.project.selectedProjectId);
  const updateProject = useUpdateProjectMutation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: { projectId: number; mediaId: number }) => {
      const response = await model_api.postCreateVPTrainingJob(params.projectId, params.mediaId);
      return response?.data?.data?.jobId;
    },
    onSuccess: newModelId => {
      if (!projectId) return;
      updateProject.mutate(
        { id: projectId, registeredModelId: newModelId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(projectModelQueryKeys.list(projectId));
          },
        },
      );
      queryClient.invalidateQueries(projectQueryKeys.versionedDefects(projectId, 0));
    },
  });
};

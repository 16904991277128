import React, { useState } from 'react';
import { Media, EdgeMedia, MediaId } from '@clef/shared/types';
import { Checkbox, Dialog, DialogContent, Toolbar, Typography } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import MediaDetailsContent from './MediaDetailsContent';
import { useKeyPress, greyScale } from '@clef/client-library';
import ImageEnhancer from '../../../components/ImageEnhancer/containers/ImageEnhancerContainer';
import ImageEnhancerContext from '../../../components/ImageEnhancer/ImageEnhancerContext';
import { useHotKeyDialog } from '../../../hooks/useHotKeyDialog';
import PreviousIcon from '@material-ui/icons/ChevronLeft';
import NextIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      paddingLeft: theme.spacing(5),
      marginRight: 'auto',
    },
    actions: {
      marginLeft: 'auto',
      marginRight: theme.spacing(2),
    },
    closeButton: {
      color: theme.palette.grey[500],
    },
  }),
);

interface MediaDetailsDialogProps {
  mediaList: Media[] | EdgeMedia[];
  initialMediaId?: MediaId;
  onClose: () => void;
  showLabels?: boolean;
  showDefectName?: boolean;
  showConfidenceScore?: boolean;
  showConfidenceChip?: boolean;
}

const MediaDetailsDialog: React.FC<MediaDetailsDialogProps> = ({
  onClose,
  mediaList,
  initialMediaId = 0,
  showLabels: showMediaLabels = true,
  showDefectName = true,
  showConfidenceScore = true,
  showConfidenceChip = false,
}) => {
  const classes = useStyles();
  // This way we are keeping a copy pf the mediaList so media detail does not get affected by media grid
  const [mediaListCopied] = useState(mediaList);
  const [mediaIndex, setMediaIndex] = useState(
    mediaList.findIndex(media => media.id === initialMediaId),
  );
  const [showLabels, setShowLabels] = useState(showMediaLabels);
  const [autoNext, setAutoNext] = useState(false);
  // Deleted MediaIds
  const [deletedMedias, setDeletedMedias] = useState<number[]>([]);

  const {
    icon: hotkeyIconButton,
    dialog: hotkeyDialog,
    toggle: toggleKeyHelp,
  } = useHotKeyDialog([
    {
      keys: ['ArrowLeft'],
      description: 'Previous Media',
    },
    {
      keys: ['ArrowRight'],
      description: 'Next Media',
    },
    {
      keys: ['A'],
      description: 'Approve Media',
    },
    {
      keys: ['R'],
      description: 'Reject Media',
    },
    {
      keys: ['L'],
      description: 'Show/Hide Labels',
    },
    {
      keys: ['?'],
      description: 'Show Hotkey',
    },
  ]);

  const toggleShowLabels = () => setShowLabels(!showLabels);
  const onPrev = () => setMediaIndex(prev => Math.max(prev - 1, 0));
  const onNext = () => setMediaIndex(prev => Math.min(prev + 1, mediaList.length - 1));
  useKeyPress('l', toggleShowLabels);
  useKeyPress('shift+/', toggleKeyHelp);
  useKeyPress('left', onPrev);
  useKeyPress('right', onNext);

  const media = mediaListCopied[mediaIndex];
  return (
    <Dialog
      fullScreen
      fullWidth={true}
      maxWidth={'xl'}
      open={true}
      onClose={() => onClose()}
      data-testid="media-details-dialog"
    >
      <ImageEnhancerContext>
        <Toolbar>
          <Typography className={classes.title} variant="h4">
            {`Media Details - ${media.name || media.id}`}
          </Typography>
          <ImageEnhancer iconColor={greyScale[100]} hideApplyToAll />
          <Checkbox
            color="primary"
            checked={showLabels}
            onClick={toggleShowLabels}
            data-testid="media-details-show-labels"
          />
          <Typography variant="body2" component="span">
            {t('Show Labels')}
          </Typography>
          <IconButton
            key="previousImage"
            disabled={mediaIndex === 0}
            onClick={onPrev}
            id="media-details-prev-media-btn"
          >
            <PreviousIcon />
          </IconButton>
          <IconButton
            key="nextImage"
            disabled={mediaIndex === mediaListCopied.length - 1}
            onClick={onNext}
            id="media-details-next-media-btn"
          >
            <NextIcon />
          </IconButton>
          {hotkeyIconButton}
          <IconButton
            key="closeDialog"
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            id="media-details-close-dialog-btn"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <DialogContent>
          <MediaDetailsContent
            media={media}
            showLabels={showLabels}
            showDefectName={showDefectName}
            showConfidenceScore={showConfidenceScore}
            autoNext={autoNext}
            toggleAutoNext={() => setAutoNext(!autoNext)}
            onNext={onNext}
            deletedMedias={deletedMedias}
            onDeleteMedia={(mediaId: number) => {
              setDeletedMedias([...deletedMedias, mediaId]);
            }}
            showConfidenceChip={showConfidenceChip}
          />
        </DialogContent>
        {hotkeyDialog}
      </ImageEnhancerContext>
    </Dialog>
  );
};

export default MediaDetailsDialog;

import React, { useState, useEffect } from 'react';
import { useDefectSelector } from '../../store/defectState/actions';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { Typography, Grid, CircularProgress } from '@material-ui/core';
import { useDefectBookEnhancedStyles } from './defectBookEnhancedStyles';
import DefectDetailViewContent from './DefectList/DefectDetailViewContent';

export interface DefectBookPrintProps {}

const DefectBookPrint: React.FC<DefectBookPrintProps> = () => {
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const defects = useDefectSelector();
  const styles = useDefectBookEnhancedStyles();
  const [defectsLoaded, setDefectsLoaded] = useState(0);

  const allDefectsLoaded = defects && defectsLoaded === defects.length;

  useEffect(() => {
    if (allDefectsLoaded) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          const container = document.querySelector('#defect-book-content')!;
          const css = `@page { size: ${container.clientWidth}px ${
            container.clientWidth * 1.4142
          }px; }`;
          const head = document.head || document.getElementsByTagName('head')[0];
          const style = document.createElement('style');

          style.type = 'text/css';
          style.media = 'print';
          // @ts-ignore style element have styleSheet
          if (style.styleSheet) {
            // @ts-ignore style element have styleSheet
            style.styleSheet.cssText = css;
          } else {
            style.appendChild(document.createTextNode(css));
          }

          head.appendChild(style);

          window.print();

          head.removeChild(style);
        });
      });
    }
  }, [allDefectsLoaded]);

  if (!defects.length) {
    return (
      <Typography variant="body1" className={styles.noDefectText}>
        {t('You have not created any classes yet.')}
      </Typography>
    );
  }

  const defectsSorted = defects.slice().sort((defectA, defectB) => {
    return defectA.id - defectB.id;
  });

  return (
    <section className={styles.defectBookPrintContainer} id="defect-book-content">
      {!allDefectsLoaded && (
        <Grid className={styles.printLoader} container alignItems="center" justifyContent="center">
          <CircularProgress size="26px" />
        </Grid>
      )}
      <div className={styles.defectBookPrintContainerInner}>
        <Typography variant="h2">
          {`${selectedProject?.name} - Defects - ${new Date().toLocaleString()}`}
        </Typography>
        {defectsSorted.map(defect => (
          <div key={defect.id} className={styles.defectBookPrint}>
            <DefectDetailViewContent
              defect={defect}
              onContentLoaded={() => {
                setDefectsLoaded(prev => prev + 1);
              }}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default DefectBookPrint;

import React, { useCallback, useState } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { useCurrentProjectModelInfoQuery, useSaveModelMutation } from '@/serverStore/projectModels';
import { round } from 'lodash';
import { Button } from '@clef/client-library';
import ClibAPI from '../../../api/clib_api';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import CLEF_PATH from '../../../constants/path';
import { pendoEntity } from '../../../utils/pendo';
import { PrecisionValueId, RecallValueId } from '../ModelPerformance/PerformanceRates';
import { useJobDetailForCurrentProject } from '@/serverStore/jobs';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minWidth: 450,
  },
}));

export type RunLiveDialogProps = {
  modelName: string;
  onClose?: () => void;
};

const RunLiveDialog: React.FC<RunLiveDialogProps> = ({ modelName, onClose }) => {
  const styles = useStyles();
  const { id: projectId, orgId } = useGetSelectedProjectQuery().data ?? {};
  const { id: currentModelId, confidence } = useCurrentProjectModelInfoQuery();
  const { data: jobDetails } = useJobDetailForCurrentProject(currentModelId);
  const modelBundleZipPath = jobDetails?.modelBundleZipPath;
  const [modelBundleName, setModelBundleName] = useState(modelName);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const saveModel = useSaveModelMutation();

  const prepareBundle = useCallback(async () => {
    if (!currentModelId || confidence === undefined || !projectId || !orgId) {
      return;
    }
    setLoading(true);
    pendoEntity?.track('run_live', {
      confidence,
      // TODO: Should find a better to calculate this value from whole dataset
      precision: document?.getElementById(PrecisionValueId)?.textContent ?? '',
      recall: document?.getElementById(RecallValueId)?.children?.[0]?.textContent ?? '',
    });
    try {
      let bundleId: string;

      const finalModelBundleName = `${modelBundleName}_${round(confidence!, 2)}`;

      if (modelBundleZipPath) {
        const {
          data: { bundle_id },
        } = await ClibAPI.prepareBundleV1({
          org_id: orgId,
          project_id: projectId,
          job_id: currentModelId,
          bundle_path: modelBundleZipPath,
          score_threshold: confidence,
          model_name: finalModelBundleName,
        });
        bundleId = bundle_id;
      } else {
        const { bundle_id } = await ClibAPI.prepareBundleV0(projectId, currentModelId, confidence);
        bundleId = bundle_id;
      }

      if (modelBundleName !== modelName) {
        await saveModel.mutateAsync({
          id: currentModelId,
          modelName: modelBundleName,
        });
      }

      enqueueSnackbar(t('Successfully prepared bundle'), { variant: 'success' });
      onClose?.();
      history.push(CLEF_PATH.deployment.overview + '?bundleId=' + bundleId);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
    setLoading(false);
  }, [
    enqueueSnackbar,
    history,
    modelBundleName,
    modelBundleZipPath,
    onClose,
    orgId,
    projectId,
    currentModelId,
    confidence,
    modelName,
    saveModel,
  ]);

  return (
    <Dialog
      open
      onClose={(_e, reason: string) => {
        if (reason === 'escapeKeyDown') {
          onClose?.();
        }
      }}
      classes={{ paper: styles.dialogPaper }}
    >
      <Box padding={7}>
        <Typography variant="h2">{t('Create a model bundle & deploy')}</Typography>
        <Box marginBottom={4} />
        <Typography variant="h4">{t('Model bundle')}</Typography>
        <Box marginBottom={1} />
        <TextField
          id="model-bunlde-name"
          variant="outlined"
          value={modelBundleName}
          fullWidth
          disabled={loading}
          onChange={e => setModelBundleName(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{`_${round(confidence!, 2)}`}</InputAdornment>
            ),
          }}
        />
        <Box marginBottom={1} />
        <Typography variant="body2">
          {t('The confidence threshold {{threshold}} will be appended to the model name.', {
            threshold: round(confidence!, 2),
          })}{' '}
          {t('The combined name is not editable once you hit deploy.')}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop={5}>
          <Button
            id="cancel-run-live"
            variant="text"
            color="primary"
            disabled={loading}
            onClick={onClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            id="confirm-run-live"
            variant="contained"
            color="primary"
            disabled={loading || !modelBundleName}
            startIcon={loading && <CircularProgress size={16} />}
            onClick={prepareBundle}
          >
            {t('Deploy')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default RunLiveDialog;

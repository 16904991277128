import { Regime, RunWithRegisteredModel, ExperimentType } from '.';

export enum TrainMode {
  Default = 'default',
  Advanced = 'advanced',
  LargeImage = 'large-image',
}

export interface ModelTrainJobParam {
  /**
   * Config path for the training job, which is served as an entry-point for
   * various configs like
   *
   * Example of s3://landing-data/platform/1/6201/share/95383aa5-5088-48c6-ba80-d05ded3def42/train_config.json
   *
    {
      "train_config_path": "s3://landing-data/platform/1/6201/share/95383aa5-5088-48c6-ba80-d05ded3def42/train.json",
      "data_path": "s3://landing-data/dataset-versions/13267/1622523/dataset-13267-v1622523-export.csv",
      "label_map_path": "s3://landing-data/dataset-versions/13267/1622523/dataset-13267-v1622523.dm.json",
      "transforms_path": "s3://landing-data/platform/1/6201/share/95383aa5-5088-48c6-ba80-d05ded3def42/augment-config.yml",
      "experiment_stage": "train",
      "report_path": "report.v2.csv",
      "s3_run_folder": "s3://landing-data/platform/1/6201/train/95383aa5-5088-48c6-ba80-d05ded3def42",
      "arch_class": "object-detection"
    }
   *
   */
  jobConfigJsonPath: string;

  /**
   * For onprem or other special usage, when we use customized s3 endpoint.
   * This should be host:port, without http scheme.
   *
   * A minio example:
   * ec2-3-145-96-163.us-east-2.compute.amazonaws.com:9000
   */
  s3Endpoint?: string;

  /**
   * For onprem when minio https is not enabled, set this to 0.
   * Otherwise set it to 1.
   */
  s3UseHttps?: number;

  /**
   * For pushing job status, or metircs
   */
  callbackUrl: string;
}

export type DatasetTrainingConfig = {
  csvPath: string;
  defectmap: Record<string, any>;
  versionId?: number;
};

export type TrainRunParams = Partial<RunWithRegisteredModel> & {
  regime?: Regime;
  mode?: TrainMode;
};

export type FastTrainingJobPayload = {
  jobId: string;
  projectId: number;
  experimentType: ExperimentType;
  datasetMetadata: DatasetTrainingConfig;
};

export type TrainingJobPayload = TrainRunParams & {
  jobId: string;
  datasetMetadata: DatasetTrainingConfig;
  trainJobName?: string;
  experimentType?: ExperimentType;
};

export type ClientTrainingJobParams = TrainRunParams & {
  datasetVersionId: number;
  modelName?: string;
  modelDetails?: string;
  experimentType: ExperimentType;
};

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { CLEF_PATH } from '../../../constants/path';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { JoinOrg } from '../../../pages/login/JoinOrg';
import { CreateOrg as FRECreateOrg } from '../../../pages/login/FRECreateOrg';
import { InviteMember } from '../../../pages/login/FREInviteMember';
import { ForgetPassword } from '../../../pages/login/ForgetPassword';
import { ConfirmResetPassword } from '../../../pages/login/ConfirmResetPassword';
import { SsoLogin } from '../../../pages/login/SsoLogin';
import { Login } from '../../../pages/login/Login';
import { CompleteSignup } from '../../../pages/signup/CompleteSignup';
import { SelfServiceSignup } from '../../../pages/signup/SelfServiceSignup';
import { SelfServiceSignupEmailDelivered } from '../../../pages/signup/SelfServiceSignupEmailDelivered';
import { ForgetPasswordEmailDelivered } from '../../../pages/login/ForgotPasswordEmailDelivered';

interface AccountAuthenticationRouteType {
  exact?: boolean;
  path: string;
  shouldBeAuthenticated: boolean;
  isAuthenticated: boolean;
  component?: React.FC;
}

const AccountAuthenticationRoute: React.FC<AccountAuthenticationRouteType> = ({
  exact,
  path,
  shouldBeAuthenticated,
  isAuthenticated,
  children,
  component,
}) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={props =>
        shouldBeAuthenticated === isAuthenticated ? (
          (component && React.createElement(component)) ?? children
        ) : (
          <Redirect to={{ ...props.location, pathname: CLEF_PATH.login.main }} />
        )
      }
    />
  );
};

// Note, only if org user is not authenticated, this router can be triggered
export const LoginRouter: React.FC = () => {
  const { isAccountAuthenticated } = useTypedSelector(state => state.login);

  return (
    <Switch>
      <AccountAuthenticationRoute
        exact
        path={CLEF_PATH.login.joinOrg}
        component={JoinOrg}
        shouldBeAuthenticated
        isAuthenticated={isAccountAuthenticated}
      />

      <AccountAuthenticationRoute
        exact
        path={CLEF_PATH.login.createOrg}
        component={FRECreateOrg}
        shouldBeAuthenticated
        isAuthenticated={isAccountAuthenticated}
      />

      <AccountAuthenticationRoute
        exact
        path={CLEF_PATH.login.inviteMember}
        component={InviteMember}
        shouldBeAuthenticated
        isAuthenticated={isAccountAuthenticated}
      />

      <AccountAuthenticationRoute
        exact
        path={CLEF_PATH.login.forgetPassword}
        component={ForgetPassword}
        shouldBeAuthenticated={false}
        isAuthenticated={isAccountAuthenticated}
      />

      <AccountAuthenticationRoute
        exact
        path={CLEF_PATH.login.confirmResetPassword}
        component={ConfirmResetPassword}
        shouldBeAuthenticated={false}
        isAuthenticated={isAccountAuthenticated}
      />

      <AccountAuthenticationRoute
        exact
        path={CLEF_PATH.login.forgetPasswordEmailDelivered}
        component={ForgetPasswordEmailDelivered}
        shouldBeAuthenticated={false}
        isAuthenticated={isAccountAuthenticated}
      />

      <AccountAuthenticationRoute
        exact
        path={CLEF_PATH.login.sso}
        component={SsoLogin}
        shouldBeAuthenticated={false}
        isAuthenticated={isAccountAuthenticated}
      />

      <Route
        exact
        path={CLEF_PATH.login.main}
        render={props => {
          // If already authenticated with account, redirect to join org page
          return isAccountAuthenticated ? (
            <Redirect to={{ ...props.location, pathname: CLEF_PATH.login.joinOrg }} />
          ) : (
            <Login />
          );
        }}
      />

      {/* Fallback to login page */}
      <Route render={() => <Redirect to={CLEF_PATH.login.main} />} />
    </Switch>
  );
};

// Note, only if org user is not authenticated, this router can be triggered
export const SignupRouter: React.FC = () => {
  const { isAccountAuthenticated } = useTypedSelector(state => state.login);

  return (
    <Switch>
      <AccountAuthenticationRoute
        exact
        path={CLEF_PATH.signup.completeSignup}
        component={CompleteSignup}
        shouldBeAuthenticated={false}
        isAuthenticated={isAccountAuthenticated}
      />

      <AccountAuthenticationRoute
        exact
        path={CLEF_PATH.signup.emailDelivered}
        component={SelfServiceSignupEmailDelivered}
        shouldBeAuthenticated={false}
        isAuthenticated={isAccountAuthenticated}
      />

      <AccountAuthenticationRoute
        exact
        path={CLEF_PATH.signup.main}
        component={SelfServiceSignup}
        shouldBeAuthenticated={false}
        isAuthenticated={isAccountAuthenticated}
      />
    </Switch>
  );
};

import {
  LabelType,
  Project,
  ProjectId,
  ProjectWithUsers,
  UserPermission,
  UserId,
  Defect,
} from '@clef/shared/types';
import { AnomalyDefectionClassName } from '@/utils';

import { useCallback } from 'react';
import { useMutation, useQueryClient, QueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router';
import { useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { projectQueryKeys, useGetSelectedProjectQuery } from './queries';
import ProjectAPI from '@/api/project_api';
import DefectApi from '@/api/defect_api';
import { CLEF_PATH } from '@/constants/path';
import { useSetSelectedProjectId } from './actions';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import ProjectModelAPI from '@/api/project_model_api';
import { isValidProjectName } from '@/components/Utils/Validation';
import { saveUserToProjectWithPermissionApi } from '@/hooks/api/useProjectApi';
import { ApiErrorType } from '@/api/base_api';
import { COLOR_ARCHIVED_DEFECT, RENAME_ARCHIVED_DEFECT } from '@clef/shared/constants';
import { datasetQueryKeys } from '../dataset';

const onAddNewProject = (newProject: ProjectWithUsers, queryClient: QueryClient) => {
  queryClient.setQueryData(projectQueryKeys.detail(newProject.id), newProject);
  queryClient.setQueryData(projectQueryKeys.list(newProject.orgId), (previous: Project[] = []) => {
    return [...previous, newProject];
  });
  queryClient.invalidateQueries({
    queryKey: projectQueryKeys.list(newProject.orgId),
    refetchType: 'none',
  });
};

export const useUpdateDefectMutation = () => {
  const queryClient = useQueryClient();
  const projectId = useTypedSelector(state => state.project.selectedProjectId) ?? 0;
  return useMutation({
    mutationFn: async (defectToUpdate: Defect) => {
      await DefectApi.updateDefect({
        id: defectToUpdate.id,
        defect: defectToUpdate,
      });
      return defectToUpdate;
    },
    onSuccess: (defectToUpdate: Defect) => {
      queryClient.setQueriesData(projectQueryKeys.defects(projectId), (previous: Defect[] = []) => {
        return previous.map(defect => {
          if (defect.id === defectToUpdate.id) return defectToUpdate;
          else return defect;
        });
      });
    },
  });
};

export const useCreateDefectMutation = () => {
  const queryClient = useQueryClient();
  const selectedProjectId = useTypedSelector(state => state.project.selectedProjectId) ?? 0;
  return useMutation({
    mutationFn: async (newDefect: Partial<Defect>) => {
      const response = await DefectApi.createDefect({
        ...newDefect,
        projectId: newDefect.projectId ? newDefect.projectId : selectedProjectId,
      });
      return response.data;
    },
    onSuccess: (newDefect: Defect) => {
      queryClient.setQueriesData(
        projectQueryKeys.defects(newDefect.projectId),
        (previous: Defect[] = []) => {
          return [...previous, newDefect];
        },
      );
      queryClient.invalidateQueries(datasetQueryKeys.filterOptions(selectedProjectId));
    },
  });
};

export const useDeleteDefectMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const selectedProject = useGetSelectedProjectQuery().data;
  return useMutation({
    mutationFn: async (defect: Defect) => {
      await DefectApi.archiveDefect(defect.id);
      return defect;
    },
    onSuccess: ({ id, name }: Defect) => {
      selectedProject &&
        queryClient.setQueriesData(
          projectQueryKeys.defects(selectedProject.id),
          (previous: Defect[] = []) => {
            return previous.map(defect => {
              if (defect.id === id)
                return {
                  ...defect,
                  isArchived: true,
                  color: COLOR_ARCHIVED_DEFECT,
                  name: defect.name + RENAME_ARCHIVED_DEFECT,
                };
              else return defect;
            });
          },
        );
      selectedProject?.datasetId &&
        queryClient.invalidateQueries(datasetQueryKeys.mediaDetails(selectedProject.datasetId));
      selectedProject &&
        queryClient.invalidateQueries(datasetQueryKeys.allWithFilters(selectedProject.id));
      selectedProject &&
        queryClient.invalidateQueries(datasetQueryKeys.filterOptions(selectedProject.id));
      enqueueSnackbar(`successfully deleted defect: ${name}`, {
        variant: 'success',
      });
    },
    onError: (err: any) => {
      enqueueSnackbar(
        (err?.body || err)?.message, // this could be an api error or frontend error
        { variant: 'error' },
      );
    },
  });
};

export const useCreateProjectMutation = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const theme = useTheme();

  const setSelectedProjectId = useSetSelectedProjectId();
  const createDefect = useCreateDefectMutation();

  const createProject = useMutation({
    mutationFn: async (labelType: LabelType) => {
      const res = await ProjectAPI.createProject(
        'New project',
        false /* isInviteOnly = */,
        labelType,
      );
      return res.data;
    },
    onSuccess: newProject => {
      onAddNewProject(newProject, queryClient);
      if (newProject.labelType === LabelType.AnomalyDetection) {
        createDefect.mutate({
          name: AnomalyDefectionClassName.Normal,
          color: theme.palette.success.main,
          projectId: newProject.id,
        });
        createDefect.mutate({
          name: AnomalyDefectionClassName.Abnormal,
          color: theme.palette.error.main,
          projectId: newProject.id,
        });
      }
    },
  });

  return useCallback(
    async (labelType: LabelType = LabelType.BoundingBox) => {
      const newProject = await createProject.mutateAsync(labelType);
      await setSelectedProjectId(newProject.id);
      history.push(`${CLEF_PATH.root}/${newProject.orgId}/pr/${newProject.id}`);
    },
    [createProject, history, setSelectedProjectId],
  );
};

export const useUpdateProjectMutation = () => {
  const queryClient = useQueryClient();
  const orgId = useTypedSelector(state => state.login.user?.orgId);
  const selectedProject = useGetSelectedProjectQuery();

  return useMutation({
    mutationFn: async (params: {
      id: ProjectId;
      name?: string;
      labelType?: LabelType;
      registeredModelId?: string;
    }) => {
      const datasetId = queryClient.getQueryData<ProjectWithUsers | undefined>(
        projectQueryKeys.detail(params.id),
      )?.datasetId;
      if (params.name) {
        await ProjectAPI.rename(params.id, params.name);
      } else if (params.labelType && datasetId) {
        await ProjectAPI.updateLabelType(params.id, datasetId, params.labelType);
      } else if (
        params.registeredModelId &&
        selectedProject.data?.labelType === LabelType.SegmentationInstantLearning
      ) {
        await ProjectModelAPI.postRegisterModelId(params.id, params.registeredModelId);
      }
      return params;
    },
    onSuccess: useCallback(
      params => {
        queryClient.setQueryData(
          projectQueryKeys.detail(params.id),
          (previous: ProjectWithUsers | undefined) => {
            return previous ? { ...previous, ...params } : previous;
          },
        );
        queryClient.setQueryData(projectQueryKeys.list(orgId ?? -1), (previous: Project[] = []) => {
          return previous.map(project => {
            if (project.id === params.id) return { ...project, ...params };
            return project;
          });
        });
      },
      [orgId, queryClient],
    ),
  });
};

export const useSoftDeleteProjectMutation = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const orgId = useTypedSelector(state => state.login.user?.orgId)!;
  const userId = useTypedSelector(state => state.login.user?.id)!;

  const deleteProject = useMutation({
    mutationFn: async (params: { projectId: ProjectId }) => {
      await ProjectAPI.softDeleteProject(params.projectId);
      return params.projectId;
    },
    onSuccess: useCallback(
      deletedProjectId => {
        queryClient.setQueryData(projectQueryKeys.list(orgId ?? -1), (previous: Project[] = []) => {
          return previous.filter(project => project.id !== deletedProjectId);
        });
        queryClient.setQueryData(
          projectQueryKeys.activeProjectList(userId, orgId),
          (previous: Project[] = []) => {
            return previous.filter(project => project.id !== deletedProjectId);
          },
        );
      },
      [orgId],
    ),
    onError: e => {
      enqueueSnackbar((e as Error).message, {
        variant: 'error',
      });
    },
  });

  return useCallback((selectedProject: Project) => {
    deleteProject.mutate(
      { projectId: selectedProject.id },
      {
        onSuccess: () => {
          enqueueSnackbar(t(`Project "${selectedProject.name}" was successfully deleted`), {
            variant: 'success',
          });
        },
      },
    );
  }, []);
};

export const useAddActiveProjectMutation = () => {
  const queryClient = useQueryClient();
  const orgId = useTypedSelector(state => state.login.user?.orgId)!;
  const userId = useTypedSelector(state => state.login.user?.id)!;
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async (params: { projectId: ProjectId }) => {
      const res = await ProjectAPI.addActiveProject(params.projectId);
      return res.data;
    },
    onSuccess: () => {
      orgId && queryClient.invalidateQueries(projectQueryKeys.activeProjectList(userId, orgId));
      enqueueSnackbar(t('Successfully added an active project'), {
        variant: 'success',
      });
    },
    onError: e =>
      enqueueSnackbar((e as Error).message, {
        variant: 'error',
      }),
  });
};

export const useDeleteActiveProjectMutation = () => {
  const queryClient = useQueryClient();
  const orgId = useTypedSelector(state => state.login.user?.orgId)!;
  const userId = useTypedSelector(state => state.login.user?.id)!;
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async (params: { projectId: ProjectId }) => {
      const res = await ProjectAPI.deleteActiveProject(params.projectId);
      return res.data;
    },
    onSuccess: () => {
      orgId && queryClient.invalidateQueries(projectQueryKeys.activeProjectList(userId, orgId));
      enqueueSnackbar(t('Successfully removed an active project'), {
        variant: 'success',
      });
    },
    onError: e =>
      enqueueSnackbar((e as Error).message, {
        variant: 'error',
      }),
  });
};

export const useRenameProjectMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const updateProject = useUpdateProjectMutation();

  return useMutation({
    mutationFn: async (params: {
      projectId: ProjectId;
      originalProjectName: Project['name'];
      editingProjectName: Project['name'];
    }) => {
      if (params.editingProjectName === params.originalProjectName) {
        return;
      }
      if (isValidProjectName(params.editingProjectName)) {
        await updateProject.mutateAsync({
          id: params.projectId,
          name: params.editingProjectName,
        });
      } else {
        throw Error(
          t('Project name can only contain letters, numbers, dashes, underscores and spaces'),
        );
      }
    },
    onError: e =>
      enqueueSnackbar((e as Error).message, {
        variant: 'error',
      }),
  });
};

export const useAddProjectMembersMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const setSelectedProjectId = useSetSelectedProjectId();

  return useMutation({
    mutationFn: async (params: {
      projectId: ProjectId;
      projectName: Project['name'];
      numberOfUsersToAdd: number;
      userPermissions: {
        id: UserId;
        permissions: UserPermission[];
      }[];
    }) => {
      const userPermissionsToSave = params.userPermissions.filter(
        user => typeof user.id === 'string',
      ) as {
        id: UserId;
        permissions: UserPermission[];
      }[];
      if (userPermissionsToSave.length === 0) {
        throw Error(t('No users to add'));
      }
      await saveUserToProjectWithPermissionApi(params.projectId, userPermissionsToSave);
      return {
        projectId: params.projectId,
        projectName: params.projectName,
        numberOfUsersToAdd: params.numberOfUsersToAdd,
      };
    },
    onError: e =>
      enqueueSnackbar((e as Error).message, {
        variant: 'error',
      }),
    onSuccess: async res => {
      await setSelectedProjectId(
        res.projectId,
        true, // shouldInvalidProjectQuery
      );
      enqueueSnackbar(
        t('Successfully added {{numberOfUsersToAdd}} member(s) to {{projectName}}', {
          numberOfUsersToAdd: res.numberOfUsersToAdd,
          projectName: res.projectName,
        }),
        {
          variant: 'success',
        },
      );
    },
  });
};

export const useImportSampleDataMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const selectedProject = useGetSelectedProjectQuery().data;
  return useMutation({
    mutationFn: async (params: { s3folder: string; importLabels: boolean }) => {
      if (!selectedProject) return;
      await ProjectAPI.importSampleData(selectedProject.id, params.s3folder, params.importLabels);
    },
    onSuccess: () => {
      selectedProject &&
        queryClient.invalidateQueries(projectQueryKeys.defects(selectedProject.id));
      selectedProject && queryClient.invalidateQueries(projectQueryKeys.stats(selectedProject.id));
      if (selectedProject && !selectedProject.coverMediaId) {
        queryClient.invalidateQueries(projectQueryKeys.list(selectedProject.orgId));
      }
      selectedProject &&
        queryClient.invalidateQueries(datasetQueryKeys.allWithFilters(selectedProject.id));
      selectedProject &&
        queryClient.invalidateQueries(datasetQueryKeys.filterOptions(selectedProject.id));
      enqueueSnackbar(t('Successfully imported sample data'), {
        variant: 'success',
      });
    },
    onError: (err: ApiErrorType) => {
      enqueueSnackbar(
        (err?.body || err)?.message, // it can be an api error or frontend error
        { variant: 'error' },
      );
    },
  });
};

import React, { useCallback } from 'react';
import { Button, TopDrawer } from '@clef/client-library';
import { useImmer } from 'use-immer';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import DeviceMonitorTopPanel from './DeviceMonitorTopPanel';
import EdgeMediaGrid from './EdgeMediaGrid';
import EdgeMediaDisplayOptions from './EdgeMediaDisplayOptions';
import EdgeMediaPaginationOptions from './EdgeMediaPaginationOptions';
import { useDeviceMonitorStyles } from './deviceMonitorStyles';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { defaultState, DeviceMonitorStateContext } from './deviceMonitorState';
import { AnnotationType, Media } from '@clef/shared/types';
import CLIA from '../../../api/clia_api';
import classNames from 'classnames';
import { useAppDispatch } from '../../../store';
import { startUpload } from '../../../store/uploadState';
import { generateUploadedMessage, getUploadStats } from '../../../store/uploadState/utils';
import { UploadState } from '../../../store/uploadState/types';
import { useQueryClient } from '@tanstack/react-query';
import { endpointQueryKeys } from '@/serverStore/endpoints';

interface DeviceMonitorDashboardProps {
  edgeProjectId: number;
  mediaLimit?: number | null;
  mediaCapacity?: number | null;
  hideDeviceSwitcher?: boolean;
  emptyStateComponent?: React.ReactNode;
}

const MonitorDashboard: React.FC<DeviceMonitorDashboardProps> = ({
  edgeProjectId,
  mediaLimit,
  mediaCapacity,
  hideDeviceSwitcher = false,
  emptyStateComponent,
}) => {
  const styles = useDeviceMonitorStyles();
  const queryClient = useQueryClient();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const [state, dispatch] = useImmer({
    ...defaultState,
    isClassificationMedium:
      selectedProject?.labelType?.toString() === AnnotationType.classification ||
      selectedProject?.labelType?.toString() === AnnotationType.anomaly_detection,
  });
  const appDispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const startMediaToastUpload = useCallback(async () => {
    let mediasData = [] as unknown as { mediaId: number; dmMediaId: number }[];
    try {
      // @ts-ignore unwrap does work
      const { uploadData, uploadedMediaData } = await appDispatch(
        startUpload({ selectedProject }),
      ).unwrap();

      /**
       * Update CLIA to mark medias
       */

      mediasData = (uploadedMediaData as unknown as (Media & { mediaId: number })[])
        .filter(Boolean)
        .map(edgeMedia => ({
          mediaId: edgeMedia.mediaId,
          dmMediaId: edgeMedia.id,
        }));
      await CLIA.updateMedias(mediasData);

      enqueueSnackbar(
        t('Successfully uploaded {{count}} image(s)!', { count: uploadData.length }),
        { variant: 'success' },
      );
    } catch (err) {
      const uploadStats = getUploadStats((err as UploadState).uploadData);
      enqueueSnackbar(generateUploadedMessage(uploadStats), {
        variant: 'warning',
      });
    } finally {
      /**
       * Refresh data
       */
      if (mediasData.length && selectedProject?.id) {
        mediasData.forEach(media =>
          queryClient.invalidateQueries(
            endpointQueryKeys.edgeMediaDetails(selectedProject.id, media.mediaId),
          ),
        );
      }
    }
  }, [appDispatch, enqueueSnackbar, selectedProject?.id]);

  return (
    <DeviceMonitorStateContext.Provider value={{ state, dispatch }}>
      {/* Media Selection Actions */}
      <TopDrawer
        show={!!state.selectedMedia.length}
        classes={{
          root: classNames(styles.scrollStickyContainer, styles.newTopDrawer),
        }}
      >
        <Button
          id="add-images-to-build"
          variant="text"
          color="primary"
          className={styles.actionButtons}
          onClick={() => {
            startMediaToastUpload();
          }}
        >
          {t('Add Images to Build')}
        </Button>
      </TopDrawer>
      {/* Top Panel */}
      <DeviceMonitorTopPanel
        edgeProjectId={edgeProjectId}
        hideDeviceSwitcher={hideDeviceSwitcher}
      />
      {!!state.deviceId && (
        <>
          <Box marginTop={5} />
          {/* Media Display Options + Sort Options */}
          <EdgeMediaDisplayOptions />
          {/* Media Pagination Options */}
          <EdgeMediaPaginationOptions />
          {/* Media Grid */}
          <EdgeMediaGrid
            fileCapacity={mediaCapacity}
            fileLimit={mediaLimit}
            emptyStateComponent={emptyStateComponent}
          />
        </>
      )}
    </DeviceMonitorStateContext.Provider>
  );
};
/**
 * @deprecated 2024-Feb-26 this component is only been used in HistoricalData
 */
export default MonitorDashboard;

import React, { useState, useCallback } from 'react';
import cx from 'classnames';
import { Card, Tooltip, TextField, InputAdornment, Typography } from '@material-ui/core';
import { Button, useKeyPress, greyScale } from '@clef/client-library';
import {
  IconBrushPlus,
  IconBrushMinus,
  IconProps,
  IconPolygonPlus,
  IconPolygonMinus,
} from './ToolIcon';
import { ToolMode, useLabelingState } from './labelingState';
import { useLabelingStyles } from './labelingStyles';

const modeToEraseOptionIcons: {
  [mode in ToolMode]?: { PlusIcon: React.FC<IconProps>; MinusIcon: React.FC<IconProps> };
} = {
  [ToolMode.Brush]: {
    PlusIcon: IconBrushPlus,
    MinusIcon: IconBrushMinus,
  },
  [ToolMode.Polygon]: {
    PlusIcon: IconPolygonPlus,
    MinusIcon: IconPolygonMinus,
  },
};

export const MIN_STROKE_WIDTH = 1;
export const MAX_STROKE_WIDTH = 999;

const calcLineWidthCapped = (value: number | string) => {
  return Math.min(Math.max(Number(value), MIN_STROKE_WIDTH), MAX_STROKE_WIDTH);
};

const SegmentationToolModeOptionsCard: React.FC<{}> = () => {
  const styles = useLabelingStyles();
  const {
    state: {
      toolMode,
      toolOptions: { strokeWidth, erasing, eraserWidth },
    },
    dispatch,
  } = useLabelingState();

  const functionalWidth = erasing ? eraserWidth : strokeWidth;
  const [localLineWidth, setLocalLineWidth] = useState<string | number>(functionalWidth);

  const setStrokeWidth = useCallback(
    (newValue: string | number) => {
      const newValueCapped = Math.min(
        Math.max(Number(newValue), MIN_STROKE_WIDTH),
        MAX_STROKE_WIDTH,
      );
      dispatch(draft => {
        if (erasing) {
          draft.toolOptions.eraserWidth = newValueCapped;
        } else {
          draft.toolOptions.strokeWidth = newValueCapped;
        }
      });
    },
    [dispatch, erasing],
  );

  const OptionButtons = toolMode ? modeToEraseOptionIcons[toolMode] : undefined;
  const enableStrokeWidth = toolMode
    ? [ToolMode.Brush, ToolMode.Polyline].includes(toolMode)
    : false;

  useKeyPress(
    '[',
    () => {
      if (enableStrokeWidth) {
        const cappedValue = calcLineWidthCapped(functionalWidth - 1);
        setStrokeWidth(cappedValue);
        setLocalLineWidth(cappedValue);
      }
    },
    { id: 'task-line-width-decr' },
  );

  useKeyPress(
    ']',
    () => {
      if (enableStrokeWidth) {
        const cappedValue = calcLineWidthCapped(functionalWidth + 1);
        setStrokeWidth(cappedValue);
        setLocalLineWidth(cappedValue);
      }
    },
    { id: 'task-line-width-incr' },
  );

  const toggleEraseModeHandler = useCallback(() => {
    if (OptionButtons) {
      dispatch(draft => {
        draft.toolOptions.erasing = !erasing;
      });
    }
  }, [dispatch, OptionButtons, erasing]);
  useKeyPress('e', toggleEraseModeHandler, { id: 'task-toggle-eraser' });

  if (!OptionButtons && !enableStrokeWidth) {
    return null;
  }

  return (
    <Card className={cx(styles.toolCard, styles.marginRight5)}>
      {OptionButtons && (
        <Tooltip
          arrow
          placement="top"
          title={t('toggle draw / erase {{hotKey}}', {
            hotKey: <span className={styles.codeBlock}>e</span>,
          })}
        >
          <div className={styles.flexRow}>
            <Button
              className={styles.labelModeButton}
              color="primary"
              aria-label="tool-option-button-draw"
              variant={erasing ? undefined : 'contained'}
              onClick={() => {
                dispatch(draft => {
                  draft.toolOptions.erasing = false;
                });
              }}
              id="task-eraser-off"
            >
              <OptionButtons.PlusIcon color={erasing ? greyScale[500]! : 'white'} />
            </Button>
            <Button
              className={styles.labelModeButton}
              color="primary"
              aria-label="tool-option-button-erase"
              variant={erasing ? 'contained' : undefined}
              onClick={() => {
                dispatch(draft => {
                  draft.toolOptions.erasing = true;
                });
              }}
              id="task-eraser-on"
            >
              <OptionButtons.MinusIcon color={erasing ? 'white' : greyScale[500]!} />
            </Button>
          </div>
        </Tooltip>
      )}
      {enableStrokeWidth && (
        <Tooltip
          arrow
          placement="top"
          title={t('Set line width {{hotKey1}} / {{hotKey2}}, ', {
            hotKey1: <span className={styles.codeBlock}>[</span>,
            hotKey2: <span className={styles.codeBlock}>]</span>,
          })}
        >
          <TextField
            size="small"
            className={styles.lineWidthInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>{erasing ? t('Eraser size:') : t('Line width:')}</Typography>
                </InputAdornment>
              ),
              classes: {
                notchedOutline: styles.notchedOutline,
              },
            }}
            variant="outlined"
            required={false}
            value={localLineWidth}
            type="number"
            inputProps={{ className: styles.numberInput, ['aria-label']: 'line-width-input' }}
            onFocus={event => {
              event.target.select();
            }}
            onChange={e => {
              e.persist();
              const newValue = e.target.value;
              // locally, this value can be beyond capped, will be adjusted to be correct o blur
              setLocalLineWidth(newValue);
              setStrokeWidth(calcLineWidthCapped(newValue));
            }}
            onBlur={() => {
              setLocalLineWidth(functionalWidth);
            }}
          />
        </Tooltip>
      )}
    </Card>
  );
};

export default SegmentationToolModeOptionsCard;

import { createStyles, StyleRules, WithStyles, withStyles, Tooltip } from '@material-ui/core';
import React from 'react';
import { sum } from '@clef/shared/utils/math';

interface BarSpec {
  text: string;
  value: number;
  color: string;
}

const styles = (): StyleRules =>
  createStyles({
    bar: {
      float: 'left',
      height: 10,
    },
  });

interface ProgressBarProps extends WithStyles<typeof styles> {
  barSpecs: BarSpec[];
}

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const MultiColorProgressBar: React.FunctionComponent<ProgressBarProps> = ({
  classes,
  barSpecs,
}) => {
  const total = sum(barSpecs.map(spec => spec.value));
  const bars = barSpecs.map((spec, idx) => {
    const percentage = (spec.value / total) * 100;

    const tooltipText = `${spec.text}: ${spec.value} (${Math.round(percentage)}%)`;
    return (
      <LightTooltip title={tooltipText} key={idx}>
        <div
          aria-label={tooltipText}
          className={classes.bar}
          style={{
            backgroundColor: spec.color,
            width: percentage + '%',
          }}
          key={idx}
        ></div>
      </LightTooltip>
    );
  });

  return <div className="bars">{bars}</div>;
};

export default withStyles(styles)(MultiColorProgressBar);

import React from 'react';
import { Popover, List, ListItem, ListItemText, makeStyles, Tooltip } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CompareArrows from '@material-ui/icons/CompareArrows';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Archive from '@material-ui/icons/Archive';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

import { IconButton } from '@clef/client-library';

const useStyles = makeStyles(theme => ({
  moreActionButton: {
    padding: '8px',
    height: '94px',
    borderRadius: '0px',

    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

interface EvaluationSetMoreActionsProps {
  snapshotVersion: number;
  onCompareModelsClick: () => void;
  onArchiveEvaluationSetClick: () => void;
  disabled?: boolean;
}

const EvaluationSetMoreActions: React.FC<EvaluationSetMoreActionsProps> = ({
  snapshotVersion,
  onCompareModelsClick,
  onArchiveEvaluationSetClick,
  disabled = false,
}) => {
  const styles = useStyles();

  return (
    <PopupState variant="popover" popupId="snapshot-page-more-action-popup-popover">
      {popupState => (
        <>
          <IconButton
            id="evaluation-set-more-action"
            className={styles.moreActionButton}
            {...bindTrigger(popupState)}
            disabled={disabled}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <List>
              <Tooltip arrow placement="top" title={''}>
                {/* wrap with a span so that the tooltip will not hide when button disabled */}
                <span>
                  <ListItem
                    button
                    id="compare-btn"
                    className={styles.listItem}
                    onClick={() => {
                      onCompareModelsClick?.();
                      popupState.close();
                    }}
                  >
                    <CompareArrows fontSize="small" />
                    <ListItemText primary={t('Compare models')} />
                  </ListItem>
                </span>
              </Tooltip>
              <Tooltip arrow interactive placement="top" title={''}>
                <span>
                  <ListItem
                    button
                    id="view-snapshot-btn"
                    className={styles.listItem}
                    onClick={() => {
                      const newUrl =
                        window.location.href.split('/models')[0] +
                        '/data/dataset_snapshot/' +
                        snapshotVersion;
                      window.open(newUrl, '_blank', 'noopener,noreferrer');
                      popupState.close();
                    }}
                  >
                    <OpenInNew fontSize="small" />
                    <ListItemText primary={t('View snapshot')} />
                  </ListItem>
                </span>
              </Tooltip>
              <Tooltip arrow interactive placement="top" title={''}>
                <span>
                  <ListItem
                    button
                    id="archive-evaluation-btn"
                    className={styles.listItem}
                    onClick={() => {
                      onArchiveEvaluationSetClick?.();
                      popupState.close();
                    }}
                  >
                    <Archive fontSize="small" />
                    <ListItemText primary={t('Archive evaluation set')} />
                  </ListItem>
                </span>
              </Tooltip>
            </List>
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default EvaluationSetMoreActions;

import React, { useState } from 'react';
import { Box, Drawer, LinearProgress, makeStyles } from '@material-ui/core';
import { greyModernScale, Typography } from '@clef/client-library';
import MatTable from '@/components/Utils/MatTable';
import { Column } from '@material-table/core';
import {
  DatasetVersion,
  DatasetVersionId,
  RegisteredModel,
  RegisteredModelId,
} from '@clef/shared/types';
import { isModelTrainingFailed } from '@/store/projectModelInfoState/utils';
import { useGetProjectModelListQuery } from '@/serverStore/projectModels';
import ModelNameAndConfigurationTableCell from './componentsV2/TableCell/ModelNameAndConfigurationTableCell';
import { useDatasetExportedWithVersionsQuery } from '@/serverStore/dataset';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';
import { useHistory } from 'react-router';
import ModelDetailsPanel from './componentsV2/ModelDetailsPanel/ModelDetailsPanel';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    '& .MuiPaper-root': {
      outline: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 8,
      overflow: 'hidden',
      boxShadow: 'none !important',
    },
    '& .MuiTableCell-sizeSmall:last-child': {
      borderRightStyle: 'none !important',
    },
  },
  modelNameCell: {
    display: 'flex',
    height: '100%',
    width: '100%',
    border: 'none !important',
  },
  reportDrawer: {
    top: '108px', // 108px is the height of the top tabs
    height: 'calc(100% - 108px)',
  },
}));

export const tableStyleOptions: Record<string, React.CSSProperties> = {
  headerStyle: {
    backgroundColor: greyModernScale[50],
    height: 61,
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: greyModernScale[300],
  },
  rowStyle: {
    fontSize: 13,
    height: 61,
  },
  cellStyle: {
    padding: 0,
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: greyModernScale[300],
  },
};

export type FailedModelJobsPageProps = {};

const FailedModelJobsPage: React.FC<FailedModelJobsPageProps> = () => {
  const styles = useStyles();
  const { data: modelListData, isLoading: isModelListLoading } = useGetProjectModelListQuery();
  const getLastWeekDate = () => {
    const today = new Date();
    return new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() - 7);
  };
  const failedModels = modelListData
    ?.filter(model => isModelTrainingFailed(model.status))
    .filter(model => {
      if (!model.createdAt) return false;
      const modelCreatedDate = new Date(model.createdAt);
      return modelCreatedDate > getLastWeekDate();
    });
  const history = useHistory();
  const modelIdFromSearch = new URLSearchParams(history?.location?.search).get('modelId');
  const [selectedModelId, setSelectedModelId] = useState<string | null>(modelIdFromSearch);
  const selectedModel = failedModels?.find(model => model.id === selectedModelId);
  const { data: snapshots } = useDatasetExportedWithVersionsQuery({
    withCount: true,
    includeNotCompleted: true,
    includeFastEasy: true,
  });

  const datasetVersions = snapshots && snapshots.datasetVersions ? snapshots.datasetVersions : [];
  const datasetVersionsMap = datasetVersions.reduce((acc, cur: DatasetVersion) => {
    return { ...acc, [cur.id]: cur };
  }, {} as Record<DatasetVersionId, DatasetVersion>);
  const getColumns = (
    datasetVersionsMap: Record<DatasetVersionId, DatasetVersion>,
    selectedModelId: RegisteredModelId | null,
  ): Column<RegisteredModel>[] => [
    {
      id: 'model-name-and-configuration',
      title: t('Model'),
      render: item => (
        <ModelNameAndConfigurationTableCell
          model={item}
          className={styles.modelNameCell}
          datasetVersionName={
            datasetVersionsMap[item.datasetVersionId!]?.name ?? (
              <Skeleton variant="text" width={50} />
            )
          }
          selected={selectedModelId === item.id}
          onClick={() => setSelectedModelId(item.id)}
        />
      ),
    },
    {
      id: 'model-job-id',
      title: t('Job ID'),
      render: item => (
        <Box padding={4}>
          <Typography variant="body_regular" maxWidth={300}>
            {item.id}
          </Typography>
        </Box>
      ),
    },
    {
      id: 'model-status',
      title: t('Status'),
      render: item => (
        <Box padding={4}>
          <Typography variant="body_regular" maxWidth={300}>
            {t(item.status!)}
          </Typography>
        </Box>
      ),
    },
    {
      id: 'model-trained-at',
      title: t('Trained at'),
      render: item => (
        <Box padding={4}>
          {item.createdAt && (
            <Typography variant="body_regular" maxWidth={300}>
              {new Date(item.createdAt).toLocaleString()}
            </Typography>
          )}
        </Box>
      ),
    },
    // {
    //   id: 'Operations',
    //   title: t(''),
    //   width: 110,
    //   render: item => (
    //     <Box display="flex" alignItems="center" justifyContent="center" padding={4}>
    //       <Dropdown
    //         dropdown={
    //           <MenuList>
    //             <MenuItem
    //               onClick={() => {
    //                 console.log('retrain clicked');
    //               }}
    //             >
    //               {t('Retrain')}
    //             </MenuItem>
    //           </MenuList>
    //         }
    //       >
    //         <IconButton>
    //           <MoreHoriz />
    //         </IconButton>
    //       </Dropdown>
    //     </Box>
    //   ),
    // },
  ];

  return (
    <Box>
      <Box marginTop={5} position="relative" className={styles.tableContainer}>
        {isModelListLoading && (
          <LinearProgress style={{ width: '100%', position: 'absolute', bottom: '100%' }} />
        )}
        <MatTable<RegisteredModel>
          title=""
          data={failedModels ?? []}
          columns={getColumns(datasetVersionsMap, selectedModelId)}
          options={{
            ...tableStyleOptions,
            search: false,
            toolbar: false,
            padding: 'dense',
            pageSize: 10,
          }}
        />
      </Box>
      <Drawer
        classes={{ paper: styles.reportDrawer }}
        variant="persistent"
        anchor="right"
        open={!!selectedModel}
        onClose={() => {
          setSelectedModelId(null);
        }}
      >
        <Box width="72vw" height="100%">
          {selectedModel && (
            <ModelDetailsPanel
              key={`${selectedModel.id}`}
              model={selectedModel}
              datasetVersion={datasetVersionsMap[selectedModel.datasetVersionId!]}
              threshold={0}
              onClose={() => {
                setSelectedModelId(null);
              }}
            />
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default FailedModelJobsPage;

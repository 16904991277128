import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography, Button } from '@clef/client-library';

import emptyBox from '@/images/empty_box.svg';
import { LabelType } from '@clef/shared/types';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(4),
    display: 'flex',
    gap: theme.spacing(5),
    flexDirection: 'column',
    alignItems: 'center',
  },
  emptyBoxIcon: {
    width: 120,
    height: 120,
  },
  descriptionContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
    textAlign: 'center',
  },
  title: {
    fontWeight: 500,
    color: theme.palette.grey[800],
  },
}));

export const ModelPerformanceSidebarEmptyState: React.FC<{ labelType?: LabelType | null }> = ({
  labelType,
}) => {
  const styles = useStyles();
  if (!labelType) {
    return (
      <Typography variant="body1" gutterBottom>
        {t('No model trained yet.')}
      </Typography>
    );
  }
  return (
    <div className={styles.container}>
      <img src={emptyBox} className={styles.emptyBoxIcon} />
      <div className={styles.descriptionContainer}>
        <Typography className={styles.title} variant="h4">
          {t('You don’t have any models yet')}
        </Typography>
        <Typography>
          {t('You can train a model after you label {{imageCount}}.', {
            imageCount:
              labelType === LabelType.SegmentationInstantLearning
                ? '1 image'
                : labelType === LabelType.Segmentation
                ? '2 images'
                : '10 images',
          })}
        </Typography>
      </div>
      <Button
        id="model-empty-state-learn-more-button"
        variant="outlined"
        onClick={() =>
          window.open(
            'https://support.landing.ai/docs/train-models',
            '_blank',
            'noopener,noreferrer',
          )
        }
      >
        {t('Learn More')}
      </Button>
    </div>
  );
};

import React, { useState, useCallback, useEffect } from 'react';
import FullscreenDialog from '../../FullScreenDialog';

import { ClassificationUploadType } from './types';
import { useUploadStyles } from '../styles';

import PickUploadType from './PickUploadType';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { UploadStage } from '../../../../store/uploadState/types';
import { UploadFullscreenProps } from '..';
import { useAppDispatch } from '../../../../store';
import { resetUploadState } from '../../../../store/uploadState';
import ClassifiedUploadDropFileZone from './ClassifiedUploadDropFileZone';
import UnclassifiedUploadDropFileZone from './UnclassifiedUploadDropFileZone';
import UploadWrapper from '../components/UploadWrapper';
import { Typography } from '@material-ui/core';
import { getUploadStats } from '../../../../store/uploadState/utils';
import { useDialog } from '../../../Layout/components/useDialog';
import MediaUploadProgress from '../components/MediaUploadProgress';
import WebcamFileSwitchButton from '../../../../components/WebcamFileSwitchButton';
import WebcamCapture from '../components/WebcamCapture';
import QRCodeUploadButton from '@/components/QRCodeUploadButton';
import { useIsFeatureEnabledAndMayHideForSnowflake } from '@/hooks/useFeatureGate';

const ClassificationMediaUploadContent: React.FC<{
  mediaCount?: number;
  mediaLimit?: number | null;
  mediaCapacity?: number | null;
  isNewCreditSystem?: boolean;
  hasNoMediaCapacity?: boolean;
  uploadType: ClassificationUploadType | null;
  setUploadType: React.Dispatch<React.SetStateAction<ClassificationUploadType | null>>;
  handleDisableEscapeKeyDown?: (disableEscapeKeyDown: boolean) => void;
  onClose?: () => void;
  initialUseWebcam?: boolean;
}> = ({
  mediaCount,
  mediaLimit,
  mediaCapacity,
  isNewCreditSystem,
  hasNoMediaCapacity,
  uploadType,
  setUploadType,
  handleDisableEscapeKeyDown,
  onClose,
  initialUseWebcam = false,
}) => {
  const styles = useUploadStyles();
  const [useWebcam, setUseWebcam] = useState<boolean>(initialUseWebcam);
  const isWebcamEnabled = useIsFeatureEnabledAndMayHideForSnowflake().webcam;

  return (
    <div className={styles.contentContainer}>
      {!uploadType && (
        <PickUploadType
          onSelectUploadType={uploadType => setUploadType(uploadType)}
          mediaCount={mediaCount}
          mediaLimit={mediaLimit}
          isNewCreditSystem={isNewCreditSystem}
        />
      )}
      {uploadType === ClassificationUploadType.ClassifiedUpload && (
        <UploadWrapper handleDisableEscapeKeyDown={handleDisableEscapeKeyDown} onClose={onClose}>
          <Typography variant="h1" align="center" className={styles.headerText}>
            {t('Classified images upload')}
          </Typography>
          <MediaUploadProgress
            mediaCount={mediaCount}
            mediaLimit={mediaLimit}
            isNewCreditSystem={isNewCreditSystem}
            className={styles.mediaCountProgressBar}
          />

          <ClassifiedUploadDropFileZone
            disabled={hasNoMediaCapacity}
            fileCapacity={mediaCapacity}
            fileLimit={mediaLimit}
            isNewCreditSystem={isNewCreditSystem}
          />
        </UploadWrapper>
      )}
      {uploadType === ClassificationUploadType.UnclassifiedUpload && (
        <UploadWrapper handleDisableEscapeKeyDown={handleDisableEscapeKeyDown} onClose={onClose}>
          <Typography variant="h1" align="center" className={styles.headerText}>
            {t('Unclassified images upload')}
          </Typography>
          <MediaUploadProgress
            mediaCount={mediaCount}
            mediaLimit={mediaLimit}
            isNewCreditSystem={isNewCreditSystem}
            className={styles.mediaCountProgressBar}
          />
          {/* Drop in zone or Webcam */}
          {useWebcam && isWebcamEnabled ? (
            <WebcamCapture
              disabled={hasNoMediaCapacity}
              fileCapacity={mediaCapacity}
              fileLimit={mediaLimit}
              isNewCreditSystem={isNewCreditSystem}
            />
          ) : (
            <UnclassifiedUploadDropFileZone
              disabled={hasNoMediaCapacity}
              fileCapacity={mediaCapacity}
              fileLimit={mediaLimit}
              isNewCreditSystem={isNewCreditSystem}
            />
          )}
          <div className={styles.webcamFileSwitchButtonWrapper}>
            {isWebcamEnabled && (
              <WebcamFileSwitchButton
                useWebcam={useWebcam}
                onClick={() => setUseWebcam(prev => !prev)}
              />
            )}
            <QRCodeUploadButton />
          </div>
        </UploadWrapper>
      )}
    </div>
  );
};

const ClassificationMediaUploadDialog: React.FC<UploadFullscreenProps> = props => {
  const { open, onClose, initialUseWebcam } = props;
  const dispatch = useAppDispatch();
  const [uploadType, setUploadType] = useState<ClassificationUploadType | null>(null);
  const { uploadData, uploadStage } = useTypedSelector(state => state.uploadState);
  const [disableEscapeKeyDown, setDisableEscapeKeyDown] = useState(false);

  useEffect(() => {
    if (open && initialUseWebcam) {
      setUploadType(ClassificationUploadType.UnclassifiedUpload);
    }
  }, [open, initialUseWebcam]);

  const onDialogBack = useCallback(() => {
    setUploadType(null);
    dispatch(resetUploadState());
  }, [dispatch]);

  const { retryableCount } = getUploadStats(uploadData);

  const { showConfirmationDialog } = useDialog();
  const onDialogClose = useCallback(() => {
    let confirmMessage: string | undefined;
    if (uploadStage === UploadStage.NotStarted && uploadData.length) {
      confirmMessage = t('There are still images not uploaded');
    } else if (uploadStage === UploadStage.UploadFulfilledWithFailure && retryableCount > 0) {
      confirmMessage = t('There are images failed to upload');
    }
    if (confirmMessage) {
      showConfirmationDialog({
        title: t('Are you sure you want to leave'),
        content: confirmMessage,
        onConfirm: () => {
          onClose();
          setUploadType(null);
          dispatch(resetUploadState());
        },
        confirmText: t('Leave'),
        color: 'primary',
      });
    } else {
      onClose();
      setUploadType(null);
      dispatch(resetUploadState());
    }
  }, [dispatch, onClose, retryableCount, showConfirmationDialog, uploadData.length, uploadStage]);

  return (
    <FullscreenDialog
      open={open}
      onClose={uploadStage !== UploadStage.UploadInProgress ? onDialogClose : undefined}
      onBack={uploadType && uploadStage === UploadStage.NotStarted ? onDialogBack : undefined}
      onMinimize={uploadStage === UploadStage.UploadInProgress ? () => onClose() : undefined}
      coverPendoGuide
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {open && (
        <ClassificationMediaUploadContent
          uploadType={uploadType}
          setUploadType={setUploadType}
          handleDisableEscapeKeyDown={value => {
            setDisableEscapeKeyDown(value);
          }}
          onClose={onClose}
          initialUseWebcam={initialUseWebcam}
        />
      )}
    </FullscreenDialog>
  );
};

export default ClassificationMediaUploadDialog;

import React, { useState, useEffect, useRef } from 'react';
import { Media, Transform, TransformId, TransformParamValue } from '@clef/shared/types';
import { IconButton } from '@clef/client-library';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/core/styles';
import CarouselImageViewer from './CarouselImageViewer';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useDebounce } from '@clef/client-library';
import { isEqual } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { useTransformImages } from '../../../hooks/api/useTransformsApi';

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    minHeight: 200,
    margin: spacing(2, 0),
    position: 'relative',
  },
  arrowIcon: {
    borderRadius: 100,
    padding: spacing(2),
    backgroundColor: palette.grey[300],
    color: palette.grey[100],
    zIndex: 1,
    '&:hover': {
      backgroundColor: palette.grey[300],
      color: palette.grey[100],
    },
  },
  arrowIconDisabled: {
    display: 'none',
  },
  backArrow: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'rotate(180deg)',
  },
  forwardArrow: {
    position: 'absolute',
    top: '50%',
    right: 0,
  },
  centerImage: {
    display: 'block',
    margin: 'auto',
    width: 300,
  },
}));
type CarouselProps = {
  medias: Media[];
  showCropTool?: boolean;
  alwaysTransform?: boolean;
  localTransformParam: number[];
  transformValues?: { id: TransformId; params: TransformParamValue[] };
  pipelineSections: {
    train: Array<{ id: TransformId; params: TransformParamValue[] }>;
    valid: Array<{ id: TransformId; params: TransformParamValue[] }>;
  };
  lowerRangeSliderKeys?: string[];
  resizeId: string;
  rescaleId: string;
  updateTransformParam(id: TransformId, param: TransformParamValue): void;
  transformMultiples?: number;
  displayAllTransforms?: boolean;
  transformParams: Transform[];
};
const MediaCarousel = ({
  medias,
  transformValues,
  localTransformParam,
  alwaysTransform = false,
  updateTransformParam,
  showCropTool,
  lowerRangeSliderKeys,
  pipelineSections,
  resizeId,
  rescaleId,
  transformMultiples,
  displayAllTransforms = true,
  transformParams,
}: CarouselProps) => {
  const styles = useStyles();
  const [selectedMediaIndex, setSelectedMediaIndex] = useState<number>(0);
  const [processedMedia, setProcessedMedia] = useState<Media[]>([]);
  const [showWithoutResizeInCrop, setShowWithoutResizeInCrop] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [resizeOrRescaleId, setResizeOrRescaleId] = useState<string>('');
  const [resizeOrRescaleHeight, setResizeOrRescaleHeight] = useState<number>(0);
  const [resizeOrRescaleWidth, setResizeOrRescaleWidth] = useState<number>(0);
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const prevTransformValuesRef = useRef<typeof transformValues>();
  const [sectionsToApply, setSectionsToApply] = useState<typeof pipelineSections | null>(null);
  const [transformedUrls, transforming] = useTransformImages(
    sectionsToApply && medias.length
      ? {
          sources: medias.map(t => t.path || ''),
          sections: sectionsToApply,
          projectId: selectedProject?.id,
        }
      : undefined,
  );
  useEffect(() => {
    if (transformedUrls) {
      setProcessedMedia(medias.map((m, i) => ({ ...m, url: transformedUrls[i] })));
    }
  }, [medias, transformedUrls]);
  const applyTransform = useDebounce(async () => {
    if (isEqual(transformValues, prevTransformValuesRef.current) || !transformValues) {
      return;
    }

    if (medias.length) {
      prevTransformValuesRef.current = transformValues;
      setIsProcessing(true);

      const params = transformValues.params.map(param => {
        const lowerKeyIndex = lowerRangeSliderKeys?.indexOf(param.name) || 0;
        const upperKeyIndex =
          lowerRangeSliderKeys?.indexOf(param.name.replace('upper', 'lower')) || 0;
        if (lowerKeyIndex > -1 && localTransformParam[lowerKeyIndex]) {
          return { ...param, value: localTransformParam[lowerKeyIndex] };
        }
        if (upperKeyIndex > -1 && localTransformParam[upperKeyIndex]) {
          return { ...param, value: localTransformParam[upperKeyIndex] };
        }
        if (param.name === 'p') {
          return { ...param, value: 1 };
        }
        return param;
      });

      const resizeIndex = pipelineSections.train.findIndex(section => section.id === resizeId);
      const rescaleIndex = pipelineSections.train.findIndex(section => section.id === rescaleId);
      const cropIndex = pipelineSections.train.findIndex(
        section => section.id === transformValues.id,
      );

      const resizeBeforeCrop = resizeIndex > -1 && cropIndex > resizeIndex;
      const rescaleBeforeCrop = rescaleIndex > -1 && cropIndex > rescaleIndex;

      if (resizeBeforeCrop && rescaleBeforeCrop) {
        setResizeOrRescaleId(resizeIndex > rescaleIndex ? resizeId : rescaleId);
      } else if (resizeBeforeCrop || resizeIndex >= 0) {
        setResizeOrRescaleId(resizeId);
      } else if (rescaleBeforeCrop || rescaleIndex >= 0) {
        setResizeOrRescaleId(rescaleId);
      }

      const showWithoutResizeInCropLocal =
        Boolean(showCropTool) && !(resizeBeforeCrop || rescaleBeforeCrop);

      setShowWithoutResizeInCrop(showWithoutResizeInCropLocal);

      if (showCropTool && processedMedia.length) {
        // only transform once for crop
        setIsProcessing(false);
        setSectionsToApply(null);
        return;
      }

      let newSections = pipelineSections.train;
      if (!displayAllTransforms) {
        const availableTransformIds = transformParams.map(t => t.id);
        newSections = newSections.filter(s => availableTransformIds.includes(s.id));
      }
      if (showCropTool) {
        // don't apply crop when showing crop tool
        newSections = newSections.filter(s => s.id !== transformValues.id);
      }
      newSections = newSections.map(s => {
        return s.id === transformValues.id ? { ...s, params } : s;
      });

      setIsProcessing(false);
      setSectionsToApply({
        train: newSections,
        valid: [],
      });
    }
  }, 500);

  useEffect(() => {
    applyTransform();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    alwaysTransform,
    localTransformParam,
    lowerRangeSliderKeys,
    medias,
    pipelineSections.train,
    rescaleId,
    resizeId,
    resizeOrRescaleId,
    showCropTool,
    transformValues,
    selectedProject,
  ]);

  const handlePreviousClick = () => {
    setSelectedMediaIndex(index => index - 1);
  };

  const handleNextClick = () => {
    setSelectedMediaIndex(index => index + 1);
  };

  useEffect(() => {
    if (showWithoutResizeInCrop) {
      setResizeOrRescaleWidth(0);
      setResizeOrRescaleHeight(0);
    } else {
      const resizeOrRescaleParams = pipelineSections.train.find(
        section => section.id === resizeOrRescaleId,
      )?.params;
      setResizeOrRescaleWidth(resizeOrRescaleParams?.find(param => param.name === 'width')?.value);
      setResizeOrRescaleHeight(
        resizeOrRescaleParams?.find(param => param.name === 'height')?.value,
      );
    }
  }, [resizeOrRescaleId, showWithoutResizeInCrop, pipelineSections.train]);

  const [carouselContainerWidth, setCarouselContainerWidth] = useState(0);
  return (
    <>
      {processedMedia.length === 0 && <Skeleton variant="rect" height={400} />}
      {processedMedia.length > 0 && (
        <>
          <div className={styles.container}>
            <IconButton
              id={'media-carousel-back-button'}
              onClick={handlePreviousClick}
              disabled={selectedMediaIndex === 0}
              classes={{ root: styles.arrowIcon, disabled: styles.arrowIconDisabled }}
              className={styles.backArrow}
            >
              <ArrowForwardIosIcon />
            </IconButton>
            <div
              ref={ref => {
                if (ref && ref.clientWidth !== carouselContainerWidth) {
                  setCarouselContainerWidth(ref.clientWidth);
                }
              }}
            >
              <CarouselImageViewer
                isProcessing={isProcessing || transforming}
                showCropTool={showCropTool}
                imageSrc={processedMedia[selectedMediaIndex]?.url}
                width={
                  resizeOrRescaleWidth || processedMedia[selectedMediaIndex]!.properties!.width
                }
                height={
                  resizeOrRescaleHeight || processedMedia[selectedMediaIndex]!.properties!.height
                }
                transformValues={transformValues}
                updateTransformParam={updateTransformParam}
                transformMultiples={transformMultiples}
                parentWidth={carouselContainerWidth}
              />
            </div>

            <IconButton
              id={'media-carousel-forward-button'}
              onClick={handleNextClick}
              disabled={selectedMediaIndex === medias.length - 1}
              classes={{ root: styles.arrowIcon }}
              className={styles.forwardArrow}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
        </>
      )}
    </>
  );
};

export default MediaCarousel;

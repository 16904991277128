import React, { ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { TransformParamDescription } from '@clef/shared/types';

const useStyles = makeStyles({
  field: {
    width: '25ch',
  },
});

interface ParamInputProps {
  jsonSchema: TransformParamDescription['jsonSchema'];
  isRequired: boolean;
  onChange(newValue: any): void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled: boolean;
  label: string;
  value: any;
  id: string;
  min?: number;
  max?: number;
  errorMessage?: string;
}

const ParamInput = ({
  isRequired,
  jsonSchema,
  onChange,
  onBlur,
  disabled,
  value,
  label,
  id,
  min,
  max,
  errorMessage,
}: ParamInputProps) => {
  const classes = useStyles();

  const getPropsFromSchema = () => {
    switch (jsonSchema.type) {
      case 'integer':
      case 'number':
        return {
          type: 'number',
          min: min ?? jsonSchema.minimum,
          max: max ?? jsonSchema.maximum,
          onChange: (event: ChangeEvent<HTMLInputElement>) =>
            onChange(
              Number.isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber,
            ),
          step: jsonSchema.type === 'integer' ? '1' : 'any',
        };
      default:
        return {
          type: 'text',
          onChange: (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value),
        };
    }
  };

  return (
    <TextField
      data-testid={'param-input-textfield'}
      className={classes.field}
      required={isRequired}
      disabled={disabled}
      onBlur={onBlur}
      variant="outlined"
      margin="normal"
      size="small"
      label={
        // Display full name for the common probability field
        label === 'p'
          ? 'Probability'
          : // Naive attempt at creating a more user-friendly label, good enough for now.
            // In the future, expect the backend to provide a display label, or solve within i18n.
            label.charAt(0).toUpperCase() + label.replace(/_/g, ' ').slice(1)
      }
      value={typeof value === 'undefined' ? '' : value}
      id={id}
      inputProps={getPropsFromSchema()}
      error={!!errorMessage}
      helperText={errorMessage}
    />
  );
};

export default ParamInput;

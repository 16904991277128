import CLEF_PATH from '@/constants/path';
import { Box, Grid, Link, Tooltip, Typography, makeStyles } from '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import { useHistory } from 'react-router';
import {
  useIsFreeUser,
  useIsNonStripeUser,
  useIsPricingV2User,
  useQuotaAndUsageInfo,
} from '../../../../hooks/useSubscriptions';
import BorderLinearProgress from './BorderLinearProgress';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import useStyles from './styles';
import CommonBlockHeader from './CommonBlockHeader';

const CREDIT_USAGE = t('Credit usage');
const CREDIT_USAGE_MO = t('Credit usage/mo');
const CREDIT_USAGE_TOOLTIP = t(
  'Credits do not rollover and will reset at the start of every billing cycle.',
);
const CREDIT_USAGE_MO_TOOLTIP = t(
  'The number of credits used this month. Credits reset at the beginning of each billing cycle and do not roll over.',
);
const USAGE_BAR_INFOTIP_ENTERPRISE = t(
  'The amount of credits used over the total number of credits allotted in the current billing period.',
);
const USAGE_BAR_INFOTIP_VISIONARY = t(
  'Credits used beyond the included 5000 credits will be charged in arrears at $0.01 per credit. Please note: you cannot spend more than 500K credits per month. In such cases, we recommend considering an Enterprise custom package.',
);

const UsageNumber = ({
  usage,
  showUpgrade = false,
}: {
  usage: number | undefined;
  showUpgrade: boolean;
}) => {
  const styles = useStyles();
  const history = useHistory();
  return (
    <Box marginTop={1} display="flex" flexDirection="row" alignItems="center">
      <Typography className={styles.value}>{usage?.toLocaleString()}</Typography>
      {showUpgrade && (
        <Link
          className={styles.clickableLink}
          onClick={() => {
            history.push(CLEF_PATH.planV2);
          }}
        >
          {t('Upgrade')}
        </Link>
      )}
    </Box>
  );
};

const UsageProgressBar = ({
  usage,
  limit,
  infoTip,
}: {
  usage: number | undefined;
  limit: number | undefined;
  infoTip?: string;
}) => {
  const styles = useStyles();
  const usagePercent = Math.min(100, ((usage ?? 0) / (limit || 1)) * 100);
  if (limit === -1) {
    return null;
  }
  return (
    <Box display="flex" alignItems="center" marginTop={1}>
      <BorderLinearProgress
        variant="determinate"
        value={usagePercent}
        color={usagePercent === 100 ? 'secondary' : 'primary'}
      />
      <Typography className={styles.progressValue}>
        {usage}/{limit}
      </Typography>
      {infoTip && (
        <Tooltip arrow placement="bottom" title={infoTip}>
          <InfoOutlined className={styles.infoOutlinedIcon} />
        </Tooltip>
      )}
    </Box>
  );
};

const UsagePromptForVisionary = ({ infoTip }: { infoTip: string }) => {
  const styles = useStyles();
  return (
    <Box display="flex" alignItems="center" marginTop={1}>
      <Typography className={styles.usagePromptForVisionary}>
        {t('5000 credits included')}
      </Typography>
      <Tooltip arrow placement="bottom" title={infoTip}>
        <InfoOutlined className={styles.infoOutlinedIcon} />
      </Tooltip>
    </Box>
  );
};

const CreditUsageBlockEnterprise = () => {
  const { quotaUsage, usage } = useQuotaAndUsageInfo();
  const headerTitle = CREDIT_USAGE;
  const headerTooltip = CREDIT_USAGE_TOOLTIP;
  const infoTip = USAGE_BAR_INFOTIP_ENTERPRISE;

  return (
    <Box>
      {<CommonBlockHeader title={headerTitle} tooltip={headerTooltip} />}
      {<UsageNumber usage={usage} showUpgrade={false} />}
      {<UsageProgressBar usage={usage} limit={quotaUsage} infoTip={infoTip} />}
    </Box>
  );
};

const CreditUsageBlockFree = () => {
  const { quotaUsage, usage } = useQuotaAndUsageInfo();
  const headerTitle = CREDIT_USAGE_MO;
  const headerTooltip = CREDIT_USAGE_MO_TOOLTIP;

  return (
    <Box>
      {<CommonBlockHeader title={headerTitle} tooltip={headerTooltip} />}
      {<UsageNumber usage={usage} showUpgrade={true} />}
      {<UsageProgressBar usage={usage} limit={quotaUsage} />}
    </Box>
  );
};

const CreditUsageBlockVisionary = () => {
  const { usage } = useQuotaAndUsageInfo();
  const headerTitle = CREDIT_USAGE_MO;
  const headerTooltip = CREDIT_USAGE_MO_TOOLTIP;
  const infoTip = USAGE_BAR_INFOTIP_VISIONARY;

  return (
    <Box>
      {<CommonBlockHeader title={headerTitle} tooltip={headerTooltip} />}
      {<UsageNumber usage={usage} showUpgrade={false} />}
      {<UsagePromptForVisionary infoTip={infoTip} />}
    </Box>
  );
};

/**
 * @deprecated remove after pricing migration
 */
const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
  },
}));

/**
 * @deprecated remove after pricing migration
 */
const CreditUsageBlockStripePricingV1User = () => {
  const styles = useStyles();

  const history = useHistory();
  const { quotaUsage, usage } = useQuotaAndUsageInfo();

  const creditUsagePercent = Math.min(100, ((usage ?? 0) / (quotaUsage || 1)) * 100);
  const tooltipClasses = useStylesBootstrap();
  const isNonStripeUser = useIsNonStripeUser();
  return (
    <Box>
      <Tooltip
        arrow
        classes={tooltipClasses}
        title={
          <Box className={styles.tooltipBox}>
            <Typography variant="body1" className={styles.tooltipTitle}>
              {isNonStripeUser ? t('Credit Limit') : t('Credit Limit / Month')}
            </Typography>
            <Typography variant="body1" className={styles.tooltipContent}>
              {t('Credits do not rollover and will reset at the start of every billing cycle.')}
            </Typography>
          </Box>
        }
      >
        <span className={styles.cardTitle}>
          {isNonStripeUser ? t('Credit Limit') : t('Credit Limit / Month')}
        </span>
      </Tooltip>
      <Box marginTop={1} display="flex" alignItems="center">
        <Typography className={styles.value}>
          {quotaUsage === -1 ? t('Unlimited') : quotaUsage?.toLocaleString()}
        </Typography>
        {!isNonStripeUser && (
          <Tooltip title={t('Increase credit limit')} placement="top" arrow>
            <AddCircleOutline
              className={styles.addButton}
              onClick={() => {
                history.push(CLEF_PATH.plan);
              }}
            />
          </Tooltip>
        )}
      </Box>
      {quotaUsage !== -1 && (
        <Box display="flex" alignItems="center" marginTop={1}>
          <BorderLinearProgress
            variant="determinate"
            value={creditUsagePercent}
            color={creditUsagePercent === 100 ? 'secondary' : 'primary'}
          />
          <Typography className={styles.progressValue}>
            {usage}/{quotaUsage}
          </Typography>
          <Tooltip
            arrow
            placement="top"
            title={t(
              'The amount of credits used over the total number of credits allotted in the current billing period',
            )}
          >
            <InfoIcon className={styles.creditLimitMonthInfoButton} />
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

const CreditUsageBlock = () => {
  const isNonStripeUser = useIsNonStripeUser();
  const isPricingV2User = useIsPricingV2User();
  const isFreeUser = useIsFreeUser();

  const renderCreditUsageBlock = () => {
    if (isNonStripeUser) {
      // Enterprise || Enterprise Pre-sales
      return <CreditUsageBlockEnterprise />;
    } else if (isFreeUser) {
      // Stripe V2: Free
      return <CreditUsageBlockFree />;
    } else if (isPricingV2User) {
      // Stripe V2: Visionary
      return <CreditUsageBlockVisionary />;
    } else {
      // Stripe V1: Freemium, Starter, Visionary
      return <CreditUsageBlockStripePricingV1User />;
    }
  };
  return (
    <Grid item xs={12} sm={6} md={3}>
      {renderCreditUsageBlock()}
    </Grid>
  );
};

export default CreditUsageBlock;

import CLEF_PATH from '@/constants/path';
import { Button, Typography } from '@clef/client-library';
import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import Check from '@material-ui/icons/Check';
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions';
import { allPlansFeatures } from './constants';
import PlanFeaturesTable from './PlanFeaturesTable';
import ArrowBack from '@material-ui/icons/ArrowBack';
import useGoBack from '@/hooks/useGoBack';

export type PlanPageV2Props = {};

const useStyles = makeStyles(theme => ({
  allPlansFeaturesBox: {
    backgroundColor: theme.palette.greyModern[25],
    borderRadius: 20,
    padding: theme.spacing(6),
    fontSize: 14,
  },
}));

const PlanPageV2: React.FC<PlanPageV2Props> = () => {
  const styles = useStyles();

  const goBack = useGoBack(CLEF_PATH.organizationSettings);

  return (
    <Box paddingX={21} paddingY={7} marginX="auto" maxWidth={1400}>
      <Box marginBottom={2} marginLeft={-5}>
        <Button
          id="back-to-plan-and-usage"
          onClick={() => goBack()}
          startIcon={<ArrowBack />}
          color="primary"
        >
          {t('Back')}
        </Button>
      </Box>
      <Box id="page-title" marginBottom={6}>
        <Typography variant="h1_semibold">{t('Choose a plan')}</Typography>
      </Box>

      <PlanFeaturesTable />

      <Box marginTop={8}>
        <Typography variant="h3_semibold">{t('All Plans include:')}</Typography>
      </Box>
      <Box className={styles.allPlansFeaturesBox} marginTop={2} display="flex">
        {allPlansFeatures.map(featureSet => (
          <Box key={featureSet.key} flex={1}>
            <Box marginBottom={3}>
              <Typography variant="body_bold">{featureSet.name}</Typography>
            </Box>
            {featureSet.features.map((feature, index) => (
              <Box
                display="flex"
                alignItems="center"
                key={`feature-${featureSet.name}-${index}`}
                marginTop={2}
              >
                <Check />
                <Box marginLeft={1}>{feature}</Box>
              </Box>
            ))}
          </Box>
        ))}
      </Box>

      <Box marginBottom={10} marginTop={12}>
        <FrequentlyAskedQuestions pricingV2 />
      </Box>
    </Box>
  );
};

export default PlanPageV2;

import React from 'react';
import { Menu, Typography, MenuItem, makeStyles } from '@material-ui/core';

interface MoreIconMenuProps {
  anchorEl: HTMLElement | null;
  hideInvite: boolean;
  onClose: () => void;
  onBlur: () => void;
  onItemClick: (e: React.MouseEvent<HTMLElement>, key: MoreIconMenuItemKeys) => void;
}

export enum MoreIconMenuItemKeys {
  rename = 'menu-item-rename',
  invite = 'menu-item-invite',
  delete = 'menu-item-delete',
}

enum MoreIconMenuItemMessages {
  rename = 'Rename',
  invite = 'Invite teammates',
  delete = 'Delete',
}

const moreIconMenuStyles = makeStyles(() => ({
  deleteText: {
    fontWeight: 'bold',
  },
}));

export const MoreIconMenu: React.FC<MoreIconMenuProps> = props => {
  const styles = moreIconMenuStyles();
  const { anchorEl, hideInvite, onClose, onBlur, onItemClick } = props;
  return (
    <Menu
      id="more-icon-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      onBlur={onBlur}
    >
      <MenuItem
        key={MoreIconMenuItemKeys.rename}
        onClick={e => onItemClick(e, MoreIconMenuItemKeys.rename)}
      >
        {t(MoreIconMenuItemMessages.rename)}
      </MenuItem>
      {hideInvite ? null : (
        <MenuItem
          key={MoreIconMenuItemKeys.invite}
          onClick={e => onItemClick(e, MoreIconMenuItemKeys.invite)}
        >
          {t(MoreIconMenuItemMessages.invite)}
        </MenuItem>
      )}
      <MenuItem
        key={MoreIconMenuItemKeys.delete}
        onClick={e => onItemClick(e, MoreIconMenuItemKeys.delete)}
      >
        <Typography color="secondary" className={styles.deleteText}>
          {t(MoreIconMenuItemMessages.delete)}
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default MoreIconMenu;

import React from 'react';

import {
  DeployModelDialog,
  DeployModelDialogProps as DeployModelDialogV1Props,
} from './DeployModelDialogV1';
import {
  DeployModelDialogV2,
  DeployModelDialogProps as DeployModelDialogV2Props,
} from './DeployModelDialogV2';

const Dialog: React.FC<DeployModelDialogV1Props & DeployModelDialogV2Props> = props => {
  if (!props.open) {
    return null;
  }

  return props.isLegacy ? <DeployModelDialog {...props} /> : <DeployModelDialogV2 {...props} />;
};

export default Dialog;

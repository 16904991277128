import zero_auth_api from '@/api/zero_auth_api';
import fetchHookFactory from './fetchHookFactory';

export const [useZeroAuthLoginApi] = fetchHookFactory(async () => {
  return zero_auth_api.login();
}, 'cache');

export const [useZeroAuthProjectsApi] = fetchHookFactory(async () => {
  return zero_auth_api.getProjects();
}, 'cache');

export const [useZeroAuthProjectApi, refreshZeroAuthProjectApi] = fetchHookFactory(
  async (projectId: number) => {
    return zero_auth_api.getProject(projectId);
  },
  'cache',
);

import { combineReducers, Reducer, AnyAction } from '@reduxjs/toolkit';
import login from './newLoginState';
import user from './user_store';
import metadata from './metadata_store';
import enabledFeatures from './feature_toggle_store';
// Newer reducers
import uploadState from './uploadState';
import projectState from './projectState';
import { LoginState } from './types';

const appReducer = combineReducers({
  login: login as Reducer<LoginState, AnyAction & { payload: any }>,
  user,
  metadata,
  enabledFeatures,
  uploadState,
  project: projectState,
});

/* Reset redux state when user logs out
 * https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
 */
const rootReducer: typeof appReducer = function (state, action) {
  // back door for unit test to directly update state.
  // SHOULD NOT BE USED IN PRODUCTION CODE!
  if (action.type === 'UNSAFE/REPLACE_STATE') {
    state = action.payload;
  }

  return appReducer(state, action);
};

export default rootReducer;

import React from 'react';
import { Tooltip, Typography, TooltipProps } from '@material-ui/core';

export interface TypographyWithTooltipProps {
  title?: string | undefined;
  typographyClassName: string | undefined;
  placement?: TooltipProps['placement'];
}

const TypographyWithTooltip: React.FC<TypographyWithTooltipProps> = ({
  title,
  typographyClassName,
  placement = 'top',
}) => {
  return (
    <Tooltip title={title ?? ''} placement={placement}>
      <Typography className={typographyClassName} noWrap>
        {title}
      </Typography>
    </Tooltip>
  );
};

export default TypographyWithTooltip;

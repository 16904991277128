import React, { useState, useRef } from 'react';
import {
  ButtonGroup,
  MenuList,
  MenuItem,
  Popper,
  Paper,
  Grow,
  Grid,
  ClickAwayListener,
} from '@material-ui/core';
import { Button } from '../../../..';
import KeyboardArrowDownRounded from '@material-ui/icons/KeyboardArrowDownRounded';
import ZoomInRounded from '@material-ui/icons/ZoomInRounded';
import ZoomOutRounded from '@material-ui/icons/ZoomOutRounded';
import { useMediaInteractiveCanvasStyles } from '../styles';
import { calcZoomScaleRange, getDisplayZoomValue, StepZoomRatio, ZoomScale } from '../utils';
import classNames from 'classnames';

export interface ZoomFloaterProps {
  zoomScale: ZoomScale;
  setZoomScale: (newValue: ZoomScale) => void;
  fitZoomScale: number;
  zoomOption?: boolean | 'topRight' | 'topLeft';
}

const ZoomFloater: React.FC<ZoomFloaterProps> = ({
  zoomScale,
  setZoomScale,
  fitZoomScale,
  zoomOption,
}) => {
  if (zoomOption === true) {
    zoomOption = 'topRight';
  }
  const styles = useMediaInteractiveCanvasStyles();

  const [openZoomOptions, setOpenZoomOptions] = useState(false);
  const zoomOptionAnchorRef = useRef<HTMLButtonElement>(null);
  const { minZoomScale, maxZoomScale } = calcZoomScaleRange(fitZoomScale);
  const zoomScaleNumber = zoomScale === 'fit' ? fitZoomScale : zoomScale;

  const autoFitText = t('{{fitZoomScale}} (Fit)', {
    fitZoomScale: getDisplayZoomValue(fitZoomScale),
  });
  const displayZoomValue = zoomScale === 'fit' ? autoFitText : getDisplayZoomValue(zoomScaleNumber);

  return (
    <Grid
      container
      wrap="wrap"
      justifyContent="flex-end"
      className={classNames(styles.zoomAction, zoomOption)}
      style={openZoomOptions ? { opacity: 1 } : {}}
    >
      <ButtonGroup classes={{ root: styles.buttonGroup }}>
        <Button
          id="zoom-options"
          ref={zoomOptionAnchorRef}
          size="small"
          endIcon={<KeyboardArrowDownRounded fontSize="small" />}
          variant="contained"
          onClick={() => {
            setOpenZoomOptions(true);
          }}
          aria-controls={openZoomOptions ? 'split-button-menu' : undefined}
          aria-expanded={openZoomOptions ? 'true' : undefined}
        >
          {displayZoomValue}
        </Button>
        <Button
          id="zoom-in"
          size="small"
          variant="contained"
          onClick={() => setZoomScale(Math.min(zoomScaleNumber * StepZoomRatio, maxZoomScale))}
          disabled={zoomScaleNumber >= maxZoomScale}
        >
          <ZoomInRounded fontSize="small" />
        </Button>
        <Button
          id="zoom-out"
          size="small"
          variant="contained"
          onClick={() => setZoomScale(Math.max(zoomScaleNumber / StepZoomRatio, minZoomScale))}
          disabled={zoomScaleNumber <= minZoomScale}
        >
          <ZoomOutRounded fontSize="small" />
        </Button>
      </ButtonGroup>
      <Popper
        open={openZoomOptions}
        anchorEl={zoomOptionAnchorRef.current}
        role={undefined}
        transition
        className={styles.zoomOptionsPopover}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener
                onClickAway={() => {
                  setOpenZoomOptions(false);
                }}
              >
                <MenuList autoFocusItem>
                  <MenuItem
                    key="AutoFit"
                    onClick={() => {
                      setZoomScale('fit');
                      setOpenZoomOptions(false);
                    }}
                  >
                    {autoFitText}
                  </MenuItem>
                  <MenuItem
                    key="OriginalSize"
                    onClick={() => {
                      setZoomScale(1);
                      setOpenZoomOptions(false);
                    }}
                  >
                    100%
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
};

export default ZoomFloater;

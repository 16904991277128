import React from 'react';
import {
  Box,
  BoxProps,
  Dialog,
  DialogProps,
  IconButtonProps,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import CloseIcon from '@material-ui/icons/Close';

const DEFAULT_PADDING_X = 10;
const DEFAULT_PADDING_Y = 6;

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    marginBottom: theme.spacing(6),
    color: theme.palette.greyModern[900],
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
}));

export interface BaseDialogProps extends DialogProps {
  title?: string;
  onClose: () => void | Promise<void>;
  containerProps?: BoxProps;
  closeButtonProps?: IconButtonProps;
}

export const BaseDialog: React.FC<BaseDialogProps> = ({
  open,
  onClose,
  children,
  title,
  containerProps,
  closeButtonProps,
  ...rest
}) => {
  const styles = useStyles();

  return (
    <Dialog open={open} onClose={onClose} {...rest}>
      <Box
        paddingX={containerProps?.paddingX ?? DEFAULT_PADDING_X}
        paddingY={containerProps?.paddingY ?? DEFAULT_PADDING_Y}
        {...containerProps}
      >
        {title && (
          <Typography variant="h2" className={styles.title}>
            {title}
          </Typography>
        )}

        <IconButton
          id="close-dialog-button"
          onClick={onClose}
          className={styles.closeButton}
          {...closeButtonProps}
        >
          <CloseIcon />
        </IconButton>

        {children}
      </Box>
    </Dialog>
  );
};

export default BaseDialog;

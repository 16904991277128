import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useTypedSelector } from '../hooks/useTypedSelector';

let stripePromise: Promise<Stripe | null>;

export const useStripePromise = () => {
  const stripePublicKey = useTypedSelector(state => state.login?.user?.stripePublicKey);
  if (!stripePromise && stripePublicKey) {
    stripePromise = loadStripe(stripePublicKey);
  }
  return stripePromise;
};

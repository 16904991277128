import { BaseAPI } from './base_api';
import {
  User,
  ApiResponse,
  UserWithOrg,
  PendingUserWithOrg,
  Account,
  UTMParams,
  OrganizationSegment,
  ApiKey,
} from '@clef/shared/types';

class AccountAPI extends BaseAPI {
  getProfile(): Promise<Account> {
    return this.get('profile', {}, true);
  }

  getOrgs(): Promise<{
    activeUsers: UserWithOrg[];
    pendingUsers: PendingUserWithOrg[];
  }> {
    return this.get('user_orgs', {}, true);
  }

  createOrg(
    name: string,
    segment?: OrganizationSegment,
    utmParams?: UTMParams,
  ): Promise<ApiResponse<User>> {
    return this.postJSON('create_org', { name, segment, utmParams });
  }

  joinOrg(token: string) {
    return this.postJSON('join_org', { token });
  }

  loginOrgUser(orgId: number, userId: string): Promise<ApiResponse<User>> {
    return this.postJSON('use_user', {
      orgId,
      userId,
    });
  }

  async getKeyById(id: number): Promise<ApiKey> {
    return this.get('get_api_key_by_id', { id, unmask: true }, true);
  }

  async createApiKey(name: string): Promise<ApiResponse<ApiKey>> {
    return this.postJSON('create_api_key', { name });
  }

  async updateApiKey(params: Pick<ApiKey, 'id' | 'name' | 'expirationDate' | 'status'>) {
    return this.postJSON('update_api_key', params);
  }

  async deleteApiKey(id: number) {
    return this.delete(`delete_api_key?id=${id}`);
  }
}

export const accountApiWithAuth = new AccountAPI('account');

export default new AccountAPI('account', '');

import { withStyles, WithStyles } from '@material-ui/styles';
import styles from './styles';
import React, { useState, useEffect } from 'react';
import { Paper, Chip } from '@material-ui/core';
import { noop } from '../../utils';
import { MetadataType } from '@clef/shared/types';
import ChipInput from 'material-ui-chip-input';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';

interface ChipsContainerProps extends WithStyles<typeof styles> {
  values: string[] | null;
  disabledValues?: string[] | null;
  maxHeight?: number;
  maxWidth?: number;
  onDeleteFunction?: (value: string, index: number) => void;
  onAddFunction?: (value: string) => void;
  readOnly?: boolean;
  disableUnderline?: boolean;
  autoFocus?: boolean;
  type?: MetadataType;
}

const ChipsContainer: React.FunctionComponent<ChipsContainerProps> = ({
  classes,
  values,
  disabledValues,
  maxHeight,
  maxWidth,
  onDeleteFunction,
  readOnly = true,
  type,
  disableUnderline = false,
  autoFocus = false,
  onAddFunction = noop,
}) => {
  if (!values) {
    values = [];
  }
  const [errorText, setErrorText] = useState('');
  const [disabledValuesSet, setDisabledValuesSet] = useState(
    disabledValues ? new Set(disabledValues) : new Set(),
  );
  useEffect(() => {
    setDisabledValuesSet(new Set(disabledValues));
  }, [disabledValues]);
  const inputRootStyle = makeStyles({
    chipInputStyle: {
      minWidth: '50px',
      width: '50px',
    },
  });
  const chipInputStyle = inputRootStyle();
  return (
    <div>
      <Paper
        style={{
          maxHeight: maxHeight ? maxHeight : 150,
          maxWidth: maxWidth ? maxWidth : 300,
        }}
        className={classes.chipsContainerStyle}
      >
        <ChipInput
          className="cy-metadata-chips-container"
          classes={{
            inputRoot: chipInputStyle.chipInputStyle,
          }}
          InputProps={autoFocus ? { autoFocus: true } : {}}
          value={values}
          onAdd={chip => {
            onAddFunction(chip);
            setErrorText('');
          }}
          onBeforeAdd={chip => {
            if (chip.length <= 0 || chip.length > 256) {
              setErrorText('value length must be within 1 - 256');
              return false;
            }
            if (type === MetadataType.Number && isNaN(chip)) {
              setErrorText('value must be number');
              return false;
            }
            return true;
          }}
          chipRenderer={({ value }, key) => (
            <Chip
              key={key}
              onDelete={
                readOnly || disabledValuesSet.has(value) || !onDeleteFunction
                  ? undefined
                  : () => onDeleteFunction(value, key)
              }
              className={cx(
                classes ? classes.chipsContainerValueSytle : undefined,
                'cy-metadata-chip-element',
              )}
              label={value}
              color={readOnly || disabledValuesSet.has(value) ? 'default' : 'primary'}
            />
          )}
          disabled={readOnly}
          disableUnderline={disableUnderline || readOnly}
          helperText={errorText}
          error={errorText.length === 0 ? false : true}
        />
      </Paper>
    </div>
  );
};

export default withStyles(styles)(ChipsContainer);

import React from 'react';

export type ModelIconProps = {
  size?: number;
};

const ModelV2Icon: React.FC<ModelIconProps> = ({ size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" stroke="none">
        <path d="M12.4036 15.0765L19.6235 7.77662L20.0968 8.24594L12.8776 15.5458L12.4036 15.0765ZM7.54102 1.36872L8.20567 1.31738L8.72433 8.03061L8.05967 8.08195L7.54102 1.36872Z" />
        <path d="M1.3999 9.07252L8.34046 7.74121L8.46579 8.39653L1.52657 9.72718L1.3999 9.07252ZM12.3624 15.4651L12.9737 15.1991L16.1643 22.535L15.553 22.8016L12.3624 15.4651Z" />
        <path d="M5.5293 20.0112L12.4579 15.0312L12.8472 15.5726L5.91796 20.5525L5.5293 20.0112Z" />
        <path d="M2.49854 14.999L3.08653 14.685L6.03848 20.2123L5.45049 20.5263L2.49854 14.999ZM8.09978 8.22178L8.67444 7.88379L12.961 15.185L12.3864 15.5223L8.09978 8.22178ZM19.5329 8.09645L20.1929 8.00112L21.5329 17.3056L20.8736 17.4003L19.5329 8.09645Z" />
        <path d="M5.7334 8.06702C5.7334 8.77425 6.01434 9.45252 6.51443 9.95261C7.01452 10.4527 7.69279 10.7336 8.40002 10.7336C9.10726 10.7336 9.78552 10.4527 10.2856 9.95261C10.7857 9.45252 11.0666 8.77425 11.0666 8.06702C11.0666 7.35978 10.7857 6.68152 10.2856 6.18143C9.78552 5.68134 9.10726 5.40039 8.40002 5.40039C7.69279 5.40039 7.01452 5.68134 6.51443 6.18143C6.01434 6.68152 5.7334 7.35978 5.7334 8.06702Z" />
        <path d="M15.8662 8.06635C15.8662 9.1272 16.2876 10.1446 17.0378 10.8947C17.7879 11.6449 18.8053 12.0663 19.8661 12.0663C20.927 12.0663 21.9444 11.6449 22.6945 10.8947C23.4447 10.1446 23.8661 9.1272 23.8661 8.06635C23.8661 7.0055 23.4447 5.9881 22.6945 5.23796C21.9444 4.48783 20.927 4.06641 19.8661 4.06641C18.8053 4.06641 17.7879 4.48783 17.0378 5.23796C16.2876 5.9881 15.8662 7.0055 15.8662 8.06635Z" />
        <path d="M3.06641 20.3326C3.06641 20.6828 3.13538 21.0296 3.26939 21.3531C3.4034 21.6766 3.59982 21.9706 3.84744 22.2182C4.09506 22.4659 4.38903 22.6623 4.71256 22.7963C5.03609 22.9303 5.38285 22.9993 5.73303 22.9993C6.08322 22.9993 6.42998 22.9303 6.75351 22.7963C7.07704 22.6623 7.371 22.4659 7.61862 22.2182C7.86624 21.9706 8.06266 21.6766 8.19667 21.3531C8.33068 21.0296 8.39966 20.6828 8.39966 20.3326C8.39966 19.9825 8.33068 19.6357 8.19667 19.3122C8.06266 18.9886 7.86624 18.6947 7.61862 18.4471C7.371 18.1994 7.07704 18.003 6.75351 17.869C6.42998 17.735 6.08322 17.666 5.73303 17.666C5.38285 17.666 5.03609 17.735 4.71256 17.869C4.38903 18.003 4.09506 18.1994 3.84744 18.4471C3.59982 18.6947 3.4034 18.9886 3.26939 19.3122C3.13538 19.6357 3.06641 19.9825 3.06641 20.3326Z" />
        <path d="M14.5332 22.6665C14.5332 22.8416 14.5676 23.015 14.6346 23.1768C14.7016 23.3386 14.7998 23.4856 14.9237 23.6095C15.0475 23.7333 15.1945 23.8315 15.3562 23.8985C15.518 23.9655 15.6914 24 15.8665 24C16.0416 24 16.215 23.9655 16.3768 23.8985C16.5386 23.8315 16.6856 23.7333 16.8094 23.6095C16.9332 23.4856 17.0314 23.3386 17.0984 23.1768C17.1654 23.015 17.1999 22.8416 17.1998 22.6665C17.1999 22.4914 17.1654 22.318 17.0984 22.1562C17.0314 21.9944 16.9332 21.8474 16.8094 21.7236C16.6856 21.5998 16.5386 21.5015 16.3768 21.4345C16.215 21.3675 16.0416 21.333 15.8665 21.333C15.6914 21.333 15.518 21.3675 15.3562 21.4345C15.1945 21.5015 15.0475 21.5998 14.9237 21.7236C14.7998 21.8474 14.7016 21.9944 14.6346 22.1562C14.5676 22.318 14.5332 22.4914 14.5332 22.6665Z" />
        <path d="M1.4668 14.8003C1.46677 14.9754 1.50124 15.1488 1.56823 15.3106C1.63522 15.4724 1.73343 15.6194 1.85724 15.7432C1.98106 15.8671 2.12805 15.9653 2.28983 16.0323C2.4516 16.0993 2.625 16.1338 2.80011 16.1338C2.97522 16.1338 3.14862 16.0993 3.31039 16.0323C3.47217 15.9653 3.61916 15.8671 3.74298 15.7432C3.86679 15.6194 3.965 15.4724 4.03199 15.3106C4.09898 15.1488 4.13345 14.9754 4.13342 14.8003C4.13345 14.6252 4.09898 14.4518 4.03199 14.29C3.965 14.1282 3.86679 13.9812 3.74298 13.8574C3.61916 13.7336 3.47217 13.6353 3.31039 13.5683C3.14862 13.5013 2.97522 13.4668 2.80011 13.4668C2.625 13.4668 2.4516 13.5013 2.28983 13.5683C2.12805 13.6353 1.98106 13.7336 1.85724 13.8574C1.73343 13.9812 1.63522 14.1282 1.56823 14.29C1.50124 14.4518 1.46677 14.6252 1.4668 14.8003Z" />
        <path d="M10.6665 15.333C10.6665 15.8634 10.8772 16.3721 11.2523 16.7472C11.6273 17.1222 12.136 17.3329 12.6665 17.3329C13.1969 17.3329 13.7056 17.1222 14.0807 16.7472C14.4557 16.3721 14.6664 15.8634 14.6664 15.333C14.6664 14.8026 14.4557 14.2939 14.0807 13.9188C13.7056 13.5437 13.1969 13.333 12.6665 13.333C12.136 13.333 11.6273 13.5437 11.2523 13.9188C10.8772 14.2939 10.6665 14.8026 10.6665 15.333Z" />
        <path d="M0.133301 9.39992C0.133274 9.57503 0.167741 9.74843 0.234734 9.91022C0.301728 10.072 0.399935 10.219 0.523747 10.3429C0.647559 10.4667 0.794551 10.5649 0.95633 10.6319C1.11811 10.6989 1.2915 10.7334 1.46661 10.7334C1.64172 10.7334 1.81512 10.6989 1.9769 10.6319C2.13868 10.5649 2.28567 10.4667 2.40948 10.3429C2.53329 10.219 2.6315 10.072 2.69849 9.91022C2.76549 9.74843 2.79995 9.57503 2.79993 9.39992C2.79995 9.22481 2.76549 9.05141 2.69849 8.88962C2.6315 8.72783 2.53329 8.58083 2.40948 8.457C2.28567 8.33317 2.13868 8.23494 1.9769 8.16792C1.81512 8.1009 1.64172 8.06641 1.46661 8.06641C1.2915 8.06641 1.11811 8.1009 0.95633 8.16792C0.794551 8.23494 0.647559 8.33317 0.523747 8.457C0.399935 8.58083 0.301728 8.72783 0.234734 8.88962C0.167741 9.05141 0.133274 9.22481 0.133301 9.39992Z" />
        <path d="M6.5332 1.33352C6.53318 1.50863 6.56764 1.68203 6.63464 1.84382C6.70163 2.00561 6.79984 2.15261 6.92365 2.27644C7.04746 2.40027 7.19445 2.4985 7.35623 2.56552C7.51801 2.63254 7.69141 2.66703 7.86652 2.66703C8.04163 2.66703 8.21502 2.63254 8.3768 2.56552C8.53858 2.4985 8.68557 2.40027 8.80938 2.27644C8.9332 2.15261 9.0314 2.00561 9.0984 1.84382C9.16539 1.68203 9.19986 1.50863 9.19983 1.33352C9.19986 1.15841 9.16539 0.985006 9.0984 0.823218C9.0314 0.661429 8.9332 0.514422 8.80938 0.39059C8.68557 0.266759 8.53858 0.16853 8.3768 0.101512C8.21502 0.0344938 8.04163 0 7.86652 0C7.69141 0 7.51801 0.0344938 7.35623 0.101512C7.19445 0.16853 7.04746 0.266759 6.92365 0.39059C6.79984 0.514422 6.70163 0.661429 6.63464 0.823218C6.56764 0.985006 6.53318 1.15841 6.5332 1.33352Z" />
        <path d="M19.1997 17.333C19.1997 17.8634 19.4104 18.3721 19.7855 18.7472C20.1606 19.1222 20.6693 19.3329 21.1997 19.3329C21.7301 19.3329 22.2388 19.1222 22.6139 18.7472C22.9889 18.3721 23.1996 17.8634 23.1996 17.333C23.1996 16.8026 22.9889 16.2939 22.6139 15.9188C22.2388 15.5437 21.7301 15.333 21.1997 15.333C20.6693 15.333 20.1606 15.5437 19.7855 15.9188C19.4104 16.2939 19.1997 16.8026 19.1997 17.333Z" />
      </g>
    </svg>
  );
};

export default ModelV2Icon;

import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import cx from 'classnames';
import { useHistory } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useStyles } from '../signup/styles';
import { AuthPageLayout } from '../../components/Layout/AuthPageLayout';
import EmailDeliveredPng from '../../images/email_delivered.png';
import { LandingLensLogo } from '../../components/Auth/Common';

export const ForgetPasswordEmailDelivered: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <AuthPageLayout metaTitle={t('Email Delivered')}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minWidth="100vw"
        minHeight="100vh"
        position="relative"
      >
        <LandingLensLogo />

        <IconButton
          id="go-back"
          onClick={() => {
            history.goBack();
          }}
          className={styles.backButton}
        >
          <ArrowBackIcon />
        </IconButton>

        <Typography
          variant="h1"
          className={cx(styles.invitationDeliveredText, styles.marginBottom3)}
        >
          {t('Check your inbox to reset your password in LandingLens.')}
        </Typography>

        <img src={EmailDeliveredPng} className={styles.emailDeliveredPng} />
      </Box>
    </AuthPageLayout>
  );
};

import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import {
  AnnotationType,
  EdgeMedia,
  EdgeMediaDetails,
  EdgeMediaStatusType,
  LabelType,
} from '@clef/shared/types';
import { useDefectSelectorWithArchived } from '../../../store/defectState/actions';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { createPixelatedCanvas, MediaViewer } from '@clef/client-library';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useDeviceMonitorStyles } from './deviceMonitorStyles';
import {
  getEdgeBoxAnnotations,
  getJudgementAnnotation,
  getAnnotationSrcData,
} from '../../../utils';
import { EdgeMediaStatusProps } from './constants';
import { Chip } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import ConfidenceChipDisplay from './ConfidenceChipDisplay';
import { useDeviceMonitorState } from './deviceMonitorState';
import { endpointQueryKeys, useEdgeMediaDetailsQuery } from '@/serverStore/endpoints';
import { useQueryClient } from '@tanstack/react-query';

export interface EdgeMediaContainerProps {
  media: EdgeMedia;
  onSelected: (selected: boolean, selectedMediaDetails: EdgeMediaDetails | undefined) => void;
  selected: boolean;
  showLabels: boolean;
  showConfidenceScore: boolean;
  showDefectName: boolean;
  showImageClassification: boolean;
  showHumanJudgement: boolean;
  onInfoClick: () => void;
  mediaIndex?: number;
  recentDialogMediaIndex?: number;
}

const EdgeMediaContainer: React.FC<EdgeMediaContainerProps> = ({
  media,
  onSelected,
  selected,
  showLabels,
  showConfidenceScore,
  showImageClassification,
  showDefectName,
  showHumanJudgement,
  onInfoClick,
  recentDialogMediaIndex,
  mediaIndex,
}) => {
  const styles = useDeviceMonitorStyles();
  const { id: projectId, labelType } = useGetSelectedProjectQuery().data ?? {};
  const { data: mediaDetails, isLoading: isMediaDetailsLoading } = useEdgeMediaDetailsQuery(
    media.id,
  );
  const { state, dispatch } = useDeviceMonitorState();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (
      !state.displayOptions.showHumanJudgementCheckbox &&
      mediaDetails?.human_judgement?.judgement
    ) {
      dispatch(draft => {
        draft.displayOptions.showHumanJudgementCheckbox = true;
      });
    }
  }, [mediaDetails, dispatch, state.displayOptions.showHumanJudgementCheckbox]);

  useEffect(() => {
    // fetch updated media details for which recently judgement updated
    if (
      recentDialogMediaIndex &&
      mediaIndex &&
      recentDialogMediaIndex === mediaIndex &&
      projectId
    ) {
      queryClient.invalidateQueries(endpointQueryKeys.edgeMediaDetails(projectId, media.id));
    }
  }, [recentDialogMediaIndex, media.id, projectId, mediaIndex]);

  const [imgSrc, setImgSrc] = useState<string | undefined>();

  const defects = useDefectSelectorWithArchived();

  useEffect(() => {
    if (isMediaDetailsLoading) {
      return;
    } else if (mediaDetails?.url) {
      setImgSrc(mediaDetails?.url);
    } else {
      const mediaWidth = media.width;
      const mediaHeight = media.height;
      const offscreen = createPixelatedCanvas(
        { width: mediaWidth, height: mediaHeight },
        () => true,
        '#2F4F4F',
        0.6,
      );
      offscreen.convertToBlob().then(value => setImgSrc(URL.createObjectURL(value)));
    }
  }, [media, mediaDetails?.url, isMediaDetailsLoading]);

  const boxAnnotations = showLabels
    ? getEdgeBoxAnnotations(
        mediaDetails,
        AnnotationType.bndbox,
        defects,
        showDefectName,
        showConfidenceScore,
      )
    : undefined;

  const segmentationAnnotations = showLabels
    ? getEdgeBoxAnnotations(
        mediaDetails,
        AnnotationType.segmentation,
        defects,
        showDefectName,
        showConfidenceScore,
      )
    : undefined;

  const annotationSrcData = getAnnotationSrcData(mediaDetails);

  const judgement = getJudgementAnnotation(mediaDetails?.human_judgement?.judgement!);
  const judgementColor =
    judgement > -1 && showHumanJudgement ? (judgement === 0 ? green[400] : red[400]) : '';

  const mediaStatus = mediaDetails?.dm_media_id
    ? EdgeMediaStatusProps[EdgeMediaStatusType.InSync]
    : EdgeMediaStatusProps[EdgeMediaStatusType.Raw];

  return (
    <>
      <div
        className={cx(styles.hoverClickContainer, selected && styles.hoverIconContainerSelected)}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onInfoClick();
        }}
        data-testid={selected ? 'edge-media-selected' : 'edge-media-unselected'}
      >
        <CheckCircleIcon
          onClick={e => {
            e.stopPropagation();
            onSelected(!selected, mediaDetails);
          }}
          className={cx(styles.checkIcon)}
          data-testid="edge-media-container-check-icon"
        />
      </div>

      <Chip
        size="small"
        label={mediaStatus.text}
        className={cx(styles.chipStyle, styles.tagRightCorner)}
        style={{ backgroundColor: mediaStatus.color + 'E6' }}
      />
      {/* for OD projects, show chip when showImageClassification is true */}
      {/* for seg & cls projects, show chip whenshowConfidenceScore or showDefectName is true */}
      {((labelType !== LabelType.BoundingBox && (showConfidenceScore || showDefectName)) ||
        (labelType === LabelType.BoundingBox && showImageClassification)) && (
        <ConfidenceChipDisplay
          mediaDetails={mediaDetails}
          // for OD projects, show confidence score and defect name when chip shows
          showConfidenceScore={showConfidenceScore || labelType === LabelType.BoundingBox}
          showDefectName={showDefectName || labelType === LabelType.BoundingBox}
        />
      )}

      <MediaViewer
        judgementColor={judgementColor}
        selected={selected}
        imgSrc={imgSrc}
        properties={{ width: media.width, height: media.height }}
        boxAnnotations={boxAnnotations}
        segmentationAnnotations={segmentationAnnotations}
        annotationSrcData={annotationSrcData}
      />
    </>
  );
};

export default EdgeMediaContainer;

import { DialogProps } from '@material-ui/core';
import React from 'react';
import { useIsEnterpriseUser, useIsPricingV2User } from '../../../hooks/useSubscriptions';
import { ReachLimitModelDialogV1 } from './ReachLimitModelDialogV1';
import { ReachLimitModelDialogV2 } from './ReachLimitModelDialogV2';

interface ReachLimitModelDialogProps extends DialogProps {
  projectId: number;
  datasetId: number;
  onClose?: (open: boolean) => void;
  onPredicting?: boolean;
  onPredictingMediaCount?: number;
}

export const ReachLimitModelDialog: React.FC<ReachLimitModelDialogProps> = props => {
  const isPricingV2User = useIsPricingV2User();
  const { open, projectId, datasetId, onClose, onPredicting, onPredictingMediaCount } = props;
  const isEnterpriseUser = useIsEnterpriseUser();
  // This condition could be removed after migration
  if (isEnterpriseUser) {
    return null;
  }
  // This condition could be removed after migration
  if (isPricingV2User) {
    return (
      <ReachLimitModelDialogV2
        open={open}
        projectId={projectId}
        datasetId={datasetId}
        onClose={onClose}
        onPredicting={onPredicting}
        onPredictingMediaCount={onPredictingMediaCount}
      />
    );
  } else {
    return (
      <ReachLimitModelDialogV1
        open={open}
        projectId={projectId}
        datasetId={datasetId}
        onClose={onClose}
        onPredicting={onPredicting}
        onPredictingMediaCount={onPredictingMediaCount}
      />
    );
  }
};

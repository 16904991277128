import React from 'react';
import { Dialog, Stepper, Step, StepLabel, LinearProgress } from '@material-ui/core';
import { MediaStatusType, SelectMediaOption } from '@clef/shared/types';
import { Alert } from '@material-ui/lab';
import { useImmer } from 'use-immer';

import { defaultState, AutoSplitStateContext } from './state';
import { useAutoSplitStyles } from './styles';

import Step1MediaSelection from './Step1MediaSelection';
import Step2SplitDistribution from './Step2SplitDistribution';
import Step3SplitDone from './Step3SplitDone';
import { supportedAutoSplit } from './utils';
import { useGetDatasetFilterOptionsQuery } from '@/serverStore/dataset';

interface AutoSplitDialogProps {
  onClose: () => void;
  step?: 0 | 1 | 2;
}

const AutoSplitSteps = [t('Select images'), t('Set split distribution'), t('Done')];

const AutoSplitDialog: React.FC<AutoSplitDialogProps> = ({ onClose, step }) => {
  const styles = useAutoSplitStyles();

  const [state, dispatch] = useImmer({ ...defaultState, currentStep: step ?? 0 });
  const { includeAlreadySplitMedia, currentStep, isMakingPostRequest } = state;

  const { data: allFilters } = useGetDatasetFilterOptionsQuery();

  // 'Split' is in columnFilterMap, the project has split in datasetContent's splitSet column
  // 'split' is in fieldFilterMap, the project has split metadata
  // Always prefer to use 'Split' column in case user manually creates 'split' or 'Split' metadata
  const splitFilterOption = allFilters
    ? allFilters.find(value => value.filterName === 'Split' && value.filterType === 'column') ||
      allFilters.find(value => value.filterName === 'split')
    : undefined;
  const preDefinedChoices = splitFilterOption?.value ? Object.keys(splitFilterOption.value) : [];
  const customizedSplitOptions = preDefinedChoices.filter(
    option => !supportedAutoSplit.includes(option),
  );

  const mediaStatusFilter = [MediaStatusType.Approved];

  // includeAlreadySplitMedia = "Include assigned train/dev/test media to reassigned"
  const splitFilter = includeAlreadySplitMedia ? customizedSplitOptions : preDefinedChoices;
  const splitFilterByIds: number[] =
    splitFilterOption?.filterType === 'column'
      ? splitFilter.map(s => splitFilterOption.value![s] as number)
      : [];

  const selectMediaOption: SelectMediaOption | undefined = splitFilterOption
    ? {
        fieldFilterMap:
          splitFilterOption.filterType === 'field' && splitFilter?.length
            ? { [splitFilterOption.fieldId!]: { NOT_CONTAIN_ANY: splitFilter } }
            : {},
        columnFilterMap: {
          datasetContent: {
            mediaStatus: { CONTAINS_ANY: mediaStatusFilter },
            ...(splitFilterOption.filterType === 'column' &&
              splitFilterByIds.length && { splitSet: { NOT_CONTAIN_ANY: splitFilterByIds } }),
          },
        },
        selectedMedia: [],
        unselectedMedia: [],
        isUnselectMode: true,
      }
    : undefined;
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open
      onClose={onClose}
      classes={{ paper: styles.dialogScrollContainer }}
    >
      <AutoSplitStateContext.Provider value={{ state, dispatch }}>
        {isMakingPostRequest && (
          <div className={styles.postRequestMask}>
            <LinearProgress />
          </div>
        )}
        <div className={styles.dialogContainer}>
          {customizedSplitOptions && !!customizedSplitOptions.length && (
            <Alert className={styles.topAlert} severity="warning">
              {t(
                'Auto split only supports assigning images to predefined {{predefinedSet}} sets. If you want to use your customized {{customizedSet}} set for training, please assign media manually.',
                {
                  predefinedSet: <strong>{t('train / dev / test')}</strong>,
                  customizedSet: <strong>{customizedSplitOptions.join(' / ')}</strong>,
                },
              )}
            </Alert>
          )}
          <Stepper activeStep={currentStep}>
            {AutoSplitSteps.map(title => {
              return (
                <Step key={title}>
                  <StepLabel>{title}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {currentStep === 0 && <Step1MediaSelection selectMediaOption={selectMediaOption} />}
          {currentStep === 1 && <Step2SplitDistribution selectMediaOption={selectMediaOption} />}
          {currentStep === 2 && <Step3SplitDone onClose={onClose} />}
        </div>
      </AutoSplitStateContext.Provider>
    </Dialog>
  );
};

export default AutoSplitDialog;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useCurrentProject, useZeroAuthInstantLearningState } from './state';
import ImageItem from './ImageItem';

const useStyles = makeStyles(() => ({
  imageList: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
}));

export type ImageListProps = {};

const ImageList: React.FC<ImageListProps> = () => {
  const styles = useStyles();
  const project = useCurrentProject();

  const { state, dispatch } = useZeroAuthInstantLearningState();
  const { mediaIndex, mediaStatesByIndex } = state;

  if (!project?.dataset.length) {
    return null;
  }

  return (
    <div className={styles.imageList}>
      {project.dataset.map((imageItem, index) => (
        <ImageItem
          key={imageItem.id}
          imageItem={imageItem}
          active={index === mediaIndex}
          mediaStates={mediaStatesByIndex[index]}
          onClick={() => {
            dispatch(draft => {
              draft.mediaIndex = index;
            });
          }}
        />
      ))}
    </div>
  );
};

export default ImageList;

import React, { ReactNode, useEffect, useState } from 'react';
import { Link, Box, Tab, Tabs, Typography, CircularProgress } from '@material-ui/core';
import { Predict } from '../../../components/Predict';
import TrainingData from '../TrainingData';
import { Defect } from '@clef/shared/types';
import { useCreateProjectMutation } from '@/serverStore/projects';
import ConstructionIcon from '../Icons/ConstructionIcon';
import useStyles from './styles';
import { ExampleProjectDetails } from '@/api/example_project_api';
import { useExampleProjectModelInfoQuery } from '@/serverStore/projectModels';

const a11yProps = (index: any) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

interface PredictDialogProps {
  onClose: () => void;
  projectId: number;
  selectedProject: ExampleProjectDetails;
}

// This component should only be used for example projects
const PredictDialog: React.FC<PredictDialogProps> = ({ onClose, projectId, selectedProject }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [registeredModel, setRegisteredModel] = useState<{
    threshold: number | undefined;
    registeredModelId: string | undefined;
  }>({
    threshold: 0,
    registeredModelId: undefined,
  });
  const exampleProjectModel = useExampleProjectModelInfoQuery(projectId);
  const { id: currentModelId, confidence: currentModelThreshold } = exampleProjectModel ?? {};

  useEffect(() => {
    setRegisteredModel({
      threshold: currentModelThreshold ?? undefined,
      registeredModelId: currentModelId,
    });
  }, [currentModelId, currentModelThreshold]);

  const [trainingDataCount, setTrainingDataCount] = useState<number | null>(null);
  const createProject = useCreateProjectMutation();
  const styles = useStyles();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    event.stopPropagation();
    setSelectedTab(newValue);
  };

  const getDefectMap = () =>
    Object.values(selectedProject?.defectMap || {}).map(
      defect =>
        ({
          id: defect?.defectId,
          name: defect.name,
          projectId,
        } as Defect),
    );

  const defectMap = getDefectMap();

  const renderTabs = (predictComponent: ReactNode) =>
    ({
      0: predictComponent,
      1: (
        <TrainingData
          projectId={projectId}
          defects={defectMap}
          setTrainingDataCount={setTrainingDataCount}
        />
      ),
    }[selectedTab]);

  return (
    <Predict
      selectedProject={{
        name: selectedProject?.title,
        description: selectedProject?.description,
        projectId,
        datasetId: selectedProject?.datasetId,
        labelType: selectedProject?.labelType,
      }}
      defectMap={defectMap}
      modelInfo={{
        id: registeredModel.registeredModelId,
        threshold: registeredModel.threshold,
      }}
      updateThreshold={(newThreshold: number) =>
        setRegisteredModel(prevRegisteredModel => ({
          ...prevRegisteredModel,
          threshold: newThreshold,
        }))
      }
      onCloseModal={onClose}
      includeHelpImages
      footerComponent={
        <Box className={styles.predictFooter}>
          <Box display="flex" alignItems="center" justifyContent="center">
            {isCreatingProject ? (
              <CircularProgress size="26px" data-testid="api-response-loader" />
            ) : (
              <>
                <ConstructionIcon />
                <Typography
                  className={styles.predictFooterText}
                  data-testid="create-project-banner"
                >
                  {t('Ready to build your own model? ')}
                  <Link
                    className={styles.predictFooterLink}
                    onClick={async event => {
                      event.stopPropagation();
                      setIsCreatingProject(true);
                      await createProject(selectedProject?.labelType ?? undefined);
                    }}
                  >
                    {t('Create a Project')}
                  </Link>
                  {t(' to start!')}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      }
      trialData={selectedProject?.trialData}
      enableModelRefresh={false}
    >
      {predictComponent => (
        <Box className={styles.predictDialogContainer}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            classes={{
              root: styles.tabsRoot,
              flexContainer: styles.tabsFlexContainer,
              indicator: styles.tabsIndicator,
            }}
          >
            <Tab
              label="Predict"
              {...a11yProps(0)}
              selected={selectedTab === 0}
              classes={{
                root: styles.tab,
                selected: styles.tabSelected,
              }}
            />
            <Tab
              label={
                typeof trainingDataCount === 'number'
                  ? `Training Data (${trainingDataCount || 0})`
                  : 'Training Data'
              }
              {...a11yProps(1)}
              selected={selectedTab === 1}
              classes={{
                root: styles.tab,
                selected: styles.tabSelected,
              }}
            />
          </Tabs>
          {renderTabs(predictComponent)}
        </Box>
      )}
    </Predict>
  );
};

export default PredictDialog;

import React from 'react';

export type TopRightProps = {};

const TopRight: React.FC<TopRightProps> = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 7.5V12.5H12.5V7.5C12.5 5.41667 11.7708 3.64583 10.3125 2.1875C8.85417 0.729167 7.08333 0 5 0H0V5H5C5.70833 5 6.30167 5.23917 6.78 5.7175C7.26 6.1975 7.5 6.79167 7.5 7.5Z"
        fill="white"
      />
    </svg>
  );
};

export default TopRight;

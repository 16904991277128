import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Button, greyScale } from '@clef/client-library';

const useStyles = makeStyles(theme => ({
  alertDialogTitle: {
    '& h2': {
      fontFamily: 'Commissioner',
      fontSize: 20,
      fontWeight: 500,
    },
  },
  alertDialogContent: {
    paddingTop: 0,
    color: theme.palette.grey[800],
    fontFamily: 'Commissioner',
  },
  alertList: {
    marginTop: theme.spacing(0.5),
    marginBottom: 0,
  },
  alertDialogPaper: {
    width: 516,
  },
  alertActions: {
    '& button': {
      flex: 1,
      height: 40,
    },
  },
  buttonProgress: {
    marginLeft: theme.spacing(3),
    color: greyScale[100],
  },
}));

interface CustomTrainAlertDialogProps extends DialogProps {
  onCancel?: () => void;
  isExecuting: boolean;
  onOk: () => void;
}

const CustomTrainAlertDialog: React.FC<CustomTrainAlertDialogProps> = ({
  open,
  onCancel,
  isExecuting,
  onOk,
}) => {
  const styles = useStyles();

  return (
    <Dialog
      open={open}
      classes={{
        paper: styles.alertDialogPaper,
      }}
    >
      <DialogTitle className={styles.alertDialogTitle}>{t('Image Size Compatibility')}</DialogTitle>
      <DialogContent className={styles.alertDialogContent}>
        <Typography>
          {t(
            'Due to the image resize settings in this model, it will not be compatible with cloud inference. Training is still support but,',
          )}
        </Typography>
        <ul className={styles.alertList}>
          <li>
            <Typography>{t('The predict dialog will be disabled for this model')}</Typography>
          </li>
          <li>
            <Typography>{t('You can only use edge deployment for this model')}</Typography>
          </li>
        </ul>
      </DialogContent>
      <DialogActions className={styles.alertActions}>
        <Button variant="outlined" color="primary" id="train-back-to-edit" onClick={onCancel}>
          {t('Back to Edit')}
        </Button>
        <Button
          disabled={isExecuting}
          variant="contained"
          color="primary"
          id="train-now-button"
          onClick={onOk}
        >
          {t('Yes! Train Now')}
          {isExecuting && <CircularProgress size={20} className={styles.buttonProgress} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomTrainAlertDialog;

import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import { createDefectBookExampleApi } from '../../../hooks/api/useDefectBookApi';
import useLocalMediaUpload from '../../../hooks/localMediaUpload';
import { DefectBookExample, DefectBookExampleData, UploadType } from '@clef/shared/types';
import { Button } from '@clef/client-library';
import { useDefectBookEnhancedStyles } from '../defectBookEnhancedStyles';
import { v4 as uuidV4 } from 'uuid';
import MediaSelectionDialog from '../../DataBrowser/MediaSelectionDialog';

export interface CreateNewExampleButtonGroupProps {
  onCreateNewExample: (newExamples: DefectBookExample[]) => void;
}

const newExampleTemplate = (mediaId: number): DefectBookExampleData => ({
  mediaId,
  note: `*Please add notes for this example*`,
  annotations: {
    boxAnnotations: [],
    textAnnotations: [],
    lineAnnotations: [],
  },
});

const uniqueUploadId = 'local-media-upload-input';

const CreateNewExampleButtonGroup: React.FC<CreateNewExampleButtonGroupProps> = ({
  onCreateNewExample,
}) => {
  const styles = useDefectBookEnhancedStyles();
  const [openMediaBrowser, setOpenMediaBrowser] = useState(false);
  const [creatingExample, setCreatingExample] = useState(false);
  const { uploadFiles } = useLocalMediaUpload(() => {}, UploadType.DefectBook);

  const createDefectBookExamplesFromMediaIds = useCallback(
    async (mediaIds: number[]) => {
      setCreatingExample(true);
      await Promise.all(
        mediaIds.map(mediaId => createDefectBookExampleApi(newExampleTemplate(mediaId))),
      ).then(newExamples => {
        onCreateNewExample(newExamples);
      });
      setCreatingExample(false);
    },
    [onCreateNewExample],
  );

  const onUploadMediaFromLocal = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return;
      }
      setCreatingExample(true);
      const mediaFiles: File[] = Array.from(e.target.files).map(
        originalFile =>
          new File(
            [originalFile],
            // Prefix filename with uuid to avoid 2 different image with same name
            `${uuidV4().slice(0, 4)}-${originalFile.name}`,
            {
              type: originalFile.type,
              lastModified: originalFile.lastModified,
            },
          ),
      );
      const uploadedMedia = await uploadFiles(mediaFiles, {}, undefined);
      await createDefectBookExamplesFromMediaIds(uploadedMedia.map(_ => _.id));
    },
    [createDefectBookExamplesFromMediaIds, uploadFiles],
  );

  return (
    <>
      <label htmlFor={uniqueUploadId}>
        {/* @ts-ignore component does exist and has to be span */}
        <Button color="primary" component="span" isPending={creatingExample} variant="text">
          {t('Add Images from Computer')}
        </Button>
      </label>
      <Button
        id="add-image-from-data-button"
        color="primary"
        onClick={() => setOpenMediaBrowser(true)}
        isPending={creatingExample}
        variant="text"
      >
        {t('Add Images from Data Browser')}
      </Button>
      <input
        accept="image/*"
        className={cx(styles.hidden)}
        multiple
        id={uniqueUploadId}
        type="file"
        onChange={onUploadMediaFromLocal}
      />
      {openMediaBrowser && (
        <MediaSelectionDialog
          onClose={() => setOpenMediaBrowser(false)}
          onSelectMedia={mediaIds => {
            createDefectBookExamplesFromMediaIds(mediaIds);
          }}
        />
      )}
    </>
  );
};

export default CreateNewExampleButtonGroup;

import { addDays, format } from 'date-fns';

export const dateToISOString = (date: string | Date) => {
  // new Date('2023-01-02') turns the date to UTC 2023-01-02T00:00:00Z, but we want it to be 00:00:00 of local time.
  // e.g. In China (GMT+8), the output should be '2023-01-02T00:00:00+08:00'
  // e.g. In the U.S. (GMT-7), the output should be '2023-01-02T00:00:00-07:00'
  // So, what we need to do is to replace the trailing 'Z' to the user's timezone string

  // This produces a string like +08:00, -07:00 etc.
  const offsetString = format(new Date(), 'XXX');

  const isoString = new Date(date instanceof Date ? date : new Date(date)).toISOString();
  return isoString.replace('Z', offsetString);
};

export const MillisecondsForOneDay = 24 * 60 * 60 * 1000;

export const tomorrowISOString = (today: string | Date) => {
  const tomorrow = addDays(today instanceof Date ? today : new Date(today), 1);
  return dateToISOString(tomorrow);
};

import { useCallback } from 'react';
import { useHistory } from 'react-router';

/**
 * If there is previous page in the history, go back to it.
 * Otherwise, redirect to the given page.
 */
export default function useGoBack(page: string) {
  const history = useHistory();
  const goBack = useCallback(() => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.replace(page);
    }
  }, [history, page]);

  return goBack;
}

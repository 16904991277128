import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  planSectionContainer: {
    marginTop: theme.spacing(1),
  },
  planSectionHeader: {
    width: '100%',
    color: theme.palette.grey[800],
    fontSize: 20,
    fontWeight: 500,
    fontFamily: 'Commissioner',
    '& > span': {
      display: 'flex',
    },
  },

  starter: {
    fontSize: 20,
    margin: '0 6px',
    color: theme.palette.blue[500],
    fontWeight: 500,
  },
  planSectionCard: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '13px',
    width: '100% !important',
    margin: '0 !important',
    '&.MuiGrid-spacing-xs-10 > .MuiGrid-item': {
      padding: theme.spacing(5, 7.5),
    },
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.grey[800],
    borderBottom: `1px dotted ${theme.palette.grey[900]}`,
  },
  value: {
    fontSize: 20,
    color: theme.palette.grey[800],
    fontWeight: 500,
    marginRight: 6,
    fontFamily: 'Commissioner',
  },
  progressValue: {
    color: theme.palette.grey[800],
    fontSize: 14,
    paddingRight: theme.spacing(1),
  },
  addButton: {
    cursor: 'pointer',
  },
  creditLimitMonthInfoButton: {
    height: 20,
    width: 20,
    color: theme.palette.blue[200],
  },
  tooltipBox: {
    width: 238,
    padding: theme.spacing(2),
  },
  tooltipTitle: {
    fontSize: 14,
    fontWeight: 700,
  },
  tooltipContent: {
    marginTop: theme.spacing(1),
  },
  cancelTip: {
    fontSize: 14,
    fontFamily: 'Commissioner',
    lineHeight: '20px',
    color: theme.palette.grey[600],
    fontWeight: 400,
  },
  clickableLink: {
    fontSize: 14,
    color: theme.palette.blue[500],
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 500,
  },
  usagePromptForVisionary: {
    color: theme.palette.greyModern[600],
    fontSize: 14,
    paddingRight: theme.spacing(1),
  },
  infoOutlinedIcon: {
    width: 20,
    height: 20,
    color: theme.palette.greyModern[600],
  },
}));

export default useStyles;

import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import { Defect } from '@clef/shared/types';
import { Redirect } from 'react-router';
import { useDefectBookEnhancedStyles } from '../defectBookEnhancedStyles';
import CLEF_PATH from '../../../constants/path';
import { Grid, Paper, Typography } from '@material-ui/core';
import { Button } from '@clef/client-library';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getDefectColor } from '../../../utils';
import DefectDetailViewContent from './DefectDetailViewContent';
import { useWindowEventListener } from '@clef/client-library';

export interface DefectDetailViewProps {
  defects: Defect[];
  showArchived: boolean;
  onSelectDefect: (defectIdStr: string) => void;
  selectedDefectId: number;
}

const DefectDetailView: React.FC<DefectDetailViewProps> = ({
  defects,
  showArchived,
  onSelectDefect,
  selectedDefectId,
}) => {
  const styles = useDefectBookEnhancedStyles();
  const [sidebarFixed, setSidebarFixed] = useState(false);

  const selectedDefect = defects.find(defect => defect.id === selectedDefectId);

  useWindowEventListener(
    'scroll',
    useCallback(() => {
      const windowScrollHeight = window.scrollY;
      if (sidebarFixed && windowScrollHeight < 210) {
        setSidebarFixed(false);
      }
      if (!sidebarFixed && windowScrollHeight >= 210) {
        setSidebarFixed(true);
      }
    }, [sidebarFixed]),
  );

  if (!selectedDefectId || !selectedDefect) {
    // redirect back if the defect is invalid
    return <Redirect to={CLEF_PATH.data.defectBookEnhanced} />;
  }

  return (
    <Grid container direction="row" wrap="nowrap" alignItems="flex-start">
      {/* Side bar - list all defects */}
      <aside className={styles.defectDetailNav}>
        <div className={cx(sidebarFixed && styles.defectDetailNavFixed)}>
          <Paper className={styles.defectDetailNavPaper}>
            {defects.map(defect => {
              const isSelected = defect.id === selectedDefect.id;
              return !defect.isArchived || showArchived ? (
                <Grid
                  container
                  key={defect.id}
                  className={styles.defectDetailNavRow}
                  data-selected={isSelected}
                  justifyContent="flex-start"
                  alignItems="center"
                  wrap="nowrap"
                  onClick={() =>
                    defect.isArchived !== true ? onSelectDefect(defect.id.toString()) : null
                  }
                >
                  <div
                    className={styles.defectColorSmall}
                    style={{ backgroundColor: getDefectColor(defect) }}
                  />
                  <Typography variant="body1" className={styles.defectDetailNavRowText}>
                    {defect.name}
                  </Typography>
                </Grid>
              ) : null;
            })}
          </Paper>
          <Button
            id="back-to-card-view"
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={() => onSelectDefect('')}
          >
            {t('Back to card view')}
          </Button>
        </div>
      </aside>
      {/* Main content - defect detail of the selected defect */}
      {/* Deliberately add key here to re-render when defect changes*/}
      <DefectDetailViewContent key={selectedDefect.id} defect={selectedDefect} />
    </Grid>
  );
};

export default DefectDetailView;

import React from 'react';
import cx from 'classnames';
import { makeStyles, Typography, Tooltip } from '@material-ui/core';
import seedrandom from 'seedrandom';

const AVATAR_COLORS = ['#A9B0B7', '#B39DDA', '#47BDD3', '#007BD7'];

const useStyles = makeStyles({
  avatar: {
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    flexShrink: 0,
  },
  text: {
    color: '#fff',
  },
});

export interface UserAvatarProps {
  name: string;
  ['data-testid']?: string;
  disableTooltip?: boolean;
  classes?: {
    avatar?: string;
  };
}

export const UserAvatar: React.FC<UserAvatarProps> = React.memo(
  ({ name, 'data-testid': dataTestId, disableTooltip, classes }) => {
    const styles = useStyles();
    const letter = name[0]?.toUpperCase() || '';
    const AvatarComponent = (
      <div
        data-testid={dataTestId}
        className={cx(styles.avatar, classes?.avatar)}
        style={{
          backgroundColor:
            AVATAR_COLORS[Math.round(seedrandom(name)() * 10) % AVATAR_COLORS.length],
        }}
      >
        <Typography variant="subtitle1" className={styles.text}>
          {letter}
        </Typography>
      </div>
    );
    return disableTooltip ? (
      AvatarComponent
    ) : (
      <Tooltip title={name} placement="top">
        {AvatarComponent}
      </Tooltip>
    );
  },
);

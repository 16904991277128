import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  predictDialogContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabsRoot: {
    minHeight: 20,
    marginBottom: theme.spacing(4),
  },
  tabsFlexContainer: {
    columnGap: 24,
  },
  tabsIndicator: {
    display: 'none',
  },
  tab: {
    padding: 0,
    minWidth: 'auto',
    width: 'auto',
    minHeight: 20,
    fontFamily: theme.typography.body1.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#6C737F',
  },
  tabSelected: {
    color: '#051221',
    fontWeight: 700,
  },
  predictFooter: {
    width: '100%',
    height: 40,
    background: '#EFF7FF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  predictFooterText: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '24px',
    marginLeft: 14,
  },
  predictFooterLink: {
    cursor: 'pointer',
  },
}));

export default useStyles;

import { makeStyles } from '@material-ui/core/styles';

export const useTaskListStyles = makeStyles(() => ({
  root: {},
  taskStatus: {
    width: 150,
    lineHeight: 2,
  },
  projectPicker: {
    padding: 20,
  },
  createTaskBtn: {
    float: 'right',
  },
  setAsDefaultBtn: {
    pointerEvents: 'none',
  },
  helperHeaderText: {
    fontSize: '12px',
    color: 'gray',
  },
  tableRow: {
    '&:hover': {
      '& $moreActions': {
        visibility: 'visible',
      },
    },
  },
  moreActions: {
    visibility: 'hidden',
  },
}));

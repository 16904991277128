import { useDefectSelector, useDefectSelectorWithArchived } from '@/store/defectState/actions';
import { AutoCreatedClassDescription, getDefectColor } from '@/utils';
import { defectColors } from '@clef/client-library';
import { useCreateDefectMutation } from '@/serverStore/projects';
import { Defect } from '@clef/shared/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const useInitializeClasses = (
  minimalRequiredClasses = 2,
  onDefectCreated?: (defects: Defect[]) => void | Promise<void>,
) => {
  const allDefects = useDefectSelector();
  const allDefectsWithArchived = useDefectSelectorWithArchived();
  const allDefectNamesSet = useMemo(() => {
    return new Set<string>(allDefectsWithArchived.map(d => d.name.replace(' (deleted)', '')));
  }, [allDefectsWithArchived]);
  const allDefectColorsSet = useMemo(() => {
    return new Set<string>(allDefectsWithArchived.map(d => getDefectColor(d)));
  }, [allDefectsWithArchived]);
  const createDefectApi = useCreateDefectMutation();

  const newClassIndexRef = useRef(1);

  // loop until we find a non-existing name
  const getNewClassName = useCallback(() => {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const newName = `Class ${newClassIndexRef.current++}`;
      if (!allDefectNamesSet.has(newName)) {
        return newName;
      }
    }
  }, [allDefectNamesSet]);

  const getNewClassColor = useCallback(() => {
    const color = defectColors.find(color => !allDefectColorsSet.has(color));
    // add to the set so that we have the latest color set in a closure.
    if (color) {
      allDefectColorsSet.add(color);
    }
    return color;
  }, [allDefectColorsSet]);

  const [initialing, setInitialing] = useState(false);

  const createNewClass = useCallback(async () => {
    setInitialing(true);
    const defects: Defect[] = [];

    for (let i = 0; i < minimalRequiredClasses - allDefects.length; i++) {
      const newName = getNewClassName();
      const newColor = getNewClassColor();
      defects.push(
        await createDefectApi.mutateAsync({
          name: newName,
          color: newColor,
          descriptionText: AutoCreatedClassDescription,
        }),
      );
    }

    onDefectCreated?.(defects);
    setInitialing(false);
  }, [
    allDefects.length,
    getNewClassColor,
    getNewClassName,
    minimalRequiredClasses,
    onDefectCreated,
  ]);

  useEffect(() => {
    // Only need to create if there are not enough classes.
    if (!initialing && allDefects && allDefects.length < minimalRequiredClasses) {
      createNewClass();
    }
  }, [allDefects, allDefectsWithArchived, initialing, minimalRequiredClasses, createNewClass]);

  return [initialing];
};

import { makeStyles, Paper, Typography } from '@material-ui/core';
import { Button } from '@clef/client-library';
import HelpOutline from '@material-ui/icons/HelpOutline';
import React from 'react';
import { useUsersnapState } from './store/usersnapContext';

const useStyles = makeStyles(theme => ({
  usersnapBtn: {
    position: 'fixed',
    right: 14 - 4,
    bottom: 200,
    zIndex: 9999,
    '&:hover $feedbackText': {
      display: 'block',
    },
    transition: 'right 0.3s',
    borderRadius: 6,
    backgroundColor: theme.palette.primary.main,
    transform: 'translateX(50%) rotate(-90deg)',
    '& $feedbackText': {
      display: 'block',
      fontSize: 14,
    },
    '& $messageBtn': {
      width: 16,
      height: 16,
    },
    '& $feedbackBtn': {
      height: 28,
    },
  },
  feedbackBtn: {
    display: 'flex',
    padding: '10px',
    minWidth: 'auto!important',
    height: 42,
  },
  feedbackText: {
    display: 'none',
    marginLeft: theme.spacing(1),
    fontWeight: 500,
    fontSize: 18,
  },
  messageBtn: {
    color: 'white',
    width: 26,
    height: 26,
  },
}));

const Usersnap: React.FC = () => {
  const styles = useStyles();
  const usersnapApi = useUsersnapState();

  return (
    <>
      <Paper className={styles.usersnapBtn}>
        <Button
          id="feedback-button"
          className={styles.feedbackBtn}
          onClick={() => {
            if (usersnapApi) {
              usersnapApi?.logEvent('open_feedback');
            }
          }}
          variant="contained"
          color="primary"
        >
          <HelpOutline className={styles.messageBtn} />
          <Typography className={styles.feedbackText}>{t('Feedback')}</Typography>
        </Button>
      </Paper>
    </>
  );
};

export default Usersnap;

import React, { useCallback, useEffect, useState } from 'react';
import { FormControlLabel, Radio, Tooltip, Box } from '@material-ui/core';

import { ToggleButton, Typography, useKeyPress } from '@clef/client-library';
import { FilterOptionName } from '@clef/shared/types';

import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useCurrentProjectModelInfoQuery, useModelStatusQuery } from '@/serverStore/projectModels';
import { isModelTrainingSuccessful } from '@/store/projectModelInfoState/utils';

import { useDataBrowserState } from '../dataBrowserState';
import ModelSettings from './ModelSettings';
import useStyles from './styles';

const LabelToggles: React.FC<{}> = () => {
  const styles = useStyles();
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const {
    state: { showGroundTruth, showPredictions, viewMode, appliedFilters },
    dispatch,
  } = useDataBrowserState();
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  const { data: modelStatusRes, isLoading: isModelStatusLoading } = useModelStatusQuery(
    projectId,
    currentModelId,
  );
  const { status: modelStatus, metricsReady } = modelStatusRes ?? {};
  const fastEasyModelSuccessful = isModelTrainingSuccessful(modelStatus, metricsReady);

  const handleToggleGroundTruth = useCallback(() => {
    viewMode === 'image' &&
      dispatch(draft => {
        draft.showGroundTruth = !draft.showGroundTruth;
      });
  }, [dispatch, viewMode]);
  useKeyPress('g', handleToggleGroundTruth);

  const handleTogglePrediction = useCallback(() => {
    viewMode === 'image' &&
      !!currentModelId &&
      dispatch(draft => {
        draft.showPredictions = !draft.showPredictions;
      });
  }, [dispatch, currentModelId, viewMode]);
  useKeyPress('p', handleTogglePrediction);

  const hasAppliedGroundTruthFilter = !!appliedFilters[FilterOptionName.GroundTruthLabels];
  const hasAppliedPredictionFilter = !!appliedFilters[FilterOptionName.PredictionLabels];
  useEffect(() => {
    if (viewMode === 'image') {
      return;
    }
    dispatch(draft => {
      if (hasAppliedGroundTruthFilter && hasAppliedPredictionFilter) {
        draft.showGroundTruth = true;
        draft.showPredictions = true;
      } else if (hasAppliedGroundTruthFilter) {
        if (!draft.showGroundTruth && draft.showPredictions) {
          draft.showGroundTruth = true;
          draft.showPredictions = false;
        }
      } else if (hasAppliedPredictionFilter) {
        if (draft.showGroundTruth && !draft.showPredictions) {
          draft.showGroundTruth = false;
          draft.showPredictions = true;
        }
      }
    });
  }, [dispatch, hasAppliedGroundTruthFilter, hasAppliedPredictionFilter, viewMode]);

  const [predictionTooltipOpen, setPredictionTooltipOpen] = useState(false);

  return viewMode === 'image' ? (
    <>
      <Tooltip
        arrow
        placement="top"
        title={
          viewMode === 'image'
            ? t('View ground truth labels. Press {{key}} to show/hide labels.', {
                key: <code>g</code>,
              })
            : ''
        }
      >
        <span>
          <ToggleButton
            id="show-ground-truth"
            isOn={showGroundTruth}
            onToggle={() =>
              dispatch(draft => {
                draft.showGroundTruth = !draft.showGroundTruth;
              })
            }
          >
            <Typography variant="body_medium" className={styles.labelText}>
              {t('Ground truth')}
            </Typography>
          </ToggleButton>
        </span>
      </Tooltip>
      <Tooltip
        open={predictionTooltipOpen}
        arrow
        placement="top"
        title={
          viewMode === 'image'
            ? t('View the model’s predictions. Press {{key}} to show/hide predictions.', {
                key: <code>p</code>,
              })
            : ''
        }
      >
        <span>
          <ToggleButton
            id="show-prediction"
            isOn={showPredictions || (isModelStatusLoading && !fastEasyModelSuccessful)}
            disableTouchRipple
            onToggle={() =>
              dispatch(draft => {
                draft.showPredictions = !draft.showPredictions;
              })
            }
            onMouseEnter={() => setPredictionTooltipOpen(true)}
            onMouseLeave={() => setPredictionTooltipOpen(false)}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="body_medium" className={styles.labelText}>
                {t('Prediction')}
              </Typography>
              {(showPredictions || (isModelStatusLoading && !fastEasyModelSuccessful)) && (
                <>
                  <Box mr={1}>{': '}</Box>
                  <ModelSettings onClick={() => setPredictionTooltipOpen(false)} />
                </>
              )}
            </Box>
          </ToggleButton>
        </span>
      </Tooltip>
    </>
  ) : currentModelId ? (
    <>
      <Box ml={4} />
      <Tooltip
        placement="top"
        arrow
        title={
          hasAppliedPredictionFilter
            ? t('To view Ground truth labels, please clear Prediction labels filter')
            : ''
        }
      >
        <FormControlLabel
          control={
            <Radio
              name="show-ground-truth-instance"
              checked={showGroundTruth && !showPredictions}
              color="primary"
              onClick={() =>
                dispatch(draft => {
                  draft.showGroundTruth = true;
                  draft.showPredictions = false;
                })
              }
              disabled={hasAppliedPredictionFilter}
            />
          }
          label={
            <Typography variant="body_medium" className={styles.labelText}>
              {t('Ground truth')}
            </Typography>
          }
        />
      </Tooltip>
      <Tooltip
        placement="top"
        arrow
        title={
          hasAppliedGroundTruthFilter
            ? t('To view Prediction labels, please clear Ground truth labels filter')
            : ''
        }
      >
        <FormControlLabel
          control={
            <Radio
              name="show-prediction-instance"
              checked={showPredictions && !showGroundTruth}
              color="primary"
              onClick={() =>
                dispatch(draft => {
                  draft.showPredictions = true;
                  draft.showGroundTruth = false;
                })
              }
              disabled={hasAppliedGroundTruthFilter}
            />
          }
          label={
            <Box display="flex" alignItems="center">
              <Typography variant="body_medium" className={styles.labelText}>
                {t('Prediction')}
              </Typography>
              {showPredictions && !showGroundTruth && (
                <>
                  <Box mr={1}>{': '}</Box>
                  <ModelSettings />
                </>
              )}
            </Box>
          }
        />
      </Tooltip>
    </>
  ) : null;
};

export default LabelToggles;

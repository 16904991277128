import React from 'react';
import { CircularProgress, TextField, withStyles, Grid, Typography } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { withRouter } from 'react-router';
import { LoginTabProps } from '../types';
import FormStatus from './FormStatus';
import styles from '../styles';
import AuthApi from '../../../api/auth_api';
import { useSnackbar } from 'notistack';

const SingleSignOnPage: React.SFC<LoginTabProps> = ({ classes, ...props }) => {
  const handleLoginKeyPress = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter') {
      props.handleLoginButtonClick();
      e.preventDefault();
    }
  };
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <FormStatus
        classes={classes}
        isLoading={props.isLoading}
        error={props.error}
        success={props.success}
        message={props.message}
      />
      <Typography className={classes.ssoPageTitle}>{t('SSO Log In')}</Typography>
      <TextField
        name="login"
        type="email"
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField,
          },
        }}
        fullWidth
        margin="normal"
        placeholder={t('Email Address')}
        label={t('Email Address')}
        value={props.loginValue}
        onChange={(e: React.ChangeEvent): void => props.handleInput(e)}
        onKeyDown={handleLoginKeyPress}
      />
      <div className={classes.formButtons}>
        {props.isLoading ? (
          <CircularProgress size={26} className={classes.loginLoader} />
        ) : (
          <Grid container>
            <Grid item xs={12} className={classes.centerGrid}>
              <Button
                id="sso-login-button"
                aria-label="SSOLogin"
                variant="contained"
                color="primary"
                disabled={props.loginValue.length === 0}
                onClick={() => {
                  AuthApi.samlLogin(props.loginValue)
                    .then(redirectUrl => {
                      window.location.replace(redirectUrl);
                    })
                    .catch(e => {
                      enqueueSnackbar(
                        t('Saml Login Failed: {{temp0}}', {
                          temp0: e.message,
                        }),
                        {
                          variant: 'error',
                        },
                      );
                    });
                }}
              >
                {t('Continue')}
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.centerGrid}>
              <Grid
                className={classes.buttonText}
                onClick={() => {
                  props.history.push('/login/0' + props.location.search);
                }}
              >
                {t('Log in with password')}
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default withRouter(withStyles(styles, { withTheme: true })(SingleSignOnPage));

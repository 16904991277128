import React, { ReactNode } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useTypographyStyles } from '@clef/client-library/src/themes/typography';
import classNames from 'classnames';
import Check from '@material-ui/icons/Check';
import EmojiObjectsOutlined from '@material-ui/icons/EmojiObjectsOutlined';

const useStyles = makeStyles(theme => ({
  step: {
    '&:last-child $line': {
      display: 'none',
    },
  },
  stepIndex: {
    width: 60,
    height: 60,
    border: `2px solid ${theme.palette.greyModern[300]}`,
    color: theme.palette.greyModern[300],
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    fontSize: 14,
  },
  doneStepIndex: {
    border: `2px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    color: 'white',
  },
  currentStepIndex: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  line: {
    borderLeft: `2px solid ${theme.palette.greyModern[300]}`,
    position: 'absolute',
    left: 29,
    top: 60,
    bottom: 0,
  },
  doneLine: {
    borderLeft: `2px solid ${theme.palette.primary.main}`,
  },
  card: {
    background: 'white',
    borderRadius: 10,
    border: `1px solid ${theme.palette.greyModern[200]}`,
    padding: theme.spacing(4, 5),
  },
  notStartedTitle: {
    color: theme.palette.greyModern[400],
  },
  content: {
    fontSize: 14,
    color: theme.palette.greyModern[600],
  },
  image: {
    width: '100%',
  },
}));

export type StepProps = {
  stepIndex: number;
  currentStep: number;
  title: string;
  doneTitle?: string;
  content: ReactNode;
  tips?: string;
  image?: string;
};

const Step: React.FC<StepProps> = props => {
  const { stepIndex, currentStep, title, doneTitle, content, tips, image } = props;
  const styles = useStyles();
  const typographyStyles = useTypographyStyles();
  const isCurrent = stepIndex === currentStep;
  const notStarted = stepIndex > currentStep;
  const done = stepIndex < currentStep;
  return (
    <Box paddingBottom={10} display="flex" position="relative" className={styles.step}>
      <Box flex={0}>
        <Box
          className={classNames(
            styles.stepIndex,
            isCurrent && styles.currentStepIndex,
            done && styles.doneStepIndex,
          )}
        >
          {done ? <Check /> : stepIndex + 1}
        </Box>
      </Box>
      <Box className={classNames(styles.line, done && styles.doneLine)}></Box>
      <Box flex={1} marginLeft={5}>
        <Box className={styles.card}>
          <Box
            className={classNames(
              typographyStyles.h4_semibold,
              notStarted && styles.notStartedTitle,
            )}
          >
            {done ? doneTitle ?? title : title}
          </Box>
          {stepIndex === currentStep && (
            <Box marginTop={4} minWidth={400}>
              <Box className={styles.content}>{content}</Box>
              {tips && (
                <Box
                  display="flex"
                  alignItems="center"
                  className={typographyStyles.body_small}
                  marginTop={4.5}
                >
                  <EmojiObjectsOutlined fontSize="small" />
                  <Box marginLeft={1}>{tips}</Box>
                </Box>
              )}
              {image && (
                <Box width="100%" marginTop={2.5}>
                  <img src={image} className={styles.image} />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Step;

import { BaseAPI } from './base_api';
import {
  ApiResponse,
  Project,
  ProjectId,
  UserId,
  ProjectRole,
  ProjectWithUsers,
  LabelType,
  UserPermission,
  ActiveProject,
  ProjectStatsById,
  ClientTrainingState,
  DatasetId,
  ProjectSplit,
  XEvent,
  ActiveProjectActionTypeEnum,
  ActiveProjectXEventProperty,
} from '@clef/shared/types';
import { SAMPLE_PROJECT_NAME_PREFIX } from '../constants';

const ProjectAPIUrlPrefix = 'project';

const ACTIVE_PROJECT_X_EVENT_NAME = 'active_project';

class ProjectAPI extends BaseAPI {
  async getProjectWithUsers(
    projectId: ProjectId,
    upsertUserProject?: boolean,
  ): Promise<ApiResponse<ProjectWithUsers>> {
    return this.get(`with_users`, {
      projectId,
      ...(upsertUserProject !== undefined && { upsertUserProject }),
    });
  }

  async getProjectList(): Promise<ApiResponse<Project[]>> {
    return this.get('list', { ownedOnly: false });
  }

  async getActiveProjectList(): Promise<ApiResponse<{ projects: ActiveProject[] }>> {
    return this.get('active_project/list');
  }

  async addActiveProject(projectId: ProjectId): Promise<ApiResponse<void>> {
    const x_properties: ActiveProjectXEventProperty = {
      project_id: projectId,
      action_type: ActiveProjectActionTypeEnum.Add,
    };
    const x_event: XEvent<ActiveProjectXEventProperty> = {
      event: ACTIVE_PROJECT_X_EVENT_NAME,
      action: 'POST',
      properties: x_properties,
    };
    return this.post(
      'active_project/add',
      JSON.stringify({
        projectId,
      }),
      {
        headers: { 'Content-Type': 'application/json', 'x-event': JSON.stringify(x_event) },
      },
    );
  }

  async deleteActiveProject(projectId: ProjectId): Promise<ApiResponse<void>> {
    const x_properties: ActiveProjectXEventProperty = {
      project_id: projectId,
      action_type: ActiveProjectActionTypeEnum.Delete,
    };
    const x_event: XEvent<ActiveProjectXEventProperty> = {
      event: ACTIVE_PROJECT_X_EVENT_NAME,
      action: 'POST',
      properties: x_properties,
    };
    return this.post(
      'active_project/delete',
      JSON.stringify({
        projectId,
      }),
      {
        headers: { 'Content-Type': 'application/json', 'x-event': JSON.stringify(x_event) },
      },
    );
  }

  async getProjectStatList(): Promise<ApiResponse<ProjectStatsById>> {
    return this.get('list/stats');
  }

  async createProject(
    projectName: string,
    isInviteOnly: boolean,
    labelType?: LabelType,
  ): Promise<ApiResponse<ProjectWithUsers>> {
    return this.postJSON('create', { projectName, isInviteOnly, labelType });
  }

  async saveMembersToProjectWithPermission(
    projectId: ProjectId,
    userPermissions: Array<{ id: UserId; permissions: UserPermission[] }>,
  ) {
    return this.postJSON('upsert_members', {
      projectId,
      userPermissions,
    });
  }

  async removeUserFromProject(projectId: ProjectId, userId: UserId) {
    return this.delete('with_users', {
      projectId,
      userId,
    });
  }

  async transferOwnership(projectId: ProjectId, userId: UserId) {
    return this.postJSON('transfer_ownership', {
      projectId,
      userId,
    });
  }

  async editMemberRole(projectId: ProjectId, memberId: UserId, newMemberRole: ProjectRole) {
    return this.postJSON('change_role', {
      projectId,
      memberId,
      newMemberRole,
    });
  }

  async changeVisibility(projectId: ProjectId, isInviteOnly: boolean) {
    return this.postJSON('set_invite_only', {
      projectId,
      isInviteOnly,
    });
  }

  async rename(projectId: ProjectId, name: Project['name']) {
    return this.postJSON('rename', {
      projectId,
      name,
    });
  }

  async clone(
    sourceProjectId: ProjectId,
    name: Project['name'],
    inviteOnly: Project['inviteOnly'],
    cloneDefectBook: boolean,
    cloneUsers: boolean,
  ) {
    return this.postJSON('clone', {
      sourceProjectId,
      name,
      inviteOnly,
      cloneDefectBook,
      cloneUsers,
    });
  }

  async cloneSample(): Promise<ApiResponse<string>> {
    return this.postJSON('clone_sample', {
      projectName: SAMPLE_PROJECT_NAME_PREFIX,
    });
  }

  async getLastUploadTime(projectId: ProjectId): Promise<string> {
    const res = await this.get('last_upload_time', { projectId }, true);
    return res.uploadTime;
  }
  // Edge project mapping
  async getEdgeProject(edgeProjectId: number): Promise<{ edgeProjectId: number }> {
    return this.get('edge_project_mapping', { projectId: edgeProjectId }, true);
  }

  async softDeleteProject(projectId: ProjectId): Promise<ApiResponse<{}>> {
    return this.postJSON('softDelete', { projectId });
  }

  // project split
  async getSplit(projectId: ProjectId): Promise<ApiResponse<ProjectSplit[]>> {
    return this.get('split', { projectId });
  }

  async upsertSplit(
    projectId: ProjectId,
    splitSetName: string,
    description?: string,
    projectSplitId?: number,
  ) {
    return this.postJSON('split/upsert', { projectId, splitSetName, description, projectSplitId });
  }

  async deleteSplit(projectSplitId: number) {
    return this.postJSON('split/delete', { projectSplitId });
  }

  async upsertCustomTrainingConfig(projectId: ProjectId, customConfig: ClientTrainingState) {
    return this.postJSON('upsert_custom_training_config', { projectId, customConfig });
  }

  async importSampleData(projectId: ProjectId, sampleDataFolder: string, importLabels?: boolean) {
    return this.postJSON('import_sample_data', { projectId, sampleDataFolder, importLabels });
  }

  async updateLabelType(projectId: ProjectId, datasetId: DatasetId, labelType: LabelType) {
    return this.postJSON('update_label_type', { projectId, datasetId, labelType });
  }

  async cloneProjectFromVersionedDataset(
    currentProjectId: ProjectId,
    version: Number,
    projectName: string,
    labelType: LabelType,
    includeLabels?: boolean,
    includeMetadata?: boolean,
    includeTags?: boolean,
  ) {
    return this.postJSON('clone', {
      currentProjectId,
      version,
      projectName,
      labelType,
      includeLabels,
      includeMetadata,
      includeTags,
    });
  }
}

export default new ProjectAPI(ProjectAPIUrlPrefix);

import React from 'react';
import cx from 'classnames';
import { makeStyles, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  badge: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    overflow: 'hidden',
    margin: 1,
  },
  badgePrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  badgeSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  badgeText: {
    color: theme.palette.common.white,
  },
}));

export interface SimpleBadgeProps {
  color: 'primary' | 'secondary';
  content: number;
}

const SimpleBadge: React.FC<SimpleBadgeProps> = ({ color, content }) => {
  const styles = useStyles();
  return (
    <Grid
      className={cx(
        styles.badge,
        color === 'primary' && styles.badgePrimary,
        color === 'secondary' && styles.badgeSecondary,
      )}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="body2" className={styles.badgeText}>
        {content}
      </Typography>
    </Grid>
  );
};

export default SimpleBadge;

import cx from 'classnames';
import { Divider, Grid, Typography } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import React, { Fragment } from 'react';
import commandKeyImage from '../../../images/command_key.png';
import { isMacOS } from '@clef/client-library';
import { ReactNode } from 'react';
import { LabelingType } from '@clef/shared/types';
import { HotKeySection, HotKeyValue } from '../../../hooks/useHotKeyDialog';
import { useLabelingDrawerStyles } from '../labelingDrawerStyles';

// wrap the key within `` to be rendered as a code span.
const k = (key: HotKeyValue) => '`' + key + '`';

const navigationHotKeySection: HotKeySection = {
  title: t('Navigation'),
  hotKeyList: [
    {
      description: t('Previous / Next image'),
      keys: [k('ARROW_UP'), '/', k('ARROW_DOWN'), t('or'), k('ARROW_LEFT'), '/', k('ARROW_RIGHT')],
    },
    { description: t('Zoom in / out'), keys: [k('='), '/', k('-')] },
    { description: t('Zoom to fit'), keys: [k('0')] },
    { description: t('Zoom to 100% media size'), keys: [k('1')] },
    { description: t('Pan in drawing modes'), keys: [k(t('Space')), '+', t('Drag')] },
  ],
};
const navigationHotKeySectionForMediaDetails: HotKeySection = {
  title: t('Navigation'),
  hotKeyList: [
    { description: t('Previous / Next image'), keys: [k('ARROW_LEFT'), '/', k('ARROW_RIGHT')] },
    { description: t('Zoom in / out'), keys: [k('='), '/', k('-')] },
    { description: t('Zoom to fit'), keys: [k('0')] },
    { description: t('Zoom to 100% media size'), keys: [k('1')] },
    { description: t('Pan in drawing modes'), keys: [k(t('Space')), '+', t('Drag')] },
  ],
};
const segmentationToolHotKeySection: HotKeySection = {
  title: t('Drawing tool'),
  hotKeyList: [
    { description: t('Smart Labeling'), keys: [k('q')] },
    { description: t('Brush'), keys: [k('b')] },
    { description: t('Polygon'), keys: [k('w')] },
    { description: t('Polyline'), keys: [k('l')] },
    { description: t('Mark nothing to label'), keys: [k('n')] },
    { description: t('Toggle draw / erase'), keys: [k('e')] },
    { description: t('Decrease / increase stroke width'), keys: [k('['), '/', k(']')] },
  ],
};
const boundingBoxToolHotKeySection: HotKeySection = {
  title: t('Drawing tool'),
  hotKeyList: [
    { description: t('Delete selected box'), keys: [k(t('Delete')), '/', k(t('Backspace'))] },
    { description: t('Resize with original ratio'), keys: [t('Hold'), k('Shift')] },
    { description: t('Mark nothing to label'), keys: [k('n')] },
  ],
};
const editHotKeySection: HotKeySection = {
  title: t('Edit'),
  hotKeyList: [
    { description: t('Undo'), keys: [k('COMMAND'), '+', k('z')] },
    { description: t('Redo'), keys: [k('COMMAND'), '+', k('Shift'), '+', k('z')] },
  ],
};
const labelsHotKeySection: HotKeySection = {
  title: t('Labels'),
  hotKeyList: [{ description: t('Hide labels'), keys: [t('Hold'), k('h')] }],
};
const defectHotKeySection: HotKeySection = {
  title: t('Classes'),
  hotKeyList: [
    { description: t('Choose Class'), keys: [k('c')] },
    { description: t('Open Label Book for selected class'), keys: [k('v')] },
    { description: t('Open labeling instructions'), keys: [k('i')] },
  ],
};
const defectHotKeySectionForClassification: HotKeySection = {
  title: t('Classes'),
  hotKeyList: [
    { description: t('Choose Class'), keys: [k('c')] },
    { description: t('Open labeling instructions'), keys: [k('i')] },
  ],
};
const reviewHotKeySection: HotKeySection = {
  title: t('Review'),
  hotKeyList: [{ description: t('Open review notes'), keys: [k('o')] }],
};
const shortcutHotKeySection: HotKeySection = {
  title: t('Shortcut'),
  hotKeyList: [{ description: t('Show all shortcuts'), keys: [k('/')] }],
};
const mediaHotKeySection: HotKeySection = {
  title: t('Media'),
  hotKeyList: [{ description: t('Add metadata'), keys: [k('m')] }],
};

const labelsHotKeySectionForBbox: HotKeySection = {
  title: t('Labels'),
  hotKeyList: [
    { description: t('Hide labels'), keys: [t('Hold'), k('h')] },
    {
      description: t('Toggle display of ground truth'),
      keys: [k('g')],
    },
    {
      description: t('Toggle display of predictions'),
      keys: [k('p')],
    },
  ],
};

export const labelingTaskHotKeySectionsMap = {
  [LabelingType.DefectSegmentation]: [
    navigationHotKeySection,
    segmentationToolHotKeySection,
    editHotKeySection,
    labelsHotKeySection,
    defectHotKeySection,
    shortcutHotKeySection,
    reviewHotKeySection,
  ],
  [LabelingType.DefectBoundingBox]: [
    navigationHotKeySection,
    boundingBoxToolHotKeySection,
    editHotKeySection,
    labelsHotKeySection,
    defectHotKeySection,
    shortcutHotKeySection,
    reviewHotKeySection,
  ],
  [LabelingType.DefectClassification]: [
    navigationHotKeySection,
    defectHotKeySection,
    shortcutHotKeySection,
    mediaHotKeySection,
    reviewHotKeySection,
  ],
};

export const getLabelingTaskHotKeySections = (
  labelingType: keyof typeof labelingTaskHotKeySectionsMap,
) => labelingTaskHotKeySectionsMap[labelingType] ?? [];

export const mediaDetailsHotKeySectionsMap = {
  [LabelingType.DefectSegmentation]: [
    navigationHotKeySectionForMediaDetails,
    segmentationToolHotKeySection,
    editHotKeySection,
    labelsHotKeySection,
    defectHotKeySection,
    shortcutHotKeySection,
  ],
  [LabelingType.DefectBoundingBox]: [
    navigationHotKeySectionForMediaDetails,
    boundingBoxToolHotKeySection,
    editHotKeySection,
    labelsHotKeySectionForBbox,
    defectHotKeySection,
    shortcutHotKeySection,
  ],
  [LabelingType.DefectClassification]: [
    navigationHotKeySectionForMediaDetails,
    defectHotKeySectionForClassification,
    shortcutHotKeySection,
  ],
};

export const getMediaDetailsHotKeySections = (
  labelingType: keyof typeof mediaDetailsHotKeySectionsMap,
) => mediaDetailsHotKeySectionsMap[labelingType] ?? [];

const Key: React.FC<{ oneKey: string }> = ({ oneKey }) => {
  const styles = useLabelingDrawerStyles();
  const wrapInCode = oneKey.startsWith('`') && oneKey.endsWith('`');
  const key = wrapInCode ? oneKey.replace(/`/g, '') : oneKey;
  let keyComponent: ReactNode = key;
  switch (key) {
    case 'ARROW_UP':
      keyComponent = <ArrowDropUp />;
      break;
    case 'ARROW_DOWN':
      keyComponent = <ArrowDropDown />;
      break;
    case 'ARROW_LEFT':
      keyComponent = <ArrowLeft />;
      break;
    case 'ARROW_RIGHT':
      keyComponent = <ArrowRight />;
      break;
    case 'COMMAND':
      keyComponent = isMacOS() ? (
        <img title="Command" className={styles.iconImg} src={commandKeyImage} />
      ) : (
        'Ctrl'
      );
      break;
    case 'CTRL':
      keyComponent = <KeyboardArrowUp titleAccess="Ctrl" />;
      break;
  }
  // for icons, we do not need to add padding left and padding right
  const renderAsIcon = key.length === 1 || typeof keyComponent !== 'string';
  return (
    <span
      className={cx(
        wrapInCode && styles.code,
        // pure '+' needs more spacing
        oneKey === '+' && styles.keyJoin,
        renderAsIcon && styles.codeIcon,
      )}
    >
      {keyComponent}
    </span>
  );
};

const HotKeys: React.FC<{ keys: string[] }> = ({ keys }) => {
  return (
    <Grid container alignItems="center" spacing={1}>
      {keys.map((keyStr, index) => {
        return (
          <Grid key={index} item>
            <Key oneKey={keyStr} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export type HotKeysDrawerProps = {
  hotKeySections?: HotKeySection[];
};
export const HotKeysDrawer: React.FC<HotKeysDrawerProps> = ({ hotKeySections = [] }) => {
  const styles = useLabelingDrawerStyles();
  return (
    <>
      <Typography variant="h3" className={styles.drawerTitle}>
        {t('Keyboard Shortcuts')}
      </Typography>
      {hotKeySections.map((hotKeySection, index) => (
        <Fragment key={index}>
          {index > 0 && <Divider className={styles.divider} />}
          <section className={styles.hotKeySection}>
            <Typography variant="h4" className={styles.hotKeySectionTitle} role="heading">
              <strong>{hotKeySection.title}</strong>
            </Typography>
            {hotKeySection.hotKeyList.map(hotKey => (
              <Grid
                key={hotKey.description}
                container
                justifyContent="space-between"
                alignItems="center"
                className={styles.hotKeyItem}
              >
                <Grid item>{hotKey.description}</Grid>
                <Grid item>
                  <HotKeys keys={hotKey.keys} />
                </Grid>
              </Grid>
            ))}
          </section>
        </Fragment>
      ))}
    </>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { USERSNAP_GLOBAL_API_KEY } from '../constants/usersnap';

export const UsersnapApiContext = React.createContext<{
  logEvent: (type: 'open_feedback') => void;
} | null>(null);

export const useUsersnapState = () => useContext(UsersnapApiContext);

export const UsersnapApiContextProvider: React.FC<{ initParams?: { [key: string]: any } }> = ({
  children,
  initParams = {},
}) => {
  const [usersnapApi, setUsersnapApi] = useState(null);

  useEffect(() => {
    let usersnapApi: any = null;
    window.onUsersnapCXLoad = function (api: any) {
      api.init(initParams);
      usersnapApi = api;
      setUsersnapApi(api);
    };
    const script = document.createElement('script');
    script.defer = true;
    script.src = `https://widget.usersnap.com/global/load/${USERSNAP_GLOBAL_API_KEY}?onload=onUsersnapCXLoad`;
    document.head.appendChild(script);

    return () => {
      if (usersnapApi) {
        usersnapApi.destroy();
      }
      script.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <UsersnapApiContext.Provider value={usersnapApi}>{children}</UsersnapApiContext.Provider>;
};

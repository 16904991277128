import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Typography, Box, ButtonGroup } from '@material-ui/core';
import { IconButton, Button } from '@clef/client-library';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import AccountAPI from '../../api/account_api';
import { LandingLensLogo } from '../../components/Auth/Common';
import { BaseTextField } from '../../components/Auth/TextField';
import { PrimaryButton } from '../../components/Auth/Button';

import { CLEF_PATH } from '../../constants/path';
import AuthPageLayout from '../../components/Layout/AuthPageLayout';
import { parseUTMParams } from '@/utils/url_utils';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { OrganizationSegment } from '@clef/shared/types';

const CONTENT_WIDTH = 482;

export const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  image: {
    marginBottom: theme.spacing(7),
  },
  questionTitle: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(3),
  },
  normalInputBox: {
    width: CONTENT_WIDTH,
    height: 44,
    marginBottom: theme.spacing(7),
    alignSelf: 'flex-start',
  },
  submitButton: {
    width: CONTENT_WIDTH,
    height: 40,
  },
  backButton: {
    position: 'absolute',
    top: 96,
    left: 60,
    border: `1px solid ${theme.palette.greyModern[300]}`,
    width: 40,
    height: 40,
  },
  desc: {
    fontSize: 14,
    color: theme.palette.greyModern[600],
    textAlign: 'center',
    marginBottom: theme.spacing(9),
  },
  buttonGroup: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(7),
    '& button': {
      width: 150,
      marginRight: theme.spacing(4),
      borderColor: theme.palette.greyModern[200],
      borderRightColor: `${theme.palette.greyModern[200]} !important`,
      borderRadius: '10px !important',
      backgroundColor: theme.palette.greyModern[50],
      '&:hover': {
        borderColor: `${theme.palette.primary.main} !important`,
      },
      '&:last-child': {
        marginRight: 0,
      },
      '&.active': {
        borderColor: `${theme.palette.primary.main} !important`,
        backgroundColor: theme.palette.blue[50],
      },
    },
  },
}));

interface FormValues {
  orgName: string;
}

export const CreateOrg: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [isCreating, setIsCreating] = useState(false);
  const { control, errors, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      orgName: '',
    },
  });
  const [segment, setSegment] = useState<OrganizationSegment>(OrganizationSegment.Empty);
  const [orgName, setOrgName] = useState('');

  const utmParams = parseUTMParams();

  const onSubmit = useCallback(
    async ({ orgName }: FormValues) => {
      setIsCreating(true);

      try {
        const { data } = await AccountAPI.createOrg(orgName, segment, utmParams);
        enqueueSnackbar('Organization has been created!', { variant: 'success' });

        history.push(`${CLEF_PATH.login.inviteMember}?orgId=${data?.orgId}&userId=${data?.id}`);
      } catch (e) {
        enqueueSnackbar(e?.message, { variant: 'error', autoHideDuration: 12000 });
        setIsCreating(false);
      }
    },
    [enqueueSnackbar, history, segment, utmParams],
  );

  const getActiveClassnameBySegment = useCallback(
    (currentSegment: OrganizationSegment) => {
      return currentSegment === segment ? 'active' : '';
    },
    [segment],
  );

  const placeHolder = useMemo(() => {
    return segment === 'Work'
      ? t('E.g. Landing AI')
      : segment === 'Academic'
      ? t('Eg. School, class, or team name')
      : t('Workspace name');
  }, [segment]);

  return (
    <AuthPageLayout metaTitle={t('Create Organization')}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minWidth="100vw"
        minHeight="100vh"
        position="relative"
      >
        <Box display="flex" flexDirection="column" justifyContent="center" width={CONTENT_WIDTH}>
          <LandingLensLogo />

          <IconButton
            id="go-back"
            onClick={() => {
              history.goBack();
            }}
            className={styles.backButton}
          >
            <ArrowBackIcon />
          </IconButton>

          <Typography variant="h1" className={styles.title}>
            {t("Let's set up your workspace!")}
          </Typography>

          <Typography className={styles.desc}>
            {t(
              'A workspace is where a team can create different vision projects, manage datasets, and train and deploy models.',
            )}
          </Typography>
          <Typography variant="h3">{t('How do you plan to use LandingLens?')}</Typography>

          <ButtonGroup className={styles.buttonGroup}>
            <Button
              color={segment === OrganizationSegment.Work ? 'primary' : 'default'}
              className={getActiveClassnameBySegment(OrganizationSegment.Work)}
              startIcon={<BusinessCenterOutlinedIcon />}
              onClick={() => setSegment(OrganizationSegment.Work)}
              id="segment-work"
            >
              {t('Work')}
            </Button>
            <Button
              color={segment === OrganizationSegment.Academic ? 'primary' : 'default'}
              startIcon={<SchoolOutlinedIcon />}
              className={getActiveClassnameBySegment(OrganizationSegment.Academic)}
              onClick={() => setSegment(OrganizationSegment.Academic)}
              id="segment-academic"
            >
              {t('Academic')}
            </Button>
            <Button
              color={segment === OrganizationSegment.Personal ? 'primary' : 'default'}
              className={getActiveClassnameBySegment(OrganizationSegment.Personal)}
              startIcon={<AccountCircleOutlinedIcon />}
              onClick={() => setSegment(OrganizationSegment.Personal)}
              id="segment-personal"
            >
              {t('Personal')}
            </Button>
          </ButtonGroup>

          <Typography variant="h3" className={styles.questionTitle}>
            {segment === OrganizationSegment.Work
              ? t('What is your company name?')
              : t('What is your workspace name?')}
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <BaseTextField
              label={placeHolder}
              rules={{
                required: t('This is required.'),
              }}
              name="orgName"
              error={errors?.orgName}
              control={control}
              classes={{ root: styles.normalInputBox }}
              onChange={e => {
                setOrgName(e.target.value);
              }}
            />

            <PrimaryButton
              id="create-new-organization-confirm"
              text={t('Continue')}
              disabled={!!errors.orgName || !orgName || segment === OrganizationSegment.Empty}
              isPending={isCreating}
              className={styles.submitButton}
              useNewUi
            />
          </Box>
        </Box>
      </Box>
    </AuthPageLayout>
  );
};

import React, { useState } from 'react';
import cx from 'classnames';
import { ProjectId } from '@clef/shared/types';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useForm, useFieldArray, OnSubmit, FieldValues, UseFieldArrayProps } from 'react-hook-form';
import { UserRole, ProjectRole } from '@clef/shared/types';
import AddMemberRow from './AddMemberRow';
import { styles } from './styles';
import { useDispatch } from 'react-redux';
import { fetchUsers } from '../../store/user_store';

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  projectId?: ProjectId;
  onClose: () => void;
  inviteUser: (
    email: string,
    userRole: UserRole,
    projectId?: ProjectId,
    projectRole?: ProjectRole,
  ) => void;
}

type InviteFormData = {
  invites: FieldValues;
};

const AddMembersToPlatformDialog: React.FC<Props> = ({
  classes,
  open,
  projectId,
  onClose,
  inviteUser,
}: Props) => {
  const [isInviting, setIsInviting] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { control, errors, handleSubmit, reset } = useForm({
    defaultValues: {
      invites: [{ email: '', userRole: '', projectRole: undefined }],
    },
  });
  const { fields, append, remove } = useFieldArray<UseFieldArrayProps>({
    control,
    name: 'invites',
  });

  const _inviteMember = async (
    email: string,
    userRole: string,
    projectRole: ProjectRole,
  ): Promise<boolean> => {
    try {
      await inviteUser(email, userRole as UserRole, projectId, projectRole);
      return true;
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: 'warning',
      });
      return false;
    }
  };

  const onSubmit: OnSubmit<InviteFormData> = async (data): Promise<void> => {
    setIsInviting(true);
    const { invites } = data;
    let allInvited = true;

    await Promise.all(
      invites.map(async (invite: FieldValues) => {
        const success = await _inviteMember(invite.email, invite.userRole, invite.projectRole);
        if (!success) {
          allInvited = false;
        }
      }),
    );

    if (allInvited) {
      enqueueSnackbar(t('Invitations have been sent!'), {
        variant: 'success',
      });
      dispatch(fetchUsers());
    }
    setIsInviting(false);
    onClose();
    reset();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md">
      <form onSubmit={handleSubmit(onSubmit)} className="cy-invite-members-form">
        <DialogTitle>{t('Add Members to The Platform')}</DialogTitle>
        <DialogContent className={cx(classes.dialogContent, 'cy-invite-members-content')}>
          {fields.map((item, index: number) => (
            <AddMemberRow
              key={item.id}
              index={index}
              classes={{
                root: classes.appendableField,
                errorText: classes.errorText,
              }}
              onDelete={(): void => {
                remove(index);
              }}
              control={control}
              errors={errors}
              showProjectRole={Boolean(projectId)}
            />
          ))}
          <Button
            id="add-member-button"
            variant="contained"
            color="primary"
            onClick={(): void => {
              append({ name: 'append' });
            }}
            className="cy-add-another-member-button"
          >
            {t('＋ Add another member')}
          </Button>
        </DialogContent>
        <hr className={classes.hr} />
        <DialogActions className={classes.dialogActions}>
          <Button
            id="cancel-button"
            onClick={(): void => onClose()}
            color="primary"
            className="cy-cancel-invite-button"
          >
            {t('Cancel')}
          </Button>
          <Button
            id="invite-member-button"
            type="submit"
            color="primary"
            variant="contained"
            disabled={isInviting}
            className="cy-invite-button"
          >
            {isInviting ? t('Inviting...') : t('Invite')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(styles)(AddMembersToPlatformDialog);

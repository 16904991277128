import React, { useCallback, useMemo } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Button, Img, Typography, getThumbnail } from '@clef/client-library';
import { minListHeight, rowHeight, useStyles } from './styles';
import { ActiveProject } from '@clef/shared/types';
import { FixedSizeList } from 'react-window';
import LoadingProgress from '@/pages/model_iteration/componentsV2/LoadingProgress';
import ActiveProjectsEmptyState from './ActiveProjectsEmptyState';
import {
  useDeleteActiveProjectMutation,
  useGetActiveProjectsQuery,
  useGetProjectStatsByOrgIdQuery,
  useSetSelectedProjectId,
} from '@/serverStore/projects';
import { isProjectOwner } from '@clef/shared/utils/role';
import UserProfile, { DataItem } from '../components/UserProfile';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { useDatasetMediaDetailsQuery } from '@/serverStore/dataset';
import PrivateProjectImagePng from '@/images/project/private_project_image.png';
import ProjectFallbackImagePng from '@/images/project/project_fallback_image.png';
import CLEF_PATH from '@/constants/path';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const ActiveProjectsListHeader = () => {
  const styles = useStyles();
  return (
    <Box display={'flex'} flexDirection="row" width={'100%'}>
      <Box flex={4}>
        <Typography className={styles.greyModern600} variant="body_bold">
          {t('Project name')}
        </Typography>
      </Box>
      <Box flex={2}>
        <Typography className={styles.greyModern600} variant="body_bold">
          {t('Owner')}
        </Typography>
      </Box>
      <Box flex={1}>
        <Typography className={styles.greyModern600} variant="body_bold">
          {t('# of Models')}
        </Typography>
      </Box>
      <Box flex={1}></Box>
    </Box>
  );
};

type VirtualizedActiveProjectListProps = {
  data: ActiveProject[];
  onRowClicked: (row: ActiveProject) => void;
};

const ProjectImageCard = (props: { project: ActiveProject }) => {
  const styles = useStyles();
  const { project } = props;
  const { data: mediaDetails } = useDatasetMediaDetailsQuery({
    datasetId: project.datasetId,
    mediaId: project.coverMediaId,
  });

  return (
    <Grid container wrap="nowrap" className={styles.projectCardImageContainer}>
      {project.canAccess ? (
        project?.datasetId && project?.coverMediaId ? (
          <Img
            src={getThumbnail(mediaDetails, 'large')}
            fallbackSrc={mediaDetails?.url}
            className={styles.projectCardImage}
            crossOrigin="use-credentials"
          />
        ) : (
          <img src={ProjectFallbackImagePng} className={styles.projectCardImage} />
        )
      ) : (
        <img src={PrivateProjectImagePng} className={styles.projectCardImage} />
      )}
    </Grid>
  );
};

const VirtualizedActiveProjectList = (props: VirtualizedActiveProjectListProps) => {
  const { data, onRowClicked } = props;
  const users = useTypedSelector(state => state.user.users);
  const pendingUsers = useTypedSelector(state => state.user.pendingUsers);
  const allUsers: DataItem[] = useMemo(() => [...users, ...pendingUsers], [users, pendingUsers]);
  const { data: statsById } = useGetProjectStatsByOrgIdQuery();
  const listHeight = Math.min(minListHeight, data.length * rowHeight);
  const styles = useStyles();
  const deleteActiveProject = useDeleteActiveProjectMutation();

  const RCWindowItem = ({
    data,
    index,
    style,
  }: {
    data: ActiveProject[];
    index: number;
    style: any;
  }) => {
    const activeProject = data[index];
    const ownerProjectRole = activeProject?.usersRoles?.find(user => isProjectOwner(user.role));
    const owner = allUsers.find(user => user.id === ownerProjectRole?.userId);

    return (
      <div key={activeProject.id} style={style}>
        <Box className={styles.activeProjectRowContainer}>
          <Box
            onClick={() => {
              onRowClicked(activeProject);
            }}
            className={styles.activeProjectRow}
          >
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              alignItems="center"
              key={activeProject.id}
            >
              <Box
                flex={4}
                width={'50%'}
                display="flex"
                flexDirection="row"
                justifyContent={'flex-start'}
                alignItems={'center'}
              >
                <ProjectImageCard project={activeProject} />
                <Typography maxWidth={'60%'} variant="body_bold" className={styles.greyModern600}>
                  {activeProject.canAccess ? activeProject.name : t('Private project')}
                </Typography>
              </Box>
              <Box flex={2}>{owner && <UserProfile item={owner} />}</Box>
              <Box flex={1}>
                <Typography variant="body_regular" className={styles.greyModern600}>
                  {statsById?.modelCountByProjectId[activeProject.id] ?? 0}
                </Typography>
              </Box>
              <Box flex={1} display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteActiveProject.mutate({ projectId: activeProject.id });
                  }}
                  color="secondary"
                  id={'remove-active-project'}
                >
                  {t('Remove')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    );
  };

  return (
    <FixedSizeList
      width={'100%'}
      height={listHeight}
      itemSize={rowHeight}
      itemData={data}
      itemCount={data.length}
    >
      {RCWindowItem}
    </FixedSizeList>
  );
};

const ActiveProjectsContent = () => {
  const { data: activeProjects, isLoading } = useGetActiveProjectsQuery();
  const history = useHistory();
  const setSelectedProjectId = useSetSelectedProjectId();
  const orgId = useTypedSelector(state => state.login.user)?.orgId!;

  const { enqueueSnackbar } = useSnackbar();
  const onActiveProjectRowClicked = useCallback(
    async (project: ActiveProject) => {
      if (project.canAccess) {
        await setSelectedProjectId(project.id);
        history.push(`${CLEF_PATH.root}/${orgId}/pr/${project.id}`);
      } else {
        enqueueSnackbar(t('You do not have access to this project'), { variant: 'warning' });
      }
    },
    [history, orgId],
  );

  if (isLoading) {
    return <LoadingProgress />;
  }
  if (activeProjects?.length === 0) {
    return <ActiveProjectsEmptyState />;
  }
  return (
    <>
      <ActiveProjectsListHeader />
      <VirtualizedActiveProjectList
        data={activeProjects ?? []}
        onRowClicked={onActiveProjectRowClicked}
      />
    </>
  );
};

export default ActiveProjectsContent;

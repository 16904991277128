import { MediaId, PaginationOptions } from './basic';
import { FormattedFieldFilterMap, FormattedColumnFilterMap } from './store';
export interface SelectMediaOption {
  selectedMedia: MediaId[];
  unselectedMedia: MediaId[];
  isUnselectMode: boolean;
  fieldFilterMap: FormattedFieldFilterMap;
  columnFilterMap: FormattedColumnFilterMap;
}

export enum AnnotationSortType {
  labelTime = 'labelTime',
  confidence = 'confidence',
}

export interface AnnotationSortOptions extends Partial<PaginationOptions> {
  sortType: AnnotationSortType;
  sortOrder?: 'asc' | 'desc';
}

import UserAPI from '../api/user_api';
import { UserState, UserThunks } from './types';
import { sortByName } from '../utils';
import { ActionType } from './types';
import { User, UserStatus } from '@clef/shared/types';
import { inviteUserApi } from '../hooks/api/useUserApi';

// action types
const LOAD_USERS = 'load_users';
const LOAD_PENDING_USERS = 'load_pending_users';
const ADD_USER = 'add_user';
const UPDATE_PENDING_USER = 'update_pending_user';

const actions: ActionType<UserState> = {};
const initialState: UserState = {
  ldbTasks: [],
  users: [],
  pendingUsers: [],
  fetchUsers: () => {},
  inviteUser: () => {},
};

// @ts-ignore
actions[LOAD_USERS] = (state: UserState, { payload }) => {
  return {
    ...state,
    users: payload,
  };
};

// @ts-ignore
actions[LOAD_PENDING_USERS] = (state: UserState, { payload }) => {
  return {
    ...state,
    pendingUsers: payload,
  };
};

// @ts-ignore
actions[ADD_USER] = (state: UserState, { payload }) => {
  return {
    ...state,
    pendingUsers: state.pendingUsers.concat(payload),
  };
};

// @ts-ignore
actions[UPDATE_PENDING_USER] = (state: UserState, { payload }) => {
  const updatedUsers = state.pendingUsers.map(p => {
    return p.email == payload.email ? payload : p;
  });

  return {
    ...state,
    pendingUsers: updatedUsers,
  };
};

function loadUsers(users: UserState['users']) {
  return {
    type: LOAD_USERS,
    payload: users,
  };
}

function loadPendingUsers(pendingUsers: UserState['pendingUsers']) {
  return {
    type: LOAD_PENDING_USERS,
    payload: pendingUsers,
  };
}

function addUser(pendingUser: UserState['pendingUsers']) {
  return {
    type: ADD_USER,
    payload: pendingUser,
  };
}

function updatePendingUser(pendingUser: UserState['pendingUsers']) {
  return {
    type: UPDATE_PENDING_USER,
    payload: pendingUser,
  };
}

export const fetchUsers: UserThunks['fetchUsers'] = () => {
  return dispatch => {
    Promise.resolve(UserAPI.fetchUsers([])).then(result => {
      if (!result) return;
      const { activeCount, pendingCount, activeUsers, pendingUsers } = result;
      // Sort the user based on the name. 'a' should return
      // before 'Z'
      const activeUsersWithActiveStatus = (activeUsers as User[]).filter(
        user => user.status === UserStatus.Active,
      );
      const inactiveUser = (activeUsers as User[]).filter(
        user => user.status === UserStatus.Inactive,
      );
      if (activeCount !== 0) {
        activeUsersWithActiveStatus.sort(sortByName);
        inactiveUser.sort(sortByName);
        dispatch(loadUsers(activeUsersWithActiveStatus.concat(inactiveUser)));
      } else {
        dispatch(loadUsers([]));
      }

      if (pendingCount !== 0) {
        dispatch(loadPendingUsers(pendingUsers));
      } else {
        dispatch(loadPendingUsers([]));
      }
    });
  };
};

export const inviteUser: UserThunks['inviteUser'] = (email, userRole, projectId?, projectRole?) => {
  return async dispatch => {
    const { pendingUser, isNew } = await inviteUserApi(email, userRole, projectId, projectRole);
    dispatch(isNew ? addUser(pendingUser) : updatePendingUser(pendingUser));
  };
};

// @ts-ignore
export default function reducer(state = initialState, action) {
  // @ts-ignore
  const handler = actions[action && action.type];
  return handler ? handler(state, action) : state;
}

import { EvaluationSetItem } from '@/api/evaluation_set_api';
import { RegisteredModelWithThreshold } from '@/api/model_api';
import { MediaSplitName } from '@/constants/stats_card';

export const getEvaluationSetName = (evaluationSet: EvaluationSetItem) => {
  if (evaluationSet.split) {
    return `${evaluationSet.datasetVersion.name}, #${evaluationSet.split?.splitSetName}`;
  }
  return evaluationSet.datasetVersion.name;
};

export const getTrainDevTestEvaluationSetName = (evaluationSet: EvaluationSetItem) => {
  if (evaluationSet.split?.splitSetName === MediaSplitName.Dev) {
    return t('Dev set');
  } else if (evaluationSet.split?.splitSetName === MediaSplitName.Test) {
    return t('Test set');
  } else if (evaluationSet.split?.splitSetName === MediaSplitName.Train) {
    return t('Train set');
  }
  return t('Unknown');
};

export type ModelComparisonSelectionInfo = {
  baseline?: RegisteredModelWithThreshold;
  candidate?: RegisteredModelWithThreshold;
  evaluationSet?: EvaluationSetItem;
};

export const isSameModelAndThreshold = (
  model1?: RegisteredModelWithThreshold,
  model2?: RegisteredModelWithThreshold,
) => model1 && model2 && model1.id === model2.id && model1.threshold === model2.threshold;

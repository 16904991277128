import { Typography } from '@clef/client-library';
import { Box, makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import OSOption, { ArchitectureOption, getCurrentOS } from './OSOption';

const useStyles = makeStyles(() => ({
  fullWidthToggleButton: {
    flex: 1,
    width: '100%',
  },
  toggleButton: {
    fontFamily: 'Commissioner, sans-serif',
    fontWeight: 700,
    fontSize: 14,
    textTransform: 'none',
    height: 30,
    padding: 0,
  },
  leftTitle: {
    display: 'flex',
    alignItems: 'center',
    flex: '1',
    maxWidth: '85px',
  },
}));

interface ArchSelectorProps {
  onSelect?: (value: string) => void;
}

const ArchSelector: React.FC<ArchSelectorProps> = ({ onSelect }) => {
  const classes = useStyles();
  const [selectedOS, setSelectedOS] = useState<string>(getCurrentOS());
  const [selectedArchitecture, setSelectedArchitecture] = useState<string>();

  const handleOSChange = (_event: React.MouseEvent<HTMLElement>, newOS: string) => {
    if (newOS === selectedOS || isEmpty(newOS)) return;
    setSelectedOS(newOS);
    onSelect && onSelect(newOS);
    if (newOS !== OSOption.Linux) {
      setSelectedArchitecture('');
    }
  };

  const handleArchitectureChange = (
    _event: React.MouseEvent<HTMLElement>,
    newArchitecture: string,
  ) => {
    if (newArchitecture === selectedArchitecture || isEmpty(newArchitecture)) return;
    setSelectedArchitecture(newArchitecture);
    onSelect && onSelect(newArchitecture);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex' }}>
        <Box className={classes.leftTitle}>
          <Typography>{t('OS')}:</Typography>{' '}
        </Box>
        <Box style={{ flex: '1' }}>
          <ToggleButtonGroup
            value={selectedOS}
            onChange={handleOSChange}
            exclusive
            aria-label="Operating System"
            style={{ width: '100%' }}
            size="small"
          >
            <ToggleButton
              classes={{
                root: classes.toggleButton,
              }}
              className={classes.fullWidthToggleButton}
              value={OSOption.Windows}
            >
              {OSOption.Windows}
            </ToggleButton>
            <ToggleButton
              classes={{
                root: classes.toggleButton,
              }}
              className={classes.fullWidthToggleButton}
              value={OSOption.Linux}
            >
              {OSOption.Linux}
            </ToggleButton>
            <ToggleButton
              classes={{
                root: classes.toggleButton,
              }}
              className={classes.fullWidthToggleButton}
              value={OSOption.Mac}
            >
              {OSOption.Mac}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </div>

      {selectedOS === OSOption.Linux && (
        <div style={{ display: 'flex' }}>
          <Box className={classes.leftTitle}>
            <Typography>{t('Architecture')}:</Typography>
          </Box>
          <Box style={{ flex: '1' }}>
            <ToggleButtonGroup
              value={selectedArchitecture}
              onChange={handleArchitectureChange}
              exclusive
              size="small"
              aria-label="Architecture"
              style={{ width: '100%', marginTop: '2px' }}
            >
              <ToggleButton
                classes={{
                  root: classes.toggleButton,
                }}
                className={classes.fullWidthToggleButton}
                value={OSOption.X86_64_ARM64}
                disabled={selectedOS !== OSOption.Linux}
              >
                {ArchitectureOption[OSOption.X86_64_ARM64]}
              </ToggleButton>
              <ToggleButton
                classes={{
                  root: classes.toggleButton,
                }}
                className={classes.fullWidthToggleButton}
                value={OSOption.JetsonJetpack4x}
                disabled={selectedOS !== OSOption.Linux}
              >
                {ArchitectureOption[OSOption.JetsonJetpack4x]}
              </ToggleButton>
              <ToggleButton
                classes={{
                  root: classes.toggleButton,
                }}
                className={classes.fullWidthToggleButton}
                value={OSOption.JetsonJetpack5x}
                disabled={selectedOS !== OSOption.Linux}
              >
                {ArchitectureOption[OSOption.JetsonJetpack5x]}
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </div>
      )}
    </div>
  );
};

export default ArchSelector;

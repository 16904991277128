export const getDateNumber = (date: number | Date | string | undefined | null) => {
  if (!date) {
    return NaN;
  }

  if (typeof date === 'number') {
    return date;
  }

  if (typeof date === 'string') {
    const dateNum = Date.parse(date);

    if (isNaN(dateNum)) {
      return NaN;
    }

    return dateNum;
  }

  return date.getTime();
};

export const getBrowserTimezone = () => {
  const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
  if (timezone) {
    return timezone;
  }

  // Fallback for older browsers
  const tz = new Date().toString().match(/\((.+)\)/)?.[1];

  return tz?.includes(' ')
    ? tz
        .split(' ')
        .map(([first]) => first)
        .join('')
    : tz;
};

import React from 'react';
import { Box } from '@material-ui/core';

import { LabelType } from '@clef/shared/types';

import { useGetSelectedProjectQuery } from '@/serverStore/projects';

import LabelToggles from './LabelToggles';
import LabelTogglesClassification from './LabelTogglesClassification';
import { useStyles } from './styles';

type LabelControlBarProps = {};

const LabelControlBar: React.FC<LabelControlBarProps> = () => {
  const styles = useStyles();

  const { labelType } = useGetSelectedProjectQuery().data ?? {};

  return (
    <Box className={styles.labelControlBar}>
      {(labelType === LabelType.BoundingBox || labelType === LabelType.Segmentation) && (
        <LabelToggles />
      )}
      {labelType === LabelType.Classification && <LabelTogglesClassification />}
    </Box>
  );
};

export default LabelControlBar;

import { ApiErrorType } from '@/api/base_api';
import { ProjectId } from '@clef/shared/types';
import { useQuery } from '@tanstack/react-query';
import { useGetSelectedProjectQuery } from '../projects';
import layout_api, { LayoutListResponse } from '@/api/layout_api';

export const layoutQueryKeys = {
  all: ['layout'] as const,
  list: (projectId: ProjectId) => [projectId, ...layoutQueryKeys.all, 'list'] as const,
};

export const useGetLayoutListQuery = (isEnabled = true) => {
  const { id: projectId = 0 } = useGetSelectedProjectQuery().data ?? {};
  return useQuery<LayoutListResponse, ApiErrorType>({
    queryKey: layoutQueryKeys.list(projectId),
    queryFn: async () => {
      const response = await layout_api.list(projectId);
      return response;
    },
    enabled: !!projectId && isEnabled,
  });
};

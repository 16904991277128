import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useCompressedBitMapDataUrl } from './util';

export interface MediaViewerSegmentationAnnotationProps {
  color: string;
  text?: string;
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
  compressedBitMap: string | OffscreenCanvas;
  scale?: number;
  compress?: boolean;
}

const useStyles = makeStyles(() => ({
  segmentationStyle: {
    position: 'absolute',
    zIndex: 99,
    imageRendering: 'pixelated',
  },
  basicTextStyle: {
    position: 'absolute',
    zIndex: 101,
    fontWeight: 700,
  },
}));

const MediaViewerSegmentationAnnotation: React.FC<MediaViewerSegmentationAnnotationProps> =
  React.memo(({ color, xMin, xMax, yMin, yMax, text, compressedBitMap, scale = 1, compress }) => {
    const styles = useStyles();

    const imgSrc = useCompressedBitMapDataUrl(
      compressedBitMap,
      color,
      xMax - xMin + 1,
      yMax - yMin + 1,
      compress,
    );

    const segmentationStyles: React.CSSProperties = {
      top: yMin,
      left: xMin,
      height: yMax - yMin + 1,
      width: xMax - xMin + 1,
    };
    return (
      <>
        {text && (
          <div
            data-testid="segmentation-label"
            className={styles.basicTextStyle}
            style={{ color, top: yMin - 16 / scale, left: xMin, fontSize: 12 / scale }}
          >
            {text}
          </div>
        )}
        {imgSrc && (
          <img
            data-testid="segmentation-image"
            className={styles.segmentationStyle}
            style={segmentationStyles}
            src={imgSrc}
          />
        )}
      </>
    );
  });

export default MediaViewerSegmentationAnnotation;

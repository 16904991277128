import { BaseAPI } from './base_api';
import {
  Label,
  LabelId,
  MediaId,
  TaskId,
  DatasetId,
  MediaStatusType,
  ApiResponse,
  UpsertLabelsParameters,
  AnnotationWithoutId,
} from '@clef/shared/types';

export interface SuggestLabel {
  id: number | string;
  rangeBox: { xmax: number; xmin: number; ymax: number; ymin: number };
  defectId: number;
  confidence: number;
  minThreshold: number;
  maxThreshold: number;
}
class LabelAPI extends BaseAPI {
  /* --- User Specific results -- */
  async review(
    labelId: LabelId,
    datasetId: DatasetId,
    content: Partial<Pick<Label, 'reviewStatus' | 'reviewNotes'>>,
    prevLabel?: Label,
  ): Promise<
    ApiResponse<{
      changed: boolean;
      label: Label;
      mediaStatus: MediaStatusType;
    }>
  > {
    return this.postJSON(`review`, {
      labelId,
      datasetId,
      prevLabel,
      content,
    });
  }

  /**
   * Batch set media annotation labels
   * @param taskId
   * @param mediaIdToLabels MediaId to label of this media
   */

  async setBatchAnnotationsForMedias(
    taskId: TaskId,
    mediaIdToLabels: Record<MediaId, { id?: number; annotationList: AnnotationWithoutId[] }[]>,
    reviewEdit?: boolean,
  ) {
    return this.postJSON<Record<MediaId, Label[]>>(`task/upsert`, {
      taskId,
      mediaIdToLabels,
      reviewEdit,
    });
  }

  async upsertLabels(params: UpsertLabelsParameters): Promise<ApiResponse<any>> {
    return this.postJSON('upsert', params);
  }

  async fetchModelAssistSuggestions(params: {
    projectId: number;
    mediaId?: number;
    modelId?: string;
  }): Promise<Array<SuggestLabel>> {
    return this.get('suggest', params, true);
  }
}
export default new LabelAPI('labels');

import React from 'react';
import { Defect, MediaStatusType, DatasetGroupOptions } from '@clef/shared/types';
import { useGetDatasetStatsQuery } from '@/serverStore/dataset';
import { useDefectBookEnhancedStyles } from '../defectBookEnhancedStyles';
import { Typography, Card, Grid } from '@material-ui/core';

import RelativeDateTime from '../../../components/RelativeDateTime/RelativeDateTime';
import { getDefectColor } from '../../../utils';

export interface DefectCardViewProps {
  defects: Defect[];
  showArchived: boolean;
  onSelectDefect: (defectIdStr: string) => void;
}

const DefectCardView: React.FC<DefectCardViewProps> = ({
  defects,
  showArchived,
  onSelectDefect,
}) => {
  const { data: defectStats } = useGetDatasetStatsQuery({
    // Approved media
    selectOptions: {
      columnFilterMap: {
        datasetContent: { mediaStatus: { CONTAINS_ANY: [MediaStatusType.Approved] } },
      },
      fieldFilterMap: {},
      selectedMedia: [],
      unselectedMedia: [],
      isUnselectMode: true,
    },
    groupOptions: [DatasetGroupOptions.DEFECT_TYPE],
  });
  const styles = useDefectBookEnhancedStyles();

  return (
    <Grid container alignItems="center" spacing={4}>
      {defects.map(defect => {
        const lastUpdate =
          new Date(defect.updatedAt!) > new Date(defect.createdAt!)
            ? defect.updatedAt!
            : defect.createdAt!;
        const approvedMediaCountWithTheDefect =
          defectStats?.find(stat => stat.defect_id! === defect.id)?.count ?? 0;
        return !defect.isArchived || showArchived ? (
          <Grid item xl={2} lg={3} md={4} sm={6} xs={12} key={defect.id}>
            <Card
              className={styles.defectCard}
              style={{ borderTopColor: getDefectColor(defect) }}
              onClick={() =>
                defect.isArchived !== true ? onSelectDefect(defect.id.toString()) : null
              }
            >
              <Typography variant="h3" className={styles.defectNameBox} gutterBottom>
                {defect.name}
              </Typography>
              <RelativeDateTime prefixText={t('Last updated: ')}>{lastUpdate}</RelativeDateTime>
              <div className={styles.flexGrow}></div>
              <Typography variant="body2" className={styles.approvedMediaCount}>
                {t('{{count}} approved media', {
                  count: <strong>{approvedMediaCountWithTheDefect}</strong>,
                })}
              </Typography>
            </Card>
          </Grid>
        ) : null;
      })}
    </Grid>
  );
};

export default DefectCardView;

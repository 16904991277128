import React, { createContext, useState, useCallback, useContext } from 'react';
import { ButtonProps } from '@material-ui/core';
import ConfirmationDialog from '../../Dialogs/ConfirmationDialog';
import UploadFullscreen from '../../Dialogs/UploadFullscreen';
import LoadSampleData from '../../Dialogs/LoadSampleData';
import { useImmer } from 'use-immer';
import UsageExceedDialog, { ExceedType, UsageExceedDialogProps } from './UsageExceedDialog';

type ShowConfirmationOption = {
  onConfirm: () => void;
  onCancel?: () => void;
  title?: string;
  confirmText: string;
  confirmAllowedCheckbox?: string[];
  content?: string;
  color: ButtonProps['color'];
  disableCancel?: boolean;
  switchButtonPosition?: boolean;
};

export type DialogContextType = {
  showConfirmationDialog: (options: ShowConfirmationOption) => void;
  openUpload: (useWebcam?: boolean) => void;
  closeUpload: () => void;
  openLoadSampleData: () => void;
  showUsageExceedDialog: (exceedType: ExceedType, cost: number, remaining: number) => void;
};

const DialogContext = createContext<DialogContextType>({
  showConfirmationDialog: () => {
    throw Error('showConfirmationDialog function not found!');
  },
  openUpload: () => {
    throw Error('openUpload function not found!');
  },
  closeUpload: () => {
    throw Error('closeUpload function not found!');
  },
  openLoadSampleData: () => {
    throw Error('openLoadSampleData function not found!');
  },
  showUsageExceedDialog: () => {
    throw Error('showUsageExceedDialog function not found!');
  },
});

export const useDialog = (): DialogContextType => useContext(DialogContext);

export const DialogContextProvider: React.FC = ({ children }) => {
  /**
   * Confirmation Dialog
   */
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationDialogProps, setConfirmationDialogProps] = useState<
    ShowConfirmationOption | undefined
  >();
  const showConfirmationDialog = useCallback((options: ShowConfirmationOption) => {
    setConfirmationDialogProps(options);
    setConfirmationDialogOpen(true);
  }, []);

  /**
   * Classification upload media fullscreen dialog
   */
  const [uploadFullscreenOpen, setUploadFullscreenOpen] = useState(false);
  const [useWebcam, setUseWebcam] = useState(false);
  const openUpload = useCallback((useWebcam?: boolean) => {
    setUploadFullscreenOpen(true);
    setUseWebcam(!!useWebcam);
  }, []);
  const closeUpload = useCallback(() => {
    setUploadFullscreenOpen(false);
  }, []);

  /**
   * Load sample data dialog
   */
  const [loadSampleDataOpen, setLoadSampleDataOpen] = useState(false);
  const openLoadSampleData = useCallback(() => {
    setLoadSampleDataOpen(true);
  }, []);

  /**
   * Usage exceed dialog
   */
  const [usageExceedDialogState, dispatchUsageExceedDialogState] = useImmer({
    remaining: -1,
    cost: 0,
  } as UsageExceedDialogProps);

  return (
    <DialogContext.Provider
      value={{
        showConfirmationDialog,
        openUpload,
        closeUpload,
        openLoadSampleData,
        showUsageExceedDialog: (exceedType: ExceedType, cost: number, remaining: number) => {
          dispatchUsageExceedDialogState(draft => {
            draft.exceedType = exceedType;
            draft.cost = cost;
            draft.remaining = remaining;
          });
        },
      }}
    >
      {children}
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onConfirm={() => {
          confirmationDialogProps?.onConfirm();
          setConfirmationDialogOpen(false);
        }}
        onClose={() => {
          confirmationDialogProps?.onCancel && confirmationDialogProps?.onCancel();
          setConfirmationDialogOpen(false);
        }}
        title={confirmationDialogProps?.title ?? ''}
        content={confirmationDialogProps?.content ?? ''}
        confirmText={confirmationDialogProps?.confirmText ?? ''}
        confirmAllowedCheckbox={confirmationDialogProps?.confirmAllowedCheckbox}
        color={confirmationDialogProps?.color}
        disableCancel={confirmationDialogProps?.disableCancel}
        switchButtonPosition={confirmationDialogProps?.switchButtonPosition ?? false}
      />
      <UploadFullscreen
        open={uploadFullscreenOpen}
        onClose={() => setUploadFullscreenOpen(false)}
        initialUseWebcam={useWebcam}
      />
      <LoadSampleData open={loadSampleDataOpen} onClose={() => setLoadSampleDataOpen(false)} />
      <UsageExceedDialog
        {...usageExceedDialogState}
        onClose={() => {
          dispatchUsageExceedDialogState(draft => {
            draft.exceedType = undefined;
          });
        }}
        onConfirm={() => {
          dispatchUsageExceedDialogState(draft => {
            draft.exceedType = undefined;
          });
          setUploadFullscreenOpen(false);
        }}
      />
    </DialogContext.Provider>
  );
};

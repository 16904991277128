import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  emptyStateContainer: {
    height: '70vh',
  },
  emptyStateImage: {
    marginBottom: theme.spacing(5),
  },
  emptyStateText: {
    marginBottom: theme.spacing(5),
  },
  emptyStateDownloadSampleDataButton: {
    marginRight: theme.spacing(5),
  },
}));

import React from 'react';
import { Box, LinearProgress, makeStyles, withStyles } from '@material-ui/core';
import { Typography } from '@clef/client-library';

const BorderLinearProgress = withStyles(theme => ({
  root: {
    width: 200,
    height: 8,
    borderRadius: 4,
    flex: 1,
    marginRight: theme.spacing(2),
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[200],
  },
  bar: {
    borderRadius: 4,
  },
  barColorPrimary: {
    backgroundColor: theme.palette.blue[600],
  },
  barColorSecondary: {
    backgroundColor: theme.palette.red[500],
  },
}))(LinearProgress);

interface UsageProgressBarProps {
  usage: number;
  limit: number;
}

const useStyles = makeStyles(theme => ({
  progressValue: {
    color: theme.palette.grey[800],
    fontSize: 14,
    paddingRight: theme.spacing(1),
  },
}));

const UsageProgressBar = (props: UsageProgressBarProps) => {
  const { usage, limit } = props;
  const styles = useStyles();
  const creditUsagePercent = Math.min(100, ((usage ?? 0) / (limit || 1)) * 100);
  if (limit === -1) {
    return null;
  }
  return (
    <Box display="flex" alignItems="center" marginTop={1}>
      <BorderLinearProgress
        variant="determinate"
        value={creditUsagePercent}
        color={creditUsagePercent === 100 ? 'secondary' : 'primary'}
      />
      <Typography className={styles.progressValue}>
        {usage}/{limit}
      </Typography>
    </Box>
  );
};

export default UsageProgressBar;

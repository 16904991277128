const DURATION_LONG = 5000;
const DURATION_SHORT = 3000;

const LEVEL_WARNING = 'warning';
const LEVEL_ERROR = 'error';
const LEVEL_SUCCESS = 'success';

function getConfig(duration: number, level: string): object {
  return {
    variant: level,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    autoHideDuration: duration,
    preventDuplicate: true,
  };
}

export const LONG_WARNING = getConfig(DURATION_LONG, LEVEL_WARNING);
export const SHORT_ERROR = getConfig(DURATION_SHORT, LEVEL_ERROR);
export const SHORT_SUCCESS = getConfig(DURATION_SHORT, LEVEL_SUCCESS);

import React from 'react';
import cx from 'classnames';
import { Box, Chip, Typography, makeStyles } from '@material-ui/core';
import { useGetDatasetStatsQuery } from '@/serverStore/dataset';
import { DatasetGroupOptions, DatasetVersion } from '@clef/shared/types';
import { BarChartHorizontal } from '@clef/client-library';
import { MediaAllSplitName } from '@/constants/stats_card';
import { useFormattedSplitDefectsForChart } from '@/components/StatsCharts/useFormattedSplitDefectsForChart';
import LoadingProgress from '../LoadingProgress';

const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  unselectedChip: {
    backgroundColor: theme.palette.greyModern[100],
    '&:focus': {
      backgroundColor: theme.palette.greyModern[100],
    },
  },
  selectedChip: {
    backgroundColor: theme.palette.purple[100],
    color: theme.palette.purple[500],
    '&:focus': {
      backgroundColor: theme.palette.purple[100],
      color: theme.palette.purple[500],
    },
  },
  chipText: {
    fontWeight: 'bold',
  },
  classDistributionChartContainer: {
    paddingTop: theme.spacing(5),
  },
  classDistributionText: {
    fontWeight: 'bold',
    paddingBottom: theme.spacing(5),
  },
}));

export type DataSnapshotStatProps = {
  datasetVersion: DatasetVersion;
  selectedSplitName: string;
  setSelectedSplitName: (splitName: string) => void;
  splitStatsFormatted: { name: string; value: number }[];
};

const DataSnapshotStat: React.FC<DataSnapshotStatProps> = props => {
  const { datasetVersion, selectedSplitName, setSelectedSplitName, splitStatsFormatted } = props;
  const styles = useStyles();
  //   const allDefects = useDefectSelector();
  const { data: mediaDefectDistribution, isLoading: isMediaDefectDistributionLoading } =
    useGetDatasetStatsQuery({
      version: datasetVersion.version,
      groupOptions: [DatasetGroupOptions.DEFECT_DISTRIBUTION, DatasetGroupOptions.SPLIT],
    });

  const { totalDefectsFormatted, defectColorMap } = useFormattedSplitDefectsForChart(
    mediaDefectDistribution,
    true,
  );

  const selectedSplitDefects = totalDefectsFormatted.find(
    split => split.name === selectedSplitName,
  );
  const selectedDefectsFormatted = selectedSplitDefects
    ? selectedSplitDefects.distributions.map(defect => {
        return {
          name: defect.distributor,
          value: defect.value,
          color: defectColorMap[defect.distributor],
        };
      })
    : [];

  return isMediaDefectDistributionLoading ? (
    <LoadingProgress />
  ) : (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" flexDirection="row" width="100%" flexWrap="wrap">
        {splitStatsFormatted.map(split => {
          return (
            <Chip
              onClick={() => setSelectedSplitName(split.name)}
              key={split.name}
              label={
                <Typography className={styles.chipText}>{`${
                  split.name === MediaAllSplitName ? t('All Images') : split.name
                } (${split.value})`}</Typography>
              }
              className={cx(
                styles.chip,
                selectedSplitName === split.name ? styles.selectedChip : styles.chip,
              )}
            />
          );
        })}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        className={styles.classDistributionChartContainer}
      >
        <Typography className={styles.classDistributionText}>{t('Class distribution')}</Typography>
        <BarChartHorizontal chartData={selectedDefectsFormatted} oneline={true} />
      </Box>
    </Box>
  );
};

export default DataSnapshotStat;

import React from 'react';

import { tealScale, yellowScale, purpleScale } from '@clef/client-library';

import { TransformUISchema } from '../types/client';

export const DEFAULT_PIPELINE_TRANSFORMS = [
  {
    id: '25fcedd2-e310-9cb0-385b-e66533afa891',
    params: [
      { name: 'p', value: 1 },
      { name: 'height', value: 512 },
      { name: 'width', value: 512 },
      { name: 'always_apply', value: false },
    ],
  },
];

export const AUTO_RESIZE_TRANSFORM = {
  id: 'autoResize',
  name: 'AutoResize',
  category: 'Spatial level',
  description: `Auto-resize dynamically calculates optimal image size
  during training to optimize model training time. It won't
  be visible below because it's calculated during training`,
  paramsDescription: {
    estimate: {
      name: 'Defect shortest side pixel size',
      description: `Give best estimate about your defect size. For the smallest visible defect,
      how many pixels does its shortest side has? We will auto-resize based on that pixel size.`,
      jsonSchema: {
        type: 'number',
        default: 50,
        minimum: 0,
      },
      isRequired: false,
      category: 'generic',
    },
  },
};

export const SEGMENTATION_INPUT_MULTIPLE = 32;

export const DEFAULT_TRANSFORM_DIMENSIONS = {
  width: 512,
  height: 512,
};

export const GRID_SIZE = 20;

export const TRANSFORMS_UI_SCHEMA: Array<TransformUISchema> = [
  {},
  {
    ResizeOptions: {
      name: 'Resize',
      label: 'Resize',
      isAdded: false,
      showCarousel: false,
      probability: false,
      hasRangeSlider: false,
      showCropTool: false,
    },
    Crop: {
      name: 'Crop',
      label: 'Crop',
      isAdded: false,
      showCarousel: true,
      probability: false,
      hasRangeSlider: false,
      showCropTool: true,
    },
    AutoResize: {
      name: 'AutoResize',
      label: 'Auto-resize',
      isAdded: false,
      showCarousel: false,
      probability: false,
      hasRangeSlider: false,
      isResizeOption: true,
      estimate: true,
    },
    Resize: {
      name: 'Resize',
      label: 'Manual resize',
      isAdded: false,
      showCarousel: false,
      probability: false,
      hasRangeSlider: false,
      isResizeOption: true,
    },
    RescaleWithPadding: {
      name: 'RescaleWithPadding',
      label: 'Rescale with padding',
      isAdded: false,
      showCarousel: false,
      probability: false,
      hasRangeSlider: false,
      isResizeOption: true,
    },
  },
  {
    RandomBrightness: {
      name: 'RandomBrightness',
      label: 'Random Brightness',
      isAdded: false,
      showCarousel: true,
      probability: true,
      hasRangeSlider: true,
    },
    Blur: {
      name: 'Blur',
      label: 'Blur',
      isAdded: false,
      showCarousel: true,
      probability: true,
      hasRangeSlider: true,
    },
    MotionBlur: {
      name: 'MotionBlur',
      label: 'Motion Blur',
      isAdded: false,
      showCarousel: true,
      probability: true,
      hasRangeSlider: true,
    },
    GaussianBlur: {
      name: 'GaussianBlur',
      label: 'Gaussian Blur',
      isAdded: false,
      showCarousel: true,
      probability: true,
      hasRangeSlider: true,
    },
    HueSaturationValue: {
      name: 'HueSaturationValue',
      label: 'Hue Saturation Value',
      isAdded: false,
      showCarousel: true,
      probability: true,
      hasRangeSlider: true,
    },
    RandomContrast: {
      name: 'RandomContrast',
      label: 'Random Contrast',
      isAdded: false,
      showCarousel: true,
      probability: true,
      hasRangeSlider: true,
    },
    HorizontalFlip: {
      name: 'HorizontalFlip',
      label: 'Horizontal Flip',
      isAdded: false,
      showCarousel: true,
      probability: true,
      hasRangeSlider: false,
    },
    VerticalFlip: {
      name: 'VerticalFlip',
      label: 'Vertical Flip',
      isAdded: false,
      showCarousel: true,
      probability: true,
      hasRangeSlider: false,
    },
    RandAugment: {
      name: 'RandAugment',
      label: 'Random Augment',
      isAdded: false,
      showCarousel: false,
      probability: true,
      hasRangeSlider: false,
      hasSingleSlider: true,
    },
    Rotate: {
      name: 'Rotate',
      label: 'Random Rotate',
      isAdded: false,
      showCarousel: true,
      probability: true,
      hasRangeSlider: true,
      hasSingleSlider: false,
      hasDropdown: true,
    },
  },
  {},
];

type TransformTextItem = {
  tooltip?: string;
  description?: string;
};

// TODO: Ideally we should update the /transforms response. Adding here to move fast.
export const TRANSFORM_TEXTS: Record<string, TransformTextItem | undefined> = {
  RescaleWithPadding: {
    tooltip: t(
      'Images will be rescaled to the entered dimensions, padding will fill the sides of the image, and the original aspect ratio will be kept.',
    ),
    description: t(
      `Enter the dimensions that you want images rescaled to. If the dimensions don't maintain the original aspect ratio, LandingLens adds padding to the either the sides or top and bottom. For example, if the original image is 1200x800 (4:3) and you rescale it to 512x512 (1:1), LandingLens changes the dimensions to 512x341. Then it adds 171 pixels of padding total to the top and bottom (512 - 341 = 171).`,
    ),
  },
  Resize: {
    tooltip: t(
      'Images will be rescaled to the entered dimensions. The original aspect ratio will not be kept. If you want to preserve the original aspect ratio, use Rescale with Padding. {{note}}: If you have large images that include small objects to detect, make the images larger so the objects are clearly visible.',
      { note: <strong>{t('Note')}</strong> },
    ),
    description: t(
      'Enter the dimensions you want your images to be when you train the model. For example, if the original images are 1200x800 and the Resize is 512x512, all images will be resized into square images, even though the original images were in a 4:3 ratio. If you want to preserve the original aspect ratio, use Rescale with Padding.',
    ),
  },
  Crop: {
    tooltip: t(
      'Images will be cropped to the entered dimensions. {{note}}: Resize images before you crop them. This will help ensure that all data is consistently sized to prevent accidentally cropping outside the image boundaries of small images.',
      { note: <strong>{t('Note')}</strong> },
    ),
    description: t(
      'Manually crop all images in your dataset by clicking and dragging the sides of the box to create the crop. {{note}}: Resize images before you crop them. This will help ensure that all data is consistently sized to prevent accidentally cropping outside the image boundaries of small images.',
      { note: <strong>{t('Note')}</strong> },
    ),
  },
  RandomBrightness: {
    tooltip: t(
      'The brightness of the images will fluctuate according to the set brightness range.',
    ),
    description: t('Apply a brightness range for the possibility of images randomly brightening.'),
  },
  Blur: {
    tooltip: t('Images may have a heavy blur applied according to the set blur range.'),
    description: t('Apply a blur range for the possibility of images having a blur effect.'),
  },
  MotionBlur: {
    tooltip: t(
      'Images may have a blur applied to make them look in motion according to the set blur range.',
    ),
    description: t('Apply a blur range for the possibility of images appearing in motion.'),
  },
  GaussianBlur: {
    tooltip: t('Images may have a natural blur applied according to the set blur range.'),
    description: t(
      'Apply a blur range  for the possibility of images appearing in a natural blur.',
    ),
  },
  HueSaturationValue: {
    tooltip: t(
      'The color intensity of the images may change according to the set hue saturation value range.',
    ),
    description: t(
      'Apply ranges for the possibility of a change in the color intensity of images.',
    ),
  },
  RandomContrast: {
    tooltip: t('The tone of the images may randomly change according to the set range.'),
    description: t(
      'Apply a random contrast range for the possibility of a random change in the tone of images.',
    ),
  },
  HorizontalFlip: {
    tooltip: t('Images may flip 180 degrees left or right.'),
    description: t('Apply for a chance that images will rotate 180 degrees left or right.'),
  },
  VerticalFlip: {
    tooltip: t('Images may rotate 180 degrees up or down.'),
    description: t('Apply for a chance that images will rotate 180 degrees up or down.'),
  },
  RandAugment: {
    tooltip: t(''),
    description: t('Apply for a chance of any augmentation to be applied to images at random.'),
  },
  Rotate: {
    tooltip: t(''),
    description: t(
      'Apply a rotate range for the possibility that the orientation of images will randomly change.',
    ),
  },
  // == transform params ==
  magnitude: {
    tooltip: t(
      'Select how strong the applied transform will be, where 1 is weak and 10 is strong.',
    ),
  },
  interpolation: {
    tooltip: t('Use this setting to resize images.'),
  },
  border_mode: {
    tooltip: t('Use this setting to create a border around the image, like a photo frame.'),
  },
  // == hyper params ==
  'backboneParams.name': {
    tooltip: t(
      `{{medium}}: Capture more complex patterns in the training data. Training and running inferences will take longer.{{break}}
    {{small}}: Train and run inferences faster.`,
      {
        medium: <strong>{t('Medium Models')}</strong>,
        small: <strong>{t('Small Models')}</strong>,
        break: <br />,
      },
    ),
    description: t(
      `Select the size of the model's capacity. Model capacity refers to the model's ability to learn complex patterns in data.`,
    ),
  },
  'learningParams.epochs': {
    tooltip: t(
      `When your Model trains, it works through your dataset multiple times. The completion of one cycle is called an "epoch".`,
    ),
    description: t(`Enter the number of cycles you want the model to perform in this field.`),
  },
};

// Going forward, these should not be used, since each model can have
// different constraints. Rather, we should use the response from backend,
// as shown in packages/client/src/pages/DataBrowser/TrainModelButtonGroup/state.tsx.
export const MIN_LABELED_MEDIA_FOR_FAST_N_EASY_TRAIN = 10;
export const MAX_LABELED_MEDIA_FOR_FAST_N_EASY_TRAIN = 1000;
export const MIN_CLASSIFICATION_DEFECTS_FOR_FAST_N_EASY_TRAIN = 2;

export const customTrainingPath = '/data/custom_training';

export const customTrainSplitChartMap: { [key: string]: string } = {
  train: tealScale[300] ?? '#5FE9D0',
  dev: yellowScale[300] ?? '#FDE172',
  test: purpleScale[500] ?? '#7A5AF8',
};

export enum TrainingStepType {
  Snapshot = 'snapshot',
  Provision = 'provision',
  Train = 'train',
  Calculation = 'calculation',
}

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  topProjectsContent: {
    width: '70%',
  },
  topProjectsValue: {
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.blue[500],
    marginRight: theme.spacing(4),
  },
  label: {
    width: 120,
    color: theme.palette.grey[600],
    fontSize: 14,
    flex: '0 0 120px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginRight: theme.spacing(2),
  },
  topValueItem: {
    marginBottom: theme.spacing(2),
  },
  usageBox: {
    flex: 1,
  },
  noData: {
    height: 70,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

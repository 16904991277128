import { Box, Checkbox, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ContactUsDialog from '@/pages/OrgnizationSettings/components/ContactUsDialog';

const useLocalStyles = makeStyles(theme => ({
  infoBlock: {
    width: '100%',
    backgroundColor: theme.palette.blue[50],
    borderRadius: theme.spacing(2),
  },
  infoIcon: {
    marginRight: theme.spacing(2),
  },
  checkBoxContainer: {
    position: 'relative',
    left: -theme.spacing(3),
  },
  boldText: {
    fontWeight: 'bold',
  },
  contactUsText: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.blue[500],
    fontWeight: 'bold',
  },
}));

type NonCommercialUseOnlyBlockProps = {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
};

const NonCommercialUseOnlyBlock = (props: NonCommercialUseOnlyBlockProps) => {
  const styles = useLocalStyles();
  const { checked, onCheckedChange } = props;
  const [contactUsDialogOpen, setContactUsDialogOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckedChange(event.target.checked);
  };
  return (
    <>
      <ContactUsDialog
        open={contactUsDialogOpen}
        onClose={() => {
          setContactUsDialogOpen(false);
        }}
      />
      <Box padding={5} className={styles.infoBlock}>
        <Box
          paddingBottom={2}
          display="flex"
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'flex-start'}
        >
          <InfoOutlinedIcon className={styles.infoIcon} />
          <Typography className={styles.boldText}>{t('Noncommercial use only')}</Typography>
        </Box>
        <Typography>
          {t(
            'Models can only be downloaded for noncommercial use. {{contactUsText}} for commercial inquiries.',
            {
              contactUsText: (
                <Typography
                  component="span"
                  onClick={() => {
                    setContactUsDialogOpen(true);
                  }}
                  className={styles.contactUsText}
                >
                  {t('Contact us')}
                </Typography>
              ),
            },
          )}
        </Typography>
        <Box
          className={styles.checkBoxContainer}
          display="flex"
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'flex-start'}
        >
          <Checkbox color="primary" checked={checked} onChange={handleChange}></Checkbox>
          <Typography>{t('I confirm the models will not be used for commercial use.')}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default NonCommercialUseOnlyBlock;

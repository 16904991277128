import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import subscription_api from '@/api/subscription_api';
import { IconButton } from '@clef/client-library';
import { useSnackbar } from 'notistack';
import { useAppDispatch } from '@/store';
import { fetchOrgUserProfile } from '@/store/newLoginState/actions';
import Check from '@material-ui/icons/Check';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { usageQueryKey } from '@/serverStore/usage';
import { queryClient } from '@/serverStore';

const useStyles = makeStyles(theme => ({
  invalidDateMessage: {
    color: theme.palette.error.main,
  },
}));

export type TestClockProps = {};

const TestClock: React.FC<TestClockProps> = () => {
  const styles = useStyles();
  const { currentTime } = useTypedSelector(state => state.login.user) ?? {};
  const orgId = useTypedSelector(state => state.login.user)?.orgId!;
  const finalCurrentTime = currentTime
    ? new Date(currentTime).toISOString()
    : new Date().toISOString();
  const { enqueueSnackbar } = useSnackbar();
  const [newDate, setNewDate] = useState(finalCurrentTime);
  useEffect(() => {
    setNewDate(finalCurrentTime);
  }, [finalCurrentTime]);
  const [changing, setChanging] = useState(false);
  const dispatch = useAppDispatch();
  const invalidDate = new Date(newDate).getTime() < new Date(finalCurrentTime).getTime();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box marginY={4} display="flex" alignItems="center" flexWrap="nowrap">
        <Box marginRight={4}>{t('Set test clock:')}</Box>
        <DateTimePicker
          autoOk
          inputVariant="outlined"
          value={newDate}
          disablePast
          // @ts-ignore
          onChange={newDate => setNewDate(newDate.toISOString())}
        />
        {newDate !== finalCurrentTime && !invalidDate && (
          <IconButton
            id="confirm-change-test-clock"
            disabled={changing}
            onClick={async () => {
              setChanging(true);
              try {
                await subscription_api.setTestClock(newDate);
                await dispatch(fetchOrgUserProfile()).unwrap();
                orgId && queryClient.invalidateQueries(usageQueryKey.summary(orgId));
                subscription_api.getBillingDetails();
                subscription_api.getInvoiceHistory();
              } catch (e) {
                enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
              }
              setChanging(false);
            }}
          >
            {changing ? <CircularProgress size={20} /> : <Check />}
          </IconButton>
        )}
      </Box>
      {invalidDate && (
        <Box paddingX={4} className={styles.invalidDateMessage}>
          {t('New date should not be earlier than {{time}}', { time: finalCurrentTime })}
        </Box>
      )}
    </MuiPickersUtilsProvider>
  );
};

export default TestClock;

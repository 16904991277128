import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography, Grid, LinearProgress, DialogContent, Box, Tooltip } from '@material-ui/core';
import { Button, IconButton, SelectOptions } from '@clef/client-library';
import AddIcon from '@material-ui/icons/Add';
import {
  generateUploadedMessage,
  generateUploadingMessage,
  getUploadStats,
} from '../../../../store/uploadState/utils';
import { useNewUploadStyles, useUploadStyles } from '../styles';
import { useAppDispatch } from '../../../../store';
import { Alert } from '@material-ui/lab';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useGetProjectSplitQuery, useGetSelectedProjectQuery } from '@/serverStore/projects';
import { UploadStage } from '../../../../store/uploadState/types';
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded';
import { resetUploadState, addMetadata, addSplit } from '../../../../store/uploadState';
import PreviewMediaSection from './PreviewMediaSection';
import PreviewMedia from './PreviewMedia';
import { useDialog } from '../../../Layout/components/useDialog';
import {
  Metadata,
  MetadataFormattedValue,
  MetadataType,
  MetadataValueType,
} from '@clef/shared/types';
import { useMetadataApi } from '../../../../hooks/api/useMetadataApi';
import { Dropdown } from '@clef/client-library';
import SetMetadataDropdown from '../../../SetMetadata/SetMetadataDropdown';
import { pairMediaLabelFiles } from '../../../../utils';
import { omit } from 'lodash';
import { MAX_PREVIEW_IMAGES } from '../constants';
import MaxPreviewImagesIcon from './MaxPreviewImagesIcon';
import { useHistory } from 'react-router-dom';
import CLEF_PATH from '../../../../constants/path';
import { NO_SPLIT_DISPLAY_NAME } from '@clef/shared/constants';
import { statsCardDataKeyCompareFn } from '@/utils';
import { useIsShowUploadLimitDialog } from '../../../../pages/DataBrowser/utils';
import { addSkipDialogWithId, SkipDialogKey } from '../../../../utils/train';
import UploadLimitDialog from '../../../../pages/DataBrowser/UploadLimitDialog';
import { useUploadMediaMutation } from '@/serverStore/upload';
import { UploadAddTagButton } from '@/components/UploadAddTagButton';
import { TagChip } from '@/components/AddTagDropdown/TagChip';

export interface UploadWrapperProps {
  handleDisableEscapeKeyDown?: (disableEscapeKeyDown: boolean) => void;
  onClose?: () => void;
}

const UploadWrapper: React.FC<UploadWrapperProps> = ({
  children,
  handleDisableEscapeKeyDown,
  onClose,
}) => {
  const styles = useUploadStyles();
  const newStyles = useNewUploadStyles();
  const dispatch = useAppDispatch();
  const { classifiedFolders, uploadData, uploadStage, metadata, split } = useTypedSelector(
    state => state.uploadState,
  );
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { successCount, retryableCount, duplicatedCount } = getUploadStats(uploadData);

  const uploadImages = useUploadMediaMutation(
    !!classifiedFolders.length,
    true, // isUsedInUploadWrapper
  );
  const uploadMedia = useUploadMediaMutation();
  const [allMetadata] = useMetadataApi(selectedProject?.id);
  const [metadataValuesMap, setMetadataValuesMap] = useState<MetadataFormattedValue>({});
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const history = useHistory();
  const isShowUploadLimitDialog = useIsShowUploadLimitDialog();
  const [openUploadLimitDialog, setOpenUploadLimitDialog] = useState(false);
  const { data: projectSplits = [] } = useGetProjectSplitQuery();
  const projectSplitNames = projectSplits.map(p => p.splitSetName);
  const projectSplitNameToId = projectSplits.reduce((obj: Record<string, number>, projectSplit) => {
    obj[projectSplit.splitSetName] = projectSplit.id;
    return obj;
  }, {});
  // no split will use 0 as the value
  projectSplitNameToId[NO_SPLIT_DISPLAY_NAME] = 0;

  const UploadInProgressHeader = useMemo(
    () => (
      <div className={styles.headerContainer}>
        <Typography variant="h1" align="center" className={styles.headerText}>
          {t('Uploading Images...')}
        </Typography>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="body1">{generateUploadingMessage(uploadData)}</Typography>
          {/* <Button color="secondary">{t('Cancel Upload')}</Button> */}
        </Grid>
        <LinearProgress
          value={(successCount / uploadData.length) * 100}
          variant="determinate"
          className={styles.uploadProgress}
        />
      </div>
    ),
    [styles, uploadData, successCount],
  );

  const reformatMetaDataValue = (
    metadata: Metadata,
    value: string[] | number[],
  ): MetadataValueType => {
    let valueReformatted: MetadataValueType;
    if (metadata.type === MetadataType.Boolean) {
      valueReformatted = value?.[0] === t('true');
    } else if (!metadata.allowMultiple) {
      valueReformatted = metadata.type === MetadataType.Number ? Number(value?.[0]) : value?.[0];
    } else {
      valueReformatted =
        metadata.type === MetadataType.Number ? (value as string[]).map(val => Number(val)) : value;
    }
    return valueReformatted;
  };

  const onUploadImagesCallback = useCallback(() => {
    onClose?.();
    uploadImages.mutate();
  }, [onClose, uploadImages.mutateAsync]);

  const onUploadImages = useCallback(() => {
    if (isShowUploadLimitDialog && selectedProject?.orgId) {
      setOpenUploadLimitDialog(true);
      addSkipDialogWithId(SkipDialogKey.Upload, selectedProject.orgId);
    } else {
      onUploadImagesCallback();
    }
  }, [isShowUploadLimitDialog, onUploadImagesCallback, selectedProject?.orgId]);

  const UploadButtonContent = useMemo(() => {
    if (uploadData.length) {
      return (
        <Box
          className={styles.uploadButtonWrapper}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          marginBottom={4}
        >
          <Box className={newStyles.additionalInfoChips}>
            <Box className={newStyles.additionalInfoChipsTitle}>{t('Metadata: ')}</Box>
            <Box className={newStyles.chipsContainer}>
              {allMetadata &&
                metadata &&
                Object.entries(metadata).map(([metadataId, metadataValue]) => (
                  <TagChip
                    key={metadataId}
                    name={`${allMetadata![metadataId]!.name} : ${
                      typeof metadataValue === 'boolean' ? t(String(metadataValue)) : metadataValue
                    }`}
                    onDelete={() => {
                      const clonedMetadataValuesMap = omit(metadataValuesMap);
                      delete clonedMetadataValuesMap[metadataId];
                      setMetadataValuesMap(clonedMetadataValuesMap);
                    }}
                  />
                ))}
              {allMetadata && !!Object.keys(allMetadata).length ? (
                <Dropdown
                  classes={{ selector: styles.metadataButtonDropdown }}
                  extraGutter={{ vertical: 8 }}
                  dropdown={
                    <SetMetadataDropdown
                      setMetadataAction={metadata => async value => {
                        // @ts-ignore: for metadata that does not allow multiple, extract the first value
                        value = reformatMetaDataValue(metadata, value);
                        setMetadataValuesMap(prev => ({ ...prev, [metadata.id]: value }));
                      }}
                    />
                  }
                  onClose={() => {
                    handleDisableEscapeKeyDown?.(false);
                  }}
                >
                  {!metadata || Object.keys(metadata).length === 0 ? (
                    <Button
                      variant="contained"
                      size="small"
                      id="attach-metadata-to-images-button"
                      className={newStyles.addTagButton}
                      onClick={() => {
                        handleDisableEscapeKeyDown?.(true);
                      }}
                    >
                      {t('Add metadata')}
                    </Button>
                  ) : (
                    <IconButton
                      size="small"
                      className={newStyles.addTagButton}
                      id="attach-metadata-to-images-icon-button"
                      onClick={() => handleDisableEscapeKeyDown?.(true)}
                    >
                      <AddIcon className={newStyles.addTagIcon} />
                    </IconButton>
                  )}
                </Dropdown>
              ) : (
                <Tooltip
                  arrow
                  interactive
                  placement="top"
                  PopperProps={{
                    disablePortal: true,
                  }}
                  title={
                    <div>
                      {t('No metadata is set up, to create new one, {{link}}.', {
                        link: (
                          <a
                            className={styles.setMetadataBtn}
                            onClick={e => {
                              e.preventDefault();
                              onClose?.();
                              history.push(CLEF_PATH.data.metadata);
                            }}
                          >
                            {t('click here')}
                          </a>
                        ),
                      })}
                    </div>
                  }
                >
                  <span>
                    <Button
                      variant="contained"
                      size="small"
                      id="attach-metadata-to-images-button"
                      disabled
                      className={newStyles.addTagButton}
                    >
                      {t('Add metadata')}
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Box>
          </Box>
          <Box flexShrink={0} marginLeft={4}>
            <Button
              color="primary"
              variant="contained"
              id="upload-dialog-upload-button"
              disabled={!uploadData.length}
              data-testid="data_upload_files"
              onClick={onUploadImages}
            >
              {t('Upload {{count}} Image(s)', {
                count: uploadData.length,
              })}
            </Button>
          </Box>
        </Box>
      );
    }
    return null;
  }, [
    uploadData.length,
    styles.uploadButtonWrapper,
    styles.metadataButtonDropdown,
    styles.setMetadataBtn,
    newStyles.additionalInfoChips,
    newStyles.additionalInfoChipsTitle,
    newStyles.chipsContainer,
    newStyles.addTagButton,
    newStyles.addTagIcon,
    allMetadata,
    metadata,
    onUploadImages,
    metadataValuesMap,
    handleDisableEscapeKeyDown,
    history,
  ]);

  const shouldDisableSplit =
    uploadData.length === 0 ||
    uploadData.some(
      data =>
        !data.initialLabel?.segMask &&
        !data.initialLabel?.objectDetection &&
        !data.initialLabel?.unlabeledAsNothingToLabel &&
        !data.initialLabel?.classification &&
        !data.classifiedFolder,
    );

  const splitDropdown = (
    <SelectOptions
      size="medium"
      disabled={shouldDisableSplit}
      options={projectSplitNames
        .sort((a, b) => statsCardDataKeyCompareFn(a, b))
        .concat(NO_SPLIT_DISPLAY_NAME)}
      value={split ?? NO_SPLIT_DISPLAY_NAME}
      onChange={value => {
        dispatch(addSplit(value as string));
      }}
    />
  );

  const UploadDropzoneHeader = useMemo(
    () => (
      <div className={styles.headerContainer}>
        {children}
        {Boolean(uploadData.length) && (
          <Box className={newStyles.additionalInfoChips}>
            <Box className={newStyles.additionalInfoChipsTitle} display="flex" alignItems="center">
              <Typography>{t('Split: ')}</Typography>
            </Box>
            {shouldDisableSplit ? (
              <Tooltip
                placement="top"
                arrow
                interactive
                PopperProps={{
                  disablePortal: true,
                }}
                title={t('You can only assign split on labeled images')}
              >
                <span>{splitDropdown}</span>
              </Tooltip>
            ) : (
              splitDropdown
            )}
          </Box>
        )}
        {/* Media count and upload button */}
        {UploadButtonContent}
        {Boolean(uploadData.length) && (
          <UploadAddTagButton handleDisableEscapeKeyDown={handleDisableEscapeKeyDown} />
        )}
        {!!uploadData.length && (
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="body1">
              {t('{{count1}} Image(s) Ready for Upload', {
                count1: <strong>{uploadData.length}</strong>,
              })}
            </Typography>
          </Grid>
        )}
      </div>
    ),
    [
      styles.headerContainer,
      children,
      projectSplitNames,
      split,
      uploadData.length,
      UploadButtonContent,
    ],
  );

  const { showConfirmationDialog } = useDialog();

  const UploadMoreMediaButton = useMemo(
    () => (
      <Button
        variant="text"
        color="primary"
        id="upload-more-images-button"
        onClick={() => {
          if (retryableCount > 0) {
            showConfirmationDialog({
              title: t('Ignore failed images?'),
              content: t('There are images not uploaded successfully'),
              onConfirm: () => {
                dispatch(resetUploadState());
              },
              confirmText: t('Yes, ignore'),
              color: 'primary',
            });
          } else {
            dispatch(resetUploadState());
            if (metadata) {
              dispatch(addMetadata(metadata));
            }
          }
        }}
      >
        {t('Upload More Images')}
      </Button>
    ),
    [dispatch, metadata, retryableCount, showConfirmationDialog],
  );

  const RetryUploadButton = useMemo(
    () => (
      <Button color="primary" id="re-upload-image" onClick={() => uploadMedia.mutate()}>
        {t('Retry upload {{count}} image(s)', { count: retryableCount })}
      </Button>
    ),
    [retryableCount, uploadMedia.mutate],
  );

  const UploadSuccessHeader = useMemo(
    () => (
      <div className={styles.headerContainer}>
        <Typography variant="h1" align="center" className={styles.headerText}>
          <CheckCircleRounded className={styles.uploadSuccessIcon} />
          {t('Success!')}
          {UploadMoreMediaButton}
        </Typography>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="body1">
            {t('Uploaded {{count}} / {{total}} image(s)', {
              count: <strong>{successCount}</strong>,
              total: <strong>{uploadData.length}</strong>,
            })}
          </Typography>
        </Grid>
        <LinearProgress value={100} variant="determinate" className={styles.uploadProgress} />
      </div>
    ),
    [styles, uploadData.length, successCount, UploadMoreMediaButton],
  );

  const UploadFailureHeader = useMemo(
    () => (
      <div className={styles.headerContainer}>
        <Alert
          severity="warning"
          data-testid="upload-fullscreen-dialog-alert"
          action={
            <>
              {UploadMoreMediaButton}
              {!!retryableCount && RetryUploadButton}
            </>
          }
        >
          {generateUploadedMessage({ successCount, retryableCount, duplicatedCount })}
        </Alert>
      </div>
    ),
    [
      styles.headerContainer,
      UploadMoreMediaButton,
      retryableCount,
      RetryUploadButton,
      successCount,
      duplicatedCount,
    ],
  );

  const isDuplicateMediaName = useCallback(async () => {
    const mediaLabelPairs = await pairMediaLabelFiles(uploadData.map(item => item.file) as File[]);
    let duplicateMediaName: boolean = false;

    Object.keys(mediaLabelPairs).forEach(key => {
      if (mediaLabelPairs[key].mediaFiles!.length > 1) {
        duplicateMediaName = true;
      }
    });
    setIsDuplicate(duplicateMediaName);
  }, [uploadData]);

  useEffect(() => {
    isDuplicateMediaName();
  }, [isDuplicateMediaName]);

  const RepeatMediaContent = useMemo(
    () => (
      <div className={styles.headerContainer}>
        <Grid container direction="row" alignItems="flex-start">
          <Alert severity="warning">
            <Typography>
              {t(
                'You are uploading images with duplicate names. \
                If this image name contains a label, \
                it will be added to all of the images with the same name',
              )}
            </Typography>
          </Alert>
        </Grid>
      </div>
    ),
    [styles.headerContainer],
  );

  const UploadClassifiedContent = useMemo(
    () => (
      <DialogContent className={styles.uploadContainer}>
        {/* Preview header */}
        {uploadStage === UploadStage.NotStarted && (
          <Grid container alignItems="center" className={styles.divider}>
            <Typography variant="h4">{t('Upload Preview')}</Typography>
            <div className={styles.flexGrow} />
            {/* TODO: Need review not working right now */}
            {/* <Typography variant="body1">{t('Review required')}</Typography> */}
            {/* <HelpOutlineRounded className={styles.helpIcon} fontSize="small" /> */}
            {/* <Switch color="primary" size="small" /> */}
          </Grid>
        )}
        {/* Preview content */}
        {classifiedFolders.map(folder => (
          <PreviewMediaSection key={folder.folderName} name={folder.folderName} />
        ))}
      </DialogContent>
    ),
    [classifiedFolders, styles, uploadStage],
  );

  useEffect(() => {
    dispatch(addMetadata(metadataValuesMap));
  }, [dispatch, metadataValuesMap]);

  const UploadUnclassifiedContent = useMemo(
    () => (
      <DialogContent className={styles.uploadContainer}>
        {/* Preview header */}
        {uploadStage === UploadStage.NotStarted && (
          <Grid container alignItems="center" className={styles.divider}>
            <Typography variant="h4">{t('Upload Preview')}</Typography>
            <MaxPreviewImagesIcon imageCount={uploadData.length} />
          </Grid>
        )}
        {/* Preview content */}
        <div className={styles.previewImgRow}>
          {uploadData.slice(0, MAX_PREVIEW_IMAGES).map(uploadFile => (
            <PreviewMedia uploadFile={uploadFile} key={uploadFile.key} />
          ))}
        </div>
      </DialogContent>
    ),
    [styles, uploadData, uploadStage],
  );

  const HeaderComponent =
    (uploadStage === UploadStage.NotStarted && UploadDropzoneHeader) ||
    (uploadStage === UploadStage.UploadInProgress && UploadInProgressHeader) ||
    (uploadStage === UploadStage.UploadFulfilled && UploadSuccessHeader) ||
    (uploadStage === UploadStage.UploadFulfilledWithFailure && UploadFailureHeader) ||
    null;

  const ContentComponent = uploadData.length
    ? classifiedFolders.length
      ? UploadClassifiedContent
      : UploadUnclassifiedContent
    : null;

  return (
    <>
      {isDuplicate && RepeatMediaContent}
      {HeaderComponent}
      {ContentComponent}
      <UploadLimitDialog
        style={{ zIndex: 300002 }}
        open={openUploadLimitDialog}
        onGotIt={() => {
          setOpenUploadLimitDialog(false);
          onUploadImagesCallback();
        }}
      />
    </>
  );
};

export default UploadWrapper;

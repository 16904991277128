import React from 'react';

export type BottomLeftProps = {};

const BottomLeft: React.FC<BottomLeftProps> = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.1875 10.3125C3.64583 11.7708 5.41667 12.5 7.5 12.5H12.5V7.5H7.5C6.79167 7.5 6.19833 7.26 5.72 6.78C5.24 6.30167 5 5.70833 5 5V0H0V5C0 7.08333 0.729167 8.85417 2.1875 10.3125Z"
        fill="white"
      />
    </svg>
  );
};

export default BottomLeft;

import React, { useState, useRef, useMemo, useCallback } from 'react';
import { Popper, Paper, ClickAwayListener, Typography } from '@material-ui/core';
import { Button, IconButton } from '@clef/client-library';
import { CacheStorage, CacheStorageKey, useKeyPress, useLoadImage } from '@clef/client-library';
import { useLabelingTaskStyles } from './labelingTaskStyles';
import cx from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import { LabelingType } from '@clef/shared/types';
import labeling_tutorial_1_polygon from '../../images/labeling_instructions/labeling_tutorial_1_polygon.gif';
import labeling_tutorial_3_zoom from '../../images/labeling_instructions/labeling_tutorial_3_zoom.gif';
import { TutorialStep, useLabelingState } from '../../components/Labeling/labelingState';
export const ShowLabelingSegmentationTutorialCache = new CacheStorage<boolean>({
  key: CacheStorageKey.ShowSegmentationLabelingTutorial,
  persist: true,
  defaultValue: true,
});

export interface LabelingTaskTutorialProps {}

const tutorialGifUrlMap: { [step in TutorialStep]: string } = {
  [TutorialStep.Polygon]: labeling_tutorial_1_polygon,
  [TutorialStep.Zoom]: labeling_tutorial_3_zoom,
};

const LabelingTaskTutorial: React.FC<LabelingTaskTutorialProps> = () => {
  const {
    state: { tutorialAnchorElementsMap, labelingType },
  } = useLabelingState();
  const styles = useLabelingTaskStyles();

  const arrowRef = useRef(null);

  const [currIndex, setCurrIndex] = useState(0);

  const tutorialDescriptionMap: { [step in TutorialStep]: React.ReactNode } = useMemo(
    () => ({
      [TutorialStep.Polygon]: (
        <>
          <Typography className={cx(styles.tutorialText, styles.marginBottom2)}>
            {t('You can now use polygon to draw or erase!')}
          </Typography>
          <Typography className={styles.tutorialText}>
            {t('{{click}} to draw straight edges or {{hold}} to draw curved edges.', {
              click: <span className={styles.codeBlock}>{t('click')}</span>,
              hold: <span className={styles.codeBlock}>{t('hold down')}</span>,
            })}
          </Typography>
        </>
      ),
      [TutorialStep.Zoom]: (
        <>
          <Typography className={styles.tutorialText}>{t('You can zoom image by')}</Typography>
          <Typography className={styles.tutorialText}>
            {t('1. Use toolbar actions (with shortcuts {{shortcuts}})', {
              shortcuts: (
                <>
                  <span className={styles.codeBlock}>-</span>,
                  <span className={styles.codeBlock}>=</span>,
                  <span className={styles.codeBlock}>0</span>,
                  <span className={styles.codeBlock}>1</span>
                </>
              ),
            })}
          </Typography>
          <Typography className={cx(styles.tutorialText, styles.marginBottom2)}>
            {t('2. Scroll or pinch on track pad')}
          </Typography>
          <Typography className={styles.tutorialText}>
            {t('When drawing, you can hold down {{spacebar}} and drag to move image around', {
              spacebar: <span className={styles.codeBlock}>spacebar</span>,
            })}
          </Typography>
        </>
      ),
    }),
    [styles],
  );

  let tutorialSteps = [] as TutorialStep[];

  switch (labelingType) {
    case LabelingType.DefectSegmentation:
      tutorialSteps = [TutorialStep.Polygon, TutorialStep.Zoom];
      break;
    case LabelingType.DefectBoundingBox:
      tutorialSteps = [TutorialStep.Zoom];
      break;
    case LabelingType.DefectClassification:
      tutorialSteps = [TutorialStep.Zoom];
      break;
  }

  const tutorialGifList = useLoadImage(tutorialSteps.map(step => tutorialGifUrlMap[step]));

  const currentStep = tutorialSteps[currIndex];
  const currentGif = tutorialGifList[currIndex];
  const currentAnchor = tutorialAnchorElementsMap[currentStep];
  const currentDescription = tutorialDescriptionMap[currentStep];

  const tutorialLength = tutorialSteps.length;

  const isLast = currIndex === tutorialLength - 1;
  const isFirst = currIndex === 0;

  const closeTutorial = useCallback(() => setCurrIndex(tutorialLength), [tutorialLength]);

  useKeyPress('esc', closeTutorial);

  // only start showing tutorial when everything is in place
  if (!currentGif || !currentAnchor || !currentDescription || currIndex >= tutorialLength)
    return null;

  return (
    <>
      <div className={styles.tutorialMask}></div>
      <Popper
        open
        anchorEl={currentAnchor}
        className={styles.tutorialPopper}
        transition
        data-testid="labeling-task-tutorial-popper"
        modifiers={{
          arrow: {
            enabled: true,
            element: arrowRef.current,
          },
        }}
      >
        <span className={styles.tutorialContainerArrow} ref={arrowRef} />
        <Paper className={styles.tutorialContainer}>
          <ClickAwayListener
            onClickAway={() => {
              setCurrIndex(prev => prev + 1);
            }}
          >
            <div>
              <IconButton
                id="close-tutorial"
                onClick={closeTutorial}
                aria-label="close-tutorial"
                className={styles.tutorialClose}
                size="small"
              >
                <CloseIcon fontSize="small" htmlColor="white" />
              </IconButton>
              <img className={styles.tutorialGif} src={currentGif.src} />
              <div className={styles.tutorialTextContainer}>{currentDescription}</div>
              <div className={styles.flexRow}>
                {tutorialLength > 1 && (
                  <Typography className={styles.tutorialText}>
                    {`${currIndex + 1} of ${tutorialLength}`}
                  </Typography>
                )}
                <div className={styles.flexGrow}></div>
                {!isFirst && (
                  <Button
                    id="tutorial-previous-step-button"
                    className={styles.tutorialButton}
                    onClick={() => setCurrIndex(prev => prev - 1)}
                  >
                    {t('Previous')}
                  </Button>
                )}
                <Button
                  id={isLast ? 'done-tutorial-button' : 'tutorial-next-step-button'}
                  className={styles.tutorialButton}
                  onClick={() => {
                    setCurrIndex(prev => prev + 1);
                    if (isLast) {
                      // never show tutorial again
                      ShowLabelingSegmentationTutorialCache.set(false);
                    }
                  }}
                >
                  {isLast ? t('Never Show This Again') : t('Next')}
                </Button>
              </div>
            </div>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

export default LabelingTaskTutorial;

import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  AnnotationChangeType,
  AnnotationSourceType,
  CanvasAnnotation,
  CanvasAnnotationType,
  CanvasMode,
  getThumbnail,
  InstantLearningFitMediaPaddingTop,
  MediaInteractiveCanvas,
  ToggleButton,
} from '@clef/client-library';
import {
  convertToCanvasAnnotations,
  getCanvasMode,
  offscreenCanvasToCanvasAnnotation,
  useHandleCanvasInteractiveEvent,
} from '../../components/Labeling/utils';
import {
  BitMapLabelingAnnotation,
  PureCanvasLabelingAnnotation,
  useLabelingState,
} from '../../components/Labeling/labelingState';
import { useUpdateDefectMutation } from '@/serverStore/projects';
import useGetDefectColorById from '../../hooks/defect/useGetDefectColorById';
import useGetDefectNameById from '../../hooks/defect/useGetDefectNameById';
import { getDefectColor, isPreCreatedDefect } from '../../utils';
import { Layer } from 'konva/types/Layer';
import LabelingTools from './LabelingTools';
import {
  useCurrentMediaStates,
  useImageLabelingContext,
  useUpdateAnnotations,
} from '../../components/Labeling/imageLabelingContext';
import { useDefectSelector } from '../../store/defectState/actions';
import ClassSelector from './LabelingTools/ClassSelector';
import TrainButton from './LabelingTools/TrainButton';
import classNames from 'classnames';
import { useHasLabeledGroundTruthAnnotations, useLabeledClassIds } from './utils';
import { formatDistanceToNow } from 'date-fns';
import { useInstantLearningState } from './state';
import RunningMask, { loopTexts } from './RunningMask';
import CreateClassesDialog from '@/components/Labeling/CreateClassesDialog';

const useStyles = makeStyles(theme => ({
  labelingWrapper: {
    position: 'relative',
    height: '100%',
    '&.disabled': {
      cursor: 'not-allowed',
      pointerEvent: 'none',
      opacity: 0.7,
    },
  },
  labelingToolsWrapper: {
    height: 48,
    backgroundColor: theme.palette.grey[200],
    paddingLeft: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  classSelectorWrapper: {
    position: 'absolute',
    left: `min(50px, 3%)`,
    top: 70,
    zIndex: 2,
  },
  navigateButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    opacity: 0.7,
    backgroundColor: 'white',
    borderRadius: 6,
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'opacity 0.2s',
    fontSize: 48,
    '&:hover': {
      opacity: 0.9,
    },
    '&:active': {
      opacity: 0.8,
    },
  },
  prevImageButton: {
    left: theme.spacing(2),
  },
  nextImageButton: {
    right: theme.spacing(2),
  },
  savingIndicator: {
    position: 'absolute',
    left: 0,
    width: '100%',
    bottom: '100%',
  },
  mediaCanvas: {
    backgroundColor: theme.palette.grey[100],
    height: 'calc(100% - 48px) !important',
  },
  timeCont: {
    width: 'auto',
    color: theme.palette.greyModern[600],
    fontSize: 12,
    fontWeight: 500,
    marginRight: theme.spacing(6),
    marginLeft: theme.spacing(3),
  },
}));

export type LabelingWrapperProps = {
  groundTruthCanvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
  predictionCanvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
  loading?: boolean;
  isTraining?: boolean;
};

const LabelingWrapper: React.FC<LabelingWrapperProps> = props => {
  const { groundTruthCanvasRef, predictionCanvasRef, loading, isTraining } = props;
  const styles = useStyles();
  const { annotations, mediaDetails, predictionAnnotations } = useCurrentMediaStates();
  const getDefectColorById = useGetDefectColorById();
  const getDefectNameById = useGetDefectNameById();
  const updateDefectApi = useUpdateDefectMutation();
  const {
    state: {
      labelAndTrainMode,
      labelingWrapper: { showGroundTruthLabels, showPredictionLabeled },
    },
    dispatch: dispatchInstantLearning,
  } = useInstantLearningState();

  const {
    state: {
      labelingType,
      selectedDefect,
      toolMode,
      toolOptions: { erasing, strokeWidth, eraserWidth },
    },
    dispatch,
  } = useLabelingState();

  const canvasPredictionsAnnotations = useMemo(
    () =>
      (predictionAnnotations || []).map(
        ann =>
          ({
            ...offscreenCanvasToCanvasAnnotation(
              ann as PureCanvasLabelingAnnotation,
              /* opacity */ 0.8,
            ),
            defectId: ann.defectId,

            group: AnnotationSourceType.Prediction,
          } as CanvasAnnotation),
      ),
    [predictionAnnotations],
  );

  const canvasAnnotations = useMemo(() => {
    const annotationList = convertToCanvasAnnotations(
      labelingType,
      annotations as BitMapLabelingAnnotation[],
      getDefectColorById,
      AnnotationSourceType.GroundTruth,
      getDefectNameById,
    );
    return [...annotationList, ...canvasPredictionsAnnotations];
  }, [
    annotations,
    canvasPredictionsAnnotations,
    getDefectColorById,
    getDefectNameById,
    labelingType,
  ]);

  const canvasShapeProps = useMemo(
    () => ({
      color: erasing ? 'transparent' : selectedDefect ? getDefectColor(selectedDefect) : undefined,
      segmentationOpacity: 0.5,
      lineStrokeWidth: erasing ? eraserWidth : strokeWidth,
    }),
    [eraserWidth, erasing, selectedDefect, strokeWidth],
  );

  const [renderUrl, setRenderUrl] = useState<string>();

  // Preload with large thumbnail first, replaced with original image 3s later
  useEffect(() => {
    if (mediaDetails) {
      const largeThumbnail = getThumbnail(mediaDetails, 'large');
      setRenderUrl(largeThumbnail);
      const timer = setTimeout(() => {
        setRenderUrl(mediaDetails.url);
      }, 3 * 1000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [mediaDetails]);
  const { onCanvasInteractiveEvent } = useHandleCanvasInteractiveEvent();

  const updateAnnotations = useUpdateAnnotations();
  const onAnnotationChanged = useCallback(
    (annotations: CanvasAnnotation[], changeType: AnnotationChangeType, layerRef: Layer | null) => {
      updateAnnotations(annotations, changeType, layerRef);
      // Sync annotations to prediction canvas
      if (changeType === AnnotationChangeType.Create) {
        predictionCanvasRef.current?.setAnnotations(
          prev => {
            // only sync new annotations to ground truth canvas
            const prevAnnotationIds = new Set(prev.map(a => a.id));
            const groundTruthAnnotations = annotations.filter(
              a => a.group !== AnnotationSourceType.Prediction,
            );
            const newAnnotations = groundTruthAnnotations.filter(
              annotation =>
                !prevAnnotationIds.has(annotation.id) &&
                // Only sync newly drawn annotations
                // Assumptions:
                // 1. Those loaded from backend are merged into a CanvasAnnotationType.BitMap annotation for each class
                // 2. Newly drawn annotations are CanvasAnnotationType.Line annotations
                // Thus we can find newly drawn annotations like this.
                annotation.type === CanvasAnnotationType.Line,
            );

            return [...prev, ...newAnnotations];
          },

          changeType,
          /* skipCallback = */ true,
        );
      } else if (changeType === AnnotationChangeType.Undo) {
        predictionCanvasRef.current?.undo();
      } else if (changeType === AnnotationChangeType.Redo) {
        predictionCanvasRef.current?.redo();
      } else if (changeType === AnnotationChangeType.DeleteAll) {
        // Do not call with AnnotationChangeType.DeleteAll because it will delete prediction annotations as well.
        // Instead, we only keep prediction annotations to make it look like DeleteAll ground truth annotations.
        predictionCanvasRef.current?.setAnnotations(
          prev => prev.filter(ann => ann.group === AnnotationSourceType.Prediction),
          AnnotationChangeType.Edit,
          /* skipCallback = */ true,
        );
      }
    },
    [predictionCanvasRef, updateAnnotations],
  );

  const {
    state: { saving },
  } = useImageLabelingContext();

  const allDefects = useDefectSelector();
  const canLabel = allDefects.length >= 2;

  const totalLabeledClasses = useLabeledClassIds().size;
  const totalLabeledClassesRef = useRef(totalLabeledClasses);
  totalLabeledClassesRef.current = totalLabeledClasses;
  const defectCreatorTips = useMemo(() => {
    if (totalLabeledClasses === 0) {
      return t(
        'Paint {{smallArea}} over the object you want to identify.{{br}}Precision is key! Use [ ] to adjust brush to an appropriate size.',
        {
          smallArea: <strong>{t('a small area')}</strong>,
          br: <br />,
        },
      );
    }
    if (totalLabeledClasses === 1) {
      return t(
        'Paint {{smallArea}} of another class.{{br}}Tip: You can label the background as the second Class.',
        {
          smallArea: <strong>{t('a small area')}</strong>,
          br: <br />,
        },
      );
    }
    return undefined;
  }, [totalLabeledClasses]);

  const lastTrainTime = mediaDetails?.label?.labelTime
    ? formatDistanceToNow(new Date(mediaDetails.label.labelTime))
    : '';

  useEffect(() => {
    if (labelAndTrainMode === 'single') {
      dispatchInstantLearning(draft => {
        draft.labelingWrapper.showGroundTruthLabels = true;
        draft.labelingWrapper.showPredictionLabeled = true;
      });
    } else {
      dispatchInstantLearning(draft => {
        draft.labelingWrapper.showPredictionLabeled = false;
      });
    }
  }, [dispatch, dispatchInstantLearning, labelAndTrainMode]);

  const isLoadingPredictions =
    !!mediaDetails && !!mediaDetails.predictionLabel?.segImgPath && !predictionAnnotations;
  const onDefectCreate = useCallback(
    async (defectName, defectColor, autoSwitch: boolean = true) => {
      // TODO: update defect name
      await updateDefectApi.mutateAsync({
        ...selectedDefect!,
        name: defectName,
        color: defectColor,
        descriptionText: '',
      });
      dispatch(draft => {
        draft.selectedDefect!.name = defectName;
        draft.selectedDefect!.descriptionText = '';
      });
      // auto switch to the next class if we have only one class labeled
      if (totalLabeledClassesRef.current === 1 && autoSwitch) {
        const anotherDefect = allDefects.find(d => d.id !== selectedDefect?.id);
        if (anotherDefect) {
          dispatch(draft => {
            draft.selectedDefect = anotherDefect;
          });
        }
      }
    },
    [allDefects, dispatch, selectedDefect],
  );

  const hasLabeledGroundTruthAnnotations = useHasLabeledGroundTruthAnnotations(selectedDefect?.id);
  const [createClassesDialogOpen, setCreateClassesDialogOpen] = useState(false);

  return (
    <Box className={classNames(styles.labelingWrapper, loading && 'disabled')}>
      {(saving || loading) && <LinearProgress className={styles.savingIndicator} />}
      {!mediaDetails && <Skeleton variant="rect" width="100%" height="100%" />}
      {canLabel && (
        <Box className={styles.labelingToolsWrapper} display="flex" justifyContent="sp">
          <LabelingTools
            mediaCanvasRef={groundTruthCanvasRef}
            hasAnnotation={!!annotations && annotations.length > 0}
            deleteOption={AnnotationChangeType.DeleteGroundTruth}
          />
          {labelAndTrainMode === 'single' && (
            <Box display="flex" marginLeft="auto">
              <ToggleButton
                id="toggle-ground-truth-labels"
                isOn={showGroundTruthLabels}
                onToggle={() => {
                  dispatchInstantLearning(draft => {
                    draft.labelingWrapper.showGroundTruthLabels =
                      !draft.labelingWrapper.showGroundTruthLabels;
                  });
                }}
              >
                {t('Labels')}
              </ToggleButton>
              <ToggleButton
                id="toggle-predictions-labels"
                isOn={showPredictionLabeled}
                onToggle={() =>
                  dispatchInstantLearning(draft => {
                    draft.labelingWrapper.showPredictionLabeled =
                      !draft.labelingWrapper.showPredictionLabeled;
                  })
                }
              >
                {t('Predictions')}
              </ToggleButton>
            </Box>
          )}
          {lastTrainTime && (
            <Typography className={styles.timeCont}>
              {t('Labeled {{time}} ago', { time: lastTrainTime })}
            </Typography>
          )}
        </Box>
      )}
      {!!mediaDetails && (
        <Box width="100%" height="100%" position="relative">
          <RunningMask
            show={isTraining || isLoadingPredictions}
            blur={isTraining}
            texts={!isTraining && isLoadingPredictions ? loopTexts : undefined}
          />
          <MediaInteractiveCanvas
            ref={groundTruthCanvasRef}
            className={styles.mediaCanvas}
            key={mediaDetails.id}
            imageSrc={renderUrl}
            properties={mediaDetails?.properties}
            annotations={canvasAnnotations}
            showGroundTruthLabels={showGroundTruthLabels ? true : 'created-only'}
            hidePredictions={!showPredictionLabeled}
            mode={
              canLabel ? (!toolMode ? CanvasMode.Pan : getCanvasMode(toolMode)) : CanvasMode.Pan
            }
            enablePinchScrollZoom
            freeDrag
            shapeProps={canvasShapeProps}
            onInteractionEvent={onCanvasInteractiveEvent}
            onZoomScaleChange={(newZoomScale, mousePosition) => {
              dispatch(draft => {
                draft.toolOptions.zoomScale = newZoomScale;
              });
              predictionCanvasRef.current?.setZoomScale(
                newZoomScale,
                mousePosition,
                /* skip callback */ true,
              );
            }}
            onFitZoomScaleChange={zoomScale => {
              dispatch(draft => {
                draft.toolOptions.fitZoomScale = zoomScale;
              });
            }}
            onAnnotationChanged={onAnnotationChanged}
            onDragMove={p => predictionCanvasRef.current?.setStagePosition(p)}
            enableCrosshair
            onMouseMove={e => {
              predictionCanvasRef.current?.mouseMove(e);
            }}
            pendingDefectColor={
              totalLabeledClasses <= 2 && isPreCreatedDefect(selectedDefect)
                ? getDefectColor(selectedDefect)
                : undefined
            }
            pendingDefectName={selectedDefect?.name}
            onDefectCreate={onDefectCreate}
            onDisabledDrawingMouseDown={() => setCreateClassesDialogOpen(true)}
            defectCreatorTips={defectCreatorTips}
            fitMediaPaddingTop={InstantLearningFitMediaPaddingTop}
            enableFitPadding
            enableContour
            enablePixelation
            enableDefectCreatorClickAwayEvent
          />
        </Box>
      )}
      {/* require at least two classes to start labeling */}
      {canLabel && (
        <>
          <Box className={styles.classSelectorWrapper}>
            <ClassSelector
              mediaCanvasRef={groundTruthCanvasRef}
              onMenuButtonClick={async () => {
                // When the class selector menu button is click, auto save the class if user has not saved
                // and there is at least one labeled ground truth annotation
                if (
                  selectedDefect &&
                  isPreCreatedDefect(selectedDefect) &&
                  hasLabeledGroundTruthAnnotations
                ) {
                  groundTruthCanvasRef?.current?.defectCreator?.setIsLoading(true);
                  await onDefectCreate(selectedDefect.name, selectedDefect.color, false);
                  groundTruthCanvasRef?.current?.defectCreator?.setIsLoading(false);
                }
              }}
            />
          </Box>
          <TrainButton />
        </>
      )}
      {createClassesDialogOpen && (
        <CreateClassesDialog
          initialDefectName={selectedDefect?.name}
          initialDefectColor={getDefectColor(selectedDefect) ?? undefined}
          onClose={() => {
            setCreateClassesDialogOpen(false);
          }}
          onClassCreateOverwrite={async (name, color) => {
            groundTruthCanvasRef?.current?.defectCreator?.setIsLoading(true);
            await onDefectCreate(name, color);
            groundTruthCanvasRef?.current?.defectCreator?.setIsLoading(false);
          }}
          onClassCreateDiscard={async () => {
            groundTruthCanvasRef.current?.undo();
            groundTruthCanvasRef?.current?.defectCreator?.setMode(null);
          }}
          hideColorSelector
          skipExistingColorCheck
        />
      )}

      {/* TODO: Ask user to name the auto-created class  */}
    </Box>
  );
};

export default LabelingWrapper;

import React, { useState, useCallback } from 'react';
import { Dialog, DialogContent, DialogActions, Box, Link } from '@material-ui/core';
import { Button, Typography } from '@clef/client-library';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, useFieldArray, OnSubmit, FieldError, UseFieldArrayProps } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { UserRole } from '@clef/shared/types';
import { InviteMembersRow } from './InviteMembersRow';
import { useAppDispatch } from '../../../store';
import { inviteUser } from '../../../store/user_store';
import { fetchUsers } from '../../../store/user_store';
import { useCheckUserReachLimit } from '@/hooks/useSubscriptions';

const useStyles = makeStyles(theme => ({
  titleText: {
    padding: theme.spacing(6),
  },
  formContainer: {
    minWidth: 700,
  },
  dialogContent: {
    marginBottom: theme.spacing(5),
  },
  addMoreMembers: {
    color: '#37414D',
    '&:hover': { cursor: 'pointer' },
    paddingRight: theme.spacing(2),
  },
  warningText: {
    color: theme.palette.red[500],
  },
  numberOfSeatsLeftText: {
    color: theme.palette.greyModern[600],
  },
}));

interface InviteMembersFormValues {
  invites: { email: string; userRole: string }[];
}

export interface InviteMembersDialogProps {
  open: boolean;
  onClose: () => void;
  onReachUserLimit?: () => void;
}

export const InviteMembersDialog: React.FC<InviteMembersDialogProps> = ({
  open,
  onClose,
  onReachUserLimit,
}) => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isInviting, setIsInviting] = useState(false);
  const { control, errors, handleSubmit, reset } = useForm<InviteMembersFormValues>({
    defaultValues: {
      invites: [{ email: '', userRole: '' }],
    },
  });
  const { fields, append, remove } = useFieldArray<UseFieldArrayProps>({
    control,
    name: 'invites',
  });
  const { limit, usage, checkReachLimitApplyingCost } = useCheckUserReachLimit();
  const canInviteMoreUser = !checkReachLimitApplyingCost(fields.length);
  const pendingNewMember = fields.length ?? 0;
  const unlimitedSeats = limit === -1;
  const numberOfSeatsLeft = limit - usage - pendingNewMember;

  const _inviteUser = useCallback(
    async (email: string, userRole: string): Promise<boolean> => {
      try {
        await dispatch(inviteUser(email, userRole as UserRole));
        return true;
      } catch (e) {
        enqueueSnackbar(e.message, {
          variant: 'warning',
        });
        return false;
      }
    },
    [dispatch, enqueueSnackbar],
  );

  const onSubmit: OnSubmit<InviteMembersFormValues> = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async data => {
      setIsInviting(true);
      const { invites } = data;
      let allInvited = true;
      let hasInvited = false;

      await Promise.all(
        invites.map(async (invite: any) => {
          const success = await _inviteUser(invite.email, invite.userRole);
          if (!success) {
            allInvited = false;
          } else {
            hasInvited = true;
          }
        }),
      );

      if (allInvited) {
        enqueueSnackbar(t('Invitations have been sent!'), {
          variant: 'success',
        });
      }

      if (hasInvited) {
        dispatch(fetchUsers());
      }
      setIsInviting(false);
      onClose();
      reset();
    },
    [_inviteUser, dispatch, enqueueSnackbar, onClose, reset],
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        reset();
      }}
      maxWidth="md"
    >
      <Typography variant="h3" className={styles.titleText}>
        {t('Invite Members')}
      </Typography>

      <Box component="form" onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
        <DialogContent className={styles.dialogContent}>
          {fields.map((item, index) => {
            return (
              <InviteMembersRow
                key={item.id}
                index={index}
                control={control}
                onDelete={(): void => {
                  remove(index);
                }}
                deletable={fields.length > 1}
                error={
                  errors.invites &&
                  (errors.invites[index] as {
                    email: FieldError;
                    userRole: FieldError;
                  })
                }
              />
            );
          })}
          {numberOfSeatsLeft > 0 || unlimitedSeats ? (
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <Link
                underline="always"
                onClick={() => {
                  append({ name: 'append' });
                }}
                className={styles.addMoreMembers}
              >
                {t('Add more members')}
              </Link>
              {!unlimitedSeats && (
                <Typography variant="body_regular" className={styles.numberOfSeatsLeftText}>
                  {t('{{numberOfSeatsLeft}} {{seatsText}} left', {
                    numberOfSeatsLeft: numberOfSeatsLeft,
                    seatsText: numberOfSeatsLeft === 1 ? t('seat') : t('seats'),
                  })}
                </Typography>
              )}
            </Box>
          ) : (
            <Typography variant="body_regular" className={styles.warningText}>
              {t(
                `You've reached the member limit of your plan. Please contact us for additional seats.`,
              )}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            id="cancel-invite-member-button"
            onClick={() => {
              onClose();
              reset();
            }}
          >
            {t('Cancel')}
          </Button>
          {canInviteMoreUser ? (
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isInviting}
              id="invite-member-button"
            >
              {isInviting ? t('Inviting...') : t('Invite')}
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              id="contact us for more seats"
              onClick={onReachUserLimit}
            >
              {t('Contact us for more seats')}
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

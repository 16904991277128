import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDeviceMonitorMedias } from '../../../hooks/api/useEdgeApi';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { ApiResponseLoader, VirtualGrid, useKeyPress } from '@clef/client-library';
import EdgeMediaContainer from './EdgeMediaContainer';
import { EdgeMedia, EdgeMediaDetails, MediaId } from '@clef/shared/types';
import { useDeviceMonitorState, consolidateFilterOptionFromState } from './deviceMonitorState';
import MediaDetailsDialog from '../../DataBrowser/MediaDetailsDialog';
import { useAppDispatch } from '../../../store';
import { addFile, deleteFile, resetUploadState } from '../../../store/uploadState';
import { v4 as uuidv4 } from 'uuid';

import pick from 'lodash/pick';
import CLIA from '../../../api/clia_api';
import { getFileFromUrl } from './utils';
import { FileWithPath } from 'react-dropzone';

const calcOptimalRatio = (medias: EdgeMedia[]) => {
  const allRatios = medias.map(media => media.height / media.width).sort((a, b) => a - b);
  // Lazy here to calculate the most frequent item
  return allRatios.length ? allRatios[Math.floor(allRatios.length / 2)] : 16 / 9;
};

export interface EdgeMediaGridProps {
  fileCapacity?: number | null;
  fileLimit?: number | null;
  disabled?: boolean;
  emptyStateComponent?: React.ReactNode;
}

const EdgeMediaGrid: React.FC<EdgeMediaGridProps> = ({
  fileCapacity,
  fileLimit,
  emptyStateComponent,
}) => {
  const { data: selectedProject } = useGetSelectedProjectQuery();

  const { state, dispatch } = useDeviceMonitorState();
  const {
    deviceId,
    selectedMedia,
    sortOptions,
    pageIndex,
    filterConfidence,
    filterConfidenceEnabled,
    filterDefects,
    filterClass,
    filterModel,
    filterUploadTime,
    filterUploadTimeEnabled,
    filterHumanJudgement,
    filterImageId,
    filterImageIdEnabled,
    filterLocationId,
    filterLocationIdEnabled,
    filterInspectionStationId,
    filterInspectionStationIdEnabled,
    paginationLimit,
    displayOptions: {
      showConfidenceScore,
      showDefectName,
      showImageClassification,
      showLabels,
      showHumanJudgement,
    },
  } = state;

  const filterOptions = useMemo(
    () => consolidateFilterOptionFromState(state),
    // only specific filter change will effect filterOptions
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterConfidence,
      filterConfidenceEnabled,
      filterDefects,
      filterClass,
      filterModel,
      filterUploadTime,
      filterUploadTimeEnabled,
      filterHumanJudgement,
      filterImageId,
      filterImageIdEnabled,
      filterLocationId,
      filterLocationIdEnabled,
      filterInspectionStationId,
      filterInspectionStationIdEnabled,
    ],
  );

  const [deviceMediaList, deviceMediaListLoading, deviceMediaListError] = useDeviceMonitorMedias(
    selectedProject
      ? {
          projectId: selectedProject.id,
          deviceId: deviceId!,
          paginationOptions: {
            offset: pageIndex * paginationLimit,
            limit: paginationLimit,
          },
          filterOptions,
          sortOptions: pick(sortOptions, ['sortType', 'sortValue']),
        }
      : undefined,
  );

  // when paginationLimit / filterOptions / sortOptions changes and we need to refresh data
  // reset pageIndex = 0
  useEffect(() => {
    dispatch(draft => {
      draft.pageIndex = 0;
    });
  }, [paginationLimit, filterOptions, sortOptions, dispatch]);

  const [shiftKey, setShiftKey] = useState(false);
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number>(-1);
  const [dialogMediaId, setDialogMediaId] = useState<MediaId>(-1);
  const [recentDialogMediaIndex, setRecentDialogMediaIndex] = useState<number>(-1);
  const uploadData = useTypedSelector(state => state.uploadState.uploadData);
  const appDispatch = useAppDispatch();
  const onCloseMediaDialog = useCallback(() => {
    setRecentDialogMediaIndex(
      (deviceMediaList || []).findIndex(media => media.id === dialogMediaId),
    );
    setDialogMediaId(-1);
  }, [deviceMediaList, dialogMediaId]);

  useEffect(() => {
    // Global state should be on sync with local state
    if (!selectedMedia.length && uploadData.length) {
      appDispatch(resetUploadState());
    }
  }, [selectedMedia, appDispatch, uploadData.length]);

  const getImageFile = async (selectedMediaDetails: EdgeMediaDetails | undefined) => {
    if (selectedMediaDetails) {
      let fileName = selectedMediaDetails.path.split('/').pop() || `cl_${uuidv4()}.png`;
      if (!fileName.includes('.')) {
        fileName += '.png';
      }
      const file = await getFileFromUrl(selectedMediaDetails.url, fileName);
      return Object.assign(file, { path: file.name, mediaId: selectedMediaDetails.id });
    }
    return null;
  };

  const onMediaSelected = useCallback(
    (mediaId: number, index: number) =>
      async (selected: boolean, selectedMediaDetails: EdgeMediaDetails | undefined) => {
        if (!selected) {
          dispatch(draft => {
            draft.selectedMedia = selectedMedia.filter(id => mediaId !== id);
          });
          const fileName = selectedMediaDetails?.path.split('/').pop();
          if (fileName) {
            appDispatch(deleteFile(fileName));
          }
          return;
        }
        if (!selectedProject) return;

        setLastSelectedIndex(index);
        // if with shift key, find all media in between index to be selected
        if (shiftKey && lastSelectedIndex >= 0) {
          // Avoid duplicated files
          appDispatch(resetUploadState());

          const selectedMediaRange = [
            ...new Set([
              ...selectedMedia,
              ...deviceMediaList!
                .slice(
                  Math.min(lastSelectedIndex, index),
                  Math.max(lastSelectedIndex + 1, index + 1),
                )
                .map(_ => _.id),
            ]),
          ];

          const selectedImageFiles = await Promise.all(
            selectedMediaRange.map(async id => {
              const mediaDetails = await CLIA.getMediaDetails(selectedProject.id, id);
              return getImageFile(mediaDetails);
            }),
          );

          appDispatch(
            addFile({
              files: selectedImageFiles.filter(Boolean) as FileWithPath[],
              capacity: fileCapacity,
              limit: fileLimit,
            }),
          );
          dispatch(draft => {
            draft.selectedMedia = selectedMediaRange;
          });
        } else {
          const imageFile = await getImageFile(selectedMediaDetails);
          if (imageFile) {
            appDispatch(
              addFile({
                files: [imageFile],
                capacity: fileCapacity,
                limit: fileLimit,
              }),
            );
          }
          dispatch(draft => {
            draft.selectedMedia.push(mediaId);
          });
        }
      },
    [
      shiftKey,
      lastSelectedIndex,
      dispatch,
      selectedMedia,
      deviceMediaList,
      appDispatch,
      fileCapacity,
      fileLimit,
      selectedProject,
    ],
  );

  useKeyPress(
    '*',
    event => {
      switch (event.key) {
        case 'Shift':
          if (event.type === 'keydown') {
            setShiftKey(true);
          } else if (event.type === 'keyup') {
            setShiftKey(false);
          }
          break;
      }
    },
    { keyup: true, keydown: true },
  );

  // esc to unselect all
  useKeyPress('esc', () => {
    dispatch(draft => {
      draft.selectedMedia = [];
    });
  });
  return (
    <ApiResponseLoader
      response={deviceMediaList}
      loading={deviceMediaListLoading}
      error={deviceMediaListError}
      defaultHeight={300}
    >
      {response => {
        if (!response.length && emptyStateComponent) {
          return emptyStateComponent;
        }

        return (
          <>
            {dialogMediaId !== -1 && deviceMediaList && (
              <MediaDetailsDialog
                mediaList={deviceMediaList}
                initialMediaId={dialogMediaId}
                onClose={() => onCloseMediaDialog()}
                showLabels={showLabels}
                showDefectName={showDefectName}
                showConfidenceScore={showConfidenceScore}
                showConfidenceChip={true}
              />
            )}

            <VirtualGrid imageRatio={calcOptimalRatio(response)} componentList={response}>
              {(media, index) => (
                <EdgeMediaContainer
                  media={media}
                  selected={selectedMedia.includes(media.id)}
                  onSelected={onMediaSelected(media.id, index)}
                  showLabels={showLabels}
                  showDefectName={showDefectName}
                  showConfidenceScore={showConfidenceScore}
                  showImageClassification={showImageClassification}
                  showHumanJudgement={showHumanJudgement}
                  onInfoClick={() => setDialogMediaId(media.id)}
                  recentDialogMediaIndex={recentDialogMediaIndex}
                  mediaIndex={index}
                />
              )}
            </VirtualGrid>
          </>
        );
      }}
    </ApiResponseLoader>
  );
};

export default EdgeMediaGrid;

import { Draft } from 'immer';
import { useContext, createContext, useCallback } from 'react';
import {
  LabelingTaskReviewResult,
  MediaDetailsForLabelingReview,
  LabelReviewStatus,
  Label,
} from '@clef/shared/types';
import {
  BitMapLabelingAnnotation,
  BoxLabelingAnnotation,
} from '../../components/Labeling/labelingState';

export type AgreementScoreSortOrder = 'desc' | 'asc';

export type LabelingReviewState = {
  currentMediaId: number;
  agreementScoreSortOrder: AgreementScoreSortOrder;
  rejectAgreementScoreSortOrder: AgreementScoreSortOrder;
  acceptAgreementScoreSortOrder: AgreementScoreSortOrder;
  inReivewAgreementScoreSortOrder: AgreementScoreSortOrder;
  agreementThreshold: number;
  reviewResult: LabelingTaskReviewResult;
  filteredDefect: number[];
  visibleLabel?: number;
  visibleDefect?: number;
  visibleAnnotation?: string;
  reviewMediaList: MediaDetailsForLabelingReview[];
  isViewResultMode: boolean;
  editingLabel?: Label;
  annotations?: BoxLabelingAnnotation[] | BitMapLabelingAnnotation[];
};

export const defaultState: LabelingReviewState = {
  currentMediaId: 0,
  agreementThreshold: 0.8,
  reviewResult: {},
  filteredDefect: [],
  reviewMediaList: [],
  isViewResultMode: false,
  agreementScoreSortOrder: 'asc',
  rejectAgreementScoreSortOrder: 'asc',
  acceptAgreementScoreSortOrder: 'asc',
  inReivewAgreementScoreSortOrder: 'asc',
  annotations: [],
};
/**
 * Context
 */
export const LabelingReviewContext = createContext<{
  state: LabelingReviewState;
  dispatch: (f: (state: Draft<LabelingReviewState>) => void | LabelingReviewState) => void;
}>({
  state: defaultState,
  dispatch: () => {},
});

export const useLabelingReviewState = () => useContext(LabelingReviewContext);

export const useUpdateFilters = () => {
  const {
    state: { reviewResult, reviewMediaList },
    dispatch,
  } = useLabelingReviewState();
  return useCallback(
    (newMediaId: number) => {
      if (reviewResult[newMediaId]?.reviewStatus === LabelReviewStatus.Rejected) {
        dispatch(draft => {
          draft.filteredDefect = [];
          draft.visibleLabel = undefined;
        });
      } else {
        const foundMedia = reviewMediaList.find(media => media.id === newMediaId)!;
        const bestLabelId = reviewResult[newMediaId]?.selectedLabel ?? foundMedia.bestLabelId;
        dispatch(draft => {
          draft.filteredDefect = [];
          draft.visibleLabel = bestLabelId;
        });
      }
    },
    [dispatch, reviewResult, reviewMediaList],
  );
};

export const useGoToNextMedia = () => {
  const { state, dispatch } = useLabelingReviewState();
  const update = useUpdateFilters();

  return useCallback(
    (mediaList?: MediaDetailsForLabelingReview[]) => {
      const list = mediaList || state.reviewMediaList;
      if (state.currentMediaId && list.length) {
        const currentIndex = list.findIndex(media => media.id === state.currentMediaId);
        if (currentIndex !== list.length - 1) {
          const newId = list[currentIndex + 1].id;
          dispatch(draft => {
            draft.currentMediaId = newId;
          });
          update(newId);
        }
      }
    },
    [dispatch, state.currentMediaId, state.reviewMediaList, update],
  );
};

export const useGoToPrevMedia = () => {
  const { state, dispatch } = useLabelingReviewState();
  const update = useUpdateFilters();
  return useCallback(
    (mediaList?: MediaDetailsForLabelingReview[]) => {
      const list = mediaList || state.reviewMediaList;
      if (state.currentMediaId && list.length) {
        const currentIndex = list.findIndex(media => media.id === state.currentMediaId);
        if (currentIndex) {
          const newId = list[currentIndex - 1].id;
          dispatch(draft => {
            draft.currentMediaId = newId;
          });
          update(newId);
        }
      }
    },
    [dispatch, state.currentMediaId, state.reviewMediaList, update],
  );
};

import React, { useMemo, useCallback } from 'react';
import { Typography, Checkbox, Grid, makeStyles } from '@material-ui/core';
import { isEqual, startCase } from 'lodash';
import { useAtom } from 'jotai';

import { Dropdown, DropdownProps, Button } from '@clef/client-library';
import { FilterOptionName, FilterOptionType } from '@clef/shared/types';

import { statsCardDataKeyCompareFn } from '@/utils';
import { appliedFiltersAtom } from '@/uiStates/mediaFilter';

const useStyles = makeStyles(theme => ({
  optionItem: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    paddingRight: theme.spacing(5),
    height: 33,
    '&:hover': {
      backgroundColor: 'rgba(9, 30, 66, 0.08)',
    },
  },
  textButton: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  dropdownPanel: {
    minWidth: 150,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  noneOption: {},
}));

export const NoneOption = 'Unassigned';
const BooleanFilterOptions = { true: true, false: false };
const BooleanFilterOptionsTranslated = { [t('true')]: true, [t('false')]: false };

export type FilterDropdownPredefinedChoicesProps = {
  customizedSelector: (selectedOptions: string[], dropdownOpen: boolean) => React.ReactNode;
  filterOption: FilterOptionType;
  // To be passed to Dropdown
  placement?: DropdownProps['placement'];
  extraGutter?: DropdownProps['extraGutter'];
  filterMappingKey: string | number;
};

export const FilterDropdownPredefinedChoices: React.FC<FilterDropdownPredefinedChoicesProps> = ({
  filterOption,
  customizedSelector,
  placement,
  extraGutter,
  filterMappingKey,
}) => {
  const styles = useStyles();
  const { filterName, value, filterType, fieldId, operations } = filterOption;
  const filterPredefinedChoiceMapping = isEqual(value, BooleanFilterOptions)
    ? BooleanFilterOptionsTranslated
    : (value as { [key: string]: string });
  const filterPredefinedChoices = useMemo(
    () =>
      Object.entries(filterPredefinedChoiceMapping)
        .slice()
        .sort((entryA, entryB) => statsCardDataKeyCompareFn(entryA[0], entryB[0])),
    [filterPredefinedChoiceMapping],
  );

  const [appliedFilters, setAppliedFilters] = useAtom(appliedFiltersAtom);
  const curAppliedFilters = useMemo(
    () => appliedFilters[filterMappingKey] ?? {},
    [appliedFilters, filterMappingKey],
  );

  const selectedFilterValues: string[] = useMemo(() => {
    if (!curAppliedFilters[filterName]) {
      return [];
    }

    const appliedOptions = curAppliedFilters[filterName].v as string[];

    return curAppliedFilters[filterName].o === 'NOT_CONTAIN_ANY'
      ? // if current operation is NOT_CONTAIN_ANY, all the value options should be reversed and None should be included
        [
          ...filterPredefinedChoices
            .map(([_, value]) => value)
            .filter(value => !appliedOptions.includes(value)),
          NoneOption,
        ]
      : appliedOptions;
  }, [curAppliedFilters, filterName, filterPredefinedChoices]);

  const updatePredefinedChoicesFilter = useCallback(
    (newOptions: string[]) => {
      const isNotContainAnyLogic =
        operations.includes('NOT_CONTAIN_ANY') && newOptions.includes(NoneOption);

      const value = isNotContainAnyLogic
        ? // if applying NOT_CONTAIN_ANY, all the selected options should not be added to value
          // all the non-selected options are to be "not contained"
          filterPredefinedChoices
            .map(([_, value]) => value)
            .filter(value => !newOptions.includes(value))
        : newOptions;

      const newCurAppliedFilters = { ...curAppliedFilters };
      if (value.length) {
        newCurAppliedFilters[filterName] = {
          o: isNotContainAnyLogic ? 'NOT_CONTAIN_ANY' : 'CONTAINS_ANY',
          t: filterType,
          fi: fieldId,
          v: value,
        };
      } else {
        newCurAppliedFilters[filterName].v = [];
        newCurAppliedFilters[filterName].o = isNotContainAnyLogic
          ? 'NOT_CONTAIN_ANY'
          : 'CONTAINS_ANY';
      }
      setAppliedFilters(prev => ({
        ...prev,
        [filterMappingKey]: newCurAppliedFilters,
      }));
    },
    [
      curAppliedFilters,
      fieldId,
      filterMappingKey,
      filterName,
      filterPredefinedChoices,
      filterType,
      operations,
      setAppliedFilters,
    ],
  );

  const onFilterOptionChange = useCallback(
    (optionValue: string) => () => {
      const newSelectedFilterOption = selectedFilterValues.includes(optionValue)
        ? selectedFilterValues.filter(option => option !== optionValue)
        : [...selectedFilterValues, optionValue];
      updatePredefinedChoicesFilter(newSelectedFilterOption);
    },
    [selectedFilterValues, updatePredefinedChoicesFilter],
  );

  return (
    <Dropdown
      placement={placement}
      extraGutter={extraGutter}
      dropdown={
        <div className={styles.dropdownPanel}>
          {/* All the given filter option */}
          {filterPredefinedChoices.map(([optionName, optionValue]) => (
            <div
              key={optionValue}
              onClick={onFilterOptionChange(optionValue)}
              className={styles.optionItem}
              data-testid="filter-dropdown-option"
            >
              <Checkbox
                color="primary"
                size="small"
                checked={selectedFilterValues.includes(optionValue)}
                onChange={onFilterOptionChange(optionValue)}
              />
              <Typography variant="body1" component="span">
                {filterName === FilterOptionName.MediaStatus
                  ? startCase(t(optionName))
                  : filterName === FilterOptionName.Split
                  ? startCase(optionName)
                  : optionName}
              </Typography>
            </div>
          ))}
          {/* If operations supports NOT_CONTAIN_ANY,  */}
          {operations.includes('NOT_CONTAIN_ANY') && (
            <div
              onClick={onFilterOptionChange(NoneOption)}
              className={styles.optionItem}
              data-testid="filter-dropdown-option"
            >
              <Checkbox
                color="primary"
                size="small"
                checked={selectedFilterValues.includes(NoneOption)}
                onChange={onFilterOptionChange(NoneOption)}
              />
              <Typography variant="body1" className={styles.noneOption} component="span">
                {NoneOption}
              </Typography>
            </div>
          )}
          {/* Clear all options */}
          {filterPredefinedChoices.length > 1 && (
            <Grid container justifyContent="flex-end">
              <Button
                color="secondary"
                size="small"
                className={styles.textButton}
                onClick={() => updatePredefinedChoicesFilter([])}
                disabled={!selectedFilterValues.length}
                id="clear-selected-filtered-options"
              >
                {t('Clear')}
              </Button>
            </Grid>
          )}
        </div>
      }
    >
      {open => customizedSelector(selectedFilterValues, open)}
    </Dropdown>
  );
};

export default FilterDropdownPredefinedChoices;

import React from 'react';
import { Button } from '@clef/client-library';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

interface ConfirmationDialogProps {
  open: boolean;
  onClose(): void;
  onClickConfirm(): void;
  onClickCancel(): void;
  title?: string;
  text?: string;
  id?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  onClickConfirm,
  onClickCancel,
  title = 'Confirmation Dialog',
  text = 'Are you sure you wish to do this action?',
  id = 'confirmation-dialog',
}) => {
  return (
    <Dialog
      aria-labelledby={`${id}-title`}
      data-testid="confirmation-dialog"
      onClose={onClose}
      open={open}
    >
      <DialogTitle id={`${id}-title`}>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{text}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={onClickConfirm}
          variant={'contained'}
          color="primary"
          id="dialog-confirm-changes-btn"
        >
          {t('Yes')}
        </Button>
        <Button
          size="small"
          onClick={onClickCancel}
          variant={'contained'}
          color="default"
          id="dialog-deny-changes-btn"
        >
          {t('Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

import React, { useState } from 'react';
import { Button } from '@clef/client-library';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';

import { useTypedSelector } from '../../hooks/useTypedSelector';
import { projectSettingAdministrativeStyles } from './projectSettingAdministrativeStyles';
import { isProjectOwner } from '@clef/shared/utils';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import DeleteProjectDialog from '../Dialogs/DeleteProjectDialog';

type ProjectSettingAdministrativeProps = {};

const ProjectSettingAdministrative: React.FC<ProjectSettingAdministrativeProps> = () => {
  const styles = projectSettingAdministrativeStyles();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const [openDeleteProjectDialog, setOpenDeleteProjectDialog] = useState<boolean>(false);

  const activeUsers = selectedProject?.users ?? [];
  const user = useTypedSelector(state => state.login.user!);

  const handleDeleteProjectDialogClose = () => setOpenDeleteProjectDialog(false);

  if (!selectedProject) return <CircularProgress />;

  const currentUserWithRole = activeUsers?.find(
    userRole => userRole.userProject.userId === user.id,
  );

  const isOwner = isProjectOwner(currentUserWithRole?.userProject.role);

  return (
    <>
      <Box>
        <Box>{<Typography variant="subtitle1">{t('Project name')}</Typography>}</Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography
            variant="h2"
            component="span"
            className={classNames('cy-settings-project-name', styles.projectName)}
            data-testid="selected-project-name"
          >
            {selectedProject.name}
          </Typography>
        </Box>
      </Box>
      {isOwner && (
        <Box>
          <Box className={styles.moreActionsTitle}>
            <Typography variant="subtitle1">{t('More actions')}</Typography>
          </Box>
          <Box className={styles.deleteProject}>
            <Button
              onClick={() => {
                setOpenDeleteProjectDialog(true);
              }}
              variant="contained"
              color="secondary"
              className={styles.deleteBtn}
              id="project-setting-delete-project-btn"
            >
              {t('Delete Project')}
            </Button>
          </Box>
        </Box>
      )}
      {isOwner && (
        <DeleteProjectDialog
          project={selectedProject}
          open={openDeleteProjectDialog}
          handleClose={handleDeleteProjectDialogClose}
        />
      )}
    </>
  );
};
export default ProjectSettingAdministrative;

import React, { useCallback, useEffect, useRef } from 'react';
import cx from 'classnames';
import { MediaDetails } from '@clef/shared/types';
import { useLabelingTaskStyles } from '../labelingTaskStyles';
import { Grid, Chip, Tooltip, makeStyles } from '@material-ui/core';
import { useLabelingTaskState } from '../labelingTaskState';
import { useDefectSelector } from '../../../store/defectState/actions';
import { getDefectColor } from '../../../utils';
import { getThumbnail, Img } from '@clef/client-library';
import { useUnsavedLabelCheckDecorator } from '@/components/Labeling/labelingState';

export interface TaskMediaPreviewProps {
  taskMedia: MediaDetails;
}

const useTaskMediaPreviewStyles = makeStyles(theme => ({
  defectColorOverride: {
    marginBottom: theme.spacing(1),
  },
  defectGrid: {
    overflowY: 'scroll',
  },
}));

const TaskMediaPreview: React.FC<TaskMediaPreviewProps> = ({ taskMedia }) => {
  const previewRef = useRef<HTMLDivElement | null>(null);
  const styles = useLabelingTaskStyles();
  const taskMediaPreviewStyles = useTaskMediaPreviewStyles();
  const {
    state: { currentMediaId, annotationData },
    dispatch,
  } = useLabelingTaskState();
  const { id: mediaId } = taskMedia;
  const annotationsForThisMedia = annotationData[mediaId];
  const allDefects = useDefectSelector();
  const labeledDefects =
    annotationsForThisMedia &&
    allDefects?.filter(defect => !!annotationsForThisMedia.find(ann => ann.defectId === defect.id));
  const nothingToLabel = annotationsForThisMedia && annotationsForThisMedia.length === 0;
  // scroll into view
  useEffect(() => {
    if (previewRef.current && currentMediaId === mediaId) {
      previewRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [mediaId, currentMediaId]);

  const handleClick = useCallback(() => {
    dispatch(draft => {
      draft.currentMediaId = mediaId;
    });
  }, [dispatch, mediaId]);
  const onClick = useUnsavedLabelCheckDecorator(handleClick);

  return (
    <Grid
      ref={previewRef}
      direction="row"
      alignContent="center"
      container
      wrap="nowrap"
      key={mediaId}
      id={`preview-media-${mediaId}`}
      onClick={onClick}
      className={cx(styles.mediaPreview, currentMediaId === mediaId && styles.mediaPreviewSelected)}
      data-testid="task-media-preview"
      aria-selected={currentMediaId === mediaId}
    >
      <Grid container xs={6} item direction="row" alignItems="center">
        <Img
          src={getThumbnail(taskMedia, 'small')}
          fallbackSrc={taskMedia?.url}
          className={styles.mediaPreviewImg}
          crossOrigin="use-credentials"
        />
      </Grid>
      <Grid
        container
        xs={6}
        item
        direction="row"
        alignItems="center"
        className={taskMediaPreviewStyles.defectGrid}
      >
        {labeledDefects && labeledDefects.length ? (
          // If this media have labeled defect
          labeledDefects.map(defect => (
            <Tooltip key={defect.id} placement="top" title={defect.name}>
              <div
                className={cx(styles.defectColor, taskMediaPreviewStyles.defectColorOverride)}
                data-testid={defect.name}
                style={{ backgroundColor: getDefectColor(defect) }}
              />
            </Tooltip>
          ))
        ) : nothingToLabel ? (
          // If this media is marked nothing to label
          <Chip
            size="small"
            variant="outlined"
            color="primary"
            component="span"
            label={t('No labels')}
            clickable={false}
          />
        ) : (
          // This media is not labeled yet
          <Chip
            size="small"
            variant="outlined"
            component="span"
            label={t('Not labeled')}
            clickable={false}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TaskMediaPreview;

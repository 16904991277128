import React, { CSSProperties } from 'react';
import { makeStyles, Box, Paper } from '@material-ui/core';
import { CustomPicker, ColorResult } from 'react-color';
import { EditableInput, Saturation, Hue } from 'react-color/lib/components/common';

const useStyles = makeStyles(theme => ({
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  container: {
    backgroundColor: '#fff',
    padding: theme.spacing(2),
  },
  hexInputContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: theme.spacing(0.5, 3),
    alignItems: 'center',
  },
  colorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  colorPalette: {
    width: 20,
    height: 20,
    borderRadius: 15,
    marginRight: theme.spacing(3),
  },
  hexInput: {
    width: 'fit-content',
  },
  saturationBox: {
    width: '170px',
    height: '180px',
    margin: theme.spacing(3),
    position: 'relative',
    overflow: 'hidden',
  },
  hueSlider: {
    width: '90%',
    height: '12px',
    margin: theme.spacing(2.5),
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '15px',
  },
}));

const inputBox: CSSProperties = {
  width: '80px',
  padding: 4,
  borderRadius: 4,
};

interface ColorPickerProps {
  color: string;
  onChange: (color: ColorResult) => void;
  onClose: () => void;
}

const ColorPicker = (props: ColorPickerProps) => {
  const styles = useStyles();
  const { color, onChange, onClose } = props;

  return (
    <>
      <Box className={styles.cover} onClick={onClose} data-testid="color-picker-cover" />
      <Paper elevation={0}>
        <Box data-testid="color-picker" className={styles.container}>
          <Box className={styles.hexInputContainer}>
            <Box
              className={styles.colorPalette}
              style={{
                backgroundColor: color,
              }}
            />
            <Box>
              <EditableInput style={{ input: inputBox }} value={color} onChange={onChange} />
            </Box>
          </Box>
          <Box className={styles.colorContainer}>
            <Box className={styles.saturationBox}>
              <Saturation {...props} />
            </Box>
            <Box className={styles.hueSlider}>
              <Hue {...props} />
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default CustomPicker(ColorPicker);

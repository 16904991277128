import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  webcamWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    alignItems: 'center',
    position: 'relative',
  },
  webcam: {
    flex: 1,
    maxWidth: 1280,
    maxHeight: 'calc(100% - 78px)',
  },
}));

export default useStyles;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card, Tooltip, Menu, MenuItem, Grid, Box } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import ExpandMoreRounded from '@material-ui/icons/ExpandMoreRounded';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { Defect, LabelType } from '@clef/shared/types';
import { Button, greyScale, Typography } from '@clef/client-library';
import { useKeyPress } from '@clef/client-library';
import cx from 'classnames';
import { useLabelingDrawer } from './LabelingDrawer';
import { getDefectColor } from '../../utils';
import { useLabelingState } from './labelingState';
import { useDefectSelector } from '../../store/defectState/actions';
import { useHintSnackbar } from './HintSnackbar';
import { useLabelingStyles } from './labelingStyles';
import CreateClassesDialog from './CreateClassesDialog';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import classNames from 'classnames';

export interface ToolDefectCardProps {
  warning: boolean;
  disabled?: boolean;
}

export const DefectItem: React.FC<{
  defect: Defect;
  hideInfo?: boolean;
  className?: string;
  classes?: { root?: string; defectName?: string };
}> = ({ defect, hideInfo = false, className, classes }) => {
  const styles = useLabelingStyles();
  const { toggleDefectBookDrawer } = useLabelingDrawer();

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classNames(styles.defectItem, className, classes?.root)}
    >
      <div className={styles.defectColor} style={{ backgroundColor: getDefectColor(defect) }} />
      <Typography
        variant="body1"
        className={classNames(styles.defectName, classes?.defectName)}
        maxWidth={100}
      >
        {defect.name}
      </Typography>
      <div className={styles.flexGrow} />
      {!hideInfo && (
        <InfoOutlined
          fontSize="small"
          style={{ color: greyScale[500] }}
          data-testid="defect-detail"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            toggleDefectBookDrawer(defect.id);
          }}
        />
      )}
    </Grid>
  );
};

const ToolDefectCard: React.FC<ToolDefectCardProps> = ({ warning, disabled }) => {
  const styles = useLabelingStyles();
  const {
    state: {
      selectedDefect,
      toolOptions: { erasing },
    },
    dispatch,
  } = useLabelingState();
  const { toggleDefectBookDrawer } = useLabelingDrawer();
  const allDefects = useDefectSelector();
  const triggerHint = useHintSnackbar();

  const anchorEl = useRef<null | HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const { labelType } = useGetSelectedProjectQuery().data ?? {};

  useEffect(() => {
    if (selectedDefect && !allDefects?.find(defect => defect.id === selectedDefect?.id)) {
      dispatch(draft => {
        draft.selectedDefect = undefined;
      });
    }
  }, [selectedDefect, allDefects, dispatch]);

  const handleToggleMenu = () => {
    if (!erasing) {
      setMenuOpen(!menuOpen);
      triggerHint();
      dispatch(draft => {
        draft.anyMenuOpened = !menuOpen;
      });
    }
  };

  useKeyPress('c', () => handleToggleMenu(), { id: 'task-select-defect' });
  useKeyPress(
    'v',
    () => {
      if (selectedDefect) {
        toggleDefectBookDrawer(selectedDefect.id);
      }
    },
    { id: 'task-open-defect-definition' },
  );

  const notDopdown = useMemo(() => {
    return !allDefects?.length && labelType === LabelType.BoundingBox;
  }, [allDefects?.length, labelType]);

  const [hoveringMenuItemKey, setHoveringMenuItemKey] = useState<number | undefined>(undefined);

  return (
    <Card
      className={cx(styles.toolCard, styles.defectSelector, warning && styles.warningBorder)}
      aria-label="Select Class"
    >
      {erasing && <div className={styles.disableMask} />}
      <>
        <Tooltip
          arrow
          placement="top"
          title={
            <>
              {notDopdown ? t('Create class') : t('Select Class')}
              <span className={styles.codeBlock}>{t('c')}</span>
            </>
          }
        >
          <Button
            id="select-defect-button"
            aria-controls="select-defect-menu"
            ref={anchorEl}
            onClick={e => {
              if (notDopdown) {
                e.stopPropagation();
                e.preventDefault();
                setOpenCreateDialog(true);
              } else {
                handleToggleMenu();
              }
            }}
            aria-label="select-defect"
            className={styles.selectDefectCTA}
            disabled={disabled}
          >
            {notDopdown ? (
              <>
                <Add className={styles.add} />
                <Typography variant="body1" style={{ fontStyle: 'italic' }}>
                  {t('Create Class ')}
                </Typography>
              </>
            ) : selectedDefect ? (
              <DefectItem defect={selectedDefect} hideInfo />
            ) : (
              <Typography variant="body1" style={{ fontStyle: 'italic' }}>
                {t('- Select Class -')}
              </Typography>
            )}

            {!notDopdown && <ExpandMoreRounded />}
          </Button>
        </Tooltip>
        <Menu
          id="select-defect-menu"
          anchorEl={anchorEl.current}
          keepMounted
          open={menuOpen}
          onClose={handleToggleMenu}
          className={styles.defectMenuContainer}
          variant="menu"
        >
          {(allDefects ?? []).map(defect => (
            <MenuItem
              onMouseEnter={() => {
                setHoveringMenuItemKey(defect.id);
              }}
              onMouseLeave={() => {
                setHoveringMenuItemKey(undefined);
              }}
              key={defect.id}
              onClick={() => {
                dispatch(draft => {
                  draft.selectedDefect = defect;
                });
                handleToggleMenu();
              }}
              selected={defect.id === selectedDefect?.id}
            >
              <DefectItem hideInfo={hoveringMenuItemKey !== defect.id} defect={defect} />
            </MenuItem>
          ))}
          <MenuItem onClick={() => setOpenCreateDialog(true)}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={styles.createDefect}
            >
              <Add className={styles.add} />
              {t('Create class')}
            </Box>
          </MenuItem>
        </Menu>
      </>
      {openCreateDialog ? (
        <CreateClassesDialog
          onClose={() => setOpenCreateDialog(false)}
          onCreateSuccess={defect => {
            dispatch(draft => {
              draft.selectedDefect = defect;
            });
            setOpenCreateDialog(false);
            handleToggleMenu();
          }}
          title={t('Create class')}
        />
      ) : null}
    </Card>
  );
};

export default ToolDefectCard;

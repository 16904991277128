import SubscriptionAPI from '../../api/subscription_api';
import fetchHookFactory from './fetchHookFactory';
export const [useSubscriptionPlans] = fetchHookFactory(async () => {
  const res = await SubscriptionAPI.getPlans();
  return res.data || [];
}, 'cache');

export const [useBillingDetails, refreshBillingDetails] = fetchHookFactory(async () => {
  return SubscriptionAPI.getBillingDetails();
}, 'cache');

export const [useInvoiceHistory, refreshInvoiceHistory] = fetchHookFactory(async () => {
  return SubscriptionAPI.getInvoiceHistory();
}, 'cache');

export const [useSubscriptionPlanSettings, refreshSubscriptionPlanSettings] = fetchHookFactory(
  async () => {
    return SubscriptionAPI.getPlanSettings();
  },
  'stale-while-revalidate',
);

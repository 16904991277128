import { useEffect, useRef } from 'react';

/**
 * The hook to add a event listener to window, and remove the event when component unmounts
 * https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
 * @param type the event type to listen to
 * @param listener the listener handler for the event
 * @param options the options such as capture/passive/once
 */
export default function useWindowEventListener<K extends keyof WindowEventMap>(
  // the event type, such as 'scroll', 'resize', 'beforeprint'
  type: K,
  // the listener event
  listener: (ev: WindowEventMap[K]) => void,
  // options to pass to to event listener
  options?: boolean | AddEventListenerOptions,
): void {
  const listenerRef = useRef(listener);

  useEffect(() => {
    listenerRef.current = listener;
  }, [listener]);

  useEffect(() => {
    // Create a closure to be able to always use the latest listenerRef
    const trigger = (ev: WindowEventMap[K]): void => {
      listenerRef.current(ev);
    };
    window.addEventListener(type, trigger, options);
    return (): void => {
      window.removeEventListener(type, trigger, options);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

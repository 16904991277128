import React, { useCallback, useState } from 'react';
import { Grid, makeStyles, TextField } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { FilterOptionType } from '@clef/shared/types';
import { Dropdown, DropdownProps } from '@clef/client-library';
import { useDataBrowserState } from '../dataBrowserState';

const useStyles = makeStyles(theme => ({
  textButton: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  dropdownPanel: {
    minWidth: 150,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  operationValueContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  inputRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
    },
  },
  input: {
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: '0.75rem',
  },
}));

export interface FilterDropdownRawTextProps {
  customizedSelector: (applied: boolean, open: boolean) => React.ReactNode;
  filterOption: FilterOptionType;
  // To be passed to Dropdown
  placement?: DropdownProps['placement'];
  extraGutter?: DropdownProps['extraGutter'];
}

const FilterDropdownRawText: React.FC<FilterDropdownRawTextProps> = ({
  customizedSelector,
  filterOption,
  placement,
  extraGutter,
}) => {
  const styles = useStyles();
  const { filterName, filterType, fieldId } = filterOption;
  const {
    state: { appliedFilters },
    dispatch,
  } = useDataBrowserState();
  const [textValue, setTextValue] = useState<string>(
    appliedFilters[filterName] ? String(appliedFilters[filterName].v) : '',
  );

  const applyRawTextFilter = useCallback(() => {
    dispatch(draft => {
      draft.appliedFilters[filterName] = {
        o: 'LIKE',
        v: textValue,
        t: filterType,
        fi: fieldId,
      };
    });
  }, [dispatch, filterName, filterType, textValue, fieldId]);

  const onValueChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(e.target?.value || '');
  }, []);
  return (
    <Dropdown
      placement={placement}
      extraGutter={extraGutter}
      dropdown={
        <div className={styles.dropdownPanel}>
          <div className={styles.operationValueContainer}>
            <TextField
              variant="outlined"
              onKeyPress={ev => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  applyRawTextFilter();
                }
              }}
              fullWidth
              autoFocus
              onChange={onValueChange}
              value={textValue}
              inputProps={{
                className: styles.input,
                'data-testid': 'filter-raw-text-input',
              }}
              classes={{
                root: styles.inputRoot,
              }}
              size="small"
              data-testid="text-filter-value-field"
            />
          </div>
          {/* Clear all options */}
          <Grid container justifyContent="flex-end">
            <Button
              color="primary"
              size="small"
              className={styles.textButton}
              onClick={applyRawTextFilter}
              disabled={!textValue}
              id="text-filter-apply-btn"
            >
              {t('Search')}
            </Button>
            <Button
              color="secondary"
              size="small"
              className={styles.textButton}
              onClick={() =>
                dispatch(draft => {
                  delete draft.appliedFilters[filterName];
                })
              }
              disabled={!appliedFilters[filterName]}
              id="text-filter-clear-btn"
            >
              {t('Clear')}
            </Button>
          </Grid>
        </div>
      }
    >
      {open => customizedSelector(!!appliedFilters[filterName], open)}
    </Dropdown>
  );
};

export default FilterDropdownRawText;

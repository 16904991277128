import React, { useMemo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import barChartSvg from '@/images/bar_chart.svg';
import horizontalBarChartSvg from '@/images/bar_chart_horizontal.svg';
import timelineSvg from '@/images/timeline.svg';

const useStyles = makeStyles(theme => ({
  emptyRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 1,
  },
  chartIconBg: {
    backgroundColor: theme.palette.blue[50],
    borderRadius: '100%',
    width: 80,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      verticalAlign: 'middle',
    },
  },
  emptyTitle: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    fontFamily: 'Commissioner',
    color: theme.palette.grey[700],
  },
  emptySubtitle: {
    fontSize: 14,
    lineHeight: '20px',
    color: theme.palette.grey[600],
  },
}));

export type ChartEmptyStateProps = {
  chartType: 'vertical_bar' | 'horizontal_bar' | 'line';
  title: string;
  subtitle: string;
  position?: 'absolute' | 'relative';
};

const ChartEmptyState: React.FC<ChartEmptyStateProps> = props => {
  const { chartType, title, subtitle, position = 'relative' } = props;
  const styles = useStyles();
  const svgPath = useMemo(() => {
    if (chartType === 'horizontal_bar') {
      return horizontalBarChartSvg;
    } else if (chartType === 'line') {
      return timelineSvg;
    }
    return barChartSvg;
  }, [chartType]);
  return (
    <Box className={styles.emptyRoot} style={{ position }}>
      <Box className={styles.chartIconBg}>
        <img src={svgPath} />
      </Box>
      <Box marginTop={3} className={styles.emptyTitle}>
        {title}
      </Box>
      <Box marginTop={1} className={styles.emptySubtitle}>
        {subtitle}
      </Box>
    </Box>
  );
};

export default ChartEmptyState;

import {
  compose,
  withState,
  withHandlers,
  lifecycle,
  setPropTypes,
  // @ts-ignore
} from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginView from './LoginView';
import {
  loginUser,
  signUpUser,
  resetError,
  requestVerificationCode,
  confirmResetPassword,
} from '../../store/newLoginState/actions';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { LegacyReduxStates } from '../../store/types';
const TAB_CODE = {
  LOGIN: 0,
  SIGNUP: 1,
  FORGOT: 2,
};

export default compose(
  connect(
    (state: LegacyReduxStates) => ({
      isLoading: state.login.isLoading,
      isAuthenticated: state.login.isAuthenticated,
      error: state.login.error,
      success: state.login.success,
      message: state.login.message,
    }),
    {
      loginUser,
      signUpUser,
      resetError,
      requestVerificationCode,
      confirmResetPassword,
    },
  ),
  withRouter,
  withState('activeTabId', 'setActiveTabId', TAB_CODE.LOGIN),
  withState('nameValue', 'setNameValue', ''),
  withState('lastnameValue', 'setLastnameValue', ''),
  withState('loginValue', 'setLoginValue', ''),
  withState('emailValue', 'setEmailValue', ''),
  withState('passwordValue', 'setPasswordValue', ''),
  withState('isEmailLocked', 'setEmailLocked', false),
  withState('isSignUpDone', 'setSignUpDone', false),
  withState('verificationCode', 'setVerificationCode', null),
  withState('tokenValue', 'setTokenValue', null),
  withState('isCodeRequested', 'setIsCodeRequested', false),
  setPropTypes({
    setActiveTabId: PropTypes.func.isRequired,
    setNameValue: PropTypes.func.isRequired,
    setLastnameValue: PropTypes.func.isRequired,
    setLoginValue: PropTypes.func.isRequired,
    setEmailValue: PropTypes.func.isRequired,
    setPasswordValue: PropTypes.func.isRequired,
    setEmailLocked: PropTypes.func.isRequired,
    setSignUpDone: PropTypes.func.isRequired,
  }),
  withHandlers({
    // TODO: remove all ts-ignores
    // @ts-ignore
    handleDidMount: props => () => {
      const tab = props.location.pathname.split('/').pop();
      if (tab === '1') props.setActiveTabId(TAB_CODE.SIGNUP);
      if (tab === 'forgot') props.setActiveTabId(TAB_CODE.FORGOT);
      const queries = queryString.parse(props.location.search);
      if ('email' in queries) {
        props.setEmailValue(queries.email);
        props.setLoginValue(queries.email);
        props.setEmailLocked(true);
      }
      if ('token' in queries) {
        props.setTokenValue(queries.token);
      }
    },
    // @ts-ignore
    handleForgotButtonClick: props => () => {
      props.setActiveTabId(TAB_CODE.FORGOT);
      props.history.push('/legacy_login/forgot');
    },
    // @ts-ignore
    handleRequestVerificationCode: props => {
      props.setIsCodeRequested(true);
      props.requestVerificationCode(props.emailValue);
    },
    // @ts-ignore
    handleResetPassword: props => {
      props.confirmResetPassword({
        username: props.emailValue,
        verificationCode: props.verificationCode,
        newPassword: props.passwordValue,
      });
    },
    // @ts-ignore
    handleTabChange: props => (e, id) => {
      props.setActiveTabId(id);
    },
    // @ts-ignore
    handleInput: props => e => {
      if (props.error) {
        props.resetError();
      }
      const input = e.target.getAttribute('name');
      const inputValue = e.target.value;
      if (input === 'login') {
        props.setLoginValue(inputValue);
      } else if (input === 'password') {
        props.setPasswordValue(inputValue);
      } else if (input === 'name') {
        props.setNameValue(inputValue);
      } else if (input === 'lastname') {
        props.setLastnameValue(inputValue);
      } else if (input === 'email') {
        props.setEmailValue(inputValue);
        props.setLoginValue(inputValue);
      } else if (input === 'verificationCode') {
        props.setVerificationCode(inputValue);
      } else if (input === 'tokenValue') {
        props.setInviteCode(inputValue);
      }
    },
    // @ts-ignore
    handleLoginButtonClick: props => () => {
      props.loginUser(props.loginValue, props.passwordValue);
    },
    // @ts-ignore
    handleSignUpButtonClick: props => () => {
      props.signUpUser({
        email: props.emailValue,
        name: props.nameValue,
        lastname: props.lastnameValue,
        password: props.passwordValue,
        token: props.tokenValue,
      });
    },
  }),
  lifecycle({
    // @ts-ignore
    componentDidMount() {
      this.props.handleDidMount();
    },
    // @ts-ignore
    componentWillReceiveProps(nextProps) {
      if (!this.props.error && nextProps.error) {
        this.props.setPasswordValue('');
      }
      if (
        nextProps.success &&
        nextProps.activeTabId === TAB_CODE.SIGNUP &&
        !nextProps.isAuthenticated
      ) {
        setTimeout(() => {
          this.props.setActiveTabId(TAB_CODE.LOGIN);
          this.props.history.push('/legacy_login/0' + this.props.location.search);
        }, 2000);
      }
    },
  }),
)(LoginView);

import React, { useCallback } from 'react';
import { makeStyles, MenuItem, MenuList, Paper, Popover } from '@material-ui/core';
import {
  Button,
  calcZoomScaleRange,
  getDisplayZoomValue,
  StepZoomRatio,
  useKeyPress,
  ZoomScale,
} from '@clef/client-library';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useLabelingState } from '../../components/Labeling/labelingState';

const useStyles = makeStyles(theme => ({
  fitButton: {
    width: 110,
    padding: theme.spacing(0, 2),
    margin: theme.spacing(0, 2),
  },
  fitButtonLabel: {
    justifyContent: 'space-between',
  },
  iconButton: {
    minWidth: 32,
    height: 32,
    padding: theme.spacing(0),
    margin: theme.spacing(0, 2),
  },
}));

export type ZoomToolsProps = {
  setZoomScale: (newZoomScale: ZoomScale) => void;
};

const ZoomTools: React.FC<ZoomToolsProps> = ({ setZoomScale }) => {
  const styles = useStyles();

  const { state } = useLabelingState();
  const {
    toolOptions: { zoomScale, fitZoomScale },
  } = state;

  const autoFitText = t('{{fitZoomScale}} (Fit)', {
    fitZoomScale: getDisplayZoomValue(fitZoomScale),
  });
  const zoomScaleNumber = zoomScale === 'fit' ? fitZoomScale : zoomScale;
  const displayZoomValue = zoomScale === 'fit' ? autoFitText : getDisplayZoomValue(zoomScaleNumber);

  const { minZoomScale, maxZoomScale } = calcZoomScaleRange(fitZoomScale);
  // Might need this back
  // const disableZoomIn = zoomScaleNumber >= maxZoomScale;
  // const disableZoomOut = zoomScaleNumber <= minZoomScale;

  const zoomIn = useCallback(() => {
    if (zoomScaleNumber < maxZoomScale) {
      setZoomScale(Math.min(zoomScaleNumber * StepZoomRatio, maxZoomScale));
    }
  }, [maxZoomScale, setZoomScale, zoomScaleNumber]);

  const zoomOut = useCallback(() => {
    if (zoomScaleNumber > minZoomScale) {
      setZoomScale(Math.max(zoomScaleNumber / StepZoomRatio, minZoomScale));
    }
  }, [minZoomScale, setZoomScale, zoomScaleNumber]);

  useKeyPress('=', zoomIn, { id: 'task-zoom-in' });
  useKeyPress('-', zoomOut, { id: 'task-zoom-out' });
  useKeyPress('0', () => setZoomScale('fit'), { id: 'task-zoom-fit' });
  useKeyPress('1', () => setZoomScale(1), { id: 'task-zoom-100' });

  const popupState = usePopupState({
    popupId: 'zoom-popup',
    variant: 'popover',
  });

  return (
    <>
      <Button
        id="instant-learning-zoom-options"
        {...bindTrigger(popupState)}
        className={styles.fitButton}
        classes={{ label: styles.fitButtonLabel }}
        endIcon={<KeyboardArrowDown />}
      >
        {displayZoomValue}
      </Button>
      {/* Might need this back */}
      {/* <Button
        id="instant-learning-zoom-in"
        className={styles.iconButton}
        disabled={disableZoomIn}
        onClick={zoomIn}
      >
        <ZoomInOutlined />
      </Button>
      <Button
        id="instant-learning-zoom-out"
        className={styles.iconButton}
        disabled={disableZoomOut}
        onClick={zoomOut}
      >
        <ZoomOutOutlined />
      </Button> */}
      <Popover {...bindPopover(popupState)}>
        <Paper>
          <MenuList autoFocusItem>
            <MenuItem
              key="AutoFit"
              onClick={() => {
                setZoomScale('fit');
                popupState.close();
              }}
            >
              {autoFitText}
            </MenuItem>
            <MenuItem
              key="OriginalSize"
              onClick={() => {
                setZoomScale(1);
                popupState.close();
              }}
            >
              100%
            </MenuItem>
          </MenuList>
        </Paper>
      </Popover>
    </>
  );
};

export default ZoomTools;

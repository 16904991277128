import React from 'react';

import { RegisteredModel } from '@clef/shared/types';

import { isModelTrainingInProgress } from '@/store/projectModelInfoState/utils';

import ModelInfoCardPerformanceSummary from './ModelInfoCardPerformanceSummary';
import ModelInfoCardTrainingInProgress from './ModelInfoCardTrainingInProgress';

export interface ModelInfoCardProps {
  model: RegisteredModel;
  index?: number;
}

const ModelInfoCard: React.FC<ModelInfoCardProps> = ({ model, index }) => {
  const isModelTraining = isModelTrainingInProgress(model.status, model.metricsReady);

  return isModelTraining ? (
    <ModelInfoCardTrainingInProgress model={model} />
  ) : (
    <ModelInfoCardPerformanceSummary model={model} initialExpand={index === 0} />
  );
};

export default ModelInfoCard;

import { BaseAPI } from './base_api';
import { ProjectId } from '@clef/shared/types';
import { TaskCountPerPurpose } from '../store/types';
import { PageName } from '@clef/shared/constants';

export type LayoutListResponse = {
  allowedSections: PageName[];
  tasksCount: Record<ProjectId, TaskCountPerPurpose>;
  modelsInProgressCount: number;
};
class LayoutAPI extends BaseAPI {
  async list(projectId: ProjectId): Promise<LayoutListResponse> {
    return this.get('list', { projectId }, true);
  }
}

export default new LayoutAPI('layout');

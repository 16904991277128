import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  ImageList,
  ImageListItem,
  Tooltip,
} from '@material-ui/core';
import { Button, IconButton, Img, MediaViewer, Typography } from '@clef/client-library';
import Close from '@material-ui/icons/Close';
import LinkedCameraOutlined from '@material-ui/icons/LinkedCameraOutlined';
import SidebarSection from './SidebarSection';
import DragAndDropZone from './DragAndDropZone';
import DriveFolderUploadIcon from './Icons/DriveFolderUploadIcon';
import FeaturedMediaViewer from './FeaturedMediaViewer';
import WebcamCapture from './WebcamCapture';
import { LivePrediction } from './hooks/useLivePredictions';
import { getFileFromUrl } from '../../pages/continuous_learning/DeviceMonitorDashboard/utils';
import { Defect, LabelType, Media, ProjectId } from '@clef/shared/types';
import useStyles from './styles';
import { MainWrapper } from './MainWrapper';
import DragAndDropArea from './DragAndDropArea';
import WebcamFileSwitchButton from '../WebcamFileSwitchButton';
import CameraIcon from './Icons/CameraIcon';
import { ReachLimitModelDialog } from '@/pages/DataBrowser/TrainModelButtonGroup/ReachLimitModelDialog';
import { useCheckCreditReachLimit } from '@/hooks/useSubscriptions';
import { useModelInferenceCost } from '@/hooks/api/useExperimentReportApi';
import InfoIcon from '@material-ui/icons/Info';
import {
  ClientFeatures,
  useFeatureGateEnabled,
  useIsFeatureEnabledAndMayHideForSnowflake,
} from '@/hooks/useFeatureGate';
import { useDefectSelectorWithArchived } from '../../store/defectState/actions';
import { StepName } from '@/types/client';
import { useWorkflowAssistantState } from '../WorkflowAssistant/state';
import { usePredictWithModel } from './usePredictWithModel';

const imageFormat = 'image/jpeg';
const imageName = () => new Date().toISOString() + '_sample_image';
const MaxFilesLength = 40;

export interface PredictProps {
  selectedProject: {
    projectId: ProjectId;
    datasetId: number | undefined;
    labelType: LabelType | undefined | null;
    name: string;
    description?: string;
  };
  defectMap: Defect[];
  modelInfo: {
    id: string | undefined;
    threshold: number | undefined;
  };
  updateThreshold?: (newThreshold: number) => void;
  title?: string;
  bannerComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
  deployComponent?: React.ReactNode;
  displayTitle?: boolean;
  onCloseModal?: (event: React.MouseEvent) => void;
  classes?: {
    container?: string;
    wrapper?: string;
    bannerContainer?: string;
  };
  children?: (predictComponent: ReactNode) => ReactNode;
  includeHelpImages?: boolean;
  trialData?: Media[];
  enableModelRefresh?: boolean;
}

export const Predict: React.FC<PredictProps> = ({
  selectedProject,
  modelInfo,
  defectMap,
  updateThreshold,
  title,
  onCloseModal,
  classes,
  bannerComponent,
  footerComponent,
  children,
  includeHelpImages = false,
  trialData,
  deployComponent,
}) => {
  const [selectedMedia, setSelectedMedia] = useState(0);
  const [isWebcamOn, setIsWebcamOn] = useState<boolean>(false);
  const styles = useStyles();
  const dragAreaRef = useRef<HTMLDivElement | null>(null);
  const [openReachLimitModelDialog, setOpenReachLimitModelDialog] = useState(false);
  const onPredictingMediaCount = useRef(0);
  const { checkReachLimitApplyingCost } = useCheckCreditReachLimit();
  const isCreditReferenceEnabled = useIsFeatureEnabledAndMayHideForSnowflake().creditReference;

  const { livePredictions, setLivePredictions, clearLivePredictions, getImageFilePredictions } =
    usePredictWithModel({
      selectedProjectId: selectedProject.projectId,
      selectedDatasetId: selectedProject.datasetId,
      selectedModelId: modelInfo?.id,
    });
  const { state, dispatch: workflowAssistantDispatch } = useWorkflowAssistantState();

  const isProcessing = livePredictions.some(prediction => prediction.loading);
  const processedCount = livePredictions.filter(prediction => prediction.result).length;

  const shouldUseAdvancedPricing = useFeatureGateEnabled(ClientFeatures.AdvancedUsageBasedPricing);
  const isWebcamEnabled = useIsFeatureEnabledAndMayHideForSnowflake().webcam;

  const [inferenceCost, inferenceCostLoading] = useModelInferenceCost(
    shouldUseAdvancedPricing && modelInfo?.id
      ? {
          projectId: selectedProject.projectId,
          jobId: modelInfo.id,
        }
      : undefined,
  );

  const onPredict = useCallback(
    async (files: File[]) => {
      onPredictingMediaCount.current = files.length;
      const shouldOpenReachLimitDialog = checkReachLimitApplyingCost(
        onPredictingMediaCount.current,
      );
      if (shouldOpenReachLimitDialog) {
        setOpenReachLimitModelDialog(true);
      } else {
        await getImageFilePredictions(files);
      }
    },
    [checkReachLimitApplyingCost, getImageFilePredictions],
  );

  useEffect(
    () => () => {
      clearLivePredictions();
    },
    [clearLivePredictions],
  );

  const handleOnClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (onCloseModal) {
      onCloseModal(event);
    }
    livePredictions.length > 0 &&
      state?.step?.stepName === StepName.Predict &&
      workflowAssistantDispatch(state => {
        state.step = { stepName: StepName.Complete, stepStarted: false, stepPointsCompleted: 0 };
      });
  };
  // mark as archived if the defect is archived in the current project
  const allDefectsWithArchived = useDefectSelectorWithArchived();
  const defectMapWithArchiveInfo = useMemo(() => {
    return defectMap?.map(defect => {
      const currentDefect = allDefectsWithArchived.find(def => def.id === defect.id);
      if (currentDefect) {
        return {
          ...defect,
          name: currentDefect.name,
          color: currentDefect.color,
        } as Defect;
      }
      return defect;
    });
  }, [defectMap, allDefectsWithArchived]);

  const helpImages = useMemo(() => {
    return trialData?.map(data => ({
      id: data?.md5 || String(data.id),
      loading: false,
      imgSrc: data?.url || '',
      s3url: data?.path,
      name: data.name,
    }));
  }, [trialData]);

  const wrapper = (component: React.ReactNode) => (
    <>
      <Dialog
        open
        onClose={onCloseModal}
        maxWidth="lg"
        fullWidth
        classes={{ paper: styles.dialogPaper }}
      >
        <Box className={cx(styles.predictContainer, classes?.container)}>
          <Box className={cx(styles.predictWrapper, classes?.wrapper)}>
            {onCloseModal && (
              <IconButton
                id="close-predict-modal"
                data-testid="close-predict-modal"
                className={styles.predictCloseButton}
                onClick={handleOnClose}
              >
                <Close />
              </IconButton>
            )}
            <Box className={styles.predictTitleWrapper}>
              <Typography variant="h2" className={styles.predictTitle}>
                {t(title || selectedProject?.name || '')}
              </Typography>
            </Box>
            <Box className={styles.predictContent}>
              {typeof children === 'function' ? children(component) : component}
            </Box>
          </Box>
        </Box>
      </Dialog>
      {selectedProject.datasetId && (
        <ReachLimitModelDialog
          projectId={selectedProject.projectId}
          datasetId={selectedProject.datasetId}
          onPredicting
          onPredictingMediaCount={onPredictingMediaCount.current}
          open={openReachLimitModelDialog}
          onClose={() => {
            setOpenReachLimitModelDialog(false);
            onPredictingMediaCount.current = 0;
          }}
        />
      )}
    </>
  );

  const onOpenCamera = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsWebcamOn(true);
  };

  const onCloseCamera = () => {
    setIsWebcamOn(false);
  };

  const handleOnSelectMedia = async (index: number, prediction: LivePrediction) => {
    setSelectedMedia(index);
    if (prediction?.error) {
      return;
    }

    if (!prediction?.result && prediction?.imgSrc) {
      const file = await getFileFromUrl(prediction.imgSrc, imageName(), imageFormat);
      getImageFilePredictions({ file, uuid: prediction.id, s3url: prediction?.s3url });
    }
  };

  const onImagePreview = useCallback(async () => {
    const [image, ...restImages] = helpImages!;
    const { imgSrc, id, s3url } = image;
    setLivePredictions([{ ...image, loading: true }, ...restImages]);
    const file = await getFileFromUrl(imgSrc, imageName(), imageFormat);
    getImageFilePredictions({ file, uuid: id, s3url });
  }, [getImageFilePredictions, helpImages, setLivePredictions]);

  const horizontalListActionsContent = useMemo(
    () => (
      <Grid item container xs={12} md={12} sm={12} className={styles.horizontalList}>
        {livePredictions.length < MaxFilesLength && (
          <Grid item className={styles.horizontalListActions}>
            <Box className={styles.horizontalListIconWrapper}>
              <DragAndDropZone getPredictions={onPredict} maxFilesLength={MaxFilesLength}>
                <DriveFolderUploadIcon />
              </DragAndDropZone>
            </Box>
            {isWebcamEnabled && (
              <Tooltip arrow title={t('Upload an image taken from your webcam.')}>
                <Box
                  className={styles.horizontalListIconWrapper}
                  onClick={onOpenCamera}
                  data-testid="webcam-open-button"
                >
                  <LinkedCameraOutlined />
                </Box>
              </Tooltip>
            )}
          </Grid>
        )}
        <Grid item xs>
          <ImageList className={styles.imageList} data-testid="media-list">
            {livePredictions.map((prediction: LivePrediction, index: number) => {
              return (
                <ImageListItem
                  key={`media-prediction-${index}`}
                  {...(selectedMedia === index && {
                    'data-testid': 'selected-featured-image',
                  })}
                  classes={{
                    root: styles.imageListItemRoot,
                  }}
                >
                  <MediaViewer
                    imgSrc={prediction?.imgSrc}
                    onClick={() =>
                      includeHelpImages
                        ? handleOnSelectMedia(index, prediction)
                        : setSelectedMedia(index)
                    }
                    selected={selectedMedia === index}
                    classes={{ root: styles.mediaViewerRoot }}
                    wrapperStyle={selectedMedia === index ? { border: '4px solid #0056FE' } : {}}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        </Grid>
      </Grid>
    ),
    [
      onPredict,
      handleOnSelectMedia,
      includeHelpImages,
      livePredictions,
      selectedMedia,
      styles.horizontalList,
      styles.horizontalListActions,
      styles.horizontalListIconWrapper,
      styles.imageList,
      styles.imageListItemRoot,
      styles.mediaViewerRoot,
    ],
  );

  if (isWebcamEnabled && isWebcamOn) {
    return wrapper(
      <Box className={cx(styles.predictContainer, classes?.container)} flexDirection="column">
        <WebcamCapture getPredictions={onPredict} onCloseCamera={onCloseCamera} />
      </Box>,
    );
  }

  if (!livePredictions.length && !isWebcamOn) {
    return wrapper(
      <DragAndDropZone
        getPredictions={onPredict}
        dropzoneProps={{
          noClick: !!livePredictions.length,
        }}
        maxFilesLength={MaxFilesLength}
      >
        <Grid container classes={{ root: styles.startScreenRoot }}>
          <div ref={dragAreaRef} className={styles.dragAndDrop}>
            <DragAndDropArea
              title={t('Drop images here')}
              label={
                !isCreditReferenceEnabled ? (
                  ''
                ) : shouldUseAdvancedPricing ? (
                  !inferenceCostLoading ? (
                    <>
                      {t(
                        `${inferenceCost?.data.inferenceCredits ?? 1} ${
                          inferenceCost?.data.inferenceCredits ?? 1 > 1 ? 'credits' : 'credit'
                        } / image`,
                      )}
                      <Tooltip
                        placement="top"
                        title={t(
                          'Prediction cost is calculated based on training settings (image resize, model size) and project type. ',
                        )}
                        arrow
                      >
                        <InfoIcon className={styles.infoIcon} />
                      </Tooltip>
                    </>
                  ) : (
                    <CircularProgress size={20} />
                  )
                ) : (
                  t('Testing consumes 1 credit per inference')
                )
              }
              position="static"
            >
              {isWebcamEnabled && (
                <Button
                  id="webcam-open-button"
                  target="_blank"
                  color="primary"
                  startIcon={
                    <span className={styles.cameraIcon}>
                      <CameraIcon />
                    </span>
                  }
                  className={styles.uploadButton}
                  onClick={onOpenCamera}
                >
                  <WebcamFileSwitchButton useWebcam defaultStyles={true} />
                </Button>
              )}
            </DragAndDropArea>
          </div>

          {includeHelpImages && !!helpImages?.length && (
            <Box
              position="absolute"
              bottom="60px"
              right="53px"
              display="flex"
              flexDirection="column"
            >
              <Typography variant="body1" className={styles.startScreenImagesText}>
                {t('Try with images below')}
              </Typography>
              <Box
                draggable
                onDragEnd={async e => {
                  const {
                    x: targetX,
                    y: targetY,
                    height: targetHeight,
                    width: targetWidth,
                  } = dragAreaRef.current!.getBoundingClientRect();
                  const { clientX, clientY } = e;
                  if (
                    clientX >= targetX &&
                    clientX <= targetX + targetWidth &&
                    clientY >= targetY &&
                    clientY <= targetY + targetHeight
                  ) {
                    onImagePreview();
                  }
                }}
              >
                <ImageList
                  className={styles.predictImageList}
                  cols={helpImages.length}
                  rowHeight={100}
                  gap={8}
                  onClick={(event: React.MouseEvent) => {
                    event.stopPropagation();
                    onImagePreview();
                  }}
                >
                  {helpImages.map(({ id, imgSrc }) => {
                    return (
                      <ImageListItem key={id} classes={{ item: styles.predictImageListItem }}>
                        <Img
                          src={imgSrc}
                          draggable={false}
                          className={styles.predictImage}
                          crossOrigin="use-credentials"
                        />
                      </ImageListItem>
                    );
                  })}
                </ImageList>
              </Box>
            </Box>
          )}
        </Grid>
      </DragAndDropZone>,
    );
  }

  return wrapper(
    <DragAndDropZone
      getPredictions={getImageFilePredictions}
      dropzoneProps={{
        noClick: !!livePredictions.length,
      }}
      maxFilesLength={MaxFilesLength}
    >
      <Box className={styles.containerGrid} style={{ paddingBottom: footerComponent ? '40px' : 0 }}>
        {selectedProject.labelType === LabelType.SegmentationInstantLearning ? (
          <Grid container className={styles.fullHeight}>
            <Grid
              container
              item
              className={
                bannerComponent
                  ? styles.instantLearningPredictGridWithBanner
                  : styles.instantLearningPredictGrid
              }
            >
              <Grid item xs={8}>
                <Box>
                  <Grid
                    data-testid="predict-result-container"
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    classes={{
                      root: bannerComponent
                        ? styles.instantLearningFeaturedGridContainerWithBanner
                        : styles.instantLearningFeaturedGridContainer,
                    }}
                  >
                    <MainWrapper
                      prediction={livePredictions[selectedMedia]}
                      threshold={modelInfo.threshold || 0}
                      defects={defectMapWithArchiveInfo || []}
                      labelType={selectedProject.labelType}
                    >
                      {annotations => (
                        <Grid item xs={12} md={12} sm={12} className={styles.featuredGrid}>
                          <FeaturedMediaViewer
                            labelType={selectedProject.labelType}
                            prediction={livePredictions[selectedMedia]}
                            annotations={annotations}
                            openReachLimitModelDialog={() => {
                              setOpenReachLimitModelDialog(true);
                            }}
                          />
                        </Grid>
                      )}
                    </MainWrapper>
                  </Grid>
                  {horizontalListActionsContent}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box height="100%" width="100%">
                  <MainWrapper
                    prediction={livePredictions[selectedMedia]}
                    threshold={modelInfo.threshold || 0}
                    defects={defectMapWithArchiveInfo || []}
                    labelType={selectedProject.labelType}
                  >
                    {annotations => (
                      <SidebarSection
                        modelInfo={modelInfo}
                        labelType={selectedProject?.labelType}
                        prediction={livePredictions[selectedMedia]}
                        updateThreshold={updateThreshold}
                        defects={defectMapWithArchiveInfo || []}
                        deployComponent={deployComponent}
                        annotations={annotations}
                      />
                    )}
                  </MainWrapper>
                </Box>
              </Grid>
            </Grid>
            {bannerComponent && (
              <Grid
                container
                item
                xs={12}
                md={12}
                sm={12}
                className={cx(styles.predictBanner, classes?.bannerContainer)}
              >
                {bannerComponent}
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid container>
            <Grid
              data-testid="predict-result-container"
              container
              item
              xs={12}
              md={12}
              sm={12}
              classes={{ root: styles.predictGrid }}
            >
              <MainWrapper
                prediction={livePredictions[selectedMedia]}
                threshold={modelInfo.threshold || 0}
                defects={defectMapWithArchiveInfo || []}
                labelType={selectedProject.labelType}
              >
                {annotations => (
                  <>
                    <Grid item xs={8} md={8} sm={8} className={styles.featuredGrid}>
                      <FeaturedMediaViewer
                        labelType={selectedProject.labelType}
                        prediction={livePredictions[selectedMedia]}
                        annotations={annotations}
                        openReachLimitModelDialog={() => {
                          setOpenReachLimitModelDialog(true);
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} className={styles.predictSidebarSection}>
                      <SidebarSection
                        modelInfo={modelInfo}
                        labelType={selectedProject?.labelType}
                        prediction={livePredictions[selectedMedia]}
                        updateThreshold={updateThreshold}
                        defects={defectMapWithArchiveInfo || []}
                        deployComponent={deployComponent}
                        annotations={annotations}
                      />
                    </Grid>
                  </>
                )}
              </MainWrapper>
            </Grid>
            {horizontalListActionsContent}
            {bannerComponent && (
              <Grid
                container
                item
                xs={12}
                md={12}
                sm={12}
                className={cx(styles.predictBanner, classes?.bannerContainer)}
              >
                {bannerComponent}
              </Grid>
            )}
          </Grid>
        )}

        {isProcessing && (
          <Box ml={13} mt={4}>
            <Typography variant="body_semibold">
              {t(`Processed ${processedCount} images...`)}
            </Typography>
          </Box>
        )}

        {footerComponent && footerComponent}
      </Box>
    </DragAndDropZone>,
  );
};

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { AppBar, Typography, Grid, Badge, LinearProgress, Tooltip } from '@material-ui/core';
import { IconButton, Button, useWindowEventListener } from '@clef/client-library';
import {
  Task,
  LabelReviewStatus,
  MediaId,
  TaskPurpose,
  LabelSource,
  AnnotationWithoutId,
  Annotation,
} from '@clef/shared/types';
import { useLabelingReviewStyles } from '../labelingReviewStyles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { useLabelingReviewState } from '../labelingReviewState';
import CLEF_PATH from '../../../constants/path';
import {
  postSubmitLabelingReviewResult,
  refreshTaskInfo,
  refreshTaskLabelingLabelingMediaToReview,
  refreshTaskMediaStats,
  useTasksMediaStats,
  refreshUseMyTask,
} from '../../../hooks/api/useTaskApi';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useSnackbar } from 'notistack';
import { useHotKeyDialog } from '../../../hooks/useHotKeyDialog';
import { usePageLayoutState } from '../../../components/Layout/PageLayout/state';
import useGoBack from '../../../hooks/useGoBack';
import LabelingReviewHeaderSubmitDialog from './LabelingReviewHeaderPanelSubmitDialog';
import LabelApi from '../../../api/label_api';
import { LoginState } from '../../../store/types';
import { useQueryClient } from '@tanstack/react-query';
import { datasetQueryKeys } from '@/serverStore/dataset';
import { layoutQueryKeys } from '@/serverStore/layout';
import { useDialog } from '@/components/Layout/components/useDialog';

const commonHotKeys = [
  { description: t('next media'), keys: ['ArrowDown'] },
  { description: t('previous media'), keys: ['ArrowUp'] },
  { description: t('sort media by agreement score'), keys: ['s'] },
  { description: t('open review notes'), keys: ['o'] },
  { description: t('add metadata'), keys: ['m'] },
  { description: t('add to label book'), keys: ['d'] },
];

const reviewOnlyHotKeys = [
  { description: t('decrease agreement threshold'), keys: ['ArrowLeft'] },
  { description: t('increase agreement threshold'), keys: ['ArrowRight'] },
  { description: t('accept media + next media'), keys: ['a'] },
  { description: t('reject media + next media'), keys: ['r'] },
];

export interface LabelingReviewHeaderProps {
  taskInfo: Task;
}

const LabelingReviewHeader: React.FC<LabelingReviewHeaderProps> = ({ taskInfo }) => {
  const styles = useLabelingReviewStyles();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: selectedProject } = useGetSelectedProjectQuery();
  const user = useTypedSelector(state => state.login.user)!;
  const [isSubmittingResult, setIsSubmittingResult] = useState(false);
  const {
    state: { reviewResult, isViewResultMode, reviewMediaList },
    dispatch,
  } = useLabelingReviewState();

  const { dispatch: dispatchPageLayout } = usePageLayoutState();

  // shortcut dialog
  const { icon: hotkeysIcon, dialog: hotkeyDialog } = useHotKeyDialog(
    isViewResultMode ? commonHotKeys : [...commonHotKeys, ...reviewOnlyHotKeys],
  );
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);

  const rejectMediaList = useMemo(() => {
    return reviewMediaList.filter(media => {
      const reviewStatus = reviewResult[media.id]?.reviewStatus ?? LabelReviewStatus.NotReviewed;
      return reviewStatus === LabelReviewStatus.Rejected;
    });
  }, [reviewMediaList, reviewResult]);

  const [totalPendingReview, totalAccepted, totalRejected] = Object.values(reviewResult).reduce(
    (acc, review) => {
      return [
        acc[0] + +(review.reviewStatus === LabelReviewStatus.NotReviewed),
        acc[1] + +(review.reviewStatus === LabelReviewStatus.Accepted),
        acc[2] + +(review.reviewStatus === LabelReviewStatus.Rejected),
      ];
    },
    [0, 0, 0],
  );
  useEffect(() => {
    dispatchPageLayout(draft => {
      draft.metaTitle = `Review Task - ${taskInfo.taskName}`;
    });
  }, [dispatchPageLayout, taskInfo.taskName]);

  const { showConfirmationDialog } = useDialog();

  const hasUnsavedChanges = useMemo(() => {
    return Object.values(reviewResult).some(
      ({ reviewStatus }) => reviewStatus !== LabelReviewStatus.NotReviewed,
    );
  }, [reviewResult]);

  const goBack = useGoBack(CLEF_PATH.label.main);
  const goBackWithValidation = useCallback(() => {
    if (hasUnsavedChanges) {
      showConfirmationDialog({
        title: t('Unsaved changes'),
        content: t('Are you sure you want to leave? Your changes will be lost.'),
        confirmText: t('Discard changes'),
        color: 'secondary',
        onConfirm: () => {
          goBack();
        },
      });
    } else {
      goBack();
    }
  }, [goBack, hasUnsavedChanges, showConfirmationDialog]);

  const windowConfirmation = useCallback(
    (event: WindowEventMap['beforeunload']) => {
      if (hasUnsavedChanges) {
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
      }
    },
    [hasUnsavedChanges],
  );
  useWindowEventListener('beforeunload', windowConfirmation);

  const taskId = taskInfo.id;

  const onSubmit = useCallback(
    async (reassignMediaIds?: MediaId[]) => {
      if (!reassignMediaIds) {
        setIsSubmittingResult(true);
      }
      const datasetId = selectedProject?.datasetId;
      if (!datasetId) return;
      const approvedAndRejectedReviewResult = Object.entries(reviewResult)
        .filter(
          ([_, value]) =>
            value.reviewStatus === LabelReviewStatus.Accepted ||
            value.reviewStatus === LabelReviewStatus.Rejected,
        )
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {} as typeof reviewResult);
      try {
        // 1. get labeler's edits
        const mediasWithReviewEdit = [] as MediaId[];
        const mediaIdToLabels = reviewMediaList.reduce((acc, media) => {
          const reviewerEditLabels = media.labels.filter(
            label => label.source === LabelSource.LabelingReviewEdit,
          );
          if (reviewerEditLabels.length > 0) {
            mediasWithReviewEdit.push(media.id);
          }
          return {
            ...acc,
            [media.id]: reviewerEditLabels.map(({ id, annotations }) => ({
              id,
              // don't pass annotation
              annotationList: (annotations as Annotation[])?.map(({ id, ...rest }) => rest) || [],
            })),
          };
        }, {} as Record<MediaId, { id?: number; annotationList: AnnotationWithoutId[] }[]>);
        let reviewResultForSubmit = approvedAndRejectedReviewResult;
        if (mediasWithReviewEdit.length > 0) {
          // 2. call API to submit labels and get created labels
          const { data: serverMediaIdToLabels } = await LabelApi.setBatchAnnotationsForMedias(
            taskId,
            mediaIdToLabels,
            true,
          );

          // 3. replace temporary label IDs to server label IDs
          const labelIdMappingPerMedia = Object.entries(mediaIdToLabels).reduce(
            (acc, [mediaId, labels]) => {
              const serverLabels = serverMediaIdToLabels[Number(mediaId)];
              const labelIdMapping = {} as Record<number, number>;
              labels.forEach((label, index) => {
                labelIdMapping[label.id!] = serverLabels[index].id;
              });
              return {
                ...acc,
                [mediaId]: labelIdMapping,
              };
            },
            {} as { [mediaId: number]: { [tempLabelId: number]: number } },
          );

          reviewResultForSubmit = Object.entries(approvedAndRejectedReviewResult).reduce(
            (acc, [mediaId, result]) => {
              const accResult = { ...result };
              if (result.selectedLabel !== undefined) {
                accResult.selectedLabel =
                  labelIdMappingPerMedia[Number(mediaId)][result.selectedLabel] ||
                  accResult.selectedLabel;
              }
              return { ...acc, [mediaId]: accResult };
            },
            {} as typeof approvedAndRejectedReviewResult,
          );
        }
        // 4. submit review
        const { haveMediaToReview } = await postSubmitLabelingReviewResult(
          datasetId,
          taskInfo!.id,
          user.id!,
          reviewResultForSubmit,
          reassignMediaIds,
        );
        selectedProject &&
          queryClient.invalidateQueries(datasetQueryKeys.allWithFilters(selectedProject.id));
        setOpenSubmitDialog(false);
        if (reassignMediaIds) {
          enqueueSnackbar(t('Reassign success'), { variant: 'success' });
        } else {
          enqueueSnackbar(
            t('Success submitted review for {{temp0}} media.', {
              temp0: totalAccepted + totalRejected,
            }),
            { variant: 'success' },
          );
        }
        if (mediasWithReviewEdit.length > 0) {
          mediasWithReviewEdit.forEach(mediaId =>
            queryClient.invalidateQueries(
              datasetQueryKeys.mediaDetails(datasetId, {
                mediaId,
              }),
            ),
          );
        }
        selectedProject && queryClient.invalidateQueries(layoutQueryKeys.list(selectedProject.id));
        if (!haveMediaToReview) {
          refreshUseMyTask({ keys: 'refresh-all' });
          refreshTaskInfo({ keys: 'refresh-all' }); // refresh task because task might have finished review
          refreshTaskLabelingLabelingMediaToReview({ keys: 'refresh-all' });
          goBack();
          return;
        }
        refreshTaskLabelingLabelingMediaToReview({ keys: 'refresh-all' });
        refreshUseMyTask({ keys: 'refresh-all' });
        dispatch(draft => {
          draft.currentMediaId = 0;
          draft.reviewResult = {};
          draft.reviewMediaList = [];
        });
        refreshTaskMediaStats({
          keys: 'refresh-all',
        });
      } catch (err) {
        enqueueSnackbar(((err as any)?.body || err)?.message, { variant: 'error' });
      } finally {
        setIsSubmittingResult(false);
      }
    },
    [
      dispatch,
      enqueueSnackbar,
      goBack,
      reviewMediaList,
      reviewResult,
      selectedProject?.datasetId,
      taskId,
      taskInfo,
      totalAccepted,
      totalRejected,
      user.id,
    ],
  );

  const onSubmitBtnClick = useCallback(async () => {
    if (totalRejected > 0) {
      setOpenSubmitDialog(true);
    } else {
      onSubmit();
    }
  }, [onSubmit, totalRejected]);

  const [taskMediaStatsResponse] = useTasksMediaStats(
    selectedProject
      ? {
          projectId: selectedProject.id,
          purposes: [TaskPurpose.BatchLabeling, TaskPurpose.MultiLabeling],
          taskIds: [taskId],
        }
      : undefined,
  );

  const taskStats = taskMediaStatsResponse?.data[taskId];
  const assignedCount = useMemo(() => {
    if (!taskStats || !taskInfo) {
      return undefined;
    }
    const { approved, rejected, labeled } = taskStats;
    return taskInfo.mediaCount - approved - rejected - labeled;
  }, [taskInfo, taskStats]);

  const defectBookUrl = useMemo(() => {
    let url = CLEF_PATH.data.defectBookEnhanced;
    url = url.replace(
      'app',
      `app/${(user as LoginState['user'])?.orgId}/pr/${selectedProject?.id}`,
    );
    return url;
  }, [selectedProject?.id, user]);

  return (
    <>
      {isSubmittingResult && (
        <div className={styles.postRequestMask}>
          <LinearProgress />
        </div>
      )}
      {hotkeyDialog}
      <AppBar elevation={0} className={styles.appBar} color="default" position="fixed">
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          wrap="nowrap"
          alignItems="center"
          data-testid="labeling-review-header"
        >
          <Grid
            item
            container
            justifyContent="flex-start"
            direction="row"
            wrap="nowrap"
            alignItems="center"
          >
            <IconButton id="go-back" onClick={goBackWithValidation}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4" style={{ marginLeft: 8 }}>
              {taskInfo.taskName}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            direction="row"
            wrap="nowrap"
            alignItems="center"
            data-testid="labeling-review-header-stats"
          >
            {!!assignedCount && (
              <>
                <Typography variant="h4" className={styles.appBarTaskAssignedProgress}>
                  {t('Assigned')}
                </Typography>
                <Tooltip
                  arrow
                  title={t(
                    'The number of rejected media which is already reassigned in the \
                    whole labeling task',
                  )}
                >
                  <InfoOutlined className={styles.tooltipIcon} fontSize="small" />
                </Tooltip>
                <Typography variant="h4" className={styles.appBarTaskAssignedProgressIcon}>
                  :
                </Typography>
                <Typography variant="h2" data-testid="assigned-count">
                  {assignedCount}
                </Typography>
              </>
            )}
            {!isViewResultMode && (
              <>
                <Typography variant="h4" className={styles.appBarTaskProgress}>
                  {t('In Review:')}
                </Typography>
                <Typography variant="h2" data-testid="pending-count">
                  {totalPendingReview}
                </Typography>
              </>
            )}
            <Typography variant="h4" className={styles.appBarTaskProgress}>
              {t('Accepted:')}
            </Typography>
            <Typography variant="h2" data-testid="accept-count">
              {totalAccepted}
            </Typography>
            <Typography variant="h4" className={styles.appBarTaskProgress}>
              {t('Rejected:')}
            </Typography>
            <Typography variant="h2" data-testid="reject-count">
              {totalRejected}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            direction="row"
            wrap="nowrap"
            alignItems="center"
          >
            {hotkeysIcon}
            <Button
              id="open-label-book"
              color="primary"
              variant="outlined"
              className={styles.actionButton}
              endIcon={<OpenInNewIcon />}
              target="_blank"
              href={defectBookUrl}
            >
              {t('Label Book')}
            </Button>
            {!isViewResultMode && (
              <Badge color="secondary" badgeContent={totalAccepted + totalRejected}>
                <Button
                  id="submit-labeling-review"
                  color="primary"
                  variant="contained"
                  disabled={!(totalRejected + totalAccepted)}
                  className={styles.actionButton}
                  onClick={onSubmitBtnClick}
                  data-testid="media-submit-btn"
                >
                  {t('Submit')}
                </Button>
              </Badge>
            )}
          </Grid>
        </Grid>
        <LabelingReviewHeaderSubmitDialog
          dialogProps={{
            open: openSubmitDialog,
            onClose: () => setOpenSubmitDialog(false),
          }}
          rejectMediaList={rejectMediaList}
          totalRejected={totalRejected}
          onSubmit={onSubmit}
        />
      </AppBar>
    </>
  );
};

export default LabelingReviewHeader;

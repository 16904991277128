import {
  ProjectId,
  Task,
  TaskId,
  TaskMediaStats,
  TaskPurpose,
  UserId,
  UserTaskStatus,
  TaskStatus,
  ReviewType,
} from '@clef/shared/types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@clef/client-library';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import merge from 'lodash/merge';
import MaterialTable, { MTableBodyRow } from '@material-table/core';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TaskAPI from '../../api/task_api';
import CLEF_PATH from '../../constants/path';
import { TaskManagementRow } from '../../store/types';
import { getReadableText } from '../../utils';
import TaskAssignmentDialog from '../TaskAssignmentDialog/TaskAssignmentDialog';
import { UserAvatar } from '../UserAvatar/UserAvatarById';
import ConfirmationDialog from '../Utils/ConfirmationDialog';
import { tableIcons } from '../Utils/MatTable';
import { ROW_COUNT_OPTIONS, ROW_DEFAULT_COUNT } from './constant';
import MultiColorProgressBar from './ProgressBar';
import { useTaskListStyles } from './styles';
import { Box, MenuItem, MenuList, Typography } from '@material-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@clef/client-library';
import { cancelTaskApi } from '../../hooks/api/useTaskApi';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { Column } from '@material-table/core/types';
import { Dropdown } from '@clef/client-library';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AssessmentRounded from '@material-ui/icons/AssessmentRounded';
import CancelRounded from '@material-ui/icons/CancelRounded';
import MoreVert from '@material-ui/icons/MoreVert';
import { defaultState as defaultTaskState, TaskContext } from './taskState';
import { useImmer } from 'use-immer';
import { datasetQueryKeys } from '@/serverStore/dataset';
import { layoutQueryKeys } from '@/serverStore/layout';

type TaskListProps = {};

type StatType = 'approved' | 'pendingLabeling' | 'pendingReview' | 'rejected';

const statsDisplaySpec = {
  approved: { text: t('Approved'), color: '#8CC947' },
  pendingReview: { text: t('In Review'), color: '#c0d6ed' },
  pendingLabeling: { text: t('Assigned'), color: '#f3f3f3' },
  rejected: { text: t('Rejected'), color: '#ef4747' },
};

const TaskList: React.FC<TaskListProps> = () => {
  const classes = useTaskListStyles();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const [isAssignmentDialogOpen, setIsAssignmentDialogOpen] = useState(false);
  const [isCloseTaskDialogOpen, setIsCloseTaskDialogOpen] = useState(false);
  const [taskToAssign, setTaskToAssign] = useState<Task | null>(null);
  const [taskToClose, setTaskToClose] = useState<Task | null>(null);
  const history = useHistory();
  const [taskState, dispatchTaskState] = useImmer(defaultTaskState);
  const { tasks, tasksStats } = taskState;

  const { enqueueSnackbar } = useSnackbar();

  const content = <CircularProgress />;

  const fetchTasksAndStats = useCallback(
    async (projectId: ProjectId) => {
      const purposes = [TaskPurpose.BatchLabeling, TaskPurpose.MultiLabeling];
      try {
        const [tasksRes, statsRes] = await Promise.all([
          TaskAPI.fetchTasks(projectId),
          TaskAPI.fetchTasksMediaStats(projectId, purposes),
        ]);
        dispatchTaskState(draft => {
          draft.tasks = tasksRes.data;
          draft.tasksStats = statsRes.data;
        });
      } catch (e) {
        enqueueSnackbar(e.message, {
          variant: 'warning',
        });
      }
    },
    [dispatchTaskState, enqueueSnackbar],
  );

  const init = useCallback(() => {
    if (selectedProject) {
      fetchTasksAndStats(selectedProject.id);
    }
  }, [fetchTasksAndStats, selectedProject]);

  useEffect(() => {
    init();
  }, [init]);

  const createLegendItem = useCallback((statType: StatType): React.ReactNode => {
    return (
      <Grid item>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FiberManualRecordIcon
            color="primary"
            style={{ fill: statsDisplaySpec[statType].color }}
          />
          <Typography variant="body2">{statsDisplaySpec[statType].text}</Typography>
        </div>
      </Grid>
    );
  }, []);

  const getDateFormatted = useCallback((time: string): React.ReactNode => {
    const dateStr = new Date(time).toLocaleString() || '';
    const arr = dateStr.split(',');

    return (
      <div>
        <div>{arr[0]}</div>
      </div>
    );
  }, []);

  const renderStats = useCallback(
    (stats: TaskMediaStats): React.ReactNode => {
      const statsValues: Record<StatType, { value: number }> = {
        approved: { value: stats.approved },
        pendingReview: { value: stats.labeled },
        pendingLabeling: { value: stats.notAssigned + stats.assigned },
        rejected: { value: stats.rejected },
      };

      const progressBarSpecs = Object.values(merge(statsDisplaySpec, statsValues));

      return (
        <div
          style={{
            minWidth: 300,
          }}
        >
          <MultiColorProgressBar barSpecs={progressBarSpecs} />
        </div>
      );
    },
    [statsDisplaySpec],
  );

  const getTaskAssigneeIds = useCallback((task: Task): UserId[] => {
    if (task.assignees && task.assignees.length > 0) {
      return task.assignees
        .filter(user => user.status !== UserTaskStatus.Canceled)
        .map(user => user.userId);
    }
    return [];
  }, []);

  const getTaskStatus = useCallback((task: Task): string => {
    return t(getReadableText(task.status));
  }, []);

  const openCloseTaskDialog = useCallback((task: Task) => {
    setIsCloseTaskDialogOpen(true);
    setTaskToClose(task);
  }, []);

  const goToTaskDetail = useCallback(
    (task: Task, type: ReviewType) => {
      const search = new URLSearchParams(history.location.search);
      search.append('type', type);
      history.push({
        pathname: `${CLEF_PATH.label.review}/${task.id}`,
        search: search.toString(),
      });
    },
    [history],
  );

  const getPrimaryAction = useCallback(
    (task: Task): React.ReactNode => {
      // if task has in review media, show Review button
      // else if task has approved/rejected media, show View Result button
      const stats = tasksStats[task.id] ?? {};
      if (stats.labeled > 0) {
        return (
          <Button
            key="review"
            color="primary"
            variant="outlined"
            id="task-review-btn"
            onClick={() => goToTaskDetail(task, 'review_ready')}
          >
            {t('Review')}
          </Button>
        );
      }
      const reviewed = stats.approved + stats.rejected;
      if (stats.approved > 0 || stats.rejected > 0 || task.mediaCount === reviewed) {
        return (
          <Button
            key="view-result"
            color="primary"
            variant="outlined"
            id="task-review-result-btn"
            onClick={() => goToTaskDetail(task, 'reviewed')}
            style={{ whiteSpace: 'nowrap' }}
            title={t('View result')}
          >
            {t('View result')}
          </Button>
        );
      }
      return null;
    },
    [goToTaskDetail, tasksStats],
  );

  const getMoreActions = useCallback(
    (task: Task): React.ReactNode => {
      const stats = tasksStats[task.id] ?? {};
      const taskFinished =
        task.status === TaskStatus.Canceled || task.mediaCount === stats.approved;
      if (taskFinished) {
        return null;
      }
      const hasReviewedMedia = stats.approved + stats.rejected > 0;
      return (
        <Dropdown
          placement="left"
          dropdown={toggleOpenDropdown => (
            <MenuList onClick={() => toggleOpenDropdown(false)}>
              {hasReviewedMedia && stats.labeled > 0 && (
                <MenuItem onClick={() => goToTaskDetail(task, 'reviewed')}>
                  <AssessmentRounded style={{ paddingRight: 8 }} />
                  {t('View result')}
                </MenuItem>
              )}
              {task.status !== TaskStatus.Labeled && task.status !== TaskStatus.Completed && (
                <MenuItem
                  onClick={(): void => {
                    setTaskToAssign(task);
                    setIsAssignmentDialogOpen(true);
                  }}
                >
                  <Box data-testid="task-assignment-btn" display="flex" alignItems="center">
                    <AccountCircle style={{ paddingRight: 8 }} />
                    {t('Assign to')}
                  </Box>
                </MenuItem>
              )}
              <MenuItem onClick={() => openCloseTaskDialog(task)}>
                <Box data-testid="task-close-btn" display="flex" alignItems="center">
                  <CancelRounded style={{ paddingRight: 8 }} />
                  {t('Close task')}
                </Box>
              </MenuItem>
            </MenuList>
          )}
        >
          <IconButton className={classes.moreActions} data-testid="more-actions">
            <MoreVert />
          </IconButton>
        </Dropdown>
      );
    },
    [classes.moreActions, goToTaskDetail, openCloseTaskDialog, tasksStats],
  );

  const getActions = useCallback(
    (task: Task): React.ReactNode => {
      return (
        <Box display="flex" flexWrap="nowrap" justifyContent="space-between" alignItems="center">
          {getPrimaryAction(task)}
          {getMoreActions(task)}
        </Box>
      );
    },
    [getMoreActions, getPrimaryAction],
  );

  const getTable = useCallback((): React.ReactNode => {
    const taskArray = (tasks?.filter(task => task.projectId === selectedProject?.id) ?? []).map(
      taskRow => ({
        ...taskRow,
        stats: tasksStats![taskRow.id],
      }),
    );

    const columns: Column<Task>[] = [
      {
        title: t('Name'),
        field: 'taskName',
      },
      {
        title: t('Create time'),
        defaultSort: 'desc',
        field: 'creationTime',
        type: 'datetime',
        render: (data: Task): React.ReactNode => getDateFormatted(data.creationTime),
      },
      {
        title: t('# Images'),
        field: 'mediaCount',
      },
      {
        title: (
          <Grid container spacing={1} direction="row">
            <Grid item xs={12}>
              {t('Progress')}
            </Grid>
            <Grid item>
              <Grid container spacing={2} direction="row">
                {createLegendItem('approved')}
                {createLegendItem('pendingReview')}
                {createLegendItem('rejected')}
              </Grid>
            </Grid>
          </Grid>
        ),
        render: (data: TaskManagementRow): React.ReactNode => {
          return data.stats && renderStats(data.stats);
        },
        cellStyle: {
          padding: 0,
          margin: 0,
          minWidth: 300,
        },
        headerStyle: {
          padding: 0,
          margin: 0,
          minWidth: 300,
        },
      },
      {
        title: t('Assignees'),
        sorting: false,
        render: (data: Task): React.ReactNode => (
          <Grid
            container
            className="cy-user-inspector-avatars"
            direction="row"
            wrap="nowrap"
            spacing={1}
          >
            {getTaskAssigneeIds(data)
              .sort()
              .map(userId => (
                <Grid item key={userId}>
                  <UserAvatar userId={userId} />
                </Grid>
              ))}
          </Grid>
        ),
      },
      {
        title: t('# Distinctive label required'),
        field: 'labelRequired',
        render: (data: Task): React.ReactNode => data.extra.numberOfLabelerPerMedia ?? 1,
      },
      {
        title: t('Status'),
        field: 'status',
        render: (data: Task): string => getTaskStatus(data),
      },
      {
        title: t('Actions'),
        sorting: false,
        render: getActions,
      },
    ];

    const options = {
      pageSize: ROW_DEFAULT_COUNT,
      pageSizeOptions: ROW_COUNT_OPTIONS,
      showTitle: false,
      sorting: true,
      paging: true,
      search: true,
      toolbar: true,
    };

    return (
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        data={taskArray!}
        options={options}
        components={{
          Row: props => <MTableBodyRow {...props} className={classes.tableRow} />,
          Container: props => <Box {...props} />,
        }}
      />
    );
  }, [
    tasks,
    createLegendItem,
    getActions,
    selectedProject,
    tasksStats,
    getDateFormatted,
    renderStats,
    getTaskAssigneeIds,
    getTaskStatus,
    classes.tableRow,
  ]);

  const closeTaskAssignmentDialog = useCallback(() => {
    setIsAssignmentDialogOpen(false);
  }, []);

  const assignUsers = useCallback(
    async (task: TaskId, labelers: UserId[]): Promise<void> => {
      if (!selectedProject) return;
      await TaskAPI.addAssignees(task, labelers);
      fetchTasksAndStats(selectedProject.id);
      closeTaskAssignmentDialog();
    },
    [closeTaskAssignmentDialog, fetchTasksAndStats, selectedProject],
  );

  const cancelCloseTaskDialog = useCallback(() => {
    setIsCloseTaskDialogOpen(false);
    setTaskToClose(null);
  }, []);

  const queryClient = useQueryClient();

  const onCloseConfirmation = useCallback(async () => {
    if (!selectedProject) return;
    // taskToCancel cannot be null at this point
    try {
      await cancelTaskApi(taskToClose!.id);
      selectedProject.datasetId &&
        queryClient.invalidateQueries(datasetQueryKeys.mediaDetails(selectedProject.datasetId));
      selectedProject.id &&
        queryClient.invalidateQueries(datasetQueryKeys.allWithFilters(selectedProject.id));
      fetchTasksAndStats(selectedProject.id);
      enqueueSnackbar(`Task *${taskToClose!.taskName}* successfully closed.`, {
        variant: 'success',
      });
      queryClient.invalidateQueries(layoutQueryKeys.list(selectedProject.id));
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error',
      });

      setIsCloseTaskDialogOpen(false);
    } finally {
      cancelCloseTaskDialog();
    }
  }, [cancelCloseTaskDialog, enqueueSnackbar, fetchTasksAndStats, selectedProject, taskToClose]);

  if (!selectedProject || !tasks) {
    return content;
  }

  return (
    <TaskContext.Provider
      value={{
        state: taskState,
        dispatch: dispatchTaskState,
      }}
    >
      <Grid container direction="row" className={classes.root}>
        <Grid item xs={12}>
          {getTable()}
        </Grid>
      </Grid>
      {taskToAssign && isAssignmentDialogOpen ? (
        <TaskAssignmentDialog
          open={isAssignmentDialogOpen}
          task={taskToAssign}
          mediaCount={taskToAssign.mediaCount}
          handleCancel={closeTaskAssignmentDialog}
          handleAssign={assignUsers}
        />
      ) : null}
      {taskToClose && isCloseTaskDialogOpen && (
        <ConfirmationDialog
          open={isCloseTaskDialogOpen}
          onClose={cancelCloseTaskDialog}
          onClickCancel={cancelCloseTaskDialog}
          onClickConfirm={onCloseConfirmation}
          title={t('Are you sure you want to close this task?')}
          text={t(
            'Approved media will be added to ground truth and unapproved media will be returned to the data browser.',
          )}
        />
      )}
    </TaskContext.Provider>
  );
};

export default TaskList;

import React from 'react';
import { ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';

export interface MediaSectionProps {
  title: string | JSX.Element;
  action?: JSX.Element;
}
const MediaDetailsSection: React.FC<MediaSectionProps> = ({ title, children, action }) => {
  return (
    <>
      <ListItem ContainerComponent="div" dense disableGutters>
        <ListItemText primary={title} />
        {action && <ListItemSecondaryAction>{action}</ListItemSecondaryAction>}
      </ListItem>
      {children}
    </>
  );
};

export default MediaDetailsSection;

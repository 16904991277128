import { Position } from '@clef/shared/types';
import Konva from 'konva';
import { LineConfig } from 'konva/types/shapes/Line';
import React, { useRef } from 'react';
import { Group, Line } from 'react-konva';
import { greyScale } from '../../..';

export type CrosshairProps = {
  scale: number;
  mousePos?: Position;
};

const Crosshair: React.FC<CrosshairProps> = props => {
  const { scale, mousePos } = props;
  const crosshairProps = {
    stroke: '#ffffff',
    strokeWidth: 2,
    dash: [6, 6],
    shadowColor: greyScale[600],
    shadowBlur: 2 / scale,
    shadowForStrokeEnabled: true,
    strokeScaleEnabled: false,
    // the crosshair should not listen to any events
    listening: false,
  } as LineConfig;

  const helperRef = useRef<Konva.Group>(null);
  const stage = helperRef.current?.getStage();
  const stagePosition = stage?.getPosition() ?? { x: 0, y: 0 };
  const stageSize = stage?.getSize() ?? { width: 0, height: 0 };

  const canvasRect = {
    x: -stagePosition.x / scale,
    y: -stagePosition.y / scale,
    width: stageSize.width / scale,
    height: stageSize.height / scale,
  };

  if (!mousePos) {
    return null;
  }

  return (
    <Group ref={helperRef}>
      <Line
        {...crosshairProps}
        points={[canvasRect.x, mousePos.y, canvasRect.x + canvasRect.width, mousePos.y]}
      />
      <Line
        {...crosshairProps}
        points={[mousePos.x, canvasRect.y, mousePos.x, canvasRect.y + canvasRect.height]}
      />
    </Group>
  );
};

export default Crosshair;

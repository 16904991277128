import { ApiKey } from '@clef/shared/types';
import { BaseAPI } from './base_api';

class OrganizationAPI extends BaseAPI {
  // This API will set cookie for later calls
  async getApiKeyList(): Promise<ApiKey[]> {
    return this.get('list_api_keys', undefined, true);
  }
}

export default new OrganizationAPI('organization');

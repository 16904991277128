import fetchHookFactory from './fetchHookFactory';
import public_endpoint_api from '@/api/public_endpoint_api';

export const [usePublicEndpointByIdApi, refreshPublicEndpointByIdApi] = fetchHookFactory(
  (endpointId?: string) => {
    return public_endpoint_api.getPublicEndpointById(endpointId!);
  },
  'cache',
);

export const [usePublicEndpointApi, refreshPublicEndpointApi] = fetchHookFactory(
  (params: { projectId: number; type: string }) => {
    return public_endpoint_api.getPublicEndpoint(params.projectId, params.type);
  },
  'cache',
);

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Button, IconButton } from '@clef/client-library';
import { useDefectBookEnhancedStyles } from '../defectBookEnhancedStyles';
import { defectColors } from '@clef/client-library';
import {
  useDefectSelector,
  useDefectSelectorWithArchived,
} from '../../../store/defectState/actions';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ColorResult } from 'react-color';
import { useHistory } from 'react-router';
import CLEF_PATH from '../../../constants/path';
import { Alert } from '@material-ui/lab';
import { COLOR_PALETTE } from '../../../constants/colors';
import ColorToggleButton from '../components/ColorToggleButton';
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import ColorPicker from '../components/ColorPicker';
import { Defect, LabelType } from '@clef/shared/types';
import { RESERVED_CLASS_NAMES } from '@clef/shared/constants';
import { useCreateDefectMutation, useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useSavePostProcessingConfig } from '@/components/Predict/hooks/useSavePostProcessingConfig';
import { useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import { ClientFeatures } from '@clef/shared/features';

export interface CreateDefectDialogProps {
  onClose: () => void;
  nextDefectIndex: number;
  alertText?: ReturnType<typeof t>;
  onCreateSuccess?: (defect: Defect) => void;
  style?: React.CSSProperties;
}

const CreateDefectDialog: React.FC<CreateDefectDialogProps> = props => {
  const { onClose, nextDefectIndex, alertText, onCreateSuccess, style } = props;
  const styles = useDefectBookEnhancedStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [isCreatingDefect, setIsCreatingDefect] = useState(false);
  const [selectedColor, setSelectedColor] = useState(defectColors[nextDefectIndex]);
  const [selectedName, setSelectedName] = useState('');
  const [newColor, setNewColor] = useState<string>(COLOR_PALETTE[0].toUpperCase());
  const [customColors, setCustomColors] = useState<string[]>([]);
  const defects = useDefectSelectorWithArchived();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const labelType = selectedProject?.labelType;
  const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const instantLearningPostprocessingEnabled = useFeatureGateEnabled(
    ClientFeatures.InstantLearningPostprocessing,
  );
  const createDefectApi = useCreateDefectMutation();

  const chosenColors = useMemo(() => {
    return (defects || []).map(item => {
      const color = (item.color || '').toUpperCase();
      return color;
    });
  }, [defects]);

  const isExist = useCallback(
    (color: string) => {
      return chosenColors.includes(color);
    },
    [chosenColors],
  );

  const isDisabledCreate = useMemo(() => {
    const isRepeat = isExist(selectedColor);
    return !selectedName || isRepeat;
  }, [isExist, selectedColor, selectedName]);

  const isReservedKeyword = useMemo(() => {
    return (
      !!selectedName &&
      labelType !== LabelType.Classification &&
      RESERVED_CLASS_NAMES.includes(selectedName.toLowerCase())
    );
  }, [selectedName, labelType]);

  const disableCreate = isDisabledCreate || isReservedKeyword;

  const allDefects = useDefectSelector();
  const savePostProcessingConfig = useSavePostProcessingConfig();

  const onCreateDefect = useCallback(async () => {
    setIsCreatingDefect(true);
    try {
      const newDefect = await createDefectApi.mutateAsync({
        name: selectedName.trim(),
        color: selectedColor,
      });

      if (
        labelType === LabelType.SegmentationInstantLearning &&
        instantLearningPostprocessingEnabled
      ) {
        await savePostProcessingConfig([...allDefects, newDefect]);
      }

      onClose();
      enqueueSnackbar(
        t('Successfully created the class: {{temp0}}', {
          temp0: selectedName,
        }),
        {
          variant: 'success',
        },
      );
      if (!onCreateSuccess) {
        history.push(`${CLEF_PATH.data.defectBookEnhanced}?tab=defects&defect=${newDefect.id}`);
      } else {
        onCreateSuccess?.(newDefect);
      }
    } catch (err) {
      const e = err as Record<string, any>;
      enqueueSnackbar(
        (e?.body || e)?.message, // this could be an api error or frontend error
        { variant: 'error' },
      );
    }
    setIsCreatingDefect(false);
  }, [
    allDefects,
    enqueueSnackbar,
    history,
    instantLearningPostprocessingEnabled,
    labelType,
    onClose,
    onCreateSuccess,
    savePostProcessingConfig,
    selectedColor,
    selectedName,
  ]);

  const handleAddNewColor = useCallback(() => {
    setAnchorEl(null);
    if (
      [...customColors, selectedColor, ...defectColors]
        .map(color => color.toUpperCase())
        .includes(newColor)
    ) {
      enqueueSnackbar(t('Please do not set duplicate colors'), {
        variant: 'warning',
      });
      return;
    }
    setSelectedColor(newColor);
    setCustomColors([...customColors, newColor]);
  }, [customColors, enqueueSnackbar, newColor, selectedColor]);

  useEffect(() => {
    if (chosenColors.length > 0) {
      const colors = chosenColors.filter(color => !defectColors.includes(color));
      setCustomColors(Array.from(new Set(colors)));
    }
  }, [chosenColors]);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const showColorPicker = Boolean(anchorEl);

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md" style={style}>
      {isCreatingDefect && (
        <div className={styles.postRequestMask}>
          <LinearProgress />
        </div>
      )}
      <DialogTitle disableTypography>
        <Typography variant="h3">{t('Create a Class')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="caption" gutterBottom display="block">
              {t('Class Color')}
            </Typography>

            <Box className={styles.colorTogglesContainer}>
              {defectColors.map(color => {
                const disabled = isExist(color);
                return (
                  <Box
                    key={color}
                    data-testid={`color-toggle-button-item-${color}`}
                    aria-disabled={disabled}
                  >
                    <ColorToggleButton
                      disabled={disabled}
                      key={color}
                      color={color}
                      selectedColor={selectedColor}
                      onClick={() => setSelectedColor(color)}
                    />
                  </Box>
                );
              })}
              {!!customColors.length && (
                <>
                  <Divider flexItem orientation="vertical" className={styles.divider} />
                  {customColors.map(color => {
                    const disabled = isExist(color);
                    return (
                      <Box
                        key={color}
                        data-testid={`color-toggle-button-item-${color}`}
                        aria-disabled={disabled}
                      >
                        <ColorToggleButton
                          disabled={disabled}
                          key={color}
                          color={color}
                          selectedColor={selectedColor}
                          onClick={() => setSelectedColor(color)}
                        />
                      </Box>
                    );
                  })}
                </>
              )}
              <Box alignSelf="center">
                <IconButton
                  size="small"
                  className={styles.addColorButton}
                  id="create-defects-custom-color"
                  onClick={handleClick}
                  aria-owns={showColorPicker ? 'colorpicker-click-popover' : undefined}
                  aria-haspopup="true"
                >
                  <AddCircleOutlineOutlined />
                </IconButton>
                <Popover
                  id="colorpicker-click-popover"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={showColorPicker}
                  anchorEl={anchorEl}
                  disableEnforceFocus
                >
                  <ColorPicker
                    color={newColor}
                    onChange={(color: ColorResult) => {
                      setNewColor(color.hex.toUpperCase());
                    }}
                    onClose={handleAddNewColor}
                  />
                </Popover>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" gutterBottom display="block">
              {t('Class name')}
            </Typography>
            <TextField
              variant="outlined"
              value={selectedName}
              fullWidth
              onChange={e => {
                e.persist();
                const value = e?.target?.value || '';
                if (value.length <= 30) {
                  setSelectedName(value);
                } else {
                  setSelectedName(value.substring(0, 30));
                }
              }}
              inputProps={{
                'aria-label': 'create-defect-name-field',
                className: styles.editModeTitle,
              }}
              classes={{
                root: styles.editModeInputRoot,
              }}
            />
            <Typography variant="caption" gutterBottom display="block">
              {t('Class names are permanent and cannot be changed')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info" className={styles.createDefectInformation}>
              {alertText ||
                t(
                  // eslint-disable-next-line max-len
                  'If you want to rename an unused class, you must delete it and create a new class.',
                )}
            </Alert>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button id="create-defect-dialog-cancel-button" onClick={onClose} color="primary">
          {t('Cancel')}
        </Button>
        <Tooltip
          placement="top"
          arrow
          title={
            disableCreate
              ? isReservedKeyword
                ? t('This class name is reserved, please use a different name')
                : t('Please select a color')
              : ''
          }
        >
          <span>
            <Button
              disabled={disableCreate}
              onClick={onCreateDefect}
              color="primary"
              variant="contained"
              id="create-defect-dialog-create-button"
            >
              {t('Create')}
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDefectDialog;

import { makeStyles } from '@material-ui/core';

const drawerWidth = 350;

export const useStyles = makeStyles(theme => ({
  detailsDrawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    top: theme.spacing(24),
    height: `calc(100% - ${theme.spacing(24)}px)`,
    backgroundColor: 'white',
    width: drawerWidth,
    borderRadius: 0,
  },
  topBar: {
    flexShrink: 0,
    backgroundColor: 'white',
    boxShadow: 'none !important',
  },
  tabsRoot: {
    height: '100%',
    padding: theme.spacing(0, 3),
  },
  tabsFlexContainer: {
    height: '100%',
    columnGap: theme.spacing(3),
  },
  tabsIndicator: {
    backgroundColor: theme.palette.blue[600],
  },
  tab: {
    flexBasis: 'fit-content',
    height: '100%',
    minWidth: 0,
    padding: 0,
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    color: '#4D5761',
    whiteSpace: 'nowrap',
    opacity: 1,
  },
  tabSelected: {
    color: theme.palette.blue[600],
    fontWeight: 700,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(5),
  },
  infoTabWrapper: {
    position: 'relative',
  },
  infoMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
  mediaName: {
    display: 'flex',
    alignItems: 'center',

    '& p': {
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.greyModern[800],
    },
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.greyModern[800],
  },
  sectionTitleWithToggle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: theme.spacing(-2.5),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
  },
  toggleDisabled: {
    cursor: 'unset',
  },
  sectionTitleTabs: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  sectionTitleJSON: {
    marginLeft: theme.spacing(3),
  },
  sectionTitleInactive: {
    color: theme.palette.greyModern[400],
  },
  sectionTitleText: {
    lineHeight: '34px',
    color: theme.palette.greyModern[800],
  },
  sectionContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 0),
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: 14,
    fontWeight: 400,
    color: '#4D5761',
  },
  sectionContentLabel: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    columnGap: theme.spacing(1),

    '& svg': {
      fontSize: 16,
    },
  },
  splitDropdownRoot: {
    width: '100%',
    height: 40,
  },
  splitSelector: {
    width: '100%',
    height: 40,
    justifyContent: 'space-between',
    borderRadius: theme.spacing(2),
  },
  splitDropdownOptions: {
    width: 265,
  },
  operation: {
    display: 'flex',
    '& button, & a': {
      height: 32,
      minWidth: 32,
      padding: 0,
      margin: theme.spacing(0, 0),
      border: `1px solid ${theme.palette.greyModern[300]}`,
    },
  },
  removeBtn: {
    marginLeft: '8px !important',
  },
  hotkeysDrawerWrapper: {
    margin: theme.spacing(4),
  },
  modelAssistDefaultBtn: {
    marginBottom: theme.spacing(3),
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '16px',
  },
  modelAssistBlock: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    background: theme.palette.blue[50],
    border: `2px solid ${theme.palette.blue[600]}`,
    borderRadius: 10,
    padding: theme.spacing(3, 3, 4, 3),
    marginBottom: theme.spacing(3),
    color: theme.palette.greyModern[900],
  },
  modelAssistBlockTitle: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
  },
  modelAssistBlueBtn: {
    color: theme.palette.blue[600],
  },
  modelAssistBlockSubTitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    color: theme.palette.greyModern[600],
  },
  modelAssistBlockBtn: {
    display: 'flex',
    flex: 1,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '16px',
  },
  modelAssistBlockBtnSub: {
    marginLeft: theme.spacing(1),
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
  },
  acceptAllBtn: {
    flex: 'none',
    color: theme.palette.blue[800],
    background: theme.palette.blue[100],
    '&:hover': {
      background: theme.palette.blue[200],
    },
  },
  thresholdWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  thresholdSetting: {
    display: 'flex',
    columnGap: '12px',
    marginBottom: theme.spacing(4),
  },
  sliderRoot: {
    margin: 'auto 0',
    flex: 1,
  },
  sliderRail: {
    color: theme.palette.greyModern[400],
    borderRadius: 4,
    height: 4,
  },
  sliderTrack: {
    color: '#4B5565',
    borderRadius: 4,
    height: 4,
  },
  sliderMark: {
    borderRadius: 2,
    height: 4,
    width: 4,
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.common.white} !important`,
    opacity: `1 !important`,
  },
  sliderMarkLabel: {
    top: 20,
  },
  sliderThumb: {
    width: 16,
    height: 16,
    background: '#FFFFFF',
    border: '1.5px solid #4B5565',
    borderRadius: 12,
    marginTop: -7,
    '&:hover': {
      boxShadow: '0px 0px 0px 8px rgb(75, 85, 101, .16)',
    },
    '&.MuiSlider-active': {
      boxShadow: '0px 0px 0px 14px rgb(75, 85, 101, .16)',
    },
  },
  sliderActive: {
    boxShadow: '0px 0px 0px 14px rgb(75, 85, 101, .16)',
  },
  thresholdInputRoot: {
    width: 44,
    height: 32,
  },
  thresholdInput: {
    textAlign: 'center',
    padding: 0,
    fontFamily: theme.typography.body1.fontFamily,
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  suggestionCountWrapper: {
    flex: 0,
    display: 'flex',
    gap: theme.spacing(2),
  },
  tipsBlock: {
    position: 'fixed',
    right: '370px',
    bottom: '32px',
    width: '380px',
    height: 'auto',
    padding: theme.spacing(5, 6),
    borderRadius: '20px',
    background: '#fff',
    boxShadow: `0px 4px 4px -2px rgba(24, 39, 75, 0.08), 0px 2px 4px -2px rgba(24, 39, 75, 0.12)`,
  },
  tipsTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  tipsContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  tipsItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  tipsItemTextBold: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    display: 'inline',
  },
  arrow: {
    color: '#fff',
    top: '294px !important',
  },
  tooltip: {
    pointerEvents: 'auto',
    minWidth: '308px',
    padding: theme.spacing(3),
    backgroundColor: '#fff',
    color: theme.palette.greyModern[900],
    borderRadius: '20px',
    marginRight: theme.spacing(7),
  },
  onboardingTipsContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
  },
  tipsGif: {
    width: '284px',
    height: '180px',
    borderRadius: '20px',
  },
  disableCursor: {
    cursor: 'not-allowed',
  },
}));

export default useStyles;

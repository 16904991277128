import { TRANSFORM_TEXTS } from '@/constants/model_train';
import { Transform } from '@clef/shared/types';
import { Tooltip } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { handleSliderInputChange } from '../../../../utils';
import { getStepSize } from './SliderUtils';

const useStyles = makeStyles(({ spacing }) => ({
  slider: {
    width: 140,
  },
  configItem: {
    marginBottom: spacing(2),
    display: 'flex',
    gap: `${spacing(3)}px`,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    padding: spacing(1),
    textAlign: 'center',
    height: 24,
    width: 45,
  },
  sliderName: {
    textTransform: 'capitalize',
    minWidth: 120,
  },
}));

export const SingleSlider = ({
  title,
  transform,
  value,
  minKey,
  maxKey,
  updateTransformParam,
}: {
  minKey: string;
  maxKey: string;
  title: string;
  transform: Transform;
  value: number;
  updateTransformParam(value: number): void;
}) => {
  const styles = useStyles();
  const [paramInput, setParamInput] = React.useState<number | string>(value);

  const handleParamUpdate = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      updateTransformParam(Number(e.currentTarget.value));
    }
  };

  return (
    <div>
      <div className={styles.configItem}>
        <Tooltip title={TRANSFORM_TEXTS[title]?.tooltip ?? ''} arrow placement="top">
          <div className={styles.sliderName}>{title.replace(/_/g, ' ')}</div>
        </Tooltip>
        <Slider
          className={styles.slider}
          max={Number(transform?.paramsDescription[maxKey].jsonSchema.maximum)}
          min={Number(transform?.paramsDescription[minKey].jsonSchema.minimum)}
          defaultValue={value}
          value={paramInput as number}
          step={getStepSize(title, String(transform?.paramsDescription[minKey].jsonSchema.type))}
          onChange={(_, newValue) => {
            setParamInput(newValue as number);
          }}
          onChangeCommitted={(_, newValue) => {
            updateTransformParam(newValue as number);
          }}
        />
        <OutlinedInput
          data-testid={`single-slider-${title}`}
          classes={{ input: styles.input }}
          value={paramInput}
          onChange={e => {
            handleSliderInputChange(e, setParamInput);
          }}
          onKeyUp={handleParamUpdate}
          onBlur={e => {
            setParamInput(Number(e.target.value));
            updateTransformParam(Number(e.target.value));
          }}
          margin="dense"
        />
      </div>
    </div>
  );
};

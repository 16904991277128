import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Checkbox,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import { Project } from '@clef/shared/types';
import { useSoftDeleteProjectMutation } from '@/serverStore/projects';

export const deleteProjectDialogStyles = makeStyles(theme => ({
  deleteBtn: {
    fontSize: '14px',
    marginTop: 5,
    marginBottom: 20,
    verticalAlign: 'middle',
    horizontalAlign: 'center',
  },
  keepProjectBtn: {
    fontSize: 14,
    marginTop: 5,
    marginBottom: 20,
    verticalAlign: 'middle',
    horizontalAlign: 'center',
  },
  deleteText: {
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
  },
  deleteDialogTop: {
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 20,
  },
  deleteCheckbox: {
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 10,
  },
}));

interface DeleteProjectDialogProps {
  project: Project;
  open: boolean;
  handleClose: () => void;
}

export const DeleteProjectDialog: React.FC<DeleteProjectDialogProps> = props => {
  const { project, open, handleClose } = props;
  const styles = deleteProjectDialogStyles();

  const [acceptNoLinkAccess, setAcceptNoLinkAccess] = useState<boolean>(false);
  const [acceptNoDataAndModelsAccess, setAcceptNoDataAndModelsAccess] = useState<boolean>(false);

  const deleteProject = useSoftDeleteProjectMutation();

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        setAcceptNoLinkAccess(false);
        setAcceptNoDataAndModelsAccess(false);
      }}
      data-testid="project-deletion-dialog"
    >
      <DialogContent>
        <Box className={styles.deleteDialogTop}>
          <Typography>
            {t('Are you sure you want to {{delete}} project {{projectName}}?', {
              delete: <span className={styles.deleteText}>{t('delete')}</span>,
              projectName: <strong>{project.name}</strong>,
            })}
          </Typography>
        </Box>
        <Box>
          <div className={styles.deleteCheckbox}>
            <Checkbox
              checked={acceptNoLinkAccess}
              onChange={() => setAcceptNoLinkAccess(!acceptNoLinkAccess)}
              data-testid="project-del-accept-no-link-access"
            />
            <Typography component="span">{t('All shared links will be inaccessible')}</Typography>
          </div>
          <div className={styles.deleteCheckbox}>
            <Checkbox
              checked={acceptNoDataAndModelsAccess}
              onChange={() => setAcceptNoDataAndModelsAccess(!acceptNoDataAndModelsAccess)}
              data-testid="project-del-accept-no-data-and-models-access"
            />
            <Typography component="span">
              {t('All labeled data and trained models in this project will be inaccessible')}
            </Typography>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!acceptNoLinkAccess || !acceptNoDataAndModelsAccess}
          variant="contained"
          color="secondary"
          onClick={() => {
            if (!project) {
              handleClose();
              return;
            }
            deleteProject(project);
            handleClose();
          }}
          className={styles.deleteBtn}
          id="confirm-project-deletion"
        >
          {t('Yes, Delete Project')}
        </Button>
        <Button
          variant="outlined"
          color="default"
          onClick={() => {
            handleClose();
            setAcceptNoLinkAccess(false);
            setAcceptNoDataAndModelsAccess(false);
          }}
          className={styles.keepProjectBtn}
          id="cancel-project-deletion"
        >
          {t('No, Keep Project')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProjectDialog;

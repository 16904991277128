import React, { useState, useEffect } from 'react';
import { makeStyles, Grid, Typography, Tooltip, Box } from '@material-ui/core';
import { toPercentSingleDecimal } from './helpers';
import classNames from 'classnames';

type UseStylesProps = {
  compact?: boolean;
  size?: 'small' | 'medium';
  bandWidth?: number;
  labelWidth?: number;
  borderRadius?: number;
};
const useStyles = (props: UseStylesProps) =>
  makeStyles(theme => {
    const { compact, size, bandWidth = size === 'small' ? 10 : 20, labelWidth = size === 'small' ? 50 : 120 , borderRadius = size === 'small' ? 2 : 8 } = props;
    return {
      distributionContainer: {
        marginTop: theme.spacing(5),
        minWidth: 240,
      },
      labelContainer: {
        marginTop: theme.spacing(5),
      },
      distributionBarWithLabel: {
        marginBottom: size === 'small' ? theme.spacing(3) : theme.spacing(4),
        alignItems: size === 'small' ? 'center' : 'flex-start',
      },
      distributionLabelName: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: compact ? '100%' : labelWidth,
        fontSize: size === 'small' ? 12 : 14,
      },
      distributionBarWithCaption: {
        width: compact ? '100%' : size === 'small' ? `calc(100% - 20px - ${labelWidth}px)` : `calc(100% - 20px - ${labelWidth}px)`,
        minWidth: 240,
      },
      distributionBar: {
        minHeight: bandWidth,
        borderRadius: borderRadius,
        position: 'relative',
        height: '100%',
      },
      distribution: {
        height: '100%',
        position: 'absolute',
        transition: 'left 1s ease-in-out, width 1s ease-in-out, transform 0.15s ease',
        top: 0,
        '&:hover': {
          transform: 'scale(1.1)',
          zIndex: 100,
        },
        '&:first-of-type': {
          borderBottomLeftRadius: borderRadius,
          borderTopLeftRadius: borderRadius,
        },
        '&:last-of-type': {
          borderBottomRightRadius: borderRadius,
          borderTopRightRadius: borderRadius,
        },
      },
      colorBlock: {
        height: theme.spacing(5),
        width: theme.spacing(10),
        marginRight: theme.spacing(2),
        borderRadius: borderRadius,
        overflow: 'hidden',
      },
      chartLabelName: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: 100,
      },
      barCaption: {
        marginTop: theme.spacing(1),
      },
      noFullLabelName: {
        width: 80,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'textOverflow',
      },
      autoWidth: {
        width: 'auto',
        flex: 1,
        height: 25,
      },
    };
  });

export type DistributionType = { value: number; distributor: string };

export interface DistributionChartProps {
  distributionData: {
    name?: string;
    distributions: DistributionType[];
    caption?: React.ReactNode;
  }[];
  distributorColorMap: { [key: string]: string };
  hideLabel?: boolean;
  compact?: boolean;
  size?: 'small' | 'medium' | undefined;
  bandWidth?: number;
  labelWidth?: number;
  borderRadius?: number;
}

const DistributionChart: React.FC<DistributionChartProps> = ({
  distributionData,
  distributorColorMap,
  hideLabel,
  compact = false,
  size = 'medium',
  bandWidth,
  labelWidth,
  borderRadius,
}) => {
  const styles = useStyles({ compact, size, bandWidth, labelWidth, borderRadius })();
  const [startAnimation, setStartAnimation] = useState(false);
  useEffect(() => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setStartAnimation(true);
      });
    });
  });

  const distributionDataFiltered = distributionData.filter(distribution =>
    distribution.distributions.some(dis => dis.value),
  );

  return (
    <>
      {/* Distribution chart */}
      <Grid
        className={styles.distributionContainer}
        container
        direction="column"
        justifyContent="center"
      >
        {distributionDataFiltered.length === 0 ? (
          <Box marginTop={-2}>{t('No data')}</Box>
        ) : (
          distributionDataFiltered.map((data, index) => {
            const distributionFiltered = data.distributions.filter(dis => dis.value);
            const distributionTotalValue: number = distributionFiltered.reduce(
              (acc, distribution) => acc + distribution.value,
              0,
            );
            if (distributionTotalValue === 0) return null;
            return (
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                wrap={compact ? 'wrap' : 'nowrap'}
                key={data.name ?? index}
                justifyContent="space-between"
                className={styles.distributionBarWithLabel}
              >
                {data.name && (
                  <Tooltip title={data.name} placement="top">
                    <Typography
                      variant="body2"
                      component="div"
                      className={styles.distributionLabelName}
                    >
                      {data.name}
                    </Typography>
                  </Tooltip>
                )}
                <div className={styles.distributionBarWithCaption}>
                  <div className={styles.distributionBar}>
                    {distributionFiltered.map((distribution, index) => {
                      const previousAccValue = distributionFiltered
                        .slice(0, index)
                        .reduce((acc, column) => acc + column.value, 0);
                      const previousAccOfTotal = toPercentSingleDecimal(
                        previousAccValue / distributionTotalValue,
                      );
                      const percentageOfTotal = toPercentSingleDecimal(
                        distribution.value / distributionTotalValue,
                      );
                      return (
                        <Tooltip
                          title={
                            <span>
                              <strong>{distribution.distributor}</strong> │ {distribution.value} (
                              {percentageOfTotal}
                              %)
                            </span>
                          }
                          key={index}
                          placement="top"
                          arrow
                          data-testid="single-distribution"
                          aria-label={`${data.name}|${distribution.distributor}`}
                          data-value={`${distribution.value}(${percentageOfTotal}%)`}
                        >
                          <div
                            className={styles.distribution}
                            style={{
                              backgroundColor: distributorColorMap[distribution.distributor],
                              width: startAnimation ? `${percentageOfTotal}%` : 0,
                              left: startAnimation ? `${previousAccOfTotal}%` : 0,
                            }}
                          ></div>
                        </Tooltip>
                      );
                    })}
                  </div>
                  {data.caption && (
                    <Typography variant="body2" component="div" className={styles.barCaption}>
                      {data.caption}
                    </Typography>
                  )}
                </div>
              </Grid>
            );
          })
        )}
      </Grid>
      {/* Distributor labels */}
      {!hideLabel && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={2}
          className={styles.labelContainer}
        >
          {Object.entries(distributorColorMap).map(([name, color]) => {
            return (
              <Grid
                container
                item
                // @ts-ignore
                xs={Math.ceil(12 / Object.entries(distributorColorMap).length)}
                direction="row"
                justifyContent="center"
                alignItems="center"
                wrap="nowrap"
                key={name}
              >
                <div className={styles.colorBlock} style={{ backgroundColor: color }}></div>
                <Typography variant="body2" component="div" className={styles.chartLabelName}>
                  {name}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default DistributionChart;

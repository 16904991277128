import React, { useEffect, useRef } from 'react';
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router';
import ErrorPage from '../../pages/error/ErrorPage';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useDispatch } from 'react-redux';
import CLEF_PATH from '../../constants/path';
import { PageLayout } from './PageLayout';
import { useLocation } from 'react-router-dom';
import { useSetAtom } from 'jotai';

import MyTasksPage from '../../pages/my_tasks/MyTasksPage';
import { projectIdCache, transformLegacyPath, transformLegacyUrlWithOrgId } from './utils';
import { PageTitle } from '../../constants/page';
import dataRoutes from './routes/DataRoutes';
import labelingRoutes from './routes/LabelingRoutes';
import continuousLearningRoutes from './routes/ContinuousLearningRoutes';
import { PageName } from '@clef/shared/constants';
import { ApiResponseLoader, useLocalStorage } from '@clef/client-library';
import ProjectSettingsAcessAndAdmin from '../ProjectSettingsAccessAndAdmin/index';
import { UserPermission, ProjectRole } from '@clef/shared/types';
import { UploadStage } from '../../store/uploadState/types';
import { resetUploadState } from '../../store/uploadState';
import { useModelAnalysisEnabled } from '../../hooks/useFeatureGate';
import { useHasPermission, useProjectRolePermissions } from '../../hooks/useProjectRolePermissions';
import { isProjectLevelPage } from '@/utils/url_utils';
import { useSnackbar } from 'notistack';
import {
  useGetProjectSplitQuery,
  useGetProjectDefectsQuery,
  useGetProjectsQuery,
  useGetSelectedProjectQuery,
  useSetSelectedProjectId,
} from '@/serverStore/projects';
import modelIterationV2Routes from './routes/ModelIterationV2Routes';
import { useGetProjectModelListQuery, useSucceedProjectModels } from '@/serverStore/projectModels';
import { SelectedModelIdForProjectsStorageKey } from '@/constants/data_browser';
import { thresholdForPredictAtom } from '@/uiStates/projectModels/pageUIStates';

const AppProjectRouter: React.FC<{}> = () => {
  const { path: currentPath, url: currentUrl } = useRouteMatch();
  const { projectId: projectIdStr, orgId } = useParams<{ projectId: string; orgId: string }>();
  const dispatch = useDispatch();
  const projectId = Number(projectIdStr);
  const { data: projects } = useGetProjectsQuery();
  const { data: selected } = useGetSelectedProjectQuery();
  const setSelectedProjectId = useSetSelectedProjectId();
  const { data: splitsForProject } = useGetProjectSplitQuery();
  const { data: defectsForProject } = useGetProjectDefectsQuery();
  const validProjectId = projects?.find(pr => pr.id === projectId);
  // const modelWasInProgress = useRef(false);
  const enableModelAnalysis = useModelAnalysisEnabled();

  const location = useLocation();
  const canDeployModel = useHasPermission(UserPermission.DeployModel);
  const { projectRole } = useProjectRolePermissions();

  const setThresholdForPredict = useSetAtom(thresholdForPredictAtom);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // remove pr from searchParams, we don't want to keep this
    searchParams.delete('pr');
    // directly use window.history.replaceState to prevent react rerender the page
    window.history.replaceState(
      null,
      document.title,
      location.pathname + `${searchParams.toString() ? '?' + searchParams.toString() : ''}`,
    );
  }, []);

  useEffect(() => {
    // sync projectId in to localStorage
    if (validProjectId) {
      if (projectIdCache.get() !== projectId) {
        projectIdCache.set(projectId);
      }
      // sync projectId in to project state
      if (!selected) {
        setSelectedProjectId(projectId);
      }
    } else {
      projectIdCache.delete();
    }
  }, [dispatch, projectId, selected, validProjectId]);

  // init currentModelId for current project in to localStorage
  const { succeedModels } = useSucceedProjectModels();
  const { data: projectModelsData } = useGetProjectModelListQuery();
  const [selectedModelIdForProjects, setSelectedModelIdForProjects] = useLocalStorage<{
    [projectId: number]: string;
  }>(SelectedModelIdForProjectsStorageKey);
  useEffect(() => {
    if (!projectId) return;
    const modelIds = selectedModelIdForProjects || {};
    if (succeedModels?.length) {
      if (
        !modelIds[projectId] ||
        (modelIds[projectId] &&
          projectModelsData &&
          !projectModelsData.find(m => m.id === modelIds[projectId]))
      ) {
        setSelectedModelIdForProjects({ ...modelIds, [projectId]: succeedModels[0].id });
      }
    }
  }, [
    projectId,
    succeedModels,
    selectedModelIdForProjects,
    setSelectedModelIdForProjects,
    projectModelsData,
  ]);

  // reset upload state when selected project changes
  const { uploadStage } = useTypedSelector(state => state.uploadState);
  useEffect(() => {
    // we should consider the behavior for upload in progress later
    if (uploadStage !== UploadStage.UploadInProgress) {
      dispatch(resetUploadState());
    }
    // only trigger when selected project change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected?.id]);

  /**
   * Prevent user from leaving project level pages if there is upload in progress
   */
  const history = useHistory();
  const currentIsProjectLevelPage = isProjectLevelPage();
  const { enqueueSnackbar } = useSnackbar();
  const login = useTypedSelector(state => state.login) ?? {};
  const loginRef = useRef(login);
  loginRef.current = login;
  useEffect(() => {
    history.block(newHistory => {
      // When sign out / switch org, the operations are async. Need to check localStorage instead of redux state.
      const isLogin = localStorage.getItem('clef_is_login');
      if (!isLogin) {
        return;
      }
      const oldPath = history.location.pathname;
      const newPath = newHistory.pathname;
      if (newPath === oldPath) {
        return;
      }
      const isLeavingProjectLevelPage = isProjectLevelPage(oldPath) && !isProjectLevelPage(newPath);
      if (isLeavingProjectLevelPage) {
        setSelectedProjectId(undefined);
        setThresholdForPredict(undefined);
      }
      if (isLeavingProjectLevelPage && uploadStage === UploadStage.UploadInProgress) {
        window.history.replaceState(null, document.title, oldPath + history.location.search);
        enqueueSnackbar(t('Please do not leave before uploading is finished.'), {
          variant: 'warning',
        });
        // block the navigation
        return false;
      }
      return;
    });
  }, [currentIsProjectLevelPage, enqueueSnackbar, history, setSelectedProjectId, uploadStage]);

  if (!projectId) {
    return <ErrorPage message="Project id should be a number" />;
  }

  if (!validProjectId) {
    return (
      <Redirect
        to={{
          pathname: transformLegacyUrlWithOrgId(CLEF_PATH.projects, Number(orgId)),
          state: {
            redirectMessage:
              'Project not found, make sure you are in correct organization and have access to the project',
          },
        }}
      />
    );
  }

  if (!selected || !defectsForProject || !splitsForProject) {
    return (
      <ApiResponseLoader defaultHeight={200} loading>
        {() => null}
      </ApiResponseLoader>
    );
  }

  // If the current user is labeler, redirect to the tasks page; otherwise, redirect to data browser page
  const projectLandingPage =
    projectRole === ProjectRole.Labeler ? CLEF_PATH.myTasks : CLEF_PATH.data.dataBrowser;

  return (
    /**
     * IMPORTANT
     * ===
     * When switching project, the ordering of React update is
     * Redux -> rerender -> api hook state changes -> rerender
     * This might cause misalignment from api hook to redux
     * page might flash rerender with out-dated media + up-to-date redux (dataset, defect).
     * To prevent this, rebuilt VDOM instead patching it
     */
    <Switch key={selected.id}>
      {/* if this is / path, redirect to Data browser as the landing page */}
      <Route
        exact
        path={`${currentPath}`}
        render={() => <Redirect to={transformLegacyPath(currentUrl, projectLandingPage)} />}
      />
      {/* Data pages */}
      {...dataRoutes(currentPath)}
      {/* Labeling pages */}
      {...labelingRoutes(currentPath)}
      {/* Model iteration new pages - model analysis & model comparison*/}
      {...enableModelAnalysis ? modelIterationV2Routes(currentPath) : []}
      {/* Deployment */}
      {...canDeployModel ? continuousLearningRoutes(currentPath) : []}
      {/* My tasks */}
      <Route
        exact
        path={transformLegacyPath(currentPath, CLEF_PATH.myTasks)}
        render={() => (
          <PageLayout title={PageTitle.NewMyTasks} pageName={PageName.MyTasks} showProjectBanner>
            <MyTasksPage />
          </PageLayout>
        )}
      />
      {/* Project settings */}
      <Route
        exact
        path={transformLegacyPath(currentPath, CLEF_PATH.projectSettings)}
        render={() => (
          <PageLayout
            pageName={PageName.ProjectSettings}
            title={PageTitle.Settings}
            showProjectBanner
          >
            {/* delete old code, use new code */}
            <ProjectSettingsAcessAndAdmin />
          </PageLayout>
        )}
      />
      {/* Fallback */}
      <Route render={() => <ErrorPage />} />
    </Switch>
  );
};

export default AppProjectRouter;

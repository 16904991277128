import React from 'react';

export type UndoIconProps = {};

const UndoIcon: React.FC<UndoIconProps> = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66675 8.16667H16.3334C20.1994 8.16667 23.3334 11.3007 23.3334 15.1667C23.3334 19.0327 20.1994 22.1667 16.3334 22.1667H4.66675M4.66675 8.16667L9.33342 3.5M4.66675 8.16667L9.33341 12.8333"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UndoIcon;

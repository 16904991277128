import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import classNames from 'classnames';

import { DatasetGroupOptions } from '@clef/shared/types';
import { Typography, DistributionChart, IconButton } from '@clef/client-library';

import {
  MediaSplitName,
  SplitMapping,
  MediaStatusNameMapping,
  statsColorMap,
  splitColorMap,
} from '@/constants/stats_card';
import { defaultSelectOptions } from '@/constants/data_browser';
import {
  useDatasetMediaCountQuery,
  useGetDatasetStatsQuery,
  useDatasetExportedWithVersionsQuery,
} from '@/serverStore/dataset';
import { useGetProjectVersionedDefectsQuery } from '@/serverStore/projects';

import SnapshotDetailItem from './SnapshotDetailItem';
import SnapshotDistributionChart from './SnapshotDistributionChart';
import SnapshotModelsTable from './SnapshotModelsTable';
import EditDetailDialog from './EditDetailDialog';
import useStyles from './styles';

const DataVersionSummary: React.FC<{}> = () => {
  const styles = useStyles();
  const { version } = useParams<{ version: string }>();

  const { data: datasetExported } = useDatasetExportedWithVersionsQuery({
    withCount: true,
    includeNotCompleted: true,
    includeFastEasy: true,
  });

  const currentSnapshot = useMemo(() => {
    return datasetExported?.datasetVersions?.find(v => v.version === parseInt(version));
  }, [datasetExported, version]);

  const { data: mediaCount, isLoading: mediaCountLoading } = useDatasetMediaCountQuery(
    {
      version: parseInt(version, 10),
      selectOptions: defaultSelectOptions,
    },
    Boolean(version),
  );

  const [isEditingDetail, setIsEditingDetail] = useState(false);

  const { data: versionedDefects, isLoading: defectTypeLoading } =
    useGetProjectVersionedDefectsQuery(parseInt(version));

  // get label status
  const { data: mediaGroupByMediaStatus } = useGetDatasetStatsQuery({
    selectOptions: defaultSelectOptions,
    groupOptions: [DatasetGroupOptions.MEDIA_STATUS],
    version: parseInt(version, 10),
  });
  const mediaStatsFormatted = useMemo(() => {
    return Object.entries(MediaStatusNameMapping).map(([status, name]) => ({
      name,
      value: mediaGroupByMediaStatus?.find(item => item.media_status === status)?.count ?? 0,
    }));
  }, [mediaGroupByMediaStatus]);

  // get split data
  const { data: mediaGroupBySplit } = useGetDatasetStatsQuery({
    selectOptions: defaultSelectOptions,
    groupOptions: [DatasetGroupOptions.SPLIT],
    version: parseInt(version, 10),
  });
  const mediaSplitFormatted = useMemo(() => {
    return Object.entries(SplitMapping).map(([split, name]) => ({
      name,
      value:
        mediaGroupBySplit?.find(item => {
          if (item.split === null && split === MediaSplitName.Unassigned) return true;
          return item.split === split;
        })?.count ?? 0,
    }));
  }, [mediaGroupBySplit]);

  return (
    <Box className={styles.summaryRoot}>
      {!mediaCountLoading && !defectTypeLoading && (
        <Box className={styles.basicInfo}>
          <Box className={classNames(styles.infoBlock, styles.countInfo)}>
            <Box className={styles.countDetail}>
              <Typography className={styles.countValue}>{mediaCount}</Typography>
              <Typography>{t('Images')}</Typography>
            </Box>
            <Box className={styles.countDetail}>
              <Typography className={styles.countValue}>{versionedDefects?.length}</Typography>
              <Typography>
                {!!versionedDefects && versionedDefects.length > 1 ? t('Classes') : t('Class')}
              </Typography>
            </Box>
          </Box>
          {mediaGroupByMediaStatus && (
            <Box className={classNames(styles.infoBlock, styles.statsInfo)}>
              <Box className={styles.detailTitle}>{t('Image status')}</Box>
              <DistributionChart
                distributionData={[
                  {
                    distributions: mediaStatsFormatted.map(item => ({
                      distributor: item.name,
                      value: item.value,
                    })),
                  },
                ]}
                distributorColorMap={statsColorMap}
                hideLabel
                bandWidth={8}
                compact
                size="small"
              />
              {mediaStatsFormatted.map((item, index) => (
                <SnapshotDetailItem key={index} item={item} index={index} />
              ))}
            </Box>
          )}
          {mediaGroupBySplit && (
            <Box className={classNames(styles.infoBlock, styles.statsInfo)}>
              <Box className={styles.detailTitle}>{t('Split distribution on labeled images')}</Box>
              <DistributionChart
                distributionData={[
                  {
                    distributions: mediaSplitFormatted.map(item => ({
                      distributor: item.name,
                      value: item.value,
                    })),
                  },
                ]}
                distributorColorMap={splitColorMap}
                hideLabel
                bandWidth={8}
                compact
                size="small"
              />
              {mediaSplitFormatted.map((item, index) => (
                <SnapshotDetailItem key={index} item={item} index={index} />
              ))}
            </Box>
          )}
        </Box>
      )}

      <Box className={styles.summarySection}>
        <Box className={styles.sectionTitle}>
          <Typography variant="h2">{t('Description')}</Typography>
          <IconButton
            id="model-details-edit-model-name"
            size="small"
            onClick={() => setIsEditingDetail(true)}
          >
            <EditIcon />
          </IconButton>
        </Box>
        {currentSnapshot?.details ? (
          <Typography className={styles.snapshotDetails}>{currentSnapshot.details}</Typography>
        ) : (
          <Typography className={styles.placeholderText}>
            {t(
              'Add snapshot description here. e.g. Backup current data, training on subset, holdout for evaluation etc.',
            )}
          </Typography>
        )}
      </Box>

      <Box className={styles.summarySection}>
        <Box className={styles.sectionTitle}>
          <Typography variant="h2">{t('Distribution')}</Typography>
        </Box>
        <SnapshotDistributionChart selectMediaOptions={defaultSelectOptions} />
      </Box>

      {currentSnapshot && (
        <Box className={styles.summarySection}>
          <Box className={styles.sectionTitle}>
            <Typography variant="h2">{t('Models Trained')}</Typography>
          </Box>
          <SnapshotModelsTable snapshot={currentSnapshot}></SnapshotModelsTable>
        </Box>
      )}

      {/* TODO: show this section when evaluation part is done */}
      {/* <Box className={styles.summarySection}>
        <Box className={styles.sectionTitle}>
          <Typography variant="h2">{t('Evaluation Job')}</Typography>
        </Box>
      </Box> */}

      {/* detail edit modal */}
      {isEditingDetail && currentSnapshot && (
        <EditDetailDialog snapshot={currentSnapshot} onClose={() => setIsEditingDetail(false)} />
      )}
    </Box>
  );
};

export default DataVersionSummary;

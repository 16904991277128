/**
 * Creators
 */
import { useBoxAnnotationCreator } from './useBoxAnnotationCreator';
import { useLineAnnotationCreator } from './useLineAnnotationCreator';
import { useTextAnnotationCreator } from './useTextAnnotationCreator';
import { usePolygonAnnotationCreator } from './usePolygonAnnotationCreator';
import { usePolylineAnnotationCreator } from './usePolylineAnnotationCreator';

import {
  CanvasMode,
  CanvasAnnotationType,
  CanvasAnnotation,
  CanvasInteractionEvent,
  AnnotationCreatorBuilder,
  AnnotationSourceType,
} from '../types';
import { Dimensions, LineAnnotation } from '@clef/shared/types';
import { usePrevious } from '../../../hooks/usePrevious';
import { MutableRefObject, useCallback } from 'react';
import { DefectCreatorRef } from '../components/DefectCreator';
import { useSAMAnnotationCreator } from './useSAMAnnotationCreator';

export const generateAnnotationId = () => {
  // transform current timestamp to base 36 string that consist of [0-9a-z], length is 8 chars
  return Number(new Date()).toString(36);
};

type AnnotationCreatorOptions = {
  zoomScale: number;
  color?: string;
  disabled?: boolean;
  onDisabledMouseDown?: () => void;
  onAnnotationCreated?: (ann: CanvasAnnotation) => void;
  mousePos?: { x: number; y: number };
  lineStrokeWidth: number;
  segmentationOpacity: number;
  onInteractionEvent?: (type: CanvasInteractionEvent) => void;
  onError?: (error: any) => void;
  wipText: string;
  fontSize?: number;
  otherTextSelected?: boolean;
  imageDimensions?: Dimensions;
  defectCreatorRef?: MutableRefObject<DefectCreatorRef | null>;
  enableContour?: boolean;
  imageSize?:
    | {
        height: number;
        width: number;
      }
    | null
    | undefined;
  onnxModel?: ArrayBuffer | undefined;
  imageEmbedding?: ArrayBuffer | undefined;
};

const nullAnnotationCreatorBuilder: AnnotationCreatorBuilder = {
  creatorPreviewComponent: null,
  creatorHelperComponent: null,
  creatorInteractiveProps: {},
  creatorCustomHandlers: {},
  customCursor: null,
};

/**
 * Return the correspond creator component for provided
 * @param mode CanvasMode
 * @param options AnnotationCreatorOptions
 * @returns AnnotationCreatorBuilder
 */
export const useAnnotationCreator = (
  mode: CanvasMode,
  options: AnnotationCreatorOptions,
): AnnotationCreatorBuilder => {
  const {
    zoomScale,
    color,
    disabled = false,
    onDisabledMouseDown,
    onAnnotationCreated,
    mousePos,
    lineStrokeWidth,
    segmentationOpacity,
    onInteractionEvent,
    onError,
    wipText,
    otherTextSelected,
    fontSize,
    imageDimensions,
    defectCreatorRef,
    enableContour = false,
    imageSize,
    onnxModel,
    imageEmbedding,
  } = options;

  const onIsWorkingInProgressChanged = useCallback(
    (isWorkingInProgress: boolean) =>
      onInteractionEvent?.(
        isWorkingInProgress
          ? CanvasInteractionEvent.DrawingStarted
          : CanvasInteractionEvent.DrawingEnded,
      ),
    [onInteractionEvent],
  );

  const lineParams = {
    disabled,
    color,
    mousePos,
    strokeWidth: lineStrokeWidth,
    opacity: segmentationOpacity,
    onFinishAnnotation: (newLine: LineAnnotation) => {
      onAnnotationCreated?.({
        id: generateAnnotationId(),
        type: CanvasAnnotationType.Line,
        data: newLine,
        created: true,
        group: AnnotationSourceType.GroundTruth,
      });
      // Show the quick defect creator after finish drawing annotation
      if (defectCreatorRef?.current?.mode === 'label-without-defect') {
        defectCreatorRef?.current?.setMode('create-defect');
      }
    },
    onDisabledMouseDown,
    onMissingColor: () => onInteractionEvent?.(CanvasInteractionEvent.MissingColor),
    onIsWorkingInProgressChanged,
    enableContour,
  };
  const wipLineAnnotationBuilder = useLineAnnotationCreator({ ...lineParams, zoomScale });

  const wipPolygonAnnotationBuilder = usePolygonAnnotationCreator({
    scale: zoomScale,
    color,
    opacity: segmentationOpacity,
    onFinishAnnotation: newPolygon => {
      onAnnotationCreated?.({
        id: generateAnnotationId(),
        type: CanvasAnnotationType.Polygon,
        data: newPolygon,
        created: true,
        group: AnnotationSourceType.GroundTruth,
      });
    },
    onMissingColor: () => onInteractionEvent?.(CanvasInteractionEvent.MissingColor),
    onIsWorkingInProgressChanged,
  });

  const wipPolylineAnnotationBuilder = usePolylineAnnotationCreator({
    color,
    strokeWidth: lineStrokeWidth,
    opacity: segmentationOpacity,
    onMissingColor: () => onInteractionEvent?.(CanvasInteractionEvent.MissingColor),
    onFinishAnnotation: newLine => {
      onAnnotationCreated?.({
        id: generateAnnotationId(),
        type: CanvasAnnotationType.Line,
        data: newLine,
        created: true,
        group: AnnotationSourceType.GroundTruth,
      });
    },
    onIsWorkingInProgressChanged,
  });

  const wipTextAnnotationBuilder = useTextAnnotationCreator({
    color,
    text: wipText,
    fontSize: fontSize!,
    otherTextSelected: otherTextSelected!,
    onFinishAnnotation: newText => {
      onAnnotationCreated?.({
        id: generateAnnotationId(),
        type: CanvasAnnotationType.Text,
        data: newText,
        created: true,
      });
    },
  });

  const wipBoxAnnotationBuilder = useBoxAnnotationCreator({
    scale: zoomScale,
    color,
    disabled,
    imageDimensions,
    mousePos,
    onMissingColor: () => onInteractionEvent?.(CanvasInteractionEvent.MissingColor),
    onFinishAnnotation: newBox => {
      onAnnotationCreated?.({
        id: generateAnnotationId(),
        type: CanvasAnnotationType.Box,
        data: newBox,
      });

      // Show the quick defect creator after finish drawing annotation
      if (defectCreatorRef?.current?.mode === 'label-without-defect') {
        defectCreatorRef?.current?.setMode('create-defect');
      }
    },
    onIsWorkingInProgressChanged,
    onDisabledMouseDown,
    onMouseDown: () => {
      defectCreatorRef?.current?.setShow(false);
    },
    onMouseUp: () => {
      defectCreatorRef?.current?.setShow(true);
    },
  });

  const wipSAMAnnotationBuilder = useSAMAnnotationCreator({
    disabled: disabled || mode !== CanvasMode.IQuickLabeling,
    color,
    opacity: segmentationOpacity,
    defectCreator: defectCreatorRef?.current,
    // If defect creator is disabled, check missing color
    onMissingColor: defectCreatorRef?.current
      ? undefined
      : () => onInteractionEvent?.(CanvasInteractionEvent.MissingColor),
    onFinishAnnotation: canvas => {
      onAnnotationCreated?.({
        id: generateAnnotationId(),
        type: CanvasAnnotationType.PureCanvas,
        data: canvas,
        created: true,
        group: AnnotationSourceType.GroundTruth,
      });
    },
    onIsWorkingInProgressChanged,
    imageSize,
    onnxModel,
    imageEmbedding,
    zoomScale,
    onError,
  });

  const getAnnotationCreatorBuilder = useCallback(
    (md: CanvasMode | undefined) => {
      return (
        (md === CanvasMode.IBox && wipBoxAnnotationBuilder) ||
        (md === CanvasMode.ILine && wipLineAnnotationBuilder) ||
        (md === CanvasMode.IPolygon && wipPolygonAnnotationBuilder) ||
        (md === CanvasMode.IPolyline && wipPolylineAnnotationBuilder) ||
        (md === CanvasMode.IText && wipTextAnnotationBuilder) ||
        (md === CanvasMode.IQuickLabeling && wipSAMAnnotationBuilder) ||
        nullAnnotationCreatorBuilder
      );
    },
    [
      wipBoxAnnotationBuilder,
      wipLineAnnotationBuilder,
      wipPolygonAnnotationBuilder,
      wipPolylineAnnotationBuilder,
      wipSAMAnnotationBuilder,
      wipTextAnnotationBuilder,
    ],
  );

  const currCreatorBuilder = getAnnotationCreatorBuilder(mode);
  const prevMode = usePrevious(mode);
  const prevCreatorBuilder = getAnnotationCreatorBuilder(prevMode);

  return {
    ...currCreatorBuilder,
    creatorCustomHandlers: {
      ...currCreatorBuilder.creatorCustomHandlers,
      // when mode changed, onModeChanged should be from the previous builder
      onModeChanged: prevCreatorBuilder.creatorCustomHandlers?.onModeChanged,
    },
  };
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import {
  MediaDetails,
  UpsertLabelsParameters,
  Label,
  LabelReviewStatus,
  MediaStatusType,
} from '@clef/shared/types';

import LabelAPI from '@/api/label_api';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import { datasetQueryKeys } from '@/serverStore/dataset';
import { labelQueryKeys } from '@/serverStore/label';
import { generateRandomId } from '@/utils';

export const useUpsertLabels = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { datasetId, id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  const { id: currentUserId } = useTypedSelector(state => state.login.user!);

  return useMutation({
    mutationFn: async (params: UpsertLabelsParameters) => {
      if (!projectId || !datasetId) return;
      const { annotations, mediaId, mediaLevelLabel, source } = params;
      await LabelAPI.upsertLabels({
        projectId,
        mediaId,
        annotations,
        mediaLevelLabel,
        source,
        modelId: currentModelId,
      });
      return params;
    },
    onMutate: params => {
      if (!datasetId) return;
      const { annotations, mediaId, mediaLevelLabel, source } = params;
      const mediaDetailQueryKey = datasetQueryKeys.mediaDetails(datasetId, {
        mediaId,
        modelId: currentModelId,
      });
      queryClient.cancelQueries(mediaDetailQueryKey);
      const prevData = queryClient.getQueriesData(mediaDetailQueryKey);

      queryClient.setQueryData<MediaDetails>(
        datasetQueryKeys.mediaDetails(datasetId, {
          mediaId,
          modelId: currentModelId,
        }),
        prev => {
          if (!prev) return prev;
          const newLabel = prev.label ? { ...prev.label } : ({ id: Date.now() } as Label);

          newLabel.reviewStatus = LabelReviewStatus.Accepted;
          newLabel.labelerName = currentUserId;
          newLabel.annotations = annotations.map(ann => ({ ...ann, id: generateRandomId() })); // for optimistic update data used in canvas
          newLabel.mediaLevelLabel = mediaLevelLabel;
          newLabel.source = source;
          return {
            ...prev,
            label: newLabel,
            mediaStatus:
              annotations.length === 0 && !mediaLevelLabel
                ? MediaStatusType.Raw
                : MediaStatusType.Approved,
          } as MediaDetails;
        },
      );
      return { params, prevData };
    },
    onError: (error: Error, _, context) => {
      if (!datasetId) return;
      queryClient.setQueryData(
        datasetQueryKeys.mediaDetails(datasetId, {
          mediaId: context?.params.mediaId,
          modelId: currentModelId,
        }),
        context?.prevData,
      );
      enqueueSnackbar(
        t('Failed to save annotations for media {{name}}: {{errorMessage}}', {
          name: context?.params.mediaName,
          errorMessage: error.message,
        }),
        { variant: 'error' },
      );
    },
    onSettled: params => {
      if (projectId && datasetId && params) {
        queryClient.removeQueries(
          labelQueryKeys.modelAssistSuggestions(projectId, params.mediaId, currentModelId),
        );
        queryClient.invalidateQueries(datasetQueryKeys.allWithFilters(projectId));
      }
    },
  });
};

import { Transform } from '@clef/shared/types';
import { Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(({ spacing }) => ({
  dropdown: {
    width: 140,
  },
  configItem: {
    marginBottom: spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    textTransform: 'capitalize',
    minWidth: 120,
  },
}));

export const Dropdown = ({
  title,
  transform,
  value,
  updateTransformParam,
}: {
  title: string;
  transform: Transform;
  value: string;
  updateTransformParam(value: string): void;
}) => {
  const styles = useStyles();
  const items = transform.paramsDescription[title].jsonSchema.enum;
  return items ? (
    <div className={styles.configItem}>
      <div className={styles.title}>{title.replace(/_/g, ' ')}</div>
      <Select
        data-testid={`transform-dropdown`}
        className={styles.dropdown}
        value={value}
        label={title}
        onChange={e => updateTransformParam(String(e.target.value))}
      >
        {items.map(item => (
          <MenuItem key={String(item)} value={String(item)}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </div>
  ) : null;
};

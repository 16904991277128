import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '@clef/client-library';
import { makeStyles } from '@material-ui/core/styles';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import ConfirmationDialog from '../../../components/Utils/ConfirmationDialog';
import { AnyFunction } from '@clef/shared/types/utils';
import { useSnackbar } from 'notistack';
import { SHORT_ERROR, SHORT_SUCCESS } from '../../../utils/notif_utils';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/GetApp';
import { useRemoveMediaFromDataset } from '../../../hooks/api/useDatasetApi';
import { useHasPermission } from '../../../hooks/useProjectRolePermissions';
import { UserPermission } from '@clef/shared/types';

interface MediaActionsSectionProps {
  mediaId: number;
  mediaUrl: string;
  onDelete?: AnyFunction;
}

const useStyles = makeStyles(() => ({
  normalText: {},
}));

const MediaActionsSection: React.FC<MediaActionsSectionProps> = ({
  mediaId,
  mediaUrl,
  onDelete,
}) => {
  const classes = useStyles();
  const { datasetId, id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    fetch(mediaUrl)
      .then(response => {
        response.arrayBuffer().then(buffer => {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          setDownloadUrl(url);
        });
      })
      .catch(err =>
        enqueueSnackbar(err.body?.message ?? err.message, {
          variant: 'error',
          autoHideDuration: 12000,
          preventDuplicate: true,
        }),
      );
  }, [mediaUrl]);

  const closeConfirmDialog = useCallback(() => {
    setOpenConfirmDialog(false);
  }, [setOpenConfirmDialog]);

  const cancelAction = useCallback(() => {
    closeConfirmDialog();
  }, [closeConfirmDialog]);

  const removeMediaFromDataset = useRemoveMediaFromDataset();

  const confirmAction = useCallback(() => {
    if (!projectId || !datasetId) return;
    removeMediaFromDataset(projectId, datasetId, {
      selectedMedia: [mediaId],
      isUnselectMode: false,
      unselectedMedia: [],
      fieldFilterMap: {},
      columnFilterMap: {},
    })
      .then(res => {
        if (res.deletedMediasCount == 1) {
          closeConfirmDialog();
          enqueueSnackbar(t('Media deleted successfully'), SHORT_SUCCESS);
          onDelete?.();
        } else {
          enqueueSnackbar(t('Media not deleted'), SHORT_ERROR);
        }
      })
      .catch(e => {
        enqueueSnackbar('Failed to delete media ' + e.message, SHORT_ERROR);
      });
  }, [
    projectId,
    datasetId,
    removeMediaFromDataset,
    mediaId,
    closeConfirmDialog,
    enqueueSnackbar,
    onDelete,
  ]);
  const actions = [
    <Button
      key="download"
      className={classes.normalText}
      startIcon={<DownloadIcon />}
      component="a"
      href={downloadUrl}
      target="_blank"
      download={`${mediaId}.png`}
      id={'media-details-download-btn'}
    >
      {t('Download')}
    </Button>,
  ];

  const canUploadData = useHasPermission(UserPermission.UploadData);
  if (onDelete && canUploadData) {
    actions.push(
      <Button
        className={classes.normalText}
        startIcon={<DeleteIcon />}
        key="delete"
        onClick={() => setOpenConfirmDialog(true)}
        id={'media-details-delete-btn'}
      >
        {t('Remove from dataset')}
      </Button>,
    );
  }

  return (
    <>
      {actions.map(action => action)}
      <ConfirmationDialog
        title="Confirm deletion"
        open={openConfirmDialog}
        onClose={cancelAction}
        onClickConfirm={confirmAction}
        onClickCancel={cancelAction}
        text={'Media deletion results in deletion of labels. Do you still want to continue?'}
      />
    </>
  );
};

export default MediaActionsSection;

import React, { createContext, useContext, useEffect, useRef } from 'react';
import { LabelType, ProjectId } from '@clef/shared/types';
import { useDebounce } from '@clef/client-library';
import { isEqual, pick } from 'lodash';
import {
  DataBrowserState,
  defaultState,
  useDataBrowserState,
} from '@/pages/DataBrowser/dataBrowserState';
import { useSnackbar } from 'notistack';
import { useSavePostProcessingConfig } from './hooks/useSavePostProcessingConfig';
import { LivePrediction, ProcessedFile, useLivePredictions } from './hooks/useLivePredictions';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';

const DEBOUNCE_DELAY = 1000;

export interface PredictContextProps {
  livePredictions: LivePrediction[];
  setLivePredictions: React.Dispatch<React.SetStateAction<LivePrediction[]>>;
  clearLivePredictions: () => void;
  getImageFilePredictions: (
    imageFiles: File | ProcessedFile | (File | ProcessedFile)[],
  ) => Promise<void>;
  refreshImageFilePredictions: () => void;
}

export const PredictContext = createContext<PredictContextProps | null>(null);

export const usePredictContext = (useFallbackValues: boolean = true) => {
  const context = useContext(PredictContext);

  if (!context) {
    if (useFallbackValues) {
      return {
        livePredictions: [],
        setLivePredictions: () => {},
        clearLivePredictions: () => {},
        getImageFilePredictions: () => {},
        refreshImageFilePredictions: () => {},
      };
    } else {
      throw Error('No predict context provider initialized');
    }
  }

  return context;
};

// TODO: Move the instant learning related states from data browser context state to this context
export const PredictContextProvider: React.FC<{
  projectId: ProjectId;
  datasetId?: number;
  labelType?: LabelType | null;
  modelId?: string;
}> = ({ children, projectId, datasetId, labelType, modelId }) => {
  const { enqueueSnackbar } = useSnackbar();
  // Note, only instant learning projects have the dependency on data browser context state
  const { state: dataBrowserState } = useDataBrowserState();
  const {
    TEMP_defectIdToSegmentationAreaThreshold,
    TEMP_imageLevelClassificationRuleCollection,
    TEMP_postProcessingLoading,
  } = dataBrowserState;
  const instantLearningPostprocessingEnabled = useFeatureGateEnabled(
    ClientFeatures.InstantLearningPostprocessing,
  );

  const {
    livePredictions,
    setLivePredictions,
    clearLivePredictions,
    getImageFilePredictions,
    refreshImageFilePredictions,
  } = useLivePredictions(projectId, datasetId, modelId);

  const savePostProcessingConfig = useSavePostProcessingConfig();
  const savePostProcessingConfigDebounced = useDebounce(async () => {
    // Step 1 - Save the post-processing config
    try {
      // If there are predictions, mark all of them as loading
      if (livePredictions.length) {
        setLivePredictions(predictions =>
          predictions.map(prediction => {
            prediction.loading = true;
            return prediction;
          }),
        );
      }

      await savePostProcessingConfig();
    } catch (e) {
      enqueueSnackbar('Failed to save post-processing configuration', { variant: 'error' });

      // If there are predictions, mark all of them as having error
      if (livePredictions.length) {
        setLivePredictions(predictions =>
          predictions.map(prediction => {
            prediction.error = 'Failed to save post-processing configuration';
            return prediction;
          }),
        );
      }

      // If failed to save post-processing config, skip prediction refreshing
      return;
    }

    // Step 2 - Refresh all predictions if any
    if (livePredictions.length) {
      refreshImageFilePredictions();
    }
  }, DEBOUNCE_DELAY);

  const previousRawConfigRef = useRef<Pick<
    DataBrowserState,
    'TEMP_defectIdToSegmentationAreaThreshold' | 'TEMP_imageLevelClassificationRuleCollection'
  > | null>(null);

  // Auto saving the post-processing config if changed and feature toggle is enabled
  useEffect(() => {
    if (
      labelType !== LabelType.SegmentationInstantLearning ||
      !instantLearningPostprocessingEnabled
    ) {
      return;
    }

    const rawConfig = {
      TEMP_defectIdToSegmentationAreaThreshold,
      TEMP_imageLevelClassificationRuleCollection,
    };

    const defaultConfig = pick(defaultState, [
      'TEMP_defectIdToSegmentationAreaThreshold',
      'TEMP_imageLevelClassificationRuleCollection',
    ]);

    // If post processing config is loaded and not the default values, we process the config
    if (!TEMP_postProcessingLoading && !isEqual(rawConfig, defaultConfig)) {
      // If previous config is empty, then it is the initial config
      if (!previousRawConfigRef.current) {
        previousRawConfigRef.current = rawConfig;
      }
      // Otherwise, there is a change on config, and we will save it
      else if (!isEqual(previousRawConfigRef.current, rawConfig)) {
        previousRawConfigRef.current = rawConfig;
        savePostProcessingConfigDebounced();
      }
    }
  }, [
    TEMP_defectIdToSegmentationAreaThreshold,
    TEMP_imageLevelClassificationRuleCollection,
    savePostProcessingConfigDebounced,
    TEMP_postProcessingLoading,
    labelType,
    instantLearningPostprocessingEnabled,
  ]);

  return (
    <PredictContext.Provider
      value={{
        livePredictions,
        setLivePredictions,
        clearLivePredictions,
        getImageFilePredictions,
        refreshImageFilePredictions,
      }}
    >
      {children}
    </PredictContext.Provider>
  );
};

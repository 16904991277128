import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { ApiResponseLoader } from '@clef/client-library';
import { useImmer } from 'use-immer';

import {
  useTaskApi,
  useUserTaskMediaLabelBatch,
  refreshTaskInfo,
} from '../../hooks/api/useTaskApi';
import {
  LabelingTaskContext,
  defaultState as defaultLabelingTaskState,
  LabelingTaskState,
} from './labelingTaskState';
import {
  defaultState as defaultLabelingState,
  ToolMode,
} from '../../components/Labeling/labelingState';
import { useLabelingTaskStyles } from './labelingTaskStyles';

import LabelingTaskHeader from './LabelingTaskHeader';
import LabelingTaskMediaList from './LabelingTaskMediaList';
import LabelingTaskMain from './LabelingTaskMain';
import { MediaLevelLabel, LabelingType, Annotation } from '@clef/shared/types';
import { useDefectSelector } from '../../store/defectState/actions';
import { HintSnackbarContextProvider } from '../../components/Labeling/HintSnackbar';
import LabelingTaskTutorial, {
  ShowLabelingSegmentationTutorialCache,
} from './LabelingTaskTutorial';
import CLEF_PATH from '../../constants/path';
import useGoBack from '../../hooks/useGoBack';
import { LabelingContext } from '../../components/Labeling/labelingState';
import { serverAnnotationsToLabelingAnnotations } from '../../components/Labeling/utils';
import ImageEnhancerContext from '../../components/ImageEnhancer/ImageEnhancerContext';

const LabelingTaskPage: React.FC = ({ children }) => {
  const history = useHistory();
  const styles = useLabelingTaskStyles();

  const { taskId: taskIdStr } = useParams<{
    taskId?: string;
  }>();

  const taskId = Number(taskIdStr);
  const [taskInfo, taskInfoLoading, taskInfoError] = useTaskApi(taskId);
  const [taskMediaBatch, taskMediaBatchLoading, taskMediaBatchError] =
    useUserTaskMediaLabelBatch(taskId);
  const allDefects = useDefectSelector();

  const [labelingTaskState, dispatchLabelingTaskState] = useImmer(defaultLabelingTaskState);
  const [labelingState, dispatchLabelingState] = useImmer(defaultLabelingState);

  // When receiving taskMediaBatch and allDefects
  // 1. Save to labelingTaskState.taskMediaList
  // 2. Use first mediaId as labelingTaskState.currentMediaId
  // 4. Initiate labelingTaskState.annotationData
  // 5. Initiate labelingTaskState.toolOptions.strokeWidth/eraserWidth based on first image dimensions
  // 6. Preload all the image, so later navigation can be really quick
  useEffect(() => {
    if (
      !labelingTaskState.currentMediaId &&
      taskMediaBatch &&
      taskMediaBatch.length &&
      allDefects &&
      taskInfo
    ) {
      // the order of media seems not guaranteed per refresh, sort it by media id to make sure it's the same
      const taskMediaBatchSorted = taskMediaBatch.sort((mediaA, mediaB) => mediaA.id - mediaB.id);
      const initialAnnotationMap = taskMediaBatchSorted.reduce(
        (acc, taskMedia) => ({
          ...acc,
          [taskMedia.id]:
            (taskMedia?.label?.mediaLevelLabel === MediaLevelLabel.OK && []) ||
            ((taskMedia?.label?.mediaLevelLabel === MediaLevelLabel.NG &&
              serverAnnotationsToLabelingAnnotations(
                taskMedia?.label?.annotations as Annotation[],
              )) ??
              []) ||
            undefined,
        }),
        {} as LabelingTaskState['annotationData'],
      );

      dispatchLabelingTaskState(draft => {
        draft.currentMediaId = taskMediaBatchSorted[0].id;
        draft.taskMediaList = taskMediaBatchSorted;
        draft.annotationData = initialAnnotationMap;
      });

      dispatchLabelingState(draft => {
        if (taskInfo.labelingType.includes(LabelingType.DefectBoundingBox)) {
          draft.labelingType = LabelingType.DefectBoundingBox;
          // there is only one mode for bounding box labeling task, it is always selected
          draft.toolMode = ToolMode.Box;
        } else if (taskInfo.labelingType.includes(LabelingType.DefectSegmentation)) {
          draft.labelingType = LabelingType.DefectSegmentation;
        } else if (taskInfo.labelingType.includes(LabelingType.DefectClassification)) {
          draft.labelingType = LabelingType.DefectClassification;
        }
        // if first media has properties, initialize strokeWidth with that
        if (taskMediaBatchSorted[0].properties) {
          const initialWidth = Math.max(
            1,
            Math.round(
              (taskMediaBatchSorted[0].properties.width +
                taskMediaBatchSorted[0].properties.height) /
                100,
            ),
          );
          draft.toolOptions.strokeWidth = initialWidth;
          draft.toolOptions.eraserWidth = Math.round(initialWidth / 2);
        }
      });
    }
  }, [
    dispatchLabelingTaskState,
    labelingTaskState.currentMediaId,
    taskMediaBatch,
    allDefects,
    taskInfo,
    dispatchLabelingState,
  ]);

  const goBack = useGoBack(CLEF_PATH.myTasks);

  // if there are no more media to label, go back
  useEffect(() => {
    if (taskMediaBatch && !taskMediaBatch.length) {
      refreshTaskInfo({ keys: 'refresh-all' }); // refresh task because task might have finished review
      goBack();
    }
  }, [goBack, history, taskMediaBatch]);

  // reset states when switching media
  useEffect(() => {
    dispatchLabelingState(draft => {
      draft.toolOptions.erasing = false;
      draft.toolOptions.zoomScale = 'fit';
    });
  }, [dispatchLabelingState, labelingTaskState.currentMediaId]);

  return (
    <LabelingContext.Provider value={{ state: labelingState, dispatch: dispatchLabelingState }}>
      <LabelingTaskContext.Provider
        value={{ state: labelingTaskState, dispatch: dispatchLabelingTaskState }}
      >
        <HintSnackbarContextProvider>
          <ImageEnhancerContext>
            <ApiResponseLoader
              loading={taskInfoLoading || taskMediaBatchLoading}
              error={taskInfoError || taskMediaBatchError}
              response={
                taskInfo &&
                labelingTaskState.taskMediaList.length &&
                labelingTaskState.currentMediaId
                  ? { taskInfo }
                  : undefined
              }
              defaultWidth="100vw"
              defaultHeight="100vh"
            >
              {response => {
                return (
                  <>
                    <LabelingTaskHeader taskInfo={response.taskInfo} />
                    <div className={styles.mainContent}>
                      <LabelingTaskMediaList />
                      <LabelingTaskMain />
                    </div>
                    {/* For testing purpose only! */}
                    {children}
                  </>
                );
              }}
            </ApiResponseLoader>
          </ImageEnhancerContext>
        </HintSnackbarContextProvider>
        {ShowLabelingSegmentationTutorialCache.get() && <LabelingTaskTutorial />}
      </LabelingTaskContext.Provider>
    </LabelingContext.Provider>
  );
};

export default LabelingTaskPage;

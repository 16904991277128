import { Defect } from '@clef/shared/types';
import {
  useGetProjectDefectsQuery,
  useGetProjectVersionedDefectsQuery,
} from '@/serverStore/projects';
import { isPreCreatedDefect } from '@/utils';
import { useMemo } from 'react';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';

const EmptyDefects: Defect[] = [];

export const useDefectSelectorWithArchived = (): Defect[] => {
  const { data: defects } = useGetProjectDefectsQuery();
  return defects ?? EmptyDefects;
};

/**
 * This has dependencies on versioned defect and registered model
 * which are current tech debt, should be deprecated when we
 * make the clean up for those
 * @deprecated
 */
export const useCurrentDefectsWithArchivedAndIndexFromVersion = (version?: number): Defect[] => {
  const { data: currentDefects } = useGetProjectDefectsQuery();
  const { versionedDatasetContentId } = useCurrentProjectModelInfoQuery();
  const { data: defectMap } = useGetProjectVersionedDefectsQuery(
    version ?? versionedDatasetContentId,
  );
  return useMemo(
    () =>
      (defectMap ?? []).map(defect => ({
        ...(currentDefects?.find(_defect => _defect.id === defect.id) ?? defect),
        indexId: defect.indexId,
      })),
    [currentDefects, defectMap],
  );
};

export const useDefectSelector = (includePreCreatedDefects: boolean = true): Defect[] => {
  const allDefects = useDefectSelectorWithArchived();
  return useMemo(() => {
    const activeDefects = allDefects.filter(defect => {
      return defect.isArchived !== true;
    });
    return includePreCreatedDefects
      ? activeDefects
      : activeDefects.filter(defect => !isPreCreatedDefect(defect));
  }, [allDefects, includePreCreatedDefects]);
};

export const useArchivedDefects = (): Defect[] => {
  const allDefects = useDefectSelectorWithArchived();
  return allDefects.filter(defect => {
    return defect.isArchived === true;
  });
};

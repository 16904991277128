import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

import { DatasetVersion } from '@clef/shared/types';
import { Button, IconButton, Typography } from '@clef/client-library';

import { useEditSnapshotInfoMutation } from '@/serverStore/dataset';

import useStyles from './styles';

export interface EditDetailDialogProps {
  snapshot: DatasetVersion;
  onClose: () => void;
}

export const EditDetailDialog: React.FC<EditDetailDialogProps> = ({ snapshot, onClose }) => {
  const styles = useStyles();
  const { id } = snapshot;

  const [snapshotDesc, setSnapshotDesc] = useState(snapshot.details);

  const editSnapshot = useEditSnapshotInfoMutation();
  const handleSnapshotDescChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSnapshotDesc(e.target.value);
  };

  return (
    <Dialog open color="primary" PaperProps={{ className: styles.dialog }}>
      <DialogTitle className={styles.dialogSection}>
        <Typography variant="h2" className={styles.dialogTitleText}>
          {t('Edit Description')}
        </Typography>
        <IconButton
          id="edit-detail-dialog-close-btn"
          onClick={onClose}
          className={styles.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classNames(styles.dialogSection, styles.dialogContent)}>
        <TextField
          fullWidth
          size="small"
          multiline
          maxRows={4}
          InputProps={{
            classes: {
              root: styles.descInputWrapper,
              input: styles.descInput,
            },
          }}
          variant="outlined"
          value={snapshotDesc}
          onChange={handleSnapshotDescChange}
          placeholder={t(
            'e.g. Backup current data, training on subset, holdout for evaluation etc.',
          )}
        />
      </DialogContent>
      <DialogActions className={classNames(styles.dialogSection, styles.dialogActions)}>
        <Button id="cancel-btn" color="default" onClick={onClose} disabled={editSnapshot.isLoading}>
          {t('Cancel')}
        </Button>
        <Button
          id="save-changes-btn"
          color="primary"
          variant="contained"
          onClick={() => {
            editSnapshot.mutate(
              { datasetVersionId: id, details: snapshotDesc },
              {
                onSuccess: onClose,
              },
            );
          }}
          disabled={editSnapshot.isLoading || snapshotDesc === snapshot.details}
        >
          {t('Save Changes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDetailDialog;

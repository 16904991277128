import { Button, IconButton } from '@clef/client-library';
import { PendingUser, User, UserRole, UserStatus } from '@clef/shared/types';
import {
  canUpdateUserRole,
  getUserRolePermissionDescription,
  hasPermissionOver,
  isUser,
} from '@clef/shared/utils';
import { Column } from '@material-table/core';
import { Divider, Grid, Menu, MenuItem, OutlinedInput, Typography } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDialog } from '@/components/Layout/components/useDialog';
import MatTable from '../../components/Utils/MatTable';
import { changeUserActivation, updateUserRole } from '../../hooks/api/useUserApi';
import { useMenu } from '../../hooks/useMenu';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useAppDispatch } from '../../store';
import { fetchUsers } from '../../store/user_store';
import { rootElement } from '../../utils/dom_utils';
import { toTitle } from '../../utils/str_utils';
import { Chip } from './components/Chip';
import { InviteMembersDialog } from './components/InviteMembersDialog';
import { RevokeMemberInvitationDialog } from './components/RevokeMemberInvitationDialog';
import useStyles, { tableStyleOptions } from './styles';
import { upperFirst } from 'lodash';

type DataItem = User | PendingUser;

const getUserName = (item: DataItem) =>
  isUser(item) ? `${item.name} ${item.lastName}` : t('Pending user');

const getUserStatus = (item: DataItem) =>
  isUser(item) ? toTitle(item.status || 'Unknown') : toTitle(UserStatus.Pending);

export const Members: React.FC<{}> = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [searchText, setSearchText] = useState<string>('');
  const {
    open: memberActionsMenuOpen,
    menuRef: memberActionsMenuRef,
    onMenuOpen: onMemberActionsMenuOpen,
    onMenuClose: onMemberActionsMenuClose,
  } = useMenu();

  const loginUser = useTypedSelector(state => state.login.user!);
  const users = useTypedSelector(state => state.user.users);
  const pendingUsers = useTypedSelector(state => state.user.pendingUsers);
  const allUsers: DataItem[] = useMemo(() => [...users, ...pendingUsers], [users, pendingUsers]);

  const [inviteUsersDialogOpen, setInviteUsersDialogOpen] = useState<boolean>(false);
  const [revokeInvitationDialogOpen, setRevokeInvitationDialogOpen] = useState<boolean>(false);
  const { showConfirmationDialog } = useDialog();
  const [selectedUserEmail, setSelectedUserEmail] = useState<string>('');

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const renderedUsers = useMemo(
    () =>
      searchText
        ? allUsers.filter(user => {
            return (
              (isUser(user) ? `${user.name} ${user.lastName}` : t('Pending user'))
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
              user.email.toLowerCase().includes(searchText.toLowerCase())
            );
          })
        : [...allUsers],
    [allUsers, searchText],
  );

  const selectedUser = allUsers.filter(
    user => selectedUserEmail && user.email === selectedUserEmail,
  )[0];

  const columns: Column<DataItem>[] = useMemo(() => {
    return [
      { title: t('Id'), field: 'id', hidden: true },
      {
        title: t('Name'),
        render: getUserName,
        customSort: (a, b) => {
          const nameA = getUserName(a);
          const nameB = getUserName(b);
          return nameA.localeCompare(nameB);
        },
      },
      {
        title: t('Email'),
        render: item => item.email,
        customSort: (a, b) => a?.email.localeCompare(b?.email),
      },
      {
        title: t('Role'),
        width: 200,
        render: item => toTitle(item.userRole),
        customSort: (a, b) => {
          const titleA = toTitle(a.userRole);
          const titleB = toTitle(b.userRole);
          return titleA.localeCompare(titleB);
        },
      },
      {
        title: t('Status'),
        width: 200,
        render: item =>
          isUser(item) ? (
            <Chip
              text={toTitle(item.status || 'Unknown')}
              color={item.status === UserStatus.Active ? 'green' : 'blue'}
            />
          ) : (
            <Chip text={toTitle(UserStatus.Pending)} color="blue" />
          ),
        customSort: (a, b) => {
          const statusA = getUserStatus(a);
          const statusB = getUserStatus(b);
          return statusA.localeCompare(statusB);
        },
      },
      {
        title: '',
        align: 'right',
        sorting: false,
        width: 81,
        render: item => {
          return item.id !== loginUser.id &&
            (!isUser(item) ||
              (isUser(item) && hasPermissionOver(loginUser.userRole, item.userRole))) ? (
            <IconButton
              id="org-settings-members-more-vert-button"
              data-testid="org-settings-members-more-vert-button"
              onClick={e => {
                onMemberActionsMenuOpen(e);
                setSelectedUserEmail(item.email);
              }}
              className={styles.moreVertIconButton}
            >
              <MoreVertIcon />
            </IconButton>
          ) : null;
        },
      },
    ];
  }, [loginUser, onMemberActionsMenuOpen, styles.moreVertIconButton]);

  const handleDeactivateUser = useCallback(
    async (userId: string) => {
      await changeUserActivation(userId, UserStatus.Inactive)
        .then(() => {
          enqueueSnackbar(t('Successfully deactivated user'), {
            variant: 'success',
          });
          dispatch(fetchUsers());
        })
        .catch(() => {
          enqueueSnackbar(t('Failed deactivate user'), {
            variant: 'error',
          });
        });
    },
    [dispatch, enqueueSnackbar],
  );

  const handleReactivateUser = useCallback(
    async (userId: string) => {
      await changeUserActivation(userId, UserStatus.Active)
        .then(() => {
          enqueueSnackbar(t('Successfully reactivated user'), {
            variant: 'success',
          });
          dispatch(fetchUsers());
        })
        .catch(e => {
          enqueueSnackbar(
            e.body?.errorCode === 'QuotaExceeded'
              ? t('Failed reactivating user. Number of active members reached limit.')
              : t('Failed reactivating user.'),
            { variant: 'error', autoHideDuration: 12000 },
          );
        });
    },
    [dispatch, enqueueSnackbar],
  );

  const handleUpdateUserRole = useCallback(
    async (userId: string, userRole: UserRole) => {
      await updateUserRole(userId, userRole)
        .then(() => {
          enqueueSnackbar(
            t(`Successfully changed user role to {{userRole}}`, { userRole: upperFirst(userRole) }),
            {
              variant: 'success',
            },
          );
          dispatch(fetchUsers());
        })
        .catch(e => {
          enqueueSnackbar(
            t(`Failed changing user role to {{userRole}}, {{errorMessage}}`, {
              userRole: upperFirst(userRole),
              errorMessage: e.message,
            }),
            {
              variant: 'error',
            },
          );
        });
    },
    [dispatch, enqueueSnackbar],
  );

  const onHandleUserRoleClick = (selectedUser: User, expectedUserRole: UserRole) => {
    showConfirmationDialog({
      title: t(`Are you sure you want to change {{name}} {{lastName}} to {{userRole}}?`, {
        name: selectedUser.name,
        lastName: selectedUser.lastName,
        userRole: upperFirst(expectedUserRole),
      }),
      content: t('{{roleDescription}}. Are you sure you want to continue?', {
        roleDescription: getUserRolePermissionDescription(expectedUserRole),
      }),
      confirmText: t('Confirm'),
      color: 'primary',
      onConfirm: async () => {
        onMemberActionsMenuClose();
        await handleUpdateUserRole(selectedUser.id, expectedUserRole);
      },
    });
  };

  return (
    <div className={styles.membersContainer}>
      <Grid container className={styles.marginBottom5}>
        <Button
          id="org-settings-invite-members-button"
          color="primary"
          variant="contained"
          onClick={() => {
            setInviteUsersDialogOpen(true);
          }}
        >
          {t('Invite Members')}
        </Button>

        <div className={styles.grow} />

        <OutlinedInput
          value={searchText}
          onChange={({ target: { value } }) => setSearchText(value)}
          placeholder={t('Search member')}
          endAdornment={<SearchIcon className={styles.searchIcon} />}
          inputProps={{
            className: styles.projectGridSearchInput,
          }}
          className={styles.projectGridSearchContainer}
        />
      </Grid>

      <Menu
        id="member-actions-menu"
        open={memberActionsMenuOpen}
        anchorEl={memberActionsMenuRef}
        onClose={onMemberActionsMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        container={rootElement}
        disableAutoFocusItem
      >
        {isUser(selectedUser) ? (
          [
            selectedUser.status === UserStatus.Active
              ? [
                  Object.values(UserRole).map(userRole => {
                    let canUpdateUserRoleResult = false;
                    try {
                      canUpdateUserRoleResult = canUpdateUserRole(
                        loginUser.userRole,
                        selectedUser.userRole,
                        userRole,
                      );
                      if (!canUpdateUserRoleResult) return null;
                      return (
                        <MenuItem
                          id={`change ${selectedUser.userRole} to ${userRole}`}
                          key={`change ${selectedUser.userRole} to ${userRole}`}
                          onClick={() => {
                            onHandleUserRoleClick(selectedUser as User, userRole);
                          }}
                          button
                        >
                          <Typography>
                            {t('Change to {{userRole}}', { userRole: upperFirst(userRole) })}
                          </Typography>
                        </MenuItem>
                      );
                    } catch (e) {
                      return null;
                    }
                  }),
                  <Divider key="member-dropdown-divider" className={styles.divider} />,
                ]
              : null,
            <MenuItem
              id={
                selectedUser?.status === UserStatus.Active
                  ? 'deactivate-member-button'
                  : 'reactivate-member-button'
              }
              key={
                selectedUser?.status === UserStatus.Active
                  ? 'deactivate-member-button'
                  : 'reactivate-member-button'
              }
              onClick={async () => {
                if (selectedUser?.status === UserStatus.Active) {
                  handleDeactivateUser(selectedUser.id);
                } else {
                  handleReactivateUser(selectedUser.id);
                }
                onMemberActionsMenuClose();
              }}
              button
            >
              <Typography color="secondary" className={styles.changeMemberActivationButtonText}>
                {selectedUser?.status === UserStatus.Active
                  ? t('Deactivate Member')
                  : t('Activate Member')}
              </Typography>
            </MenuItem>,
          ]
        ) : (
          <MenuItem
            id={'revoke-pending-user-button'}
            onClick={() => {
              setRevokeInvitationDialogOpen(true);
              onMemberActionsMenuClose();
            }}
            button
          >
            <Typography>{t('Revoke invite')}</Typography>
          </MenuItem>
        )}
      </Menu>

      <MatTable<DataItem>
        title=""
        data={renderedUsers}
        columns={columns}
        options={{
          ...tableStyleOptions,
          search: false,
          toolbar: false,
          padding: 'dense',
        }}
      />

      <InviteMembersDialog
        open={inviteUsersDialogOpen}
        onClose={() => setInviteUsersDialogOpen(false)}
      />

      <RevokeMemberInvitationDialog
        open={revokeInvitationDialogOpen}
        email={selectedUser?.email}
        onClose={() => setRevokeInvitationDialogOpen(false)}
      />
    </div>
  );
};
export default Members;

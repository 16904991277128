import React, { ReactElement } from 'react';
import {
  AnnotationBase,
  AnnotationType,
  BoundingBoxPrediction,
  Defect,
  LabelType,
  SegmentationInstantLearningPrediction,
  SegmentationPrediction,
} from '@clef/shared/types';
import {
  getBoxAnnotations,
  isBoundingBoxPrediction,
  isClassificationPrediction,
  isSegmentationPrediction,
} from '../utils';
import { LivePrediction } from '../hooks/useLivePredictions';
import { BoxAnnotation, SegmentationAnnotation } from '@clef/client-library';
import { PureCanvasLabelingAnnotation } from '@/components/Labeling/labelingState';
import { useSegmentationPredictionAnnotations } from '@/pages/DataBrowser/utils';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';

interface AnnotationData {
  boxAnnotations: BoxAnnotation[] | undefined;
  segmentationAnnotations: (SegmentationAnnotation & PureCanvasLabelingAnnotation)[] | undefined;
  classificationAnnotations: AnnotationBase[] | undefined;
}

interface MainWrapperProps {
  threshold: number | undefined;
  prediction: LivePrediction;
  defects: Defect[];
  children: (data: AnnotationData) => ReactElement | null;
  labelType?: LabelType | null;
}

export const MainWrapper: React.FC<MainWrapperProps> = ({
  prediction,
  threshold,
  defects,
  children,
  labelType,
}) => {
  const getBBoxAnnotations = () => {
    const result = prediction?.result as BoundingBoxPrediction;
    return isBoundingBoxPrediction(result)
      ? getBoxAnnotations(result.label!, defects!, threshold)
      : undefined;
  };

  const boxAnnotations = getBBoxAnnotations();

  const instantLearningPostprocessingEnabled = useFeatureGateEnabled(
    ClientFeatures.InstantLearningPostprocessing,
  );
  const getSegS3Path = () => {
    if (
      labelType === LabelType.SegmentationInstantLearning &&
      instantLearningPostprocessingEnabled
    ) {
      return (
        (prediction?.result as SegmentationInstantLearningPrediction)?.postProcLabel
          ?.filteredMaskPath ?? ''
      );
    }

    const result = prediction?.result as SegmentationPrediction;
    if (isSegmentationPrediction(result)) {
      // For snowflake, we use relative path to pictor, which causes prediction seg masks not rendered for some reason.
      // This is a workaround by making it a full path.
      // TODO: @tian.lan Debug why relative path does not work for prediction but works for GT.
      const segS3Path = result.segS3Path ?? '';
      return segS3Path.startsWith('/') ? window.location.origin + segS3Path : segS3Path;
    }
    return '';
  };

  const segmentationAnnotations = useSegmentationPredictionAnnotations(
    getSegS3Path(),
    defects,
    threshold || 0,
    0.8,
  );

  const classificationAnnotations = isClassificationPrediction(prediction?.result)
    ? [
        {
          defectId: prediction?.result?.label.defectId,
          confidence: prediction?.result?.label.score || 0,
          annotationType: AnnotationType.classification,
          dataSchemaVersion: 0,
        },
      ]
    : undefined;

  return children({
    boxAnnotations,
    segmentationAnnotations,
    classificationAnnotations,
  });
};

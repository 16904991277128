import { Project, ProjectId } from '@clef/shared/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { projectQueryKeys } from './queries';
import { setSelectedProjectId } from '@/store/projectState';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { useAppDispatch } from '@/store';

export const useSetSelectedProjectId = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const orgId = useTypedSelector(state => state.login.user?.orgId);

  return useCallback(
    async (projectId: ProjectId | undefined, shouldInvalidProjectQuery: boolean = false) => {
      if (projectId === undefined) {
        await dispatch(setSelectedProjectId(undefined));
        return;
      }
      const projects = queryClient.getQueryData<Project[]>(projectQueryKeys.list(orgId ?? -1));
      const selectedProject = projects?.find(project => project.id === projectId);
      if (!selectedProject) {
        return;
      }
      await dispatch(setSelectedProjectId(selectedProject.id)).unwrap();
      if (shouldInvalidProjectQuery) {
        queryClient.invalidateQueries({
          queryKey: projectQueryKeys.detail(projectId),
        });
      }
    },
    [orgId],
  );
};

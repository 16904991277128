import { DependencyList, useEffect, useRef } from 'react';

const DEFAULT_WAIT = 500;

/**
 * Debounced `useEffect`.
 *
 * The effect will be executed in a debounced manner.
 *
 * @param factory the function to run
 * @param deps the dependency array
 * @param wait the wait time for the debounced behavior
 */
export const useDebouncedEffect = <T>(
  factory: () => T,
  deps: DependencyList | undefined,
  wait: number = DEFAULT_WAIT,
) => {
  const timeout = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current as number);
    }

    timeout.current = setTimeout(() => {
      factory();
    }, wait) as unknown as number;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

import { makeStyles } from '@material-ui/core';
const DND_ICON_OFFSET = -44;
const useStyles = makeStyles(theme => ({
  overlay: {
    top: DND_ICON_OFFSET,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    zIndex: theme.zIndex.drawer - 1,
    position: 'absolute',
    alignItems: 'center',
    flexDirection: 'column',
    backdropFilter: 'blur(3px)',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255, 1)',
  },
  dropzone: {
    border: 'none',
    background: 'none',
    cursor: 'unset',
  },
  cloudIcon: {
    width: 100,
    height: 100,
  },
  northIcon: {
    width: 60,
    height: 60,
    position: 'absolute',
    top: 75,
    left: '50%',
    transform: 'translateX(-50%)',
    animation: '$northIconAnimation 2s ease infinite',
  },
  emptyStateText: {
    color: theme.palette.grey[900],
    fontSize: 20,
    lineHeight: '28px',
    marginBottom: theme.spacing(7),
    marginTop: theme.spacing(5),
  },
  '@keyframes northIconAnimation': {
    '50%': { top: 65 },
  },

  '@keyframes circle1Animation': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  circleBox: {
    width: 360,
    height: 360,
  },
  circle1: {
    width: 360,
    height: 360,
    borderRadius: '100%',
    backgroundColor: theme.palette.blue[25],
    transformOrigin: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    animation: '$circle1Animation 2s ease infinite',
  },
  circle2: {
    position: 'absolute',
    top: 60,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 240,
    height: 240,
    borderRadius: '100%',
    backgroundColor: theme.palette.blue[50],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 10,
  },
}));

export default useStyles;

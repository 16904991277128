import React from 'react';
import cx from 'classnames';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Helmet from 'react-helmet';

const useStyles = makeStyles(theme => ({
  pageTitleContainer: {
    justifyContent: 'space-between',
    marginBottom: theme.spacing(7),
    // For defect book printing
    '@media print': {
      display: 'none',
    },
  },
  pageTitle: {
    fontSize: 16,
  },
}));

interface PageTitleProps {
  title: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  const classes = useStyles();
  return (
    <Grid container className={cx(classes.pageTitleContainer, 'cy-page-title')}>
      <Typography variant="h1">{title}</Typography>
    </Grid>
  );
};

type MetaTitleProps = {
  title?: string | null | undefined;
};

export const MetaTitle: React.FC<MetaTitleProps> = ({ title }) => (
  <Helmet>
    <title>{title ? `${title} - LandingAI` : t('LandingAI')}</title>
  </Helmet>
);

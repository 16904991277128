import { Link, IconButton as MuiIconButton, Tooltip, TooltipProps } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';
import React, { forwardRef } from 'react';
import EventLogger, { LoggingEvent } from '../../logging/EventLogger';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  spinner: {
    marginLeft: theme.spacing(-3),
    marginTop: theme.spacing(-3),
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
}));

type Props = {
  isPending?: boolean;
  id?: string;
  children: React.ReactNode;
  tooltip?: string;
  tooltipProps?: TooltipProps;
  component?: string;
} & React.ComponentProps<typeof MuiIconButton> &
  Pick<React.ComponentProps<typeof Link>, 'href' | 'rel' | 'target' | 'download'>;

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      // custom props
      isPending,
      id,
      // button props
      children,
      disabled,
      onClick,
      className,
      tooltip,
      tooltipProps,
      ...otherProps
    },
    fRef,
  ) => {
    const styles = useStyles();
    const button = (
      <MuiIconButton
        id={id}
        data-testid={id}
        {...otherProps}
        ref={fRef}
        className={cx(className, id)}
        disabled={isPending || disabled}
        onClick={e => {
          onClick?.(e);
          if (id) {
            EventLogger.log(LoggingEvent.ActionEvent, {
              actionType: 'button',
              actionEventName: id,
            });
          }
        }}
      >
        {children}
        {isPending && <CircularProgress size={24} className={styles.spinner} />}
      </MuiIconButton>
    );
    return tooltip ? (
      <Tooltip arrow title={tooltip} placement="top" {...tooltipProps}>
        <div>{button}</div>
      </Tooltip>
    ) : (
      button
    );
  },
);

export default Button;

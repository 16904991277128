import { cloneDeep } from 'lodash';
import { useCallback, useRef, useState } from 'react';

export type ValueSetter<T> = T extends Function ? never : T | ((prev: T) => T);

export type Memento<T> = {
  current: T;
  canUndo: boolean;
  canRedo: boolean;
  /**
   * store a value into memento
   */
  set: (value: ValueSetter<T>) => T;
  /**
   * replace the current value without changing the history
   */
  replace: (value: ValueSetter<T>) => T;
  undo: () => T; // returns new current item
  redo: () => T; // returns new current item
  reset: (initValue: T) => void;
};

/**
 * Hooks verson of memento https://en.wikipedia.org/wiki/Memento_pattern.
 * @param initValue initial value. If it changes, the history will be cleared.
 * @param maxUndoCount limit the undo count. 0 means no limit
 */
export default function useMemento<T>(initValue: T, maxUndoCount = 10): Memento<T> {
  // the first position of history list stores the initValue and cannot be undone
  const [history, setHistory] = useState([initValue] as T[]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [current, setCurrent] = useState(initValue);

  const currentRef = useRef<T>();
  currentRef.current = current;

  const canUndo = currentIndex > 0;
  const canRedo = currentIndex < history.length - 1;

  const replace = useCallback((value: ValueSetter<T>) => {
    // use currentRef.current to read the newest value instead of the one from closure
    const newValue = cloneDeep(typeof value === 'function' ? value(currentRef.current) : value);
    setCurrent(newValue);
    return newValue;
  }, []);

  const set = useCallback(
    (value: ValueSetter<T>) => {
      // use currentRef.current to read the newest value instead of the one from closure
      const newValue = cloneDeep(typeof value === 'function' ? value(currentRef.current) : value);
      setCurrent(newValue);

      // clear future items to avoid redo
      let newHistory = history.filter((_, index) => index < currentIndex + 1);
      // keep limited items in history. 0 means no limit.
      const maxHistoryCount = maxUndoCount > 0 ? maxUndoCount + 1 : 0;
      newHistory = [...newHistory, newValue].slice(-maxHistoryCount); // arr.slice(-0) equals arr

      setHistory(newHistory);
      setCurrentIndex(Math.min(currentIndex + 1, newHistory.length - 1));

      return newValue;
    },
    [currentIndex, history, maxUndoCount],
  );

  const undo = useCallback<() => T>(() => {
    let newValue = current;
    if (canUndo) {
      setCurrentIndex(currentIndex - 1);
      newValue = history[currentIndex - 1];
      setCurrent(newValue);
    }
    return cloneDeep(newValue);
  }, [canUndo, current, currentIndex, history]);

  const redo = useCallback<() => T>(() => {
    let newValue = current;
    if (canRedo) {
      setCurrentIndex(currentIndex + 1);
      newValue = history[currentIndex + 1];
      setCurrent(newValue);
    }
    return cloneDeep(newValue);
  }, [canRedo, current, currentIndex, history]);

  const reset = useCallback((initValue: T) => {
    setHistory([initValue]);
    setCurrentIndex(0);
    setCurrent(initValue);
  }, []);

  return {
    current,
    canUndo,
    canRedo,
    set,
    replace,
    undo,
    redo,
    reset,
  };
}

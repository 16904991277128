import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  // index.tsx
  summaryRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    color: theme.palette.greyModern[800],
    paddingRight: theme.spacing(3),
  },
  basicInfo: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoBlock: {
    minWidth: 200,
    height: 130,
    borderRadius: 6,
    backgroundColor: theme.palette.greyModern[50],
    marginRight: theme.spacing(4),
    '&:last-child': {
      marginRight: 0,
    },
  },
  countInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 8),
  },
  countDetail: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(9, 0),
    '& p': {
      color: '#000000',
      fontWeight: 500,
    },
  },
  snapshotDetails: {
    wordWrap: 'break-word',
  },
  countValue: {
    fontSize: 24,
  },
  statsInfo: {
    flex: 1,
    minWidth: 270,
    position: 'relative',
    padding: theme.spacing(7, 3, 3, 3),
  },
  detailTitle: {
    position: 'absolute',
    top: theme.spacing(4),
    fontSize: 14,
    fontWeight: 700,
  },
  summarySection: {
    marginTop: theme.spacing(8),
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  placeholderText: {
    color: theme.palette.greyModern[500],
  },
  // EditDetailDialog.tsx
  dialog: {
    width: 516,
    minHeight: 196,
    padding: theme.spacing(6),
  },
  dialogSection: {
    padding: theme.spacing(0),
  },
  dialogContent: {
    position: 'relative',
  },
  dialogTitleText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.greyModern[900],
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  dialogActions: {
    width: '100%',
    justifyContent: 'end',
  },
  descInputWrapper: {
    alignItems: 'flex-start',
    minHeight: 76,
    borderRadius: 6,
    margin: theme.spacing(6, 0),
  },
  descInput: {
    lineHeight: '20px',
  },
}));
export default useStyles;

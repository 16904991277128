import React, { useState } from 'react';
import { tagColors } from '@clef/client-library';
import { DialogWithColorSelector } from './DialogWithColorSelector';

interface CreateTagDialogProps {
  isLoading: boolean;
  initialTagName?: string;
  initialColor?: string | null;
  onSave: (name: string, color: string) => void;
  onClose: () => void;
}

export const CreateOrUpdateTagDialog: React.FC<CreateTagDialogProps> = ({
  onClose,
  onSave,
  isLoading,
  initialColor,
  initialTagName = '',
}) => {
  const [selectedName, setSelectedName] = useState(initialTagName);
  const [selectedColor, setSelectedColor] = useState(
    initialColor ?? tagColors[Math.floor(Math.random() * tagColors.length)],
  );

  return (
    <DialogWithColorSelector
      title="Create Tag"
      propertyName="Tag"
      selectedName={selectedName}
      setSelectedName={setSelectedName}
      selectedColor={selectedColor}
      setSelectedColor={setSelectedColor}
      defaultColors={tagColors}
      chosenColors={[]}
      isCreating={isLoading}
      disableSaveButton={initialTagName === selectedName}
      onClose={onClose}
      hideColorSelector
      showTextHint={false}
      onSaveButtonClick={() => onSave(selectedName, selectedColor)}
    />
  );
};

import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  highlight: {
    display: 'flex',
    outline: `${theme.palette.blue[400]} solid 4px`,
    borderRadius: 10,
    animation: '$wiggle 3s infinite',
  },
  '@keyframes wiggle': {
    '0%': { transform: 'rotate(0deg);' },
    '20%': { transform: 'rotate(0deg);' },
    '30%': { transform: 'rotate(10deg);' },
    '40%': { transform: 'rotate(-10deg);' },
    '50%': { transform: 'rotate(0deg);' },
    '100%': { transform: 'rotate(0deg);' },
  },
}));

export type HighlightWrapperProps = {
  enabled?: boolean;
  className?: string;
};

export const HighlightWrapper: React.FC<HighlightWrapperProps> = forwardRef(
  ({ enabled, className, children }, _ref) => {
    const styles = useStyles();

    if (!enabled) {
      return <>{children}</>;
    }

    return <div className={cx(styles.highlight, className)}>{children}</div>;
  },
);

export default HighlightWrapper;

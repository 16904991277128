import React, { useEffect } from 'react';
import { Typography, Divider, OutlinedInput, Slider, ButtonBase } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { makeStyles } from '@material-ui/core/styles';
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';
import TonalityIcon from '@material-ui/icons/Tonality';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import HistogramIcon from './histogram_icon.svg';
import AutoContrastIcon from './autocontrast_icon.svg';
import AutoContrastColoredIcon from './autocontrast_icon_colored.svg';
import HistogramColoredIcon from './histogram_icon_colored.svg';
import {
  MAX_BRIGHTNESS,
  MIN_BRIGHTNESS,
  MAX_CONTRAST,
  MIN_CONTRAST,
} from '../../constants/image_enhancer';
import { adjustBrightnessInRange, adjustContrastInRange } from '../../utils';
import { greyScale } from '@clef/client-library';

export interface SettingsPanelProps {
  contrast: number;
  brightness: number;
  histogramEnabled: boolean;
  autoContrastEnabled: boolean;
  setBrightness(value: number): void;
  setContrast(value: number): void;
  toggleHistogram?(): void;
  toggleAutoContrast?(): void;
  resetEnhancers(): void;
  handleClose(): void;
  hideApplyToAll?: boolean;
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  configItem: {
    marginBottom: spacing(2),
    display: 'flex',
    gap: `${spacing(3)}px`,
    alignItems: 'center',
  },
  configLabel: {
    minWidth: 70,
  },
  input: {
    padding: spacing(1),
    textAlign: 'center',
    height: 24,
    width: 34,
  },
  divider: {
    marginBottom: spacing(5),
    marginTop: spacing(5),
  },
  advanceLabel: {
    marginBottom: spacing(4),
  },
  allAction: {
    textAlign: 'right',
  },
  radioSelectRow: {
    padding: spacing(0, 6),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: `${spacing(6)}px`,
  },
  radioSelect: {
    border: `1px solid ${palette.grey[200]}`,
    borderRadius: spacing(1),
    padding: spacing(3, 2),
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: `${spacing(2)}px`,
  },
  radioSelected: {
    border: `1px solid ${palette.primary.main}`,
    color: palette.primary.main,
    backgroundColor: 'rgba(12, 100, 205, 0.1)',
  },
  checkedIcon: {
    position: 'absolute',
    top: spacing(1),
    left: spacing(1),
  },
  imageSizeButton: {
    border: `1px solid ${palette.grey[300]}`,
    color: palette.grey[600],
    borderRadius: 4,
    width: '100%',
    padding: 10,
  },
  imageSizeOption: {
    minWidth: 200,
  },
  imageSizeOptionLabel: {
    minWidth: 90,
  },
  popoverPaper: {
    width: '100%',
    height: '100%',
    maxHeight: 'unset',
    maxWidth: 'unset',
  },
}));

const SettingsPanel: React.FunctionComponent<SettingsPanelProps> = ({
  contrast = 0,
  brightness = 0,
  histogramEnabled = false,
  autoContrastEnabled = false,
  setBrightness,
  setContrast,
  toggleHistogram,
  toggleAutoContrast,
  resetEnhancers,
  handleClose,
  hideApplyToAll,
}) => {
  const classes = useStyles();
  const [brightnessInput, setBrightnessInput] = React.useState<number | string>(brightness);
  const [contrastInput, setContrastInput] = React.useState<number | string>(contrast);

  const setFilterValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setValue: (value: string | number) => void,
    adjustInRange: (value: number) => number,
  ) => {
    const parsedValue = parseInt(e.target?.value);
    const value = !isNaN(parsedValue)
      ? adjustInRange(parsedValue)
      : e.target.value.toString() === '-'
      ? '-'
      : '';
    setValue(value);
  };

  const setBrightnessValue = (inputValue: string) => {
    const value = parseInt(inputValue);
    if (!isNaN(value) && value !== brightness) {
      setBrightness(adjustBrightnessInRange(value));
    }
  };

  const setContrastValue = (inputValue: string) => {
    const value = parseInt(inputValue);
    if (!isNaN(value) && value !== contrast) {
      setContrast(adjustContrastInRange(value));
    }
  };

  const handleBrightnessInputValue = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      setBrightnessValue(e.currentTarget.value);
    }
  };

  const handleContrastInputValue = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      setContrastValue(e.currentTarget.value);
    }
  };

  useEffect(() => {
    setBrightnessInput(brightness);
    setContrastInput(contrast);
  }, [brightness, contrast]);

  return (
    <div>
      <div data-testid="enhancer-modal" className={classes.configItem}>
        <WbSunnyOutlinedIcon fontSize={'small'} htmlColor={greyScale[500]} />
        <Typography className={classes.configLabel} variant="body1" component="div">
          {t('Brightness')}
        </Typography>
        <Slider
          value={Number(brightnessInput)}
          step={1}
          min={MIN_BRIGHTNESS}
          max={MAX_BRIGHTNESS}
          onChange={(_, newValue) => {
            setBrightnessInput(Number(newValue));
          }}
          onChangeCommitted={(_, newValue) => {
            setBrightness(Number(newValue));
            setBrightnessInput(Number(newValue));
          }}
          aria-labelledby="brightness-slider"
        />
        <OutlinedInput
          classes={{ input: classes.input }}
          value={brightnessInput}
          margin="dense"
          onChange={e => {
            setFilterValue(e, setBrightnessInput, adjustBrightnessInRange);
          }}
          onKeyUp={handleBrightnessInputValue}
          onBlur={e => setBrightnessValue(e.target.value)}
          inputProps={{
            'data-testid': 'brightness-input',
            'aria-labelledby': 'brightness-input',
          }}
        />
      </div>
      <div className={classes.configItem}>
        <TonalityIcon fontSize={'small'} htmlColor={greyScale[500]} />
        <Typography className={classes.configLabel} variant="body1" component="div">
          {t('Contrast')}
        </Typography>
        <Slider
          value={Number(contrastInput)}
          step={1}
          min={MIN_CONTRAST}
          max={MAX_CONTRAST}
          onChange={(_, newValue) => {
            setContrastInput(Number(newValue));
          }}
          onChangeCommitted={(_, newValue) => {
            setContrast(Number(newValue));
            setContrastInput(Number(newValue));
          }}
          aria-labelledby="contrast-slider"
        />
        <OutlinedInput
          classes={{ input: classes.input }}
          value={contrastInput}
          margin="dense"
          onChange={e => {
            setFilterValue(e, setContrastInput, adjustContrastInRange);
          }}
          onKeyUp={handleContrastInputValue}
          onBlur={e => setContrastValue(e.target.value)}
          inputProps={{
            'data-testid': 'contrast-input',
            'aria-labelledby': 'contrast-slider',
          }}
        />
      </div>
      <Divider className={classes.divider} />
      <Typography className={classes.advanceLabel} variant="subtitle1" component="div">
        {t('Advanced Enhancement')}
      </Typography>
      <div className={classes.radioSelectRow}>
        <ButtonBase
          className={
            autoContrastEnabled
              ? `${classes.radioSelect} ${classes.radioSelected}`
              : classes.radioSelect
          }
          onClick={() => toggleAutoContrast && toggleAutoContrast()}
        >
          {autoContrastEnabled && (
            <CheckCircleIcon className={classes.checkedIcon} fontSize={'small'} color={'primary'} />
          )}
          <img
            src={autoContrastEnabled ? AutoContrastColoredIcon : AutoContrastIcon}
            width={30}
            height={24}
          />
          <Typography variant="body2" component="div">
            {t('Auto Contrast')}
          </Typography>
        </ButtonBase>
        <ButtonBase
          className={
            histogramEnabled
              ? `${classes.radioSelect} ${classes.radioSelected}`
              : classes.radioSelect
          }
          onClick={() => toggleHistogram && toggleHistogram()}
        >
          {histogramEnabled && (
            <CheckCircleIcon className={classes.checkedIcon} fontSize={'small'} color={'primary'} />
          )}
          <img
            src={histogramEnabled ? HistogramColoredIcon : HistogramIcon}
            width={30}
            height={30}
          />
          <Typography variant="body2" component="div">
            {t('Histogram Equalization')}
          </Typography>
        </ButtonBase>
      </div>
      {!hideApplyToAll && (
        <>
          <Divider className={classes.divider} />
          <div className={classes.allAction}>
            <Button
              id="reset-enhancers-button"
              variant="text"
              color="secondary"
              onClick={() => {
                handleClose();
                resetEnhancers();
              }}
            >
              {t('Reset')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default SettingsPanel;

import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

import { DatasetVersion } from '@clef/shared/types';
import CLEF_PATH from '@/constants/path';
import { Button, IconButton, Typography } from '@clef/client-library';

import {
  useSoftDeleteSnapshotMutation,
  useDatasetExportedWithVersionsQuery,
} from '@/serverStore/dataset';
import { getDateNumber } from '@/utils';

import { useStyles } from './styles';

export interface DeleteDialogProps {
  snapshot: DatasetVersion;
  onClose: () => void;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({ snapshot, onClose }) => {
  const styles = useStyles();
  const { name, id: datasetVersionId } = snapshot;

  const history = useHistory();

  const softDeleteSnapshot = useSoftDeleteSnapshotMutation();

  const { data: datasetExported } = useDatasetExportedWithVersionsQuery({
    withCount: true,
    includeNotCompleted: true,
    includeFastEasy: true,
  });

  const afterDeleteUrl = useMemo(() => {
    const sortedList = datasetExported?.datasetVersions?.sort(
      (a, b) => getDateNumber(b.creationTime) - getDateNumber(a.creationTime),
    );
    if (!sortedList?.length || sortedList.length === 1) {
      return CLEF_PATH.data.dataBrowser;
    }
    const curSnapshotIndex =
      sortedList.findIndex(datasetVersion => datasetVersion.id === datasetVersionId) ?? -1;
    const nextSnapshotVersion =
      curSnapshotIndex === sortedList.length - 1
        ? sortedList[0].version
        : sortedList[curSnapshotIndex + 1]?.version;
    return `${CLEF_PATH.data.datasetSnapshot}/${nextSnapshotVersion}`;
  }, [datasetExported?.datasetVersions, datasetVersionId]);

  const handleDelete = () => {
    softDeleteSnapshot.mutate(datasetVersionId, {
      onSuccess: () => {
        onClose();
        setTimeout(() => {
          history.push(afterDeleteUrl);
        }, 1000);
      },
    });
  };

  return (
    <Dialog open color="primary" PaperProps={{ className: styles.dialog }}>
      <DialogTitle className={styles.dialogSection}>
        <Box className={styles.dialogTitleText}>
          <Box marginRight={2}>{t('Delete')}</Box>
          <Typography variant="h2" maxWidth={300}>
            {name}
          </Typography>
        </Box>
        <IconButton id="delete-dialog-close-btn" onClick={onClose} className={styles.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classNames(styles.dialogSection, styles.dialogContent)}>
        <Box marginTop={2}>
          {t(
            'This action is not reversible. We will {{keep}} all models and evaluation jobs run with this snapshot.',
            { keep: <strong>keep</strong> },
          )}
        </Box>
      </DialogContent>
      <DialogActions className={classNames(styles.dialogSection, styles.dialogActions)}>
        <Button
          id="delete-dialog-cancel-btn"
          color="default"
          onClick={onClose}
          disabled={softDeleteSnapshot.isLoading}
        >
          {t('Cancel')}
        </Button>
        <Button
          id="delete-dialog-confirm-btn"
          className={styles.deleteButton}
          color="secondary"
          variant="contained"
          onClick={handleDelete}
          disabled={softDeleteSnapshot.isLoading}
        >
          {t('Delete snapshot')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;

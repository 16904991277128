import CloseIcon from '@material-ui/icons/Close';
import { Drawer } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import React, { useCallback, useEffect, useState } from 'react';
import { useContext } from 'react';
import { createContext } from 'react';
import { useLabelingDrawerStyles } from './labelingDrawerStyles';
import { DefectBookDrawer } from './drawer/DefectBookDrawer';
import { LabelingInstructionsDrawer } from './drawer/LabelingInstructionsDrawer';
import { HotKeysDrawer } from './drawer/HotKeysDrawer';
import { useKeyPress } from '@clef/client-library';
import { HotKeySection } from '../../hooks/useHotKeyDialog';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { LabelType } from '@clef/shared/types';

export enum DrawerType {
  DefectBook = 'DefectBook',
  LabelingInstructions = 'LabelingInstructions',
  HotKeys = 'HotKeys',
}

type LabelingDrawerContextProps = {
  drawerOpened: boolean;
  closeDrawer: () => void;
  toggleDefectBookDrawer: (defectId: number) => void;
  toggleLabelingInstructionsDrawer: () => void;
  toggleHotKeysDrawer: () => void;
  /**
   * Private api, should not call from outside
   */
  _setHotKeySections: (sections: HotKeySection[]) => void;
};

const noContextWarning = () => {
  throw Error('<LabelingDrawerContext.Provider /> not found!');
};

export const LabelingDrawerContext = createContext<LabelingDrawerContextProps>({
  drawerOpened: false,
  closeDrawer: () => noContextWarning(),
  toggleDefectBookDrawer: () => noContextWarning(),
  toggleLabelingInstructionsDrawer: () => noContextWarning(),
  toggleHotKeysDrawer: () => noContextWarning(),
  _setHotKeySections: () => noContextWarning(),
});

export type LabelingDrawerProps = {
  hotKeySections?: HotKeySection[];
};
export const useLabelingDrawer = (
  options: LabelingDrawerProps = {},
): LabelingDrawerContextProps => {
  const context = useContext(LabelingDrawerContext);
  useEffect(() => {
    // Note: this should be changed to push stack and pop stack
    options.hotKeySections && context._setHotKeySections(options.hotKeySections);
    return () => options.hotKeySections && context._setHotKeySections([]);
    // intentionally not adding context, because _setHotKeySections will not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.hotKeySections]);
  return context;
};

// Note: will update this drawer to a more generic component
export const LabelingDrawerContextProvider: React.FC = ({ children }) => {
  const styles = useLabelingDrawerStyles();
  const [drawerType, setDrawerType] = useState<DrawerType | undefined>(undefined);
  const [defectId, setDefect] = useState<number | undefined>();
  // Note: should change to a stack of array instead of a single array
  const [hotKeySections, setHotKeySections] = useState<HotKeySection[]>([]);

  const toggleDefectBookDrawer = useCallback(
    (newDefectId: number) => {
      if (drawerType === DrawerType.DefectBook && newDefectId === defectId) {
        setDrawerType(undefined);
      } else {
        setDefect(newDefectId);
        setDrawerType(DrawerType.DefectBook);
      }
    },
    [defectId, drawerType],
  );

  const toggleLabelingInstructionsDrawer = useCallback(() => {
    setDrawerType(prev =>
      prev === DrawerType.LabelingInstructions ? undefined : DrawerType.LabelingInstructions,
    );
  }, []);

  const toggleHotKeysDrawer = useCallback(() => {
    if (hotKeySections.length === 0) {
      throw Error('Hot key sections not defined! Specify hotKeySections for useLabelingDrawer');
    }
    setDrawerType(prev => (prev === DrawerType.HotKeys ? undefined : DrawerType.HotKeys));
  }, [hotKeySections.length]);

  const closeDrawer = useCallback(() => {
    if (drawerType) {
      setDrawerType(undefined);
    }
  }, [drawerType]);

  // `useKeyPress('esc', xxx)` has lower priority than MediaGrid.tsx, need to listen to '*' for workaround
  useKeyPress('*', e => {
    if (e.key === 'Escape' && drawerType) {
      e.stopPropagation();
      e.preventDefault();
      closeDrawer();
    }
  });
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const labelType = selectedProject?.labelType;

  return (
    <LabelingDrawerContext.Provider
      value={{
        drawerOpened: drawerType !== undefined,
        closeDrawer,
        toggleDefectBookDrawer,
        toggleLabelingInstructionsDrawer,
        toggleHotKeysDrawer,
        _setHotKeySections: setHotKeySections,
      }}
    >
      <Drawer
        anchor="left"
        aria-label="labeling drawer"
        open={!!drawerType}
        variant="persistent"
        classes={{
          paper:
            labelType === LabelType.SegmentationInstantLearning
              ? styles.instantLearningDrawer
              : styles.drawer,
        }}
        PaperProps={{ square: true }}
      >
        <div className={styles.drawerContent} aria-label="drawer content">
          {drawerType === DrawerType.DefectBook && <DefectBookDrawer defectId={defectId!} />}
          {drawerType === DrawerType.LabelingInstructions && <LabelingInstructionsDrawer />}
          {drawerType === DrawerType.HotKeys && <HotKeysDrawer hotKeySections={hotKeySections} />}
        </div>
        <IconButton
          id="close-labeling-drawer"
          className={styles.closeButton}
          onClick={() => setDrawerType(undefined)}
          aria-label="close"
        >
          <CloseIcon className={styles.closeIcon} />
        </IconButton>
      </Drawer>
      {children}
    </LabelingDrawerContext.Provider>
  );
};

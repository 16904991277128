import React, { useMemo, useCallback } from 'react';
import cx from 'classnames';
import { Annotation, LabelType, Media, MediaStatusType } from '@clef/shared/types';
import { useDatasetMediaDetailsQuery } from '@/serverStore/dataset';
import {
  useGetProjectVersionedDefectsQuery,
  useGetSelectedProjectQuery,
} from '@/serverStore/projects';
import { MediaViewer, BoxAnnotation, SegmentationAnnotation } from '@clef/client-library';
import { FilterOptions } from '@/api/model_analysis_api';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import { Box, Grid, makeStyles } from '@material-ui/core';
import {
  useCurrentDefectsWithArchivedAndIndexFromVersion,
  useDefectSelectorWithArchived,
} from '../../../store/defectState/actions';
import { getBoxAnnotations, getSegmentAnnotations } from '../../../utils';
import { useOffscreenCanvasToDataUrl, useSegmentationPredictionDataUrl } from '../utils';
import MediaClassifiedClass from './MediaClassifiedClass';
import NewMediaLevelLabelIcon from './NewMediaLevelLabelIcon';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import { useDataBrowserState } from '../dataBrowserState';
import MediaPredictionPerformanceScore from './MediaPredictionPerformanceScore';
import { getThumbnail } from '@clef/client-library/';
import { ColorMap, useHeatmapCanvas } from '../../error_analysis/components/utils';
import { isModelTrainingSuccessful } from '../../../store/projectModelInfoState/utils';
import MediaClassifiedClassWithClassification from './MediaClassifiedClassWithClassification';
import classNames from 'classnames';

import { HighlightWrapper } from '../../../components/WorkflowAssistant/HighlightWrapper';
import { PulseWrapper } from '@clef/client-library';
import { useModelStatusQuery } from '@/serverStore/projectModels';
import { Theme } from '@material-ui/core';
import { thresholdForPredictAtom } from '@/uiStates/projectModels/pageUIStates';
import { useAtom } from 'jotai';

const MEDIA_CONTAINER_SPACING = 6;

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    position: 'relative',
    width: `calc(100% - ${MEDIA_CONTAINER_SPACING}px)`,
    height: `calc(100% - ${MEDIA_CONTAINER_SPACING}px)`,
    overflow: 'hidden',
  },
  noSpacing: {
    width: '100%!important',
    height: '100%!important',
  },
  highlightedContainer: {
    width: '100% !important',
    height: '100% !important',
  },
  highlightWrapper: {
    width: `calc(100% - ${MEDIA_CONTAINER_SPACING}px)`,
    height: `calc(100% - ${MEDIA_CONTAINER_SPACING}px)`,
  },
  pulsedWrapper: {
    width: `calc(100% - ${MEDIA_CONTAINER_SPACING}px)`,
    height: `calc(100% - ${MEDIA_CONTAINER_SPACING}px)`,
  },
  imageViewHitFilter: {
    opacity: 0.7,
  },
  hoverClickContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    zIndex: 105,

    '&:hover': {
      background: 'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 60px)',
      borderColor: theme.palette.grey[400],
      '& $infoIcon': {
        opacity: 1,
      },
      '& $checkIcon': {
        opacity: 1,
      },
    },
    transition: 'all 0.135s cubic-bezier(0.0,0.0,0.2,1)',
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 8,
    overflow: 'hidden',
  },
  disableSelectionOnItem: {
    cursor: 'default',

    '&:hover': {
      background: 'none',
      borderColor: 'transparent',
    },
  },
  hoverIconContainerSelected: {
    borderColor: theme.palette.primary.main,
    '& $checkIcon': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
  },
  checkIcon: {
    opacity: 0,
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
    color: 'rgba(255,255,255, 0.7)',
  },
  infoIcon: {
    opacity: 0,
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
    color: 'rgba(255,255,255, 0.7)',
    '&:hover': {
      cursor: 'pointer',
      color: 'rgba(255,255,255, 1)',
    },
  },
  tagLeftCorner: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    maxWidth: 'calc(100% - 130px)', // leave room for right tag
  },
  mediaClassififiedClassContainer: {
    '& > *': {
      cursor: 'pointer!important',
    },
  },
  classification: {
    width: '100%',
  },
}));

interface MediaContainerProps {
  media: Media;
  versionId?: number;
  modelId?: string;
  threshold?: number;
  /**
   * @deprecated use showGroundTruth and showPredictions instead
   */
  labelDisplay?: 'ground_truth' | 'predictions';
  showGroundTruth?: boolean;
  showPredictions?: boolean;
  showHeatmap?: boolean;
  enableZoom?: boolean;
  hideDefectName?: boolean;
  maskHitFilterUrl?: string;
  boxAnnotations?: BoxAnnotation[];
  onSelected?: (selected: boolean) => void;
  selected?: boolean;
  onInfoClick?: () => void;
  hasSelectedMedia?: boolean;
  thumbnailSize?: 'medium' | 'large';
  showClassChip?: boolean;
  disableSelection?: boolean;
  disableSetClass?: boolean;
  disableMediaStatusCheckForLabelDisplay?: boolean;
  segmentationOpacity?: number;
  enableGrayscaleImage?: boolean;
  filterOptions?: FilterOptions;
  highlighted?: boolean;
  hinted?: boolean;
  noSpacing?: boolean;
  classes?: {
    mediaViewerRoot?: string;
    filteredMaskImage?: string;
  };
}

const MediaContainer: React.FC<MediaContainerProps> = ({
  media,
  modelId,
  threshold: thresholdProps,
  versionId,
  labelDisplay,
  showGroundTruth: showGroundTruthProps,
  showPredictions: showPredictionsProps,
  maskHitFilterUrl,
  showHeatmap: showHeatmapProps,
  selected,
  onSelected,
  onInfoClick,
  enableZoom,
  hasSelectedMedia = false,
  hideDefectName = true,
  thumbnailSize = 'medium',
  showClassChip = true,
  disableSelection = false,
  disableSetClass = false,
  segmentationOpacity = 0.3,
  filterOptions,
  boxAnnotations: boxAnnotationsProps,
  enableGrayscaleImage = false,
  children,
  highlighted = false,
  hinted = false,
  noSpacing = false,
  classes,
}) => {
  const styles = useStyles();
  const { id, properties } = media;
  const { id: projectId, datasetId, labelType } = useGetSelectedProjectQuery().data ?? {};

  const { data: versionedDefects } = useGetProjectVersionedDefectsQuery(versionId);
  const datasetDefects = useDefectSelectorWithArchived();

  const defects = versionId ? versionedDefects : datasetDefects;

  const {
    id: currentModelId,
    confidence,
    versionedDatasetContentId,
  } = useCurrentProjectModelInfoQuery();
  const allPredictionVersionedDefectsWithArchiveInfo =
    useCurrentDefectsWithArchivedAndIndexFromVersion(versionedDatasetContentId);

  const { data: modelStatusRes } = useModelStatusQuery(projectId, modelId ?? currentModelId);
  const { status: modelStatus, metricsReady } = modelStatusRes ?? {};
  const { data: mediaDetails } = useDatasetMediaDetailsQuery(
    {
      datasetId,
      mediaId: id,
      modelId: modelId ?? currentModelId,
      ...(versionId && { versionId }),
    },
    true,
    true,
  );
  const isApprovedMedia = mediaDetails?.mediaStatus === MediaStatusType.Approved;
  const { state } = useDataBrowserState();
  const { sortField, showHeatmap } = state;
  const annotations = (mediaDetails?.label?.annotations as Annotation[]) ?? [];
  const boxGroundTruthAnnotations =
    isApprovedMedia && defects ? getBoxAnnotations(annotations, defects) : undefined;
  const segmentationGroundTruthAnnotations =
    isApprovedMedia && defects ? getSegmentAnnotations(annotations, defects) : undefined;

  const [thresholdForPredict] = useAtom(thresholdForPredictAtom);
  const confidenceThreshold = thresholdProps ?? thresholdForPredict ?? confidence ?? 0;

  const predictionAnnotations = (mediaDetails?.predictionLabel?.annotations as Annotation[]) ?? [];
  const boxPredictionAnnotations = defects
    ? getBoxAnnotations(predictionAnnotations, defects, confidenceThreshold)
    : undefined;

  const [dataUrl] = useSegmentationPredictionDataUrl(
    mediaDetails?.predictionLabel?.segImgPath || '',
    confidenceThreshold,
    allPredictionVersionedDefectsWithArchiveInfo, // defects
    segmentationOpacity,
    undefined, // areaMaskSrc
    undefined, // defectIdToAreaThreshold
    filterOptions,
  );
  const imageSrc = getThumbnail(mediaDetails, thumbnailSize);

  const heatmapCanvas = useHeatmapCanvas({
    imageSrc: mediaDetails?.predictionLabel?.heatmapPath ? imageSrc ?? '' : '',
    attentionHeatmapSrc: mediaDetails?.predictionLabel?.heatmapPath || '',
    colorMap: ColorMap.inferno,
    confidenceThreshold: [LabelType.Classification, LabelType.AnomalyDetection].includes(labelType!)
      ? 0
      : confidenceThreshold,
  });
  const heatmapUrl = useOffscreenCanvasToDataUrl(heatmapCanvas);

  const showGroundTruth = (labelDisplay && labelDisplay === 'ground_truth') || showGroundTruthProps;
  const showPredictions = (labelDisplay && labelDisplay === 'predictions') || showPredictionsProps;
  let boxAnnotations: BoxAnnotation[] = [];
  let segmentationAnnotations: (SegmentationAnnotation | string)[] | null = [];
  // TODO: remove labelDisplay check when new data browser launched
  if (labelDisplay) {
    if (labelDisplay === 'ground_truth') {
      boxAnnotations = boxGroundTruthAnnotations ?? [];
      segmentationAnnotations = segmentationGroundTruthAnnotations ?? [];
    } else {
      boxAnnotations = boxPredictionAnnotations ?? [];
      segmentationAnnotations = dataUrl ? [dataUrl] : [];
    }
  } else {
    boxAnnotations = [
      ...(showGroundTruth ? boxGroundTruthAnnotations ?? [] : []),
      ...(showPredictions ? boxPredictionAnnotations ?? [] : []),
    ];
    segmentationAnnotations = [
      ...(showGroundTruth ? segmentationGroundTruthAnnotations ?? [] : []),
      ...(showPredictions && dataUrl ? [dataUrl] : []),
    ];
  }
  if (labelType === LabelType.BoundingBox && !!boxAnnotationsProps) {
    boxAnnotations = boxAnnotationsProps;
  }

  const projectLabelTypes = [LabelType.AnomalyDetection];

  const renderMediaClassifiedClassWithClassification = () => {
    if (versionId && !showPredictions && !mediaDetails?.label) return null;
    if (versionId && showPredictions && !mediaDetails?.predictionLabel) return null;
    return (
      <MediaClassifiedClassWithClassification
        media={media}
        versionId={versionId}
        mediaDetails={mediaDetails}
        isPrediction={showPredictions}
        enableSetClass={!disableSetClass}
        enableColor={false}
        showGroundTruth={showGroundTruth}
      />
    );
  };

  const showHeatmapToogle = useMemo(() => {
    if (showHeatmapProps !== undefined) {
      return showHeatmapProps;
    }
    return showHeatmap;
  }, [showHeatmapProps, showHeatmap]);

  const renderSegmentationComponentWithFilter = useCallback(
    (actualImageWidth: number, actualImageHeight: number) => {
      if (!maskHitFilterUrl) return null;
      return (
        <img
          className={cx(styles.imageViewHitFilter, classes?.filteredMaskImage)}
          data-testid="image-view-hit-filter"
          src={maskHitFilterUrl}
          key="image-view-hit-filter"
          width={actualImageWidth}
          height={actualImageHeight}
          style={{ position: 'absolute', left: 0, top: 0 }}
        />
      );
    },
    [maskHitFilterUrl, styles.imageViewHitFilter, classes?.filteredMaskImage],
  );

  return (
    <HighlightWrapper
      enabled={highlighted}
      className={classNames(styles.highlightWrapper, noSpacing && styles.noSpacing)}
    >
      <PulseWrapper
        enabled={hinted}
        fillContainer
        className={cx(
          styles.pulsedWrapper,
          highlighted && styles.highlightedContainer,
          noSpacing && styles.noSpacing,
        )}
      >
        <div
          className={cx(
            styles.container,
            (highlighted || hinted) && styles.highlightedContainer,
            noSpacing && styles.noSpacing,
          )}
        >
          {/* Hover indicator, info button, and onClick handler */}
          <div
            className={cx(
              styles.hoverClickContainer,
              disableSelection && styles.disableSelectionOnItem,
              selected && styles.hoverIconContainerSelected,
            )}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (hasSelectedMedia) {
                onSelected?.(!selected);
              } else {
                onInfoClick && onInfoClick();
              }
            }}
            data-testid={'media-container-hover'}
            title={media.name}
          >
            {!disableSelection && onSelected && (
              <CheckCircleIcon
                data-testid="select-media-grid-item"
                className={cx(styles.checkIcon)}
                onClick={event => {
                  event.stopPropagation();
                  event.nativeEvent.stopImmediatePropagation();
                  onSelected?.(!selected);
                }}
              />
            )}
            {onInfoClick && selected && (
              <InfoIcon
                className={cx(styles.infoIcon)}
                onClick={e => {
                  e.stopPropagation();
                  onInfoClick();
                }}
                data-testid="media-container-info-icon"
              />
            )}
          </div>
          {/* Media viewer */}
          <MediaViewer
            selected={selected}
            imgSrc={imageSrc}
            enableZoom={enableZoom}
            fallbackImgSrc={mediaDetails?.url}
            properties={properties}
            classificationGradcamUrl={showHeatmapToogle ? heatmapUrl || undefined : undefined}
            classifiedClass={
              <Box
                className={classNames(styles.mediaClassififiedClassContainer, {
                  [styles.classification]: labelType === LabelType.Classification,
                })}
                display="flex"
                alignItems="center"
              >
                {[LabelType.BoundingBox, LabelType.Segmentation].includes(labelType!) && (
                  <Box style={{ flex: 'auto', width: 'auto' }} marginRight={2}>
                    {state.viewMode === 'image' && (
                      <NewMediaLevelLabelIcon
                        mediaDetails={mediaDetails}
                        showGroundTruth={showGroundTruthProps}
                        showPredictions={showPredictionsProps}
                      />
                    )}
                  </Box>
                )}
                {projectLabelTypes.includes(labelType!) &&
                  (labelDisplay ? (
                    <MediaClassifiedClass
                      media={media}
                      mediaDetails={mediaDetails}
                      isPrediction={labelDisplay === 'predictions'}
                      enableSetClass
                      enableColor={labelType === LabelType.AnomalyDetection}
                    />
                  ) : (
                    <Grid container spacing={4}>
                      <Grid item>
                        <MediaClassifiedClass
                          media={media}
                          mediaDetails={mediaDetails}
                          enableSetClass
                          enableColor={labelType === LabelType.AnomalyDetection}
                        />
                      </Grid>
                      {(modelId ?? currentModelId) &&
                        isModelTrainingSuccessful(modelStatus, metricsReady) && (
                          <Grid item>
                            <MediaClassifiedClass
                              media={media}
                              mediaDetails={mediaDetails}
                              isPrediction
                              enableSetClass
                              enableColor={labelType === LabelType.AnomalyDetection}
                            />
                          </Grid>
                        )}
                    </Grid>
                  ))}
                {[LabelType.Classification].includes(labelType!) && (
                  <NewMediaLevelLabelIcon
                    mediaDetails={mediaDetails}
                    showGroundTruth={showGroundTruthProps}
                    showPredictions={showPredictionsProps}
                  />
                )}
              </Box>
            }
            leftCornerComponent={
              labelType !== LabelType.SegmentationInstantLearning && showClassChip ? (
                <>
                  {labelType === LabelType.Classification &&
                    renderMediaClassifiedClassWithClassification()}
                  {sortField.sortType === 'prediction' ? (
                    <MediaPredictionPerformanceScore mediaDetails={mediaDetails} />
                  ) : null}
                </>
              ) : null
            }
            boxAnnotations={boxAnnotations}
            segmentationAnnotations={segmentationAnnotations}
            hideDefectName={hideDefectName}
            enableGrayscaleImage={enableGrayscaleImage}
            renderSegmentationComponentWithFilter={
              maskHitFilterUrl ? renderSegmentationComponentWithFilter : undefined
            }
            classes={{ root: classes?.mediaViewerRoot }}
          />
          {children}
        </div>
      </PulseWrapper>
    </HighlightWrapper>
  );
};

export default MediaContainer;

import { QueryClient } from '@tanstack/react-query';

/*
 * react-query has some default settings which may not be expected if has no context
 * Stale queries are refetched automatically in the background when:
 *    - New instances of the query mount
 *    - The window is refocused
 *    - The network is reconnected
 *    - The query is optionally configured with a refetch interval
 * Reference: https://tanstack.com/query/latest/docs/react/guides/important-defaults?from=reactQueryV3&original=https%3A%2F%2Ftanstack.com%2Fquery%2Fv3%2Fdocs%2Fguides%2Fimportant-defaults
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 600000,
    },
  },
});

import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { LabelType } from '@clef/shared/types';
import ClassificationUpload from './ClassificationUpload';
import AnomalyDetectionUpload from './AnomalyDetectionUpload';
import { useWindowEventListener } from '@clef/client-library';
import { UploadStage, UploadMediaType } from '../../../store/uploadState/types';
import { switchMediaUploadType } from '../../../store/uploadState';
import SegmentationUploadDialog from './SegmentationUpload';
import ObjectDetectionUploadDialog from './ObjectDetectionUpload';
import ErrorPage from '../../../pages/error/ErrorPage';
import InstantLearningUpload from './InstantLearningUpload';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';

export interface UploadFullscreenProps {
  open: boolean;
  onClose(): void;
  initialUseWebcam?: boolean;
}

const UploadFullscreen: React.FC<UploadFullscreenProps> = props => {
  const { open, onClose, initialUseWebcam } = props;
  const dispatch = useDispatch();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { uploadStage } = useTypedSelector(state => state.uploadState);
  const windowConfirmation = useCallback(
    (event: WindowEventMap['beforeunload']) => {
      if (uploadStage === UploadStage.UploadInProgress) {
        event.returnValue = 'You are still uploading images, are you sure you want to leave?';
      }
    },
    [uploadStage],
  );
  useWindowEventListener('beforeunload', windowConfirmation);

  const labelType = selectedProject?.labelType;

  useEffect(() => {
    if (open) {
      if (labelType === LabelType.Segmentation && uploadStage === UploadStage.NotStarted) {
        dispatch(switchMediaUploadType(UploadMediaType.SegmentationUnlabeledMedia));
      }
    }
  }, [dispatch, labelType, uploadStage, open]);

  if (!selectedProject) return null;

  if (labelType === LabelType.Classification) {
    return (
      <ClassificationUpload open={open} onClose={onClose} initialUseWebcam={initialUseWebcam} />
    );
  }
  if (labelType === LabelType.AnomalyDetection) {
    return (
      <AnomalyDetectionUpload open={open} onClose={onClose} initialUseWebcam={initialUseWebcam} />
    );
  }

  if (labelType === LabelType.Segmentation) {
    return (
      <SegmentationUploadDialog open={open} onClose={onClose} initialUseWebcam={initialUseWebcam} />
    );
  }

  if (!labelType || labelType === LabelType.BoundingBox) {
    return (
      <ObjectDetectionUploadDialog
        open={open}
        onClose={onClose}
        initialUseWebcam={initialUseWebcam}
      />
    );
  }

  if (labelType === LabelType.SegmentationInstantLearning) {
    return (
      <InstantLearningUpload open={open} onClose={onClose} initialUseWebcam={initialUseWebcam} />
    );
  }

  return <ErrorPage message="no upload dialog found" />;
};

export default UploadFullscreen;

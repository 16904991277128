export default {
  '- Select Class -': '- 不良を選択 -',
  '(Dataset, split)': '（データセット、スプリット）',
  '[Approved media] Data split': '［承認した画像］データスプリット',
  '[Approved media] Defect count': '［承認した画像］不良数',
  '[Approved media] Defect per split distribution': '［承認した画像］スプリット分布毎の不良',
  '* Also include': '*も含む',
  '*No description about this defect yet.*': '*この不良についての説明はありません。*',
  '*Please add context about this project*': '*このプロジェクトの背景を追加してください。*',
  '# Assignees': '#任命者',
  '# Defects detected': '#不良を特定',
  '# Distinctive label required': '#特定のラベルが必要',
  '# Images': '#画像',
  '# Media Predicted': '#予測画像',
  '# medias': '#画像',
  '#Media': '#画像',
  '• Check the data split chart to make sure it looks good before you export dataset':
    '・データスプリットチャートをチェックして良いことを確認してからデータセットをエクスポートしてください。',
  '• Use Metadata "Split" to help you split your approved medias into train/dev/test':
    '・メタデータ"Split"を使って承認された画像をtrain/dev/testにスプリットしてください。',
  '+ Add another member': '+メンバーを追加',
  '+ Add metadata': '+メタデータを追加',
  '+ ADD TO ANOTHER PROJECT': '+他のプロジェクトに追加',
  '+ Upload media': '+画像をアップロード',
  '1. Go to': '1. 行く',
  '1. There are too few media and defects.': '1. 画像と不良が少なすぎます。',
  '1st folder = Parent folder / 2nd tier sub-folder = Defect names':
    '1番目のフォルダ＝親フォルダ/2番目のサブフォルダ=不良名',
  '2-3 seconds to process.': 'プロセスに2~3秒かかります。',
  '2. Select media you want labelled': '２．ラベルを貼りたい画像を選択します。',
  '2. You have set extremely different split for each defect.':
    '２．各不良に全然違うスプリットを設定しました。',
  '3. Create labeling task and assign labelers':
    '３．ラベリングタスクを作成しラベラーを割当てします。',
  '4. Once labels are submitted, you can approve or reject them':
    '４．ラベルが送信されたら、承認するか拒否できます。',
  Accept: '許可',
  'accept and go to next media': '許可して次の画像に進む',
  'Acceptable Inputs': '許可された入力',
  accepted: '許可された',
  'Accepted:': '許可された：',
  Action: 'アクション',
  Actions: 'アクション',
  'Active members:': 'アクティブなメンバー：',
  'Active models': 'アクティブなモデル',
  'Add A New Field': '新しいフィールドの追加',
  'Add media': '画像の追加',
  'Add images from data browser': 'データブラウザから画像を追加',
  'Add images from local': 'ローカルから画像を追加',
  'Add image to Label Book': '不良ブックに画像を追加',
  'Add media to:': '画像を：に追加',
  'Add members': 'メンバーの追加',
  'Add Members to': 'メンバーを～に追加',
  'Add Members to The Platform': 'プラットフォームにメンバーを追加',
  'Add metadata': 'メタデータの追加',
  'Add to comparison': '比較に追加',
  'Add to data browser': 'データブラウザに追加',
  'Add to deployment': '配置に追加',
  'Add To Project': 'プロジェクトに追加',
  'Add/Edit Metadata Field': 'メタデータフィールドの追加/編集',
  'Adding members...': 'メンバーを追加しています・・・',
  'Adding...': '追加中・・・',
  'Adjust confidence threshold': '信頼できる閾値の調整',
  'Adjust value for all defect types together': '全ての不良種類の値を一気に調整',
  'Advanced Enhancement': '増大を進める',
  Agreement: '一致',
  'Agreement score': '一致スコア',
  'Agreement Threshold': '一致の閾値',
  Alarm: 'アラーム',
  'All completed': '全完了',
  'All media in this task has been labeled and submitted successfully.':
    'このタスクの画像は全てラベルされ、正しく送信されました。',
  "All medias are pending labeling, pending review or deleted. Can't create tasks for these medias, please finish/cancel labeling tasks and review labels first.":
    '画像は全て、ラベリング途中、確認途中、もしくは削除されました。これらの画像のタスクを作成できません、ラベリングタスクを完了/キャンセルしてレベルをまず初めに確認してください。',
  'All selected media already uploaded to data browser':
    '選択された画像は全てデータブラウザーにアップロードされました。',
  'Allow Multiple': '複数許可',
  'Allow Multiple Selection': '複数選択を許可',
  'Allowed image type:': '許可した画像タイプ：',
  'Allowed image type: JPEG, PNG and BMP': '許可された画像タイプ：JPEG, PGNGとBMP',
  'Analyzing...': '分析中・・・',
  and: 'そして',
  'Anyone can view': '誰でも閲覧可能',
  API: 'API',
  'API documentation': 'API資料',
  'API key': 'APIキー',
  'API keys': '複数のAPI キー',
  'API Secret': 'APIシークレットキー',
  Apply: '適用',
  'Apply Metadata Filters to Objects': 'メタデータフィルタをオブジェクトに適用',
  'Apply To All Media': '全ての画像に適用',
  Approved: '承認された',
  'approved media': '承認された画像',
  'Are you sure you want delete this device?': 'このデバイスを削除しても良いですか？',
  'Are you sure you want to cancel the upload of': 'のアップロードをキャンセルしても良いですか？',
  'Are you sure you want to delete this example': 'このサンプルを削除しても良いですか？',
  'Are you sure you want to discard all your changes to the defect?':
    '不良に関する全ての変更を破棄しても良いですか？',
  'Are you sure you want to remove': '取り除いても良いですか？',
  'aria-controls': 'aria-制御',
  ArrowDown: '下矢印',
  ArrowLeft: '左矢印',
  ArrowRight: '右矢印',
  ArrowUp: '上矢印',
  Assign: '割当てる',
  'Assign labelers': 'ラベラーを割り当てる',
  Assigned: '割当てられました',
  'Assigned Time': '割当て時間',
  Assignees: '割当者',
  'Auto Split': '自動スプリット',
  'Auto split only supports assigning images to predefined {{predefinedSet}} sets. If you want to use your customized {{customizedSet}}, set for training, please assign media manually.':
    '自動スプリットは事前に定義された{{predefinedSet}}セットに画像に割当てることだけをサポートします。もしカスタマイズした {{customizedSet}}セットをトレーニングの為に使いたい場合は、画像を手動で割当ててください。',
  'Auto-reject media with low agreement score':
    '低い合意スコアの画像は自動リジェクトしてください。',
  'Available seats:': '利用可能なシート：',
  'average deviation from target:': 'ターゲットから外れた平均',
  'Avg confidence': '信頼性の平均',
  'Avg defective rate': '不良率の平均',
  Back: '戻る',
  'Back to card view': 'カードビューに戻る',
  'Back to Home': 'ホーム画面に戻る',
  'Based on how your target defect split above, the final result of how media will be in each split':
    'どのようにしてターゲット不良がスプリットされるかによって、最終結果で画像がどの様にスプリットの中に表示されるかが決まる',
  Baseline: 'ベースライン',
  'Baseline and Candidate models were evaluated on different datasets or split metadata.':
    'ベースラインと候補モデルが違うデータセットかスプリットメタデータで評価されました。',
  'Baseline and candidate test sets share': 'ベースラインと候補のテストセットの共有',
  'Baseline and candidate test sets share {{mediaTotal}} media, On those {{mediaTotal}} media, ground truth has {{groundTruthCountTotal}} box(es), baseline model predicts {{baselineCountTotal}} box(es), and candidate model predicts {{candidateCountTotal}} box(es)':
    'ベースラインと候補テストセットが画像{{mediaTotal}}をシェアし、それらの{{mediaTotal}} 画像、自分のラベルには {{groundTruthCountTotal}} ボックス、ベースラインモデル予測 {{baselineCountTotal}} ボックス、と候補モデル予測 {{candidateCountTotal}} ボックスがあります。',
  'Baseline and candidate test sets share {{mediaTotal}} media, On those {{mediaTotal}} media, ground truth has {{groundTruthCountTotal}} defect(s), baseline model predicts {{baselineCountTotal}} defect(s), and candidate model predicts {{candidateCountTotal}} defect(s)':
    'ベースラインと候補テストセットが {{mediaTotal}} 画像をシェアし、それらの{{mediaTotal}} 画像で、 自分のラベルには {{groundTruthCountTotal}} 不良、ベースラインモデル予測 {{baselineCountTotal}} 不良、と候補モデル予測{{candidateCountTotal}} 不良があります。',
  'Best model': 'ベストモデル',
  boolean: 'ブール',
  'Bounding Box': '境界ボックス',
  Brightness: '明るさ',
  'By default, all {{approvedText}} with {{noSplitAssignedText}} images will be selected for auto splitting.':
    'デフォルトで、全ての {{approvedText}} と一緒に {{noSplitAssignedText}} 画像が選択されて自動スプリットされます。',
  'Calculating labeling agreement among multiple labelers helps:':
    '複数ラベラーの一致ラベリング計算は：を助ける',
  Cam: 'カム',
  'Can view by anyone': '誰でも閲覧可能',
  Cancel: 'キャンセル',
  'Cancel Data Upload': 'データのアップロードをキャンセル',
  Candidate: '候補者',
  'Chaff Elevator': 'チャフエレベーター',
  'Chaff Hopper': 'チャフホッパー',
  'Change Password': 'パスワードの変更',
  'Choose device to monitor': 'デバイスを選択して監視する',
  'Clarify and evaluate your defect definitions': '不良の定義を明らかにして評価する',
  'Class and architecture': 'クラスと構造',
  Clear: 'クリア',
  'Clear all': '全てクリア',
  'Clear filters': 'フィルターをクリア',
  'Clear Labels': 'ラベルをクリア',
  'Clear selection': '選択をクリア',
  CLICK: 'クリック',
  Clone: 'クローン',
  'Clone from': 'からクローン',
  'Clone project': 'クローンプロジェクト',
  Close: 'クローズ',
  Color: '色',
  'Coming soon...': '近日公開・・・',
  Compare: '比較',
  'Confidence score': '信頼度スコア',
  'Confidence threshold': '信頼度の閾値',
  'Confirm Deletion': '削除を確認する',
  ConnectionLost: '接続が切れる',
  'Constrained by how defects are distributed across media, the final split per defect percentage might be deviated from your target.':
    '複数画像の中でどのように不良が出ているかの不自然さにより、不良パーセンテージ毎の最終スプリットが目標よりも乖離する可能性があります。',
  Context: '背景',
  Continue: '次へ',
  Contrast: 'コントラスト',
  'Copy S3 path': 'S3パスをコピー',
  'Copy to clipboard': 'クリップボードにコピー',
  Correct: '修正',
  'Correct Classifications': '分類を修正',
  Count: 'カウント',
  'Count baseline': 'ベースラインをカウント',
  'Count candidate': '候補をカウント',
  'Count difference': '違いをカウント',
  Create: '作成',
  'Create defect': '不良の作成',
  'Create evaluation job': '評価ジョブの作成',
  'Create First Project': '最初のプロジェクトを作成',
  'Create job': 'ジョブの作成',
  'Create labeling task': 'ラベリングタスクの作成',
  'Create new': '新規作成',
  'Create new consensus task': '新規コンセンサスタスクの作成',
  'Create New Class': '新しい不良の作成',
  'Create new labeling task': '新規ラベリングタスクの作成',
  'Create New Project': '新規プロジェクトの作成',
  'Create new single user task': '新規シングルユーザータスクの作成',
  'Create Pipeline': 'パイプラインの作成',
  'Create single user task': 'シングルユーザータスクの作成',
  'Create task': 'タスクの作成',
  'Create time': '時間の作成',
  'Create your account': 'アカウントの作成',
  'Create your defect book': '不良ブックの作成',
  created: '作成済',
  'Created at': 'ここで作成済',
  'Created On': 'ここに作成済',
  'Creating Defects and Categories...': '不良とカテゴリを作成中・・・',
  'Creating evaluation job': '評価ジョブを作成中',
  'Creating job': 'ジョブを作成中',
  'Creation Time': '作成時間',
  'Creation time - newest first': '作成時間 - 最新から',
  'Creation time - oldest first': '作成時間 - 古いものから',
  Creator: '作成者',
  'CSV file must contains S3 paths to the medias with prefix':
    'CSVファイルはプレフィックスのある画像にS3パスを含める必要がある。',
  'CSV Upload': 'CSVアップロード',
  'ctrl/cmd + y': 'ctrl/cmd + y',
  'ctrl/cmd + z': 'ctrl/cmd + z',
  'Current media status:': '現状の画像のステータス：',
  Data: 'データ',
  'Data augmentation': 'データ拡張',
  'Data Augmentation Details': 'データ拡張について',
  'Data augmentation is a set of techniques used to increase the amout of data by adding slightly modified copies of existing data to the training set. This can improve model performance in some situations but can lead to issues if not handled carefully.':
    'データ拡張はトレーニングセットに少し改造した既存データのコピーを追加してデータ量を増やすテクニックです。これにより状況によってはモデルパフォーマンスを改善する可能性がありますが、注意深く処理しなければ問題が発生する可能性があります。',
  'Data browser': 'データブラウザ',
  'Data CSV': 'データCSV',
  'Data Info': 'データ情報',
  'Data overview': 'データ概要',
  'data.': 'データ。',
  'Dataset not found': 'データセットが見つかりません',
  'Dataset, split': 'データセット、スプリット',
  Date: '日付',
  Deactivate: '停止',
  Defect: '不良',
  'defect / split distribution': '不良/スプリット配分',
  'Defect Book': '不良ブック',
  'Class Color': '不良色',
  'Defect consensus': '不良コンセンサス',
  'Defect consensus last measured': '不良コンセンサス最終測定',
  'Defect consensus not measured yet...': '不良コンセンサスをまだ測定していません・・・',
  'Defect Distribution': '不良配分',
  'Defect Map': '不良マップ',
  'Class Name': '不良名',
  'Defect Rate Trend': '不良率のトレンド',
  Defects: '不良',
  'Defects ({{defectCount}})': '不良 ({{defectCount}})',
  'Defects created': '不良作成',
  'Defects Per Confidence': '信頼度毎の不良',
  'Defects Per Type': '種類毎の不良',
  Delete: '削除',
  'Delete example': '削除例',
  'Delete job': 'ジョブの削除',
  Deploy: '配備する',
  'Deploy ({{appCount}})': '配備する ({{appCount}})',
  'Deploy Now': '今配備する',
  Deployment: '配置',
  Description: '説明',
  Device: 'デバイス',
  'Device deployed to': 'デバイスを配置',
  'Device Media': 'デバイス画像',
  'Device Registered to Project': 'プロジェクトに登録したデバイス',
  Devices: 'デバイス',
  'Devices Registered': '登録したデバイス',
  Dimensions: '寸法',
  'Direct Upload': '直接アップロード',
  Discard: '除外',
  Dismiss: '無視する',
  'distributions for all media with train/dev/test split': '全画像をtrain/dev/testスプリットに分配',
  'Do you have similar defects?': '似た様な不良がありますか？',
  Documentation: '資料',
  'Does your split represent your defects well?': 'スプリットが不良をきちんと示していますか？',
  Done: '完了',
  Download: 'ダウンロード',
  'Download for Docker': 'ドッカ―ダウンロード',
  'Download for Windows 10': 'ウィンドウズダウンロード',
  'Download PDF': 'PDFダウンロード ',
  'Drag and drop some files here, or click to select files.':
    'ここにファイルをドラッグアンドドロップ、またはファイルを選択してください。',
  'Drop media here': 'ここにファイルをドロップしてください。',
  Edit: '編集',
  'Edit Member': 'メンバーの編集',
  'Effect training': 'エフェクトのトレーニング',
  'Effect validation': 'エフェクトの検証',
  Email: 'メール',
  'Enter details to Reset Password': 'パスワードをリセットする為の詳細を入力',
  'entries. For larger dataset, use the ingestion API from':
    'エントリー、大きなデータセットのためにはインジェスチョンAPIを使ってください',
  'Error analysis': '損失分析',
  Evaluate: '評価',
  'Evaluate & Analyze': '評価と分析',
  Evaluated: '評価しました',
  'Evaluated on': 'で評価しました',
  'Evaluating defect consensus and addressing ambiguity in your defect book can help avoid data being labeled incorrectly and time lost to relabeling. You can measure defect consensus whenever you think there is ambiguity in your data.':
    '不良のコンセンサスを評価して不良ブックに曖昧な部分を書きこむことにより、データが誤ってラベリングされることや再度ラベルをやり直す時間が削減されます。データに曖昧さがあると思われる場合はいつでも」不良のコンセンサスを測定出来ます。',
  'Evaluation Info': '評価情報',
  'Existing Media': '既存の画像',
  'Experiment report': '実験レポート',
  Export: 'エクスポート',
  'Export dataset': 'データセットのエクスポート',
  'Export dataset with training/dev/test split':
    'training/dev/testスプリットでデータセットをエクスポート',
  'Export Failed!': 'エクスポート失敗！',
  'Export in progress...': 'エクスポートしています・・・',
  'Exported dataset': 'データセットをエクスポートしました',
  'Failed to create categories and defects': 'カテゴリと不良の作成に失敗',
  false: '偽予測',
  'False Negative': '偽の異常',
  'False Positive': '本当の異常',
  'file(s)?': 'ファイル?',
  Fill: '埋める',
  'Finish Segmentation': 'セグメンテーションの終了',
  'Folder Upload': 'フォルダのアップロード',
  'for advanced tracking, searching and sorting. You can add metadata to your data when uploading, or assign metadata to already uploaded data.':
    '高度な追跡、検索とソートの為、メタデータをデータに追加してアップロードできます。もしくは既にアップロード済のデータにメタデータを割り当てることが出来ます。',
  'Forgot Password?': 'パスワードをお忘れですか？',
  'Generate API Key': 'API キーの生成',
  'Go Back': '戻る',
  'Go to data browser': 'データブラウザに進む',
  'Go to defect book': '不良ブックに進む',
  'Go to Models': 'モデルに進む',
  'Go to models page': 'モデルページに進む',
  'Go to task': 'タスクに進む',
  'Got It': '分かりました',
  'Ground truth': '自分のラベル',
  'Ground truth = {{groundTruth}}, and Baseline prediction = {{baselinePrediction}}, and Candidate prediction = {{candidatePrediction}}':
    '自分のラベル = {{groundTruth}}, とベースライン予測 = {{baselinePrediction}}, と候補予測 = {{candidatePrediction}}',
  'Ground truth = {{groundTruth}}, and Prediction = {{predictions}}':
    '自分のラベル = {{groundTruth}}, と予測 = {{predictions}}',
  'has been deleted': '削除された',
  higher: 'より高い',
  'Historgram equalization': 'ヒストグラム平準化',
  'Hot Keys': 'キーボードのショートカット',
  'How did we calculate FPs & FNs?': '本当の異常FPとと偽の異常FNをどうやって計算しましたか？',
  'How is your label quality?': 'ラベルの品質はどうですか？',
  'How to select data for agreement-based labeling task?':
    '一致ベースのラベリングタスクのデータはどうやって選択しますか？',
  'How to use': '使い方',
  'How to use auto-segmentation': '自動セグメンテーションの使い方',
  Id: 'ID',
  'Ideas to improve your model': 'モデルを改善するアイディア',
  'Identify defect ambiguity': '不良の曖昧さを特定する',
  'If a ground truth bounding box is defect type A and the corresponding predicted bounding box is defect type B it results in a FP for defect type B and one FN for defect type A.':
    'もし自分のラベルバウンディングボックスが不良タイプAで対応する予測バウンディングボックスが不良タイプBの場合、不良タイプBのFNと１個FNAの不良タイプAという結果になります。',
  'If there are existing labels, do you want to carry them?':
    '既存のラベルがある場合、それらを使いますか？',
  'If you find defect types that are too similar to one another maybe consider merging them.':
    'もう一方に似すぎている不良タイプを発見した場合、例えば混合させることも考慮してください。',
  'Image analysis': '画像分析',
  images: '画像',
  'Import Sample Project': '画像インポートサンプルプロジェクト',
  'In Progress': '進行中',
  'In Review': 'レビュー中',
  'In Review:': 'レビュー中：',
  'In single user task,': 'シングルユーザータスク中',
  'Include assigned train/dev/test media to reassigned':
    '登録されたtrain/dev/test画像を含めて再登録',
  'Include in the cloned project:': 'クローンプロジェクトに含める：',
  'Include pending review media': '途中のレビュー画像を含める',
  Incorrect: '不正確',
  'Incorrect Classifications': '不正確な区分',
  Information: '情報',
  Inspectors: '評価人',
  'Instance Browser ({{instanceCount}})': 'インスタンスブラウザ ({{instanceCount}})',
  'Invalid hyperparameters': '無効なハイパーパラメータ',
  Invite: '招待',
  'Invite members by email': 'メールでメンバーを招待',
  'Invite only': '招待のみ',
  'Inviting...': '招待中です・・・',
  'It is best for Machine Learning Engineers (MLE) to create, update and maintain the defect book while collaborating with Subject Matter Experts (SME). SMEs understand defect definitions, whereas MLEs know how to label the defects, and how labeling can affect model performance.':
    '機械学習エンジニア（MLE）は対象分野の専門家（SME)と協力しながら、不良ブックを作成、更新、及び保守することが最適です。SMEは不良定義を理解していますが、MLEは不良にラベル付けの方法と、ラベルのつけ方がモデルのパフォーマンスにどう影響するかを知っています。',
  Job: 'ジョブ',
  'Job details': 'ジョブについて',
  'Job ID': 'ジョブID',
  'Job Info': 'ジョブ情報',
  'Job is still in progress': 'ジョブが進行中',
  'JPEG, PNG and BMP': 'JPEG、PNG、BMP',
  Junior: 'ジュニア',
  'Key:': 'キー',
  Label: 'ラベル',
  'Label caption:': 'ラベルキャプション：',
  'Label your data': 'データにラベルを付ける',
  Labeled: 'ラベル済',
  'labeled by me / total in task': '自分でラベル済/タスクの合計',
  'labeled by me/by others/total in task': '自分でラベル済/他者によって/タスクの合計',
  Labeling: 'ラベリング',
  'Labeling agreement': 'ラベリング一致',
  'Labeling by me:': '自分でラベリング：',
  'Labeling instructions': 'ラベリング説明',
  'Labeling Task Assignment': 'ラベリングタスクの割り当て',
  'Labeling task type': 'ラベリングタスクのタイプ',
  'Labeling Type': 'ラベリングタイプ',
  Labels: 'ラベル',
  'Labels:': 'ラベル：',
  'Landing AI Platform API keys carry many privileges. Please keep them secure. Do not share the API keys in publicly accessible areas such as GitHub, client-side scripts, etc. All API requests must be made over HTTPS. Calls made over HTTP will fail. Unauthorized API requests will also fail.':
    'APIキーには多くの特権があります。それらを安全に保管してください。APIキーをGitHub、クライエント側スクリプト等、公けにアクセス可能な場所にシェアしないでください。API要求は全てHTTPS経由でされなければいけません。HTTP経由でされたコールは失敗します。許可されていないAPI要求も失敗します。',
  'Landing AI Platform uses API keys to authenticate requests. You can view and manage the API keys through this utility.':
    'APIキーを使って要求を認証します。このユーティリティを使ってAPIキーを表示及び管理出来ます。',
  LandingLens: 'LandingLens',
  'Landing AI Visual Inspection Platform': 'LandingLens AI外観検査 プラットフォーム',
  'All rights reserved.': 'すべての権利を留保する',
  'Landing AI': 'Landing AI',
  'Last update time - latest first': '最終更新時間－最新の物を初めに表示',
  'Last updated on': '最終更新日',
  'Last updated time - earliest first': '最終更新時間－古い物を初めに表示',
  'Last updated:': '最終更新：',
  'Last uploaded on': 'に最終アップロード',
  'Leave review notes about this media': 'この画像に関するレビューメモを残す',
  'Left-click is positive input to auto-generate segmentation label':
    '左クリックは自動生成セグメンテーションラベルのポジティブ入力です。',
  Loading: 'ロード中',
  'Loading...': 'ロード中・・・',
  'Lodging Front': 'ロッジングフロント',
  'Log In': 'ログイン',
  'Log in with password': 'パスワードでログインする',
  'Log in with SSO': 'シングルサインオン (SSO) でログイン',
  Logs: 'ログ',
  'Look for FP for a particular defect type and evaluate what the predominant miscategorized defect type is. This usually means that you have mislabeled data, your defect definitions are ambiguous or your defects are too similar to distinguish from the images. If you find mislabeled ground truths in image analysis, select the check mark in the upper left corner of the image and click “Create labeling task”, you can also select multiple images and add them to the same labeling task.':
    '特定の不良タイプの偽不良を見て、誤分類された主な不良タイプが何かを評価します。これは通常間違ってラベルしたデータ、不良の定義が曖昧、もしくは不良が似すぎていて画像から区別できないことを意味しています。もし間違ってラベルされた自分のラベルが画像分析で見つかったら、画像の左上の角のチェックマークを選択して”ラベリングタスクを作成”をクリック、そして更に複数の画像を選択して同じラベリングタスクに追加出来ます。',
  'Look for TP with low confidence scores or FN in your image analysis report. If you see many of these model errors for a particular defect type that could mean lack of this defect in your training split data.\n':
    '本当の異常で低い信頼度スコアか偽の異常を画像分析レポートから探します。もし特定の不良タイプに対してこれらのモデル損失を沢山発見した場合には、トレーニングスプリットデータの中に不良が欠落していることを意味しているかもしれません。\n',
  'Look out for corner or underrepresented cases of your defect types and add them manually to both training and validation sets.':
    '不良タイプのコーナーまたは過小評価されたケースを見て、両方トレーニングと検証セットを手動で追加します。',
  lower: '低い',
  'Maintenance in progress, please come back later.':
    'メンテナンス中です。後で再度アクセスしてください。',
  'Make invite only': '招待のみ',
  'Make visible by anyone': '誰にでも見えるようにする',
  Manage: '管理',
  'Manage projects': 'プロジェクトの管理',
  'Manage your data through utilizing': 'を利用してデータを管理',
  Mandatory: '必須',
  mAP: '平均精度適合（mAP)',
  'Mark as best': 'ベストとマーク',
  'Maximize productivity, ensure quality, and optimize business results with our AI-powered Visual Inspection product.':
    '私達のAIを活用したビジュアル検査製品を使って、生産性を最大化して、品質を保証して、そしてビジネス結果を最適化します。',
  'MAXIMUM of 1000': '最大1000',
  Media: '画像',
  'Media Agreement': '画像一致',
  'Media agreement is {{higher}} than the agreement threshold. We have picked the best label, you can choose to {{accept}} or {{reject}} this label':
    '画像一致',
  "Media agreement is {{lower}} than the agreement threshold. This media is {{rejected}}, you can view all labeler's labels":
    '画像一致が一致の閾値よりも {{lower}} 。この画像が {{rejected}}, ラベラー全員のラベルを見ることが出来ます。',
  'Media details': '画像について',
  'media from dataset?': 'データセットからの画像？',
  'Media has no labels or is pending labeling!': '画像にラベルがないか、ラベリング中です！',
  'Media has nothing to label': '画像にラベルするものがありません。',
  'Media id': '画像ID',
  'Media in this batch': 'バッチの中の画像',
  'Media List': '画像リスト',
  'Media List ({{mediaListLength}})': '画像リスト ({{mediaListLength}})',
  'Media Metadata': '画像メタデータ',
  'Media Metrics': '画像メトリクス',
  'Media Name': '画像名',
  'media not tagged': '画像にタグされていない',
  'Media per page:': 'ページあたりの画像：',
  'Media Predicted Per Day': '1日あたりの予測画像',
  'media selected': '選択画像',
  'media selected, however your task will only have': '選択画像、しかしタスクには～しかない',
  'Image selection': '画像選択',
  'Media size:': '画像サイズ：',
  'Media Status': '画像ステータス',
  'media to': '画像が',
  'Media uploaded': '画像をアップロードしました',
  'media will be removed from existing labeling tasks (media already labeled will not be affected) and data browser for all future actions.':
    '画像が既存のラベリングタスク(画像は既にラベルされていて影響を受けない)と全ての将来のアクションに対してデータブラウザーから取り除かれる。',
  'media with no defects': '不良の無い画像',
  'media.': '画像。',
  'media. Pending labeling, pending review and deleted medias will not be included in the task':
    '画像。ラベリングやレビューの途中で削除した画像はタスクに含まれません。',
  'media. You can only export': '画像。エクスポートしかできません。',
  medias: '画像',
  'Member seats in your plan:': 'プランの中のメンバーシート',
  Members: '会員',
  Message: 'メッセージ',
  Metadata: 'メタデータ',
  Metric: 'メトリック',
  'Might take some time to analyze...': '分析する為に時間がかかるかもしれません。。。',
  mIOU: '領域集合体の中の共通部分(mIOU)',
  Mismatched: 'ミスマッチされた',
  'Machine Learning workflow': '機械学習ワークフロー',
  'Machine Learning workflow overview': '機械学習ワークフローの概要',
  Model: 'モデル',
  'Model & Device': 'モデル＆デバイス',
  'Model Comparison': 'モデル比較',
  'Model details': 'モデルについて',
  'Model evaluated on': 'で評価したモデル',
  'Model info': 'モデル情報',
  'Model Running': 'モデルを実行中',
  Models: 'モデル',
  'Models available to deploy': '配置可能なモデル',
  'Models Availaible to deploy': '配置可能なモデル',
  'models page': 'モデルページ',
  Monitor: 'モニター',
  'Monitor device media': 'モニターデバイスメディア',
  'More filters': '更なるフィルター',
  'My profile': 'マイプロフィール',
  'My tasks': 'マイタスク',
  'N/A': '該当しない',
  Name: '名前',
  'name of the bucket': 'バケットの名前',
  'Navigate to your data browser and select data: select 10 - 20 medias that to your knowledge are ambiguous and difficult to label. Create a Labeling Task, assign > 1 distinctive labels per image to 2+ labelers.':
    'データブラウザーと選択データをナビゲートする：知識が曖昧でラベルが難しい10-20メディアを選択してください。ラベリングタスクを作成してください、画像毎に1個以上の固有のラベルを2個以上のラベラーに割当ててください。',
  'New Defect': '新規の不良',
  'New Media': '新規画像',
  'New Project': '新規プロジェクト',
  Next: '次へ',
  NG: '良くない(NG)',
  No: 'ない',
  'No available project found': '利用可能なプロジェクトがありません。',
  'No data augmentation applied': '適用したデータ拡張がありません。',
  'No data to display': '表示するデータがありません。',
  'No Default Dataset': 'デフォルトのデータセットがありません。',
  'No defect created yet...': '作成した不良がまだありません・・・',
  'No defect defined. Please fill in defect book first.':
    '定義した不良がありません。不良ブックを初めに入力してください。',
  'No defect media': '不良の画像がありません。',
  'No defects': '不良がありません。',
  'No devices': 'デバイスがありません。',
  'No devices or apps registered. You can download our inference app to your local machine and register to run with the models.':
    'デバイスもアプリも登録されていません。推論アプリをローカルマシンにダウンロードして登録すると、モデルを実行できます。',
  'No error message': 'エラーメッセージはありません。',
  'No evaluation jobs for this model': 'このモデルには、評価ジョブはありません。',
  'No favorite evaluation jobs for this model':
    'このモデルには、お気に入りの評価ジョブがありません。',
  'No label': 'ラベルがありません。',
  'No images available to split.': 'スプリットできる画像がありません。',
  'No media found': '画像がありません。',
  'No media in this project': 'このプロジェクトには画像がありません。',
  'No media uploaded yet...': 'アップロードした画像がまだありません・・・',
  'No metrics available': '利用できるメトリクスがありません。',
  'No Model': 'モデルがありません。',
  'No model marked as best yet': '最適とマークしたモデルがまだありません。',
  'No models trained yet.': '訓練したモデルはまだありません。',
  'no split assigned': '割り当てたスプリットがありません。',
  'No tasks assigned': '割り当てたタスクがありません。',
  'No trained models yet': 'モデルをまだ訓練していません。',
  'no-model': 'モデルがありません。',
  'no-project': 'プロジェクトがありません。',
  'No, erase existing labels': 'いいえ、既存のラベルを消去します。',
  None: 'なし',
  'Not labeled': 'ラベル付けしたものがありません。',
  'Not started': 'まだ開始していません。',
  'not tagged': 'タグ付けされていません。',
  Notes: 'メモ',
  'Nothing to show': '表示できるものがありません。',
  'Notify the Platform team the': 'をプラットフォームチームに知らせてください。',
  number: '数',
  'Number of labelers has to be greater than number of labelers per media':
    'ラベラー数は、画像あたりのラベラー数よりも大きい必要があります。',
  'number of media in each split': '各スプリットにおける画像数',
  'Number of medias is limited to a': '画像数は、に限定されます',
  Offline: 'オフライン',
  OK: 'OK',
  on: 'に',
  'On {{mediaTotal}} media, ground truth has {{groundTruthTotal}} bounding box(es), model predicts {{predictionCountTotal}} bounding box(es).':
    '{{mediaTotal}}画像では、自分のラベルに{{groundTruthTotal}}バウンディングボックスがあり、モデルが{{predictionCountTotal}}バウンディングボックスを予測します。',
  'On {{mediaTotal}} media, ground truth has {{groundTruthTotal}} defect(s), model predicts {{predictionCountTotal}} defect(s).':
    '{{mediaTotal}}画像では、自分のラベルに{{groundTruthTotal}}不良があり、モデルが{{predictionCountTotal}}不良を予測します。',
  "Once you export an accurate dataset you're confident in, it's time to train a model and evaluate its performance.":
    '確信のある正確なデータセットをエクスポートすると、モデルを訓練し、そのパフォーマンスを評価します。',
  'Once you have a suitable exported dataset you can train your first model.':
    'エクスポート済みの最適なデータセットを持っていれば、すぐに最初のモデルを訓練できます。',
  'Once you have reached acceptable defect consensus, the next step is to label your data.':
    '許容できる不良コンセンサスにたどり着いたら、次はデータをラベル付けします。',
  'One good way to start improving quality is to assign labeling tasks to multiple inspectors and see whether their labels overlap. You can evaluate team alignment on defects with the reported “Agreement Score” metric on each image, which quantifies overlap so you can sort by score and understand where defect ambiguity exists.\n':
    '品質改善に向けた良い方法として、複数のインスペクターにタスクのラベル付けを割り当て、そのラベルがオーバーラップするかどうか確かめることが挙げられます。これにより、各画像で報告された「一致スコア」のメトリックを用いながら、不良におけるチーム・アラインメントを評価できます。このメトリックはオーバーラップを数値化するもので、スコアのソートや曖昧な不良の存在場所の把握ができます。',
  Online: 'オンライン',
  'Only invited members can view': '招待されたメンバーのみが閲覧可能',
  'Only show favorite evaluation jobs': 'お気に入りの評価ジョブのみを表示',
  'Only show favorite models': 'お気に入りのモデルのみを表示',
  "Oops. Looks like the page you're looking for no longer exists":
    'お探しのページは削除された可能性があります。',
  Opacity: '不透明性',
  operation: 'オペレーション',
  'Or, you can create a downloadable app, download it on your local machine and run locally.':
    'またはダウンロード可能なアプリを作成し、それをローカルマシンにダウンロードすると、ローカル環境で実行できます。',
  Original: 'オリジナル',
  'Overall agreement score': '一致スコアの総合点',
  'Overall Task Progress': 'タスクの全体的な進捗度',
  Overview: '概要',
  Owner: '所有者',
  'Pending assignment:': '割り当ての保留中：',
  'Platform API': 'プラットフォームAPI',
  'Please ensure that the files you want to upload are organized following way:':
    'アップロードしたいファイルが、以下の方法で整理されていることを確かめてください：',
  'Please select at least 2 labelers': '少なくとも2つのラベラーを選択してください。',
  'Please select the number of distinctive labels (labeled by different labelers) required:':
    '特定のラベル（各ラベラーがラベル付けしたもの）の数を選択する必要があります：',
  'Pre-Defined Choice': '事前に定義した選択',
  Precision: '適合率',
  'Precision/Recall': '適合率/再現率',
  Prediction: '予測',
  'Preparing media to be uploaded to data browser':
    'データブラウザにアップロードする画像を用意する',
  'Data Preview': 'データのプレビュー',
  Previous: '戻る',
  'Prioritize media in review': 'レビューにおける画像の優先順位付け',
  'Proceed to next media after review': 'レビュー後、次の画像に進む',
  Progress: '進捗状況',
  "Progress of all inspectors' work combined": '全てのインスペクターの作業状況をまとめた進捗状況',
  Project: 'プロジェクト',
  Projects: 'プロジェクト',
  'Projects participation': 'プロジェクトに参加',
  'Randomize the media order in task': 'タスクで画像の順番をランダム化する',
  Raw: '未処理',
  Recall: '再現率',
  redo: 'やり直す',
  Refresh: 'リフレッシュ',
  'Refresh job state': 'ジョブ状況のリフレッシュ',
  Reject: 'リジェクト',
  'reject and go to next media': 'リジェクトして次の画像に進む',
  Rejected: 'リジェクトしました',
  'Rejected:': 'リジェクトしました：',
  'Remaining upload limit is': '残りのアップロード制限は次の通りです。',
  'Remember that the selection of the best model in a training job happens by looking at the valid/dev split metrics. This is why it is also important to have a valid/dev split that represents all of your defect types. A good way to start is to use the auto-split feature to ensure classes are properly split.\n':
    '訓練ジョブに最適なモデルの選択は、valid/devスプリットメトリクスで行われていることを覚えておいてください。valid/devスプリットが不良タイプ全てを表しているため、それを行うことが重要になります。開始時の最適な方法として、自動スプリット機能でクラスが適切にスプリットされていることをよく確かめてください。',
  'Remember that your model will not perform better than your labelers.':
    'あなたのモデルが、ラベラーより優れたパフォーマンスを行わない可能性があることを覚えておいてください。',
  Remove: '削除',
  'Remove from dataset': 'データセットから削除する',
  Rename: '名前の変更',
  Requirements: '要件',
  'Result Preview': '結果のプレビュー',
  Retry: '再試行',
  Review: 'レビュー',
  'Right-click is negative input to auto-erase labeled area':
    '右クリックするとマイナス入力になり、ラベル付けした領域の自動削除ができます。',
  Role: '役割',
  RUNNABLE: '実行可能なもの',
  RUNNING: '実行中',
  'Running Time': '実行時間',
  's3://': 's3://',
  'Sample Projects In Progress': '進行中のサンプルプロジェクト',
  Save: '保存',
  'Save Drawing Segmentation': 'ドローイング・セグメンテーションの保存',
  'Saved data augmentation pipelines': 'データ拡張パイプラインを保存しました',
  Search: '検索',
  'Secret:': 'シークレット：',
  Segmentation: 'セグメンテーション',
  Select: '選択する',
  'Select all': '全て選択',
  'Select Class': '不良を選択',
  'Select media': '画像を選択',
  'Select media from dataset': 'データセットから画像を選択',
  'Select metadata': 'メタデータを選択',
  'Select metadata to attach to uploaded media':
    'メタデータを選択して、アップロード済みの画像に添付する',
  'Select page': 'ページの選択',
  'Select Unit on the table to view the video': 'テーブルのユニットを選択して、動画を表示する',
  Selected: '選択しました',
  'Selected media': '画像を選択しました',
  'Selected media + Existing media with split': '画像+スプリット付きの既存の画像を選択しました',
  Senior: '上位',
  'Set metadata': 'メタデータの設定',
  'set metadata value': 'メタデータの値の設定',
  'Set split distribution': 'スプリット分布の設定',
  'Set your target defect split distribution': 'ターゲットとなる不良のスプリット分布の設定',
  'Show All': '全て表示',
  'Show Labels': 'ラベルを表示',
  'Show Pending Users': '保留中のユーザーを表示',
  'Show:': '表示：',
  'Sign out': 'ログアウト',
  'Similarly segmentation is calculated by looking at the IoU between the ground truth and predicted classes (instead of boxes).':
    'セグメンテーションも同様に、自分のラベルと予測した（ボックスの代わりの）クラス間のIoUを見て計算します。',
  'Since it is a single-user task, no review process is needed and all labels are automatically approved.':
    'シングルユーザーのタスクであるためレビュープロセスは不要で、ラベル全てが自動的に承認されます。',
  'Skip Export for Now': '今はエクスポートをスキップする',
  'Skip this media': 'この画像をスキップする',
  'Something is wrong with this job metadata. A new evaluation job cannot be created.':
    'ジョブメタデータに問題が生じているため、新しい評価ジョブを作成できません。',
  'Something is wrong with your input :(': '無効な値：（',
  'Something is wrong...': '問題が生じています・・・',
  'Sometimes there are bounding boxes that look like a correct prediction but are labeled as FN. This happens when the IoU (intersection over union) of the two bounding boxes is too low. If this happens too often the root cause might indicate inconsistent labeling styles.\n':
    '正しい予測のようですが、偽陰性（FN）としてラベル付けされているバウンディングボックスが見られることがあります。これは、2つのバウンディングボックスのIoU（2つの領域の重なりを表す指標）が低すぎることが原因として挙げられます。もしもこれが頻繁に生じるのであれば、ラベリングのスタイルに一貫性がない可能性が根本的な原因として考えられます。',
  'Sort by:': '並び替え順：',
  'sort media by agreement score': '一致スコアで画像を並び替え',
  'Sort options:': '並び替えオプション：',
  'split / defect distribution': 'スプリット/不良の分布',
  'Split and Export Dataset': 'データセットのスプリットとエクスポート',
  'SSO Sign In': 'SSOでサインイン',
  'Start Labeling': 'ラベリングを始める',
  'Started, keep iterating': '開始後、繰り返し行う',
  STARTING: '開始中',
  Status: '状況',
  'Stroke width': 'ストローク幅',
  'stroke width:': 'ストローク幅：',
  Submit: '送信',
  'Succeeded evaluation jobs': '評価ジョブが成功しました',
  'Succeeded training jobs': '訓練ジョブが成功しました',
  Tags: 'タグ付け',
  'Task cancelled': 'キャンセルしたタスク',
  'Task Name': 'タスク名',
  "Task progress of all inspectors' work combined": '全てのインスペクターの作業進捗状況',
  text: 'テキスト',
  'The report could not be generated': 'レポートを作成できませんでした',
  'There are': 'があります',
  "There are 0 defect in all selected media, you won't be able to set split distribution on them.":
    '選択済み画像全てに0の不良があるため、スプリット分布を設定できません。',
  'There was an error while fetching your trained models.':
    'トレーニング済みのモデルのフェッチング中にエラーが生じました。',
  'This chart shows the number of defects for each split, usually you want the distribution to be similar across each split to train a successful model':
    'このグラフでは、各スプリットにおける不良の数を表示しています。通常、モデルの訓練を成功させるには、各スプリットを同様に分布させる必要があります。',
  'This job has no': 'このジョブには次のものがありません。',
  'This media has been {{accepted}}. You can toggle to view selected label or all labels.':
    'この画像は {{accepted}}されました。トグルして選択されたラベルか全てのラベルを表示してください。',
  'This media has been {{rejected}}. You can view all labels.':
    'この画像は {{rejected}}されました。全てのラベルを表示出来ます。',
  'This project does not have any device.': 'このプロジェクトはデバイスがありません。',
  'This project has no exported datasets yet':
    'このプロジェクトには、エクスポートしたデータセットがまだありません。',
  'This should directly resemble your target defect split above, if the average deviation is very large, reasons could be:':
    'これは、上のターゲット不良スプリットに直接的に類似している必要があります。もし平均偏差が非常に大きい場合の理由は、次のことが考えられます：',
  'This will create a new evaluation job. Enter more details about your job.':
    'これは新規の評価ジョブを作成します。更にジョブに関する詳細を入力してください。',
  Title: 'タイトル',
  'To clarify ambiguity among SMEs, you can assign 2-3 SMEs to complete an agreement-based\n labeling task.':
    'SMEにおける曖昧さを明らかにするには、2、3つのSMEを割り当てて一致に基づいたラベリングタスクを完了します。',
  'To identify ambiguity for labelers, you can assign 2-3 labelers to complete an\n agreement-based labeling task.':
    'ラベラーにおける曖昧さを特定するには、2、3つのラベラーを割り当てて一致に基づいたラベリングタスクを完了します。',
  'toggle color': 'トグルの色',
  'toggle tools': 'トグルのツール',
  Tools: 'ツール',
  Total: 'トータル',
  'Total {{mediaCount}} media': 'トータル {{mediaCount}} 画像',
  'Total in task:': 'タスクの合計：',
  'Total number of defects': '不良の総数',
  'Total Number of Media to Be Labeled:': 'ラベル付けした画像の総数：',
  'Total number of images to split': 'スプリットする画像の総数',
  Train: '訓練',
  'train / dev / test': 'train / dev / test',
  'train / dev / test (%)': 'train / dev / test (%)',
  'Train and evaluate your model': 'トレーニングとモデルを評価',
  'Train new model': '新規モデルの訓練',
  Trained: 'トレーニング',
  'Trained on': 'トレーニングする',
  Training: '訓練中',
  'Training Info': '訓練情報',
  'Training jobs': '訓練ジョブ',
  'Transform name': '名前の変換',
  'Transforms Details': '変換について',
  'transforms:': '変換：',
  true: '正しい予測',
  'True Negative': '正しい正解',
  'True Positive': '正しい異常',
  Type: 'タイプ',
  undo: '元に戻す',
  'Unexpected validation errors:': '予期しない検証損失',
  Unknown: '不明',
  'Unknown dataset': '不明なデータシート',
  'Unknown height': '不明な高さ',
  'Unknown Job ID': '不明なジョブID',
  'Unknown model': '不明なモデル',
  'Unknown owner': '不明な所有者',
  'Unknown project': '不明なプロジェクト',
  'Unknown time': '不明な時間',
  'Unknown user': '不明なユーザー',
  'Unknown width': '不明な幅',
  'Unselect all': '全て選択解除',
  'Unsupported transform': 'サポートされていない変換',
  Upload: 'アップロード',
  'Upload defects, add defect descriptions, upload sample media, and annotate your media to help define your defects.':
    'アップロード不良、不良説明を追加、サンプル画像をアップロード、そして画像に注釈をつけて不良を定義する手助けをしてください。',
  'Upload Images': '画像のアップロード',
  'Upload Time': 'アップロード時間',
  'Upload time (Newest to Oldest)': 'アップロード時間(最新から古いものへ)',
  'Upload time (Oldest to Newest)': 'アップロード時間(古いものから最新へ)',
  'Upload yaml file': 'yamlファイルのアップロード',
  'Upload your existing data to your project and continuously upload new data as it becomes available. View and explore your data in the':
    '既存データをプロジェクトにアップロードし、利用可能になる度に続けて新しいデータをアップロードしてください。データを表示して探してください。',
  'Upload, browse & manage your media': '画像をアップロード、閲覧及び管理してください。',
  Validation: '検証',
  Value: '値',
  View: '表示',
  'View & Deploy': '表示と配置',
  'View all datasets': '全データセットの表示',
  'View all labeling task': '全ラベリングタスクの表示',
  'View all labels': '全ラベルの表示',
  'View details': '詳細の表示',
  'View job details': 'ジョブ詳細を表示',
  'view labels from all labelers': '全ラベラーからラベルを表示',
  'View Model Details': 'モデル詳細を表示',
  VISIBILITY: '可視性',
  'Visibility:': '可視性：',
  'Warning:': '警告：',
  'We assign media to inspectors in batches. Each batch contains 20 media. Once a batch is submitted, another one will be assigned.':
    '画像を検査にバッチで割当てます。各パッチには20枚の画像が含まれています。バッチが送信されると別のバッチが割当てられます。',
  "We offer two model options, RetinaNet for object detection and UNet for segmentation. You should choose whichever model aligns with the label type you've used in your data browser.":
    'オブジェクト検出用のRetinaNetとセグメンテーション用のUNetの2個のモデルオプションを提供しています。データブラウザで使われたラベルタイプに一致するモデルを選択する必要があります。',
  'We recommend you submit your current work': '現在の仕事を送信することをお勧めします。',
  'What can you look for when doing error analysis?':
    '損失分析をする時に何を探すことが出来ますか？',
  'What is Data Augmentation?': 'データ拡張とは何ですか？',
  'When enough media are labeled and approved, you will want to export a version of your data with training, dev, and test splits appropriately applied to the dataset to ensure accuracy of labels.':
    '十分なメディアにラベルがつけられて承認されたら、ラベルの正確性を確保する為に、データセットに適切に適用されたトレーニング、開発、テストスプリットを含むバージョンのデータをエクスポートする必要があります。',
  'where the medias will be added from if the bucket has never been verified':
    'バケットがまだ検証されていない場合に画像が追加されます。',
  'Which model class and architecture should I choose?':
    'どのモデルクラスと構造を使えば良いですか？',
  'Who should create the defect book?': '誰が不良ブックを作成すれば良いですか？',
  'Who should participate?': '誰が出席すれば良いですか？',
  'with any split.': 'どのスプリットで',
  Yes: 'はい',
  'Yes, keep existing labels': 'はい、既存のラベルを保持します。',
  Yield: '産出',
  'You are drawing a segmentation, do you want to save it?':
    'セグメンテーションを描いています。保存しますか？',
  'You can assign split and export media in this task to get ready for training.':
    'スプリットを割り当ててこのタスクの画像をエクスポートしてトレーニングに臨みます。',
  'You can click multiple times to get what you want, each click might take':
    '複数回クリックして欲しいものを手に入れてください。各クリックには次が必要かもしれません。',
  'You can try clearing some filters to reveal hidden models.':
    'フィルターをクリアしてみれば隠れたモデルを表示できます。',
  'You changes will be discarded': '変更が破棄されます。',
  'You do not have any projects yet. Please create one.':
    'まだ何もプロジェクトがありません。作成してください。',
  'You have not created any defects yet.': 'まだ何も不良を作成していません。',
  'You have not submitted all your reviewed media. Do you want to submit and continue?':
    'まだ再確認した画像を送信していません。送信して続けますか？',
  'You have selected': '選択しました。',
  'You have selected {{inReviewCount}} in review media, We do not recommend you export media that has not been approved.':
    '再確認した画像を {{inReviewCount}} 選択しました。承認されていない画像をエクスポートすることはお勧めしません。',
  'You have successfully assigned': '割当てに成功しました。',
  'You need at least one exported dataset to run a job.':
    'ジョブを実行するには、少なくとも1つのエクスポートされたデータセットが必要です。',
  'you will be automatically assigned as the labeler and your labels will be automatically approved.':
    '自動的にラベラーとして割り当てられると、お客様のラベルは自動的に承認されます。',
  'You will have the option to split and export the media in this task after submitting your labels.':
    'ラベルを送信した後、このタスクで画像をスプリットしてエクスポートするオプションがあります。',
  'You will provide positive input and negative input:':
    'ポジティブ入力とネガティブ入力を提供します。',
  'Your browser does not support the HTML5 Video element.':
    'お使いのブラウザはHTML5ビデオ要素をサポートしていません。',
  "Your organization doesn't have devices yet.": '組織には、デバイスがまだありません。',
  'Your task will have': 'あなたのタスクは次があります。',
  'Zoom options': 'ズームオプション',
} as { [key: string]: string };

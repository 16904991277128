import { EvaluationSetItem } from '@/api/evaluation_set_api';
import {
  RegisteredModelWithBundles,
  RegisteredModelWithThresholdAndEndpoints,
} from '@/api/model_api';
import { Provider } from 'jotai';
import { Button, Typography, useDebounce } from '@clef/client-library';
import { Box, Link, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import ModelComparisonDialog from './componentsV2/ModelComparison/ModelComparisonDialog';
import ModelComparisonSelection from './componentsV2/ModelComparison/ModelComparisonSelection';
import ModelPerformanceMoreOptions from './componentsV2/ModelPerformanceMoreOptions';
import ModelPerformanceTable from './componentsV2/ModelPerformanceTable';
import { isSameModelAndThreshold, ModelComparisonSelectionInfo } from './componentsV2/utils';
import { useGetModelListQuery } from '@/serverStore/modelAnalysis';
import LoadingProgress from './componentsV2/LoadingProgress';
import emptyBox from '@/images/empty_box.svg';
import {
  isModelTrainingFailed,
  isModelTrainingInProgress,
} from '@/store/projectModelInfoState/utils';
import Checkbox from '@material-ui/core/Checkbox';
import BasicFilterSearchBar from './componentsV2/AddEvaluationSetDialog/BasicFilterSearchBar';
import { DatasetVersion, DatasetVersionId } from '@clef/shared/types';
import { useDatasetExportedWithVersionsQuery } from '@/serverStore/dataset';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useHistory } from 'react-router';
import CLEF_PATH from '@/constants/path';

const useStyles = makeStyles(theme => ({
  newTopButtonGroup: {
    top: 124,
    right: 100,
    position: 'absolute',
    marginBottom: theme.spacing(7),
    marginTop: -theme.spacing(7),
  },
  freTopButtonGroup: {
    top: theme.spacing(7),
    marginBottom: 0,
    marginTop: 0,
  },
  emptyBoxIcon: {
    width: 120,
    height: 120,
  },
  emptyStateModelPageTextBody: {
    width: 400,
    textAlign: 'center',
    color: theme.palette.greyModern[500],
  },
  linkText: {
    cursor: 'pointer',
  },
}));

const EmptyStateModelPage = () => {
  const styles = useStyles();
  return (
    <Box display="flex" flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <Box marginTop={5}>
        <img src={emptyBox} className={styles.emptyBoxIcon} />
      </Box>
      <Box marginTop={5}>
        <Typography variant="h2_semibold">{t('Everything About Models')}</Typography>
      </Box>
      <Box marginTop={5}>
        <Typography className={styles.emptyStateModelPageTextBody} variant="body_regular">
          {t(
            'Effortlessly review, evaluate, and compare all your models in one place on this dedicated Models page, simplifying your deployment decisions.',
          )}
        </Typography>
      </Box>
      <Box marginTop={5}>
        <Button
          id="model-page-empty-state-learn-more-btn"
          variant="outlined"
          onClick={() => {
            window.open(
              'https://support.landing.ai/docs/compare-models',
              '_blank',
              'noopener,noreferrer',
            );
          }}
        >
          {t('Learn More')}
        </Button>
      </Box>
    </Box>
  );
};

const EmptyStateModelPageAfterFiltering = () => {
  const styles = useStyles();
  return (
    <Box display="flex" flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <Box marginTop={5}>
        <img src={emptyBox} className={styles.emptyBoxIcon} />
      </Box>
      <Box marginTop={5}>
        <Typography variant="h2_semibold">{t('No models found')}</Typography>
      </Box>
    </Box>
  );
};

const ModelsPageV2 = () => {
  const history = useHistory();
  const [enableComparisonSelection, setEnableComparisonSelection] = useState(false);
  const [comparisonSelectionInfo, setComparisonSelectionInfo] =
    useState<ModelComparisonSelectionInfo>({});
  const { data: snapshots } = useDatasetExportedWithVersionsQuery({
    withCount: true,
    includeNotCompleted: true,
    includeFastEasy: true,
  });

  const datasetVersions = snapshots && snapshots.datasetVersions ? snapshots.datasetVersions : [];
  const datasetVersionsMap = datasetVersions.reduce((acc, cur: DatasetVersion) => {
    return { ...acc, [cur.id]: cur };
  }, {} as Record<DatasetVersionId, DatasetVersion>);

  const [openComparisonDialog, setOpenComparisonDialog] = useState(false);

  const styles = useStyles();
  const { data: modelList = [], isLoading: isModelListLoading } = useGetModelListQuery();

  const [onlyShowFavoriteModelBundles, setOnlyShowFavoriteModelBundles] = useState(false);
  const [searchFilterText, setSearchFilterText] = useState<string | null>(null);

  const handleSelectComparisonSet = (evaluationSet: EvaluationSetItem) => {
    setComparisonSelectionInfo(prev => ({ ...prev, evaluationSet }));
  };

  const handleComparisonClick = useCallback(
    (model: RegisteredModelWithThresholdAndEndpoints, evaluationSet: EvaluationSetItem) => {
      if (!enableComparisonSelection) {
        setEnableComparisonSelection(true);
      }
      const { baseline, candidate } = comparisonSelectionInfo;
      const newComparisonSelectionInfo = { ...comparisonSelectionInfo };
      if (isSameModelAndThreshold(baseline, model)) {
        newComparisonSelectionInfo.evaluationSet = undefined;
        newComparisonSelectionInfo.baseline = undefined;
        setEnableComparisonSelection(false);
      } else if (isSameModelAndThreshold(candidate, model)) {
        newComparisonSelectionInfo.candidate = undefined;
      } else if (!baseline) {
        newComparisonSelectionInfo.baseline = model;
        newComparisonSelectionInfo.evaluationSet = evaluationSet;
      } else {
        newComparisonSelectionInfo.candidate = model;
        newComparisonSelectionInfo.evaluationSet = evaluationSet;
      }

      // If no model selected, clear the selected evaluation set
      if (!newComparisonSelectionInfo.baseline && !newComparisonSelectionInfo.candidate) {
        newComparisonSelectionInfo.evaluationSet = undefined;
      }

      setComparisonSelectionInfo(newComparisonSelectionInfo);
    },
    [comparisonSelectionInfo, enableComparisonSelection],
  );

  /**
   * In model list from backend, we need to filter out
   * 1. failed models
   * 2. new models(after model analysis feat) have bundles but all deleted by user
   *
   * After these filters, bundles still could be [], for following possibilities
   * 1. model is training in progress
   * 2. old models (before model analysis run eval feature)
   */
  const successfulModels: RegisteredModelWithBundles[] = modelList.filter(
    model => !isModelTrainingFailed(model.status),
  ); // filter out failed model

  /**
   * For old models with no bundles, alert user to deploy these models from build page
   */
  const oldModels = successfulModels.filter(model => {
    const isOldModelsWithNoBundles =
      model.bundles.length === 0 && !isModelTrainingInProgress(model.status, model.metricsReady);
    return isOldModelsWithNoBundles;
  });
  const showOldModelsAlert = oldModels.length !== 0;
  const savedModels = successfulModels
    .filter(model => {
      const hasBundleNotDeleted = model.bundles.reduce((acc, cur) => {
        return acc || !cur.isDeleted;
      }, false);
      const isTrainingInProgressModel =
        model.bundles.length === 0 && isModelTrainingInProgress(model.status, model.metricsReady);
      return isTrainingInProgressModel || hasBundleNotDeleted;
    }) // filter out models whose bundles are all being deleted, but keep model whose bundles is [] because it might be old models or training-in-progress model
    .map(model => {
      return {
        ...model,
        bundles: model.bundles.filter(bundle => !bundle.isDeleted), // filter out deleted bundles
      };
    });
  const savedModelsFilteredByFavorite = onlyShowFavoriteModelBundles
    ? savedModels
        .filter(model => {
          const hasBundleFavorite = model.bundles.reduce((acc, cur) => {
            return acc || cur.isFav;
          }, false);
          return hasBundleFavorite;
        })
        .map(model => {
          return {
            ...model,
            bundles: model.bundles.filter(bundle => bundle.isFav),
          };
        })
    : savedModels;
  const filterModelByNameAndCreator = (
    searchText: string | null,
    modelName: string | null | undefined,
    creatorName: string | null | undefined,
  ) => {
    if (!searchText) {
      return true;
    }
    if (!modelName || !creatorName) return false;
    const searchTextLowerCase = searchText.toLowerCase();
    return `${modelName} ${creatorName}`.toLowerCase().includes(searchTextLowerCase);
  };
  const savedModelsFilteredBySearchText = savedModelsFilteredByFavorite.filter(model => {
    const datasetVersion = model.datasetVersionId
      ? datasetVersionsMap[model.datasetVersionId]
      : null;
    datasetVersion?.creator;
    const creatorName = datasetVersion ? datasetVersion?.creator : null;
    return filterModelByNameAndCreator(searchFilterText, model.modelName, creatorName);
  });

  const setFilterTextDebounced = useDebounce((text: string) => {
    setSearchFilterText(text);
  }, 200);

  const handleBaselineCandidateSwitch = () => {
    const { baseline: prevBaseline, candidate: prevCandidate } = comparisonSelectionInfo;
    setComparisonSelectionInfo({
      ...comparisonSelectionInfo,
      baseline: prevCandidate,
      candidate: prevBaseline,
    });
  };

  if (isModelListLoading) return <LoadingProgress />;

  return (
    <>
      {showOldModelsAlert && (
        <Box width="calc(100% - 70px)" marginBottom={2}>
          <Alert severity="info">
            <AlertTitle>
              {t(
                `Not seeing some models in the table? That's because the models below were trained before 2024. To include them, go to the {{buildPageLink}} page to deploy the model.`,
                {
                  buildPageLink: (
                    <Link
                      className={styles.linkText}
                      color="primary"
                      onClick={() => history.push(CLEF_PATH.data.dataBrowser)}
                    >
                      {t('Build')}
                    </Link>
                  ),
                },
              )}
            </AlertTitle>
          </Alert>
        </Box>
      )}
      <Box
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        display="flex"
        className={classNames(styles.newTopButtonGroup, styles.freTopButtonGroup)}
      >
        <Box marginX={3}>
          <ModelPerformanceMoreOptions />
        </Box>
      </Box>
      {!savedModels || savedModels.length === 0 ? (
        <Box marginTop={12}>
          <EmptyStateModelPage />
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            width="calc(100% - 70px)"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            marginBottom={4}
          >
            <Box width={320}>
              <BasicFilterSearchBar
                filterKey={searchFilterText}
                handleFilterKeyChange={setFilterTextDebounced}
                options={[]}
                placeholderText={'Search by model name, creator'}
                showSearchIcon={true}
              />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
              <Checkbox
                color="primary"
                checked={onlyShowFavoriteModelBundles}
                onChange={e => {
                  setOnlyShowFavoriteModelBundles(e.target.checked);
                }}
              />
              <Typography> {t('Only show favorite models')}</Typography>
            </Box>
          </Box>
          {savedModelsFilteredBySearchText.length === 0 ? (
            <EmptyStateModelPageAfterFiltering />
          ) : (
            <Provider>
              <ModelPerformanceTable
                savedModels={savedModelsFilteredBySearchText}
                enableComparison={enableComparisonSelection && !openComparisonDialog}
                onCompareModelsClick={(evaluationSet: EvaluationSetItem) => {
                  setEnableComparisonSelection(true);
                  handleSelectComparisonSet(evaluationSet);
                }}
                comparisonSelectionInfo={comparisonSelectionInfo}
                handleComparisonClick={handleComparisonClick}
              />
            </Provider>
          )}
          {enableComparisonSelection && !openComparisonDialog && (
            <ModelComparisonSelection
              comparisonSelectionInfo={comparisonSelectionInfo}
              onModelsSwitch={handleBaselineCandidateSwitch}
              onCancel={() => {
                setEnableComparisonSelection(false);
                setComparisonSelectionInfo({});
              }}
              onCreateReport={() => {
                setEnableComparisonSelection(false);
                setOpenComparisonDialog(true);
              }}
            />
          )}
          <ModelComparisonDialog
            open={openComparisonDialog}
            onClose={() => {
              setOpenComparisonDialog(false);
              setComparisonSelectionInfo({});
              setEnableComparisonSelection(false);
            }}
            comparisonSelectionInfo={comparisonSelectionInfo}
          />
        </>
      )}
    </>
  );
};

export default ModelsPageV2;

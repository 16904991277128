import React, { useState, useCallback } from 'react';
import { Dialog, DialogContent, DialogActions, Box, DialogTitle } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import { useAppDispatch } from '../../../store';
import { fetchUsers } from '../../../store/user_store';
import { removePendingUser } from '../../../hooks/api/useUserApi';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    marginBottom: theme.spacing(5),
  },
}));

interface RevokeMemberInvitationDialogProps {
  open: boolean;
  email: string;
  onClose: () => void;
}

export const RevokeMemberInvitationDialog: React.FC<RevokeMemberInvitationDialogProps> = ({
  open,
  email,
  onClose,
}) => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [isRevoking, setIsRevoking] = useState(false);

  const handleRemovePendingUser = useCallback(async () => {
    setIsRevoking(true);

    await removePendingUser(email)
      .then(() => {
        enqueueSnackbar(t('Successfully revoked user invite.'), {
          variant: 'success',
        });
        dispatch(fetchUsers());
      })
      .catch(() => {
        enqueueSnackbar(t('Failed revoking user'), {
          variant: 'error',
        });
      });

    onClose();

    setIsRevoking(false);
  }, [dispatch, email, enqueueSnackbar, onClose]);

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>{t('Revoke Invitation')}</DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <Box>
          {t('Are you sure you want to revoke the invitation for {{email}}?', {
            email,
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button id="cancel-revoke-invitation-button" color="primary" onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          disabled={isRevoking}
          onClick={handleRemovePendingUser}
          id="confirm-revoke-invitation-button"
        >
          {isRevoking ? t('Revoking...') : t('Revoke')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

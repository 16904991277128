import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useImperativeHandle,
  Ref,
  forwardRef,
  useRef,
} from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  useTheme,
  Menu,
  MenuItem,
  ListItemText,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ProjectRole, UserStatus, UserPermission, UserId, ProjectId } from '@clef/shared/types';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { isProjectOwner, isProjectLabeler, isOrgCollaborator } from '@clef/shared/utils';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MatTable from '../Utils/MatTable';
import { Options, Column } from '@material-table/core';
import { PERMISSION_SELECTOR_OPTIONS, ProjectRoleMap, PermissionMap } from './constants';
import { AnyUser, UserPartialUserProject } from './types';
import SearchBox from '../Text/SearchBox';

import { projectSettingWithUserRoleTableStyles } from './projectSettingWithUserRoleTableStyles';
import Actions, { ActionProps } from './Actions';
import { UserAvatar } from '../../components/UserAvatar/UserAvatarNew';
import { saveUserToProjectWithPermissionApi, editMemberRole } from '../../hooks/api/useProjectApi';
import { useImmer } from 'use-immer';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useDispatch } from 'react-redux';
import { useSetSelectedProjectId } from '@/serverStore/projects';
import { useExternalSearchText } from '../../hooks/MaterialTable';
import { getProjectRole, getDisplayPermissions, compareOwners } from './utils';

export type UserPermissions = {
  [userId: string]: {
    [permission in UserPermission]: boolean;
  };
};

interface Props {
  usersPartialUserProject: UserPartialUserProject[];
  pageSize?: number;
  showSelect?: boolean;
  showMoreActions?: boolean;
  allEditableRole?: boolean;
  showRoleErrors?: boolean;
  onSelectionChange?: (users: AnyUser[], rowUser?: AnyUser) => void;
  tableRef?: any;
  isInvite?: boolean;
  onPermissionChange?: (userPermissions: UserPermissions) => void;
  onUserRemovalStart?: (user: AnyUser) => void;
  onTransferOwnershipStart?: (user: AnyUser) => void;
}

type FilterQuery = {
  role?: ProjectRole | string;
  permission?: string;
  userName?: string;
};

export type ProjectSettingWithUserRoleTableRef = {
  clear: () => void;
};

function ProjectSettingWithUserRoleTable(
  props: Props,
  ref: Ref<ProjectSettingWithUserRoleTableRef>,
) {
  const {
    usersPartialUserProject,
    pageSize,
    showSelect,
    tableRef,
    isInvite = false,
    onSelectionChange,
    onUserRemovalStart,
    onTransferOwnershipStart,
  } = props;
  const styles = projectSettingWithUserRoleTableStyles();
  const [filterQuery, setFilterQuery] = useState<FilterQuery>({
    role: 'all',
    permission: 'all',
  });
  const [editingUserId, setEditingUserId] = useState<string | undefined>(undefined);
  const [roleSelectingUserId, setRoleSelectingUserId] = useState<string | undefined>(undefined);
  const [roleSelectingDropdownAnchorEl, setRoleSelectingDropdownAnchorEl] =
    useState<HTMLElement | null>(null);
  const [selectedRole, setSelectedRole] = useState<ProjectRole | undefined>(undefined);
  const [selectIds, setSelectIds] = useState<UserId[]>([]);
  const [checkedPermissionMap, dispatchPermissionMap] = useImmer<UserPermissions>(
    {} as UserPermissions,
  );
  const user = useTypedSelector(state => state.login.user!);

  const { data: selectedProject } = useGetSelectedProjectQuery();
  const dispatch = useDispatch();
  const setSelectedProjectId = useSetSelectedProjectId();

  const isOwner = useMemo(
    () =>
      isProjectOwner(
        selectedProject?.users?.find(findUser => user.id === findUser.id)?.userProject.role,
      ),
    [selectedProject?.users, user.id],
  );

  const _onSelectionChange = (users: AnyUser[], currentUser?: AnyUser): void => {
    if (onSelectionChange) {
      setSelectIds(users.map(user => user.id as UserId) || []);
      users.forEach(rowUser => {
        if (!isOrgCollaborator(rowUser.userRole)) {
          const checkedUser = users.find(user => user.id === currentUser?.id);
          if (checkedUser) {
            dispatchPermissionMap(draft => {
              draft[checkedUser.id] = Object.values(UserPermission).reduce(
                (acc, permission) => ({ ...acc, [permission]: true }),
                {},
              ) as Record<UserPermission, boolean>;
            });
          } else {
            dispatchPermissionMap(draft => {
              delete draft[currentUser!.id];
            });
          }
        }
      });
      onSelectionChange(users);
    }
  };

  useImperativeHandle(ref, () => {
    return {
      clear: () => {
        setSelectIds([]);
      },
    };
  });

  const onPermissionChange = useCallback(
    (userId: UserId, checked: boolean, permission: UserPermission) => {
      dispatchPermissionMap(draft => {
        draft[userId][permission] = checked;
      });
    },
    [dispatchPermissionMap],
  );

  useEffect(() => {
    props.onPermissionChange && props.onPermissionChange(checkedPermissionMap);
  }, [checkedPermissionMap, props]);

  const onPermissionManagementStart = useCallback(
    (user: AnyUser) => {
      setEditingUserId(user.id);
      dispatchPermissionMap(draft => {
        draft[user.id] = Object.values(UserPermission).reduce(
          (acc, permission) => ({
            ...acc,
            [permission]: !!user.userProject?.permissions?.includes(permission),
          }),
          {},
        ) as Record<UserPermission, boolean>;
      });
    },
    [dispatchPermissionMap],
  );

  const onPermissionManagementCancel = useCallback(() => {
    setEditingUserId(undefined);
  }, []);

  const onPermissionManagementEnd = useCallback(
    async (user: AnyUser) => {
      if (!selectedProject) return;
      const permissions = [
        {
          id: user.id,
          permissions: Object.entries(checkedPermissionMap[user.id] ?? ({} as UserPermissions))
            .filter(([, value]) => {
              return value;
            })
            .map(([key]) => key) as UserPermission[],
        },
      ];
      await saveUserToProjectWithPermissionApi(
        user.userProject?.projectId as ProjectId,
        permissions,
      );
      await setSelectedProjectId(
        selectedProject?.id,
        true, // shouldInvalidProjectQuery
      );
      setEditingUserId(undefined);
    },
    [checkedPermissionMap, dispatch, selectedProject?.id],
  );

  const onRoleManagementStart = useCallback((user: AnyUser) => {
    setRoleSelectingUserId(user.id);
    setSelectedRole(user.userProject?.role);
  }, []);

  const onRoleManagementCancel = useCallback(() => {
    setRoleSelectingUserId(undefined);
    setSelectedRole(undefined);
  }, []);

  const onRoleManagementEnd = useCallback(
    async (user: AnyUser) => {
      if (selectedRole && selectedProject?.id) {
        await editMemberRole(user.userProject?.projectId as ProjectId, user.id, selectedRole);
        setSelectedProjectId(
          selectedProject.id,
          true, // shouldInvalidProjectQuery
        );
      }
      setRoleSelectingUserId(undefined);
      setSelectedRole(undefined);
      return Promise.resolve();
    },
    [dispatch, selectedProject?.id, selectedRole],
  );

  const onDropdownButtonClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setRoleSelectingDropdownAnchorEl(event.currentTarget);
  }, []);

  const onDropdownButtonClose = useCallback(() => {
    setRoleSelectingDropdownAnchorEl(null);
  }, []);

  const actionBtns: Column<AnyUser>[] = [
    {
      title: t('Status'),
      editable: 'never',
      render: (user: AnyUser) => {
        // to filter out the sso pending user
        if (user.status === UserStatus.Active) {
          return t('Active');
        }
        return '--';
      },
      customSort: (user1: AnyUser, user2: AnyUser, _, sortDirection) => {
        // always put the owner at the top
        const ownerCompareResult = compareOwners(user1, user2);
        if (ownerCompareResult) {
          return sortDirection === 'desc' ? ownerCompareResult : -ownerCompareResult;
        }
        const status1 = user1.status === UserStatus.Active ? 'Active' : '--';
        const status2 = user2.status === UserStatus.Active ? 'Active' : '--';
        return status1.localeCompare(status2);
      },
    },
  ];
  if (isOwner) {
    actionBtns.push({
      title: t('Actions'),
      sorting: false,
      render(user: AnyUser) {
        // Skip showing action buttons if the current row is for owner
        if (isProjectOwner(user.userProject?.role)) {
          return null;
        }

        let actions: ActionProps[] = [];

        // Show manage permission button for standard user
        if (!isProjectLabeler(user.userProject?.role)) {
          actions = [
            {
              children: t('Manage permission'),
              onClick: () => onPermissionManagementStart(user),
              changeInlineUiOnClick: true,
              onInlineChangedUiCancel: onPermissionManagementCancel,
              onInlineChangedUiConfirm: () => onPermissionManagementEnd(user),
            },
          ];
        }

        // Show change role button, transfer ownership button, and remove button for
        // standard user and labeler
        actions.push({
          children: t('Change role'),
          onClick: () => onRoleManagementStart(user),
          changeInlineUiOnClick: true,
          onInlineChangedUiCancel: onRoleManagementCancel,
          onInlineChangedUiConfirm: () => onRoleManagementEnd(user),
        });
        actions.push({
          children: t('Transfer ownership'),
          onClick: () => onTransferOwnershipStart && onTransferOwnershipStart(user),
          changeInlineUiOnClick: false,
        });
        actions.push({
          children: <div className={styles.removeUserActionItem}>{t('Remove')}</div>,
          onClick: () => onUserRemovalStart && onUserRemovalStart(user),
          changeInlineUiOnClick: false,
        });

        return <Actions actions={actions} />;
      },
    });
  }

  const columns = useMemo(() => {
    let list: Column<AnyUser>[] = [
      { title: t('Id'), field: 'email', hidden: true, editable: 'never' },
      {
        title: t('Name'),
        render: (user: AnyUser) => {
          const userName = [user.name, user.lastName].filter(Boolean).join(' ');
          return (
            <Box display="flex" alignItems="center">
              {userName && <UserAvatar name={user.name} />}
              <Box className={styles.nameBox}>
                <Typography className={styles.name}>{userName}</Typography>
                <Typography data-testid="table-cell-email" className={styles.email}>
                  {user.email}
                </Typography>
              </Box>
            </Box>
          );
        },
        customFilterAndSearch: (searchText: string, user: AnyUser) => {
          return [user.name, user.lastName, user.email]
            .filter(Boolean)
            .join(' ')
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase());
        },
        customSort: (user1: AnyUser, user2: AnyUser, _, sortDirection) => {
          // always put the owner at the top
          const ownerCompareResult = compareOwners(user1, user2);
          if (ownerCompareResult) {
            return sortDirection === 'desc' ? ownerCompareResult : -ownerCompareResult;
          }
          const userName1 = [user1.name, user1.lastName].filter(Boolean).join(' ');
          const userName2 = [user2.name, user2.lastName].filter(Boolean).join(' ');
          return userName1.localeCompare(userName2);
        },
        editable: 'never',
      },
      {
        title: t('Project role'),
        field: 'projectRole',
        editable: 'never',
        render(user: AnyUser) {
          const role = getProjectRole(user);

          const editForm = (
            <Typography variant="button" align="center">
              <Button
                id="project_role_dropdown_menu"
                aria-controls="project_role_dropdown_menu"
                aria-haspopup="true"
                variant="outlined"
                color="primary"
                onClick={onDropdownButtonClick}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {t(selectedRole === ProjectRole.Standard ? 'Standard' : 'Labeler')}
              </Button>

              <Menu
                elevation={0}
                // Required for placing the menu item below the dropdown button
                // https://github.com/mui/material-ui/issues/7961
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                id="project_role_dropdown_menu"
                data-testid="project-role-dropdown-menu"
                keepMounted
                anchorEl={roleSelectingDropdownAnchorEl}
                open={!!roleSelectingDropdownAnchorEl}
                onClose={onDropdownButtonClose}
              >
                <MenuItem
                  onClick={() => {
                    setSelectedRole(ProjectRole.Standard);
                    onDropdownButtonClose();
                  }}
                >
                  <ListItemText primary={t('Standard')} />
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setSelectedRole(ProjectRole.Labeler);
                    onDropdownButtonClose();
                  }}
                >
                  <ListItemText primary={t('Labeler')} />
                </MenuItem>
              </Menu>
            </Typography>
          );

          return roleSelectingUserId === user.id ? (
            editForm
          ) : (
            <Box className={styles.projectRole}>{ProjectRoleMap[role]}</Box>
          );
        },
        customFilterAndSearch: (searchText: string, user: AnyUser) => {
          const role = getProjectRole(user).toLocaleLowerCase();
          return role.includes(searchText.toLocaleLowerCase());
        },
        customSort: (user1: AnyUser, user2: AnyUser, _, sortDirection) => {
          // always put the owner at the top
          const ownerCompareResult = compareOwners(user1, user2);
          if (ownerCompareResult) {
            return sortDirection === 'desc' ? ownerCompareResult : -ownerCompareResult;
          }
          const role1 = getProjectRole(user1);
          const role2 = getProjectRole(user2);
          return role1.localeCompare(role2);
        },
      },
      {
        title: t('Project permissions'),
        render: (user: AnyUser): React.ReactNode => {
          const permissions = (user as UserPartialUserProject).userProject?.permissions;
          const editForm = (
            <FormControl variant="outlined" className={styles.formControl}>
              <FormGroup className={styles.editPermissions}>
                {Object.keys(PERMISSION_SELECTOR_OPTIONS).map(permission => {
                  const permissionStr = PERMISSION_SELECTOR_OPTIONS[permission];
                  const defaultChecked = isInvite || permissions?.includes(permissionStr);
                  return (
                    <FormControlLabel
                      key={permission}
                      control={
                        <Checkbox
                          value={permission}
                          color="primary"
                          defaultChecked={defaultChecked}
                          onChange={(_, checked) => {
                            onPermissionChange(user.id, checked, permissionStr);
                          }}
                          data-testid="checkbox"
                        />
                      }
                      label={PermissionMap[permissionStr as UserPermission]}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          );
          if (isInvite) {
            if (!selectIds.includes(user.id as UserId)) {
              return <Box className={styles.editPermissions}>--</Box>;
            }
            return isOrgCollaborator(user.userRole) ? t('Can only label') : editForm;
          } else {
            return editingUserId === user.id ? (
              editForm
            ) : (
              <Box className={styles.permissionBox}>{getDisplayPermissions(user)}</Box>
            );
          }
        },
        sorting: !isInvite,
        customSort: (user1: AnyUser, user2: AnyUser, _, sortDirection) => {
          // always put the owner at the top
          const ownerCompareResult = compareOwners(user1, user2);
          if (ownerCompareResult) {
            return sortDirection === 'desc' ? ownerCompareResult : -ownerCompareResult;
          }
          const displayPermissions1 = getDisplayPermissions(user1);
          const displayPermissions2 = getDisplayPermissions(user2);
          return displayPermissions1.localeCompare(displayPermissions2);
        },
      },
    ];
    if (!isInvite) {
      list = list.concat(actionBtns);
    }
    return list;
  }, [
    actionBtns,
    roleSelectingDropdownAnchorEl,
    editingUserId,
    isInvite,
    onDropdownButtonClick,
    onDropdownButtonClose,
    onPermissionChange,
    roleSelectingUserId,
    selectIds,
    selectedRole,
    styles.commonBox,
    styles.editPermissions,
    styles.email,
    styles.formControl,
    styles.name,
    styles.nameBox,
    styles.permissionBox,
    styles.projectRole,
  ]);

  const theme = useTheme();

  let options: Options<AnyUser> = {
    pageSize,
    sorting: true,
    toolbar: false,
    paging: Boolean(pageSize),
    actionsColumnIndex: -1,
    actionsCellStyle: { width: '5%' },
    debounceInterval: 200,
    rowStyle: (row: AnyUser) => {
      if (row.id === editingUserId) {
        return {
          position: 'relative',
          zIndex: 12,
        };
      }
      if (row.id === user.id) {
        return {
          backgroundColor: theme.palette.primary.light + '0C',
        };
      }
      return {};
    },
  };
  if (showSelect) {
    options = {
      ...options,
      selection: true,
    };
  }

  const filterByPermission = useCallback(
    (user: AnyUser) => {
      if (!filterQuery.permission || filterQuery.permission === 'all') {
        return true;
      } else {
        const permissions = (user as UserPartialUserProject).userProject?.permissions as string[];
        const checkPermission = filterQuery.permission || '';
        return (permissions || []).includes(checkPermission);
      }
    },
    [filterQuery.permission],
  );

  const filterByRole = useCallback(
    (user: AnyUser) => {
      if (!filterQuery.role || filterQuery.role === 'all') {
        return true;
      }
      return getProjectRole(user) === filterQuery.role;
    },
    [filterQuery.role],
  );

  const allUsers = useMemo(
    () => usersPartialUserProject.map(item => ({ ...item })),
    [usersPartialUserProject],
  );
  const filteredUsers = useMemo(() => {
    // put owner on the top
    const _isProjectOwner = (user: AnyUser) => isProjectOwner(user.userProject?.role);
    const projectOwner = allUsers.find(_isProjectOwner);
    return [
      projectOwner,
      ...allUsers
        .filter(user => !_isProjectOwner(user))
        .filter(filterByPermission)
        .filter(filterByRole),
    ].filter(user => {
      const u = user as AnyUser;
      return Boolean(u) && (_isProjectOwner(u) || u.status === UserStatus.Active);
    }) as AnyUser[];
  }, [allUsers, filterByPermission, filterByRole]);

  const internalTableRef = useRef(null);
  const { setSearchText } = useExternalSearchText(tableRef ?? internalTableRef);

  const renderFilter = (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      className={styles.filterBox}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Select
          value={filterQuery.role}
          onChange={event =>
            setFilterQuery(prev => ({
              ...prev,
              role: event.target.value as ProjectRole,
            }))
          }
          className={styles.selectBox}
          displayEmpty
        >
          <MenuItem value="all">{t('All role')}</MenuItem>
          <MenuItem value={ProjectRole.Owner}>Owner</MenuItem>
          <MenuItem value={ProjectRole.Standard}>Standard</MenuItem>
          <MenuItem value={ProjectRole.Labeler}>Labeler</MenuItem>
        </Select>
        <Select
          value={filterQuery.permission}
          onChange={event =>
            setFilterQuery(prev => ({
              ...prev,
              permission: event.target.value as UserPermission,
            }))
          }
          className={styles.selectBox}
          displayEmpty
        >
          <MenuItem value="all">{t('All permissions')}</MenuItem>
          <MenuItem value={UserPermission.UploadData}>
            {PermissionMap[UserPermission.UploadData]}
          </MenuItem>
          <MenuItem value={UserPermission.TrainModel}>
            {PermissionMap[UserPermission.TrainModel]}
          </MenuItem>
          <MenuItem value={UserPermission.DeployModel}>
            {PermissionMap[UserPermission.DeployModel]}
          </MenuItem>
          <MenuItem value={UserPermission.DirectLabel}>
            {PermissionMap[UserPermission.DirectLabel]}
          </MenuItem>
        </Select>
      </Box>
      <Box className={styles.searchBox}>
        <SearchBox
          placeholder={t('Search users')}
          onInput={(e: React.FormEvent) => {
            setSearchText((e.target as HTMLInputElement).value);
          }}
        />
      </Box>
    </Box>
  );

  return (
    <Box
      className={styles.tableBox}
      data-testid={isInvite ? 'project-setting-table-invite' : 'project-setting-table'}
    >
      {!isInvite && renderFilter}
      {!!editingUserId && <div className={styles.tableBoxMask} />}
      <MatTable<AnyUser>
        title=""
        data={filteredUsers}
        columns={columns}
        onSelectionChange={_onSelectionChange}
        options={options}
        tableRef={tableRef ?? internalTableRef}
      />
    </Box>
  );
}

export default forwardRef(ProjectSettingWithUserRoleTable);

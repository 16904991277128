import { IconButton } from '@clef/client-library';
import { Box, CircularProgress, Dialog, Paper, makeStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React from 'react';
import MobileInferenceDialogContentEnabled from './MobileInferenceDialogContentEnabled';
import { usePublicEndpointByIdApi } from '@/hooks/api/usePublicEndpointApi';
import MobileInferenceDialogContentDisabled from './MobileInferenceDialogContentDisabled';

const useStyles = makeStyles(() => ({
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

type MobileInferenceDialogProps = {
  endpointId?: string;
  inferenceCost: number;
  onClose?: () => void;
};

const MobileInferenceDialog: React.FC<MobileInferenceDialogProps> = props => {
  const { endpointId, onClose, inferenceCost } = props;
  const styles = useStyles();
  const [endpoint, loading] = usePublicEndpointByIdApi(endpointId);
  return (
    <Dialog open onClose={onClose}>
      <Paper>
        <Box
          padding={7}
          position="relative"
          display="flex"
          flexDirection="column"
          alignItems="center"
          width={480}
        >
          <IconButton id="close-dialog" className={styles.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
          {(loading || !endpoint) && (
            <Box
              width={120}
              height={120}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress size={24} />
            </Box>
          )}
          {!loading &&
            !!endpoint &&
            (endpoint.enable ? (
              <MobileInferenceDialogContentEnabled
                inferenceCost={inferenceCost}
                endpointId={endpointId}
              />
            ) : (
              <MobileInferenceDialogContentDisabled endpointId={endpointId} />
            ))}
        </Box>
      </Paper>
    </Dialog>
  );
};

export default MobileInferenceDialog;

import { CacheStorage, CacheStorageKey } from '@clef/client-library';

export const projectIdCache = new CacheStorage<number>({
  key: CacheStorageKey.ProjectId,
  persist: true,
});

/**
 * /app/profile => ${currentPath}/:orgId/profile
 * @param currentPath current path from useRouteMatch
 * @param legacyRoute the legacy route from ./src/constants/path.ts
 */
export const transformLegacyPathWithOrgId = (currentPath: string, legacyRoute: string) =>
  `${currentPath}/:orgId${legacyRoute.replace('/app', '')}`;

/**
 * /app/profile => ${currentPath}/123/profile
 * @param currentPath current path from useRouteMatch
 * @param legacyRoute the legacy route from ./src/constants/path.ts
 */
export const transformLegacyUrlWithOrgId = (legacyRoute: string, orgId: number) =>
  `/app/${orgId}${legacyRoute.replace('/app', '')}`;

/**
 * /app/overview => ${currentPath}/overview
 * @param currentPath current path from useRouteMatch
 * @param legacyRoute the legacy route from ./src/constants/path.ts
 */
export const transformLegacyPath = (currentPath: string, legacyRoute: string) =>
  legacyRoute.replace('/app', currentPath).replace('//', '/');

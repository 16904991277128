import React from 'react';
import { TextField } from '@material-ui/core';
import { useDeviceMonitorState } from '../deviceMonitorState';

export interface LocationIdFilterProps {}

const LocationIdFilter: React.FC<LocationIdFilterProps> = () => {
  const {
    state: { filterLocationId },
    dispatch,
  } = useDeviceMonitorState();
  return (
    <TextField
      value={filterLocationId}
      onChange={event => {
        const value = event.target.value.trim();
        dispatch(draft => {
          draft.filterLocationId = value;
        });
      }}
    />
  );
};

export default LocationIdFilter;

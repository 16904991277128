import { Button, Typography } from '@clef/client-library';
import { Typography as MuiTypography } from '@material-ui/core';
import { Box, Paper } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@clef/client-library';
import ContactUsDialog from '@/pages/OrgnizationSettings/components/ContactUsDialog';
import { useHistory } from 'react-router-dom';
import CLEF_PATH from '@/constants/path';
import { useStyles } from './style';
import { SharedDialogProps } from '.';

type ReachActiveProjectLimitDialogProps = SharedDialogProps;

const ReachActiveProjectLimitDialog = (props: ReachActiveProjectLimitDialogProps) => {
  const { open, onClose } = props;
  const styles = useStyles();
  const history = useHistory();
  const [contactUsDialogOpen, setContactUsDialogOpen] = useState(false);
  return (
    <>
      <ContactUsDialog
        open={contactUsDialogOpen}
        onClose={() => {
          setContactUsDialogOpen(false);
        }}
      />
      <Dialog open={open} onClose={onClose} classes={{ paper: styles.paper }}>
        <Paper>
          <Box display="flex" flexDirection={'column'}>
            <Box padding={5} display="flex" flexDirection={'row'} justifyContent={'space-between'}>
              <Box>
                <Typography variant="h2_semibold">{t('Active Project Limit Reached')}</Typography>
              </Box>
              <IconButton
                id="close-browser-detection-snack-bar"
                size="small"
                aria-label="close"
                color="inherit"
                onClick={onClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box paddingX={5}>
              <Typography variant="body_regular">
                {t(
                  'Manage active projects on the {{activeProjectsPage}} page, or contact us to purchase more active projects.',
                  {
                    activeProjectsPage: (
                      <MuiTypography
                        component={'span'}
                        className={styles.linkText}
                        onClick={() => {
                          onClose();
                          history.push(CLEF_PATH.organizationSettings + '?tab=activeProject');
                        }}
                      >
                        {t('Active Projects')}
                      </MuiTypography>
                    ),
                  },
                )}
              </Typography>
            </Box>
            <Box padding={5} width="100%">
              <Button
                className={styles.button}
                id={'contact-us'}
                color="primary"
                variant="contained"
                onClick={() => {
                  onClose();
                  setContactUsDialogOpen(true);
                }}
              >
                {t('Contact Us')}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Dialog>
    </>
  );
};

export default ReachActiveProjectLimitDialog;

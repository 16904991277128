import React, { useCallback } from 'react';
import { Metadata, MetadataObjectValue, MetadataType, MetadataValueType } from '@clef/shared/types';
import { Chip, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SetMetadataPredefinedChoices from '../../../../components/SetMetadata/SetMetadataPredefinedChoices';
import SetMetadataRawText from '../../../../components/SetMetadata/SetMetadataRawText';
import { attachMetadataToSingleMedia } from '../../../../hooks/api/useMetadataApi';
import { useSnackbar } from 'notistack';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '& $solo': {
      opacity: 0,
    },
    '&:hover': {
      '& $solo': {
        opacity: 1,
      },
    },
  },
  inlineBlock: {
    display: 'inline-flex',
  },
  name: {
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    minWidth: 100,
  },
  value: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'inline-block',
    maxWidth: '100%',
    '& span': {
      display: 'inline-block',
      maxWidth: '100%',
    },
  },
  valueBox: {
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  solo: {
    marginLeft: theme.spacing(2),
    cursor: 'pointer',
  },
  rightValue: {
    width: `calc(100% - 120px)`,
    overflow: 'hidden',
  },
}));

export const NameView: React.FC<{ name: string }> = ({ name }) => {
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      data-testid={`media-viewer-metadata-key-${name}`}
      className={classes.name}
      component="div"
      title={name}
    >
      {name}
    </Typography>
  );
};

interface ValueViewProps {
  value?: MetadataObjectValue;
  type: MetadataType;
}

export const ValueView: React.FC<ValueViewProps> = ({ value, type }) => {
  const classes = useStyles();
  if (value === undefined) return <></>;

  return (
    <>
      {Array.isArray(value) ? (
        value.map((v: any) => <ValueView key={v} value={v} type={type} />)
      ) : (
        <Chip
          size="small"
          variant="outlined"
          component="span"
          title={`${value}`}
          label={
            <Typography
              className={classes.valueBox}
              variant="body2"
              data-testid={`media-details-metadata-value-${value}`}
            >
              {type === MetadataType.Boolean ? t(String(value)) : value}
            </Typography>
          }
          className={classes.value}
          clickable={false}
        />
      )}
    </>
  );
};

type EditMetadataProps = {
  value?: MetadataObjectValue;
  metadata: Metadata;
  mediaId: number;
};

export const EditMetadata: React.FC<EditMetadataProps> = ({ metadata, value, mediaId }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();

  const handleSetMetadata = useCallback(
    async (value: string[] | number[]) => {
      let valueReformatted: MetadataValueType;
      if (metadata.type === MetadataType.Boolean) {
        valueReformatted = value?.[0] === t('true');
      } else if (!metadata.allowMultiple) {
        valueReformatted = metadata.type === MetadataType.Number ? Number(value?.[0]) : value?.[0];
      } else {
        valueReformatted =
          metadata.type === MetadataType.Number
            ? (value as string[]).map(val => Number(val))
            : value;
      }
      try {
        if (!selectedProject) {
          return;
        }
        await attachMetadataToSingleMedia(
          mediaId,
          {
            [metadata.id]: valueReformatted,
          },
          undefined,
          selectedProject,
          currentModelId,
        );
        enqueueSnackbar(t('Metadata updated'), { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [
      enqueueSnackbar,
      mediaId,
      metadata.allowMultiple,
      metadata.id,
      metadata.type,
      selectedProject,
      currentModelId,
    ],
  );

  const EditButtonText = useCallback(
    () => (
      <Typography variant="body2" component="a" color="primary" className={classes.solo}>
        {t('Edit')}
      </Typography>
    ),
    [classes.solo],
  );

  if (metadata.predefinedChoices || metadata.type === MetadataType.Boolean) {
    const givenChoices: string[] =
      metadata.type === MetadataType.Boolean
        ? [t('true'), t('false')]
        : metadata.predefinedChoices || [];
    return (
      <>
        <SetMetadataPredefinedChoices
          key={metadata.id}
          defaultValue={metadata.type === MetadataType.Boolean ? t(String(value)) : value}
          predefinedChoices={givenChoices}
          allowMultiple={metadata.allowMultiple}
          placement="right"
          extraGutter={{ horizontal: -22, vertical: -4 }}
          applyMetadata={handleSetMetadata}
        >
          {EditButtonText}
        </SetMetadataPredefinedChoices>
      </>
    );
  } else {
    return (
      <>
        <SetMetadataRawText
          key={metadata.id}
          defaultValue={value}
          allowMultiple={metadata.allowMultiple}
          placement="right"
          extraGutter={{ horizontal: -22, vertical: -4 }}
          isNumber={metadata.type === MetadataType.Number}
          applyMetadata={handleSetMetadata}
        >
          {EditButtonText}
        </SetMetadataRawText>
      </>
    );
  }
};

const MetadataView: React.FC<EditMetadataProps> = ({ metadata, value, mediaId }) => {
  const classes = useStyles();
  const { name, type } = metadata;
  const { enqueueSnackbar } = useSnackbar();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();

  const handleRemoveMetadata = useCallback(async () => {
    try {
      if (!selectedProject) {
        return;
      }
      await attachMetadataToSingleMedia(
        mediaId,
        // @ts-ignore allow setting value undefined for deleting metadata
        { [metadata.id]: undefined },
        undefined,
        selectedProject,
        currentModelId,
      );
      enqueueSnackbar(t('Metadata removed'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [enqueueSnackbar, mediaId, metadata.id, currentModelId, selectedProject]);

  return (
    <Grid container alignItems="flex-start" wrap="nowrap" className={classes.container}>
      <Grid item style={{ width: 120 }}>
        <NameView name={name} />
      </Grid>
      <Grid item className={classes.rightValue}>
        <ValueView value={value} type={type} />
        <div className={classes.inlineBlock}>
          <EditMetadata metadata={metadata} value={value} mediaId={mediaId} />
          <Typography
            variant="body2"
            component="a"
            color="secondary"
            className={classes.solo}
            onClick={handleRemoveMetadata}
            id="delete-metadata-from-image"
          >
            {t('Delete')}
          </Typography>
        </div>
        <div className={classes.inlineBlock}></div>
      </Grid>
    </Grid>
  );
};

export default MetadataView;

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core';
import { calcAnnotationTextPositions, PositionPriority } from './util';
import { isDark } from '@clef/shared/utils';
import { truncate } from 'lodash';

export interface MediaViewerBoxAnnotationProps {
  color: string;
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
  imageHeight: number;
  imageWidth: number;
  text?: string;
  highlighted?: boolean;
  scale?: number;
  dashed?: boolean;
  isErrorRegion?: boolean;
  textPositionPriorities?: PositionPriority[];
}

const useStyles = makeStyles(theme => ({
  boxSvg: {
    position: 'absolute',
    zIndex: 99,
  },
  basicBoxStyle: {
    borderStyle: 'solid',
    position: 'absolute',
    zIndex: 99,
  },
  basicTextStyle: {
    position: 'absolute',
    zIndex: 101,
    fontWeight: 700,
  },
  basicBoxBorderStyle: {
    zIndex: 100,
    '&:before': {
      content: "''",
      position: 'absolute',
      zIndex: -1,
      top: -4,
      left: -4,
      right: -4,
      bottom: -4,
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
}));

const MediaViewerBoxAnnotation: React.FC<MediaViewerBoxAnnotationProps> = React.memo(
  ({
    color,
    xMin,
    xMax,
    yMin,
    yMax,
    imageHeight,
    imageWidth,
    text,
    highlighted,
    scale = 1,
    isErrorRegion, // isErrorRegion is true, Rounded bounding box with solid border will be shown
    dashed = false,
    textPositionPriorities,
  }) => {
    if (text) {
      text = truncate(text, { length: 25 });
    }

    const styles = useStyles();
    const borderWidth = 3 / scale;

    const svgStyles: React.CSSProperties = {
      top: yMin,
      left: xMin,
      height: yMax - yMin,
      width: xMax - xMin,
      overflow: 'visible',
    };
    const svgBoxProps: React.SVGProps<SVGRectElement> = {
      stroke: color,
      strokeWidth: highlighted ? borderWidth * 1.5 : borderWidth,
      y: 0,
      x: 0,
      height: yMax - yMin,
      width: xMax - xMin,
      strokeDasharray: dashed ? `${5 / scale},${4 / scale}` : 'none',
      rx: isErrorRegion ? '50%' : '0',
      fill: 'none',
    };

    const [svgTextPosition, svgTextBoxPosition] = calcAnnotationTextPositions(
      {
        xMin,
        xMax,
        yMin,
        yMax,
        imageHeight,
        imageWidth,
        scale,
        text: text || '',
      },
      textPositionPriorities,
    );

    const highContrastColor = isDark(color) ? '#ffffff' : '#000000';

    return (
      <>
        {/*
          Why not using div?
          Because div truncates border width to integer and displays incorrectly after scaled.
          Using SVG rect instead to support floating point border (stroke) width.
        */}
        <svg
          className={cx(styles.boxSvg)}
          style={svgStyles}
          data-testid={dashed ? 'media-bbox-dashed-annotation' : 'media-bbox-annotation'}
        >
          <rect {...svgBoxProps} />
          {text && (
            <g>
              <rect
                fill={color}
                stroke={highContrastColor}
                strokeWidth={1 / scale}
                strokeDasharray={dashed ? `${4 / scale},${2 / scale}` : 'none'}
                rx={4 / scale}
                {...svgTextBoxPosition}
              />
              <text
                fontWeight={700}
                fontSize={12 / scale}
                fill={highContrastColor}
                {...svgTextPosition}
              >
                {text}
              </text>
            </g>
          )}
        </svg>
      </>
    );
  },
);

export default MediaViewerBoxAnnotation;

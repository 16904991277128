import React, { useCallback } from 'react';
import { Typography, Checkbox, Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDatasetDefectTotalNumbers } from '@/hooks/api/useDatasetApi';
import { SelectMediaOption } from '@clef/shared/types';
import StatsCardDefectTotalCount from '../StatsCard/StatsCardDefectTotalCount';
import { useAutoSplitStyles } from './styles';
import StepContainer from './StepContainer';
import { useAutoSplitState } from './state';
import { useDatasetMediaCountQuery } from '@/serverStore/dataset';

export interface Step1MediaSelectionProps {
  selectMediaOption?: SelectMediaOption;
}

const Step1MediaSelection: React.FC<Step1MediaSelectionProps> = ({ selectMediaOption }) => {
  const styles = useAutoSplitStyles();
  const { state, dispatch } = useAutoSplitState();
  const { includeAlreadySplitMedia } = state;

  const { data: totalMediaCount } = useDatasetMediaCountQuery(
    {
      selectOptions: selectMediaOption,
    },
    !!selectMediaOption,
  );

  const defectTotalNumberStats = useDatasetDefectTotalNumbers(
    selectMediaOption,
    !!selectMediaOption,
  );

  const onToggleIncludeAlreadySplitMedia = useCallback(() => {
    dispatch(draft => {
      draft.includeAlreadySplitMedia = !draft.includeAlreadySplitMedia;
    });
  }, [dispatch]);

  return (
    <StepContainer
      primaryButton={{
        text: 'Next',
        action: () =>
          dispatch(draft => {
            draft.currentStep = 1;
          }),
        // continue to auto split when there are available media and defects created
        disabled: !totalMediaCount || !defectTotalNumberStats,
      }}
    >
      <section className={styles.infoSection}>
        <Typography variant="subtitle1" component="div" className={styles.headerText} gutterBottom>
          {t('Image selection')}
        </Typography>
        <Alert className={styles.alerts} severity="info">
          {t(
            'By default, all {{approvedText}} with {{noSplitAssignedText}} images will be selected for auto splitting.',
            {
              approvedText: <strong>{t('approved')}</strong>,
              noSplitAssignedText: <strong>{t('no split assigned')}</strong>,
            },
          )}
        </Alert>
        <div className={styles.checkBoxContainer}>
          <Checkbox
            data-testid="auto-split-reassign-split-checkbox"
            color="primary"
            size="small"
            checked={includeAlreadySplitMedia}
            onClick={onToggleIncludeAlreadySplitMedia}
          />
          <Typography
            variant="body2"
            component="span"
            className={styles.checkBoxCaption}
            onClick={onToggleIncludeAlreadySplitMedia}
          >
            {t('Include assigned train/dev/test media to reassigned')}
          </Typography>
        </div>
      </section>
      <Divider />

      <section className={styles.infoSection}>
        <Typography variant="subtitle1" component="div" className={styles.headerText} gutterBottom>
          {t('Total number of images to split')}
        </Typography>
        {totalMediaCount === 0 ? (
          <Alert className={styles.alerts} severity="error">
            {t('No images available to split.')}
          </Alert>
        ) : (
          <Typography variant="subtitle1" component="div">
            {totalMediaCount ?? t('-')}
          </Typography>
        )}
      </section>
      <Divider />
      <section className={styles.infoSection}>
        <StatsCardDefectTotalCount allowEmpty={false} selectMediaOptions={selectMediaOption} />
      </section>
    </StepContainer>
  );
};

export default Step1MediaSelection;

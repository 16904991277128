type ApiErrorConstructor = {
  api: string;
  code?: number;
  method: string;
  typeError?: TypeError; // likely cors
  message?: string;
};

export class ApiError extends Error {
  code?: number;
  api: string;
  method: string;
  message: string;

  constructor({ api, code, typeError, method, message = '' }: ApiErrorConstructor) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super();

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = 'ApiError';
    this.api = api;
    this.code = code;
    this.method = method;

    if (typeError) {
      this.message = `${typeError.name} - ${message ?? typeError.message}: ${method} ${api}`;
    } else {
      this.message = `Fetch failure ${code ?? ''}: ${method} ${api} - ${message}`;
    }
  }
}

export class ReactDomOperationError extends Error {
  constructor(...args: Parameters<ErrorConstructor>) {
    super(...args);
    this.name = 'ReactDomOperationError';
  }
}

export class UnauthorizedError extends Error {
  apiError?: Error;
  constructor(message?: string, error?: Error) {
    super(message);
    this.name = 'UnauthorizedError';
    this.apiError = error;
  }
}

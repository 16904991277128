import { BaseAPI } from './base_api';
import { SelectMediaOption } from '@clef/shared/types/dataset';
import { MetadataFormattedValue } from '@clef/shared/types/store';
import { Project } from '@clef/shared/types';

class MetadataObjectAPI extends BaseAPI {
  async getMetadataOfObject(projectId: number, objectId: number, objectType: string) {
    return this.get(
      'metadata',
      {
        projectId,
        objectId: objectId,
        objectType: objectType,
      },
      true,
    );
  }

  async attachMetadataToMedia(projectId: number, mediaId: number, metadata: any) {
    return this.attachMetadataToObject(projectId, mediaId, 'media', metadata);
  }

  async attachMetadataToObject(
    projectId: number,
    objectId: number,
    objectType: string,
    metadata: any,
  ) {
    return this.postJSON('metadata', {
      projectId,
      objectId: objectId,
      objectType: objectType,
      metadata: metadata,
    });
  }

  async attachMetadataToMedias(
    project: Project,
    selectOption: SelectMediaOption,
    metadata: MetadataFormattedValue,
  ) {
    return this.postJSON('medias_metadata', {
      project,
      selectOption,
      metadata,
    });
  }
}

export default new MetadataObjectAPI('object');

import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import Konva from 'konva';
import { Circle } from 'react-konva';
import { Position, PureCanvasAnnotation } from '@clef/shared/types';
import { PureCanvasAnnotationComponent } from '../components/Shapes';
import {
  arrayToImageData,
  calcAccurateOffset,
  imageDataToOffscreenCanvas,
  offscreenCanvasWithNewColor,
} from '../utils';
import { AnnotationCreatorBuilder } from '../types';
import useMemento from '../../../hooks/useMemento';
import useThrottle from '../../../hooks/useThrottle';
import { ModelInput, ModelInputClickType } from '../../../utils/sam_model';
import { hexToRgb } from '../../../utils/color';
import { DefectCreatorRef } from '../components/DefectCreator';
import MinusCircleSvg from '../../../images/sam/minus_circle.svg';
import PlusCircleSvg from '../../../images/sam/plus_circle.svg';
import { useSAM } from '../hooks/useSAM';

const DEFAULT_SAM_INFERENCE_PREVIEW_COLOR = '#FFFFFF';
const BASE_PROMPT_DOT_RADIUS = 5;

const adjustMousePosition = <T extends Position | undefined>(position: T) => {
  return {
    x: position ? Math.floor(position.x) : undefined,
    y: position ? Math.floor(position.y) : undefined,
  } as T extends Position
    ? Position
    : {
        x: undefined;
        y: undefined;
      };
};

export interface ModelScale {
  samScale: number;
  height: number;
  width: number;
}

type SAMAnnotationDrawHook = (options: {
  disabled: boolean;
  color?: string;
  opacity: number;
  onMissingColor?: () => void;
  onFinishAnnotation: (canvas: PureCanvasAnnotation) => void;
  onIsWorkingInProgressChanged?: (wip: boolean) => void;
  onError?: (error: any) => void;
  imageSize:
    | {
        height: number;
        width: number;
      }
    | null
    | undefined;
  onnxModel: ArrayBuffer | undefined;
  imageEmbedding: ArrayBuffer | undefined;
  zoomScale: number;
  defectCreator: DefectCreatorRef | null | undefined;
}) => AnnotationCreatorBuilder;

export const useSAMAnnotationCreator: SAMAnnotationDrawHook = ({
  disabled,
  color,
  opacity,
  onMissingColor,
  onFinishAnnotation,
  onIsWorkingInProgressChanged,
  onError,
  imageSize,
  onnxModel,
  imageEmbedding,
  zoomScale,
  defectCreator,
}) => {
  const { runModel } = useSAM({
    imageSize,
    onnxModel,
    imageEmbedding,
    onError,
  });

  // Canvas annotation for rendering the SAM mask in the canvas
  const [samAnnotation, setSamAnnotation] = useState<PureCanvasAnnotation | null>(null);

  // Result object for determining if a mouse position is on the SAM mask
  const [samResult, setSamResult] = useState<{
    data: number[] | null;
    width: number | null;
    height: number | null;
    type: 'preview' | 'labeling';
  }>({
    data: null,
    width: null,
    height: null,
    type: 'preview',
  });

  const {
    current: samPrompts,
    set: setSAMPrompts,
    reset: resetSAMPrompts,
    undo: undoSAMPrompts,
    redo: redoSAMPrompts,
    canUndo: canUndoSAMPrompts,
    canRedo: canRedoSAMPrompts,
  } = useMemento<ModelInput[]>([]);
  const [customCursor, setCustomCursor] = useState<string | null>(null);

  // It's in SAM preview mode if no active click prompts are provided and there are no undoable/redoable prompts
  // Otherwise, it's in SAM labeling mode
  const isSAMPreviewMode = !samPrompts.length && !canUndoSAMPrompts && !canRedoSAMPrompts;

  const clearSamAnnotations = useCallback(() => {
    setSamAnnotation(null);
    setSamResult({
      data: null,
      width: null,
      height: null,
      type: 'preview',
    });
  }, []);

  const onDiscard = useCallback(() => {
    // Clear SAM prompts, custom cursor, and annotations
    resetSAMPrompts([]);
    setCustomCursor(null);
    clearSamAnnotations();
    onIsWorkingInProgressChanged?.(false);
  }, [clearSamAnnotations, onIsWorkingInProgressChanged, resetSAMPrompts]);
  const onDiscardRef = useRef(onDiscard);
  onDiscardRef.current = onDiscard;

  // Reset SAM-related states when image embedding is changed
  // Note, do not rely on image srouce as it can be the thumbnail, which does not affect the SAM
  useEffect(() => {
    onDiscardRef.current();
  }, [imageEmbedding]);

  useEffect(() => {
    // If there is no prompts and it is in create-defect mode, reset back to label-with-defect mode
    if (!disabled && defectCreator?.mode === 'create-defect' && !samPrompts.length) {
      defectCreator.setMode('label-without-defect');
    }
  }, [defectCreator, disabled, samPrompts]);

  const updateSamAnnotations = useCallback(
    (prompts: ModelInput[], type: 'preview' | 'labeling' = 'preview') => {
      if (!prompts.length) {
        setSamAnnotation(null);
        return;
      }

      runModel(prompts, type === 'labeling').then(resultTensor => {
        if (!resultTensor) {
          return;
        }

        const [, , maskHeight, maskWidth] = resultTensor.dims;

        setSamResult({
          data: resultTensor.data as unknown as number[],
          width: maskWidth,
          height: maskHeight,
          type,
        });

        setSamAnnotation({
          x: 0,
          y: 0,
          canvas: imageDataToOffscreenCanvas(
            arrayToImageData({
              array: resultTensor.data as unknown as number[],
              height: maskHeight,
              width: maskWidth,
              color: hexToRgb(DEFAULT_SAM_INFERENCE_PREVIEW_COLOR),
            }),
          ),
          opacity,
          width: imageSize?.width,
          height: imageSize?.height,
          color,
        });
      });
    },
    [color, imageSize?.height, imageSize?.width, opacity, runModel],
  );

  const updateSamAnnotationsThrottled = useThrottle(updateSamAnnotations, 100, {
    trailing: true,
  });

  // [NOTE]
  // We intentionally use ref to the samResult, so that samResult is only holded by this ref.
  // Before this change, samResult will be referenced by lots of callbacks and does not get garbage collected.
  // And because samResult.data is large (can be up to 16MB for a 2048x2048 image), the memory keeps increasing
  // when drawing with smart labeling.
  const samResultRef = useRef(samResult);
  samResultRef.current = samResult;
  const checkSamAnnotationsOverlapping = useCallback(
    (position: Position, threshold: number = 0.0) => {
      const samResult = samResultRef.current;
      if (
        samResult.type === 'preview' ||
        !samResult.data ||
        !samResult.width ||
        !samResult.height
      ) {
        return false;
      }

      const i = position.x + position.y * samResult.width;
      return samResult.data[i] > threshold;
    },
    [],
  );

  const onMouseMove = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      // If disabled, we do not allow changes to the prompts and annotations
      if (disabled) {
        return;
      }

      const position = calcAccurateOffset(e);
      const { x, y } = adjustMousePosition(position);

      // If cursor is undefined or outside the image, clear either SAM mask or custom cursor style
      if (
        x === undefined ||
        y === undefined ||
        x < 0 ||
        (imageSize?.width && x > imageSize?.width) ||
        y < 0 ||
        (imageSize?.height && y > imageSize?.height)
      ) {
        if (isSAMPreviewMode) {
          clearSamAnnotations();
        } else {
          setCustomCursor(null);
        }

        return;
      }

      // In SAM preview mode, show the preview
      if (isSAMPreviewMode) {
        const previewPrompt = {
          x,
          y,
          clickType: ModelInputClickType.Add,
        };

        updateSamAnnotationsThrottled([previewPrompt]);
      }
      // Otherwise, show the different cursor
      else {
        const isOverlappedWithSamAnno = checkSamAnnotationsOverlapping({ x, y });
        setCustomCursor(
          isOverlappedWithSamAnno
            ? // "8, 8" is to center the cursor svg
              `url(${MinusCircleSvg}) 8 8, not-allowed`
            : `url(${PlusCircleSvg}) 8 8, copy`,
        );
      }
    },
    [
      disabled,
      imageSize?.width,
      imageSize?.height,
      isSAMPreviewMode,
      clearSamAnnotations,
      updateSamAnnotationsThrottled,
      checkSamAnnotationsOverlapping,
    ],
  );

  const onMouseClick = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      // If disabled, we do not allow changes to the prompts and annotations
      if (disabled) {
        return;
      }

      if (onMissingColor && !color) {
        onMissingColor();
        return;
      }

      const position = calcAccurateOffset(e);

      if (
        !position ||
        position.x < 0 ||
        position.y < 0 ||
        position.x > (imageSize?.width ?? 0) ||
        position.y > (imageSize?.height ?? 0)
      ) {
        return;
      }

      const { x, y } = adjustMousePosition(position);
      const isOverlappedWithSamAnno = checkSamAnnotationsOverlapping({ x, y });

      setSAMPrompts(prompts => {
        const newPrompts = [
          ...prompts,
          {
            x,
            y,
            clickType: isOverlappedWithSamAnno
              ? ModelInputClickType.Remove
              : ModelInputClickType.Add,
          },
        ];

        updateSamAnnotations(newPrompts, 'labeling');
        onIsWorkingInProgressChanged?.(true);
        return newPrompts;
      });
    },
    [
      disabled,
      onMissingColor,
      color,
      imageSize?.width,
      imageSize?.height,
      checkSamAnnotationsOverlapping,
      setSAMPrompts,
      updateSamAnnotations,
      onIsWorkingInProgressChanged,
    ],
  );

  const saveSAMAnnotations = useCallback(
    (canvasAnnotation: PureCanvasAnnotation | null, color?: string) => {
      // To successfully save the SAM annotations, the associated class must be created prior to calling this function
      if (canvasAnnotation) {
        onFinishAnnotation(
          color
            ? {
                ...canvasAnnotation,
                canvas: offscreenCanvasWithNewColor(canvasAnnotation.canvas, color),
                color,
              }
            : canvasAnnotation,
        );

        onDiscard();
      }
    },
    [onDiscard, onFinishAnnotation],
  );

  const onFinish = useCallback(
    (customColor?: string) => {
      if (samAnnotation && !isSAMPreviewMode) {
        // If defect creator is enabled, then change its mode to "create-defect" for the first time to wait for the
        // class creation
        if (defectCreator) {
          defectCreator?.setMode('create-defect');
        }
        // If defect creator is not enabled, then save the SAM annotations
        else if (customColor ?? color) {
          saveSAMAnnotations(samAnnotation, customColor ?? color);
        }
      }
    },
    [samAnnotation, isSAMPreviewMode, defectCreator, color, saveSAMAnnotations],
  );

  return {
    creatorPreviewComponent: samAnnotation ? (
      <PureCanvasAnnotationComponent {...samAnnotation} />
    ) : null,
    creatorHelperComponent: (
      <>
        {samPrompts.map(prompt => {
          const { x, y, clickType } = prompt;

          return (
            <Circle
              key={`${x}-${y}`}
              x={x}
              y={y}
              radius={BASE_PROMPT_DOT_RADIUS / zoomScale}
              fill={clickType === ModelInputClickType.Add ? '#CFF000' : '#AD2E24'}
            />
          );
        })}
      </>
    ),
    creatorInteractiveProps: {
      onMouseMove,
      onClick: onMouseClick,
    },
    creatorCustomHandlers: {
      onEscPressed: onDiscard,
      onEnterPressed: onFinish,
      isWorkInProgress: () => !isSAMPreviewMode && !!samAnnotation,
      onUndo: useMemo(() => {
        return canUndoSAMPrompts
          ? () => {
              const prompts = undoSAMPrompts();
              updateSamAnnotations(prompts, 'labeling');

              // If there is no prompt, reset back to the initial state
              if (!prompts.length) {
                onDiscard();
              }
            }
          : undefined;
      }, [canUndoSAMPrompts, onDiscard, undoSAMPrompts, updateSamAnnotations]),
      onRedo: useMemo(() => {
        return canRedoSAMPrompts
          ? () => {
              const prompts = redoSAMPrompts();
              updateSamAnnotations(prompts, 'labeling');
            }
          : undefined;
      }, [canRedoSAMPrompts, redoSAMPrompts, updateSamAnnotations]),
    },
    customCursor: disabled ? undefined : customCursor,
    clearWipAnnotations: onDiscard,
  };
};

import {
  defaultState,
  LabelingContext,
  ToolMode,
  useLabelingState,
} from '@/components/Labeling/labelingState';
import { DialogContextProvider } from '@/components/Layout/components/useDialog';
import SnackbarCloseButton from '@/components/SnackbarCloseButton';
import {
  useZeroAuthLoginApi,
  useZeroAuthProjectApi,
  useZeroAuthProjectsApi,
} from '@/hooks/api/useZeroAuthApi';
import {
  ApiResponseLoader,
  MediaInteractiveCanvas,
  useSnackbarStyles,
  useStateSyncSearchParams,
} from '@clef/client-library';
import { MediaDetailsWithPrediction } from '@clef/shared/types';
import { Box } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useRef } from 'react';
import { useImmer } from 'use-immer';
import PreviewBar from './PreviewBar';
import {
  initialState,
  useAllClasses,
  useAutoSwitchToNextClass,
  useCurrentProject,
  useZeroAuthInstantLearningState,
  ZeroAuthInstantLearningContext,
} from './state';
import ZeroAuthHeader from './ZeroAuthHeader';
import ZeroAuthLabelingWrapper from './ZeroAuthLabelingWrapper';

const ZeroAuthInstantLearningInner: React.FC = () => {
  const canvasRef = useRef<MediaInteractiveCanvas>(null);

  const { state: labelingState, dispatch: dispatchLabelingState } = useLabelingState();

  const allClasses = useAllClasses();

  // initialize default selected class
  const once = useRef(false);
  const project = useCurrentProject();
  useEffect(() => {
    const image = project?.dataset[0];
    const brushSize = project?.brushSize;
    if (image && allClasses.length > 0 && !once.current) {
      const properties =
        image.width && image.height ? { width: image.width, height: image.height } : undefined;
      dispatchLabelingState(draft => {
        draft.toolMode = ToolMode.Brush;
        const { width = 1500, height = 1000 } = properties ?? {};
        const initStrokeWidth = Math.max(1, Math.round(Math.min(width, height) * 0.015));
        draft.toolOptions.strokeWidth = brushSize ?? initStrokeWidth;
        draft.toolOptions.eraserWidth = brushSize ?? initStrokeWidth;
      });
      once.current = true;
    }
  }, [allClasses, dispatchLabelingState, labelingState.selectedDefect, project]);

  // Auto switch to the next class
  useAutoSwitchToNextClass();

  const { state } = useZeroAuthInstantLearningState();
  const { mediaStatesByIndex } = state;

  return (
    <Box
      width="100vw"
      height="100vh"
      overflow="hidden"
      display="flex"
      flexDirection="column"
      alignItems="stretch"
    >
      <ZeroAuthHeader />
      <PreviewBar />

      <ApiResponseLoader
        response={isEmpty(mediaStatesByIndex) ? undefined : mediaStatesByIndex}
        loading={isEmpty(mediaStatesByIndex)}
        defaultWidth="100vw"
        defaultHeight="100vh"
      >
        {() => <ZeroAuthLabelingWrapper canvasRef={canvasRef} />}
      </ApiResponseLoader>
    </Box>
  );
};

const ZeroAuthInstantLearning: React.FC<{}> = () => {
  const [state, dispatch] = useImmer(initialState);
  const { currentProjectId } = state;

  const [labelingState, dispatchLabelingState] = useImmer(defaultState);
  const [userId] = useZeroAuthLoginApi({});
  const [projects] = useZeroAuthProjectsApi(userId ? {} : undefined);

  const [projectId, setProjectId] = useStateSyncSearchParams('projectId', '1');

  useEffect(() => {
    dispatch(draft => {
      draft.userId = userId;
    });
  }, [dispatch, userId]);

  useEffect(() => {
    dispatch(draft => {
      // initialize current project id
      if (projects && projects.length > 0 && currentProjectId === undefined) {
        const projectIdNum = Number(projectId);
        const isValidProjectId = projectId && projects.some(p => p.id === projectIdNum);
        draft.currentProjectId = isValidProjectId ? projectIdNum : projects[0].id;
      }
      // reset media index on project switch
      draft.mediaIndex = 0;
    });
    if (currentProjectId) {
      setProjectId(String(currentProjectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, projects, currentProjectId]);

  const [project] = useZeroAuthProjectApi(currentProjectId);
  useEffect(() => {
    if (!project || project.id !== currentProjectId) {
      return;
    }
    dispatch(draft => {
      const { mediaStatesByIndex } = draft;
      project?.dataset.forEach((image, index) => {
        const mediaStates = mediaStatesByIndex[index] ?? {};
        if (mediaStates.gtSegPath !== image.gtSegPath) {
          mediaStates.gtSegPath = image.gtSegPath;
        }
        if (mediaStates.predSegPath !== image.predSegPath) {
          mediaStates.predSegPath = image.predSegPath;
          mediaStates.predictionAnnotations = undefined;
        }
        mediaStates.isChanged = false;
        mediaStates.properties =
          image.width && image.height ? { width: image.width, height: image.height } : undefined;
        mediaStates.mediaDetails = { url: image.mediaPath } as MediaDetailsWithPrediction;
        mediaStatesByIndex[index] = mediaStates;
      });
      draft.mediaStatesByIndex = mediaStatesByIndex;
    });
  }, [currentProjectId, dispatch, project]);

  const snackbarStyles = useSnackbarStyles();

  if (!projects) {
    return (
      <ApiResponseLoader response={undefined} loading defaultWidth="100vw" defaultHeight="100vh">
        {() => null}
      </ApiResponseLoader>
    );
  }

  return (
    <SnackbarProvider
      preventDuplicate
      maxSnack={3}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      classes={{
        root: snackbarStyles.root,
      }}
      action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
    >
      <DialogContextProvider>
        <ZeroAuthInstantLearningContext.Provider value={{ state, dispatch }}>
          <LabelingContext.Provider
            // use current project id as key here so that we can re-initialize tool states like stroke width
            key={state.currentProjectId}
            value={{ state: labelingState, dispatch: dispatchLabelingState }}
          >
            <ZeroAuthInstantLearningInner />
          </LabelingContext.Provider>
        </ZeroAuthInstantLearningContext.Provider>
      </DialogContextProvider>
    </SnackbarProvider>
  );
};

export default ZeroAuthInstantLearning;

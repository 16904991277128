import React from 'react';
import { ListItemIcon, ListItemText } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { TransformUISchema } from '../../../types/client';

type Props = {
  anchorEl: HTMLButtonElement | null;
  subAnchorEl: HTMLLIElement | null;
  transformNames: string[];
  handleMenuClick: (
    name: string,
    isNewEntry: boolean,
    isResizeOption?: boolean,
    event?: React.MouseEvent<HTMLLIElement>,
    isResizeSwitch?: boolean,
  ) => void;
  handleMenuClose: () => void;
  uiSchema: TransformUISchema;
  title: string;
  isTransformStep?: boolean;
};

const TransformsMenu = ({
  anchorEl,
  subAnchorEl,
  transformNames,
  handleMenuClick,
  handleMenuClose,
  uiSchema,
  title,
  isTransformStep = false,
}: Props) => {
  return (
    <>
      <Menu
        data-testid={`add${title}Menu`}
        id="transform-menu"
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {Object.entries(uiSchema)
          .filter(([key, option]) => !option.isAdded && !transformNames.includes(key))
          .filter(([, option]) => (isTransformStep ? !option.isResizeOption : option))
          .map(([, option]) => (
            <MenuItem
              data-testid={`add${title}MenuItem`}
              key={option.name}
              onClick={event => handleMenuClick(option.name, true, option.isResizeOption, event)}
            >
              <ListItemText>{option?.label}</ListItemText>
              {option.name === 'Resize' && !option.isResizeOption && (
                <ListItemIcon>
                  <ArrowRightIcon fontSize="small" />
                </ListItemIcon>
              )}
            </MenuItem>
          ))}
      </Menu>

      {/* Sub menu */}
      <Menu
        data-testid={`add${title}SubMenu`}
        id="transform-sub-menu"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={subAnchorEl}
        open={Boolean(subAnchorEl)}
        onClose={handleMenuClose}
      >
        {Object.values(uiSchema)
          .filter(
            option =>
              !option.isAdded && !transformNames.includes(option.name) && option.isResizeOption,
          )
          .map(option => (
            <MenuItem
              data-testid={`add${title}SubMenuItem`}
              key={option.name}
              onClick={() =>
                handleMenuClick(option.name, true, option.isResizeOption, undefined, true)
              }
            >
              {option?.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default TransformsMenu;

import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useResponsiveWidth } from '../../hooks/useResponsiveWidth';
import { useStyles } from './styles';
import { ApiResponseLoader } from '@clef/client-library';
import { useExampleProjectsList } from '../../hooks/api/useExampleProjectApi';
import ExampleProjectCard from './ExampleProjectCard';

const PROJECT_CARD_MARGIN = 40;

const Examples: React.FC = () => {
  const styles = useStyles();
  const { percentageWidth } = useResponsiveWidth({
    maxItemPerRow: 3,
    screenSizeBreakPoints: [0, 1200],
    ordering: 'decremental',
  });

  const [exampleProjectsList, exampleProjectsListLoading, exampleProjectsListError] =
    useExampleProjectsList({});

  return (
    <div>
      <Box marginBottom={10}>
        <Typography variant="h1">{t('Pre-built Models')}</Typography>
      </Box>

      <Grid container>
        <ApiResponseLoader
          response={exampleProjectsList}
          loading={exampleProjectsListLoading ?? false}
          error={exampleProjectsListError}
          defaultHeight="30vh"
        >
          {projectListLoaded => {
            return projectListLoaded.map((project, index) => (
              <ExampleProjectCard
                key={index}
                className={styles.card}
                style={{
                  marginRight: PROJECT_CARD_MARGIN,
                  marginBottom: PROJECT_CARD_MARGIN,
                  width: `calc(${percentageWidth} - ${PROJECT_CARD_MARGIN}px)`,
                }}
                projectId={project.projectId}
                datasetId={project?.datasetId}
                coverMediaId={project.coverMediaId}
                title={project.title}
                description={project.description}
                defects={project.defectMap}
              />
            ));
          }}
        </ApiResponseLoader>
      </Grid>
    </div>
  );
};

export default Examples;

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  verticalMargin: {
    marginBottom: 20,
  },
  divider: {
    margin: '20px 0',
  },
}));

export const SpacingVertical: React.FC<{}> = () => {
  const styles = useStyles();
  return <div className={styles.verticalMargin}></div>;
};

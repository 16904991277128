import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { SnowflakeFolderDirectoryResponse } from '@/api/pictor_api';

export type TreeNode = SnowflakeFolderDirectoryResponse;

export const findPath = (node: TreeNode, id: string): TreeNode[] | null => {
  if (node.id === id) {
    return [node];
  }
  if (!node.children) {
    return null;
  }
  for (const child of node.children) {
    const path = findPath(child, id);
    if (path) {
      return [node, ...path];
    }
  }
  return null;
};

export const findNode = (root: TreeNode, id: string): TreeNode | null => {
  if (root.id === id) {
    return root;
  }
  if (!root.children) {
    return null;
  }
  for (const child of root.children) {
    const found = findNode(child, id);
    if (found) {
      return found;
    }
  }
  return null;
};

const useStyles = makeStyles({
  root: {
    // height: 110,
    flexGrow: 1,
    // maxWidth: 400,
  },
});

interface SnowflakePathTreeViewProps {
  data: TreeNode;
  selected: string;
  setSelected: (newState: string) => void;
}

const SnowflakePathTreeView = (props: SnowflakePathTreeViewProps) => {
  const styles = useStyles();
  const { data, selected, setSelected } = props;
  const [expanded, setExpanded] = React.useState<string[]>([]);

  const handleToggle = (_event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (_event: React.ChangeEvent<{}>, nodeIds: string) => {
    setSelected(nodeIds);
  };

  const renderTree = (nodes: TreeNode) => (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
      {Array.isArray(nodes.children) ? nodes.children.map(node => renderTree(node)) : null}
    </TreeItem>
  );

  return (
    <TreeView
      className={styles.root}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={['root']}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {renderTree(data)}
    </TreeView>
  );
};

export default SnowflakePathTreeView;

import React from 'react';
import { Box } from '@material-ui/core';

import { Typography, Button } from '@clef/client-library';

import useStyles from './styles';
import ModelDetailsDialogTrainingInProgress from './ModelDetailsDialogTrainingInProgress';
import ModelTrainingProgress from './ModelTrainingProgress';
import { RegisteredModel } from '@clef/shared/types';

interface ModelInfoCardTrainingInProgressProps {
  model: RegisteredModel;
}

const ModelInfoCardTrainingInProgress: React.FC<ModelInfoCardTrainingInProgressProps> = ({
  model,
}) => {
  const styles = useStyles();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  return (
    <>
      <Box className={styles.modelInfoCard}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mr={-4}>
          <Typography variant="h4_semibold" maxWidth={164}>
            {model.modelName}
          </Typography>
          <Button
            id="view-model-training-detail"
            variant="text"
            size="small"
            color="primary"
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            {t('View Details')}
          </Button>
        </Box>
        <Box className={styles.inProgressChip}>
          <Typography variant="body_small_bold">{t('Training in progress')}</Typography>
        </Box>
        <ModelTrainingProgress modelId={model.id} />
      </Box>
      {dialogOpen && (
        <ModelDetailsDialogTrainingInProgress
          model={model}
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default ModelInfoCardTrainingInProgress;

import { attentionRed } from '@clef/client-library';
import { makeStyles } from '@material-ui/core';

export const projectSettingWithUserRoleTableStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190,
  },
  filterBox: {
    height: 64,
    borderRadius: '8px 8px 0 0',
    backgroundColor: '#fff',
    borderBottom: '1px solid #E6E7E9',
    paddingLeft: 28,
    paddingRight: 20,
  },
  selectBox: {
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
    marginRight: 47,
    backgroundColor: '#fff',
  },
  searchBox: {
    border: ' 1px solid #CDD0D3',
    borderRadius: 8,
  },

  nameBox: {
    marginLeft: theme.spacing(2),
  },
  name: {
    marginBottom: theme.spacing(1),
    fontSize: 14,
  },
  email: {
    fontSize: 14,
    color: theme.palette.grey[500],
  },
  editPermissions: {
    flexDirection: 'row',
    minWidth: 510,
  },
  permissionBox: {
    width: 280,
  },
  projectRole: {
    width: 120,
  },
  tableBox: {
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.primary.main,
    },
    '& .MuiCheckbox-indeterminate.MuiCheckbox-root': {
      color: theme.palette.primary.main,
    },
    position: 'relative',
  },
  tableBoxMask: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 11,
  },
  commonBox: {
    width: 400,
  },
  removeUserActionItem: {
    color: attentionRed.main,
  },
}));

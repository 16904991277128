import { EdgeMediaStatusType, EdgeMediaDetails } from '@clef/shared/types';

export const EdgeMediaStatusProps = {
  [EdgeMediaStatusType.Raw]: {
    text: 'Raw',
    color: '#004070',
  },
  [EdgeMediaStatusType.InSync]: {
    text: 'In Sync',
    color: '#00B69B',
  },
};

export const DEVICE_ID_METADATA_NAME = 'cl_device';
export const MODEL_ID_METADATA_NAME = 'cl_model';

export const CL_METADATA_NAMES = [DEVICE_ID_METADATA_NAME, MODEL_ID_METADATA_NAME];

export const CL_METADATA_NAMES_TO_FIELD_MAPPING: {
  [key: string]: { key: keyof EdgeMediaDetails; default: string };
} = {
  [DEVICE_ID_METADATA_NAME]: {
    key: 'device_id',
    default: '',
  },
  [MODEL_ID_METADATA_NAME]: {
    key: 'model_id',
    default: '',
  },
};

import { SubscriptionName } from '@clef/shared/types';
import ForumOutlined from '@material-ui/icons/ForumOutlined';
import Check from '@material-ui/icons/Check';
import React from 'react';

export const plans = [
  {
    key: SubscriptionName.Free,
    name: t('Free'),
    description: t('Best for Individual Practitioners'),
    price: t('$0'),
    priceDescriptions: [t('Free forever'), t('Free credits every month ')],
    credits: t('1000 credits/mo'),
    creditOverages: t('Not supported'),
    downloadableModelProjects: t('1'),
    modelUsage: t('Noncommercial use only'),
    users: t('3'),
    samlSso: t('-'),
    lvm: t('-'),
    customerSuccess: t('-'),
  },
  {
    key: SubscriptionName.Visionary,
    name: t('Visionary'),
    description: t('Best for Small Businesses'),
    price: t('$50'),
    priceDescriptions: [t('Basic monthly fee')],
    credits: t('5000 credits/mo *'),
    creditOverages: t('$0.01/credit, billed in arrears'),
    downloadableModelProjects: t('3'),
    modelUsage: t('Noncommercial use only'),
    users: t('15'),
    samlSso: t('-'),
    lvm: t('-'),
    customerSuccess: t('-'),
  },
  {
    key: SubscriptionName.Enterprise,
    name: t('Enterprise'),
    description: t('Best for Large Enterprises'),
    price: <ForumOutlined fontSize="large" />,
    priceDescriptions: [t('Contact sales for pricing')],
    credits: t('Custom package'),
    creditOverages: t('Custom package'),
    downloadableModelProjects: t('Starting at 5'),
    modelUsage: t('Commercial use'),
    users: t('Unlimited'),
    samlSso: <Check fontSize="small" />,
    lvm: <Check fontSize="small" />,
    customerSuccess: <Check fontSize="small" />,
  },
];

export const planAttributes = [
  { key: 'credits', name: t('Credits') },
  { key: 'creditOverages', name: t('Additional Credits') },
  { key: 'downloadableModelProjects', name: t('Download model projects') },
  { key: 'modelUsage', name: t('Model usage') },
  { key: 'users', name: t('Users') },
  { key: 'samlSso', name: t('SAML SSO') },
  { key: 'lvm', name: t('Access to Large Vision Models') },
  { key: 'customerSuccess', name: t('Customer Success') },
] as { key: keyof typeof plans[0]; name: string }[];

export const allPlansFeatures = [
  {
    key: 'general',
    name: t('General'),
    features: [t('Create Orgs'), t('Invite Users'), t('Role/Access control')],
  },
  {
    key: 'data-management',
    name: t('Data Management'),
    features: [
      t('Unlimited Projects'),
      t('Unlimited Classes'),
      t('Up to 10K images per project'),
      t('Label Images'),
      t('Import Labeled Images'),
      t('Agreement-Based Labeling'),
    ],
  },
  {
    key: 'model-training-and-deployment',
    name: t('Model Training & Deployment'),
    features: [
      t('Model Training'),
      t('Model deployment (Cloud)'),
      t('LandingEdge and Docker license'),
    ],
  },
  {
    key: 'support',
    name: t('Support'),
    features: [t('Community support'), t('In-product feedback')],
  },
];

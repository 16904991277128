import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  previewSegContainer: {
    width: '100%',
    backgroundColor: theme.palette.grey[200],
    borderRadius: 8,
    overflow: 'hidden',
    position: 'relative',
  },
  previewContainer: {
    position: 'relative',
    '& $deleteAction': {
      opacity: 0,
    },
    '&:hover $deleteAction': {
      opacity: 1,
    },
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  hintTextNormal: {
    color: theme.palette.grey[500],
  },
  showExpandIconOnHover: {
    '& .MuiAccordionSummary-expandIcon': {
      opacity: 0,
      transition: 'opacity 200ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    '&:hover': {
      '& .MuiAccordionSummary-expandIcon': {
        opacity: 1,
      },
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  previewContainerTop: {
    marginBottom: theme.spacing(1),
  },
  deleteAction: {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
  },
}));

export default useStyles;

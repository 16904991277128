import { useAppDispatch } from '@/store';
import { MutationFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import MetadataAPI from '@/api/metadata_api';
import { addField, updateField } from '@/store/metadata_store';
import { useSnackbar } from 'notistack';
import { SUCCESS_SNACKBAR_STYLE, ERROR_SNACKBAR_STYLE } from '@/constants/metadata_constants';
import { refreshMetadataApi } from '@/hooks/api/useMetadataApi';
import { datasetQueryKeys } from '../dataset';
import { useTypedSelector } from '@/hooks/useTypedSelector';

const useManipulateMetadataFieldMutation = <TData, TVariables>(
  mutationFn: MutationFunction<TData, TVariables>,
  onSucceed: (data: TData) => void,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const selectedProjectId = useTypedSelector(state => state.project.selectedProjectId);
  return useMutation({
    mutationFn,
    onSuccess: data => {
      onSucceed(data);
      enqueueSnackbar(t('Save Succeed'), SUCCESS_SNACKBAR_STYLE);
      refreshMetadataApi({ keys: 'refresh-all' });
      selectedProjectId &&
        queryClient.invalidateQueries(datasetQueryKeys.filterOptions(selectedProjectId));
    },
    onError: e => enqueueSnackbar('Save Failed: ' + (e as Error).message, ERROR_SNACKBAR_STYLE),
  });
};

export const useUpdateMetadataFieldMutation = () => {
  const dispatch = useAppDispatch();
  return useManipulateMetadataFieldMutation(
    async (params: Parameters<typeof MetadataAPI.updateFieldById>[0]) => {
      const response = await MetadataAPI.updateFieldById(params);
      return response.data;
    },
    data => dispatch(updateField(data)),
  );
};

export const useAddMetadataFieldMutation = () => {
  const dispatch = useAppDispatch();
  return useManipulateMetadataFieldMutation(
    async (params: Parameters<typeof MetadataAPI.addField>[0]) => {
      const response = await MetadataAPI.addField(params);
      return response.data;
    },
    data => dispatch(addField(data)),
  );
};

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    paddingRight: '0 !important',
    // For defect book printing
    '@media print': {
      display: 'none',
    },
  },
  defaultAppBar: {
    boxShadow: `0 0 24px ${theme.palette.blue[500]}0C !important`,
  },
  toolbar: {
    padding: theme.spacing(0, 4, 0, 4),
    backgroundColor: theme.palette.greyModern[50],
    height: 48,
    minHeight: 48,
  },
  toolbarOnProjectPage: {
    backgroundColor: theme.palette.greyModern[25],
    boxShadow: '0px 0px 24px rgba(10, 100, 252, 0.05)',
  },
  landingLensLogoLink: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(8),
    padding: theme.spacing(2),
    borderRadius: 50,
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0,0,0,0.04)',
    },
  },
  tabItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 90,
    borderRadius: 16,
    marginRight: theme.spacing(6),
    '&:hover': {
      background: 'transparent',
    },
  },
  tabItemText: {
    color: theme.palette.greyModern[600],
    fontWeight: 500,
  },
  selectedTabItemText: {
    color: theme.palette.blue[600],
  },
  helpButton: {
    fontWeight: 500,
    padding: 6,
    margin: theme.spacing(0, 2),
  },
  helpIcon: {
    color: theme.palette.grey[800],
  },
  upgradeButton: {
    height: 'auto',
    padding: '4px 12px',
    borderRadius: 4,
    flexShrink: 0,
    background: theme.palette.indigoBlue[100],
    cursor: 'pointer',
    marginRight: theme.spacing(2),
    '&:hover': {
      background: theme.palette.blue[200],
    },
  },
  upgradeButtonText: {
    color: theme.palette.indigoBlue[900],
    fontWeight: 700,
  },
  linkSelected: {
    backgroundColor: 'transparent !important',
  },
  announcementBanner: {
    width: '100%',
    height: 40,
    fontSize: 14,
    backgroundColor: theme.palette.blue[700],
    color: theme.palette.common.white,
  },
  linkText: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  closeAnnouncementBannerBtn: {
    height: 20,
    width: 20,
    color: theme.palette.common.white,
  },
}));

import React from 'react';

export interface IconProps {
  color?: string;
  width?: number | string;
  height?: number | string;
}

export const IconQuickLabeling: React.FC<IconProps> = React.memo(
  ({ color = '#4B5565', width = 24, height = 24 }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 7L19.05 4.95L17 4L19.05 3.05L20 1L20.95 3.05L23 4L20.95 4.95L20 7ZM8.5 7L7.55 4.95L5.5 4L7.55 3.05L8.5 1L9.45 3.05L11.5 4L9.45 4.95L8.5 7ZM20 18.5L19.05 16.45L17 15.5L19.05 14.55L20 12.5L20.95 14.55L23 15.5L20.95 16.45L20 18.5ZM5.1 21.7L2.3 18.9C2.1 18.7 2 18.4583 2 18.175C2 17.8917 2.1 17.65 2.3 17.45L13.45 6.3C13.65 6.1 13.8917 6 14.175 6C14.4583 6 14.7 6.1 14.9 6.3L17.7 9.1C17.9 9.3 18 9.54167 18 9.825C18 10.1083 17.9 10.35 17.7 10.55L6.55 21.7C6.35 21.9 6.10833 22 5.825 22C5.54167 22 5.3 21.9 5.1 21.7ZM5.85 19.6L13 12.4L11.6 11L4.4 18.15L5.85 19.6Z"
        fill={color}
      />
    </svg>
  ),
);

export const IconBrush: React.FC<IconProps> = React.memo(({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1688 3C18.9088 3 18.6588 3.1 18.4588 3.29L9.49878 12.25L12.2488 15L21.2088 6.04C21.5988 5.65 21.5988 5.02 21.2088 4.63L19.8688 3.29C19.6688 3.09 19.4188 3 19.1688 3ZM7.49878 16C8.04878 16 8.49878 16.45 8.49878 17C8.49878 18.1 7.59878 19 6.49878 19C6.32878 19 6.16878 18.98 5.99878 18.95C6.30878 18.4 6.49878 17.74 6.49878 17C6.49878 16.45 6.94878 16 7.49878 16ZM4.49878 17C4.49878 15.34 5.83878 14 7.49878 14C9.15878 14 10.4988 15.34 10.4988 17C10.4988 19.21 8.70878 21 6.49878 21C4.98878 21 3.41878 20.22 2.49878 19C3.33878 19 4.49878 18.31 4.49878 17Z"
      fill={color}
    />
  </svg>
));

export const IconBrushPlus: React.FC<IconProps> = React.memo(({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.67 3C18.41 3 18.16 3.1 17.96 3.29L9 12.25L11.75 15L20.71 6.04C21.1 5.65 21.1 5.02 20.71 4.63L19.37 3.29C19.17 3.09 18.92 3 18.67 3ZM7 16C7.55 16 8 16.45 8 17C8 18.1 7.1 19 6 19C5.83 19 5.67 18.98 5.5 18.95C5.81 18.4 6 17.74 6 17C6 16.45 6.45 16 7 16ZM4 17C4 15.34 5.34 14 7 14C8.66 14 10 15.34 10 17C10 19.21 8.21 21 6 21C4.49 21 2.92 20.22 2 19C2.84 19 4 18.31 4 17Z"
      fill={color}
    />
    <line x1="14.5012" y1="18" x2="22.5012" y2="18" stroke={color} strokeWidth="2" />
    <line x1="18.5012" y1="14" x2="18.5012" y2="22" stroke={color} strokeWidth="2" />
  </svg>
));

export const IconBrushMinus: React.FC<IconProps> = React.memo(({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.67 3C18.41 3 18.16 3.1 17.96 3.29L9 12.25L11.75 15L20.71 6.04C21.1 5.65 21.1 5.02 20.71 4.63L19.37 3.29C19.17 3.09 18.92 3 18.67 3ZM7 16C7.55 16 8 16.45 8 17C8 18.1 7.1 19 6 19C5.83 19 5.67 18.98 5.5 18.95C5.81 18.4 6 17.74 6 17C6 16.45 6.45 16 7 16ZM4 17C4 15.34 5.34 14 7 14C8.66 14 10 15.34 10 17C10 19.21 8.21 21 6 21C4.49 21 2.92 20.22 2 19C2.84 19 4 18.31 4 17Z"
      fill={color}
    />
    <line x1="14.5012" y1="18" x2="22.5012" y2="18" stroke={color} strokeWidth="2" />
  </svg>
));

export const IconPolygon: React.FC<IconProps> = React.memo(({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 3L14.4496 3L18.8622 10.8446L13.5005 21.0805L5 16.8302L5 3Z"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
));

export const IconPolygonPlus: React.FC<IconProps> = React.memo(({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 3L11.7474 3L15.8732 10.6651L10.8721 20.6427L3 16.5294L3 3Z"
      stroke={color}
      strokeWidth="2"
    />
    <line x1="15" y1="18" x2="23" y2="18" stroke={color} strokeWidth="2" />
    <line x1="19" y1="14" x2="19" y2="22" stroke={color} strokeWidth="2" />
  </svg>
));

export const IconPolygonMinus: React.FC<IconProps> = React.memo(({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 3L11.7474 3L15.8732 10.6651L10.8721 20.6427L3 16.5294L3 3Z"
      stroke={color}
      strokeWidth="2"
    />
    <line x1="15" y1="18" x2="23" y2="18" stroke={color} strokeWidth="2" />
  </svg>
));

export const IconPolyline: React.FC<IconProps> = React.memo(({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.0434 5.25692C5.13592 5.05499 5.1875 4.83039 5.1875 4.59375C5.1875 3.71355 4.47395 3 3.59375 3C2.71355 3 2 3.71355 2 4.59375C2 5.35755 2.53729 5.99585 3.25454 6.15132L7.45662 14.5555C7.36406 14.7574 7.31247 14.9821 7.31247 15.2188C7.31247 16.099 8.02602 16.8125 8.90622 16.8125C9.25522 16.8125 9.57802 16.7003 9.84052 16.5101L19.1329 19.0443C19.3789 19.6069 19.9404 20 20.5938 20C21.474 20 22.1875 19.2865 22.1875 18.4062C22.1875 17.526 21.474 16.8125 20.5938 16.8125C20.2448 16.8125 19.922 16.9247 19.6595 17.1149L10.3671 14.5806C10.1649 14.1183 9.74966 13.7705 9.24554 13.6612L5.0434 5.25692Z"
      fill={color}
    />
  </svg>
));

export const UncheckCircleIcon: React.FC<IconProps> = React.memo(({ color = '#D2D6DB' }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="9.5" fill="white" stroke={color} />
  </svg>
));

export const NextImageIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="5" fill="#CDD5DF" />
    <path d="M7.91669 14.167L12.0834 10.0003L7.91669 5.83366L7.91669 14.167Z" fill="#4B5565" />
  </svg>
);

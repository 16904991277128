import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { ApiResponseLoader } from '@clef/client-library';
import { Box, Grid, ImageList } from '@material-ui/core';
import { Defect } from '@clef/shared/types';
import useStyles from './styles';
import ExampleMediaContainer from '../ExampleMediaContainer';
import { useExampleProjectTrainingData } from '@/hooks/api/useExampleProjectApi';

interface TrainingDataProps {
  projectId: number;
  defects: Defect[];
  setTrainingDataCount: Dispatch<SetStateAction<number | null>>;
}

const TrainingData: React.FC<TrainingDataProps> = ({
  projectId,
  defects,
  setTrainingDataCount,
}) => {
  const styles = useStyles();

  const [
    exampleProjectsTrainingData,
    exampleProjectsTrainingDataLoading,
    exampleProjectsTrainingDataError,
  ] = useExampleProjectTrainingData(projectId);

  useEffect(() => {
    if (exampleProjectsTrainingData?.length) {
      setTrainingDataCount(exampleProjectsTrainingData?.length);
    }
  }, [exampleProjectsTrainingData?.length, setTrainingDataCount]);

  return (
    <Box className={styles.trainingDataContainer}>
      <ApiResponseLoader
        response={exampleProjectsTrainingData}
        loading={exampleProjectsTrainingDataLoading}
        error={exampleProjectsTrainingDataError}
      >
        {medias => {
          return (
            <ImageList className={styles.trainingImageList} cols={5} component="div">
              {medias?.map(media => (
                <Grid item xs={12} className={styles.gridListTile} key={media.id}>
                  <ExampleMediaContainer media={media} defects={defects} />
                </Grid>
              ))}
            </ImageList>
          );
        }}
      </ApiResponseLoader>
    </Box>
  );
};

export default TrainingData;

import { ProjectId } from '@clef/shared/types';
import { createAtom, createWritableAtom } from '../utils';

export interface ProjectDisplayCheckMarkWhenCompletedUIStateMap {
  [projectId: ProjectId]: boolean | null; // shouldDisplayCheckMarkWhenComplete
}

export const projectDisplayCheckMarkWhenCompleteAtom =
  createAtom<ProjectDisplayCheckMarkWhenCompletedUIStateMap>(
    'projectDisplayCheckMarkWhenCompleteAtom',
    {},
  );

export const setProjectDisplayCheckMarkWhenCompleteAtom = createWritableAtom(
  'setProjectDisplayCheckMarkWhenCompleteAtom',
  null,
  (_get, set, upload: { projectId: ProjectId; shouldDisplayCheckMarkWhenComplete: boolean }) => {
    const { projectId, shouldDisplayCheckMarkWhenComplete } = upload;
    set(projectDisplayCheckMarkWhenCompleteAtom, {
      ...projectDisplayCheckMarkWhenCompleteAtom,
      [projectId]: shouldDisplayCheckMarkWhenComplete,
    });
  },
);

export const removeAllProjectDisplayCheckMarkWhenCompleteAtom = createWritableAtom(
  'removeAllProjectDisplayCheckMarkWhenCompleteAtom',
  null,
  (_get, set) => {
    set(projectDisplayCheckMarkWhenCompleteAtom, _prev => {
      return {};
    });
  },
);

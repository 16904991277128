import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import { useLabelingReviewStyles } from '../labelingReviewStyles';
import { useLabelingReviewState, useUpdateFilters } from '../labelingReviewState';
import { Typography, Grid, GridSize, Checkbox, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { LabelReviewStatus, MediaDetailsForLabelingReview } from '@clef/shared/types';
import { getThumbnail, Img } from '@clef/client-library';

export interface SimpleMediaPreviewProps {
  mediaDetail: MediaDetailsForLabelingReview;
  showAgreement?: boolean;
  checked?: boolean;
  onChange?: (checked?: boolean) => void;
}

const SimpleMediaPreview: React.FC<SimpleMediaPreviewProps> = ({
  mediaDetail,
  showAgreement,
  checked = false,
  onChange,
}) => {
  const previewRef = useRef<HTMLDivElement | null>(null);
  const styles = useLabelingReviewStyles();
  const { dispatch, state } = useLabelingReviewState();

  const { overallAgreementScore = 0, id: mediaId } = mediaDetail;

  // initialize reviewResult
  // - this is review mode, initialize base on the threshold.
  // - this is view result mode, initialize wih media status.
  useEffect(() => {
    if (overallAgreementScore >= 0 && !state.reviewResult[mediaId]) {
      dispatch(draft => {
        draft.reviewResult[mediaId] = state.isViewResultMode
          ? {
              reviewStatus: mediaDetail?.reviewStatus ?? LabelReviewStatus.NotApplicable,
              note:
                mediaDetail?.labels.find(label => label.id === mediaDetail?.bestLabelId)
                  ?.reviewNotes ?? '',
            }
          : {
              reviewStatus:
                showAgreement &&
                Number(overallAgreementScore?.toFixed(2)) < state.agreementThreshold
                  ? LabelReviewStatus.Rejected
                  : LabelReviewStatus.NotReviewed,
              note: '',
            };
      });
    }
  }, [
    dispatch,
    mediaId,
    overallAgreementScore,
    state.agreementThreshold,
    state.reviewResult,
    state.isViewResultMode,
    mediaDetail,
    showAgreement,
  ]);
  const updateFilters = useUpdateFilters();

  // scroll into view
  useEffect(() => {
    if (previewRef.current && state.currentMediaId === mediaId) {
      previewRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [mediaId, state.currentMediaId]);

  const reviewStatus = state.reviewResult[mediaId]?.reviewStatus ?? LabelReviewStatus.NotReviewed;

  const isAccepted = reviewStatus === LabelReviewStatus.Accepted;
  const isRejected = reviewStatus === LabelReviewStatus.Rejected;
  const isNotReview = reviewStatus === LabelReviewStatus.NotReviewed;

  const columnGridSizes: {
    image: GridSize;
    agreementScore?: GridSize;
    hasOverallAgreementScore?: GridSize;
    reviewIcon?: GridSize;
  } = showAgreement
    ? {
        image: 5,
        agreementScore: 5,
        hasOverallAgreementScore: 4,
        reviewIcon: 2,
      }
    : {
        image: 9,
        reviewIcon: 3,
      };

  return (
    <Grid
      ref={previewRef}
      direction="row"
      alignContent="center"
      container
      wrap="nowrap"
      key={mediaId}
      spacing={0}
      onClick={() => {
        dispatch(draft => {
          draft.currentMediaId = mediaId;
        });
        updateFilters(mediaId);
      }}
      className={cx(
        styles.mediaPreview,
        state.currentMediaId === mediaId && styles.mediaPreviewSelected,
      )}
      data-testid="task-media-preview"
      title={state.currentMediaId === mediaId ? 'current-media-preview' : undefined}
    >
      <Grid item xs={columnGridSizes.image} container direction="row" alignItems="center">
        <Img
          src={getThumbnail(mediaDetail, 'small')}
          fallbackSrc={mediaDetail?.url}
          className={styles.mediaPreviewImg}
          crossOrigin="use-credentials"
        />
      </Grid>
      {showAgreement && (
        <Grid item xs={4} container direction="row" alignItems="center">
          <Typography variant="h4">{overallAgreementScore?.toFixed(2)}</Typography>
        </Grid>
      )}

      <Grid item xs={columnGridSizes.reviewIcon} container direction="row" alignItems="center">
        {isRejected && (
          <Box display="flex" alignItems="center">
            <CloseIcon className={styles.mediaReject} data-testid="task-media-preview-reject" />
            {state.isViewResultMode && (
              <Checkbox
                color="primary"
                checked={checked}
                onChange={e => {
                  onChange && onChange(e.target.checked);
                }}
                onClick={e => {
                  e.nativeEvent.stopImmediatePropagation();
                  e.stopPropagation();
                }}
              />
            )}
          </Box>
        )}
        {isAccepted && (
          <CheckIcon className={styles.mediaApproved} data-testid="task-media-preview-approve" />
        )}
        {isNotReview && (
          <Typography
            variant="h4"
            style={{ marginLeft: 8 }}
            data-testid="task-media-preview-pending"
          >
            <strong>{t('-')}</strong>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default SimpleMediaPreview;

import { liveWebinarEventIdFromUrlAtom } from '@/uiStates/liveWebinar';
import { useAtom, useSetAtom } from 'jotai';
import { useGetStreamyardIdFromSecretManagerQuery } from '@/serverStore/streamyard/queries';
import { useEffect } from 'react';

export const useStreamyardId = () => {
  const [streamyardIdFromUrl] = useAtom(liveWebinarEventIdFromUrlAtom);
  const setLiveWebinarEventId = useSetAtom(liveWebinarEventIdFromUrlAtom);
  const { data: streamyardIdFromSecretsManager } = useGetStreamyardIdFromSecretManagerQuery();
  const { search } = location;
  const params = new URLSearchParams(search);
  const currentStreamyardIdFromUrlParams: string =
    params.get('streamyardId') !== null ? (params.get('streamyardId') as string) : '';

  const streamyardId = streamyardIdFromUrl
    ? streamyardIdFromUrl
    : streamyardIdFromSecretsManager?.streamyardId;

  useEffect(() => {
    if (currentStreamyardIdFromUrlParams) {
      setLiveWebinarEventId(currentStreamyardIdFromUrlParams);
    }
  }, [currentStreamyardIdFromUrlParams]);

  return streamyardId;
};

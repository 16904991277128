import { reverseMap } from './basic';

export const RLE_ALGO_NAME = 'rle';
export const RLE_OPTIONS = { map: { Z: '0', N: '1' } };

const rleEncodeMap: Record<string, string> = reverseMap(RLE_OPTIONS.map);
const rleDecodeMap: Record<string, string> = RLE_OPTIONS.map;

/**
 * Groups all the consecutive similar chars, Z presents 0 and N presents 1.
 * 1001000100000100100010001 =>
 * ["1N", "2Z", "1N", "3Z", "1N", "5Z", "1N", "2Z", "1N", "3Z", "1N", "3Z", "1N"] =>
 * 1N2Z1N3Z1N5Z1N2Z1N3Z1N3Z1N
 *
 * @param text The text to be encoded. Can be a single string, or an array of characters. e.g.
 *             '1001000100000100100010001'
 *             ['1', '1', '1', '0', '0']
 */
export const runLengthEncode = (text: string | string[]) => {
  if (!text) return text;
  let result = '';

  // Note: We are NOT using RegExp solution like text.match(/(.)\1*/g) as it does not work for large
  // consecutive similar chars.
  // e.g. input Array(5e6).fill('1').join('') will cause RangeError: Maximum call stack size exceeded
  // because String.match implementation is recursive.

  // text[i, j) is a consecutive sub string.
  let i = 0;
  let j = 0;
  const len = text.length;
  while (j < len) {
    const ch = text[i];
    // find a different letter
    while (j < len && text[j] == ch) {
      ++j;
    }
    // add encoded string for this sub string
    const substrLen = j - i;
    result += substrLen + rleEncodeMap[ch];
    i = j;
  }

  return result;
};

export const runLengthDecode = (text: string) => {
  if (!text) return text;
  /**
   * Groups all encoded pieces together
   * 1N2Z1N3Z1N5Z1N2Z1N3Z1N3Z1N =>
   * ["12Z", "1N", "3Z", "1N", "5Z", "1N", "2Z", "1N", "3Z", "1N", "3Z", "1N"]
   */
  const matches = text.match(/(\d+)(\w|\s)/g);
  /**
   * Repeat each piece's last char with number
   * 3Z = 000 1N = 1
   */
  return matches!.reduce((acc, str) => {
    const decodedKey = rleDecodeMap[str.slice(-1)];
    const times = Number(str.slice(0, str.length - 1));
    return `${acc}${decodedKey.repeat(times)}`;
  }, '');
};

import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { Button } from '@clef/client-library';
import MemberTable from './components/MemberTable';
import { inviteUser } from '../../store/user_store';
import { LegacyReduxStates } from '../../store/types';
import { User, PendingUser } from '@clef/shared/types';
import SearchBox from '../../components/Text/SearchBox';
import { useExternalSearchText } from '../../hooks/MaterialTable';
import AddMembersToPlatformDialog from '../../components/AddMembersToPlatformDialog/AddMembersToPlatformDialog';
import AddIcon from '@material-ui/icons/Add';
import { EnvironmentEnum, getEnv } from '../../constants';

interface ReduxStateProps {
  users?: User[];
  pendingUsers: PendingUser[];
}
interface ReduxStateActions {
  inviteUser: (...args: any[]) => any;
}
type Props = ReduxStateProps & ReduxStateActions;

const MemberView: React.FunctionComponent<Props> = ({ users, pendingUsers, inviteUser }: Props) => {
  const [isDialogOpen, setDialogOpen] = useState(false);

  const env = getEnv();
  const isOnprem = env === EnvironmentEnum.Onprem;

  const tableRef = useRef<any>();
  const { setSearchText } = useExternalSearchText(tableRef);
  return (
    <>
      {users && (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {!isOnprem && (
                <Grid item>
                  <Button
                    id="invite-members-by-email"
                    variant="contained"
                    color="primary"
                    className="cy-member-invite-button"
                    onClick={() => setDialogOpen(true)}
                    startIcon={<AddIcon />}
                  >
                    {t('Invite members by email')}
                  </Button>
                </Grid>
              )}
              <Grid item xs>
                <Grid container direction="row-reverse">
                  <SearchBox
                    type="text"
                    placeholder={t('Search')}
                    onKeyDown={(e: React.KeyboardEvent): void => {
                      if (e.keyCode === 13) {
                        // @ts-ignore
                        setSearchText(e.target.value);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={8}>
              <Grid item>
                <Typography variant="body2">
                  {t('Active members: {{count}}', {
                    count: <b>{users.length}</b>,
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MemberTable active={users} pending={pendingUsers} tableRef={tableRef} withPending />
            {isDialogOpen && (
              <AddMembersToPlatformDialog
                open
                onClose={(): void => setDialogOpen(false)}
                inviteUser={inviteUser}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state: LegacyReduxStates): ReduxStateProps => ({
  users: state.user.users,
  pendingUsers: state.user.pendingUsers,
});

const actions = { inviteUser };
export default connect(mapStateToProps, actions)(MemberView);

import { Media, AnnotationOldFormat, UserId, MediaId, DefectId, RangeBox } from './basic';
import { JobInfo } from './maglev';

export enum ConfusionMatrixKey {
  TP = 'TP',
  TN = 'TN',
  FP = 'FP',
  FN = 'FN',
  incorrect = 'incorrect',
  correct = 'correct',
  Miscategorized = 'Miscategorized',
}

export enum ModelType {
  Segmentation = 'segmentation',
  Classification = 'classification',
  ObjectDetection = 'object-detection',
  AnomalyDetection = 'anomaly-detection',
}

export type JobId = JobInfo['jobId'];

export interface Metrics {
  accuracy?: number;
  precision?: number;
  recall?: number;
  mAP?: number;
  mean_iou?: number;
  AUC?: number;
  balancedAccuracy?: number;
  f1Score?: number;
}

export interface ClassLevelMetric {
  [key: string]: Metrics;
}

export interface NumberOfMediasPerClass {
  [key: string]: number;
}

export type ModelMetadata = {
  jobId: string;
  datasetId: string;
  numberOfMedias: number;
  reportDescription?: string;
  createdAt?: string;
};

export type ModelJobDetails = {
  jobId: string;
  jobName: string;
  jobDescription: string;
  favorite: boolean;
  confidenceThreshold?: number;
  metrics: Metrics;
};

// legacy type
export type PredictionMeta = {
  id: number;
  project_id: number;
  job_id: JobInfo['id'] | null;
  csv_path: string;
  exported_dataset_id: number | null;
  created_at: string;
  user_id: UserId;
  model_type: ModelType;
  description: string | null;
  defect_map_path: string;
  exported_dataset_description?: string | null;
};

// API specific
export type PredictionInfo = {
  ground_truth: string;
  prediction: string | null;
  type: ConfusionMatrixKey;
  iou?: number;
  ground_truth_id?: string;
};

// legacy type
type SharedReportDetailRow = {
  media_id?: number | null;
  file_path: string;
  // platform backend fields
  file_path_url?: string;
  ground_truth_media?: Media;
  ground_truth_annotation?: Record<string, AnnotationOldFormat>;
};

// legacy type
export type SingleModelReportDetailRow = SharedReportDetailRow & {
  prediction: string;
  ground_truth?: string;
  processed_file_path?: string;
  prediction_info: { info: PredictionInfo[] };
  debug_file: string;
  // platform backend fields
  debug_file_url?: string;
  processed_file_url?: string;
};

export type BboxAnnotation = {
  defectName: string;
  defectId: number | null;
  annotationType: string;
  dataVersion: string;
  annotationData: {
    xmin: number;
    ymin: number;
    xmax: number;
    ymax: number;
  };
};

// legacy type
export type SingleModelReportDetailOutputRow = {
  media_id: number | null;
  file_path_url: string;
  debug_file_url: string;
  processed_file_url: string;
  prediction: BboxAnnotation[];
  ground_truth: BboxAnnotation[];
  prediction_info: { info: PredictionInfo[] };
  // To be removed: The varaiables below are kept for backward compatibility.
  ground_truth_media?: Media;
  ground_truth_annotation?: Record<string, AnnotationOldFormat>;
};

// legacy type
export type ComparisonReportDetailRow = SharedReportDetailRow & {
  media_id_baseline?: number | null;
  processed_file_path_baseline?: string;
  processed_file_url?: string;
  ground_truth_baseline?: string;
  prediction_baseline: string;
  prediction_info_baseline: { info: PredictionInfo[] };
  debug_file_baseline: string;
  ground_truth_candidate?: string;
  prediction_candidate: string;
  prediction_info_candidate: { info: PredictionInfo[] };
  debug_file_candidate: string;
  _merge: string;
  // platform backend fields
  debug_file_baseline_url?: string;
  debug_file_candidate_url?: string;
};

// legacy type
export type ComparisonReportDetailOutputRow = {
  media_id: number | null;
  file_path_url: string;
  processed_file_url: string;
  ground_truth: BboxAnnotation[];
  debug_file_baseline_url: string;
  prediction_baseline: BboxAnnotation[];
  prediction_info_baseline: { info: PredictionInfo[] };
  debug_file_candidate_url: string;
  prediction_candidate: BboxAnnotation[];
  prediction_info_candidate: { info: PredictionInfo[] };
  // To be removed: The varaiables below are kept for backward compatibility.
  ground_truth_media?: Media;
  ground_truth_annotation?: Record<string, AnnotationOldFormat>;
};

// legacy type
type SharedReportConfusionMatrixTypeRow = {
  ground_truth: string;
  prediction: string;
};

type SharedReportConfusionMatrixRow = {
  groundTruth: string | null;
  prediction: string | null;
};

export type SingleModelReportConfusionMatrixRow = SharedReportConfusionMatrixRow & {
  count: number;
};

// legacy type
export type SingleModelReportConfusionMatrixTypeRow = SharedReportConfusionMatrixTypeRow & {
  count: number;
};

// legacy type
export type ComparisonReportConfusionMatrixTypeRow = SharedReportConfusionMatrixTypeRow & {
  count_baseline: number;
  count_candidate: number;
  count_diff: number;
};

export type ComparisonReportConfusionMatrixRow = SharedReportConfusionMatrixRow & {
  candidateCount: number;
  baselineCount: number;
};

// legacy type
type SingleModelReportSummary = {
  total_rows: number;
};

// legacy type
type ComparisonModelReportSummary = {
  baseline_rows: number;
  candidate_rows: number;
  baseline_predictions: number;
  candidate_predictions: number;
  common_rows: number;
  mismatched_rows: number;
};

// legacy type
export type SingleModelReportData = {
  summary: SingleModelReportSummary;
  confusion_matrix: Partial<Record<ConfusionMatrixKey, SingleModelReportConfusionMatrixTypeRow[]>>;
  metadata: {
    prediction: PredictionMeta;
  };
};

// legacy type
export type SingleModelReportDetailsData = {
  details: SingleModelReportDetailRow[];
  page: number;
  total_rows: number;
  metadata: {
    prediction: PredictionMeta;
  };
};

// legacy type
export type SingleModelReportDetailsOutputData = {
  details: SingleModelReportDetailOutputRow[];
  page: number;
  total_rows: number;
  metadata: {
    prediction: PredictionMeta;
  };
};

// legacy type
export type ComparisonReportData = {
  summary: ComparisonModelReportSummary;
  confusion_matrix: Partial<Record<ConfusionMatrixKey, ComparisonReportConfusionMatrixTypeRow[]>>;
  metadata: {
    baseline_prediction: PredictionMeta;
    candidate_prediction: PredictionMeta;
  };
};

// legacy type
export type ComparisonReportDetailsData = {
  details_common: ComparisonReportDetailRow[];
  page: number;
  total_rows: number;
  metadata: {
    baseline_prediction: PredictionMeta;
    candidate_prediction: PredictionMeta;
  };
};

// legacy type
export type ComparisonReportDetailsOutputData = {
  details_common: ComparisonReportDetailOutputRow[];
  page: number;
  total_rows: number;
  metadata: {
    baseline_prediction: PredictionMeta;
    candidate_prediction: PredictionMeta;
  };
};

export type ResponseData = {
  statusCode: number;
  body: string;
};

export type ObjectDetectionLabel = {
  labelName: string;
  defectId: DefectId | null;
  coordinates: {
    xmin: number;
    xmax: number;
    ymin: number;
    ymax: number;
  };
};

export type SegmentationLabel = {
  imageHeight: number;
  imageWidth: number;
  numClasses: number;
  encoding: {
    algorithm: string;
    options: { map: Record<string, number> };
  };
  bitmaps: Record<string, { labelName: string; defectId: DefectId; bitmap: string }>;
};

export type ClassificationLabel = Record<
  string,
  | {
      labelName: string;
      type: string;
      score?: number;
    }
  | string
>;

export type AnomalyDetectionLabel = {
  labelName: string;
  defectId: number;
  labelIndex: number;
  score?: number;
};

export type SingleModelReportConfusionMatrix =
  | Record<number, Partial<Record<ConfusionMatrixKey, SingleModelReportConfusionMatrixRow[]>>>
  | Partial<Record<ConfusionMatrixKey, SingleModelReportConfusionMatrixRow[]>>;

export type SingleModelReportSummaryData = {
  confusionMatrix: SingleModelReportConfusionMatrix;
  classLevelMetrics: ClassLevelMetric | Record<string, ClassLevelMetric>;
  metrics: Metrics | Record<string, Metrics>;
};

export type SingleModelReport = {
  type: ModelType;
  metadata: ModelMetadata;
  summary: SingleModelReportSummaryData;
  classLevelMetrics: ClassLevelMetric;
  numberOfMediasPerClass: NumberOfMediasPerClass;
  jobDetails: ModelJobDetails;
};

export type ReportDetailLabel =
  | string // to be deprecated soon
  | ClassificationLabel
  | SegmentationLabel
  | Record<string, ObjectDetectionLabel>
  | AnomalyDetectionLabel;

export type SingleModelReportDetails = {
  type: ModelType;
  totalItems: number;
  details: {
    prediction: ReportDetailLabel;
    groundTruth: ReportDetailLabel;
    attentionHeatmap?: string | null;
    image: string;
    score: number | null;
    type: ConfusionMatrixKey;
    mediaId: MediaId;
    objectDetectionVisuals: null | {
      groundTruth: Record<string, ObjectDetectionLabel>;
      prediction: Record<string, ObjectDetectionLabel & { score: number }>;
    };
    segmentationVisuals: null | {
      groundTruth: SegmentationLabel;
      prediction: SegmentationLabel;
    };
    groundTruthPredictionLinks:
      | {
          groundTruthId: string | null;
          predictionId: string | null;
          iou: number;
          type: ConfusionMatrixKey;
        }[]
      | null;
  }[];
};

export type ComparisonReportConfusionMatrix =
  | Record<number, Partial<Record<ConfusionMatrixKey, SingleModelReportConfusionMatrixRow[]>>>
  | Partial<Record<ConfusionMatrixKey, ComparisonReportConfusionMatrixRow[]>>;

export type ComparisonReportSummaryData = {
  confusionMatrix: ComparisonReportConfusionMatrix;
  baselineMetrics: Metrics | Record<string, Metrics>;
  candidateMetrics: Metrics | Record<string, Metrics>;
  numberOfMediasPerClass?: Record<string, number>;
};

export type ComparisonReport = {
  type: ModelType;
  candidateMetadata: ModelMetadata;
  baselineMetadata: ModelMetadata;
  summary: ComparisonReportSummaryData;
  baselineClassLevelMetrics: ClassLevelMetric;
  candidateClassLevelMetrics: ClassLevelMetric;
  baselineNumberOfMediasPerClass: NumberOfMediasPerClass;
  candidateNumberOfMediasPerClass: NumberOfMediasPerClass;
  baselineJobDetails: ModelJobDetails;
  candidateJobDetails: ModelJobDetails;
};

export type ComparisonReportDetails = {
  type: ModelType;
  totalItems: number;
  details: {
    groundTruth: string | SegmentationLabel | Record<string, ObjectDetectionLabel>;
    baselineDetails: {
      attentionHeatmap?: string | null;
      prediction: string | SegmentationLabel | Record<string, ObjectDetectionLabel>;
      score: number | null;
      type: ConfusionMatrixKey;
      objectDetectionVisuals: null | {
        groundTruth: Record<string, ObjectDetectionLabel>;
        prediction: Record<string, ObjectDetectionLabel & { score: number }>;
      };
      segmentationVisuals: null | {
        groundTruth: SegmentationLabel;
        prediction: SegmentationLabel;
      };
    };
    candidateDetails: {
      attentionHeatmap?: string | null;
      prediction: string | SegmentationLabel | Record<string, ObjectDetectionLabel>;
      score: number | null;
      type: ConfusionMatrixKey;
      objectDetectionVisuals: null | {
        groundTruth: Record<string, ObjectDetectionLabel>;
        prediction: Record<string, ObjectDetectionLabel & { score: number }>;
      };
      segmentationVisuals: null | {
        groundTruth: SegmentationLabel;
        prediction: SegmentationLabel;
      };
    };
    image: string;
    mediaId: MediaId;
  }[];
};

export interface ActionItemsResponse {
  jobId: JobId;
  mediaId: MediaId;
  errorId: string;
  actionItems: string[];
}
interface MatchPoint {
  x: number;
  y: number;
}

interface CorrelatedTrainingImageGroundTruth {
  defectId: DefectId;
  coordinates: Array<number>; // coordinates in order ["xmin","ymin","xmax","ymax"]
}

export interface CorrelatedTrainingImageSimilarPredictions {
  groundTruth: CorrelatedTrainingImageGroundTruth[];
  image: string;
  matchPoint: MatchPoint;
  mediaId: MediaId;
  similarityScore: number;
}
export interface CorrelatedTrainingImagesResponse {
  queryErrorId: string;
  queryLocation: MatchPoint;
  queryMediaId: number;
  topSimilarPredictions: CorrelatedTrainingImageSimilarPredictions[];
}

export enum MetricLabel {
  // Training/learning curve metircs
  loss_train = 'loss',
  loss_val = 'loss_val',
  mean_iou_train = 'training_mean_iou',
  mean_iou_val = 'training_mean_iou_val',
  // Below two metrics are only used in the v1 segmentation training jobs
  // we need them for compatibility
  old_mean_iou_train = 'mean_iou',
  old_mean_iou_val = 'mean_iou_val',
  // Eval metrics
  precision = 'precision',
  recall = 'recall',
  // Single summary eval metric
  mAP = 'mAP',
  AUC = 'AUC',
  mIOU = 'mean_iou',
  mIoU_val = 'mIoU_val', // seg
  mAP_val = 'mAP_val', // od
  f1_val = 'f1_val', // cls
}

export type ImageMetricsSegmentationMediasInput = {
  media_id: number;
  gt_seg_path: string;
  pred_seg_path: string;
}[];

export type ImageMetricsObjectDetectionMediasInput = {
  media_id: number;
  ground_truth_boxes: {
    bbox: RangeBox;
    annotation_id: number;
    defect_id: number;
  }[];
  prediction_boxes: {
    bbox: RangeBox;
    annotation_id: number;
    defect_id: number;
    confidence: number;
  }[];
}[];

export type ImageMetricsMediasInput =
  | ImageMetricsSegmentationMediasInput
  | ImageMetricsObjectDetectionMediasInput;

export type ImageMetricsOutputMapping = {
  gt_annotation_id: number | null;
  pred_annotation_id: number | null;
  gt_defect_id: number | null;
  pred_defect_id: number | null;
  min_threshold: number;
  max_threshold: number;
}[];

export type ImageMetricsOutputV2 = {
  media_id: number;
  metrics: {
    tps: number[];
    fps: number[];
    tns: number[];
    fns: number[];
    mcs?: number[];
  };
  mapping: ImageMetricsOutputMapping;
  class_level_matrix_path?: string | null;
}[];

export type DatasetLimits = {
  maxLabeledMedia: number;
  minLabeledMedia: number;
  minImageArea: number;
  largeImageThresholdArea: number;
  largeImageMaxArea: number;
};

export type TransformsRuleParamValue = {
  properties: {
    width?: { min: number; max: number; default: number; title?: string; type?: string };
    height?: { min: number; max: number; default: number; title?: string; type?: string };
    area?: { min: number; max: number; default: number; title?: string; type?: string };
  } & {
    [key: string]: { min: number; max: number; default: number };
  };
  title: string;
  type: string;
};

export type TransformsRules = {
  default: Array<string>; // list with default transforms
  params: Record<string, TransformsRuleParamValue>; // dict with transform rules
};

export type HyperParamsSchema = {
  type: string;
  title: string;
  definitions: {
    BackboneParams: {
      properties: { name: { title: string; type: string } };
      required: Array<string>;
      title: string;
      type: string;
    };
    LearningParams: {
      properties: {
        epochs: { minimum: number; maximum: number; title?: string; type?: string };
      };
      required: Array<string>;
      title: string;
      type: string;
    };
    NonMaxSuppressionParams: {
      properties: {
        iou_threshold: { minimum: number; maximum: number; title?: string; type?: string };
      };
      required: Array<string>;
      title: string;
      type: string;
    };
  };
  properties: Record<string, any>;
  required: Array<string>;
};

export type ModelArchSchemaItem = {
  name: string; // archName
  modelSize: string;
  schema: HyperParamsSchema; // json schema with hyperParams.model rules
  datasetLimits: DatasetLimits;
  preprocessing: TransformsRules;
  augmentations: TransformsRules;
};

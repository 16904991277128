import React, { useEffect, useState, useCallback } from 'react';
import cx from 'classnames';
import Dropdown from '../Dropdown';
import { Typography, makeStyles } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';

const selectorHeight = 32;

const useStyles = makeStyles(theme => ({
  selector: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #B0B0B0',
    borderRadius: selectorHeight / 2,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    overflow: 'hidden',
    cursor: 'pointer',
    height: selectorHeight,
    '&:hover': {
      borderColor: theme.palette.common.black,
    },
  },
  selectorText: {
    paddingRight: theme.spacing(2),
  },
  dropdownOptions: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxHeight: 320,
    overflowY: 'auto',
  },
  optionItem: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    height: selectorHeight,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 13,
    lineHeight: 1.9,
    paddingRight: 37,
    '&:hover': {
      backgroundColor: 'rgba(9, 30, 66, 0.08)',
    },
  },
  optionItemSelected: {
    backgroundColor: 'rgba(9, 30, 66, 0.16)',
    '&:hover': {
      backgroundColor: 'rgba(9, 30, 66, 0.16)',
    },
  },
  dropdownRoot: {
    display: 'inline-block',
  },
}));

export interface SelectOptionsProps {
  options: (string | number)[];
  value: string | number;
  disabled?: boolean;
  onChange: (option: string | number, index: number) => void;
  classes?:
    | {
        selector?: string | undefined;
        dropdownRoot?: string | undefined;
        dropdownOptions?: string | undefined;
      }
    | undefined;
  size?: 'small' | 'medium';
  renderValue?: (value: number | string) => number | string;
}

const SelectOptions: React.FC<SelectOptionsProps> = ({
  options,
  value,
  onChange,
  classes,
  disabled,
  size = 'small',
  renderValue,
}) => {
  const styles = useStyles();
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const onSelectOption = useCallback(
    (option: string | number, index: number) => {
      onChange(option, index);
      setSelectedValue(option);
    },
    [onChange],
  );

  const overflow = options.length > 10;

  const scrollIntoViewIfSelected = useCallback(
    (enabled: boolean) => (ref: HTMLDivElement | null) => {
      if (ref && overflow && enabled) {
        ref.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    },
    [overflow],
  );

  const extraTop = 8 + (options.findIndex(option => option === selectedValue) + 1) * selectorHeight;
  return (
    <Dropdown
      extraGutter={{ vertical: -(extraTop > 320 ? 184 : extraTop) }}
      classes={{ root: cx(styles.dropdownRoot, classes?.dropdownRoot) }}
      disabled={disabled}
      dropdown={toggleOpen => (
        <div className={cx(styles.dropdownOptions, classes?.dropdownOptions)} role="select-options">
          {options.map((option, index) => {
            const isOverFlown = index >= 9;
            return (
              <Typography
                variant={size === 'small' ? 'body2' : 'body1'}
                key={option}
                ref={scrollIntoViewIfSelected(option === selectedValue && isOverFlown)}
                onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  event.stopPropagation();
                  onSelectOption(option, index);
                  toggleOpen(false);
                }}
                className={cx(
                  styles.optionItem,
                  option === selectedValue && styles.optionItemSelected,
                )}
                component="div"
                data-testid={`dropdown-option-${option}`.toLowerCase().replace(/\s/g, '-')}
              >
                {renderValue?.(option) ?? option}
              </Typography>
            );
          })}
        </div>
      )}
    >
      <div className={cx(styles.selector, classes?.selector)} data-testid={'dropdown-selector'}>
        <Typography variant={size === 'small' ? 'body2' : 'body1'} className={styles.selectorText}>
          {renderValue?.(selectedValue) ?? selectedValue}
        </Typography>
        <ExpandMore fontSize="small" />
      </div>
    </Dropdown>
  );
};

export default SelectOptions;

import { MediaDetailsForLabelingReview } from '@clef/shared/types';

export const checkRenderAgreementThreshold = (
  reviewMediaList: MediaDetailsForLabelingReview[],
  currentMedia: MediaDetailsForLabelingReview,
  currentIndex: number,
  currentOrder: 'asc' | 'desc',
  currentThreshold: number,
): { renderDividerAfter: boolean; renderDividerBefore: boolean } => {
  const mediaAgreementScore = currentMedia.overallAgreementScore ?? 0;
  let nextMediaAgreementScore: number;
  let prevMediaAgreementScore: number;
  let renderDividerAfter: boolean = false;
  let renderDividerBefore: boolean = false;

  // calculate nextMediaAgreementScore
  if (currentIndex === reviewMediaList.length - 1) {
    nextMediaAgreementScore = currentOrder === 'asc' ? 1.1 : -0.1;
  } else {
    nextMediaAgreementScore = reviewMediaList[currentIndex + 1]?.overallAgreementScore || 0;
  }

  // calculate prevMediaAgreementScore
  if (currentIndex === 0) {
    prevMediaAgreementScore = currentOrder === 'asc' ? -0.1 : 1.1;
  } else {
    prevMediaAgreementScore = reviewMediaList[currentIndex - 1]?.overallAgreementScore || 0;
  }

  if (currentOrder === 'asc') {
    // if the prev media didn't reach threshold but current media reached
    if (prevMediaAgreementScore < currentThreshold && mediaAgreementScore >= currentThreshold) {
      renderDividerBefore = true;
    }
    // if the last one didn't reach threshold
    if (currentIndex === reviewMediaList.length - 1 && mediaAgreementScore < currentThreshold) {
      renderDividerAfter = true;
    }
  }

  if (currentOrder === 'desc') {
    // if the prev media didn't reach threshold but current media reached
    if (nextMediaAgreementScore < currentThreshold && mediaAgreementScore >= currentThreshold) {
      renderDividerAfter = true;
    }
    // if the first one didn't reach threshold
    if (currentIndex === 0 && mediaAgreementScore < currentThreshold) {
      renderDividerBefore = true;
    }
  }
  return { renderDividerAfter, renderDividerBefore };
};

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { Button, SwitchButtonGroup } from '@clef/client-library';
import GridViewIcon from './icons/GridViewIcon';
import { ViewSideBarIcon } from './icons/ViewSidebarIcon';
import classNames from 'classnames';
import { InstantLearningState, useInstantLearningState } from './state';

const useStyles = makeStyles(theme => ({
  button: {
    padding: theme.spacing(2.5, 2.5),
    minWidth: 'unset',
  },
}));

export type ViewImagesButtonGroupProps = {
  onClick?(browseImagesMode: InstantLearningState['browseImagesMode']): void;
};

const ViewImagesButtonGroup: React.FC<ViewImagesButtonGroupProps> = props => {
  const { onClick } = props;
  const styles = useStyles();
  const theme = useTheme();
  const { state } = useInstantLearningState();
  const { browseImagesMode } = state;
  return (
    <SwitchButtonGroup>
      <Button
        id="browse-images-sidebar"
        className={classNames(styles.button, browseImagesMode === 'sidebar' && 'active')}
        tooltip={t('View Images in Sidebar')}
        onClick={() => onClick?.('sidebar')}
      >
        {<ViewSideBarIcon />}
      </Button>
      <Button
        id="browse-images-fullscreen"
        className={classNames(styles.button, browseImagesMode === 'fullscreen' && 'active')}
        tooltip={t('View Images in Full Screen')}
        onClick={() => onClick?.('fullscreen')}
      >
        {<GridViewIcon color={theme.palette.primary.main} />}
      </Button>
    </SwitchButtonGroup>
  );
};

export default ViewImagesButtonGroup;

import { useEffect, useReducer, useRef } from 'react';
import LRU from 'lru-cache';
import { ApiErrorType } from '../../api/base_api';
import { APICache } from 'src/types/client';
import { isEqual } from 'lodash';
import { isJestEnv } from '@clef/client-library/src/utils/env';
import { sleep } from '@clef/shared/utils';

// make unit test faster
export const DEFAULT_POLLING_INTERVAL = isJestEnv ? 500 : 3000;

type UseHookType<K, R> = (
  params: K | undefined,
  /**
   * If set to true, each polling will trigger rerender. Set to true if you want to react on polling status changes,
   * like pollingCount, isPolling etc, in which case the response may not change.
   *
   * If no value or set to false, only data change will trigger rerender.
   * Used who only cares about api response changes.
   */
  listenToPollingChange?: boolean,
) => [
  // api response
  R | undefined,
  // loading / polling
  boolean,
  // api error
  ApiErrorType | undefined,
  // mutate helper
  (updateFn: (currentData?: R) => R | undefined) => void,
  // pollingCount.
  // returns 0 is polling is not enabled;
  // otherwise, returns non-zero number to present the number of polling. starts from 1
  number,
];

type FetchHookOptions<K, R> = {
  // when cacheStrategy='stale-while-revalidate', you can choose to keep stale response when new refresh result in error
  keepStaleResponseOnError?: boolean;
  // pass this predicator function to enable polling.
  // after each fetch, we use this predicator to check if we should continue polling.
  // See https://docs.google.com/document/d/164uV-l4vpsHFiD7NCd1g0UotwriEfynqfEN7sGZYU3U for more details on polling
  shouldPoll?: (res: R, params?: K) => boolean;
  // polling interval (milliseconds), default value is 3000 (ms)
  pollingIntervalMs?: number;
};

const convertParamsToKey = <T>(params: T): string | number | undefined => {
  const paramsType = typeof params;
  // @ts-ignore params type is correct, typescript can't figure this out
  return paramsType === 'string' || paramsType === 'number' || paramsType === 'undefined'
    ? params
    : JSON.stringify(params);
};

type ApiHookState<R> = {
  data?: R;
  isLoading: boolean;
  error?: ApiErrorType;
};

type ApiHookAction<R> =
  | { type: 'SET_LOADING' }
  | { type: 'SET_RESPONSE'; response: R | undefined }
  | { type: 'SET_FAILURE'; error: ApiErrorType }
  | { type: 'SET_FAILURE_ONLY'; error: ApiErrorType };

type RefreshParams<K, R> = {
  /**
   * The keys(params) you wish to revalidate and refresh
   * @option K              : single key(param) to refresh
   * @option K[]            : array of keys(params) to refresh
   * @option 'refresh-all'  : refresh all keys(params) exist in cache
   */
  keys: K[] | K | 'refresh-all';
  /**
   * stand for "stale-while-revalidate", keep a stale data in place while re-fetch for new data
   * @option true     : you want "stale-while-revalidate", stale data use existing cache data while refresh for new data
   * @option {value}  : you want "stale-while-revalidate", stale data use your given new data
   * @option R => R   : you want "stale-while-revalidate", stale data use your given new data based on previous data
   * @option false    : you do not want "stale-while-revalidate", fallback to loading state during re-fetch for new data
   */
  swr?: boolean | R | ((prev?: R) => R | undefined);
};

type RefreshMethod<K, R> = (params: RefreshParams<K, R>, restartPolling?: boolean) => Promise<void>;

// WARNING: do not use this unless you know what you are doing, any action directly applied to cache will not result in ui update
export const UNSAFE_cacheRegistry: APICache = [];
if (typeof window !== 'undefined') {
  // @ts-ignore
  window.UNSAFE_cacheRegistry = UNSAFE_cacheRegistry;
}

/**
 * constructor for creating an api hook to fetch data from given async method, with caching mechanism
 * @param fetchMethod The async fetch function
 * @param cacheStrategy 'cache' | 'stale-while-revalidate' | 'always-refresh'
 * @return [apiHook, refreshHookMethod]
 */
const fetchHookFactory = <K, R>(
  // The async fetch function
  fetchMethod: (params: K, prevRes?: R) => Promise<R>,
  /**
   * caching strategy
   * @option 'cache' : when params change, will prioritize use response from cache, and does not make new request
   * @option 'stale-while-revalidate' : you want "stale-while-revalidate", stale data use your given new data
   * @option 'always-refresh' : you do not want "stale-while-revalidate", fallback to loading state during re-fetch for new data
   *
   * If options.shouldPoll is specified, caching strategy will be forced to 'cache'.
   */
  cacheStrategy: 'cache' | 'stale-while-revalidate' | 'always-refresh',
  options?: FetchHookOptions<K, R>,
): [UseHookType<K, R>, RefreshMethod<K, R>] => {
  const enablePolling = !!options?.shouldPoll;
  if (enablePolling) {
    // stale-while-revalidate and always-refresh will fetch every time when the hook is used,
    // which makes no sense for polling.
    // force to 'cache' if polling is enabled.
    cacheStrategy = 'cache';
  }
  // if cache option is not passed, do not create LRU cache
  const cache =
    cacheStrategy === 'always-refresh'
      ? undefined
      : new LRU<string | number, { timestamp: number; data: Promise<R | undefined> }>({
          max: 300,
          maxAge: 1000 * 60 * 15, // 15 min
        });
  cache && UNSAFE_cacheRegistry.push(cache);
  // for each fetchHook, create a refreshListener that keeps track of all the listeners inside hooks
  const refreshListeners: RefreshMethod<K, R>[] = [];

  const subscribeListener = (newListener: RefreshMethod<K, R>) => {
    refreshListeners.push(newListener);
    // return method to unsubscribe;
    return () => {
      const listenerIndex = refreshListeners.indexOf(newListener);
      if (listenerIndex >= 0) {
        refreshListeners.splice(listenerIndex, 1);
      }
    };
  };

  const triggerRefreshListener = async (params: RefreshParams<K, R>, restartPolling?: boolean) => {
    await Promise.all(refreshListeners.map(listener => listener(params, restartPolling)));
  };

  const pollingStates = {} as {
    [cacheKey: string | number]: {
      listenerCount: number;
      pollingCount: number;
      polling: boolean;
    };
  };
  const initPollingStatusIfNeeded = (cacheKey: string | number, force?: boolean) => {
    if (cacheKey !== undefined && (!(cacheKey in pollingStates) || force)) {
      pollingStates[cacheKey] = {
        listenerCount: 0,
        pollingCount: 0,
        polling: true,
      };
    }
  };
  const useApiHook: UseHookType<K, R> = (params, listenToPollingChange) => {
    const [state, dispatch] = useReducer(
      (state: ApiHookState<R>, action: ApiHookAction<R>) => {
        switch (action.type) {
          case 'SET_LOADING':
            return {
              ...state,
              isLoading: true,
            };
          case 'SET_RESPONSE':
            return {
              data: action.response,
              error: undefined,
              isLoading: false,
            };
          case 'SET_FAILURE':
            return {
              data: undefined,
              error: action.error,
              isLoading: false,
            };
          case 'SET_FAILURE_ONLY':
            return {
              // keep data when SET_FAILURE_ONLY
              data: state.data,
              error: action.error,
              isLoading: false,
            };
          default:
            return state;
        }
      },
      { isLoading: false, data: undefined, error: undefined },
    );
    const stateRef = useRef(state);
    stateRef.current = state;

    const cacheKey = convertParamsToKey(params);
    useEffect(() => {
      let isMount = true;

      const coreFetchAndCache = async (keepStaleResponse?: boolean) => {
        if (isMount && params && cacheKey) {
          isMount && dispatch({ type: 'SET_LOADING' });
          try {
            const cachedPromise = cache?.get(cacheKey)?.data;
            const fetchPromise = cachedPromise || fetchMethod(params, stateRef.current.data);
            // optimistic set cache first
            cache?.set(cacheKey, { timestamp: Date.now(), data: fetchPromise });
            const apiResponse = await fetchPromise;
            // optimize, only update response when data changed.
            const responseChanged = !isEqual(apiResponse, stateRef.current.data);
            if (isMount && (!enablePolling || listenToPollingChange || responseChanged)) {
              dispatch({ type: 'SET_RESPONSE', response: apiResponse });
            }
            return apiResponse;
          } catch (error) {
            isMount &&
              dispatch({
                type: keepStaleResponse ? 'SET_FAILURE_ONLY' : 'SET_FAILURE',
                error: error as ApiErrorType,
              });
            // remove cache on failure
            cache?.del(cacheKey);
          }
        }
        return undefined;
      };

      const fetchOnParamUpdate = async () => {
        if (params && cacheKey) {
          // if we have cached data, always use that for now
          const cachedPromise = cache?.get(cacheKey);
          isMount && dispatch({ type: 'SET_LOADING' });
          if (cachedPromise) {
            const response = await cachedPromise.data;
            isMount && dispatch({ type: 'SET_RESPONSE', response });
          } else {
            isMount && dispatch({ type: 'SET_RESPONSE', response: undefined });
          }

          // if we are 'stale-while-revalidate' or there is no cache, make new request to refresh the data
          if (
            enablePolling ||
            !cachedPromise ||
            (cacheStrategy === 'stale-while-revalidate' &&
              // cache is more than 5 seconds old
              Date.now() - cachedPromise.timestamp > 5000)
          ) {
            return coreFetchAndCache(options?.keepStaleResponseOnError);
          }
          return cachedPromise?.data;
        }
        return undefined;
      };

      if (enablePolling && cacheKey) {
        initPollingStatusIfNeeded(cacheKey);
        const pollingState = pollingStates[cacheKey];
        if (pollingState.listenerCount === 0) {
          // delete cache for the first poll
          cache?.del(cacheKey);
        }
      }
      fetchOnParamUpdate();

      const stopPolling = (cacheKey?: string | number, stopAll?: boolean) => {
        if (cacheKey !== undefined && cacheKey in pollingStates) {
          const pollingState = pollingStates[cacheKey];
          pollingState.listenerCount = Math.max(0, pollingState.listenerCount - 1);

          if (stopAll || pollingState.listenerCount === 0) {
            pollingState.polling = false;
          }
        }
      };

      const startPolling = async (cacheKey?: string | number) => {
        if (cacheKey === undefined) {
          return;
        }
        const pollingState = pollingStates[cacheKey];
        const listenerCount = ++pollingState.listenerCount;

        if (listenerCount === 1) {
          pollingState.pollingCount = 1;
          pollingState.polling = true;
        }

        const firstRes = await coreFetchAndCache();

        if (listenerCount === 1 && firstRes) {
          if (options?.shouldPoll?.(firstRes, params)) {
            // pollingState.polling is true,pollingState.pollingCount >1 ,means another listenerCount is polling
            // so no need to start new polling
            if (pollingState.polling && pollingState.pollingCount > 1) {
              return;
            }
            //send a new request after receive response
            let shouldPolling = true;
            while (shouldPolling && pollingState.polling) {
              cache?.del(cacheKey);
              pollingState.pollingCount++;
              await sleep(options.pollingIntervalMs ?? DEFAULT_POLLING_INTERVAL);
              if (!pollingState.polling) {
                return;
              }
              const res = await coreFetchAndCache();
              if (!res || !options?.shouldPoll?.(res, params)) {
                stopPolling(cacheKey, true);
                shouldPolling = false;
              }
              triggerRefreshListener({ keys: params!, swr: true });
            }
          } else {
            pollingState.polling = false;
            triggerRefreshListener({ keys: params!, swr: true });
          }
        }
      };

      const restartPolling = async (keys: K | 'refresh-all' | K[]) => {
        // restart polling
        if (keys === 'refresh-all') {
          Object.keys(pollingStates).forEach(key => {
            stopPolling(key, /* stopAll */ true);
            pollingStates[key].listenerCount = 0;
          });
          Object.keys(pollingStates).forEach(key => startPolling(key));
        } else if (Array.isArray(keys)) {
          const cacheKeys = keys.map(key => convertParamsToKey(key)!);
          Object.keys(pollingStates).forEach(key => {
            stopPolling(key, /* stopAll */ true);
            pollingStates[key].listenerCount = 0;
          });
          cacheKeys.forEach(cacheKey => startPolling(cacheKey));
        } else {
          const cacheKey = convertParamsToKey(keys);
          stopPolling(cacheKey, /* stopAll */ true);
          if (String(cacheKey) in pollingStates) {
            pollingStates[String(cacheKey)].listenerCount = 0;
          }
          startPolling(cacheKey);
        }
      };

      const subscribePolling = () => {
        startPolling(cacheKey);
        return () => stopPolling(cacheKey);
      };

      const unsubscribePolling = enablePolling ? subscribePolling() : undefined;

      const unsubscribeRefresh = subscribeListener(
        async (refreshParams: RefreshParams<K, R>, shouldRestartPolling?: boolean) => {
          const { keys, swr } = refreshParams;
          // only refresh when our cacheKey is in keys[] or = keys
          const cacheKeyMatch =
            keys === 'refresh-all' ||
            (cacheKey && Array.isArray(keys)
              ? keys.map(_ => convertParamsToKey(_)).includes(cacheKey)
              : convertParamsToKey(keys) === cacheKey);
          if (params && cacheKey && cacheKeyMatch) {
            if (!swr) {
              // reset response
              isMount && dispatch({ type: 'SET_RESPONSE', response: undefined });
            } else if (typeof swr === 'function') {
              // if swr is function, use it to generate new value and use that as temporary reponse
              // @ts-ignore we are sure swr is a function here
              const newVal = swr(stateRef.current?.data) as R;
              isMount && dispatch({ type: 'SET_RESPONSE', response: newVal });
            } else if (swr !== true) {
              // if swr has value, use that as the temporary response
              isMount && dispatch({ type: 'SET_RESPONSE', response: swr });
            }
            // if swr is true, just leave the current state (should be stale response)

            if (enablePolling && shouldRestartPolling) {
              restartPolling(keys);
            }

            // make new request to update up-to-date response
            await coreFetchAndCache();
            return;
          }
          return;
        },
      );

      // unsubscribe after component unmounts, and unset isMount
      return () => {
        isMount = false;
        unsubscribeRefresh();
        unsubscribePolling?.();
      };
      // React does not deep compare deps, so this could trigger infinite loops
      // If params change, cacheKey will change, we are confident here
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cacheKey]);

    // Bound mutate helper for optimistic updates on POST/PUT/DELETE requests.
    // Very loosely inspired by https://swr.vercel.app/docs/mutation#mutation-and-post-request
    const mutate = (updateFn: (currentData?: R) => R | undefined) => {
      const newData = updateFn(state.data);
      dispatch({ type: 'SET_RESPONSE', response: newData });
      if (cacheKey && cache) {
        cache.set(cacheKey, { timestamp: Date.now(), data: Promise.resolve(newData) });
      }
    };

    const pollingState =
      cacheKey !== undefined && cacheKey in pollingStates ? pollingStates[cacheKey] : undefined;

    return [
      state.data,
      enablePolling ? !!pollingState?.polling : state.isLoading,
      state.error,
      mutate,
      pollingState?.pollingCount || 0,
    ];
  };

  return [
    useApiHook,
    (refreshParams: RefreshParams<K, R>) => {
      const { keys } = refreshParams;
      // need to reset outside of listener because we need to clean cache regardless
      // if the there are components using the response or not
      if (keys === 'refresh-all') {
        cache?.reset();
      } else if (Array.isArray(keys)) {
        const cacheKeys = keys.map(key => convertParamsToKey(key)!);
        cacheKeys.forEach(cacheKey => {
          cache?.del(cacheKey);
        });
      } else {
        const cacheKey = convertParamsToKey(keys)!;
        cache?.del(cacheKey);
      }
      // refresh existing components in the ui
      return triggerRefreshListener(refreshParams, /* restartPolling */ true);
    },
  ];
};

export default fetchHookFactory;

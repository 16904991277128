import React, { Suspense, useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { MuiThemeProvider, createTheme, CssBaseline } from '@material-ui/core';
import { landingTheme, searchBrowserAndVersion, useSnackbarStyles } from '@clef/client-library';
import { SignupRouter, LoginRouter } from './components/Layout/routes/AuthRoutes';
import { SnackbarProvider } from 'notistack';
import { LoggingEvent, EventLogger } from '@clef/client-library';
import AppRouter from './components/Layout/AppRouter';
import ErrorPage from './pages/error/ErrorPage';
import { setAuthenticated } from './store/newLoginState/actions';
import * as Sentry from '@sentry/react';
import { Contexts } from '@sentry/types';
import { useTypedSelector } from './hooks/useTypedSelector';
import { useDispatch } from 'react-redux';
import { SnackbarUtilsConfigurator } from './utils/snackbar_singleton';
import SnackbarCloseButton from './components/SnackbarCloseButton';
import Usersnap from './Usersnap';
import { UsersnapApiContextProvider } from './store/usersnapContext';
import BrowserDetectionSnackbar from './components/BrowserDetectionSnackbar/browserDetectionSnackbar';
import { EnvironmentEnum, getEnv } from './constants';
import LdapLoginRoute from './components/Layout/LdapLoginRoute';
import GoogleSSOLoginRoute from './components/Layout/GoogleSSOLoginRoute';
import { refreshAuthenticatedStates } from './store/newLoginState/actions';
import { resetEnabledFeaturesForUser } from './store/feature_toggle_store';
import Login from './pages/login/LoginContainer';
import ZeroAuthInstantLearning from './pages/ZeroAuthInstantLearning';
import CLEF_PATH from './constants/path';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isCypressEnv } from '@clef/client-library/src/utils/env';
import { DevTools as JotaiDevTools } from 'jotai-devtools';
import { queryClient } from '@/serverStore';

const theme = createTheme(landingTheme);
const env = getEnv();
const isOnprem = env === EnvironmentEnum.Onprem;

type AuthenticationRouteType = {
  shouldBeAuthenticated: boolean;
  isAuthenticated: boolean;
  path: string;
};

const AuthenticationRoute: React.FC<AuthenticationRouteType> = ({
  path,
  children,
  shouldBeAuthenticated,
  isAuthenticated,
}) => {
  return (
    <Route
      path={path}
      render={prop =>
        shouldBeAuthenticated === isAuthenticated ? (
          children
        ) : (
          <Redirect to={{ ...prop.location, pathname: '/' }} />
        )
      }
    />
  );
};

const apiTimingLoggingFunc = () => {
  const performanceEntries = performance.getEntriesByType(
    'resource',
  ) as PerformanceResourceTiming[];
  performance.clearResourceTimings();
  const newEntries = performanceEntries
    .filter(_ => _.initiatorType === 'fetch')
    .map(_ => ({
      url: _.name,
      apiResponseTime: _.responseEnd - _.startTime,
      apiNetworkTime: _.responseEnd - _.requestStart,
    }));
  newEntries.forEach(entry => {
    EventLogger.log(LoggingEvent.PerformanceEntryEvent, entry);
  });
};

const App: React.FC = () => {
  const snackbarStyles = useSnackbarStyles();
  const { isOrgUserAuthenticated, isAuthenticated } = useTypedSelector(state => state.login);

  const isFinalAuthenticated = useMemo(
    () => isOrgUserAuthenticated || isAuthenticated,
    [isAuthenticated, isOrgUserAuthenticated],
  );

  // For snowflake, we need to redirect to /sso instead of /login
  const authPath = process.env.IS_SNOWFLAKE === 'true' ? '/sso' : '/login';
  const dispatch = useDispatch();

  useEffect(() => {
    const apiTimingLoggingInterval = setInterval(apiTimingLoggingFunc, 10000);
    performance.onresourcetimingbufferfull = () => {
      apiTimingLoggingFunc(); // log and clear
    };
    window.addEventListener('beforeunload', () => {
      apiTimingLoggingFunc();
    });
    EventLogger.setMethods(
      () => {},
      (errorOrMessage, attributes, scopeFingerPrints) => {
        Sentry.withScope(function (scope) {
          if (scopeFingerPrints && scopeFingerPrints.length) {
            scope.setFingerprint(scopeFingerPrints);
          }
          Sentry.captureException(errorOrMessage, {
            contexts: attributes as Contexts,
          });
        });
      },
    );

    return () => {
      clearInterval(apiTimingLoggingInterval);
    };
  }, []);

  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        dispatch(refreshAuthenticatedStates());

        // If org user is logged out, reset the features info for the user
        if (!localStorage.getItem('clef_is_login')) {
          dispatch(resetEnabledFeaturesForUser());
        }
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [dispatch]);

  return (
    <Suspense fallback={null}>
      <Sentry.ErrorBoundary
        onError={(e, componentStack) => {
          EventLogger.error(e, { errorInfo: e, componentStack });
        }}
      >
        <QueryClientProvider client={queryClient}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
              preventDuplicate
              maxSnack={3}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              classes={{
                root: snackbarStyles.root,
              }}
              action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
            >
              <SnackbarUtilsConfigurator />
              <BrowserDetectionSnackbar message={searchBrowserAndVersion().toString()} />
              <BrowserRouter>
                <Switch>
                  {/* / route is source of truth here */}
                  <Route
                    exact
                    path="/"
                    render={prop => (
                      <Redirect
                        to={{
                          ...prop.location,
                          pathname: isFinalAuthenticated ? '/app' : authPath,
                        }}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/ldap_aes_login"
                    render={prop => {
                      const url = window.location.href;
                      const index = url.indexOf('?');
                      if (isOnprem && index != -1) {
                        // this is for LDAP onprem
                        return (
                          <LdapLoginRoute
                            routeProps={prop}
                            encryptedLoginInfo={url.substring(index + 1)}
                          />
                        );
                      }
                      return <Redirect to={{ ...prop.location, pathname: '/' }} />;
                    }}
                  />
                  <Route
                    exact
                    path="/sso"
                    render={prop => {
                      localStorage.setItem('clef_account_is_login', '1');
                      localStorage.setItem('clef_is_login', '1');
                      dispatch(setAuthenticated(true));
                      return <Redirect to={{ ...prop.location, pathname: '/' }} />;
                    }}
                  />
                  <Route
                    exact
                    path="/login/google_sso"
                    render={() => {
                      return <GoogleSSOLoginRoute />;
                    }}
                  />
                  {/* Zero auth instant learning */}
                  <Route
                    exact
                    path={CLEF_PATH.zeroAuthInstantLearning}
                    render={() => <ZeroAuthInstantLearning />}
                  ></Route>
                  {/* Sign up pages */}
                  <AuthenticationRoute
                    path="/signup"
                    isAuthenticated={isFinalAuthenticated}
                    shouldBeAuthenticated={false}
                  >
                    <SignupRouter />
                  </AuthenticationRoute>
                  {/* Log in pages */}
                  <AuthenticationRoute
                    path={authPath}
                    isAuthenticated={isFinalAuthenticated}
                    shouldBeAuthenticated={false}
                  >
                    <LoginRouter />
                  </AuthenticationRoute>
                  {/* Legacy log in pages */}
                  <AuthenticationRoute
                    path="/legacy_login"
                    isAuthenticated={isFinalAuthenticated}
                    shouldBeAuthenticated={false}
                  >
                    <Login />
                  </AuthenticationRoute>
                  {/* Logged in pages */}
                  <AuthenticationRoute
                    path="/app"
                    isAuthenticated={isFinalAuthenticated}
                    shouldBeAuthenticated
                  >
                    <AppRouter />
                  </AuthenticationRoute>
                  {/* fallback to 404 pages */}
                  <Route render={() => <ErrorPage />} />
                </Switch>
              </BrowserRouter>
            </SnackbarProvider>
            <UsersnapApiContextProvider>
              <Usersnap />
            </UsersnapApiContextProvider>
            {!isCypressEnv() && <JotaiDevTools />}
            {!isCypressEnv() && <ReactQueryDevtools initialIsOpen={false} />}
          </MuiThemeProvider>
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    </Suspense>
  );
};

export default App;

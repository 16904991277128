import { BaseAPI } from './base_api';
import { PublicEndpoint } from '@clef/shared/types';

class PublicEndpointAPI extends BaseAPI {
  async getPublicEndpointById(endpointId: string): Promise<PublicEndpoint> {
    return this.get(`details_by_id`, { endpointId }, true);
  }

  async getPublicEndpoint(projectId: number, type: string): Promise<PublicEndpoint> {
    return this.get(`details`, { projectId, type }, true);
  }

  async changePublicEndpointStateById(endpointId?: string, enable: boolean = true): Promise<any> {
    return this.postJSON('upsert', { endpointId, enable });
  }

  async changePublicEndpointState(
    projectId: number,
    type: string,
    enable: boolean = true,
  ): Promise<any> {
    return this.postJSON('upsert', { enable, projectId, type });
  }
}

export default new PublicEndpointAPI('public_endpoint');

import {
  DefectBookOverviewInfo,
  DefectBookExample,
  DefectBookExampleData,
} from '@clef/shared/types';

import DefectBookOverviewApi from '../../api/defect_book_overview_api';
import DefectBookExampleApi from '../../api/defect_book_example_api';

import fetchHookFactory from './fetchHookFactory';

/**
 * fetch/update DefectBookOverview, key is projectId
 */
const defaultLabelingInstruction = {
  projectTitle: '*Project title*',
  projectBackground: t('*Please add context about this project*'),
  termExamples: [],
  termDescription: '',
};

export const [useFetchDefectBookOverviewApi, refreshDefectBookOverview] = fetchHookFactory(
  async (projectId: number) =>
    (await DefectBookOverviewApi.getData(projectId)).defectBookInfo ??
    (defaultLabelingInstruction as DefectBookOverviewInfo),
  'cache',
);

export const updateDefectBookOverviewApi = async (
  projectId: number,
  newOverviewInfo: DefectBookOverviewInfo,
) => {
  try {
    await DefectBookOverviewApi.updateData(projectId, newOverviewInfo);
  } catch (e) {
    refreshDefectBookOverview({ keys: projectId, swr: newOverviewInfo });
  }
};

/**
 * fetch/update/delete DefectBookExample, key is exampleId
 */
export const [useFetchDefectBookExampleApi, refreshDefectBookExample] = fetchHookFactory(
  (exampleIds: number[]) => DefectBookExampleApi.getExamples(exampleIds),
  'cache',
);

export const updateDefectBookExampleApi = async (
  exampleId: number,
  newExampleInfo: DefectBookExampleData,
) => {
  await DefectBookExampleApi.updateExample(exampleId, newExampleInfo);
  refreshDefectBookExample({ keys: 'refresh-all', swr: true });
};

export const createDefectBookExampleApi = async (
  newExample: DefectBookExampleData,
): Promise<DefectBookExample> => {
  const response: DefectBookExample = await DefectBookExampleApi.createExample(newExample);
  return response;
};

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  stage: {},
  dotTyping: {
    position: 'relative',
    left: -9997,
    top: 6,
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: theme.palette.common.white,
    boxShadow: `9993px 0 0 0 ${theme.palette.common.white}, 9999px 0 0 0 ${theme.palette.common.white}, 10005px 0 0 0 ${theme.palette.common.white}`,
    animation: '$dotTyping 6s infinite linear',
  },
  '@keyframes dotTyping': {
    '0%': {
      opacity: 0.1,
      boxShadow: `9993px 0 0 0 ${theme.palette.common.white}, 9999px 0 0 0 ${theme.palette.common.white}, 10005px 0 0 0 ${theme.palette.common.white}`,
    },
    '4%': {
      boxShadow: `9993px -2px 0 0 ${theme.palette.common.white}, 9999px 0 0 0 ${theme.palette.common.white}, 10005px 0 0 0 ${theme.palette.common.white}`,
    },
    '8%': {
      boxShadow: `9993px -6px 0 0 ${theme.palette.common.white}, 9999px -2px 0 0 ${theme.palette.common.white}, 10005px 0 0 0 ${theme.palette.common.white}`,
    },
    '12%': {
      opacity: 1,
      boxShadow: `9993px -2px 0 0 ${theme.palette.common.white}, 9999px -6px 0 0 ${theme.palette.common.white}, 10005px -2px 0 0 ${theme.palette.common.white}`,
    },
    '16% ': {
      boxShadow: `9993px 0 0 0 ${theme.palette.common.white}, 9999px -2px 0 0 ${theme.palette.common.white}, 10005px -6px 0 0 ${theme.palette.common.white}`,
    },
    '20%': {
      boxShadow: `9993px 0 0 0 ${theme.palette.common.white}, 9999px 0 0 0 ${theme.palette.common.white}, 10005px -2px 0 0 ${theme.palette.common.white}`,
    },
    '24%': {
      boxShadow: `9993px 0 0 0 ${theme.palette.common.white}, 9999px 0 0 0 ${theme.palette.common.white}, 10005px 0 0 0 ${theme.palette.common.white}`,
    },
    '28%': {
      opacity: 1,
      boxShadow: `9993px -2px 0 0 ${theme.palette.common.white}, 9999px 0 0 0 ${theme.palette.common.white}, 10005px 0 0 0 ${theme.palette.common.white}`,
    },
    '32%': {
      boxShadow: `9993px -6px 0 0 ${theme.palette.common.white}, 9999px -2px 0 0 ${theme.palette.common.white}, 10005px 0 0 0 ${theme.palette.common.white}`,
    },
    '36%': {
      boxShadow: `9993px -2px 0 0 ${theme.palette.common.white}, 9999px -6px 0 0 ${theme.palette.common.white}, 10005px -2px 0 0 ${theme.palette.common.white}`,
    },
    '40% ': {
      boxShadow: `9993px 0 0 0 ${theme.palette.common.white}, 9999px -2px 0 0 ${theme.palette.common.white}, 10005px -6px 0 0 ${theme.palette.common.white}`,
    },
    '44%': {
      boxShadow: `9993px 0 0 0 ${theme.palette.common.white}, 9999px 0 0 0 ${theme.palette.common.white}, 10005px -2px 0 0 ${theme.palette.common.white}`,
    },
    '48%': {
      boxShadow: `9993px 0 0 0 ${theme.palette.common.white}, 9999px 0 0 0 ${theme.palette.common.white}, 10005px 0 0 0 ${theme.palette.common.white}`,
      opacity: 1,
    },
    '60%': {
      opacity: 0.1,
    },
    '100%': {
      opacity: 0,
    },
  },
  trainingInProgressCount: {
    position: 'relative',
    top: -2,
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 'bold',
    animation: '$trainingInProgressCount 6s infinite linear',
  },
  '@keyframes trainingInProgressCount': {
    '0%': {
      opacity: 0,
    },
    '30%': {
      opacity: 0,
    },
    '48%': {
      opacity: 0,
    },
    '60%': {
      opacity: 0.1,
    },
    '70%': {
      opacity: 1,
    },
    '90%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.1,
    },
  },
}));
type TrainingInProgressIndicatorProps = {
  count: number;
};
const TrainingInProgressIndicator = (props: TrainingInProgressIndicatorProps) => {
  const styles = useStyles();
  const { count } = props;
  return (
    <div className={styles.stage}>
      <div className={styles.dotTyping}></div>
      <span className={styles.trainingInProgressCount}>{count}</span>
    </div>
  );
};

export default TrainingInProgressIndicator;

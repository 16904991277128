import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ZeroAuthRunRecord } from '@clef/shared/types';
import classNames from 'classnames';
import { ImageItemHeight } from './constants';
import { useSegmentationPredictionDataUrl } from '../DataBrowser/utils';
import { ZeroAuthMediaStates, useAllClasses } from './state';
import SegmentationAnnotation from './SegmentationAnnotation';
import { BitMapLabelingAnnotation } from '@/components/Labeling/labelingState';

const useStyles = makeStyles(theme => ({
  imageItem: {
    position: 'relative',
    borderRadius: 5,
    border: '2px solid transparent',
    overflow: 'hidden',
    cursor: 'pointer',
    transition: 'border 0.2s',
    '&.active, &.active:hover': {
      borderColor: theme.palette.blue[600],
    },
    '& + $imageItem': {
      marginLeft: theme.spacing(3),
    },
    '&:hover': {
      borderColor: theme.palette.greyModern[400],
    },
  },
  image: {
    height: ImageItemHeight,
    verticalAlign: 'middle',
  },
  gtImage: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0.9,
  },
  predImage: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0.5,
  },
}));

export type ImageItemProps = {
  imageItem: Partial<ZeroAuthRunRecord>;
  mediaStates?: ZeroAuthMediaStates;
  active?: boolean;
  onClick?: () => void;
};

const ImageItem: React.FC<ImageItemProps> = props => {
  const { imageItem, mediaStates, active = false, onClick } = props;
  const { annotations } = mediaStates ?? {};
  const styles = useStyles();
  const allClasses = useAllClasses();
  const [predSegUrl] = useSegmentationPredictionDataUrl(
    imageItem.predSegPath ?? '',
    0.5,
    allClasses,
    1,
  );

  return (
    <div
      className={classNames(styles.imageItem, active && 'active')}
      id="select-image"
      data-testid="image-item"
      data-image-id={imageItem.id}
      role="button"
      onClick={onClick}
    >
      <img
        className={styles.image}
        crossOrigin="use-credentials"
        src={imageItem.mediaPath}
        data-testid="image-item-background"
      />
      {predSegUrl && (
        <img
          className={classNames(styles.image, styles.predImage)}
          crossOrigin="use-credentials"
          src={predSegUrl}
        />
      )}
      {annotations?.map(ann => (
        <SegmentationAnnotation
          key={ann.id}
          annotation={ann as BitMapLabelingAnnotation}
          imageDimensions={imageItem}
        />
      ))}
    </div>
  );
};

export default ImageItem;

import { DateStringISO, ProjectId, UserId } from './basic';
import { JSONSchema7 } from 'json-schema';

export type TransformPipelineId = string;
export type TransformId = string;

export type PipelineSection = 'train' | 'valid';

export interface PipelineMetadata {
  createdAt: DateStringISO;
  projectId: ProjectId;
  creatorId: UserId;
  name: string;
  id: TransformPipelineId;
}

export interface TransformParamValue {
  name: string;
  value: any;
}

export interface TransformParams {
  id: TransformId;
  params: TransformParamValue[];
  name?: string;
}

export interface Pipeline {
  metadata: PipelineMetadata;
  sections: Record<PipelineSection, Array<TransformParams>>;
}

export enum TransformType {
  ALL = 'all',
  TRANSFORM = 'transform',
  AUGMENTATION = 'augmentation',
}

export interface TransformParamDescription {
  name: string;
  description?: string;
  jsonSchema: JSONSchema7;
  isRequired: boolean;
  category: 'specific' | 'generic';
}

export interface Transform {
  id: TransformId;
  name: string;
  description: string;
  paramsDescription: Record<string, TransformParamDescription>;
  category:
    | 'Postprocessing'
    | 'Spatial level'
    | 'Pixel level'
    | 'Labels only'
    | 'Compose'
    | 'Others';
  label?: string;
}

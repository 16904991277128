import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import PlanCard, { PlanInfo } from './PlanCard';
import { usePlanPageStateContext } from './state';
import { SubscriptionName } from '@clef/shared/types';
import { useSubscriptionPlans } from '../../hooks/api/useSubscriptionApi';

const PlanSubtitleMap: Record<string, string> = {
  [SubscriptionName.Starter]: t('Best for Individual Practitioners'),
  [SubscriptionName.Visionary]: t('Best for Small Businesses'),
  [SubscriptionName.Enterprise]: t('Best for Large Enterprises'),
};

export type PlanSelectorProps = {};

const ParentPlanTypes: Record<string, string | undefined> = {
  [SubscriptionName.Starter]: undefined,
  [SubscriptionName.Visionary]: t('Starter'),
  [SubscriptionName.Enterprise]: t('Visionary'),
  [SubscriptionName.Trialing]: undefined,
};

const PlanSelector: React.FC<PlanSelectorProps> = () => {
  const { state, dispatch } = usePlanPageStateContext();
  const { plan } = state;
  const selectPlan = (plan: SubscriptionName) => {
    dispatch(draft => {
      draft.plan = plan;
      draft.creditOptionIndex = 0;
    });
  };

  const [planData] = useSubscriptionPlans({});

  const getFeatures = useCallback((planType: SubscriptionName) => {
    const features: { [key: string]: string[] } = {
      [SubscriptionName.Starter as string]: [
        t('Unlimited projects'),
        t('500 images per project'),
        t('10 model versions per project'),
        t('Unlimited Users'),
        t('Unlimited Classes'),
        t('Import labeled images'),
        t('Agreement-Based labeling'),
        t('Metadata management'),
      ],
      [SubscriptionName.Visionary as string]: [
        t('Unlimited projects'),
        t('Up to 10K images per project'),
        t('Unlimited model versions per project'),
        t('Project visibility control'),
        t('SAML SSO'),
      ],
      [SubscriptionName.Enterprise as string]: [
        t('Large image support'),
        t('LandingEdge deployment'),
        t('Regulated Stack'),
        t('Overdraft exception'),
        t('Integration options'),
      ],
    };

    return features[planType];
  }, []);

  const allPlanInfos: PlanInfo[] = (planData || []).map(info => {
    const planType = info.name.toLowerCase() as SubscriptionName;
    return {
      planType,
      planName: info.name,
      basedOnPlan: ParentPlanTypes[planType],
      features: getFeatures(planType),
      planSubtitle: PlanSubtitleMap[planType] ?? '',
    };
  });

  return (
    <Box display="flex" flexWrap="nowrap" justifyContent="stretch" alignItems="stretch">
      {allPlanInfos.map(planInfo => (
        <PlanCard
          key={planInfo.planType}
          active={plan === planInfo.planType}
          planInfo={planInfo}
          onClick={() => selectPlan(planInfo.planType)}
        />
      ))}
    </Box>
  );
};

export default PlanSelector;

import React, { useState, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  makeStyles,
  DialogTitle,
  LinearProgress,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import {
  useDataBrowserState,
  getSelectedMediaCount,
  formatStateToSelectMediaOption,
} from '../dataBrowserState';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useSnackbar } from 'notistack';
import { useRemoveMediaFromDataset } from '../../../hooks/api/useDatasetApi';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import { refreshTaskInfo } from '@/hooks/api/useTaskApi';

const useStyles = makeStyles(theme => ({
  button: {},
  dialogContainer: {
    padding: theme.spacing(5),
    position: 'relative',
    overflow: 'hidden',
  },
  dialogActions: {
    marginTop: theme.spacing(5),
  },
  postRequestMask: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
    background: 'rgba(255,255,255,0.7)',
  },
}));

export interface RemoveMediaDialogProps {
  onClose: () => void;
}

const RemoveMediaDialog: React.FC<RemoveMediaDialogProps> = ({ onClose }) => {
  const styles = useStyles();
  const { id: projectId, datasetId } = useGetSelectedProjectQuery().data ?? {};
  const { state, dispatch } = useDataBrowserState();
  const { enqueueSnackbar } = useSnackbar();
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  const selectedMediaOptions = useMemo(
    () => formatStateToSelectMediaOption(state, currentModelId),
    [state, currentModelId],
  );

  const [isRemoving, setIsRemoving] = useState(false);

  const selectedMediaCount = useMemo(() => getSelectedMediaCount(state), [state]);

  const removeMediaFromDataset = useRemoveMediaFromDataset();
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      {isRemoving && (
        <div className={styles.postRequestMask}>
          <LinearProgress />
        </div>
      )}
      <div className={styles.dialogContainer}>
        <DialogTitle>
          {t('Are you sure you want to remove {{selectedMediaCount}} media from dataset?', {
            selectedMediaCount,
          })}
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            {t(
              'The selected {{selectedMediaCount}} media will be removed from existing labeling tasks (media already labeled will not be affected) and data browser for all future actions.',
              { selectedMediaCount: <strong>{selectedMediaCount}</strong> },
            )}
          </Typography>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button
            id="cancel-remove-media-btn"
            onClick={onClose}
            color="primary"
            className={styles.button}
            size="small"
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={async () => {
              if (!projectId || !datasetId) return;
              setIsRemoving(true);
              try {
                await removeMediaFromDataset(projectId, datasetId, selectedMediaOptions);
                refreshTaskInfo({
                  keys: 'refresh-all',
                });
                enqueueSnackbar(
                  t('{{temp0}} media removed from dataset', {
                    temp0: selectedMediaCount,
                  }),
                  {
                    variant: 'success',
                  },
                );
                setIsRemoving(false);
                dispatch(draft => {
                  draft.selectingMediaMode = 'select';
                  draft.selectedOrUnselectedMedia = [];
                });
                onClose();
              } catch (error) {
                setIsRemoving(false);
                enqueueSnackbar(error.message, { variant: 'error' });
              }
            }}
            className={styles.button}
            color="secondary"
            variant="contained"
            size="small"
            id="remove-media-btn"
          >
            <strong>{t('Remove {{selectedMediaCount}} media', { selectedMediaCount })}</strong>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default RemoveMediaDialog;

export function sum(array: number[]): number {
  return array.reduce(function (a, b) {
    return a + b;
  }, 0);
}

// convert: 0.5 to 50%
export function percentage(value: number): string {
  return `${(value * 100).toFixed(2)}%`;
}

/**
 * float to floor percent int
 * @param value 0.56 to '50%'
 */
export function percentInt(value: number): string {
  return `${Math.floor(Number(value) * 100)}%`;
}

/**
 * Converts long numbers to abbrieviated format 9856 => 9.8K
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 * @param num number
 */
export function abbr(num: number): string {
  return Intl.NumberFormat('en-US', {
    maximumFractionDigits: 1,
    //@ts-ignore Open issue: https://github.com/microsoft/TypeScript/issues/36533
    notation: 'compact',
    compactDisplay: 'short',
  }).format(num);
}

/**
 * 1-1000 -> {value}
 * 1000+ -> 999+
 * @param value input number value
 * @param max max number to truncate
 */
export function truncMaxNumber(value: number, max: number = 1000): string {
  const displayValue = value > max ? ''.concat(String(max), '+') : value;
  return String(displayValue);
}

// 45.6789 -> 45.7
export function roundToTenth(value: number): number {
  return Math.round(value * 10) / 10;
}

/**
 * Rounds a number to a maximum amount of decimal places, but only if necessary.
 *
 * @param value The number to round
 * @param maxDecimals The maximum amount of decimal places to display
 * @link https://stackoverflow.com/a/11832950
 */
export function roundToMaxDecimals(value: number, maxDecimals: number) {
  const multiplier = Math.pow(10, maxDecimals);
  return Math.round((value + Number.EPSILON) * multiplier) / multiplier;
}

export const getClosestMultiple = (inputNumber: number, baseNumber: number): number =>
  Math.round(inputNumber / baseNumber) * baseNumber;

import React from 'react';

export interface IconProps {
  color?: string;
  width?: number | string;
  height?: number | string;
}

export const LabelIcon: React.FC<IconProps> = ({ color = '#4B5565' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2030_102" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="white" />
      </mask>
      <g mask="url(#mask0_2030_102)">
        <rect x="6" y="4" width="10" height="10" stroke={color} strokeWidth="1.5" />
        <rect
          x="3"
          y="7"
          width="10"
          height="10"
          stroke={color}
          strokeWidth="1.5"
          strokeDasharray="2 2"
        />
      </g>
    </svg>
  );
};

export const InformationIcon: React.FC<IconProps> = ({ color = '#4B5565' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2030_108" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2030_108)">
        <path
          d="M10 5.83333C9.54171 5.83333 9.14935 5.67014 8.82296 5.34375C8.49657 5.01736 8.33337 4.625 8.33337 4.16667C8.33337 3.70833 8.49657 3.31597 8.82296 2.98958C9.14935 2.66319 9.54171 2.5 10 2.5C10.4584 2.5 10.8507 2.66319 11.1771 2.98958C11.5035 3.31597 11.6667 3.70833 11.6667 4.16667C11.6667 4.625 11.5035 5.01736 11.1771 5.34375C10.8507 5.67014 10.4584 5.83333 10 5.83333ZM8.75004 17.5V7.5H11.25V17.5H8.75004Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const KeyboardIcon: React.FC<IconProps> = ({ color = '#4A5578' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2030_112" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2030_112)">
        <path
          d="M3.33329 15.8327C2.87496 15.8327 2.4826 15.6695 2.15621 15.3431C1.82982 15.0167 1.66663 14.6243 1.66663 14.166V5.83268C1.66663 5.37435 1.82982 4.98199 2.15621 4.6556C2.4826 4.32921 2.87496 4.16602 3.33329 4.16602H16.6666C17.125 4.16602 17.5173 4.32921 17.8437 4.6556C18.1701 4.98199 18.3333 5.37435 18.3333 5.83268V14.166C18.3333 14.6243 18.1701 15.0167 17.8437 15.3431C17.5173 15.6695 17.125 15.8327 16.6666 15.8327H3.33329ZM3.33329 14.166H16.6666V5.83268H3.33329V14.166ZM6.66663 13.3327H13.3333V11.666H6.66663V13.3327ZM4.16663 10.8327H5.83329V9.16602H4.16663V10.8327ZM6.66663 10.8327H8.33329V9.16602H6.66663V10.8327ZM9.16663 10.8327H10.8333V9.16602H9.16663V10.8327ZM11.6666 10.8327H13.3333V9.16602H11.6666V10.8327ZM14.1666 10.8327H15.8333V9.16602H14.1666V10.8327ZM4.16663 8.33268H5.83329V6.66602H4.16663V8.33268ZM6.66663 8.33268H8.33329V6.66602H6.66663V8.33268ZM9.16663 8.33268H10.8333V6.66602H9.16663V8.33268ZM11.6666 8.33268H13.3333V6.66602H11.6666V8.33268ZM14.1666 8.33268H15.8333V6.66602H14.1666V8.33268Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const DownloadIcon: React.FC<IconProps> = ({ color = '#202939' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1548_2089" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1548_2089)">
        <path
          d="M5.00004 16.6673C4.54171 16.6673 4.14935 16.5041 3.82296 16.1777C3.49657 15.8513 3.33337 15.459 3.33337 15.0007V12.5007H5.00004V15.0007H15V12.5007H16.6667V15.0007C16.6667 15.459 16.5035 15.8513 16.1771 16.1777C15.8507 16.5041 15.4584 16.6673 15 16.6673H5.00004ZM10 13.334L5.83337 9.16732L7.00004 7.95898L9.16671 10.1257V3.33398H10.8334V10.1257L13 7.95898L14.1667 9.16732L10 13.334Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const RemoveIcon: React.FC<IconProps> = ({ color = '#F04438' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1548_2093" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1548_2093)">
        <path
          d="M5.83337 17.5C5.37504 17.5 4.98268 17.3368 4.65629 17.0104C4.3299 16.684 4.16671 16.2917 4.16671 15.8333V5H3.33337V3.33333H7.50004V2.5H12.5V3.33333H16.6667V5H15.8334V15.8333C15.8334 16.2917 15.6702 16.684 15.3438 17.0104C15.0174 17.3368 14.625 17.5 14.1667 17.5H5.83337ZM14.1667 5H5.83337V15.8333H14.1667V5ZM7.50004 14.1667H9.16671V6.66667H7.50004V14.1667ZM10.8334 14.1667H12.5V6.66667H10.8334V14.1667Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const CopyIcon: React.FC<IconProps> = ({ color = '#4B5565' }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1548_2082" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1548_2082)">
        <path
          d="M12.4 12.8008H5.19998C4.87775 12.8008 4.5972 12.6813 4.35831 12.4424C4.11942 12.2036 3.99998 11.923 3.99998 11.6008V2.00078C3.99998 1.67856 4.11942 1.398 4.35831 1.15911C4.5972 0.920226 4.87775 0.800781 5.19998 0.800781H10.4L13.6 4.00078V11.6008C13.6 11.923 13.4805 12.2036 13.2416 12.4424C13.0028 12.6813 12.7222 12.8008 12.4 12.8008ZM9.59998 4.80078V2.00078H5.19998V11.6008H12.4V4.80078H9.59998ZM2.79998 15.2008C2.47775 15.2008 2.1972 15.0813 1.95831 14.8424C1.71942 14.6036 1.59998 14.323 1.59998 14.0008V4.80078H2.79998V14.0008H10.4V15.2008H2.79998Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

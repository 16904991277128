import { makeStyles } from '@material-ui/core/styles';

const useHeaderStyles = makeStyles(theme => ({
  rootLink: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    margin: theme.spacing(0, 12, 0, 0),
  },
  logotypeLeft: {
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(3),
    fontWeight: 500,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: `0 0 24px ${theme.palette.blue[500]}0C !important`,
    // For defect book printing
    '@media print': {
      display: 'none',
    },
    paddingRight: '0 !important',
  },
  toolbar: {
    padding: theme.spacing(0, 4, 0, 4),
    backgroundColor: '#fff',
    height: 48,
    minHeight: 48,
  },
  grow: {
    flexGrow: 1,
  },
  profileMenuContainer: {
    marginTop: theme.spacing(7),
  },
  profileMenuOrgName: {
    fontWeight: 500,
    padding: theme.spacing(0.5, 8),
  },
  freProfileMenuOrg: {
    display: 'flex',
    alignItems: 'center',
  },
  freProfileMenuOrgDivider: {
    width: '100%',
  },
  freProfileMenuOrgName: {
    fontWeight: 500,
  },
  planLine: {
    fontSize: 12,
    color: theme.palette.grey[500],
  },
  planText: {
    color: theme.palette.blue[500],
    fontWeight: 500,
    cursor: 'pointer',
  },
  orgIcon: {
    verticalAlign: 'middle',
  },
  headerMenuItem: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  headerRightButton: {
    fontWeight: 500,
    padding: 6,
    marginRight: theme.spacing(2),
  },
  headerButton: {
    padding: theme.spacing(0, 0),
  },
  importantHeaderButton: {
    fontWeight: 500,
  },
  headerIcon: {
    color: theme.palette.grey[800],
  },
  profileMenu: {
    minWidth: 300,
  },
  profileMenuUser: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)}px ${theme.spacing(5)}px 0`,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  verticalDivider: {
    margin: 10,
    height: '40%',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  linkSelected: {
    backgroundColor: `${theme.palette.primary.main}1A !important`,
  },
  projectPickerContainer: {
    padding: theme.spacing(3),
    width: 360,
  },
  projectPickerInput: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
    },
    marginBottom: theme.spacing(2),
  },
  labelTypeIcon: {
    marginRight: theme.spacing(2),
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  projectPickerList: {
    margin: 0,
    padding: 0,
    zIndex: 1,
    maxHeight: 360,
    overflow: 'scroll',
    listStyle: 'none',
    '& li': {
      borderRadius: 8,
      padding: theme.spacing(2, 4),
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'nowrap',
    },
    '& li *': {
      lineHeight: 'initial',
    },
    '& li span': {
      color: theme.palette.grey[400],
    },
    '& li[data-focus="true"]': {
      backgroundColor: `${theme.palette.primary.main}1A`,
    },
    '& li[data-focus="true"] > *': {
      color: theme.palette.primary.main,
    },
    '& li:active': {
      backgroundColor: `${theme.palette.primary.main}1A`,
      color: theme.palette.primary.main,
    },
  },
  noProjectText: {
    padding: theme.spacing(2, 4),
    color: theme.palette.grey[400],
  },
  indicatorContainer: {
    position: 'relative',
    width: 30,
    height: 30,
    cursor: 'pointer',
    margin: theme.spacing(0, 2),
    flexShrink: 0,
  },
  uploadProgressIcon: {
    position: 'absolute',
    transform: `translate(-50%, -50%)`,
    top: '50%',
    left: '50%',
    color: theme.palette.primary.light,
    '&.animated': {
      animation: `$fadeInOut 1.5s infinite`,
    },
  },
  jobTrainingIcon: {
    position: 'absolute',
    top: 2,
    right: 2,
    width: 4,
    height: 4,
    borderRadius: '50%',
    background: theme.palette.primary.light,
    animation: `$fadeInOut 1.5s infinite`,
  },
  uploadInProgress: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    color: theme.palette.primary.light,
  },
  uploadBase: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    color: theme.palette.primary.light,
    '&.wip': {
      opacity: 0.2,
    },
  },
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 1,
    },
  },
  mediaUploadSuccessBadge: {
    color: theme.palette.success.main,
    top: -8,
    right: -8,
    position: 'absolute',
  },
  mediaUploadFailureBadge: {
    color: theme.palette.error.main,
    top: -6,
    right: -6,
    position: 'absolute',
    backgroundColor: '#fff',
    borderRadius: '100%',
    overflow: 'hidden',
  },
  jobTrainingDialog: {
    width: 498,
    paddingBottom: 40,
    backgroundColor: '#ffffff',
    borderRadius: 3,
  },
  jobHead: {
    backgroundColor: '#37414D',
    borderRadius: '3px 3px 0 0',
    height: 40,
    color: '#fff',
    padding: '0 21px',
  },
  trainContent: {
    padding: '0 21px',
  },
  trainTime: {
    color: theme.palette.grey[800],
    fontSize: 14,
    padding: '17px 0',
    fontWeight: 500,
  },
  lineChartBox: {
    padding: '8px 20px 0 0',
    border: '1px solid #E6E7E9',
    minHeight: 120,
    textAlign: 'center',
  },
  lineChartYTitle: {
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%) rotate(-90deg)',
  },
  labels: {
    paddingLeft: 18,
    marginTop: 5,
    paddingBottom: 15,
  },
  labelItem: {
    '&:first-child': {
      marginRight: 20,
    },
  },
  projectName: {
    marginRight: theme.spacing(2),
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    color: theme.palette.grey[600],
    fontWeight: 500,
  },
  arrowForwardIosIcon: {
    color: theme.palette.grey[400],
    transform: 'scale(0.5)',
  },
  keyboardArrowDownIcon: {
    color: theme.palette.grey[400],
  },
  arrowBackIosIcon: {
    color: theme.palette.grey[400],
    marginLeft: 20,
  },
  userInfoColumn: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(5),
  },
  menuUserInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1.5, 4),
  },
  userNameText: {
    fontWeight: 500,
  },
  newRootLink: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginRight: 10,
    padding: theme.spacing(2),
    borderRadius: 50,
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0,0,0,0.04)',
    },
  },
  profileButtonContainer: {
    flexShrink: 0,
  },
  profileButton: {
    color: theme.palette.grey[800],
    padding: theme.spacing(0.5, 2),
    borderRadius: 10,
  },
  profileButtonBorder: {
    border: '1px solid #E6E7E9',
  },
  profileButtonHover: {
    '&:hover': {
      background: 'rgba(0,0,0,0.04)',
      cursor: 'pointer',
    },
  },
  prorfileButtonOrgName: {
    fontWeight: 500,
    paddingRight: 10,
    paddingLeft: 6,
  },
  userInfoGridContainer: {
    flexWrap: 'nowrap',
  },
  userInfoGridLeftItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  userInfoGridRightItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  avatar: {
    minWidth: 30,
  },
  releaseVersionName: {
    alignContent: 'center',
    fontSize: 11,
    color: theme.palette.grey[400],
    padding: theme.spacing(0, 4),
  },
  releaseVersionMenuItem: {
    pointerEvents: 'none',
  },
  creditBoxWithCredits: {
    color: theme.palette.grey[600],
    padding: '6px 8px',
    height: 36,
    borderRadius: 10,
    border: `1px solid ${theme.palette.grey[200]}`,
    flexShrink: 0,
    '&:hover': {
      cursor: 'pointer',
      background: 'rgba(0,0,0,0.04)',
    },
  },
  creditBoxWithoutCredits: {
    color: theme.palette.grey[600],
    padding: '6px 8px',
    height: 36,
    borderRadius: 10,
    background: '#FFF3F2',
    border: `1px solid #FFF3F2`,
    flexShrink: 0,
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.secondary.light,
    },
  },
  sampleProjectInfoBox: {
    borderColor: '#5D789D',
    borderRadius: 15,
    borderStyle: 'solid',
    border: 1,
    marginLeft: '8px',
  },
  sampleProjectText: {
    fontWeight: 'bold',
    color: '#5D789D',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  snowflakeSyncTaskListItem: {
    color: theme.palette.grey[800],
    padding: theme.spacing(2),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.greyModern[200]}`,
    },
  },
  snowflakeTaskStatusChip: {
    backgroundColor: '#F4F8FE',
    padding: theme.spacing(0, 1),
    fontWeight: 700,
    fontSize: 14,
    margin: theme.spacing(0, 1, 0, 2),
    borderRadius: 4,
    height: 19,
    lineHeight: '19px',
  },
  snowflakeTaskStatusInProgress: {
    color: theme.palette.primary.main,
  },
  snowflakeTaskStatusSuccess: {
    color: theme.palette.green[500],
  },
  snowflakeTaskStatusFail: {
    color: theme.palette.red[500],
  },
  snowflakeSyncFailInfoIcon: {
    color: theme.palette.red[200],
    height: 14,
    width: 14,
  },
  dateDistanceText: {
    color: theme.palette.greyModern[500],
  },
}));

export default useHeaderStyles;

import { BaseAPI } from './base_api';
import { ApiResponse, Defect, DefectId, ProjectId } from '@clef/shared/types';

class DefectAPI extends BaseAPI {
  async getByProject(projectId: ProjectId, version?: number): Promise<ApiResponse<Defect[]>> {
    return this.get('defects', { projectId, version });
  }

  async updateDefect(params: {
    id: DefectId;
    defect: Partial<Defect>;
  }): Promise<ApiResponse<Defect>> {
    return this.postJSON('set', params);
  }

  async createDefect(defect: Partial<Defect>): Promise<ApiResponse<Defect>> {
    return this.postJSON('new', defect);
  }

  async deleteDefect(id: DefectId) {
    return this.delete('', {
      id,
    });
  }

  async archiveDefect(id: DefectId) {
    return this.postJSON('archive', { defectId: id });
  }
}

export default new DefectAPI('defect');

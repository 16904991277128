import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  sectionSpacing: {
    paddingBottom: 8,
  },
  accordion: {
    width: '100%',
  },
  accordionDetails: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  accordionSummary: {
    display: 'flex',
    alignItems: 'center',
  },
  accordionTooltip: {
    fontSize: '1rem',
    color: theme.palette.grey[500],
    marginLeft: 4,
  },
  divider: {
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
  },
  noiseThresholdClassInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  noiseThresholdSliderWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 16,
    marginRight: 16,
  },
  noiseThresholdSlider: {
    marginLeft: 12,
    marginRight: 12,
  },
  infinityText: {
    fontSize: 14,
  },
  noiseThresholdClassColor: {
    width: 16,
    height: 16,
    borderRadius: 4,
    marginRight: theme.spacing(2),
    flexShrink: 0,
  },
  classNameText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
  },
  modelActionButtonGroup: {
    marginTop: 20,
  },
}));

export default useStyles;

import { useCallback, useEffect, useRef } from 'react';

/**
 * Return a setTimeout function.
 * Auto clear timeout on destruct.
 */
export const useSetTimeout = () => {
  const timerIdRef = useRef<ReturnType<typeof setTimeout>>();
  const internalSetTimeout = useCallback((callback: () => void, ms?: number) => {
    timerIdRef.current = setTimeout(callback, ms) as any;
    return timerIdRef.current;
  }, []);

  useEffect(() => {
    return () => {
      timerIdRef.current && clearTimeout(timerIdRef.current);
      timerIdRef.current = undefined;
    };
  }, []);

  return { timerIdRef, setTimeout: internalSetTimeout };
};

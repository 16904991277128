import { debounce, DebounceSettings } from 'lodash';
import { useCallback, useRef } from 'react';

const useDebounce = (cb: Function, delay: number, options?: DebounceSettings) => {
  // use mutable ref to make useCallback/debounce not depend on `cb` dep
  const cbRef = useRef(cb);
  cbRef.current = cb;
  return useCallback(
    debounce((...args: any[]) => cbRef.current(...args), delay, options),
    [delay],
  );
};

export default useDebounce;

import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Box, Dialog, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import { ApiResponseLoader, Button } from '@clef/client-library';
import { getDateNumber } from '@/utils';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useDeployModelToEndpointMutation, useGetEndpointListQuery } from '@/serverStore/endpoints';
import { formatDistance } from 'date-fns';

import { getModelName, isFastNEasyModel } from '@/utils/models';
import { useAllModels } from '@/hooks/useModels';
import Close from '@material-ui/icons/Close';
import { useWindowEventListener } from '@clef/client-library';
import { EndpointConfirmDialog } from './EndpointConfirmDialog';
import CreateEndpointDialog from '@/pages/deployment/components/CreateEndpointDialog';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    marginBottom: theme.spacing(7),
  },
  closeButton: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  endpointItemContainer: {
    border: '2px solid #CDD5DF',
    padding: theme.spacing(5),
    marginBottom: theme.spacing(2),
    borderRadius: 10,
    cursor: 'pointer',
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  endpointItemName: {
    color: theme.palette.grey[900],
    lineHeight: '20px',
    fontWeight: 700,
  },
  endpointModelName: {
    lineHeight: '20px',
    color: theme.palette.grey[800],
  },
  endpointModelDeployDate: {
    lineHeight: '20px',
    color: theme.palette.grey[500],
  },
  boldText: {
    fontWeight: 500,
  },
  endpointItemDate: {
    color: theme.palette.grey[400],
  },
  createEndpointAndDeployButton: {
    marginTop: theme.spacing(2),
    height: 34,
    width: '100%',
  },
  backButton: {
    position: 'absolute',
    top: 12,
    left: 12,
  },
  top20Margin: {
    marginTop: 20,
  },
  actionButton: {
    width: 'calc(50% - 10px)',
  },
  deployButton: {
    width: '100%',
  },
}));

const EndpointItem: React.FC<{
  endpointName: string;
  modelName: string;
  lastDeployedTime: string;
  onClick?: () => void | Promise<void>;
}> = ({ endpointName, modelName, lastDeployedTime, onClick }) => {
  const styles = useStyles();
  const dateNow = useMemo(() => new Date(), []);

  return (
    <Box display="flex" width="100%" className={styles.endpointItemContainer} onClick={onClick}>
      <Grid item xs={4}>
        <Typography className={styles.boldText}>{endpointName}</Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography className={styles.boldText}>{modelName}</Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography className={styles.endpointItemDate}>
          {isNaN(getDateNumber(lastDeployedTime))
            ? t('Unknown date')
            : t('Deployed {{dateDistance}} ago', {
                dateDistance: formatDistance(getDateNumber(lastDeployedTime), dateNow),
              })}
        </Typography>
      </Grid>
    </Box>
  );
};

export interface DeployModelDialogProps {
  open: boolean;
  onClose: () => void;
  modelInfo:
    | {
        id: string | undefined;
        threshold: number | undefined;
        evalJobId?: string;
      }
    | undefined
    | null;
  selectedEndpointId?: string | null;
  isLargeImageModel?: boolean;
}

export const DeployModelDialog: React.FC<DeployModelDialogProps> = ({
  open,
  onClose,
  modelInfo,
  selectedEndpointId: initialSelectedEndpointId,
  isLargeImageModel,
}) => {
  const styles = useStyles();
  const { data: project } = useGetSelectedProjectQuery();
  const { id: projectId, labelType } = project ?? {};
  const {
    data: endpointsData,
    isLoading: endpointsLoading,
    error: endpointsError,
  } = useGetEndpointListQuery(projectId ?? 0);
  const { mutateAsync: deployModelToEndpoint, isLoading: isDeploying } =
    useDeployModelToEndpointMutation();
  const { findClassicModels } = useAllModels();
  const currentModel = useMemo(
    () => findClassicModels(modelInfo?.id),
    [findClassicModels, modelInfo?.id],
  );
  const [selectedEndpointId, setSelectedEndpointId] = useState<string | null>(
    initialSelectedEndpointId ?? null,
  );
  const [createEndpointDialogOpen, setCreateEndpointDialogOpen] = useState(false);

  useEffect(() => {
    if (initialSelectedEndpointId) {
      setSelectedEndpointId(initialSelectedEndpointId);
    }
  }, [initialSelectedEndpointId]);

  const sortedEndpointsData = useMemo(() => {
    return endpointsData?.sort((a, b) => {
      return getDateNumber(b.endpoint.creationTime) - getDateNumber(a.endpoint.creationTime);
    });
  }, [endpointsData]);

  const handler = useCallback(
    (event: WindowEventMap['beforeunload']) => {
      if (isDeploying) {
        event.preventDefault();
        event.returnValue = 'There are unsaved changes. Are you sure want to leave?';
      }
    },
    [isDeploying],
  );

  useWindowEventListener('beforeunload', handler);

  const onModelDeploy = async (endpointId = selectedEndpointId) => {
    if (!endpointId || !modelInfo?.id) {
      return;
    }
    await deployModelToEndpoint(
      endpointId,
      // when deploying through an eval job in classic flow, MI requires eval job ID instead of root job ID
      modelInfo.evalJobId || modelInfo.id,
      modelInfo.threshold,
    );
  };

  if (!currentModel) {
    return null;
  }

  if (selectedEndpointId) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="lg">
        <Box display="flex" flexDirection="column" minWidth={800} padding={6}>
          <EndpointConfirmDialog
            isLargeImageModel={isLargeImageModel}
            modelInfo={modelInfo}
            onClose={onClose}
            onModelDeploy={onModelDeploy}
            isDeploying={isDeploying}
            labelType={labelType}
            initialSelectedEndpointId={initialSelectedEndpointId}
            setSelectedEndpointId={setSelectedEndpointId}
          />
        </Box>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <Box display="flex" flexDirection="column" minWidth={800} padding={6}>
        <Grid container>
          <Typography variant="h2" className={styles.title}>
            {t('Choose an Endpoint')}
          </Typography>

          <IconButton
            id="close-deploy-model-dialog-v1"
            className={styles.closeButton}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Grid>

        <ApiResponseLoader
          response={sortedEndpointsData}
          loading={endpointsLoading}
          error={endpointsError ?? undefined}
          defaultHeight={100}
        >
          {endpoints => {
            const currentModelIsFastNEasyModel = isFastNEasyModel(currentModel);

            return endpoints
              .filter(endpoint => {
                // Fast-N-Easy model can be deployed only on dynamic device/endpoint
                if (currentModelIsFastNEasyModel) {
                  return endpoint.endpoint.type === 'dynamic';
                }
                // Classic model can be deployed on both dynamic and static device/endpoint
                else {
                  return (
                    endpoint.endpoint.type === 'dynamic' || endpoint.endpoint.type === 'static'
                  );
                }
              })
              .map(endpoint => {
                return (
                  <EndpointItem
                    key={endpoint.endpoint.id}
                    endpointName={endpoint.endpoint.name}
                    modelName={
                      endpoint.model === null
                        ? t('No model deployed')
                        : getModelName(findClassicModels(endpoint.model?.jobId))
                    }
                    lastDeployedTime={endpoint.endpoint.lastDeployedTime}
                    onClick={() => setSelectedEndpointId(endpoint.endpoint.id)}
                  />
                );
              });
          }}
        </ApiResponseLoader>

        <Tooltip
          arrow
          placement="top"
          title={
            isLargeImageModel ? (
              <span>
                <Typography variant="body2">
                  {t('This model is incompatible with cloud inference.')}
                </Typography>
                <Typography variant="body2">{t('Use LandingEdge to run predictions.')}</Typography>
              </span>
            ) : (
              ''
            )
          }
        >
          <Box display="flex" justifyContent="center">
            <Button
              id="deploy-model-dialog-create-endpoint-and-deploy-button"
              variant="outlined"
              className={styles.createEndpointAndDeployButton}
              onClick={() => {
                setCreateEndpointDialogOpen(true);
              }}
              disabled={isLargeImageModel}
            >
              {t('Create Endpoint & Deploy')}
            </Button>
          </Box>
        </Tooltip>

        <CreateEndpointDialog
          open={createEndpointDialogOpen}
          onClose={() => setCreateEndpointDialogOpen(false)}
          isClassicModel
          modelInfo={{
            id: currentModel.trainJobId,
            threshold: modelInfo?.threshold,
          }}
        />
      </Box>
    </Dialog>
  );
};

import React, { useState, useEffect, useCallback } from 'react';
import Konva from 'konva';
import { TextAnnotation } from '@clef/shared/types';
import { TextAnnotationComponent } from '../components/Shapes';
import throttle from 'lodash/throttle';
import { calcAccurateOffset } from '../utils';
import { AnnotationCreatorBuilder } from '../types';

type TextAnnotationDrawHook = (options: {
  color?: string;
  text: string;
  fontSize: number;
  otherTextSelected: boolean;
  onFinishAnnotation: (line: TextAnnotation) => void;
}) => AnnotationCreatorBuilder;

export const useTextAnnotationCreator: TextAnnotationDrawHook = ({
  color,
  text,
  fontSize,
  otherTextSelected,
  onFinishAnnotation,
}) => {
  const [wipTextAnnotation, setWipTextAnnotation] = useState<TextAnnotation>();

  useEffect(() => {
    if (otherTextSelected) {
      setWipTextAnnotation(undefined);
    }
  }, [otherTextSelected]);

  const onMouseMove = useCallback(
    throttle((e: Konva.KonvaEventObject<MouseEvent>) => {
      const cPos = calcAccurateOffset(e);
      setWipTextAnnotation({
        color: color!,
        text: otherTextSelected ? '' : text,
        x: cPos.x,
        y: cPos.y,
        fontSize,
      });
    }, 48),
    [color, text, fontSize, otherTextSelected],
  );

  const onMouseDown = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      const cPos = calcAccurateOffset(e);
      wipTextAnnotation &&
        !otherTextSelected &&
        onFinishAnnotation({
          ...wipTextAnnotation,
          x: cPos.x,
          y: cPos.y,
        });
      setWipTextAnnotation(undefined);
    },
    [onFinishAnnotation, wipTextAnnotation, otherTextSelected],
  );

  const onMouseLeave = useCallback(() => {
    setTimeout(() => {
      setWipTextAnnotation(undefined);
    }, 64);
  }, []);

  return {
    creatorPreviewComponent: wipTextAnnotation ? (
      <TextAnnotationComponent
        key="text-annotation-wip"
        annotationId="wip-text-annotation"
        annotation={wipTextAnnotation}
        fontSize={fontSize}
      />
    ) : null,
    creatorInteractiveProps: color
      ? {
          onMouseLeave,
          onMouseDown,
          onMouseMove,
        }
      : {},
  };
};

import React, { useMemo } from 'react';
import {
  ChartContainer,
  ApiResponseLoader,
  DistributionChart,
  PieChart,
} from '@clef/client-library';
import { DefectDistributionWithAssignment } from '@clef/shared/utils/auto_split_core_algorithm';
import {
  distributionAssignmentAggregateByDefect,
  distributionAssignmentAggregateBySplit,
  distributionAssignmentToSplitStats,
  aggregateMappingTypeToDistributionList,
  supportedAutoSplit,
} from './utils';
import { useDefectSelector } from '../../store/defectState/actions';
import { useAutoSplitStyles } from './styles';
import { DatasetGroupOptions } from '@clef/shared/types';
import { splitColors } from '@clef/client-library';
import { getDefectColor } from '../../utils';
import { useGetDatasetFilterOptionsQuery, useGetDatasetStatsQuery } from '@/serverStore/dataset';

const ChartTitles = {
  splitChart: {
    title: t('number of images in each split'),
    tooltip: t('Based on your target defect split above, this is how your images will be split'),
  },
  defectSplitChart: {
    title: t('defect / split distribution'),
    tooltip: (
      <div>
        {t(
          'This should directly resemble your target defect split above, if the average deviation is very large, reasons could be:',
        )}
        <br />
        {t('1. There are too few images and defects.')}
        <br />
        {t('2. You have set extremely different split for each defect.')}
      </div>
    ),
  },
  splitDefectChart: {
    title: t('split / defect distribution'),
    tooltip: t(
      'This chart shows the number of defects for each split, usually you want the distribution to be similar across each split to train a successful model',
    ),
  },
};

const noDefectMediaTemplate = (count: number) => (
  <>{t('* Also include {{count}} images with no defects', { count: <strong>{count}</strong> })}</>
);

const toTwoDecimal = (number: number) => {
  return Math.round(number * 100) / 100;
};

export interface DistributionPreviewGraphsProps {
  distributionWithAssignment?: DefectDistributionWithAssignment[];
  assignmentDeviation?: { [defectId: number]: number };
  previewMode?: 'selected' | 'existing' | 'selected+existing';
}

const DistributionPreviewGraphs: React.FC<DistributionPreviewGraphsProps> = ({
  distributionWithAssignment,
  assignmentDeviation,
  previewMode = 'selected',
}) => {
  const styles = useAutoSplitStyles();

  const { data: allFilters } = useGetDatasetFilterOptionsQuery();

  // 'Split' is in columnFilterMap, the project has split in datasetContent's splitSet column
  // 'split' is in fieldFilterMap, the project has split metadata
  // Always prefer to use 'Split' column in case user manually creates 'split' or 'Split' metadata
  const splitFilterOption = allFilters
    ? allFilters.find(value => value.filterName === 'Split' && value.filterType === 'column') ||
      allFilters.find(value => value.filterName === 'split')
    : undefined;

  const supportedAutoSplitId: number[] =
    splitFilterOption?.filterType === 'column'
      ? supportedAutoSplit.map(s => splitFilterOption.value![s] as number)
      : [];

  const allDefects = useDefectSelector();
  const { data: mediaGroupBySplitDefectDistribution } = useGetDatasetStatsQuery(
    {
      selectOptions: {
        fieldFilterMap:
          splitFilterOption?.filterType === 'field'
            ? {
                [splitFilterOption.fieldId!]: { CONTAINS_ANY: supportedAutoSplit },
              }
            : {},
        columnFilterMap:
          splitFilterOption?.filterType === 'column'
            ? {
                datasetContent: { splitSet: { CONTAINS_ANY: supportedAutoSplitId } },
              }
            : {},
        selectedMedia: [],
        unselectedMedia: [],
        isUnselectMode: true,
      },
      groupOptions: [DatasetGroupOptions.DEFECT_DISTRIBUTION, DatasetGroupOptions.SPLIT],
    },
    !!splitFilterOption,
  );

  // Reformat mediaGroupBySplitDefectDistribution to DefectDistributionWithAssignment[] as well
  const existDistributionWithAssignment = useMemo(
    () =>
      mediaGroupBySplitDefectDistribution?.reduce((acc, { defect_distribution, split, count }) => {
        return [
          ...acc,
          {
            count,
            defect_distribution: defect_distribution!,
            assignment: [
              split === 'train' ? count : 0,
              split === 'dev' ? count : 0,
              split === 'test' ? count : 0,
            ] as [number, number, number],
          },
        ];
      }, [] as DefectDistributionWithAssignment[]),
    [mediaGroupBySplitDefectDistribution],
  );

  // if we are showing chart for all media, combine existDistributionWithAssignment with distributionWithAssignment
  let distributionWithAssignmentFinal: DefectDistributionWithAssignment[] | undefined;

  if (previewMode === 'selected') {
    distributionWithAssignmentFinal = distributionWithAssignment;
  } else if (previewMode === 'existing') {
    distributionWithAssignmentFinal = existDistributionWithAssignment;
  } else {
    distributionWithAssignmentFinal =
      existDistributionWithAssignment && distributionWithAssignment
        ? [...distributionWithAssignment, ...existDistributionWithAssignment]
        : undefined;
  }
  const defectColorMap: { [key: string]: string } | undefined = useMemo(
    () =>
      allDefects?.reduce((acc, defect) => ({ ...acc, [defect.name]: getDefectColor(defect) }), {}),
    [allDefects],
  );

  const splitColorMap: { [key: string]: string } | undefined = useMemo(
    () =>
      ['train', 'dev', 'test']?.reduce(
        (acc, name, index) => ({
          ...acc,
          [name]: splitColors[index % splitColors.length],
        }),
        {},
      ),
    [],
  );

  const distributionAggregatedBySplitMapping = useMemo(
    () =>
      allDefects && distributionWithAssignmentFinal
        ? distributionAssignmentAggregateBySplit(distributionWithAssignmentFinal, allDefects)
        : undefined,
    [allDefects, distributionWithAssignmentFinal],
  );
  const distributionAggregatedByDefectMapping = useMemo(
    () =>
      allDefects && distributionWithAssignmentFinal
        ? distributionAssignmentAggregateByDefect(distributionWithAssignmentFinal, allDefects)
        : undefined,
    [allDefects, distributionWithAssignmentFinal],
  );

  // for split graph
  const splitStats = useMemo(
    () =>
      distributionWithAssignmentFinal
        ? distributionAssignmentToSplitStats(distributionWithAssignmentFinal)
        : undefined,
    [distributionWithAssignmentFinal],
  );
  // for split - defect graph
  const noDefectMediaAssignment = useMemo(
    () =>
      distributionWithAssignmentFinal?.reduce(
        (acc, { defect_distribution, assignment }) => {
          if (defect_distribution) {
            return acc;
          }
          return [acc[0] + assignment[0], acc[1] + assignment[1], acc[2] + assignment[2]];
        },
        [0, 0, 0],
      ),
    [distributionWithAssignmentFinal],
  );
  const distributionAggregatedBySplit = useMemo(
    () =>
      distributionAggregatedBySplitMapping
        ? aggregateMappingTypeToDistributionList(
            distributionAggregatedBySplitMapping,
            noDefectMediaAssignment
              ? {
                  train: noDefectMediaTemplate(noDefectMediaAssignment[0]),
                  dev: noDefectMediaTemplate(noDefectMediaAssignment[1]),
                  test: noDefectMediaTemplate(noDefectMediaAssignment[2]),
                }
              : undefined,
          )
        : undefined,
    [distributionAggregatedBySplitMapping, noDefectMediaAssignment],
  );
  // for defect - split graph
  const distributionAggregatedByDefect = useMemo(
    () =>
      distributionAggregatedByDefectMapping
        ? aggregateMappingTypeToDistributionList(distributionAggregatedByDefectMapping)
        : undefined,
    [distributionAggregatedByDefectMapping],
  );
  // average deviation
  const averageDeviation = useMemo(
    () =>
      assignmentDeviation && previewMode === 'selected'
        ? toTwoDecimal(
            Object.values(assignmentDeviation).reduce((acc, dev) => acc + dev, 0) /
              Object.values(assignmentDeviation).length,
          )
        : undefined,
    [assignmentDeviation, previewMode],
  );

  return (
    <div className={styles.graphContainer}>
      <div className={styles.splitGraph} data-testid="split-doughnut-chart">
        {/* split graph */}
        <ChartContainer
          title={ChartTitles.splitChart.title}
          tooltip={ChartTitles.splitChart.tooltip}
        >
          <ApiResponseLoader response={splitStats} loading={!splitStats}>
            {result => (
              <PieChart
                chartData={result}
                variant="doughnut"
                doughnutCenterText={totalValue => `${totalValue} images`}
              />
            )}
          </ApiResponseLoader>
        </ChartContainer>
      </div>
      <div className={styles.defectSplitGraph} data-testid="defect-split-distribution-chart">
        {/* defect - split graph */}
        <ChartContainer
          title={ChartTitles.defectSplitChart.title}
          tooltip={ChartTitles.defectSplitChart.tooltip}
          caption={
            typeof averageDeviation === 'number' ? (
              <span>
                {t('average deviation from target: {{value}}%', {
                  value: <strong>{averageDeviation}</strong>,
                })}
              </span>
            ) : undefined
          }
        >
          <ApiResponseLoader
            response={distributionAggregatedByDefect}
            loading={!distributionAggregatedByDefect}
            defaultHeight={200}
          >
            {result => (
              <DistributionChart
                distributionData={result}
                distributorColorMap={splitColorMap!}
                hideLabel
              />
            )}
          </ApiResponseLoader>
        </ChartContainer>
      </div>
      <div className={styles.splitDefectGraph} data-testid="split-defect-distribution-chart">
        {/* split - defect graph */}
        <ChartContainer
          title={ChartTitles.splitDefectChart.title}
          tooltip={ChartTitles.splitDefectChart.tooltip}
        >
          <ApiResponseLoader
            response={distributionAggregatedBySplit}
            loading={!distributionAggregatedBySplit}
            defaultHeight={200}
          >
            {result => (
              <DistributionChart
                distributionData={result}
                distributorColorMap={defectColorMap!}
                hideLabel
              />
            )}
          </ApiResponseLoader>
        </ChartContainer>
      </div>
    </div>
  );
};

export default DistributionPreviewGraphs;

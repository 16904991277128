import React from 'react';

export type GridViewIconProps = {
  color?: string;
};

const GridViewIcon: React.FC<GridViewIconProps> = ({ color = 'inherit' }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_651_1790"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_651_1790)">
        <path
          d="M3.5 11V3H11.5V11H3.5ZM3.5 21V13H11.5V21H3.5ZM13.5 11V3H21.5V11H13.5ZM13.5 21V13H21.5V21H13.5ZM5.5 9H9.5V5H5.5V9ZM15.5 9H19.5V5H15.5V9ZM15.5 19H19.5V15H15.5V19ZM5.5 19H9.5V15H5.5V19Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default GridViewIcon;

import { IconButton } from '@clef/client-library';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { Typography } from '@clef/client-library';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import React, { ReactElement, useState } from 'react';
import cx from 'classnames';
import noLabelIcon from '@/images/no_label.svg';
import { FixedSizeList } from 'react-window';
import { ObjectInstancePairing } from '@clef/shared/types';
import useGetDefectById from '../../../hooks/defect/useGetDefectById';
import useGetDefectColorById from '@/hooks/defect/useGetDefectColorById';
import { FilterOptions } from '@/api/model_analysis_api';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    marginBottom: theme.spacing(1),
  },
  expander: {
    borderBottom: '1px solid white',
  },
  expanderTitle: {
    height: 36,
    cursor: 'pointer',
    margin: theme.spacing(0, -4),
    padding: theme.spacing(0, 4),
    whiteSpace: 'nowrap',
  },
  matrixRow: {
    height: 36,
  },
  matrixLeftCell: {
    paddingLeft: theme.spacing(2),
  },
  matrixRightCell: {
    paddingRight: theme.spacing(2),
  },
  selectedCell: {
    backgroundColor: theme.palette.grey[200],
  },
  selectedLeftCell: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  selectedRightCell: {
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  matrixCell: {
    verticalAlign: 'middle',
  },
  matrixCount: {
    fontWeight: 'bold',
    textAlign: 'right',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[100] + 'aa',
    },
  },
  caption: {
    maxWidth: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  correctColor: {
    color: theme.palette.green[500],
  },
  incorrectColor: {
    color: theme.palette.error.main,
  },
}));

const useIconStyles = makeStyles<Theme, { defectColor: string; size: number }>(theme => ({
  incorrectIncon: {
    marginRight: theme.spacing(1),
  },
  colorBlock: {
    width: props => props.size,
    height: props => props.size,
    borderRadius: 4,
    background: props => props.defectColor,
    border: `1px solid ${theme.palette.grey[800]}`,
    marginRight: theme.spacing(1),
  },
}));

export const DefectColorChip: React.FC<{
  defectId: number | null;
  size?: number;
  width?: number;
  maxWidth?: number;
  isPrediction?: boolean;
}> = ({ defectId, maxWidth, size = 16, width, isPrediction }) => {
  const getColorById = useGetDefectColorById();
  const getDefectById = useGetDefectById();
  const styles = useIconStyles({
    defectColor: getColorById(defectId ?? 0),
    size,
  });

  if (defectId && getDefectById(defectId)?.name) {
    // rectangle widh color 1 inside and color 2 as border
    return (
      <Box display="flex" alignItems="center" width={width}>
        <Box className={styles.colorBlock} />
        <Typography maxWidth={maxWidth} className={styles.caption}>
          {getDefectById(defectId).name}
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box display="flex" alignItems="center" width={width}>
        <img className={styles.incorrectIncon} src={noLabelIcon} width={size} height={size} />
        <Typography maxWidth={maxWidth} className={styles.caption}>
          {isPrediction ? t('No prediction') : t('No label')}
        </Typography>
      </Box>
    );
  }
};

export type PredictionMatrixData = ObjectInstancePairing & {
  gtCaption: string;
  predictionCaption: string;
};

type VirtualizedDefectPairsProps = {
  data: PredictionMatrixData[];
  onlyShowCorrectColumn?: boolean;
  filterOptions?: FilterOptions;
  onCountClick?: (data: PredictionMatrixData) => void;
};

const rowHeight = 36;

const VirtualizedDefectPairs = (props: VirtualizedDefectPairsProps) => {
  const { data, filterOptions, onlyShowCorrectColumn, onCountClick } = props;
  const RCWindowItem = ({
    data,
    index,
    style,
  }: {
    data: PredictionMatrixData[];
    index: number;
    style: any;
  }) => {
    const styles = useStyles();
    const row = data[index];
    const isSelected =
      filterOptions &&
      filterOptions.gtClassId === row.gtDefectId &&
      filterOptions.predClassId === row.predDefectId;
    return (
      <div key={`${row.gtDefectId}-${row.predDefectId}`} style={style}>
        <Box
          display="flex"
          flexDirection="row"
          height={rowHeight}
          width="100%"
          alignItems="center"
          key={row.gtCaption + '->' + row.predictionCaption}
          data-testid="prediction-row"
        >
          <Box
            className={cx(styles.matrixCell, styles.matrixLeftCell, {
              [styles.selectedLeftCell]: isSelected,
              [styles.selectedCell]: isSelected,
            })}
            width={onlyShowCorrectColumn ? '80%' : '44%'}
          >
            <DefectColorChip
              defectId={row.gtDefectId}
              maxWidth={onlyShowCorrectColumn ? 150 : 70}
            />
          </Box>
          {!onlyShowCorrectColumn && (
            <Box
              className={cx(styles.matrixCell, {
                [styles.selectedCell]: isSelected,
              })}
              width="44%"
            >
              <DefectColorChip isPrediction defectId={row.predDefectId} maxWidth={65} />
            </Box>
          )}
          <Box
            className={cx(styles.matrixCell, styles.matrixRightCell, {
              [styles.selectedCell]: isSelected,
              [styles.selectedRightCell]: isSelected,
            })}
            onClick={() => onCountClick?.(row)}
            data-testid={`filter by gt:${row.gtCaption}, pred:${row.predictionCaption}`}
          >
            <Typography variant="body2" className={styles.matrixCount}>
              {row.count}
            </Typography>
          </Box>
        </Box>
      </div>
    );
  };
  const listHeight = Math.min(360, data.length * rowHeight);
  return (
    <FixedSizeList
      width={'100%'}
      height={listHeight}
      itemSize={rowHeight}
      itemData={data}
      itemCount={data.length}
    >
      {RCWindowItem}
    </FixedSizeList>
  );
};

export const PredictionMatrix: React.FC<{
  title: ReactElement;
  data: PredictionMatrixData[];
  onlyShowCorrectColumn?: boolean;
  filterOptions?: FilterOptions;
  onCountClick?: (data: PredictionMatrixData) => void;
}> = props => {
  const { title, onlyShowCorrectColumn, data, filterOptions, onCountClick, ...otherProps } = props;
  const styles = useStyles();
  const [expanded, setExpanded] = useState(true);

  return (
    <div className={styles.expander} {...otherProps}>
      <Box
        display="flex"
        alignItems="center"
        className={styles.expanderTitle}
        onClick={() => setExpanded(prev => !prev)}
      >
        {title}
        <div style={{ flex: 1 }} />
        <IconButton size="small">
          {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </Box>
      {expanded && (
        <VirtualizedDefectPairs
          data={data}
          onlyShowCorrectColumn={onlyShowCorrectColumn}
          filterOptions={filterOptions}
          onCountClick={onCountClick}
        />
      )}
    </div>
  );
};

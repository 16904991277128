import React, { useMemo } from 'react';
import { makeStyles, Slider, Tooltip } from '@material-ui/core';
import { ToolMode, useLabelingState } from '../../../components/Labeling/labelingState';
import { useKeyPress } from '@clef/client-library';
import {
  MIN_STROKE_WIDTH,
  MAX_STROKE_WIDTH,
} from '../../../components/Labeling/SegmentationToolModeOptionsCard';

const sliderSize = 4;

const useStyles = makeStyles(theme => ({
  lineWidthSelector: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
    // TODO: why height: 100% doesn't work?
    height: '56px',
  },
  sliderRoot: {
    width: 128,
    height: sliderSize,
    color: theme.palette.grey[600],
  },
  sliderRail: {
    height: sliderSize,
    borderRadius: sliderSize / 2,
  },
  sliderTrack: {
    height: sliderSize,
    borderRadius: sliderSize / 2,
  },
  sliderThumb: {
    height: sliderSize * 4,
    width: sliderSize * 4,
    marginTop: -sliderSize * 1.5,
    marginLeft: -sliderSize * 2,
    '&:hover': {
      boxShadow: `0px 0px 0px 8px ${theme.palette.grey[600] + '28'}`,
    },
  },
}));

export type LineWidthSelectorProps = {};

const LineWidthSelector: React.FC<LineWidthSelectorProps> = () => {
  const styles = useStyles();
  const { state, dispatch } = useLabelingState();
  const {
    toolMode,
    toolOptions: { strokeWidth },
  } = state;

  const enableStrokeWidth = toolMode
    ? [ToolMode.Brush, ToolMode.Polyline].includes(toolMode)
    : false;
  useKeyPress(
    '[',
    () =>
      enableStrokeWidth &&
      dispatch(draft => {
        draft.toolOptions.strokeWidth = draft.toolOptions.eraserWidth = Math.max(
          MIN_STROKE_WIDTH,
          draft.toolOptions.strokeWidth - 1,
        );
      }),
  );
  useKeyPress(
    ']',
    () =>
      enableStrokeWidth &&
      dispatch(draft => {
        draft.toolOptions.strokeWidth = draft.toolOptions.eraserWidth = Math.min(
          MAX_STROKE_WIDTH,
          draft.toolOptions.strokeWidth + 1,
        );
      }),
  );

  const sliderValue = useMemo(() => {
    if (strokeWidth < 12) {
      return strokeWidth;
    }
    if (strokeWidth === MAX_STROKE_WIDTH) {
      return 50;
    }
    return Math.round(Math.log(strokeWidth - 5) / Math.log(1.15));
  }, [strokeWidth]);

  if (!enableStrokeWidth) {
    return null;
  }

  return (
    <Tooltip
      arrow
      placement="right"
      title={t('{{decreaseKey}} to decrease, {{increaseKey}} to increase', {
        decreaseKey: <code>[</code>,
        increaseKey: <code>]</code>,
      })}
    >
      <div className={styles.lineWidthSelector}>
        <Slider
          classes={{
            root: styles.sliderRoot,
            rail: styles.sliderRail,
            track: styles.sliderTrack,
            thumb: styles.sliderThumb,
          }}
          min={1}
          max={50}
          value={sliderValue}
          onChange={(_event, value) => {
            const sliderValue = Number(value);
            const newStrokeWidth = Math.min(
              MAX_STROKE_WIDTH,
              Math.round(sliderValue < 12 ? sliderValue : 5 + 1.15 ** sliderValue),
            );
            dispatch(draft => {
              draft.toolOptions.strokeWidth = draft.toolOptions.eraserWidth = newStrokeWidth;
            });
          }}
        />
      </div>
    </Tooltip>
  );
};

export default LineWidthSelector;

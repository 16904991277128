import React, { useEffect, useState } from 'react';
import { Button } from '@clef/client-library';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Checkbox, Chip, Divider, Typography, makeStyles } from '@material-ui/core';
import BasicFilterSearchBar from './BasicFilterSearchBar';
import DataSnapshotCard from './DataSnapshotCard';
import {
  useDatasetExportedWithVersionsQuery,
  useGetDatasetStatsQuery,
} from '@/serverStore/dataset';
import { DatasetGroupOptions, DatasetVersion, DatasetVersionId } from '@clef/shared/types';
import { useAddEvaluationSetMutation } from '@/serverStore/evaluationSets/mutations';
import { useSnackbar } from 'notistack';
import { useGetProjectSplitQuery } from '@/serverStore/projects';
import { MediaAllSplitName } from '@/constants/stats_card';
import LoadingProgress from '../LoadingProgress';
import { getDateNumber } from '@clef/shared/utils';
import DataSnapshotStat from './DataSnapshotStat';
import { useFormattedSplitMediasForChart } from '@/components/StatsCharts/useFormattedSplitDefectsForChart';

interface AddEvaluationSetDialogProps {
  open: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles(theme => ({
  dialog: {
    minWidth: 960,
    minHeight: 480,
    height: '80%',
  },
  dialogTitleText: {
    fontSize: theme.spacing(5),
    fontWeight: 'bold',
  },
  dialogContent: {
    padding: theme.spacing(0, 6, 6, 6),
  },
  verticalDivider: {
    margin: theme.spacing(2, 4, 0, 4),
  },
  cardsList: {
    overflowY: 'scroll',
    maxHeight: 600,
  },
  checkboxUserGeneratedOnly: {
    padding: theme.spacing(0, 2, 0, 0),
  },
  evaluationSetChip: {
    background: theme.palette.blue[50],
  },
  evaluationSetChipStaticText: {
    color: theme.palette.greyModern[500],
  },
  evaluationSetChipNameText: {
    fontWeight: 'bold',
  },
  dialogActionButtonsContainer: {
    paddingTop: theme.spacing(4),
  },
  addToTheTableButton: {
    marginLeft: theme.spacing(2),
  },
}));

const filterDatasetVersion = (
  datasetVersion: DatasetVersion,
  filterKey: string | null,
): boolean => {
  if (filterKey === null) return true;
  if (datasetVersion.name.indexOf(filterKey) !== -1) {
    return true;
  }
  return false;
};

const AddEvaluationSetDialog = (props: AddEvaluationSetDialogProps) => {
  const { open, handleClose } = props;
  const classes = useStyles();
  const [includeFastEasy, setIncludeFastEasy] = useState(true);
  const { data: snapshots, isLoading: isSnapshotsLoading } = useDatasetExportedWithVersionsQuery({
    withCount: true,
    includeNotCompleted: true,
    includeFastEasy: includeFastEasy,
  });
  const addEvaluationSet = useAddEvaluationSetMutation();
  const { data: projectSplits = [] } = useGetProjectSplitQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedDatasetVersionId, setSelectedDatasetVersionId] = useState<DatasetVersionId | null>(
    null,
  );
  const [filterKey, setFilterKey] = useState<string | null>(null);

  const datasetVersions = snapshots && snapshots.datasetVersions ? snapshots.datasetVersions : [];
  const filterOptions = datasetVersions.map(datasetVersion => datasetVersion.name);
  const [selectedSplitName, setSelectedSplitName] = useState<string>(MediaAllSplitName);

  const filteredDatasetVersions = datasetVersions.filter(datasetVersions => {
    return filterDatasetVersion(datasetVersions, filterKey);
  });
  const sortedFilteredDatasetVersions = filteredDatasetVersions.sort(
    (a, b) => getDateNumber(b.creationTime) - getDateNumber(a.creationTime),
  );
  const selectedDatasetVersion = sortedFilteredDatasetVersions.find(
    version => version.id === selectedDatasetVersionId,
  );
  const { data: mediaSplitDistribution, isLoading: isMediaSplitDistributionLoading } =
    useGetDatasetStatsQuery({
      version: selectedDatasetVersion?.version,
      groupOptions: [DatasetGroupOptions.SPLIT],
    });
  const { splitStatsFormatted } = useFormattedSplitMediasForChart(mediaSplitDistribution);
  const splitStatsFiltered = splitStatsFormatted.filter(splitTag => {
    return splitTag.name !== 'unassigned';
  });
  const selectedSplitMediaCount =
    splitStatsFiltered.find(split => split.name === selectedSplitName)?.value ?? 0;
  const handleAddEvaluationSet = async () => {
    if (!selectedDatasetVersion) {
      enqueueSnackbar(t('Please choose a data snapshot from the list left'), {
        variant: 'warning',
      });
      return;
    }
    const selectedProjectSplit = projectSplits.find(
      projectSplit => projectSplit.splitSetName === selectedSplitName,
    );
    await addEvaluationSet.mutateAsync({
      datasetVersionId: selectedDatasetVersion.id,
      splitId: selectedProjectSplit ? selectedProjectSplit.id : null,
      hidden: false,
    });
    handleClose();
  };

  // reset selected dataset version to null if previous snapshot being filtered out
  useEffect(() => {
    if (!selectedDatasetVersion) {
      setSelectedDatasetVersionId(null);
    }
  }, [selectedDatasetVersion]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id="form-dialog-title">
        <Typography className={classes.dialogTitleText}>{t('Add Evaluation Set')}</Typography>
        <Typography variant="body1">
          {t(
            `You can add an entire snapshot or specify a split designation as an evaluation test set.`,
          )}
        </Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Box display="flex" flexDirection="row" height="100%">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            width="35%"
          >
            <Box display="flex" flexDirection="column" width="100%" height="100%">
              <Box width="100%">
                <BasicFilterSearchBar
                  placeholderText={t('Search by snapshot name')}
                  filterKey={filterKey}
                  options={filterOptions}
                  handleFilterKeyChange={setFilterKey}
                />
              </Box>
              {isSnapshotsLoading ? (
                <LoadingProgress />
              ) : (
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  className={classes.cardsList}
                >
                  {sortedFilteredDatasetVersions.map((datasetVersion: DatasetVersion) => {
                    return (
                      <DataSnapshotCard
                        key={datasetVersion.id}
                        id={datasetVersion.id}
                        name={datasetVersion.name}
                        description={datasetVersion.details || ''}
                        creator={datasetVersion.creator}
                        mediaCount={datasetVersion.count}
                        modelsCount={datasetVersion.modelsCount}
                        isSelected={selectedDatasetVersionId === datasetVersion.id}
                        handleClick={setSelectedDatasetVersionId}
                      />
                    );
                  })}
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              width="100%"
            >
              <Checkbox
                color="primary"
                checked={!includeFastEasy}
                onClick={() => {
                  setIncludeFastEasy(includeFastEasy => !includeFastEasy);
                }}
                className={classes.checkboxUserGeneratedOnly}
              />
              <Typography>{t('Show user-generated snapshots only')}</Typography>
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
          <Box
            flex="1"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            {isMediaSplitDistributionLoading ? (
              <LoadingProgress />
            ) : selectedDatasetVersion ? (
              <DataSnapshotStat
                key={selectedDatasetVersion.id}
                splitStatsFormatted={splitStatsFiltered}
                selectedSplitName={selectedSplitName}
                setSelectedSplitName={setSelectedSplitName}
                datasetVersion={selectedDatasetVersion}
              />
            ) : (
              <Typography>{t('Please select a data snapshot from left list')}</Typography>
            )}
            <Box display="flex" flexDirection="column" alignItems="flex-end" width="100%">
              {selectedDatasetVersion && (
                <Chip
                  label={t(
                    '{{evaluationSet}} {{snapshotName}}, {{splitName}} ({{snapshotMediaCount}})',
                    {
                      evaluationSet: (
                        <span className={classes.evaluationSetChipStaticText}>
                          {t('Evaluation Set:')}
                        </span>
                      ),
                      snapshotName: (
                        <span className={classes.evaluationSetChipNameText}>
                          {selectedDatasetVersion.name}
                        </span>
                      ),
                      splitName: (
                        <span className={classes.evaluationSetChipNameText}>
                          {selectedSplitName}
                        </span>
                      ),
                      snapshotMediaCount: (
                        <span>{selectedSplitMediaCount || selectedDatasetVersion.count}</span>
                      ),
                    },
                  )}
                  className={classes.evaluationSetChip}
                />
              )}
              <Box
                className={classes.dialogActionButtonsContainer}
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                width="100%"
              >
                <Button id="cancel-add-evaluation-button" onClick={handleClose} color="primary">
                  {t('Cancel')}
                </Button>
                <Button
                  id="add-evaluation-to-table-button"
                  variant="contained"
                  className={classes.addToTheTableButton}
                  onClick={handleAddEvaluationSet}
                  color="primary"
                >
                  {t('Add to the Table')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddEvaluationSetDialog;

import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Button } from '@clef/client-library';
import DeployModelDialog from '@/components/Dialogs/DeployModelDialog';

const useStyles = makeStyles(theme => ({
  updateThresholdButton: {
    marginLeft: theme.spacing(3),
  },
}));

type UpdateThresholdProps = {
  modelInfo:
    | {
        id: string | undefined;
        threshold: number | undefined;
      }
    | undefined
    | null;
  selectedEndpointId?: string | null;
  disabled?: boolean;
  onResetThresholdButtonClick?: () => void;
};

export const UpdateThreshold: React.FC<UpdateThresholdProps> = ({
  modelInfo,
  selectedEndpointId,
  disabled,
  onResetThresholdButtonClick,
}) => {
  const styles = useStyles();
  const [deployModelDialogOpen, setDeployModelDialogOpen] = useState(false);

  return (
    <>
      <Box display="flex" width="100%" marginBottom={3} justifyContent="flex-end">
        <Button
          id="predict-dialog-reset-threshold-button"
          variant="outlined"
          color="primary"
          onClick={onResetThresholdButtonClick}
        >
          {t('Reset')}
        </Button>

        <Button
          id="predict-dialog-update-threshold-button"
          variant="contained"
          color="primary"
          onClick={() => {
            setDeployModelDialogOpen(true);
          }}
          disabled={disabled}
          className={styles.updateThresholdButton}
        >
          {t('Update Threshold')}
        </Button>
      </Box>

      <DeployModelDialog
        open={deployModelDialogOpen}
        onClose={() => setDeployModelDialogOpen(false)}
        modelInfo={modelInfo}
        selectedEndpointId={selectedEndpointId}
      />
    </>
  );
};

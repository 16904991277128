import { useState, useEffect } from 'react';

interface Output {
  searchText: string;
  setSearchText: (value: string) => void;
}

/**
 * Use this hook to handle search text outside material table.
 * @example
 * const tableRef = React.useRef();
 * const { searchText, setSearchText } = useExternalSearchText(tableRef);
 * // use searchText, setSearchText as you would with useState
 * return <MaterialTable tableRef={tableRef}/>
 *
 * @param tableRef - the ref that is passed to MaterialTable
 * @return object.searchText - text that is used for search
 * @return object.setSearchText - a setter that triggers search in table
 */
export const useExternalSearchText = (tableRef: any): Output => {
  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    if (tableRef && tableRef.current) {
      const { dataManager, onSearchChangeDebounce } = tableRef.current;
      dataManager.changeSearchText(searchText);
      onSearchChangeDebounce(searchText);
    }
  }, [tableRef, searchText]);

  return { searchText, setSearchText };
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import CLPAPI from '@/api/clp_api';
import { LabelType, ProjectId } from '@clef/shared/types';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { endpointQueryKeys } from './queries';
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { useHistory } from 'react-router';
import CLEF_PATH from '@/constants/path';
import { clibAPIV0 } from '@/api/clib_api';
import { modelAnalysisQueryKeys } from '../modelAnalysis';

export const useDeployModelToEndpointMutation = () => {
  const { id: projectId, labelType } = useGetSelectedProjectQuery().data ?? {};
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const deployModel = useMutation({
    mutationFn: async (params: {
      projectId: ProjectId;
      endpointId: string;
      modelId: string;
      threshold: number | undefined;
    }) => {
      await CLPAPI.deployModelToEndpoint(params);
      return params;
    },
    onSuccess: ({ projectId }) => {
      queryClient.invalidateQueries(endpointQueryKeys.list(projectId));
      queryClient.invalidateQueries(endpointQueryKeys.bundleList(projectId));
      queryClient.invalidateQueries(modelAnalysisQueryKeys.modelList(projectId));
      enqueueSnackbar(t('Your model is successfully deployed.'), {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar(t('Failed to deploy model. Please try again later.'), {
        variant: 'error',
        autoHideDuration: 12000,
      });
    },
  });
  return {
    mutateAsync: useCallback(
      async (endpointId: string, modelId: string, threshold?: number) => {
        if (
          !projectId ||
          (!threshold &&
            (labelType === LabelType.AnomalyDetection || labelType === LabelType.BoundingBox))
        ) {
          return;
        } else if (labelType === LabelType.SegmentationInstantLearning) {
          threshold = 0;
        } else if (!threshold && labelType === LabelType.Classification) {
          threshold = 0.5;
        } else if (!threshold && labelType === LabelType.Segmentation) {
          threshold = 0.3;
        }

        await deployModel.mutateAsync({ projectId, modelId, endpointId, threshold });
        history.push(`${CLEF_PATH.deployment.overview}?selected_endpoint_id=${endpointId}`);
      },
      [labelType, projectId, history],
    ),
    isLoading: deployModel.isLoading,
  };
};

export const useCreateEndpointMutation = () => {
  const selectedProjectId = useTypedSelector(state => state.project.selectedProjectId);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: deployModel, isLoading: deployModelIsLoading } =
    useDeployModelToEndpointMutation();
  const history = useHistory();

  const createEndpoint = useMutation({
    mutationFn: async (params: { projectId: ProjectId; endpointName: string }) => {
      return CLPAPI.createEndpoint({
        projectId: params.projectId,
        endpointName: params.endpointName,
        endpointType: 'dynamic',
      });
    },
    onSuccess: ({ endpointName }) => {
      enqueueSnackbar(
        t('{{endpointName}} has been created to host your deployment.', {
          endpointName,
        }),
        {
          variant: 'success',
        },
      );
    },
  });
  return {
    mutateAsync: useCallback(
      async (
        endpointName = 'Untitled Endpoint',
        modelInfo?: {
          id: string;
          threshold?: number;
        },
      ) => {
        if (!selectedProjectId) return;
        const { endpointId } = await createEndpoint.mutateAsync(
          {
            projectId: selectedProjectId,
            endpointName,
          },
          {
            onError: (error: any) =>
              enqueueSnackbar(
                error?.body?.detail
                  ? error?.body?.detail
                  : t('Failed to create endpoint {{endpointName}}', {
                      endpointName,
                    }),
                {
                  variant: 'error',
                  autoHideDuration: 12000,
                },
              ),
          },
        );
        queryClient.invalidateQueries(endpointQueryKeys.list(selectedProjectId));
        if (modelInfo) {
          await deployModel(endpointId, modelInfo.id, modelInfo.threshold);
        } else {
          history.push(`${CLEF_PATH.deployment.overview}?selected_endpoint_id=${endpointId}`);
        }
      },
      [selectedProjectId, deployModel, history],
    ),
    isLoading: deployModelIsLoading || createEndpoint.isLoading,
  };
};

export const usePutBundleMutation = (modelName: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: { projectId: ProjectId; modelId: string; threshold: number }) => {
      const res = await clibAPIV0.putBundle(params);
      return {
        bundleId: res.bundleId,
        projectId: params.projectId,
        modelId: params.modelId,
        threshold: params.threshold,
      };
    },
    onSuccess: ({ projectId, modelId, threshold }) => {
      enqueueSnackbar(
        t(`A version of "${modelName}, confidence threshold ${threshold}" is generated`),
        { variant: 'success' },
      );
      queryClient.invalidateQueries(endpointQueryKeys.bundleList(projectId));
      queryClient.invalidateQueries(modelAnalysisQueryKeys.modelList(projectId));
      queryClient.removeQueries(modelAnalysisQueryKeys.bundleStatus(projectId, modelId, threshold));
    },
    onError: (e: Error) => enqueueSnackbar(e.message, { variant: 'error' }),
  });
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAppDispatch } from '@/store';
import { startClassifiedUpload, startUpload } from '@/store/uploadState';
import { UploadState } from '@/store/uploadState/types';
import { projectQueryKeys, useGetSelectedProjectQuery } from '@/serverStore/projects';
import { generateUploadedMessage, getUploadStats } from '@/store/uploadState/utils';
import { datasetQueryKeys } from '../dataset';
import { usageQueryKey } from '../usage';
import { useCurrentProjectModelInfoQuery } from '../projectModels';

export const useUploadMediaMutation = (
  useClassifiedUpload = false,
  isUsedInUploadWrapper = false,
) => {
  const dispatch = useAppDispatch();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { id: selectedModelId } = useCurrentProjectModelInfoQuery();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const uploadFunction = useClassifiedUpload ? startClassifiedUpload : startUpload;

  return useMutation({
    mutationFn: async () => {
      // TODO: @Louie replace redux action on migrating redux uploadState
      const { uploadData } = (await dispatch(
        uploadFunction({ selectedProject, selectedModelId }),
      ).unwrap()) as UploadState;
      return uploadData;
    },
    onSuccess: (uploadData: UploadState['uploadData']) => {
      enqueueSnackbar(
        t('Successfully uploaded {{count}} image(s)!', { count: uploadData.length }),
        { variant: 'success' },
      );
      isUsedInUploadWrapper &&
        selectedProject &&
        queryClient.invalidateQueries(usageQueryKey.mediaCountsByOrg(selectedProject.orgId));
      selectedProject &&
        queryClient.invalidateQueries(projectQueryKeys.stats(selectedProject.orgId));
      if (selectedProject && !selectedProject.coverMediaId) {
        queryClient.invalidateQueries(projectQueryKeys.list(selectedProject.orgId));
      }
      selectedProject?.id &&
        queryClient.invalidateQueries(datasetQueryKeys.filterOptions(selectedProject?.id));
    },
    onError: err => {
      const uploadStats = getUploadStats((err as UploadState).uploadData);
      enqueueSnackbar(generateUploadedMessage(uploadStats), {
        variant: 'warning',
      });
    },
    onSettled: () => {
      // Medias and Counts are invalidated during upload (throttled). We can skip them here.
      selectedProject &&
        queryClient.invalidateQueries(
          datasetQueryKeys.allWithFiltersExcludingMediasAndCounts(selectedProject.id),
        );

      selectedProject?.id &&
        queryClient.invalidateQueries(projectQueryKeys.defects(selectedProject.id));
    },
  });
};

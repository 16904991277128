import { AnnotationChangeType, Button, MediaInteractiveCanvas } from '@clef/client-library';
import { generateAnnotationId } from '@clef/client-library/src/components/MediaInteractiveCanvas/creators';
import { LabelingType } from '@clef/shared/types';
import { Typography, Grid, Box } from '@material-ui/core';
import React, { MutableRefObject, useCallback } from 'react';
import { useDialog } from '../../../components/Layout/components/useDialog';
import { useDefectSelector } from '../../../store/defectState/actions';
import {
  useGoToNextMedia,
  useLabelingTaskState,
  useSaveAnnotationsToState,
} from '../labelingTaskState';
import { useLabelingTaskStyles } from '../labelingTaskStyles';
import ClassificationDefectSelector from './ClassificationDefectSelector';
import MetadataMenu from './MetadataMenu';
import ReviewNotes from '../../LabelingReview/components/ReviewNotes';
import {
  ClassificationLabelingAnnotation,
  useLabelingState,
} from '../../../components/Labeling/labelingState';

export type LabelingTaskFooterProps = {
  mediaCanvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
};
const LabelingTaskFooter: React.FC<LabelingTaskFooterProps> = ({ mediaCanvasRef }) => {
  const styles = useLabelingTaskStyles();
  const {
    state: { currentMediaId, taskMediaList, annotationData },
  } = useLabelingTaskState();
  const {
    state: { labelingType },
  } = useLabelingState();

  const { showConfirmationDialog } = useDialog();
  const saveAnnotations = useSaveAnnotationsToState();
  const goToNextMedia = useGoToNextMedia();

  const onClassificationChanged = useCallback(
    (defectId: number) => {
      const newClassificationAnnotation: ClassificationLabelingAnnotation = {
        id: generateAnnotationId(),
        defectId,
        data: {},
      };
      saveAnnotations([newClassificationAnnotation]);
      goToNextMedia({ force: true });
    },
    [goToNextMedia, saveAnnotations],
  );

  const currentMedia = taskMediaList.find(media => media.id === currentMediaId);
  const allDefects = useDefectSelector();
  const annotationsForThisMedia = annotationData[currentMediaId];
  const nothingToLabel = annotationsForThisMedia && !annotationsForThisMedia.length;
  const hasAnnotation = annotationsForThisMedia && annotationsForThisMedia.length > 0;
  const reviewNotes = currentMedia?.label?.reviewNotes;

  if (!currentMedia || !allDefects) {
    return null;
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      wrap="nowrap"
      className={styles.footer}
    >
      {labelingType === LabelingType.DefectClassification ? (
        <>
          <MetadataMenu mediaId={currentMediaId} />
          <Box pl={6} />
          <ReviewNotes key={currentMediaId} reviewNotes={reviewNotes} defaultOpened />
          <div className={styles.flexGrow} />
          <ClassificationDefectSelector
            currentMediaId={currentMediaId}
            annotationData={annotationData}
            onClassificationChanged={onClassificationChanged}
          />
        </>
      ) : (
        <>
          <Typography>{currentMedia.name}</Typography>
          <Box pl={6} />
          <ReviewNotes key={currentMediaId} reviewNotes={reviewNotes} />
          <div className={styles.flexGrow} />
          {hasAnnotation && (
            <Button
              color="secondary"
              id="task-clear-all-labels"
              onClick={() =>
                showConfirmationDialog({
                  title: t('Are you sure you want to delete all labels?'),
                  content: t(
                    'Are you sure you want to delete all labels on this media? This operations cannot be undone',
                  ),
                  confirmText: t('Remove all labels'),
                  color: 'secondary',
                  onConfirm: () => {
                    mediaCanvasRef.current?.setAnnotations([], AnnotationChangeType.DeleteAll);
                  },
                })
              }
            >
              {t('Clear All Labels')}
            </Button>
          )}
          <Button
            variant={nothingToLabel ? 'contained' : 'outlined'}
            color="primary"
            disabled={hasAnnotation}
            onClick={() => {
              mediaCanvasRef.current?.setAnnotations([], AnnotationChangeType.DeleteAll);
              goToNextMedia();
            }}
            id="task-nothing-to-label"
          >
            {t('No Class to Label')}
          </Button>
        </>
      )}
    </Grid>
  );
};

export default LabelingTaskFooter;

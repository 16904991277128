import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import CompareArrows from '@material-ui/icons/CompareArrows';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { IconButton, Button } from '@clef/client-library';
import { MediaId, ModelEvaluationReportStatus, RegisteredModel } from '@clef/shared/types';
import { EvaluationSetItem } from '@/api/evaluation_set_api';
import { useGetBatchModelMetricsQuery } from '../../../../serverStore/modelAnalysis';
import { Skeleton } from '@material-ui/lab';
import { Typography } from '@clef/client-library';
import { isModelTrainingSuccessful } from '@/store/projectModelInfoState/utils';
import { useRunEvaluationMutation } from '@/serverStore/modelAnalysis/mutations';
import {
  getEvaluationSetName,
  isSameModelAndThreshold,
  ModelComparisonSelectionInfo,
} from '../utils';
import Check from '@material-ui/icons/Check';
import { ModelAnalysisTableCellProps } from './common';
import { StyledTableCell } from './StyledTableCell';
import Replay from '@material-ui/icons/Replay';
import { useIsLargeImageModel } from '@/hooks/useIsLargeImageModel';
import { useAddEvaluationSetMutation } from '@/serverStore/evaluationSets/mutations';
import { useGetProjectSplitQuery } from '@/serverStore/projects';
import { useSnackbar } from 'notistack';
import { isNumber } from 'lodash';

export const MODEL_PERFORMANCE_CELL_MIN_WIDTH = 228;
export const SPLITS_CELL_MIN_WIDTH = 94;

const useStyles = makeStyles(theme => ({
  evaluateRoot: {
    padding: theme.spacing(4),
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    border: '0.5px solid transparent',
    cursor: 'pointer',
    '& p': {
      color: theme.palette.greyModern[400],
    },

    '&:hover': {
      border: `0.5px solid ${theme.palette.blue[600]}`,
      backgroundColor: theme.palette.greyModern[50],

      '& p': {
        color: theme.palette.blue[500],
      },
    },
  },
  evaluateFailedRoot: {
    padding: theme.spacing(4),
    height: '100%',
    width: '100%',
  },
  evaluationFailedRetryButton: {
    fontWeight: 'bold',
    color: theme.palette.blue[500],
    textDecoration: 'none',
  },
  evaluationFailedText: {
    color: theme.palette.red[500],
  },
  root: {
    height: '100%',
    padding: theme.spacing(3.5),
    display: 'flex',
    alignItems: 'center',
    border: '0.5px solid transparent',
    cursor: 'pointer',
    '&:hover': {
      border: `0.5px solid ${theme.palette.blue[600]}`,
      backgroundColor: theme.palette.greyModern[50],
    },
    '&:hover $compareModelSwitch': {
      display: 'flex',
    },
  },
  splitsRoot: {
    borderLeft: 'none',
    borderRight: 'none',
  },
  selectedText: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  checkIcon: {
    marginLeft: theme.spacing(1.5),
  },
  performance: {
    color: theme.palette.greyModern[600],
  },
  performanceValue: {
    fontWeight: 'bold',
  },
  fullTableCell: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(4),
  },
  warningIconSvg: {
    marginRight: theme.spacing(1),
    color: theme.palette.blue[300],
  },
  compareModelSwitchWrapper: {
    flex: 1,
    position: 'relative',
    height: '20px',
  },
  compareModelSwitch: {
    display: 'none',
    position: 'absolute',
    top: '-7px',
    padding: theme.spacing(2.5),
    minWidth: 'auto!important',
    width: 34,
    backgroundColor: theme.palette.greyModern[200],
  },
}));

const UnavailableCellContent = (props: { content: string; isSplits?: boolean }) => {
  const styles = useStyles();
  const { content, isSplits = false } = props;
  return (
    <Tooltip title={content} placement="top" arrow>
      <Box
        className={cx(styles.root, styles.fullTableCell)}
        display="flex"
        alignItems="center"
        justifyContent={isSplits ? 'center' : 'flex-end'}
      >
        <Typography variant="body_regular">{t('--')}</Typography>
      </Box>
    </Tooltip>
  );
};

const UnselectedComparingCellContent = (props: { content?: string }) => {
  const styles = useStyles();
  const { content = '' } = props;
  return (
    <Tooltip title={content} placement="top" arrow>
      <Box className={cx(styles.root, styles.fullTableCell)} />
    </Tooltip>
  );
};

const LoadingCellContent = (props: { isSplits?: boolean }) => {
  const styles = useStyles();
  const { isSplits = false } = props;
  return (
    <Box className={styles.fullTableCell}>
      <Box
        height="100%"
        display="flex"
        flexDirection="row"
        justifyContent={isSplits ? 'center' : 'flex-end'}
        alignItems="center"
      >
        <Skeleton variant="text" width={40} />
      </Box>
    </Box>
  );
};

const EvaluatingCellContent = (props: { isSplits?: boolean }) => {
  const styles = useStyles();
  const { isSplits = false } = props;
  return (
    <Box className={styles.fullTableCell}>
      <Box
        height="100%"
        display="flex"
        flexDirection="row"
        justifyContent={isSplits ? 'center' : 'flex-end'}
        alignItems="center"
      >
        <Typography variant="body_regular">{t('Evaluating...')}</Typography>
      </Box>
    </Box>
  );
};

const PerformanceCellContent = (props: {
  isBaseline: boolean | null | undefined;
  isCandidate: boolean | null | undefined;
  overallPerformance: number | undefined;
  onCellClick: (() => void) | undefined;
  onCompareClick?: () => void;
  showEvalMediaUsedForTrain: boolean;
  evalMediaIdsInTrainSet: MediaId[] | undefined;
  isSplits?: boolean;
  cellTooltip?: string;
  comparing?: boolean;
}) => {
  const styles = useStyles();
  const {
    isBaseline,
    isCandidate,
    overallPerformance,
    onCellClick,
    onCompareClick,
    showEvalMediaUsedForTrain,
    evalMediaIdsInTrainSet,
    isSplits = false,
    cellTooltip = '',
    comparing = false,
  } = props;
  return (
    <Tooltip placement="top" arrow title={cellTooltip}>
      <Box
        className={styles.root}
        onClick={onCellClick}
        justifyContent={isSplits ? 'center' : 'unset'}
      >
        {(isBaseline || isCandidate) && (
          <Box display="flex" alignItems="center">
            <Box className={styles.selectedText}>
              {isBaseline ? t('Baseline model') : t('Candidate model')}
            </Box>
            <Check fontSize="small" color="primary" className={styles.checkIcon} />
          </Box>
        )}
        {!isSplits && (
          <Box className={styles.compareModelSwitchWrapper}>
            {!comparing ? (
              <Tooltip arrow placement="top" title={t('Compare')}>
                <Button
                  id="model-comparison-switch"
                  variant="contained"
                  className={styles.compareModelSwitch}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onCompareClick?.();
                  }}
                >
                  <CompareArrows fontSize="small" />
                </Button>
              </Tooltip>
            ) : null}
          </Box>
        )}
        {showEvalMediaUsedForTrain && evalMediaIdsInTrainSet?.length ? (
          <Tooltip
            placement="top"
            arrow={true}
            title={t(
              'The model was trained with {{mediaCountUsedForTraining}} images from this evaluation set, influencing the comparability of performance metrics with other models.',
              { mediaCountUsedForTraining: evalMediaIdsInTrainSet.length },
            )}
          >
            <InfoOutlined fontSize="small" className={styles.warningIconSvg} />
          </Tooltip>
        ) : null}
        <Box className={styles.performance}>
          {t('{{performance}}%', {
            performance: (
              <Typography display="inline" className={styles.performanceValue}>
                {Math.round((overallPerformance ?? 0) * 100)}
              </Typography>
            ),
          })}
        </Box>
      </Box>
    </Tooltip>
  );
};

const RunEvaluationCell = (props: { onRunEvaluationClick: () => void }) => {
  const styles = useStyles();
  const { onRunEvaluationClick } = props;
  return (
    <Box className={styles.evaluateRoot} onClick={onRunEvaluationClick}>
      <Typography>{t('Evaluate')}</Typography>
    </Box>
  );
};

const RerunEvaluationCell = (props: { onRerunEvaluationClick: () => void; isSplits?: boolean }) => {
  const styles = useStyles();
  const { onRerunEvaluationClick, isSplits } = props;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={isSplits ? 'center' : 'flex-end'}
      className={styles.evaluateFailedRoot}
    >
      <Typography className={styles.evaluationFailedText} variant="body_regular">
        {t('Evaluation failed')}
      </Typography>
      <IconButton size={'small'} tooltip="Re-run evaluation job" onClick={onRerunEvaluationClick}>
        <Replay />
      </IconButton>
    </Box>
  );
};

export type ModelPerformanceTableCellProps = {
  model: RegisteredModel;
  evaluationSet: EvaluationSetItem | null;
  threshold: number;
  comparisonSelectionInfo?: ModelComparisonSelectionInfo;
  enableComparison?: boolean;
  onCellClick?: () => void;
  onCompareClick?: () => void;
  splitName?: string;
  isSplits?: boolean;
  columnSelected?: boolean;
  onBoardingHighlight?: boolean;
} & ModelAnalysisTableCellProps;

const ModelPerformanceTableCell: React.FC<ModelPerformanceTableCellProps> = props => {
  const {
    model,
    evaluationSet,
    threshold,
    comparisonSelectionInfo,
    enableComparison,
    onCellClick,
    onCompareClick,
    splitName,
    isSplits,
    columnSelected = false,
    onBoardingHighlight = false,
    ...otherProps
  } = props;
  const styles = useStyles();
  const [isLargeImageModel] = useIsLargeImageModel(model?.id);

  const { enqueueSnackbar } = useSnackbar();
  const {
    baseline,
    candidate,
    evaluationSet: selectedEvaluationSet,
  } = comparisonSelectionInfo ?? {};
  const { data: projectSplits = [] } = useGetProjectSplitQuery();
  const { data, isInitialLoading } = useGetBatchModelMetricsQuery();
  const [isEvaluating, setIsEvaluating] = useState<boolean>(false);

  const modelMetrics =
    evaluationSet && data
      ? data.find(
          pred =>
            pred.evaluationSetId === evaluationSet?.id &&
            pred.threshold === threshold &&
            pred.modelId === model.id,
        )
      : undefined;
  const projectSplitNameToId = projectSplits.reduce((obj: Record<string, number>, projectSplit) => {
    obj[projectSplit.splitSetName] = projectSplit.id;
    return obj;
  }, {});
  const splitId = !splitName ? null : projectSplitNameToId[splitName] ?? null;
  const showEvalMediaUsedForTrain = !splitName;
  const addEvaluationSetMutation = useAddEvaluationSetMutation();
  const runEvaluationMutation = useRunEvaluationMutation(
    model.modelName || t('Untitled model'),
    evaluationSet ? getEvaluationSetName(evaluationSet) : '',
  );
  const overallPerformance = modelMetrics?.metrics?.all?.performance;

  const isBaseline =
    evaluationSet &&
    evaluationSet.id === selectedEvaluationSet?.id &&
    isSameModelAndThreshold({ ...model, threshold }, baseline);
  const isCandidate =
    evaluationSet &&
    evaluationSet.id === selectedEvaluationSet?.id &&
    isSameModelAndThreshold({ ...model, threshold }, candidate);

  const handleRunOrRerunEvaluation = async () => {
    setIsEvaluating(true);
    if (evaluationSet) {
      runEvaluationMutation.mutate(
        {
          modelId: model.id,
          evaluationSetId: evaluationSet.id,
          threshold,
        },
        {
          onSettled: () => setIsEvaluating(false),
        },
      );
    } else {
      if (splitName && model.datasetVersionId) {
        try {
          const newEvalSet = await addEvaluationSetMutation.mutateAsync({
            datasetVersionId: model.datasetVersionId,
            splitId: splitId,
            hidden: true,
          });
          if (newEvalSet) {
            await runEvaluationMutation.mutateAsync({
              modelId: model.id,
              evaluationSetId: newEvalSet.id,
              threshold,
            });
          } else {
            setIsEvaluating(false);
          }
        } catch (e) {
          setIsEvaluating(false);
          enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
        }
      } else {
        setIsEvaluating(false);
        enqueueSnackbar(
          t('There is not {{split}} evaluation set associated with this model', {
            split: splitName,
          }),
          { variant: 'error' },
        );
      }
    }
  };

  useEffect(() => {
    isEvaluating && modelMetrics?.evaluationStatus && setIsEvaluating(false);
  }, [modelMetrics?.evaluationStatus, isEvaluating]);

  // when column selected, highlight the cell if the evaluation is completed and the performance is available
  const highlightCell =
    isModelTrainingSuccessful(model?.status, model?.metricsReady) &&
    !isInitialLoading &&
    !isEvaluating &&
    modelMetrics?.evaluationStatus === ModelEvaluationReportStatus.COMPLETED &&
    isNumber(threshold) &&
    modelMetrics?.metrics?.all.performance !== -1;

  let cellContent: React.ReactElement | null = null;
  if (!isModelTrainingSuccessful(model?.status, model?.metricsReady)) {
    cellContent = (
      <UnavailableCellContent content={t(`Model metrics not ready`)} isSplits={isSplits} />
    );
  } else if (isInitialLoading) {
    cellContent = <LoadingCellContent isSplits={isSplits} />;
  } else if (!isNumber(threshold)) {
    cellContent = (
      <UnavailableCellContent
        content={t(`There is no confidence threshold for this model`)}
        isSplits={isSplits}
      />
    );
  } else if (
    isEvaluating ||
    modelMetrics?.evaluationStatus === ModelEvaluationReportStatus.STARTED
  ) {
    cellContent = <EvaluatingCellContent isSplits={isSplits} />;
  } else if (modelMetrics?.evaluationStatus === ModelEvaluationReportStatus.FAILED) {
    cellContent =
      !enableComparison || columnSelected ? (
        <RerunEvaluationCell
          onRerunEvaluationClick={handleRunOrRerunEvaluation}
          isSplits={isSplits}
        />
      ) : (
        <UnselectedComparingCellContent
          content={t('You can only compare model performance on the same evaluation set.')}
        />
      );
  } else if (!modelMetrics?.evaluationStatus || !evaluationSet) {
    if (isLargeImageModel) {
      cellContent =
        !enableComparison || columnSelected ? (
          <UnavailableCellContent
            content={t(`At this point, evaluation is not 
      supported for large image models.`)}
            isSplits={isSplits}
          />
        ) : (
          <UnselectedComparingCellContent
            content={t('You can only compare model performance on the same evaluation set.')}
          />
        );
    } else {
      cellContent =
        !enableComparison || columnSelected ? (
          <RunEvaluationCell onRunEvaluationClick={handleRunOrRerunEvaluation} />
        ) : (
          <UnselectedComparingCellContent
            content={t('You can only compare model performance on the same evaluation set.')}
          />
        );
    }
  } else if (modelMetrics?.metrics?.all.performance === -1) {
    cellContent = (
      <UnavailableCellContent
        content={
          enableComparison && !columnSelected
            ? t('You can only compare model performance on the same evaluation set.')
            : t(`No images in this evaluation set`)
        }
        isSplits={isSplits}
      />
    );
  } else {
    cellContent = (
      <PerformanceCellContent
        isBaseline={isBaseline}
        isCandidate={isCandidate}
        onCellClick={
          !enableComparison || (enableComparison && columnSelected) ? onCellClick : undefined
        }
        onCompareClick={onCompareClick}
        overallPerformance={overallPerformance}
        showEvalMediaUsedForTrain={showEvalMediaUsedForTrain}
        evalMediaIdsInTrainSet={modelMetrics.evalMediaIdsInTrainSet}
        isSplits={isSplits}
        cellTooltip={
          enableComparison && !columnSelected
            ? t('You can only compare model performance on the same evaluation set.')
            : ''
        }
        comparing={enableComparison}
      />
    );
  }

  return (
    <StyledTableCell
      id={`cell-${model.id}-${evaluationSet?.id}-${threshold}-${splitName}`}
      key={`cell-${model.id}-${evaluationSet?.id}-${threshold}-${splitName}`}
      fixedWidth={isSplits ? SPLITS_CELL_MIN_WIDTH : MODEL_PERFORMANCE_CELL_MIN_WIDTH}
      className={isSplits ? styles.splitsRoot : ''}
      highlight={(columnSelected && highlightCell) || onBoardingHighlight}
      {...otherProps}
    >
      {cellContent}
    </StyledTableCell>
  );
};

export default ModelPerformanceTableCell;

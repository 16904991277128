import React, { useState } from 'react';
import { Box, CircularProgress, makeStyles, TextField } from '@material-ui/core';
import { Button, SelectOptions } from '@clef/client-library';
import CreditsAPI from '@/api/credits';
import { useSnackbar } from 'notistack';
import { isInteger } from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import { UsageOptionTexts } from '../data';
import { useGetProjectsQuery } from '@/serverStore/projects';
import { queryClient } from '@/serverStore';
import { usageQueryKey } from '@/serverStore/usage';
import { useTypedSelector } from '@/hooks/useTypedSelector';

const useStyles = makeStyles(theme => ({
  usageInput: {
    width: 100,
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  projectInput: {
    width: 300,
  },
}));

export type UsageReporterProps = {};

const UsageReporter: React.FC<UsageReporterProps> = () => {
  const styles = useStyles();
  const [api, setApi] = useState('/train/v2/create_fast_training_run');
  const [usage, setUsage] = useState<number | undefined>(1);
  const [saving, setSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { orgId } = useTypedSelector(state => state.login.user) ?? {};
  const { data: projects } = useGetProjectsQuery();
  const projectOptions = projects ?? [];
  const [selectedProject, setSelectedProject] = useState(projectOptions[0]);

  const usageError = !isInteger(usage) || usage! < 1;
  const noProjectError = projectOptions.length === 0;
  const projectSelectionError = !projectOptions.some(o => o.id === selectedProject?.id);
  const projectError = noProjectError || projectSelectionError;
  const hasError = usageError || projectError;

  return (
    <Box marginY={4}>
      <Box display="flex" alignItems="center">
        <Box marginRight={4}>{t('Report usage:')}</Box>
        <SelectOptions
          options={['/train/v2/create_fast_training_run', '/inference/v2/predict']}
          value={api}
          renderValue={option => UsageOptionTexts[option as string]}
          onChange={function (option: string | number): void {
            setApi(option as string);
          }}
        />
        <TextField
          type="number"
          variant="outlined"
          placeholder={t('usage')}
          size="small"
          inputProps={{ min: 1 }}
          className={styles.usageInput}
          value={usage}
          onChange={e => setUsage(e.target.value === '' ? undefined : Number(e.target.value))}
          error={usageError}
          label={t('Usage')}
        />
        <Autocomplete
          options={projectOptions}
          value={selectedProject}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              label={t('Project')}
              className={styles.projectInput}
              error={projectError}
            />
          )}
          getOptionLabel={option => option.name}
          onChange={(_, newOption) => setSelectedProject(newOption!)}
        />
        {!hasError && (
          <Button
            id="confirm-report-usage"
            disabled={saving}
            color="primary"
            startIcon={saving && <CircularProgress size={20} />}
            onClick={async () => {
              setSaving(true);
              try {
                await CreditsAPI.reportUsage(usage!, selectedProject.id!, api);
                orgId && queryClient.invalidateQueries(usageQueryKey.all(orgId));
                enqueueSnackbar(t('Report successfully!'), { variant: 'success' });
              } catch (e) {
                enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
              }
              setSaving(false);
            }}
          >
            {t('Report')}
          </Button>
        )}
      </Box>
      {hasError && (
        <>
          {usageError && (
            <Box marginTop={1} className={styles.errorMessage}>
              {t('Usage must be an integer not less than 1')}
            </Box>
          )}
          {noProjectError && (
            <Box marginTop={1} className={styles.errorMessage}>
              {t('Usage report requires a project but no project created for this org')}
            </Box>
          )}
          {projectSelectionError && (
            <Box marginTop={1} className={styles.errorMessage}>
              {t('Select a project to proceed')}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default UsageReporter;

import { TransformPipelineId, ProjectId, Pipeline } from '@clef/shared/types';

import fetchHookFactory from './fetchHookFactory';
import TransformsApi from '../../api/transforms_api';

export const [useTransformPipelineYaml] = fetchHookFactory(
  (params: { projectId: ProjectId; pipelineId: TransformPipelineId }) =>
    TransformsApi.getPipelineYaml(params),
  'cache',
);

export const [useTransformsApi] = fetchHookFactory(
  (_params: {}) => TransformsApi.getTransforms(),
  'cache',
);

export const [useTransformImages, refreshTransformImages] = fetchHookFactory(
  async (params: { sources: string[]; sections: Pipeline['sections']; projectId?: ProjectId }) => {
    const { sources, sections, projectId } = params;
    const errorMessages = new Set<string>();
    const images = await Promise.all(
      sources.map(async url => {
        try {
          const transformed = await TransformsApi.executePipeline([url], sections, projectId);
          return transformed['train'][0];
        } catch (e) {
          errorMessages.add(e.message);
          return undefined;
        }
      }),
    );
    if (errorMessages.size) {
      throw new Error(Array.from(errorMessages).join('\n'));
    }
    return images;
  },
  'cache',
);

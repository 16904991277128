import {
  Paper,
  Box,
  Typography,
  Badge,
  Grid,
  Popover,
  TextField,
  useTheme,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import InsertCommentOutlined from '@material-ui/icons/InsertCommentOutlined';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';
import { useLabelingReviewStyles } from '../labelingReviewStyles';
import { Button, IconButton, useKeyPress } from '@clef/client-library';

export interface ReviewNotesActions {
  openPopover(open: boolean): void;
}

export type ReviewNotesProps = {
  reviewNotes?: string;
  onFinishEditing?: (newReviewNotes: string) => void;
  actions?: React.RefObject<ReviewNotesActions | undefined>;
  defaultOpened?: boolean;
};

const ReviewNotes: React.FC<ReviewNotesProps> = ({
  reviewNotes,
  onFinishEditing,
  actions,
  defaultOpened = false,
}) => {
  const styles = useLabelingReviewStyles();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'review-notes-popover',
  });

  const anchorEl = useRef<HTMLDivElement>(null);
  const openPopover = useCallback(
    (open: boolean) => {
      popupState.setOpen(open, anchorEl.current ?? undefined);
    },
    [popupState],
  );

  useImperativeHandle(
    actions,
    () => ({
      openPopover,
    }),
    [openPopover],
  );

  const [localReviewNotes, setLocalReviewNotes] = useState(reviewNotes ?? '');
  useEffect(() => {
    setLocalReviewNotes(reviewNotes ?? '');
  }, [reviewNotes]);

  const once = useRef(false);
  useEffect(() => {
    if (!once.current && defaultOpened && !!reviewNotes) {
      openPopover(true);
      once.current = true;
    }
  }, [defaultOpened, openPopover, reviewNotes]);

  useKeyPress('o', e => {
    e.preventDefault();
    e.stopPropagation();
    openPopover(!popupState.isOpen);
  });

  useKeyPress('esc', e => {
    e.preventDefault();
    openPopover(false);
  });

  const theme = useTheme();

  return (
    <>
      <Badge
        id="open-review-notes-button"
        data-testid="open-review-notes-button"
        ref={anchorEl}
        color="primary"
        variant="dot"
        invisible={!reviewNotes}
        classes={{ badge: styles.reviewNotesBadge }}
        {...bindTrigger(popupState)}
      >
        <InsertCommentOutlined style={{ color: theme.palette.grey[500] }} />
      </Badge>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        data-testid="review-notes-popover"
        disableAutoFocus
        disableEnforceFocus
      >
        <Paper className={styles.reviewNotesPopover}>
          <IconButton
            size="small"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              openPopover(false);
            }}
            className={styles.closeButton}
            aria-label="Close review notes"
          >
            <Close fontSize="small" />
          </IconButton>
          <Box px={5} py={4}>
            <Typography variant="body2">
              <strong>{t('Review notes')}</strong>
            </Typography>
            <Box pb={2} />
            {onFinishEditing ? (
              <>
                <Box pb={2}>
                  <Typography variant="body2">
                    {t('Use {{shift}} + {{enter}} to add new line.', {
                      shift: <span className={styles.codeBlockLight}>{t('shift')}</span>,
                      ctrl: <span className={styles.codeBlockLight}>{t('ctrl')}</span>,
                      enter: <span className={styles.codeBlockLight}>{t('enter')}</span>,
                    })}
                  </Typography>
                </Box>
                <TextField
                  data-testid="media-review-comment"
                  className={styles.reviewComment}
                  placeholder={t('Leave notes about this media')}
                  type={'text'}
                  multiline
                  size="small"
                  fullWidth
                  autoFocus
                  onKeyDown={e => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      openPopover(false);
                      onFinishEditing(localReviewNotes);
                    }
                  }}
                  onChange={e => {
                    e.persist();
                    setLocalReviewNotes(e?.target?.value ?? '');
                  }}
                  rows={4}
                  rowsMax={4}
                  value={localReviewNotes}
                  variant={'outlined'}
                />
                <Box pb={5} />
                <Grid container>
                  <div className={styles.flexGrow} />
                  <Grid item>
                    <Button
                      id="confirm-reject"
                      data-testid="confirm-reject-button"
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        openPopover(false);
                        onFinishEditing(localReviewNotes);
                      }}
                      endIcon={<Close />}
                    >
                      <Typography variant="body2">{t('Reject')}</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Typography variant="body2" className={styles.reviewNotesText}>
                {reviewNotes ?? ''}
              </Typography>
            )}
          </Box>
        </Paper>
      </Popover>
    </>
  );
};

export default ReviewNotes;

import { makeStyles } from '@material-ui/core/styles';

export const useLabelingTaskDialogStyles = makeStyles(theme => ({
  notAvailableCount: {
    color: theme.palette.grey[300],
  },
  typography: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
  inputRoot: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
      height: 40,
      width: '80%',
    },
  },
  checkIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(2),
  },
  textWithIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: '0.8rem',
  },
  alerts: {
    marginBottom: theme.spacing(5),
    borderRadius: 8,
  },
  dialogContainer: {
    padding: theme.spacing(5),
  },
  postRequestMask: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 100,
    background: 'rgba(255,255,255,0.7)',
  },
  errorMessage: {
    marginLeft: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  infoIcon: {
    color: theme.palette.grey[400],
    marginLeft: theme.spacing(2),
  },
}));

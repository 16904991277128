import { Button, useKeyPress } from '@clef/client-library';
import { LabelType } from '@clef/shared/types';
import React, { useCallback, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useDataBrowserState } from '../dataBrowserState';
import {
  initialState,
  PerformanceType,
  ProjectModelDetailsContext,
} from '../ProjectModelDetails/states';
import { useDataBrowserStyles } from '../styles';
import { FirstRunLiveExperienceDialog } from '../FirstRunLiveExperienceDialog';
import cx from 'classnames';
import { useDatasetMedias } from '../utils';

type ModelPerformanceProps = {};

const ModelPerformance: React.FC<ModelPerformanceProps> = () => {
  const styles = useDataBrowserStyles();
  const { labelType } = useGetSelectedProjectQuery().data ?? {};

  const {
    state: { rightSidebar, viewMode, isFirstRunLiveExperienceModalOpen },
    dispatch,
  } = useDataBrowserState();

  const {
    data: datasetMedias,
    isLoading: datasetMediasLoading,
    error: datasetMediasError,
  } = useDatasetMedias();

  const isProjectEmpty =
    !datasetMediasLoading && !datasetMediasError && datasetMedias?.length === 0;

  const selected = rightSidebar === 'model_performance';
  useKeyPress(
    'm',
    useCallback(() => {
      if (labelType === LabelType.AnomalyDetection) {
        return;
      }
      dispatch(draft => {
        draft.rightSidebar = selected ? undefined : 'model_performance';
      });
    }, [dispatch, labelType, selected]),
  );

  // TODO: remove this level of state
  const [state, dispatchModelStates] = useImmer(initialState);
  useEffect(() => {
    dispatchModelStates(draft => {
      if (labelType === LabelType.Classification || labelType === LabelType.AnomalyDetection) {
        draft.performanceType = PerformanceType.AnnotationLevel;
      } else {
        draft.performanceType =
          viewMode === 'image' ? PerformanceType.MediaLevel : PerformanceType.AnnotationLevel;
      }
    });
  }, [dispatchModelStates, labelType, viewMode]);

  if (labelType === LabelType.AnomalyDetection) {
    return null;
  }

  return (
    <ProjectModelDetailsContext.Provider value={{ state, dispatch: dispatchModelStates }}>
      {!isProjectEmpty && (
        <Button
          color="primary"
          id="open-model-performance-sidebar-button"
          variant="outlined"
          className={cx({
            [styles.modelListBtn]: true,
            [styles.modelListBtnActive]: rightSidebar === 'model_performance',
          })}
          onClick={() => {
            dispatch(draft => {
              if (rightSidebar === 'model_performance') {
                draft.rightSidebar = undefined;
              } else {
                draft.rightSidebar = 'model_performance';
              }
            });
          }}
        >
          {t('Model list')}
        </Button>
      )}
      {isFirstRunLiveExperienceModalOpen && <FirstRunLiveExperienceDialog />}
    </ProjectModelDetailsContext.Provider>
  );
};

export default ModelPerformance;

import { ApiResponseLoader, useKeyPress } from '@clef/client-library';
import { Metadata } from '@clef/shared/types';
import { Box, ClickAwayListener, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import SetMetadataDropdown from '../../../components/SetMetadata/SetMetadataDropdown';
import { useMediaMetadataApi, useMetadataApi } from '../../../hooks/api/useMetadataApi';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import MediaMetadataPanel from '../../DataBrowser/MediaDetailsDialog/MediaMetadataPanel';
import { useHandleSetMetadata } from '../../DataBrowser/MediaDetailsDialog/Metadata/AddMetadata';
import { useLabelingTaskStyles } from '../labelingTaskStyles';

export type MetadataMenuProps = {
  mediaId: number;
};

const MetadataMenu: React.FC<MetadataMenuProps> = ({ mediaId }) => {
  const styles = useLabelingTaskStyles();

  const handleSetMetadata = useHandleSetMetadata(mediaId);

  const { data: selectedProject } = useGetSelectedProjectQuery();
  const [mediaMetadata, mediaMetadataLoading, mediaMetadataError] = useMediaMetadataApi(
    selectedProject
      ? {
          projectId: selectedProject.id,
          mediaId,
        }
      : undefined,
  );
  const [metadataMap, metadataMapLoading, metadataMapError] = useMetadataApi(selectedProject?.id);

  const [open, setOpen] = useState(false);

  useKeyPress('m', () => setOpen(prev => !prev));
  useKeyPress('esc', () => setOpen(false));

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={styles.metadataPanel}>
        <Typography
          id="add-metadata-button"
          variant="body1"
          component="div"
          color="primary"
          className={styles.addMetadataButton}
          onClick={() => setOpen(true)}
        >
          <strong>{t('Add metadata')}</strong>
        </Typography>

        {open && (
          <Paper className={styles.metadataPopover} data-testid="metadata-popover">
            <Box px={5} pt={3} data-testid="existing-metadata-list">
              <Box pb={3}>
                <Typography variant="body2">
                  <strong>{t('Existing metadata')}</strong>
                </Typography>
              </Box>
              <MediaMetadataPanel
                mediaId={mediaId}
                showAddMetadataButton={false}
                mediaMetadata={mediaMetadata || {}}
              />
            </Box>
            <ApiResponseLoader
              response={mediaMetadata && metadataMap ? { mediaMetadata, metadataMap } : undefined}
              loading={mediaMetadataLoading || metadataMapLoading}
              error={mediaMetadataError || metadataMapError}
              defaultHeight={100}
            >
              {({ metadataMap: metadataMapLoaded, mediaMetadata: mediaMetadataLoaded }) => {
                const metadataIds = Object.keys(metadataMapLoaded!);
                const appliedMetadataIds = metadataIds
                  .filter(metadataId => mediaMetadataLoaded[metadataId] !== undefined)
                  .map(Number);

                const isAllApplied = appliedMetadataIds.length === metadataIds.length;
                return isAllApplied ? (
                  <Box pb={3} />
                ) : (
                  <>
                    <Box px={4} py={3}>
                      <div className={styles.metadataMenuLine} />
                    </Box>

                    <Box px={5}>
                      <Typography variant="body2">
                        <strong>{t('Add New')}</strong>
                      </Typography>
                    </Box>

                    <SetMetadataDropdown
                      setMetadataAction={(metadata: Metadata) =>
                        async (value: string[] | number[]) => {
                          await handleSetMetadata(metadata)(value);
                        }}
                      metadataFilter={metadataList =>
                        metadataList.filter(metadata => !appliedMetadataIds.includes(metadata.id))
                      }
                    />
                  </>
                );
              }}
            </ApiResponseLoader>
          </Paper>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default MetadataMenu;

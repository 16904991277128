import React, { MutableRefObject } from 'react';
import cx from 'classnames';
import { useLabelingTaskStyles } from './labelingTaskStyles';
import { Typography } from '@material-ui/core';
import LabelPreviewList from '../../components/Labeling/LabelPreviewList';
import MetadataPanel from '../../components/SetMetadata/MetadataPanel';
import { useLabelingTaskState } from './labelingTaskState';
import { AnnotationSourceType, MediaInteractiveCanvas } from '@clef/client-library';

export interface LabelingTaskMediaInfoProps {
  mediaCanvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
}

const LabelingTaskMediaInfo: React.FC<LabelingTaskMediaInfoProps> = ({ mediaCanvasRef }) => {
  const styles = useLabelingTaskStyles();
  const {
    state: { currentMediaId, annotationData, taskMediaList },
  } = useLabelingTaskState();
  const annotationsForThisMedia = annotationData[currentMediaId] ?? [];
  const currentMedia = taskMediaList.find(media => media.id === currentMediaId);
  return (
    <section
      className={cx(styles.panelContainer, styles.mediaInfoPanelContainer)}
      data-testid="media-info-panel"
    >
      <div
        className={styles.panelSection}
        style={{ height: '80%' }}
        data-testid="label-preview-list"
      >
        <Typography variant="subtitle1" className={styles.panelSectionH4}>
          {t('Labels')}
        </Typography>
        <LabelPreviewList
          mediaCanvasRef={mediaCanvasRef}
          isLabelMode
          annotationSourceType={AnnotationSourceType.GroundTruth}
          defaultExpanded
          annotations={annotationsForThisMedia}
          mediaDimensions={currentMedia?.properties || undefined}
        />
      </div>
      <div className={styles.panelSection} style={{ height: '20%' }}>
        <Typography variant="subtitle1" className={styles.panelSectionH4}>
          {t('Metadata')}
        </Typography>
        <MetadataPanel mediaId={currentMediaId} />
      </div>
    </section>
  );
};

export default LabelingTaskMediaInfo;

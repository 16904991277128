import React from 'react';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { ApiResponseLoader } from '@clef/client-library';
import { useQuotaAndUsageInfo } from '../useSubscriptions';
import { LabelType } from '@clef/shared/types';
import { InstantLearningMediaLimit } from '@/constants';

/**
 * Only used for instant learning projects
 * @param Component
 * @returns
 */
export const withMediaLimitApiHOC = <T extends {}>(Component: React.FC<T>) => {
  const WrappedComponent: React.FC<
    Omit<T, 'mediaCount' | 'mediaLimit' | 'mediaCapacity' | 'hasNoMediaCapacity'>
  > = props => {
    const { data: selectedProject } = useGetSelectedProjectQuery();
    const { labelType } = selectedProject ?? { labelType: undefined };
    const isSegmentationInstantLearning = labelType === LabelType.SegmentationInstantLearning;

    const { imageUsage, imageUsageLoading, imageUsageError } = useQuotaAndUsageInfo();

    const imageLimitData = {
      currentCount: imageUsage,
    };

    if (!isSegmentationInstantLearning) {
      return (
        <Component
          {...(props as T)}
          mediaCount={null}
          mediaLimit={null}
          mediaCapacity={null}
          hasNoMediaCapacity={false}
        />
      );
    }

    return (
      <ApiResponseLoader
        response={imageLimitData}
        loading={imageUsageLoading}
        error={imageUsageError}
      >
        {imageLimitLoaded => {
          const quota = isSegmentationInstantLearning ? InstantLearningMediaLimit : null;
          const imageUsage = imageLimitLoaded.currentCount;
          const mediaCapacity =
            !!(quota || quota === 0) && !!(imageUsage || imageUsage === 0)
              ? quota - imageUsage
              : null;
          const hasNoMediaCapacity = mediaCapacity !== null && mediaCapacity <= 0;

          return (
            <Component
              {...(props as T)}
              mediaCount={imageUsage}
              mediaLimit={quota}
              mediaCapacity={mediaCapacity}
              isNewCreditSystem={true}
              hasNoMediaCapacity={hasNoMediaCapacity}
            />
          );
        }}
      </ApiResponseLoader>
    );
  };

  return WrappedComponent;
};

import { Subscription, SubscriptionName } from '@clef/shared/types';
import { useMemo } from 'react';
import { useSubscriptionPlans } from '../../hooks/api/useSubscriptionApi';
import { usePlanPageStateContext } from './state';

type CreditOptionInfo = {
  credits: number;
  unitPrice: number;
  monthPrice: number;
  yearPrice: number;
  stripePriceId: string;
};

export const useCreditOptions = (plan?: SubscriptionName): CreditOptionInfo[] => {
  const { state } = usePlanPageStateContext();
  const [planData] = useSubscriptionPlans({});
  const { paymentInterval } = state;
  const tiers = useMemo(() => {
    let selectedPlan = plan ?? state.plan;
    if (selectedPlan === SubscriptionName.Freemium) {
      selectedPlan = SubscriptionName.Starter;
    }
    return planData?.find(data => data.name.toLowerCase() === selectedPlan)?.tiers || [];
  }, [plan, planData, state.plan]);

  return useMemo(() => {
    return tiers.map(tier => {
      return {
        credits: tier?.credits,
        stripePriceId:
          paymentInterval === 'monthly'
            ? tier.prices.month.stripePriceId
            : tier.prices.year.stripePriceId,
        unitPrice:
          paymentInterval === 'monthly'
            ? tier.prices.month.price
            : Math.round(tier.prices.year.price / 12),
        monthPrice: tier.prices.month.price,
        yearPrice: tier.prices.year.price,
      };
    });
  }, [paymentInterval, tiers]);
};

/**
 * Convert product name to readable plan name
 *
 * enterprise   -> enterprise
 * starter-N    -> starter
 * visionary-N  -> visionary
 * freemium     -> freemium
 * freemium(trailing) -> free trial
 */
export const getPlanName = (subscription?: Pick<Subscription, 'meta' | 'status'>) => {
  const { meta, status } = subscription ?? {};
  if (!meta) {
    return '';
  }
  const { plan } = meta;
  if (!plan || plan === SubscriptionName.Enterprise) {
    return t(plan ?? '');
  }
  if (plan === SubscriptionName.Freemium) {
    return status === 'trialing' ? t('free trial') : t('freemium');
  }
  return plan.replace('_', ' ');
};

export const formatPrice = (price: number) => {
  if (!price) return 0;
  return Number(price || 0).toLocaleString();
};

import React from 'react';
import { DropzoneOptions, DropzoneState, useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import { GetPredictions } from '../hooks/useLivePredictions';
import { Box } from '@material-ui/core';
import { UploadAnimate } from '../../../pages/DataBrowser/DataBrowserUploadDropZone';
import { isCypressEnv } from '@clef/client-library/src/utils/env';

type OnDrop<T extends Blob> = (acceptedFiles: T[]) => void;

interface DragAndDropZoneProps {
  children: React.ReactNode;
  getPredictions: GetPredictions;
  dropzoneProps?: DropzoneOptions;
  maxFilesLength?: number; // explicitly set max files length, using the native one will not prompt
}

const DragAndDropZone: React.FC<DragAndDropZoneProps> = ({
  children,
  getPredictions,
  dropzoneProps = {},
  maxFilesLength,
}) => {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const onDrop: OnDrop<File> = acceptedFiles => {
    if (!maxFilesLength || (maxFilesLength && acceptedFiles.length <= maxFilesLength)) {
      getPredictions(acceptedFiles);
    } else {
      enqueueSnackbar(t(`Only upload up to ${maxFilesLength} files at once.`), {
        variant: 'error',
        autoHideDuration: 12000,
        preventDuplicate: true,
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive }: DropzoneState = useDropzone({
    accept: ['image/jpeg', 'image/bmp', 'image/png', '.mpo'],
    noKeyboard: true,
    noDragEventsBubbling: true,
    multiple: true,
    onDrop,
    ...dropzoneProps,
  });

  return (
    <Box
      {...getRootProps()}
      display="flex"
      flexDirection="column"
      flex="1"
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      {isDragActive && !isCypressEnv() && (
        <Box className={styles.overlay}>
          <UploadAnimate label={t('Drop images here')} />
        </Box>
      )}
      <input
        {...getInputProps()}
        {...(isDragActive && { directory: true, webkitdirectory: true })}
        multiple
        type="file"
        id="predict-drop-zone"
        data-testid="predict-dropzone"
      />
      {children}
    </Box>
  );
};

export default DragAndDropZone;

import { DefectBookExample } from '@clef/shared/types';
import { AnnotationSourceType, CanvasAnnotation, CanvasAnnotationType } from '@clef/client-library';

export const defectExampleAnnotationToCanvasAnnotation = (
  input: DefectBookExample['annotations'],
): CanvasAnnotation[] =>
  [
    ...input.boxAnnotations.map((bA, index) => ({
      id: `box_${index}`, // does not matter as it is not stored in backend
      type: CanvasAnnotationType.Box,
      data: bA,
    })),
    ...input.lineAnnotations.map((bA, index) => ({
      id: `line_${index}`, // does not matter as it is not stored in backend
      type: CanvasAnnotationType.Line,
      data: bA,
    })),
    ...input.textAnnotations.map((bA, index) => ({
      id: `text_${index}`, // does not matter as it is not stored in backend
      type: CanvasAnnotationType.Text,
      data: bA,
    })),
  ].map(ann => ({
    ...ann,
    group: AnnotationSourceType.GroundTruth,
  }));

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(5, 2),
  },
  autoWidth: {
    width: 'auto',
  },
  helperText: {
    marginBottom: theme.spacing(8),
  },
  helperTextUnderlined: {
    textDecoration: 'underline dotted',
  },
  marginRight1: {
    marginRight: theme.spacing(1),
  },
  marginRight8: {
    marginRight: theme.spacing(8),
  },
  marginBottom2: {
    marginBottom: theme.spacing(2),
  },
  marginBottom3: {
    marginBottom: theme.spacing(3),
  },
  marginBottom6: {
    marginBottom: theme.spacing(6),
  },
  marginBottom8: {
    marginBottom: theme.spacing(8),
  },
  marginBottom20: {
    marginBottom: theme.spacing(20),
  },
  alreadyHaveAnAccountText: {
    color: theme.palette.grey[500],
  },
  termsText1: {
    display: 'inline',
  },
  termsText2: {
    display: 'inline',
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  submitButton: {
    width: 400,
    height: 40,
  },
  normalInputBox: {
    width: 400,
    height: 40,
  },
  smallInputBox: {
    width: 184,
    height: 40,
  },
  loadingSpinner: {
    color: theme.palette.grey[400],
  },
  link: {
    fontWeight: 700,
    fontSize: 14,
    cursor: 'pointer',
  },
  newLink: {
    color: theme.palette.blue[500],
  },
  disabledLink: {
    color: theme.palette.grey[400],
    cursor: 'not-allowed',
  },
  reviewedTermsCheckboxErrorState: {
    color: theme.palette.secondary.main,
  },
  invitationDeliveredText: {
    fontWeight: 500,
  },
  passwordInputContainer: {
    width: 'auto',
    position: 'relative',
  },
  passwordGuideContainer: {
    width: 240,
    position: 'absolute',
    right: -260,
  },
  reviewedTermsError: {
    fontWeight: 300,
    lineHeight: 1.66,
    marginTop: -8,
  },
  emailDeliveredPng: {
    width: 372,
    height: 299,
  },
  backButton: {
    position: 'absolute',
    top: 96,
    left: 60,
    border: `1px solid ${theme.palette.greyModern[300]}`,
    width: 40,
    height: 40,
  },
}));

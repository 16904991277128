import React, { useCallback, useState, useRef, useEffect } from 'react';
import { ImageList, ImageListItem, makeStyles } from '@material-ui/core';
import useWindowEventListener from '../hooks/useWindowEventListener';
import throttle from 'lodash/throttle';
import cx from 'classnames';
import useElementEventListener from '../hooks/useElementEventListener';

//   Illustration of how VirtualGrid works:
//   ┌ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─
//      ▲                   ▲                                         │
//   │  │  window.scrollY   │  node.offsetTop
//      │                   ▼                                         │
//   │  │          ┌──────────────────────────────────────────────┐
//      │          │┌ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ┐┌ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ┐│   │
//   │  │          │                                              │
//      │          ││        Empty        ││        Empty        ││   │
//   │  │          │                                              │
//      │          │└ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ┘└ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ┘│   │
//   │  │          │┌─────────────────────┐┌─────────────────────┐│
//      │          ││                     ││                     ││   │
//   │  │          ││   Render (buffer)   ││   Render (buffer)   ││
//      │          ││                     ││                     ││   │
//   │  │          │└─────────────────────┘└─────────────────────┘│
//      │          │┌─────────────────────┐┌─────────────────────┐│   │
//   │  │          ││                     ││                     ││
//      ▼          ││       Render        ││       Render        ││   │
// ┌─┼─────────────┼┼─────────────────────┼┼─────────────────────┼┼─────┐
// │    ▲          │└─────────────────────┘└─────────────────────┘│   │ │
// │ │  │          │┌─────────────────────┐┌─────────────────────┐│     │
// │    │          ││                     ││                     ││   │ │
// │ │  │ window.  ││       Render        ││       Render        ││     │
// │    │ innerHe  ││                     ││                     ││   │ │
// │ │  │  ight    │└─────────────────────┘└─────────────────────┘│     │
// │    │          │┌─────────────────────┐┌─────────────────────┐│   │ │
// │ │  │          ││                     ││                     ││     │
// │    ▼          ││       Render        ││       Render        ││   │ │
// └─┼─────────────┼┼─────────────────────┼┼─────────────────────┼┼─────┘
//                 │└─────────────────────┘└─────────────────────┘│   │
//   │             │┌─────────────────────┐┌─────────────────────┐│
//                 ││                     ││                     ││   │
//   │             ││   Render (buffer)   ││   Render (buffer)   ││
//                 ││                     ││                     ││   │
//   │             │└─────────────────────┘└─────────────────────┘│
//                 │┌ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ┐┌ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ┐│   │
//   │             │                                              │
//                 ││        Empty        ││        Empty        ││   │
//   │             │                                              │
//                 │└ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ┘└ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ┘│   │
//   │             └──────────────────────────────────────────────┘
//                                                                    │
//   └ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─ ─

const DefaultBufferRowLength = 5;

const PADDING_IMAGE_LIST = '2px';

const screenSizeBreakPoints = [768, 1200, 1440, 1600, 1920, 2240, 2560, 2880, Infinity];
export const calcItemPerRow = (itemPerRowCap?: number, itemPerRowOffset?: number) =>
  Math.min(
    Math.max(
      // 768 -> 1, 1200 -> 2, 1440 -> 3, 1600 -> 4 ...
      screenSizeBreakPoints.findIndex(breakpoint => breakpoint > window.innerWidth) +
        1 +
        (itemPerRowOffset ?? 0),
      1,
    ),
    itemPerRowCap ?? Infinity,
  );

export interface VirtualGridProps<T extends { id: number | string }> {
  itemPerRow?: number;
  itemPerRowCap?: number;
  componentList: T[];
  itemPerRowOffset?: number;
  imageRatio: number;
  disabled?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  className?: string;
  visibleOverflow?: boolean;
  bufferRowLength?: number;
  children: (component: T, index: number, itemPerRow: number) => React.ReactNode;
}

const useStyles = makeStyles(() => ({
  tileRoot: {
    position: 'relative',
    userSelect: 'none',
    minHeight: '90px',
  },
  tile: {
    position: 'absolute',
    top: 0,
    height: 'calc(100% - 2px)',
    width: 'calc(100% - 2px)',
  },
  visibleOverflow: {
    overflow: 'visible',
  },
  fullWidth: {
    width: '100%',
  },
}));

const VirtualGridMinHeight = 116;

const VirtualGrid = <T extends { id: number | string }>({
  itemPerRowCap,
  itemPerRowOffset,
  componentList,
  imageRatio,
  children,
  disabled,
  style,
  className,
  visibleOverflow = false,
  fullWidth = false,
  bufferRowLength = DefaultBufferRowLength,
  ...restProps
}: VirtualGridProps<T>) => {
  const enableResponsive = !restProps.itemPerRow;
  const [itemPerRow, setItemPerRow] = useState(
    restProps.itemPerRow ? restProps.itemPerRow : calcItemPerRow(itemPerRowCap, itemPerRowOffset),
  );
  useWindowEventListener('resize', () => {
    enableResponsive && setItemPerRow(calcItemPerRow(itemPerRowCap, itemPerRowOffset));
  });

  useEffect(() => {
    enableResponsive && setItemPerRow(calcItemPerRow(itemPerRowCap, itemPerRowOffset));
  }, [itemPerRowOffset, itemPerRowCap, enableResponsive]);

  useEffect(() => {
    if (restProps.itemPerRow) {
      setItemPerRow(restProps.itemPerRow);
    }
  }, [restProps.itemPerRow]);

  const styles = useStyles();
  const gridRef = useRef<HTMLUListElement>();
  // we keep track of a startIndex and endIndex for all the componentList to render
  const [renderRange, setRenderRange] = useState<{
    start: number;
    end: number;
  }>({ start: 0, end: 0 });

  const scrollParentRef = useRef<HTMLDivElement | null>(null);
  const scrollParentElement = scrollParentRef.current;

  const calculateRenderRange = useCallback(() => {
    if (gridRef.current && !disabled) {
      const containerHeight = scrollParentElement
        ? scrollParentElement.offsetHeight
        : window.innerHeight;
      const containerScrollHeight = scrollParentElement
        ? scrollParentElement.scrollTop
        : window.scrollY;

      const gridToScreenTop = containerScrollHeight - gridRef.current?.offsetTop;
      const gridToScreenBottom = gridToScreenTop + containerHeight;
      // So we can only estimate the height of each media, it's not totally exact, but mostly fine
      const height = (imageRatio / itemPerRow) * gridRef.current?.clientWidth;
      const estimateMediaHeight = Math.max(height, VirtualGridMinHeight);

      setRenderRange({
        start:
          // If gridToTop / estimateMediaHeight = 4.5, we take 4th row, - 2 bufferRow = 2 row to start render
          (Math.floor(gridToScreenTop / estimateMediaHeight) - bufferRowLength) * itemPerRow,
        end:
          // If gridToBottom / estimateMediaHeight = 4.5, we take 5th row, + 2 bufferRow = 7 row to end render
          (Math.ceil(gridToScreenBottom / estimateMediaHeight) + bufferRowLength) * itemPerRow,
      });
    }
  }, [disabled, scrollParentElement, imageRatio, itemPerRow, bufferRowLength]);

  const gridRefCallback = useCallback(
    (node: HTMLUListElement | null) => {
      if (node && !disabled) {
        gridRef.current = node;
        // The search stops when get to the parent of <html> tag, which is null
        for (let parent = node.parentElement; parent; parent = parent.parentElement) {
          const { overflowX, overflowY } = window.getComputedStyle(parent);
          if (
            overflowX === 'auto' ||
            overflowX === 'scroll' ||
            overflowY === 'auto' ||
            overflowY === 'scroll'
          ) {
            scrollParentRef.current = parent as HTMLDivElement;
            break;
          }
        }
      }
      calculateRenderRange();
    },
    [calculateRenderRange, disabled],
  );

  useElementEventListener(
    scrollParentElement ? scrollParentElement : undefined,
    'scroll',
    (disabled ? disabled : !scrollParentElement)
      ? () => {}
      : // throttle scroll event so it doesn't trigger too often
        throttle(
          () => {
            calculateRenderRange();
          },
          200,
          { leading: false },
        ),
  );

  useWindowEventListener(
    'scroll',
    (disabled ? disabled : scrollParentElement)
      ? () => {}
      : // throttle scroll event so it doesn't trigger too often
        throttle(
          () => {
            calculateRenderRange();
          },
          200,
          { leading: false },
        ),
  );
  return (
    <ImageList
      rowHeight="auto"
      cols={itemPerRow}
      ref={gridRefCallback}
      data-testid="virtual-grid"
      style={style}
      className={cx(className, fullWidth && styles.fullWidth)}
    >
      {componentList.map((component, index) => (
        <ImageListItem
          classes={{
            root: styles.tileRoot,
            item: cx(styles.tile, visibleOverflow && styles.visibleOverflow),
          }}
          key={component.id}
          style={{
            paddingTop: `calc(${(imageRatio / itemPerRow) * 100}% - ${PADDING_IMAGE_LIST})`,
          }}
          data-testid="virtual-grid-tile"
        >
          {/* Only render Media within renderRange, but keep ImageListItem for the extra top/bottom spacing */}
          {((index >= renderRange.start && index < renderRange.end) || disabled) &&
            children(component, index, itemPerRow)}
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default VirtualGrid;

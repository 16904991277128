import { ApiResponseLoader, VirtualGrid } from '@clef/client-library';
import { Box, Grid, Typography } from '@material-ui/core';
import { Button } from '@clef/client-library';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Project, UserId, User } from '@clef/shared/types';

import ProjectCard from '../../components/ProjectCard/NewProjectCard';
import { CreateProjectCard } from '../../components/ProjectCard/CreateProjectCard';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import CLEF_PATH from '../../constants/path';
import { useStyles } from './styles';
import { WorkflowTutorialBox } from '../projects/components/WorkflowTutorialBox';
import { IntroSection } from './components/IntroSection';
import { isProjectOwner } from '@clef/shared/utils';

import { useGetRecentProjectsQuery, useSetSelectedProjectId } from '@/serverStore/projects';

const PROJECT_CARD_MARGIN = 28;

const Home: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const currentUser = useTypedSelector(state => state.login.user!);
  const users = useTypedSelector(state => state.user.users);
  const setSelectedProjectId = useSetSelectedProjectId();

  const userById = useMemo(() => {
    return users.reduce((accum: { [key: UserId]: User }, val) => {
      accum[val.id] = val;
      return accum;
    }, {});
  }, [users]);

  const {
    data: projectListData,
    isLoading: projectListLoading,
    error: projectListError,
  } = useGetRecentProjectsQuery(currentUser.id, 3);

  const onProjectCardClick = useCallback(
    async (projectId: number) => {
      await setSelectedProjectId(projectId);
      history.push(`${CLEF_PATH.root}/${currentUser.orgId}/pr/${projectId}`);
    },
    [history],
  );

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={styles.introSectionContainer}
      >
        <Typography variant="h1" className={styles.sectionTitle}>
          {t('Welcome, {{userName}}', { userName: currentUser.name })}
        </Typography>

        <IntroSection />
      </Grid>

      <Grid container direction="column" className={styles.projectsSectionContainer}>
        <Grid container>
          <Typography variant="h1" className={styles.sectionTitle}>
            {t('Projects')}
          </Typography>

          <Box flex={1} />

          <Button
            id="view-all-projects"
            color="primary"
            onClick={() => history.push(CLEF_PATH.projects)}
          >
            {t('View All')}
          </Button>
        </Grid>

        <ApiResponseLoader
          response={projectListData}
          loading={projectListLoading}
          error={projectListError ?? undefined}
          defaultHeight="30vh"
        >
          {recentProjects => {
            return recentProjects.length ? (
              <VirtualGrid
                itemPerRowCap={4}
                imageRatio={2 / 3}
                componentList={[{ id: 'create-project-card' }, ...recentProjects]}
                fullWidth
              >
                {component => {
                  if (component.id === 'create-project-card') {
                    return (
                      <CreateProjectCard
                        style={{
                          width: `calc(100% - ${PROJECT_CARD_MARGIN}px)`,
                          height: '100%',
                          aspectRatio: '1.4',
                        }}
                      />
                    );
                  }
                  const project = component as Project;
                  const projectOwner = project.usersRoles?.find(user => isProjectOwner(user.role));
                  return (
                    <ProjectCard
                      key={project.id}
                      project={project}
                      ownerUser={userById[projectOwner?.userId ?? '']}
                      isCurrentUserOwner={currentUser.id === projectOwner?.userId}
                      style={{
                        width: `calc(100% - ${PROJECT_CARD_MARGIN}px)`,
                        height: '100%',
                        aspectRatio: '3 / 2',
                      }}
                      onClick={onProjectCardClick}
                    />
                  );
                }}
              </VirtualGrid>
            ) : (
              <WorkflowTutorialBox startButtonPlacement="right" />
            );
          }}
        </ApiResponseLoader>
      </Grid>
    </>
  );
};

export default Home;

import React, { useEffect, useMemo } from 'react';
import { Box, ImageListItem, Typography } from '@material-ui/core';
import { BoxAnnotation, Img, MediaViewer, SegmentationAnnotation } from '@clef/client-library';
import { LivePrediction } from '../hooks/useLivePredictions';
import { Skeleton } from '@material-ui/lab';
import preNetworkLoadingIcon from '../Icons/PreNetworkLoadingIcon.gif';
import useStyles from './styles';
import ReadOnlyMediaClassifiedClass from '@/pages/DataBrowser/MediaContainer/ReadOnlyMediaClassifiedClass';
import { LabelType, SegmentationInstantLearningPrediction } from '@clef/shared/types';
import { useDataBrowserState } from '@/pages/DataBrowser/dataBrowserState';

interface FeaturedMediaViewerProps {
  labelType?: LabelType | null;
  prediction: LivePrediction;
  annotations: {
    boxAnnotations: BoxAnnotation[] | undefined;
    segmentationAnnotations: SegmentationAnnotation[] | undefined;
  };
  openReachLimitModelDialog: () => void;
}

const FeaturedMediaViewer: React.FC<FeaturedMediaViewerProps> = ({
  labelType,
  prediction,
  annotations: { boxAnnotations, segmentationAnnotations },
  openReachLimitModelDialog,
}) => {
  const styles = useStyles();
  const { state } = useDataBrowserState();
  useEffect(() => {
    if (prediction.error?.startsWith('QuotaExceeded')) {
      openReachLimitModelDialog();
    }
  }, [prediction.error]);

  const renderLoadingComponent = () => {
    return (
      <Skeleton
        height={'100%'}
        width={'100%'}
        variant="rect"
        classes={{ withChildren: styles.loadingWithChildren }}
      >
        <Box display="flex" flexDirection="column">
          <Img src={preNetworkLoadingIcon} className={styles.loadingImg} />
          <Typography>{t('Running your prediction...')}</Typography>
        </Box>
      </Skeleton>
    );
  };

  const instantLearningPredictedClass = useMemo(() => {
    if (labelType === LabelType.SegmentationInstantLearning) {
      return (
        (prediction.result as SegmentationInstantLearningPrediction)?.postProcLabel?.classString ??
        null
      );
    }

    return null;
  }, [labelType, prediction.result]);

  return (
    <ImageListItem
      component="div"
      classes={{ root: styles.featuredItem }}
      data-testid="featured-image"
    >
      {prediction?.error ? (
        <Box className={styles.featuredItemError}>
          <Typography variant="h4" component="div" gutterBottom className={styles.errorMessage}>
            {prediction.error}
            <div>Please contact the administrator</div>
          </Typography>
        </Box>
      ) : (
        <MediaViewer
          imgSrc={prediction?.loading ? undefined : prediction.imgSrc}
          boxAnnotations={boxAnnotations}
          segmentationAnnotations={segmentationAnnotations}
          enableZoom
          classes={{ root: styles.mediaViewerFeaturedRoot }}
          loadingComponent={renderLoadingComponent()}
          hideDefectName
          leftCornerComponent={
            labelType === LabelType.SegmentationInstantLearning &&
            state.TEMP_showImageLevelClassification &&
            instantLearningPredictedClass ? (
              <ReadOnlyMediaClassifiedClass name={instantLearningPredictedClass} dotted />
            ) : undefined
          }
        />
      )}
    </ImageListItem>
  );
};

export default FeaturedMediaViewer;

import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { makeStyles } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';

const useStyles = makeStyles(theme => ({
  btn: {
    fontSize: theme.typography.body1.fontSize,
    padding: theme.spacing(1),
    margin: theme.spacing(-1),
  },
}));

interface Props {
  ['data-testid']?: string;
  tooltip?: string;
  text: string;
}

const CopyToClipboardButton = (props: Props) => {
  const styles = useStyles();

  const copyTextToClipboard = useCopyToClipboard(props);

  const label = props.tooltip || t('Copy to clipboard');

  return (
    <Tooltip title={label} placement="top" arrow>
      <IconButton
        id={props['data-testid'] ?? 'copy-to-clipboard-button'}
        data-testid={props['data-testid']}
        aria-label={label}
        className={styles.btn}
        onClick={copyTextToClipboard}
        size="small"
      >
        <FileCopyOutlinedIcon color="disabled" fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

export default CopyToClipboardButton;

import { getTraceId } from '@clef/shared/utils';
import { ApiResponse } from '@clef/shared/types/api';
import { EventLogger } from '@clef/client-library';
import { ApiError, UnauthorizedError } from '../utils/error';
import { extractIdFromUrl } from '../utils/url_utils';
import { isTestEnv } from '@clef/client-library/src/utils/env';

export interface ApiErrorType {
  message: string;
  status?: number;
  statusText?: string;
  body?: { message: string; code: number };
  url?: string;
}

function rejectResponse(response: Response, body: ApiErrorType['body']): ApiErrorType {
  return {
    status: response.status,
    message: body?.message || 'Something went wrong with your request.',
    statusText: response.statusText,
    body,
    url: response.url,
  };
}

function checkStatus(response: Response, isArrayBuffer: boolean = false) {
  if (response.status >= 200 && response.status < 300) {
    return isArrayBuffer
      ? response.arrayBuffer()
      : response.text().then(text => {
          return text ? JSON.parse(text) : undefined;
        });
  }

  return response.json().then(
    json => {
      return Promise.reject(rejectResponse(response, json));
    },
    err => {
      return Promise.reject(rejectResponse(response, err.body || {}));
    },
  );
}

/**
 * https://dev.to/bil/using-abortcontroller-with-react-hooks-and-typescript-to-cancel-window-fetch-requests-1md4
 * https://developer.mozilla.org/en-US/docs/Web/API/AbortController
 */
const abortController = new AbortController();

export const BASE_PREFIX = '/api';

export enum HttpMethod {
  Post = 'post',
  Get = 'get',
  Delete = 'delete',
  Put = 'put',
  Patch = 'PATCH',
}

export class BaseAPI {
  basePrefix: string;

  prefix: string;

  constructor(prefix: string, basePrefix: string = BASE_PREFIX) {
    this.basePrefix = basePrefix;
    this.prefix = prefix;
  }

  async fetch(
    method: HttpMethod,
    url: string,
    body?: RequestInit['body'],
    options?: RequestInit,
    isArrayBuffer: boolean = false,
  ) {
    const fetchUrl = [
      this.basePrefix ? `${this.basePrefix}/` : '',
      this.prefix ? `${this.prefix}/` : '',
      url,
    ].join('');
    const urlObject = new URL(fetchUrl, window.origin);
    const urlWithoutSearch = urlObject.origin + urlObject.pathname;
    const traceId = getTraceId();
    const orgId = extractIdFromUrl()?.orgId;
    return fetch(urlObject.toString(), {
      signal: abortController.signal,
      method: method,
      body: body,
      credentials: 'include',
      ...options,
      headers: { ...options?.headers, traceId, ...(orgId ? { orgId: orgId.toString() } : {}) },
    })
      .then((res: Response) => checkStatus(res, isArrayBuffer))
      .catch((e: TypeError | ApiErrorType) => {
        if (isTestEnv()) {
          // eslint-disable-next-line no-console
          console.warn(`Api failure in test, likely api not mocked: ${urlWithoutSearch}`);
        }
        // @ts-ignore error could be AbortError
        if (e?.name === 'AbortError') {
          // do nothing here, AbortError is triggered when user's session just expired
          return;
        }
        // e could be TypeError, for example: CORS issue
        if (e instanceof TypeError) {
          EventLogger.error(
            new ApiError({ api: urlWithoutSearch, typeError: e, method }),
            {
              FetchInfo: {
                search: urlObject.search,
                url: urlWithoutSearch,
              },
              ErrorInfo: e,
            },
            ['ApiTypeError'],
          );
          throw e;
        }

        if (e?.status === 401) {
          // TODO: Device page make /train/job calls that are determined to fail, we should fix this soon
          if (urlObject.toString().includes('/train/job')) {
            return;
          }
          localStorage.removeItem('clef_account_is_login');
          localStorage.removeItem('clef_is_login');

          if (
            !window.location.pathname.includes('/login') &&
            !window.location.pathname.includes('/legacy_login')
          ) {
            abortController.abort();
            // session expired, use is logged out
            const urlSearch = new URLSearchParams();
            urlSearch.set('redirect', window.location.pathname + window.location.search);
            window.location.replace(`/?${urlSearch.toString()}`);
            EventLogger.error(
              new UnauthorizedError('Got 401, need to login. Original message: ' + e.message),
              {
                FetchInfo: {
                  search: urlObject.search,
                  url: urlWithoutSearch,
                  body: String(body),
                },
                ErrorInfo: e,
              },
              ['UnauthorizedError'],
            );
            return null;
          } else {
            // login failure, throw the error to be handled outside
            throw e;
          }
        } else {
          // Indicate this is not a RegularOperationError
          // packages/server-shared/error/index.ts:44
          if (e.body?.code !== -2) {
            EventLogger.error(
              new ApiError({ api: urlWithoutSearch, code: e.status, method, message: e.message }),
              {
                FetchInfo: {
                  search: urlObject.search,
                  url: urlWithoutSearch,
                  body: String(body),
                },
                ErrorInfo: e,
              },
              [method, urlWithoutSearch, String(e.status), e.message],
            );
          }
          // Still throw the original error
          throw e;
        }
      });
  }

  /**
   * Send HTTP GET request to url
   * @param {string} url The url that GET request will send to.
   * @param {object} params The query params of the query.
   * @param {boolean} onlyData When set to true, we will get the data field
   * @param {object} options extra options to fetch, such as credential
   * @return {object} The response of the HTTP call.
   */
  async get<Response = any>(
    url: string,
    params?: Record<string, any>,
    onlyData = false,
    options?: RequestInit,
    isArrayBuffer: boolean = false,
  ): Promise<Response> {
    if (params) {
      if (!url.endsWith('?')) {
        url += '?';
      }
      url += Object.keys(params)
        .filter(key => typeof params[key] !== 'undefined')
        .map(key => {
          return [key, params[key]].map(encodeURIComponent).join('=');
        })
        .join('&');
    }
    const res = await this.fetch(HttpMethod.Get, url, undefined, options, isArrayBuffer);

    return onlyData ? res?.data : res;
  }

  async post(url: string, body?: RequestInit['body'], options?: object) {
    return this.fetch(HttpMethod.Post, url, body, options);
  }

  async put(url: string, body: RequestInit['body'], options?: object) {
    return this.fetch(HttpMethod.Put, url, body, options);
  }

  async postJSON<Response = any>(
    url: string,
    body: object,
    options?: object,
  ): Promise<ApiResponse<Response>> {
    return this.post(url, JSON.stringify(body), {
      ...options,
      headers: { 'Content-Type': 'application/json' },
    });
  }

  async putJSON<Response = any>(
    url: string,
    body: object,
    options?: object,
  ): Promise<ApiResponse<Response>> {
    return this.put(url, JSON.stringify(body), {
      ...options,
      headers: { 'Content-Type': 'application/json' },
    });
  }

  async postURLParams(url: string, params: object) {
    const form = new URLSearchParams();
    for (const [key, value] of Object.entries(params)) {
      form.append(key, value);
    }
    return this.post(url, form, {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    });
  }

  async postForm(url: string, params: object) {
    const form = new FormData();
    for (const [key, value] of Object.entries(params)) {
      form.append(key, value);
    }
    return this.post(url, form);
  }

  async postFormURL(url: string, params: object, options?: object) {
    const form = new URLSearchParams();
    for (const [key, value] of Object.entries(params)) {
      form.append(key, value);
    }
    return this.post(url, form.toString(), options);
  }

  async putForm(url: string, params: object) {
    const form = new FormData();
    for (const [key, value] of Object.entries(params)) {
      form.append(key, value);
    }
    return this.put(url, form);
  }

  async delete(url: string, body?: object, options?: object) {
    return this.fetch(HttpMethod.Delete, url, JSON.stringify(body), {
      ...options,
      headers: { 'Content-Type': 'application/json' },
    });
  }

  async patch(url: string, body?: object, options?: object) {
    return this.fetch(HttpMethod.Patch, url, JSON.stringify(body), {
      ...options,
      headers: { 'Content-Type': 'application/json' },
    });
  }
}

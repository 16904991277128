import React, { useMemo, useState } from 'react';
import {
  makeStyles,
  Tooltip,
  TooltipProps,
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  TypographyVariant,
} from '@material-ui/core';
import { EnhancedTypographyVariant, useTypographyStyles } from '../../themes/typography';
import classNames from 'classnames';

const useLocalStyles = makeStyles(() => ({
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const MuiTypographyVariantList = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'subtitle2',
  'body1',
  'body2',
  'caption',
  'button',
  'overline',
] as string[];

export interface TypographyProps extends Omit<MuiTypographyProps, 'variant'> {
  variant?: TypographyVariant | EnhancedTypographyVariant;
  width?: string | number;
  maxWidth?: string | number;
  fullTextTooltipPlacement?: TooltipProps['placement'];
}

const Typography: React.FC<TypographyProps> = props => {
  const {
    variant,
    className,
    style,
    children,
    width,
    maxWidth,
    fullTextTooltipPlacement = 'top',
    ...otherProps
  } = props;
  const typographyStyles = useTypographyStyles();
  const localStyles = useLocalStyles();
  const [isTruncated, setIsTruncated] = useState(false);

  const component = useMemo(() => {
    return (
      <MuiTypography
        ref={ref => {
          if ((width || maxWidth) && ref) {
            setIsTruncated(ref.offsetWidth < ref.scrollWidth);
          }
        }}
        {...otherProps}
        variant={
          variant && MuiTypographyVariantList.includes(variant)
            ? (variant as TypographyVariant)
            : undefined
        }
        className={classNames(
          typographyStyles[variant as string],
          // If either width or max width is specified via props, we consider to enable text truncation
          (width || maxWidth) && localStyles.truncate,
          className,
        )}
        style={{
          ...style,
          ...(width && { width }),
          ...(maxWidth && { maxWidth }),
        }}
      >
        {children}
      </MuiTypography>
    );
  }, [
    children,
    className,
    localStyles.truncate,
    maxWidth,
    otherProps,
    style,
    typographyStyles,
    variant,
    width,
  ]);

  if (isTruncated && children) {
    return (
      <Tooltip title={children} arrow placement={fullTextTooltipPlacement}>
        {component}
      </Tooltip>
    );
  }

  return component;
};

export default Typography;

import React from 'react';

export type HandProps = {};

const HandIcon: React.FC<HandProps> = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.04992 13.3515V16.5923M8.04992 13.3515V5.57375C8.04992 4.49986 8.93789 3.6293 10.0333 3.6293C11.1286 3.6293 12.0166 4.49986 12.0166 5.57375M8.04992 13.3515C8.04992 12.2776 7.16195 11.4071 6.06659 11.4071C4.97122 11.4071 4.08325 12.2776 4.08325 13.3515V15.9441C4.08325 21.3136 8.52309 25.6663 13.9999 25.6663C19.4767 25.6663 23.9166 21.3136 23.9166 15.9441V9.46264C23.9166 8.38875 23.0286 7.51819 21.9333 7.51819C20.8379 7.51819 19.9499 8.38875 19.9499 9.46264M12.0166 5.57375V12.7034M12.0166 5.57375V4.27745C12.0166 3.20357 12.9046 2.33301 13.9999 2.33301C15.0953 2.33301 15.9833 3.20357 15.9833 4.27745V5.57375M15.9833 5.57375V12.7034M15.9833 5.57375C15.9833 4.49986 16.8712 3.6293 17.9666 3.6293C19.0619 3.6293 19.9499 4.49986 19.9499 5.57375V9.46264M19.9499 9.46264V12.7034"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HandIcon;

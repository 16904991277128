import { makeStyles } from '@material-ui/core';

export const useLabelingTaskStyles = makeStyles(theme => ({
  // page
  mainContent: {
    width: '100vw',
    height: 'calc(100vh - 88px)',
    overflow: 'hidden',
    marginTop: 88,
    display: 'flex',
  },
  // header
  appBar: {
    width: '100vw',
    backgroundColor: '#fff',
    padding: theme.spacing(5),
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: theme.boxShadow.default,
  },
  appBarTaskProgress: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(3),
    whiteSpace: 'nowrap',
  },
  postRequestMask: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: theme.zIndex.drawer + 10,
    background: 'rgba(255,255,255,0.7)',
  },
  actionButton: {
    marginLeft: theme.spacing(2),
  },
  autoSaveCaption: {
    marginLeft: theme.spacing(2),
    fontStyle: 'italic',
  },
  // panel shared
  panelContainer: {
    height: '100%',
    overflow: 'auto',
  },
  mediaInfoPanelContainer: {
    width: 240,
    backgroundColor: 'white',
  },
  leftPanelContainer: {
    boxShadow: theme.boxShadow.default,
    width: 280,
    backgroundColor: 'white',
    zIndex: 1,
  },
  mainPanelContainer: {
    flex: 1,
    backgroundColor: theme.palette.background.lightBlue,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  toolImageEnhance: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(5),
    zIndex: 1,
  },
  footer: {
    padding: theme.spacing(0, 5, 5, 5),
  },
  panelSection: {
    padding: theme.spacing(5),
    position: 'relative',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    overflow: 'auto',
  },
  panelSectionH4: {
    marginBottom: theme.spacing(4),
  },
  panelSectionImportant: {
    paddingTop: theme.spacing(10),
  },
  panelSectionSticky: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: theme.zIndex.appBar - 10,
  },
  // media list section
  mediaListHeader: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  mediaPreview: {
    cursor: 'pointer',
    height: 100,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(3),
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    '& .MuiChip-root': {
      cursor: 'pointer',
    },
  },
  mediaPreviewSelected: {
    backgroundColor: `${theme.palette.primary.main}1A`,
    height: 'auto',
  },
  mediaPreviewImg: {
    height: '100%',
    width: 80,
    borderRadius: 8,
    objectFit: 'contain',
    display: 'block',
    backgroundColor: '#e1e4e9',
  },
  strokeWidthSlider: {
    width: 120,
    margin: theme.spacing(0, 4),
  },
  // main section - canvas
  canvasContainer: {
    overflow: 'hidden',
    flexGrow: 1,
    backgroundColor: theme.palette.grey[200],
    position: 'relative',
    marginBottom: theme.spacing(5),
  },
  defectMenuContainer: {
    zIndex: theme.zIndex.modal + 10,
    // transform: 'translateY(48px)',
    '& .MuiListItem-root.Mui-selected': {
      backgroundColor: `${theme.palette.primary.main}1A`,
    },
    '& .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: `${theme.palette.primary.main}2B`,
    },
    '& .MuiListItem-root:hover': {
      backgroundColor: `${theme.palette.grey[500]}1A`,
    },
    '& .MuiListItem-root.Mui-selected.Mui-focusVisible': {
      backgroundColor: `${theme.palette.primary.main}1A`,
    },
    '& .MuiListItem-root.Mui-focusVisible': {
      backgroundColor: `${theme.palette.grey[500]}1A`,
    },
  },
  hintTextNormal: {
    color: theme.palette.grey[500],
  },
  // tutorial
  tutorialPopper: {
    zIndex: theme.zIndex.tooltip,
  },
  tutorialContainer: {
    backgroundColor: theme.palette.info.main,
    padding: theme.spacing(3, 5, 5, 5),
    width: 400,
  },
  tutorialClose: {
    float: 'right',
    marginBottom: theme.spacing(2),
  },
  tutorialGif: {
    width: '100%',
    boxShadow: theme.boxShadow.strong,
  },
  tutorialTextContainer: {
    margin: theme.spacing(5, 0),
  },
  tutorialMask: {
    top: 0,
    left: 0,
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: theme.zIndex.drawer + 10,
  },
  tutorialText: {
    color: 'white',
    '& $codeBlock': {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[600],
    },
  },
  tutorialButton: {
    color: 'white',
    borderColor: 'white',
  },
  tutorialContainerArrow: {
    position: 'absolute',
    fontSize: 7,
    top: 0,
    left: 0,
    marginTop: '-0.9em',
    width: '3em',
    height: '1em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '0 1em 1em 1em',
      borderColor: `transparent transparent ${theme.palette.info.main} transparent`,
    },
  },
  // universal
  marginBottom2: {
    marginBottom: theme.spacing(2),
  },
  marginBottom5: {
    marginBottom: theme.spacing(5),
  },
  marginRight5: {
    marginRight: theme.spacing(5),
  },
  flexGrow: {
    flexGrow: 1,
  },
  codeBlock: {
    backgroundColor: theme.palette.grey[500],
    borderRadius: 4,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: theme.spacing(1),
    fontWeight: 700,
  },
  expandButton: {
    marginRight: theme.spacing(4),
    padding: theme.spacing(0, 4),
    fontWeight: 500,
  },
  defectColor: {
    width: 20,
    height: 20,
    borderRadius: 6,
    marginRight: theme.spacing(3),
  },
  checkIcon: {
    color: theme.palette.success.main,
    width: 20,
    height: 20,
  },
  errorIcon: {
    color: theme.palette.error.main,
    width: 20,
    height: 20,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  annotationInstanceText: {
    ...theme.typography.body2,
    color: theme.palette.grey[500],
    lineHeight: '25px',
  },
  annotationInstanceIconContainer: {
    display: 'none',
  },
  previewDefectName: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  previewDefectNameText: {
    ...theme.typography.body2,
    lineHeight: '25px',
  },
  defectSelector: {
    position: 'relative',
  },
  defectSelectorButton: {
    minWidth: 200,
    height: 40,
    justifyContent: 'center',
  },
  defectSelectorMenu: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    maxHeight: 'calc(100vh - 120px)',
    overflowY: 'auto',
  },
  defectSelectorHeader: {
    padding: theme.spacing(0, 2, 0, 4),
  },
  metadataPanel: {
    position: 'relative',
  },
  addMetadataButton: {
    cursor: 'pointer',
    lineHeight: 1,
  },
  metadataPopover: {
    position: 'absolute',
    bottom: 32,
    left: 0,
    minWidth: 260,
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    maxWidth: 500,
  },
  metadataMenuLine: {
    borderTopColor: theme.palette.grey[300],
    borderTopWidth: 1,
    borderTopStyle: 'solid',
  },
}));

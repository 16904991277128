import React from 'react';
import { makeStyles, ButtonProps } from '@material-ui/core';
import ToggleOn from '@material-ui/icons/ToggleOn';
import ToggleOff from '@material-ui/icons/ToggleOff';

import Button from '../Button';

type ToggleIconProps = {
  disabled?: boolean;
  children?: JSX.Element | string;
};

const useStyles = (props: ToggleIconProps) =>
  makeStyles(theme => {
    const { disabled = false, children } = props;
    return {
      toggleBtn: {
        cursor: 'pointer',
      },
      toggleOn: {
        fontSize: '28px !important',
        color: disabled ? theme.palette.primary.light : theme.palette.primary.main,
        marginRight: children ? theme.spacing(2) : 0,
      },
      toggleOff: {
        fontSize: '28px !important',
        color: theme.palette.greyModern[500],
        marginRight: children ? theme.spacing(2) : 0,
      },
    };
  });

export type ToggleButtonProps = {
  children?: JSX.Element | string;
  id: string;
  isOn?: boolean;
  onToggle?(): void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
} & ButtonProps;

export const ToggleButton: React.FC<ToggleButtonProps> = ({
  id,
  children,
  isOn,
  onToggle,
  disabled = false,
  onKeyPress,
  ...otherProps
}) => {
  const styles = useStyles({ disabled, children })();

  return (
    <Button
      id={id}
      onClick={onToggle}
      data-toggle-status={isOn ? 'on' : 'off'}
      disabled={disabled}
      className={styles.toggleBtn}
      onKeyPress={onKeyPress}
      {...otherProps}
    >
      {isOn ? <ToggleOn className={styles.toggleOn} /> : <ToggleOff className={styles.toggleOff} />}
      {children}
    </Button>
  );
};

export default ToggleButton;

export const convertToShortNumberFormat = (num: number | undefined): string => {
  if (num == undefined) {
    return '';
  }

  if (num >= 2000000) {
    return (num / 1000000).toFixed(2) + 'M';
  } else if (num >= 1000) {
    return Math.floor(num / 1000) + 'K';
  } else {
    return num.toString();
  }
};

import reducers from './reducers';
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

const store = configureStore({
  reducer: reducers ?? {},
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // because we need to store file data in redux :(
      serializableCheck: false,
      // TODO: We should turn on below flag asap!
      immutableCheck: false,
    }),
});

export type ReduxState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

if (module.hot) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const newReducers = require('./reducers').default;
  module.hot.accept('./reducers', () => store?.replaceReducer(newReducers));
}

export default store;

import React, { useCallback, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Button, useKeyPress } from '@clef/client-library';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import classNames from 'classnames';
import {
  useGoToNextMedia,
  useGoToPreviousMedia,
  useImageLabelingContext,
  useMediaList,
} from '../../components/Labeling/imageLabelingContext';
import { useInstantLearningState } from './state';
import IteratingGit from '../../images/instant_learning/Iterating.gif';

const useStyles = makeStyles(theme => ({
  root: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 4),
  },
  iconButton: {
    minWidth: 32,
    height: 32,
    padding: theme.spacing(0),
    margin: theme.spacing(0, 2),
  },
  verticalLine: {
    height: 32,
    margin: theme.spacing(0, 2.5),
  },
  navigationButton: {
    backgroundColor: theme.palette.greyBlue[100],
    color: theme.palette.greyBlue[800],
    width: 32,
    height: 32,
    borderRadius: 10,
  },
  navigationText: {
    fontSize: 14,
    fontWeight: 500,
    minWidth: 60,
    padding: theme.spacing(0, 2.5),
    textAlign: 'center',
  },
  trainDialog: {
    position: 'absolute',
    top: 65,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 440,
    minHeight: 279,
    zIndex: 1001,
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    backgroundRepeat: 'no-repeat',
    paddingTop: 29,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 6, 5),
    boxShadow: '0px 1px 2px rgba(48, 55, 79, 0.16), 0px 2px 4px 2px rgba(48, 55, 79, 0.08)',
  },
  mask: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.25)',
    zIndex: 1000,
  },
  trainDesc: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  trainContent: {
    width: 500,
    height: 159,
    backgroundColor: theme.palette.pink[200],
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.common.white,
    lineHeight: '159px',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  startIterating: {
    marginTop: theme.spacing(3),
  },
  icon: {
    marginBottom: theme.spacing(4),
  },
  iteratingImgBox: {
    marginTop: theme.spacing(4),
  },
  iteratingImg: {
    width: '100%',
  },
}));

export type TopToolBarProps = {
  onSwtichImage?: () => void;
};

const TopCenterTools: React.FC<TopToolBarProps> = ({ onSwtichImage }) => {
  const styles = useStyles();

  const {
    state: { mediaIndex },
  } = useImageLabelingContext();
  const mediaCount = useMediaList().length;

  const goToNextImageCallback = useGoToNextMedia();
  const goToPrevImageCallback = useGoToPreviousMedia();
  const [openNextTooltip, setOpenNextTooltip] = useState(false);

  const goToNextImage = useCallback(() => {
    goToNextImageCallback();
    onSwtichImage?.();
  }, [goToNextImageCallback, onSwtichImage]);

  const goToPrevImage = useCallback(() => {
    goToPrevImageCallback();
    onSwtichImage?.();
  }, [goToPrevImageCallback, onSwtichImage]);

  useKeyPress('left', goToPrevImage);
  useKeyPress('right', goToNextImage);

  const {
    state: { openIterating },
    dispatch,
  } = useInstantLearningState();

  return (
    <Box position="relative" className={styles.root}>
      {/* view / label mode */}
      <Button
        id="instant-learning-prev-image"
        color="default"
        onClick={goToPrevImage}
        className={classNames(styles.iconButton, styles.navigationButton)}
        disabled={mediaIndex === 0}
        size="large"
        tooltip={t('Previous image')}
      >
        <KeyboardArrowLeft fontSize="large" />
      </Button>
      <Box className={styles.navigationText}>
        {mediaIndex + 1}/{mediaCount}
      </Box>
      <Button
        id="instant-learning-next-image"
        color="default"
        onClick={goToNextImage}
        className={classNames(styles.iconButton, styles.navigationButton)}
        disabled={mediaIndex === mediaCount - 1}
        size="large"
        tooltip={t('Next image')}
        tooltipProps={{
          open: openNextTooltip,
        }}
      >
        <KeyboardArrowRight
          onMouseEnter={() => setOpenNextTooltip(true)}
          onMouseLeave={() => setOpenNextTooltip(false)}
          fontSize="large"
        />
      </Button>
      {openIterating && (
        <>
          <Box className={styles.mask}></Box>
          <Box className={styles.trainDialog}>
            <Typography variant="h2">{t('Iterate to get more precise results!')}</Typography>
            <Typography className={styles.trainDesc}>
              {t('Are there any predictions that are not quite right yet?')}
            </Typography>
            <Typography>{t('Fine-tune your labeling and run again!')}</Typography>
            <Box className={styles.iteratingImgBox}>
              <img className={styles.iteratingImg} src={IteratingGit} alt="" />
            </Box>

            <Button
              className={styles.startIterating}
              id="start-iterating-btn"
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(draft => {
                  draft.openIterating = false;
                });
              }}
            >
              {t('Start Iterating')}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default TopCenterTools;

import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Defect,
  Project,
  ProjectId,
  ProjectWithUsers,
  ProjectStatsById,
  OrgId,
  LabelType,
  ActiveProject,
  UserId,
} from '@clef/shared/types';
import ProjectAPI from '@/api/project_api';
import { ApiErrorType } from '@/api/base_api';
import DefectAPI from '@/api/defect_api';
import { getRecentProjects } from '@/utils/project_utils';
import { useTypedSelector } from '@/hooks/useTypedSelector';
import { statsCardDataKeyCompareFn } from '@/utils';

export const projectQueryKeys = {
  all: ['projects'] as const,
  list: (orgId: OrgId) => [orgId, ...projectQueryKeys.all, 'list'] as const,
  activeProjectList: (userId: UserId, orgId: OrgId) =>
    [userId, orgId, ...projectQueryKeys.all, 'activeProjectList'] as const,
  detail: (projectId: ProjectId) => [...projectQueryKeys.all, 'detail', projectId] as const,
  stats: (orgId: OrgId) => [orgId, ...projectQueryKeys.all, 'stats'] as const,
  splits: (projectId: ProjectId) => [...projectQueryKeys.all, projectId, 'splits'] as const,
  defects: (projectId: ProjectId, versionedDatasetContentId?: number) =>
    [
      ...projectQueryKeys.all,
      projectId,
      'defects',
      { projectId, ...(versionedDatasetContentId && { versionedDatasetContentId }) },
    ] as const,
  versionedDefects: (projectId: ProjectId, versionedDatasetContentId: number) =>
    [...projectQueryKeys.all, projectId, 'versionedDefects', versionedDatasetContentId] as const,
};

export const useGetProjectsQuery = (select?: (data: Project[]) => Project[]) => {
  const orgId = useTypedSelector(state => state.login.user?.orgId);
  return useQuery<Project[], ApiErrorType>({
    queryKey: projectQueryKeys.list(orgId ?? -1),
    queryFn: async () => {
      const res = await ProjectAPI.getProjectList();
      return res.data;
    },
    select,
    enabled: !!orgId,
  });
};

export const useGetActiveProjectsQuery = () => {
  const orgId = useTypedSelector(state => state.login.user?.orgId)!;
  const userId = useTypedSelector(state => state.login.user?.id)!;
  return useQuery<ActiveProject[], ApiErrorType>({
    queryKey: projectQueryKeys.activeProjectList(userId, orgId),
    queryFn: async () => {
      const res = await ProjectAPI.getActiveProjectList();
      return res.data.projects;
    },
    enabled: !!orgId,
  });
};

export const useGetRecentProjectsQuery = (currentUserId: string, projectsNum?: number) =>
  useGetProjectsQuery(
    useCallback(
      projects => getRecentProjects(projects, currentUserId, projectsNum),
      [currentUserId, projectsNum],
    ),
  );

export const useGetUserProjectQuery = (projectId: ProjectId) => {
  return useQuery<ProjectWithUsers, ApiErrorType>({
    queryKey: projectQueryKeys.detail(projectId),
    queryFn: async () => {
      const res = await ProjectAPI.getProjectWithUsers(projectId, true);
      return res.data;
    },
    enabled: !!projectId,
  });
};

export const useGetSelectedProjectQuery = () => {
  const projectId = useTypedSelector(state => state.project.selectedProjectId);
  return useGetUserProjectQuery(projectId ?? 0);
};

export const useGetProjectStatsByOrgIdQuery = () => {
  const orgId = useTypedSelector(state => state.login.user?.orgId);
  return useQuery<ProjectStatsById, ApiErrorType>({
    queryKey: projectQueryKeys.stats(orgId ?? -1),
    queryFn: async () => {
      const res = await ProjectAPI.getProjectStatList();
      return res.data;
    },
    staleTime: 20000,
    enabled: !!orgId,
  });
};

export const useGetProjectSplitQuery = () => {
  const projectId = useTypedSelector(state => state.project.selectedProjectId) ?? 0;
  return useQuery({
    queryKey: projectQueryKeys.splits(projectId),
    queryFn: async () => {
      const apiResponse = await ProjectAPI.getSplit(projectId);
      return apiResponse.data.sort((a, b) =>
        statsCardDataKeyCompareFn(a.splitSetName, b.splitSetName),
      );
    },
    enabled: !!projectId,
  });
};

const fetchDefects = async (projectId: ProjectId, versionedDatasetContentId?: number) => {
  const apiResponse = await DefectAPI.getByProject(projectId, versionedDatasetContentId);
  const defects: Defect[] = apiResponse.data.sort((defectA, defectB) => defectA.id - defectB.id);
  return defects;
};

export const useGetProjectDefectsQuery = (versionedDatasetContentId?: number) => {
  const projectId = useTypedSelector(state => state.project.selectedProjectId) ?? 0;
  return useQuery<Defect[], ApiErrorType>({
    queryKey: projectQueryKeys.defects(projectId, versionedDatasetContentId),
    queryFn: async () => fetchDefects(projectId, versionedDatasetContentId),
    enabled: !!projectId,
  });
};

export const useGetProjectVersionedDefectsQuery = (versionedDatasetContentId = 0) => {
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const projectId = selectedProject?.id ?? 0;
  return useQuery<Defect[], ApiErrorType>({
    queryKey: projectQueryKeys.versionedDefects(projectId, versionedDatasetContentId),
    queryFn: async () => fetchDefects(projectId, versionedDatasetContentId),
    enabled:
      !!projectId &&
      (!!versionedDatasetContentId ||
        selectedProject?.labelType === LabelType.SegmentationInstantLearning),
  });
};

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useSetAtom } from 'jotai';
import { setProjectDisplayCheckMarkWhenCompleteAtom } from '@/uiStates/projectTrainingInProgress/pageUIStates';
import CheckIcon from '@material-ui/icons/Check';

const ANIMATION_SEC = 6;

const useStyles = makeStyles(theme => ({
  trainingCompletedCheckMark: {
    top: -2,
    color: theme.palette.common.white,
    fontSize: 20,
    fontWeight: 'bold',
    animation: `$trainingCompletedCheckMark ${ANIMATION_SEC}s linear infinite`,
  },
  '@keyframes trainingCompletedCheckMark': {
    '0%': {
      opacity: 0,
    },
    '30%': {
      opacity: 1,
    },
    '70%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
}));
type TrainingCompletedCheckMarkProps = {
  projectId?: number;
};
const TrainingCompletedCheckMark = (props: TrainingCompletedCheckMarkProps) => {
  const styles = useStyles();
  const { projectId } = props;
  const setProjectDisplayCheckMarkWhenComplete = useSetAtom(
    setProjectDisplayCheckMarkWhenCompleteAtom,
  );

  useEffect(() => {
    const timer1 = setTimeout(
      () =>
        projectId &&
        setProjectDisplayCheckMarkWhenComplete({
          projectId: projectId,
          shouldDisplayCheckMarkWhenComplete: false,
        }),
      ANIMATION_SEC * 1000,
    );
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return <CheckIcon className={styles.trainingCompletedCheckMark} />;
};

export default TrainingCompletedCheckMark;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import IconButton from '../components/IconButton';
import cx from 'classnames';
import { throttle } from 'lodash';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  row: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'auto',
  },
  switchBatchButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    background: theme.palette.common.white,
    zIndex: 1000,
    width: 40,
    height: 40,
  },
  goToPrevBatchButton: {
    left: -15,
    boxShadow: '0px 1px 2px 1px #30374F14,0px 1px 2px 0px #30374F29',
  },
  // Material UI ArrowBackIos icon is not horizontally centered, so we use reversed ArrowForwardIos instead
  goToPrevBatchButtonIcon: {
    scale: -1,
  },
  goToNextBatchButton: {
    right: -15,
    boxShadow: '0px 1px 2px 1px #30374F14,0px 1px 2px 0px #30374F29',
  },
}));

const FLOATING_NUMBER_PRECISION_TOLERANCE = 0.01;
const SCROLLING_INTO_VIEW_DURATION = 750;

const divide = (a: number, b: number) => {
  const c = a / b;
  const decimalPortion = c % 1;

  // For number like X.009, we consider it as X
  if (decimalPortion <= FLOATING_NUMBER_PRECISION_TOLERANCE) {
    return Math.trunc(c);
  }
  // For number like X.991, we consider it as X+1
  else if (1 - decimalPortion <= FLOATING_NUMBER_PRECISION_TOLERANCE) {
    return Math.ceil(c);
  } else {
    return c;
  }
};

export interface VirtualRowProps<T extends { id: number | string }> {
  dataList: T[];
  children: (data: T, index: number) => React.ReactNode;
  containerWidth: number;
  itemRatio: number;
  itemCountPerRow: number;
  bufferOffset?: number;
}

export const VirtualRow = <T extends { id: number | string }>({
  dataList,
  children,
  containerWidth,
  itemRatio,
  itemCountPerRow,
  bufferOffset,
}: VirtualRowProps<T>) => {
  const styles = useStyles();

  const containerRef = useRef<HTMLDivElement>();
  const childrenRef = useRef<Array<HTMLDivElement | undefined>>(
    new Array(dataList.length).fill(undefined),
  );
  const currentTimeout = useRef<number | null>(null);
  const [currentRange, setCurrentRange] = useState<{
    start: number;
    end: number;
  }>({ start: 0, end: itemCountPerRow - 1 });

  useEffect(() => {
    setCurrentRange({
      start: 0,
      end: itemCountPerRow - 1,
    });
  }, [itemCountPerRow]);

  const finalBufferOffset = useMemo(
    () => bufferOffset ?? itemCountPerRow * 2,
    [bufferOffset, itemCountPerRow],
  );

  const jumpOffset = useMemo(
    () =>
      currentRange.end - currentRange.start > itemCountPerRow - 1
        ? // If some children are rendered partially due to scrolling, mark the offset as 0, so that the next time clicking
          // on the switch batch button will first adjust the scrolling to make children rendered just fit the container
          0
        : itemCountPerRow,
    [itemCountPerRow, currentRange.end, currentRange.start],
  );

  const shouldRender = useCallback(
    (index: number) =>
      index >= currentRange.start - finalBufferOffset &&
      index <= currentRange.end + finalBufferOffset,

    [finalBufferOffset, currentRange.end, currentRange.start],
  );

  const isScrollingIntoView = useRef(false);

  const jumpToItem = useCallback(
    (itemIndex: number) => {
      // Scroll from left to right
      if (currentRange.end <= itemIndex) {
        const newEnd = Math.min(itemIndex);

        setCurrentRange({
          start: newEnd - itemCountPerRow + 1,
          end: newEnd,
        });
      }
      // Scroll from right to left
      else if (itemIndex <= currentRange.start) {
        const newStart = itemIndex;

        setCurrentRange({
          start: newStart,
          end: newStart + itemCountPerRow - 1,
        });
      }

      isScrollingIntoView.current = true;

      // If there is already a scroll-into-view happening, then clear the previous "setTimeout"
      if (currentTimeout.current) {
        clearTimeout(currentTimeout.current);
      }

      // TODO: wait for the new CSS proposal to add "scrollend" event to know when the animation is finished instead of
      // using "isScrollingIntoView", in order to avoid redundant handling for the scorll events triggered
      // Reference: https://stackoverflow.com/a/57867348
      childrenRef.current[itemIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      currentTimeout.current = setTimeout(() => {
        isScrollingIntoView.current = false;
      }, SCROLLING_INTO_VIEW_DURATION) as unknown as number;
    },
    [itemCountPerRow, currentRange.end, currentRange.start],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onScroll = useCallback(
    throttle(
      (width: number, itemCountPerRow: number) => {
        if (isScrollingIntoView.current) {
          return;
        }

        const widthPerItem = width / itemCountPerRow;
        const scrollLeft = containerRef.current?.scrollLeft ?? 0;

        const scrolledItemCount = divide(scrollLeft, widthPerItem);

        const start = Math.trunc(scrolledItemCount);
        const end = Math.ceil(scrolledItemCount) + itemCountPerRow - 1;

        setCurrentRange({
          start,
          end,
        });
      },
      200,
      { leading: false },
    ),
    [],
  );

  return (
    <div
      className={styles.container}
      style={{
        width: containerWidth,
      }}
    >
      <div
        ref={ref => {
          if (ref) {
            containerRef.current = ref;
          }
        }}
        className={styles.row}
        onScroll={() => onScroll(containerWidth, itemCountPerRow)}
      >
        {dataList.map((data, index) => (
          <div
            key={data.id}
            ref={ref => {
              if (ref && !childrenRef.current[index]) {
                childrenRef.current[index] = ref;
              }
            }}
            style={{
              height: (containerWidth * itemRatio) / itemCountPerRow,
              width: `${100 / itemCountPerRow}%`,
              minWidth: `${100 / itemCountPerRow}%`,
            }}
          >
            {shouldRender(index) && children(data, index)}
          </div>
        ))}

        {currentRange.start !== 0 && (
          <IconButton
            id="virtual-row-go-to-prev-batch"
            className={cx(styles.switchBatchButton, styles.goToPrevBatchButton)}
            onClick={() => jumpToItem(Math.max(currentRange.start - jumpOffset, 0))}
          >
            <ArrowForwardIos className={styles.goToPrevBatchButtonIcon} />
          </IconButton>
        )}

        {currentRange.end < dataList.length - 1 && (
          <IconButton
            id="virtual-row-go-to-next-batch"
            className={cx(styles.switchBatchButton, styles.goToNextBatchButton)}
            onClick={() => jumpToItem(Math.min(currentRange.end + jumpOffset, dataList.length - 1))}
          >
            <ArrowForwardIos />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default VirtualRow;

import { ModelComparisonReport, ModelComparisonReportResponseItem } from '@clef/shared/types';
import { BaseAPI } from './base_api';

export type ModelComparisonReportsResponse = ModelComparisonReportResponseItem[];

export type CreateModelComparisonReportParams = Pick<
  ModelComparisonReport,
  | 'projectId'
  | 'name'
  | 'baseModelId'
  | 'baseThreshold'
  | 'candidateModelId'
  | 'candidateThreshold'
  | 'evaluationSetId'
>;

class ModelComparisonReportAPI extends BaseAPI {
  async createModelComparisonReport(params: CreateModelComparisonReportParams) {
    return this.postJSON('create_comparison', params);
  }
  async deleteModelComparisonReport(modelComparisonReportId: number) {
    return this.postJSON('delete_comparison', { modelComparisonReportId });
  }
  async getModelComparisonReports(projectId: number): Promise<ModelComparisonReportsResponse> {
    return this.get('comparisons', { projectId }, true);
  }
  async updateModelComparisonReport(params: {
    modelComparisonReportId: number;
    name?: string;
    description?: string;
    favorite?: boolean;
  }) {
    return this.postJSON('update', params);
  }
}

export default new ModelComparisonReportAPI('model_comparison');

import React, { useState } from 'react';
import {
  LinearProgress,
  makeStyles,
  Box,
  Chip,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { Button, IconButton } from '@clef/client-library';
import SearchBox from '@/components/Text/SearchBox';
import { CreateOrUpdateTagDialog } from '@/components/Labeling/CreateOrUpdateTagDialog';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  useGetProjectTagsQuery,
  useCreateNewTagMutation,
  useUpdateTagMutation,
  useArchiveTagMutation,
} from '@/serverStore/tags';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useDialog } from '@/components/Layout/components/useDialog';
import { Tag } from '@clef/shared/types';

const useChipStyles = makeStyles(theme => ({
  chip: {
    backgroundColor: theme.palette.greyModern[200],
  },
}));

const TagChip: React.FC<{ name: string }> = ({ name }) => {
  const styles = useChipStyles();
  return <Chip label={name} className={styles.chip} />;
};

const useStyles = makeStyles(theme => ({
  operationPanel: {
    margin: theme.spacing(5, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableContainer: {
    border: '1px solid',
    borderColor: theme.palette.grey[300],
    borderRadius: '6px',
    height: 500,
  },
  tableHeader: {
    background: theme.palette.grey[25],
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}));

export const TagTable: React.FunctionComponent<{}> = () => {
  const styles = useStyles();
  const { data: tags = [], isFetching } = useGetProjectTagsQuery();
  const [selectedTag, setSelectedTag] = useState<Tag | undefined>(undefined);
  const updateTag = useUpdateTagMutation();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const createTag = useCreateNewTagMutation();
  const [searchText, setSearchText] = useState<string>('');
  const archiveTag = useArchiveTagMutation();
  const { showConfirmationDialog } = useDialog();

  const onClose = () => setSelectedTag(undefined);

  return !isFetching ? (
    <>
      <Box className={styles.operationPanel}>
        <SearchBox
          type="text"
          placeholder={t('Search')}
          onInput={(e: React.FormEvent): void => {
            setSearchText((e.target as HTMLInputElement).value);
          }}
        />
        <Button
          variant="contained"
          color="primary"
          id="create-tags"
          className="cy-create-tags"
          onClick={() => {
            setAddDialogOpen(true);
          }}
        >
          {t('Add A New Tag')}
        </Button>
      </Box>
      <TableContainer className={styles.tableContainer}>
        <Table aria-label="tag-table" size="small">
          <TableHead className={styles.tableHeader}>
            <TableRow>
              <TableCell align="left">{t('Name')}</TableCell>
              <TableCell align="left">{t('Rename')}</TableCell>
              <TableCell align="left">{t('Delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tags
              .filter(
                tag =>
                  !tag.isArchived &&
                  (!searchText || tag.name.toLowerCase().startsWith(searchText.toLowerCase())),
              )
              .sort((tagA, tagB) => tagA.name.toLowerCase().localeCompare(tagB.name.toLowerCase()))
              .map(tag => (
                <TableRow key={tag.id} className={styles.tableRow} hover>
                  <TableCell align="left">
                    <TagChip name={tag.name} />
                  </TableCell>
                  <TableCell align="left">
                    <IconButton onClick={() => setSelectedTag(tag)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="left">
                    <IconButton
                      onClick={() =>
                        showConfirmationDialog({
                          title: t('Confirm deleting tags'),
                          content: t('Are you sure you want to delete these tags?'),
                          confirmText: t('Yes, delete'),
                          color: 'secondary',
                          onConfirm: () => {
                            archiveTag.mutate(tag.id);
                          },
                        })
                      }
                    >
                      <DeleteIcon color="secondary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {selectedTag && (
          <CreateOrUpdateTagDialog
            initialTagName={selectedTag.name}
            isLoading={updateTag.isLoading}
            onSave={name => {
              updateTag.mutate(
                {
                  tagId: selectedTag.id,
                  name: name === selectedTag.name || name.length === 0 ? undefined : name,
                },
                {
                  onSuccess: onClose,
                },
              );
            }}
            onClose={onClose}
          />
        )}
      </TableContainer>
      {addDialogOpen && (
        <CreateOrUpdateTagDialog
          isLoading={createTag.isLoading}
          onSave={name => {
            selectedProject &&
              createTag.mutate(
                {
                  projectId: selectedProject.id,
                  name,
                },
                {
                  onSuccess: () => {
                    setAddDialogOpen(false);
                  },
                },
              );
          }}
          onClose={() => {
            setAddDialogOpen(false);
          }}
        />
      )}
    </>
  ) : (
    <LinearProgress />
  );
};

import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core';

const appHeaderHeight = 64;

const useStyles = makeStyles(theme => ({
  topDrawer: {
    position: 'fixed',
    width: 'calc(100vw - 304px)',
    backgroundColor: theme.palette.background.default,
    boxSizing: 'content-box',
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    left: 240,
    zIndex: 300,
    transition: 'all 0.3s ease',
    boxShadow: theme.boxShadow.default,
  },
}));

export interface TopDrawerProps {
  classes?: { root?: string };
  show: boolean;
}

const TopDrawer: React.FC<TopDrawerProps> = ({ children, classes, show }) => {
  const styles = useStyles();

  const [currentShow, setCurrentShow] = useState(false);
  const [stickyStyles, setStickStyles] = useState<React.CSSProperties>({
    top: 0,
    opacity: 0,
  });

  useEffect(() => {
    // If current we don't show, but next prop says we should
    if (!currentShow && show) {
      // first set sticky position to be topped and hidden
      setStickStyles({
        top: 0,
        opacity: 0,
      });
      setCurrentShow(true);
      // After down rAFs (so browser has painted), set correct position, set currentSticky=true
      // this will trigger animation
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setStickStyles({
            top: appHeaderHeight,
            opacity: 1,
          });
        });
      });
    }

    // If current we show sticky header, but next prop says we should not
    if (currentShow && !show) {
      // first set sticky position to be topped and hidden, this will trigger animation
      setStickStyles({
        top: 0,
        opacity: 0,
      });
      // After animation finished, set currentSticky=false, this will remove sticky header from DOM
      setTimeout(() => {
        setCurrentShow(false);
      }, 500);
    }
  }, [currentShow, show]);

  if (!currentShow) return null;

  return (
    <div style={stickyStyles} className={cx(styles.topDrawer, classes?.root)}>
      {children}
    </div>
  );
};

export default TopDrawer;

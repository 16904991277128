import Konva from 'konva';
import { isEqual, throttle } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Circle, Line } from 'react-konva';
import { LineAnnotation } from '@clef/shared/types';
import { calcAccurateOffset, distance } from '../utils';
import { attentionRed, infoBlue } from '../../../themes/colors';
import { AnnotationCreatorBuilder } from '../types';
import useMemento from '../../../hooks/useMemento';

export type PolygonAnnotationDrawHook = (options: {
  scale: number;
  color?: string;
  opacity: number;
  onFinishAnnotation: (line: LineAnnotation) => void;
  onMissingColor?: () => void;
  onIsWorkingInProgressChanged?: (wip: boolean) => void;
}) => AnnotationCreatorBuilder;

export const POLYGON_AUTO_CLOSE_THRESHOLD = 12;

const tryStickToPoint = (
  fromX: number,
  fromY: number,
  toX: number,
  toY: number,
  threshold: number,
) => {
  if (distance(fromX, fromY, toX, toY) < threshold) {
    return [toX, toY];
  } else {
    return [fromX, fromY];
  }
};

export const usePolygonAnnotationCreator: PolygonAnnotationDrawHook = ({
  scale,
  color,
  opacity,
  onFinishAnnotation,
  onMissingColor,
  onIsWorkingInProgressChanged: onIsDrawingChange,
}) => {
  const [points, setPoints] = useState<number[]>([]);
  const { set, undo, reset } = useMemento(points);
  const wipPolygonAnnotation = useMemo(
    () => ({
      color: color!,
      points,
      opacity,
      strokeWidth: 0,
    }),
    [color, opacity, points],
  );
  // these states do not need to triger re-render
  const isDrawing = points.length > 0;
  const isMouseDown = useRef(false);
  const isFreeDrawLine = useRef(false);
  const mousePosition = useRef({ x: 0, y: 0 });

  const onMouseMove = useCallback(
    throttle((e: Konva.KonvaEventObject<MouseEvent>) => {
      if (!isDrawing) {
        return;
      }
      let { x, y } = calcAccurateOffset(e);
      mousePosition.current = { x, y };
      setPoints(prev => {
        if (points.length >= 2) {
          [x, y] = tryStickToPoint(
            x,
            y,
            points[0],
            points[1],
            POLYGON_AUTO_CLOSE_THRESHOLD / scale,
          );
        }

        // do not store consecutive duplicate points
        if (isEqual([x, y], prev.slice(-2))) {
          return prev;
        }

        if (isMouseDown.current) {
          // free draw, add every point to the list
          isFreeDrawLine.current = true;
          return prev.concat(x, y);
        } else {
          // not free draw, update the last position (current mouse position) in the list
          return prev.slice(0, -2).concat(x, y);
        }
      });
    }, 48),
    [scale, isDrawing],
  );

  const onMouseDown = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (color) {
        isMouseDown.current = true;
        const { x, y } = calcAccurateOffset(e);
        setPoints(prev => {
          const newPoints = prev.length === 0 ? [x, y, x, y] : prev.concat(x, y);
          set(newPoints);
          return newPoints;
        });
        onIsDrawingChange?.(true);
      } else {
        onMissingColor?.();
      }
    },
    [color, onIsDrawingChange, onMissingColor, set],
  );

  const onDiscardWip = useCallback(() => {
    setPoints([]);
    reset([]);
    onIsDrawingChange?.(false);
  }, [onIsDrawingChange, reset]);

  const onMouseUp = useCallback(() => {
    if (!color) {
      return;
    }

    isMouseDown.current = false;
    // when the current point equals the start point, finish drawing
    if (points.length > 4) {
      const [firstX, firstY] = points;
      let [lastX, lastY] = points.slice(-2);
      [lastX, lastY] = tryStickToPoint(
        lastX,
        lastY,
        firstX,
        firstY,
        POLYGON_AUTO_CLOSE_THRESHOLD / scale,
      );
      if (lastX === firstX && lastY === firstY) {
        onFinishAnnotation(wipPolygonAnnotation);
        onDiscardWip();
        return;
      }
    }

    if (isFreeDrawLine.current) {
      set(points);
      isFreeDrawLine.current = false;
    }
  }, [color, points, scale, onFinishAnnotation, wipPolygonAnnotation, onDiscardWip, set]);

  const handleUndo = useCallback(() => {
    const { x, y } = mousePosition.current;
    const newPoints = undo();
    if (newPoints.length === 0) {
      onDiscardWip();
    } else if (!isEqual(newPoints.slice(-2), [x, y])) {
      newPoints.push(x, y);
    }
    setPoints(newPoints);
  }, [onDiscardWip, undo]);

  // TODO: wait for feedback to see if redo is needed.
  // const handleRedo = useCallback(() => {
  //   const newPoints = redo();
  //   const { x, y } = mousePosition.current;
  //   if (!isEqual(newPoints.slice(-2), [x, y])) {
  //     newPoints.push(x, y);
  //   }
  //   setPoints(newPoints);
  // }, [redo]);

  const previewColor = color === 'transparent' ? attentionRed.dark : infoBlue.main;

  return {
    creatorHelperComponent: points.length ? (
      <>
        {/* drawn line */}
        <Line
          points={points.slice(0, -2)}
          stroke={previewColor}
          strokeWidth={2}
          strokeScaleEnabled={false}
        />
        {/* the last line */}
        <Line
          dash={[8, 4]}
          points={points.slice(-4)}
          stroke={previewColor}
          strokeWidth={1}
          strokeScaleEnabled={false}
        />
        {points.length >= 4 && (
          <Circle
            x={points[0]}
            y={points[1]}
            radius={6 / scale}
            stroke="white"
            strokeWidth={2}
            strokeScaleEnabled={false}
            fill={previewColor}
          />
        )}
      </>
    ) : undefined,
    creatorInteractiveProps: {
      onMouseMove,
      onMouseDown,
      onMouseUp,
    },
    creatorCustomHandlers: {
      onEscPressed: onDiscardWip,
      onOutsideClick: onDiscardWip,
      onModeChanged: onDiscardWip,
      onUndo: handleUndo,
      isWorkInProgress: () => isDrawing,
    },
  };
};

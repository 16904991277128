import { Annotation, AnnotationType, Defect, Media, Position } from '@clef/shared/types';
import React, { useRef, useMemo, useState } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { FilterOptions } from '@/api/model_analysis_api';
import { useAtom } from 'jotai';
import { modelListFilterOptionsAtom } from '../atoms';
import { hexToRgb } from '@clef/shared/utils';
import {
  useSegmentationInfoWithFilterOptions,
  useComparisonSegmentationInfoWithFilters,
  useSegmentationPredictionDataUrl,
} from '@/pages/DataBrowser/utils';
import { useDatasetMediaDetailsQuery } from '@/serverStore/dataset';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import {
  AnnotationSourceType,
  CanvasAnnotation,
  CanvasAnnotationType,
  isDark,
  MediaInteractiveCanvas,
  Typography,
  ZoomScale,
} from '@clef/client-library';
import { isAboutSameColor, offscreenCanvasToCanvasAnnotation } from '@/components/Labeling/utils';
import { Skeleton } from '@material-ui/lab';
import { KonvaEventObject } from 'konva/types/Node';
import { getDefectColor } from '@/utils';
import { DefectColorChip } from '@/pages/DataBrowser/ModelPerformance/ConfusionMatrix';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { useCurrentDefectsWithArchivedAndIndexFromVersion } from '@/store/defectState/actions';

const useStyles = makeStyles<
  Theme,
  {
    left: number;
    top: number;
    color?: string | null;
  }
>(theme => ({
  floatingBox: {
    position: 'absolute',
    top: props => props.top,
    left: props => props.left,
    background: props => props.color ?? theme.palette.grey[100],
    color: props => (props.color && isDark(props.color) ? 'white' : theme.palette.grey[800]),
    border: '1px solid #000',
    borderRadius: '5px',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
  },
  floatingBoxText: {
    fontWeight: 700,
  },
  floatingBoxIcon: {
    fontSize: '20px',
  },
}));

const FloatingDefectClass: React.FC<{
  mousePos: Position;
  defect?: Defect;
  isPrediction: boolean;
  containerWidth: number;
  containerHeight: number;
  showCorrectIcon?: boolean;
  isCorrect?: boolean;
}> = ({
  mousePos,
  defect,
  containerWidth,
  containerHeight,
  isPrediction,
  showCorrectIcon,
  isCorrect,
}) => {
  const boxRef = useRef<HTMLDivElement>(null);
  // at initial rendering boxRef is undefined
  const boxWidth = boxRef.current?.clientWidth || (defect?.name.length ?? 8) + 24;
  const boxHeight = boxRef.current?.clientHeight || 24;
  const left = mousePos.x > containerWidth / 2 ? mousePos.x - boxWidth - 8 : mousePos.x + 8;
  const top = mousePos.y > containerHeight / 2 ? mousePos.y - boxHeight - 8 : mousePos.y + 8;

  const styles = useStyles({
    left,
    top,
    color: getDefectColor(defect),
  });
  if (!mousePos) {
    return null;
  }
  return (
    <div ref={boxRef} className={styles.floatingBox}>
      {showCorrectIcon &&
        (isCorrect ? (
          <CheckIcon className={styles.floatingBoxIcon} />
        ) : (
          <CloseIcon className={styles.floatingBoxIcon} color="error" />
        ))}
      <Typography className={styles.floatingBoxText}>
        {defect?.name ?? (isPrediction ? t('No Prediction') : t('No Label'))}
      </Typography>
    </div>
  );
};

interface IProps {
  media: Media & { count?: number; candidateCount?: number };
  modelId?: string;
  versionId?: number;
  threshold?: number;
  candidateModelId?: string;
  candidateThreshold?: number;
  showFilteredOnly?: boolean;
}

interface WrapperProps extends IProps {
  forwardRef: React.MutableRefObject<MediaInteractiveCanvas | null>;
  target: AnnotationSourceType;
  title: string;
  hoveredClass?: Defect;
  hasPairedClass?: boolean;
  setHoveredClass: (defect: Defect | undefined) => void;
  onMouseMove: (e?: KonvaEventObject<MouseEvent>) => void;
  onZoomScaleChange: (newZoomScale: ZoomScale, mousePosition?: Position | null) => void;
  onDragMove: (p: Position) => void;
}

const useWrapperStyles = makeStyles<Theme, { target: AnnotationSourceType }>(theme => ({
  container: {
    width: '50%',
    display: 'flex',
    flexGrow: 0,
    flexDirection: 'column',
    position: 'relative',
  },
  title: {
    fontWeight: 700,
    padding: theme.spacing(1, 4),
    color: theme.palette.grey[900],
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
  errorPixelsCountText: {
    fontWeight: 400,
    color: theme.palette.grey[800],
  },
  pixelCount: {
    minHeight: theme.spacing(5),
    color: theme.palette.grey[900],
    padding: theme.spacing(0, 4),
  },
  highlightTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
  errorPixelsCount: {
    background: 'rgba(0, 0, 0, 0.50)',
    padding: '0 2px',
    position: 'absolute',
    right: 24,
    bottom: 24,
    color: 'white',
  },
}));

export const HighlightedWrapper: React.FC<
  WrapperProps & {
    canvas?: OffscreenCanvas;
    filterOptions: FilterOptions;
    type?: 'baseline' | 'candidate' | 'ground_truth';
    showCorrectIcon?: boolean;
    isCorrect?: boolean;
  }
> = ({
  media,
  title,
  forwardRef,
  modelId,
  versionId,
  canvas,
  target,
  type,
  hasPairedClass,
  hoveredClass,
  setHoveredClass,
  filterOptions,
  showCorrectIcon,
  isCorrect,
  onMouseMove,
  onZoomScaleChange,
  onDragMove,
}) => {
  const styles = useWrapperStyles({ target });
  const containerRef = useRef<HTMLDivElement>(null);
  const { datasetId } = useGetSelectedProjectQuery().data ?? {};
  const datasetDefects = useCurrentDefectsWithArchivedAndIndexFromVersion(versionId);
  const { data: mediaDetails } = useDatasetMediaDetailsQuery({
    datasetId,
    mediaId: media.id,
    modelId: modelId,
    ...(versionId && { versionId }),
  });
  const defectsWithRgb = useMemo(
    () => datasetDefects?.map(defect => ({ ...defect, rgb: hexToRgb(getDefectColor(defect)) })),
    [datasetDefects],
  );
  const [mousePos, setMousePos] = useState<Position>();

  if (!canvas) {
    return <Skeleton variant="rect" width="100%" height="100%" />;
  }

  const noLabel =
    (target === AnnotationSourceType.GroundTruth && filterOptions.gtClassId === 0) ||
    (target === AnnotationSourceType.Prediction && filterOptions.predClassId === 0);

  // TODO: MediaInteractiveCanvas is too heavy, @Louie change to a
  // simple Konva component
  return (
    <div ref={containerRef} className={styles.container}>
      <Box className={styles.titleContainer}>
        <Typography className={styles.title}>{title}</Typography>
        {media.count && type === 'baseline' && (
          <Typography className={styles.errorPixelsCountText}>
            {t('{{count}} pixels', { count: media.count })}
          </Typography>
        )}
        {media.candidateCount && type === 'candidate' && (
          <Typography className={styles.errorPixelsCountText}>
            {t('{{count}} pixels', { count: media.candidateCount })}
          </Typography>
        )}
      </Box>
      {media.count && target === 'ground_truth' && (
        <div className={styles.pixelCount}>
          <Box className={styles.highlightTitle}>
            <Typography>
              {t('{{count}} pixels', {
                count: media.count,
              })}
            </Typography>
            <DefectColorChip defectId={filterOptions.gtClassId} />
            <Typography>
              {t(' to ', {
                count: media.count,
              })}
            </Typography>
            <DefectColorChip isPrediction defectId={filterOptions.predClassId} />
            <Typography>{t(' filtered')}</Typography>
          </Box>
        </div>
      )}
      <MediaInteractiveCanvas
        ref={forwardRef}
        imageSrc={mediaDetails?.url}
        properties={mediaDetails?.properties}
        builtInZoom
        onMouseMove={(e, info) => {
          const { labelColor } = info ?? {};
          if (labelColor) {
            const defect = defectsWithRgb?.find(defect => isAboutSameColor(defect.rgb, labelColor));
            setHoveredClass(defect);
          } else {
            setHoveredClass(undefined);
          }
          if (e) {
            setMousePos({
              x: e.evt.offsetX,
              y: e.evt.offsetY,
            });
          }
          onMouseMove(e);
        }}
        enablePinchScrollZoom
        onZoomScaleChange={onZoomScaleChange}
        onDragMove={onDragMove}
        annotations={[
          offscreenCanvasToCanvasAnnotation(
            {
              data: canvas,
              id: '',
            },
            noLabel ? 1 : 0.7,
            target,
          ),
        ]}
        showGroundTruthLabels
        enableCrosshair
        disableUndoRedo
      />
      {mousePos && containerRef.current && (hasPairedClass || !!hoveredClass) && (
        <FloatingDefectClass
          mousePos={mousePos}
          defect={hoveredClass}
          isPrediction={target === AnnotationSourceType.Prediction}
          showCorrectIcon={showCorrectIcon}
          isCorrect={isCorrect}
          containerWidth={containerRef.current.clientWidth}
          containerHeight={containerRef.current.clientHeight}
        />
      )}
    </div>
  );
};

// TODO: can be combined with the wrapper abover @Louie
export const NormalWrapper: React.FC<WrapperProps> = ({
  media,
  title,
  forwardRef,
  modelId,
  versionId,
  threshold,
  target,
  hasPairedClass,
  hoveredClass,
  setHoveredClass,
  onMouseMove,
  onZoomScaleChange,
  onDragMove,
}) => {
  const styles = useWrapperStyles({ target });
  const containerRef = useRef<HTMLDivElement>(null);
  const { datasetId } = useGetSelectedProjectQuery().data ?? {};
  const datasetDefects = useCurrentDefectsWithArchivedAndIndexFromVersion(versionId);
  const { data: mediaDetails } = useDatasetMediaDetailsQuery({
    datasetId,
    mediaId: media.id,
    modelId: modelId,
    ...(versionId && { versionId }),
  });
  const [, canvas] = useSegmentationPredictionDataUrl(
    mediaDetails?.predictionLabel?.segImgPath || '',
    threshold ?? 0,
    datasetDefects,
    0.7,
  );
  const defectsWithRgb = useMemo(
    () => datasetDefects?.map(defect => ({ ...defect, rgb: hexToRgb(getDefectColor(defect)) })),
    [datasetDefects],
  );
  const [mousePos, setMousePos] = useState<Position>();

  if (!canvas) {
    return <Skeleton variant="rect" width="100%" height="100%" />;
  }

  const predictionCanvasAnnotation = canvas
    ? [
        offscreenCanvasToCanvasAnnotation(
          { data: canvas, id: '' },
          1,
          AnnotationSourceType.Prediction,
        ),
      ]
    : [];

  const annotations =
    (mediaDetails?.label?.annotations as Annotation[])?.filter(
      annotation => annotation.annotationType === AnnotationType.segmentation,
    ) ?? [];
  const gtCanvasAnnotations = annotations.map(annotation => {
    const defect = datasetDefects?.find(defect => defect.id === annotation.defectId);
    const color = getDefectColor(defect);
    return {
      id: annotation.id.toString(),
      type: CanvasAnnotationType.BitMap,
      defectId: annotation.defectId,
      data: {
        rangeBox: annotation.rangeBox,
        bitMap: annotation.segmentationBitmapEncoded,
        opacity: 0.7,
        color,
        key: annotation.id.toString(),
      },
      group: AnnotationSourceType.GroundTruth,
    } as CanvasAnnotation;
  });

  // TODO: MediaInteractiveCanvas is too heavy, @Louie change to a
  // simple Konva component
  return (
    <div ref={containerRef} className={styles.container}>
      <Typography className={styles.title}>{title}</Typography>
      <MediaInteractiveCanvas
        ref={forwardRef}
        enablePinchScrollZoom
        onMouseMove={(e, info) => {
          const { labelColor, predictColor } = info ?? {};
          const color = target === AnnotationSourceType.GroundTruth ? labelColor : predictColor;

          if (color) {
            const defect = defectsWithRgb?.find(defect => isAboutSameColor(defect.rgb, color));
            setHoveredClass(defect);
          } else {
            setHoveredClass(undefined);
          }
          if (e) {
            setMousePos({
              x: e.evt.offsetX,
              y: e.evt.offsetY,
            });
          }
          onMouseMove(e);
        }}
        builtInZoom
        onZoomScaleChange={onZoomScaleChange}
        onDragMove={onDragMove}
        imageSrc={mediaDetails?.url}
        properties={mediaDetails?.properties}
        annotations={
          target === AnnotationSourceType.GroundTruth
            ? gtCanvasAnnotations
            : predictionCanvasAnnotation
        }
        showGroundTruthLabels
        enableCrosshair
        disableUndoRedo
      />
      {mousePos && containerRef.current && (hasPairedClass || !!hoveredClass) && (
        <FloatingDefectClass
          mousePos={mousePos}
          defect={hoveredClass}
          isPrediction={target === AnnotationSourceType.Prediction}
          containerWidth={containerRef.current.clientWidth}
          containerHeight={containerRef.current.clientHeight}
        />
      )}
    </div>
  );
};

export const SegmentationImageDiffView: React.FC<IProps> = props => {
  const gtRef = useRef<MediaInteractiveCanvas | null>(null);
  const predictionRef = useRef<MediaInteractiveCanvas | null>(null);
  const [hoveredGtClass, setHoveredGtClass] = useState<Defect | undefined>();
  const [hoveredPredictionClass, setHoveredPredictionClass] = useState<Defect | undefined>();
  const [filterOptions] = useAtom(modelListFilterOptionsAtom);
  const info = useSegmentationInfoWithFilterOptions(
    props.media.id,
    props.modelId,
    props.threshold,
    props.versionId,
    filterOptions,
  );

  return (
    <>
      {[AnnotationSourceType.GroundTruth, AnnotationSourceType.Prediction].map(target => {
        const targetRef = target === AnnotationSourceType.GroundTruth ? predictionRef : gtRef;
        const wrapperProps: WrapperProps = {
          ...props,
          title:
            target === AnnotationSourceType.GroundTruth
              ? t('Ground truth mask')
              : t('Prediction mask'),
          forwardRef: target === AnnotationSourceType.GroundTruth ? gtRef : predictionRef,
          target,
          hoveredClass:
            target === AnnotationSourceType.GroundTruth ? hoveredGtClass : hoveredPredictionClass,
          hasPairedClass:
            target === AnnotationSourceType.GroundTruth
              ? !!hoveredPredictionClass
              : !!hoveredGtClass,
          setHoveredClass:
            target === AnnotationSourceType.GroundTruth
              ? setHoveredGtClass
              : setHoveredPredictionClass,
          onMouseMove: e => {
            targetRef?.current?.mouseMove(e);
          },
          onZoomScaleChange: (newScale, mousePosition) => {
            targetRef?.current?.setZoomScale(newScale, mousePosition, true);
          },
          onDragMove: p => targetRef.current?.setStagePosition(p),
        };
        return props.showFilteredOnly && filterOptions ? (
          <HighlightedWrapper
            key={target}
            {...wrapperProps}
            filterOptions={filterOptions}
            canvas={
              target === AnnotationSourceType.GroundTruth
                ? info?.gt.canvas
                : info?.prediction.canvas
            }
          />
        ) : (
          <NormalWrapper key={target} {...wrapperProps} />
        );
      })}
    </>
  );
};

export const ComparisonSegmentationImageDiffView: React.FC<IProps> = props => {
  const fullmaskRef = useRef<MediaInteractiveCanvas | null>(null);
  const baselineRef = useRef<MediaInteractiveCanvas | null>(null);
  const candidateRef = useRef<MediaInteractiveCanvas | null>(null);
  const [hoveredFullmaskClass, setHoveredFullmaskClass] = useState<Defect | undefined>();
  const [hoveredBaselineClass, setHoveredBaselineClass] = useState<Defect | undefined>();
  const [hoveredCandidateClass, setHoveredCandidateClass] = useState<Defect | undefined>();
  const [filterOptions] = useAtom(modelListFilterOptionsAtom);
  const { baseline, candidate } =
    useComparisonSegmentationInfoWithFilters(
      props.media.id,
      props.modelId,
      props.threshold,
      props.candidateModelId,
      props.candidateThreshold,
      props.versionId,
      filterOptions,
    ) ?? {};

  const wrappers = [
    ...(filterOptions && props.showFilteredOnly
      ? []
      : [
          {
            type: 'ground_truth',
            title: t('Ground truth'),
            canvas: undefined,
            forwardRef: fullmaskRef,
            hoveredClass: hoveredFullmaskClass,
            setHoveredClass: setHoveredFullmaskClass,
            target: AnnotationSourceType.GroundTruth,
            modelId: props.modelId,
            threshold: props.threshold,
          },
        ]),
    {
      type: 'baseline',
      title: t('Baseline model'),
      canvas: baseline?.canvas,
      forwardRef: baselineRef,
      hoveredClass: hoveredBaselineClass,
      setHoveredClass: setHoveredBaselineClass,
      showCorrectIcon:
        filterOptions &&
        filterOptions.gtClassId !== filterOptions.predClassId &&
        props.showFilteredOnly,
      isCorrect: (hoveredBaselineClass?.id ?? 0) === filterOptions?.gtClassId,
      target: AnnotationSourceType.Prediction,
      modelId: props.modelId,
      threshold: props.threshold,
    },
    {
      type: 'candidate',
      title: t('Candidate model'),
      canvas: candidate?.canvas,
      forwardRef: candidateRef,
      hoveredClass: hoveredCandidateClass,
      showCorrectIcon:
        filterOptions &&
        filterOptions.gtClassId !== filterOptions.predClassId &&
        props.showFilteredOnly,
      isCorrect: (hoveredCandidateClass?.id ?? 0) === filterOptions?.gtClassId,
      setHoveredClass: setHoveredCandidateClass,
      target: AnnotationSourceType.Prediction,
      modelId: props.candidateModelId,
      threshold: props.candidateThreshold,
    },
  ];

  return (
    <>
      {wrappers.map(wrapper => {
        const wrapperProps: WrapperProps = {
          ...props,
          ...wrapper,
          target: wrapper.target,
          hasPairedClass: wrappers.some(wrapper => !!wrapper.hoveredClass),
          onMouseMove: e => {
            wrappers.forEach(_wrapper => {
              _wrapper.type !== wrapper.type && _wrapper.forwardRef.current?.mouseMove(e);
            });
          },
          onZoomScaleChange: (newScale, mousePosition) => {
            wrappers.forEach(_wrapper => {
              _wrapper.type !== wrapper.type &&
                _wrapper.forwardRef.current?.setZoomScale(newScale, mousePosition, true);
            });
          },
          onDragMove: p => {
            wrappers.forEach(_wrapper => {
              _wrapper.type !== wrapper.type && _wrapper.forwardRef.current?.setStagePosition(p);
            });
          },
        };
        return filterOptions &&
          props.showFilteredOnly &&
          wrapper.type !== AnnotationSourceType.GroundTruth ? (
          <HighlightedWrapper
            key={wrapper.type}
            {...wrapperProps}
            filterOptions={filterOptions}
            canvas={wrapper.canvas}
          />
        ) : (
          <NormalWrapper key={wrapper.type} {...wrapperProps} />
        );
      })}
    </>
  );
};

import { Bar } from '@clef/shared/types';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { Button } from '../..';

type UseStylesProps = {
  size?: 'small' | 'medium';
  bandWidth?: number;
  borderRadius?: number;
};
const useStyles = (props: UseStylesProps) =>
  makeStyles(theme => {
    const {
      size,
      bandWidth = size === 'small' ? 10 : 20,
      borderRadius = size === 'small' ? 2 : 8,
    } = props;
    return {
      barChartRoot: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: theme.spacing(-2),
      },
      barContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        position: 'relative',
        paddingBottom: size === 'small' ? 10 : 16,
      },
      barName: {
        padding: theme.spacing(0, 1, 0, 0),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flexShrink: 1,
      },
      barNameOneline: {
        fontSize: 14,
        width: size === 'small' ? 50 : 120,
        textWrap: 'nowrap',
      },
      bar: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: bandWidth,
        borderRadius: borderRadius,
        transition: 'transform 0.25s',
        '&:hover': {
          transform: 'scale(1.1)',
        },
      },
      barOneline: {
        height: bandWidth,
        borderRadius: borderRadius,
        margin: theme.spacing(0, 4, 0, 6),
      },
    };
  });

export type BarChartHorizontalProps = {
  chartData: Bar[];
  showMoreThreshold?: number;
  oneline?: boolean;
  size?: 'small' | 'medium' | undefined;
  bandWidth?: number;
  borderRadius?: number;
};

const BarChartHorizontal: React.FC<BarChartHorizontalProps> = ({
  chartData,
  showMoreThreshold = 5,
  oneline = false,
  size = 'medium',
  bandWidth,
  borderRadius,
}) => {
  const styles = useStyles({ size, bandWidth, borderRadius })();
  const maxValue = useMemo(
    () => chartData.reduce((max, bar) => Math.max(bar.value, max), 0),
    [chartData],
  );
  const [data, moreData] = useMemo(() => {
    const sortedData = [...chartData].sort((a, b) => b.value - a.value);
    return [sortedData.slice(0, showMoreThreshold), sortedData.slice(showMoreThreshold)];
  }, [chartData, showMoreThreshold]);
  const [showMore, setShowMore] = React.useState(false);
  const noValue = chartData.every(item => item.value === 0);

  const renderDataLabelNameValueTop = useCallback(
    (data: Bar[]) => {
      if (noValue) return <Box>{t('No data')}</Box>;
      return data.map(({ value, name, color }) => {
        const widthPercent = (value / maxValue) * 100;
        const width = widthPercent ? `${widthPercent}%` : '1px';
        if (value === 0) return null;
        return (
          <div key={name} className={styles.barContainer}>
            <Tooltip placement="top" arrow title={t('{{name}}: {{value}}', { name, value })}>
              <div
                style={{
                  backgroundColor: color,
                  width,
                }}
                className={cx(styles.bar)}
              ></div>
            </Tooltip>
            <div className={styles.barName}>{name}</div>
            <div style={{ flex: 1 }}></div>
            <div style={{ flex: 0 }}>{value}</div>
          </div>
        );
      });
    },
    [maxValue, styles.bar, styles.barContainer, styles.barName],
  );

  const renderDataLabelOneline = useCallback(
    (data: Bar[]) => {
      if (noValue) return <Box>{t('No data')}</Box>;
      return data.map(({ value, name, color }) => {
        if (value === 0) return null;
        const widthPercent = (value / maxValue) * 100;
        const width = widthPercent ? `${widthPercent}%` : '1px';
        return (
          <Box
            key={name}
            className={styles.barContainer}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Tooltip placement="top" arrow title={name}>
              <div className={cx(styles.barName, styles.barNameOneline)}>{name}</div>
            </Tooltip>
            <Box
              display="flex"
              flex="1"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Tooltip placement="top" arrow title={t('{{name}}: {{value}}', { name, value })}>
                <div
                  style={{
                    backgroundColor: color,
                    width,
                  }}
                  className={cx(styles.barOneline)}
                ></div>
              </Tooltip>
              <div>{value}</div>
            </Box>
          </Box>
        );
      });
    },
    [maxValue, styles.bar, styles.barContainer, styles.barName],
  );

  const renderData = oneline ? renderDataLabelOneline : renderDataLabelNameValueTop;

  const showMoreComponent = useMemo(() => {
    return (
      <Button
        variant="text"
        size="small"
        color="primary"
        id={showMore ? 'bar_chart_show_less' : 'bar_chart_show_more'}
        onClick={() => setShowMore(prev => !prev)}
      >
        {showMore ? t('Show less') : t('Show more')}
      </Button>
    );
  }, [showMore]);

  return (
    <div className={styles.barChartRoot}>
      {renderData(data)}
      {moreData.length > 0 && showMore && renderData(moreData)}
      {moreData.length > 0 && showMoreComponent}
    </div>
  );
};

export default BarChartHorizontal;

import { IconButton } from '@clef/client-library';
import { List, ListItem, ListItemText, Popover } from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AutoSplitDialog from '../../../components/AutoSplitDialog';
import CLEF_PATH from '../../../constants/path';
import { rootElement } from '../../../utils/dom_utils';
import { useDataBrowserStyles } from '../styles';
import { TagsPage } from '@/pages/tags/TagsPage';

type MoreButtonProps = {
  onlyClasses?: boolean;
  onlyClassesAndMetadata?: boolean;
};

const MoreButton: React.FC<MoreButtonProps> = ({ onlyClasses, onlyClassesAndMetadata }) => {
  const styles = useDataBrowserStyles();

  const [isAutoSplitDialogOpen, setAutoSplitDialogOpen] = useState(false);
  const [isTagDialogOpen, setIsTagDialogOpen] = useState(false);

  return (
    <PopupState
      variant="popover"
      popupId="more-btn-popup-popover"
      data-testid="more-btn-popup-popover"
    >
      {popupState => (
        <>
          <IconButton
            id="data-browser-more-action-btn"
            className={styles.more}
            {...bindTrigger(popupState)}
          >
            <MoreHoriz />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            container={rootElement}
            disableEnforceFocus
          >
            <List component="nav">
              <ListItem
                component={Link}
                to={CLEF_PATH.data.defectBookEnhanced}
                className={styles.threeDotsPopoverListItem}
                role="menuitem"
              >
                <ListItemText primary={t('Manage Classes')} />
              </ListItem>

              <ListItem
                className={styles.threeDotsPopoverListItem}
                onClick={() => setIsTagDialogOpen(true)}
                role="menuitem"
              >
                <ListItemText primary={t('Manage Tags')} />
              </ListItem>

              {!onlyClasses && (
                <ListItem
                  component={Link}
                  to={CLEF_PATH.data.metadata}
                  className={styles.threeDotsPopoverListItem}
                  role="menuitem"
                >
                  <ListItemText primary={t('Manage Metadata')} />
                </ListItem>
              )}

              {!onlyClasses && !onlyClassesAndMetadata && (
                <ListItem
                  onClick={() => {
                    setAutoSplitDialogOpen(true);
                    popupState.close();
                  }}
                  data-testid="data-browser-auto-split"
                  className={styles.threeDotsPopoverListItem}
                  role="menuitem"
                >
                  <ListItemText primary={t('Auto Split')} />
                </ListItem>
              )}
            </List>
          </Popover>
          {isAutoSplitDialogOpen && (
            <AutoSplitDialog onClose={() => setAutoSplitDialogOpen(false)} />
          )}
          {isTagDialogOpen && (
            <TagsPage open={isTagDialogOpen} onClose={() => setIsTagDialogOpen(false)} />
          )}
        </>
      )}
    </PopupState>
  );
};

export default MoreButton;

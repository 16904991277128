import Konva from 'konva';
import { KonvaEventObject } from 'konva/types/Node';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DefectCreatorRef } from '../components/DefectCreator';

export interface UseDefectCreatorProps {
  defectName: string | undefined;
  defectColor: string | undefined;
  imageRef: React.MutableRefObject<Konva.Image | null>;
  mediaContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  enabled?: boolean;
}

export const useDefectCreator = ({
  defectName,
  defectColor,
  imageRef,
  mediaContainerRef,
  enabled = true,
}: UseDefectCreatorProps) => {
  const defectCreatorRef = useRef<DefectCreatorRef | null>(null);
  const [update, forceUpdate] = useState(false);

  const loadDefectCreatorRef = useCallback((ref: DefectCreatorRef) => {
    if (ref) {
      defectCreatorRef.current = ref;
      forceUpdate(value => !value);
    }
  }, []);

  // Auto switch to null mode if disabled
  useEffect(() => {
    if (!enabled) {
      defectCreatorRef.current = null;
      forceUpdate(value => !value);
    }
  }, [enabled]);

  const { left: containerLeft = 0, top: containerTop = 0 } =
    (enabled && mediaContainerRef.current?.getBoundingClientRect()) || {};
  const { x, y, width, height } = (enabled && imageRef.current?.getClientRect({})) || {};

  // Update the position limit
  useEffect(() => {
    // Make defect creator will be bounded inside the image
    defectCreatorRef.current?.setPositionLimit({
      xmin: containerLeft + (x ?? 0),
      xmax: containerLeft + (x ?? 0) + (width ?? 0),
      ymin: containerTop + (y ?? 0),
      ymax: containerTop + (y ?? 0) + (height ?? 0),
    });
  }, [update, containerLeft, x, containerTop, y, width, height]);

  return {
    defectCreatorRef,
    loadDefectCreatorRef,
    onAnnotationDelete: useCallback(() => {
      if (enabled) {
        // Switch to null mode after an annotation is deleted
        if (!enabled || (defectName && defectColor)) {
          defectCreatorRef.current?.setMode(null);
        } else {
          defectCreatorRef.current?.setMode('label-without-defect');
        }

        defectCreatorRef.current?.setPosition({
          x: undefined,
          y: undefined,
        });
      }
    }, [defectColor, defectName, enabled]),
    onMouseMove: useCallback(
      (event?: KonvaEventObject<MouseEvent>) => {
        // Update the position for label mode
        if (enabled && event) {
          if (defectCreatorRef.current?.mode === 'label-without-defect') {
            defectCreatorRef.current?.setPosition({
              x: event.evt.x,
              y: event.evt.y,
            });
          }
        }
      },
      [enabled],
    ),
    onMouseEnter: useCallback(() => {
      if (enabled) {
        // Switch to label-without-defect mode if the current mode is null
        if (defectCreatorRef.current?.mode === null) {
          defectCreatorRef.current?.setMode('label-without-defect');
        }
      }
    }, [enabled]),
    onMouseLeave: useCallback(() => {
      if (enabled) {
        // Switch to null mode if the current mode is label-without-defect
        if (defectCreatorRef.current?.mode === 'label-without-defect') {
          defectCreatorRef.current?.setMode(null);
        }
      }
    }, [enabled]),
  };
};

import React from 'react';
import cx from 'classnames';
import { Divider, Grid } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useHistory } from 'react-router';

import { Button, Typography } from '@clef/client-library';

import { useStyles } from './styles';
import GoogleSVG from '../../images/google.svg';
import EnterpriseSsoSVG from '../../images/enterprise-sso.svg';
import logoLandingLens from '@/images/logo_LandingLens_color.svg';
import CLEF_PATH, { SsoLoginOpener } from '../../constants/path';

export const LandingLensLogo: React.FC<{}> = () => {
  const styles = useStyles();

  return (
    <Grid container className={cx(styles.title, styles.newTitle)} alignItems="center">
      <img src={logoLandingLens} height={32} />
    </Grid>
  );
};

export interface BaseButtonProps {
  onClick?: () => void;
  className?: string;
}

export const ContinueWithGoogleButton: React.FC<Omit<BaseButtonProps, 'onClick'>> = ({
  className,
}) => {
  const styles = useStyles();

  return (
    <Button
      id="sign-up-with-google"
      data-testid="sign-up-with-google"
      variant="outlined"
      className={cx(styles.normalButton, className)}
      onClick={() => {
        const url = '/auth/google' + (location?.search || '');
        window.open(url, '_self', 'noopener,noreferrer');
      }}
    >
      <img src={GoogleSVG} width={20} height={20} className={styles.marginRight3} />

      {t('Continue with Google')}
    </Button>
  );
};

export const ContinueWithEnterpriseSsoButton: React.FC<
  {
    opener: SsoLoginOpener;
  } & Omit<BaseButtonProps, 'onClick'>
> = ({ opener, className }) => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <Button
      id="sign-up-with-enterprise-sso"
      data-testid="sign-up-with-enterprise-sso"
      variant="outlined"
      className={cx(styles.normalButton, className)}
      onClick={() => {
        const email = new URLSearchParams(history?.location?.search).get('email');
        history.push(`${CLEF_PATH.login.sso}?opener=${opener}` + (email ? `&email=${email}` : ''));
      }}
    >
      <img src={EnterpriseSsoSVG} width={20} height={20} className={styles.marginRight3} />

      {t('Continue with Enterprise SSO')}
    </Button>
  );
};

export const OrSeparator: React.FC = () => {
  const styles = useStyles();

  return (
    <Grid container alignItems="center" className={cx(styles.autoWidth, styles.marginBottom8)}>
      <Divider className={styles.orSeparatorDivider} />
      <Typography className={styles.orSeparatorText}>{t('Or')}</Typography>
      <Divider className={styles.orSeparatorDivider} />
    </Grid>
  );
};

export const PasswordGuideItem: React.FC<{ text: string; active?: boolean }> = ({
  text,
  active = false,
}) => {
  const styles = useStyles();

  return (
    <Grid container alignItems="center" className={cx(styles.autoWidth, styles.marginBottom2)}>
      <FiberManualRecordIcon
        className={cx(
          active ? styles.activePasswordGuideItemDotIcon : styles.inactivePasswordGuideItemDotIcon,
          styles.marginRight1,
        )}
      />
      <Typography variant="body1">{text}</Typography>
    </Grid>
  );
};

import React, { MutableRefObject, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Divider, Tooltip } from '@material-ui/core';
import HelpOutline from '@material-ui/icons/HelpOutline';

import { useSnackbar } from 'notistack';
import { format, formatDistance } from 'date-fns';
import { omit } from 'lodash';
import { useAtom } from 'jotai';
import { useQueryClient } from '@tanstack/react-query';

import { Button, IconButton, MediaInteractiveCanvas, Typography } from '@clef/client-library';
import CLEF_PATH from '@/constants/path';

import { useGetProjectSplitQuery, useGetSelectedProjectQuery } from '@/serverStore/projects';
import { datasetQueryKeys, useDatasetMediasQuery } from '@/serverStore/dataset';
import { refreshTaskInfo } from '@/hooks/api/useTaskApi';
import { removeMediaFromDataset } from '@/hooks/api/useDatasetApi';
import MediaMetadataPanel from '@/pages/DataBrowser/MediaDetailsDialog/MediaMetadataPanel';
import { useDialog } from '@/components/Layout/components/useDialog';
import { MediaTagPanel } from '@/pages/DataBrowser/MediaDetailsDialog/MediaTagPanel';
import { CopyIcon, DownloadIcon, RemoveIcon } from '@/images/media_details/ToolIcons';
import {
  annotationInstanceAtom,
  datasetSearchParamsAtom,
  mediaStatesMapAtom,
  useCurrentMediaStates,
} from '@/uiStates/mediaDetails/pageUIStates';

import LabelListWrapper from './LabelListWrapper';
import SplitInfo from './SplitInfo';
import useStyles from './styles';

type InformationTabProps = {
  mediaCanvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
};

const InformationTab: React.FC<InformationTabProps> = ({ mediaCanvasRef }) => {
  const history = useHistory();
  const styles = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [datasetSearchParams] = useAtom(datasetSearchParamsAtom);
  const [annotationInstance] = useAtom(annotationInstanceAtom);
  const [mediaStatesMap, setMediaStatesMap] = useAtom(mediaStatesMapAtom);

  const { id: projectId, datasetId } = useGetSelectedProjectQuery().data ?? {};
  const { data: projectSplits = [] } = useGetProjectSplitQuery();

  const { data: datasetMedias = [] } = useDatasetMediasQuery(
    {
      sortOptions: datasetSearchParams.sortOptions,
      columnFilterMap: datasetSearchParams.columnFilterMap,
      metadataFilterMap: datasetSearchParams.metadataFilterMap,
      includeMediaStatus: true, // task creation needs here
    },
    datasetSearchParams && !annotationInstance,
  );
  const queryClient = useQueryClient();

  const currentMediaStates = useCurrentMediaStates();
  const { mediaDetails } = currentMediaStates;
  const currentMediaId = mediaDetails?.id;

  const { showConfirmationDialog } = useDialog();

  const removeMedia = useCallback(() => {
    showConfirmationDialog({
      title: 'Confirm removal',
      content: 'Are you sure you want to remove this media from dataset?',
      confirmText: 'Confirm',
      color: 'primary',
      onConfirm: async () => {
        if (!projectId || !datasetId) return;
        try {
          const currentId = mediaDetails!.id;
          const currentIndex = datasetMedias.findIndex(media => media.id === currentId);
          const nextId =
            currentIndex === datasetMedias.length - 1
              ? datasetMedias[0].id
              : datasetMedias[currentIndex + 1].id;
          const { deletedMediasCount } = await removeMediaFromDataset(projectId, datasetId, {
            selectedMedia: [currentId],
            unselectedMedia: [],
            isUnselectMode: false,
            fieldFilterMap: {},
            columnFilterMap: {},
          });
          refreshTaskInfo({
            keys: 'refresh-all',
          });
          if (datasetMedias.length === 1) {
            history.push(CLEF_PATH.data.dataBrowser);
          } else {
            history.push(`${CLEF_PATH.data.mediaDetails}/${nextId}${window.location.search}`);
          }
          if (deletedMediasCount === 1) {
            enqueueSnackbar('Media removed successfully', { variant: 'success' });
            queryClient.invalidateQueries(datasetQueryKeys.medias(projectId));

            const newMediaStatesMap = omit(mediaStatesMap, currentId);
            setMediaStatesMap(newMediaStatesMap);
          } else {
            enqueueSnackbar('Media not removed', { variant: 'error' });
          }
        } catch (err) {
          if (err.status === 403) {
            enqueueSnackbar('Cannot modify sample project', { variant: 'error' });
          } else {
            enqueueSnackbar('Failed to remove media', { variant: 'error' });
          }
        }
      },
    });
  }, [
    showConfirmationDialog,
    projectId,
    datasetId,
    mediaDetails,
    datasetMedias,
    history,
    enqueueSnackbar,
    queryClient,
    mediaStatesMap,
    setMediaStatesMap,
  ]);

  if (!currentMediaId) {
    return null;
  }

  return (
    <>
      <Box className={styles.section}>
        <Box className={styles.infoMain}>
          <Box className={styles.mediaName}>
            <Typography maxWidth={150}>{currentMediaStates?.mediaDetails?.name || ''}</Typography>
            <IconButton
              id="copy-media-name"
              // className={styles.iconButton}
              tooltip={t('Copy image name')}
              onClick={() => {
                navigator.clipboard.writeText(currentMediaStates?.mediaDetails?.name || '');
                enqueueSnackbar('Media name copied to clipboard.', { variant: 'success' });
              }}
            >
              <CopyIcon />
            </IconButton>
          </Box>
          <Box className={styles.operation}>
            {/*
              Ideally we can add `download` attribute to force download instead of preview image in new tab,
              but our media is in s3 and download attribute does not support cross origin urls.
              If we really need force download, we should make media url and our website in the same domain.
              https://stackoverflow.com/questions/17527713/force-browser-to-download-image-files-on-click/17527821
            */}
            <Button
              id="download-media"
              variant="contained"
              // tooltip={t('Download image')}
              component="a"
              href={mediaDetails?.url}
              target="_blank"
            >
              <DownloadIcon />
            </Button>
            <Button
              id="remove-media"
              className={styles.removeBtn}
              onClick={removeMedia}
              tooltip={t('Delete image')}
            >
              <RemoveIcon />
            </Button>
          </Box>
        </Box>
        <Typography variant="body_bold" className={styles.sectionTitle}>
          {t('General')}
        </Typography>
        <Box className={styles.sectionContent}>
          <Box className={styles.sectionContentLabel}>{t('ID')}</Box>
          <Box>{mediaDetails?.id}</Box>
        </Box>
        <Box className={styles.sectionContent}>
          <Box className={styles.sectionContentLabel}>{t('Dimensions')}</Box>
          <Box>
            {mediaDetails?.properties ? t('{{width}} x {{height}}', mediaDetails.properties) : null}
          </Box>
        </Box>
        <Box className={styles.sectionContent}>
          <Box className={styles.sectionContentLabel}>{t('Uploaded')}</Box>
          <Tooltip
            placement="top"
            arrow
            title={
              !!mediaDetails?.uploadTime &&
              format(new Date(mediaDetails.uploadTime), 'yyyy-MM-dd hh:mm:ss')
            }
          >
            <Box>
              {mediaDetails?.uploadTime
                ? formatDistance(new Date(mediaDetails.uploadTime), new Date(), {
                    addSuffix: true,
                  })
                : ''}
            </Box>
          </Tooltip>
        </Box>
        {/* Split for projects with projectSplit */}
        {projectSplits.length > 0 && (
          <Box className={styles.sectionContent}>
            <Box className={styles.sectionContentLabel}>
              {t('Split')}
              <Tooltip
                placement="top"
                interactive
                arrow
                title={t(
                  'LandingLens automatically splits your images during Model Training. If you prefer, you can manually split images, and your manual splits will be respected in the next training session. {{link}}',
                  {
                    link: (
                      <Typography
                        style={{
                          display: 'inline',
                          cursor: 'pointer',
                          marginLeft: 4,
                          textDecoration: 'underline',
                          fontSize: '12px',
                        }}
                        onClick={() => {
                          window.open(
                            'https://support.landing.ai/docs/split-images-into-the-train-dev-and-test-sets-video',
                            '_blank',
                          );
                        }}
                      >
                        {t('Learn More')}
                      </Typography>
                    ),
                  },
                )}
              >
                <HelpOutline fontSize="small" />
              </Tooltip>
            </Box>
            <Box marginLeft={32} flexGrow={1}>
              <SplitInfo
                split={mediaDetails?.split}
                mediaId={mediaDetails?.id}
                projectSplits={projectSplits}
              />
            </Box>
          </Box>
        )}
      </Box>

      <Divider orientation="horizontal" />
      <Box className={styles.section}>
        <Typography variant="body_bold" className={styles.sectionTitle}>
          {t('Tags')}
        </Typography>
        {/* intentionally add div here because we don't need flex layout */}
        <div style={{ width: '100%' }}>
          <MediaTagPanel mediaId={currentMediaId} tagIds={mediaDetails.tagIds || []} />
        </div>
      </Box>

      <Divider orientation="horizontal" />
      <Box className={styles.section}>
        <Typography variant="body_bold" className={styles.sectionTitle}>
          {t('Metadata')}
        </Typography>
        {/* intentionally add div here because we don't need flex layout */}
        <div style={{ width: '100%' }}>
          {/* TODO: (minyuMao) change UI for the add metadata button */}
          <MediaMetadataPanel
            mediaId={currentMediaId}
            mediaMetadata={mediaDetails.metadata ?? {}}
          />
        </div>
      </Box>

      <LabelListWrapper mediaCanvasRef={mediaCanvasRef} />
    </>
  );
};

export default InformationTab;

import React from 'react';

export const ViewSideBarIcon: React.FC<{ color?: string }> = ({ color = 'inherit' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_633_1380"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_633_1380)">
        <path
          d="M2 20V4H22V20H2ZM17.5 8.675H20V6H17.5V8.675ZM17.5 13.325H20V10.675H17.5V13.325ZM4 18H15.5V6H4V18ZM17.5 18H20V15.325H17.5V18Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

import React from 'react';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import Fullscreen from '@material-ui/icons/Fullscreen';
import { useInstantLearningState } from './state';
import {
  useCurrentMediaStates,
  useGoToNextMedia,
  useGoToPreviousMedia,
  useImageLabelingContext,
  useMediaList,
} from '../../components/Labeling/imageLabelingContext';
import MediaViewerWrapper from './MediaViewerWrapper';
import { useKeyPress } from '@clef/client-library';

const useStyles = makeStyles(theme => ({
  browseImagesSideBarContainer: {
    height: '100%',
    overflow: 'auto',
  },
  fullscreenButton: {
    position: 'absolute',
    bottom: 12,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 48,
    height: 48,
    zIndex: 1,
    fontSize: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 300ms',
    backgroundColor: 'white',
    boxShadow: '0 0 12px rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[200],
    },
    '&:active': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  mediaList: {
    padding: theme.spacing(6, 0, 8, 0),
  },
}));

export type BrowseImagesSideBarProps = {};

const BrowseImagesSideBar: React.FC<BrowseImagesSideBarProps> = () => {
  const styles = useStyles();
  const { dispatch } = useInstantLearningState();
  const mediaList = useMediaList();
  const { mediaDetails } = useCurrentMediaStates();
  const currentMediaId = mediaDetails?.id;
  const { dispatch: dispatchImageLabelingState } = useImageLabelingContext();
  const goToNextMedia = useGoToNextMedia();
  const goToPrevMedia = useGoToPreviousMedia();
  useKeyPress('down', e => {
    e.preventDefault();
    goToNextMedia();
  });
  useKeyPress('up', e => {
    e.preventDefault();
    goToPrevMedia();
  });
  return (
    <Box
      className={styles.browseImagesSideBarContainer}
      onKeyDown={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {mediaList && (
        <Box
          data-testid="image-list"
          display="flex"
          flexDirection="column"
          alignItems="center"
          overflow="auto"
          className={styles.mediaList}
        >
          {mediaList.map(media => (
            <MediaViewerWrapper
              media={media}
              key={media.id}
              selected={media.id === currentMediaId}
              onClick={() => {
                const mediaIndex = mediaList.findIndex(m => m.id === media.id);
                if (mediaIndex !== -1) {
                  dispatchImageLabelingState(draft => {
                    draft.mediaIndex = mediaIndex;
                  });
                }
              }}
            />
          ))}
        </Box>
      )}
      {/* full screen button */}
      <Tooltip placement="top" arrow title={t('View all images in full screen')}>
        <Box
          className={styles.fullscreenButton}
          id="browse-images-full-screen-button"
          role="button"
          onClick={() =>
            dispatch(draft => {
              draft.browseImagesMode = 'fullscreen';
            })
          }
        >
          <Fullscreen fontSize="inherit" />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default BrowseImagesSideBar;

import React, { useState, useRef } from 'react';
import {
  Button,
  calcZoomScaleRange,
  getDisplayZoomValue,
  StepZoomRatio,
  useKeyPress,
  ZoomScale,
} from '@clef/client-library';
import { Box, MenuList, MenuItem, Popper, Grow, Paper, ClickAwayListener } from '@material-ui/core';
import KeyboardArrowDownRounded from '@material-ui/icons/KeyboardArrowDownRounded';
import { useCallback } from 'react';
import { HintEvents, useHintSnackbar } from '@/components/Labeling/HintSnackbar';
import { TutorialStep, useLabelingState } from '@/components/Labeling/labelingState';

import useStyles from './styles';

export interface ToolImageEnhanceProps {
  setZoomScale: (newZoomScale: ZoomScale) => void;
}

const ZoomTool: React.FC<ToolImageEnhanceProps> = ({ setZoomScale }) => {
  const styles = useStyles();
  const {
    state: {
      toolOptions: { zoomScale, fitZoomScale },
    },
    dispatch,
  } = useLabelingState();

  const triggerHint = useHintSnackbar();

  const { minZoomScale, maxZoomScale } = calcZoomScaleRange(fitZoomScale);
  const zoomScaleNumber = zoomScale === 'fit' ? fitZoomScale : zoomScale;

  const zoomIn = useCallback(() => {
    if (zoomScaleNumber >= maxZoomScale) {
      triggerHint(HintEvents.NoMoreZoomIn);
    } else {
      setZoomScale(Math.min(zoomScaleNumber * StepZoomRatio, maxZoomScale));
      triggerHint();
    }
  }, [maxZoomScale, setZoomScale, triggerHint, zoomScaleNumber]);

  const zoomOut = useCallback(() => {
    if (zoomScaleNumber <= minZoomScale) {
      triggerHint(HintEvents.NoMoreZoomOut);
    } else {
      setZoomScale(Math.max(zoomScaleNumber / StepZoomRatio, minZoomScale));
      triggerHint();
    }
  }, [minZoomScale, setZoomScale, triggerHint, zoomScaleNumber]);

  useKeyPress('=', zoomIn, { id: 'task-zoom-in' });
  useKeyPress('-', zoomOut, { id: 'task-zoom-out' });
  useKeyPress('0', () => setZoomScale('fit'), { id: 'task-zoom-fit' });
  useKeyPress('1', () => setZoomScale(1), { id: 'task-zoom-100' });

  const [openZoomOptions, setOpenZoomOptions] = useState(false);
  const toggleZoomOptions = useCallback(
    (value: boolean) => {
      setOpenZoomOptions(value);
      dispatch(draft => {
        draft.anyMenuOpened = value;
      });
    },
    [dispatch],
  );

  const zoomOptionAnchorRef = useRef<HTMLButtonElement>();

  const autoFitText = t('{{fitZoomScale}} (Fit)', {
    fitZoomScale: getDisplayZoomValue(fitZoomScale),
  });
  const displayZoomValue = zoomScale === 'fit' ? autoFitText : getDisplayZoomValue(zoomScaleNumber);

  return (
    <Box>
      {/* Zoom level picker */}
      <Button
        ref={ref => {
          if (ref) {
            zoomOptionAnchorRef.current = ref;
            dispatch(draft => {
              // having type issues putting HTMLElement into state, so cast to any
              draft.tutorialAnchorElementsMap[TutorialStep.Zoom] = ref as any;
            });
          }
        }}
        variant="outlined"
        className={styles.zoomBtn}
        id="task-zoom-value-button"
        endIcon={<KeyboardArrowDownRounded fontSize="small" />}
        onClick={() => {
          toggleZoomOptions(true);
        }}
        aria-controls={openZoomOptions ? 'split-button-menu' : undefined}
        aria-expanded={openZoomOptions ? 'true' : undefined}
      >
        {displayZoomValue}
      </Button>
      <Popper
        open={openZoomOptions}
        anchorEl={zoomOptionAnchorRef.current}
        role={undefined}
        transition
        className={styles.zoomOptions}
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener
                onClickAway={() => {
                  toggleZoomOptions(false);
                }}
              >
                <MenuList autoFocusItem>
                  <MenuItem key="ZoomIn" onClick={zoomIn}>
                    <Box className={styles.toolItem}>
                      <span>{t('Zoom in')}</span>
                      <span className={styles.codeBlock}>=</span>
                    </Box>
                  </MenuItem>
                  <MenuItem key="ZoomOut" onClick={zoomOut}>
                    <Box className={styles.toolItem}>
                      <span>{t('Zoom out')}</span>
                      <span className={styles.codeBlock}>-</span>
                    </Box>
                  </MenuItem>
                  <MenuItem
                    key="AutoFit"
                    onClick={() => {
                      setZoomScale('fit');
                      toggleZoomOptions(false);
                    }}
                  >
                    <Box className={styles.toolItem}>
                      <span>
                        {t(`Zoom to `)}
                        {autoFitText}
                      </span>
                      <span className={styles.codeBlock}>0</span>
                    </Box>
                  </MenuItem>
                  <MenuItem
                    key="OriginalSize"
                    onClick={() => {
                      setZoomScale(1);
                      toggleZoomOptions(false);
                    }}
                  >
                    <Box className={styles.toolItem}>
                      <span>{t('Zoom to 100%')}</span>
                      <span className={styles.codeBlock}>1</span>
                    </Box>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default ZoomTool;

import React, { useCallback } from 'react';
import { useHistory, withRouter } from 'react-router';
import { CircularProgress, Typography, withStyles } from '@material-ui/core';
import { Button } from '@clef/client-library';
import styles from '../styles';
import { passwordRegex } from '../../../constants/regex';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { ForgotPasswordTabProps } from '../types';
import FormStatus from './FormStatus';
import { useAppDispatch } from '../../../store';
import AuthAPI from '../../../api/auth_api';
import { useSnackbar } from 'notistack';
import { loginFailure, passwordResetSuccess } from '../../../store/newLoginState/actions';

const ForgotPasswordTab: React.SFC<ForgotPasswordTabProps> = ({
  classes,
  isCodeRequested,
  ...props
}) => {
  const { verificationCode, emailValue, passwordValue, ...formProps } = props;
  const stage = Number(isCodeRequested);
  const submitFormText = [t('Send Verification code'), t('Reset Password')][stage];
  const submitFormAction = [props.handleRequestVerificationCode, props.handleResetPassword][stage];
  const isSubmitDisabled = [
    Boolean(emailValue.length === 0),
    Boolean(
      isCodeRequested &&
        ((verificationCode && verificationCode.length === 0) ||
          (passwordValue && passwordValue.length === 0)),
    ),
  ][stage];
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleReturn = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter') {
      submitFormAction();
      e.preventDefault();
    }
  };

  const onSubmitResetPassword = useCallback(async () => {
    try {
      await AuthAPI.confirmPassword({
        username: emailValue,
        verificationCode: verificationCode,
        newPassword: passwordValue,
      });
      dispatch(passwordResetSuccess());
      enqueueSnackbar(t('Password reset successful'), {
        variant: 'success',
      });
      history.push('/legacy_login');
      // eslint-disable-next-line no-empty
    } catch (err) {
      dispatch(loginFailure(err));
    }
  }, [dispatch, emailValue, enqueueSnackbar, history, passwordValue, verificationCode]);

  return (
    <ValidatorForm
      onError={() => {
        // TODO: Fix eslint - Jay
        // no-console
        // console.log(err)
      }}
      onSubmit={stage === 0 ? props.handleRequestVerificationCode : onSubmitResetPassword}
    >
      <Typography variant="h1" className={classes.greeting}>
        {t('Enter details to Reset Password')}
      </Typography>
      <FormStatus {...formProps} classes={classes} />
      <TextValidator
        name="email"
        type="email"
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField,
          },
        }}
        fullWidth
        margin="normal"
        placeholder={t('Email Address')}
        label={t('Email Address')}
        value={emailValue}
        disabled={isCodeRequested && Boolean(props.success)}
        onKeyDown={handleReturn}
        onChange={(e: React.ChangeEvent): void => props.handleInput(e)}
        validators={['required', 'isEmail']}
        errorMessages={['Required field', 'Enter a valid email']}
      />
      {isCodeRequested && (
        <>
          <TextValidator
            name="verificationCode"
            type="text"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            fullWidth
            margin="normal"
            placeholder={t('Verification code')}
            label={t('Verification code')}
            value={verificationCode}
            onChange={(e: React.ChangeEvent): void => props.handleInput(e)}
            validators={['required', 'isNumber']}
            errorMessages={['Required field', 'Numbers only']}
          />
          <TextValidator
            name="password"
            type="password"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            fullWidth
            margin="normal"
            placeholder={t('Password')}
            label={t('Password')}
            value={passwordValue}
            onChange={(e: React.ChangeEvent): void => props.handleInput(e)}
            onKeyDown={handleReturn}
            validators={['required', `matchRegexp:${passwordRegex.exp}`]}
            errorMessages={['Required Field', passwordRegex.err]}
          />
        </>
      )}
      <div className={classes.formButtons}>
        {props.isLoading ? (
          <CircularProgress size={26} className={classes.loginLoader} />
        ) : (
          <Button
            id="submit-forget-password-form"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            disabled={isSubmitDisabled}
            type="submit"
          >
            {submitFormText}
          </Button>
        )}
      </div>
    </ValidatorForm>
  );
};

export default withStyles(styles)(withRouter(ForgotPasswordTab));

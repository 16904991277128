import React, { createContext, useState, useContext, useCallback, useEffect, useMemo } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { Button, IconButton } from '@clef/client-library';
import { CacheStorage, CacheStorageKey, isMacOS, useKeyPress } from '@clef/client-library';
import { useLabelingStyles } from './labelingStyles';

export enum HintEvents {
  MissingDefect = 'MissingDefect',
  PolylineInstructions = 'PolylineInstructions',
  PolygonInstructions = 'PolygonInstructions',
  SelectInstructions = 'SelectInstructions',
  NoMoreUndo = 'NoMoreUndo',
  NoMoreRedo = 'NoMoreRedo',
  LabelInteractions = 'LabelInteractions',
  NoMoreZoomIn = 'NoMoreZoomIn',
  NoMoreZoomOut = 'NoMoreZoomOut',
}

export type LabelUploadFailedPayload = {
  failedCount: number;
};
export type LabelUploadSucceededPayload = {
  succeededCount: number;
};
export type HintEventPayload = LabelUploadFailedPayload | LabelUploadSucceededPayload | undefined;

export interface HintSnackbarProps {
  event?: HintEvents;
  payload?: HintEventPayload;
}

const LabelingToolHintDisableCache = new CacheStorage<{ [key in HintEvents]?: boolean }>({
  key: CacheStorageKey.LabelingToolHint,
  persist: true,
  defaultValue: {},
});

const HintSnackbar: React.FC<HintSnackbarProps> = ({ event, payload }) => {
  const styles = useLabelingStyles();
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  const hintEventsMessage: { [event in HintEvents]: React.ReactNode } = useMemo(
    () => ({
      [HintEvents.MissingDefect]: t(
        'Please pick a class before start labeling, you can use the hotkey {{hotKey}}',
        { hotKey: <span className={styles.codeBlock}>c</span> },
      ),
      [HintEvents.PolygonInstructions]: t(
        '{{click}} to draw straight edges or {{hold}} to draw curved edges.',
        {
          click: <span className={styles.codeBlock}>{t('click')}</span>,
          hold: <span className={styles.codeBlock}>{t('hold down')}</span>,
        },
      ),
      [HintEvents.PolylineInstructions]: (
        <>
          <div className={styles.marginBottom2}>
            {t('{{leftClick}} - Add points / {{rightClick}} or {{hotKey}} - Remove last point', {
              leftClick: <span className={styles.codeBlock}>{t('left click')}</span>,
              rightClick: <span className={styles.codeBlock}>{t('right click')}</span>,
              hotKey: (
                <span className={styles.codeBlock}>{isMacOS() ? 'cmd + z' : 'ctrl + z'}</span>
              ),
            })}
          </div>
          <div className={styles.marginBottom2}>
            {t('{{enter}} - Finish polyline / {{esc}} - Discard polyline', {
              enter: <span className={styles.codeBlock}>enter</span>,
              esc: <span className={styles.codeBlock}>esc</span>,
            })}
          </div>
        </>
      ),
      [HintEvents.SelectInstructions]: (
        <>
          <div className={styles.marginBottom2}>
            {t('{{backspace}} or {{delete}} - remove the selected instance', {
              backspace: <span className={styles.codeBlock}>{t('backspace')}</span>,
              delete: <span className={styles.codeBlock}>{t('delete')}</span>,
            })}
          </div>
          <div className={styles.marginBottom2}>
            {t('Hold {{shift}} - resize with original ratio', {
              shift: <span className={styles.codeBlock}>{t('shift')}</span>,
            })}
          </div>
        </>
      ),
      [HintEvents.NoMoreUndo]: t('There are no more actions to undo'),
      [HintEvents.NoMoreRedo]: t('There are no more actions to redo'),
      [HintEvents.LabelInteractions]: (
        <>
          <div className={styles.marginBottom2}>
            {t('Undo / Redo labels - {{undoKey}} / {{redoKEy}}', {
              undoKey: (
                <span className={styles.codeBlock}>{isMacOS() ? 'cmd + z' : 'ctrl + z'}</span>
              ),
              redoKEy: (
                <span className={styles.codeBlock}>
                  {isMacOS() ? 'shift + cmd + z' : 'shift + ctrl + z'}
                </span>
              ),
            })}
          </div>
          <div>
            {t('Hide labels - hover over the eye icon or hold {{hotKey}}', {
              hotKey: <span className={styles.codeBlock}>h</span>,
            })}
          </div>
        </>
      ),
      [HintEvents.NoMoreZoomIn]: t('This is the maximum zoom level'),
      [HintEvents.NoMoreZoomOut]: t('This is the minimum zoom level'),
    }),
    [styles],
  );

  useEffect(() => {
    // it is intentional that we don't show the same message twice in a row
    if (event && !LabelingToolHintDisableCache.get()?.[event]) {
      setOpenSnackBar(true);
    } else {
      setOpenSnackBar(false);
    }
    // intentionally add `payload` here to trigger the effect when changed
  }, [event, payload]);

  useKeyPress('esc', () => {
    setOpenSnackBar(false);
  });

  return (
    <>
      {event && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openSnackBar}
          classes={{
            anchorOriginTopRight: styles.snackBarPosition,
          }}
        >
          <SnackbarContent
            message={
              <>
                {hintEventsMessage[event]}
                <Button
                  id="hide-hint-snackbar-button"
                  className={styles.neverShowMessage}
                  size="small"
                  onClick={() => {
                    setOpenSnackBar(false);
                    LabelingToolHintDisableCache.set({
                      ...LabelingToolHintDisableCache.get(),
                      [event]: true,
                    });
                  }}
                >
                  {t('Never Show This Again')}
                </Button>
              </>
            }
            action={
              <IconButton
                size="small"
                aria-label="close-hint"
                color="inherit"
                onClick={() => {
                  setOpenSnackBar(false);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
            classes={{
              message: styles.message,
              root: styles.snackBarRoot,
              action: styles.action,
            }}
          />
        </Snackbar>
      )}
    </>
  );
};

type HintSnackbarContextProps = (event?: HintEvents, payload?: HintEventPayload) => void;

const HintSnackbarContext = createContext<HintSnackbarContextProps>(() => {
  throw Error('<HintSnackbarContext.Provider /> not found!');
});

export const useHintSnackbar = (): HintSnackbarContextProps => useContext(HintSnackbarContext);

export const HintSnackbarContextProvider: React.FC = ({ children }) => {
  const [event, setEvent] = useState<HintEvents>();
  const [payload, setPayload] = useState<HintEventPayload>();
  const showHint = useCallback((newEvent?: HintEvents, payload?: HintEventPayload) => {
    setEvent(newEvent);
    setPayload(payload);
  }, []);
  return (
    <HintSnackbarContext.Provider value={showHint}>
      {children}
      <HintSnackbar event={event} payload={payload} />
    </HintSnackbarContext.Provider>
  );
};

import React from 'react';
import { Tooltip } from '@material-ui/core';
import Cancel from '@material-ui/icons/Cancel';
import { useAtom } from 'jotai';

import { Button, Dropdown, SimpleBadge } from '@clef/client-library';
import { FilterOptionType } from '@clef/shared/types';

import FilterSvg from '@/images/data-browser/filter.svg';
import { useGetDatasetFilterOptionsQuery } from '@/serverStore/dataset';
import { appliedFiltersAtom } from '@/uiStates/mediaFilter';

import MediaFilterDropDown from './FilterDropDown';
import useStyles from './styles';

type MediaFilterProps = {
  filterMappingKey?: string | number;
};

export const MediaFilter: React.FC<MediaFilterProps> = ({ filterMappingKey = 'live' }) => {
  const styles = useStyles();

  const { data: allFilters } = useGetDatasetFilterOptionsQuery(
    filterMappingKey !== 'live' ? (filterMappingKey as number) : undefined,
  );

  const [appliedFilters, setAppliedFilters] = useAtom(appliedFiltersAtom);

  const appliedFilterCount = Object.keys(appliedFilters[filterMappingKey] ?? {}).filter(key => {
    const value = (appliedFilters[filterMappingKey] ?? {})[key]?.v;
    return Array.isArray(value) ? value.length : value;
  }).length;

  if (!allFilters?.length) {
    return null;
  }

  const [defaultFilters, moreFilters] = allFilters.reduce(
    (acc, filterOption) => {
      acc[filterOption.displayDefault ? 0 : 1].push(filterOption);
      return acc;
    },
    [[], []] as [FilterOptionType[], FilterOptionType[]],
  );
  const FiltersDropdownFilters: (FilterOptionType | 'divider')[] =
    defaultFilters.length === 0 ? [...moreFilters] : [...defaultFilters, 'divider', ...moreFilters];

  return (
    <Dropdown
      extraGutter={{ vertical: 12 }}
      dropdown={
        <MediaFilterDropDown filters={FiltersDropdownFilters} filterMappingKey={filterMappingKey} />
      }
    >
      <Button
        id="open-media-filter-button"
        color="primary"
        variant="outlined"
        className={styles.toolbarButton}
        startIcon={
          appliedFilterCount ? (
            <SimpleBadge color="primary" content={appliedFilterCount} />
          ) : (
            <img src={FilterSvg} />
          )
        }
        endIcon={
          !!appliedFilterCount && (
            <Tooltip title={t('Clear filters')} arrow placement="top">
              <Cancel
                className={styles.clearFiltersIcon}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  setAppliedFilters(prev => ({
                    ...prev,
                    [filterMappingKey]: {},
                  }));
                }}
              />
            </Tooltip>
          )
        }
      >
        {t('Filter')}
      </Button>
    </Dropdown>
  );
};

import { BaseAPI } from './base_api';
import {
  EdgeMedia,
  PaginationOptions,
  EdgeMediaDetails,
  EdgeMediaFilterOption,
  SortFieldInfo,
  ProjectId,
  DeviceId,
  DateString,
  ReportKind,
  Report,
  MediaLevelLabel,
} from '@clef/shared/types';
import { API_GATEWAY_URL } from '../constants';
import { getBrowserTimezone } from '@/utils';

export const prefix = 'clia';

/**
 * CLIA - CL inference analyzer
 * Responsiblities:
 * - handling artifact uploads from edge devices and analyzing inference results
 */
class CLIAAPI extends BaseAPI {
  async getDeviceReport(
    deviceId: DeviceId,
    edgeProjectId: ProjectId,
    from: DateString,
    to: DateString,
    reportKind: ReportKind,
  ): Promise<Report> {
    const response_timezone = getBrowserTimezone();
    return this.get(
      `v1/report`,
      {
        device_id: deviceId,
        project_id: edgeProjectId,
        from: from,
        to: to,
        kind: reportKind,
        ...(!!response_timezone && { response_timezone }),
      },
      true,
    );
  }

  async getMediaList(
    projectId: number,
    deviceId: string,
    paginationOptions: PaginationOptions,
    filterOptions: EdgeMediaFilterOption[],
    sortOptions: Pick<SortFieldInfo, 'sortType' | 'sortValue'>,
  ): Promise<EdgeMedia[]> {
    return this.get(
      'v1/medias',
      {
        projectId,
        deviceId,
        paginationOptions: JSON.stringify(paginationOptions),
        filterOptions: JSON.stringify(filterOptions),
        sortOptions: JSON.stringify(sortOptions),
      },
      // dataOnly
      true,
    );
  }

  async getMediaDetails(projectId: number, mediaId: number): Promise<EdgeMediaDetails> {
    return this.get(
      'v1/media_details',
      { projectId, mediaId },
      // dataOnly
      true,
    );
  }

  async getTotalMediaCount(
    projectId: number,
    deviceId: string,
    filterOptions: EdgeMediaFilterOption[],
  ): Promise<number> {
    return this.get(
      'v1/total_media_count',
      { projectId, deviceId, filterOptions: JSON.stringify(filterOptions) },
      // dataOnly
      true,
    );
  }

  async updateMedias(
    body: {
      mediaId: number;
      dmMediaId: number;
    }[],
  ) {
    return this.post('v1/media_uploaded', JSON.stringify(body));
  }

  async saveJudgement(params: {
    mediaId: number;
    inspectorId: string;
    judgement: MediaLevelLabel;
    mode: string;
    comments: string;
  }) {
    const { mediaId, ...judgementJSON } = params;

    return this.postJSON(`v1/medias/${mediaId}/humanjudgement`, {
      ...judgementJSON,
    });
  }
}
export default new CLIAAPI(prefix, API_GATEWAY_URL);

import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import {
  Grid,
  makeStyles,
  DialogContent,
  Dialog,
  Box,
  TextField,
  ClickAwayListener,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Divider,
} from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { groupBy, includes, startCase, camelCase, isEmpty } from 'lodash';
import { LazyLog } from 'react-lazylog';
import * as yaml from 'js-yaml';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atelierSulphurpoolLight } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

import { ApiResponseLoader, Typography } from '@clef/client-library';
import { JobMetricData, MetricLabel, ModelStatus } from '@clef/shared/types';

import { useGetProjectModelListQuery, useSaveModelMutation } from '@/serverStore/projectModels';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useMaglevGetJobLogsApi } from '../../../hooks/api/useMaglevApi';
import { useFetchUsersForProject } from '../../../hooks/api/useProjectApi';
import { getDateNumber } from '../../../utils/time_utils';
import MetricsChart from '../../model_iteration/MetricsChart';
import { useJobDetailForCurrentProject } from '@/serverStore/jobs';
import { useIsFeatureEnabledAndMayHideForSnowflake } from '@/hooks/useFeatureGate';

const DISPLAY_LEARNING_CURVE_METRICS = [
  MetricLabel.loss_train,
  MetricLabel.loss_val,
  MetricLabel.mean_iou_train,
  MetricLabel.mean_iou_val,
  MetricLabel.old_mean_iou_train,
  MetricLabel.old_mean_iou_val,
];

const DISPLAY_SUMMARY_EVAL_METRICS = [MetricLabel.mAP, MetricLabel.AUC, MetricLabel.mIOU];

const isEvalMetric = (metric: JobMetricData) => {
  return metric.values.length == 1 && includes(DISPLAY_SUMMARY_EVAL_METRICS, metric.name);
};

const MODEL_STATUS_LOOKUP: Record<string, string> = {
  [ModelStatus.Succeed]: 'Successful',
};

const useStyles = makeStyles(theme => ({
  container: {
    width: 900,
    overflowY: 'auto',
    minHeight: 600,
  },
  dialogTitle: {
    display: 'flex',
    margin: theme.spacing(2, 4, 0),
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(0, 4, 7),
  },
  modelNameInput: {
    width: 400,
    marginBottom: theme.spacing(5),
  },
  saveModelButton: {
    width: 140,
  },
  accordion: {
    width: '100%',
  },
  accordionDetails: {
    display: 'flex',
    width: '100%',
  },
  divider: {
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
  },
  grow: {
    flex: 1,
  },
  trainingInfoItemContainer: {
    marginRight: theme.spacing(2),
  },
  trainingInfoItemTitle: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  trainingInfoItemContent: {
    marginRight: theme.spacing(2),
    background: '#F4F8FE',
    padding: theme.spacing(2, 3),
    width: 200,
    borderRadius: 6,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  modelNameContainer: {
    marginBottom: theme.spacing(5),
  },
  modelNameText: {
    marginRight: theme.spacing(2),
  },
}));

const TrainingInfoItem: React.FC<{
  title: string;
  content: string;
}> = ({ title, content }) => {
  const styles = useStyles();

  return (
    <Grid alignItems="center" direction="column" className={styles.trainingInfoItemContainer}>
      <Typography variant="subtitle1" className={styles.trainingInfoItemTitle}>
        {title}
      </Typography>
      <Typography className={styles.trainingInfoItemContent}>{content}</Typography>
    </Grid>
  );
};

const AccordionWrapper: React.FC<{ title: string }> = ({ title, children }) => {
  const styles = useStyles();

  return (
    <>
      <Accordion
        defaultExpanded
        classes={{
          root: styles.accordion,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <strong>{title}</strong>
        </AccordionSummary>

        <AccordionDetails
          classes={{
            root: styles.accordionDetails,
          }}
        >
          {children}
        </AccordionDetails>
      </Accordion>

      <Divider light className={styles.divider} />
    </>
  );
};

export interface ModelDetailsDialogProps {
  open: boolean;
  onClose(): void;
  modelId: string | undefined;
}

export const ModelDetailsDialog: React.FC<ModelDetailsDialogProps> = ({
  open,
  onClose,
  modelId,
}) => {
  const styles = useStyles();

  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};

  const [isEditing, setIsEditing] = useState(false);
  const [modelName, setModelName] = useState('');
  const [modelNameError, setModelNameError] = useState('');
  const previousModelNameRef = useRef('');

  const { data: projectModelsData } = useGetProjectModelListQuery();

  const {
    data: modelDetailsData,
    isLoading: modelDetailsLoading,
    error: modelDetailsError,
  } = useJobDetailForCurrentProject(modelId);
  const saveModel = useSaveModelMutation();
  const [users] = useFetchUsersForProject(projectId);
  const isTrainingLogEnabled = useIsFeatureEnabledAndMayHideForSnowflake().trainingLogs;
  const [jobLogs, jobLogsLoading, jobLogsError] = useMaglevGetJobLogsApi(
    projectId && modelId ? { projectId, jobId: modelId } : undefined,
  );

  const currentModel = useMemo(() => {
    return !modelId || !projectModelsData
      ? null
      : projectModelsData.find(model => model.id === modelId) ?? null;
  }, [modelId, projectModelsData]);

  useEffect(() => {
    if (currentModel?.modelName !== undefined) {
      setModelName(currentModel?.modelName ?? '');
      previousModelNameRef.current = currentModel?.modelName ?? '';
    }
  }, [currentModel?.modelName]);

  const onModelNameChange = useCallback(
    (modelName: string) => {
      if (!modelId || modelName === previousModelNameRef.current) {
        return;
      }
      saveModel.mutate(
        {
          id: modelId,
          modelName,
        },
        {
          onSuccess: () => (previousModelNameRef.current = modelName),
          onError: () => setModelName(previousModelNameRef.current),
        },
      );
    },
    [modelId, saveModel],
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" classes={{ paper: styles.container }}>
      <Grid container>
        <div className={styles.grow} />

        <IconButton id="close-dialog-button" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>

      <DialogContent className={styles.contentContainer}>
        <Grid container alignItems="center" className={styles.modelNameContainer}>
          {isEditing ? (
            <>
              <ClickAwayListener
                onClickAway={() => {
                  if (!modelName) {
                    setModelNameError('This is required.');
                  } else {
                    onModelNameChange(modelName);
                    setIsEditing(false);
                  }
                }}
              >
                <TextField
                  variant="outlined"
                  value={modelName}
                  size="small"
                  className={styles.modelNameText}
                  onChange={e => {
                    setModelName(e.target.value as string);
                  }}
                  error={!!modelNameError}
                  helperText={modelNameError}
                />
              </ClickAwayListener>

              <IconButton
                id="model-details-save-model-name"
                size="small"
                onClick={() => {
                  if (!modelName) {
                    setModelNameError('This is required.');
                  } else {
                    onModelNameChange(modelName);
                    setIsEditing(false);
                  }
                }}
              >
                <CheckIcon />
              </IconButton>
            </>
          ) : (
            <>
              <Typography variant="h3" className={styles.modelNameText} maxWidth={600}>
                {modelName || t('Untitled model')}
              </Typography>

              <IconButton
                id="model-details-edit-model-name"
                size="small"
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </>
          )}
        </Grid>

        <ApiResponseLoader
          response={modelDetailsData}
          loading={modelDetailsLoading}
          error={modelDetailsError}
        >
          {modelDetailsLoaded => {
            const modelDetails = modelDetailsLoaded ?? {};

            const creator = users?.activeUsers?.find(user => user.id === modelDetails.creatorId);

            const trainingMetrics = groupBy(
              modelDetails.metrics?.filter(
                // Note: MetricLabel.old_mean_iou_train and MetricLabel.mIOU have the same value
                // which is why we need to add below "!isEvalMetric" check
                metric =>
                  includes(DISPLAY_LEARNING_CURVE_METRICS, metric.name) && !isEvalMetric(metric),
              ),
              // Render metrics with `_val` suffix in same chart
              ({ name }) => name.replace(/_val$/, ''),
            );

            const { hyperParams } = modelDetails;
            const yamlHyperParams = hyperParams ? yaml.dump(hyperParams) : '';

            return (
              <>
                <AccordionWrapper title={t('Training Info')}>
                  <TrainingInfoItem
                    title={t('Status')}
                    content={
                      startCase(
                        camelCase(
                          MODEL_STATUS_LOOKUP[modelDetails.jobStatus] ?? modelDetails.jobStatus,
                        ),
                      ) ?? t('Unknown status')
                    }
                  />
                  <TrainingInfoItem
                    title={t('Trained By')}
                    content={creator ? `${creator.name} ${creator.lastName}` : t('Unknown creator')}
                  />
                  <TrainingInfoItem
                    title={t('Trained On')}
                    content={
                      new Date(getDateNumber(modelDetails.createdAt)).toLocaleString() ??
                      t('Unknown time')
                    }
                  />
                </AccordionWrapper>

                <AccordionWrapper title={t('Learning Curve')}>
                  {!isEmpty(trainingMetrics) ? (
                    <Box display="flex" flexWrap="wrap">
                      {Object.entries(trainingMetrics).map(([metricGroupName, metrics]) => {
                        return (
                          <Box minWidth={600} maxWidth={800} mt={2} key={metricGroupName} mr={4}>
                            <Paper>
                              <MetricsChart
                                name={metricGroupName}
                                metrics={metrics}
                                startTime={Number(modelDetails.createdAt)}
                              />
                            </Paper>
                          </Box>
                        );
                      })}
                    </Box>
                  ) : (
                    <Box mt={2}>
                      <Typography color="textSecondary">{t('No metrics available')}</Typography>
                    </Box>
                  )}
                </AccordionWrapper>

                <AccordionWrapper title={t('Configuration')}>
                  {yamlHyperParams && (
                    <SyntaxHighlighter
                      language="yaml"
                      style={atelierSulphurpoolLight}
                      customStyle={{ width: '100%', borderRadius: 6 }}
                    >
                      {yamlHyperParams}
                    </SyntaxHighlighter>
                  )}
                </AccordionWrapper>

                {isTrainingLogEnabled && (
                  <AccordionWrapper title={t('Logs')}>
                    <ApiResponseLoader
                      response={jobLogs}
                      loading={jobLogsLoading}
                      error={jobLogsError}
                    >
                      {loadedResponse => (
                        <LazyLog
                          text={loadedResponse}
                          height={600}
                          lineClassName="cy-view-details-job-log"
                        />
                      )}
                    </ApiResponseLoader>
                  </AccordionWrapper>
                )}
              </>
            );
          }}
        </ApiResponseLoader>
      </DialogContent>
    </Dialog>
  );
};

export default ModelDetailsDialog;

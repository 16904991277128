import React, { useCallback, useEffect, useMemo, MutableRefObject, useState } from 'react';
import classnames from 'classnames';
import { Box, Tooltip, Divider, useTheme } from '@material-ui/core';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import { useAtom, useSetAtom } from 'jotai';

import {
  Button,
  isMacOS,
  MediaInteractiveCanvas,
  AnnotationChangeType,
  useKeyPress,
  CanvasMode,
  ToggleButton,
} from '@clef/client-library';
import {
  // Defect,
  LabelType,
  LabelingType,
  MediaStatusType,
  UserPermission,
} from '@clef/shared/types';

import {
  ToolMode,
  BitMapLabelingAnnotation,
  BoxLabelingAnnotation,
  // ClassificationLabelingAnnotation,
  useLabelingState,
} from '@/components/Labeling/labelingState';
import { convertToCanvasAnnotations, getCanvasMode } from '@/components/Labeling/utils';
import SegmentationLabelTools from '@/components/Labeling/SegmentationLabelTools';
import { useDialog } from '@/components/Layout/components/useDialog';

import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useGetProjectModelListQuery } from '@/serverStore/projectModels';
import useGetDefectColorById from '@/hooks/defect/useGetDefectColorById';
import { useHasPermission } from '@/hooks/useProjectRolePermissions';
import { useFirstRunExperienceWorkflowAssistantEnabled } from '@/hooks/useFeatureGate';

import {
  HandIcon,
  BoundingBoxIcon,
  UndoIcon,
  RedoIcon,
  ClearIcon,
  TuneIcon,
} from '@/images/labeling_tools_icons/ToolIcons';

import {
  // LabelIcon,
  InformationIcon,
  KeyboardIcon,
} from '@/images/media_details/ToolIcons';
import { UpdatingIcon, OKIcon } from '@/images/media_details/UpdateStatesIcons';

import {
  showGroundTruthAtom,
  showPredictionAtom,
  showHeatmapAtom,
} from '@/uiStates/mediaDetails/labelToggles';
import {
  isLabelModeAtom,
  currentToolModeAtom,
  annotationInstanceAtom,
  useCurrentMediaStates,
  useSaveAnnotations,
  mediaStatesMapAtom,
  rightDrawerTypeAtom,
  RightDrawerType,
  labelUpdateStateAtom,
  LabelUpdateState,
  toggleOnModelAssistLabelingAtom,
} from '@/uiStates/mediaDetails/pageUIStates';

import ZoomTool from './ZoomTool';
import ImageEnhanceWrapper from './ImageEnhanceWrapper';
import useStyles from './styles';

interface ToolbarProps {
  mediaCanvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
  className: string;
}

const Toolbar: React.FC<ToolbarProps> = ({ mediaCanvasRef, className }) => {
  const styles = useStyles();
  const theme = useTheme();

  const { labelType } = useGetSelectedProjectQuery().data ?? {};

  const {
    state: { labelingType, isCreatingDefect, haveUnsavedLabel, toolMode },
    dispatch: dispatchLabelingState,
  } = useLabelingState();

  const firstRunExperienceWorkflowAssistantEnabled =
    useFirstRunExperienceWorkflowAssistantEnabled();

  const { data: models } = useGetProjectModelListQuery(firstRunExperienceWorkflowAssistantEnabled);

  const currentMediaStates = useCurrentMediaStates();
  const [showGroundTruth, setShowGroundTruth] = useAtom(showGroundTruthAtom);
  const [showPrediction, setShowPrediction] = useAtom(showPredictionAtom);
  const [showHeatmap, setShowHeatmap] = useAtom(showHeatmapAtom);
  const [isLabelMode, setIsLabelMode] = useAtom(isLabelModeAtom);
  const setCurrentToolMode = useSetAtom(currentToolModeAtom);
  const [annotationInstance] = useAtom(annotationInstanceAtom);
  const [mediaStatesMap, setMediaStatesMap] = useAtom(mediaStatesMapAtom);
  const [rightDrawerType, setRightDrawerType] = useAtom(rightDrawerTypeAtom);
  const [labelUpdateState] = useAtom(labelUpdateStateAtom);
  const [toggleOnModelAssistLabeling, setToggleOnModelAssistLabeling] = useAtom(
    toggleOnModelAssistLabelingAtom,
  );

  const { showConfirmationDialog } = useDialog();

  const saveAnnotations = useSaveAnnotations();

  const canDirectLabel = useHasPermission(UserPermission.DirectLabel);
  const allowLabelMode =
    [LabelingType.DefectBoundingBox, LabelingType.DefectSegmentation].includes(labelingType) &&
    canDirectLabel;

  const isInTask =
    currentMediaStates.mediaDetails?.mediaStatus &&
    currentMediaStates.mediaDetails.mediaStatus == MediaStatusType.InTask;

  const getDefectColorById = useGetDefectColorById();

  const changeIsLabelMode = useCallback(
    (isLabelMode: boolean) => {
      setIsLabelMode(isLabelMode);
      if (isLabelMode) {
        setShowGroundTruth(true);
        setShowPrediction(false);
      } else {
        setShowPrediction(true);
      }

      dispatchLabelingState(draft => {
        if (!isLabelMode) {
          draft.toolMode = undefined;
        } else if (labelingType === LabelingType.DefectBoundingBox) {
          // bounding box has only one tool mode, select it by default for label mode
          draft.toolMode = ToolMode.Box;
        }
      });

      let newAnnotations = currentMediaStates.annotations as (
        | BoxLabelingAnnotation
        | BitMapLabelingAnnotation
      )[];
      const instanceMediaId = annotationInstance?.mediaId;
      if (instanceMediaId) {
        // clear hovered annotation on switch view / label mode
        // only useful when the dialog opened from instance view
        const mediaState = mediaStatesMap[instanceMediaId];
        if (mediaState) {
          mediaState.annotations.forEach(ann => {
            ann.hovered = false;
          });
          mediaState.predictionAnnotations?.forEach(ann => {
            ann.hovered = false;
          });
          const newMediaStateMap = { ...mediaStatesMap, [instanceMediaId]: mediaState };
          setMediaStatesMap(newMediaStateMap);
        }

        newAnnotations = newAnnotations.map(ann => ({
          ...ann,
          hovered: false,
        }));
      }
      mediaCanvasRef.current?.setAnnotations(
        convertToCanvasAnnotations(labelingType, newAnnotations, getDefectColorById),
        AnnotationChangeType.Reset,
      );
    },
    [
      annotationInstance?.mediaId,
      currentMediaStates.annotations,
      dispatchLabelingState,
      getDefectColorById,
      labelingType,
      mediaCanvasRef,
      mediaStatesMap,
      setIsLabelMode,
      setMediaStatesMap,
      setShowGroundTruth,
      setShowPrediction,
    ],
  );

  // `s` conflicts with the key to switch ground truth and prediction labels in data browser.
  // listen to `*` instead to get higher priority.
  useKeyPress('*', e => {
    if (e.code !== 'KeyS') {
      return;
    }
    if (!isInTask && allowLabelMode) {
      const newIsLabelMode = !isLabelMode;
      changeIsLabelMode(newIsLabelMode);
      // save annotations when switch to view mode
      // if creating defect, do not save the annotations based on the assumption that there will be at most one
      // annotation under this mode
      if (!newIsLabelMode && !isCreatingDefect) {
        saveAnnotations();
      }
    }
  });

  useEffect(() => {
    if (isInTask) {
      changeIsLabelMode(false);
    }
  }, [isInTask, changeIsLabelMode]);

  const labelTooltip = useMemo(() => {
    if (isInTask) return t('Media is in a labeling task');
    if (labelType === LabelType.BoundingBox) return t('Bounding Box Tool');
    if (labelType === LabelType.Segmentation) return t('Brush');
    return t('Label tool');
  }, [isInTask, labelType]);

  const handleSwitchToPanMode = () => {
    if (!isLabelMode) return;
    changeIsLabelMode(false);
    setCurrentToolMode(undefined);
    if (toggleOnModelAssistLabeling) {
      setToggleOnModelAssistLabeling(false);
    }
    // if creating defect, do not save the annotations based on the assumption that there will be at
    // most one annotation under this mode
    if (!isCreatingDefect) {
      saveAnnotations();
    }
  };

  const handleChangeRightDrawerType = useCallback(
    (type: RightDrawerType | null) => {
      if (rightDrawerType === type) {
        setRightDrawerType(null);
      } else {
        setRightDrawerType(type);
      }
      // resize canvas when drawer is toggled
      if ((rightDrawerType === null && !!type) || (!!rightDrawerType && type === rightDrawerType)) {
        setTimeout(() => {
          mediaCanvasRef.current?.resizeScale();
        }, 300); // wait for drawer animation
      }
    },
    [rightDrawerType, setRightDrawerType, mediaCanvasRef],
  );

  useKeyPress('g', () => {
    setShowGroundTruth(!showGroundTruth);
  });

  useKeyPress('p', () => {
    setShowPrediction(!showPrediction);
  });

  useKeyPress('a', handleSwitchToPanMode);

  useKeyPress('/', () => handleChangeRightDrawerType(RightDrawerType.HotKeys));

  const clearAllLabels = useCallback(() => {
    showConfirmationDialog({
      title: t('Are you sure you want to delete all labels?'),
      content: t(
        // eslint-disable-next-line max-len
        'Are you sure you want to delete all labels on this media? This operations cannot be undone',
      ),
      confirmText: t('Remove all labels'),
      color: 'secondary',
      onConfirm: () => {
        mediaCanvasRef.current?.setAnnotations([], AnnotationChangeType.DeleteAll);
      },
    });
  }, [showConfirmationDialog, mediaCanvasRef]);

  const toggleLabelVisibility = useCallback(() => {
    setShowGroundTruth(!showGroundTruth);
  }, [setShowGroundTruth, showGroundTruth]);

  const togglePredictionVisibility = useCallback(() => {
    setShowPrediction(!showPrediction);
  }, [setShowPrediction, showPrediction]);

  const toggleHeatmapVisibility = useCallback(() => {
    setShowHeatmap(!showHeatmap);
  }, [setShowHeatmap, showHeatmap]);

  const canvasMode = useMemo(
    () => (!isLabelMode ? CanvasMode.View : getCanvasMode(toolMode)),
    [isLabelMode, toolMode],
  );

  const [hideLabelsButtonHovered, setHideLabelsButtonHovered] = useState(false);
  useEffect(() => {
    dispatchLabelingState(draft => {
      draft.hideLabels = hideLabelsButtonHovered;
    });
  }, [dispatchLabelingState, hideLabelsButtonHovered]);

  return (
    <Box className={classnames(styles.toolbar, className)}>
      <Box className={styles.labelingTools}>
        {labelType !== LabelType.Classification && (
          <>
            <Tooltip placement="top" arrow title={t('Pan {{key}}', { key: <code>a</code> })}>
              <Button
                // id="media-details-view-mode-button"
                id="media-details-pan-mode"
                aria-pressed={!isLabelMode}
                className={classnames({
                  [styles.active]: !isLabelMode,
                })}
                onClick={handleSwitchToPanMode}
              >
                <HandIcon />
              </Button>
            </Tooltip>

            <Divider orientation="vertical" className={styles.verticalLine} />

            {labelType === LabelType.BoundingBox && (
              <>
                <Tooltip placement="top" arrow title={labelTooltip}>
                  <Button
                    // id="media-details-label-mode-button"
                    id="media-details-bounding-box"
                    aria-pressed={isLabelMode}
                    onClick={() => !isInTask && changeIsLabelMode(true)}
                    // `disabled` attribute will make tooltip unavailable.
                    // use opacity to make it looks like disabled.
                    className={classnames({
                      [styles.disabled]: isInTask,
                      [styles.active]: isLabelMode,
                    })}
                    disableRipple={isInTask}
                  >
                    <BoundingBoxIcon />
                  </Button>
                </Tooltip>
                <Divider orientation="vertical" className={styles.verticalLine} />
              </>
            )}

            {labelType === LabelType.Segmentation && (
              <>
                <SegmentationLabelTools onClick={() => changeIsLabelMode(true)} />
                <Divider orientation="vertical" className={styles.verticalLine} />
              </>
            )}

            <Button
              id="media-details-undo"
              onClick={() => mediaCanvasRef.current?.undo()}
              tooltip={t('Undo {{key}}', {
                key: (
                  <>
                    <code>{isMacOS() ? 'Command' : 'Ctrl'}</code> + <code>Z</code>
                  </>
                ),
              })}
              style={!isLabelMode ? { cursor: 'not-allowed' } : undefined}
            >
              <UndoIcon />
            </Button>

            <Button
              id="media-details-redo"
              onClick={() => mediaCanvasRef.current?.redo()}
              tooltip={t('Redo {{key}}', {
                key: (
                  <>
                    <code>Shift</code> + <code>{isMacOS() ? 'Command' : 'Ctrl'}</code> +{' '}
                    <code>Z</code>
                  </>
                ),
              })}
              style={!isLabelMode ? { cursor: 'not-allowed' } : undefined}
            >
              <RedoIcon />
            </Button>

            {/* remove all labels within current image */}
            <Button
              id="media-details-remove-all-label"
              onClick={() => {
                // SAM labeling canvas mode with unsaved label should not show the nothing-to-label button
                if (canvasMode !== CanvasMode.IQuickLabeling || !haveUnsavedLabel) {
                  clearAllLabels();
                }
              }}
              tooltip={t('Remove All Labels')}
              disabled={currentMediaStates.annotations.length === 0}
              className={classnames({
                [styles.disabled]: currentMediaStates.annotations.length === 0 || haveUnsavedLabel,
              })}
            >
              <ClearIcon />
            </Button>
            <Divider orientation="vertical" className={styles.verticalLine} />
          </>
        )}

        <ImageEnhanceWrapper
          customerIcon={
            <Button id="media-details-enhancement" tooltip={t('Enhance')}>
              <TuneIcon />
            </Button>
          }
        />
        {/* Hide labels */}
        {(labelingType === LabelingType.DefectBoundingBox ||
          labelingType === LabelingType.DefectSegmentation) && (
          <Tooltip
            arrow
            placement="top"
            title={t('Hold {{hotKey}} to hide', {
              hotKey: <code>h</code>,
            })}
          >
            <Button
              size="medium"
              id="hover-hide-label-button"
              disableTouchRipple
              onMouseEnter={() => setHideLabelsButtonHovered(true)}
              onMouseLeave={() => setHideLabelsButtonHovered(false)}
            >
              {showGroundTruth ? (
                <VisibilityOutlined fontSize="small" htmlColor={theme.palette.grey[500]} />
              ) : (
                <VisibilityOffOutlined fontSize="small" htmlColor={theme.palette.grey[500]} />
              )}
            </Button>
          </Tooltip>
        )}
        <ZoomTool
          setZoomScale={newZoomScale => mediaCanvasRef.current?.setZoomScale(newZoomScale)}
        />
      </Box>

      <Box className={styles.rightContent}>
        <Box className={styles.labelUpdateStates}>
          {labelUpdateState === LabelUpdateState.Updating && (
            <Box className={styles.updatingIcon}>
              <UpdatingIcon />
              {t('Saving...')}
            </Box>
          )}
          {labelUpdateState === LabelUpdateState.Updated && (
            <>
              <OKIcon /> {t('Labels saved!')}
            </>
          )}
          {labelUpdateState !== LabelUpdateState.Unchanged && (
            <Divider orientation="vertical" className={styles.verticalLine} />
          )}
        </Box>

        <Box className={styles.toggleBtns}>
          {labelType === LabelType.Segmentation && !isLabelMode && (
            <Box style={isLabelMode ? { cursor: 'not-allowed' } : undefined}>
              <ToggleButton
                id="media-details-toggle-labels"
                isOn={showGroundTruth}
                onToggle={toggleLabelVisibility}
              >
                {t('Labels')}
              </ToggleButton>
              <ToggleButton
                id="media-details-toggle-prediction"
                isOn={showPrediction}
                onToggle={togglePredictionVisibility}
                disabled={
                  !currentMediaStates.predictionAnnotations ||
                  currentMediaStates.predictionAnnotations.length === 0
                }
              >
                {t('Predictions')}
              </ToggleButton>
            </Box>
          )}

          {labelType === LabelType.Classification && models && models.length > 0 && (
            <ToggleButton
              id="media-details-toggle-heatmap"
              isOn={showHeatmap}
              onToggle={toggleHeatmapVisibility}
            >
              {t('Heat-map')}
            </ToggleButton>
          )}
          {!isLabelMode && <Divider orientation="vertical" className={styles.verticalLine} />}
        </Box>

        <Box className={styles.drawerBtns}>
          {/* {labelType !== LabelType.Classification && (
            <Button
              id="label-drawer-button"
              tooltip={t('Labels information / Predictions information')}
              className={classnames({
                [styles.active]: rightDrawerType === RightDrawerType.Labels,
              })}
              onClick={() => handleChangeRightDrawerType(RightDrawerType.Labels)}
            >
              <LabelIcon />
            </Button>
          )} */}
          <Button
            id="label-drawer-button"
            tooltip={t('Image details')}
            className={classnames({
              [styles.active]: rightDrawerType === RightDrawerType.Information,
            })}
            onClick={() => handleChangeRightDrawerType(RightDrawerType.Information)}
          >
            <InformationIcon />
          </Button>
          <Button
            id="label-drawer-button"
            tooltip={t('Keyboard shortcuts')}
            className={classnames({
              [styles.active]: rightDrawerType === RightDrawerType.HotKeys,
            })}
            aria-pressed={rightDrawerType === RightDrawerType.HotKeys}
            onClick={() => handleChangeRightDrawerType(RightDrawerType.HotKeys)}
          >
            <KeyboardIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Toolbar;

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  toolbarButton: {
    minWidth: 66,
    height: 32,
    marginRight: theme.spacing(2),
    padding: theme.spacing(2, 3),
    borderColor: theme.palette.grey[300],
    color: theme.palette.grey[600],
    fontSize: 12,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  clearFiltersIcon: {
    fontSize: '16px !important',
    color: theme.palette.grey[500],
  },
  optionItem: {
    cursor: 'pointer',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'rgba(9, 30, 66, 0.08)',
    },
  },
  optionItemOpen: {
    backgroundColor: 'rgba(9, 30, 66, 0.08)',
  },
  dropdownPanel: {
    minWidth: 150,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectorText: {
    marginRight: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;

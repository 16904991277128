import { Tooltip } from '@material-ui/core';
import { IconButton } from '../../../..';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined';
import React, { useEffect, useState } from 'react';
import { useKeyPressHold } from '../../../hooks/useKeyPress';
import { useMediaInteractiveCanvasStyles } from '../styles';
import cx from 'classnames';

export interface HideLabelsButtonFloaterProps {
  onHideLabelStatusChange: (hideLabels: boolean) => void;
  buttonClassName?: string;
}

const HideLabelsButtonFloater: React.FC<HideLabelsButtonFloaterProps> = ({
  onHideLabelStatusChange,
  buttonClassName,
}) => {
  const styles = useMediaInteractiveCanvasStyles();

  const hideKeyPressed = useKeyPressHold('h');
  const [hideLabelsButtonHovered, setHideLabelsButtonHovered] = useState(false);

  useEffect(() => {
    onHideLabelStatusChange(!!hideKeyPressed || hideLabelsButtonHovered);
  }, [hideKeyPressed, hideLabelsButtonHovered, onHideLabelStatusChange]);

  return (
    <Tooltip
      title={t('Hold {{hotKey}} to hide', {
        hotKey: <span className={styles.codeBlock}>h</span>,
      })}
      placement="bottom"
    >
      <IconButton
        id="toggle-label-visiblity"
        className={cx(styles.hideLabelsButton, buttonClassName)}
        size="medium"
        disableTouchRipple
        onMouseEnter={() => setHideLabelsButtonHovered(true)}
        onMouseLeave={() => setHideLabelsButtonHovered(false)}
      >
        {!!hideKeyPressed || hideLabelsButtonHovered ? (
          <VisibilityOffOutlined />
        ) : (
          <VisibilityOutlined />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default HideLabelsButtonFloater;

import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMore from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import { every, sumBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';

import { Button, IconButton, DistributionChart } from '@clef/client-library';
import { DatasetGroupOptions, MediaStatusType, SelectMediaOption } from '@clef/shared/types';

import CLEF_PATH from '@/constants/path';
import {
  MediaSplitName,
  SplitMapping,
  MediaStatusNameMapping,
  statsColorMap,
  splitColorMap,
} from '@/constants/stats_card';
import { defaultSelectOptions } from '@/constants/data_browser';
import { useDefectSelector } from '@/store/defectState/actions';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';
import {
  useGetDatasetStatsQuery,
  useGetDatasetFilterOptionsQuery,
  useAutoSplitMutation,
  useCreateDatesetSnapshotMutation,
} from '@/serverStore/dataset';
import {
  createSnapshotDialogModeAtom,
  CreateSnapshotDialogMode,
} from '@/uiStates/datasetSnapshot/pageUIStates';
// import ToggleButton from '@/components/GroupedImages/components/ToggleButton';
import {
  autoSplitCoreAlgorithm,
  DefectDistribution,
  DefectDistributionWithAssignment,
  assignmentToDistributionToAssignSplitMapping,
  splitValueToNoDefectAssignment,
} from '@clef/shared/utils/auto_split_core_algorithm';
import { distributionAssignmentToSplitStats } from '@/components/AutoSplitDialog/utils';
import { SnapshotDetailItem } from '@/pages/DatasetSnapshot/SnapshotSummary/SnapshotDetailItem';

import { useDataBrowserState, formatStateToSelectMediaOption } from '../dataBrowserState';

import { useStyles } from './styles';

const splitRatio: [number, number, number] = [80, 20, 0];

interface CreateSnapshotDialogProps {}

export const CreateSnapshotDialog: React.FC<CreateSnapshotDialogProps> = () => {
  const styles = useStyles();
  const history = useHistory();

  const { state, dispatch } = useDataBrowserState();
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();
  const createDatasetSnapshot = useCreateDatesetSnapshotMutation();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [createSnapshotDialogMode, setCreateSnapshotDialogMode] = useAtom(
    createSnapshotDialogModeAtom,
  );

  const [snapshotName, setSnapshotName] = useState<string>(
    'Snapshot-' + format(new Date(), 'MM-dd-yyyy HH:mm'),
  );
  const handleSnapshotNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSnapshotName(e.target.value);
  };

  const [snapshotDesc, setSnapshotDesc] = useState<string>('');
  const handleSnapshotDescChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSnapshotDesc(e.target.value);
  };

  // const [removeUnlabelImage, setRemoveUnlabelImage] = useState<boolean>(false);
  const [removeUnlabelImage] = useState<boolean>(false);
  // const [autoSplitUnassigned, setAutoSplitUnassigned] = useState<boolean>(false);
  const [autoSplitUnassigned] = useState<boolean>(false);
  const [expandMore, setExandMore] = useState<boolean>(
    createSnapshotDialogMode === CreateSnapshotDialogMode.SUBSET,
  );

  const selectMediaOptionsDataBrowser = useMemo(
    () => formatStateToSelectMediaOption(state, currentModelId),
    [state, currentModelId],
  );

  // depends on createSnapshotDialogMode
  const selectMediaOptionsForAll: SelectMediaOption = useMemo(() => {
    if (createSnapshotDialogMode === CreateSnapshotDialogMode.FULL) {
      return defaultSelectOptions;
    }
    return selectMediaOptionsDataBrowser;
  }, [createSnapshotDialogMode, selectMediaOptionsDataBrowser]);

  // get label status
  const { data: mediaGroupByMediaStatus } = useGetDatasetStatsQuery({
    selectOptions: selectMediaOptionsForAll,
    groupOptions: [DatasetGroupOptions.MEDIA_STATUS],
  });
  const mediaStatsFormatted = useMemo(() => {
    const obj = Object.entries(MediaStatusNameMapping).map(([status, name]) => ({
      name,
      value: mediaGroupByMediaStatus?.find(item => item.media_status === status)?.count ?? 0,
    }));
    if (removeUnlabelImage) {
      return obj.map(item => {
        if (item.name === t('unlabeled') || item.name === t('in task')) {
          return { ...item, value: 0 };
        }
        return item;
      });
    }
    return obj;
  }, [mediaGroupByMediaStatus, removeUnlabelImage]);

  const snapshotImageCount = useMemo(() => {
    const totalImageCount = sumBy(mediaStatsFormatted, item => item.value);
    if (removeUnlabelImage && mediaStatsFormatted) {
      const unlabeledValue =
        mediaGroupByMediaStatus?.find(item => item.media_status === MediaStatusType.Raw)?.count ??
        0;
      const inTashValue =
        mediaGroupByMediaStatus?.find(item => item.media_status === MediaStatusType.InTask)
          ?.count ?? 0;
      return totalImageCount - unlabeledValue - inTashValue;
    }
    return totalImageCount;
  }, [removeUnlabelImage, mediaStatsFormatted, mediaGroupByMediaStatus]);

  const labeledOption = { CONTAINS_ANY: [MediaStatusType.Approved] };
  const { data: allFilters } = useGetDatasetFilterOptionsQuery();
  // 'Split' is in columnFilterMap, the project has split in datasetContent's splitSet column
  // 'split' is in fieldFilterMap, the project has split metadata
  // Always prefer to use 'Split' column in case user manually creates 'split' or 'Split' metadata
  const splitFilterOption = allFilters
    ? allFilters.find(value => value.filterName === 'Split' && value.filterType === 'column') ||
      allFilters.find(value => value.filterName === 'split')
    : undefined;
  const splitFilter = splitFilterOption?.value ? Object.keys(splitFilterOption.value) : [];
  const splitFilterByIds: number[] =
    splitFilterOption?.filterType === 'column'
      ? splitFilter.map(s => splitFilterOption.value![s] as number)
      : [];

  const labeledMediaSelectMediaOption: SelectMediaOption | undefined = splitFilterOption
    ? {
        ...selectMediaOptionsForAll,
        fieldFilterMap:
          splitFilterOption.filterType === 'field' && splitFilter?.length
            ? {
                ...selectMediaOptionsForAll.fieldFilterMap,
                [splitFilterOption.fieldId!]: { NOT_CONTAIN_ANY: splitFilter },
              }
            : selectMediaOptionsForAll.fieldFilterMap,
        columnFilterMap: {
          ...selectMediaOptionsForAll.columnFilterMap,
          datasetContent: {
            ...selectMediaOptionsForAll.columnFilterMap.datasetContent,
            mediaStatus: labeledOption,
          },
        },
      }
    : undefined;

  const unassignedLabeledMediaSelectMediaOption: SelectMediaOption | undefined =
    splitFilterOption && labeledMediaSelectMediaOption
      ? {
          ...labeledMediaSelectMediaOption,
          columnFilterMap: {
            ...labeledMediaSelectMediaOption.columnFilterMap,
            datasetContent: {
              ...labeledMediaSelectMediaOption.columnFilterMap.datasetContent,
              ...(splitFilterOption.filterType === 'column' &&
                splitFilterByIds.length && { splitSet: { NOT_CONTAIN_ANY: splitFilterByIds } }),
            },
          },
        }
      : undefined;

  const allDefects = useDefectSelector();

  /**
   * @description: delow are for auto split unassigned labeled images
   */
  const { data: mediaGroupByDefectType } = useGetDatasetStatsQuery(
    {
      selectOptions: unassignedLabeledMediaSelectMediaOption,
      groupOptions: [DatasetGroupOptions.DEFECT_TYPE],
    },
    !!unassignedLabeledMediaSelectMediaOption,
  );
  const { data: mediaGroupByDefectDistribution } = useGetDatasetStatsQuery(
    {
      selectOptions: unassignedLabeledMediaSelectMediaOption,
      groupOptions: [DatasetGroupOptions.DEFECT_DISTRIBUTION],
    },
    !!unassignedLabeledMediaSelectMediaOption,
  );
  // filter out defects that does not have count
  const defectList =
    allDefects && mediaGroupByDefectType
      ? allDefects.filter(({ id }) =>
          mediaGroupByDefectType.find(defectType => defectType.defect_id === id),
        )
      : undefined;
  const defectIdList: number[] = defectList?.map(defect => defect.id) ?? [];
  const defectDistributions: DefectDistribution[] =
    (mediaGroupByDefectDistribution as DefectDistribution[])?.filter(
      stats => stats.count && stats.defect_distribution,
    ) ?? [];
  const numberOfMediaWithNoDefects =
    mediaGroupByDefectDistribution?.find(stats => !stats.defect_distribution)?.count || 0;

  const distributionWithDefectResult = autoSplitCoreAlgorithm(
    defectIdList,
    defectDistributions,
    defectIdList.reduce(
      (acc, id) => ({ ...acc, [id]: splitRatio }),
      {} as { [defectId: number]: [number, number, number] },
    ),
  );
  // { count: number, defect_distribution: { defectId: count }[], assignment: [train_count, dev_count, test_count] }
  const defectDistributionWithAssignment: DefectDistributionWithAssignment[] = useMemo(
    () => [
      ...distributionWithDefectResult,
      ...(numberOfMediaWithNoDefects
        ? [
            {
              count: numberOfMediaWithNoDefects,
              defect_distribution: null,
              assignment: splitValueToNoDefectAssignment(numberOfMediaWithNoDefects, splitRatio),
            },
          ]
        : []),
    ],
    [distributionWithDefectResult, numberOfMediaWithNoDefects],
  );
  // auto split for unassigned labeled images
  const splitStats = useMemo(
    () => distributionAssignmentToSplitStats(defectDistributionWithAssignment),
    [defectDistributionWithAssignment],
  );
  /**
   * @description: above are for auto split unassigned labeled images
   */

  // get split data for all selected media
  const { data: mediaGroupBySplit } = useGetDatasetStatsQuery({
    selectOptions: labeledMediaSelectMediaOption,
    groupOptions: [DatasetGroupOptions.SPLIT],
  });
  const mediaSplitFormatted = useMemo(() => {
    const obj = Object.entries(SplitMapping).map(([split, name]) => ({
      name,
      value:
        mediaGroupBySplit?.find(item => {
          if (item.split === null && split === MediaSplitName.Unassigned) return true;
          return item.split === split;
        })?.count ?? 0,
    }));

    if (autoSplitUnassigned) {
      obj.forEach(item => {
        if (splitStats.find(e => e.name === item.name)) {
          item.value = item.value + splitStats.find(e => e.name === item.name)!.value;
        }
        if (item.name === t('unassigned')) {
          item.value = 0;
        }
      });
    }
    return obj;
  }, [autoSplitUnassigned, mediaGroupBySplit, splitStats]);

  const autoSplit = useAutoSplitMutation();
  const saveSnapshot = async () => {
    // auto split first if toggle AutoSplitUnassigned is on
    if (autoSplitUnassigned) {
      await autoSplit.mutateAsync({
        selectOptions: unassignedLabeledMediaSelectMediaOption!,
        splitByDefectDistribution: assignmentToDistributionToAssignSplitMapping(
          defectDistributionWithAssignment,
        ),
      });
    }

    createDatasetSnapshot.mutate(
      {
        name: snapshotName,
        details: snapshotDesc,
        onlyLabeled: removeUnlabelImage,
        selectMediaOptions: selectMediaOptionsForAll,
      },
      {
        onSuccess: res => {
          const snackbarKey = enqueueSnackbar(
            t('Snapshot successfully created. {{link}}', {
              link: (
                <Typography
                  style={{
                    display: 'inline',
                    cursor: 'pointer',
                    marginLeft: 4,
                    fontWeight: 500,
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    history.push(`${CLEF_PATH.data.datasetSnapshot}/${res.version}`);
                    closeSnackbar(snackbarKey);
                  }}
                >
                  {t('View Details.')}
                </Typography>
              ),
            }),
            {
              variant: 'success',
              autoHideDuration: 18000,
            },
          );
          setCreateSnapshotDialogMode(null);
          dispatch(draft => {
            draft.selectingMediaMode = 'select';
            draft.selectedOrUnselectedMedia = [];
          });
        },
      },
    );
  };

  return (
    <Dialog open color="primary" PaperProps={{ className: styles.createDialog }}>
      <DialogTitle className={styles.dialogSection}>
        <Typography variant="h2" className={styles.dialogTitleText}>
          {t('Create Snapshot')}
        </Typography>
        <IconButton
          id="close-clone-project-dialog-btn"
          onClick={() => setCreateSnapshotDialogMode(null)}
          className={styles.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classNames(styles.dialogSection, styles.dialogContent)}>
        <Typography className={classNames(styles.normalFont, styles.fieldTitle)}>
          {t('Snapshot Name')}
        </Typography>
        <TextField
          fullWidth
          size="small"
          classes={{ root: styles.dialogInput }}
          variant="outlined"
          value={snapshotName}
          onChange={handleSnapshotNameChange}
          placeholder={t('Snapshot Name')}
          error={snapshotName?.length === 0 || snapshotName.length > 100}
          helperText={
            (snapshotName?.length === 0 && t('Name is required to create a snapshot.')) ||
            (snapshotName.length > 100 && t('Sorry, the name exceeds the 100-character limit.'))
          }
          autoFocus
        />

        <Typography className={classNames(styles.normalFont, styles.fieldTitle)}>
          {t('Description')}
        </Typography>
        <TextField
          fullWidth
          size="small"
          multiline
          maxRows={4}
          InputProps={{
            classes: {
              root: styles.descInputWrapper,
              input: styles.descInput,
            },
          }}
          variant="outlined"
          value={snapshotDesc}
          onChange={handleSnapshotDescChange}
          placeholder={t(
            'e.g. Backup current data, training on subset, holdout for evaluation etc.',
          )}
        />

        <Accordion expanded={expandMore} classes={{ root: styles.accordionRoot }}>
          <AccordionSummary
            expandIcon={<ExpandMore fontSize="small" />}
            aria-controls="preview-and-setting"
            id="preview-and-setting-header"
            onClick={() => setExandMore(prev => !prev)}
          >
            <Typography className={classNames(styles.normalFont, styles.fieldTitle)}>
              {t('Snapshot Preview & Settings')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            id="preview-and-setting"
            classes={{ root: styles.accordionDetailsRoot }}
          >
            {mediaGroupByMediaStatus && (
              <Box className={styles.settingDetails}>
                <Box className={styles.detailTitle}>{t('Image status')}</Box>
                {snapshotImageCount === 0 ? (
                  <Box className={styles.emptyChart} />
                ) : (
                  <DistributionChart
                    distributionData={[
                      {
                        name: '',
                        distributions: mediaStatsFormatted.map(item => ({
                          distributor: item.name,
                          value: item.value,
                        })),
                      },
                    ]}
                    distributorColorMap={statsColorMap}
                    hideLabel
                    compact
                    bandWidth={6}
                    size="small"
                  />
                )}
                {mediaStatsFormatted.map((item, index) => (
                  <SnapshotDetailItem key={index} item={item} index={index} />
                ))}
                {/* <Box className={styles.detailDescWrapper}>
                  {mediaGroupByMediaStatus?.find(
                    item => item.media_status === MediaStatusType.Approved,
                  )?.count ===
                  (selectMediaOptionsDataBrowser.selectedMedia.length > 0
                    ? selectMediaOptionsDataBrowser.selectedMedia.length
                    : state.totalMediaCount) ? (
                    <Box className={styles.emphasizeFont}>{t('All images are labeled')}</Box>
                  ) : (
                    <>
                      <ToggleButton
                        id="media-details-toggle-labels"
                        isOn={removeUnlabelImage}
                        onToggle={() => setRemoveUnlabelImage(prev => !prev)}
                      >
                        {t('Only keep labeled images in this snapshot')}
                      </ToggleButton>
                      {removeUnlabelImage && (
                        <Typography className={styles.detailSmallTips}>
                          {t(`${selectedMediaCount - snapshotImageCount} images removed`)}
                        </Typography>
                      )}
                    </>
                  )}
                </Box> */}
              </Box>
            )}
            {mediaGroupBySplit && (
              <Box className={styles.settingDetails}>
                <Box className={styles.detailTitle}>
                  {t('Split distribution on labeled images')}
                </Box>
                {every(mediaSplitFormatted, ['value', 0]) ? (
                  <Box className={styles.emptyChart} />
                ) : (
                  <DistributionChart
                    distributionData={[
                      {
                        name: '',
                        distributions: mediaSplitFormatted.map(item => ({
                          distributor: item.name,
                          value: item.value,
                        })),
                      },
                    ]}
                    distributorColorMap={splitColorMap}
                    hideLabel
                    compact
                    bandWidth={6}
                    size="small"
                  />
                )}

                {mediaSplitFormatted.map((item, index) => (
                  <SnapshotDetailItem key={index} item={item} index={index} />
                ))}
                {Boolean(
                  mediaSplitFormatted.find(item => item.name === t('unassigned'))?.value,
                ) && (
                  <Box className={styles.reminderBox}>
                    {t(
                      `If you want to use this snapshot {{purpose}}, we recommend {{action}} before creating the snapshot.`,
                      {
                        purpose: (
                          <Typography className={styles.emphasizeFont}>
                            {t('for training')}
                          </Typography>
                        ),
                        action: (
                          <Typography className={styles.emphasizeFont}>
                            {t('splitting all labeled images')}
                          </Typography>
                        ),
                      },
                    )}
                  </Box>
                )}
                {/* <Box className={styles.detailDescWrapper}>
                  {every(mediaSplitFormatted, ['value', 0]) ? (
                    <Box className={styles.emphasizeFont}>
                      {t('No labeled images in this snapshot')}
                    </Box>
                  ) : (
                    <>
                      {!mediaGroupBySplit?.find(item => item.split === null) ? (
                        <Box className={styles.emphasizeFont}>
                          {t('All labeled images are assigned to splits')}
                        </Box>
                      ) : (
                        <ToggleButton
                          id="media-details-toggle-labels"
                          isOn={autoSplitUnassigned}
                          onToggle={() => setAutoSplitUnassigned(prev => !prev)}
                        >
                          {t('Auto-split unassigned labeled images')}
                        </ToggleButton>
                      )}
                    </>
                  )}
                </Box> */}
              </Box>
            )}
          </AccordionDetails>
        </Accordion>

        <Box className={styles.contentSummary}>
          <Typography className={styles.normalFont}>
            {t('Total: {{imageCount}} images', {
              imageCount: <span className={styles.emphasizeFont}>{snapshotImageCount}</span>,
            })}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions className={classNames(styles.dialogSection, styles.dialogActions)}>
        <Button
          id="create-snapshot-dialog-btn"
          className={styles.opsButton}
          color="primary"
          variant="contained"
          onClick={saveSnapshot}
          disabled={
            createDatasetSnapshot.isLoading ||
            snapshotName?.length === 0 ||
            snapshotName.length > 100
          }
        >
          {t('Save snapshot')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

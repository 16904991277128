import { customTrainingPath } from './model_train';

const ROOT = '/app';

export const CLEF_PATH = {
  root: ROOT,
  zeroAuthInstantLearning: '/public/visual_prompting',
  login: {
    main: `/login`,
    joinOrg: `/login/join_org`,
    createOrg: `/login/create_org`,
    inviteMember: `/login/invite_member`,
    forgetPassword: `/login/forget_password`,
    confirmResetPassword: `/login/confirm_reset_password`,
    sso: '/login/sso',
    forgetPasswordEmailDelivered: `/login/forget_password_email_delivered`,
  },
  signup: {
    main: '/signup',
    emailDelivered: '/signup/email_delivered',
    completeSignup: '/signup/complete',
  },
  home: `${ROOT}/home`,
  examples: `${ROOT}/examples`,
  // landing: `${ROOT}/landing`,
  organizationSettings: `${ROOT}/org_settings`,
  projects: `${ROOT}/projects`,
  members: `${ROOT}/members`,
  api: {
    auth: `${ROOT}/api_auth`,
    docs: `${ROOT}/api_docs`,
  },
  plan: `${ROOT}/plan`,
  planV2: `${ROOT}/plan_v2`,
  cancelPlan: `${ROOT}/cancel_plan`,
  // project specific routes
  projectSettings: `${ROOT}/project_settings`,
  projectSettingWithUserRole: `${ROOT}/project_setting_with_user_role`,
  myTasks: `${ROOT}/my_tasks`,
  data: {
    overview: `${ROOT}/data/stats`,
    defectBookEnhanced: `${ROOT}/data/defects`,
    defectBookPrint: `${ROOT}/data/defects/print`,
    dataBrowser: `${ROOT}/data/databrowser`,
    mediaDetails: `${ROOT}/data/media_details`,
    instantLearningLabel: `${ROOT}/data/visual_prompting/label`,
    task: `${ROOT}/data/task`,
    tags: `${ROOT}/data/tags`,
    metadata: `${ROOT}/data/metadata`,
    datasetSnapshot: `${ROOT}/data/dataset_snapshot`,
    customTraining: `${ROOT}${customTrainingPath}`,
  },
  dataset: {
    main: `${ROOT}/datasets`,
    detail: `${ROOT}/dataset`,
    pretrain: `${ROOT}/datasets/pretrain`,
    training: `${ROOT}/datasets/training`,
    exportedData: `${ROOT}/datasets/exported_data`,
    dataBrowser: `${ROOT}/datasets/databrowser`,
  },
  label: {
    main: `${ROOT}/label`,
    management: `${ROOT}/label/management`,
    overview: `${ROOT}/label/overview`,
    task: `${ROOT}/label/task`,
    tasks: `${ROOT}/label/tasks`,
    labelClass: `${ROOT}/label_class`,
    labelingQuorum: `${ROOT}/labeling_quorum`, // this will redirect to `${ROOT}/label/review`
    review: `${ROOT}/label/review`,
  },
  models: {
    list: `${ROOT}/models`,
    run: `${ROOT}/models/run`,
    view: `${ROOT}/models/view`,
    jobs: `${ROOT}/jobs`, // TODO: remove when merging features
    errorAnalysis: {
      list: `${ROOT}/models/error_analysis`,
      view: `${ROOT}/models/error_analysis/view`,
      // this is legacy model comparison
      compare: `${ROOT}/models/error_analysis/compare`,
    },
  },
  modelsV2: {
    list: `${ROOT}/models-v2`,
    // model comparison for fast & easy models
    comparisonReports: `${ROOT}/models-v2/comparison-reports`,
    failedJobs: `${ROOT}/models-v2/failed-jobs`,
  },
  deployment: {
    overview: `${ROOT}/deployment`,
  },
  shortcut: {
    createProject: `${ROOT}/shortcut/create_project`,
  },
};

const organizationLevelPaths: string[] = [
  CLEF_PATH.projects,
  CLEF_PATH.members,
  CLEF_PATH.api.auth,
  CLEF_PATH.api.docs,
  CLEF_PATH.home,
  CLEF_PATH.examples,
  CLEF_PATH.organizationSettings,
  CLEF_PATH.plan,
  CLEF_PATH.planV2,
  CLEF_PATH.cancelPlan,
];

export const isLegacyOrganizationLevelPath = (pathname: string) =>
  organizationLevelPaths.includes(pathname.startsWith(ROOT) ? pathname : `${ROOT}${pathname}`);

export const isLegacyPath = (pathname: string) => /^\/app\/[a-z]\w+/.test(pathname);

export enum SsoLoginOpener {
  Login = 'login',
  Signup = 'signup',
}

export enum JoinOrgOpener {
  Login = 'login',
  App = 'app',
}

export default CLEF_PATH;

import { useStreamyardId } from '@/hooks/useStreamyardId';
import { showLiveWebinarAtom } from '@/uiStates/liveWebinar';
import { Box, Fab, Typography, makeStyles } from '@material-ui/core';

import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';

const useStyles = makeStyles(theme => ({
  livestreamActionButton: {
    zIndex: 999999999,
    position: 'fixed',
    right: theme.spacing(5),
    bottom: theme.spacing(5),
  },
  livestreamPlayIconGroup: {
    position: 'relative',
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
  livestreamPlayIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 99,
    borderRadius: theme.spacing(3),
    borderWidth: 0,
    height: theme.spacing(6),
    width: theme.spacing(6),
    background: theme.palette.primary.main,
    animation: '$playIcon 1.25s alternate infinite ease-in',
  },
  livestreamPlayIconBackgroundRipple: {
    position: 'absolute',
    left: -theme.spacing(1),
    top: -theme.spacing(1),
    transform: 'translate(-50%, -50%)',
    zIndex: 9,
    height: theme.spacing(8),
    borderRadius: theme.spacing(4),
    width: theme.spacing(8),
    backgroundColor: theme.palette.greyModern[200],
    animation: '$ripple 2s infinite ease-in',
  },
  '@keyframes playIcon': {
    '0%': { transform: 'scale(0.9)' },
    '100%': { transform: 'scale(1.1)' },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(0, 0)',
      opacity: 1,
    },
    '20%': {
      transform: 'scale(0.25, 0.25)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.2, 1.2)',
      opacity: 0,
    },
  },
  //   button:focus:not(:active)::after {
  //     animation: ripple 1s ease-out;
  //   }
  livestreamText: {
    textTransform: 'none',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    paddingLeft: theme.spacing(1),
  },
}));

const FloatingActionButton = () => {
  const styles = useStyles();
  const [showLiveWebinar] = useAtom(showLiveWebinarAtom);
  const setShowLiveWebinar = useSetAtom(showLiveWebinarAtom);
  const streamyardId = useStreamyardId();

  if (!streamyardId) return null;

  return showLiveWebinar ? null : (
    <Fab
      onClick={() => setShowLiveWebinar(true)}
      variant="extended"
      className={styles.livestreamActionButton}
      color="primary"
      aria-label="add"
    >
      <Box component="span" className={styles.livestreamPlayIconGroup}>
        <PlayCircleFilledIcon fontSize="large" className={styles.livestreamPlayIcon} />
        <Box className={styles.livestreamPlayIconBackgroundRipple} />
      </Box>
      <Typography component="div" className={styles.livestreamText}>
        {t('Join the Livestream with Andrew Ng')}
      </Typography>
    </Fab>
  );
};

export default FloatingActionButton;

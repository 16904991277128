import {
  Annotation,
  AnnotationType,
  Label,
  LabelingType,
  MediaLevelLabel,
  MediaDetails,
} from '@clef/shared/types';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { Button, IconButton, AnnotationSourceType } from '@clef/client-library';
import React, { useCallback, useRef, useState, useMemo } from 'react';
import { useGoToNextMedia, useLabelingReviewState } from '../labelingReviewState';
import { useLabelingReviewStyles } from '../labelingReviewStyles';
import LabelInstance from './LabelInstance';
import CheckIcon from '@material-ui/icons/Check';
import classNames from 'classnames';
import {
  BitMapLabelingAnnotation,
  BoxLabelingAnnotation,
  useColorToDefectIdMap,
  useUnsavedLabelCheckDecorator,
} from '../../../components/Labeling/labelingState';
import MediaCanvasWrapper from '../../../components/Labeling/MediaCanvasWrapper';
import {
  AnnotationChangeType,
  MediaInteractiveCanvas,
  MediaInteractiveCanvasProps,
  useWindowEventListener,
} from '@clef/client-library';
import {
  canvasAnnotationToBoxAnnotation,
  convertToServerAnnotation,
  layerToBitMapAnnotationsAsync,
  serverAnnotationsToLabelingAnnotations,
} from '../../../components/Labeling/utils';
import { useDefectSelector } from '../../../store/defectState/actions';
import CloseIcon from '@material-ui/icons/Close';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { HintEvents, useHintSnackbar } from '../../../components/Labeling/HintSnackbar';
import ClassificationDefectSelector from '../../LabelingTask/components/ClassificationDefectSelector';
import useGetDefectColorById from '../../../hooks/defect/useGetDefectColorById';

const useSelfStyles = makeStyles(theme => ({
  labelWrapper: {
    flex: '0 0 280px',
    padding: theme.spacing(0, 6),
  },
  close: {
    padding: theme.spacing(3, 0, 2, 0),
    transform: `translateX(-${theme.spacing(2)}px)`,
  },
  labelTitle: {
    fontSize: '12px',
    color: '#69717A',
    paddingBottom: theme.spacing(1),
    fontWeight: 500,
  },
  container: {
    height: '100%',
  },
  main: {
    backgroundColor: '#F4F8FE',
    position: 'relative',
  },
  mediaWrapper: {
    background: '#CDD0D3',
  },
  mediaViewerBox: {
    backgroundColor: 'transparent',
  },
  save: {
    color: '#33ACB3',
    backgroundColor: '#fff',
    borderColor: theme.palette.grey[300],
    marginLeft: '14px',
  },
  cancel: {
    backgroundColor: '#fff',
    borderColor: theme.palette.grey[300],
  },
  toolCards: {
    position: 'absolute',
    left: theme.spacing(5),
    top: theme.spacing(5),
    zIndex: 1,
    display: 'flex',
    maxWidth: `calc(100% - 300px)`,
    flexWrap: 'wrap',
    '& > div': {
      marginBottom: theme.spacing(5),
    },
  },
  toolImageEnhance: {
    position: 'absolute',
    right: 20,
    top: 20,
    zIndex: 1,
  },
}));

type LabelingReviewEditProps = {
  labelingType?: LabelingType;
  onCancel: () => void;
  onSaveAccept?: (newLabel: Label) => void;
  selectedLabelId: number;
};

const LabelingReviewEdit: React.FC<LabelingReviewEditProps> = ({
  labelingType,
  onCancel: onCancelProps,
  onSaveAccept,
}) => {
  const styles = useLabelingReviewStyles();
  const mStyles = useSelfStyles();
  const goToNextMedia = useGoToNextMedia();
  const {
    state: { currentMediaId, reviewMediaList, editingLabel },
    dispatch,
  } = useLabelingReviewState();
  const mediaDetails = reviewMediaList.find(media => media.id === currentMediaId)!;
  const selectIds = useRef(new Map());
  const user = useTypedSelector(state => state.login.user);
  const allDefects = useDefectSelector();
  const getDefectColorById = useGetDefectColorById();
  const mediaInteractiveCanvasRef = useRef<MediaInteractiveCanvas | null>(null);
  const once = useRef(false);
  const annotations = serverAnnotationsToLabelingAnnotations(
    editingLabel?.annotations as Annotation[],
  );

  const [hasChange, setHasChange] = useState(false);

  const triggerHint = useHintSnackbar();
  const colorToDefectIdMap = useColorToDefectIdMap();

  const onAnnotationChanged = useCallback<
    NonNullable<MediaInteractiveCanvasProps['onAnnotationChanged']>
  >(
    async (newAnnotations, changeType, layer) => {
      const hasAnnotation = newAnnotations.length > 0;
      if (!once.current && hasAnnotation) {
        once.current = true;
        triggerHint(HintEvents.LabelInteractions);
      }
      let serverAnnotations = [] as Annotation[];
      if (labelingType === LabelingType.DefectSegmentation) {
        const annotations = ((await layerToBitMapAnnotationsAsync(
          layer,
          mediaDetails?.properties!,
          allDefects,
        )) || []) as BitMapLabelingAnnotation[];
        serverAnnotations = annotations.map((ann, index) => ({
          id: index, // does not matter, will be removed before sent to server
          ...convertToServerAnnotation(ann, AnnotationType.segmentation),
        }));
      } else if (labelingType === LabelingType.DefectBoundingBox) {
        const annotations = newAnnotations.map(ann =>
          canvasAnnotationToBoxAnnotation(ann, colorToDefectIdMap),
        );
        serverAnnotations = annotations.map((ann, index) => ({
          id: index, // does not matter, will be removed before sent to server
          ...convertToServerAnnotation(ann, AnnotationType.bndbox),
        }));
        if (changeType === AnnotationChangeType.Select) {
          if (newAnnotations.some(ann => ann.selected)) {
            triggerHint(HintEvents.SelectInstructions);
          } else {
            triggerHint();
          }
        }
      }
      setHasChange(true);
      dispatch(draft => {
        if (draft.editingLabel) {
          draft.editingLabel!.mediaLevelLabel = serverAnnotations.length
            ? MediaLevelLabel.NG
            : MediaLevelLabel.OK;
          draft.editingLabel!.annotations = serverAnnotations;
        }
      });
    },
    [allDefects, colorToDefectIdMap, dispatch, labelingType, mediaDetails.properties, triggerHint],
  );

  const updateMediaLevelLabel = useCallback(
    (mediaLevelLabel: MediaLevelLabel | undefined) => {
      dispatch(draft => {
        draft.editingLabel!.mediaLevelLabel = mediaLevelLabel;
      });
    },
    [dispatch],
  );

  const handleSave = useCallback(() => {
    setHasChange(false);
    onSaveAccept && onSaveAccept(editingLabel!);
    goToNextMedia();
  }, [editingLabel, goToNextMedia, onSaveAccept]);
  const onSave = useUnsavedLabelCheckDecorator(handleSave);
  const onCancel = useUnsavedLabelCheckDecorator(onCancelProps);

  const handleBeforeUnload = useCallback(
    (event: WindowEventMap['beforeunload']) => {
      if (hasChange) {
        event.preventDefault();
        event.returnValue = 'There are unsaved changes. Are you sure want to leave?';
      }
    },
    [hasChange],
  );
  useWindowEventListener('beforeunload', handleBeforeUnload);

  const onClassificationChanged = useCallback(
    (defectId: number) => {
      const newClassificationAnnotation: Annotation = {
        id: Date.now(),
        defectId,
        dataSchemaVersion: 3,
        annotationType: AnnotationType.classification,
      };
      setHasChange(true);
      dispatch(draft => {
        draft.editingLabel!.mediaLevelLabel = MediaLevelLabel.NG;
        draft.editingLabel!.annotations = [newClassificationAnnotation];
      });
    },
    [dispatch],
  );

  const newMediaDetails: MediaDetails = useMemo(() => {
    return {
      ...mediaDetails,
      label: editingLabel ?? null,
    };
  }, [editingLabel, mediaDetails]);

  if (!currentMediaId || !editingLabel) {
    return null;
  }

  return (
    <>
      <Box display="flex" className={mStyles.container}>
        <Box className={mStyles.labelWrapper}>
          <Box className={mStyles.close} onClick={onCancel}>
            <IconButton id="cancel-labeling-review-edit">
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography className={mStyles.labelTitle} variant="body1">
            {t('Reviewer Edits')}
          </Typography>
          <LabelInstance
            label={editingLabel}
            key={editingLabel.id}
            enableSelect={false}
            isVisible
            labelingType={labelingType}
            isSelected
            showSelectBox={false}
            onToggleVisibility={(visibility, visibleDefect) => {
              if (visibility && visibleDefect) {
                mediaInteractiveCanvasRef.current?.setHoveredAnnotation({
                  color: getDefectColorById(visibleDefect),
                  group: AnnotationSourceType.GroundTruth,
                });
              } else {
                mediaInteractiveCanvasRef.current?.setHoveredAnnotation();
              }
            }}
            onSelect={annId => {
              if (selectIds.current.has(annId)) {
                mediaInteractiveCanvasRef.current?.setSelectedAnnotation('');
                selectIds.current.delete(annId);
              } else {
                mediaInteractiveCanvasRef.current?.setSelectedAnnotation(annId);
                selectIds.current.set(annId, annId);
              }
            }}
            userName={user!.name!}
          />
        </Box>
        <Box className={mStyles.main} flex="1" display="flex" flexDirection="column">
          <div className={classNames(styles.centerPanelMedia, mStyles.mediaWrapper)}>
            <MediaCanvasWrapper
              isLabelMode
              mediaCanvasRef={mediaInteractiveCanvasRef}
              // Pass a memorized variable to avoid redundant useEffects retrigger
              mediaDetails={newMediaDetails}
              annotations={
                (annotations ?? []) as BoxLabelingAnnotation[] | BitMapLabelingAnnotation[]
              }
              mediaLevelLabel={editingLabel.mediaLevelLabel}
              onAnnotationChanged={onAnnotationChanged}
              updateMediaLevelLabel={updateMediaLevelLabel}
              showClassChip={false}
            />
          </div>
          <Box
            className={styles.centerPanelReview}
            marginRight={4}
            display="flex"
            justifyContent="flex-end"
          >
            <Grid item xs={6} container justifyContent="flex-end" alignItems="center" wrap="nowrap">
              <Box display="flex">
                <Button
                  id="cancel-labeling-review-edit"
                  className={mStyles.cancel}
                  variant="outlined"
                  color="secondary"
                  onClick={onCancel}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  id="save-labeling-review-edit"
                  variant="outlined"
                  className={mStyles.save}
                  onClick={onSave}
                  startIcon={<CheckIcon />}
                >
                  {t('Save and accept')}
                </Button>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
      {labelingType === LabelingType.DefectClassification && (
        <div className={styles.selectClass}>
          <ClassificationDefectSelector
            currentMediaId={currentMediaId}
            annotationData={{
              [currentMediaId]: annotations,
            }}
            onClassificationChanged={onClassificationChanged}
          />
        </div>
      )}
    </>
  );
};

export default LabelingReviewEdit;

import React, { CSSProperties, MutableRefObject, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useLabeledClassIds, useZeroAuthInstantLearningState } from './state';
import { useLabelingState } from '@/components/Labeling/labelingState';
import { getDefectColor } from '@/utils';
import { isDark } from '@clef/client-library';

const useStyles = makeStyles(theme => ({
  labelingTipBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 3),
    position: 'absolute',
    borderRadius: '13px 13px 13px 0',
    borderWidth: 2,
    borderStyle: 'solid',
    zIndex: 2,
    pointerEvent: 'none',
    transform: 'translateY(-100%)',
    marginLeft: theme.spacing(3),
    marginTop: -theme.spacing(3),
    whiteSpace: 'nowrap',
  },
}));

export type LabelingTipBoxProps = {
  tipBoxRef?: MutableRefObject<HTMLDivElement | null>;
  hasPredictionLabel?: boolean;
  hasCreatedLabel?: boolean;
};

const LabelingTipBox: React.FC<LabelingTipBoxProps> = ({
  tipBoxRef,
  hasPredictionLabel,
  hasCreatedLabel,
}) => {
  const styles = useStyles();
  const {
    state: { selectedDefect, toolMode, toolOptions },
  } = useLabelingState();
  const { state } = useZeroAuthInstantLearningState();
  const { mousePos } = state;
  const labeledClassIds = useLabeledClassIds('all');

  const tipMessage = useMemo(() => {
    if (labeledClassIds.size < 2) {
      return t('Paint {{smallArea}} over {{name}}', {
        smallArea: <strong>{t('a small area')}</strong>,
        name: selectedDefect?.name === 'buddy' ? 'Buddy' : `the ${selectedDefect?.name}`,
      });
    }
    // If having prediction but no new ground truth label, show the messages
    else if (hasPredictionLabel && !hasCreatedLabel) {
      return t('Notice wrong predictions? Label with correct Class');
    }

    return null;
  }, [hasCreatedLabel, hasPredictionLabel, labeledClassIds.size, selectedDefect?.name]);

  if (!mousePos || !tipMessage) {
    return null;
  }
  const defectColor = getDefectColor(selectedDefect);
  const style: CSSProperties = {
    left: mousePos.x + 'px',
    top: mousePos.y + 'px',
    borderColor: defectColor,
    backgroundColor: defectColor + 'b2',
    color: isDark(defectColor) ? 'white' : 'black',
  };

  if (!toolMode || toolOptions.erasing) return null;
  return (
    <div className={styles.labelingTipBox} style={style} ref={tipBoxRef}>
      {tipMessage}
    </div>
  );
};

export default LabelingTipBox;

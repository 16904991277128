import CLPAPI from '../../api/clp_api';
import fetchHookFactory from './fetchHookFactory';
import {
  PaginationOptions,
  EdgeMediaFilterOption,
  SortFieldInfo,
  EdgeRecord,
} from '@clef/shared/types';
import CLIAAPI from '../../api/clia_api';
import { CLOUD_DEVICE_HOSTNAME, DEFAULT_DEVICE_NAME } from '../../pages/deployment/constants';
import { DeviceType } from '../../pages/deployment/utils';
import { v4 as uuid } from 'uuid';

export const [useDeviceList] = fetchHookFactory(
  async (params: { projectId: number; isDefaultDeviceFallback?: boolean }) => {
    const { projectId, isDefaultDeviceFallback = false } = params;
    const devices = await CLPAPI.listDevices(projectId);
    if (isDefaultDeviceFallback) {
      const defaultDeviceId = String(projectId);
      const isThereADefaultDevice = devices.find(
        device => device.id === defaultDeviceId || device.name === DEFAULT_DEVICE_NAME,
      );
      // If there is no default device in the list, it will be backed up with a static minimum placeholder.
      // Users should see a default device as the first option after training the first model.
      // Default devices are created with the deployment of the first model.
      if (!isThereADefaultDevice) {
        return [
          ...devices,
          {
            projectId: defaultDeviceId,
            id: DEFAULT_DEVICE_NAME,
            name: DEFAULT_DEVICE_NAME,
            uid: uuid(),
            creationTime: '',
            status: 'Online',
            type: DeviceType.CloudInference,
            hostname: CLOUD_DEVICE_HOSTNAME,
          },
        ] as EdgeRecord[];
      }
    }
    return devices as EdgeRecord[];
  },
  'always-refresh',
);

export const [useDeviceMonitorMedias] = fetchHookFactory(
  (params: {
    projectId: number;
    deviceId: string;
    paginationOptions: PaginationOptions;
    filterOptions: EdgeMediaFilterOption[];
    sortOptions: Pick<SortFieldInfo, 'sortType' | 'sortValue'>;
  }) =>
    CLIAAPI.getMediaList(
      params.projectId,
      params.deviceId,
      params.paginationOptions,
      params.filterOptions,
      params.sortOptions,
    ),
  'always-refresh',
);

export const [useDeviceMonitorTotalMediaCount] = fetchHookFactory(
  (params: { projectId: number; deviceId: string; filterOptions: EdgeMediaFilterOption[] }) =>
    CLIAAPI.getTotalMediaCount(params.projectId, params.deviceId, params.filterOptions),
  'always-refresh',
);

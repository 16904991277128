import { Button, Typography } from '@clef/client-library';
import { Box, Paper } from '@material-ui/core';
import { Typography as MuiTypography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@clef/client-library';
import NonCommercialUseOnlyBlock from './NonCommercialUseOnlyBlock';
import { useStyles } from './style';
import { SharedDialogProps } from '.';

type DownloadModelDialogProps = SharedDialogProps & {
  showNonCommercialUseBlock: boolean;
  downloadModelBundle: () => void;
};

const DownloadModelDialog = (props: DownloadModelDialogProps) => {
  const { open, onClose, showNonCommercialUseBlock, downloadModelBundle } = props;
  const styles = useStyles();
  const [checked, setChecked] = useState(false);
  const bodyContent = t(
    'This project is activated for model downloads. You can download {{boldText}} from this project without additional charges.',
    {
      boldText: (
        <MuiTypography component="span" className={styles.boldText}>
          {t('as many models as you want')}
        </MuiTypography>
      ),
    },
  );

  return (
    <>
      <Dialog open={open} onClose={onClose} classes={{ paper: styles.paper }}>
        <Paper>
          <Box display="flex" flexDirection={'column'}>
            <Box padding={5} display="flex" flexDirection={'row'} justifyContent={'space-between'}>
              <Box>
                <Typography variant="h2_semibold">{t('Download Model')}</Typography>
              </Box>
              <IconButton
                id="close-browser-detection-snack-bar"
                size="small"
                aria-label="close"
                color="inherit"
                onClick={onClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            <Box paddingX={5} paddingBottom={5}>
              <Typography variant="body_regular">{bodyContent}</Typography>
            </Box>
            {showNonCommercialUseBlock && (
              <Box paddingX={5}>
                <NonCommercialUseOnlyBlock
                  checked={checked}
                  onCheckedChange={newValue => {
                    setChecked(newValue);
                  }}
                />
              </Box>
            )}
            <Box padding={5} width="100%">
              <Button
                className={styles.button}
                id={'download-model-btn'}
                color="primary"
                variant="contained"
                disabled={showNonCommercialUseBlock ? !checked : false}
                onClick={() => {
                  onClose();
                  downloadModelBundle();
                }}
              >
                {t('Download')}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Dialog>
    </>
  );
};

export default DownloadModelDialog;

import { useTypedSelector } from '@/hooks/useTypedSelector';
import { ProjectId, Tag } from '@clef/shared/types';
import { useQuery } from '@tanstack/react-query';
import { TagAPI } from '@/api/tag_api';
import { ApiErrorType } from '@/api/base_api';

export const tagsQueryKey = {
  all: ['tags'] as const,
  listByProjectId: (projectId: ProjectId) =>
    [...tagsQueryKey.all, 'listByProjectId', projectId] as const,
};

export const useGetProjectTagsQuery = () => {
  const selectedProjectId = useTypedSelector(state => state.project.selectedProjectId);
  return useQuery<Tag[], ApiErrorType>({
    queryKey: tagsQueryKey.listByProjectId(selectedProjectId!),
    queryFn: async () => {
      const response = await TagAPI.tagsByProjectId(selectedProjectId!);
      return response.data.filter(tag => !tag.isArchived);
    },
    enabled: !!selectedProjectId,
  });
};

import React, { useState } from 'react';
import { Typography } from '@clef/client-library';
import { Accordion, AccordionDetails, AccordionSummary, Box, makeStyles } from '@material-ui/core';
import TrainingInformationPanel from './TrainingInformationPanel';
import { RegisteredModel, DatasetVersion } from '@clef/shared/types';
import ModelTrainingInProgress from '@/pages/DataBrowser/ModelPerformance/ModelTrainingInProgressV2';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

type ModelTrainingInProgressPanelProps = {
  model: RegisteredModel;
  datasetVersion: DatasetVersion;
};

const useStyles = makeStyles(theme => ({
  modelTrainingInProgressContainer: {
    width: 720,
  },
  accordionRoot: {
    marginTop: theme.spacing(4),
  },
}));

const ModelTrainingInProgressPanel = (props: ModelTrainingInProgressPanelProps) => {
  const { model, datasetVersion } = props;
  const styles = useStyles();
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleAccordionExpandChange = (_event: React.ChangeEvent<{}>, curExpanded: boolean) => {
    setExpanded(curExpanded);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box className={styles.modelTrainingInProgressContainer}>
        <ModelTrainingInProgress modelId={model.id} showLearningGraphBlock={true} />
      </Box>
      <Accordion
        classes={{ root: styles.accordionRoot }}
        expanded={expanded}
        onChange={handleAccordionExpandChange}
      >
        <AccordionSummary>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
            {expanded ? <ExpandLess /> : <ExpandMore />}
            <Typography variant="body_bold">{t('Show Training Configuration')}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box marginTop={4}>
            <TrainingInformationPanel
              model={model}
              datasetVersion={datasetVersion}
              hideCharts={true}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ModelTrainingInProgressPanel;

import { ApiResponseLoader } from '@clef/client-library';
import { Typography, Grid } from '@material-ui/core';
import { Button } from '@clef/client-library';
import Launch from '@material-ui/icons/Launch';
import React, { useMemo } from 'react';
import CLEF_PATH from '../../../constants/path';
import { useFetchDefectBookOverviewApi } from '../../../hooks/api/useDefectBookApi';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { DrawerTerminologyExample } from './DrawerTerminologyExample';
import { useLabelingDrawerStyles } from '../labelingDrawerStyles';
import { LoginState } from '../../../store/types';

export const LabelingInstructionsDrawer = () => {
  const styles = useLabelingDrawerStyles();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const [defectBookOverview, defectBookOverviewLoading, defectBookOverviewError] =
    useFetchDefectBookOverviewApi(selectedProject?.id);

  const labelingInstructionsParams = new URLSearchParams({
    tab: 'labeling_instruction',
  });
  const user = useTypedSelector(state => state.login.user)!;
  const defectBookUrl = useMemo(() => {
    const url = `${CLEF_PATH.data.defectBookEnhanced}?${labelingInstructionsParams.toString()}`;
    return url.replace(
      'app',
      `app/${(user as LoginState['user'])?.orgId}/pr/${selectedProject?.id}`,
    );
  }, [labelingInstructionsParams, selectedProject, user]);

  return (
    <>
      <Typography variant="h3" className={styles.drawerTitle}>
        {t('Labeling instructions')}
      </Typography>
      <ApiResponseLoader
        response={defectBookOverview}
        loading={defectBookOverviewLoading}
        error={defectBookOverviewError}
        defaultHeight={300}
      >
        {({ termExamples }) => (
          <Grid container spacing={3}>
            {termExamples.map(termId => (
              <Grid item xs={12} key={termId}>
                <DrawerTerminologyExample termId={termId} />
              </Grid>
            ))}
          </Grid>
        )}
      </ApiResponseLoader>
      <div className={styles.bottomLinkContainer}>
        <Button
          id="view-full-class-definition"
          color="primary"
          variant="text"
          role="link"
          startIcon={<Launch fontSize="small" />}
          target="_blank"
          href={defectBookUrl}
        >
          <strong>{t('View Full Class Definition')}</strong>
        </Button>
      </div>
    </>
  );
};

import { User } from '@clef/shared/types';

/**
 * Move array item from startIndex to endIndex
 * Algorithm:
 *   Remove item at startIndex, saving a copy of the value
 *   Insert copied value at endIndex
 * Example:
 *   reorder([1, 2, 3, 4, 5], 0, 3)
 *   -> [2, 3, 4, 1, 5]
 */
export function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  if (startIndex > list.length - 1 || startIndex < 0) return list;
  if (endIndex > list.length - 1 || endIndex < 0) return list;
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export const sortByName = (a: User, b: User) => {
  const capitalizedA = a.name.toUpperCase();
  const capitalizedB = b.name.toUpperCase();
  if (capitalizedA < capitalizedB) {
    return -1;
  }
  if (capitalizedA === capitalizedB) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name == b.name) {
      return 0;
    }
    if (a.name > b.name) {
      return 1;
    }
  }
  return 1;
};

export function difference<T>(raw: T[], other: T[]): T[] {
  return raw.filter(x => !other.includes(x));
}

/*
    Ex: chunk([1,2,3,4,5], 2)  => [ [1,2], [3,4], [5] ]
 */
// @ts-ignore
export function chunk(array, size) {
  const chunked_arr = [];
  const copied = [...array]; // ES6 destructuring
  const numOfChild = Math.ceil(copied.length / size); // Round up to the nearest integer
  for (let i = 0; i < numOfChild; i++) {
    chunked_arr.push(copied.splice(0, size));
  }
  return chunked_arr;
}

/**
 * transpose matrices(two-dimensional array)
 * @param array
 */
const transPose = <T extends unknown>(array: T[][]): T[][] => {
  const result: T[][] = [];
  for (let i = 0; i < array[0].length; i++) {
    const row: T[] = [];
    for (let j = 0; j < array.length; j++) {
      if (array[j][i] !== undefined) {
        row.push(array[j][i]);
      }
    }
    result.push(row);
  }
  return result;
};

/**
 * Group items into batches,as page mode:
 * firstly, fill a row, then fill next row,if reach itemCountPerColumn * itemCountPerRow,recorded as a page.
 * then add a new page
 * E.g. we have array = [ 0, 1, 2, 3, 4 ]; groupSize = 2;itemCountPerRow = 3;
 * [[0,1],[2,3]] is first page,[[4]] is next page
 * Then We need to transpose matrix(two-dimensional array) to the data format required by the media display component
 * The last image format array should be [[0,2],[1,3],[4]]
 * @param array
 * @param itemCountPerColumn- the max item number in a group(column).
 * @param itemCountPerRow- the number of items that can fit in a row without a scroll bar.
 */
export const groupItems = <T extends unknown>(
  array: T[],
  itemCountPerColumn: number,
  itemCountPerRow: number,
): T[][] => {
  let currentBatch: T[] = [];
  let pageItems: T[][] = [];
  const groupedItems: T[][] = [];

  array.forEach(item => {
    currentBatch.push(item);
    if (currentBatch.length === itemCountPerRow) {
      pageItems.push([...currentBatch]);
      currentBatch = [];
      if (pageItems.length === itemCountPerColumn) {
        groupedItems.push(...transPose(pageItems));
        pageItems = [];
      }
    }
  });

  if (currentBatch.length) {
    pageItems.push([...currentBatch]);
  }

  if (pageItems.length) {
    groupedItems.push(...transPose(pageItems));
  }

  return groupedItems;
};

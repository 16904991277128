import React from 'react';
import { SvgIcon } from '@material-ui/core';

// Purple Yellow Green Pink Blue Teal
const PURPL_CLOUD_ICON = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="6" fill="#EBE9FE" />
    <g clipPath="url(#clip0_1071_3187)">
      <path
        d="M28.75 27.875H25.25C25.0179 27.875 24.7954 27.7828 24.6313 27.6187C24.4672 27.4546 24.375 27.2321 24.375 27V18.25C24.375 18.0179 24.4672 17.7954 24.6313 17.6313C24.7954 17.4672 25.0179 17.375 25.25 17.375C25.4821 17.375 25.7046 17.4672 25.8687 17.6313C26.0328 17.7954 26.125 18.0179 26.125 18.25V26.125H28.75C28.9821 26.125 29.2046 26.2172 29.3687 26.3813C29.5328 26.5454 29.625 26.7679 29.625 27C29.625 27.2321 29.5328 27.4546 29.3687 27.6187C29.2046 27.7828 28.9821 27.875 28.75 27.875Z"
        fill="#BDB4FE"
      />
      <path
        d="M25.25 26.125V17.375C25.0179 17.375 24.7954 17.4672 24.6313 17.6313C24.4672 17.7954 24.375 18.0179 24.375 18.25V27C24.375 27.2321 24.4672 27.4546 24.6313 27.6187C24.7954 27.7828 25.0179 27.875 25.25 27.875H28.75C28.9821 27.875 29.2046 27.7828 29.3687 27.6187C29.5328 27.4546 29.625 27.2321 29.625 27H26.125C25.8929 27 25.6704 26.9078 25.5063 26.7437C25.3422 26.5796 25.25 26.3571 25.25 26.125Z"
        fill="#7A5AF8"
      />
      <path
        d="M14.75 29.625H11.25C11.0179 29.625 10.7954 29.5328 10.6313 29.3687C10.4672 29.2046 10.375 28.9821 10.375 28.75C10.375 28.5179 10.4672 28.2954 10.6313 28.1313C10.7954 27.9672 11.0179 27.875 11.25 27.875H13.875V20C13.875 19.7679 13.9672 19.5454 14.1313 19.3813C14.2954 19.2172 14.5179 19.125 14.75 19.125C14.9821 19.125 15.2046 19.2172 15.3687 19.3813C15.5328 19.5454 15.625 19.7679 15.625 20V28.75C15.625 28.9821 15.5328 29.2046 15.3687 29.3687C15.2046 29.5328 14.9821 29.625 14.75 29.625Z"
        fill="#BDB4FE"
      />
      <path
        d="M14.75 19.125V27.875C14.75 28.1071 14.6578 28.3296 14.4937 28.4937C14.3296 28.6578 14.1071 28.75 13.875 28.75H10.375C10.375 28.9821 10.4672 29.2046 10.6313 29.3687C10.7954 29.5328 11.0179 29.625 11.25 29.625H14.75C14.9821 29.625 15.2046 29.5328 15.3687 29.3687C15.5328 29.2046 15.625 28.9821 15.625 28.75V20C15.625 19.7679 15.5328 19.5454 15.3687 19.3813C15.2046 19.2172 14.9821 19.125 14.75 19.125Z"
        fill="#7A5AF8"
      />
      <path
        d="M20 29.625C19.7679 29.625 19.5454 29.5328 19.3813 29.3687C19.2172 29.2046 19.125 28.9821 19.125 28.75V20C19.125 19.7679 19.2172 19.5454 19.3813 19.3813C19.5454 19.2172 19.7679 19.125 20 19.125C20.2321 19.125 20.4546 19.2172 20.6187 19.3813C20.7828 19.5454 20.875 19.7679 20.875 20V28.75C20.875 28.9821 20.7828 29.2046 20.6187 29.3687C20.4546 29.5328 20.2321 29.625 20 29.625Z"
        fill="#BDB4FE"
      />
      <path
        d="M20 28.75V20C20.0024 19.8488 20.044 19.7008 20.1207 19.5704C20.1974 19.4401 20.3065 19.3318 20.4375 19.2562C20.3059 19.1745 20.1549 19.1292 20 19.125C19.7679 19.125 19.5454 19.2172 19.3813 19.3813C19.2172 19.5454 19.125 19.7679 19.125 20V28.75C19.125 28.9821 19.2172 29.2046 19.3813 29.3687C19.5454 29.5328 19.7679 29.625 20 29.625C20.1549 29.6208 20.3059 29.5755 20.4375 29.4938C20.3065 29.4182 20.1974 29.3099 20.1207 29.1796C20.044 29.0492 20.0024 28.9012 20 28.75Z"
        fill="#7A5AF8"
      />
      <path
        d="M30.5 29.625C31.9497 29.625 33.125 28.4497 33.125 27C33.125 25.5503 31.9497 24.375 30.5 24.375C29.0503 24.375 27.875 25.5503 27.875 27C27.875 28.4497 29.0503 29.625 30.5 29.625Z"
        fill="#BDB4FE"
      />
      <path
        d="M31.0513 28.75C30.3551 28.75 29.6874 28.4734 29.1951 27.9811C28.7028 27.4889 28.4263 26.8212 28.4263 26.125C28.4259 25.8076 28.4852 25.4929 28.6013 25.1975C28.3388 25.4812 28.1423 25.8193 28.0256 26.1877C27.909 26.5561 27.8751 26.9457 27.9265 27.3287C27.9778 27.7117 28.113 28.0787 28.3226 28.4034C28.5321 28.728 28.8107 29.0025 29.1386 29.207C29.4664 29.4116 29.8354 29.5413 30.2191 29.5868C30.6028 29.6323 30.9919 29.5925 31.3585 29.4703C31.7251 29.3481 32.0602 29.1464 32.3398 28.8797C32.6195 28.613 32.8368 28.2879 32.9763 27.9275C32.729 28.1893 32.4305 28.3974 32.0993 28.5389C31.7681 28.6804 31.4114 28.7523 31.0513 28.75Z"
        fill="#7A5AF8"
      />
      <path
        d="M30.5 12.5012C30.1114 11.8527 29.5613 11.3159 28.9034 10.9433C28.2456 10.5706 27.5023 10.3748 26.7462 10.375C26.5203 10.3745 26.2947 10.395 26.0725 10.4362C25.4067 9.76616 24.5725 9.28811 23.6578 9.0525C22.743 8.81688 21.7816 8.83243 20.875 9.0975C20.2238 8.3955 19.4345 7.8357 18.5566 7.45323C17.6787 7.07077 16.7313 6.87391 15.7737 6.875C14.0939 6.87614 12.4705 7.48133 11.1999 8.58009C9.92923 9.67886 9.09607 11.1979 8.8525 12.86C8.24782 13.2561 7.75072 13.7959 7.40564 14.4311C7.06055 15.0663 6.87824 15.7771 6.875 16.5C6.875 17.6603 7.33594 18.7731 8.15641 19.5936C8.56266 19.9998 9.04496 20.3221 9.57576 20.542C10.1066 20.7618 10.6755 20.875 11.25 20.875H28.75C29.7506 20.8693 30.7191 20.5208 31.4939 19.8875C32.2687 19.2543 32.8029 18.3745 33.0076 17.395C33.2123 16.4156 33.0751 15.3955 32.6188 14.5049C32.1624 13.6144 31.4146 12.9072 30.5 12.5012Z"
        fill="#BDB4FE"
      />
      <path
        d="M30.5 12.5013C30.1114 11.8527 29.5613 11.3159 28.9034 10.9433C28.2456 10.5706 27.5023 10.3748 26.7463 10.375C26.5203 10.3745 26.2947 10.395 26.0725 10.4363C25.4067 9.76617 24.5725 9.28812 23.6578 9.0525C22.743 8.81689 21.7816 8.83243 20.875 9.0975C20.1819 8.36068 19.3374 7.78283 18.3995 7.40373C17.4617 7.02464 16.4528 6.85331 15.4424 6.90154C14.432 6.94977 13.4441 7.21643 12.5466 7.68313C11.6491 8.14984 10.8635 8.80551 10.2438 9.605C11.618 8.62152 13.3016 8.16837 14.9838 8.32919C16.666 8.49001 18.2333 9.25396 19.3963 10.48C20.3009 10.2155 21.26 10.1995 22.173 10.4335C23.086 10.6675 23.9191 11.143 24.585 11.81C24.8062 11.7908 25.0284 11.7879 25.25 11.8013C26.0035 11.7972 26.7448 11.9905 27.4004 12.362C28.0559 12.7335 28.6027 13.2702 28.9863 13.9188C29.7661 14.2591 30.4298 14.8195 30.896 15.5313C31.3623 16.2431 31.6108 17.0754 31.6113 17.9263C31.6105 18.7513 31.3737 19.559 30.9288 20.2538C31.618 19.8458 32.182 19.2564 32.5593 18.55C32.9366 17.8435 33.1127 17.047 33.0685 16.2473C33.0243 15.4476 32.7614 14.6754 32.3085 14.0148C31.8556 13.3543 31.23 12.8307 30.5 12.5013Z"
        fill="#7A5AF8"
      />
      <path
        d="M20 33.125C21.4497 33.125 22.625 31.9497 22.625 30.5C22.625 29.0503 21.4497 27.875 20 27.875C18.5503 27.875 17.375 29.0503 17.375 30.5C17.375 31.9497 18.5503 33.125 20 33.125Z"
        fill="#BDB4FE"
      />
      <path
        d="M20.875 32.25C20.1788 32.25 19.5111 31.9734 19.0188 31.4812C18.5266 30.9889 18.25 30.3212 18.25 29.625C18.2517 29.145 18.3881 28.6751 18.6437 28.2688C18.259 28.5009 17.9402 28.828 17.7181 29.2187C17.4959 29.6094 17.3778 30.0506 17.375 30.5C17.375 31.1962 17.6516 31.8639 18.1438 32.3562C18.6361 32.8484 19.3038 33.125 20 33.125C20.4494 33.1222 20.8906 33.0041 21.2813 32.7819C21.672 32.5598 21.9991 32.241 22.2312 31.8563C21.8249 32.1119 21.355 32.2483 20.875 32.25Z"
        fill="#7A5AF8"
      />
      <path
        d="M9.5 31.375C10.9497 31.375 12.125 30.1997 12.125 28.75C12.125 27.3003 10.9497 26.125 9.5 26.125C8.05025 26.125 6.875 27.3003 6.875 28.75C6.875 30.1997 8.05025 31.375 9.5 31.375Z"
        fill="#BDB4FE"
      />
      <path
        d="M10.375 30.5C9.67881 30.5 9.01113 30.2234 8.51884 29.7312C8.02656 29.2389 7.75 28.5712 7.75 27.875C7.75169 27.395 7.88812 26.9251 8.14375 26.5188C7.75895 26.7509 7.44021 27.078 7.21806 27.4687C6.99591 27.8594 6.87779 28.3006 6.875 28.75C6.875 29.4462 7.15156 30.1139 7.64384 30.6062C8.13613 31.0984 8.80381 31.375 9.5 31.375C9.94942 31.3722 10.3906 31.2541 10.7813 31.0319C11.172 30.8098 11.4991 30.491 11.7313 30.1063C11.3249 30.3619 10.855 30.4983 10.375 30.5Z"
        fill="#7A5AF8"
      />
    </g>
    <defs>
      <clipPath id="clip0_1071_3187">
        <rect width="28" height="28" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

const YELLOW_CLOUD_ICON = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="6" fill="#FEF7C3" />
    <g clipPath="url(#clip0_1071_3318)">
      <path
        d="M28.75 27.875H25.25C25.0179 27.875 24.7954 27.7828 24.6313 27.6187C24.4672 27.4546 24.375 27.2321 24.375 27V18.25C24.375 18.0179 24.4672 17.7954 24.6313 17.6313C24.7954 17.4672 25.0179 17.375 25.25 17.375C25.4821 17.375 25.7046 17.4672 25.8687 17.6313C26.0328 17.7954 26.125 18.0179 26.125 18.25V26.125H28.75C28.9821 26.125 29.2046 26.2172 29.3687 26.3813C29.5328 26.5454 29.625 26.7679 29.625 27C29.625 27.2321 29.5328 27.4546 29.3687 27.6187C29.2046 27.7828 28.9821 27.875 28.75 27.875Z"
        fill="#FAC415"
      />
      <path
        d="M25.25 26.125V17.375C25.0179 17.375 24.7954 17.4672 24.6313 17.6313C24.4672 17.7954 24.375 18.0179 24.375 18.25V27C24.375 27.2321 24.4672 27.4546 24.6313 27.6187C24.7954 27.7828 25.0179 27.875 25.25 27.875H28.75C28.9821 27.875 29.2046 27.7828 29.3687 27.6187C29.5328 27.4546 29.625 27.2321 29.625 27H26.125C25.8929 27 25.6704 26.9078 25.5063 26.7437C25.3422 26.5796 25.25 26.3571 25.25 26.125Z"
        fill="#CA8504"
      />
      <path
        d="M14.75 29.625H11.25C11.0179 29.625 10.7954 29.5328 10.6313 29.3687C10.4672 29.2046 10.375 28.9821 10.375 28.75C10.375 28.5179 10.4672 28.2954 10.6313 28.1313C10.7954 27.9672 11.0179 27.875 11.25 27.875H13.875V20C13.875 19.7679 13.9672 19.5454 14.1313 19.3813C14.2954 19.2172 14.5179 19.125 14.75 19.125C14.9821 19.125 15.2046 19.2172 15.3687 19.3813C15.5328 19.5454 15.625 19.7679 15.625 20V28.75C15.625 28.9821 15.5328 29.2046 15.3687 29.3687C15.2046 29.5328 14.9821 29.625 14.75 29.625Z"
        fill="#FAC415"
      />
      <path
        d="M14.75 19.125V27.875C14.75 28.1071 14.6578 28.3296 14.4937 28.4937C14.3296 28.6578 14.1071 28.75 13.875 28.75H10.375C10.375 28.9821 10.4672 29.2046 10.6313 29.3687C10.7954 29.5328 11.0179 29.625 11.25 29.625H14.75C14.9821 29.625 15.2046 29.5328 15.3687 29.3687C15.5328 29.2046 15.625 28.9821 15.625 28.75V20C15.625 19.7679 15.5328 19.5454 15.3687 19.3813C15.2046 19.2172 14.9821 19.125 14.75 19.125Z"
        fill="#CA8504"
      />
      <path
        d="M20 29.625C19.7679 29.625 19.5454 29.5328 19.3813 29.3687C19.2172 29.2046 19.125 28.9821 19.125 28.75V20C19.125 19.7679 19.2172 19.5454 19.3813 19.3813C19.5454 19.2172 19.7679 19.125 20 19.125C20.2321 19.125 20.4546 19.2172 20.6187 19.3813C20.7828 19.5454 20.875 19.7679 20.875 20V28.75C20.875 28.9821 20.7828 29.2046 20.6187 29.3687C20.4546 29.5328 20.2321 29.625 20 29.625Z"
        fill="#FAC415"
      />
      <path
        d="M20 28.75V20C20.0024 19.8488 20.044 19.7008 20.1207 19.5704C20.1974 19.4401 20.3065 19.3318 20.4375 19.2562C20.3059 19.1745 20.1549 19.1292 20 19.125C19.7679 19.125 19.5454 19.2172 19.3813 19.3813C19.2172 19.5454 19.125 19.7679 19.125 20V28.75C19.125 28.9821 19.2172 29.2046 19.3813 29.3687C19.5454 29.5328 19.7679 29.625 20 29.625C20.1549 29.6208 20.3059 29.5755 20.4375 29.4938C20.3065 29.4182 20.1974 29.3099 20.1207 29.1796C20.044 29.0492 20.0024 28.9012 20 28.75Z"
        fill="#CA8504"
      />
      <path
        d="M30.5 29.625C31.9497 29.625 33.125 28.4497 33.125 27C33.125 25.5503 31.9497 24.375 30.5 24.375C29.0503 24.375 27.875 25.5503 27.875 27C27.875 28.4497 29.0503 29.625 30.5 29.625Z"
        fill="#FAC415"
      />
      <path
        d="M31.0513 28.75C30.3551 28.75 29.6874 28.4734 29.1951 27.9811C28.7028 27.4889 28.4263 26.8212 28.4263 26.125C28.4259 25.8076 28.4852 25.4929 28.6013 25.1975C28.3388 25.4812 28.1423 25.8193 28.0256 26.1877C27.909 26.5561 27.8751 26.9457 27.9265 27.3287C27.9778 27.7117 28.113 28.0787 28.3226 28.4034C28.5321 28.728 28.8107 29.0025 29.1386 29.207C29.4664 29.4116 29.8354 29.5413 30.2191 29.5868C30.6028 29.6323 30.9919 29.5925 31.3585 29.4703C31.7251 29.3481 32.0602 29.1464 32.3398 28.8797C32.6195 28.613 32.8368 28.2879 32.9763 27.9275C32.729 28.1893 32.4305 28.3974 32.0993 28.5389C31.7681 28.6804 31.4114 28.7523 31.0513 28.75Z"
        fill="#CA8504"
      />
      <path
        d="M30.5 12.5012C30.1114 11.8527 29.5613 11.3159 28.9034 10.9433C28.2456 10.5706 27.5023 10.3748 26.7462 10.375C26.5203 10.3745 26.2947 10.395 26.0725 10.4362C25.4067 9.76616 24.5725 9.28811 23.6578 9.0525C22.743 8.81688 21.7816 8.83243 20.875 9.0975C20.2238 8.3955 19.4345 7.8357 18.5566 7.45323C17.6787 7.07077 16.7313 6.87391 15.7737 6.875C14.0939 6.87614 12.4705 7.48133 11.1999 8.58009C9.92923 9.67886 9.09607 11.1979 8.8525 12.86C8.24782 13.2561 7.75072 13.7959 7.40564 14.4311C7.06055 15.0663 6.87824 15.7771 6.875 16.5C6.875 17.6603 7.33594 18.7731 8.15641 19.5936C8.56266 19.9998 9.04496 20.3221 9.57576 20.542C10.1066 20.7618 10.6755 20.875 11.25 20.875H28.75C29.7506 20.8693 30.7191 20.5208 31.4939 19.8875C32.2687 19.2543 32.8029 18.3745 33.0076 17.395C33.2123 16.4156 33.0751 15.3955 32.6188 14.5049C32.1624 13.6144 31.4146 12.9072 30.5 12.5012Z"
        fill="#FAC415"
      />
      <path
        d="M30.5 12.5013C30.1114 11.8527 29.5613 11.3159 28.9034 10.9433C28.2456 10.5706 27.5023 10.3748 26.7463 10.375C26.5203 10.3745 26.2947 10.395 26.0725 10.4363C25.4067 9.76617 24.5725 9.28812 23.6578 9.0525C22.743 8.81689 21.7816 8.83243 20.875 9.0975C20.1819 8.36068 19.3374 7.78283 18.3995 7.40373C17.4617 7.02464 16.4528 6.85331 15.4424 6.90154C14.432 6.94977 13.4441 7.21643 12.5466 7.68313C11.6491 8.14984 10.8635 8.80551 10.2438 9.605C11.618 8.62152 13.3016 8.16837 14.9838 8.32919C16.666 8.49001 18.2333 9.25396 19.3963 10.48C20.3009 10.2155 21.26 10.1995 22.173 10.4335C23.086 10.6675 23.9191 11.143 24.585 11.81C24.8062 11.7908 25.0284 11.7879 25.25 11.8013C26.0035 11.7972 26.7448 11.9905 27.4004 12.362C28.0559 12.7335 28.6027 13.2702 28.9863 13.9188C29.7661 14.2591 30.4298 14.8195 30.896 15.5313C31.3623 16.2431 31.6108 17.0754 31.6113 17.9263C31.6105 18.7513 31.3737 19.559 30.9288 20.2538C31.618 19.8458 32.182 19.2564 32.5593 18.55C32.9366 17.8435 33.1127 17.047 33.0685 16.2473C33.0243 15.4476 32.7614 14.6754 32.3085 14.0148C31.8556 13.3543 31.23 12.8307 30.5 12.5013Z"
        fill="#CA8504"
      />
      <path
        d="M20 33.125C21.4497 33.125 22.625 31.9497 22.625 30.5C22.625 29.0503 21.4497 27.875 20 27.875C18.5503 27.875 17.375 29.0503 17.375 30.5C17.375 31.9497 18.5503 33.125 20 33.125Z"
        fill="#FAC415"
      />
      <path
        d="M20.875 32.25C20.1788 32.25 19.5111 31.9734 19.0188 31.4812C18.5266 30.9889 18.25 30.3212 18.25 29.625C18.2517 29.145 18.3881 28.6751 18.6437 28.2688C18.259 28.5009 17.9402 28.828 17.7181 29.2187C17.4959 29.6094 17.3778 30.0506 17.375 30.5C17.375 31.1962 17.6516 31.8639 18.1438 32.3562C18.6361 32.8484 19.3038 33.125 20 33.125C20.4494 33.1222 20.8906 33.0041 21.2813 32.7819C21.672 32.5598 21.9991 32.241 22.2312 31.8563C21.8249 32.1119 21.355 32.2483 20.875 32.25Z"
        fill="#CA8504"
      />
      <path
        d="M9.5 31.375C10.9497 31.375 12.125 30.1997 12.125 28.75C12.125 27.3003 10.9497 26.125 9.5 26.125C8.05025 26.125 6.875 27.3003 6.875 28.75C6.875 30.1997 8.05025 31.375 9.5 31.375Z"
        fill="#FAC415"
      />
      <path
        d="M10.375 30.5C9.67881 30.5 9.01113 30.2234 8.51885 29.7312C8.02656 29.2389 7.75 28.5712 7.75 27.875C7.75169 27.395 7.88812 26.9251 8.14375 26.5188C7.75895 26.7509 7.44022 27.078 7.21806 27.4687C6.99591 27.8594 6.87779 28.3006 6.875 28.75C6.875 29.4462 7.15156 30.1139 7.64384 30.6062C8.13613 31.0984 8.80381 31.375 9.5 31.375C9.94942 31.3722 10.3906 31.2541 10.7813 31.0319C11.172 30.8098 11.4991 30.491 11.7313 30.1063C11.3249 30.3619 10.855 30.4983 10.375 30.5Z"
        fill="#CA8504"
      />
    </g>
    <defs>
      <clipPath id="clip0_1071_3318">
        <rect width="28" height="28" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

const GREEN_CLOUD_ICON = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="6" fill="#D1FADF" />
    <g clipPath="url(#clip0_1071_3302)">
      <path
        d="M28.75 27.875H25.25C25.0179 27.875 24.7954 27.7828 24.6313 27.6187C24.4672 27.4546 24.375 27.2321 24.375 27V18.25C24.375 18.0179 24.4672 17.7954 24.6313 17.6313C24.7954 17.4672 25.0179 17.375 25.25 17.375C25.4821 17.375 25.7046 17.4672 25.8687 17.6313C26.0328 17.7954 26.125 18.0179 26.125 18.25V26.125H28.75C28.9821 26.125 29.2046 26.2172 29.3687 26.3813C29.5328 26.5454 29.625 26.7679 29.625 27C29.625 27.2321 29.5328 27.4546 29.3687 27.6187C29.2046 27.7828 28.9821 27.875 28.75 27.875Z"
        fill="#6CE9A6"
      />
      <path
        d="M25.25 26.125V17.375C25.0179 17.375 24.7954 17.4672 24.6313 17.6313C24.4672 17.7954 24.375 18.0179 24.375 18.25V27C24.375 27.2321 24.4672 27.4546 24.6313 27.6187C24.7954 27.7828 25.0179 27.875 25.25 27.875H28.75C28.9821 27.875 29.2046 27.7828 29.3687 27.6187C29.5328 27.4546 29.625 27.2321 29.625 27H26.125C25.8929 27 25.6704 26.9078 25.5063 26.7437C25.3422 26.5796 25.25 26.3571 25.25 26.125Z"
        fill="#12B76A"
      />
      <path
        d="M14.75 29.625H11.25C11.0179 29.625 10.7954 29.5328 10.6313 29.3687C10.4672 29.2046 10.375 28.9821 10.375 28.75C10.375 28.5179 10.4672 28.2954 10.6313 28.1313C10.7954 27.9672 11.0179 27.875 11.25 27.875H13.875V20C13.875 19.7679 13.9672 19.5454 14.1313 19.3813C14.2954 19.2172 14.5179 19.125 14.75 19.125C14.9821 19.125 15.2046 19.2172 15.3687 19.3813C15.5328 19.5454 15.625 19.7679 15.625 20V28.75C15.625 28.9821 15.5328 29.2046 15.3687 29.3687C15.2046 29.5328 14.9821 29.625 14.75 29.625Z"
        fill="#6CE9A6"
      />
      <path
        d="M14.75 19.125V27.875C14.75 28.1071 14.6578 28.3296 14.4937 28.4937C14.3296 28.6578 14.1071 28.75 13.875 28.75H10.375C10.375 28.9821 10.4672 29.2046 10.6313 29.3687C10.7954 29.5328 11.0179 29.625 11.25 29.625H14.75C14.9821 29.625 15.2046 29.5328 15.3687 29.3687C15.5328 29.2046 15.625 28.9821 15.625 28.75V20C15.625 19.7679 15.5328 19.5454 15.3687 19.3813C15.2046 19.2172 14.9821 19.125 14.75 19.125Z"
        fill="#12B76A"
      />
      <path
        d="M20 29.625C19.7679 29.625 19.5454 29.5328 19.3813 29.3687C19.2172 29.2046 19.125 28.9821 19.125 28.75V20C19.125 19.7679 19.2172 19.5454 19.3813 19.3813C19.5454 19.2172 19.7679 19.125 20 19.125C20.2321 19.125 20.4546 19.2172 20.6187 19.3813C20.7828 19.5454 20.875 19.7679 20.875 20V28.75C20.875 28.9821 20.7828 29.2046 20.6187 29.3687C20.4546 29.5328 20.2321 29.625 20 29.625Z"
        fill="#6CE9A6"
      />
      <path
        d="M20 28.75V20C20.0024 19.8488 20.044 19.7008 20.1207 19.5704C20.1974 19.4401 20.3065 19.3318 20.4375 19.2562C20.3059 19.1745 20.1549 19.1292 20 19.125C19.7679 19.125 19.5454 19.2172 19.3813 19.3813C19.2172 19.5454 19.125 19.7679 19.125 20V28.75C19.125 28.9821 19.2172 29.2046 19.3813 29.3687C19.5454 29.5328 19.7679 29.625 20 29.625C20.1549 29.6208 20.3059 29.5755 20.4375 29.4938C20.3065 29.4182 20.1974 29.3099 20.1207 29.1796C20.044 29.0492 20.0024 28.9012 20 28.75Z"
        fill="#12B76A"
      />
      <path
        d="M30.5 29.625C31.9497 29.625 33.125 28.4497 33.125 27C33.125 25.5503 31.9497 24.375 30.5 24.375C29.0503 24.375 27.875 25.5503 27.875 27C27.875 28.4497 29.0503 29.625 30.5 29.625Z"
        fill="#6CE9A6"
      />
      <path
        d="M31.0513 28.75C30.3551 28.75 29.6874 28.4734 29.1951 27.9811C28.7028 27.4889 28.4263 26.8212 28.4263 26.125C28.4259 25.8076 28.4852 25.4929 28.6013 25.1975C28.3388 25.4812 28.1423 25.8193 28.0256 26.1877C27.909 26.5561 27.8751 26.9457 27.9265 27.3287C27.9778 27.7117 28.113 28.0787 28.3226 28.4034C28.5321 28.728 28.8107 29.0025 29.1386 29.207C29.4664 29.4116 29.8354 29.5413 30.2191 29.5868C30.6028 29.6323 30.9919 29.5925 31.3585 29.4703C31.7251 29.3481 32.0602 29.1464 32.3398 28.8797C32.6195 28.613 32.8368 28.2879 32.9763 27.9275C32.729 28.1893 32.4305 28.3974 32.0993 28.5389C31.7681 28.6804 31.4114 28.7523 31.0513 28.75Z"
        fill="#12B76A"
      />
      <path
        d="M30.5 12.5012C30.1114 11.8527 29.5613 11.3159 28.9034 10.9433C28.2456 10.5706 27.5023 10.3748 26.7462 10.375C26.5203 10.3745 26.2947 10.395 26.0725 10.4362C25.4067 9.76616 24.5725 9.28811 23.6578 9.0525C22.743 8.81688 21.7816 8.83243 20.875 9.0975C20.2238 8.3955 19.4345 7.8357 18.5566 7.45323C17.6787 7.07077 16.7313 6.87391 15.7737 6.875C14.0939 6.87614 12.4705 7.48133 11.1999 8.58009C9.92923 9.67886 9.09607 11.1979 8.8525 12.86C8.24782 13.2561 7.75072 13.7959 7.40564 14.4311C7.06055 15.0663 6.87824 15.7771 6.875 16.5C6.875 17.6603 7.33594 18.7731 8.15641 19.5936C8.56266 19.9998 9.04496 20.3221 9.57576 20.542C10.1066 20.7618 10.6755 20.875 11.25 20.875H28.75C29.7506 20.8693 30.7191 20.5208 31.4939 19.8875C32.2687 19.2543 32.8029 18.3745 33.0076 17.395C33.2123 16.4156 33.0751 15.3955 32.6188 14.5049C32.1624 13.6144 31.4146 12.9072 30.5 12.5012Z"
        fill="#6CE9A6"
      />
      <path
        d="M30.5 12.5013C30.1114 11.8527 29.5613 11.3159 28.9034 10.9433C28.2455 10.5706 27.5023 10.3748 26.7462 10.375C26.5202 10.3745 26.2947 10.395 26.0725 10.4363C25.4067 9.76617 24.5725 9.28812 23.6578 9.0525C22.743 8.81689 21.7816 8.83243 20.875 9.0975C20.1819 8.36068 19.3374 7.78283 18.3995 7.40373C17.4617 7.02464 16.4528 6.85331 15.4424 6.90154C14.432 6.94977 13.4441 7.21643 12.5466 7.68313C11.6491 8.14984 10.8635 8.80551 10.2437 9.605C11.618 8.62152 13.3016 8.16837 14.9838 8.32919C16.666 8.49001 18.2333 9.25396 19.3962 10.48C20.3009 10.2155 21.26 10.1995 22.173 10.4335C23.086 10.6675 23.9191 11.143 24.585 11.81C24.8061 11.7908 25.0284 11.7879 25.25 11.8013C26.0035 11.7972 26.7448 11.9905 27.4004 12.362C28.0559 12.7335 28.6026 13.2702 28.9862 13.9188C29.7661 14.2591 30.4298 14.8195 30.896 15.5313C31.3623 16.2431 31.6108 17.0754 31.6112 17.9263C31.6105 18.7513 31.3737 19.559 30.9287 20.2538C31.618 19.8458 32.182 19.2564 32.5593 18.55C32.9366 17.8435 33.1127 17.047 33.0685 16.2473C33.0243 15.4476 32.7614 14.6754 32.3085 14.0148C31.8556 13.3543 31.23 12.8307 30.5 12.5013Z"
        fill="#12B76A"
      />
      <path
        d="M20 33.125C21.4497 33.125 22.625 31.9497 22.625 30.5C22.625 29.0503 21.4497 27.875 20 27.875C18.5503 27.875 17.375 29.0503 17.375 30.5C17.375 31.9497 18.5503 33.125 20 33.125Z"
        fill="#6CE9A6"
      />
      <path
        d="M20.875 32.25C20.1788 32.25 19.5111 31.9734 19.0188 31.4812C18.5266 30.9889 18.25 30.3212 18.25 29.625C18.2517 29.145 18.3881 28.6751 18.6437 28.2688C18.259 28.5009 17.9402 28.828 17.7181 29.2187C17.4959 29.6094 17.3778 30.0506 17.375 30.5C17.375 31.1962 17.6516 31.8639 18.1438 32.3562C18.6361 32.8484 19.3038 33.125 20 33.125C20.4494 33.1222 20.8906 33.0041 21.2813 32.7819C21.672 32.5598 21.9991 32.241 22.2312 31.8563C21.8249 32.1119 21.355 32.2483 20.875 32.25Z"
        fill="#12B76A"
      />
      <path
        d="M9.5 31.375C10.9497 31.375 12.125 30.1997 12.125 28.75C12.125 27.3003 10.9497 26.125 9.5 26.125C8.05025 26.125 6.875 27.3003 6.875 28.75C6.875 30.1997 8.05025 31.375 9.5 31.375Z"
        fill="#6CE9A6"
      />
      <path
        d="M10.375 30.5C9.67881 30.5 9.01113 30.2234 8.51885 29.7312C8.02656 29.2389 7.75 28.5712 7.75 27.875C7.75169 27.395 7.88812 26.9251 8.14375 26.5188C7.75895 26.7509 7.44022 27.078 7.21806 27.4687C6.99591 27.8594 6.87779 28.3006 6.875 28.75C6.875 29.4462 7.15156 30.1139 7.64384 30.6062C8.13613 31.0984 8.80381 31.375 9.5 31.375C9.94942 31.3722 10.3906 31.2541 10.7813 31.0319C11.172 30.8098 11.4991 30.491 11.7313 30.1063C11.3249 30.3619 10.855 30.4983 10.375 30.5Z"
        fill="#12B76A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1071_3302">
        <rect width="28" height="28" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

const PINK_CLOUD_ICON = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="6" fill="#FFE4E8" />
    <g clipPath="url(#clip0_1071_3203)">
      <path
        d="M28.75 27.875H25.25C25.0179 27.875 24.7954 27.7828 24.6313 27.6187C24.4672 27.4546 24.375 27.2321 24.375 27V18.25C24.375 18.0179 24.4672 17.7954 24.6313 17.6313C24.7954 17.4672 25.0179 17.375 25.25 17.375C25.4821 17.375 25.7046 17.4672 25.8687 17.6313C26.0328 17.7954 26.125 18.0179 26.125 18.25V26.125H28.75C28.9821 26.125 29.2046 26.2172 29.3687 26.3813C29.5328 26.5454 29.625 26.7679 29.625 27C29.625 27.2321 29.5328 27.4546 29.3687 27.6187C29.2046 27.7828 28.9821 27.875 28.75 27.875Z"
        fill="#FEA3B4"
      />
      <path
        d="M25.25 26.125V17.375C25.0179 17.375 24.7954 17.4672 24.6313 17.6313C24.4672 17.7954 24.375 18.0179 24.375 18.25V27C24.375 27.2321 24.4672 27.4546 24.6313 27.6187C24.7954 27.7828 25.0179 27.875 25.25 27.875H28.75C28.9821 27.875 29.2046 27.7828 29.3687 27.6187C29.5328 27.4546 29.625 27.2321 29.625 27H26.125C25.8929 27 25.6704 26.9078 25.5063 26.7437C25.3422 26.5796 25.25 26.3571 25.25 26.125Z"
        fill="#F63D68"
      />
      <path
        d="M14.75 29.625H11.25C11.0179 29.625 10.7954 29.5328 10.6313 29.3687C10.4672 29.2046 10.375 28.9821 10.375 28.75C10.375 28.5179 10.4672 28.2954 10.6313 28.1313C10.7954 27.9672 11.0179 27.875 11.25 27.875H13.875V20C13.875 19.7679 13.9672 19.5454 14.1313 19.3813C14.2954 19.2172 14.5179 19.125 14.75 19.125C14.9821 19.125 15.2046 19.2172 15.3687 19.3813C15.5328 19.5454 15.625 19.7679 15.625 20V28.75C15.625 28.9821 15.5328 29.2046 15.3687 29.3687C15.2046 29.5328 14.9821 29.625 14.75 29.625Z"
        fill="#FEA3B4"
      />
      <path
        d="M14.75 19.125V27.875C14.75 28.1071 14.6578 28.3296 14.4937 28.4937C14.3296 28.6578 14.1071 28.75 13.875 28.75H10.375C10.375 28.9821 10.4672 29.2046 10.6313 29.3687C10.7954 29.5328 11.0179 29.625 11.25 29.625H14.75C14.9821 29.625 15.2046 29.5328 15.3687 29.3687C15.5328 29.2046 15.625 28.9821 15.625 28.75V20C15.625 19.7679 15.5328 19.5454 15.3687 19.3813C15.2046 19.2172 14.9821 19.125 14.75 19.125Z"
        fill="#F63D68"
      />
      <path
        d="M20 29.625C19.7679 29.625 19.5454 29.5328 19.3813 29.3687C19.2172 29.2046 19.125 28.9821 19.125 28.75V20C19.125 19.7679 19.2172 19.5454 19.3813 19.3813C19.5454 19.2172 19.7679 19.125 20 19.125C20.2321 19.125 20.4546 19.2172 20.6187 19.3813C20.7828 19.5454 20.875 19.7679 20.875 20V28.75C20.875 28.9821 20.7828 29.2046 20.6187 29.3687C20.4546 29.5328 20.2321 29.625 20 29.625Z"
        fill="#FEA3B4"
      />
      <path
        d="M20 28.75V20C20.0024 19.8488 20.044 19.7008 20.1207 19.5704C20.1974 19.4401 20.3065 19.3318 20.4375 19.2562C20.3059 19.1745 20.1549 19.1292 20 19.125C19.7679 19.125 19.5454 19.2172 19.3813 19.3813C19.2172 19.5454 19.125 19.7679 19.125 20V28.75C19.125 28.9821 19.2172 29.2046 19.3813 29.3687C19.5454 29.5328 19.7679 29.625 20 29.625C20.1549 29.6208 20.3059 29.5755 20.4375 29.4938C20.3065 29.4182 20.1974 29.3099 20.1207 29.1796C20.044 29.0492 20.0024 28.9012 20 28.75Z"
        fill="#F63D68"
      />
      <path
        d="M30.5 29.625C31.9497 29.625 33.125 28.4497 33.125 27C33.125 25.5503 31.9497 24.375 30.5 24.375C29.0503 24.375 27.875 25.5503 27.875 27C27.875 28.4497 29.0503 29.625 30.5 29.625Z"
        fill="#FEA3B4"
      />
      <path
        d="M31.0513 28.75C30.3551 28.75 29.6874 28.4734 29.1951 27.9811C28.7028 27.4889 28.4263 26.8212 28.4263 26.125C28.4259 25.8076 28.4852 25.4929 28.6013 25.1975C28.3388 25.4812 28.1423 25.8193 28.0256 26.1877C27.909 26.5561 27.8751 26.9457 27.9265 27.3287C27.9778 27.7117 28.113 28.0787 28.3226 28.4034C28.5321 28.728 28.8107 29.0025 29.1386 29.207C29.4664 29.4116 29.8354 29.5413 30.2191 29.5868C30.6028 29.6323 30.9919 29.5925 31.3585 29.4703C31.7251 29.3481 32.0602 29.1464 32.3398 28.8797C32.6195 28.613 32.8368 28.2879 32.9763 27.9275C32.729 28.1893 32.4305 28.3974 32.0993 28.5389C31.7681 28.6804 31.4114 28.7523 31.0513 28.75Z"
        fill="#F63D68"
      />
      <path
        d="M30.5 12.5012C30.1114 11.8527 29.5613 11.3159 28.9034 10.9433C28.2456 10.5706 27.5023 10.3748 26.7462 10.375C26.5203 10.3745 26.2947 10.395 26.0725 10.4362C25.4067 9.76616 24.5725 9.28811 23.6578 9.0525C22.743 8.81688 21.7816 8.83243 20.875 9.0975C20.2238 8.3955 19.4345 7.8357 18.5566 7.45323C17.6787 7.07077 16.7313 6.87391 15.7737 6.875C14.0939 6.87614 12.4705 7.48133 11.1999 8.58009C9.92923 9.67886 9.09607 11.1979 8.8525 12.86C8.24782 13.2561 7.75072 13.7959 7.40564 14.4311C7.06055 15.0663 6.87824 15.7771 6.875 16.5C6.875 17.6603 7.33594 18.7731 8.15641 19.5936C8.56266 19.9998 9.04496 20.3221 9.57576 20.542C10.1066 20.7618 10.6755 20.875 11.25 20.875H28.75C29.7506 20.8693 30.7191 20.5208 31.4939 19.8875C32.2687 19.2543 32.8029 18.3745 33.0076 17.395C33.2123 16.4156 33.0751 15.3955 32.6188 14.5049C32.1624 13.6144 31.4146 12.9072 30.5 12.5012Z"
        fill="#FEA3B4"
      />
      <path
        d="M30.5 12.5013C30.1114 11.8527 29.5613 11.3159 28.9034 10.9433C28.2455 10.5706 27.5023 10.3748 26.7462 10.375C26.5202 10.3745 26.2947 10.395 26.0725 10.4363C25.4067 9.76617 24.5725 9.28812 23.6578 9.0525C22.743 8.81689 21.7816 8.83243 20.875 9.0975C20.1819 8.36068 19.3374 7.78283 18.3995 7.40373C17.4617 7.02464 16.4528 6.85331 15.4424 6.90154C14.432 6.94977 13.4441 7.21643 12.5466 7.68313C11.6491 8.14984 10.8635 8.80551 10.2437 9.605C11.618 8.62152 13.3016 8.16837 14.9838 8.32919C16.666 8.49001 18.2333 9.25396 19.3962 10.48C20.3009 10.2155 21.26 10.1995 22.173 10.4335C23.086 10.6675 23.9191 11.143 24.585 11.81C24.8061 11.7908 25.0284 11.7879 25.25 11.8013C26.0035 11.7972 26.7448 11.9905 27.4004 12.362C28.0559 12.7335 28.6026 13.2702 28.9862 13.9188C29.7661 14.2591 30.4298 14.8195 30.896 15.5313C31.3623 16.2431 31.6108 17.0754 31.6112 17.9263C31.6105 18.7513 31.3737 19.559 30.9287 20.2538C31.618 19.8458 32.182 19.2564 32.5593 18.55C32.9366 17.8435 33.1127 17.047 33.0685 16.2473C33.0243 15.4476 32.7614 14.6754 32.3085 14.0148C31.8556 13.3543 31.23 12.8307 30.5 12.5013Z"
        fill="#F63D68"
      />
      <path
        d="M20 33.125C21.4497 33.125 22.625 31.9497 22.625 30.5C22.625 29.0503 21.4497 27.875 20 27.875C18.5503 27.875 17.375 29.0503 17.375 30.5C17.375 31.9497 18.5503 33.125 20 33.125Z"
        fill="#FEA3B4"
      />
      <path
        d="M20.875 32.25C20.1788 32.25 19.5111 31.9734 19.0188 31.4812C18.5266 30.9889 18.25 30.3212 18.25 29.625C18.2517 29.145 18.3881 28.6751 18.6437 28.2688C18.259 28.5009 17.9402 28.828 17.7181 29.2187C17.4959 29.6094 17.3778 30.0506 17.375 30.5C17.375 31.1962 17.6516 31.8639 18.1438 32.3562C18.6361 32.8484 19.3038 33.125 20 33.125C20.4494 33.1222 20.8906 33.0041 21.2813 32.7819C21.672 32.5598 21.9991 32.241 22.2312 31.8563C21.8249 32.1119 21.355 32.2483 20.875 32.25Z"
        fill="#F63D68"
      />
      <path
        d="M9.5 31.375C10.9497 31.375 12.125 30.1997 12.125 28.75C12.125 27.3003 10.9497 26.125 9.5 26.125C8.05025 26.125 6.875 27.3003 6.875 28.75C6.875 30.1997 8.05025 31.375 9.5 31.375Z"
        fill="#FEA3B4"
      />
      <path
        d="M10.375 30.5C9.67881 30.5 9.01113 30.2234 8.51885 29.7312C8.02656 29.2389 7.75 28.5712 7.75 27.875C7.75169 27.395 7.88812 26.9251 8.14375 26.5188C7.75895 26.7509 7.44022 27.078 7.21806 27.4687C6.99591 27.8594 6.87779 28.3006 6.875 28.75C6.875 29.4462 7.15156 30.1139 7.64384 30.6062C8.13613 31.0984 8.80381 31.375 9.5 31.375C9.94942 31.3722 10.3906 31.2541 10.7813 31.0319C11.172 30.8098 11.4991 30.491 11.7313 30.1063C11.3249 30.3619 10.855 30.4983 10.375 30.5Z"
        fill="#F63D68"
      />
    </g>
    <defs>
      <clipPath id="clip0_1071_3203">
        <rect width="28" height="28" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

const BLUE_CLOUD_ICON = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="6" fill="#E0EAFF" />
    <g clipPath="url(#clip0_1071_3286)">
      <path
        d="M28.75 27.875H25.25C25.0179 27.875 24.7954 27.7828 24.6313 27.6187C24.4672 27.4546 24.375 27.2321 24.375 27V18.25C24.375 18.0179 24.4672 17.7954 24.6313 17.6313C24.7954 17.4672 25.0179 17.375 25.25 17.375C25.4821 17.375 25.7046 17.4672 25.8687 17.6313C26.0328 17.7954 26.125 18.0179 26.125 18.25V26.125H28.75C28.9821 26.125 29.2046 26.2172 29.3687 26.3813C29.5328 26.5454 29.625 26.7679 29.625 27C29.625 27.2321 29.5328 27.4546 29.3687 27.6187C29.2046 27.7828 28.9821 27.875 28.75 27.875Z"
        fill="#A4BCFD"
      />
      <path
        d="M25.25 26.125V17.375C25.0179 17.375 24.7954 17.4672 24.6313 17.6313C24.4672 17.7954 24.375 18.0179 24.375 18.25V27C24.375 27.2321 24.4672 27.4546 24.6313 27.6187C24.7954 27.7828 25.0179 27.875 25.25 27.875H28.75C28.9821 27.875 29.2046 27.7828 29.3687 27.6187C29.5328 27.4546 29.625 27.2321 29.625 27H26.125C25.8929 27 25.6704 26.9078 25.5063 26.7437C25.3422 26.5796 25.25 26.3571 25.25 26.125Z"
        fill="#6172F3"
      />
      <path
        d="M14.75 29.625H11.25C11.0179 29.625 10.7954 29.5328 10.6313 29.3687C10.4672 29.2046 10.375 28.9821 10.375 28.75C10.375 28.5179 10.4672 28.2954 10.6313 28.1313C10.7954 27.9672 11.0179 27.875 11.25 27.875H13.875V20C13.875 19.7679 13.9672 19.5454 14.1313 19.3813C14.2954 19.2172 14.5179 19.125 14.75 19.125C14.9821 19.125 15.2046 19.2172 15.3687 19.3813C15.5328 19.5454 15.625 19.7679 15.625 20V28.75C15.625 28.9821 15.5328 29.2046 15.3687 29.3687C15.2046 29.5328 14.9821 29.625 14.75 29.625Z"
        fill="#A4BCFD"
      />
      <path
        d="M14.75 19.125V27.875C14.75 28.1071 14.6578 28.3296 14.4937 28.4937C14.3296 28.6578 14.1071 28.75 13.875 28.75H10.375C10.375 28.9821 10.4672 29.2046 10.6313 29.3687C10.7954 29.5328 11.0179 29.625 11.25 29.625H14.75C14.9821 29.625 15.2046 29.5328 15.3687 29.3687C15.5328 29.2046 15.625 28.9821 15.625 28.75V20C15.625 19.7679 15.5328 19.5454 15.3687 19.3813C15.2046 19.2172 14.9821 19.125 14.75 19.125Z"
        fill="#6172F3"
      />
      <path
        d="M20 29.625C19.7679 29.625 19.5454 29.5328 19.3813 29.3687C19.2172 29.2046 19.125 28.9821 19.125 28.75V20C19.125 19.7679 19.2172 19.5454 19.3813 19.3813C19.5454 19.2172 19.7679 19.125 20 19.125C20.2321 19.125 20.4546 19.2172 20.6187 19.3813C20.7828 19.5454 20.875 19.7679 20.875 20V28.75C20.875 28.9821 20.7828 29.2046 20.6187 29.3687C20.4546 29.5328 20.2321 29.625 20 29.625Z"
        fill="#A4BCFD"
      />
      <path
        d="M20 28.75V20C20.0024 19.8488 20.044 19.7008 20.1207 19.5704C20.1974 19.4401 20.3065 19.3318 20.4375 19.2562C20.3059 19.1745 20.1549 19.1292 20 19.125C19.7679 19.125 19.5454 19.2172 19.3813 19.3813C19.2172 19.5454 19.125 19.7679 19.125 20V28.75C19.125 28.9821 19.2172 29.2046 19.3813 29.3687C19.5454 29.5328 19.7679 29.625 20 29.625C20.1549 29.6208 20.3059 29.5755 20.4375 29.4938C20.3065 29.4182 20.1974 29.3099 20.1207 29.1796C20.044 29.0492 20.0024 28.9012 20 28.75Z"
        fill="#6172F3"
      />
      <path
        d="M30.5 29.625C31.9497 29.625 33.125 28.4497 33.125 27C33.125 25.5503 31.9497 24.375 30.5 24.375C29.0503 24.375 27.875 25.5503 27.875 27C27.875 28.4497 29.0503 29.625 30.5 29.625Z"
        fill="#A4BCFD"
      />
      <path
        d="M31.0513 28.75C30.3551 28.75 29.6874 28.4734 29.1951 27.9811C28.7028 27.4889 28.4263 26.8212 28.4263 26.125C28.4259 25.8076 28.4852 25.4929 28.6013 25.1975C28.3388 25.4812 28.1423 25.8193 28.0256 26.1877C27.909 26.5561 27.8751 26.9457 27.9265 27.3287C27.9778 27.7117 28.113 28.0787 28.3226 28.4034C28.5321 28.728 28.8107 29.0025 29.1386 29.207C29.4664 29.4116 29.8354 29.5413 30.2191 29.5868C30.6028 29.6323 30.9919 29.5925 31.3585 29.4703C31.7251 29.3481 32.0602 29.1464 32.3398 28.8797C32.6195 28.613 32.8368 28.2879 32.9763 27.9275C32.729 28.1893 32.4305 28.3974 32.0993 28.5389C31.7681 28.6804 31.4114 28.7523 31.0513 28.75Z"
        fill="#6172F3"
      />
      <path
        d="M30.5 12.5012C30.1114 11.8527 29.5613 11.3159 28.9034 10.9433C28.2456 10.5706 27.5023 10.3748 26.7462 10.375C26.5203 10.3745 26.2947 10.395 26.0725 10.4362C25.4067 9.76616 24.5725 9.28811 23.6578 9.0525C22.743 8.81688 21.7816 8.83243 20.875 9.0975C20.2238 8.3955 19.4345 7.8357 18.5566 7.45323C17.6787 7.07077 16.7313 6.87391 15.7737 6.875C14.0939 6.87614 12.4705 7.48133 11.1999 8.58009C9.92923 9.67886 9.09607 11.1979 8.8525 12.86C8.24782 13.2561 7.75072 13.7959 7.40564 14.4311C7.06055 15.0663 6.87824 15.7771 6.875 16.5C6.875 17.6603 7.33594 18.7731 8.15641 19.5936C8.56266 19.9998 9.04496 20.3221 9.57576 20.542C10.1066 20.7618 10.6755 20.875 11.25 20.875H28.75C29.7506 20.8693 30.7191 20.5208 31.4939 19.8875C32.2687 19.2543 32.8029 18.3745 33.0076 17.395C33.2123 16.4156 33.0751 15.3955 32.6188 14.5049C32.1624 13.6144 31.4146 12.9072 30.5 12.5012Z"
        fill="#A4BCFD"
      />
      <path
        d="M30.5 12.5013C30.1114 11.8527 29.5613 11.3159 28.9034 10.9433C28.2455 10.5706 27.5023 10.3748 26.7462 10.375C26.5202 10.3745 26.2947 10.395 26.0725 10.4363C25.4067 9.76617 24.5725 9.28812 23.6578 9.0525C22.743 8.81689 21.7816 8.83243 20.875 9.0975C20.1819 8.36068 19.3374 7.78283 18.3995 7.40373C17.4617 7.02464 16.4528 6.85331 15.4424 6.90154C14.432 6.94977 13.4441 7.21643 12.5466 7.68313C11.6491 8.14984 10.8635 8.80551 10.2437 9.605C11.618 8.62152 13.3016 8.16837 14.9838 8.32919C16.666 8.49001 18.2333 9.25396 19.3962 10.48C20.3009 10.2155 21.26 10.1995 22.173 10.4335C23.086 10.6675 23.9191 11.143 24.585 11.81C24.8061 11.7908 25.0284 11.7879 25.25 11.8013C26.0035 11.7972 26.7448 11.9905 27.4004 12.362C28.0559 12.7335 28.6026 13.2702 28.9862 13.9188C29.7661 14.2591 30.4298 14.8195 30.896 15.5313C31.3623 16.2431 31.6108 17.0754 31.6112 17.9263C31.6105 18.7513 31.3737 19.559 30.9287 20.2538C31.618 19.8458 32.182 19.2564 32.5593 18.55C32.9366 17.8435 33.1127 17.047 33.0685 16.2473C33.0243 15.4476 32.7614 14.6754 32.3085 14.0148C31.8556 13.3543 31.23 12.8307 30.5 12.5013Z"
        fill="#6172F3"
      />
      <path
        d="M20 33.125C21.4497 33.125 22.625 31.9497 22.625 30.5C22.625 29.0503 21.4497 27.875 20 27.875C18.5503 27.875 17.375 29.0503 17.375 30.5C17.375 31.9497 18.5503 33.125 20 33.125Z"
        fill="#A4BCFD"
      />
      <path
        d="M20.875 32.25C20.1788 32.25 19.5111 31.9734 19.0188 31.4812C18.5266 30.9889 18.25 30.3212 18.25 29.625C18.2517 29.145 18.3881 28.6751 18.6437 28.2688C18.259 28.5009 17.9402 28.828 17.7181 29.2187C17.4959 29.6094 17.3778 30.0506 17.375 30.5C17.375 31.1962 17.6516 31.8639 18.1438 32.3562C18.6361 32.8484 19.3038 33.125 20 33.125C20.4494 33.1222 20.8906 33.0041 21.2813 32.7819C21.672 32.5598 21.9991 32.241 22.2312 31.8563C21.8249 32.1119 21.355 32.2483 20.875 32.25Z"
        fill="#6172F3"
      />
      <path
        d="M9.5 31.375C10.9497 31.375 12.125 30.1997 12.125 28.75C12.125 27.3003 10.9497 26.125 9.5 26.125C8.05025 26.125 6.875 27.3003 6.875 28.75C6.875 30.1997 8.05025 31.375 9.5 31.375Z"
        fill="#A4BCFD"
      />
      <path
        d="M10.375 30.5C9.67881 30.5 9.01113 30.2234 8.51885 29.7312C8.02656 29.2389 7.75 28.5712 7.75 27.875C7.75169 27.395 7.88812 26.9251 8.14375 26.5188C7.75895 26.7509 7.44022 27.078 7.21806 27.4687C6.99591 27.8594 6.87779 28.3006 6.875 28.75C6.875 29.4462 7.15156 30.1139 7.64384 30.6062C8.13613 31.0984 8.80381 31.375 9.5 31.375C9.94942 31.3722 10.3906 31.2541 10.7813 31.0319C11.172 30.8098 11.4991 30.491 11.7313 30.1063C11.3249 30.3619 10.855 30.4983 10.375 30.5Z"
        fill="#6172F3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1071_3286">
        <rect width="28" height="28" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

const TEAL_CLOUD_ICON = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="6" fill="#CCFBEF" />
    <g clipPath="url(#clip0_1071_4871)">
      <path
        d="M28.75 27.875H25.25C25.0179 27.875 24.7954 27.7828 24.6313 27.6187C24.4672 27.4546 24.375 27.2321 24.375 27V18.25C24.375 18.0179 24.4672 17.7954 24.6313 17.6313C24.7954 17.4672 25.0179 17.375 25.25 17.375C25.4821 17.375 25.7046 17.4672 25.8687 17.6313C26.0328 17.7954 26.125 18.0179 26.125 18.25V26.125H28.75C28.9821 26.125 29.2046 26.2172 29.3687 26.3813C29.5328 26.5454 29.625 26.7679 29.625 27C29.625 27.2321 29.5328 27.4546 29.3687 27.6187C29.2046 27.7828 28.9821 27.875 28.75 27.875Z"
        fill="#5FE9D0"
      />
      <path
        d="M25.25 26.125V17.375C25.0179 17.375 24.7954 17.4672 24.6313 17.6313C24.4672 17.7954 24.375 18.0179 24.375 18.25V27C24.375 27.2321 24.4672 27.4546 24.6313 27.6187C24.7954 27.7828 25.0179 27.875 25.25 27.875H28.75C28.9821 27.875 29.2046 27.7828 29.3687 27.6187C29.5328 27.4546 29.625 27.2321 29.625 27H26.125C25.8929 27 25.6704 26.9078 25.5063 26.7437C25.3422 26.5796 25.25 26.3571 25.25 26.125Z"
        fill="#15B79E"
      />
      <path
        d="M14.75 29.625H11.25C11.0179 29.625 10.7954 29.5328 10.6313 29.3687C10.4672 29.2046 10.375 28.9821 10.375 28.75C10.375 28.5179 10.4672 28.2954 10.6313 28.1313C10.7954 27.9672 11.0179 27.875 11.25 27.875H13.875V20C13.875 19.7679 13.9672 19.5454 14.1313 19.3813C14.2954 19.2172 14.5179 19.125 14.75 19.125C14.9821 19.125 15.2046 19.2172 15.3687 19.3813C15.5328 19.5454 15.625 19.7679 15.625 20V28.75C15.625 28.9821 15.5328 29.2046 15.3687 29.3687C15.2046 29.5328 14.9821 29.625 14.75 29.625Z"
        fill="#5FE9D0"
      />
      <path
        d="M14.75 19.125V27.875C14.75 28.1071 14.6578 28.3296 14.4937 28.4937C14.3296 28.6578 14.1071 28.75 13.875 28.75H10.375C10.375 28.9821 10.4672 29.2046 10.6313 29.3687C10.7954 29.5328 11.0179 29.625 11.25 29.625H14.75C14.9821 29.625 15.2046 29.5328 15.3687 29.3687C15.5328 29.2046 15.625 28.9821 15.625 28.75V20C15.625 19.7679 15.5328 19.5454 15.3687 19.3813C15.2046 19.2172 14.9821 19.125 14.75 19.125Z"
        fill="#15B79E"
      />
      <path
        d="M20 29.625C19.7679 29.625 19.5454 29.5328 19.3813 29.3687C19.2172 29.2046 19.125 28.9821 19.125 28.75V20C19.125 19.7679 19.2172 19.5454 19.3813 19.3813C19.5454 19.2172 19.7679 19.125 20 19.125C20.2321 19.125 20.4546 19.2172 20.6187 19.3813C20.7828 19.5454 20.875 19.7679 20.875 20V28.75C20.875 28.9821 20.7828 29.2046 20.6187 29.3687C20.4546 29.5328 20.2321 29.625 20 29.625Z"
        fill="#5FE9D0"
      />
      <path
        d="M20 28.75V20C20.0024 19.8488 20.044 19.7008 20.1207 19.5704C20.1974 19.4401 20.3065 19.3318 20.4375 19.2562C20.3059 19.1745 20.1549 19.1292 20 19.125C19.7679 19.125 19.5454 19.2172 19.3813 19.3813C19.2172 19.5454 19.125 19.7679 19.125 20V28.75C19.125 28.9821 19.2172 29.2046 19.3813 29.3687C19.5454 29.5328 19.7679 29.625 20 29.625C20.1549 29.6208 20.3059 29.5755 20.4375 29.4938C20.3065 29.4182 20.1974 29.3099 20.1207 29.1796C20.044 29.0492 20.0024 28.9012 20 28.75Z"
        fill="#15B79E"
      />
      <path
        d="M30.5 29.625C31.9497 29.625 33.125 28.4497 33.125 27C33.125 25.5503 31.9497 24.375 30.5 24.375C29.0503 24.375 27.875 25.5503 27.875 27C27.875 28.4497 29.0503 29.625 30.5 29.625Z"
        fill="#5FE9D0"
      />
      <path
        d="M31.0513 28.75C30.3551 28.75 29.6874 28.4734 29.1951 27.9811C28.7028 27.4889 28.4263 26.8212 28.4263 26.125C28.4259 25.8076 28.4852 25.4929 28.6013 25.1975C28.3388 25.4812 28.1423 25.8193 28.0256 26.1877C27.909 26.5561 27.8751 26.9457 27.9265 27.3287C27.9778 27.7117 28.113 28.0787 28.3226 28.4034C28.5321 28.728 28.8107 29.0025 29.1386 29.207C29.4664 29.4116 29.8354 29.5413 30.2191 29.5868C30.6028 29.6323 30.9919 29.5925 31.3585 29.4703C31.7251 29.3481 32.0602 29.1464 32.3398 28.8797C32.6195 28.613 32.8368 28.2879 32.9763 27.9275C32.729 28.1893 32.4305 28.3974 32.0993 28.5389C31.7681 28.6804 31.4114 28.7523 31.0513 28.75Z"
        fill="#15B79E"
      />
      <path
        d="M30.5 12.5012C30.1114 11.8527 29.5613 11.3159 28.9034 10.9433C28.2456 10.5706 27.5023 10.3748 26.7462 10.375C26.5203 10.3745 26.2947 10.395 26.0725 10.4362C25.4067 9.76616 24.5725 9.28811 23.6578 9.0525C22.743 8.81688 21.7816 8.83243 20.875 9.0975C20.2238 8.3955 19.4345 7.8357 18.5566 7.45323C17.6787 7.07077 16.7313 6.87391 15.7737 6.875C14.0939 6.87614 12.4705 7.48133 11.1999 8.58009C9.92923 9.67886 9.09607 11.1979 8.8525 12.86C8.24782 13.2561 7.75072 13.7959 7.40564 14.4311C7.06055 15.0663 6.87824 15.7771 6.875 16.5C6.875 17.6603 7.33594 18.7731 8.15641 19.5936C8.56266 19.9998 9.04496 20.3221 9.57576 20.542C10.1066 20.7618 10.6755 20.875 11.25 20.875H28.75C29.7506 20.8693 30.7191 20.5208 31.4939 19.8875C32.2687 19.2543 32.8029 18.3745 33.0076 17.395C33.2123 16.4156 33.0751 15.3955 32.6188 14.5049C32.1624 13.6144 31.4146 12.9072 30.5 12.5012Z"
        fill="#5FE9D0"
      />
      <path
        d="M30.5 12.5013C30.1114 11.8527 29.5613 11.3159 28.9034 10.9433C28.2455 10.5706 27.5023 10.3748 26.7462 10.375C26.5202 10.3745 26.2947 10.395 26.0725 10.4363C25.4067 9.76617 24.5725 9.28812 23.6578 9.0525C22.743 8.81689 21.7816 8.83243 20.875 9.0975C20.1819 8.36068 19.3374 7.78283 18.3995 7.40373C17.4617 7.02464 16.4528 6.85331 15.4424 6.90154C14.432 6.94977 13.4441 7.21643 12.5466 7.68313C11.6491 8.14984 10.8635 8.80551 10.2437 9.605C11.618 8.62152 13.3016 8.16837 14.9838 8.32919C16.666 8.49001 18.2333 9.25396 19.3962 10.48C20.3009 10.2155 21.26 10.1995 22.173 10.4335C23.086 10.6675 23.9191 11.143 24.585 11.81C24.8061 11.7908 25.0284 11.7879 25.25 11.8013C26.0035 11.7972 26.7448 11.9905 27.4004 12.362C28.0559 12.7335 28.6026 13.2702 28.9862 13.9188C29.7661 14.2591 30.4298 14.8195 30.896 15.5313C31.3623 16.2431 31.6108 17.0754 31.6112 17.9263C31.6105 18.7513 31.3737 19.559 30.9287 20.2538C31.618 19.8458 32.182 19.2564 32.5593 18.55C32.9366 17.8435 33.1127 17.047 33.0685 16.2473C33.0243 15.4476 32.7614 14.6754 32.3085 14.0148C31.8556 13.3543 31.23 12.8307 30.5 12.5013Z"
        fill="#15B79E"
      />
      <path
        d="M20 33.125C21.4497 33.125 22.625 31.9497 22.625 30.5C22.625 29.0503 21.4497 27.875 20 27.875C18.5503 27.875 17.375 29.0503 17.375 30.5C17.375 31.9497 18.5503 33.125 20 33.125Z"
        fill="#5FE9D0"
      />
      <path
        d="M20.875 32.25C20.1788 32.25 19.5111 31.9734 19.0188 31.4812C18.5266 30.9889 18.25 30.3212 18.25 29.625C18.2517 29.145 18.3881 28.6751 18.6437 28.2688C18.259 28.5009 17.9402 28.828 17.7181 29.2187C17.4959 29.6094 17.3778 30.0506 17.375 30.5C17.375 31.1962 17.6516 31.8639 18.1438 32.3562C18.6361 32.8484 19.3038 33.125 20 33.125C20.4494 33.1222 20.8906 33.0041 21.2813 32.7819C21.672 32.5598 21.9991 32.241 22.2312 31.8563C21.8249 32.1119 21.355 32.2483 20.875 32.25Z"
        fill="#15B79E"
      />
      <path
        d="M9.5 31.375C10.9497 31.375 12.125 30.1997 12.125 28.75C12.125 27.3003 10.9497 26.125 9.5 26.125C8.05025 26.125 6.875 27.3003 6.875 28.75C6.875 30.1997 8.05025 31.375 9.5 31.375Z"
        fill="#5FE9D0"
      />
      <path
        d="M10.375 30.5C9.67881 30.5 9.01113 30.2234 8.51885 29.7312C8.02656 29.2389 7.75 28.5712 7.75 27.875C7.75169 27.395 7.88812 26.9251 8.14375 26.5188C7.75895 26.7509 7.44022 27.078 7.21806 27.4687C6.99591 27.8594 6.87779 28.3006 6.875 28.75C6.875 29.4462 7.15156 30.1139 7.64384 30.6062C8.13613 31.0984 8.80381 31.375 9.5 31.375C9.94942 31.3722 10.3906 31.2541 10.7813 31.0319C11.172 30.8098 11.4991 30.491 11.7313 30.1063C11.3249 30.3619 10.855 30.4983 10.375 30.5Z"
        fill="#15B79E"
      />
    </g>
    <defs>
      <clipPath id="clip0_1071_4871">
        <rect width="28" height="28" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

const CLOUD_ENDPOINT_ICONS = [
  PURPL_CLOUD_ICON,
  YELLOW_CLOUD_ICON,
  GREEN_CLOUD_ICON,
  PINK_CLOUD_ICON,
  BLUE_CLOUD_ICON,
  TEAL_CLOUD_ICON,
];

const TEAL_EDGE_ICON = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1073_3195)">
      <rect width="40" height="40" rx="6" fill="#CCFBEF" />
      <path d="M10.5094 5.90451H-0.41573V8.50651H10.5094V5.90451Z" fill="#15B79E" />
      <path d="M31.9554 -0.61795H29.3534V19.9814H31.9554V-0.61795Z" fill="#15B79E" />
      <path d="M10.551 31.08H7.94894V40.3883H10.551V31.08Z" fill="#15B79E" />
      <path
        d="M40.5897 30.8604V33.4624H21.5237V40.389H18.9217V21.592H13.747C13.0288 21.592 12.446 21.0092 12.446 20.291V10.5785C12.446 9.86035 13.0288 9.27752 13.747 9.27752C14.4669 9.27752 15.048 9.86035 15.048 10.5785V18.99H26.157C26.8751 18.99 27.458 19.5728 27.458 20.291C27.458 21.0092 26.8751 21.592 26.157 21.592H21.5237V30.8604H40.5897Z"
        fill="#15B79E"
      />
      <path
        d="M18.0647 7.20621C18.0647 8.89754 17.0256 10.3477 15.5511 10.9514C15.0775 11.1456 14.5607 11.2532 14.0175 11.2532C11.7815 11.2532 9.97055 9.44049 9.97055 7.20621C9.97055 4.97192 11.7815 3.15921 14.0175 3.15921C14.5605 3.15921 15.0774 3.26677 15.5511 3.46099C17.0256 4.0647 18.0647 5.51487 18.0647 7.20621Z"
        fill="#125D56"
      />
      <path
        d="M13.0288 28.9227C13.0288 30.4632 12.1076 31.7884 10.7842 32.3766C10.3158 32.5848 9.79722 32.7009 9.25064 32.7009C7.16552 32.7009 5.47418 31.0096 5.47418 28.9227C5.47418 26.8376 7.16552 25.1463 9.25064 25.1463C9.79705 25.1463 10.3158 25.2625 10.7842 25.4707C12.1078 26.0588 13.0288 27.3842 13.0288 28.9227Z"
        fill="#125D56"
      />
      <path
        d="M35.5105 20.291C35.5105 22.4369 34.1175 24.2582 32.1869 24.8984C31.7064 25.0596 31.1911 25.1464 30.6552 25.1464C27.9734 25.1464 25.798 22.9729 25.798 20.291C25.798 17.6091 27.9733 15.4338 30.6552 15.4338C31.1913 15.4338 31.7064 15.5205 32.1869 15.6819C34.1175 16.322 35.5105 18.1451 35.5105 20.291Z"
        fill="#125D56"
      />
    </g>
    <defs>
      <clipPath id="clip0_1073_3195">
        <rect width="40" height="40" rx="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const BLUE_EDGE_ICON = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1073_3235)">
      <rect width="40" height="40" rx="6" fill="#E0EAFF" />
      <path d="M10.5094 5.90451H-0.415726V8.50651H10.5094V5.90451Z" fill="#6172F3" />
      <path d="M31.9554 -0.61795H29.3534V19.9814H31.9554V-0.61795Z" fill="#6172F3" />
      <path d="M10.551 31.08H7.94894V40.3883H10.551V31.08Z" fill="#6172F3" />
      <path
        d="M40.5897 30.8604V33.4624H21.5237V40.389H18.9217V21.592H13.747C13.0288 21.592 12.446 21.0092 12.446 20.291V10.5785C12.446 9.86035 13.0288 9.27752 13.747 9.27752C14.4669 9.27752 15.048 9.86035 15.048 10.5785V18.99H26.157C26.8751 18.99 27.458 19.5728 27.458 20.291C27.458 21.0092 26.8751 21.592 26.157 21.592H21.5237V30.8604H40.5897Z"
        fill="#6172F3"
      />
      <path
        d="M18.0647 7.20621C18.0647 8.89754 17.0256 10.3477 15.5511 10.9514C15.0775 11.1456 14.5607 11.2532 14.0175 11.2532C11.7815 11.2532 9.97056 9.44049 9.97056 7.20621C9.97056 4.97192 11.7815 3.15921 14.0175 3.15921C14.5605 3.15921 15.0774 3.26677 15.5511 3.46099C17.0256 4.0647 18.0647 5.51487 18.0647 7.20621Z"
        fill="#2D31A6"
      />
      <path
        d="M13.0288 28.9227C13.0288 30.4632 12.1076 31.7884 10.7842 32.3766C10.3158 32.5848 9.79722 32.7009 9.25065 32.7009C7.16552 32.7009 5.47418 31.0096 5.47418 28.9227C5.47418 26.8376 7.16552 25.1463 9.25065 25.1463C9.79705 25.1463 10.3158 25.2625 10.7842 25.4707C12.1078 26.0588 13.0288 27.3842 13.0288 28.9227Z"
        fill="#2D31A6"
      />
      <path
        d="M35.5105 20.291C35.5105 22.4369 34.1175 24.2582 32.1869 24.8984C31.7064 25.0596 31.1911 25.1464 30.6552 25.1464C27.9734 25.1464 25.798 22.9729 25.798 20.291C25.798 17.6091 27.9733 15.4338 30.6552 15.4338C31.1913 15.4338 31.7064 15.5205 32.1869 15.6819C34.1175 16.322 35.5105 18.1451 35.5105 20.291Z"
        fill="#2D31A6"
      />
    </g>
    <defs>
      <clipPath id="clip0_1073_3235">
        <rect width="40" height="40" rx="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PINK_EDGE_ICON = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1073_3245)">
      <rect width="40" height="40" rx="6" fill="#FFE4E8" />
      <path d="M10.5094 5.90451H-0.415726V8.50651H10.5094V5.90451Z" fill="#F63D68" />
      <path d="M31.9554 -0.61795H29.3534V19.9814H31.9554V-0.61795Z" fill="#F63D68" />
      <path d="M10.551 31.08H7.94894V40.3883H10.551V31.08Z" fill="#F63D68" />
      <path
        d="M40.5897 30.8604V33.4624H21.5237V40.389H18.9217V21.592H13.747C13.0289 21.592 12.446 21.0092 12.446 20.291V10.5785C12.446 9.86035 13.0289 9.27752 13.747 9.27752C14.4669 9.27752 15.048 9.86035 15.048 10.5785V18.99H26.157C26.8752 18.99 27.458 19.5728 27.458 20.291C27.458 21.0092 26.8752 21.592 26.157 21.592H21.5237V30.8604H40.5897Z"
        fill="#F63D68"
      />
      <path
        d="M18.0647 7.20621C18.0647 8.89754 17.0256 10.3477 15.5511 10.9514C15.0775 11.1456 14.5607 11.2532 14.0175 11.2532C11.7815 11.2532 9.97055 9.44049 9.97055 7.20621C9.97055 4.97192 11.7815 3.15921 14.0175 3.15921C14.5605 3.15921 15.0774 3.26677 15.5511 3.46099C17.0256 4.0647 18.0647 5.51487 18.0647 7.20621Z"
        fill="#A11043"
      />
      <path
        d="M13.0288 28.9227C13.0288 30.4632 12.1076 31.7884 10.7842 32.3766C10.3158 32.5848 9.79722 32.7009 9.25065 32.7009C7.16552 32.7009 5.47418 31.0096 5.47418 28.9227C5.47418 26.8376 7.16552 25.1463 9.25065 25.1463C9.79705 25.1463 10.3158 25.2625 10.7842 25.4707C12.1078 26.0588 13.0288 27.3842 13.0288 28.9227Z"
        fill="#A11043"
      />
      <path
        d="M35.5105 20.291C35.5105 22.4369 34.1175 24.2582 32.1869 24.8984C31.7064 25.0596 31.1911 25.1464 30.6552 25.1464C27.9734 25.1464 25.798 22.9729 25.798 20.291C25.798 17.6091 27.9733 15.4338 30.6552 15.4338C31.1913 15.4338 31.7064 15.5205 32.1869 15.6819C34.1175 16.322 35.5105 18.1451 35.5105 20.291Z"
        fill="#A11043"
      />
    </g>
    <defs>
      <clipPath id="clip0_1073_3245">
        <rect width="40" height="40" rx="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const GREEN_EDGE_ICON = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1073_3255)">
      <rect width="40" height="40" rx="6" fill="#D1FADF" />
      <path d="M10.5094 5.90451H-0.415726V8.50651H10.5094V5.90451Z" fill="#12B76A" />
      <path d="M31.9554 -0.61795H29.3534V19.9814H31.9554V-0.61795Z" fill="#12B76A" />
      <path d="M10.551 31.08H7.94894V40.3883H10.551V31.08Z" fill="#12B76A" />
      <path
        d="M40.5897 30.8604V33.4624H21.5237V40.389H18.9217V21.592H13.747C13.0289 21.592 12.446 21.0092 12.446 20.291V10.5785C12.446 9.86035 13.0289 9.27752 13.747 9.27752C14.4669 9.27752 15.048 9.86035 15.048 10.5785V18.99H26.157C26.8752 18.99 27.458 19.5728 27.458 20.291C27.458 21.0092 26.8752 21.592 26.157 21.592H21.5237V30.8604H40.5897Z"
        fill="#12B76A"
      />
      <path
        d="M18.0647 7.20621C18.0647 8.89754 17.0256 10.3477 15.5511 10.9514C15.0775 11.1456 14.5607 11.2532 14.0175 11.2532C11.7815 11.2532 9.97055 9.44049 9.97055 7.20621C9.97055 4.97192 11.7815 3.15921 14.0175 3.15921C14.5605 3.15921 15.0774 3.26677 15.5511 3.46099C17.0256 4.0647 18.0647 5.51487 18.0647 7.20621Z"
        fill="#05603A"
      />
      <path
        d="M13.0288 28.9227C13.0288 30.4632 12.1076 31.7884 10.7842 32.3766C10.3158 32.5848 9.79722 32.7009 9.25065 32.7009C7.16552 32.7009 5.47418 31.0096 5.47418 28.9227C5.47418 26.8376 7.16552 25.1463 9.25065 25.1463C9.79705 25.1463 10.3158 25.2625 10.7842 25.4707C12.1078 26.0588 13.0288 27.3842 13.0288 28.9227Z"
        fill="#05603A"
      />
      <path
        d="M35.5105 20.291C35.5105 22.4369 34.1175 24.2582 32.1869 24.8984C31.7064 25.0596 31.1911 25.1464 30.6552 25.1464C27.9734 25.1464 25.798 22.9729 25.798 20.291C25.798 17.6091 27.9733 15.4338 30.6552 15.4338C31.1913 15.4338 31.7064 15.5205 32.1869 15.6819C34.1175 16.322 35.5105 18.1451 35.5105 20.291Z"
        fill="#05603A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1073_3255">
        <rect width="40" height="40" rx="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const YELLOW_EDGE_ICON = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1073_3265)">
      <rect width="40" height="40" rx="6" fill="#FEF7C3" />
      <path d="M10.5093 5.90451H-0.415741V8.50651H10.5093V5.90451Z" fill="#EAAA08" />
      <path d="M31.9553 -0.61795H29.3533V19.9814H31.9553V-0.61795Z" fill="#EAAA08" />
      <path d="M10.551 31.08H7.94894V40.3883H10.551V31.08Z" fill="#EAAA08" />
      <path
        d="M40.5897 30.8604V33.4624H21.5237V40.389H18.9217V21.592H13.747C13.0288 21.592 12.446 21.0092 12.446 20.291V10.5785C12.446 9.86035 13.0288 9.27752 13.747 9.27752C14.4669 9.27752 15.048 9.86035 15.048 10.5785V18.99H26.157C26.8751 18.99 27.458 19.5728 27.458 20.291C27.458 21.0092 26.8751 21.592 26.157 21.592H21.5237V30.8604H40.5897Z"
        fill="#EAAA08"
      />
      <path
        d="M18.0647 7.20621C18.0647 8.89754 17.0256 10.3477 15.5511 10.9514C15.0775 11.1456 14.5607 11.2532 14.0175 11.2532C11.7815 11.2532 9.97055 9.44049 9.97055 7.20621C9.97055 4.97192 11.7815 3.15921 14.0175 3.15921C14.5605 3.15921 15.0774 3.26677 15.5511 3.46099C17.0256 4.0647 18.0647 5.51487 18.0647 7.20621Z"
        fill="#85490E"
      />
      <path
        d="M13.0288 28.9227C13.0288 30.4632 12.1076 31.7884 10.7842 32.3766C10.3158 32.5848 9.79722 32.7009 9.25065 32.7009C7.16552 32.7009 5.47418 31.0096 5.47418 28.9227C5.47418 26.8376 7.16552 25.1463 9.25065 25.1463C9.79705 25.1463 10.3158 25.2625 10.7842 25.4707C12.1078 26.0588 13.0288 27.3842 13.0288 28.9227Z"
        fill="#85490E"
      />
      <path
        d="M35.5105 20.291C35.5105 22.4369 34.1175 24.2582 32.1869 24.8984C31.7064 25.0596 31.1911 25.1464 30.6552 25.1464C27.9734 25.1464 25.798 22.9729 25.798 20.291C25.798 17.6091 27.9733 15.4338 30.6552 15.4338C31.1913 15.4338 31.7064 15.5205 32.1869 15.6819C34.1175 16.322 35.5105 18.1451 35.5105 20.291Z"
        fill="#85490E"
      />
    </g>
    <defs>
      <clipPath id="clip0_1073_3265">
        <rect width="40" height="40" rx="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PURPLE_EDGE_ICON = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1073_3275)">
      <rect width="40" height="40" rx="6" fill="#EBE9FE" />
      <path d="M10.5093 5.90451H-0.415741V8.50651H10.5093V5.90451Z" fill="#7A5AF8" />
      <path d="M31.9553 -0.61795H29.3533V19.9814H31.9553V-0.61795Z" fill="#7A5AF8" />
      <path d="M10.551 31.08H7.94894V40.3883H10.551V31.08Z" fill="#7A5AF8" />
      <path
        d="M40.5897 30.8604V33.4624H21.5237V40.389H18.9217V21.592H13.747C13.0288 21.592 12.446 21.0092 12.446 20.291V10.5785C12.446 9.86035 13.0288 9.27752 13.747 9.27752C14.4669 9.27752 15.048 9.86035 15.048 10.5785V18.99H26.157C26.8751 18.99 27.458 19.5728 27.458 20.291C27.458 21.0092 26.8751 21.592 26.157 21.592H21.5237V30.8604H40.5897Z"
        fill="#7A5AF8"
      />
      <path
        d="M18.0647 7.20621C18.0647 8.89754 17.0256 10.3477 15.5511 10.9514C15.0775 11.1456 14.5607 11.2532 14.0175 11.2532C11.7815 11.2532 9.97055 9.44049 9.97055 7.20621C9.97055 4.97192 11.7815 3.15921 14.0175 3.15921C14.5605 3.15921 15.0774 3.26677 15.5511 3.46099C17.0256 4.0647 18.0647 5.51487 18.0647 7.20621Z"
        fill="#4A1FB8"
      />
      <path
        d="M13.0288 28.9227C13.0288 30.4632 12.1076 31.7884 10.7842 32.3766C10.3158 32.5848 9.79722 32.7009 9.25065 32.7009C7.16552 32.7009 5.47418 31.0096 5.47418 28.9227C5.47418 26.8376 7.16552 25.1463 9.25065 25.1463C9.79705 25.1463 10.3158 25.2625 10.7842 25.4707C12.1078 26.0588 13.0288 27.3842 13.0288 28.9227Z"
        fill="#4A1FB8"
      />
      <path
        d="M35.5105 20.291C35.5105 22.4369 34.1175 24.2582 32.1869 24.8984C31.7064 25.0596 31.1911 25.1464 30.6552 25.1464C27.9734 25.1464 25.798 22.9729 25.798 20.291C25.798 17.6091 27.9733 15.4338 30.6552 15.4338C31.1913 15.4338 31.7064 15.5205 32.1869 15.6819C34.1175 16.322 35.5105 18.1451 35.5105 20.291Z"
        fill="#4A1FB8"
      />
    </g>
    <defs>
      <clipPath id="clip0_1073_3275">
        <rect width="40" height="40" rx="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const EDGE_ENDPOINT_ICONS = [
  TEAL_EDGE_ICON,
  BLUE_EDGE_ICON,
  PINK_EDGE_ICON,
  GREEN_EDGE_ICON,
  YELLOW_EDGE_ICON,
  PURPLE_EDGE_ICON,
];
interface Props {
  size: number;
  isCloudEndpoint: boolean;
  endpointId: string;
}

function stringToIntHash(str: string, lowerBound: number, upperBound: number) {
  let result = 0;
  for (let i = 0; i < str.length; i++) {
    result = result + str.charCodeAt(i);
  }
  return (result % (upperBound - lowerBound)) + lowerBound;
}

export const EndpointIcon: React.FC<Props> = ({ endpointId, size, isCloudEndpoint }) => {
  const arr = isCloudEndpoint ? CLOUD_ENDPOINT_ICONS : EDGE_ENDPOINT_ICONS;
  return (
    <SvgIcon style={{ fontSize: size }} viewBox="0 0 40 40">
      {arr[stringToIntHash(endpointId, 0, EDGE_ENDPOINT_ICONS.length)]}
    </SvgIcon>
  );
};

import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { atelierSulphurpoolLight } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import * as yaml from 'js-yaml';
import React from 'react';

import { JobInfoParsed } from '@clef/shared/types';

interface Props {
  onClose(): void;
  job: JobInfoParsed;
}

const ModelDetailsDialog = ({ job, onClose }: Props) => {
  const yamlHyperParams = React.useMemo(() => {
    const { hyperParams } = job;
    return hyperParams ? yaml.dump(hyperParams) : '';
  }, [job]);

  return (
    <Dialog
      aria-labelledby="model-details-dialog-title"
      fullWidth={true}
      maxWidth="lg"
      onClose={onClose}
      open
    >
      <DialogTitle id="model-details-dialog-title">{t('Model details')}</DialogTitle>
      <DialogContent data-testid="model-details-text">
        <SyntaxHighlighter language="yaml" style={atelierSulphurpoolLight}>
          {yamlHyperParams}
        </SyntaxHighlighter>
      </DialogContent>
      <DialogActions>
        <Button id="model-details-close-btn" onClick={onClose} color="primary">
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModelDetailsDialog;

import React from 'react';

const CameraIcon: React.FC = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6667 2.16667H13.025L11.5 0.5H6.50001L4.97501 2.16667H2.33334C1.41667 2.16667 0.666672 2.91667 0.666672 3.83333V13.8333C0.666672 14.75 1.41667 15.5 2.33334 15.5H15.6667C16.5833 15.5 17.3333 14.75 17.3333 13.8333V3.83333C17.3333 2.91667 16.5833 2.16667 15.6667 2.16667ZM15.6667 13.8333H2.33334V3.83333H5.70834L7.23334 2.16667H10.7667L12.2917 3.83333H15.6667V13.8333ZM9.00001 4.66667C6.70001 4.66667 4.83334 6.53333 4.83334 8.83333C4.83334 11.1333 6.70001 13 9.00001 13C11.3 13 13.1667 11.1333 13.1667 8.83333C13.1667 6.53333 11.3 4.66667 9.00001 4.66667ZM9.00001 11.3333C7.62501 11.3333 6.50001 10.2083 6.50001 8.83333C6.50001 7.45833 7.62501 6.33333 9.00001 6.33333C10.375 6.33333 11.5 7.45833 11.5 8.83333C11.5 10.2083 10.375 11.3333 9.00001 11.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CameraIcon;

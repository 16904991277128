import './polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// ./i18n should be imported before ./store to ensure we have function `t` to
// define translation constants in files indirectly imported through ./store
import './i18n';
import store from './store';
import { EnvironmentEnum, getEnv } from './constants';
import { ApiError, ReactDomOperationError, UnauthorizedError } from './utils/error';
import { extractIdFromUrl } from './utils/url_utils';
import { rootElement } from './utils/dom_utils';
import { ImageLoadingError } from '@clef/client-library';
import { CaptureConsole } from '@sentry/integrations';
import { SSEError } from './hooks/useSSE';

const env = getEnv();
const sentryDsnKey =
  // Only care errors on production
  env === EnvironmentEnum.Production
    ? 'https://47d078bf2c2a4736ad6200ce6d652a97@o951630.ingest.sentry.io/5986145'
    : undefined;

Sentry.init({
  dsn: sentryDsnKey,
  environment: env,
  integrations: [
    new Integrations.BrowserTracing(),
    // Log console errors to Sentry
    new CaptureConsole({ levels: ['error'] }) as any,
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
  // Extend the maximum message length to 1000 to support logging the full image url when failed to load
  // Reference: https://docs.sentry.io/platforms/javascript/configuration/options/#max-value-length
  maxValueLength: 1000,
  beforeSend: (event, hint) => {
    if (event.tags) {
      const { orgId, projectId } = extractIdFromUrl();
      event.tags.orgId = orgId;
      event.tags.projectId = projectId;
      event.tags.visibilityState = document.visibilityState;
    }

    const isAutomatedTestUser = event.user?.email?.includes('landingaitestimautomation');
    const exception = hint?.originalException;
    /**
     * Ignore Error in below scenario
     * 1. Illegal invocation issues
     * 2. Automated test user
     */
    if (
      (exception instanceof TypeError && exception.message.includes('Illegal invocation')) ||
      isAutomatedTestUser
    ) {
      return null;
    }
    let errorSeverity = Sentry.Severity.Error;
    const isBackground = document.visibilityState === 'hidden';
    if (isBackground) {
      // de-prioritze all background errors
      errorSeverity = Sentry.Severity.Debug;
    } else if (exception instanceof ApiError) {
      // api error are also de-prioritized
      if (exception.code === 409) {
        return null;
      }
      errorSeverity = Sentry.Severity.Warning;
    } else if (exception instanceof ReactDomOperationError) {
      // react dom operation error are also de-prioritized
      errorSeverity = Sentry.Severity.Warning;
      event.fingerprint = [...(event.fingerprint ?? []), 'ReactDomOperationError'];
    } else if (exception instanceof ImageLoadingError) {
      // downgrade image failed to load
      errorSeverity = Sentry.Severity.Warning;
    } else if (exception instanceof UnauthorizedError) {
      // session timeout error are only for debugging
      errorSeverity = Sentry.Severity.Debug;
      event.fingerprint = [...(event.fingerprint ?? []), 'UnauthorizedError'];
    } else if (exception instanceof SSEError) {
      // depriorize for now because it happens too frequent
      errorSeverity = Sentry.Severity.Debug;
    }

    event.level = errorSeverity;
    return event;
  },
  initialScope: scope => {
    return scope;
  },
  beforeBreadcrumb: (breadCrumb, hint) => {
    /**
     * Sentry-generated breadcrumb for ui.click events are not friendly for reading. E.g.
     * <button class="MuiButton-root MuiButton-text task-zoom-in MuiButton-textPrimary" type="button" aria-label="zoom-in">
     *   <span class="MuiButton-label">
     *     <svg class="MuiSvgIcon-root" focusable="false" aria-hidden="true">
     *       <path d="..."></path>
     *     </svg>
     *   </span>
     *   <span class="MuiTouchRipple-root"></span>
     * </button>
     *
     * Sentry generats breadcrumb like
     * 1. span.MuiButton-label > svg
     * 2. button.MuiButton-root.MuiButton-text.task-zoom-in.MuiButton-textPrimary
     *
     * But we expect something like
     * 1. button.task-zoom-in > span > svg
     * 2. button.task-zoom-in
     *
     * These codes are generating more readable breadcrumb for ui.click event.
     */
    if (breadCrumb.category === 'ui.click') {
      const event = hint?.event as PointerEvent;
      const path = event.composedPath();

      const isComprehensiveClassName = (className: string) =>
        !className.includes('Mui') &&
        !className.includes('jss') &&
        !className.includes('makeStyle');

      const getComprehensiveDescription = (el: Element) => {
        const nodeName = el.nodeName?.toLowerCase();
        // a. if there is id, return node#id
        if (el.id) {
          return nodeName + '#' + el.id;
        }
        // b. if there is class name, return node.className (Mui, jss, makeStyle class names will be ignored)
        const classNames = Array.prototype.filter.call(
          el.classList ?? [],
          isComprehensiveClassName,
        );
        if (classNames.length > 0) {
          return nodeName + classNames.map(className => '.' + className).join('');
        }
        // c. if there are label, aria-label, title attributes defined, return node[<attr>="label_value"].
        const comprehensiveAttributes = ['label', 'aria-label', 'title'];
        for (const attr of comprehensiveAttributes) {
          const attrValue = el.getAttribute?.(attr);
          if (attrValue) {
            return `${nodeName}[${attr}="${attrValue}"]`;
          }
        }
        // d. fallback to node name only
        return nodeName;
      };

      // 1. find the path from target to the first comprehensive ansestor.
      const firstComprehensiveElementIndex = path.findIndex(
        target =>
          getComprehensiveDescription(target as Element) !==
            (target as Element).nodeName?.toLowerCase() ||
          (target as Element).nodeName?.toLowerCase().startsWith('body'),
      );
      const comprehensivePath = path.slice(0, firstComprehensiveElementIndex + 1);

      // 2. generate breacrumb
      let newBreadcrumbMessage = comprehensivePath
        .map(target => getComprehensiveDescription(target as Element))
        .reverse()
        .join(' > ');

      // 3. append element text content if any
      const { textContent } = event.target as Element;
      if (textContent) {
        newBreadcrumbMessage += ` "${textContent}"`;
      }

      breadCrumb.message = newBreadcrumbMessage;
    }
    return breadCrumb;
  },
});

function render() {
  require('./i18n');
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const App = require('./App').default;
  return ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement,
  );
}

render();

if (module.hot) {
  module.hot.accept(['./App', './i18n', './polyfill'], render);
}

import { CircularProgress, makeStyles, Grid, Typography, Paper, Box } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import React from 'react';
import { statsCardDataKeyCompareFn } from '../../utils/str_utils';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'inline-block',
    minHeight: 92,
    position: 'relative',
  },
  noDataStateContainerGrid: {
    height: 92,
  },
  table: {
    padding: theme.spacing(6, 3, 3, 3),
    boxSizing: 'border-box',
    tableLayout: 'auto',
    width: '100%',
    '& th': {
      textAlign: 'left',
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      whiteSpace: 'nowrap',
    },
    '& td': {
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
    },
  },
  maxCardWidth: {
    width: '100%',
    maxWidth: '380px',
    overflow: 'auto',
  },
  cardTitle: {
    maxWidth: 100,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  buttonGroupContainer: {
    display: 'flex',
    padding: 12,
  },
  flexGrow: {
    flexGrow: 1,
  },
  full: {
    width: '100% !important',
    maxWidth: '100% !important',
  },
}));

interface BaseLink {
  label: string;
  to: string;
}

interface StatsCardBaseProps {
  data: Record<string, React.ReactNode>;
  loading?: boolean;
  errorMessage?: React.ReactNode;
  link?: BaseLink | BaseLink[];
  classes?: {
    root?: string;
  };
  style?: React.CSSProperties;
  isFullScreen?: boolean;
}

const StatsCardBase: React.FC<StatsCardBaseProps> = ({
  data,
  loading,
  classes,
  errorMessage,
  link,
  style,
  isFullScreen = false,
}) => {
  const styles = useStyles();
  const keys = Object.keys(data).sort(statsCardDataKeyCompareFn);

  return (
    <Paper
      className={cx(classes?.root, styles.maxCardWidth, {
        [styles.full]: isFullScreen,
      })}
      data-testid="stats-card"
      style={style}
      classes={{
        root: styles.card,
      }}
    >
      {loading && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          className={styles.noDataStateContainerGrid}
        >
          <CircularProgress size="26px" />
        </Grid>
      )}
      {errorMessage && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          className={styles.noDataStateContainerGrid}
        >
          <Typography variant="body2">{errorMessage}</Typography>
        </Grid>
      )}
      {!errorMessage && !loading && (
        <>
          <table className={styles.table}>
            <thead>
              <tr>
                {keys.map(key => (
                  <th key={key} data-testid="stats-card-th">
                    <Box className={styles.cardTitle} title={key}>
                      <Typography variant="caption" color="textSecondary">
                        {key}
                      </Typography>
                    </Box>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {keys.map(key => (
                  <td key={key} data-testid="stats-card-tr">
                    <Typography variant="h2">{data[key]}</Typography>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>

          {link && (
            <div className={styles.buttonGroupContainer}>
              <div className={styles.flexGrow} />
              {Array.isArray(link) ? (
                link.map((linkItem, index) => (
                  <Button
                    id={`stats-card-${linkItem.label}-link`}
                    // @ts-ignore
                    component={Link}
                    to={linkItem.to}
                    color="primary"
                    key={index}
                  >
                    {linkItem.label}
                  </Button>
                ))
              ) : (
                // @ts-ignore
                <Button component={Link} to={link.to} color="primary">
                  {link.label}
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </Paper>
  );
};

export default StatsCardBase;

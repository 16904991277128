import { indigoScale } from '@clef/client-library';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  orgSettingsPage: {
    maxWidth: 1200,
    margin: 'auto',
  },
  pageTitleContainer: {
    marginBottom: theme.spacing(5),
    display: 'flex',
  },
  titleText: {
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  companyText: {
    color: theme.palette.grey[600],
    fontWeight: 500,
  },
  searchIcon: {
    color: theme.palette.grey[500],
  },
  projectGridSearchInput: {
    padding: '9px 0 9px 14px',
  },
  projectGridSearchContainer: {
    borderRadius: 6,
    minWidth: 280,
    marginRight: 20,
    height: 34,
    backgroundColor: theme.palette.common.white,
  },
  memberRoleChip: {
    marginLeft: theme.spacing(1),
  },
  moreVertIconButton: {
    maxWidth: 100,
  },
  marginBottom5: {
    marginBottom: theme.spacing(5),
  },
  inviteMembersProgressBox: {
    border: '1px solid #E6E7E9',
    borderRadius: 6,
    padding: 28,
    marginBottom: 20,
    background: 'white',
  },
  grow: {
    flexGrow: 1,
  },
  inviteMembersLinearProgressContainer: {
    width: '100%',
    marginBottom: 10,
  },
  inviteMembersLinearProgress: {
    borderRadius: 2,
  },
  orgDuration: {
    margin: theme.spacing(5),
  },
  membersContainer: {
    '& .MuiPaper-root': {
      boxShadow: 'none !important',
    },
  },
  tabItem: {
    width: 'auto',
    minWidth: 'initial',
    padding: '0',
    marginRight: '28px',
  },
  selectedTab: {
    color: theme.palette.blue[600],
  },
  indicator: {
    backgroundColor: theme.palette.blue[600],
  },
  changeMemberActivationButtonText: {
    fontWeight: 'bold',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dialogContent: {
    width: 600,
  },
}));

export const tableStyleOptions: Record<string, React.CSSProperties> = {
  headerStyle: {
    backgroundColor: indigoScale[25],
    height: 61,
  },
  rowStyle: {
    fontSize: 13,
    height: 61,
  },
};

export default useStyles;

import React, { MutableRefObject, useCallback, useState } from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import TopCenterTools from './TopCenterTools';
import {
  Button,
  MediaInteractiveCanvas,
  useLocalStorage,
  useWindowEventListener,
} from '@clef/client-library';
import CLEF_PATH from '../../constants/path';
import { useInstantLearningState } from './state';
import {
  useHasUnsavedChanges,
  useImageLabelingContext,
  useSaveAnnotations,
} from '../../components/Labeling/imageLabelingContext';
import { useSnackbar } from 'notistack';
import ZoomTools from './ZoomTools';
import { useLabelingState } from '../../components/Labeling/labelingState';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import GridOn from '@material-ui/icons/GridOn';
import BrowseImagesTopBar from './BrowseImagesTopBar';
import { useDataBrowserState } from '../DataBrowser/dataBrowserState';
import { LabelStatus } from '@clef/shared/types';
import { useVPLatestModel } from '@/serverStore/projectModels';
import { useTypographyStyles } from '@clef/client-library/src/themes/typography';
import useZeroAuthInstantLearningStyles from '@/pages/ZeroAuthInstantLearning/styles';

const useStyles = makeStyles(theme => ({
  topToolBar: {
    padding: theme.spacing(0, 6),
    height: 52,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    boxShadow: '0px 1px 2px rgba(48, 55, 79, 0.16), 0px 1px 2px 1px rgba(48, 55, 79, 0.08)',
    borderBottom: '1px solid #CDD5DF',
  },
  topLeftTools: {
    position: 'absolute',
    left: theme.spacing(6),
    display: 'flex',
    flexWrap: 'nowrap',
  },
  visualPromptingAndTopRightTools: {
    position: 'absolute',
    right: theme.spacing(6),
  },
  topRightTools: {
    display: 'flex',
    flexWrap: 'nowrap',
    height: 40,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 10,
    alignItems: 'center',
  },
  verticalLine: {
    borderLeft: `1px solid ${theme.palette.greyModern[300]}`,
    height: 24,
    margin: theme.spacing(0, 3),
  },
  backButton: {
    backgroundColor: '#EEEEEE',
  },
  viewImagesButton: {
    padding: theme.spacing(2.5, 2.5),
    minWidth: 'unset',
  },
  canvasModeButton: {
    padding: theme.spacing(2.5, 2.5),
    minWidth: 'unset',
    '&.active': {
      backgroundColor: theme.palette.indigoBlue[100],
    },
  },
}));

export type HeaderProps = {
  groundTruthCanvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
  predictionCanvasRef: MutableRefObject<MediaInteractiveCanvas | null>;
};

const TopToolBar: React.FC<HeaderProps> = ({ groundTruthCanvasRef, predictionCanvasRef }) => {
  const zeroAuthInstantLearningStyles = useZeroAuthInstantLearningStyles();
  const typographyStyles = useTypographyStyles();
  const styles = useStyles();
  const { state: dataBrowserState, dispatch: dispatchDataBrowserState } = useDataBrowserState();
  const { state: instantLearningState, dispatch: dispatchInstantLearningState } =
    useInstantLearningState();
  const { browseImagesMode } = instantLearningState;
  const hasUnsavedChanges = useHasUnsavedChanges();
  const saveAnnotations = useSaveAnnotations();
  const {
    state: { saving },
  } = useImageLabelingContext();
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch: dispatchLabelingState } = useLabelingState();
  const history = useHistory();
  const { latestModel } = useVPLatestModel();
  const { id: latestModelId } = latestModel ?? {};

  const handleBeforeUnload = useCallback(
    (event: WindowEventMap['beforeunload']) => {
      if (hasUnsavedChanges) {
        // when user clicks cancel we can proceed to save changes
        saveAnnotations();
        event.preventDefault();
        event.returnValue = 'There are unsaved changes. Are you sure want to leave?';
      }
    },
    [hasUnsavedChanges, saveAnnotations],
  );
  useWindowEventListener('beforeunload', handleBeforeUnload);

  // Hide for now
  // useKeyPress('s', () => {
  //   dispatchInstantLearningState(draft => {
  //     draft.labelAndTrainMode = draft.labelAndTrainMode === 'single' ? 'split' : 'single';
  //   });
  // });
  const [openBrowserTootip, setOpenBrowserTooltip] = useState(false);
  const [isOpenBrowsersImagesTooltip, setIsOpenBrowsersImagesTooltip] = useLocalStorage(
    'is_open_browsers_images_tooltip',
  );
  const [isToggleImage, setIsToggleImage] = useState(false);
  const onSwtichImage = () => {
    if (!isOpenBrowsersImagesTooltip) {
      setOpenBrowserTooltip(true);
      setIsOpenBrowsersImagesTooltip(true);
      setIsToggleImage(true);
    } else {
      setOpenBrowserTooltip(false);
      setIsToggleImage(false);
    }
  };

  const onTogglePredictionLabels = useCallback(
    (show: boolean, imageGroup: LabelStatus) => {
      dispatchDataBrowserState(draft => {
        if (imageGroup === LabelStatus.Unlabeled) {
          draft.showPredictionUnlabeled = show;
        } else if (imageGroup === LabelStatus.Labeled) {
          draft.showPredictionLabeled = show;
        }
      });
    },
    [dispatchDataBrowserState],
  );

  const closeBrowseImagesTopBar = useCallback(() => {
    dispatchInstantLearningState(draft => {
      draft.browseImagesMode = draft.browseImagesMode === 'topbar' ? undefined : 'topbar';
    });
    setOpenBrowserTooltip(false);
    setIsToggleImage(false);
  }, [dispatchInstantLearningState]);

  return (
    <Box>
      <Box className={styles.topToolBar}>
        <Box className={styles.topLeftTools}>
          <Button
            startIcon={saving ? <CircularProgress size={20} /> : <ArrowBack />}
            id="back-to-project"
            variant="contained"
            disabled={saving}
            onClick={async () => {
              if (hasUnsavedChanges) {
                try {
                  await saveAnnotations();
                  history.push(CLEF_PATH.data.dataBrowser);
                } catch (e) {
                  enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
                }
              } else {
                history.push(CLEF_PATH.data.dataBrowser);
              }
            }}
            className={styles.backButton}
          >
            {t('Project')}
          </Button>
          <Box paddingLeft={6} />

          <Button
            id="browse-images"
            variant="outlined"
            color={browseImagesMode === 'topbar' ? 'primary' : 'default'}
            tooltipProps={{
              open: openBrowserTootip,
            }}
            tooltip={
              isToggleImage
                ? t('You can browse more images from here!')
                : t('Browse Images in Sidebar')
            }
            onMouseEnter={() => setOpenBrowserTooltip(true)}
            onMouseLeave={() => {
              setOpenBrowserTooltip(false);
              setIsToggleImage(false);
            }}
            isCustomTooltipStyle={isToggleImage}
            className={classNames(
              styles.canvasModeButton,
              browseImagesMode === 'topbar' && 'active',
            )}
            onClick={closeBrowseImagesTopBar}
          >
            <GridOn />
          </Button>
          <Box paddingLeft={9} />
          {/* Hide for now */}
          {/* <Tooltip
            arrow
            title={t('Toggle single view / split view {{key}}', { key: <code>S</code> })}
          >
            <SwitchButtonGroup>
              <Button
                id="single-view"
                className={classNames(
                  styles.canvasModeButton,
                  labelAndTrainMode === 'single' && 'active',
                )}
                onClick={() =>
                  dispatchInstantLearningState(draft => {
                    draft.labelAndTrainMode = 'single';
                  })
                }
              >
                <SingleViewIcon />
              </Button>
              <Button
                id="split-view"
                className={classNames(
                  styles.canvasModeButton,
                  labelAndTrainMode === 'split' && 'active',
                )}
                onClick={() =>
                  dispatchInstantLearningState(draft => {
                    draft.labelAndTrainMode = 'split';
                  })
                }
              >
                <SplitViewIcon />
              </Button>
            </SwitchButtonGroup>
          </Tooltip> */}
        </Box>
        <TopCenterTools onSwtichImage={onSwtichImage} />
        {/* Top right tools (Star & Add Tag) is not in this phase */}
        <Box display="flex" alignItems="center" className={styles.visualPromptingAndTopRightTools}>
          <Box className={typographyStyles.h4_semibold}>{t('Visual Prompting')}</Box>
          <Box
            className={classNames(
              typographyStyles.body_bold,
              zeroAuthInstantLearningStyles.betaBadge,
            )}
          >
            {t('Beta')}
          </Box>
          <div className={styles.verticalLine}></div>
          <Box className={styles.topRightTools}>
            <ZoomTools
              setZoomScale={zoomScale => {
                groundTruthCanvasRef.current?.setZoomScale(
                  zoomScale,
                  undefined,
                  /* skip callback */ true,
                );
                predictionCanvasRef.current?.setZoomScale(
                  zoomScale,
                  undefined,
                  /* skip callback */ true,
                );
                dispatchLabelingState(draft => {
                  draft.toolOptions.zoomScale = zoomScale;
                });
              }}
            />
          </Box>
        </Box>
      </Box>

      {browseImagesMode === 'topbar' && (
        <BrowseImagesTopBar
          showGroundTruthLabels={dataBrowserState.showGroundTruth}
          showPredictionLabeled={dataBrowserState.showPredictionLabeled}
          showPredictionUnlabeled={dataBrowserState.showPredictionUnlabeled}
          setShowGroundTruthLabels={show =>
            dispatchDataBrowserState(draft => {
              draft.showGroundTruth = show;
            })
          }
          setShowPredictionLabels={latestModelId ? onTogglePredictionLabels : undefined}
          closeBrowseImagesTopBar={closeBrowseImagesTopBar}
        />
      )}
    </Box>
  );
};

export default TopToolBar;

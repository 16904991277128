import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ERROR_SNACKBAR_STYLE } from '../../constants/metadata_constants';
import AccountAPI from '../../api/account_api';
import { loginAccountSuccess } from '../../store/newLoginState/actions';
import CLEF_PATH from '../../constants/path';

interface Props {}
const GoogleSSOLoginRoute: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [path, setPath] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    AccountAPI.getProfile()
      .then(response => {
        //@ts-ignore
        dispatch(loginAccountSuccess(response));
        // If succeeded to log in account, go to join org page
        setPath(CLEF_PATH.login.joinOrg + window.location.search);
      })
      .catch(err => {
        enqueueSnackbar((err?.body || err)?.message, ERROR_SNACKBAR_STYLE);
        // jump to login page
        setPath('/login');
      });
  }, []);
  return (
    <LoadingOverlay active={path != null} spinner text="Please wait...">
      {path && <Redirect to={path} />}
    </LoadingOverlay>
  );
};

export default GoogleSSOLoginRoute;

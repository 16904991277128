import React, { useEffect } from 'react';
import {
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  makeStyles,
  Box,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import MaterialTable, { MTableBodyRow } from '@material-table/core';
import { Column } from '@material-table/core/types';
import { TaskPurpose, UserTaskStatus, LabelingTaskRow } from '@clef/shared/types';
import { getReadableTime } from '../../utils';
import { ROW_COUNT_OPTIONS, ROW_DEFAULT_COUNT, READABLE_LABELING_TYPES_MAPPING } from './constants';
import { tableIcons } from '../Utils/MatTable';
import CLEF_PATH from '../../constants/path';
import { useMyTask, refreshUseMyTask } from '../../hooks/api/useTaskApi';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { ApiResponseLoader } from '@clef/client-library';
import { useHistory } from 'react-router';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';

const useStyles = makeStyles(theme => ({
  action: {
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
  },
  tooltipIcon: {
    verticalAlign: 'middle',
  },
  tooltipText: {
    fontSize: '14px',
  },
  helperHeaderText: {
    fontSize: '12px',
    color: 'gray',
  },
  linearProgress: {
    marginTop: '9px',
  },
  taskCancelledText: {
    textAlign: 'center',
    color: theme.palette.error.main,
  },
}));

const LabelingTasks: React.FunctionComponent<{ taskType: TaskPurpose }> = ({ taskType }) => {
  const classes = useStyles();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const user = useTypedSelector(state => state.login.user);
  const history = useHistory();

  const [tasksRaw, tasksLoading, tasksError] = useMyTask(
    selectedProject
      ? {
          taskType,
          projectId: selectedProject.id,
        }
      : undefined,
  );

  useEffect(() => {
    refreshUseMyTask({ keys: 'refresh-all' });
  }, []);

  const renderActions = (task: LabelingTaskRow): React.ReactNode | null => {
    const activeUserTask = task.assignees.filter(assignee => assignee.userId === user?.id)[0];
    if (![UserTaskStatus.Completed, UserTaskStatus.Canceled].includes(activeUserTask.status)) {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Button
            id="go-to-task"
            variant="outlined"
            color="primary"
            onClick={(): void => history.push(`${CLEF_PATH.data.task}/${task.id}`)}
          >
            {t('Go to task')}
          </Button>
        </Grid>
      );
    } else if (activeUserTask.status === UserTaskStatus.Canceled) {
      return <Typography className={classes.taskCancelledText}>{t('Task closed')}</Typography>;
    } else {
      return null;
    }
  };

  return (
    <ApiResponseLoader
      loading={tasksLoading}
      error={tasksError}
      response={tasksRaw}
      defaultHeight="200px"
    >
      {tasks => {
        if (!tasks) {
          return <CircularProgress />;
        } else if (tasks.length === 0) {
          return <p>{t('No tasks assigned')}</p>;
        }

        const columns: Column<LabelingTaskRow>[] = [];

        columns.push(
          {
            title: t('Id'),
            field: 'id',
            defaultSort: 'desc',
            cellStyle: {
              maxWidth: '40px',
            },
            render: (data: LabelingTaskRow) => data.id,
          },
          {
            title: t('Task Name'),
            field: 'taskName',
            render: (data: LabelingTaskRow) => data.taskName,
          },
          {
            title: t('Labeling Type'),
            field: 'labelingType',
            render: (data: LabelingTaskRow): React.ReactNode => {
              if (data.labelingType !== null) {
                const readableTypes = data.labelingType.map(type =>
                  READABLE_LABELING_TYPES_MAPPING.get(type),
                );
                return (
                  <>
                    {readableTypes.map(
                      types =>
                        types && (
                          <>
                            {t(types)}
                            <br />
                          </>
                        ),
                    )}
                  </>
                );
              }
              return <></>;
            },
          },
          {
            title: t('Assigned Time'),
            field: 'creationTime',
            type: 'datetime',
            render: (task: LabelingTaskRow): string | null => {
              const activeUserTask = task.assignees.filter(
                assignee => assignee.userId === user!.id,
              )[0];
              if (activeUserTask.createdAt) {
                return getReadableTime(activeUserTask.createdAt);
              }
              return null;
            },
          },
          {
            title: t('# Assignees'),
            render: (data: LabelingTaskRow): number => data.assignees.length,
            cellStyle: {
              maxWidth: '40px',
            },
            customSort: (a: LabelingTaskRow, b: LabelingTaskRow) =>
              a.assignees.length - b.assignees.length,
          },
          {
            title: t('# Distinctive label required'),
            field: 'labelRequired',
            render: (data: LabelingTaskRow): number => data.extra.numberOfLabelerPerMedia ?? 1,
          },
          {
            title: t('Action'),
            sorting: false,
            render: (data: LabelingTaskRow): React.ReactNode | null => renderActions(data),
            cellStyle: {
              minWidth: '120px',
            },
          },
        );

        if (taskType === TaskPurpose.Training) {
          const imagesTooltipText = (
            <span className={classes.helperHeaderText}>{t('labeled by me / total in task')}</span>
          );
          const imagesTitle = (
            <Grid container spacing={1} direction="column">
              <Grid item>{t('# Images')}</Grid>
              <Grid item>{imagesTooltipText}</Grid>
            </Grid>
          );
          const imagesColumn = {
            title: imagesTitle,
            render: (data: LabelingTaskRow): string => {
              const [myLabeledCount] = data.stats.usersStats.reduce(
                (acc, curr) => {
                  let [mine, others] = acc;
                  if (curr.userId === user!.id) {
                    mine += curr.labeledMediaCount;
                  } else {
                    others += curr.labeledMediaCount;
                  }
                  return [mine, others];
                },
                [0, 0],
              );

              return `${myLabeledCount} / ${data.stats.totalMediaCount}`;
            },
            sorting: false,
          };
          // insert right before action column
          columns.splice(-1, 0, imagesColumn);

          const progressTooltipText = (
            <span className={classes.helperHeaderText}>
              {t("Progress of all inspectors' work combined")}
            </span>
          );
          const progressTitle = (
            <Grid container spacing={1} direction="column" alignItems="center">
              <Grid item>{t('Overall Task Progress')}</Grid>
              <Grid item>{progressTooltipText}</Grid>
            </Grid>
          );

          const progressColumn = {
            title: progressTitle,
            render: (data: LabelingTaskRow): React.ReactNode => {
              const totalLabeled = data.stats.usersStats.reduce((acc, curr) => {
                return acc + curr.labeledMediaCount;
              }, 0);

              const progress = data.stats.totalMediaCount
                ? Math.round(
                    (totalLabeled * 100) /
                      data.stats.totalMediaCount /
                      (data.extra.numberOfLabelerPerMedia ?? 1),
                  )
                : 0;

              return (
                <Grid spacing={2} container>
                  <Grid xs={10} item>
                    <LinearProgress
                      variant="determinate"
                      value={progress}
                      className={classes.linearProgress}
                    />
                  </Grid>
                  <Grid xs={2} item>
                    {progress}
                    {t('%')}
                  </Grid>
                </Grid>
              );
            },
            cellStyle: {
              minWidth: '230px',
            },
            sorting: false,
          };
          // insert right before action column
          columns.splice(-1, 0, progressColumn);
        }

        return (
          <MaterialTable
            icons={tableIcons}
            columns={columns}
            data={tasks!}
            components={{
              Row: props => <MTableBodyRow {...props} data-testid={'labeling-tasks-table-row'} />,
              Container: props => <Box {...props} />,
            }}
            options={{
              pageSize: ROW_DEFAULT_COUNT,
              pageSizeOptions: ROW_COUNT_OPTIONS,
              showTitle: false,
              sorting: true,
            }}
          />
        );
      }}
    </ApiResponseLoader>
  );
};
export default LabelingTasks;

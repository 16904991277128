import React from 'react';
import { TabManager } from '@clef/client-library';
import LabelingInstructions from './LabelingInstructions';
import NewDefectBookPage from './newIndex';

interface DefectBookEnhancedProps {}

const DefectBookEnhanced: React.FC<DefectBookEnhancedProps> = () => {
  return (
    <TabManager
      tabs={[
        {
          key: 'defects',
          title: t('Classes'),
          component: <NewDefectBookPage />,
        },
        {
          key: 'labeling_instruction',
          title: t('Labeling instructions'),
          component: <LabelingInstructions />,
        },
      ]}
    />
  );
};

export default DefectBookEnhanced;

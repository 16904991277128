import React from 'react';
import cx from 'classnames';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { Button } from '@clef/client-library';
import Delete from '@material-ui/icons/Delete';
import { createStyles, withStyles, WithStyles, StyleRules } from '@material-ui/core/styles';
import {
  Controller,
  ErrorMessage,
  EventFunction,
  Control,
  FieldErrors,
  FieldValues,
} from 'react-hook-form';
import { UserRole, ProjectRole } from '@clef/shared/types';
import { toTitle } from '../../utils';

const styles = (): StyleRules =>
  createStyles({
    root: {},
    errorText: {
      margin: 0,
      color: 'red',
    },
    userFields: {
      height: '100%',
      maxHeight: '56px',
    },
  });

interface Props extends WithStyles<typeof styles> {
  index: number;
  onDelete: () => void;
  control: Control;
  errors: FieldErrors<FieldValues>;
  showProjectRole: boolean;
}

const AddMemberRow: React.FC<Props> = ({
  index = 0,
  onDelete,
  control,
  errors,
  showProjectRole,
  classes,
}: Props) => {
  const userRoles = [
    {
      value: UserRole.Admin,
      label: t(toTitle(UserRole.Admin)),
    },
    {
      value: UserRole.Collaborator,
      label: t(toTitle(UserRole.Collaborator)),
    },
  ];

  const projectRoles = [
    {
      value: ProjectRole.Owner,
      label: t(toTitle(ProjectRole.Owner)),
    },
    {
      value: ProjectRole.Standard,
      label: t(toTitle(ProjectRole.Standard)),
    },
    {
      value: ProjectRole.Labeler,
      label: t(toTitle(ProjectRole.Labeler)),
    },
  ];

  const onChangeSelect: EventFunction = ([event]) => {
    return { value: event.target.value };
  };

  const _errorMessage = (name: string, message: string): React.ReactNode => (
    <ErrorMessage errors={errors} name={name} message={message}>
      {({ message }): React.ReactNode => <p className={classes.errorText}>{message}</p>}
    </ErrorMessage>
  );

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
      classes={{ root: classes.root }}
      className="cy-member-invite-info-grid"
    >
      <Grid item xs={showProjectRole ? 5 : 6}>
        <Controller
          as={
            <TextField
              placeholder={t('Company email address')}
              margin="none"
              variant="outlined"
              label={t('Company Email')}
              fullWidth
              autoFocus
              type="email"
              className="cy-member-email-input"
            />
          }
          rules={{ required: true }}
          defaultValue={''}
          name={`invites[${index}].email`}
          control={control}
        />
        {_errorMessage(`invites[${index}].email`, 'Invalid email')}
      </Grid>
      <Grid item xs={showProjectRole ? 3 : 5}>
        <Controller
          as={
            <TextField
              variant="outlined"
              margin="none"
              label={t('Select Organization Role')}
              fullWidth
              select
              className="cy-select-member-role-dropdown"
            >
              {userRoles.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          }
          rules={{ required: true }}
          defaultValue={''}
          name={`invites[${index}].userRole`}
          control={control}
          onChange={onChangeSelect}
        />
        {_errorMessage(`invites[${index}].userRole`, 'Please select Organization Role')}
      </Grid>
      {showProjectRole && (
        <Grid item xs={3}>
          <Controller
            as={
              <TextField
                className="cy-select-project-role-dropdown"
                variant="outlined"
                margin="none"
                label={t('Select Project Role')}
                fullWidth
                select
              >
                {projectRoles.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            }
            rules={{ required: true }}
            defaultValue={''}
            name={`invites[${index}].projectRole`}
            control={control}
            onChange={onChangeSelect}
          />
          {_errorMessage(`invites[${index}].projectRole`, 'Please select Project Role')}
        </Grid>
      )}
      <Grid item xs={1}>
        <Button
          id="delete-member-button"
          variant="outlined"
          onClick={(): void => onDelete()}
          className={cx(classes.userFields, 'cy-delete-member-button')}
        >
          <Delete />
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(AddMemberRow);

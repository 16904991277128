import { RangeBox } from '@clef/shared/types';
import LinePixelationWorker from './linePixelation.worker.ts';
import type { LinePixelationResponseEventData } from './linePixelation.worker';

const pixelationWorkerPromises = new Set<Promise<any>>();
export const waitForPixelationWorkersFinished = async () => {
  await Promise.all(Array.from(pixelationWorkerPromises));
};

/**
 * Note: Do not put this into ./utils, it will cause circular dependency issue.
 * Wrap linePointsToPixelatedCanvas with web worker
 */
export const linePointsToPixelatedCanvasAsync = (
  points: number[],
  strokeWidth: number,
  color: string,
  isPolygon?: boolean,
) => {
  const workerPromise = new Promise<[OffscreenCanvas, RangeBox]>(resolve => {
    const canvasWorker = new LinePixelationWorker();
    canvasWorker.addEventListener('message', ev => {
      const { calcRangeBox, imageData } = ev.data as LinePixelationResponseEventData;
      const width = calcRangeBox.xmax - calcRangeBox.xmin + 1;
      const height = calcRangeBox.ymax - calcRangeBox.ymin + 1;
      const canvas = new OffscreenCanvas(imageData.width, imageData.height);
      const ctx = canvas.getContext('2d');
      ctx?.putImageData(imageData, 0, 0);
      ctx?.scale(width / imageData.width, height / imageData.height);
      resolve([canvas, calcRangeBox]);
      canvasWorker.terminate();
      pixelationWorkerPromises.delete(workerPromise);
    });
    canvasWorker.postMessage({ points, strokeWidth, color, isPolygon });
  });
  pixelationWorkerPromises.add(workerPromise);
  return workerPromise;
};

import React, { useState } from 'react';
import { Box, Dialog, MobileStepper, Paper, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@clef/client-library';
import apiKeyV2Intro1 from '@/images/api_key_v2/api_key_v2_intro_1.png';
import apiKeyV2Intro2 from '@/images/api_key_v2/api_key_v2_intro_2.png';

const steps = [
  {
    img: apiKeyV2Intro1,
    description: t(`{{title}} - You no longer need an API Secret with the all-in-one API Key.`, {
      title: <strong>{t(`We've simplified your experience`)}</strong>,
    }),
  },
  {
    img: apiKeyV2Intro2,
    description: t(
      `{{title}} - You can now generate multiple API Keys, rename them, view them at any time, and revoke unused ones.`,
      {
        title: <strong>{t(`Manage your API Keys with new powerful settings`)}</strong>,
      },
    ),
  },
];

const useStyles = makeStyles(theme => ({
  controlButtons: {
    marginTop: theme.spacing(4),
    '& button.MuiButton-root': {
      flex: 1,
      height: 40,
    },
  },
  stepper: {
    boxShadow: 'none !important',
  },
  img: {
    verticalAlign: 'middle',
    width: '100%',
  },
}));

export type ApiKeyV2IntroductionDialogProps = {
  onClose?: (generateKey?: boolean) => void;
};

const ApiKeyV2IntroductionDialog: React.FC<ApiKeyV2IntroductionDialogProps> = props => {
  const { onClose } = props;
  const styles = useStyles();
  const [stepIndex, setStepIndex] = useState(0);
  const step = steps[stepIndex];
  return (
    <Dialog open>
      <Paper>
        <Box padding={6} width={540}>
          <Box textAlign="center">
            <Typography variant="h2_semibold">{t('Introducing New API Keys')}</Typography>
          </Box>
          <Box marginTop={6}>
            <Box>
              <img src={step.img} className={styles.img} />
            </Box>
            <Box marginTop={3}>
              <Typography variant="body_regular">{step.description}</Typography>
            </Box>
          </Box>
          <Box marginTop={1} display="flex" justifyContent="center">
            <MobileStepper
              variant="dots"
              position="static"
              className={styles.stepper}
              steps={steps.length}
              activeStep={stepIndex}
              backButton={null}
              nextButton={null}
            />
          </Box>
          <Box display="flex" alignItems="center" className={styles.controlButtons}>
            {stepIndex === 0 ? (
              <Button
                id="api-key-v2-intro-dismiss"
                color="primary"
                variant="outlined"
                onClick={() => onClose?.()}
              >
                {t('Dismiss')}
              </Button>
            ) : (
              <Button
                id="api-key-v2-intro-back"
                color="primary"
                variant="outlined"
                onClick={() => setStepIndex(prev => prev - 1)}
              >
                {t('Back')}
              </Button>
            )}
            <Box paddingLeft={2} />
            {stepIndex < steps.length - 1 ? (
              <Button
                id="api-key-v2-intro-next"
                color="primary"
                variant="contained"
                onClick={() => setStepIndex(prev => prev + 1)}
              >
                {t('Next')}
              </Button>
            ) : (
              <Button
                id="api-key-v2-intro-generate-key"
                color="primary"
                variant="contained"
                onClick={() => onClose?.(/* generateKey */ true)}
              >
                {t('Generate API Key')}
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
};

export default ApiKeyV2IntroductionDialog;

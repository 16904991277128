import ProjectAPI from '../../api/project_api';
import fetchHookFactory from './fetchHookFactory';
import UserAPI from '../../api/user_api';
import { UserId, ProjectRole, ProjectId, UserPermission, UserGroup } from '@clef/shared/types';

export const [useFetchUsersForProject, refreshUsersForProject] = fetchHookFactory(
  async (projectId: ProjectId) => {
    const result: UserGroup = await UserAPI.fetchUsers(undefined, projectId);
    return result;
  },
  'cache',
);

export const saveUserToProjectWithPermissionApi = async (
  projectId: number,
  userPermissions: Array<{ id: UserId; permissions: UserPermission[] }>,
) => {
  const response = await ProjectAPI.saveMembersToProjectWithPermission(projectId, userPermissions);
  refreshUsersForProject({ keys: 'refresh-all' });
  return response;
};

export const removeUserFromProject = async (projectId: number, userId: UserId) => {
  const response = await ProjectAPI.removeUserFromProject(projectId, userId);
  refreshUsersForProject({ keys: 'refresh-all' });
  return response;
};

export const transferOwnership = async (projectId: number, userId: UserId) => {
  const response = await ProjectAPI.transferOwnership(projectId, userId);
  refreshUsersForProject({ keys: 'refresh-all' });
  return response;
};

export const editMemberRole = async (
  projectId: number,
  userId: UserId,
  newMemberRole: ProjectRole,
) => {
  const response = await ProjectAPI.editMemberRole(projectId, userId, newMemberRole);
  refreshUsersForProject({ keys: 'refresh-all' });
  return response;
};

import { ModelArch } from '@clef/shared/types/model_arch';
import { LabelType } from '@clef/shared/types';
export enum ModelClass {
  ObjectDetection = 'object-detection',
  Classification = 'classification',
  Segmentation = 'segmentation',
  AnomalyDetection = 'anomaly-detection',
  FastObjectDetection = 'fastneasy-object-detection',
  FastSegmentation = 'fastneasy-segmentation',
  FastClassification = 'fastneasy-classification',
}

export enum ArchType {
  TensorFlow = 'TensorFlow', // old flow
  PyTorch = 'PyTorch', // unified flow
}

export const ModelClassToArch: Partial<Record<ModelClass, Record<ArchType, string>>> = {
  [ModelClass.FastObjectDetection]: {
    [ArchType.TensorFlow]: 'RetinaNet',
    [ArchType.PyTorch]: 'AFNet',
  },
  [ModelClass.FastSegmentation]: {
    [ArchType.TensorFlow]: 'UNet',
    [ArchType.PyTorch]: 'InstantNet',
  },
  [ModelClass.FastClassification]: {
    [ArchType.TensorFlow]: 'ResNet',
    [ArchType.PyTorch]: 'ClassificationNet',
  },
};

export const ModelClassToArchV2: Partial<Record<ModelClass, string>> = {
  [ModelClass.FastObjectDetection]: 'AFNet',
  [ModelClass.FastSegmentation]: 'SegmentationNet',
  [ModelClass.FastClassification]: 'ClassificationNet',
};

export const ModelArchModelSizeDisplayName: Partial<Record<ModelArch, Record<string, string>>> = {
  [ModelArch.AFNet]: {
    fast: 'RtmDet-[9M]',
    small: 'RepPoints-[20M]',
    medium: 'RepPoints-[37M]',
  },
  [ModelArch.SegmentationNet]: { fast: 'FastVit-[14M]', small_v2: 'SegFormer-[14M]' },
  [ModelArch.InstantNet]: { small: 'XCiT small-[26M]', medium: 'XCiT medium-[26M]' },
  [ModelArch.ClassificationNet]: { small: 'ConvNext-[16M]', medium: 'ConvNext-[29M]' },
};

export const ModelSizeToArchNameForTooltip: Partial<Record<LabelType, Record<string, string>>> = {
  [LabelType.BoundingBox]: {
    level3: 'RepPoints-[37M]',
    level2: 'RepPoints-[20M]',
    level1: 'RtmDet-[9M]',
  },
  [LabelType.Segmentation]: {
    level3: 'SegFormer-[14M]',
    level2: 'FastVit-[14M]',
  },
  [LabelType.Classification]: {
    level3: 'ConvNext-[29M]',
    level2: 'ConvNext-[16M]',
  },
};

export const customTrainingDefaultModelArchName: Partial<Record<LabelType, string>> = {
  [LabelType.BoundingBox]: 'RepPoints-[37M]',
  [LabelType.Segmentation]: 'SegFormer-[14M]',
  [LabelType.Classification]: 'ConvNext-[29M]',
};

// The minimum version of a v2 job for each model arch class in production
// any version equal or above the version is considered a v2 job
// we need this map because some versions of the v1 model may not be compatible with the v2 eval job.
export const MinimumV2JobVersionMap = new Map<string, Record<string, number>>([
  [
    // Code Build(Update-Schema) log: https://us-east-2.console.aws.amazon.com/codesuite/codebuild/286751717145/projects/landing-avi-common-publish-image-xa/build/landing-avi-common-publish-image-xa%3A01940768-b870-4c38-aca6-749ecbc78911/?region=us-east-2
    'production',
    {
      classification: 1.12,
      segmentation: 5.55,
      'object-detection': 6.45,
      'anomaly-detection': 1.1,
    },
  ],
  [
    // Code Build(Update-Schema) log: https://us-east-2.console.aws.amazon.com/codesuite/codebuild/286751717145/projects/landing-avi-common-publish-image-xa/build/landing-avi-common-publish-image-xa%3A38386017-b3e4-4abb-bf3d-8df7c8fcc68e/?region=us-east-2
    'staging',
    {
      classification: 3.6,
      segmentation: 903.86,
      'object-detection': 903.86,
      'anomaly-detection': 3.44,
    },
  ],
]);

// TODO: need a better way for distinguishing if it’s a fast job or customize job
// https://app.asana.com/0/1204554785675703/1206673612222866/f
export const CUSTOMIZED_REGIME = 'slow-accurate';

import { ApiResponseLoader, ChartContainer, PieChart } from '@clef/client-library';
import { DatasetGroupOptions, MediaStatusType, SelectMediaOption } from '@clef/shared/types';
import React from 'react';
import { useGetDatasetStatsQuery } from '@/serverStore/dataset';

const palette: { [key: string]: string } = {
  [MediaStatusType.Approved]: '#0C64CD',
  [MediaStatusType.InTask]: '#C1D5F3',
  [MediaStatusType.Raw]: '#E6E7E9',
};

const MediaStatusNameMapping: Record<MediaStatusType, string> = {
  [MediaStatusType.Approved]: t('Labeled'),
  [MediaStatusType.InTask]: t('In task'),
  [MediaStatusType.Raw]: t('Unlabeled'),
};

export type MediaLabelingStatusStackedBarChartProps = {
  title?: string;
  selectMediaOptions: SelectMediaOption;
};

const MediaLabelingStatusStackedBarChart: React.FC<
  MediaLabelingStatusStackedBarChartProps
> = props => {
  const { title, selectMediaOptions } = props;
  const {
    data: mediaGroupByStatus,
    isLoading: mediaGroupByStatusLoading,
    error: mediaGroupByStatusError,
  } = useGetDatasetStatsQuery({
    selectOptions: selectMediaOptions,
    groupOptions: [DatasetGroupOptions.MEDIA_STATUS],
  });

  const ChartComponent = (
    <ApiResponseLoader
      response={mediaGroupByStatus}
      loading={mediaGroupByStatusLoading}
      error={mediaGroupByStatusError}
      defaultHeight={300}
    >
      {mediaGroupByStatusLoaded => {
        const mediaStats = (mediaGroupByStatusLoaded || []).reduce(
          (acc, { media_status, count }) => ({ ...acc, [media_status as string]: count }),
          {} as { [status: string]: number },
        );
        const mediaStatsFormatted = Object.entries(MediaStatusNameMapping)
          .map(([status, name]) => ({
            name,
            value: mediaStats[status],
            color: palette[status],
          }))
          .filter(({ value }) => !!value);
        return <PieChart chartData={mediaStatsFormatted} variant="doughnut" />;
      }}
    </ApiResponseLoader>
  );

  return title ? <ChartContainer title={title}>{ChartComponent}</ChartContainer> : ChartComponent;
};

export default MediaLabelingStatusStackedBarChart;

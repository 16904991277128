import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Typography, Grid, Link, Box } from '@material-ui/core';
import cx from 'classnames';
import { useSnackbar } from 'notistack';

import { useStyles } from './styles';
import AuthAPI from '../../api/auth_api';
import {
  LandingLensLogo,
  ContinueWithGoogleButton,
  OrSeparator,
} from '../../components/Auth/Common';
import { EmailTextField } from '../../components/Auth/TextField';
import { PrimaryButton } from '../../components/Auth/Button';
import { useHistory } from 'react-router';
import { CLEF_PATH } from '../../constants/path';
import { AuthPageLayout } from '../../components/Layout/AuthPageLayout';
import { isFDA } from '../../constants';
import { parseUTMParams } from '@/utils/url_utils';

interface FormValues {
  email: string;
}

export interface SelfServiceSignupProps {}

export const SelfServiceSignup: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [isRegistering, setIsRegistering] = useState(false);
  const { control, errors, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = useCallback(
    async ({ email }: FormValues) => {
      setIsRegistering(true);

      try {
        const utmParams = parseUTMParams(location.search);
        await AuthAPI.register(email, utmParams);
        const searchParams = new URLSearchParams(location.search);
        searchParams.append('email', email);
        history.push(`${CLEF_PATH.signup.emailDelivered}?${searchParams.toString()}`);
      } catch (e) {
        enqueueSnackbar(e?.message, { variant: 'error', autoHideDuration: 12000 });
        setIsRegistering(false);
      }
    },
    [enqueueSnackbar, history],
  );

  return (
    <AuthPageLayout metaTitle={t('Signup')} showExamples>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        minHeight="100vh"
        position="relative"
      >
        <LandingLensLogo />

        <Typography variant="h1" className={styles.helperText}>
          {t('Sign Up to Start Building Models')}
        </Typography>

        {!isFDA() && <ContinueWithGoogleButton className={styles.marginBottom8} />}

        <OrSeparator />

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <EmailTextField
            error={errors?.email}
            control={control}
            className={styles.marginBottom6}
          />

          <PrimaryButton
            id="sign-up-with-email"
            text={t('Create Your Account')}
            className={styles.marginBottom6}
            disabled={!!errors.email}
            isPending={isRegistering}
            useNewUi
          />
        </Box>

        <Grid container direction="row" className={styles.autoWidth} alignItems="center">
          <Typography className={cx(styles.alreadyHaveAnAccountText, styles.marginRight1)}>
            {t('Already have an account?')}
          </Typography>

          <Link
            className={cx(styles.link, styles.newLink)}
            onClick={() => history.push(CLEF_PATH.login.main + location.search)}
          >
            {t('Sign In')}
          </Link>
        </Grid>
      </Box>
    </AuthPageLayout>
  );
};

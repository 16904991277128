import React from 'react';
import { Grid, Paper, TextField, TextFieldProps } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '20em',
  },
  textFieldContainer: {
    flex: 1,
  },
  textField: {
    width: '100%',
  },
}));

type Props = TextFieldProps;

const SearchBox: React.FC<Props> = ({ classes, style = {}, ...props }: Props) => {
  const styles = useStyles();
  return (
    <Paper square className={styles.root} style={style}>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <Search />
        </Grid>
        <Grid item className={styles.textFieldContainer}>
          <TextField
            className={styles.textField}
            InputProps={props.InputProps ? props.InputProps : { disableUnderline: true }}
            {...props}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SearchBox;

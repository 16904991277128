import { BaseAPI } from './base_api';
import { ProjectId } from '@clef/shared/types';
const APIurlPrefix = 'unleash';

class FeatureToggleAPI extends BaseAPI {
  async getEnabledFeaturesForUser(): Promise<string[]> {
    return this.get('enabled_features_for_user', {}, true);
  }

  async getEnabledFeaturesForProject(projectId: ProjectId): Promise<string[]> {
    return this.get('enabled_features_for_project', { projectId }, true);
  }
  async getEnabledFeaturesForOrg(orgId: number): Promise<string[]> {
    return this.get('enabled_features_for_org', { orgId }, true);
  }
}

export default new FeatureToggleAPI(APIurlPrefix);

import { Media, ThumbanilSize, Dimensions } from '@clef/shared/types';

// Regex pattern matching `.${width}x${height}.jpg`, e.g. `.100x100.jpg`
const THUMBNAIL_SIZE_PATTERN = /.\d+x\d+.jpg/g;

/**
 * Get the width and height of the thumbnail based on the thumbnail url
 * @param url Thumbnail url
 * @returns Width and height of the thumbnail, or undefined
 */
export const getThumbnailDimensionFromUrl = (url: string | undefined): Dimensions | undefined => {
  if (!url) {
    return undefined;
  }

  const matches = url.match(THUMBNAIL_SIZE_PATTERN);

  // There should be 1 and only 1 match. Otherwise, return undefined
  if (!matches || matches.length !== 1) {
    return undefined;
  }

  // Splitting by dot should return 3 sub-strings, while the middle one contain the width and height
  const sizeString = matches![0].split('.')![1];

  // Splitting by x should return 2 sub-strings. One for width, and another one for height
  const [width, height] = sizeString.split('x').map(string => parseInt(string));

  return {
    width,
    height,
  };
};

const THUMBNAIL_SIZE_ORDER_LOOKUP: Record<ThumbanilSize, number> = {
  small: 0,
  medium: 1,
  large: 2,
  full: 3,
};

/**
 * Get the thumbnail from the media object with fallback logic, which
 * follows the order of small -> medium -> large -> full.
 * @param media Media object with details
 * @param thumbnailSize Size of the thumbnail
 * @param enableFallback Boolean for enabling fallback logic. True by default
 * @returns url string of the thumbnail, or undefined
 */
export const getThumbnail = (
  media: Media | undefined,
  thumbnailSize: ThumbanilSize,
  enableFallback: boolean = true,
): string | undefined => {
  if (!media) {
    return undefined;
  }

  if (media.thumbnails?.length) {
    const index = THUMBNAIL_SIZE_ORDER_LOOKUP[thumbnailSize];

    if (index === undefined) {
      return undefined;
    }

    if (enableFallback) {
      // Since the thumbnails are placed following the order of "small" -> "medium" -> "large" in the array,
      // we directly use a for-loop starting from the corresponding index in the array, given fallback enabled
      // E.g. if we want to get small thumbnail, then we have the index = 0. In the for-loop, we first try to
      // get the small thumbnail (at index 0); if failed, we then try to get the medium thumbnail (at index 1),
      // and so on
      for (let i = index; i < Object.keys(THUMBNAIL_SIZE_ORDER_LOOKUP).length - 1; i++) {
        if (media.thumbnails.length > i) {
          return media?.thumbnails![i];
        }
      }
    } else {
      if (media.thumbnails.length > index) {
        return media?.thumbnails![index];
      }
    }
  }

  // Return full image as final fallback if fallback enabled
  return enableFallback ? media.url : undefined;
};

import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Confetti from 'react-confetti';
import CongratsSvg from '@/images/workflow_assistant/congrats.svg';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import { NEW_DRAWER_WIDTH } from '@clef/shared/constants';

const useStyles = makeStyles(theme => ({
  congratsTooltipImg: {
    width: 80,
  },
  congratsTooltipTitle: {
    margin: theme.spacing(5, 0),
  },
  paper: {
    margin: 0,
    position: 'absolute',
    top: 120,
    left: `calc(50% + ${NEW_DRAWER_WIDTH / 2}px)`,
    transform: 'translateX(-50%)',
    boxShadow:
      '0px 1px 2px rgba(48, 55, 79, 0.16), 0px 2px 4px 2px rgba(48, 55, 79, 0.08) !important',
  },
}));

export type CongratsDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const CongratsDialog: React.FC<CongratsDialogProps> = ({ open, onClose }) => {
  const styles = useStyles();

  return (
    <BaseDialog
      data-testid="workflow-assistant-congrats-dialog"
      open={open}
      onClose={onClose}
      hideBackdrop
      containerProps={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 500,
        height: 250,
      }}
      classes={{
        paper: styles.paper,
      }}
    >
      <Confetti
        width={500}
        height={250}
        recycle={false}
        opacity={0.5}
        numberOfPieces={1500}
        tweenDuration={50000}
      />

      <img src={CongratsSvg} className={styles.congratsTooltipImg} />

      <Typography variant="h2" className={styles.congratsTooltipTitle}>
        {t('Congratulations!')}
      </Typography>

      <Typography>
        {t("You've completed all the steps of your model's first lifecycle!")}
      </Typography>
    </BaseDialog>
  );
};

import { getThumbnail, Img } from '@clef/client-library';
import { MediaDetailsForLabelingReview, MediaId } from '@clef/shared/types';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Button } from '@clef/client-library';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const useStyles = makeStyles(() => {
  return {
    dialogBox: {
      width: '600px',
    },
    mediaImg: {
      display: 'block',
      marginRight: '16px',
    },
    checkedWrapper: {
      height: 'auto',
      maxHeight: 200,
      overflowY: 'auto',
    },
    mediaList: {
      height: 0,
      overflow: 'hidden',
      transition: 'all 0.2s',
      opacity: 0,
    },
    arrow: {
      transition: 'all 0.2s',
    },
    progress: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: 100,
      background: 'rgba(255,255,255,0.7)',
    },
  };
});

const ArrowRight: React.FC = () => (
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 10L5.5 5L0.5 0V10Z" fill="#69717A" />
  </svg>
);

interface CustomDialogProps extends DialogProps {
  onClose: () => void;
}

interface LabelingReviewHeaderPanelSubmitDialogProps {
  dialogProps: CustomDialogProps;
  rejectMediaList: MediaDetailsForLabelingReview[];
  totalRejected: number;
  onSubmit: (reassignMediaIds?: MediaId[]) => Promise<void>;
}

const LabelingReviewHeaderSubmitDialog: React.FC<
  LabelingReviewHeaderPanelSubmitDialogProps
> = props => {
  const { rejectMediaList, totalRejected, dialogProps } = props;
  const { onSubmit } = props;
  const { onClose } = dialogProps;
  const styles = useStyles();
  const [openAll, setOpenAll] = useState(false);

  const [checkedWithMedia, setCheckedWithMedia] = useState<{ [key: MediaId]: boolean }>({});
  const [isReassign, setIsReassign] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const initChecked = useRef(false);

  useEffect(() => {
    if (!initChecked.current && dialogProps.open && rejectMediaList.length > 0) {
      setCheckedWithMedia(Object.fromEntries(rejectMediaList.map(media => [media.id, true])));
      initChecked.current = true;
    }
  }, [dialogProps.open, rejectMediaList]);

  useEffect(() => {
    if (!dialogProps.open) {
      initChecked.current = false;
    }
  }, [dialogProps.open]);

  const checkedIds = useMemo(() => {
    return Object.entries(checkedWithMedia)
      .filter(([, checked]) => checked)
      .map(([mediaId]) => Number(mediaId));
  }, [checkedWithMedia]);

  const onReassign = useCallback(async () => {
    try {
      setIsReassign(true);
      await onSubmit(checkedIds);
      setIsReassign(false);
    } catch (e) {
      setIsReassign(false);
    }
  }, [checkedIds, onSubmit]);

  const onSelectAll = useCallback(
    (checked: boolean) => {
      setSelectAll(checked);
      setCheckedWithMedia(Object.fromEntries(rejectMediaList.map(media => [media.id, checked])));
    },
    [rejectMediaList],
  );

  useEffect(() => {
    setSelectAll(checkedIds.length === rejectMediaList.length);
  }, [checkedIds.length, rejectMediaList.length]);

  return (
    <Dialog {...dialogProps} data-testid="reassign-media-confirm-dialog">
      {isReassign && (
        <div className={styles.progress}>
          <LinearProgress />
        </div>
      )}
      <DialogTitle>
        {t('{{num}} rejected media were detected', {
          num: totalRejected,
        })}
      </DialogTitle>
      <DialogContent className={styles.dialogBox}>
        <Typography>{t('Do you want to reassign rejected media?')}</Typography>
        <Box marginTop={2}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box
              style={{ cursor: 'pointer' }}
              display="flex"
              alignItems="center"
              onClick={() => setOpenAll(!openAll)}
            >
              <Box className={styles.arrow} style={{ transform: `rotate(${openAll ? 90 : 0}deg)` }}>
                <ArrowRight />
              </Box>
              <Box marginLeft={3}>
                <Typography>{t('Rejected media')}</Typography>
              </Box>
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selectAll}
                  onChange={e => onSelectAll(!!e.target.checked)}
                />
              }
              label={t('Select all')}
              data-testid="select-all-reject-media"
            ></FormControlLabel>
          </Box>
          <Box className={styles.checkedWrapper}>
            <Box
              className={styles.mediaList}
              style={{
                height: openAll ? rejectMediaList.length * 42 : 0,
                opacity: openAll ? 1 : 0,
              }}
            >
              <FormGroup>
                {rejectMediaList.map(media => {
                  return (
                    <FormControlLabel
                      key={media.id}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={e => {
                            setCheckedWithMedia(prev => ({
                              ...prev,
                              [media.id]: e.target.checked,
                            }));
                          }}
                          checked={checkedWithMedia[media.id] || false}
                        />
                      }
                      label={
                        <Box display="flex">
                          <Img
                            className={styles.mediaImg}
                            src={getThumbnail(media, 'small')}
                            fallbackSrc={media?.url}
                            width="24"
                            crossOrigin="use-credentials"
                          />
                          <Typography>{media.name}</Typography>
                        </Box>
                      }
                    />
                  );
                })}
              </FormGroup>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          id="cancel-reassign"
          onClick={() => {
            onClose && onClose();
          }}
          color="primary"
        >
          {t('Cancel')}
        </Button>
        <Button
          onClick={onReassign}
          color="primary"
          variant="contained"
          id="reassign-media-btn-with-submit"
        >
          {t('Reassign {{count}} media', {
            count: checkedIds.length,
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LabelingReviewHeaderSubmitDialog;

import { useState } from 'react';

import { useWindowEventListener } from '@clef/client-library';

const DEFAULT_WIDTH = 100;
const DEFAULT_SCREEN_SIZE_BREAK_POINTS = [768, 1200, 1440, 1600, 1920, 2240, 2560, 2880, Infinity];

export const calcItemPerRow = (
  maxItemPerRow?: number,
  screenSizeBreakPoints: number[] = DEFAULT_SCREEN_SIZE_BREAK_POINTS,
  ordering: 'incremental' | 'decremental' = 'incremental',
) => {
  if (ordering === 'incremental') {
    return Math.min(
      Math.max(
        screenSizeBreakPoints.findIndex(breakpoint => breakpoint > window.innerWidth) + 1,
        1,
      ),
      maxItemPerRow ?? Infinity,
    );
  } else {
    if (maxItemPerRow === undefined) {
      return 0;
    }

    const reversed = [...screenSizeBreakPoints].reverse();

    return Math.min(
      Math.max(maxItemPerRow - reversed.findIndex(breakpoint => breakpoint < window.innerWidth), 1),
      maxItemPerRow ?? Infinity,
    );
  }
};

export type useResponsiveWidthProps = {
  maxItemPerRow: number;
  baseWidth?: number;
  screenSizeBreakPoints?: number[];
  ordering?: 'incremental' | 'decremental';
};

export const useResponsiveWidth = ({
  maxItemPerRow,
  baseWidth = DEFAULT_WIDTH,
  screenSizeBreakPoints = DEFAULT_SCREEN_SIZE_BREAK_POINTS,
  ordering = 'incremental',
}: useResponsiveWidthProps) => {
  const [width, setWidth] = useState(
    baseWidth / calcItemPerRow(maxItemPerRow, screenSizeBreakPoints, ordering),
  );

  useWindowEventListener('resize', () => {
    setWidth(baseWidth / calcItemPerRow(maxItemPerRow, screenSizeBreakPoints, ordering));
  });

  return {
    width,
    percentageWidth: `${(width * 100) / baseWidth}%`,
  };
};

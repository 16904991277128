import React, { useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { Button, QrCode } from '@clef/client-library';
import { useTypographyStyles } from '@clef/client-library/src/themes/typography';
import { useSnackbar } from 'notistack';
import { getMobileInferenceEndpoint } from '../../utils';
import LandingLogo from '@/images/logo_withoutText_color.svg';
import { refreshPublicEndpointByIdApi } from '@/hooks/api/usePublicEndpointApi';
import public_endpoint_api from '@/api/public_endpoint_api';
import { useIsFeatureEnabledAndMayHideForSnowflake } from '@/hooks/useFeatureGate';

export type MobileInferenceCardEnabledProps = {
  inferenceCost: number;
  endpointId?: string;
};

const MobileInferenceCardEnabled: React.FC<MobileInferenceCardEnabledProps> = props => {
  const { endpointId, inferenceCost } = props;
  const typographyStyles = useTypographyStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const isCreditReferenceEnabled = useIsFeatureEnabledAndMayHideForSnowflake().creditReference;

  if (!endpointId) {
    return null;
  }

  return (
    <>
      <QrCode value={getMobileInferenceEndpoint(endpointId)} size={160} logo={LandingLogo} />
      <Box className={typographyStyles.h3_semibold} marginTop={3}>
        {t('Mobile Inference')}
      </Box>
      {isCreditReferenceEnabled && (
        <Box className={typographyStyles.body_regular} marginY={7} textAlign="center">
          {t(
            `Anyone with the QR code can run inference and consume your credits. Each inference ${inferenceCost} ${
              inferenceCost > 1 ? 'credits' : 'credit'
            } per image.`,
          )}
        </Box>
      )}
      <Button
        id="generate-public-qr-code"
        color="primary"
        variant="text"
        disabled={loading}
        startIcon={loading ? <CircularProgress size={20} /> : undefined}
        onClick={async () => {
          if (loading) {
            return;
          }
          setLoading(true);
          try {
            await public_endpoint_api.changePublicEndpointStateById(endpointId, /* enable */ false);
            refreshPublicEndpointByIdApi({ keys: 'refresh-all' });
          } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
          }
          setLoading(false);
        }}
      >
        {t('Turn It Off')}
      </Button>
    </>
  );
};

export default MobileInferenceCardEnabled;

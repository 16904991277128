import React from 'react';
import cx from 'classnames';
import { AppBar, Toolbar, Tooltip, ListItem, Box } from '@material-ui/core';
import { IconButton } from '@clef/client-library';
import { useHistory } from 'react-router-dom';
import HelpOutline from '@material-ui/icons/HelpOutline';

import { SubscriptionName } from '@clef/shared/types';
import { Typography } from '@clef/client-library';
import CLEF_PATH from '../../../../constants/path';
import NewProfileMenu from './NewProfileMenu';
import logoLandingLens from '@/images/logo_LandingLens_color.svg';
import MediaUploadIndicator from './MediaUploadIndicator';
import { useStyles } from './newStyles';
import { isOnProjectPage } from '../../../../utils/url_utils';
import { usePageLayoutState } from '../../PageLayout/state';
import PlanBadge from './PlanBadge';
import { useCurrentSubscription } from '../../../../hooks/useSubscriptions';
import LanguageSwitch from './LanguageSwitch';
import { useIsFeatureEnabledAndMayHideForSnowflake } from '@/hooks/useFeatureGate';
import CloseIcon from '@material-ui/icons/Close';

const appProjectUrlPattern = /.*\/app\/\d+\/pr\/\d+\//;
const urlRewriteLookup: Record<string, string> = {
  'data/defects': 'data/databrowser',
  'data/metadata': 'data/databrowser',
  'data/exported_data': 'data/databrowser',
  jobs: 'models',
  'models/run': 'models',
};

const getModifiedUrl = (url: string) => {
  const appProjectUrl = url.match(appProjectUrlPattern)?.[0];
  if (!appProjectUrl) {
    return url;
  }

  const extraUrl = url.replace(appProjectUrl, '');
  return urlRewriteLookup[extraUrl] ? appProjectUrl + urlRewriteLookup[extraUrl] : url;
};

const isLinkActive = (link: string | undefined): boolean => {
  const url = getModifiedUrl(location.pathname);

  // Backdoor for specific project page to be mapped to projects url
  if (new RegExp(/\/app\/\d+\/pr\/\d+/).test(url) && link === CLEF_PATH.projects) {
    return true;
  }

  return (
    !!link &&
    /**
     * This will match "/app/home" with:
     *   /app/home
     *   /app/1/home
     */
    new RegExp(link.replace('/app/', '/app(/\\d+)?/')).test(url)
  );
};

const NavItem: React.FC<{
  link: string;
  text: string;
}> = ({ link, text }) => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <ListItem
      button
      disableRipple
      selected={isLinkActive(link)}
      classes={{
        selected: styles.linkSelected,
      }}
      className={styles.tabItem}
      onClick={() => {
        try {
          const url = new URL(link);

          if (url.host && url.host !== window.location.host) {
            window.open(url, '_blank', 'noopener');
          } else {
            window.location.href = link;
          }
        } catch (e) {
          history.push(link);
        }
      }}
    >
      <Typography
        className={cx(styles.tabItemText, isLinkActive(link) && styles.selectedTabItemText)}
      >
        {text}
      </Typography>
    </ListItem>
  );
};

const AnnouncementBanner = (props: { children: any; onClose: () => void }) => {
  const { children, onClose } = props;
  const styles = useStyles();
  return (
    <Box
      display="flex"
      alignItems={'center'}
      justifyContent={'center'}
      className={styles.announcementBanner}
    >
      <Box display="flex" width="100%" justifyContent={'center'} alignItems={'center'}>
        {children}
      </Box>
      <Box display="flex" alignItems={'center'} paddingRight={2}>
        <IconButton onClick={onClose}>
          <CloseIcon className={styles.closeAnnouncementBannerBtn} />
        </IconButton>
      </Box>
    </Box>
  );
};

export const Header: React.FC<{}> = () => {
  const styles = useStyles();
  const { state, dispatch: dispatchPageLayout } = usePageLayoutState();
  const { showProjectBanner, showBetaAnnouncement } = state;

  const subscription = useCurrentSubscription();

  const history = useHistory();
  const showExamplesPage = useIsFeatureEnabledAndMayHideForSnowflake().examplesPage;

  const handleAnnouncementBannerClose = () => {
    dispatchPageLayout(draft => {
      draft.showBetaAnnouncement = false;
    });
  };

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        className={cx(styles.appBar, !showProjectBanner && styles.defaultAppBar, 'cy-app-header')}
      >
        {showBetaAnnouncement && (
          <AnnouncementBanner onClose={handleAnnouncementBannerClose}>
            {t('This is a Beta version of LandingLens.')}
          </AnnouncementBanner>
        )}
        <Toolbar className={cx(styles.toolbar, isOnProjectPage() && styles.toolbarOnProjectPage)}>
          {/* LandingLens Logo */}
          <Tooltip title={t('Go to home page')}>
            <div
              className={styles.landingLensLogoLink}
              onClick={() => history.push(CLEF_PATH.home)}
            >
              <img src={logoLandingLens} height={28} data-testid="landing-lens-logo" />
            </div>
          </Tooltip>

          {/* Home */}
          <NavItem link={CLEF_PATH.home} text={t('Home')} />

          {/* Projects */}
          <NavItem link={CLEF_PATH.projects} text={t('Projects')} />

          {/* Examples */}
          {showExamplesPage && <NavItem link={CLEF_PATH.examples} text={t('Examples')} />}

          {/* Community */}
          <NavItem link="https://community.landing.ai" text={t('Community')} />

          <div className={styles.grow} />

          {/* Media upload header */}
          <MediaUploadIndicator />

          {subscription?.meta?.plan === SubscriptionName.Freemium && (
            <PlanBadge subscription={subscription} />
          )}

          {/* Support center */}
          <IconButton
            id="help-button"
            className={cx(styles.helpButton)}
            aria-label="support-center"
            href="https://support-landingai.document360.io/"
            component="a"
            target="_blank"
            rel="noopener"
          >
            <HelpOutline classes={{ root: styles.helpIcon }} fontSize="medium" />
          </IconButton>

          {/* Language button */}
          <LanguageSwitch />

          {/* User profile button and menu */}
          <NewProfileMenu useNewUi />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;

import React, { useCallback } from 'react';
import { Theme, Tooltip } from '@material-ui/core';
import { Button, Dropdown } from '@clef/client-library';
import { useSnackbar } from 'notistack';
import {
  attachMetadataToSingleMedia,
  useMediaMetadataApi,
  useMetadataApi,
} from '../../../../hooks/api/useMetadataApi';

import SetMetadataDropdown from '../../../../components/SetMetadata/SetMetadataDropdown';
import { Metadata, MetadataType, MetadataValueType } from '@clef/shared/types';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import CLEF_PATH from '../../../../constants/path';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import { useCurrentProjectModelInfoQuery } from '@/serverStore/projectModels';

const useStyles = makeStyles((theme: Theme) => ({
  setMetadataBtn: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  disabledMetadata: {
    color: '#666',
  },
  addMetadataBtn: {
    padding: theme.spacing(1, 1.5),
    height: theme.spacing(6),
    borderRadius: 5,
    fontSize: 12,
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[200],
  },
}));

export const useHandleSetMetadata = (mediaId: number) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { id: currentModelId } = useCurrentProjectModelInfoQuery();

  const handleSetMetadata = useCallback(
    (metadata: Metadata) => async (value: string[] | number[]) => {
      let valueReformatted: MetadataValueType;
      if (metadata.type === MetadataType.Boolean) {
        valueReformatted = value?.[0] === t('true');
      } else if (!metadata.allowMultiple) {
        valueReformatted = metadata.type === MetadataType.Number ? Number(value?.[0]) : value?.[0];
      } else {
        valueReformatted =
          metadata.type === MetadataType.Number
            ? (value as string[]).map(val => Number(val))
            : value;
      }
      try {
        if (!selectedProject) {
          return;
        }
        await attachMetadataToSingleMedia(
          mediaId,
          {
            [metadata.id]: valueReformatted,
          },
          undefined,
          selectedProject,
          currentModelId,
        );
        enqueueSnackbar(
          t('Metadata {{temp0}} added', {
            temp0: metadata.name,
          }),
          { variant: 'success' },
        );
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, mediaId, currentModelId, selectedProject],
  );

  return handleSetMetadata;
};

const AddMetadata: React.FC<{
  mediaId: number;
  appliedMetadataIds: number[];
  mediaMetadata?: ReturnType<typeof useMediaMetadataApi>[0];
}> = ({ mediaId, appliedMetadataIds, mediaMetadata }) => {
  const handleSetMetadata = useHandleSetMetadata(mediaId);
  const { data: project } = useGetSelectedProjectQuery();
  const [metadataMapping] = useMetadataApi(project?.id);
  const metadataList = Object.values(metadataMapping || {});
  const history = useHistory();
  const styles = useStyles();

  if (!metadataList.length) {
    return (
      <Tooltip
        title={
          <span>
            {t('No metadata is set up, to create new one, {{link}}.', {
              link: (
                <a
                  className={styles.setMetadataBtn}
                  onClick={e => {
                    e.preventDefault();
                    history.push(CLEF_PATH.data.metadata);
                  }}
                >
                  {t('click here')}
                </a>
              ),
            })}
          </span>
        }
        interactive
        arrow
        placement="top"
      >
        <Button id="add-metadata" className={styles.addMetadataBtn}>
          {t('Add Metadata')}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Dropdown
      extraGutter={{ vertical: 8 }}
      dropdown={toggleDropdown => (
        <SetMetadataDropdown
          setMetadataAction={(metadata: Metadata) => async (value: string[] | number[]) => {
            await handleSetMetadata(metadata)(value);
            toggleDropdown(false);
          }}
          appliedMetadataIds={appliedMetadataIds}
          mediaMetadata={mediaMetadata}
        />
      )}
    >
      <Button id="add-metadata" className={styles.addMetadataBtn}>
        {t('Add Metadata')}
      </Button>
    </Dropdown>
  );
};

export default AddMetadata;

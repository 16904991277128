import { BaseAPI } from './base_api';
import { ProjectId, MetadataType, SearchColumnResult, Metadata } from '@clef/shared/types';

class MetadataAPI extends BaseAPI {
  async getMetadataByProjectId(id: ProjectId): Promise<{ [key: string]: Metadata }> {
    return this.get('get_metadata_by_projectId', { projectId: id }, true);
  }

  async updateFieldById(params: {
    id: number;
    name: string;
    allowMultiple: boolean;
    projectId: number;
    valueFlexible: boolean;
    predefinedChoices?: string[] | null;
  }) {
    return this.postJSON('modify_field', params);
  }

  async addField(params: {
    projectId: number;
    name: string;
    type: MetadataType;
    allowMultiple: boolean;
    valueFlexible: boolean;
    predefinedChoices?: string[] | null;
  }) {
    return this.postJSON('create_new_field', params);
  }

  async getSearchableColumnInfo(projectId: ProjectId): Promise<SearchColumnResult> {
    const res = await this.get('get_searchable_column_info', { projectId });
    return res.data;
  }
}

export default new MetadataAPI('metadata');

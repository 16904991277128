import subscription_api from '@/api/subscription_api';
import { useBillingDetails } from '@/hooks/api/useSubscriptionApi';
import { Button, Typography } from '@clef/client-library';
import { Box, CircularProgress, makeStyles, Tooltip } from '@material-ui/core';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { usePlanStyles } from '.';
import {
  useCurrentSubscription,
  useIsPricingV2User,
  useIsPricingV2VisionaryUser,
} from '../../../hooks/useSubscriptions';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { upperFirst } from 'lodash';

export type BillingSectionProps = {};
const useStyles = makeStyles(theme => ({
  bilingContainer: {
    paddingTop: theme.spacing(8),
  },
  billingTitle: {
    color: theme.palette.grey[800],
  },
  billingItem: {
    width: 180,
    height: 84,
    border: `1px solid ${theme.palette.greyModern[200]}`,
    borderRadius: 16,
    backgroundColor: theme.palette.greyModern[50],
    paddingLeft: theme.spacing(5),
    marginRight: theme.spacing(4),
  },
  label: {
    fontSize: 14,
    color: theme.palette.grey[600],
    marginBottom: theme.spacing(2),
  },
  value: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.grey[900],
  },
  interval: {
    fontSize: 14,
    lineHeight: '20px',
    color: theme.palette.grey[400],
  },
  detailedAmountValue: {
    fontSize: 12,
    lineHeight: '16px',
    color: theme.palette.grey[400],
  },
  infoOutlinedIcon: {
    width: 20,
    height: 20,
    color: theme.palette.greyModern[600],
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
}));

const DEFAULT_CURRENCY_SYMBOL = '$';

const TOOLTIP_FOR_NEXT_PAYMENT =
  'This includes a $50 basic monthly fee, which includes 5000 credits, plus any additional credits beyond the 5000 used in the last billing cycle. Please note that the displayed number is not realtime; refer to your invoice for the most accurate information.';

const getCurrencySymbol = (currencyCode: string) => {
  if (!currencyCode) {
    return DEFAULT_CURRENCY_SYMBOL;
  }
  const format = new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode });
  const parts = format.formatToParts(123);
  const zeroDecimalCurrency = parts.find(m => m.type === 'currency');
  return zeroDecimalCurrency ? zeroDecimalCurrency.value : DEFAULT_CURRENCY_SYMBOL;
};

const BillingSection: React.FC<BillingSectionProps> = () => {
  const styles = useStyles();
  const planStyles = usePlanStyles();
  const [billingDetails] = useBillingDetails({});
  const subscription = useCurrentSubscription();
  const isPricingV2User = useIsPricingV2User();
  const isPricingV2VisionaryUser = useIsPricingV2VisionaryUser();

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const updateCard = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const url = await subscription_api.getBillingPortalUrl(true /* update card */);
      window.open(url);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error', autoHideDuration: 12000 });
    }
    setLoading(false);
  }, [enqueueSnackbar, loading]);

  const baseAmount = billingDetails?.nextPayment?.base?.amount ?? 0;
  const baseCurrency = billingDetails?.nextPayment?.base?.currency
    ? getCurrencySymbol(billingDetails?.nextPayment?.base?.currency)
    : DEFAULT_CURRENCY_SYMBOL;
  const overageAmount = billingDetails?.nextPayment?.overage?.amount ?? 0;
  const overageCurrency = billingDetails?.nextPayment?.overage?.currency
    ? getCurrencySymbol(billingDetails?.nextPayment?.overage?.currency)
    : DEFAULT_CURRENCY_SYMBOL;
  const overallAmount = baseAmount + overageAmount;
  const overallCurrency = baseCurrency;

  const hasDefaultPaymentType = billingDetails?.defaultPaymentType;
  const showDefaultPaymentType = !billingDetails?.cardLast4 && hasDefaultPaymentType;

  return (
    <Box className={styles.bilingContainer}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h2" className={styles.billingTitle}>
          {t('Billing Details')}
        </Typography>
        {
          <Button
            id={hasDefaultPaymentType ? 'update-card-btn' : 'add-card-btn'}
            variant="tonal"
            onClick={updateCard}
            startIcon={
              loading && (
                <Box marginRight={3} display="flex" alignItems="center">
                  <CircularProgress size={20} />
                </Box>
              )
            }
          >
            {hasDefaultPaymentType ? t('Update Card') : t('Add Card')}
          </Button>
        }
      </Box>
      <Box display="flex" marginTop={4}>
        {!!billingDetails && (
          <>
            <Box
              className={styles.billingItem}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography className={styles.label}>{t('Due Date')}</Typography>
              <Typography className={styles.value}>
                {format(new Date(billingDetails.dueDate), 'MMM dd, yyyy')}
              </Typography>
            </Box>
            <Box
              className={styles.billingItem}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Typography className={styles.label}>{t('Next Payment')}</Typography>
                {isPricingV2VisionaryUser && (
                  <Tooltip arrow placement="bottom" title={TOOLTIP_FOR_NEXT_PAYMENT}>
                    <InfoOutlined className={styles.infoOutlinedIcon} />
                  </Tooltip>
                )}
              </Box>
              {isPricingV2User ? (
                <Box display="flex" alignItems="center">
                  <Typography className={styles.value}>
                    {t('{{overallCurrency}}{{overallAmount}}', {
                      overallCurrency: overallCurrency,
                      overallAmount: overallAmount,
                    })}
                  </Typography>
                  {isPricingV2VisionaryUser && (
                    <Box marginLeft={2}>
                      <Typography className={styles.detailedAmountValue}>
                        {t('{{baseCurrency}}{{baseAmount}}+{{overageCurrency}}{{overageAmount}}', {
                          baseCurrency: baseCurrency,
                          baseAmount: baseAmount,
                          overageCurrency: overageCurrency,
                          overageAmount: overageAmount,
                        })}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <Typography className={styles.value}>
                    {t('{{overallCurrency}}{{overallAmount}}', {
                      overallCurrency: overallCurrency,
                      overallAmount: overallAmount,
                    })}
                  </Typography>
                  {subscription?.status !== 'to_be_canceled' && (
                    <Box marginLeft={2} className={styles.interval}>
                      {subscription?.stripeRecurringInterval === 'month'
                        ? t('billed monthly')
                        : subscription?.stripeRecurringInterval === 'year'
                        ? t('billed annually')
                        : ''}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            {billingDetails.cardLast4 && (
              <Box
                className={styles.billingItem}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography className={styles.label}>{t('Card on File')}</Typography>
                <Typography className={styles.label}>
                  {t('xxxx-xxxx-xxxx-{{last4}}', { last4: billingDetails.cardLast4 })}
                </Typography>
              </Box>
            )}
            {showDefaultPaymentType && (
              <Box
                className={styles.billingItem}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography className={styles.label}>{t('Payment Type')}</Typography>
                <Typography className={styles.value}>
                  {upperFirst(billingDetails.defaultPaymentType)}
                </Typography>
              </Box>
            )}
          </>
        )}
        {!billingDetails && (
          <Box className={planStyles.noData}>{t('No billing information yet')}</Box>
        )}
      </Box>
    </Box>
  );
};

export default BillingSection;

import React, { SetStateAction, useCallback, useState } from 'react';
import { Menu, MenuItem, ListItemText, TextField, Chip } from '@material-ui/core';
import Autocomplete, {
  AutocompleteGetTagProps,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Button } from '@clef/client-library';
import { PROJECT_TYPE, QueryTag, SortOption } from '../../../utils/project_utils';
import useStyles from '../styles';
import { rootElement } from '../../../utils/dom_utils';
import { LabelType } from '@clef/shared/types';
import { useIsFeatureEnabledAndMayHideForSnowflake } from '@/hooks/useFeatureGate';

export interface ProjectsToolbarProps {
  idPrefix?: string;
  className?: string;
  style?: React.CSSProperties;
  searchText: string;
  setSearchText: (searchText: string) => void;
  sortOption: SortOption;
  setSortOption: (sortOption: SortOption) => void;
  queryTags: QueryTag[];
  setQueryTags: React.Dispatch<SetStateAction<QueryTag[]>>;
}

const getOptions = (isVpEnabled: boolean) => {
  return [
    { group: PROJECT_TYPE, label: t('Object Detection'), id: LabelType.BoundingBox },
    { group: PROJECT_TYPE, label: t('Segmentation'), id: LabelType.Segmentation },
    { group: PROJECT_TYPE, label: t('Classification'), id: LabelType.Classification },
    ...(isVpEnabled
      ? [
          {
            group: PROJECT_TYPE,
            label: t('Visual Prompting'),
            id: LabelType.SegmentationInstantLearning,
          },
        ]
      : []),
  ];
};

export interface ProjectToolbarAutocompleteProps {
  searchText: string;
  setSearchText: (searchText: string) => void;
  queryTags: QueryTag[];
  setQueryTags: React.Dispatch<SetStateAction<QueryTag[]>>;
}

export const ProjectToolbarAutocomplete: React.FC<ProjectToolbarAutocompleteProps> = ({
  searchText,
  setSearchText,
  queryTags,
  setQueryTags,
}) => {
  const styles = useStyles();
  const [groupedOptionsOpen, setGroupedOptionsOpen] = useState(false);
  const closePopper = () => setGroupedOptionsOpen(false);
  const openPopper = () => setGroupedOptionsOpen(true);
  const isVpEnabled = useIsFeatureEnabledAndMayHideForSnowflake().visualPrompting;
  const _renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        variant="outlined"
        placeholder={t('Search by project name, project type, creator')}
        inputProps={{
          ...params.inputProps,
        }}
        InputProps={{
          ...params.InputProps,
          className: styles.projectToolbarSearchTextField,
          startAdornment: (
            <>
              <SearchIcon className={styles.searchIcon} />
              {params.InputProps.startAdornment}
            </>
          ),
        }}
      />
    );
  };

  const _renderGroup = (params: AutocompleteRenderGroupParams) => {
    return (
      <div key={params.key} className={styles.projectToolbarPopupGroup}>
        <div className={styles.projectToolbarPopupGroupHeader}>{params.group}</div>
        {params && params.children ? (
          <div className={styles.projectToolbarPopupGroupItemsContainer}>{params?.children}</div>
        ) : null}
      </div>
    );
  };

  const _renderQueryTag = (
    option: QueryTag,
    index: number,
    getTagProps: AutocompleteGetTagProps,
  ) => {
    return (
      <Chip
        size="small"
        {...getTagProps({ index })}
        label={option.label}
        onDelete={_e => {
          setQueryTags((prevQueryTags: QueryTag[]) => {
            return prevQueryTags.filter(item => {
              return item.id !== option.id;
            });
          });
        }}
        classes={{
          root: styles.projectToolbarQueryTagRoot,
          sizeSmall: styles.projectToolbarQueryTagSizeSmall,
        }}
      />
    );
  };

  return (
    <Autocomplete
      id="grouped-demo"
      value={queryTags}
      inputValue={searchText}
      open={groupedOptionsOpen}
      onBlur={closePopper}
      onOpen={openPopper}
      onClose={closePopper}
      onKeyDown={e => {
        if (e.key === 'Backspace' && searchText === '' && queryTags.length > 0) {
          setQueryTags(curQueryTags => {
            const clonedQueryTags = [...curQueryTags];
            clonedQueryTags.pop();
            return clonedQueryTags;
          });
        }
      }}
      onChange={(_event, value, reason) => {
        if (reason === 'select-option') {
          _event.defaultPrevented = true;
          setQueryTags((prevQueryTags: QueryTag[]) => {
            const queryTags = value.filter(option => {
              return typeof option !== 'string';
            }) as QueryTag[];
            if (!prevQueryTags.length) {
              return [...queryTags];
            }
            const newSelectedQueryTag = queryTags.find(queryTag => {
              return !prevQueryTags.map(prevQueryTag => prevQueryTag.id).includes(queryTag.id);
            });
            if (newSelectedQueryTag) {
              const filteredQueryTagsWithDifferentGroup = queryTags.filter(queryTag => {
                return queryTag.group !== newSelectedQueryTag.group;
              }) as QueryTag[];
              return [...filteredQueryTagsWithDifferentGroup, newSelectedQueryTag];
            }
            return [...queryTags];
          });
        } else if (reason === 'clear') {
          setQueryTags([]);
          setSearchText('');
        }
      }}
      onInputChange={(_event, value, reason) => {
        if (reason === 'input') {
          setSearchText(value);
        }
      }}
      renderTags={(value: readonly (string | QueryTag)[], getTagProps) =>
        value.map((option: string | QueryTag, index: number) => {
          if (typeof option !== 'string') {
            return _renderQueryTag(option, index, getTagProps);
          } else {
            return null;
          }
        })
      }
      renderGroup={_renderGroup}
      renderInput={_renderInput}
      className={styles.projectToolbarAutocomplete}
      classes={{
        option: styles.projectToolbarAutocompleteOption,
        input: styles.projectToolbarAutocompleteInput,
        endAdornment: styles.projectToolbarEndAdornment,
      }}
      ListboxProps={{
        className: styles.projectToolbarPopupBox,
      }}
      multiple={true}
      fullWidth={true}
      options={getOptions(isVpEnabled)}
      freeSolo={true}
      autoSelect={true}
      disableCloseOnSelect={true}
      groupBy={option => option.group}
      getOptionLabel={option => option.label}
      filterOptions={(options, _state) => {
        // If there is selected option from a group, hide all options of the group from the dropdown
        const filteredOptions = options.filter(option => {
          return !queryTags.map(tag => tag.group).includes(option.group);
        });
        return filteredOptions;
      }}
    />
  );
};

export const ProjectsToolbar: React.FC<ProjectsToolbarProps> = ({
  idPrefix = '',
  className,
  style,
  queryTags,
  setQueryTags,
  searchText,
  setSearchText,
  sortOption,
  setSortOption,
}) => {
  const styles = useStyles();
  const [dropdownButtonAnchorEl, setDropdownButtonAnchorEl] = useState<HTMLElement | null>(null);

  const onDropdownButtonClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setDropdownButtonAnchorEl(event.currentTarget);
  }, []);

  const onDropdownButtonClose = useCallback(() => {
    setDropdownButtonAnchorEl(null);
  }, []);

  return (
    <div className={className} style={style}>
      <ProjectToolbarAutocomplete
        searchText={searchText}
        setSearchText={setSearchText}
        queryTags={queryTags}
        setQueryTags={setQueryTags}
      />
      <>
        <Button
          id={`${idPrefix ? idPrefix + '_' : ''}projects_sorting_button`}
          data-testid={`${idPrefix ? idPrefix + '_' : ''}projects_sorting_button`}
          aria-controls="projects_sorting_menu"
          aria-haspopup="true"
          variant="outlined"
          onClick={onDropdownButtonClick}
          endIcon={<KeyboardArrowDownIcon />}
          className={styles.projectGridSortingDropDownButton}
        >
          {t(sortOption)}
        </Button>

        <Menu
          id={`${idPrefix ? idPrefix + '_' : ''}projects_sorting_menu`}
          data-testid={`${idPrefix ? idPrefix + '_' : ''}projects_sorting_menu`}
          elevation={0}
          // Required for placing the menu item below the dropdown button
          // https://github.com/mui/material-ui/issues/7961
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          anchorEl={dropdownButtonAnchorEl}
          open={!!dropdownButtonAnchorEl}
          onClose={onDropdownButtonClose}
          container={rootElement}
          keepMounted
        >
          {[SortOption.LastOpened, SortOption.DateCreatedNewest, SortOption.DateCreatedOldest].map(
            sortOption => (
              <MenuItem
                key={sortOption}
                onClick={() => {
                  setSortOption(sortOption);
                  onDropdownButtonClose();
                }}
              >
                <ListItemText primary={t(sortOption)} />
              </MenuItem>
            ),
          )}
        </Menu>
      </>
    </div>
  );
};

import React, { useState, useCallback, useRef, useEffect, ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import MediaViewerBoxAnnotation from './MediaViewerBoxAnnotation';
import useLoadImage from '../../hooks/useLoadImage';
import { useKeyPressHold } from '../../hooks/useKeyPress';
import useWindowEventListener from '../../hooks/useWindowEventListener';
import { MediaProperties, RangeBox } from '@clef/shared/types';
import throttle from 'lodash/throttle';
import MediaViewerSegmentationAnnotation from './MediaViewerSegmentationAnnotation';
import HideLabelsButtonFloater from '../MediaInteractiveCanvas/components/HideLabelsButtonFloater';
import ClassChip, { ClassifiedClass } from './ClassChip';
import { DefaultPositionPriorities, PaddingRatio, ReversedPositionPriorities } from './util';
import { Img } from '../Image';

const useStyles = makeStyles(({ spacing, palette }) => ({
  imgItem: {
    width: '100%',
    height: '100%',
    imageRendering: 'pixelated',
  },
  imgContainer: {
    overflow: 'hidden',
    transformOrigin: '0 0',
  },
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    borderRadius: 8,
    overflow: 'hidden',
    position: 'relative',
    '&:hover $hideLabelsButton': {
      opacity: 1,
    },
  },
  rootSelected: {
    backgroundColor: `${palette.primary.main}1A`,
  },
  stage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1)',
  },
  leftCorner: {
    zIndex: 106,
    position: 'absolute',
    bottom: spacing(2),
    left: spacing(2),
    maxWidth: '80%',
    overflow: 'hidden',
  },
  rightCorner: {
    zIndex: 106,
    position: 'absolute',
    bottom: spacing(2),
    right: spacing(2),
  },
  hideLabelsButton: {
    opacity: 0,
  },
  attentionHeatmap: {
    filter: 'grayscale(100%)',
  },
  grayscale: {
    filter: 'grayscale(1)',
  },
  overlapImage: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  imgSrcData: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
}));

export type BoxAnnotation = {
  key: string | number;
  xMin: number;
  yMin: number;
  xMax: number;
  yMax: number;
  description?: string;
  highlighted?: boolean;
  color: string;
  isErrorRegion?: boolean;
  isPrediction?: boolean;
  confidence?: number;
  defectId?: number;
};

export type SegmentationAnnotation = {
  key: string | number;
  description?: string;
  color: string;
  compressedBitMap: string;
  xMin: number;
  yMin: number;
  xMax: number;
  yMax: number;
};

const maxZoomScale = 10;
const minZoomScale = 1;

export interface MediaViewerProps {
  // The image to render
  imgSrc?: string;
  // The image to render
  fallbackImgSrc?: string;
  // List of box annotations to render, coordinates should be absolute to the image size.
  boxAnnotations?: BoxAnnotation[];
  // List of segmentation annotations or segmentation image url to render
  segmentationAnnotations?: (SegmentationAnnotation | string)[];
  // left corner component is the element shown on the left corner
  leftCornerComponent?: ReactElement | null;
  // classified class, used for classification project
  classifiedClass?: ClassifiedClass | ReactElement | null;
  // Classification gradcam url
  classificationGradcamUrl?: string;
  // Image properties can include height and width to be used for placeholder and preview to use
  // before the actual image is loaded
  properties?: MediaProperties | null;
  // Extra classes to internal components
  classes?: {
    root?: string;
    stage?: string;
  };
  // onClick handler to the whole MediaViewer
  onClick?: () => void;
  // enable zoom from wheel event on the image
  enableZoom?: boolean;
  // universal style pattern for media selected
  selected?: boolean;
  // enable hide annotations
  enableHideAnnotations?: boolean;
  // OK/accepted->green // NG/rejected->red
  judgementColor?: string;
  hideDefectName?: boolean;
  className?: string;
  // the range box to focus on
  focusBox?: RangeBox;
  // Base64 encoded PNG source image
  annotationSrcData?: string | undefined;
  openCreateDialog?: () => void;
  enableGrayscaleImage?: boolean;
  wrapperStyle?: React.CSSProperties;
  loadingComponent?: ReactNode | null;
  imageViewMode?: 'contain' | 'cover';
  renderSegmentationComponentWithFilter?: (
    actualImageWidth: number,
    actualImageHeight: number,
  ) => React.ReactNode;
}

/**
 * Basic media viewer component, render a given image src and annotations.
 * Pure component, does not tie to any backend structure.
 * NOTE: currently only support boxAnnotations.
 */
const MediaViewer: React.FC<MediaViewerProps> = ({
  imgSrc,
  fallbackImgSrc,
  boxAnnotations,
  segmentationAnnotations,
  classifiedClass,
  properties,
  classes,
  onClick,
  enableZoom,
  selected,
  enableHideAnnotations = false,
  judgementColor = '',
  hideDefectName = false,
  classificationGradcamUrl,
  leftCornerComponent,
  className,
  focusBox,
  annotationSrcData,
  openCreateDialog,
  enableGrayscaleImage = false,
  renderSegmentationComponentWithFilter,
  wrapperStyle = {},
  loadingComponent,
  imageViewMode = 'contain',
}) => {
  const styles = useStyles();
  // we only need to load image to calculate width/height if it does not already exist from properties
  const image = useLoadImage(properties ? undefined : imgSrc, 'use-credentials');
  const containerNode = useRef<HTMLDivElement | null>(null);

  const [zoomTransform, setZoomTransform] = useState({ scale: 1, translateX: 0, translateY: 0 });
  const [, updateState] = useState<object>();
  const forceUpdate = useCallback(() => updateState({}), []);
  // On window resize, the size of container node also changed, this
  // require the state scale of the pane to be re-adjusted
  useWindowEventListener('resize', forceUpdate);

  const mounted = useRef(true);
  // @ts-ignore ignore return type is boolean warning
  useEffect(() => () => (mounted.current = false), []);

  useEffect(forceUpdate, [forceUpdate, judgementColor]);

  const [hideAnnotationsState, setHideAnnotationsState] = useState(false);
  const hideAnnotationsKey = useKeyPressHold('h');

  useEffect(() => {
    if (!focusBox || !properties || !containerNode.current) {
      return;
    }
    const { clientWidth, clientHeight } = containerNode.current;
    const actualImageWidth = properties?.width ?? image!.width;
    const actualImageHeight = properties?.height ?? image!.height;
    const stageScale =
      Math.min(clientWidth / actualImageWidth, clientHeight / actualImageHeight) *
      (selected ? 0.85 : 1);
    const stageWidth = clientWidth / stageScale;
    const stageHeight = clientHeight / stageScale;

    // use the <long edge width> * <padding ratio> as the padding
    const { xmin, xmax, ymin, ymax } = focusBox;
    const boxWidth = xmax - xmin;
    const boxHeight = ymax - ymin;
    const padding = Math.max(boxWidth, boxHeight) * PaddingRatio;
    // calculate the scale factor and offset
    const scale = Math.min(
      actualImageWidth / (xmax - xmin + 2 * padding),
      actualImageHeight / (ymax - ymin + 2 * padding),
    );
    if (scale <= 1) {
      return;
    }
    const scaledImageWidth = actualImageWidth * scale;
    const scaledImageHeight = actualImageHeight * scale;
    // when aspect ratio differs from the image, need to consider image paddings
    const imagePaddingX = Math.max(0, (stageWidth - actualImageWidth) / 2);
    const imagePaddingY = Math.max(0, (stageHeight - actualImageHeight) / 2);
    // to make sure there are no spaces on the right or bottom of the image, need to limit the translateX and translateY
    const minTranslateX = stageWidth - scaledImageWidth - imagePaddingX;
    const minTranslateY = stageHeight - scaledImageHeight - imagePaddingY;
    // update zoom transform
    const offsetX = (-xmin - boxWidth * 0.5) * scale + stageWidth * 0.5 - imagePaddingX;
    const offsetY = (-ymin - boxHeight * 0.5) * scale + stageHeight * 0.5 - imagePaddingY;
    const translateX = Math.min(
      // scaledImageWidth * (1 - scale) * 0.5,
      -imagePaddingX,
      // 0,
      Math.max(offsetX, minTranslateX),
    );
    const translateY = Math.min(
      -imagePaddingY,
      // 0,
      Math.max(offsetY, minTranslateY),
    );
    setZoomTransform({ scale, translateX, translateY });
  }, [boxAnnotations, properties, selected, image, focusBox]);

  if ((!image && !properties) || !containerNode.current || (loadingComponent && !imgSrc)) {
    return (
      <div className={cx(styles.root, classes?.root)} ref={containerNode}>
        {loadingComponent ? (
          loadingComponent
        ) : (
          <Skeleton height={'100%'} width={'100%'} variant="rect" />
        )}
      </div>
    );
  }

  const userHideAnnotations = enableHideAnnotations && (hideAnnotationsState || hideAnnotationsKey);

  const isClassifiedClassComponent = !!classifiedClass && React.isValidElement(classifiedClass);
  const rightCornerComponent =
    (isClassifiedClassComponent && (classifiedClass as ReactElement)) ||
    (!!classifiedClass && (
      <ClassChip
        openCreateDialog={openCreateDialog}
        classifiedClass={classifiedClass as ClassifiedClass}
      />
    )) ||
    null;

  const actualImageWidth = properties?.width ?? image!.width;
  const actualImageHeight = properties?.height ?? image!.height;

  const imgContainerZoomStyle: React.CSSProperties = {
    transform: `translate(${zoomTransform.translateX}px, ${zoomTransform.translateY}px) scale(${zoomTransform.scale})`,
  };

  const humanJudgementBorder: React.CSSProperties = {
    border: `5px solid ${judgementColor}`,
  };

  const { clientWidth: currentClientWidth, clientHeight: currentClientHeight } =
    containerNode.current;
  const getStageScaleContainMode = (heightRatio: number, weightRatio: number) => {
    if (heightRatio === 0 && weightRatio === 0) {
      return 0.85;
    }
    if (heightRatio === 0) {
      return weightRatio;
    }
    if (weightRatio === 0) {
      return heightRatio;
    }
    return Math.min(heightRatio, weightRatio);
  };
  const stageScaleContainMode = getStageScaleContainMode(
    currentClientWidth / actualImageWidth,
    currentClientHeight / actualImageHeight,
  );
  const stageScaleCoverModel = Math.max(
    containerNode.current!.clientWidth / actualImageWidth,
    containerNode.current!.clientHeight / actualImageHeight,
  );
  const stageScale =
    (imageViewMode === 'contain' ? stageScaleContainMode : stageScaleCoverModel) *
    (selected ? 0.85 : 1);
  const stageStyle: React.CSSProperties = {
    transform: `translate(-50%, -50%) scale(${stageScale})`,
    width: actualImageWidth,
    height: actualImageHeight,
  };
  const updateZoomTransform = throttle((deltaY: number, xPos: number, yPos: number) => {
    setZoomTransform(prev => {
      const prevScale = prev.scale;
      const newScale = Math.min(
        Math.max(prev.scale * (deltaY > 0 ? 1.2 : 0.8), minZoomScale),
        maxZoomScale,
      );
      return {
        scale: newScale,
        translateX:
          newScale > 1
            ? prev.translateX - ((newScale - prevScale) / prevScale) * (xPos / stageScale)
            : 0,
        translateY:
          newScale > 1
            ? prev.translateY - ((newScale - prevScale) / prevScale) * (yPos / stageScale)
            : 0,
      };
    });
  }, 32);

  const onWheelEvent = (e: WheelEvent) => {
    e.stopPropagation();
    e.stopImmediatePropagation();
    e.preventDefault();
    // @ts-ignore getBoundingClientRect does exist
    const componentRect: DOMRect = (e.currentTarget || e.target)!.getBoundingClientRect();
    const deltaY = -e.deltaY;
    updateZoomTransform(deltaY, e.clientX - componentRect.left, e.clientY - componentRect.top);
  };

  return (
    <div
      className={cx(styles.root, selected && styles.rootSelected, classes?.root, className)}
      style={{
        border: '0px none transparent',
        ...wrapperStyle,
        ...(judgementColor && humanJudgementBorder),
      }}
      onClick={onClick}
      ref={node => {
        containerNode.current = node;
        if (!node) {
          return;
        }
        const { clientWidth, clientHeight } = node;
        // client width and height changes after render but will not trigger re-render,
        // however, we need to force re-render to update the stage scale
        requestAnimationFrame(() => {
          // why nested requestAnimationFrame: if you need to perform an action after the browser has fully completed the current frame (including all rendering tasks), you can nest a second requestAnimationFrame inside the first one. This will schedule your function to run after the next frame, ensuring all rendering tasks have been completed.
          requestAnimationFrame(() => {
            const { clientWidth: newClientWidth, clientHeight: newClientHeight } = node;
            const sizeChanged = newClientWidth !== clientWidth || newClientHeight !== clientHeight;
            // check `mounted` to avoid updating states after unmount
            sizeChanged && mounted && forceUpdate();
          });
        });
      }}
      data-testid="media-viewer"
    >
      {enableHideAnnotations && (
        <HideLabelsButtonFloater
          buttonClassName={styles.hideLabelsButton}
          onHideLabelStatusChange={hide => setHideAnnotationsState(hide)}
        />
      )}
      <div className={cx(styles.stage, classes?.stage)} style={stageStyle}>
        <div
          className={styles.imgContainer}
          style={imgContainerZoomStyle}
          ref={node => {
            // why we are using ref to add event listener instead of prop onWheel
            // https://github.com/facebook/react/issues/5845#issuecomment-492955321
            if (node && enableZoom) {
              node.removeEventListener('wheel', onWheelEvent);
              node.addEventListener('wheel', onWheelEvent);
            }
          }}
        >
          <Img
            className={cx(
              styles.imgItem,
              classificationGradcamUrl && styles.attentionHeatmap,
              enableGrayscaleImage && styles.grayscale,
            )}
            src={imgSrc}
            fallbackSrc={fallbackImgSrc}
            draggable={false}
            crossOrigin="use-credentials"
          />
          {boxAnnotations &&
            !userHideAnnotations &&
            !annotationSrcData &&
            boxAnnotations.map(annotation => (
              <MediaViewerBoxAnnotation
                key={annotation.key}
                text={hideDefectName ? undefined : annotation.description}
                color={annotation.color}
                xMin={annotation.xMin}
                xMax={annotation.xMax}
                yMin={annotation.yMin}
                yMax={annotation.yMax}
                imageHeight={actualImageHeight}
                imageWidth={actualImageWidth}
                highlighted={annotation.highlighted}
                scale={stageScale * zoomTransform.scale}
                isErrorRegion={annotation.isErrorRegion}
                dashed={annotation.isPrediction}
                textPositionPriorities={
                  annotation.isPrediction ? ReversedPositionPriorities : DefaultPositionPriorities
                }
              />
            ))}
          {!renderSegmentationComponentWithFilter &&
            segmentationAnnotations &&
            !userHideAnnotations &&
            !annotationSrcData &&
            segmentationAnnotations.map(annotation =>
              typeof annotation === 'object' ? (
                <MediaViewerSegmentationAnnotation
                  key={annotation.key}
                  text={hideDefectName ? undefined : annotation.description}
                  color={annotation.color}
                  xMin={annotation.xMin}
                  xMax={annotation.xMax}
                  yMin={annotation.yMin}
                  yMax={annotation.yMax}
                  compressedBitMap={annotation.compressedBitMap}
                  scale={stageScale}
                />
              ) : (
                // assumption: prediction image has the same size with the original image
                <img
                  data-testid="prediction-image"
                  src={annotation as string}
                  key={annotation}
                  width={actualImageWidth}
                  height={actualImageHeight}
                  style={{ position: 'absolute', left: 0, top: 0 }}
                />
              ),
            )}
          {renderSegmentationComponentWithFilter &&
            renderSegmentationComponentWithFilter(actualImageWidth, actualImageHeight)}
          {!annotationSrcData && classificationGradcamUrl && (
            <img
              className={styles.overlapImage}
              data-testid="classification-gradcam-image"
              src={classificationGradcamUrl}
              width={actualImageWidth}
              height={actualImageHeight}
            />
          )}
          {annotationSrcData && (
            <img
              data-testid="base64-prediction-image"
              className={styles.imgSrcData}
              src={`data:image/png;base64,${annotationSrcData}`}
            />
          )}
        </div>
      </div>
      <div className={styles.leftCorner}>{leftCornerComponent}</div>
      <div className={styles.rightCorner}>{rightCornerComponent}</div>
    </div>
  );
};

export default MediaViewer;

import React from 'react';
import { Route } from 'react-router-dom';

import CLEF_PATH from '../../../constants/path';
import { transformLegacyPath } from '../utils';
import { PageLayout } from '../PageLayout';

import { FREDeploymentInferencePage } from '../../../pages/deployment/FREDeploymentInferencePage';
import { PageTitle } from '../../../constants/page';
import { PageName } from '@clef/shared/constants';

const continuousLearningRoutes = (currentPath: string) => [
  // Deployment routes
  <Route
    exact
    key={CLEF_PATH.deployment.overview}
    path={transformLegacyPath(currentPath, CLEF_PATH.deployment.overview)}
    render={() => (
      <PageLayout title={PageTitle.Deploy} pageName={PageName.DeployPage} showProjectBanner>
        <FREDeploymentInferencePage />
      </PageLayout>
    )}
  />,
];

export default continuousLearningRoutes;

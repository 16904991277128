import { makeStyles } from '@material-ui/core';
import React from 'react';
import BillingSection from './BillingSection';
import CreditsUsageSection from './CreditsUsageSection';
import InvoiceHistory from './InvoiceHistory';
import PlanSection from './PlanSection';
import { useIsNonStripeUser } from '@/hooks/useSubscriptions';

export const usePlanStyles = makeStyles(theme => ({
  button: {
    padding: '10px 24px',
    backgroundColor: theme.palette.blue[100],
    borderRadius: 13,
    color: theme.palette.blue[900],
    fontSize: 14,
    fontWeight: 700,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  noData: {
    fontSize: 14,
  },
}));

const PlanAndBilling: React.FC = () => {
  const isNonStripeUser = useIsNonStripeUser();

  return (
    <>
      <PlanSection />
      <CreditsUsageSection />
      {!isNonStripeUser && (
        <>
          <BillingSection />
          <InvoiceHistory />
        </>
      )}
    </>
  );
};

export default PlanAndBilling;

import React from 'react';
import { Button } from '@clef/client-library';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

interface NewApiKeySecretDialog {
  open: boolean;
  onClickOk(): void;
  title?: string;
  text?: string;
  textWarning?: string;
  apiKey: string;
  secret: string;
}

const useStyles = makeStyles(() => ({
  dialogTitle: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
}));

const ApiKeySecretDialog: React.FunctionComponent<NewApiKeySecretDialog> = ({
  open,
  onClickOk,
  title = 'API Key Generation',
  text = 'API Key and secret successfully generated',
  textWarning = 'Please save (right click to copy & paste)  ' +
    'the API key and shared secret safely before you click OK. As soon as you ' +
    "leave the page you won't be able to view or retrieve the shared " +
    'secret.',
  apiKey,
  secret,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent>
        <Typography gutterBottom variant="body2">
          {text}
        </Typography>
        <br />
        <Typography gutterBottom variant="subtitle1" data-testid="api-key-text">
          {t('Key: ')}
          {apiKey}
        </Typography>
        <Typography gutterBottom variant="subtitle1" data-testid="api-secret-text">
          {t('Secret: ')}
          {secret}
        </Typography>
        <br />
        <Typography gutterBottom variant="body2">
          {textWarning}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button id="confirm-new-api-key-secret-dialog" onClick={onClickOk}>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApiKeySecretDialog;

import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import { DataBrowserState, useDataBrowserState } from '@/pages/DataBrowser/dataBrowserState';
import MediaContainer from '@/pages/DataBrowser/MediaContainer';
import { useDatasetMedias, useTotalMediaCount } from '@/pages/DataBrowser/utils';
import { ApiResponseLoader, VirtualGrid, VirtualRow } from '@clef/client-library';
import { FilterOptionName, Media, MediaId, MediaStatusType } from '@clef/shared/types';
import { Pagination } from '@material-ui/lab';
import {
  isMediaSelected as isMediaSelectedInLabeling,
  useImageLabelingContext,
} from '../../Labeling/imageLabelingContext';
import { useInstantLearningState } from '../../../pages/InstantLearningLabeling/state';
import cx from 'classnames';
import { calcOptimalRatio } from '@/pages/DataBrowser/MediaGrid/MediaGrid';

const useStyles = makeStyles(theme => ({
  groupTitle: {
    fontSize: 16,
    lineHeight: '34px',
    fontWeight: 500,
  },
  loadingImagesProgress: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  container: { overflowX: 'auto', height: '70vh' },
  containerHide: { visibility: 'hidden' },
  media: { padding: '0px 0px 2px 2px', width: '100%' },
}));

export type DisplayMode = 'partial' | 'full';

export type ImagesGroupInLabelingViewProps = {
  group: 'labeled' | 'unlabeled';
  displayMode: DisplayMode;
  showGroundTruthLabels?: boolean;
  showPredictionLabels?: boolean;
  setShowGroundTruthLabels?(show: boolean): void;
  setShowPredictionLabels?(show: boolean): void;
  onImageClick?(mediaId: MediaId): void;
  toggleLabelButton?: JSX.Element;
  expandMoreButton?: JSX.Element;
  enableGrayscaleImage?: boolean;
  onPaginationChange?: (pageIndex: number) => void;
  width: number;
  itemCountPerColumnInPartialMode?: number;
  itemPerRow: number;
  imageRatio?: number;
};

export const ImagesGroupInLabelingView: React.FC<ImagesGroupInLabelingViewProps> = ({
  group,
  displayMode,
  toggleLabelButton,
  /*  expandMoreButton,*/
  showGroundTruthLabels,
  showPredictionLabels,
  onImageClick,
  enableGrayscaleImage = false,
  onPaginationChange,
  width,
  itemCountPerColumnInPartialMode = 1,
  itemPerRow,
  imageRatio,
}) => {
  const styles = useStyles();

  const { state: originalState } = useDataBrowserState();
  const { dispatch } = useInstantLearningState();
  const state: DataBrowserState = useMemo(() => {
    return {
      ...originalState,
      pageIndex: group === 'labeled' ? originalState.pageIndex : originalState.secondaryPageIndex,
      appliedFilters: {
        ...originalState.appliedFilters,
        [FilterOptionName.MediaStatus]: {
          o: 'CONTAINS_ANY',
          t: 'column',
          v: group === 'labeled' ? [MediaStatusType.Approved] : [MediaStatusType.Raw],
        },
      },
    };
  }, [group, originalState]);

  const { data: totalMediaCount } = useTotalMediaCount(state);
  const {
    data: datasetMedias,
    isLoading: datasetMediasLoading,
    error: datasetMediasError,
  } = useDatasetMedias(state);
  const { showGroundTruth, showPredictions } = state;
  const { state: imageLabelingState } = useImageLabelingContext();

  useEffect(() => {
    if (group === 'labeled') {
      dispatch(draft => {
        draft.topbarImages.labeledGroupImages = datasetMedias || [];
      });
    } else if (group === 'unlabeled') {
      dispatch(draft => {
        draft.topbarImages.unlabeledGroupImages = datasetMedias || [];
      });
    }
  }, [datasetMedias, datasetMediasLoading, dispatch, group]);

  const renderMedia = useCallback(
    (media: Media) => {
      return (
        <Box
          height={`${100 / itemCountPerColumnInPartialMode}%`}
          key={media.id}
          className={cx(displayMode === 'partial' && styles.media)}
        >
          <MediaContainer
            media={media}
            selected={isMediaSelectedInLabeling(imageLabelingState, media.id)}
            disableSelection
            onInfoClick={() => onImageClick?.(media.id)}
            showGroundTruth={showGroundTruthLabels ?? showGroundTruth}
            showPredictions={showPredictionLabels ?? showPredictions}
            thumbnailSize={itemPerRow > 1 ? 'medium' : 'large'}
            showClassChip={false}
            segmentationOpacity={0.6}
            enableGrayscaleImage={enableGrayscaleImage}
          />
        </Box>
      );
    },
    [
      itemCountPerColumnInPartialMode,
      displayMode,
      styles.media,
      imageLabelingState,
      showGroundTruthLabels,
      showGroundTruth,
      showPredictionLabels,
      showPredictions,
      itemPerRow,
      enableGrayscaleImage,
      onImageClick,
    ],
  );

  const optimalRatio = useMemo(
    () => (imageRatio === undefined ? calcOptimalRatio(datasetMedias ?? []) : imageRatio),
    [datasetMedias, imageRatio],
  );

  return (
    <ApiResponseLoader
      response={datasetMedias}
      loading={datasetMediasLoading}
      error={datasetMediasError}
      defaultHeight={200}
    >
      {datasetMedias => {
        return datasetMedias.length ? (
          <Box position="relative" width={width}>
            <Box display="flex" alignItems="center" width="100%" marginBottom={4.5}>
              <Typography className={styles.groupTitle}>
                {t('{{groupName}} ({{count}})', {
                  groupName: group === 'labeled' ? t('Labeled') : t('Unlabeled'),
                  count: totalMediaCount,
                })}
              </Typography>

              <Box flex={1} />

              {toggleLabelButton}
              {group === 'unlabeled' && <Box marginLeft={4}></Box>}
              {/*todo:error:if unlabeledImages is empty,expandMoreButton can't show {expandMoreButton}*/}
            </Box>

            {datasetMediasLoading && <LinearProgress className={styles.loadingImagesProgress} />}

            {displayMode === 'partial' && (
              <VirtualRow
                dataList={datasetMedias}
                itemCountPerRow={itemPerRow}
                itemRatio={optimalRatio * itemCountPerColumnInPartialMode}
                containerWidth={width}
              >
                {renderMedia}
              </VirtualRow>
            )}

            {displayMode === 'full' && (
              <div className={styles.container}>
                <VirtualGrid
                  componentList={datasetMedias}
                  itemPerRow={itemPerRow}
                  imageRatio={optimalRatio}
                  style={{ margin: 0 }}
                >
                  {renderMedia}
                </VirtualGrid>
              </div>
            )}
            {onPaginationChange &&
              Math.ceil((totalMediaCount ?? 0) / state.paginationLimit) > 1 && (
                <Pagination
                  data-testid="labeling-view-pagination"
                  count={Math.ceil((totalMediaCount ?? 0) / state.paginationLimit)}
                  color="primary"
                  size="small"
                  page={state.pageIndex + 1}
                  onChange={(_, pageIndex) => onPaginationChange(pageIndex)}
                  className={styles.pagination}
                />
              )}
          </Box>
        ) : null;
      }}
    </ApiResponseLoader>
  );
};

import {
  Box,
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import { format } from 'date-fns';
import React from 'react';
import { UsageItemWithInterval } from '../../../api/usage_api';
import { useGetProjectsQuery } from '@/serverStore/projects';
import { UsageOptionTexts } from '../data';
import { UsageParams, useUsageStyles } from './UsageSection';
import { useGetUsage } from '@/serverStore/usage';

export type UsageLogProps = {
  params: UsageParams;
};

const useStyles = makeStyles(theme => ({
  topProjectsContent: {
    width: '70%',
  },
  topProjectsValue: {
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.blue[500],
    marginRight: theme.spacing(4),
  },
  label: {
    width: 120,
    color: theme.palette.grey[600],
    fontSize: 14,
  },
  topValueItem: {
    marginBottom: 14,
  },
  value: {
    fontSize: 14,
    color: theme.palette.grey[600],
  },
  logHeader: {
    borderRadius: '13px 13px 0px 0px',
    backgroundColor: theme.palette.greyModern[25],
  },
  noData: {
    height: 70,
    fontSize: 14,
  },
  loadingContainer: {
    marginTop: 50,
  },
}));

const UsageLogSection: React.FC<UsageLogProps> = ({ params }) => {
  const usageStyles = useUsageStyles();
  const styles = useStyles();
  const { startTime, endTime } = params;
  const { data: projects } = useGetProjectsQuery();
  const { data: usageData, isLoading: loading } = useGetUsage({
    fromTimestamp: startTime,
    toTimestamp: endTime,
    interval: 'day',
    groupByProject: true,
    groupBySource: true,
  });

  const tableHead = [
    {
      label: t('Usage Type'),
    },
    {
      label: t('Project'),
    },
    {
      label: t('Credits Used'),
    },
    {
      label: t('Timestamp'),
    },
  ];

  const getUsageStyle = (text: string) => {
    switch (text) {
      case 'Training':
        return usageStyles.value1;
      case 'Inference':
        return usageStyles.value2;
      case 'OCR':
        return usageStyles.valueOCR;
      default:
        return usageStyles.greyValue;
    }
  };

  const values = ((usageData || []) as UsageItemWithInterval[]).map(item => {
    const project = projects?.find(project => project.id === item.projectId);
    const sourceValue = UsageOptionTexts[item.source!] || item.source;
    return [
      {
        value: UsageOptionTexts[item.source!] || item.source,
        classNames: classNames(
          usageStyles.value,
          UsageOptionTexts[item.source!]
            ? getUsageStyle(UsageOptionTexts[item.source!])
            : usageStyles.greyValue,
        ),
      },
      {
        value:
          project?.name || (sourceValue === 'OCR' ? 'Object Character Recognition (OCR)' : '-'),
      },
      {
        value: item.usage,
      },
      {
        value: format(new Date(item.startTime), 'MMM d'),
      },
    ];
  });

  return (
    <Box marginTop={8}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography className={usageStyles.title} variant="h2">
          {t('Usage Log')}
        </Typography>
      </Box>
      {loading && (
        <Box
          className={styles.loadingContainer}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress size={24} />
        </Box>
      )}
      {!loading && (
        <Box marginTop={4}>
          <Table>
            <TableHead className={styles.logHeader}>
              <TableRow>
                {tableHead.map((d, index) => {
                  return <TableCell key={index}>{d.label}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {values.map((v, index) => {
                return (
                  <TableRow key={index}>
                    {v.map(d => {
                      return (
                        <TableCell key={d.value} component="td">
                          <span className={classNames(d.classNames, styles.value)}>{d.value}</span>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {!values.length && (
            <Box
              className={styles.noData}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {t('No data')}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default UsageLogSection;

import model_comparison_report_api from '@/api/model_comparison_report_api';
import { ModelComparisonReportResponseItem } from '@clef/shared/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useGetSelectedProjectQuery } from '../projects';
import { modelComparisonReqortQueryKeys } from './queries';

export const useDeleteModelComparisonReportMutation = () => {
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      await model_comparison_report_api.deleteModelComparisonReport(id);
      return id;
    },
    onMutate: id => {
      const prevData = queryClient.getQueryData<ModelComparisonReportResponseItem[]>(
        modelComparisonReqortQueryKeys.modelComparisonReports(projectId!),
      );
      queryClient.setQueriesData<ModelComparisonReportResponseItem[]>(
        modelComparisonReqortQueryKeys.modelComparisonReports(projectId!),
        prevData?.filter(item => item.id !== id),
      );
      return { prevData };
    },
    onSuccess: _id => {
      queryClient.invalidateQueries(
        modelComparisonReqortQueryKeys.modelComparisonReports(projectId!),
      );
    },
    onError: (e: Error, _id, context) => {
      enqueueSnackbar(e.message, { variant: 'error' });
      queryClient.setQueriesData<ModelComparisonReportResponseItem[]>(
        modelComparisonReqortQueryKeys.modelComparisonReports(projectId!),
        context?.prevData,
      );
    },
  });
};

import React, { useMemo } from 'react';
import { useDeviceMonitorTotalMediaCount } from '../../../hooks/api/useEdgeApi';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { ApiResponseLoader, SelectOptions } from '@clef/client-library';
import { useDeviceMonitorState, consolidateFilterOptionFromState } from './deviceMonitorState';
import { Typography, Grid, ButtonGroup } from '@material-ui/core';
import { Button } from '@clef/client-library';
import { useDeviceMonitorStyles } from './deviceMonitorStyles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

export interface EdgeMediaPaginationOptionsProps {}

const EdgeMediaPaginationOptions: React.FC<EdgeMediaPaginationOptionsProps> = () => {
  const styles = useDeviceMonitorStyles();
  const { data: selectedProject } = useGetSelectedProjectQuery();
  const { state, dispatch } = useDeviceMonitorState();
  const {
    deviceId,
    selectedMedia,
    pageIndex,
    paginationLimit,
    filterConfidence,
    filterConfidenceEnabled,
    filterDefects,
    filterModel,
    filterClass,
    filterUploadTime,
    filterUploadTimeEnabled,
    filterHumanJudgement,
    filterImageId,
    filterImageIdEnabled,
    filterLocationId,
    filterLocationIdEnabled,
    filterInspectionStationId,
    filterInspectionStationIdEnabled,
  } = state;

  const filterOptions = useMemo(
    () => consolidateFilterOptionFromState(state),
    // only specific filter change will effect filterOptions
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterConfidence,
      filterConfidenceEnabled,
      filterDefects,
      filterModel,
      filterClass,
      filterUploadTime,
      filterUploadTimeEnabled,
      filterHumanJudgement,
      filterImageId,
      filterImageIdEnabled,
      filterLocationId,
      filterLocationIdEnabled,
      filterInspectionStationId,
      filterInspectionStationIdEnabled,
    ],
  );

  const [deviceMediaTotal, deviceMediaTotalLoading, deviceMediaTotalError] =
    useDeviceMonitorTotalMediaCount(
      selectedProject
        ? {
            projectId: selectedProject.id,
            deviceId: deviceId!,
            filterOptions,
          }
        : undefined,
    );

  return (
    <ApiResponseLoader
      response={deviceMediaTotal}
      loading={deviceMediaTotalLoading}
      error={deviceMediaTotalError}
      defaultHeight={0}
    >
      {response => {
        const maxPage = Math.ceil(response / paginationLimit);
        return (
          <>
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              justifyContent="space-between"
              className={styles.mediaPaginationGrid}
            >
              <Grid container alignItems="center" justifyContent="flex-start">
                <Typography variant="body1">
                  <strong>{response}</strong>&nbsp;
                  {t('media')}
                </Typography>
                {!!selectedMedia.length && (
                  <Typography
                    color="primary"
                    variant="subtitle1"
                    className={styles.textButtonRaw}
                    onClick={() =>
                      dispatch(draft => {
                        draft.selectedMedia = [];
                      })
                    }
                  >
                    {t('Deselect all')}
                  </Typography>
                )}
              </Grid>
              <Grid container alignItems="center" justifyContent="flex-end">
                <SelectOptions
                  size="medium"
                  value={paginationLimit}
                  onChange={newValue => {
                    dispatch(draft => {
                      draft.paginationLimit = newValue as number;
                    });
                  }}
                  renderValue={value => `${value} per page`}
                  options={[20, 50, 100, 200]}
                  classes={{
                    selector: styles.selectorNoBorder,
                  }}
                />
                <Typography variant="body1" className={styles.paginationText}>{`${
                  pageIndex + 1
                } of ${maxPage}`}</Typography>
                <ButtonGroup
                  disableElevation
                  classes={{
                    grouped: styles.groupButton,
                  }}
                >
                  <Button
                    size="small"
                    id="previous-page"
                    disabled={pageIndex === 0}
                    onClick={() => {
                      dispatch(draft => {
                        draft.pageIndex = pageIndex - 1;
                      });
                    }}
                  >
                    <ChevronLeft />
                  </Button>
                  <Button
                    size="small"
                    id="next-page"
                    disabled={pageIndex >= maxPage - 1}
                    onClick={() => {
                      dispatch(draft => {
                        draft.pageIndex = pageIndex + 1;
                      });
                    }}
                  >
                    <ChevronRight />
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </>
        );
      }}
    </ApiResponseLoader>
  );
};

export default EdgeMediaPaginationOptions;

import { StyledComponentProps } from '@material-ui/styles/withStyles';
import { RouteComponentProps } from 'react-router-dom';
import LRU from 'lru-cache';

export interface DefaultFormProps {
  isLoading: boolean;
  error: any;
  success: any;
  message: any;
}

/* MUI Utils */
export type StyledRoutedProps = Required<StyledComponentProps> & RouteComponentProps;
export type StyledRoutedFormComponentProps = StyledRoutedProps & DefaultFormProps;

export type IHandleChangeEvent = (e: React.ChangeEvent) => any;
export interface DataAugmentationConfig {
  uid: string;
  name: string;
  creator_id: string;
  created: string;
}

export interface DataAugmentationParameter {
  name: string;
  value: string | number | boolean | number[];
  has_default: boolean;
  json_schema_type: DataAugmentationParameterJsonSchemaType;
  description: DataAugmentationParameterDescription;
  component: DataAugmentationComponent;
  json_schema_description: string;
}

export const enum DataAugmentationParameterJsonSchemaType {
  array = 'array',
  boolean = 'boolean',
  integer = 'integer',
  number = 'number',
  object = 'object',
  string = 'string',
}

export const enum DataAugmentationComponentType {
  text = 'text',
  number = 'number',
  checkbox = 'checkbox',
  array = 'array',
  select = 'select',
}

export interface DataAugmentationParameterDescription {
  choices: string[];
}

export interface DataAugmentationComponent {
  type: DataAugmentationComponentType | null;
  size?: number;
}

export interface DataAugmentationTransformationList {
  version: string;
  transformationList: Array<DataAugmentationTransformation>;
}

export interface DataAugmentationTransformation {
  id: number;
  name: string;
  category: string;
  description: string;
  transform_generic_params: Array<DataAugmentationParameter>;
  transform_specific_params: Array<DataAugmentationParameter>;
}

export interface ModelIterationObjectDetectionSchema {
  dataset: ModelIterationObjectDetectionDataset;
  train: ModelIterationObjectDetectionTrain;
  model: ModelIterationObjectDetectionModel;
}

export interface ModelIterationObjectDetectionDataset {
  train_split_key: string;
  val_split_key: string;
  test_split_key: string;
}

export interface ModelIterationObjectDetectionTrain {
  batch_size: number;
  epochs: number;
  learning_rate: number;
}

export interface ModelIterationObjectDetectionModel {
  avi: ModelIterationObjectDetectionAVI;
}

export interface ModelIterationObjectDetectionAVI {
  RetinaNetOD: ModelIterationObjectDetectionRetinaNetOD;
}

export interface ModelIterationObjectDetectionRetinaNetOD {
  backbone: string;
  input_shape: number[];
}

export interface ModelIterationForm {
  train_split_key: string;
  val_split_key: string;
  test_split_key: string;
  batch_size: number;
  epochs: number;
  learning_rate: number;
  backbone: string;
  input_shape: number[];
}
export type APICache = LRU<string | number, { timestamp: number; data: Promise<any> }>[];

export type TransformUISchemaOption = {
  name: string;
  label: string;
  isAdded: boolean;
  showCarousel: boolean;
  hasRangeSlider: boolean;
  hasSingleSlider?: boolean;
  hasDropdown?: boolean;
  probability: boolean;
  showCropTool?: boolean;
  isResizeOption?: boolean;
  estimate?: boolean;
};

export type TransformUISchema = {
  [key: string]: TransformUISchemaOption;
};

export interface PreviewDimensions {
  width: number;
  height: number;
}

export enum StepName {
  Upload = 'Upload',
  Label = 'Label',
  Train = 'Train',
  Predict = 'Predict',
  Complete = 'Complete',
}

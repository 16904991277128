import { useCallback } from 'react';
import useGetDefectById from './useGetDefectById';

const useGetDefectNameById = () => {
  const getDefectById = useGetDefectById();

  return useCallback((defectId: number) => getDefectById(defectId)?.name, [getDefectById]);
};

export default useGetDefectNameById;
